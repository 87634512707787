import WasmController from 'react-lib/frameworks/WasmController';
import Scheduling from "issara-sdk/apis/Scheduling_apps_QUE";
import DivisionServiceBlockView from "issara-sdk/apis/DivisionServiceBlockView_apps_QUE";
import DivisionServiceBlockDetail from "issara-sdk/apis/DivisionServiceBlockDetail_apps_QUE";
import { DsbStatus } from "../Model";
import { serialToDate } from 'react-lib/apps/Scheduling/common/Utils';

export type State = 
  {
    selectedDivision?: any,
    SetScheduleSequence?: {
      sequenceIndex: string | null,
      selectedDivision?: any,
      blockList?: any[],
    } | null,
  }

export const StateInitial: State = 
  {
    SetScheduleSequence: null,
  }

export type Event = 
  { message: "RunSequence", params: {} }

export type Data = 
  {
    division?: number,
    divisionDict?: any,
    device?: number,
  }

export const DataInitial = 
  {
  }

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any

export const Start: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.SetScheduleSequence) return

  const blockList = await RefreshDsb(controller, params);

  // Set state and go to the next step
  controller.setState({
    SetScheduleSequence: {
      ...state.SetScheduleSequence,
      sequenceIndex: "Edit",
      selectedDivision: state.selectedDivision,
      blockList: blockList,
    }
  });
}

export const Edit: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.SetScheduleSequence) return
  if (params?.action === "AddBlock") {
    const providerId = params?.selectedProvider?.provider_id;
    if (providerId && params?.event 
        && !params?.event?.providers
                  .map((p: any) => p.provider_id)
                  .includes(providerId)) {
      const block = await DivisionServiceBlockView.create({
        data: {
          division: controller.data.division,
          provider: providerId,
          start_serial: params.event?.start_serial,
          end_serial: params.event?.end_serial,
        } as any,
        apiToken: controller.apiToken
      });
      console.log(block[1] ? block[1] : block[0]);
      
      // Refresh schedule
      const blockList = await RefreshDsb(controller, params);
      controller.setState({
        SetScheduleSequence: {
          ...state.SetScheduleSequence,
          blockList: blockList
        }
      });
    
    } else {
      console.log("DSB with this provider already exists.")
      const block = await DivisionServiceBlockDetail.patch({
        pk: params.event?.providers?.[0]?.dsb_id,
        data: {
          status: params?.inactive ? DsbStatus.INACTIVE : DsbStatus.REVISE,
        } as any,
        apiToken: controller.apiToken
      });
      console.log(block[1] ? block[1] : block[0]);
      
      // Refresh schedule
      const blockList = await RefreshDsb(controller, params);
      controller.setState({
        SetScheduleSequence: {
          ...state.SetScheduleSequence,
          blockList: blockList
        }
      });
    }
  }
}

// Utilities ===================================================
const RefreshDsb: Handler = async (controller, params) => {
  const state = controller.getState();
  // Get dsb
  const dsb = await Scheduling.get({
    command: "DivisionServiceBlockFilter",
    params: { divisions: [state.selectedDivision?.id] },
    apiToken: controller.apiToken
  });
  console.log(dsb[1] ? dsb[1] : dsb[0]);

  const blockList = (dsb[0] || []).map((item: any, index: number) => { 

    return {
      ...item,
      id: index,
      start: serialToDate(item.start_serial),
      end: serialToDate(item.end_serial),
      title: `${item.start_time} - ${item.end_time}`
    }
  });
  return blockList
}