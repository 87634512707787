import { useEffect, useCallback, useMemo, useState } from "react";

import CardTreatmentResultUX from "./CardTreatmentResultUX";
import { Input, Button, Label, Modal } from "semantic-ui-react";
import TimeComboBox from "react-lib/apps/common/TimeComboBox";

import EmployeeToken from "react-lib/apps/common/EmployeeToken";
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import DateTextBox from "react-lib/apps/common/DateTextBox";
import ModInfo from "react-lib/apps/common/ModInfo";
import ModAuthen from "react-lib/apps/common/ModAuthen";


import {
  formatTimeCurrent,
  dateToStringWithoutTimeBE,
} from "react-lib/utils/dateUtils";
import { Table } from "react-lib/frameworks/Table";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import ModError from "react-lib/appcon/common/ModInfo";
import { useIntl } from "react-intl";

type ModInfoType = {
  open: boolean;
  error?: any;
  title?: string;
  color?: string;
};

const CARD_TREATMENT_RESULT = "CardTreatmentResult";

const REGEX_TIME = /(\d+:\d+)/g;

const CardTreatmentResult = (props: any) => {
  const intl = useIntl();
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [openModNote, setOpeModNote] = useState<boolean>(false);
  const [dataChanged, setDataChanged] = useState<boolean>(false);
  const [result, setResult] = useState<string>("");
  const [complications, setComplications] = useState<string>("");
  const [openModAuthen, setOpenModAuthen] = useState<any>({
    open: false,
    isApproveOrder: false,
    type: "",
  });

  useEffect(() => {
    props.runSequence({ sequence: "TreatmentResult", restart: true });
  }, []);

  useEffect(() => {
    if (props.successMessage?.["CardTreatmentResult_saveApprove"]) {
      setOpenModAuthen({ open: false, isApproveOrder: false });
      props.setProp(
        `successMessage.${"CardTreatmentResult_saveApprove"}`,
        null
      );
    }
  }, [props.successMessage?.["CardTreatmentResult_saveApprove"]]);

  const TreatmentList = useMemo(() => {
    return (props.TreatmentResultList || [])?.map(
      (item: any, index: number) => ({
        ...item,
        order_detail_header: (
          <div
            dangerouslySetInnerHTML={{ __html: item.order_summary_detail }}
          />
        ),
        order_detail: (
          <div style={{ justifyContent: "space-between" }}>
            <div style={{ display: "flex" }}>
              <Label basic color="blue" style={{marginRight: "10px", height: "30px"}}>
                {item.to_be_performer_name}
              </Label>
              <div 
                style={{
                  textDecoration:
                    item.status === "CANCELED" ? "line-through" : "",
                }} 
                dangerouslySetInnerHTML={{ __html: item.order_summary_detail }} />
            </div>
            {item?.order_detail && (
              <div style={{ color: "gray" }}>
                <b>{intl.formatMessage({ id: "รายละเอียดเพิ่มเติม :" })}</b>
                {item.order_detail}
              </div>
            )}
            {item?.equipment_detail && (
              <div style={{ color: "gray" }}>
                <b>{intl.formatMessage({ id: "อุปกรณ์ :" })}</b>
                {item.equipment_detail}
              </div>
            )}
            <div
              style={{
                backgroundColor: "red",
                borderRadius: "50%",
                width: "1em",
                height: "1em",
                margin: "0px 5px",
                ...(!(item?.need_approve && item?.student_name) && {
                  display: "none",
                }),
              }}
            />
          </div>
        ),
        careprovider: item?.status !== "CANCELED" && (
          <div>
            {item?.order_by === item?.approved_by ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                {item?.approved_by_name}
              </div>
            ) : (
              <div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {item?.student_name || item?.order_by_name}
                </div>
                {item?.need_approve && item?.student_name ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "20px 0px",
                    }}
                  >
                    <ButtonLoadCheck
                      // function
                      id="btn-confirmMedOrder"
                      setProp={props.setProp}
                      onClick={() => {
                        props.setProp(
                          "OrderCentralLabSequence.orderId",
                          item?.id
                        );

                        let fullNameSplit =
                          props.django?.user?.full_name.split(" ");

                        if (
                          props.selectedEmr?.doctor_name ===
                            `${fullNameSplit?.[0]}${fullNameSplit?.[1]} ${fullNameSplit?.[2]}` &&
                          item?.student_name
                        ) {
                          handleSaveApprove({
                            approved_by_username: "",
                            approved_by_password: "",
                            id: item?.id,
                          });
                        } else {
                          setOpenModAuthen({
                            open: true,
                            isApproveOrder: true,
                            id: item.id,
                          });
                        }
                      }}
                      // data
                      paramKey={`${CARD_TREATMENT_RESULT}_CONFIRM_${item?.id}`}
                      buttonLoadCheck={
                        props.buttonLoadCheck?.[
                          `${CARD_TREATMENT_RESULT}_CONFIRM_${item?.id}`
                        ]
                      }
                      // config
                      color={"green"}
                      title={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src="/static/images/drugOrder/doctor_approve.png"
                            style={{ width: "20px", marginRight: "7px" }}
                          ></img>
                          <label>APPROVE</label>
                        </div>
                      }
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                ) : (
                  item?.student_name && (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "20px 0px",
                        }}
                      >
                        <img
                          src="/static/images/drugOrder/approve_check.png"
                          style={{ width: "10px 0px", marginRight: "7px" }}
                        ></img>
                        <label style={{ color: "green" }}>APPROVE</label>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          color: "gray",
                        }}
                      >
                        ตรวจสอบโดย
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "10px 0px",
                        }}
                      >
                        {item?.approved_by_name}
                      </div>
                    </div>
                  )
                )}
              </div>
            )}
          </div>
        ),
        treatmen_name: item?.status === "PERFORMED" && (
          <div>
            {item?.order_by === item?.approved_by ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                {item?.order_by_name}
              </div>
            ) : (
              <div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {item?.order_perform_by_name}
                </div>
                {item?.result_approved_by === null && item?.student_name ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "20px 0px",
                    }}
                  >
                    <ButtonLoadCheck
                      // function
                      id="btn-confirmMedOrder"
                      setProp={props.setProp}
                      onClick={() => {
                        props.setProp(
                          "OrderCentralLabSequence.orderId",
                          item?.id
                        );

                        let fullNameSplit =
                          props.django?.user?.full_name.split(" ");

                        if (
                          props.selectedEmr?.doctor_name ===
                            `${fullNameSplit?.[0]}${fullNameSplit?.[1]} ${fullNameSplit?.[2]}` &&
                          item?.student_name
                        ) {
                          handleSaveApprove({
                            approved_by_username: "",
                            approved_by_password: "",
                            type: "treatmentResult",
                            value: {
                              id: item?.id,
                            },
                          });
                        } else {
                          setOpenModAuthen({
                            open: true,
                            isApproveOrder: true,
                            type: "treatmentResult",
                          });
                        }
                      }}
                      // data
                      paramKey={`${CARD_TREATMENT_RESULT}_CONFIRM_${item?.id}`}
                      buttonLoadCheck={
                        props.buttonLoadCheck?.[
                          `${CARD_TREATMENT_RESULT}_CONFIRM_${item?.id}`
                        ]
                      }
                      // config
                      color={"green"}
                      title={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src="/static/images/drugOrder/doctor_approve.png"
                            style={{ width: "20px", marginRight: "7px" }}
                          ></img>
                          <label>APPROVE</label>
                        </div>
                      }
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                ) : (
                  item?.student_name && (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "20px 0px",
                        }}
                      >
                        <img
                          src="/static/images/drugOrder/approve_check.png"
                          style={{ width: "10px 0px", marginRight: "7px" }}
                        ></img>
                        <label style={{ color: "green" }}>APPROVE</label>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          color: "gray",
                        }}
                      >
                        ตรวจสอบโดย
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "10px 0px",
                        }}
                      >
                        {item?.result_approved_by_name}
                      </div>
                    </div>
                  )
                )}
              </div>
            )}
          </div>
        ),
        index: index,
      })
    );
  }, [props.TreatmentResultList]);

  const selectedTreatment = useMemo(() => {
    // -if (selectedRow) {
    return TreatmentList?.[selectedRow?.index];
    // }
    // else {
    // return TreatmentList?.[0];
    // }
  }, [selectedRow, TreatmentList]);

  const isSameUser = useMemo(() => {
    return selectedTreatment?.approved_by === selectedTreatment?.order_by;
  }, []);

  const allowCancelOrder = useMemo(() => {
    return !(
      (props.isStudentUser && isSameUser) ||
      selectedTreatment?.status === "ORDERED"
    );
  }, [props.isStudentUser, selectedTreatment, isSameUser]);

  const allowConfirmOrder = useMemo(() => {
    return !(
      (props.isStudentUser && isSameUser) ||
      (!props.isStudentUser &&
        isSameUser &&
        selectedTreatment?.status === "PERFORMED")
    );
  }, [props.isStudentUser, selectedTreatment, isSameUser]);

  const allowSaveOrder = useMemo(() => {
    return !(
      !props.isStudentUser ||
      (props.isStudentUser &&
        ((selectedTreatment?.id && selectedTreatment?.status === "PERFORMED") ||
          isSameUser))
    );
  }, [props.isStudentUser, selectedTreatment, isSameUser]);

  const hideEmployeeCode = useMemo(() => {
    return (
      (props.isStudentUser && isSameUser) ||
      (!props.isStudentUser &&
        isSameUser &&
        selectedTreatment?.status === "PERFORMED")
    );
  }, [props.isStudentUser, selectedTreatment, isSameUser]);

  const formatDate = useCallback((datetime?: string) => {
    return !datetime
      ? dateToStringWithoutTimeBE(new Date())
      : datetime?.split(" [")[0];
  }, []);

  const formatTime = useCallback((datetime?: string) => {
    return !datetime
      // ? formatTimeCurrent(new Date())
      ? `00:00` //* issue 68584
      : datetime?.match(REGEX_TIME)?.[0];
  }, []);

  const startDatetime = useMemo(() => {
    return {
      date: formatDate(selectedTreatment?.start),
      time: formatTime(selectedTreatment?.start),
    };
  }, [selectedTreatment, props.TreatmentResultList]);

  const endDatetime = useMemo(() => {
    return {
      date: formatDate(selectedTreatment?.finish),
      time: formatTime(selectedTreatment?.finish),
    };
  }, [selectedTreatment, props.TreatmentResultList]);

  useEffect(() => {
    if (!selectedRow) {
      setSelectedRow(selectedTreatment);
    }
  }, [props.TreatmentResultList, selectedRow, TreatmentList]);

  useEffect(() => {
    if (
      selectedTreatment &&
      !selectedTreatment?.start &&
      startDatetime.date &&
      startDatetime.time
    ) {
      props.setProp(
        `TreatmentResultList.${selectedTreatment.index}.start`,
        `${startDatetime.date} [${startDatetime.time}]`
      );
    }
  }, [selectedTreatment, startDatetime]);

  useEffect(() => {
    if (
      selectedTreatment &&
      !selectedTreatment?.finish &&
      endDatetime.date &&
      endDatetime.time
    ) {
      props.setProp(
        `TreatmentResultList.${selectedTreatment.index}.finish`,
        `${endDatetime.date} [${endDatetime.time}]`
      );
    }
  }, [selectedTreatment, endDatetime]);

  const handleRowProps = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => {
    return {
      style: {
        backgroundColor:
          rowInfo?.original?.id && rowInfo?.original?.id === selectedRow?.id
            ? "#cccccc"
            : "white",
      },
      onClick: () => {
        if (rowInfo?.original?.status !== "CANCELED") {
          const coreProcedureSecondary = rowInfo.original?.core_procedure
            ?.filter((acc: any) => acc.type === "SECONDARY")
            .map((item: any) => ({
              ...item,
              medical_description: !!item.icd9cm_med_term
                ? item.icd9cm_term
                : item.medical_description,
              icd9cm_med_term: item.medical_description,
            }));

          const coreProcedurePrincipal = rowInfo.original?.core_procedure
            ?.filter((acc: any) => acc.type === "PRIMARY")
            .map((item: any) => ({
              ...item,
              medical_description: !!item.icd9cm_med_term
                ? item.icd9cm_term
                : item.medical_description,
              icd9cm_med_term: item.medical_description,
            }));

          setSelectedRow(rowInfo.original);
          props.setProp("TreatmentResultSequence", {
            ...props.TreatmentResultSequence,
            diagnosisListPrincipal: coreProcedurePrincipal,
            diagnosisListSecondary: coreProcedureSecondary,
          });

          setDataChanged(false);
          setResult(
            rowInfo?.original?.result === null ? "" : rowInfo?.original?.result
          );
          setComplications(
            rowInfo?.original?.complications === null
              ? ""
              : rowInfo?.original?.complications
          );
        }
      },
    };
  };

  const onSearchTextChange = (key: number) => (e: any, v: any) => {
    props.setProp(`TreatmentResultSequence.index`, key);

    props.runSequence({
      sequence: "TreatmentResult",
      action: "searchPrincipal",
      name: v.name,
      value: v.value,
    });

    setDataChanged(true);
  };

  const onSearchSecondaryChange = (key: number) => (e: any, v: any) => {
    props.setProp(`TreatmentResultSequence.index`, key);

    props.runSequence({
      sequence: "TreatmentResult",
      action: "searchSecondary",
      name: v.name,
      value: v.value,
    });

    setDataChanged(true);
  };

  const diagnosisListPrincipal = useMemo(
    () =>
      (props.TreatmentResultSequence?.diagnosisListPrincipal || [])?.map(
        (item: any, index: number) => {
          return (
            <div
              style={{
                marginTop: "5px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ marginLeft: "10px", marginRight: "5px" }}>
                  Medical Term
                </div>
                <Input
                  name="medterm"
                  onChange={onSearchTextChange(index)}
                  value={item.icd9cm_med_term}
                ></Input>
                <div style={{ marginLeft: "10px", marginRight: "5px" }}>
                  ICD9CM Term
                </div>
                <Input
                  name="icdterm"
                  onChange={onSearchTextChange(index)}
                  value={item.icd9cm_term}
                ></Input>
                <div style={{ marginLeft: "10px", marginRight: "5px" }}>
                  ICD9CM Code
                </div>
                <Input
                  name="icdcode"
                  onChange={onSearchTextChange(index)}
                  value={item.icd9cm_code}
                ></Input>
                <Button
                  size="mini"
                  circular
                  icon="minus"
                  color="red"
                  style={{ marginLeft: "10px", marginRight: "5px" }}
                  onClick={() => {
                    props.runSequence({
                      sequence: "TreatmentResult",
                      action: "removeDiagnosis",
                      value: item,
                      index: index,
                    });

                    setDataChanged(true);
                  }}
                />
              </div>
              <div
                style={{
                  display:
                    props.TreatmentResultSequence?.index === index &&
                    props.TreatmentResultSequence?.showSearchResultPrincipal
                      ? "block"
                      : "none",
                  zIndex: 100,
                  width: "calc(100% - 20px)",
                }}
              >
                <Table
                  data={props.procedureItems}
                  getTrProps={(
                    state: any,
                    rowInfo: any,
                    column: any,
                    instance: any
                  ) => {
                    return {
                      onClick: (e: any) => {
                        e.preventDefault();
                        e.stopPropagation();

                        props.runSequence({
                          sequence: "TreatmentResult",
                          action: "selectPrincipal",
                          card: CARD_TREATMENT_RESULT,
                          value: rowInfo.original,
                          index: index,
                        });
                      },
                    };
                  }}
                  keys="medterm,icdterm,icdcode"
                  minRows="3"
                  showPagination={false}
                  style={{ height: "150px" }}
                />
              </div>
            </div>
          );
        }
      ),
    [
      props?.TreatmentResultSequence?.diagnosisListPrincipal,
      props.procedureItems,
    ]
  );

  const diagnosisListSecondary = useMemo(
    () =>
      (props?.TreatmentResultSequence?.diagnosisListSecondary || [])?.map(
        (item: any, index: number) => {
          return (
            <div
              style={{
                marginTop: "5px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ marginLeft: "10px", marginRight: "5px" }}>
                  Medical Term
                </div>
                <Input
                  name="medterm"
                  onChange={onSearchSecondaryChange(index)}
                  value={item.icd9cm_med_term}
                ></Input>
                <div style={{ marginLeft: "10px", marginRight: "5px" }}>
                  ICD9CM Term
                </div>
                <Input
                  name="icdterm"
                  onChange={onSearchSecondaryChange(index)}
                  value={item.icd9cm_term}
                ></Input>
                <div style={{ marginLeft: "10px", marginRight: "5px" }}>
                  ICD9CM Code
                </div>
                <Input
                  name="icdcode"
                  onChange={onSearchSecondaryChange(index)}
                  value={item.icd9cm_code}
                ></Input>
                <Button
                  size="mini"
                  circular
                  icon="minus"
                  color="red"
                  style={{ marginLeft: "10px", marginRight: "5px" }}
                  onClick={() => {
                    props.runSequence({
                      sequence: "TreatmentResult",
                      action: "removeDiagnosis",
                      value: item,
                      index: index,
                    });
                  }}
                />
              </div>
              <div
                style={{
                  display:
                    props.TreatmentResultSequence?.index === index &&
                    props.TreatmentResultSequence?.showSearchResultSecondary
                      ? "block"
                      : "none",
                  zIndex: 100,
                  width: "calc(100% - 20px)",
                }}
              >
                <Table
                  data={props.secondaryItems}
                  getTrProps={(
                    state: any,
                    rowInfo: any,
                    column: any,
                    instance: any
                  ) => {
                    return {
                      onClick: (e: any) => {
                        e.preventDefault();
                        e.stopPropagation();

                        props.runSequence({
                          sequence: "TreatmentResult",
                          action: "selectSencondary",
                          card: CARD_TREATMENT_RESULT,
                          value: rowInfo.original,
                          index: index,
                        });
                      },
                    };
                  }}
                  keys="medterm,icdterm,icdcode"
                  minRows="3"
                  showPagination={false}
                  style={{ height: "150px" }}
                />
              </div>
            </div>
          );
        }
      ),

    [
      props?.TreatmentResultSequence?.diagnosisListSecondary,
      props.secondaryItems,
    ]
  );

  const handleChangeDate = (key: string) => (date: string, v: any) => {
    const [index, field] = key?.split(".");
    const reg = /^\d{2}\/\d{2}\/\d{4}/g;

    if (date.search(reg) === -1) {
      return;
    }

    if (field === "start") {
      handleChangeValue(`${index}.start`)(null, {
        value: selectedTreatment.start.replace(reg, date),
      });
    } else if (field === "finish") {
      handleChangeValue(`${index}.finish`)(null, {
        value: selectedTreatment.finish.replace(reg, date),
      });
    }

    setDataChanged(true);
  };

  const handleChangeTime = (key: string) => (time: string) => {
    if (key === "start") {
      handleChangeValue(`${selectedTreatment?.index}.start`)(null, {
        value: selectedTreatment.start.replace(REGEX_TIME, time),
      });
    } else if (key === "finish") {
      handleChangeValue(`${selectedTreatment?.index}.finish`)(null, {
        value: selectedTreatment.finish.replace(REGEX_TIME, time),
      });
    }

    setDataChanged(true);
  };

  const handleChangeValue = (key: string) => (e: any, v: any) => {
    props.setProp(`TreatmentResultList.${key}`, v.value);

    setDataChanged(true);
  };

  const handleChangeNote = (key: string) => (e: any, v: any) => {
    props.setProp(`TreatmentResultSequence.${key}`, v.value);
  };

  const handleCloseModInfo = () => {
    props.setProp(`TreatmentResultSequence.isOpenError`, false);
  };

  const handleCloseMessage = () => {
    setOpeModNote(false);
    props.setProp(`successMessage.${CARD_TREATMENT_RESULT}`, null);
    props.setProp(`errorMessage.${CARD_TREATMENT_RESULT}`, null);
  };

  const handleCloseModNote = () => {
    setOpeModNote(false);
    props.setProp(`errorMessage.${CARD_TREATMENT_RESULT}`, null);
  };

  const handleCloseModInfoNote = () => {
    props.setProp(`TreatmentResultSequence.isOpenErrorNote`, false);
  };

  const handleSave = () => {
    props.runSequence({
      sequence: "TreatmentResult",
      action: "Save",
      card: CARD_TREATMENT_RESULT,
      id: selectedRow?.id ? selectedRow?.index : 0,
      value: selectedRow,
      datetime: {
        startDate: startDatetime.date,
        startTime: startDatetime.time,
        endDate: endDatetime.date,
        endTime: endDatetime.time,
      },
      data_changed: props.isStudentUser && dataChanged,
      result: result,
      complications: complications,
    });

    setDataChanged(false);
  };

  const handleSaveApprove = (params: any) => {
    props.runSequence({
      sequence: "TreatmentResult",
      action: "saveApprove",
      card: CARD_TREATMENT_RESULT,
      approved_by_username: params.approved_by_username,
      approved_by_password: params.approved_by_password,
      id: selectedRow?.id ? selectedRow?.index : 0,
      type: params.type,
      value: selectedRow || params.value,
      datetime: {
        startDate: startDatetime.date,
        startTime: startDatetime.time,
        endDate: endDatetime.date,
        endTime: endDatetime.time,
      },
      data_changed: props.isStudentUser && dataChanged,
      result: result,
      complications: complications,
    });

    setDataChanged(false);
  };

  const handleChangeResult = (e: any, v: any) => {
    if (v.name === "result") {
      setResult(v.value)
    } else if (v.name === "complications") {
      setComplications(v.value)
    }
  }

  return (
    <div id="CardTreatmentResult" style={{ paddingBottom: "2rem" }}>
      <CardTreatmentResultUX
        diagnosisListPrincipal={diagnosisListPrincipal}
        diagnosisListSecondary={diagnosisListSecondary}
        isShowDiagnosisSecondary={diagnosisListSecondary?.length > 0}
        isShowDiagnosisPrincipal={diagnosisListPrincipal?.length > 0}
        result={result}
        complications={complications}
        handleChangeResult={handleChangeResult}
        dataTreatment={TreatmentList}
        rowProps={handleRowProps}
        treatmentRow={selectedTreatment}
        hideDescription={!selectedRow?.id}
        hideCode={hideEmployeeCode || allowCancelOrder}
        handleChangeValue={handleChangeValue}
        handleChangeDate={handleChangeDate}
        //Principal
        procedureItems={props?.procedureItems || []}
        // showSearchResultPrincipal={
        //   props.TreatmentResultSequence?.showSearchResultPrincipal
        // }
        rowPrincipalProps={(
          state: any,
          rowInfo: any,
          column: any,
          instance: any
        ) => {
          return {
            onClick: (e: any) => {
              e.preventDefault();
              e.stopPropagation();

              props.runSequence({
                sequence: "TreatmentResult",
                action: "selectPrincipal",
                card: CARD_TREATMENT_RESULT,
                value: rowInfo.original,
              });
            },
          };
        }}
        //Secondary=
        // showSearchResultSecondary={
        //   props.TreatmentResultSequence?.showSearchResultSecondary
        // }
        secondaryItems={props?.secondaryItems || []}
        rowSecondaryProps={(
          state: any,
          rowInfo: any,
          column: any,
          instance: any
        ) => {
          return {
            onClick: (e: any) => {
              e.preventDefault();
              e.stopPropagation();

              props.runSequence({
                sequence: "TreatmentResult",
                action: "selectSencondary",
                card: CARD_TREATMENT_RESULT,
                value: rowInfo.original,
              });
            },
          };
        }}
        ErrorMessage={
          <ErrorMessage error={props?.errorMessage?.[CARD_TREATMENT_RESULT]} />
        }
        OnCancel={() => setOpeModNote(true)}
        onSave={handleSave}
        showEdit={
          selectedRow?.id
            ? TreatmentList?.[selectedRow?.index]?.status === "PERFORMED"
            : TreatmentList?.[0]?.status === "PERFORMED"
        }
        code={
          !allowCancelOrder && (
            <EmployeeToken
              placeholder="code"
              onEnterToken={(code) => {
                props.onEvent({
                  message: "HandleGetTreatmentTokenization",
                  params: {
                    code,
                    id: selectedRow?.id ? selectedRow?.index : 0,
                  },
                });
              }}
              onClearToken={() =>
                props.setProp("treatmentToken", {
                  ...props.treatmentToken,
                  token: "",
                  employeeName: "",
                  loading: false,
                  error: null,
                })
              }
              error={props?.treatmentToken?.error}
              loading={props?.treatmentToken?.loading}
              employeeName={props?.treatmentToken?.employeeName}
            />
          )
        }
        StartTime={
          <TimeComboBox
            defaultValue={startDatetime.time || ""}
            onTextChange={handleChangeTime("start")}
          />
        }
        FinishTime={
          <TimeComboBox
            defaultValue={endDatetime.time || ""}
            onTextChange={handleChangeTime("finish")}
          />
        }
        startDate={startDatetime.date}
        finishDate={endDatetime.date}
        OnaddPrincipal={() => {
          props.runSequence({
            sequence: "TreatmentResult",
            action: "addPrincipal",
            card: CARD_TREATMENT_RESULT,
          });

          setDataChanged(true);
        }}
        OnaddSecondary={() => {
          props.runSequence({
            sequence: "TreatmentResult",
            action: "addSecondary",
            card: CARD_TREATMENT_RESULT,
          });

          setDataChanged(true);
        }}
        buttonEditOrder={
          <ButtonLoadCheck
            // function
            id="btn-confirmMedOrder"
            setProp={props.setProp}
            onClick={handleSave}
            // data
            paramKey={`${CARD_TREATMENT_RESULT}_edit`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${CARD_TREATMENT_RESULT}_edit`]
            }
            disabled={props.isStudentUser && !dataChanged}
            // config
            color="yellow"
            title="Edit"
            style={{
              margin: "0px 5px",
              ...(!allowSaveOrder && {
                display: "none",
              }),
            }}
          />
        }
        buttonSaveOrder={
          <ButtonLoadCheck
            // function
            id="btn-confirmMedOrder"
            setProp={props.setProp}
            onClick={handleSave}
            // data
            paramKey={`${CARD_TREATMENT_RESULT}_Save`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${CARD_TREATMENT_RESULT}_Save`]
            }
            // config
            disabled={
              props.TreatmentResultList?.id &&
              props.isStudentUser &&
              dataChanged === false
            }
            color="blue"
            title="SAVE"
            style={{
              margin: "0px 5px",
              ...(!allowSaveOrder && {
                display: "none",
              }),
            }}
          />
        }
        buttonConfirmOrder={
          <ButtonLoadCheck
            // function
            id="btn-confirmMedOrder"
            setProp={props.setProp}
            onClick={() => {
              // selectedRow.
              props.isStudentUser &&
              selectedTreatment.order_by !== selectedTreatment.approved_by
                ? setOpenModAuthen({
                    open: true,
                    isApproveOrder: true,
                    type: "treatmentResult",
                  })
                : handleSave();
            }}
            // data
            paramKey={
              props.isStudentUser
                ? `${CARD_TREATMENT_RESULT}_treatmentResult`
                : `${CARD_TREATMENT_RESULT}_Save`
            }
            buttonLoadCheck={
              props.isStudentUser
                ? props.buttonLoadCheck?.[
                    `${CARD_TREATMENT_RESULT}_treatmentResult`
                  ]
                : props.buttonLoadCheck?.[`${CARD_TREATMENT_RESULT}_Save`]
            }
            // config
            disabled={
              props.TreatmentResultList?.item &&
              props.isStudentUser &&
              dataChanged === false
            }
            color="green"
            title="CONFIRM"
            style={{
              margin: "0px 5px",
              ...((!allowConfirmOrder || allowCancelOrder) && {
                display: "none",
              }),
            }}
          />
        }
        buttonCancelOrder={
          <ButtonLoadCheck
            // function
            id="btn-confirmMedOrder"
            setProp={props.setProp}
            onClick={() => setOpeModNote(true)}
            // data
            paramKey={`${CARD_TREATMENT_RESULT}_cancel`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${CARD_TREATMENT_RESULT}_cancel`]
            }
            // config
            color="red"
            title={intl.formatMessage({ id: "ยกเลิก PERFORM" })}
            style={{
              margin: "0px 5px",
              ...(!allowCancelOrder && {
                display: "none",
              }),
            }}
          />
        }
        StartDateElement={
          <DateTextBox
            onChange={handleChangeDate(`${props.treatmentRow?.index}.start`)}
            value={startDatetime?.date}
            inputStyle={{ width: "145px" }}
          ></DateTextBox>
        }
        FinishDateElement={
          <DateTextBox
            onChange={handleChangeDate(`${props.treatmentRow?.index}.finish`)}
            inputStyle={{ width: "145px" }}
            value={endDatetime?.date}
          ></DateTextBox>
        }
        languageUX={props.languageUX}
      />

      <Modal open={openModNote} size="large" onClose={handleCloseModNote}>
        <div style={{ display: "flex", padding: "10px" }}>
          <ErrorMessage error={props?.errorMessage?.[CARD_TREATMENT_RESULT]} />
        </div>
        <div
          style={{
            display: "flex",
            padding: "10px 10px 25px",
            alignItems: "center",
          }}
        >
          <label style={{ padding: "5px" }}> NOTE </label>
          <div style={{ flex: "1", marginLeft: "5px", marginRight: "5px" }}>
            <Input
              className={
                props.errorMessage?.[CARD_TREATMENT_RESULT] &&
                !props?.TreatmentResultSequence?.note
                  ? "error"
                  : ""
              }
              style={{ width: "100%" }}
              value={props?.TreatmentResultSequence?.note}
              onChange={handleChangeNote("note")}
            ></Input>
          </div>

          <Button
            color="green"
            onClick={() => {
              props.runSequence({
                sequence: "TreatmentResult",
                action: "Cancel",
                card: CARD_TREATMENT_RESULT,
                id: selectedRow?.id
                  ? TreatmentList?.[selectedRow?.index]?.id
                  : TreatmentList?.[0]?.id,
              });
            }}
          >
            ยืนยัน
          </Button>
        </div>
      </Modal>

      <ModInfo
        open={props?.TreatmentResultSequence?.isOpenError}
        titleColor="red"
        titleName={intl.formatMessage({ id: "บันทึกไม่สำเร็จ" })}
        onApprove={handleCloseModInfo}
        onClose={handleCloseModInfo}
      ></ModInfo>

      <ModInfo
        open={!!props.successMessage?.[CARD_TREATMENT_RESULT]}
        titleColor="green"
        titleName={intl.formatMessage({ id: "บันทึกสำเร็จ" })}
        onApprove={handleCloseMessage}
        onClose={handleCloseMessage}
      ></ModInfo>

      <ModInfo
        open={props?.TreatmentResultSequence?.isOpenErrorNote}
        titleColor={"red"}
        titleName={intl.formatMessage({ id: "ยกเลิก PERFORM ไม่สำเร็จ" })}
        onApprove={handleCloseModInfoNote}
        onClose={handleCloseModInfoNote}
      ></ModInfo>

      <ModAuthen
        titleName={intl.formatMessage({ id: "ยืนยันการตรวจสอบ" })}
        titleColor={"blue"}
        open={openModAuthen?.open}
        onDeny={() =>
          setOpenModAuthen({ open: false, isApproveOrder: false, type: "" })
        }
        onApprove={({ username, password }: any) => {
          // save
          handleSaveApprove({
            approved_by_username: username,
            approved_by_password: password,
            type: openModAuthen.type,
          });
          // setOpenModAuthen({ open: false, isApproveOrder: false, type: "" });
        }}
      />

      <ModError
        open={props.errorMessage?.["CardTreatmentResult_saveApprove"]}
        titleColor="red"
        onApprove={() => {
          props.setProp(
            `errorMessage.${"CardTreatmentResult_saveApprove"}`,
            null
          );
        }}
        alertText={
          props.errorMessage?.["CardTreatmentResult_saveApprove"]?.error
            ?.approved_by?.[0]
        }
      />
    </div>
  );
};

export default CardTreatmentResult;
