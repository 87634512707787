import React, {
  CSSProperties,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { Button, Modal } from "semantic-ui-react";

import { RowInfo } from "react-table-6";

// Common
import DateTextBox from "react-lib/apps/common/DateTextBox";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import { alignRight } from "react-lib/apps/common/PureReactTable";
import ButtonLoadCheck, { BLClickHandler } from "react-lib/appcon/common/ButtonLoadCheck";

// TPD
import CardPatientSearchBox from "../TPD/CardPatientSearchBox";
// ADM
import ModalSearchEncounter from "../ADM/ModalSearchEncounter";

import CardClaimInformationDetail from "./CardClaimInformationDetail";
import CardExportEClaimFile2UX from "./CardExportEClaimFile2UX";
import CardSaveRefNo from "./CardSaveRefNoUX";
import CardSearchInvoiceGroup from "./CardSearchInvoiceGroup";
import ModDownloadZipFile from "./ModDownloadZipFile";

// Interface
import {
  ACTIONS,
  BTN_ACTS,
  FilterType,
  MasterOptionsType,
  PickedProps,
  RunSequence,
  SetProp,
  State,
} from "./sequence/ExportEClaimFile";

// Utils
import { formatDatetime } from "react-lib/utils/dateUtils";
import { useIntl } from "react-intl";

type CardExportEClaimFileProps = {
  onEvent: (e: any) => Promise<any>;
  setProp: SetProp;
  // controller
  drugOrderQueueController: any;
  // seq
  runSequence: RunSequence;
  ExportEClaimFileSequence: State["ExportEClaimFileSequence"];
  SendClaimDownloadZipFileSequence: any;
  // CommonInterface
  masterData?: Record<string, any>;
  // options
  masterOptions?: MasterOptionsType;
} & PickedProps;

type Styles = Record<"input" | "modal", CSSProperties>;

const styles: Styles = {
  input: { width: "145px" },
  modal: { height: "calc(100dvh - 3.5rem)" },
};

const TREATMENT_TYPE_OPTIONS = [
  { key: 1, text: "IPD", value: "IPD" },
  { key: 2, text: "OPD", value: "OPD" },
];

export const CARD_EXPORT_E_CLAIM_FILE = "CardExportEClaimFile";

const CardExportEClaimFile = (props: CardExportEClaimFileProps) => {
  const intl = useIntl();
  const [openModSave, setOpenModSave] = useState<boolean>(false);
  const [refNo, setRefNo] = useState<string>("");
  const [openModDownloadZipFile, setOpenModDownloadZipFile] = useState<boolean>(false);

  const encounterRef = useRef<any>();

  // Effect
  useEffect(() => {
    props.runSequence({ sequence: "ExportEClaimFile", restart: true });
  }, []);

  // Callback
  const handleOpenClaimInfo = useCallback(
    (item: any) => (e: SyntheticEvent) => {
      e.stopPropagation();

      props.runSequence({ sequence: "ExportEClaimFile", action: ACTIONS.DESCRIPTION, data: item });
    },
    []
  );

  const formatPrice = useCallback((inputNumber?: number | string) => {
    const number = (inputNumber || "0").toString();

    return Number.parseFloat(number).toLocaleString("en-US", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      style: "decimal",
    });
  }, []);

  const handleGetTrProps = useCallback((state: any, rowInfo?: RowInfo) => {
    const data = rowInfo?.original?.row_style || {};

    return { style: { ...data, whiteSpace: "pre-line" } };
  }, []);

  const handleChangeRefNo = useCallback((e: any, data: any) => {
    setRefNo(data.value);
  }, []);

  const handleClickDownloadPreviousSendClaim = useCallback(() => {
    setOpenModDownloadZipFile(true);
  }, []);

  const handleCloseModDownloadZipFile = useCallback(() => {
    setOpenModDownloadZipFile(false);
  }, []);

  const handleCloseErrMsg = useCallback(() => {
    props.setProp(`errorMessage.${CARD_EXPORT_E_CLAIM_FILE}`, null);
  }, []);

  const handleSelectedAR = useCallback(
    (data: Record<string, any> | null) => {
      const seq = props.ExportEClaimFileSequence;

      const coveragePayer =
        data?.coverage_payer_sent_claim_group ||
        props.ExportEClaimFileSequence?.coveragePayerOptions?.[0]?.value ||
        "";

      props.setProp("ExportEClaimFileSequence", {
        ...seq,
        filter: {
          ...seq?.filter,
          arTransaction: data?.id || null,
          arTransactionData: data,
          coveragePayer,
        },
        ...(!data && { billTransactionList: [], billTransactionSummary: null }),
      });
    },
    [props.ExportEClaimFileSequence]
  );

  const handleChangeValue = useCallback(
    async (
      e: SyntheticEvent | null,
      data: { checked?: boolean; name: keyof FilterType; value: any }
    ) => {
      const seq = props.ExportEClaimFileSequence;
      const value = typeof data.checked === "boolean" ? data.checked : data.value;

      await props.setProp("ExportEClaimFileSequence.filter", {
        ...seq?.filter,
        [data.name]: value,
      });
    },
    [props.ExportEClaimFileSequence]
  );

  const handleChangeDate = (name: "endDate" | "startDate") => (value: string) => {
    handleChangeValue(null, { name, value });
  };

  const handleChangePatient = useCallback(
    async (id: any, hn: any) => {
      await handleChangeValue(null, { name: "hn", value: hn });

      handleChangeValue(null, { name: "patientId", value: id });
    },
    [handleChangeValue]
  );

  const handleSelectedEncounter = useCallback(
    (item: any) => {
      handleChangeValue(null, { name: "encounter", value: item.id });
    },
    [handleChangeValue]
  );

  const handleClearEncounter = useCallback(() => {
    handleChangeValue(null, { name: "encounter", value: "" });
  }, [handleChangeValue]);

  const handleOpenModSave = useCallback(() => {
    setOpenModSave(true);
  }, []);

  const handleCloseModSave = useCallback(() => {
    setOpenModSave(false);
    setRefNo("");
  }, []);

  const handleGetEncounterList = useCallback(
    async (params: any) =>
      props.onEvent({
        message: "GetEncounterList",
        params,
      }),
    []
  );

  const handleSearch: BLClickHandler<typeof ACTIONS.SEARCH> = useCallback((e, data) => {
    props.runSequence({
      sequence: "ExportEClaimFile",
      action: data.name,
      card: CARD_EXPORT_E_CLAIM_FILE,
    });
  }, []);

  const handlePrintDocument: BLClickHandler<typeof ACTIONS.PRINT_DOCUMENT> = useCallback((e, data) => {
    props.runSequence({
      sequence: "ExportEClaimFile",
      action: data.name,
      card: CARD_EXPORT_E_CLAIM_FILE,
    });
  }, []);

  const handleSave = useCallback(() => {
    props.runSequence({
      sequence: "ExportEClaimFile",
      action: ACTIONS.SAVE_REF_NO,
      card: CARD_EXPORT_E_CLAIM_FILE,
      refNo,
      onSuccess: handleCloseModSave,
    });
  }, [handleCloseModSave, refNo]);

  const handleCloseClaimInfo = useCallback(() => {
    props.setProp("ExportEClaimFileSequence.claimInfoDetail", null);
    props.setProp("userTokenize", null);
  }, []);

  const handleExportDataFile: BLClickHandler<typeof ACTIONS.EXPORT_DATA_16_FILE> = useCallback(
    (e, data) => {
      props.runSequence({
        sequence: "ExportEClaimFile",
        action: data.name,
        card: CARD_EXPORT_E_CLAIM_FILE,
      });
    },
    []
  );

  // Memo

  const filterEClaim = useMemo(
    () => props.ExportEClaimFileSequence?.filter,
    [props.ExportEClaimFileSequence?.filter]
  );

  const total = useMemo(() => {
    const summary = props.ExportEClaimFileSequence?.billTransactionSummary;

    return {
      otherPayPrice: formatPrice(summary?.total_other_pay_price),
      paidPrice: formatPrice(summary?.total_paid_price),
      sentClaimPrice: formatPrice(summary?.total_sent_claim_price),
    };
  }, [props.ExportEClaimFileSequence?.billTransactionSummary]);

  const billTransactionItems = useMemo(
    () =>
      (props.ExportEClaimFileSequence?.billTransactionList || []).map((item) => ({
        ...item,
        amount: alignRight(formatPrice(item.amount)),
        claim_amount: alignRight(formatPrice(item.claim_amount)),
        description: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button color="orange" size="mini" onClick={handleOpenClaimInfo(item)}>
              รายละเอียด
            </Button>
          </div>
        ),
        dt_tran: formatDatetime(item.dt_tran, true),
        paid: alignRight(formatPrice(item.paid)),
      })),
    [handleOpenClaimInfo, props.ExportEClaimFileSequence?.billTransactionList]
  );

  const searchInvoiceGroup = useMemo(
    () => (
      <CardSearchInvoiceGroup
        onEvent={props.onEvent}
        setProp={props.setProp}
        // config
        isEClaim
        buttonLoadCheck={props.buttonLoadCheck}
        // callback
        onSelected={handleSelectedAR}
        languageUX={props.languageUX}
      />
    ),
    [handleSelectedAR, props.buttonLoadCheck]
  );

  const startDate = useMemo(
    () => (
      <DateTextBox
        disabled={!filterEClaim?.isSpecifyDate}
        inputStyle={styles.input}
        value={filterEClaim?.startDate}
        fluid
        onChange={handleChangeDate("startDate")}
      />
    ),
    [filterEClaim, handleChangeDate]
  );

  const endDate = useMemo(
    () => (
      <DateTextBox
        disabled={!filterEClaim?.isSpecifyDate}
        inputStyle={styles.input}
        value={filterEClaim?.endDate}
        fluid
        onChange={handleChangeDate("endDate")}
      />
    ),
    [filterEClaim, handleChangeDate]
  );

  const patientSearchBox = useMemo(
    () => (
      <CardPatientSearchBox
        controller={props.drugOrderQueueController}
        disabled={!filterEClaim?.isHN}
        onEnterPatientSearch={handleChangePatient}
        languageUX={props.languageUX}
      />
    ),
    [filterEClaim, handleChangePatient, props.drugOrderQueueController]
  );

  const searchEncounter = useMemo(
    () => (
      <ModalSearchEncounter
        key="searchBoxEncounter"
        ref={encounterRef}
        disabledSearch={!filterEClaim?.isEncounter}
        patientId={filterEClaim?.patientId as any}
        textField="id"
        fluid
        readOnly
        onClearInput={handleClearEncounter}
        onGetEncounter={handleGetEncounterList}
        onSelect={handleSelectedEncounter}
      />
    ),
    [filterEClaim, handleClearEncounter, handleGetEncounterList, handleSelectedEncounter]
  );

  const buttonSearch = useMemo(
    () => (
      <ButtonLoadCheck
        setProp={props.setProp}
        color={"blue"}
        name={ACTIONS.SEARCH}
        paramKey={BTN_ACTS.SEARCH}
        size="small"
        title={intl.formatMessage({ id: "ค้นหา" })}
        fluid
        buttonLoadCheck={props.buttonLoadCheck?.[BTN_ACTS.SEARCH]}
        // callback
        onClick={handleSearch}
      />
    ),
    [handleSearch, props.buttonLoadCheck]
  );

  const buttonExportData = useMemo(
    () => (
      <ButtonLoadCheck
        setProp={props.setProp}
        color={"blue"}
        disabled={!props.ExportEClaimFileSequence?.filter?.arTransaction}
        name={ACTIONS.EXPORT_DATA_16_FILE}
        paramKey={BTN_ACTS.EXPORT_DATA_16_FILE}
        size="small"
        title={intl.formatMessage({ id: "Export data 16 แฟ้ม" })}
        fluid
        buttonLoadCheck={props.buttonLoadCheck?.[BTN_ACTS.EXPORT_DATA_16_FILE]}
        // callback
        onClick={handleExportDataFile}
      />
    ),
    [handleExportDataFile, props.buttonLoadCheck]
  );

  const buttonPrintDocument = useMemo(
    () => (
      <ButtonLoadCheck
        setProp={props.setProp}
        color={"teal"}
        name={ACTIONS.PRINT_DOCUMENT}
        paramKey={BTN_ACTS.PRINT_DOCUMENT}
        size="small"
        title={intl.formatMessage({ id: "ดาวน์โหลด xlsx." })}
        fluid
        buttonLoadCheck={props.buttonLoadCheck?.[BTN_ACTS.PRINT_DOCUMENT]}
        // callback
        onClick={handlePrintDocument}
      />
    ),
    [handlePrintDocument, props.buttonLoadCheck]
  );

  const buttonSave = useMemo(
    () => (
      <ButtonLoadCheck
        setProp={props.setProp}
        color={"green"}
        name={BTN_ACTS.SAVE_REF_NO}
        paramKey={BTN_ACTS.SAVE_REF_NO}
        size="medium"
        title={intl.formatMessage({ id: "บันทึก" })}
        buttonLoadCheck={props.buttonLoadCheck?.[BTN_ACTS.SAVE_REF_NO]}
        // callback
        onClick={handleSave}
      />
    ),
    [handleSave, props.buttonLoadCheck]
  );

  return (
    <div style={{ height: "100%", overflow: "auto" }}>
      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage?.[CARD_EXPORT_E_CLAIM_FILE]}
        success={null}
        onClose={handleCloseErrMsg}
        languageUX={props.languageUX}
      />

      <CardExportEClaimFile2UX
        // data
        billTransactionList={billTransactionItems}
        buttonExportData={buttonExportData}
        buttonPrintDocument={buttonPrintDocument}
        buttonSearch={buttonSearch}
        disabledSave={billTransactionItems.length === 0}
        endDate={endDate}
        filter={filterEClaim}
        patientSearchBox={patientSearchBox}
        searchEncounter={searchEncounter}
        searchInvoiceGroup={searchInvoiceGroup}
        startDate={startDate}
        totalOtherPayPrice={total.otherPayPrice}
        totalPaidPrice={total.paidPrice}
        totalRows={props.ExportEClaimFileSequence?.billTransactionSummary?.total_rows}
        totalSentClaimPrice={total.sentClaimPrice}
        // options
        coveragePayerOptions={props.ExportEClaimFileSequence?.coveragePayerOptions}
        sentClaimChoiceOptions={props.masterOptions?.sentClaimChoice}
        treatmentTypeOptions={TREATMENT_TYPE_OPTIONS}
        // callback
        getTdProps={handleGetTrProps}
        onChangeValue={handleChangeValue}
        onClickDownloadPreviousSendClaim={handleClickDownloadPreviousSendClaim}
        onExportDataFile={handleExportDataFile}
        onSave={handleOpenModSave}
        languageUX={props.languageUX}
      />

      <Modal open={openModSave} closeOnDimmerClick onClose={handleCloseModSave}>
        <CardSaveRefNo
          // data
          buttonSave={buttonSave}
          createAt={filterEClaim?.arTransactionData?.created_at}
          lotNo={filterEClaim?.arTransactionData?.lot_no}
          refNo={refNo}
          // callback
          onCancel={handleCloseModSave}
          onChangeRefNo={handleChangeRefNo}
          onClose={handleCloseModSave}
          languageUX={props.languageUX}
        />
      </Modal>

      <Modal
        open={!!props.ExportEClaimFileSequence?.claimInfoDetail}
        size="fullscreen"
        style={styles.modal}
        closeOnDimmerClick
        onClose={handleCloseClaimInfo}
      >
        <CardClaimInformationDetail
          onEvent={props.onEvent}
          setProp={props.setProp}
          // seq
          runSequence={props.runSequence}
          // data
          claimInfoDetail={props.ExportEClaimFileSequence?.claimInfoDetail}
          // CommonInterface
          buttonLoadCheck={props.buttonLoadCheck}
          masterData={props.masterData}
          userTokenize={props.userTokenize}
          // options
          masterOptions={props.masterOptions}
          // callback
          onClose={handleCloseClaimInfo}
          languageUX={props.languageUX}
        />
      </Modal>

      <ModDownloadZipFile
        onEvent={props.onEvent}
        setProp={props.setProp as any}
        // seq
        runSequence={props.runSequence as any}
        SendClaimDownloadZipFileSequence={props.SendClaimDownloadZipFileSequence}
        // data
        arTransaction={filterEClaim?.arTransactionData}
        open={openModDownloadZipFile}
        type="E-CLAIM"
        // CommonInterface
        buttonLoadCheck={props.buttonLoadCheck}
        errorMessage={props.errorMessage}
        // callback
        onClose={handleCloseModDownloadZipFile}
        languageUX={props.languageUX}
      />
    </div>
  );
};

CardExportEClaimFile.displayName = "CardExportEClaimFile";

export default React.memo(CardExportEClaimFile);
