import _ from 'lodash';
import axios from 'axios';
import Qs from 'qs';

class BaseService {
  static _interceptors = new Map();

  static addInterceptor(interceptor, callback) {
    BaseService._interceptors.set(interceptor, callback);
  }
  
  static removeInterceptor(interceptor) {
    BaseService._interceptors.delete(interceptor);
  }

  static _dispatchError(errorMessage) {
    BaseService._interceptors.forEach(callback => {
      callback(errorMessage);
    });
  }

  constructor() {
    console.log('Old basEService Init \n Should not see me, You are using duplicate Base obsolete version, \n you use wrong directory path')
    this.client = axios.create({
      paramsSerializer: params => Qs.stringify(params, {arrayFormat: 'repeat'}),
      withCredentials: true 
    })

    this.client.interceptors.request.use(this._interceptRequest, error => Promise.reject(error));
    // intercept a response error (500) and error
    this.client.interceptors.response.use(res => res, this._handleError);
  }

  _interceptRequest(config) {
    // intercept a request to handle pagination param
    if (config.params && !_.has(config.params, 'pagination_class')) {
      config.params.pagination_class = 'page_number';
    }
    // intercept a request to handle a csrf token
    config.xsrfCookieName = 'csrftoken';
    config.xsrfHeaderName = 'X-CSRFToken';
    return config;
  }

  _readBlobAsText(blob) {
    return new Promise(res => {
      const reader = new FileReader();
      reader.onload = () => res(reader.result);
      reader.readAsText(blob);
    })
  }

  _handleError = async (err) => {
    if (err.response) {
      try {
        if (err.response.data instanceof Blob) {
          err.response.data = await this._readBlobAsText(err.response.data);
        }
      } catch (catchError) {
        throw catchError;
      }
    }
    if (err.response && [500, 503].includes(err.response.status)) {
      let data = err.response.data;
      let one = data.indexOf('Installed Applications:');
      let two = data.indexOf('Traceback:');
      let three = data.indexOf('META:');

      const message = `
          Internal Server Error ${err.response.status}\n
          ${data.substring(0, one)}\n
          ${data.substring(two, three)}
      `;
      BaseService._dispatchError(message.replace(/\r\n|\n|\r/gm, '\n'));
    }
    if (!err.response && err.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser
      // patch response.data to the error object
      const message = 'Network Error: Maybe backend did not respond';
      err.response = { data: message };
      BaseService._dispatchError(message);
    }
    if (!err.response && !err.request) {
      // Something happened in setting up the request that triggered an Error
      err.response = { data: err.message };
    }
    return Promise.reject(err);
  }
  
  // used in subclass
  throwErrorMessage(error) {
    throw error.response.data;
  }
}

export default BaseService;
