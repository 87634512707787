import React, { useEffect, useMemo, useState } from "react";
import { Accordion, Dropdown, Form, Icon, TextArea } from "semantic-ui-react";
// UI Common
import SnackMessage from "react-lib/apps/common/SnackMessage";
// UI
import CardPhysicalExamUX from "./CardPhysicalExamUX";
import { useIntl } from "react-intl";

const CARD_KEY: string = "CardPhysicalExam";

type CardPhysicalExamProps = {
  // function
  onEvent: any;
  setProp: any;

  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;

  // seq
  runSequence?: any;
  PhysicalExamSequence?: any;

  // data
  emrId?: number;
  physicalOrganList?: any[];
  physicalTemplateList?: any[];
  patientExamOther?: any;
  isCheckout?: boolean;
};

const OrganComponent = (props: any) => {
  const intl = useIntl();
  return (
    <Form>
      {props.organList?.map((organ: any, index: number) => {
        let optionList: any[] = (organ?.test_result_option_list || [])?.map(
          (item: any, idx: number) => {
            return { key: idx, value: item.trim(), text: item };
          }
        );
        optionList.push({ key: "N/A", value: "N/A", text: "N/A" });

        return (
          <div key={index}>
            <Form.Group>
              <Form.Field width={3}>
                <label>{organ.organ_name}</label>
              </Form.Field>
              <Form.Field width={13}>
                <Dropdown
                  clearable
                  fluid
                  search
                  selection
                  disabled={props.isCheckout}
                  name="result"
                  options={optionList}
                  value={organ.result}
                  onChange={(_e: any, data: any) =>
                    props.onChangeOrganData(organ, data)
                  }
                ></Dropdown>
              </Form.Field>
            </Form.Group>
            <Form.Group
              style={{
                display: ["", "N/A", undefined].includes(organ.result)
                  ? "none"
                  : "",
              }}
            >
              <Form.Field width={3}></Form.Field>
              <Form.Field width={13}>
                <TextArea
                  rows={1}
                  disabled={props.isCheckout}
                  placeholder={intl.formatMessage({ id: "ระบุรายละเอียดเพิ่มเติม" })}
                  name="description"
                  value={organ.description}
                  onChange={(_e: any, data: any) =>
                    props.onChangeOrganData(organ, data)
                  }
                ></TextArea>
              </Form.Field>
            </Form.Group>
          </div>
        );
      })}
    </Form>
  );
};

const CardPhysicalExam = (props: CardPhysicalExamProps) => {
  const intl = useIntl();
  const [acc, setAcc] = useState<any[]>([0]);
  const [selectedTemplateId, setSelectedTemplateId] = useState<any[]>([]);

  useEffect(() => {
    let result: any[] = [];
    props.physicalOrganList?.map((item: any) => {
      result.push({
        ...item,
        organ: item.organ_id,
        result: item.result || "",
        description: item.description || "",
        emr: props.emrId,
      });
    });
    props.setProp("PhysicalExamSequence.organList", result);
  }, [props.physicalOrganList]);

  useEffect(() => {
    if (props.physicalTemplateList?.length > 0) {
      if (
        props.physicalTemplateList?.length === 1 &&
        props.physicalTemplateList?.[0] === false
      ) {
        props.runSequence({
          sequence: "PhysicalExam",
          action: "GET_TEMPLATE",
          templates: [],
          emr: props.emrId,
        });
      } else {
        props.runSequence({
          sequence: "PhysicalExam",
          action: "GET_TEMPLATE",
          templates: props.physicalTemplateList,
          emr: props.emrId,
        });
        const result: any[] = props.physicalTemplateList?.map((item: any) => {
          return item.id;
        });
        setSelectedTemplateId(result);

        setAcc([...Array(result.length).keys()]);
      }
    }
  }, [props.physicalTemplateList]);

  useEffect(() => {
    props.setProp("PhysicalExamSequence.otherExam", {
      ...props.patientExamOther,
      emr: props.emrId,
    });
  }, [props.patientExamOther]);

  const templateOptions = useMemo(() => {
    let result = props.PhysicalExamSequence?.templateList?.map((item: any) => {
      return {
        key: item.id,
        value: item.id,
        text: item.template_name,
        data: item,
      };
    });
    return result;
  }, [props.PhysicalExamSequence?.templateList]);

  // Organ
  const handleChangeOrganData = (organ: any, data: any) => {
    props.runSequence({
      sequence: "PhysicalExam",
      action: "SET_EXAM",
      organ: organ,
      data: data,
    });
  };

  // Other Detail
  const handleChangeOtherDetail = (_event: any, data: any) => {
    props.setProp("PhysicalExamSequence.otherExam.other_detail", data.value);
  };

  // Template
  const handleChangeTemplateOptions = (_event: any, data: any) => {
    setSelectedTemplateId(data.value);
    setAcc([...Array(data.value.length).keys()]);

    const tempSel: any[] = props.PhysicalExamSequence.templateList
      ?.map((item: any) => {
        if (data.value.includes(item.id)) {
          return { ...item, emr: props.emrId };
        } else {
          return null;
        }
      })
      .filter((item: any) => item);
    props.setProp("PhysicalExamSequence.selectedTemplateList", tempSel);
  };

  const handleAccordionClick = (_event: any, data: any) => {
    let result = [...acc];
    if (acc.includes(data.index)) {
      const index = result.indexOf(data.index);
      result.splice(index, 1);
    } else {
      result.push(data.index);
    }
    setAcc(result);
  };

  const handleChangeTemplateOrgan = (organ: any, data: any) => {
    props.runSequence({
      sequence: "PhysicalExam",
      action: "SET_EXAM",
      organ: organ,
      data: data,
    });
  };

  return (
    <>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_KEY}`, null);
          props.setProp(`successMessage.${CARD_KEY}`, null);
        }}
        error={props.errorMessage?.[CARD_KEY]}
        success={props.successMessage?.[CARD_KEY]}
        languageUX={props.languageUX}
      />

      <CardPhysicalExamUX
        // component
        organList={
          <OrganComponent
            organList={props.PhysicalExamSequence?.organList}
            isCheckout={props.isCheckout}
            onChangeOrganData={handleChangeOrganData}
          />
        }
        templateDropdown={
          <Dropdown
            clearable={true}
            disabled={props.isCheckout}
            fluid={true}
            multiple={true}
            placeholder={intl.formatMessage({ id: "เลือก Template การตรวจร่างกาย" })}
            onChange={handleChangeTemplateOptions}
            options={templateOptions || []}
            search={true}
            selection={true}
            value={selectedTemplateId}
          ></Dropdown>
        }
        templateItem={
          props.PhysicalExamSequence?.selectedTemplateList?.length > 0 && (
            <Accordion fluid defaultActiveIndex={acc} exclusive={false}>
              {props.PhysicalExamSequence?.selectedTemplateList?.map(
                (template: any, index: number) => {
                  return (
                    <div key={index}>
                      <Accordion.Title
                        active={acc.includes(index)}
                        index={index}
                        style={{ background: "#D6ECF3", color: "#0072BC" }}
                        onClick={handleAccordionClick}
                      >
                        <Icon name="dropdown" />
                        {template.template_name}
                      </Accordion.Title>
                      <Accordion.Content
                        active={acc.includes(index)}
                        style={{ padding: "20px 30px" }}
                      >
                        <OrganComponent
                          organList={template?.items}
                          isCheckout={props.isCheckout}
                          onChangeOrganData={handleChangeTemplateOrgan}
                        />
                      </Accordion.Content>
                    </div>
                  );
                }
              )}
            </Accordion>
          )
        }
        otherDetailText={
          <TextArea
            disabled={props.isCheckout}
            placeholder={intl.formatMessage({ id: "ระบุข้อมูลการตรวจร่างกายอื่นๆ" })}
            name="other_detail"
            onChange={handleChangeOtherDetail}
            rows={3}
            style={{ margin: "20px 0px" }}
            value={props.PhysicalExamSequence?.otherExam?.other_detail || ""}
          />
        }
        languageUX={props.languageUX}
      />
    </>
  );
};

export default React.memo(CardPhysicalExam);
