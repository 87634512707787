import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Checkbox,
  Label,
  Button,
  Dropdown
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardReserveTransferQueueUX = (props: any) => {
    return(
      <div>
        <div
          className="accordion ui fluid blue raised segment"
          style={{boxShadow: "none"}}>
          
          <div
            style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
            
            <label
              style={{fontSize: "1.25rem", fontWeight: "bold"}}>
              คิวย้าย Ward
            </label>
            <div
              style={{display: "flex", alignItems: "center"}}>
              
            </div>
          </div>
          <div
            className="ui divider">
            
          </div>
          <div
            className="ui form small">
            
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={2}>
                <Checkbox
                  checked={props.isHN}
                  label="HN"
                  name="isHN"
                  onChange={props.onChangeValue}>
                </Checkbox>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <div
                  style={{width: "100%"}}>
                  {props.PatientSearchBox}
                </div>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <Label
                  className="large fluid">
                  {props.patientFullName || "-"}
                </Label>
              </FormField>
              <FormField
                inline={true}
                width={1}>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <Checkbox
                  checked={props.isTransferDate}
                  label="ช่วงวันที่ต้องการย้าย"
                  name="isTransferDate"
                  onChange={props.onChangeValue}>
                </Checkbox>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <div
                  style={{width: "100%"}}>
                  {props.TransferStartDate}
                </div>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <div
                  style={{width: "100%"}}>
                  {props.TransferEndDate}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={2}>
                <Checkbox
                  checked={props.isTransferStatus}
                  label="สถานะการย้าย"
                  name="isTransferStatus"
                  onChange={props.onChangeValue}>
                </Checkbox>
              </FormField>
              <FormField
                inline={true}
                width={6}>
                <Dropdown
                  name="transferStatus"
                  onChange={props.onChangeValue}
                  options={props.transferStatusOptions || []}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.transferStatus || ""}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}
                width={1}>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <Checkbox
                  checked={props.isUrgency}
                  label="ความเร่งด่วน"
                  name="isUrgency"
                  onChange={props.onChangeValue}>
                </Checkbox>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <Dropdown
                  name="urgency"
                  onChange={props.onChangeValue}
                  options={props.transferUrgencyOptions || []}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.urgency || ""}>
                </Dropdown>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={15}>
              </FormField>
              <FormField
                inline={true}
                width={1}>
                <Button
                  className="fluid"
                  color={"green"}
                  icon={true}
                  onClick={props.onSearch}
                  size={"small"}>
                  ค้นหา
                </Button>
              </FormField>
            </FormGroup>
            <div>
              
              <Table
                data={props.reserveTransferQueueList}
                getTrProps={props.getTrProps}
                headers="HN, ข้อมูลผู้ป่วย, ห้องปัจจุบัน,วันที่ต้องการย้าย,ประเภท,ความเร่งด่วน, สถานะการจอง"
                keys="hn,patient_name,current_room_no,plan_transfer_date,division,urgency_name,status_label"
                showPagination={false}
                style={{height: "250px"}}>
              </Table>
            </div>
          </div>
        </div>
      </div>
    )
}


export default CardReserveTransferQueueUX

export const screenPropsDefault = {}

/* Date Time : Mon Mar 18 2024 15:47:01 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "accordion ui fluid blue raised segment"
        },
        "style": {
          "type": "code",
          "value": "{boxShadow: \"none\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form small"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "FormGroup",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "Checkbox",
      "parent": 6,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isHN"
        },
        "label": {
          "type": "value",
          "value": "HN"
        },
        "name": {
          "type": "value",
          "value": "isHN"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "code",
          "value": "props.PatientSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "Label",
      "parent": 8,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientFullName || \"-\""
        },
        "className": {
          "type": "value",
          "value": "large fluid"
        },
        "name": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "Checkbox",
      "parent": 14,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isTransferDate"
        },
        "label": {
          "type": "value",
          "value": "ช่วงวันที่ต้องการย้าย"
        },
        "name": {
          "type": "value",
          "value": "isTransferDate"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "code",
          "value": "props.TransferStartDate"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "code",
          "value": "props.TransferEndDate"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "FormGroup",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "FormField",
      "parent": 20,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "FormField",
      "parent": 20,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "FormField",
      "parent": 20,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "FormField",
      "parent": 20,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "FormField",
      "parent": 20,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "Checkbox",
      "parent": 21,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isTransferStatus"
        },
        "label": {
          "type": "value",
          "value": "สถานะการย้าย"
        },
        "name": {
          "type": "value",
          "value": "isTransferStatus"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "Checkbox",
      "parent": 25,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isUrgency"
        },
        "label": {
          "type": "value",
          "value": "ความเร่งด่วน"
        },
        "name": {
          "type": "value",
          "value": "isUrgency"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "FormGroup",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "FormField",
      "parent": 34,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "15"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "FormField",
      "parent": 34,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "Button",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "className": {
          "type": "value",
          "value": "fluid"
        },
        "color": {
          "type": "code",
          "value": "\"green\""
        },
        "icon": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSearch"
        },
        "size": {
          "type": "code",
          "value": "\"small\""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 51,
      "name": "Table",
      "parent": 50,
      "props": {
        "data": {
          "type": "code",
          "value": "props.reserveTransferQueueList"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getTrProps"
        },
        "headers": {
          "type": "value",
          "value": "HN, ข้อมูลผู้ป่วย, ห้องปัจจุบัน,วันที่ต้องการย้าย,ประเภท,ความเร่งด่วน, สถานะการจอง"
        },
        "keys": {
          "type": "value",
          "value": "hn,patient_name,current_room_no,plan_transfer_date,division,urgency_name,status_label"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"250px\"}"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "label",
      "parent": 52,
      "props": {
        "children": {
          "type": "value",
          "value": "คิวย้าย Ward"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.25rem\", fontWeight: \"bold\"}"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "div",
      "parent": 52,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "Dropdown",
      "parent": 22,
      "props": {
        "name": {
          "type": "value",
          "value": "transferStatus"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.transferStatusOptions || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.transferStatus || \"\""
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "Dropdown",
      "parent": 26,
      "props": {
        "name": {
          "type": "value",
          "value": "urgency"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.transferUrgencyOptions || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.urgency || \"\""
        }
      },
      "seq": 58,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 70,
  "isMounted": false,
  "memo": false,
  "name": "CardReserveTransferQueueUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 65
}

*********************************************************************************** */
