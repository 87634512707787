import React, {
  useRef,
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import PropTypes from "prop-types";
import {
  Button,
  Grid,
  Menu
} from "semantic-ui-react";
import ChatTable from "react-lib/apps/IsHealth/Chat/ChatTable";
import REGChatTable from "react-lib/apps/IsHealth/REG/REGChatTable";
import AppointmentChatTable from "./AppointmentChatTable";

import { Route } from "react-router-dom";
import { useHistory } from "react-router-dom";
import TimeNurse from "react-lib/apps/QUE/TimeNurse";
import DoctorScheduleView from "react-lib/apps/QUE/DoctorScheduleView";
import ModSelectAppointment from "react-lib/apps/IsHealth/Common/ModSelectAppointment";
import BindHNPage from "react-lib/apps/IsHealth/REG/BindHNPage";
import Dashboard from "react-lib/apps/IsHealth/Dashboard/Dashboard";
import MainRuleList from "react-lib/apps/IsHealth/Studio/MainRuleList";
import VaccineUpload from "./VaccineUpload";
import DiagComposer from "react-lib/apps/IsHealth/Studio/DiagComposer";
import FormAnalytics from "./FormAnalytics";
import Settings from "./Settings";
import QAChatTable from "react-lib/apps/IsHealth/Platform/QAChatTable";
import CONFIG from "../config/config";

import AppointmentDashboard from "react-lib/apps/IsHealth/APP/AppointmentDashboard";
import DoctorSchedule from "./DoctorSchedule";
import { useIntl } from "react-intl";

const PAGE = {
  CHAT: "/Chat",
  SCHEDULE: "/Schedule",
  MY_SCHEDULE: "/MySchedule",
  DASHBOARD: "/Dashboard",
  APPOINTMENT_CHAT: "/Appointment",
  APPOINTMENT_DASHBOARD: "/AppointmentDashboard",
  DOCTOR_SCHEDULE: "/DoctorSchedule",
  MESSAGES: "/messages",
  RESOLVE_CHAT: "/resolve-chat",
  FORM_ANALYTICS: "/FormAnalytics",
  STUDIO: "/Studio",
  VACCINE_UPLOAD: "/VaccineUpload",
  CREATE_OA: "/CreateOA",
  SETTINGS: "/Settings",
  MANAGE_SCHEDULE: "/ManageSchedule",
  CLASSIFY_CHAT: "/ClassifyChat",
  COMPOSE: "/composer",
  BIND_HN: "bind-hn",
  QUEUE: "Queue",
  AIRFLOW_CHAT: "/IsolationChat",
  USER_LIST_CHAT: "/UserChat"
};

const MainScreen = forwardRef((props, ref) => {
  const [currentPage, setCurrentPage] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [openModChooseAppointment, setOpenModChooseAppointment] =
    useState(false);
  const [patientAppointmentId, setPatientAppointmentId] = useState(null);

  const chatTable = useRef();
  const qaChatTable = useRef();
  const appointmentTable = useRef();
  const dashboardRef = useRef();
  const settingsRef = useRef();
  const history = useHistory();
  const isMounted = useRef(true);

  useImperativeHandle(ref, () => ({
    chatTable: chatTable.current,
    qaChatTable: qaChatTable.current,
    appointmentTable: appointmentTable.current,
    dashboard: dashboardRef.current,
    settings: settingsRef.current,
  }));

  useEffect(() => {
    console.log("useEffect MainScreen ", props, history.location);
    if (history.location.pathname.includes(PAGE.CLASSIFY_CHAT)) {
      setCurrentPage(PAGE.CLASSIFY_CHAT);
    } else if (history.location.pathname.includes(PAGE.AIRFLOW_CHAT)) {
      setCurrentPage(PAGE.AIRFLOW_CHAT);
    } else if (history.location.pathname.includes(PAGE.USER_LIST_CHAT)) {
      setCurrentPage(PAGE.USER_LIST_CHAT);
    } else if (history.location.pathname.includes(PAGE.CHAT)) {
      setCurrentPage(PAGE.CHAT);
    } else if (history.location.pathname.includes(PAGE.SCHEDULE)) {
      setCurrentPage(PAGE.SCHEDULE);
    } else if (history.location.pathname.includes(PAGE.MY_SCHEDULE)) {
      setCurrentPage(PAGE.MY_SCHEDULE);
    } else if (history.location.pathname.includes(PAGE.DASHBOARD)) {
      setCurrentPage(PAGE.DASHBOARD);
    } else if (history.location.pathname.includes(PAGE.APPOINTMENT_DASHBOARD)) {
      setCurrentPage(PAGE.APPOINTMENT_DASHBOARD);
    } else if (history.location.pathname.includes(PAGE.DOCTOR_SCHEDULE)) {
      setCurrentPage(PAGE.SCHEDULE);
    } else if (history.location.pathname.includes(PAGE.MESSAGES)) {
      setCurrentPage(PAGE.MESSAGES);
    } else if (history.location.pathname.includes(PAGE.APPOINTMENT_CHAT)) {
      setCurrentPage(PAGE.APPOINTMENT_CHAT);
    } else if (history.location.pathname.includes(PAGE.FORM_ANALYTICS)) {
      setCurrentPage(PAGE.FORM_ANALYTICS);
    } else if (history.location.pathname.includes(PAGE.SETTINGS)) {
      setCurrentPage(PAGE.SETTINGS);
    } else if (history.location.pathname.includes(PAGE.STUDIO)) {
      setCurrentPage(PAGE.STUDIO);
    } else if (history.location.pathname.includes(PAGE.VACCINE_UPLOAD)) {
      setCurrentPage(PAGE.VACCINE_UPLOAD);
    } else if (history.location.pathname.includes(PAGE.MANAGE_SCHEDULE)) {
      setCurrentPage(PAGE.MANAGE_SCHEDULE);
    } else if (history.location.pathname.includes(PAGE.COMPOSE)) {
      setCurrentPage(PAGE.COMPOSE);
    } else if (history.location.pathname.includes(PAGE.BIND_HN)) {
      setCurrentPage(PAGE.BIND_HN);
    } else if (history.location.pathname.includes(PAGE.QUEUE)) {
      setCurrentPage(PAGE.QUEUE);
    } else {
      setCurrentPage("");
      console.log("end of else !111 2MainScreen", history.location.pathname);
      history.push("/Chat/");
    }
  }, [history.location]);

  useEffect(() => {
    if (currentPage !== PAGE.DASHBOARD) {
      props.onReconnectWs();
    }
  }, [currentPage]);

  useEffect(() => {
    props.onGetDivision();
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleCreateAppointment = ({ contentPayload, appointmentId } = {}) => {
    let id = contentPayload
      ? contentPayload.patient_appointment
      : appointmentId;
    setPatientAppointmentId(id);
    setOpenModChooseAppointment(true);
  };

  const handleNavigationMessage = ({
    content = "",
    contentPayload = null,
  } = {}) => {
    if (content.includes("{ตารางออกตรวจผู้ให้คำปรึกษา}")) {
      let contentArr = content.split("{ตารางออกตรวจผู้ให้คำปรึกษา}");
      return (
        <div>
          {contentArr.map((item, index) => {
            if (index !== contentArr.length - 1) {
              return (
                <>
                  {item}
                  <span
                    className="textButton"
                    onClick={() => handleCreateAppointment({ contentPayload })}
                  >
                    ตารางออกตรวจผู้ให้คำปรึกษา
                  </span>
                </>
              );
            }
          })}
        </div>
      );
    } else {
      return content;
    }
  };

  return (
    <div className="MainScreen">
      <ModSelectAppointment
        controller={props.queueController}
        open={openModChooseAppointment}
        onClose={() => {
          setPatientAppointmentId(null);
          setOpenModChooseAppointment(false);
        }}
        patientAppointmentId={patientAppointmentId}
        division={props.division}
        languageUX={props.languageUX}
      />
      <Menu secondary className="topbar-menu">
        {props.getLeftMenuItem()}
        {props.getDivisionMenuItem()}
        {props.getRightMenuItem()}
      </Menu>
      <Grid className="left-side-menu">
        {!CONFIG.HIDE_SIDEBAR && (
          <Grid.Column className="tab">
            {CONFIG.DASHBOARD && (
              <Button
                className="tabButton"
                icon="pie chart"
                active={currentPage === PAGE.DASHBOARD}
                onClick={() => history.push("/Dashboard/")}
              />
            )}
            {
              CONFIG.ENCOUNTER_CHAT && (
                <Button
                  className="tabButton"
                  icon="chat"
                  active={currentPage === PAGE.CHAT}
                  onClick={() => history.push("/Chat/")}
                />
              )
            }
            {CONFIG.CLASSIFY_CHAT && (
              <Button
                className="tabButton"
                icon="comment alternate outline"
                active={currentPage === PAGE.CLASSIFY_CHAT}
                onClick={() => history.push("/ClassifyChat/")}
              />
            )}
            {CONFIG.USER_LIST_CHAT && (
              <Button
                className="tabButton"
                icon="comments"
                active={currentPage === PAGE.USER_LIST_CHAT}
                onClick={() => history.push("/UserChat/")}
              />
            )}
            {CONFIG.AIRFLOW_CHAT && (
              <Button
                className="tabButton"
                icon="comment alternate"
                active={currentPage === PAGE.AIRFLOW_CHAT}
                onClick={() => history.push("/IsolationChat/")}
              />
            )}
            {CONFIG.SHOW_BIND_HN && (
              <Button
                className="tabButton"
                icon="address book"
                active={currentPage === PAGE.BIND_HN}
                onClick={() => history.push("/bind-hns/")}
              />
            )}
            {CONFIG.APPOINTMENT_CHAT_FUNCTION && (
              <Button
                className="tabButton"
                icon="calendar check"
                active={currentPage === PAGE.APPOINTMENT_CHAT}
                onClick={() => history.push("/Appointment/")}
              />
            )}

            {/* {config.BOOKING_FUNCTION && (
              <Button
                className="tabButton"
                icon="calendar"
                active={currentPage === PAGE.MY_SCHEDULE}
                onClick={() => history.push("/MySchedule/")}
              />
            )} */}
            {CONFIG.BOOKING_FUNCTION && (
              <Button
                className="tabButton"
                icon="users"
                active={currentPage === PAGE.SCHEDULE}
                onClick={() => history.push("/Schedule/")}
              />
            )}
            {CONFIG.BOOKING_FUNCTION && (
              <Button
                className="tabButton"
                icon="users"
                active={currentPage === PAGE.QUEUE}
                onClick={() => history.push("/Queue/")}
              />
            )}
            {CONFIG.DASHBOARD && (
              <Button
                className="tabButton"
                icon="users"
                active={currentPage === PAGE.APPOINTMENT_DASHBOARD}
                onClick={() => history.push("/AppointmentDashboard/")}
              />
            )}
            {CONFIG.FORM_ANALYTICS && (
              <Button
                className="tabButton"
                icon="wpforms"
                active={currentPage === PAGE.FORM_ANALYTICS}
                onClick={() => history.push("/FormAnalytics/")}
              />
            )}
            {CONFIG.MANAGE_SCHEDULE && (
              <Button
                className="tabButton"
                icon="user doctor"
                active={currentPage === PAGE.MANAGE_SCHEDULE}
                onClick={() => history.push("/ManageSchedule/")}
              />
            )}
            {CONFIG.STUDIO && (
              <Button
                className="tabButton"
                icon="sitemap"
                active={currentPage === PAGE.STUDIO}
                onClick={() => history.push("/Studio/")}
              />
            )}
            {CONFIG.SETTINGS && (
              <Button
                className="tabButton"
                icon="settings"
                active={currentPage === PAGE.SETTINGS}
                onClick={() => history.push("/Settings/")}
              />
            )}
            {CONFIG.VACCINE_UPLOAD && (
              <Button
                className="tabButton"
                icon="cloud upload"
                active={currentPage === PAGE.VACCINE_UPLOAD}
                onClick={() => history.push("/VaccineUpload/")}
              />
            )}
          </Grid.Column>
        )}
        <Grid.Column
          className={`page ${CONFIG.HIDE_SIDEBAR ? "hide-sidebar" : ""}`}
        >
          {
            CONFIG.ENCOUNTER_CHAT && (
              <Route
                exact
                path="/Chat/:encounterId?/:chatChannelId?/"
                render={(chatProps) => (
                  <ChatTable
                    {...props}
                    {...chatProps}
                    ref={chatTable}
                    // ref={ref => (this.chatTable = ref)}
                    allowCreateAppoint={CONFIG.BOOKING_FUNCTION ? true : false}
                    controller={props.chatController}
                    diagFormController={props.diagFormController}
                    patientPanelController={{ ...props.patientPanelController, ...props.bilPatientPanelController }}
                    nurseNoteController={props.nurseNoteController}
                    progressNoteController={props.progressNoteController}
                    classifyController={props.classifyController}
                    apiToken={props.apiToken}
                    onGetDivision={props.loadMydivision}
                    division={props.division}
                    userId={props.userId}
                    fullname={props.fullname}
                    username={props.username}
                    finishedTriageLevel={props.finishedTriageLevel}
                    onNavigationMessage={handleNavigationMessage}
                    onEditAppointment={handleCreateAppointment}
                    isPatient={false}
                    languageUX={props.languageUX}
                  />
                )}
              />
            )}
          {CONFIG.APPOINTMENT_CHAT_FUNCTION && (
            <Route
              exact
              path="/Appointment/:patientId?/:chatChannelId?/"
              render={(chatProps) => (
                <AppointmentChatTable
                  {...props}
                  {...chatProps}
                  ref={appointmentTable}
                  // ref={ref => (this.chatTable = ref)}
                  allowCreateAppoint={true}
                  controller={props.appointmentChatController}
                  diagFormController={props.diagFormController}
                  patientPanelController={props.patientPanelController}
                  nurseNoteController={props.nurseNoteController}
                  progressNoteController={props.progressNoteController}
                  classifyController={props.classifyController}
                  apiToken={props.apiToken}
                  onGetDivision={props.loadMydivision}
                  division={props.division}
                  userId={props.userId}
                  fullname={props.fullname}
                  username={props.username}
                  finishedTriageLevel={props.finishedTriageLevel}
                  onNavigationMessage={handleNavigationMessage}
                  onEditAppointment={handleCreateAppointment}
                  isPatient={false}
                  languageUX={props.languageUX}
                />
              )}
            />
          )}
          {CONFIG.CLASSIFY_CHAT && (
            <Route
              exact
              path="/ClassifyChat/:triageLevel?/:diagFormId?"
              render={(chatProps) => (
                <QAChatTable
                  apiToken={props.apiToken}
                  division={props.division}
                  finishedTriageLevel={props.finishedTriageLevel}
                  {...props}
                  {...chatProps}
                  ref={qaChatTable}
                  // ref={ref => (this.chatTable = ref)}
                  allowCreateAppoint={CONFIG.BOOKING_FUNCTION ? true : false}
                  // Controller
                  controller={props.chatController}
                  diagFormController={props.diagFormController}
                  patientPanelController={props.patientPanelController}
                  nurseNoteController={props.nurseNoteController}
                  progressNoteController={props.progressNoteController}
                  classifyController={props.classifyController}
                  crdVitalSignController={props.crdVitalSignController}
                  onGetDivision={props.onGetDivision}
                  dashboardController={props.dashboardController}
                  queueController={props.queueController}
                  // Data
                  userId={props.userId}
                  fullname={props.fullname}
                  username={props.username}
                  finishedTriageLevel={props.finishedTriageLevel}
                  isPatient={false}
                  // Callback
                  onNavigationMessage={handleNavigationMessage}
                  onEditAppointment={handleCreateAppointment}
                  onSetShowMenuNoti={(show) =>
                    props.onSetState({ showMenuNoti: show })
                  }
                  //Feature #54627
                  patientADR={props.patientADR}
                  languageUX={props.languageUX}
                />
              )}
            />
          )}
          {CONFIG.USER_LIST_CHAT && (
            <Route
              exact
              path="/UserChat/:patientId?/:chatChannelId?/"
              render={(chatProps) => (
                <REGChatTable
                  {...props}
                  {...chatProps}
                  ref={chatTable}
                  // ref={ref => (this.chatTable = ref)}
                  allowCreateAppoint={true}
                  controller={props.regChatController}
                  diagFormController={props.diagFormController}
                  patientPanelController={props.bilPatientPanelController}
                  nurseNoteController={props.nurseNoteController}
                  progressNoteController={props.progressNoteController}
                  classifyController={props.classifyController}
                  apiToken={props.apiToken}
                  onGetDivision={props.loadMydivision}
                  division={props.division}
                  userId={props.userId}
                  fullname={props.fullname}
                  username={props.username}
                  finishedTriageLevel={props.finishedTriageLevel}
                  onNavigationMessage={handleNavigationMessage}
                  onEditAppointment={handleCreateAppointment}
                  isPatient={false}
                  languageUX={props.languageUX}
                />
              )}
            />
          )}
          {CONFIG.AIRFLOW_CHAT && (
            <Route
              exact
              path="/IsolationChat/:triageLevel?/:diagFormId?"
              render={(chatProps) => (
                <QAChatTable
                  apiToken={props.apiToken}
                  division={props.division}
                  finishedTriageLevel={props.finishedTriageLevel}
                  {...props}
                  {...chatProps}
                  ref={qaChatTable}
                  // ref={ref => (this.chatTable = ref)}
                  allowCreateAppoint={CONFIG.BOOKING_FUNCTION ? true : false}
                  // Controller
                  controller={props.chatController}
                  diagFormController={props.diagFormController}
                  patientPanelController={props.patientPanelController}
                  nurseNoteController={props.nurseNoteController}
                  progressNoteController={props.progressNoteController}
                  classifyController={props.classifyController}
                  crdVitalSignController={props.crdVitalSignController}
                  onGetDivision={props.onGetDivision}
                  dashboardController={props.dashboardController}
                  queueController={props.queueController}
                  // Data
                  userId={props.userId}
                  fullname={props.fullname}
                  username={props.username}
                  finishedTriageLevel={props.finishedTriageLevel}
                  storage={props.storage}
                  isPatient={false}
                  // Callback
                  onNavigationMessage={handleNavigationMessage}
                  onEditAppointment={handleCreateAppointment}
                  // onSetShowMenuNoti={(show) =>
                  //   props?.onSetState({ showMenuNoti: show })
                  // }
                  // config
                  AIRFLOW_CHAT={true}
                  //Feature #54627
                  patientADR={props.patientADR}
                  languageUX={props.languageUX}
                />
              )}
            />
          )}
          {CONFIG.BOOKING_FUNCTION && (
            <Route
              exact
              path="/Schedule/"
              render={(appProps) => (
                <TimeNurse
                  {...props}
                  {...appProps}
                  queueController={props.queueController}
                  languageUX={props.languageUX}
                />
              )}
            />
          )}
          {CONFIG.DASHBOARD && (
            <Route
              exact
              path="/AppointmentDashboard/"
              render={(appProps) => (
                <AppointmentDashboard
                  {...props}
                  {...appProps}
                  controller={props.appointmentDashboardController}
                  languageUX={props.languageUX}
                />
              )}
            />
          )}
          {CONFIG.BOOKING_FUNCTION && (
            <Route
              exact
              path="/Queue/"
              render={(appProps) => (
                <TimeNurse
                  {...appProps}
                  queueController={props.queueController}
                  apiToken={props.apiToken}
                  providerId={props.providerId}
                  division={props.division}
                  userProviderId={props.providerId}
                  filterProvider
                  hideScheduleButton={true}
                  languageUX={props.languageUX}
                // onClickDaySlot={({ index, weekStartSerial }) => {
                //   history.push(
                //     "/Schedule/" + index + "/" + weekStartSerial + "/"
                //   );
                // }}
                />
              )}
            />
          )}
          {CONFIG.BOOKING_FUNCTION && (
            <Route
              exact
              path="/DoctorSchedule/:providerId/:date?/"
              render={(appProps) => {
                return (
                  <DoctorScheduleView
                    {...props}
                    {...appProps}
                    controller={props.queueController}
                    languageUX={props.languageUX}
                  />
                );
              }}
            />
          )}
          {/* {config.BOOKING_FUNCTION && (
            <Route
              exact
              path="/MySchedule/"
              render={(appProps) => (
                <TimeDoctor
                  {...appProps}
                  controller={props.queueController}
                  apiToken={props.apiToken}
                  providerId={props.providerId}
                  onClickDaySlot={({ index, weekStartSerial }) => {
                    history.push(
                      "/MySchedule/" + index + "/" + weekStartSerial + "/"
                    );
                  }}
                />
              )}
            />
          )}
          {config.BOOKING_FUNCTION && (
            <Route
              exact
              path="/MySchedule/:id/:weekStartSerial?/"
              render={(appProps) => (
                <TimeDoctorEdit
                  {...appProps}
                  controller={props.queueController}
                  apiToken={props.apiToken}
                  providerId={props.providerId}
                />
              )}
            />
          )} */}
          {CONFIG.DASHBOARD && (
            <Route
              exact
              path="/Dashboard/"
              render={(appProps) => (
                <Dashboard
                  {...appProps}
                  ref={dashboardRef}
                  apiToken={props.apiToken}
                  division={props.division}
                  controller={props.dashboardController}
                  languageUX={props.languageUX}
                />
              )}
            />
          )}
          {CONFIG.FORM_ANALYTICS && (
            <Route
              exact
              path="/FormAnalytics/"
              render={(appProps) => (
                <FormAnalytics
                  {...appProps}
                  ref={dashboardRef}
                  apiToken={props.apiToken}
                  division={props.division}
                  controller={props.dashboardController}
                  onEvent={props.onEvent}
                  patientSegmentList={props.patientSegmentList}
                  diagFormDetail={props.diagFormDetail}
                  chatController={props.chatController}
                  isLoadingFormAnalytics={props.isLoadingFormAnalytics}
                  diagFormController={props.diagFormController}
                  divisionProfileList={props.divisionProfileList}
                  divisionHasUser={props.myDivisions}
                  languageUX={props.languageUX}
                />
              )}
            />
          )}
          {CONFIG.STUDIO && (
            <Route
              exact
              path="/Studio/"
              render={(appProps) => (
                <MainRuleList
                  apiToken={props.apiToken}
                  controller={props.ruleListController}
                  onSetState={props.onSetState}
                  onGetDivision={props.getDivision}
                  division={props.division}
                  // isVerified={props.isVerified}
                  isVerified={true} // for demo
                  languageUX={props.languageUX}
                />
              )}
            />
          )}
          {CONFIG.STUDIO && (
            <Route
              exact
              path="/composer/:id/"
              render={(appProps) => (
                <DiagComposer
                  {...appProps}
                  controller={props.diagComposerController}
                  storage={props.storage}
                  apiToken={props.apiToken}
                  onGetDivision={props.getDivision}
                  division={props.division}
                  goToRegister={props.goToRegister}
                  //isVerified={props.isVerified}
                  isVerified={true} // for demo
                  languageUX={props.languageUX}
                />
              )}
            />
          )}
          {CONFIG.VACCINE_UPLOAD && (
            <Route
              exact
              path="/VaccineUpload/"
              render={(appProps) => (
                <VaccineUpload 
                  onEvent={props.onEvent}
                  vaccineInfo={props.vaccineInfo}
                  appointmentList={props.appointmentList}
                  division={props.division}
                  generateUserModal={props.generateUserModal}
                  generateUserResult={props.generateUserResult}
                  languageUX={props.languageUX}
                />
              )}
            />
          )}
          {CONFIG.MANAGE_SCHEDULE && (
            <Route
              exact
              path="/ManageSchedule/"
              render={(appProps) => (
                <DoctorSchedule
                  apiToken={props.apiToken}
                  division={props.division}
                  controller={props.dashboardController}
                  {...appProps}
                  queueController={props.queueController}
                  providerId={props.providerId}
                  onClickDaySlot={({ index, weekStartSerial }) => {
                    history.push(
                      "/ManageSchedule/" + index + "/" + weekStartSerial + "/"
                    );
                  }}
                  languageUX={props.languageUX}
                />
              )}
            />
          )}
          {CONFIG.MANAGE_SCHEDULE && (
            <Route
              exact
              path="/ManageSchedule/:id/:weekStartSerial?/"
              render={(appProps) => (
                <DoctorSchedule
                  apiToken={props.apiToken}
                  division={props.division}
                  controller={props.dashboardController}
                  {...appProps}
                  queueController={props.queueController}
                  providerId={props.providerId}
                  onClickDaySlot={({ index, weekStartSerial }) => {
                    history.push(
                      "/ManageSchedule/" + index + "/" + weekStartSerial + "/"
                    );
                  }}
                  languageUX={props.languageUX}
                />
              )}
            />
          )}
          {CONFIG.SETTINGS && (
            <>
              <Route
                exact
                path="/Settings/"
                render={(appProps) => (
                  <Settings
                    {...appProps}
                    ref={settingsRef}
                    apiToken={props.apiToken}
                    division={props.division}
                    controller={props.dashboardController}
                    onEvent={props.onEvent}
                    patientSegmentList={props.patientSegmentList}
                    diagFormDetail={props.diagFormDetail}
                    chatController={props.chatController}
                    isLoadingFormAnalytics={props.isLoadingFormAnalytics}
                    diagFormController={props.diagFormController}
                    divisionProfileList={props.divisionProfileList}
                    divisionHasUser={props.myDivisions}
                    onGetDivision={props.onGetDivision}
                    languageUX={props.languageUX}
                  />
                  // <DoctorSchedule />
                )}
              />
            </>
          )}
          <Route
            exact
            path="/composer/:id/"
            render={(appProps) => (
              <DiagComposer
                {...appProps}
                controller={props.diagComposerController}
                storage={props.storage}
                apiToken={props.apiToken}
                onGetDivision={props.getDivision}
                division={props.division}
                goToRegister={props.goToRegister}
                isVerified={props.isVerified}
                languageUX={props.languageUX}
              />
            )}
          />
          {CONFIG.SHOW_BIND_HN && (
            <Route
              exact
              path="/bind-hns/"
              render={(appProps) => (
                <BindHNPage
                  {...appProps}
                  controller={props.bindHNPageController}
                  apiToken={props.apiToken}
                  division={props.division}
                  languageUX={props.languageUX}
                />
              )}
            />
          )}
        </Grid.Column>
      </Grid>
    </div>
  );
});

MainScreen.defaultProps = {
  diagFormController: {},
  chatController: {},
  appointmentChatController: {},
  patientPanelController: {},
  nurseNoteController: {},
  progressNoteController: {},
  classifyController: {},
  crdVitalSignController: {},
  onGetDivision: () => { },
  finishedTriageLevel: [],
  queueController: {},
  bilPatientPanelController: {},
  myDivisions: [],
  storage: {},
  vaccineInfo: []
};

MainScreen.propTypes = {
  diagFormController: PropTypes.object,
  chatController: PropTypes.object,
  appointmentChatController: PropTypes.object,
  patientPanelController: PropTypes.object,
  nurseNoteController: PropTypes.object,
  progressNoteController: PropTypes.object,
  crdVitalSignController: PropTypes.object,
  classifyController: PropTypes.object,
  bilPatientPanelController: PropTypes.object,
  apiToken: PropTypes.string,
  onGetDivision: PropTypes.func,
  division: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fullname: PropTypes.string,
  username: PropTypes.string,
  finishedTriageLevel: PropTypes.array,
  queueController: PropTypes.object,
  myDivisions: PropTypes.array,
  storage: PropTypes.object,
  vaccineInfo: PropTypes.array,
};

MainScreen.displayName = "MainScreen"; 
export default React.memo(MainScreen);
