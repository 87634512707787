import React from "react";
import PropTypes from "prop-types";
import * as CONSTANT from "../../utils/constant";
import CardLayout from "react-lib/apps/common/CardLayout";
import SubFormViewer from "react-lib/apps/PTM/SubFormViewer";
import { Label } from "semantic-ui-react";
import { useIntl } from "react-intl";

const CardNutritionAssessment = React.forwardRef((props, ref) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [status, setStatus] = React.useState(null);
  const [nutritionAssessmentData, setNutritionAssessmentData] = React.useState(
    []
  );
  const [subFormViewData, setSubFormViewerData] = React.useState([]);

  React.useImperativeHandle(ref, () => ({
    refresh: ({ encounterId } = {}) => {
      refresh({ encounterId });
    }
  }));

  React.useEffect(() => {
    refresh({ encounterId: props.encounterId });
  }, [props.encounterId]);

  React.useEffect(() => {
    formatFormViewData();
  }, [nutritionAssessmentData]);

  const refresh = ({ encounterId } = {}) => {
    getFormDataLatest({ encounterId });
  };

  const formatFormViewData = () => {
    let newArr = [...nutritionAssessmentData];
    newArr.forEach(item => {
      item.lose_weight = TRUE_FALSE[item.lose_weight];
      item.malnutrition = TRUE_FALSE[item.malnutrition];
      item.bmi_outrange = TRUE_FALSE[item.bmi_outrange];
      item.critical = TRUE_FALSE[item.critical];
      item.result = RESULT[item.result];
      item.result_preg = RESULT_PREG[item.result_preg];
      item.preg1 = TRUE_FALSE[item.preg1];
      item.preg2 = TRUE_FALSE[item.preg2];
      item.preg3 = TRUE_FALSE[item.preg3];
    });
    setSubFormViewerData(newArr);
  };

  const getFormDataLatest = async ({ encounterId }) => {
    setIsLoading(true);
    const [data, error] = await props.controller.getFormDataLatest({
      encounterId: encounterId
        ? encounterId
        : props.patientData.ENCOUNTER.encounter_id,
      formCode: "CardNutritionAssessment",
      formVersion: "0.1"
    });
    if (data) {
      setStatus(data.status);
      setNutritionAssessmentData(data.data);
    }
    setIsLoading(false);
  };

  let statusColor = "black";
  if (status === CONSTANT.FORM_DATA_STATUS.DRAFT) {
    statusColor = "yellow";
  } else if (status === CONSTANT.FORM_DATA_STATUS.EDITED) {
    statusColor = "olive";
  } else if (status === CONSTANT.FORM_DATA_STATUS.CONFIRMED) {
    statusColor = "green";
  } else if (status === CONSTANT.FORM_DATA_STATUS.CANCELLED) {
    statusColor = "red";
  }

  let labelStatus = props.showSummary ? <Label color={statusColor}>{status}</Label> : <Label>Need to Implement</Label>;

  return (
    <CardLayout
      headerColor="grey"
      loading={isLoading}
      titleText="Nutrition Assessment"
      onClose={props.onClose}
      titleContent={labelStatus}
    >
      {props.showSummary ? (
        <SubFormViewer
          fieldList={rmdFieldList}
          fieldMap={rmdFieldMap}
          data={subFormViewData}
          languageUX={props.languageUX}
        />
      ) : (
        "TODO ---> Implement ShowSummary = false"
      )}
    </CardLayout>
  );
});

const rmdFieldList = [
  "user_fullname",
  "date",
  "lose_weight",
  "malnutrition",
  "bmi_outrange",
  "critical",
  "result",
  "result_preg",
  "preg1",
  "preg2",
  "preg3"
];

const RESULT = {
  Normal: "ปกติ",
  Abnormal1: "ผิดปกติ รายงานแพทย์",
  OTH: "อื่นๆ ระบุ",
  Abnormal: "ผิดปกติ ส่งปรึกษาทีมโภชนบำบัด"
};

const RESULT_PREG = {
  NA: "NA",
  Normal: "ปกติ",
  Abnormal1: "ผิดปกติรายงานแพทย์"
};

const TRUE_FALSE = {
  true: "ใช่",
  false: "ไม่ใช่"
};

const rmdFieldMap = {
  malnutrition: "ผู้ป่วยได้รับอาหารน้อยกว่าที่เคยได้ (เกินกว่า 7 วัน)",
  critical: "ผู้ป่วยมีภาวะโรควิกฤตหรือกึ่งวิกฤตร่วมด้วยหรือไม่",
  result_preg: "ผลการคัดกรอง (เฉพาะหญิงตั้งครรภ์)",
  date: "Date Time",
  preg1:
    "ไตรมาสแรก (อายุครรภ์ 0-12 สัปดาห์) น้ำหนักลด > 0.5 kg/week ร่วมกับอาเจียน, กินไม่ได้ (เฉพาะหญิงตั้งครรภ์)",
  user_fullname: "ผู้บันทึก/แก้ไข",
  lose_weight:
    "ผู้ป่วยมีน้ำหนักตัวลดลงโดยไม่ได้ตั้งใจในช่วง 6 เดือนที่ผ่านมาหรือไม่",
  result: "ผลการคัดกรอง",
  bmi_outrange: "BMI < 18.5 หรือตั้งแต่ 25.0 กก./ตร.ม ขึ้นไป",
  preg3:
    "ไตรมาสที่ 2 ขึ้นไป (อายุครรภ์ 12 สัปดาห์ขึ้นไป) น้ำหนักร่วมกับอาการบวม/ความดันโลหิตสูง (เฉพาะหญิงตั้งครรภ์)",
  preg2:
    "ไตรมาสที่ 2 ขึ้นไป (อายุครรภ์ 12 สัปดาห์ขึ้นไป) น้ำหนักเพิ่ม/ลด > 1 kg/week (เฉพาะหญิงตั้งครรภ์)"
};

CardNutritionAssessment.defaultProps = {
  onClose: () => {},
  controller: {},
  encounterId: null,
  showSummary: false
};

CardNutritionAssessment.propTypes = {
  onClose: PropTypes.func,
  controller: PropTypes.object,
  encounterId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showSummary: PropTypes.bool
};

export default React.memo(CardNutritionAssessment);
