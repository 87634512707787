export default class CardProgressNoteController {
  constructor(props) {
    this.dpiManager = props.dpiManager;
    this.prxManager = props.prxManager;
  }

  geProgressNoteList = async ({ apiToken, encounterId } = {}) => {
    const [response, error, network] = await this.prxManager.geProgressNoteList(
      {
        apiToken,
        encounterId
      }
    );
    return [response, error, network];
  };

  createProgressionNote = async ({ apiToken, data } = {}) => {
    const [
      response,
      error,
      network
    ] = await this.dpiManager.createProgressionNote({
      apiToken: apiToken,
      data: data
    });
    return [response, error, network];
  };

  putProgressNote = async ({ apiToken, emrId, data } = {}) => {
    const [
      response,
      error,
      network
    ] = await this.dpiManager.putProgressNote({
      apiToken: apiToken,
      data: data,
      emrId
    });
    return [response, error, network];
  }

  putProgressNoteById = async ({ apiToken, progressNoteId, data } = {}) => {
    const [
      response,
      error,
      network
    ] = await this.dpiManager.putProgressNoteById({
      apiToken: apiToken,
      data: data,
      progressNoteId
    });
    return [response, error, network];
  }
}
