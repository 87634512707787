import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  FormField,
  Button,
  Divider,
  Dropdown,
  Input,
  Label,
  Checkbox
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardEpisodeOfCareUX = (props: any) => {
    return(
      <div
        style={{ width : "100%", height: "100%" , padding: "1rem"}}>
        <Form>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={6}>
              <label
                style={{ fontSize: "1.2rem"}}>
                Episode of Care
              </label>
              <div>
                {props.loadingCheckbox}
              </div>
            </FormField>
            <FormField
              style={{flex: 1}}>
            </FormField>
            <FormField
              style={{padding: 0}}>
              <Button
                color="green"
                onClick={props.onNewEpisode}
                size="small">
                Create New Episode
              </Button>
            </FormField>
          </FormGroup>
        </Form>
        <div>

          <Table
            NoDataComponent={()=>null}
            data={props.episodeList}
            getTrProps={props.onGetTrProps}
            headers="Episode no.,Episode type,Episode name,Doctor,Started,Finished"
            keys="episode_no,episodeTypeName,name,doctor_name,firstEncounterCreated,finishedAt"
            minRows="5"
            showPagination={false}
            style={{height: "200px"}}>
          </Table>
        </div>
        <div
          style={{display:props.episodeDetail?"":"none"}}>

          <Divider>
          </Divider>
          <Form>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}>
                <label
                  style={{minWidth: "6rem"}}>
                  Episode no.
                </label>
                <Label
                  color="blue"
                  style={{minWidth:"7rem"}}>
                  {props.episodeDetail?.episode_no??"\u00A0"}
                </Label>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={8}>
                <label
                  style={{minWidth: "6rem"}}>
                  Episode type
                </label>
                <Dropdown
                  clearable={true}
                  fluid={true}
                  name="episode_type"
                  onChange={props.onChangeValue}
                  options={props.episodeTypeOptions}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.episodeDetail?.episode_type??""}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}
                width={8}>
                <label
                  style={{minWidth: "6rem"}}>
                  Episode name
                </label>
                <Input
                  fluid={true}
                  name="name"
                  onChange={props.onChangeValue}
                  value={props.episodeDetail?.name??""}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={8}>
                <label
                  style={{minWidth: "6rem"}}>
                  Doctor
                </label>
                <div
                  style={{width: "100%"}}>
                  {props.searchBoxDoctor}
                </div>
              </FormField>
              <FormField
                inline={true}
                width={8}>
                <Checkbox
                  checked={props.episodeDetail?.is_finished??""}
                  label="Finish"
                  name="is_finished"
                  onChange={props.onChangeValue}>
                </Checkbox>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{marginBottom: "0.5rem"}}>
              <FormField
                inline={true}>
                <label>
                  Encounter in this Episode
                </label>
              </FormField>
            </FormGroup>
          </Form>
          <div>

            <Table
              NoDataComponent={props.NoDataComponent}
              data={props.episodeEncounterList}
              headers=",Encounter,Division,Doctor,Status,Start,Del,"
              keys="checkbox,number,division_name,doctor_name,status_name,startDate,canceledDate,delete"
              minRows={1}
              showPagination={false}
              sortable={false}
              widths="60,120,^120,^120,^110,^100,^100,85">
            </Table>
          </div>
          <Form>
            <FormGroup
              inline={true}
              style={{margin: "1rem 0 0.25rem"}}>
              <FormField>
                <label>
                  Patient's Encounter (No Episode)
                </label>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{marginBottom: "0.5rem"}}>
              <FormField
                inline={true}>
                <div>
                  {props.toggleSwitch}
                </div>
              </FormField>
              <FormField
                inline={true}
                style={{display: props.filterEncounter?.include_expired? "flex":"none", alignItems: "center"}}>
                <label
                  style={{minWidth: "max-content", fontWeight: "normal"}}>
                  Encounter date
                </label>
                <div
                  className="ui form size mini"
                  style={{width: "100%"}}>
                  {}
                  <DateTextBox
                    onChange={(value: string)=>{props.onChangeFilterEncounter?.(null, {value, name: "started"})}}
                    value={props.filterEncounter?.started || ""}>
                  </DateTextBox>
                </div>
              </FormField>
              <FormField
                inline={true}
                style={{display: props.filterEncounter?.include_expired? "flex":"none", alignItems: "center"}}>
                <label
                  style={{minWidth: "max-content", fontWeight: "normal"}}>
                  to
                </label>
                <div
                  className="ui form size mini"
                  style={{width: "100%"}}>

                  <DateTextBox
                    onChange={(value: string)=>{props.onChangeFilterEncounter?.(null, {value, name: "ended"})}}
                    value={props.filterEncounter?.ended || ""}>
                  </DateTextBox>
                </div>
              </FormField>
              <FormField
                style={{display: props.filterEncounter?.include_expired? "flex":"none"}}>
                <div>
                  {props.buttonSearchEncounter}
                </div>
              </FormField>
            </FormGroup>
          </Form>
          <div>

            <Table
              NoDataComponent={props.NoDataComponent}
              data={props.activeEncounterList}
              headers={props.tableHeaders}
              keys="checkbox,number,division_name,doctor_name,status_name,startDate,canceledDate,delete"
              minRows={5}
              showPagination={false}
              sortable={false}
              style={{height: "250px"}}
              widths="60,120,^120,^120,^110,^100,^100,85">
            </Table>
          </div>
          <Form>
            <FormGroup
              inline={true}
              style={{marginTop: "0.75rem"}}>
              <FormField
                style={{flex:1}}>
              </FormField>
              <FormField
                style={{ padding: 0}}>
                <div>
                  {props.pagination}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{marginTop: "1.25rem"}}>
              <FormField
                style={{flex: 1}}>
              </FormField>
              <FormField>
                <Button
                  color="red"
                  onClick={props.onDeleteEpisode}
                  size="small"
                  style={{display: props.showDeleteEpisode?"":"none"}}>
                  DELETE EPISODE
                </Button>
              </FormField>
              <FormField
                style={{padding: 0}}>
                <div>
                  {props.buttonSave}
                </div>
              </FormField>
            </FormGroup>
          </Form>
        </div>
      </div>
    )
}

CardEpisodeOfCareUX.displayName = "CardEpisodeOfCareUX";
export default React.memo(CardEpisodeOfCareUX)

export const screenPropsDefault = {"cannotPay":false,"cashAmountText":"14","citizenID":"31020000552","oweText":123,"patientNameAndHN":"HN: xxxxx นายทดสอบ","sumAmount":{"price":100,"reimbursable":42}}

/* Date Time : Fri Oct 04 2024 18:30:42 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width : \"100%\", height: \"100%\" , padding: \"1rem\"}"
        }
      },
      "seq": 0
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "FormGroup",
      "parent": 26,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "FormField",
      "parent": 27,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "FormField",
      "parent": 27,
      "props": {
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "label",
      "parent": 28,
      "props": {
        "children": {
          "type": "value",
          "value": "Episode of Care"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"1.2rem\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "FormField",
      "parent": 27,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding: 0}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "Button",
      "parent": 32,
      "props": {
        "children": {
          "type": "value",
          "value": "Create New Episode"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onNewEpisode"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 36,
      "name": "Table",
      "parent": 35,
      "props": {
        "NoDataComponent": {
          "type": "code",
          "value": "()=>null"
        },
        "data": {
          "type": "code",
          "value": "props.episodeList"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.onGetTrProps"
        },
        "headers": {
          "type": "value",
          "value": "Episode no.,Episode type,Episode name,Doctor,Started,Finished"
        },
        "keys": {
          "type": "value",
          "value": "episode_no,episodeTypeName,name,doctor_name,firstEncounterCreated,finishedAt"
        },
        "minRows": {
          "type": "value",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"200px\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:props.episodeDetail?\"\":\"none\"}"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "Divider",
      "parent": 37,
      "props": {
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "Form",
      "parent": 37,
      "props": {
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "FormGroup",
      "parent": 40,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "FormGroup",
      "parent": 40,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "FormField",
      "parent": 41,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "FormField",
      "parent": 42,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "FormField",
      "parent": 42,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "label",
      "parent": 43,
      "props": {
        "children": {
          "type": "value",
          "value": "Episode no."
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"6rem\"}"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "label",
      "parent": 45,
      "props": {
        "children": {
          "type": "value",
          "value": "Episode type"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"6rem\"}"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "label",
      "parent": 46,
      "props": {
        "children": {
          "type": "value",
          "value": "Episode name"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"6rem\"}"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "Dropdown",
      "parent": 45,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "episode_type"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.episodeTypeOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.episodeDetail?.episode_type??\"\""
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "Input",
      "parent": 46,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "name"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.episodeDetail?.name??\"\""
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "Label",
      "parent": 43,
      "props": {
        "children": {
          "type": "code",
          "value": "props.episodeDetail?.episode_no??\"\\u00A0\""
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "style": {
          "type": "code",
          "value": "{minWidth:\"7rem\"}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "FormGroup",
      "parent": 40,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "FormField",
      "parent": 53,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "label",
      "parent": 54,
      "props": {
        "children": {
          "type": "value",
          "value": "Doctor"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"6rem\"}"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "div",
      "parent": 54,
      "props": {
        "children": {
          "type": "code",
          "value": "props.searchBoxDoctor"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "FormField",
      "parent": 53,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "Checkbox",
      "parent": 57,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.episodeDetail?.is_finished??\"\""
        },
        "label": {
          "type": "value",
          "value": "Finish"
        },
        "name": {
          "type": "value",
          "value": "is_finished"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "FormGroup",
      "parent": 40,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.5rem\"}"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "FormField",
      "parent": 59,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "label",
      "parent": 60,
      "props": {
        "children": {
          "type": "value",
          "value": "Encounter in this Episode"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "div",
      "parent": 37,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 63,
      "name": "Table",
      "parent": 62,
      "props": {
        "NoDataComponent": {
          "type": "code",
          "value": "props.NoDataComponent"
        },
        "data": {
          "type": "code",
          "value": "props.episodeEncounterList"
        },
        "headers": {
          "type": "value",
          "value": ",Encounter,Division,Doctor,Status,Start,Del,"
        },
        "keys": {
          "type": "value",
          "value": "checkbox,number,division_name,doctor_name,status_name,startDate,canceledDate,delete"
        },
        "minRows": {
          "type": "code",
          "value": "1"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "sortable": {
          "type": "code",
          "value": "false"
        },
        "widths": {
          "type": "value",
          "value": "60,120,^120,^120,^110,^100,^100,85"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": null,
      "id": 66,
      "name": "div",
      "parent": 37,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 67,
      "name": "Table",
      "parent": 66,
      "props": {
        "NoDataComponent": {
          "type": "code",
          "value": "props.NoDataComponent"
        },
        "data": {
          "type": "code",
          "value": "props.activeEncounterList"
        },
        "headers": {
          "type": "code",
          "value": "props.tableHeaders"
        },
        "keys": {
          "type": "value",
          "value": "checkbox,number,division_name,doctor_name,status_name,startDate,canceledDate,delete"
        },
        "minRows": {
          "type": "code",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "sortable": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"250px\"}"
        },
        "widths": {
          "type": "value",
          "value": "60,120,^120,^120,^110,^100,^100,85"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "Form",
      "parent": 37,
      "props": {
      },
      "seq": 84,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 77,
      "name": "FormGroup",
      "parent": 73,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"1.25rem\"}"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "FormField",
      "parent": 77,
      "props": {
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 79,
      "name": "FormField",
      "parent": 77,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding: 0}"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "div",
      "parent": 79,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "div",
      "parent": 28,
      "props": {
        "children": {
          "type": "code",
          "value": "props.loadingCheckbox"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "FormField",
      "parent": 77,
      "props": {
      },
      "seq": 79,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 83,
      "name": "Button",
      "parent": 82,
      "props": {
        "children": {
          "type": "value",
          "value": "DELETE EPISODE"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "name": {
          "type": "value",
          "value": ""
        },
        "onClick": {
          "type": "code",
          "value": "props.onDeleteEpisode"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{display: props.showDeleteEpisode?\"\":\"none\"}"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 84,
      "name": "Form",
      "parent": 37,
      "props": {
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 97,
      "name": "FormGroup",
      "parent": 84,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"1rem 0 0.25rem\"}"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 98,
      "name": "FormGroup",
      "parent": 84,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.5rem\"}"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 99,
      "name": "FormField",
      "parent": 97,
      "props": {
      },
      "seq": 99,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 100,
      "name": "FormField",
      "parent": 98,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 101,
      "name": "FormField",
      "parent": 98,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: props.filterEncounter?.include_expired? \"flex\":\"none\", alignItems: \"center\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 102,
      "name": "FormField",
      "parent": 98,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: props.filterEncounter?.include_expired? \"flex\":\"none\", alignItems: \"center\"}"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 103,
      "name": "FormField",
      "parent": 98,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: props.filterEncounter?.include_expired? \"flex\":\"none\"}"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": null,
      "id": 104,
      "name": "label",
      "parent": 99,
      "props": {
        "children": {
          "type": "value",
          "value": "Patient's Encounter (No Episode)"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": null,
      "id": 105,
      "name": "label",
      "parent": 101,
      "props": {
        "children": {
          "type": "value",
          "value": "Encounter date"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", fontWeight: \"normal\"}"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": null,
      "id": 106,
      "name": "label",
      "parent": 102,
      "props": {
        "children": {
          "type": "value",
          "value": "to"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", fontWeight: \"normal\"}"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": null,
      "id": 108,
      "name": "div",
      "parent": 102,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form size mini"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": null,
      "id": 109,
      "name": "div",
      "parent": 101,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form size mini"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 110,
      "name": "DateTextBox",
      "parent": 108,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(value: string)=>{props.onChangeFilterEncounter?.(null, {value, name: \"ended\"})}"
        },
        "value": {
          "type": "code",
          "value": "props.filterEncounter?.ended || \"\""
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 111,
      "name": "DateTextBox",
      "parent": 109,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(value: string)=>{props.onChangeFilterEncounter?.(null, {value, name: \"started\"})}"
        },
        "value": {
          "type": "code",
          "value": "props.filterEncounter?.started || \"\""
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": null,
      "id": 112,
      "name": "div",
      "parent": 103,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearchEncounter"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": null,
      "id": 113,
      "name": "div",
      "parent": 100,
      "props": {
        "children": {
          "type": "code",
          "value": "props.toggleSwitch"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 114,
      "name": "FormGroup",
      "parent": 73,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"0.75rem\"}"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 115,
      "name": "FormField",
      "parent": 114,
      "props": {
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 116,
      "name": "FormField",
      "parent": 114,
      "props": {
        "style": {
          "type": "code",
          "value": "{ padding: 0}"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": null,
      "id": 117,
      "name": "div",
      "parent": 116,
      "props": {
        "children": {
          "type": "code",
          "value": "props.pagination"
        }
      },
      "seq": 117,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": true,
  "name": "CardEpisodeOfCareUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "cannotPay": false,
    "cashAmountText": "14",
    "citizenID": "31020000552",
    "oweText": 123,
    "patientNameAndHN": "HN: xxxxx นายทดสอบ",
    "sumAmount": {
      "price": 100,
      "reimbursable": 42
    }
  },
  "width": 75
}

*********************************************************************************** */
