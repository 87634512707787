import WasmController from "../../../../frameworks/WasmController";
// APIs
import CentralLabOrderItemResult from "issara-sdk/apis/CentralLabOrderItemResult_apps_LAB";
import CentralLabOrderItemResultPrintView from "issara-sdk/apis/CentralLabOrderItemResultPrintView_apps_LAB";
import CentralLabOrderList from "issara-sdk/apis/CentralLabOrderList_apps_LAB";

export type State = {
  // CommonInterface
  buttonLoadCheck?: any;

  // sequence
  HistoryCentralLabSequence?: {
    sequenceIndex?: string | null;
    loading?: boolean;
    labResult?: any[];
  } | null;
};

export const StateInitial: State = {
  // sequence
  HistoryCentralLabSequence: {
    sequenceIndex: null,
    loading: false,
    labResult: [],
  },
};

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const Start: Handler = async (controller, params) => {
  const state = controller.getState();

  if (params.action === "FETCH") {
    controller.setState({
      HistoryCentralLabSequence: {
        ...state.HistoryCentralLabSequence,
        loading: true,
        labResult: [],
      },
    });

    const [orderResp, orderErr, orderNetw] = await CentralLabOrderList.list({
      apiToken: controller.apiToken,
      params: { patient: params.patientId },
    });

    if (orderErr) {
      controller.setState({
        HistoryCentralLabSequence: {
          ...state.HistoryCentralLabSequence,
          loading: false,
          labResult: [],
        },
      });
    } else {
      const promiseArr: any = [];
      orderResp?.items?.forEach((order: any) => {
        promiseArr.push(
          CentralLabOrderItemResult.list({
            apiToken: controller.apiToken,
            params: { order_id: order.id },
          })
        );
      });
      const resultResp = await Promise.all(promiseArr);

      let result: any[] = orderResp?.items?.map((order: any, index: number) => {
        if (
          resultResp[index][0]?.items?.[0]?.order === order.id &&
          resultResp[index][0]?.items?.length > 0
        ) {
          return { ...order, result: resultResp[index][0]?.items };
        }
      });

      const state = controller.getState();
      controller.setState({
        HistoryCentralLabSequence: {
          ...state.HistoryCentralLabSequence,
          loading: false,
          labResult: result.filter((item) => item),
        },
      });
    }
  } else if (params.action === "PRINT") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadCheckKey]: "LOADING",
      },
    });

    const [orderResp, orderErr, orderNetw] =
      await CentralLabOrderItemResultPrintView.put({
        apiToken: controller.apiToken,
        pk: params.labOrderId,
        extra: { division: controller.data.division },
      });
    if (orderErr) {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadCheckKey]: "ERROR",
        },
      });
    } else {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadCheckKey]: "SUCCESS",
        },
      });
    }
  }
};
