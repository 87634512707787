import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Dropdown,
  Input,
  Button,
  Icon
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardStockManagementTabIssueUX = (props: any) => {
    return(
      <div
        style={{width:"100%", padding: "10px",  height: "100%"}}>
        <div
          className="ui form">
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "max-content"}}>
                ผู้ตัดจ่ายสินค้า
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Dropdown
                disabled={props.readOnly || !!props.issueStockList?.length || false}
                fluid={true}
                name="requester"
                onChange={props.onChangeValue}
                options={props.requestDivisionOptions|| []}
                search={true}
                selection={true}
                style={{width: "100%", opacity: 1}}
                value={props.issueStockDetail?.requester || ""}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}>
              <label>
                สินค้า
              </label>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <Input
                fluid={true}
                readOnly={true}
                readonly={true}
                ref={(ref) => ref && (ref.inputRef.current.style.backgroundColor = "#DFDFDF",ref.inputRef.current.style.borderColor = "#DBDBDB")}
                style={{width: "100%"}}
                value={props.name || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}>
              <Button
                className="icon"
                color="blue"
                disabled={props.readOnly || false}
                onClick={props.onOpenSelectLot}
                style={{position: "relative", padding:"0.4rem", fontSize: "1.25rem"}}>
                <Icon
                  name="bars">
                </Icon>
                <div
                  style={{"position":"absolute","backgroundColor":"rgb(33, 133, 208)","bottom":"7px","right":"7px","width":"9px","height":"9px"}}>
                  
                </div>
                <div
                  style={{"position":"absolute","fontSize":"0.55rem","right":"2px","bottom":"3px"}}>
                  
                  <Icon
                    name="search">
                  </Icon>
                </div>
              </Button>
            </FormField>
            <FormField
              inline={true}>
              <div
                style={{display: "flex", fontWeight: "bold",minWidth: "max-content"}}>
                
                <label>
                  Lot No
                </label>
                <label
                  style={{color: "red"}}>
                  *
                </label>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <Input
                fluid={true}
                readOnly={true}
                ref={(ref) => ref && (ref.inputRef.current.style.backgroundColor = "#DFDFDF",ref.inputRef.current.style.borderColor = "#DBDBDB")}
                style={{width: "100%"}}
                value={props.issueStockDetail?.lot?.mfc_no || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}>
              <div
                style={{display: "flex", fontWeight: "bold",minWidth: "max-content"}}>
                {}
                <label>
                  Expiry date
                </label>
                <label
                  style={{color: "red"}}>
                  *
                </label>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <Input
                fluid={true}
                readOnly={true}
                ref={(ref) => ref && (ref.inputRef.current.style.backgroundColor = "#DFDFDF",ref.inputRef.current.style.borderColor = "#DBDBDB")}
                style={{width: "100%"}}
                value={props.expiryDate || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}>
              <div
                style={{display: "flex", fontWeight: "bold",minWidth: "max-content"}}>
                
                <label>
                  จำนวนตัดจ่าย
                </label>
                <label
                  style={{color: "red"}}>
                  *
                </label>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <Input
                disabled={props.readOnly || false}
                fluid={true}
                name="quantity"
                onChange={props.onChangeValue}
                style={{width: "100%"}}
                type="number"
                value={props.issueStockDetail?.quantity|| ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <div
                style={{display: "flex", fontWeight: "bold",minWidth: "max-content"}}>
                
                <label>
                  เหตุผลในการตัดจ่ายสินค้า
                </label>
                <label
                  style={{color: "red"}}>
                  *
                </label>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={8}>
              <Dropdown
                clearable={true}
                disabled={props.readOnly || !!props.issueStockList?.length || false}
                fluid={true}
                name="reason"
                onChange={props.onChangeValue}
                options={props.distributionReasonOptions|| []}
                search={true}
                selection={true}
                style={{width: "100%", opacity: !!props.issueStockList?.length ?"1":""}}
                value={props.reason || ""}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "max-content", opacity: props.disabledProvider ?"0.5":""}}>
                ตัดจ่ายสินค้าไปยัง
              </label>
            </FormField>
            <FormField
              inline={true}
              style={{position: "relative"}}
              width={8}>
              <div
                style={{width: "100%"}}>
                {props.ProviderElement}
              </div>
              <div
                style={{position: "absolute", top: "-16px", left: 0, color: "#5C5D5D", fontSize: "0.75rem"}}>
                {props.providerLabel || ""}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label
                style={{fontWeight: "normal", minWidth: "max-content"}}>
                หมายเหตุเพิ่มเติม
              </label>
            </FormField>
            <FormField
              inline={true}
              width={14}>
              <Input
                disabled={props.readOnly || false}
                fluid={true}
                name="remark"
                onChange={props.onChangeValue}
                readOnly={!!props.issueStockList?.length|| false}
                style={{width: "100%"}}
                value={props.remark || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}>
              <Button
                color="green"
                disabled={props.disabledAdd || false}
                onClick={props.onAdd}
                style={{minWidth: "max-content"}}>
                เพิ่มรายการ
              </Button>
            </FormField>
          </FormGroup>
        </div>
        <div
          className="ui form">
          
          <Table
            data={props.issueStockList|| []}
            getTheadThProps={props.getTheadThProps}
            headers="Item code,Name,Lot No.,Expiry Date,จำนวนตัดจ่าย,ลบ"
            keys="code,name,lot_no,expiry_date,quantity,action"
            minRows={5}
            showPagination={false}
            style={{height: "250px"}}
            widths="200,,200,200,200,90">
          </Table>
        </div>
        <div
          className="ui form"
          style={{marginTop: "1rem"}}>
          
          <FormGroup
            inline={true}>
            <div
              className="field inline"
              style={{flex: 1}}>
              
            </div>
            <FormField
              inline={true}>
              <div>
                {props.ButtonConfirm}
              </div>
            </FormField>
            <FormField>
              <Button
                color="red"
                disabled={props.readOnly || false}
                onClick={props.onCancel}>
                ยกเลิก
              </Button>
            </FormField>
          </FormGroup>
        </div>
      </div>
    )
}

CardStockManagementTabIssueUX.displayName = "CardStockManagementTabIssueUX";
export default React.memo(CardStockManagementTabIssueUX)

export const screenPropsDefault = {}

/* Date Time : Thu Jul 06 2023 07:47:15 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width:\"100%\", padding: \"10px\",  height: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 2,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 23,
      "name": "Table",
      "parent": 22,
      "props": {
        "data": {
          "type": "code",
          "value": "props.issueStockList|| []"
        },
        "getTheadThProps": {
          "type": "code",
          "value": "props.getTheadThProps"
        },
        "headers": {
          "type": "value",
          "value": "Item code,Name,Lot No.,Expiry Date,จำนวนตัดจ่าย,ลบ"
        },
        "keys": {
          "type": "value",
          "value": "code,name,lot_no,expiry_date,quantity,action"
        },
        "minRows": {
          "type": "code",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"250px\"}"
        },
        "widths": {
          "type": "value",
          "value": "200,,200,200,200,90"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"1rem\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "FormGroup",
      "parent": 24,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "FormField",
      "parent": 25,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "field inline"
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonConfirm"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "label",
      "parent": 31,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ตัดจ่ายสินค้า"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "label",
      "parent": 33,
      "props": {
        "children": {
          "type": "value",
          "value": "สินค้า"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 36,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\",minWidth: \"max-content\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "div",
      "parent": 38,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\",minWidth: \"max-content\"}"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\",minWidth: \"max-content\"}"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "label",
      "parent": 46,
      "props": {
        "children": {
          "type": "value",
          "value": "จำนวนตัดจ่าย"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "label",
      "parent": 46,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "label",
      "parent": 45,
      "props": {
        "children": {
          "type": "value",
          "value": "Expiry date"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "label",
      "parent": 45,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "label",
      "parent": 44,
      "props": {
        "children": {
          "type": "value",
          "value": "Lot No"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "label",
      "parent": 44,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "Dropdown",
      "parent": 32,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.readOnly || !!props.issueStockList?.length || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "requester"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.requestDivisionOptions|| []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", opacity: 1}"
        },
        "value": {
          "type": "code",
          "value": "props.issueStockDetail?.requester || \"\""
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "Input",
      "parent": 34,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "readonly": {
          "type": "code",
          "value": "true"
        },
        "ref": {
          "type": "code",
          "value": "(ref) => ref && (ref.inputRef.current.style.backgroundColor = \"#DFDFDF\",ref.inputRef.current.style.borderColor = \"#DBDBDB\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.name || \"\""
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "Button",
      "parent": 35,
      "props": {
        "className": {
          "type": "value",
          "value": "icon"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.readOnly || false"
        },
        "onClick": {
          "type": "code",
          "value": "props.onOpenSelectLot"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\", padding:\"0.4rem\", fontSize: \"1.25rem\"}"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 56,
      "name": "Input",
      "parent": 37,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "ref": {
          "type": "code",
          "value": "(ref) => ref && (ref.inputRef.current.style.backgroundColor = \"#DFDFDF\",ref.inputRef.current.style.borderColor = \"#DBDBDB\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.issueStockDetail?.lot?.mfc_no || \"\""
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "Input",
      "parent": 39,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "ref": {
          "type": "code",
          "value": "(ref) => ref && (ref.inputRef.current.style.backgroundColor = \"#DFDFDF\",ref.inputRef.current.style.borderColor = \"#DBDBDB\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.expiryDate || \"\""
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "Input",
      "parent": 41,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.readOnly || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "quantity"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "ref": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.issueStockDetail?.quantity|| \"\""
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "FormField",
      "parent": 11,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "FormField",
      "parent": 11,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "FormField",
      "parent": 11,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "FormField",
      "parent": 11,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\"}"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "div",
      "parent": 59,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\",minWidth: \"max-content\"}"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "label",
      "parent": 63,
      "props": {
        "children": {
          "type": "value",
          "value": "เหตุผลในการตัดจ่ายสินค้า"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": null,
      "id": 66,
      "name": "label",
      "parent": 63,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "label",
      "parent": 61,
      "props": {
        "children": {
          "type": "value",
          "value": "ตัดจ่ายสินค้าไปยัง"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", opacity: props.disabledProvider ?\"0.5\":\"\"}"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "Dropdown",
      "parent": 60,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.readOnly || !!props.issueStockList?.length || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "reason"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.distributionReasonOptions|| []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", opacity: !!props.issueStockList?.length ?\"1\":\"\"}"
        },
        "value": {
          "type": "code",
          "value": "props.reason || \"\""
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "FormField",
      "parent": 30,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "FormField",
      "parent": 30,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "14"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "FormField",
      "parent": 30,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "label",
      "parent": 70,
      "props": {
        "children": {
          "type": "value",
          "value": "หมายเหตุเพิ่มเติม"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", minWidth: \"max-content\"}"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "Input",
      "parent": 71,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "disabled": {
          "type": "code",
          "value": "props.readOnly || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "readOnly": {
          "type": "code",
          "value": "!!props.issueStockList?.length|| false"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.remark || \"\""
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "Button",
      "parent": 72,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มรายการ"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledAdd || false"
        },
        "onClick": {
          "type": "code",
          "value": "props.onAdd"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "FormField",
      "parent": 25,
      "props": {
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 77,
      "name": "Button",
      "parent": 76,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิก"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "disabled": {
          "type": "code",
          "value": "props.readOnly || false"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancel"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "Icon",
      "parent": 55,
      "props": {
        "name": {
          "type": "value",
          "value": "bars"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "div",
      "parent": 55,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{\"position\":\"absolute\",\"fontSize\":\"0.55rem\",\"right\":\"2px\",\"bottom\":\"3px\"}"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 80,
      "name": "Icon",
      "parent": 79,
      "props": {
        "name": {
          "type": "value",
          "value": "search"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "div",
      "parent": 55,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{\"position\":\"absolute\",\"backgroundColor\":\"rgb(33, 133, 208)\",\"bottom\":\"7px\",\"right\":\"7px\",\"width\":\"9px\",\"height\":\"9px\"}"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "div",
      "parent": 62,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ProviderElement"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "div",
      "parent": 62,
      "props": {
        "children": {
          "type": "code",
          "value": "props.providerLabel || \"\""
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\", top: \"-16px\", left: 0, color: \"#5C5D5D\", fontSize: \"0.75rem\"}"
        }
      },
      "seq": 84,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": true,
  "name": "CardStockManagementTabIssueUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
