import WasmController from 'react-lib/frameworks/WasmController';

// APIs
import TriageLatest from "issara-sdk/apis/TriageLatest_core";
import TriageList from "issara-sdk/apis/TriageList_core";
import TriageDetail from "issara-sdk/apis/TriageDetail_core";
import AdrList from "issara-sdk/apis/AdverseReactionList_apps_ADR";
import ChoiceView from "issara-sdk/apis/ChoiceView_core";
import FormDataLatest from "issara-sdk/apis/FormDataLatest_apps_PTM"

import { SaveOrCreate } from './Assessment';

export type State =
  {
    ChoiceTriage?: any[],
    selectedEncounter?: any,
    TriageSequence?: {
      sequenceIndex: string | null,
      triage?: {
        patient_id?: number
        division?: number,
        id?: number | null,
        pk?: string | null,
        chief_complaint?: string
        arrive_status?: any,
        arrive_date?: any,
        arrive_time?: any,
        case?: any,
        patient_type?: any,
        referer?: any,
        triage_level?: any,
      },
      adrList?: any[],
      adr?: any,
    } | null,
    successMessage?: any;
    errorMessage?: any;
    buttonLoadCheck?: any;
  }

export const StateInitial: State =
  {
    TriageSequence: null,
    ChoiceTriage: [],
    successMessage: null,
    errorMessage: null,
  }

export type Event =
  { message: "RunSequence", params: {} }
  | { message: "GetMasterData", params: any }
  | { message: "GetTriageLevel", params: any }


export type Data =
  {
    division?: number,
    device?: number,
    masterData?: Record<string, any>
  }

export const DataInitial =
  {
  }

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any

export const SetupTriage: Handler = async (controller, params) => {
  let state = controller.getState();

  if (!state.TriageSequence) return;
  if (!Number.isInteger(state.selectedEncounter?.patient_id)) return;

  // Master data
  await controller.handleEvent({
    message: "GetMasterData", params: {
      masters: [
        ["arriveStatus", {}],
        ["case", {}],
        ["symptoms", {}],
      ]
    }
  });

  // Retrieve patient info
  const [triage, assessment] = await GetInfo(controller, params);

  state = controller.getState();

  const arriveStatus = controller.data.masterData?.arriveStatus?.find((item: any) =>
    item.code === assessment?.data?.arrive_status
  )?.id

  const statusInitial = controller.data.masterData?.arriveStatus?.find((item: any) =>
    item.code === "WLK"
  )?.id

  controller.setState({
    TriageSequence: {
      ...state.TriageSequence,
      sequenceIndex: "SaveTriage",
      triage: triage ? triage : {
        patient_id: state.selectedEncounter?.patient_id,
        division: controller.data.division,
        chief_complaint: assessment?.data?.chief_complaint || "",
        triage_level: assessment?.data?.triage_level || "5",
        arrive_status: arriveStatus || statusInitial,
        case: 54
      },
      // adrList: adrList,
      adr: {
        action: null,
        note: ""
      },
    }
  });
}

export const SaveTriage: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.TriageSequence) return;
  if (!Number.isInteger(state.selectedEncounter?.patient_id)) return;
  if (params?.action === "save") {
    if (state?.TriageSequence?.triage?.id &&
      Number.isInteger(state?.TriageSequence?.triage?.id)) {
      controller.setState({
        successMessage: { ...state.successMessage, "Triage": null },
        errorMessage: { ...state.errorMessage, "Triage": null },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "LOADING",
        },
      });
      const triage = await TriageDetail.update({
        pk: state.TriageSequence.triage.id,
        data: {
          ...state.TriageSequence.triage,
        } as any,
        apiToken: controller.apiToken
      });

      if (params.updateOnly) {
        return
      }

      if (triage[0]) {
        controller.setState({
          successMessage: {
            ...state.successMessage,
            [params?.sequence]: "successMessage",
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [params.buttonLoadKey]: "SUCCESS",
          },
        });
      } else {
        controller.setState({
          errorMessage: { ...state.errorMessage, [params?.sequence]: triage[1] },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [params.buttonLoadKey]: "ERROR",
          },
        });
      }
    } else {
      controller.setState({
        successMessage: { ...state.successMessage, "Triage": null },
        errorMessage: { ...state.errorMessage, "Triage": null },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "LOADING",
        },
      });
      const triage = await TriageList.create({
        data: {
          ...state.TriageSequence.triage,
          patient_id: state.selectedEncounter.patient_id
        },
        apiToken: controller.apiToken
      });

      if (triage[0]) {
        controller.setState({
          successMessage: {
            ...state.successMessage,
            [params?.sequence]: "successMessage",
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [params.buttonLoadKey]: "SUCCESS",
          },
        });
      } else {
        controller.setState({
          errorMessage: { ...state.errorMessage, [params?.sequence]: triage[1] },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [params.buttonLoadKey]: "ERROR",
          },
        });
      }
    }

    if (state?.TriageSequence?.adr?.action !== null) {
      const adr = await AdrList.create({
        data: {
          patient: state.selectedEncounter.patient_id,
          ...state?.TriageSequence?.adr,
        },
        apiToken: controller.apiToken
      });
      console.log(adr[1] ? adr[1] : adr[0]);
    }

    const [triage, assessment] = await GetInfo(controller, params);

    // Update Patient Assessment
    if (assessment?.id && triage?.id) {
      const status = (controller.data.masterData?.arriveStatus || []).find((item: any) =>
        item.id === triage.arrive_status)?.code

      SaveOrCreate(controller, {
        id: assessment.id,
        assessment: {
          status: assessment.status,
          form_code: "CardPatientAssessment",
          form_name: "แบบประเมินแรกรับ",
          form_version: "0.1",
          encounter: assessment.encounter,
          data: {
            arrive_status: status || "",
            chief_complaint: triage.chief_complaint,
            triage_level: triage.triage_level
          },
        },
        params: {
          status: assessment.status === "EDITED" ? "SAVE" :
            assessment.status === "CONFIRMED" ? "CONFIRM" :
              ""
        }
      });
    }


    controller.setState({
      TriageSequence: {
        ...state.TriageSequence,
        sequenceIndex: "SaveTriage",
        ...(triage ? { triage } : {}),
        // ...(adrList ? { adrList } : {}),
        adr: {
          action: null,
          note: ""
        },
      }
    });
  } else if (params.action === "clearMessage") {
    controller.setState({
      successMessage: { ...state.successMessage, "Triage": null },
      errorMessage: { ...state.errorMessage, "Triage": null },
    });
  } else if (params.action === "refreshTriage") {
    const [triage, assessment] = await GetInfo(controller, params);
    const arriveStatus = controller.data.masterData?.arriveStatus?.find((item: any) =>
      item.code === assessment?.data?.arrive_status
    )?.id

    const statusInitial = controller.data.masterData?.arriveStatus?.find((item: any) =>
      item.code === "WLK"
    )?.id

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: null,
      },
      successMessage: { ...state.successMessage, "Triage": null },
      errorMessage: { ...state.errorMessage, "Triage": null },
      TriageSequence: {
        ...state.TriageSequence,
        triage: triage ? triage : {
          patient_id: state.selectedEncounter.patient_id,
          division: controller.data.division,
          chief_complaint: assessment?.data?.chief_complaint || "",
          triage_level: assessment?.data?.triage_level || "5",
          arrive_status: arriveStatus || statusInitial,
          case: 54
        },
      }
    });
  }
}

const GetInfo: Handler = async (controller, params) => {
  const state = controller.getState();
  const [triage, assessment] = await Promise.all([
    TriageLatest.retrieve({
      params: { patient: state.selectedEncounter?.patient_id },
      apiToken: controller.apiToken
    }),
    FormDataLatest.retrieve({
      apiToken: controller.apiToken,
      params: {
        encounter: state.selectedEncounter?.id,
        form_code: "CardPatientAssessment",
        form_version: "0.1"
      }
    })
  ])
  // const adrList = await AdrList.list({
  //   params: { patient: state.selectedEncounter.patient_id },
  //   apiToken: controller.apiToken
  // });
  // console.log(adrList[1] ? adrList[1] : adrList[0]);


  return [triage[0], assessment[0]];
}

export const GetTriageLevel: Handler = async (controller, params) => {
  const choiceTriage = await ChoiceView.get({
    params: {
      model: "core.Triage",
      field: "triage_level",
      name_as_id: true
    },
    apiToken: controller.apiToken
  })
  controller.setState({ ChoiceTriage: choiceTriage[0] })
}