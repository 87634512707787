import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button,
  Input,
  Checkbox
} from 'semantic-ui-react'

const ModChangePasswordUX = (props: any) => {
    return(
      <div>
        
        <div
          style={{ backgroundColor: "#C6EBF3", fontSize: "1.2rem", padding: "10px"}}>
          {}
          <div
            style={{marginTop:"5px",fontWeight: "bold", fontSize: "1.2rem"}}>
            เปลี่ยนรหัสผ่าน
          </div>
        </div>
        <div
          style={{display:"flex",marginBottom:"10px"}}>
          
          <div
            style={{ fontWeight: "bold", width: "15%",margin:"10px" }}>
            ชื่อเข้าใช้งาน
          </div>
          <div
            style={{ fontWeight: "bold", width: "20%",margin:"10px" }}>
            {props.username}
          </div>
        </div>
        <div
          style={{display:"flex",marginBottom:"10px"}}>
          
          <div
            style={{ fontWeight: "bold", width: props.pw2CheckTeacherFlag?"16.5rem":"17.5%",margin:"10px" }}>
            
            <div>
              PASSWORD
            </div>
          </div>
          <div
            style={{ fontWeight: "bold", width: "20%",margin:"10px" }}>
            ********
          </div>
          <div
            style={{ fontWeight: "bold", width: "20%",margin:"10px" }}>
            {props.changePass1}
          </div>
        </div>
        <div
          style={{fontWeight: "normal", display: props.pw2CheckTeacherFlag?"":"none", marginTop: "0.5rem", marginBottom:"1rem"}}>
          {props.passwordNote}
        </div>
        <div
          style={{margin:"10px", backgroundColor: "rgba(198, 235, 243, 0.2)",display:props.showChangePass1 ?"": "none"}}>
          
          <div
            style={{padding: "0px 10px 7px 1.5rem", backgroundColor: "white"}}>
            กรุณาระบุรหัสผ่านที่ต้องการเปลี่ยนใหม่
          </div>
          <div
            style={{display:"flex", marginLeft:"20px", paddingTop: "10px"}}>
            
            <div
              style={{ fontWeight: "bold", width: "20%",display:"flex",alignItems:"center"}}>
              PASSWORD
            </div>
            <Input
              icon={props.eyePass1}
              onChange={props.changeValue("password1")}
              style={{width:"25%"}}
              type={props.typePassword1}
              value={props.password1}>
            </Input>
            <div>
              
            </div>
          </div>
          <div
            style={{display:"flex", margin:"10px  0px 20px 20px", paddingBottom: "10px"}}>
            
            <div
              style={{ fontWeight: "bold", width: "20%",display:"flex",alignItems:"center"}}>
              ยืนยัน PASSWORD
            </div>
            <Input
              icon={props.eyeConfirm1}
              onChange={props.changeValue("confirm_password1")}
              style={{width:"25%"}}
              type={props.typeConfirm1}
              value={props.confirm_password1}>
            </Input>
          </div>
        </div>
        <div
          style={{padding: "0 1rem", width: "100%", display: props.showPassword2 && props.pw2CheckTeacherFlag? "":"none" , marginTop: "-0.5rem"}}>
          
          <div
            style={{borderTop: "1px dashed rgba(0,0,0,0.75)"}}>
            
          </div>
        </div>
        <div
          style={{marginBottom:"10px", display: props.showPassword2?"flex":"none"}}>
          
          <div
            style={{ fontWeight: "bold", width: props.pw2CheckTeacherFlag?"16.5rem":"17.5%",margin:"10px" }}>
            
            <div>
              PINCODE
            </div>
            <div
              style={{fontWeight: "normal", display: props.pw2CheckTeacherFlag?"":"none", marginTop: "0.5rem"}}>
              สำหรับ Approve งาน นศ.
            </div>
          </div>
          <div
            style={{ fontWeight: "bold", width: "20%",margin:"10px" }}>
            ********
          </div>
          <div
            style={{ fontWeight: "bold", width: "20%",margin:"10px" }}>
            {props.changePass2}
          </div>
        </div>
        <div
          style={{ marginLeft: "10px",display: props.showPassword2 && props.pw2CheckTeacherFlag? "flex":"none", marginTop: "-0.75rem" }}>
          
          <Checkbox
            checked={props.approvalPassword}
            onChange={()=>props.onChangeApprovalPassword?.(!props.approvalPassword)}
            style={{marginRight: "0.5rem"}}>
          </Checkbox>
          <button
            onClick={()=>props.onChangeApprovalPassword?.(!props.approvalPassword)}
            style={{color: "red", padding:0, background: "transparent", fontWeight: "normal", border:"none", cursor: "pointer"}}>
            Only use PINCODE for Checkout/Approval
          </button>
        </div>
        <div
          style={{margin:"10px", backgroundColor: "rgba(198, 235, 243, 0.2)",display:props.showChangePass2 ?"": "none"}}>
          
          <div
            style={{padding: "6px 10px 7px 1.5rem", backgroundColor: "white", display: "flex", alignItems: "center"}}>
            
            <div>
              กรุณาระบุ PINCODE ที่ต้องการเปลี่ยนใหม่
            </div>
            <div>
              {props.InfoPopup}
            </div>
          </div>
          <div
            style={{display:"flex", marginLeft:"20px", paddingTop: "10px"}}>
            
            <div
              style={{ fontWeight: "bold", width: "20%",display:"flex",alignItems:"center"}}>
              PINCODE
            </div>
            <Input
              icon={props.eyePass2}
              onChange={props.changeValue("password2")}
              style={{width:"25%"}}
              type={props.typePassword2}
              value={props.password2}>
            </Input>
          </div>
          <div
            style={{display:"flex", margin:"10px  0px 20px 20px", paddingBottom: "10px"}}>
            
            <div
              style={{ fontWeight: "bold", width: "20%",display:"flex",alignItems:"center"}}>
              ยืนยัน PINCODE
            </div>
            <Input
              icon={props.eyeConfirm2}
              onChange={props.changeValue("confirm_password2")}
              style={{width:"25%"}}
              type={props.typeConfirm2}
              value={props.confirm_password2}>
            </Input>
          </div>
        </div>
        <div
          style={{margin:"10px"}}>
          {props.ErrorMessages}
        </div>
        <div
          style={{display:"flex",justifyContent:"flex-end",margin:"15px"}}>
          
          <div
            style={{marginRight:"5px"}}>
            {props.buttonSave}
          </div>
          <Button
            color="red"
            onClick={props.OnCancel}
            size="small">
            ยกเลิก
          </Button>
        </div>
      </div>
    )
}


export default ModChangePasswordUX

export const screenPropsDefault = {"title":"9:00-12:00"}

/* Date Time : Fri Aug 02 2024 15:33:19 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ backgroundColor: \"#C6EBF3\", fontSize: \"1.2rem\", padding: \"10px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "เปลี่ยนรหัสผ่าน"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\",fontWeight: \"bold\", fontSize: \"1.2rem\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",justifyContent:\"flex-end\",margin:\"15px\"}"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "Button",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิก"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.OnCancel"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",marginBottom:\"10px\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อเข้าใช้งาน"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"15%\",margin:\"10px\" }"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "code",
          "value": "props.username"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"20%\",margin:\"10px\" }"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",marginBottom:\"10px\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: props.pw2CheckTeacherFlag?\"16.5rem\":\"17.5%\",margin:\"10px\" }"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": "********"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"20%\",margin:\"10px\" }"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{margin:\"10px\", backgroundColor: \"rgba(198, 235, 243, 0.2)\",display:props.showChangePass1 ?\"\": \"none\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginBottom:\"10px\", display: props.showPassword2?\"flex\":\"none\"}"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "code",
          "value": "props.changePass1"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"20%\",margin:\"10px\" }"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "กรุณาระบุรหัสผ่านที่ต้องการเปลี่ยนใหม่"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0px 10px 7px 1.5rem\", backgroundColor: \"white\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", marginLeft:\"20px\", paddingTop: \"10px\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": "PASSWORD"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"20%\",display:\"flex\",alignItems:\"center\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "Input",
      "parent": 21,
      "props": {
        "icon": {
          "type": "code",
          "value": "props.eyePass1"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"password1\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"25%\"}"
        },
        "type": {
          "type": "code",
          "value": "props.typePassword1"
        },
        "value": {
          "type": "code",
          "value": "props.password1"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", margin:\"10px  0px 20px 20px\", paddingBottom: \"10px\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": "ยืนยัน PASSWORD"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"20%\",display:\"flex\",alignItems:\"center\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "Input",
      "parent": 24,
      "props": {
        "icon": {
          "type": "code",
          "value": "props.eyeConfirm1"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"confirm_password1\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"25%\"}"
        },
        "type": {
          "type": "code",
          "value": "props.typeConfirm1"
        },
        "value": {
          "type": "code",
          "value": "props.confirm_password1"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: props.pw2CheckTeacherFlag?\"16.5rem\":\"17.5%\",margin:\"10px\" }"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "********"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"20%\",margin:\"10px\" }"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "code",
          "value": "props.changePass2"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"20%\",margin:\"10px\" }"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{margin:\"10px\", backgroundColor: \"rgba(198, 235, 243, 0.2)\",display:props.showChangePass2 ?\"\": \"none\"}"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "div",
      "parent": 30,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"6px 10px 7px 1.5rem\", backgroundColor: \"white\", display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "div",
      "parent": 30,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", marginLeft:\"20px\", paddingTop: \"10px\"}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 30,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", margin:\"10px  0px 20px 20px\", paddingBottom: \"10px\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 32,
      "props": {
        "children": {
          "type": "value",
          "value": "PINCODE"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"20%\",display:\"flex\",alignItems:\"center\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "div",
      "parent": 33,
      "props": {
        "children": {
          "type": "value",
          "value": "ยืนยัน PINCODE"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"20%\",display:\"flex\",alignItems:\"center\"}"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "Input",
      "parent": 32,
      "props": {
        "icon": {
          "type": "code",
          "value": "props.eyePass2"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"password2\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"25%\"}"
        },
        "type": {
          "type": "code",
          "value": "props.typePassword2"
        },
        "value": {
          "type": "code",
          "value": "props.password2"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "Input",
      "parent": 33,
      "props": {
        "icon": {
          "type": "code",
          "value": "props.eyeConfirm2"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"confirm_password2\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"25%\"}"
        },
        "type": {
          "type": "code",
          "value": "props.typeConfirm2"
        },
        "value": {
          "type": "code",
          "value": "props.confirm_password2"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "style": {
          "type": "code",
          "value": "{marginRight:\"5px\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ErrorMessages"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"10px\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "PASSWORD"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.passwordNote"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", display: props.pw2CheckTeacherFlag?\"\":\"none\", marginTop: \"0.5rem\", marginBottom:\"1rem\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 1rem\", width: \"100%\", display: props.showPassword2 && props.pw2CheckTeacherFlag? \"\":\"none\" , marginTop: \"-0.5rem\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "div",
      "parent": 44,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{borderTop: \"1px dashed rgba(0,0,0,0.75)\"}"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": "PINCODE"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "div",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": "สำหรับ Approve งาน นศ."
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", display: props.pw2CheckTeacherFlag?\"\":\"none\", marginTop: \"0.5rem\"}"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"10px\",display: props.showPassword2 && props.pw2CheckTeacherFlag? \"flex\":\"none\", marginTop: \"-0.75rem\" }"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "Checkbox",
      "parent": 48,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.approvalPassword"
        },
        "label": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "()=>props.onChangeApprovalPassword?.(!props.approvalPassword)"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.5rem\"}"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "button",
      "parent": 48,
      "props": {
        "children": {
          "type": "value",
          "value": "Only use PINCODE for Checkout/Approval"
        },
        "onClick": {
          "type": "code",
          "value": "()=>props.onChangeApprovalPassword?.(!props.approvalPassword)"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\", padding:0, background: \"transparent\", fontWeight: \"normal\", border:\"none\", cursor: \"pointer\"}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 31,
      "props": {
        "children": {
          "type": "value",
          "value": "กรุณาระบุ PINCODE ที่ต้องการเปลี่ยนใหม่"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "div",
      "parent": 31,
      "props": {
        "children": {
          "type": "code",
          "value": "props.InfoPopup"
        }
      },
      "seq": 54,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 60,
  "isMounted": false,
  "memo": false,
  "name": "ModChangePasswordUX",
  "project": "cudent2",
  "screenPropsDefault": {
    "title": "9:00-12:00"
  },
  "width": 70
}

*********************************************************************************** */
