export default function toPDFMakeData(props: any) {
  return {
    pageSize: `A4`,
    content: [
      {
        alignment: `center`,
        width: `auto`,
        decoration: ``,
        color: ``,
        decorationStyle: ``,
        decorationColor: ``,
        bold: `true`,
        preserveLeadingSpaces: true,
        fontSize: `18`,
        pageBreak: ``,
        text: `ใบรับรองแพทย์`,
      },
      {
        alignment: `center`,
        color: ``,
        fontSize: `18`,
        preserveLeadingSpaces: true,
        width: `auto`,
        pageBreak: ``,
        text: `5 โรค`,
        decorationColor: ``,
        decoration: ``,
        bold: `true`,
        decorationStyle: ``,
      },
      {
        width: `auto`,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        fontSize: 15,
        text: ` `,
        color: ``,
        pageBreak: ``,
        bold: false,
        alignment: `left`,
        decorationStyle: ``,
        decoration: ``,
      },
      {
        columns: [
          {
            decorationStyle: ``,
            decorationColor: ``,
            width: 420,
            alignment: `left`,
            pageBreak: ``,
            color: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            bold: false,
            text: ``,
            fontSize: 15,
          },
          {
            text: `วันที่`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            bold: true,
            fontSize: 15,
            decorationStyle: ``,
            alignment: `left`,
            decoration: ``,
            width: `auto`,
            color: ``,
            decorationColor: ``,
          },
          {
            preserveLeadingSpaces: true,
            alignment: `left`,
            decoration: ``,
            width: 5,
            fontSize: 15,
            decorationColor: ``,
            pageBreak: ``,
            decorationStyle: ``,
            bold: false,
            color: ``,
            text: ``,
          },
          {
            decorationColor: ``,
            alignment: `center`,
            preserveLeadingSpaces: true,
            bold: true,
            width: `auto`,
            decoration: ``,
            fontSize: 15,
            text: props.items?.formatCreatedDate,
            pageBreak: ``,
            decorationStyle: ``,
            color: ``,
          },
        ],
      },
      {
        text: ` `,
        width: `auto`,
        fontSize: 15,
        decoration: ``,
        bold: false,
        pageBreak: ``,
        alignment: `left`,
        preserveLeadingSpaces: true,
        color: ``,
        decorationStyle: ``,
        margin: [0, 0, 0, 0],
        decorationColor: ``,
      },
      {
        columns: [
          {
            text: ``,
            fontSize: 15,
            color: ``,
            alignment: `left`,
            pageBreak: ``,
            bold: false,
            width: 40,
            decoration: ``,
            decorationColor: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
          },
          {
            alignment: `left`,
            color: ``,
            decorationColor: ``,
            fontSize: 15,
            width: `auto`,
            bold: false,
            decorationStyle: ``,
            text: `ขอรับรองว่า `,
            preserveLeadingSpaces: true,
            decoration: ``,
            pageBreak: ``,
          },
          {
            decorationColor: ``,
            alignment: `left`,
            fontSize: 15,
            width: 10,
            pageBreak: ``,
            decorationStyle: ``,
            text: ``,
            color: ``,
            preserveLeadingSpaces: true,
            bold: false,
            decoration: ``,
          },
          {
            stack: [
              {
                decorationColor: ``,
                bold: false,
                fontSize: 15,
                margin: [0, 0, 0, -15],
                preserveLeadingSpaces: true,
                width: `auto`,
                color: ``,
                text: props.items?.patientInfo?.full_name,
                pageBreak: ``,
                decoration: ``,
                alignment: `left`,
                decorationStyle: ``,
              },
              {
                alignment: `left`,
                text: `....................................................................................................................................................................`,
                pageBreak: ``,
                decorationStyle: ``,
                width: `auto`,
                decoration: ``,
                color: ``,
                fontSize: 15,
                decorationColor: ``,
                preserveLeadingSpaces: true,
                bold: false,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            fontSize: 15,
            decorationColor: ``,
            color: ``,
            alignment: `left`,
            pageBreak: ``,
            decorationStyle: ``,
            bold: false,
            text: `หมายเลขประจำตัวประชาชน / เลขที่หนังสือเดินทาง / อื่นๆ`,
            decoration: ``,
            width: `auto`,
          },
          {
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            fontSize: 15,
            width: 10,
            color: ``,
            alignment: `left`,
            pageBreak: ``,
            text: ``,
            decorationColor: ``,
            decoration: ``,
            bold: false,
          },
          {
            stack: [
              {
                margin: [0, 0, 0, -15],
                bold: false,
                decoration: ``,
                color: ``,
                fontSize: 15,
                decorationColor: ``,
                decorationStyle: ``,
                alignment: `left`,
                width: `auto`,
                text:
                  props.items?.patientInfo?.citizen_no === "" ||
                  props.items?.patientInfo?.citizen_no ===
                    "Non citizen identity"
                    ? props.items?.patientInfo?.citizen_passport === "" ||
                      props.items?.patientInfo?.citizen_passport ===
                        "Non citizen identity"
                      ? " "
                      : props.items?.patientInfo?.citizen_passport
                    : props.items?.patientInfo?.citizen_no,
                preserveLeadingSpaces: true,
                pageBreak: ``,
              },
              {
                width: `auto`,
                text: `........................................................................................................`,
                fontSize: 15,
                pageBreak: ``,
                color: ``,
                alignment: `left`,
                decoration: ``,
                preserveLeadingSpaces: true,
                decorationStyle: ``,
                decorationColor: ``,
                margin: [0, 0, 0, 0],
                bold: false,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            alignment: `left`,
            bold: false,
            color: ``,
            decoration: ``,
            decorationColor: ``,
            text: `อายุ `,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
            fontSize: 15,
            decorationStyle: ``,
          },
          {
            text: ``,
            decorationStyle: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            bold: false,
            decorationColor: ``,
            width: 10,
            color: ``,
            alignment: `left`,
            fontSize: 15,
          },
          {
            stack: [
              {
                text: `${props.items?.ageYear} ปี ${props.items?.ageMonth} เดือน ${props.items?.ageDay} วัน`,
                alignment: `left`,
                width: `auto`,
                decorationColor: ``,
                preserveLeadingSpaces: true,
                bold: false,
                decorationStyle: ``,
                decoration: ``,
                margin: [0, 0, 0, -15],
                fontSize: 15,
                color: ``,
                pageBreak: ``,
              },
              {
                bold: false,
                margin: [0, 0, 0, 0],
                alignment: `left`,
                decoration: ``,
                pageBreak: ``,
                preserveLeadingSpaces: true,
                fontSize: 15,
                decorationColor: ``,
                color: ``,
                text: `..............................................................`,
                width: `auto`,
                decorationStyle: ``,
              },
            ],
          },
          {
            width: `auto`,
            decorationColor: ``,
            text: `ได้รับการตรวจพบว่า`,
            color: ``,
            decorationStyle: ``,
            alignment: `left`,
            decoration: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            fontSize: 15,
            bold: false,
          },
          {
            fontSize: 15,
            pageBreak: ``,
            color: ``,
            decorationStyle: ``,
            bold: false,
            preserveLeadingSpaces: true,
            text: ``,
            decoration: ``,
            decorationColor: ``,
            alignment: `left`,
            width: 10,
          },
          {
            stack: [
              {
                bold: false,
                decorationStyle: ``,
                decorationColor: ``,
                alignment: `left`,
                fontSize: 15,
                preserveLeadingSpaces: true,
                decoration: ``,
                text:
                  props.items?.isFoundDisease === "Found"
                    ? "พบเงื่อนไขบางอย่างดังต่อไปนี้"
                    : "ปราศจากโรคดังต่อไปนี้",
                color: ``,
                pageBreak: ``,
                margin: [0, 0, 0, -15],
                width: `auto`,
              },
              {
                text: `...........................................................................`,
                margin: [0, 0, 0, 0],
                bold: false,
                color: ``,
                preserveLeadingSpaces: true,
                width: `auto`,
                pageBreak: ``,
                alignment: `left`,
                decorationColor: ``,
                fontSize: 15,
                decoration: ``,
                decorationStyle: ``,
              },
            ],
          },
        ],
      },
      {
        bold: false,
        fontSize: 15,
        pageBreak: ``,
        preserveLeadingSpaces: true,
        color: ``,
        width: `auto`,
        decorationStyle: ``,
        text: ` `,
        decoration: ``,
        decorationColor: ``,
        alignment: `left`,
      },
      {
        columns: [
          {
            stack: [
              {
                decorationColor: ``,
                preserveLeadingSpaces: true,
                text: props.items?.count[0],
                alignment: `left`,
                color: ``,
                width: `auto`,
                margin: [55, 0, 0, 0],
                bold: false,
                pageBreak: ``,
                fontSize: 15,
                decorationStyle: ``,
                decoration: ``,
              },
              {
                decoration: ``,
                width: `auto`,
                decorationStyle: ``,
                preserveLeadingSpaces: true,
                pageBreak: ``,
                color: ``,
                alignment: `left`,
                bold: false,
                text: props.items?.count[1],
                decorationColor: ``,
                margin: [55, 0, 0, 0],
                fontSize: 15,
              },
              {
                color: ``,
                bold: false,
                decorationColor: ``,
                width: `auto`,
                fontSize: 15,
                margin: [55, 0, 0, 0],
                decoration: ``,
                decorationStyle: ``,
                text: props.items?.count[2],
                alignment: `left`,
                pageBreak: ``,
                preserveLeadingSpaces: true,
              },
              {
                margin: [55, 0, 0, 0],
                pageBreak: ``,
                preserveLeadingSpaces: true,
                color: ``,
                width: `auto`,
                text: props.items?.count[3],
                fontSize: 15,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                alignment: `left`,
                bold: false,
              },
              {
                width: `auto`,
                text: props.items?.count[4],
                decorationStyle: ``,
                fontSize: 15,
                decorationColor: ``,
                margin: [55, 0, 0, 0],
                color: ``,
                preserveLeadingSpaces: true,
                decoration: ``,
                alignment: `left`,
                pageBreak: ``,
                bold: false,
              },
            ],
          },
          {
            stack: [
              {
                preserveLeadingSpaces: true,
                decorationColor: ``,
                fontSize: 15,
                alignment: `left`,
                decorationStyle: ``,
                decoration: ``,
                bold: false,
                color: ``,
                pageBreak: ``,
                margin: [-189, 0, 0, 0],
                width: `auto`,
                text: props.items?.isFirstDisease ? "โรคเรื้อน (Leprosy)" : "",
              },
              {
                pageBreak: ``,
                width: `auto`,
                decoration: ``,
                fontSize: 15,
                decorationStyle: ``,
                text: props.items?.isSecondDisease
                  ? "วัณโรคระยะอันตราย (Tuberculosis)"
                  : "",
                color: ``,
                decorationColor: ``,
                preserveLeadingSpaces: true,
                bold: false,
                margin: [-189, 0, 0, 0],
                alignment: `left`,
              },
              {
                margin: [-189, 0, 0, 0],
                color: ``,
                decorationStyle: ``,
                alignment: `left`,
                pageBreak: ``,
                fontSize: 15,
                text: props.items?.isThirdDisease
                  ? "โรคเท้าช้าง (Elephantiasis)"
                  : "",
                decorationColor: ``,
                width: `auto`,
                decoration: ``,
                preserveLeadingSpaces: true,
                bold: false,
              },
              {
                alignment: `left`,
                fontSize: 15,
                color: ``,
                decorationStyle: ``,
                decorationColor: ``,
                margin: [-189, 0, 0, 0],
                decoration: ``,
                pageBreak: ``,
                bold: false,
                width: `auto`,
                text: props.items?.isFourthDisease
                  ? "โรคยาเสพติดให้โทษ (Drug Addiction)"
                  : "",
                preserveLeadingSpaces: true,
              },
              {
                alignment: `left`,
                text: props.items?.isFifthDisease
                  ? "โรคซิฟิลิสในระยะที่ 3 (Third step of syphilis)"
                  : "",
                pageBreak: ``,
                width: `auto`,
                decorationStyle: ``,
                margin: [-189, 0, 0, 0],
                fontSize: 15,
                decorationColor: ``,
                preserveLeadingSpaces: true,
                color: ``,
                bold: false,
                decoration: ``,
              },
            ],
          },
        ],
      },
      {
        color: ``,
        fontSize: 15,
        decorationStyle: ``,
        pageBreak: ``,
        text: ` `,
        width: `auto`,
        decoration: ``,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        bold: false,
        alignment: `left`,
      },
      {
        columns: [
          {
            text: `อื่นๆ (ถ้ามี)`,
            decorationStyle: ``,
            alignment: `left`,
            decoration: ``,
            decorationColor: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            bold: false,
            color: ``,
            fontSize: 15,
            width: `auto`,
          },
          {
            width: 10,
            fontSize: 15,
            decorationStyle: ``,
            pageBreak: ``,
            decorationColor: ``,
            bold: false,
            decoration: ``,
            color: ``,
            alignment: `left`,
            text: ``,
            preserveLeadingSpaces: true,
          },
          {
            stack: [
              {
                bold: false,
                color: ``,
                alignment: `left`,
                pageBreak: ``,
                decorationStyle: ``,
                text:
                  props.items?.otherNote === "" ? " " : props.items?.otherNote,
                decorationColor: ``,
                decoration: ``,
                margin: [0, 0, 0, -15],
                width: `auto`,
                preserveLeadingSpaces: true,
                fontSize: 15,
              },
              {
                decorationStyle: ``,
                pageBreak: ``,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
                bold: false,
                fontSize: 15,
                decoration: ``,
                text: `.......................................................................................................................................................................................`,
                alignment: `left`,
                decorationColor: ``,
                color: ``,
                width: `auto`,
              },
            ],
          },
        ],
      },
      {
        color: ``,
        alignment: `left`,
        text: `.............................................................................................................................................................................................................`,
        width: `auto`,
        pageBreak: ``,
        decorationStyle: ``,
        bold: false,
        decorationColor: ``,
        margin: [0, 0, 0, 0],
        preserveLeadingSpaces: true,
        decoration: ``,
        fontSize: 15,
      },
      {
        decorationColor: ``,
        margin: [0, 0, 0, 0],
        color: ``,
        pageBreak: ``,
        text: `.............................................................................................................................................................................................................`,
        fontSize: 15,
        bold: false,
        decorationStyle: ``,
        decoration: ``,
        alignment: `left`,
        preserveLeadingSpaces: true,
        width: `auto`,
      },
      {
        alignment: `left`,
        preserveLeadingSpaces: true,
        decorationColor: ``,
        color: ``,
        fontSize: 15,
        width: `auto`,
        bold: false,
        pageBreak: ``,
        text: ` `,
        decoration: ``,
        decorationStyle: ``,
      },
      {
        pageBreak: ``,
        decorationColor: ``,
        decorationStyle: ``,
        decoration: ``,
        alignment: `left`,
        color: ``,
        width: `auto`,
        fontSize: 15,
        text: ` `,
        preserveLeadingSpaces: true,
        bold: false,
      },
      {
        width: `auto`,
        text: ` `,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        pageBreak: ``,
        decoration: ``,
        bold: false,
        color: ``,
        fontSize: 15,
        alignment: `left`,
      },
      {
        fontSize: 15,
        width: `auto`,
        pageBreak: ``,
        decoration: ``,
        bold: false,
        alignment: `left`,
        text: ` `,
        decorationStyle: ``,
        color: ``,
        decorationColor: ``,
        preserveLeadingSpaces: true,
      },
      {
        fontSize: 15,
        preserveLeadingSpaces: true,
        pageBreak: ``,
        decoration: ``,
        decorationColor: ``,
        bold: false,
        decorationStyle: ``,
        width: `auto`,
        color: ``,
        alignment: `left`,
        text: ` `,
      },
      {
        bold: false,
        text: ` `,
        fontSize: 15,
        color: ``,
        pageBreak: ``,
        alignment: `left`,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        decorationColor: ``,
        decoration: ``,
        width: `auto`,
      },
      {
        columns: [
          {
            width: `auto`,
            decoration: ``,
            color: ``,
            decorationColor: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            alignment: `left`,
            text: `ลงนามแพทย์ผู้ตรวจ `,
            pageBreak: ``,
            bold: false,
          },
          {
            alignment: `left`,
            decorationStyle: ``,
            color: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationColor: ``,
            fontSize: 15,
            width: 30,
            bold: false,
            text: ``,
            pageBreak: ``,
          },
          {
            width: `auto`,
            text: `.............................................................`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationColor: ``,
            fontSize: 15,
            color: ``,
            alignment: `center`,
            decorationStyle: ``,
            bold: false,
          },
          {
            decorationStyle: ``,
            text: ` `,
            pageBreak: ``,
            fontSize: 15,
            alignment: `center`,
            bold: false,
            width: 30,
            decorationColor: ``,
            color: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
          },
          {
            decorationStyle: ``,
            decorationColor: ``,
            text: ` เลขใบประกอบวิชาชีพแพทย์ `,
            preserveLeadingSpaces: true,
            width: `auto`,
            bold: false,
            pageBreak: ``,
            fontSize: 15,
            alignment: `left`,
            decoration: ``,
            color: ``,
          },
          {
            color: ``,
            decorationStyle: ``,
            decorationColor: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decoration: ``,
            text: ``,
            bold: false,
            alignment: `left`,
            width: 30,
          },
          {
            decoration: `underline`,
            color: ``,
            alignment: `center`,
            fontSize: 15,
            text: props.items?.doctorCertNumber
              ? props.items?.doctorCertNumber
              : "                    ",
            pageBreak: ``,
            decorationStyle: `dotted`,
            decorationColor: ``,
            bold: false,
            preserveLeadingSpaces: true,
            width: `auto`,
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            alignment: `left`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            text: `		      `,
            fontSize: 15,
            bold: false,
            pageBreak: ``,
            decoration: ``,
            width: 134,
            decorationColor: ``,
          },
          {
            pageBreak: ``,
            fontSize: 15,
            width: `auto`,
            color: ``,
            bold: false,
            preserveLeadingSpaces: true,
            text: `(`,
            decoration: ``,
            decorationStyle: ``,
            alignment: `left`,
            decorationColor: ``,
          },
          {
            color: ``,
            pageBreak: ``,
            decorationColor: ``,
            bold: false,
            alignment: `center`,
            decorationStyle: ``,
            fontSize: 15,
            decoration: ``,
            width: `auto`,
            text: props.items?.doctorName,
            preserveLeadingSpaces: true,
          },
          {
            color: ``,
            decorationColor: ``,
            pageBreak: ``,
            fontSize: 15,
            text: `)`,
            bold: false,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decoration: ``,
            alignment: `left`,
            width: `auto`,
          },
        ],
      },
    ],
    defaultStyle: {
      font: `THSarabunNew`,
    },
    pageOrientation: `portrait`,
    header: props.header,
    footer: props.footer,
  };
}
