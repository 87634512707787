import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Label,
  Form,
  FormGroup,
  FormField,
  Dropdown,
  Button,
  Input
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardBillingDepositUX = (props: any) => {
    return(
      <div
        style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{ display:  "flex", justifyContent: "space-between",  padding: "10px 10px 0px 10px"  }}>
          {}
          <div
            style={{ fontSize: "1.2rem", fontWeight: "bold",display: "flex", alignItems: "center" }}>
            รับชำระเงินมัดจำ
            <div
              style={{marginLeft: "50px", display: "none"}}>
              เลขที่ใบเสร็จ : 
            </div>
            <Label
              style={{marginLeft: "50px"}}>
              {props.receiptCode}
            </Label>
          </div>
        </div>
        <div
          style={{padding: "0px 10px 10px 10px", display: props.viewMode ? "none" : "" }}>
          
          <hr>
          </hr>
        </div>
        <div
          style={{ margin: "0px 10px 15px" }}>
          {props.errorMessage}
        </div>
        <div
          style={{ margin: "0px 10px" }}>
          
          <Form>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                style={{ width: "100%" }}
                width={6}>
                <div
                  style={{ display: "flex",  width: "100%", alignItems: "center"  }}>
                  
                  <div
                    style={{ marginRight: "15px", width: "25%" }}>
                    รายการเงินมัดจำ
                  </div>
                  <div
                    style={{ width: "75%" }}>
                    
                    <Dropdown
                      disabled={props.selectDeposit?.receipt_no !== null}
                      fluid={true}
                      id="CardBillingDeposit-Dropdown-Wallet"
                      name="selectDeposit.id"
                      onChange={ props.onSelectWallet}
                      options={props.walletOptions}
                      selection={true}
                      value={props.selectDeposit?.id}>
                    </Dropdown>
                  </div>
                </div>
              </FormField>
              <FormField
                inline={true}
                width={7}>
                <div
                  style={{ display: "flex",  width: "100%", alignItems: "center"  }}>
                  
                  <div
                    style={{ margin: "0px 20px" }}>
                    ยอดเงินมัดจำ
                  </div>
                  <div
                    style={{ width: "20%" }}>
                    
                    <div>
                      {props.inputWalletValue}
                    </div>
                  </div>
                  <div
                    style={{ margin:   "0px 15px" }}>
                    
                    <Button
                      color={props.selectDeposit?.mode_code !== "" && props.selectDeposit?.init_balance !== "0.00" ? "yellow" : "green"}
                      disabled={props.selectDeposit?.receipt_no !== null}
                      onClick={props.onAddDeposit}
                      size="small">
                      {props.buttonAddDeposit}
                    </Button>
                  </div>
                </div>
              </FormField>
            </FormGroup>
          </Form>
        </div>
        <div
          style={{ display: "flex", margin: "20px 15px" }}>
          
          <div
            style={{ width: "0%" }}>
            
          </div>
          <div
            style={{ width: "100%" }}>
            
            <Table
              data={props.selectDepositData}
              headers="รหัส,รายการเงินมัดจำ,ราคา,ผู้ป่วยชำระ,ยอดตัดใข้,คงเหลือ"
              keys="code,mode_name,initBalanceFormat,patientPaidFormat,paymentFormat,balanceFormat"
              minRows="1"
              showPagination={false}
              widths="auto,^500,auto,auto">
            </Table>
          </div>
        </div>
        <div
          style={{ margin: "0px 15px" }}>
          
          <Form>
            <FormGroup
              inline={true}
              style={{ display: "flex", justifyContent: "flex-end" }}>
              <FormField
                inline={true}
                width={2}>
                <div
                  style={{ width: "100%", fontWeight: "700" }}>
                  ราคารวม
                  <Input
                    id="CardBillingDeposit-Input-SumTotal"
                    value={props.sumWallet?.sumTotal}>
                  </Input>
                </div>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <div
                  style={{ width: "100%", fontWeight: "700" }}>
                  ผู้ป่วยชำระ
                  <Input
                    id="CardBillingDeposit-Input-SumPayable"
                    value={props.sumWallet?.sumPayable}>
                  </Input>
                </div>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <div
                  style={{ width: "100%", fontWeight: "700" }}>
                  ยอดตัดใช้
                  <Input
                    id="CardBillingDeposit-Input-SumAmount"
                    value={props.sumWallet?.sumAmount}>
                  </Input>
                </div>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <div
                  style={{ width: "100%", fontWeight: "700" }}>
                  คงเหลือ
                  <Input
                    id="CardBillingDeposit-Input-SumRemain"
                    value={props.sumWallet?.sumRemian}>
                  </Input>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{ display: "flex", justifyContent: "flex-end", ...(!props.isEdit && {display: "none"} ) }}>
              <FormField
                inline={true}
                width={6}>
                <div
                  style={{ display: "flex",  width: "100%", alignItems: "center"  }}>
                  
                  <div
                    style={{ fontWeight: "700", marginRight: "15px" }}>
                    ชื่อผู้ป่วยบนใบเสร็จ
                  </div>
                  <div>
                    
                    <Dropdown
                      onChange={props.onChangePatient}
                      options={props.patientOptions}
                      selection={true}
                      value={props.patientValue}>
                    </Dropdown>
                  </div>
                </div>
              </FormField>
              <FormField
                inline={true}
                style={{ display: "flex", justifyContent: "flex-end" }}
                width={2}>
                <div>
                  
                  <Button
                    color="green"
                    onClick={props.billPayment}
                    size="small">
                    รับชำระเงิน
                  </Button>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{ ...(props.isEdit && {display: "none"} ) }}>
              <FormField
                inline={true}
                width={8}>
                <div>
                  
                  <Button
                    color="yellow"
                    size="small">
                    รายละเอียดการใช้เงินมัดจำ
                  </Button>
                </div>
              </FormField>
              <FormField
                inline={true}
                width={8}>
                <div
                  style={{ width: "100%", display: "flex", justifyContent: "space-between", }}>
                  
                  <div>
                    
                    <Button
                      color="blue"
                      onClick={props.onPrintDeposit}
                      size="small"
                      style={{ ...(props.selectDeposit?.status === "CANCELED" && { display: "none" }) }}>
                      พิมพ์สำเนาใบเสร็จ
                    </Button>
                    <Button
                      color="blue"
                      size="small"
                      style={{ display: "none" }}>
                      พิมพ์ใบแสดงรายละเอียด
                    </Button>
                  </div>
                  <div>
                    
                    <Button
                      color="red"
                      onClick={props.openCancelDeposit}
                      size="small"
                      style={{ ...(props.selectDeposit?.balance === "0.00" && { display: "none" }) }}>
                      ยกเลิกและคืนเงินมัดจำ
                    </Button>
                    <Button
                      color="black"
                      onClick={props.onCloseData}
                      size="small"
                      style={{ ...(props.selectDeposit?.receipt_no === "0.00" && { display: "none" }) }}>
                      ปิดหน้าต่าง
                    </Button>
                  </div>
                </div>
              </FormField>
            </FormGroup>
          </Form>
        </div>
        <div
          style={{ display:  "flex", justifyContent: "space-between",  padding: "40px 10px 0px 10px"  }}>
          
          <div
            style={{ fontSize: "1.2rem", fontWeight: "bold",display: "flex", alignItems: "center" }}>
            Deposit - History
          </div>
          <div
            style={{ fontSize: "1.2rem", fontWeight: "bold",display: "flex", alignItems: "center" }}>
            ประวัติรับชำระเงินมัดจำ
          </div>
        </div>
        <div
          style={{padding: "0px 10px 10px 10px", display: props.viewMode ? "none" : "" }}>
          
          <hr>
          </hr>
        </div>
        <div>
          
          <Form>
            <FormGroup
              inline={true}
              style={{ display: "flex" , justifyContent: "flex-end" }}>
              <FormField
                width={3}>
              </FormField>
              <FormField>
                <div>
                  รหัสเอกสาร
                </div>
              </FormField>
              <FormField>
                <div>
                  
                  <Input
                    id="CardBillingDeposit-Input-DepositCode"
                    name="depositCode"
                    onChange={ props.onChangeHistoryData}
                    value={props.depositCode}>
                  </Input>
                </div>
              </FormField>
              <FormField>
                <div>
                  สถานะ
                </div>
              </FormField>
              <FormField>
                <div>
                  
                  <Dropdown
                    clearable={true}
                    id="CardBillingDeposit-Dropdown-DepositStatus"
                    name="depositStatus"
                    onChange={ props.onChangeHistoryData}
                    options={props.depositStatusOptions}
                    selection={true}
                    value={props.depositStatus}>
                  </Dropdown>
                </div>
              </FormField>
              <FormField>
                <div>
                  วันที่ออกเอกสาร
                </div>
              </FormField>
              <FormField>
                <div>
                  
                  <DateTextBox
                    id="CardBillingDeposit-DateTextBox-StartDate"
                    onChange={props.onChangeStartDate}
                    value={props.startDate}>
                  </DateTextBox>
                </div>
              </FormField>
              <FormField>
                <div>
                  
                  <DateTextBox
                    id="CardBillingDeposit-DateTextBox-EndDate"
                    onChange={props.onChangeEndDate}
                    value={props.endDate}>
                  </DateTextBox>
                </div>
              </FormField>
              <FormField>
                <div>
                  {props.buttonSearchDepositHistory}
                </div>
              </FormField>
            </FormGroup>
          </Form>
        </div>
        <div
          style={{ margin: "0px 10px 30px 10px" }}>
          
          <Table
            data={props.depositHistoryData}
            getTrProps={props.selectDepositHistory}
            headers="ลำดับ, รหัสเอกสาร, สถานะ, วันที่,จำนวน, ยอดตัดใช้, คงเหลือ,เจ้าหน้าที่,"
            keys="no,code,status,created_datetime,initBalanceFormat,paymentFormat,balanceFormat,receipt_create_user,buttonDetail"
            minRows="5"
            showPagination={false}
            style={{ height: "300px" }}
            widths="auto,^220,^100,^100,auto,auto,auto,auto">
          </Table>
        </div>
      </div>
    )
}


export default CardBillingDepositUX

export const screenPropsDefault = {"by":"order","title":"MEDICAL FEE","titleDescription":"ข้อมูลรายการค่ารักษาพยาบาลรอรับชำระ"}

/* Date Time : Wed Aug 07 2024 13:01:39 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", flexDirection: \"column\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display:  \"flex\", justifyContent: \"space-between\",  padding: \"10px 10px 0px 10px\"  }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "รับชำระเงินมัดจำ"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"1.2rem\", fontWeight: \"bold\",display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขที่ใบเสร็จ : "
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"50px\", display: \"none\"}"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0px 10px 10px 10px\", display: props.viewMode ? \"none\" : \"\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "hr",
      "parent": 53,
      "props": {
      },
      "seq": 54,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "Label",
      "parent": 6,
      "props": {
        "children": {
          "type": "code",
          "value": "props.receiptCode"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"50px\"}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 10px\" }"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 80,
      "name": "Form",
      "parent": 79,
      "props": {
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 81,
      "name": "FormGroup",
      "parent": 80,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "FormField",
      "parent": 81,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 83,
      "name": "FormField",
      "parent": 81,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 84,
      "name": "div",
      "parent": 82,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  width: \"100%\", alignItems: \"center\"  }"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "div",
      "parent": 83,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  width: \"100%\", alignItems: \"center\"  }"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": null,
      "id": 86,
      "name": "div",
      "parent": 84,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการเงินมัดจำ"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"15px\", width: \"25%\" }"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 84,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"75%\" }"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "div",
      "parent": 85,
      "props": {
        "children": {
          "type": "value",
          "value": "ยอดเงินมัดจำ"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 20px\" }"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": null,
      "id": 90,
      "name": "div",
      "parent": 85,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\" }"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": null,
      "id": 91,
      "name": "div",
      "parent": 85,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ margin:   \"0px 15px\" }"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 92,
      "name": "Dropdown",
      "parent": 87,
      "props": {
        "clearable": {
          "type": "value",
          "value": ""
        },
        "disabled": {
          "type": "code",
          "value": "props.selectDeposit?.receipt_no !== null"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardBillingDeposit-Dropdown-Wallet"
        },
        "name": {
          "type": "value",
          "value": "selectDeposit.id"
        },
        "onChange": {
          "type": "code",
          "value": " props.onSelectWallet"
        },
        "options": {
          "type": "code",
          "value": "props.walletOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.selectDeposit?.id"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 94,
      "name": "Button",
      "parent": 91,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonAddDeposit"
        },
        "color": {
          "type": "code",
          "value": "props.selectDeposit?.mode_code !== \"\" && props.selectDeposit?.init_balance !== \"0.00\" ? \"yellow\" : \"green\""
        },
        "disabled": {
          "type": "code",
          "value": "props.selectDeposit?.receipt_no !== null"
        },
        "icon": {
          "type": "value",
          "value": ""
        },
        "onClick": {
          "type": "code",
          "value": "props.onAddDeposit"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", margin: \"20px 15px\" }"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": null,
      "id": 96,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 15px\" }"
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 98,
      "name": "Form",
      "parent": 96,
      "props": {
      },
      "seq": 98,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 99,
      "name": "FormGroup",
      "parent": 98,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"flex-end\", ...(!props.isEdit && {display: \"none\"} ) }"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 100,
      "name": "FormGroup",
      "parent": 98,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"flex-end\" }"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 105,
      "name": "FormField",
      "parent": 99,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"flex-end\" }"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 106,
      "name": "FormField",
      "parent": 100,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 108,
      "name": "FormField",
      "parent": 100,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 109,
      "name": "FormField",
      "parent": 100,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 110,
      "name": "FormField",
      "parent": 100,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": null,
      "id": 111,
      "name": "div",
      "parent": 95,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"0%\" }"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": null,
      "id": 112,
      "name": "div",
      "parent": 95,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 113,
      "name": "Table",
      "parent": 112,
      "props": {
        "data": {
          "type": "code",
          "value": "props.selectDepositData"
        },
        "headers": {
          "type": "value",
          "value": "รหัส,รายการเงินมัดจำ,ราคา,ผู้ป่วยชำระ,ยอดตัดใข้,คงเหลือ"
        },
        "keys": {
          "type": "value",
          "value": "code,mode_name,initBalanceFormat,patientPaidFormat,paymentFormat,balanceFormat"
        },
        "minRows": {
          "type": "value",
          "value": "1"
        },
        "pageSize": {
          "type": "value",
          "value": ""
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "widths": {
          "type": "value",
          "value": "auto,^500,auto,auto"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": null,
      "id": 118,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": null,
      "id": 119,
      "name": "div",
      "parent": 106,
      "props": {
        "children": {
          "type": "value",
          "value": "ราคารวม"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", fontWeight: \"700\" }"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": null,
      "id": 121,
      "name": "div",
      "parent": 108,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ป่วยชำระ"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", fontWeight: \"700\" }"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": null,
      "id": 122,
      "name": "div",
      "parent": 109,
      "props": {
        "children": {
          "type": "value",
          "value": "ยอดตัดใช้"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", fontWeight: \"700\" }"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": null,
      "id": 123,
      "name": "div",
      "parent": 110,
      "props": {
        "children": {
          "type": "value",
          "value": "คงเหลือ"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", fontWeight: \"700\" }"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 124,
      "name": "Input",
      "parent": 119,
      "props": {
        "id": {
          "type": "value",
          "value": "CardBillingDeposit-Input-SumTotal"
        },
        "value": {
          "type": "code",
          "value": "props.sumWallet?.sumTotal"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 126,
      "name": "Input",
      "parent": 121,
      "props": {
        "id": {
          "type": "value",
          "value": "CardBillingDeposit-Input-SumPayable"
        },
        "value": {
          "type": "code",
          "value": "props.sumWallet?.sumPayable"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 127,
      "name": "Input",
      "parent": 122,
      "props": {
        "id": {
          "type": "value",
          "value": "CardBillingDeposit-Input-SumAmount"
        },
        "value": {
          "type": "code",
          "value": "props.sumWallet?.sumAmount"
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 128,
      "name": "Input",
      "parent": 123,
      "props": {
        "id": {
          "type": "value",
          "value": "CardBillingDeposit-Input-SumRemain"
        },
        "value": {
          "type": "code",
          "value": "props.sumWallet?.sumRemian"
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 129,
      "name": "Button",
      "parent": 118,
      "props": {
        "children": {
          "type": "value",
          "value": "รับชำระเงิน"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.billPayment"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": null,
      "id": 130,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display:  \"flex\", justifyContent: \"space-between\",  padding: \"40px 10px 0px 10px\"  }"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": null,
      "id": 131,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0px 10px 10px 10px\", display: props.viewMode ? \"none\" : \"\" }"
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": null,
      "id": 132,
      "name": "div",
      "parent": 130,
      "props": {
        "children": {
          "type": "value",
          "value": "Deposit - History"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"1.2rem\", fontWeight: \"bold\",display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": null,
      "id": 133,
      "name": "div",
      "parent": 130,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติรับชำระเงินมัดจำ"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"1.2rem\", fontWeight: \"bold\",display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": null,
      "id": 134,
      "name": "hr",
      "parent": 131,
      "props": {
      },
      "seq": 134,
      "void": true
    },
    {
      "from": null,
      "id": 135,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": null,
      "id": 136,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 10px 30px 10px\" }"
        }
      },
      "seq": 181,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 137,
      "name": "Table",
      "parent": 136,
      "props": {
        "data": {
          "type": "code",
          "value": "props.depositHistoryData"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.selectDepositHistory"
        },
        "headers": {
          "type": "value",
          "value": "ลำดับ, รหัสเอกสาร, สถานะ, วันที่,จำนวน, ยอดตัดใช้, คงเหลือ,เจ้าหน้าที่,"
        },
        "keys": {
          "type": "value",
          "value": "no,code,status,created_datetime,initBalanceFormat,paymentFormat,balanceFormat,receipt_create_user,buttonDetail"
        },
        "minRows": {
          "type": "value",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"300px\" }"
        },
        "widths": {
          "type": "value",
          "value": "auto,^220,^100,^100,auto,auto,auto,auto"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 138,
      "name": "Form",
      "parent": 135,
      "props": {
      },
      "seq": 138,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 139,
      "name": "FormGroup",
      "parent": 138,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" , justifyContent: \"flex-end\" }"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 140,
      "name": "FormField",
      "parent": 139,
      "props": {
      },
      "seq": 143,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 141,
      "name": "FormField",
      "parent": 139,
      "props": {
      },
      "seq": 144,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 142,
      "name": "FormField",
      "parent": 139,
      "props": {
      },
      "seq": 176,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 143,
      "name": "FormField",
      "parent": 139,
      "props": {
      },
      "seq": 177,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 144,
      "name": "FormField",
      "parent": 139,
      "props": {
      },
      "seq": 183,
      "void": false
    },
    {
      "from": null,
      "id": 145,
      "name": "div",
      "parent": 140,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะ"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": null,
      "id": 147,
      "name": "div",
      "parent": 141,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": null,
      "id": 148,
      "name": "div",
      "parent": 142,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": null,
      "id": 149,
      "name": "div",
      "parent": 143,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": null,
      "id": 150,
      "name": "div",
      "parent": 144,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearchDepositHistory"
        },
        "className": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 152,
      "name": "DateTextBox",
      "parent": 149,
      "props": {
        "id": {
          "type": "value",
          "value": "CardBillingDeposit-DateTextBox-EndDate"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeEndDate"
        },
        "value": {
          "type": "code",
          "value": "props.endDate"
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 153,
      "name": "DateTextBox",
      "parent": 148,
      "props": {
        "id": {
          "type": "value",
          "value": "CardBillingDeposit-DateTextBox-StartDate"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeStartDate"
        },
        "value": {
          "type": "code",
          "value": "props.startDate"
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 154,
      "name": "Dropdown",
      "parent": 147,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardBillingDeposit-Dropdown-DepositStatus"
        },
        "name": {
          "type": "value",
          "value": "depositStatus"
        },
        "onChange": {
          "type": "code",
          "value": " props.onChangeHistoryData"
        },
        "options": {
          "type": "code",
          "value": "props.depositStatusOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.depositStatus"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 155,
      "name": "FormField",
      "parent": 139,
      "props": {
      },
      "seq": 155,
      "void": false
    },
    {
      "from": null,
      "id": 156,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่ออกเอกสาร"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 157,
      "name": "FormField",
      "parent": 99,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": null,
      "id": 158,
      "name": "div",
      "parent": 157,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  width: \"100%\", alignItems: \"center\"  }"
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": null,
      "id": 159,
      "name": "div",
      "parent": 158,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 160,
      "name": "Dropdown",
      "parent": 159,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangePatient"
        },
        "options": {
          "type": "code",
          "value": "props.patientOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.patientValue"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": null,
      "id": 161,
      "name": "div",
      "parent": 158,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อผู้ป่วยบนใบเสร็จ"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"700\", marginRight: \"15px\" }"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": null,
      "id": 162,
      "name": "div",
      "parent": 90,
      "props": {
        "children": {
          "type": "code",
          "value": "props.inputWalletValue",
          "valueEN": ""
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 163,
      "name": "FormGroup",
      "parent": 98,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ ...(props.isEdit && {display: \"none\"} ) }"
        }
      },
      "seq": 163,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 164,
      "name": "FormField",
      "parent": 163,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 165,
      "name": "FormField",
      "parent": 163,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 165,
      "void": false
    },
    {
      "from": null,
      "id": 166,
      "name": "div",
      "parent": 164,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": null,
      "id": 167,
      "name": "div",
      "parent": 165,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", display: \"flex\", justifyContent: \"space-between\", }"
        }
      },
      "seq": 167,
      "void": false
    },
    {
      "from": null,
      "id": 168,
      "name": "div",
      "parent": 167,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 168,
      "void": false
    },
    {
      "from": null,
      "id": 170,
      "name": "div",
      "parent": 167,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 170,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 171,
      "name": "Button",
      "parent": 166,
      "props": {
        "children": {
          "type": "value",
          "value": "รายละเอียดการใช้เงินมัดจำ"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 171,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 172,
      "name": "Button",
      "parent": 168,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์สำเนาใบเสร็จ"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "value",
          "value": ""
        },
        "onClick": {
          "type": "code",
          "value": "props.onPrintDeposit"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{ ...(props.selectDeposit?.status === \"CANCELED\" && { display: \"none\" }) }"
        }
      },
      "seq": 172,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 174,
      "name": "Button",
      "parent": 170,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิกและคืนเงินมัดจำ"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.openCancelDeposit"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{ ...(props.selectDeposit?.balance === \"0.00\" && { display: \"none\" }) }"
        }
      },
      "seq": 174,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 175,
      "name": "Button",
      "parent": 168,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์ใบแสดงรายละเอียด"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"none\" }"
        }
      },
      "seq": 175,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 176,
      "name": "FormField",
      "parent": 139,
      "props": {
      },
      "seq": 141,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 177,
      "name": "FormField",
      "parent": 139,
      "props": {
      },
      "seq": 142,
      "void": false
    },
    {
      "from": null,
      "id": 178,
      "name": "div",
      "parent": 177,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": null,
      "id": 179,
      "name": "div",
      "parent": 176,
      "props": {
        "children": {
          "type": "value",
          "value": "รหัสเอกสาร"
        }
      },
      "seq": 179,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 180,
      "name": "Input",
      "parent": 178,
      "props": {
        "id": {
          "type": "value",
          "value": "CardBillingDeposit-Input-DepositCode"
        },
        "name": {
          "type": "value",
          "value": "depositCode"
        },
        "onChange": {
          "type": "code",
          "value": " props.onChangeHistoryData"
        },
        "value": {
          "type": "code",
          "value": "props.depositCode"
        }
      },
      "seq": 180,
      "void": false
    },
    {
      "from": null,
      "id": 181,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.errorMessage"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 10px 15px\" }"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 182,
      "name": "Button",
      "parent": 170,
      "props": {
        "children": {
          "type": "value",
          "value": "ปิดหน้าต่าง"
        },
        "color": {
          "type": "value",
          "value": "black"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCloseData"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{ ...(props.selectDeposit?.receipt_no === \"0.00\" && { display: \"none\" }) }"
        }
      },
      "seq": 182,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 183,
      "name": "FormField",
      "parent": 139,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 140,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardBillingDepositUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "by": "order",
    "title": "MEDICAL FEE",
    "titleDescription": "ข้อมูลรายการค่ารักษาพยาบาลรอรับชำระ"
  },
  "width": 80
}

*********************************************************************************** */
