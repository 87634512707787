
/* Generated apis ---------------------- */
import { to } from 'react-lib/utils'
import BaseService from  'react-lib/apis/services/BaseService'
import config from '../../config/config'


const HOST = `${config.API_HOST}`
/* ['generics.ListAPIView'] */
/* params: 'pks', 'is_external' */
/* data:  */
const DrugOrderDetailList : 
{
  retrieve_drug_opd_home_by_emr: 
    ( {pk, params, apiToken, extra}:
      { pk: any, 
        params?: any,
        apiToken?: any,
        extra?: any,
      }) => any,
  retrieve_drug_opd_one_dose_by_progression_cycle: 
    ( {pk, params, apiToken, extra}:
      { pk: any, 
        params?: any,
        apiToken?: any,
        extra?: any,
      }) => any,
  retrieve_drug_opd_one_dose_by_emr: 
  ( {pk, params, apiToken, extra}:
    { pk: any, 
      params?: any,
      apiToken?: any,
      extra?: any,
    }) => any,
  retrieve_drug_opd_stat_by_progression_cycle: 
    ( {pk, params, apiToken, extra}:
      { pk: any, 
        params?: any,
        apiToken?: any,
        extra?: any,
      }) => any,
  // IPD
  retrieve_drug_ipd_home_by_emr: 
    ( {pk, params, apiToken, extra}:
      { pk: any, 
        params?: any,
        apiToken?: any,
        extra?: any,
      }) => any,
  retrieve_drug_ipd_one_dose_by_emr: 
    ( {pk, params, apiToken, extra}:
      { pk: any, 
        params?: any,
        apiToken?: any,
        extra?: any,
      }) => any,
  retrieve_drug_ipd_one_day_by_emr: 
    ( {pk, params, apiToken, extra}:
      { pk: any, 
        params?: any,
        apiToken?: any,
        extra?: any,
      }) => any,
  retrieve_drug_ipd_stat_by_emr: 
    ( {pk, params, apiToken, extra}:
      { pk: any, 
        params?: any,
        apiToken?: any,
        extra?: any,
      }) => any,
  retrieve_drug_operation_by_pks: 
    ( {pk, params, apiToken, extra}:
      { pk: any, 
        params?: any,
        apiToken?: any,
        extra?: any,
      }) => any,
  retrieve_drug_operation_by_emr: 
    ( {pk, params, apiToken, extra}:
      { pk: any, 
        params?: any,
        apiToken?: any,
        extra?: any,
      }) => any,
} = 
{
  retrieve_drug_opd_home_by_emr: async ({pk, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    if (extra?.division) {
      base.client.defaults.headers["division"] = extra.division;
    }
    const result = await to(base.client.get(`${HOST}/apis/TPD/drug-opd-home-order/emr/${pk}/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
  retrieve_drug_opd_one_dose_by_progression_cycle: async ({pk, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    if (extra?.division) {
      base.client.defaults.headers["division"] = extra.division;
    }
    const result = await to(base.client.get(`${HOST}/apis/TPD/drug-opd-one-dose-order/progression-cycle/${pk}/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
  retrieve_drug_opd_one_dose_by_emr: async ({pk, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    if (extra?.division) {
      base.client.defaults.headers["division"] = extra.division;
    }
    const result = await to(base.client.get(`${HOST}/apis/TPD/drug-opd-one-dose-order/emr/${pk}/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
  retrieve_drug_opd_stat_by_progression_cycle: async ({pk, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    if (extra?.division) {
      base.client.defaults.headers["division"] = extra.division;
    }
    const result = await to(base.client.get(`${HOST}/apis/TPD/drug-opd-stat-order/progression-cycle/${pk}/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
  // IPD
  retrieve_drug_ipd_home_by_emr: async ({pk, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    if (extra?.division) {
      base.client.defaults.headers["division"] = extra.division;
    }
    const result = await to(base.client.get(`${HOST}/apis/TPD/drug-ipd-home-order/emr/${pk}/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
  retrieve_drug_ipd_one_dose_by_emr: async ({pk, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    if (extra?.division) {
      base.client.defaults.headers["division"] = extra.division;
    }
    const result = await to(base.client.get(`${HOST}/apis/TPD/drug-ipd-one-dose-order/emr/${pk}/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
  retrieve_drug_ipd_one_day_by_emr: async ({pk, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    if (extra?.division) {
      base.client.defaults.headers["division"] = extra.division;
    }
    const result = await to(base.client.get(`${HOST}/apis/TPD/drug-one-day-order/emr/${pk}/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
  retrieve_drug_ipd_stat_by_emr: async ({pk, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    if (extra?.division) {
      base.client.defaults.headers["division"] = extra.division;
    }
    const result = await to(base.client.get(`${HOST}/apis/TPD/drug-ipd-stat-order/emr/${pk}/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
  retrieve_drug_operation_by_pks: async ({pk, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    if (extra?.division) {
      base.client.defaults.headers["division"] = extra.division;
    }
    const result = await to(base.client.get(`${HOST}/apis/TPD/drug-operating-order/pks/`,
      { params: params ? { ...params, pks: pk } : {pks: pk},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
  retrieve_drug_operation_by_emr: async ({pk, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    if (extra?.division) {
      base.client.defaults.headers["division"] = extra.division;
    }
    const result = await to(base.client.get(`${HOST}/apis/TPD/drug-operating-order/emr/${pk}/`,
      { params: params ? { ...params} : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

export default DrugOrderDetailList

