import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'

const ModEventDetailUX = (props: any) => {
    return(
      <div
        style={{padding: "20px 20px 40px 20px"}}>

        <div
          style={{margin: "10px 0px 15px 0px", fontWeight: "bold"}}>
          {props?.title}
        </div>
        <div>

          <Table
            data={props.providers}
            getTrProps={props.rowProps}
            headers={`แพทย์,ประเภทออกตรวจ,วันที่,เริ่ม,ถึง,${props.isShowChair ? "เก้าอี้,ZONE,จำนวนผู้ป่วยที่นัดหมาย,จำนวน slot ที่ถูกใช้ (Slot ทั้งหมด)" : "จำนวนนัดหมาย"}`}
            keys={`doctorName,examinationTypeLabel,date,doctor_start_time,doctor_end_time,${props.isShowChair ? "chair,zone,numAppointedPatients,number_appointments" : "number_appointments"}`}
            minRows="5"
            showPagination={false}
            style={{height: "200px"}}
            widths={`^100,^100,^120,100,100,${props.isShowChair ? ",^100,^100,^140" : "^100"}`}>
          </Table>
        </div>
      </div>
    )
}


export default ModEventDetailUX

export const screenPropsDefault = {"title":"9:00-12:00"}

/* Date Time : Thu Nov 14 2024 14:01:25 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"20px 20px 40px 20px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.title"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px 0px 15px 0px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 2,
      "name": "Table",
      "parent": 3,
      "props": {
        "data": {
          "type": "code",
          "value": "props.providers"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.rowProps"
        },
        "headers": {
          "type": "code",
          "value": "`แพทย์,ประเภทออกตรวจ,วันที่,เริ่ม,ถึง,${props.isShowChair ? \"เก้าอี้,ZONE,จำนวนผู้ป่วยที่นัดหมาย,จำนวน slot ที่ถูกใช้ (Slot ทั้งหมด)\" : \"จำนวนนัดหมาย\"}`"
        },
        "keys": {
          "type": "code",
          "value": "`doctorName,examinationTypeLabel,date,doctor_start_time,doctor_end_time,${props.isShowChair ? \"chair,zone,numAppointedPatients,number_appointments\" : \"number_appointments\"}`"
        },
        "minRows": {
          "type": "value",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"200px\"}"
        },
        "widths": {
          "type": "code",
          "value": "`^100,^100,^120,100,100,${props.isShowChair ? \",^100,^100,^140\" : \"^100\"}`"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 3,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "ModEventDetailUX",
  "project": "cudent2",
  "screenPropsDefault": {
    "title": "9:00-12:00"
  },
  "width": 80
}

*********************************************************************************** */
