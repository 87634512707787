import React, {
  FC,
  forwardRef,
  useState,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { Grid, Form, Button, Checkbox, Dropdown } from "semantic-ui-react";


// import styles from './CardOTPhysicalExaminationPhysicalDysfunctionSensorimotorFunction.css'

import TableRangeOfMotion, {
  TableRangeOfMotionViewDataType,
  TableRangeOfMotionInitialViewData,
} from "./TableRangeOfMotion";
import TableFunctionalMotion, {
  TableFunctionalMotionViewDataType,
  TableFunctionalMotionInitialViewData,
} from "./TableFunctionalMotion";

import _ from "../../compat/lodashplus";
import { useIntl } from "react-intl";

const styles = {
  gridBG: {
    background: "#F3F3F3",
    marginLeft: "1px",
    marginRight: "1px",
    paddingTop: "15px",
    paddingLeft: "0px",
  },
  noPadding: {
    padding: "0px",
  },
  leftPadding: {
    paddingLeft: "5px",
  },
};

export interface CardOTPhysicalExaminationPhysicalDysfunctionSensorimotorFunctionViewDataType {
  onEvent: (any) => any;
  lightTouchRightOptions: any[];
  lightTouchLeftOptions: any[];
  proprioceptiveRightOptions: any[];
  proprioceptiveLeftOptions: any[];
  readonly: boolean;

  viewData: {
    buttonRightDisabled: boolean;
    buttonLeftDisabled: boolean;
    checkBoxUnSelected: boolean;

    lightTouchRight: string;
    lightTouchRightDisabled: boolean;

    lightTouchLeft: string;
    lightTouchLeftDisabled: boolean;

    proprioceptiveRight: string;
    proprioceptiveRightDisabled: boolean;

    proprioceptiveLeft: string;
    proprioceptiveLeftDisabled: boolean;

    checkBoxRangeOfMotionRight: boolean;
    checkBoxRangeOfMotionRightDisabled: boolean;
    tableRangeRight: TableRangeOfMotionViewDataType;

    checkBoxRangeOfMotionLeft: boolean;
    checkBoxRangeOfMotionLeftDisabled: boolean;
    tableRangeLeft: TableRangeOfMotionViewDataType;

    checkBoxFuncMotionRight: boolean;
    checkBoxFuncMotionRightDisabled: boolean;
    tableFunctionRight: TableFunctionalMotionViewDataType;

    checkBoxFuncMotionLeft: boolean;
    checkBoxFuncMotionLeftDisabled: boolean;
    tableFunctionLeft: TableFunctionalMotionViewDataType;
  };
}

const CardOTPhysicalExaminationPhysicalDysfunctionSensorimotorFunction: FC<CardOTPhysicalExaminationPhysicalDysfunctionSensorimotorFunctionViewDataType> = forwardRef(
  (props, ref) => {
    const [rightActive, setRightActive] = useState(false);
    const [leftActive, setLeftActive] = useState(false);
    const [physicalExaminationData, setPhysicalExaminationData] = useState<any>(
      {}
    );
    const [lightTouchRightOptions, setLightTouchRightOptions] = useState<any>(
      CardOTPhysicalExaminationPhysicalDysfunctionSensorimotorFunctionInitialViewData.lightTouchRightOptions
    );
    const [lightTouchLeftOptions, setLightTouchLeftOptions] = useState<any>(
      CardOTPhysicalExaminationPhysicalDysfunctionSensorimotorFunctionInitialViewData.lightTouchLeftOptions
    );
    const [
      proprioceptiveRightOptions,
      setProprioceptiveRightOptions,
    ] = useState<any>(
      CardOTPhysicalExaminationPhysicalDysfunctionSensorimotorFunctionInitialViewData.proprioceptiveRightOptions
    );
    const [proprioceptiveLeftOptions, setProprioceptiveLeftOptions] = useState<
      any
    >(
      CardOTPhysicalExaminationPhysicalDysfunctionSensorimotorFunctionInitialViewData.proprioceptiveLeftOptions
    );

    const tableFuncRightRef = useRef();
    const tableFuncLeftRef = useRef();
    const tableRangeRightRef = useRef();
    const tableRangeLeftRef = useRef();

    useEffect(() => {
      if (props.viewData) {
        setPhysicalExaminationData(props.viewData);
      }
    }, [props.viewData]);

    useEffect(() => {
      if (props) {
        setLightTouchRightOptions(props.lightTouchRightOptions);
        setLightTouchLeftOptions(props.lightTouchLeftOptions);
        setProprioceptiveRightOptions(props.proprioceptiveRightOptions);
        setProprioceptiveLeftOptions(props.proprioceptiveLeftOptions);
      }
    }, [props]);

    useEffect(() => {
      if (physicalExaminationData.checkBoxUnSelected) {
        setLeftActive(false);
        setRightActive(false);
        props.onEvent("unSelectCheck");
      }
    }, [physicalExaminationData.checkBoxUnSelected]);

    useImperativeHandle(ref, () => ({
      getData: () => {
        let refData = {};
        refData = { ...physicalExaminationData };
        refData["tableRangeRight"] = tableRangeRightRef.current.getData();
        refData["tableFunctionRight"] = tableFuncRightRef.current.getData();
        refData["tableRangeLeft"] = tableRangeLeftRef.current.getData();
        refData["tableFunctionLeft"] = tableFuncLeftRef.current.getData();
        return refData;
      },
    }));

    const handleOnCheckChange = (event, data) => {
      if(!props.readonly){
        let dictA = { ...physicalExaminationData }; //Object.assign({}, data)
        dictA[data.name] = data.checked;
        setPhysicalExaminationData(dictA);
      }
    };

    const handleOnTextChange = (event, data) => {
      if (!props.readonly) {
        let dictA = { ...physicalExaminationData };
        dictA[data.name] = data.value;
        setPhysicalExaminationData(dictA);
      }
    };

    const handleButtonLeftRightClick = (leftOrRight) => {
      if (leftOrRight === "left") {
        setLeftActive(true);
        setRightActive(false);
      } else if (leftOrRight === "right") {
        setRightActive(true);
        setLeftActive(false);
      } else {
        setLeftActive(false);
        setRightActive(false);
      }
      props.onEvent(leftOrRight);
    };

    // console.log('CardOTPhysicalExamination Render data', physicalExaminationData)
    return (
      <Grid style={styles.gridBG}>
        <Grid.Row columns={2} style={styles.noPadding}>
          <Grid.Column width={2}>
            <Form>
              <Form.Group inline>
                <Form.Field width={1}></Form.Field>
                <Form.Field width={15}>
                  <strong>Sensorimotor function</strong>
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
          <Grid.Column width={14}>
            <Form>
              <Form.Group inline>
                <Form.Field width={3}>
                  <Button
                    toggle
                    disabled={
                      physicalExaminationData.buttonLeftDisabled ||
                      physicalExaminationData.checkBoxUnSelected
                    }
                    onClick={() => {
                      handleButtonLeftRightClick("left");
                    }}
                    style={{
                      backgroundColor: leftActive ? "#21BA45" : "#FFFFFF",
                      color: leftActive ? "white" : "",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    Left
                  </Button>
                </Form.Field>
                <Form.Field width={3}>
                  <Button
                    toggle
                    disabled={
                      physicalExaminationData.buttonRightDisabled ||
                      physicalExaminationData.checkBoxUnSelected
                    }
                    onClick={() => {
                      handleButtonLeftRightClick("right");
                    }}
                    style={{
                      backgroundColor: rightActive ? "#21BA45" : "#FFFFFF",
                      color: rightActive ? "white" : "",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    Right
                  </Button>
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    style={{ height: "100%", width: "100%" }}
                    checked={physicalExaminationData.checkBoxUnSelected}
                    readOnly={props.readonly}
                    name="checkBoxUnSelected"
                    onChange={handleOnCheckChange}
                  />
                </Form.Field>
                <Form.Field>N/A</Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
        {/* SENSORIMOTOR RIGHT */}
        <Grid.Row style={{ display: rightActive ? "" : "none" }} columns={2}>
          <Grid.Column width={2}>
            <Form>
              <Form.Group inline>
                <Form.Field width={2} />
                <Form.Field width={14}>
                  <strong>Sensation</strong>
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
          <Grid.Column width={14}>
            <Form>
              <Form.Group inline>
                <Form.Field width={2}>Light touch</Form.Field>
                <Form.Field width={3}>
                  <Dropdown
                    selection
                    style={{ height: "100%", width: "100%" }}
                    name="lightTouchRight"
                    value={physicalExaminationData.lightTouchRight}
                    disabled={
                      physicalExaminationData.lightTouchRightDisabled ||
                      physicalExaminationData.checkBoxUnSelected
                    }
                    onChange={handleOnTextChange}
                    options={lightTouchRightOptions}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
            <Form>
              <Form.Group inline>
                <Form.Field width={2}>proprioceptive</Form.Field>
                <Form.Field width={3}>
                  <Dropdown
                    selection
                    style={{ height: "100%", width: "100%" }}
                    name="proprioceptiveRight"
                    value={physicalExaminationData.proprioceptiveRight}
                    disabled={
                      physicalExaminationData.proprioceptiveRightDisabled ||
                      physicalExaminationData.checkBoxUnSelected
                    }
                    onChange={handleOnTextChange}
                    options={proprioceptiveRightOptions}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ display: rightActive ? "" : "none" }} columns={2}>
          <Grid.Column width={2}>
            <Form>
              <Form.Group inline>
                <Form.Field width={2} />
                <Form.Field width={14}>
                  <strong>Rang of motion</strong>
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
          <Grid.Column width={14}>
            <Form>
              <Form.Group inline>
                <Form.Field>
                  <Checkbox
                    style={{ height: "100%", width: "100%" }}
                    checked={physicalExaminationData.checkBoxRangeOfMotionRight}
                    name="checkBoxRangeOfMotionRight"
                    disabled={
                      physicalExaminationData.checkBoxRangeOfMotionRightDisabled ||
                      physicalExaminationData.checkBoxUnSelected }
                    onChange={handleOnCheckChange}
                  />
                </Form.Field>
                <Form.Field>N/A</Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
          <Grid.Column width={16}>
            <TableRangeOfMotion
              key={"tableRangeRightRef"}
              ref={tableRangeRightRef}
              tableDisable={
                physicalExaminationData.checkBoxUnSelected ||
                physicalExaminationData.checkBoxRangeOfMotionRight ||
                props.readonly
              }
              viewData={physicalExaminationData.tableRangeRight}
              // viewData={physicalExaminationData.tableRangeRight.viewData}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ display: rightActive ? "" : "none" }} columns={2}>
          <Grid.Column width={2}>
            <Form>
              <Form.Group inline>
                <Form.Field width={2} />
                <Form.Field width={14}>
                  <strong>Functional motion</strong>
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
          <Grid.Column width={14}>
            <Form>
              <Form.Group inline>
                <Form.Field>
                  <Checkbox
                    style={{ height: "100%", width: "100%" }}
                    checked={physicalExaminationData.checkBoxFuncMotionRight}
                    name="checkBoxFuncMotionRight"
                    disabled={
                      physicalExaminationData.checkBoxFuncMotionRightDisabled ||
                      physicalExaminationData.checkBoxUnSelected ||
                      props.readonly
                    }
                    onChange={handleOnCheckChange}
                  />
                </Form.Field>
                <Form.Field>N/A</Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
          <Grid.Column width={16}>
            <TableFunctionalMotion
              key={"tableFuncRightRef"}
              ref={tableFuncRightRef}
              tableDisable={
                physicalExaminationData.checkBoxUnSelected ||
                physicalExaminationData.checkBoxFuncMotionRight ||
                props.readonly
              }
              viewData={physicalExaminationData.tableFunctionRight}
            />
          </Grid.Column>
        </Grid.Row>
        {/* SENSORIMOTOR LEFT */}
        <Grid.Row style={{ display: leftActive ? "" : "none" }} columns={2}>
          <Grid.Column width={2}>
            <Form>
              <Form.Group inline>
                <Form.Field width={2} />
                <Form.Field width={14}>
                  <strong>Sensation</strong>
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
          <Grid.Column width={14}>
            <Form>
              <Form.Group inline>
                <Form.Field width={2}>Light touch</Form.Field>
                <Form.Field width={3}>
                  <Dropdown
                    selection
                    style={{ height: "100%", width: "100%" }}
                    value={physicalExaminationData.lightTouchLeft}
                    name="lightTouchLeft"
                    disabled={
                      physicalExaminationData.lightTouchLeftDisabled ||
                      physicalExaminationData.checkBoxUnSelected
                    }
                    onChange={handleOnTextChange}
                    options={lightTouchLeftOptions}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
            <Form>
              <Form.Group inline>
                <Form.Field width={2}>proprioceptive</Form.Field>
                <Form.Field width={3}>
                  <Dropdown
                    selection
                    style={{ height: "100%", width: "100%" }}
                    value={physicalExaminationData.proprioceptiveLeft}
                    name="proprioceptiveLeft"
                    disabled={
                      physicalExaminationData.proprioceptiveLeftDisabled ||
                      physicalExaminationData.checkBoxUnSelected
                    }
                    onChange={handleOnTextChange}
                    options={proprioceptiveLeftOptions}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ display: leftActive ? "" : "none" }} columns={2}>
          <Grid.Column width={2}>
            <Form>
              <Form.Group inline>
                <Form.Field width={2} />
                <Form.Field width={14}>
                  <strong>Rang of motion</strong>
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
          <Grid.Column width={14}>
            <Form>
              <Form.Group inline>
                <Form.Field>
                  <Checkbox
                    style={{ height: "100%", width: "100%" }}
                    checked={physicalExaminationData.checkBoxRangeOfMotionLeft}
                    name="checkBoxRangeOfMotionLeft"
                    disabled={
                      physicalExaminationData.checkBoxRangeOfMotionLeftDisabled ||
                      physicalExaminationData.checkBoxUnSelected 
                    }
                    onChange={handleOnCheckChange}
                  />
                </Form.Field>
                <Form.Field>N/A</Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
          <Grid.Column width={16}>
            <TableRangeOfMotion
              key={"tableRangeLeftRef"}
              ref={tableRangeLeftRef}
              tableDisable={
                physicalExaminationData.checkBoxUnSelected ||
                physicalExaminationData.checkBoxRangeOfMotionLeft ||
                props.readonly
              }
              viewData={physicalExaminationData.tableRangeLeft}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ display: leftActive ? "" : "none" }} columns={2}>
          <Grid.Column width={2}>
            <Form>
              <Form.Group inline>
                <Form.Field width={2} />
                <Form.Field width={14}>
                  <strong>Functional motion</strong>
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
          <Grid.Column width={14}>
            <Form>
              <Form.Group inline>
                <Form.Field>
                  <Checkbox
                    style={{ height: "100%", width: "100%" }}
                    checked={physicalExaminationData.checkBoxFuncMotionLeft}
                    name="checkBoxFuncMotionLeft"
                    disabled={
                      physicalExaminationData.checkBoxFuncMotionLeftDisabled ||
                      physicalExaminationData.checkBoxUnSelected }
                    onChange={handleOnCheckChange}
                  />
                </Form.Field>
                <Form.Field>N/A</Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
          <Grid.Column width={16}>
            <TableFunctionalMotion
              key={"tableFuncLeftRef"}
              ref={tableFuncLeftRef}
              tableDisable={
                physicalExaminationData.checkBoxUnSelected ||
                physicalExaminationData.checkBoxFuncMotionLeft ||
                props.readonly
              }
              viewData={physicalExaminationData.tableFunctionLeft}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
);

export const CardOTPhysicalExaminationPhysicalDysfunctionSensorimotorFunctionInitialViewData: CardOTPhysicalExaminationPhysicalDysfunctionSensorimotorFunctionViewDataType = {
  onEvent: ({}) => {},
  lightTouchRightOptions: [{ key: 0, value: "A", text: "A" }],
  lightTouchLeftOptions: [{ key: 0, value: "A", text: "A" }],
  proprioceptiveRightOptions: [{ key: 0, value: "A", text: "A" }],
  proprioceptiveLeftOptions: [{ key: 0, value: "A", text: "A" }],
  readonly: false,
  viewData: {
    buttonRightDisabled: false,
    buttonLeftDisabled: false,
    checkBoxUnSelected: false,

    lightTouchRight: "",
    lightTouchRightDisabled: false,

    lightTouchLeft: "",
    lightTouchLeftDisabled: false,

    proprioceptiveRight: "",
    proprioceptiveRightDisabled: false,

    proprioceptiveLeft: "",
    proprioceptiveLeftDisabled: false,

    checkBoxRangeOfMotionRight: false,
    checkBoxRangeOfMotionRightDisabled: false,
    tableRangeRight: _.cloneDeep(TableRangeOfMotionInitialViewData.viewData),

    checkBoxRangeOfMotionLeft: false,
    checkBoxRangeOfMotionLeftDisabled: false,
    tableRangeLeft: _.cloneDeep(TableRangeOfMotionInitialViewData.viewData),

    checkBoxFuncMotionRight: false,
    checkBoxFuncMotionRightDisabled: false,
    tableFunctionRight: _.cloneDeep(
      TableFunctionalMotionInitialViewData.viewData
    ),

    checkBoxFuncMotionLeft: false,
    checkBoxFuncMotionLeftDisabled: false,
    tableFunctionLeft: _.cloneDeep(
      TableFunctionalMotionInitialViewData.viewData
    ),
  },
};

CardOTPhysicalExaminationPhysicalDysfunctionSensorimotorFunction.defaultProps = CardOTPhysicalExaminationPhysicalDysfunctionSensorimotorFunctionInitialViewData;

export default CardOTPhysicalExaminationPhysicalDysfunctionSensorimotorFunction;
