import React from 'react'
import {
   Checkbox,
   Dropdown,
   Input,
} from 'semantic-ui-react'



import styles from './CardOTPhysicalExaminationPediatrics1.module.css'

export interface CardOTPhysicalExaminationPediatrics1ViewDataType {
    CardOtPhysicalPediatric14: boolean,
    CardOtPhysicalPediatric14Disabled: boolean,
    CardOtPhysicalPediatric15: boolean,
    CardOtPhysicalPediatric15Disabled: boolean,
    CardOtPhysicalPediatric17: boolean,
    CardOtPhysicalPediatric17Disabled: boolean,
    CardOtPhysicalPediatric19: boolean,
    CardOtPhysicalPediatric19Disabled: boolean,
    CardOtPhysicalPediatric111: boolean,
    CardOtPhysicalPediatric111Disabled: boolean,
    CardOtPhysicalPediatric113: boolean,
    CardOtPhysicalPediatric113Disabled: boolean,
    CardOtPhysicalPediatric115: boolean,
    CardOtPhysicalPediatric115Disabled: boolean,
    CardOtPhysicalPediatric118: boolean,
    CardOtPhysicalPediatric118Disabled: boolean,
    CardOtPhysicalPediatric124: boolean,
    CardOtPhysicalPediatric124Disabled: boolean,
    CardOtPhysicalPediatric125: boolean,
    CardOtPhysicalPediatric125Disabled: boolean,
    CardOtPhysicalPediatric126: boolean,
    CardOtPhysicalPediatric126Disabled: boolean,
    CardOtPhysicalPediatric128: boolean,
    CardOtPhysicalPediatric128Disabled: boolean,
    CardOtPhysicalPediatric134: boolean,
    CardOtPhysicalPediatric134Disabled: boolean,
    CardOtPhysicalPediatric135: string,
    CardOtPhysicalPediatric135Disabled: boolean,
    CardOtPhysicalPediatric139: boolean,
    CardOtPhysicalPediatric139Disabled: boolean,
    CardOtPhysicalPediatric141: boolean,
    CardOtPhysicalPediatric141Disabled: boolean,
    CardOtPhysicalPediatric145: string,
    CardOtPhysicalPediatric145Disabled: boolean,
    CardOtPhysicalPediatric149: string,
    CardOtPhysicalPediatric149Disabled: boolean,
    CtGeneralAppearance: string,
    CtGeneralAppearanceDisabled: boolean,
    CtGeneralAppearanceOptions: any,
    Ct4Communication: string,
    Ct4CommunicationDisabled: boolean,
    Ct4CommunicationOptions: any,
    Ct4CoOperation: string,
    Ct4CoOperationDisabled: boolean,
    Ct4CoOperationOptions: any,
    Ct3LevelOfArousal: string,
    Ct3LevelOfArousalDisabled: boolean,
    Ct3LevelOfArousalOptions: any,
    Ct2Attention: string,
    Ct2AttentionDisabled: boolean,
    Ct2AttentionOptions: any,
}

export const CardOTPhysicalExaminationPediatrics1InitialViewData: CardOTPhysicalExaminationPediatrics1ViewDataType = {
    CardOtPhysicalPediatric14: false,
    CardOtPhysicalPediatric14Disabled: false,
    CardOtPhysicalPediatric15: false,
    CardOtPhysicalPediatric15Disabled: false,
    CardOtPhysicalPediatric17: false,
    CardOtPhysicalPediatric17Disabled: false,
    CardOtPhysicalPediatric19: false,
    CardOtPhysicalPediatric19Disabled: false,
    CardOtPhysicalPediatric111: false,
    CardOtPhysicalPediatric111Disabled: false,
    CardOtPhysicalPediatric113: false,
    CardOtPhysicalPediatric113Disabled: false,
    CardOtPhysicalPediatric115: false,
    CardOtPhysicalPediatric115Disabled: false,
    CardOtPhysicalPediatric118: false,
    CardOtPhysicalPediatric118Disabled: false,
    CardOtPhysicalPediatric124: false,
    CardOtPhysicalPediatric124Disabled: false,
    CardOtPhysicalPediatric125: false,
    CardOtPhysicalPediatric125Disabled: false,
    CardOtPhysicalPediatric126: false,
    CardOtPhysicalPediatric126Disabled: false,
    CardOtPhysicalPediatric128: false,
    CardOtPhysicalPediatric128Disabled: false,
    CardOtPhysicalPediatric134: false,
    CardOtPhysicalPediatric134Disabled: false,
    CardOtPhysicalPediatric135: "",
    CardOtPhysicalPediatric135Disabled: false,
    CardOtPhysicalPediatric139: false,
    CardOtPhysicalPediatric139Disabled: false,
    CardOtPhysicalPediatric141: false,
    CardOtPhysicalPediatric141Disabled: false,
    CardOtPhysicalPediatric145: "",
    CardOtPhysicalPediatric145Disabled: false,
    CardOtPhysicalPediatric149: "",
    CardOtPhysicalPediatric149Disabled: false,
    CtGeneralAppearance: "",
    CtGeneralAppearanceDisabled: false,
    CtGeneralAppearanceOptions: [{key: 0, value: "A", text: "A"}],
    Ct4Communication: "",
    Ct4CommunicationDisabled: false,
    Ct4CommunicationOptions: [{key: 0, value: "A", text: "A"}],
    Ct4CoOperation: "",
    Ct4CoOperationDisabled: false,
    Ct4CoOperationOptions: [{key: 0, value: "A", text: "A"}],
    Ct3LevelOfArousal: "",
    Ct3LevelOfArousalDisabled: false,
    Ct3LevelOfArousalOptions: [{key: 0, value: "A", text: "A"}],
    Ct2Attention: "",
    Ct2AttentionDisabled: false,
    Ct2AttentionOptions: [{key: 0, value: "A", text: "A"}],
}

const Sub = (props: any) => {
    const children = React.Children.toArray(props.children)
    return (
        <div className={styles.container}>
            <div className={styles.CardOtPhysicalPediatric11}>
                <div
                    style={{backgroundColor: "#F3F3F3", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalPediatric14}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalPediatric14}
                    disabled={ props.viewData.CardOtPhysicalPediatric14Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationPediatrics1",
                                name: "CardOtPhysicalPediatric14"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalPediatric15}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalPediatric15}
                    disabled={ props.viewData.CardOtPhysicalPediatric15Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationPediatrics1",
                                name: "CardOtPhysicalPediatric15"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalPediatric16}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Observation</strong></p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalPediatric17}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalPediatric17}
                    disabled={ props.viewData.CardOtPhysicalPediatric17Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationPediatrics1",
                                name: "CardOtPhysicalPediatric17"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalPediatric18}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Normal</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalPediatric19}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalPediatric19}
                    disabled={ props.viewData.CardOtPhysicalPediatric19Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationPediatrics1",
                                name: "CardOtPhysicalPediatric19"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalPediatric110}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p>Wheelchair</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalPediatric111}>
                <Checkbox 
                    style={{ height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalPediatric111}
                    disabled={ props.viewData.CardOtPhysicalPediatric111Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationPediatrics1",
                                name: "CardOtPhysicalPediatric111"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalPediatric112}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p>Stroller</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalPediatric113}>
                <Checkbox 
                    style={{ height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalPediatric113}
                    disabled={ props.viewData.CardOtPhysicalPediatric113Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationPediatrics1",
                                name: "CardOtPhysicalPediatric113"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalPediatric114}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Cane/walker</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalPediatric115}>
                <Checkbox 
                    style={{ height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalPediatric115}
                    disabled={ props.viewData.CardOtPhysicalPediatric115Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationPediatrics1",
                                name: "CardOtPhysicalPediatric115"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalPediatric116}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p>Arm sling</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalPediatric118}>
                <Checkbox 
                    style={{ height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalPediatric118}
                    disabled={ props.viewData.CardOtPhysicalPediatric118Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationPediatrics1",
                                name: "CardOtPhysicalPediatric118"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalPediatric119}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>On Ventilator</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalPediatric121}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>On ET</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalPediatric123}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>TT tube</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalPediatric124}>
                <Checkbox 
                    style={{ height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalPediatric124}
                    disabled={ props.viewData.CardOtPhysicalPediatric124Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationPediatrics1",
                                name: "CardOtPhysicalPediatric124"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalPediatric125}>
                <Checkbox 
                    style={{ height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalPediatric125}
                    disabled={ props.viewData.CardOtPhysicalPediatric125Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationPediatrics1",
                                name: "CardOtPhysicalPediatric125"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalPediatric126}>
                <Checkbox 
                    style={{ height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalPediatric126}
                    disabled={ props.viewData.CardOtPhysicalPediatric126Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationPediatrics1",
                                name: "CardOtPhysicalPediatric126"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalPediatric127}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p>On Foley's catheter</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalPediatric128}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalPediatric128}
                    disabled={ props.viewData.CardOtPhysicalPediatric128Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationPediatrics1",
                                name: "CardOtPhysicalPediatric128"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalPediatric130}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p>On IV fluid</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalPediatric134}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalPediatric134}
                    disabled={ props.viewData.CardOtPhysicalPediatric134Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationPediatrics1",
                                name: "CardOtPhysicalPediatric134"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalPediatric135}>
                <Input
                    value={props.viewData.CardOtPhysicalPediatric135}
                    disabled={ props.viewData.CardOtPhysicalPediatric135Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTPhysicalExaminationPediatrics1',
                            name: 'CardOtPhysicalPediatric135',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CardOtPhysicalPediatric136}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>On ICD drainage</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalPediatric139}>
                <Checkbox 
                    style={{ height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalPediatric139}
                    disabled={ props.viewData.CardOtPhysicalPediatric139Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationPediatrics1",
                                name: "CardOtPhysicalPediatric139"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalPediatric140}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p>NG Tube</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalPediatric141}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalPediatric141}
                    disabled={ props.viewData.CardOtPhysicalPediatric141Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationPediatrics1",
                                name: "CardOtPhysicalPediatric141"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalPediatric142}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p>OG Tube</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalPediatric143}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>PEG</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalPediatric144}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p>Other</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalPediatric145}>
                <Input
                    value={props.viewData.CardOtPhysicalPediatric145}
                    disabled={ props.viewData.CardOtPhysicalPediatric145Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTPhysicalExaminationPediatrics1',
                            name: 'CardOtPhysicalPediatric145',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CardOtPhysicalPediatric146}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Behavior</strong></p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalPediatric149}>
                <Input
                    value={props.viewData.CardOtPhysicalPediatric149}
                    disabled={ props.viewData.CardOtPhysicalPediatric149Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTPhysicalExaminationPediatrics1',
                            name: 'CardOtPhysicalPediatric149',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CardOtPhysicalPediatric150}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p><strong>General appearance</strong></p>
                </div>
            </div>
            <div className={styles.CtGeneralAppearance}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtGeneralAppearance}
                    disabled={ props.viewData.CtGeneralAppearanceDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationPediatrics1",
                                name: "CtGeneralAppearance",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtGeneralAppearanceOptions}
                />
            </div>
            <div className={styles.Ct4Communication}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct4Communication}
                    disabled={ props.viewData.Ct4CommunicationDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationPediatrics1",
                                name: "Ct4Communication",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct4CommunicationOptions}
                />
            </div>
            <div className={styles.Ct4CoOperation}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct4CoOperation}
                    disabled={ props.viewData.Ct4CoOperationDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationPediatrics1",
                                name: "Ct4CoOperation",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct4CoOperationOptions}
                />
            </div>
            <div className={styles.CardOtPhysicalPediatric161}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p><strong>Communication</strong></p>
                </div>
            </div>
            <div className={styles.Ct3LevelOfArousal}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct3LevelOfArousal}
                    disabled={ props.viewData.Ct3LevelOfArousalDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationPediatrics1",
                                name: "Ct3LevelOfArousal",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct3LevelOfArousalOptions}
                />
            </div>
            <div className={styles.Ct2Attention}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct2Attention}
                    disabled={ props.viewData.Ct2AttentionDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationPediatrics1",
                                name: "Ct2Attention",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct2AttentionOptions}
                />
            </div>
            <div className={styles.CardOtPhysicalPediatric168}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p><strong>Co-operation</strong></p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalPediatric169}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Level of arousal</strong></p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalPediatric171}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Attention</strong></p>
                </div>
            </div>
        </div>
    )
}

export default Sub

const getTsName = (cssClass: string) => {
    return cssClass.split('-').map((s) => (
        s.charAt(0).toUpperCase() + s.slice(1)
    )).join('')
}
