import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Button
} from 'semantic-ui-react'

const ModConsultDateTimeUX = (props: any) => {
    return(
      <div
        style={{padding: "20px"}}>
        <div
          style={{color: "#0D71BB",  fontSize: "18px", fontWeight: "bold"}}>
          รายละเอียดการนัดหมาย
        </div>
        <hr>
        </hr>
        <div
          style={{padding: "10px"}}>
          {props.patientDetail}
        </div>
        <div
          style={{color: "#0D71BB",  fontSize: "18px", fontWeight: "bold", paddingBottom: "10px"}}>
          วันที่และเวลานัดหมาย
        </div>
        <div
          className="ui form"
          style={{paddingLeft: "10px"}}>
          
          <FormGroup
            inline={true}>
            <FormField
              width="6">
              <div
                style={{fontSize: "16px"}}>
                {props.selectDate}
              </div>
            </FormField>
            <FormField
              width="6">
              <div
                style={{display: "flex", alignItems: "center", width: "100%"}}>
                
                <div
                  style={{display: "flex", paddingRight: "10px", fontSize: "16px"}}>
                  
                  <div>
                    เวลา
                  </div>
                  <div
                    style={{color:"red"}}>
                    *
                  </div>
                </div>
                <div
                  style={{width: "150px"}}>
                  {props.selectTime}
                </div>
                <div
                  style={{paddingLeft: "10px"}}>
                  {props.viewListIcon}
                </div>
              </div>
            </FormField>
          </FormGroup>
        </div>
        <div
          className="ui form"
          style={{paddingLeft: "10px"}}>
          
          <FormGroup
            inline={true}>
            <FormField
              style={{fontSize: "16px"}}
              width="6">
              <div>
                สถานที่:
              </div>
              <div>
                {props.divisionDetail}
              </div>
            </FormField>
            <FormField
              style={{fontSize: "16px"}}
              width="6">
              <div>
                แพทย์:
              </div>
              <div>
                {props.doctorDetail}
              </div>
            </FormField>
          </FormGroup>
        </div>
        <div
          style={{display: "flex", justifyContent: "flex-end"}}>
          {}
          <div
            style={{paddingRight: "10px"}}>
            {props.buttonSave}
          </div>
          <Button
            color="red"
            onClick={props.onCancel}>
            ยกเลิก
          </Button>
        </div>
      </div>
    )
}


export default ModConsultDateTimeUX

export const screenPropsDefault = {}

/* Date Time : Wed Oct 18 2023 10:48:09 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding: \"20px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "รายละเอียดการนัดหมาย"
        },
        "style": {
          "type": "code",
          "value": "{color: \"#0D71BB\",  fontSize: \"18px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "hr",
      "parent": 0,
      "props": {
      },
      "seq": 2,
      "void": true
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientDetail"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่และเวลานัดหมาย"
        },
        "style": {
          "type": "code",
          "value": "{color: \"#0D71BB\",  fontSize: \"18px\", fontWeight: \"bold\", paddingBottom: \"10px\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"10px\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormGroup",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "FormField",
      "parent": 8,
      "props": {
        "width": {
          "type": "value",
          "value": "6"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "FormField",
      "parent": 8,
      "props": {
        "width": {
          "type": "value",
          "value": "6"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectDate"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"16px\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", width: \"100%\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", paddingRight: \"10px\", fontSize: \"16px\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectTime"
        },
        "style": {
          "type": "code",
          "value": "{width: \"150px\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "code",
          "value": "props.viewListIcon"
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"10px\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลา"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color:\"red\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"10px\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "FormGroup",
      "parent": 22,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "FormField",
      "parent": 23,
      "props": {
        "style": {
          "type": "code",
          "value": "{fontSize: \"16px\"}"
        },
        "width": {
          "type": "value",
          "value": "6"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "FormField",
      "parent": 23,
      "props": {
        "style": {
          "type": "code",
          "value": "{fontSize: \"16px\"}"
        },
        "width": {
          "type": "value",
          "value": "6"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานที่:"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": "แพทย์:"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 24,
      "props": {
        "children": {
          "type": "code",
          "value": "props.divisionDetail"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "code",
          "value": "props.doctorDetail"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "Button",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิก"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancel"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "style": {
          "type": "code",
          "value": "{paddingRight: \"10px\"}"
        }
      },
      "seq": 32,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 75,
  "name": "ModConsultDateTimeUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 55
}

*********************************************************************************** */
