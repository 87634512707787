import React, { useEffect, useState } from "react";
import { Button, Header } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

const ShowDivision = (props) => {
  const intl = useIntl();
  const history = useHistory();
  const [listPending, setListPending] = useState([]);

  useEffect(() => {
    const getPendingList = async () => {
      const [listDivision, error2, network2] =
        await props.controller.getListOARequestCheck({
          apiToken: props.apiToken,
        });
      console.log(" listDivision", listDivision, error2, network2);
      if (listDivision?.items?.length > 0) {
        let nameList = listDivision?.items?.map(
          (item) => JSON.parse(item.division_profile.extra).name
        );
        setListPending(nameList);
      } else if (props.myDivisions?.length > 1) {
        // no waiting 
        history.push("/Chat");
      } else {
        history.push("/");
      }
    };
    getPendingList();
  }, []);

  return (
    <div className="no-oa-channel">
      <div className="title-first">{intl.formatMessage({ id: "คุณร้องขอเข้าใช้งาน Official Account" })}</div>
      <ul>
        {listPending?.length > 0 &&
          listPending.map((item, idx) => {
            return (
              <>
                <li key={idx} className="title-second">
                  {item} <br />
                </li>
              </>
            );
          })}
      </ul>
      <div className="title-first">
        <br />
        กรุณารอการตรวจสอบจากแอดมิน
      </div>

      {props.myDivisions?.length > 1 && (
        <div className="last-div">
          <Button
            color="blue"
            style={{ width: "300px" }}
            onClick={() => {
              console.log(" Goto Chat");
              history.push("/Chat");
            }}
          >
            กลับสู่หน้าหลัก
          </Button>
        </div>
      )}
    </div>
  );
};

export default ShowDivision;
