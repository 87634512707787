import React, { useState, SyntheticEvent } from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import { FormControlLabel } from "@mui/material";

import Android12Switch from "react-lib/appcon/common/Android12Switch";
import { useIntl } from "react-intl";

// Interface
import {
  RunSequence,
  AggStockSerializer,
  BUTTON_ACTIONS,
} from "./sequence/StockManagement";

// Types
type DisplayCountingProps = {
  // seq
  runSequence: RunSequence;
  // data
  data: AggStockSerializer;
  card: string;
  // config
  disabled?: boolean;
};

// Const
const COLORS = {
  blue: "rgb(2 52 150)",
};

const CountingSwitch = (props: DisplayCountingProps) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleCounting = (e: SyntheticEvent) => {
    handlePrevent(e);

    props.runSequence({
      sequence: "StockManagement",
      action: "COUNTING",
      stock: props.data,
      card: props.card,
      errorKey: props.card,
      btnAction: BUTTON_ACTIONS.COUNTING,
      onLoading: setIsLoading,
    });
  };

  const handlePrevent = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return props.data.storage.id ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
      // onClick={handlePrevent}
    >
      <div style={{ position: "relative" }}>
        <FormControlLabel
          control={
            <Android12Switch
              background={COLORS.blue}
              checked={!!props.data.in_reconcile}
              disabled={props.disabled}
              onChange={handleCounting}
            />
          }
          label=""
          style={{ margin: "0 auto", transform: "scale(0.8)" }}
        />
        <Dimmer
          active={isLoading}
          size="mini"
          inverted
          style={{ background: "transparent", paddingRight: 0 }}
        >
          <Loader
            size="mini"
            inline={true}
            style={{
              marginLeft: props.data.in_reconcile ? "-17px" : "-32px",
            }}
          ></Loader>
        </Dimmer>
      </div>
    </div>
  ) : null;
};

export default React.memo(CountingSwitch);
