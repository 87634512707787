import {
  Alignment,
  ColumnProperties,
  Content,
  ContentColumns,
  ContentStack,
  TDocumentDefinitions,
} from "pdfmake/interfaces";

import { getDataURLFromURL, getLogoReportNResize, getWidth } from "./CommonInterface";

import CONFIG from "config/config";

// Types
type ComponyLogoFormProps = {
  alignment?: Alignment;
  font: FontType;
  form: string;
  height: number;
  hideLogo?: CompanyType[];
  isHorizontal?: boolean;
  maxWidth?: number;
  showAddress?: CompanyType[];
  type?: number;
};

export type CompanyType = "CU" | "SAPIENS";

export type FontType = "KanitLM" | "PoppinsLM" | "THSarabunNew";

export type CompanyLogoLayoutConfig = ColumnProperties &
  ContentColumns &
  Pick<TDocumentDefinitions, "images"> & {
    addressWidth: number;
    fonts: typeof FONTS | typeof TH_SARABUN_FONTS;
    fontSizes: Record<number, number>;
    lineHeights: Record<number, number>;
    logoHeight: number;
    logoWidth: number;
  };

interface CustomContentStack extends ContentStack {
  stack: (Content & { scale?: number })[];
}

type CompanyLogoType = Partial<
  Record<
    string,
    {
      address: { horizontal?: CustomContentStack; vertical: CustomContentStack };
      marginTop?: number;
    }
  >
>;

const COMPANY_LOGO: CompanyLogoType = {
  CU: {
    address: {
      horizontal: {
        stack: [
          { bold: true, lineHeight: 0.8, text: "โรงพยาบาลคณะทันตแพทยศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย" },
          { bold: true, text: "Chulalongkorn University Faculty of Dentistry Hospital" },
        ],
      },
      vertical: {
        stack: [
          { bold: true, lineHeight: 0.8, text: "โรงพยาบาลคณะทันตแพทยศาสตร์" },
          { bold: true, text: "จุฬาลงกรณ์มหาวิทยาลัย" },
          { lineHeight: 0.8, text: "ถนนอังรีดูนังต์ ปทุมวัน กรุงเทพฯ 10330" },
          { text: "โทร 02-218-8705" },
        ],
      },
    },
  },
  SAPIENS: {
    address: {
      vertical: {
        marginLeft: 5,
        stack: [
          { bold: true, lineHeight: 1, marginTop: -2.5, scale: 1.9, text: "โรงพยาบาล เซเปี้ยนซ์" },
          { lineHeight: 0.8, scale: 1.2, text: "498/7 ซอยรามคำแหง 39 (เทพลีลา1)" },
          { scale: 1.2, text: "แขวงวังทองหลาง เขตวังทองหลาง กรุงเทพฯ 10310 โทร 02-111-3703" },
        ],
      },
    },
  },
};

const FONTS = {
  KANIT_LM: "KanitLM",
  POPPINS: "Poppins",
  POPPINS_LM: "PoppinsLM",
  TH_SARABUN_NEW: "THSarabunNew",
} as const;

const TH_SARABUN_FONTS = {
  KANIT_LM: FONTS.TH_SARABUN_NEW,
  POPPINS: FONTS.TH_SARABUN_NEW,
  POPPINS_LM: FONTS.TH_SARABUN_NEW,
  TH_SARABUN_NEW: FONTS.TH_SARABUN_NEW,
} as const;

const generateAdjustedValues = (
  start: number,
  end: number,
  step: number,
  fontOffset: number
): Record<number, number> => {
  const length = Math.floor((end - start) / step) + 1;
  const entries = Array.from({ length }, (unused, index) => {
    const value = Number.parseFloat((start + index * step).toFixed(2));
    const adjustedValue = Number.parseFloat((value * fontOffset).toFixed(2));

    return [value, adjustedValue] as [number, number];
  });

  return Object.fromEntries(entries);
};

const generateFontSize = (fontType: FontType): Record<number, number> => {
  const fontOffset = ([FONTS.KANIT_LM, FONTS.POPPINS_LM] as string[]).includes(fontType)
    ? 0.727
    : 1;

  return generateAdjustedValues(0, 30, 0.05, fontOffset);
};

const generateLineHeight = (fontType: FontType): Record<number, number> => {
  const fontOffset = ([FONTS.KANIT_LM, FONTS.POPPINS_LM] as string[]).includes(fontType)
    ? 1.193
    : 1;

  return generateAdjustedValues(0, 3, 0.05, fontOffset);
};

const getStack = (
  companyLogo: CompanyLogoType[string],
  align: "horizontal" | "vertical",
  showAddress: boolean
) => {
  if (!showAddress) {
    return [{ text: "" }];
  }

  const addressContent = companyLogo?.address[align];

  if (!addressContent?.stack) {
    return [{ text: "" }];
  }

  return addressContent.stack;
};

export const getCompanyFont = () =>
  CONFIG.COMPANY === "SAPIENS" ? FONTS.KANIT_LM : FONTS.TH_SARABUN_NEW;

const ComponyLogoForm = async (props: ComponyLogoFormProps): Promise<CompanyLogoLayoutConfig> => {
  const type = props.type || 1;

  const companyName: CompanyType = CONFIG.COMPANY || "";
  const companyLogo = COMPANY_LOGO[companyName];
  const { alignment, font, form, height, isHorizontal, maxWidth } = props;

  let logoHeight = height;
  let marginTop = -1.5;

  const logo = await getLogoReportNResize(logoHeight, type, form);

  if (maxWidth && logo.width > maxWidth) {
    logoHeight *= maxWidth / logo.width;
    logo.width = maxWidth;

    marginTop += (height - logoHeight) / 2;
  }

  // 140: Address width
  const fontSizes = generateFontSize(font);
  const lineHeights = generateLineHeight(font);
  const align = isHorizontal ? "horizontal" : "vertical";
  const showAddress = !!companyLogo?.address && !!props.showAddress?.includes(companyName);

  const stack = getStack(companyLogo, align, showAddress);

  const fontSize = Object.keys(fontSizes).find((size) => {
    const height = stack.length * (Number(size) + 2);
    const difference = Math.abs(logoHeight - height);

    return difference >= 0 && difference <= 1;
  });
  const textWidths = stack.map((data: any) => getWidth(data.text, Number(fontSize)));
  const addressWidth = Math.max(...textWidths) + 5;

  const width = addressWidth + logo.width;

  const address = companyLogo?.address[align];

  const hideLogo = !!CONFIG.HIDE_COMPANY_LOGO_PRINT || !!props.hideLogo?.includes(companyName);

  if (address?.stack) {
    address.stack = address.stack.map((content) => {
      if (typeof content === "object") {
        return {
          ...content,
          ...(content.scale && { fontSize: Number(fontSize) * content.scale }),
        } as Content;
      }

      return content;
    });
  }

  const logos = await getDataURLFromURL([{ image: logo.src ? `${origin}${logo.src}` : "" }]);

  return {
    addressWidth,
    columns: hideLogo
      ? []
      : [
          {
            alignment,
            marginTop,
            stack: [{ height: logoHeight, image: "logo", width: logo.width }],
            width: alignment ? "100%" : "auto",
          },
          showAddress ? { ...(address as ContentStack), fontSize: Number(fontSize) } : { text: "" },
        ],
    font,
    fonts: font === FONTS.TH_SARABUN_NEW ? TH_SARABUN_FONTS : FONTS,
    fontSizes,
    images: {
      logo: logos[0].image,
    },
    lineHeights,
    logoHeight,
    logoWidth: logo.width,
    width,
  };
};

export default ComponyLogoForm;
