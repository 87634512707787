import React from "react";
import CardAppointmentEncounterUX from "./CardAppointmentEncounterUX";
import { useIntl } from "react-intl";

const CardAppointmentEncounter = (props: any) => {
  return(
    <CardAppointmentEncounterUX
      languageUX={props.languageUX}
    />
  )
}

export default CardAppointmentEncounter