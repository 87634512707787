import WasmController from 'react-lib/frameworks/WasmController';
import AdverseReactionList from "issara-sdk/apis/AdverseReactionList_apps_ADR"
import AdverseReactionDetail from "issara-sdk/apis/AdverseReactionDetail_apps_ADR";
import PatientEMRNew from "issara-sdk/apis/PatientEMRNew_apps_DPO";
import PatientEMRList from 'issara-sdk/apis/PatientEMRList_apps_DPO';
import EncounterDetail from 'issara-sdk/apis/EncounterDetail_core';
import PatientEMREdit from "issara-sdk/apis/PatientEMREdit_apps_DPO";
import PhysicalExamOrganList from "issara-sdk/apis/PhysicalExamOrganList_apps_DPO";
import * as DoctorNoteOrder from "./sequence/DoctorNoteOrder";
import * as SetProgressCycle from "./sequence/SetProgressCycle";
import * as OrderTreatment from "./sequence/OrderTreatment";
import * as DoctorPendingTask from "./sequence/DoctorPendingTask"
import * as TreatmentResult from"./sequence/TreatmentResult";
import * as DoctorCertificate from "./sequence/DoctorCertificate";
import * as ConsultationList from "./sequence/ConsultationList";
import * as TimeTrackingI from "./sequence/TimeTracking";

export type State = {
  // Prerequisite
  selectedEncounter?: any | null;
  selectedEmr?: any | null;

  // Other states
} & DoctorNoteOrder.State &
  SetProgressCycle.State &
  OrderTreatment.State &
  DoctorPendingTask.State &
  TreatmentResult.State &
  DoctorCertificate.State &
  ConsultationList.State & 
  TimeTrackingI.State

export const StateInitial: State = 
  {
    // ...DoctorNoteOrder.StateInitial,
    // ...SetProgressCycle.StateInitial,
    // ...OrderTreatment.StateInitial,
    // ...DoctorPendingTask.StateInitial,
    // ...TreatmentResult.StateInitial,
    // ...DoctorCertificate.StateInitial,
    // ...ConsultationList.StateInitial
  }

export type Event =
  | { message: "getAdverseReactionList"; params: any }
  | { message: "postAdverseReaction"; params: any }
  | { message: "putAdverseReaction"; params: any }
  | { message: "postPatientScreenNew"; params: any }
  | { message: "getEncounterDetail"; params: any }
  | { message: "getMedicalRecordEmrDetail"; params: any }
  | { message: "putPatientScreenUpdate"; params: any }
  | { message: "getPhysicalExamOrgan"; params: any }
  | DoctorNoteOrder.Event
  | SetProgressCycle.Event
  | OrderTreatment.Event
  | DoctorPendingTask.Event
  | TreatmentResult.Event
  | DoctorCertificate.Event
  | ConsultationList.Event
  | TimeTrackingI.Event

export type Data = {
  division?: number;
} & DoctorNoteOrder.Data &
  SetProgressCycle.Data &
  OrderTreatment.Data &
  DoctorPendingTask.Data &
  TreatmentResult.Data &
  DoctorCertificate.Data &
  ConsultationList.Data &
  TimeTrackingI.Data

export const DataInitial = 
  {
    ...DoctorNoteOrder.DataInitial,
    ...SetProgressCycle.DataInitial,
    ...OrderTreatment.DataInitial,
    ...DoctorPendingTask.DataInitial,
    ...TreatmentResult.DataInitial
  }

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any 


export const getPhysicalExamOrgan: Handler = async (controller, {}) => {
  return await PhysicalExamOrganList.list({
    extra: { division: controller.data.division },
    apiToken: controller.apiToken
  });
}

export const putAdverseReaction: Handler = async (controller, {
  patientId,
  action,
  note,
  adrId,
  changeNote
}) => {
  let params: any = {};
  let data: any = {};
  data.patient = controller.getState().selectedEncounter.patient_id;
  if (action) data.action = action;
  data.note = note;
  if (changeNote) params.change_note = changeNote;
  return await AdverseReactionDetail.update({
    pk: adrId,
    data: data,
    apiToken: controller.apiToken,
  });
}

export const putPatientScreenUpdate: Handler = async (controller, { data }) => {
  return await PatientEMREdit.put({
    data: data,
    apiToken: controller.apiToken
  });
}

export const getEncounterDetail: Handler = async (controller, { encounterId }) => {
  return await EncounterDetail.retrieve({
    pk: encounterId,
    apiToken: controller.apiToken,
    extra: { division: controller.data.division }
  });
}

export const getMedicalRecordEmrDetail: Handler = async (
  controller,
  { emrId, autoCheckIn }
) => {
  const params: Record<string, any> = {};

  params.auto_checkin = autoCheckIn;

  return await PatientEMRList.get({
    emr: emrId,
    params,
    apiToken: controller.apiToken,
    extra: { division: controller.data.division },
  });
};

export const postPatientScreenNew: Handler = async (controller, { data }) => {
  return await PatientEMRNew.post({
    data: data,
    apiToken: controller.apiToken
  });
}

export const postAdverseReaction: Handler = async (controller, { patientId, action, note }) => {
  let data: any = {};
  let params: any = {};
  data.patient = controller.getState().selectedEncounter.patient_id
  if (action) data.action = action;
  data.note = note;
  return await AdverseReactionList.create({
    params: params,
    data: data,
    apiToken: controller.apiToken
  });
}

export const getAdverseReactionList: Handler = async (controller, {
  patientId,
  today,
  excludeCancel,
  byUser,
  excludeUnused,
  severeFirst,
  approveOnly,
  showNotKnownByDoctor,
  notApproveOnly,
  byPatientId
}) => {
  let params: any = {};
  params.patient = controller.getState().selectedEncounter?.patient_id;

  if (byPatientId){
    params.patient = patientId
  }

  if (today) params.today = today;
  if (excludeCancel) params.exclude_cancel = excludeCancel;
  if (byUser) params.by_user = byUser;
  if (excludeUnused) params.exclude_unused = excludeUnused;
  if (severeFirst) params.severe_first = severeFirst;
  if (approveOnly) params.approve_only = approveOnly;
  if (showNotKnownByDoctor) params.show_not_known_by_doctor = showNotKnownByDoctor;
  if (notApproveOnly) params.not_approve_only = notApproveOnly;
  return await AdverseReactionList.list({
    params: params,
    apiToken: controller.apiToken
  });
}
