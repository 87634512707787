import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";

// PDF
const PDF = "/static/pdf/taxDoctor/200360_WHT1.pdf";
const Font = "/static/fonts/THSarabunNew-Bold.ttf";

const FormPND1Report = async (data: any) => {
  try {
    const existingPdfBytes = await fetch(PDF).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Load your custom font
    const fontUrl = Font; // Replace with your font URL
    const fontBytes = await fetch(fontUrl).then((res) => res.arrayBuffer());
    pdfDoc.registerFontkit(fontkit);
    const customFont = await pdfDoc.embedFont(fontBytes);
    const zapFont = await pdfDoc.embedFont(StandardFonts.ZapfDingbats);

    const getDecimal = (value: number) => {
      let rounded = Math.floor(value);

      return (value - rounded || 0).toFixed(2)?.toLocaleString().slice(2, 4);
    };

    const fontSize = 12;
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];
    const textCoordinates = [
      { text: data?.officerId, x: 165, y: 738 },

      { text: data?.branch, x: 302, y: 708 },

      { text: data?.payerName, x: 50, y: 691 },
      { text: data?.buildingName, x: 90, y: 674 },
      { text: data?.roomNo, x: 187, y: 675 },
      { text: data?.floor, x: 227, y: 675 },
      { text: data?.village, x: 265, y: 675 },
      { text: data?.No, x: 60, y: 660 },
      { text: data?.villageNo, x: 158, y: 660 },
      { text: data?.alley, x: 212, y: 660 },
      { text: data?.Junction, x: 295, y: 658 },
      { text: data?.street, x: 60, y: 643 },
      { text: data?.subDistrict, x: 215, y: 643 },
      { text: data?.district, x: 80, y: 627 },
      { text: data?.province, x: 216, y: 627 },
      { text: data?.zipCode, x: 95, y: 612 },
      { text: data?.hospitalPhone, x: 170, y: 612 },
      { text: data?.year, x: 525, y: 718 },

      { text: data?.specialSubmitNo?.toString(), x: 287, y: 570 },

      { text: data?.total_page?.toString(), x: 513, y: 524 },

      {
        text: data?.recordingInComputerQuantity?.toString(),
        x: 513,
        y: 505,
      },
      { text: data?.registerNo?.toString(), x: 500, y: 490 },
      { text: data?.registerRefNo?.toString(), x: 500, y: 476 },
      { text: data?.doctor_count?.toString(), x: 340, y: 417 },
      {
        text: Number(data?.total_net_income_price?.toFixed(0))?.toLocaleString(),
        x:
          437 -
          customFont?.widthOfTextAtSize(
            (data?.total_net_income_price || 0)?.toFixed(0)?.toLocaleString(),
            fontSize
          ),
        y: 417,
      },
      {
        text:
          data?.total_net_income_price === 0
            ? "00"
            : getDecimal(Number(data?.total_net_income_price)),
        x: 443,
        y: 417,
      },
      {
        text: Number(data?.total_tax?.toFixed(0))?.toLocaleString(),
        x:
          523 -
          customFont?.widthOfTextAtSize(
            (data?.total_tax || 0)?.toFixed(0)?.toLocaleString(),
            fontSize
          ),
        y: 417,
      },
      {
        text: data?.total_tax === 0 ? "00" : getDecimal(Number(data?.total_tax)),
        x: 529.5,
        y: 417,
      },
      { text: data?.summaryNo2CaseNumber, x: 340, y: 368 },
      {
        text: data?.summaryNo2TotalIncome?.toLocaleString(),
        x:
          440 -
          customFont.widthOfTextAtSize(
            (data?.summaryNo2TotalIncome || 0)?.toLocaleString(),
            fontSize
          ),
        y: 368,
      },
      {
        text:
          data?.summaryNo2TotalIncomeDecimal === 0
            ? "00"
            : data?.summaryNo2TotalIncomeDecimal?.toString().slice(0, 2),
        x: 443,
        y: 368,
      },
      {
        text: data?.summaryNo2Tax?.toLocaleString(),
        x:
          526 -
          customFont.widthOfTextAtSize((data?.summaryNo2Tax || 0)?.toLocaleString(), fontSize),
        y: 368,
      },
      {
        text:
          data?.summaryNo2TaxDecimal === 0
            ? "00"
            : data?.summaryNo2TaxDecimal?.toString().slice(0, 2),
        x: 529.5,
        y: 368,
      },
      { text: data?.summaryNo2BookNo, x: 150, y: 368 },
      { text: data?.summaryNo2Date, x: 240, y: 368 },
      { text: data?.summaryNo3CaseNumber, x: 340, y: 330 },
      {
        text: data?.summaryNo3TotalIncome?.toLocaleString(),
        x:
          440 -
          customFont.widthOfTextAtSize(
            (data?.summaryNo3TotalIncome || 0)?.toLocaleString(),
            fontSize
          ),
        y: 330,
      },
      {
        text:
          data?.summaryNo3TotalIncomeDecimal === 0
            ? "00"
            : data?.summaryNo3TotalIncomeDecimal?.toString().slice(0, 2),
        x: 443,
        y: 330,
      },
      {
        text: data?.summaryNo3Tax?.toLocaleString(),
        x:
          526 -
          customFont.widthOfTextAtSize((data?.summaryNo3Tax || 0)?.toLocaleString(), fontSize),
        y: 330,
      },
      {
        text:
          data?.summaryNo3TaxDecimal === 0
            ? "00"
            : data?.summaryNo3TaxDecimal?.toString().slice(0, 2),
        x: 529.5,
        y: 330,
      },
      { text: data?.summaryNo4CaseNumber, x: 340, y: 312 },
      {
        text: data?.summaryNo4TotalIncome?.toLocaleString(),
        x:
          440 -
          customFont.widthOfTextAtSize(
            (data?.summaryNo4TotalIncome || 0)?.toLocaleString(),
            fontSize
          ),
        y: 312,
      },
      {
        text:
          data?.summaryNo4TotalIncomeDecimal === 0
            ? "00"
            : data?.summaryNo4TotalIncomeDecimal?.toString().slice(0, 2),
        x: 443,
        y: 312,
      },
      {
        text: data?.summaryNo4Tax?.toLocaleString(),
        x:
          526 -
          customFont.widthOfTextAtSize((data?.summaryNo4Tax || 0)?.toLocaleString(), fontSize),
        y: 312,
      },
      {
        text:
          data?.summaryNo4TaxDecimal === 0
            ? "00"
            : data?.summaryNo4TaxDecimal?.toString().slice(0, 2),
        x: 529.5,
        y: 312,
      },
      { text: data?.summaryNo5CaseNumber, x: 340, y: 294 },
      {
        text: data?.summaryNo5TotalIncome?.toLocaleString(),
        x:
          440 -
          customFont.widthOfTextAtSize(
            (data?.summaryNo5TotalIncome || 0)?.toLocaleString(),
            fontSize
          ),
        y: 294,
      },
      {
        text:
          data?.summaryNo5TotalIncomeDecimal === 0
            ? "00"
            : data?.summaryNo5TotalIncomeDecimal?.toString().slice(0, 2),
        x: 443,
        y: 294,
      },
      {
        text: data?.summaryNo5Tax?.toLocaleString(),
        x:
          526 -
          customFont.widthOfTextAtSize((data?.summaryNo5Tax || 0)?.toLocaleString(), fontSize),
        y: 294,
      },
      {
        text:
          data?.summaryNo5TaxDecimal === 0
            ? "00"
            : data?.summaryNo5TaxDecimal?.toString().slice(0, 2),
        x: 529.5,
        y: 294,
      },
      {
        text: data?.summaryNo6totalCaseNumber?.toString(),
        x: 340,
        y: 276,
      },
      {
        text: data?.summaryNo6MoneyTotalIncome?.toLocaleString(),
        x:
          440 -
          customFont.widthOfTextAtSize(
            (data?.summaryNo6MoneyTotalIncome || 0)?.toLocaleString(),
            fontSize
          ),
        y: 276,
      },
      {
        text:
          data?.summaryNo6MoneyTotalIncomeDecimal === 0
            ? "00"
            : data?.summaryNo6MoneyTotalIncomeDecimal?.toString().slice(0, 2),
        x: 443,
        y: 276,
      },
      {
        text: data?.summaryNo6TotalTax?.toLocaleString(),
        x:
          526 -
          customFont.widthOfTextAtSize((data?.summaryNo6TotalTax || 0)?.toLocaleString(), fontSize),
        y: 276,
      },
      {
        text:
          data?.summaryNo6TotalTaxDecimal === 0
            ? "00"
            : data?.summaryNo6TotalTaxDecimal?.toString().slice(0, 2),
        x: 529.5,
        y: 276,
      },
      {
        text: data?.summaryNo7If?.toLocaleString(),
        x:
          526 - customFont.widthOfTextAtSize((data?.summaryNo7If || 0)?.toLocaleString(), fontSize),
        y: 262,
      },
      {
        text:
          data?.summaryNo7IfDecimal === 0
            ? "00"
            : data?.summaryNo7IfDecimal?.toString().slice(0, 2),
        x: 529.5,
        y: 262,
      },
      {
        text: data?.summaryNo8?.toLocaleString(),
        x: 526 - customFont.widthOfTextAtSize((data?.summaryNo8 || 0)?.toLocaleString(), fontSize),
        y: 240,
      },
      {
        text:
          data?.summaryNo8Decimal === 0 ? "00" : data?.summaryNo8Decimal?.toString().slice(0, 2),
        x: 529.5,
        y: 240,
      },
      { text: data?.payerFullName, x: 280, y: 145 },
      { text: data?.position, x: 280, y: 125 },
      { text: data?.submitDate, x: 245, y: 108 },
      { text: data?.submitMonth, x: 290, y: 108 },
      { text: data?.submitYear, x: 354, y: 108 },
      // Add other text fields using data? here...
    ];

    for (const { text, x, y } of textCoordinates) {
      let adjustedX = x; // Initialize adjustedX to the original x coordinate

      if (text === data?.officerId) {
        // Loop through each character in data?.taxId
        for (let j = 0; j < data?.officerId?.length; j++) {
          const charSpacing = 6.5;
          if (j === 1 || j === 5 || j === 10 || j === 12) {
            adjustedX += charSpacing;
          } // Apply spacing adjustment if necessary

          // Get the current character from taxId
          const char = data?.officerId[j];

          // Draw the character at the adjustedX position
          firstPage.drawText(char, {
            x: adjustedX,
            y,
            size: fontSize,
            font: customFont,
            color: rgb(0, 0, 0),
          });

          // Increment adjustedX by the width of the character plus the spacing adjustment
          adjustedX += customFont.widthOfTextAtSize(char, fontSize) + charSpacing;
        }
      } else if (text === data?.branch) {
        const charSpacing = 3.3;

        for (let j = 0; j < data?.branch?.length; j++) {
          const char = data?.branch[j];

          // Adjust spacing for specific letters
          if (j === 1 || j === 2 || j === 3) {
            adjustedX += charSpacing; // Increase the X coordinate by charSpacing
          }

          // Draw the character at the currentX position
          firstPage.drawText(char, {
            x: adjustedX,
            y,
            size: fontSize,
            font: customFont,
            color: rgb(0, 0, 0),
          });

          // Update currentX with character width and spacing
          adjustedX += customFont.widthOfTextAtSize(char, fontSize) + charSpacing;
        }
      } else if (text === data?.zipCode) {
        const charSpacing = 3;

        for (let j = 0; j < data?.zipCode?.length; j++) {
          const char = data?.zipCode[j];

          // Adjust spacing for specific letters
          if (j === 1 || j === 2 || j === 3 || j === 4 || j === 5) {
            adjustedX += charSpacing; // Increase the X coordinate by charSpacing
          }

          // Draw the character at the currentX position
          firstPage.drawText(char, {
            x: adjustedX,
            y,
            size: fontSize,
            font: customFont,
            color: rgb(0, 0, 0),
          });

          // Update currentX with character width and spacing
          adjustedX += customFont.widthOfTextAtSize(char, fontSize) + charSpacing;
        }
      } else {
        // Draw the text as it is without any adjustments
        firstPage.drawText(text, {
          x,
          y,
          size: fontSize,
          font: customFont,
          color: rgb(0, 0, 0),
        });
      }
    }
    const checkSymbol = "\u2713"; // Check symbol
    const falseText = ""; // Empty text when isMoneyType is false
    const isNormalSubmit = checkSymbol;
    const isSpecialSubmit = data?.isSpecialSubmit ? checkSymbol : falseText;
    const isJanuary = data?.month === "1" ? checkSymbol : falseText;
    const isFebruary = data?.month === "4" ? checkSymbol : falseText;
    const isMarch = data?.month === "7" ? checkSymbol : falseText;
    const isMay = data?.month === "10" ? checkSymbol : falseText;
    const isApril = data?.month === "2" ? checkSymbol : falseText;
    const isJune = data?.month === "5" ? checkSymbol : falseText;
    const isJuly = data?.month === "8" ? checkSymbol : falseText;
    const isAugust = data?.month === "11" ? checkSymbol : falseText;
    const isSeptember = data?.month === "3" ? checkSymbol : falseText;
    const isOctober = data?.month === "6" ? checkSymbol : falseText;
    const isNovember = data?.month === "9" ? checkSymbol : falseText;
    const isDecember = data?.month === "12" ? checkSymbol : falseText;
    const isAttachment = checkSymbol;
    const isRecordingInComputer = data?.isRecordingInComputer ? checkSymbol : falseText;

    // Draw each check symbol at appropriate coordinates
    firstPage.drawText(isNormalSubmit, {
      x: 87,
      y: 568,
      size: fontSize,
      font: zapFont,
    });
    firstPage.drawText(isSpecialSubmit, {
      x: 182,
      y: 568,
      size: fontSize,
      font: zapFont,
    });
    firstPage.drawText(isJanuary, {
      x: 340,
      y: 692,
      size: fontSize,
      font: zapFont,
    });
    firstPage.drawText(isFebruary, {
      x: 397,
      y: 692,
      size: fontSize,
      font: zapFont,
    });
    firstPage.drawText(isMarch, {
      x: 455,
      y: 692,
      size: fontSize,
      font: zapFont,
    });
    firstPage.drawText(isApril, {
      x: 510,
      y: 692,
      size: fontSize,
      font: zapFont,
    });
    firstPage.drawText(isMay, {
      x: 340,
      y: 668,
      size: fontSize,
      font: zapFont,
    });
    firstPage.drawText(isJune, {
      x: 397,
      y: 668,
      size: fontSize,
      font: zapFont,
    });
    firstPage.drawText(isJuly, {
      x: 455,
      y: 668,
      size: fontSize,
      font: zapFont,
    });
    firstPage.drawText(isAugust, {
      x: 510,
      y: 668,
      size: fontSize,
      font: zapFont,
    });
    firstPage.drawText(isSeptember, {
      x: 340,
      y: 645,
      size: fontSize,
      font: zapFont,
    });
    firstPage.drawText(isOctober, {
      x: 397,
      y: 645,
      size: fontSize,
      font: zapFont,
    });
    firstPage.drawText(isNovember, {
      x: 455,
      y: 645,
      size: fontSize,
      font: zapFont,
    });
    firstPage.drawText(isDecember, {
      x: 510,
      y: 645,
      size: fontSize,
      font: zapFont,
    });
    firstPage.drawText(isAttachment, {
      x: 266,
      y: 522,
      size: fontSize,
      font: zapFont,
    });
    firstPage.drawText(isRecordingInComputer, {
      x: 266,
      y: 503,
      size: fontSize,
      font: zapFont,
    });

    const modifiedPdfBytes = await pdfDoc.save();
    const modifiedPdfUrl = URL.createObjectURL(
      new Blob([modifiedPdfBytes], { type: "application/pdf" })
    );

    return { uint8Array: modifiedPdfBytes, blobUrl: modifiedPdfUrl };
  } catch (error) {
    console.error("Error modifying PDF:", error);

    return {};
  }
};

export default FormPND1Report;
