import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Checkbox,
  Button,
  Input
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardOPDDownloadSentClaimFileUX = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{width: "100%",height:"100%",padding:"10px"}}>
        <div
          className="ui form">
          
          <div
            style={{fontSize: "1.2rem", fontWeight: "bold", padding: "2.5px 0"}}>
            รายการที่สร้างเลขงวดแล้ว
          </div>
        </div>
        <div
          className="ui divider">
          
        </div>
        <div
          className="ui form">
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label>
                เลือกงวด
              </label>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <div
                style={{width: "100%"}}>
                {props.SearchARTransaction}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <label>
                ปีงบประมาณ
              </label>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <Input
                readonly={true}
                value={props.fiscalYear || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}>
              <Checkbox
                checked={props.isDate || false}
                label="ระบุวันที่"
                name="isDate"
                onChange={props.onChangeValue}
                style={{fontWeight: "bold"}}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}>
              <DateTextBox
                disabled={!props.isDate || false}
                onChange={props.onChangeDate?.("startDate")}
                value={props.startDate}>
              </DateTextBox>
            </FormField>
            <FormField
              inline={true}>
              <label>
                ถึง
              </label>
            </FormField>
            <FormField
              inline={true}>
              <DateTextBox
                disabled={!props.isDate || false}
                onChange={props.onChangeDate?.("endDate")}
                value={props.endDate}>
              </DateTextBox>
            </FormField>
            <FormField
              inline={true}>
              <label>
                สิทธิ
              </label>
            </FormField>
            <FormField
              inline={true}>
              <Input
                readonly={true}
                value={props.coveragePayer || ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <Checkbox
                checked={props.isOnlyC || false}
                label="ดูเฉพาะรายการที่ validate ไม่ผ่านหรือติด C"
                name="isOnlyC"
                onChange={props.onChangeValue}
                style={{fontWeight: "bold"}}
                toggle={true}>
              </Checkbox>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{width: "30px", height: "15px", backgroundColor: "rgb(255, 204, 102)"}}>
                
              </div>
              <label>
                Validate ไม่ผ่าน
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{width: "30px", height: "15px", backgroundColor: "rgb(255, 197, 197)"}}>
                
              </div>
              <label>
                ติด C
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{width: "30px", height: "15px", backgroundColor: "rgb(153, 204, 255)"}}>
                
              </div>
              <label>
                รอผลตอบกลับ
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{width: "30px", height: "15px", backgroundColor: "rgb(153, 255, 153)"}}>
                
              </div>
              <label>
                เบิกสำเร็จ
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{width: "30px", height: "15px", backgroundColor: "rgb(201, 255, 229)"}}>
                
              </div>
              <label>
                ไม่ส่งเบิก
              </label>
            </FormField>
          </FormGroup>
        </div>
        <div>
          
          <Table
            data={props.billTransactionList || []}
            getTrProps={props.getTrProps}
            headers=",สถานะส่งเบิก,Station,Auth Code,Date Transaction,Hospital Code,Invoice No.,Bill No.,HN,Member No.,Amount,Paid,Verify Code,T Flag,Personal ID,Name,Hospital Main,Pay Plan,Claim Amount,Other Pay Plan,Other Pay"
            keys="description,status_display,station,auth_code,dt_tran,hospital_code,invoice_no,bill_no,hn,member_no,amount,paid,verify_code,t_flag,pid,name,hospital_main,pay_plan,claim_amount,other_pay_plan,other_pay"
            showPagination={false}
            style={{height: "calc(100vh - 33rem)"}}>
          </Table>
        </div>
        <div
          className="ui form"
          style={{marginTop: "1rem"}}>
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={3}>
              <label
                style={{minWidth: "fit-content"}}>
                ยอดรวม
              </label>
              <Input
                readOnly={true}
                value={props.billSummary?.total_price || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <label
                style={{minWidth: "fit-content"}}>
                ยอดหมวด 2
              </label>
              <Input
                readOnly={true}
                value={props.billSummary?.total_price_bill_item_mode_2 || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <label
                style={{minWidth: "fit-content"}}>
                ยอดหมวด 3-5
              </label>
              <Input
                readOnly={true}
                value={props.billSummary?.total_price_bill_item_mode_3_5 || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <label
                style={{minWidth: "fit-content"}}>
                 ยอดหมวดที่เหลือ
              </label>
              <Input
                readOnly={true}
                value={props.billSummary?.total_price_bill_item_mode_remain || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <label
                style={{minWidth: "fit-content"}}>
                ยอด Dispensing
              </label>
              <Input
                readOnly={true}
                value={props.billSummary?.total_price_dispensing || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <label
                style={{minWidth: "fit-content"}}>
                 ยอด OPService
              </label>
              <Input
                readOnly={true}
                value={props.billSummary?.total_price_operation_service || ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={3}>
              <label
                style={{minWidth: "fit-content"}}>
                เบิกรวม
              </label>
              <Input
                readOnly={true}
                value={props.billSummary?.total_sent_claim_price || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <label
                style={{minWidth: "fit-content"}}>
                เบิกหมวด 2
              </label>
              <Input
                readOnly={true}
                value={props.billSummary?.total_sent_claim_price_bill_item_mode_2 || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <label
                style={{minWidth: "fit-content"}}>
                เบิกหมวด 3-5
              </label>
              <Input
                readOnly={true}
                value={props.billSummary?.total_sent_claim_price_bill_item_mode_3_5 || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <label
                style={{minWidth: "fit-content"}}>
                 เบิกหมวดที่เหลือ
              </label>
              <Input
                readOnly={true}
                value={props.billSummary?.total_sent_claim_price_bill_item_mode_remain || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <label
                style={{minWidth: "fit-content"}}>
                เบิก Dispensing
              </label>
              <Input
                readOnly={true}
                value={props.billSummary?.total_sent_claim_price_dispensing || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <label
                style={{minWidth: "fit-content"}}>
                 เบิก OPService
              </label>
              <Input
                readOnly={true}
                value={props.billSummary?.total_sent_claim_price_bill_item_mode_remain || ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={3}>
              <label
                style={{minWidth: "fit-content"}}>
                จำนวนข้อมูล
              </label>
              <Input
                readOnly={true}
                value={props.billSummary?.total_rows || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <label
                style={{minWidth: "fit-content"}}>
                 ยอดผู้ป่วยจ่าย
              </label>
              <Input
                readOnly={true}
                value={props.billSummary?.total_paid || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <label
                style={{minWidth: "fit-content"}}>
                ยอดร่วมจ่าย
              </label>
              <Input
                readOnly={true}
                value={props.billSummary?.total_other_pay || ""}>
              </Input>
            </FormField>
          </FormGroup>
        </div>
        <div
          className="ui form"
          style={{marginTop: "1.5rem", paddingBottom: "1rem"}}>
          
          <div
            className="inline fields"
            style={{justifyContent: "flex-end"}}>
            
            <FormField
              inline={true}>
              <div>
                {props.ButtonUpdate}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <Button
                color="violet"
                disabled={props.disabledDownloadZipFile || false}
                onClick={props.onDownloadZipFile}>
                Download Zip file ย้อนหลัง
              </Button>
            </FormField>
            <FormField
              inline={true}>
              <div>
                {props.ButtonDownload}
              </div>
            </FormField>
          </div>
        </div>
      </div>
    )
}

export default CardOPDDownloadSentClaimFileUX

export const screenPropsDefault = {}

/* Date Time : Thu Feb 02 2023 10:56:47 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width: \"100%\",height:\"100%\",padding:\"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการที่สร้างเลขงวดแล้ว"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.2rem\", fontWeight: \"bold\", padding: \"2.5px 0\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormGroup",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "label",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "เลือกงวด"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "FormGroup",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "FormField",
      "parent": 31,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "Checkbox",
      "parent": 32,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isOnlyC || false"
        },
        "label": {
          "type": "value",
          "value": "ดูเฉพาะรายการที่ validate ไม่ผ่านหรือติด C"
        },
        "name": {
          "type": "value",
          "value": "isOnlyC"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 50,
      "name": "Table",
      "parent": 49,
      "props": {
        "columns": {
          "type": "code",
          "value": ""
        },
        "data": {
          "type": "code",
          "value": "props.billTransactionList || []"
        },
        "getTdProps": {
          "type": "code",
          "value": ""
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getTrProps"
        },
        "headers": {
          "type": "value",
          "value": ",สถานะส่งเบิก,Station,Auth Code,Date Transaction,Hospital Code,Invoice No.,Bill No.,HN,Member No.,Amount,Paid,Verify Code,T Flag,Personal ID,Name,Hospital Main,Pay Plan,Claim Amount,Other Pay Plan,Other Pay"
        },
        "keys": {
          "type": "value",
          "value": "description,status_display,station,auth_code,dt_tran,hospital_code,invoice_no,bill_no,hn,member_no,amount,paid,verify_code,t_flag,pid,name,hospital_main,pay_plan,claim_amount,other_pay_plan,other_pay"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"calc(100vh - 33rem)\"}"
        },
        "widths": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"1rem\"}"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "FormGroup",
      "parent": 51,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"1.5rem\", paddingBottom: \"1rem\"}"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "div",
      "parent": 77,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "inline fields"
        },
        "style": {
          "type": "code",
          "value": "{justifyContent: \"flex-end\"}"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 79,
      "name": "FormField",
      "parent": 78,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 81,
      "name": "FormField",
      "parent": 78,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "FormField",
      "parent": 78,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 85,
      "name": "Button",
      "parent": 81,
      "props": {
        "children": {
          "type": "value",
          "value": "Download Zip file ย้อนหลัง"
        },
        "color": {
          "type": "value",
          "value": "violet"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledDownloadZipFile || false"
        },
        "onClick": {
          "type": "code",
          "value": "props.onDownloadZipFile"
        },
        "size": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": null,
      "id": 93,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "code",
          "value": "props.SearchARTransaction"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": null,
      "id": 102,
      "name": "label",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": "ปีงบประมาณ"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 103,
      "name": "Input",
      "parent": 14,
      "props": {
        "readonly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.fiscalYear || \"\""
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 104,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 105,
      "name": "Checkbox",
      "parent": 104,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isDate || false"
        },
        "label": {
          "type": "value",
          "value": "ระบุวันที่"
        },
        "name": {
          "type": "value",
          "value": "isDate"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 106,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 107,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 108,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 109,
      "name": "DateTextBox",
      "parent": 106,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.isDate || false"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeDate?.(\"startDate\")"
        },
        "value": {
          "type": "code",
          "value": "props.startDate"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 110,
      "name": "DateTextBox",
      "parent": 108,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.isDate || false"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeDate?.(\"endDate\")"
        },
        "value": {
          "type": "code",
          "value": "props.endDate"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": null,
      "id": 111,
      "name": "label",
      "parent": 107,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 112,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 113,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 114,
      "name": "Input",
      "parent": 113,
      "props": {
        "readonly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.coveragePayer || \"\""
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": null,
      "id": 115,
      "name": "label",
      "parent": 112,
      "props": {
        "children": {
          "type": "value",
          "value": "สิทธิ"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 116,
      "name": "FormGroup",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 117,
      "name": "FormField",
      "parent": 116,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 118,
      "name": "FormField",
      "parent": 116,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 119,
      "name": "FormField",
      "parent": 116,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 120,
      "name": "FormField",
      "parent": 116,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 121,
      "name": "FormField",
      "parent": 116,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": null,
      "id": 122,
      "name": "div",
      "parent": 117,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"30px\", height: \"15px\", backgroundColor: \"rgb(255, 204, 102)\"}"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": null,
      "id": 123,
      "name": "div",
      "parent": 118,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"30px\", height: \"15px\", backgroundColor: \"rgb(255, 197, 197)\"}"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": null,
      "id": 124,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"30px\", height: \"15px\", backgroundColor: \"rgb(153, 204, 255)\"}"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": null,
      "id": 125,
      "name": "div",
      "parent": 120,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"30px\", height: \"15px\", backgroundColor: \"rgb(153, 255, 153)\"}"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": null,
      "id": 126,
      "name": "div",
      "parent": 121,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"30px\", height: \"15px\", backgroundColor: \"rgb(201, 255, 229)\"}"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": null,
      "id": 127,
      "name": "label",
      "parent": 117,
      "props": {
        "children": {
          "type": "value",
          "value": "Validate ไม่ผ่าน"
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": null,
      "id": 128,
      "name": "label",
      "parent": 118,
      "props": {
        "children": {
          "type": "value",
          "value": "ติด C"
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": null,
      "id": 129,
      "name": "label",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": "รอผลตอบกลับ"
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": null,
      "id": 130,
      "name": "label",
      "parent": 120,
      "props": {
        "children": {
          "type": "value",
          "value": "เบิกสำเร็จ"
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": null,
      "id": 131,
      "name": "label",
      "parent": 121,
      "props": {
        "children": {
          "type": "value",
          "value": "ไม่ส่งเบิก"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 132,
      "name": "FormField",
      "parent": 52,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 133,
      "name": "FormField",
      "parent": 52,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 134,
      "name": "FormField",
      "parent": 52,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 135,
      "name": "FormField",
      "parent": 52,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 136,
      "name": "FormField",
      "parent": 52,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": null,
      "id": 137,
      "name": "label",
      "parent": 132,
      "props": {
        "children": {
          "type": "value",
          "value": "ยอดรวม"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"fit-content\"}"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": null,
      "id": 138,
      "name": "label",
      "parent": 133,
      "props": {
        "children": {
          "type": "value",
          "value": "ยอดหมวด 2"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"fit-content\"}"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": null,
      "id": 139,
      "name": "label",
      "parent": 134,
      "props": {
        "children": {
          "type": "value",
          "value": "ยอดหมวด 3-5"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"fit-content\"}"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": null,
      "id": 140,
      "name": "label",
      "parent": 135,
      "props": {
        "children": {
          "type": "value",
          "value": " ยอดหมวดที่เหลือ"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"fit-content\"}"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": null,
      "id": 141,
      "name": "label",
      "parent": 136,
      "props": {
        "children": {
          "type": "value",
          "value": "ยอด Dispensing"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"fit-content\"}"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 142,
      "name": "Input",
      "parent": 132,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.billSummary?.total_price || \"\""
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 143,
      "name": "Input",
      "parent": 133,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.billSummary?.total_price_bill_item_mode_2 || \"\""
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 144,
      "name": "Input",
      "parent": 134,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.billSummary?.total_price_bill_item_mode_3_5 || \"\""
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 145,
      "name": "Input",
      "parent": 135,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.billSummary?.total_price_bill_item_mode_remain || \"\""
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 146,
      "name": "Input",
      "parent": 136,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.billSummary?.total_price_dispensing || \"\""
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 147,
      "name": "FormField",
      "parent": 52,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": null,
      "id": 148,
      "name": "label",
      "parent": 147,
      "props": {
        "children": {
          "type": "value",
          "value": " ยอด OPService"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"fit-content\"}"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 149,
      "name": "Input",
      "parent": 147,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.billSummary?.total_price_operation_service || \"\""
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 150,
      "name": "FormGroup",
      "parent": 51,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 151,
      "name": "FormField",
      "parent": 150,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 152,
      "name": "FormField",
      "parent": 150,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 153,
      "name": "FormField",
      "parent": 150,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 154,
      "name": "FormField",
      "parent": 150,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 155,
      "name": "FormField",
      "parent": 150,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 156,
      "name": "FormField",
      "parent": 150,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": null,
      "id": 157,
      "name": "label",
      "parent": 151,
      "props": {
        "children": {
          "type": "value",
          "value": "เบิกรวม"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"fit-content\"}"
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": null,
      "id": 158,
      "name": "label",
      "parent": 152,
      "props": {
        "children": {
          "type": "value",
          "value": "เบิกหมวด 2"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"fit-content\"}"
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": null,
      "id": 159,
      "name": "label",
      "parent": 153,
      "props": {
        "children": {
          "type": "value",
          "value": "เบิกหมวด 3-5"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"fit-content\"}"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": null,
      "id": 160,
      "name": "label",
      "parent": 154,
      "props": {
        "children": {
          "type": "value",
          "value": " เบิกหมวดที่เหลือ"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"fit-content\"}"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": null,
      "id": 161,
      "name": "label",
      "parent": 155,
      "props": {
        "children": {
          "type": "value",
          "value": "เบิก Dispensing"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"fit-content\"}"
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": null,
      "id": 162,
      "name": "label",
      "parent": 156,
      "props": {
        "children": {
          "type": "value",
          "value": " เบิก OPService"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"fit-content\"}"
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 163,
      "name": "Input",
      "parent": 151,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.billSummary?.total_sent_claim_price || \"\""
        }
      },
      "seq": 163,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 164,
      "name": "Input",
      "parent": 152,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.billSummary?.total_sent_claim_price_bill_item_mode_2 || \"\""
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 165,
      "name": "Input",
      "parent": 153,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.billSummary?.total_sent_claim_price_bill_item_mode_3_5 || \"\""
        }
      },
      "seq": 165,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 166,
      "name": "Input",
      "parent": 154,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.billSummary?.total_sent_claim_price_bill_item_mode_remain || \"\""
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 167,
      "name": "Input",
      "parent": 155,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.billSummary?.total_sent_claim_price_dispensing || \"\""
        }
      },
      "seq": 167,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 168,
      "name": "Input",
      "parent": 156,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.billSummary?.total_sent_claim_price_bill_item_mode_remain || \"\""
        }
      },
      "seq": 168,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 169,
      "name": "FormGroup",
      "parent": 51,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 169,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 170,
      "name": "FormField",
      "parent": 169,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 170,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 171,
      "name": "FormField",
      "parent": 169,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 171,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 172,
      "name": "FormField",
      "parent": 169,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 172,
      "void": false
    },
    {
      "from": null,
      "id": 173,
      "name": "label",
      "parent": 170,
      "props": {
        "children": {
          "type": "value",
          "value": "จำนวนข้อมูล"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"fit-content\"}"
        }
      },
      "seq": 173,
      "void": false
    },
    {
      "from": null,
      "id": 174,
      "name": "label",
      "parent": 171,
      "props": {
        "children": {
          "type": "value",
          "value": " ยอดผู้ป่วยจ่าย"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"fit-content\"}"
        }
      },
      "seq": 174,
      "void": false
    },
    {
      "from": null,
      "id": 175,
      "name": "label",
      "parent": 172,
      "props": {
        "children": {
          "type": "value",
          "value": "ยอดร่วมจ่าย"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"fit-content\"}"
        }
      },
      "seq": 175,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 176,
      "name": "Input",
      "parent": 170,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.billSummary?.total_rows || \"\""
        }
      },
      "seq": 176,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 177,
      "name": "Input",
      "parent": 171,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.billSummary?.total_paid || \"\""
        }
      },
      "seq": 177,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 178,
      "name": "Input",
      "parent": 172,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.billSummary?.total_other_pay || \"\""
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": null,
      "id": 179,
      "name": "div",
      "parent": 79,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonUpdate"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 179,
      "void": false
    },
    {
      "from": null,
      "id": 180,
      "name": "div",
      "parent": 82,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonDownload"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 180,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 100,
  "isMounted": true,
  "memo": false,
  "name": "CardOPDDownloadSentClaimFileUX",
  "project": "CLM CU",
  "screenPropsDefault": {
  },
  "width": 100
}

*********************************************************************************** */
