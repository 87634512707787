import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Dropdown,
  Button
} from 'semantic-ui-react'

const CardServiceRefundUX = (props: any) => {
    return(
      <div
        style={{ zoom: "90%" }}>
        <div
          style={{fontWeight: "bold", fontSize: "1.2rem",padding: "10px 10px 0px 10px", display: "flex", justifyContent: "space-between"}}>
          แจ้งคืนค่าบริการ
        </div>
        <div
          style={{ padding: "0px 10px 10px 10px"}}>
          
          <hr>
          </hr>
        </div>
        <div
          style={{display: "flex", justifyContent: "space-between", margin: "10px"}}>
          
          <div
            style={{display: "flex", }}>
            
            <div
              style={{ paddingRight: "10px", display: "flex", alignItems: 'center'}}>
              ค้นหาEncounter
            </div>
            <div
              style={{ width: "500px" }}>
              
              <Dropdown
                fluid={true}
                onChange={props.onChangeValue?.("encounterSelect")}
                options={props.encounterList}
                selection={true}
                value={props.data?.encounterSelect}>
              </Dropdown>
            </div>
          </div>
          <div>
            
            <Button
              color={props.selectedMode==="refundable"&& "green"}
              name="refundable"
              onClick={props.onSelectMode}>
              รายการที่แจ้งคืนได้
            </Button>
            <Button
              color={props.selectedMode==="refunding" && "green"}
              name="refunding"
              onClick={props.onSelectMode}
              style={{  margin: "0px 10px" }}>
              รายการที่รอเงินคืน
            </Button>
            <Button
              color={props.selectedMode==="refunded"&& "green"}
              name="refunded"
              onClick={props.onSelectMode}>
              รายการที่คืนเงินแล้ว
            </Button>
          </div>
        </div>
        <Table
          data={props.invoiceItemData}
          getTrProps={props.handleRowProps}
          headers="รหัสหมวด,รหัสค่ารักษา,ชื่อรายการค่ารักษาพยาบาล,จำนวน,ราคาต่อหน่วย,ราคา,ส่งเบิก,absorb,เบิกเอง,เบิกไม่ได้,ส่วนลด,ผู้ป่วยชำระ"
          keys="mode_code,pricing_code,product,quantity,unitprice,price,send_claim,absorb,patient_claim,non_claimable,discount,payable"
          minRows={5}
          showPagination={false}
          style={{height: "450px",  margin: "0px 10px"}}
          widths="150,150,*,90,120,120,120,120,120,120,120,*">
        </Table>
        <div
          style={{display: "flex", justifyContent: "flex-end", margin: "10px"}}>
          
          <div>
            
            <Button
              disabled={!(props.selectedMode === "refundable" && props.selectedRow)}
              onClick={props.handleClickRefund}
              style={{  margin: "0px 10px" }}>
              แจ้งคืนเงิน
            </Button>
            <Button
              color="red"
              disabled={!(props.selectedMode === "refunding" && props.selectedRow)}
              onClick={props.handleClickCancelRefund}>
              ยกเลิกแจ้งคืนเงิน
            </Button>
          </div>
        </div>
        <div
          style={{width: "100%", height: "200px"}}>
          
        </div>
      </div>
    )
}


export default CardServiceRefundUX

export const screenPropsDefault = {}

/* Date Time : Wed Nov 06 2024 09:17:51 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ zoom: \"90%\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "แจ้งคืนค่าบริการ"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.2rem\",padding: \"10px 10px 0px 10px\", display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"200px\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"0px 10px 10px 10px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"space-between\", margin: \"10px\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "hr",
      "parent": 3,
      "props": {
      },
      "seq": 5,
      "void": true
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", margin: \"10px\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 7,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.invoiceItemData"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.handleRowProps"
        },
        "headers": {
          "type": "value",
          "value": "รหัสหมวด,รหัสค่ารักษา,ชื่อรายการค่ารักษาพยาบาล,จำนวน,ราคาต่อหน่วย,ราคา,ส่งเบิก,absorb,เบิกเอง,เบิกไม่ได้,ส่วนลด,ผู้ป่วยชำระ"
        },
        "keys": {
          "type": "value",
          "value": "mode_code,pricing_code,product,quantity,unitprice,price,send_claim,absorb,patient_claim,non_claimable,discount,payable"
        },
        "minRows": {
          "type": "code",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"450px\",  margin: \"0px 10px\"}"
        },
        "widths": {
          "type": "value",
          "value": "150,150,*,90,120,120,120,120,120,120,120,*"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", }"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหาEncounter"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\", display: \"flex\", alignItems: 'center'}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"500px\" }"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "Dropdown",
      "parent": 11,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue?.(\"encounterSelect\")"
        },
        "options": {
          "type": "code",
          "value": "props.encounterList"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.data?.encounterSelect"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "Button",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการที่แจ้งคืนได้"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "color": {
          "type": "code",
          "value": "props.selectedMode===\"refundable\"&& \"green\""
        },
        "disabled": {
          "type": "code",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "refundable"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSelectMode"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Button",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการที่รอเงินคืน"
        },
        "color": {
          "type": "code",
          "value": "props.selectedMode===\"refunding\" && \"green\""
        },
        "name": {
          "type": "value",
          "value": "refunding"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSelectMode"
        },
        "style": {
          "type": "code",
          "value": "{  margin: \"0px 10px\" }"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "Button",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการที่คืนเงินแล้ว"
        },
        "color": {
          "type": "code",
          "value": "props.selectedMode===\"refunded\"&& \"green\""
        },
        "name": {
          "type": "value",
          "value": "refunded"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSelectMode"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "Button",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": "แจ้งคืนเงิน"
        },
        "disabled": {
          "type": "code",
          "value": "!(props.selectedMode === \"refundable\" && props.selectedRow)"
        },
        "onClick": {
          "type": "code",
          "value": "props.handleClickRefund"
        },
        "style": {
          "type": "code",
          "value": "{  margin: \"0px 10px\" }"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "Button",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิกแจ้งคืนเงิน"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "disabled": {
          "type": "code",
          "value": "!(props.selectedMode === \"refunding\" && props.selectedRow)"
        },
        "onClick": {
          "type": "code",
          "value": "props.handleClickCancelRefund"
        }
      },
      "seq": 18,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardServiceRefundUX",
  "project": "New Project",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
