
/* Generated apis ---------------------- */
import { to } from 'react-lib/utils'
import BaseService from  'react-lib/apis/services/BaseService'
import config from '../../config/config'
import UserDetailSerializer from '../types/UserDetailSerializer_users'


const HOST = `${config.API_HOST}`
/* ['generics.RetrieveUpdateAPIView'] */
/* params:  */
/* data:  */
const UserDetailAPIViewM : 
{
  retrieve: ({pk, params, apiToken, extra}:
             { pk: any, 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
  update:   ({pk, params, data, apiToken, extra}:
             { pk: any, 
                params?: any,
                data?: UserDetailSerializer,
                apiToken?: any,
                extra?: any,
            }) => any,
  patch:    ({pk, params, data, apiToken, extra}:
             { pk: any, 
                params?: any,
                data?: UserDetailSerializer,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  retrieve: async ({pk, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken)
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    if (extra?.division)
      base.client.defaults.headers["division"] = extra.division;
    if (extra?.device)
      base.client.defaults.headers["device"] = extra.device;
    const result = await to(base.client.get(`${HOST}/users/apis/users/${pk}/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  update: async ({pk, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken)
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
      base.client.defaults.headers["content-type"] = "multipart/form-data"
    if (extra?.division)
      base.client.defaults.headers["division"] = extra.division;
    if (extra?.device)
      base.client.defaults.headers["device"] = extra.device;
    const formData = new FormData();
      for (const [key, value] of Object.entries(data)) {
        formData.append(key, value)
      }
    const result = await to(base.client.put(`${HOST}/users/apis/users/${pk}/`,
    formData,
    {
      params: params ? { ...params } : {},
      ...extra
    }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  patch: async ({pk, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken)
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
      base.client.defaults.headers["content-type"] = "multipart/form-data"
    if (extra?.division)
      base.client.defaults.headers["division"] = extra.division;
    if (extra?.device)
      base.client.defaults.headers["device"] = extra.device;
      const formData = new FormData();
      for (const [key, value] of Object.entries(data || {})) {
        formData.append(key, value)
      }
    const result = await to(base.client.patch(`${HOST}/users/apis/users/${pk}/`,
    formData,
    {
      params: params ? { ...params } : {},
      ...extra
    }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

export default UserDetailAPIViewM

