import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';


const CardDefaultCertificateUX = (props: any) => {
    return(
      <div>
        <div>
          {props.onStartText}
        </div>
      </div>
    )
}


export default CardDefaultCertificateUX

export const screenPropsDefault = {}

/* Date Time : Thu Feb 15 2024 10:49:56 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.onStartText"
        }
      },
      "seq": 1,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardDefaultCertificateUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
