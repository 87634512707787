import React, { useState, useEffect, useMemo } from "react";
import { Button, Icon, Modal } from "semantic-ui-react";

// UX
import CardConfirmCoverageUX from "./CardConfirmCoverageUX";
import SearchBox from "react-lib/appcon/common/SearchBox";

// Common
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import { useIntl } from "react-intl";

const CardConfirmCoverage = (props: any) => {
  const intl = useIntl();
  const [loadding, setLoadding] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    props.runSequence({ sequence: "SetCoverage", restart: true });
  }, []);

  useEffect(() => {
    if (
      props.errorMessage?.SetCoverage !== null &&
      props.errorMessage?.SetCoverage !== undefined
    ) {
      setLoadding(false);
    }

    if (
      props.successMessage?.SetCoverage !== null &&
      props.successMessage?.SetCoverage !== undefined
    ) {
      setLoadding(false);
      setCompleted(true);

      setTimeout(() => {
        setCompleted(false);
      }, 2000);

      props.runSequence({
        sequence: "SetCoverage",
        action: "clear",
      });
    }
  }, [props.errorMessage?.SetCoverage, props.successMessage?.SetCoverage]);

  // Memo
  const coverageList = useMemo(() => {
    return (props.SetCoverageSequence?.coverageList || []).map((item: any) => ({
      ...item,
      status: item?.active ? "รับรองสิทธิ" : "ระงับสิทธิ",
    }));
  }, [props.SetCoverageSequence?.coverageList]);

  const selectedCoverage = useMemo(() => {
    return props.SetCoverageSequence?.selectedCoverage;
  }, [props.SetCoverageSequence?.selectedCoverage]);

  const formattedMessage = useMemo(() => {
    const error = props.errorMessage?.SetCoverage;

    const message = {
      service_type: "ประเภทบริการ",
      coverage: "สิทธิ",
      assure_type: "ประเภทสิทธิ์",
      payer: "ผู้จ่ายเงิน",
      start_date: "วันที่เริ่ม",
      stop_date: "วันหมดอายุ",
    };

    return error
      ? {
          กรอกข้อมูลที่จำเป็น: Object.entries(message)
            .map(([key, value]) => error[key] && value)
            .filter(Boolean),
        }
      : null;
  }, [props.errorMessage?.SetCoverage]);

  // Handler
  const changeCoverage = (key: string, value?: any) => (e?: any, v?: any) => {
    props.setProp(
      `SetCoverageSequence.selectedCoverage.${key}`,
      typeof value === "undefined" ? v.value : value
    );
  };

  const changeDate = (key: string) => (date: any) => {
    console.log(key, date) 
    props.setProp(`SetCoverageSequence.selectedCoverage.${key}`, date);
  }

  console.log(selectedCoverage)

  const onSaveData = () => {
    // -if (selectedCoverage.coverage === 467) {
    //   setOpenModal(true)
    // }
    setLoadding(true);
    props.runSequence({
      sequence: "SetCoverage",
      action: "save",
      callback: () => {
        props.onRefreshPatient?.()
        // props.onEvent({
        //   message: "HandleSelect",
        //   params: {
        //     action: "select",
        //     patient: props?.patient,
        //     noClear: true,
        //   },
        // });
      },
    });
  };

  return (
    <div style={{ paddingBottom: "2rem" }}>
      <Modal open={openModal} onClose={() => setOpenModal(false)} size={"tiny"}>
        <div
          style={{
            background: "#2185d0",
            fontSize: "1.2rem",
            fontWeight: "bold",
            color: "white",
            padding: "20px",
          }}
        >
          สิทธิที่รับรองเกินวงเงินสำหรับส่งเบิกแล้ว ผู้ป่วยต้องทำการสำรองจ่าย
        </div>
        <div
          style={{ padding: "20px", display: "flex", justifyContent: "center" }}
        >
          <Button color="green" onClick={() => setOpenModal(false)}>
            ตกลง
          </Button>
        </div>
      </Modal>

      <ErrorMessage
        error={formattedMessage}
        style={{ margin: "10px 10px 0" }}
      />

      <ErrorMessage
        error={props.errorMessage?.SetCoverage}
        style={{ margin: "10px 10px 0" }}
      />

      {/* #{openSnackMessage ? (
        <SnackMessage
          onEvent={props.onEvent}
          onClose={() => {
            setOpenSnackMessage(false);
            props.runSequence({
              sequence: "SetCoverage",
              action: "clear",
            });
          }}
          error={props.errorMessage?.SetCoverage}
          success={props.successMessage?.SetCoverage}
        />
      ) : (
        ""
      )} */}
      {/* #{open ? (
        <div style={{ padding: "10px" }}>
          <Message color="red">
            <Message.Header>กรอกข้อมูลที่จำเป็น</Message.Header>
            <p>{handleSetAlertData()}</p>
          </Message>
        </div>
      ) : (
        ""
      )} */}
      <CardConfirmCoverageUX
        coverageList={coverageList}
        new={!selectedCoverage?.id}
        // Fields
        service_type={selectedCoverage?.service_type || ""}
        priority={selectedCoverage?.priority || ""}
        priorityOptions={[
          { key: 99, value: 99, text: "ไม่เลือก" },
          { key: 1, value: 1, text: 1 },
          { key: 2, value: 2, text: 2 },
          { key: 3, value: 3, text: 3 },
          { key: 4, value: 4, text: 4 },
          { key: 5, value: 5, text: 5 },
          { key: 6, value: 6, text: 6 },
          { key: 7, value: 7, text: 7 },
          { key: 8, value: 8, text: 8 },
          { key: 9, value: 9, text: 9 },
          { key: 10, value: 10, text: 10 },
        ]}
        active={selectedCoverage?.active || false}
        coverage={selectedCoverage?.coverage || ""}
        coverageOptions={props.masterOptions?.coverage || []}
        servicePointOptions={props.masterOptions?.divisionOpd || []}
        refererOptions={props.masterOptions?.referer || []}
        referer={selectedCoverage?.referer || ""}
        servicePoint={selectedCoverage?.service_point || ""}
        refer_date={selectedCoverage?.refer_date}
        refer_no={selectedCoverage?.refer_no || ""}
        assure_type={selectedCoverage?.assure_type || ""}
        assureTypeOptions={props.masterOptions?.assureType || []}
        main_hospital_code={selectedCoverage?.main_hospital_code || ""}
        start_date={selectedCoverage?.start_date}
        stop_date={selectedCoverage?.stop_date}
        searchBoxPayer={
          <SearchBox
            vertical={true}
            onEvent={props.onEvent}
            setProp={props.setProp}
            searchedItemList={props.searchedItemList}
            selectedItem={selectedCoverage?.payer}
            setSelectedItem={(item: any) => {
              console.log("set");
              console.log(item);
              changeCoverage("payer", item)();
            }}
            type="Payer"
            label={
              <div style={{ color: "red", marginBottom: "0.25rem" }}>
                ผู้จ่ายเงิน*
              </div>
            }
            placeholder={intl.formatMessage({ id: "กรอกชื่อผู้จ่ายเงินที่ต้องการค้นหา" })}
            toDisplay={(item: any) => `${item.name_with_id}`}
            fluid={true}
            style={{ width: "100%" }}
          />
        }
        // Methods
        rowProps={(state: any, rowInfo: any, column: any, instance: any) => ({
          style: {
            backgroundColor:
              selectedCoverage &&
              rowInfo?.original?.id &&
              rowInfo?.original?.id === selectedCoverage?.id
                ? "#d6ecf3"
                : "white",
          },
          onClick: async () => {
            await props.runSequence({
              sequence: "SetCoverage",
              action: "select",
              item: rowInfo?.original,
            });
          },
        })}
        changeCoverage={changeCoverage}
        changeDate={changeDate}
        onNewCoverage={(e: any) =>
          props.runSequence({ sequence: "SetCoverage", action: "new" })
        }
        onSave={(e: any) => {
          onSaveData();
        }}
        button_save={
          completed ? (
            <Icon name="check"></Icon>
          ) : props.SetCoverageSequence?.selectedCoverage?.id !== undefined ? (
            "แก้ไขข้อมูล"
          ) : (
            "SAVE"
          )
        }
        loadingSave={loadding}
        languageUX={props.languageUX}
      />
    </div>
  );
};

export default React.memo(CardConfirmCoverage);
