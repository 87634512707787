import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button
} from 'semantic-ui-react'

const CardPreAssessmentActionLogUX = (props: any) => {
    return(
      <div>
        <div
          style={ {fontWeight: "bold", fontSize:"large",padding: "20px"}}>
          ประวัติการบันทึกแก้ไข
        </div>
        <div>
          
          <Table
            data={props.actionLogData}
            headers="ผู้บันทึก,หมายเหตุ,แผนก,วันที่ เวลา"
            keys="name,remark,division_name,edited"
            minRows="9"
            showPagination={false}
            style={{padding: "20px"}}>
          </Table>
        </div>
        <div
          style={{display: "flex", justifyContent: "space-between", padding: "20px"}}>
          
          <div>
            {props.paginationComponent}
          </div>
          <Button
            onClick={props.onDismiss}>
            OK
          </Button>
        </div>
      </div>
    )
}

export default CardPreAssessmentActionLogUX

export const screenPropsDefault = {}

/* Date Time : Mon Jan 16 2023 02:54:04 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 1,
      "name": "Table",
      "parent": 3,
      "props": {
        "data": {
          "type": "code",
          "value": "props.actionLogData"
        },
        "headers": {
          "type": "value",
          "value": "ผู้บันทึก,หมายเหตุ,แผนก,วันที่ เวลา"
        },
        "keys": {
          "type": "value",
          "value": "name,remark,division_name,edited"
        },
        "minRows": {
          "type": "value",
          "value": "9"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"20px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "code",
          "value": "props.paginationComponent"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติการบันทึกแก้ไข"
        },
        "style": {
          "type": "code",
          "value": " {fontWeight: \"bold\", fontSize:\"large\",padding: \"20px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"space-between\", padding: \"20px\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "Button",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "OK"
        },
        "onClick": {
          "type": "code",
          "value": "props.onDismiss"
        }
      },
      "seq": 7,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardPreAssessmentActionLogUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
