import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button,
  Dropdown
} from 'semantic-ui-react'

const ModDrugOrderActionNoteUX = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
        <div
          style={{ backgroundColor: "#2185D0", color: "white", fontSize: "1.2rem", padding: "10px"}}>
          {props.actionName? `ยืนยัน  ${props.actionName}` : ""}
        </div>
        <div
          style={{padding: "10px", display: "flex", flexDirection: "column"}}>
          
          <div
            style={{ display: "flex",  flexDirection: "column", justifyContent: "center"}}>
            
            <div
              style={{fontSize: "16px", paddingBottom: "10px"}}>
              สาเหตุ
            </div>
            <Dropdown
              fluid={true}
              onChange={props.onChange}
              options={props.options}
              search={true}
              selection={true}
              value={props.value}>
            </Dropdown>
          </div>
          <div
            style={{padding: "20px", display: "flex", alignItems: "center", justifyContent: "space-evenly"}}>
            
            <Button
              className="basic"
              color="green"
              onClick={props.onConfirm}
              style={{width:"100px"}}>
              ใช่
            </Button>
            <Button
              className="basic"
              color="red"
              onClick={props.onCancel}
              style={{width:"100px"}}>
              ไม่
            </Button>
          </div>
        </div>
      </div>
    )
}

export default ModDrugOrderActionNoteUX

export const screenPropsDefault = {"diDetail":"ทดสอบ","needNote":true}

/* Date Time : Thu Jun 16 2022 11:13:34 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"100%\", display: \"flex\", flexDirection: \"column\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.actionName? `ยืนยัน  ${props.actionName}` : \"\""
        },
        "style": {
          "type": "code",
          "value": "{ backgroundColor: \"#2185D0\", color: \"white\", fontSize: \"1.2rem\", padding: \"10px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", display: \"flex\", flexDirection: \"column\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  flexDirection: \"column\", justifyContent: \"center\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"20px\", display: \"flex\", alignItems: \"center\", justifyContent: \"space-evenly\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "Button",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "ใช่"
        },
        "className": {
          "type": "value",
          "value": "basic"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onConfirm"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100px\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "Button",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "ไม่"
        },
        "className": {
          "type": "value",
          "value": "basic"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancel"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100px\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "Dropdown",
      "parent": 12,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChange"
        },
        "options": {
          "type": "code",
          "value": "props.options"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.value"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "สาเหตุ"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"16px\", paddingBottom: \"10px\"}"
        }
      },
      "seq": 18,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 30,
  "isMounted": true,
  "memo": false,
  "name": "ModDrugOrderActionNoteUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "diDetail": "ทดสอบ",
    "needNote": true
  },
  "width": 25
}

*********************************************************************************** */
