import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Dropdown,
  Input,
  TextArea
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardBasicInfoUX = (props: any) => {
    return(
      <div
        id="CardBasicInfo"
        style={{ margin: "15px", padding: "20px", backgroundColor: "rgba(198, 235, 243, 0.2)" }}>
        <div
          style={{ paddingBottom: "5px" }}>
          
          <label
            style={{ fontWeight: "bold", fontSize: "18px" }}>
            Basic Info
          </label>
          <hr>
          </hr>
        </div>
        <div
          className="ui form">
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={16}>
              <div
                style={{width:"100%"}}>
                {props.errorMessage}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={6}>
              <label
                style={{minWidth:"60px", maxWidth: "60px"}}>
                Patient in
              </label>
              <div
                style={{width: "100%", marginRight: "1rem"}}>
                
                <DateTextBox
                  onChange={props.handleChangeDate("patient_in_date")}
                  style={{width:"100%"}}
                  value={props.basicInfoData?.patient_in_date }>
                </DateTextBox>
              </div>
              <div
                style={{width:"100%"}}>
                {props.patientInTime}
              </div>
            </FormField>
            <FormField
              inline={true}
              width={6}>
              <label
                style={{minWidth:"65px", maxWidth: "65px"}}>
                Patient out
              </label>
              <div
                style={{width: "100%", marginRight: "1rem"}}>
                
                <DateTextBox
                  onChange={props.handleChangeDate("patient_out_date")}
                  value={props.basicInfoData?.patient_out_date }>
                </DateTextBox>
              </div>
              <div
                style={{width:"100%"}}>
                {props.patientOutTime}
              </div>
            </FormField>
            <FormField
              inline={true}
              style={{padding:0}}
              width={6}>
              <label
                style={{minWidth:"90px", maxWidth: "90px"}}>
                Total time
              </label>
              <Input
                fluid={true}
                style={{marginRight: "0.5rem"}}
                value={props.splitTime("total_time","h")}>
              </Input>
              <label
                style={{marginRight: "1rem"}}>
                hr.
              </label>
              <Input
                fluid={true}
                style={{marginRight: "0.5rem"}}
                value={props.splitTime("total_time","m")}>
              </Input>
              <label>
                min
              </label>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={6}>
              <label
                style={{minWidth:"60px", maxWidth: "60px"}}>
                Incision
              </label>
              <div
                style={{width: "100%", marginRight: "1rem"}}>
                
                <DateTextBox
                  onChange={props.handleChangeDate("incision_date")}
                  value={props.basicInfoData?.incision_date }>
                </DateTextBox>
              </div>
              <div
                style={{width: "100%"}}>
                {props.incisionTime}
              </div>
            </FormField>
            <FormField
              inline={true}
              width={6}>
              <label
                style={{minWidth:"65px", maxWidth: "65px"}}>
                Closure
              </label>
              <div
                style={{width: "100%", marginRight: "1rem"}}>
                
                <DateTextBox
                  onChange={props.handleChangeDate("closure_date")}
                  value={props.basicInfoData?.closure_date }>
                </DateTextBox>
              </div>
              <div
                style={{width:"100%"}}>
                {props.closureTime}
              </div>
            </FormField>
            <FormField
              inline={true}
              style={{padding:0}}
              width={6}>
              <label
                style={{minWidth:"90px", maxWidth: "90px"}}>
                Total Operation time
              </label>
              <Input
                fluid={true}
                style={{marginRight: "0.5rem"}}
                value={props.splitTime("operation_time","h")}>
              </Input>
              <label
                style={{marginRight: "1rem"}}>
                hr.
              </label>
              <Input
                fluid={true}
                style={{marginRight: "0.5rem"}}
                value={props.splitTime("operation_time","m")}>
              </Input>
              <label>
                min
              </label>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={3}>
              <label>
                Preoperative Diagnosis
              </label>
            </FormField>
            <FormField
              inline={true}
              width={13}>
              <TextArea
                onChange={props.handleChangeValue("pre_diagnosis")}
                rows={3}
                value={props.basicInfoData?.pre_diagnosis || ""}>
              </TextArea>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={3}>
              <label>
                Postoperative Diagnosis
              </label>
            </FormField>
            <FormField
              width={13}>
              <TextArea
                onChange={props.handleChangeValue("post_diagnosis")}
                rows={3}
                value={props.basicInfoData?.post_diagnosis || ""}>
              </TextArea>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={3}>
              <label>
                Operation
              </label>
            </FormField>
            <FormField
              width={13}>
              <TextArea
                onChange={props.handleChangeValue("pre_operation")}
                rows={3}
                value={props.basicInfoData?.pre_operation || ""}>
              </TextArea>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={3}>
              <label>
                Anesthesia
              </label>
            </FormField>
            <FormField>
              <Dropdown
                onChange={props.handleChangeValue("anesthesia_method")}
                options={props.anesthesiaOptions}
                selection={true}
                value={props.basicInfoData?.anesthesia_method}>
              </Dropdown>
            </FormField>
          </FormGroup>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <div
            style={{  width: "50%" }}>
            
            <div>
              {props?.surgeryTeam}
            </div>
          </div>
          <div
            style={{ width: "50%" }}>
            
            <div>
              {props.anesthesiaTeam}
            </div>
          </div>
        </div>
        <div
          style={{ height: "55px", paddingTop: "15px" }}>
          
          <div
            style={{display:"flex",justifyContent:"flex-end"}}>
            
            <div
              style={{margin:"10px"}}>
              {props.buttonSave}
            </div>
            <div
              style={{margin:"10px"}}>
              {props.buttonConfirm}
            </div>
            <div
              style={{margin:"10px"}}>
              {props.buttonUnconfirm}
            </div>
          </div>
        </div>
      </div>
    )
}


export default CardBasicInfoUX

export const screenPropsDefault = {}

/* Date Time : Fri Aug 23 2024 10:44:36 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardBasicInfo"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"15px\", padding: \"20px\", backgroundColor: \"rgba(198, 235, 243, 0.2)\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ paddingBottom: \"5px\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "label",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Basic Info"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"18px\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "hr",
      "parent": 1,
      "props": {
      },
      "seq": 4,
      "void": true
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 46,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",justifyContent:\"flex-end\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ height: \"55px\", paddingTop: \"15px\" }"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "FormGroup",
      "parent": 47,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "FormField",
      "parent": 48,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "FormField",
      "parent": 48,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "FormGroup",
      "parent": 47,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "FormField",
      "parent": 63,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "FormField",
      "parent": 63,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "FormGroup",
      "parent": 47,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 77,
      "name": "FormGroup",
      "parent": 47,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "FormGroup",
      "parent": 47,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 79,
      "name": "FormGroup",
      "parent": 47,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 80,
      "name": "FormField",
      "parent": 76,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 81,
      "name": "FormField",
      "parent": 76,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "label",
      "parent": 80,
      "props": {
        "children": {
          "type": "value",
          "value": "Preoperative Diagnosis"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 84,
      "name": "FormField",
      "parent": 77,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 85,
      "name": "FormField",
      "parent": 77,
      "props": {
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": null,
      "id": 86,
      "name": "label",
      "parent": 84,
      "props": {
        "children": {
          "type": "value",
          "value": "Postoperative Diagnosis"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 88,
      "name": "FormField",
      "parent": 78,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 89,
      "name": "FormField",
      "parent": 79,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 90,
      "name": "FormField",
      "parent": 78,
      "props": {
        "width": {
          "type": "code",
          "value": "13"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 91,
      "name": "FormField",
      "parent": 79,
      "props": {
      },
      "seq": 91,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "label",
      "parent": 88,
      "props": {
        "children": {
          "type": "value",
          "value": "Operation"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 93,
      "name": "label",
      "parent": 89,
      "props": {
        "children": {
          "type": "value",
          "value": "Anesthesia"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 95,
      "name": "Dropdown",
      "parent": 91,
      "props": {
        "disabled": {
          "type": "code",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"anesthesia_method\")"
        },
        "options": {
          "type": "code",
          "value": "props.anesthesiaOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.basicInfoData?.anesthesia_method"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": null,
      "id": 96,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "div",
      "parent": 96,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{  width: \"50%\" }"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": null,
      "id": 98,
      "name": "div",
      "parent": 96,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\" }"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 106,
      "name": "div",
      "parent": 98,
      "props": {
        "children": {
          "type": "code",
          "value": "props.anesthesiaTeam"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": null,
      "id": 107,
      "name": "div",
      "parent": 97,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.surgeryTeam"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 149,
      "name": "FormField",
      "parent": 48,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding:0}"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 150,
      "name": "Input",
      "parent": 149,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.5rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.splitTime(\"total_time\",\"h\")"
        }
      },
      "seq": 169,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 152,
      "name": "FormField",
      "parent": 63,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding:0}"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 154,
      "name": "Input",
      "parent": 152,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.5rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.splitTime(\"operation_time\",\"h\")"
        }
      },
      "seq": 172,
      "void": false
    },
    {
      "from": null,
      "id": 155,
      "name": "div",
      "parent": 42,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"10px\"}"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": null,
      "id": 156,
      "name": "div",
      "parent": 42,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonConfirm"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"10px\"}"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": null,
      "id": 157,
      "name": "div",
      "parent": 42,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonUnconfirm"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"10px\"}"
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 158,
      "name": "FormGroup",
      "parent": 47,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 159,
      "name": "FormField",
      "parent": 158,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": null,
      "id": 160,
      "name": "div",
      "parent": 159,
      "props": {
        "children": {
          "type": "code",
          "value": "props.errorMessage"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": null,
      "id": 169,
      "name": "label",
      "parent": 149,
      "props": {
        "children": {
          "type": "value",
          "value": "hr."
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"1rem\"}"
        }
      },
      "seq": 181,
      "void": false
    },
    {
      "from": null,
      "id": 172,
      "name": "label",
      "parent": 152,
      "props": {
        "children": {
          "type": "value",
          "value": "hr."
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"1rem\"}"
        }
      },
      "seq": 192,
      "void": false
    },
    {
      "from": null,
      "id": 173,
      "name": "div",
      "parent": 51,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", marginRight: \"1rem\"}"
        }
      },
      "seq": 174,
      "void": false
    },
    {
      "from": null,
      "id": 174,
      "name": "label",
      "parent": 51,
      "props": {
        "children": {
          "type": "value",
          "value": "Patient in"
        },
        "style": {
          "type": "code",
          "value": "{minWidth:\"60px\", maxWidth: \"60px\"}"
        }
      },
      "seq": 173,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 175,
      "name": "DateTextBox",
      "parent": 173,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDate(\"patient_in_date\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.basicInfoData?.patient_in_date "
        }
      },
      "seq": 175,
      "void": false
    },
    {
      "from": null,
      "id": 176,
      "name": "div",
      "parent": 51,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientInTime"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        }
      },
      "seq": 176,
      "void": false
    },
    {
      "from": null,
      "id": 177,
      "name": "label",
      "parent": 57,
      "props": {
        "children": {
          "type": "value",
          "value": "Patient out"
        },
        "style": {
          "type": "code",
          "value": "{minWidth:\"65px\", maxWidth: \"65px\"}"
        }
      },
      "seq": 177,
      "void": false
    },
    {
      "from": null,
      "id": 178,
      "name": "div",
      "parent": 57,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", marginRight: \"1rem\"}"
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 179,
      "name": "DateTextBox",
      "parent": 178,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDate(\"patient_out_date\")"
        },
        "value": {
          "type": "code",
          "value": "props.basicInfoData?.patient_out_date "
        }
      },
      "seq": 179,
      "void": false
    },
    {
      "from": null,
      "id": 180,
      "name": "div",
      "parent": 57,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientOutTime"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        }
      },
      "seq": 180,
      "void": false
    },
    {
      "from": null,
      "id": 181,
      "name": "label",
      "parent": 149,
      "props": {
        "children": {
          "type": "value",
          "value": "Total time"
        },
        "style": {
          "type": "code",
          "value": "{minWidth:\"90px\", maxWidth: \"90px\"}"
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 182,
      "name": "Input",
      "parent": 149,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.5rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.splitTime(\"total_time\",\"m\")"
        }
      },
      "seq": 182,
      "void": false
    },
    {
      "from": null,
      "id": 183,
      "name": "label",
      "parent": 149,
      "props": {
        "children": {
          "type": "value",
          "value": "min"
        }
      },
      "seq": 183,
      "void": false
    },
    {
      "from": null,
      "id": 184,
      "name": "label",
      "parent": 65,
      "props": {
        "children": {
          "type": "value",
          "value": "Incision"
        },
        "style": {
          "type": "code",
          "value": "{minWidth:\"60px\", maxWidth: \"60px\"}"
        }
      },
      "seq": 184,
      "void": false
    },
    {
      "from": null,
      "id": 185,
      "name": "div",
      "parent": 65,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", marginRight: \"1rem\"}"
        }
      },
      "seq": 185,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 186,
      "name": "DateTextBox",
      "parent": 185,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDate(\"incision_date\")"
        },
        "value": {
          "type": "code",
          "value": "props.basicInfoData?.incision_date "
        }
      },
      "seq": 186,
      "void": false
    },
    {
      "from": null,
      "id": 187,
      "name": "div",
      "parent": 65,
      "props": {
        "children": {
          "type": "code",
          "value": "props.incisionTime"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 187,
      "void": false
    },
    {
      "from": null,
      "id": 188,
      "name": "label",
      "parent": 72,
      "props": {
        "children": {
          "type": "value",
          "value": "Closure"
        },
        "style": {
          "type": "code",
          "value": "{minWidth:\"65px\", maxWidth: \"65px\"}"
        }
      },
      "seq": 188,
      "void": false
    },
    {
      "from": null,
      "id": 189,
      "name": "div",
      "parent": 72,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", marginRight: \"1rem\"}"
        }
      },
      "seq": 189,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 190,
      "name": "DateTextBox",
      "parent": 189,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDate(\"closure_date\")"
        },
        "value": {
          "type": "code",
          "value": "props.basicInfoData?.closure_date "
        }
      },
      "seq": 190,
      "void": false
    },
    {
      "from": null,
      "id": 191,
      "name": "div",
      "parent": 72,
      "props": {
        "children": {
          "type": "code",
          "value": "props.closureTime"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        }
      },
      "seq": 191,
      "void": false
    },
    {
      "from": null,
      "id": 192,
      "name": "label",
      "parent": 152,
      "props": {
        "children": {
          "type": "value",
          "value": "Total Operation time"
        },
        "style": {
          "type": "code",
          "value": "{minWidth:\"90px\", maxWidth: \"90px\"}"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 193,
      "name": "Input",
      "parent": 152,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.5rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.splitTime(\"operation_time\",\"m\")"
        }
      },
      "seq": 193,
      "void": false
    },
    {
      "from": null,
      "id": 194,
      "name": "label",
      "parent": 152,
      "props": {
        "children": {
          "type": "value",
          "value": "min"
        }
      },
      "seq": 194,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 195,
      "name": "TextArea",
      "parent": 81,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"pre_diagnosis\")"
        },
        "rows": {
          "type": "code",
          "value": "3"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.basicInfoData?.pre_diagnosis || \"\""
        }
      },
      "seq": 195,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 196,
      "name": "TextArea",
      "parent": 85,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"post_diagnosis\")"
        },
        "rows": {
          "type": "code",
          "value": "3"
        },
        "value": {
          "type": "code",
          "value": "props.basicInfoData?.post_diagnosis || \"\""
        }
      },
      "seq": 196,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 197,
      "name": "TextArea",
      "parent": 90,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"pre_operation\")"
        },
        "rows": {
          "type": "code",
          "value": "3"
        },
        "value": {
          "type": "code",
          "value": "props.basicInfoData?.pre_operation || \"\""
        }
      },
      "seq": 197,
      "void": true
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardBasicInfoUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 90
}

*********************************************************************************** */
