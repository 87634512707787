import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  CSSProperties,
  useMemo,
  useImperativeHandle,
  forwardRef,
  SyntheticEvent,
} from "react";
import Cookies from "js-cookie";
import { useHistory, useLocation } from "react-router-dom";
import {
  Button,
  Dropdown,
  Form,
  Grid,
  Input,
  Icon,
  Modal,
  Header,
  Divider,
  Dimmer,
  Loader,
  TextArea,
  SemanticCOLORS,
  Segment,
  Radio,
  Checkbox,
  CheckboxProps,
  DropdownProps,
} from "semantic-ui-react";
import moment from "moment";
// import axios from "axios"

//  Chat Controller
import DashboardController from "react-lib/apps/IsHealth/Dashboard/DashboardController";
import ChatController from "react-lib/apps/IsHealth/Chat/ChatController";

// Nurse Controller
import CardProgressNoteController from "react-lib/apps/IsHealth/Nurse/CardProgressNoteController";
import CardNurseNoteController from "react-lib/apps/IsHealth/Nurse/CardNurseNoteController";
import CardClassifyController from "react-lib/apps/IsHealth/Nurse/CardClassifyController";
import QueueController from "react-lib/apps/QUE/QueueController";

// Manager
import PRXManager from "react-lib/apis/manager/PRXManager";
import CoreManager from "react-lib/apis/manager/CoreManager";
import REGManager from "react-lib/apis/manager/REGManager";
import DPIManager from "react-lib/apis/manager/DPIManager";
import PTMManager from "react-lib/apis/manager/PTMManager";
import UserManager from "react-lib/apis/manager/UserManager";

import ChatBox from "react-lib/apps/IsHealth/Chat/ChatBox";
import config from "config/config";

// Nurse
import CardPatientPanel from "react-lib/apps/IsHealth/Nurse/CardPatientPanel";
import CardClassify from "react-lib/apps/IsHealth/Nurse/CardClassify";
import CardSendDoctorQueue from "react-lib/apps/IsHealth/Nurse/CardSendDoctorQueue";
import CardDiagFormHistory from "react-lib/apps/IsHealth/Nurse/CardDiagFormHistory";
import CardDiagFormHistoryAirFlow from "react-lib/apps/IsHealth/Nurse/CardDiagFormHistoryAirFlow";
import CardNurseNote from "react-lib/apps/IsHealth/Nurse/CardNurseNote";
import CardProgressNote from "react-lib/apps/IsHealth/Nurse/CardProgressNote";

// PHV
import CardVitalSign from "react-lib/apps/PHV/CardVitalSign";

// Common
import CardLayout from "react-lib/apps/common/CardLayout";
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import DateTextBox from "react-lib/apps/common/DateTextBox";
import ModInfo from "react-lib/apps/common/ModInfo";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";


import ModConfirmAppointment from "react-lib/apps/common/ModConfirm";
import PureReactTable, {
  alignCenter,
} from "react-lib/apps/common/PureReactTable";
import TimeFreeTextBox24 from "react-lib/apps/common/TimeFreeTextBox24";

// Utils
import { useInterval } from "react-lib/utils/hooksUtils";
import { formatDateToStringBe } from "react-lib/utils/dateUtils";
import { convertToBEDate } from "react-lib/utils/tsUtils";

import CardPatientQueueChatAirflow, {
  CardPatientQueueChatAirflowRef, histroyReplaceWrapper
} from "./CardPatientQueueChatAirflow";
import CardPatientQueueChat from "./CardPatientQueueChat";
import { useIntl } from "react-intl";

type QAChatTableProps = {
  apiToken: string;
  division: number;

  setState: any;
  getState: any;
  diag_rule: any;
  noReactRouter: boolean;

  // Controller
  dashboardController: DashboardController & {
    prxManager: PRXManager;
    coreManager: CoreManager;
  };
  crdVitalSignController: any;
  controller: ChatController & {
    regManager: REGManager;
  };
  patientPanelController: any;
  nurseNoteController: CardNurseNoteController & {
    prxManager: PRXManager;
    ptmManager: PTMManager;
  };
  progressNoteController: CardProgressNoteController & {
    prxManager: PRXManager;
    dpiManager: DPIManager;
  };
  classifyController: CardClassifyController & {
    coreManager: CoreManager;
    prxManager: PRXManager;
    userManager: UserManager;
  };
  queueController: QueueController & {
    prxManager: PRXManager;
  };
  diagFormController: any;

  // Data
  userId: number;
  finishedTriageLevel: string;
  fullname: string;
  username: string;
  match: any;
  allowCreateAppoint: boolean;
  nullId: number;
  isPatient: boolean;
  usePatientId: number;
  AIRFLOW_CHAT?: boolean;
  storage: any;
  classifyFromDiagRule?: boolean;
  autoHeight?: boolean;
  // Callback
  onGetDivision: () => any;
  onNavigationMessage: () => any;
  onEditAppointment: (params: { appointmentId: number | null }) => any;
  onSetShowMenuNoti: (show: boolean) => any;
};

type PatientSelectAppointmentProps = {
  apiToken: string;
  controller: QueueController & {
    prxManager: PRXManager;
  };
  division: number | string;
  diagFormId: number;
  onSuccess?: () => void;
};

type CardProfileFormProps = {
  diagRuleId: number;
  onCreateSuccess?: (id: number) => any;
  onEditSuccess?: () => any;
  patientId: number;
  triageLevel?: string;
} & Pick<
  QAChatTableProps,
  | "apiToken"
  | "division"
  | "dashboardController"
  | "classifyController"
  | "controller"
>;

type CardProgressNoteEditProps = {
  encounterId: number;
  emrId: number;
} & Pick<QAChatTableProps, "progressNoteController" | "apiToken">;

type CardNurseNoteEditProps = {
  encounterId: number;
  division: string | number;
} & Pick<
  QAChatTableProps,
  "nurseNoteController" | "apiToken" | "classifyController" | "userId"
>;

type CardVaccineNoteProps = {
  hideHeaderIcon: boolean;
  cardStyle: CSSProperties;
  patientData: any;
  encounterId: number;
  division: string | number;
  diagFormId: number;
} & Pick<QAChatTableProps, "controller" | "apiToken">;

type LabelTextProps = {
  label: string;
  description?: string;
  style?: CSSProperties;
  required?: boolean;
};

type MenuPatient =
  | "CHAT"
  | "ASSESSMENT"
  | "PROGRESS_NOTE"
  | "NURSE_NOTE"
  | "VITAL_SIGN"
  | "VACCINE_NOTE"
  | "PROFILE";

export type DropdownType =
  | undefined
  | string
  | number
  | boolean
  | (string | number | boolean)[];

type InputType = string | number | undefined;

export type ModInfoType = {
  data?: any;
  open: boolean;
  error?: any;
  title?: string;
  color?: SemanticCOLORS;
  loading?: boolean;
};

type SEVERITY_KEY = keyof typeof SEVERITY_LEVEL;

/**
 * @example "a.b", "c"
 * @example (data) => `${data.key1} ${data.key2}`
 */
// field

type FilterVaccineKey =
  | "vaccineName"
  | "needle"
  | "severityLevel"
  | "trackingStatus"
  | "duration";
type FieldType = string | ((data: any) => string | number);
type FilterVaccineType = {
  [key in FilterVaccineKey]: {
    value: string;
    field: FieldType;
    operator: "eq" | "in";
    id?: number;
  };
};

const filterVaccineInitial: FilterVaccineType = {
  vaccineName: {
    value: "",
    field: "vaccine_name",
    operator: "eq",
    id: 0,
  },
  needle: {
    value: "",
    field: (data: any) => +data.vaccinate_seq.replace(/เข็มที่ /i, ""),
    operator: "eq",
    id: 0,
  },
  severityLevel: {
    value: "",
    field: "severity",
    operator: "eq",
    id: 0,
  },
  trackingStatus: {
    value: "",
    field: "tracking_status",
    operator: "eq",
    id: 0,
  },
  duration: {
    value: "",
    field: "after_injection_operator",
    operator: "eq",
    id: 0,
  },
};

export const modInfoInitial: ModInfoType = {
  data: null,
  open: false,
  error: null,
  title: "",
  color: undefined,
  loading: false,
};

const IMAGE = {
  ISHEALTH: process.env.PUBLIC_URL + "/static/images/logo-circle.png",
  IH: process.env.PUBLIC_URL + "/static/images/IH.png",
  QR_CODE: process.env.PUBLIC_URL + "/static/images/qr-code.png",
};

const NEW_PATIENT = "new_patient";

const today = moment();
const beFormatDate = "DD/MM/YYYY";
const beFormatTime = "HH:mm";

export const NEW_PATIENT_DATA = {
  first_name: "คนไข้ใหม่",
  hn: " -",
  age: " -",
  phone_no: " -",
  email: " -",
  address: " -",
  id: NEW_PATIENT,
  chat_channel: {
    id: NEW_PATIENT,
  },
  patient_name: "คนไข้ใหม่",
  patient_age: "-",
  patient: {
    fullname: "คนไข้ใหม่",
    hn: " -",
    age: " -",
  },
};

const VACCINE_DOSE = [
  {
    key: 1,
    value: 1,
    text: "1",
  },
  {
    key: 2,
    value: 2,
    text: "2",
  },
];

const SEVERITY_LEVEL_OPTIONS = [
  {
    key: 0,
    value: 0,
    text: "ทั้งหมด",
  },
  {
    key: 1,
    value: "รุนแรง",
    text: "รุนแรง",
  },
  {
    key: 2,
    value: "ปานกลาง",
    text: "ปานกลาง",
  },
  {
    key: 3,
    value: "ไม่มีอาการข้างเคียง",
    text: "ไม่มีอาการข้างเคียง",
  },
];

export const MONITOR_STATUS_OPTIONS = [
  {
    key: 0,
    value: 0,
    text: "All",
  },
  {
    key: 1,
    value: "NONE",
    text: "ยังไม่ได้ทำแบบคัดกรอง",
  },
  {
    key: 2,
    value: "WAITING",
    text: "ยังไม่ได้ monitor",
  },
];

const SEVERITY_LEVEL = {
  NONE: {
    color: "#333333",
    text: "ไม่มีอาการข้างเคียง",
  },
  MODERATE: {
    color: "#F6B26B",
    text: "ปานกลาง",
  },
  SEVERE: {
    color: "#EB001B",
    text: "รุนแรง",
  },
};

const TRACKING_STATUS_OPTIONS = [
  {
    key: 0,
    value: 0,
    text: "ทั้งหมด",
  },
  {
    key: 1,
    value: "WAITING ",
    text: "Waiting",
  },
  {
    key: 2,
    value: "COMPLETED",
    text: "Complete",
  },
];

const DURATION_OPTIONS = [
  {
    key: 0,
    value: 0,
    text: "ทั้งหมด",
  },
  {
    key: 1,
    value: "LTE_30_DAYS",
    text: "น้อยกว่า 30 วัน",
  },
  {
    key: 2,
    value: "GT_30_DAYS",
    text: "มากกว่า 30 วัน",
  },
];

const initialProfile = {
  triageLevel: "",
  preName: "",
  firstName: "",
  lastName: "",
  user: null,
  citizenNo: null,
  passportNo: "",
  birthdate: "",
  age: "",
  gender: "",
  religion: null,
  nationality: null,
  bloodType: null,
  occupation: "",
  allergy: "",
  sideEffect: "",
  contactName: "",
  no: "",
  province: null,
  district: null as string | null,
  city: null as string | null,
  zipcode: "",
  telMobile: "",
  email: "",
  hn: "",
  emergencyPerson: "",
  emergencyNumber: "",
  emergencyRelation: "",
};

const LabelText: React.FC<LabelTextProps> = (props) => {
  return (
    <>
      {!props.description ? (
        <div style={{ ...props.style }}>
          <Form style={{ width: "fit-content" }}>
            <label style={{ fontWeight: "bold" }}>
              {props.required && (
                <div
                  style={{ position: "absolute", right: "-8px", color: "red" }}
                >
                  *
                </div>
              )}
              {props.label}
            </label>
          </Form>
        </div>
      ) : (
        <div style={{ display: "grid", color: "#324350", ...props.style }}>
          <Form style={{ width: "fit-content" }}>
            <label style={{ fontWeight: "bold" }}>
              {props.required && (
                <div
                  style={{ position: "absolute", right: "-8px", color: "red" }}
                >
                  *
                </div>
              )}
              {props.label}
            </label>
          </Form>
          <div style={{ color: "#4F4F4F" }}>{props.description}</div>
        </div>
      )}
    </>
  );
};

const PatientSelectAppointment = (props: PatientSelectAppointmentProps) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(true);
  const [employeeChoices, setEmployeeChoices] = useState([]);
  const [dateChoices, setDateChoices] = useState([]);
  const [timeRangeChoices, setTimeRangeChoices] = useState([]);
  const [employee, setEmployee] = useState<InputType>(undefined);
  const [date, setDate] = useState<string | undefined>(undefined);
  const [time, setTime] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState<string | null>(null);
  const [error, setError] = useState<string | null | any[] | {}>(null);
  const isMounted = useRef(true);
  const [openModConfirmAppointment, setOpenModConfirmAppointment] =
    useState<boolean>(false);

  useEffect(() => {
    clearErrorSuccess();
    handleGetChoices();
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    clearErrorSuccess();
    if (employee && date && time) {
      return;
    } else {
      handleGetChoices();
    }
  }, [employee, date, time, props.division]);

  const handleGetChoices = async () => {
    if (!props.division) {
      return;
    }
    setIsLoading(true);
    const [res, err] = await props.controller.getAvailableDivisionServiceBlock({
      employee,
      date,
      timeRange: time,
      apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
      division: props.division, //! Beware, each appointment might be different division
      providerTypeCategory: "covid_vaccine",
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (res) {
        setEmployeeChoices(res.employees);
        setDateChoices(res.dates);
        setTimeRangeChoices(res.time_ranges);
      }
    }
  };

  const handleEmployeeChange = async (
    e: SyntheticEvent,
    { value }: DropdownProps
  ) => {
    if (typeof value === "number" || typeof value === "string") {
      setEmployee(value);
    }
  };

  const handleDateChange = async (
    e: SyntheticEvent,
    { value }: DropdownProps
  ) => {
    setDate(value as string);
  };

  const handleTimeChange = async (
    e: SyntheticEvent,
    { value }: DropdownProps
  ) => {
    setTime(value as string);
  };

  const handleConfirmAppointment = async () => {
    clearErrorSuccess();
    setIsLoading(true);
    setOpenModConfirmAppointment(false);
    const [res, err, network] =
      await props.controller.postSelectDivisionServiceBlock({
        apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
        division: props.division,
        timeRange: time,
        diagFormId: props.diagFormId,
        providerTypeCategory: "covid_vaccine",
      } as any);
    if (isMounted.current) {
      setIsLoading(false);
      if (res) {
        setSuccess("สร้างนัดหมายสำเร็จ");
        setTimeout(() => {
          props.onSuccess?.();
        }, 1000);
      } else if (err) {
        if (network.response.status === 500) {
          setError(" Error 500: เกิดข้อผิดพลาด");
        } else {
          setError(err);
        }
      }
    }
  };

  const clearErrorSuccess = () => {
    setError(null);
    setSuccess(null);
  };

  const handleOpenModConfirmAppointment = () => {
    setOpenModConfirmAppointment(true);
  };

  const handleCloseModConfirmAppointment = () => {
    setOpenModConfirmAppointment(false);
  };


  console.log("PatientSelectAppointment props: ", props)
  return (
    <div className="patient-select-appointment">
      <ModConfirmAppointment
        open={openModConfirmAppointment}
        titleName={intl.formatMessage({ id: "ยืนยันการสร้างนัดหมาย" })}
        alertText={intl.formatMessage({ id: "คุณต้องการสร้างนัดหมายใช่หรือไม่" })}
        buttonLeft={intl.formatMessage({ id: "ยกเลิก" })}
        buttonRight={intl.formatMessage({ id: "ยืนยัน" })}
        onButtonLeftClick={handleCloseModConfirmAppointment}
        onButtonRightClick={handleConfirmAppointment}
      />
      <h2>{intl.formatMessage({ id: "เลือกนัดหมาย" })}</h2>
      <Divider />
      <Form>
        <ErrorMessage error={error} />
        <ErrorMessage success={success} />
        <Form.Group inline>
          <Form.Field width={4}>
            ผู้ให้คำปรึกษา <span style={{ color: "red" }}>*</span>
          </Form.Field>
          <Form.Dropdown
            width={14}
            search
            selection
            clearable
            style={{ width: "100%" }}
            onChange={handleEmployeeChange}
            loading={isLoading}
            options={employeeChoices}
            value={employee}
          />
        </Form.Group>
        <Form.Group inline>
          <Form.Field width={4}>
            วันที่ <span style={{ color: "red" }}>*</span>
          </Form.Field>
          <Form.Dropdown
            width={14}
            search
            selection
            clearable
            style={{ width: "100%" }}
            onChange={handleDateChange}
            loading={isLoading}
            options={dateChoices}
            value={date}
          />
        </Form.Group>
        <Form.Group inline>
          <Form.Field width={4}>
            เวลา <span style={{ color: "red" }}>*</span>
          </Form.Field>
          <Form.Dropdown
            width={14}
            search
            selection
            clearable
            style={{ width: "100%" }}
            onChange={handleTimeChange}
            loading={isLoading}
            options={timeRangeChoices}
            value={time}
          />
        </Form.Group>
      </Form>
      <div className="button-container">
        <Button
          primary
          content={intl.formatMessage({ id: "ยืนยัน" })}          fluid
          onClick={handleOpenModConfirmAppointment}
          loading={isLoading}
          disabled={isLoading || !time || !employee || !date}
        />
      </div>
      {}
    </div>
  );
};

const CardProgressNoteEdit = (props: CardProgressNoteEditProps) => {
  const intl = useIntl();
  // Loading
  const [isLoading, setIsLoading] = useState(false);

  // Open
  const [openModClearInput, setOpenModClearInput] = useState<boolean>(false);
  const [openModInfo, setOpenModInfo] = useState<ModInfoType>(modInfoInitial);

  // Input
  const [subjective, setSubjective] = useState<InputType>("");
  const [objecttive, setObjecttive] = useState<InputType>("");
  const [assessment, setAssessment] = useState<InputType>("");
  const [plan, setPlan] = useState<InputType>("");

  // Select
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const [selectedRow, setSelectedRow] = useState<any>(null);

  const progressNoteRef = useRef<any>();

  const handleClearInput = () => {
    setSubjective("");
    setObjecttive("");
    setAssessment("");
    setPlan("");
    setOpenModClearInput(false);
  };

  const handleSaveProgressNote = async () => {
    setIsLoading(true);
    let [res, error] = [null, null];

    const data = {
      emr: props.emrId,
      s_txt: subjective,
      o_txt: objecttive,
      a_txt: assessment,
      p_txt: plan,
    };

    if (selectedRow) {
      [res, error] = await props.progressNoteController.putProgressNoteById({
        apiToken: props.apiToken,
        progressNoteId: selectedRow.id,
        data: {
          ...selectedRow,
          ...data,
        },
      });
    } else {
      [res, error] = await props.progressNoteController.createProgressionNote({
        apiToken: props.apiToken,
        data,
      });
    }

    setIsLoading(false);

    if (error) {
      setOpenModInfo({
        open: true,
        error,
      });
      return;
    }

    setOpenModInfo({
      open: true,
      title: "บันทึกสำเร็จ",
      color: "green",
    });

    setSelectedRow(null);
    setSelectedRowIndex(null);
    handleClearInput();

    if (progressNoteRef.current) {
      progressNoteRef.current.getNote();
    }
  };

  const handleCloseModInfo = () => {
    setOpenModInfo(modInfoInitial);
  };

  const handleSelectedCell = (row: { index?: any; original?: any }) => {
    const { original = null } = row;
    setSubjective(original?.s_txt || "");
    setObjecttive(original?.o_txt || "");
    setAssessment(original?.a_txt || "");
    setPlan(original?.p_txt || "");

    if (original?.id === "mockId") {
      setSelectedRow(null);
      setSelectedRowIndex(null);
    } else {
      setSelectedRow(original);
      setSelectedRowIndex(row.index);
    }
  };

  return (
    <>
      <CardProgressNote
        ref={progressNoteRef}
        // hideCallback={closeModalProgressNote}
        controller={props.progressNoteController}
        apiToken={props.apiToken}
        encounterId={props.encounterId}
        emrId={props.emrId}
        readOnly={true}
        hideHeaderIcon={true}
        cardStyle={{ boxShadow: "none", border: 0 }}
        tablePageSize={6}
        dividerStyle={{ marginTop: "5px", marginBottom: "-5px" }}
        tableStyle={{ height: "240px" }}
        onSelectedCell={handleSelectedCell}
        selectedRowIndex={selectedRowIndex}
        languageUX={props.languageUX}
      />
      <Dimmer active={isLoading} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      <Form
        className="form-label-primary"
        style={{ marginTop: "5px", paddingLeft: "15px" }}
      >
        <Form.Group>
          <Form.Field width={16} style={{ paddingRight: "1.5rem" }}>
            <label>S: Subjective</label>
            <TextArea
              rows={1}
              value={subjective}
              onChange={(e, { value }) => setSubjective(value)}
            />
          </Form.Field>
        </Form.Group>

        <Form.Group>
          <Form.Field width={16} style={{ paddingRight: "1.5rem" }}>
            <label>O: Objective</label>
            <TextArea
              rows={1}
              value={objecttive}
              onChange={(e, { value }) => setObjecttive(value)}
            />
          </Form.Field>
        </Form.Group>

        <Form.Group>
          <Form.Field width={16} style={{ paddingRight: "1.5rem" }}>
            <label>A: Assessment</label>
            <TextArea
              rows={1}
              value={assessment}
              onChange={(e, { value }) => setAssessment(value)}
            />
          </Form.Field>
        </Form.Group>

        <Form.Group>
          <Form.Field width={16} style={{ paddingRight: "1.5rem" }}>
            <label>P: Plan</label>
            <TextArea
              rows={1}
              value={plan}
              onChange={(e, { value }) => setPlan(value)}
            />
          </Form.Field>
        </Form.Group>
      </Form>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "20px",
          marginBottom: "35px",
        }}
      >
        <Button
          className="primary back"
          onClick={() => setOpenModClearInput(true)}
        >
          ล้างข้อมูล
        </Button>
        <Button
          className="primary"
          style={{ marginLeft: "10px" }}
          onClick={handleSaveProgressNote}
        >
          {selectedRow ? intl.formatMessage({id: "บันทึกการแก้ไข"}) : intl.formatMessage({id: "บันทึก"})}
        </Button>
      </div>

      <ModInfo
        open={openModInfo.open}
        titleColor={openModInfo.color || "red"}
        titleName={openModInfo.title}
        onApprove={handleCloseModInfo}
        onClose={handleCloseModInfo}
      >
        {openModInfo.error === "string" ? (
          <div>{openModInfo.error}</div>
        ) : (
          <ErrorMessage error={openModInfo.error} />
        )}
      </ModInfo>

      <ModConfirm
        openModal={openModClearInput}
        onDeny={() => setOpenModClearInput(false)}
        onApprove={handleClearInput}
        onCloseWithDimmerClick={() => setOpenModClearInput(false)}
        content={
          <div style={{ textAlign: "center" }}>
            ต้องการล้างข้อมูลทั้งหมดหรือไม่
          </div>
        }
      ></ModConfirm>
    </>
  );
};

const CardNurseNoteEdit = (props: CardNurseNoteEditProps) => {
  const intl = useIntl();
  // Open
  const [openModClearInput, setOpenModClearInput] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModInfo, setOpenModInfo] = useState<ModInfoType>(modInfoInitial);

  // Loading
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Input
  const [goal, setGoal] = useState<InputType>("");
  const [plan, setPlan] = useState<InputType>("");
  const [diagnosis, setDiagnosis] = useState<InputType>("");

  // Select
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);

  const nurseNoteRef = useRef<any>();

  const handleClearInput = () => {
    setGoal("");
    setPlan("");
    setDiagnosis("");
    setOpenModClearInput(false);
    // setSelectedRow(null)
  };

  const handleSaveNurseNote = async () => {
    let payload: any = {};

    if (selectedRow) {
      payload = {
        date: formatDateToStringBe(today),
        time: today.format(beFormatTime),
        saveData: {
          ...selectedRow,
          plan,
          goal,
          diagnosis,
        },
        nurseNoteId: selectedRow.nurse_note,
        encounter: props.encounterId,
        nurseNoteItemId: selectedRow.id,
        apiToken: props.apiToken,
        division: props.division,
      };
    } else {
      payload = {
        date: formatDateToStringBe(today),
        time: today.format(beFormatTime),
        plan,
        goal,
        diagnosis,
        encounter: props.encounterId,
        division: props.division,
        apiToken: props.apiToken,
        nurseNoteId: null,
      };
    }

    setIsLoading(true);

    const [userToken, errorUserToken] =
      await props.classifyController.userManager.getUserTokenize({
        code: ``,
        apiToken: props.apiToken,
      });

    if (errorUserToken) {
      setIsLoading(false);
      setOpenModInfo({ open: true, error: errorUserToken });
      return;
    }

    let [res, error] = [null, null];
    if (selectedRow) {
      console.log({
        ...payload,
        userToken: userToken.token,
      });
      [res, error] = await props.nurseNoteController.putNurseNoteItem({
        ...payload,
        userToken: userToken.token,
      } as any);
    } else {
      [res, error] = await props.nurseNoteController.postNurseNoteItem({
        ...payload,
        nurseNoteId: payload.nurseNoteId,
        userToken: userToken.token,
      } as any);
    }

    setIsLoading(false);

    if (error) {
      setOpenModInfo({ open: true, error: error });
      return;
    }

    setOpenModInfo({
      open: true,
      title: "บันทึกสำเร็จ",
      color: "green",
    });

    if (nurseNoteRef.current) {
      nurseNoteRef.current.getNoteItem();
    }

    setSelectedRow(null);
    setSelectedRowIndex(null);
    handleClearInput();
  };

  const handleCloseModInfo = () => {
    setOpenModInfo(modInfoInitial);
  };

  const handleSelectedCell = (row: { index?: any; original?: any }) => {
    const { original = null } = row;
    setGoal(original?.goal || "");
    setPlan(original?.plan || "");
    setDiagnosis(original?.diagnosis || "");
    if (original?.id === "mockId") {
      setSelectedRow(null);
      setSelectedRowIndex(null);
    } else {
      setSelectedRow(original);
      setSelectedRowIndex(row.index);
    }
  };

  return (
    <>
      <Dimmer active={isLoading} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>

      <CardNurseNote
        ref={nurseNoteRef}
        // hideCallback={closeModalNurseNote}
        controller={props.nurseNoteController}
        apiToken={props.apiToken}
        hideHeaderIcon={true}
        encounterId={props.encounterId}
        division={props.division}
        readOnly={true}
        cardStyle={{ boxShadow: "none", border: 0 }}
        tablePageSize={6}
        dividerStyle={{ marginTop: "10px", marginBottom: "0px" }}
        tableStyle={{ height: "240px" }}
        isGetNurseNoteByEncounter={true}
        onSelectedCell={handleSelectedCell}
        selectedRowIndex={selectedRowIndex}
        languageUX={props.languageUX}
      />
      <Form
        className="form-label-primary"
        style={{ marginTop: "5px", paddingLeft: "15px" }}
      >
        <Form.Group>
          <Form.Field width={16} style={{ paddingRight: "1.5rem" }}>
            <label>{intl.formatMessage({ id: "ข้อมูล/อาการ/ปัญหา" })}</label>
            <TextArea
              rows={2}
              value={diagnosis}
              onChange={(e, { value }) => setDiagnosis(value)}
            />
          </Form.Field>
        </Form.Group>

        <Form.Group>
          <Form.Field width={16} style={{ paddingRight: "1.5rem" }}>
            <label>{intl.formatMessage({ id: "การพยาบาล / ให้คำปรึกษา" })}</label>
            <TextArea
              rows={2}
              value={plan}
              onChange={(e, { value }) => setPlan(value)}
            />
          </Form.Field>
        </Form.Group>

        <Form.Group>
          <Form.Field width={16} style={{ paddingRight: "1.5rem" }}>
            <label>{intl.formatMessage({ id: "ผลการพยาบาล" })}</label>
            <TextArea
              rows={2}
              value={goal}
              onChange={(e, { value }) => setGoal(value)}
            />
          </Form.Field>
        </Form.Group>
      </Form>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "25px",
          marginBottom: "35px",
        }}
      >
        <Button
          className="primary back"
          onClick={() => setOpenModClearInput(true)}
        >
          ล้างข้อมูล
        </Button>
        <Button
          className="primary"
          style={{ marginLeft: "10px" }}
          onClick={handleSaveNurseNote}
          disabled={!diagnosis || !goal || !plan}
        >
          {selectedRow ? intl.formatMessage({id: "บันทึกการแก้ไข"}) : intl.formatMessage({id: "บันทึก"})}
        </Button>
      </div>

      <ModConfirm
        openModal={openModClearInput}
        onDeny={() => setOpenModClearInput(false)}
        onApprove={handleClearInput}
        onCloseWithDimmerClick={() => setOpenModClearInput(false)}
        content={
          <div style={{ textAlign: "center" }}>
            ต้องการล้างข้อมูลทั้งหมดหรือไม่
          </div>
        }
      />

      <ModInfo
        open={openModInfo.open}
        titleColor={openModInfo.color || "red"}
        titleName={openModInfo.title}
        onApprove={handleCloseModInfo}
        onClose={handleCloseModInfo}
      >
        {openModInfo.error === "string" ? (
          <div>{openModInfo.error}</div>
        ) : (
          <ErrorMessage error={openModInfo.error} />
        )}
      </ModInfo>
    </>
  );
};

const CardVaccineNote = (props: CardVaccineNoteProps) => {
  const intl = useIntl();
  // Loading
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [isLoadingCardVaccine, setIsLoadingCardVaccine] = useState(false);

  // Open
  const [openModClearInput, setOpenModClearInput] = useState<boolean>(false);
  const [openModConfirm, setOpenModConfirm] = useState(modInfoInitial);
  const [openModInfo, setOpenModInfo] = useState<ModInfoType>(modInfoInitial);
  const [openModConfirmSave, setOpenModConfirmSave] =
    useState<ModInfoType>(modInfoInitial);

  // Options
  const [needleOptions, setNeedleOptions] = useState<any[]>([]);
  const [lotNumberOptions, setLotNumberOptions] = useState<any[]>([]);
  const [vaccineNameOptions, setVaccineNameOptions] = useState<any[]>([]);

  // Input
  const [vaccineName, setVaccineName] = useState<DropdownType>("");
  const [needle, setNeedle] = useState<DropdownType>("");
  const [lotNumber, setLotNumber] = useState<DropdownType>("");
  const [vaccinatorName, setVaccinatorName] = useState<InputType>("");
  const [otherSymptoms, setOtherSymptoms] = useState<InputType>("");
  const [sideEffect, setSideEffect] = useState<"YES" | "NO" | "">("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");

  const [severeSymptoms, setSevereSymptoms] = useState<any>({});
  const [moderateSymptoms, setModerateSymptoms] = useState<any>({});

  const [tableList, setTableList] = useState<any[]>([]);
  const [cloneTableList, setCloneTableList] = useState<any[]>([]);
  // const [tableFilter, setTableFilter] = useState(initialTableFilter)

  const [filterVaccine, setFilterVaccine] =
    useState<FilterVaccineType>(filterVaccineInitial);

  const getTableData = async (params = {}) => {
    setIsLoadingTable(true);
    const [res, error] = await props.controller.prxManager.getListVaccineNote({
      apiToken: props.apiToken,
      ...params,
      divisionId: props.division,
    } as any);

    let vaccineNoteList = res?.items || [];
    vaccineNoteList = vaccineNoteList.filter(
      (item: any) => item.patient === props.patientData?.id
    );

    vaccineNoteList = await Promise.all(
      vaccineNoteList.map(async (list: any) => {
        const [diagFornData, diagFormError] =
          await props.controller.prxManager.getDiagFormDetail({
            apiToken: props.apiToken,
            diagFormId: list.diag_form,
          });
        const duration = list.duration_after_injected;
        const afterInjection = duration.replace(/\d+/i, "").trim();
        const number = duration.match(/\d+/i, "")?.[0];
        let operator = "";
        if (duration === "ไม่สามารถค้นหาเวลาหลังฉีดได้") {
          operator = "";
        } else if (
          afterInjection === "เดือน" ||
          afterInjection === "ปี" ||
          (afterInjection === "วัน" && number > 30)
        ) {
          operator = "GT_30_DAYS";
        } else {
          operator = "LTE_30_DAYS";
        }
        return {
          ...list,
          tracking_status: diagFornData?.tracking_status || "",
          after_injection_operator: operator,
        };
      })
    );

    setTableList(vaccineNoteList);
    setCloneTableList(vaccineNoteList);
    setIsLoadingTable(false);
  };

  useEffect(() => {
    const getVaccine = async () => {
      setIsLoadingCardVaccine(true);
      const [res, error] = await props.controller.prxManager.getListVaccine({
        apiToken: props.apiToken,
      });
      setIsLoadingCardVaccine(false);
      const vaccineList: any[] = res?.items || [];

      setVaccineNameOptions(createOptions(vaccineList));
      setNeedleOptions(VACCINE_DOSE);

      getTableData();
    };
    // setTableFilter(initialTableFilter)
    getVaccine();
  }, []);

  useEffect(() => {
    const onFilterVaccineList = () => {
      const option: FilterVaccineType = { ...filterVaccine };

      const filter = cloneTableList.filter((list: any) => {
        let isCompare = true;
        const objKeys = Object.keys(option);

        objKeys.map((key) => {
          const { value, field, operator } =
            option[key as keyof FilterVaccineType];
          const data =
            typeof field === "function" ? field(list) : objGet(list, field);

          if (value) {
            if (data === undefined) {
              objKeys.splice(0);
            }

            const where = {
              eq: data === value ? (isCompare = true) : (isCompare = false),
              in: data
                .toString()
                .toUpperCase()
                .includes(value.toString().toUpperCase())
                ? (isCompare = true)
                : (isCompare = false),
            }[operator];
          }

          if (!isCompare) {
            objKeys.splice(0);
          }
          return [];
        });
        return isCompare;
      });

      setTableList(filter);
    };

    onFilterVaccineList();
  }, [filterVaccine, cloneTableList]);

  const columns = useMemo(
    () => [
      {
        Header: "วันที่ / เวลา ประเมิน",
        accessor: " ",
        width: 150,
        Cell: ({ original }: any) =>
          alignCenter(moment(original?.date).format("DD/MM/YYYY HH:mm:ss")),
      },
      {
        Header: "ชื่อ Vaccine",
        accessor: " ",
        width: 110,
        Cell: ({ original }: any) => {
          return alignCenter(original.vaccine_name);
        },
      },
      {
        Header: "เข็มที่",
        accessor: " ",
        width: 50,
        Cell: ({ original }: any) => alignCenter(original.vaccinate_seq),
      },
      {
        Header: "อาการข้างเคียงที่พบ",
        accessor: "side_effect",
        minWidth: 100,
        Cell: ({ original }: any) => {
          // const moderateData = Object.entries(JSON.parse(original.moderate_side_effects))
          // const concatModerate = moderateData?.map(([key, value]: any) => value.value ? value.text : null).filter(Boolean).join(", ")

          // const severeData = Object.entries(JSON.parse(original.severe_side_effects))
          // const concatSevere = severeData?.map(([key, value]: any) => value.value ? value.text : null).filter(Boolean).join(", ")

          return alignCenter(original.side_effects);
        },
      },
      {
        Header: "ความรุนแรง",
        accessor: " ",
        width: 120,
        Cell: ({ original }: { original: { severity: any } }) => {
          let color = "#000000";

          if (original.severity !== "ไม่สามารถค้นหาความรุนแรงได้") {
            const severityList = Object.keys(SEVERITY_LEVEL) as SEVERITY_KEY[];
            const findSeverity = severityList.find((key) =>
              original.severity.includes(SEVERITY_LEVEL[key].text)
            );

            if (findSeverity) {
              color = SEVERITY_LEVEL[findSeverity].color;
            }
          }

          return (
            <div style={{ textAlign: "center", color }}>
              {original.severity}
            </div>
          );
        },
        //  alignCenter(original?.severity)
      },
      {
        Header: "หลังฉีด",
        accessor: "after_injection",
        width: 70,
        Cell: ({ original }: any) => {
          // let display = ""
          // if (!original.vaccinated_date) {
          //   display = "30 นาที"
          // }
          // else {
          //   const start = moment(`${original.vaccinated_date} ${original.vaccinated_time}`);
          //   const end = moment(original.created);
          //   const diff = end.diff(start, 'minutes');

          //   const numdays = Math.floor(diff / 1440);
          //   const numhours = Math.floor((diff % 1440) / 60);
          //   const numminutes = Math.floor((diff % 1440) % 60);
          //   display = numdays > 0 ? `${numdays} วัน` : numhours > 0 ? `${numhours} ชม.` : `${numminutes} นาที`
          // }
          return (
            <div style={{ textAlign: "center" }}>
              {original.duration_after_injected}
            </div>
          );
          // return alignCenter(original?.after_injection)
        },
        // alignCenter(original?.after_injection)
      },
      {
        Header: "สถานะติดตาม",
        accessor: " ",
        width: 100,
        Cell: ({ original }: any) => (
          <div style={{ textAlign: "center" }}>
            {original?.tracking_status === "WAITING" ? (
              <Button
                style={{ backgroundColor: "#FDEC51" }}
                size={"mini"}
                onClick={() =>
                  setOpenModConfirm({
                    open: true,
                    data: original,
                  })
                }
              >
                Waiting
              </Button>
            ) : original?.tracking_status === "COMPLETE" ? (
              "Complete"
            ) : (
              ""
            )}
          </div>
        ),
      },
    ],
    [vaccineNameOptions]
  );

  //

  // Utils
  const createOptions = (data: any[], primaryKey = "id") => {
    return data.map((item: any) => ({
      key: item[primaryKey],
      value: item[primaryKey],
      text: item.name || item.name_en || item.lot_no,
    }));
  };

  const objGet = (obj: any, keyString: string) => {
    let keys = keyString?.split(".");
    return keys?.reduce(
      (result: { [x: string]: any }, key: string | number) => result?.[key],
      obj
    );
  };

  // Handle
  const handleEndVaccination = async () => {
    setOpenModConfirm({ ...openModConfirm, loading: true });

    const [res, error] = await props.controller.prxManager.patchDiagForm({
      apiToken: props.apiToken,
      diagFormId: openModConfirm.data.diag_form,
      // data: {
      //   ...openModConfirm.data,
      trackingStatus: "COMPLETED",
      // }
    });

    if (error) {
      setOpenModConfirm({ ...openModConfirm, error, loading: false });
      return;
    }

    setOpenModConfirm({ open: false, error: null, loading: false });

    setOpenModInfo({
      open: true,
      error: null,
      title: "บันทึกสำเร็จ",
      color: "green",
    });
    getTableData();
    // setFilterVaccine(filterVaccineInitial)
    handleCloseModConfirm();
  };

  const handleClearInput = () => {
    setVaccineName("");
    setNeedle("");
    setLotNumber("");
    setVaccinatorName("");
    setOtherSymptoms("");
    setModerateSymptoms({});
    setSevereSymptoms({});
    setSideEffect("");
    setOpenModClearInput(false);
    setDate("");
    setTime("");
  };

  const handleCloseModConfirm = () => {
    setOpenModConfirm(modInfoInitial);
  };

  const handleCloseModInfo = () => {
    setOpenModInfo(modInfoInitial);
  };

  const handleSaveVaccine = async () => {
    // save vaccine
    setOpenModConfirmSave({ ...openModConfirmSave, loading: true });

    let severity: SEVERITY_KEY = "NONE";
    if (sideEffect === "YES") {
      // console.log(Object.keys(severeSymptoms)[0])
      if (
        Object.keys(moderateSymptoms)?.some(
          (key) => moderateSymptoms[key]?.value
        )
      ) {
        severity = "MODERATE";
      }
      if (
        Object.keys(severeSymptoms)?.some((key) => severeSymptoms[key]?.value)
      ) {
        severity = "SEVERE";
      }
    }

    let vaccinatedDate = date;
    if (vaccinatedDate) {
      const splitDate = vaccinatedDate.split("/");
      vaccinatedDate = `${+splitDate[2] - 543}-${splitDate[1]}-${splitDate[0]}`;
    }

    const data = {
      encounter: props.encounterId,
      vaccine_lot: lotNumber,
      duration_after_injected: "MINUTES_30", //"MINUTES_30 | DAYS_1 | DAYS_7"
      severity,
      have_side_effects: sideEffect === "YES" ? true : false,
      moderate_side_effects: JSON.stringify(moderateSymptoms),
      severe_side_effects: JSON.stringify(severeSymptoms),
      other_side_effects: otherSymptoms,
      tracking_status: "WAITING", // "WAITING | COMPLETED",
      vaccinate_seq: needle,
      vaccinated_by: setVaccinatorName,
      vaccinated_date: vaccinatedDate,
      vaccinated_time: time,
    };

    const [res, error] = await props.controller.prxManager.postVaccineNote({
      apiToken: props.apiToken,
      data,
    });

    if (error) {
      setOpenModConfirmSave({ open: true, error, loading: false });
      return;
    }

    setOpenModConfirmSave({ open: false, error: null, loading: false });

    setOpenModInfo({
      open: true,
      error: null,
      color: "green",
      title: "บันทึกสำเร็จ",
    });
    handleClearInput();

    await getTableData();
  };

  // Handle Change
  const handleChangeVaccineName = async (value: any) => {
    setVaccineName(value);

    const [res, error] = await props.controller.prxManager.getListVaccineLot({
      apiToken: props.apiToken,
      vaccineId: value,
    });
    const filterVaccineLot = res?.items || [];

    setLotNumberOptions(createOptions(filterVaccineLot));
    setLotNumber("");
  };

  const handleChangeCheckbox = (
    type: "moderate" | "severe",
    data: CheckboxProps
  ) => {
    if (data.name === undefined) {
      return;
    }

    const setObj = {
      [data.name]: {
        value: data.checked,
        text: data.label,
      },
    };

    if (type === "moderate") {
      setModerateSymptoms({
        ...moderateSymptoms,
        ...setObj,
      });
    } else if (type === "severe") {
      setSevereSymptoms({
        ...severeSymptoms,
        ...setObj,
      });
    }
  };

  const handleChangeFilter = (key: FilterVaccineKey, value: DropdownType) => {
    let id = value;
    if (key === "vaccineName") {
      value =
        vaccineNameOptions.find((option: any) => option.value === value)
          ?.text || "";
    }
    if (!value) {
      value = "";
    }
    setFilterVaccine({
      ...filterVaccine,
      [key]: {
        ...filterVaccine[key],
        value,
        id,
      },
    });
  };

  // const getTableDataByParams = (filter: { vaccineName: any; needle: any; severityLevel: any; trackingStatus: any; duration: any; }) => {
  //   const params = {
  //     encounterId: props.encounterId,
  //     patientId: props.patientData?.id,
  //     divisionId: props.division,
  //     vaccineId: filter.vaccineName,
  //     vaccineSeq: filter.needle,
  //     severity: filter.severityLevel,
  //     trackingStatus: filter.trackingStatus,
  //     durationVaccinated: filter.duration
  //   }
  //   // getTableData(params)
  // }

  return (
    <>
      <CardLayout
        titleText="Vaccine's Note"
        headerColor="gray"
        closeable={true}
        toggleable={false}
        hideHeaderIcon={props.hideHeaderIcon}
        // loading={loading}
        // onClose={props.hideCallback}
        style={props.cardStyle}
      >
        {/* <Common.ErrorMessage error={stateError} /> */}

        <Dimmer active={isLoadingCardVaccine} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
        <Form className="form-label-primary">
          <Form.Group inline>
            <Form.Field inline width={8}>
              <label
                className="font-primary"
                style={{ width: "105px", fontWeight: "bold" }}
              >
                ชื่อ Vaccine
              </label>
              <Dropdown
                selection
                options={vaccineNameOptions}
                value={vaccineName}
                search
                fluid
                style={{ width: "100%" }}
                onChange={(e, { value }) => handleChangeVaccineName(value)}
              />
            </Form.Field>
            <Form.Field inline width={8}>
              <label style={{ width: "175px", fontWeight: "bold" }}>
                Lot ของ Vaccine
              </label>
              <Dropdown
                selection
                options={lotNumberOptions}
                value={lotNumber}
                search
                fluid
                style={{ width: "100%" }}
                onChange={(e, { value }) => setLotNumber(value)}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group inline>
            <Form.Field inline width={8}>
              <label style={{ width: "105px", fontWeight: "bold" }}>
                เข็มที่
              </label>
              <Dropdown
                selection
                options={needleOptions}
                value={needle}
                search
                fluid
                style={{ width: "100%" }}
                onChange={(e, { value }) => setNeedle(value)}
              />
            </Form.Field>

            <Form.Field inline width={8}>
              <label style={{ width: "165px", fontWeight: "bold" }}>
                ชื่อผู้ฉีดวัคซีน
              </label>
              <Input
                value={vaccinatorName}
                onChange={(e, { value }) => setVaccinatorName(value)}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group inline>
            <Form.Field inline width={8}>
              <label style={{ width: "105px", fontWeight: "bold" }}>
                วันที่ฉีด
              </label>
              <DateTextBox
                value={date}
                onChange={(date: string) => {
                  if (!/[\d]{2}\/[\d]{2}\/[\d]{4}/i.test(date)) {
                    return;
                  }
                  setDate(date);
                }}
              />
            </Form.Field>

            <Form.Field inline width={8}>
              <label style={{ width: "98.5px", fontWeight: "bold" }}>
                เวลาฉีด
              </label>
              <TimeFreeTextBox24
                style={{
                  display: "flex",
                  alignItems: "left",
                  margin: "0 15px",
                }}
                className="time-custom-react-picker"
                value={time}
                onChange={(time: any) => setTime(time)}
                autoFocus={false}
                clearIcon={null}
                checkTimeOverflow
              />
            </Form.Field>
          </Form.Group>
        </Form>

        <div style={{ display: "flex", alignItems: "baseline" }}>
          <label style={{ color: "#267CAD", fontWeight: "bold" }}>
            อาการข้างเคียงหลังรับวัคซีน 30 นาที
          </label>
          <Radio
            name="radioGroup"
            label={intl.formatMessage({ id: "ไม่มี" })}
            style={{ margin: "0 12px" }}
            value={"NO"}
            checked={"NO" === sideEffect}
            onChange={() => setSideEffect("NO")}
          />
          <Radio
            name="radioGroup"
            label={intl.formatMessage({ id: "มี" })}
            value={"YES"}
            checked={"YES" === sideEffect}
            onChange={() => setSideEffect("YES")}
          />
          <div
            style={{ color: "#267CAD", marginLeft: "5px", fontSize: "13px" }}
          >
            (โปรดระบุอาการที่พบ)
          </div>
        </div>

        {/*form อาการที่พบ ปานกลาง */}
        <Form>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "16.5% 23.5% 24% 36%",
              backgroundColor: "rgba(246,178,107,0.2)",
              padding: "12px 5px",
              borderRadius: "5px",
              rowGap: "15px",
              marginTop: "5px",
            }}
          >
            <div>
              <Checkbox
                label={intl.formatMessage({ id: "ปวดศีรษะ" })}
                checked={moderateSymptoms?.headache?.value || false}
                name="headache"
                onChange={(e, data) => handleChangeCheckbox("moderate", data)}
              />
            </div>
            <div>
              <Checkbox
                label="คลื่นไส้, อาเจียน <= 5 ครั้ง"
                checked={moderateSymptoms?.vomit?.value || false}
                name="vomit"
                onChange={(e, data) => handleChangeCheckbox("moderate", data)}
              />
            </div>
            <div>
              <Checkbox
                label={intl.formatMessage({ id: "อ่อนเพลีย ไม่มีแรง" })}
                checked={moderateSymptoms?.exhaustion?.value || false}
                name="exhaustion"
                onChange={(e, data) => handleChangeCheckbox("moderate", data)}
              />
            </div>
            <div>
              <Checkbox
                label={intl.formatMessage({ id: "ปวด, บวม, แดง, ร้อน บริเวณที่ฉีด" })}
                checked={moderateSymptoms?.swellingPain?.value || false}
                name="swellingPain"
                onChange={(e, data) => handleChangeCheckbox("moderate", data)}
              />
            </div>
            <div>
              <Checkbox
                label={intl.formatMessage({ id: "ท้องเสีย" })}
                checked={moderateSymptoms?.diarrhea?.value || false}
                name="diarrhea"
                onChange={(e, data) => handleChangeCheckbox("moderate", data)}
              />
            </div>
            <div>
              <Checkbox
                label={intl.formatMessage({ id: "พบผื่นเล็กน้อย" })}
                checked={moderateSymptoms?.rash?.value || false}
                name="rash"
                onChange={(e, data) => handleChangeCheckbox("moderate", data)}
              />
            </div>
            <div
              style={{
                position: "absolute",
                color: "#F6B26B",
                fontWeight: "bold",
                bottom: "15px",
                right: "11px",
                fontSize: "15px",
              }}
            >
              กลุ่มอาการปานกลาง
            </div>
          </div>
        </Form>

        {/*form อาการที่พบ รุนแรง */}
        <Form>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "16.5% 23.5% 24% 25% 11%",
              backgroundColor: "rgba(255,77,77,0.2)",
              padding: "12px 5px",
              borderRadius: "5px",
              rowGap: "15px",
              marginTop: "5px",
            }}
          >
            <div>
              <Checkbox
                label={intl.formatMessage({ id: "ปวดศีรษะรุนแรง" })}
                checked={severeSymptoms?.headache?.value || false}
                name="headache"
                onChange={(e, data) => handleChangeCheckbox("severe", data)}
              />
            </div>
            <div>
              <Checkbox
                label="อาเจียน > 5 ครั้ง"
                checked={severeSymptoms?.vomit?.value || false}
                name="vomit"
                onChange={(e, data) => handleChangeCheckbox("severe", data)}
              />
            </div>
            <div>
              <Checkbox
                label={intl.formatMessage({ id: "กล้ามเนื้ออ่อนแรง" })}
                checked={severeSymptoms?.exhaustion?.value || false}
                name="exhaustion"
                onChange={(e, data) => handleChangeCheckbox("severe", data)}
              />
            </div>
            <div>
              <Checkbox
                label={intl.formatMessage({ id: "มีจุดเลือดออกจำนวนมาก" })}
                checked={severeSymptoms?.bleedingSpots?.value || false}
                name="bleedingSpots"
                onChange={(e, data) => handleChangeCheckbox("severe", data)}
              />
            </div>
            <div>
              <Checkbox
                label={intl.formatMessage({ id: "ปากเบี้ยว" })}
                checked={severeSymptoms?.crookedMouth?.value || false}
                name="crookedMouth"
                onChange={(e, data) => handleChangeCheckbox("severe", data)}
              />
            </div>
            <div>
              <Checkbox
                label={intl.formatMessage({ id: "ไข้สูง" })}
                checked={severeSymptoms?.highFever?.value || false}
                name="highFever"
                onChange={(e, data) => handleChangeCheckbox("severe", data)}
              />
            </div>
            <div>
              <Checkbox
                label={intl.formatMessage({ id: "ผื่นขึ้นทั้งตัว" })}
                checked={severeSymptoms?.rash?.value || false}
                name="rash"
                onChange={(e, data) => handleChangeCheckbox("severe", data)}
              />
            </div>
            <div>
              <Checkbox
                label={intl.formatMessage({ id: "แน่นหน้าอก หายใจลำบาก" })}
                checked={severeSymptoms?.chestTight?.value || false}
                name="chestTight"
                onChange={(e, data) => handleChangeCheckbox("severe", data)}
              />
            </div>
            <div>
              <Checkbox
                label="ชัก/หมดสติ"
                checked={severeSymptoms?.convulsion?.value || false}
                name="convulsion"
                onChange={(e, data) => handleChangeCheckbox("severe", data)}
              />
            </div>
            <div
              style={{
                position: "absolute",
                color: "#FF4D4D",
                fontWeight: "bold",
                bottom: "15px",
                right: "11px",
                fontSize: "15px",
              }}
            >
              กลุ่มอาการรุนแรง
            </div>
          </div>
        </Form>

        <Form style={{ marginTop: "10px" }}>
          <Form.Group>
            <Form.Field width={16} style={{ paddingRight: "1.5rem" }}>
              <label style={{ color: "#267CAD" }}>{intl.formatMessage({ id: "อาการอื่นๆ" })}</label>
              <TextArea
                value={otherSymptoms}
                onChange={(e, { value }) => setOtherSymptoms(value)}
                rows={1}
              />
            </Form.Field>
          </Form.Group>
        </Form>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "20px",
            marginBottom: "35px",
          }}
        >
          <Button
            className="primary back"
            onClick={() => setOpenModClearInput(true)}
          >
            ล้างข้อมูล
          </Button>
          <Button
            className="primary"
            style={{ marginLeft: "10px" }}
            onClick={() => setOpenModConfirmSave({ open: true })}
          >
            บันทึก
          </Button>
        </div>

        {/* Card Side Effect Monitoring */}
        <Segment style={{ boxShadow: "none", border: 0, padding: 0 }}>
          <Dimmer active={isLoadingTable} inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
          <div className="profile-header">
            <Header as="h3">Side effect monitoring</Header>
          </div>
          <Divider />
          <Form className="form-label-primary">
            <Form.Group inline>
              <Form.Field
                width={7}
                style={{ display: "flex", alignItems: "center" }}
              >
                <label
                  className="font-primary"
                  style={{ fontWeight: "bold", width: "70px" }}
                >
                  ชื่อวัคซีน
                </label>
                <Dropdown
                  selection
                  options={[
                    { key: 0, value: 0, text: "ทั้งหมด" },
                    ...vaccineNameOptions,
                  ]}
                  value={filterVaccine.vaccineName.id}
                  search
                  fluid
                  style={{ width: "100%" }}
                  onChange={(e, { value }) =>
                    handleChangeFilter("vaccineName", value)
                  }
                />
              </Form.Field>

              <Form.Field
                width={3}
                style={{ display: "flex", alignItems: "center" }}
              >
                <label style={{ fontWeight: "bold", width: "70px" }}>
                  เข็มที่
                </label>
                <Dropdown
                  selection
                  options={[
                    { key: 0, value: 0, text: "ทั้งหมด" },
                    ...needleOptions,
                  ]}
                  value={filterVaccine.needle.id}
                  search
                  fluid
                  style={{ width: "100%" }}
                  onChange={(e, { value }) =>
                    handleChangeFilter("needle", value)
                  }
                />
              </Form.Field>

              <Form.Field
                width={6}
                style={{ display: "flex", alignItems: "center" }}
              >
                <label
                  className="font-primary"
                  style={{ fontWeight: "bold", width: "110px" }}
                >
                  ความรุนแรง
                </label>
                <Dropdown
                  selection
                  options={SEVERITY_LEVEL_OPTIONS}
                  value={filterVaccine.severityLevel.id}
                  search
                  fluid
                  style={{ width: "100%" }}
                  onChange={(e, { value }) =>
                    handleChangeFilter("severityLevel", value)
                  }
                />
              </Form.Field>
            </Form.Group>

            <Form.Group inline>
              <Form.Field
                width={7}
                style={{ display: "flex", alignItems: "center" }}
              >
                <label
                  className="font-primary"
                  style={{ fontWeight: "bold", width: "130px" }}
                >
                  สถานะติดตาม
                </label>
                <Dropdown
                  selection
                  options={TRACKING_STATUS_OPTIONS}
                  value={filterVaccine.trackingStatus.value || 0}
                  search
                  fluid
                  style={{ width: "100%" }}
                  onChange={(e, { value }) =>
                    handleChangeFilter("trackingStatus", value)
                  }
                />
              </Form.Field>

              <Form.Field
                width={7}
                style={{ display: "flex", alignItems: "center" }}
              >
                <label
                  className="font-primary"
                  style={{ fontWeight: "bold", width: "75px" }}
                >
                  ระยะเวลา
                </label>
                <Dropdown
                  selection
                  options={DURATION_OPTIONS}
                  value={filterVaccine.duration?.id}
                  search
                  fluid
                  style={{ width: "100%" }}
                  onChange={(e, { value }) =>
                    handleChangeFilter("duration", value)
                  }
                />
              </Form.Field>
            </Form.Group>
          </Form>

          {/* Table */}
          {useMemo(
            () => (
              <PureReactTable
                data={tableList}
                columns={columns}
                showPagination={false}
                pageSize={tableList.length > 3 ? tableList.length : 3}
                manual
                style={{ height: "250px" }}
              />
            ),
            [tableList, columns]
          )}
        </Segment>

        <ModConfirm
          openModal={openModClearInput}
          onDeny={() => setOpenModClearInput(false)}
          onApprove={handleClearInput}
          onCloseWithDimmerClick={() => setOpenModClearInput(false)}
          content={
            <div style={{ textAlign: "center" }}>
              ต้องการล้างข้อมูลทั้งหมดหรือไม่
            </div>
          }
        />

        <ModConfirm
          openModal={openModConfirm.open}
          onDeny={handleCloseModConfirm}
          onApprove={handleEndVaccination}
          onCloseWithDimmerClick={handleCloseModConfirm}
          content={
            <>
              <ErrorMessage error={openModConfirm.error} />
              <div style={{ textAlign: "center" }}>
                ต้องการเปลี่ยนสถานะเป็น Complete หรือไม่
              </div>
            </>
          }
          loading={openModConfirm.loading}
        />

        <ModConfirm
          openModal={openModConfirmSave.open}
          onDeny={() => setOpenModConfirmSave(modInfoInitial)}
          onApprove={handleSaveVaccine}
          onCloseWithDimmerClick={() => setOpenModConfirmSave(modInfoInitial)}
          content={
            <>
              <ErrorMessage error={openModConfirmSave.error} />
              <div style={{ textAlign: "center" }}>{intl.formatMessage({ id: "ต้องการบันทึกหรือไม่" })}</div>
            </>
          }
          loading={openModConfirmSave.loading}
        />

        <ModInfo
          open={openModInfo.open}
          titleColor={openModInfo.color || "red"}
          titleName={openModInfo.title}
          onApprove={handleCloseModInfo}
          onClose={handleCloseModInfo}
        >
          {openModInfo.error === "string" ? (
            <div>{openModInfo.error}</div>
          ) : (
            <ErrorMessage error={openModInfo.error} />
          )}
        </ModInfo>
      </CardLayout>
    </>
  );
};

const CardProfileForm = (props: CardProfileFormProps) => {
  const intl = useIntl();
  // Options
  const [preNameThOptions, setPreNameThOptions] = useState<any[]>([]);
  const [religionOptions, setReligionOptions] = useState<any[]>([]);
  const [nationalityOptions, setNationalityOptions] = useState<any[]>([]);
  const [bloodTypeOptions, setBloodTypeOptions] = useState<any[]>([]);
  const [classifyOptions, setClassifyOptions] = useState<any[]>([]);
  const [provinceOptions, setProvinceOptions] = useState<any[]>([]);
  const [cityOptions, setCityOptions] = useState<any[]>([]);
  const [districtOptions, setDistrictOptions] = useState<any[]>([]);

  // List
  const [cityList, setCityList] = useState([]);

  // Open
  const [openModClearInput, setOpenModClearInput] = useState(false);
  const [openModalBindId, setOpenModalBindId] = useState(false);
  const [openModInfo, setOpenModInfo] = useState<ModInfoType>(modInfoInitial);

  // Loading
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingModalBindId, setIsLoadingModalBindId] =
    useState<boolean>(false);

  // Input
  const [profile, setProfile] = useState({ ...initialProfile });
  const [userProfile, setUserProfile] = useState<any>({});

  const [bindId, setBindId] = useState<string>("");

  const getPatientFromDiagRule = useCallback(async () => {
    const [res, error] =
      await props.dashboardController.prxManager.getPatientFromDiagRule({
        apiToken: props.apiToken,
        patientId: props.patientId,
        diagRuleId: props.diagRuleId,
        divisionId: props.division,
      });

    const detail = res;

    if (!detail) {
      return;
    }

    if (detail.user) {
      setBindId(detail.user);
    }

    const data = {
      triageLevel: detail.triage_level,
      preName: detail.pre_name,
      firstName: detail.first_name,
      lastName: detail.last_name,
      user: detail.user,
      citizenNo: detail.citizen_no,
      passportNo: detail.passport_no,
      birthdate: detail.birthdate
        ? convertToBEDate({
            date: moment(detail.birthdate, [beFormatDate, "YYYY-MM-DD"]).format(
              beFormatDate
            ),
          })
        : "",
      // age: "",
      gender: detail.gender,
      religion: detail.religion,
      nationality: detail.nationality,
      bloodType: detail.blood_type,
      occupation: detail.occupation,
      allergy: detail.allergy,
      sideEffect: detail.side_effect,
      contactName: detail.contact_name,
      no: detail.no,
      province: +detail.province,
      district: +detail.district,
      city: +detail.city,
      zipcode: "",
      telMobile: detail.tel_mobile,
      email: detail.email,
      hn: detail.hn,
      emergencyPerson: detail.emergency_person,
      emergencyNumber: detail.emergency_number,
      emergencyRelation: detail.emergency_relation,
    };

    const getDistrict = props.dashboardController.coreManager.getDistrict({
      apiToken: props.apiToken,
      area: detail.province,
    });

    const getCity = props.dashboardController.coreManager.getCity({
      apiToken: props.apiToken,
      area: detail.district,
    });

    const [resDistrict, resCity] = await Promise.all([getDistrict, getCity]);

    const cityItens = resCity?.[0] || [];

    let findCity = cityItens.find(
      (item: { id: number }) => item.id === +detail.city
    );
    data.zipcode = findCity?.zipcode;

    setDistrictOptions(createOptions(resDistrict?.[0] || []));
    setCityOptions(createOptions(cityItens));
    setCityList(cityItens);
    setProfile(data as any);
  }, [props.patientId, props.diagRuleId]);

  useEffect(() => {
    const getProfileData = async () => {
      setIsLoading(true);

      const getListPreName =
        props.dashboardController.coreManager.getListPreName({
          apiToken: props.apiToken,
          language: "TH",
        });

      const getReligion = props.dashboardController.coreManager.getReligion({
        apiToken: props.apiToken,
      });

      const getNationalities =
        props.dashboardController.coreManager.getNationalities({
          apiToken: props.apiToken,
        });

      const getBloodType = props.controller.regManager.getBloodType({
        apiToken: props.apiToken,
      });

      const getProvince = props.dashboardController.coreManager.getProvince({
        apiToken: props.apiToken,
      });

      const [resPreName, resLigion, resNational, resBloodType, resProvince] =
        await Promise.all([
          getListPreName,
          getReligion,
          getNationalities,
          getBloodType,
          getProvince,
        ]);

      setPreNameThOptions(createOptions(resPreName[0] || []));
      setReligionOptions(createOptions(resLigion[0] || []));
      setNationalityOptions(createOptions(resNational[0] || []));
      setBloodTypeOptions(createOptions(resBloodType[0] || []));
      setProvinceOptions(createOptions(resProvince[0] || []));
      setProfile({ ...initialProfile });
      setBindId("");

      if (props.triageLevel) {
        handleSetValue("triageLevel", props.triageLevel);
      }

      if (props.patientId) {
        await getPatientFromDiagRule();
      }

      setIsLoading(false);
    };
    getProfileData();
  }, [getPatientFromDiagRule]);

  useEffect(() => {
    const getClassify = async () => {
      const [res, error] =
        await props.dashboardController.prxManager.getDivisionClassify({
          apiToken: props.apiToken,
          divisionId: props.division,
        });
      const data = res?.classify || [];
      const options = data.map(
        (item: { triage_level: any; description: any }) => ({
          key: item.triage_level,
          value: item.triage_level,
          text: item.description,
        })
      );

      setClassifyOptions(options);
    };
    if (props.division) {
      getClassify();
    }
  }, [props.division]);

  const handleSetValue = useCallback((key, value) => {
    setProfile((profile) => ({
      ...profile,
      [key]: value,
    }));
  }, []);

  const calculateAge = useCallback((birthdate) => {
    const a = moment(formatDateToStringBe(moment()), beFormatDate);
    const b = moment(birthdate, beFormatDate);
    const diff = a.diff(b, "years");

    return diff;
  }, []);

  useEffect(() => {
    if (profile.birthdate) {
      const diff = calculateAge(profile.birthdate);

      handleSetValue("age", diff);
      console.log(formatDateToStringBe(moment()), profile.birthdate);
    }
  }, [profile.birthdate, handleSetValue, calculateAge]);

  // Utils
  const createOptions = (data: any[]) => {
    return data.map((item: { id: any; name: any; name_en: any }) => ({
      key: item.id,
      value: item.id,
      text: item.name || item.name_en,
    }));
  };

  const handleChangeProvince = async (value: any) => {
    setProfile({
      ...profile,
      province: value,
      district: "",
      city: "",
      zipcode: "",
    });

    const [res, error] =
      await props.dashboardController.coreManager.getDistrict({
        apiToken: props.apiToken,
        area: value,
      });

    setDistrictOptions(createOptions(res || []));
    setCityOptions([]);
  };

  const handleChangeDistrict = async (value: any) => {
    setProfile({
      ...profile,
      district: value,
      city: "",
      zipcode: "",
    });

    const [res, error] = await props.dashboardController.coreManager.getCity({
      apiToken: props.apiToken,
      area: value,
    });

    setCityOptions(createOptions(res || []));
    setCityList(res);
  };

  const handleChangeCity = async (value: any) => {
    let findCity: any = cityList.find(
      (item: { id: number }) => item.id === value
    );

    setProfile({
      ...profile,
      city: value,
      zipcode: findCity?.zipcode || "",
    });
  };

  const handleClickBindId = async () => {
    setOpenModalBindId(true);

    console.log(profile.user);

    if (!profile.user) {
      setUserProfile({});
      return;
    }

    setIsLoadingModalBindId(true);

    let [userData, error, network] =
      await props.classifyController.getUserWithPatient({
        apiToken: props.apiToken,
        userId: profile.user,
      });

    setIsLoadingModalBindId(false);

    if (userData?.dob) {
      userData.dob = convertToBEDate({
        date: moment(userData.dob, [beFormatDate, "YYYY-MM-DD"]).format(
          beFormatDate
        ),
      });
      const diff = calculateAge(userData.dob);

      userData.dob = moment(userData.dob, beFormatDate).format("DD MMMM YYYY");
      userData.age = diff;
    }

    setUserProfile(userData || {});
  };

  const handleClearInput = () => {
    setProfile({ ...initialProfile });

    setDistrictOptions([]);
    setCityOptions([]);
    setCityList([]);

    setOpenModClearInput(false);
  };

  const handleConfirmBindId = () => {
    handleSaveProfile();
  };

  const onCreateProfile = async (data: any) => {
    setIsLoading(true);

    const [res, error] =
      await props.controller.prxManager.postPatientFromDiagRule({
        apiToken: props.apiToken,
        data,
      });

    setIsLoading(false);

    if (error) {
      setOpenModInfo({ open: true, error });
      return;
    }

    setOpenModInfo({
      open: true,
      error: null,
      title: "บันทึกสำเร็จ",
      color: "green",
    });

    props.onCreateSuccess?.(res.id);
  };

  const onEditProfile = async (data: any) => {
    setIsLoading(true);

    const [res, error] =
      await props.controller.prxManager.putPatientFromDiagRule({
        apiToken: props.apiToken,
        patientId: props.patientId,
        diagRuleId: props.diagRuleId,
        data,
      });

    setIsLoading(false);

    if (error) {
      setOpenModInfo({ open: true, error });
      return;
    }

    setOpenModInfo({
      open: true,
      error: null,
      title: "บันทึกสำเร็จ",
      color: "green",
    });
    setOpenModalBindId(false);

    props.onEditSuccess?.();
  };

  const handleSaveProfile = async () => {
    let birthDate = profile.birthdate;
    if (birthDate) {
      const splitDate = birthDate.split("/");
      birthDate = `${+splitDate[2] - 543}-${splitDate[1]}-${splitDate[0]}`;
    }

    const data = {
      diag_rule: props.diagRuleId,
      triage_level: profile.triageLevel,
      pre_name: profile.preName,
      first_name: profile.firstName,
      last_name: profile.lastName,
      user:
        openModalBindId && userProfile.user
          ? userProfile.user
          : bindId
          ? bindId
          : null,
      citizen_no: profile.citizenNo,
      passport_no: profile.passportNo,
      birthdate: birthDate,
      gender: profile.gender,
      religion: profile.religion,
      nationality: profile.nationality,
      blood_type: profile.bloodType,
      occupation: profile.occupation,
      allergy: profile.allergy,
      side_effect: profile.sideEffect,
      contact_name: profile.contactName,
      no: profile.no,
      province: profile.province,
      district: profile.district,
      city: profile.city,
      tel_mobile: profile.telMobile,
      email: profile.email,
      hn: profile.hn,
      emergency_person: profile.emergencyPerson,
      emergency_number: profile.emergencyNumber,
      emergency_relation: profile.emergencyRelation,
    };

    if (props.patientId) {
      onEditProfile(data);
    } else {
      onCreateProfile(data);
    }
  };

  const handleCloseModInfo = () => {
    setOpenModInfo(modInfoInitial);
  };

  console.log(profile);
  console.log("CardProfileForm props.triageLevel", props.triageLevel)
  return (
    <>
      <div className="profile-header">
        <Header as="h3">{intl.formatMessage({ id: "แก้ไขโปรไฟล์" })}</Header>
      </div>
      <Divider />
      <Dimmer active={isLoading} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>

      <Form style={{ paddingLeft: "5px" }}>
        <Form.Group inline>
          <Form.Field width={8}>
            <LabelText label="Classify" style={{ width: "63px" }} />
            <Dropdown
              selection
              value={profile.triageLevel}
              options={classifyOptions}
              onChange={(e, { value }) => handleSetValue("triageLevel", value)}
              search
              disabled
            />
          </Form.Field>
        </Form.Group>

        <Form.Group inline>
          <Form.Field inline width={5}>
            <LabelText
              label={intl.formatMessage({ id: "คำนำหน้า" })}
              description="Prefx"
              style={{ width: "110px" }}
              required
            />
            <Dropdown
              selection
              options={preNameThOptions}
              value={profile.preName}
              onChange={(e, { value }) => handleSetValue("preName", value)}
              fluid
              style={{ width: "100%" }}
              search
            />
          </Form.Field>

          <Form.Field width={5}>
            <LabelText
              label={intl.formatMessage({ id: "ชื่อ" })}
              description="First name"
              style={{ width: "110px" }}
              required
            />
            <Input
              value={profile.firstName}
              onChange={(e, { value }) => handleSetValue("firstName", value)}
            />
          </Form.Field>

          <Form.Field width={6}>
            <LabelText
              label={intl.formatMessage({ id: "นามสกุล" })}
              description="Last Name"
              style={{ width: "110px" }}
              required
            />
            <Input
              value={profile.lastName}
              onChange={(e, { value }) => handleSetValue("lastName", value)}
            />
          </Form.Field>
        </Form.Group>

        <Form.Group inline>
          <Form.Field inline width={8}>
            <LabelText label="IH ID" style={{ width: "50px" }} />
            <Input
              // readOnly={props.patientId ? false : true}
              value={profile.user || ""}
              onChange={(e, { value }) => handleSetValue("user", value)}
            />
          </Form.Field>

          <Form.Field>
            <Button
              color="blue"
              onClick={handleClickBindId}
              // disabled={props.patientId ? false : true}
            >
              ผูก ID ของ IH App
            </Button>
          </Form.Field>
        </Form.Group>

        <Form.Group inline>
          <Form.Field inline width={8}>
            <LabelText
              label={intl.formatMessage({ id: "เลขบัตรประชาชน" })}
              description="Citizen ID"
              style={{ width: "200px" }}
              required
            />
            <Input
              value={profile.citizenNo || ""}
              onChange={(e, { value }) => handleSetValue("citizenNo", value)}
            />
          </Form.Field>

          <Form.Field inline width={8}>
            <LabelText
              label={intl.formatMessage({ id: "เลขหนังสือเดินทาง" })}
              description="Passport No."
              style={{ width: "220px", marginLeft: "15px" }}
            />
            <Input
              value={profile.passportNo}
              onChange={(e, { value }) => handleSetValue("passportNo", value)}
            />
          </Form.Field>
        </Form.Group>

        <Form.Group inline>
          <Form.Field inline width={6}>
            <LabelText
              label={intl.formatMessage({ id: "วันเดือนปีเกิด" })}
              description="Birth Date"
              style={{ width: "140px" }}
              required
            />
            <DateTextBox
              value={profile.birthdate}
              maxDate={formatDateToStringBe(moment())}
              onChange={(date: string) => {
                if (!/[\d]{2}\/[\d]{2}\/[\d]{4}/i.test(date)) {
                  return;
                }
                handleSetValue("birthdate", date);
              }}
            />
          </Form.Field>

          <Form.Field inline width={4}>
            <LabelText
              label={intl.formatMessage({ id: "อายุ" })}
              description="Age"
              style={{ width: "40px" }}
              required
            />
            <Input
              value={profile.age}
              // onChange={(e, { value }) => setAge(value)}
              readOnly
            />
          </Form.Field>

          <Form.Field inline width={6}>
            <LabelText
              label={intl.formatMessage({ id: "เพศ" })}
              description="Gender"
              style={{ width: "40px" }}
              required
            />
            <Dropdown
              selection
              options={[
                { key: "M", value: "M", text: "ชาย" },
                { key: "F", value: "F", text: "หญิง" },
              ]}
              value={profile.gender}
              onChange={(e, { value }) => handleSetValue("gender", value)}
            />
          </Form.Field>
        </Form.Group>

        <Form.Group inline>
          <Form.Field inline width={8}>
            <LabelText label={intl.formatMessage({ id: "ศาสนา" })} description="Religion" />
            <Dropdown
              selection
              options={religionOptions}
              value={profile.religion || ""}
              fluid
              style={{ width: "100%" }}
              onChange={(e, { value }) => handleSetValue("religion", value)}
              search
            />
          </Form.Field>

          <Form.Field inline width={8}>
            <LabelText
              label={intl.formatMessage({ id: "สัญชาติ" })}
              description="Nationality"
              style={{ marginLeft: "15px" }}
              required
            />
            <Dropdown
              selection
              options={nationalityOptions}
              value={profile.nationality || ""}
              fluid
              style={{ width: "100%" }}
              onChange={(e, { value }) => handleSetValue("nationality", value)}
              search
            />
          </Form.Field>
        </Form.Group>

        <Form.Group inline>
          <Form.Field inline width={7}>
            <LabelText
              label={intl.formatMessage({ id: "หมู่เลือด" })}
              description="Blood Type"
              style={{ width: "100px" }}
            />
            <Dropdown
              selection
              options={bloodTypeOptions}
              value={profile.bloodType || ""}
              fluid
              style={{ width: "100%" }}
              onChange={(e, { value }) => handleSetValue("bloodType", value)}
              search
            />
          </Form.Field>

          <Form.Field inline width={9}>
            <LabelText
              label={intl.formatMessage({ id: "อาชีพ" })}
              description="Occupation"
              style={{ width: "100px" }}
            />
            <Input
              value={profile.occupation}
              onChange={(e, { value }) => handleSetValue("occupation", value)}
            />
          </Form.Field>
        </Form.Group>

        <Form.Group inline>
          <Form.Field inline width={8}>
            <LabelText
              label={intl.formatMessage({ id: "ข้อมูลการแพ้" })}
              description="Alergy"
              style={{ width: "130px" }}
            />
            <Input
              value={profile.allergy}
              onChange={(e, { value }) => handleSetValue("allergy", value)}
            />
          </Form.Field>

          <Form.Field inline width={8}>
            <LabelText
              label={intl.formatMessage({ id: "ผลข้างเคียง" })}
              description="Side Effect"
              style={{ width: "120px" }}
            />
            <Input
              value={profile.sideEffect}
              onChange={(e, { value }) => handleSetValue("sideEffect", value)}
            />
          </Form.Field>
        </Form.Group>

        <Form.Group inline>
          <Form.Field inline width={2} style={{ alignItems: "baseline" }}>
            <LabelText
              label={intl.formatMessage({ id: "ที่อยู่" })}
              description="Address"
              style={{ width: "60px" }}
            />
          </Form.Field>

          <Form.Field inline width={7}>
            <LabelText
              label={intl.formatMessage({ id: "ชื่อสถานที่" })}
              description="Contact Name"
              style={{ width: "130px" }}
            />
            <Input
              value={profile.contactName}
              onChange={(e, { value }) => handleSetValue("contactName", value)}
            />
          </Form.Field>
          <Form.Field inline width={7}>
            <LabelText
              label={intl.formatMessage({ id: "เลขที่" })}
              description="No."
              style={{ width: "130px" }}
            />
            <Input
              value={profile.no}
              onChange={(e, { value }) => handleSetValue("no", value)}
            />
          </Form.Field>
        </Form.Group>

        <Form.Group inline>
          <Form.Field inline width={2} style={{ alignItems: "baseline" }}>
            <LabelText label="" style={{ width: "60px" }} />
          </Form.Field>

          <Form.Field inline width={7}>
            <LabelText
              label={intl.formatMessage({ id: "จังหวัด" })}
              description="Province"
              style={{ width: "130px" }}
            />
            <Dropdown
              selection
              options={provinceOptions}
              value={profile.province || ""}
              fluid
              style={{ width: "100%" }}
              onChange={(e, { value }) => handleChangeProvince(value)}
              search
            />
          </Form.Field>
          <Form.Field inline width={7}>
            <LabelText
              label="เขต/อำเภอ"
              description="District"
              style={{ width: "130px" }}
            />
            <Dropdown
              selection
              options={districtOptions}
              value={profile.district || ""}
              fluid
              style={{ width: "100%" }}
              onChange={(e, { value }) => handleChangeDistrict(value)}
              search
            />
          </Form.Field>
        </Form.Group>

        <Form.Group inline>
          <Form.Field inline width={2} style={{ alignItems: "baseline" }}>
            <LabelText label="" style={{ width: "60px" }} />
          </Form.Field>

          <Form.Field inline width={7}>
            <LabelText
              label="แขวง/ตำบล"
              description="City"
              style={{ width: "130px" }}
            />
            <Dropdown
              selection
              options={cityOptions}
              value={profile.city || ""}
              fluid
              style={{ width: "100%" }}
              onChange={(e, { value }) => handleChangeCity(value)}
              search
            />
          </Form.Field>
          <Form.Field inline width={7}>
            <LabelText
              label={intl.formatMessage({ id: "รหัสไปรษณีย์" })}
              description="Postal"
              style={{ width: "130px" }}
            />
            <Input
              readOnly
              value={profile.zipcode}
              // onChange={(e, { value }) => setAlergy(value)}
            />
          </Form.Field>
        </Form.Group>

        <Form.Group inline>
          <Form.Field inline width={8}>
            <LabelText
              label={intl.formatMessage({ id: "เบอร์โทร" })}
              description="Mobile No."
              style={{ width: "100px" }}
              required
            />
            <Input
              value={profile.telMobile}
              onChange={(e, { value }) => handleSetValue("telMobile", value)}
            />
          </Form.Field>

          <Form.Field inline width={8}>
            <LabelText
              label="e-mail"
              description="Address"
              style={{ width: "80px" }}
            />
            <Input
              value={profile.email}
              onChange={(e, { value }) => handleSetValue("email", value)}
            />
          </Form.Field>
        </Form.Group>

        <Form.Group inline>
          <Form.Field inline width={8}>
            <LabelText
              label="HN"
              description="Hospital No."
              style={{ width: "110px" }}
            />
            <Input
              value={profile.hn || ""}
              onChange={(e, { value }) => handleSetValue("hn", value)}
              // readOnly={true}
            />
          </Form.Field>

          <Form.Field inline width={8}>
            <LabelText
              label={intl.formatMessage({ id: "กรณีฉุกเฉินติดต่อ" })}
              description="Emergency Contact Person"
              style={{ width: "410px" }}
            />
            <Input
              value={profile.emergencyPerson}
              onChange={(e, { value }) =>
                handleSetValue("emergencyPerson", value)
              }
            />
          </Form.Field>
        </Form.Group>

        <Form.Group inline>
          <Form.Field inline width={9}>
            <LabelText
              label={intl.formatMessage({ id: "เบอร์ติดต่อกรณีฉุกเฉิน" })}
              description="Emergency Contact No."
              style={{ width: "310px" }}
            />
            <Input
              value={profile.emergencyNumber}
              onChange={(e, { value }) =>
                handleSetValue("emergencyNumber", value)
              }
            />
          </Form.Field>

          <Form.Field inline width={7}>
            <LabelText
              label={intl.formatMessage({ id: "เกี่ยวข้องเป็น" })}
              description="Relationship"
              style={{ width: "120px" }}
            />
            <Input
              value={profile.emergencyRelation}
              onChange={(e, { value }) =>
                handleSetValue("emergencyRelation", value)
              }
            />
          </Form.Field>
        </Form.Group>
      </Form>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "35px",
          marginBottom: "35px",
        }}
      >
        <Button
          className="primary back"
          onClick={() => setOpenModClearInput(true)}
        >
          ล้างข้อมูล
        </Button>
        <Button
          className="primary"
          style={{ marginLeft: "10px" }}
          onClick={handleSaveProfile}
        >
          บันทึก
        </Button>
      </div>

      <Modal
        open={openModalBindId}
        closeOnDimmerClick
        size="small"
        onClose={() => setOpenModalBindId(false)}
        style={{ borderRadius: "15px", overflow: "hidden" }}
      >
        <Segment style={{ padding: "40px" }}>
          <Dimmer active={isLoadingModalBindId} inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
          <Header as="h3" style={{ marginTop: 0 }}>
            กรุณาตรวจสอบข้อมูล User ก่อนผูก ID
          </Header>
          <div className="modal-profile">
            <div className="image">
              <img src={IMAGE.IH} alt="ih_image" />
            </div>
            <div className="detail">
              <div>IH ID : {userProfile.user || ""}</div>
              {userProfile.facebook_id ? (
                <>
                  <div>Login ด้วย : {"Facebook"}</div>
                  <div>Facebook ID : {userProfile.facebook_id}</div>
                </>
              ) : userProfile.line_id ? (
                <>
                  <div>Login ด้วย : {"Line"}</div>
                  <div>Line ID : {userProfile.line_id}</div>
                </>
              ) : null}
              <div>
                ชื่อ-นามสกุล : {userProfile.first_name} {userProfile.last_name}
              </div>
              {/* <div>HN : {userProfile.hn}</div> */}
              <div>อายุ : {userProfile.age || "-"}</div>
              <div>เลขบัตรประชาชน : {userProfile.citizen_no}</div>
              <div>วันเกิด {userProfile.dob}</div>
              <div>โทร : {userProfile.phone_no}</div>
            </div>
          </div>
          <div
            className="modal-profile-button"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "45px",
              marginBottom: "0px",
            }}
          >
            <Button
              className="primary back"
              onClick={() => setOpenModalBindId(false)}
            >
              ยกเลิก
            </Button>
            <Button
              className="primary"
              style={{ marginLeft: "10px" }}
              onClick={handleConfirmBindId}
            >
              ยืนยันการผูก ID
            </Button>
          </div>
        </Segment>
      </Modal>

      <ModConfirm
        openModal={openModClearInput}
        onDeny={() => setOpenModClearInput(false)}
        onApprove={handleClearInput}
        onCloseWithDimmerClick={() => setOpenModClearInput(false)}
        content={
          <div style={{ textAlign: "center" }}>
            ต้องการล้างข้อมูลทั้งหมดหรือไม่
          </div>
        }
      />

      <ModInfo
        open={openModInfo.open}
        titleColor={openModInfo.color || "red"}
        titleName={openModInfo.title}
        onApprove={handleCloseModInfo}
        onClose={handleCloseModInfo}
      >
        {openModInfo.error === "string" ? (
          <div>{openModInfo.error}</div>
        ) : (
          <ErrorMessage error={openModInfo.error} />
        )}
      </ModInfo>
    </>
  );
};

const QAChatTable: React.FC<QAChatTableProps> = forwardRef((props, ref) => {
  const intl = useIntl();
  const history = useHistory();

  // Loading
  const [modAppointError, setModAppointError] = useState(null);
  const [modAppointLoading, setModAppointLoading] = useState(false);
  const [patientLoading, setPatientLoading] = useState(false);

  // Open
  const [openModAppoint, setOpenModAppoint] = useState(false);
  const [openModDiagHis, setOpenModDiagHis] = useState(false);
  const [openClassify, setOpenClassify] = useState(false);
  const [openDoctorQueue, setOpenDoctorQueue] = useState(false);
  const [openModFinishTreatment, setOpenModFinishTreatment] =
    useState<ModInfoType>(modInfoInitial);
  const [openModInfo, setOpenModInfo] = useState<ModInfoType>(modInfoInitial);
  const [readOnly, setReadOnly] = useState(false);
  const [openVaccineAppointment, setOpenVaccineAppointment] = useState(false);
  const [hideContentChat, setHideContentChat] = useState(false);

  // List
  const [triageLevelList, setTriageLevelList] = useState<any[]>([]);
  const [allClassify, setAllClassify] = useState([]);

  // Select
  const [selectedPatient, setSelectedPatient] = useState<any>(null);

  const [hasWSReclassify, setHasWSReclassify] = useState(false);
  const [patientAppointmentId, setPatientAppointmentId] = useState(null);
  const [patientData, setPatientData] = useState<any>({});
  const [patientAllergyData, setPatientAllergyData] = useState<any>({});
  const [encounterData, setEncounterData] = useState<any>({
    patient: null,
    triage_level: null,
  });
  const [activeItem, setActiveItem] = useState<MenuPatient>("CHAT");
  const [encounterId, setEncounterId] = useState<any>("");
  const [loadCardChatBox, setLoadCardChatBox] = useState(false);
  const isMounted = useRef<boolean>(true);
  const chatBoxRef = useRef<any>();
  const patientPanelRef = useRef<any>();
  const cardVitalSignRef = useRef<any>();
  const queueChatRef = useRef<CardPatientQueueChatAirflowRef>({} as any);

  useImperativeHandle(ref, () => ({
    chatBoxRef: chatBoxRef.current,
    getListTriageLevel: () => queueChatRef.current.handleEventReclassify(),
    updateDiagForm: ({ patientId }: any = {}) =>
      queueChatRef.current.updateDiagForm?.({ patientId }),
    receivedMessage: ({ chatChannelId }: any = {}) => {
      handleReceivedMessage(null, chatChannelId);
    },
  }));

  const handleGetClassify = async () => {
    if (!allClassify) {
      return;
    }
    let newArr = allClassify.filter((item: any) => {
      if (
        props.finishedTriageLevel &&
        !props.finishedTriageLevel.includes(item.triage_level)
      ) {
        return item;
      }
    });
    let options = newArr.map(
      ({ triage_level, description, active, codeColor }) => ({
        key: triage_level,
        value: triage_level,
        triage_level: triage_level,
        code_color: codeColor,
        text: description,
        active: active,
      })
    );
    let appTriageList: { key: string; text: string; value: string }[] = [];
    if (config.BOOKING_FUNCTION) {
      appTriageList = [
        { key: "app", text: "มีนัดหมาย", value: "app" },
        { key: "noApp", text: "ไม่มีนัดหมาย", value: "noApp" },
      ];
    }
    setTriageLevelList([
      { key: "default", text: "DEFAULT", value: "default" },
      ...appTriageList,
      ...options,
    ]);
  };

  useEffect(() => {
    props.onSetShowMenuNoti?.(true);
  }, []);

  useEffect(() => {
    if (props.division && typeof props.division === "number") {
      setTriageLevelList([]);
    }
  }, [props.division]);

  useEffect(() => {
    handleGetClassify();
  }, [props.finishedTriageLevel, allClassify]);

  // รอ encounter
  useInterval(() => {
    if (hasWSReclassify) {
      getPatientDetail();
    }
  }, 10000);

  // useInterval(() => {
  //   if (hasWSReclassify) {
  //     handleGetChatChannel()
  //   }
  // }, 10000);

  useEffect(() => {
    props.onGetDivision();
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const getClassifyUser = async () => {
      const [response, error, network] = await props.controller.getClassifyUser(
        {
          apiToken: Cookies.get("apiToken") ? Cookies.get("apiToken") : props.apiToken,
          divisionId: props.division,
        }
      );
      if (!isMounted.current) {
        return;
      }
      if (response) {
        setAllClassify(response.classify);
      } else {
        setAllClassify([]);
        // setTriageLevelList([]);
      }
    };
    if (props.division) {
      getClassifyUser();
    }
  }, [props.division]);

  useEffect(() => {
    const getEncounterDetail = async () => {
      const [data, error] = await props.controller.getEncounterDetail({
        encounterId,
        apiToken: Cookies.get("apiToken") ? Cookies.get("apiToken") : props.apiToken,
      });

      if (isMounted.current) {
        if (error) {
          console.log(error);
        } else {
          console.log(data);
          setEncounterData(data);
        }
      }
    };

    if (encounterId) {
      getEncounterDetail();
    }
  }, [encounterId]);

  const getPatientDetail = async () => {
    setHasWSReclassify(false);
    const patientId = selectedPatient?.patient?.id; // encounterData.patient

    if (!patientId) {
      setPatientLoading(false);
      return;
    }

    const [data, error] = await props.controller.getProxyPatient({
      patientId,
      apiToken: Cookies.get("apiToken") ? Cookies.get("apiToken") : props.apiToken,
    });

    const [allergyData, allergyError] =
      await props.dashboardController.prxManager.getProxyPatientAllergy({
        patientId,
        apiToken: Cookies.get("apiToken") ? Cookies.get("apiToken") : props.apiToken,
      });

    setPatientLoading(false);

    if (error) {
      setPatientData({});
      setPatientAllergyData({});
    } else {
      setPatientData(data);
      setPatientAllergyData(allergyData?.items?.[0] || {});
    }
  };

  const handleCreateEditAppointment = () => {
    if (!patientAppointmentId) {
      setOpenModAppoint(true);
    } else {
      props.onEditAppointment({ appointmentId: patientAppointmentId });
    }
  };

  const handleOpenAppointment = async () => {
    // props.match && props.match.params && props.match.params.encounterId : QueryParams
    if (encounterId) {
      setModAppointError(null);
      setModAppointLoading(true);
      const [response, error] =
        await props.controller.postEncounterPatientAppointment({
          encounterId,
          apiToken: Cookies.get("apiToken") ? Cookies.get("apiToken") : props.apiToken,
        });
      if (isMounted.current) {
        setModAppointLoading(false);
        if (chatBoxRef.current) {
          chatBoxRef.current.getLastMessage();
        }
        if (response) {
          setOpenModAppoint(false);
        } else {
          setModAppointError(error);
        }
      }
    }
  };

  const handleFinishTreatment = async () => {
    setOpenModFinishTreatment({ ...openModFinishTreatment, loading: true });
    const [response, error, network] =
      await props.classifyController.updateTriage({
        apiToken: props.apiToken,
        encounterId: encounterId,
        triageLevel: "dis",
        diagFormId: selectedPatient?.id,
      });

    if (error) {
      setOpenModFinishTreatment({
        ...openModFinishTreatment,
        loading: false,
        error,
      });
      return;
    } else if (response) {
      setOpenModFinishTreatment(modInfoInitial);
      setOpenModInfo({
        open: true,
        color: "green",
        title: "บันทึกสำเร็จ",
      });
    }
  };

  const handleSuccessModalClassify = async (data: any = {}) => {
    setOpenClassify(false);
  };

  // Handle Click

  const getParamsHistory = (item: any, triage: any = null) => {
    const page = getCurrentPageURL();
    const { diag_rule } = getQueryParams();
    let path = page;
    triage = triage ? triage : queueChatRef.current.getSelectedTriage();

    if (triage.triageLevel) {
      path = `${path}${triage.triageLevel}/`;
    }

    if (item.id && item.id !== NEW_PATIENT) {
      path = `${path}${item.id}/`;
    }

    console.log(" Check getParamsHistory ", path)
    return {
      pathname: path,
      search: diag_rule ? `?diag_rule=${diag_rule}` : "",
    };
  };

  const handleSelectedPatient = (data: any) => {
    setSelectedPatient(data);
  };

  const handleReceivedMessage = async (seltChatId: any, chatId: any) => {
    const { vid_call } = getQueryParams();
    if (JSON.parse(vid_call || "false")) {
      return;
    }

    const selectedTriage = queueChatRef.current.getSelectedTriage();
    let classifyList = queueChatRef.current?.getClassifyList?.();
    const activeItem = await new Promise((resolve, reject) => {
      setActiveItem((prev) => {
        resolve(prev);
        return prev;
      });
    });

    if (
      chatId &&
      selectedTriage.items[0] &&
      (selectedPatient?.chat_channel?.id !== chatId || activeItem !== "CHAT")
    ) {
      console.log("RECEIVED");
      const findChat = selectedTriage.items.findIndex(
        (item: any) => item?.chat_channel?.id === chatId
      );
      if (findChat !== -1) {
        let newArr: any[] = [...selectedTriage.items];
        newArr[findChat].chat_channel.unread_message_count += 1;
        queueChatRef.current.setSelectedTriage({ items: newArr });
      }
    }

    if (
      chatId &&
      classifyList &&
      (selectedPatient?.chat_channel?.id !== chatId || activeItem !== "CHAT")
    ) {
      classifyList = classifyList?.map((list) => ({
        ...list,
        items: list.items.map((item) =>
          item?.chat_channel?.id === chatId
            ? {
                ...item,
                has_new_messages: true,
                chat_channel: { ...item.chat_channel, unread_message_count: 1 },
              }
            : item
        ),
      }));

      queueChatRef.current?.setClassifyList?.(classifyList);
    }
  };

  const handleCloseVideoCall = () => {
    console.log('handleCloseVideoCall 5');
    const isReclassify = queueChatRef.current?.getIsReclassify?.();
    const isDiagForm = queueChatRef.current?.getIsDiagFormUpdate?.();
    if (isReclassify || isDiagForm) {
      queueChatRef.current.handleEventReclassify();
    }
  };

  const handleActiveItem = (isCreateNewPatient: boolean) => {
    setPatientLoading(true);
    getPatientDetail();

    if (selectedPatient.id === NEW_PATIENT || isCreateNewPatient) {
      setActiveItem("PROFILE");
    } else {
      if (selectedPatient?.chat_channel?.id) {
        setActiveItem("CHAT");
      } else {
        setActiveItem("ASSESSMENT");
      }
    }
  };

  const handleClickMenuChat = () => {
    setActiveItem("CHAT");
    const selectedTriage = queueChatRef.current.getSelectedTriage();
    const findChat = selectedTriage.items?.find(
      (item: any) => item.id === selectedPatient.id
    );
    if (findChat?.chat_channel?.unread_message_count > 0) {
      const triageList = {
        ...selectedTriage,
        items: selectedTriage.items.map((triage) =>
          triage.chat_channel?.id === findChat.chat_channel?.id
            ? {
                ...triage,
                has_new_messages: false,
                chat_channel: {
                  ...triage.chat_channel,
                  unread_message_count: 0,
                },
              }
            : triage
        ),
      };
      queueChatRef.current.setSelectedTriage(triageList);
      queueChatRef.current?.setClassifyListByTraige?.(
        selectedTriage.triageLevel,
        triageList
      );
    }

    setTimeout(() => {
      console.log("**********   history.replace  ***************")
      histroyReplaceWrapper(!props.noReactRouter && history ,getParamsHistory(selectedPatient), props.setState);
    });
  };

  // Handle Mod
  const handleModDiagHis = () => {
    setOpenModDiagHis(!openModDiagHis);
  };

  const openModalClassify = () => {
    setOpenClassify(true);
  };

  const handleEnNoDiv = () => {
    setReadOnly(true);
  };

  const handleEnHasDiv = () => {
    setReadOnly(false);
  };

  const handleModDoctorQueue = () => {
    setOpenDoctorQueue(!openDoctorQueue);
  };

  const closeModalClassify = async () => {
    setOpenClassify(false);
  };

  const handleCloseModInfo = () => {
    setOpenModInfo(modInfoInitial);
  };

  const handleOnKeyPress = async (ev: {
    key: string;
    preventDefault: () => void;
  }) => {
    if (ev.key === "Enter") {
      ev.preventDefault();
    }
  };

  const handleOnGetPatientDetail = () => {
    setPatientLoading(true);
    getPatientDetail();
  };

  // Utils

  const getQueryParams = () => {
    const queryParams = new URLSearchParams(history.location.search);
    const diag_rule = props.diag_rule || queryParams.get("diag_rule") || queueChatRef.current.getDiagRuleId();
    const vid_call = JSON.parse(queryParams.get("vid_call") || "false");

    return { diag_rule, vid_call };
  };

  const getCurrentPageURL = () => {
    const re = new RegExp(/^(\/[^/]*).*/);
    let match = re.exec(history.location.pathname);
    if (match) {
      return match[1] + "/";
    }
    return `/${history.location.pathname.split("/")[1]}/`;
  };

  const encounterOptions = useMemo(() => {
    const mapOption = (item: { id: any; label: any }, index: any) => ({
      key: index,
      value: item.id,
      text: item.label,
    });
    return selectedPatient?.encounters
      ? selectedPatient.encounters.map(mapOption)
      : [];
  }, [selectedPatient]);

  const CardPatientQueue = useMemo(() => {
    const components = {
      airflow: CardPatientQueueChatAirflow,
      old: CardPatientQueueChat,
    };
    const PatientQueueAirflow = props.AIRFLOW_CHAT;
    return components[PatientQueueAirflow ? "airflow" : "old"];
  }, [props.AIRFLOW_CHAT]);

  console.log(hideContentChat, "3703");
  return (
    <div className="qa-chat-table">
      <Grid className="ChatTable noMargin" celled>
        {/* Left */}
        <Grid.Column
          width={4}
          style={{
            borderRight: "1px solid #d4d4d5",
            height: "calc(100vh - 40px)",
            paddingRight: "8px",
            paddingLeft: "8px",
          }}
        >
          {/* <Dimmer active={isLoading} inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer> */}

          <CardPatientQueue
            ref={queueChatRef}
            {...props}
            apiToken={props.apiToken}
            controller={props.controller}
            division={props.division}
            storage={props.storage}
            chatBoxRef={chatBoxRef}
            // heightQueue={formRef.current?.offsetHeight ? formRef.current?.offsetHeight + 70 : 175}
            selectedPatient={selectedPatient}
            getParamsHistory={getParamsHistory}
            onSelectedPatient={handleSelectedPatient}
            onActiveItem={handleActiveItem}
            onGetPatientDetail={handleOnGetPatientDetail}
            onSetEncounterId={(id: string) => {
              setEncounterId(id);
            }}
            onSetEncounterData={(data: any) => setEncounterData(data)}
            onSetLoadCardChatBox={(loading: boolean) =>
              setLoadCardChatBox(loading)
            }
            onSetActiveItem={(value: any) => setActiveItem(value)}
            onSetHideContentChat={(value: any) => setHideContentChat(value)}
            languageUX={props.languageUX}
          />
        </Grid.Column>
        {
          // ยังไม่ได้เลือก Patient
          !selectedPatient?.id ? (
            <Grid.Column width={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  color: "rgb(158 157 157)",
                }}
              >
                <Icon
                  name="comment alternate outline"
                  style={{
                    transform: "scale(8.5)",
                    marginBottom: "100px",
                    color: "#E0E0E0",
                  }}
                />
                <p style={{ lineHeight: 0 }}>{intl.formatMessage({ id: "ไม่มีผู้ป่วยที่เลือกไว้" })}</p>
                <p>{intl.formatMessage({ id: "กรุณาเลือกผู้ป่วยเพื่อเริ่ม Teleconsult" })}</p>
              </div>
            </Grid.Column>
          ) : (
            <>
              {/* Header center */}
              <Grid.Column
                width={config.HIDE_QUEUE ? 12 : 9}
                style={{ padding: 0 }}
                className="chatBox"
              >
                {(props.match && props.match.params) ||
                selectedPatient?.id === NEW_PATIENT ? (
                  <>
                    {selectedPatient?.id !== NEW_PATIENT && (
                      <>
                        <Form className="chatbox-header">
                          <div className="center-header">
                            <div>
                              {/* <Form.Field > */}
                              {encounterOptions[0] && (
                                <Dropdown
                                  options={encounterOptions}
                                  value={encounterId}
                                  selection
                                  // search
                                  onChange={(e, { value }) =>
                                    setEncounterId(value)
                                  }
                                  fluid
                                />
                              )}
                              {/* </Form.Field> */}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                              }}
                            >
                              {(config.ALLOW_CREATE_VACCINE_APPOINTMENT ===
                              undefined
                                ? props.allowCreateAppoint
                                : config.ALLOW_CREATE_VACCINE_APPOINTMENT) && (
                                <Button
                                  className={
                                    patientAppointmentId
                                      ? "edit-appointment"
                                      : "create-appointment"
                                  }
                                  // onClick={handleCreateEditAppointment}
                                  disabled={!encounterId}
                                  onClick={() =>
                                    setOpenVaccineAppointment(true)
                                  }
                                >
                                  <Icon name="calendar" />
                                  {"สร้างนัดหมายวัคซีน"}
                                </Button>
                              )}

                              {props.allowCreateAppoint && (
                                <Button
                                  className={
                                    patientAppointmentId
                                      ? "edit-appointment"
                                      : "create-appointment"
                                  }
                                  onClick={handleCreateEditAppointment}
                                  disabled={!encounterId}
                                  // onClick={() => setOpenModAppoint(true)}
                                >
                                  <Icon name="calendar" />
                                  {patientAppointmentId
                                    ? "แก้ไขนัดหมาย"
                                    : "สร้างนัดหมาย"}
                                </Button>
                              )}

                              {/* <Button
                                // onClick={openModalClassify}
                                disabled={readOnly}
                                className="teleconsult"
                              >
                                Teleconsult-แพทย์
                              </Button> */}

                              {!config.HIDE_CLASSIFY && (
                                <Button
                                  onClick={openModalClassify}
                                  // disabled={readOnly}
                                  disabled={props.classifyFromDiagRule ? false : !encounterId}
                                >
                                  Reclassify
                                </Button>
                              )}
                              <Button
                                onClick={() =>
                                  setOpenModFinishTreatment({ open: true })
                                }
                                disabled={!encounterId}
                                className="finish-treatment"
                              >
                                จบการรักษา
                              </Button>
                            </div>
                          </div>
                        </Form>
                      </>
                    )}

                    {/*เมนูเลือก form */}
                    <div style={{ display: "flex" }}>
                      <div
                        className="menu-patient-info"
                        style={{
                          ...(selectedPatient?.id === NEW_PATIENT
                            ? { height: "96vh" }
                            : {}),
                        }}
                      >
                        {!config.HIDE_CLASSIFY_SUBMENU_CHAT && (
                          <Button
                            icon="comment alternate outline"
                            className={activeItem === "CHAT" ? "active" : ""}
                            onClick={handleClickMenuChat}
                            disabled={
                              selectedPatient?.id === NEW_PATIENT ||
                              // !props.match.params.chatChannelId
                              !selectedPatient?.chat_channel?.id
                            }
                          />
                        )}
                        {!config.HIDE_CLASSIFY_SUBMENU_ASSESSMENT && (
                          <Button
                            icon="file alternate outline"
                            className={
                              activeItem === "ASSESSMENT" ? "active" : ""
                            }
                            onClick={() => setActiveItem("ASSESSMENT")}
                            disabled={selectedPatient?.id === NEW_PATIENT}
                          />
                        )}
                        {!config.HIDE_CLASSIFY_SUBMENU_PROGRESS_NOTE && (
                          <Button
                            className={
                              activeItem === "PROGRESS_NOTE" ? "active" : ""
                            }
                            onClick={() => setActiveItem("PROGRESS_NOTE")}
                            disabled={
                              selectedPatient?.id === NEW_PATIENT ||
                              !encounterId
                            }
                            // disabled={true}
                            style={{ height: "45px" }}
                          >
                            <svg
                              style={{ margin: "-1px -1px 0 0" }}
                              width="16"
                              height="18"
                              viewBox="0 0 36 36"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M20 10.375C20 9.11103 19.751 7.85943 19.2673 6.69167C18.7836 5.52391 18.0747 4.46286 17.1809 3.5691C16.2871 2.67533 15.2261 1.96636 14.0583 1.48266C12.8906 0.998958 11.639 0.75 10.375 0.75C9.11103 0.75 7.85943 0.998958 6.69167 1.48266C5.52391 1.96636 4.46286 2.67533 3.5691 3.5691C2.67533 4.46286 1.96636 5.52391 1.48266 6.69167C0.998958 7.85943 0.75 9.11103 0.75 10.375C0.75 12.9277 1.76406 15.3759 3.5691 17.1809C5.37414 18.9859 7.82229 20 10.375 20C12.9277 20 15.3759 18.9859 17.1809 17.1809C18.9859 15.3759 20 12.9277 20 10.375ZM11.25 11.25L11.2517 15.632C11.2517 15.8641 11.1596 16.0866 10.9955 16.2507C10.8314 16.4148 10.6088 16.507 10.3767 16.507C10.1447 16.507 9.92213 16.4148 9.75803 16.2507C9.59394 16.0866 9.50175 15.8641 9.50175 15.632V11.25H5.118C4.88594 11.25 4.66338 11.1578 4.49928 10.9937C4.33519 10.8296 4.243 10.6071 4.243 10.375C4.243 10.1429 4.33519 9.92038 4.49928 9.75628C4.66338 9.59219 4.88594 9.5 5.118 9.5H9.5V5.125C9.5 4.89294 9.59219 4.67038 9.75628 4.50628C9.92038 4.34219 10.1429 4.25 10.375 4.25C10.6071 4.25 10.8296 4.34219 10.9937 4.50628C11.1578 4.67038 11.25 4.89294 11.25 5.125V9.5H15.6303C15.8623 9.5 16.0849 9.59219 16.249 9.75628C16.4131 9.92038 16.5053 10.1429 16.5053 10.375C16.5053 10.6071 16.4131 10.8296 16.249 10.9937C16.0849 11.1578 15.8623 11.25 15.6303 11.25H11.2517H11.25Z"
                                fill="rgb(79, 79, 79)"
                              />
                              <path
                                d="M30.0625 6.875H21.2023C20.903 5.95039 20.486 5.06814 19.9615 4.25H30.0625C31.5709 4.25 33.0176 4.84922 34.0842 5.91583C35.1508 6.98244 35.75 8.42908 35.75 9.9375V21.9757C35.7495 23.0194 35.3347 24.0202 34.5967 24.7582L24.76 34.5967C24.3944 34.9624 23.9603 35.2524 23.4826 35.4503C23.0049 35.6482 22.4928 35.75 21.9757 35.75H9.9375C8.42908 35.75 6.98244 35.1508 5.91583 34.0842C4.84922 33.0176 4.25 31.5709 4.25 30.0625V19.9615C5.06025 20.4812 5.94225 20.8995 6.875 21.2023V30.0625C6.875 31.753 8.247 33.125 9.9375 33.125H21.75V27.4375C21.7499 25.9848 22.3057 24.587 23.3034 23.5311C24.3011 22.4751 25.6651 21.841 27.1155 21.7588L27.4375 21.75H33.125V9.9375C33.125 9.12527 32.8023 8.34632 32.228 7.77199C31.6537 7.19766 30.8747 6.875 30.0625 6.875ZM31.2682 24.375H27.4375C26.6686 24.3751 25.9279 24.6643 25.3625 25.1853C24.7971 25.7063 24.4483 26.421 24.3855 27.1873L24.375 27.4375V31.2682L31.2682 24.375Z"
                                fill="rgb(79, 79, 79)"
                              />
                            </svg>
                          </Button>
                        )}
                        {!config.HIDE_CLASSIFY_SUBMENU_NURSE_NOTE && (
                          <Button
                            className={
                              activeItem === "NURSE_NOTE" ? "active" : ""
                            }
                            onClick={() => setActiveItem("NURSE_NOTE")}
                            disabled={
                              selectedPatient?.id === NEW_PATIENT ||
                              !encounterId
                            }
                            style={{ height: "45px" }}
                          >
                            <svg
                              style={{ margin: "-1px -1px 0 0" }}
                              width="16"
                              height="18"
                              viewBox="0 0 31 37"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.55138 0.125L6.3125 1.36991L4.78125 9.98319V15.4375H4.92519C5.13725 16.9176 5.65523 18.3372 6.44613 19.6061C7.23703 20.875 8.28351 21.9652 9.51894 22.8074C4.05697 25.1502 0.1875 30.5708 0.1875 36.875H3.25C3.24752 35.0372 3.65923 33.2224 4.45452 31.5655C5.24982 29.9087 6.40825 28.4523 7.84375 27.3047V28.3092L8.27403 28.7885L14.399 34.9135L15.5 35.9654L16.601 34.9135L22.726 28.7885L23.1562 28.3092V27.3047C24.5917 28.4523 25.7502 29.9087 26.5455 31.5655C27.3408 33.2224 27.7525 35.0372 27.75 36.875H30.8125C30.8125 30.5708 26.943 25.1518 21.4811 22.8059C22.7163 21.9639 23.7627 20.8738 24.5536 19.6053C25.3445 18.3367 25.8625 16.9173 26.0748 15.4375H26.2188V9.98166L24.6875 1.36991L24.4486 0.125H6.55138ZM9.13612 3.1875H21.8639L23.1562 10.5084V12.375H7.84375V10.5084L9.13612 3.1875ZM13.9688 4.71875V6.25H12.4375V9.3125H13.9688V10.8438H17.0312V9.3125H18.5625V6.25H17.0312V4.71875H13.9688ZM7.98769 15.4375H23.0123C22.6587 17.1683 21.7177 18.7235 20.3485 19.8398C18.9794 20.9561 17.2665 21.5647 15.5 21.5625C13.7335 21.5647 12.0206 20.9561 10.6515 19.8398C9.28235 18.7235 8.34127 17.1683 7.98769 15.4375ZM15.5 24.625C17.0722 24.6168 18.6315 24.9094 20.0938 25.4871V27.0183L15.5 31.6121L10.9062 27.0183V25.4871C12.3685 24.9094 13.9278 24.6168 15.5 24.625Z"
                                fill="rgb(79, 79, 79)"
                              />
                            </svg>
                          </Button>
                        )}
                        {!config.HIDE_CLASSIFY_SUBMENU_VITAL_SIGN && (
                          <Button
                            className={
                              activeItem === "VITAL_SIGN" ? "active" : ""
                            }
                            onClick={() => setActiveItem("VITAL_SIGN")}
                            disabled={
                              selectedPatient?.id === NEW_PATIENT ||
                              !encounterId
                            }
                            style={{ height: "45px" }}
                          >
                            <svg
                              style={{ margin: "-1px -1px 0 0" }}
                              width="16"
                              height="18"
                              viewBox="0 0 31 34"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M26.875 3.875H20.0825C19.4 1.99 17.6125 0.625 15.5 0.625C13.3875 0.625 11.6 1.99 10.9175 3.875H4.125C3.26305 3.875 2.4364 4.21741 1.8269 4.8269C1.21741 5.4364 0.875 6.26305 0.875 7.125V29.875C0.875 30.737 1.21741 31.5636 1.8269 32.1731C2.4364 32.7826 3.26305 33.125 4.125 33.125H26.875C27.737 33.125 28.5636 32.7826 29.1731 32.1731C29.7826 31.5636 30.125 30.737 30.125 29.875V7.125C30.125 6.26305 29.7826 5.4364 29.1731 4.8269C28.5636 4.21741 27.737 3.875 26.875 3.875ZM15.5 3.875C15.931 3.875 16.3443 4.0462 16.649 4.35095C16.9538 4.6557 17.125 5.06902 17.125 5.5C17.125 5.93098 16.9538 6.3443 16.649 6.64905C16.3443 6.95379 15.931 7.125 15.5 7.125C15.069 7.125 14.6557 6.95379 14.351 6.64905C14.0462 6.3443 13.875 5.93098 13.875 5.5C13.875 5.06902 14.0462 4.6557 14.351 4.35095C14.6557 4.0462 15.069 3.875 15.5 3.875ZM4.125 23.375H9.17875L11.6325 18.7437L12.8675 28.12L18.8638 20.4662L21.8213 23.375H26.875V29.875H4.125V23.375ZM26.875 20.8725H22.8613L18.6362 16.6475L14.59 21.8313L13.0625 10.505L7.65125 20.8725H4.125V7.125H7.375V8.75H23.625V7.125H26.875V20.8725Z"
                                fill="rgb(79, 79, 79)"
                              />
                            </svg>
                          </Button>
                        )}
                        {!config.HIDE_CLASSIFY_SUBMENU_VACCINE_NOTE && (
                          <Button
                            icon="syringe"
                            className={
                              activeItem === "VACCINE_NOTE" ? "active" : ""
                            }
                            onClick={() => setActiveItem("VACCINE_NOTE")}
                            disabled={
                              selectedPatient?.id === NEW_PATIENT ||
                              !encounterId
                            }
                            // disabled={true}
                          />
                        )}
                        {!config.HIDE_CLASSIFY_SUBMENU_PROFILE && (
                          <Button
                            icon="user plus"
                            className={activeItem === "PROFILE" ? "active" : ""}
                            onClick={() => {
                              setActiveItem("PROFILE");
                            }}
                          />
                        )}
                      </div>
                      <div
                        style={{
                          width: ["PROGRESS_NOTE", "NURSE_NOTE"].includes(
                            activeItem
                          )
                            ? "94%"
                            : "100%",
                        }}
                      >
                        {hideContentChat ? (
                          <Segment style={{ height: "97vh" }}>
                            <Dimmer active={true} inverted>
                              <Loader inverted>Loading</Loader>
                            </Dimmer>
                          </Segment>
                        ) : (activeItem === "CHAT" || loadCardChatBox) &&
                          !hideContentChat ? (
                          <div
                            style={
                              loadCardChatBox
                                ? { height: "0px", overflow: "hidden" }
                                : {}
                            }
                          >
                            <ChatBox
                              {...props}
                              match={{
                                params: {
                                  chatChannelId:
                                    selectedPatient?.chat_channel?.id,
                                },
                              }}
                              ref={chatBoxRef}
                              nullId={props.nullId}
                              controller={props.controller}
                              userId={props.userId}
                              apiToken={props.apiToken}
                              fullname={props.fullname}
                              username={props.username}
                              patientData={patientData}
                              autoHeight={props.autoHeight}
                              isPatient={props.isPatient}
                              enHasNoDiv={handleEnNoDiv}
                              enHasDiv={handleEnHasDiv}
                              onNavigationMessage={props.onNavigationMessage}
                              onCallGetLastMessage={handleReceivedMessage}
                              onCloseVideoCall={handleCloseVideoCall}
                            />
                          </div>
                        ) : activeItem === "ASSESSMENT" ? (
                          <div>
                            {props.AIRFLOW_CHAT ? (
                              <CardDiagFormHistoryAirFlow
                                controller={props.diagFormController}
                                hideCallback={handleModDiagHis}
                                userId={props.userId}
                                patientData={patientData}
                                patientAllergyData={patientAllergyData}
                                headerColor=""
                                hideHeaderIcon={true}
                                dividerStyle={{
                                  marginTop: "5px",
                                  marginBottom: "-8px",
                                }}
                                cardStyle={{ boxShadow: "none", border: 0 }}
                                // userId={props.userId}
                                division={props.division}
                                maxDiagFormId={queueChatRef.current?.getMaxDiagFormId?.()}
                                // apiToken={props.apiToken}
                                languageUX={props.languageUX}
                              />
                            ) : (
                              <CardDiagFormHistory
                                controller={props.diagFormController}
                                hideCallback={handleModDiagHis}
                                userId={props.userId}
                                patientData={patientData}
                                patientAllergyData={patientAllergyData}
                                headerColor=""
                                hideHeaderIcon={true}
                                dividerStyle={{
                                  marginTop: "5px",
                                  marginBottom: "-8px",
                                }}
                                cardStyle={{ boxShadow: "none", border: 0 }}
                                // userId={props.userId}
                                // division={props.division}
                                // apiToken={props.apiToken}
                                languageUX={props.languageUX}
                              />
                            )}
                          </div>
                        ) : activeItem === "PROGRESS_NOTE" ? (
                          <div>
                            <CardProgressNoteEdit
                              // hideCallback={closeModalProgressNote}
                              progressNoteController={
                                props.progressNoteController
                              }
                              apiToken={props.apiToken}
                              encounterId={encounterData.id}
                              emrId={encounterData.medical_record}
                              languageUX={props.languageUX}
                            />
                          </div>
                        ) : activeItem === "NURSE_NOTE" ? (
                          <div
                            style={{
                              height: "calc(100vh - 75px)",
                              overflowY: "auto",
                              overflowX: "hidden",
                            }}
                          >
                            <CardNurseNoteEdit
                              // hideCallback={closeModalNurseNote}
                              nurseNoteController={props.nurseNoteController}
                              apiToken={props.apiToken}
                              encounterId={encounterData.id}
                              division={props.division}
                              classifyController={props.classifyController}
                              userId={props.userId}
                              languageUX={props.languageUX}
                            />
                          </div>
                        ) : activeItem === "VITAL_SIGN" ? (
                          <div className="card-vital-sign">
                            <CardVitalSign
                              ref={cardVitalSignRef}
                              key="CardVitalSign"
                              controller={props.crdVitalSignController}
                              encounterId={encounterData.id}
                              patientId={patientData.id}
                              hideCallback={() => {
                                // this.handleOpenVitalSign();
                              }}
                              readOnly={false}
                              hideBarChart={true}
                              hideFilter={true}
                              hideFooter={true}
                              hideHeaderIcon={true}
                              headerColor={""}
                              cardStyle={{ boxShadow: "none", border: 0 }}
                              languageUX={props.languageUX}
                            />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "55px",
                                marginBottom: "35px",
                              }}
                            >
                              <Button
                                className="primary"
                                style={{ marginLeft: "10px" }}
                                onClick={() => {
                                  cardVitalSignRef.current.onValidateDuplicationSave?.();
                                }}
                              >
                                บันทึก
                              </Button>
                            </div>
                          </div>
                        ) : activeItem === "VACCINE_NOTE" ? (
                          <div
                            style={{
                              height: "calc(100vh - 82px)",
                              overflowY: "auto",
                              paddingBottom: "20px",
                            }}
                          >
                            <CardVaccineNote
                              cardStyle={{ boxShadow: "none", border: 0 }}
                              hideHeaderIcon={true}
                              patientData={patientData}
                              controller={props.controller}
                              apiToken={props.apiToken}
                              encounterId={encounterData.id}
                              division={props.division}
                              diagFormId={selectedPatient?.id}
                              languageUX={props.languageUX}
                            />
                          </div>
                        ) : activeItem === "PROFILE" ? (
                          <div
                            className="profile-form"
                            style={{
                              ...(selectedPatient?.id === NEW_PATIENT
                                ? { height: "96vh" }
                                : {}),
                            }}
                          >
                            <CardProfileForm
                              apiToken={props.apiToken}
                              division={props.division}
                              dashboardController={props.dashboardController}
                              classifyController={props.classifyController}
                              controller={props.controller}
                              diagRuleId={queueChatRef.current?.getDiagRuleId?.()}
                              triageLevel={
                                queueChatRef.current?.getSelectedTriage?.()
                                  ?.triageLevel
                              }
                              // Callback
                              onCreateSuccess={(id) =>
                                queueChatRef.current.handleGetChatChannel({
                                  newPatientId: id,
                                })
                              }
                              onEditSuccess={() =>
                                queueChatRef.current.handleGetChatChannel()
                              }
                              patientId={
                                selectedPatient?.id == NEW_PATIENT
                                  ? null
                                  : selectedPatient?.patient?.id
                              }
                              languageUX={props.languageUX}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </>
                ) : null}
              </Grid.Column>
              <Grid.Column
                width={3}
                style={{ padding: 0 }}
                className="chatDetail"
              >
                {
                  // (props.match && props.match.params && props.match.params.encounterId)
                  selectedPatient?.patient?.id ||
                  selectedPatient?.id === NEW_PATIENT ? (
                    <div className="card-patient-panel">
                      <CardPatientPanel
                        {...props}
                        ref={patientPanelRef}
                        controller={props.patientPanelController}
                        nurseNoteController={props.nurseNoteController}
                        progressNoteController={props.progressNoteController}
                        userId={props.userId}
                        division={props.division}
                        apiToken={props.apiToken}
                        patientData={
                          selectedPatient?.id === NEW_PATIENT
                            ? NEW_PATIENT_DATA
                            : patientData
                        }
                        encounterData={encounterData}
                        loading={patientLoading}
                        readOnly={readOnly}
                        triageLevelList={triageLevelList}
                        // hideNote={config.HIDE_NOTE}
                        hideNote={true}
                        showPatientProfileDownload={
                          !config.HIDE_PATIENT_PROFILE_DOWNLOAD
                        }
                        showConsentFormDownload={
                          !config.HIDE_CONSENT_FORM_DOWNLOAD
                        }
                        hideClassify={config.HIDE_CLASSIFY}
                        //Feature #54627
                        patientADR={props.patientADR}
                        languageUX={props.languageUX}
                        languageUX={props.languageUX}
                      />
                    </div>
                  ) : null
                }
              </Grid.Column>
            </>
          )
        }
      </Grid>

      {/* Modal Shared Link */}
      {openVaccineAppointment && (
        <Modal
          Modal
          open={true}
          size="tiny"
          style={{ padding: "20px" }}
          onClose={() => setOpenVaccineAppointment(false)}
          // closeIcon
        >
          <PatientSelectAppointment
            division={props.division}
            apiToken={props.apiToken}
            controller={props.queueController}
            diagFormId={selectedPatient?.id}
            onSuccess={() => {
              queueChatRef.current.handleGetChatChannel();
              setOpenVaccineAppointment(false);
            }}
            languageUX={props.languageUX}
          />
        </Modal>
      )}

      <ModConfirm
        openModal={openModAppoint}
        content={
          <>
            <ErrorMessage error={modAppointError} />
            <div style={{ textAlign: "center" }}>
              ต้องการเปิดนัดหมายให้ผู้ป่วยใช่หรือไม่
            </div>
          </>
        }
        onDeny={() => {
          setModAppointError(null);
          setOpenModAppoint(false);
        }}
        onApprove={handleOpenAppointment}
        loading={modAppointLoading}
        // error={modAppointError}
      />

      <ModConfirm
        openModal={openModFinishTreatment.open}
        titleName={intl.formatMessage({ id: "ยืนยันการจบการรักษา" })}
        onDeny={() => {
          setOpenModFinishTreatment(modInfoInitial);
        }}
        onApprove={handleFinishTreatment}
        loading={openModFinishTreatment.loading}
        content={<ErrorMessage error={openModFinishTreatment.error} />}
        // error={modAppointError}
      />

      <Modal open={openClassify} className="classify">
        <CardClassify
          controller={
            props.classifyFromDiagRule ? {
              getTriageLevelClassify: () => [
                {
                  classify: queueChatRef.current?.getClassifyFromDiagRule?.(),
                },
              ],
              updateTriage: (params: any) => props.controller.prxManager.patchDiagForm(params)
            } : props.classifyController}
          hideCallback={closeModalClassify}
          onSuccess={handleSuccessModalClassify}
          userId={props.userId}
          division={props.division}
          apiToken={props.apiToken}
          patientData={patientData}
          encounterData={encounterData}
          diagFormId={props.classifyFromDiagRule ? selectedPatient?.diag_form_id : selectedPatient?.id}
          // onSuccess={() => chatListRef.current.getChatList()}
          languageUX={props.languageUX}
        />
      </Modal>

      <Modal open={openDoctorQueue} size="tiny">
        <CardSendDoctorQueue
          // controller={props.classifyController}
          hideCallback={handleModDoctorQueue}
          // userId={props.userId}
          // division={props.division}
          // apiToken={props.apiToken}
          // patientData={patientData}
          languageUX={props.languageUX}
        />
      </Modal>

      <Modal
        open={openModDiagHis}
        closeOnDimmerClick
        onClose={handleModDiagHis}
        size="large"
      >
        <CardDiagFormHistory
          controller={props.diagFormController}
          hideCallback={handleModDiagHis}
          patientData={patientData}
          // userId={props.userId}
          // division={props.division}
          // apiToken={props.apiToken}
          languageUX={props.languageUX}
        />
      </Modal>

      <ModInfo
        open={openModInfo.open}
        titleColor={openModInfo.color || "red"}
        titleName={openModInfo.title}
        onApprove={handleCloseModInfo}
        onClose={handleCloseModInfo}
      >
        {openModInfo.error === "string" ? (
          <div>{openModInfo.error}</div>
        ) : (
          <ErrorMessage error={openModInfo.error} />
        )}
      </ModInfo>
    </div>
  );
});

export default QAChatTable;
