import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Dropdown
} from 'semantic-ui-react'

const CardSelectDateTimeOperationUX = (props: any) => {
    return(
      <div>
        <div
          style={{display: "flex" , marginBottom: "10px"}}>

          <div
            style={{marginRight: "10px"}}>

            <div>
              {props.doctorLabel}
            </div>
            <Dropdown
              className="inline-label"
              clearable={true}
              disabled={props.disabledDoctorOptions}
              onChange={props.handleChangeOption("doctor")}
              options={props.operationDoctorOptions}
              search={true}
              selection={true}
              value={props.operationDoctor}>
            </Dropdown>
          </div>
          <div
            style={{marginRight: "10px"}}>

            <div>
              {props.anesthesiaLabel}
            </div>
            <Dropdown
              className="inline-label"
              clearable={true}
              disabled={props.disabledAnesthesiaOptions || false}
              onChange={props.handleChangeOption("anesthesiologist")}
              options={props.ansDoctorOptions}
              search={true}
              selection={true}
              value={props.ansDoctor}>
            </Dropdown>
          </div>
          <div
            style={{marginRight: "10px"}}>

            <div>
              {props.operatingRoomLabel}
            </div>
            <div>
              {props.operatingRoomOptions}
            </div>
          </div>
          <div
            style={{marginRight: "10px"}}>

            <div>
              {"\u00a0"}
            </div>
            <div
              style={{ display: "flex", color: "rgb(6, 108, 187, 0.8)", border: "1px solid rgb(6, 108, 187, 0.8)", borderRadius: "5px", padding: "2px 8px", marginTop: "1rem",fontSize: "0.95rem", minWidth: "max-content"}}>
              {}
              <div
                style={{ fontWeight: "bold", marginRight: "10px" }}>
                {`Case: ${props.operatingCase || ""}`}
              </div>
              <div>
                {props.orOrderType || ""}
              </div>
            </div>
          </div>
        </div>
        <div>
          {props.selectCalendar}
        </div>
      </div>
    )
}

CardSelectDateTimeOperationUX.displayName = "CardSelectDateTimeOperationUX";
export default React.memo(CardSelectDateTimeOperationUX)

export const screenPropsDefault = {}

/* Date Time : Thu Sep 05 2024 21:57:36 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\" , marginBottom: \"10px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectCalendar"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"10px\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"10px\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"10px\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "Dropdown",
      "parent": 4,
      "props": {
        "className": {
          "type": "value",
          "value": "inline-label"
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledDoctorOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeOption(\"doctor\")"
        },
        "options": {
          "type": "code",
          "value": "props.operationDoctorOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.operationDoctor"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "Dropdown",
      "parent": 5,
      "props": {
        "className": {
          "type": "value",
          "value": "inline-label"
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledAnesthesiaOptions || false"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeOption(\"anesthesiologist\")"
        },
        "options": {
          "type": "code",
          "value": "props.ansDoctorOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ansDoctor"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "code",
          "value": "props.doctorLabel"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "code",
          "value": "props.anesthesiaLabel"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "code",
          "value": "props.operatingRoomLabel"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "code",
          "value": "props.operatingRoomOptions"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"10px\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", color: \"rgb(6, 108, 187, 0.8)\", border: \"1px solid rgb(6, 108, 187, 0.8)\", borderRadius: \"5px\", padding: \"2px 8px\", marginTop: \"1rem\",fontSize: \"0.95rem\", minWidth: \"max-content\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "code",
          "value": "`Case: ${props.operatingCase || \"\"}`"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", marginRight: \"10px\" }"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "code",
          "value": "props.orOrderType || \"\""
        }
      },
      "seq": 18,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": true,
  "name": "CardSelectDateTimeOperationUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
