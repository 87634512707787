import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Dropdown,
  Input,
  Button
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardStockManagementTabTransferUX = (props: any) => {
    return(
      <div
        style={{width:"100%", padding: "10px",  height: "100%"}}>
        <div
          className="ui form">
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label>
                ผู้ขอสินค้า
              </label>
            </FormField>
            <FormField
              inline={true}>
              <Dropdown
                disabled={props.readOnly || false}
                name="requester"
                onChange={props.onChangeValue}
                options={props.requesterDivisionOptions || []}
                search={true}
                selection={true}
                value={props.requester || ""}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}>
              <label>
                ผู้ให้สินค้า
              </label>
            </FormField>
            <FormField
              inline={true}>
              <Dropdown
                disabled={props.readOnly || false}
                name="provider"
                onChange={props.onChangeValue}
                options={props.providerDivisionOptions || []}
                search={true}
                selection={true}
                value={props.provider|| ""}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label>
                สินค้า
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Input
                fluid={true}
                readOnly={true}
                ref={(ref) => ref && (ref.inputRef.current.style.backgroundColor = "#DFDFDF",ref.inputRef.current.style.borderColor = "#DBDBDB")}
                style={{width: "100%"}}
                value={props.name || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}>
              <label>
                จำนวน
              </label>
            </FormField>
            <FormField
              inline={true}>
              <Input
                disabled={props.readOnly || false}
                name="quantity"
                onChange={props.onChangeValue}
                type="number"
                value={props.quantity || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}>
              <Button
                color="blue"
                disabled={props.disabledAdd || false}
                onClick={props.onAdd}>
                ADD
              </Button>
            </FormField>
          </FormGroup>
        </div>
        <div
          className="ui form">
          
          <Table
            data={props.stockTransferList || []}
            getTheadThProps={props.getTheadThProps}
            headers="Item code,Name,จำนวนขอ,หน่วย,ลบ"
            keys="code,name,quantity,unit,action"
            minRows={5}
            showPagination={false}
            style={{height: "250px"}}>
          </Table>
        </div>
        <div
          className="ui form"
          style={{marginTop: "1rem"}}>
          
          <FormGroup
            inline={true}>
            <div
              className="field inline"
              style={{flex: 1}}>
              
            </div>
            <FormField
              inline={true}>
              <div>
                {props.ButtonConfirm}
              </div>
            </FormField>
          </FormGroup>
        </div>
      </div>
    )
}

CardStockManagementTabTransferUX.displayName = "CardStockManagementTabTransferUX";
export default React.memo(CardStockManagementTabTransferUX)

export const screenPropsDefault = {}

/* Date Time : Thu Jul 06 2023 07:47:10 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width:\"100%\", padding: \"10px\",  height: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 2,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "label",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ขอสินค้า"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "label",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ให้สินค้า"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "Dropdown",
      "parent": 4,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.readOnly || false"
        },
        "name": {
          "type": "value",
          "value": "requester"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.requesterDivisionOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.requester || \"\""
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "Dropdown",
      "parent": 6,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.readOnly || false"
        },
        "name": {
          "type": "value",
          "value": "provider"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.providerDivisionOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.provider|| \"\""
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "FormField",
      "parent": 11,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "FormField",
      "parent": 11,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "FormField",
      "parent": 11,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "FormField",
      "parent": 11,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "label",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "สินค้า"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "label",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": "จำนวน"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "Input",
      "parent": 13,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "code",
          "value": ""
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "ref": {
          "type": "code",
          "value": "(ref) => ref && (ref.inputRef.current.style.backgroundColor = \"#DFDFDF\",ref.inputRef.current.style.borderColor = \"#DBDBDB\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.name || \"\""
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "Input",
      "parent": 15,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.readOnly || false"
        },
        "name": {
          "type": "value",
          "value": "quantity"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.quantity || \"\""
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "FormField",
      "parent": 11,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "Button",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": "ADD"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledAdd || false"
        },
        "onClick": {
          "type": "code",
          "value": "props.onAdd"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 23,
      "name": "Table",
      "parent": 22,
      "props": {
        "data": {
          "type": "code",
          "value": "props.stockTransferList || []"
        },
        "getTheadThProps": {
          "type": "code",
          "value": "props.getTheadThProps"
        },
        "headers": {
          "type": "value",
          "value": "Item code,Name,จำนวนขอ,หน่วย,ลบ"
        },
        "keys": {
          "type": "value",
          "value": "code,name,quantity,unit,action"
        },
        "minRows": {
          "type": "code",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"250px\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"1rem\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "FormGroup",
      "parent": 24,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "FormField",
      "parent": 25,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "field inline"
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonConfirm"
        }
      },
      "seq": 29,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": true,
  "name": "CardStockManagementTabTransferUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
