import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  FormField,
  Button
} from 'semantic-ui-react'

const ANES_ChargeItem_Tab = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        id="CardAnesBillTab">
        <Form>
          <FormGroup
            inline={true}
            style={{marginTop: "1rem"}}>
            <FormField>
              <label
                style={{fontSize: "1.25rem", marginLeft: "1.5rem"}}>
                ชุดรายการค่าใช้จ่าย
              </label>
            </FormField>
            <FormField
              style={{flex:1}}>
            </FormField>
            <FormField>
              <div>
                {props.buttonRefresh}
              </div>
            </FormField>
            <FormField>
              <Button
                color="blue"
                onClick={props.onSelectTemplate}>
                เลือก Template
              </Button>
            </FormField>
          </FormGroup>
        </Form>
        <Form>
          <FormGroup
            inline={true}
            style={{padding : "0 15px"}}>
            <FormField
              inline={true}
              width={6}>
              <Button
                color={props.tab === "Drug" ? "blue" : "white"}
                name="Drug"
                onClick={props.onChangeTab}
                style={{width: "100%"}}>
                รายการยา
              </Button>
            </FormField>
            <FormField
              inline={true}
              width={6}>
              <Button
                color={props.tab === "Supply" ? "blue" : "white"}
                name="Supply"
                onClick={props.onChangeTab}
                style={{width: "100%"}}>
                รายการเวชภัณฑ์
              </Button>
            </FormField>
            <FormField
              inline={true}
              style={{padding:0}}
              width={6}>
              <Button
                color={props.tab === "Operative" ? "blue" : "white"}
                name="Operative"
                onClick={props.onChangeTab}
                style={{width: "100%"}}>
                รายการหัตถการ
              </Button>
            </FormField>
          </FormGroup>
        </Form>
        <hr>
        </hr>
      </div>
    )
}


export default ANES_ChargeItem_Tab

export const screenPropsDefault = {}

/* Date Time : Fri Dec 08 2023 15:49:53 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardAnesBillTab"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 12,
      "name": "hr",
      "parent": 0,
      "props": {
      },
      "seq": 37,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "FormGroup",
      "parent": 20,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"1rem\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "FormField",
      "parent": 21,
      "props": {
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "FormField",
      "parent": 21,
      "props": {
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "FormField",
      "parent": 21,
      "props": {
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "FormField",
      "parent": 21,
      "props": {
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "label",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": "ชุดรายการค่าใช้จ่าย"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.25rem\", marginLeft: \"1.5rem\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 24,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonRefresh"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "Button",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": "เลือก Template"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSelectTemplate"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "FormGroup",
      "parent": 37,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding : \"0 15px\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "FormField",
      "parent": 38,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "FormField",
      "parent": 38,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "FormField",
      "parent": 38,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding:0}"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "Button",
      "parent": 39,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการยา"
        },
        "color": {
          "type": "code",
          "value": "props.tab === \"Drug\" ? \"blue\" : \"white\""
        },
        "name": {
          "type": "value",
          "value": "Drug"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeTab"
        },
        "size": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "Button",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการเวชภัณฑ์"
        },
        "color": {
          "type": "code",
          "value": "props.tab === \"Supply\" ? \"blue\" : \"white\""
        },
        "name": {
          "type": "value",
          "value": "Supply"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeTab"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "Button",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการหัตถการ"
        },
        "color": {
          "type": "code",
          "value": "props.tab === \"Operative\" ? \"blue\" : \"white\""
        },
        "name": {
          "type": "value",
          "value": "Operative"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeTab"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 44,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "ANES_ChargeItem_Tab",
  "project": "IsHealth_by_Front-end",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
