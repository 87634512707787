import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button,
  Form,
  FormGroup,
  FormField,
  Dropdown,
  Input
} from 'semantic-ui-react'

const CardFallRiskAssessmentUX = (props: any) => {
    return(
      <div
        style={{padding: "15px"}}>
        <div
          style={{display: "flex" }}>
          
          <div
            style={{padding: "5px", fontSize: "1.2rem", fontWeight: "bold"}}>
            Fall Risk Assessment
          </div>
          <div
            style={{flex: 1}}>
            
          </div>
        </div>
        <Table
          data={props.data}
          headers="Date,User,Fall Risk Assessment,Nursing,Delete"
          keys="date,user_fullname,fall_risk_description,nursing,delete"
          minRows="3"
          showPagination={false}
          style={{height: "150px"}}>
        </Table>
        <div
          style={{ margin: "15px 25px" }}>
          
          <Form>
            <FormGroup
              inline={true}
              style={{ ...(props.version === "0.2" && { display: "none" }) }}>
              <FormField
                inline={true}
                width={3}>
                <div>
                  Fall Risk Assessment
                </div>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <div
                  style={{ width: "100%" }}>
                  
                  <Dropdown
                    fluid={true}
                    onChange={props.changeFallRisk("fall_risk")}
                    options={props.fallRiskOptions}
                    selection={true}
                    value={props.fall_risk}>
                  </Dropdown>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{ ...(props.version !== "0.2" && { display: "none" }) }}>
              <FormField
                inline={true}
                width={3}>
                <div>
                  Fall Risk Assessment
                </div>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <div
                  style={{ width: "100%" }}>
                  
                  <Dropdown
                    compact={true}
                    fluid={true}
                    onChange={props.changeFallRisk("fall_riskV2")}
                    options={props.fallRiskOptions}
                    selection={true}
                    value={props.fall_riskV2}>
                  </Dropdown>
                </div>
              </FormField>
              <FormField
                inline={true}
                style={{ ...(props.fall_riskV2 !== "HIG" && { display: "none" })  }}
                width={4}>
                <div
                  style={{ width: "100%" }}>
                  
                  <Dropdown
                    fluid={true}
                    multiple={true}
                    onChange={props.changeFallRisk("fall_riskHigValue")}
                    options={props.fallRiskHigOptions}
                    selection={true}
                    value={props.fall_riskHigValue}>
                  </Dropdown>
                </div>
              </FormField>
              <FormField
                inline={true}
                style={{ ...(!(props.fall_riskV2 === "HIG" && props.fall_riskHigValue.includes(9)) && { display: "none" })  }}
                width={3}>
                <div
                  style={{ width: "100%", display: "flex", alignItems: "center" }}>
                  
                  <div
                    style={{ marginRight: "10px" }}>
                    ระบุ
                  </div>
                  <div
                    style={{ width: "100%" }}>
                    
                    <Input
                      onChange={props.changeFallRisk("fall_riskText")}
                      value={props.fall_riskText}>
                    </Input>
                  </div>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={3}>
                <div>
                  การให้การพยาบาลผู้ป่วย
                </div>
              </FormField>
              <FormField
                inline={true}
                width={9}>
                <div
                  style={{ width: "100%" }}>
                  
                  <Dropdown
                    compact={true}
                    fluid={true}
                    multiple={true}
                    onChange={props.changeFallRisk("nursing")}
                    options={props.fallRiskNursingOptions}
                    selection={true}
                    value={props.nursing}>
                  </Dropdown>
                </div>
              </FormField>
            </FormGroup>
          </Form>
        </div>
        <div
          style={{ display: "flex", justifyContent: "flex-end", margin: "0px 15px" }}>
          
          <Button
            color="blue"
            onClick={props.onReassess}>
            Reassessment
          </Button>
        </div>
      </div>
    )
}


export default CardFallRiskAssessmentUX

export const screenPropsDefault = {}

/* Date Time : Thu Mar 07 2024 18:08:52 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding: \"15px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "Fall Risk Assessment"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"5px\", fontSize: \"1.2rem\", fontWeight: \"bold\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 17,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.data"
        },
        "headers": {
          "type": "value",
          "value": "Date,User,Fall Risk Assessment,Nursing,Delete"
        },
        "keys": {
          "type": "value",
          "value": "date,user_fullname,fall_risk_description,nursing,delete"
        },
        "minRows": {
          "type": "value",
          "value": "3"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"150px\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"flex-end\", margin: \"0px 15px\" }"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "Button",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": "Reassessment"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onReassess"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"15px 25px\" }"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "Form",
      "parent": 43,
      "props": {
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "FormGroup",
      "parent": 44,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ ...(props.version !== \"0.2\" && { display: \"none\" }) }"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "FormGroup",
      "parent": 44,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "FormField",
      "parent": 45,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "FormField",
      "parent": 45,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "FormField",
      "parent": 46,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "FormField",
      "parent": 46,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "9"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 47,
      "props": {
        "children": {
          "type": "value",
          "value": "Fall Risk Assessment"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "div",
      "parent": 48,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 49,
      "props": {
        "children": {
          "type": "value",
          "value": "การให้การพยาบาลผู้ป่วย"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "div",
      "parent": 50,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "Dropdown",
      "parent": 54,
      "props": {
        "compact": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeFallRisk(\"nursing\")"
        },
        "options": {
          "type": "code",
          "value": "props.fallRiskNursingOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.nursing"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 56,
      "name": "Dropdown",
      "parent": 52,
      "props": {
        "compact": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeFallRisk(\"fall_riskV2\")"
        },
        "options": {
          "type": "code",
          "value": "props.fallRiskOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.fall_riskV2"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "FormField",
      "parent": 45,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ ...(props.fall_riskV2 !== \"HIG\" && { display: \"none\" })  }"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 57,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "Dropdown",
      "parent": 58,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeFallRisk(\"fall_riskHigValue\")"
        },
        "options": {
          "type": "code",
          "value": "props.fallRiskHigOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.fall_riskHigValue"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "FormField",
      "parent": 45,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ ...(!(props.fall_riskV2 === \"HIG\" && props.fall_riskHigValue.includes(9)) && { display: \"none\" })  }"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "div",
      "parent": 60,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "div",
      "parent": 61,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": null,
      "id": 64,
      "name": "div",
      "parent": 61,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "Input",
      "parent": 64,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changeFallRisk(\"fall_riskText\")"
        },
        "value": {
          "type": "code",
          "value": "props.fall_riskText"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "FormGroup",
      "parent": 44,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ ...(props.version === \"0.2\" && { display: \"none\" }) }"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "FormField",
      "parent": 66,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "FormField",
      "parent": 66,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "div",
      "parent": 67,
      "props": {
        "children": {
          "type": "value",
          "value": "Fall Risk Assessment"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "div",
      "parent": 68,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "Dropdown",
      "parent": 70,
      "props": {
        "compact": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeFallRisk(\"fall_risk\")"
        },
        "options": {
          "type": "code",
          "value": "props.fallRiskOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.fall_risk"
        }
      },
      "seq": 71,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 60,
  "isMounted": false,
  "memo": false,
  "name": "CardFallRiskAssessmentUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 75
}

*********************************************************************************** */
