import React, { useState, useRef, useEffect, memo } from "react";
import {
  Segment,
  Button,
  Dimmer,
  Loader,
  Radio,
  Modal
} from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import ModInfo from "react-lib/apps/common/ModInfo";

const LANGUAGE = {
  TH: "th-TH",
  EN_US: "en-US"
};

const ConsentForm = props => {
  const [answer, setAnswer] = useState({});
  const [diagRuleContent, setDiagRuleContent] = useState({});
  const [showModalIndex, setShowModalIndex] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [modalDetail, setModalDetail] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [formLanguage, setFormLanguage] = useState(null);
  const [currentForm, setCurrentForm] = useState({});
  const [division, setDivision] = useState({});
  const [diagFormId, setDiagFormId] = useState(null);
  const [editAnswer, setEditAnswer] = useState({});
  const [userLang, setUserLang] = useState(null);
  const [formLangText, setFormLangText] = useState("th-TH")
  const isMounted = useRef(true);
  const history = useHistory();

  useEffect(() => {
    handleSetDefaultLang();
    if(props.match.params.page || (props.match.params.page !== 1)){
      history.push("/consent-form/" + props.match.params.id + "/")
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!props.apiToken && !Cookies.get("apiToken")) {
      props.noApiToken();
    }
  }, [props.apiToken, Cookies.get("apiToken")]);


  useEffect(() => {
    if (
      props.match &&
      props.match.params.id &&
      (props.apiToken || Cookies.get("apiToken"))
    ) {
      getPublishDiagRuleDetail({ publishDiagRuleId: props.match.params.id });
    }
    if (props.apiToken || Cookies.get("apiToken")) {
      getProxyPatientHasDivision();
    }
  }, [props.match.params.id, props.apiToken, Cookies.get("apiToken")]);

  useEffect(() => {
    if (diagFormId) {
      getDiagFormDetail();
    }
  }, [diagFormId]);

  useEffect(() => {
    if (division.id) {
      getPublishDiagRuleCheck();
    }
  }, [division.id]);

  useEffect(() => {
    setShowModalIndex(null);
  }, [props.match.params.page]);

  useEffect(() => {
    if (Cookies.get("formLang")) {
      return setFormLanguage(Cookies.get("formLang"));
    }
    if (Object.keys(diagRuleContent).length > 1) {
      let obj = Object.values(diagRuleContent).find(
        item => item.parent_id === diagRuleContent[0].id
      );
      if (obj) {
        setFormLanguage(obj.id);
      }
    } else {
      setCurrentForm(diagRuleContent[0]);
    }
  }, [diagRuleContent, Cookies.get("formLang")]);

  useEffect(() => {
    if (Object.keys(diagRuleContent).length > 1) {
      let obj = Object.values(diagRuleContent).find(
        item => item.id == formLanguage
      );
      if (obj) {
        setCurrentForm(obj);
        props.onFormLanguageChange(obj.label)
        let language = "th-TH"
        if(obj.label && typeof(obj.label) === "string" && ["en", "en-us", "eng", "english"].includes(obj.label.toLowerCase())){
          language = "en-US"
        }
        setFormLangText(language)
      }
    } 
    else {
      setCurrentForm(diagRuleContent[0]);
    }
  }, [formLanguage, diagRuleContent]);

  useEffect(() => {
    let firstAnswer = editAnswer && editAnswer[0] ? editAnswer[0].answer : null;
    let obj = Object.values(editAnswer).find(
      item => item.label === firstAnswer
    );
    
    if (
      currentForm &&
      currentForm.extra_items &&
      (!obj || currentForm.id != obj.id)
    ) {
      let defaultAnswer = {};
      currentForm.extra_items.forEach((item, index) => {
        if (item.type === "Select" && item.default) {
          defaultAnswer[item.label] = item.default;
        }
      });
      setAnswer(defaultAnswer);
    } else {
      handleSetEditAnswer();
    }
  }, [currentForm, editAnswer]);
  
  useEffect(() => {
    if(Object.keys(editAnswer).length === 0){
      if (userLang === "th-TH") {
        let obj = Object.values(diagRuleContent).find(
          item => item.label === "ไทย"
        );
        if(obj){
          return setFormLanguage(obj.id);
        }
      } else if (userLang === "en-US") {
        let obj = Object.values(diagRuleContent).find(
          item => item.label.toLowerCase() === "English".toLowerCase()
        );
        if(obj){
          return setFormLanguage(obj.id);
        }
      }
    } else {
      let obj = Object.values(diagRuleContent).find(
        item => ((editAnswer[0]) && item.label === editAnswer[0].answer)
      );
      if(obj){
        setFormLanguage(obj.id)
      }
    }
  }, [diagRuleContent, userLang, editAnswer]);

  const handleSetDefaultLang = () => {
    if (navigator && !Cookies.get("formLang")) {
      // var userLang = navigator.language || navigator.userLanguage;
      var userLang = navigator.languages[0];
      if (userLang.toLowerCase().includes("en")) {
        userLang = LANGUAGE.EN_US;
      } else if (userLang.toLowerCase().includes("th")) {
        userLang = LANGUAGE.TH;
      } else {
        userLang = LANGUAGE.EN_US;
      }
      if (userLang) {
        setUserLang(userLang)
      }
    }
  };

  const handleSetEditAnswer = () => {
    if (Object.keys(editAnswer).length > 0) {
      let firstAnswer = editAnswer[0].answer;
      let obj = Object.values(editAnswer).find(
        item => item.label === firstAnswer
      );
      setFormLanguage(obj.id);
      Cookies.set("formLang", obj.id);
      // setCookies("formLang", obj.id);
      let newObj = {};
      if (obj) {
        for (let item of obj.extra_items) {
          newObj[item.label] = item.value;
        }
        setAnswer(newObj);
      } else {
        setAnswer(editAnswer);
      }
    } else {
      setAnswer(editAnswer);
    }
  };

  const getProxyPatientHasDivision = async () => {
    setIsLoading(true);
    const [
      response,
      error,
      network
    ] = await props.controller.getProxyPatientHasDivision({
      apiToken: Cookies.get("apiToken") ? Cookies.get("apiToken") : props.apiToken
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (response) {
        if (response.items.length > 0 && response.items[0].division) {
          setDivision(response.items[0].division);
        }
      }
    }
  };

  const getPublishDiagRuleCheck = async () => {
    setIsLoading(true);
    const [
      response,
      error,
      network
    ] = await props.controller.getPublishDiagRuleCheck({
      apiToken: Cookies.get("apiToken") ? Cookies.get("apiToken") : props.apiToken,
      divisionId: division.id,
      code: "CS"
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (response) {
        if (response.diag_form) {
          setDiagFormId(response.diag_form);
        }
      }
    }
  };

  const getPublishDiagRuleDetail = async () => {
    setIsLoading(true);
    const [
      response,
      error,
      network
    ] = await props.controller.getPublishDiagRuleDetail({
      publishDiagRuleId: props.match.params.id,
      apiToken: Cookies.get("apiToken") ? Cookies.get("apiToken") : props.apiToken
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (response && response.content) {
        let json = JSON.parse(response.content);
        setDiagRuleContent(json);
      } else {
        setDiagRuleContent({});
        if (error.detail) {
          if (typeof window.iosNative !== "undefined") {
            window.iosNative.showToast(error.detail);
          } else if (typeof window.MobNative !== "undefined") {
            window.MobNative.showToast(error.detail);
          }
        }
      }
    }
  };

  const getDiagFormDetail = async () => {
    setIsLoading(true);
    const [response, error, network] = await props.controller.getDiagFormDetail(
      {
        diagFormId,
        apiToken: Cookies.get("apiToken") ? Cookies.get("apiToken") : props.apiToken
      }
    );
    if (isMounted.current) {
      setIsLoading(false);
      if (response && response.answers) {
        let ans = JSON.parse(response.answers);
        setEditAnswer(ans);
      }
    }
  };

  const createFirstPageChoices = () => {
    let choices = Object.values(diagRuleContent).filter(
      item => item.parent_id === diagRuleContent[0].id
    );
    return (
      <div className="content-item">
        <h3>{diagRuleContent[0].label}</h3>
        <div className="answer-options" style={{ textAlign: "left" }}>
          {choices.map((item, index) => {
            return (
              <div key={item.label + index}>
                <div>
                  <Radio
                    label={item.label}
                    name={item.label}
                    value={item.id}
                    checked={formLanguage == item.id}
                    onChange={(e, { checked, value }) => {
                      setFormLanguage(value);
                      Cookies.remove("fromLang")
                      Cookies.set("formLang", value)
                      // removeCookie("formLang");
                      // setCookies("formLang", value);
                    }}
                  />
                  &ensp;
                </div>
                <br />
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const createContent = () => {
    if (
      (!props.match.params.page || props.match.params.page == 1) &&
      Object.keys(diagRuleContent).length > 1
    ) {
      return createFirstPageChoices(); // For handle select language
    }
    if (!currentForm || !currentForm.extra_items) {
      return;
    }
    return currentForm.extra_items
      .filter(item => {
        if (Object.keys(diagRuleContent).length > 1) {
          return (
            item.page ==
            (props.match.params.page
              ? parseInt(props.match.params.page) - 1
              : 0)
          );
        } else {
          return (
            item.page ==
            (props.match.params.page ? parseInt(props.match.params.page) : 1)
          );
        }
      })
      .map((item, index) => {
        if (item.type === "Modal") {
          return <React.Fragment key={index} />;
        }
        return (
          <div key={index} className="content-item">
            <div dangerouslySetInnerHTML={{ __html: item.label }} />
            <div className="answer-options">
              {item.type === "Select" &&
                item.options.map((optionItem, optionIndex) => {
                  return (
                    <span key={item.label + optionIndex}>
                      <Radio
                        label={optionItem}
                        name={item.label}
                        value={optionItem}
                        checked={answer[item.label] && answer[item.label] === optionItem}
                        onChange={(e, { checked, value }) => {
                          if (
                            item.showModalIfNotOk &&
                            (value === "ไม่ยินยอม" || value === "Refuse")
                          ) {
                            setShowModalIndex(index);
                          } else {
                            setShowModalIndex(null);
                          }
                          setAnswer({
                            ...answer,
                            [item.label]: value
                          });
                        }}
                      />
                      &ensp;
                    </span>
                  );
                })}
            </div>
            <br />
          </div>
        );
      });
  };

  const handleNextPage = () => {
    if (showModalIndex || showModalIndex === 0) {
      let arrAfterItem = currentForm.extra_items.slice(showModalIndex + 1);
      let modalObj = arrAfterItem.find(item => item.type === "Modal");
      setModalDetail(modalObj);
      setOpenModal(true);
    } else {
      goToNextPage();
    }
  };

  const goToNextPage = () => {
    let nextPage =
      (props.match.params.page ? parseInt(props.match.params.page) : 1) + 1;
    if (nextPage <= diagRuleContent[0].total_page) {
      history.push(`/consent-form/${props.match.params.id}/${nextPage}`);
    } else {
      handleFinishForm();
    }
  };

  const handleFinishForm = async ({ finished } = { finished: true }) => {
    setIsLoading(true);
    const [res, err] = await props.controller.createDiagForm(
      props.apiToken ? props.apiToken : Cookies.get("apiToken"),
      props.patientData.patient, //patientId
      props.match.params.id //DiagFormId
    );
    let formatAns = { ...diagRuleContent };
    formatAns[0].answer = currentForm.label;
    formatAns[currentForm.id].selected = true;
    formatAns[currentForm.id].extra_items = [];
    formatAns[currentForm.id].extra_finished = true;
    for (let ansItem of Object.keys(answer)) {
      formatAns[currentForm.id].extra_items.push({
        label: ansItem,
        value: answer[ansItem]
      });
    }
    if (res.id) {
      // res.id == formId != diagFormId
      let params = {
        apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
        diagFormId: res.id,
        answers: JSON.stringify(formatAns),
        text: formatAns[currentForm.id].text,
        finished
      };
      const [response, error] = await props.controller.updateDiagForm(params);
      setIsLoading(false);
      if (response) {
        // setOpenSuccessModal(true);
        if (typeof window.iosNative !== "undefined" && finished) {
          return window.iosNative.diagConfirm();
        } else if (typeof window.MobNative !== "undefined" && finished) {
          return window.MobNative.diagConfirm();
        } else if (finished) {
          return history.push("/");
        } else {
          return;
        }
      }
    } else {
      setIsLoading(false);
      return;
    }
  };

  const handleModalButtonClick = async e => {
    const { textContent } = e.target;
    if (textContent === "ไม่ใช่" || textContent === "No") {
      setOpenModal(false);
    } else if (textContent === "ใช่" || textContent === "Yes") {
      if (typeof window.iosNative !== "undefined") {
        await handleFinishForm({ finished: false });
        window.iosNative.diagUnConfirm();
      } else if (typeof window.MobNative !== "undefined") {
        await handleFinishForm({ finished: false });
        window.MobNative.diagUnConfirm();
      } else {
        await handleFinishForm({ finished: false });
        setOpenModal(false);
        history.push("/");
      }
      // let modalAnswer = { ...answer };
      // modalAnswer[modalDetail.label] = e.target.textContent;
      // setAnswer(modalAnswer);
      // goToNextPage();
    }
  };

  return (
    <div className="consent-form">
      <ModInfo
        open={openSuccessModal}
        type="success"
        duration={500}
        onClose={() => {
          setOpenSuccessModal(false);
          if (typeof window.iosNative !== "undefined") {
            window.iosNative.diagConfirm();
          } else if (typeof window.MobNative !== "undefined") {
            window.MobNative.diagConfirm();
          } else {
            history.push("/");
          }
        }}
      />
      <Modal open={openModal} className="mod-consent-form">
        <div dangerouslySetInnerHTML={{ __html: modalDetail.label }} />
        <br />
        <div className="btn-group">
          {modalDetail.options &&
            modalDetail.options.map((optionItem, index) => {
              return (
                <Button
                  key={index}
                  content={optionItem}
                  fluid
                  onClick={handleModalButtonClick}
                  loading={isLoading}
                />
              );
            })}
        </div>
      </Modal>
      {props.menuBar}
      <Dimmer.Dimmable>
        <Dimmer active={isLoading} inverted>
          <Loader />
        </Dimmer>
        <div className="content">{createContent()}</div>
        <Segment>
          <Button
            fluid
            content={
              diagRuleContent[0] &&
              diagRuleContent[0].total_page == props.match.params.page
                ? formLangText === "en-US" ? "Done" : "เสร็จสิ้น"
                : formLangText === "en-US" ? "Next" : "ต่อไป"
            }
            onClick={handleNextPage}
            loading={isLoading}
            disabled={isLoading}
          />
          <div>
            <span>{`${
              props.match.params.page ? parseInt(props.match.params.page) : 1
            }/${
              diagRuleContent[0] && diagRuleContent[0].total_page
                ? diagRuleContent[0].total_page
                : 0
            }`}</span>
          </div>
        </Segment>
      </Dimmer.Dimmable>
    </div>
  );
};

ConsentForm.defaultProps = {
  onFormLanguageChange: () => {},
}

export default memo(ConsentForm);
