import React, { SyntheticEvent, useEffect, useRef, useState } from "react";
import { Input, Modal, Button, Dropdown } from "semantic-ui-react";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import { useIntl } from "react-intl";

const ModDrugDuplicateGroup = (props: any) => {
  const intl = useIntl();
  const [duplicateNotes, setDuplicateNotes] = useState<any[]>([]);
  const [options, setOptions] = useState<any[]>([])

  useEffect(() => {
    setOptions(props.duplicateReasonsOptions || []);
  }, [props.duplicateReasonsOptions]);

  const handleConfirm = () => {
    console.log("duplicateNotes: ", duplicateNotes);
    let mapping = duplicateNotes?.map((i) => ({
      ...i,
      duplicated_detail: options.find((j: any) => j.value === i.duplicated_detail).text,
    }));

    props.onApprove?.({
      duplicatedNotes: mapping,
    });
  };

  useEffect(() => {
    if (props?.modDuplicateDrug?.duplicateList?.length > 0) {
      console.log("duplicateNotes: ", duplicateNotes);
      setDuplicateNotes(
        Array(props?.modDuplicateDrug?.duplicateList?.length).fill("")
      );
    }
  }, [props?.modDuplicateDrug?.duplicateList]);

  const handleAddOptions = (e: SyntheticEvent, v: any) => {
    setOptions([...options, { key: v.value, text: v.value, value: v.value }]);
  };

  console.log(" props.modDuplicateDrug" ,props.modDuplicateDrug)
  return (
    <>
      <ModConfirm
        openModal={props.modDuplicateDrug?.open}
        titleName={intl.formatMessage({ id: "พบรายการสั่งยาซ้ำตาม Drug Classification" })}
        titleColor={"orange"}
        size="small"
        approveButtonText={intl.formatMessage({ id: "ยืนยัน" })}
        denyButtonText={intl.formatMessage({ id: "ยกเลิก" })}
        onApprove={handleConfirm}
        disabledApproveButton={duplicateNotes?.some((i) => !i.duplicated_detail)}
        onDeny={props.onClose}
        onCloseWithDimmerClick={props.onClose}
        content={
          <>
            {props.modDuplicateDrug?.duplicateList?.map(
              (error: any, idx: number) => {
                return (
                  <>
                    {idx !== 0 ? <br /> : null}
                    {/* <ErrorMessage error={error} /> */}
                    {(error?.duplicate_list || []).map((item: any) => (
                      <div className="ui" style={{ whiteSpace: "pre-wrap", lineHeight: 1.75 }}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item?.duplicated_detail,
                          }}
                        />
                      </div>
                    ))}
                    <br />
                    หากต้องการยืนยันการสั่ง กรุณาใส่เหตุผล
                    <Dropdown
                      placeholder={intl.formatMessage({ id: "กรุณาระบุเหตุผลการสั่งซ้ำ" })}
                      value={duplicateNotes?.[idx]?.duplicated_detail}
                      selection={true}
                      fluid
                      search
                      options={options}
                      allowAdditions
                      onAddItem={handleAddOptions}
                      onChange={(e, v) => {
                        let newArr: any[] = [...duplicateNotes];
                        newArr[idx] = {
                          product: props.modDuplicateDrug?.duplicateList?.[idx]?.drugId,
                          duplicated_detail: v.value,
                        };
                        setDuplicateNotes(newArr);
                      }}
                    />
                    <br />
                  </>
                );
              }
            )}
          </>
        }
      />
    </>
  );
};

export default ModDrugDuplicateGroup;
