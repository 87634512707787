import WasmController from 'react-lib/frameworks/WasmController';
import DoctorWorkAtDivList from "issara-sdk/apis/DoctorWorkAtDivList_apps_APP";
import AppointmentList from "issara-sdk/apis/AppointmentList_apps_APP";
import AppointmentDetail from "issara-sdk/apis/AppointmentDetail_apps_APP";
import AppointmentRequestList from "issara-sdk/apis/AppointmentRequestList_apps_APP";
import AppointmentCancel from "issara-sdk/apis/AppointmentCancel_apps_APP";
import DoctorNoteTemplateList from "issara-sdk/apis/DoctorNoteTemplateList_apps_APP";
import DoctorNoteTemplateGroupList from "issara-sdk/apis/DoctorNoteTemplateGroupList_apps_APP";
import DoctorScheduleAppointmentList from "issara-sdk/apis/DoctorScheduleAppointmentList_apps_APP";
import AppointmentPrinting from "issara-sdk/apis/AppointmentPrinting_apps_APP";
import _ from "react-lib/compat/lodashplus";

export type State = 
  {
  }

export const StateInitial: State = 
  {
  }

export type Event = 
  { message: "getAppointmentWithId", params: any }
  |  { message: "deleteAppointmentWithId", params: any }
  |  { message: "cancelAppointment", params: any }
  |  { message: "updateAppointmentWithId", params: any }
  |  { message: "postponeAppointmentWithId", params: any }
  |  { message: "getDoctorWorkAtDivision", params: any }
  |  { message: "convertDoctorScheduleToChoice", params: any }
  |  { message: "getAppointmentOrder", params: any }
  |  { message: "getAppointmentOrderRaw", params: any }
  |  { message: "getDoctorNote", params: any }
  |  { message: "getDoctorNoteGroup", params: any }
  |  { message: "getDoctorScheduleAppointment", params: any }
  |  { message: "postAppointmentRequest", params: any }
  |  { message: "printAppointment", params: any }
  |  { message: "_convertToAppointmentModel", params: any }

export type Data = 
  {
    division?: number,
  }

export const DataInitial = 
  {
  }

export const APPOINTMENT_MODEL = {
  id: null,
  action: "REQUEST",
  patient: null,
  order_encounter: null,
  doctor: "",
  date: null,
  start_time: "",
  end_time: "",
  extend_queue: false,
  note_to_nurse: "",
  note_to_patient: "",
  doctor_note: "",
  from_schedule: null,
  from_schedule_detail: "",
  from_schedule_doctor_name: "",
  is_editable: false,
  from_follow_up: 0,
  repeat_number: 0,
  repeat_frequency: 0,
  status: "",
  order_dict: {},
  children: {}
};

export const STATUS_REQUESTED = 6

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any 

export const getAppointmentWithId: Handler = async (controller, appointmentId) => {
  return await AppointmentDetail.retrieve({
    pk: appointmentId,
    apiToken: controller.apiToken
  });
}

export const deleteAppointmentWithId: Handler = async (controller, app = APPOINTMENT_MODEL) => {
  let params = _convertToAppointmentModel(controller, app);
  return await AppointmentDetail.delete({
    pk: app.id,
    params: params,
    apiToken: controller.apiToken
  });
}

export const cancelAppointment: Handler = async (controller, {appointmentId, params}) => {
  return await AppointmentCancel.update({
    pk: appointmentId,
    data: params
  });
}

export const updateAppointmentWithId: Handler = async (controller, app = APPOINTMENT_MODEL) => {
  let params = _convertToAppointmentModel(controller, app);
  return await AppointmentDetail.update({
    pk: app.id,
    data: params,
    apiToken: controller.apiToken
  });
}

export const postponeAppointmentWithId: Handler = async (controller, {}) => {
//function (app) {
//            if (app === void 0) { app = exports.APPOINTMENT_MODEL; }
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, appService, _a, error, response;
//                return __generator(this, function (_b) {
//                    switch (_b.label) {
//                        case 0:
//                            params = this._convertToAppointmentModel(app);
//                            appService = new APPService_1.default();
//                            return [4 /*yield*/, utils_1.to(appService.postponeAppointmentWithId(app.id, params))];
//                        case 1:
//                            _a = _b.sent(), error = _a[0], response = _a[1];
//                            return [2 /*return*/, [response, error]];
//                    }
//                });
//            });
//        }
//function (appointmentId, params) {
//        var url = apis_1.APP.APPOINTMENT_ID({ app_id: appointmentId });
//        return this.client
//            .put(url, params)
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/APP/appointment/' +
//((__t = (app_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const getDoctorWorkAtDivision: Handler = async (controller, divisionId) => {
  const result = await DoctorWorkAtDivList.get({
    params: { division: divisionId },
    apiToken: controller.apiToken
  });
  return [result[0].items || [], result[1]]
}

export const convertDoctorScheduleToChoice: Handler = async (controller, doctorList) => {
  var choices = [];
  if (doctorList) {
    if (doctorList.length > 0) {
      choices = _.map(doctorList, (item: any) => ({
        id: item.id,
        text: item.name,
        value: item.code,
      }));
    }
  }
  return choices;
}

export const getAppointmentOrder: Handler = async (controller, 
  {
    patientId = null,
    type = null,
    appointment_type = null,
    excludeCanceled = true,
    requestFlag,
    excludePostponed,
    excludeFinished,
    status=null,
  }) => {
  let params: any = {
      exclude_canceled: excludeCanceled,
  };
  if (patientId != null) {
      params.patient = patientId;
  }
  if (type != null) {
      params.type = type;
  }
  if (appointment_type != null) {
      params.appointment_type = appointment_type;
  }
  if (requestFlag != null) {
      params.request_flag = requestFlag;
  }
  if (excludePostponed != null) {
      params.exclude_postponed = excludePostponed;
  }
  if (excludeFinished != null) {
      params.exclude_finished = excludeFinished;
  }
  if (status != null) {
      params.status = status;
  }
  const result = await AppointmentList.list({
    params: params,
    apiToken: controller.apiToken
  });
  return [result[0].items || [], result[1]]
}

export const getAppointmentOrderRaw: Handler = async (controller, {}) => {
//function (params) { return __awaiter(_this, void 0, void 0, function () {
//            var appService, consultData, consultError, _a, error, response;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        appService = new APPService_1.default();
//                        consultData = [];
//                        consultError = null;
//                        return [4 /*yield*/, utils_1.to(appService.getAppointment(params))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1];
//                        if (error) {
//                            consultError = error;
//                        }
//                        else {
//                            consultData = response;
//                        }
//                        return [2 /*return*/, [consultData, consultError]];
//                }
//            });
//        }); }
//
//----> Name mismatch between manager and service
}

export const getDoctorNote: Handler = async (controller, {language, searchText}) => {
  let params: any = {};
  if (language) {
    params.language = language;
  }
  if (searchText) {
    params.text__contains = searchText;
  }
  const result = await DoctorNoteTemplateList.list({
    params: params,
    apiToken: controller.apiToken
  });
  return [result[0].items || [], result[1]];
}

export const getDoctorNoteGroup: Handler = async (controller, { language, searchText } = {}) => {
  let params: any = {};
  if (language) {
    params.language = language;
  }
  if (searchText) {
    params.name__contains = searchText;
  }
  const result = await DoctorNoteTemplateGroupList.list({
    params: params,
    apiToken: controller.apiToken
  });
  return [result[0].items || [], result[1]];
}

export const getDoctorScheduleAppointment: Handler = async (controller, params) => {
  const result = await DoctorScheduleAppointmentList.list({
    params: params,
    apiToken: controller.apiToken
  });
  return [result[0].items || [], result[1]];
}

export const postAppointmentRequest: Handler = async (controller, app = APPOINTMENT_MODEL) => {
  // params = this._convertToAppointmentModel(app); --- from original, unused or not???
  console.log("postAppointmentRequest controller", controller);
  console.log("postAppointmentRequest controller.data", controller.data);
  return await AppointmentRequestList.post({
    data: {
      ...app,
      order_encounter: app.order_encounter === 0 ? null : app.order_encounter,
    },
    apiToken: controller.apiToken,
    extra: {division: controller.data.division},
  });
}

export const printAppointment: Handler = async (controller, appointmentId: number) => {
  const result = await AppointmentPrinting.update({
    pk: appointmentId,
    data: { language: "TH" }
  });
  return [result[0] || [], result[1]];
}

export const _convertToAppointmentModel: Handler = async (controller, app) => {
  return {
    id: app.id ? app.id : null,
    action: app.action ? app.action : "",
    patient: app.patient ? app.patient : null,
    order_encounter: app.order_encounter ? app.order_encounter : null,
    doctor: app.doctor ? app.doctor : "",
    date: app.date ? app.date : "",
    start_time: app.start_time ? app.start_time : "",
    end_time: app.end_time ? app.end_time : "",
    extend_queue: app.extend_queue !== null ? app.extend_queue : false,
    note_to_nurse: app.note_to_nurse ? app.note_to_nurse : "",
    note_to_patient: app.note_to_patient ? app.note_to_patient : "",
    doctor_note: app.doctor_note ? app.doctor_note : "",
    from_schedule: app.from_schedule ? app.from_schedule : null,
    from_schedule_detail: app.from_schedule_detail
        ? app.from_schedule_detail
        : "",
    from_schedule_doctor_name: app.from_schedule_doctor_name
        ? app.from_schedule_doctor_name
        : "",
    is_editable: app.is_editable !== null ? app.is_editable : false,
    from_follow_up: app.from_follow_up !== null ? app.from_follow_up : 0,
    from_follow_up_division: app.from_follow_up_division
        ? app.from_follow_up_division
        : null,
    from_follow_up_doctor: app.from_follow_up_doctor
        ? app.from_follow_up_doctor
        : null,
    repeat_number: app.repeat_number !== null ? app.repeat_number : 0,
    repeat_frequency: app.repeat_frequency !== null ? app.repeat_frequency : 0,
    status: app.status !== null ? app.status : null,
    add_to_order_episode: app.add_to_order_episode
        ? app.add_to_order_episode
        : false,
    order_dict: app.order_dict ? app.order_dict : {},
    order_doctor: app.order_doctor ? app.order_doctor : null,
    children: app.children ? app.children : {},
  };
}
