import React, { CSSProperties, useState, useMemo, useEffect } from "react";

import {
  Button,
  Icon,
  Input,
  InputProps,
  Form,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

// @ts-ignore
import Cookies from "js-cookie";

// Interface
import {
  Event as MobEvent,
  State as MobState,
  StateInitial as MobStateInitial,
} from "../../../../patient-lib/MobTransformInterface";

// UI
import TFHead from "./TFHead";
import { DisplayTitle, PersonalInformation, SpecifyHN } from "./TFRegister";
import { TFModInfo } from "./TFVerifyIdentity";
import ModMessage from "./ModMessage";

// Common

// Styles
import "./Transform.scss";
import { useIntl } from "react-intl";

type TransformSignInEmailProps = {
  onEvent: (e: MobEvent) => any;
  history: any;
  onHaveApiToken?: (history: any) => any;
} & Pick<
  MobState,
  | "registerInfo"
  | "signInByEmailError"
  | "inputEmail"
  | "inputEmailError"
  | "viewIndexRegister"
  | "errorMessage"
  | "loadingMainTransform"
  | "successMessage"
  | "modMessageType"
>;

const COLOR = {
  primary: "var(--primary-theme-color)",
  secondary: "#F2C94C",
  violet_blue: "#2D247F",
  font_white: "white",
};

const styles = {
  label: {
    color: COLOR.primary,
    fontSize: "16px",
    marginBottom: "10px",
    textAlign: "left",
    fontWeight: "bold",
  } as CSSProperties,
};

const initialHeight = window.innerHeight;

export const ModCheckEmail: React.FunctionComponent<any> = (props) => {
  const intl = useIntl();
  return (
    <TFModInfo
      open={true}
      buttonName="ปิด"
      onApprove={props.onClose}
      onClose={props.onClose}
      titleName="กรุณาตรวจสอบอีเมล"
      style={{ height: "170px" }}
    >
      <div>
        <div>{intl.formatMessage({ id: "ขณะนี้ได้ส่ง link" })}</div>
        <div>{intl.formatMessage({ id: "สำหรับลงชื่อเข้าใช้งานไปที่" })}</div>
        <div>{intl.formatMessage({ id: "Email ที่ได้ลงทะเบียนไว้" })}</div>
        <div>{intl.formatMessage({ id: "กับทางโรงพยาบาลเรียบร้อยแล้ว" })}</div>
        <div>{intl.formatMessage({ id: "กรุณาตรวจสอบเพื่อเข้าสู่ระบบ" })}</div>
      </div>
    </TFModInfo>
  );
};

const TransformSignInEmail: React.FunctionComponent<
  TransformSignInEmailProps
> = (props) => {
  useEffect(() => {
    if (Cookies.get("apiToken")) {
      props.onHaveApiToken?.(props.history);
    }
  }, []);

  /* ------------------------ Memo ------------------------ */
  const headerName = useMemo(() => {
    const names = ["ลงทะเบียน", "กรุณาระบุ HN"];
    return names[props.viewIndexRegister || 0];
  }, [props.viewIndexRegister]);

  const contentHeight = useMemo(() => {
    return `${(initialHeight * 63) / 100}px`;
  }, []);

  /* ----------------------- Handle ----------------------- */
  const handleLeftIconClick = () => {
    props.history.goBack();
  };

  const handleNextView = (
    current: "username" | "password" | "hn" | "info",
    next: string
  ) => {
    props.onEvent({
      message: "HandleNextViewRegister",
      params: { current, next, history: props.history },
    });
  };

  const handleClose = () => {
    props.onEvent({ message: "HandleCloseMessage", params: {} });
  };

  const handleOpenLink = () => {
    props.onEvent({ message: "HandleOpenLinkEKYC", params: {} });
  };

  return (
    <div className="tf-register">
      <Dimmer
        active={props.loadingMainTransform}
        inverted
        style={{ zIndex: 1100 }}
      >
        <Loader inverted>Loading</Loader>
      </Dimmer>

      <TFHead title={headerName} leftIconClick={handleLeftIconClick} />

      {!props.signInByEmailError && (
        <div className="tf-main">
          <div style={{ height: "24rem" }}>
            <DisplayTitle
              title="กรุณาระบุอีเมลเพื่อเข้าใช้งาน"
              description={[
                "กรุณาระบุอีเมลที่ทำการลงทะเบียนไว้กับ",
                "โรงพยาบาลธรรมศาสตร์เฉลิมพระเกียรติ",
              ]}
            />

            <Form
              onSubmit={() =>
                props.onEvent({
                  message: "HandleSignInByEmail",
                  params: { history: props.history },
                })
              }
            >
              <Form.Field error={props.inputEmailError || false}>
                <label className="label-required" style={styles.label}>{intl.formatMessage({ id: "อีเมล" })}</label>
                <Input
                  value={props.inputEmail || ""}
                  icon
                  placeholder=""
                  style={{ fontSize: "16px" }}
                  onChange={(e: any, v: InputProps) =>
                    props.onEvent({
                      message: "HandleSetInputEmail",
                      params: { value: v.value },
                    })
                  }
                  error={props.inputEmailError}
                >
                  <input />
                  <Icon name="mail outline" />
                </Input>
              </Form.Field>
            </Form>
          </div>

          <Button
            fluid
            className="button-submit-primary"
            onClick={() =>
              props.onEvent({
                message: "HandleSignInByEmail",
                params: { history: props.history },
              })
            }
          >
            ลงชื่อเข้าใช้งาน
          </Button>
        </div>
      )}

      {props.signInByEmailError && (
        <Carousel
          selectedItem={props.viewIndexRegister}
          swipeable={false}
          showThumbs={false}
          showArrows={false}
          showStatus={false}
          interval={5000000}
        >
          <div className="tf-main">
            <PersonalInformation
              {...props}
              onSubmit={() => handleNextView("info", "hn")}
              autoFocus={props.viewIndexRegister === 0}
              height={contentHeight}
            />
            <Button
              fluid
              className="button-submit-primary"
              onClick={() => handleNextView("info", "hn")}
            >
              ต่อไป
            </Button>
          </div>
          <div className="tf-main">
            <SpecifyHN
              {...props}
              height={contentHeight}
              onSubmit={() => handleNextView("hn", "success")}
              autoFocus={props.viewIndexRegister === 1}
            />
            <Button
              fluid
              className="button-submit-primary"
              onClick={() => handleNextView("hn", "success")}
            >
              เสร็จสิ้น
            </Button>
          </div>
        </Carousel>
      )}

      {props.modMessageType === "SEND_TO_EMAIL" ? (
        <ModCheckEmail onClose={handleClose} languageUX={props.languageUX}/>
      ) : props.modMessageType === "REGIS_KYC" ? (
        <TFModInfo
          open={true}
          buttonName="ลงทะเบียน e-KYC"
          onApprove={handleOpenLink}
          onClose={handleClose}
          type="not_available"
          languageUX={props.languageUX}
        >
          <>
            <div>{intl.formatMessage({ id: "เนื่องจากยังไม่ได้ลงทะเบียน" })}</div>
            <div>{intl.formatMessage({ id: "ผ่านระบบ e-KYC" })}</div>
            <div>{intl.formatMessage({ id: "กรุณาลงทะเบียนก่อนเริ่มต้นใช้งาน" })}</div>
          </>
        </TFModInfo>
      ) : null}

      <ModMessage
        onEvent={props.onEvent}
        successMessage={props.successMessage}
        errorMessage={props.errorMessage}
        languageUX={props.languageUX}
      />
    </div>
  );
};

TransformSignInEmail.defaultProps = {
  onEvent: () => {},
  history: {},
  viewIndexRegister: 0,
  inputEmail: "",
  inputEmailError: false,
  signInByEmailError: false,
  registerInfo: MobStateInitial.registerInfo,
} as TransformSignInEmailProps;

export default React.memo(TransformSignInEmail);
