import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import { get } from "fingerprintjs2";

// PDF
const PDF = "/static/pdf/taxDoctor/book13number.pdf";
const Font = "/static/fonts/THSarabunNew-Bold.ttf";

const FormTaxCertificateReport = async (
  data: any,
  number: number
): Promise<Partial<{ uint8Array: Uint8Array; blobUrl: string }>> => {
  try {
    const existingPdfBytes = await fetch(PDF).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    const getDecimal = (value: number) => {
      let rounded = Math.floor(value);

      return (value - rounded || 0).toFixed(2)?.toLocaleString().slice(2, 4);
    };

    // Load your custom font
    const fontSize = 12;
    const fontUrl = Font; // Replace with your font URL
    const fontBytes = await fetch(fontUrl).then((res) => res.arrayBuffer());
    pdfDoc.registerFontkit(fontkit);
    const customFont = await pdfDoc.embedFont(fontBytes);
    const zapFont = await pdfDoc.embedFont(StandardFonts.ZapfDingbats);
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    const textCoordinates = [
      { text: data?.VolumeNo, x: 525, y: 781 },
      { text: data?.bookNo, x: 525, y: 768 },
      { text: data?.officerId, x: 379, y: 748.5 },

      { text: data?.taxOfficerId, x: 423, y: 732 },

      { text: data?.officerName, x: 60, y: 732 },
      { text: data?.officerAddress, x: 60, y: 707 },
      { text: data?.fields?.[number]?.citizen_id, x: 379, y: 680 },

      { text: data?.taxId, x: 423, y: 660 },

      { text: data?.fields?.[number]?.doctor_name, x: 60, y: 659 },
      { text: data?.taxAddress, x: 60, y: 631 },
      { text: data?.fields?.[number]?.seq?.toString(), x: 103, y: 605 },

      { text: data?.moneyType1PayDate, x: 340, y: 535 },
      {
        text: data?.moneyType1PayAmount?.toLocaleString(),
        x:
          473 -
          customFont.widthOfTextAtSize(
            (data?.moneyType1PayAmount || 0)?.toLocaleString(),
            fontSize
          ),
        y: 535,
        size: fontSize, // Include the font size here
      },
      {
        text:
          data?.moneyType1PayAmountDecimal === 0
            ? "00"
            : data?.moneyType1PayAmountDecimal?.toString().slice(0, 2),
        x: 477,
        y: 535,
      },
      {
        text: data?.moneyType1TaxCost?.toLocaleString(),
        x:
          544.5 -
          customFont.widthOfTextAtSize((data?.moneyType1TaxCost || 0)?.toLocaleString(), fontSize),
        y: 535,
      },
      {
        text:
          data?.moneyType1TaxCostDecimal === 0
            ? "00"
            : data?.moneyType1TaxCostDecimal?.toString().slice(0, 2),
        x: 548,
        y: 535,
      },
      { text: data?.moneyType2PayDate, x: 340, y: 522 },
      {
        text: data?.moneyType2PayAmount?.toLocaleString(),
        x:
          473 -
          customFont.widthOfTextAtSize(
            (data?.moneyType2PayAmount || 0)?.toLocaleString(),
            fontSize
          ),
        y: 522,
      },
      {
        text:
          data?.moneyType2PayAmountDecimal === 0
            ? "00"
            : data?.moneyType2PayAmountDecimal?.toString().slice(0, 2),
        x: 477,
        y: 522,
      },
      {
        text: data?.moneyType2TaxCost?.toLocaleString(),
        x:
          544.5 -
          customFont.widthOfTextAtSize((data?.moneyType2TaxCost || 0)?.toLocaleString(), fontSize),
        y: 522,
      },
      {
        text:
          data?.moneyType2TaxCostDecimal === 0
            ? "00"
            : data?.moneyType2TaxCostDecimal?.toString().slice(0, 2),
        x: 548,
        y: 522,
      },
      { text: data?.moneyType3PayDate, x: 340, y: 505 },
      {
        text: data?.moneyType3PayAmount?.toLocaleString(),
        x:
          473 -
          customFont.widthOfTextAtSize(
            (data?.moneyType3PayAmount || 0)?.toLocaleString(),
            fontSize
          ),
        y: 505,
      },
      {
        text:
          data?.moneyType3PayAmountDecimal === 0
            ? "00"
            : data?.moneyType3PayAmountDecimal?.toString().slice(0, 2),
        x: 477,
        y: 505,
      },
      {
        text: data?.moneyType3TaxCost?.toLocaleString(),
        x:
          544.5 -
          customFont.widthOfTextAtSize((data?.moneyType3TaxCost || 0)?.toLocaleString(), fontSize),
        y: 505,
      },
      {
        text:
          data?.moneyType3TaxCostDecimal === 0
            ? "00"
            : data?.moneyType3TaxCostDecimal?.toString().slice(0, 2),
        x: 548,
        y: 505,
      },
      { text: data?.moneyType4PayDate, x: 340, y: 492 },
      {
        text: data?.moneyType4PayAmount?.toLocaleString(),
        x:
          473 -
          customFont.widthOfTextAtSize(
            (data?.moneyType4PayAmount || 0)?.toLocaleString(),
            fontSize
          ),
        y: 492,
      },
      {
        text:
          data?.moneyType4PayAmountDecimal === 0
            ? "00"
            : data?.moneyType4PayAmountDecimal?.toString().slice(0, 2),
        x: 477,
        y: 492,
      },
      {
        text: data?.moneyType4TaxCost?.toLocaleString(),
        x:
          544.5 -
          customFont.widthOfTextAtSize((data?.moneyType4TaxCost || 0)?.toLocaleString(), fontSize),
        y: 492,
      },
      {
        text:
          data?.moneyType3TaxCostDecimal === 0
            ? "00"
            : data?.moneyType3TaxCostDecimal?.toString().slice(0, 2),
        x: 548,
        y: 492,
      },
      { text: data?.moneyType4_30percentPayDate, x: 340, y: 434 },
      {
        text: data?.moneyType4_30percentPayAmount?.toLocaleString(),
        x:
          473 -
          customFont.widthOfTextAtSize(
            (data?.moneyType4_30percentPayAmount || 0)?.toLocaleString(),
            fontSize
          ),
        y: 434,
      },
      {
        text:
          data?.moneyType4_30percentPayAmountDecimal === 0
            ? "00"
            : data?.moneyType4_30percentPayAmountDecimal?.toString().slice(0, 2),
        x: 477,
        y: 434,
      },
      {
        text: data?.moneyType4_30percentTaxCost?.toLocaleString(),
        x:
          544.5 -
          customFont.widthOfTextAtSize(
            (data?.moneyType4_30percentTaxCost || 0)?.toLocaleString(),
            fontSize
          ),
        y: 434,
      },
      {
        text:
          data?.moneyType4_30percentTaxCostDecimal === 0
            ? "00"
            : data?.moneyType4_30percentTaxCostDecimal?.toString().slice(0, 2),
        x: 548,
        y: 434,
      },
      { text: data?.moneyType4_25percentPayDate, x: 340, y: 420 },
      {
        text: data?.moneyType4_25percentPayAmount?.toLocaleString(),
        x:
          473 -
          customFont.widthOfTextAtSize(
            (data?.moneyType4_25percentPayAmount || 0)?.toLocaleString(),
            fontSize
          ),
        y: 420,
      },
      {
        text:
          data?.moneyType4_25percentPayAmountDecimal === 0
            ? "00"
            : data?.moneyType4_25percentPayAmountDecimal?.toString().slice(0, 2),
        x: 477,
        y: 420,
      },
      {
        text: data?.moneyType4_25percentTaxCost?.toLocaleString(),
        x:
          544.5 -
          customFont.widthOfTextAtSize(
            (data?.moneyType4_25percentTaxCost || 0)?.toLocaleString(),
            fontSize
          ),
        y: 420,
      },
      {
        text:
          data?.moneyType4_25percentTaxCostDecimal === 0
            ? "00"
            : data?.moneyType4_25percentTaxCostDecimal?.toString().slice(0, 2),
        x: 548,
        y: 420,
      },
      { text: data?.moneyType4_20percentPayDate, x: 340, y: 406 },
      {
        text: data?.moneyType4_20percentPayAmount?.toLocaleString(),
        x:
          473 -
          customFont.widthOfTextAtSize(
            (data?.moneyType4_20percentPayAmount || 0)?.toLocaleString(),
            fontSize
          ),
        y: 406,
      },
      {
        text:
          data?.moneyType4_20percentPayAmountDecimal === 0
            ? "00"
            : data?.moneyType4_20percentPayAmountDecimal?.toString().slice(0, 2),
        x: 477,
        y: 406,
      },
      {
        text: data?.moneyType4_20percentTaxCost?.toLocaleString(),
        x:
          544.5 -
          customFont.widthOfTextAtSize(
            (data?.moneyType4_20percentTaxCost || 0)?.toLocaleString(),
            fontSize
          ),
        y: 406,
      },
      {
        text:
          data?.moneyType4_20percentTaxCostDecimal === 0
            ? "00"
            : data?.moneyType4_20percentTaxCostDecimal?.toString().slice(0, 2),
        x: 548,
        y: 406,
      },
      { text: data?.moneyType4_OtherPercent, x: 170, y: 389 },
      { text: data?.moneyType4_OtherPercentPayDate, x: 340, y: 392 },
      {
        text: data?.moneyType4_OtherPercentPayAmount?.toLocaleString(),
        x:
          473 -
          customFont.widthOfTextAtSize(
            (data?.moneyType4_OtherPercentPayAmount || 0)?.toLocaleString(),
            fontSize
          ),
        y: 392,
      },
      {
        text:
          data?.moneyType4_OtherPercentPayAmountDecimal === 0
            ? "00"
            : data?.moneyType4_OtherPercentPayAmountDecimal?.toString().slice(0, 2),
        x: 477,
        y: 392,
      },
      {
        text: data?.moneyType4_OtherPercentTaxCost?.toLocaleString(),
        x:
          544.5 -
          customFont.widthOfTextAtSize(
            (data?.moneyType4_OtherPercentTaxCost || 0)?.toLocaleString(),
            fontSize
          ),
        y: 392,
      },
      {
        text:
          data?.moneyType4_OtherPercentTaxCostDecimal === 0
            ? "00"
            : data?.moneyType4_OtherPercentTaxCostDecimal?.toString().slice(0, 2),
        x: 548,
        y: 392,
      },
      {
        text:
          data?.moneyType4_2Point1PayDate && data?.moneyType4_2Point1PayDate !== ""
            ? data?.moneyType4_2Point1PayDate
            : null,
        x: 340,
        y: 360,
      },

      {
        text:
          data?.moneyType4_2Point1PayDate &&
          data?.moneyType4_2Point1PayDate !== "" &&
          data?.moneyType4_2Point1PayAmount !== null &&
          data?.moneyType4_2Point1PayAmount !== undefined &&
          data?.moneyType4_2Point1PayAmount?.toLocaleString() !== ""
            ? data?.moneyType4_2Point1PayAmount?.toLocaleString()
            : null,
        x:
          473 -
          customFont.widthOfTextAtSize(
            (data?.moneyType4_2Point1PayAmount || 0)?.toLocaleString(),
            fontSize
          ),
        y: 360,
      },
      {
        text:
          data?.moneyType4_2Point1PayDate &&
          data?.moneyType4_2Point1PayDate !== "" &&
          data?.moneyType4_2Point1PayAmount !== null &&
          data?.moneyType4_2Point1PayAmount !== undefined &&
          (data?.moneyType4_2Point1PayAmountDecimal !== 0 ||
            data?.moneyType4_2Point1PayAmountDecimal?.toString().slice(0, 2) !== "")
            ? data?.moneyType4_2Point1PayAmountDecimal === 0
              ? "00"
              : data?.moneyType4_2Point1PayAmountDecimal?.toString().slice(0, 2)
            : null,
        x: 477,
        y: 360,
      },
      {
        text:
          data?.moneyType4_2Point1PayDate &&
          data?.moneyType4_2Point1PayDate !== "" &&
          data?.moneyType4_2Point1TaxCost !== null &&
          data?.moneyType4_2Point1TaxCost !== undefined &&
          data?.moneyType4_2Point1TaxCost?.toLocaleString() !== ""
            ? data?.moneyType4_2Point1TaxCost?.toLocaleString()
            : null,
        x:
          544.5 -
          customFont.widthOfTextAtSize(
            (data?.moneyType4_2Point1TaxCost || 0)?.toLocaleString(),
            fontSize
          ),
        y: 360,
      },
      {
        text:
          data?.moneyType4_2Point1PayDate &&
          data?.moneyType4_2Point1PayDate !== "" &&
          data?.moneyType4_2Point1TaxCost !== null &&
          data?.moneyType4_2Point1TaxCost !== undefined &&
          (data?.moneyType4_2Point1TaxCostDecimal !== 0 ||
            data?.moneyType4_2Point1TaxCostDecimal?.toString().slice(0, 2) !== "")
            ? data?.moneyType4_2Point1TaxCostDecimal === 0
              ? "00"
              : data?.moneyType4_2Point1TaxCostDecimal?.toString().slice(0, 2)
            : null,
        x: 548,
        y: 360,
      },
      {
        text:
          data?.moneyType4_2Point2PayDate && data?.moneyType4_2Point2PayDate !== ""
            ? data?.moneyType4_2Point2PayDate
            : null,
        x: 340,
        y: 332,
      },
      {
        text:
          data?.moneyType4_2Point2PayDate &&
          data?.moneyType4_2Point2PayDate !== "" &&
          data?.moneyType4_2Point2PayAmount !== null &&
          data?.moneyType4_2Point2PayAmount !== undefined &&
          data?.moneyType4_2Point2PayAmount?.toLocaleString() !== ""
            ? data?.moneyType4_2Point2PayAmount?.toLocaleString()
            : null,
        x:
          473 -
          customFont.widthOfTextAtSize(
            (data?.moneyType4_2Point2PayAmount || 0)?.toLocaleString(),
            fontSize
          ),
        y: 332,
      },
      {
        text:
          data?.moneyType4_2Point2PayDate &&
          data?.moneyType4_2Point2PayDate !== "" &&
          data?.moneyType4_2Point2PayAmount !== null &&
          data?.moneyType4_2Point2PayAmount !== undefined &&
          (data?.moneyType4_2Point2PayAmountDecimal !== 0 ||
            data?.moneyType4_2Point2PayAmountDecimal?.toString().slice(0, 2) !== "")
            ? data?.moneyType4_2Point2PayAmountDecimal === 0
              ? "00"
              : data?.moneyType4_2Point2PayAmountDecimal?.toString().slice(0, 2)
            : null,
        x: 477,
        y: 332,
      },
      {
        text:
          data?.moneyType4_2Point2PayDate &&
          data?.moneyType4_2Point2PayDate !== "" &&
          data?.moneyType4_2Point2TaxCost !== null &&
          data?.moneyType4_2Point2TaxCost !== undefined &&
          data?.moneyType4_2Point2TaxCost?.toLocaleString() !== ""
            ? data?.moneyType4_2Point2TaxCost?.toLocaleString()
            : null,
        x:
          544.5 -
          customFont.widthOfTextAtSize(
            (data?.moneyType4_2Point2TaxCost || 0)?.toLocaleString(),
            fontSize
          ),
        y: 332,
      },
      {
        text:
          data?.moneyType4_2Point2PayDate &&
          data?.moneyType4_2Point2PayDate !== "" &&
          data?.moneyType4_2Point2TaxCost !== null &&
          data?.moneyType4_2Point2TaxCost !== undefined &&
          (data?.moneyType4_2Point2TaxCostDecimal !== 0 ||
            data?.moneyType4_2Point2TaxCostDecimal?.toString().slice(0, 2) !== "")
            ? data?.moneyType4_2Point2TaxCostDecimal === 0
              ? "00"
              : data?.moneyType4_2Point2TaxCostDecimal?.toString().slice(0, 2)
            : null,
        x: 548,
        y: 332,
      },
      {
        text:
          data?.moneyType4_2Point3PayDate && data?.moneyType4_2Point3PayDate !== ""
            ? data?.moneyType4_2Point3PayDate
            : null,
        x: 340,
        y: 302,
      },
      {
        text:
          data?.moneyType4_2Point3PayDate &&
          data?.moneyType4_2Point3PayDate !== "" &&
          data?.moneyType4_2Point3PayAmount !== null &&
          data?.moneyType4_2Point3PayAmount !== undefined &&
          data?.moneyType4_2Point3PayAmount?.toLocaleString() !== ""
            ? data?.moneyType4_2Point3PayAmount?.toLocaleString()
            : null,
        x:
          473 -
          customFont.widthOfTextAtSize(
            (data?.moneyType4_2Point3PayAmount || 0)?.toLocaleString(),
            fontSize
          ),
        y: 302,
      },
      {
        text:
          data?.moneyType4_2Point3PayDate &&
          data?.moneyType4_2Point3PayDate !== "" &&
          data?.moneyType4_2Point3PayAmount !== null &&
          data?.moneyType4_2Point3PayAmount !== undefined &&
          (data?.moneyType4_2Point3PayAmountDecimal !== 0 ||
            data?.moneyType4_2Point3PayAmountDecimal?.toString().slice(0, 2) !== "")
            ? data?.moneyType4_2Point3PayAmountDecimal === 0
              ? "00"
              : data?.moneyType4_2Point3PayAmountDecimal?.toString().slice(0, 2)
            : null,
        x: 477,
        y: 302,
      },
      {
        text:
          data?.moneyType4_2Point3PayDate &&
          data?.moneyType4_2Point3PayDate !== "" &&
          data?.moneyType4_2Point3TaxCost !== null &&
          data?.moneyType4_2Point3TaxCost !== undefined &&
          data?.moneyType4_2Point3TaxCost?.toLocaleString() !== ""
            ? data?.moneyType4_2Point3TaxCost?.toLocaleString()
            : null,
        x:
          544.5 -
          customFont.widthOfTextAtSize(
            (data?.moneyType4_2Point3TaxCost || 0)?.toLocaleString(),
            fontSize
          ),
        y: 302,
      },
      {
        text:
          data?.moneyType4_2Point3PayDate &&
          data?.moneyType4_2Point3PayDate !== "" &&
          data?.moneyType4_2Point3TaxCost !== null &&
          data?.moneyType4_2Point3TaxCost !== undefined &&
          (data?.moneyType4_2Point3TaxCostDecimal !== 0 ||
            data?.moneyType4_2Point3TaxCostDecimal?.toString().slice(0, 2) !== "")
            ? data?.moneyType4_2Point3TaxCostDecimal === 0
              ? "00"
              : data?.moneyType4_2Point3TaxCostDecimal?.toString().slice(0, 2)
            : null,
        x: 548,
        y: 302,
      },
      {
        text:
          data?.moneyType4_2Point4PayDate && data?.moneyType4_2Point4PayDate !== ""
            ? data?.moneyType4_2Point4PayDate
            : null,
        x: 340,
        y: 275,
      },
      {
        text:
          data?.moneyType4_2Point4PayDate &&
          data?.moneyType4_2Point4PayDate !== "" &&
          data?.moneyType4_2Point4PayAmount !== null &&
          data?.moneyType4_2Point4PayAmount !== undefined &&
          data?.moneyType4_2Point4PayAmount?.toLocaleString() !== ""
            ? data?.moneyType4_2Point4PayAmount?.toLocaleString()
            : null,
        x:
          473 -
          customFont.widthOfTextAtSize(
            (data?.moneyType4_2Point4PayAmount || 0)?.toLocaleString(),
            fontSize
          ),
        y: 275,
      },
      {
        text:
          data?.moneyType4_2Point4PayDate &&
          data?.moneyType4_2Point4PayDate !== "" &&
          data?.moneyType4_2Point4PayAmount !== null &&
          data?.moneyType4_2Point4PayAmount !== undefined &&
          (data?.moneyType4_2Point4PayAmountDecimal !== 0 ||
            data?.moneyType4_2Point4PayAmountDecimal?.toString().slice(0, 2) !== "")
            ? data?.moneyType4_2Point4PayAmountDecimal === 0
              ? "00"
              : data?.moneyType4_2Point4PayAmountDecimal?.toString().slice(0, 2)
            : null,
        x: 477,
        y: 275,
      },
      {
        text:
          data?.moneyType4_2Point4PayDate &&
          data?.moneyType4_2Point4PayDate !== "" &&
          data?.moneyType4_2Point4TaxCost !== null &&
          data?.moneyType4_2Point4TaxCost !== undefined &&
          data?.moneyType4_2Point4TaxCost?.toLocaleString() !== ""
            ? data?.moneyType4_2Point4TaxCost?.toLocaleString()
            : null,
        x:
          544.5 -
          customFont.widthOfTextAtSize(
            (data?.moneyType4_2Point4TaxCost || 0)?.toLocaleString(),
            fontSize
          ),
        y: 275,
      },
      {
        text:
          data?.moneyType4_2Point4PayDate &&
          data?.moneyType4_2Point4PayDate !== "" &&
          data?.moneyType4_2Point4TaxCost !== null &&
          data?.moneyType4_2Point4TaxCost !== undefined &&
          (data?.moneyType4_2Point4TaxCostDecimal !== 0 ||
            data?.moneyType4_2Point4TaxCostDecimal?.toString().slice(0, 2) !== "")
            ? data?.moneyType4_2Point4TaxCostDecimal === 0
              ? "00"
              : data?.moneyType4_2Point4TaxCostDecimal?.toString().slice(0, 2)
            : null,
        x: 548,
        y: 275,
      },
      {
        text:
          data?.moneyType4_2Point5 && data?.moneyType4_2Point5 !== ""
            ? data?.moneyType4_2Point5
            : null,
        x: 170,
        y: 273,
      },
      {
        text:
          data?.moneyType4_2Point5 && data?.moneyType4_2Point5 !== ""
            ? data?.moneyType4_2Point5PayDate
            : null,
        x: 340,
        y: 275,
      },
      {
        text:
          data?.moneyType4_2Point5 &&
          data?.moneyType4_2Point5 !== "" &&
          data?.moneyType4_2Point5PayAmount !== null &&
          data?.moneyType4_2Point5PayAmount !== undefined &&
          data?.moneyType4_2Point5PayAmount?.toLocaleString() !== ""
            ? data?.moneyType4_2Point5PayAmount?.toLocaleString()
            : null,
        x:
          473 -
          customFont.widthOfTextAtSize(
            (data?.moneyType4_2Point5PayAmount || 0)?.toLocaleString(),
            fontSize
          ),
        y: 275,
      },
      {
        text:
          data?.moneyType4_2Point5 &&
          data?.moneyType4_2Point5 !== "" &&
          data?.moneyType4_2Point5PayAmount !== null &&
          data?.moneyType4_2Point5PayAmount !== undefined &&
          (data?.moneyType4_2Point5PayAmountDecimal !== 0 ||
            data?.moneyType4_2Point5PayAmountDecimal?.toString().slice(0, 2) !== "")
            ? data?.moneyType4_2Point5PayAmountDecimal === 0
              ? "00"
              : data?.moneyType4_2Point5PayAmountDecimal?.toString().slice(0, 2)
            : null,
        x: 477,
        y: 275,
      },
      {
        text:
          data?.moneyType4_2Point5 &&
          data?.moneyType4_2Point5 !== "" &&
          data?.moneyType4_2Point5TaxCost !== null &&
          data?.moneyType4_2Point5TaxCost !== undefined &&
          data?.moneyType4_2Point5TaxCost?.toLocaleString() !== ""
            ? data?.moneyType4_2Point5TaxCost?.toLocaleString()
            : null,
        x:
          544.5 -
          customFont.widthOfTextAtSize(
            (data?.moneyType4_2Point5TaxCost || 0)?.toLocaleString(),
            fontSize
          ),
        y: 275,
      },
      {
        text:
          data?.moneyType4_2Point5 &&
          data?.moneyType4_2Point5 !== "" &&
          data?.moneyType4_2Point5TaxCost !== null &&
          data?.moneyType4_2Point5TaxCost !== undefined &&
          (data?.moneyType4_2Point5TaxCostDecimal !== 0 ||
            data?.moneyType4_2Point5TaxCostDecimal?.toString().slice(0, 2) !== "")
            ? data?.moneyType4_2Point5TaxCostDecimal === 0
              ? "00"
              : data?.moneyType4_2Point5TaxCostDecimal?.toString().slice(0, 2)
            : null,
        x: 548,
        y: 275,
      },
      { text: data?.moneyType5PayDate, x: 340, y: 218 },
      {
        text: data?.moneyType5PayAmount?.toLocaleString(),
        x:
          473 -
          customFont.widthOfTextAtSize(
            (data?.moneyType5PayAmount || 0)?.toLocaleString(),
            fontSize
          ),
        y: 218,
      },
      {
        text:
          data?.moneyType5PayAmountDecimal === 0
            ? "00"
            : data?.moneyType5PayAmountDecimal?.toString().slice(0, 2),
        x: 477,
        y: 218,
      },
      {
        text: data?.moneyType5TaxCost?.toLocaleString(),
        x:
          544.5 -
          customFont.widthOfTextAtSize((data?.moneyType5TaxCost || 0)?.toLocaleString(), fontSize),
        y: 218,
      },
      {
        text:
          data?.moneyType5TaxCostDecimal === 0
            ? "00"
            : data?.moneyType5TaxCostDecimal?.toString().slice(0, 2),
        x: 548,
        y: 218,
      },
      { text: data?.otherNote, x: 125, y: 202 },
      { text: data?.datePayTex, x: 340, y: 202 },
      {
        text: Number((data?.fields?.[number]?.total_net_price || 0)?.toFixed(0))?.toLocaleString(),
        x:
          470 -
          customFont.widthOfTextAtSize(
            (data?.fields?.[number]?.total_net_price || 0)?.toFixed(0)?.toLocaleString(),
            fontSize
          ),
        y: 202,
      },
      {
        text:
          data?.fields?.[number]?.total_net_price === 0
            ? "00"
            : getDecimal(Number(data?.fields?.[number]?.total_net_price)),
        x: 477,
        y: 202,
      },
      {
        text: Number((data?.fields?.[number]?.total_tax || 0)?.toFixed(0))?.toLocaleString(),
        x:
          541.5 -
          customFont.widthOfTextAtSize(
            (data?.fields?.[number]?.total_tax || 0)?.toFixed(0)?.toLocaleString(),
            fontSize
          ),
        y: 202,
      },
      {
        text:
          data?.fields?.[number]?.total_tax === 0
            ? "00"
            : getDecimal(Number(data?.fields?.[number]?.total_tax)),
        x: 548,
        y: 202,
      },
      {
        text: Number((data?.fields?.[number]?.total_net_price || 0)?.toFixed(0))?.toLocaleString(),
        x:
          470 -
          customFont.widthOfTextAtSize(
            (data?.fields?.[number]?.total_net_price || 0)?.toFixed(0)?.toLocaleString(),
            fontSize
          ),
        y: 180,
      },
      {
        text:
          data?.fields?.[number]?.total_net_price === 0
            ? "00"
            : getDecimal(Number(data?.fields?.[number]?.total_net_price)),
        x: 477,
        y: 180,
      },
      {
        text: Number((data?.fields?.[number]?.total_tax || 0)?.toFixed(0))?.toLocaleString(),
        x:
          541.5 -
          customFont.widthOfTextAtSize(
            (data?.fields?.[number]?.total_tax || 0)?.toFixed(0)?.toLocaleString(),
            fontSize
          ),
        y: 180,
      },
      {
        text:
          data?.fields?.[number]?.total_tax === 0
            ? "00"
            : getDecimal(Number(data?.fields?.[number]?.total_tax)),
        x: 548,
        y: 180,
      },
      { text: data?.fields?.[number]?.total_tax_text, x: 190, y: 163 },
      { text: (data?.teacherFunds || 0).toFixed(2), x: 250, y: 143 },
      { text: (data?.socialFunds || 0).toFixed(2), x: 380, y: 143 },
      { text: (data?.makeALiveFunds || 0).toFixed(2), x: 515, y: 143 },
      { text: data?.payerType4Detail, x: 490, y: 123 },

      { text: data?.submitDate, x: 356, y: 77 },
      { text: data?.submitMonth, x: 390, y: 77 },
      { text: data?.submitYear, x: 430, y: 77 },
      // Add other text fields using data? here...
    ];

    for (const { text, x, y } of textCoordinates) {
      // Skip drawing text if the value is null or an empty string
      if (!text) continue;
      let adjustedX = x; // Initialize adjustedX to the original x coordinate

      if (text === data?.officerId) {
        // Loop through each character in data?.taxId
        for (let j = 0; j < data?.officerId.length; j++) {
          const charSpacing = 7.2;
          if (j === 1 || j === 5 || j === 10 || j === 12) {
            adjustedX += charSpacing;
          } // Apply spacing adjustment if necessary

          // Get the current character from taxId
          const char = data?.officerId[j];

          // Draw the character at the adjustedX position
          firstPage.drawText(char, {
            x: adjustedX,
            y,
            size: fontSize,
            font: customFont,
            color: rgb(0, 0, 0),
          });

          // Increment adjustedX by the width of the character plus the spacing adjustment
          adjustedX += customFont.widthOfTextAtSize(char, fontSize) + charSpacing;
        }
      } else if (text === data?.taxOfficerId) {
        const charSpacing = 7;

        for (let j = 0; j < data?.taxOfficerId.length; j++) {
          const char = data?.taxOfficerId[j];

          // Adjust spacing for specific letters
          if (j === 1 || j === 5 || j === 9) {
            adjustedX += charSpacing; // Increase the X coordinate by charSpacing
          }

          // Draw the character at the currentX position
          firstPage.drawText(char, {
            x: adjustedX,
            y,
            size: fontSize,
            font: customFont,
            color: rgb(0, 0, 0),
          });

          // Update currentX with character width and spacing
          adjustedX += customFont.widthOfTextAtSize(char, fontSize) + charSpacing;
        }
      } else if (text === data?.fields?.[number]?.citizen_id) {
        const charSpacing = 7.2;

        for (let j = 0; j < data?.fields?.[number]?.citizen_id?.length; j++) {
          const char = data?.fields?.[number]?.citizen_id[j];

          // Adjust spacing for specific letters
          if (j === 1 || j === 5 || j === 10 || j === 12) {
            adjustedX += charSpacing; // Increase the X coordinate by charSpacing
          }

          // Draw the character at the currentX position
          firstPage.drawText(char, {
            x: adjustedX,
            y,
            size: fontSize,
            font: customFont,
            color: rgb(0, 0, 0),
          });

          // Update currentX with character width and spacing
          adjustedX += customFont.widthOfTextAtSize(char, fontSize) + charSpacing;
        }
      } else if (text === data?.taxId) {
        const charSpacing = 7;

        for (let j = 0; j < data?.taxId.length; j++) {
          const char = data?.taxId[j];

          // Adjust spacing for specific letters
          if (j === 1 || j === 5 || j === 9) {
            adjustedX += charSpacing; // Increase the X coordinate by charSpacing
          }

          // Draw the character at the currentX position
          firstPage.drawText(char, {
            x: adjustedX,
            y,
            size: fontSize,
            font: customFont,
            color: rgb(0, 0, 0),
          });

          // Update currentX with character width and spacing
          adjustedX += customFont.widthOfTextAtSize(char, fontSize) + charSpacing;
        }
      } else {
        // Draw the text as it is without any adjustments
        firstPage.drawText(text, {
          x,
          y,
          size: fontSize,
          font: customFont,
          color: rgb(0, 0, 0),
        });
      }
    }

    const checkSymbol = "\u2713"; // ✓ Check symbol
    const falseText = ""; // Empty text when isMoneyType is false

    // Adjust coordinates for each check symbol
    const isTaxTypeTextCoordinates = [
      {
        text: data?.isTaxType1 ? checkSymbol : falseText,
        x: 212,
        y: 603,
      },
      {
        text: data?.isTaxType2 ? checkSymbol : falseText,
        x: 290,
        y: 603,
      },
      {
        text: data?.isTaxType3 ? checkSymbol : falseText,
        x: 398,
        y: 603,
      },
      {
        text: data?.isTaxType4 ? checkSymbol : falseText,
        x: 473,
        y: 603,
      },
      {
        text: data?.isTaxType5 ? checkSymbol : falseText,
        x: 212,
        y: 585,
      },
      {
        text: checkSymbol,
        x: 290,
        y: 585,
      },
      {
        text: data?.isTaxType7 ? checkSymbol : falseText,
        x: 398,
        y: 585,
      },
    ];

    for (const { text, x, y } of isTaxTypeTextCoordinates) {
      firstPage.drawText(text, {
        x,
        y,
        size: 12,
        font: zapFont,
        color: rgb(0, 0, 0),
      });
    }
    const isPayerTypeTextCoordinates = [
      {
        text: data?.isPayerType1 ? checkSymbol : falseText,
        x: 85,
        y: 121,
      },
      {
        text: data?.isPayerType2 ? checkSymbol : falseText,
        x: 178,
        y: 121,
      },
      {
        text: data?.isPayerType3 ? checkSymbol : falseText,
        x: 285,
        y: 121,
      },
      {
        text: data?.isPayerType4 ? checkSymbol : falseText,
        x: 395,
        y: 121,
      },
    ];

    for (const { text, x, y } of isPayerTypeTextCoordinates) {
      firstPage.drawText(text, {
        x,
        y,
        size: 12,
        font: zapFont,
        color: rgb(0, 0, 0),
      });
    }
    const modifiedPdfBytes = await pdfDoc.save();
    const modifiedPdfUrl = URL.createObjectURL(
      new Blob([modifiedPdfBytes], { type: "application/pdf" })
    );

    return { uint8Array: modifiedPdfBytes, blobUrl: modifiedPdfUrl };
  } catch (error) {
    console.error("Error modifying PDF:", error);

    return {};
  }
};

export default FormTaxCertificateReport;
