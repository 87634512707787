import React, { CSSProperties, useMemo } from "react";

import { ButtonProps, SemanticCOLORS } from "semantic-ui-react";

import ButtonLoadCheck, { BLClickHandler } from "react-lib/appcon/common/ButtonLoadCheck";

import CONFIG from "config/config";

type ButtonCallPharmacistProps = {
  setProp: (key: string, value: any, callback?: () => void) => void;
  consultStatus: string;
  encounterStatus?: string;
  orderPaymentStatus: string;
  productType: string;
  size?: ButtonProps["size"];
  specificType: string;
  statusName: string;
  telepharType?: string;
  onCall: BLClickHandler;
};

// Images
const IMAGES = {
  callMedicine: "/static/images/intratelephar/call-medicine-white.png",
};

type Styles = Record<"button", CSSProperties>;

const styles: Styles = {
  button: { height: "100%", padding: "0.35rem 0.75rem" },
};

const ButtonCallPharmacist = (props: ButtonCallPharmacistProps) => {
  const buttonActions = useMemo(() => {
    const buttons: Record<"intra" | "normal", { color: SemanticCOLORS; title: string }> = {
      intra: {
        color: "green",
        title: "TelePharmacy",
      },
      normal: {
        color: "blue",
        title: "Call to Pharmacist",
      },
    };

    return props.statusName === "RECEIVED" && props.telepharType === "INTRA"
      ? buttons.intra
      : buttons.normal;
  }, [props.statusName, props.telepharType]);

  const shouldHideDrugBtnCall = useMemo(() => {
    if (props.consultStatus !== "IDLE") {
      return true;
    }

    if (props.specificType !== "HOME_OPD") {
      // จำหน่ายผู้ป่วยกลับบ้าน
      return props.encounterStatus === "DISCHARGED";
    }

    return props.statusName === "RECEIVED"
      ? // Type intra จะแสดงปุ่ม TelePharmacy
        props.telepharType !== "INTRA"
      : props.orderPaymentStatus === "PAID";
  }, [
    props.consultStatus,
    props.encounterStatus,
    props.orderPaymentStatus,
    props.specificType,
    props.statusName,
    props.telepharType,
  ]);

  const hideButtonCall = useMemo(
    () =>
      shouldHideDrugBtnCall ||
      ["imagingorder", "supply"].includes(props.productType) ||
      !CONFIG.ENABLE_INTRA_TELEPHAR,
    [props.productType, shouldHideDrugBtnCall]
  );

  const buttonTitle = useMemo(
    () => (
      <div style={{ alignItems: "center", display: "flex" }}>
        <img
          alt="call-medicine"
          src={IMAGES.callMedicine}
          style={{ marginRight: "0.5rem", width: "20px" }}
        />
        <label>{buttonActions.title}</label>
      </div>
    ),
    [buttonActions.title]
  );

  return (
    <>
      {!hideButtonCall && (
        <ButtonLoadCheck
          setProp={props.setProp}
          color={buttonActions.color}
          size={props.size ?? "mini"}
          style={styles.button}
          title={buttonTitle}
          // callback
          onClick={props.onCall}
        />
      )}
    </>
  );
};

ButtonCallPharmacist.displayName = "ButtonCallPharmacist";

export default React.memo(ButtonCallPharmacist);
