import React from "react";
import { Button } from "semantic-ui-react";
import { Bar } from 'react-chartjs-2'
import html2canvas from "html2canvas";
import { useIntl } from "react-intl";

const ChartAppointmentSummary = (props: any) => {
  const intl = useIntl();

  const printChart = async (e: any) => {
    let input = window.document.getElementsByClassName("summaryChart")[0];
    const canvas = await html2canvas(input as HTMLElement, {
      scale: 0.5
    });
    const img = canvas.toDataURL("image/png");
    props.onEvent({message: "PrintScheduling", params: {
      form: "FormChartAppointmentSummary",
      data: img
    }});
  }
    
  return(
  <div>
    <div style={{display: "flex", justifyContent: "flex-end"}}>
      <Button size="tiny" color="blue" onClick={printChart}>{intl.formatMessage({ id: "พิมพ์" })}</Button>
    </div>
    <div className="summaryChart">
      <Bar 
        data={{
          labels: ["a", "b"],
          datasets: [
            { label:"a", data: [1, 2], backgroundColor: "blue" },
            { label: "b", data: [3, 4], backgroundColor: "green" }
          ]
        }}
        options={{
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              }
            }]
          }
        }}
      />
    </div>
  </div>
  )
}

export default ChartAppointmentSummary;