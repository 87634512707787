import WasmController from "../../../../frameworks/WasmController";
import { State as MainState } from "../../../../../HIS/MainHISInterface";
// APIs
import PhysicalExamTemplateList from "issara-sdk/apis/PhysicalExamTemplateList_apps_DPO";

export type State = {
  PhysicalExamSequence?: {
    sequenceIndex?: string | null;

    organList?: any[];
    templateList?: any[];
    selectedTemplateList?: any[];
    otherExam?: any;
  } | null;
};

export const StateInitial: State = {
  PhysicalExamSequence: {
    sequenceIndex: null,

    organList: [],
    templateList: [],
    selectedTemplateList: [],
    otherExam: {},
  },
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  division?: number;
};

type Picked = Partial<
  Pick<
    MainState,
    | "buttonLoadCheck"
    | "errorMessage"
    | "successMessage"
    | "masterOptions"
    | "django"
  >
>;

export const DataInitial = {};

type Handler<P = any, R = any> = (
  controller: WasmController<State & Picked, Event, Data>,
  params: P
) => R;

export const Start: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState(
    {
      PhysicalExamSequence: {
        ...state.PhysicalExamSequence,

        sequenceIndex: "Action",
      },
    },
    () => {
      controller.handleEvent({ message: "RunSequence", params: { ...params } });
    }
  );
};

export const Action: Handler = async (controller, params) => {
  if (params?.action === "GET_TEMPLATE") {
    const [resp, error, netw] = await PhysicalExamTemplateList.list({
      apiToken: controller.apiToken,
      extra: { division: controller.data.division },
    });

    const state = controller.getState();

    if (error) {
      controller.setState({
        PhysicalExamSequence: {
          ...state.PhysicalExamSequence,
          templateList: [],
          selectedTemplateList: [],
        },
      });
    } else {
      const selectedTemplate: any[] = params.templates?.map((item: any) => {
        return { ...item, emr: params.emr };
      });

      const templateList = resp?.items?.map((tempList: any) => {
        const matchingTemp = params.templates?.find(
          (selTemp: any) => selTemp.id === tempList.id
        );
        let resultItem = tempList?.items?.map((item: any) => {
          const matchingOrgan = state.PhysicalExamSequence?.organList?.find(
            (selTemp: any) => selTemp.organ === item.organ
          );
          return { ...item, ...matchingOrgan };
        });
        return { ...tempList, ...matchingTemp, items: resultItem };
      });

      controller.setState({
        PhysicalExamSequence: {
          ...state.PhysicalExamSequence,
          templateList: templateList,
          selectedTemplateList: selectedTemplate,
        },
      });
    }
  } else if (params?.action === "SET_EXAM") {
    const state = controller.getState();

    let organList = state.PhysicalExamSequence?.organList?.map(
      (item: any) => item.organ
    );
    let templateResult = state.PhysicalExamSequence?.templateList;
    if (organList?.includes(params.organ.organ)) {
      templateResult = templateResult?.map((temp: any) => {
        let resultItem = temp.items.map((item: any) => {
          if (item.organ === params.organ.organ) {
            return { ...item, [params.data.name]: params.data.value };
          } else {
            return { ...item };
          }
        });
        return { ...temp, items: resultItem };
      });
    }

    let selectedResult = state.PhysicalExamSequence?.selectedTemplateList?.map(
      (temp: any) => {
        let resultItem = temp.items.map((item: any) => {
          if (item.organ === params.organ.organ) {
            return { ...item, [params.data.name]: params.data.value };
          } else {
            return { ...item };
          }
        });
        return { ...temp, items: resultItem };
      }
    );

    let organResult = state.PhysicalExamSequence?.organList?.map(
      (item: any) => {
        if (item.organ === params.organ.organ) {
          return { ...item, [params.data.name]: params.data.value };
        } else {
          return { ...item };
        }
      }
    );

    controller.setState({
      PhysicalExamSequence: {
        ...state.PhysicalExamSequence,
        templateList: templateResult,
        selectedTemplateList: selectedResult,
        organList: organResult,
      },
    });
  }
};
