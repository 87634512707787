import WasmController from "react-lib/frameworks/WasmController";

// APIS
import CoveragePayerSentClaimGroupList from "issara-sdk/apis/CoveragePayerSentClaimGroupList_apps_INF";
import BillTransactionSummaryList from "issara-sdk/apis/BillTransactionSummaryList_apps_INF";
import BillTransactionList from "issara-sdk/apis/BillTransactionList_apps_INF";
import DoUpdateSentClaimDataFromARList from "issara-sdk/apis/DoUpdateSentClaimDataFromARList_apps_INF";
import GenerateSentClaimFileFromARList from "issara-sdk/apis/GenerateSentClaimFileFromARList_apps_INF";

// Serializer
import BillTransactionListSerializer from "issara-sdk/types/BillTransactionListSerializer_apps_INF";

import moment from "moment";

// Utils
import { formatDate } from "react-lib/utils/dateUtils";
import { downloadFile } from "react-lib/utils";

export type State = Partial<{
  // CommonInterface
  errorMessage: any;
  buttonLoadCheck: any;
  // sequence
  DownloadSentClaimFileSequence: Partial<{
    sequenceIndex: "START" | "ACTION" | null;
    filter: Partial<FilterType>;
    coveragePayerOptions: any[];
    billTransactionList: BillTransactionIPDListSerialize[];
    billTransactionSummary: Partial<TransactionSummaryType>;
    runningARTask: boolean;
    isLoading: boolean;
    items: any[];
  }> | null;
}>;

export type TransactionSummaryType = {
  isCalulating: boolean;
  total_rows: string;
  total_price: string;
  total_sent_claim_price: string;
  total_paid: string;
  total_other_pay: string;
  total_price_bill_item_mode_2: string;
  total_sent_claim_price_bill_item_mode_2: string;
  total_price_bill_item_mode_3_5: string;
  total_sent_claim_price_bill_item_mode_3_5: string;
  total_price_bill_item_mode_remain: string;
  total_sent_claim_price_bill_item_mode_remain: string;
  total_price_dispensing: string;
  total_sent_claim_price_dispensing: string;
  total_price_operation_service: string;
};

type FilterType = {
  arTransaction: number;
  fiscalYear: string;
  isDate: boolean;
  startDate: string;
  endDate: string;
  coveragePayer: number;
  isOnlyC: string;
  coverageCodeName: string;
  lotNo: string;
};

// Sequence
type SeqState = {
  sequence: "DownloadSentClaimFile";
  restart?: boolean;
  clear?: boolean;
};
// Handle Action
type ActionType =
  | { action: "SEARCH" }
  | { action: "CHANGE"; data: Partial<FilterType> }
  | { action: "UPDATE_SENT_CLAIM"; card: string }
  | { action: "DOWNLOAD_SENT_CLAIM"; card: string };

type SeqAct = ActionType & SeqState;
type SeqType<K> = K extends { action: string } ? Extract<SeqAct, K> : SeqState;

export type RunSequence = <K extends keyof SeqAct>(
  params: SeqType<Pick<SeqAct, K>>
) => any;

type Params<A extends ActionType["action"]> = Extract<
  ActionType,
  { action: A }
>;

export const StateInitial: State = {
  // sequence
  DownloadSentClaimFileSequence: {
    sequenceIndex: null,
  },
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

/* ------------------------------------------------------ */

/*                          START                         */

/* ------------------------------------------------------ */
export const GetMaster: Handler = async (controller, params) => {
  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [],
    },
  } as any);

  const coveragePayerResult = await CoveragePayerSentClaimGroupList.list({
    apiToken: controller.apiToken,
    params: {},
  });

  const coveragePayerOptions = [...(coveragePayerResult?.[0]?.items || [])].map(
    (item: any) => ({
      key: item.id,
      value: item.id,
      text: `[${item.code}] ${item.name}`,
    })
  );

  controller.setState(
    {
      DownloadSentClaimFileSequence: {
        sequenceIndex: "ACTION",
        coveragePayerOptions,
        filter: {
          // coveragePayer: coveragePayerOptions?.[0]?.value,
          startDate: formatDate(moment()),
          endDate: formatDate(moment()),
        },
      },
    }
    // () =>
    //   controller.handleEvent({ message: "RunSequence", params: { ...params } })
  );
};

/* ------------------------------------------------------ */

/*                      Handle Action                     */

/* ------------------------------------------------------ */
export const Action: Handler = async (controller, params: ActionType) => {
  if (params.action === "SEARCH") {
    HandleSearch(controller, params);
  } else if (params.action === "CHANGE") {
    HandleChange(controller, params);
  } else if (params.action === "UPDATE_SENT_CLAIM") {
    HandleUpdateSentClaim(controller, params);
  } else if (params.action === "DOWNLOAD_SENT_CLAIM") {
    HandleDownloadSentClaim(controller, params);
  }
};

const HandleSearch: Handler = async (controller, params: Params<"SEARCH">) => {
  const state = controller.getState();

  controller.setState({
    DownloadSentClaimFileSequence: {
      ...state.DownloadSentClaimFileSequence,
      billTransactionSummary: { isCalulating: true },
    },
  });

  const filter = state.DownloadSentClaimFileSequence?.filter || {};

  const formatParams = (isKey: keyof FilterType, value: any) => {
    return ((filter as any)[isKey] || undefined) && value;
  };

  const urlParams = {
    ar_transaction: filter.arTransaction,
    start_date: formatParams("isDate", filter.startDate),
    end_date: formatParams("isDate", filter.endDate),
    // coverage_payer_sent_claim_group: filter.coveragePayer,
    filter_only_c: filter.isOnlyC,
  };

  const [billList] = await BillTransactionList.list({
    apiToken: controller.apiToken,
    params: urlParams,
  });

  const [billSummary] = await BillTransactionSummaryList.get({
    apiToken: controller.apiToken,
    params: {
      ...urlParams,
    },
  });

  controller.setState({
    DownloadSentClaimFileSequence: {
      ...state.DownloadSentClaimFileSequence,
      billTransactionList: billList?.items || [],
      billTransactionSummary: {
        ...(billSummary || {}),
        isCalulating: false,
      },
    },
  });
};

export const HandleChange: Handler = (controller, params: Params<"CHANGE">) => {
  const state = controller.getState();

  const claim = state.DownloadSentClaimFileSequence || {};

  controller.setState(
    {
      DownloadSentClaimFileSequence: {
        ...claim,
        filter: {
          ...claim.filter,
          ...params.data,
        },
      },
    },
    () => HandleSearch(controller, params)
  );
};

export const HandleUpdateSentClaim: Handler = async (
  controller,
  params: Params<"UPDATE_SENT_CLAIM">
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_UPDATE`]: "LOADING",
    },
  });

  const data = state.DownloadSentClaimFileSequence?.filter || {};

  const claim = await DoUpdateSentClaimDataFromARList.get({
    apiToken: controller.apiToken,
    ar_transaction_id: data.arTransaction,
  });

  if (claim[1]) {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_UPDATE`]: "ERROR",
      },
    });
  } else {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_UPDATE`]: "SUCCESS",
      },
      errorMessage: {
        ...state.errorMessage,
        [params.card]: claim[1],
      },
    });
    HandleSearch(controller, params);
  }
};

const HandleDownloadSentClaim: Handler = async (
  controller,
  params: Params<"DOWNLOAD_SENT_CLAIM">
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_DOWNLOAD`]: "LOADING",
    },
  });

  const data = state.DownloadSentClaimFileSequence?.filter || {};

  const download = await GenerateSentClaimFileFromARList.get({
    apiToken: controller.apiToken,
    ar_transaction_id: data.arTransaction,
    extra: { responseType: "blob" },
  });

  if (download[1]) {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_DOWNLOAD`]: "ERROR",
      },
      errorMessage: {
        ...state.errorMessage,
        [params.card]: download[1],
      },
    });
  } else {
    try {
      downloadFile(download[2]);
    } catch (error) {}
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_DOWNLOAD`]: "SUCCESS",
      },
    });

    GetMaster(controller, params);
  }
};
