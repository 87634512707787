import React from "react";
import PropTypes from "prop-types";
import { Button, Card, Header, Grid } from "semantic-ui-react";
import { useIntl } from "react-intl";

const style = {
  root: {
    width: "100%"
  },
  noPadding: {
    padding: "0px"
  },
  formPadding: {
    padding: "10%",
    textAlign: "center"
  }
};

const CardSendDoctorQueue = props => {
  const intl = useIntl();
  return (
    <Card style={style.root}>
      <Card.Content>
        <div style={style.formPadding}>
          <Header>{intl.formatMessage({ id: "ยืนยันการส่งเข้าคิวแพทย์ เพื่อส่งปรึกษาเพิ่มเติมหรือไม่" })}</Header>
        </div>
        <Grid>
          <Grid.Row columns={2} style={style.noPadding}>
            <Grid.Column width={8} style={style.noPadding}>
              <Button fluid color="red" onClick={props.hideCallback}>{intl.formatMessage({ id: "ยกเลิก" })}</Button>
            </Grid.Column>
            <Grid.Column width={8} style={style.noPadding}>
              <Button fluid color="green">{intl.formatMessage({ id: "ยืนยัน" })}</Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card.Content>
    </Card>
  );
};

CardSendDoctorQueue.defaultProps = {
  hideCallback: () => {}
};

CardSendDoctorQueue.propTypes = {
  hideCallback: PropTypes.func
};

export default React.memo(CardSendDoctorQueue);
