
/* Generated apis ---------------------- */
import { to } from 'react-lib/utils'
import BaseService from  'react-lib/apis/services/BaseService'
import config from '../../config/config'
const HOST = `${config.API_HOST}`
/* ['barcode.BarcodeView'] */
/* params:  */
/* data:  */
const DefaultBarcodeView : 
{
  get: ({barcode, params, apiToken, extra}:
             {  barcode: any, 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
  post:   ({params, data, apiToken, extra}:
            { barcode: any, 
            params?: any,
            data?: any,
            apiToken?: any,
            extra?: any
        }) => any,
  put:   ({barcode, params, data, apiToken, extra}:
             { barcode: any, 
                params?: any,
                data?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
  patch:    ({barcode, params, data, apiToken, extra}:
             { barcode: any, 
                params?: any,
                data?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  get: async ({barcode, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken)
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    if (extra?.division)
      base.client.defaults.headers["division"] = extra.division;
    if (extra?.device)
      base.client.defaults.headers["device"] = extra.device;
    const result = await to(base.client.get(`${HOST}/apis/core/default-barcode/${barcode}/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  post: async ({barcode, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken)
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    if (extra?.division)
      base.client.defaults.headers["division"] = extra.division;
    if (extra?.device)
      base.client.defaults.headers["device"] = extra.device;
    const result = await to(base.client.post(`${HOST}/apis/core/default-barcode/${barcode}/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  put: async ({barcode, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken)
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    if (extra?.division)
      base.client.defaults.headers["division"] = extra.division;
    if (extra?.device)
      base.client.defaults.headers["device"] = extra.device;
    const result = await to(base.client.put(`${HOST}/apis/core/default-barcode/${barcode}/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  patch: async ({barcode, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken)
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    if (extra?.division)
      base.client.defaults.headers["division"] = extra.division;
    if (extra?.device)
      base.client.defaults.headers["device"] = extra.device;
    const result = await to(base.client.patch(`${HOST}/apis/core/default-barcode/${barcode}/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

export default DefaultBarcodeView

