import React, { useEffect, useMemo, useState } from 'react';
import { Checkbox, Icon, Modal } from 'semantic-ui-react';
import ModDrugRecommendationUX from './ModDrugRecommendationUX';
import { useIntl } from "react-intl";

const ModDrugRecommendation = (props: any) => {
  const intl = useIntl();
	const [selectedDrug, setSelectedDrug] = useState<number[]>([]);
	const [selectedReport, setSelectedReport] = useState<number[]>([]);

	useEffect(() => {
		setSelectedDrug([])
		setSelectedReport([])
	}, [props.modDrugRecommendation?.open])

	const handleOnAdd = (type: string, data: number) => {
		console.log("handleOnAdd:", type, data)
		if (type === "drug") {
			let selectDrugData = [...selectedDrug]
			if (selectDrugData.includes(data)) {
				selectDrugData = selectDrugData.filter((id: number) => id !== data)
			} else {

				selectDrugData.push(data)
			}
			setSelectedDrug(selectDrugData)
		} else if (type === "report") {
			let selectReportData = [...selectedReport]
			if (selectReportData.includes(data)) {
				selectReportData = selectReportData.filter((id: number) => id !== data)
			} else {
				selectReportData.push(data)
			}
			setSelectedReport(selectReportData)
		}
	}

	const drugRecommendation = useMemo(() => {
		return <div style={{ padding: "15px", fontSize: "18px" }}>
			{props.modDrugRecommendation?.data.length > 0 ?
				[...(props.modDrugRecommendation?.data || [])]?.map((drug: any) => {
					return <div key={`drug${drug.drug_id}`}>
						{/* main component show */}
						<div style={{ display: "flex" }}>
							<Icon
								style={{ paddingRight: "10px", cursor: "pointer" }}
								name={selectedDrug.includes(drug.drug_id) ? "caret down" : "caret right"}
								onClick={() => { handleOnAdd("drug", drug.drug_id) }}
							/>
							<div style={{ fontWeight: "bold", cursor: "pointer" }} 
							onClick={() => { handleOnAdd("drug", drug.drug_id) }}>{drug.drug_name}</div>
						</div>
						{/* sub component show */}
						<div style={{
							display: selectedDrug.includes(drug.drug_id) ? "flex" : "none",
							paddingLeft: "20px",
							flexDirection: "column"
						}}
						>
							{drug.items?.map((item: any) => {
								return <div key={`report${item.number}`} style={{ display: "flex", padding: "5px" }}>
									<Checkbox
										checked={selectedReport.includes(item.number)}
										style={{ marginRight: "10px", fontSize: "18px" }}
										label={item.title}
										onClick={() => { handleOnAdd("report", item.number) }}
									/>
								</div>
							}) || <></>}
						</div>
					</div>
				}
				) : <div style={{ display: "flex", flex: 1, justifyContent: "center", fontSize: "18px", color: "#4C4C4C" }}>{intl.formatMessage({ id: "ไม่พบรายการยาที่มีคำแนะนำ" })}</div>}
		</div>
	}, [props.modDrugRecommendation, selectedDrug, selectedReport])

	// console.log("ModDrugRecommendation props: ", props)
	return (
		<Modal closeOnDimmerClick={false} open={props.modDrugRecommendation?.open} size="tiny" >
			<ModDrugRecommendationUX
				drugRecommendations={drugRecommendation}
				haveData={props.modDrugRecommendation?.data?.length > 0}
				disableApprove={selectedReport.length === 0}
				onApprove={() => {
					props.onEvent({
						message: "HandleDrugRecommendation",
						params: { action: "select_report", selectedReport }
					})
				}}
				onCancel={() => {
					props.onEvent({
						message: "HandleDrugRecommendation",
						params: { action: "close" }
					})
				}}
				languageUX={props.languageUX}
			/>
		</Modal>
	)
}

export default ModDrugRecommendation


