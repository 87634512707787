import * as SetAppointment from "./SetAppointment";
import * as SetSchedule from "./SetSchedule";
import * as SetDoctorFeeReport from "./SetDoctorFeeReport";

const SequencePattern: { [name: string]: { [flavor: string] : { [func: string]: any }}} = {
  SetAppointment: { 
    default: {
      START: SetAppointment.Start,
      Edit: SetAppointment.Edit
    }    
  },
  SetSchedule: {
    default: {
      START: SetSchedule.Start,
      Edit: SetSchedule.Edit
    }
  },
  SetDoctorFeeReport: {
    default: {
      START: SetDoctorFeeReport.Start,
    }
  }
};

export default SequencePattern;
