import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import {
  applyTextSpacing,
  applyTextSpacingforBranch,
} from "react-lib/apps/HISV3/CLM/pdf/FormTaxUtils";

// PDF
const PDF = "/static/pdf/taxDoctor/270360_attach3.pdf";
const Font = "/static/fonts/THSarabunNew-Bold.ttf";

const FormPND3 = async (data: any, number: number) => {
  try {
    const existingPdfBytes = await fetch(PDF).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Load your custom font
    const fontUrl = Font; // Replace with your font URL
    const fontBytes = await fetch(fontUrl).then((res) => res.arrayBuffer());
    pdfDoc.registerFontkit(fontkit);
    const customFont = await pdfDoc.embedFont(fontBytes);

    const getDecimal = (value: number) => {
      let rounded = Math.floor(value);

      return (value - rounded || 0).toFixed(2)?.toLocaleString().slice(2, 4) || "";
    };

    const pages = pdfDoc.getPages();
    const firstPage = pages[0];
    const fontSize = 12;

    const textCoordinates = [
      { text: data?.taxId, x: 380, y: 569 },
      { text: data?.branch, x: 792, y: 570 },
      { text: data?.sheetNo, x: 715, y: 552 },
      { text: data?.quantity?.toString(), x: 785, y: 552 },
      {
        text:
          data?.fields?.[number]?.total >= 0
            ? Number(data?.fields?.[number]?.total?.toFixed(0))?.toLocaleString()
            : "",
        x:
          682 -
          customFont?.widthOfTextAtSize(
            (data?.fields?.[number]?.total || 0)?.toFixed(0)?.toLocaleString(),
            fontSize
          ),
        y: 140,
      },
      {
        text:
          data?.fields?.[number]?.total >= 0
            ? data?.fields?.[number]?.total === 0
              ? "00"
              : getDecimal(Number(data?.fields?.[number]?.total))
            : "",
        x: 690,
        y: 140,
      },
      {
        text: data?.totalTaxCost?.toLocaleString(),
        x:
          783 - customFont.widthOfTextAtSize((data?.totalTaxCost || 0)?.toLocaleString(), fontSize),
        y: 140,
      },
      {
        text:
          data?.totalTaxCostDecimal === 0
            ? "00"
            : data?.totalTaxCostDecimal?.toString().slice(0, 2),
        x: 787,
        y: 140,
      },
      { text: data?.payerFullName, x: 650, y: 75 },
      { text: data?.position, x: 670, y: 55 },
      { text: data?.submitDate, x: 620, y: 36 },
      { text: data?.submitMonth, x: 680, y: 36 },
      { text: data?.submitYear, x: 760, y: 36 },
      // Add other text fields here...
    ];

    for (const { text, x, y } of textCoordinates) {
      if (text === data?.taxId) {
        applyTextSpacing(text, x, y, customFont, fontSize, firstPage);
      } else if (text === data?.branch) {
        applyTextSpacingforBranch(text, x, y, customFont, fontSize, firstPage);
      } else {
        // Draw the text as it is without any adjustments
        firstPage.drawText(text, {
          x,
          y,
          size: fontSize,
          font: customFont,
          color: rgb(0, 0, 0),
        });
      }
    }

    // Coordinates for the earnings section
    const earnings = data?.fields?.[number]?.items;
    const initialX = 37;
    const initialY = 455;
    const yStep = 55.5;

    for (let i = 0; i < earnings?.length; i++) {
      let earning = earnings?.[i];
      let x = initialX;
      let y = initialY - yStep * i;
      // const {
      //   no,
      //   earnerId,
      //   prefix,
      //   fullName,
      //   lastName,
      //   address,
      //   earnerBranch,
      //   payDate,
      //   moneyType,
      //   taxPercent,
      //   paidAmountType,
      //   paidAmountTypeDecimal,
      //   money,
      //   moneyDecimal,
      //   condition,
      // } = earning;

      firstPage.drawText(`${earning?.seq || ""}`, {
        x: x,
        y,
        size: fontSize,
        font: customFont,
        color: rgb(0, 0, 0),
      });

      // Draw earnerId character by character with custom spacing
      applyTextSpacing(
        `${earning?.citizen_id || ""}`,
        x + 28,
        y + 17.5,
        customFont,
        fontSize,
        firstPage
      );
      // firstPage.drawText(prefix, {
      //   x: x + 39,
      //   y,
      //   size: fontSize,
      //   font: customFont,
      //   color: rgb(0, 0, 0),
      // });
      firstPage.drawText(`${earning?.first_name || ""}`, {
        x: x + 57,
        y,
        size: fontSize,
        font: customFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(`${earning?.last_name || ""}`, {
        x: x + 240,
        y,
        size: fontSize,
        font: customFont,
        color: rgb(0, 0, 0),
      });

      // firstPage.drawText(address, {
      //   x: x + 41,
      //   y: y - 18,
      //   size: fontSize,
      //   font: customFont,
      //   color: rgb(0, 0, 0),
      // });

      // Draw earnerBranch character by character with custom spacing
      //   applyTextSpacingforBranch(earnerBranch, x + 277, y + 17.5, customFont, fontSize, firstPage);
      //   firstPage.drawText(payDate, {
      //     x: x + 385,
      //     y,
      //     size: fontSize,
      //     font: customFont,
      //     color: rgb(0, 0, 0),
      //   });

      //   firstPage.drawText(moneyType, {
      //     x: x + 503,
      //     y,
      //     size: fontSize,
      //     font: customFont,
      //     color: rgb(0, 0, 0),
      //   });

      //   firstPage.drawText(taxPercent.toString(), {
      //     x: x + 551,
      //     y,
      //     size: fontSize,
      //     font: customFont,
      //     color: rgb(0, 0, 0),
      //   });

      firstPage.drawText(
        `${Number(earning?.net_income_price?.toFixed(0))?.toLocaleString() || ""}`,
        {
          x:
            682 -
            customFont?.widthOfTextAtSize(
              (earning?.net_income_price || 0)?.toFixed(0)?.toLocaleString(),
              fontSize
            ),
          y,
          size: fontSize,
          font: customFont,
          color: rgb(0, 0, 0),
        }
      );

      firstPage.drawText(
        earning?.net_income_price === 0 ? "00" : getDecimal(Number(earning?.net_income_price)),
        {
          x: 690,
          y,
          size: fontSize,
          font: customFont,
          color: rgb(0, 0, 0),
        }
      );
      //   firstPage.drawText(money?.toLocaleString(), {
      //     x: 783 - customFont.widthOfTextAtSize(money?.toLocaleString(), fontSize),
      //     y,
      //     size: fontSize,
      //     font: customFont,
      //     color: rgb(0, 0, 0),
      //   });
      //   firstPage.drawText(moneyDecimal === 0 ? "00" : moneyDecimal.toString().slice(0, 2), {
      //     x: 787,
      //     y,
      //     size: fontSize,
      //     font: customFont,
      //     color: rgb(0, 0, 0),
      //   });
      //   firstPage.drawText(condition, {
      //     x: x + 770,
      //     y,
      //     size: fontSize,
      //     font: customFont,
      //     color: rgb(0, 0, 0),
      //   });
    }

    const modifiedPdfBytes = await pdfDoc.save();
    const modifiedPdfUrl = URL.createObjectURL(
      new Blob([modifiedPdfBytes], { type: "application/pdf" })
    );

    return { uint8Array: modifiedPdfBytes, blobUrl: modifiedPdfUrl };
  } catch (error) {
    console.error("Error modifying PDF:", error);

    return {};
  }
};

export default FormPND3;
