import WasmController from "react-lib/frameworks/WasmController";

import axios from "axios";

// APIs
import EncounterList from "issara-sdk/apis/EncounterList_core";
import InvoiceItemList from "issara-sdk/apis/InvoiceItemList_apps_BIL";
import InvoiceItemRefundView from "issara-sdk/apis/InvoiceItemRefundView_apps_BILM";

export type State = {
  ORServiceRefundSequence?: any;
  errorMessage?: any;
  successMessage?: any;
};

export const StateInitial: State = {
  // sequence
  ORServiceRefundSequence: null,
  errorMessage: {
    ORServiceRefund: null,
  },
  successMessage: {
    ORServiceRefund: null,
  },
};

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const Start: Handler = async (controller, params) => {
  const [r] = await EncounterList.list({
    params: {
      patient: params.patientId,
    },
    apiToken: controller.apiToken,
  });

  const state = controller.getState();

  controller.setState({
    ORServiceRefundSequence: {
      ...state.ORServiceRefundSequence,
      sequenceIndex: "Action",
      encounterList: r?.items || [],
      data: {
        encounterSelect: 0,
      },
    },
  });
};

export const Action: Handler = async (controller, params) => {
  if (params.action === "getInvoiceItem") {
    const source = axios.CancelToken.source();

    params.sourceRef.current = source;

    const [r] = await InvoiceItemList.list({
      params: {
        encounter: params.encounterId,
        [params.mode]: true,
        // refundable,
        // refunding,
        // refunded,
      },
      apiToken: controller.apiToken,
      extra: { cancelToken: source.token },
    });

    // * โดน Cancel request
    if (r === null) {
      return [null, null, null];
    }

    const state = controller.getState();

    controller.setState({
      ORServiceRefundSequence: {
        ...state.ORServiceRefundSequence,
        data: {
          ...state.ORServiceRefundSequence.data,
          invoiceItemList: r?.items,
        },
      },
    });
  } else if (params.action === "updateInvoiceRefundView") {
    const api =
      params.type === "cancel"
        ? InvoiceItemRefundView.delete
        : InvoiceItemRefundView.put;

    const [r, e] = await api({
      pk: params.invoiceId,
      data: {
        reason: params.reason,
      },
      apiToken: controller.apiToken,
    });

    const state = controller.getState();

    if (r) {
      controller.setState({
        successMessage: { ...state.successMessage, [params.card]: "success" },
      });
    } else {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: e },
      });
    }
  }
};
