const RemoveNode = (content, id) => {
  const getDescendants = (output, contentlist, id) => {
    output.push(id) 
    for (var i=0; i < contentlist.length; i++) {
        if (contentlist[i].parent_id === id) {
            output = getDescendants(output, contentlist, contentlist[i].id)
        }
    }
    return output
  }
  const idlist = getDescendants([], Object.values(content), id)
  for (id of idlist) {
    delete content[id]  
  }
  return content
}

export default RemoveNode
