import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  FormField,
  Input,
  Button,
  Icon,
  Dropdown,
  TextArea
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardSettingMediaDocumentUX = (props: any) => {
    return(
      <div
        style={{width: "100%"}}>
        <div
          style={{display: "flex", alignItems: "center", justifyContent: "space-between", padding: "15px 10px", backgroundColor: "#A5D9FF"}}>
          
          <div
            style={{fontSize: "1.15rem", fontWeight: "bold"}}>
            การตั้งค่ารายการเอกสารยินยอม สำหรับสื่อสารผู้ป่วยก่อนเข้ารับการทำหัตถการ
          </div>
        </div>
        <div
          className="ui divider"
          style={{marginTop: 0}}>
          
        </div>
        <Form>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              style={{paddingLeft: "1rem"}}
              width={4}>
              <label
                style={{minWidth: "max-content"}}>
                ชื่อเอกสาร
              </label>
              <Input
                className="small"
                fluid={true}
                name="name"
                onChange={props.onChangeFilter}
                value={props.filter?.name || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}
              width={7}>
              <label
                style={{minWidth: "max-content"}}>
                กลุ่มงาน
              </label>
              <div
                style={{width: "100%"}}>
                {props.WorkGroupDropdownOptions}
              </div>
            </FormField>
            <FormField>
              <label
                style={{minWidth: "max-content"}}>
                วันหมดอายุของ QR code
              </label>
            </FormField>
            <FormField
              width={2}>
              <div
                style={{width: "100%", minWidth: "10rem"}}>
                
                <DateTextBox
                  onChange={(date: string)=>props.onChangeFilter?.(null, {name:"fromDate", value: date})}
                  value={props.filter?.fromDate|| ""}>
                </DateTextBox>
              </div>
            </FormField>
            <FormField>
              <label>
                ถึง
              </label>
            </FormField>
            <FormField
              width={2}>
              <div
                style={{width: "100%", minWidth: "10rem"}}>
                
                <DateTextBox
                  onChange={(date: string)=>props.onChangeFilter?.(null, {name:"toDate", value: date})}
                  value={props.filter?.toDate|| ""}>
                </DateTextBox>
              </div>
            </FormField>
            <FormField
              inline={true}>
              <div>
                {props.ButtonSearch}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <Button
                color="green"
                onClick={props.onAddNew}
                size="small"
                style={{minWidth: "max-content"}}>
                เพิ่มรายการใหม่
              </Button>
            </FormField>
          </FormGroup>
        </Form>
        <div
          style={{padding: "0 1rem"}}>
          
          <Table
            data={props.mediaDocList || []}
            getTrProps={props.onGetTrProps}
            headers="วันที่บันทึกครั้งล่าสุด,ชื่อเอกสาร,กลุ่มงาน,ภาพ/QR code,วันหมดอายุของ QR code,"
            keys="edited,name,workgroup,_qrcode,qr_expired_at,_action"
            minRows={props.showDetail ? 3 : 12}
            showPagination={false}
            style={{height: props.showDetail ? "calc(-45rem + 100dvh)" :"calc(-18rem + 100dvh)"}}
            widths="^90,^200,^120,^50,^90,150">
          </Table>
        </div>
        <div
          style={{margin: "0.5rem 1rem 0", display: "flex", justifyContent: "flex-end"}}>
          {props.Pagination}
        </div>
        <div
          style={{padding: "0 1rem", display: props.showDetail?"":"none"}}>
          
          <div
            className="ui divider"
            style={{margin: "0.75rem 0 0.5rem 0"}}>
            
          </div>
          <div
            style={{margin: "0.5rem 0 0.75rem", display: "flex", justifyContent: "flex-end", fontSize: "1.15rem"}}>
            
            <Icon
              link={true}
              name="close"
              onClick={props.onClickClose}>
            </Icon>
          </div>
          <Form>
            <FormGroup
              inline={true}
              style={{marginBottom: "0.75rem"}}>
              <FormField
                className="required"
                inline={true}
                style={{minWidth: "9rem", maxWidth: "9rem"}}>
                <label>
                  ชื่อเอกสาร
                </label>
              </FormField>
              <FormField
                className={props.showRequiredField?.name ? "error" : ""}
                inline={true}
                style={{padding:0}}
                width={15}>
                <Input
                  name="name"
                  onChange={props.onChangeValue}
                  value={props.mediaDocDetail?.name || ""}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{marginBottom: "0.75rem"}}>
              <FormField
                className="required"
                inline={true}
                style={{minWidth: "9rem", maxWidth: "9rem"}}>
                <label>
                  กลุ่มงาน
                </label>
              </FormField>
              <FormField
                className={props.showRequiredField?.workgroup ? "error" : ""}
                inline={true}
                style={{padding:0}}
                width={15}>
                <Dropdown
                  allowAdditions={true}
                  clearable={true}
                  fluid={true}
                  name="workgroup"
                  onAddItem={props.onAddItemWorkGroup}
                  onChange={props.onChangeValue}
                  options={props.workGroupsOptions || []}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.mediaDocDetail?.workgroup || ""}>
                </Dropdown>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{marginBottom: "0.75rem", alignItems: "flex-start"}}>
              <FormField
                inline={true}
                style={{minWidth: "9rem", maxWidth: "9rem"}}>
                <label>
                  QR สำหรับเอกสาร
                </label>
              </FormField>
              <FormField
                inline={true}
                style={{padding:0, alignItems:"flex-start"}}
                width={15}>
                <Button
                  color="blue"
                  onClick={props.onClickBrowse}
                  size="mini"
                  style={{minWidth: "max-content"}}>
                  <Icon
                    name="search">
                  </Icon>
                  <label>
                    Browse
                  </label>
                </Button>
                <div
                  style={{minWidth: "140px",maxWidth: "140px", height: "115px", border: "1px dashed #22242626", borderRadius: "3px", position: "relative", display: "flex",alignItems: "center", justifyContent: "center"}}>
                  
                  <img
                    src={props.qrCodeUrl}
                    style={{display: props.qrCodeUrl?"":"none", height: "90px", width: "auto"}}>
                  </img>
                  <Button
                    className="circular"
                    color="red"
                    icon="close"
                    onClick={(date: string)=>props.onChangeValue?.(null, {name:"qr_image", value: ""})}
                    size="mini"
                    style={{padding: "0.2rem", position: "absolute", right:"-7px", top: "-6px", display: props.qrCodeUrl?"":"none"}}>
                  </Button>
                </div>
                <label
                  style={{minWidth: "max-content", marginLeft: "5rem", paddingRight: "1rem"}}>
                  รายละเอียด
                </label>
                <TextArea
                  name="note"
                  onChange={props.onChangeValue}
                  rows="5"
                  value={props.mediaDocDetail?.note|| ""}>
                </TextArea>
              </FormField>
            </FormGroup>
            <FormGroup
              inline="true"
              style={{marginBottom: "0.75rem"}}>
              <FormField
                inline={true}
                style={{minWidth: "max-content"}}>
                <label>
                  วันหมดอายุของ QR code
                </label>
              </FormField>
              <FormField
                inline={true}>
                <div
                  style={{width: "100%"}}>
                  {props.ExpireDateTextBox}
                </div>
              </FormField>
            </FormGroup>
          </Form>
        </div>
        <Form>
          <FormGroup
            inline={true}
            style={{marginTop: "1.5rem", display: props.showDetail?"":"none"}}>
            <FormField
              style={{flex:1}}>
            </FormField>
            <FormField>
              <div>
                {props.ButtonSave}
              </div>
            </FormField>
            <FormField>
              <Button
                color="red"
                onClick={props.onClickCancel}
                size="small">
                ยกเลิก
              </Button>
            </FormField>
          </FormGroup>
        </Form>
      </div>
    )
}


export default CardSettingMediaDocumentUX

export const screenPropsDefault = {"current":null,"price_claimable":0,"price_non_claimable":0,"price_total":0}

/* Date Time : Mon Apr 01 2024 18:11:35 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", justifyContent: \"space-between\", padding: \"15px 10px\", backgroundColor: \"#A5D9FF\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "การตั้งค่ารายการเอกสารยินยอม สำหรับสื่อสารผู้ป่วยก่อนเข้ารับการทำหัตถการ"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.15rem\", fontWeight: \"bold\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: 0}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "Form",
      "parent": 0,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "FormGroup",
      "parent": 4,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"1rem\"}"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "label",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อเอกสาร"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "label",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "กลุ่มงาน"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "Input",
      "parent": 6,
      "props": {
        "className": {
          "type": "value",
          "value": "small"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "name"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "value": {
          "type": "code",
          "value": "props.filter?.name || \"\""
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSearch"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "Button",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มรายการใหม่"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onAddNew"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 1rem\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 17,
      "name": "Table",
      "parent": 16,
      "props": {
        "data": {
          "type": "code",
          "value": "props.mediaDocList || []"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.onGetTrProps"
        },
        "headers": {
          "type": "value",
          "value": "วันที่บันทึกครั้งล่าสุด,ชื่อเอกสาร,กลุ่มงาน,ภาพ/QR code,วันหมดอายุของ QR code,"
        },
        "keys": {
          "type": "value",
          "value": "edited,name,workgroup,_qrcode,qr_expired_at,_action"
        },
        "minRows": {
          "type": "code",
          "value": "props.showDetail ? 3 : 12"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: props.showDetail ? \"calc(-45rem + 100dvh)\" :\"calc(-18rem + 100dvh)\"}"
        },
        "widths": {
          "type": "value",
          "value": "^90,^200,^120,^50,^90,150"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.Pagination"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0.5rem 1rem 0\", display: \"flex\", justifyContent: \"flex-end\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 1rem\", display: props.showDetail?\"\":\"none\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "Form",
      "parent": 19,
      "props": {
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "FormGroup",
      "parent": 20,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.75rem\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "FormGroup",
      "parent": 20,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.75rem\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "FormGroup",
      "parent": 20,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.75rem\", alignItems: \"flex-start\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "FormField",
      "parent": 21,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"9rem\", maxWidth: \"9rem\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "FormField",
      "parent": 21,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.name ? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding:0}"
        },
        "width": {
          "type": "code",
          "value": "15"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "FormField",
      "parent": 22,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"9rem\", maxWidth: \"9rem\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "FormField",
      "parent": 22,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.workgroup ? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding:0}"
        },
        "width": {
          "type": "code",
          "value": "15"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "FormField",
      "parent": 23,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"9rem\", maxWidth: \"9rem\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "FormField",
      "parent": 23,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding:0, alignItems:\"flex-start\"}"
        },
        "width": {
          "type": "code",
          "value": "15"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "label",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อเอกสาร"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "label",
      "parent": 28,
      "props": {
        "children": {
          "type": "value",
          "value": "กลุ่มงาน"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "label",
      "parent": 30,
      "props": {
        "children": {
          "type": "value",
          "value": "QR สำหรับเอกสาร"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "Input",
      "parent": 27,
      "props": {
        "name": {
          "type": "value",
          "value": "name"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.mediaDocDetail?.name || \"\""
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "FormGroup",
      "parent": 46,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"1.5rem\", display: props.showDetail?\"\":\"none\"}"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "FormField",
      "parent": 47,
      "props": {
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "FormField",
      "parent": 47,
      "props": {
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "FormField",
      "parent": 47,
      "props": {
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 49,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSave"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "Button",
      "parent": 50,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิก"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickCancel"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0.5rem 0 0.75rem\", display: \"flex\", justifyContent: \"flex-end\", fontSize: \"1.15rem\"}"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "Icon",
      "parent": 53,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "link": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "close"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickClose"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "div",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0.75rem 0 0.5rem 0\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "code",
          "value": "props.WorkGroupDropdownOptions"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "FormField",
      "parent": 5,
      "props": {
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "FormField",
      "parent": 5,
      "props": {
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "FormField",
      "parent": 5,
      "props": {
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "FormField",
      "parent": 5,
      "props": {
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "label",
      "parent": 58,
      "props": {
        "children": {
          "type": "value",
          "value": "วันหมดอายุของ QR code"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "label",
      "parent": 60,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": null,
      "id": 64,
      "name": "div",
      "parent": 59,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", minWidth: \"10rem\"}"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "div",
      "parent": 61,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", minWidth: \"10rem\"}"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 66,
      "name": "DateTextBox",
      "parent": 64,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(date: string)=>props.onChangeFilter?.(null, {name:\"fromDate\", value: date})"
        },
        "value": {
          "type": "code",
          "value": "props.filter?.fromDate|| \"\""
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 67,
      "name": "DateTextBox",
      "parent": 65,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(date: string)=>props.onChangeFilter?.(null, {name:\"toDate\", value: date})"
        },
        "value": {
          "type": "code",
          "value": "props.filter?.toDate|| \"\""
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "Dropdown",
      "parent": 29,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "workgroup"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddItemWorkGroup"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.workGroupsOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.mediaDocDetail?.workgroup || \"\""
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "Button",
      "parent": 31,
      "props": {
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickBrowse"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "Icon",
      "parent": 69,
      "props": {
        "name": {
          "type": "value",
          "value": "search"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "label",
      "parent": 69,
      "props": {
        "children": {
          "type": "value",
          "value": "Browse"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "div",
      "parent": 31,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"140px\",maxWidth: \"140px\", height: \"115px\", border: \"1px dashed #22242626\", borderRadius: \"3px\", position: \"relative\", display: \"flex\",alignItems: \"center\", justifyContent: \"center\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "label",
      "parent": 31,
      "props": {
        "children": {
          "type": "value",
          "value": "รายละเอียด"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", marginLeft: \"5rem\", paddingRight: \"1rem\"}"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "TextArea",
      "parent": 31,
      "props": {
        "name": {
          "type": "value",
          "value": "note"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "rows": {
          "type": "value",
          "value": "5"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.mediaDocDetail?.note|| \"\""
        }
      },
      "seq": 74,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "FormGroup",
      "parent": 20,
      "props": {
        "inline": {
          "type": "value",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.75rem\"}"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "FormField",
      "parent": 75,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 77,
      "name": "FormField",
      "parent": 75,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "label",
      "parent": 76,
      "props": {
        "children": {
          "type": "value",
          "value": "วันหมดอายุของ QR code"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "div",
      "parent": 77,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ExpireDateTextBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "img",
      "parent": 72,
      "props": {
        "src": {
          "type": "code",
          "value": "props.qrCodeUrl"
        },
        "style": {
          "type": "code",
          "value": "{display: props.qrCodeUrl?\"\":\"none\", height: \"90px\", width: \"auto\"}"
        }
      },
      "seq": 81,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "Button",
      "parent": 72,
      "props": {
        "className": {
          "type": "value",
          "value": "circular"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "icon": {
          "type": "value",
          "value": "close"
        },
        "onClick": {
          "type": "code",
          "value": "(date: string)=>props.onChangeValue?.(null, {name:\"qr_image\", value: \"\"})"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0.2rem\", position: \"absolute\", right:\"-7px\", top: \"-6px\", display: props.qrCodeUrl?\"\":\"none\"}"
        }
      },
      "seq": 82,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 95,
  "isMounted": false,
  "memo": false,
  "name": "CardSettingMediaDocumentUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "current": null,
    "price_claimable": 0,
    "price_non_claimable": 0,
    "price_total": 0
  },
  "width": 55
}

*********************************************************************************** */
