import * as I from "./BillPayment";
import * as BillingHistoryI from "./BillingHistory";
import * as MedicalFeeHistoryI from "./MedicalFeeHistory";
import * as InvoicesSummaryI from "./InvoicesSummary";
import * as FinancialAmountSetI from "./FinancialAmountSet";
import * as BillingDepositI from "./BillingDeposit";
import * as BillingDepositHistoryI from "./BillingDepositHistory";
import * as FinancialReportI from "./FinancialReport";
import * as ClinicAnnualReportI from "./ClinicAnnualReport";
import * as BillPaymentArI from "react-lib/apps/HISV3/BIL/sequence/BillPaymentAr"

const SequencePattern: {
  [name: string]: { [flavor: string]: { [func: string]: any } };
} = {
  BillPayment: {
    default: {
      START: I.BeforePaymentCheck,
      PaymentCalculate: I.PaymentCalculate,
      CreateReceipt: I.CreateReceipt,
    },
  },
  BillingHistory: {
    default: {
      START: BillingHistoryI.Start,
      Action: BillingHistoryI.Action,
    },
  },
  MedicalFeeHistory: {
    default: {
      START: MedicalFeeHistoryI.Start,
      SearchAndEdit: MedicalFeeHistoryI.SearchAndEdit,
    },
  },
  InvoicesSummary: {
    default: {
      START: InvoicesSummaryI.Start,
    },
  },
  FinancialAmountSet: {
    default: {
      START: FinancialAmountSetI.Start,
      Action: FinancialAmountSetI.Action,
      ModalAction: FinancialAmountSetI.ModalAction,
    },
  },
  BillingDeposit: {
    default: {
      START: BillingDepositI.Start,
      Action: BillingDepositI.Action,
    },
  },
  BillingDepositHistory: {
    default: {
      START: BillingDepositHistoryI.Start,
      Action: BillingDepositHistoryI.Action,
    },
  },
  FinancialReport: {
    default: {
      START: FinancialReportI.Start,
      Action: FinancialReportI.Action,
    },
  },
  ClinicAnnualReport: {
    default: {
      START: ClinicAnnualReportI.Start,
      Action: ClinicAnnualReportI.Action,
    },
  },
  BillPaymentAr: {
    default: {
      START: BillPaymentArI.Start,
      Action: BillPaymentArI.Action,
    },
  },
};

export default SequencePattern;
