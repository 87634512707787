import config from "../../../config/config";

// level:
// 7 - debug
// 6 - info
// 4 - warning
// 3 - error

class GrayLogger {
  static _instance: any;
  defaultBody: { version: string; host: any; _API_HOST: string; short_message: string; level: number; _project: string; _site: string; _prog_lang: string;  _platform: string; _version?: string  };
  requestOptions: {
    method: string;
    headers: { Authorization: string; "Access-Control-Allow-Origin": string; "Access-Control-Allow-Credentials": string };
    body: string;
  };

  constructor() {
    this.defaultBody = {
      version: "1.1",
      host: window.location.protocol + "//" + window.location.host,
      _API_HOST: config.API_HOST,
      short_message: "GrayLogger",
      level: 7,
      _project: config.COMPANY,
      _site: "firebase",
      _prog_lang: "js",
      _platform: "frontend",
      // _os:,
      // _os_version: ,
      // _version:,
      
    };
    this.requestOptions = {
      method: "POST",
      headers: { Authorization: "Basic Ykc5bloyVnk6QTI1UnI2c21CbXRNTXFpTGk4VzNRT1NEM3dvNGF1NE0=" },
      body: "",
    };

    if (GrayLogger._instance) {
      return GrayLogger._instance;
    }

    GrayLogger._instance = this;
    // ... your rest of the constructor code goes after this
  }

  debug = (message: string, info: any = {}) => {
    let prepareBody = { ...this.defaultBody, ...{ short_message: message }, ...{ level: 7 }, ...info };
    this.postToGrayLog(prepareBody);
  };
  info = (message: string, info: any = {}) => {
    let prepareBody = { ...this.defaultBody, ...{ short_message: message }, ...{ level: 6 }, ...info };
    this.postToGrayLog(prepareBody);
  };
  warning = (message: string, info: any = {}) => {
    let prepareBody = { ...this.defaultBody, ...{ short_message: message }, ...{ level: 4 }, ...info };
    this.postToGrayLog(prepareBody);
  };
  error = (message: string, info: any = {}) => {
    let prepareBody = { ...this.defaultBody, ...{ short_message: message }, ...{ level: 3 }, ...info };
    this.postToGrayLog(prepareBody);
  };

  getBuildVersion = () => {
    const metas = document.getElementsByTagName("meta");

    for (let i = 0; i < metas.length; i++) {
      let version = metas[i].getAttribute("build-version");
      if (version) {
        if (version.includes("REACTBUILDVERSION")) {
          return "Debug Mode";
        } else {
          return version;
        }
      }
    }

    return "";
  };

  postToGrayLog = (body: any) => {
    const mobNative = typeof (window as any).MobNative !== "undefined" ? (window as any).MobNative : null;
    let deviceMobile = {};
    let deviceDesktop = {};

    if (mobNative) {
      deviceMobile = { _deviceType: mobNative.getDeviceType(), _deviceId: mobNative.getDeviceId(), _deviceName: mobNative.getDeviceName() };
    }

    deviceDesktop = {
      _build_version: this.getBuildVersion(),
      _userAgent: navigator.userAgent,
      _appVersion: navigator.appVersion,
      _appCodeName: navigator.appCodeName,
    };

    let prepareBody = { ...body, ...deviceMobile, ...deviceDesktop };
    this.requestOptions.body = JSON.stringify(prepareBody);

    // this.requestOptions.headers['Access-Control-Allow-Origin'] = 'http://localhost:3000'
    // this.requestOptions.headers['Access-Control-Allow-Credentials'] =  'true'

    fetch("https://log.drjaysayhi.com:22202/gelf", this.requestOptions)
      .then((response) => {
        console.log(" response.json()", response);
      })
      .catch((error) => {
        console.error(" error", error);
      });

    // this.requestOptions.body = JSON.stringify(body);
    // axios.post('https://log.drjaysayhi.com:22202/gelf',
    //   body,
    //   {
    //     headers: this.requestOptions.headers
    //   })
    // .then((e) => {console.log(e) })
    // .catch((err) => {console.log(err)});
  };
}

export default GrayLogger;

// fetch('https://log.drjaysayhi.com:22202/gelf', this.requestOptions)
// .then(response => { console.log(" response.json()", response.json()) } )

// axios.post('https://log.drjaysayhi.com:22202/gelf',  body, {headers: this.requestOptions.headers}).then( (e) => {console.log(e) }).catch((err) => {console.log(err)})

// axios({
//   method: 'post',
//   data: body,
//   url: 'https://log.drjaysayhi.com:22202/gelf',
//   headers: this.requestOptions.headers,
// })

// let body2 = {
//   version: "1.1",
//   host: "127.0.0.1",
//   short_message: "Zr Test",
//   level: 7,
// };
