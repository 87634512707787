import React, { useState, useRef } from "react";
import { Dimmer, Loader, Button, Icon, Form } from "semantic-ui-react";
import Cookies from "js-cookie"
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import "semantic-ui-css/semantic.min.css";
import LineLoginButton from "react-lib/apps/IsHealth/Common/Line/LoginButton";
import FBLoginButton from "react-lib/apps/IsHealth/Common/FacebookLogin/FBLoginButton";
import settings from "../config/config";
import LoginForm from "react-lib/apps/IsHealth/Common/PasswordLogin/LoginForm";
import { useIntl } from "react-intl";

const PlatformLogin = (props: any) => {
  const intl = useIntl();
  const history = useHistory();
  const lineButtonRef = useRef();
  // const [username, setUsername] = useState("");
  // const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // const [deviceId, setDeviceId] = useState(null);

  // React.useEffect(() => {
  //   setDeviceId(props.device_id)
  //   if (props.device_id !== null) {
  //     loadFbLoginApi(props.device_id);
  //   }

  // }, [props.device_id])

  const loadFbLoginApi = (_deviceId) => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "3314489911914234",
        cookie: true,
        xfbml: true,
        version: "v9.0",
      });
      window.FB.AppEvents.logPageView();

      // window.FB.Event.subscribe("auth.statusChange", response => {
      //   if (response.authResponse) {
      //     checkLoginState(_deviceId);
      //   } else {
      //     checkLoginState(_deviceId);
      //   }
      // });

      // window.FB.Event.subscribe("auth.statusChange", response => {
      //   // if (response.authResponse) {
      //   //   checkLoginState();
      //   // } else {
      //   //   checkLoginState();
      //   // }
      //   console.log("auth_response_change_callback");
      //   console.log(response);
      //   if (response.authResponse) {
      //     checkLoginState();
      //   }
      // });

      // checkLoginState();
      // window.FB.getLoginStatus(response => {
      //   if ( response.status !== "connected" ) {
      //     // not login facebook
      //   } else {
      //     checkLoginState()
      //   }
      // });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/th_TH/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  };

  const responseFacebook = async (fbResponse, _deviceId) => {
    // console.log(" responseFacebook", _deviceId)
    if (
      fbResponse.status === "not_authorized" ||
      fbResponse.status === "unknown"
    ) {
      return;
    }
    setIsLoading(true);
    if (fbResponse.authResponse && fbResponse.authResponse.accessToken) {
      if (fbResponse.authResponse) {
        props.onLoginFacebookSiteSuccess(fbResponse.authResponse);
        await window.FB.api(
          "/me",
          "get",
          { fields: "email,name,picture" },
          async (res) => {
            console.log("res", res);
            // if (!("email" in res)) {
            //   alert("User ไม่ได้อนุญาติ ให้เข้าถึง email-address");
            //   setIsLoading(false);
            //   props.openSetupEmail();
            //   return;
            // }

            console.log(" res", res);

            let [response, error, network] =
              await props.controller.getRegisterByTokenFacebook({
                accessToken: fbResponse.authResponse.accessToken,
                app: settings.APP_NAME,
                device_id: _deviceId,
              });

            if (response) {
              props.facebookLogin(res.name);
              props.onLoginSuccess(response);
            } else {
              setIsLoading(false);
            }
          }
        );
      }
    }
  };

  const gotoLineLogin = () => {
    if (props.lineLoginManager) {
      props.lineLoginManager.openLogin();
    }
  };

  React.useEffect(() => {
    loadFbLoginApi();
    if (Cookies.get("apiToken")) {
      props.onHaveApiToken();
    }
  }, []);

  const checkLoginState = (_deviceId) => {
    window.FB.getLoginStatus((response) => {
      responseFacebook(response, _deviceId);
    });
  };

  const handleFBClick = () => {
    window.FB.login(
      function (response) {
        if (response.authResponse) {
          // console.log('Welcome!  Fetching your information.... ');
          // window.FB.api('/me', function(response) {
          //   console.log("response", response)
          //   console.log('Good to see you, ' + response.name + '.');
          // });
          console.log("Login success ", response);
          checkLoginState(props.device_id);
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      { scope: "public_profile,email" }
    );
  };

  return (
    <Dimmer.Dimmable dimmed={isLoading}>
      <div className="Login">
        {/* <div style={{ float: "left", width: "50%" }}>{props.children}</div>
        <div className="fbLogin" style={{ float: "right", width: "50%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              alt="logo"
              style={{ width: "30%", marginBottom: "12px" }}
              src={process.env.PUBLIC_URL + "/static/images/logo-circle.png"}
            />
            {settings.PENTA_ACC_LINE_LOGIN ? (
              <LineLoginButton
                onClick={gotoLineLogin}
                ref={lineButtonRef}
                textStyle={{ margin: "25px 70px" }}
                logoStyle={{ width: "40px", height: "40px" }}
                style={{
                  height: "40px",
                  margin: "auto",
                  marginBottom: "30px",
                  maxWidth: "284px",
                  display: "block",
                }}
              />
            ) : null}
            {settings.PENTA_ACC_FACEBOOK_LOGIN ? (
              <FBLoginButton
                onClick={handleFBClick}
                style={{
                  height: "40px",
                  margin: "auto",
                  marginBottom: "30px",
                  maxWidth: "284px",
                  display: "block",
                }}
                textStyle={{ margin: "25px 45px" }}
                logoStyle={{ height: "40px", padding: "5px" }}
              />
            ) : null}
            {settings.PENTA_ACC_EMAIL_LOGIN ? (
              <div
                onClick={() => {
                  history.push("/EmailLogin");
                }}
                style={{
                  backgroundColor: "white",
                  color: "#5DBCD2",
                  border: "2px solid #5DBCD2",
                  margin: "0 auto",
                  width: "278px",
                  height: "40px",
                  maxWidth: "284px",
                  lineHeight: "38px",
                  cursor: "pointer",
                }}
              >
                <Icon
                  style={{ padding: "5px", float: "left" }}
                  name="mail"
                  size="big"
                />
                <span style={{ margin: "0 10px" }}>{intl.formatMessage({ id: "ลงชื่อเข้าใช้งานด้วยอีเมล" })}</span>
              </div>
            ) : null}
          </div>
        </div> */}
        <div className="fbLogin">
          {settings.PENTA_ACC_LINE_LOGIN ? (
            <LineLoginButton
              onClick={gotoLineLogin}
              ref={lineButtonRef}
              textStyle={{ margin: "25px 70px" }}
              logoStyle={{ width: "40px", height: "40px" }}
              style={{
                height: "40px",
                margin: "auto",
                marginBottom: "30px",
                maxWidth: "284px",
                display: "block",
              }}
            />
          ) : null}
          {settings.PENTA_ACC_FACEBOOK_LOGIN ? (
            <FBLoginButton
              onClick={handleFBClick}
              style={{
                height: "40px",
                margin: "auto",
                marginBottom: "30px",
                maxWidth: "284px",
                display: "block",
              }}
              textStyle={{ margin: "25px 45px" }}
              logoStyle={{ height: "40px", padding: "5px" }}
            />
          ) : null}
          {settings.EMAIL_LOGIN || settings.PENTA_ACC_EMAIL_LOGIN ? (
            <div
              onClick={() => {
                history.push("/EmailLogin");
              }}
              style={{
                backgroundColor: "white",
                color: "#5DBCD2",
                border: "2px solid #5DBCD2",
                margin: "0 auto",
                width: "278px",
                height: "40px",
                maxWidth: "284px",
                lineHeight: "38px",
                cursor: "pointer",
              }}
            >
              <Icon
                style={{ padding: "5px", float: "left" }}
                name="mail"
                size="big"
              />
              <span style={{ margin: "0 10px" }}>{intl.formatMessage({ id: "ลงชื่อเข้าใช้งานด้วยอีเมล" })}</span>
            </div>
          ) : null}
        </div>
        {settings.USERNAME_PASSWORD_LOGIN ? (
          <div className="pwdLogin">
            <LoginForm
              usernameText="Email or phone no."
              onLoginSuccess={props.onLoginSuccess}
            />
          </div>
        ) : null}
      </div>
      <Dimmer active={isLoading} inverted>
        <Loader size="massive">{"Loading"}</Loader>
      </Dimmer>
    </Dimmer.Dimmable>
  );
};

PlatformLogin.defaultProps = {
  controller: null,
  lineLoginManager: null,
  onLoginSuccess: () => {},
  onLoginFacebookSiteSuccess: () => {},
  onHaveApiToken: () => {},
  openSetupEmail: () => {},
  redirectUri: null,
};

PlatformLogin.propTypes = {
  controller: PropTypes.object,
  lineLoginManager: PropTypes.object,
  onLoginSuccess: PropTypes.func,
  onLoginFacebookSiteSuccess: PropTypes.func,
  onHaveApiToken: PropTypes.func,
  openSetupEmail: PropTypes.func,
  redirectUri: PropTypes.string,
};

export default PlatformLogin;
