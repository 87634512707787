import React, {
  useState,
  useEffect,
  useCallback,
} from "react";
import moment from "moment";
import CardLayout from "../common/CardLayout";
import FilterDateRange from "../common/FilterDateRange";
import ModalVerifyUser from "./ModalVerifyUser";
import ModalUnauthrizedUser from "./ModalUnauthorizedUser";
import ReactTable from "react-table-6";
import PropTypes from "prop-types";
import CardLabReport from "./CardLabReport";

import withFixedColumns from "react-table-hoc-fixed-columns";
import "react-table-hoc-fixed-columns/lib/styles.css";
import {
  Form,
  Button,
  Divider,
  Grid,
  Checkbox,
  Dropdown,
  Modal,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import ModalLabChart from "./ModalLabChart";
import { useIntl } from "react-intl";

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const COLORS = {
  red: "#EE1C24",
  blue: "#1621A8",
  green: "#44A110",
  light_blue: "#1C99F3",
  light_green: "#64F315",
};

type PointColorsKey = keyof typeof POINT_COLORS;

const POINT_COLORS = {
  C040: {
    High: COLORS.red,
    Normal: COLORS.blue,
    Low: COLORS.red,
  }, // Glucose
  C880: {
    High: COLORS.red,
    Normal: COLORS.light_blue,
    Low: COLORS.red,
  }, // HbA1c
  C062: {
    High: COLORS.red,
    Normal: COLORS.green,
    Low: COLORS.red,
  }, // HDl
  C640: {
    High: COLORS.red,
    Normal: COLORS.light_blue,
    Low: COLORS.red,
  }, // LDL
};

const LabResultTable = React.memo((props: any) => {
  console.log("props.data", props.labResultTableData);
  return (
    <ReactTableFixedColumns
      // @ts-ignore
      data={props.labResultTableData}
      columns={props.columns}
      showPagination={false}
      getNoDataProps={() => {
        return { style: { display: "none" } };
      }}
      pageSize={
        props.labResultTableData &&
        props.labResultTableData.length &&
        props.labResultTableData.length > 14
          ? props.labResultTableData.length
          : 14
      }
      style={
        props.labResultTableData &&
        props.labResultTableData.length &&
        props.labResultTableData.length > 14
          ? {
              height: "500px",
            }
          : null
      }
      className="-highlight"
      minRows={1}
      subRowsKey={"children"}
      onResizedChange={(newResized: any, event: any) => {}}
    />
  );
});

const SearchLabTable = (props: any) => {
  const [scroll, setScroll] = useState(
    props.data && props.data.length && props.data.length > 20 ? true : false
  );

  useEffect(() => {
    if (props.data && props.data.length && props.data.length > 20) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  }, [props.data]);

  return (
    <ReactTableFixedColumns
      // @ts-ignore
      data={props.data}
      columns={props.columns}
      expanded={props.selectLabExpand}
      expanderDefaults={{
        sortable: false,
        resizable: true,
        filterable: false,
      }}
      onExpandedChange={(expanded: any, index: number, event: any) => {
        console.log("onExpandedChange expanded", expanded);
        console.log("onExpandedChange index", index);
        console.log("onExpandedChange event", event);
        // Find Expand
        let parentCount =
          props.data && props.data.length ? props.data.length : 0;
        let childCount = 0;
        // Adjust PageSize and setHeight
        Object.keys(expanded).forEach(function (key) {
          if (expanded[key]) {
            childCount += props.data[key].children.length;
          }
        });
        if (parentCount + childCount > 20) {
          setScroll(true);
        } else {
          setScroll(false);
        }

        props.setSelectLabExpand(expanded);
      }}
      pageSize={props.data && props.data.length ? props.data.length : 20}
      style={scroll ? { height: "500px" } : null}
      subRowsKey={"children"}
      showPagination={false}
      getNoDataProps={() => {
        return { style: { display: "none" } };
      }}
      minRows={1}
    />
  );
};

const CardLabResult = React.memo((props: any) => {
  const intl = useIntl();
  const {
    labCode,
    labDetail,
    labError,
    closeable,
    toggleable,
    hideHeaderIcon,
    getLabCode,
    getLabDetail,
    getInterfaceSummaryReportDetail,
    getLabResultList,
    reportDetail,
    patientId,
    isLoading,
    patientInfo,
    centralLabResultList,
    onClearLabResult,
  } = props;

  const divisionList = labCode.division;
  const labNameResultList = labCode.filterResult;

  const [authmodalOpen, setAuthModalOpen] = useState(false);
  const [unauthmodalOpen, setUnauthmodalOpen] = useState(false);

  const [dispatchAction, setDispatchAction] = useState<Record<
    string,
    any
  > | null>(null);
  const [selectedDivision, setSelectedDivision] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState<
    Record<string, any>
  >({});

  const [selectLabExpand, setSelectLabExpand] = useState({});

  const [openCardLabReport, setOpenCardLabReport] = useState(false);
  const [labReportLoading, setLabReportLoading] = useState(false);

  const [labResultLoading, setLabResultLoading] = useState(false);

  const [labResultData, setLabResultData] = useState([]);

  let divisionOptions =
    divisionList && divisionList.items
      ? divisionList.items.map((item: any) => {
          return {
            key: item.id,
            value: item.synonym,
            text: item.name,
          };
        })
      : [];

  let labNameResultListItems = labNameResultList ? labNameResultList.items : [];
  // let labResultDataItems = labResultData ? labResultData.items : [];

  let labresultColumnsInit = [
    {
      width: 35,
      fixed: "left",
      expander: true,
      Expander: ({ isExpanded, ...rest }: any) => {
        // test your condition for Sub-Component here
        // I am using the presence of no comments

        if (!rest.original.hasOwnProperty("children")) {
          return <span> </span>;
        } else {
          return (
            <div>
              {isExpanded ? (
                <span
                  style={{
                    display: "inline-block",
                    transform: "rotate(90deg)",
                  }}
                >
                  &#10148;
                </span>
              ) : rest.original.children &&
                rest.original.children.length === 0 ? (
                <span> </span>
              ) : (
                <span>&#10148;</span>
              )}
            </div>
          );
        }
      },

      getProps: (state: any, rowInfo: any, column: any) => {
        if (rowInfo) {
          // same test as above

          if (
            !rowInfo.original.children ||
            (rowInfo.original.children &&
              rowInfo.original.children.length === 0)
          ) {
            // hijack the onClick so it doesn't open

            return {
              onClick: () => {},
            };
          }
        }

        return { className: "show-pointer" };
      },
      style: {
        textAlign: "center",
        userSelect: "none",
      },
    },
    {
      id: "labname",
      Header: "LAB NAME",
      accessor: "name",
      headerStyle: { whiteSpace: "unset" },
      style: { whiteSpace: "unset" },
      fixed: "left",
    },
    {
      id: "refvalue",
      Header: "Reference Value",
      accessor: "ref_value",
      headerStyle: { whiteSpace: "unset" },
      style: { whiteSpace: "unset" },
      fixed: "left",
    },
    {
      id: "unit",
      Header: "Unit",
      accessor: "lab_unit",
      fixed: "left",
    },
  ];

  const [labResultColumns, setLabResultColumn] = useState(labresultColumnsInit);

  const formatLabDate = useCallback((date: string) => {
    const dateMM = moment(date, "DD/MM/YYYY HH:mm:ss");
    const labelDate =
      dateMM.format("DD/MM/YYYY") + " [" + dateMM.format("HH:mm") + "]";

    return labelDate;
  }, []);

  const getValueByDate = useCallback((data: any, date: string) => {
    const obj = { ...data };
    const dates = Object.keys(data).filter((acc) => acc.search(" ") >= 0);

    for (const date of dates) {
      //* 15/12/2566 09:15:37 to 15/12/2566 [09:15]
      const formattedDate = date.replace(/(\d{2}:\d{2}):(\d{2})$/, "[$1]");

      obj[formattedDate] = obj[date];

      delete obj[date];
    }

    return obj[date];
  }, []);

  useEffect(() => {
    let labCompare = labDetail.labCompare;
    let labResultItems = labCompare ? labCompare.items : [];
    setLabResultData(labResultItems);

    if (labCompare && labCompare.columns && labCompare.columns.length) {
      const columns = labCompare.columns.map((column: any) =>
        formatLabDate(column)
      );

      const columnsUnique = [...new Set(columns)];

      columnsUnique.forEach((date: any) => {
        labresultColumnsInit.push({
          id: date,
          Header: (props: any) => <span>{date}</span>,
          headerStyle: { whiteSpace: "unset" },
          Cell: (d: any) => {
            if (d.original.is_confidential) {
              return (
                <Button color="green" size="tiny" onClick={handleOpen}>
                  Verify
                </Button>
              );
            } else if (!d.original.for_pdf) {
              return (
                <div
                  style={{
                    cursor: "pointer",
                  }}
                  key={date}
                  onDoubleClick={() => {
                    console.log(patientId, d.original.lab_id);
                    handleGetLabResultList({
                      product_id: d.original.lab_id,
                      patient: patientId,
                    });
                  }}
                >
                  {" "}
                  {getValueByDate(d.original, date)}{" "}
                </div>
              );
            } else {
              return (
                <Button
                  primary
                  fluid
                  onClick={() => {
                    setOpenCardLabReport(getValueByDate(d.original, date));
                  }}
                >
                  PDF
                </Button>
              );
            }
          },
        });
      });
    }

    setLabResultColumn(labresultColumnsInit);
  }, [props.labDetail]);

  useEffect(() => {
    let query = {
      patientId: patientId,
      divisionList: selectedDivision,
      startDate: "",
      stopDate: "",
    };

    async function fetchLabCode() {
      await getLabCode(query);
      setLabResultLoading(false);
    }

    if (isLoading === null) {
      setLabResultLoading(true);
      fetchLabCode();
    } else {
      getLabCode(query);
    }
  }, [patientId]);

  useEffect(() => {
    if (labError && labError.__all__ && labError.__all__.length > 0) {
      setUnauthmodalOpen(true);
    }
  }, [labError]);

  const handleSearchLabDetail = async ({
    productId,
    date,
    username,
    password,
  }: {
    productId: any[];
    date: any;
    username: string;
    password: string;
  }) => {
    let query = {
      LabCode: productId,
      startDate: date && date.startDate ? date.startDate.toString() : "",
      stopDate: date && date.endDate ? date.endDate.toString() : "",
      username,
      password,
    };

    if (isLoading === null) {
      setLabResultLoading(true);
      await getLabDetail(query);
      setLabResultLoading(false);
    } else {
      getLabDetail(query);
    }
  };

  const handleSearchLabResultSubmit = (params?: any) => {
    let productId: any[] = [];
    let username = "";
    let password = "";

    labNameResultListItems.forEach((item: any) => {
      if (selectedProductId.hasOwnProperty(item.id)) {
        productId.push(item.product_id);
      }
      if (item.children && item.children.length > 0) {
        item.children.forEach((i: any) => {
          if (selectedProductId.hasOwnProperty(i.id)) {
            productId.push(i.product_id);
          }
        });
      }
    });

    if (params && params.username && params.password) {
      username = params.username;
      password = params.password;
    }

    setDispatchAction({
      type: "GETFILTERDATE",
      requestField: ["startDate", "endDate"],
      callbackFilter: (date: any) => {
        handleSearchLabDetail({ productId, date, username, password });
      },
    });
  };

  const handleOpen = () => {
    setAuthModalOpen(true);
  };

  const SelectAllAndSearch = () => {
    //
    let selectedCodeLength =
      selectedProductId &&
      Object.keys(selectedProductId) &&
      Object.keys(selectedProductId).length
        ? Object.keys(selectedProductId).length
        : 0;
    let labNameResultListItemsLength =
      labNameResultListItems && labNameResultListItems.length
        ? labNameResultListItems.length +
          labNameResultListItems.reduce((total: number, value: any) => {
            if (value && value.children) {
              return total + value.children.length;
            }
            return 0;
          }, 0)
        : 0;

    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 14,
          }}
        >
          <Checkbox
            label="Select All"
            defaultIndeterminate={
              selectedCodeLength < labNameResultListItemsLength &&
              selectedCodeLength > 0
            }
            checked={selectedCodeLength === labNameResultListItemsLength}
            onClick={handleSelectAll}
          />
          <Button
            color="green"
            icon="arrow right"
            content="Show"
            labelPosition="right"
            onClick={handleSearchLabResultSubmit}
          />
        </div>
      </>
    );
  };

  const searchCodeColumns = [
    {
      id: "checkbox",
      accessor: "",
      Cell: ({ original }: any) => {
        return (
          <Checkbox
            checked={selectedProductId ? selectedProductId[original.id] : false}
            onClick={checkboxSelected(original.id)}
          />
        );
      },
      Header: "",
      sortable: false,
      width: 30,

      style: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 0,
        margin: 0,
      },
      resizable: false,
    },
    {
      Header: "Code",
      accessor: "lab_code",
      width: 80,
    },
    {
      Header: "Name",
      // width: 300,
      resizable: false,
      expander: true,
      style: { whiteSpace: "unset" }, // WordWrap

      Expander: ({ isExpanded, ...rest }: any) => {
        // test your condition for Sub-Component here
        // I am using the presence of no comments
        if (!rest.original.hasOwnProperty("children")) {
          return <div>&nbsp;&nbsp;&nbsp;{rest.original.name}</div>;
        } else if (
          rest.original.hasOwnProperty("children") &&
          rest.original.children.length === 0
        ) {
          return <div>{rest.original.name}</div>;
        } else {
          return (
            <div>
              {isExpanded ? (
                <>
                  <span
                    style={{
                      display: "inline-block",
                      transform: "rotate(90deg)",
                    }}
                  >
                    &#10148;
                  </span>
                  <span> {rest.original.name} </span>
                </>
              ) : (
                <span>&#10148; {rest.original.name} </span>
              )}
            </div>
          );
        }
      },

      getProps: (state: any, rowInfo: any, column: any) => {
        if (rowInfo) {
          // same test as above

          if (
            !rowInfo.original.children ||
            (rowInfo.original.children &&
              rowInfo.original.children.length === 0)
          ) {
            // hijack the onClick so it doesn't open

            return { onClick: () => {} };
          }
        }

        return { className: "show-pointer" };
      },
    },
  ];

  const checkboxSelected = (code: any) => (data: any, props: any) => {
    if (props.checked) {
      if (labNameResultListItems) {
        let majorCode = labNameResultListItems
          ? labNameResultListItems.filter((item: any) => {
              return item.id === code;
            })
          : [];

        if (majorCode && majorCode.length > 0) {
          setSelectedProductId((d) => {
            let child = majorCode[0].children
              ? majorCode[0].children.reduce((total: any, value: any) => {
                  return { [value.id]: true, ...total };
                }, {})
              : {};

            let pre = { ...d, [code]: true, ...child };

            return pre;
          });
        } else {
          setSelectedProductId((d) => ({ ...d, [code]: true }));
        }
      }
    } else {
      setSelectedProductId((d) => {
        delete d[code];
        if (labNameResultListItems) {
          let majorCode = labNameResultListItems
            ? labNameResultListItems.filter((item: any) => {
                return item.id === code;
              })
            : [];
          if (majorCode && majorCode.length > 0) {
            majorCode[0].children.forEach((item: any) => {
              delete d[item.id];
            });
          }
        }
        return { ...d };
      });
    }
  };

  const handleSelectAll = (e: any, props: any) => {
    if (props.checked && !props.defaultIndeterminate) {
      setSelectedProductId((d) => {
        return labNameResultListItems.reduce((total: any, value: any) => {
          let child = {};
          if (value && value.children) {
            child = value.children.reduce((total: any, value: any) => {
              let pre = { [value.id]: true, ...total };

              return pre;
            }, {});
          }

          let prepare = { [value.id]: true, ...child, ...total };

          return prepare;
        }, {});
      });
    } else {
      setSelectedProductId({});
    }
  };

  const handleSearchDivisionSubmit = async (e: any) => {
    let query = {
      divisionList: selectedDivision,
      startDate: "",
      stopDate: "",
    };

    if (isLoading === null) {
      setLabResultLoading(true);
      await getLabCode(query);
      setLabResultLoading(false);
    } else {
      getLabCode(query);
    }
    //
    // Clear filter
  };

  const handleChangeDivisionSelect = (props: any, { value }: any) => {
    let keys = [];
    if (value.length > 0) {
      keys = value.map((item: any) => {
        return divisionOptions.find((element: any) => element.value === item)
          .key;
      });
    }

    setSelectedDivision(keys);

    // Call API
  };

  const handleOnStartDateChange = (e: any) => {};

  const handleOnStopDateChange = (e: any) => {};

  const handleOnFilterDate = (e: any) => {
    handleSearchLabResultSubmit();
  };

  const handleSubmitVerify = (params: any) => {
    setAuthModalOpen(false);
    handleSearchLabResultSubmit(params);
  };

  const handleModVerifyUserCancel = () => {
    setAuthModalOpen(false);
  };

  const handleUnVerifyOnClose = () => {
    setUnauthmodalOpen(false);
  };

  const handleGetReportList = async (params: any) => {
    setLabReportLoading(true);
    await getInterfaceSummaryReportDetail(params);
    setLabReportLoading(false);
  };

  const handleGetLabResultList = async (params: any) => {
    setLabReportLoading(true);
    await getLabResultList?.(params);
    setLabReportLoading(false);
  };

  console.log("labNameResultList", labNameResultList);
  return (
    <>
      {props.centralLabResultList && (
        <ModalLabChart
          centralLabResultList={centralLabResultList}
          labNameResultList={labNameResultList}
          genderName={props.patientInfo?.gender_name || ""}
          onClose={onClearLabResult}
          languageUX={props.languageUX}
        />
      )}

      <ModalUnauthrizedUser
        open={unauthmodalOpen}
        onClose={handleUnVerifyOnClose}
        languageUX={props.languageUX}
      />
      <ModalVerifyUser
        handleSubmitVerify={handleSubmitVerify}
        open={authmodalOpen}
        onCancel={handleModVerifyUserCancel}
        languageUX={props.languageUX}
      />

      <CardLayout
        titleText="Lab Result History"
        closeable={closeable}
        toggleable={toggleable}
        hideHeaderIcon={hideHeaderIcon}
      >
        <Dimmer
          active={isLoading !== null ? isLoading : labResultLoading}
          inverted
        >
          <Loader />
        </Dimmer>

        <Form onSubmit={handleSearchDivisionSubmit}>
          <Form.Group inline>
            <Form.Field>
              <label> Lab Division </label>
            </Form.Field>
            <Dropdown
              multiple
              selection
              placeholder="All Division"
              options={divisionOptions}
              style={{ marginRight: 14 }}
              onChange={handleChangeDivisionSelect}
            />
            <Form.Field>
              <Button type="submit" color={"blue"}>{intl.formatMessage({ id: "ค้นหา" })}</Button>
            </Form.Field>
          </Form.Group>
        </Form>
        <Divider />

        <Grid columns="two" divided>
          <Grid.Row>
            <Grid.Column width="5">
              <SelectAllAndSearch setDispatchAction={setDispatchAction} />
              <SearchLabTable
                data={labNameResultListItems}
                columns={searchCodeColumns}
                selectLabExpand={selectLabExpand}
                setSelectLabExpand={setSelectLabExpand}
                languageUX={props.languageUX}
              />
            </Grid.Column>
            <Grid.Column width="11">
              <Form>
                <Form.Group inline>
                  <Form.Field>
                    <FilterDateRange
                      fromText="From"
                      toText="To"
                      searchText="Filter Date"
                      searchColor="green"
                      dispatchAction={dispatchAction}
                      dateTextBoxSize="large"
                      onStartDateChange={handleOnStartDateChange}
                      onEndDateChange={handleOnStopDateChange}
                      onFilterDate={handleOnFilterDate}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
              <LabResultTable
                labResultTableData={labResultData || []}
                columns={labResultColumns}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Modal open={!!openCardLabReport} size="fullscreen">
          <CardLabReport
            patientInfo={patientInfo}
            isLoading={labReportLoading}
            documentId={openCardLabReport}
            reportDetail={reportDetail}
            getReportList={handleGetReportList}
            hideCallback={() => setOpenCardLabReport(false)}
            languageUX={props.languageUX}
          />
        </Modal>
      </CardLayout>
    </>
  );
});

CardLabResult.defaultProps = {
  closeable: false,
  toggleable: false,
  hideHeaderIcon: true,

  labCode: {},
  labDetail: {},
  labError: {},
  reportDetail: {},
  centralLabResultList: {},
  patientInfo: {},

  getLabCode: () => {},
  getLabDetail: () => {},
  getInterfaceSummaryReportDetail: () => {},
  getLabResultList: () => {},
  onClearLabResult: () => {},
  patientId: 0,

  // divisionList: null,
  // labNameResultList: null,
  // labResultData: null,
  // isHidden: true,
  isLoading: null,
};

CardLabResult.propTypes = {
  closeable: PropTypes.bool,
  toggleable: PropTypes.bool,
  hideHeaderIcon: PropTypes.bool,

  labCode: PropTypes.object,
  labDetail: PropTypes.object,
  labError: PropTypes.object,
  reportDetail: PropTypes.object,
  centralLabResultList: PropTypes.object,
  patientInfo: PropTypes.object,

  getLabCode: PropTypes.func,
  getLabDetail: PropTypes.func,
  getInterfaceSummaryReportDetail: PropTypes.func,
  getLabResultList: PropTypes.func,
  onClearLabResult: PropTypes.func,
  patientId: PropTypes.number,

  // divisionList: PropTypes.object,
  // labNameResultList: PropTypes.object,
  // labResultData: PropTypes.object,
  // isHidden: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
};

export default CardLabResult;
