import React from 'react'
import {
   Button,
   Checkbox,
   Dropdown,
   Input,
   Radio,
   TextArea
} from 'semantic-ui-react'

import DateTextBox from '../common/DateTextBox';
import TimeFreeTextBox24 from '../common/TimeFreeTextBox24';
import { useIntl } from "react-intl";


import styles from './CardOTRehabilitationTreatment.module.css'

export interface OTRehabilitationTreatmentViewDataType {
    OtRehabilitationTreatment4: string,
    OtRehabilitationTreatment4Disabled: boolean,
    OtRehabilitationTreatment6: string,
    OtRehabilitationTreatment6Disabled: boolean,
    OtRehabilitationTreatment8: string,
    OtRehabilitationTreatment8Disabled: boolean,
    Ct6PainScore: string,
    Ct6PainScoreDisabled: boolean,
    Ct6PainScoreOptions: any,
    OtRehabilitationTreatment13: string,
    OtRehabilitationTreatment13Disabled: boolean,
    OtRehabilitationTreatment16: string,
    OtRehabilitationTreatment16Disabled: boolean,
    OtRehabilitationTreatment18: boolean,
    OtRehabilitationTreatment18Disabled: boolean,
    OtRehabilitationTreatment20: boolean,
    OtRehabilitationTreatment20Disabled: boolean,
    OtRehabilitationTreatment23: string,
    OtRehabilitationTreatment23Disabled: boolean,
    OtRehabilitationTreatment24: boolean,
    OtRehabilitationTreatment24Disabled: boolean,
    OtRehabilitationTreatment26: string,
    OtRehabilitationTreatment26Disabled: boolean,
    OtRehabilitationTreatment28: boolean,
    OtRehabilitationTreatment28Disabled: boolean,
    OtRehabilitationTreatment31: boolean,
    OtRehabilitationTreatment31Disabled: boolean,
    OtRehabilitationTreatment34: string,
    OtRehabilitationTreatment34Disabled: boolean,
    OtRehabilitationTreatment36: string,
    OtRehabilitationTreatment36Disabled: boolean,
    OtRehabilitationTreatment38: string,
    OtRehabilitationTreatment38Disabled: boolean,
    OtRehabilitationTreatment46: string,
    OtRehabilitationTreatment46Disabled: boolean,
    OtRehabilitationTreatment47: string,
    OtRehabilitationTreatment47Disabled: boolean,
    SaveRequestDisabled: boolean,
}

export const OTRehabilitationTreatmentInitialViewData: OTRehabilitationTreatmentViewDataType = {
    OtRehabilitationTreatment4: "",
    OtRehabilitationTreatment4Disabled: false,
    OtRehabilitationTreatment6: "",
    OtRehabilitationTreatment6Disabled: false,
    OtRehabilitationTreatment8: "",
    OtRehabilitationTreatment8Disabled: false,
    Ct6PainScore: "",
    Ct6PainScoreDisabled: false,
    Ct6PainScoreOptions: [{key: 0, value: "A", text: "A"}],
    OtRehabilitationTreatment13: "",
    OtRehabilitationTreatment13Disabled: false,
    OtRehabilitationTreatment16: "",
    OtRehabilitationTreatment16Disabled: false,
    OtRehabilitationTreatment18: false,
    OtRehabilitationTreatment18Disabled: false,
    OtRehabilitationTreatment20: false,
    OtRehabilitationTreatment20Disabled: false,
    OtRehabilitationTreatment23: "",
    OtRehabilitationTreatment23Disabled: false,
    OtRehabilitationTreatment24: false,
    OtRehabilitationTreatment24Disabled: false,
    OtRehabilitationTreatment26: "",
    OtRehabilitationTreatment26Disabled: false,
    OtRehabilitationTreatment28: false,
    OtRehabilitationTreatment28Disabled: false,
    OtRehabilitationTreatment31: false,
    OtRehabilitationTreatment31Disabled: false,
    OtRehabilitationTreatment34: "",
    OtRehabilitationTreatment34Disabled: false,
    OtRehabilitationTreatment36: "",
    OtRehabilitationTreatment36Disabled: false,
    OtRehabilitationTreatment38: "",
    OtRehabilitationTreatment38Disabled: false,
    OtRehabilitationTreatment46: "",
    OtRehabilitationTreatment46Disabled: false,
    OtRehabilitationTreatment47: "",
    OtRehabilitationTreatment47Disabled: false,
    SaveRequestDisabled: false,
}

const Sub = (props: any) => {
    const children = React.Children.toArray(props.children)
    return (
        <div className={styles.container}>
            <div className={styles.OtRehabilitationTreatment2}>
                <div
                    style={{background: "#F3F3F3", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.OtRehabilitationTreatment3}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Problem/Occupational therapy diagnosis*</strong></p>
                </div>
            </div>
            <div className={styles.OtRehabilitationTreatment4}>
                <Input
                    value={props.viewData.OtRehabilitationTreatment4}
                    disabled={ props.viewData.OtRehabilitationTreatment4Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTRehabilitationTreatment',
                            name: 'OtRehabilitationTreatment4',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtRehabilitationTreatment6}>
                <Input
                    value={props.viewData.OtRehabilitationTreatment6}
                    disabled={ props.viewData.OtRehabilitationTreatment6Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTRehabilitationTreatment',
                            name: 'OtRehabilitationTreatment6',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtRehabilitationTreatment7}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Goal of Treatment*</strong></p>
                </div>
            </div>
            <div className={styles.OtRehabilitationTreatment8}>
                <TextArea
                    value={props.viewData.OtRehabilitationTreatment8}
                    disabled={ props.viewData.OtRehabilitationTreatment8Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTRehabilitationTreatment',
                            name: 'OtRehabilitationTreatment8',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </TextArea>
            </div>
            <div className={styles.OtRehabilitationTreatment9}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Treatment*</strong></p>
                </div>
            </div>
            <div className={styles.OtRehabilitationTreatment16}>
                <TextArea
                    value={props.viewData.OtRehabilitationTreatment16}
                    disabled={ props.viewData.OtRehabilitationTreatment16Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTRehabilitationTreatment',
                            name: 'OtRehabilitationTreatment16',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </TextArea>
            </div>
            <div className={styles.OtRehabilitationTreatment17}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Note</strong></p>
                </div>
            </div>
            <div className={styles.OtRehabilitationTreatment18}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtRehabilitationTreatment18}
                    disabled={ props.viewData.OtRehabilitationTreatment18Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "OTRehabilitationTreatment",
                                name: "OtRehabilitationTreatment18"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtRehabilitationTreatment19}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>N/A</p>
                </div>
            </div>
            <div className={styles.OtRehabilitationTreatment20}>
                <Radio 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtRehabilitationTreatment20}
                    disabled={ props.viewData.OtRehabilitationTreatment20Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "radio",
                            params: {
                                view: "OTRehabilitationTreatment",
                                name: "OtRehabilitationTreatment20"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtRehabilitationTreatment21}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Note to doctor</strong></p>
                </div>
            </div>
            <div className={styles.OtRehabilitationTreatment22}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Yes, able to perform treatment follow doctor’s prescription</p>
                </div>
            </div>
            <div className={styles.OtRehabilitationTreatment23}>
                <Input
                    value={props.viewData.OtRehabilitationTreatment23}
                    disabled={ props.viewData.OtRehabilitationTreatment23Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTRehabilitationTreatment',
                            name: 'OtRehabilitationTreatment23',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtRehabilitationTreatment24}>
                <Radio 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtRehabilitationTreatment24}
                    disabled={ props.viewData.OtRehabilitationTreatment24Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "radio",
                            params: {
                                view: "OTRehabilitationTreatment",
                                name: "OtRehabilitationTreatment24"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtRehabilitationTreatment25}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>No,</p>
                </div>
            </div>
            <div className={styles.OtRehabilitationTreatment26}>
                <Input
                    value={props.viewData.OtRehabilitationTreatment26}
                    disabled={ props.viewData.OtRehabilitationTreatment26Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTRehabilitationTreatment',
                            name: 'OtRehabilitationTreatment26',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtRehabilitationTreatment27}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Instruction*</strong></p>
                </div>
            </div>
            <div className={styles.OtRehabilitationTreatment28}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtRehabilitationTreatment28}
                    disabled={ props.viewData.OtRehabilitationTreatment28Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "OTRehabilitationTreatment",
                                name: "OtRehabilitationTreatment28"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtRehabilitationTreatment29}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Patient and/or family was/were informed about instruction and plan of treatment</p>
                </div>
            </div>
            <div className={styles.OtRehabilitationTreatment31}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtRehabilitationTreatment31}
                    disabled={ props.viewData.OtRehabilitationTreatment31Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "OTRehabilitationTreatment",
                                name: "OtRehabilitationTreatment31"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtRehabilitationTreatment32}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Need to be reviewed</p>
                </div>
            </div>
            <div className={styles.SaveRequest}>
                <Button
                    disabled={ props.viewData.SaveRequestDisabled }
                    onClick={(e) => { props.onEvent({
                        message: 'clickButton',
                        params: {
                            view: 'OTRehabilitationTreatment',
                            name: 'SaveRequest'
                        }
                    })}}
                    color="green"
                    style={{height: "100%", width: "100%"}}
                >
                    Save
                </Button>
            </div>
        </div>
    )
}

export default Sub

const getTsName = (cssClass: string) => {
    return cssClass.split('-').map((s) => (
        s.charAt(0).toUpperCase() + s.slice(1)
    )).join('')
}
