import WasmController from 'react-lib/frameworks/WasmController';
import axios from "axios";
import { getSheetInfo } from "react-lib/frameworks/Excel";

export type State = 
  {
    VaccineUpload?: {
      vaccineInfo?: any[],
      appointmentList?: any[],
      generateUserModal?: boolean,
      generateUserResult?: any | null,
    } | null
  }

export const StateInitial: State = 
  {
    VaccineUpload: {
      vaccineInfo: [],
      appointmentList: [],
      generateUserModal: false,
      generateUserResult: null,
    }
  }

export type Event = 
    { message: "GetVaccineInfo", params: {} }
  | { message: "ChooseVaccineFile", params: {} }
  | { message: "UploadVaccineData", params: {} }
  | { message: "GetVaccineDataList", params: {} }
  | { message: "GenerateVaccineUser", params: { row_id: string } }
  | { message: "CloseGenerateVaccineUserModal", params: {} }

export type Data = 
  {
    division?: number
  }

export const DataInitial = 
  {
  }

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any

export const GetVaccineInfo: Handler = async (controller, params) => {
  GetDataList(controller, params);
}

export const ChooseVaccineFile: Handler = async (controller, params) => {
  const state = controller.getState();
  console.log(params.file.files[0]);
  const vaccineInfo = await getSheetInfo(params.file.files[0],
    { "0": "reference_id",
      "1": "name",
      "2": "last_name",
      "3": "citizen_id",
      "4": "brand",
      "5": "mobile",
      "6": "email",
    });
  const vaccineInfoFiltered = vaccineInfo.slice(0, 5);
  controller.setState({ 
    VaccineUpload: {
      ...state.VaccineUpload,
      vaccineInfo: vaccineInfoFiltered 
    }
  });
}

export const UploadData: Handler = async (controller, params) => {
  const state = controller.getState();
  console.log(controller.data.division);
  const res = await axios.post("https://vaccine-4s6ii3rlhq-de.a.run.app/upload-data/", {
    division_id: controller.data.division || "",
    vaccineInfo: state.VaccineUpload?.vaccineInfo
  }, { headers: { "Authorization": `Token ${controller.apiToken}`}})
  console.log(res)
  const data = await DoGetDataList(controller, params);
  controller.setState({
    VaccineUpload: {
      ...state.VaccineUpload,
      vaccineInfo: [],
      appointmentList: data
    }
  });
}

export const GetDataList: Handler = async (controller, params) => {
  const state = controller.getState();
  console.log(controller.data.division);
  const data = await DoGetDataList(controller, params);
  controller.setState({
    VaccineUpload: {
      ...state.VaccineUpload,
      appointmentList: data
    }
  });
}

export const DoGetDataList: Handler = async (controller, params) => {
  const state = controller.getState();
  console.log(controller.data.division);
  const res = await axios.get("https://vaccine-4s6ii3rlhq-de.a.run.app/get-data-list/",
  { 
    params: { division_id: controller.data.division || ""},
    headers: { "Authorization": `Token ${controller.apiToken}`}
  });
  return res.data;
}

export const GenerateUser: Handler = async (controller, params) => {
  const state = controller.getState();
  try {
    const res = await axios.post("https://vaccine-4s6ii3rlhq-de.a.run.app/generate-user/", {
      row_id: params.row_id
    }, { headers: { "Authorization": `Token ${controller.apiToken}`}})
    controller.setState({
      VaccineUpload: {
        ...state.VaccineUpload,
        generateUserModal: true,
        generateUserResult: res.data
      }
    });
  } catch(e) {
    controller.setState({
      VaccineUpload: {
        ...state.VaccineUpload,
        generateUserModal: true,
        generateUserResult: null
      }
    });
  }
}

export const CloseGenerateUserModal: Handler = async (controller, params) => {
  const state = controller.getState();
  controller.setState({
    VaccineUpload: {
      ...state.VaccineUpload,
      generateUserModal: false
    }
  }, () => { 
    // Refresh to remove tested item
    GetDataList(controller, params);
  });
}
