
/* Generated apis ---------------------- */
import { to } from 'react-lib/utils'
import BaseService from  'react-lib/apis/services/BaseService'
import config from '../../config/config'
import DrugOrderTemplateSerializer from '../types/DrugOrderTemplateSerializer_apps_TPD'


const HOST = `${config.API_HOST}`
/* ['PrePrescriptionPrefetchView', 'generics.CreateAPIView'] */
/* params:  */
/* data: 'items' */
const DrugOrderTemplateList : 
{
  create:   ({params, data, apiToken, extra}:
             { 
                params?: any,
                data?: DrugOrderTemplateSerializer,
                apiToken?: any,
                extra?: any
            }) => any,
  list:    ({params, data, apiToken, extra}:
             { 
                 params?: any,
                 data?: DrugOrderTemplateSerializer,
                 apiToken?: any,
                 extra?: any
             }) => any,
} = 
{
  create: async ({params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken)
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    if (extra?.division)
      base.client.defaults.headers["division"] = extra.division;
    if (extra?.device)
      base.client.defaults.headers["device"] = extra.device;
    const result = await to(base.client.post(`${HOST}/apis/TPD/drug-order-template/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
  list: async ({params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken)
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    if (extra?.division)
      base.client.defaults.headers["division"] = extra.division;
    if (extra?.device)
      base.client.defaults.headers["device"] = extra.device;
    const result = await to(base.client.get(`${HOST}/apis/TPD/drug-order-template/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}


export default DrugOrderTemplateList

