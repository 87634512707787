import WasmController from "react-lib/frameworks/WasmController";

// APIs
import RoomList from "issara-sdk/apis/RoomList_apps_ADM";
import AdmitOrderDetail from "issara-sdk/apis/AdmitOrderDetail_apps_ADM";
import AdmitOrderList from "issara-sdk/apis/AdmitOrderList_apps_ADM";
import PlanTransferList from "issara-sdk/apis/PlanTransferList_apps_ADM";
import QueueWardList from "issara-sdk/apis/QueueWardList_apps_ADM";

// Form
import FormBedSummary from "react-lib/apps/HISV3/ADM/FormBedSummary";
import FormReserveBedDaily from "react-lib/apps/HISV3/ADM/FormReserveBedDaily";
import FormReserveBedQueue from "react-lib/apps/HISV3/ADM/FormReserveBedQueue";

import moment from "moment";

// Utils
import { formatDate } from "react-lib/utils";
import getPdfMake from "react-lib/appcon/common/pdfMake";

export type State = {
  successMessage?: any;
  errorMessage?: any;
  selectedPatient?: any;
  selectedDivision?: any;
  selectedEncounter?: any;
  encounterDetailDict?: any;
  buttonLoadCheck?: any;
  SetBedSequence?: {
    sequenceIndex: string | null;
    admitOrderList?: any[];
    isHN: boolean;
    inputHN?: string | null;
    selectedAdmitOrder?: any;
    selectedRoomType?: string | null;
    selectedWardType?: string | null;
    selectedDivision?: string | null;
    selectedRoomStatus?: string | null;
    searching?: boolean;
    roomList?: any[];
    selectedRoom?: any;
    totalPage?: number;
    reserveBedQueueList: any[];
    reserveTransferQueueList: any[];
    selectedReserveBedQueue?: any;
    codeIcontains: string;
    filterReserveBedQueue?: Partial<{
      isHN: boolean;
      selectedPatient: Record<string, any>;
      isAdmitDate: boolean;
      admitFromDate: string;
      admitToDate: string;
      isOrderDiv: boolean;
      selectedOrderDivision: Record<string, any>;
      isSaveDate: boolean;
      saveFromDate: string;
      saveToDate: string;
      isReserveStatus: boolean;
      reserveStatus: string;
      isWardType: boolean;
      wardType: string;
    }>;
    filterReserveTransferQueue?: Partial<{
      isHN: boolean;
      selectedPatient: Record<string, any>;
      isTransferDate: boolean;
      transferFromDate: string;
      transferToDate: string;
      isTransferStatus: boolean;
      transferStatus: string;
      isUrgency: boolean;
      urgency: string;
    }>;
  } | null;
};

export const StateInitial: State = {
  SetBedSequence: null,
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  user?: number;
  division?: number;
  device?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

const LIMIT = 50;

export const Start: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.SetBedSequence) return;

  // Master data
  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["divisionIpd", {}],
        ["roomStatus", {}],
        ["roomType", {}],
        ["wardType", {}],
        ["divisionType", {}],
        ["admitOrderStatus", {}],
        ["transferStatus", {}],
        ["transferUrgency", {}],
      ],
    },
  });

  // Go to wait at the next in sequence
  let tempAdmitOrderList: any[] =
    state.encounterDetailDict?.admitorderfrom?.[state.selectedEncounter?.id] ||
    [];
  if (tempAdmitOrderList?.length > 0) {
    tempAdmitOrderList = tempAdmitOrderList.map((admitOrder: any) => {
      return admitOrder.coverage
        ? { ...admitOrder }
        : { ...admitOrder, coverage: state.selectedPatient?.coverage || "" };
    });
  }

  controller.setState({
    SetBedSequence: {
      ...state.SetBedSequence,
      sequenceIndex: "SearchOrEdit",
      admitOrderList: tempAdmitOrderList,
      selectedAdmitOrder: tempAdmitOrderList?.[0],
      roomList: [],
      selectedRoom: null,
      filterReserveBedQueue: {
        isSaveDate: true,
        saveFromDate: formatDate(moment()),
        saveToDate: formatDate(moment()),
        ...(!!params.defaultCancel && {isReserveStatus: true }),
        ...(!!params.defaultCancel && {reserveStatus: "CANCELED" })
      },
    },
  });

  SearchOrEdit(controller, {
    action: "search_queue",
    saveFromDate: formatDate(moment()),
    saveToDate: formatDate(moment()),
  });
};

export const SearchOrEdit: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.SetBedSequence) return;

  switch (params.action) {
    case "search":
      HandleSearch(controller, params);
      break;

    case "reserve":
      HandleReserve(controller, params);
      break;

    case "admit":
      HandleAdmit(controller, params);
      break;

    case "search_queue":
      HandleSearchQueue(controller, params);
      break;

    case "search_transfer_queue":
      HandleSearchTransferQueue(controller, params);
      break;

    case "search_reserve":
      HandleSearchReserve(controller, params);
      break;

    case "room_reserve":
      HandleRoomReserve(controller, params);
      break;

    case "cancel_reserve":
      HandleCancelReserve(controller, params);
      break;

    case "cancel_admit":
      HandleCancelAdmit(controller, params);
      break;

    case "room_admit":
      HandleRoomAdmit(controller, params);
      break;

    case "clear_queus":
      HandleClearFilter(controller, params);
      break;

    case "printBedReport":
      HandlePrintBedReport(controller, params);
      break;

    case "printReserveBedDailyReport":
      HandlePrintReserveBedDailyReport(controller, params);
      break;

    case "printReserveBed":
      HandlePrintReserveBed(controller, params);
      break;

    case "refresh":
      await controller.handleEvent({
        message: "RunSequence",
        params: { ...params, action: "search_queue", page: params.page },
      });

      await controller.handleEvent({
        message: "RunSequence",
        params: {
          ...params,
          action: "search",
          page: params.page,
          isClear: false,
        },
      });
      break;

    default:
      break;
  }
};

/* ------------------------------------------------------ */

/*                         Handle                         */

/* ------------------------------------------------------ */

const HandleSearch: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.SetBedSequence) return;

  if (typeof params.isClear !== "boolean" || params.isClear) {
    controller.setState({
      SetBedSequence: {
        ...state.SetBedSequence,
        roomList: [],
        searching: true,
      },
    });
  } else {
    controller.setState({
      SetBedSequence: { ...state.SetBedSequence, searching: true },
    });
  }

  // const roomSummary = await RoomSummary.get({
  //   params: {},
  //   apiToken: controller.apiToken
  // });
  const getValue = (key: any) => {
    return (state.SetBedSequence as any)?.[key] === "all"
      ? ""
      : (state.SetBedSequence as any)?.[key];
  };

  const statusName = getValue("selectedRoomStatus");

  const roomList = await RoomList.list({
    params: {
      hn: state.SetBedSequence?.isHN ? state.SetBedSequence?.inputHN : "",
      room_type: getValue("selectedRoomType"),
      ward_type: getValue("selectedWardType"),
      division: getValue("selectedDivision"),
      status_name: statusName,
      offset: (params?.page - 1) * LIMIT || 0,
      limit: LIMIT,
    },
    apiToken: controller.apiToken,
  });

  // mapping reserve people
  let reservesRoomList: any[] =
    roomList[0]?.items?.map((room: any) => {
      if (!room.hn && room.hn_reserve) {
        return {
          ...room,
          is_reserve: room.hn_reserve && room.room_status === "ว่าง",
          hn: room.hn ? room.hn : room.hn_reserve,
          patient_name: room.patient_name
            ? room.patient_name
            : room.patient_name_reserve,
          patient_gender: room.patient_gender
            ? room.patient_gender
            : room.patient_gender_reserve,
        };
      }
      return { ...room };
    }) || [];

  if (statusName === "EMPTY") {
    reservesRoomList = reservesRoomList.filter((item) => !item.is_reserve);
  }

  controller.setState({
    SetBedSequence: {
      ...(controller.getState().SetBedSequence as any),
      roomList: reservesRoomList,
      totalPage: Math.ceil((roomList[0]?.total || 0) / LIMIT),
      searching: false,
    },
  }, () => {
    if (params?.withPrint === "printBedReport") {
      HandlePrintBedReport(controller, params);
    }
  });
};

const HandleReserve: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.SetBedSequence) return;

  if (
    state.SetBedSequence?.selectedAdmitOrder?.id &&
    state.SetBedSequence?.selectedRoom?.id
  ) {
    const admitOrder = await AdmitOrderDetail.update({
      pk: state.SetBedSequence.selectedAdmitOrder.id,
      data: {
        action: "RESERVE",
        items: [state.SetBedSequence?.selectedAdmitOrder],
        room: state.SetBedSequence.selectedRoom.id,
      },
      apiToken: controller.apiToken,
    });
    console.log(admitOrder[1] ? admitOrder[1] : admitOrder[0]);
    await AdmitOrderSync(controller, params);
  }
};

const HandlePrintReserveBed: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.SetBedSequence) return;

  // const patient = state?.selectedPatient;

  let statusText = ( state?.masterOptions?.admitOrderStatus || []).find((item: any) => item.value === (state?.SetBedSequence?.filterReserveBedQueue?.isReserveStatus ? state?.SetBedSequence?.filterReserveBedQueue?.reserveStatus || "ทุกสถานะ" : "ทุกสถานะ") )?.text

  const data = Object.assign({
    time: moment().format("HH:mm") || "",
    date: formatDate(moment()) || "",
    // patient: patient || {},
    // parentLabID: params.parentLabID || [],
    // labResultList: params.groupByParentLab || [],
    // doctorRecommends: params.doctorRecommends,
    isSaveDate: state?.SetBedSequence?.filterReserveBedQueue?.isSaveDate,
    saveFromDate: state?.SetBedSequence?.filterReserveBedQueue?.saveFromDate,
    saveToDate: state?.SetBedSequence?.filterReserveBedQueue?.saveToDate,
    data: state?.SetBedSequence?.reserveBedQueueList,
    reserveStatusName: statusText || "ทุกสถานะ",
    reserveStatus: state?.SetBedSequence?.filterReserveBedQueue?.isReserveStatus ? state?.SetBedSequence?.filterReserveBedQueue?.reserveStatus || "ANY" : "ANY"

  });

  let docDef: any = { content: [] };

  docDef = FormReserveBedQueue(data);
  // console.log('docDef: ', docDef);

  const pdfMake = (await getPdfMake()).createPdf(docDef);
  pdfMake.open();
};


const HandlePrintBedReport: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.SetBedSequence) return;

  // const patient = state?.selectedPatient;

  const data = Object.assign({
    time: moment().format("HH:mm") || "",
    date: formatDate(moment()) || "",
    // patient: patient || {},
    // parentLabID: params.parentLabID || [],
    // labResultList: params.groupByParentLab || [],
    // doctorRecommends: params.doctorRecommends,
    selectedDivision : state?.SetBedSequence?.selectedDivision === undefined ? "all" : state?.SetBedSequence?.selectedDivision,
    selectedRoomStatus : state?.SetBedSequence?.selectedRoomStatus === undefined ? "all" : state?.SetBedSequence?.selectedRoomStatus,
    selectedRoomType : state?.SetBedSequence?.selectedRoomType === undefined ? "all" : state?.SetBedSequence?.selectedRoomType,
    selectedWardType : state?.SetBedSequence?.selectedWardType === undefined ? "all" : state?.SetBedSequence?.selectedWardType,
    data: state?.SetBedSequence?.roomList,
    masterOptions: state?.masterOptions,
  });

  let docDef: any = { content: [] };

  docDef = FormBedSummary(data);
  // console.log('docDef: ', docDef);

  const pdfMake = (await getPdfMake()).createPdf(docDef);
  pdfMake.open();
};

const HandlePrintReserveBedDailyReport: Handler = async (
  controller,
  params
) => {
  console.log("HandlePrintReserveBedDailyReport params: ", params);
  const state = controller.getState();
  if (!state.SetBedSequence) return;

  // const patient = state?.selectedPatient;

  const data = Object.assign({
    time: moment().format("HH:mm") || "",
    date: formatDate(moment()) || "",
    // patient: patient || {},
    // parentLabID: params.parentLabID || [],
    // labResultList: params.groupByParentLab || [],
    // doctorRecommends: params.doctorRecommends,
    isSaveDate: state?.SetBedSequence?.filterReserveBedQueue?.isSaveDate,
    saveFromDate: state?.SetBedSequence?.filterReserveBedQueue?.saveFromDate,
    saveToDate: state?.SetBedSequence?.filterReserveBedQueue?.saveToDate,
    data: state?.SetBedSequence?.reserveBedQueueList,
  });

  let docDef: any = { content: [] };

  docDef = FormReserveBedDaily(data);
  console.log("docDef: ", docDef);

  const pdfMake = (await getPdfMake()).createPdf(docDef);
  pdfMake.open();
};

const HandleAdmit: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.SetBedSequence) return;

  if (state.SetBedSequence?.selectedAdmitOrder?.id) {
    const admitOrder = await AdmitOrderDetail.update({
      pk: state.SetBedSequence.selectedAdmitOrder.id,
      data: {
        action: "FINISH",
      },
      apiToken: controller.apiToken,
    });
    console.log(admitOrder[1] ? admitOrder[1] : admitOrder[0]);
    await AdmitOrderSync(controller, params);
  }
};

const HandleSearchQueue: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.SetBedSequence) return;

  const filter = state.SetBedSequence.filterReserveBedQueue || {};

  const formatParams = (isKey: any, value: any) => {
    return ((filter as any)[isKey] || undefined) && value;
  };

  const status = (state as any).masterOptions.admitOrderStatus?.findIndex(
    (option: any) => option.value === filter.reserveStatus
  );

  let urlParams: Record<string, any> = {};

  if (params.isBarcode) {
    urlParams = {
      code: state.SetBedSequence.codeIcontains || "",
    };
  } else {
    urlParams = {
      encounter__patient__hn: formatParams("isHN", filter.selectedPatient?.hn),
      admit_date__gte: formatParams("isAdmitDate", filter.admitFromDate),
      admit_date__lte: formatParams("isAdmitDate", filter.admitToDate),
      status: formatParams(
        "isReserveStatus",
        status !== -1 ? status + 1 : undefined
      ),
      created__gte:
        params?.saveFromDate || formatParams("isSaveDate", filter.saveFromDate),
      created__lte:
        params?.saveToDate || formatParams("isSaveDate", filter.saveToDate),
      order_div__code: formatParams(
        "isOrderDiv",
        filter.selectedOrderDivision?.code
      ),
      ward_type: formatParams("isWardType", filter.wardType),
      // not_finished: true,
    };
  }

  const result = await AdmitOrderList.list({
    apiToken: controller.apiToken,
    params: urlParams,
  });

  controller.setState({
    SetBedSequence: {
      ...(controller.getState().SetBedSequence as any),
      reserveBedQueueList:
        filter.isReserveStatus === false ||
        filter.isReserveStatus === undefined ||
        (filter.isReserveStatus === true && filter.reserveStatus !== "CANCELED")
          ? result[0]?.items.filter(
              (item: any) => item.status_name !== "CANCELED"
            ) || []
          : result[0]?.items,
    },
  }, () => {
    if (params?.withPrint === "printReserveBedDailyReport") {
      HandlePrintReserveBedDailyReport(controller, params);
    } else if (params?.withPrint === "printReserveBed") {
      HandlePrintReserveBed(controller, params);
    }
  });
};

const HandleSearchTransferQueue: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.SetBedSequence) return;

  const filter = state.SetBedSequence.filterReserveTransferQueue || {};

  const formatParams = (isKey: any, value: any) => {
    return ((filter as any)[isKey] || undefined) && value;
  };

  const urlParams = {
    emr__encounter__patient__hn: formatParams(
      "isHN",
      filter.selectedPatient?.hn
    ),
    date__gte: formatParams("isTransferDate", filter.transferFromDate),
    date__lte: formatParams("isTransferDate", filter.transferToDate),
    status: formatParams("isTransferStatus", filter.transferStatus),
    urgency: formatParams("isUrgency", filter.urgency),
  };

  const result = await PlanTransferList.list({
    apiToken: controller.apiToken,
    params: urlParams,
  });

  controller.setState({
    SetBedSequence: {
      ...(controller.getState().SetBedSequence as any),
      reserveTransferQueueList: result[0]?.items || [],
    },
  });
};

const HandleSearchReserve: Handler = (controller, params) => {
  const state = controller.getState();
  if (!state.SetBedSequence) return;

  const data = state.SetBedSequence.selectedReserveBedQueue || {};

  controller.setState(
    {
      SetBedSequence: {
        ...state.SetBedSequence,
        inputHN: "",
        selectedRoomType: data.first_room_type || "all",
        selectedWardType: data.ward_type || "all",
        selectedDivision: "all",
        selectedRoomStatus: "EMPTY",
      },
    },
    () => {
      controller.handleEvent({
        message: "RunSequence",
        params: { ...params, action: "search", page: 1 },
      });
    }
  );
};

const HandleRoomReserve: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.SetBedSequence) return;

  const btnKey = `${params.card}_${params.action}`;

  controller.setState({ buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "LOADING" } });

  const admit = await AdmitOrderDetail.update({
    pk: params.reserve.id,
    data: {
      action: "RESERVE",
      items: [params.reserve],
      room: params.room.id,
    },
    apiToken: controller.apiToken,
  });

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: admit[1] ? "ERROR" : null },
  });

  if (!admit[1]) {
    params.onSuccess?.()

    await controller.handleEvent({
      message: "RunSequence",
      params: { ...params, action: "refresh", page: params.page },
    });
  }
};

const HandleCancelReserve: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.SetBedSequence) return;

  const cancelReserve = await AdmitOrderDetail.update({
    pk: state.SetBedSequence.selectedRoom.admit_order_id,
    data: {
      action: "CANCEL_RESERVE",
      cancel_reason: params.note,
    },
    apiToken: controller.apiToken,
  });

  if (!cancelReserve[1]) {
    controller.setState({
      SetBedSequence: {
        ...state.SetBedSequence,
        selectedRoom: null,
      },
      successMessage: { ...state.successMessage, [params.card]: true },
    });
  } else {
    return controller.setState({
      errorMessage: { ...state.errorMessage, [params.card]: cancelReserve[1] },
    });
  }

  await controller.handleEvent({
    message: "RunSequence",
    params: { ...params, action: "refresh", page: params.page },
  });
};

const HandleCancelAdmit: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.SetBedSequence) return;

  const room = state.SetBedSequence.selectedRoom;
  let admitOrder = room.admit_order_id

  if (!admitOrder){
    const [ward] = await QueueWardList.get({
      apiToken: controller.apiToken,
      params: {
        exclude_estimate_field: true,
        division: room.division,
        room_item_status: room.status,
        room_no: room.id
      },
      extra: {
        device: (controller.data as any).device,
        division: controller.data.division,
      },
    });

    admitOrder = ward?.items?.[0]?.admit_order
  }

  const cancelAdmit = await AdmitOrderDetail.update({
    pk: admitOrder,
    data: {
      action: "REVERSE_FINISH",
      cancel_reason: params.note,
    },
    apiToken: controller.apiToken,
  });

  if (!cancelAdmit[1]) {
    controller.setState({
      successMessage: { ...state.successMessage, [params.card]: true },
    });
  } else {
    return controller.setState({
      errorMessage: { ...state.errorMessage, [params.card]: cancelAdmit[1] },
    });
  }

  await controller.handleEvent({
    message: "RunSequence",
    params: { ...params, action: "refresh", page: params.page },
  });
};

const HandleRoomAdmit: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.SetBedSequence) return;

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  const roomReserve = await AdmitOrderDetail.update({
    pk: state.SetBedSequence.selectedRoom.admit_order_id,
    data: {
      action: "FINISH",
    },
    apiToken: controller.apiToken,
  });

  // if (!roomReserve[1]) {
  //   controller.setState({
  //     successMessage: { ...state.successMessage, [params.card]: true },
  //   });
  // }

  if (roomReserve[1]) {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "SUCCESS",
      },
      errorMessage: { ...state.errorMessage, [params.card]: roomReserve[1] },
    });
  } else {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "ERROR",
      },
      successMessage: { ...state.successMessage, [params.card]: true },
    });
  }

  await controller.handleEvent({
    message: "RunSequence",
    params: { ...params, action: "refresh", page: params.page },
  });
};

const AdmitOrderSync: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.SetBedSequence) return;

  const admitOrderSync = await AdmitOrderDetail.retrieve({
    pk: state.SetBedSequence.selectedAdmitOrder.id,
    apiToken: controller.apiToken,
  });
  console.log(
    "admitOrderSync",
    admitOrderSync[1] ? admitOrderSync[1] : admitOrderSync[0]
  );
  if (admitOrderSync) {
    let admitOrderList: any[] = [];
    // Look up id to replace or use the same.
    // No need to add additional row as the selectedAdmitOrder has to come from original admitOrderList
    for (const item of state.SetBedSequence?.admitOrderList || []) {
      if (item?.id !== admitOrderSync[0]?.id) admitOrderList.push(item);
      else {
        // if not have coverage in admit order item
        if (!admitOrderSync[0]?.coverage)
          admitOrderList.push({
            ...admitOrderSync[0],
            coverage: item?.coverage,
          });
        else admitOrderList.push(admitOrderSync[0]);
      }
    }
    controller.setState({
      SetBedSequence: {
        ...state.SetBedSequence,
        admitOrderList: admitOrderList,
        selectedAdmitOrder: admitOrderSync[0],
      },
    });
  }
};

const HandleClearFilter: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.SetBedSequence) return;
  controller.setState({
    SetBedSequence: {
      ...state.SetBedSequence,
      filterReserveBedQueue: {
        isHN: false,
        selectedPatient: {},
        isAdmitDate: false,
        admitFromDate: "",
        admitToDate: "",
        isOrderDiv: false,
        selectedOrderDivision: {},
        isSaveDate: false,
        saveFromDate: "",
        saveToDate: "",
        isReserveStatus: false,
        reserveStatus: "",
        isWardType: false,
        wardType: "",
      },
    },
  });
};
