import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Icon,
  Form,
  FormGroup,
  FormField,
  Checkbox,
  Input,
  Button
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardDFPayrollGroupSearchBoxUX = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{backgroundColor: "#FFFFF",width: "100%",height:"100%",padding:"10px 1rem"}}>
        <div
          style={{display: "flex", alignItems: "center", justifyContent: "space-between", margin: "0.25rem 0 -0.15rem"}}>

          <label
            style={{fontSize: "1.15rem", fontWeight: "bold"}}>
            ค้นหารายการที่สร้างเลขงวด
          </label>
          <Icon
            className="large red"
            link={true}
            name="close"
            onClick={props.onClickClose}>
          </Icon>
        </div>
        <div
          className="ui divider">

        </div>
        <Form
          className="small">
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={5}>
              <Checkbox
                checked={props.filter?.isDate || false}
                label="วันที่สร้างเลขงวด"
                name="isDate"
                onChange={props.onChangeFilter}
                style={{minWidth: "max-content", margin: "0 1rem 0 -0.25rem"}}>
              </Checkbox>
              <div
                style={{width: "100%"}}>

                <DateTextBox
                  onChange={(value: string)=>props.onChangeFilter?.(null, {name:"createDate", value})}
                  value={props.filter?.createDate || ""}>
                </DateTextBox>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={5}>
              <Checkbox
                checked={props.filter?.isYear || false}
                name="isYear"
                onChange={props.onChangeFilter}>
              </Checkbox>
              <label
                style={{minWidth: "max-content", margin: "0 1rem 0 -0.25rem"}}>
                ปีพ.ศ.
              </label>
              <Input
                name="year"
                onChange={props.onChangeFilter}
                value={props.filter?.year || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}
              width={5}>
              <Checkbox
                checked={props.filter?.isLotNo || false}
                name="isLotNo"
                onChange={props.onChangeFilter}>
              </Checkbox>
              <label
                style={{minWidth: "max-content", margin: "0 1rem 0 -0.25rem"}}>
                เลขงวด
              </label>
              <Input
                name="lotNo"
                onChange={props.onChangeFilter}
                value={props.filter?.lotNo || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}
              style={{padding:0}}>
              <div>
                {props.ButtonSearch}
              </div>
            </FormField>
          </FormGroup>
        </Form>
        <div>

          <Table
            data={props.data}
            getTdProps={props.onGetTdProps}
            getTrProps={props.onGetTrProps}
            headers="ปี พ.ศ.,เลขงวด,วันที่เริ่มต้น,วันที่สิ้นสุด,วันที่สร้างเลขงวด"
            keys="year_be,lot_no,started_date,ended_date,created"
            minRows={10}
            showPagination={false}
            style={{height: "325px"}}>
          </Table>
        </div>
        <Form>
          <FormGroup
            inline={true}
            style={{marginTop: "1rem"}}>
            <FormField
              style={{flex:1}}>
            </FormField>
            <FormField
              inline={true}>
              <Button
                color="green"
                disabled={props.disabledSelect}
                onClick={props.onSelect}>
                เลือก
              </Button>
            </FormField>
          </FormGroup>
        </Form>
      </div>
    )
}


export default CardDFPayrollGroupSearchBoxUX

export const screenPropsDefault = {}

/* Date Time : Mon Aug 26 2024 12:35:44 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#FFFFF\",width: \"100%\",height:\"100%\",padding:\"10px 1rem\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", justifyContent: \"space-between\", margin: \"0.25rem 0 -0.15rem\"}"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "label",
      "parent": 58,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหารายการที่สร้างเลขงวด"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.15rem\", fontWeight: \"bold\"}"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "Icon",
      "parent": 58,
      "props": {
        "className": {
          "type": "value",
          "value": "large red"
        },
        "link": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "close"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickClose"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "Form",
      "parent": 0,
      "props": {
        "className": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "FormGroup",
      "parent": 63,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "FormField",
      "parent": 64,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "FormField",
      "parent": 64,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "FormField",
      "parent": 64,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "Checkbox",
      "parent": 67,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filter?.isLotNo || false"
        },
        "name": {
          "type": "value",
          "value": "isLotNo"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "Checkbox",
      "parent": 66,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filter?.isYear || false"
        },
        "name": {
          "type": "value",
          "value": "isYear"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "Checkbox",
      "parent": 65,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filter?.isDate || false"
        },
        "label": {
          "type": "value",
          "value": "วันที่สร้างเลขงวด"
        },
        "name": {
          "type": "value",
          "value": "isDate"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", margin: \"0 1rem 0 -0.25rem\"}"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "label",
      "parent": 66,
      "props": {
        "children": {
          "type": "value",
          "value": "ปีพ.ศ."
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", margin: \"0 1rem 0 -0.25rem\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "label",
      "parent": 67,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขงวด"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", margin: \"0 1rem 0 -0.25rem\"}"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "div",
      "parent": 65,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "Input",
      "parent": 66,
      "props": {
        "name": {
          "type": "value",
          "value": "year"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "value": {
          "type": "code",
          "value": "props.filter?.year || \"\""
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "Input",
      "parent": 67,
      "props": {
        "name": {
          "type": "value",
          "value": "lotNo"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "value": {
          "type": "code",
          "value": "props.filter?.lotNo || \"\""
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 77,
      "name": "DateTextBox",
      "parent": 74,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(value: string)=>props.onChangeFilter?.(null, {name:\"createDate\", value})"
        },
        "value": {
          "type": "code",
          "value": "props.filter?.createDate || \"\""
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "FormField",
      "parent": 64,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding:0}"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "div",
      "parent": 78,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSearch"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 81,
      "name": "Table",
      "parent": 80,
      "props": {
        "data": {
          "type": "code",
          "value": "props.data"
        },
        "getTdProps": {
          "type": "code",
          "value": "props.onGetTdProps"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.onGetTrProps"
        },
        "headers": {
          "type": "value",
          "value": "ปี พ.ศ.,เลขงวด,วันที่เริ่มต้น,วันที่สิ้นสุด,วันที่สร้างเลขงวด"
        },
        "keys": {
          "type": "value",
          "value": "year_be,lot_no,started_date,ended_date,created"
        },
        "minRows": {
          "type": "code",
          "value": "10"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"325px\"}"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 82,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 83,
      "name": "FormGroup",
      "parent": 82,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"1rem\"}"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 84,
      "name": "FormField",
      "parent": 83,
      "props": {
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 85,
      "name": "FormField",
      "parent": 83,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 86,
      "name": "Button",
      "parent": 85,
      "props": {
        "children": {
          "type": "value",
          "value": "เลือก"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledSelect"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSelect"
        }
      },
      "seq": 86,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 35,
  "isMounted": true,
  "memo": false,
  "name": "CardDFPayrollGroupSearchBoxUX",
  "project": "CLM CU",
  "screenPropsDefault": {
  },
  "width": 75
}

*********************************************************************************** */
