import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  FormField,
  Checkbox,
  Input,
  TextArea
} from 'semantic-ui-react'

const ANES_PreANES_06_Risk = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        id="CardPreAnestheticRisk"
        style={{ width: "100%", height: "100%" ,padding:"10px"}}>
        <div
          style={{backgroundColor: "#EDF6F9",width: "100%",padding:"20px 10px 10px",borderRadius: 3,border : "solid 0.5px ",borderColor:"#5DBCD2"}}>
          
          <div
            style={{ display: "flex", justifyContent: "flex-end", marginBottom: "1rem"}}>
            
            <div
              style={{ display: props.PreAnestheticSequence?.status_label === "Confirm" ?  "none" : "",}}>
              {props?.buttonSave}
            </div>
          </div>
          <Form>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={4}>
                <Checkbox
                  checked={props.PreAnestheticSequence?.data?.SoreThroat || false}
                  label="Sore throat"
                  name="SoreThroat"
                  onChange={props.onChangeData}>
                </Checkbox>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <Checkbox
                  checked={props.PreAnestheticSequence?.data?.Hoarseness || false}
                  label="Hoarseness"
                  name="Hoarseness"
                  onChange={props.onChangeData}>
                </Checkbox>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <Checkbox
                  checked={props.PreAnestheticSequence?.data?.Nausea || false}
                  label="Nausea"
                  name="Nausea"
                  onChange={props.onChangeData}>
                </Checkbox>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <Checkbox
                  checked={props.PreAnestheticSequence?.data?.Vomiting || false}
                  label="Vomiting"
                  name="Vomiting"
                  onChange={props.onChangeData}>
                </Checkbox>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <Checkbox
                  checked={props.PreAnestheticSequence?.data?.Pruritus || false}
                  label="Pruritus"
                  name="Pruritus"
                  onChange={props.onChangeData}>
                </Checkbox>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={4}>
                <Checkbox
                  checked={props.PreAnestheticSequence?.data?.UrinaryRetention || false}
                  label="Urinary retention"
                  name="UrinaryRetention"
                  onChange={props.onChangeData}>
                </Checkbox>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <Checkbox
                  checked={props.PreAnestheticSequence?.data?.VentilatorySupport || false}
                  label="Ventilatory support"
                  name="VentilatorySupport"
                  onChange={props.onChangeData}>
                </Checkbox>
              </FormField>
              <FormField
                inline={true}
                width={12}>
                <Checkbox
                  checked={props.PreAnestheticSequence?.data?.RiskOfAnesthesiaIncludedOther || false}
                  label="Other"
                  name="RiskOfAnesthesiaIncludedOther"
                  onChange={props.onChangeData}
                  style={{minWidth: "max-content"}}>
                </Checkbox>
                <Input
                  disabled={!props.PreAnestheticSequence?.data?.RiskOfAnesthesiaIncludedOther}
                  name="RiskOfAnesthesiaIncludedOtherRemark"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.RiskOfAnesthesiaIncludedOtherRemark || ""}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup>
              <FormField
                width={16}>
                <label>
                  Comment
                </label>
                <TextArea
                  name="RiskOfAnesthesiaIncludedComment"
                  onChange={props.onChangeData}
                  rows={5}
                  value={props.PreAnestheticSequence?.data?.RiskOfAnesthesiaIncludedComment || ""}>
                </TextArea>
              </FormField>
            </FormGroup>
          </Form>
        </div>
      </div>
    )
}


export default ANES_PreANES_06_Risk

export const screenPropsDefault = {}

/* Date Time : Fri Dec 01 2023 17:01:22 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAnestheticRisk"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"100%\" ,padding:\"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#EDF6F9\",width: \"100%\",padding:\"20px 10px 10px\",borderRadius: 3,border : \"solid 0.5px \",borderColor:\"#5DBCD2\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"flex-end\", marginBottom: \"1rem\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 44,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.buttonSave"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAnestheticSequence?.status_label === \"Confirm\" ?  \"none\" : \"\",}"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "Form",
      "parent": 1,
      "props": {
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "FormGroup",
      "parent": 47,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "FormGroup",
      "parent": 47,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "FormGroup",
      "parent": 47,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "FormField",
      "parent": 48,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "FormField",
      "parent": 49,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "FormField",
      "parent": 50,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "FormField",
      "parent": 48,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "FormField",
      "parent": 48,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 56,
      "name": "FormField",
      "parent": 48,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "FormField",
      "parent": 48,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "FormField",
      "parent": 49,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "FormField",
      "parent": 49,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "12"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "Checkbox",
      "parent": 51,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.SoreThroat || false"
        },
        "label": {
          "type": "value",
          "value": "Sore throat"
        },
        "name": {
          "type": "value",
          "value": "SoreThroat"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "Checkbox",
      "parent": 54,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.Hoarseness || false"
        },
        "label": {
          "type": "value",
          "value": "Hoarseness"
        },
        "name": {
          "type": "value",
          "value": "Hoarseness"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "Checkbox",
      "parent": 55,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.Nausea || false"
        },
        "label": {
          "type": "value",
          "value": "Nausea"
        },
        "name": {
          "type": "value",
          "value": "Nausea"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "Checkbox",
      "parent": 56,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.Vomiting || false"
        },
        "label": {
          "type": "value",
          "value": "Vomiting"
        },
        "name": {
          "type": "value",
          "value": "Vomiting"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "Checkbox",
      "parent": 57,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.Pruritus || false"
        },
        "label": {
          "type": "value",
          "value": "Pruritus"
        },
        "name": {
          "type": "value",
          "value": "Pruritus"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "Checkbox",
      "parent": 52,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.UrinaryRetention || false"
        },
        "label": {
          "type": "value",
          "value": "Urinary retention"
        },
        "name": {
          "type": "value",
          "value": "UrinaryRetention"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "Checkbox",
      "parent": 58,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.VentilatorySupport || false"
        },
        "label": {
          "type": "value",
          "value": "Ventilatory support"
        },
        "name": {
          "type": "value",
          "value": "VentilatorySupport"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "Checkbox",
      "parent": 59,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.RiskOfAnesthesiaIncludedOther || false"
        },
        "label": {
          "type": "value",
          "value": "Other"
        },
        "name": {
          "type": "value",
          "value": "RiskOfAnesthesiaIncludedOther"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "Input",
      "parent": 59,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.PreAnestheticSequence?.data?.RiskOfAnesthesiaIncludedOther"
        },
        "name": {
          "type": "value",
          "value": "RiskOfAnesthesiaIncludedOtherRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.RiskOfAnesthesiaIncludedOtherRemark || \"\""
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "label",
      "parent": 53,
      "props": {
        "children": {
          "type": "value",
          "value": "Comment"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "TextArea",
      "parent": 53,
      "props": {
        "name": {
          "type": "value",
          "value": "RiskOfAnesthesiaIncludedComment"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "rows": {
          "type": "code",
          "value": "5"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.RiskOfAnesthesiaIncludedComment || \"\""
        }
      },
      "seq": 70,
      "void": true
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "ANES_PreANES_06_Risk",
  "project": "IsHealth_by_Front-end",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
