import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Button,
  Dropdown,
  Checkbox
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardAppointmentSummaryListUX = (props: any) => {
    return(
      <div
        style={{padding: "15px"}}>
        <div
          className="ui form">

          <FormGroup
            inline={true}
            style={{display: "flex", alignItems: "flex-end"}}>
            <FormField
              style={{display: "block"}}
              width="3">
              <label>
                ประเภทนัดหมาย
              </label>
              <div>

                <Dropdown
                  clearable={true}
                  fluid={true}
                  onChange={props.onChange("appointmentType")}
                  options={props.appointmentTypeOptions}
                  selection={true}
                  value={props.appointmentType}>
                </Dropdown>
              </div>
            </FormField>
            <FormField
              style={{display: "block"}}
              width="3">
              <label>
                สถานะนัดหมาย
              </label>
              <div>

                <Dropdown
                  clearable={true}
                  fluid={true}
                  onChange={props.onChange("appointmentStatus")}
                  options={props.appointmentStatusOptions}
                  search={true}
                  selection={true}
                  value={props.appointmentStatus}>
                </Dropdown>
              </div>
            </FormField>
            <FormField
              style={{display: "block"}}
              width="2">
              <label>
                วันที่นัดหมาย
              </label>
              <DateTextBox
                onChange={props.onDateChange("startDate")}
                value={props.startDate}>
              </DateTextBox>
            </FormField>
            <FormField>
              <label
                style={{paddingBottom: "10px"}}>
                ถึง
              </label>
            </FormField>
            <FormField
              style={{display: "block"}}
              width="2">
              <label>

              </label>
              <DateTextBox
                onChange={props.onDateChange("endDate")}
                value={props.endDate}>
              </DateTextBox>
            </FormField>
            <FormField
              style={{display: "block"}}
              width="3">
              <label>
                สถานะรับบริการ
              </label>
              <div>

                <Dropdown
                  clearable={true}
                  fluid={true}
                  onChange={props.onChange("orderStatus")}
                  options={props.orderStatusOptions}
                  selection={true}
                  value={props.orderStatus}>
                </Dropdown>
              </div>
            </FormField>
            <FormField>
              <Button
                color="blue"
                disabled={props.disableShowDetail}
                onClick={props.onClickShowDetail}>
                แสดงรายละเอียด
              </Button>
            </FormField>
          </FormGroup>
          <FormGroup
            style={{display: "flex", alignItems: "flex-end"}}>
            <FormField
              style={{display: "block"}}
              width="3">
              <label>
                HN ผู้ป่วย
              </label>
              <div>
                {props.searchPatient}
              </div>
            </FormField>
            <FormField
              style={{display: "block"}}
              width="3">
              <div
                style={{display: "flex", justifyContent: "space-between"}}>

                <label
                  style={{fontWeight: "bold"}}>
                  แพทย์
                </label>
                <Checkbox
                  checked={props.isUnspecifiedDoctor}
                  disabled={!!props.providerDoctor}
                  label=" ไม่ระบุ"
                  onChange={props.onChange("isUnspecifiedDoctor")}>
                </Checkbox>
              </div>
              <div>

                <Dropdown
                  clearable={true}
                  disabled={props.isUnspecifiedDoctor}
                  fluid={true}
                  onChange={props.onChange("providerDoctor")}
                  options={props.providerDoctorOptions}
                  selection={true}
                  value={props.providerDoctor}>
                </Dropdown>
              </div>
            </FormField>
            <FormField
              width={2}>
              <label
                style={{margin: 0}}>
                ความเชี่ยวชาญ
              </label>
              <Dropdown
                clearable={true}
                fluid={true}
                onChange={props.onChange("specialty")}
                options={props.specialtyOptions}
                selection={true}
                value={props.specialty || ""}>
              </Dropdown>
            </FormField>
            <FormField
              width={2}>
              <label
                style={{margin: 0}}>
                นัดหมายเพื่อ
              </label>
              <Dropdown
                clearable={true}
                fluid={true}
                onChange={props.onChange("patientAppTypeWL")}
                options={props.patientAppTypeWLOptions}
                selection={true}
                style={{width: "calc(100% - -1.5rem)"}}
                value={props.patientAppTypeWL || ""}>
              </Dropdown>
            </FormField>
            <FormField
              style={{marginRight: "-0.5rem", marginLeft: "1.5rem"}}>
              <label>
                {"\u00a0"}
              </label>
              <Button
                color="blue"
                loading={props.loadingFilterAppList}
                onClick={props.onSearch}>
                ค้นหา
              </Button>
            </FormField>
            <FormField
              style={{marginRight: "-0.5rem"}}>
              <label>
                {"\u00a0"}
              </label>
              <div>
                {props.buttonPrintReport}
              </div>
            </FormField>
            <FormField>
              <label>
                {"\u00a0"}
              </label>
              <div>
                {props.buttonDownloadFile}
              </div>
            </FormField>
          </FormGroup>
        </div>
        <div>

          <Table
            data={props.appointmentData}
            defaultPageSize="10"
            getTrProps={props.onGetPatientRow}
            headers=",HN,ชื่อ - สกุล,เบอร์โทร,แพทย์,ความเชี่ยวชาญ,นัดหมายเพื่อ,วันที่นัดหมาย,สถานะนัดหมาย,วันที่รับบริการ,สถานะรับบริการ"
            keys="_icon,hn,patient_name,tel,doctor_name,specialty_text,appointment_for_text,appointment_date,appointment_status,service_date,service_status"
            minRows="10"
            pageSize={10}
            showPagination={false}
            style={{height: "50vh"}}
            widths="60,,,,,,,,">
          </Table>
        </div>
        <div
          style={{display: "flex", justifyContent: "flex-end", padding: "5px"}}>
          {props.pagination}
        </div>
      </div>
    )
}


export default CardAppointmentSummaryListUX

export const screenPropsDefault = {}

/* Date Time : Thu Jun 13 2024 12:43:31 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding: \"15px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"flex-end\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"flex-end\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "FormField",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: \"block\"}"
        },
        "width": {
          "type": "value",
          "value": "3"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormField",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: \"block\"}"
        },
        "width": {
          "type": "value",
          "value": "3"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormField",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: \"block\"}"
        },
        "width": {
          "type": "value",
          "value": "2"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormField",
      "parent": 3,
      "props": {
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "FormField",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: \"block\"}"
        },
        "width": {
          "type": "value",
          "value": "2"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "FormField",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: \"block\"}"
        },
        "width": {
          "type": "value",
          "value": "3"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "FormField",
      "parent": 3,
      "props": {
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "FormField",
      "parent": 4,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: \"block\"}"
        },
        "width": {
          "type": "value",
          "value": "3"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "FormField",
      "parent": 4,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: \"block\"}"
        },
        "width": {
          "type": "value",
          "value": "3"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"-0.5rem\", marginLeft: \"1.5rem\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 15,
      "name": "Table",
      "parent": 2,
      "props": {
        "data": {
          "type": "code",
          "value": "props.appointmentData"
        },
        "defaultPageSize": {
          "type": "value",
          "value": "10"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.onGetPatientRow"
        },
        "headers": {
          "type": "value",
          "value": ",HN,ชื่อ - สกุล,เบอร์โทร,แพทย์,ความเชี่ยวชาญ,นัดหมายเพื่อ,วันที่นัดหมาย,สถานะนัดหมาย,วันที่รับบริการ,สถานะรับบริการ"
        },
        "keys": {
          "type": "value",
          "value": "_icon,hn,patient_name,tel,doctor_name,specialty_text,appointment_for_text,appointment_date,appointment_status,service_date,service_status"
        },
        "minRows": {
          "type": "value",
          "value": "10"
        },
        "pageSize": {
          "type": "code",
          "value": "10"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"50vh\"}"
        },
        "widths": {
          "type": "value",
          "value": "60,,,,,,,,"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "label",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทนัดหมาย"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "label",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะนัดหมาย"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "label",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่นัดหมาย"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "style": {
          "type": "code",
          "value": "{paddingBottom: \"10px\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "label",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "label",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะรับบริการ"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "Button",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": "แสดงรายละเอียด"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.disableShowDetail"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickShowDetail"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "Dropdown",
      "parent": 23,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChange(\"appointmentType\")"
        },
        "options": {
          "type": "code",
          "value": "props.appointmentTypeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.appointmentType"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "Dropdown",
      "parent": 25,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChange(\"appointmentStatus\")"
        },
        "options": {
          "type": "code",
          "value": "props.appointmentStatusOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.appointmentStatus"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "Dropdown",
      "parent": 27,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChange(\"orderStatus\")"
        },
        "options": {
          "type": "code",
          "value": "props.orderStatusOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.orderStatus"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 29,
      "name": "DateTextBox",
      "parent": 7,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onDateChange(\"startDate\")"
        },
        "value": {
          "type": "code",
          "value": "props.startDate"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 30,
      "name": "DateTextBox",
      "parent": 9,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onDateChange(\"endDate\")"
        },
        "value": {
          "type": "code",
          "value": "props.endDate"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "label",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "HN ผู้ป่วย"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "code",
          "value": "props.searchPatient"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "label",
      "parent": 33,
      "props": {
        "children": {
          "type": "value",
          "value": "แพทย์"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "Checkbox",
      "parent": 33,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isUnspecifiedDoctor"
        },
        "disabled": {
          "type": "code",
          "value": "!!props.providerDoctor"
        },
        "label": {
          "type": "value",
          "value": " ไม่ระบุ"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChange(\"isUnspecifiedDoctor\")"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "Button",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "loading": {
          "type": "code",
          "value": "props.loadingFilterAppList"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSearch"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.pagination"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", padding: \"5px\"}"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "Dropdown",
      "parent": 34,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.isUnspecifiedDoctor"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChange(\"providerDoctor\")"
        },
        "options": {
          "type": "code",
          "value": "props.providerDoctorOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.providerDoctor"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "FormField",
      "parent": 4,
      "props": {
        "style": {
          "type": "code",
          "value": "{marginRight: \"-0.5rem\"}"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "FormField",
      "parent": 4,
      "props": {
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "label",
      "parent": 45,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "label",
      "parent": 44,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "div",
      "parent": 45,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonDownloadFile"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "label",
      "parent": 14,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "FormField",
      "parent": 4,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "FormField",
      "parent": 4,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "label",
      "parent": 51,
      "props": {
        "children": {
          "type": "value",
          "value": "ความเชี่ยวชาญ"
        },
        "style": {
          "type": "code",
          "value": "{margin: 0}"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "label",
      "parent": 52,
      "props": {
        "children": {
          "type": "value",
          "value": "นัดหมายเพื่อ"
        },
        "style": {
          "type": "code",
          "value": "{margin: 0}"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "Dropdown",
      "parent": 51,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChange(\"specialty\")"
        },
        "options": {
          "type": "code",
          "value": "props.specialtyOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.specialty || \"\""
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 56,
      "name": "Dropdown",
      "parent": 52,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChange(\"patientAppTypeWL\")"
        },
        "options": {
          "type": "code",
          "value": "props.patientAppTypeWLOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"calc(100% - -1.5rem)\"}"
        },
        "value": {
          "type": "code",
          "value": "props.patientAppTypeWL || \"\""
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 44,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonPrintReport"
        }
      },
      "seq": 57,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardAppointmentSummaryListUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
