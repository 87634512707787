import WasmController from "react-lib/frameworks/WasmController";
// sequence
import * as SegmentI from "./sequence/Segment";
export type State = {

} & SegmentI.State

export const StateInitial: State = {
  ...SegmentI.StateInitial,
}

export type Event =
  | { message: "RunSequence"; params: any }
  | { message: "GetMasterData"; params: {} }
  | SegmentI.Event

export type Data = {
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;