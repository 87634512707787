import WasmController from "react-lib/frameworks/WasmController";
import { State as MainState } from "../../../../../HIS/MainHISInterface";

// APIs
import PricingListCreateAPIView from "issara-sdk/apis/PricingListCreateAPIView_apps_BIL";

export type State = Partial<{
  // Sequence
  // ButtonLoadCheck: any;
  masterOptions: any;
  errorMessage: any;
  SupplyServiceCheckingSequence: Partial<{
    sequenceIndex: "START" | "ACTION" | null;
    // filter
    filter: Partial<filterType>;
    // data
    paginatedItems: { items: any[]; page: number; pageSize: number; total: number };
    // options
    statusOptions: any[];
  }> | null;
}>;

export type filterType = {
  code: number | string | null;
  name: string | null;
  isType: boolean;
  type: number | string | null;
  isStatus: boolean;
  status: number | string | null;
  isCategory: boolean;
  category: number | string | null;
};

export const StateInitial: State = {
  SupplyServiceCheckingSequence: {
    sequenceIndex: null,
  },
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  division?: number;
};

type Picked = Partial<
  Pick<
    MainState,
    "buttonLoadCheck" | "errorMessage" | "successMessage" | "masterOptions" | "django"
  >
>;

type Handler = (controller: WasmController<State & Picked, Event, Data>, params?: any) => any;

export const DataInitial = {};

export const allOptions = [{ key: 0, value: 0, text: "ทั้งหมด" }];

export const ACTIVE_FLAGS = [
  { key: 0, value: "ALL", text: "ทั้งหมด" },
  { key: 1, value: "ACTIVE", text: "ขายได้ปกติ" },
  { key: 2, value: "INSUFFICIENT", text: "ขาดชั่วคราว(Temo Unava)" },
  { key: 3, value: "UNAVAILABLE", text: "เลิกใช้งานแต่ของยังไม่หมด" },
  { key: 4, value: "TERMINATED", text: "เลิกใช้งาน" },
  { key: 5, value: "INACTIVE", text: "ยังไม่พร้อมขาย" },
];

export const LIST_LIMIT = 20;

/**============================================
 **                   START
 *=============================================**/
export const Start: Handler = async (controller, params) => {
  const state = controller.getState();

  // Master data
  await controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["productType", {}],
        ["billMode", {}],
      ],
    },
  });

  // Init Search
  const data = {
    code: null,
    name: null,
    type: 0,
    status: "ACTIVE",
    category: 0,
  };

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`CardSupplyServiceChecking_SEARCH`]: "LOADING",
    },
    SupplyServiceCheckingSequence: {
      ...state.SupplyServiceCheckingSequence,
      sequenceIndex: "ACTION",
      filter: {
        code: data.code,
        name: data.name,
        isType: true,
        type: data.type,
        isStatus: true,
        status: data.status,
        isCategory: true,
        category: data.category,
      },
      statusOptions: ACTIVE_FLAGS,
    },
  });

  const result = await GetPricingList(controller, {
    data: data,
    pageSize: LIST_LIMIT,
    page: 0,
  });

  if (result.err) {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`CardSupplyServiceChecking_SEARCH`]: "ERROR",
      },
      errorMessage: {
        ...state.errorMessage,
        [`CardSupplyServiceChecking`]: result.err,
      },
      SupplyServiceCheckingSequence: {
        ...state.SupplyServiceCheckingSequence,
        sequenceIndex: "ACTION",
        filter: {
          code: data.code,
          name: data.name,
          isType: true,
          type: data.type,
          isStatus: true,
          status: data.status,
          isCategory: true,
          category: data.category,
        },
        paginatedItems: {},
        statusOptions: ACTIVE_FLAGS,
      },
    });
  }

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`CardSupplyServiceChecking_SEARCH`]: "SUCCESS",
    },
    SupplyServiceCheckingSequence: {
      ...state.SupplyServiceCheckingSequence,
      sequenceIndex: "ACTION",
      filter: {
        code: data.code,
        name: data.name,
        isType: true,
        type: data.type,
        isStatus: true,
        status: data.status,
        isCategory: true,
        category: data.category,
      },
      paginatedItems: result?.resp,
      statusOptions: ACTIVE_FLAGS,
    },
  });
};

/**============================================
 **              ACTION & HANDLER
 *=============================================**/
export const Action: Handler = async (controller, params) => {
  const state = controller.getState();

  if (params?.action === "SEARCH") {
    HandleSearch(controller, params);
  }
};

const HandleSearch: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_SEARCH`]: "LOADING",
    },
  });

  const filter = state.SupplyServiceCheckingSequence?.filter || {};
  const paginatedItems = state.SupplyServiceCheckingSequence?.paginatedItems;

  const data = {
    code: filter.code || null,
    name: filter.name || null,
    type: filter.isType
      ? filter.type
      : null,
    status: filter.isStatus
      ? filter.status
      : null,
    category: filter.isCategory
      ? filter.category
      : null,
  };

  const result = await GetPricingList(controller, {
    data: data,
    pageSize: LIST_LIMIT,
    page: params.page ?? paginatedItems?.page,
    pageSize: params.pageSize ?? paginatedItems?.pageSize,
  });

  if (result.err) {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_SEARCH`]: "ERROR",
      },
      errorMessage: {
        ...state.errorMessage,
        [`${params.card}`]: result.err,
      },
    });
  }

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_SEARCH`]: "SUCCESS",
    },
    SupplyServiceCheckingSequence: {
      ...state.SupplyServiceCheckingSequence,
      paginatedItems: result.resp,
    },
  });
};

/**============================================
 **                   APIS
 *=============================================**/
const GetPricingList: Handler = async (controller, params) => {
  const state = controller.getState();

  if (!params) {
    console.warn("Have no data in params: ", params);
    return;
  }

  const offset = params.page * params.pageSize;

  const [resp, err] = await PricingListCreateAPIView.list({
    apiToken: controller.apiToken,
    params: {
      limit: params.pageSize,
      offset,
      code: params.data?.code,
      name: params.data?.name,
      p_type: params.data?.type !== 0 ? params.data?.type : null,
      active_flag: params?.data?.status !== "ALL" ? params?.data?.status : null,
      bill_mode: params?.data?.category !== 0 ? params?.data?.category : null,
    },
    extra: {
      division: controller.data.division,
    },
  });

  if (err) {
    return { resp: resp ?? null, err: err };
  }

  return { resp: { ...resp, pageSize: params.pageSize, page: params.page }, err: null };
};