import AddTableLivingWillEN from "./AddTableLivingWillEN";

export default function toPDFMakeData(props: any) {
  return {
    content: [
      {
        alignment: `center`,
        margin: [0, 0, 0, 0],
        text: `Living Will`,
        preserveLeadingSpaces: true,
        bold: `true`,
        decoration: ``,
        pageBreak: ``,
        fontSize: `18`,
        decorationStyle: ``,
        decorationColor: ``,
        color: ``,
        width: `auto`,
      },
      {
        width: `auto`,
        margin: [0, 0, 0, 0],
        decorationStyle: ``,
        color: ``,
        alignment: `left`,
        decorationColor: ``,
        decoration: ``,
        text: ` `,
        pageBreak: ``,
        fontSize: 15,
        preserveLeadingSpaces: true,
        bold: false,
      },
      {
        columns: [
          {
            margin: [0, 0, 0, 0],
            fontSize: 15,
            color: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decorationColor: ``,
            alignment: `left`,
            width: `auto`,
            bold: false,
            text: `I (Mr./Mrs./Ms.)`,
            decoration: ``,
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.formatPatient,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `..................................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            decorationColor: ``,
            pageBreak: ``,
            bold: false,
            text: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            width: 10,
            alignment: `left`,
            decoration: ``,
            color: ``,
          },
          {
            text: `Passport`,
            fontSize: 15,
            bold: false,
            alignment: `left`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            width: `auto`,
            decorationColor: ``,
            decoration: ``,
            color: ``,
            margin: [0, 0, 0, 0],
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.citizen_passport || props.items?.citizen_no || " ",
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `..............................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            text: `Nationality`,
            pageBreak: ``,
            decoration: ``,
            decorationStyle: ``,
            bold: false,
            fontSize: 15,
            width: `auto`,
            alignment: `left`,
            color: ``,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.patientNationality || " ",
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.........................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            bold: false,
            text: ``,
            width: 10,
            color: ``,
            decorationStyle: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            decorationColor: ``,
            fontSize: 15,
            decoration: ``,
          },
          {
            color: ``,
            pageBreak: ``,
            decorationColor: ``,
            width: `auto`,
            bold: false,
            margin: [0, 0, 0, 0],
            text: `Phone number`,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decorationStyle: ``,
            decoration: ``,
            fontSize: 15,
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items.present_address?.tel_mobile || " ",
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            bold: false,
            alignment: `left`,
            text: ``,
            pageBreak: ``,
            color: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 10,
            preserveLeadingSpaces: true,
            decoration: ``,
            margin: [0, 0, 0, 0],
            fontSize: 15,
          },
          {
            color: ``,
            width: `auto`,
            bold: false,
            pageBreak: ``,
            decorationColor: ``,
            text: `Current address`,
            decorationStyle: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            decoration: ``,
            alignment: `left`,
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.present_address?.no || " ",
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `..................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: `Village no.`,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: `auto`,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.present_address?.town || " ",
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.....................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            text: `Village`,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: `auto`,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.present_address?.name || " ",
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `............................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: `Alley/Lane`,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: `auto`,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.present_address?.street || " ",
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `............................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: `Road`,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: `auto`,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.present_address?.road || " ",
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.........................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: `Sub-district`,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: `auto`,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.present_address?.city_en_label || " ",
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `......................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            text: `District`,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: `auto`,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.present_address?.district_en_label || " ",
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.........................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: `Province`,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: `auto`,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.present_address?.province_en_label || " ",
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.........................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: `Zipcode`,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: `auto`,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.present_address?.zipcode || " ",
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `............................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            bold: false,
            decorationStyle: ``,
            text: ``,
            preserveLeadingSpaces: true,
            width: 10,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            alignment: `left`,
            pageBreak: ``,
            decoration: ``,
            color: ``,
          },
          {
            text: `do hereby wilfully and voluntarily make known my desire that when I am in terminally ill condition as`,
            decorationColor: ``,
            color: ``,
            bold: false,
            decoration: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            pageBreak: ``,
            fontSize: 15,
            width: `auto`,
            decorationStyle: ``,
            alignment: `left`,
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            decorationStyle: ``,
            bold: false,
            width: 10,
            preserveLeadingSpaces: true,
            text: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            color: ``,
            pageBreak: ``,
            decoration: ``,
            alignment: `left`,
          },
          {
            decoration: ``,
            margin: [0, 0, 0, 0],
            width: `auto`,
            pageBreak: ``,
            decorationStyle: ``,
            text: `diadnosed by my physician, I request physicians, nurses, and staff of Sapiens Hospital or other health care`,
            color: ``,
            alignment: `left`,
            fontSize: 15,
            bold: false,
            decorationColor: ``,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            width: 10,
            decorationStyle: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            text: ``,
            alignment: `left`,
            decorationColor: ``,
            bold: false,
            decoration: ``,
            color: ``,
          },
          {
            decoration: ``,
            decorationColor: ``,
            bold: false,
            alignment: `left`,
            pageBreak: ``,
            decorationStyle: ``,
            text: `facility not to provide any medical care that would only serve either to artificially delay the moment of my`,
            width: `auto`,
            preserveLeadingSpaces: true,
            color: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            text: ``,
            decorationStyle: ``,
            width: 10,
            fontSize: 15,
            pageBreak: ``,
            bold: false,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            color: ``,
            alignment: `left`,
          },
          {
            alignment: `left`,
            bold: false,
            decorationStyle: ``,
            pageBreak: ``,
            width: `auto`,
            decoration: ``,
            text: `death or to prolong the suffering from my illness.`,
            margin: [0, 0, 0, 0],
            color: ``,
            decorationColor: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            text: ``,
            bold: false,
            decorationColor: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            color: ``,
            fontSize: 15,
            width: 10,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            alignment: `left`,
          },
          {
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            decorationStyle: ``,
            color: ``,
            pageBreak: ``,
            width: `auto`,
            decoration: ``,
            preserveLeadingSpaces: true,
            text: `Those medical cares include:`,
            alignment: `left`,
            fontSize: 15,
          },
        ],
      },
      {
        decorationColor: ``,
        fontSize: 15,
        pageBreak: ``,
        decoration: ``,
        bold: false,
        decorationStyle: ``,
        width: `auto`,
        text: ` `,
        margin: [0, 0, 0, 0],
        color: ``,
        preserveLeadingSpaces: true,
        alignment: `left`,
      },
      AddTableLivingWillEN,
      {
        decorationStyle: ``,
        preserveLeadingSpaces: true,
        text: ` `,
        pageBreak: ``,
        width: `auto`,
        bold: false,
        decoration: ``,
        color: ``,
        decorationColor: ``,
        margin: [0, 0, 0, 0],
        fontSize: 15,
        alignment: `left`,
      },
      {
        columns: [
          {
            pageBreak: ``,
            decorationStyle: ``,
            text: ``,
            color: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            alignment: `left`,
            width: 10,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            decoration: ``,
          },
          {
            decoration: ``,
            decorationColor: ``,
            width: `auto`,
            pageBreak: ``,
            bold: false,
            preserveLeadingSpaces: true,
            alignment: `left`,
            text: `All physicians, nurses, other healtcare providers and Sapiens Hospital staff members who have exected my`,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            color: ``,
            fontSize: 15,
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            margin: [0, 0, 0, 0],
            decoration: ``,
            text: ``,
            pageBreak: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            color: ``,
            alignment: `left`,
            width: 10,
            bold: false,
            decorationStyle: ``,
          },
          {
            decoration: ``,
            decorationStyle: ``,
            fontSize: 15,
            color: ``,
            margin: [0, 0, 0, 0],
            text: `intention as stated in this Living Will are deemed not legally responsible and released from all liabilities. I read`,
            decorationColor: ``,
            width: `auto`,
            alignment: `left`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            bold: false,
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            decorationColor: ``,
            color: ``,
            text: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            bold: false,
            decoration: ``,
            width: 10,
          },
          {
            pageBreak: `after`,
            decorationStyle: ``,
            alignment: `left`,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            fontSize: 15,
            width: `auto`,
            margin: [0, 0, 0, 0],
            color: ``,
            text: `and understood conditions and regulations of Living Will as attached herein.`,
            bold: false,
          },
        ],
      },
      {
        columns: [
          {
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            preserveLeadingSpaces: true,
            bold: false,
            color: ``,
            alignment: `left`,
            fontSize: 15,
            decorationStyle: ``,
            text: ``,
            pageBreak: ``,
            decoration: ``,
            width: 10,
          },
          {
            text: `I hereby certify that I am competent and fully conscious while making this document. I read and clearly understood that this Livng Will is according to my wishes. Therefore, I sign my name in the presence of witnesses.`,
            bold: false,
            alignment: `left`,
            preserveLeadingSpaces: true,
            width: `auto`,
            decoration: ``,
            decorationStyle: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            color: ``,
            decorationColor: ``,
            pageBreak: ``,
          },
        ],
      },
      {
        text: ` `,
        fontSize: 15,
        decorationColor: ``,
        margin: [0, 0, 0, 0],
        color: ``,
        decorationStyle: ``,
        pageBreak: ``,
        preserveLeadingSpaces: true,
        decoration: ``,
        width: `auto`,
        alignment: `left`,
        bold: false,
      },
      {
        pageBreak: ``,
        width: `auto`,
        text: ` `,
        decoration: ``,
        decorationColor: ``,
        margin: [0, 0, 0, 0],
        bold: false,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        alignment: `left`,
        color: ``,
        fontSize: 15,
      },
      {
        columns: [
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.full_name_en || props.items?.full_name,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `......................................................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            decorationColor: ``,
            text: ``,
            bold: false,
            decorationStyle: ``,
            pageBreak: ``,
            width: 10,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decoration: ``,
            color: ``,
          },
          {
            width: `auto`,
            stack: [
              {
                text: ` `,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.....................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            decorationStyle: ``,
            decorationColor: ``,
            pageBreak: ``,
            color: ``,
            text: ``,
            width: 10,
            fontSize: 15,
            bold: false,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            decoration: ``,
            alignment: `left`,
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.signedDate,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            decorationColor: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            width: 86,
            color: ``,
            decoration: ``,
            text: ``,
            bold: false,
            pageBreak: ``,
            decorationStyle: ``,
          },
          {
            color: ``,
            margin: [0, 0, 0, 0],
            text: `Patient's Name`,
            pageBreak: ``,
            decoration: ``,
            alignment: `left`,
            width: `auto`,
            decorationColor: ``,
            bold: false,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decorationStyle: ``,
          },
          {
            decorationStyle: ``,
            alignment: `left`,
            decorationColor: ``,
            fontSize: 15,
            color: ``,
            pageBreak: ``,
            width: 155,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            bold: false,
            decoration: ``,
            text: ``,
          },
          {
            text: `Signature`,
            decorationStyle: ``,
            width: `auto`,
            margin: [0, 0, 0, 0],
            color: ``,
            pageBreak: ``,
            decorationColor: ``,
            bold: false,
            decoration: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            fontSize: 15,
          },
          {
            decoration: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            text: ``,
            width: 95,
            alignment: `left`,
            color: ``,
            decorationColor: ``,
            pageBreak: ``,
          },
          {
            alignment: `left`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            pageBreak: ``,
            text: `Date`,
            width: `auto`,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            decoration: ``,
            color: ``,
            bold: false,
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            decorationStyle: ``,
            alignment: `left`,
            width: 165,
            text: ``,
            bold: false,
            decoration: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            color: ``,
          },
          {
            decoration: ``,
            width: `auto`,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            text: ``,
            alignment: `left`,
            decorationStyle: ``,
            color: ``,
            bold: false,
            pageBreak: ``,
          },
        ],
      },
      {
        bold: false,
        fontSize: 15,
        decoration: ``,
        pageBreak: ``,
        text: ` `,
        decorationColor: ``,
        width: `auto`,
        color: ``,
        margin: [0, 0, 0, 0],
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        alignment: `left`,
      },
      {
        width: `auto`,
        fontSize: 15,
        color: ``,
        bold: false,
        margin: [0, 0, 0, 0],
        alignment: `left`,
        decoration: ``,
        decorationStyle: ``,
        pageBreak: ``,
        text: ` `,
        decorationColor: ``,
        preserveLeadingSpaces: true,
      },
      {
        columns: [
          {
            width: `auto`,
            stack: [
              {
                text: ` `,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `......................................................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            preserveLeadingSpaces: true,
            text: ``,
            decorationColor: ``,
            bold: false,
            color: ``,
            pageBreak: ``,
            decoration: ``,
            width: 10,
            alignment: `left`,
            decorationStyle: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: ` `,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.....................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            fontSize: 15,
            decoration: ``,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            decorationColor: ``,
            text: ``,
            bold: false,
            decorationStyle: ``,
            width: 10,
            alignment: `left`,
            color: ``,
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.signedDate,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            bold: false,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            color: ``,
            fontSize: 15,
            alignment: `left`,
            decorationStyle: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            width: 87,
            text: ``,
          },
          {
            preserveLeadingSpaces: true,
            bold: false,
            alignment: `left`,
            color: ``,
            text: `Witness' Name`,
            margin: [0, 0, 0, 0],
            width: `auto`,
            decorationColor: ``,
            decoration: ``,
            pageBreak: ``,
            fontSize: 15,
            decorationStyle: ``,
          },
          {
            preserveLeadingSpaces: true,
            text: ``,
            decorationColor: ``,
            decoration: ``,
            color: ``,
            bold: false,
            fontSize: 15,
            decorationStyle: ``,
            pageBreak: ``,
            width: 155,
            margin: [0, 0, 0, 0],
            alignment: `left`,
          },
          {
            width: `auto`,
            decorationStyle: ``,
            text: `Signature`,
            alignment: `left`,
            pageBreak: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            color: ``,
            bold: false,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            decoration: ``,
          },
          {
            fontSize: 15,
            bold: false,
            decoration: ``,
            margin: [0, 0, 0, 0],
            color: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            alignment: `left`,
            text: ``,
            width: 97,
            decorationStyle: ``,
            pageBreak: ``,
          },
          {
            color: ``,
            width: `auto`,
            text: `Date`,
            decoration: ``,
            decorationColor: ``,
            pageBreak: ``,
            bold: false,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decorationStyle: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
          },
        ],
      },
      {
        decoration: ``,
        pageBreak: ``,
        decorationColor: ``,
        text: ` `,
        bold: false,
        width: `auto`,
        alignment: `left`,
        preserveLeadingSpaces: true,
        color: ``,
        margin: [0, 0, 0, 0],
        decorationStyle: ``,
        fontSize: 15,
      },
      {
        pageBreak: ``,
        decorationStyle: ``,
        text: ` `,
        width: `auto`,
        decorationColor: ``,
        bold: false,
        margin: [0, 0, 0, 0],
        color: ``,
        decoration: ``,
        fontSize: 15,
        alignment: `left`,
        preserveLeadingSpaces: true,
      },
      {
        columns: [
          {
            width: `auto`,
            stack: [
              {
                text: ` `,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `......................................................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            decorationStyle: ``,
            text: ``,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            decoration: ``,
            bold: false,
            alignment: `left`,
            fontSize: 15,
            width: 10,
            preserveLeadingSpaces: true,
            color: ``,
            decorationColor: ``,
          },
          {
            width: `auto`,
            stack: [
              {
                text: ` `,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.....................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            pageBreak: ``,
            width: 10,
            fontSize: 15,
            decorationStyle: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            text: ``,
            decoration: ``,
            decorationColor: ``,
            bold: false,
            color: ``,
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.signedDate,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            alignment: `left`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            color: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            decoration: ``,
            width: 75,
            decorationColor: ``,
            fontSize: 15,
            text: ``,
          },
          {
            margin: [0, 0, 0, 0],
            alignment: `left`,
            color: ``,
            decoration: ``,
            text: `Second Witness' Name`,
            decorationColor: ``,
            pageBreak: ``,
            decorationStyle: ``,
            width: `auto`,
            preserveLeadingSpaces: true,
            fontSize: 15,
            bold: false,
          },
          {
            fontSize: 15,
            decoration: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            color: ``,
            text: ``,
            bold: false,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            width: 130,
            pageBreak: ``,
          },
          {
            fontSize: 15,
            preserveLeadingSpaces: true,
            bold: false,
            color: ``,
            width: `auto`,
            pageBreak: ``,
            decoration: ``,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            decorationStyle: ``,
            text: `Signature`,
          },
          {
            color: ``,
            alignment: `left`,
            width: 97,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            bold: false,
            decoration: ``,
            decorationStyle: ``,
            text: ``,
          },
          {
            margin: [0, 0, 0, 0],
            width: `auto`,
            fontSize: 15,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            bold: false,
            pageBreak: ``,
            color: ``,
            text: `Date`,
          },
        ],
      },
      {
        color: ``,
        fontSize: 15,
        decorationColor: ``,
        decoration: ``,
        width: `auto`,
        text: ` `,
        pageBreak: ``,
        preserveLeadingSpaces: true,
        margin: [0, 0, 0, 0],
        decorationStyle: ``,
        alignment: `left`,
        bold: false,
      },
      {
        alignment: `left`,
        fontSize: 15,
        color: ``,
        margin: [0, 0, 0, 0],
        decorationStyle: ``,
        pageBreak: ``,
        width: `auto`,
        decorationColor: ``,
        decoration: ``,
        preserveLeadingSpaces: true,
        bold: false,
        text: ` `,
      },
      {
        columns: [
          {
            width: `auto`,
            text: `I, Dr.`,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            color: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            fontSize: 15,
            bold: false,
            decorationColor: ``,
            decorationStyle: ``,
            decoration: ``,
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.formatDoctor || " ",
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `...................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            width: 10,
            fontSize: 15,
            preserveLeadingSpaces: true,
            text: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            decoration: ``,
            pageBreak: ``,
            decorationColor: ``,
            decorationStyle: ``,
            color: ``,
            bold: false,
          },
          {
            decorationStyle: ``,
            text: `License number`,
            preserveLeadingSpaces: true,
            bold: false,
            decorationColor: ``,
            width: `auto`,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            decoration: ``,
            pageBreak: ``,
            fontSize: 15,
            color: ``,
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.doctorNumber || " ",
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.........................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            fontSize: 15,
            color: ``,
            decorationColor: ``,
            text: ``,
            decoration: ``,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            width: 10,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            bold: false,
            alignment: `left`,
          },
          {
            decoration: ``,
            text: `hereby certity that`,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            width: `auto`,
            alignment: `left`,
            fontSize: 15,
            bold: false,
            margin: [0, 0, 0, 0],
            color: ``,
            decorationStyle: ``,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            preserveLeadingSpaces: true,
            fontSize: 15,
            text: `(the patient)`,
            alignment: `left`,
            decoration: ``,
            width: `auto`,
            decorationColor: ``,
            decorationStyle: ``,
            pageBreak: ``,
            color: ``,
            margin: [0, 0, 0, 0],
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.full_name_en || props.items?.full_name,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.........................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            color: ``,
            alignment: `left`,
            decorationColor: ``,
            pageBreak: ``,
            decoration: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            width: 10,
            bold: false,
            text: ``,
            fontSize: 15,
          },
          {
            pageBreak: ``,
            decoration: ``,
            fontSize: 15,
            color: ``,
            width: `auto`,
            alignment: `left`,
            preserveLeadingSpaces: true,
            bold: false,
            text: `is fully conscious while completing this Living Will.`,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            decorationColor: ``,
          },
        ],
      },
      {
        fontSize: 15,
        pageBreak: ``,
        alignment: `left`,
        margin: [0, 0, 0, 0],
        width: `auto`,
        decorationColor: ``,
        decorationStyle: ``,
        bold: false,
        text: ` `,
        decoration: ``,
        color: ``,
        preserveLeadingSpaces: true,
      },
      {
        width: `auto`,
        fontSize: 15,
        pageBreak: ``,
        decorationStyle: ``,
        alignment: `left`,
        bold: false,
        decoration: ``,
        preserveLeadingSpaces: true,
        text: ` `,
        color: ``,
        decorationColor: ``,
        margin: [0, 0, 0, 0],
      },
      {
        columns: [
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.formatDoctor || " ",
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `......................................................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            decorationColor: ``,
            fontSize: 15,
            alignment: `left`,
            pageBreak: ``,
            width: 10,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            decoration: ``,
            text: ``,
            bold: false,
            color: ``,
          },
          {
            width: `auto`,
            stack: [
              {
                text: ` `,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.....................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            decoration: ``,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            preserveLeadingSpaces: true,
            text: ``,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            color: ``,
            width: 10,
            decorationStyle: ``,
            bold: false,
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.signedDate,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            fontSize: 15,
            decorationStyle: ``,
            width: 72,
            color: ``,
            alignment: `left`,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            text: ``,
            bold: false,
            preserveLeadingSpaces: true,
          },
          {
            decoration: ``,
            width: `auto`,
            text: `Physician's Name`,
            bold: false,
            color: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            pageBreak: ``,
            alignment: `left`,
            fontSize: 15,
          },
          {
            pageBreak: ``,
            fontSize: 15,
            bold: false,
            decorationColor: ``,
            color: ``,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            text: ``,
            alignment: `left`,
            width: 115,
            preserveLeadingSpaces: true,
            decoration: ``,
          },
          {
            alignment: `left`,
            preserveLeadingSpaces: true,
            text: `Physician's Signature`,
            decorationStyle: ``,
            width: `auto`,
            decoration: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            bold: false,
            color: ``,
            pageBreak: ``,
          },
          {
            decoration: ``,
            color: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            bold: false,
            text: ``,
            decorationStyle: ``,
            pageBreak: ``,
            decorationColor: ``,
            fontSize: 15,
            width: 90,
            margin: [0, 0, 0, 0],
          },
          {
            decorationColor: ``,
            alignment: `left`,
            pageBreak: ``,
            color: ``,
            text: `Date`,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            decoration: ``,
            width: `auto`,
            bold: false,
            fontSize: 15,
            decorationStyle: ``,
          },
        ],
      },
      {
        pageBreak: ``,
        alignment: `left`,
        decoration: ``,
        bold: false,
        text: ` `,
        decorationStyle: ``,
        preserveLeadingSpaces: true,
        fontSize: 15,
        color: ``,
        decorationColor: ``,
        width: `auto`,
        margin: [0, 0, 0, 0],
      },
      {
        text: `I request to revoke this Living Will`,
        decorationColor: ``,
        fontSize: 15,
        pageBreak: ``,
        preserveLeadingSpaces: true,
        decoration: ``,
        width: `auto`,
        decorationStyle: ``,
        bold: `true`,
        alignment: `left`,
        color: ``,
        margin: [0, 0, 0, 0],
      },
      {
        margin: [0, 0, 0, 0],
        decoration: ``,
        decorationStyle: ``,
        alignment: `left`,
        pageBreak: ``,
        width: `auto`,
        preserveLeadingSpaces: true,
        bold: false,
        decorationColor: ``,
        fontSize: 15,
        color: ``,
        text: ` `,
      },
      {
        width: `auto`,
        decoration: ``,
        decorationColor: ``,
        margin: [0, 0, 0, 0],
        pageBreak: ``,
        alignment: `left`,
        text: ` `,
        decorationStyle: ``,
        color: ``,
        preserveLeadingSpaces: true,
        bold: false,
        fontSize: 15,
      },
      {
        columns: [
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.full_name_en || props.items?.full_name,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `......................................................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            decoration: ``,
            text: ``,
            color: ``,
            margin: [0, 0, 0, 0],
            width: 10,
            alignment: `left`,
            fontSize: 15,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            bold: false,
            decorationColor: ``,
          },
          {
            width: `auto`,
            stack: [
              {
                text: ` `,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.....................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            text: ``,
            fontSize: 15,
            alignment: `left`,
            decorationStyle: ``,
            color: ``,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            decoration: ``,
            width: 10,
            pageBreak: ``,
            bold: false,
            preserveLeadingSpaces: true,
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.signedDate,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            color: ``,
            text: ``,
            bold: false,
            decoration: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            width: 95,
            decorationStyle: ``,
            decorationColor: ``,
            alignment: `left`,
            pageBreak: ``,
          },
          {
            margin: [0, 0, 0, 0],
            color: ``,
            decorationColor: ``,
            pageBreak: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            fontSize: 15,
            bold: false,
            decoration: ``,
            text: `Patient's Name`,
            width: `auto`,
          },
          {
            bold: false,
            pageBreak: ``,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            decoration: ``,
            width: 125,
            alignment: `left`,
            fontSize: 15,
            text: ``,
            preserveLeadingSpaces: true,
            color: ``,
            decorationStyle: ``,
          },
          {
            alignment: `left`,
            text: `Patient's Signature`,
            decoration: ``,
            fontSize: 15,
            bold: false,
            color: ``,
            width: `auto`,
            pageBreak: ``,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            preserveLeadingSpaces: true,
          },
          {
            color: ``,
            width: 80,
            decoration: ``,
            margin: [0, 0, 0, 0],
            text: ``,
            alignment: `left`,
            fontSize: 15,
            preserveLeadingSpaces: true,
            bold: false,
            decorationColor: ``,
            decorationStyle: ``,
            pageBreak: ``,
          },
          {
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            pageBreak: `after`,
            color: ``,
            decorationColor: ``,
            alignment: `left`,
            width: `auto`,
            text: `Date`,
            bold: false,
            decoration: ``,
            decorationStyle: ``,
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            fontSize: 15,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            width: `auto`,
            decorationStyle: ``,
            alignment: `left`,
            text: `Regulation and process of Living Will`,
            color: ``,
            bold: `true`,
            decoration: `underline`,
          },
        ],
      },
      {
        columns: [
          {
            margin: [0, 0, 0, 0],
            width: `auto`,
            pageBreak: ``,
            text: `(1)`,
            preserveLeadingSpaces: true,
            bold: false,
            decoration: ``,
            alignment: `left`,
            fontSize: 15,
            decorationColor: ``,
            color: ``,
            decorationStyle: ``,
          },
          {
            preserveLeadingSpaces: true,
            alignment: `left`,
            decorationStyle: ``,
            decoration: ``,
            width: 10,
            text: ``,
            decorationColor: ``,
            bold: false,
            color: ``,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            pageBreak: ``,
          },
          {
            pageBreak: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
            text: `The Living Will requires two witnesses: the first witness must be the family member or a person trusted by the patient and understanding the patient's intention well. The second winess can be anyone except the patient's family member and not a physician, nurse or any staff related to the treatment of such patient.`,
            fontSize: 15,
            decorationColor: ``,
            decoration: ``,
            alignment: `left`,
            bold: false,
            color: ``,
            margin: [0, 0, 0, 0],
          },
        ],
      },
      {
        text: ` `,
        pageBreak: ``,
        bold: false,
        alignment: `left`,
        margin: [0, 0, 0, 0],
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        color: ``,
        decoration: ``,
        decorationColor: ``,
        fontSize: 15,
        width: `auto`,
      },
      {
        columns: [
          {
            decorationColor: ``,
            pageBreak: ``,
            alignment: `left`,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            decoration: ``,
            width: `auto`,
            fontSize: 15,
            text: `(2)`,
            preserveLeadingSpaces: true,
            bold: false,
            color: ``,
          },
          {
            decorationColor: ``,
            decoration: ``,
            margin: [0, 0, 0, 0],
            text: ``,
            color: ``,
            bold: false,
            preserveLeadingSpaces: true,
            alignment: `left`,
            pageBreak: ``,
            fontSize: 15,
            decorationStyle: ``,
            width: 10,
          },
          {
            decorationColor: ``,
            decoration: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            width: `auto`,
            bold: false,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            color: ``,
            text: `In case of emergency situation, health care provider will perform the standard emergency medical treatment except when the patient is diagnosed to be in the end stage of life condition as per medical standard and such emergency is a result from the previous diagnosed illness.`,
            fontSize: 15,
          },
        ],
      },
      {
        decorationStyle: ``,
        preserveLeadingSpaces: true,
        decoration: ``,
        fontSize: 15,
        width: `auto`,
        margin: [0, 0, 0, 0],
        color: ``,
        alignment: `left`,
        text: ` `,
        decorationColor: ``,
        bold: false,
        pageBreak: ``,
      },
      {
        columns: [
          {
            decorationStyle: ``,
            pageBreak: ``,
            color: ``,
            bold: false,
            width: `auto`,
            alignment: `left`,
            text: `(3)`,
            fontSize: 15,
            decoration: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            width: 10,
            alignment: `left`,
            decorationStyle: ``,
            color: ``,
            text: ``,
            fontSize: 15,
            decoration: ``,
            decorationColor: ``,
            bold: false,
            preserveLeadingSpaces: true,
          },
          {
            decorationColor: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            width: `auto`,
            fontSize: 15,
            text: `When the patient is in the end stage of life or to terminate suffering from the illness, health facility will proceed as follows :`,
            bold: false,
            color: ``,
            pageBreak: ``,
          },
        ],
      },
      {
        columns: [
          {
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            text: ``,
            color: ``,
            bold: false,
            decorationColor: ``,
            alignment: `left`,
            decoration: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            width: 25,
          },
          {
            decorationColor: ``,
            text: `(3.1)`,
            pageBreak: ``,
            width: `auto`,
            decorationStyle: ``,
            decoration: ``,
            bold: false,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            alignment: `left`,
            color: ``,
            fontSize: 15,
          },
          {
            text: ``,
            bold: false,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            decorationColor: ``,
            decorationStyle: ``,
            pageBreak: ``,
            width: 10,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decoration: ``,
            color: ``,
          },
          {
            width: `auto`,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            alignment: `left`,
            decorationColor: ``,
            decoration: ``,
            pageBreak: ``,
            color: ``,
            preserveLeadingSpaces: true,
            text: `In case that the patient is fully conscious and able to communicate normally, the Attending Physician will explain the condition and progression of illness to the patient and confirm that the patient wants to use/revoke the revoke the Living Will.`,
            fontSize: 15,
          },
        ],
      },
      {
        columns: [
          {
            text: ``,
            color: ``,
            fontSize: 15,
            decorationColor: ``,
            width: 25,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            bold: false,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            decoration: ``,
          },
          {
            decoration: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            color: ``,
            pageBreak: ``,
            text: `(3.2)`,
            decorationStyle: ``,
            decorationColor: ``,
            fontSize: 15,
            bold: false,
            margin: [0, 0, 0, 0],
            width: `auto`,
          },
          {
            color: ``,
            alignment: `left`,
            decorationColor: ``,
            text: ``,
            bold: false,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            pageBreak: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            width: 10,
          },
          {
            pageBreak: ``,
            color: ``,
            bold: false,
            preserveLeadingSpaces: true,
            text: `In case that the patient is not fully conscious and unable to communicate normally, the Attending Physician will discuss the patient's condition and progression of illness with the requestor of the patient's Living Will usage or family member. Details of the Living Will's proceeding will be explaned before the actual practice.`,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            width: `auto`,
            decoration: ``,
            decorationColor: ``,
            decorationStyle: ``,
            alignment: `left`,
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            width: 25,
            color: ``,
            decoration: ``,
            bold: false,
            alignment: `left`,
            fontSize: 15,
            decorationColor: ``,
            text: ``,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
          },
          {
            decorationStyle: ``,
            decoration: ``,
            width: `auto`,
            margin: [0, 0, 0, 0],
            bold: false,
            fontSize: 15,
            preserveLeadingSpaces: true,
            text: `(3.3)`,
            alignment: `left`,
            pageBreak: ``,
            color: ``,
            decorationColor: ``,
          },
          {
            pageBreak: ``,
            width: 10,
            bold: false,
            color: ``,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            decorationStyle: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decoration: ``,
            alignment: `left`,
            text: ``,
          },
          {
            decoration: ``,
            text: `In case there is any problem occurs during the Living Will's proceeding, the Attending Physician will discuss with the first witness or relatives of the patient considering mainly on the patient's wishes.`,
            width: `auto`,
            decorationColor: ``,
            pageBreak: ``,
            color: ``,
            bold: false,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            fontSize: 15,
            alignment: `left`,
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            width: 25,
            pageBreak: ``,
            alignment: `left`,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            text: ``,
            color: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            bold: false,
            fontSize: 15,
          },
          {
            decorationStyle: ``,
            decoration: ``,
            bold: false,
            width: `auto`,
            preserveLeadingSpaces: true,
            alignment: `left`,
            pageBreak: ``,
            fontSize: 15,
            decorationColor: ``,
            color: ``,
            text: `(3.4)`,
            margin: [0, 0, 0, 0],
          },
          {
            alignment: `left`,
            width: 10,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            bold: false,
            decorationStyle: ``,
            decorationColor: ``,
            text: ``,
            decoration: ``,
            color: ``,
            pageBreak: ``,
          },
          {
            margin: [0, 0, 0, 0],
            color: ``,
            text: `In case of pregnant patient, the Living Will's process can be started once the patient is no longer in the pregnancy status.`,
            bold: false,
            width: `auto`,
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationColor: ``,
            decorationStyle: ``,
            pageBreak: ``,
            alignment: `left`,
            fontSize: 15,
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            bold: false,
            margin: [0, 0, 0, 0],
            decoration: ``,
            alignment: `left`,
            decorationStyle: ``,
            text: ``,
            fontSize: 15,
            width: 20,
            color: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
          },
          {
            pageBreak: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            decoration: ``,
            color: ``,
            decorationColor: ``,
            alignment: `left`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            bold: false,
            width: `auto`,
            text: `Nevertheless, palliative care will be provided to the patient by health facility.`,
          },
        ],
      },
      {
        text: ` `,
        margin: [0, 0, 0, 0],
        width: `auto`,
        color: ``,
        preserveLeadingSpaces: true,
        bold: false,
        decoration: ``,
        fontSize: 15,
        decorationColor: ``,
        alignment: `left`,
        pageBreak: ``,
        decorationStyle: ``,
      },
      {
        columns: [
          {
            decoration: ``,
            decorationStyle: ``,
            pageBreak: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            bold: false,
            width: `auto`,
            text: `(4)`,
            color: ``,
            decorationColor: ``,
            fontSize: 15,
          },
          {
            preserveLeadingSpaces: true,
            width: 10,
            color: ``,
            bold: false,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            text: ``,
            decorationStyle: ``,
            alignment: `left`,
            decoration: ``,
            fontSize: 15,
            pageBreak: ``,
          },
          {
            fontSize: 15,
            width: `auto`,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            text: `The patient is able to revoke or change the Living Will by informing the patient's wishes to health facility. In case there are several versions of Living Will, the effective one is the latest version.`,
            decoration: ``,
            pageBreak: ``,
            bold: false,
            decorationColor: ``,
            alignment: `left`,
            color: ``,
          },
        ],
      },
    ],
    defaultStyle: {
      font: `THSarabunNew`,
    },
    pageSize: `A4`,
    pageOrientation: `portrait`,
    header: props.header,
    footer: props.footer,
  };
}
