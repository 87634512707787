import React, {
  useEffect,
  useState,
  CSSProperties,
  useRef,
  useMemo,
  useCallback,
  SyntheticEvent,
} from "react";
import { Icon, Modal, Pagination } from "semantic-ui-react";

import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";

// Common
import { blockListForAppointmentSummary } from "react-lib/apps/Scheduling/common/BlockList";
import PatientSearchBoxCU from "react-lib/apps/common/PatientSearchBoxCU";

// Ux
import CardAppointmentSummaryUX from "./CardAppointmentSummaryUX";
import ChartAppointmentSummary from "./ChartAppointmentSummary";
import CardAppointmentSummaryListUX from "./CardAppointmentSummaryListUX";
import CardConsultDetail from "./CardConsultDetail";
import CardConsultDateTime from "./CardConsultDateTime";
import CardAppointmentDetail from "./CardAppointmentDetail";
import ModEventDetail from "./ModEventDetail";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

// Interface
import {
  APPOINTMENT_STATUS_OPTIONS,
  APPOINTMENT_TYPE_OPTIONS,
  DOCTOR_CONSULT_ORDER,
  handleGetAppointmentType,
} from "./SchedulingInterface";

// Utils
import {
  adToBe,
  formatADtoBEString,
  formatDate,
} from "react-lib/utils/dateUtils";

import config from "config/config";
import { useIntl } from "react-intl";

console.log("initial moment locale en week dow 1")
moment.locale("en", { week: { dow: 1 } });
const localizer = momentLocalizer(moment);

const CARD_APPOINTMENT_SUMMARY = "CardAppointmentSummary";

const ACTION_DOWNLOAD = `${CARD_APPOINTMENT_SUMMARY}_DOWNLOAD`;
const ACTION_PRINT = `${CARD_APPOINTMENT_SUMMARY}_PRINT`;

const CardAppointmentSummary = (props: any) => {
  const intl = useIntl();
  const [appointmentList, setAppointmentList] = useState<any[]>([]);
  const [selectedDivision, setSelectedDivision] = useState<any>(null);
  const [openChart, setOpenChart] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState<any>(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  // const [startTime, setStartTime] = useState("");
  // const [endTime, setEndTime] = useState("");
  // const [appointmentType, setAppointmentType] = useState<any>(null);
  const [tab, setTab] = useState<string>("");
  const [showDescription, setShowDescription] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<any>(null);
  const [selectedAppointment, setSelectedAppointment] = useState<any>(null);

  const [estimatedAt, setEstimatedAt] = useState("");
  const [estimatedDuration, setEstimatedDuration] = useState("");

  const [openModOrder, setOpenModOrder] = useState<{
    id?: number;
    type?: string;
  } | null>(null);

  const [filter, setFilter] = useState<any>({
    appointmentType: null,
    appointmentStatus: null,
    orderstatus: null,
    startDate: adToBe(moment().format("DD/MM/YYYY")),
    endDate: adToBe(moment().format("DD/MM/YYYY")),
    serviceStatus: null,
    patient: null,
    doctorProvider: null,
    isUnspecifiedDoctor: false,
    patientId: null,
    patientHN: "",
    specialty: "",
    patientAppTypeWL: "",
  });

  const [blockListAndHoliday, setBlockListAndHoliday] = useState<any[]>([]);
  const [calendarYear, setCalendarYear] = useState<string[]>([
    moment().format("YYYY"),
  ]);
  const [showMod, setShowMod] = useState<boolean>(false);
  const patientRefSeach = useRef(null);

  // Memo Effect
  const isMatchAppointment = useMemo(() => {
    if (
      selectedAppointment?.id &&
      props.selectedAppointment?.id &&
      props.selectedAppointment.id === selectedAppointment.id
    ) {
      return true;
    }
    return false;
  }, [selectedAppointment, props.selectedAppointment]);

  useEffect(() => {
    props.onEvent({
      message: "GetMasterData",
      params: {
        masters: [
          ["specialty", {}],
          ["patientAppType", {}],
        ],
      },
    });

    props.onEvent({ message: "GetChairList", params: {} });

    props.onEvent({
      message: "SetScheduling",
      params: { action: "GetWaitingList" },
    });

    props.setProp("selectedAppointment", {});

    handleOnSearchAppointment();
  }, []);

  useEffect(() => {
    if (props.blockList?.length > 0) {
      let bl = blockListForAppointmentSummary(props.blockList);
      console.log("useEffect bl", bl);

      const holiday = (props.holiday?.items || []).flatMap((item: any) => {
        const [day, month, year] = item.date[0].split("/");
        return calendarYear.map((year: any) => {
          const date = `${year}-${month}-${day}`;
          return {
            start: new Date(`${date} 00:00`),
            end: new Date(`${date} 23:59`),
            title: item.detail,
            holiday: true,
          };
        });
      });

      console.log("useEffect holiday: ", holiday);
      setBlockListAndHoliday([...holiday, ...bl]);
    }
  }, [props.blockList, props.holiday]);

  useEffect(() => {
    props.onEvent({
      message: "FilterSchedule",
      params: {
        divisionId: props.selectedDivision?.id,
        initial: selectedDivision === null,
      },
    });
    setSelectedDivision(props.selectedDivision);
  }, [props.selectedDivision]);

  useEffect(() => {
    // filter only status === 1   Note: active(1), inactive(2), revise(3)
    let fAppointmentList = props.appointmentSummaryList?.filter(
      (item: any) => item.status === 1
    );
    // console.log("appointmentSummaryList: ", props.appointmentSummaryList)
    setAppointmentList(fAppointmentList);
  }, [props.appointmentSummaryList]);

  useEffect(() => {
    if (isMatchAppointment) {
      const data = props.appointmentSummaryList?.find(
        (item: any) => item.id === props.selectedAppointment?.id
      );

      setShowDescription(!!data);
    }
  }, [
    props.selectedAppointment,
    props.appointmentSummaryList,
    isMatchAppointment,
  ]);

  const getAppointmentForChair = (list: any[], providers: any[]) => {
    // console.log('getAppointmentForChair: list', list);
    // console.log('getAppointmentForChair: providers', providers);
    // console.log('getAppointmentForChair: props.chairList', props.chairList);
    let ret = list.map((item: any) => ({
      ...item,
      providers: providers
        .filter((item: any) => item.dsb.appointments.length)
        .filter(
          (provider: any) =>
            provider.dsb.doctor_provider_name === item.provider &&
            provider.dsb.appointments.find((acc: any) => acc.id === item.id)
        )
        .map((provider: any) => provider.dsb)
        .map((provider: any) => ({
          ...provider,
          chairName: props.chairList.find(
            (el: any) =>
              el.provider && el.provider === provider?.chair_provider_id
          )?.name,
        })),
    }));
    // console.log('getAppointmentForChair ret: ', ret);
    return ret;
  };

  const mapProviderOptions = useCallback((items: any) => {
    // console.log("mapProviderOptions items", items)
    return items.map((item: any) => ({
      key: item.provider_id,
      value: item.first_name + " " + item.last_name + " [" + item.code + "]",
      text: item.first_name + " " + item.last_name + " [" + item.code + "]",
    }));
  }, []);

  const handleSelectEvent = (e: any) => {
    console.log(e);

    setSelectedEvent(e);
    setAppointmentList(e.appointmentList);
  };

  // #const handlePrint = (e: any) => {
  //   const appointments = getAppointmentForChair(
  //     appointmentList || [],
  //     selectedEvent
  //       ? selectedEvent.providers
  //       : (props.blockList || []).flatMap((acc: any) => acc.providers)
  //   );
  //   // console.log('handlePrint selectedEvent: ', selectedEvent, props.blockList);
  //   // console.log('handlePrint appointmentList: ', appointmentList);
  //   // console.log('handlePrint appointments: ', appointments);
  //   // console.log(" handlePrint props.data?.divisionDict" , props?.divisionDict)
  //   const orders = appointments.map((item: any) => {
  //     // console.log("CardAppointmentSummary item" , item )
  //     return {
  //       ...item,
  //       ...(item?.original || {}),
  //       patient_hn: item.patient_hn || item.original?.hn,
  //       patient: item.patient_first_name
  //         ? item.patient_pre_name
  //           ? `${item.patient_pre_name} ${item.patient_first_name} ${item.patient_last_name}`
  //           : `${item.patient_first_name} ${item.patient_last_name}`
  //         : item.patient,
  //       patient_tel: item.patient_tel || item.original?.tel,
  //       patient_id: item.patient_id || item.original?.id,
  //       division_name:
  //         item.original?.appointment_div_name || selectedEvent?.division_name,
  //       provider_name: item.display_info?.provider_name || item.provider,
  //       chair: props.chairList.find(
  //         (el: any) => el.provider && el.provider === item.chair_provider_id
  //       )?.name,
  //       zone:
  //         item?.chair_zone_id === null
  //           ? "ว่าง"
  //           : props.divisionDict?.[item?.chair_zone_id]?.name,
  //       estimated_at: item.estimated_at
  //         ? item.estimated_at
  //         : `${formatDate(moment(item.original.estimated_at))} [${moment(
  //             item.original.estimated_at
  //           ).format("HH:mm")}]`,
  //     };
  //   });

  //   const provider = Array.from(
  //     new Set(orders.map((item) => item.provider_name))
  //   );
  //   const fromDate = startDate.includes("-")
  //     ? startDate.replace(/-/g, "/")
  //     : startDate;
  //   const toDate = endDate.includes("-") ? endDate.replace(/-/g, "/") : endDate;
  //   // console.log('SORT fromDate: ', fromDate);
  //   // console.log('SORT toDate: ', toDate);

  //   // console.log('orders: ', orders);

  //   props.onEvent({
  //     message: "PrintScheduling",
  //     params: {
  //       form: "FormAppointmentSummary",
  //       data: {
  //         is_search: !selectedEvent,
  //         division_name: props.selectedDivision.name_code,
  //         provider_name: !selectedEvent
  //           ? `${selectedDoctor?.first_name || ""} ${
  //               selectedDoctor?.last_name || ""
  //             }`
  //           : provider.length === 1
  //           ? provider[0]
  //           : "",
  //         date_range: `${
  //           selectedEvent ? formatDate(moment(selectedEvent?.start)) : fromDate
  //         } - ${
  //           selectedEvent ? formatDate(moment(selectedEvent?.end)) : toDate
  //         }`,
  //         orders,
  //       },
  //     },
  //   });
  // };

  const IMAGES = useMemo(() => {
    return {
      appointment_onsite: "/static/images/order_images/onsite_outline.png",
      appointment_telemed: "/static/images/order_images/telemed_outline.png",
      or_outline: "/static/images/order_images/or_outline.png",
      waiting_list_outline: "/static/images/order_images/onsite_outline.png",
      package_outline: "/static/images/order_images/package_outline.png",
    };
  }, []);

  const patientAppTypeWLOptions = useMemo(() => {
    const patientAppType = (props.patientAppType || []).map((option: any) => ({
      ...option,
      type: "type",
    }));

    const waitingList = (props.waitingList || []).map((item: any) => {
      const value = `${item.id}-w`;
      return {
        key: value,
        text: item.name,
        value: value,
        type: "waiting_list_id",
      };
    });

    return [...patientAppType, ...waitingList];
  }, [props.patientAppType]);

  const handleSetTab = (appType: string) => {
    const targetTab =
      appType === "consult"
        ? "consultDetailUX"
        : appType === "or"
        ? "orRequestUX"
        : appType === "package"
        ? "packageDetailUX"
        : "generalAppDetailUX";
    setTab(targetTab);
  };

  const handleOnChangeTab = (name: string) => () => {
    setTab(name || "");
  };

  const handleOnRangeChange = (range: any, view: any) => {
    const year = Array.isArray(range)
      ? [range[0], range.slice(-1)[0]]
      : [range.start, range.end];
    const uniqueYear = Array.from(
      new Set([moment(year[0]).format("YYYY"), moment(year[1]).format("YYYY")])
    );
    if (uniqueYear.toString() !== calendarYear.toString()) {
      setCalendarYear(uniqueYear);
    }
  };

  const handleGetIcon = (item: any) => {
    const type = handleGetAppointmentType(item);
    let status =
      item.status === 1 && item.is_message_sent ? "1.5" : item.status;

    const getIconColor: any = {
      "1": "#949494",
      "1.5": "#1F77B4",
      "2": "#2ead64",
      "5": "#d80000",
      "6": "#d80000",
      "8": "#2ead64",
    };

    const color = getIconColor?.[status] || "#949494";

    const iconType: any = {
      consult: { icon: "user md", color },
      or: { image: IMAGES.or_outline, color },
      package: { image: IMAGES.package_outline, color },
      waiting_list: { image: IMAGES.waiting_list_outline, color },
      appointment_onsite: { image: IMAGES.appointment_onsite, color },
      appointment_telemed: { image: IMAGES.appointment_telemed, color },
    }[type];

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "100%",
        }}
      >
        <div
          style={{
            width: "32px",
            height: "32px",
            backgroundColor: "white",
            borderRadius: "500rem",
            display: "flex",
          }}
        >
          <div
            style={{
              width: "30px",
              height: "30px",
              backgroundColor: iconType.color,
              borderRadius: "500rem",
              margin: "auto",
              padding: "6px",
            }}
          >
            {iconType.image ? (
              <img
                src={iconType.image}
                alt={`icon-${item.id}.icon`}
                style={{ width: "100%" }}
              />
            ) : (
              <Icon
                name={iconType.icon}
                // alt={`icon-${index}.icon`}
                style={{
                  width: "100%",
                  color: "white",
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  const getStatusNote = (data: any) => {
    let note = "";
    try {
      note = JSON.parse(data.status_note).join(", ");
    } catch (error) {
      note = data.status_note;
    }
    return note && `(${note})`;
  };

  const orderStatusOptions = useMemo(() => {
    return [
      { key: "ไม่มาตามนัด", text: "ไม่มาตามนัด", value: "ไม่มาตามนัด" },
      { key: "มาไม่ตรงนัด", text: "มาไม่ตรงนัด", value: "มาไม่ตรงนัด" },
      { key: "มาตรงนัดหมาย", text: "มาตรงนัดหมาย", value: "มาตรงนัดหมาย" },
    ];
  }, []);

  const appointmentData = useMemo(() => {
    return (props.appointmentSummaryList || [])
      .filter((item: any) =>
        filter.orderStatus ? item.service_status_text === filter.orderStatus : item
      )
      .map((item: any) => {
        return {
          ...item,
          _icon: handleGetIcon(item),
          hn: item.patient_hn,
          patient_name: item.patient_name_text,
          tel: item.tel_text,
          doctor_name:
            item.doctor_name_text === "ยังไม่ระบุ" ? (
              <span style={{ color: "#d80000" }}>{intl.formatMessage({ id: "ยังไม่ระบุ" })}</span>
            ) : (
              item.doctor_name_text
            ),
          appointment_status: (
            <div>
              {item.appointment_status_text}
              <div>{item.appointment_status_note}</div>
            </div>
          ),
          service_date: <div>{item.service_date_text}</div>,
          service_status: <div>{item.service_status_text}</div>,
          viewType: handleGetAppointmentType(item),
          appointment_for_text: item.appointment_for_text === "null" ? "" : item.appointment_for_text
        };
      });
  }, [props.appointmentSummaryList]);

  const doctorOptions = useMemo(() => {
    return (props.blockList || [])
      .map((item: any) => item.providers.map((p: any) => p.dsb))
      .flat(2)
      .filter((item: any) => item.doctor_provider_type === "Doctor")
      .reduce((result: any, item: any) => {
        if (
          result &&
          !result?.find(
            (i: any) => i.doctor_provider_id === item.doctor_provider_id
          )
        ) {
          result.push(item);
        }
        if (!result && item?.doctor_provider_id) {
          return [item];
        }
        return result;
      }, [])
      ?.map((item: any) => ({
        key: item.doctor_provider_id,
        text: item.doctor_provider_name,
        value: item.doctor_provider_id,
      }));
  }, [props.blockList]);

  const urlParamsFilter = useMemo(() => {
    const patientAppTypeWL =
      patientAppTypeWLOptions.find(
        (option) => option.value === filter.patientAppTypeWL
      ) || {};

    const value = patientAppTypeWL.value
      ? parseInt(patientAppTypeWL.value)
      : undefined;

    return { ...filter, [patientAppTypeWL.type || ""]: value };
  }, [patientAppTypeWLOptions, filter]);

  const handleOnFilterChange =
    (name: string) => (e: SyntheticEvent, v: any) => {
      if (name === "isUnspecifiedDoctor") {
        setFilter({ ...filter, [name]: v.checked });
      } else {
        setFilter({ ...filter, [name]: v.value });
      }
    };

  const handleOnFilterDateChange = (name: string) => (date: string) => {
    setFilter({ ...filter, [name]: date });
  };

  const handlePageChange = useCallback(
    (_e: any, data: any) => {
      handleOnSearchAppointment({ activePage: data.activePage });
    },
    [props.appointmentSummaryPage]
  );

  const handleOnSelectPatient = (id: any, hn: any) => {
    setFilter({ ...filter, patientId: id, patientHN: hn });
  };

  const handleOnSearchAppointment = (params: any = null) => {
    props.onEvent({
      message: "SetScheduling",
      params: {
        action: "FilterByAppointmentSummary",
        ...urlParamsFilter,
        ...(params ? { ...params } : {}),
      },
    });
  };

  const handleSelectAppointment = (
    selectApp: any,
    isSelectRow: boolean = false
  ) => {
    handleSetTab(selectApp?.viewType);
    // if(isSelectRow){
    //   setSelectedAppointment(selectApp)
    // }
    props.onEvent({
      message: "SelectAppointment",
      params: {
        ...selectApp,
        ...(isSelectRow
          ? { onFinished: () => setSelectedAppointment(selectApp) }
          : {}),
      },
    });

    setEstimatedAt("");
    setEstimatedDuration("");
  };

  const selectedConsultId = useMemo(() => {
    return (
      props.selectedAppointment?.orders?.find(
        (item: any) => item.type === DOCTOR_CONSULT_ORDER
      )?.id || null
    );
  }, [props.selectedAppointment]);

  const handleDownloadFile = () => {
    props.onEvent({
      message: "HandleDownloadAppointmentSummary",
      params: {
        ...urlParamsFilter,
        doctorName:
          doctorOptions.find(
            (option: any) => option.value === filter.providerDoctor
          )?.text || "",
        buttonLoadKey: ACTION_DOWNLOAD,
      },
    });
  };

  const handlePrintAppointmentList = () => {
    props.onEvent({
      message: "HandlePrintAppointmentSummaryList",
      params: {
        ...urlParamsFilter,
        buttonLoadKey: ACTION_PRINT,
      },
    });
  };

  console.log("CardAppointmentSummary", props);
  // console.log("props.blockList", blockListForAppointmentSummary(props.blockList));

  return (
    <div style={{ margin: "10px 0px 0px 0px" }}>
      {!isMatchAppointment ? (
        <CardAppointmentSummaryListUX
          // controller
          proxyController={props.proxyController}
          // data
          disableShowDetail={!selectedAppointment?.id}
          appointmentData={appointmentData}
          appointmentType={filter.appointmentType}
          appointmentStatus={filter.appointmentStatus}
          startDate={filter.startDate}
          endDate={filter.endDate}
          orderStatus={filter.orderStatus}
          providerDoctor={filter.providerDoctor}
          specialty={filter.specialty}
          isUnspecifiedDoctor={filter.isUnspecifiedDoctor}
          patientAppTypeWL={filter.patientAppTypeWL}
          loadingFilterAppList={props.loadingFilterAppList}
          // options
          appointmentTypeOptions={APPOINTMENT_TYPE_OPTIONS}
          appointmentStatusOptions={APPOINTMENT_STATUS_OPTIONS}
          orderStatusOptions={orderStatusOptions}
          providerDoctorOptions={doctorOptions}
          specialtyOptions={props.masterOptions?.specialty}
          patientAppTypeWLOptions={patientAppTypeWLOptions}
          // function
          onChange={handleOnFilterChange}
          onDateChange={handleOnFilterDateChange}
          onSearch={(e: any, data: any) => {
            handleOnSearchAppointment();
          }}
          onClickShowDetail={() => {
            handleSelectAppointment(selectedAppointment);
          }}
          onPrintAppointmentList={handlePrintAppointmentList}
          onGetPatientRow={(state: any, row: any, column: any, instance: any) => {
            return {
              style: {
                backgroundColor:
                  row?.original?.id && row?.original?.id === selectedAppointment?.id
                    ? "#cccccc"
                    : "white",
              },
              onClick: () => {
                setSelectedAppointment(row?.original);
              },
              onDoubleClick: () => {
                handleSelectAppointment(row?.original);
              },
            };
          }}
          // component
          searchPatient={
            <PatientSearchBoxCU
              ref={patientRefSeach}
              controller={props.proxyController}
              inputChangeAutoSearch={false}
              onSelectPatient={handleOnSelectPatient}
              onEnter={handleOnSelectPatient}
              systemHn8Digit={config.COMPANY === "CU"}
              userProfile={props.django?.user}
              languageUX={props.languageUX}
            />
          }
          pagination={
            <Pagination
              // className="StockManagement-pagination"
              activePage={props.appointmentSummaryPage?.activePage}
              ellipsisItem={{
                content: <Icon name="ellipsis horizontal" />,
                icon: true,
              }}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
              totalPages={props.appointmentSummaryPage?.total}
              size="mini"
              // callback
              onPageChange={handlePageChange}
            />
          }
          buttonPrintReport={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={handlePrintAppointmentList}
              // data
              paramKey={ACTION_PRINT}
              buttonLoadCheck={props.buttonLoadCheck?.[ACTION_PRINT]}
              // config
              color={"orange"}
              name="PRINT"
              size="medium"
              title={intl.formatMessage({ id: "พิมพ์" })}
            />
          }
          buttonDownloadFile={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={handleDownloadFile}
              // data
              paramKey={ACTION_DOWNLOAD}
              buttonLoadCheck={props.buttonLoadCheck?.[ACTION_DOWNLOAD]}
              // config
              color={"green"}
              name="DOWNLOAD"
              size="medium"
              title={intl.formatMessage({ id: "ดาวน์โหลด .xlsx" })}
            />
          }
          languageUX={props.languageUX}
        />
      ) : (
        <CardAppointmentSummaryUX
          // controller
          proxyController={props.proxyController}
          // data
          disableShowDetail={!selectedAppointment?.id}
          appointmentData={appointmentData}
          appointmentType={filter.appointmentType}
          appointmentStatus={filter.appointmentStatus}
          startDate={filter.startDate}
          endDate={filter.endDate}
          orderStatus={filter.orderStatus}
          providerDoctor={filter.providerDoctor}
          isUnspecifiedDoctor={filter.isUnspecifiedDoctor}
          disabledBtnAppointment={!showDescription}
          disabledBtnSelectDatetime={!showDescription}
          // options
          appointmentTypeOptions={APPOINTMENT_TYPE_OPTIONS}
          appointmentStatusOptions={APPOINTMENT_STATUS_OPTIONS}
          orderStatusOptions={orderStatusOptions}
          providerDoctorOptions={doctorOptions}
          // function
          onChange={handleOnFilterChange}
          onDateChange={handleOnFilterDateChange}
          onSearch={(e: any, data: any) => {
            handleOnSearchAppointment();
          }}
          onHideDetail={() => {
            setSelectedAppointment("");
          }}
          onGetPatientRow={(state: any, row: any, column: any, instance: any) => {
            return {
              style: {
                backgroundColor:
                  row?.original?.id && row?.original?.id === selectedAppointment?.id
                    ? "#cccccc"
                    : "white",
              },
              onClick: () => {
                handleSelectAppointment(row?.original, true);
              },
            };
          }}
          // component
          searchPatient={
            <PatientSearchBoxCU
              ref={patientRefSeach}
              controller={props.proxyController}
              inputChangeAutoSearch={false}
              onSelectPatient={handleOnSelectPatient}
              onEnter={handleOnSelectPatient}
              systemHn8Digit={config.COMPANY === "CU"}
              userProfile={props.django?.user}
              languageUX={props.languageUX}
            />
          }
          pagination={
            <Pagination
              // className="StockManagement-pagination"
              activePage={props.appointmentSummaryPage?.activePage}
              ellipsisItem={{
                content: <Icon name="ellipsis horizontal" />,
                icon: true,
              }}
              firstItem={{
                content: <Icon name="angle double left" />,
                icon: true,
              }}
              lastItem={{
                content: <Icon name="angle double right" />,
                icon: true,
              }}
              prevItem={{ content: <Icon name="angle left" />, icon: true }}
              nextItem={{ content: <Icon name="angle right" />, icon: true }}
              totalPages={props.appointmentSummaryPage?.total}
              size="mini"
              // callback
              onPageChange={handlePageChange}
            />
          }
          localizer={localizer}
          blockList={blockListAndHoliday}
          appointmentList={appointmentData || []}
          onSelectEvent={handleSelectEvent}
          // print={handlePrint}
          showChart={(e: any) => setOpenChart(true)}
          loadingFilterAppList={props.loadingFilterAppList}
          loadingPrintAppList={props.loadingPrintAppList}
          eventPropGetter={(event: any, start: Date, end: Date, isSelected: boolean) => {
            // เลือกแพทย์ ไม่มี dsb
            // console.log("props.selectedDivision?.id", props.selectedDivision?.id)
            // console.log("event", event);
            return {
              style: event?.holiday
                ? {
                    backgroundColor: "#FFE696",
                    color: "black",
                    textAlign: "center",
                  }
                : ({} as CSSProperties),
            };
          }}
          onRangeChange={handleOnRangeChange}
          // Page view
          tab={tab}
          onChangeTab={handleOnChangeTab}
          // general view
          generalAppDetailUX={
            showDescription ? (
              <CardAppointmentDetail
                setProp={props.setProp}
                onEvent={props.onEvent}
                // controller
                proxyController={props.proxyController}
                // seq
                runSequence={props.runSequence}
                // data
                reservedText={intl.formatMessage({ id: "(มีนัดหมายแล้ว)" })}
                estimatedAt={estimatedAt}
                estimatedDuration={estimatedDuration}
                card={"CARD_APPOINTMENT_DETAIL"}
                availableSlots={props.availableSlots}
                blockList={props.blockList}
                preOrderList={props.preOrderList}
                selectedBlock={props.selectedBlock}
                openModOrder={openModOrder}
                duplicateAppointment={props.duplicateAppointment}
                reoccureDuplicateAppointment={props.reoccureDuplicateAppointment}
                // treatment order
                OrderTreatmentSequence={props.OrderTreatmentSequence}
                procedureItems={props.procedureItems}
                secondaryItems={props.secondaryItems}
                treatmentTemplateList={props.treatmentTemplateList}
                treatmentOrderList={props.treatmentOrderList}
                // imaging order
                ImagingOrderSequence={props.ImagingOrderSequence}
                modXrayDetail={props.modXrayDetail}
                clinicalFindingId={props.clinicalFindingId}
                clinicalFindingIndex={props.clinicalFindingIndex}
                clinicalFindingList={props.clinicalFindingList}
                organ={props.organ}
                clinicaltags={props.clinicaltags}
                filterClinicalFindingIds={props.filterClinicalFindingIds}
                // lab order
                selectedEncounter={props.selectedEncounter}
                selectedEmr={props.selectedEmr}
                selectedProgressCycle={props.selectedProgressCycle}
                OrderCentralLabSequence={props.OrderCentralLabSequence}
                userLabPermission={props.userLabPermission}
                doctorLabOrderList={props.doctorLabOrderList}
                // CommonInterface
                userTokenize={props.userTokenize}
                selectedAppointment={props.selectedAppointment}
                selectedPatient={props.selectedPatient}
                loadingStatus={props.loadingStatus}
                buttonLoadCheck={props.buttonLoadCheck}
                errorMessage={props.errorMessage}
                successMessage={props.successMessage}
                searchedItemList={props.searchedItemList}
                searchedItemListWithKey={props.searchedItemListWithKey}
                // options
                masterOptions={props.masterOptions}
                // cancelOptions={APPOINTMENT_CANCEL_OPTIONS}
                // postponeOptions={APPOINTMENT_POSTPONE_OPTIONS}
                // config
                showOrderAppointment={props.showOrderAppointment}
                hideDrugAllergy={props.hideDrugAllergy}
                isAppointment={true}
                showEmergencyContact={true}
                isEditable={true}
                // callback
                setTab={setTab}
                setEstimatedAt={setEstimatedAt}
                setEstimatedDuration={setEstimatedDuration}
                setOpenModOrder={setOpenModOrder}
                languageUX={props.languageUX}
              />
            ) : (
              <span style={{ paddingLeft: "0.75rem" }}>
                กรุณาเลือกนัดหมายคนไข้ที่ต้องการทำรายการ
              </span>
            )
          }
          generalAppDateTimeUX={
            <Calendar
              endAccessor="end"
              eventPropGetter={props.eventPropGetter}
              events={props.blockList || []}
              localizer={localizer}
              min={new Date(1972, 0, 1, 8, 0, 0)}
              max={new Date(1972, 0, 1, 20, 0, 0)}
              onRangeChange={handleOnRangeChange}
              onSelectEvent={(e: any) => {
                // console.log("onSelectEvent e: ", e);
                if (props.selectedAppointment) {
                  setSelectedEvent(e);
                  console.log(e);
                  setShowMod(true);
                }
              }}
              startAccessor="start"
              style={{ width: "100%", height: "100%" }}
              tooltipAccessor="tooltip"
              views={["month", "week"]}
            ></Calendar>
          }
          // consult View
          // 5 AS CANCEL APPOINTMENT
          disabledConsultDateTimeTab={
            !props.selectedAppointment?.status || props.selectedAppointment?.status === 5
          }
          consultDetailUX={
            <CardConsultDetail
              // data
              emrId={null}
              refreshId={props.selectedAppointment?.id}
              filter={filter}
              selectedAppointment={props.selectedAppointment}
              selectedConsultId={selectedConsultId} // Todo
              consultDetail={props.consultDetail}
              consultData={props.consultData}
              // CommonInterface
              errorMessage={props.errorMessage}
              successMessage={props.successMessage}
              buttonLoadCheck={props.buttonLoadCheck}
              // function
              onEvent={props.onEvent}
              setProp={props.setProp}
              mapProviderOptions={mapProviderOptions}
              onSelectDateTime={() => {
                setTab("consultDateTimeUX");
              }}
              onRefreshAppointment={handleOnSearchAppointment}
              onConfirmAppointmentSuccess={() => {}}
              // options
              masterOptions={props.masterOptions}
              divisionList={props.divisionList}
              config={{
                isSource: true,
                sourceReadOnly: true,
                isDestination: true,
                destinationReadOnly: true,
                hideEditSourceNote: true,
                hideDestinationNote: true,
                hideEditDestinationNote: true,
                hideActionBar: false,
                hideConfirmButton:
                  props.selectedAppointment?.status !== 1 ||
                  !props?.consultDetail?.destinationProviderDoctor,
                hideEditDateTimeButton: false,
                hideSaveButton: false,
                hideEditButton: false,
                hideCancelButton: false,
                hideCloseIcon: true,
                hideConsultStatus: true,
                showViewListIcon: true,
                showSelectDoctorButton: props.selectedAppointment?.status === 1,
                hideResponseButton: true,
                hideFinishButton: true,
                hideReverseFinishButton: false,
              }}
              languageUX={props.languageUX}
            />
          }
          consultDateTimeUX={
            <CardConsultDateTime
              // data
              filter={filter}
              selectedPatient={props.selectedPatient}
              selectedAppointment={props.selectedAppointment}
              consultDetail={props.consultDetail}
              consultData={props.consultData}
              buttonLoadCheck={props.buttonLoadCheck}
              duplicateAppointment={props.duplicateAppointment}
              reoccureDuplicateAppointment={props.reoccureDuplicateAppointment}
              userTokenize={props.userTokenize}
              // function
              onRefreshAppointment={handleOnSearchAppointment}
              onEvent={props.onEvent}
              setProp={props.setProp}
              mapProviderOptions={mapProviderOptions}
              backToDetailTab={() => {
                setTab("consultDetailUX");
              }}
              // options
              divisionList={props.divisionList}
              languageUX={props.languageUX}
            />
          }
          languageUX={props.languageUX}
        />
      )}
      <Modal open={openChart} onClose={() => setOpenChart(false)} closeOnDimmerClick={true}>
        <ChartAppointmentSummary onEvent={props.onEvent} />
      </Modal>

      <Modal size="fullscreen" open={showMod} closeOnDimmerClick={true} onClose={(e: any) => setShowMod(false)}>
        <ModEventDetail
          onEvent={(params) => {
            if (params.message !== "AssignAppointmentToBlock") {
              return;
            }

            props.onEvent({
              message: "AssignAppointmentToBlock",
              params: params.params,
            });
            console.log(
              "params.params.doctor_dsb_slot_length:",
              params.params.doctor_dsb_slot_length
            );
            setShowMod(false);
            setTab("generalAppDetailUX");
            setEstimatedAt("");
            setEstimatedDuration(params.params.doctor_dsb_slot_length);
          }}
          chairList={props.chairList}
          divisionDict={props.allDivisionDict}
          providers={selectedEvent?.providers}
          selectedAppointment={props.selectedAppointment}
          selectedBlock={props.selectedBlock}
          title={`วันที่เลือก: ${formatADtoBEString(selectedEvent?.date)}, ${selectedEvent?.title}`}
          setEstimatedAt={setEstimatedAt}
          setEstimatedDuration={setEstimatedDuration}
          setShowMod={setShowMod}
          setTab={setTab}
        />
      </Modal>
    </div>
  );
};

export default CardAppointmentSummary;
