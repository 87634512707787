import React from "react";
import { Divider, Header, Image, Label, Segment } from "semantic-ui-react";
import personImg from "../../common/person.png";
import moment from "moment";
import * as Util from "../../../utils/tsUtils";
import { useIntl } from "react-intl";
// import chatStyle from "../../../../css/chatStyle.js";
// import "../css/ChatStyle.css";

interface ChatListRowProps {
  chat: any;
  onSelect: (id: number) => void;
  className: string;
  triageDes: string;
  triageColor: string;
  hideReclassify: boolean;
  isListChatSupport?: boolean;
}

const ChatListRow = (props: ChatListRowProps) => {
  const intl = useIntl();
  if (!props.chat) {
    return <></>;
  }

  let appDate = "";
  let momentStartDate = null;
  if (props.chat && props.chat.appointment_start_at) {
    let momentAppDate = moment(props.chat.appointment_start_at);
    let estimateService = moment(props.chat.estimate_service_at);
    let thaiDate = Util.convertToThaiDate({
      date: momentAppDate,
      display: "localeDate",
    });
    let localTime = estimateService.isValid() ? ` ${estimateService.format("HH:mm")} น.` : "";
    appDate = thaiDate || localTime ? `${thaiDate}${localTime}` : "-";
  }

  if (props.chat.started) {
    momentStartDate = moment(props.chat.started);
  }

  // appointment_start_at
  return (
    <Segment style={{ padding: 0, margin: "2%" }}>
      <div
        id={`chat_list_row${props.chat.id}`}
        className={"row " + `${props.isListChatSupport && "support "}` + props.className}
        onClick={() => props.onSelect(props.chat.id)}
        style={{ display: "flex", flexDirection: "column", cursor: "pointer" }}
      >
        <div
          style={{ display: "flex", margin: "10px 0 0 10px", alignItems: "center", gap: "10px" }}
        >
          <Image src={props.chat.image ? props.chat.image : personImg} circular size="mini" />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div style={{ fontWeight: "bold", fontSize: "1.1em" }}>{props.chat.patient_name} </div>
            {props.chat.unread_message_count > 0 ? (
              <Label circular color="red">
                {Number(props.chat.unread_message_count) > 100 ?  "99+" : props.chat.unread_message_count}
              </Label>
            ) : null}
          </div>
        </div>
        <Divider />
        <div className="detail" style={{ width: "100%" }}>
          <div className={`subDetail ${props.isListChatSupport && "support"}`}>
            <div>
              <span style={{ fontWeight: "bold" }}>{intl.formatMessage({ id: "อายุ:" })} </span>
              {props.chat.patient_age}
            </div>
            <div>
              <span style={{ fontWeight: "bold" }}>hn: </span>
              {props.chat.hn}
            </div>
            {momentStartDate ? (
              <div>
                <span style={{ fontWeight: "bold" }}>{intl.formatMessage({ id: "เข้ารับบริการ:" })} </span>
                {`${Util.convertToBEDate({
                  date: momentStartDate.format("DD/MM/YYYY"),
                })} ${momentStartDate.format("HH:mm")}`}
              </div>
            ) : null}
            {props.chat.appointment_doctor && (
              <>
                <div className="app-doc-name">
                  <span style={{ fontWeight: "bold" }}>{intl.formatMessage({ id: "นัดหมาย:" })} </span>
                  {props.chat.appointment_doctor
                    ? `${props.chat.appointment_doctor_full_name}`
                    : "-"}
                </div>
                <div>
                  <span style={{ fontWeight: "bold" }}>{intl.formatMessage({ id: "วันที่/เวลาโดยประมาณ:" })} </span>
                  {`${appDate}`}
                </div>
              </>
            )}
            {!props.hideReclassify && (
              <div style={{ color: props.triageColor }}>
                <span style={{ fontWeight: "bold" }}>Classify: </span>
                {props.triageDes}
              </div>
            )}
          </div>
          {/* <div>{props.chat.latest_message_text}</div> */}
        </div>
        {/* <div style={chatStyle.chatListTime}>{props.chat.latest_message_at}</div> */}
        <div className="time" style={{ alignSelf: "center" }}></div>
      </div>
    </Segment>
  );
};

ChatListRow.defaultProps = {
  chat: {},
  onSelect: () => {},
  className: "",
  triageDes: "",
  triageColor: "black",
  hideReclassify: false,
};

export default React.memo(ChatListRow);
