import React, { CSSProperties, ChangeEvent, useCallback, useEffect, useMemo } from "react";

// UX
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import ModLockExpense from "react-lib/apps/HISV3/BIL/ModLockExpense";
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";
import SnackMessage from "react-lib/apps/common/SnackMessage";

import CardSearchPackageUX from "./CardSearchPackageUX";
import LoadingIcon from "./LoadingIcon";
import ModPackageDescription from "./ModPackageDescription";

// Common

// Interface
import {
  BUTTON_ACTIONS,
  FilterPackageType,
  MasterOptionsType,
  RunSequence,
  State,
} from "./sequence/PackagePurchase";
import { GENDER_OPTIONS, PackageDetailType } from "./sequence/SettingPackage";
import { useIntl } from "react-intl";

// Types
type CardSearchPackageProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: () => any) => any;
  // seq
  runSequence: RunSequence;
  PackagePurchaseSequence?: State["PackagePurchaseSequence"];
  buttonLoadCheck?: Record<string, any>;
  errorMessage?: Record<string, any>;
  // CommonInterface
  searchedItemListWithKey?: Record<string, any>;
  successMessage?: Record<string, any>;
  // options
  masterOptions?: MasterOptionsType;
};

const GridCenter = { display: "grid", placeContent: "center" } as CSSProperties;

const CARD_SEARCH_PACKAGE = "CardSearchPackage";

const CardSearchPackage = (props: CardSearchPackageProps) => {
  const intl = useIntl();
  // Use Effect
  useEffect(() => {
    // use action SearchPackage
    props.runSequence({
      sequence: "PackagePurchase",
      card: CARD_SEARCH_PACKAGE,
      nextIndex: "SearchPackage",
      restart: true,
    });

    return () => {
      handleCloseMessage();
    };
  }, []);

  // Memo callback
  const filterPackage = useMemo(
    () => props.PackagePurchaseSequence?.filterPackage || {},
    [props.PackagePurchaseSequence?.filterPackage]
  );

  // Use Callback
  const mapPackageOptions = useCallback(
    (items: any[]) =>
      items.map((item: any) => ({
        key: item.id,
        text: `[${item.code}] ${item.name}`,
        value: item.id,
      })),
    []
  );

  const handleSelectedItem = useCallback(
    async (value: any) => {
      props.setProp("PackagePurchaseSequence.filterPackage", {
        ...filterPackage,
        programId: value || null,
      });
    },
    [filterPackage, props.searchedItemListWithKey]
  );

  const handleBuy = useCallback(
    (id?: number) => async () => {
      if (!id) {
        return;
      }

      const isLocked = await props.onEvent({ message: "CheckEncounterLocker" });

      if (isLocked) {
        return;
      }

      props.runSequence({
        sequence: "PackagePurchase",
        action: "PURCHASE",
        card: CARD_SEARCH_PACKAGE,
        packageId: id,
      });
    },
    []
  );

  const handleOpenInfo = useCallback(
    (data?: PackageDetailType) => () => {
      if (data) {
        props.runSequence({
          sequence: "PackagePurchase",
          action: "DESCRIPTION",
          card: CARD_SEARCH_PACKAGE,
          data,
        });
      }
    },
    []
  );

  const handleInputBlur = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name, valueAsNumber } = e.target;

      if (!["ageEnd", "ageStart"].includes(name)) {
        return;
      }

      const detail = props.PackagePurchaseSequence?.filterPackage || {};
      const start = detail.ageStart || "";
      const end = detail.ageEnd || "";

      // * หากระบุ age start มากกว่า year end หรือ age end น้อยกว่า age start ให้แสดงข้อความแจ้งเตือน
      const isError =
        (name === "ageStart" && valueAsNumber > Number(end) && end !== "") ||
        (name === "ageEnd" && valueAsNumber < Number(start) && start !== "");

      props.setProp("PackagePurchaseSequence.filterPackage.ageRangeError", isError);
    },
    [props.PackagePurchaseSequence?.filterPackage]
  );

  const handleInputRef = useCallback(
    (ref: any) => {
      if (ref) {
        const input = ref.inputRef.current;

        input.onblur = handleInputBlur;
      }
    },
    [props.PackagePurchaseSequence?.filterPackage]
  );

  // Use Memo
  const packageItems = useMemo(
    () =>
      (props.PackagePurchaseSequence?.packageList || []).map((item) => {
        const packageType =
          props.masterOptions?.packageType?.find((option) => option.value === item.package_type)
            ?.text || "";

        const gender = GENDER_OPTIONS.find((option) => option.value === item.gender);

        return {
          ...item,
          ageRange: (
            <div style={{ textAlign: "center" }}>
              {item.age_limits_year_start} - {item.age_limits_year_end}
            </div>
          ),
          buy: (
            <div style={GridCenter}>
              <LoadingIcon
                color="green"
                name="in cart"
                onClick={handleBuy(item.id)}
                loading={
                  !!props.buttonLoadCheck?.[
                    `${CARD_SEARCH_PACKAGE}_${BUTTON_ACTIONS.purchase}_${item.id}`
                  ]
                }
              />
            </div>
          ),
          description: (
            <div style={GridCenter}>
              <LoadingIcon
                name="eye"
                onClick={handleOpenInfo(item)}
                loading={
                  !!props.buttonLoadCheck?.[
                    `${CARD_SEARCH_PACKAGE}_${BUTTON_ACTIONS.desc}_${item.id}`
                  ]
                }
              />
            </div>
          ),
          genderName: item.gender === "NA" ? "ไม่ระบุเพศ" : gender?.text,
          package_type: <div>{packageType}</div>,
          price: (
            <div style={GridCenter}>
              {Number(item.price).toLocaleString("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: "decimal",
              })}
            </div>
          ),
        };
      }),
    [
      props.buttonLoadCheck,
      props.masterOptions?.packageType,
      props.PackagePurchaseSequence?.packageList,
    ]
  );

  // Handler
  const handleChangeValue = (e: any, data: any) => {
    let value = data.value === undefined ? data.checked : data.value;

    const name = data.name as keyof FilterPackageType;

    const detail = { ...filterPackage };

    // type number value ไม่น้อยกว่า 0
    if (["ageEnd", "ageStart"].includes(name)) {
      value = Number(value) < 0 ? 0 : value;
    }

    if (!value) {
      const clear = (
        {
          isAgeRange: ["ageStart", "ageEnd", "ageRangeError"],
          isGender: ["gender"],
          isPackageType: ["packageType"],
          isProgramName: ["programId"],
          isServiceType: ["serviceType"],
        } as any
      )[name];

      if (clear && Array.isArray(clear)) {
        for (const key of clear) {
          (detail as Record<string, string>)[key] = "";
        }
      }
    }

    detail[name] = value;

    props.setProp("PackagePurchaseSequence.filterPackage", { ...detail });
  };

  const handleSearch = () => {
    props.runSequence({
      sequence: "PackagePurchase",
      action: "SEARCH",
      card: CARD_SEARCH_PACKAGE,
    });
  };

  const handleClear = () => {
    props.setProp("PackagePurchaseSequence.filterPackage", {});
  };

  const handleCloseModInfo = () => {
    props.setProp("PackagePurchaseSequence.packageInfo", null);
  };

  const handleCloseMessage = () => {
    props.setProp(`errorMessage.${CARD_SEARCH_PACKAGE}`, null);

    props.setProp(`successMessage.${CARD_SEARCH_PACKAGE}`, null);
  };

  console.log("CardSearchPackageUX", props);

  return (
    <div>
      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage?.[CARD_SEARCH_PACKAGE]}
        success={props.successMessage?.[CARD_SEARCH_PACKAGE]}
        onClose={handleCloseMessage}
        languageUX={props.languageUX}
      />

      <CardSearchPackageUX
        ageRangeError={filterPackage.ageRangeError}
        disabledPackageServiceType={!filterPackage.isServiceType}
        disabledPackageType={!filterPackage.isPackageType}
        filterPackage={filterPackage}
        // ref
        inputRef={handleInputRef}
        packageItems={packageItems}
        packageServiceTypeOptions={props.masterOptions?.packageServiceType}
        // options
        packageTypeOptions={props.masterOptions?.packageType}
        // callback
        onChangeValue={handleChangeValue}
        onClear={handleClear}
        ButtonSearch={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            color={"blue"}
            // config
            disabled={filterPackage.ageRangeError}
            name={BUTTON_ACTIONS.search}
            // data
            paramKey={`${CARD_SEARCH_PACKAGE}_${BUTTON_ACTIONS.search}`}
            size="medium"
            title={intl.formatMessage({ id: "ค้นหา" })}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${CARD_SEARCH_PACKAGE}_${BUTTON_ACTIONS.search}`]
            }
            onClick={handleSearch}
          />
        }
        // Element
        SearchBoxProgramName={
          <SearchBoxDropdown
            onEvent={props.onEvent}
            id="SP"
            disabled={!filterPackage.isProgramName}
            icon="search"
            limit={20}
            selectedItem={filterPackage.programId || null}
            style={{ width: "100%" }}
            // config
            type="Package"
            fluid
            inline
            useWithKey
            // Select
            searchedItemListWithKey={props.searchedItemListWithKey}
            setSelectedItem={handleSelectedItem}
            // options
            mapOptions={mapPackageOptions}
            onAdditionalUrlParams={() => ({ active: true })}
          />
        }
        languageUX={props.languageUX}
      />

      <ModPackageDescription
        data={props.PackagePurchaseSequence?.packageInfo || null}
        open={!!props.PackagePurchaseSequence?.packageInfo}
        onClose={handleCloseModInfo}
        languageUX={props.languageUX}
      />

      <ModLockExpense
        onEvent={props.onEvent}
        setProp={props.setProp}
        buttonLoadCheck={props.buttonLoadCheck}
        errorMessage={props.errorMessage}
      />
    </div>
  );
};

CardSearchPackage.displayName = "CardSearchPackage";

export default React.memo(CardSearchPackage);
