import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  FormField,
  Input,
  Button
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardSegmentPatientUX = (props: any) => {
    return(
      <div>
        <div
          style={{ paddingBottom: "10px" }}>

          <label
            style={{ color: "#0072BC", fontWeight: "bold", fontSize: "16px" }}>
            เพิ่มสมาชิกกลุ่ม
          </label>
        </div>
        <div>

          <Form>
            <FormGroup
              inline={true}>
              <FormField>
                <label
                  style={{ fontWeight: "bold", fontSize: "14px" , minWidth: "max-content"}}>
                  เพิ่มรายบุคคล :
                </label>
              </FormField>
              <FormField
                width={3}>
                <div
                  style={{width: "100%"}}>
                  {props.addPatientSearchbox}
                </div>
              </FormField>
              <FormField>
                <div>
                  {props.buttonAdd}
                </div>
              </FormField>
              <FormField>
                <label
                  style={{ color: "grey", fontWeight: "bold", fontSize: "13px" }}>
                  หรือ
                </label>
              </FormField>
              <FormField>
                <label
                  style={{ fontWeight: "bold", fontSize: "14px" , minWidth: "max-content"}}>
                  เพิ่มเป็นกลุ่ม :
                </label>
              </FormField>
              <FormField
                style={{ display: "flex" , paddingRight: "0.5rem"}}
                width={4}>
                <Input
                  fluid={true}
                  readOnly={true}
                  style={{width: "100%"}}
                  value={props.file?.name || ""}>
                </Input>
                <Button
                  onClick={props.onBrowseFile}
                  style={{ backgroundColor: "#5DBCD2", color: "white" }}
                  type="button">
                  Browse
                </Button>
              </FormField>
              <FormField
                style={{paddingRight: "0.5rem"}}>
                <div>
                  {props.buttonImport}
                </div>
              </FormField>
              <FormField
                style={{paddingRight: "0"}}>
                <Button
                  basic={true}
                  color="blue"
                  onClick={props.onDownloadTemplate}
                  type="button">
                  Download template
                </Button>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField>
                <label
                  style={{ fontWeight: "normal", fontSize: "14px" }}>
                  ค้นหาสมาชิกกลุ่ม :
                </label>
              </FormField>
              <FormField>
                <div>
                  {props.patientSearchbox}
                </div>
              </FormField>
              <FormField>
                <Button
                  color="orange"
                  onClick={props.onClearSearch}
                  type="button">
                  clear search
                </Button>
              </FormField>
            </FormGroup>
          </Form>
        </div>
        <div>

          <Table
            data={props.tableData || []}
            headers="HN, ชื่อ-นามสกุล, ข้อความเพิ่มเติม,"
            keys="hn, fullname, remark_lbl, action "
            loading={props.loading}
            onPageChange={props.handlePageChange}
            onPageSizeChange={props.handlePageSizeChange}
            page={props.currentPage}
            pageSize={props.limit}
            pages={props.totalPage}
            showPageSizeOptions={true}
            showPagination={true}
            widths="^100, ^150, ^250,120">
          </Table>
        </div>
      </div>
    )
}


export default CardSegmentPatientUX

export const screenPropsDefault = {}

/* Date Time : Thu Oct 17 2024 11:41:57 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ paddingBottom: \"10px\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "label",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มสมาชิกกลุ่ม"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"#0072BC\", fontWeight: \"bold\", fontSize: \"16px\" }"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "Form",
      "parent": 2,
      "props": {
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormGroup",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormGroup",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormField",
      "parent": 6,
      "props": {
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "FormField",
      "parent": 6,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "FormField",
      "parent": 6,
      "props": {
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "FormField",
      "parent": 6,
      "props": {
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "FormField",
      "parent": 6,
      "props": {
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "FormField",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" , paddingRight: \"0.5rem\"}"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มรายบุคคล :"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"14px\" , minWidth: \"max-content\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonAdd"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "label",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": "หรือ"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"grey\", fontWeight: \"bold\", fontSize: \"13px\" }"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "label",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มเป็นกลุ่ม :"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"14px\" , minWidth: \"max-content\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "Input",
      "parent": 13,
      "props": {
        "disabled": {
          "type": "value",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.file?.name || \"\""
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "Button",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": "Browse"
        },
        "color": {
          "type": "value",
          "value": ""
        },
        "onClick": {
          "type": "code",
          "value": "props.onBrowseFile"
        },
        "style": {
          "type": "code",
          "value": "{ backgroundColor: \"#5DBCD2\", color: \"white\" }"
        },
        "type": {
          "type": "value",
          "value": "button"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "FormField",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{paddingRight: \"0.5rem\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "FormField",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{paddingRight: \"0\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "Button",
      "parent": 29,
      "props": {
        "basic": {
          "type": "code",
          "value": "true"
        },
        "children": {
          "type": "value",
          "value": "Download template"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onDownloadTemplate"
        },
        "type": {
          "type": "value",
          "value": "button"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "FormField",
      "parent": 7,
      "props": {
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "label",
      "parent": 32,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหาสมาชิกกลุ่ม : "
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"normal\", fontSize: \"14px\" }"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "FormField",
      "parent": 7,
      "props": {
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 36,
      "name": "Table",
      "parent": 3,
      "props": {
        "data": {
          "type": "code",
          "value": "props.tableData || []"
        },
        "headers": {
          "type": "value",
          "value": "HN, ชื่อ-นามสกุล, ข้อความเพิ่มเติม,"
        },
        "keys": {
          "type": "value",
          "value": "hn, fullname, remark_lbl, action "
        },
        "loading": {
          "type": "code",
          "value": "props.loading"
        },
        "onPageChange": {
          "type": "code",
          "value": "props.handlePageChange"
        },
        "onPageSizeChange": {
          "type": "code",
          "value": "props.handlePageSizeChange"
        },
        "page": {
          "type": "code",
          "value": "props.currentPage"
        },
        "pageSize": {
          "type": "code",
          "value": "props.limit"
        },
        "pages": {
          "type": "code",
          "value": "props.totalPage"
        },
        "showPageSizeOptions": {
          "type": "code",
          "value": "true"
        },
        "showPagination": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "value",
          "value": "^100, ^150, ^250,120"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 9,
      "props": {
        "children": {
          "type": "code",
          "value": "props.addPatientSearchbox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonImport"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 34,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientSearchbox"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "FormField",
      "parent": 7,
      "props": {
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "Button",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": "clear search"
        },
        "color": {
          "type": "value",
          "value": "orange"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClearSearch"
        },
        "type": {
          "type": "value",
          "value": "button"
        }
      },
      "seq": 41,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardSegmentPatientUX",
  "project": "cnmi",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
