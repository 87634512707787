import React from 'react'
import {
   Checkbox,
   Dropdown,
   Input,
} from 'semantic-ui-react'



import styles from './POPhysicalexaminationOrthoticFootOrthotic2LOW.module.css'

export interface POPhysicalexaminationOrthoticFootOrthotic2LOWViewDataType {
    CtCalcaneusPosition: string,
    CtCalcaneusPositionDisabled: boolean,
    CtCalcaneusPositionOptions: any,
    PoPhysicalOrthoticFootOrthotic2Low6: string,
    PoPhysicalOrthoticFootOrthotic2Low6Disabled: boolean,
    PoPhysicalOrthoticFootOrthotic2Low8: boolean,
    PoPhysicalOrthoticFootOrthotic2Low8Disabled: boolean,
    PoPhysicalOrthoticFootOrthotic2Low9: boolean,
    PoPhysicalOrthoticFootOrthotic2Low9Disabled: boolean,
    CtForefootPosition: string,
    CtForefootPositionDisabled: boolean,
    CtForefootPositionOptions: any,
    PoPhysicalOrthoticFootOrthotic2Low15: string,
    PoPhysicalOrthoticFootOrthotic2Low15Disabled: boolean,
    PoPhysicalOrthoticFootOrthotic2Low17: boolean,
    PoPhysicalOrthoticFootOrthotic2Low17Disabled: boolean,
    PoPhysicalOrthoticFootOrthotic2Low18: boolean,
    PoPhysicalOrthoticFootOrthotic2Low18Disabled: boolean,
    CtArchDeformity: string,
    CtArchDeformityDisabled: boolean,
    CtArchDeformityOptions: any,
    PoPhysicalOrthoticFootOrthotic2Low24: string,
    PoPhysicalOrthoticFootOrthotic2Low24Disabled: boolean,
    PoPhysicalOrthoticFootOrthotic2Low26: boolean,
    PoPhysicalOrthoticFootOrthotic2Low26Disabled: boolean,
    PoPhysicalOrthoticFootOrthotic2Low27: boolean,
    PoPhysicalOrthoticFootOrthotic2Low27Disabled: boolean,
    PoPhysicalOrthoticFootOrthotic2Low30: string,
    PoPhysicalOrthoticFootOrthotic2Low30Disabled: boolean,
    PoPhysicalOrthoticFootOrthotic2Low33: string,
    PoPhysicalOrthoticFootOrthotic2Low33Disabled: boolean,
    PoPhysicalOrthoticFootOrthotic2Low33Options: any,
    PoPhysicalOrthoticFootOrthotic2Low37: string,
    PoPhysicalOrthoticFootOrthotic2Low37Disabled: boolean,
    PoPhysicalOrthoticFootOrthotic2Low37Options: any,
    PoPhysicalOrthoticFootOrthotic2Low40: string,
    PoPhysicalOrthoticFootOrthotic2Low40Disabled: boolean,
    PoPhysicalOrthoticFootOrthotic2Low42: string,
    PoPhysicalOrthoticFootOrthotic2Low42Disabled: boolean,
}

export const POPhysicalexaminationOrthoticFootOrthotic2LOWInitialViewData: POPhysicalexaminationOrthoticFootOrthotic2LOWViewDataType = {
    CtCalcaneusPosition: "",
    CtCalcaneusPositionDisabled: false,
    CtCalcaneusPositionOptions: [{key: 0, value: "A", text: "A"}],
    PoPhysicalOrthoticFootOrthotic2Low6: "",
    PoPhysicalOrthoticFootOrthotic2Low6Disabled: false,
    PoPhysicalOrthoticFootOrthotic2Low8: false,
    PoPhysicalOrthoticFootOrthotic2Low8Disabled: false,
    PoPhysicalOrthoticFootOrthotic2Low9: false,
    PoPhysicalOrthoticFootOrthotic2Low9Disabled: false,
    CtForefootPosition: "",
    CtForefootPositionDisabled: false,
    CtForefootPositionOptions: [{key: 0, value: "A", text: "A"}],
    PoPhysicalOrthoticFootOrthotic2Low15: "",
    PoPhysicalOrthoticFootOrthotic2Low15Disabled: false,
    PoPhysicalOrthoticFootOrthotic2Low17: false,
    PoPhysicalOrthoticFootOrthotic2Low17Disabled: false,
    PoPhysicalOrthoticFootOrthotic2Low18: false,
    PoPhysicalOrthoticFootOrthotic2Low18Disabled: false,
    CtArchDeformity: "",
    CtArchDeformityDisabled: false,
    CtArchDeformityOptions: [{key: 0, value: "A", text: "A"}],
    PoPhysicalOrthoticFootOrthotic2Low24: "",
    PoPhysicalOrthoticFootOrthotic2Low24Disabled: false,
    PoPhysicalOrthoticFootOrthotic2Low26: false,
    PoPhysicalOrthoticFootOrthotic2Low26Disabled: false,
    PoPhysicalOrthoticFootOrthotic2Low27: false,
    PoPhysicalOrthoticFootOrthotic2Low27Disabled: false,
    PoPhysicalOrthoticFootOrthotic2Low30: "",
    PoPhysicalOrthoticFootOrthotic2Low30Disabled: false,
    PoPhysicalOrthoticFootOrthotic2Low33: "",
    PoPhysicalOrthoticFootOrthotic2Low33Disabled: false,
    PoPhysicalOrthoticFootOrthotic2Low33Options: [{key: 0, value: "A", text: "A"}],
    PoPhysicalOrthoticFootOrthotic2Low37: "",
    PoPhysicalOrthoticFootOrthotic2Low37Disabled: false,
    PoPhysicalOrthoticFootOrthotic2Low37Options: [{key: 0, value: "A", text: "A"}],
    PoPhysicalOrthoticFootOrthotic2Low40: "",
    PoPhysicalOrthoticFootOrthotic2Low40Disabled: false,
    PoPhysicalOrthoticFootOrthotic2Low42: "",
    PoPhysicalOrthoticFootOrthotic2Low42Disabled: false,
}

const Sub = (props: any) => {
    const children = React.Children.toArray(props.children)
    return (
        <div className={styles.container}>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Low1}>
                <div
                    style={{background: "#F3F3F3", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.CtCalcaneusPosition}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtCalcaneusPosition}
                    disabled={ props.viewData.CtCalcaneusPositionDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "POPhysicalexaminationOrthoticFootOrthotic2LOW",
                                name: "CtCalcaneusPosition",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtCalcaneusPositionOptions}
                />
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Low6}>
                <Input
                    value={props.viewData.PoPhysicalOrthoticFootOrthotic2Low6}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Low6Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationOrthoticFootOrthotic2LOW',
                            name: 'PoPhysicalOrthoticFootOrthotic2Low6',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Low7}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Calcaneus Position</strong></p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Low8}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalOrthoticFootOrthotic2Low8}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Low8Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationOrthoticFootOrthotic2LOW",
                                name: "PoPhysicalOrthoticFootOrthotic2Low8"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Low9}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalOrthoticFootOrthotic2Low9}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Low9Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationOrthoticFootOrthotic2LOW",
                                name: "PoPhysicalOrthoticFootOrthotic2Low9"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Low10}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Left</p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Low11}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Right</p>
                </div>
            </div>
            <div className={styles.CtForefootPosition}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtForefootPosition}
                    disabled={ props.viewData.CtForefootPositionDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "POPhysicalexaminationOrthoticFootOrthotic2LOW",
                                name: "CtForefootPosition",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtForefootPositionOptions}
                />
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Low15}>
                <Input
                    value={props.viewData.PoPhysicalOrthoticFootOrthotic2Low15}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Low15Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationOrthoticFootOrthotic2LOW',
                            name: 'PoPhysicalOrthoticFootOrthotic2Low15',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Low16}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Forefoot Position</strong></p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Low17}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalOrthoticFootOrthotic2Low17}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Low17Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationOrthoticFootOrthotic2LOW",
                                name: "PoPhysicalOrthoticFootOrthotic2Low17"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Low18}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalOrthoticFootOrthotic2Low18}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Low18Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationOrthoticFootOrthotic2LOW",
                                name: "PoPhysicalOrthoticFootOrthotic2Low18"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Low19}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Left</p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Low20}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Right</p>
                </div>
            </div>
            <div className={styles.CtArchDeformity}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtArchDeformity}
                    disabled={ props.viewData.CtArchDeformityDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "POPhysicalexaminationOrthoticFootOrthotic2LOW",
                                name: "CtArchDeformity",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtArchDeformityOptions}
                />
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Low24}>
                <Input
                    value={props.viewData.PoPhysicalOrthoticFootOrthotic2Low24}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Low24Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationOrthoticFootOrthotic2LOW',
                            name: 'PoPhysicalOrthoticFootOrthotic2Low24',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Low25}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Arch Deformity</strong></p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Low26}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalOrthoticFootOrthotic2Low26}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Low26Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationOrthoticFootOrthotic2LOW",
                                name: "PoPhysicalOrthoticFootOrthotic2Low26"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Low27}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalOrthoticFootOrthotic2Low27}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Low27Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationOrthoticFootOrthotic2LOW",
                                name: "PoPhysicalOrthoticFootOrthotic2Low27"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Low28}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Left</p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Low29}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Right</p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Low30}>
                <Input
                    value={props.viewData.PoPhysicalOrthoticFootOrthotic2Low30}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Low30Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationOrthoticFootOrthotic2LOW',
                            name: 'PoPhysicalOrthoticFootOrthotic2Low30',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Low31}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Footwear</strong></p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Low32}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Shoe style preference</p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Low33}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.PoPhysicalOrthoticFootOrthotic2Low33}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Low33Disabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "POPhysicalexaminationOrthoticFootOrthotic2LOW",
                                name: "PoPhysicalOrthoticFootOrthotic2Low33",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.PoPhysicalOrthoticFootOrthotic2Low33Options}
                />
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Low36}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Removable insole</strong></p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Low37}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.PoPhysicalOrthoticFootOrthotic2Low37}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Low37Disabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "POPhysicalexaminationOrthoticFootOrthotic2LOW",
                                name: "PoPhysicalOrthoticFootOrthotic2Low37",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.PoPhysicalOrthoticFootOrthotic2Low37Options}
                />
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Low40}>
                <Input
                    value={props.viewData.PoPhysicalOrthoticFootOrthotic2Low40}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Low40Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationOrthoticFootOrthotic2LOW',
                            name: 'PoPhysicalOrthoticFootOrthotic2Low40',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Low41}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Shoe worn</strong></p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Low42}>
                <Input
                    value={props.viewData.PoPhysicalOrthoticFootOrthotic2Low42}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Low42Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationOrthoticFootOrthotic2LOW',
                            name: 'PoPhysicalOrthoticFootOrthotic2Low42',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Low43}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Remark</strong></p>
                </div>
            </div>
        </div>
    )
}

export default Sub

const getTsName = (cssClass: string) => {
    return cssClass.split('-').map((s) => (
        s.charAt(0).toUpperCase() + s.slice(1)
    )).join('')
}
