import React, {
  FC,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  Button,
  Dropdown,
  Form,
  Grid,
  Icon,
  Input,
  Segment,
} from "semantic-ui-react";

import CardLayout from "react-lib/apps/common/CardLayout";
import RightLabel from "react-lib/apps/common/RightLabel";
import { useIntl } from "react-intl";

const styles = {
  marginBottom: {
    marginBottom: "15px",
  },
  noMargin: {
    margin: "0px",
  },
};

/* ----- SubPainAssessment ----- */
interface SubPainAssessmentProps {
  painAssessmentMethodOptions: any[];
  painAssessmentPainScoreOptions: any[];
  painAssessmentFallRiskOptions: any[];
  painAssessmentFallRiskDetailOptions: any[];
  painAssessmentFrequencyOfPainOptions: any[];
  painAssessmentDuratonOptions: any[];
  painAssessmentCharacteristicOptions: any[];
  index: any;
  data: any;
  readonly:boolean;
  onRemove: (any) => any;
}

const SubPainAssessment: FC<SubPainAssessmentProps> = forwardRef(
  (props, ref) => {
    const [item, setItem] = useState<any>(defaultData);
    const [fallRiskDetailOptions, setFallRiskDetailOptions] = useState<any>([]);

    useEffect(() => {
      let options = [];
      let multiOptions = [];

      if (props.painAssessmentFallRiskDetailOptions) {
        props.painAssessmentFallRiskDetailOptions.map((option) => {
          options.push(option.value);
        });
        multiOptions = [...props.painAssessmentFallRiskDetailOptions];
      }

      if (props.data.cboPainAssessmentFallRiskDetail) {
        props.data.cboPainAssessmentFallRiskDetail
          .filter((e) => !options.includes(e))
          .map((detail) => {
            multiOptions.push({
              key: detail,
              text: detail,
              value: detail,
            });
          });
      }

      setFallRiskDetailOptions(multiOptions);
      setItem(props.data);
    }, [props.data, props.painAssessmentFallRiskDetailOptions]);

    useImperativeHandle(ref, () => ({
      getData: () => {
        return item;
      },
    }));

    const handleDataChange = (event, data) => {
      setItem({ ...item, [data.name]: data.value });
    };

    const handleAdditionMultiData = (event, { value }) => {
      let multiOptions = [...fallRiskDetailOptions];
      multiOptions.push({
        key: value,
        text: value,
        value: value,
      });
      setFallRiskDetailOptions(multiOptions);

      let multiData = [...item.cboPainAssessmentFallRiskDetail];
      multiData.push(value);
      setItem({
        ...item,
        cboPainAssessmentFallRiskDetail: multiData,
      });
    };

    const handleRemove = () => {
      props.onRemove(props.index);
    };

    return (
      <Segment tertiary style={styles.marginBottom}>
        <Form size="tiny">
          <Form.Group inline>
            <Form.Field width={3}>
              <RightLabel>Method</RightLabel>
            </Form.Field>
            <Form.Field width={5}>
              <Dropdown
                selection
                className="fluidDropdown"
                name="cboPainAssessmentMethod"
                disabled={item.cboPainAssessmentMethodDisabled || props.readonly}
                value={item.cboPainAssessmentMethod}
                options={props.painAssessmentMethodOptions || []}
                onChange={handleDataChange}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group inline>
            <Form.Field width={3}>
              <RightLabel>Pain score</RightLabel>
            </Form.Field>
            <Form.Field width={5}>
              <Dropdown
                selection
                className="fluidDropdown"
                name="cboPainAssessmentPainScore"
                disabled={item.cboPainAssessmentPainScoreDisabled || props.readonly}
                readOnly={props.readonly}
                value={item.cboPainAssessmentPainScore}
                options={props.painAssessmentPainScoreOptions || []}
                onChange={handleDataChange}
              />
            </Form.Field>
            <Form.Field width={8}>
              <Input
                name="txtPainAssessmentPainScore"
                value={item.txtPainAssessmentPainScore}
                disabled={item.txtPainAssessmentPainScoreDisabled || props.readonly}
                onChange={handleDataChange}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group inline>
            <Form.Field width={3}>
              <RightLabel>Location of pain</RightLabel>
            </Form.Field>
            <Form.Field width={5}>
              <Input
                name="txtPainAssessmentLocationOfPain"
                value={item.txtPainAssessmentLocationOfPain}
                disabled={item.txtPainAssessmentLocationOfPainDisabled || props.readonly}
                onChange={handleDataChange}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group inline>
            <Form.Field width={3}>
              <RightLabel>Fall risk</RightLabel>
            </Form.Field>
            <Form.Field width={3}>
              <Dropdown
                selection
                className="fluidDropdown"
                name="cboPainAssessmentFallRisk"
                disabled={item.cboPainAssessmentFallRiskDisabled || props.readonly}
                value={item.cboPainAssessmentFallRisk}
                options={props.painAssessmentFallRiskOptions || []}
                onChange={handleDataChange}
              />
            </Form.Field>
            <Form.Field width={10}>
              <Dropdown
                search
                selection
                multiple
                allowAdditions
                className="fluidDropdown"
                name="cboPainAssessmentFallRiskDetail"
                disabled={item.cboPainAssessmentFallRiskDetailDisabled || props.readonly}
                value={
                  item.cboPainAssessmentFallRiskDetail
                    ? item.cboPainAssessmentFallRiskDetail
                    : []
                }
                options={fallRiskDetailOptions}
                onAddItem={handleAdditionMultiData}
                onChange={handleDataChange}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group inline>
            <Form.Field width={3}>
              <RightLabel>Frequency of pain</RightLabel>
            </Form.Field>
            <Form.Field width={5}>
              <Dropdown
                selection
                className="fluidDropdown"
                name="cboPainAssessmentFrequencyOfPain"
                disabled={item.cboPainAssessmentFrequencyOfPainDisabled || props.readonly}
                value={item.cboPainAssessmentFrequencyOfPain}
                options={props.painAssessmentFrequencyOfPainOptions || []}
                onChange={handleDataChange}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group inline>
            <Form.Field width={3}>
              <RightLabel>Duraton</RightLabel>
            </Form.Field>
            <Form.Field width={5}>
              <Dropdown
                selection
                className="fluidDropdown"
                name="cboPainAssessmentDuraton"
                disabled={item.cboPainAssessmentDuratonDisabled || props.readonly}
                value={item.cboPainAssessmentDuraton}
                options={props.painAssessmentDuratonOptions || []}
                onChange={handleDataChange}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group inline>
            <Form.Field width={3}>
              <RightLabel>Characteristic</RightLabel>
            </Form.Field>
            <Form.Field width={5}>
              <Dropdown
                selection
                className="fluidDropdown"
                name="cboPainAssessmentCharacteristic"
                disabled={item.cboPainAssessmentCharacteristicDisabled || props.readonly}
                value={item.cboPainAssessmentCharacteristic}
                options={props.painAssessmentCharacteristicOptions || []}
                onChange={handleDataChange}
              />
            </Form.Field>
            <Form.Field width={8}>
              <Input
                name="txtPainAssessmentCharacteristic"
                value={item.txtPainAssessmentCharacteristic}
                disabled={item.txtPainAssessmentCharacteristicDisabled || props.readonly}
                onChange={handleDataChange}
              />
            </Form.Field>
          </Form.Group>

          {!props.readonly && <Form.Group inline className="rightAlign">
            <Form.Field width={1}>
              <Button
                icon
                color="red"
                type="button"
                size="mini"
                onClick={handleRemove}
              >
                <Icon name="delete" />
              </Button>
            </Form.Field>
          </Form.Group>}
        </Form>
      </Segment>
    );
  }
);

/* ----- CardPainAssessment ----- */
export interface PainAssessmentViewDataType {
  titleCard: string;
  isLoading: boolean;
  closeable: boolean;
  toggleable: boolean;
  readonly: boolean;
  hideCallback: () => any;

  onEvent: (any) => any;
  saveButtonDisabled: boolean;
  dropDownOptions: {
    painAssessmentMethodOptions: any[];
    painAssessmentPainScoreOptions: any[];
    painAssessmentFallRiskOptions: any[];
    painAssessmentFallRiskDetailOptions: any[];
    painAssessmentFrequencyOfPainOptions: any[];
    painAssessmentDuratonOptions: any[];
    painAssessmentCharacteristicOptions: any[];
  };
  viewData: [
    {
      cboPainAssessmentMethod: string;
      cboPainAssessmentMethodDisabled: boolean;

      cboPainAssessmentPainScore: string;
      cboPainAssessmentPainScoreDisabled: boolean;
      txtPainAssessmentPainScore: string;
      txtPainAssessmentPainScoreDisabled: boolean;

      txtPainAssessmentLocationOfPain: string;
      txtPainAssessmentLocationOfPainDisabled: boolean;

      cboPainAssessmentFallRisk: string;
      cboPainAssessmentFallRiskDisabled: boolean;
      cboPainAssessmentFallRiskDetail: any[];
      cboPainAssessmentFallRiskDetailDisabled: boolean;

      cboPainAssessmentFrequencyOfPain: string;
      cboPainAssessmentFrequencyOfPainDisabled: boolean;

      cboPainAssessmentDuraton: string;
      cboPainAssessmentDuratonDisabled: boolean;

      cboPainAssessmentCharacteristic: string;
      cboPainAssessmentCharacteristicDisabled: boolean;
      txtPainAssessmentCharacteristic: string;
      txtPainAssessmentCharacteristicDisabled: boolean;
    }
  ];
}

const CardPainAssessment: FC<PainAssessmentViewDataType> = forwardRef(
  (props, ref) => {
    const subPainAssessmentRef = useRef([]);
    const [items, setItems] = useState<any>([defaultData]);

    useEffect(() => {
      let newItems: any = [...props.viewData];
      if (newItems.length === 0) {
        newItems.push(defaultData);
      }
      setItems(newItems);
    }, [props.viewData]);

    useImperativeHandle(ref, () => ({
      getData: () => {
        let result: any = [];
        subPainAssessmentRef.current.map((item) => {
          try {
            result.push(item.getData());
          } catch (err) {}
        });
        return result;
      },
    }));

    const handleReassessment = () => {
      let newItems: any = [...items];
      newItems.push(defaultData);
      setItems(newItems);
    };

    const handleRemoveComponent = (index) => {
      let newItems: any = [];
      subPainAssessmentRef.current.map((item, i) => {
        if (i !== index) {
          try {
            newItems.push(item.getData());
          } catch (err) {}
        }
      });
      setItems(newItems);
    };

    const handleSave = () => {
      const params = {
        name: "SaveRequest",
        view: "PainAssessmentViewData",
        value: [],
      };
      props.onEvent({ message: "clickButton", params: params });
    };

    return (
      <>
        <CardLayout
          titleText={props.titleCard}
          headerColor="olive"
          loading={props.isLoading}
          closeable={props.closeable}
          toggleable={props.toggleable}
          onClose={props.hideCallback}
          hideHeaderIcon={true}
          hideDivider={true}
        >
          <Grid>
            <Grid.Row columns={2}>
              {items.map((item, index) => {
                return (
                  <Grid.Column width={8} key={`grid${index}`}>
                    <SubPainAssessment
                      readonly={props.readonly}
                      ref={(el) => (subPainAssessmentRef.current[index] = el)}
                      key={index}
                      index={index}
                      painAssessmentMethodOptions={
                        props.dropDownOptions.painAssessmentMethodOptions
                      }
                      painAssessmentPainScoreOptions={
                        props.dropDownOptions.painAssessmentPainScoreOptions
                      }
                      painAssessmentFallRiskOptions={
                        props.dropDownOptions.painAssessmentFallRiskOptions
                      }
                      painAssessmentFallRiskDetailOptions={
                        props.dropDownOptions
                          .painAssessmentFallRiskDetailOptions
                      }
                      painAssessmentFrequencyOfPainOptions={
                        props.dropDownOptions
                          .painAssessmentFrequencyOfPainOptions
                      }
                      painAssessmentDuratonOptions={
                        props.dropDownOptions.painAssessmentDuratonOptions
                      }
                      painAssessmentCharacteristicOptions={
                        props.dropDownOptions
                          .painAssessmentCharacteristicOptions
                      }
                      data={item}
                      onRemove={handleRemoveComponent}
                      languageUX={props.languageUX}
                    />
                  </Grid.Column>
                );
              })}
            </Grid.Row>
          </Grid>

          {!props.readonly && <Form>
            <Form.Group inline className="rightAlign" style={styles.noMargin}>
              <Form.Field width={2}>
                <Button
                  fluid
                  color="blue"
                  type="button"
                  size="small"
                  onClick={handleReassessment}
                >
                  Reassessment
                </Button>
              </Form.Field>
            </Form.Group>
          </Form>}
        </CardLayout>

        {!props.readonly && <Form>
          <Form.Group inline className="rightAlign">
            <Form.Field width={1}>
              <Button
                fluid
                disabled={props.saveButtonDisabled}
                color="green"
                type="button"
                size="small"
                onClick={handleSave}
              >
                Save
              </Button>
            </Form.Field>
          </Form.Group>
        </Form>}
      </>
    );
  }
);

const defaultOption = {
  painAssessmentMethodOptions: [],
  painAssessmentPainScoreOptions: [],
  painAssessmentFallRiskOptions: [],
  painAssessmentFallRiskDetailOptions: [],
  painAssessmentFrequencyOfPainOptions: [],
  painAssessmentDuratonOptions: [],
  painAssessmentCharacteristicOptions: [],
};

const defaultData = {
  cboPainAssessmentMethod: "nrs",
  cboPainAssessmentMethodDisabled: false,

  cboPainAssessmentPainScore: "",
  cboPainAssessmentPainScoreDisabled: false,
  txtPainAssessmentPainScore: "",
  txtPainAssessmentPainScoreDisabled: false,

  txtPainAssessmentLocationOfPain: "",
  txtPainAssessmentLocationOfPainDisabled: false,

  cboPainAssessmentFallRisk: "high",
  cboPainAssessmentFallRiskDisabled: false,
  cboPainAssessmentFallRiskDetail: [],
  cboPainAssessmentFallRiskDetailDisabled: false,

  cboPainAssessmentFrequencyOfPain: "daily",
  cboPainAssessmentFrequencyOfPainDisabled: false,

  cboPainAssessmentDuraton: "",
  cboPainAssessmentDuratonDisabled: false,

  cboPainAssessmentCharacteristic: "",
  cboPainAssessmentCharacteristicDisabled: false,
  txtPainAssessmentCharacteristic: "",
  txtPainAssessmentCharacteristicDisabled: false,
};

export const PainAssessmentInitialViewData: PainAssessmentViewDataType = {
  titleCard: "",
  isLoading: false,
  closeable: false,
  toggleable: false,
  hideCallback: () => {},

  onEvent: ({}) => {},
  dropDownOptions: defaultOption,
  viewData: [defaultData],
  saveButtonDisabled: false,
  readonly: false,
};

CardPainAssessment.defaultProps = PainAssessmentInitialViewData;

export default CardPainAssessment;
