import React, { useState, useEffect, useMemo } from "react";
import { Button, Icon } from "semantic-ui-react";
import CardSummaryStatisticsUX from "./CardSummaryStatisticsUX";
import moment from "moment";
import { parseDate, formatADtoBEString } from "react-lib/utils/dateUtils";
import { DATE_FORMAT, timeSlotList } from "react-lib/apps/Scheduling/common/Utils";
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";
import { CSVLink } from "react-csv";
import { useIntl } from "react-intl";

const dateDefault = moment(new Date())?.format(DATE_FORMAT);

const startExportDateDefault = formatADtoBEString(moment(dateDefault).format(DATE_FORMAT));
const endExportDateDefault = formatADtoBEString(moment(dateDefault).format(DATE_FORMAT));

const CardSummaryStatistics = (props: any) => {
  const intl = useIntl();
  const [selectDivisionPA, setSelectDivisionPA] = useState<any>(null);
  const [startDate, setStartDate] = useState(dateDefault);
  const [endDate, setEndDate] = useState(dateDefault);
  const [startExportDate, setStartExportDate] = useState(startExportDateDefault);
  const [endExportDate, setEndExportDate] = useState(endExportDateDefault);

  useEffect(() => {
    props.onEvent({ message: "GetSummaryStatistics", params: {} });
  }, []);

  const dataSummaryStatistics = useMemo(() => {
    return (props.summaryStatisticsList || []).map((item: any, index: number) => ({
      ...item,
      date: `${startExportDate} ถึง ${endExportDate}`,
      reject_percent: `${item.reject_percent} %`,
      cancel_percent: `${item.cancel_percent} %`,
      abandon_percent: `${item.abandon_percent} %`,
    }));
  }, [props.summaryStatisticsList]);

  const mapDivisionOptions = (items: any) => {
    // console.log("mapDivisionOptions items: ", items);
    return items.map((item: any) => ({
      key: item.code,
      value: item.name,
      text: item.name,
    }));
  };
  const changeDate = (key: string) => (value: any) => {
    console.log(value);
    const newDateString = (parseDate(value, true) as moment.Moment).format(DATE_FORMAT);
    if (key === "startDate") {
      setStartDate(newDateString);
      setStartExportDate(value);
    } else if (key === "endDate") {
      setEndDate(newDateString);
      setEndExportDate(value);
    }
  };

  //Export .CSV
  const dataExport = useMemo(() => {
    const title = [`รายงานสถิติการนัดหมายแต่ละคลินิก ตั้งแต่ ${dataSummaryStatistics?.[0]?.date}`,"","",""];
    const header = ["คลินิก","% การผิดนัด","% การเลื่อนนัด (ผู้ป่วยเป็นคนเลื่อน)","% การเลื่อนนัด  (คลินิกเป็นคนเลื่อน)"]
    const data = dataSummaryStatistics.map((item: any)=>[item.division__name, item.reject_percent,item.cancel_percent,item.abandon_percent])

    return [title, header, ...data]
  }, [dataSummaryStatistics])
 
  return (
    <>
      <CardSummaryStatisticsUX
        startDate={formatADtoBEString(moment(startDate).format(DATE_FORMAT))}
        changeDate={changeDate}
        endDate={formatADtoBEString(moment(endDate).format(DATE_FORMAT))}
        dataSummaryStatistics={dataSummaryStatistics}
        divisionSearch={
          <SearchBoxDropdown
            type="Division"
            id="3"
            style={{ width: "100%" }}
            fluid={true}
            label=""
            // disabled={selectedManagePatient !== null}
            toDisplay={(item: any) => `${item.name}`}
            onEvent={props.onEvent}
            // searchedItemList={props.searchedItemList}
            searchedItemListWithKey={props.searchedItemListWithKey}
            selectedItem={selectDivisionPA}
            setSelectedItem={(value: any, key: any, obj: any) => {
              if (value === null && key === null) {
                setSelectDivisionPA(null);
                return;
              }
              let division = props.searchedItemListWithKey?.Division_3?.find((item: any) => item.code === key);
              setSelectDivisionPA(division);
            }}
            useWithKey={true}
            mapOptions={mapDivisionOptions}
          />
        }
        OnSearch={() => {
          props.onEvent({
            message: "GetSummaryStatistics",
            params: {
              action: "search",
              data: {
                division: selectDivisionPA,
                startDate: startDate,
                endDate: endDate,
              },
            },
          });
        }}
        buttonExportCSV={
          <CSVLink data={dataExport}  filename={intl.formatMessage({ id: "สถิติการนัดหมาย.csv" })} target="_blank">
            <Button color="orange">
              <div style={{ display: "flex" }}>
                <Icon name="download" />
                <div style={{ marginRight: "3px" }}> Export .CSV </div>
              </div>
            </Button>
          </CSVLink>
        }
        languageUX={props.languageUX}
      />
    </>
  );
};

export default CardSummaryStatistics;
