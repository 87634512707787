import WasmController from "react-lib/frameworks/WasmController";

import { TDocumentDefinitions } from "pdfmake/interfaces";
import moment from "moment";

// APIs
import PriceChangeOverviewSummary from "issara-sdk/apis/PriceChangeOverviewSummaryReport_apps_INF";
import PriceChangeRiskReport from "issara-sdk/apis/PriceChangeRiskReport_apps_INF";
import ReportCompensationByExaminationType from "issara-sdk/apis/ReportCompensationByExaminationType_apps_INF";
import ReportDoctorIncomeMonthlySummary from "issara-sdk/apis/ReportDoctorIncomeMonthlySummary_apps_INF";
import ReportDoctorIncomeSummary from "issara-sdk/apis/ReportDoctorIncomeSummary_apps_INF";
import ReportIncomeDivisionView from "issara-sdk/apis/ReportIncomeDivisionView_apps_BIL";
import ReportMoneyReceiveView from "issara-sdk/apis/ReportMoneyReceiveView_apps_BIL";
import ReportRiskView from "issara-sdk/apis/ReportRiskView_apps_BIL";

// PDF
import FormClinicIncomeReportDaily from "../pdfFormBil/FormClinicIncomeReportDaily";
import FormClinicIncomeReportMonthly from "../pdfFormBil/FormClinicIncomeReportMonthly";
import FormClinicIncomeReportYearly from "../pdfFormBil/FormClinicIncomeReportYearly";
import FormDoctorIncomeMonthlySummaryReport from "../pdfFormBil/FormDoctorIncomeMonthlySummaryReport";
import FormPaidCompensationWorkingType from "../pdfFormBil/FormPaidCompensationWorkingType";
import FormPatientPaidRiskReport from "../pdfFormBil/FormPatientPaidRiskReport";
import FormPatientPaidServicesReport from "../pdfFormBil/FormPatientPaidServicesReport";
import FormPatientSaveRiskReport from "../pdfFormBil/FormPatientSaveRiskReport";
import FormPriceChangeOverviewSummaryReportDaily from "../pdfFormBil/FormPriceChangeOverviewSummaryReportDaily";
import FormPriceChangeOverviewSummaryReportMonthly from "../pdfFormBil/FormPriceChangeOverviewSummaryReportMonthly";
import FormPriceChangeOverviewSummaryReportYearly from "../pdfFormBil/FormPriceChangeOverviewSummaryReportYearly";
import FormPriceChangeRiskReport from "../pdfFormBil/FormPriceChangeRiskReport";

import FormDoctorIncomeSummary from "../../BIL/pdfFormBil/FormDoctorIncomeSummary";
import FormDoctorIncomeSummaryDaily from "../../BIL/pdfFormBil/FormDoctorIncomeSummaryDaily";

// Common
import {
  SetErrorMessage,
  SetProperty,
  downloadXLSX,
} from "react-lib/apps/HISV3/common/CommonInterface";
import getPdfMake from "react-lib/appcon/common/pdfMake";

// Interface
import { State as MainState } from "HIS/MainHISInterface";

// Utils
import { dateToStringWithoutTimeBE } from "react-lib/utils/dateUtils";
import { printURL } from "react-lib/utils/printURL";

export type State = Partial<{
  // Sequence
  ClinicAnnualReportSequence: Partial<{
    sequenceIndex: "Start" | "Action" | null;
    selectedMenu: string;
    // Header Pdf
    headerReport: Partial<{
      printReportDate: string;
      publisherName: string;
    }>;
    // รายงานผู้ป่วยที่ชำระค่าบริการ
    patientPaidServicesReport: Partial<{
      filterStartDate: string;
      filterEndDate: string;
      encounterType: string;
      coverageSelected: string;
    }>;
    // รายงานสรุปรายได้คลินิก
    clinicIncomeReport: Partial<{
      filterStartDate: string;
      filterEndDate: string;
      reportType: string;
      encounterType: string;
      coverageSelected: string;
    }>;
    // รายงานผู้ป่วยค้างชำระ
    patientPaidRiskReport: Partial<{
      filterStartDate: string;
      filterEndDate: string;
      encounterType: string;
      coverageSelected: string;
    }>;
    // รายงานผู้ป่วยที่ยังไม่ได้บันทึกรับชำระ
    patientSaveRiskReport: Partial<{
      filterStartDate: string;
      filterEndDate: string;
      encounterType: string;
      coverageSelected: string;
      doctorSelected: string;
    }>;
    // รายงานค่ารักษาที่แพทย์เปลี่ยนแปลงราคา
    priceChangeRiskReport: Partial<{
      filterStartDate: string;
      filterEndDate: string;
      doctorSelected: string;
      specialtySelected: string;
      encounterType: string;
    }>;
    // รายงานสรุปรายได้ทั้งเดือนส่งเบิกจ่ายแพทย์
    doctorIncomeMonthlySummaryReport: Partial<{
      filterStartDate: string;
      filterEndDate: string;
      doctorSelected: string;
      specialtySelected: string;
    }>;
    // รายงานภาพรวมการเปลี่ยนแปลงราคาโดยแพทย์
    priceChangeOverviewSummaryReport: Partial<{
      filterStartDate: string;
      filterEndDate: string;
      doctorSelected: string;
      specialtySelected: string;
      encounterType: string;
      reportType: string;
    }>;
    // รายงานการแบ่งจ่ายค่าตอบแทนตามประเภทงาน
    paidCompensationWorkingTypeReport: Partial<{
      filterStartDate: string;
      filterEndDate: string;
      workingTypeSelected: string;
    }>;
    // รายงานสรุปรายได้ และค่าตอบแทนแพทย์
    doctorIncomeSummaryReport: Partial<{
      filterStartDate: string;
      filterEndDate: string;
      doctorSelected: string;
      specialtySelected: string;
      positionSelected: string;
      periodSelected: string;
      encounterType: string;
      reportType: string;
    }>;
  }>;
}>;

type PickedState = Partial<Pick<MainState, "buttonLoadCheck" | "django" | "errorMessage">>;

export type PickedProps = Partial<Omit<PickedState, "">>;

type ReportMenu =
  | "รายงานสรุปรายได้ และค่าตอบแทนแพทย์"
  | "รายงานสรุปรายได้ทั้งเดือนส่งเบิกจ่ายแพทย์"
  | "รายงานการแบ่งจ่ายค่าตอบแทนตามประเภทงาน"
  | "รายงานค่ารักษาที่แพทย์เปลี่ยนแปลงราคา"
  | "รายงานภาพรวมการเปลี่ยนแปลงราคาโดยแพทย์"
  | "รายงานสรุปรายได้คลินิก"
  | "รายงานผู้ป่วยค้างชำระ"
  | "รายงานผู้ป่วยที่ยังไม่ได้บันทึกรับชำระ"
  | "รายงานผู้ป่วยที่ชำระค่าบริการ";

type ReportType = "daily" | "monthly" | "none" | "yearly";

type ReportReturnType<T extends { excel?: boolean }> = T["excel"] extends boolean
  ? T["excel"] extends true
    ? [ArrayBuffer | null, unknown]
    : [any, unknown]
  : [any, unknown];

// Sequence State
type SeqState = {
  sequence: "ClinicAnnualReport";
  restart?: boolean;
  clear?: boolean;
};

// Handle Action
type ActionType =
  // Action
  | {
      action: "PREVIEW_REPORT";
      menu: ReportMenu;
    }
  | {
      action: "PRINT_REPORT";
      menu: ReportMenu;
    }
  | {
      action: "DOWNLOAD_REPORT";
      menu: ReportMenu;
    }
  | {
      action: "GET_REPORT";
      menu: ReportMenu;
    };

// Method
type SeqAct = SeqState & ActionType;
type SeqType<K> = K extends { action: string } ? Extract<SeqAct, K> : SeqState;

export type RunSequence = <K extends keyof SeqAct>(params: SeqType<Pick<SeqAct, K>>) => void;

export type SetProp = SetProperty<State & PickedState>;

type CustomExtract<T, U> = T extends object ? (U extends Partial<T> ? T : never) : never;

type Params<A extends ActionType["action"]> = CustomExtract<ActionType, { action: A }>;

export const StateInitial: State = {
  // Sequence
  ClinicAnnualReportSequence: {
    sequenceIndex: null,
  },
};

export type Event =
  | { message: "RunSequence"; params: Record<string, unknown> }
  | { message: "GetMasterData"; params: Record<string, unknown> };

export type Data = {
  division?: number;
};

export const DataInitial = {};

// Const
const Masters = [
  ["coverage", {}],
  ["division", {}],
  ["examinationTypeName", {}],
  ["specialty", {}],
  ["positionAll", {}],
];
const nowDateBeForFilter = dateToStringWithoutTimeBE(moment()); // 07/03/2567

export const ACTIONS = {
  PREVIEW: "PREVIEW_REPORT",
  PRINT: "PRINT_REPORT",
  DOWNLOAD: "DOWNLOAD_REPORT",
  GET: "GET_REPORT",
} as const;

type Controller = WasmController<State & PickedState, Event, Data>;

type Handler<P = any, R = any> = (controller: Controller, params: P) => R;

/* ------------------- START ------------------- */
export const Start: Handler = async (controller, params) => {
  console.log("ClinicAnnualReport START State");

  const state = controller.getState();

  // Start Log Zone
  // console.log("ClinicAnnualReport Seq nowDateBEForFilter: ", nowDateBeForFilter);
  // console.log("ClinicAnnualReport Seq django Data: ", state.django);

  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: Masters,
    },
  });

  console.log("ClinicAnnualReport Controller Data:", controller);
  console.log("ClinicAnnualReport Params Data:", params);
  // console.log("ClinicAnnualReport Master Data: ", params.coverage);

  controller.setState({
    ClinicAnnualReportSequence: {
      ...state.ClinicAnnualReportSequence,
      sequenceIndex: "Action",
      patientPaidServicesReport: {
        filterStartDate: nowDateBeForFilter,
        filterEndDate: nowDateBeForFilter,
      },
      clinicIncomeReport: {
        filterStartDate: nowDateBeForFilter,
        filterEndDate: nowDateBeForFilter,
        reportType: "daily",
      },
      patientPaidRiskReport: {
        filterStartDate: nowDateBeForFilter,
        filterEndDate: nowDateBeForFilter,
      },
      patientSaveRiskReport: {
        filterStartDate: nowDateBeForFilter,
        filterEndDate: nowDateBeForFilter,
      },
      priceChangeRiskReport: {
        filterStartDate: nowDateBeForFilter,
        filterEndDate: nowDateBeForFilter,
      },
      doctorIncomeMonthlySummaryReport: {
        filterStartDate: nowDateBeForFilter,
        filterEndDate: nowDateBeForFilter,
      },
      priceChangeOverviewSummaryReport: {
        filterStartDate: nowDateBeForFilter,
        filterEndDate: nowDateBeForFilter,
        reportType: "daily",
      },
      paidCompensationWorkingTypeReport: {
        filterStartDate: nowDateBeForFilter,
        filterEndDate: nowDateBeForFilter,
      },
      doctorIncomeSummaryReport: {
        filterStartDate: nowDateBeForFilter,
        filterEndDate: nowDateBeForFilter,
        reportType: "daily",
      },
    },
  });
};

/* ------------------- ACTION ------------------- */
export const Action: Handler<ActionType> = async (controller, params) => {
  const actionHandlers: Partial<{ [K in ActionType["action"]]: Handler<Params<K>> }> = {
    [ACTIONS.PREVIEW]: HandlePreviewReport,
    [ACTIONS.PRINT]: HandlePrintReport,
    [ACTIONS.DOWNLOAD]: HandleDownloadReport,
    [ACTIONS.GET]: HandleGetReport,
  };

  const action = params.action;

  return actionHandlers[action]?.(controller, params as Params<typeof params.action>);
};

const HandleGetReport: Handler = async (controller, params) => {
  console.log("ClinicAnnual Report Seq GET_REPORT");

  let state = controller.getState();

  // console.log("ClinicAnnual Report Seq (GET_REPORT) Menu: ", params.menu);

  switch (params.menu) {
    case "รายงานสรุปรายได้คลินิก":
      state = controller.getState();

      controller.setState({
        ClinicAnnualReportSequence: {
          ...state.ClinicAnnualReportSequence,
          selectedMenu: params.menu,
          clinicIncomeReport: {
            ...state.ClinicAnnualReportSequence?.clinicIncomeReport,
          },
        },
      });
      break;
    case "รายงานผู้ป่วยค้างชำระ":
      state = controller.getState();

      controller.setState({
        ClinicAnnualReportSequence: {
          ...state.ClinicAnnualReportSequence,
          selectedMenu: params.menu,
          patientPaidRiskReport: {
            ...state.ClinicAnnualReportSequence?.patientPaidRiskReport,
          },
        },
      });
      break;
    case "รายงานผู้ป่วยที่ยังไม่ได้บันทึกรับชำระ":
      state = controller.getState();

      controller.setState({
        ClinicAnnualReportSequence: {
          ...state.ClinicAnnualReportSequence,
          selectedMenu: params.menu,
          patientSaveRiskReport: {
            ...state.ClinicAnnualReportSequence?.patientSaveRiskReport,
          },
        },
      });
      break;
    case "รายงานผู้ป่วยที่ชำระค่าบริการ":
      state = controller.getState();

      controller.setState({
        ClinicAnnualReportSequence: {
          ...state.ClinicAnnualReportSequence,
          selectedMenu: params.menu,
          patientPaidServicesReport: {
            ...state.ClinicAnnualReportSequence?.patientPaidServicesReport,
          },
        },
      });
      break;
    case "รายงานค่ารักษาที่แพทย์เปลี่ยนแปลงราคา":
      state = controller.getState();

      controller.setState({
        ClinicAnnualReportSequence: {
          ...state.ClinicAnnualReportSequence,
          selectedMenu: params.menu,
          priceChangeRiskReport: {
            ...state.ClinicAnnualReportSequence?.priceChangeRiskReport,
          },
        },
      });
      break;
    case "รายงานสรุปรายได้ทั้งเดือนส่งเบิกจ่ายแพทย์":
      state = controller.getState();

      controller.setState({
        ClinicAnnualReportSequence: {
          ...state.ClinicAnnualReportSequence,
          selectedMenu: params.menu,
          doctorIncomeMonthlySummaryReport: {
            ...state.ClinicAnnualReportSequence?.doctorIncomeMonthlySummaryReport,
          },
        },
      });
      break;
    case "รายงานภาพรวมการเปลี่ยนแปลงราคาโดยแพทย์":
      state = controller.getState();

      controller.setState({
        ClinicAnnualReportSequence: {
          ...state.ClinicAnnualReportSequence,
          selectedMenu: params.menu,
          priceChangeOverviewSummaryReport: {
            ...state.ClinicAnnualReportSequence?.priceChangeOverviewSummaryReport,
          },
        },
      });
      break;
    case "รายงานการแบ่งจ่ายค่าตอบแทนตามประเภทงาน":
      state = controller.getState();

      controller.setState({
        ClinicAnnualReportSequence: {
          ...state.ClinicAnnualReportSequence,
          selectedMenu: params.menu,
          paidCompensationWorkingTypeReport: {
            ...state.ClinicAnnualReportSequence?.paidCompensationWorkingTypeReport,
          },
        },
      });
      break;
    case "รายงานสรุปรายได้ และค่าตอบแทนแพทย์":
      state = controller.getState();

      controller.setState({
        ClinicAnnualReportSequence: {
          ...state.ClinicAnnualReportSequence,
          selectedMenu: params.menu,
          doctorIncomeSummaryReport: {
            ...state.ClinicAnnualReportSequence?.doctorIncomeSummaryReport,
          },
        },
      });
      break;
    default:
      console.log("ClinicAnnual Report Seq GET_REPORT params.message: ", params.menu);
  }

  // Action(controller, { action: "FETCH_REPORT" });
};

const HandlePreviewReport: Handler = async (controller, params) => {
  console.log("Clinic Annual Report Seq PREVIEW_REPORT SEQUENCE");

  let state = controller.getState();

  // Logic เดิม
  switch (params.menu) {
    case "รายงานสรุปรายได้คลินิก":
      HandlePreviewPrintReport(controller, {
        ...params,
        reportType: state.ClinicAnnualReportSequence?.clinicIncomeReport?.reportType,
        isPreview: true,
      });
      break;
    case "รายงานผู้ป่วยค้างชำระ":
      HandlePreviewPrintReport(controller, {
        ...params,
        reportType: "none",
        isPreview: true,
      });
      break;
    case "รายงานผู้ป่วยที่ยังไม่ได้บันทึกรับชำระ":
      HandlePreviewPrintReport(controller, {
        ...params,
        reportType: "none",
        isPreview: true,
      });
      break;
    case "รายงานผู้ป่วยที่ชำระค่าบริการ":
      HandlePreviewPrintReport(controller, {
        ...params,
        reportType: "none",
        isPreview: true,
      });
      break;
    case "รายงานค่ารักษาที่แพทย์เปลี่ยนแปลงราคา":
      HandlePreviewPrintReport(controller, {
        ...params,
        reportType: "none",
        isPreview: true,
      });
      break;
    case "รายงานสรุปรายได้ทั้งเดือนส่งเบิกจ่ายแพทย์":
      HandlePreviewPrintReport(controller, { ...params, reportType: "none", isPreview: true });
      break;
    case "รายงานภาพรวมการเปลี่ยนแปลงราคาโดยแพทย์":
      HandlePreviewPrintReport(controller, {
        ...params,
        reportType: state.ClinicAnnualReportSequence?.priceChangeOverviewSummaryReport?.reportType,
        isPreview: true,
      });
      break;
    case "รายงานการแบ่งจ่ายค่าตอบแทนตามประเภทงาน":
      HandlePreviewPrintReport(controller, { ...params, reportType: "none", isPreview: true });
      break;
    case "รายงานสรุปรายได้ และค่าตอบแทนแพทย์":
      HandlePreviewPrintReport(controller, {
        ...params,
        reportType: state.ClinicAnnualReportSequence?.doctorIncomeSummaryReport?.reportType,
        isPreview: true,
      });
      break;
    default:
      console.log("Clinic Annual Report Seq GET_REPORT params.message: ", params.menu);
  }
};

const HandlePrintReport: Handler = async (controller, params) => {
  console.log("Clinic Annual Report Seq PRINT_REPORT SEQUENCE");

  let state = controller.getState();

  switch (params.menu) {
    case "รายงานสรุปรายได้คลินิก":
      HandlePreviewPrintReport(controller, {
        ...params,
        reportType: state.ClinicAnnualReportSequence?.clinicIncomeReport?.reportType,
      });
      break;
    case "รายงานผู้ป่วยค้างชำระ":
      HandlePreviewPrintReport(controller, {
        ...params,
        reportType: "none",
      });
      break;
    case "รายงานผู้ป่วยที่ยังไม่ได้บันทึกรับชำระ":
      HandlePreviewPrintReport(controller, {
        ...params,
        reportType: "none",
      });
      break;
    case "รายงานผู้ป่วยที่ชำระค่าบริการ":
      HandlePreviewPrintReport(controller, {
        ...params,
        reportType: "none",
      });
      break;
    case "รายงานค่ารักษาที่แพทย์เปลี่ยนแปลงราคา":
      HandlePreviewPrintReport(controller, {
        ...params,
        reportType: "none",
      });
      break;
    case "รายงานสรุปรายได้ทั้งเดือนส่งเบิกจ่ายแพทย์":
      HandlePreviewPrintReport(controller, { ...params, reportType: "none" });
      break;
    case "รายงานภาพรวมการเปลี่ยนแปลงราคาโดยแพทย์":
      HandlePreviewPrintReport(controller, {
        ...params,
        reportType: state.ClinicAnnualReportSequence?.priceChangeOverviewSummaryReport?.reportType,
      });
      break;
    case "รายงานการแบ่งจ่ายค่าตอบแทนตามประเภทงาน":
      HandlePreviewPrintReport(controller, { ...params, reportType: "none" });
      break;
    case "รายงานสรุปรายได้ และค่าตอบแทนแพทย์":
      HandlePreviewPrintReport(controller, {
        ...params,
        reportType: state.ClinicAnnualReportSequence?.doctorIncomeSummaryReport?.reportType,
      });
      break;
    default:
      console.log("Clinic Annual Report Seq GET_REPORT params.message: ", params.menu);
  }
};

const HandleDownloadReport: Handler<{
  menu: ReportMenu;
}> = async (controller, params) => {
  const state = controller.getState();

  const MENU_HANDLERS: Partial<
    Record<
      ReportMenu,
      {
        api: Handler<{ excel: true }, Promise<ReportReturnType<{ excel: true }>>>;
        name: string;
      }
    >
  > = {
    "รายงานสรุปรายได้ และค่าตอบแทนแพทย์": {
      api: GetReportDoctorIncomeSummary,
      name: "รายงานสรุปรายได้ทันตแพทย์",
    },
    รายงานสรุปรายได้คลินิก: {
      api: GetReportIncomeDivision,
      name: "รายงานรายได้คลินิกโดยประมาณการ",
    },
    รายงานสรุปรายได้ทั้งเดือนส่งเบิกจ่ายแพทย์: {
      api: GetReportDoctorIncomeMonthly,
      name: "รายงานสรุปรายได้ทั้งเดือนส่งเบิกจ่ายแพทย์",
    },
    รายงานการแบ่งจ่ายค่าตอบแทนตามประเภทงาน: {
      api: GetReportCompensationByExaminationType,
      name: "รายงานการแบ่งจ่ายค่าตอบแทนตามประเภทงาน",
    },
  };

  const menu = params.menu;

  if (MENU_HANDLERS[menu]) {
    const BTN_ACTION = `ClinicAnnualReport_DOWNLOAD_REPORT`;
    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTION]: "LOADING" },
    });
    const [result, error] = await MENU_HANDLERS[menu].api(controller, { excel: true });

    if (!result) {
      controller.setState({
        buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTION]: "ERROR" },
      });
      console.error("Get report failed:", error);
    } else {
      downloadXLSX(result, MENU_HANDLERS[menu].name);

      controller.setState({
        buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTION]: "SUCCESS" },
      });
    }
  }
};

const HandlePreviewPrintReport: Handler<{
  menu: ReportMenu;
  reportType?: ReportType;
  isPreview?: boolean;
}> = async (controller, params) => {
  const state = controller.getState();
  const MENU_HANDLERS: Partial<
    Record<
      ReportMenu,
      {
        api: Handler;
        forms: {
          [key in ReportType]?: (props: any) => TDocumentDefinitions;
        };
        data?: {
          [key in ReportType]?: Record<string, any>;
        };
      }
    >
  > = {
    "รายงานสรุปรายได้ และค่าตอบแทนแพทย์": {
      api: GetReportDoctorIncomeSummary,
      forms: {
        daily: FormDoctorIncomeSummaryDaily,
        monthly: FormDoctorIncomeSummary,
        yearly: FormDoctorIncomeSummary,
      },
      data: {
        monthly: { reportName: "รายงานสรุปรายได้ (ประจำเดือน)" },
        yearly: { isByYear: true, reportName: "รายงานสรุปรายได้ (ประจำปี)" },
      },
    },
    รายงานสรุปรายได้คลินิก: {
      api: GetReportIncomeDivision,
      forms: {
        daily: FormClinicIncomeReportDaily,
        monthly: FormClinicIncomeReportMonthly,
        yearly: FormClinicIncomeReportYearly,
      },
    },
    รายงานสรุปรายได้ทั้งเดือนส่งเบิกจ่ายแพทย์: {
      api: GetReportDoctorIncomeMonthly,
      forms: {
        none: FormDoctorIncomeMonthlySummaryReport,
      },
    },
    รายงานการแบ่งจ่ายค่าตอบแทนตามประเภทงาน: {
      api: GetReportCompensationByExaminationType,
      forms: {
        none: FormPaidCompensationWorkingType,
      },
    },
    รายงานผู้ป่วยค้างชำระ: {
      api: GetReportPatientPaidRisk,
      forms: {
        none: FormPatientPaidRiskReport,
      },
    },
    รายงานค่ารักษาที่แพทย์เปลี่ยนแปลงราคา: {
      api: GetReportPriceChangeRisk,
      forms: {
        none: FormPriceChangeRiskReport,
      },
    },
    รายงานภาพรวมการเปลี่ยนแปลงราคาโดยแพทย์: {
      api: GetReportPriceChangeOverviewSummary,
      forms: {
        daily: FormPriceChangeOverviewSummaryReportDaily,
        monthly: FormPriceChangeOverviewSummaryReportMonthly,
        yearly: FormPriceChangeOverviewSummaryReportYearly,
      },
    },
    รายงานผู้ป่วยที่ยังไม่ได้บันทึกรับชำระ: {
      api: GetReportPatientSaveRisk,
      forms: {
        none: FormPatientSaveRiskReport,
      },
    },
    รายงานผู้ป่วยที่ชำระค่าบริการ: {
      api: GetReportPatientPaidServices,
      forms: {
        none: FormPatientPaidServicesReport,
      },
    },
  };

  const menu = params.menu;
  const reportType = params.reportType || "none";

  if (MENU_HANDLERS[menu]) {
    const btnAction = params.isPreview
      ? `ClinicAnnualReport_PREVIEW_REPORT`
      : `ClinicAnnualReport_PRINT_REPORT`;
    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [btnAction]: "LOADING" },
    });

    const [result, error] = await MENU_HANDLERS[menu].api(controller, {});

    if (error) {
      controller.setState({
        buttonLoadCheck: { ...state.buttonLoadCheck, [btnAction]: "ERROR" },
      });

      console.error("Get report failed:", error);
    } else {
      const form = MENU_HANDLERS[menu].forms[reportType];
      const extraData = MENU_HANDLERS[menu].data?.[reportType];

      const docDef = await form?.({ ...result, ...extraData });

      if (!docDef) {
        console.error("PDF creation failed: Missing form specification.");

        return;
      }

      const pdfMake = await getPdfMake(true);

      const pdf = pdfMake.createPdf(docDef);

      controller.setState({
        buttonLoadCheck: { ...state.buttonLoadCheck, [btnAction]: "SUCCESS" },
      });

      if (params.isPreview) {
        pdf.open();

        return;
      }

      // pdf.print();
      const blob = await new Promise<Blob>((resolve) => {
        pdf.getBlob((blob) => {
          resolve(blob);
        });
      });
      printURL(URL.createObjectURL(blob));
    }
  }
};

/* ------------------------------------------------------ */

/*                          APIS                          */

/* ------------------------------------------------------ */
const GetReportDoctorIncomeSummary = async <S extends Controller, T extends { excel?: boolean }>(
  controller: S,
  params: T
): Promise<ReportReturnType<T>> => {
  const state = controller.getState();

  const filter = state.ClinicAnnualReportSequence?.doctorIncomeSummaryReport || {};

  const [result, error] = await ReportDoctorIncomeSummary.get({
    apiToken: controller.apiToken,
    params: {
      start_date: filter.filterStartDate,
      end_date: filter.filterEndDate,
      doctor: filter.doctorSelected === "ALL" ? "" : filter.doctorSelected,
      specialty: filter.specialtySelected === "ALL" ? "" : filter.specialtySelected,
      position: filter.positionSelected === "ALL" ? "" : filter.positionSelected,
      report_type: filter.reportType,
      period: filter.periodSelected || "",
      export_excel: params.excel,
    },
    extra: {
      division: controller.data.division,
      ...(params.excel && { responseType: "arraybuffer" }),
    },
  });

  if (error) {
    return [null, error];
  } else {
    if (params.excel) {
      return [result, null];
    }

    const data = { ...result.params, items: result.fields };

    return [data, null];
  }
};

const GetReportIncomeDivision = async <
  S extends Controller,
  T extends { excel?: boolean; btnActions?: any }
>(
  controller: S,
  params: T
): Promise<ReportReturnType<T>> => {
  const state = controller.getState();

  const filter = state.ClinicAnnualReportSequence?.clinicIncomeReport || {};

  const [result, error] = await ReportIncomeDivisionView.get({
    apiToken: controller.apiToken,
    params: {
      start_date: filter.filterStartDate,
      end_date: filter.filterEndDate,
      type_of_report: filter.reportType,
      encounter_type: filter.encounterType === "OPD และ IPD" ? "" : filter.encounterType,
      coverage: filter.coverageSelected === "ทุกสิทธิ" ? "" : filter.coverageSelected,
      export_excel: params.excel,
    },
    extra: {
      division: controller.data.division,
      ...(params.excel && { responseType: "arraybuffer" }),
    },
  });

  if (error) {
    return [null, error];
  } else {
    if (params.excel) {
      return [result, null];
    }

    const data = { items: { ...filter }, reportClinicIncomeData: result };

    return [data, null] as ReportReturnType<T>;
  }
};

const GetReportDoctorIncomeMonthly = async <S extends Controller, T extends { excel?: boolean }>(
  controller: S,
  params: T
): Promise<ReportReturnType<T>> => {
  const state = controller.getState();

  const filter = state.ClinicAnnualReportSequence?.doctorIncomeMonthlySummaryReport || {};

  const [result, error] = await ReportDoctorIncomeMonthlySummary.get({
    apiToken: controller.apiToken,
    params: {
      start_date: filter.filterStartDate,
      end_date: filter.filterEndDate,
      doctor: filter.doctorSelected === "ALL" ? "" : filter.doctorSelected,
      specialty: filter.specialtySelected === "ALL" ? "" : filter.specialtySelected,
      export_excel: params.excel,
    },
    extra: {
      division: controller.data.division,
      ...(params.excel && { responseType: "arraybuffer" }),
    },
  });

  if (error) {
    return [null, error];
  } else {
    if (params.excel) {
      return [result, null];
    }

    const data = { items: { ...filter }, doctorIncomeMonthlyData: result };

    return [data, null] as ReportReturnType<T>;
  }
};

const GetReportCompensationByExaminationType = async <
  S extends Controller,
  T extends { excel?: boolean }
>(
  controller: S,
  params: T
): Promise<ReportReturnType<T>> => {
  const state = controller.getState();

  const filter = state.ClinicAnnualReportSequence?.paidCompensationWorkingTypeReport || {};

  const [result, error] = await ReportCompensationByExaminationType.get({
    apiToken: controller.apiToken,
    params: {
      start_date: filter.filterStartDate,
      end_date: filter.filterEndDate,
      examination_type: filter.workingTypeSelected === "ALL" ? "" : filter.workingTypeSelected,
      export_excel: params.excel,
    },
    extra: {
      division: controller.data.division,
      ...(params.excel && { responseType: "arraybuffer" }),
    },
  });

  if (error) {
    return [null, error];
  } else {
    if (params.excel) {
      return [result, null];
    }

    const data = { items: { ...filter }, paidCompensationData: result };

    return [data, null] as ReportReturnType<T>;
  }
};

const GetReportPatientPaidRisk: Handler = async (controller) => {
  const state = controller.getState();

  const filter = state.ClinicAnnualReportSequence?.patientPaidRiskReport || {};

  const [result, error] = await ReportRiskView.get({
    apiToken: controller.apiToken,
    params: {
      start_date: filter.filterStartDate,
      end_date: filter.filterEndDate,
      coverage: filter.coverageSelected === "ทุกสิทธิ" ? "" : filter.coverageSelected,
      encounter_type: filter.encounterType === "OPD และ IPD" ? "" : filter.encounterType,
    },
    extra: {
      division: controller.data.division,
    },
  });

  if (error) {
    return [null, error];
  } else {
    const data = { items: { ...filter }, reportPaidRiskData: result };

    return [data, null];
  }
};

const GetReportPriceChangeRisk: Handler = async (controller) => {
  const state = controller.getState();

  const filter = state.ClinicAnnualReportSequence?.priceChangeRiskReport || {};

  const [result, error] = await PriceChangeRiskReport.get({
    apiToken: controller.apiToken,
    params: {
      start_date: filter.filterStartDate,
      end_date: filter.filterEndDate,
      doctor: filter.doctorSelected === "ALL" ? "" : filter.doctorSelected,
      specialty: filter.specialtySelected === "ALL" ? "" : filter.specialtySelected,
      encounter_type_label: filter.encounterType === "OPD และ IPD" ? "" : filter.encounterType,
    },
  });

  if (error) {
    return [null, error];
  } else {
    const data = { items: { ...filter }, reportPriceChangeData: result };

    return [data, null];
  }
};

const GetReportPriceChangeOverviewSummary: Handler = async (controller) => {
  const state = controller.getState();

  const filter = state.ClinicAnnualReportSequence?.priceChangeOverviewSummaryReport || {};

  const [result, error] = await PriceChangeOverviewSummary.get({
    apiToken: controller.apiToken,
    params: {
      start_date: filter.filterStartDate,
      end_date: filter.filterEndDate,
      doctor: filter.doctorSelected === "ALL" ? "" : filter.doctorSelected,
      specialty: filter.specialtySelected === "ALL" ? "" : filter.specialtySelected,
      encounter_type: filter.encounterType === "OPD และ IPD" ? "" : filter.encounterType,
      report_type: filter.reportType,
    },
    extra: {
      division: controller.data.division,
    },
  });

  if (error) {
    return [null, error];
  } else {
    const data = { items: { ...filter }, priceChangeOverviewData: result };

    return [data, null];
  }
};

const GetReportPatientSaveRisk: Handler = async (controller) => {
  const state = controller.getState();

  const filter = state.ClinicAnnualReportSequence?.patientSaveRiskReport || {};

  const [result, error] = await ReportRiskView.get({
    apiToken: controller.apiToken,
    params: {
      start_date: filter.filterStartDate,
      end_date: filter.filterEndDate,
      encounter_type: filter.encounterType === "OPD และ IPD" ? "" : filter.encounterType,
      coverage: filter.coverageSelected === "ทุกสิทธิ" ? "" : filter.coverageSelected,
      doctor: filter.doctorSelected === "ทุกแพทย์" ? "" : filter.doctorSelected,
      invoice_item_status: "BILLED",
    },
    extra: {
      division: controller.data.division,
    },
  });

  if (error) {
    return [null, error];
  } else {
    const data = { items: { ...filter }, reportSaveRiskData: result };

    return [data, null];
  }
};

const GetReportPatientPaidServices: Handler = async (controller) => {
  const state = controller.getState();

  const filter = state.ClinicAnnualReportSequence?.patientPaidServicesReport || {};

  const [result, error] = await ReportMoneyReceiveView.get({
    apiToken: controller.apiToken,
    params: {
      start_date: filter.filterStartDate,
      end_date: filter.filterEndDate,
      coverage: filter.coverageSelected === "ทุกสิทธิ" ? "" : filter.coverageSelected,
      encounter_type: filter.encounterType === "OPD และ IPD" ? "" : filter.encounterType,
    },
    extra: {
      division: controller.data.division,
    },
  });

  if (error) {
    return [null, error];
  } else {
    const data = { items: { ...filter }, reportMoReData: result };

    return [data, null];
  }
};
