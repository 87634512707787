import WasmController from "../../../../frameworks/WasmController";

// APIs
import AnesthesiaTemplateList from "issara-sdk/apis/AnesthesiaTemplateList_apps_ANS";
import DrugOrderEstimateView from "issara-sdk/apis/DrugOrderEstimateView_apps_TPD";
import SupplyOrderPreview from "issara-sdk/apis/SupplyOrderPreview_apps_MSD"; // Retrieve api with no pk
import AnesthesiaDetailDetail from "issara-sdk/apis/AnesthesiaDetailDetail_apps_ANS";
import AnesthesiaOrderBundleCreate from "issara-sdk/apis/AnesthesiaOrderBundleCreate_apps_ANS";
import SupplyOrderItemPreviewM from "issara-sdk/apis/SupplyOrderItemPreview_apps_MSDM";
import TreatmentOrderEstimate from "issara-sdk/apis/TreatmentOrderEstimate_apps_TRT";

// Interface
import { CheckHaveQuestion } from "../../TPD/TPDInterface";
import moment from "moment";

// Utils
import { formatDate } from "react-lib/utils/dateUtils";

export type State = {
  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;
  selectedEncounter?: any;
  selectedEmr?: any;
  selectedProgressCycle?: any;
  currentDoctor?: any;
  billDrugOrder?: any;
  masterOptions?: any;

  // ORQueueI
  selectedOrOrder?: any;

  // sequence
  AnesBillSequence?: {
    sequenceIndex?: string | null;

    detail?: any;
    bill?: any;
    templates?: any;
    claimQA?: Record<string, any>;
  } | null;
};

export const StateInitial: State = {
  AnesBillSequence: {
    sequenceIndex: null,

    detail: {},
    bill: {},
    templates: {},
  },
};

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const GetMaster: Handler = async (controller, params) => {
  controller.setState(
    {
      AnesBillSequence: { sequenceIndex: "Action" },
    },
    () => {
      controller.handleEvent({ message: "RunSequence", params: params });
    }
  );
};

export const Action: Handler = async (controller, params) => {
  const state = controller.getState();

  if (params?.action === "FETCH_ANES_BILL") {
    setErrMsg(controller, { ...params, msg: { buttonLoadCheck: "LOADING" } });

    const aneOrderId = state.selectedOrOrder?.other_orders?.find(
      (item: any) => item.model === "anesthesiaorder"
    )?.id;

    if (aneOrderId) {
      const [aneDataResp, aneDataErr] = await AnesthesiaDetailDetail.retrieve({
        apiToken: controller.apiToken,
        pk: aneOrderId,
      });

      const result = { ...state.AnesBillSequence, detail: {}, bill: {} };

      if (aneDataErr) {
        controller.setState(
          {
            AnesBillSequence: result,
          },
          () =>
            setErrMsg(controller, {
              ...params,
              msg: { buttonLoadCheck: "ERROR", errorMessage: aneDataErr },
            })
        );
      } else {
        result.detail = aneDataResp;

        const supplys = (aneDataResp.supply_ans_order?.items || []).map(
          (item: any, index: number) => ({
            ...item,
            index,
            quantity: item.quantity_request,
          })
        );

        const mapChk = (item: any) => ({
          ...item,
          chk: !item.quantity ? false : true,
        });

        result.bill = {
          raw_drugs: aneDataResp.drug_ans_order?.items || [],
          raw_supplys: supplys,
          drugs: (aneDataResp.drug_ans_order?.items || []).map(mapChk),
          supplys: supplys.map(mapChk),
          // or_treatments: (
          //   aneDataResp?.trt_ans_order?.[0]?.order_items || []
          // ).map((item: any) => ({
          //   ...item,
          //   chk: true,
          // })),
          or_treatments: aneDataResp?.trt_ans_order?.[0]?.order_items || [],
          drug_claim_payload: aneDataResp.drug_ans_order?.claim_payload || {},
          supply_claim_payload:
            aneDataResp.supply_ans_order?.claim_payload || {},
        };

        setErrMsg(controller, {
          ...params,
          msg: { buttonLoadCheck: "SUCCESS", errorMessage: null },
        });

        controller.setState({ AnesBillSequence: result });
      }
    } else {
      setErrMsg(controller, {
        ...params,
        msg: { buttonLoadCheck: "ERROR", errorMessage: null },
      });
    }
  } else if (params?.action === "FETCH_ANES_TEMPLATE") {
    const [aneTemplateResp, aneTemplateErr] = await AnesthesiaTemplateList.list(
      {
        apiToken: controller.apiToken,
      }
    );

    if (aneTemplateErr) {
      controller.setState({
        AnesBillSequence: { ...state.AnesBillSequence, templates: {} },
      });
    } else {
      controller.setState({
        AnesBillSequence: {
          ...state.AnesBillSequence,
          templates: aneTemplateResp,
        },
      });
    }
  } else if (params.action === "CHANGE_BILL") {
    controller.setState({
      AnesBillSequence: {
        ...state.AnesBillSequence,
        bill: { ...params.bill },
      },
    });
  } else if (params.action === "CALCULATE") {
    setErrMsg(controller, { ...params, msg: { buttonLoadCheck: "LOADING" } });

    let bill = state.AnesBillSequence?.bill || {};
    let drugs: any[] = (bill.drugs || []).filter((item: any) => item.chk);
    const supplys: any[] = (bill.supplys || []).filter((item: any) => item.chk);
    const treatments: any[] = (bill.or_treatments || []).filter(
      (item: any) => item.chk
    );

    let estimate: any = [null, null, null];

    // Drug Estimate
    if (!!drugs.length) {
      drugs.forEach((d:any)=> {
        d.item_id = d.id;
        delete d.id
      });
      estimate = await DrugOrderEstimateView.post({
        apiToken: controller.apiToken,
        data: {
          items: drugs,
          drug_type: "ANESTHESIA",
          encounter: state.selectedEncounter.id,
          emr: state.selectedEmr.id,
          progression_cycle: state.selectedProgressCycle?.id,
          claim_payload: {},
        },
        extra: {
          division: controller.data.division,
        },
      });

      if (!estimate[0]) {
        return setErrMsg(controller, {
          ...params,
          msg: { buttonLoadCheck: "ERROR", errorMessage: estimate[1] },
        });
      } else {
        estimate[0]?.items?.forEach((d: any) => {
          d.id = d.item_id;
        });
      }
    }

    // Supplys
    const previewList: any[] = [];

    if (!!supplys.length) {
      const promiseArr = supplys.map((item) =>
        SupplyOrderItemPreviewM.retrieve({
          params: {
            change: "quantity_request",
            encounter: state.selectedEncounter.id,
            mode: "",
            product: item.product,
            product_issue: "",
            quantity_request: item.quantity,
            quantity_issue: "",
            quantity_postpone: "",
            eligibility_type: 1,
            qa_claim_id: 0,
            index: item.index,
          },
          extra: { division: controller.data.division },
          apiToken: controller.apiToken,
        }).then((res: any) => [
          { ...(res[0] || {}), index: item.index },
          res[1],
        ])
      );

      const response = await Promise.all(promiseArr);
      const error = response.find((res) => res[1]);

      if (!!error) {
        setErrMsg(controller, {
          ...params,
          msg: { buttonLoadCheck: "ERROR", errorMessage: error },
        });
      } else {
        previewList.push(...response.map((res) => res[0]));
      }
    }

    let preview: any = [null, null, null];

    // Supply Estimate
    if (!!previewList.length) {
      preview = await SupplyOrderPreview.create({
        data: {
          action: "REQUEST",
          encounter: state.selectedEncounter?.id,
          items: previewList,
          status: "",
          type: `REFILL_${state.selectedEncounter?.type}`,
          claim_payload: {},
        } as any,
        extra: { division: controller.data.division },
        apiToken: controller.apiToken,
      });

      if (!preview[0]) {
        return setErrMsg(controller, {
          ...params,
          msg: { buttonLoadCheck: "ERROR", errorMessage: preview[1] },
        });
      }
    }

    // Treatments
    let treatmentsList: any = [null, null, null];
    const orderItems: any[] = [];
    if (!!treatments.length) {
      treatments.forEach((item: any) =>
        orderItems.push({
          id: item?.id,
          product: item?.product,
          quantity: item?.quantity,
          eligibility_type: item?.eligibility_type_value,
        })
      );
      
    treatmentsList = await TreatmentOrderEstimate.post({
      data: {
        encounter: state.selectedEncounter?.id,
        have_compensation: false,
        order_items: orderItems,
      },
      apiToken: controller.apiToken,
      extra: { division: controller.data?.division },
    });
    }
    // Set Items
    const est = estimate?.[0] || {};
    const pEst = preview?.[0] || {};

    bill = {
      ...bill,
      is_calculate: true,
      supplys: bill.raw_supplys.map((item: any) => {
        const find = preview?.[0]?.items?.find(
          (acc: any) => acc.product === item.product
        );
        return !!find
          ? {
              ...item,
              ...find,
              chk: true,
              quantity: find.quantity_request,
            }
          : item;
      }),
      drugs: bill.raw_drugs.map((item: any) => {
        const find = est.items?.find((acc: any) => acc.id === item.id);
        return !!find ? { ...item, ...find } : item;
      }),
      or_treatments: treatmentsList?.[0]?.orders.map(
        (item: any, index: any) => {
          return {
            ...bill.or_treatments?.[index],
            price_claimable: item?.price_claimable,
            price_non_claimable: item?.price_non_claimable,
          };
        }
      ),
      drug_claim_payload: est.claim_payload,
      supply_claim_payload: pEst?.claim_payload || {},
    };

    setErrMsg(controller, {
      ...params,
      msg: { buttonLoadCheck: "SUCCESS", errorMessage: null },
    });

    // check คำถาม
    const claimQA = {
      supply: {
        estimate: pEst,
        payload: {},
        index: pEst.items?.length - 1,
        isAdd: true,
        stateKey: "billDrugOrder",
        isSupply: true,
      },
    };

    controller.setState(
      {
        AnesBillSequence: {
          ...state.AnesBillSequence,
          claimQA,
        },
        billDrugOrder: {
          claim_payload: est.claim_payload,
          items: est.items?.map((item: any) => ({
            ...item,
            estimate: est,
          })),
        },
      },
      () => {
        CheckHaveQuestion(controller as any, {
          estimate: est,
          payload: {},
          index: est.items?.length - 1,
          isAdd: true,
          stateKey: "billDrugOrder",
        });
        Action(controller, { bill, action: "CHANGE_BILL" });
      }
    );
  } else if (params.action === "PREPARE_SAVE") {
    const bill = state.AnesBillSequence?.bill || {};
    const drugs: any[] = bill?.drugs.filter((item: any) => item.chk);
    const supplys: any[] = bill?.supplys.filter((item: any) => item.chk);
    const or_treatments: any[] = bill?.or_treatments?.filter(
      (item: any) => item.chk
    ) || [];
    const detail = state.AnesBillSequence?.detail || {};

    const aneOrderId = state.selectedOrOrder?.other_orders?.find(
      (item: any) => item.model === "anesthesiaorder"
    )?.id;

    const mapOrder = (item: any) => {
      delete item.id;
      return item;
    };

    let data: Record<string, any> = {};
    const update = {
      action: "EDIT",
      order_time: `${formatDate(moment())}-${moment().format("HH:mm")}`,
      order_receive_time: moment().toISOString(),
      encounter: state.selectedEncounter?.id,
    };

    // Update
    if (detail.id) {
      data = {
        ...detail,
        drug_ans_order: {
          ...detail.drug_ans_order,
          ...update,
          ref_doctor_order: detail.anesthesia_order,
          claim_payload: bill.drug_claim_payload,
          items: bill.id ? drugs.map(mapOrder) : drugs,
        },
        drug_ans_order_id: detail.drug_ans_order?.id,
        supply_ans_order: {
          ...detail.supply_ans_order,
          ...update,
          claim_payload: bill.supply_claim_payload,
          items: bill.id ? supplys.map(mapOrder) : supplys,
        },
        supply_ans_order_id: detail.supply_ans_order?.id,
        trt_ans_order:
          or_treatments.length > 0
            ? [
                {
                  ...detail.trt_ans_order[0],
                  ...update,
                  action:
                    !or_treatments.length ||
                    !detail.trt_ans_order[0].order_items?.length
                      ? "ORDER"
                      : "EDIT",
                  order_items: or_treatments.map((acc: any) => ({
                    ...acc,
                    eligibility_type: acc.eligibility_type_value
                      ? Number(acc.eligibility_type_value)
                      : acc.eligibility_type,
                  })),
                },
              ]
            : [],
      };
    }
    // Create
    else {
      data = {
        anesthesia_order: aneOrderId,
        anes_template: bill.id,
      };
      if (drugs.length > 0) {
        data.drug_ans_order = {
          ref_doctor_order: aneOrderId,
          encounter: state.selectedEncounter?.id,
          emr: state.selectedEmr.id,
          progression_cycle: state.selectedProgressCycle?.id,
          action: "REQUEST",
          order_div: controller.data.division,
          order_by: state.currentDoctor?.id || state.selectedEncounter?.doctor,
          items: drugs.map(mapOrder),
          claim_payload: bill.drug_claim_payload,
        };
      }
      if (supplys.length > 0) {
        data.supply_ans_order = {
          action: "REQUEST",
          encounter: state.selectedEncounter?.id,
          type: `REFILL_${state.selectedEncounter?.type}`,
          claim_payload: bill.supply_claim_payload,
          items: supplys.map(mapOrder),
        };
      }
      if (or_treatments.length > 0) {
        const trt = or_treatments[0];
        data.trt_ans_order = [
          {
            action: "ORDER",
            core_procedure: [
              {
                emr: state.selectedEmr?.id,
                icd9cm: trt?.icd9cm,
                icd9cm_code: trt?.code,
                icd9cm_term: trt?.term,
                icd9cm_med_term: trt?.icd9cm_med_term,
                medical_description: trt?.medical_description || "",
                type: "PRIMARY",
              },
            ],
            encounter: state.selectedEncounter?.id,
            emr: state.selectedEmr.id,
            equipment_detail: trt?.equipment_detail,
            order_detail: trt?.order_detail,
            // 'to_be_performer': "DOCTOR" || "NURSE" || "OTHER",
            to_be_performer: "DOCTOR",
            order_items: or_treatments.map((acc: any) => ({
              ...acc,
              eligibility_type: Number(acc.eligibility_type_value),
            })),
          },
        ];
      }
    }

    Action(controller, { ...params, data, action: "SAVE" });
  } else if (params?.action === "SAVE") {
    setErrMsg(controller, { ...params, msg: { buttonLoadCheck: "LOADING" } });

    const aneOrderId = state.selectedOrOrder?.other_orders?.find(
      (item: any) => item.model === "anesthesiaorder"
    )?.id;

    if (!aneOrderId) {
      return setErrMsg(controller, {
        ...params,
        msg: { buttonLoadCheck: "ERROR", errorMessage: "ไม่มี ANES ORDER" },
      });
    }

    let response: any = [null, null, null];

    // Update
    if (state.AnesBillSequence?.detail?.id) {
      response = await AnesthesiaOrderBundleCreate.put({
        apiToken: controller.apiToken,
        // pk: state.AnesBillSequence?.detail?.ans_bill?.id,
        data: params.data,
        extra: { division: controller.data.division },
      });
    }
    // Create
    else {
      response = await AnesthesiaOrderBundleCreate.post({
        apiToken: controller.apiToken,
        data: params.data,
        extra: { division: controller.data.division },
      });
    }

    if (response[1]) {
      return setErrMsg(controller, {
        ...params,
        msg: { buttonLoadCheck: "ERROR", errorMessage: response[1] },
      });
    } else {
      setErrMsg(controller, {
        ...params,
        msg: {
          buttonLoadCheck: "SUCCESS",
          errorMessage: null,
          successMessage: `${params.action} Success`,
        },
      });

      Action(controller, { ...params, action: "FETCH_ANES_BILL" });
    }
  }
};

// Utils
const setErrMsg: Handler = (controller, params) => {
  const state = controller.getState();
  const fields = ["buttonLoadCheck", "errorMessage", "successMessage"];
  const data: Record<string, any> = {};

  const keys = {
    buttonLoadCheck: `${params.cardKey}_${params.action}`,
    errorMessage: params.cardKey,
    successMessage: params.cardKey,
  };

  for (const key in params.msg) {
    if (fields.includes(key)) {
      data[key] = {
        ...(state as any)[key],
        [(keys as any)[key]]: params.msg[key],
      };
    }
  }

  controller.setState(data);
};
