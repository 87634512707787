import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Dropdown,
  Form,
  Input
} from 'semantic-ui-react'

const CardPediatricDoseUX = (props: any) => {
    return(
      <div
        style={{width: "100%", lineHeight: "1em"}}>
        <div
          style={{ height: "48px", padding: "1px", background: "#1AACFF24" }}>
          
          <Form>
            <FormGroup>
              <FormField>
                <label>
                  เลือกช่วงวัยสำหรับการคำนวณ : 
                </label>
              </FormField>
              <FormField>
                <Dropdown
                  onChange={props.handleChangeAge}
                  options={props.ageOptions || []}
                  selection={true}
                  value={props.ageValue || ""}>
                </Dropdown>
              </FormField>
              <FormField>
                <label>
                  ระบุข้อบ่งใช้ :
                </label>
              </FormField>
              <FormField
                width="5">
                <Dropdown
                  onChange={props.handleChangeSelectedItem}
                  options={props.pediatricData?.regimenOpts || []}
                  selection={true}
                  value={props.pediatricData?.regimenSelectedId || ""}>
                </Dropdown>
              </FormField>
            </FormGroup>
          </Form>
        </div>
        <div
          style={{height:"40px", display: "flex", justifyContent: "center", alignItems: "center", border: "1px solid #0D71BB", padding: "8px", margin: "14px 0px 0px 14px"}}>
          
          <label
            style={{height:"100%", display: "block", textAlign: "center"}}>
            {props.pediatricData?.selectedItem?.recommendation || ""}
          </label>
        </div>
        <div
          style={{ display: props.pediatricData?.selectedItem?.calculation_factor_name === "WEIGHT"  ?  "flex" : "none" }}>
          
          <label
            style={{height:"40px", display: "flex", justifyContent: "center", alignItems: "center", padding: "8px", margin: "14px 0px 0px 14px"}}>
            ระบุน้ำหนักผู้ป่วย ณ วันที่สั่งยา
          </label>
          <Input
            onChange={props.handleChangeWeight}
            value={props.inputWeight ? props.inputWeight : props.pediatricData?.selectedItem?.encounter_weight}>
          </Input>
          <label
            style={{height:"40px", display: "flex", justifyContent: "center", alignItems: "center", padding: "8px", margin: "14px 0px 0px 14px"}}>
            กิโลกรัม
          </label>
          <label
            style={{ height:"40px", justifyContent: "center", alignItems: "center", padding: "8px", margin: "14px 0px 0px 14px",  display: props.pediatricData?.selectedItem?.encounter_weight!=="" ?  "none" : "none" }}>
            {props.pediatricData?.selectedItem?.encounter_weight ? "encounter weight: "+props.pediatricData?.selectedItem?.encounter_weight : ""}
          </label>
          <div
            style={{height:"40px", display: "flex", justifyContent: "center", alignItems: "center", padding: "8px", margin: "14px 0px 0px 14px"}}>
            {props.calculationButton}
          </div>
        </div>
        <div
          style={{ display: "flex" }}>
          
          <label
            style={{color:"#0D71BB", fontWeight:"bold", display: "flex", justifyContent: "center", alignItems: "center", padding: "0px", margin: "1px 0px 0px 14px"}}>
            ขนาดยาต่ำสุด
          </label>
        </div>
        <div
          style={{ display: "flex", flexDirection: props.pediatricData?.selectedItem?.type_of_total_dose_name==="PER_DOSE" ? "column-reverse" : "column" }}>
          
          <div
            style={{ display: props.pediatricData?.selectedItem?.min_per_day > 0 ?  "flex" : "none" }}>
            
            <label
              style={{width: "80px", display: "flex", justifyContent: "right", alignItems: "center", padding: "0px", margin: "14px 0px 0px 14px"}}>
              {props.pediatricData?.selectedItem?.type_of_total_dose_name==="PER_DOSE" ?  "คิดเป็น" : ""}
            </label>
            <label
              style={{fontWeight:"bold", textDecoration: "underline", display: "flex", justifyContent: "center", alignItems: "center", padding: "4px", margin: "14px 0px 0px 0px"}}>
              ต่อวัน
            </label>
            <label
              style={{display: "flex", justifyContent: "center", alignItems: "center", padding: "4px", margin: "14px 0px 0px 0px"}}>
              ที่แนะนำ เท่ากับ
            </label>
            <Input
              value={props.pediatricData?.selectedItem?.min_per_day || ""}>
            </Input>
            <label
              style={{display: "flex", justifyContent: "center", alignItems: "center", padding: "8px", margin: "14px 0px 0px 0px"}}>
              {props.pediatricData?.selectedItem?.unit_of_result_name || ""}
            </label>
            <div
              style={{ display: props.pediatricData?.selectedItem?.min_per_day_liquid > 0 ?  "flex" : "none" }}>
              
              <label
                style={{color:"#0D71BB", fontWeight:"normal", display: "flex", justifyContent: "center", alignItems: "center", padding: "0px", margin: "14px 14px 0px 14px"}}>
                เท่ากับ
              </label>
              <Input
                value={props.pediatricData?.selectedItem?.min_per_day_liquid || ""}>
              </Input>
              <label
                style={{color:"#0D71BB", fontWeight:"normal", display: "flex", justifyContent: "center", alignItems: "center", padding: "0px", margin: "14px 0px 0px 14px"}}>
                {props.pediatricData?.selectedItem?.unit_of_liquid_result_name || ""}
              </label>
            </div>
          </div>
          <div
            style={{ display: props.pediatricData?.selectedItem?.min_per_dose > 0 ?  "flex" : "none"  }}>
            
            <label
              style={{width: "80px", display: "flex", justifyContent: "right", alignItems: "center", padding: "0px", margin: "14px 0px 0px 14px"}}>
              {props.pediatricData?.selectedItem?.type_of_total_dose_name==="PER_DOSE" ? "" : "คิดเป็น "}
            </label>
            <label
              style={{fontWeight:"bold", textDecoration: "underline", display: "flex", justifyContent: "center", alignItems: "center", padding: "4px", margin: "14px 0px 0px 0px"}}>
              ต่อครั้ง
            </label>
            <label
              style={{display: "flex", justifyContent: "center", alignItems: "center", padding: "4px", margin: "14px 0px 0px 0px"}}>
              ที่แนะนำ เท่ากับ
            </label>
            <Input
              value={props.pediatricData?.selectedItem?.min_per_dose || ""}>
            </Input>
            <label
              style={{display: "flex", justifyContent: "center", alignItems: "center", padding: "8px", margin: "14px 0px 0px 0px"}}>
              {props.pediatricData?.selectedItem?.unit_of_result_name || ""}
            </label>
            <div
              style={{ display: props.pediatricData?.selectedItem?.min_per_dose_liquid > 0 ?  "flex" : "none" }}>
              
              <label
                style={{color:"#0D71BB", fontWeight:"normal", display: "flex", justifyContent: "center", alignItems: "center", padding: "0px", margin: "14px 14px 0px 14px"}}>
                เท่ากับ
              </label>
              <Input
                value={props.pediatricData?.selectedItem?.min_per_dose_liquid || ""}>
              </Input>
              <label
                style={{color:"#0D71BB", fontWeight:"normal", display: "flex", justifyContent: "center", alignItems: "center", padding: "0px", margin: "14px 0px 0px 14px"}}>
                {props.pediatricData?.selectedItem?.unit_of_liquid_result_name || ""}
              </label>
            </div>
          </div>
        </div>
        <div
          style={{padding: "1px"}}>
          
          <hr>
          </hr>
        </div>
        <div
          style={{ display: "flex" }}>
          
          <label
            style={{color:"#0D71BB", fontWeight:"bold", display: "flex", justifyContent: "center", alignItems: "center", padding: "0px", margin: "1px 0px 0px 14px"}}>
            ขนาดยาสูงสุด
          </label>
        </div>
        <div
          style={{ display: "flex", flexDirection: props.pediatricData?.selectedItem?.type_of_total_dose_name==="PER_DOSE" ? "column-reverse" : "column" }}>
          
          <div
            style={{ display: props.pediatricData?.selectedItem?.max_per_day > 0 ?  "flex" : "none" }}>
            
            <label
              style={{width: "80px", display: "flex", justifyContent: "right", alignItems: "center", padding: "0px", margin: "14px 0px 0px 14px"}}>
              {props.pediatricData?.selectedItem?.type_of_total_dose_name==="PER_DOSE" ?  "คิดเป็น" : ""}
            </label>
            <label
              style={{fontWeight:"bold", textDecoration: "underline", display: "flex", justifyContent: "center", alignItems: "center", padding: "4px", margin: "14px 0px 0px 0px"}}>
              ต่อวัน
            </label>
            <label
              style={{display: "flex", justifyContent: "center", alignItems: "center", padding: "4px", margin: "14px 0px 0px 0px"}}>
              ที่แนะนำ เท่ากับ
            </label>
            <Input
              value={props.pediatricData?.selectedItem?.max_per_day || ""}>
            </Input>
            <label
              style={{display: "flex", justifyContent: "center", alignItems: "center", padding: "8px", margin: "14px 0px 0px 0px"}}>
              {props.pediatricData?.selectedItem?.unit_of_result_name || ""}
            </label>
            <div
              style={{ display: props.pediatricData?.selectedItem?.max_per_day_liquid > 0 ?  "flex" : "none" }}>
              
              <label
                style={{color:"#0D71BB", fontWeight:"normal", display: "flex", justifyContent: "center", alignItems: "center", padding: "0px", margin: "14px 14px 0px 14px"}}>
                เท่ากับ
              </label>
              <Input
                value={props.pediatricData?.selectedItem?.max_per_day_liquid || ""}>
              </Input>
              <label
                style={{color:"#0D71BB", fontWeight:"normal", display: "flex", justifyContent: "center", alignItems: "center", padding: "0px", margin: "14px 0px 0px 14px"}}>
                {props.pediatricData?.selectedItem?.unit_of_liquid_result_name || ""}
              </label>
            </div>
          </div>
          <div
            style={{ display: props.pediatricData?.selectedItem?.max_per_dose > 0 ?  "flex" : "none" }}>
            
            <label
              style={{width: "80px", display: "flex", justifyContent: "right", alignItems: "center", padding: "0px", margin: "14px 0px 0px 14px"}}>
              {props.pediatricData?.selectedItem?.type_of_total_dose_name==="PER_DOSE" ? "" : "คิดเป็น "}
            </label>
            <label
              style={{fontWeight:"bold", textDecoration: "underline", display: "flex", justifyContent: "center", alignItems: "center", padding: "4px", margin: "14px 0px 0px 0px"}}>
              ต่อครั้ง
            </label>
            <label
              style={{display: "flex", justifyContent: "center", alignItems: "center", padding: "4px", margin: "14px 0px 0px 0px"}}>
              ที่แนะนำ เท่ากับ
            </label>
            <Input
              value={props.pediatricData?.selectedItem?.max_per_dose || ""}>
            </Input>
            <label
              style={{display: "flex", justifyContent: "center", alignItems: "center", padding: "8px", margin: "14px 0px 0px 0px"}}>
              {props.pediatricData?.selectedItem?.unit_of_result_name || ""}
            </label>
            <div
              style={{ display: props.pediatricData?.selectedItem?.max_per_dose_liquid > 0 ?  "flex" : "none" }}>
              
              <label
                style={{color:"#0D71BB", fontWeight:"normal", display: "flex", justifyContent: "center", alignItems: "center", padding: "0px", margin: "14px 14px 0px 14px"}}>
                เท่ากับ
              </label>
              <Input
                value={props.pediatricData?.selectedItem?.max_per_dose_liquid || ""}>
              </Input>
              <label
                style={{color:"#0D71BB", fontWeight:"normal", display: "flex", justifyContent: "center", alignItems: "center", padding: "0px", margin: "14px 0px 0px 14px"}}>
                {props.pediatricData?.selectedItem?.unit_of_liquid_result_name || ""}
              </label>
            </div>
          </div>
        </div>
      </div>
    )
}


export default CardPediatricDoseUX

export const screenPropsDefault = {}

/* Date Time : Wed Nov 20 2024 12:26:48 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width: \"100%\", lineHeight: \"1em !important\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ height: \"48px\", padding: \"1px\", background: \"#1AACFF24\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.pediatricData?.selectedItem?.calculation_factor_name === \"WEIGHT\"  ?  \"flex\" : \"none\" }"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormGroup",
      "parent": 14,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormField",
      "parent": 6,
      "props": {
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "FormField",
      "parent": 6,
      "props": {
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "FormField",
      "parent": 6,
      "props": {
        "width": {
          "type": "value",
          "value": "5"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "Dropdown",
      "parent": 8,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeAge"
        },
        "options": {
          "type": "code",
          "value": "props.ageOptions || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ageValue || \"\""
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "label",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "เลือกช่วงวัยสำหรับการคำนวณ : "
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Form",
      "parent": 2,
      "props": {
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "label",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุข้อบ่งใช้ :"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "Dropdown",
      "parent": 10,
      "props": {
        "fluid": {
          "type": "code",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeSelectedItem"
        },
        "options": {
          "type": "code",
          "value": "props.pediatricData?.regimenOpts || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.pediatricData?.regimenSelectedId || \"\""
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "label",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุน้ำหนักผู้ป่วย ณ วันที่สั่งยา"
        },
        "style": {
          "type": "code",
          "value": "{height:\"40px\", display: \"flex\", justifyContent: \"center\", alignItems: \"center\", padding: \"8px\", margin: \"14px 0px 0px 14px\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "label",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "กิโลกรัม"
        },
        "style": {
          "type": "code",
          "value": "{height:\"40px\", display: \"flex\", justifyContent: \"center\", alignItems: \"center\", padding: \"8px\", margin: \"14px 0px 0px 14px\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "Input",
      "parent": 4,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeWeight"
        },
        "value": {
          "type": "code",
          "value": "props.inputWeight ? props.inputWeight : props.pediatricData?.selectedItem?.encounter_weight"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "code",
          "value": "props.calculationButton"
        },
        "style": {
          "type": "code",
          "value": "{height:\"40px\", display: \"flex\", justifyContent: \"center\", alignItems: \"center\", padding: \"8px\", margin: \"14px 0px 0px 14px\"}"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height:\"40px\", display: \"flex\", justifyContent: \"center\", alignItems: \"center\", border: \"1px solid #0D71BB\", padding: \"8px\", margin: \"14px 0px 0px 14px\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "label",
      "parent": 23,
      "props": {
        "children": {
          "type": "code",
          "value": "props.pediatricData?.selectedItem?.recommendation || \"\""
        },
        "style": {
          "type": "code",
          "value": "{height:\"100%\", display: \"block\", textAlign: \"center\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 90,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.pediatricData?.selectedItem?.min_per_day > 0 ?  \"flex\" : \"none\" }"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "label",
      "parent": 25,
      "props": {
        "children": {
          "type": "code",
          "value": "props.pediatricData?.selectedItem?.type_of_total_dose_name===\"PER_DOSE\" ?  \"คิดเป็น\" : \"\""
        },
        "style": {
          "type": "code",
          "value": "{width: \"80px\", display: \"flex\", justifyContent: \"right\", alignItems: \"center\", padding: \"0px\", margin: \"14px 0px 0px 14px\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "Input",
      "parent": 25,
      "props": {
        "value": {
          "type": "code",
          "value": "props.pediatricData?.selectedItem?.min_per_day || \"\""
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "label",
      "parent": 25,
      "props": {
        "children": {
          "type": "code",
          "value": "props.pediatricData?.selectedItem?.unit_of_result_name || \"\""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"center\", alignItems: \"center\", padding: \"8px\", margin: \"14px 0px 0px 0px\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "label",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": "ต่อวัน"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bold\", textDecoration: \"underline\", display: \"flex\", justifyContent: \"center\", alignItems: \"center\", padding: \"4px\", margin: \"14px 0px 0px 0px\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "label",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": "ที่แนะนำ เท่ากับ"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"center\", alignItems: \"center\", padding: \"4px\", margin: \"14px 0px 0px 0px\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "div",
      "parent": 90,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.pediatricData?.selectedItem?.min_per_dose > 0 ?  \"flex\" : \"none\"  }"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "label",
      "parent": 35,
      "props": {
        "children": {
          "type": "code",
          "value": "props.pediatricData?.selectedItem?.type_of_total_dose_name===\"PER_DOSE\" ? \"\" : \"คิดเป็น \""
        },
        "style": {
          "type": "code",
          "value": "{width: \"80px\", display: \"flex\", justifyContent: \"right\", alignItems: \"center\", padding: \"0px\", margin: \"14px 0px 0px 14px\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "label",
      "parent": 35,
      "props": {
        "children": {
          "type": "value",
          "value": "ต่อครั้ง"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bold\", textDecoration: \"underline\", display: \"flex\", justifyContent: \"center\", alignItems: \"center\", padding: \"4px\", margin: \"14px 0px 0px 0px\"}"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "label",
      "parent": 35,
      "props": {
        "children": {
          "type": "value",
          "value": "ที่แนะนำ เท่ากับ"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"center\", alignItems: \"center\", padding: \"4px\", margin: \"14px 0px 0px 0px\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "Input",
      "parent": 35,
      "props": {
        "value": {
          "type": "code",
          "value": "props.pediatricData?.selectedItem?.min_per_dose || \"\""
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "label",
      "parent": 35,
      "props": {
        "children": {
          "type": "code",
          "value": "props.pediatricData?.selectedItem?.unit_of_result_name || \"\""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"center\", alignItems: \"center\", padding: \"8px\", margin: \"14px 0px 0px 0px\"}"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"1px\"}"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "hr",
      "parent": 46,
      "props": {
      },
      "seq": 47,
      "void": true
    },
    {
      "from": null,
      "id": 48,
      "name": "div",
      "parent": 89,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.pediatricData?.selectedItem?.max_per_day > 0 ?  \"flex\" : \"none\" }"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "label",
      "parent": 48,
      "props": {
        "children": {
          "type": "code",
          "value": "props.pediatricData?.selectedItem?.type_of_total_dose_name===\"PER_DOSE\" ?  \"คิดเป็น\" : \"\""
        },
        "style": {
          "type": "code",
          "value": "{width: \"80px\", display: \"flex\", justifyContent: \"right\", alignItems: \"center\", padding: \"0px\", margin: \"14px 0px 0px 14px\"}"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "label",
      "parent": 48,
      "props": {
        "children": {
          "type": "value",
          "value": "ต่อวัน"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bold\", textDecoration: \"underline\", display: \"flex\", justifyContent: \"center\", alignItems: \"center\", padding: \"4px\", margin: \"14px 0px 0px 0px\"}"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "label",
      "parent": 48,
      "props": {
        "children": {
          "type": "value",
          "value": "ที่แนะนำ เท่ากับ"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"center\", alignItems: \"center\", padding: \"4px\", margin: \"14px 0px 0px 0px\"}"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "Input",
      "parent": 48,
      "props": {
        "value": {
          "type": "code",
          "value": "props.pediatricData?.selectedItem?.max_per_day || \"\""
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "label",
      "parent": 48,
      "props": {
        "children": {
          "type": "code",
          "value": "props.pediatricData?.selectedItem?.unit_of_result_name || \"\""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"center\", alignItems: \"center\", padding: \"8px\", margin: \"14px 0px 0px 0px\"}"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 89,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.pediatricData?.selectedItem?.max_per_dose > 0 ?  \"flex\" : \"none\" }"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "label",
      "parent": 58,
      "props": {
        "children": {
          "type": "code",
          "value": "props.pediatricData?.selectedItem?.type_of_total_dose_name===\"PER_DOSE\" ? \"\" : \"คิดเป็น \""
        },
        "style": {
          "type": "code",
          "value": "{width: \"80px\", display: \"flex\", justifyContent: \"right\", alignItems: \"center\", padding: \"0px\", margin: \"14px 0px 0px 14px\"}"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "label",
      "parent": 58,
      "props": {
        "children": {
          "type": "value",
          "value": "ต่อครั้ง"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bold\", textDecoration: \"underline\", display: \"flex\", justifyContent: \"center\", alignItems: \"center\", padding: \"4px\", margin: \"14px 0px 0px 0px\"}"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "label",
      "parent": 58,
      "props": {
        "children": {
          "type": "value",
          "value": "ที่แนะนำ เท่ากับ"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"center\", alignItems: \"center\", padding: \"4px\", margin: \"14px 0px 0px 0px\"}"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "Input",
      "parent": 58,
      "props": {
        "value": {
          "type": "code",
          "value": "props.pediatricData?.selectedItem?.max_per_dose || \"\""
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "label",
      "parent": 58,
      "props": {
        "children": {
          "type": "code",
          "value": "props.pediatricData?.selectedItem?.unit_of_result_name || \"\""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"center\", alignItems: \"center\", padding: \"8px\", margin: \"14px 0px 0px 0px\"}"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "label",
      "parent": 68,
      "props": {
        "children": {
          "type": "value",
          "value": "ขนาดยาต่ำสุด"
        },
        "style": {
          "type": "code",
          "value": "{color:\"#0D71BB\", fontWeight:\"bold\", display: \"flex\", justifyContent: \"center\", alignItems: \"center\", padding: \"0px\", margin: \"1px 0px 0px 14px\"}"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "label",
      "parent": 70,
      "props": {
        "children": {
          "type": "value",
          "value": "ขนาดยาสูงสุด"
        },
        "style": {
          "type": "code",
          "value": "{color:\"#0D71BB\", fontWeight:\"bold\", display: \"flex\", justifyContent: \"center\", alignItems: \"center\", padding: \"0px\", margin: \"1px 0px 0px 14px\"}"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "div",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.pediatricData?.selectedItem?.min_per_day_liquid > 0 ?  \"flex\" : \"none\" }"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "label",
      "parent": 72,
      "props": {
        "children": {
          "type": "value",
          "value": "เท่ากับ"
        },
        "style": {
          "type": "code",
          "value": "{color:\"#0D71BB\", fontWeight:\"normal\", display: \"flex\", justifyContent: \"center\", alignItems: \"center\", padding: \"0px\", margin: \"14px 14px 0px 14px\"}"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "Input",
      "parent": 72,
      "props": {
        "value": {
          "type": "code",
          "value": "props.pediatricData?.selectedItem?.min_per_day_liquid || \"\""
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "label",
      "parent": 72,
      "props": {
        "children": {
          "type": "code",
          "value": "props.pediatricData?.selectedItem?.unit_of_liquid_result_name || \"\""
        },
        "style": {
          "type": "code",
          "value": "{color:\"#0D71BB\", fontWeight:\"normal\", display: \"flex\", justifyContent: \"center\", alignItems: \"center\", padding: \"0px\", margin: \"14px 0px 0px 14px\"}"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "div",
      "parent": 35,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.pediatricData?.selectedItem?.min_per_dose_liquid > 0 ?  \"flex\" : \"none\" }"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "label",
      "parent": 76,
      "props": {
        "children": {
          "type": "value",
          "value": "เท่ากับ"
        },
        "style": {
          "type": "code",
          "value": "{color:\"#0D71BB\", fontWeight:\"normal\", display: \"flex\", justifyContent: \"center\", alignItems: \"center\", padding: \"0px\", margin: \"14px 14px 0px 14px\"}"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "Input",
      "parent": 76,
      "props": {
        "value": {
          "type": "code",
          "value": "props.pediatricData?.selectedItem?.min_per_dose_liquid || \"\""
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "label",
      "parent": 76,
      "props": {
        "children": {
          "type": "code",
          "value": "props.pediatricData?.selectedItem?.unit_of_liquid_result_name || \"\""
        },
        "style": {
          "type": "code",
          "value": "{color:\"#0D71BB\", fontWeight:\"normal\", display: \"flex\", justifyContent: \"center\", alignItems: \"center\", padding: \"0px\", margin: \"14px 0px 0px 14px\"}"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "div",
      "parent": 48,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.pediatricData?.selectedItem?.max_per_day_liquid > 0 ?  \"flex\" : \"none\" }"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "label",
      "parent": 80,
      "props": {
        "children": {
          "type": "value",
          "value": "เท่ากับ"
        },
        "style": {
          "type": "code",
          "value": "{color:\"#0D71BB\", fontWeight:\"normal\", display: \"flex\", justifyContent: \"center\", alignItems: \"center\", padding: \"0px\", margin: \"14px 14px 0px 14px\"}"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "Input",
      "parent": 80,
      "props": {
        "value": {
          "type": "code",
          "value": "props.pediatricData?.selectedItem?.max_per_day_liquid || \"\""
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "label",
      "parent": 80,
      "props": {
        "children": {
          "type": "code",
          "value": "props.pediatricData?.selectedItem?.unit_of_liquid_result_name || \"\""
        },
        "style": {
          "type": "code",
          "value": "{color:\"#0D71BB\", fontWeight:\"normal\", display: \"flex\", justifyContent: \"center\", alignItems: \"center\", padding: \"0px\", margin: \"14px 0px 0px 14px\"}"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 84,
      "name": "div",
      "parent": 58,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.pediatricData?.selectedItem?.max_per_dose_liquid > 0 ?  \"flex\" : \"none\" }"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "label",
      "parent": 84,
      "props": {
        "children": {
          "type": "value",
          "value": "เท่ากับ"
        },
        "style": {
          "type": "code",
          "value": "{color:\"#0D71BB\", fontWeight:\"normal\", display: \"flex\", justifyContent: \"center\", alignItems: \"center\", padding: \"0px\", margin: \"14px 14px 0px 14px\"}"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 86,
      "name": "Input",
      "parent": 84,
      "props": {
        "value": {
          "type": "code",
          "value": "props.pediatricData?.selectedItem?.max_per_dose_liquid || \"\""
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "label",
      "parent": 84,
      "props": {
        "children": {
          "type": "code",
          "value": "props.pediatricData?.selectedItem?.unit_of_liquid_result_name || \"\""
        },
        "style": {
          "type": "code",
          "value": "{color:\"#0D71BB\", fontWeight:\"normal\", display: \"flex\", justifyContent: \"center\", alignItems: \"center\", padding: \"0px\", margin: \"14px 0px 0px 14px\"}"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "label",
      "parent": 4,
      "props": {
        "children": {
          "type": "code",
          "value": "props.pediatricData?.selectedItem?.encounter_weight ? \"encounter weight: \"+props.pediatricData?.selectedItem?.encounter_weight : \"\""
        },
        "style": {
          "type": "code",
          "value": "{ height:\"40px\", justifyContent: \"center\", alignItems: \"center\", padding: \"8px\", margin: \"14px 0px 0px 14px\",  display: props.pediatricData?.selectedItem?.encounter_weight!==\"\" ?  \"none\" : \"none\" }"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", flexDirection: props.pediatricData?.selectedItem?.type_of_total_dose_name===\"PER_DOSE\" ? \"column-reverse\" : \"column\" }"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": null,
      "id": 90,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", flexDirection: props.pediatricData?.selectedItem?.type_of_total_dose_name===\"PER_DOSE\" ? \"column-reverse\" : \"column\" }"
        }
      },
      "seq": 35,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardPediatricDoseUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
