import moment from "moment";

import DoctorFeeSummaryView from "issara-sdk/apis/DoctorFeeSummaryView_apps_DFC";

import {
  ActionHandler,
  BLC_STATUS,
  GenerateActionTypes,
  SetErrorMessage,
  generatePrefixedActions,
} from "react-lib/apps/HISV3/common/CommonInterface";

import { State as MainState } from "HIS/MainHISInterface";

import { beToAd } from "react-lib/utils/dateUtils";
import { downloadFile } from "react-lib/utils/utils";

/** ============== Type-Defining Constants =============  */
export const ACTIONS = {
  EXPORT_REPORT: "export_report",
  GET_DATA: "get_data",
} as const;

/** =============== Types and Interfaces ===============  */
export type State = Partial<{
  SetDoctorFeeReportSequence: Partial<{
    sequenceIndex: "START" | null;
    reportData: any;
  }> | null;
}>;

type PickedState = Pick<MainState, "buttonLoadCheck" | "errorMessage">;

export type PickedProps = Partial<Omit<PickedState, "">>;

export type Event =
  | { message: "GetMasterData"; params: Record<string, unknown> }
  | { message: "RunSequence"; params: Record<string, unknown> };

export type Data = {
  device?: number;
  division?: number;
};

export type SeqState = {
  sequence: "SetDoctorFeeReport";
  clear?: boolean;
  restart?: boolean;
};

export type Actions = typeof ACTIONS;

type ActionPayloadMap = {
  [ACTIONS.EXPORT_REPORT]: {
    card: string;
    endDate: string;
    selectedProvider: any;
    startDate: string;
  };
  [ACTIONS.GET_DATA]: {
    card: string;
    doctor: string;
    end_date: string;
    report: boolean;
    start_date: string;
  };
};

type ActionTypes = GenerateActionTypes<
  "SetDoctorFeeReport",
  Actions,
  ActionPayloadMap,
  PickedState & State,
  SeqState
>;

type ActionType = ActionTypes["ActionType"];

export type RunSequence = ActionTypes["RunSequence"]

export type SetProp = ActionTypes["SetProp"];

type Params<A extends ActionType["action"]> = ActionTypes["Params"][A];

export type Handler<P = unknown, R = void> = ActionHandler<PickedState & State, Event, Data, P, R>;

/** ===================== Constants ====================  */
export const StateInitial: State = {
  SetDoctorFeeReportSequence: null,
};

export const DataInitial: Data = {};

export const CARD_DF_REPORT = "CardDoctorFeeReport";

export const BTN_ACTS = generatePrefixedActions(ACTIONS, CARD_DF_REPORT);

/** ===================== Functions ====================  */
export const Start: Handler = async (controller, params) => {
  const state = controller.getState();

  if (!state.SetDoctorFeeReportSequence) {
    return;
  }

  Action(controller, params as ActionType);
};

/** ====================================================  */
/**                        Action                         */
/** ====================================================  */
export const Action: Handler<ActionType> = async (controller, params) => {
  const actionHandlers: Partial<{ [K in ActionType["action"]]: Handler<Params<K>> }> = {
    [ACTIONS.EXPORT_REPORT]: HandleExportReport,
    [ACTIONS.GET_DATA]: HandleGetData,
  };

  const { action } = params;

  const handler = actionHandlers[action] as Handler<Params<typeof action>> | undefined;

  if (handler) {
    handler(controller, params);
  } else {
    console.error(`No handler found for action: ${action}`);
  }
};

/** ====================================================  */
/**                        Handler                        */
/** ====================================================  */
const HandleGetData: Handler<Params<Actions["GET_DATA"]>> = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.GET_DATA]: BLC_STATUS.LOADING },
  });

  const [response, error] = await DoctorFeeSummaryView.get({
    apiToken: controller.apiToken,
    params: {
      doctor: params.doctor,
      end_date: params.end_date,
      report: params.report,
      start_date: params.start_date,
    } as any,
  });

  if (response) {
    controller.setState({
      SetDoctorFeeReportSequence: {
        ...state.SetDoctorFeeReportSequence,
        reportData: response,
      },
      buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.GET_DATA]: BLC_STATUS.SUCCESS },
    });
  } else {
    SetErrorMessage(controller, { ...params, error });
  }
};

const HandleExportReport: Handler<Params<Actions["EXPORT_REPORT"]>> = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.EXPORT_REPORT]: BLC_STATUS.LOADING },
  });

  const [, error, net] = await DoctorFeeSummaryView.get({
    apiToken: controller.apiToken,
    params: {
      doctor: params.selectedProvider?.doctor_info?.id || "",
      end_date: moment(beToAd(params.endDate)).format("YYYY-MM-DD"),
      report: true,
      start_date: moment(beToAd(params.startDate)).format("YYYY-MM-DD"),
    },
    extra: { responseType: "arraybuffer" },
  });

  if (error) {
    SetErrorMessage(controller, { ...params, error });
  } else {
    downloadFile(net);

    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.EXPORT_REPORT]: BLC_STATUS.SUCCESS },
    });
  }
};
