  // REQUEST = 1, 'นัดหมายยังไม่ได้ถูกระบุ'
  // CONFIRM = 2, 'นัดหมายถูกระบุและยืนยัน'
  // REVISE = 3, 'นัดหมายต้องการการคำนวณใหม่'
  // RECONFIRM = 4, 'นัดหมายต้องการยืนยันจากผู้ป่วยอีกครั้ง'
  // CANCEL = 5, 'นัดหมายถูกยกเลิกจากผู้ป่วย'
  // REJECT = 6, 'นัดหมายถูกยกเลิกจาก Provider เพราะไม่จำเป็น'
  // ABANDON = 7, 'นัดหมายผ่านไปโดยผู้ป่วยไม่มาตามนัด'
  // FINISH = 8, 'ให้คำปรึกษาเสร็จสิ้น'

export enum APPOINTMENT_STATUS {
  REQUEST = "1", //'นัดหมายยังไม่ได้ถูกระบุ'
  CONFIRM = "2", //'นัดหมายถูกระบุและยืนยัน'
  REVISE = "3", //'นัดหมายต้องการการคำนวณใหม่'
  RECONFIRM = "4", //'นัดหมายต้องการยืนยันจากผู้ป่วยอีกครั้ง'
  CANCEL = "5", //'นัดหมายถูกยกเลิกจากผู้ป่วย'
  REJECT = "6", //'นัดหมายถูกยกเลิกจาก Provider เพราะไม่จำเป็น'
  ABANDON = "7", //'นัดหมายผ่านไปโดยผู้ป่วยไม่มาตามนัด'
  FINISH = "8", //'ให้คำปรึกษาเสร็จสิ้น'
}

export const blockListForAppointmentSummary = (blockList: any[]) => {


  // ACTIVE: 1,
  // INACTIVE: 2,
  // REVISE: 3

  console.log(" blockList", blockList)
  return(
    (blockList || [])
      .map((item: any) => ({
        ...item,
        appointmentList: (item?.providers || [])
                          .flatMap((provider: any) => (
                            (provider?.dsb?.appointments || [])
                              .filter((item: any) => provider?.dsb?.doctor_dsb_status !== 2) // หัก แพทย์ งดออกตรวจ
                              .filter((item: any) => item.status === 2 || item.status === 1) // นัดหมาย status
                              .map((item: any) => ({
                                  datetime: item.estimated_at_display,
                                  patient: `${item.first_name} ${item.last_name}`,
                                  provider_id: provider?.dsb?.doctor_dsb_id,
                                  provider: provider?.dsb?.doctor_provider_name,
                                  chair_provider_id: provider?.dsb?.chair_provider_id,
                                  chair_zone_id: provider?.dsb?.chair_zone_id,
                                  original: item
                              }))
                          ))
      }))
      .filter((item: any) => item.appointmentList.length > 0)
      .map((item: any) => ({
        ...item,
        title: item.appointmentList.length > 0 ?
                `${item.title} (${item.appointmentList.length})` : item.title
      }))
  )
}

export const blockListForPatientAppointment = (blockList_: any[], selectedProvider: any) => {
  // Prepare blockList for display
  // Filter only "Doctor Provider" (thus remove "Chair Provider")
  let blockList = (blockList_ || [])
  .map((event: any) => (
    {
      ...event,
      providers: event.providers.filter((provider: any) =>
        provider.dsb.doctor_provider_type === "Doctor")
    }
  ))

  // Then prepare fields for display
  blockList = (
    selectedProvider === null
      ? blockList
      : blockList.filter((event: any) =>
          event.providers.map((provider: any) =>
            provider.doctor_provider_id).includes(selectedProvider?.provider_id)
        )
  )
    .map((item: any) => ({
      ...item,
      providers: item.providers
                    // .filter((p: any) => !p.dsb.doctor_dsb_full)
                    .filter((p: any) =>
                      selectedProvider === null
                      || p.doctor_provider_id === selectedProvider?.provider_id)
                    .map((p: any) => ({
                      ...p,
                      dsb: {
                        ...p.dsb,
                        full: p.dsb.doctor_dsb_full ? "เต็มแล้ว" : ""
                      }})),
    }))
    .filter((item: any) => item.providers.length > 0)
    .map((item: any) => ({
      ...item,
      providerNames: item.providers
                      .filter((p: any) => p.doctor_provider_id !== "Unspecified")
                      .map((p: any) => p?.dsb?.doctor_provider_name),
    }))
    .map((item: any) => ({
      ...item,
      title: selectedProvider === null && item.providerNames.length > 0 ?
              `${item.title} (${item.providerNames.length})` : item.title,
      tooltip: item.providerNames.length > 0 ? item.providerNames.join(", ") : "ไม่ระบุรายชื่อแพทย์",
    }));

    return blockList;
}

export const blockListForDentistSchedule = (
  blockList: any[],
  selectedDivision: any,
  selectedProvider: any,
  chairList: any[],
  providerBlockList: any[],
  telephar?: boolean
  ) => {

    // มี dsb เมื่อไม่เลือก แพทย์​
    // ไม่มี dsb เมื่อเลือก แพทย์

    // console.log("blockListForDentistSchedule blockList?[0].providers?.[0]: ", blockList?.[0].providers?.[0])
    // console.log("blockListForDentistSchedule blockList: ", blockList)
    // console.log("blockListForDentistSchedule   telephar?", telephar?["TelePhar"]:["Doctor"])
  let bl = (blockList || [])
    .concat((providerBlockList || []).filter(
      (block: any) => block.division_id !== selectedDivision?.id))
    .map((item: any) => ({
      ...item,
      chairs: item.providers
                  .filter((provider: any) => provider?.dsb?.chair_provider_id)
                  .map((provider: any) => provider?.dsb),
      providers: selectedProvider === null ?
                 item.providers
                     .filter((provider: any) => (telephar? ["TelePhar"]:["Doctor"]).includes(provider.dsb.doctor_provider_type))
                     .map((p: any) => ({
                      ...p,
                      dsb: {     // มี dsb เมื่อไม่เลือก แพทย์​
                        ...p.dsb,
                        full: p.dsb.doctor_dsb_full ? "เต็มแล้ว" : ""
                      }}))
                 :
                 item.providers
                     .filter((provider: any) => (telephar? ["TelePhar"]:["Doctor"]).includes(provider.dsb.doctor_provider_type))
                     .filter((provider: any) =>
                             provider.doctor_provider_id === selectedProvider?.provider_id)
                     .map((provider: any) => provider.dsb)  // ไม่มี dsb เมื่อเลือก แพทย์
                     .map((provider: any) => ({
                       ...provider,
                       chairName: chairList.find((el: any) => el.provider && el.provider === provider?.chair_provider_id)?.name,
                     }))
    }))
    .map((item: any) => {


      // let patientCount = selectedProvider && item.providers?.find((item: any) => item.doctor_provider_id === selectedProvider?.provider_id)?.appointments?.length
      // // console.log('BlockList item.providers: ', item.providers);
      // // console.log('BlockList selectedProvider: ', selectedProvider);
      // let patientCountText = patientCount > 0 ? ` (คนไข้ ${patientCount} คน) `: ""
      // // console.log('BlockList patientCountText: ', patientCountText);

      // let doctorCount = []
      // let doctorCountText = ""
      // if (selectedProvider === null ) {
      //   // console.log('BlockList !! item: ', item);
      //   doctorCount = item.providers?.filter((item: any) => item.dsb.doctor_provider_type === "Doctor" && item.dsb.doctor_dsb_status !== 2)?.length
      //   // console.log('BlockList !! doctorCount: ', doctorCount);
      //   doctorCountText = doctorCount > 0 ? ` (แพทย์ ${doctorCount} คน)` : "";
      // } else {
      //   console.log("BlockList item: ", item)
      //   doctorCount = item.providers?.filter((item: any) => item.doctor_provider_type === "Doctor" && item.doctor_dsb_status !== 2)?.length
      //   // console.log('BlockList !! doctorCount: ', doctorCount);
      //   doctorCountText = doctorCount > 0 ? ` (แพทย์ ${doctorCount} คน)` : "";
      // }


      return ({
      ...item,
      title: selectedProvider === null ? `${item.title}`
      // title: selectedProvider === null ? `${item.title} ${doctorCountText}`
             :
             item.division_id !== selectedDivision?.id ?
             `${item.title} (${item.division_name})`
             :
             item.providers.length == 0 ? item.title
             :
             (
                `${item.providers[0].doctor_start_time}-${item.providers[0].doctor_end_time} `
                + ((item.providers[0].chairName && item.providers[0].chairName !== null) ?
                   `(${item.providers[0].chairName})` : "")
             )
    })});
    // console.log("blockListForDentistSchedule a ", a )
    return bl
}

export const blockListForChair = (
  blockList: any[],
  selectedChair: any,
  zone: any,
  divisionDict: any,
  chairList: any[],
  selectedDivision: any,
  ) => {

  // console.log(" blockList", blockList)
  // console.log('divisionDict: ', divisionDict);

  return (blockList || [])
    .map((block: any) => ({
      ...block,
      booked_chairs:  block.providers
                        .filter((p: any) =>
                          p?.dsb?.doctor_provider_type === "Doctor"
                          && p?.dsb?.chair_provider_id !== null)
                        .filter((p: any) =>
                          selectedChair ?
                          p?.dsb?.chair_provider_id === selectedChair?.provider
                          : true)
                        .map((p: any) => ({
                            doctor_provider_id: p?.dsb?.doctor_provider_id,
                            doctor_provider_name: p?.dsb?.doctor_provider_name,
                            chair_provider_id: p?.dsb?.chair_provider_id,
                            zone_id: p?.dsb?.doctor_dsb_zone_id,
                            zone_name: divisionDict?.[p?.dsb?.doctor_dsb_zone_id]?.name || "",
                            chair_status: p?.dsb?.doctor_dsb_status
                          }))
                        .map((p: any) => {
                          const chair = chairList.find(
                            (chair: any) => chair.provider === p.chair_provider_id);
                          return ({
                            ...p,
                            chair_name: chair?.name,
                            division_name: chair?.division_name,
                          })})
                        .filter((p: any) => zone ?
                          p.zone_id === zone.id : true),
      open_chairs:    block.providers
                        .filter((p: any) =>
                          p?.dsb?.doctor_provider_type === "Chair")
                        .filter((p: any) =>
                          selectedChair ?
                          p?.dsb?.doctor_provider_id === selectedChair?.provider
                          : true)
                        .map((p: any) => ({
                            doctor_provider_id: null,
                            doctor_provider_name: "",
                            chair_provider_id: p?.dsb?.doctor_provider_id,
                            zone_id: p?.dsb?.doctor_dsb_zone_id,
                            zone_name: divisionDict?.[p?.dsb?.doctor_dsb_zone_id]?.name || "",
                            chair_status: p?.dsb?.doctor_dsb_status,
                          }))
                        .map((p: any) => {
                          const chair = chairList.find(
                            (chair: any) => chair.provider === p.chair_provider_id);
                          return ({
                            ...p,
                            chair_name: chair?.name,
                            division_name: chair?.division_name,
                          })})
                        .filter((p: any) => zone ?
                          p.zone_id === zone.id : true),
    }))
    .map((block: any) => ({
      ...block,
      selected_chair_zone_id: (block.booked_chairs.concat(block.open_chairs))
                                .find((chair: any) =>
                                      chair.chair_provider_id === selectedChair?.provider)
                                ?.zone_id
    }))
    .map((block: any) => {

      // console.log("blockListForChair block", block)
      // console.log("blockListForChair block.division_id: ", block.division_id)
      // console.log("blockListForChair selectedDivision?.id: ", selectedDivision?.id )

      return {
      ...block,
      title:  block.division_id && block.division_id !== selectedDivision?.id ?
              `${block.title} (${block.division_name})`
              : (block.open_chairs.length + block.booked_chairs.length === 0)
              ? block?.title
              : (selectedChair === null || selectedChair === undefined)
              ? (`${block?.title} (${block.open_chairs.length}/` +
                 `${block.open_chairs.length + block.booked_chairs.length})`)
              : (block?.title +
                 ` (${divisionDict?.[block?.selected_chair_zone_id]?.name || "ไม่ระบุโซน"})`)
    }});
}