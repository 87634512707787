import React from 'react';
import PropTypes from 'prop-types';
import DateTextBox from '../common/DateTextBox';
import PureReactTable from '../common/PureReactTable';
import { formatPrice } from '../common/PureReactTable';
import CardLayout from '../common/CardLayout';
import ErrorMessage from '../common/ErrorMessage';
import { Form, Checkbox, Button, Dimmer, Loader, Select } from 'semantic-ui-react';
import SummaryPrice from './SummaryPrice';

const alignButtonRight = {
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '1em',
};

class CardCreateARTransaction extends React.Component {
  constructor(props) {
    super(props);
    this.gridColumns = [
      {
        Header: 'Station',
        accessor: 'station'
      },
      {
        Header: 'Auth Code',
        accessor: 'auth_code'
      },
      {
        Header: 'Date Transaction',
        accessor: 'dt_tran'
      },
      {
        Header: 'Hospital Code',
        accessor: 'hospital_code'
      },
      {
        Header: 'Invoice No.',
        accessor: 'invoice_no'
      },
      {
        Header: 'Bill No.',
        accessor: 'bill_no'
      },
      {
        Header: 'HN',
        accessor: 'hn'
      },
      {
        Header: 'Member No.',
        accessor: 'member_no'
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        Cell: row => formatPrice(row.value)
      },
      {
        Header: 'Paid',
        accessor: 'paid',
        Cell: row => formatPrice(row.value)
      },
      {
        Header: 'Verify Code',
        accessor: 'verify_code'
      },
      {
        Header: 'T Flag',
        accessor: 't_flag'
      },
      {
        Header: 'Personal ID',
        accessor: 'pid'
      },
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Hospital Main',
        accessor: 'hospital_main'
      },
      {
        Header: 'Pay Plan',
        accessor: 'pay_plan'
      },
      {
        Header: 'Claim Amount',
        accessor: 'claim_amount',
        Cell: row => formatPrice(row.value)
      },
      {
        Header: 'Other Pay Plan',
        accessor: 'other_pay_plan'
      },
      {
        Header: 'Other Pay',
        accessor: 'other_pay',
        Cell: row => formatPrice(row.value)
      },
    ];
  }

  renderCell = (ci) => {
    const id = ci.original.id;
    return (
      <Checkbox
        checked={this.props.selectedRows[id]}
        onClick={
          (e, data) => this.props.onToggleBillTransactionSelection([id], data.checked)
        }
      />
    );
  }

  handlePageSizeChange = (pageSize) => {
    this.props.onPaginationChange(pageSize, this.props.page);
  }

  handlePageChange = (page) => {
    this.props.onPaginationChange(this.props.pageSize, page + 1);
  }

  render() {
    const {
      date,
      error,
      items,
      page,
      total,
      pageSize,
      isLoading,
      totalRows,
      totalPrice,
      totalSentClaim,
      totalPaid,
      totalOtherPay,
      totalPriceBillItemMode_2,
      totalSentClaimPriceBillItemMode_2,
      totalPriceBillItemMode_3_5,
      totalSentClaimPriceBillItemMode_3_5,
      totalPriceBillItemModeRemain,
      totalSentClaimPriceBillItemModeRemain,
      totalPriceDispensing,
      totalSentClaimPriceDispensing,
      totalPriceOperationService,
      useFilterDate,
      onDateChange,
      onSearchClick,
      onToggleFilterDate,
      onCreateARTransactionClick,
      generateSentClaimDataDate,
      onGenerateSentClaimDataClick,
      onGenerateSentClaimDataDateChange,
      onChangeCoveragePayerId,
      onStartDateChange,
      onEndDateChange,
      coveragePayerGroup,
      coveragePayerId,
      startDate,
      endDate,
      isCalulating,
      runningARTask,
      tableStyle,
    } = this.props;

    return (
      <CardLayout
        titleText='สร้างสิทธิตั้งเบิก'
        loading={isLoading}
        closeable={false}
      >
       <Dimmer.Dimmable dimmed={runningARTask} style={{ height: '100%' }} className='MainLayout'>
          <Dimmer active={runningARTask} inverted>
            <Loader inverted>Generating Data</Loader>
          </Dimmer>
        <Form>
          <ErrorMessage error={error} />
          <Form.Group inline>
            <Form.Field width={3} style={{ marginRight: 5 }}>
              <label style={{ minWidth: "max-content" }}>เลือกสิทธิ</label>
              <Select
                selection
                search
                fluid={true}
                options={coveragePayerGroup}
                onChange={(e, { value }) => onChangeCoveragePayerId(value)}
                value={coveragePayerId}
                style={{ width: "100%" }}
              />
            </Form.Field>
            <Form.Field width={1}>
              <label  style={{ minWidth: "max-content" }}>ระบุวันที่</label>
              <Checkbox
                checked={useFilterDate}
                onChange={(e, data) => onToggleFilterDate(data.checked)}
              />
            </Form.Field>
            <Form.Field
              width={2}
              control={DateTextBox}
              value={startDate}
              disabled={!useFilterDate}
              onChange={onStartDateChange}
            />
            <label> ถึง </label>
            <Form.Field
              width={2}
              control={DateTextBox}
              value={endDate}
              disabled={!useFilterDate}
              onChange={onEndDateChange}
            />
            <Form.Button
              width={2}
              content='ค้นหา'
              color='blue'
              type='button'
              onClick={onSearchClick}
            />
            <Form.Field width={4}>
              <label  style={{ minWidth: "max-content" }}>ระบุวันที่สร้างข้อมูลส่งเบิก</label>
              <DateTextBox
                value={generateSentClaimDataDate}
                onChange={onGenerateSentClaimDataDateChange}
              />
            </Form.Field>
            <Form.Button
              width={2}
              content='สร้างข้อมูลส่งเบิก'
              color='purple'
              type='button'
              onClick={onGenerateSentClaimDataClick}
            />

          </Form.Group>

          <PureReactTable
            style={tableStyle}
            loading={isLoading}
            data={items}
            page={page - 1}
            pages={total}
            pageSize={pageSize}
            onPageSizeChange={this.handlePageSizeChange}
            onPageChange={this.handlePageChange}
            columns={this.gridColumns}
          />

          <br />

          <SummaryPrice
            totalRows={totalRows}
            totalPrice={totalPrice}
            totalSentClaim={totalSentClaim}
            totalPaid={totalPaid}
            totalOtherPay={totalOtherPay}
            totalPriceBillItemMode_2={totalPriceBillItemMode_2}
            totalSentClaimPriceBillItemMode_2={totalSentClaimPriceBillItemMode_2}
            totalPriceBillItemMode_3_5={totalPriceBillItemMode_3_5}
            totalSentClaimPriceBillItemMode_3_5={totalSentClaimPriceBillItemMode_3_5}
            totalPriceBillItemModeRemain={totalPriceBillItemModeRemain}
            totalSentClaimPriceBillItemModeRemain={totalSentClaimPriceBillItemModeRemain}
            totalPriceDispensing={totalPriceDispensing}
            totalSentClaimPriceDispensing={totalSentClaimPriceDispensing}
            totalPriceOperationService={totalPriceOperationService}
            isCalulating={isCalulating}
          />

          <div style={alignButtonRight}>
            <Form.Field
              style={{ justifyContent: 'flex-end' }}
            >
              <Button
                color={'green'}
                onClick={onCreateARTransactionClick}
                type='button'
              >
                สร้างเลขงวด
              </Button>
            </Form.Field>
          </div>
        </Form>
        </Dimmer.Dimmable> 
      </CardLayout>
    )
  }
}

CardCreateARTransaction.propTypes = {
  date: PropTypes.string,
  items: PropTypes.array,
  error: PropTypes.any,
  page: PropTypes.number,
  total: PropTypes.number,
  pageSize: PropTypes.number,
  totalRows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  totalPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  totalSentClaim: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  totalOtherPay: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  totalPaid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  totalPriceBillItemMode_2: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  totalSentClaimPriceBillItemMode_2: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  totalPriceBillItemMode_3_5: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  totalSentClaimPriceBillItemMode_3_5: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  totalPriceBillItemModeRemain: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  totalSentClaimPriceBillItemModeRemain: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  totalPriceDispensing: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  totalSentClaimPriceDispensing: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  totalPriceOperationService: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isLoading: PropTypes.bool,
  useFilterDate: PropTypes.bool,
  onDateChange: PropTypes.func,
  onPaginationChange: PropTypes.func,
  onSearchClick: PropTypes.func,
  onToggleFilterDate: PropTypes.func,
  onCreateARTransactionClick: PropTypes.func,
  generateSentClaimDataDate: PropTypes.string,
  onGenerateSentClaimDataDateChange: PropTypes.func,
  onGenerateSentClaimDataClick: PropTypes.func,
};

export default CardCreateARTransaction;
