import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const ModIntraTransferUX = (props: any) => {
    return(
      <div
        style={{width: "100%", height: "100%", padding: "10px"}}>
        <div
          style={{display: "flex", flexDirection: "column"}}>
          
          <div>
            
            <div
              style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
              
              <div
                style={{fontSize: "20px", fontWeight: "bold"}}>
                Intra Hospital Transfer Form
              </div>
              <div
                style={{display: "flex"}}>
                
                <Button
                  color="green"
                  onClick={props.onNewForm}>
                  สร้าง Form ใหม่
                </Button>
                <div>
                  {props.cancelcon}
                </div>
              </div>
            </div>
            <hr>
            </hr>
          </div>
          <div>
            
            <Table
              data={props.transferFormData}
              getTrProps={props.getTrProps}
              headers="No,วันที่,จากหน่วยงาน,ไปหน่วยงาน,"
              keys="no,date,from_division,to_division,_remove"
              minRows="5"
              showPagination={false}
              widths="100,150,,,100">
            </Table>
          </div>
        </div>
        <div>
          {props.intraTransferForm}
        </div>
        <div>
          {props.ErrorMessageWard}
        </div>
        <div
          style={{padding: "10px 0px",  display: props.hideAdmit ? "none" : ""}}>
          
          <Button
            color="green"
            onClick={props.onWardPatient}
            style={{width: "100%", height: "100%"}}>
            รับเข้า
          </Button>
        </div>
      </div>
    )
}

export default ModIntraTransferUX

export const screenPropsDefault = {}

/* Date Time : Fri Jul 08 2022 06:23:20 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\", padding: \"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", flexDirection: \"column\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "Intra Hospital Transfer Form"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"20px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "hr",
      "parent": 2,
      "props": {
      },
      "seq": 8,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "Button",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "สร้าง Form ใหม่"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onNewForm"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "code",
          "value": "props.cancelcon"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.intraTransferForm"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px 0px\",  display: props.hideAdmit ? \"none\" : \"\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 13,
      "name": "Table",
      "parent": 3,
      "props": {
        "data": {
          "type": "code",
          "value": "props.transferFormData"
        },
        "getTdProps": {
          "type": "code",
          "value": ""
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getTrProps"
        },
        "headers": {
          "type": "value",
          "value": "No,วันที่,จากหน่วยงาน,ไปหน่วยงาน,"
        },
        "keys": {
          "type": "value",
          "value": "no,date,from_division,to_division,_remove"
        },
        "minRows": {
          "type": "value",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "widths": {
          "type": "value",
          "value": "100,150,,,100"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Button",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "รับเข้า"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onWardPatient"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ErrorMessageWard"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 12,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 45,
  "isMounted": false,
  "memo": false,
  "name": "ModIntraTransferUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
