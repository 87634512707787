import React, { useEffect, useState } from "react";
import CardLayout from "../common/CardLayout";
import { Form, Label, Dimmer, Loader } from "semantic-ui-react";
import ReactTable from "react-table-6";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

const CardMedDetail = ({
  isLoading,
  homeMedDetail,
  getHomeMed,

  closeable,
  toggleable,
  hideHeaderIcon,
  defaultBackground
}) => {
  let dateTime;
  let encounterType;
  let doctorName;
  let divisionName;
  let drugOrderItems;

  if (homeMedDetail.medRecord) {
    dateTime = homeMedDetail.medRecord.created;
    encounterType = homeMedDetail.medRecord.encounter__type;
    doctorName = homeMedDetail.medRecord.doctor_name;
    divisionName = homeMedDetail.medRecord.encounter__division;
  }

  if (
    homeMedDetail.drugItems &&
    homeMedDetail.drugItems.items &&
    homeMedDetail.drugItems.items.length > 0
  ) {
    drugOrderItems = homeMedDetail.drugItems.items;
  }

  let renderCell = (props: any) => {
    return <div>
      <span>{props.original.name}</span>
      {props.original.return_quantity && <div style={{ display: "inline-block", float: "right" }}>(Return)</div>}
    </div>
  }

  let content = (
    <React.Fragment>
      <CardLayout
        titleText={dateTime}
        closeable={closeable}
        toggleable={toggleable}
        hideHeaderIcon={hideHeaderIcon}
        defaultBackground={defaultBackground}
        hideTopRightText={false}
        topRightText={encounterType}
        headerColor="brown"
      >
        <Dimmer active={isLoading} inverted>
          <Loader />
        </Dimmer>
        {homeMedDetail.medRecord ? (
          <Form>
            <Form.Group inline>
              <Form.Field width={2}>แพทย์</Form.Field>
              <Form.Field width={4}>
                <Label className="fluid"> {doctorName} </Label>{" "}
              </Form.Field>
              <Form.Field width={1}>แผนก</Form.Field>
              <Form.Field width={4}>
                <Label className="fluid"> {divisionName} </Label>
              </Form.Field>
            </Form.Group>
          </Form>
        ) : null}
        {drugOrderItems && drugOrderItems.length > 0 ? (
          <ReactTable
            showPagination={false}
            minRows={0}
            noDataText="ไม่มีข้อมูล"
            columns={[
              {
                Header: "Status",
                accessor: "order_status_label",
                width: 200
              },
              {
                Header: "Name",
                Cell: renderCell
              },
              {
                Header: "Label",
                accessor: "label"
              },
              {
                Header: "Qty",
                accessor: "quantity",
                minWidth: 50,
                resizable: false
              },
              {
                Header: "Return",
                accessor: "return_quantity",
                minWidth: 50,
                resizable: false
              }
            ]}
            data={drugOrderItems}
            getNoDataProps={() => {
              return { style: { display: "none" } };
            }}
          />
        ) : null}
      </CardLayout>
    </React.Fragment>
  );

  return content;
};

CardMedDetail.defaultProps = {
  isLoading: false,
  homeMedDetail: {},
  getHomeMed: () => { },

  closeable: true,
  toggleable: true,
  hideHeaderIcon: false,
  defaultBackground: true
};

CardMedDetail.propTypes = {
  isLoading: PropTypes.bool,
  homeMedDetail: PropTypes.object,
  getHomeMed: PropTypes.func,

  drugOrderItems: PropTypes.object,
  closeable: PropTypes.bool,
  toggleable: PropTypes.bool,
  hideHeaderIcon: PropTypes.bool,
  defaultBackground: PropTypes.bool
};

export default React.memo(CardMedDetail);
