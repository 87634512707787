import moment from "moment";
import { ContentSvg, TDocumentDefinitions } from "pdfmake/interfaces";
import CONFIG from "config/config";

// Common
import CompanyLogoForm from "react-lib/apps/HISV3/common/CompanyLogoForm";
import { splitStringNewLine } from "react-lib/apps/HISV3/common/CommonInterface";
import { getLogoReportNResize } from "react-lib/apps/HISV3/common/CommonInterface";

import { formatDate, formatDatetime } from "react-lib/utils/dateUtils";

type FormMedReconcileProps = {
  adrText: string;
  doctor?: { created_at: string; user_name: string };
  encounter: Record<string, any>;
  items: {
    label: string;
    name: string;
    note: string;
    quantity: string;
    status?: "CONTINUE" | "HOLD" | "OFF";
    take_medicine_status?: "TM_ACTIVE" | "TM_OFF";
  }[];
  nurse?: { created_at: string; user_name: string };
  nurseIpd?: { created_at: string; user_name: string };
  patientImage?: string;
  pharmacist?: { created_at: string; user_name: string };
  type: keyof typeof MED_RECS;
};

const TABLES = {
  columns: [
    "ลำดับ",
    "ชื่อยา/ความแรง/รูปแบบ",
    "วิธีใช้",
    "จำนวน",
    "Off",
    "Hold",
    "Cont.",
    "หมายเหตุ",
  ],
  keys: ["seq", "name", "label", "quantity", "off", "hold", "continue", "note"] as const,
  widths: ["7%", "25%", "25%", "10%", "5%", "5%", "5%", "18%"],
};

const MED_RECS = {
  DISCHARGE: {
    columns: [
      "ลำดับ",
      "ชื่อยา/ความแรง/รูปแบบ",
      "วิธีใช้",
      "จำนวน",
      "ใช้ยาต่อ",
      "หยุดยา",
      "หมายเหตุ",
    ],
    keys: ["seq", "name", "label", "quantity", "tm_active", "tm_off", "note"] as const,
    title: "Discharge",
    widths: ["7%", "25%", "25%", "10%", "7.5%", "7.5%", "18%"],
  },
  IPD_ADMIT: {
    columns: TABLES.columns,
    keys: TABLES.keys,
    title: "IPD แรกรับ",
    widths: TABLES.widths,
  },
  OPD: {
    columns: TABLES.columns,
    keys: TABLES.keys,
    title: "OPD",
    widths: TABLES.widths,
  },
  TRANSFER: {
    columns: TABLES.columns,
    keys: TABLES.keys,
    title: "Transfer",
    widths: TABLES.widths,
  },
};

const CheckboxKeys = ["off", "hold", "continue", "tm_active", "tm_off"] as const;

const genderMapping: Record<string, string> = {
  Female: "หญิง",
  Male: "ชาย",
};

const FORM_NAME = "FormMedReconcile";
const logoHeight = CONFIG.COMPANY === "SAPIENS" ? 30 : 52;

const SvgCheckbox = (check?: boolean) => `<svg width="9" height="9">
  <rect x="1" y="5" rx="1" width="9" height="9" style="fill:transparent;stroke:#9d9d9d;stroke-width:1.5;fill-opacity:1;stroke-opacity:0.75" />
  ${
    check &&
    `<polygon style="fill:black;stroke-width:1.5;stroke:black;transform:scale(0.055) translate(10 55)" points="191.268,26.967 59.541,158.683 5.615,104.76 0,110.386 59.541,169.92 196.887,32.585"/>`
  }
  </svg>`;

const generateSignature = ({
  datetime,
  dots,
  name,
  title,
}: {
  datetime?: string;
  dots: [number, number];
  name?: string;
  title: string;
}) => [
  { text: [`${title} `, name || Array.from({ length: dots[0] }).join(".")] },
  {
    text: [
      "วันที่/เวลา ",
      datetime ? formatDatetime(moment(datetime)) : Array.from({ length: dots[1] }).join("."),
    ],
  },
];

const FormMedReconcile = async (props: FormMedReconcileProps): Promise<TDocumentDefinitions> => {
  const companyLogoForm = await CompanyLogoForm({ font: "KanitLM", height: logoHeight, type: 2, form: FORM_NAME });
  const logoResize = await getLogoReportNResize(logoHeight, 1, FORM_NAME);
  const { font, fontSizes, images, lineHeights } = companyLogoForm;

  const items = props.items.map((item, index) => {
    const formattedCheckbox = Object.fromEntries(
      CheckboxKeys.map((key) => {
        const isTmKey = ["tm_active", "tm_off"].includes(key);
        const statusKey = isTmKey
          ? item.take_medicine_status?.toLowerCase()
          : item.status?.toLowerCase();
        const isChecked = statusKey === key.replace("tm_", "");

        return [
          key,
          {
            margin: [isTmKey ? 13 : 6.75, -1.5, 0, 0],
            style: "tableValue",
            svg: SvgCheckbox(isChecked),
            width: 15,
          } as ContentSvg,
        ];
      })
    ) as Record<(typeof CheckboxKeys)[number], ContentSvg>;

    return {
      label: {
        style: "tableValue",
        text: item.label,
      },
      name: { style: "tableValue", text: item.name },
      note: { style: "tableValue", text: item.note },
      quantity: { alignment: "center", style: "tableValue", text: item.quantity },
      seq: { alignment: "center", style: "tableValue", text: index + 1 },
      ...formattedCheckbox,
    };
  });

  const adrLines = splitStringNewLine(props.adrText || "", {
    fontSize: fontSizes[15],
    max: 1,
    width: 202,
  });

  const gender = genderMapping[props.encounter.patient_gender_name] || "ไม่ระบุ";

  console.log("FormMedReconcile", props);

  return {
    defaultStyle: {
      font,
      fontSize: fontSizes[15],
      lineHeight: lineHeights[1],
    },
    images: {
      ...images,
      userProfile: props.patientImage || `${origin}/static/images/user.png`,
    },
    pageMargins: [20, 122.5, 20, 35],
    pageOrientation: "portrait",
    pageSize: "A4",
    styles: {
      fieldDivision: { alignment: "center", fontSize: fontSizes[11] },
      fieldHeader: { alignment: "center", bold: true, fontSize: fontSizes[17] },
      fieldKey: { bold: true },
      miniField: { fontSize: fontSizes[12] },
      tableHeader: { alignment: "center", bold: true, fontSize: fontSizes[13] },
      tableValue: { fontSize: fontSizes[13] },
    },
    header: () => ({
      columns: [
        {
          stack: [
            {
              // ...companyLogoForm,
              image: "logo",
              height: logoHeight,
              width: logoResize.width,
              alignment: "center",
              margin: CONFIG.COMPANY === "SAPIENS" ? [5, 12.5, 0, 0] : [5, 0, 0, 0],
            },
            {
              margin:  CONFIG.COMPANY === "SAPIENS" ? [-20, 12.5, 0, 0] : [-20, 8, 0, 0],
              style: "fieldHeader",
              text: `Medication Reconciliation (${MED_RECS[props.type].title})`,
            },
            {
              marginLeft: -20,
              style: "fieldDivision",
              text: props.encounter.division_name || "",
            },
          ],
          width: "52%",
        },
        {
          lineHeight: lineHeights[1],
          marginTop: 5,
          stack: [
            {
              text: [
                { style: "fieldKey", text: "HN [AN]: " },
                { text: `${props.encounter.hn} [${props.encounter.number}]` },
              ],
            },
            {
              text: [
                { style: "fieldKey", text: "ชื่อ-นามสกุล: " },
                { text: props.encounter.patient_name },
              ],
            },
            {
              columns: [
                {
                  text: [
                    { style: "fieldKey", text: "วันเกิด: " },
                    { text: props.encounter.patient_birthdate },
                  ],
                  width: "auto",
                },
                {
                  marginLeft: 25,
                  text: [{ style: "fieldKey", text: "เพศ: " }, { text: gender }],
                  width: "*",
                },
              ],
            },
            {
              text: [
                { style: "fieldKey", text: "อายุ: " },
                { text: props.encounter.patient_age || "" },
              ],
            },
            {
              text: [
                { style: "fieldKey", text: "วันที่เข้ารับบริการ: " },
                {
                  text: props.encounter.started ? formatDate(moment(props.encounter.started)) : "",
                },
              ],
            },
            {
              text: [{ style: "fieldKey", text: "ADR: " }, { text: adrLines[0] }],
            },
          ],
          style: "miniField",
          width: "*",
        },
        { text: "", width: 12.5 },
        {
          alignment: "center",
          height: 90,
          image: "userProfile",
          width: 75,
        },
        { text: "", width: 7.5 },
      ],
      margin: [10, 10, 10, 0],
    }),
    content: [
      {
        table: {
          dontBreakRows: true,
          headerRows: 1,
          widths: MED_RECS[props.type].widths,
          body: [
            MED_RECS[props.type].columns.map((column) => ({
              fillColor: "#e3e3e3",
              style: "tableHeader",
              text: column,
            })),
            ...items.map((item, index) => MED_RECS[props.type].keys.map((key) => item[key])),
          ],
        },
        layout: {
          hLineWidth: () => 0.25,
          vLineWidth: () => 0.25,
        },
      },
      {
        id: "signature",
        marginTop: -fontSizes[15] - 4,
        text: " ",
      },
      {
        table: {
          widths: ["100%"],
          body: [[{ text: " " }], [{ text: " " }]],
        },
        layout: {
          hLineWidth: () => 0,
          paddingBottom: (i, node: any) => {
            const nps: any[] = node.positions || [];
            const np = nps.slice(-1)[0];
            const top = np?.top;
            const pageNumber = np?.pageNumber;

            if (pageNumber === 1 && i === 1 && top < 710) {
              return 710 - top;
            } else if (pageNumber === 2 && top < 150) {
              return -23;
            }

            return 0;
          },
          vLineWidth: () => 0,
        },
      },

      {
        style: "tableValue",
        table: {
          widths: ["25%", "25%", "25%", "25%"],
          body: [
            [
              {
                stack: generateSignature({
                  datetime:
                    props.type === "OPD" ? props.nurse?.created_at : props.nurseIpd?.created_at,
                  dots: [49, 46],
                  name: props.type === "OPD" ? props.nurse?.user_name : props.nurseIpd?.user_name,
                  title: "พยาบาล",
                }),
              },
              {
                stack: generateSignature({
                  datetime: props.doctor?.created_at,
                  dots: [54, 46],
                  name: props.doctor?.user_name,
                  title: "แพทย์",
                }),
              },
              {
                stack: generateSignature({
                  datetime: props.pharmacist?.created_at,
                  dots: [50, 46],
                  name: props.pharmacist?.user_name,
                  title: "เภสัชกร",
                }),
              },
              {
                stack: generateSignature({
                  datetime: "",
                  dots: [41, 46],
                  name: "",
                  title: "ผู้ป่วย/ญาติ",
                }),
              },
            ],
          ],
        },
        layout: {
          hLineWidth: () => 0.25,
          paddingBottom: () => 5,
          paddingTop: (i, node) => 7,
          vLineWidth: () => 0.25,
        },
      },
      {
        id: "signature_last_content",
        marginTop: -fontSizes[15] - 4,
        text: " ",
      },
    ],
    footer: (currentPage, pageCount) => ({
      columns: [
        { text: `วันที่และเวลาพิมพ์ ${formatDatetime(moment())}`, width: "*" },
        { text: `หน้า ${currentPage}/${pageCount}`, width: "auto" },
      ],
      margin: [20, 7.5, 20, 0],
      style: "miniField",
    }),
    pageBreakBefore: (currentNode, followingNodesOnPage, nodesOnNextPage) => {
      const isPageBreakClass = currentNode.id?.includes("signature");

      if (isPageBreakClass && !currentNode.id?.includes("last_content")) {
        const lastContent = [...followingNodesOnPage, ...nodesOnNextPage].find(
          (node) => node.id?.includes("last_content") && node.id.includes(currentNode.id || "")
        );

        return lastContent?.pageNumbers.toString() !== currentNode.pageNumbers.toString();
      }

      return false;
    },
  };
};

export default FormMedReconcile;
