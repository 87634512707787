import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Label,
  Button
} from 'semantic-ui-react'

const CardInvoicesSummaryUX = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{display:"flex", gap: "10px",flexDirection: "column"}}>
        <div
          style={{display: "flex", justifyContent:"space-between"}}>
          
          <Label
            size="big">
            สรุปข้อมูลใบแจ้งหนี้
          </Label>
          <Label
            color={props.colorPaidStatus}
            size="big"
            style={{ ...(props.paidStatus ? {display: "block"} : {display: "none"}) }}>
            {props.paidStatus}
          </Label>
        </div>
        <div>
          
          <Table
            NoDataComponent={props.noDataComponent}
            data={props.data}
            getTdProps={props.getTdProps}
            getTrProps={props.getTrProps}
            headers="รายการค่าใช้จ่าย,ยอดชำระรวม (บาท)"
            keys="product,price"
            minRows="5"
            showPagination={false}
            widths="auto,auto">
          </Table>
        </div>
        <div
          style={{display: "flex", justifyContent: "space-between"}}>
          
          <Label
            size="big">
            ที่อยู่จัดส่ง
          </Label>
          <Button
            color="yellow"
            onClick={props.onClickCopyToClipboard}>
            {props.copyBtnChild}
          </Button>
        </div>
        <div
          style={{minHeight: "100px",border: "1px solid lightgray", borderRadius: "3px", padding: "10px 10px 20px 10px"}}>
          {props.addressComponent}
        </div>
      </div>
    )
}

export default CardInvoicesSummaryUX

export const screenPropsDefault = {}

/* Date Time : Thu Oct 06 2022 09:53:33 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{display:\"flex\", gap: \"10px\",flexDirection: \"column\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent:\"space-between\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 8,
      "name": "Table",
      "parent": 2,
      "props": {
        "NoDataComponent": {
          "type": "code",
          "value": "props.noDataComponent"
        },
        "data": {
          "type": "code",
          "value": "props.data"
        },
        "getTdProps": {
          "type": "code",
          "value": "props.getTdProps"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getTrProps"
        },
        "headers": {
          "type": "value",
          "value": "รายการค่าใช้จ่าย,ยอดชำระรวม (บาท)"
        },
        "keys": {
          "type": "value",
          "value": "product,price"
        },
        "minRows": {
          "type": "value",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "widths": {
          "type": "value",
          "value": "auto,auto"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "Label",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "สรุปข้อมูลใบแจ้งหนี้"
        },
        "name": {
          "type": "value",
          "value": ""
        },
        "size": {
          "type": "value",
          "value": "big"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Label",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.paidStatus"
        },
        "color": {
          "type": "code",
          "value": "props.colorPaidStatus"
        },
        "size": {
          "type": "value",
          "value": "big"
        },
        "style": {
          "type": "code",
          "value": "{ ...(props.paidStatus ? {display: \"block\"} : {display: \"none\"}) }"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "Label",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "ที่อยู่จัดส่ง"
        },
        "size": {
          "type": "value",
          "value": "big"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "Button",
      "parent": 3,
      "props": {
        "children": {
          "type": "code",
          "value": "props.copyBtnChild"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "icon": {
          "type": "code",
          "value": ""
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickCopyToClipboard"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.addressComponent"
        },
        "style": {
          "type": "code",
          "value": "{minHeight: \"100px\",border: \"1px solid lightgray\", borderRadius: \"3px\", padding: \"10px 10px 20px 10px\"}"
        }
      },
      "seq": 4,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "CardInvoicesSummaryUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
