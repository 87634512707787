import React from 'react'
import {
   Dropdown,
   Input,
} from 'semantic-ui-react'



import styles from './OTPhysicalExaminationPediatrics2.module.css'

export interface OTPhysicalExaminationPediatrics2ViewDataType {
    CtPersonalAndSocial: string,
    CtPersonalAndSocialDisabled: boolean,
    CtPersonalAndSocialOptions: any,
    OtPhysicalPediatric213: string,
    OtPhysicalPediatric213Disabled: boolean,
    OtPhysicalPediatric214: string,
    OtPhysicalPediatric214Disabled: boolean,
    CtFineMotor: string,
    CtFineMotorDisabled: boolean,
    CtFineMotorOptions: any,
    OtPhysicalPediatric219: string,
    OtPhysicalPediatric219Disabled: boolean,
    CtGrossMotor: string,
    CtGrossMotorDisabled: boolean,
    CtGrossMotorOptions: any,
    OtPhysicalPediatric225: string,
    OtPhysicalPediatric225Disabled: boolean,
    CtLanguage: string,
    CtLanguageDisabled: boolean,
    CtLanguageOptions: any,
    OtPhysicalPediatric232: string,
    OtPhysicalPediatric232Disabled: boolean,
    OtPhysicalPediatric233: string,
    OtPhysicalPediatric233Disabled: boolean,
}

export const OTPhysicalExaminationPediatrics2InitialViewData: OTPhysicalExaminationPediatrics2ViewDataType = {
    CtPersonalAndSocial: "",
    CtPersonalAndSocialDisabled: false,
    CtPersonalAndSocialOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalPediatric213: "",
    OtPhysicalPediatric213Disabled: false,
    OtPhysicalPediatric214: "",
    OtPhysicalPediatric214Disabled: false,
    CtFineMotor: "",
    CtFineMotorDisabled: false,
    CtFineMotorOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalPediatric219: "",
    OtPhysicalPediatric219Disabled: false,
    CtGrossMotor: "",
    CtGrossMotorDisabled: false,
    CtGrossMotorOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalPediatric225: "",
    OtPhysicalPediatric225Disabled: false,
    CtLanguage: "",
    CtLanguageDisabled: false,
    CtLanguageOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalPediatric232: "",
    OtPhysicalPediatric232Disabled: false,
    OtPhysicalPediatric233: "",
    OtPhysicalPediatric233Disabled: false,
}

const Sub = (props: any) => {
    const children = React.Children.toArray(props.children)
    return (
        <div className={styles.container}>
            <div className={styles.OtPhysicalPediatric21}>
                <div
                    style={{backgroundColor: "#F3F3F3", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.OtPhysicalPediatric21}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Assessment</strong></p>
                </div>
            </div>
            <div className={styles.OtPhysicalPediatric22}>
                <div
                    style={{border: "solid #BDBDBD 1px", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.CtPersonalAndSocial}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtPersonalAndSocial}
                    disabled={ props.viewData.CtPersonalAndSocialDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationPediatrics2",
                                name: "CtPersonalAndSocial",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtPersonalAndSocialOptions}
                />
            </div>
            <div className={styles.OtPhysicalPediatric26}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Developmental assessment</strong></p>
                </div>
            </div>
            <div className={styles.OtPhysicalPediatric27}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Personal and social</strong></p>
                </div>
            </div>
            <div className={styles.OtPhysicalPediatric211}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Emotions and social skills</p>
                </div>
            </div>
            <div className={styles.OtPhysicalPediatric212}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p>Self-care</p>
                </div>
            </div>
            <div className={styles.OtPhysicalPediatric213}>
                <Input
                    value={props.viewData.OtPhysicalPediatric213}
                    disabled={ props.viewData.OtPhysicalPediatric213Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPediatrics2',
                            name: 'OtPhysicalPediatric213',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalPediatric214}>
                <Input
                    value={props.viewData.OtPhysicalPediatric214}
                    disabled={ props.viewData.OtPhysicalPediatric214Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPediatrics2',
                            name: 'OtPhysicalPediatric214',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CtFineMotor}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtFineMotor}
                    disabled={ props.viewData.CtFineMotorDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationPediatrics2",
                                name: "CtFineMotor",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtFineMotorOptions}
                />
            </div>
            <div className={styles.OtPhysicalPediatric216}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Fine motor and adaptive</strong></p>
                </div>
            </div>
            <div className={styles.OtPhysicalPediatric219}>
                <Input
                    value={props.viewData.OtPhysicalPediatric219}
                    disabled={ props.viewData.OtPhysicalPediatric219Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPediatrics2',
                            name: 'OtPhysicalPediatric219',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CtGrossMotor}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtGrossMotor}
                    disabled={ props.viewData.CtGrossMotorDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationPediatrics2",
                                name: "CtGrossMotor",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtGrossMotorOptions}
                />
            </div>
            <div className={styles.OtPhysicalPediatric223}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Gross motor</strong></p>
                </div>
            </div>
            <div className={styles.OtPhysicalPediatric225}>
                <Input
                    value={props.viewData.OtPhysicalPediatric225}
                    disabled={ props.viewData.OtPhysicalPediatric225Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPediatrics2',
                            name: 'OtPhysicalPediatric225',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CtLanguage}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtLanguage}
                    disabled={ props.viewData.CtLanguageDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationPediatrics2",
                                name: "CtLanguage",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtLanguageOptions}
                />
            </div>
            <div className={styles.OtPhysicalPediatric229}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Language</strong></p>
                </div>
            </div>
            <div className={styles.OtPhysicalPediatric230}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Cognition and perception</p>
                </div>
            </div>
            <div className={styles.OtPhysicalPediatric231}>
                <div
                    style={{ height: "90%", width: "100%"}}
                >
                    <p>Communication</p>
                </div>
            </div>
            <div className={styles.OtPhysicalPediatric232}>
                <Input
                    value={props.viewData.OtPhysicalPediatric232}
                    disabled={ props.viewData.OtPhysicalPediatric232Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPediatrics2',
                            name: 'OtPhysicalPediatric232',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalPediatric233}>
                <Input
                    value={props.viewData.OtPhysicalPediatric233}
                    disabled={ props.viewData.OtPhysicalPediatric233Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPediatrics2',
                            name: 'OtPhysicalPediatric233',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
        </div>
    )
}

export default Sub

const getTsName = (cssClass: string) => {
    return cssClass.split('-').map((s) => (
        s.charAt(0).toUpperCase() + s.slice(1)
    )).join('')
}
