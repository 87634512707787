import React from 'react'
import {
   Button,
} from 'semantic-ui-react'



import styles from './POPhysicalexaminationOrthoticFootOrthotic2SIDE.module.css'

export interface POPhysicalexaminationOrthoticFootOrthotic2SIDEViewDataType {
    SaveRequestDisabled: boolean,
}

export const POPhysicalexaminationOrthoticFootOrthotic2SIDEInitialViewData: POPhysicalexaminationOrthoticFootOrthotic2SIDEViewDataType = {
    SaveRequestDisabled: false,
}

const Sub = (props: any) => {
    const children = React.Children.toArray(props.children)
    return (
        <div className={styles.container}>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Side1}>
                <div
                    style={{background: "#F3F3F3", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Side3}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Position</strong></p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Side4}>
                {children[0]}
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Side8}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Dorsum</strong></p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Side9}>
                {children[1]}
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Side21}>
                {children[2]}
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Side33}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Plantar</strong></p>
                </div>
            </div>
            <div className={styles.SaveRequest}>
                <Button
                    disabled={ props.viewData.SaveRequestDisabled }
                    onClick={(e) => { props.onEvent({
                        message: 'clickButton',
                        params: {
                            view: 'POPhysicalexaminationOrthoticFootOrthotic2SIDE',
                            name: 'SaveRequest'
                        }
                    })}}
                    color="green"
                    style={{width: "100%"}}
                >
                    Save
                </Button>
            </div>
        </div>
    )
}

export default Sub

const getTsName = (cssClass: string) => {
    return cssClass.split('-').map((s) => (
        s.charAt(0).toUpperCase() + s.slice(1)
    )).join('')
}
