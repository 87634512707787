import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Dropdown,
  Button,
  Label
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardStockManagementTabLotUX = (props: any) => {
    return(
      <div
        style={{width:"100%", padding: "10px",  height: "100%"}}>
        <div
          className="ui form">

          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label
                style={{marginRight: "1rem"}}>
                Lot
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Dropdown
                disabled={props.readOnly || false}
                fluid={true}
                name="lotNo"
                onChange={props.onChangeValue}
                options={props.lotNoOptions || []}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.lotNo || ""}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{marginRight: "1rem", marginLeft: "1rem"}}>
                สถานะ
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Dropdown
                disabled={props.readOnly || false}
                fluid={true}
                name="status"
                onChange={props.onChangeValue}
                options={props.statusOptions || []}
                selection={true}
                style={{width: "100%"}}
                value={props.status || ""}>
              </Dropdown>
            </FormField>
            <FormField>
              <label
                style={{marginRight: "2rem"}}>

              </label>
            </FormField>
            <FormField>
              <div>
                {props.ButtonSearch}
              </div>
            </FormField>
            <FormField>
              <Button
                color="yellow"
                disabled={props.disabledClear || false}
                onClick={props.onClear}>
                เคลียร์
              </Button>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField>
              <Label
                style={{backgroundColor: "#C3F4FF", color: "rgba(0,0,0,.87)"}}>
                <label
                  style={{marginRight: "1rem", }}>
                  รหัสสินค้า
                </label>
                <label>
                  {props.code || "-"}
                </label>
              </Label>
            </FormField>
            <FormField>
              <Label
                style={{backgroundColor: "#C3F4FF", color: "rgba(0,0,0,.87)"}}>
                <label
                  style={{marginRight: "1rem", }}>
                  ชื่อ
                </label>
                <label>
                  {props.name || "-"}
                </label>
              </Label>
            </FormField>
            <FormField>
              <Label
                style={{backgroundColor: "#C3F4FF", color: "rgba(0,0,0,.87)"}}>
                <label
                  style={{marginRight: "1rem", }}>
                  Unit
                </label>
                <label>
                  {props.unit || "-"}
                </label>
              </Label>
            </FormField>
            <FormField>
              <Label
                style={{backgroundColor: "#C3F4FF", color: "rgba(0,0,0,.87)"}}>
                <label
                  style={{marginRight: "1rem", }}>
                  Product type
                </label>
                <label>
                  {props.productType || "-"}
                </label>
              </Label>
            </FormField>
          </FormGroup>
        </div>
        <div
          className="ui form">

          <Table
            data={props.productLotList || []}
            getTrProps={props.getTrProps}
            headers="Code,Name,Storage,Lot No.,Expiry date,Balance,,Active"
            keys="code,name,storage,lot_no,expired_date,balance,action,active"
            minRows={5}
            showPagination={false}
            style={{height: "250px"}}
            widths="100,150,150,150,150,150,,100">
          </Table>
        </div>
      </div>
    )
}

CardStockManagementTabLotUX.displayName = "CardStockManagementTabLotUX";
export default React.memo(CardStockManagementTabLotUX)

export const screenPropsDefault = {}

/* Date Time : Thu Oct 31 2024 11:36:42 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width:\"100%\", padding: \"10px\",  height: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 2,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormField",
      "parent": 2,
      "props": {
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormField",
      "parent": 2,
      "props": {
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "label",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "Lot"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"1rem\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "Dropdown",
      "parent": 4,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.readOnly || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "lotNo"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.lotNoOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.lotNo || \"\""
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "label",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะ"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"1rem\", marginLeft: \"1rem\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "Dropdown",
      "parent": 6,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.readOnly || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "status"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.statusOptions || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.status || \"\""
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "Button",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "เคลียร์"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledClear || false"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClear"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "FormGroup",
      "parent": 1,
      "props": {
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "FormField",
      "parent": 16,
      "props": {
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "FormField",
      "parent": 16,
      "props": {
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "FormField",
      "parent": 16,
      "props": {
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "FormField",
      "parent": 16,
      "props": {
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "Label",
      "parent": 17,
      "props": {
        "size": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#C3F4FF\", color: \"rgba(0,0,0,.87)\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "label",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": "รหัสสินค้า"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"1rem\", }"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "label",
      "parent": 21,
      "props": {
        "children": {
          "type": "code",
          "value": "props.code || \"-\""
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "Label",
      "parent": 18,
      "props": {
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#C3F4FF\", color: \"rgba(0,0,0,.87)\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "Label",
      "parent": 19,
      "props": {
        "size": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#C3F4FF\", color: \"rgba(0,0,0,.87)\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "Label",
      "parent": 20,
      "props": {
        "size": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#C3F4FF\", color: \"rgba(0,0,0,.87)\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "label",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"1rem\", }"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "label",
      "parent": 24,
      "props": {
        "children": {
          "type": "code",
          "value": "props.name || \"-\""
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "label",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": "Unit"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"1rem\", }"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "label",
      "parent": 25,
      "props": {
        "children": {
          "type": "code",
          "value": "props.unit || \"-\""
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "label",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": "Product type"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"1rem\", }"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "label",
      "parent": 26,
      "props": {
        "children": {
          "type": "code",
          "value": "props.productType || \"-\""
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 34,
      "name": "Table",
      "parent": 33,
      "props": {
        "data": {
          "type": "code",
          "value": "props.productLotList || []"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getTrProps"
        },
        "headers": {
          "type": "value",
          "value": "Code,Name,Storage,Lot No.,Expiry date,Balance,,Active"
        },
        "keys": {
          "type": "value",
          "value": "code,name,storage,lot_no,expired_date,balance,action,active"
        },
        "minRows": {
          "type": "code",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"250px\"}"
        },
        "widths": {
          "type": "value",
          "value": "100,150,150,150,150,150,,100"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "FormField",
      "parent": 2,
      "props": {
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "label",
      "parent": 35,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"2rem\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSearch"
        }
      },
      "seq": 37,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": true,
  "name": "CardStockManagementTabLotUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
