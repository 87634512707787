export const patientTypeOptions = [
  {
    key: 1,
    text: "OPD Onsite",
    value: "on_site",
  },
  { key: 2, text: "Telemed", value: "telemed" },
  // { key: 3, text: "Refill med", value: 3 },
];

export const deliveryTypeOptions = [
  {
    key: 1,
    text: "ส่งด่วน",
    value: 1,
  },
  { key: 2, text: "ส่งธรรมดา", value: 2 },
];

const IMAGES = {
  check: "/static/images/transport/check.png",
  dismiss: "/static/images/transport/dismiss.png",
  stop_hand: "/static/images/transport/stop-hand.png",
}

export const deliveryStatusOptions = [
  {
    key: 1,
    text: "รอจัดส่ง",
    value: "INITIAL",
    color: "rgba(0, 0, 0, 1)",
    bg: "rgba(213, 209, 209, 1)",
    img: IMAGES.check
  },
  {
    key: 2,
    text: "กำลังหาคนขับ",
    value: "ASSIGN_DRIVER",
    color: "rgba(255, 255, 255, 1)",
    bg: "rgba(234, 169, 77, 1)",
    img: IMAGES.check
  },
  {
    key: 3,
    text: "คนขับกำลังไปรับยา",
    value: "WAIT_DRIVER",
    color: "rgba(255, 255, 255, 1)",
    bg: "rgba(82, 161, 238, 1)",
    img: IMAGES.check
  },
  {
    key: 4,
    text: "คนขับรับยาแล้ว",
    value: "PICKED_UP",
    color: "rgba(255, 255, 255, 1)",
    bg: "rgba(43, 102, 190, 1)",
    img: IMAGES.check
  },
  {
    key: 5,
    text: "คนขับรับยาแล้ว > 2 วัน",
    value: "PICKED_UP",
    color: "rgba(255, 255, 255, 1)",
    bg: "rgba(43, 102, 190, 1)",
    img: IMAGES.check
  },
  {
    key: 6,
    text: "คนไข้รับยาแล้ว",
    value: "DELIVERED",
    color: "rgba(255, 255, 255, 1)",
    bg: "rgba(82, 178, 167, 1)",
    img: IMAGES.check
  },
  {
    key: 7,
    text: "จัดส่งไม่สำเร็จ",
    value: "DELIVERY_FAILED",
    color: "rgba(218, 0, 0, 1)",
    bg: "rgba(255, 209, 210, 1)",
    img: IMAGES.dismiss
  },
  {
    key: 8,
    text: "ยกเลิกโดยโรงพยาบาล",
    value: "CANCELED",
    color: "rgba(218, 0, 0, 1)",
    bg: "rgba(255, 209, 210, 1)",
    img: IMAGES.dismiss
  },
  {
    key: 9,
    text: "ยกเลิกโดยคนขับ",
    value: "REJECT_DRIVER",
    color: "rgba(218, 0, 0, 1)",
    bg: "rgba(255, 209, 210, 1)",
    img: IMAGES.dismiss
  },
  {
    key: 10,
    text: "เรียกรถเข้ารับไม่สำเร็จ",
    value: "ERROR",
    color: "rgba(218, 0, 0, 1)",
    bg: "rgba(255, 209, 210, 1)",
    img: IMAGES.stop_hand
  },
];

export const consultStatusOptions = [
  {
    key: 1,
    text: "รอรับคำปรึกษายา",
    value: 1,
  },
  { key: 2, text: "กำลังให้คำปรึกษายา", value: 2 },
  { key: 3, text: "จบการให้คำปรึกษายา", value: 3 },
];
