import React, { CSSProperties, SyntheticEvent, useMemo, useState } from "react";
import { Button, Dropdown, Icon, Popup } from "semantic-ui-react";

// Common
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

import { buttonStyle } from "./CardSuspending";
import { useIntl } from "react-intl";

// Types
type ButtonSuspendingProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: Function) => any;

  type: "discount" | "coverage";
  noCoverage?: boolean;
  patientId: number;
  suspended: boolean;
  // commonInterface
  buttonLoadCheck?: Record<string, any>;
  errorMessage?: Record<string, any>;
  // options
  activatePrivilegeOptions?: any[];
  suspendPrivilegeOptions?: any[];
};

// Const
const TYPE_NAME = {
  discount: "ส่วนลด",
  coverage: "เงินเชื่อ",
} as const;

const MOD_SUSPENDING = "ModSuspending";

const ButtonSuspending = (props: ButtonSuspendingProps) => {
  const intl = useIntl();
  const [suspend, setSuspend] = useState<boolean>(false);
  const [reason, setReason] = useState<string[]>([]);
  // Mod
  const [openModSuspending, setOpenModSuspending] = useState<boolean>(false);
  const [options, setOptions] = useState<any[]>([]);

  const icon = useMemo(() => {
    if (props.noCoverage) {
      return "minus";
    } else if (props.suspended) {
      return "ban";
    } else {
      return "checkmark";
    }
  }, [props.noCoverage, props.suspended]);

  const color = useMemo(() => {
    if (props.noCoverage) {
      return undefined;
    } else if (props.suspended) {
      return "red";
    } else {
      return "green";
    }
  }, [props.noCoverage, props.suspended]);

  const tooltip = useMemo(() => {
    if (props.noCoverage) {
      return `ไม่มีสิทธิ${TYPE_NAME[props.type]} ไม่สามารถกดได้`;
    } else if (props.suspended) {
      return `ระงับสิทธิ${TYPE_NAME[props.type]} กดเพื่อเปิดใช้งานสิทธิ${
        TYPE_NAME[props.type]
      }`;
    } else {
      return `ใช้งานสิทธิ${TYPE_NAME[props.type]} กดเพื่อระงับการใช้สิทธิ${
        TYPE_NAME[props.type]
      }`;
    }
  }, [props.noCoverage]);

  // Handler
  const handleClick = () => {
    if (props.noCoverage) {
    } else {
      setSuspend(!props.suspended);
      setOptions(
        props.suspended
          ? props.activatePrivilegeOptions || []
          : props.suspendPrivilegeOptions || []
      );
      setOpenModSuspending(true);
    }
  };

  const handleChangeReason = (e: any, data: any) => {
    setReason(data.value);
  };

  const handleAddOptions = (e: SyntheticEvent, v: any) => {
    console.log("handleAddOptions", e, v);

    setOptions([...options, { key: v.value, text: v.value, value: v.value }]);
  };

  const handleConfirmSuspending = () => {
    props.onEvent({
      message: "HandleUpdateEncounterSuspension",
      params: {
        patientId: props.patientId,
        card: MOD_SUSPENDING,
        suspend,
        remark: reason.join(","),
        type: props.type,
        onSuccess: () => setOpenModSuspending(false),
      },
    });
  };

  // Mod
  const handleCloseModSuspending = () => {
    setOpenModSuspending(false);
    props.setProp(`errorMessage.${MOD_SUSPENDING}`, null);
  };

  console.log("ButtonSuspending", props);

  return (
    <>
      {props.patientId ? (
        <Popup
          popperModifiers={{
            preventOverflow: {
              boundariesElement: "offsetParent",
            },
          }}
          size="mini"
          position="bottom left"
          // offset={-14}
          content={<div style={{ minWidth: "max-content" }}>{tooltip}</div>}
          trigger={
            <Button
              size="mini"
              disabled={props.noCoverage}
              basic={true}
              color={color}
              style={buttonStyle}
              onClick={handleClick}
            >
              <Icon name={icon} />
              {TYPE_NAME[props.type]}
            </Button>
          }
        />
      ) : null}

      <ModConfirm
        openModal={openModSuspending}
        titleName={`ยืนยัน ${
          suspend
            ? `ระงับการใช้สิทธิ${TYPE_NAME[props.type]}`
            : `เปิดใช้งานสิทธิ${TYPE_NAME[props.type]}`
        } ?`}
        titleColor="blue"
        size="mini"
        denyButtonColor="red"
        denyButtonText={intl.formatMessage({ id: "ไม่" })}
        approveButton={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleConfirmSuspending}
            // data
            paramKey={`${MOD_SUSPENDING}_SUSPENDED`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${MOD_SUSPENDING}_SUSPENDED`]
            }
            // config
            color={"green"}
            name="SUSPENDED"
            size="medium"
            title={intl.formatMessage({ id: "ตกลง" })}
            basic={true}
          />
        }
        content={
          <div
            style={{
              margin: "0rem 0 -1rem",
            }}
          >
            <ErrorMessage
              error={props.errorMessage?.[MOD_SUSPENDING]}
              style={{ marginTop: "-1rem" }}
            />
            <Dropdown
              allowAdditions={true}
              clearable={true}
              fluid={true}
              multiple={true}
              onAddItem={handleAddOptions}
              onChange={handleChangeReason}
              options={options}
              search={true}
              selection={true}
              value={reason}
            />
          </div>
        }
        onApprove={handleConfirmSuspending}
        onDeny={handleCloseModSuspending}
        onCloseWithDimmerClick={handleCloseModSuspending}
      />
    </>
  );
};

export default React.memo(ButtonSuspending);
