export default class CardNurseNoteController {
  constructor(props) {
    this.prxManager = props.prxManager;
    this.ptmManager = props.ptmManager;
  }

  geNurseNoteList = async ({ apiToken, encounterId } = {}) => {
    const [response, error, network] = await this.prxManager.geNurseNoteList({
      apiToken,
      encounterId
    });
    return [response, error, network];
  };

  geNurseNoteEncounter = async ({ apiToken, encounterId } = {}) => {
    const [response, error, network] = await this.prxManager.geNurseNoteEncounter({
      apiToken,
      encounterId
    });
    return [response, error, network];
  };

  postNurseNoteItem = async ({
    date,
    time,
    workingShift,
    plan,
    goal,
    diagnosis,
    username,
    password,
    nurseNoteId,
    encounter,
    division,
    token,
    doctorOrderId,
    apiToken,
    userToken
  } = {}) => {
    const [response, error, network] = await this.ptmManager.postNurseNoteItem({
      date,
      time,
      workingShift,
      plan,
      goal,
      diagnosis,
      username,
      password,
      nurseNoteId,
      encounter,
      division,
      token,
      doctorOrderId,
      userToken,
      apiToken
    });
    return [response, error, network];
  };

  getNurseNoteItem = async ({
    nurseNoteId,
    apiToken,
    encounterId
  }) => {
    const [response, error, network] = await this.ptmManager.getNurseNoteItem({
      nurseNoteId,
      encounterId,
      apiToken
    });
    return [response, error, network];
  }

  putNurseNoteItem = async ({
    saveData,
    username,
    password,
    nurseNoteId,
    encounter,
    token,
    date,
    time,
    nurseNoteItemId,
    apiToken,
    division,
    userToken
  } = {}) => {
    console.log(saveData, "save")
    const [data, error, network] = await this.ptmManager.putNurseNoteItem({
      ...saveData,
      username,
      password,
      date,
      time,
      nurseNoteId,
      encounter,
      token,
      nurseNoteItemId,
      apiToken,
      division,
      userToken
    });
    return [data, error, network];
  };

  // getNurseNoteItem = async ({ nurseNoteId, fromDate, toDate } = {}) => {
  //   const [response, error] = await this.ptmManager.getNurseNoteItem({
  //     nurseNoteId,
  //     fromDate,
  //     toDate
  //   });
  //   return [response, error];
  // };
}
