import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button,
  Icon
} from 'semantic-ui-react'

const CardPreAssessmentHeaderUX = (props: any) => {
    return(
      <div
        style={{"padding":"6px 10px 6px 20px","borderBottomLeftRadius":"0","borderBottomRightRadius":"0","border":"1px solid rgba(34,36,38,.15)",...(props.style || {})}}>
        <div
          style={{ display: "flex", justifyContent: "space-between", alignItems: "center",   minHeight: "40px"}}>
          
          <div
            style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
            Nursing Admission Assessment
          </div>
          <div
            style={{ display: "flex" , alignItems: "center"}}>
            
            <div
              style={{ textAlign: "right" }}>
              
              <div
                style={{ display: props.PreAssessmentSequence?.id ? "block" :  "none"}}>
                {props.edit_user_name}
              </div>
              <div
                style={{ display: props.PreAssessmentSequence?.id ? "block" :  "none"}}>
                {props.edited}
              </div>
            </div>
            <div
              style={{ margin: "0px 10px" }}>
              {props.buttonSave}
            </div>
            <Button
              disabled={props.readOnly}
              onClick={props.getActionLog}
              size="small"
              style={{display: props.readOnly ? "none" : "", height: "fit-content"}}>
              Log
            </Button>
            <div
              style={{marginLeft: "1rem", display: props.closeIcon ? "": "none"}}>
              
              <Icon
                className="red large ignore-prevent"
                link={true}
                name="close"
                onClick={props.onClose}>
              </Icon>
            </div>
          </div>
        </div>
      </div>
    )
}


export default CardPreAssessmentHeaderUX

export const screenPropsDefault = {}

/* Date Time : Wed May 10 2023 08:11:15 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"padding\":\"6px 10px 6px 20px\",\"borderBottomLeftRadius\":\"0\",\"borderBottomRightRadius\":\"0\",\"border\":\"1px solid rgba(34,36,38,.15)\",...(props.style || {})}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 709,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"space-between\", alignItems: \"center\",   minHeight: \"40px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 710,
      "name": "div",
      "parent": 709,
      "props": {
        "children": {
          "type": "value",
          "value": "Nursing Admission Assessment"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"1.2rem\", fontWeight: \"bold\" }"
        }
      },
      "seq": 710,
      "void": false
    },
    {
      "from": null,
      "id": 711,
      "name": "div",
      "parent": 709,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" , alignItems: \"center\"}"
        }
      },
      "seq": 711,
      "void": false
    },
    {
      "from": null,
      "id": 712,
      "name": "div",
      "parent": 711,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ textAlign: \"right\" }"
        }
      },
      "seq": 712,
      "void": false
    },
    {
      "from": null,
      "id": 715,
      "name": "div",
      "parent": 712,
      "props": {
        "children": {
          "type": "code",
          "value": "props.edit_user_name"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAssessmentSequence?.id ? \"block\" :  \"none\"}"
        }
      },
      "seq": 715,
      "void": false
    },
    {
      "from": null,
      "id": 716,
      "name": "div",
      "parent": 712,
      "props": {
        "children": {
          "type": "code",
          "value": "props.edited"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAssessmentSequence?.id ? \"block\" :  \"none\"}"
        }
      },
      "seq": 716,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 718,
      "name": "Button",
      "parent": 711,
      "props": {
        "children": {
          "type": "value",
          "value": "Log"
        },
        "disabled": {
          "type": "code",
          "value": "props.readOnly"
        },
        "icon": {
          "type": "code",
          "value": ""
        },
        "onClick": {
          "type": "code",
          "value": "props.getActionLog"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{display: props.readOnly ? \"none\" : \"\", height: \"fit-content\"}"
        }
      },
      "seq": 720,
      "void": false
    },
    {
      "from": null,
      "id": 719,
      "name": "div",
      "parent": 711,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 10px\" }"
        }
      },
      "seq": 717,
      "void": false
    },
    {
      "from": null,
      "id": 721,
      "name": "div",
      "parent": 711,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"1rem\", display: props.closeIcon ? \"\": \"none\"}"
        }
      },
      "seq": 721,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 722,
      "name": "Icon",
      "parent": 721,
      "props": {
        "className": {
          "type": "value",
          "value": "red large ignore-prevent"
        },
        "link": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "close"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        }
      },
      "seq": 722,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 85,
  "isMounted": false,
  "memo": false,
  "name": "CardPreAssessmentHeaderUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
