import React from 'react'
import {
   Button,
   Checkbox,
   Dropdown,
   Input,
   Radio,
   TextArea,
} from 'semantic-ui-react'

import DateTextBox from '../common/DateTextBox';
import TimeFreeTextBox24 from '../common/TimeFreeTextBox24';
import { useIntl } from "react-intl";


import styles from './CardPORehabilitationTreatmentAnkleFootOrthotic.module.css'

export interface PORehabilitationTreatmentAnkleFootOrthoticViewDataType {
    PoRehabilitationTreatmentAnkleFootOrthotic2: string,
    PoRehabilitationTreatmentAnkleFootOrthotic2Disabled: boolean,
    PoRehabilitationTreatmentAnkleFootOrthotic4: string,
    PoRehabilitationTreatmentAnkleFootOrthotic4Disabled: boolean,
    CtTreatmentAnklefootOrthosis: string,
    CtTreatmentAnklefootOrthosisDisabled: boolean,
    CtTreatmentAnklefootOrthosisOptions: any,
    PoRehabilitationTreatmentAnkleFootOrthotic9: string,
    PoRehabilitationTreatmentAnkleFootOrthotic9Disabled: boolean,
    PoRehabilitationTreatmentAnkleFootOrthotic11: string,
    PoRehabilitationTreatmentAnkleFootOrthotic11Disabled: boolean,
    Ct3PainScore: string,
    Ct3PainScoreDisabled: boolean,
    Ct3PainScoreOptions: any,
    PoRehabilitationTreatmentAnkleFootOrthotic16: string,
    PoRehabilitationTreatmentAnkleFootOrthotic16Disabled: boolean,
    PoRehabilitationTreatmentAnkleFootOrthotic19: string,
    PoRehabilitationTreatmentAnkleFootOrthotic19Disabled: boolean,
    PoRehabilitationTreatmentAnkleFootOrthotic21: boolean,
    PoRehabilitationTreatmentAnkleFootOrthotic21Disabled: boolean,
    PoRehabilitationTreatmentAnkleFootOrthotic23: boolean,
    PoRehabilitationTreatmentAnkleFootOrthotic23Disabled: boolean,
    PoRehabilitationTreatmentAnkleFootOrthotic26: string,
    PoRehabilitationTreatmentAnkleFootOrthotic26Disabled: boolean,
    PoRehabilitationTreatmentAnkleFootOrthotic27: boolean,
    PoRehabilitationTreatmentAnkleFootOrthotic27Disabled: boolean,
    PoRehabilitationTreatmentAnkleFootOrthotic29: string,
    PoRehabilitationTreatmentAnkleFootOrthotic29Disabled: boolean,
    PoRehabilitationTreatmentAnkleFootOrthotic31: boolean,
    PoRehabilitationTreatmentAnkleFootOrthotic31Disabled: boolean,
    PoRehabilitationTreatmentAnkleFootOrthotic33: boolean,
    PoRehabilitationTreatmentAnkleFootOrthotic33Disabled: boolean,
    SaveRequestDisabled: boolean,
}

export const PORehabilitationTreatmentAnkleFootOrthoticInitialViewData: PORehabilitationTreatmentAnkleFootOrthoticViewDataType = {
    PoRehabilitationTreatmentAnkleFootOrthotic2: "",
    PoRehabilitationTreatmentAnkleFootOrthotic2Disabled: false,
    PoRehabilitationTreatmentAnkleFootOrthotic4: "",
    PoRehabilitationTreatmentAnkleFootOrthotic4Disabled: false,
    CtTreatmentAnklefootOrthosis: "",
    CtTreatmentAnklefootOrthosisDisabled: false,
    CtTreatmentAnklefootOrthosisOptions: [{key: 0, value: "A", text: "A"}],
    PoRehabilitationTreatmentAnkleFootOrthotic9: "",
    PoRehabilitationTreatmentAnkleFootOrthotic9Disabled: false,
    PoRehabilitationTreatmentAnkleFootOrthotic11: "",
    PoRehabilitationTreatmentAnkleFootOrthotic11Disabled: false,
    Ct3PainScore: "",
    Ct3PainScoreDisabled: false,
    Ct3PainScoreOptions: [{key: 0, value: "A", text: "A"}],
    PoRehabilitationTreatmentAnkleFootOrthotic16: "",
    PoRehabilitationTreatmentAnkleFootOrthotic16Disabled: false,
    PoRehabilitationTreatmentAnkleFootOrthotic19: "",
    PoRehabilitationTreatmentAnkleFootOrthotic19Disabled: false,
    PoRehabilitationTreatmentAnkleFootOrthotic21: false,
    PoRehabilitationTreatmentAnkleFootOrthotic21Disabled: false,
    PoRehabilitationTreatmentAnkleFootOrthotic23: false,
    PoRehabilitationTreatmentAnkleFootOrthotic23Disabled: false,
    PoRehabilitationTreatmentAnkleFootOrthotic26: "",
    PoRehabilitationTreatmentAnkleFootOrthotic26Disabled: false,
    PoRehabilitationTreatmentAnkleFootOrthotic27: false,
    PoRehabilitationTreatmentAnkleFootOrthotic27Disabled: false,
    PoRehabilitationTreatmentAnkleFootOrthotic29: "",
    PoRehabilitationTreatmentAnkleFootOrthotic29Disabled: false,
    PoRehabilitationTreatmentAnkleFootOrthotic31: false,
    PoRehabilitationTreatmentAnkleFootOrthotic31Disabled: false,
    PoRehabilitationTreatmentAnkleFootOrthotic33: false,
    PoRehabilitationTreatmentAnkleFootOrthotic33Disabled: false,
    SaveRequestDisabled: false,
}

const Sub = (props: any) => {
    const children = React.Children.toArray(props.children)
    return (
        <div className={styles.container}>
            <div className={styles.PoRehabilitationTreatmentAnkleFootOrthotic1}>
                <div
                    style={{backgroundColor: "#F3F3F3", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.PoRehabilitationTreatmentAnkleFootOrthotic2}>
                <Input
                    value={props.viewData.PoRehabilitationTreatmentAnkleFootOrthotic2}
                    disabled={ props.viewData.PoRehabilitationTreatmentAnkleFootOrthotic2Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PORehabilitationTreatmentAnkleFootOrthotic',
                            name: 'PoRehabilitationTreatmentAnkleFootOrthotic2',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoRehabilitationTreatmentAnkleFootOrthotic3}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p><strong>Problem*</strong></p>
                </div>
            </div>
            <div className={styles.PoRehabilitationTreatmentAnkleFootOrthotic4}>
                <Input
                    value={props.viewData.PoRehabilitationTreatmentAnkleFootOrthotic4}
                    disabled={ props.viewData.PoRehabilitationTreatmentAnkleFootOrthotic4Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PORehabilitationTreatmentAnkleFootOrthotic',
                            name: 'PoRehabilitationTreatmentAnkleFootOrthotic4',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoRehabilitationTreatmentAnkleFootOrthotic5}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Goal of Treatment*</strong></p>
                </div>
            </div>
            <div className={styles.CtTreatmentAnklefootOrthosis}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtTreatmentAnklefootOrthosis}
                    disabled={ props.viewData.CtTreatmentAnklefootOrthosisDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "PORehabilitationTreatmentAnkleFootOrthotic",
                                name: "CtTreatmentAnklefootOrthosis",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtTreatmentAnklefootOrthosisOptions}
                />
            </div>
            <div className={styles.PoRehabilitationTreatmentAnkleFootOrthotic9}>
                <TextArea
                    value={props.viewData.PoRehabilitationTreatmentAnkleFootOrthotic9}
                    disabled={ props.viewData.PoRehabilitationTreatmentAnkleFootOrthotic9Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PORehabilitationTreatmentAnkleFootOrthotic',
                            name: 'PoRehabilitationTreatmentAnkleFootOrthotic9',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </TextArea>
            </div>
            <div className={styles.PoRehabilitationTreatmentAnkleFootOrthotic10}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p><strong>Treatment*</strong></p>
                </div>
            </div>
            {/* <div className={styles.PoRehabilitationTreatmentAnkleFootOrthotic11}>
                <Input
                    value={props.viewData.PoRehabilitationTreatmentAnkleFootOrthotic11}
                    disabled={ props.viewData.PoRehabilitationTreatmentAnkleFootOrthotic11Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PORehabilitationTreatmentAnkleFootOrthotic',
                            name: 'PoRehabilitationTreatmentAnkleFootOrthotic11',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoRehabilitationTreatmentAnkleFootOrthotic12}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Post of treatment</strong></p>
                </div>
            </div> */}
            {/* <div className={styles.Ct3PainScore}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct3PainScore}
                    disabled={ props.viewData.Ct3PainScoreDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "PORehabilitationTreatmentAnkleFootOrthotic",
                                name: "Ct3PainScore",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct3PainScoreOptions}
                />
            </div>
            <div className={styles.PoRehabilitationTreatmentAnkleFootOrthotic16}>
                <Input
                    value={props.viewData.PoRehabilitationTreatmentAnkleFootOrthotic16}
                    disabled={ props.viewData.PoRehabilitationTreatmentAnkleFootOrthotic16Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PORehabilitationTreatmentAnkleFootOrthotic',
                            name: 'PoRehabilitationTreatmentAnkleFootOrthotic16',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoRehabilitationTreatmentAnkleFootOrthotic17}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Pain score</strong></p>
                </div>
            </div>
            <div className={styles.PoRehabilitationTreatmentAnkleFootOrthotic18}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p><strong>Other</strong></p>
                </div>
            </div> */}
            <div className={styles.PoRehabilitationTreatmentAnkleFootOrthotic19}>
                <TextArea
                    value={props.viewData.PoRehabilitationTreatmentAnkleFootOrthotic19}
                    disabled={ props.viewData.PoRehabilitationTreatmentAnkleFootOrthotic19Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PORehabilitationTreatmentAnkleFootOrthotic',
                            name: 'PoRehabilitationTreatmentAnkleFootOrthotic19',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </TextArea>
            </div>
            <div className={styles.PoRehabilitationTreatmentAnkleFootOrthotic20}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p><strong>Note</strong></p>
                </div>
            </div>
            <div className={styles.PoRehabilitationTreatmentAnkleFootOrthotic21}>
                <Checkbox 
                    style={{ height: "100%", width: "100%"}}
                    checked={props.viewData.PoRehabilitationTreatmentAnkleFootOrthotic21}
                    disabled={ props.viewData.PoRehabilitationTreatmentAnkleFootOrthotic21Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PORehabilitationTreatmentAnkleFootOrthotic",
                                name: "PoRehabilitationTreatmentAnkleFootOrthotic21"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoRehabilitationTreatmentAnkleFootOrthotic22}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>N/A</p>
                </div>
            </div>
            <div className={styles.PoRehabilitationTreatmentAnkleFootOrthotic23}>
                <Radio 
                    style={{ height: "100%", width: "100%"}}
                    checked={props.viewData.PoRehabilitationTreatmentAnkleFootOrthotic23}
                    disabled={ props.viewData.PoRehabilitationTreatmentAnkleFootOrthotic23Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "radio",
                            params: {
                                view: "PORehabilitationTreatmentAnkleFootOrthotic",
                                name: "PoRehabilitationTreatmentAnkleFootOrthotic23"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoRehabilitationTreatmentAnkleFootOrthotic24}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Note to doctor</strong></p>
                </div>
            </div>
            <div className={styles.PoRehabilitationTreatmentAnkleFootOrthotic25}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Yes, able to perform treatment follow doctor’s presciption</p>
                </div>
            </div>
            <div className={styles.PoRehabilitationTreatmentAnkleFootOrthotic26}>
                <Input
                    value={props.viewData.PoRehabilitationTreatmentAnkleFootOrthotic26}
                    disabled={ props.viewData.PoRehabilitationTreatmentAnkleFootOrthotic26Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PORehabilitationTreatmentAnkleFootOrthotic',
                            name: 'PoRehabilitationTreatmentAnkleFootOrthotic26',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoRehabilitationTreatmentAnkleFootOrthotic27}>
                <Radio 
                    style={{ height: "100%", width: "100%"}}
                    checked={props.viewData.PoRehabilitationTreatmentAnkleFootOrthotic27}
                    disabled={ props.viewData.PoRehabilitationTreatmentAnkleFootOrthotic27Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "radio",
                            params: {
                                view: "PORehabilitationTreatmentAnkleFootOrthotic",
                                name: "PoRehabilitationTreatmentAnkleFootOrthotic27"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoRehabilitationTreatmentAnkleFootOrthotic28}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>No,</p>
                </div>
            </div>
            <div className={styles.PoRehabilitationTreatmentAnkleFootOrthotic29}>
                <Input
                    value={props.viewData.PoRehabilitationTreatmentAnkleFootOrthotic29}
                    disabled={ props.viewData.PoRehabilitationTreatmentAnkleFootOrthotic29Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PORehabilitationTreatmentAnkleFootOrthotic',
                            name: 'PoRehabilitationTreatmentAnkleFootOrthotic29',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoRehabilitationTreatmentAnkleFootOrthotic30}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p><strong>Instruction*</strong></p>
                </div>
            </div>
            <div className={styles.PoRehabilitationTreatmentAnkleFootOrthotic31}>
                <Checkbox 
                    style={{ height: "100%", width: "100%"}}
                    checked={props.viewData.PoRehabilitationTreatmentAnkleFootOrthotic31}
                    disabled={ props.viewData.PoRehabilitationTreatmentAnkleFootOrthotic31Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PORehabilitationTreatmentAnkleFootOrthotic",
                                name: "PoRehabilitationTreatmentAnkleFootOrthotic31"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoRehabilitationTreatmentAnkleFootOrthotic32}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Patient and/or family was/were informed about instruction and plan of treatment</p>
                </div>
            </div>
            <div className={styles.PoRehabilitationTreatmentAnkleFootOrthotic33}>
                <Checkbox 
                    style={{ height: "100%", width: "100%"}}
                    checked={props.viewData.PoRehabilitationTreatmentAnkleFootOrthotic33}
                    disabled={ props.viewData.PoRehabilitationTreatmentAnkleFootOrthotic33Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PORehabilitationTreatmentAnkleFootOrthotic",
                                name: "PoRehabilitationTreatmentAnkleFootOrthotic33"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoRehabilitationTreatmentAnkleFootOrthotic34}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Need to be reviewed</p>
                </div>
            </div>
            <div className={styles.PoRehabilitationTreatmentAnkleFootOrthotic53}>
                {children[0]}
            </div>
            <div className={styles.SaveRequest}>
                <Button
                    disabled={ props.viewData.SaveRequestDisabled }
                    onClick={(e) => { props.onEvent({
                        message: 'clickButton',
                        params: {
                            view: 'PORehabilitationTreatmentAnkleFootOrthotic',
                            name: 'SaveRequest'
                        }
                    })}}
                    color="green"
                    style={{ height: "100%", width: "100%"}}
                >
                    Save
                </Button>
            </div>
            <div className={styles.PoRehabilitationTreatmentAnkleFootOrthotic52}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
        </div>
    )
}

export default Sub

const getTsName = (cssClass: string) => {
    return cssClass.split('-').map((s) => (
        s.charAt(0).toUpperCase() + s.slice(1)
    )).join('')
}
