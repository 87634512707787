import WasmController from "react-lib/frameworks/WasmController";
// apis
import ClinicalTermSetView from "issara-sdk/apis/ClinicalTermSetView_core";
import FoodOrderDetail from "issara-sdk/apis/FoodOrderDetail_apps_FOD";
import FoodOrderList from "issara-sdk/apis/FoodOrderList_apps_FOD";
import FoodTypeList from "issara-sdk/apis/FoodTypeList_apps_FOD";
import NutritionList from "issara-sdk/apis/NutritionList_apps_FOD";
import PlanItemDetail from "issara-sdk/apis/PlanItemDetail_core";
import RegularMealList from "issara-sdk/apis/RegularMealList_apps_FOD";
import CONFIG from "config/config";

export type State = {
  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;
  django?: any;

  // common
  selectedEncounter?: any;
  selectedEmr?: any;

  // sequence
  FoodRequestSequence?: {
    sequenceIndex?: string | null;
    plan_item_order?: number | null;
    plan_item_allowed_actions?: string;
    plan_item_status_name?: string;

    // data
    id?: number | null;
    food_type?: number | null;
    routine_type?: any[]; //''
    quantity?: number | null;
    is_extra_meal?: boolean;
    general_time?: any[];
    routine_time?: any[];
    routine_size?: number | null;
    routine_unit?: number | null;
    intensity?: number | null;
    intensity_unit?: number | null;
    mixture?: any[]; //''
    mixture_volume?: string;
    mixture_remark?: string;
    supplement_food?: any[]; // ''
    allergy_food?: any[]; //''
    restriction_food?: any[]; //''
    remark?: string;
    npo_date?: string;
    special_diet?: any[];
    special_diet_remark?: string;
    food_nutrition_control?: any[]; // [{id: "17", nutrition: "17", name: "พลังงาน", unit_name: "Kcal/d", result: "2.00", food_order: null}]
    nutrition_control_remark?: string;
  } | null;

  // options
  foodRequestOptions?: {
    foodTypeOptions?: any[];
    routineTypeOptions?: any[];
    routineTimeOptions?: any[];
    routineUnitOptions?: any[];
    intensityUnitOptions?: any[];
    mixtureOptions?: any[];
    supplementFoodOptions?: any[];
    allergyFoodOptions?: any[];
    restrictionFoodOptions?: any[];

    generalTimeList?: any[];
    specialDietList?: any[];
    nutritionList?: any[];

    openModEditQuantity?: boolean;
  };
};

export const StateInitial: State = {
  // sequence
  FoodRequestSequence: {
    sequenceIndex: null,
    plan_item_order: null,
    plan_item_allowed_actions: "",
    plan_item_status_name: "",

    // data
    id: null,
    food_type: null, // ชนิดอาหาร
    routine_type: [], // ชนิดอาหาร
    quantity: null, // จำนวนมื้อ
    is_extra_meal: false,
    general_time: [], // มื้ออาหาร
    routine_time: [], // มื้ออาหาร
    routine_size: null, // ปริมาตร
    routine_unit: null,
    intensity: null, // ความเข้มข้น
    intensity_unit: null,
    mixture: [], // Mixture
    mixture_volume: "", // Volume
    mixture_remark: "", // หมายเหตุ Mixture
    supplement_food: [], // เสริม
    allergy_food: [], // แพ้
    restriction_food: [], // ข้อจำกัด
    remark: "", // หมายเหตุ
    npo_date: "",
    special_diet: [], // อาหารเฉพาะโรค
    special_diet_remark: "",
    food_nutrition_control: [], // คำสั่งควบคุมต่อวัน
    nutrition_control_remark: "",
  },

  // options
  foodRequestOptions: {
    foodTypeOptions: [], // ชนิดอาหาร
    routineTypeOptions: [], // ชนิดอาหาร
    routineTimeOptions: [], // มื้ออาหาร
    routineUnitOptions: [], //ปริมาตร
    intensityUnitOptions: [], // ความเข้มข้น
    mixtureOptions: [], // Mixture
    supplementFoodOptions: [], // เสริม
    allergyFoodOptions: [], // แพ้
    restrictionFoodOptions: [], // ข้อจำกัด

    generalTimeList: [], // มื้ออาหาร
    specialDietList: [], // อาหารเฉพาะโรค
    nutritionList: [], // คำสั่งควบคุมต่อวัน

    openModEditQuantity: false,
  },
};

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const InitData: Handler = async (controller, params) => {
  const listFoodType = FoodTypeList.list({ apiToken: controller.apiToken });

  const listNutrition = NutritionList.list({ apiToken: controller.apiToken });

  const listRegularMeal = RegularMealList.list({
    apiToken: controller.apiToken,
  });

  const postClinicalTermSet = ClinicalTermSetView.post({
    apiToken: controller.apiToken,
    data: {
      question: [
        "food_routine_type",
        "blenderized_unit",
        "nutrition_unit",
        "blenderized_mixture",
        "supplement_food",
        "allergy_food",
        "restriction_food",
        "special_diet",
      ],
    },
  });

  const [foodType, nutrition, regularMeal, clinicalTermSet] = await Promise.all(
    [listFoodType, listNutrition, listRegularMeal, postClinicalTermSet]
  );

  controller.setState({
    FoodRequestSequence: { sequenceIndex: "AddOrEdit" },
    foodRequestOptions: {
      foodTypeOptions: foodType?.[0]?.items?.map((item: any) => ({
        key: item.id,
        value: item.id,
        text: item.name,
        type: item.type,
      })),
      routineTypeOptions: mapOptions(
        clinicalTermSet?.[0]?.food_routine_type?.items,
        "name"
      ),
      routineTimeOptions: [
        { key: "01:00", value: "01:00", text: "01:00" },
        { key: "02:00", value: "02:00", text: "02:00" },
        { key: "03:00", value: "03:00", text: "03:00" },
        { key: "04:00", value: "04:00", text: "04:00" },
        { key: "05:00", value: "05:00", text: "05:00" },
        { key: "06:00", value: "06:00", text: "06:00" },
        { key: "07:00", value: "07:00", text: "07:00" },
        { key: "08:00", value: "08:00", text: "08:00" },
        { key: "09:00", value: "09:00", text: "09:00" },
        { key: "10:00", value: "10:00", text: "10:00" },
        { key: "11:00", value: "11:00", text: "11:00" },
        { key: "12:00", value: "12:00", text: "12:00" },
        { key: "13:00", value: "13:00", text: "13:00" },
        { key: "14:00", value: "14:00", text: "14:00" },
        { key: "15:00", value: "15:00", text: "15:00" },
        { key: "16:00", value: "16:00", text: "16:00" },
        { key: "17:00", value: "17:00", text: "17:00" },
        { key: "18:00", value: "18:00", text: "18:00" },
        { key: "19:00", value: "19:00", text: "19:00" },
        { key: "20:00", value: "20:00", text: "20:00" },
        { key: "21:00", value: "21:00", text: "21:00" },
        { key: "22:00", value: "22:00", text: "22:00" },
        { key: "23:00", value: "23:00", text: "23:00" },
        { key: "00:00", value: "00:00", text: "00:00" },
      ],
      routineUnitOptions: mapOptions(
        clinicalTermSet?.[0]?.blenderized_unit?.items,
        "id"
      ),
      intensityUnitOptions: mapOptions(
        clinicalTermSet?.[0]?.nutrition_unit?.items,
        "id"
      ),
      mixtureOptions: mapOptions(
        clinicalTermSet?.[0]?.blenderized_mixture?.items,
        "name"
      ),
      supplementFoodOptions: mapOptions(
        clinicalTermSet?.[0]?.supplement_food?.items,
        "name"
      ),
      allergyFoodOptions: mapOptions(
        clinicalTermSet?.[0]?.allergy_food?.items,
        "name"
      ),
      restrictionFoodOptions: mapOptions(
        clinicalTermSet?.[0]?.restriction_food?.items,
        "name"
      ),
      generalTimeList: regularMeal?.[0]?.items,
      specialDietList: clinicalTermSet?.[0]?.special_diet?.items,
      nutritionList: nutrition?.[0]?.items,
      openModEditQuantity: false,
    },
  });

  clear(controller);
};

export const AddOrEdit: Handler = async (controller, params) => {
  const state = controller.getState();

  if (!state.FoodRequestSequence || !state.selectedEmr) {
    return;
  }

  if (params?.action === "SELECTED") {
    let data = { ...params.data };

    let foodOptionsState: any = { ...state.foodRequestOptions };

    const [allergy, allergyOps] = addNewMultiOptions(
      controller,
      data.allergy_food,
      "allergyFoodOptions"
    );
    data.allergy_food = allergy;
    foodOptionsState.allergyFoodOptions = allergyOps;

    const [mixture, mixtureOps] = addNewMultiOptions(
      controller,
      data.mixture,
      "mixtureOptions"
    );
    data.mixture = mixture;
    foodOptionsState.mixtureOptions = mixtureOps;

    const [restriction, restrictionOps] = addNewMultiOptions(
      controller,
      data.restriction_food,
      "restrictionFoodOptions"
    );
    data.restriction_food = restriction;
    foodOptionsState.restrictionFoodOptions = restrictionOps;

    const [routineType, routineTypeOps] = addNewMultiOptions(
      controller,
      data.routine_type,
      "routineTypeOptions"
    );
    data.routine_type = routineType;
    foodOptionsState.routineTypeOptions = routineTypeOps;

    const [supplement, supplementOps] = addNewMultiOptions(
      controller,
      data.supplement_food,
      "supplementFoodOptions"
    );
    data.supplement_food = supplement;
    foodOptionsState.supplementFoodOptions = supplementOps;

    controller.setState({
      FoodRequestSequence: { sequenceIndex: "AddOrEdit", ...data },
      foodRequestOptions: foodOptionsState,
    });
  } else if (params?.action === "SAVE") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_SAVE`]: "LOADING",
      },
    });

    let data: any = {
      encounter: state.selectedEncounter?.id,
      emr: state.selectedEmr.id,
      ...state.FoodRequestSequence,
      allergy_food: params.allergyFood,
    };

    if (data.general_time.length === 0 && data.routine_time.length === 0) {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.card]: "กรุณาระบุมื้ออาหาร",
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "ERROR",
        },
      });
      return;
    }

    data.routine_size = data.routine_size || 0;
    data.intensity = data.intensity || 0;
    data.allergy_food = data.allergy_food.toString();
    data.mixture = data.mixture.toString();
    data.restriction_food = data.restriction_food.toString();
    data.routine_type = data.routine_type.toString();
    data.supplement_food = data.supplement_food.toString();
    data.npo_date = data.npo_date ? data.npo_date : "";

    delete data["statusLabel"];
    delete data["type"];
    delete data["special"];
    delete data["detailText"];
    delete data["menu"];

    if (!state.django?.user?.role_types.includes("DOCTOR")) {
      data.nurse_ordered = true;
    }

    let respAction = null;
    let errAction = null;
    let netwAction = null;

    if (state.FoodRequestSequence?.id) {
      data.action = "EDIT";

      [respAction, errAction, netwAction] = await FoodOrderDetail.update({
        pk: state.FoodRequestSequence.id,
        data: data,
        extra: { division: controller.data.division },
        apiToken: controller.apiToken,
      });
    } else {
      data.action = "REQUEST";
      data.food_nutrition_control = data.food_nutrition_control.map(
        (item: any) => {
          return { ...item, food_order: null, nutrition: item.id };
        }
      );

      [respAction, errAction, netwAction] = await FoodOrderList.create({
        data: data,
        extra: { division: controller.data.division },
        apiToken: controller.apiToken,
      });
    }

    if (errAction) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: errAction },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "ERROR",
        },
      });
    } else {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: null },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "SUCCESS",
        },
      });
      clear(controller);
    }
  } else if (params?.action === "EDIT_QUANTITY") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "LOADING",
      },
    });

    let data: any = {
      encounter: state.selectedEncounter?.id,
      emr: state.selectedEmr.id,
      food_type: state.FoodRequestSequence?.food_type,
      routine_type: state.FoodRequestSequence?.routine_type?.toString(),
      quantity: state.FoodRequestSequence?.quantity,
      general_time: state.FoodRequestSequence?.general_time,
      routine_time: state.FoodRequestSequence?.routine_time,
      action: params.action,
    };

    const [respEditQty, errEditQty, netwEditQty] = await FoodOrderDetail.patch({
      pk: state.FoodRequestSequence.id,
      data: data,
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });

    if (errEditQty) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: errEditQty },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "ERROR",
        },
      });
    } else {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: null },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "SUCCESS",
        },
        foodRequestOptions: {
          ...state.foodRequestOptions,
          openModEditQuantity: false,
        },
      });
      clear(controller);
    }
  } else if (["HOLD", "RESUME", "OFF", "RENEW"].includes(params?.action)) {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "LOADING",
      },
    });

    const [respPlan, errPlan, netwPlan] = await PlanItemDetail.update({
      pk: state.FoodRequestSequence.plan_item_order,
      data: { action: params.action },
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });
    if (errPlan) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: errPlan },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "ERROR",
        },
      });
    } else {
      // #70216 ถ้าเป็น FoodOrder ของ CU ต้องการ OFF ต้อง OFF รายการที่เป็น Pending ด้วย
      if (CONFIG.COMPANY === "CU" && params.action === "OFF") {
        const [respAction, errAction, netwAction] = await FoodOrderDetail.delete({
          pk: state.FoodRequestSequence.id,
          extra: { division: controller.data.division },
          apiToken: controller.apiToken,
        });
      }
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: null },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "SUCCESS",
        },
      });
      clear(controller);
    }
  } else if (params?.action === "CLEAR") {
    clear(controller);
  }
};

// utilities
const mapOptions = (list: any[], valueKey = "id") => {
  return list?.map((item: any) => ({
    key: item.id,
    value: item[valueKey],
    text: item.name,
  }));
};

const clear = (controller: any) => {
  var state = controller.getState();

  controller.setState({
    FoodRequestSequence: {
      sequenceIndex: "AddOrEdit",
      plan_item_order: null,
      plan_item_allowed_actions: "",
      plan_item_status_name: "",

      // data
      id: null,
      food_type: null, // ชนิดอาหาร
      routine_type: [], // ชนิดอาหาร
      quantity: null, // จำนวนมื้อ
      is_extra_meal: false,
      general_time: [], // มื้ออาหาร
      routine_time: [], // มื้ออาหาร
      routine_size: null, // ปริมาตร
      routine_unit: null,
      intensity: null, // ความเข้มข้น
      intensity_unit: null,
      mixture: [], // Mixture
      mixture_volume: "", // Volume
      mixture_remark: "", // หมายเหตุ Mixture
      supplement_food: [], // เสริม
      allergy_food: [], // แพ้
      restriction_food: [], // ข้อจำกัด
      remark: "", // หมายเหตุ
      npo_date: "",
      special_diet: [], // อาหารเฉพาะโรค
      special_diet_remark: "",
      food_nutrition_control: state.foodRequestOptions?.nutritionList || [], // คำสั่งควบคุมต่อวัน
      nutrition_control_remark: "",
    },
  });
};

const addNewMultiOptions = (controller: any, data: string, key: string) => {
  var state = controller.getState();

  let options: any[] = [...(state.foodRequestOptions?.[key] || [])];
  let optionsText: any[] = options?.map((op: any) => op.text);

  const result = data ? data.split(",") : [];
  result?.forEach((item: any) => {
    if (!optionsText.includes(item)) {
      options.push({ key: item, value: item, text: item });
    }
  });

  return [result, options];
};
