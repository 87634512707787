import PropTypes from 'prop-types';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, Divider, Modal, Segment, Form, Icon, Header, Label } from 'semantic-ui-react';
import { useIntl } from "react-intl";

const ModalNewEpisode = forwardRef((props: any, ref) => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const [episodeName, setEpisodeName] = useState('');
  const [selectedType, setSelectedType] = useState(null)

  const {
    titleCard,
    episodeType,
    onNewEpisode,
  } = props;

  useImperativeHandle(ref, () => ({
    openModal() { setIsOpen(true) }
  }));

  const onCreateEpisode = () => {
    if (episodeName === '') { return }
    if ((episodeType.length !== 0) && (selectedType === null)) { return}

    onNewEpisode(episodeName, selectedType)
    setIsOpen(false)
  }

  return (
    <Modal size='tiny' open={isOpen}>
      <Segment>
        <strong>{titleCard}</strong>
        <Icon link name='close' color='red' style={{float: 'right'}} onClick={() => {setIsOpen(false)}}/>
        <Divider/>

        <Form style={{marginTop: '34xp'}}>
          {(episodeType.length > 0) && <Form.Group inline>
            <Form.Field>
                <label>Episode Type</label>
              </Form.Field>
              <Form.Dropdown
                width={6}
                selection
                placeholder='เลือก Episode Type'
                options={episodeType}
                onChange={(e, data) => {setSelectedType(data.value as any)}}
              />
            </Form.Group>
          }

          <Form.Group widths='equal'>
            <Form.Input
              fluid 
              label=''
              placeholder={intl.formatMessage({ id: "ชื่อ Episode" })}
              value={episodeName}
              onChange={(e, data) => {setEpisodeName(data.value)}}
            />
          </Form.Group>

          <Form.Group inline widths='equal'>
            <Form.Field/>
            <Form.Field style={{width: '40%'}}>
              <Button fluid color='green' onClick={onCreateEpisode}>Create</Button>
            </Form.Field>
          </Form.Group>
        </Form>

      </Segment>
    </Modal>
  )
})

ModalNewEpisode.defaultProps = {
  titleCard: 'New Episode',
  episodeType: [],
  onNewEpisode: (name: any) => {}
};
  
ModalNewEpisode.propTypes = {
  titleCard: PropTypes.string,
  episodeType: PropTypes.array,
  onNewEpisode: PropTypes.func
};

export default ModalNewEpisode