import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
  CSSProperties,
} from "react";
import PropTypes from "prop-types";
import { Button, Form, Input } from "semantic-ui-react";

import CardLayout from "react-lib/apps/common/CardLayout";
import DateTextBox from "react-lib/apps/common/DateTextBox";
import ErrorMessage from "react-lib/apps/common/ErrorMessage";

import { toast } from "react-toastify";
import moment from "moment";
import { formatDateToStringBe } from "react-lib/utils/dateUtils";
import { useIntl } from "react-intl";

const styles = {
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  } as CSSProperties,
  customTitle: {
    fontWeight: "bold",
    minWidth: "68px",
    display: "flex",
  } as CSSProperties,
};

const MultiDisciplinaryEditor = forwardRef((props, ref) => {
  const defaultValue = {
    encounter: "",
    date: formatDateToStringBe(moment()),
    end_date: "",
    problem: "",
    plan: "",
    goal: "",
    username: "",
    password: "",
  };

  const intl = useIntl();
  const [saveLoading, setSaveLoading] = useState(false);
  const [multiErrorsMessage, setMultiErrorsMessage] = useState(null);
  const [state, setState] = useState(defaultValue);

  useImperativeHandle(ref, () => ({
    getValue: () => {
      const data = { ...state };
      return data;
    },
    setValue: (data) => {
      setState(data);
    },
    clearValue: () => {
      setState(defaultValue);
    },
  }));

  const onTextChange = (event) => {
    const {
      target: { name, value },
    } = event;
    setState({ ...state, [name]: value });
  };

  const saveMulti = async () => {
    setSaveLoading(true);
    var params = {
      id: state.id,
      encounter: props.encounterId,
      date: state.date,
      end_date: state.end_date,
      problem: state.problem,
      plan: state.plan,
      goal: state.goal,
      username: state.username,
      password: state.password,
    };

    const errors = await props.onSaveMultidisciplinary(params); //เรียกจริง
    setMultiErrorsMessage(errors);

    if (errors) {
      // toast.error("บันทึกข้อมูลไม่สำเร็จ");
    } else {
      // toast.success("บันทึกช้อมูลสำเร็จ");
    }

    setSaveLoading(false);
  };

  return (
    <CardLayout closeable={true} onClose={props.onClose}>
      <ErrorMessage error={multiErrorsMessage} />
      <h3>{intl.formatMessage({ id: "เพิ่มข้อมูล Multidisciplinary" })}</h3>
      <Form>
        <Form.Group inline style={{ marginTop: "10px" }}>
          {/* <Form.Field className="one wide" /> */}
          {/* <Form.Field required label="Start Date" className="one wide" /> */}
          <Form.Field className="three wide">
            <div style={styles.spaceBetween}>
              <div style={{ width: "50%" }} />
              <div style={styles.customTitle}>
                <div>Start Date</div>
                <span style={{ color: "red" }}>*</span>
              </div>
            </div>
          </Form.Field>
          <Form.Field className="five wide">
            <DateTextBox
              value={state.date}
              onChange={(date) => {
                setState({ ...state, date: date });
              }}
            />
          </Form.Field>
          <Form.Field className="one wide" />
          <Form.Field label="End Date" className="one wide" />
          <Form.Field className="five wide">
            <DateTextBox
              value={state.end_date}
              onChange={(date) => {
                setState({ ...state, end_date: date });
              }}
            />
          </Form.Field>
          <Form.Field className="one wide" />
        </Form.Group>

        <Form.Group inline>
          {/* <Form.Field className="one wide" /> */}
          {/* <Form.Field required label="Start Date" className="one wide" /> */}
          <Form.Field className="three wide">
            <div style={styles.spaceBetween}>
              <div style={{ width: "50%" }} />
              <div style={styles.customTitle}>
                <div>Problem</div>
                <span style={{ color: "red" }}>*</span>
              </div>
            </div>
          </Form.Field>
          <Form.TextArea
            className="twelve wide"
            name="problem"
            value={state.problem}
            onChange={onTextChange.bind(this)}
          />
          <Form.Field className="one wide" />
        </Form.Group>

        <Form.Group inline>
          {/* <Form.Field className="one wide" /> */}
          {/* <Form.Field required label="Start Date" className="one wide" /> */}
          <Form.Field className="three wide">
            <div style={styles.spaceBetween}>
              <div style={{ width: "50%" }} />
              <div style={styles.customTitle}>
                <div>Plan</div>
              </div>
            </div>
          </Form.Field>
          <Form.TextArea
            className="twelve wide"
            name="plan"
            value={state.plan}
            onChange={onTextChange.bind(this)}
          />
          <Form.Field className="one wide" />
        </Form.Group>

        <Form.Group inline>
          {/* <Form.Field className="one wide" /> */}
          {/* <Form.Field required label="Start Date" className="one wide" /> */}
          <Form.Field className="three wide">
            <div style={styles.spaceBetween}>
              <div style={{ width: "50%" }} />
              <div style={styles.customTitle}>
                <div>Goal</div>
              </div>
            </div>
          </Form.Field>
          <Form.TextArea
            className="twelve wide"
            name="goal"
            value={state.goal}
            onChange={onTextChange.bind(this)}
          />
          <Form.Field className="one wide" />
        </Form.Group>

        <Form.Group inline>
          {/* <Form.Field className="one wide" /> */}
          {/* <Form.Field required label="Start Date" className="one wide" /> */}
          <Form.Field className="three wide">
            <div style={styles.spaceBetween}>
              <div style={{ width: "50%" }} />
              <div style={styles.customTitle}>
                <div>Username</div>
                <span style={{ color: "red" }}>*</span>
              </div>
            </div>
          </Form.Field>
          {/* <Form.Input
            className="five wide"
            name="username"
            value={state.username}
            onChange={onTextChange.bind(this)}
          /> */}
          <Form.Field className="eleven wide">
            <div style={{ width: "100%", display: "flex" }}>
              <div style={{ width: "100%" }}>
                <Input
                  name="username"
                  value={state.username}
                  onChange={onTextChange.bind(this)}
                />
              </div>
              <div style={{...styles.customTitle, margin:"0 10px"}}>
                <div style={{display:"flex", alignItems:"center" }}>Password</div>
                <span style={{ color: "red",display:"flex", alignItems:"center" }}>*</span>
              </div>
              <div style={{ width: "100%" }}>
                <Input
                  type="password"
                  name="password"
                  value={state.password}
                  onChange={onTextChange.bind(this)}
                />
              </div>
            </div>
          </Form.Field>
          {/* <Form.Field required label="Password" className="one wide" />
          <Form.Input
            type="password"
            className="five wide"
            name="password"
            value={state.password}
            onChange={onTextChange.bind(this)}
          /> */}
          <Form.Field className="one wide">
            <Button
              color="green"
              onClick={saveMulti}
              loading={saveLoading}
              disabled={saveLoading}
            >{intl.formatMessage({ id: "บันทึก" })}</Button>
          </Form.Field>
          <Form.Field className="one wide" />
        </Form.Group>
      </Form>
    </CardLayout>
  );
});

MultiDisciplinaryEditor.defaultProps = {
  onSaveMultidisciplinary: () => {},
  encounterId: null,
  id: "",
  onClose: () => {},
};

MultiDisciplinaryEditor.propTypes = {
  onSaveMultidisciplinary: PropTypes.func,
  onClose: PropTypes.func,
  encounterId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  id: PropTypes.string,
};

export default React.memo(MultiDisciplinaryEditor);
