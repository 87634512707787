import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Icon
} from 'semantic-ui-react'

const CardQueueBillUX = (props: any) => {
    return(
      <div
        style={{position:"relative",marginTop: props.openFullScreen ? "40px" : "0px"  }}>
        <div
          style={{display:"flex",margin:"10px",}}>
          
          <div
            style={{width:"10%"}}>
            {}
            <img
              src={props.logo}
              style={{width: "100%", height: "90%"}}>
            </img>
          </div>
          <div
            style={{width:"100%"}}>
            
            <div
              style={{ display: "flex", justifyContent: "space-between",marginLeft:"5px"}}>
              
              <div
                style={{ fontWeight: "bold", fontSize: "18px",alignItems:"center",display:"flex"}}>
                {props.hospital}
              </div>
              <div
                style={{ fontWeight: "bold", fontSize: "18px",alignItems:"center",display:"flex"}}>
                {props.date}
              </div>
            </div>
            <div
              style={{ border: "1px solid rgb(29, 189, 212)"}}>
              
            </div>
            <div
              style={{ display: "flex", justifyContent: "space-between",marginLeft:"5px",marginTop:"5px"}}>
              
              <div
                style={{ fontWeight: "bold", fontSize: "17px",alignItems:"center",display:"flex"}}>
                {props.division}
              </div>
              <div
                style={{ fontWeight: "bold", fontSize: "17px",alignItems:"center",display:"flex"}}>
                {props.time}
              </div>
            </div>
          </div>
          <div
            style={{display: props.openFullScreen ?  "flex" : "none",alignItems:"center",marginLeft:"5px"}}>
            
            <Icon
              className="red large"
              name={"close"}
              onClick={props.onClose}>
            </Icon>
          </div>
        </div>
        <div
          style={{justifyContent:"center",display: "flex"}}>
          
          <div
            style={{ backgroundColor: "rgb(29, 189, 212)",borderRadius:"10px",margin:"10px",padding:"10px",width:"20%",position:"absolute",top:"-10px"}}>
            
            <label
              style={{  fontWeight: "bold", fontSize: "24px",justifyContent:"center",display: "flex",color:"white"}}>
              ห้องการเงิน
            </label>
          </div>
        </div>
        <div
          style={{ backgroundColor: "#C6F4C9",borderRadius:"5px",margin:"10px",position:"relative"}}>
          
          <div
            style={{paddingTop:"5px"}}>
            
            <div
              style={{ backgroundColor: "white",borderRadius:"10px",margin:"10px",padding:"10px"}}>
              
              <label
                style={{  fontWeight: "bold", fontSize: "22px",justifyContent:"center",display: "flex",color:"#026EBC"}}>
                คิวรอชำระเงิน
              </label>
            </div>
          </div>
          <div
            style={{margin:"20px 10px 10px 10px"}}>
            
            <div>
              {props.list}
            </div>
          </div>
          <div
            style={{display:"flex",margin:"10px",justifyContent:"flex-end"}}>
            
            <div
              style={{ fontWeight: "bold", fontSize: "20px",alignItems:"center",display:"flex"}}>
              จำนวนที่รอรับชำระเงิน
            </div>
            <div
              style={{ backgroundColor: "white",borderRadius:"10px",margin:"10px",padding:"10px",width:"5%"}}>
              {}
              <div
                style={{ fontWeight: "bold", fontSize: "20px",alignItems:"center",display:"flex",justifyContent:"center",}}>
                {props.number}
              </div>
            </div>
          </div>
          <div>
            {props.arrow}
          </div>
        </div>
      </div>
    )
}

export default CardQueueBillUX

export const screenPropsDefault = {}

/* Date Time : Fri Sep 30 2022 16:25:20 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{position:\"relative\",marginTop: props.openFullScreen ? \"40px\" : \"0px\"  }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ backgroundColor: \"#C6F4C9\",borderRadius:\"5px\",margin:\"10px\",position:\"relative\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{paddingTop:\"5px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",margin:\"10px\",justifyContent:\"flex-end\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "จำนวนที่รอรับชำระเงิน"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"20px\",alignItems:\"center\",display:\"flex\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ backgroundColor: \"white\",borderRadius:\"10px\",margin:\"10px\",padding:\"10px\",width:\"5%\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "code",
          "value": "props.number"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"20px\",alignItems:\"center\",display:\"flex\",justifyContent:\"center\",}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ backgroundColor: \"white\",borderRadius:\"10px\",margin:\"10px\",padding:\"10px\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "label",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "คิวรอชำระเงิน"
        },
        "style": {
          "type": "code",
          "value": "{  fontWeight: \"bold\", fontSize: \"22px\",justifyContent:\"center\",display: \"flex\",color:\"#026EBC\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{margin:\"20px 10px 10px 10px\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",margin:\"10px\",}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"space-between\",marginLeft:\"5px\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ border: \"1px solid rgb(29, 189, 212)\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"space-between\",marginLeft:\"5px\",marginTop:\"5px\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "code",
          "value": "props.hospital"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"18px\",alignItems:\"center\",display:\"flex\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "code",
          "value": "props.date"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"18px\",alignItems:\"center\",display:\"flex\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 17,
      "props": {
        "children": {
          "type": "code",
          "value": "props.division"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"17px\",alignItems:\"center\",display:\"flex\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 17,
      "props": {
        "children": {
          "type": "code",
          "value": "props.time"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"17px\",alignItems:\"center\",display:\"flex\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width:\"10%\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "img",
      "parent": 26,
      "props": {
        "src": {
          "type": "code",
          "value": "props.logo"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"90%\"}"
        }
      },
      "seq": 27,
      "void": true
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "code",
          "value": "props.list"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{justifyContent:\"center\",display: \"flex\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 29,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ backgroundColor: \"rgb(29, 189, 212)\",borderRadius:\"10px\",margin:\"10px\",padding:\"10px\",width:\"20%\",position:\"absolute\",top:\"-10px\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "label",
      "parent": 30,
      "props": {
        "children": {
          "type": "value",
          "value": "ห้องการเงิน"
        },
        "style": {
          "type": "code",
          "value": "{  fontWeight: \"bold\", fontSize: \"24px\",justifyContent:\"center\",display: \"flex\",color:\"white\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.openFullScreen ?  \"flex\" : \"none\",alignItems:\"center\",marginLeft:\"5px\"}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "Icon",
      "parent": 32,
      "props": {
        "className": {
          "type": "value",
          "value": "red large"
        },
        "name": {
          "type": "code",
          "value": "\"close\""
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.arrow"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 34,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardQueueBillUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
