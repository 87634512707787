import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Checkbox,
  Radio,
  Input,
  Button,
  Dropdown
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardSearchPackageUX = (props: any) => {
    return(
      <div
        style={{width: "100%"}}>
        <div
          style={{display: "flex", alignItems: "center", justifyContent: "space-between", padding: "15px 20px", backgroundColor: "#F2F2F2"}}>

          <div
            style={{fontSize: "1.2rem", fontWeight: "bold", color: "#0072BC"}}>
            ค้นหาแพ็กเกจประเภทต่างๆ
          </div>
        </div>
        <div
          className="ui divider"
          style={{marginTop: 0, border: "none"}}>

        </div>
        <div
          style={{padding: "0 15px"}}>

          <div
            className="ui form">

            <FormGroup
              inline={true}>
              <FormField
                inline={true}>
                <Checkbox
                  checked={props.filterPackage?.isServiceType || false}
                  label="Service type"
                  name="isServiceType"
                  onChange={props.onChangeValue}
                  style={{fontWeight: "bold", minWidth: "max-content"}}>
                </Checkbox>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <Dropdown
                  clearable={true}
                  disabled={props.disabledPackageServiceType|| false}
                  fluid={true}
                  name="serviceType"
                  onChange={props.onChangeValue}
                  options={props.packageServiceTypeOptions || []}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.filterPackage?.serviceType || ""}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}>
                <Checkbox
                  checked={props.filterPackage?.isPackageType|| false}
                  label="Package type"
                  name="isPackageType"
                  onChange={props.onChangeValue}
                  style={{fontWeight: "bold", minWidth: "max-content"}}>
                </Checkbox>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <Dropdown
                  clearable={true}
                  disabled={props.disabledPackageType || false}
                  fluid={true}
                  name="packageType"
                  onChange={props.onChangeValue}
                  options={props.packageTypeOptions|| []}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.filterPackage?.packageType || ""}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}>
                <Checkbox
                  checked={props.filterPackage?.isProgramName|| false}
                  label="Program name"
                  name="isProgramName"
                  onChange={props.onChangeValue}
                  style={{fontWeight: "bold", minWidth: "max-content"}}>
                </Checkbox>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <div
                  style={{width: "100%"}}>
                  {props.SearchBoxProgramName}
                </div>
              </FormField>
              <FormField
                inline={true}
                style={{display: "none"}}>
                <div>
                  {props.ButtonSearch}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{display: "none"}}>
              <FormField
                inline={true}>
                <Checkbox
                  checked={props.filterPackage?.isGender || false}
                  label="เพศ "
                  name="isGender"
                  onChange={props.onChangeValue}>
                </Checkbox>
              </FormField>
              <FormField
                inline={true}>
                <Radio
                  checked={props.filterPackage?.gender === "MALE"}
                  disabled={!props.filterPackage?.isGender}
                  label="ชาย"
                  name="gender"
                  onChange={props.onChangeValue}
                  value="MALE">
                </Radio>
              </FormField>
              <FormField
                inline={true}>
                <Radio
                  checked={props.filterPackage?.gender === "FEMALE"}
                  disabled={!props.filterPackage?.isGender}
                  label="หญิง"
                  name="gender"
                  onChange={props.onChangeValue}
                  value="FEMALE">
                </Radio>
              </FormField>
              <FormField
                inline={true}>
                <Checkbox
                  checked={props.filterPackage?.isAgeRange|| false}
                  label="ช่วงอายุ (ปี)"
                  name="isAgeRange"
                  onChange={props.onChangeValue}>
                </Checkbox>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <Input
                  disabled={!props.filterPackage?.isAgeRange}
                  name="ageStart"
                  onChange={props.onChangeValue}
                  ref={props.inputRef}
                  type="number"
                  value={props.filterPackage?.ageStart|| ""}>
                </Input>
              </FormField>
              <FormField
                inline={true}>
                <label>
                  ถึง
                </label>
              </FormField>
              <FormField
                inline={true}
                style={{position: "relative"}}
                width={2}>
                <Input
                  disabled={!props.filterPackage?.isAgeRange}
                  name="ageEnd"
                  onChange={props.onChangeValue}
                  ref={props.inputRef}
                  type="number"
                  value={props.filterPackage?.ageEnd|| ""}>
                </Input>
                <label
                  style={{color: "red", position: "absolute",minWidth: "max-content", top: "37px", fontSize: "0.75rem", display: props.ageRangeError ? "": "none"}}>
                  Age end ต้องไม่น้อยกว่า Age start
                </label>
              </FormField>
              <FormField
                inline={true}>
                <div>
                  {props.ButtonSearch}
                </div>
              </FormField>
              <FormField
                inline={true}>
                <Button
                  color="yellow"
                  onClick={props.onClear}>
                  เคลียร์
                </Button>
              </FormField>
            </FormGroup>
            <FormGroup>
              <FormField
                style={{flex:1}}>
              </FormField>
              <FormField
                inline={true}>
                <div>
                  {props.ButtonSearch}
                </div>
              </FormField>
              <FormField
                inline={true}>
                <Button
                  color="yellow"
                  onClick={props.onClear}>
                  เคลียร์
                </Button>
              </FormField>
            </FormGroup>
          </div>
          <div
            className="ui form"
            style={{marginTop: "1rem"}}>

            <Table
              data={props.packageItems || []}
              headers="รหัส,ประเภท,ชื่อ,เพศ,ช่วงอายุ,ราคา,รายละเอียด,ชื้อ"
              keys="code,package_type,name,genderName,ageRange,price,description,buy"
              minRows={3}
              showPagination={false}
              style={{ maxHeight: "calc(100vh - 18rem)" }}
              widths="130,^140,^200,85,95,100,100,100">
            </Table>
          </div>
        </div>
      </div>
    )
}


export default CardSearchPackageUX

export const screenPropsDefault = {"price_claimable":0,"price_non_claimable":0,"price_total":0}

/* Date Time : Tue Jul 30 2024 15:13:17 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", justifyContent: \"space-between\", padding: \"15px 20px\", backgroundColor: \"#F2F2F2\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: 0, border: \"none\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหาแพ็กเกจประเภทต่างๆ"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.2rem\", fontWeight: \"bold\", color: \"#0072BC\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 15px\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormGroup",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "Checkbox",
      "parent": 7,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filterPackage?.isServiceType || false"
        },
        "label": {
          "type": "value",
          "value": "Service type"
        },
        "name": {
          "type": "value",
          "value": "isServiceType"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", minWidth: \"max-content\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Checkbox",
      "parent": 9,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filterPackage?.isPackageType|| false"
        },
        "label": {
          "type": "value",
          "value": "Package type"
        },
        "name": {
          "type": "value",
          "value": "isPackageType"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", minWidth: \"max-content\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "Checkbox",
      "parent": 11,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filterPackage?.isProgramName|| false"
        },
        "label": {
          "type": "value",
          "value": "Program name"
        },
        "name": {
          "type": "value",
          "value": "isProgramName"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", minWidth: \"max-content\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "code",
          "value": "props.SearchBoxProgramName"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "FormGroup",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: \"none\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "FormField",
      "parent": 19,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "FormField",
      "parent": 19,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "FormField",
      "parent": 19,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "FormField",
      "parent": 19,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "FormField",
      "parent": 19,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "FormField",
      "parent": 19,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "FormField",
      "parent": 19,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\"}"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "FormField",
      "parent": 19,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "Checkbox",
      "parent": 20,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filterPackage?.isGender || false"
        },
        "label": {
          "type": "value",
          "value": "เพศ "
        },
        "name": {
          "type": "value",
          "value": "isGender"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "Radio",
      "parent": 21,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filterPackage?.gender === \"MALE\""
        },
        "disabled": {
          "type": "code",
          "value": "!props.filterPackage?.isGender"
        },
        "label": {
          "type": "value",
          "value": "ชาย"
        },
        "name": {
          "type": "value",
          "value": "gender"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "value",
          "value": "MALE"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "Radio",
      "parent": 22,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filterPackage?.gender === \"FEMALE\""
        },
        "disabled": {
          "type": "code",
          "value": "!props.filterPackage?.isGender"
        },
        "label": {
          "type": "value",
          "value": "หญิง"
        },
        "name": {
          "type": "value",
          "value": "gender"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "value",
          "value": "FEMALE"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "Checkbox",
      "parent": 23,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filterPackage?.isAgeRange|| false"
        },
        "label": {
          "type": "value",
          "value": "ช่วงอายุ (ปี)"
        },
        "name": {
          "type": "value",
          "value": "isAgeRange"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "Input",
      "parent": 24,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.filterPackage?.isAgeRange"
        },
        "name": {
          "type": "value",
          "value": "ageStart"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "ref": {
          "type": "code",
          "value": "props.inputRef"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.filterPackage?.ageStart|| \"\""
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "label",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "Input",
      "parent": 26,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.filterPackage?.isAgeRange"
        },
        "name": {
          "type": "value",
          "value": "ageEnd"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "ref": {
          "type": "code",
          "value": "props.inputRef"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.filterPackage?.ageEnd|| \"\""
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "FormField",
      "parent": 19,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "Button",
      "parent": 37,
      "props": {
        "children": {
          "type": "value",
          "value": "เคลียร์"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClear"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"1rem\"}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 40,
      "name": "Table",
      "parent": 39,
      "props": {
        "data": {
          "type": "code",
          "value": "props.packageItems || []"
        },
        "headers": {
          "type": "value",
          "value": "รหัส,ประเภท,ชื่อ,เพศ,ช่วงอายุ,ราคา,รายละเอียด,ชื้อ"
        },
        "keys": {
          "type": "value",
          "value": "code,package_type,name,genderName,ageRange,price,description,buy"
        },
        "minRows": {
          "type": "code",
          "value": "3"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{ maxHeight: \"calc(100vh - 18rem)\" }"
        },
        "widths": {
          "type": "value",
          "value": "130,^140,^200,85,95,100,100,100"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 27,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSearch"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "Dropdown",
      "parent": 8,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledPackageServiceType|| false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "serviceType"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.packageServiceTypeOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filterPackage?.serviceType || \"\""
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "Dropdown",
      "parent": 10,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledPackageType || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "packageType"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.packageTypeOptions|| []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filterPackage?.packageType || \"\""
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "label",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": "Age end ต้องไม่น้อยกว่า Age start"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\", position: \"absolute\",minWidth: \"max-content\", top: \"37px\", fontSize: \"0.75rem\", display: props.ageRangeError ? \"\": \"none\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: \"none\"}"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 45,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSearch"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "FormGroup",
      "parent": 5,
      "props": {
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "FormField",
      "parent": 48,
      "props": {
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "FormField",
      "parent": 48,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "FormField",
      "parent": 48,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "Button",
      "parent": 51,
      "props": {
        "children": {
          "type": "value",
          "value": "เคลียร์"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClear"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 50,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSearch"
        }
      },
      "seq": 53,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 90,
  "isMounted": false,
  "memo": false,
  "name": "CardSearchPackageUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "price_claimable": 0,
    "price_non_claimable": 0,
    "price_total": 0
  },
  "width": 55
}

*********************************************************************************** */
