import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Icon
} from 'semantic-ui-react'

const CardPatientExpenseItemInfoUX = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{width: "100%",height:"100%",padding:"10px"}}>
        <div>

          <div
            style={{display: "flex", alignItems: "center", justifyContent: "space-between", padding: "5px 0",margin: "-0.25rem 0 -0.5rem"}}>

            <div
              style={{fontSize: "1.15rem", fontWeight: "bold"}}>
              ข้อมูลรายการค่าใช้จ่ายผู้ป่วย
            </div>
            <div
              style={{display: "flex", alignItems: "center"}}>

              <div>
                {props.status}
              </div>
              <div
                style={{cursor: "pointer"}}>

                <Icon
                  className="red large"
                  name="close"
                  onClick={props.onClose}>
                </Icon>
              </div>
            </div>
          </div>
        </div>
        <div
          className="ui divider">

        </div>
        <div
          className="ui form"
          style={{padding: "0 15px"}}>

          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={2}>
              <div
                style={{display: "flex"}}>

                <label
                  style={{fontWeight: "bold"}}>
                  HN :
                </label>
                <label
                  style={{paddingLeft: "3px"}}>
                  {props.data.hn || ""}
                </label>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <div
                style={{display: "flex"}}>

                <label
                  style={{fontWeight: "bold"}}>
                  Encounter :
                </label>
                <label
                  style={{paddingLeft: "3px"}}>
                  {props.data.encounter_id || ""}
                </label>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{display: "flex"}}>

                <label
                  style={{fontWeight: "bold"}}>
                  IDCard No. :
                </label>
                <label
                  style={{paddingLeft: "3px"}}>
                  {props.data.citizen_passport|| ""}
                </label>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <div
                style={{display: "flex"}}>

                <label
                  style={{fontWeight: "bold"}}>
                  ชื่อ-สกุล :
                </label>
                <label
                  style={{paddingLeft: "3px"}}>
                  {props.data.patient_name|| ""}
                </label>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <div
                style={{display: "flex"}}>

                <label
                  style={{fontWeight: "bold"}}>
                  สิทธิการรักษา :
                </label>
                <label
                  style={{paddingLeft: "3px"}}>
                  {props.data.coverage_name|| ""}
                </label>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={4}>
              <div
                style={{display: "flex"}}>

                <label
                  style={{fontWeight: "bold"}}>
                  วันที่รับบริการ :
                </label>
                <label
                  style={{paddingLeft: "3px"}}>
                  {props.data.service_date|| ""}
                </label>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{display: "flex"}}>

                <label
                  style={{fontWeight: "bold"}}>
                  คลินิก :
                </label>
                <label
                  style={{paddingLeft: "3px"}}>
                  {props.data.division_name|| ""}
                </label>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={9}>
              <div
                style={{display: "flex"}}>

                <label
                  style={{fontWeight: "bold"}}>
                  ทันตแพทย์ผู้รักษา :
                </label>
                <label
                  style={{paddingLeft: "3px"}}>
                  {props.data.doctor_name|| ""}
                </label>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={7}>
              <div
                style={{display: "flex"}}>

                <label
                  style={{fontWeight: "bold"}}>
                  การวินิจฉัย (ICD10) :
                </label>
                <label
                  style={{paddingLeft: "3px"}}>
                  {props.data.icd10|| ""}
                </label>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={9}>
              <div
                style={{display: "flex"}}>

                <label
                  style={{fontWeight: "bold", minWidth: "max-content"}}>
                  การผ่าตัด/หัตถการ (ICD9CM) :
                </label>
                <label
                  style={{paddingLeft: "3px"}}>
                  {props.data.icd9cm|| ""}
                </label>
              </div>
            </FormField>
          </FormGroup>
        </div>
        <div
          style={{width: "100%", marginBottom: "-1rem"}}>
          {props.medicalFee}
        </div>
      </div>
    )
}


export default CardPatientExpenseItemInfoUX

export const screenPropsDefault = {}

/* Date Time : Wed May 15 2024 12:15:15 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width: \"100%\",height:\"100%\",padding:\"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 67,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 15px\"}"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "div",
      "parent": 67,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", justifyContent: \"space-between\", padding: \"5px 0\",margin: \"-0.25rem 0 -0.5rem\"}"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "div",
      "parent": 70,
      "props": {
        "children": {
          "type": "value",
          "value": "ข้อมูลรายการค่าใช้จ่ายผู้ป่วย"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.15rem\", fontWeight: \"bold\"}"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "FormGroup",
      "parent": 69,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "FormGroup",
      "parent": 69,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "FormGroup",
      "parent": 69,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 77,
      "name": "FormField",
      "parent": 74,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 81,
      "name": "FormField",
      "parent": 74,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "div",
      "parent": 77,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "div",
      "parent": 81,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 84,
      "name": "FormField",
      "parent": 74,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 85,
      "name": "FormField",
      "parent": 74,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 86,
      "name": "FormField",
      "parent": 74,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 84,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 85,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "div",
      "parent": 86,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": null,
      "id": 90,
      "name": "label",
      "parent": 82,
      "props": {
        "children": {
          "type": "value",
          "value": "HN : "
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": null,
      "id": 91,
      "name": "label",
      "parent": 82,
      "props": {
        "children": {
          "type": "code",
          "value": "props.data.hn || \"\""
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"3px\"}"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "label",
      "parent": 83,
      "props": {
        "children": {
          "type": "value",
          "value": "IDCard No. : "
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 93,
      "name": "label",
      "parent": 83,
      "props": {
        "children": {
          "type": "code",
          "value": "props.data.citizen_passport|| \"\""
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"3px\"}"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "label",
      "parent": 87,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ-สกุล : "
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "label",
      "parent": 87,
      "props": {
        "children": {
          "type": "code",
          "value": "props.data.patient_name|| \"\""
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"3px\"}"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": null,
      "id": 96,
      "name": "label",
      "parent": 88,
      "props": {
        "children": {
          "type": "value",
          "value": "สิทธิการรักษา : "
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "label",
      "parent": 88,
      "props": {
        "children": {
          "type": "code",
          "value": "props.data.coverage_name|| \"\""
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"3px\"}"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": null,
      "id": 98,
      "name": "label",
      "parent": 89,
      "props": {
        "children": {
          "type": "value",
          "value": "Encounter : "
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 99,
      "name": "label",
      "parent": 89,
      "props": {
        "children": {
          "type": "code",
          "value": "props.data.encounter_id || \"\""
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"3px\"}"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 100,
      "name": "FormField",
      "parent": 75,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 101,
      "name": "FormField",
      "parent": 75,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 102,
      "name": "FormField",
      "parent": 75,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "9"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": null,
      "id": 103,
      "name": "div",
      "parent": 100,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": null,
      "id": 104,
      "name": "div",
      "parent": 101,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": null,
      "id": 105,
      "name": "div",
      "parent": 102,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": null,
      "id": 106,
      "name": "label",
      "parent": 103,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่รับบริการ : "
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": null,
      "id": 107,
      "name": "label",
      "parent": 103,
      "props": {
        "children": {
          "type": "code",
          "value": "props.data.service_date|| \"\""
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"3px\"}"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": null,
      "id": 108,
      "name": "label",
      "parent": 104,
      "props": {
        "children": {
          "type": "value",
          "value": "คลินิก : "
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": null,
      "id": 109,
      "name": "label",
      "parent": 104,
      "props": {
        "children": {
          "type": "code",
          "value": "props.data.division_name|| \"\""
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"3px\"}"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": null,
      "id": 110,
      "name": "label",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": "ทันตแพทย์ผู้รักษา : "
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": null,
      "id": 111,
      "name": "label",
      "parent": 105,
      "props": {
        "children": {
          "type": "code",
          "value": "props.data.doctor_name|| \"\""
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"3px\"}"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 112,
      "name": "FormField",
      "parent": 76,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 113,
      "name": "FormField",
      "parent": 76,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "9"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": null,
      "id": 114,
      "name": "div",
      "parent": 112,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": null,
      "id": 115,
      "name": "div",
      "parent": 113,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": null,
      "id": 116,
      "name": "label",
      "parent": 114,
      "props": {
        "children": {
          "type": "value",
          "value": "การวินิจฉัย (ICD10) : "
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": null,
      "id": 117,
      "name": "label",
      "parent": 114,
      "props": {
        "children": {
          "type": "code",
          "value": "props.data.icd10|| \"\""
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"3px\"}"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": null,
      "id": 118,
      "name": "label",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": "การผ่าตัด/หัตถการ (ICD9CM) : "
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", minWidth: \"max-content\"}"
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": null,
      "id": 119,
      "name": "label",
      "parent": 115,
      "props": {
        "children": {
          "type": "code",
          "value": "props.data.icd9cm|| \"\""
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"3px\"}"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": null,
      "id": 120,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.medicalFee"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", marginBottom: \"-1rem\"}"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": null,
      "id": 121,
      "name": "div",
      "parent": 70,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": null,
      "id": 122,
      "name": "div",
      "parent": 121,
      "props": {
        "children": {
          "type": "code",
          "value": "props.status"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": null,
      "id": 123,
      "name": "div",
      "parent": 121,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{cursor: \"pointer\"}"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 124,
      "name": "Icon",
      "parent": 123,
      "props": {
        "className": {
          "type": "value",
          "value": "red large"
        },
        "name": {
          "type": "value",
          "value": "close"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        }
      },
      "seq": 124,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 85,
  "isMounted": true,
  "memo": false,
  "name": "CardPatientExpenseItemInfoUX",
  "project": "CLM CU",
  "screenPropsDefault": {
  },
  "width": 70
}

*********************************************************************************** */
