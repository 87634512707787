import React from 'react'
import {
   Button,
   Checkbox,
   Dropdown,
   Input,
} from 'semantic-ui-react'



import styles from './CardOTPhysicalExaminationPhysicalDysfunctionActivitiesOfDailyLiving.module.css'

export interface CardOTPhysicalExaminationPhysicalDysfunctionActivitiesOfDailyLivingViewDataType {
    OtPhysicalDysfunctionActivityOfDailyLiving3: boolean,
    OtPhysicalDysfunctionActivityOfDailyLiving3Disabled: boolean,
    Ct2Grooming_oral: string,
    Ct2Grooming_oralDisabled: boolean,
    Ct2Grooming_oralOptions: any,
    OtPhysicalDysfunctionActivityOfDailyLiving9: string,
    OtPhysicalDysfunctionActivityOfDailyLiving9Disabled: boolean,
    Ct2FeedingEating: string,
    Ct2FeedingEatingDisabled: boolean,
    Ct2FeedingEatingOptions: any,
    OtPhysicalDysfunctionActivityOfDailyLiving14: string,
    OtPhysicalDysfunctionActivityOfDailyLiving14Disabled: boolean,
    Ct2DressingUpper: string,
    Ct2DressingUpperDisabled: boolean,
    Ct2DressingUpperOptions: any,
    OtPhysicalDysfunctionActivityOfDailyLiving19: string,
    OtPhysicalDysfunctionActivityOfDailyLiving19Disabled: boolean,
    Ct2DressingLower: string,
    Ct2DressingLowerDisabled: boolean,
    Ct2DressingLowerOptions: any,
    OtPhysicalDysfunctionActivityOfDailyLiving24: string,
    OtPhysicalDysfunctionActivityOfDailyLiving24Disabled: boolean,
    Ct2TransferBed: string,
    Ct2TransferBedDisabled: boolean,
    Ct2TransferBedOptions: any,
    OtPhysicalDysfunctionActivityOfDailyLiving30: string,
    OtPhysicalDysfunctionActivityOfDailyLiving30Disabled: boolean,
    Ct2TransferToilet: string,
    Ct2TransferToiletDisabled: boolean,
    Ct2TransferToiletOptions: any,
    OtPhysicalDysfunctionActivityOfDailyLiving36: string,
    OtPhysicalDysfunctionActivityOfDailyLiving36Disabled: boolean,
    Ct2BedMobility: string,
    Ct2BedMobilityDisabled: boolean,
    Ct2BedMobilityOptions: any,
    OtPhysicalDysfunctionActivityOfDailyLiving41: string,
    OtPhysicalDysfunctionActivityOfDailyLiving41Disabled: boolean,
    Ct2Bathing: string,
    Ct2BathingDisabled: boolean,
    Ct2BathingOptions: any,
    OtPhysicalDysfunctionActivityOfDailyLiving46: string,
    OtPhysicalDysfunctionActivityOfDailyLiving46Disabled: boolean,
    Ct2Toileting: string,
    Ct2ToiletingDisabled: boolean,
    Ct2ToiletingOptions: any,
    OtPhysicalDysfunctionActivityOfDailyLiving51: string,
    OtPhysicalDysfunctionActivityOfDailyLiving51Disabled: boolean,
    Ct2Other: string,
    Ct2OtherDisabled: boolean,
    Ct2OtherOptions: any,
    OtPhysicalDysfunctionActivityOfDailyLiving56: string,
    OtPhysicalDysfunctionActivityOfDailyLiving56Disabled: boolean,
    SaveRequestDisabled: boolean,
}

export const CardOTPhysicalExaminationPhysicalDysfunctionActivitiesOfDailyLivingInitialViewData: CardOTPhysicalExaminationPhysicalDysfunctionActivitiesOfDailyLivingViewDataType = {
    OtPhysicalDysfunctionActivityOfDailyLiving3: false,
    OtPhysicalDysfunctionActivityOfDailyLiving3Disabled: false,
    Ct2Grooming_oral: "",
    Ct2Grooming_oralDisabled: false,
    Ct2Grooming_oralOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalDysfunctionActivityOfDailyLiving9: "",
    OtPhysicalDysfunctionActivityOfDailyLiving9Disabled: false,
    Ct2FeedingEating: "",
    Ct2FeedingEatingDisabled: false,
    Ct2FeedingEatingOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalDysfunctionActivityOfDailyLiving14: "",
    OtPhysicalDysfunctionActivityOfDailyLiving14Disabled: false,
    Ct2DressingUpper: "",
    Ct2DressingUpperDisabled: false,
    Ct2DressingUpperOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalDysfunctionActivityOfDailyLiving19: "",
    OtPhysicalDysfunctionActivityOfDailyLiving19Disabled: false,
    Ct2DressingLower: "",
    Ct2DressingLowerDisabled: false,
    Ct2DressingLowerOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalDysfunctionActivityOfDailyLiving24: "",
    OtPhysicalDysfunctionActivityOfDailyLiving24Disabled: false,
    Ct2TransferBed: "",
    Ct2TransferBedDisabled: false,
    Ct2TransferBedOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalDysfunctionActivityOfDailyLiving30: "",
    OtPhysicalDysfunctionActivityOfDailyLiving30Disabled: false,
    Ct2TransferToilet: "",
    Ct2TransferToiletDisabled: false,
    Ct2TransferToiletOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalDysfunctionActivityOfDailyLiving36: "",
    OtPhysicalDysfunctionActivityOfDailyLiving36Disabled: false,
    Ct2BedMobility: "",
    Ct2BedMobilityDisabled: false,
    Ct2BedMobilityOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalDysfunctionActivityOfDailyLiving41: "",
    OtPhysicalDysfunctionActivityOfDailyLiving41Disabled: false,
    Ct2Bathing: "",
    Ct2BathingDisabled: false,
    Ct2BathingOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalDysfunctionActivityOfDailyLiving46: "",
    OtPhysicalDysfunctionActivityOfDailyLiving46Disabled: false,
    Ct2Toileting: "",
    Ct2ToiletingDisabled: false,
    Ct2ToiletingOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalDysfunctionActivityOfDailyLiving51: "",
    OtPhysicalDysfunctionActivityOfDailyLiving51Disabled: false,
    Ct2Other: "",
    Ct2OtherDisabled: false,
    Ct2OtherOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalDysfunctionActivityOfDailyLiving56: "",
    OtPhysicalDysfunctionActivityOfDailyLiving56Disabled: false,
    SaveRequestDisabled: false,
}

const Sub = (props: any) => {
    const children = React.Children.toArray(props.children)
    return (
        <div className={styles.container}>
            <div className={styles.OtPhysicalDysfunctionActivityOfDailyLiving2}>
                <div
                    style={{backgroundColor: "#F3F3F3", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionActivityOfDailyLiving3}>
                <Checkbox 
                    style={{ height: "100%", width: "100%"}}
                    checked={props.viewData.OtPhysicalDysfunctionActivityOfDailyLiving3}
                    disabled={ props.viewData.OtPhysicalDysfunctionActivityOfDailyLiving3Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationPhysicalDysfunctionActivitiesOfDailyLiving",
                                name: "OtPhysicalDysfunctionActivityOfDailyLiving3"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionActivityOfDailyLiving4}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Activities of daily living</strong></p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionActivityOfDailyLiving5}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>N/A</p>
                </div>
            </div>
            <div className={styles.Ct2Grooming_oral}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct2Grooming_oral}
                    disabled={ props.viewData.Ct2Grooming_oralDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationPhysicalDysfunctionActivitiesOfDailyLiving",
                                name: "Ct2Grooming_oral",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct2Grooming_oralOptions}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionActivityOfDailyLiving9}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionActivityOfDailyLiving9}
                    disabled={ props.viewData.OtPhysicalDysfunctionActivityOfDailyLiving9Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTPhysicalExaminationPhysicalDysfunctionActivitiesOfDailyLiving',
                            name: 'OtPhysicalDysfunctionActivityOfDailyLiving9',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalDysfunctionActivityOfDailyLiving10}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Grooming/Oral hygiene</p>
                </div>
            </div>
            <div className={styles.Ct2FeedingEating}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct2FeedingEating}
                    disabled={ props.viewData.Ct2FeedingEatingDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationPhysicalDysfunctionActivitiesOfDailyLiving",
                                name: "Ct2FeedingEating",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct2FeedingEatingOptions}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionActivityOfDailyLiving14}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionActivityOfDailyLiving14}
                    disabled={ props.viewData.OtPhysicalDysfunctionActivityOfDailyLiving14Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTPhysicalExaminationPhysicalDysfunctionActivitiesOfDailyLiving',
                            name: 'OtPhysicalDysfunctionActivityOfDailyLiving14',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalDysfunctionActivityOfDailyLiving15}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Feeding/eating</p>
                </div>
            </div>
            <div className={styles.Ct2DressingUpper}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct2DressingUpper}
                    disabled={ props.viewData.Ct2DressingUpperDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationPhysicalDysfunctionActivitiesOfDailyLiving",
                                name: "Ct2DressingUpper",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct2DressingUpperOptions}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionActivityOfDailyLiving19}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionActivityOfDailyLiving19}
                    disabled={ props.viewData.OtPhysicalDysfunctionActivityOfDailyLiving19Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTPhysicalExaminationPhysicalDysfunctionActivitiesOfDailyLiving',
                            name: 'OtPhysicalDysfunctionActivityOfDailyLiving19',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.Ct2DressingLower}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct2DressingLower}
                    disabled={ props.viewData.Ct2DressingLowerDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationPhysicalDysfunctionActivitiesOfDailyLiving",
                                name: "Ct2DressingLower",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct2DressingLowerOptions}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionActivityOfDailyLiving23}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Dressing</p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionActivityOfDailyLiving24}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionActivityOfDailyLiving24}
                    disabled={ props.viewData.OtPhysicalDysfunctionActivityOfDailyLiving24Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTPhysicalExaminationPhysicalDysfunctionActivitiesOfDailyLiving',
                            name: 'OtPhysicalDysfunctionActivityOfDailyLiving24',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalDysfunctionActivityOfDailyLiving25}>
                <div
                    style={{color: "#2F80ED",height: "100%", width: "100%", textAlign:"Right"}}
                >
                    <p>Upper</p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionActivityOfDailyLiving26}>
                <div
                    style={{color: "#2F80ED",height: "100%", width: "100%", textAlign:"Right"}}
                >
                    <p>Lower</p>
                </div>
            </div>
            <div className={styles.Ct2TransferBed}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct2TransferBed}
                    disabled={ props.viewData.Ct2TransferBedDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationPhysicalDysfunctionActivitiesOfDailyLiving",
                                name: "Ct2TransferBed",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct2TransferBedOptions}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionActivityOfDailyLiving30}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionActivityOfDailyLiving30}
                    disabled={ props.viewData.OtPhysicalDysfunctionActivityOfDailyLiving30Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTPhysicalExaminationPhysicalDysfunctionActivitiesOfDailyLiving',
                            name: 'OtPhysicalDysfunctionActivityOfDailyLiving30',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalDysfunctionActivityOfDailyLiving31}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Transfer</p>
                </div>
            </div>
            <div className={styles.Ct2TransferToilet}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct2TransferToilet}
                    disabled={ props.viewData.Ct2TransferToiletDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationPhysicalDysfunctionActivitiesOfDailyLiving",
                                name: "Ct2TransferToilet",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct2TransferToiletOptions}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionActivityOfDailyLiving35}>
                <div
                    style={{color: "#2F80ED",height: "100%", width: "100%", textAlign:"Right"}}
                >
                    <p>W/C - bed</p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionActivityOfDailyLiving36}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionActivityOfDailyLiving36}
                    disabled={ props.viewData.OtPhysicalDysfunctionActivityOfDailyLiving36Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTPhysicalExaminationPhysicalDysfunctionActivitiesOfDailyLiving',
                            name: 'OtPhysicalDysfunctionActivityOfDailyLiving36',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalDysfunctionActivityOfDailyLiving37}>
                <div
                    style={{color: "#2F80ED",height: "100%", width: "100%", textAlign:"Right"}}
                >
                    <p>W/C - toilet</p>
                </div>
            </div>
            <div className={styles.Ct2BedMobility}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct2BedMobility}
                    disabled={ props.viewData.Ct2BedMobilityDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationPhysicalDysfunctionActivitiesOfDailyLiving",
                                name: "Ct2BedMobility",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct2BedMobilityOptions}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionActivityOfDailyLiving41}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionActivityOfDailyLiving41}
                    disabled={ props.viewData.OtPhysicalDysfunctionActivityOfDailyLiving41Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTPhysicalExaminationPhysicalDysfunctionActivitiesOfDailyLiving',
                            name: 'OtPhysicalDysfunctionActivityOfDailyLiving41',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalDysfunctionActivityOfDailyLiving42}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Functional test</p>
                </div>
            </div>
            <div className={styles.Ct2Bathing}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct2Bathing}
                    disabled={ props.viewData.Ct2BathingDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationPhysicalDysfunctionActivitiesOfDailyLiving",
                                name: "Ct2Bathing",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct2BathingOptions}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionActivityOfDailyLiving46}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionActivityOfDailyLiving46}
                    disabled={ props.viewData.OtPhysicalDysfunctionActivityOfDailyLiving46Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTPhysicalExaminationPhysicalDysfunctionActivitiesOfDailyLiving',
                            name: 'OtPhysicalDysfunctionActivityOfDailyLiving46',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalDysfunctionActivityOfDailyLiving47}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Bathing / showering</p>
                </div>
            </div>
            <div className={styles.Ct2Toileting}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct2Toileting}
                    disabled={ props.viewData.Ct2ToiletingDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationPhysicalDysfunctionActivitiesOfDailyLiving",
                                name: "Ct2Toileting",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct2ToiletingOptions}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionActivityOfDailyLiving51}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionActivityOfDailyLiving51}
                    disabled={ props.viewData.OtPhysicalDysfunctionActivityOfDailyLiving51Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTPhysicalExaminationPhysicalDysfunctionActivitiesOfDailyLiving',
                            name: 'OtPhysicalDysfunctionActivityOfDailyLiving51',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalDysfunctionActivityOfDailyLiving52}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Toileting</p>
                </div>
            </div>
            <div className={styles.Ct2Other}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct2Other}
                    disabled={ props.viewData.Ct2OtherDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationPhysicalDysfunctionActivitiesOfDailyLiving",
                                name: "Ct2Other",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct2OtherOptions}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionActivityOfDailyLiving56}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionActivityOfDailyLiving56}
                    disabled={ props.viewData.OtPhysicalDysfunctionActivityOfDailyLiving56Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTPhysicalExaminationPhysicalDysfunctionActivitiesOfDailyLiving',
                            name: 'OtPhysicalDysfunctionActivityOfDailyLiving56',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalDysfunctionActivityOfDailyLiving57}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Other</p>
                </div>
            </div>
            <div className={styles.SaveRequest}>
                <Button
                    disabled={ props.viewData.SaveRequestDisabled }
                    onClick={(e) => { props.onEvent({
                        message: 'clickButton',
                        params: {
                            view: 'CardOTPhysicalExaminationPhysicalDysfunctionActivitiesOfDailyLiving',
                            name: 'SaveRequest'
                        }
                    })}}
                    style={{color: "#FFFFFF",backgroundColor: "#27AE60", height: "100%", width: "100%"}}
                >
                    Save
                </Button>
            </div>
        </div>
    )
}

export default Sub

const getTsName = (cssClass: string) => {
    return cssClass.split('-').map((s) => (
        s.charAt(0).toUpperCase() + s.slice(1)
    )).join('')
}
