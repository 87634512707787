import WasmController from 'react-lib/frameworks/WasmController';

export type State = 
  {
  }

export const StateInitial: State = 
  {
  }

export type Event = 
  { message: "getImagingExamResult", params: any }

export type Data = 
  {
    division?: number,
  }

export const DataInitial = 
  {
  }

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any 

export const getImagingExamResult: Handler = async (controller, {}) => {
//function (params) { return __awaiter(_this, void 0, void 0, function () {
//            var imagingExamResult, imagingExamError, imeService, _a, error, response;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        imagingExamResult = [];
//                        imagingExamError = [];
//                        imeService = new IMEService_1.default();
//                        return [4 /*yield*/, utils_1.to(imeService.getImagingExamResult(params))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1];
//                        if (response) {
//                            imagingExamResult = response;
//                        }
//                        else {
//                            imagingExamError = error;
//                        }
//                        return [2 /*return*/, [imagingExamResult, imagingExamError]];
//                }
//            });
//        }); }
//function (params) {
//        var url = apis_1.IME.IMAGING_EXAM_RESULT(params);
//        return this.client
//            .get(url)
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/IME/list_result/?encounter=' +
//((__t = (encounterId)) == null ? '' : __t);
//
//}
//return __p
//}
}
