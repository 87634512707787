import React, { SyntheticEvent, useEffect, useState } from "react";
import moment from "moment";
import { formatADtoBEString } from "react-lib/utils/dateUtils";
import { momentLocalizer } from "react-big-calendar";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import ModPlanningUX from "./ModPlanningUX";
import ModDrugEditNote from "../TPD/ModDrugEditNote";
import { ButtonProps } from "semantic-ui-react";
import { useIntl } from "react-intl";

const calendarLocalizer = momentLocalizer(moment);
export const MOD_PLANNING_ID: string = "ModPlanning";

type ModPlanningProps = {
  // function
  onEvent: any;
  setProp: any;

  // CommonInterface
  buttonLoadCheck?: any;

  // selected data
  selectedPatient?: any;
  selectedDrug?: any;

  // planning
  modPlanningConfig?: any;
  planningData?: any;

  isDoctor: boolean;
};

const ModPlanningInitial: ModPlanningProps = {
  // funtion
  onEvent: () => null,
  setProp: () => null,

  // CommonInterface
  buttonLoadCheck: null,

  // selected data
  selectedPatient: null,
  selectedDrug: null,

  // planning
  modPlanningConfig: null,
  planningData: {},
};

const headerStyle = {
  fontSize: "1.25rem",
  fontWeight: "bold",
  borderBottom: "1px solid rgba(200, 200, 200, 0.5)",
  margin: "-2rem 0rem 0",
  padding: "0 0 15px",
};

const ModPlanning: React.FC<ModPlanningProps> = (props: ModPlanningProps) => {
  const intl = useIntl();
  const [modEditNote, setModEditNote] = useState<{ params?: any; type: string } | null>(null);

  useEffect(() => {
    if (props.planningData?.dates?.length > 0) {
      handleChangePlanningData(null, { name: "type", value: "CUSTOM" });
    } else if (props.planningData?.start_date) {
      handleChangePlanningData(null, { name: "type", value: "NORMAL" });
      if (props.planningData?.occurrence) {
        handleChangePlanningData(null, {
          name: "endDate",
          value: "OCCURRENCE",
        });
      } else if (props.planningData?.end_date) {
        handleChangePlanningData(null, { name: "endDate", value: "END_DATE" });
      } else {
        handleChangePlanningData(null, { name: "endDate", value: "NONE" });
      }
    }
  }, []);

  // set
  const handleChangePlanningData = (event: any, data: any) => {
    if (data.type === "number" && Number(data.value) < 0) {
      data.value = "0";
    }

    props.onEvent({
      message: "handleSetPlanningData",
      params: { name: data.name, value: data.value },
    });
  };

  const handleChangePlanningStartDate = (date: string) => {
    props.onEvent({
      message: "handleSetPlanningData",
      params: { name: "start_date", value: date },
    });
  };

  const handleChangePlanningEndDate = (date: string) => {
    props.onEvent({
      message: "handleSetPlanningData",
      params: { name: "end_date", value: date },
    });
  };

  const handleCloseMod = () => {
    props.onEvent({
      message: "handleSetModPlanningConfig",
      params: { name: "open", value: false },
    });
    // props.onEvent({ message: "GetDrugContinuePlan" });
  };

  // save
  const handleRequestPlanning = () => {
    if (props.planningData?.start_date === "" && props.planningData?.dates?.length === 0) {
      alert(intl.formatMessage({ id: "กรุณาระบุ เริิ่มต้น หรือ เลือกวันที่ แบบเลือกเอง" }));
    } else {
      props.onEvent({
        message: "handleSetModPlanningConfig",
        params: { name: "open", value: false },
      });
    }
  };

  const handleActionPlanItem = (event: any, data: any) => {
    const params = { action: data.name, card: MOD_PLANNING_ID, planItemId: props.planningData?.id };

    if (props.isDoctor) {
      props.onEvent({ message: "handleActionPlanItem", params });
    } else {
      setModEditNote({ params, type: data.name });
    }
  };

  const handleEditPlanItem = (event: any, data: any) => {
    const params = { action: data.name, card: MOD_PLANNING_ID };

    if (props.isDoctor) {
      props.onEvent({ message: "handleEditPlanItem", params });
    } else {
      setModEditNote({ params, type: data.name });
    }
  };

  // calendar
  const handleCalendarNavigate = (date: any, _type: any, _navigate: any, _view: any) => {
    let result: any[] = [...props.planningData?.dates];
    const selectedDate: string = moment(date).format("DD/MM/YYYY");
    const index: number = result.indexOf(selectedDate);
    if (index >= 0) {
      result.splice(index, 1);
    } else {
      result.push(selectedDate);
    }
    props.onEvent({
      message: "handleSetPlanningData",
      params: { name: "dates", value: result },
    });
  };

  const handleChangeRadio = (e: any, v: any) => {
    const data = props.planningData;

    data.scheduleType = v.value;

    if (v.value === "day") {
      data.week_days = [];
    } else {
      data.day_take = "";
      data.day_skip = "";
    }

    props.setProp("planningData", { ...data });
  };

  const handleCloseModEditNote = () => {
    setModEditNote(null);

    props.setProp("DrugSelectSequence.note", "");
  };

  const handleSaveEditNote = (e: SyntheticEvent, data: ButtonProps) => {
    if (data.name === "EDIT") {
      props.onEvent({ message: "handleEditPlanItem", params: modEditNote?.params });
    } else {
      props.onEvent({ message: "handleActionPlanItem", params: modEditNote?.params });
    }

    handleCloseModEditNote();
  };

  return (
    <>
      {/* {props.modPlanningConfig?.error && <ErrorMessage error={props.modPlanningConfig?.error} />} */}

      <ModPlanningUX
        // function
        onClose={handleCloseMod}
        onChangePlanningData={handleChangePlanningData}
        onChangePlanningStartDate={handleChangePlanningStartDate}
        onChangePlanningEndDate={handleChangePlanningEndDate}
        onRequestPlanning={handleRequestPlanning}
        // data
        today={formatADtoBEString(moment().format("YYYY-MM-DD"))}
        planningData={props.planningData}
        planSummary={
          <div
            dangerouslySetInnerHTML={{
              __html: props.planningData?.plan_summary,
            }}
            // style={headerStyle}
          />
        }
        onChangeRadio={handleChangeRadio}
        // calendar
        onCalendarNavigate={handleCalendarNavigate}
        calendarLocalizer={calendarLocalizer}
        // component
        buttonEdit={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleEditPlanItem}
            // data
            paramKey={`${MOD_PLANNING_ID}_EDIT`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${MOD_PLANNING_ID}_EDIT`]}
            // config
            color="yellow"
            name="EDIT"
            style={{
              display: props.planningData?.allowed_actions?.includes("EDIT") ? "" : "none",
            }}
            title="EDIT"
          />
        }
        buttonResume={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleActionPlanItem}
            // data
            paramKey={`${MOD_PLANNING_ID}_RESUME`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${MOD_PLANNING_ID}_RESUME`]}
            // config
            color="blue"
            name="RESUME"
            style={{
              display: props.planningData?.allowed_actions?.includes("RESUME") ? "" : "none",
              marginLeft: "0.5rem",
            }}
            title="RESUME"
          />
        }
        buttonOff={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleActionPlanItem}
            // data
            paramKey={`${MOD_PLANNING_ID}_OFF`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${MOD_PLANNING_ID}_OFF`]}
            // config
            color="black"
            name="OFF"
            style={{
              display: props.planningData?.allowed_actions?.includes("OFF") ? "" : "none",
              marginLeft: "0.5rem",
            }}
            title="OFF"
          />
        }
        buttonHold={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleActionPlanItem}
            // data
            paramKey={`${MOD_PLANNING_ID}_HOLD`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${MOD_PLANNING_ID}_HOLD`]}
            // config
            color="brown"
            name="HOLD"
            style={{
              display: props.planningData?.allowed_actions?.includes("HOLD") ? "" : "none",
              marginLeft: "0.5rem",
            }}
            title="HOLD"
          />
        }
        buttonRenew={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleActionPlanItem}
            // data
            paramKey={`${MOD_PLANNING_ID}_RENEW`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${MOD_PLANNING_ID}_RENEW`]}
            // config
            color="teal"
            name="RENEW"
            style={{
              display: props.planningData?.allowed_actions?.includes("RENEW") ? "" : "none",
              marginLeft: "0.5rem",
            }}
            title="RENEW"
          />
        }
        languageUX={props.languageUX}
      />

      <ModDrugEditNote
        setProp={props.setProp}
        open={!!modEditNote}
        type={modEditNote?.type}
        // callback
        onClose={handleCloseModEditNote}
        onSave={handleSaveEditNote}
        languageUX={props.languageUX}
      />
    </>
  );
};

ModPlanning.defaultProps = ModPlanningInitial;

export default React.memo(ModPlanning);
