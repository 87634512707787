import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  FormField,
  Dropdown
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardAnesthesiaStatReportUX = (props: any) => {
    return(
      <div
        style={{position: "relative", padding: "1rem"}}>
        <div>
          
          <Form>
            <FormGroup
              inline={true}
              style={{display: "flex"}}>
              <FormField>
                <div>
                  วันที่เริ่มดมยา
                </div>
              </FormField>
              <FormField>
                <div>
                  
                  <DateTextBox
                    onChange={(date: string) => props.onHandleChangeDate("filterStartDate", date)}
                    value={props.startDate}>
                  </DateTextBox>
                </div>
              </FormField>
              <FormField>
                <div>
                  ถึง
                </div>
              </FormField>
              <FormField>
                <div>
                  
                  <DateTextBox
                    onChange={(date: string) => props.onHandleChangeDate("filterEndDate", date)}
                    value={props.endDate}>
                  </DateTextBox>
                </div>
              </FormField>
              <FormField>
                <div>
                  
                  <div>
                    {props.searchButtonLoadCheck}
                  </div>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{display: "flex", padding: "2rem 0rem 1rem 0rem"}}>
              <FormField
                width={8}>
                <div
                  style={{width: "100%", display: "flex", justifyContent: "flex-start", gap: "1rem"}}>
                  
                  <label
                    style={{display: "flex", alignItems: "center"}}>
                    แสดงผลลัพธ์การค้นหา
                  </label>
                  <label
                    style={{display: "flex", alignItems: "center", fontSize: "22px", color: "darkcyan"}}>
                    {props.searchCount}
                  </label>
                  <label
                    style={{display: "flex", alignItems: "center"}}>
                    รายการ
                  </label>
                  <div>
                    {props.exportButtonLoadCheck}
                  </div>
                </div>
              </FormField>
              <FormField
                width={8}>
                <div
                  style={{width: "100%", display: "flex", justifyContent: "flex-end", gap: "1rem"}}>
                  
                  <label
                    style={{display: "flex", alignItems: "center"}}>
                    เลือกประเภทสถิติ
                  </label>
                  <Dropdown
                    placeholder="เลือกประเภทรายงาน"
                    onChange={props.onHandleSelectReport}
                    options={props.reportOptions}
                    selection={true}
                    style={{width: "36rem"}}
                    value={props.onReportValue}>
                  </Dropdown>
                  <div>
                    {props.printButtonLoadCheck}
                  </div>
                </div>
              </FormField>
            </FormGroup>
          </Form>
          <Table
            data={props.tableData}
            headers="วันที่เริ่มดมยา, เวลาเริ่มต้น, HN, ชื่อ-สกุล, อายุ(ปี), เพศ, สิทธิ, ASA, วิธีระงับความรู้สึก, วันที่สิ้นสุด, เวลาสิ้นสุด, ช่วงเวลาที่ใช้ดมยา, ประเภทเคส, ระดับความพึงพอใจ"
            keys="anesDate, startTime, formatHn, patient_full_name, patient_age, patient_gender, payer, asa, anesMethod,  endDate, endTime, timeDuration, formatCase, formatSatisfaction"
            minRows="10"
            showPagination={false}>
          </Table>
        </div>
      </div>
    )
}


export default CardAnesthesiaStatReportUX

export const screenPropsDefault = {}

/* Date Time : Wed Aug 14 2024 17:17:56 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{position: \"relative\", padding: \"1rem\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 2,
      "name": "Form",
      "parent": 1,
      "props": {
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "FormGroup",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "FormField",
      "parent": 3,
      "props": {
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormField",
      "parent": 3,
      "props": {
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 7,
      "name": "DateTextBox",
      "parent": 14,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(date: string) => props.onHandleChangeDate(\"filterStartDate\", date)"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.startDate"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่เริ่มดมยา"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "FormField",
      "parent": 3,
      "props": {
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "FormField",
      "parent": 3,
      "props": {
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 12,
      "name": "DateTextBox",
      "parent": 15,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(date: string) => props.onHandleChangeDate(\"filterEndDate\", date)"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.endDate"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "FormField",
      "parent": 3,
      "props": {
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "FormGroup",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"2rem 0rem 1rem 0rem\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "FormField",
      "parent": 19,
      "props": {
        "inline": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "FormField",
      "parent": 19,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", display: \"flex\", justifyContent: \"flex-start\", gap: \"1rem\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", display: \"flex\", justifyContent: \"flex-end\", gap: \"1rem\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "label",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": "แสดงผลลัพธ์การค้นหา"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "label",
      "parent": 22,
      "props": {
        "children": {
          "type": "code",
          "value": "props.searchCount"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", fontSize: \"22px\", color: \"darkcyan\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "label",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการ"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "label",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": "เลือกประเภทสถิติ"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "Dropdown",
      "parent": 23,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onHandleSelectReport"
        },
        "options": {
          "type": "code",
          "value": "props.reportOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"36rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.onReportValue"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 31,
      "name": "Table",
      "parent": 1,
      "props": {
        "data": {
          "type": "code",
          "value": "props.tableData"
        },
        "headers": {
          "type": "value",
          "value": "วันที่เริ่มดมยา, เวลาเริ่มต้น, HN, ชื่อ-สกุล, อายุ(ปี), เพศ, สิทธิ, ASA, วิธีระงับความรู้สึก, วันที่สิ้นสุด, เวลาสิ้นสุด, ช่วงเวลาที่ใช้ดมยา, ประเภทเคส, ระดับความพึงพอใจ"
        },
        "keys": {
          "type": "value",
          "value": "anesDate, startTime, formatHn, patient_full_name, patient_age, patient_gender, payer, asa, anesMethod,  endDate, endTime, timeDuration, formatCase, formatSatisfaction"
        },
        "minRows": {
          "type": "value",
          "value": "10"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "div",
      "parent": 17,
      "props": {
        "children": {
          "type": "code",
          "value": "props.searchButtonLoadCheck"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 22,
      "props": {
        "children": {
          "type": "code",
          "value": "props.exportButtonLoadCheck"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 23,
      "props": {
        "children": {
          "type": "code",
          "value": "props.printButtonLoadCheck"
        }
      },
      "seq": 34,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardAnesthesiaStatReportUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
