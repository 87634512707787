import { adToBeWithSetFormat } from "react-lib/utils/dateUtils";

// Types
type FormDFSummaryProps = {
  start_date: string;
  end_date: string;
  pay_to_bank_branch: string;
  data: {
    seq: number;
    doctor_name: string;
    bank_account_no: string;
    net_income_price: string;
  }[];
};

const FONT_SIZE = 16;

const FormDFSummary = (props: FormDFSummaryProps) => {
  return {
    pageSize: "A4",
    defaultStyle: {
      font: "THSarabunNew",
      lineHeight: 1,
      fontSize: FONT_SIZE,
    },
    pageMargins: [15, 95, 15, 22.5],
    styles: {
      fieldHeader: {
        fontSize: 17,
      },
      tableHeader: {
        bold: false,
        alignment: "center",
      },
      miniField: {
        fontSize: 12,
      },
      footerValue: {
        fontSize: 16,
      },
    },
    header: (currentPage: number, pageCount: number) => {
      return {
        margin: [15, 6.5, 15, 0],
        stack: [
          {
            table: {
              headerRows: 1,
              widths: ["100%"],
              body: [
                [
                  {
                    columns: [
                      {
                        width: "*",
                        stack: [
                          {
                            text: "สรุปค่าตอบแทนทันตแพทย์",
                            alignment: "center",
                            bold: true,
                            style: "fieldHeader",
                            fontSize: 17,
                            marginTop: 5,
                          },
                          {
                            text: `งวดประจำวันที่ ${adToBeWithSetFormat(
                              props.start_date,
                              "",
                              "DD MMMM YYYY",
                              "th"
                            )} - ${adToBeWithSetFormat(props.end_date, "", "DD MMMM YYYY", "th")}`,
                            alignment: "center",
                            style: "fieldHeader",
                            marginTop: 5,
                          },
                          {
                            text: props.pay_to_bank_branch,
                            alignment: "center",
                            style: "fieldHeader",
                            marginTop: 2.5,
                          },
                        ],
                      },
                    ],
                  },
                ],
              ],
            },
            layout: {
              hLineWidth: () => 0,
              vLineWidth: () => 0,
            },
          },
        ],
      };
    },
    content: [
      {
        table: {
          headerRows: 1,
          // heights: 100,
          widths: ["10%", "35%", "30%", "25%"],
          body: [
            [
              { text: "ลำดับ", style: "tableHeader" },
              { text: "ชื่อ-สกุล", style: "tableHeader" },
              { text: "บัญชีเลขที่", style: "tableHeader" },
              { text: "รับสุทธิ", style: "tableHeader" },
            ],
            ...props.data.map((item, index) => {
              return [
                { text: `${item.seq}.`, alignment: "center" },
                { text: item.doctor_name },
                { text: item.bank_account_no },
                {
                  text: Number(item.net_income_price).toLocaleString("en-US", {
                    style: "decimal",
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }),
                  alignment: "right",
                },
              ];
            }),
            Array(4)
              .fill("")
              .map((_, index) => ({
                id: `table_row_${index}_blank`,
                text: "",
              })),
          ],
        },
        layout: {
          hLineWidth: (i: any, node: any) => {
            const first = i < 2;
            const last = i === props.data.length + 2;
            const current = node.positions?.[i * 4 - 1]?.top;

            return first || last || current > 787.5 - 22.5 ? 0.25 : 0;
          },
          vLineWidth: (i: any, node: any) => {
            return 0.25;
          },
          paddingBottom: (i: any, node: any) => {
            if (i === props.data.length + 1) {
              return 787.5 - node.positions[node.positions.length - 2]?.top || 0;
            } else {
              return 4;
            }
          },
          paddingTop: () => 4,
          paddingLeft: () => 12,
          paddingRight: () => 12,
        },
      },
    ],
    footer: function (currentPage: number, pageCount: number) {
      return {
        margin: [15, 0, 15, 0],
        stack: [
          {
            marginTop: 0.5,
            columns: [
              {
                width: "*",
                text: `หน้าที่ ${currentPage}/${pageCount}`,
                style: "footerValue",
                alignment: "right",
              },
            ],
          },
        ],
      };
    },
  };
};

export default FormDFSummary;
