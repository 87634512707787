import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Segment,
  Button
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardRefundUX = (props: any) => {
    return(
      <div
        style={{ width : "100%", height: "100%" }}>
        <Segment>
          <div
            style={{display: "flex", justifyContent: "space-between"}}>
            
            <div
              style={{fontWeight: "bold", fontSize: "1.2rem"}}>
              REFUND
            </div>
            <div
              style={{cursor: "pointer", fontWeight: "bold", fontSize: "1.2rem"}}>
              ข้อมูลรายการค่ารักษาพยาบาลรอรับชำระ
            </div>
          </div>
          <hr>
          </hr>
          <div>
            
            <div>
              
              <Table
                data={props.refundOrders}
                getTrProps={props.getTrProps}
                headers="หมวด,รหัส,สินค้า,ใบเสร็จ,จำนวนรวม,ราคาต่อหน่วย,ยอดชำระ,จำนวนคืน,ยอดคืน"
                keys="mode_code,pricing_code,pricing_name,receipts,pricing_quantity,unitprice,payable,quantity,price"
                minRows="5"
                showPagination={false}
                style={{height:"300"}}>
              </Table>
            </div>
          </div>
          <div
            style={{display:"flex",marginTop:"10px", alignItems: "center"}}>
            
            <div
              style={{fontWeight: "bold", fontSize: "1.2rem"}}>
              ยอดคืนรวม
            </div>
            <div
              style={{flex:"1"}}>
              
              <div
                style={{ margin: "0px 20px", padding: "5px 20px", textAlign: "right", border: "1px solid rgba(200, 200, 200, 0.5)"}}>
                {props.priceRefund}
              </div>
            </div>
            <Button
              color="green"
              onClick={props.onRefund}>
              คืนเงิน
            </Button>
          </div>
        </Segment>
      </div>
    )
}

export default CardRefundUX

export const screenPropsDefault = {"cannotPay":false,"cashAmountText":"14","citizenID":"31020000552","oweText":123,"patientNameAndHN":"HN: xxxxx นายทดสอบ","sumAmount":{"price":100,"reimbursable":42}}

/* Date Time : Wed Feb 15 2023 06:55:15 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width : \"100%\", height: \"100%\" }"
        }
      },
      "seq": 0
    },
    {
      "from": "semantic-ui-react",
      "id": 1,
      "name": "Segment",
      "parent": 0,
      "props": {
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "REFUND"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.2rem\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "hr",
      "parent": 1,
      "props": {
      },
      "seq": 6,
      "void": true
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "ข้อมูลรายการค่ารักษาพยาบาลรอรับชำระ"
        },
        "style": {
          "type": "code",
          "value": "{cursor: \"pointer\", fontWeight: \"bold\", fontSize: \"1.2rem\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 17,
      "name": "Table",
      "parent": 16,
      "props": {
        "columns": {
          "type": "code",
          "value": ""
        },
        "data": {
          "type": "code",
          "value": "props.refundOrders"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getTrProps"
        },
        "headers": {
          "type": "value",
          "value": "หมวด,รหัส,สินค้า,ใบเสร็จ,จำนวนรวม,ราคาต่อหน่วย,ยอดชำระ,จำนวนคืน,ยอดคืน"
        },
        "keys": {
          "type": "value",
          "value": "mode_code,pricing_code,pricing_name,receipts,pricing_quantity,unitprice,payable,quantity,price"
        },
        "minRows": {
          "type": "value",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height:\"300\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",marginTop:\"10px\", alignItems: \"center\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "Button",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "คืนเงิน"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": ""
        },
        "onClick": {
          "type": "code",
          "value": "props.onRefund"
        },
        "size": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:\"1\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "ยอดคืนรวม"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.2rem\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 21,
      "props": {
        "children": {
          "type": "code",
          "value": "props.priceRefund"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 20px\", padding: \"5px 20px\", textAlign: \"right\", border: \"1px solid rgba(200, 200, 200, 0.5)\"}"
        }
      },
      "seq": 25,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardRefundUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "cannotPay": false,
    "cashAmountText": "14",
    "citizenID": "31020000552",
    "oweText": 123,
    "patientNameAndHN": "HN: xxxxx นายทดสอบ",
    "sumAmount": {
      "price": 100,
      "reimbursable": 42
    }
  },
  "width": 75
}

*********************************************************************************** */
