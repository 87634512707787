import WasmController from 'react-lib/frameworks/WasmController';
import MixInService from "react-lib/apis/services/MixInService";
import { to } from "react-lib/utils";

export type State = 
  {
  }

export const StateInitial: State = 
  {
  }

export type Event = 
  { message: "getPrintAPI", params: any }
  |  { message: "getRegisterByTokenFacebook", params: any }

export type Data = 
  {
    division?: number,
  }

export const DataInitial = 
  {
  }

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any 

export const getPrintAPI: Handler = async (controller, 
  { printAPI, patientId, encounterId, jasperModule, jasperPath, pdf, orderId, confirm, reason}
) => {
  let params: any = {};
  if (patientId) {
    params.patient_id = patientId;
  }
  if (encounterId) {
    params.encounter_id = encounterId;
  }
  if (jasperModule) {
    params.jasper_module = jasperModule;
  }
  if (jasperPath) {
    params.jasper_path = jasperPath;
  }
  if (pdf) {
    params.pdf = pdf;
  }
  if (orderId) {
    params.order_id = orderId;
  }
  if (confirm) {
    params.confirm = confirm;
  }
  if (reason) {
    params.reason = reason;
  }
  const mixInService = new MixInService(controller.apiToken);
  const [error, response, network] = await to(
    mixInService.getPrintAPI({ printAPI, params, divisionId: controller.data.division })
  );
  return [response, error, network];
}

export const getRegisterByTokenFacebook: Handler = async (controller, {}) => {
//function (_a) {
//            var accessToken = _a.accessToken, subscription = _a.subscription, device_id = _a.device_id, app = _a.app, email = _a.email;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, mixInService, _b, error, response, network;
//                return __generator(this, function (_c) {
//                    switch (_c.label) {
//                        case 0:
//                            params = {};
//                            console.log("getRegisterByTokenFacebook... subscription: ", subscription);
//                            console.log("getRegisterByTokenFacebook... device_id: ", device_id);
//                            console.log("getRegisterByTokenFacebook... app: ", app);
//                            console.log("getRegisterByTokenFacebook... email: ", email);
//                            if (accessToken) {
//                                params.access_token = accessToken;
//                            }
//                            if (subscription) {
//                                params.device_token = JSON.stringify(subscription);
//                            }
//                            if (device_id) {
//                                params.unique_id = device_id;
//                                params.device_id = device_id;
//                                params.device_type = "webpush";
//                            }
//                            if (app) {
//                                params.app = app;
//                            }
//                            if (email) {
//                                params.email = email;
//                            }
//                            mixInService = new MixInService_1.default();
//                            return [4 /*yield*/, utils_1.to(mixInService.getRegisterByTokenFacebook({ params: params }))];
//                        case 1:
//                            _b = _c.sent(), error = _b[0], response = _b[1], network = _b[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params;
//        return this.client.get(apis_1.MIXIN.REGISTER_BY_TOKEN_FACEBOOK, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//https://accounts.thevcgroup.com/apis/register_by_token/facebook/
}
