import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import { 
  Table
} from 'react-lib/frameworks/Table'
import {
  FormGroup,
  FormField,
  TextArea,
  Button
} from 'semantic-ui-react'

const ANES_PACU_Fluid = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        id="CardPACURecordFluid">
        <div
          style={{backgroundColor:"rgb(214, 236, 243, 0.23)",width: "100%",height:"500px"}}>
          
          <div
            style={{height:"50px",display:"flex",backgroundColor:"#B4E4EF",border: "solid 1px rgb(200, 200, 200, 0.5)"}}>
            
            <label
              style={{textAlign:"center",marginLeft:"20px",paddingTop:"10px","fontWeight":"bold","fontSize":"20px"}}>
              Medication / IV Fluid
            </label>
          </div>
          <div>
            
            <div
              className="ui form"
              style={{ margin: "20px"}}>
              
              <FormGroup
                inline={true}>
                <FormField
                  inline={true}
                  width={14}>
                  <div
                    style={{display:"flex",alignItems: "center", width: "100%"}}>
                    
                    <label
                      style={{textAlign:"center","fontSize":"16px",marginRight:"10px"}}>
                      Time
                    </label>
                    <div>
                      {props.fluidTime}
                    </div>
                    <TextArea
                      name="remark"
                      onChange={props.onChangeFluidInput}
                      style={{marginLeft: "30px"}}
                      value={props.fluid?.remark || ""}>
                    </TextArea>
                  </div>
                </FormField>
                <FormField
                  inline={true}
                  width={2}>
                  <Button
                    color={props.fluid?.index === "" ?  "blue" : "yellow"}
                    onClick={props.onAddFluid}
                    style={{margin:"10px"}}>
                    {props.fluid?.index === "" ?  "Add" : "Edit"}
                  </Button>
                </FormField>
              </FormGroup>
            </div>
          </div>
          <Table
            data={props.ansFluidData || []}
            headers="Time,Medication/IV Fluid,"
            keys="time,remark,menu"
            minRows="8"
            widths="150,auto,150">
          </Table>
        </div>
      </div>
    )
}


export default ANES_PACU_Fluid

export const screenPropsDefault = {}