import axios from 'axios'
import { to } from '../../../utils'
import BaseService from  '../../services/BaseService'
import config from '../../../../config/config'
import { Response, Error } from '../common'
const HOST = `${config.API_HOST}`

export interface EncounterSerializer {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    status?: any,
    episode?: any,
    number?: any,
    type?: any,
    patient?: any,
    division?: any,
    doctor?: any,
    remark?: any,
    note?: any,
    drug_allergy?: any,
    other_allergy?: any,
    underlying_disease?: any,
    started?: any,
    ended?: any,
    triage?: any,
    triage_level?: any,
    chief_complaints?: any,
    predischarge_condition?: any,
    discharge_status?: any,
    discharge_note?: any,
    is_appointment?: any,
    queue_date?: any,
    queue_time?: any,
    hospital_fee_charged?: any,
    clinic_type?: any,
    cleaning?: any,
    examination_type?: any,
    patient_case?: any,
    pk?: any,
    action?: any,
    doctor_name?: any,
    patient_name?: any,
    patient_id?: any,
    patient_reg_type?: any,
    patient_gender_name?: any,
    patient_birthdate?: any,
    patient_age?: any,
    division_name?: any,
    division_short_name?: any,
    division_code?: any,
    hn?: any,
    status_name?: any,
    status_value?: any,
    is_nurse_key_discharge_form_expired?: any,
    is_doctor_key_discharge_form_expired?: any,
    medical_record?: any,
    checkout_cause?: any,
    location?: any,
    location_id?: any,
    go_to?: any,
    appoint_time?: any,
    coverage?: any,
    payer_name?: any,
    coverage_code?: any,
    allowed_actions?: any,
    general_type?: any,
    zone?: any,
    encounter_checkin_time?: any,
    encounter_checkout_time?: any,
    checkout_time?: any,
    is_telemed?: any,
}


/* ['generics.ListCreateAPIView'] */
/* params: patient, patient__hn, 'active_only', 'unexpired_only', 'status', 'action', 'bil_queue', 'bil_div', 'type', 'order_by_created', 'number' */
/* data:  */
export const EncounterList : 
{
  list:     ({params, apiToken, extra}:
             { 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
  create:   ({params, data, apiToken, extra}:
             { 
                params?: any,
                data?: EncounterSerializer,
                apiToken?: any,
                extra?: any
            }) => any,
} = 
{
  list: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/core/encounter/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  create: async ({params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.post(`${HOST}/apis/core/encounter/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

/* ['generics.RetrieveUpdateDestroyAPIView'] */
/* params:  */
/* data: 'action', 'episode', 'type', 'division', 'note' */
export const EncounterDetail : 
{
  retrieve: ({pk, params, apiToken, extra}:
             { pk: any, 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
  update:   ({pk, params, data, apiToken, extra}:
             { pk: any, 
                params?: any,
                data?: EncounterSerializer,
                apiToken?: any,
                extra?: any,
            }) => any,
  patch:    ({pk, params, data, apiToken, extra}:
             { pk: any, 
                params?: any,
                data?: EncounterSerializer,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  retrieve: async ({pk, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/core/encounter/${pk}/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  update: async ({pk, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.put(`${HOST}/apis/core/encounter/${pk}/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  patch: async ({pk, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.patch(`${HOST}/apis/core/encounter/${pk}/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

/* ['generics.RetrieveAPIView'] */
/* params:  */
/* data:  */
export const LastPatientEncounterDetail : 
{
  retrieve: ({pk, params, apiToken, extra}:
             { pk: any, 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  retrieve: async ({pk, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/core/encounter/last-patient-encounter/${pk}/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

/* ['generics.ListAPIView'] */
/* params: 'hn' */
/* data:  */
export const EncounterByClinicalNoteListView : 
{
  list:     ({params, apiToken, extra}:
             { 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  list: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/core/encounter-by-note/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

export interface EncounterActionOnlySerializer {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    status?: any,
    episode?: any,
    number?: any,
    type?: any,
    patient?: any,
    division?: any,
    doctor?: any,
    remark?: any,
    note?: any,
    drug_allergy?: any,
    other_allergy?: any,
    underlying_disease?: any,
    started?: any,
    ended?: any,
    triage?: any,
    triage_level?: any,
    chief_complaints?: any,
    predischarge_condition?: any,
    discharge_status?: any,
    discharge_note?: any,
    is_appointment?: any,
    queue_date?: any,
    queue_time?: any,
    hospital_fee_charged?: any,
    clinic_type?: any,
    cleaning?: any,
    examination_type?: any,
    patient_case?: any,
    action?: any,
}


/* ['EncounterDetail'] */
/* params:  */
/* data:  */
export const EncounterActionOnly : 
{
} = 
{}
/*----------------------------------------------------------------------------------------------------*/

export interface EncounterPatientSerializer {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    status?: any,
    episode?: any,
    number?: any,
    type?: any,
    patient?: any,
    division?: any,
    doctor?: any,
    remark?: any,
    note?: any,
    drug_allergy?: any,
    other_allergy?: any,
    underlying_disease?: any,
    started?: any,
    ended?: any,
    triage?: any,
    triage_level?: any,
    chief_complaints?: any,
    predischarge_condition?: any,
    discharge_status?: any,
    discharge_note?: any,
    is_appointment?: any,
    queue_date?: any,
    queue_time?: any,
    hospital_fee_charged?: any,
    clinic_type?: any,
    cleaning?: any,
    examination_type?: any,
    patient_case?: any,
    pk?: any,
    action?: any,
    doctor_name?: any,
    patient_name?: any,
    patient_id?: any,
    patient_reg_type?: any,
    patient_gender_name?: any,
    patient_birthdate?: any,
    patient_age?: any,
    division_name?: any,
    division_short_name?: any,
    division_code?: any,
    hn?: any,
    status_name?: any,
    status_value?: any,
    is_nurse_key_discharge_form_expired?: any,
    is_doctor_key_discharge_form_expired?: any,
    medical_record?: any,
    checkout_cause?: any,
    location?: any,
    location_id?: any,
    go_to?: any,
    appoint_time?: any,
    coverage?: any,
    payer_name?: any,
    coverage_code?: any,
    allowed_actions?: any,
    general_type?: any,
    zone?: any,
    encounter_checkin_time?: any,
    encounter_checkout_time?: any,
    checkout_time?: any,
    is_telemed?: any,
    patient_birthday?: any,
    patient_full_age?: any,
    doctor_orders?: any,
    document_info?: any,
    zone_log_id?: any,
    zone_name?: any,
    zone_in_time?: any,
    zone_out_time?: any,
    welfare_category?: any,
    doctor_name_code?: any,
}


/* ['generics.ListAPIView'] */
/* params: patient, patient__hn, division, doctor, 'from_date', 'to_date', 'patient_name', 'first_name', 'last_name', 'status', 'zone', 'exclude_canceled' */
/* data:  */
export const EncounterPatientList : 
{
  list:     ({params, apiToken, extra}:
             { 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  list: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/core/encounter-patient/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

/* ['generics.RetrieveAPIView'] */
/* params: patient, patient__hn, division, doctor */
/* data:  */
export const EncounterPatientDetail : 
{
  retrieve: ({pk, params, apiToken, extra}:
             { pk: any, 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  retrieve: async ({pk, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/core/encounter-patient/${pk}/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

export interface EncounterPatientOptimizedSerializer {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    status?: any,
    episode?: any,
    number?: any,
    type?: any,
    patient?: any,
    division?: any,
    doctor?: any,
    remark?: any,
    note?: any,
    drug_allergy?: any,
    other_allergy?: any,
    underlying_disease?: any,
    started?: any,
    ended?: any,
    triage?: any,
    triage_level?: any,
    chief_complaints?: any,
    predischarge_condition?: any,
    discharge_status?: any,
    discharge_note?: any,
    is_appointment?: any,
    queue_date?: any,
    queue_time?: any,
    hospital_fee_charged?: any,
    clinic_type?: any,
    cleaning?: any,
    examination_type?: any,
    patient_case?: any,
    pk?: any,
    action?: any,
    doctor_name?: any,
    patient_name?: any,
    patient_id?: any,
    patient_reg_type?: any,
    patient_gender_name?: any,
    patient_birthdate?: any,
    patient_age?: any,
    division_name?: any,
    division_short_name?: any,
    division_code?: any,
    hn?: any,
    status_name?: any,
    status_value?: any,
    is_nurse_key_discharge_form_expired?: any,
    is_doctor_key_discharge_form_expired?: any,
    medical_record?: any,
    checkout_cause?: any,
    location?: any,
    location_id?: any,
    go_to?: any,
    appoint_time?: any,
    coverage?: any,
    payer_name?: any,
    coverage_code?: any,
    allowed_actions?: any,
    general_type?: any,
    zone?: any,
    encounter_checkin_time?: any,
    encounter_checkout_time?: any,
    checkout_time?: any,
    is_telemed?: any,
    patient_birthday?: any,
    patient_full_age?: any,
    doctor_orders?: any,
    document_info?: any,
    zone_log_id?: any,
    zone_name?: any,
    zone_in_time?: any,
    zone_out_time?: any,
    welfare_category?: any,
    doctor_name_code?: any,
    bed_no?: any,
    zone_display_seq?: any,
    doctor_first_name?: any,
    division_color?: any,
}

/*----------------------------------------------------------------------------------------------------*/

export interface EncounterSearchSerializer {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    status?: any,
    episode?: any,
    number?: any,
    type?: any,
    patient?: any,
    division?: any,
    doctor?: any,
    remark?: any,
    note?: any,
    drug_allergy?: any,
    other_allergy?: any,
    underlying_disease?: any,
    started?: any,
    ended?: any,
    triage?: any,
    triage_level?: any,
    chief_complaints?: any,
    predischarge_condition?: any,
    discharge_status?: any,
    discharge_note?: any,
    is_appointment?: any,
    queue_date?: any,
    queue_time?: any,
    hospital_fee_charged?: any,
    clinic_type?: any,
    cleaning?: any,
    examination_type?: any,
    patient_case?: any,
    pk?: any,
    action?: any,
    doctor_name?: any,
    patient_name?: any,
    patient_id?: any,
    patient_reg_type?: any,
    patient_gender_name?: any,
    patient_birthdate?: any,
    patient_age?: any,
    division_name?: any,
    division_short_name?: any,
    division_code?: any,
    hn?: any,
    status_name?: any,
    status_value?: any,
    is_nurse_key_discharge_form_expired?: any,
    is_doctor_key_discharge_form_expired?: any,
    medical_record?: any,
    checkout_cause?: any,
    location?: any,
    location_id?: any,
    go_to?: any,
    appoint_time?: any,
    coverage?: any,
    payer_name?: any,
    coverage_code?: any,
    allowed_actions?: any,
    general_type?: any,
    zone?: any,
    encounter_checkin_time?: any,
    encounter_checkout_time?: any,
    checkout_time?: any,
    is_telemed?: any,
    number_date?: any,
}


/* ['generics.ListAPIView'] */
/* params: id, patient, patient__hn */
/* data:  */
export const EncounterSearchList : 
{
  list:     ({params, apiToken, extra}:
             { 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  list: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/core/encounter/search/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

/* ['generics.RetrieveAPIView'] */
/* params:  */
/* data:  */
export const EncounterSearchDetail : 
{
  retrieve: ({pk, params, apiToken, extra}:
             { pk: any, 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  retrieve: async ({pk, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/core/encounter/search/${pk}/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

export interface TriageFromEncounterSerializer {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    status?: any,
    episode?: any,
    number?: any,
    type?: any,
    patient?: any,
    division?: any,
    doctor?: any,
    remark?: any,
    note?: any,
    drug_allergy?: any,
    other_allergy?: any,
    underlying_disease?: any,
    started?: any,
    ended?: any,
    triage?: any,
    triage_level?: any,
    chief_complaints?: any,
    predischarge_condition?: any,
    discharge_status?: any,
    discharge_note?: any,
    is_appointment?: any,
    queue_date?: any,
    queue_time?: any,
    hospital_fee_charged?: any,
    clinic_type?: any,
    cleaning?: any,
    examination_type?: any,
    patient_case?: any,
    chief_complaint?: any,
    arrive_status?: any,
}


/* ['generics.RetrieveAPIView'] */
/* params:  */
/* data:  */
export const TriageFromEncounter : 
{
  retrieve: ({pk, params, apiToken, extra}:
             { pk: any, 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  retrieve: async ({pk, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/core/triage/encounter/${pk}/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

export interface DoctorOrderInEncounterSerializer {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    status?: any,
    episode?: any,
    number?: any,
    type?: any,
    patient?: any,
    division?: any,
    doctor?: any,
    remark?: any,
    note?: any,
    drug_allergy?: any,
    other_allergy?: any,
    underlying_disease?: any,
    started?: any,
    ended?: any,
    triage?: any,
    triage_level?: any,
    chief_complaints?: any,
    predischarge_condition?: any,
    discharge_status?: any,
    discharge_note?: any,
    is_appointment?: any,
    queue_date?: any,
    queue_time?: any,
    hospital_fee_charged?: any,
    clinic_type?: any,
    cleaning?: any,
    examination_type?: any,
    patient_case?: any,
    emrs?: any,
    doctor_orders?: any,
}

/*----------------------------------------------------------------------------------------------------*/

export interface EncounterPatientDiagnosisSerializer {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    status?: any,
    episode?: any,
    number?: any,
    type?: any,
    patient?: any,
    division?: any,
    doctor?: any,
    remark?: any,
    note?: any,
    drug_allergy?: any,
    other_allergy?: any,
    underlying_disease?: any,
    started?: any,
    ended?: any,
    triage?: any,
    triage_level?: any,
    chief_complaints?: any,
    predischarge_condition?: any,
    discharge_status?: any,
    discharge_note?: any,
    is_appointment?: any,
    queue_date?: any,
    queue_time?: any,
    hospital_fee_charged?: any,
    clinic_type?: any,
    cleaning?: any,
    examination_type?: any,
    patient_case?: any,
    pk?: any,
    action?: any,
    doctor_name?: any,
    patient_name?: any,
    patient_id?: any,
    patient_reg_type?: any,
    patient_gender_name?: any,
    patient_birthdate?: any,
    patient_age?: any,
    division_name?: any,
    division_short_name?: any,
    division_code?: any,
    hn?: any,
    status_name?: any,
    status_value?: any,
    is_nurse_key_discharge_form_expired?: any,
    is_doctor_key_discharge_form_expired?: any,
    medical_record?: any,
    checkout_cause?: any,
    location?: any,
    location_id?: any,
    go_to?: any,
    appoint_time?: any,
    coverage?: any,
    payer_name?: any,
    coverage_code?: any,
    allowed_actions?: any,
    general_type?: any,
    zone?: any,
    encounter_checkin_time?: any,
    encounter_checkout_time?: any,
    checkout_time?: any,
    is_telemed?: any,
    patient_birthday?: any,
    patient_full_age?: any,
    doctor_orders?: any,
    document_info?: any,
    zone_log_id?: any,
    zone_name?: any,
    zone_in_time?: any,
    zone_out_time?: any,
    welfare_category?: any,
    doctor_name_code?: any,
    diagnoses?: any,
}


/* ['generics.ListAPIView'] */
/* params: 'episode' */
/* data:  */
export const EpisodeEncounterList : 
{
  list:     ({params, apiToken, extra}:
             { 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  list: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/core/episode-encounter/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

export interface SecretEncounterSerializer {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    status?: any,
    episode?: any,
    number?: any,
    type?: any,
    patient?: any,
    division?: any,
    doctor?: any,
    remark?: any,
    note?: any,
    drug_allergy?: any,
    other_allergy?: any,
    underlying_disease?: any,
    started?: any,
    ended?: any,
    triage?: any,
    triage_level?: any,
    chief_complaints?: any,
    predischarge_condition?: any,
    discharge_status?: any,
    discharge_note?: any,
    is_appointment?: any,
    queue_date?: any,
    queue_time?: any,
    hospital_fee_charged?: any,
    clinic_type?: any,
    cleaning?: any,
    examination_type?: any,
    patient_case?: any,
    division_display_name?: any,
    doctor_display_name?: any,
    active?: any,
}


/* ['generics.ListCreateAPIView'] */
/* params: 'patient' */
/* data: 'items' */
export const SecretEncounterList : 
{
  list:     ({params, apiToken, extra}:
             { 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
  create:   ({params, data, apiToken, extra}:
             { 
                params?: any,
                data?: SecretEncounterSerializer,
                apiToken?: any,
                extra?: any
            }) => any,
} = 
{
  list: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/core/secret-encounter/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  create: async ({params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.post(`${HOST}/apis/core/secret-encounter/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

export interface MAIMAIEncounterSerializer {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    status?: any,
    episode?: any,
    number?: any,
    type?: any,
    patient?: any,
    division?: any,
    doctor?: any,
    remark?: any,
    note?: any,
    drug_allergy?: any,
    other_allergy?: any,
    underlying_disease?: any,
    started?: any,
    ended?: any,
    triage?: any,
    triage_level?: any,
    chief_complaints?: any,
    predischarge_condition?: any,
    discharge_status?: any,
    discharge_note?: any,
    is_appointment?: any,
    queue_date?: any,
    queue_time?: any,
    hospital_fee_charged?: any,
    clinic_type?: any,
    cleaning?: any,
    examination_type?: any,
    patient_case?: any,
    pk?: any,
    action?: any,
    doctor_name?: any,
    patient_name?: any,
    patient_id?: any,
    patient_reg_type?: any,
    patient_gender_name?: any,
    patient_birthdate?: any,
    patient_age?: any,
    division_name?: any,
    division_short_name?: any,
    division_code?: any,
    hn?: any,
    status_name?: any,
    status_value?: any,
    is_nurse_key_discharge_form_expired?: any,
    is_doctor_key_discharge_form_expired?: any,
    medical_record?: any,
    checkout_cause?: any,
    location?: any,
    location_id?: any,
    go_to?: any,
    appoint_time?: any,
    coverage?: any,
    payer_name?: any,
    coverage_code?: any,
    allowed_actions?: any,
    general_type?: any,
    zone?: any,
    encounter_checkin_time?: any,
    encounter_checkout_time?: any,
    checkout_time?: any,
    is_telemed?: any,
    diag_text?: any,
    chief_complaint?: any,
    present_illness?: any,
    drug_order?: any,
    lab_order?: any,
}


/* ['generics.ListAPIView'] */
/* params: patient, patient__hn, 'patient', 'active_only', 'unexpired_only', 'status', 'action', 'bil_queue', 'bil_div', 'type' */
/* data:  */
export const MAIEncounterList : 
{
  list:     ({params, apiToken, extra}:
             { 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  list: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/MAI/ecounter-list/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

export interface ANSAnesthesiaQueueEncounterSerializer {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    status?: any,
    episode?: any,
    number?: any,
    type?: any,
    patient?: any,
    division?: any,
    doctor?: any,
    remark?: any,
    note?: any,
    drug_allergy?: any,
    other_allergy?: any,
    underlying_disease?: any,
    started?: any,
    ended?: any,
    triage?: any,
    triage_level?: any,
    chief_complaints?: any,
    predischarge_condition?: any,
    discharge_status?: any,
    discharge_note?: any,
    is_appointment?: any,
    queue_date?: any,
    queue_time?: any,
    hospital_fee_charged?: any,
    clinic_type?: any,
    cleaning?: any,
    examination_type?: any,
    patient_case?: any,
    encounter?: any,
    anesthesia_order_id?: any,
    anesthesia_order_status?: any,
    anesthesia_order_date?: any,
    anesthesia_order_status_value?: any,
    allowed_actions?: any,
}

/*----------------------------------------------------------------------------------------------------*/

export interface ANSAnesthesiaConsultSerializer {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    status?: any,
    episode?: any,
    number?: any,
    type?: any,
    patient?: any,
    division?: any,
    doctor?: any,
    remark?: any,
    note?: any,
    drug_allergy?: any,
    other_allergy?: any,
    underlying_disease?: any,
    started?: any,
    ended?: any,
    triage?: any,
    triage_level?: any,
    chief_complaints?: any,
    predischarge_condition?: any,
    discharge_status?: any,
    discharge_note?: any,
    is_appointment?: any,
    queue_date?: any,
    queue_time?: any,
    hospital_fee_charged?: any,
    clinic_type?: any,
    cleaning?: any,
    examination_type?: any,
    patient_case?: any,
    action?: any,
    user?: any,
    allowed_actions?: any,
    emr?: any,
}


/* ['generics.RetrieveUpdateAPIView'] */
/* params:  */
/* data:  */
export const ANSAnesthesiaConsult : 
{
  retrieve: ({pk, params, apiToken, extra}:
             { pk: any, 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
  update:   ({pk, params, data, apiToken, extra}:
             { pk: any, 
                params?: any,
                data?: ANSAnesthesiaConsultSerializer,
                apiToken?: any,
                extra?: any,
            }) => any,
  patch:    ({pk, params, data, apiToken, extra}:
             { pk: any, 
                params?: any,
                data?: ANSAnesthesiaConsultSerializer,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  retrieve: async ({pk, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/ANS/anesthesia-consult/${pk}/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  update: async ({pk, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.put(`${HOST}/apis/ANS/anesthesia-consult/${pk}/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  patch: async ({pk, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.patch(`${HOST}/apis/ANS/anesthesia-consult/${pk}/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

export interface TECGetTECSessionsFromEncounterSerializer {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    status?: any,
    episode?: any,
    number?: any,
    type?: any,
    patient?: any,
    division?: any,
    doctor?: any,
    remark?: any,
    note?: any,
    drug_allergy?: any,
    other_allergy?: any,
    underlying_disease?: any,
    started?: any,
    ended?: any,
    triage?: any,
    triage_level?: any,
    chief_complaints?: any,
    predischarge_condition?: any,
    discharge_status?: any,
    discharge_note?: any,
    is_appointment?: any,
    queue_date?: any,
    queue_time?: any,
    hospital_fee_charged?: any,
    clinic_type?: any,
    cleaning?: any,
    examination_type?: any,
    patient_case?: any,
    session_ids?: any,
}


/* ['generics.RetrieveAPIView'] */
/* params:  */
/* data:  */
export const TECGetTECSessions : 
{
  retrieve: ({pk, params, apiToken, extra}:
             { pk: any, 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  retrieve: async ({pk, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/TEC/get-tec-sessions/enconter/${pk}/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

export interface IMEEncounterSerializer {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    status?: any,
    episode?: any,
    number?: any,
    type?: any,
    patient?: any,
    division?: any,
    doctor?: any,
    remark?: any,
    note?: any,
    drug_allergy?: any,
    other_allergy?: any,
    underlying_disease?: any,
    started?: any,
    ended?: any,
    triage?: any,
    triage_level?: any,
    chief_complaints?: any,
    predischarge_condition?: any,
    discharge_status?: any,
    discharge_note?: any,
    is_appointment?: any,
    queue_date?: any,
    queue_time?: any,
    hospital_fee_charged?: any,
    clinic_type?: any,
    cleaning?: any,
    examination_type?: any,
    patient_case?: any,
    insurance_code?: any,
    insurance_name?: any,
    visit_date?: any,
    visit_internal_no?: any,
    visit_external_no?: any,
    visit_is_active?: any,
    admission_no?: any,
    reference_doctor_id?: any,
    reference_doctor_code?: any,
    reference_doctor_fname?: any,
    reference_doctor_lname?: any,
    reference_unit_code?: any,
    reference_unit_name?: any,
}


/* ['generics.ListAPIView'] */
/* params: HN */
/* data:  */
export const IMEEncounterList : 
{
  list:     ({params, apiToken, extra}:
             { 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  list: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/IME/get_encounters/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

export interface IMEDemographicSerializer {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    status?: any,
    episode?: any,
    number?: any,
    type?: any,
    patient?: any,
    division?: any,
    doctor?: any,
    remark?: any,
    note?: any,
    drug_allergy?: any,
    other_allergy?: any,
    underlying_disease?: any,
    started?: any,
    ended?: any,
    triage?: any,
    triage_level?: any,
    chief_complaints?: any,
    predischarge_condition?: any,
    discharge_status?: any,
    discharge_note?: any,
    is_appointment?: any,
    queue_date?: any,
    queue_time?: any,
    hospital_fee_charged?: any,
    clinic_type?: any,
    cleaning?: any,
    examination_type?: any,
    patient_case?: any,
    insurance_code?: any,
    insurance_name?: any,
    visit_date?: any,
    visit_internal_no?: any,
    visit_external_no?: any,
    visit_is_active?: any,
    admission_no?: any,
    reference_doctor_id?: any,
    reference_doctor_code?: any,
    reference_doctor_fname?: any,
    reference_doctor_lname?: any,
    reference_unit_code?: any,
    reference_unit_name?: any,
}


/* ['generics.RetrieveAPIView'] */
/* params:  */
/* data:  */
export const IMEDemographicDetail : 
{
  retrieve: ({pk, params, apiToken, extra}:
             { pk: any, 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  retrieve: async ({pk, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/IME/get_demographic/${pk}/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

export interface IMEImagingOrderForRISSerializer {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    status?: any,
    episode?: any,
    number?: any,
    type?: any,
    patient?: any,
    division?: any,
    doctor?: any,
    remark?: any,
    note?: any,
    drug_allergy?: any,
    other_allergy?: any,
    underlying_disease?: any,
    started?: any,
    ended?: any,
    triage?: any,
    triage_level?: any,
    chief_complaints?: any,
    predischarge_condition?: any,
    discharge_status?: any,
    discharge_note?: any,
    is_appointment?: any,
    queue_date?: any,
    queue_time?: any,
    hospital_fee_charged?: any,
    clinic_type?: any,
    cleaning?: any,
    examination_type?: any,
    patient_case?: any,
    insurance_code?: any,
    insurance_name?: any,
    hn?: any,
    order_dt?: any,
    ref_unit_code?: any,
    ref_unit_name?: any,
    ref_doc_code?: any,
    ref_doc_fname?: any,
    ref_doc_lname?: any,
    clinical_instruction?: any,
    enc_clinic?: any,
    enc_type?: any,
}

/*----------------------------------------------------------------------------------------------------*/

export interface PTMFirstAssessmentOPDSerializer {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    status?: any,
    episode?: any,
    number?: any,
    type?: any,
    patient?: any,
    division?: any,
    doctor?: any,
    remark?: any,
    note?: any,
    drug_allergy?: any,
    other_allergy?: any,
    underlying_disease?: any,
    started?: any,
    ended?: any,
    triage?: any,
    triage_level?: any,
    chief_complaints?: any,
    predischarge_condition?: any,
    discharge_status?: any,
    discharge_note?: any,
    is_appointment?: any,
    queue_date?: any,
    queue_time?: any,
    hospital_fee_charged?: any,
    clinic_type?: any,
    cleaning?: any,
    examination_type?: any,
    patient_case?: any,
    chief_complaint?: any,
    arrive_by?: any,
    translator?: any,
    functional_assessment?: any,
    phychological_assessment?: any,
    phyco_nurse_note?: any,
    spiritual_assessment?: any,
    suspected_abuse?: any,
    sus_nurse_note?: any,
    history_of_febrile_convulsion?: any,
    mdr_triage?: any,
    isolate_precaution?: any,
    alcohol?: any,
    tobacco?: any,
    narcotic?: any,
    menstruation?: any,
    fall_risk_assessment?: any,
    pain_assessment?: any,
    nutrition_assessment?: any,
}

/*----------------------------------------------------------------------------------------------------*/

export interface LABEncounterInterfaceSerializer {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    status?: any,
    episode?: any,
    number?: any,
    type?: any,
    patient?: any,
    division?: any,
    doctor?: any,
    remark?: any,
    note?: any,
    drug_allergy?: any,
    other_allergy?: any,
    underlying_disease?: any,
    started?: any,
    ended?: any,
    triage?: any,
    triage_level?: any,
    chief_complaints?: any,
    predischarge_condition?: any,
    discharge_status?: any,
    discharge_note?: any,
    is_appointment?: any,
    queue_date?: any,
    queue_time?: any,
    hospital_fee_charged?: any,
    clinic_type?: any,
    cleaning?: any,
    examination_type?: any,
    patient_case?: any,
    insurance_code?: any,
    insurance_name?: any,
    visit_date?: any,
    visit_internal_no?: any,
    visit_external_no?: any,
    visit_is_active?: any,
    admission_no?: any,
    reference_doctor_id?: any,
    reference_doctor_code?: any,
    reference_doctor_fname?: any,
    reference_doctor_lname?: any,
    reference_unit_code?: any,
    reference_unit_name?: any,
}

/*----------------------------------------------------------------------------------------------------*/

export interface DPOEncounterHistoryListSerializer {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    status?: any,
    episode?: any,
    number?: any,
    type?: any,
    patient?: any,
    division?: any,
    doctor?: any,
    remark?: any,
    note?: any,
    drug_allergy?: any,
    other_allergy?: any,
    underlying_disease?: any,
    started?: any,
    ended?: any,
    triage?: any,
    triage_level?: any,
    chief_complaints?: any,
    predischarge_condition?: any,
    discharge_status?: any,
    discharge_note?: any,
    is_appointment?: any,
    queue_date?: any,
    queue_time?: any,
    hospital_fee_charged?: any,
    clinic_type?: any,
    cleaning?: any,
    examination_type?: any,
    patient_case?: any,
    doctor_name?: any,
    patient_name?: any,
    division_code?: any,
    division_name?: any,
    status_name?: any,
    hn?: any,
    medical_record?: any,
    diagnosis?: any,
    medical?: any,
    date?: any,
    discharge_date?: any,
    type_num?: any,
    doctor_code?: any,
    is_secret?: any,
}


/* ['generics.ListAPIView'] */
/* params: 'patient', 'start', 'end', 'doctor', 'division', 'selected_tab' */
/* data:  */
export const DPOEncounterHistoryList : 
{
  list:     ({params, apiToken, extra}:
             { 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  list: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/DPO/encounter-history/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

export interface INFEncounterFromARSerializer {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    status?: any,
    episode?: any,
    number?: any,
    type?: any,
    patient?: any,
    division?: any,
    doctor?: any,
    remark?: any,
    note?: any,
    drug_allergy?: any,
    other_allergy?: any,
    underlying_disease?: any,
    started?: any,
    ended?: any,
    triage?: any,
    triage_level?: any,
    chief_complaints?: any,
    predischarge_condition?: any,
    discharge_status?: any,
    discharge_note?: any,
    is_appointment?: any,
    queue_date?: any,
    queue_time?: any,
    hospital_fee_charged?: any,
    clinic_type?: any,
    cleaning?: any,
    examination_type?: any,
    patient_case?: any,
    display_name?: any,
}

/*----------------------------------------------------------------------------------------------------*/

export interface INFAdmissionSerializer {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    status?: any,
    episode?: any,
    number?: any,
    type?: any,
    patient?: any,
    division?: any,
    doctor?: any,
    remark?: any,
    note?: any,
    drug_allergy?: any,
    other_allergy?: any,
    underlying_disease?: any,
    started?: any,
    ended?: any,
    triage?: any,
    triage_level?: any,
    chief_complaints?: any,
    predischarge_condition?: any,
    discharge_status?: any,
    discharge_note?: any,
    is_appointment?: any,
    queue_date?: any,
    queue_time?: any,
    hospital_fee_charged?: any,
    clinic_type?: any,
    cleaning?: any,
    examination_type?: any,
    patient_case?: any,
    document_date?: any,
    hn?: any,
    encounter_type?: any,
    encounter_id?: any,
    admission_date?: any,
    admission_time?: any,
    ward?: any,
    department?: any,
    delete_code?: any,
    delete_date?: any,
    delete_time?: any,
}

/*----------------------------------------------------------------------------------------------------*/

export interface LRMQueueSerializers {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    status?: any,
    episode?: any,
    number?: any,
    type?: any,
    patient?: any,
    division?: any,
    doctor?: any,
    remark?: any,
    note?: any,
    drug_allergy?: any,
    other_allergy?: any,
    underlying_disease?: any,
    started?: any,
    ended?: any,
    triage?: any,
    triage_level?: any,
    chief_complaints?: any,
    predischarge_condition?: any,
    discharge_status?: any,
    discharge_note?: any,
    is_appointment?: any,
    queue_date?: any,
    queue_time?: any,
    hospital_fee_charged?: any,
    clinic_type?: any,
    cleaning?: any,
    examination_type?: any,
    patient_case?: any,
    hn?: any,
    patient_id?: any,
    full_name?: any,
    full_age?: any,
    birthdate?: any,
    status_label?: any,
    checkin_time?: any,
    discharge_time?: any,
    doctor_full_name?: any,
    patient_case_name?: any,
    coverage_name?: any,
    payer_name?: any,
    location?: any,
    allowed_action?: any,
}


/* ['generics.ListAPIView'] */
/* params: 'status', 'general_type', 'start_date', 'end_date', 'patient', 'encounter' */
/* data:  */
export const LRMQueueList : 
{
  list:     ({params, apiToken, extra}:
             { 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  list: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/LRM/queue/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

export interface LRMCheckOutSerializers {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    status?: any,
    episode?: any,
    number?: any,
    type?: any,
    patient?: any,
    division?: any,
    doctor?: any,
    remark?: any,
    note?: any,
    drug_allergy?: any,
    other_allergy?: any,
    underlying_disease?: any,
    started?: any,
    ended?: any,
    triage?: any,
    triage_level?: any,
    chief_complaints?: any,
    predischarge_condition?: any,
    discharge_status?: any,
    discharge_note?: any,
    is_appointment?: any,
    queue_date?: any,
    queue_time?: any,
    hospital_fee_charged?: any,
    clinic_type?: any,
    cleaning?: any,
    examination_type?: any,
    patient_case?: any,
    user?: any,
    action?: any,
    old_room?: any,
}


/* ['generics.UpdateAPIView'] */
/* params:  */
/* data:  */
export const LRMCheckOutDetail : 
{
  update:   ({pk, params, data, apiToken, extra}:
             { pk: any, 
                params?: any,
                data?: LRMCheckOutSerializers,
                apiToken?: any,
                extra?: any,
            }) => any,
  patch:    ({pk, params, data, apiToken, extra}:
             { pk: any, 
                params?: any,
                data?: LRMCheckOutSerializers,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  update: async ({pk, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.put(`${HOST}/apis/LRM/lr-check-out/${pk}/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  patch: async ({pk, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.patch(`${HOST}/apis/LRM/lr-check-out/${pk}/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

export interface ADMDischargePlanningSummarySerializer {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    status?: any,
    episode?: any,
    number?: any,
    type?: any,
    patient?: any,
    division?: any,
    doctor?: any,
    remark?: any,
    note?: any,
    drug_allergy?: any,
    other_allergy?: any,
    underlying_disease?: any,
    started?: any,
    ended?: any,
    triage?: any,
    triage_level?: any,
    chief_complaints?: any,
    predischarge_condition?: any,
    discharge_status?: any,
    discharge_note?: any,
    is_appointment?: any,
    queue_date?: any,
    queue_time?: any,
    hospital_fee_charged?: any,
    clinic_type?: any,
    cleaning?: any,
    examination_type?: any,
    patient_case?: any,
    ended_date?: any,
    ended_time?: any,
    discharged_from?: any,
    next_appointments?: any,
    death_date?: any,
    corpse_identify?: any,
}


/* ['generics.RetrieveAPIView'] */
/* params:  */
/* data:  */
export const ADMDischargePlanningSummaryDetail : 
{
  retrieve: ({pk, params, apiToken, extra}:
             { pk: any, 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  retrieve: async ({pk, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/ADM/encounter/(?P<pk>\\d+)/discharge-planning-summary/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

export interface BILBillingQueueSerializer {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    status?: any,
    episode?: any,
    number?: any,
    type?: any,
    patient?: any,
    division?: any,
    doctor?: any,
    remark?: any,
    note?: any,
    drug_allergy?: any,
    other_allergy?: any,
    underlying_disease?: any,
    started?: any,
    ended?: any,
    triage?: any,
    triage_level?: any,
    chief_complaints?: any,
    predischarge_condition?: any,
    discharge_status?: any,
    discharge_note?: any,
    is_appointment?: any,
    queue_date?: any,
    queue_time?: any,
    hospital_fee_charged?: any,
    clinic_type?: any,
    cleaning?: any,
    examination_type?: any,
    patient_case?: any,
    status_name?: any,
}


/* ['generics.ListAPIView'] */
/* params: 'billing_division' */
/* data:  */
export const BILBillingQueueList : 
{
  list:     ({params, apiToken, extra}:
             { 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  list: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/BIL/opd-queue/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

/* ['generics.RetrieveAPIView'] */
/* params:  */
/* data:  */
export const BILBillingQueueDetail : 
{
  retrieve: ({pk, params, apiToken, extra}:
             { pk: any, 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  retrieve: async ({pk, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/BIL/opd-queue/${pk}/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

export interface BILEncounterBillingSummarySerializer {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    status?: any,
    episode?: any,
    number?: any,
    type?: any,
    patient?: any,
    division?: any,
    doctor?: any,
    remark?: any,
    note?: any,
    drug_allergy?: any,
    other_allergy?: any,
    underlying_disease?: any,
    started?: any,
    ended?: any,
    triage?: any,
    triage_level?: any,
    chief_complaints?: any,
    predischarge_condition?: any,
    discharge_status?: any,
    discharge_note?: any,
    is_appointment?: any,
    queue_date?: any,
    queue_time?: any,
    hospital_fee_charged?: any,
    clinic_type?: any,
    cleaning?: any,
    examination_type?: any,
    patient_case?: any,
    total?: any,
    paid?: any,
    unpaid?: any,
    covered?: any,
    discount?: any,
    coverages?: any,
}


/* ['generics.ListAPIView'] */
/* params: 'patient' */
/* data:  */
export const BILEncounterBillingSummaryList : 
{
  list:     ({params, apiToken, extra}:
             { 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  list: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/BIL/encounter-summary/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

