import React, { CSSProperties, ReactChild } from "react";
// CSS
import { Icon, SemanticICONS } from "semantic-ui-react";
import { useIntl } from "react-intl";

type TFHeadProps = {
  title?: string | ReactChild;
  leftLabel?: string | ReactChild;
  hideLeftIcon?: boolean;
  rightIcon?: SemanticICONS;
  leftIcon?: SemanticICONS | ReactChild;
  // Callback
  leftIconClick?: () => any;
  rightIconClick?: () => any;
};

const COLOR = {
  primary: "var(--primary-theme-color)",
  font_white: "white",
};

const styles = {
  box_topbar: {
    backgroundColor: COLOR.primary,
    height: "40px",
    borderBottomLeftRadius: "15px",
    borderBottomRightRadius: "15px",
    display: "grid",
    gridTemplateColumns: "100px auto 100px",
    alignItems: "center",
    color: COLOR.font_white,
    fontSize: "20px",
    // paddingTop: "15px",
  } as CSSProperties,
  left_label: {
    marginLeft: "5px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "calc(100vw - 95px)",
    marginBottom: "3px",
  } as CSSProperties,
};

const TFHead: React.FunctionComponent<TFHeadProps> = (props) => {
  return (
    <div
      style={{ position: "sticky", top: 0, zIndex: 1000, width: "100vw" }}
      className="tf-header"
    >
      <div
        style={{
          ...styles.box_topbar,
          gridTemplateColumns: props.leftLabel
            ? "85% auto 15%"
            : "100px auto 100px",
        }}
      >
        {/* Left */}
        <div style={{ width: "100%" }}>
          {props.hideLeftIcon ? null : props.leftIcon ? (
            props.leftIcon
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <Icon
                name="angle left"
                style={{ fontSize: "23px" }}
                onClick={props.leftIconClick}
              />
              {props.leftLabel && (
                <div style={styles.left_label}>{props.leftLabel}</div>
              )}
            </div>
          )}
        </div>
        {/* Center */}
        <div style={{ textAlign: "center" }}>{props.title}</div>
        {/* Right */}
        <div>
          {props.rightIcon && (
            <Icon
              name={props.rightIcon}
              style={{ float: "right", marginRight: "17px" }}
              onClick={props.rightIconClick}
            />
          )}
        </div>
      </div>
    </div>
  );
};

TFHead.defaultProps = {
  title: "",
  hideLeftIcon: false,
  leftLabel: "",
  leftIconClick: () => {},
  rightIconClick: () => {},
} as TFHeadProps;

export default React.memo(TFHead);
