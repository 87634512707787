import WasmController from "react-lib/frameworks/WasmController";
import SupplyOrderDetail from "issara-sdk/apis/SupplyOrderDetail_apps_MSD";
import SupplyReturnRequestList from "issara-sdk/apis/SupplyReturnRequestList_apps_MSD";
import SupplyReturnRequestDetail from "issara-sdk/apis/SupplyReturnRequestDetail_apps_MSD";
import SupplyReturnRequestActionLogList from "issara-sdk/apis/SupplyReturnRequestActionLogList_apps_MSD";

export type State = {
  errorMessage?: any;
  buttonLoadCheck?: any;
  MedRequestReturnSequence?: any;
  ReturnSupplySequence?: {
    sequenceIndex?: string | null;
    OrderReturnList?: any;
    SupplyReturnList?:
    | {
      code?: string | null;
      drug_order_item?: number | null;
      id?: number | null;
      name?: string | null;
      no?: number | null;
      quantity_discard?: number | null;
      quantity_issue?: number | null;
      quantity_left?: number | null;
      quantity_request?: number | null;
      quantity_restock?: number | null;
      reason?: string | null;
      stock_unit_name?: string | null;
    }[]
    | null;
    OrderSupplyHistory?: any;
    OrderReturnLog?: any;
  } | null;
  loadingStatus?: any;
};

export const StateInitial: State = {
  ReturnSupplySequence: {
    OrderReturnList: [],
    SupplyReturnList: [],
    OrderSupplyHistory: [],
    OrderReturnLog: [],
  },
};

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const Start: Handler = async (controller, params) => {
  const state = controller.getState();

  if (!state.ReturnSupplySequence) return;

  const promiseArr = [
    SupplyOrderDetail.retrieve({
      pk: params.order,
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    }),
    SupplyReturnRequestList.list({
      params: { order: params.order },
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    })
  ]

  if (params.id) {
    promiseArr.push(
      SupplyReturnRequestDetail.retrieve({
        pk: params.id,
        apiToken: controller.apiToken,
        extra: {
          division: controller.data.division,
        },
      })
    )
  }

  const [supplyOrder, orderHistory, orderSupply] = await Promise.all(promiseArr)

  const supplyOrderReturn = (supplyOrder?.[0]?.items || [])?.map(
    (item: any) => ({
      ...item,
      code: item.code,
      id: item.id,
      name: item.name,
      no: item.no,
      quantity_discard: 0,
      quantity_issue: item.quantity_issue,
      quantity_left: item.left_quantity,
      quantity_restock: 0,
      quantity_request: 0,
      reason: "",
      stock_unit_name: item.stock_unit,
    })
  );

  controller.setState({
    ReturnSupplySequence: {
      sequenceIndex: "SearchAndEdit",
      OrderReturnList: params.id ? orderSupply?.[0] : supplyOrder?.[0],
      OrderSupplyHistory: orderHistory?.[0].items,
      SupplyReturnList: params.id
        ? orderSupply?.[0]?.items
        : supplyOrderReturn,
    },
  });
};

export const SearchAndEdit: Handler = async (controller, params) => {
  const state = controller.getState();

  if (!state.ReturnSupplySequence) return;
  if (!params?.action) return;
  if (params?.action === "EditColumn") {
    const data = params.data;
    let items = state.ReturnSupplySequence.SupplyReturnList || [];

    const ItemsEdit = (item: any) => {
      items = items.map((acc: any, index: number) =>
        index === data.index ? { ...acc, ...item } : acc
      );
    };
    if (params.data.type === "quantity_request") {
      ItemsEdit({ quantity_request: data.value });
    } else if (params.data.type === "quantity_restock") {
      ItemsEdit({ quantity_restock: data.value });
    } else if (params.data.type === "quantity_discard") {
      ItemsEdit({ quantity_discard: data.value });
    }

    controller.setState({
      ReturnSupplySequence: {
        ...state.ReturnSupplySequence,
        OrderReturnList: {
          ...state.ReturnSupplySequence.OrderReturnList,
          items: items,
        },
        SupplyReturnList: items,
      },
    });
  } else if (params.action === "REQUEST") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.btnAction}`]: "LOADING",
      },
    });

    let supply: any = state.ReturnSupplySequence.SupplyReturnList;

    let defaultData = (supply || [])?.map((item: any) => ({
      code: item.code,
      supply_order_item: item.id,
      name: item.name,
      no: item.no,
      quantity_discard: 0,
      quantity_issue: item.quantity_issue,
      quantity_left: item.quantity_left,
      quantity_request: item.quantity_request || 0,
      quantity_restock: 0,
      storekey: 0,
      stock_unit_name: item.stock_unit_name,
    }));

    const supplyReturnRequest = await SupplyReturnRequestList.create({
      data: {
        action: "REQUEST",
        items: defaultData,
      },
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });

    if (supplyReturnRequest?.[1]) {
      return controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.btnAction}`]: "ERROR",
        },
        errorMessage: {
          ...state.errorMessage,
          [params.card]: { error: supplyReturnRequest?.[1] },
        },
      });
    }

    const orderHistory = await SupplyReturnRequestList.list({
      params: { order: supplyReturnRequest?.[0].order },
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });

    const order = await SupplyReturnRequestDetail.retrieve({
      pk: supplyReturnRequest?.[0]?.id,
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });

    controller.setState({
      ReturnSupplySequence: {
        ...state.ReturnSupplySequence,
        OrderSupplyHistory: orderHistory?.[0].items,
        OrderReturnList: order?.[0],
        SupplyReturnList: order?.[0]?.items,
      },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.btnAction}`]: "SUCCESS",
      },
      errorMessage: { ...state.errorMessage, [params.card]: { error: null } },
    });
  } else if (params?.action === "HistoryRequest") {
    const order = await SupplyReturnRequestDetail.retrieve({
      pk: params?.orders?.id,
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });

    controller.setState({
      ReturnSupplySequence: {
        ...state.ReturnSupplySequence,
        OrderReturnList: order?.[0],
        SupplyReturnList: order?.[0]?.items,
      },
    });
  } else if (params?.action === "New") {
    const supplyOrder = await SupplyOrderDetail.retrieve({
      pk: params.id,
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });
    const supplyOrderReturn = (supplyOrder?.[0]?.items || [])?.map(
      (item: any) => ({
        ...item,
        code: item.code,
        id: item.id,
        name: item.name,
        no: item.no,
        quantity_discard: 0,
        quantity_left: item.left_quantity,
        quantity_restock: 0,
        quantity_request: 0,
        reason: "",
        stock_unit_name: item.stock_unit_name,
        new: true,
      })
    );

    controller.setState({
      ReturnSupplySequence: {
        ...state.ReturnSupplySequence,
        OrderReturnList: supplyOrder?.[0],
        SupplyReturnList: supplyOrderReturn,
      },
      errorMessage: { ...state.errorMessage, [params.card]: { error: null } },
    });
  } else if (params?.action === "Log") {
    const orderLog = await SupplyReturnRequestActionLogList.list({
      pk: params.id,
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });

    controller.setState({
      ReturnSupplySequence: {
        ...state.ReturnSupplySequence,
        OrderReturnLog: orderLog?.[0],
      },
    });
  } else if (
    [
      "REJECT",
      "EDIT",
      "CANCEL",
      "APPROVE",
      "DELIVER",
      "RECEIVE",
      "UNAPPROVE",
      "UNDELIVER",
      "UNRECEIVE",
    ].includes(params.action || "")
  ) {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.btnAction}`]: "LOADING",
      },
    });

    const orderUpdate = await SupplyReturnRequestDetail.update({
      pk: params.id,
      data: {
        action: params?.action,
        items: state.ReturnSupplySequence?.SupplyReturnList,
        pk: params.id,
      },
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });

    if (orderUpdate?.[1]) {
      return controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.btnAction}`]: "ERROR",
        },
        errorMessage: {
          ...state.errorMessage,
          [params.card]: { error: orderUpdate?.[1] },
        },
      });
    }

    const orderHistory = await SupplyReturnRequestList.list({
      params: { order: orderUpdate?.[0].order },
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });

    controller.setState(
      {
        ReturnSupplySequence: {
          ...state.ReturnSupplySequence,
          OrderReturnList: orderUpdate?.[0],
          SupplyReturnList: orderUpdate?.[0]?.items,
          OrderSupplyHistory: orderHistory?.[0].items,
        },
        // loadingStatus: { ...state.loadingStatus, [params.card]: false },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.btnAction}`]: "SUCCESS",
        },
        errorMessage: { ...state.errorMessage, [params.card]: { error: null } },
      },
      () => !!state.MedRequestReturnSequence?.MedRequestReturnList &&
        controller.handleEvent({
          message: "RunSequence",
          params: {
            sequence: "MedRequestReturn",
            action: "search",
          },
        })
    );
  }
};
