import WasmController from 'react-lib/frameworks/WasmController';
import ProgressionCycleList from "issara-sdk/apis/ProgressionCycleList_core";

export type State = 
  {
    selectedEmr?: any,
    selectedProgressCycle?: any,
    SetProgressCycleSequence?: {
      sequenceIndex: string | null,
      progressCycleList?: any[],
    } | null,
  }

export const StateInitial: State = 
  {
    SetProgressCycleSequence: null,
  }

export type Event = 
  { message: "RunSequence", params: {} }

export type Data = 
  {
    division?: number,
  }

export const DataInitial = 
  {
  }

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any

export const RefreshProgressCycle: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.SetProgressCycleSequence || !state.selectedEmr) return
  const progressCycle = await ProgressionCycleList.list({
    params: { emr: state.selectedEmr.id },
    apiToken: controller.apiToken
  });
  console.log(progressCycle[1] ? progressCycle[1] : progressCycle[0]);
  controller.setState({
    selectedProgressCycle: progressCycle[0]?.items?.[0] || null,
    SetProgressCycleSequence: {
      ...state.SetProgressCycleSequence,
      sequenceIndex: "AddOrEdit",
      progressCycleList: progressCycle[0]?.items || [],
    }
  });
}

export const AddOrEdit: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.SetProgressCycleSequence || !state.selectedEmr) return
  if (params.action === "add") {
    const progressCycle = await ProgressionCycleList.create({
      data: {
        emr: state.selectedEmr.id,
        checkout: false,
        progression_note: ""
      } as any,
      apiToken: controller.apiToken
    });
    console.log(progressCycle[1] ? progressCycle[1] : progressCycle[0]);
    RefreshProgressCycle(controller, {});
  } else if (params.action === "select") {
    controller.setState({
      selectedProgressCycle: params?.selectedItem,
    });
  }
}
