import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button,
  Input
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardDrugOrderRefillUX = (props: any) => {
    return(
      <div>
        <div
          style={{display: "flex" , height:"70px", border: "solid 1px rgb(200, 200, 200, 0.5)","backgroundColor":"#2185d0", justifyContent: "space-between", alingItem: "center", color: "white"}}>
          
          <div
            style={{textAlign:"center",margin:"20px","fontWeight":"bold","fontSize":"17px"}}>
            แผนกการเติมยา REFILL
          </div>
        </div>
        <div
          style={{ margin: "20px" }}>
          
          <div
            style={{ display: "flex", }}>
            
            <div
              style={{ marginRight: "20px", }}>
              {props.nameTypeOrder}
            </div>
            <div>
              {props.orderStatus}
            </div>
          </div>
          <div
            style={{ display: "flex", margin: "20px 0px"}}>
            
            <div
              style={{ display: "flex", }}>
              
              <div
                style={{ display: "flex", alignItems: "center",  marginRight: "10px" }}>
                แบ่งจ่ายยา
              </div>
              <Input
                name="count"
                onChange={props.handleChangeSplit("splitCount")}
                style={{ marginRight: "10px" }}
                type="number"
                value={props.split?.splitCount || ""}>
              </Input>
              <div
                style={{ display: "flex", alignItems: "center",  marginRight: "10px" }}>
                ครั้ง
              </div>
            </div>
            <div
              style={{ display: "flex" }}>
              
              <div
                style={{ display: "flex", alignItems: "center",  marginRight: "10px" }}>
                ทุกๆ
              </div>
              <Input
                name="day"
                onChange={props.handleChangeSplit("splitDay")}
                style={{ marginRight: "10px" }}
                type="number"
                value={props.split?.splitDay || ""}>
              </Input>
              <div
                style={{ display: "flex", alignItems: "center",  marginRight: "10px" }}>
                วัน
              </div>
            </div>
            <div
              style={{ display: "flex" }}>
              
              <div
                style={{ display: "flex", alignItems: "center",  marginRight: "10px" }}>
                เริ่ม 
              </div>
              <DateTextBox
                onChange={props.onChangeSplitDate}
                style={{ marginRight: "10px" }}
                value={props.split?.splitDate || ""}>
              </DateTextBox>
            </div>
            <Button
              onClick={props.calculatorSplit}>
              คำนวณ
            </Button>
          </div>
          <div
            style={{ display: "flex", width: "100%" }}>
            
            <div>
              
              <div
                style={{ display: "flex" }}>
                
                <Table
                  data={props.drugOrderRefill}
                  headers="รหัสยา"
                  id="tb-medOrderReqList"
                  keys="code"
                  minRows="1"
                  showPagination={false}
                  style={{ marginRight: "20px"}}
                  widths="120">
                </Table>
                <Table
                  data={props.drugOrderRefill}
                  headers="ชื่อยา"
                  id="tb-medOrderReqList"
                  keys="name"
                  minRows="1"
                  showPagination={false}
                  style={{ marginRight: "20px"}}
                  widths="300">
                </Table>
                <Table
                  data={props.drugOrderRefill}
                  headers="จำนวนที่สั่ง"
                  id="tb-medOrderReqList"
                  keys="quantity"
                  minRows="1"
                  showPagination={false}
                  style={{ marginRight: "20px"}}
                  widths="120">
                </Table>
                <Table
                  data={props.drugOrderRefill}
                  headers="จำนวนที่เติม"
                  id="tb-medOrderReqList"
                  keys="planned_quantity"
                  minRows="1"
                  showPagination={false}
                  style={{ marginRight: "20px"}}
                  widths="120">
                </Table>
                <div>
                  
                  <Button
                    color="orange"
                    icon="plus"
                    onClick={props.addOrderList}
                    style={{ marginRight: "20px"}}>
                  </Button>
                </div>
              </div>
            </div>
            <div
              style={{ overflowX: "scroll", minHeight: "270px" }}>
              
              <div>
                {props.inputRefill}
              </div>
            </div>
          </div>
          <div
            style={{ display: "flex", justifyContent: "space-between"}}>
            
            <div
              style={{ display: "flex" }}>
              
              <Button
                onClick={props.logRefillPlanning}
                size="tiny">
                LOG
              </Button>
              <div
                style={{  display: props.drugStatus === "REFILL_PLANNED"  ?  "" : "none",  }}>
                {props.printRefillPlanning}
              </div>
            </div>
            <div
              style={{ display: "flex" }}>
              
              <div>
                {props.saveRefillPlanning}
              </div>
              <div
                style={{  display: props.openCancelRefill ?  "" : "none",  }}>
                {props.cancelRefillPlanning}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default CardDrugOrderRefillUX

export const screenPropsDefault = {"backwardActionButton":"REJECT","drugStatus":"flex","forwardActionButton":"VERIFY AND PRINT"}

/* Date Time : Thu Feb 02 2023 11:14:49 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\" , height:\"70px\", border: \"solid 1px rgb(200, 200, 200, 0.5)\",\"backgroundColor\":\"#2185d0\", justifyContent: \"space-between\", alingItem: \"center\", color: \"white\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"20px\" }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนกการเติมยา REFILL"
        },
        "style": {
          "type": "code",
          "value": "{textAlign:\"center\",margin:\"20px\",\"fontWeight\":\"bold\",\"fontSize\":\"17px\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", }"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "code",
          "value": "props.nameTypeOrder"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\", }"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "code",
          "value": "props.orderStatus"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", margin: \"20px 0px\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", }"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "Button",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "คำนวณ"
        },
        "onClick": {
          "type": "code",
          "value": "props.calculatorSplit"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 13,
      "name": "DateTextBox",
      "parent": 11,
      "props": {
        "keepFocus": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeSplitDate"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        },
        "value": {
          "type": "code",
          "value": "props.split?.splitDate || \"\""
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Input",
      "parent": 10,
      "props": {
        "name": {
          "type": "value",
          "value": "day"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeSplit(\"splitDay\")"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.split?.splitDay || \"\""
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "Input",
      "parent": 9,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "count"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeSplit(\"splitCount\")"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.split?.splitCount || \"\""
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "แบ่งจ่ายยา"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\",  marginRight: \"10px\" }"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "ครั้ง"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\",  marginRight: \"10px\" }"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "ทุกๆ"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\",  marginRight: \"10px\" }"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "วัน"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\",  marginRight: \"10px\" }"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": "เริ่ม "
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\",  marginRight: \"10px\" }"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", width: \"100%\" }"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "div",
      "parent": 34,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 34,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ overflowX: \"scroll\", minHeight: \"270px\" }"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 35,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 39,
      "name": "Table",
      "parent": 37,
      "props": {
        "data": {
          "type": "code",
          "value": "props.drugOrderRefill"
        },
        "headers": {
          "type": "value",
          "value": "รหัสยา"
        },
        "id": {
          "type": "value",
          "value": "tb-medOrderReqList"
        },
        "keys": {
          "type": "value",
          "value": "code"
        },
        "minRows": {
          "type": "value",
          "value": "1"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\"}"
        },
        "widths": {
          "type": "value",
          "value": "120"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 40,
      "name": "Table",
      "parent": 37,
      "props": {
        "data": {
          "type": "code",
          "value": "props.drugOrderRefill"
        },
        "headers": {
          "type": "value",
          "value": "ชื่อยา"
        },
        "id": {
          "type": "value",
          "value": "tb-medOrderReqList"
        },
        "keys": {
          "type": "value",
          "value": "name"
        },
        "minRows": {
          "type": "value",
          "value": "1"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\"}"
        },
        "widths": {
          "type": "value",
          "value": "300"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 41,
      "name": "Table",
      "parent": 37,
      "props": {
        "data": {
          "type": "code",
          "value": "props.drugOrderRefill"
        },
        "defaultPageSize": {
          "type": "code",
          "value": ""
        },
        "headers": {
          "type": "value",
          "value": "จำนวนที่สั่ง"
        },
        "id": {
          "type": "value",
          "value": "tb-medOrderReqList"
        },
        "keys": {
          "type": "value",
          "value": "quantity"
        },
        "minRows": {
          "type": "value",
          "value": "1"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\"}"
        },
        "widths": {
          "type": "value",
          "value": "120"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 42,
      "name": "Table",
      "parent": 37,
      "props": {
        "data": {
          "type": "code",
          "value": "props.drugOrderRefill"
        },
        "headers": {
          "type": "value",
          "value": "จำนวนที่เติม"
        },
        "id": {
          "type": "value",
          "value": "tb-medOrderReqList"
        },
        "keys": {
          "type": "value",
          "value": "planned_quantity"
        },
        "minRows": {
          "type": "value",
          "value": "1"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\"}"
        },
        "widths": {
          "type": "value",
          "value": "120"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 37,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "Button",
      "parent": 43,
      "props": {
        "color": {
          "type": "value",
          "value": "orange"
        },
        "icon": {
          "type": "value",
          "value": "plus"
        },
        "onClick": {
          "type": "code",
          "value": "props.addOrderList"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\"}"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 36,
      "props": {
        "children": {
          "type": "code",
          "value": "props.inputRefill"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "Button",
      "parent": 49,
      "props": {
        "children": {
          "type": "value",
          "value": "SAVE"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.saveRefillPlanning"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 50,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "div",
      "parent": 50,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "Button",
      "parent": 51,
      "props": {
        "children": {
          "type": "value",
          "value": "LOG"
        },
        "onClick": {
          "type": "code",
          "value": "props.logRefillPlanning"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 51,
      "props": {
        "children": {
          "type": "code",
          "value": "props.printRefillPlanning"
        },
        "style": {
          "type": "code",
          "value": "{  display: props.drugStatus === \"REFILL_PLANNED\"  ?  \"\" : \"none\",  }"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "div",
      "parent": 52,
      "props": {
        "children": {
          "type": "code",
          "value": "props.saveRefillPlanning"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "div",
      "parent": 52,
      "props": {
        "children": {
          "type": "code",
          "value": "props.cancelRefillPlanning"
        },
        "style": {
          "type": "code",
          "value": "{  display: props.openCancelRefill ?  \"\" : \"none\",  }"
        }
      },
      "seq": 60,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 100,
  "isMounted": false,
  "memo": false,
  "name": "CardDrugOrderRefillUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "backwardActionButton": "REJECT",
    "drugStatus": "flex",
    "forwardActionButton": "VERIFY AND PRINT"
  },
  "width": 90
}

*********************************************************************************** */
