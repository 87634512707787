import React from "react";
import { IntlProvider, FormattedMessage, useIntl } from "react-intl";

import { Form, FormGroup, FormField } from "semantic-ui-react";

const CardPhysicalExamUX = (props: any) => {
  return (
    <div>
      <div style={{ fontWeight: "bold", fontSize: "15px" }}>
        ข้อมูลการตรวจร่างกาย
      </div>
      <div style={{ padding: "20px" }}>
        <div>{props.organList}</div>
      </div>
      <div>
        <Form>
          <FormGroup>
            <FormField width={2}>
              <label>Template</label>
            </FormField>
            <FormField width={14}>
              <div>{props.templateDropdown}</div>
            </FormField>
          </FormGroup>
        </Form>
      </div>
      <div>
        <div>{props.templateItem}</div>
      </div>
      <div>
        <Form>
          <div>{props.otherDetailText}</div>
        </Form>
      </div>
    </div>
  );
};

export default CardPhysicalExamUX;

export const screenPropsDefault = {};

/* Date Time : Wed Dec 27 2023 15:44:39 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "ข้อมูลการตรวจร่างกาย"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"15px\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"20px\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "code",
          "value": "props.organList"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "label",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "Template"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "Form",
      "parent": 3,
      "props": {
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormGroup",
      "parent": 7,
      "props": {
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "FormField",
      "parent": 8,
      "props": {
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "FormField",
      "parent": 8,
      "props": {
        "width": {
          "type": "code",
          "value": "14"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "code",
          "value": "props.templateItem"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Form",
      "parent": 13,
      "props": {
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "code",
          "value": "props.templateDropdown"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "code",
          "value": "props.otherDetailText"
        }
      },
      "seq": 17,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardPhysicalExamUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
