import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";

import { DropdownProps, Modal } from "semantic-ui-react";

import ModCheckADRUX from "./ModCheckADRUX";
import { useIntl } from "react-intl";

type ModCheckADRProps = {
  onEvent: (event: { message: string; params: any }) => void;
  setProp: (path: string, value: any) => void;
  // seq
  DrugSelectSequence: Record<string, any> | null;
  card?: string;
  errorMessage?: Record<string, any>;
  masterOptions?: Record<string, any>;
};

const ModCheckADR = (props: ModCheckADRProps) => {
  const intl = useIntl();
  const [drugAllergyReasonOptions, setDrugAllergyReasonOptions] = useState<any[]>([]);

  // Effect
  useEffect(() => {
    setDrugAllergyReasonOptions(props.masterOptions?.drugAllergyReason || []);
  }, [props.masterOptions?.drugAllergyReason]);

  // Memo callback
  const adrNotesInfo = useMemo((): { key: string; value: string } => {
    if (props.DrugSelectSequence?.solventAdrNotes) {
      return {
        key: "solvent_note_adverse_reaction",
        value: props.DrugSelectSequence.solventAdrNotes,
      };
    } else if (props.DrugSelectSequence?.adrNotes) {
      return { key: "note_adverse_reaction", value: props.DrugSelectSequence.adrNotes };
    }

    return { key: "note_adverse_reaction", value: "" };
  }, [props.DrugSelectSequence]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, data: DropdownProps) => {
      props.setProp(`DrugSelectSequence.${adrNotesInfo.key}`, data.value);
    },
    [adrNotesInfo.key]
  );

  const handleConfirmOrder = useCallback(() => {
    props.onEvent({
      message: "RunSequence",
      params: {
        sequence: "DrugSelect",
        card: props.card,
        feedback: "confirm",
      },
    });
  }, [props.card]);

  const handleCancelOrder = useCallback(() => {
    props.onEvent({
      message: "RunSequence",
      params: {
        sequence: "DrugSelect",
        card: props.card,
        feedback: "cancel",
      },
    });
  }, [props.card]);

  const handleAcknowLedge = useCallback(() => {
    props.onEvent({
      message: "RunSequence",
      params: { sequence: "DrugSelect", feedback: "acknowledge" },
    });
  }, []);

  const handleAdditionOptions = useCallback(
    (e: SyntheticEvent, v: any) => {
      setDrugAllergyReasonOptions([
        ...drugAllergyReasonOptions,
        { key: v.value, text: v.value, value: v.value },
      ]);
    },
    [drugAllergyReasonOptions]
  );

  const adrDetail = useMemo(
    () =>
      adrNotesInfo.value.split("\n").map((note, noteIndex) => (
        <div key={noteIndex} style={{ padding: "5px" }}>
          {note}
        </div>
      )),
    [adrNotesInfo.value]
  );

  return (
    <Modal open={!!props.DrugSelectSequence?.adrType} size="tiny">
      <ModCheckADRUX
        acknowledge={handleAcknowLedge}
        adrDetail={adrDetail}
        adrType={props.DrugSelectSequence?.adrType}
        cancelOrder={handleCancelOrder}
        changeNote={handleChange}
        confirmADRNote={props.DrugSelectSequence?.[adrNotesInfo.key] || ""}
        confirmOrder={handleConfirmOrder}
        needNote={["NOTED", "PAUSED"].includes(props.DrugSelectSequence?.adrType || "")}
        noteError={props.errorMessage?.[props.card || ""]?.error}
        drugAllergyReasonOptions={drugAllergyReasonOptions}
        onAddItem={handleAdditionOptions}
        languageUX={props.languageUX}
      />
    </Modal>
  );
};

ModCheckADR.displayName = "ModCheckADR";

export default React.memo(ModCheckADR);
