import WasmController from "../../../../frameworks/WasmController";
import { State as MainState } from "../../../../../HIS/MainHISInterface";
import { formatDate } from "react-lib/utils";
import moment from "moment";
// APIs
import DoctorConsultAppointmentList from "issara-sdk/apis/DoctorConsultAppointmentList_apps_DPO";

export type State = {
  ConsultationListSequence?: {
    sequenceIndex?: string | null;

    appointmentStatusOptions?: any[];
    consultStatusOptions?: any[];

    filterRequestList?: {
      start_app_date?: string;
      end_app_date?: string;
      division?: any;
      patient?: any;
      order_doctor?: any;
      consulted_doctor?: any;
      noDoctor?: boolean;
      status?: number | null;
      app_status?: number | null;
      encounter_type?: string;
      current_encounter?: boolean;
    };

    filterAcceptList?: {
      patient?: number | null;
      start_app_date?: string;
      end_app_date?: string;
      order_div?: any;
      order_doctor?: any;
      status?: number | null;
      app_status?: number | null;
      encounter_type?: string;
    };

    consultRequestList?: null;
    consultAcceptList?: null;
  } | null;
};

export const StateInitial: State = {
  ConsultationListSequence: {
    sequenceIndex: null,

    appointmentStatusOptions: [],
    consultStatusOptions: [],

    filterRequestList: {
      start_app_date: "",
      end_app_date: "",
      patient: null,
      division: null,
      order_doctor: null,
      consulted_doctor: null,
      noDoctor: false,
      status: null,
      app_status: null,
      encounter_type: "",
      current_encounter: false,
    },

    filterAcceptList: {
      patient: null,
      start_app_date: "",
      end_app_date: "",
      order_div: null,
      order_doctor: null,
      status: null,
      app_status: null,
      encounter_type: "",
    },

    consultRequestList: null,
    consultAcceptList: null,
  },
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  division?: number;
};

type Picked = Partial<
  Pick<
    MainState,
    | "buttonLoadCheck"
    | "errorMessage"
    | "successMessage"
    | "masterOptions"
    | "django"
  >
>;

export const DataInitial = {};

export const APPOINTMENT_STATUS = {
  WAIT_CONFIRMED: "2",
  CONFIRMED: "1",
  CANCELED: "3",
  FINISHED: "5",
};

export const APPOINTMENT_STATUS_NAME = {
  WAIT_CONFIRMED: "รอยืนยัน",
  CONFIRMED: "ยืนยันนัดหมาย",
  CANCELED: "ยกเลิก",
  FINISHED: "นัดหมายเสร็จสิ้น",
};

export const CONSULT_STATUS = {
  ORDERED: "1",
  REPORTED: "4",
  CANCELED: "9",
  FINISHED: "10"
};

export const CONSULT_STATUS_NAME = {
  ORDERED: "รอ Consult",
  // REPORTED: "4",
  CANCELED: "ยกเลิก",
  FINISHED: "Consult เสร็จสิ้น",
};

type Handler<P = any, R = any> = (
  controller: WasmController<State & Picked, Event, Data>,
  params: P
) => R;

export const Start: Handler = async (controller, params) => {
  controller.setState(
    {
      ConsultationListSequence: {
        sequenceIndex: "Action",

        appointmentStatusOptions: [
          {
            key: APPOINTMENT_STATUS.WAIT_CONFIRMED,
            value: APPOINTMENT_STATUS.WAIT_CONFIRMED,
            text: "รอยืนยัน",
          },
          {
            key: APPOINTMENT_STATUS.CONFIRMED,
            value: APPOINTMENT_STATUS.CONFIRMED,
            text: "ยืนยันนัดหมาย",
          },
          {
            key: APPOINTMENT_STATUS.CANCELED,
            value: APPOINTMENT_STATUS.CANCELED,
            text: "ยกเลิก",
          },
        ],
        consultStatusOptions: [
          { key: "WAIT", value: "WAIT", text: "รอ consult" },
          { key: "CONSULTING", value: "CONSULTING", text: "กำลัง consult" },
          { key: "FINISH", value: "FINISH", text: "consult เสร็จสิ้น" },
        ],
      },
    },
    () => {
      controller.handleEvent({ message: "RunSequence", params: { ...params } });
    }
  );
};

export const Action: Handler = async (controller, params) => {
  const state = controller.getState();

  if (params?.action === "SEARCH_REQUEST") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    let filterParams: any = {
      order_user: params.order_user,
      ...state.ConsultationListSequence?.filterRequestList, // start_app_date, end_app_date, status, app_status, encounter_type
      order_doctor:
        state.ConsultationListSequence?.filterRequestList?.order_doctor?.id,
      consulted_doctor: state.ConsultationListSequence?.filterRequestList
        ?.noDoctor
        ? "no_doctor"
        : state.ConsultationListSequence?.filterRequestList?.consulted_doctor
            ?.id,
      division: state.ConsultationListSequence?.filterRequestList?.division?.id,
      patient: state.ConsultationListSequence?.filterRequestList?.patient
    };
    if (params.patientId) {
      filterParams.patient = params.patientId;
    }
    if (state.ConsultationListSequence?.filterRequestList?.current_encounter) {
      filterParams.encounter = params.encounterId;
    }

    const [resp, err, network] = await DoctorConsultAppointmentList.list({
      apiToken: controller.apiToken,
      params: filterParams,
    });

    if (err) {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "ERROR",
        },
        errorMessage: {
          ...state.errorMessage,
          [params?.btnKey]: err,
        },
        ConsultationListSequence: {
          ...state.ConsultationListSequence,
          consultRequestList: null,
        },
      });
    } else {
      let result = resp?.items.map((item: any) => {
        return {
          ...item,
          created_lbl: formatDate(moment(item.created, "YYYY-MM-DD")),
          app_date_lbl: formatDate(moment(item.app_date, "DD/MM/YYYY")),
          patient_lbl: `${item.patient_fullname || ""} [${item.hn}]`,
        };
      });
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "SUCCESS",
        },
        ConsultationListSequence: {
          ...state.ConsultationListSequence,
          consultRequestList: result,
        },
      });
    }
  } else if (params?.action === "SEARCH_ACCEPT") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    const [resp, err, network] = await DoctorConsultAppointmentList.list({
      apiToken: controller.apiToken,
      params: {
        consult_user: state.django?.user?.id,
        ...state.ConsultationListSequence?.filterAcceptList, // patient, start_app_date, end_app_date, status, app_status, encounter_type
        order_doctor:
          state.ConsultationListSequence?.filterAcceptList?.order_doctor?.id,
        order_div:
          state.ConsultationListSequence?.filterAcceptList?.order_div?.id,
      },
    });

    if (err) {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "ERROR",
        },
        errorMessage: {
          ...state.errorMessage,
          [params?.btnKey]: err,
        },
        ConsultationListSequence: {
          ...state.ConsultationListSequence,
          consultAcceptList: null,
        },
      });
    } else {
      let result = resp?.items.map((item: any) => {
        return {
          ...item,
          created_lbl: formatDate(moment(item.created, "YYYY-MM-DD")),
          app_date_lbl: formatDate(moment(item.app_date, "DD/MM/YYYY")),
          patient_lbl: `${item.patient_fullname || ""} [${item.hn}]`,
        };
      });
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "SUCCESS",
        },
        ConsultationListSequence: {
          ...state.ConsultationListSequence,
          consultAcceptList: result,
        },
      });
    }
  }
};
