const formatComma = (value: number | string, locales: string = "en-US", withFraction: boolean | null | undefined = true) => {

  let num = value;
  if (typeof value === "string") {
    let vNum = Number(value);
    if (isNaN(vNum)) {
      return value;
    } else {
      num = vNum;
    }
  }

  if (isNaN(num as number)) {
    return "";
  }

  if (withFraction) {
    return num.toLocaleString(locales, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  } else {
    return num.toLocaleString(locales);
  }

  // → "30,000.65" where English is the default language, or
  // → "30.000,65" where German is the default language, or
  // → "30 000,65" where French is the default language
};

export default formatComma;
