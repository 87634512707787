import React from "react";
import { IntlProvider, FormattedMessage, useIntl } from "react-intl";

const CardConsultCancelDetailUX = (props: any) => {
  return (
    <div>
      <div style={{ display: "flex", paddingBottom: "20px" }}>
        <div style={{ fontWeight: "bold", paddingRight: "5px" }}>
          ยกเลิกนัดหมาย Consult โดย
        </div>
        <div>{props.cancel_note || ""}</div>
      </div>
      <div style={{ display: "flex", paddingBottom: "20px" }}>
        <div style={{ fontWeight: "bold", paddingRight: "5px" }}>
          ยกเลิก โดย
        </div>
        <div>{props.cancel_user || ""}</div>
      </div>
    </div>
  );
};

export default CardConsultCancelDetailUX;

export const screenPropsDefault = {};

/* Date Time : Thu Nov 09 2023 15:27:18 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"20px\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"20px\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิกนัดหมาย Consult โดย  "
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", paddingRight: \"5px\" }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิก โดย  "
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", paddingRight: \"5px\" }"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.cancel_note || \"\""
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "code",
          "value": "props.cancel_user || \"\""
        }
      },
      "seq": 6,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardConsultCancelDetailUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
