import BaseService from "./BaseService";
import { LRM } from "../../configs/apis";
class LRMService extends BaseService {
  getSearchQueue({ params }) {
    return this.client
      .get(LRM.QUEUE, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getANCRecordList(params) {
    return this.client
      .get(LRM.ANC_RECORD_LIST, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getPreviousPregnancy(params) {
    return this.client
      .get(LRM.PREVIOUS_PREGNANCY, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getANCSummary({ ancSummaryId, params } = {}) {
    const url = LRM.ANC_SUMMARY({ anc_summary_id: ancSummaryId });
    return this.client
      .get(url, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getANCItem({ params } = {}) {
    return this.client
      .get(LRM.ANC_ITEM, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getANCLab({ ancLabId, params } = {}) {
    const url = LRM.ANC_LAB({ anc_lab_id: ancLabId });
    return this.client
      .get(url, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getFetalLie({ params } = {}) {
    return this.client
      .get(LRM.FETAL_LIE, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  postLabInvestigation({ params, data } = {}) {
    return this.client
      .post(LRM.LAB_INVESTIGATION_FETCH, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getLRLab({ params } = {}) {
    return this.client
      .get(LRM.LR_LAB, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  postANCRecord({ params, data } = {}) {
    return this.client
      .post(LRM.ANC_RECORD, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  putLabInvestigation({ labInvestigationId, params, data } = {}) {
    const url = LRM.LAB_INVESTIGATION_DETAIL({
      investidation_id: labInvestigationId
    });
    return this.client
      .put(url, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getBirthCertificateApplication({ params }) {
    return this.client
      .get(LRM.BIRTH_CERTIFICATE_APPLICATION, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  postBirthCertificateApplication({ data, params } = {}) {
    return this.client
      .post(LRM.BIRTH_CERTIFICATE_APPLICATION, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  putBirthCertificateApplication({ data, params, birthCerAppId } = {}) {
    const url = LRM.BIRTH_CERTIFICATE_APPLICATION_DETAIL({
      birth_certification_application_id: birthCerAppId
    });
    return this.client
      .put(url, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  putPrintBirthCertificateApplication({ params, data, birthCerAppId } = {}) {
    const url = LRM.BIRTH_CERTIFICATE_APPLICATION_PRINT({
      birth_certification_application_id: birthCerAppId
    });
    return this.client
      .put(url, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getObstetricBasicInfo({ params } = {}) {
    return this.client
      .get(LRM.OBSTETRIC_BASIC_INFO, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  // TODO: Use this.handleResponse
  postObstetricBasicInfo({ data, params } = {}) {
    return this.client
      .post(LRM.OBSTETRIC_BASIC_INFO, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  putObstetricBasicInfo({ data, params, basicInfoId } = {}) {
    let url = LRM.OBSTETRIC_BASIC_INFO_DETAIL({ basic_info_id: basicInfoId });
    return this.client
      .put(url, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  putPrintObstetric({ basicInfoId, data, params } = {}) {
    return this.client
      .put(LRM.OBSTETRIC_PRINT, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getObstetricLabor({ labourId, params } = {}) {
    // let url = LRM.OBSTETRIC_LABOR_DETAIL({ labor_id: labourId })
    return this.client
      .get(LRM.OBSTETRIC_LABOR, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  postObstetricLabor({ data, params } = {}) {
    return this.client
      .post(LRM.OBSTETRIC_LABOR, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  putObstetricLabor({ labourId, data, params } = {}) {
    let url = LRM.OBSTETRIC_LABOR_DETAIL({ labor_id: labourId });
    return this.client
      .put(url, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getObstetricInfant({ infantId, params } = {}) {
    // let url = LRM.OBSTETRIC_INFANT_DETAIL({ infant_id: infantId })
    return this.client
      .get(LRM.OBSTETRIC_INFANT, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  postObstetricInfant({ data, params } = {}) {
    return this.client
      .post(LRM.OBSTETRIC_INFANT, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  putObstetricInfant({ infantId, data, params } = {}) {
    let url = LRM.OBSTETRIC_INFANT_DETAIL({ infant_id: infantId });
    return this.client
      .put(url, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getObstetricPostLabor({ postLabourId, params } = {}) {
    // let url = LRM.OBSTETRIC_POST_LABOR_DETAIL({ post_labor_id: postLabourId })
    return this.client
      .get(LRM.OBSTETRIC_POST_LABOUR, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  postObstetricPostLabor({ data, params } = {}) {
    return this.client
      .post(LRM.OBSTETRIC_POST_LABOUR, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  putObstetricPostLabor({ postLabourId, data, params } = {}) {
    let url = LRM.OBSTETRIC_POST_LABOUR_DETAIL({ post_labor_id: postLabourId });
    return this.client
      .put(url, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getEDC({ data, params } = {}) {
    return this.client
      .post(LRM.EDC, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  postNewborn({ params, data } = {}) {
    return this.client
      .post(LRM.NEWBORN, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getCheckOutChoice({ params } = {}) {
    return this.client
      .get(LRM.CHECK_OUT_CHOICE, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  putLRCheckOut({ params, encounterId, data }) {
    let url = LRM.LR_CHECK_OUT({ encounter_id: encounterId });
    return this.client
      .put(url, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  printANCRecordByEncounter({ params, encounterId, data }) {
    let url = LRM.PRINT_ANC_RECORD_BY_ENCOUNTER({ encounter_id: encounterId });
    return this.client
      .put(url, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
}
export default LRMService;
