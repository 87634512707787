import * as TriageI from "./Triage";
import * as AssessmentI from "./Assessment"
import * as ReceiveOrderI from "./ReceiveOrder"
import * as NursingDiagnosisI from "./NursingDiagnosis"
import * as RetuenOrderI from"./ReturnOrder"
import * as DischargeNurseI from"./DischargeSummary"

const SequencePattern: { [name: string]: { [flavor: string] : { [func: string]: any }}} = {
  Triage: { 
    default: {
      START: TriageI.SetupTriage,
      SaveTriage: TriageI.SaveTriage
    }    
  },
  Assessment: {
    default: {
      START: AssessmentI.Setup,
      Edit: AssessmentI.Edit,
    }
  },
  ReceiveOrder: {
    default: {
      START: ReceiveOrderI.SearchReceiveOrder,
    }
  },
  NursingDiagnosis: {
    default: {
      START: NursingDiagnosisI.Start,
    }
  },
  ReturnOrder:{
    default:{
      START: RetuenOrderI.Start,
      Edit: RetuenOrderI.Edit

    }
  },
  DischargeNurse:{
    default:{
      START:DischargeNurseI.Start
    }
  }
  
  
};

export default SequencePattern;
