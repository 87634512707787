import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Dropdown,
  Input,
  Button,
  Checkbox
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardSettingUserEmployeeUX = (props: any) => {
    return(
      <div
        style={{padding: "10px"}}>
        
        <div
          style={{fontSize: "1.2rem", fontWeight: "bold", marginBottom: "1rem"}}>
          ข้อมูลผู้ใช้งานระบบ
          <FormGroup>
          </FormGroup>
        </div>
        <div
          className="ui form"
          style={{backgroundColor: "rgba(239, 242, 247, 0.5)", borderRadius: "3px"}}>
          
          <div
            style={{display: "grid", gridTemplateColumns: "1fr 230px", padding: "1rem 1rem 0"}}>
            
            <div
              className="ui form">
              
              <FormGroup
                inline={true}
                style={{marginBottom: "1.3rem"}}>
                <FormField
                  inline={true}>
                  <div
                    style={{display: "flex", fontWeight: "bold",width: "105px"}}>
                    
                    <label>
                      คำนำหน้า
                    </label>
                    <label
                      style={{color: "red"}}>
                      *
                    </label>
                  </div>
                </FormField>
                <FormField
                  className={props.showRequiredField?.pre_name ? "error" : ""}
                  inline={true}
                  style={{position: "relative"}}
                  width={4}>
                  <Dropdown
                    clearable={true}
                    fluid={true}
                    onChange={props.changeValue("pre_name")}
                    options={props.prenameThOptions}
                    search={true}
                    selection={true}
                    style={{width: "100%", minHeight: "2.71428571em"}}
                    value={props.preNameTh || ""}>
                  </Dropdown>
                  <div
                    style={{position: "absolute", bottom: "-17px", color: "red", fontSize: "0.8rem"}}>
                    {props.showRequiredField?.pre_name || ""}
                  </div>
                </FormField>
                <FormField
                  inline={true}>
                  <div
                    style={{display: "flex", fontWeight: "bold",width: "50px"}}>
                    
                    <label>
                      ชื่อ
                    </label>
                    <label
                      style={{color: "red"}}>
                      *
                    </label>
                  </div>
                </FormField>
                <FormField
                  className={props.showRequiredField?.first_name ? "error" : ""}
                  inline={true}
                  style={{position: "relative"}}
                  width={4}>
                  <Input
                    fluid={true}
                    onChange={props.changeValue("first_name")}
                    style={{width: "100%",}}
                    value={props.firstNameTh || ""}>
                  </Input>
                  <div
                    style={{position: "absolute", bottom: "-17px", color: "red", fontSize: "0.8rem"}}>
                    {props.showRequiredField?.first_name || ""}
                  </div>
                </FormField>
                <FormField
                  inline={true}>
                  <div
                    style={{display: "flex", fontWeight: "bold",width: "75px"}}>
                    {}
                    <label>
                      นามสกุล
                    </label>
                    <label
                      style={{color: "red"}}>
                      *
                    </label>
                  </div>
                </FormField>
                <FormField
                  className={props.showRequiredField?.last_name ? "error" : ""}
                  inline={true}
                  style={{position: "relative"}}
                  width={4}>
                  <Input
                    fluid={true}
                    onChange={props.changeValue("last_name")}
                    style={{width: "100%"}}
                    value={props.lastNameTh || ""}>
                  </Input>
                  <div
                    style={{position: "absolute", bottom: "-17px", color: "red", fontSize: "0.8rem"}}>
                    {props.showRequiredField?.last_name || ""}
                  </div>
                </FormField>
              </FormGroup>
              <FormGroup
                inline={true}
                style={{marginBottom: "1.3rem"}}>
                <FormField
                  inline={true}>
                  <div
                    style={{display: "flex", fontWeight: "bold",width: "105px"}}>
                    {}
                    <label>
                      Pre-name
                    </label>
                    <label
                      style={{color: "red"}}>
                      *
                    </label>
                  </div>
                </FormField>
                <FormField
                  className={props.showRequiredField?.pre_name_en ? "error" : ""}
                  inline={true}
                  style={{position: "relative"}}
                  width={4}>
                  <Dropdown
                    clearable={true}
                    fluid={true}
                    onChange={props.changeValue("pre_name_en")}
                    options={props.prenameEnOptions}
                    search={true}
                    selection={true}
                    style={{width: "100%", minHeight: "2.71428571em"}}
                    value={props.preNameEn || ""}>
                  </Dropdown>
                  <div
                    style={{position: "absolute", bottom: "-17px", color: "red", fontSize: "0.8rem"}}>
                    {props.showRequiredField?.pre_name_en || ""}
                  </div>
                </FormField>
                <FormField
                  inline={true}>
                  <div
                    style={{display: "flex", fontWeight: "bold",width: "50px"}}>
                    {}
                    <label>
                      Name
                    </label>
                    <label
                      style={{color: "red"}}>
                      *
                    </label>
                  </div>
                </FormField>
                <FormField
                  className={props.showRequiredField?.first_name_en ? "error" : ""}
                  inline={true}
                  style={{position: "relative"}}
                  width={4}>
                  <Input
                    fluid={true}
                    onChange={props.changeValue("first_name_en")}
                    style={{width: "100%"}}
                    value={props.firstNameEn || ""}>
                  </Input>
                  <div
                    style={{position: "absolute", bottom: "-17px", color: "red", fontSize: "0.8rem"}}>
                    {props.showRequiredField?.first_name_en || ""}
                  </div>
                </FormField>
                <FormField
                  inline={true}>
                  <div
                    style={{display: "flex", fontWeight: "bold",width: "75px"}}>
                    
                    <label>
                      Lastname
                    </label>
                    <label
                      style={{color: "red"}}>
                      *
                    </label>
                  </div>
                </FormField>
                <FormField
                  className={props.showRequiredField?.last_name_en ? "error" : ""}
                  inline={true}
                  style={{position: "relative"}}
                  width={4}>
                  <Input
                    fluid={true}
                    onChange={props.changeValue("last_name_en")}
                    style={{width: "100%"}}
                    value={props.lastNameEn || ""}>
                  </Input>
                  <div
                    style={{position: "absolute", bottom: "-17px", color: "red", fontSize: "0.8rem"}}>
                    {props.showRequiredField?.last_name_en || ""}
                  </div>
                </FormField>
              </FormGroup>
              <FormGroup
                inline={true}
                style={{marginBottom: "1.3rem"}}>
                <FormField
                  inline={true}>
                  <div
                    style={{display: "flex", fontWeight: "bold",width: "105px"}}>
                    {}
                    <label>
                      เพศ
                    </label>
                    <label
                      style={{color: "red"}}>
                      *
                    </label>
                  </div>
                </FormField>
                <FormField
                  className={props.showRequiredField?.gender ? "error" : ""}
                  inline={true}
                  style={{position: "relative"}}
                  width={4}>
                  <Dropdown
                    clearable={true}
                    fluid={true}
                    onChange={props.changeValue("gender")}
                    options={props.genderOptions}
                    search={true}
                    selection={true}
                    style={{width: "100%", minHeight: "2.71428571em"}}
                    value={props.gender || ""}>
                  </Dropdown>
                  <div
                    style={{position: "absolute", bottom: "-17px", color: "red", fontSize: "0.8rem"}}>
                    {props.showRequiredField?.gender || ""}
                  </div>
                </FormField>
                <FormField
                  inline={true}>
                  <div
                    style={{display: "flex", fontWeight: "bold"}}>
                    {}
                    <label>
                      เลขประจำตัวประชาชน
                    </label>
                    <label
                      style={{color: "red"}}>
                      {!!props.isRequireCID ? "*" : "" }
                    </label>
                  </div>
                </FormField>
                <FormField
                  className={props.showRequiredField?.citizen_passport ? "error" : ""}
                  inline={true}
                  style={{position: "relative"}}
                  width={4}>
                  <Input
                    fluid={true}
                    onChange={props.changeValue("citizen_passport")}
                    style={{width: "100%"}}
                    value={props.citizenPassport || ""}>
                  </Input>
                  <div
                    style={{position: "absolute", bottom: "-17px", color: "red", fontSize: "0.8rem", minWidth: "max-content"}}>
                    {props.showRequiredField?.citizen_passport || ""}
                  </div>
                </FormField>
              </FormGroup>
              <FormGroup
                inline={true}
                style={{marginBottom: "1.3rem"}}>
                <FormField
                  inline={true}>
                  <div
                    style={{display: "flex", fontWeight: "bold", width: "105px"}}>
                    
                    <label>
                      วัน/เดือน/ปีเกิด
                    </label>
                    <label
                      style={{color: "red"}}>
                      *
                    </label>
                  </div>
                </FormField>
                <FormField
                  inline={true}
                  style={{position: "relative"}}
                  width={4}>
                  <div
                    style={{width: "100%"}}>
                    {props.birthDate}
                  </div>
                </FormField>
                <FormField
                  inline={true}>
                  <div
                    style={{fontWeight: "bold", width: "50px"}}>
                    อายุ
                  </div>
                </FormField>
                <FormField
                  inline={true}
                  width={3}>
                  <div
                    style={{width: "100%"}}>
                    {props.age}
                  </div>
                </FormField>
              </FormGroup>
              <FormGroup
                inline={true}
                style={{marginBottom: "1.3rem"}}>
                <FormField
                  inline={true}>
                  <div
                    style={{display: "flex", fontWeight: "bold", width: "105px"}}>
                    
                    <label>
                      เบอร์โทรศัพท์
                    </label>
                    <label>
                      
                    </label>
                  </div>
                </FormField>
                <FormField
                  inline={true}
                  style={{position: "relative"}}
                  width={4}>
                  <Input
                    fluid={true}
                    onChange={props.changeValue("phone_no")}
                    style={{width: "100%"}}
                    value={props.phoneNo || ""}>
                  </Input>
                  <div>
                    
                  </div>
                </FormField>
                <FormField
                  inline={true}>
                  <div
                    style={{display: "flex", fontWeight: "bold", width: "50px"}}>
                    
                    <label>
                      อีเมล์
                    </label>
                    <label
                      style={{color: "red"}}>
                      {!!props.isRequireEmail ? "*" : "" }
                    </label>
                  </div>
                </FormField>
                <FormField
                  className={props.showRequiredField?.email ? "error" : ""}
                  inline={true}
                  style={{position: "relative"}}
                  width={4}>
                  <Input
                    fluid={true}
                    onChange={props.changeValue("email")}
                    style={{width: "100%"}}
                    value={props.email || ""}>
                  </Input>
                  <div
                    style={{position: "absolute", bottom: "-17px", color: "red", fontSize: "0.8rem"}}>
                    {props.showRequiredField?.email || ""}
                  </div>
                </FormField>
              </FormGroup>
            </div>
            <div
              style={{paddingLeft: "1.5rem"}}>
              {props.profileImage}
            </div>
          </div>
          <div
            className="ui divider">
            
          </div>
          <div
            className="ui form"
            style={{paddingLeft: "1em"}}>
            
            <FormGroup
              style={{marginBottom: "1.3rem"}}>
              <FormField
                inline={true}>
                <div
                  style={{display: "flex", fontWeight: "bold", width: "105px", marginTop: "0.65rem"}}>
                  {}
                  <label>
                    ชื่อผู้ใช้
                  </label>
                  <label
                    style={{color: "red"}}>
                    *
                  </label>
                </div>
              </FormField>
              <FormField
                inline={true}
                style={{position: "relative", height: "fit-content"}}
                width={4}>
                <Input
                  fluid={true}
                  onChange={props.changeValue("username")}
                  style={{width: "100%", marginBottom: props.allowedResetPassword ? "1rem": ""}}
                  value={props.username || ""}>
                </Input>
                <div
                  style={{position: "absolute", bottom: "-17px", color: "red", fontSize: "0.8rem"}}>
                  {props.showRequiredField?.username || ""}
                </div>
              </FormField>
              <FormField
                style={{display: "grid"}}
                width={15}>
                <FormGroup
                  inline={true}
                  style={{marginBottom: "1.3rem", width: "100%", display: props.allowedResetPassword?"none": ""}}>
                  <FormField
                    inline={true}>
                    <div
                      style={{display: "flex", fontWeight: "bold",width: "100px", marginLeft: "1rem"}}>
                      
                      <label>
                        รหัสผ่าน
                      </label>
                      <label
                        style={{color: "red"}}>
                        *
                      </label>
                    </div>
                  </FormField>
                  <FormField
                    className={props.showRequiredField?.password ? "error" : ""}
                    inline={true}
                    style={{position: "relative"}}
                    width={4}>
                    <Input
                      fluid={true}
                      icon={props.eyePass1}
                      onChange={props.changeValue("password")}
                      style={{width: "100%",}}
                      type={props.typePassword1}
                      value={props.password || ""}>
                    </Input>
                    <div
                      style={{position: "absolute", bottom: "-17px", color: "red", fontSize: "0.8rem",minWidth: "max-content"}}>
                      {props.showRequiredField?.password || ""}
                    </div>
                  </FormField>
                  <FormField
                    inline={true}>
                    <div
                      style={{display: "flex", fontWeight: "bold",width: "140px"}}>
                      
                      <label>
                        ยืนยันรหัสผ่าน
                      </label>
                      <label
                        style={{color: "red"}}>
                        *
                      </label>
                    </div>
                  </FormField>
                  <FormField
                    className={props.showRequiredField?.confirm_password ? "error" : ""}
                    inline={true}
                    style={{position: "relative"}}
                    width={4}>
                    <Input
                      fluid={true}
                      icon={props.eyeConfirm1}
                      onChange={props.changeValue("confirm_password")}
                      style={{width: "100%"}}
                      type={props.typeConfirm1}
                      value={props.confirmPassword|| ""}>
                    </Input>
                    <div
                      style={{position: "absolute", bottom: "-17px", color: "red", fontSize: "0.8rem",minWidth: "max-content"}}>
                      {props.showRequiredField?.confirm_password || ""}
                    </div>
                  </FormField>
                </FormGroup>
                <FormGroup
                  inline={true}
                  style={{marginBottom: "1.3rem", width: "100%", display: !props.showPassword2 ? "none": props.allowedResetPassword?"none": ""}}>
                  <FormField
                    inline={true}>
                    <div
                      style={{display: "flex", fontWeight: "bold",width: "100px", marginLeft: "1rem"}}>
                      
                      <label>
                        รหัสผ่านชุดที่ 2
                      </label>
                      <label>
                        
                      </label>
                    </div>
                  </FormField>
                  <FormField
                    inline={true}
                    style={{position: "relative"}}
                    width={4}>
                    <Input
                      fluid={true}
                      icon={props.eyePass2}
                      onChange={props.changeValue("secondary_password")}
                      style={{width: "100%"}}
                      type={props.typePassword2}
                      value={props.secondaryPassword || ""}>
                    </Input>
                    <div>
                      
                    </div>
                  </FormField>
                  <FormField
                    inline={true}>
                    <div
                      style={{display: "flex", fontWeight: "bold",width: "140px"}}>
                      
                      <label>
                        ยืนยันรหัสผ่านชุดที่ 2
                      </label>
                      <label>
                        
                      </label>
                    </div>
                  </FormField>
                  <FormField
                    className={props.showRequiredField?.confirm_secondary_password? "error" : ""}
                    inline={true}
                    style={{position: "relative"}}
                    width={4}>
                    <Input
                      fluid={true}
                      icon={props.eyeConfirm2}
                      onChange={props.changeValue("confirm_secondary_password")}
                      style={{width: "100%"}}
                      type={props.typeConfirm2}
                      value={props.confirmSecondaryPassword || ""}>
                    </Input>
                    <div
                      style={{position: "absolute", bottom: "-17px", color: "red", fontSize: "0.8rem", minWidth: "max-content"}}>
                      {props.showRequiredField?.confirm_secondary_password|| ""}
                    </div>
                  </FormField>
                </FormGroup>
              </FormField>
            </FormGroup>
          </div>
        </div>
        <div
          style={{fontSize: "1.2rem", fontWeight: "bold", marginBottom: "1rem", display: props.hideEmployeeForm ? "none": ""}}>
          ข้อมูลเจ้าหน้าที่
          <FormGroup>
          </FormGroup>
        </div>
        <div
          className="ui form"
          style={{backgroundColor: "rgba(239, 242, 247, 0.5)", borderRadius: "3px", padding: "1rem 1rem", display: props.hideEmployeeForm ? "none": ""}}>
          
          <FormGroup
            inline={true}
            style={{marginBottom: "1.3rem", width: "100%"}}>
            <FormField
              inline={true}>
              <div
                style={{display: "flex", fontWeight: "bold",width: "100px"}}>
                
                <label>
                  รหัสพนักงาน
                </label>
                <label
                  style={{color: "red"}}>
                  *
                </label>
              </div>
            </FormField>
            <FormField
              className={props.showRequiredField?.code ? "error" : ""}
              inline={true}
              style={{position: "relative"}}
              width={3}>
              <Input
                fluid={true}
                onChange={props.changeValue("code")}
                style={{width: "100%"}}
                value={props.code || ""}>
              </Input>
              <div
                style={{position: "absolute", bottom: "-17px", color: "red", fontSize: "0.8rem"}}>
                {props.showRequiredField?.code || ""}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <div
                style={{display: "flex", fontWeight: "bold",width: "90px"}}>
                
                <label>
                  ตำแหน่งงาน
                </label>
                <label
                  style={{color: "red"}}>
                  *
                </label>
              </div>
            </FormField>
            <FormField
              className={props.showRequiredField?.position ? "error" : ""}
              inline={true}
              style={{position: "relative"}}
              width={3}>
              <Dropdown
                clearable={true}
                fluid={true}
                onChange={props.changeValue("position")}
                options={props.positionOptions}
                search={true}
                selection={true}
                style={{width: "100%", minHeight: "2.71428571em"}}
                value={props.position || ""}>
              </Dropdown>
              <div
                style={{position: "absolute", bottom: "-17px", color: "red", fontSize: "0.8rem"}}>
                {props.showRequiredField?.position || ""}
              </div>
            </FormField>
            <FormField
              inline={true}
              style={{position: "relative"}}
              width={2}>
              <Checkbox
                checked={props.isCreateDoctor || false}
                label="ต้องการสร้างแพทย์ในระบบ"
                onChange={(e:any,v: any)=>props.changeValue?.("isCreateDoctor")?.(null,{value:v.checked})}
                style={{minWidth: "max-content", fontWeight: "bold"}}>
              </Checkbox>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{marginBottom: "1.3rem", display: props.hideDoctorForm ?"none": ""}}>
            <FormField
              inline={true}>
              <div
                style={{fontWeight: "bold", width: "100px", display: "flex"}}>
                
                <label>
                  ประเภทแพทย์
                </label>
                <label>
                  
                </label>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Dropdown
                clearable={true}
                fluid={true}
                onChange={props.changeValue("doctor_type")}
                options={props.doctorTypeOptions}
                selection={true}
                style={{width: "100%", minHeight: "2.71428571em"}}
                value={props.doctorType || ""}>
              </Dropdown>
              <div>
                
              </div>
            </FormField>
            <FormField
              inline={true}>
              <div
                style={{display: "flex", fontWeight: "bold", width: "90px"}}>
                
                <label>
                  รหัสแพทย์
                </label>
                <label
                  style={{color: "red", display: props.isCreateDoctor ? "": "none"}}>
                  *
                </label>
              </div>
            </FormField>
            <FormField
              className={props.showRequiredField?.codeDoctor ? "error" : ""}
              inline={true}
              style={{position: "relative"}}
              width={3}>
              <Input
                fluid={true}
                onChange={props.changeValue("codeDoctor")}
                style={{width: "100%"}}
                value={props.codeDoctor || ""}>
              </Input>
              <div
                style={{position: "absolute", bottom: "-17px", color: "red", fontSize: "0.8rem"}}>
                {props.showRequiredField?.codeDoctor || ""}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <div
                style={{display: "flex", fontWeight: "bold", width: "145px"}}>
                
                <label>
                  เลขใบประกอบวิชาชีพ
                </label>
                <label
                  style={{color: "red", display: props.positionFlag?.teacher_flag || (props.isCreateDoctor && !props.positionFlag?.student_flag) ? "": "none"}}>
                  *
                </label>
              </div>
            </FormField>
            <FormField
              className={props.showRequiredField?.certificate_no ? "error" : ""}
              inline={true}
              style={{position: "relative"}}
              width={2}>
              <Input
                fluid={true}
                onChange={props.changeValue("certificate_no")}
                style={{width: "100%"}}
                value={props.certificateNo || ""}>
              </Input>
              <div
                style={{position: "absolute", bottom: "-17px", color: "red", fontSize: "0.8rem"}}>
                {props.showRequiredField?.certificate_no || ""}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <div
                style={{display: "flex", fontWeight: "bold", width: "80px"}}>
                
                <label>
                  Specialty
                </label>
                <label
                  style={{color: "red", display: props.positionFlag?.teacher_flag || (props.isCreateDoctor && !props.positionFlag?.student_flag) ? "": "none"}}>
                  *
                </label>
              </div>
            </FormField>
            <FormField
              className={props.showRequiredField?.specialties ? "error" : ""}
              inline={true}
              style={{position: "relative"}}
              width={2}>
              <Dropdown
                clearable={true}
                fluid={true}
                multiple={true}
                onChange={props.changeValue("specialties")}
                options={props.specialtyOptions}
                search={true}
                selection={true}
                style={{width: "100%", marginRight: 0}}
                value={props.specialties || ""}>
              </Dropdown>
              <div
                style={{position: "absolute", bottom: "-17px", color: "red", fontSize: "0.8rem"}}>
                {props.showRequiredField?.specialties || ""}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{marginBottom: "1.3rem"}}>
            <FormField
              inline={true}>
              <div
                style={{display: "flex", fontWeight: "bold",width: "100px"}}>
                {}
                <label>
                  วันที่เริ่มงาน
                </label>
                <label
                  style={{ color: "red"}}>
                  *
                </label>
              </div>
            </FormField>
            <FormField
              className={props.showRequiredField?.flag_start_date ? "error" : ""}
              inline={true}
              style={{position: "relative"}}
              width={3}>
              <div
                style={{width: "100%"}}>
                
                <DateTextBox
                  onChange={props.changeDate("flag_start_date")}
                  value={props.flagStartDate || ""}>
                  <div>
                    div
                  </div>
                </DateTextBox>
              </div>
              <div
                style={{position: "absolute", bottom: "-17px", color: "red",fontSize: "0.8rem"}}>
                {props.showRequiredField?.flag_start_date || ""}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <div
                style={{display: "flex", fontWeight: "bold",width: "90px"}}>
                
                <label>
                  วันที่สิ้นสุด
                </label>
                <label>
                  
                </label>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{width: "100%",marginRight: "1rem"}}>
                
                <DateTextBox
                  onChange={props.changeDate("flag_end_date")}
                  value={props.flagEndDate || ""}>
                </DateTextBox>
              </div>
            </FormField>
            <FormField
              inline={true}>
              <div
                style={{fontWeight: "bold", display: "flex"}}>
                
                <label>
                  เหตุผล
                </label>
                <label>
                  
                </label>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={6}>
              <Input
                fluid={true}
                onChange={props.changeValue("inactive_reason")}
                style={{width: "100%"}}
                value={props.inactiveReason || ""}>
              </Input>
              <div>
                
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{marginBottom: "1.3rem"}}>
            <FormField
              inline={true}>
              <div
                style={{display: "flex", fontWeight: "bold",width: "100px"}}>
                
                <label>
                  สถานะ
                </label>
                <label
                  style={{color: "red"}}>
                  *
                </label>
              </div>
            </FormField>
            <FormField
              className={props.showRequiredField?.status ? "error" : ""}
              inline={true}
              style={{position: "relative"}}
              width={3}>
              <Dropdown
                defaultValue={1}
                fluid={true}
                onChange={props.changeValue("status")}
                options={props.statusOptions}
                selection={true}
                style={{width: "100%"}}
                value={props.status || ""}>
              </Dropdown>
              <div
                style={{position: "absolute", bottom: "-17px", color: "red", fontSize: "0.8rem"}}>
                {props.showRequiredField?.status || ""}
              </div>
            </FormField>
            <FormField
              className="required"
              inline={true}>
              <label
                style={{ fontSize: "1rem", width: "8.2rem"}}>
                ประเภทเจ้าหน้าที่
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Dropdown
                fluid={true}
                onChange={props.changeValue("employment_type")}
                options={props.employmentTypeOptions}
                selection={true}
                style={{width: "100%", marginRight: "2.75rem"}}
                value={props.employmentType|| ""}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{marginBottom: "1.3rem"}}>
            <FormField
              inline={true}>
              <div
                style={{fontWeight: "bold",width: "100px"}}>
                Role
              </div>
            </FormField>
            <FormField
              inline={true}>
              <div
                style={{width: "100%"}}>
                {props.selectRole}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{marginBottom: "1.3rem", display: props.showMemberGroupPermission ? "": "none"}}>
            <FormField
              inline={true}>
              <div
                style={{fontWeight: "bold",width: "100px"}}>
                กลุ่มสมาชิกหลัก
              </div>
            </FormField>
            <FormField
              inline={true}>
              <div
                style={{width: "100%"}}>
                {props.selectMainMemberGroup}
              </div>
            </FormField>
          </FormGroup>
        </div>
        <div
          style={{display:"flex",justifyContent:"flex-end", margin: "2rem 0rem 2rem"}}>
          
          <Button
            color="blue"
            disabled={!props.allowedResetPassword}
            onClick={props.resetPassword}
            size="small"
            style={{display: props.allowedResetPassword?"":"none", marginRight: "0.75rem"}}>
            {props.languageUX === "en" ? "Reset Password" : "เปลี่ยนรหัสผ่าน"}
          </Button>
          <div
            style={{marginRight:"10px"}}>
            {props.buttonSave}
          </div>
        </div>
      </div>
    )
}


export default CardSettingUserEmployeeUX

export const screenPropsDefault = {"title":"9:00-12:00"}

/* Date Time : Mon Nov 04 2024 09:47:08 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "ข้อมูลผู้ใช้งานระบบ"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.2rem\", fontWeight: \"bold\", marginBottom: \"1rem\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"rgba(239, 242, 247, 0.5)\", borderRadius: \"3px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"grid\", gridTemplateColumns: \"1fr 230px\", padding: \"1rem 1rem 0\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "code",
          "value": "props.profileImage"
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"1.5rem\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormGroup",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"1.3rem\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormGroup",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"1.3rem\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormGroup",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"1.3rem\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "FormGroup",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"1.3rem\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "FormGroup",
      "parent": 1,
      "props": {
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "FormField",
      "parent": 6,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.pre_name ? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\"}"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "FormField",
      "parent": 6,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.first_name ? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\"}"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "FormField",
      "parent": 6,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.last_name ? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\"}"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\",width: \"105px\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showRequiredField?.pre_name || \"\""
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\", bottom: \"-17px\", color: \"red\", fontSize: \"0.8rem\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\",width: \"50px\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showRequiredField?.first_name || \"\""
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\", bottom: \"-17px\", color: \"red\", fontSize: \"0.8rem\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\",width: \"75px\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showRequiredField?.last_name || \"\""
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\", bottom: \"-17px\", color: \"red\", fontSize: \"0.8rem\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "label",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "คำนำหน้า"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "label",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "label",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "label",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "label",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": "นามสกุล"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "label",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "Dropdown",
      "parent": 12,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"pre_name\")"
        },
        "options": {
          "type": "code",
          "value": "props.prenameThOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", minHeight: \"2.71428571em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.preNameTh || \"\""
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "Input",
      "parent": 16,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"last_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.lastNameTh || \"\""
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "Input",
      "parent": 14,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"first_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\",}"
        },
        "value": {
          "type": "code",
          "value": "props.firstNameTh || \"\""
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "FormField",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "FormField",
      "parent": 7,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.pre_name_en ? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\"}"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "FormField",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "FormField",
      "parent": 7,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.first_name_en ? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\"}"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "FormField",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "FormField",
      "parent": 7,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.last_name_en ? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\"}"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 33,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showRequiredField?.pre_name_en || \"\""
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\", bottom: \"-17px\", color: \"red\", fontSize: \"0.8rem\"}"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 34,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\",width: \"50px\"}"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 35,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showRequiredField?.first_name_en || \"\""
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\", bottom: \"-17px\", color: \"red\", fontSize: \"0.8rem\"}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 36,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\",width: \"75px\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 37,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showRequiredField?.last_name_en || \"\""
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\", bottom: \"-17px\", color: \"red\", fontSize: \"0.8rem\"}"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 32,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\",width: \"105px\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "label",
      "parent": 44,
      "props": {
        "children": {
          "type": "value",
          "value": "Pre-name"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "label",
      "parent": 44,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "label",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": "Name"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "label",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "label",
      "parent": 42,
      "props": {
        "children": {
          "type": "value",
          "value": "Lastname"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "label",
      "parent": 42,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "Dropdown",
      "parent": 33,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"pre_name_en\")"
        },
        "options": {
          "type": "code",
          "value": "props.prenameEnOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", minHeight: \"2.71428571em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.preNameEn || \"\""
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "Input",
      "parent": 35,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"first_name_en\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.firstNameEn || \"\""
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "Input",
      "parent": 37,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"last_name_en\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.lastNameEn || \"\""
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "FormField",
      "parent": 8,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "FormField",
      "parent": 8,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.gender ? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\"}"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 56,
      "name": "FormField",
      "parent": 8,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "FormField",
      "parent": 8,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.citizen_passport ? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\"}"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 54,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\",width: \"105px\"}"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "div",
      "parent": 55,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showRequiredField?.gender || \"\""
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\", bottom: \"-17px\", color: \"red\", fontSize: \"0.8rem\"}"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "div",
      "parent": 56,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\"}"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "div",
      "parent": 57,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showRequiredField?.citizen_passport || \"\""
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\", bottom: \"-17px\", color: \"red\", fontSize: \"0.8rem\", minWidth: \"max-content\"}"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "label",
      "parent": 58,
      "props": {
        "children": {
          "type": "value",
          "value": "เพศ"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "label",
      "parent": 58,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": null,
      "id": 64,
      "name": "label",
      "parent": 60,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขประจำตัวประชาชน"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "label",
      "parent": 60,
      "props": {
        "children": {
          "type": "code",
          "value": "!!props.isRequireCID ? \"*\" : \"\" "
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "Dropdown",
      "parent": 55,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"gender\")"
        },
        "options": {
          "type": "code",
          "value": "props.genderOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", minHeight: \"2.71428571em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.gender || \"\""
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "Input",
      "parent": 57,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"citizen_passport\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.citizenPassport || \"\""
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "FormField",
      "parent": 9,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "FormField",
      "parent": 9,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\"}"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "FormField",
      "parent": 9,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "FormField",
      "parent": 9,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "div",
      "parent": 68,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\", width: \"105px\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "div",
      "parent": 69,
      "props": {
        "children": {
          "type": "code",
          "value": "props.birthDate"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "div",
      "parent": 70,
      "props": {
        "children": {
          "type": "value",
          "value": "อายุ"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", width: \"50px\"}"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "div",
      "parent": 71,
      "props": {
        "children": {
          "type": "code",
          "value": "props.age"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "label",
      "parent": 72,
      "props": {
        "children": {
          "type": "value",
          "value": "วัน/เดือน/ปีเกิด"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "label",
      "parent": 72,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "FormGroup",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"1.3rem\"}"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 79,
      "name": "FormField",
      "parent": 78,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 80,
      "name": "FormField",
      "parent": 78,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\"}"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 81,
      "name": "FormField",
      "parent": 78,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "FormField",
      "parent": 78,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.email ? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\"}"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "div",
      "parent": 79,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\", width: \"105px\"}"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 84,
      "name": "div",
      "parent": 80,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "div",
      "parent": 81,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\", width: \"50px\"}"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": null,
      "id": 86,
      "name": "div",
      "parent": 82,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showRequiredField?.email || \"\""
        },
        "className": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\", bottom: \"-17px\", color: \"red\", fontSize: \"0.8rem\"}"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "label",
      "parent": 83,
      "props": {
        "children": {
          "type": "value",
          "value": "เบอร์โทรศัพท์"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "label",
      "parent": 83,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "label",
      "parent": 85,
      "props": {
        "children": {
          "type": "value",
          "value": "อีเมล์"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": null,
      "id": 90,
      "name": "label",
      "parent": 85,
      "props": {
        "children": {
          "type": "code",
          "value": "!!props.isRequireEmail ? \"*\" : \"\" "
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 91,
      "name": "Input",
      "parent": 80,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"phone_no\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.phoneNo || \"\""
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 92,
      "name": "Input",
      "parent": 82,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"email\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.email || \"\""
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": null,
      "id": 93,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"1em\"}"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 95,
      "name": "FormGroup",
      "parent": 94,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"1.3rem\"}"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 96,
      "name": "FormField",
      "parent": 95,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 97,
      "name": "FormField",
      "parent": 95,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"grid\"}"
        },
        "width": {
          "type": "code",
          "value": "15"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 98,
      "name": "FormGroup",
      "parent": 97,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"1.3rem\", width: \"100%\", display: props.allowedResetPassword?\"none\": \"\"}"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 99,
      "name": "FormGroup",
      "parent": 97,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"1.3rem\", width: \"100%\", display: !props.showPassword2 ? \"none\": props.allowedResetPassword?\"none\": \"\"}"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": null,
      "id": 100,
      "name": "div",
      "parent": 96,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\", width: \"105px\", marginTop: \"0.65rem\"}"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": null,
      "id": 101,
      "name": "label",
      "parent": 100,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อผู้ใช้"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": null,
      "id": 102,
      "name": "label",
      "parent": 100,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 103,
      "name": "FormField",
      "parent": 95,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\", height: \"fit-content\"}"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": null,
      "id": 104,
      "name": "div",
      "parent": 103,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showRequiredField?.username || \"\""
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\", bottom: \"-17px\", color: \"red\", fontSize: \"0.8rem\"}"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 105,
      "name": "Input",
      "parent": 103,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"username\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", marginBottom: props.allowedResetPassword ? \"1rem\": \"\"}"
        },
        "value": {
          "type": "code",
          "value": "props.username || \"\""
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 106,
      "name": "FormField",
      "parent": 98,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 107,
      "name": "FormField",
      "parent": 98,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.password ? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\"}"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 108,
      "name": "FormField",
      "parent": 98,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 109,
      "name": "FormField",
      "parent": 98,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.confirm_password ? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\"}"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": null,
      "id": 110,
      "name": "div",
      "parent": 106,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\",width: \"100px\", marginLeft: \"1rem\"}"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": null,
      "id": 111,
      "name": "div",
      "parent": 107,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showRequiredField?.password || \"\""
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\", bottom: \"-17px\", color: \"red\", fontSize: \"0.8rem\",minWidth: \"max-content\"}"
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": null,
      "id": 112,
      "name": "div",
      "parent": 108,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\",width: \"140px\"}"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": null,
      "id": 113,
      "name": "div",
      "parent": 109,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showRequiredField?.confirm_password || \"\""
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\", bottom: \"-17px\", color: \"red\", fontSize: \"0.8rem\",minWidth: \"max-content\"}"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": null,
      "id": 114,
      "name": "label",
      "parent": 110,
      "props": {
        "children": {
          "type": "value",
          "value": "รหัสผ่าน"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": null,
      "id": 115,
      "name": "label",
      "parent": 110,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": null,
      "id": 116,
      "name": "label",
      "parent": 112,
      "props": {
        "children": {
          "type": "value",
          "value": "ยืนยันรหัสผ่าน"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": null,
      "id": 117,
      "name": "label",
      "parent": 112,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 118,
      "name": "Input",
      "parent": 107,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "icon": {
          "type": "code",
          "value": "props.eyePass1"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"password\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\",}"
        },
        "type": {
          "type": "code",
          "value": "props.typePassword1"
        },
        "value": {
          "type": "code",
          "value": "props.password || \"\""
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 119,
      "name": "Input",
      "parent": 109,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "icon": {
          "type": "code",
          "value": "props.eyeConfirm1"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"confirm_password\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "type": {
          "type": "code",
          "value": "props.typeConfirm1"
        },
        "value": {
          "type": "code",
          "value": "props.confirmPassword|| \"\""
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 120,
      "name": "FormField",
      "parent": 99,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 121,
      "name": "FormField",
      "parent": 99,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\"}"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 122,
      "name": "FormField",
      "parent": 99,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 123,
      "name": "FormField",
      "parent": 99,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.confirm_secondary_password? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\"}"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": null,
      "id": 124,
      "name": "div",
      "parent": 120,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\",width: \"100px\", marginLeft: \"1rem\"}"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": null,
      "id": 125,
      "name": "div",
      "parent": 121,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": null,
      "id": 126,
      "name": "div",
      "parent": 122,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\",width: \"140px\"}"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": null,
      "id": 127,
      "name": "div",
      "parent": 123,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showRequiredField?.confirm_secondary_password|| \"\""
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\", bottom: \"-17px\", color: \"red\", fontSize: \"0.8rem\", minWidth: \"max-content\"}"
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": null,
      "id": 128,
      "name": "label",
      "parent": 124,
      "props": {
        "children": {
          "type": "value",
          "value": "รหัสผ่านชุดที่ 2"
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": null,
      "id": 129,
      "name": "label",
      "parent": 124,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": null,
      "id": 130,
      "name": "label",
      "parent": 126,
      "props": {
        "children": {
          "type": "value",
          "value": "ยืนยันรหัสผ่านชุดที่ 2"
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": null,
      "id": 131,
      "name": "label",
      "parent": 126,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 132,
      "name": "Input",
      "parent": 121,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "icon": {
          "type": "code",
          "value": "props.eyePass2"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"secondary_password\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "type": {
          "type": "code",
          "value": "props.typePassword2"
        },
        "value": {
          "type": "code",
          "value": "props.secondaryPassword || \"\""
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 133,
      "name": "Input",
      "parent": 123,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "icon": {
          "type": "code",
          "value": "props.eyeConfirm2"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"confirm_secondary_password\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "type": {
          "type": "code",
          "value": "props.typeConfirm2"
        },
        "value": {
          "type": "code",
          "value": "props.confirmSecondaryPassword || \"\""
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": null,
      "id": 134,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "ข้อมูลเจ้าหน้าที่"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.2rem\", fontWeight: \"bold\", marginBottom: \"1rem\", display: props.hideEmployeeForm ? \"none\": \"\"}"
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 135,
      "name": "FormGroup",
      "parent": 134,
      "props": {
      },
      "seq": 135,
      "void": false
    },
    {
      "from": null,
      "id": 136,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"rgba(239, 242, 247, 0.5)\", borderRadius: \"3px\", padding: \"1rem 1rem\", display: props.hideEmployeeForm ? \"none\": \"\"}"
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 137,
      "name": "FormGroup",
      "parent": 136,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"1.3rem\", width: \"100%\"}"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 138,
      "name": "FormGroup",
      "parent": 136,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"1.3rem\", display: props.hideDoctorForm ?\"none\": \"\"}"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 139,
      "name": "FormGroup",
      "parent": 136,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"1.3rem\"}"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 140,
      "name": "FormField",
      "parent": 137,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 141,
      "name": "FormField",
      "parent": 137,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.code ? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\"}"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 142,
      "name": "FormField",
      "parent": 137,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 143,
      "name": "FormField",
      "parent": 137,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.position ? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\"}"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 145,
      "name": "FormField",
      "parent": 137,
      "props": {
        "className": {
          "type": "code",
          "value": ""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\"}"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": null,
      "id": 148,
      "name": "div",
      "parent": 140,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\",width: \"100px\"}"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": null,
      "id": 149,
      "name": "div",
      "parent": 141,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showRequiredField?.code || \"\""
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\", bottom: \"-17px\", color: \"red\", fontSize: \"0.8rem\"}"
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": null,
      "id": 150,
      "name": "div",
      "parent": 142,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\",width: \"90px\"}"
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": null,
      "id": 151,
      "name": "div",
      "parent": 143,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showRequiredField?.position || \"\""
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\", bottom: \"-17px\", color: \"red\", fontSize: \"0.8rem\"}"
        }
      },
      "seq": 165,
      "void": false
    },
    {
      "from": null,
      "id": 156,
      "name": "label",
      "parent": 148,
      "props": {
        "children": {
          "type": "value",
          "value": "รหัสพนักงาน"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": null,
      "id": 157,
      "name": "label",
      "parent": 148,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 158,
      "name": "Input",
      "parent": 141,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "loading": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"code\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.code || \"\""
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": null,
      "id": 159,
      "name": "label",
      "parent": 150,
      "props": {
        "children": {
          "type": "value",
          "value": "ตำแหน่งงาน"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": null,
      "id": 160,
      "name": "label",
      "parent": 150,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 165,
      "name": "Dropdown",
      "parent": 143,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"position\")"
        },
        "options": {
          "type": "code",
          "value": "props.positionOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", minHeight: \"2.71428571em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.position || \"\""
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 168,
      "name": "FormField",
      "parent": 138,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 168,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 169,
      "name": "FormField",
      "parent": 138,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 169,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 170,
      "name": "FormField",
      "parent": 138,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 170,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 171,
      "name": "FormField",
      "parent": 138,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.codeDoctor ? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\"}"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 171,
      "void": false
    },
    {
      "from": null,
      "id": 172,
      "name": "div",
      "parent": 168,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", width: \"100px\", display: \"flex\"}"
        }
      },
      "seq": 172,
      "void": false
    },
    {
      "from": null,
      "id": 173,
      "name": "div",
      "parent": 169,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 180,
      "void": false
    },
    {
      "from": null,
      "id": 174,
      "name": "div",
      "parent": 170,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\", width: \"90px\"}"
        }
      },
      "seq": 174,
      "void": false
    },
    {
      "from": null,
      "id": 175,
      "name": "div",
      "parent": 171,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showRequiredField?.codeDoctor || \"\""
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\", bottom: \"-17px\", color: \"red\", fontSize: \"0.8rem\"}"
        }
      },
      "seq": 181,
      "void": false
    },
    {
      "from": null,
      "id": 176,
      "name": "label",
      "parent": 172,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทแพทย์"
        }
      },
      "seq": 176,
      "void": false
    },
    {
      "from": null,
      "id": 177,
      "name": "label",
      "parent": 172,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 177,
      "void": false
    },
    {
      "from": null,
      "id": 178,
      "name": "label",
      "parent": 174,
      "props": {
        "children": {
          "type": "value",
          "value": "รหัสแพทย์"
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": null,
      "id": 179,
      "name": "label",
      "parent": 174,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\", display: props.isCreateDoctor ? \"\": \"none\"}"
        }
      },
      "seq": 179,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 180,
      "name": "Dropdown",
      "parent": 169,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"doctor_type\")"
        },
        "options": {
          "type": "code",
          "value": "props.doctorTypeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", minHeight: \"2.71428571em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.doctorType || \"\""
        }
      },
      "seq": 173,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 181,
      "name": "Input",
      "parent": 171,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"codeDoctor\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.codeDoctor || \"\""
        }
      },
      "seq": 175,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 182,
      "name": "FormField",
      "parent": 139,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 182,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 183,
      "name": "FormField",
      "parent": 139,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.flag_start_date ? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\"}"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 183,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 184,
      "name": "FormField",
      "parent": 139,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 184,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 185,
      "name": "FormField",
      "parent": 139,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 185,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 186,
      "name": "FormField",
      "parent": 139,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 186,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 187,
      "name": "FormField",
      "parent": 139,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 187,
      "void": false
    },
    {
      "from": null,
      "id": 188,
      "name": "div",
      "parent": 182,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\",width: \"100px\"}"
        }
      },
      "seq": 188,
      "void": false
    },
    {
      "from": null,
      "id": 189,
      "name": "div",
      "parent": 183,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 189,
      "void": false
    },
    {
      "from": null,
      "id": 190,
      "name": "div",
      "parent": 184,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\",width: \"90px\"}"
        }
      },
      "seq": 190,
      "void": false
    },
    {
      "from": null,
      "id": 191,
      "name": "div",
      "parent": 185,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\",marginRight: \"1rem\"}"
        }
      },
      "seq": 191,
      "void": false
    },
    {
      "from": null,
      "id": 192,
      "name": "div",
      "parent": 186,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", display: \"flex\"}"
        }
      },
      "seq": 192,
      "void": false
    },
    {
      "from": null,
      "id": 193,
      "name": "div",
      "parent": 187,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 203,
      "void": false
    },
    {
      "from": null,
      "id": 194,
      "name": "label",
      "parent": 188,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่เริ่มงาน"
        }
      },
      "seq": 194,
      "void": false
    },
    {
      "from": null,
      "id": 195,
      "name": "label",
      "parent": 188,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\"}"
        }
      },
      "seq": 195,
      "void": false
    },
    {
      "from": null,
      "id": 196,
      "name": "label",
      "parent": 190,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่สิ้นสุด"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 196,
      "void": false
    },
    {
      "from": null,
      "id": 197,
      "name": "label",
      "parent": 190,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 197,
      "void": false
    },
    {
      "from": null,
      "id": 198,
      "name": "label",
      "parent": 192,
      "props": {
        "children": {
          "type": "value",
          "value": "เหตุผล"
        }
      },
      "seq": 198,
      "void": false
    },
    {
      "from": null,
      "id": 199,
      "name": "label",
      "parent": 192,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 199,
      "void": false
    },
    {
      "from": null,
      "id": 200,
      "name": "div",
      "parent": 183,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showRequiredField?.flag_start_date || \"\""
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\", bottom: \"-17px\", color: \"red\",fontSize: \"0.8rem\"}"
        }
      },
      "seq": 200,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 201,
      "name": "DateTextBox",
      "parent": 189,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changeDate(\"flag_start_date\")"
        },
        "value": {
          "type": "code",
          "value": "props.flagStartDate || \"\""
        }
      },
      "seq": 201,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 202,
      "name": "DateTextBox",
      "parent": 191,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changeDate(\"flag_end_date\")"
        },
        "value": {
          "type": "code",
          "value": "props.flagEndDate || \"\""
        }
      },
      "seq": 202,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 203,
      "name": "Input",
      "parent": 187,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"inactive_reason\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.inactiveReason || \"\""
        }
      },
      "seq": 193,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 204,
      "name": "FormGroup",
      "parent": 136,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"1.3rem\"}"
        }
      },
      "seq": 204,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 205,
      "name": "FormField",
      "parent": 204,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 205,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 206,
      "name": "FormField",
      "parent": 204,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.status ? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\"}"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 206,
      "void": false
    },
    {
      "from": null,
      "id": 207,
      "name": "div",
      "parent": 205,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\",width: \"100px\"}"
        }
      },
      "seq": 207,
      "void": false
    },
    {
      "from": null,
      "id": 208,
      "name": "div",
      "parent": 206,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showRequiredField?.status || \"\""
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\", bottom: \"-17px\", color: \"red\", fontSize: \"0.8rem\"}"
        }
      },
      "seq": 211,
      "void": false
    },
    {
      "from": null,
      "id": 209,
      "name": "label",
      "parent": 207,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะ"
        }
      },
      "seq": 209,
      "void": false
    },
    {
      "from": null,
      "id": 210,
      "name": "label",
      "parent": 207,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 210,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 211,
      "name": "Dropdown",
      "parent": 206,
      "props": {
        "defaultValue": {
          "type": "code",
          "value": "1"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"status\")"
        },
        "options": {
          "type": "code",
          "value": "props.statusOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.status || \"\""
        }
      },
      "seq": 208,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 212,
      "name": "FormGroup",
      "parent": 136,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"1.3rem\"}"
        }
      },
      "seq": 212,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 213,
      "name": "FormField",
      "parent": 212,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 213,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 214,
      "name": "FormField",
      "parent": 212,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 214,
      "void": false
    },
    {
      "from": null,
      "id": 217,
      "name": "div",
      "parent": 213,
      "props": {
        "children": {
          "type": "value",
          "value": "Role"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\",width: \"100px\"}"
        }
      },
      "seq": 217,
      "void": false
    },
    {
      "from": null,
      "id": 218,
      "name": "div",
      "parent": 214,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectRole"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 218,
      "void": false
    },
    {
      "from": null,
      "id": 221,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",justifyContent:\"flex-end\", margin: \"2rem 0rem 2rem\"}"
        }
      },
      "seq": 221,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 222,
      "name": "Button",
      "parent": 221,
      "props": {
        "children": {
          "type": "value",
          "value": "เปลี่ยนรหัสผ่าน",
          "valueEN": "Reset Password"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "!props.allowedResetPassword"
        },
        "onClick": {
          "type": "code",
          "value": "props.resetPassword"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{display: props.allowedResetPassword?\"\":\"none\", marginRight: \"0.75rem\"}"
        }
      },
      "seq": 222,
      "void": false
    },
    {
      "from": null,
      "id": 223,
      "name": "div",
      "parent": 221,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "style": {
          "type": "code",
          "value": "{marginRight:\"10px\"}"
        }
      },
      "seq": 223,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 224,
      "name": "FormGroup",
      "parent": 136,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"1.3rem\", display: props.showMemberGroupPermission ? \"\": \"none\"}"
        }
      },
      "seq": 224,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 225,
      "name": "FormField",
      "parent": 224,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 225,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 226,
      "name": "FormField",
      "parent": 224,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 226,
      "void": false
    },
    {
      "from": null,
      "id": 227,
      "name": "div",
      "parent": 225,
      "props": {
        "children": {
          "type": "value",
          "value": "กลุ่มสมาชิกหลัก"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\",width: \"100px\"}"
        }
      },
      "seq": 227,
      "void": false
    },
    {
      "from": null,
      "id": 228,
      "name": "div",
      "parent": 226,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectMainMemberGroup"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 228,
      "void": false
    },
    {
      "from": null,
      "id": 229,
      "name": "div",
      "parent": 201,
      "props": {
        "children": {
          "type": "value",
          "value": "div"
        }
      },
      "seq": 229,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 230,
      "name": "FormField",
      "parent": 138,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 230,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 231,
      "name": "FormField",
      "parent": 138,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.certificate_no ? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\"}"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 231,
      "void": false
    },
    {
      "from": null,
      "id": 232,
      "name": "div",
      "parent": 230,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\", width: \"145px\"}"
        }
      },
      "seq": 232,
      "void": false
    },
    {
      "from": null,
      "id": 233,
      "name": "label",
      "parent": 232,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขใบประกอบวิชาชีพ"
        }
      },
      "seq": 233,
      "void": false
    },
    {
      "from": null,
      "id": 235,
      "name": "label",
      "parent": 232,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\", display: props.positionFlag?.teacher_flag || (props.isCreateDoctor && !props.positionFlag?.student_flag) ? \"\": \"none\"}"
        }
      },
      "seq": 235,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 236,
      "name": "Input",
      "parent": 231,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"certificate_no\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.certificateNo || \"\""
        }
      },
      "seq": 236,
      "void": false
    },
    {
      "from": null,
      "id": 237,
      "name": "div",
      "parent": 231,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showRequiredField?.certificate_no || \"\""
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\", bottom: \"-17px\", color: \"red\", fontSize: \"0.8rem\"}"
        }
      },
      "seq": 237,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 238,
      "name": "FormField",
      "parent": 138,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 238,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 239,
      "name": "FormField",
      "parent": 138,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.specialties ? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\"}"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 239,
      "void": false
    },
    {
      "from": null,
      "id": 240,
      "name": "div",
      "parent": 238,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\", width: \"80px\"}"
        }
      },
      "seq": 240,
      "void": false
    },
    {
      "from": null,
      "id": 241,
      "name": "label",
      "parent": 240,
      "props": {
        "children": {
          "type": "value",
          "value": "Specialty"
        }
      },
      "seq": 241,
      "void": false
    },
    {
      "from": null,
      "id": 242,
      "name": "label",
      "parent": 240,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\", display: props.positionFlag?.teacher_flag || (props.isCreateDoctor && !props.positionFlag?.student_flag) ? \"\": \"none\"}"
        }
      },
      "seq": 242,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 243,
      "name": "Dropdown",
      "parent": 239,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"specialties\")"
        },
        "options": {
          "type": "code",
          "value": "props.specialtyOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", marginRight: 0}"
        },
        "value": {
          "type": "code",
          "value": "props.specialties || \"\""
        }
      },
      "seq": 243,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 244,
      "name": "Checkbox",
      "parent": 145,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isCreateDoctor || false"
        },
        "label": {
          "type": "value",
          "value": "ต้องการสร้างแพทย์ในระบบ"
        },
        "onChange": {
          "type": "code",
          "value": "(e:any,v: any)=>props.changeValue?.(\"isCreateDoctor\")?.(null,{value:v.checked})"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", fontWeight: \"bold\"}"
        }
      },
      "seq": 244,
      "void": false
    },
    {
      "from": null,
      "id": 245,
      "name": "div",
      "parent": 239,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showRequiredField?.specialties || \"\""
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\", bottom: \"-17px\", color: \"red\", fontSize: \"0.8rem\"}"
        }
      },
      "seq": 245,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 246,
      "name": "FormField",
      "parent": 204,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 246,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 247,
      "name": "FormField",
      "parent": 204,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 247,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 249,
      "name": "Dropdown",
      "parent": 247,
      "props": {
        "defaultValue": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"employment_type\")"
        },
        "options": {
          "type": "code",
          "value": "props.employmentTypeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", marginRight: \"2.75rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.employmentType|| \"\""
        }
      },
      "seq": 249,
      "void": false
    },
    {
      "from": null,
      "id": 252,
      "name": "label",
      "parent": 246,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทเจ้าหน้าที่"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"1rem\", width: \"8.2rem\"}"
        }
      },
      "seq": 252,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardSettingUserEmployeeUX",
  "project": "cudent2",
  "screenPropsDefault": {
    "title": "9:00-12:00"
  },
  "width": 80
}

*********************************************************************************** */
