export default class CardOperativeNoteViewController {
  constructor(props) {
    this.mainPHVManager = props.mainPHVManager;
    this.ormManager = props.ormManager;
  }

  getOperativeNote = async ({ encounterId, startDate, endDate } = {}) => {
    let params = {};
    if (encounterId) {
      params.encounter = encounterId;
    }
    if (startDate) {
      params.start_date = startDate;
    }
    if (endDate) {
      params.end_date = endDate;
    }
    const [
      response,
      error,
      numberOfPage,
    ] = await this.mainPHVManager.getOperativeNote(params);
    return [response, error, numberOfPage];
  };

  getOperativeImage = async (operativeNoteId) => {
    const [response, error, network] = await this.ormManager.getOperativeImage(
      operativeNoteId
    );
    return [response, error, network];
  };

  printOperativeNote = async (teamId) => {
    const [response, error, network] = await this.ormManager.printOperativeNote(
      teamId
    );
    return [response, error, network];
  };
}
