import * as FoodListI from "./FoodList";
import * as FoodRequestI from "./FoodRequest";

const SequencePattern: {
  [name: string]: { [flavor: string]: { [func: string]: any } };
} = {
  FoodList: {
    default: {
      START: FoodListI.FetchData,
      PrintReport: FoodListI.PrintReport,
    },
  },
  FoodRequest: {
    default: {
      START: FoodRequestI.InitData,
      AddOrEdit: FoodRequestI.AddOrEdit,
    },
  },
};

export default SequencePattern;
