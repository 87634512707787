import { TDocumentDefinitions } from "pdfmake/interfaces";
import moment from "moment";

import CompanyLogoForm from "react-lib/apps/HISV3/common/CompanyLogoForm";

const FORM_NAME = "FormOverDueReport";

export default async function toPDFMakeData(props: any): Promise<TDocumentDefinitions> {
  console.log("saika Props form CoverageReport: ", props);

  const companyLogoForm = await CompanyLogoForm({
    font: "THSarabunNew",
    form: FORM_NAME,
    height: 35,
    isHorizontal: true,
    showAddress: ["CU"],
    type: 2
  });
  const { font, images } = companyLogoForm;

  const generateTableBody = (data: any, columns: any) => {
    let body: any[] = [];
    let grandTotalPrice = 0
    let grandTotalPaid = 0
    let grandTotalCashonly = 0
    let grandTotalOverdue = 0
    let grandTotalDiscount = 0
    let grandTotalSendClaim = 0
    let grandTotalAbsorbPrice = 0

    // Header of Table
    body.push([
      { text: "ลำดับ", alignment: "center",  bold: true, margin: [0,10 ,0 ,0],border: [true, false, true, true] },
      { text: "HN\n วันที่-เวลา", alignment: "center", bold: true,border: [true, false, true, true] },
      { text: "VN\n ENC.ID", alignment: "center", bold: true ,border: [true, false, true, true] },
      { text: "ชื่อผู้ป่วย", alignment: "center", bold: true , margin: [0,10 ,0 ,0],border: [true, false, true, true] },
      { text: "ราคารวม", alignment: "center", bold: true , margin: [0,10 ,0 ,0],border: [true, false, true, true] },
      { text: "ชำระแล้ว", alignment: "center", bold: true , margin: [0,10 ,0 ,0],border: [true, false, true, true] },
      { text: "ค้างชำระ", alignment: "center", bold: true , margin: [0,10 ,0 ,0],border: [true, false, true, true] },
      { text: "เบิกไม่ได้", alignment: "center", bold: true , margin: [0,10 ,0 ,0],border: [true, false, true, true] },
      { text: "เบิกได้", alignment: "center", bold: true , margin: [0,10 ,0 ,0],border: [true, false, true, true] },
      { text: "ส่วนลด", alignment: "center", bold: true , margin: [0,10 ,0 ,0],border: [true, false, true, true] },
      { text: "ส่วนลดตามสิทธิ", alignment: "center", bold: true, margin: [0,10 ,0 ,0],border: [true, false, true, true] },
      { text: "สิทธิการรักษา", alignment: "center", bold: true, margin: [0,10 ,0 ,0],border: [true, false, true, true] },
      { text: "รายละเอียด", alignment: "center" , bold: true, margin: [0,10 ,0 ,0],border: [true, false, true, true]},
    ]);

    data.forEach((field: any) => {
      if (field.items?.length > 0) {
        (field.items || []).forEach((item: any,index:any) => {
          let sumTotalPrice = 0
          let sumTotalPaid = 0
          let sumTotalCashonly = 0
          let sumTotalDiscount = 0
          let sumTotalOverdue = 0
          let sumTotalSendClaim = 0
          let sumTotalAbsorbPrice = 0

          if (item?.length > 0) {
            body.push([
              {
                text: `สิทธิ : ${findCoverage(item[0].coverage) || ""}`,colSpan: 13 },
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
            ]);

            (item|| []).forEach((item: any,index:any) => {

              sumTotalPrice = sumTotalPrice + Number(item.total_price.replaceAll(",",""))
              sumTotalPaid = sumTotalPaid + Number(item.total_paid.replaceAll(",",""))
              sumTotalCashonly = sumTotalCashonly +  Number(item.total_cashonly.replaceAll(",",""))
              sumTotalOverdue = sumTotalOverdue + Number(item.total_overdue.replaceAll(",",""))
              sumTotalDiscount = sumTotalDiscount +  Number(item.total_discount.replaceAll(",",""))
              sumTotalSendClaim = sumTotalSendClaim +  Number(item.total_send_claim.replaceAll(",",""))
              sumTotalAbsorbPrice = sumTotalAbsorbPrice +  Number(item.total_absorb_price.replaceAll(",",""))

              let subRow: any[] = [];
              (item.description_list_medical_fee || []).forEach((column: any) => {
                subRow.push({ text: column, border: [true, false, true, true] });
              });
              const row = [
                { text: index+1,alignment: "center", border: [true, true, true, true] },
                { text: `${item.hn || ""}\n${item.performed_date}`,alignment: "center", border: [true, true, true, true] }, // issue 69736
                { text: `${item.encounter_number || ""} (${item.encounter_id || ""})`,alignment: "center", border: [true, true, true, true] },
                { text: item.patient_name ,alignment: "left", border: [true, true, true, true] },
                { text: item.total_price || "0.00",alignment: "right", border: [true, true, true, true] },
                { text: item.total_paid || "0.00",alignment: "right", border: [true, true, true, true] },
                { text: item.total_overdue || "0.00",alignment: "right", border: [true, true, true, true] },
                { text: item.total_cashonly || "0.00",alignment: "right", border: [true, true, true, true] },
                { text: item.total_send_claim || "0.00",alignment: "right", border: [true, true, true, true] },
                { text: item.total_discount || "0.00",alignment: "right", border: [true, true, true, true] },
                { text: item.total_absorb_price || "0.00",alignment: "right", border: [true, true, true, true] },
                { text: item.coverage,alignment: "left", border: [true, true, true, true] },
                { text: subRow ,alignment: "left", border: [true, true, true, true] },
              ];
              body.push(row);

            })
            body.push([
              {
                text: `sub Total`,colSpan: 4 ,alignment: "center", border: [true, true, true, true] },
              "",
              "",
              "",
              { text: handleToString(sumTotalPrice || "0.00"),alignment: "right", border: [true, true, true, true] },
              { text: handleToString(sumTotalPaid || "0.00"),alignment: "right", border: [true, true, true, true] },
              { text: handleToString(sumTotalOverdue || "0.00"),alignment: "right", border: [true, true, true, true] },
              { text: handleToString(sumTotalCashonly || "0.00"),alignment: "right", border: [true, true, true, true] },
              { text: handleToString(sumTotalSendClaim || "0.00"),alignment: "right", border: [true, true, true, true] },
              { text: handleToString(sumTotalDiscount || "0.00"),alignment: "right", border: [true, true, true, true] },
              { text: handleToString(sumTotalAbsorbPrice || "0.00"),alignment: "right", border: [true, true, true, true] },
              "",
              "",
            ]);
          }
          grandTotalPrice =  grandTotalPrice + sumTotalPrice
          grandTotalPaid = grandTotalPaid + sumTotalPaid
          grandTotalCashonly = grandTotalCashonly + sumTotalCashonly
          grandTotalOverdue = grandTotalOverdue + sumTotalOverdue
          grandTotalDiscount = grandTotalDiscount + sumTotalDiscount
          grandTotalSendClaim = grandTotalSendClaim + sumTotalSendClaim
          grandTotalAbsorbPrice = grandTotalAbsorbPrice + sumTotalAbsorbPrice
        });
      }
    });
    body.push([
      {
        text: `Grand Total`,colSpan: 4 ,alignment: "center", border: [true, true, true, true] },
      "",
      "",
      "",
      { text: handleToString(grandTotalPrice || "0.00"),alignment: "right", border: [true, true, true, true] },
      { text: handleToString(grandTotalPaid || "0.00"),alignment: "right", border: [true, true, true, true] },
      { text: handleToString(grandTotalOverdue || "0.00"),alignment: "right", border: [true, true, true, true] },
      { text: handleToString(grandTotalCashonly || "0.00"),alignment: "right", border: [true, true, true, true] },
      { text: handleToString(grandTotalSendClaim || "0.00"),alignment: "right", border: [true, true, true, true] },
      { text: handleToString(grandTotalDiscount || "0.00"),alignment: "right", border: [true, true, true, true] },
      { text: handleToString(grandTotalAbsorbPrice || "0.00"),alignment: "right", border: [true, true, true, true] },
      { text: "", border: [false, false, false, false] },
      { text: "", border: [false, false, false, false] },
    ]);
    return body;
  };

  const findCoverage = (coverage: string) => {
    const insideBrackets = (coverage || "").match(/\[([^[\]]*)\]/g)?.map((str : any) => str.slice(1, -1));

    return insideBrackets?.[0] || coverage
  }

  // 5
  const tableReport = (data: any, columns: any) => {

    const groupData = data.map((field: any) => {
        if (field.items?.length > 0) {
          const groupByItem = field.items?.reduce((group: any, product:any) => {
            const { coverage } = product;
            group[coverage] = group[coverage] ?? [];
            group[coverage].push(product);
            return group;
          },{});
          const groupArrays = Object.keys(groupByItem).map((item:any) => {
            const drugName = item;
            return groupByItem[item];
          });
          return {items : groupArrays}
        } else {
          return {...field}
        }
    })
    return {
      table: {
        widths: ["3%", "6%", "5.5%", "*", "6%", "6%","6%", "6%", "6%", "6%", "8%", "9%", "22%"],
        headerRows: 1,
        body: generateTableBody(groupData, columns),
      },
      margin: [0, 0, 0, 0],
    };
  };

  const subTableTotals = (data: any, columns: any) => {
    return {
      id: "signature",
      margin: [0, 15, 0, 0],
      table: {
        widths: ["3%", "6%", "5.5%", "*", "6%", "6%","6%", "6%", "6%", "6%", "8%", "9%", "22%"],
        body: generateSubTableTotals(data, columns),
      },
    };
  };

  const generateSubTableTotals = (data: any, columns: any) => {
    let subBody: any[] = [];
    let grandTotalPrice = 0
    let grandTotalPaid = 0
    let grandTotalCashonly = 0
    let grandTotalOverdue = 0
    let grandTotalDiscount = 0
    let grandTotalSendClaim = 0
    let grandTotalAbsorbPrice = 0

    // Header of Table
    subBody.push([
      {
        text: `Summary`,colSpan: 4 ,alignment: "left", bold : true, border: [true, true, true, true] },
      "",
      "",
      "",
      { text: "ราคารวม" ,alignment: "right", bold : true, border: [true, true, true, true] },
      { text: "ชำระแล้ว" ,alignment: "right",  bold : true, border: [true, true, true, true] },
      { text: "ค้างชำระ",alignment: "right",  bold : true, border: [true, true, true, true] },
      { text: "เบิกไม่ได้" ,alignment: "right",  bold : true, border: [true, true, true, true] },
      { text: "เบิกได้",alignment: "right",  bold : true, border: [true, true, true, true] },
      { text: "ส่วนลด",alignment: "right",  bold : true, border: [true, true, true, true] },
      { text: "ส่วนลดตามสิทธิ",alignment: "right", bold : true, border: [true, true, true, true] },
      { text: "", border: [false, false, false, false] },
      { text: "", border: [false, false, false, false] },
    ]);

    (data || []).forEach((field: any) => {
      if (field.sub_totals?.length > 0) {
        field.sub_totals.forEach((row: any) => {

          if (row.coverage_name === "ทั้งหมด") {
            grandTotalPrice = Number(row.total_price.replaceAll(",",""))
            grandTotalPaid = Number(row.total_paid.replaceAll(",",""))
            grandTotalCashonly = Number(row.total_cashonly.replaceAll(",",""))
            grandTotalOverdue = Number(row.total_overdue.replaceAll(",",""))
            grandTotalDiscount = Number(row.total_discount.replaceAll(",",""))
            grandTotalSendClaim = Number(row.total_send_claim.replaceAll(",",""))
            grandTotalAbsorbPrice = Number(row.total_absorb_price.replaceAll(",",""))
          } else {
            const dataRow = [
              { text: row.coverage_name,alignment: "left",colSpan: 4, border: [true, true, true, true] },
              { text: "", border: [true, true, true, true] },
              { text: "", border: [true, true, true, true] },
              { text: "" , border: [true, true, true, true] },
              { text: row.total_price ,alignment: "right", border: [true, true, true, true] },
              { text: row.total_paid,alignment: "right", border: [true, true, true, true] },
              { text: row.total_overdue,alignment: "right", border: [true, true, true, true] },
              { text: row.total_cashonly,alignment: "right", border: [true, true, true, true] },
              { text: row.total_send_claim,alignment: "right", border: [true, true, true, true] },
              { text: row.total_discount,alignment: "right", border: [true, true, true, true] },
              { text: row.total_absorb_price,alignment: "right", border: [true, true, true, true] },
              { text: "",alignment: "left", border: [false, false, false, false]  },
              { text: "" ,alignment: "left", border: [false, false, false, false]  },
            ];
            subBody.push(dataRow);
          }
        });
      }
    });

    subBody.push([{
      text: `รวมทั้งสิ้น`,colSpan: 4 ,alignment: "left", bold : true, border: [true, true, true, true] },
    "",
    "",
    "",
    { text: handleToString(grandTotalPrice) ,alignment: "right", border: [true, true, true, true] },
    { text: handleToString(grandTotalPaid),alignment: "right", border: [true, true, true, true] },
    { text: handleToString(grandTotalOverdue),alignment: "right", border: [true, true, true, true] },
    { text: handleToString(grandTotalCashonly),alignment: "right", border: [true, true, true, true] },
    { text: handleToString(grandTotalSendClaim),alignment: "right", border: [true, true, true, true] },
    { text: handleToString(grandTotalDiscount) ,alignment: "right", border: [true, true, true, true] },
    { text: handleToString(grandTotalAbsorbPrice),alignment: "right", border: [true, true, true, true] },
    { text: "", border: [false, false, false, false] },
    { text: "", border: [false, false, false, false] },
    ]);

    return subBody;
  };

  const handleToString = (num:any) => {
    return num.toLocaleString("en-US", {
      style: "decimal",
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    })
  }

  return {
    header: (currentPage: any, pageCount: any) => {
      return {
        table: {
          widths: ["*", "23%", "23%"],
          headerRows: 1,
          body: [
            [
              {
                ...companyLogoForm ,
                absolutePosition: { x: 25, y: 25 },
                // width: 130,
                rowSpan: 2,
                border: [true, true, false, true],
              },
              {
                text: `รายงานค้างชำระค่ารักษาพยาบาล`,
                fontSize: 15,
                bold: true,
                alignment: "center",
                colSpan: 2,
                border: [true, true, true, true],
              },
              "",
            ],
            [
              "",
              {
                text: `ประจำวันที่ : ${props.reportOverDue?.params?.start_date || ""} - ${
                  props.reportOverDue?.params?.end_date || ""
                }`,
                fontSize: 12,
                alignment: "center",
                border: [true, true, true, true],
              },
              {
                text: `ผู้พิมพ์: ${props.reportOverDue?.params?.print_user || ""} `,
                fontSize: 12,
                alignment: "right",
                border: [true, true, true, true],
              },
            ],
            [
              {
                text: [
                  {
                    text: `วันที่พิมพ์: ${props.reportOverDue?.params?.print_date_time || ""} `,
                    fontSize: 12,
                    alignment: "right",
                    margin: [0, 0, 10, 0],
                  },
                  {
                    text: ` หน้า ` + currentPage.toString() + `/` + pageCount.toString(),
                    fontSize: 12,
                    alignment: "right",
                    margin: [10, 0, 0, 0],
                  },
                ],
                fontSize: 12,
                colSpan: 3,
                alignment: "right",
                border: [true, true, true, true],
              },
            ],
          ],
        },
        layout: {
          paddingLeft: (i: any, node: any) => {
            return 10;
          },
          paddingRight: (i: any, node: any) => {
            return 10;
          },
          paddingTop: (i: any, node: any) => {
            return 5;
          },
          paddingBottom: (i: any, node: any) => {
            return 5;
          },
        },
        margin: [15, 15, 15, 0],
      };
    },
    content: [
      tableReport(props.reportOverDue?.fields || [], []),
      subTableTotals(props.reportOverDue?.fields, []),
    ],
    images: {
      ...images,
    },
    pageOrientation: `landscape`,
    defaultStyle: {
      font: font,
      // fontSize: 14,
    },
    pageSize: `A4`,
    pageMargins: [15, 100, 15, 15],
    pageBreakBefore: (
      currentNode: any,
      followingNodesOnPage: any,
      nodesOnNextPage: any,
      previousNodesOnPage: any
    ) => {
      const isPageBreakClass = currentNode.id?.includes("signature");

      if (isPageBreakClass && !currentNode.id?.includes("last_content")) {
        const lastContent = [...followingNodesOnPage, ...nodesOnNextPage].find(
          (node: any) => node.id?.includes("last_content") && node.id?.includes(currentNode.id)
        );

        return lastContent?.pageNumbers?.toString() !== currentNode.pageNumbers.toString();
      }
    },
  };
}
