import React, { useMemo, useCallback } from "react";
import { Header } from "semantic-ui-react";

import moment from "moment";

// Framework
import { Table } from "react-lib/frameworks/Table";

// Utils
import { formatDatetime } from "react-lib/utils/dateUtils";
import { useIntl } from "react-intl";

const DrugActionLog = (props: any) => {
  const intl = useIntl();

  const parseJson = useCallback((jsonString: string) => {
    if (!jsonString) {
      return { note: "" };
    }

    try {
      const validJsonString = jsonString.includes("'")
        ? jsonString.replace(/'/g, '"')
        : jsonString;

      return JSON.parse(validJsonString);
    } catch (error) {
      return { note: jsonString };
    }
  }, []);

  const items = useMemo(() => {
    return props.data.map((item: any) => {
      const isValid = moment(item.datetime).isValid();

      return {
        ...item,
        userName: (
          <>
            {item?.order_receive_fullname && <>{item?.order_receive_fullname}/</>}
            {item.name}
          </>
        ),
        note: parseJson(item.note).note,
        datetime: !isValid ? item.datetime : formatDatetime(moment(item.datetime), true),
      };
    });
  }, [props.data]);

  return (
    <div style={{ padding: "10px" }}>
      <Header>{intl.formatMessage({ id: "ประวัติการทำงาน" })}</Header>
      <Table
        data={items}
        headers="User,Action,note,division,Date-Time"
        keys="userName,action,note,division,datetime"
        minRows="10"
        showPagination={false}
        style={{ height: "300px" }}
      ></Table>
    </div>
  );
};

export default React.memo(DrugActionLog);
