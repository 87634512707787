import moment from 'moment';
import _ from 'lodash';
import $ from 'jquery';
function getAge(birthdate) {
    // accept birthdate as moment() date object
    //   usually you can get from DateTextBox.getMomentValue()
    // Selected this solution: https://stackoverflow.com/a/33988284/554894
    //   if birthdate is today, this give "x year 0 month 0 day"
    if(birthdate.isValid()) {
        var today = moment().startOf('day')
        var years = today.diff(birthdate, 'year')
        birthdate.add(years, 'years')
        var months = today.diff(birthdate, 'months')
        birthdate.add(months, 'months')
        var days = today.diff(birthdate, 'days')
        return years + " ปี " + months + " เดือน " + days + " วัน";
    }
    return ""
}

function setCookie (cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie (cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function alignRight(val) {
    return '<div style="text-align: right; ">' + val + '</div>';
}

function alignCenter(val) {
    return '<div style="text-align: center; ">' + val + '</div>';
}

function formatPrice(val) {
    var value = formatComma(val);
    return '<div style="text-align: right; ">' + value + '</div>';
}

function formatShortDecimal(value) {
    if (typeof value === 'undefined') {
        return ''
    }
    var result = value.toString().split('.')

    var integer = result[0].replace(/^0*/, '') || '0'
    var fracture = (result[1]) ? result[1].replace(/0*$/, '') : ''

    if (!fracture) {
        return integer
    } else {
        return integer + '.' + fracture
    }
}

function formatComma(val){
    return !isNaN(parseFloat(val)) ? parseFloat(val).toFixed(2).toString().replace(/\B(?=(?:\d{3})+(?!\d))/g, ",") : val;
}

function formatDatetime(value){
    if (value) {
        var datetime = moment(value)
        var day = datetime.format('DD');
        var month = datetime.format('MM');
        var year = parseInt(datetime.format('YYYY')) + 543;
        var time = datetime.format('HH:mm');
        return day + '/' + month + '/' + year + ' ' + time
    } else {
        return ''
    }
}
function formatDate(value){
    if (value) {
        var datetime = moment(value)
        var day = datetime.format('DD');
        var month = datetime.format('MM');
        var year = parseInt(datetime.format('YYYY')) + 543;
        return day + '/' + month + '/' + year
    } else {
        return ''
    }
}
function formatBEDatetime(value){
    //Ex. value = '2562-08-08T15:19:37'
    if (value) {
        var datetime = moment(value)
        var day = datetime.format('DD');
        var month = datetime.format('MM');
        var year = parseInt(datetime.format('YYYY'));
        var time = datetime.format('HH:mm');
        return day + '/' + month + '/' + year + ' ' + time
    } else {
        return ''
    }
}
function formatBEDate(value){
    if (value) {
        var datetime = moment(value)
        var day = datetime.format('DD');
        var month = datetime.format('MM');
        var year = parseInt(datetime.format('YYYY'));
        return day + '/' + month + '/' + year
    } else {
        return ''
    }
}
function formatTime(value){
    if (value) {
        return moment(value).format('HH:mm');
    } else {
        return ''
    }
}
/**Format datetime for sending to server
* The date_value should be DD/MM/YYYY (Default from DateTextBox component)
* The time_value should be HH:MM (Default from TimeTextBox component)
*/
function formatDateTime(date_value, time_value) {
    var date = date_value.split('/')
    date[2] = (parseInt(date[2])-543).toString()
    return date[2] + '-' + date[1] + '-' + date[0] + 'T' + time_value
}
function parseDate(value){
    if (value) {
        var date = value.split('/')
        date[2] = (parseInt(date[2])-543).toString()
        return moment(date[2] + '-' + date[1] + '-' + date[0])
    } else {
        return
    }
}

function getUrlParameters(urlString,preventDecode){
    var paramString = ''
    if(urlString.indexOf('?')!=-1) { // check url in format http://xxxxxxx?xx=xx or ?xx=xx
        paramString = urlString.split('?')[1];
    } else {
        paramString = urlString;
    }

    var queryDict = {};
    paramString.split("&").forEach(function(item, index) {
        var tmp = item.split('=')
        if(preventDecode) {
            queryDict[tmp[0]] = tmp[1];
        }
        else{
            queryDict[tmp[0]] = decodeURIComponent(tmp[1]);
        }
    });
    return queryDict;
}

function openWindowAtCenter(url,width,height,callback) {
    var left = (window.screen.width - width) / 2;
    var top = (window.screen.height - height) / 4;
    var position = ',left='+left+',top='+top
    var size = ',width='+width+',height='+height
    var win = window.open(url, '_blank', 'scrollbars=yes,status=yes'+position+size);
    win.onunload = callback
}

function openNewTab(url,callback) {
    var win = window.open(url, '_blank')
    win.onunload = callback
}

function getSemanticColor(color, alpha) {
    var div = document.createElement("div");
    div.classList.add('ui')
    div.classList.add('button')
    div.classList.add(color)
    div.setAttribute('style', 'display:none;')
    window.document.body.appendChild(div)
    var color = window.getComputedStyle(div).backgroundColor // this should be rgb color convention.
    window.document.body.removeChild(div)

    alpha = (alpha === undefined) ? 1 : alpha
    color = color.replace('rgb','rgba')
    color = color.replace(')',', ' + alpha + ')')
    return color
}

// suggested: https://davidwalsh.name/javascript-debounce-function
function debounce(func, wait, immediate) {
    var timeout
    return function () {
        var context = this, args = arguments
        var later = function () {
            timeout = null
            if (!immediate) func.apply(context, args)
        };
        var callNow = immediate && !timeout
        clearTimeout(timeout)
        timeout = setTimeout(later, wait)
        if (callNow) func.apply(context, args)
    }
}

// suggested by: https://stackoverflow.com/a/22395463
function arrCompare(array1, array2) {
    array1 = array1.slice().sort()
    array2 = array2.slice().sort()
    var sameLength = array1.length == array2.length
    var sameElement = array1.every(function (element, index) {
        return element === array2[index]
    })
    return sameLength && sameElement
}

// only primitive type
function compareProps(obj1, obj2, strict) {
    var result = true
    for (var prop in obj1) {
        if (obj1.hasOwnProperty(prop)) {
            if (!strict) {
                if (obj1[prop] != obj2[prop]) {
                    result = false
                }
            } else {
                if (obj1[prop] !== obj2[prop]) {
                    result = false
                }
            }
        }
    }
    return result
}

/**
 *
 * @param {string} base64
 */
function displayPDF(base64) {
    var parameters = 'toolbar=0,location=0,menubar=0'
    parameters += ',height=' + (window.screen.height - 20)
    parameters += ',width=' + window.screen.width
    var html = '' +
    '<html>' +
        '<head>'  +
            '<style>' +
                'html, body {' +
                    'margin: 0;' +
                    'padding: 0;' +
                    'border: 0;' +
                    'height: 100%;' +
                    'overflow: hidden;' +
                '}' +
            '</style>' +
        '</head>' +
        '<body>'  +
            '<object height="100%" width="100%"' +
                'data="data:application/pdf;base64,' + base64 + '"' +
                'type="application/pdf">' +
            '</object>' +
        '</body>' +
    '</html>'
    var win = window.open('', '', parameters);
    if (!win) {
        alert('กรุณากดอนุญาติการเปิด popup', 'red')
    } else {
        win.document.write(html);
    }
}

/**
 *
 * Normal:
 *  Util.alert('test', 'black', 'ok', null, function() {console.log('hide')}, 'text in content')
 * Toast:
 *  Util.alert('test', 'red', 'ok', 2000, function() {console.log('toast')})
 * Object at the first argument
 *  Util.alert({
 *      titleName: 'test object',
 *      buttonText: 'OK'
 *  })
 * @param {string | object} titleName
 * @param {string} titleColor
 * @param {string} buttonText
 * @param {integer} duration
 * @param {function} onHide
 * @param {string} textContent
 */
function alert(titleName, titleColor, buttonText, duration, onHide, textContent) {
    if (typeof titleName === 'object') {
        titleColor = titleName.titleColor
        buttonText = titleName.buttonText
        duration = titleName.duration
        onHide = titleName.onHide
        textContent = titleName.textContent
        titleName = titleName.titleName
    }

    function __callOnCompleted(child) {
        child.Component.completed()
        const QMLBaseObject = window.QmlWeb.getConstructor("QtQml", "2.0", "QtObject")
        for (var i = 0; i < child.$tidyupList.length; i++) {
            if (child.$tidyupList[i] instanceof QMLBaseObject) {
                __callOnCompleted(child.$tidyupList[i])
            }
        }
    }
    function __removeChildProperties(child) {
        var signals = window.QmlWeb.engine.completedSignals
        signals.splice(signals.indexOf(child.Component.completed), 1)
        for (var i = 0; i < child.children.length; i++) {
            this.__removeChildProperties(child.children[i])
        }
    }
    var promise
    if (typeof onHide !== 'function') {
        promise = new Promise(function(resolve, reject){
            onHide = function() {
                resolve()
            }
        })
    }
    $(function(){
        var modal = window.Qt.createComponent("/static/qml/Common/ModInfo.qml")
        modal = modal.$createObject()
        if (window.QmlWeb.engine.firstCallCompleted) {
            __callOnCompleted(modal)
        }
        if (window.QmlWeb.engine.operationState !== window.QMLOperationState.Init) {
            window.QmlWeb.engine.$initializePropertyBindings()
        }
        modal.titleName = titleName || modal.titleName
        modal.textContent = textContent || modal.textContent
        modal.titleColor = titleColor || modal.titleColor
        modal.buttonText = buttonText || modal.buttonText
        modal.approved.connect(modal, function() {
            modal.hide()
        })
        modal.hidden.connect(modal, function () {
            modal.$delete()
            __removeChildProperties(modal)
            onHide()
        })
        if (duration > 0) {
            setTimeout(modal.toast.bind(modal, duration))
        } else {
            setTimeout(modal.show.bind(modal))
        }
    })
    return promise
}

function success(textContent) {
    return alert({
        textContent: textContent,
        titleName: '-',
        titleColor: 'green',
    })
}
function danger(textContent) {
    return alert({
        textContent: textContent,
        titleName: _('แจ้งเตือน'),
        titleColor: 'red',
    })
}
function info(textContent) {
    return alert({
        textContent: textContent,
        titleName: '-',
        titleColor: 'blue',
    })
}
function prompt(textContent) {
    return new Promise(function(resolve, reject){
        confirm({
            textContent: textContent,
            titleName: '-',
            titleColor: 'yellow',
            onApprove: function(){
                resolve()
            },
            onDeny: function(){
                reject()
            },
        })
    })
}
/**
 * Util.confirm('test', 'orange', 'text content', function() {console.log('approve')}, function() {console.log('deny')})
 *
 * Object at the first argument
 *
 * Util.confirm({
 *     titleName: 'test object',
 *     buttonText: 'OK'
 * })
 * @param {string | object} titleName
 * @param {string} titleColor
 * @param {string} textContent
 * @param {function} onApprove
 * @param {function} onDeny
 * @param {string} approveButtonText
 * @param {string} denyButtonText
 * @param {string} approveButtonColor
 * @param {string} denyButtonColor
 */
function confirm(
    titleName,
    titleColor,
    textContent,
    onApprove,
    onDeny,
    approveButtonText,
    denyButtonText,
    approveButtonColor,
    denyButtonColor) {

    if (typeof titleName === 'object') {
        titleColor = titleName.titleColor
        textContent = titleName.textContent
        onApprove = titleName.onApprove
        onDeny = titleName.onDeny
        approveButtonText = titleName.approveButtonText
        denyButtonText = titleName.denyButtonText
        approveButtonColor = titleName.approveButtonColor
        denyButtonColor = titleName.denyButtonColor
        titleName = titleName.titleName
    }

    function __callOnCompleted(child) {
        child.Component.completed()
        const QMLBaseObject = window.QmlWeb.getConstructor("QtQml", "2.0", "QtObject")
        for (var i = 0; i < child.$tidyupList.length; i++) {
            if (child.$tidyupList[i] instanceof QMLBaseObject) {
                __callOnCompleted(child.$tidyupList[i])
            }
        }
    }
    function __removeChildProperties(child) {
        var signals = window.QmlWeb.engine.completedSignals
        signals.splice(signals.indexOf(child.Component.completed), 1)
        for (var i = 0; i < child.children.length; i++) {
            this.__removeChildProperties(child.children[i])
        }
    }

    if (typeof onApprove !== 'function') {
        onApprove = function () { }
    }
    if (typeof onDeny !== 'function') {
        onDeny = function () { }
    }

    $(function () {
        var modal = window.Qt.createComponent("/static/qml/Common/ModConfirm.qml")
        modal = modal.$createObject()
        if (window.QmlWeb.engine.firstCallCompleted) {
            __callOnCompleted(modal)
        }
        if (window.QmlWeb.engine.operationState !== window.QMLOperationState.Init) {
            window.QmlWeb.engine.$initializePropertyBindings()
        }

        modal.titleName = titleName || modal.titleName
        modal.titleColor = titleColor || modal.titleColor
        modal.textContent = textContent || modal.textContent
        modal.approveButtonText = approveButtonText || modal.approveButtonText
        modal.denyButtonText = denyButtonText || modal.denyButtonText
        modal.approveButtonColor = approveButtonColor || modal.approveButtonColor
        modal.denyButtonColor = denyButtonColor || modal.denyButtonColor

        modal.approve.connect(modal, function () {
            modal.hide()
            onApprove()
        })
        modal.deny.connect(modal, function () {
            modal.hide()
        })

        modal.hidden.connect(modal, function () {
            modal.$delete()
            __removeChildProperties(modal)
            onDeny()
        })

        setTimeout(modal.show.bind(modal))
    })
}

/**
 *
 *  Util.scan(function(base64Image) {
 *  }, function() {
 *  })
 *
 * @param {function} onSuccess
 * @param {function} onFail
 * @param {integer} AGENT_PORT
 */
function scan(onSuccess, onFail, AGENT_PORT) {
    if(window.DJANGO.TEST_SCANNED_IMAGE !== undefined) {
        onSuccess('data:text/xml;base64,' + window.DJANGO.TEST_SCANNED_IMAGE)
        return
    }
    AGENT_PORT = AGENT_PORT || 5555
    if (typeof onSuccess !== 'function') {
        onSuccess = function() {}
    }
    if (typeof onFail !== 'function') {
        onFail = function() {}
    }

    $.get('http://localhost:' + AGENT_PORT + '/scanner/isAvailable', function(result) {
        if (result.available === true) {
            _doScan()
        }
        else {
            onFail(_('เครื่องสแกนไม่พร้อมใช้งาน'))
        }
    }, 'json').fail(function() {
        onFail(_('ไม่สามารถติดต่อเครื่องสแกนได้'))
    })

    function _doScan() {
        $.get('http://localhost:' + AGENT_PORT + '/scanner/scan', function(result) {
            if (result.success === true) {
                _toDataURL('http://localhost:' + AGENT_PORT + '/scanner/latestImage', function(dataUrl) {
                    onSuccess(dataUrl)  // send data as base64 image
                })
            }
            else {
                onFail(_('สแกนไม่สำเร็จ'))
            }
        }, 'json')
    }

    function _toDataURL(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function() {
            var reader = new FileReader();
            reader.onloadend = function() {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }
}

function smartcard(onSuccess, onFail, AGENT_PORT) {
    AGENT_PORT = AGENT_PORT || 5555
    if (typeof onSuccess !== 'function') {
        onSuccess = function() {}
    }
    if (typeof onFail !== 'function') {
        onFail = function() {}
    }

    $.get('http://localhost:' + AGENT_PORT + '/smartcard/isAvailable', function(result) {
        if (result.available === true) {
            _doReadSmartCard()
        }
        else {
            // Smart card service is not running
            if(result.cause === "no_service") {
                onFail(_('Smart Card Service บน Windows ยังไม่เปิดใช้งาน กรุณาเปิดใช้งานที่ Control Panel -> Services ค้นหา "Smart Card" แล้วคลิกขวาเลือก Start'))
            }
            else if(result.cause === "no_reader") {
                onFail(_('ไม่สามารถเชื่อมต่อเครื่องอ่าน Smart Card'))
            }
            else {
                onFail(_('เกิดข้อผิดพลาดในการอ่าน Smart Card : Unknown Error'))
            }
        }
    }, 'json').fail(function() {
        onFail(_('ไม่สามารถเชื่อมต่อ CAgent Service'))
    })

    function _doReadSmartCard() {
        $.get('http://localhost:' + AGENT_PORT + '/smartcard/read', function(result) {
            if (result.success === true) {
                onSuccess(result)
            }
            else {
                if(result.cause.indexOf("RemovedCard") !== -1) {
                    onFail(_('กรุณาเสียบบัตร Smart Card'))
                }
                else {
                    onFail(_('เกิดข้อผิดพลาดในการอ่าน Smart Card : ' + result.cause))
                }
            }
        }, 'json').fail(function() {
            onFail(_('ไม่สามารถเชื่อมต่อ CAgent Service'))
        })
    }
}

function padZero(str, len) {
    str = String(str)
    while(str.length < len) {
        str = '0'+str
    }
    return str
}

function dateNow(){
    var date = moment().format('DD/MM/YYYY').split('/')
    date[2] = (parseInt(date[2])+543).toString()
    return date.join('/')
}

// Get date string of past date on X days
function datePast(number_of_days) {
    var date = moment().subtract(number_of_days, 'days').format('DD/MM/YYYY').split('/')
    date[2] = (parseInt(date[2])+543).toString()
    return date.join('/')
}

// Get date string of future date on X days
function dateFuture(number_of_days) {
    var date = moment().add(number_of_days, 'days').format('DD/MM/YYYY').split('/')
    date[2] = (parseInt(date[2])+543).toString()
    return date.join('/')
}

// suggested by: https://developer.mozilla.org/en/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
// base64encode implementation is not the same as suggested
// becase backend side doesn't converts the percent encodings into raw bytes before doing base64 encoding
function b64EncodeUnicode(str) {
    return btoa(encodeURIComponent(str))
}

function b64DecodeUnicode(str) {
    return decodeURIComponent(atob(str))
}

/**
* Keyboard code and character mappings
*/
var KEY_CODE = {
    BACK_SPACE:  8,
    TAB:         9,
    ENTER:       13,
    SHIFT:       16,
    CTRL:        17,
    ALT:         18,
    BREAK:       19,
    CAPS_LOCK:   20,
    ESC:         27,
    SPACE:       32,
    PAGE_UP:     33,
    PAGE_DOWN:   34,
    END:         35,
    HOME:        36,
    ARROW_LEFT:  37,
    ARROW_UP:    38,
    ARROW_RIGHT: 39,
    ARROW_DOWN:  40,
    INSERT:      45,
    DELETE:      46,
    0:   48,
    1:   49,
    2:   50,
    3:   51,
    4:   52,
    5:   53,
    6:   54,
    7:   55,
    8:   56,
    9:   57,
    a:   65,
    b:   66,
    c:   67,
    d:   68,
    e:   69,
    f:   70,
    g:   71,
    h:   72,
    i:   73,
    j:   74,
    k:   75,
    l:   76,
    m:   77,
    n:   78,
    o:   79,
    p:   80,
    q:   81,
    r:   82,
    s:   83,
    t:   84,
    u:   85,
    v:   86,
    w:   87,
    x:   88,
    y:   89,
    z:   90,
    NUM_0:    96,
    NUM_1:    97,
    NUM_2:    98,
    NUM_3:    99,
    NUM_4:    100,
    NUM_5:    101,
    NUM_6:    102,
    NUM_7:    103,
    NUM_8:    104,
    NUM_9:    105,
    MULTIPLY: 106,
    ADD:      107,
    SUBTRACT: 109,
    POINT:    110,
    DIVIDE:   111,
    f1:  112,
    f2:  113,
    f3:  114,
    f4:  115,
    f5:  116,
    f6:  117,
    f7:  118,
    f8:  119,
    f9:  120,
    f10: 121,
    f11: 122,
    f12: 123,
    NUM_LOCK:        144,
    SCROLL_LOCK:     145,
    SEMI_COLON:      186,
    EQUAL:           187,
    COMMA:           188,
    DASH:            189,
    PERIOD:          190,
    SLASH:           191,
    GRAVE_ACCENT:    192,
    OPEN_BRACKET:    219,
    BACK_SLASH:      220,
    CLOSE_BRACKET:   221,
    SINGLE_QUOTE:    222
}

var ENCOUNTER_STATUS_COLOR = {
    WAIT_TRIAGE: 'grey',
    ARRIVING: 'red',
    ARRIVED: 'orange',
    SCREENED: 'yellow',
    IN_QUEUE: 'olive',
    IN_EXAM: 'green',
    WAIT_LAB: 'purple',
    WAIT_TREATMENT: 'purple',
    RECEIVE_RESULT: 'blue',
    IN_TREATMENT: 'violet',
    CHECKED_OUT: 'teal',
    WAIT_PAY: 'blue',
    WAIT_DISPENSE: 'pink',
    DISCHARGED: 'black',
    WAIT_RESULT: 'brown'
}

var TRIAGE_COLOR = {
    1: 'purple',
    2: 'red',
    3: 'orange',
    4: 'yellow',
    5: 'green'
}

var TRIAGE_DESC = {
    1: 'Life Threatening',
    2: 'Emergency',
    3: 'Urgency',
    4: 'Semi-Urgency',
    5: 'ทั่วไป',
}

var DOCTOR_ORDER_ACRONYM = {
    'centrallaborder': 'L',
    'treatmentorder': 'T',
    'admitorder': 'A',
    'doctorconsultorder': 'C',
    "drugorder": "RX",
    'drugopdhomeorder': 'RX',
    'drugsellingorder': 'RX',
    'drugrefillorder': 'RX',
    'drugstatorder': 'RX',
    'drugonedoseorder': 'RX',
    'drugcontinueorder': 'RX',
    'drugcontinueissue': 'RX',
    'drugipdhomeorder': 'RX',
    'drugpickingorder': 'RX',
    "drugcontinueplan": "RX",
    "drugonedayorder": "RX",
    "druginjectionorder": "RX",
    'drugresuscitationorder': 'RX',
    'drugscanorder': 'RX',
    'drugoperatingorder': 'RX',
    'druganesthesiaorder': "RX",
    'supplyopdhomeorder': 'MS',
    'supplyopdrefillorder': 'MS',
    'supplystatorder': 'MS',
    'supplycostcenterorder': 'MS',
    'supplyipdhomeorder': 'MS',
    'supplyipdrefillorder': 'MS',
    "supplyonedayorder": "MS",
    'supplyorder': "MS",
    'imagingorder': 'X',
    'operatingorder': 'O',
    'hdrequestitem': 'H',
    'anesthesiaorder': "ANES",
}

var DOCTOR_ORDER_BG_COLOR = {
    APPOINTMENT: 'grey',
    PENDING: 'yellow',
    PERFORMED: 'green',
    CANCEL: 'red',
    VERIFY: "green",
    DRAFT: 'grey',
    PLANNING: 'yellow',
    OFF: 'grey',
}

const TREATMENT_ORDER_STATUS_COLOR = {
  ORDERED: "olive",
  ARRIVED: "green",
  PERFORMED: "brown",
  CANCELED: "grey",
};

/**
 * @param {Array} array of array with 2 element of string
 * INPUT:
 *     [
 *         ['AAA', 'BB'],
 *         ['DD', 'CCCCC'],
 *         ['EEEEE', 'FFFFFFF'],
 *     ]
 *
 * @return {String} string of html which array is formatted
 * OUTPUT (in dom):
 *     AAA        BB
 *     DD      CCCCC
 *     EEEEE FFFFFFF
 * OUTPUT (html):
 *     <p><span style="float:left;">AAA</span><span style="float:right;">BB</span></p><br/>
 *     <p><span style="float:left;">DD</span><span style="float:right;">CCCCC</span></p><br/>
 *     <p><span style="float:left;">EEEEE</span><span style="float:right;">FFFFFFF</span></p><br/>
 */
function generateLRParagraph(array) {
    return array.reduce(function(text, item){
        return text + '<p><span style="float:left;">{0}</span><span style="float:right;">{1}</span></p><br/>'.format(item[0], item[1])
    }, '')
}

/**
 * Get name of window.screen without / and .qml
 * @param {string} path of current window
 */
function getScreenName(path) {
    return path.slice(path.lastIndexOf('/') + 1, path.lastIndexOf('.qml'))
}

function buildButton(icon, text, color){
    var iconColor
    var buttonNode = document.createElement('button')
    buttonNode.classList.add('ui', 'button', 'mini')
    if (color) {
        color = color.split(' ')
        if (color[0] == 'icon') {
            iconColor = color[1]
        } else {
            buttonNode.classList.add.apply(buttonNode.classList, color)
        }
    }
    if (icon) {
        var iconNode = document.createElement('i')
        iconNode.classList.add('icon')
        iconNode.classList.add.apply(iconNode.classList, icon.split(' '))
        buttonNode.classList.add('icon')
        buttonNode.appendChild(iconNode)
        if (iconColor) {
            iconNode.classList.add(iconColor)
        }
    }
    if (text) {
        var textNode = document.createElement('span')
        textNode.innerHTML = text
        buttonNode.appendChild(textNode)
    }
    if (icon && !text) {
        buttonNode.classList.add('circular')
    }
    if (text && text.length == 1 && !icon) {
        buttonNode.classList.remove('button', 'mini')
        buttonNode.classList.add('label', 'circular')
    }
    if (icon && text) {
        textNode.style.marginLeft = '5px'
    }
    return buttonNode
}

function showTooltip(targetDom, tooltip, text) {
    if (! targetDom.isSameNode(tooltip.target.dom)) {
        tooltip.target = {dom:targetDom}
        tooltip.text = text
        tooltip.show()
    }
}

function uuid(){
    // suggested by: http://stackoverflow.com/a/2117523 : RFC4122
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
        return v.toString(16);
    });
}

export {danger,         getUrlParameters, displayPDF, getSemanticColor, ENCOUNTER_STATUS_COLOR,         TRIAGE_COLOR, TRIAGE_DESC, DOCTOR_ORDER_ACRONYM, DOCTOR_ORDER_BG_COLOR,         TREATMENT_ORDER_STATUS_COLOR};
