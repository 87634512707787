import React, { useEffect, useState, SyntheticEvent, useRef, useMemo } from "react";
import { Button, Modal, Embed, Header, Label, Pagination } from "semantic-ui-react";
import moment from "moment";
import CONFIG from "config/config";

import CardTelemedRecordUX from "./CardTelemedRecordUX";
import { beToAd, formatDate } from "react-lib/utils";

import { firebaseApp, db } from "react-lib/frameworks/Firebase";
import { FieldPath, getFirestore } from "firebase/firestore";
import { getStorage, ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { collection, getDocs, query, where, orderBy, limit, startAfter , endBefore } from "firebase/firestore";
import { useIntl } from "react-intl";

console.log("initial moment locale en week dow 1")
moment.locale("en", { week: { dow: 1 } });

const CardTelemedRecord = (props: any) => {
  const intl = useIntl();
  const [startDate, setStartDate] = useState(formatDate(moment()));
  const [endDate, setEndDate] = useState(formatDate(moment().add(1, "days")));
  const [callSessions, setCallSessions] = useState([]);
  const [modalPlayer, setModalPlayer] = useState(false);
  const [streamUrl, setStreamUrl] = useState("");
  const [searchData, setSearchData] = useState<any>({});
  const [activePage, setActivePage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [firstVisibleDoc, setFirstVisibleDoc] = useState<any>({});
  const [lastVisibleDoc, setLastVisibleDoc] = useState<any>({});
  const pageSize = 10;

  const generateSignedURL = async (filePath: string) => {
    try {
      props.controller.storage
        .refFromURL("gs://" + CONFIG.WEB_RTC_BUCKET)
        .child(filePath)
        .getDownloadURL()
        .then((url: string) => {
          setStreamUrl(url);
          setModalPlayer(true);
        });
    } catch (error) {
      console.log("generateSignedURL", error);
    }
  };

  const getAllCallSessions = async (action:string) => {
    const startTimestamp = moment(beToAd(startDate)).toDate();
    let endTimestamp = moment(beToAd(endDate)).toDate();
    endTimestamp.setHours(23)
    endTimestamp.setMinutes(59)
    endTimestamp.setSeconds(59)
    console.log(startTimestamp, endTimestamp)
    try {
      let q = query(
        collection(db, "webrtc_sessions"),
        where("timestamp", ">=", startTimestamp),
        where("timestamp", "<=", endTimestamp),
        orderBy("timestamp", "desc")
      );

      if (searchData?.data?.patient != "") {
        q = query(q, where("patient_id", "==", parseInt(searchData?.data?.patient)));
      }

      if (searchData?.data?.en != "") {
        q = query(q, where("encounter_id", "==", parseInt(searchData?.data?.en)));
      }

      if (searchData?.data?.division != "") {
        q = query(q, where("division_id", "==", parseInt(searchData?.data?.division)));
      }

      // If there is a last document from the previous page, start after it
      if (action === "next") {
        q = query(q, startAfter(lastVisibleDoc));
      } else if (action === "prev") { // If there is a first document from the current page, start at it for the previous page
        q = query(q, endBefore(firstVisibleDoc));
      } else if (action === "reset") {
        let allDocs = await getDocs(q);
        // Filter by username after retrieving the data
        // newData = newData.filter(doc => {
        //   const username = doc.username;
        //   return username.includes(searchData?.data?.username);
        // });

        // Calculate total pages
        const totalDocuments = allDocs.docs.length;
        const totalPages = Math.ceil(totalDocuments / pageSize);
        setTotalPages(totalPages)

        // Calculate current page
        const currentPage = lastVisibleDoc ? totalPages - Math.ceil((totalDocuments - allDocs.docs.length) / pageSize) : 1;
      }

      // Limit the number of documents per page
      q = query(q, limit(pageSize));

      // Fetch the documents
      let querySnapshot = await getDocs(q);

      // Store the first and last visible documents for pagination
      setFirstVisibleDoc(querySnapshot.docs[0]);
      setLastVisibleDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);

      let newData = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          ...data,
          id: doc.id,
          username: data.username ? data.username : data.qos?.username,
          duration: data.qos?.duration,
          timestamp: data.timestamp?.toDate()?.toLocaleString(),
        };
      });
      newData = newData.filter((d: any) => d.recpath);

      setCallSessions(newData);
      console.log(newData);
    } catch (error) {
      console.error("Error fetching call sessions:", error);
    }
  };

  useEffect(() => {
    setSearchData({
      ...searchData,
      data: {
        ...searchData.data,
        en: "",
        patient: "",
        division: "",
        username: "",
      },
    });

    // Fetch call sessions from Firebase
    getAllCallSessions("reset")
      .then((r) => {
        console.log(r);
      })
      .catch((error) => console.error("Error fetching call sessions:", error));
  }, []);

  const columns: any[] = [
    // {
    //   Header: "session",
    //   accessor: "sessionId",
    //   getFooterProps: () => ({ style: { border: "0px" } }),
    //   Cell: (props: any) => (
    //     <span
    //       className="code"
    //       style={{ display: "flex"}}
    //     >
    //       {props.value}
    //     </span>
    //   ),
    //   width: 350,
    // },
    { Header: "timestamp", accessor: "timestamp" },
    { Header: "username", accessor: "username" },
    { Header: "url", accessor: "referrer", width: 250 },
    { Header: "channel_id", accessor: "channel_id", width: 120 },
    { Header: "encounter_id", accessor: "encounter_id", width: 120 },
    { Header: "patient_id", accessor: "patient_id", width: 120 },
    { Header: "division_id", accessor: "division_id", width: 120 },
    {
      Header: "recpath",
      accessor: "recpath",
      Cell: (props: any) => (
        <span className="case" style={{ display: "flex", justifyContent: "center" }}>
          {props.value}
        </span>
      ),
      width: 350,
    },
    {
      Header: "duration (seconds)",
      accessor: "duration",
      Cell: (props: any) => (
        <span className="case" style={{ display: "flex", justifyContent: "center" }}>
          {props.value}
        </span>
      ),
      width: 100,
    },
  ];

  const handleChangeData = (_event: any, data: any) => {
    setSearchData({
      ...searchData,
      data: {
        ...searchData.data,
        [data.name]: data.value,
      },
    });
    console.log(searchData);
  };

  const searchClick = () => {
    getAllCallSessions("reset");
  };

  const handlePageChange = (event: SyntheticEvent, data: any) => {
    setActivePage(data.activePage);
  };

  return (
    <div style={{ height: "90vh", width: "100%" }}>
      <div style={{ margin: "5px 25px", borderBottom: "1px solid grey" }}></div>

      <Modal size={"large"} closeIcon open={modalPlayer} onClose={() => setModalPlayer(false)}>
        <Header>
          {`Player`}
          {/* <Label color="yellow">{streamUrl}</Label> */}
        </Header>
        <Modal.Content>
          <Embed active={modalPlayer} icon="arrow circle down" id="90Omh7_I8vI" url={streamUrl} />
        </Modal.Content>
      </Modal>

      <CardTelemedRecordUX
        recColumns={columns}
        recData={callSessions}
        tableStyle={{ zoom: "95%" }}
        startDate={startDate}
        endDate={endDate}
        onChangeStartDate={(date: any) => setStartDate(date)}
        onChangeEndDate={(date: any) => setEndDate(date)}
        onSearchClick={searchClick}
        handleChangeData={handleChangeData}
        searchData={searchData}
        telemedTable={
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "baseline",
            }}
          >
            <Pagination
              activePage={activePage}
              boundaryRange={0}
              onPageChange={handlePageChange}
              ellipsisItem={null}
              firstItem={null}
              lastItem={null}
              siblingRange={1}
              totalPages={totalPages}
            />
          </div>
        }
        boxTableRowProps={(state: any, rowInfo: any, column: any, instance: any) => ({
          style: {
            backgroundColor:
              rowInfo?.original?.id &&
              rowInfo?.original?.id === props.DrugResuscitationSequence?.selectedBox?.id
                ? "#cccccc"
                : "white",
          },
          onClick: () => {
            let obj = rowInfo?.original;
            generateSignedURL(obj.recpath);
          },
        })}
        languageUX={props.languageUX}
      />
    </div>
  );
};

export default CardTelemedRecord;
