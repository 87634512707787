import React from "react";
import CardLayout from "../common/CardLayout";
import ReactTable from "react-table-6";
import PropTypes from "prop-types";
import PatientIdentification from "../common/PatientIdentification";
import { Grid, Segment } from "semantic-ui-react";

import axios from "axios";
import { useIntl } from "react-intl";

const FrameChrome = ({ file }) => {
  if (file) {
    let frame =
      '<iframe src="' + file + '" width="100%" height="100%" ></iframe>';
    return (
      <div
        style={{ width: "100%", height: "100%" }}
        dangerouslySetInnerHTML={{ __html: frame }}
      />
    );
  } else {
    return <></>;
  }
};

class CardLabReport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: 0,
      pdfFile: null,
    };
  }

  componentDidMount() {
    this.props.getReportList({ cloi: this.props.documentId });
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      this.props.reportDetail?.reports?.length &&
      prevProps.reportDetail?.reports != this.props.reportDetail?.reports &&
      prevState.pdfFile === null &&
      this.state.pdfFile === null
    ) {
      this.handleSelectRow({
        index: 0,
        original: { report_pdf: this.props.reportDetail.reports[0].report_pdf },
      });
    }
  }

  handleSelectRow = (rowInfo: any) => {
    this.setState({
      pdfFile: rowInfo.original.report_pdf,
      selected: rowInfo.index,
    });
  };

  handleClose = () => {
    this.props.hideCallback?.();
  };

  render() {
    return (
      <CardLayout
        titleText="ดูผล LAB (PDF)"
        onClose={this.handleClose}
        toggleable={false}
      >
        <PatientIdentification patientInfo={this.props.patientInfo} />
        <Grid>
          <Grid.Row>
            <Grid.Column width={4}>
              <ReactTable
                loading={this.props.isLoading}
                data={
                  this.props.reportDetail &&
                  this.props.reportDetail.reports &&
                  this.props.reportDetail.reports.length
                    ? this.props.reportDetail.reports
                    : []
                }
                showPagination={false}
                noDataText="ไม่มีข้อมูล"
                minRows={15}
                pageSize={
                  this.props.reportDetail &&
                  this.props.reportDetail.reports &&
                  this.props.reportDetail.reports.length
                    ? this.props.reportDetail.reports.length
                    : 10
                }
                style={{ backgroundColor: "#FFFFFF", height: "400px" }}
                columns={[
                  {
                    Header: "ชื่อเอกสาร",
                    accessor: "report_datetime",
                    style: { whiteSpace: "unset" },
                  },
                ]}
                getTrProps={(state, rowInfo) => {
                  if (rowInfo && rowInfo.row) {
                    return {
                      onClick: (e) => {
                        this.handleSelectRow(rowInfo);
                      },
                      style: {
                        background:
                          rowInfo.index === this.state.selected
                            ? "#00afec"
                            : "white",
                        color:
                          rowInfo.index === this.state.selected
                            ? "white"
                            : "black",
                      },
                    };
                  } else {
                    return {};
                  }
                }}
              />
            </Grid.Column>
            <Grid.Column width={12}>
              <Segment
                id="container"
                style={{ height: "480px" }}
                className="grey inverted"
              >
                <FrameChrome file={this.state.pdfFile} />
                {/* <FrameChrome file="http://localhost:3000/static/misc/test.pdf" /> */}
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </CardLayout>
    );
  }
}

CardLabReport.defaultProps = {
  hideCallBack: () => {},
  getReportList: () => {},

  isLoading: false,
  documentId: "0",
  patientInfo: {},
  reportDetail: {},
};

CardLabReport.propTypes = {
  hideCallBack: PropTypes.func,
  getReportList: PropTypes.func,

  isLoading: PropTypes.bool,
  documentId: PropTypes.string,
  patientInfo: PropTypes.object,
  reportDetail: PropTypes.object,
};

export default React.memo(CardLabReport);
