import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  FormField,
  Dropdown
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardConsultAccepttListUX = (props: any) => {
    return(
      <div
        style={{ width: "100%", height: "auto", padding: "15px 15px 0px 10px" }}>
        <Form>
          <FormGroup
            inline={true}
            style={{marginBottom: "0.25rem"}}>
            <FormField
              width={2}>
              <label>
                ค้นหาผู้ป่วย
              </label>
            </FormField>
            <FormField
              width={4}>
              <label>
                วันที่นัดส่งปรึกษา
              </label>
            </FormField>
            <FormField
              width={2}>
              <label>
                จากแผนก
              </label>
            </FormField>
            <FormField
              width={2}>
              <label>
                แพทย์ผู้ส่งปรึกษา
              </label>
            </FormField>
            <FormField
              width={2}>
              <label>
                สถานะการนัดหมาย
              </label>
            </FormField>
            <FormField
              width={2}>
              <label>
                สถานะการ Consult
              </label>
            </FormField>
            <FormField
              width={3}>
              <label>
                ประเภท Encounter
              </label>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              width={2}>
              <div
                style={{ width: "100%" }}>
                {props.patientSearchBox}
              </div>
            </FormField>
            <FormField
              width={4}>
              <DateTextBox
                onChange={(date: string)=> props.onChangeValue(null, {value: date, name: "start_app_date"})}
                value={props.filterParams?.start_app_date}>
              </DateTextBox>
              <label
                style={{marginRight: "0.75rem"}}>
                ถึง
              </label>
              <DateTextBox
                onChange={(date: string)=> props.onChangeValue(null, {value: date, name: "end_app_date"})}
                value={props.filterParams?.end_app_date}>
              </DateTextBox>
            </FormField>
            <FormField
              width={2}>
              <div
                style={{ width: "100%" }}>
                {props.divisionSearchBox}
              </div>
            </FormField>
            <FormField
              width={2}>
              <div
                style={{ width: "100%" }}>
                {props.doctorSearchBox}
              </div>
            </FormField>
            <FormField
              width={2}>
              <Dropdown
                clearable={true}
                fluid={true}
                name="app_status"
                onChange={props.onChangeValue}
                options={props.appointmentStatusOptions || []}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.filterParams?.app_status}>
              </Dropdown>
            </FormField>
            <FormField
              width={2}>
              <Dropdown
                clearable={true}
                fluid={true}
                name="status"
                onChange={props.onChangeValue}
                options={props.consultStatusOptions || []}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.filterParams?.status}>
              </Dropdown>
            </FormField>
            <FormField
              width={2}>
              <Dropdown
                clearable={true}
                fluid={true}
                name="encounter_type"
                onChange={props.onChangeValue}
                options={props.encounterType || []}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.filterParams?.encounter_type}>
              </Dropdown>
            </FormField>
            <FormField
              width={1}>
              <div>
                {props.buttonSearch}
              </div>
            </FormField>
          </FormGroup>
        </Form>
        <Table
          data={props.tableData}
          defaultPageSize={10}
          headers="วันที่ส่งคำปรึกษา, วันที่นัดส่งปรึกษา, ชื่อ-นามสกุล ผู้ป่วย, แพทย์/แผนก ผู้ส่งปรึกษา, ประเภท Encounter, สถานะการนัดหมาย, สถานะการ Consult, "
          keys="created_lbl, app_date_lbl, patient_lbl, doctor_lbl, en_type, app_status_lbl, status_lbl, action"
          widths="130, 130, 300, 300, 140, 180, 180, 50">
        </Table>
      </div>
    )
}


export default CardConsultAccepttListUX

export const screenPropsDefault = {}

/* Date Time : Tue Feb 06 2024 10:23:58 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"auto\", padding: \"15px 15px 0px 10px\" }"
        }
      },
      "seq": 0
    },
    {
      "from": "semantic-ui-react",
      "id": 2,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "FormGroup",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.25rem\"}"
        },
        "widths": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "value",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "FormField",
      "parent": 3,
      "props": {
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "label",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่นัดส่งปรึกษา"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "label",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "จากแผนก"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "value",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "แพทย์ผู้ส่งปรึกษา"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "value",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "label",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะการนัดหมาย"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "value",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "label",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะการ Consult"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "value",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "label",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภท Encounter"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "FormGroup",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "widths": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "FormField",
      "parent": 19,
      "props": {
        "inline": {
          "type": "value",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "FormField",
      "parent": 19,
      "props": {
        "inline": {
          "type": "value",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "FormField",
      "parent": 19,
      "props": {
        "inline": {
          "type": "value",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "FormField",
      "parent": 19,
      "props": {
        "inline": {
          "type": "value",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "FormField",
      "parent": 19,
      "props": {
        "inline": {
          "type": "value",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "FormField",
      "parent": 19,
      "props": {
        "inline": {
          "type": "value",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 34,
      "name": "DateTextBox",
      "parent": 20,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(date: string)=> props.onChangeValue(null, {value: date, name: \"start_app_date\"})"
        },
        "value": {
          "type": "code",
          "value": "props.filterParams?.start_app_date"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "Dropdown",
      "parent": 28,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "app_status"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.appointmentStatusOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filterParams?.app_status"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "Dropdown",
      "parent": 30,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "status"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.consultStatusOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filterParams?.status"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "Dropdown",
      "parent": 32,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "encounter_type"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.encounterType || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filterParams?.encounter_type"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "FormField",
      "parent": 19,
      "props": {
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 41,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearch"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 43,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.tableData"
        },
        "defaultPageSize": {
          "type": "code",
          "value": "10"
        },
        "headers": {
          "type": "value",
          "value": "วันที่ส่งคำปรึกษา, วันที่นัดส่งปรึกษา, ชื่อ-นามสกุล ผู้ป่วย, แพทย์/แผนก ผู้ส่งปรึกษา, ประเภท Encounter, สถานะการนัดหมาย, สถานะการ Consult, "
        },
        "keys": {
          "type": "value",
          "value": "created_lbl, app_date_lbl, patient_lbl, doctor_lbl, en_type, app_status_lbl, status_lbl, action"
        },
        "widths": {
          "type": "value",
          "value": "130, 130, 300, 300, 140, 180, 180, 50"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "label",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.75rem\"}"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 45,
      "name": "DateTextBox",
      "parent": 20,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(date: string)=> props.onChangeValue(null, {value: date, name: \"end_app_date\"})"
        },
        "value": {
          "type": "code",
          "value": "props.filterParams?.end_app_date"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 24,
      "props": {
        "children": {
          "type": "code",
          "value": "props.divisionSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "code",
          "value": "props.doctorSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "value",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "label",
      "parent": 48,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหาผู้ป่วย"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "FormField",
      "parent": 19,
      "props": {
        "inline": {
          "type": "value",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 50,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 51,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardConsultAccepttListUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
