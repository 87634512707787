import React, { useEffect, useState } from "react";
// ui common
import SnackMessage from "react-lib/apps/common/SnackMessage";
import ExpandingMenu from "react-lib/apps/common/ExpandingMenu";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
// ui ADM
import CardReAssessmentHistory from "./CardReAssessmentHistory";
import CardReAssessmentUX from "./CardReAssessmentUX";
import CardReAssessmentVenousUX from "./CardReAssessmentVenousUX";
import CardReAssessmentSurgicalUX from "./CardReAssessmentSurgicalUX";
import CardReAssessmentUrinaryUX from "./CardReAssessmentUrinaryUX";
import CardReAssessmentPain from "./CardReAssessmentPain";
import CardReAssessmentRiskFall from "./CardReAssessmentRiskFall";
import CardReAssessmentSleepUX from "./CardReAssessmentSleepUX";

import { Button } from "semantic-ui-react";
import { useIntl } from "react-intl";
// import moment from "moment";

const CARD_RE_ASSESSMENT_ID: string = "CardReAssessment";

type CardReAssessmentProps = {
  // function
  onEvent: any;
  setProp: any;

  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;
  showForm?: any;

  // seq
  runSequence: any;
  ReAssessmentSequence?: any;

  // options
  masterOptions?: any;
  reAssessmentOptions?: any;
};

const CardReAssessmentInitial: CardReAssessmentProps = {
  // funtion
  onEvent: () => null,
  setProp: () => null,

  // CommonInterface
  errorMessage: null,
  successMessage: null,
  buttonLoadCheck: null,

  // seq
  runSequence: null,

  // options
  masterOptions: {},
  reAssessmentOptions: {},
};

const CardReAssessment: React.FC<CardReAssessmentProps> = (props: any) => {
  const intl = useIntl();
  const [openForm, setOpenForm] = useState(true);

  useEffect(() => {
    props.runSequence({ sequence: "ReAssessment", restart: true });
  }, []);

  useEffect(() => {
    if (!openForm) {
      props.runSequence({
        sequence: "ReAssessment",
        action: "getDataHistory",
      });
    }
  }, [openForm]);

  const handleChangeData = (_event: any, data: any) => {
    if (data?.type === "checkbox") {
      props.setProp(`ReAssessmentSequence.${data.name}`, data.checked);
    } else {
      props.setProp(`ReAssessmentSequence.${data.name}`, data.value);
    }
  };

  const handleActionForm = (_event: any, data: any) => {
    props.runSequence({
      sequence: "ReAssessment",
      action: data.name,
      card: CARD_RE_ASSESSMENT_ID,
    });
  };

  // console.log("Yeti ReAssessment Props: ", props);

  return (
    <>
      {(props.errorMessage?.[CARD_RE_ASSESSMENT_ID] ||
        props.successMessage?.[CARD_RE_ASSESSMENT_ID]) && (
        <SnackMessage
          onEvent={props.onEvent}
          onClose={() => {
            props.setProp(`errorMessage.${CARD_RE_ASSESSMENT_ID}`, null);
            props.setProp(`successMessage.${CARD_RE_ASSESSMENT_ID}`, null);
          }}
          error={props.errorMessage?.[CARD_RE_ASSESSMENT_ID]}
          success={props.successMessage?.[CARD_RE_ASSESSMENT_ID]}
          languageUX={props.languageUX}
        />
      )}

      <div style={{ ...(openForm && { minWidth: "max-content", whiteSpace: "nowrap" }) }}>
        <div style={{ margin: "20px", ...(props.showForm && { display: "none" }) }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ fontSize: "22px", fontWeight: "bold" }}>Nursing Re-assessment</div>
            <div style={{ margin: "0px 20px" }}>
              <Button onClick={() => setOpenForm(true)} {...(openForm && { color: "blue" })}>
                แบบฟอร์มการประเมิน
              </Button>
            </div>
            <div>
              <Button onClick={() => setOpenForm(false)} {...(!openForm && { color: "blue" })}>
                ประวัติการบันทึก
              </Button>
            </div>
          </div>
        </div>

        {openForm ? (
          <>
            <ExpandingMenu
              key="AssessmentPain"
              name="Pain Assessment"
              content={
                <CardReAssessmentPain
                  // function
                  onEvent={props.onEvent}
                  setProp={props.setProp}
                  onChangeData={handleChangeData}
                  // seq
                  runSequence={props.runSequence}
                  ReAssessmentSequence={props.ReAssessmentSequence}
                  // options
                  masterOptions={props.masterOptions}
                  reAssessmentOptions={props.reAssessmentOptions}
                  // common
                  buttonLoadCheck={props.buttonLoadCheck}
                  // onChange
                  onChangeDatePain={(date: any) =>
                    props.setProp(`ReAssessmentSequence.painData.saveData`, date)
                  }
                  onChangeTimePain={(value: any) =>
                    props.setProp(`ReAssessmentSequence.painData.saveTime`, value)
                  }
                  onChangeMedicationGiveTime={(value: any) =>
                    props.setProp(`ReAssessmentSequence.painQuestion.painMedicationGiveTime`, value)
                  }
                  onChangeScoreAfterInterventionTime={(value: any) =>
                    props.setProp(
                      `ReAssessmentSequence.painQuestion.painScoreAfterInterventionTime`,
                      value
                    )
                  }
                  buttonSavePain={
                    <ButtonLoadCheck
                      // function
                      setProp={props.setProp}
                      onClick={handleActionForm}
                      // data
                      paramKey={`${CARD_RE_ASSESSMENT_ID}_savePain`}
                      buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_RE_ASSESSMENT_ID}_savePain`]}
                      // config
                      color={"green"}
                      name="savePain"
                      size="small"
                      title={"บันทึก"}
                    />
                  }
                  languageUX={props.languageUX}
                />
              }
            />

            <ExpandingMenu
              key="AssessmentFallRisk"
              name="Fall Risk Assessment"
              content={
                <CardReAssessmentRiskFall
                  // function
                  onEvent={props.onEvent}
                  setProp={props.setProp}
                  onChangeData={handleChangeData}
                  // seq
                  runSequence={props.runSequence}
                  ReAssessmentSequence={props.ReAssessmentSequence}
                  // options
                  masterOptions={props.masterOptions}
                  reAssessmentOptions={props.reAssessmentOptions}
                  // common
                  buttonLoadCheck={props.buttonLoadCheck}
                  // onChange
                  onChangeDateRiskFall={(date: any) =>
                    props.setProp(`ReAssessmentSequence.riskFallData.saveData`, date)
                  }
                  onChangeTimeRiskFall={(value: any) =>
                    props.setProp(`ReAssessmentSequence.riskFallData.saveTime`, value)
                  }
                  buttonSaveRiskFall={
                    <ButtonLoadCheck
                      // function
                      setProp={props.setProp}
                      onClick={handleActionForm}
                      // data
                      paramKey={`${CARD_RE_ASSESSMENT_ID}_saveRiskFall`}
                      buttonLoadCheck={
                        props.buttonLoadCheck?.[`${CARD_RE_ASSESSMENT_ID}_saveRiskFall`]
                      }
                      // config
                      color={"green"}
                      name="saveRiskFall"
                      size="small"
                      title={"บันทึก"}
                    />
                  }
                  languageUX={props.languageUX}
                />
              }
            />

            <ExpandingMenu
              key="ReAssessmentSleep"
              name={intl.formatMessage({ id: "การพักผ่อนนอนหลับ" })}
              content={
                <CardReAssessmentSleepUX
                  // function
                  onChangeData={handleChangeData}
                  // seq
                  ReAssessmentSequence={props.ReAssessmentSequence}
                  // options
                  masterOptions={props.masterOptions}
                  reAssessmentOptions={props.reAssessmentOptions}
                  // onChange
                  onChangDateSleep={(date: any) =>
                    props.setProp(`ReAssessmentSequence.sleepData.saveData`, date)
                  }
                  onChangTimeSleep={(value: any) =>
                    props.setProp(`ReAssessmentSequence.sleepData.saveTime`, value)
                  }
                  // check input number
                  onKeyDown={(e: any) => {
                    if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                      e.preventDefault();
                    }
                  }}
                  buttonSaveSleep={
                    <ButtonLoadCheck
                      // function
                      setProp={props.setProp}
                      onClick={handleActionForm}
                      // data
                      paramKey={`${CARD_RE_ASSESSMENT_ID}_saveSleep`}
                      buttonLoadCheck={
                        props.buttonLoadCheck?.[`${CARD_RE_ASSESSMENT_ID}_saveSleep`]
                      }
                      // config
                      color={"green"}
                      name="saveSleep"
                      size="small"
                      title={"บันทึก"}
                    />
                  }
                  languageUX={props.languageUX}
                />
              }
            />

            <ExpandingMenu
              key="ReAssessmentVenous"
              name={intl.formatMessage({ id: "แบบบันทึกข้อมูลการเฝ้าระวังหลอดเลือดดำอักเสบ" })}
              isPopup
              popupContent={
                <div>
                  {"Yes คือ ได้ปฏิบัติถูกต้อง"}
                  <br />
                  {"No คือ ปฏิบัติไม่ถูกต้อง"}
                  <br />
                  {"NA คือ Not Applicable"}
                </div>
              }
              content={
                <CardReAssessmentVenousUX
                  // function
                  onChangeData={handleChangeData}
                  // seq
                  ReAssessmentSequence={props.ReAssessmentSequence}
                  // options
                  masterOptions={props.masterOptions}
                  reAssessmentOptions={props.reAssessmentOptions}
                  // onChange
                  onChangeDateVenous={(date: any) =>
                    props.setProp(`ReAssessmentSequence.venousData.saveData`, date)
                  }
                  onChangeTimeVenous={(value: any) =>
                    props.setProp(`ReAssessmentSequence.venousData.saveTime`, value)
                  }
                  buttonSaveVenous={
                    <ButtonLoadCheck
                      // function
                      setProp={props.setProp}
                      onClick={handleActionForm}
                      // data
                      paramKey={`${CARD_RE_ASSESSMENT_ID}_saveVenous`}
                      buttonLoadCheck={
                        props.buttonLoadCheck?.[`${CARD_RE_ASSESSMENT_ID}_saveVenous`]
                      }
                      // config
                      color={"green"}
                      name="saveVenous"
                      size="small"
                      title={"บันทึก"}
                    />
                  }
                  languageUX={props.languageUX}
                />
              }
            />

            <ExpandingMenu
              key="ReAssessmentSurgical"
              name={intl.formatMessage({
                id: "แบบประเมินการปฏิบัติการป้องกันการติดเชื้อตำแหน่งแผลผ่าตัด",
              })}
              isPopup
              popupContent={
                <div>
                  {"Yes คือ ได้ปฏิบัติถูกต้อง"}
                  <br />
                  {"No คือ ปฏิบัติไม่ถูกต้อง"}
                  <br />
                  {"NA คือ Not Applicable"}
                </div>
              }
              content={
                <CardReAssessmentSurgicalUX
                  // function
                  onChangeData={handleChangeData}
                  // seq
                  ReAssessmentSequence={props.ReAssessmentSequence}
                  // options
                  masterOptions={props.masterOptions}
                  reAssessmentOptions={props.reAssessmentOptions}
                  // onChange
                  onChangeDateSurgicalBefore={(date: any) =>
                    props.setProp(`ReAssessmentSequence.surgicalBeforeData.saveData`, date)
                  }
                  onChangeTimeSurgicalBefore={(value: any) =>
                    props.setProp(`ReAssessmentSequence.surgicalBeforeData.saveTime`, value)
                  }
                  onChangeDateSurgicalAfter={(date: any) =>
                    props.setProp(`ReAssessmentSequence.surgicalAfterData.saveData`, date)
                  }
                  onChangeTimeSurgicalAfter={(value: any) =>
                    props.setProp(`ReAssessmentSequence.surgicalAfterData.saveTime`, value)
                  }
                  surgicalTopicOne={
                    <>
                      <span style={{ fontWeight: "bold" }}>
                        {intl.formatMessage({ id: "การเตรียมผู้ป่วยก่อนผ่าตัด" })}
                      </span>
                      <span style={{ color: "red" }}>
                        {intl.formatMessage({
                          id: "*หากไม่เลือกตัวเลือกใดเลย จะถือว่าในข้อนั้นไม่ได้ปฏิบัติ",
                        })}
                      </span>
                    </>
                  }
                  surgicalTopicTwo={
                    <>
                      <span style={{ fontWeight: "bold" }}>
                        {intl.formatMessage({
                          id: "การดูแลผู้ป่วยหลังผ่าตัด (วันที่แพทย์เปิดทำแผลครั้งแรก)",
                        })}
                      </span>
                      <span style={{ color: "red" }}>
                        {intl.formatMessage({
                          id: "*หากไม่เลือกตัวเลือกใดเลย จะถือว่าในข้อนั้นไม่ได้ปฏิบัติ",
                        })}
                      </span>
                    </>
                  }
                  buttonSaveSurgicalBefore={
                    <ButtonLoadCheck
                      // function
                      setProp={props.setProp}
                      onClick={handleActionForm}
                      // data
                      paramKey={`${CARD_RE_ASSESSMENT_ID}_saveSurgicalBefore`}
                      buttonLoadCheck={
                        props.buttonLoadCheck?.[`${CARD_RE_ASSESSMENT_ID}_saveSurgicalBefore`]
                      }
                      // config
                      color={"green"}
                      name="saveSurgicalBefore"
                      size="small"
                      title={"บันทึก"}
                    />
                  }
                  buttonSaveSurgicalAfter={
                    <ButtonLoadCheck
                      // function
                      setProp={props.setProp}
                      onClick={handleActionForm}
                      // data
                      paramKey={`${CARD_RE_ASSESSMENT_ID}_saveSurgicalAfter`}
                      buttonLoadCheck={
                        props.buttonLoadCheck?.[`${CARD_RE_ASSESSMENT_ID}_saveSurgicalAfter`]
                      }
                      // config
                      color={"green"}
                      name="saveSurgicalAfter"
                      size="small"
                      title={"บันทึก"}
                    />
                  }
                  languageUX={props.languageUX}
                />
              }
            />

            <ExpandingMenu
              key="ReAssessmentUrinary"
              name={intl.formatMessage({
                id: "แบบบันทึกการป้องกันการเกิดการติดเชื้อทางเดินปัสสาวะจากการใส่สายปัสสาวะ : CAUTI Bundles",
              })}
              isPopup
              popupContent={
                <div>
                  {"Yes คือ ได้ปฏิบัติถูกต้อง"}
                  <br />
                  {"No คือ ปฏิบัติไม่ถูกต้อง"}
                  <br />
                  {"NA คือ Not Applicable"}
                </div>
              }
              content={
                <CardReAssessmentUrinaryUX
                  // function
                  onChangeData={handleChangeData}
                  // seq
                  ReAssessmentSequence={props.ReAssessmentSequence}
                  // options
                  masterOptions={props.masterOptions}
                  reAssessmentOptions={props.reAssessmentOptions}
                  // onChange
                  onChangeDateUrinary={(date: any) => {
                    props.setProp(`ReAssessmentSequence.urinaryData.saveData`, date);
                  }}
                  onChangeTimeUrinary={(value: any) =>
                    props.setProp(`ReAssessmentSequence.urinaryData.saveTime`, value)
                  }
                  buttonSaveUrinary={
                    <ButtonLoadCheck
                      // function
                      setProp={props.setProp}
                      onClick={handleActionForm}
                      // data
                      paramKey={`${CARD_RE_ASSESSMENT_ID}_saveUrinary`}
                      buttonLoadCheck={
                        props.buttonLoadCheck?.[`${CARD_RE_ASSESSMENT_ID}_saveUrinary`]
                      }
                      // config
                      color={"green"}
                      name="saveUrinary"
                      size="small"
                      title={"บันทึก"}
                    />
                  }
                  languageUX={props.languageUX}
                />
              }
            />

            {/* <CardReAssessmentUX
            // function
            onChangeData={handleChangeData}
            // seq
            ReAssessmentSequence={props.ReAssessmentSequence}
            // options
            masterOptions={props.masterOptions}
            reAssessmentOptions={props.reAssessmentOptions}
            //onChange
            onChangDatePain={(date: any) =>
              props.setProp(`ReAssessmentSequence.painData.saveData`, date)
            }
            onChangDateRiskFall={(date: any) =>
              props.setProp(`ReAssessmentSequence.riskFallData.saveData`, date)
            }
            onChangDateSleep={(date: any) =>
              props.setProp(`ReAssessmentSequence.sleepData.saveData`, date)
            }
            onChangTimePain={(value: any) =>
              props.setProp(`ReAssessmentSequence.painData.saveTime`, value)
            }
            onChangTimeRiskFall={(value: any) =>
              props.setProp(`ReAssessmentSequence.riskFallData.saveTime`, value)
            }
            onChangTimeSleep={(value: any) =>
              props.setProp(`ReAssessmentSequence.sleepData.saveTime`, value)
            }
            buttonSavePain={
              <ButtonLoadCheck
                // function
                setProp={props.setProp}
                onClick={handleActionForm}
                // data
                paramKey={`${CARD_RE_ASSESSMENT_ID}_savePain`}
                buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_RE_ASSESSMENT_ID}_savePain`]}
                // config
                color={"green"}
                name="savePain"
                size="small"
                title={"บันทึก"}
              />
            }
            buttonSaveRiskFall={
              <ButtonLoadCheck
                // function
                setProp={props.setProp}
                onClick={handleActionForm}
                // data
                paramKey={`${CARD_RE_ASSESSMENT_ID}_saveRiskFall`}
                buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_RE_ASSESSMENT_ID}_saveRiskFall`]}
                // config
                color={"green"}
                name="saveRiskFall"
                size="small"
                title={"บันทึก"}
              />
            }
            buttonSaveSleep={
              <ButtonLoadCheck
                // function
                setProp={props.setProp}
                onClick={handleActionForm}
                // data
                paramKey={`${CARD_RE_ASSESSMENT_ID}_saveSleep`}
                buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_RE_ASSESSMENT_ID}_saveSleep`]}
                // config
                color={"green"}
                name="saveSleep"
                size="small"
                title={"บันทึก"}
              />
            }
            // check input number
            onKeyDown={(e: any) => {
              if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                e.preventDefault();
              }
            }}
            showForm={props.showForm}
          /> */}
          </>
        ) : (
          <>
            <CardReAssessmentHistory
              // seq
              runSequence={props.runSequence}
              // function
              onEvent={props.onEvent}
              setProp={props.setProp}
              // CommonInterface
              errorMessage={props.errorMessage}
              successMessage={props.successMessage}
              // data
              masterOptions={props.masterOptions}
              title={{ code: "PAIN", name: intl.formatMessage({ id: "การประเมินความเจ็บปวด" }) }}
              data={props.ReAssessmentSequence?.painDataHistory}
            />

            <CardReAssessmentHistory
              // seq
              runSequence={props.runSequence}
              // function
              onEvent={props.onEvent}
              setProp={props.setProp}
              // CommonInterface
              errorMessage={props.errorMessage}
              successMessage={props.successMessage}
              // data
              masterOptions={props.masterOptions}
              title={{
                code: "RISK_FALL",
                name: intl.formatMessage({ id: "การประเมินความเสี่ยงต่อการพลัดตกหกล้ม" }),
              }}
              data={props.ReAssessmentSequence?.riskFallDataHistory}
              languageUX={props.languageUX}
            />

            <CardReAssessmentHistory
              // seq
              runSequence={props.runSequence}
              // function
              onEvent={props.onEvent}
              setProp={props.setProp}
              // CommonInterface
              errorMessage={props.errorMessage}
              successMessage={props.successMessage}
              // data
              masterOptions={props.masterOptions}
              title={{ code: "SLEEP", name: intl.formatMessage({ id: "การพักผ่อนนอนหลับ" }) }}
              data={props.ReAssessmentSequence?.sleepDataHistory}
              languageUX={props.languageUX}
            />

            <CardReAssessmentHistory
              // seq
              runSequence={props.runSequence}
              // function
              onEvent={props.onEvent}
              setProp={props.setProp}
              // CommonInterface
              errorMessage={props.errorMessage}
              successMessage={props.successMessage}
              // data
              masterOptions={props.masterOptions}
              title={{
                code: "VENOUS",
                name: intl.formatMessage({ id: "แบบบันทึกข้อมูลการเฝ้าระวังหลอดเลือดดำอักเสบ" }),
              }}
              data={props.ReAssessmentSequence?.venousDataHistory}
              languageUX={props.languageUX}
            />

            <CardReAssessmentHistory
              // seq
              runSequence={props.runSequence}
              // function
              onEvent={props.onEvent}
              setProp={props.setProp}
              // CommonInterface
              errorMessage={props.errorMessage}
              successMessage={props.successMessage}
              // data
              masterOptions={props.masterOptions}
              title={{
                code: "SURGICAL",
                name: intl.formatMessage({
                  id: "แบบประเมินการปฏิบัติการป้องกันการติดเชื้อตำแหน่งแผลผ่าตัด",
                }),
              }}
              type={props.ReAssessmentSequence?.surgicalDataHistory?.data}
              data={props.ReAssessmentSequence?.surgicalDataHistory}
              languageUX={props.languageUX}
            />

            <CardReAssessmentHistory
              // seq
              runSequence={props.runSequence}
              // function
              onEvent={props.onEvent}
              setProp={props.setProp}
              // CommonInterface
              errorMessage={props.errorMessage}
              successMessage={props.successMessage}
              // data
              masterOptions={props.masterOptions}
              title={{
                code: "URINARY",
                name: intl.formatMessage({
                  id: "แบบบันทึกการป้องกันการเกิดการติดเชื้อทางเดินปัสสาวะจากการใส่สายปัสสาวะ : CAUTI Bundles",
                }),
              }}
              data={props.ReAssessmentSequence?.urinaryDataHistory}
              languageUX={props.languageUX}
            />
          </>
        )}
      </div>
    </>
  );
};

CardReAssessment.defaultProps = CardReAssessmentInitial;

export default React.memo(CardReAssessment);
