export default class AppointmentDashboardController {
  constructor(props) {
    this.coreManager = props.coreManager;
    this.prxManager = props.prxManager;
  }

  getLocation = async (props) => {
    const [response, error] = await this.coreManager.getDivision(props);
    return [response, error];
  }

  getDashboardAppointment = async (props) => {
    console.log('getDashboardAppointment props', props)
    const [response, error] = await this.prxManager.getDashboardAppointment(props);
    return [response, error];
  }

}