import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Dropdown,
  Container,
  Header,
  Input,
  TextArea,
  Divider,
  Button,
  Segment,
  Select,
  Icon,
  DropdownItemProps,
} from "semantic-ui-react";
import Cookies from "js-cookie";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import CONFIG from "config/config"
import axios from 'axios'

const ChildAnswer = (props: any) => {
  // props.choise
  const answerChoise = props.choise;
  const questionItem = props.question;

  return (
    <>
      {answerChoise &&
        answerChoise.length > 0 &&
        answerChoise.map((item) => {
          return (
            <React.Fragment key={item.id}>
              {item.condition
                ? item.condition
                : "ยังไม่ได้ระบุคำตอบของคำถาม " + questionItem.text}
              <Divider style={{ width: "50%" }} />
            </React.Fragment>
          );
        })}
    </>
  );
};

const Detail = (props: any) => {
  const intl = useIntl();
  const isMounted = useRef(true);
  const [tagOptions, setTagOptions] = useState([]);
  const [tagEconsentOptions, setTagEconsentOptions] = useState<DropdownItemProps[]>([]);

  useEffect(() => {
    const handleTagsOptions = async () => {


      // Get data from GCS = tag-
      props.storage?.refFromURL(CONFIG.TAGOPTIONS_URL)
      .getDownloadURL()
      .then((url: string) => {
        console.log("url", url)
        axios.get(url)
          .then(res => {
            if (res?.data) {
              if (isMounted.current) {
                setTagOptions(res?.data);
              }
            }
          })
          .catch(err => {
            console.log(err)
          })
      }).catch((err: any) => {
        console.log(err)
      })

      let [response, error, network] = await props.controller.getTagEconsentOptions({
        apiToken: Cookies.get("apiToken") ? Cookies.get("apiToken") : props.apiToken,
      });
      if (response && response?.length > 0) {
        if (isMounted.current) {
          setTagEconsentOptions(response);
        }
      }
    };

    handleTagsOptions();
    return () => {
      isMounted.current = false;
    };
  }, []);

  if (!props.row_item) {
    return <Header as="h3">{intl.formatMessage({ id: "กรุณาเลือกคำถามเพื่อดูรายละเอียด และแก้ไข" })}</Header>;
  }

  const typeOptions = [
    { key: "Text", value: "Text", text: "Text" },
    { key: "Integer", value: "Integer", text: "Integer" },
    { key: "Decimal", value: "Decimal", text: "Decimal" },
    { key: "Select", value: "Select", text: "Select" },
    { key: "MultiSelect", value: "MultiSelect", text: "MultiSelect" },
    { key: "Modal", value: "Modal", text: "Modal" },
    { key: "Description", value: "Description", text: "Description" },
  ];
  const nullOkOptions = [
    { key: "true", value: true, text: "ได้" },
    { key: "false", value: false, text: "ไม่" },
  ];
  const showModalIfNotOKOptions = [
    { key: "true", value: true, text: "ได้" },
    { key: "false", value: false, text: "ไม่" },
  ];

  // console.log("Detail: props.row_item?.extra_items", props.row_item?.extra_items)
  return (
    <Container fluid>
      <Form>
        {Cookies.get("apiToken") == "anonymous" ? null : (
          <Form.Field style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              basic
              size="small"
              color="orange"
              onClick={(e) => {
                props.onAddExtraItem(props.row_item.id);
              }}
            >
              + ข้อมูลสอบถาม
            </Button>
            <Button
              basic
              size="small"
              // inverted
              color="teal"
              disabled={Cookies.get("apiToken") == "anonymous"}
              onClick={props.onSave}
            >
              {" "}
              {"save"}{" "}
            </Button>
          </Form.Field>
        )}
        { props.row_item?.extra_items?.map((ex, index) => (
            <Form.Group key={index}>
              <Form.Input width={2} label="Tag-econsent">
                <Dropdown
                  fluid
                  multiple
                  // selection
                  options={tagEconsentOptions}
                  value={ex.tageconsent && tagEconsentOptions?.length > 0 ? ex.tageconsent.map((key:any) => tagEconsentOptions?.find( (options:any) => options.key === key)?.value) : []}
                  onChange={(event, data: any) => {
                    props.onChangeExtraItem(props.row_item.id, index, {
                      tageconsent: data.value?.map((value:any) => tagEconsentOptions?.find((options:any) => options.value === value)?.key ),
                    });
                  }}
                />
              </Form.Input>
              <Form.Input width={2} label="Tag-covid">
                <Select
                  fluid
                  options={tagOptions}
                  value={ex.tag ? ex.tag : "0"}
                  onChange={(event, data) => {
                    props.onChangeExtraItem(props.row_item.id, index, {
                      tag: data.value,
                    });
                  }}
                />
              </Form.Input>
              <Form.Input
                width={3}
                label={intl.formatMessage({ id: "ชื่อข้อมูล" })}
                value={ex.label}
                onChange={(event, data) => {
                  props.onChangeExtraItem(props.row_item.id, index, {
                    label: event.target.value,
                  });
                }}
              />
              <Form.Input
                width={1}
                label="page"
                value={ex.page}
                onChange={(event, data) => {
                  props.onChangeExtraItem(props.row_item.id, index, {
                    page: event.target.value,
                  });
                }}
              />
              <Form.Input width={3} label={intl.formatMessage({ id: "ประเภท" })}>
                <Select
                  fluid
                  options={typeOptions}
                  value={ex.type}
                  onChange={(event, data) => {
                    props.onChangeExtraItem(props.row_item.id, index, {
                      type: data.value,
                    });
                  }}
                />
              </Form.Input>
              <Form.Input width={1} label={intl.formatMessage({ id: "ค่าว่าง" })}>
                <Select
                  fluid
                  options={nullOkOptions}
                  value={ex.nullOk}
                  onChange={(event, data) => {
                    props.onChangeExtraItem(props.row_item.id, index, {
                      nullOk: data.value,
                    });
                  }}
                />
              </Form.Input>
              <Form.Input width={2} label={intl.formatMessage({ id: "Modalถ้าตอบไม่" })}>
                <Select
                  fluid
                  options={showModalIfNotOKOptions}
                  value={ex.showModalIfNotOk}
                  onChange={(event, data) => {
                    props.onChangeExtraItem(props.row_item.id, index, {
                      showModalIfNotOk: data.value,
                    });
                  }}
                />
              </Form.Input>
              {["Text", "Integer", "Decimal"].includes(ex.type) ? (
                <Form.Input
                  width={4}
                  label={intl.formatMessage({ id: "ค่าตั้งต้น" })}
                  value={ex.default}
                  onChange={(event, data) => {
                    props.onChangeExtraItem(props.row_item.id, index, {
                      default: event.target.value,
                    });
                  }}
                />
              ) : (
                <Form.Input
                  width={6}
                  label={intl.formatMessage({ id: "ตัวเลือก (แยกด้วยสัญลักษณ์ คอมมา , )" })}
                  value={ex.options ? ex.options.join(",") : ""}
                  onChange={(event, data) => {
                    props.onChangeExtraItem(props.row_item.id, index, {
                      options: event.target.value.split(","),
                    });
                  }}
                />
              )}
              <Form.Field style={{ display: "flex", alignItems: "flex-end" }}>
                <Button
                  basic
                  icon
                  onClick={(event, data) => {
                    props.onRemoveExtraItem(props.row_item.id, index);
                  }}
                >
                  <Icon name="close" />
                </Button>
              </Form.Field>
            </Form.Group>
          ))}
        <Form.Field>
          <label>{intl.formatMessage({ id: "หัวข้อ" })}</label>
          <TextArea
            value={
              props.row_item && props.row_item.label ? props.row_item.label : ""
            }
            // maxLength={40}
            rows={1}
            placeholder={intl.formatMessage({ id: "กรุณาใส่หัวข้อ" })}
            onChange={(e) => {
              props.setRowContent(props.row_item.id, "label", e.target.value);
            }}
          />
        </Form.Field>
        <Form.Field>
          {props.row_item && props.row_parent !== null && (
            <Segment style={{ backgroundColor: "#5dbcd2" }}>
              {props.row_item && props.row_parent !== null && (
                <Form.Field>
                  <label style={{ color: "white" }}>{intl.formatMessage({ id: "จากคำถามก่อนหน้า" })}</label>
                  <textarea
                    style={{ backgroundColor: "#F5F5F5" }}
                    readOnly={true}
                    value={props.row_parent.text}
                  />
                </Form.Field>
              )}
              {props.row_item && !(props.row_item.parent_id === null) && (
                <Form.Field>
                  <label style={{ color: "white" }}>{intl.formatMessage({ id: "คำตอบ" })}</label>
                  <textarea
                    rows={1}
                    value={props.row_item.condition}
                    placeholder={
                      props.row_parent &&
                      props.row_parent.text &&
                      "กรุณาใส่คำตอบของคำถาม: " + props.row_parent.text
                    }
                    onChange={(e) => {
                      props.setRowContent(
                        props.row_item.id,
                        "condition",
                        e.target.value
                      );
                    }}
                  />
                </Form.Field>
              )}
            </Segment>
          )}
        </Form.Field>

        {props.row_item.type === "end" && (
          <Form.Field>
            <label>{intl.formatMessage({ id: "ผลการคัดกรอง" })}</label>
            <Dropdown
              selection
              options={props.triageLevel}
              value={
                props.row_item.triage_level ? props.row_item.triage_level : null
              }
              onChange={(e, { value }) => {
                props.setRowContent(props.row_item.id, "triage_level", value);
              }}
            />
          </Form.Field>
        )}
        <Form.Field>
          {props.row_item && props.row_item.type === "end" ? (
            <>
              <label>{intl.formatMessage({ id: "ผลประเมิน / ข้อความแนะนำ" })}</label>
              <textarea
                value={
                  props.row_item && props.row_item.text
                    ? props.row_item.text
                    : ""
                }
                onChange={(e) => {
                  props.setRowContent(
                    props.row_item.id,
                    "text",
                    e.target.value
                  );
                }}
              />
            </>
          ) : props.row_item.parent_id == null ? (
            <>
              <label>{intl.formatMessage({ id: "คำถามแรก" })}</label>
              <textarea
                value={
                  props.row_item && props.row_item.text
                    ? props.row_item.text
                    : ""
                }
                onChange={(e) => {
                  props.setRowContent(
                    props.row_item.id,
                    "text",
                    e.target.value
                  );
                }}
              />
            </>
          ) : (
            <>
              <label>{intl.formatMessage({ id: "คำถามถัดไป" })}</label>
              <TextArea
                rows={7}
                value={
                  props.row_item && props.row_item.text
                    ? props.row_item.text
                    : ""
                }
                placeholder={
                  'กรุณากรอกคำถามต่อเนื่อง\n\nเมื่อตอบว่า\n  "' +
                  props.row_item.condition +
                  "\n\nจากคำถาม\n  " +
                  props.row_parent.text
                }
                onChange={(e) => {
                  props.setRowContent(
                    props.row_item.id,
                    "text",
                    e.target.value
                  );
                }}
              />
            </>
          )}
        </Form.Field>

        <Form.Field>
          {props.row_item && (
            <>
              {props.row_item && props.row_item.type !== "end" ? (
                <label>{intl.formatMessage({ id: "ตัวเลือกที่สามารถเลือกได้" })}</label>
              ) : null}

              <br />
              {props.choise && props.choise.length > 0 ? (
                <ChildAnswer choise={props.choise} question={props.row_item} />
              ) : props.row_item && props.row_item.type !== "end" ? (
                <label style={{ color: "red" }}>
                  {" "}
                  {" ยังไม่มีตัวเลือกที่สามารถเลือกได้ กรุณากด + "}
                  <br />
                  {" ที่อยู่ด้านซ้ายมือตรงคำถามที่ต้องการเพิ่มตัวเลือก"}
                </label>
              ) : null}
            </>
          )}
        </Form.Field>
      </Form>
    </Container>
  );
};

Detail.defaultProps = {
  // className:PropTypes.string,
  // row_item:PropTypes.object,
  // row_parent:PropTypes.object,
  // setRowContent: PropTypes.func,
  triageLevel: [],
  // choise:PropTypes.array,
  onSave: () => {},
};

Detail.propTypes = {
  className: PropTypes.string,
  row_item: PropTypes.object,
  row_parent: PropTypes.object,
  setRowContent: PropTypes.func,
  triageLevel: PropTypes.array,
  choise: PropTypes.array,
  onSave: PropTypes.func,
  onGetTagsOptions: PropTypes.func,
  controller: PropTypes.any,
  storage: PropTypes.any,
};

export default Detail;
