import CONFIG from "config/config";
import CompanyLogoForm from "react-lib/apps/HISV3/common/CompanyLogoForm";

const FORM_NAME = "FormPaymentARReport";

const COLORS = {
  greyHeader: "#D9D9D9",
  greySummary: "#E8E8E8",
  greyBorder: "#ACACAC",
  black: "#000000",
};

type FormPaymentARReportProps = {
  params?: any;
  fields?: any;
  date?: any;
  user_name?: string;
  code_formatted?: string;
};

const FormPaymentARReport = async (props: FormPaymentARReportProps) => {
  console.log("Yeti Form Payment AR Report Props: ", props);
  const companyLogoForm = await CompanyLogoForm({
    font: "THSarabunNew",
    height: 40,
    form: FORM_NAME,
  });
  const { font, images } = companyLogoForm;

  let billModeList: any[] = [];
  let billModeData: any[] = [];

  const fieldsData = (props.fields || []).map((item: any) => {
    if (!billModeList.includes(item?.bill_mode_code)) {
      billModeList.push(item?.bill_mode_code);

      let filterListData = (props.fields || [])
        .filter((data: any) => data.bill_mode_code === item?.bill_mode_code)
        .map((data: any, number: any) => {
          if (number === 0) {
            billModeData.push([
              {
                text: `${item?.bill_mode_name}`,
                colSpan: 6,
                border: [false, false, false, false],
                style: {
                  bold: true,
                  fontSize: 14,
                },
              },
              {},
              {},
              {},
              {},
              {},
            ]);
          }

          billModeData.push([
            {
              text: `${data?.product_code}`,
              border: [false, false, false, false],
            },
            {
              text: `${data?.product_name}`,
              border: [false, false, false, false],
            },
            {
              text: `${data?.quantity}`,
              alignment: "right",
              border: [false, false, false, false],
            },
            {
              text: `${data?.total_send_claim_price}`,
              alignment: "right",
              border: [false, false, false, false],
            },
            {
              text: `${data?.total_must_pay_price}`,
              alignment: "right",
              border: [false, false, false, false],
            },
            {
              text: `${data?.total_overdue_price}`,
              alignment: "right",
              border: [false, false, false, false],
            },
          ]);
        });

      billModeData.push([
        {
          text: ` `,
          border: [false, false, false, true],
          margin: [0, 0, 0, 12],
        },
        {
          text: `รวม`,
          alignment: "center",
          style: {
            bold: true,
          },
          border: [false, false, false, true],
          colSpan: 2,
          margin: [0, 0, 0, 12],
        },
        {},
        {
          text: `${props.params[`${item?.bill_mode_code}_total_send_claim_price`]}`,
          alignment: "right",
          style: {
            bold: true,
          },
          border: [false, false, false, true],
          margin: [0, 0, 0, 12],
        },
        {
          text: `${props.params[`${item?.bill_mode_code}_total_must_pay_price`]}`,
          alignment: "right",
          style: {
            bold: true,
          },
          border: [false, false, false, true],
          margin: [0, 0, 0, 12],
        },
        {
          text: `${props.params[`${item?.bill_mode_code}_total_overdue_price`]}`,
          alignment: "right",
          style: {
            bold: true,
          },
          border: [false, false, false, true],
          margin: [0, 0, 0, 12],
        },
      ]);
    }
  });

  return {
    defaultStyle: {
      font: font,
      fontSize: 12,
    },
    pageSize: "A4",
    pageMargins: [10, 210, 10, 50],
    header: function (currentPage: any) {
      return {
        margin: [5, 13, 5, 0],
        stack: [
          // header
          {
            margin: [10, 0, 20, 0],
            table: {
              widths: [133, "*", 133],
              body: [
                [
                  {
                    image: "logo",
                    width: 133,
                    height: 40,
                    border: [false, false, false, false],
                    alignment: "left",
                    margin: [0, 8, 0, 0],
                  },
                  {
                    stack: [
                      {
                        text: "ใบแสดงรายละเอียดค่าใช้จ่าย",
                        style: {
                          bold: true,
                          fontSize: 18,
                        },
                      },
                      {
                        text: "โรงพยาบาลคณะทันตแพทยศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย",
                        style: {
                          bold: true,
                        },
                      },
                      {
                        text: "คณะทันตแพทย์ จุฬาลงกรณ์มหาวิทยาลัย",
                        style: {
                          bold: true,
                        },
                      },
                      {
                        text: "ถนนอังรีดูนังต์ ปทุมวัน กรุงเทพฯ 10330",
                      },
                      {
                        text: "02-218-8705",
                      },
                    ],
                    border: [false, false, false, false],
                  },
                  {
                    stack: [
                      {
                        text: `หน้า ${currentPage.toString()}`,
                        alignment: "right",
                      },
                      {
                        text: " ",
                      },
                      {
                        text: `เลขที่ใบเสร็จ: ${props.code_formatted == null ? `ยังไม่ได้รับชำระ` : props.code_formatted}`,
                        style: {
                          bold: true,
                        },
                      },
                      {
                        text: `วันที่พิมพ์: ${props.params?.print_date_time}`,
                        style: {
                          bold: true,
                        },
                      },
                      {
                        text: "",
                      },
                    ],
                    margin: [0, 10, 0, 0],
                    border: [false, false, false, false],
                  },
                ],
              ],
            },
          },
          {
            margin: [19, 2, 19, 0],
            table: {
              widths: ["*"],
              body: [[{ text: "", border: [false, true, false, false] }]],
            },
            layout: {
              hLineColor: function (i: any, node: any) {
                return COLORS.greyBorder;
              },
              vLineColor: function (i: any, node: any) {
                return COLORS.greyBorder;
              },
            },
          },
          {
            margin: [5, 10, 5, 0],
            table: {
              widths: ["*"],
              body: [
                [
                  {
                    table: {
                      widths: ["*", "*"],
                      body: [
                        [
                          {
                            text: [
                              {
                                text: `วันที่ออกใบแจ้งหนี้ :`,
                                style: {
                                  bold: true,
                                },
                              },
                              {
                                text: `  ${props.params?.created_date}`,
                              },
                            ],
                            border: [false, false, false, false],
                          },
                          {
                            text: [
                              {
                                text: `เลขที่ใบแจ้งหนี้ :`,
                                style: {
                                  bold: true,
                                  margin: [0,0,10,0]
                                },
                              },
                              {
                                text: `  ${props.params?.ar_transaction_lot_no}`,
                              },
                            ],
                            border: [false, false, false, false],
                          },
                        ],
                        [
                          {
                            text: [
                              {
                                text: `สิทธิ :`,
                                style: {
                                  bold: true,
                                },
                              },
                              {
                                text: `  ${props.params?.coverage_name}`,
                              },
                            ],
                            border: [false, false, false, false],
                          },
                          {
                            text: [
                              {
                                text: `หน่วยงานต้นสังกัดที่เรียกเก็บ :`,
                                style: {
                                  bold: true,
                                },
                              },
                              {
                                text: `   ${props.params?.payer_name}`,
                              },
                            ],
                            border: [false, false, false, false],
                          },
                        ],
                      ],
                    },
                    border: [true, true, true, true],
                  },
                ],
              ],
            },
            layout: {
              hLineColor: function (i: any, node: any) {
                return COLORS.greyBorder;
              },
              vLineColor: function (i: any, node: any) {
                return COLORS.greyBorder;
              },
            },
          },
          {
            margin: [10, 10, 10, 0],
            table: {
              widths: ["auto", "*", 50, 60, 60, 60], // original [75, "*", 50, 60, 60, 60]
              body: [
                [
                  {
                    text: `Service Code`,
                    style: {
                      bold: true,
                    },
                    border: [false, false, false, true],
                  },
                  {
                    text: `รายการ`,
                    style: {
                      bold: true,
                    },
                    border: [false, false, false, true],
                  },
                  {
                    text: `จำนวน`,
                    style: {
                      bold: true,
                    },
                    alignment: "right",
                    border: [false, false, false, true],
                  },
                  {
                    text: `ส่งเบิก`,
                    style: {
                      bold: true,
                    },
                    alignment: "right",
                    border: [false, false, false, true],
                  },
                  {
                    text: `ต้องชำระ`,
                    style: {
                      bold: true,
                    },
                    alignment: "right",
                    border: [false, false, false, true],
                  },
                  {
                    text: `ค้างชำระ`,
                    style: {
                      bold: true,
                    },
                    alignment: "right",
                    border: [false, false, false, true],
                  },
                ],
              ],
            },
            layout: {
              hLineColor: function (i: any, node: any) {
                return COLORS.greyBorder;
              },
              vLineColor: function (i: any, node: any) {
                return COLORS.greyBorder;
              },
            },
          },
        ],
      };
    },
    styles: {},
    content: [
      {
        margin: [5, 0, 10, 0],
        table: {
          widths: [48, "*", 50, 60, 60, 60], // original [75, "*", 50, 60, 60, 60]
          body: [
            ...billModeData,
            [
              {
                text: ` `,
                border: [false, false, false, false],
                margin: [0, 12, 0, 12],
              },
              {
                text: `รวมทั้งสิ้น`,
                alignment: "center",
                style: {
                  bold: true,
                },
                border: [false, false, false, false],
                colSpan: 2,
                margin: [0, 12, 0, 12],
              },
              {},
              {
                text: `${props.params?.total_send_claim_price}`,
                alignment: "right",
                style: {
                  bold: true,
                },
                border: [false, false, false, false],
                margin: [0, 12, 0, 12],
              },
              {
                text: `${props.params?.total_must_pay_price}`,
                alignment: "right",
                style: {
                  bold: true,
                },
                border: [false, false, false, false],
                margin: [0, 12, 0, 12],
              },
              {
                text: `${props.params?.total_overdue_price}`,
                alignment: "right",
                style: {
                  bold: true,
                },
                border: [false, false, false, false],
                margin: [0, 12, 0, 12],
              },
            ],
          ],
        },
        layout: {
          hLineColor: function (i: any, node: any) {
            return COLORS.greyBorder;
          },
          vLineColor: function (i: any, node: any) {
            return COLORS.greyBorder;
          },
        },
      },
      {
        text: `\n\n`,
      },
      {
        margin: [5, 0, 10, 0],
        unbreakable: true, // issue 67867
        table: {
          widths: ["*", 120, 60],
          body: [
            [
              {
                text: `ลงชื่อ/Signature`,
                alignment: "right",
                border: [false, false, false, false],
                style: {
                  fontSize: 14,
                },
              },
              {
                text: `____________________________`,
                alignment: "left",
                border: [false, false, false, false],
              },
              {
                text: ` `,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: ` `,
                border: [false, false, false, false],
              },
              {
                text: `( ${props?.user_name} )`,
                alignment: "center",
                border: [false, false, false, false],
              },
              {
                text: ` `,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: `ตำแหน่ง/ Cashier ID`,
                alignment: "right",
                border: [false, false, false, false],
                style: {
                  fontSize: 14,
                },
              },
              {
                text: `เจ้าหน้าที่เบิก`,
                alignment: "center",
                border: [false, false, false, false],
              },
              {
                text: ` `,
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
    ],
    images: {
      ...images,
    },
  };
};

export default FormPaymentARReport;
