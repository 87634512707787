import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const ModMedicationSummaryUX = (props: any) => {
    return(
      <div
        style={{width: "100%", padding: "10px", display:"flex",flexDirection:"column"}}>
        <div
          style={{justifyContent: "space-between",display: "none"}}>
          
          <div
            style={{fontSize: "20px", fontWeight: "bold"}}>
            Medication Summary
          </div>
          <div
            style={{marginBottom: "20px"}}>
            {props.closeIcon}
          </div>
        </div>
        <div>
          {props.error}
        </div>
        <div
          style={{display: props.showInternal ? "flex": "none",flexDirection:"column",flexGrow:1}}>
          
          <div
            style={{display: "flex", alignItems: "center", padding: "5px 0px"}}>
            
            <div
              style={{fontSize: "18px", fontWeight: "bold",paddingRight: "5px"}}>
              รายการยาจากประวัติที่เคยใช้ในโรงพยาบาล
            </div>
          </div>
          <div>
            {props.internalItems}
          </div>
          <Table
            columns={props.internalColumns}
            data={props.internalItemsData}
            getTableProps={props.getInternalTableProps}
            getTbodyProps={props.getInternalTbodyProps}
            getTdProps={props.getInternalTdProps}
            minRows={props.internalMin}
            showPagination={false}>
          </Table>
          <div>
            {props.internalTable}
          </div>
        </div>
        <div
          style={{display: props.showExternalInHospital? "flex": "none",flexDirection:"column",flexGrow:1}}>
          
          <div
            style={{display: "flex", alignItems: "center", padding: "5px 0px"}}>
            
            <div
              style={{fontSize: "18px", fontWeight: "bold",paddingRight: "5px"}}>
              External Medication (รายการยาตรวจ พบ ในบัญชียาโรงพยาบาล)
            </div>
            <div
              style={{color: "red"}}>
              *
            </div>
            <div>
              กรุณาเลือกระบุ Trade Product
            </div>
          </div>
          <div>
            {props.externalInHospitalItems}
          </div>
          <Table
            columns={props.externalColumns}
            data={props.externalInHospitalItemsData}
            getTableProps={props.getExternalTableProps}
            getTbodyProps={props.getExternalTbodyProps}
            getTdProps={props.getExternalTdProps}
            minRows={props.externalMin}
            showPagination={false}>
          </Table>
          <div>
            {props.externalTable}
          </div>
        </div>
        <div
          style={{display: props.showExternalOutHospital? "flex": "none", paddingTop: "12px",flexDirection:"column",flexGrow:1}}>
          
          <div
            style={{display: "flex", alignItems: "center", padding: "5px 0px"}}>
            
            <div
              style={{fontSize: "18px", fontWeight: "bold",paddingRight: "5px"}}>
              External Medication (รายการยาที่ตรวจ ไม่พบ ในบัญชียาโรงพยาบาล)
            </div>
            <div
              style={{color: "red"}}>
              *
            </div>
            <div>
              รายการยาจะถูกสั่งเป็น External Medications
            </div>
          </div>
          <div>
            {props.externalOutHospitalItems}
          </div>
          <Table
            data={props.externalOutHospitalItemsData}
            getTableProps={props.getCustomTableProps}
            getTbodyProps={props.getCustomTbodyProps}
            getTdProps={props.getCustomTdProps}
            headers="Drug,Dose,วิธีใช้,Start Date,One Dose,"
            keys="drug,dose,usage,startDate,oneDose,delete"
            minRows={props.customMin}
            showPagination={false}
            widths="auto,100,auto,auto,80,60">
          </Table>
        </div>
        <div
          style={{display: "flex", justifyContent: "flex-end", paddingTop: "20px"}}>
          
          <Button
            color="blue"
            disabled={props.disabledConfirm || props.loading}
            onClick={props.onConfirmOrder}>
            Confirm Order
          </Button>
        </div>
      </div>
    )
}


export default ModMedicationSummaryUX

export const screenPropsDefault = {}

/* Date Time : Mon May 27 2024 09:58:11 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width: \"100%\", padding: \"10px\", display:\"flex\",flexDirection:\"column\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 33,
      "props": {
        "children": {
          "type": "code",
          "value": "props.internalItems"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.showExternalInHospital? \"flex\": \"none\",flexDirection:\"column\",flexGrow:1}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", padding: \"5px 0px\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "External Medication (รายการยาตรวจ พบ ในบัญชียาโรงพยาบาล)"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"18px\", fontWeight: \"bold\",paddingRight: \"5px\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "กรุณาเลือกระบุ Trade Product"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "code",
          "value": "props.externalInHospitalItems"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.showExternalOutHospital? \"flex\": \"none\", paddingTop: \"12px\",flexDirection:\"column\",flexGrow:1}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", padding: \"5px 0px\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": "External Medication (รายการยาที่ตรวจ ไม่พบ ในบัญชียาโรงพยาบาล)"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"18px\", fontWeight: \"bold\",paddingRight: \"5px\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการยาจะถูกสั่งเป็น External Medications"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "code",
          "value": "props.externalOutHospitalItems"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", paddingTop: \"20px\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "Button",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": "Confirm Order"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledConfirm || props.loading"
        },
        "onClick": {
          "type": "code",
          "value": "props.onConfirmOrder"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{justifyContent: \"space-between\",display: \"none\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 28,
      "props": {
        "children": {
          "type": "value",
          "value": "Medication Summary"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"20px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "div",
      "parent": 28,
      "props": {
        "children": {
          "type": "code",
          "value": "props.closeIcon"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"20px\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.showInternal ? \"flex\": \"none\",flexDirection:\"column\",flexGrow:1}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 33,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", padding: \"5px 0px\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "div",
      "parent": 34,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการยาจากประวัติที่เคยใช้ในโรงพยาบาล"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"18px\", fontWeight: \"bold\",paddingRight: \"5px\"}"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.error"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 37,
      "name": "Table",
      "parent": 33,
      "props": {
        "columns": {
          "type": "code",
          "value": "props.internalColumns"
        },
        "data": {
          "type": "code",
          "value": "props.internalItemsData"
        },
        "getTableProps": {
          "type": "code",
          "value": "props.getInternalTableProps"
        },
        "getTbodyProps": {
          "type": "code",
          "value": "props.getInternalTbodyProps"
        },
        "getTdProps": {
          "type": "code",
          "value": "props.getInternalTdProps"
        },
        "headers": {
          "type": "value",
          "value": ""
        },
        "keys": {
          "type": "value",
          "value": ""
        },
        "minRows": {
          "type": "code",
          "value": "props.internalMin"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "widths": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 38,
      "name": "Table",
      "parent": 4,
      "props": {
        "columns": {
          "type": "code",
          "value": "props.externalColumns"
        },
        "data": {
          "type": "code",
          "value": "props.externalInHospitalItemsData"
        },
        "getTableProps": {
          "type": "code",
          "value": "props.getExternalTableProps"
        },
        "getTbodyProps": {
          "type": "code",
          "value": "props.getExternalTbodyProps"
        },
        "getTdProps": {
          "type": "code",
          "value": "props.getExternalTdProps"
        },
        "headers": {
          "type": "value",
          "value": ""
        },
        "keys": {
          "type": "value",
          "value": ""
        },
        "minRows": {
          "type": "code",
          "value": "props.externalMin"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "widths": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 39,
      "name": "Table",
      "parent": 20,
      "props": {
        "data": {
          "type": "code",
          "value": "props.externalOutHospitalItemsData"
        },
        "getTableProps": {
          "type": "code",
          "value": "props.getCustomTableProps"
        },
        "getTbodyProps": {
          "type": "code",
          "value": "props.getCustomTbodyProps"
        },
        "getTdProps": {
          "type": "code",
          "value": "props.getCustomTdProps"
        },
        "headers": {
          "type": "value",
          "value": "Drug,Dose,วิธีใช้,Start Date,One Dose,"
        },
        "keys": {
          "type": "value",
          "value": "drug,dose,usage,startDate,oneDose,delete"
        },
        "minRows": {
          "type": "code",
          "value": "props.customMin"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "widths": {
          "type": "value",
          "value": "auto,100,auto,auto,80,60"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 33,
      "props": {
        "children": {
          "type": "code",
          "value": "props.internalTable"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "code",
          "value": "props.externalTable"
        }
      },
      "seq": 41,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 75,
  "isMounted": false,
  "memo": false,
  "name": "ModMedicationSummaryUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
