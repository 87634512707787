import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  Icon,
  FormField,
  Label,
  Button
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const ModStockOtherStoreUX = (props: any) => {
    return(
      <div
        style={{width:"100%", padding: "10px",  height: "100%"}}>
        <div
          style={{backgroundColor: "var(--primary-theme-color,#5DBCD2)",margin: "-0.75rem -0.75rem -1rem", padding: "1rem", display: "flex", justifyContent: "space-between"}}>
          
          <div
            style={{fontSize: "1.1rem", fontWeight: "bold", padding: "2.5px 0", color: "white"}}>
            Stock in other store    (Within Lot)
          </div>
          <div
            style={{cursor: "pointer", color: "white", marginTop: "2.5px"}}>
            
            <Icon
              className="white"
              name="close"
              onClick={props.onClose}>
            </Icon>
          </div>
        </div>
        <div
          className="ui divider">
          
        </div>
        <div
          className="ui form">
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label
                style={{marginLeft: "0.85rem",color: "rgba(0,0,0,0.87)"}}>
                {props.name || "-"}
              </label>
            </FormField>
          </FormGroup>
        </div>
        <div
          className="ui form"
          style={{padding: "0 10px"}}>
          
          <FormGroup
            inline={true}>
            <FormField>
              <Label
                style={{backgroundColor: "#C3F4FF", color: "rgba(0,0,0,.87)"}}>
                <label
                  style={{marginRight: "1rem"}}>
                  Lot No.
                </label>
                <label>
                  {props.lotNo || "-"}
                </label>
              </Label>
            </FormField>
            <FormField>
              <Label
                style={{backgroundColor: "#C3F4FF", color: "rgba(0,0,0,.87)"}}>
                <label
                  style={{marginRight: "1rem"}}>
                  Expiry date
                </label>
                <label>
                  {props.expireDate || "-"}
                </label>
              </Label>
            </FormField>
          </FormGroup>
        </div>
        <div>
          
          <Table
            data={props.otherStoreList || []}
            getTrProps={props.getTrProps}
            headers={props.headers ? props.headers:  "Store, Balance"}
            keys="store,balance, storage"
            minRows={1}
            showPagination={false}
            style={{maxHeight: "300px", marginBottom: "1rem"}}
            widths="auto,100,200">
          </Table>
        </div>
        <div
          style={{margin: "2rem 0 0.5rem", display: "flex", justifyContent: "center"}}>
          
          <div>
            {props.ButtonSave}
          </div>
          <Button
            color="red"
            onClick={props.onCancel}
            style={{width: "130px", margin: "0 2rem"}}>
            ยกเลิก
          </Button>
        </div>
      </div>
    )
}

export default React.memo(ModStockOtherStoreUX)

export const screenPropsDefault = {}

/* Date Time : Wed Feb 08 2023 07:51:27 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width:\"100%\", padding: \"10px\",  height: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"var(--primary-theme-color,#5DBCD2)\",margin: \"-0.75rem -0.75rem -1rem\", padding: \"1rem\", display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Stock in other store    (Within Lot)"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.1rem\", fontWeight: \"bold\", padding: \"2.5px 0\", color: \"white\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 10px\"}"
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormGroup",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 122,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{cursor: \"pointer\", color: \"white\", marginTop: \"2.5px\"}"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 123,
      "name": "Icon",
      "parent": 122,
      "props": {
        "className": {
          "type": "value",
          "value": "white"
        },
        "name": {
          "type": "value",
          "value": "close"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 124,
      "name": "FormField",
      "parent": 8,
      "props": {
      },
      "seq": 124,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 125,
      "name": "FormField",
      "parent": 8,
      "props": {
      },
      "seq": 125,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 126,
      "name": "Label",
      "parent": 124,
      "props": {
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#C3F4FF\", color: \"rgba(0,0,0,.87)\"}"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 127,
      "name": "Label",
      "parent": 125,
      "props": {
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#C3F4FF\", color: \"rgba(0,0,0,.87)\"}"
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": null,
      "id": 128,
      "name": "label",
      "parent": 126,
      "props": {
        "children": {
          "type": "value",
          "value": "Lot No."
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"1rem\"}"
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": null,
      "id": 129,
      "name": "label",
      "parent": 127,
      "props": {
        "children": {
          "type": "value",
          "value": "Expiry date"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"1rem\"}"
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": null,
      "id": 130,
      "name": "label",
      "parent": 126,
      "props": {
        "children": {
          "type": "code",
          "value": "props.lotNo || \"-\""
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": null,
      "id": 131,
      "name": "label",
      "parent": 127,
      "props": {
        "children": {
          "type": "code",
          "value": "props.expireDate || \"-\""
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": null,
      "id": 132,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 133,
      "name": "FormGroup",
      "parent": 132,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 134,
      "name": "FormField",
      "parent": 133,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": null,
      "id": 135,
      "name": "label",
      "parent": 134,
      "props": {
        "children": {
          "type": "code",
          "value": "props.name || \"-\""
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"0.85rem\",color: \"rgba(0,0,0,0.87)\"}"
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": null,
      "id": 136,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 137,
      "name": "Table",
      "parent": 136,
      "props": {
        "data": {
          "type": "code",
          "value": "props.otherStoreList || []"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getTrProps"
        },
        "headers": {
          "type": "code",
          "value": "props.headers ? props.headers:  \"Store, Balance\""
        },
        "keys": {
          "type": "value",
          "value": "store,balance, storage"
        },
        "minRows": {
          "type": "code",
          "value": "1"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{maxHeight: \"300px\", marginBottom: \"1rem\"}"
        },
        "widths": {
          "type": "value",
          "value": "auto,100,200"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": null,
      "id": 138,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{margin: \"2rem 0 0.5rem\", display: \"flex\", justifyContent: \"center\"}"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 140,
      "name": "Button",
      "parent": 138,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิก"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancel"
        },
        "style": {
          "type": "code",
          "value": "{width: \"130px\", margin: \"0 2rem\"}"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": null,
      "id": 141,
      "name": "div",
      "parent": 138,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSave"
        }
      },
      "seq": 140,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": true,
  "name": "ModStockOtherStoreUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
