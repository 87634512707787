import BaseService from "./BaseService";
import { ADM } from "../../configs/apis";
class ADMService extends BaseService {
  // -------------------------------------------------- ward
  getWardType({ params } = {}) {
    return this.client
      .get(ADM.WARD_TYPE, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  // -------------------------------------------------- room
  getRoomType() {
    return this.client
      .get(ADM.ROOM_TYPE)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  // -------------------------------------------------- status
  getAdmitOrderRoomItemStatus() {
    return this.client
      .get(ADM.ADMIT_ORDER_ROOM_ITEM_STATUS_CHOICE)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  // -------------------------------------------------- admit order
  getAdmitOrder(params) {
    return this.client
      .get(ADM.ADMIT_ORDER, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getAdmitOrderDetail(admitOrderId) {
    return this.client
      .get(ADM.ADMIT_ORDER_DETAIL({ admit_order_id: admitOrderId }))
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getGetAdmitOrder(params) {
    return this.client
      .get(ADM.GET_ADMIT_ORDER, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  estimateAdmit(admitOrderId) {
    return this.client
      .get(ADM.ESTIMATE_ADMIT({ admit_order_id: admitOrderId }))
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  postAdmitOrder(params) {
    return this.client
      .post(ADM.ADMIT_ORDER, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  putAdmitOrder(admitOrderId, params) {
    const url = ADM.ADMIT_ORDER_DETAIL({ admit_order_id: admitOrderId });
    return this.client
      .put(url, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  printAdmitOrder(admitOrderId) {
    const url = ADM.ADMIT_ORDER_PRINT({ admit_order_id: admitOrderId });
    return this.client
      .put(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  // -------------------------------------------------- admit order room item
  getAdmitOrderRoomItem(admitOrderRoomItemId) {
    return this.client
      .get(
        ADM.ADMIT_ORDER_ROOM_ITEM_DETAIL({
          admit_order_room_item_id: admitOrderRoomItemId
        })
      )
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  cancelCheckout(admitOrderRoomItemId, params) {
    const url = ADM.ADMIT_ORDER_ROOM_ITEM_DETAIL({
      admit_order_room_item_id: admitOrderRoomItemId
    });
    return this.client
      .put(url, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  putAdmitOrderRoomItem(admitOrderRoomItemId, params, data) {
    const url = ADM.ADMIT_ORDER_ROOM_ITEM_DETAIL({
      admit_order_room_item_id: admitOrderRoomItemId
    });
    return this.client
      .put(url, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  // -------------------------------------------------- discharge planing summary
  getDischargePlanningSummary(encounter) {
    const url = ADM.DISCHARGE_PLANNING_SUMMARY({ encounter });
    return this.client
      .get(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  // -------------------------------------------------- admit day
  getAdmitDay(encounterId, params, cancelToken) {
    const url = ADM.START_END_WARD({ encounter_id: encounterId });
    return this.client
      .get(url, { params, cancelToken })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
}
export default ADMService;
