import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardDivisionScheduleUX = (props: any) => {
    return(
      <div
        style={{display: "flex", height: "94vh"}}>
        <div
          style={{flex: 1, padding: "5px"}}>

          <div
            style={{display: "flex", alignItems: "center"}}>

            <div
              style={{fontWeight: "bold", fontSize: "1.2rem"}}>
              รายการที่สร้างแล้ว
            </div>
            <div
              style={{flex: 1}}>

            </div>
          </div>
          <Table
            data={props.scheduleTemplates}
            getTrProps={props.templateRowProps}
            headers="ตั้งแต่,ถึง,วันที่เปิด"
            keys="start,end,days"
            showPagination={false}
            style={{height: "90vh"}}>
          </Table>
        </div>
        <div
          style={{flex: 3, padding: "5px", display: "flex", flexDirection: "column", overflowY: "auto"}}>

          <div
            style={{display: "flex", alignItems: "center"}}>

            <div
              style={{margin: "0 10px 0 0"}}>
              ตั้งแต่วันที่
            </div>
            <DateTextBox
              disabled={props.editMode}
              onChange={props.changeDate("startDateTime")}
              value={props.startDateTime}>
            </DateTextBox>
            <div
              style={{margin: "0 10px 0 10px"}}>
              ถึงวันที่
            </div>
            <DateTextBox
              disabled={props.editMode}
              onChange={props.changeDate("endDateTime")}
              value={props.endDateTime}>
            </DateTextBox>
            <Button
              color="blue"
              onClick={props.onClear}
              size="small"
              style={{margin: "0 10px 0 10px"}}>
              เคลียร์ (สร้างรายการใหม่)
            </Button>
            <Button
              color="green"
              onClick={props.onSave}
              size="small"
              style={{margin: "0 10px 0 10px"}}>
              อัพเดท (SAVE)
            </Button>
          </div>
          <div
            style={{display: "flex", flex: 1,  marginTop: "10px"}}>
            {props.weekTemplate}
          </div>
        </div>
      </div>
    )
}

CardDivisionScheduleUX.displayName = "CardDivisionScheduleUX";
export default React.memo(CardDivisionScheduleUX)

export const screenPropsDefault = {}

/* Date Time : Thu Oct 10 2024 16:16:40 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: \"flex\", height: \"94vh\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: 1, padding: \"5px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: 3, padding: \"5px\", display: \"flex\", flexDirection: \"column\", overflowY: \"auto\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 3,
      "name": "Table",
      "parent": 1,
      "props": {
        "data": {
          "type": "code",
          "value": "props.scheduleTemplates"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.templateRowProps"
        },
        "headers": {
          "type": "value",
          "value": "ตั้งแต่,ถึง,วันที่เปิด"
        },
        "keys": {
          "type": "value",
          "value": "start,end,days"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"90vh\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการที่สร้างแล้ว"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.2rem\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "Button",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "เคลียร์ (สร้างรายการใหม่)"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClear"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 10px 0 10px\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "code",
          "value": "props.weekTemplate"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", flex: 1,  marginTop: \"10px\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "ตั้งแต่วันที่"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 10px 0 0\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 11,
      "name": "DateTextBox",
      "parent": 8,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.editMode"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeDate(\"startDateTime\")"
        },
        "value": {
          "type": "code",
          "value": "props.startDateTime"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึงวันที่"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 10px 0 10px\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 13,
      "name": "DateTextBox",
      "parent": 8,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.editMode"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeDate(\"endDateTime\")"
        },
        "value": {
          "type": "code",
          "value": "props.endDateTime"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Button",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "อัพเดท (SAVE)"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSave"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 10px 0 10px\"}"
        }
      },
      "seq": 14,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": true,
  "name": "CardDivisionScheduleUX",
  "project": "cudent2",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
