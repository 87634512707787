export default function toPDFMakeData(props: any) {
  return {
    defaultStyle: {
      font: `THSarabunNew`,
    },
    content: [
      {
        preserveLeadingSpaces: true,
        width: `auto`,
        alignment: `center`,
        text: `Medical Certificate`,
        pageBreak: ``,
        fontSize: `18`,
        color: ``,
        decorationColor: ``,
        bold: `true`,
        decoration: ``,
        decorationStyle: ``,
      },
      {
        decorationStyle: ``,
        decorationColor: ``,
        pageBreak: ``,
        width: `auto`,
        fontSize: `18`,
        alignment: `center`,
        decoration: ``,
        bold: `true`,
        color: ``,
        text: `COVID-19 test`,
        preserveLeadingSpaces: true,
      },
      {
        decorationStyle: ``,
        fontSize: 15,
        bold: false,
        decoration: ``,
        color: ``,
        pageBreak: ``,
        alignment: `left`,
        preserveLeadingSpaces: true,
        decorationColor: ``,
        width: `auto`,
        text: ` `,
      },
      {
        columns: [
          {
            decoration: ``,
            color: ``,
            text: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decorationStyle: ``,
            alignment: `left`,
            fontSize: 15,
            bold: false,
            width: 420,
            pageBreak: ``,
          },
          {
            decorationStyle: ``,
            bold: true,
            text: `Date `,
            width: `auto`,
            preserveLeadingSpaces: true,
            decoration: ``,
            pageBreak: ``,
            alignment: `left`,
            fontSize: 15,
            decorationColor: ``,
            color: ``,
          },
          {
            width: 5,
            fontSize: 15,
            decorationStyle: ``,
            decorationColor: ``,
            text: ``,
            decoration: ``,
            pageBreak: ``,
            color: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            bold: false,
          },
          {
            decoration: ``,
            bold: true,
            decorationStyle: ``,
            text: props.items?.formatCreatedDate,
            pageBreak: ``,
            color: ``,
            alignment: `center`,
            fontSize: 15,
            preserveLeadingSpaces: true,
            width: `auto`,
            decorationColor: ``,
          },
        ],
      },
      {
        decorationColor: ``,
        width: `auto`,
        pageBreak: ``,
        decoration: ``,
        color: ``,
        text: ` `,
        bold: false,
        preserveLeadingSpaces: true,
        fontSize: 15,
        decorationStyle: ``,
        alignment: `left`,
      },
      {
        columns: [
          {
            width: 40,
            color: ``,
            fontSize: 15,
            decoration: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decorationColor: ``,
            pageBreak: ``,
            bold: false,
            text: ``,
          },
          {
            width: `auto`,
            decorationStyle: ``,
            bold: false,
            decoration: ``,
            text: `This is certify that `,
            decorationColor: ``,
            pageBreak: ``,
            alignment: `left`,
            color: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
          },
          {
            pageBreak: ``,
            bold: false,
            decorationColor: ``,
            decoration: ``,
            alignment: `left`,
            color: ``,
            width: 10,
            decorationStyle: ``,
            text: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
          },
          {
            stack: [
              {
                text: `${
                  props.items.patientInfo?.full_name_en !== ""
                    ? props.items?.patientInfo?.full_name_en
                    : props.items?.patientInfo?.full_name_th !== ""
                    ? props.items?.patientInfo?.full_name_th
                    : props.items?.patientInfo?.full_name
                }`,
                width: `auto`,
                decoration: ``,
                margin: [0, 0, 0, -15],
                preserveLeadingSpaces: true,
                color: ``,
                decorationColor: ``,
                bold: false,
                alignment: `left`,
                decorationStyle: ``,
                fontSize: 15,
                pageBreak: ``,
              },
              {
                fontSize: 15,
                pageBreak: ``,
                text: `.........................................................................................................................................................`,
                width: `auto`,
                alignment: `left`,
                decorationColor: ``,
                decorationStyle: ``,
                color: ``,
                decoration: ``,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
                bold: false,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            decorationStyle: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            width: `auto`,
            color: ``,
            bold: false,
            fontSize: 15,
            decorationColor: ``,
            text: `Identification / Passport number / Other `,
            pageBreak: ``,
          },
          {
            decoration: ``,
            decorationColor: ``,
            bold: false,
            decorationStyle: ``,
            color: ``,
            fontSize: 15,
            width: 10,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            text: ``,
            alignment: `left`,
          },
          {
            stack: [
              {
                color: ``,
                decorationStyle: ``,
                bold: false,
                pageBreak: ``,
                text:
                  props.items?.patientInfo?.citizen_no === "" ||
                  props.items?.patientInfo?.citizen_no === "Non citizen identity"
                    ? props.items?.patientInfo?.citizen_passport === "" ||
                      props.items?.patientInfo?.citizen_passport === "Non citizen identity"
                      ? " "
                      : props.items?.patientInfo?.citizen_passport
                    : props.items?.patientInfo?.citizen_no,
                alignment: `left`,
                margin: [0, 0, 0, -15],
                width: `auto`,
                decoration: ``,
                decorationColor: ``,
                fontSize: 15,
                preserveLeadingSpaces: true,
              },
              {
                decorationColor: ``,
                alignment: `left`,
                fontSize: 15,
                bold: false,
                width: `auto`,
                pageBreak: ``,
                color: ``,
                margin: [0, 0, 0, 0],
                text: `................................................................................................................................`,
                decoration: ``,
                preserveLeadingSpaces: true,
                decorationStyle: ``,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            pageBreak: ``,
            decorationStyle: ``,
            bold: false,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decoration: ``,
            text: `Age `,
            fontSize: 15,
            width: `auto`,
            color: ``,
          },
          {
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            text: ``,
            decoration: ``,
            color: ``,
            bold: false,
            width: 10,
            fontSize: 15,
            decorationColor: ``,
            alignment: `left`,
            pageBreak: ``,
          },
          {
            stack: [
              {
                color: ``,
                decorationStyle: ``,
                margin: [0, 0, 0, -15],
                text: `${props.items?.ageYear} years ${props.items?.ageMonth} months ${props.items?.ageDay} days`,
                decorationColor: ``,
                bold: false,
                alignment: `left`,
                decoration: ``,
                fontSize: 15,
                width: `auto`,
                preserveLeadingSpaces: true,
                pageBreak: ``,
              },
              {
                color: ``,
                width: `auto`,
                margin: [0, 0, 0, 0],
                decorationColor: ``,
                alignment: `left`,
                pageBreak: ``,
                decoration: ``,
                bold: false,
                decorationStyle: ``,
                preserveLeadingSpaces: true,
                text: `............................................................................`,
                fontSize: 15,
              },
            ],
          },
          {
            decoration: ``,
            preserveLeadingSpaces: true,
            bold: false,
            width: `auto`,
            alignment: `left`,
            text: `Hospital Number`,
            pageBreak: ``,
            fontSize: 15,
            color: ``,
            decorationColor: ``,
            decorationStyle: ``,
          },
          {
            decoration: ``,
            decorationColor: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            bold: false,
            width: 10,
            fontSize: 15,
            color: ``,
            alignment: `left`,
            text: ``,
            decorationStyle: ``,
          },
          {
            stack: [
              {
                fontSize: 15,
                decorationColor: ``,
                decoration: ``,
                color: ``,
                alignment: `left`,
                text: props.items?.patientInfo?.hn,
                pageBreak: ``,
                width: `auto`,
                margin: [0, 0, 0, -15],
                decorationStyle: ``,
                bold: false,
                preserveLeadingSpaces: true,
              },
              {
                decorationColor: ``,
                bold: false,
                width: `auto`,
                text: `..............................................................................`,
                margin: [0, 0, 0, 0],
                fontSize: 15,
                preserveLeadingSpaces: true,
                alignment: `left`,
                decoration: ``,
                pageBreak: ``,
                decorationStyle: ``,
                color: ``,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            pageBreak: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            color: ``,
            bold: false,
            decoration: ``,
            fontSize: 15,
            text: `Was tested `,
            width: `auto`,
            alignment: `left`,
          },
          {
            pageBreak: ``,
            alignment: `left`,
            bold: false,
            text: ``,
            color: ``,
            decorationColor: ``,
            decoration: ``,
            decorationStyle: ``,
            width: 10,
            preserveLeadingSpaces: true,
            fontSize: 15,
          },
          {
            stack: [
              {
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
                decorationStyle: ``,
                pageBreak: ``,
                text: props.items?.testedResult ? props.items?.testedResult : ` `,
                width: `auto`,
                decorationColor: ``,
                color: ``,
                bold: false,
                decoration: ``,
                alignment: `left`,
                fontSize: 15,
              },
              {
                margin: [0, 0, 0, 0],
                preserveLeadingSpaces: true,
                pageBreak: ``,
                alignment: `left`,
                text: `..............................................................`,
                color: ``,
                decorationStyle: ``,
                decoration: ``,
                width: `auto`,
                decorationColor: ``,
                bold: false,
                fontSize: 15,
              },
            ],
          },
          {
            alignment: `left`,
            fontSize: 15,
            margin: [-38, 0, 0, 0],
            decorationColor: ``,
            width: `auto`,
            text: `for COVID-19 by`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            bold: false,
            color: ``,
            decoration: ``,
            decorationStyle: ``,
          },
          {
            alignment: `left`,
            pageBreak: ``,
            decorationColor: ``,
            width: 10,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decoration: ``,
            text: ``,
            decorationStyle: ``,
            bold: false,
            color: ``,
          },
          {
            stack: [
              {
                decoration: ``,
                color: ``,
                decorationColor: ``,
                pageBreak: ``,
                fontSize: 15,
                decorationStyle: ``,
                width: `auto`,
                text: props.items?.testedMethod ? props.items?.testedMethod : ` `,
                preserveLeadingSpaces: true,
                bold: false,
                alignment: `left`,
                margin: [0, 0, 0, -15],
              },
              {
                text: `................................................................................`,
                preserveLeadingSpaces: true,
                alignment: `left`,
                margin: [0, 0, 0, 0],
                bold: false,
                pageBreak: ``,
                decorationColor: ``,
                color: ``,
                decoration: ``,
                fontSize: 15,
                decorationStyle: ``,
                width: `auto`,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            width: `auto`,
            decorationColor: ``,
            decorationStyle: ``,
            bold: false,
            text: `on Swab date `,
            color: ``,
            decoration: ``,
            alignment: `left`,
            fontSize: 15,
            preserveLeadingSpaces: true,
          },
          {
            color: ``,
            width: 10,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            fontSize: 15,
            decoration: ``,
            bold: false,
            text: ``,
            decorationColor: ``,
            decorationStyle: ``,
          },
          {
            stack: [
              {
                pageBreak: ``,
                color: ``,
                preserveLeadingSpaces: true,
                decoration: ``,
                decorationColor: ``,
                bold: false,
                alignment: `left`,
                decorationStyle: ``,
                text: props.items?.swabDate ? props.items?.swabDate : ` `,
                width: `auto`,
                margin: [0, 0, 0, -15],
                fontSize: 15,
              },
              {
                decorationStyle: ``,
                fontSize: 15,
                alignment: `left`,
                decoration: ``,
                color: ``,
                bold: false,
                text: `.........................................................`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
                decorationColor: ``,
                pageBreak: ``,
                width: `auto`,
              },
            ],
          },
          {
            alignment: `left`,
            decorationStyle: ``,
            fontSize: 15,
            decorationColor: ``,
            bold: false,
            decoration: ``,
            margin: [-25, 0, 0, 0],
            preserveLeadingSpaces: true,
            width: `auto`,
            pageBreak: ``,
            color: ``,
            text: `and Examination date`,
          },
          {
            width: 10,
            text: ``,
            decorationColor: ``,
            decoration: ``,
            bold: false,
            fontSize: 15,
            preserveLeadingSpaces: true,
            color: ``,
            decorationStyle: ``,
            alignment: `left`,
            pageBreak: ``,
          },
          {
            stack: [
              {
                bold: false,
                pageBreak: ``,
                decorationColor: ``,
                width: `auto`,
                text: props.items?.examDate,
                margin: [0, 0, 0, -15],
                color: ``,
                decoration: ``,
                fontSize: 15,
                decorationStyle: ``,
                preserveLeadingSpaces: true,
                alignment: `left`,
              },
              {
                bold: false,
                decorationColor: ``,
                fontSize: 15,
                preserveLeadingSpaces: true,
                color: ``,
                pageBreak: ``,
                decorationStyle: ``,
                alignment: `left`,
                margin: [0, 0, 0, 0],
                width: `auto`,
                decoration: ``,
                text: `.....................................................................`,
              },
            ],
          },
        ],
      },
      {
        alignment: `left`,
        decorationColor: ``,
        decorationStyle: ``,
        fontSize: 15,
        pageBreak: ``,
        text: `Patient is advised to be under care of medical staff according to the guidelines from the Ministry of Public`,
        preserveLeadingSpaces: true,
        bold: false,
        width: `auto`,
        color: ``,
        decoration: ``,
      },
      {
        pageBreak: ``,
        color: ``,
        width: `auto`,
        decoration: ``,
        decorationColor: ``,
        bold: false,
        text: `Health (MOPH)`,
        alignment: `left`,
        fontSize: 15,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
      },
      {
        fontSize: 15,
        decorationColor: ``,
        pageBreak: ``,
        decorationStyle: ``,
        text: ` `,
        alignment: `left`,
        bold: false,
        preserveLeadingSpaces: true,
        decoration: ``,
        color: ``,
        width: `auto`,
      },
      {
        columns: [
          {
            pageBreak: ``,
            decorationColor: ``,
            decoration: ``,
            color: ``,
            bold: `true`,
            decorationStyle: ``,
            fontSize: 15,
            text: `Other Recommendation :`,
            width: `auto`,
            preserveLeadingSpaces: true,
            alignment: `left`,
          },
          {
            color: ``,
            decoration: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            bold: false,
            decorationStyle: ``,
            pageBreak: ``,
            text: ``,
            fontSize: 15,
            width: 10,
            alignment: `left`,
          },
          {
            stack: [
              {
                margin: [0, 0, 0, -15],
                preserveLeadingSpaces: true,
                bold: false,
                pageBreak: ``,
                alignment: `left`,
                color: ``,
                width: `auto`,
                decorationColor: ``,
                decoration: ``,
                fontSize: 15,
                decorationStyle: ``,
                text:
                  props.items?.comment == null || props.items?.comment === ""
                    ? " "
                    : props.items?.comment,
              },
              {
                bold: false,
                alignment: `left`,
                fontSize: 15,
                color: ``,
                width: `auto`,
                decorationColor: ``,
                preserveLeadingSpaces: true,
                text: `........................................................................................................................................................`,
                margin: [0, 0, 0, 0],
                pageBreak: ``,
                decoration: ``,
                decorationStyle: ``,
              },
            ],
          },
        ],
      },
      {
        decoration: ``,
        text: `...............................................................................................................................................................................................................`,
        fontSize: 15,
        alignment: `left`,
        decorationColor: ``,
        width: `auto`,
        color: ``,
        decorationStyle: ``,
        preserveLeadingSpaces: true,
        margin: [0, 0, 0, 0],
        bold: false,
        pageBreak: ``,
      },
      {
        preserveLeadingSpaces: true,
        alignment: `left`,
        decorationColor: ``,
        fontSize: 15,
        decoration: ``,
        bold: false,
        width: `auto`,
        color: ``,
        decorationStyle: ``,
        margin: [0, 0, 0, 0],
        pageBreak: ``,
        text: `...............................................................................................................................................................................................................`,
      },
      {
        fontSize: 15,
        pageBreak: ``,
        color: ``,
        decorationStyle: ``,
        decoration: ``,
        preserveLeadingSpaces: true,
        text: ` `,
        alignment: `left`,
        decorationColor: ``,
        bold: false,
        width: `auto`,
      },
      {
        columns: [
          {
            decorationStyle: ``,
            text: `Patient required period of absence for`,
            color: ``,
            bold: false,
            decorationColor: ``,
            alignment: `left`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
            fontSize: 15,
            decoration: ``,
          },
          {
            fontSize: 15,
            width: 10,
            margin: [0, 0, 0, 0],
            color: ``,
            preserveLeadingSpaces: true,
            bold: false,
            decorationStyle: ``,
            alignment: `left`,
            pageBreak: ``,
            decorationColor: ``,
            text: ``,
            decoration: ``,
          },
          {
            stack: [
              {
                text: props.items?.absenceDuration ? props.items?.absenceDuration : " ",
                color: ``,
                width: `auto`,
                decorationColor: ``,
                alignment: `center`,
                pageBreak: ``,
                decoration: ``,
                bold: false,
                decorationStyle: ``,
                fontSize: 15,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `....................`,
                color: ``,
                width: `auto`,
                decorationColor: ``,
                alignment: `left`,
                pageBreak: ``,
                decoration: ``,
                bold: false,
                decorationStyle: ``,
                fontSize: 15,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
            width: `auto`,
          },
          {
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            fontSize: 15,
            alignment: `left`,
            decorationStyle: ``,
            decorationColor: ``,
            bold: false,
            decoration: ``,
            text: ``,
            pageBreak: ``,
            width: 10,
            color: ``,
          },
          {
            width: `auto`,
            text: `days             from`,
            decorationColor: ``,
            fontSize: 15,
            bold: false,
            pageBreak: ``,
            color: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decoration: ``,
            alignment: `left`,
          },
          {
            pageBreak: ``,
            color: ``,
            text: ``,
            alignment: `left`,
            fontSize: 15,
            decorationColor: ``,
            width: 10,
            margin: [0, 0, 0, 0],
            decoration: ``,
            bold: false,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
          },
          {
            stack: [
              {
                text: props.items?.absenceStartDate ? props.items?.absenceStartDate : " ",
                color: ``,
                width: `auto`,
                decorationColor: ``,
                alignment: `center`,
                pageBreak: ``,
                decoration: ``,
                bold: false,
                decorationStyle: ``,
                fontSize: 15,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.........................`,
                color: ``,
                width: `auto`,
                decorationColor: ``,
                alignment: `left`,
                pageBreak: ``,
                decoration: ``,
                bold: false,
                decorationStyle: ``,
                fontSize: 15,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
            width: `auto`,
          },
          {
            fontSize: 15,
            text: ``,
            decorationStyle: ``,
            width: 10,
            color: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            decoration: ``,
            pageBreak: ``,
            bold: false,
          },
          {
            pageBreak: ``,
            decorationColor: ``,
            decoration: ``,
            alignment: `left`,
            bold: false,
            preserveLeadingSpaces: true,
            color: ``,
            text: `to`,
            fontSize: 15,
            width: `auto`,
            decorationStyle: ``,
          },
          {
            bold: false,
            preserveLeadingSpaces: true,
            color: ``,
            fontSize: 15,
            alignment: `left`,
            decorationColor: ``,
            text: ``,
            width: 10,
            decoration: ``,
            pageBreak: ``,
            decorationStyle: ``,
          },
          {
            stack: [
              {
                text: props.items?.absenceEndDate ? props.items?.absenceEndDate : " ",
                color: ``,
                width: `auto`,
                decorationColor: ``,
                alignment: `center`,
                pageBreak: ``,
                decoration: ``,
                bold: false,
                decorationStyle: ``,
                fontSize: 15,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.........................`,
                color: ``,
                width: `auto`,
                decorationColor: ``,
                alignment: `left`,
                pageBreak: ``,
                decoration: ``,
                bold: false,
                decorationStyle: ``,
                fontSize: 15,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
            width: `auto`,
          },
        ],
      },
      {
        color: ``,
        bold: false,
        fontSize: 15,
        width: `auto`,
        alignment: `left`,
        decoration: ``,
        pageBreak: ``,
        preserveLeadingSpaces: true,
        decorationColor: ``,
        text: ` `,
        decorationStyle: ``,
      },
      {
        preserveLeadingSpaces: true,
        pageBreak: ``,
        color: ``,
        decorationColor: ``,
        alignment: `left`,
        decoration: ``,
        decorationStyle: ``,
        text: ` `,
        bold: false,
        width: `auto`,
        fontSize: 15,
      },
      {
        pageBreak: ``,
        decorationStyle: ``,
        bold: false,
        fontSize: 15,
        width: `auto`,
        decorationColor: ``,
        color: ``,
        text: ` `,
        preserveLeadingSpaces: true,
        decoration: ``,
        alignment: `left`,
      },
      {
        color: ``,
        decorationColor: ``,
        width: `auto`,
        decorationStyle: ``,
        alignment: `left`,
        pageBreak: ``,
        text: ` `,
        preserveLeadingSpaces: true,
        fontSize: 15,
        bold: false,
        decoration: ``,
      },
      {
        width: `auto`,
        preserveLeadingSpaces: true,
        decorationColor: ``,
        decoration: ``,
        text: ` `,
        alignment: `left`,
        pageBreak: ``,
        decorationStyle: ``,
        bold: false,
        fontSize: 15,
        color: ``,
      },
      {
        preserveLeadingSpaces: true,
        decorationColor: ``,
        fontSize: 15,
        decoration: ``,
        decorationStyle: ``,
        text: ` `,
        width: `auto`,
        alignment: `left`,
        color: ``,
        bold: false,
        pageBreak: ``,
      },
      {
        width: `auto`,
        decoration: ``,
        bold: false,
        decorationStyle: ``,
        text: ` `,
        pageBreak: ``,
        color: ``,
        preserveLeadingSpaces: true,
        fontSize: 15,
        alignment: `left`,
        decorationColor: ``,
      },
      {
        text: ``,
        decorationStyle: ``,
        color: ``,
        decoration: ``,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        width: `auto`,
        pageBreak: ``,
        bold: false,
        alignment: `left`,
        fontSize: 15,
      },
      {
        columns: [
          {
            text: `Physician’s Signature `,
            pageBreak: ``,
            fontSize: 15,
            width: `auto`,
            decorationColor: ``,
            decorationStyle: ``,
            alignment: `left`,
            color: ``,
            bold: false,
            preserveLeadingSpaces: true,
            decoration: ``,
          },
          {
            decorationStyle: ``,
            text: ``,
            fontSize: 15,
            bold: false,
            alignment: `left`,
            decoration: ``,
            color: ``,
            pageBreak: ``,
            decorationColor: ``,
            width: 30,
            preserveLeadingSpaces: true,
          },
          {
            color: ``,
            decoration: ``,
            decorationColor: ``,
            text: `.............................................................`,
            decorationStyle: ``,
            alignment: `center`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            bold: false,
            fontSize: 15,
            width: `auto`,
          },
          {
            text: ``,
            alignment: `right`,
            decoration: ``,
            decorationColor: ``,
            bold: false,
            width: 30,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            fontSize: 15,
            color: ``,
          },
          {
            color: ``,
            bold: false,
            pageBreak: ``,
            fontSize: 15,
            text: ` Medical License No. `,
            decoration: ``,
            decorationStyle: ``,
            alignment: `left`,
            decorationColor: ``,
            width: `auto`,
            preserveLeadingSpaces: true,
          },
          {
            pageBreak: ``,
            decoration: ``,
            color: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            bold: false,
            alignment: `left`,
            width: 15,
            text: ``,
            fontSize: 15,
            decorationColor: ``,
          },
          {
            alignment: `center`,
            color: ``,
            fontSize: 15,
            decorationStyle: `dotted`,
            decoration: `underline`,
            text: props.items?.doctorCertNumber
              ? props.items?.doctorCertNumber
              : "                    ",
            decorationColor: ``,
            preserveLeadingSpaces: true,
            bold: false,
            pageBreak: ``,
            width: `auto`,
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            width: 144,
            text: ``,
            color: ``,
            decoration: ``,
            alignment: `left`,
            pageBreak: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            bold: false,
            decorationColor: ``,
          },
          {
            decoration: ``,
            decorationColor: ``,
            alignment: `center`,
            width: `auto`,
            fontSize: 15,
            decorationStyle: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            color: ``,
            text: `(${props.items?.doctorNameEn})`,
            bold: false,
          },
        ],
      },
    ],
    pageOrientation: `portrait`,
    pageSize: `A4`,
    header: props.header,
    footer: props.footer,
  };
}
