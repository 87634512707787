import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  FormField,
  Dropdown,
  Input,
  Radio
} from 'semantic-ui-react'

const ANES_PreANES_03_Airway = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        id="CardPreAnestheticAirwayEvaluation"
        style={{ width: "100%", height: "100%" ,padding:"10px"}}>
        <div
          style={{backgroundColor: "#EDF6F9",width: "100%",padding:"20px 10px 10px",borderRadius: 3,border : "solid 0.5px ",borderColor:"#5DBCD2"}}>
          
          <div
            style={{ display: "flex", justifyContent: "flex-end", marginBottom: "10px"}}>
            
            <div
              style={{ display: props.PreAnestheticSequence?.status_label === "Confirm" ?  "none" : "",}}>
              {props.buttonSave}
            </div>
          </div>
          <Form>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={6}>
                <label
                  style={{minWidth: "120px", maxWidth: "120px"}}>
                  Mallampati grade
                </label>
                <Dropdown
                  clearable={true}
                  fluid={true}
                  name="MallampatiGrade"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.preanes_mallampati || []}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.PreAnestheticSequence?.data?.MallampatiGrade || ""}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}
                width={5}>
                <label
                  style={{minWidth: "105px", maxWidth: "105px"}}>
                  Thyromental distance
                </label>
                <Dropdown
                  clearable={true}
                  fluid={true}
                  name="ThyromentalDistance"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.preanes_thyromental || []}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.PreAnestheticSequence?.data?.ThyromentalDistance || ""}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}
                width={5}>
                <label
                  style={{minWidth: "110px", maxWidth: "110px"}}>
                  Mouth opening
                </label>
                <Dropdown
                  clearable={true}
                  fluid={true}
                  name="MouthOpening"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.preanes_mouth_opening || []}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.PreAnestheticSequence?.data?.MouthOpening || ""}>
                </Dropdown>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={6}>
                <label
                  style={{minWidth: "120px", maxWidth: "120px"}}>
                  Patency of nares
                </label>
                <Dropdown
                  clearable={true}
                  fluid={true}
                  name="PatencyOfNares"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.preanes_patency_nares || []}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.PreAnestheticSequence?.data?.PatencyOfNares || ""}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}
                width={5}>
                <label
                  style={{minWidth: "105px", maxWidth: "105px"}}>
                  Prominent incisor
                </label>
                <Dropdown
                  clearable={true}
                  fluid={true}
                  name="ProminentIncisor"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.preanes_prominent || []}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.PreAnestheticSequence?.data?.ProminentIncisor || ""}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}
                width={5}>
                <label
                  style={{minWidth: "110px", maxWidth: "110px"}}>
                  Limitation of head /neck motility
                </label>
                <Dropdown
                  clearable={true}
                  fluid={true}
                  name="LimitationOfHeadNeckMotility"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.preanes_limitation || []}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.PreAnestheticSequence?.data?.LimitationOfHeadNeckMotility || ""}>
                </Dropdown>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={6}>
                <label
                  style={{minWidth: "120px", maxWidth: "120px"}}>
                  Dificult intubation expected
                </label>
                <Dropdown
                  clearable={true}
                  fluid={true}
                  name="DifficultIntubationExpected"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.preanes_difficult || []}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.PreAnestheticSequence?.data?.DifficultIntubationExpected || ""}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}
                width={10}>
                <label
                  style={{minWidth: "105px", maxWidth: "105px"}}>
                  Comment
                </label>
                <Input
                  fluid={true}
                  name="Comment"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.Comment || ""}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}>
                <label
                  style={{minWidth: "120px", maxWidth: "120px"}}>
                  Artificial airway
                </label>
              </FormField>
              <FormField
                inline={true}>
                <Radio
                  checked={props.PreAnestheticSequence?.data?.ArtificialAirway === "none"}
                  label="None"
                  name="ArtificialAirway"
                  onChange={props.onChangeData}
                  value="none">
                </Radio>
              </FormField>
              <FormField
                inline={true}>
                <Radio
                  checked={props.PreAnestheticSequence?.data?.ArtificialAirway === "ett"}
                  label="ETT"
                  name="ArtificialAirway"
                  onChange={props.onChangeData}
                  value="ett">
                </Radio>
              </FormField>
              <FormField
                inline={true}
                style={{minWidth: "max-content"}}>
                <Radio
                  checked={props.PreAnestheticSequence?.data?.ArtificialAirway === "tracheostomy_tube"}
                  label="Tracheostomy tube"
                  name="ArtificialAirway"
                  onChange={props.onChangeData}
                  style={{minWidth: "max-content"}}
                  value="tracheostomy_tube">
                </Radio>
              </FormField>
              <FormField>
              </FormField>
              <FormField
                inline={true}
                width={10}>
                <label>
                  Comment
                </label>
                <Input
                  fluid={true}
                  name="ArtificialAirwayIsCommentTube"
                  onChange={props.onChangeData}
                  style={{width: "100%"}}
                  value={props.PreAnestheticSequence?.data?.ArtificialAirwayIsCommentTube || ""}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={16}>
                <label
                  style={{minWidth: "120px", maxWidth: "120px"}}>
                  Dental
                </label>
                <Input
                  name="Dental"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.Dental || ""}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}>
                <label
                  style={{minWidth: "120px", maxWidth: "120px"}}>
                  Systemic evaluation
                </label>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={6}>
                <label
                  style={{minWidth: "120px", maxWidth: "120px",paddingLeft: "10px", fontWeight: "normal"}}>
                  Respiratory
                </label>
                <Dropdown
                  clearable={true}
                  fluid={true}
                  name="Respiratory"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.preanes_respiratory || []}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.PreAnestheticSequence?.data?.Respiratory || ""}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}
                width={5}>
                <Input
                  disabled={props.PreAnestheticSequence?.data?.Respiratory !== "Abnormal"}
                  name="RespiratoryRemark"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.RespiratoryRemark || ""}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={6}>
                <label
                  style={{minWidth: "120px", maxWidth: "120px",paddingLeft: "10px", fontWeight: "normal"}}>
                  Cardiovascular
                </label>
                <Dropdown
                  clearable={true}
                  fluid={true}
                  name="Cardiovascular"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.preanes_cardiovascul || []}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.PreAnestheticSequence?.data?.Cardiovascular || ""}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}
                width={5}>
                <Input
                  disabled={props.PreAnestheticSequence?.data?.Cardiovascular !== "Abnormal"}
                  name="CardiovascularRemark"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.CardiovascularRemark || ""}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={6}>
                <label
                  style={{minWidth: "120px", maxWidth: "120px",paddingLeft: "10px", fontWeight: "normal"}}>
                  Neurologic
                </label>
                <Dropdown
                  clearable={true}
                  fluid={true}
                  name="Neurologic"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.preanes_neurologic || []}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.PreAnestheticSequence?.data?.Neurologic || ""}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}
                width={5}>
                <Input
                  disabled={props.PreAnestheticSequence?.data?.Neurologic !== "Abnormal"}
                  name="NeurologicRemark"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.NeurologicRemark || ""}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={6}>
                <label
                  style={{minWidth: "120px", maxWidth: "120px",paddingLeft: "10px", fontWeight: "normal"}}>
                  EENT
                </label>
                <Dropdown
                  clearable={true}
                  fluid={true}
                  name="EENT"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.preanes_EENT || []}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.PreAnestheticSequence?.data?.EENT || ""}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}
                width={5}>
                <Input
                  disabled={props.PreAnestheticSequence?.data?.EENT !== "Abnormal"}
                  name="EENTRemark"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.EENTRemark || ""}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={6}>
                <label
                  style={{minWidth: "120px", maxWidth: "120px",paddingLeft: "10px", fontWeight: "normal"}}>
                  GI
                </label>
                <Dropdown
                  clearable={true}
                  fluid={true}
                  name="GI"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.preanes_gi || []}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.PreAnestheticSequence?.data?.GI || ""}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}
                width={5}>
                <Input
                  disabled={props.PreAnestheticSequence?.data?.GI !== "Abnormal"}
                  name="GIRemark"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.GIRemark || ""}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={6}>
                <label
                  style={{minWidth: "120px", maxWidth: "120px",paddingLeft: "10px", fontWeight: "normal"}}>
                  Genitourinary
                </label>
                <Dropdown
                  clearable={true}
                  fluid={true}
                  name="Genitourinary"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.preanes_genitourinar || []}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.PreAnestheticSequence?.data?.Genitourinary || ""}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}
                width={5}>
                <Input
                  disabled={props.PreAnestheticSequence?.data?.Genitourinary !== "Abnormal"}
                  name="GenitourinaryRemark"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.GenitourinaryRemark || ""}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={6}>
                <label
                  style={{minWidth: "120px", maxWidth: "120px",paddingLeft: "10px", fontWeight: "normal"}}>
                  Endocrine and metobolic
                </label>
                <Dropdown
                  clearable={true}
                  fluid={true}
                  name="EndocrineAndMetabolic"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.preanes_Endocrine || []}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.PreAnestheticSequence?.data?.EndocrineAndMetabolic || ""}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}
                width={5}>
                <Input
                  disabled={props.PreAnestheticSequence?.data?.EndocrineAndMetabolic !== "Abnormal"}
                  name="EndocrineAndMetabolicRemark"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.EndocrineAndMetabolicRemark || ""}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={6}>
                <label
                  style={{minWidth: "120px", maxWidth: "120px",paddingLeft: "10px", fontWeight: "normal"}}>
                  Skin/ Brest
                </label>
                <Dropdown
                  clearable={true}
                  fluid={true}
                  name="SkinBreast"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.preanes_skin_breast || []}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.PreAnestheticSequence?.data?.SkinBreast || ""}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}
                width={5}>
                <Input
                  disabled={props.PreAnestheticSequence?.data?.SkinBreast !== "Abnormal"}
                  name="SkinBreastRemark"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.SkinBreastRemark || ""}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={6}>
                <label
                  style={{minWidth: "120px", maxWidth: "120px",paddingLeft: "10px", fontWeight: "normal"}}>
                  Immunology/ Hematology
                </label>
                <Dropdown
                  clearable={true}
                  fluid={true}
                  name="ImmunologyHematology"
                  onChange={props.onChangeData}
                  options={props.masterOptions?.preanes_Immunology || []}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.PreAnestheticSequence?.data?.ImmunologyHematology || ""}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}
                width={5}>
                <Input
                  disabled={props.PreAnestheticSequence?.data?.ImmunologyHematology !== "Abnormal"}
                  name="ImmunologyHematologyRemake"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.ImmunologyHematologyRemake || ""}>
                </Input>
              </FormField>
            </FormGroup>
          </Form>
        </div>
      </div>
    )
}


export default ANES_PreANES_03_Airway

export const screenPropsDefault = {}

/* Date Time : Fri Dec 15 2023 10:52:11 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAnestheticAirwayEvaluation"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"100%\" ,padding:\"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#EDF6F9\",width: \"100%\",padding:\"20px 10px 10px\",borderRadius: 3,border : \"solid 0.5px \",borderColor:\"#5DBCD2\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 183,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"flex-end\", marginBottom: \"10px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 184,
      "name": "div",
      "parent": 183,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAnestheticSequence?.status_label === \"Confirm\" ?  \"none\" : \"\",}"
        }
      },
      "seq": 184,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 203,
      "name": "Form",
      "parent": 1,
      "props": {
      },
      "seq": 203,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 204,
      "name": "FormGroup",
      "parent": 203,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 204,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 205,
      "name": "FormField",
      "parent": 204,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 205,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 206,
      "name": "FormField",
      "parent": 204,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 206,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 207,
      "name": "FormField",
      "parent": 204,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 207,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 208,
      "name": "FormGroup",
      "parent": 203,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 208,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 209,
      "name": "FormField",
      "parent": 208,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 209,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 210,
      "name": "FormField",
      "parent": 208,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 210,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 211,
      "name": "FormField",
      "parent": 208,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 211,
      "void": false
    },
    {
      "from": null,
      "id": 212,
      "name": "label",
      "parent": 205,
      "props": {
        "children": {
          "type": "value",
          "value": "Mallampati grade"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"120px\", maxWidth: \"120px\"}"
        }
      },
      "seq": 212,
      "void": false
    },
    {
      "from": null,
      "id": 213,
      "name": "label",
      "parent": 206,
      "props": {
        "children": {
          "type": "value",
          "value": "Thyromental distance"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"105px\", maxWidth: \"105px\"}"
        }
      },
      "seq": 213,
      "void": false
    },
    {
      "from": null,
      "id": 214,
      "name": "label",
      "parent": 207,
      "props": {
        "children": {
          "type": "value",
          "value": "Mouth opening"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"110px\", maxWidth: \"110px\"}"
        }
      },
      "seq": 214,
      "void": false
    },
    {
      "from": null,
      "id": 215,
      "name": "label",
      "parent": 209,
      "props": {
        "children": {
          "type": "value",
          "value": "Patency of nares"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"120px\", maxWidth: \"120px\"}"
        }
      },
      "seq": 215,
      "void": false
    },
    {
      "from": null,
      "id": 216,
      "name": "label",
      "parent": 210,
      "props": {
        "children": {
          "type": "value",
          "value": "Prominent incisor"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"105px\", maxWidth: \"105px\"}"
        }
      },
      "seq": 216,
      "void": false
    },
    {
      "from": null,
      "id": 217,
      "name": "label",
      "parent": 211,
      "props": {
        "children": {
          "type": "value",
          "value": "Limitation of head /neck motility"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"110px\", maxWidth: \"110px\"}"
        }
      },
      "seq": 217,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 218,
      "name": "Dropdown",
      "parent": 205,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "MallampatiGrade"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_mallampati || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.MallampatiGrade || \"\""
        }
      },
      "seq": 218,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 219,
      "name": "Dropdown",
      "parent": 206,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "ThyromentalDistance"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_thyromental || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.ThyromentalDistance || \"\""
        }
      },
      "seq": 219,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 220,
      "name": "Dropdown",
      "parent": 207,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "MouthOpening"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_mouth_opening || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.MouthOpening || \"\""
        }
      },
      "seq": 220,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 221,
      "name": "Dropdown",
      "parent": 209,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "PatencyOfNares"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_patency_nares || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PatencyOfNares || \"\""
        }
      },
      "seq": 221,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 222,
      "name": "Dropdown",
      "parent": 210,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "ProminentIncisor"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_prominent || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.ProminentIncisor || \"\""
        }
      },
      "seq": 222,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 223,
      "name": "Dropdown",
      "parent": 211,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "LimitationOfHeadNeckMotility"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_limitation || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.LimitationOfHeadNeckMotility || \"\""
        }
      },
      "seq": 223,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 224,
      "name": "FormGroup",
      "parent": 203,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 224,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 225,
      "name": "FormField",
      "parent": 224,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 225,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 226,
      "name": "FormField",
      "parent": 224,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "10"
        }
      },
      "seq": 226,
      "void": false
    },
    {
      "from": null,
      "id": 227,
      "name": "label",
      "parent": 225,
      "props": {
        "children": {
          "type": "value",
          "value": "Dificult intubation expected"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"120px\", maxWidth: \"120px\"}"
        }
      },
      "seq": 227,
      "void": false
    },
    {
      "from": null,
      "id": 228,
      "name": "label",
      "parent": 226,
      "props": {
        "children": {
          "type": "value",
          "value": "Comment"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"105px\", maxWidth: \"105px\"}"
        }
      },
      "seq": 228,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 229,
      "name": "Dropdown",
      "parent": 225,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "DifficultIntubationExpected"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_difficult || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.DifficultIntubationExpected || \"\""
        }
      },
      "seq": 229,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 230,
      "name": "Input",
      "parent": 226,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "Comment"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.Comment || \"\""
        }
      },
      "seq": 230,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 231,
      "name": "FormGroup",
      "parent": 203,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 231,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 232,
      "name": "FormField",
      "parent": 231,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 232,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 233,
      "name": "FormField",
      "parent": 231,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 233,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 234,
      "name": "FormField",
      "parent": 231,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 234,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 235,
      "name": "FormField",
      "parent": 231,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 235,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 236,
      "name": "FormField",
      "parent": 231,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "10"
        }
      },
      "seq": 243,
      "void": false
    },
    {
      "from": null,
      "id": 237,
      "name": "label",
      "parent": 232,
      "props": {
        "children": {
          "type": "value",
          "value": "Artificial airway"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"120px\", maxWidth: \"120px\"}"
        }
      },
      "seq": 237,
      "void": false
    },
    {
      "from": null,
      "id": 238,
      "name": "label",
      "parent": 236,
      "props": {
        "children": {
          "type": "value",
          "value": "Comment"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 238,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 239,
      "name": "Radio",
      "parent": 233,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.ArtificialAirway === \"none\""
        },
        "label": {
          "type": "value",
          "value": "None"
        },
        "name": {
          "type": "value",
          "value": "ArtificialAirway"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "none"
        }
      },
      "seq": 239,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 240,
      "name": "Radio",
      "parent": 234,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.ArtificialAirway === \"ett\""
        },
        "label": {
          "type": "value",
          "value": "ETT"
        },
        "name": {
          "type": "value",
          "value": "ArtificialAirway"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "ett"
        }
      },
      "seq": 240,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 241,
      "name": "Radio",
      "parent": 235,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.ArtificialAirway === \"tracheostomy_tube\""
        },
        "label": {
          "type": "value",
          "value": "Tracheostomy tube"
        },
        "name": {
          "type": "value",
          "value": "ArtificialAirway"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        },
        "value": {
          "type": "value",
          "value": "tracheostomy_tube"
        }
      },
      "seq": 241,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 242,
      "name": "Input",
      "parent": 236,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "ArtificialAirwayIsCommentTube"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.ArtificialAirwayIsCommentTube || \"\""
        }
      },
      "seq": 242,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 243,
      "name": "FormField",
      "parent": 231,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 236,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 244,
      "name": "FormGroup",
      "parent": 203,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 244,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 245,
      "name": "FormGroup",
      "parent": 203,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 245,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 246,
      "name": "FormField",
      "parent": 244,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 246,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 247,
      "name": "FormField",
      "parent": 245,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 247,
      "void": false
    },
    {
      "from": null,
      "id": 248,
      "name": "label",
      "parent": 246,
      "props": {
        "children": {
          "type": "value",
          "value": "Dental"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"120px\", maxWidth: \"120px\"}"
        }
      },
      "seq": 248,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 250,
      "name": "Input",
      "parent": 246,
      "props": {
        "name": {
          "type": "value",
          "value": "Dental"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.Dental || \"\""
        }
      },
      "seq": 250,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 251,
      "name": "FormGroup",
      "parent": 203,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 251,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 252,
      "name": "FormGroup",
      "parent": 203,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 252,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 253,
      "name": "FormGroup",
      "parent": 203,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 253,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 254,
      "name": "FormGroup",
      "parent": 203,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 254,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 255,
      "name": "FormGroup",
      "parent": 203,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 255,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 256,
      "name": "FormGroup",
      "parent": 203,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 256,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 257,
      "name": "FormGroup",
      "parent": 203,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 257,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 258,
      "name": "FormGroup",
      "parent": 203,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 258,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 259,
      "name": "FormGroup",
      "parent": 203,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 259,
      "void": false
    },
    {
      "from": null,
      "id": 261,
      "name": "label",
      "parent": 247,
      "props": {
        "children": {
          "type": "value",
          "value": "Systemic evaluation"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"120px\", maxWidth: \"120px\"}"
        }
      },
      "seq": 261,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 262,
      "name": "FormField",
      "parent": 251,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 262,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 263,
      "name": "FormField",
      "parent": 251,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 263,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 264,
      "name": "FormField",
      "parent": 252,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 264,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 265,
      "name": "FormField",
      "parent": 252,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 265,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 266,
      "name": "FormField",
      "parent": 253,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 266,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 267,
      "name": "FormField",
      "parent": 253,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 267,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 268,
      "name": "FormField",
      "parent": 254,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 268,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 269,
      "name": "FormField",
      "parent": 254,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 269,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 270,
      "name": "FormField",
      "parent": 255,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 270,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 271,
      "name": "FormField",
      "parent": 255,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 271,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 272,
      "name": "FormField",
      "parent": 256,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 272,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 273,
      "name": "FormField",
      "parent": 256,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 273,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 274,
      "name": "FormField",
      "parent": 257,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 274,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 275,
      "name": "FormField",
      "parent": 257,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 275,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 276,
      "name": "FormField",
      "parent": 258,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 276,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 277,
      "name": "FormField",
      "parent": 258,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 277,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 278,
      "name": "FormField",
      "parent": 259,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 278,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 279,
      "name": "FormField",
      "parent": 259,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 279,
      "void": false
    },
    {
      "from": null,
      "id": 280,
      "name": "label",
      "parent": 262,
      "props": {
        "children": {
          "type": "value",
          "value": "Respiratory"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"120px\", maxWidth: \"120px\",paddingLeft: \"10px\", fontWeight: \"normal\"}"
        }
      },
      "seq": 280,
      "void": false
    },
    {
      "from": null,
      "id": 281,
      "name": "label",
      "parent": 264,
      "props": {
        "children": {
          "type": "value",
          "value": "Cardiovascular"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"120px\", maxWidth: \"120px\",paddingLeft: \"10px\", fontWeight: \"normal\"}"
        }
      },
      "seq": 281,
      "void": false
    },
    {
      "from": null,
      "id": 282,
      "name": "label",
      "parent": 266,
      "props": {
        "children": {
          "type": "value",
          "value": "Neurologic"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"120px\", maxWidth: \"120px\",paddingLeft: \"10px\", fontWeight: \"normal\"}"
        }
      },
      "seq": 282,
      "void": false
    },
    {
      "from": null,
      "id": 283,
      "name": "label",
      "parent": 268,
      "props": {
        "children": {
          "type": "value",
          "value": "EENT"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"120px\", maxWidth: \"120px\",paddingLeft: \"10px\", fontWeight: \"normal\"}"
        }
      },
      "seq": 283,
      "void": false
    },
    {
      "from": null,
      "id": 284,
      "name": "label",
      "parent": 270,
      "props": {
        "children": {
          "type": "value",
          "value": "GI"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"120px\", maxWidth: \"120px\",paddingLeft: \"10px\", fontWeight: \"normal\"}"
        }
      },
      "seq": 284,
      "void": false
    },
    {
      "from": null,
      "id": 285,
      "name": "label",
      "parent": 272,
      "props": {
        "children": {
          "type": "value",
          "value": "Genitourinary"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"120px\", maxWidth: \"120px\",paddingLeft: \"10px\", fontWeight: \"normal\"}"
        }
      },
      "seq": 285,
      "void": false
    },
    {
      "from": null,
      "id": 286,
      "name": "label",
      "parent": 274,
      "props": {
        "children": {
          "type": "value",
          "value": "Endocrine and metobolic"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"120px\", maxWidth: \"120px\",paddingLeft: \"10px\", fontWeight: \"normal\"}"
        }
      },
      "seq": 286,
      "void": false
    },
    {
      "from": null,
      "id": 287,
      "name": "label",
      "parent": 276,
      "props": {
        "children": {
          "type": "value",
          "value": "Skin/ Brest"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"120px\", maxWidth: \"120px\",paddingLeft: \"10px\", fontWeight: \"normal\"}"
        }
      },
      "seq": 287,
      "void": false
    },
    {
      "from": null,
      "id": 288,
      "name": "label",
      "parent": 278,
      "props": {
        "children": {
          "type": "value",
          "value": "Immunology/ Hematology"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"120px\", maxWidth: \"120px\",paddingLeft: \"10px\", fontWeight: \"normal\"}"
        }
      },
      "seq": 288,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 289,
      "name": "Dropdown",
      "parent": 262,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "Respiratory"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_respiratory || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.Respiratory || \"\""
        }
      },
      "seq": 289,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 290,
      "name": "Dropdown",
      "parent": 264,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "Cardiovascular"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_cardiovascul || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.Cardiovascular || \"\""
        }
      },
      "seq": 290,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 291,
      "name": "Dropdown",
      "parent": 266,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "Neurologic"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_neurologic || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.Neurologic || \"\""
        }
      },
      "seq": 291,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 292,
      "name": "Dropdown",
      "parent": 268,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "EENT"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_EENT || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.EENT || \"\""
        }
      },
      "seq": 292,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 293,
      "name": "Dropdown",
      "parent": 270,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "GI"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_gi || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.GI || \"\""
        }
      },
      "seq": 293,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 294,
      "name": "Dropdown",
      "parent": 272,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "Genitourinary"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_genitourinar || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.Genitourinary || \"\""
        }
      },
      "seq": 294,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 295,
      "name": "Dropdown",
      "parent": 274,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "EndocrineAndMetabolic"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_Endocrine || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.EndocrineAndMetabolic || \"\""
        }
      },
      "seq": 295,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 296,
      "name": "Dropdown",
      "parent": 276,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "SkinBreast"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_skin_breast || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.SkinBreast || \"\""
        }
      },
      "seq": 296,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 297,
      "name": "Dropdown",
      "parent": 278,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "ImmunologyHematology"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.preanes_Immunology || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.ImmunologyHematology || \"\""
        }
      },
      "seq": 297,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 298,
      "name": "Input",
      "parent": 263,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.Respiratory !== \"Abnormal\""
        },
        "name": {
          "type": "value",
          "value": "RespiratoryRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.RespiratoryRemark || \"\""
        }
      },
      "seq": 298,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 299,
      "name": "Input",
      "parent": 265,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.Cardiovascular !== \"Abnormal\""
        },
        "name": {
          "type": "value",
          "value": "CardiovascularRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.CardiovascularRemark || \"\""
        }
      },
      "seq": 299,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 300,
      "name": "Input",
      "parent": 267,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.Neurologic !== \"Abnormal\""
        },
        "name": {
          "type": "value",
          "value": "NeurologicRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.NeurologicRemark || \"\""
        }
      },
      "seq": 300,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 301,
      "name": "Input",
      "parent": 269,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.EENT !== \"Abnormal\""
        },
        "name": {
          "type": "value",
          "value": "EENTRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.EENTRemark || \"\""
        }
      },
      "seq": 301,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 302,
      "name": "Input",
      "parent": 271,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.GI !== \"Abnormal\""
        },
        "name": {
          "type": "value",
          "value": "GIRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.GIRemark || \"\""
        }
      },
      "seq": 302,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 303,
      "name": "Input",
      "parent": 273,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.Genitourinary !== \"Abnormal\""
        },
        "name": {
          "type": "value",
          "value": "GenitourinaryRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.GenitourinaryRemark || \"\""
        }
      },
      "seq": 303,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 304,
      "name": "Input",
      "parent": 275,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.EndocrineAndMetabolic !== \"Abnormal\""
        },
        "name": {
          "type": "value",
          "value": "EndocrineAndMetabolicRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.EndocrineAndMetabolicRemark || \"\""
        }
      },
      "seq": 304,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 305,
      "name": "Input",
      "parent": 277,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.SkinBreast !== \"Abnormal\""
        },
        "name": {
          "type": "value",
          "value": "SkinBreastRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.SkinBreastRemark || \"\""
        }
      },
      "seq": 305,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 306,
      "name": "Input",
      "parent": 279,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.ImmunologyHematology !== \"Abnormal\""
        },
        "name": {
          "type": "value",
          "value": "ImmunologyHematologyRemake"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.ImmunologyHematologyRemake || \"\""
        }
      },
      "seq": 306,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "ANES_PreANES_03_Airway",
  "project": "IsHealth_by_Front-end",
  "screenPropsDefault": {
  },
  "width": 90
}

*********************************************************************************** */
