import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  FormField,
  Input,
  TextArea
} from 'semantic-ui-react'

const ANES_PreANES_04_Blood = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        id="CardPreAnestheticBloodChemistry"
        style={{ width: "100%", height: "100%" ,padding:"10px"}}>
        <div
          style={{backgroundColor: "#EDF6F9",width: "100%",padding:"20px 10px 10px",borderRadius: 3,border : "solid 0.5px ",borderColor:"#5DBCD2"}}>
          
          <div
            style={{ display: "flex", justifyContent: "flex-end", marginBottom: "1rem"}}>
            
            <div
              style={{ display: props.PreAnestheticSequence?.status_label === "Confirm" ?  "none" : "",}}>
              {props.buttonSave}
            </div>
          </div>
          <Form>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={4}>
                <label
                  style={{minWidth: "50px", maxWidth: "50px"}}>
                  Hb
                </label>
                <Input
                  fluid={true}
                  name="HbTime"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.HbTime || ""}>
                </Input>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <label
                  style={{minWidth: "30px", maxWidth: "30px"}}>
                  Hct
                </label>
                <Input
                  fluid={true}
                  name="HcTime"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.HcTime || ""}>
                </Input>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <label
                  style={{minWidth: "30px", maxWidth: "30px"}}>
                   Pit
                </label>
                <Input
                  fluid={true}
                  name="PltTime"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.PltTime || ""}>
                </Input>
              </FormField>
              <FormField
                inline={true}
                style={{padding:0}}
                width={4}>
                <label
                  style={{minWidth: "30px", maxWidth: "30px"}}>
                  WBC
                </label>
                <Input
                  fluid={true}
                  name="WBCTime"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.WBCTime || ""}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={4}>
                <label
                  style={{minWidth: "50px", maxWidth: "50px"}}>
                  PT
                </label>
                <Input
                  fluid={true}
                  name="PTTime"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.PTTime || ""}>
                </Input>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <label
                  style={{minWidth: "30px", maxWidth: "30px"}}>
                  PTT
                </label>
                <Input
                  fluid={true}
                  name="PTTTime"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.PTTTime || ""}>
                </Input>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <label
                  style={{minWidth: "30px", maxWidth: "30px"}}>
                  INR
                </label>
                <Input
                  fluid={true}
                  name="INRTime"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.INRTime || ""}>
                </Input>
              </FormField>
              <FormField
                inline={true}
                width={4}>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={4}>
                <label
                  style={{minWidth: "50px", maxWidth: "50px"}}>
                  Na
                </label>
                <Input
                  fluid={true}
                  name="NaTime"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.NaTime || ""}>
                </Input>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <label
                  style={{minWidth: "30px", maxWidth: "30px"}}>
                  K
                </label>
                <Input
                  fluid={true}
                  name="KTime"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.KTime || ""}>
                </Input>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <label
                  style={{minWidth: "30px", maxWidth: "30px"}}>
                  Cl
                </label>
                <Input
                  fluid={true}
                  name="ClTime"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.ClTime || ""}>
                </Input>
              </FormField>
              <FormField
                inline={true}
                style={{padding:0}}
                width={4}>
                <label
                  style={{minWidth: "30px", maxWidth: "30px"}}>
                  CO2
                </label>
                <Input
                  fluid={true}
                  name="CO2Time"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.CO2Time || ""}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              widths={4}>
              <FormField
                inline={true}
                width={4}>
                <label
                  style={{minWidth: "50px", maxWidth: "50px"}}>
                  BUN
                </label>
                <Input
                  fluid={true}
                  name="BunTime"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.BunTime || ""}>
                </Input>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <label
                  style={{minWidth: "30px", maxWidth: "30px"}}>
                  Cr
                </label>
                <Input
                  fluid={true}
                  name="CrTime"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.CrTime || ""}>
                </Input>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <label
                  style={{minWidth: "30px", maxWidth: "30px"}}>
                  GFR
                </label>
                <Input
                  fluid={true}
                  name="GFRTime"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.GFRTime || ""}>
                </Input>
              </FormField>
              <FormField
                inline={true}
                style={{padding:0}}
                width={4}>
                <label
                  style={{minWidth: "30px", maxWidth: "30px"}}>
                  BS
                </label>
                <Input
                  fluid={true}
                  name="BSTIME"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.BSTIME || ""}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={4}>
                <label
                  style={{minWidth: "50px", maxWidth: "50px"}}>
                  AST
                </label>
                <Input
                  fluid={true}
                  name="ASTTime"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.ASTTime || ""}>
                </Input>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <label
                  style={{minWidth: "30px", maxWidth: "30px"}}>
                  ALT
                </label>
                <Input
                  fluid={true}
                  name="ALTTime"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.ALTTime || ""}>
                </Input>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <label
                  style={{minWidth: "30px", maxWidth: "30px"}}>
                  Alp
                </label>
                <Input
                  fluid={true}
                  name="AlpTime"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.AlpTime || ""}>
                </Input>
              </FormField>
              <FormField
                inline={true}
                style={{padding:0}}
                width={4}>
                <label
                  style={{minWidth: "30px", maxWidth: "30px"}}>
                  Alb
                </label>
                <Input
                  fluid={true}
                  name="AlbTime"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.AlbTime || ""}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={4}>
                <label
                  style={{minWidth: "50px", maxWidth: "50px"}}>
                  Other
                </label>
                <Input
                  fluid={true}
                  name="OthersTime"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.OthersTime || ""}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                style={{alignItems: "flex-start"}}
                width={8}>
                <label
                  style={{minWidth: "50px", maxWidth: "50px"}}>
                  CXR
                </label>
                <TextArea
                  name="CXR"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.CXR || ""}>
                </TextArea>
              </FormField>
              <FormField
                inline={true}
                style={{alignItems: "flex-start", paddingRight:0}}
                width={8}>
                <label
                  style={{minWidth: "30px", maxWidth: "30px"}}>
                  EKG
                </label>
                <TextArea
                  name="EKG"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.EKG || ""}>
                </TextArea>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                style={{alignItems: "flex-start"}}
                width={8}>
                <label
                  style={{minWidth: "50px", maxWidth: "50px"}}>
                  Imaging
                </label>
                <TextArea
                  name="Imaging"
                  onChange={props.onChangeData}
                  value={props.PreAnestheticSequence?.data?.Imaging || ""}>
                </TextArea>
              </FormField>
            </FormGroup>
          </Form>
        </div>
      </div>
    )
}


export default ANES_PreANES_04_Blood

export const screenPropsDefault = {}

/* Date Time : Fri Dec 01 2023 16:40:41 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPreAnestheticBloodChemistry"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"100%\" ,padding:\"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#EDF6F9\",width: \"100%\",padding:\"20px 10px 10px\",borderRadius: 3,border : \"solid 0.5px \",borderColor:\"#5DBCD2\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 109,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"flex-end\", marginBottom: \"1rem\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 111,
      "name": "div",
      "parent": 109,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.PreAnestheticSequence?.status_label === \"Confirm\" ?  \"none\" : \"\",}"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 112,
      "name": "Form",
      "parent": 1,
      "props": {
      },
      "seq": 112,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 113,
      "name": "FormGroup",
      "parent": 112,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 114,
      "name": "FormField",
      "parent": 113,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 115,
      "name": "FormField",
      "parent": 113,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 116,
      "name": "FormField",
      "parent": 113,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 117,
      "name": "FormField",
      "parent": 113,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding:0}"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 118,
      "name": "FormGroup",
      "parent": 112,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 119,
      "name": "FormGroup",
      "parent": 112,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 120,
      "name": "FormGroup",
      "parent": 112,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 121,
      "name": "FormGroup",
      "parent": 112,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 122,
      "name": "FormField",
      "parent": 118,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 123,
      "name": "FormField",
      "parent": 118,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 124,
      "name": "FormField",
      "parent": 118,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 125,
      "name": "FormField",
      "parent": 118,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 126,
      "name": "FormField",
      "parent": 119,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 127,
      "name": "FormField",
      "parent": 119,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 128,
      "name": "FormField",
      "parent": 119,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 129,
      "name": "FormField",
      "parent": 119,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding:0}"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 130,
      "name": "FormField",
      "parent": 120,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 131,
      "name": "FormField",
      "parent": 120,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 132,
      "name": "FormField",
      "parent": 120,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 133,
      "name": "FormField",
      "parent": 120,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding:0}"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 134,
      "name": "FormField",
      "parent": 121,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 135,
      "name": "FormField",
      "parent": 121,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 136,
      "name": "FormField",
      "parent": 121,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 137,
      "name": "FormField",
      "parent": 121,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding:0}"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": null,
      "id": 138,
      "name": "label",
      "parent": 114,
      "props": {
        "children": {
          "type": "value",
          "value": "Hb"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"50px\", maxWidth: \"50px\"}"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": null,
      "id": 139,
      "name": "label",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": "Hct"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"30px\", maxWidth: \"30px\"}"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": null,
      "id": 140,
      "name": "label",
      "parent": 116,
      "props": {
        "children": {
          "type": "value",
          "value": " Pit"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"30px\", maxWidth: \"30px\"}"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": null,
      "id": 141,
      "name": "label",
      "parent": 117,
      "props": {
        "children": {
          "type": "value",
          "value": "WBC"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"30px\", maxWidth: \"30px\"}"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": null,
      "id": 142,
      "name": "label",
      "parent": 122,
      "props": {
        "children": {
          "type": "value",
          "value": "PT"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"50px\", maxWidth: \"50px\"}"
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": null,
      "id": 143,
      "name": "label",
      "parent": 123,
      "props": {
        "children": {
          "type": "value",
          "value": "PTT"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"30px\", maxWidth: \"30px\"}"
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": null,
      "id": 144,
      "name": "label",
      "parent": 124,
      "props": {
        "children": {
          "type": "value",
          "value": "INR"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"30px\", maxWidth: \"30px\"}"
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": null,
      "id": 146,
      "name": "label",
      "parent": 126,
      "props": {
        "children": {
          "type": "value",
          "value": "Na"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"50px\", maxWidth: \"50px\"}"
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": null,
      "id": 147,
      "name": "label",
      "parent": 127,
      "props": {
        "children": {
          "type": "value",
          "value": "K"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"30px\", maxWidth: \"30px\"}"
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": null,
      "id": 148,
      "name": "label",
      "parent": 128,
      "props": {
        "children": {
          "type": "value",
          "value": "Cl"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"30px\", maxWidth: \"30px\"}"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": null,
      "id": 149,
      "name": "label",
      "parent": 129,
      "props": {
        "children": {
          "type": "value",
          "value": "CO2"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"30px\", maxWidth: \"30px\"}"
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": null,
      "id": 150,
      "name": "label",
      "parent": 130,
      "props": {
        "children": {
          "type": "value",
          "value": "BUN"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"50px\", maxWidth: \"50px\"}"
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": null,
      "id": 151,
      "name": "label",
      "parent": 131,
      "props": {
        "children": {
          "type": "value",
          "value": "Cr"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"30px\", maxWidth: \"30px\"}"
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": null,
      "id": 152,
      "name": "label",
      "parent": 132,
      "props": {
        "children": {
          "type": "value",
          "value": "GFR"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"30px\", maxWidth: \"30px\"}"
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": null,
      "id": 153,
      "name": "label",
      "parent": 133,
      "props": {
        "children": {
          "type": "value",
          "value": "BS"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"30px\", maxWidth: \"30px\"}"
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": null,
      "id": 154,
      "name": "label",
      "parent": 134,
      "props": {
        "children": {
          "type": "value",
          "value": "AST"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"50px\", maxWidth: \"50px\"}"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": null,
      "id": 155,
      "name": "label",
      "parent": 135,
      "props": {
        "children": {
          "type": "value",
          "value": "ALT"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"30px\", maxWidth: \"30px\"}"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": null,
      "id": 156,
      "name": "label",
      "parent": 136,
      "props": {
        "children": {
          "type": "value",
          "value": "Alp"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"30px\", maxWidth: \"30px\"}"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": null,
      "id": 157,
      "name": "label",
      "parent": 137,
      "props": {
        "children": {
          "type": "value",
          "value": "Alb"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"30px\", maxWidth: \"30px\"}"
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 158,
      "name": "Input",
      "parent": 114,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "HbTime"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.HbTime || \"\""
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 159,
      "name": "Input",
      "parent": 115,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "HcTime"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.HcTime || \"\""
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 160,
      "name": "Input",
      "parent": 116,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "PltTime"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PltTime || \"\""
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 161,
      "name": "Input",
      "parent": 117,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "WBCTime"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.WBCTime || \"\""
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 162,
      "name": "Input",
      "parent": 122,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "PTTime"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PTTime || \"\""
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 163,
      "name": "Input",
      "parent": 123,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "PTTTime"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.PTTTime || \"\""
        }
      },
      "seq": 163,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 164,
      "name": "Input",
      "parent": 124,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "INRTime"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.INRTime || \"\""
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 166,
      "name": "Input",
      "parent": 126,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "NaTime"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.NaTime || \"\""
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 167,
      "name": "Input",
      "parent": 127,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "KTime"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.KTime || \"\""
        }
      },
      "seq": 167,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 168,
      "name": "Input",
      "parent": 128,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "ClTime"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.ClTime || \"\""
        }
      },
      "seq": 168,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 169,
      "name": "Input",
      "parent": 129,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "CO2Time"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.CO2Time || \"\""
        }
      },
      "seq": 169,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 170,
      "name": "Input",
      "parent": 130,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "BunTime"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.BunTime || \"\""
        }
      },
      "seq": 170,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 171,
      "name": "Input",
      "parent": 131,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "CrTime"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.CrTime || \"\""
        }
      },
      "seq": 171,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 172,
      "name": "Input",
      "parent": 132,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "GFRTime"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.GFRTime || \"\""
        }
      },
      "seq": 172,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 173,
      "name": "Input",
      "parent": 133,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "BSTIME"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.BSTIME || \"\""
        }
      },
      "seq": 173,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 174,
      "name": "Input",
      "parent": 134,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "ASTTime"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.ASTTime || \"\""
        }
      },
      "seq": 174,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 175,
      "name": "Input",
      "parent": 135,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "ALTTime"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.ALTTime || \"\""
        }
      },
      "seq": 175,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 176,
      "name": "Input",
      "parent": 136,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "AlpTime"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.AlpTime || \"\""
        }
      },
      "seq": 176,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 177,
      "name": "Input",
      "parent": 137,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "AlbTime"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.AlbTime || \"\""
        }
      },
      "seq": 177,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 178,
      "name": "FormGroup",
      "parent": 112,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 179,
      "name": "FormField",
      "parent": 178,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 179,
      "void": false
    },
    {
      "from": null,
      "id": 180,
      "name": "label",
      "parent": 179,
      "props": {
        "children": {
          "type": "value",
          "value": "Other"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"50px\", maxWidth: \"50px\"}"
        }
      },
      "seq": 180,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 181,
      "name": "Input",
      "parent": 179,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "OthersTime"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.OthersTime || \"\""
        }
      },
      "seq": 181,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 182,
      "name": "FormGroup",
      "parent": 112,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 182,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 183,
      "name": "FormGroup",
      "parent": 112,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 183,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 184,
      "name": "FormField",
      "parent": 182,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{alignItems: \"flex-start\"}"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 184,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 185,
      "name": "FormField",
      "parent": 182,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{alignItems: \"flex-start\", paddingRight:0}"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 185,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 186,
      "name": "FormField",
      "parent": 183,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{alignItems: \"flex-start\"}"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 186,
      "void": false
    },
    {
      "from": null,
      "id": 187,
      "name": "label",
      "parent": 184,
      "props": {
        "children": {
          "type": "value",
          "value": "CXR"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"50px\", maxWidth: \"50px\"}"
        }
      },
      "seq": 187,
      "void": false
    },
    {
      "from": null,
      "id": 188,
      "name": "label",
      "parent": 185,
      "props": {
        "children": {
          "type": "value",
          "value": "EKG"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"30px\", maxWidth: \"30px\"}"
        }
      },
      "seq": 188,
      "void": false
    },
    {
      "from": null,
      "id": 189,
      "name": "label",
      "parent": 186,
      "props": {
        "children": {
          "type": "value",
          "value": "Imaging"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"50px\", maxWidth: \"50px\"}"
        }
      },
      "seq": 189,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 190,
      "name": "TextArea",
      "parent": 186,
      "props": {
        "name": {
          "type": "value",
          "value": "Imaging"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.Imaging || \"\""
        }
      },
      "seq": 190,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 191,
      "name": "TextArea",
      "parent": 185,
      "props": {
        "name": {
          "type": "value",
          "value": "EKG"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.EKG || \"\""
        }
      },
      "seq": 191,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 192,
      "name": "TextArea",
      "parent": 184,
      "props": {
        "name": {
          "type": "value",
          "value": "CXR"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.PreAnestheticSequence?.data?.CXR || \"\""
        }
      },
      "seq": 192,
      "void": true
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "ANES_PreANES_04_Blood",
  "project": "IsHealth_by_Front-end",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
