import CONFIG from "config/config";
import moment from "moment";

// Utils
import { formatDatetime } from "react-lib/utils/dateUtils";
import CompanyLogoForm from "react-lib/apps/HISV3/common/CompanyLogoForm";

const FORM_NAME = "FormAddStock";

const COLORS = {
  primary: "#5dbcd2",
  blue: "#063bab",
  light_blue: "#b2f1ff",
  grey: "#d9d9d9",
};

const FormAddStock = async (props: any) => {
  console.log("FormRequestStock", props);

  const companyLogoForm = await CompanyLogoForm({
    type: 2,
    font: "THSarabunNew",
    height: 30,
    form: FORM_NAME,
  });
  const { font, images } = companyLogoForm;

  return {
    pageSize: "A4",
    defaultStyle: {
      font: font,
      lineHeight: 1,
      fontSize: 18,
    },
    pageMargins: [25, props.hideCode ? 230 : 270, 30, 30],
    styles: {
      fieldHeader: {
        fontSize: 28,
        bold: true,
        color: COLORS.blue,
      },
      tableHeader: {
        fontSize: 22,
        bold: true,
      },
      fieldKey: {
        bold: true,
        fontSize: 22,
      },
      fieldValue: {
        fontSize: 22,
      },
      miniFieldKey: {
        bold: true,
        fontSize: 18,
      },
      miniFieldValue: {
        fontSize: 18,
      },
      footerValue: {
        fontSize: 18,
      },
    },
    header: (currentPage: number, pageCount: number) => {
      return {
        margin: [25, 20, 25, 0],
        stack: [
          {
            text: "ใบเติมสินค้า",
            style: "fieldHeader",
            alignment: "center",
          },
          {
            layout: "noBorders",
            table: {
              widths: ["100%"],
              heights: 1,
              body: [[{ text: "", fillColor: COLORS.primary }]],
            },
          },
          props.hideCode
            ? { text: "" }
            : ({
                margin: [0, 10, 0, 0],
                alignment: "right",
                stack: [
                  {
                    svg: `<svg width="190" height="30">
            <rect width="190" height="30" style="fill:${COLORS.light_blue};"  rx="5"/>
          </svg>`,
                  },
                  {
                    text: ["เลขที่ใบขอ: ", { text: "", bold: false }],
                    style: "fieldKey",
                    relativePosition: { x: -13.5, y: -27.5 },
                  },
                ],
              } as any),
          {
            margin: [0, 25, 0, 0],
            stack: [
              [["วันที่เติม: ", props.datetime]],
              [["เติมไปยังคลังสินค้า: ", props.storageName]],
              [["ผู้เติม: ", props.editor]],
            ].map((item) => ({
              columns: [
                {
                  width: "100%",
                  text: [item[0][0], { text: item[0][1], bold: false }],
                  style: "fieldKey",
                },
              ],
            })),
          },
          {
            margin: [0, 20, 0, 0],
            text: "รายการสินค้า",
            style: "fieldKey",
          },
        ],
      };
    },
    content: [
      {
        table: {
          headerRows: 1,
          widths: ["15%", "30%", "15%", "20%", "10%", "10%"],
          body: [
            [
              { text: "Code", style: "tableHeader", fillColor: COLORS.grey },
              { text: "Name", style: "tableHeader", fillColor: COLORS.grey },
              {
                text: "Lot No.",
                style: "tableHeader",
                alignment: "center",
                fillColor: COLORS.grey,
              },
              {
                text: "Expiry date",
                style: "tableHeader",
                alignment: "center",
                fillColor: COLORS.grey,
              },
              {
                text: "จำนวน",
                style: "tableHeader",
                alignment: "center",
                fillColor: COLORS.grey,
              },
              {
                text: "รวม",
                style: "tableHeader",
                alignment: "center",
                fillColor: COLORS.grey,
              },
            ],
            ...props.items.map((item: any) => [
              { text: item.code, style: "fieldValue", rowSpan: item.rowSpan },

              { text: item.name, style: "fieldValue", rowSpan: item.rowSpan },
              { text: item.lot_no, style: "fieldValue", alignment: "center" },
              {
                text: item.expire_date,
                style: "fieldValue",
                alignment: "center",
              },
              {
                text: item.quantity,
                style: "fieldValue",
                alignment: "center",
              },
              { text: item.total, style: "fieldValue", alignment: "center" },
            ]),
          ],
        },
      },
    ],
    footer: function (currentPage: number, pageCount: number) {
      return {
        margin: [25, 0, 25, 0],
        stack: [
          {
            table: {
              widths: ["100%"],
              body: [[{ text: "", border: [false, true, false, false] }]],
            },
          },
          {
            columns: [
              {
                width: "auto",
                text: `พิมพ์เอกสารโดย ${props.staff}`,
                style: "footerValue",
              },
              {
                width: "*",
                margin: [15, 0, 0, 0],
                text: formatDatetime(moment()),
                style: "footerValue",
              },
              {
                width: "auto",
                text: `หน้าที่ ${currentPage}/${pageCount}`,
                style: "footerValue",
              },
            ],
          },
        ],
      };
    },
    images: {
      ...images,
    },
  };
};

export default FormAddStock;
