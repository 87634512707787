import React from "react";
import { IntlProvider, FormattedMessage, useIntl } from "react-intl";

import { TextArea, Icon } from "semantic-ui-react";

const CardDrugVerbalOrderUX = (props: any) => {
  return (
    <div>
      <div
        style={{
          padding: "10px",
          fontSize: "1.2rem",
          fontWeight: "bold",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div style={{ paddingRight: "10px" }}>เพิ่มรายการคำสั่งยา แบบ Freetext</div>
        <Icon
          name={props.hideDrugTxt ? "plus green" : "minus red"}
          onClick={props.onClickDrugTxt}
        ></Icon>
      </div>
      <div
        className="ui form"
        style={{
          padding: "10px",
          fontWeight: "bold",
          display: props.hideDrugTxt ? "none" : "flex",
          alignItems: "center",
        }}
      >
        <TextArea
          onChange={props.noteChange}
          style={{ height: "300px", width: "100%" }}
          value={props.note}
        ></TextArea>
      </div>
    </div>
  );
};

export default CardDrugVerbalOrderUX;

export const screenPropsDefault = {};

/* Date Time : Fri Oct 25 2024 11:30:22 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", fontSize: \"1.2rem\", fontWeight: \"bold\" ,display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", fontWeight: \"bold\" ,display: props.hideDrugTxt ? \"none\" : \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มรายการคำสั่งยา แบบ Freetext"
        },
        "style": {
          "type": "code",
          "value": "{ paddingRight: \"10px\" }"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "TextArea",
      "parent": 2,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.noteChange"
        },
        "style": {
          "type": "code",
          "value": "{height: \"300px\", width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.note"
        }
      },
      "seq": 5,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "Icon",
      "parent": 1,
      "props": {
        "name": {
          "type": "code",
          "value": "props.hideDrugTxt ? \"plus green\" : \"minus red\""
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickDrugTxt"
        }
      },
      "seq": 12,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardDrugVerbalOrderUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
