
/* Generated apis ---------------------- */
import { to } from 'react-lib/utils'
import BaseService from  'react-lib/apis/services/BaseService'
import config from '../../config/config'
import UserCreateWithPasswordSerializer from '../types/UserCreateWithPasswordSerializer_users'


const HOST = `${config.API_HOST}`
/* ['generics.CreateAPIView'] */
/* params:  */
/* data:  */
const CreateUserAPIViewM : 
{
  create:   ({params, data, apiToken, extra}:
             { 
                params?: any,
                data?: UserCreateWithPasswordSerializer,
                apiToken?: any,
                extra?: any
            }) => any,
} = 
{
  create: async ({params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken)
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
      base.client.defaults.headers["content-type"] = "multipart/form-data"
    if (extra?.division)
      base.client.defaults.headers["division"] = extra.division;
    if (extra?.device)
      base.client.defaults.headers["device"] = extra.device;
      const formData = new FormData();
      for (const [key, value] of Object.entries(data || {})) {
        formData.append(key, value)
      }
    const result = await to(base.client.post(`${HOST}/users/apis/users/create/`,
    formData,
    {
      params: params ? { ...params } : {},
      ...extra
    }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

export default CreateUserAPIViewM

