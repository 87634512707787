import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Checkbox,
  Dropdown,
  Button
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardSupplyDeliveryHistoryUX = (props: any) => {
    return(
      <div
        id="CardSupplyDeliveryHistory"
        style={{padding:"5px 10px"}}>
        <div
          style={{fontWeight: "bold", fontSize: "1.2rem",margin:"10px"}}>
          ประวัติการจัดส่งเวชภัณฑ์
        </div>
        <div>
          
          <div
            style={{ display: "flex", paddingBottom: "5px",alignItems: "center" }}>
            
            <div
              style={{minWidth: "130px", maxWidth: "130px"}}>
              
              <Checkbox
                checked={props.checkedStatus}
                label="สถานะ"
                onChange={props.handleChangeChecked("checkedStatus")}
                style={{ fontWeight: "bold", margin: "5px 10px" }}>
              </Checkbox>
            </div>
            <div
              style={{ width: "27%" }}>
              
              <div
                style={{display:"flex"}}>
                
                <Dropdown
                  fluid={true}
                  onChange={props.handleChangeValue("selectedStatus","checkedStatus")}
                  options={props.statusOptions}
                  selection={true}
                  style={{width:"80%"}}
                  value={props.selectedStatus}>
                </Dropdown>
              </div>
            </div>
            <div
              style={{minWidth: "130px", maxWidth: "130px"}}>
              
              <Checkbox
                checked={props.checkedUser}
                label="ผู้บันทึก"
                onChange={props.handleChangeChecked("checkedUser")}
                style={{ fontWeight: "bold", margin: "5px 10px" }}>
              </Checkbox>
            </div>
            <div
              style={{ width: "26%" }}>
              
              <div>
                {props.employeeCode}
              </div>
            </div>
          </div>
          <div
            style={{ display: "flex", paddingBottom: "5px" ,alignItems: "center"}}>
            
            <div
              style={{minWidth: "130px", maxWidth: "130px"}}>
              
              <Checkbox
                checked={props.checkedOrderDivision}
                label="หน่วยงานสั่ง"
                onChange={props.handleChangeChecked("checkedOrderDivision")}
                style={{ fontWeight: "bold", margin: "5px 10px" }}>
              </Checkbox>
            </div>
            <div
              style={{ width: "27%" }}>
              
              <Dropdown
                fluid={true}
                onChange={props.handleChangeValue("selectedOrderDivision","checkedOrderDivision")}
                options={props.masterOptions?.division}
                search={true}
                selection={true}
                style={{width:"80%"}}
                value={props.selectedOrderDivision}>
              </Dropdown>
            </div>
            <div
              style={{minWidth: "130px", maxWidth: "130px"}}>
              
              <Checkbox
                checked={props.checkedOrderDate}
                label="วันที่สั่ง"
                onChange={props.handleChangeChecked("checkedOrderDate")}
                style={{ fontWeight: "bold", margin: "5px 10px" }}>
              </Checkbox>
            </div>
            <div
              style={{ width: "35%",display:"flex" }}>
              
              <DateTextBox
                onChange={props.handleChangeDate("selectedOrderStartDate")}
                value={props.selectedOrderStartDate}>
              </DateTextBox>
              <DateTextBox
                onChange={props.handleChangeDate("selectedOrderEndDate")}
                style={{marginLeft:"5px"}}
                value={props.selectedOrderEndDate}>
              </DateTextBox>
            </div>
          </div>
          <div
            style={{ display: "flex", paddingBottom: "10px", alignItems: "center" }}>
            
            <div
              style={{minWidth: "130px", maxWidth: "130px"}}>
              
              <Checkbox
                checked={props.checkedPerformDivision}
                label="หน่วยงานจ่าย"
                onChange={props.handleChangeChecked("checkedPerformDivision")}
                style={{ fontWeight: "bold", margin: "5px 10px" }}>
              </Checkbox>
            </div>
            <div
              style={{ width: "27%" }}>
              
              <Dropdown
                fluid={true}
                onChange={props.handleChangeValue("selectedPerformDivision","checkedPerformDivision")}
                options={props.masterOptions?.divisionSupply}
                search={true}
                selection={true}
                style={{width:"80%"}}
                value={props.selectedPerformDivision}>
              </Dropdown>
            </div>
            <div
              style={{minWidth: "130px", maxWidth: "130px"}}>
              
              <Checkbox
                checked={props.checkedPerformDate}
                label="วันที่จ่าย"
                onChange={props.handleChangeChecked("checkedPerformDate")}
                style={{ fontWeight: "bold", margin: "5px 10px" }}>
              </Checkbox>
            </div>
            <div
              style={{ width: "35%",display:"flex" }}>
              
              <DateTextBox
                onChange={props.handleChangeDate("selectedPerformStartDate")}
                value={props.selectedPerformStartDate}>
              </DateTextBox>
              <DateTextBox
                onChange={props.handleChangeDate("selectedPerformEndDate")}
                style={{marginLeft:"5px"}}
                value={props.selectedPerformEndDate}>
              </DateTextBox>
            </div>
          </div>
          <div
            style={{ display: "flex", paddingBottom: "5px" }}>
            
            <div
              style={{ display:"flex",justifyContent:"flex-end",width: "100%" }}>
              
              <Button
                color="yellow"
                onClick={props.onClear}
                size="small">
                ล้างตัวกรอง
              </Button>
              <div>
                {props.buttonSearch}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{marginTop:"1rem"}}>
          
          <Table
            data={props.deliveryHistoryList}
            getTrProps={props.getTrProps}
            headers="รหัส,สถานะ,หน่วยงานที่สั่ง,หน่วยงานที่จ่าย,วันที่จ่าย"
            id="CardSupplyDeliveryHistory"
            keys="code, status_name, to_division_name, from_division_name,performDate"
            minRows="10"
            showPagination={false}
            style={{height: "calc(100dvh - 26rem)"}}>
          </Table>
        </div>
      </div>
    )
}


export default CardSupplyDeliveryHistoryUX

export const screenPropsDefault = {"patientSearch":"[Patient Search Box]"}

/* Date Time : Thu Dec 28 2023 10:46:49 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardSupplyDeliveryHistory"
        },
        "style": {
          "type": "code",
          "value": "{padding:\"5px 10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติการจัดส่งเวชภัณฑ์"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.2rem\",margin:\"10px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"1rem\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 4,
      "name": "Table",
      "parent": 3,
      "props": {
        "data": {
          "type": "code",
          "value": "props.deliveryHistoryList"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getTrProps"
        },
        "headers": {
          "type": "value",
          "value": "รหัส,สถานะ,หน่วยงานที่สั่ง,หน่วยงานที่จ่าย,วันที่จ่าย"
        },
        "id": {
          "type": "value",
          "value": "CardSupplyDeliveryHistory"
        },
        "keys": {
          "type": "value",
          "value": "code, status_name, to_division_name, from_division_name,performDate"
        },
        "minRows": {
          "type": "value",
          "value": "10"
        },
        "pageSize": {
          "type": "code",
          "value": ""
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"calc(100dvh - 26rem)\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\",alignItems: \"center\" }"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"130px\", maxWidth: \"130px\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "Checkbox",
      "parent": 15,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedStatus"
        },
        "label": {
          "type": "value",
          "value": "สถานะ"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedStatus\")"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"5px 10px\" }"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"27%\" }"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"130px\", maxWidth: \"130px\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"26%\" }"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "Checkbox",
      "parent": 19,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedUser"
        },
        "label": {
          "type": "value",
          "value": "ผู้บันทึก"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedUser\")"
        },
        "onClick": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"5px 10px\" }"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\"}"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "Dropdown",
      "parent": 51,
      "props": {
        "clearable": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedStatus\",\"checkedStatus\")"
        },
        "options": {
          "type": "code",
          "value": "props.statusOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width:\"80%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedStatus"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "code",
          "value": "props.employeeCode"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" ,alignItems: \"center\"}"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "div",
      "parent": 54,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"130px\", maxWidth: \"130px\"}"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "div",
      "parent": 54,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"27%\" }"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 54,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"130px\", maxWidth: \"130px\"}"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 54,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\",display:\"flex\" }"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "Checkbox",
      "parent": 55,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedOrderDivision"
        },
        "label": {
          "type": "value",
          "value": "หน่วยงานสั่ง"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedOrderDivision\")"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"5px 10px\" }"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "Checkbox",
      "parent": 57,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedOrderDate"
        },
        "label": {
          "type": "value",
          "value": "วันที่สั่ง"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedOrderDate\")"
        },
        "onClick": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"5px 10px\" }"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 63,
      "name": "DateTextBox",
      "parent": 58,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDate(\"selectedOrderStartDate\")"
        },
        "value": {
          "type": "code",
          "value": "props.selectedOrderStartDate"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 64,
      "name": "DateTextBox",
      "parent": 58,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDate(\"selectedOrderEndDate\")"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"5px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedOrderEndDate"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "Dropdown",
      "parent": 56,
      "props": {
        "clearable": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedOrderDivision\",\"checkedOrderDivision\")"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.division"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width:\"80%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedOrderDivision"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": null,
      "id": 66,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"10px\", alignItems: \"center\" }"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "div",
      "parent": 66,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"130px\", maxWidth: \"130px\"}"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "div",
      "parent": 66,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"27%\" }"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "div",
      "parent": 66,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"130px\", maxWidth: \"130px\"}"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "div",
      "parent": 66,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\",display:\"flex\" }"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "Checkbox",
      "parent": 67,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedPerformDivision"
        },
        "label": {
          "type": "value",
          "value": "หน่วยงานจ่าย"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedPerformDivision\")"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"5px 10px\" }"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "Checkbox",
      "parent": 69,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedPerformDate"
        },
        "label": {
          "type": "value",
          "value": "วันที่จ่าย"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedPerformDate\")"
        },
        "onClick": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"5px 10px\" }"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "Dropdown",
      "parent": 68,
      "props": {
        "clearable": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedPerformDivision\",\"checkedPerformDivision\")"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.divisionSupply"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width:\"80%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedPerformDivision"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 74,
      "name": "DateTextBox",
      "parent": 70,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDate(\"selectedPerformStartDate\")"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.selectedPerformStartDate"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 75,
      "name": "DateTextBox",
      "parent": 70,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDate(\"selectedPerformEndDate\")"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"5px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedPerformEndDate"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "div",
      "parent": 76,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display:\"flex\",justifyContent:\"flex-end\",width: \"100%\" }"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "Button",
      "parent": 77,
      "props": {
        "children": {
          "type": "value",
          "value": "ล้างตัวกรอง"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClear"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "div",
      "parent": 77,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearch"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 79,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardSupplyDeliveryHistoryUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "patientSearch": "[Patient Search Box]"
  },
  "width": 80
}

*********************************************************************************** */
