const FormReserveBedQueue = (props: any) => {
  console.log("FormReserveBedQueue props: ", props);

  let rangeText = "";
  if (props.isSaveDate && (props.saveFromDate || props.saveToDate)) {
    if (props.saveFromDate && props.saveToDate) {
      rangeText = `ตั้งแต่วันที่ ${props.saveFromDate} ถึง ${props.saveToDate}`;
    } else if (props.saveFromDate && !props.saveToDate) {
      rangeText = `ตั้งแต่วันที่ ${props.saveFromDate}`;
    } else if (!props.saveFromDate && props.saveToDate) {
      rangeText = `ถึงวันที่ ${props.saveToDate}`;
    }
  } else {
    rangeText = "ไม่ระบุวันที่";
  }

  return {
    defaultStyle: {
      font: "THSarabunNew",
      // alignment: 'justify'
      lineHeight: 1,
      fontSize: 11,
    },
    pageMargins: [10, 95, 10, 30],
    // A4: [595.28, 841.89]
    pageSize: "A4",
    styles: {
      table: {
        margin: [0, 5, 0, 15],
      },
      header: {
        fontSize: 22,
        bold: true,
        alignment: "center",
      },
      tableHeader: {
        bold: true,
        fontSize: 12,
        color: "black",
        fillColor: "lightgray",
      },
      tableData: {},
    },
    header: {
      margin: [20, 20, 20, 0],
      stack: [
        {
          layout: "noBorders",
          // fillColor: "#1b9d2c",
          // fillOpacity: 0.4,
          table: {
            // widths: ["10%", "90%"],
            widths: ["100%"],
            heights: [55],
            body: [
              [
                // {
                //   // margin: [5, 2, 0, 0],
                //   // layout: "noBorders",
                //   // fillColor: "#1b9d2c",
                //   // fillOpacity: 0.4,
                //   // stack: [
                //   //   {
                //   //     image: "logo",
                //   //     fillColor: "#1b9d2c",
                //   //     fillOpacity: 0.4,
                //   //     width: 90,
                //   //   },
                //   // ],
                //   text: ""
                // },
                {
                  margin: [0, 0, 0, 0],
                  stack: [
                    {
                      text: `รายงาน สถานะ ${
                        props.reserveStatusName || ""
                      } ของ ห้องพักและเตียง`,
                      style: "header",
                    },
                    { text: rangeText, style: "header" },
                  ],
                  alignment: "center",
                  style: {
                    fontSize: 20,
                    bold: true,
                  },
                },
              ],
            ],
          },
        },
      ],
    },
    content: [
      {
        style: "table",
        table: {
          headerRows: 1,
          widths: [40, 30, "*", 60, 50, 40, 50, 70, 70],
          body: [
            [
              { text: "วันที่", style: "tableHeader", alignment: "center" },
              { text: "เวลา", style: "tableHeader", alignment: "center" },
              {
                text: "ชื่อผู้ป่วย",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "ประเภทหอผู้ป่วย",
                style: "tableHeader",
                alignment: "center",
              },
              { text: "Diagnosis", style: "tableHeader", alignment: "center" },
              { text: "สถานะ", style: "tableHeader", alignment: "center" },
              {
                text: "เบอร์ติดต่อ",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "หน่วยตรวจที่จอง",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: `${
                  props.reserveStatus === "CANCELED"
                    ? "หมายเหตุที่ยกเลิก"
                    : "หมายเหตุ"
                }`,
                style: "tableHeader",
                alignment: "center",
              },
            ],
            ...(props?.data || []).map((item: any) => [
              {
                text: item.admit_date__date,
                style: "tableData",
                alignment: "center",
              },
              {
                text: item.admit_date__time,
                style: "tableData",
                alignment: "center",
              },
              {
                text: item.patient_name,
                style: "tableData",
                alignment: "left",
              },
              {
                text: item.ward_type_name,
                style: "tableData",
                alignment: "center",
              },
              { text: `${item.icd10_detail.code}  ${item.icd10_detail.term}`,
                style: "tableData",
                alignment: "center" },
              {
                text: item.room_no ? `${item.status_label} ${item.room_no}` : item.status_label,
                style: "tableData",
                alignment: "center",
              },
              {
                text: item.contact_number,
                style: "tableData",
                alignment: "center",
              },
              {
                text: item.order_by_division_name,
                style: "tableData",
                alignment: "center",
              },
              {
                text: item.cancel_note || item.remark,
                style: "tableData",
                alignment: "center",
              },
            ]),
          ],
        },
      },
    ],
    footer: function (currentPage: number, pageCount: number) {
      let pageText = currentPage.toString() + " of " + pageCount;
      return { text: pageText, alignment: "center" };
    },
    // footer: function (currentPage: number, pageCount: number) {
    //   return {
    //     margin: [20, 0, 20, 0],
    //     stack: [
    //       {
    //         text: `Approved by: ${props.doctorDetail.full_name || ""} (ว.${
    //           props.doctorDetail?.certificate_no || ""
    //         })`,
    //         style: {
    //           bold: true,
    //           alignment: "right",
    //         },
    //       },
    //       {
    //         table: {
    //           widths: ["50%", "40%", "10%"],
    //           body: [
    //             [
    //               {
    //                 borderColor: ["#646464", "#646464", "#646464", "#646464"],
    //                 border: [false, true, false, false],
    //                 text: `HN: ${props.patient?.hn} ${props.patient?.full_name_th} DOB: ${props.patient?.birthdate}`,
    //                 italics: true,
    //                 style: {
    //                   fontSize: 12,
    //                   color: "#5A5A5A",
    //                 },
    //               },
    //               {
    //                 border: [false, true, false, false],
    //                 text: `Printed date: ${props.date} [${props.time}]`,
    //                 italics: true,
    //                 style: {
    //                   fontSize: 12,
    //                   color: "#5A5A5A",
    //                 },
    //               },
    //               {
    //                 border: [false, true, false, false],
    //                 text: `${currentPage.toString()} / ${pageCount}`,
    //                 italics: true,
    //                 style: {
    //                   fontSize: 12,
    //                   color: "#5A5A5A",
    //                   alignment: "right",
    //                 },
    //               },
    //             ],
    //           ],
    //         },
    //       },
    //     ],
    //   };
    // },
    // images: {
    //   logo:
    //     origin + "/static/images/laboratory_examination_summary/logoMor.png",
    //   queue: origin + "/static/images/menus/queue.png",
    // },
  };
};

export default FormReserveBedQueue;
