import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
  useCallback,
} from "react";
import {
  Grid,
  Header,
  Form,
  Input,
  Button,
  TextArea,
  Modal,
  Radio,
  Container,
  Label,
  Popup,
  Icon,
} from "semantic-ui-react";

import SubPatientHistory from "react-lib/apps/DPO/SubPatientHistory";
import SubADRAndOther from "react-lib/apps/DPO/SubADRAndOther";

import CardLayout from "react-lib/apps/common/CardLayout";
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import SubADR from "react-lib/apps/common/SubADR";
import ModInfo from "react-lib/apps/common/ModInfo";
import ModAuthen from "react-lib/apps/common/ModAuthen";

import CardPhysicalExam from "./CardPhysicalExam";
import { useIntl } from "react-intl";

const PhysicalOrganExam = React.memo(
  forwardRef((props: any, ref) => {
    const [value, setValue] = useState("X");
    const [text, setText] = useState("");

    useImperativeHandle(ref, () => ({
      getData: () => {
        return {
          organ: props.data.organ_id,
          status: value,
          description: text,
          emr: props.emrId,
        };
      },
      getValue: () => value,
      getText: () => text,
      getOrganId: () => props.data.organ_id,
    }));

    useEffect(() => {
      if (props.data.status) {
        setValue(props.data.status);
      }
    }, [props.data.status]);

    useEffect(() => {
      if (props.data.description) {
        setText(props.data.description);
      }
    }, [props.data.description]);

    return (
      <Container>
        <Grid>
          <Grid.Column width={3}>
            <Form.Field>
              <label>{props.data.organ_name}</label>
            </Form.Field>
          </Grid.Column>
          <Grid.Column width={13}>
            <Form.Group inline>
              <Form.Field>
                <Radio
                  label="N/A"
                  value="X"
                  checked={value === "X"}
                  onChange={(e, { value }) => setValue(value)}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label="Normal"
                  value="N"
                  checked={value === "N"}
                  onChange={(e, { value }) => setValue(value)}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label="Abnormal"
                  value="A"
                  checked={value === "A"}
                  onChange={(e, { value }) => setValue(value)}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field width={16}>
                <TextArea
                  style={{ display: value === "X" ? "none" : null }}
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                />
              </Form.Field>
            </Form.Group>
          </Grid.Column>
        </Grid>
      </Container>
    );
  })
);

const CardPatientInfo = forwardRef((props: CardPatientInfoProps, ref) => {
  const intl = useIntl();
  const [patientIllness, setPatientIllness] = useState({});
  const [allergy, setAllergy] = useState([]);
  const [notApproveAllergy, setNotApproveAllergy] = useState([]);
  const [enableSave, setEnableSave] = useState(true);
  const [isADRFailed, setIsADRFailed] = useState(false);
  const [patientExamOther, setPatientExamOther] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [btnHistoryClassName, setBtnHistoryClassName] = useState("grey fluid");
  const [openModPatientHistory, setOpenModPatientHistory] = useState(false);
  const [physicalOrganList, setPhysicalOrganList] = useState([]);
  const [physicalTemplateList, setPhysicalTemplateList] = useState([]);
  const [openModInfoError, setOpenModInfoError] = useState(false);
  const [openModInfoSuccess, setOpenModInfoSuccess] = useState(false);
  const [errorText, setErrorText] = useState(null);

  const [openModADR, setOpenModADR] = useState(false);
  const [modADRApproveOnly, setModADRApproveOnly] = useState(true);
  const [showSaveAlert, setShowSaveAlert] = useState(true);
  const [confirmSave, setConfirmSave] = useState(false);
  const [medicalRecord, setMedicalRecord] = useState();
  const [openModAuthen, setOpenModAuthen] = useState({
    open: false,
    isApproveOrder: false,
  });

  const [validateError, setValidateError] = useState(null);

  const isMounted = useRef(false);
  const subADRAndOtherRef = useRef();
  const physicalOrganRef = useRef([]);
  const textareaRef = useRef<any>();
  const isCreated = useRef<boolean>(false)
  const newInfo = useRef<boolean>(true);

  useImperativeHandle(ref, () => ({
    save: () => {
      setShowSaveAlert(false);
      setTimeout(() => save(), 0);
    },
    saveForCheckOut: () => {
      setShowSaveAlert(false);
      setTimeout(() => save(), 0);
      if (!validateFrom()) {
        props.onFailed();
      }
    },
  }));

  useEffect(() => {
    props.runSequence({ sequence: "PhysicalExam", restart: true });
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    fetchPatientInfo();
  }, [props.emrId]);

  useEffect(() => {
    if (props.patientSymptomInfo && !patientIllness.present_illness) {
      handleSetPatientIllness({
        field: "present_illness",
        value: props.patientSymptomInfo,
        // value: e.target.value.replace(/\nANCRecord:.*$/g, "")
      });
    }
  }, [props.patientSymptomInfo]);

  const fetchPatientInfo = () => {
    if (!props.emrId) {
      return;
    }

    getEncounterDetail();
    getMedicalRecordEmrDetail();
    getAdverseReactionApproved();
    getAdverseReactionNotApproved();
  };

  const clear = () => {
    setPatientIllness({});
    setPatientExamOther({});

    newInfo.current = true;

    setPhysicalOrganList([]);
    setIsADRFailed(false);
    setValidateError(null);
  };

  const getAdverseReactionApproved = async () => {
    setIsLoading(true);
    const [data, error] = await props.controller.getAdverseReactionList({
      patientId: props.patientData?.patient_id,
      excludeUnused: true,
      severeFirst: true,
      approveOnly: true,
      showNotKnownByDoctor: true,
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (data) {
        if (data.items.length > 5) {
          setAllergy(data.items);
        } else {
          setAllergy(data.items);
        }
      }
    }
  };

  const getAdverseReactionNotApproved = async () => {
    setIsLoading(true);
    const [data, error] = await props.controller.getAdverseReactionList({
      patientId: props.patientData?.patient_id,
      excludeUnused: true,
      severeFirst: true,
      notApproveOnly: true,
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (data) {
        if (data.items.length > 5) {
          setNotApproveAllergy(data.items);
        } else {
          setNotApproveAllergy(data.items);
        }
      }
    }
  };

  const getEncounterDetail = async () => {

    const [data, error] = await props.controller.getEncounterDetail({
      encounterId: props.selectedEncounter.id,
    });
    props.setProp("selectedEncounter", data)

  }

  const getMedicalRecordEmrDetail = async () => {
    setIsLoading(true);

    const [data, error] = await props.controller.getMedicalRecordEmrDetail({
      emrId: props.emrId,
      ...(newInfo.current ? { autoCheckIn: false } : {}),
    });

    if(isCreated.current){
      isCreated.current = false;

      props.onCreated?.();
    }

    if (isMounted.current) {
      setIsLoading(false);
      if (data) {
        props.setProp("patientEmr", data)
        setMedicalRecord(data);
        setDataToUI(data);
        props.onGetEmrSuccess?.();
      }
    }
  };

  const setDataToUI = async (data) => {
    //* User with getMedicalRecordEmrDetail only
    if (data.patient_illness) {
      setPatientIllness(data.patient_illness);

      newInfo.current = !data.patient_illness.id;
    } else {
      setPatientIllness({
        chief_complaint: data.encounter_chief_complaint,
      });

      newInfo.current = true;
    }

    if (data.patient_exam.length > 0) {
      setEnableSave(true);
      setPhysicalOrganList(data.patient_exam);
    } else {
      getPhysicalExamOrgan();
    }

    if (data.exam_template?.length > 0) {
      setPhysicalTemplateList(data.exam_template);
    } else {
      setPhysicalTemplateList([false]);
    }

    if (!isADRFailed) {
      if (subADRAndOtherRef.current) {
        subADRAndOtherRef.current.refresh();
      }
    }

    if (data.patient_exam_other) {
      setPatientExamOther(data.patient_exam_other);
    } else {
      setPatientExamOther("");
    }

    if (
      data.patient_history.personal_history.length > 0 ||
      data.patient_history.past_illness.length > 0 ||
      data.patient_history.family_history.length > 0 ||
      data.sensitive_note.sensitive_note.length > 0
    ) {
      setBtnHistoryClassName("blue fluid");
    } else {
      setBtnHistoryClassName("grey fluid");
    }
  };

  const getPhysicalExamOrgan = async () => {
    setIsLoading(true);
    const [data, error] = await props.controller.getPhysicalExamOrgan();
    if (isMounted.current) {
      setIsLoading(false);
      if (data) {
        if (data.items.length > 0) {
          setPhysicalOrganList(data.items);
        }
        // else {
        //   setErrorText(
        //     "กรุณาตั้งค่า PhysicalExamOrgan กับหน่วยงาน " +
        //       props.django.division.name
        //   );
        //   setOpenModInfoError(true);
        //   setEnableSave(false);
        //   props.onNoPhysicalExamOrgan?.(); //* Notify when no organ examination created
        // }
      }
    }
  };

  const generateOrganUI = useCallback(() => {
    return physicalOrganList.map((item, index) => (
      <PhysicalOrganExam
        key={item.organ_id}
        data={item}
        ref={(el) => (physicalOrganRef.current[index] = el)}
        emrId={props.emrId}
      />
    ));
  });

  const handleSetPatientIllness = ({ field, value } = {}) => {
    setPatientIllness((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const generateNotApproveAllergyLabel = () => {
    let arr = notApproveAllergy.slice(0, 5).map((item, index) => {
      return (
        <Form.Field width={3} key={index}>
          <Popup
            trigger={
              <Label
                icon="help"
                className="grey fluid"
                style={styles.label}
                content={item.name ? item.name : item.note}
              />
            }
          >{`[${item.name}] [${item.severity_name}] ${item.note}`}</Popup>
        </Form.Field>
      );
    });
    return (
      <Form>
        <Form.Group inline>
          {arr}{" "}
          <Button
            style={{ display: notApproveAllergy.length < 6 ? "none" : null }}
            color="blue"
            content="more"
            onClick={() => {
              setModADRApproveOnly(false);
              setOpenModADR(true);
            }}
          />
        </Form.Group>
      </Form>
    );
  };

  const generateAllergyLabel = () => {
    let arr = allergy.slice(0, 5).map((item, index) => {
      return (
        <Form.Field width={3} key={index}>
          <Popup
            trigger={
              <Label
                className="fluid"
                style={styles.label}
                color={ALLERGY_LEVEL.COLOR[item.level]}
              >
                {item.name ? item.name : item.note}
              </Label>
            }
          >{`[${item.name}] [${item.severity_name}] ${item.note}`}</Popup>
        </Form.Field>
      );
    });
    return (
      <Form>
        <Form.Group inline>
          {arr}{" "}
          <Button
            style={{ display: allergy.length < 6 ? "none" : null }}
            color="blue"
            content="more"
            onClick={() => {
              setModADRApproveOnly(true);
              setOpenModADR(true);
            }}
          />
        </Form.Group>
      </Form>
    );
  };

  const save = async () => {
    setValidateError(null);
    if (enableSave === false) {
      setErrorText(
        "กรุณาตั้งค่า PhysicalExamOrgan กับหน่วยงาน " +
          props.django.division.name
      );
      setOpenModInfoError(true);
      props.onFailed();
      return;
    }
    setIsLoading(true);
    subADRAndOtherRef.current?.save?.();
  };

  const setSaveData = () => {
    let saveData: any = {};
    saveData.patient_illness = {
      ...patientIllness,
      emr: props.emrId,
    };
    saveData.patient_exam_other = { ...props.PhysicalExamSequence.otherExam };

    const organ: any[] =
      props.PhysicalExamSequence?.organList?.map((item: any) => {
        let result: any = {
          organ: item.organ_id,
          status: item.status,
          result: item.result,
          description: item.description,
          emr: item.emr,
        };
        if (item.result === "") {
          result.result = "N/A";
          result.description = "";
        } else if (item.result === "N/A") {
          result.description = "";
        }
        return result;
      }) || [];

    const template: any[] = [];
    props.PhysicalExamSequence.selectedTemplateList?.map((temp: any) => {
      return temp.items.map((item: any) => {
        let result: any = {
          organ: item.organ,
          status: item.status || null,
          result: item.result || "",
          description: item.description || "",
          template_item: item.id,
          emr: temp.emr,
        };
        if (result.result === "") {
          result.result = "N/A";
          result.description = "";
        } else if (result.result === "N/A") {
          result.description = "";
        }
        template.push(result);
      });
    });

    saveData.patient_exam = [...organ, ...template];
    return saveData;
  };

  const postPatientScreenNew = async () => {
    const saveData = setSaveData();

    setIsLoading(true);
    const [data, error] = await props.controller.postPatientScreenNew({
      data: saveData,
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (data) {
        newInfo.current = false;

        if (showSaveAlert) {
          setOpenModInfoSuccess(true);
        }

        isCreated.current = true;

        fetchPatientInfo();
      } else if (error) {
        setErrorText("เกิดข้อผิดพลาด บันทึกไม่สำเร็จ");
        setOpenModInfoError(true);
        props.onFailed();
      }
    }
  };

  const putPatientScreenUpdate = async (param: any) => {
    const saveData = setSaveData();
    setIsLoading(true);
    const [data, error] = await props.controller.putPatientScreenUpdate({
      data: {
        ...saveData,
        approved_by_username: param?.approved_by_username,
        approved_by_password: param?.approved_by_password,
      },
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (data) {
        newInfo.current = false;
        // if (showSaveAlert) {
        //   setOpenModInfoSuccess(true);
        // }
        fetchPatientInfo();
        setIsLoading(false);
      } else if (error) {
        setErrorText(
          "ข้อมูลการรักษาถูกสร้างไว้แล้วโดยแพทย์ท่านอื่น กำลังทำการดึงข้อมูลการรักษาที่บันทึกแล้วออกมา"
        );
        setOpenModInfoError(true);
        fetchPatientInfo();
        props.onFailed();
      }
    }
  };

  const handleOnSaveAllergy = (success) => {
    if (success) {
      setIsADRFailed(false);
      if (newInfo.current) {
        postPatientScreenNew();
      } else {
        putPatientScreenUpdate();
      }
      subADRAndOtherRef.current?.clearError?.();
    } else {
      setIsADRFailed(true);
      setShowSaveAlert(false);
      if (newInfo.current) {
        postPatientScreenNew();
      } else {
        putPatientScreenUpdate();
      }
      // failed to save allergy notify parent card that we stopped here
      setIsLoading(false);
      props.onFailed();
    }
  };

  // const setValueTextArea = React.useCallback(() => {
  //   const replace = (patientIllness.present_illness
  //     ? patientIllness.present_illness
  //     : "").replace(/\nANCRecord:.*$/g, "")
  //   const anc = patientIllness.anc_symptom ? `\nANCRecord: ${patientIllness.anc_symptom}` : ""
  //   const value = `${replace}${anc}`

  //   if (textareaRef.current) {
  //     console.log(textareaRef.current.selectionEnd, replace.length)
  //     if (textareaRef.current.selectionEnd > replace.length) {
  //       textareaRef.current.selectionEnd = replace.length
  //     }
  //   }

  //   console.log("Patient Illness:", patientIllness, value)
  //   return value
  // }, [patientIllness])

  const validateFrom = () => {
    let error = [];
    if (!patientIllness.chief_complaint) {
      error.push('กรุณาระบุค่าใน "Chief complaint"');
    }
    if (!patientIllness.present_illness) {
      error.push('กรุณาระบุค่าใน "Present illness"');
    }
    setValidateError(error);
  };

  return (
    <CardLayout
      titleText="ประวัติผู้ป่วยและการตรวจร่างกาย"
      closeable={false}
      toggleable={false}
      loading={isLoading}
      approveStatus={
        <div>
          {medicalRecord?.need_approve === true &&
          medicalRecord?.student_name ? (
            <div
              style={{
                color: "gray",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={"/static/images/drugOrder/need_approve.png"}
                style={{ marginRight: "5px" }}
              />
              <label>NEED APPROVE</label>
            </div>
          ) : (
            medicalRecord?.need_approve === false &&
            medicalRecord?.student_name && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={"/static/images/drugOrder/approve_check.png"}
                  style={{ marginRight: "5px" }}
                />
                <label style={{ color: "green", marginRight: "10px" }}>
                  APPROVE
                </label>
                <div style={{ display: "flex", color: "black" }}>
                  <label>
                    {"[ "}
                    <span style={{ color: "#898383" }}>{intl.formatMessage({id: "โดย"})}{" "}</span>
                    {medicalRecord?.approved_by_name} {" ]"}
                  </label>
                </div>
              </div>
            )
          )}
        </div>
      }
    >
      <Modal
        open={openModADR}
        closeOnDimmerClick={true}
        onClose={() => setOpenModADR(false)}
      >
        <SubADR
          patientData={props.patientData}
          controller={props.controller}
          approvedOnly={modADRApproveOnly}
          languageUX={props.languageUX}
        />
      </Modal>
      <ModInfo
        type="error"
        open={openModInfoError}
        titleName={errorText}
        onClose={() => setOpenModInfoError(false)}
        onApprove={() => setOpenModInfoError(false)}
      />
      <ModInfo
        type="success"
        duration={2000}
        open={openModInfoSuccess}
        onClose={() => setOpenModInfoSuccess(false)}
        onApprove={() => setOpenModInfoSuccess(false)}
      />
      <Modal
        open={openModPatientHistory}
        closeOnDimmerClick={true}
        onClose={() => setOpenModPatientHistory(false)}
      >
        <SubPatientHistory
          controller={props.controller}
          patientData={props.patientData}
          emrId={props.emrId}
          divisionId={props.divisionId}
          onClose={() => setOpenModPatientHistory(false)}
          languageUX={props.languageUX}
        />
      </Modal>
      <Grid divided columns={2}>
        <Grid.Column>
          {/* <Header>{intl.formatMessage({ id: "อาการไม่พึงประสงค์จากยาและอาหารเพิ่มเติม" })}</Header>
          {generateAllergyLabel()}
          {generateNotApproveAllergyLabel()} */}
          <SubADRAndOther
            ref={subADRAndOtherRef}
            controller={props.controller}
            patientData={props.patientData || {}}
            onSaveAllergy={handleOnSaveAllergy}
            hideForm={true}
            languageUX={props.languageUX}
          />
          <Header>{intl.formatMessage({ id: "ข้อมูลประวัติผู้ป่วย" })}</Header>
          <Form>
            <ErrorMessage error={validateError} />
            <Form.Field
              width={15}
              error={validateError && !patientIllness.chief_complaint}
            >
              <label>Chief complaint</label>
              <Input
                value={
                  patientIllness.chief_complaint
                    ? patientIllness.chief_complaint
                    : ""
                }
                onChange={(e) =>
                  handleSetPatientIllness({
                    field: "chief_complaint",
                    value: e.target.value,
                  })
                }
                disabled={props.isCheckout}
              />
            </Form.Field>
            <Form.Field
              width={15}
              error={validateError && !patientIllness.present_illness}
            >
              <label>Present illness</label>
              <TextArea
                ref={(instance) => {
                  if (instance) {
                    textareaRef.current = instance.ref.current;
                  }
                }}
                rows={8}
                value={patientIllness.present_illness}
                disabled={props.isCheckout}
                onChange={(e) =>
                  handleSetPatientIllness({
                    field: "present_illness",
                    value: e.target.value,
                  })
                }
                onClick={(e) => {
                  handleSetPatientIllness({
                    field: "present_illness",
                    value: e.target.value,
                    // value: e.target.value.replace(/\nANCRecord:.*$/g, "")
                  });
                }}
              />
            </Form.Field>
            <br />
            <Form.Field width={4}>
              <Button
                color="blue"
                disabled={props.isCheckout}
                className={btnHistoryClassName}
                content="History"
                onClick={() => setOpenModPatientHistory(true)}
              />
            </Form.Field>
          </Form>
        </Grid.Column>
        <Grid.Column>
          <CardPhysicalExam
            // function
            onEvent={props.onEvent}
            setProp={props.setProp}
            // CommonInterface
            errorMessage={props.errorMessage}
            successMessage={props.successMessage}
            buttonLoadCheck={props.buttonLoadCheck}
            // seq
            runSequence={props.runSequence}
            PhysicalExamSequence={props.PhysicalExamSequence}
            // data
            emrId={props.emrId}
            physicalOrganList={physicalOrganList}
            physicalTemplateList={physicalTemplateList}
            patientExamOther={patientExamOther}
            isCheckout={props.isCheckout}
            languageUX={props.languageUX}
          />
          <Form>
            <Form.Group
              inline
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Form.Field
                width={8}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  color="blue"
                  style={{ ...(!props.isStudentUser && { display: "none" }) }}
                  content={confirmSave ? <Icon name="check"></Icon> : "Save"}
                  disabled={props.isCheckout}
                  onClick={() => {
                    if (subADRAndOtherRef.current?.validate?.()) {
                      // setShowSaveAlert(true);
                      setConfirmSave(true);
                      setTimeout(() => {
                        setConfirmSave(false);
                      }, 3000);
                    } else {
                      // setShowSaveAlert(false)
                    }
                    setTimeout(() => {
                      if (newInfo.current) {
                        postPatientScreenNew();
                      } else {
                        putPatientScreenUpdate({});
                      }
                    }, 0);
                  }}
                />
                <Button
                  color="green"
                  content={confirmSave ? <Icon name="check"></Icon> : "Confirm"}
                  disabled={props.isCheckout}
                  onClick={() => {
                    if (subADRAndOtherRef.current?.validate?.()) {
                      // setShowSaveAlert(true);
                      setConfirmSave(true);
                      setTimeout(() => {
                        setConfirmSave(false);
                      }, 3000);
                    } else {
                      // setShowSaveAlert(false)
                    }
                    setTimeout(() => {
                      if (props.isStudentUser) {
                        setOpenModAuthen({
                          open: true,
                          isApproveOrder: true,
                        });
                      } else {
                        if (newInfo.current) {
                          postPatientScreenNew();
                        } else {
                          putPatientScreenUpdate({});
                        }
                      }
                    }, 0);
                  }}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Grid.Column>

        <ModAuthen
          titleName={"ยืนยันการตรวจสอบ"}
          titleColor={"blue"}
          open={openModAuthen?.open}
          onDeny={() =>
            setOpenModAuthen({ open: false, isApproveOrder: false })
          }
          onApprove={({ username, password }: any) => {
            // save
            putPatientScreenUpdate({
              approved_by_username: username,
              approved_by_password: password,
            });
            setOpenModAuthen({ open: false, isApproveOrder: false });
          }}
        />
      </Grid>
    </CardLayout>
  );
});

const styles = {
  label: { textOverflow: "ellipsis", overflow: "hidden" },
};

const ALLERGY_LEVEL = {
  COLOR: {
    0: "teal",
    1: "olive",
    2: "yellow",
    3: "orange",
    4: "red",
  },
};

type CardPatientInfoProps = {
  controller: object;
  patientData: object;
  emrId: number;
  divisionId: number;
  django: object;
  onNoPhysicalExamOrgan: any;
  onUploadImageClicked: any;
  onFailed: any;
  onCreated: any;
  onGetEmrSuccess: any;
  // CardPhysicalExam
  onEvent: any;
  setProp: any;
  errorMessage: any;
  successMessage: any;
  buttonLoadCheck: any;
  runSequence: any;
  PhysicalExamSequence: any;
  isCheckout: boolean;

  selectedEncounter: any;
};

CardPatientInfo.displayName = "CardPatientInfo"
export default React.memo(CardPatientInfo);
