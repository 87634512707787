import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button,
  FormGroup,
  FormField,
  TextArea
} from 'semantic-ui-react'

const CardTreatmentResultUX = (props: any) => {
    return(
      <div>
        <div
          id="CardTreatmentResult-Div-TreatmentResultScreen"
          style={{padding: "10px 10px 0px 10px", fontSize: "1.4rem", fontWeight: "bold" ,display: "flex", alignItems: "center" }}>
          
          <div>
            บันทึกผลหัตถการ
          </div>
        </div>
        <div
          className="ui divider"
          style={{marginRight:"10px", marginLeft:"10px"}}>
          
        </div>
        <div
          style={{padding:"10px"}}>
          
          <Table
            data={props.dataTreatment}
            getTrProps={props.rowProps}
            headers="รายละเอียดคำสั่งหัตถการ,Care provider,สถานะ,ผู้ทำหัตถการ"
            keys="order_detail,careprovider,status_name,treatmen_name"
            minRows={4}
            showPagination={false}
            style={{height: "250px"}}
            widths="^250,^150,^100,^150">
          </Table>
        </div>
        <div
          className="ui divider"
          style={{marginRight:"10px", marginLeft:"10px"}}>
          
        </div>
        <div
          style={{padding: "0px 10px 0px 10px", fontSize: "1.4rem", fontWeight: "bold" ,display: props.hideDescription ? "none":"flex", alignItems: "center",  }}>
          Principal
        </div>
        <div
          style={{position: "relative",display: props.hideDescription ? "none":""}}>
          
          <div
            style={{padding: "10px 10px 0px 10px"}}>
            {props?.diagnosisListPrincipal}
          </div>
          <div
            style={{display: props?.showSearchResultPrincipal ? "" : "none", position: "absolute", zIndex: 100, width: "80%", height: "400px" }}>
            
          </div>
          <div
            style={{padding: "10px 10px 0px 10px" ,display:"flex"}}>
            
            <div>
              
              <Button
                color="green"
                id="CardTreatmentResult-Button-AddPrincipal"
                onClick={props.OnaddPrincipal}>
                + Principal
              </Button>
            </div>
            <div
              style={{display: props.isShowDiagnosisSecondary ? "none" : ""}}>
              
              <Button
                color="yellow"
                id="CardTreatmentResult-Button-AddSecondary"
                onClick={props.OnaddSecondary}>
                + Secondary
              </Button>
            </div>
          </div>
          <div
            style={{padding: "10px 10px 0px 10px",display: props.isShowDiagnosisSecondary ? "" : "none"}  }>
            
            <div
              className="ui divider">
              
            </div>
            <div
              style={{fontSize: "1.4rem", fontWeight: "bold" ,display: "flex", alignItems: "center"}}>
              Secondary
            </div>
            <div>
              {props?.diagnosisListSecondary}
            </div>
            <div
              style={{display: props?.showSearchResultSecondary ? "" : "none", position: "absolute", zIndex: 100, width: "80%", height: "400px" }}>
              
            </div>
            <div
              style={{marginTop:"10px"}}>
              
              <Button
                color="yellow"
                onClick={props.OnaddSecondary}>
                + Secondary
              </Button>
            </div>
            <div
              className="ui divider">
              
            </div>
          </div>
        </div>
        <div
          style={{padding: "10px 10px 0px 10px",display: props.hideDescription ? "none":""}}>
          
          <div>
            {props?.ErrorMessage}
          </div>
          <div
            className="ui form">
            
            <FormGroup
              inline={true}
              style={{alignItems:"baseline"}}
              widths={16}>
              <FormField
                inline={true}
                style={{alignItems:"baseline"}}
                width={2}>
                <label
                  style={{fontWeight: "bold",fontSize: "1.3rem"}}>
                  บันทึกผล :
                </label>
              </FormField>
              <FormField
                inline={true}
                style={{alignItems:"baseline"}}
                width={8}>
                <label
                  style={{fontWeight:"bold", fontSize:"1rem"}}>
                  รายละเอียดคำสั่งหัตถการ:
                </label>
                <div>
                  {props?.treatmentRow?.order_detail_header}
                </div>
              </FormField>
              <FormField
                inline={true}
                style={{alignItems:"baseline"}}
                width={6}>
                <label
                  style={{fontWeight:"bold", fontSize:"1rem"}}>
                   รายละเอียดอุปกรณ์:
                </label>
                <div>
                  {props?.treatmentRow?.equipment_detail}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              widths={16}>
              <FormField
                inline={true}
                width={2}>
                <label
                  style={{fontWeight:"bold", fontSize:"1rem"}}>
                  วันเวลาการเริ่มทำหัตการ:
                </label>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <div
                  style={{width:"100%", marginBottom:"-5px"}}>
                  {props?.StartTime}
                </div>
              </FormField>
              <FormField
                inline={true}>
                <div>
                  {props.StartDateElement}
                </div>
              </FormField>
              <FormField
                inline={true}>
                <label
                  style={{fontWeight:"bold", fontSize:"1rem"}}>
                  วันเวลาสิ้นสุดการทำหัตถการ :
                </label>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <div
                  style={{width:"100%", marginBottom:"-5px"}}>
                  {props?.FinishTime}
                </div>
              </FormField>
              <FormField
                inline={true}>
                <div>
                  {props.FinishDateElement}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{alignItems:"flex-start"}}
              widths={16}>
              <FormField
                width={2}>
                <label
                  style={{fontWeight:"bold", fontSize:"1rem", minWidth: "max-content"}}>
                  รายละเอียด :
                </label>
              </FormField>
              <FormField
                width={6}>
                <TextArea
                  id="CardTreatmentResult-TextArea-OrderDetail"
                  name="result"
                  onChange={props.handleChangeResult}
                  value={props?.result}>
                </TextArea>
              </FormField>
              <FormField
                width={2}>
                <label
                  style={{fontWeight:"bold", fontSize:"1rem", textAlign:"right", minWidth: "max-content"}}>
                  ภาวะแทรกซ้อน :
                </label>
              </FormField>
              <FormField
                width={6}>
                <TextArea
                  id="CardTreatmentResult-TextArea-Complications"
                  name="complications"
                  onChange={props.handleChangeResult}
                  value={props?.complications}>
                </TextArea>
              </FormField>
            </FormGroup>
          </div>
        </div>
        <div
          style={{paddingTop: "20px ",display: props.hideDescription ? "none":"flex", alignItems:"center"}}>
          
          <div
            style={{flex:"1"}}>
            
          </div>
          <div
            style={{ fontWeight: "bold" ,display: props.hideCode ? "none" : "flex", alignItems: "center",marginRight:"7px"}}>
            รหัสผู้ทำหัตถการ
          </div>
          <div
            style={{ display: props.hideCode ? "none" : "flex" }}>
            {props?.code}
          </div>
          <div>
            {props.buttonEditOrder}
          </div>
          <div>
            {props.buttonSaveOrder}
          </div>
          <div>
            {props.buttonConfirmOrder}
          </div>
          <div>
            {props.buttonCancelOrder}
          </div>
        </div>
        <div
          style={{padding: "10px ",display: props.hideDescription ? "none":"flex"}}>
          
        </div>
      </div>
    )
}


export default CardTreatmentResultUX

export const screenPropsDefault = {}

/* Date Time : Tue Oct 08 2024 14:07:56 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardTreatmentResult-Div-TreatmentResultScreen"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px 10px 0px 10px\", fontSize: \"1.4rem\", fontWeight: \"bold\" ,display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "บันทึกผลหัตถการ"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        },
        "style": {
          "type": "code",
          "value": "{marginRight:\"10px\", marginLeft:\"10px\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding:\"10px\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 8,
      "name": "Table",
      "parent": 7,
      "props": {
        "data": {
          "type": "code",
          "value": "props.dataTreatment"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.rowProps"
        },
        "headers": {
          "type": "value",
          "value": "รายละเอียดคำสั่งหัตถการ,Care provider,สถานะ,ผู้ทำหัตถการ"
        },
        "keys": {
          "type": "value",
          "value": "order_detail,careprovider,status_name,treatmen_name"
        },
        "minRows": {
          "type": "code",
          "value": "4"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"250px\"}"
        },
        "widths": {
          "type": "value",
          "value": "^250,^150,^100,^150"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        },
        "style": {
          "type": "code",
          "value": "{marginRight:\"10px\", marginLeft:\"10px\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "Principal"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0px 10px 0px 10px\", fontSize: \"1.4rem\", fontWeight: \"bold\" ,display: props.hideDescription ? \"none\":\"flex\", alignItems: \"center\",  }"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\",display: props.hideDescription ? \"none\":\"\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.diagnosisListPrincipal"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px 10px 0px 10px\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px 10px 0px 10px\" ,display:\"flex\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px 10px 0px 10px\",display: props.isShowDiagnosisSecondary ? \"\" : \"none\"}  "
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.isShowDiagnosisSecondary ? \"none\" : \"\"}"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "Button",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "+ Principal"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "id": {
          "type": "value",
          "value": "CardTreatmentResult-Button-AddPrincipal"
        },
        "onClick": {
          "type": "code",
          "value": "props.OnaddPrincipal"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "Button",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": "+ Secondary"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "id": {
          "type": "value",
          "value": "CardTreatmentResult-Button-AddSecondary"
        },
        "onClick": {
          "type": "code",
          "value": "props.OnaddSecondary"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": "Secondary"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.4rem\", fontWeight: \"bold\" ,display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.diagnosisListSecondary"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"10px\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "Button",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": "+ Secondary"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.OnaddSecondary"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{paddingTop: \"20px \",display: props.hideDescription ? \"none\":\"flex\", alignItems:\"center\"}"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 51,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:\"1\"}"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "div",
      "parent": 51,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.code"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.hideCode ? \"none\" : \"flex\" }"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "div",
      "parent": 51,
      "props": {
        "children": {
          "type": "value",
          "value": "รหัสผู้ทำหัตถการ"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" ,display: props.hideCode ? \"none\" : \"flex\", alignItems: \"center\",marginRight:\"7px\"}"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 64,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px 10px 0px 10px\",display: props.hideDescription ? \"none\":\"\"}"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px \",display: props.hideDescription ? \"none\":\"flex\"}"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": null,
      "id": 91,
      "name": "div",
      "parent": 64,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.ErrorMessage"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props?.showSearchResultPrincipal ? \"\" : \"none\", position: \"absolute\", zIndex: 100, width: \"80%\", height: \"400px\" }"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props?.showSearchResultSecondary ? \"\" : \"none\", position: \"absolute\", zIndex: 100, width: \"80%\", height: \"400px\" }"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "div",
      "parent": 51,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonEditOrder"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": null,
      "id": 96,
      "name": "div",
      "parent": 51,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSaveOrder"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "div",
      "parent": 51,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonConfirmOrder"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": null,
      "id": 98,
      "name": "div",
      "parent": 51,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonCancelOrder"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 102,
      "name": "div",
      "parent": 64,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 103,
      "name": "FormGroup",
      "parent": 102,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{alignItems:\"baseline\"}"
        },
        "widths": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 104,
      "name": "FormGroup",
      "parent": 102,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 105,
      "name": "FormGroup",
      "parent": 102,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{alignItems:\"flex-start\"}"
        },
        "widths": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 106,
      "name": "FormField",
      "parent": 103,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{alignItems:\"baseline\"}"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 108,
      "name": "FormField",
      "parent": 103,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{alignItems:\"baseline\"}"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 111,
      "name": "FormField",
      "parent": 104,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 112,
      "name": "FormField",
      "parent": 104,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 113,
      "name": "FormField",
      "parent": 104,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 114,
      "name": "FormField",
      "parent": 104,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 115,
      "name": "FormField",
      "parent": 104,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 116,
      "name": "FormField",
      "parent": 105,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 117,
      "name": "FormField",
      "parent": 105,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 118,
      "name": "FormField",
      "parent": 105,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": null,
      "id": 119,
      "name": "label",
      "parent": 106,
      "props": {
        "children": {
          "type": "value",
          "value": "บันทึกผล :"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\",fontSize: \"1.3rem\"}"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": null,
      "id": 121,
      "name": "div",
      "parent": 108,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.treatmentRow?.order_detail_header"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 124,
      "name": "FormField",
      "parent": 103,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{alignItems:\"baseline\"}"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": null,
      "id": 125,
      "name": "div",
      "parent": 124,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.treatmentRow?.equipment_detail"
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": null,
      "id": 126,
      "name": "label",
      "parent": 108,
      "props": {
        "children": {
          "type": "value",
          "value": "รายละเอียดคำสั่งหัตถการ:"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bold\", fontSize:\"1rem\"}"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": null,
      "id": 127,
      "name": "label",
      "parent": 124,
      "props": {
        "children": {
          "type": "value",
          "value": " รายละเอียดอุปกรณ์:"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bold\", fontSize:\"1rem\"}"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": null,
      "id": 128,
      "name": "label",
      "parent": 111,
      "props": {
        "children": {
          "type": "value",
          "value": "วันเวลาการเริ่มทำหัตการ:"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bold\", fontSize:\"1rem\"}"
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": null,
      "id": 129,
      "name": "label",
      "parent": 114,
      "props": {
        "children": {
          "type": "value",
          "value": "วันเวลาสิ้นสุดการทำหัตถการ :"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bold\", fontSize:\"1rem\"}"
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": null,
      "id": 130,
      "name": "div",
      "parent": 112,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.StartTime"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\", marginBottom:\"-5px\"}"
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": null,
      "id": 131,
      "name": "div",
      "parent": 113,
      "props": {
        "children": {
          "type": "code",
          "value": "props.StartDateElement"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": null,
      "id": 132,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.FinishTime"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\", marginBottom:\"-5px\"}"
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 133,
      "name": "FormField",
      "parent": 104,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": null,
      "id": 134,
      "name": "div",
      "parent": 133,
      "props": {
        "children": {
          "type": "code",
          "value": "props.FinishDateElement"
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 135,
      "name": "FormField",
      "parent": 105,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": null,
      "id": 136,
      "name": "label",
      "parent": 116,
      "props": {
        "children": {
          "type": "value",
          "value": "รายละเอียด :"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bold\", fontSize:\"1rem\", minWidth: \"max-content\"}"
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": null,
      "id": 137,
      "name": "label",
      "parent": 118,
      "props": {
        "children": {
          "type": "value",
          "value": "ภาวะแทรกซ้อน :"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bold\", fontSize:\"1rem\", textAlign:\"right\", minWidth: \"max-content\"}"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 138,
      "name": "TextArea",
      "parent": 117,
      "props": {
        "id": {
          "type": "value",
          "value": "CardTreatmentResult-TextArea-OrderDetail"
        },
        "name": {
          "type": "value",
          "value": "result"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeResult"
        },
        "value": {
          "type": "code",
          "value": "props?.result"
        }
      },
      "seq": 138,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 139,
      "name": "TextArea",
      "parent": 135,
      "props": {
        "id": {
          "type": "value",
          "value": "CardTreatmentResult-TextArea-Complications"
        },
        "name": {
          "type": "value",
          "value": "complications"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeResult"
        },
        "value": {
          "type": "code",
          "value": "props?.complications"
        }
      },
      "seq": 139,
      "void": true
    }
  ],
  "forwardRef": false,
  "height": 100,
  "isMounted": false,
  "memo": false,
  "name": "CardTreatmentResultUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 90
}

*********************************************************************************** */
