import React from 'react'
import {
   Checkbox,
   Dropdown,
   Input,
   TextArea
} from 'semantic-ui-react'



import styles from './CardPTPhysicalexaminationCardiopulmonaryTOP.module.css'

export interface CardPTPhysicalexaminationCardiopulmonaryTOPViewDataType {
    Ct3Observation: string,
    Ct3ObservationDisabled: boolean,
    Ct3ObservationOptions: any,
    PtPhysicalCadioTop5: string,
    PtPhysicalCadioTop5Disabled: boolean,
    PtPhysicalCadioTop7: string,
    PtPhysicalCadioTop7Disabled: boolean,
    Ct8GeneralAppearance: string,
    Ct8GeneralAppearanceDisabled: boolean,
    Ct8GeneralAppearanceOptions: any,
    Ct10Consciousness: string,
    Ct10ConsciousnessDisabled: boolean,
    Ct10ConsciousnessOptions: any,
    Ct13Communication: string,
    Ct13CommunicationDisabled: boolean,
    Ct13CommunicationOptions: any,
    Ct13CoOperation: string,
    Ct13CoOperationDisabled: boolean,
    Ct13CoOperationOptions: any,
    PtPhysicalCadioTop21: boolean,
    PtPhysicalCadioTop21Disabled: boolean,
    PtPhysicalCadioTop22: string,
    PtPhysicalCadioTop22Disabled: boolean,
    PtPhysicalCadioTop23: boolean,
    PtPhysicalCadioTop23Disabled: boolean,
    PtPhysicalCadioTop120: boolean,
    PtPhysicalCadioTop120Disabled: boolean,
    PtPhysicalCadioTop121: string,
    PtPhysicalCadioTop121Disabled: boolean,
    PtPhysicalCadioTop25: boolean,
    PtPhysicalCadioTop25Disabled: boolean,
    PtPhysicalCadioTop27: boolean,
    PtPhysicalCadioTop27Disabled: boolean,
    PtPhysicalCadioTop30: boolean,
    PtPhysicalCadioTop30Disabled: boolean,
    Ct2Cough: string,
    Ct2CoughDisabled: boolean,
    Ct2CoughOptions: any,
    Ct2BreathingPattern: string,
    Ct2BreathingPatternDisabled: boolean,
    Ct2BreathingPatternOptions: any,
    PtPhysicalCadioTop38: string,
    PtPhysicalCadioTop38Disabled: boolean,
    Ct2ChestMovement: string,
    Ct2ChestMovementDisabled: boolean,
    Ct2ChestMovementOptions: any,
    PtPhysicalCadioTop41: string,
    PtPhysicalCadioTop41Disabled: boolean,
    Ct2ChestExpansion: string,
    Ct2ChestExpansionDisabled: boolean,
    Ct2ChestExpansionOptions: any,
    PtPhysicalCadioTop46: string,
    PtPhysicalCadioTop46Disabled: boolean,
    PtPhysicalCadioTop48: string,
    PtPhysicalCadioTop48Disabled: boolean,
    Ct2PercussionNote: string,
    Ct2PercussionNoteDisabled: boolean,
    Ct2PercussionNoteOptions: any,
    PtPhysicalCadioTop53: string,
    PtPhysicalCadioTop53Disabled: boolean,
    Ct2AdventitionsSound: string,
    Ct2AdventitionsSoundDisabled: boolean,
    Ct2AdventitionsSoundOptions: any,
    PtPhysicalCadioTop58: string,
    PtPhysicalCadioTop58Disabled: boolean,
    CtBedMobilityTestRolling: string,
    CtBedMobilityTestRollingDisabled: boolean,
    CtBedMobilityTestRollingOptions: any,
    PtPhysicalCadioTop62: boolean,
    PtPhysicalCadioTop62Disabled: boolean,
    CtRollingTransfer: string,
    CtRollingTransferDisabled: boolean,
    CtRollingTransferOptions: any,
    CtBedMobilityTestSupine: string,
    CtBedMobilityTestSupineDisabled: boolean,
    CtBedMobilityTestSupineOptions: any,
    CtSupineToSitTransfer: string,
    CtSupineToSitTransferDisabled: boolean,
    CtSupineToSitTransferOptions: any,
    CtBedMobilityTestSit: string,
    CtBedMobilityTestSitDisabled: boolean,
    CtBedMobilityTestSitOptions: any,
    CtSitToStandTransfer: string,
    CtSitToStandTransferDisabled: boolean,
    CtSitToStandTransferOptions: any,
    PtPhysicalCadioTop81: string,
    PtPhysicalCadioTop81Disabled: boolean,
    PtPhysicalCadioTop82: string,
    PtPhysicalCadioTop82Disabled: boolean,
    PtPhysicalCadioTop83: boolean,
    PtPhysicalCadioTop83Disabled: boolean,
    PtPhysicalCadioTop85: string,
    PtPhysicalCadioTop85Disabled: boolean,
    PtPhysicalCadioTop86: boolean,
    PtPhysicalCadioTop86Disabled: boolean,
    PtPhysicalCadioTop88: string,
    PtPhysicalCadioTop88Disabled: boolean,
    PtPhysicalCadioTop89: boolean,
    PtPhysicalCadioTop89Disabled: boolean,
    PtPhysicalCadioTop91: boolean,
    PtPhysicalCadioTop91Disabled: boolean,
    PtPhysicalCadioTop93: boolean,
    PtPhysicalCadioTop93Disabled: boolean,
    PtPhysicalCadioTop96: string,
    PtPhysicalCadioTop96Disabled: boolean,
    PtPhysicalCadioTop97: string,
    PtPhysicalCadioTop97Disabled: boolean,
    PtPhysicalCadioTop98: boolean,
    PtPhysicalCadioTop98Disabled: boolean,
    PtPhysicalCadioTop100: string,
    PtPhysicalCadioTop100Disabled: boolean,
    PtPhysicalCadioTop101: boolean,
    PtPhysicalCadioTop101Disabled: boolean,
    PtPhysicalCadioTop103: boolean,
    PtPhysicalCadioTop103Disabled: boolean,
    PtPhysicalCadioTop105: boolean,
    PtPhysicalCadioTop105Disabled: boolean,
    PtPhysicalCadioTop108: boolean,
    PtPhysicalCadioTop108Disabled: boolean,
    PtPhysicalCadioTop111: boolean,
    PtPhysicalCadioTop111Disabled: boolean,
    PtPhysicalCadioTop114: string,
    PtPhysicalCadioTop114Disabled: boolean,
    PtPhysicalCadioTop115: string,
    PtPhysicalCadioTop115Disabled: boolean,
    PtPhysicalCadioTop116: string,
    PtPhysicalCadioTop116Disabled: boolean,
    PtPhysicalCadioTop118: string,
    PtPhysicalCadioTop118Disabled: boolean,
    PtPhysicalCadioTop122: string,
    PtPhysicalCadioTop122Disabled: boolean,
}

export const CardPTPhysicalexaminationCardiopulmonaryTOPInitialViewData: CardPTPhysicalexaminationCardiopulmonaryTOPViewDataType = {
    Ct3Observation: "",
    Ct3ObservationDisabled: false,
    Ct3ObservationOptions: [{key: 0, value: "A", text: "A"}],
    PtPhysicalCadioTop5: "",
    PtPhysicalCadioTop5Disabled: false,
    PtPhysicalCadioTop7: "",
    PtPhysicalCadioTop7Disabled: false,
    Ct8GeneralAppearance: "",
    Ct8GeneralAppearanceDisabled: false,
    Ct8GeneralAppearanceOptions: [{key: 0, value: "A", text: "A"}],
    Ct10Consciousness: "",
    Ct10ConsciousnessDisabled: false,
    Ct10ConsciousnessOptions: [{key: 0, value: "A", text: "A"}],
    Ct13Communication: "",
    Ct13CommunicationDisabled: false,
    Ct13CommunicationOptions: [{key: 0, value: "A", text: "A"}],
    Ct13CoOperation: "",
    Ct13CoOperationDisabled: false,
    Ct13CoOperationOptions: [{key: 0, value: "A", text: "A"}],
    PtPhysicalCadioTop21: false,
    PtPhysicalCadioTop21Disabled: false,
    PtPhysicalCadioTop22: "",
    PtPhysicalCadioTop22Disabled: true,
    PtPhysicalCadioTop23: false,
    PtPhysicalCadioTop23Disabled: false,
    PtPhysicalCadioTop120: false,
    PtPhysicalCadioTop120Disabled: true,
    PtPhysicalCadioTop121: "",
    PtPhysicalCadioTop121Disabled: true,
    PtPhysicalCadioTop25: false,
    PtPhysicalCadioTop25Disabled: false,
    PtPhysicalCadioTop27: false,
    PtPhysicalCadioTop27Disabled: false,
    PtPhysicalCadioTop30: false,
    PtPhysicalCadioTop30Disabled: false,
    Ct2Cough: "",
    Ct2CoughDisabled: false,
    Ct2CoughOptions: [{key: 0, value: "A", text: "A"}],
    Ct2BreathingPattern: "",
    Ct2BreathingPatternDisabled: false,
    Ct2BreathingPatternOptions: [{key: 0, value: "A", text: "A"}],
    PtPhysicalCadioTop38: "",
    PtPhysicalCadioTop38Disabled: false,
    Ct2ChestMovement: "",
    Ct2ChestMovementDisabled: false,
    Ct2ChestMovementOptions: [{key: 0, value: "A", text: "A"}],
    PtPhysicalCadioTop41: "",
    PtPhysicalCadioTop41Disabled: false,
    Ct2ChestExpansion: "",
    Ct2ChestExpansionDisabled: false,
    Ct2ChestExpansionOptions: [{key: 0, value: "A", text: "A"}],
    PtPhysicalCadioTop46: "",
    PtPhysicalCadioTop46Disabled: false,
    PtPhysicalCadioTop48: "",
    PtPhysicalCadioTop48Disabled: false,
    Ct2PercussionNote: "",
    Ct2PercussionNoteDisabled: false,
    Ct2PercussionNoteOptions: [{key: 0, value: "A", text: "A"}],
    PtPhysicalCadioTop53: "",
    PtPhysicalCadioTop53Disabled: false,
    Ct2AdventitionsSound: "",
    Ct2AdventitionsSoundDisabled: false,
    Ct2AdventitionsSoundOptions: [{key: 0, value: "A", text: "A"}],
    PtPhysicalCadioTop58: "",
    PtPhysicalCadioTop58Disabled: false,
    CtBedMobilityTestRolling: "",
    CtBedMobilityTestRollingDisabled: false,
    CtBedMobilityTestRollingOptions: [{key: 0, value: "A", text: "A"}],
    PtPhysicalCadioTop62: false,
    PtPhysicalCadioTop62Disabled: false,
    CtRollingTransfer: "",
    CtRollingTransferDisabled: false,
    CtRollingTransferOptions: [{key: 0, value: "A", text: "A"}],
    CtBedMobilityTestSupine: "",
    CtBedMobilityTestSupineDisabled: false,
    CtBedMobilityTestSupineOptions: [{key: 0, value: "A", text: "A"}],
    CtSupineToSitTransfer: "",
    CtSupineToSitTransferDisabled: false,
    CtSupineToSitTransferOptions: [{key: 0, value: "A", text: "A"}],
    CtBedMobilityTestSit: "",
    CtBedMobilityTestSitDisabled: false,
    CtBedMobilityTestSitOptions: [{key: 0, value: "A", text: "A"}],
    CtSitToStandTransfer: "",
    CtSitToStandTransferDisabled: false,
    CtSitToStandTransferOptions: [{key: 0, value: "A", text: "A"}],
    PtPhysicalCadioTop81: "",
    PtPhysicalCadioTop81Disabled: false,
    PtPhysicalCadioTop82: "",
    PtPhysicalCadioTop82Disabled: false,
    PtPhysicalCadioTop83: false,
    PtPhysicalCadioTop83Disabled: false,
    PtPhysicalCadioTop85: "",
    PtPhysicalCadioTop85Disabled: false,
    PtPhysicalCadioTop86: false,
    PtPhysicalCadioTop86Disabled: false,
    PtPhysicalCadioTop88: "",
    PtPhysicalCadioTop88Disabled: false,
    PtPhysicalCadioTop89: false,
    PtPhysicalCadioTop89Disabled: false,
    PtPhysicalCadioTop91: false,
    PtPhysicalCadioTop91Disabled: false,
    PtPhysicalCadioTop93: false,
    PtPhysicalCadioTop93Disabled: false,
    PtPhysicalCadioTop96: "",
    PtPhysicalCadioTop96Disabled: false,
    PtPhysicalCadioTop97: "",
    PtPhysicalCadioTop97Disabled: false,
    PtPhysicalCadioTop98: false,
    PtPhysicalCadioTop98Disabled: false,
    PtPhysicalCadioTop100: "",
    PtPhysicalCadioTop100Disabled: false,
    PtPhysicalCadioTop101: false,
    PtPhysicalCadioTop101Disabled: false,
    PtPhysicalCadioTop103: false,
    PtPhysicalCadioTop103Disabled: false,
    PtPhysicalCadioTop105: false,
    PtPhysicalCadioTop105Disabled: false,
    PtPhysicalCadioTop108: false,
    PtPhysicalCadioTop108Disabled: false,
    PtPhysicalCadioTop111: false,
    PtPhysicalCadioTop111Disabled: false,
    PtPhysicalCadioTop114: "",
    PtPhysicalCadioTop114Disabled: false,
    PtPhysicalCadioTop115: "",
    PtPhysicalCadioTop115Disabled: false,
    PtPhysicalCadioTop116: "",
    PtPhysicalCadioTop116Disabled: false,
    PtPhysicalCadioTop118: "",
    PtPhysicalCadioTop118Disabled: false,
    PtPhysicalCadioTop122: "",
    PtPhysicalCadioTop122Disabled: false,

}

const Sub = (props: any) => {
    const children = React.Children.toArray(props.children)
    return (
        <div className={styles.container}>
            <div className={styles.PtPhysicalCadioTop1}>
                <div
                    style={{background: "#F3F3F3", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.Ct3Observation}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct3Observation}
                    disabled={ props.viewData.Ct3ObservationDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardPTPhysicalexaminationCardiopulmonaryTOP",
                                name: "Ct3Observation",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct3ObservationOptions}
                />
            </div>
            <div className={styles.PtPhysicalCadioTop5}>
                <Input
                    value={props.viewData.PtPhysicalCadioTop5}
                    disabled={ props.viewData.PtPhysicalCadioTop5Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPTPhysicalexaminationCardiopulmonaryTOP',
                            name: 'PtPhysicalCadioTop5',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtPhysicalCadioTop6}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Observation</strong></p>
                </div>
            </div>
            <div className={styles.PtPhysicalCadioTop7}>
                <Input
                    value={props.viewData.PtPhysicalCadioTop7}
                    disabled={ props.viewData.PtPhysicalCadioTop7Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPTPhysicalexaminationCardiopulmonaryTOP',
                            name: 'PtPhysicalCadioTop7',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtPhysicalCadioTop8}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>General apperance</strong></p>
                </div>
            </div>
            <div className={styles.Ct8GeneralAppearance}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct8GeneralAppearance}
                    disabled={ props.viewData.Ct8GeneralAppearanceDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardPTPhysicalexaminationCardiopulmonaryTOP",
                                name: "Ct8GeneralAppearance",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct8GeneralAppearanceOptions}
                />
            </div>
            <div className={styles.Ct10Consciousness}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct10Consciousness}
                    disabled={ props.viewData.Ct10ConsciousnessDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardPTPhysicalexaminationCardiopulmonaryTOP",
                                name: "Ct10Consciousness",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct10ConsciousnessOptions}
                />
            </div>
            <div className={styles.Ct13Communication}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct13Communication}
                    disabled={ props.viewData.Ct13CommunicationDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardPTPhysicalexaminationCardiopulmonaryTOP",
                                name: "Ct13Communication",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct13CommunicationOptions}
                />
            </div>
            <div className={styles.Ct13CoOperation}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct13CoOperation}
                    disabled={ props.viewData.Ct13CoOperationDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardPTPhysicalexaminationCardiopulmonaryTOP",
                                name: "Ct13CoOperation",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct13CoOperationOptions}
                />
            </div>
            <div className={styles.PtPhysicalCadioTop18}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Consciousness</strong></p>
                </div>
            </div>
            <div className={styles.PtPhysicalCadioTop19}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Communication</strong></p>
                </div>
            </div>
            <div className={styles.PtPhysicalCadioTop20}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Co-operation</strong></p>
                </div>
            </div>
            <div className={styles.PtPhysicalCadioTop21}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtPhysicalCadioTop21}
                    disabled={ props.viewData.PtPhysicalCadioTop21Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTPhysicalexaminationCardiopulmonaryTOP",
                                name: "PtPhysicalCadioTop21"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtPhysicalCadioTop22}>
                <Input
                    value={props.viewData.PtPhysicalCadioTop22}
                    disabled={ props.viewData.PtPhysicalCadioTop22Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPTPhysicalexaminationCardiopulmonaryTOP',
                            name: 'PtPhysicalCadioTop22',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtPhysicalCadioTop23}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtPhysicalCadioTop23}
                    disabled={ props.viewData.PtPhysicalCadioTop23Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTPhysicalexaminationCardiopulmonaryTOP",
                                name: "PtPhysicalCadioTop23"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtPhysicalCadioTop24}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Oxygen therapy</strong></p>
                </div>
            </div>
            <div className={styles.PtPhysicalCadioTop25}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtPhysicalCadioTop25}
                    disabled={ props.viewData.PtPhysicalCadioTop25Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTPhysicalexaminationCardiopulmonaryTOP",
                                name: "PtPhysicalCadioTop25"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtPhysicalCadioTop26}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Room air</p>
                </div>
            </div>
            <div className={styles.PtPhysicalCadioTop27}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtPhysicalCadioTop27}
                    disabled={ props.viewData.PtPhysicalCadioTop27Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTPhysicalexaminationCardiopulmonaryTOP",
                                name: "PtPhysicalCadioTop22"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtPhysicalCadioTop28}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>On ET tube</p>
                </div>
            </div>
            <div className={styles.PtPhysicalCadioTop27}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtPhysicalCadioTop27}
                    disabled={ props.viewData.PtPhysicalCadioTop27Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTPhysicalexaminationCardiopulmonaryTOP",
                                name: "PtPhysicalCadioTop27"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtPhysicalCadioTop119}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>On TT tube</p>
                </div>
            </div>
            <div className={styles.PtPhysicalCadioTop120}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtPhysicalCadioTop120}
                    disabled={ props.viewData.PtPhysicalCadioTop120Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTPhysicalexaminationCardiopulmonaryTOP",
                                name: "PtPhysicalCadioTop120"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtPhysicalCadioTop29}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Oxygen mask with bag</p>
                </div>
            </div>
            <div className={styles.PtPhysicalCadioTop30}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtPhysicalCadioTop30}
                    disabled={ props.viewData.PtPhysicalCadioTop30Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTPhysicalexaminationCardiopulmonaryTOP",
                                name: "PtPhysicalCadioTop30"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtPhysicalCadioTop31}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Oxygen cannular</p>
                </div>
            </div>
            <div className={styles.PtPhysicalCadioTop32}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>On ventilator</p>
                </div>
            </div>
            <div className={styles.PtPhysicalCadioTop121}>
                <Input
                    value={props.viewData.PtPhysicalCadioTop121}
                    disabled={ props.viewData.PtPhysicalCadioTop121Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPTPhysicalexaminationCardiopulmonaryTOP',
                            name: 'PtPhysicalCadioTop121',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.Ct2Cough}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct2Cough}
                    disabled={ props.viewData.Ct2CoughDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardPTPhysicalexaminationCardiopulmonaryTOP",
                                name: "Ct2Cough",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct2CoughOptions}
                />
            </div>
            <div className={styles.PtPhysicalCadioTop36}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Cough</strong></p>
                </div>
            </div>
            <div className={styles.Ct2BreathingPattern}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct2BreathingPattern}
                    disabled={ props.viewData.Ct2BreathingPatternDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardPTPhysicalexaminationCardiopulmonaryTOP",
                                name: "Ct2BreathingPattern",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct2BreathingPatternOptions}
                />
            </div>
            <div className={styles.PtPhysicalCadioTop38}>
                <Input
                    value={props.viewData.PtPhysicalCadioTop38}
                    disabled={ props.viewData.PtPhysicalCadioTop38Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPTPhysicalexaminationCardiopulmonaryTOP',
                            name: 'PtPhysicalCadioTop38',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtPhysicalCadioTop39}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Breathing pattern</strong></p>
                </div>
            </div>
            <div className={styles.Ct2ChestMovement}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct2ChestMovement}
                    disabled={ props.viewData.Ct2ChestMovementDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardPTPhysicalexaminationCardiopulmonaryTOP",
                                name: "Ct2ChestMovement",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct2ChestMovementOptions}
                />
            </div>
            <div className={styles.PtPhysicalCadioTop41}>
                <Input
                    value={props.viewData.PtPhysicalCadioTop41}
                    disabled={ props.viewData.PtPhysicalCadioTop41Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPTPhysicalexaminationCardiopulmonaryTOP',
                            name: 'PtPhysicalCadioTop41',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.Ct2ChestExpansion}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct2ChestExpansion}
                    disabled={ props.viewData.Ct2ChestExpansionDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardPTPhysicalexaminationCardiopulmonaryTOP",
                                name: "Ct2ChestExpansion",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct2ChestExpansionOptions}
                />
            </div>
            <div className={styles.PtPhysicalCadioTop45}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Chest movement</strong></p>
                </div>
            </div>
            <div className={styles.PtPhysicalCadioTop46}>
                <Input
                    value={props.viewData.PtPhysicalCadioTop46}
                    disabled={ props.viewData.PtPhysicalCadioTop46Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPTPhysicalexaminationCardiopulmonaryTOP',
                            name: 'PtPhysicalCadioTop46',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtPhysicalCadioTop47}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Chest expansion</strong></p>
                </div>
            </div>
            <div className={styles.PtPhysicalCadioTop48}>
                <Input
                    value={props.viewData.PtPhysicalCadioTop48}
                    disabled={ props.viewData.PtPhysicalCadioTop48Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPTPhysicalexaminationCardiopulmonaryTOP',
                            name: 'PtPhysicalCadioTop48',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtPhysicalCadioTop49}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Percussion Note</strong></p>
                </div>
            </div>
            <div className={styles.Ct2PercussionNote}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct2PercussionNote}
                    disabled={ props.viewData.Ct2PercussionNoteDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardPTPhysicalexaminationCardiopulmonaryTOP",
                                name: "Ct2PercussionNote",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct2PercussionNoteOptions}
                />
            </div>
            <div className={styles.PtPhysicalCadioTop53}>
                <Input
                    value={props.viewData.PtPhysicalCadioTop53}
                    disabled={ props.viewData.PtPhysicalCadioTop53Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPTPhysicalexaminationCardiopulmonaryTOP',
                            name: 'PtPhysicalCadioTop53',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.Ct2AdventitionsSound}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct2AdventitionsSound}
                    disabled={ props.viewData.Ct2AdventitionsSoundDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardPTPhysicalexaminationCardiopulmonaryTOP",
                                name: "Ct2AdventitionsSound",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct2AdventitionsSoundOptions}
                />
            </div>
            <div className={styles.PtPhysicalCadioTop57}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Adventitions sound</strong></p>
                </div>
            </div>
            <div className={styles.PtPhysicalCadioTop58}>
                <Input
                    value={props.viewData.PtPhysicalCadioTop58}
                    disabled={ props.viewData.PtPhysicalCadioTop58Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPTPhysicalexaminationCardiopulmonaryTOP',
                            name: 'PtPhysicalCadioTop58',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CtBedMobilityTestRolling}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtBedMobilityTestRolling}
                    disabled={ props.viewData.CtBedMobilityTestRollingDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardPTPhysicalexaminationCardiopulmonaryTOP",
                                name: "CtBedMobilityTestRolling",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtBedMobilityTestRollingOptions}
                />
            </div>
            <div className={styles.PtPhysicalCadioTop62}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtPhysicalCadioTop62}
                    disabled={ props.viewData.PtPhysicalCadioTop62Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTPhysicalexaminationCardiopulmonaryTOP",
                                name: "PtPhysicalCadioTop62"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CtRollingTransfer}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtRollingTransfer}
                    disabled={ props.viewData.CtRollingTransferDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardPTPhysicalexaminationCardiopulmonaryTOP",
                                name: "CtRollingTransfer",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtRollingTransferOptions}
                />
            </div>
            <div className={styles.PtPhysicalCadioTop66}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Functional test</strong></p>
                </div>
            </div>
            <div className={styles.PtPhysicalCadioTop67}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>independent</p>
                </div>
            </div>
            <div className={styles.PtPhysicalCadioTop68}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Rolling</p>
                </div>
            </div>
            <div className={styles.CtBedMobilityTestSupine}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtBedMobilityTestSupine}
                    disabled={ props.viewData.CtBedMobilityTestSupineDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardPTPhysicalexaminationCardiopulmonaryTOP",
                                name: "CtBedMobilityTestSupine",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtBedMobilityTestSupineOptions}
                />
            </div>
            <div className={styles.CtSupineToSitTransfer}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtSupineToSitTransfer}
                    disabled={ props.viewData.CtSupineToSitTransferDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardPTPhysicalexaminationCardiopulmonaryTOP",
                                name: "CtSupineToSitTransfer",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtSupineToSitTransferOptions}
                />
            </div>
            <div className={styles.PtPhysicalCadioTop74}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Supine to sit</p>
                </div>
            </div>
            <div className={styles.CtBedMobilityTestSit}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtBedMobilityTestSit}
                    disabled={ props.viewData.CtBedMobilityTestSitDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardPTPhysicalexaminationCardiopulmonaryTOP",
                                name: "CtBedMobilityTestSit",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtBedMobilityTestSitOptions}
                />
            </div>
            <div className={styles.CtSitToStandTransfer}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtSitToStandTransfer}
                    disabled={ props.viewData.CtSitToStandTransferDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardPTPhysicalexaminationCardiopulmonaryTOP",
                                name: "CtSitToStandTransfer",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtSitToStandTransferOptions}
                />
            </div>
            <div className={styles.PtPhysicalCadioTop80}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Sit to stand</p>
                </div>
            </div>
            <div className={styles.PtPhysicalCadioTop81}>
                <Input
                    value={props.viewData.PtPhysicalCadioTop81}
                    disabled={ props.viewData.PtPhysicalCadioTop81Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPTPhysicalexaminationCardiopulmonaryTOP',
                            name: 'PtPhysicalCadioTop81',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtPhysicalCadioTop82}>
                <Input
                    value={props.viewData.PtPhysicalCadioTop82}
                    disabled={ props.viewData.PtPhysicalCadioTop82Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPTPhysicalexaminationCardiopulmonaryTOP',
                            name: 'PtPhysicalCadioTop82',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtPhysicalCadioTop83}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtPhysicalCadioTop83}
                    disabled={ props.viewData.PtPhysicalCadioTop83Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTPhysicalexaminationCardiopulmonaryTOP",
                                name: "PtPhysicalCadioTop83"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtPhysicalCadioTop84}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Muscle length</strong></p>
                </div>
            </div>
            <div className={styles.PtPhysicalCadioTop85}>
                <Input
                    value={props.viewData.PtPhysicalCadioTop85}
                    disabled={ props.viewData.PtPhysicalCadioTop85Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPTPhysicalexaminationCardiopulmonaryTOP',
                            name: 'PtPhysicalCadioTop85',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtPhysicalCadioTop86}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtPhysicalCadioTop86}
                    disabled={ props.viewData.PtPhysicalCadioTop86Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTPhysicalexaminationCardiopulmonaryTOP",
                                name: "PtPhysicalCadioTop86"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtPhysicalCadioTop87}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Normal</p>
                </div>
            </div>
            <div className={styles.PtPhysicalCadioTop88}>
                <Input
                    value={props.viewData.PtPhysicalCadioTop88}
                    disabled={ props.viewData.PtPhysicalCadioTop88Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPTPhysicalexaminationCardiopulmonaryTOP',
                            name: 'PtPhysicalCadioTop88',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtPhysicalCadioTop89}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtPhysicalCadioTop89}
                    disabled={ props.viewData.PtPhysicalCadioTop89Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTPhysicalexaminationCardiopulmonaryTOP",
                                name: "PtPhysicalCadioTop89"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtPhysicalCadioTop90}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Tightness</p>
                </div>
            </div>
            <div className={styles.PtPhysicalCadioTop91}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtPhysicalCadioTop91}
                    disabled={ props.viewData.PtPhysicalCadioTop91Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTPhysicalexaminationCardiopulmonaryTOP",
                                name: "PtPhysicalCadioTop91"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtPhysicalCadioTop92}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Shortening</p>
                </div>
            </div>
            <div className={styles.PtPhysicalCadioTop93}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtPhysicalCadioTop93}
                    disabled={ props.viewData.PtPhysicalCadioTop93Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTPhysicalexaminationCardiopulmonaryTOP",
                                name: "PtPhysicalCadioTop93"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtPhysicalCadioTop94}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Contracture</p>
                </div>
            </div>
            <div className={styles.PtPhysicalCadioTop95}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>N/A</p>
                </div>
            </div>
            <div className={styles.PtPhysicalCadioTop96}>
                <Input
                    value={props.viewData.PtPhysicalCadioTop96}
                    disabled={ props.viewData.PtPhysicalCadioTop96Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPTPhysicalexaminationCardiopulmonaryTOP',
                            name: 'PtPhysicalCadioTop96',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtPhysicalCadioTop97}>
                <Input
                    value={props.viewData.PtPhysicalCadioTop97}
                    disabled={ props.viewData.PtPhysicalCadioTop97Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPTPhysicalexaminationCardiopulmonaryTOP',
                            name: 'PtPhysicalCadioTop97',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtPhysicalCadioTop98}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtPhysicalCadioTop98}
                    disabled={ props.viewData.PtPhysicalCadioTop98Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTPhysicalexaminationCardiopulmonaryTOP",
                                name: "PtPhysicalCadioTop98"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtPhysicalCadioTop99}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Muscle tone</strong></p>
                </div>
            </div>
            <div className={styles.PtPhysicalCadioTop100}>
                <Input
                    value={props.viewData.PtPhysicalCadioTop100}
                    disabled={ props.viewData.PtPhysicalCadioTop100Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPTPhysicalexaminationCardiopulmonaryTOP',
                            name: 'PtPhysicalCadioTop100',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtPhysicalCadioTop101}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtPhysicalCadioTop101}
                    disabled={ props.viewData.PtPhysicalCadioTop101Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTPhysicalexaminationCardiopulmonaryTOP",
                                name: "PtPhysicalCadioTop101"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtPhysicalCadioTop102}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Normal</p>
                </div>
            </div>
            <div className={styles.PtPhysicalCadioTop103}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtPhysicalCadioTop103}
                    disabled={ props.viewData.PtPhysicalCadioTop103Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTPhysicalexaminationCardiopulmonaryTOP",
                                name: "PtPhysicalCadioTop103"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtPhysicalCadioTop104}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Spasticity</p>
                </div>
            </div>
            <div className={styles.PtPhysicalCadioTop105}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtPhysicalCadioTop105}
                    disabled={ props.viewData.PtPhysicalCadioTop105Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTPhysicalexaminationCardiopulmonaryTOP",
                                name: "PtPhysicalCadioTop105"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtPhysicalCadioTop106}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Flaccid</p>
                </div>
            </div>
            <div className={styles.PtPhysicalCadioTop107}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>N/A</p>
                </div>
            </div>
            <div className={styles.PtPhysicalCadioTop108}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtPhysicalCadioTop108}
                    disabled={ props.viewData.PtPhysicalCadioTop108Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTPhysicalexaminationCardiopulmonaryTOP",
                                name: "PtPhysicalCadioTop108"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtPhysicalCadioTop109}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Motor Power:</strong></p>
                </div>
            </div>
            <div className={styles.PtPhysicalCadioTop110}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Right</p>
                </div>
            </div>
            <div className={styles.PtPhysicalCadioTop111}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtPhysicalCadioTop111}
                    disabled={ props.viewData.PtPhysicalCadioTop111Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTPhysicalexaminationCardiopulmonaryTOP",
                                name: "PtPhysicalCadioTop111"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtPhysicalCadioTop112}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Left</p>
                </div>
            </div>
            <div className={styles.PtPhysicalCadioTop113}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>N/A</p>
                </div>
            </div>
            <div className={styles.PtPhysicalCadioTop114}>
                <Input
                    value={props.viewData.PtPhysicalCadioTop114}
                    disabled={ props.viewData.PtPhysicalCadioTop114Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPTPhysicalexaminationCardiopulmonaryTOP',
                            name: 'PtPhysicalCadioTop114',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtPhysicalCadioTop115}>
                <Input
                    value={props.viewData.PtPhysicalCadioTop115}
                    disabled={ props.viewData.PtPhysicalCadioTop115Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPTPhysicalexaminationCardiopulmonaryTOP',
                            name: 'PtPhysicalCadioTop115',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtPhysicalCadioTop116}>
                <Input
                    value={props.viewData.PtPhysicalCadioTop116}
                    disabled={ props.viewData.PtPhysicalCadioTop116Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPTPhysicalexaminationCardiopulmonaryTOP',
                            name: 'PtPhysicalCadioTop116',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtPhysicalCadioTop117}>
                <div
                    style={{border: "solid lightgrey 1px", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.PtPhysicalCadioTop118}>
                <Input
                    value={props.viewData.PtPhysicalCadioTop118}
                    disabled={ props.viewData.PtPhysicalCadioTop118Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPTPhysicalexaminationCardiopulmonaryTOP',
                            name: 'PtPhysicalCadioTop118',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtPhysicalCadioTop122}>
                <TextArea
                    value={props.viewData.PtPhysicalCadioTop122}
                    disabled={ props.viewData.PtPhysicalCadioTop122Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPTPhysicalexaminationCardiopulmonaryTOP',
                            name: 'PtPhysicalCadioTop122',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </TextArea>
            </div>
        </div>
    )
}

export default Sub

const getTsName = (cssClass: string) => {
    return cssClass.split('-').map((s) => (
        s.charAt(0).toUpperCase() + s.slice(1)
    )).join('')
}
