import React from 'react';
import { Button, Segment, Modal } from 'semantic-ui-react';
import { ToastContainer, toast, cssTransition } from 'react-toastify';
import BaseService from '../../apis/services/BaseService';

// COMMON CSS
import 'react-table-6/react-table.css'
import 'react-toastify/dist/ReactToastify.css';
import '../../css/ReactTable.css';
import '../../css/CardLayout.css';

// COMMON JS
import 'moment/locale/th';
import '../../utils/moment-thai-year-patch';
import { FormattedMessage } from "react-intl";

const CustomZoom = cssTransition({
  enter: 'Toastify__zoom-enter',
  exit: 'Toastify__zoom-exit',
  // @ts-ignore
  duration: 200,
});

class Interceptor extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = {
      internalError: null
    };
  }

  componentDidMount() {
    BaseService.addInterceptor(this, this.handleError);
  }

  componentWillUnmount() {
    BaseService.removeInterceptor(this);
  }

  handleError = (errorMessage: any) => {
    this.setState({ internalError: errorMessage });
  };

  onCloseErrorModal = () => {
    this.setState({ internalError: null });
  };

  render() {
    const {
      internalError
    }: any = this.state;


    return (
      <React.Fragment>
        <Modal
          open={!!internalError}
          onClose={this.onCloseErrorModal}
          content={
            <Segment.Group>
              <Segment inverted color='red'><FormattedMessage id="เกิดข้อผิดพลาด"/></Segment>
              <Segment raised>
                <div style={{ whiteSpace: 'pre-line' }} > {internalError} </div>
                <Button
                  basic
                  color='red'
                  style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    display: 'block',
                  }}
                  onClick={this.onCloseErrorModal}
                ><FormattedMessage id="ตกลง"/></Button>
              </Segment>
            </Segment.Group>
          }
        />
        <ToastContainer
          transition={CustomZoom}
          position={toast.POSITION.TOP_CENTER}
        />
      </React.Fragment>
    );
  }
}

export default Interceptor;