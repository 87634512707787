import WasmController from "react-lib/frameworks/WasmController";

import moment from "moment";

import NursingDiagnosisPrint from "issara-sdk/apis/NursingDiagnosisPrint_apps_PTM";
import NursingDiagnosisResulFromTemplate from "issara-sdk/apis/NursingDiagnosisResulFromTemplate_apps_PTM";
import NursingDiagnosisResultDetail from "issara-sdk/apis/NursingDiagnosisResultDetail_apps_PTM";
import NursingDiagnosisResultGroupDetail from "issara-sdk/apis/NursingDiagnosisResultGroupDetail_apps_PTM";
import NursingDiagnosisResultGroupList from "issara-sdk/apis/NursingDiagnosisResultGroupList_apps_PTM";
import NursingDiagnosisResultList from "issara-sdk/apis/NursingDiagnosisResultList_apps_PTM";
import NursingDiagnosisTemplateList from "issara-sdk/apis/NursingDiagnosisTemplateList_apps_PTM";

import NursingDiagnosisResultGroupSerializerI from "issara-sdk/types/NursingDiagnosisResultGroupSerializer_apps_PTM";
import NursingDiagnosisTemplateSerializerI from "issara-sdk/types/NursingDiagnosisTemplateSerializer_apps_PTM";

import { SetProperty } from "react-lib/apps/HISV3/common/CommonInterface";

import { State as MainState } from "HIS/MainHISInterface";

import { formatDate } from "react-lib/utils/dateUtils";

/** ============== Type-Defining Constants =============  */
export const ACTIONS = {
  ADD: "add",
  CLEAR: "clear",
  DELETE: "delete",
  GET_DATA: "getData",
  HANDLE_CHANGE: "handleChange",
  PRINT: "print",
  SAVE: "save",
  SAVE_TEMPLATE: "saveTemplate",
  SEARCH: "search",
  SELECT: "select",
  SELECT_EDIT: "selectEdit",
  TEMPLATE_FORM: "templateForm",
  TEMPLATE_LIST: "templateList",
  UPDATE: "update",
} as const;

/** =============== Types and Interfaces ===============  */
export type State = Partial<{
  NursingDiagnosisSequence: Partial<{
    sequenceIndex: "Action" | "Start" | null;
    nursingDiagnosis: NursingDiagnosis;
    nursingDiagnosisList: Record<string, any>[];
    nursingDiagnosisLoading: boolean;
    nursingDiagnosisSearch: NursingDiagnosisSearch;
    nursingDiagnosisSelected: Record<string, any>;
    nursingDiagnosistemplateList: NursingDiagnosisTemplateSerializer[];
    nursingDiagnosistemplateQuestionsList: NursingDiagnosisTemplateSerializer["questions"];
    resultGroupList: NursingDiagnosisResultGroupSerializer[];
    nursingDiagnosisTemplateOptions: { key: string; text: string; value: string }[];
  }> | null;
}>;

type PickedState = Pick<
  MainState,
  "buttonLoadCheck" | "django" | "errorMessage" | "selectedEncounter" | "successMessage"
>;

export type PickedProps = Partial<Omit<PickedState, "">>;

type NursingDiagnosisResultGroupSerializer = {
  results: {
    id: number;
    active: boolean;
    cancelable: boolean;
    created: string;
    diagnosis: string;
    document: number;
    edit_user_name: string;
    editable: boolean;
    edited: string;
    encounter: number;
    end_date: any;
    goal: string;
    plan: string;
  }[];
} & Omit<NursingDiagnosisResultGroupSerializerI, "results">;

type NursingDiagnosisTemplateSerializer = {
  questions: {
    id: number;
    active: true;
    end_date?: string;
    items: {
      id: number;
      active: boolean;
      column: number;
      name: string;
      order: number;
      question: number;
      type: string;
    }[];
    name: string;
    row: number;
    template: number;
  }[];
} & Omit<NursingDiagnosisTemplateSerializerI, "questions">;

type NursingDiagnosis = Partial<{
  id: number | null;
  diagnosis: string;
  division: number;
  end_date: string;
  goal: string;
  group: number | null;
  patient_id: number;
  pk: string | null;
  plan: string;
  templateID: string;
}>;

type NursingDiagnosisSearch = Partial<{
  division: number;
  end_date: string;
  start_date: string;
}>;

type PrefixedOptional<T> = {
  [K in keyof T]: `${typeof CARD_NURSING_DIAGNOSIS}_${string & T[K]}`;
};

export type Event =
  | { message: "GetMasterData"; params: Record<string, unknown> }
  | { message: "RunSequence"; params: Record<string, unknown> };

export type Data = {
  device?: number;
  division?: number;
};

export type SeqState = {
  sequence: "NursingDiagnosis";
  clear?: boolean;
  encounterId?: number | null;
  restart?: boolean;
};

export type Actions = typeof ACTIONS;

type ActionValues = Actions[keyof Actions];

type ActionPayloadMap = {
  [ACTIONS.DELETE]: { id: number; card: string };
  [ACTIONS.GET_DATA]: { card: string };
  [ACTIONS.HANDLE_CHANGE]: { date: string; pIndex: number };
  [ACTIONS.PRINT]: { card: string; diagResults: any };
  [ACTIONS.SAVE_TEMPLATE]: { card: string };
  [ACTIONS.SAVE]: { card: string };
  [ACTIONS.SELECT_EDIT]: { data: NursingDiagnosisResultGroupSerializer["results"][number] };
  [ACTIONS.SELECT]: { card: string; item: any };
  [ACTIONS.UPDATE]: { card: string };
};

type ActionPayload = {
  [K in ActionValues]: {
    sequence: SeqState["sequence"];
    action: K;
  } & (K extends keyof ActionPayloadMap ? ActionPayloadMap[K] : Record<string, unknown>);
};

export type ActionType = ActionPayload[ActionValues];

type SeqAct = ActionType & SeqState;

type SeqType<K> = K extends { action: string } ? Extract<SeqAct, K> : SeqState;

export type RunSequence = <K extends keyof SeqAct>(params: SeqType<Pick<SeqAct, K>>) => any;

export type SetProp = SetProperty<PickedState & State>;

type CustomExtract<T, U> = T extends T ? (U extends Partial<T> ? T : never) : never;

type Params<A extends ActionType["action"]> = CustomExtract<ActionType, { action: A }>;

type Handler<P = unknown, R = void> = (
  controller: WasmController<PickedState & State, Event, Data>,
  ...params: unknown extends P ? [params?: P] : [params: P]
) => R;

/** ===================== Constants ====================  */
export const StateInitial: State = {
  NursingDiagnosisSequence: null,
};

export const DataInitial = {};

const INITIAL_NURSING_DIAGNOSIS = {
  diagnosis: "",
  end_date: "",
  goal: "",
  group: null,
  plan: "",
};

const MASTERS = [["division", {}]] as const;

export const CARD_NURSING_DIAGNOSIS = "CardNursingDiagnosis";

const BLC_STATUS = {
  ERROR: "ERROR",
  LOADING: "LOADING",
  SUCCESS: "SUCCESS",
} as const;

export const BTN_ACTS = Object.fromEntries(
  Object.entries(ACTIONS).map(([key, value]) => [key, `${CARD_NURSING_DIAGNOSIS}_${value}`])
) as PrefixedOptional<typeof ACTIONS>;

/** ===================== Functions ====================  */
export const Start: Handler = async (controller, params) => {
  const state = controller.getState();

  if (!state.NursingDiagnosisSequence) {
    return;
  }

  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: MASTERS,
    },
  });

  Action(controller, params as ActionType);
};

/** ====================================================  */
/**                        Action                         */
/** ====================================================  */
export const Action: Handler<ActionType> = async (controller, params) => {
  const actionHandlers: Partial<{ [K in ActionType["action"]]: Handler<Params<K>> }> = {
    [ACTIONS.ADD]: HandleAdd,
    [ACTIONS.CLEAR]: HandleClear,
    [ACTIONS.DELETE]: HandleDelete,
    [ACTIONS.GET_DATA]: HandleGetData,
    [ACTIONS.HANDLE_CHANGE]: HandleChange,
    [ACTIONS.PRINT]: HandlePrint,
    [ACTIONS.SAVE]: HandleSave,
    [ACTIONS.SAVE_TEMPLATE]: HandleSaveTemplate,
    [ACTIONS.SEARCH]: HandleSearch,
    [ACTIONS.SELECT]: HandleSelect,
    [ACTIONS.SELECT_EDIT]: HandleSelectEdit,
    [ACTIONS.TEMPLATE_FORM]: HandleTemplateForm,
    [ACTIONS.TEMPLATE_LIST]: HandleTemplateList,
    [ACTIONS.UPDATE]: HandleUpdate,
  };

  const { action } = params;

  const handler = actionHandlers[action];

  if (handler) {
    handler(controller, params as Params<typeof params.action>);
  } else {
    console.error(`No handler found for action: ${action}`);
  }
};

/** ====================================================  */
/**                        Handler                        */
/** ====================================================  */
const HandleSearch: Handler<Params<Actions["SEARCH"]>> = async (controller) => {
  const state = controller.getState();

  controller.setState({
    NursingDiagnosisSequence: {
      ...state.NursingDiagnosisSequence,
      nursingDiagnosisLoading: true,
    },
  });

  const nursingSearch = state.NursingDiagnosisSequence?.nursingDiagnosisSearch;

  const nursingDiagnosisList = await NursingDiagnosisResultGroupList.list({
    apiToken: controller.apiToken,
    params: {
      division: nursingSearch?.division,
      encounter: state.selectedEncounter.id,
      end_date: nursingSearch?.end_date || formatDate(moment()),
      start_date: nursingSearch?.start_date || formatDate(moment()),
    },
    extra: {
      division: controller.data.division,
    },
  });

  controller.setState({
    NursingDiagnosisSequence: {
      ...state.NursingDiagnosisSequence,
      nursingDiagnosisList: nursingDiagnosisList[0]?.items || [],
      nursingDiagnosisLoading: false,
    },
  });
};

const HandleAdd: Handler<Params<Actions["ADD"]>> = (controller) => {
  const state = controller.getState();

  controller.setState({
    NursingDiagnosisSequence: {
      ...state.NursingDiagnosisSequence,
      nursingDiagnosis: { ...INITIAL_NURSING_DIAGNOSIS },
    },
  });
};

const HandleSave: Handler<Params<Actions["SAVE"]>> = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SAVE]: BLC_STATUS.LOADING },
  });

  const nursing = state.NursingDiagnosisSequence?.nursingDiagnosis || {};

  const [, error] = await NursingDiagnosisResultList.create({
    apiToken: controller.apiToken,
    data: {
      diagnosis: nursing.diagnosis,
      encounter: state.selectedEncounter.id,
      end_date: nursing.end_date,
      goal: nursing.goal,
      group: nursing.group || null,
      plan: nursing.plan,
    },
  });

  if (error) {
    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SAVE]: BLC_STATUS.ERROR },
      errorMessage: { ...state.errorMessage, [params.sequence]: error },
    });

    return;
  }

  controller.setState(
    {
      NursingDiagnosisSequence: {
        ...state.NursingDiagnosisSequence,
        nursingDiagnosis: { ...INITIAL_NURSING_DIAGNOSIS },
      },
      buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SAVE]: BLC_STATUS.SUCCESS },
      successMessage: { ...state.successMessage, [params.sequence]: "บันทึกสำเร็จ" },
    },
    () => {
      Action(controller, { ...params, action: ACTIONS.GET_DATA });
    }
  );
};

const HandleUpdate: Handler<Params<Actions["UPDATE"]>> = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.UPDATE]: BLC_STATUS.LOADING },
  });

  const nursing = state.NursingDiagnosisSequence?.nursingDiagnosis;

  const [, error] = await NursingDiagnosisResultDetail.update({
    apiToken: controller.apiToken,
    data: {
      diagnosis: nursing?.diagnosis,
      encounter: state.selectedEncounter.id,
      end_date: nursing?.end_date,
      goal: nursing?.goal,
      group: nursing?.group || null,
      plan: nursing?.plan,
    },
    pk: nursing?.group,
  });

  if (error) {
    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.UPDATE]: BLC_STATUS.ERROR },
      errorMessage: { ...state.errorMessage, [params.sequence]: error },
    });

    return;
  }

  controller.setState(
    {
      NursingDiagnosisSequence: {
        ...state.NursingDiagnosisSequence,
        nursingDiagnosis: { ...INITIAL_NURSING_DIAGNOSIS },
      },
      buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.UPDATE]: BLC_STATUS.SUCCESS },
    },
    () => {
      Action(controller, { ...params, action: ACTIONS.GET_DATA });
    }
  );
};

const HandleSelectEdit: Handler<Params<Actions["SELECT_EDIT"]>> = (controller, params) => {
  const state = controller.getState();

  const { data } = params;

  controller.setState({
    NursingDiagnosisSequence: {
      ...state.NursingDiagnosisSequence,
      nursingDiagnosis: {
        diagnosis: data.diagnosis,
        end_date: data.end_date,
        goal: data.goal,
        group: data.id,
        plan: data.plan,
      },
    },
  });
};

const HandleClear: Handler<Params<Actions["CLEAR"]>> = (controller, params) => {
  const state = controller.getState();

  controller.setState({
    errorMessage: { ...state.errorMessage, [params.sequence]: null },
    successMessage: { ...state.successMessage, [params.sequence]: null },
  });
};

const HandleSelect: Handler<Params<Actions["SELECT"]>> = async (controller, params) => {
  const state = controller.getState();

  if (!params.item) {
    return;
  }

  const [response] = await NursingDiagnosisResultGroupDetail.retrieve({
    apiToken: controller.apiToken,
    pk: params.item?.id,
  });

  if (response) {
    controller.setState({
      NursingDiagnosisSequence: {
        ...state.NursingDiagnosisSequence,
        nursingDiagnosisSelected: response,
      },
      ...(params.card && {
        buttonLoadCheck: { ...state.buttonLoadCheck, [params.card]: BLC_STATUS.SUCCESS },
      }),
    });
  } else {
    controller.setState({
      NursingDiagnosisSequence: {
        ...state.NursingDiagnosisSequence,
        nursingDiagnosisSelected: [],
      },
      ...(params.card && {
        buttonLoadCheck: { ...state.buttonLoadCheck, [params.card]: BLC_STATUS.ERROR },
      }),
    });
  }
};

const HandleDelete: Handler<Params<Actions["DELETE"]>> = async (controller, params) => {
  const state = controller.getState();

  const btnKey = `${BTN_ACTS.DELETE}_${params.id}`;

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: BLC_STATUS.LOADING },
  });

  const [, error] = await NursingDiagnosisResultDetail.update({
    apiToken: controller.apiToken,
    data: {
      active: false,
    },
    pk: params.id,
  });

  if (error) {
    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: BLC_STATUS.ERROR },
      errorMessage: { ...state.errorMessage, [params.sequence]: error },
    });

    return;
  }

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: null },
  });

  Action(controller, { ...params, action: ACTIONS.GET_DATA });
};

const HandleTemplateList: Handler<Params<Actions["TEMPLATE_LIST"]>> = async (controller) => {
  const state = controller.getState();

  const nursingDiagnosistemplateList = await NursingDiagnosisTemplateList.list({
    apiToken: controller.apiToken,
    extra: {
      device: controller.data.device,
      division: controller.data.division,
    },
  });

  const items: any[] = nursingDiagnosistemplateList?.[0].items || [];

  const options = items.map((item) => ({
    key: item.id,
    text: item.name,
    value: item.id,
  }));

  controller.setState({
    NursingDiagnosisSequence: {
      ...state.NursingDiagnosisSequence,
      nursingDiagnosistemplateList: items,
      nursingDiagnosisTemplateOptions: options,
    },
  });
};

const HandleTemplateForm: Handler<Params<Actions["TEMPLATE_FORM"]>> = (controller) => {
  const state = controller.getState();

  let questions: any[] | undefined;

  const templateList = state.NursingDiagnosisSequence?.nursingDiagnosistemplateList || [];
  const templateID = state.NursingDiagnosisSequence?.nursingDiagnosis?.templateID;

  if (templateID) {
    const foundItem = templateList.find((item) => item.id === templateID);

    questions = foundItem?.questions;
  }

  controller.setState({
    NursingDiagnosisSequence: {
      ...state.NursingDiagnosisSequence,
      nursingDiagnosistemplateQuestionsList: questions || [],
    },
  });
};

const HandleSaveTemplate: Handler<Params<Actions["SAVE_TEMPLATE"]>> = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SAVE_TEMPLATE]: BLC_STATUS.LOADING },
  });

  const questions = state.NursingDiagnosisSequence?.nursingDiagnosistemplateQuestionsList || [];

  const questionsData = questions.map((data) => {
    const items = data.items.map((item: any) => ({
      column: item?.column || "",
      name: item?.name || "",
      type: item?.type || "",
      value: item?.value,
      // item?.type === "CHECKBOX" && item?.value === undefined
      //   ? false
      //   : item?.value === undefined
      //   ? ""
      //   : item?.value,
    }));

    return {
      active: true,
      end_date: data.end_date || "",
      items,
      name: data.name || "",
      template: data.template || "",
    };
  });

  const [response, error] = await NursingDiagnosisResulFromTemplate.create({
    apiToken: controller.apiToken,
    data: {
      // document: state.NursingDiagnosisSequence?.nursingDiagnosisSelected?.id,
      encounter: state.selectedEncounter.id,
      template: {
        questions: questionsData,
      },
    },
  });

  if (response) {
    controller.setState(
      {
        NursingDiagnosisSequence: {
          ...state.NursingDiagnosisSequence,
          nursingDiagnosis: { ...INITIAL_NURSING_DIAGNOSIS },
          nursingDiagnosistemplateQuestionsList: [],
        },
        buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SAVE_TEMPLATE]: BLC_STATUS.SUCCESS },
        successMessage: { ...state.successMessage, [params.sequence]: "บันทึกสำเร็จ" },
      },
      () => {
        Action(controller, { ...params, action: ACTIONS.GET_DATA });
      }
    );

    return;
  }

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SAVE_TEMPLATE]: BLC_STATUS.ERROR },
    errorMessage: { ...state.errorMessage, [params.sequence]: error },
  });
};

const HandleChange: Handler<Params<Actions["HANDLE_CHANGE"]>> = (controller, params) => {
  const state = controller.getState();

  const questions = state.NursingDiagnosisSequence?.nursingDiagnosistemplateQuestionsList;

  if (questions) {
    questions[params.pIndex].end_date = params.date;

    controller.setState({
      NursingDiagnosisSequence: {
        ...state.NursingDiagnosisSequence,
        nursingDiagnosistemplateQuestionsList: [...questions],
      },
    });
  } else {
    console.error("NursingDiagnosisSequence or nursingDiagnosistemplateQuestionsList is undefined");
  }
};

const HandlePrint: Handler<Params<Actions["PRINT"]>> = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.PRINT]: BLC_STATUS.LOADING },
  });

  const printResponse = await NursingDiagnosisPrint.update({
    apiToken: controller.apiToken,
    data: {
      diag_results: params.diagResults,
    } as any,
  });

  if (printResponse[0]) {
    const pdfWindow = window.open("_blank");

    if (pdfWindow) {
      pdfWindow.document.write(
        `<iframe width='100%' height='100%' src='data:application/pdf;base64, ${printResponse[0].pdf_b64data}'></iframe>`
      );
    }

    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.PRINT]: BLC_STATUS.SUCCESS },
    });

    return;
  }

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.PRINT]: BLC_STATUS.ERROR },
    errorMessage: { ...state.errorMessage, [params.sequence]: printResponse[1] },
  });
};

const HandleGetData: Handler<Params<Actions["GET_DATA"]>> = async (controller) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.GET_DATA]: BLC_STATUS.LOADING },
  });

  const nursingSearch = state.NursingDiagnosisSequence?.nursingDiagnosisSearch;

  const nursingDiagnosisList = await NursingDiagnosisResultGroupList.list({
    apiToken: controller.apiToken,
    params: {
      division: nursingSearch?.division,
      encounter: state.selectedEncounter.id,
      end_date: formatDate(moment()),
      // start_date: formatDate(moment()),
    },
    extra: {
      division: controller.data.division,
    },
  });

  controller.setState({
    NursingDiagnosisSequence: {
      ...state.NursingDiagnosisSequence,
      resultGroupList: nursingDiagnosisList[0]?.items || [],
    },
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.GET_DATA]: BLC_STATUS.SUCCESS },
  });
};
