import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  Button
} from 'semantic-ui-react'

const CardClinicAnnualReportUX = (props: any) => {
    return(
      <div
        style={{position: "relative", padding: "1em"}}>
        <div
          style={{display: "flex"}}>
          
          <label
            style={{fontWeight: "bold", fontSize: "large"}}>
            รายงานประจำคลินิก
          </label>
        </div>
        <hr>
        </hr>
        <div
          style={{display: "flex"}}>
          
          <Form>
            <FormGroup
              className="ui form"
              style={{padding: "1em 0em 1em 1em", gap: "5em"}}>
              <Button
                onClick={() => props.onHandleSelectedMenu("รายงานสรุปรายได้ และค่าตอบแทนแพทย์")}
                style={{width: "25em"}}>
                รายงานสรุปรายได้ และค่าตอบแทนแพทย์
              </Button>
              <Button
                onClick={() => props.onHandleSelectedMenu("รายงานสรุปรายได้คลินิก")}
                style={{width: "25em"}}>
                รายงานสรุปรายได้คลินิก
              </Button>
            </FormGroup>
            <FormGroup
              className="ui form"
              style={{padding: "1em 0em 1em 1em", gap: "5em"}}>
              <Button
                onClick={() => props.onHandleSelectedMenu("รายงานสรุปรายได้ทั้งเดือนส่งเบิกจ่ายแพทย์")}
                style={{width: "25em"}}>
                รายงานสรุปรายได้ทั้งเดือนส่งเบิกจ่ายแพทย์
              </Button>
              <Button
                onClick={() => props.onHandleSelectedMenu("รายงานผู้ป่วยค้างชำระ")}
                style={{width: "25em"}}>
                รายงานผู้ป่วยค้างชำระ
              </Button>
            </FormGroup>
            <FormGroup
              className="ui form"
              style={{padding: "1em 0em 1em 1em", gap: "5em"}}>
              <Button
                onClick={() => props.onHandleSelectedMenu("รายงานการแบ่งจ่ายค่าตอบแทนตามประเภทงาน")}
                style={{width: "25em"}}>
                รายงานการแบ่งจ่ายค่าตอบแทนตามประเภทงาน
              </Button>
              <Button
                onClick={() => props.onHandleSelectedMenu("รายงานผู้ป่วยที่ยังไม่ได้บันทึกรับชำระ")}
                style={{width: "25em"}}>
                รายงานผู้ป่วยที่ยังไม่ได้บันทึกรับชำระ
              </Button>
            </FormGroup>
            <FormGroup
              className="ui form"
              style={{padding: "1em 0em 1em 1em", gap: "5em"}}>
              <Button
                onClick={() => props.onHandleSelectedMenu("รายงานค่ารักษาที่แพทย์เปลี่ยนแปลงราคา")}
                style={{width: "25em"}}>
                รายงานค่ารักษาที่แพทย์เปลี่ยนแปลงราคา
              </Button>
              <Button
                onClick={() => props.onHandleSelectedMenu("รายงานผู้ป่วยที่ชำระค่าบริการ")}
                style={{width: "25em"}}>
                รายงานผู้ป่วยที่ชำระค่าบริการ
              </Button>
            </FormGroup>
            <FormGroup
              className="ui form"
              style={{padding: "1em 0em 1em 1em", gap: "5em"}}>
              <Button
                onClick={() => props.onHandleSelectedMenu("รายงานภาพรวมการเปลี่ยนแปลงราคาโดยแพทย์")}
                style={{width: "25em"}}>
                รายงานภาพรวมการเปลี่ยนแปลงราคาโดยแพทย์
              </Button>
            </FormGroup>
          </Form>
        </div>
      </div>
    )
}


export default CardClinicAnnualReportUX

export const screenPropsDefault = {}

/* Date Time : Fri Apr 05 2024 09:19:41 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{position: \"relative\", padding: \"1em\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "label",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "รายงานประจำคลินิก"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"large\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "Form",
      "parent": 20,
      "props": {
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormGroup",
      "parent": 4,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"1em 0em 1em 1em\", gap: \"5em\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormGroup",
      "parent": 4,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"1em 0em 1em 1em\", gap: \"5em\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormGroup",
      "parent": 4,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"1em 0em 1em 1em\", gap: \"5em\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "FormGroup",
      "parent": 4,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"1em 0em 1em 1em\", gap: \"5em\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "FormGroup",
      "parent": 4,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"1em 0em 1em 1em\", gap: \"5em\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "Button",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "รายงานสรุปรายได้ และค่าตอบแทนแพทย์"
        },
        "onClick": {
          "type": "code",
          "value": "() => props.onHandleSelectedMenu(\"รายงานสรุปรายได้ และค่าตอบแทนแพทย์\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"25em\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "Button",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "รายงานสรุปรายได้คลินิก"
        },
        "onClick": {
          "type": "code",
          "value": "() => props.onHandleSelectedMenu(\"รายงานสรุปรายได้คลินิก\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"25em\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "Button",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "รายงานสรุปรายได้ทั้งเดือนส่งเบิกจ่ายแพทย์"
        },
        "onClick": {
          "type": "code",
          "value": "() => props.onHandleSelectedMenu(\"รายงานสรุปรายได้ทั้งเดือนส่งเบิกจ่ายแพทย์\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"25em\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Button",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "รายงานผู้ป่วยค้างชำระ"
        },
        "onClick": {
          "type": "code",
          "value": "() => props.onHandleSelectedMenu(\"รายงานผู้ป่วยค้างชำระ\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"25em\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "Button",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "รายงานการแบ่งจ่ายค่าตอบแทนตามประเภทงาน"
        },
        "onClick": {
          "type": "code",
          "value": "() => props.onHandleSelectedMenu(\"รายงานการแบ่งจ่ายค่าตอบแทนตามประเภทงาน\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"25em\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "Button",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "รายงานผู้ป่วยที่ยังไม่ได้บันทึกรับชำระ"
        },
        "onClick": {
          "type": "code",
          "value": "() => props.onHandleSelectedMenu(\"รายงานผู้ป่วยที่ยังไม่ได้บันทึกรับชำระ\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"25em\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "Button",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "รายงานค่ารักษาที่แพทย์เปลี่ยนแปลงราคา"
        },
        "onClick": {
          "type": "code",
          "value": "() => props.onHandleSelectedMenu(\"รายงานค่ารักษาที่แพทย์เปลี่ยนแปลงราคา\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"25em\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "Button",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "รายงานผู้ป่วยที่ชำระค่าบริการ"
        },
        "onClick": {
          "type": "code",
          "value": "() => props.onHandleSelectedMenu(\"รายงานผู้ป่วยที่ชำระค่าบริการ\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"25em\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "Button",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "รายงานภาพรวมการเปลี่ยนแปลงราคาโดยแพทย์"
        },
        "onClick": {
          "type": "code",
          "value": "() => props.onHandleSelectedMenu(\"รายงานภาพรวมการเปลี่ยนแปลงราคาโดยแพทย์\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"25em\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "hr",
      "parent": 0,
      "props": {
      },
      "seq": 20,
      "void": true
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardClinicAnnualReportUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
