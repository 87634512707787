import React from "react";
import {
  Checkbox,
  Divider,
  Dropdown,
  Form,
  Grid,
  Input,
  Segment,
  TextArea,
} from "semantic-ui-react";
import RightLabel from "../common/RightLabel";
import { useIntl } from "react-intl";

export interface OTPhysicalExaminationCerebralPalsy1ViewDataType {
  OtPhysicalCerebral12: boolean;
  OtPhysicalCerebral12Disabled: boolean;
  OtPhysicalCerebral13: boolean;
  OtPhysicalCerebral13Disabled: boolean;
  OtPhysicalCerebral15: boolean;
  OtPhysicalCerebral15Disabled: boolean;
  OtPhysicalCerebral16: boolean;
  OtPhysicalCerebral16Disabled: boolean;
  OtPhysicalCerebral18: boolean;
  OtPhysicalCerebral18Disabled: boolean;
  OtPhysicalCerebral110: boolean;
  OtPhysicalCerebral110Disabled: boolean;
  OtPhysicalCerebral112: boolean;
  OtPhysicalCerebral112Disabled: boolean;
  OtPhysicalCerebral114: boolean;
  OtPhysicalCerebral114Disabled: boolean;
  OtPhysicalCerebral121: boolean;
  OtPhysicalCerebral121Disabled: boolean;
  OtPhysicalCerebral122: boolean;
  OtPhysicalCerebral122Disabled: boolean;
  OtPhysicalCerebral123: boolean;
  OtPhysicalCerebral123Disabled: boolean;
  OtPhysicalCerebral124: boolean;
  OtPhysicalCerebral124Disabled: boolean;
  OtPhysicalCerebral128: boolean;
  OtPhysicalCerebral128Disabled: boolean;
  OtPhysicalCerebral129: string;
  OtPhysicalCerebral129Disabled: boolean;
  OtPhysicalCerebral131: boolean;
  OtPhysicalCerebral131Disabled: boolean;
  OtPhysicalCerebral132: boolean;
  OtPhysicalCerebral132Disabled: boolean;
  OtPhysicalCerebral137: string;
  OtPhysicalCerebral137Disabled: boolean;
  OtPhysicalCerebral139: string;
  OtPhysicalCerebral139Disabled: boolean;
  Ct7GeneralAppearance: string;
  Ct7GeneralAppearanceDisabled: boolean;
  Ct7GeneralAppearanceOptions: any;
  Ct3Communication: string;
  Ct3CommunicationDisabled: boolean;
  Ct3CommunicationOptions: any;
  Ct3CoOperation: string;
  Ct3CoOperationDisabled: boolean;
  Ct3CoOperationOptions: any;
  Ct2Cognitive: string;
  Ct2CognitiveDisabled: boolean;
  Ct2CognitiveOptions: any;
  CtAttention: string;
  CtAttentionDisabled: boolean;
  CtAttentionOptions: any;
  CtPathology: string;
  CtPathologyDisabled: boolean;
  CtPathologyOptions: any;
  Ct2LevelOfArousal: string;
  Ct2LevelOfArousalDisabled: boolean;
  Ct2LevelOfArousalOptions: any;
  OtPhysicalCerebral168: string;
  OtPhysicalCerebral168Disabled: boolean;
  OtPhysicalCerebral169: boolean;
  OtPhysicalCerebral169Disabled: boolean;
  OtPhysicalCerebral170: string;
  OtPhysicalCerebral170Disabled: boolean;
  OtPhysicalCerebral172: boolean;
  OtPhysicalCerebral172Disabled: boolean;
  OtPhysicalCerebral173: string;
  OtPhysicalCerebral173Disabled: boolean;
  OtPhysicalCerebral175: boolean;
  OtPhysicalCerebral175Disabled: boolean;
  OtPhysicalCerebral176: string;
  OtPhysicalCerebral176Disabled: boolean;
  OtPhysicalCerebral178: boolean;
  OtPhysicalCerebral178Disabled: boolean;
  OtPhysicalCerebral180: boolean;
  OtPhysicalCerebral180Disabled: boolean;
  OtPhysicalCerebral183: string;
  OtPhysicalCerebral183Disabled: boolean;
  OtPhysicalCerebral184: boolean;
  OtPhysicalCerebral184Disabled: boolean;
  OtPhysicalCerebral185: string;
  OtPhysicalCerebral185Disabled: boolean;
  OtPhysicalCerebral187: boolean;
  OtPhysicalCerebral187Disabled: boolean;
  OtPhysicalCerebral188: string;
  OtPhysicalCerebral188Disabled: boolean;
  OtPhysicalCerebral190: boolean;
  OtPhysicalCerebral190Disabled: boolean;
  OtPhysicalCerebral192: boolean;
  OtPhysicalCerebral192Disabled: boolean;
  OtPhysicalCerebral195: boolean;
  OtPhysicalCerebral195Disabled: boolean;
  OtPhysicalCerebral197: boolean;
  OtPhysicalCerebral197Disabled: boolean;
  OtPhysicalCerebral1102: string;
  OtPhysicalCerebral1102Disabled: boolean;
  OtPhysicalCerebral1104: string;
  OtPhysicalCerebral1104Disabled: boolean;
  OtPhysicalCerebral1106: string;
  OtPhysicalCerebral1106Disabled: boolean;
  OtPhysicalCerebral1109: string;
  OtPhysicalCerebral1109Disabled: boolean;
  CtContractures: string;
  CtContracturesDisabled: boolean;
  CtContracturesOptions: any;
  OtPhysicalCerebral1114: string;
  OtPhysicalCerebral1114Disabled: boolean;
  OtPhysicalCerebral1116: string;
  OtPhysicalCerebral1116Disabled: boolean;
  OtPhysicalCerebral1117: boolean;
  OtPhysicalCerebral1117Disabled: boolean;
  OtPhysicalCerebral1119: boolean;
  OtPhysicalCerebral1119Disabled: boolean;
  OtPhysicalCerebral1121Disabled: boolean;
  Ct2Sensory: string;
  Ct2SensoryDisabled: boolean;
  Ct2SensoryOptions: any;
  OtPhysicalCerebral1127: string;
  OtPhysicalCerebral1127Disabled: boolean;
}

export const OTPhysicalExaminationCerebralPalsy1InitialViewData: OTPhysicalExaminationCerebralPalsy1ViewDataType = {
  OtPhysicalCerebral12: false,
  OtPhysicalCerebral12Disabled: false,
  OtPhysicalCerebral13: false,
  OtPhysicalCerebral13Disabled: false,
  OtPhysicalCerebral15: false,
  OtPhysicalCerebral15Disabled: false,
  OtPhysicalCerebral16: false,
  OtPhysicalCerebral16Disabled: false,
  OtPhysicalCerebral18: false,
  OtPhysicalCerebral18Disabled: false,
  OtPhysicalCerebral110: false,
  OtPhysicalCerebral110Disabled: false,
  OtPhysicalCerebral112: false,
  OtPhysicalCerebral112Disabled: false,
  OtPhysicalCerebral114: false,
  OtPhysicalCerebral114Disabled: false,
  OtPhysicalCerebral121: false,
  OtPhysicalCerebral121Disabled: false,
  OtPhysicalCerebral122: false,
  OtPhysicalCerebral122Disabled: false,
  OtPhysicalCerebral123: false,
  OtPhysicalCerebral123Disabled: false,
  OtPhysicalCerebral124: false,
  OtPhysicalCerebral124Disabled: false,
  OtPhysicalCerebral128: false,
  OtPhysicalCerebral128Disabled: false,
  OtPhysicalCerebral129: "",
  OtPhysicalCerebral129Disabled: false,
  OtPhysicalCerebral131: false,
  OtPhysicalCerebral131Disabled: false,
  OtPhysicalCerebral132: false,
  OtPhysicalCerebral132Disabled: false,
  OtPhysicalCerebral137: "",
  OtPhysicalCerebral137Disabled: false,
  OtPhysicalCerebral139: "",
  OtPhysicalCerebral139Disabled: false,
  Ct7GeneralAppearance: "",
  Ct7GeneralAppearanceDisabled: false,
  Ct7GeneralAppearanceOptions: [{ key: 0, value: "A", text: "A" }],
  Ct3Communication: "",
  Ct3CommunicationDisabled: false,
  Ct3CommunicationOptions: [{ key: 0, value: "A", text: "A" }],
  Ct3CoOperation: "",
  Ct3CoOperationDisabled: false,
  Ct3CoOperationOptions: [{ key: 0, value: "A", text: "A" }],
  Ct2Cognitive: "",
  Ct2CognitiveDisabled: false,
  Ct2CognitiveOptions: [{ key: 0, value: "A", text: "A" }],
  CtAttention: "",
  CtAttentionDisabled: false,
  CtAttentionOptions: [{ key: 0, value: "A", text: "A" }],
  CtPathology: "",
  CtPathologyDisabled: false,
  CtPathologyOptions: [{ key: 0, value: "A", text: "A" }],
  Ct2LevelOfArousal: "",
  Ct2LevelOfArousalDisabled: false,
  Ct2LevelOfArousalOptions: [{ key: 0, value: "A", text: "A" }],
  OtPhysicalCerebral168: "",
  OtPhysicalCerebral168Disabled: false,
  OtPhysicalCerebral169: false,
  OtPhysicalCerebral169Disabled: false,
  OtPhysicalCerebral170: "",
  OtPhysicalCerebral170Disabled: false,
  OtPhysicalCerebral172: false,
  OtPhysicalCerebral172Disabled: false,
  OtPhysicalCerebral173: "",
  OtPhysicalCerebral173Disabled: false,
  OtPhysicalCerebral175: false,
  OtPhysicalCerebral175Disabled: false,
  OtPhysicalCerebral176: "",
  OtPhysicalCerebral176Disabled: false,
  OtPhysicalCerebral178: false,
  OtPhysicalCerebral178Disabled: false,
  OtPhysicalCerebral180: false,
  OtPhysicalCerebral180Disabled: false,
  OtPhysicalCerebral183: "",
  OtPhysicalCerebral183Disabled: false,
  OtPhysicalCerebral184: false,
  OtPhysicalCerebral184Disabled: false,
  OtPhysicalCerebral185: "",
  OtPhysicalCerebral185Disabled: false,
  OtPhysicalCerebral187: false,
  OtPhysicalCerebral187Disabled: false,
  OtPhysicalCerebral188: "",
  OtPhysicalCerebral188Disabled: false,
  OtPhysicalCerebral190: false,
  OtPhysicalCerebral190Disabled: false,
  OtPhysicalCerebral192: false,
  OtPhysicalCerebral192Disabled: false,
  OtPhysicalCerebral195: false,
  OtPhysicalCerebral195Disabled: false,
  OtPhysicalCerebral197: false,
  OtPhysicalCerebral197Disabled: false,
  OtPhysicalCerebral1102: "",
  OtPhysicalCerebral1102Disabled: false,
  OtPhysicalCerebral1104: "",
  OtPhysicalCerebral1104Disabled: false,
  OtPhysicalCerebral1106: "",
  OtPhysicalCerebral1106Disabled: false,
  OtPhysicalCerebral1109: "",
  OtPhysicalCerebral1109Disabled: false,
  CtContractures: "",
  CtContracturesDisabled: false,
  CtContracturesOptions: [{ key: 0, value: "A", text: "A" }],
  OtPhysicalCerebral1114: "",
  OtPhysicalCerebral1114Disabled: false,
  OtPhysicalCerebral1116: "",
  OtPhysicalCerebral1116Disabled: false,
  OtPhysicalCerebral1117: false,
  OtPhysicalCerebral1117Disabled: false,
  OtPhysicalCerebral1119: false,
  OtPhysicalCerebral1119Disabled: false,
  OtPhysicalCerebral1121Disabled: false,
  Ct2Sensory: "",
  Ct2SensoryDisabled: false,
  Ct2SensoryOptions: [{ key: 0, value: "A", text: "A" }],
  OtPhysicalCerebral1127: "",
  OtPhysicalCerebral1127Disabled: false,
};

const styles = {
  segmentBg: {
    backgroundColor: "#F3F3F3",
    padding: "15px 0px 0px 15px",
  },
  rightLabel: {
    fontSize: "1rem",
    fontWeight: "normal",
  },
  noPadding: {
    padding: "0px",
  },
  columnBg: {
    backgroundColor: "#F3F3F3",
    width: "100%",
  },
  leftColumnPadding: {
    padding: "10px 10px 0px 30px",
  },
  rightColumnPadding: {
    padding: "10px 30px 0px 10px",
  },
};

const Sub = (props: any) => {
  return (
    <>
      <Segment basic style={styles.segmentBg}>
        <Form>
          <Form.Group inline>
            <Form.Field width={2}><strong>Observation</strong></Form.Field>
            <Form.Field width={2}>
              <Checkbox 
                style={{fontWeight: "normal"}} 
                label="Normal"
                checked={props.viewData.OtPhysicalCerebral12}
                disabled={props.viewData.OtPhysicalCerebral12Disabled}
                onChange={() => {
                  props.onEvent({
                    message: "check",
                    params: {
                      view: "OTPhysicalExaminationCerebralPalsy1",
                      name: "OtPhysicalCerebral12",
                    },
                  });
                }}
              />
            </Form.Field>
            <Form.Field width={2}>
              <Checkbox 
                style={{fontWeight: "normal"}} 
                label="Wheelchair"
                checked={props.viewData.OtPhysicalCerebral13}
                disabled={props.viewData.OtPhysicalCerebral13Disabled}
                onChange={() => {
                  props.onEvent({
                    message: "check",
                    params: {
                      view: "OTPhysicalExaminationCerebralPalsy1",
                      name: "OtPhysicalCerebral13",
                    },
                  });
                }}
              />
            </Form.Field>
            <Form.Field width={2}>
              <Checkbox 
                  style={{fontWeight: "normal"}} 
                label="Stroller"
                checked={props.viewData.OtPhysicalCerebral15}
                disabled={props.viewData.OtPhysicalCerebral15Disabled}
                onChange={() => {
                  props.onEvent({
                    message: "check",
                    params: {
                      view: "OTPhysicalExaminationCerebralPalsy1",
                      name: "OtPhysicalCerebral15",
                    },
                  });
                }}
              />
            </Form.Field>
            <Form.Field width={2}>
              <Checkbox 
                style={{fontWeight: "normal"}} 
                label="Cane/walker"
                checked={props.viewData.OtPhysicalCerebral16}
                disabled={props.viewData.OtPhysicalCerebral16Disabled}
                onChange={() => {
                  props.onEvent({
                    message: "check",
                    params: {
                      view: "OTPhysicalExaminationCerebralPalsy1",
                      name: "OtPhysicalCerebral16",
                    },
                  });
                }}
              />
            </Form.Field>
            <Form.Field width={2}>
              <Checkbox 
                style={{fontWeight: "normal"}} 
                label="Arm sling"
                checked={props.viewData.OtPhysicalCerebral18}
                disabled={props.viewData.OtPhysicalCerebral18Disabled}
                onChange={() => {
                  props.onEvent({
                    message: "check",
                    params: {
                      view: "OTPhysicalExaminationCerebralPalsy1",
                      name: "OtPhysicalCerebral18",
                    },
                  });
                }}
              />
            </Form.Field>
            <Form.Field width={2}>
              <Checkbox 
                style={{fontWeight: "normal"}} 
                label="On Ventilator"
                checked={props.viewData.OtPhysicalCerebral110}
                disabled={props.viewData.OtPhysicalCerebral110Disabled}
                onChange={() => {
                  props.onEvent({
                    message: "check",
                    params: {
                      view: "OTPhysicalExaminationCerebralPalsy1",
                      name: "OtPhysicalCerebral110",
                    },
                  });
                }}
              />
            </Form.Field>
            <Form.Field width={1}>
              <Checkbox 
                style={{fontWeight: "normal"}} 
                label="On ET"
                checked={props.viewData.OtPhysicalCerebral112}
                disabled={props.viewData.OtPhysicalCerebral112Disabled}
                onChange={() => {
                  props.onEvent({
                    message: "check",
                    params: {
                      view: "OTPhysicalExaminationCerebralPalsy1",
                      name: "OtPhysicalCerebral112",
                    },
                  });
                }}
              />
            </Form.Field>
            <Form.Field width={1}>
              <Checkbox 
                style={{fontWeight: "normal"}} 
                label="TT tube"
                checked={props.viewData.OtPhysicalCerebral114}
                disabled={props.viewData.OtPhysicalCerebral114Disabled}
                onChange={() => {
                  props.onEvent({
                    message: "check",
                    params: {
                      view: "OTPhysicalExaminationCerebralPalsy1",
                      name: "OtPhysicalCerebral114",
                    },
                  });
                }}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group inline>
            <Form.Field width={2}></Form.Field>
            <Form.Field width={2}>
              <Checkbox 
                style={{fontWeight: "normal"}} 
                label="On IV fluid"
                checked={props.viewData.OtPhysicalCerebral122}
                disabled={props.viewData.OtPhysicalCerebral122Disabled}
                onChange={() => {
                  props.onEvent({
                    message: "check",
                    params: {
                      view: "OTPhysicalExaminationCerebralPalsy1",
                      name: "OtPhysicalCerebral122",
                    },
                  });
                }}
              />
            </Form.Field>
            <Form.Field width={2}>
              <Checkbox 
                style={{fontWeight: "normal"}} 
                label="On Foley's catheter"
                checked={props.viewData.OtPhysicalCerebral121}
                disabled={props.viewData.OtPhysicalCerebral121Disabled}
                onChange={() => {
                  props.onEvent({
                    message: "check",
                    params: {
                      view: "OTPhysicalExaminationCerebralPalsy1",
                      name: "OtPhysicalCerebral121",
                    },
                  });
                }}
              />
            </Form.Field>
            <Form.Field width={2}>
              <Checkbox 
                style={{fontWeight: "normal"}} 
                label="On ICD drainage"
                checked={props.viewData.OtPhysicalCerebral123}
                disabled={props.viewData.OtPhysicalCerebral123Disabled}
                onChange={() => {
                  props.onEvent({
                    message: "check",
                    params: {
                      view: "OTPhysicalExaminationCerebralPalsy1",
                      name: "OtPhysicalCerebral123",
                    },
                  });
                }}
              />
            </Form.Field>
            <Form.Field width={2}>
              <Checkbox 
                style={{fontWeight: "normal"}} 
                label="NG Tube"
                checked={props.viewData.OtPhysicalCerebral124}
                disabled={props.viewData.OtPhysicalCerebral124Disabled}
                onChange={() => {
                  props.onEvent({
                    message: "check",
                    params: {
                      view: "OTPhysicalExaminationCerebralPalsy1",
                      name: "OtPhysicalCerebral124",
                    },
                  });
                }}
              />
            </Form.Field>
            <Form.Field width={2}>
              <Checkbox 
                style={{fontWeight: "normal"}} 
                label="OG Tube"
                checked={props.viewData.OtPhysicalCerebral128}
                disabled={props.viewData.OtPhysicalCerebral128Disabled}
                onChange={() => {
                  props.onEvent({
                    message: "check",
                    params: {
                      view: "OTPhysicalExaminationCerebralPalsy1",
                      name: "OtPhysicalCerebral128",
                    },
                  });
                }}
              />
            </Form.Field>
            <Form.Field width={2}>
              <Checkbox 
                style={{fontWeight: "normal"}} 
                label="PEG"
                checked={props.viewData.OtPhysicalCerebral131}
                disabled={props.viewData.OtPhysicalCerebral131Disabled}
                onChange={() => {
                  props.onEvent({
                    message: "check",
                    params: {
                      view: "OTPhysicalExaminationCerebralPalsy1",
                      name: "OtPhysicalCerebral131",
                    },
                  });
                }}
              />
            </Form.Field>
            <Form.Field width={1}>
              <Checkbox 
                style={{fontWeight: "normal"}} 
                label="Other"
                checked={props.viewData.OtPhysicalCerebral132}
                disabled={props.viewData.OtPhysicalCerebral132Disabled}
                onChange={() => {
                  props.onEvent({
                    message: "check",
                    params: {
                      view: "OTPhysicalExaminationCerebralPalsy1",
                      name: "OtPhysicalCerebral132",
                    },
                  });
                }}
              />
            </Form.Field>
            <Form.Field width={1}>
              <Input
                value={props.viewData.OtPhysicalCerebral129}
                disabled={props.viewData.OtPhysicalCerebral129Disabled}
                onChange={(e) => {
                  props.onEvent({
                    message: "inputChange",
                    params: {
                      view: "OTPhysicalExaminationCerebralPalsy1",
                      name: "OtPhysicalCerebral129",
                      value: e.target.value,
                    },
                  });
                }}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group inline>
            <Form.Field width={2}><strong>Behavior</strong></Form.Field>
            <Form.Field width={14}>
              <TextArea
                rows={3}
                value={props.viewData.OtPhysicalCerebral137}
                disabled={props.viewData.OtPhysicalCerebral137Disabled}
                onChange={(e) => {
                  props.onEvent({
                    message: "inputChange",
                    params: {
                      view: "OTPhysicalExaminationCerebralPalsy1",
                      name: "OtPhysicalCerebral137",
                      value: e.target.value,
                    },
                  });
                }}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group inline>
            <Form.Field width={2}><strong>General appearance</strong></Form.Field>
            <Form.Field width={12}>
              <Input
                value={props.viewData.OtPhysicalCerebral139}
                disabled={props.viewData.OtPhysicalCerebral139Disabled}
                onChange={(e) => {
                  props.onEvent({
                    message: "inputChange",
                    params: {
                      view: "OTPhysicalExaminationCerebralPalsy1",
                      name: "OtPhysicalCerebral139",
                      value: e.target.value,
                    },
                  });
                }}
              />
            </Form.Field>
            <Form.Field width={2}>
              <Dropdown
                selection
                className="fluidDropdown"
                value={props.viewData.Ct7GeneralAppearance}
                disabled={props.viewData.Ct7GeneralAppearanceDisabled}
                onChange={(e, data) => {
                  props.onEvent({
                    message: "choose",
                    params: {
                      view: "OTPhysicalExaminationCerebralPalsy1",
                      name: "Ct7GeneralAppearance",
                      value: data.value,
                    },
                  });
                }}
                options={props.viewData.Ct7GeneralAppearanceOptions}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group inline>
            <Form.Field width={2}><strong>Communication</strong></Form.Field>
            <Form.Field width={2}>
              <Dropdown
                selection
                className="fluidDropdown"
                value={props.viewData.Ct3Communication}
                disabled={props.viewData.Ct3CommunicationDisabled}
                onChange={(e, data) => {
                  props.onEvent({
                    message: "choose",
                    params: {
                      view: "OTPhysicalExaminationCerebralPalsy1",
                      name: "Ct3Communication",
                      value: data.value,
                    },
                  });
                }}
                options={props.viewData.Ct3CommunicationOptions}
              />
            </Form.Field>
            <Form.Field width={2}>
              <RightLabel style={styles.rightLabel}>Co-operation</RightLabel>
            </Form.Field>
            <Form.Field width={2}>
              <Dropdown
                selection
                className="fluidDropdown"
                value={props.viewData.Ct3CoOperation}
                disabled={props.viewData.Ct3CoOperationDisabled}
                onChange={(e, data) => {
                  props.onEvent({
                    message: "choose",
                    params: {
                      view: "OTPhysicalExaminationCerebralPalsy1",
                      name: "Ct3CoOperation",
                      value: data.value,
                    },
                  });
                }}
                options={props.viewData.Ct3CoOperationOptions}
              />
            </Form.Field>
            <Form.Field width={2}>
              <RightLabel style={styles.rightLabel}>Cognitive</RightLabel>
            </Form.Field>
            <Form.Field width={2}>
              <Dropdown
                selection
                className="fluidDropdown"
                value={props.viewData.Ct2Cognitive}
                disabled={props.viewData.Ct2CognitiveDisabled}
                onChange={(e, data) => {
                  props.onEvent({
                    message: "choose",
                    params: {
                      view: "OTPhysicalExaminationCerebralPalsy1",
                      name: "Ct2Cognitive",
                      value: data.value,
                    },
                  });
                }}
                options={props.viewData.Ct2CognitiveOptions}
              />
            </Form.Field>
            <Form.Field width={2}>
              <RightLabel style={styles.rightLabel}>Attention</RightLabel>
            </Form.Field>
            <Form.Field width={2}>
              <Dropdown
                selection
                className="fluidDropdown"
                value={props.viewData.CtAttention}
                disabled={props.viewData.CtAttentionDisabled}
                onChange={(e, data) => {
                  props.onEvent({
                    message: "choose",
                    params: {
                      view: "OTPhysicalExaminationCerebralPalsy1",
                      name: "CtAttention",
                      value: data.value,
                    },
                  });
                }}
                options={props.viewData.CtAttentionOptions}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group inline>
            {/* <Form.Field width={2}><strong>Pathology</strong></Form.Field>
            <Form.Field width={2}>
              <Dropdown
                selection
                className="fluidDropdown"
                value={props.viewData.CtPathology}
                disabled={props.viewData.CtPathologyDisabled}
                onChange={(e, data) => {
                  props.onEvent({
                    message: "choose",
                    params: {
                      view: "OTPhysicalExaminationCerebralPalsy1",
                      name: "CtPathology",
                      value: data.value,
                    },
                  });
                }}
                options={props.viewData.CtPathologyOptions}
              />
            </Form.Field> */}
            <Form.Field width={2}> <strong> Level of arousal </strong> </Form.Field>
            <Form.Field width={2}>
              <Dropdown
                selection
                className="fluidDropdown"
                value={props.viewData.Ct2LevelOfArousal}
                disabled={props.viewData.Ct2LevelOfArousalDisabled}
                onChange={(e, data) => {
                  props.onEvent({
                    message: "choose",
                    params: {
                      view: "OTPhysicalExaminationCerebralPalsy1",
                      name: "Ct2LevelOfArousal",
                      value: data.value,
                    },
                  });
                }}
                options={props.viewData.Ct2LevelOfArousalOptions}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group inline>
            <Form.Field width={2}><strong>Muscle length</strong></Form.Field>
            <Form.Field width={1}>
              <Checkbox 
                style={{fontWeight: "normal"}} 
                label="Normal"
                checked={props.viewData.OtPhysicalCerebral169}
                disabled={props.viewData.OtPhysicalCerebral169Disabled}
                onChange={() => {
                  props.onEvent({
                    message: "check",
                    params: {
                      view: "OTPhysicalExaminationCerebralPalsy1",
                      name: "OtPhysicalCerebral169",
                    },
                  });
                }}
              />
            </Form.Field>
            <Form.Field width={2}>
              <Input
                value={props.viewData.OtPhysicalCerebral168}
                disabled={props.viewData.OtPhysicalCerebral168Disabled}
                onChange={(e) => {
                  props.onEvent({
                    message: "inputChange",
                    params: {
                      view: "OTPhysicalExaminationCerebralPalsy1",
                      name: "OtPhysicalCerebral168",
                      value: e.target.value,
                    },
                  });
                }}
              />
            </Form.Field>
            <Form.Field width={1}>
              <Checkbox 
                style={{fontWeight: "normal"}} 
                label="Tightness"
                checked={props.viewData.OtPhysicalCerebral172}
                disabled={props.viewData.OtPhysicalCerebral172Disabled}
                onChange={() => {
                  props.onEvent({
                    message: "check",
                    params: {
                      view: "OTPhysicalExaminationCerebralPalsy1",
                      name: "OtPhysicalCerebral172",
                    },
                  });
                }}
              />
            </Form.Field>
            <Form.Field width={2}>
              <Input
                value={props.viewData.OtPhysicalCerebral170}
                disabled={props.viewData.OtPhysicalCerebral170Disabled}
                onChange={(e) => {
                  props.onEvent({
                    message: "inputChange",
                    params: {
                      view: "OTPhysicalExaminationCerebralPalsy1",
                      name: "OtPhysicalCerebral170",
                      value: e.target.value,
                    },
                  });
                }}
              />
            </Form.Field>
            <Form.Field width={1}>
              <Checkbox 
                style={{fontWeight: "normal"}} 
                label="Shortening"
                checked={props.viewData.OtPhysicalCerebral175}
                disabled={props.viewData.OtPhysicalCerebral175Disabled}
                onChange={() => {
                  props.onEvent({
                    message: "check",
                    params: {
                      view: "OTPhysicalExaminationCerebralPalsy1",
                      name: "OtPhysicalCerebral175",
                    },
                  });
                }}
              />
            </Form.Field>
            <Form.Field width={2}>
              <Input
                value={props.viewData.OtPhysicalCerebral173}
                disabled={props.viewData.OtPhysicalCerebral173Disabled}
                onChange={(e) => {
                  props.onEvent({
                    message: "inputChange",
                    params: {
                      view: "OTPhysicalExaminationCerebralPalsy1",
                      name: "OtPhysicalCerebral173",
                      value: e.target.value,
                    },
                  });
                }}
              />
            </Form.Field>
            <Form.Field width={2}>
              <Checkbox 
                style={{fontWeight: "normal"}} 
                label="Contracture"
                checked={props.viewData.OtPhysicalCerebral178}
                disabled={props.viewData.OtPhysicalCerebral178Disabled}
                onChange={() => {
                  props.onEvent({
                    message: "check",
                    params: {
                      view: "OTPhysicalExaminationCerebralPalsy1",
                      name: "OtPhysicalCerebral178",
                    },
                  });
                }}
              />
            </Form.Field>
            <Form.Field width={2}>
              <Input
                value={props.viewData.OtPhysicalCerebral176}
                disabled={props.viewData.OtPhysicalCerebral176Disabled}
                onChange={(e) => {
                  props.onEvent({
                    message: "inputChange",
                    params: {
                      view: "OTPhysicalExaminationCerebralPalsy1",
                      name: "OtPhysicalCerebral176",
                      value: e.target.value,
                    },
                  });
                }}
              />
            </Form.Field>
            <Form.Field width={1}>
              <Checkbox 
                style={{fontWeight: "normal"}} 
                label="N/A"
                checked={props.viewData.OtPhysicalCerebral180}
                disabled={props.viewData.OtPhysicalCerebral180Disabled}
                onChange={() => {
                  props.onEvent({
                    message: "check",
                    params: {
                      view: "OTPhysicalExaminationCerebralPalsy1",
                      name: "OtPhysicalCerebral180",
                    },
                  });
                }}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group inline>
            <Form.Field width={2}><strong>Muscle tone</strong></Form.Field>
            <Form.Field width={1}>
              <Checkbox 
                style={{fontWeight: "normal"}} 
                label="Normal"
                checked={props.viewData.OtPhysicalCerebral184}
                disabled={props.viewData.OtPhysicalCerebral184Disabled}
                onChange={() => {
                  props.onEvent({
                    message: "check",
                    params: {
                      view: "OTPhysicalExaminationCerebralPalsy1",
                      name: "OtPhysicalCerebral184",
                    },
                  });
                }}
              />
            </Form.Field>
            <Form.Field width={2}>
              <Input
                value={props.viewData.OtPhysicalCerebral183}
                disabled={props.viewData.OtPhysicalCerebral183Disabled}
                onChange={(e) => {
                  props.onEvent({
                    message: "inputChange",
                    params: {
                      view: "OTPhysicalExaminationCerebralPalsy1",
                      name: "OtPhysicalCerebral183",
                      value: e.target.value,
                    },
                  });
                }}
              />
            </Form.Field>
            <Form.Field width={1}>
              <Checkbox 
                style={{fontWeight: "normal"}} 
                label="Spasticity"
                checked={props.viewData.OtPhysicalCerebral187}
                disabled={props.viewData.OtPhysicalCerebral187Disabled}
                onChange={() => {
                  props.onEvent({
                    message: "check",
                    params: {
                      view: "OTPhysicalExaminationCerebralPalsy1",
                      name: "OtPhysicalCerebral187",
                    },
                  });
                }}
              />
            </Form.Field>
            <Form.Field width={2}>
              <Input
                value={props.viewData.OtPhysicalCerebral185}
                disabled={props.viewData.OtPhysicalCerebral185Disabled}
                onChange={(e) => {
                  props.onEvent({
                    message: "inputChange",
                    params: {
                      view: "OTPhysicalExaminationCerebralPalsy1",
                      name: "OtPhysicalCerebral185",
                      value: e.target.value,
                    },
                  });
                }}
              />
            </Form.Field>
            <Form.Field width={1}>
              <Checkbox 
                style={{fontWeight: "normal"}} 
                label="Flaccid"
                checked={props.viewData.OtPhysicalCerebral190}
                disabled={props.viewData.OtPhysicalCerebral190Disabled}
                onChange={() => {
                  props.onEvent({
                    message: "check",
                    params: {
                      view: "OTPhysicalExaminationCerebralPalsy1",
                      name: "OtPhysicalCerebral190",
                    },
                  });
                }}
              />
            </Form.Field>{" "}
            <Form.Field width={2}>
              <Input
                value={props.viewData.OtPhysicalCerebral188}
                disabled={props.viewData.OtPhysicalCerebral188Disabled}
                onChange={(e) => {
                  props.onEvent({
                    message: "inputChange",
                    params: {
                      view: "OTPhysicalExaminationCerebralPalsy1",
                      name: "OtPhysicalCerebral188",
                      value: e.target.value,
                    },
                  });
                }}
              />
            </Form.Field>
            <Form.Field width={1}>
              <Checkbox 
                style={{fontWeight: "normal"}} 
                label="N/A"
                checked={props.viewData.OtPhysicalCerebral192}
                disabled={props.viewData.OtPhysicalCerebral192Disabled}
                onChange={() => {
                  props.onEvent({
                    message: "check",
                    params: {
                      view: "OTPhysicalExaminationCerebralPalsy1",
                      name: "OtPhysicalCerebral192",
                    },
                  });
                }}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group inline>
            <Form.Field width={2}><strong>Motor Power</strong></Form.Field>
            <Form.Field width={2}>
              <Checkbox 
                style={{fontWeight: "normal"}} 
                checked={props.viewData.OtPhysicalCerebral195}
                disabled={props.viewData.OtPhysicalCerebral195Disabled}
                onChange={() => {
                  props.onEvent({
                    message: "check",
                    params: {
                      view: "OTPhysicalExaminationCerebralPalsy1",
                      name: "OtPhysicalCerebral195",
                    },
                  });
                }}
              />
            </Form.Field>
            <Form.Field width={2}>Right</Form.Field>
            <Form.Field width={2}>Left</Form.Field>
            <Form.Field width={2}>
              <Checkbox 
                style={{fontWeight: "normal"}} 
                label="N/A"
                checked={props.viewData.OtPhysicalCerebral197}
                disabled={props.viewData.OtPhysicalCerebral197Disabled}
                onChange={() => {
                  props.onEvent({
                    message: "check",
                    params: {
                      view: "OTPhysicalExaminationCerebralPalsy1",
                      name: "OtPhysicalCerebral197",
                    },
                  });
                }}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group inline>
            <Form.Field width={3}></Form.Field>
            <Form.Field width={4}>
              <Segment basic style={styles.columnBg}>
                <Grid columns={2} relaxed="very">
                  <Grid.Column style={styles.leftColumnPadding}>
                    <Form>
                      <Form.Group inline>
                        <Form.Field width={16} style={styles.noPadding}>
                          <Input
                            value={props.viewData.OtPhysicalCerebral1106}
                            disabled={
                              props.viewData.OtPhysicalCerebral1106Disabled
                            }
                            onChange={(e) => {
                              props.onEvent({
                                message: "inputChange",
                                params: {
                                  view: "OTPhysicalExaminationCerebralPalsy1",
                                  name: "OtPhysicalCerebral1106",
                                  value: e.target.value,
                                },
                              });
                            }}
                          />
                        </Form.Field>
                      </Form.Group>
                      <Divider />
                      <Form.Group inline>
                        <Form.Field width={16} style={styles.noPadding}>
                          <Input
                            value={props.viewData.OtPhysicalCerebral1109}
                            disabled={
                              props.viewData.OtPhysicalCerebral1109Disabled
                            }
                            onChange={(e) => {
                              props.onEvent({
                                message: "inputChange",
                                params: {
                                  view: "OTPhysicalExaminationCerebralPalsy1",
                                  name: "OtPhysicalCerebral1109",
                                  value: e.target.value,
                                },
                              });
                            }}
                          />
                        </Form.Field>
                      </Form.Group>
                    </Form>
                  </Grid.Column>
                  <Grid.Column style={styles.rightColumnPadding}>
                    <Form>
                      <Form.Group inline>
                        <Form.Field width={16} style={styles.noPadding}>
                          <Input
                            value={props.viewData.OtPhysicalCerebral1102}
                            disabled={
                              props.viewData.OtPhysicalCerebral1102Disabled
                            }
                            onChange={(e) => {
                              props.onEvent({
                                message: "inputChange",
                                params: {
                                  view: "OTPhysicalExaminationCerebralPalsy1",
                                  name: "OtPhysicalCerebral1102",
                                  value: e.target.value,
                                },
                              });
                            }}
                          />
                        </Form.Field>
                      </Form.Group>
                      <Divider />
                      <Form.Group inline>
                        <Form.Field width={16} style={styles.noPadding}>
                          <Input
                            value={props.viewData.OtPhysicalCerebral1104}
                            disabled={
                              props.viewData.OtPhysicalCerebral1104Disabled
                            }
                            onChange={(e) => {
                              props.onEvent({
                                message: "inputChange",
                                params: {
                                  view: "OTPhysicalExaminationCerebralPalsy1",
                                  name: "OtPhysicalCerebral1104",
                                  value: e.target.value,
                                },
                              });
                            }}
                          />
                        </Form.Field>
                      </Form.Group>
                    </Form>
                  </Grid.Column>
                </Grid>
                <Divider vertical></Divider>
              </Segment>
            </Form.Field>
          </Form.Group>

          <Form.Group inline>
            <Form.Field width={2}><strong>Contractures / deformities</strong></Form.Field>
            <Form.Field width={2}>
              <Dropdown
                selection
                className="fluidDropdown"
                value={props.viewData.CtContractures}
                disabled={props.viewData.CtContracturesDisabled}
                onChange={(e, data) => {
                  props.onEvent({
                    message: "choose",
                    params: {
                      view: "OTPhysicalExaminationCerebralPalsy1",
                      name: "CtContractures",
                      value: data.value,
                    },
                  });
                }}
                options={props.viewData.CtContracturesOptions}
              />
            </Form.Field>
            <Form.Field width={12}>
              <Input
                value={props.viewData.OtPhysicalCerebral1114}
                disabled={props.viewData.OtPhysicalCerebral1114Disabled}
                onChange={(e) => {
                  props.onEvent({
                    message: "inputChange",
                    params: {
                      view: "OTPhysicalExaminationCerebralPalsy1",
                      name: "OtPhysicalCerebral1114",
                      value: e.target.value,
                    },
                  });
                }}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group inline>
            <Form.Field width={2}><strong>Sensory</strong></Form.Field>
            <Form.Field width={2}>
              <Dropdown
                selection
                className="fluidDropdown"
                value={props.viewData.Ct2Sensory}
                disabled={props.viewData.Ct2SensoryDisabled}
                onChange={(e, data) => {
                  props.onEvent({
                    message: "choose",
                    params: {
                      view: "OTPhysicalExaminationCerebralPalsy1",
                      name: "Ct2Sensory",
                      value: data.value,
                    },
                  });
                }}
                options={props.viewData.Ct2SensoryOptions}
              />
            </Form.Field>
            <Form.Field width={12}>
              <Input
                value={props.viewData.OtPhysicalCerebral1127}
                disabled={props.viewData.OtPhysicalCerebral1127Disabled}
                onChange={(e) => {
                  props.onEvent({
                    message: "inputChange",
                    params: {
                      view: "OTPhysicalExaminationCerebralPalsy1",
                      name: "OtPhysicalCerebral1127",
                      value: e.target.value,
                    },
                  });
                }}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </Segment>
    </>
  );
};

export default Sub;
