import React from "react";
import CardLayout from "./CardLayout";
import ReactTable from "react-table-6";
import PropTypes from "prop-types";
import { formatUTCtoBEString } from "../../utils/dateUtils";
import { useIntl } from "react-intl";

const CardPatientOldName = (props: any) => {
  const intl = useIntl();
  let titleText = 'ประวัติเปลี่ยนชื่อ : ' + ' [' + props.hn + '] ' + props.fullName

  return (
    <CardLayout
      titleText={titleText}
      onClose={props.hideCallBack}
      toggleable={false}
    >
      <ReactTable
        style={{ height: 470, backgroundColor: '#FFFFFF' }}
        loading={props.patientOldNameLoading}
        noDataText={intl.formatMessage({ id: "ไม่มีข้อมูล" })}
        data={props.patientOldNameDataList}
        showPagination={false}
        pageSize={props.patientOldNameDataList ? (props.patientOldNameDataList.length < 12 ? 12 : props.patientOldNameDataList.length) : 12}
        columns={[
          {
            Header: 'Citizen ID / Passport No.',
            accessor: 'citizen_passport',
            style: { 'whiteSpace': 'unset' },
            width: 150,
          },
          {
            Header: 'ชื่อ นามสกุล เดิม(ไทย)',
            accessor: 'full_name_th',
            style: { 'whiteSpace': 'unset' },
            width: 200,
          },
          {
            Header: 'ชื่อ นามสกุล เดิม (อังกฤษ)',
            accessor: 'full_name_en',
            style: { 'whiteSpace': 'unset' },
            width: 200,
          },
          {
            Header: 'เหตุผล',
            accessor: 'reason',
            style: { 'whiteSpace': 'unset' },
            width: 200,
          },
          {
            Header: 'หมายเลขหนังสือเปลี่ยนชื่อ',
            accessor: 'change_ref',
            style: { 'whiteSpace': 'unset' },
            width: 200,
          },
          {
            Header: 'วันที่แก้ไข',
            accessor: 'edited_utc',
            width: 200,
            Cell: (row) => {
              const [dateComponent, timeComponent] = formatUTCtoBEString(row.original.edited_utc);
              return (
                `${dateComponent} ${timeComponent}`
              )
            }
          },
          {
            Header: 'ผู้แก้ไข',
            accessor: 'edit_user',
            style: { 'whiteSpace': 'unset' },
            width: 200,
            minWidth: 50
          },
        ]}
      />
    </CardLayout>
  );
};

CardPatientOldName.defaultProps = {
  hideCallBack: () => { },
  hn: '',
  fullName: '',
  patientOldNameDataList: [],
  patientOldNameLoading: false,
};

CardPatientOldName.propTypes = {
  hideCallBack: PropTypes.func,
  hn: PropTypes.string,
  fullName: PropTypes.string,
  patientOldNameDataList: PropTypes.array,
  patientOldNameLoading: PropTypes.bool,
};

export default React.memo(CardPatientOldName);
