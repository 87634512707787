import React, { MutableRefObject, useEffect, useRef, useState } from "react";
// ui common
import SnackMessage from "react-lib/apps/common/SnackMessage";
// ui ORM
import CardPreOperationUX from "./CardPreOperationUX";
import CardPreOPVisit from "./CardPreOPVisit";
import CardORRegistrationArea from "./CardORRegistrationArea";
import CardHoldingRoom from "./CardHoldingRoom";
import CardORRequest from "./CardORRequest";
// ui PTM
import CardAssessment from "../PTM/CardAssessmentBundle";
import CardDischargeReassessment from "../PTM/CardDischargeReassessment";
import "./CardORHistory.scss";
import { useIntl } from "react-intl";

const CARD_KEY = "CardPreOperation";

type CardPreOperationProps = {
  // function
  onEvent: any;
  setProp: any;

  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;

  // select OR
  selectedOrOrder?: any;

  // options
  masterOptions?: any;
  masterData?: any;

  // seq
  runSequence?: any;
  PreOperationSequence?: any;
  HistoryCentralLabSequence?: any;

  // or request
  ORRequestSequence?: any;
  searchedItemListWithKey?: any;
  loadingStatus?: any;
  proxyController?: any;
  userTokenize?: any;
  divisionId?: number;

  // Assessment
  DJANGO?: any;
  selectedEncounter?: any;
  AssessmentSequence?: any;
  choiceTriage?: any;
  patientInfo?: any;

  phvReadOnly?: boolean;
};

const CardPreOperationInitial: CardPreOperationProps = {
  // funtion
  onEvent: () => null,
  setProp: () => null,

  // CommonInterface
  errorMessage: null,
  successMessage: null,
  buttonLoadCheck: null,

  // select OR
  selectedOrOrder: {},

  // options
  masterOptions: {},

  // seq
  runSequence: null,
  PreOperationSequence: {},
  HistoryCentralLabSequence: {},

  // or request
  ORRequestSequence: {},
};

const CardPreOperation: React.FC<CardPreOperationProps> = (
  props: CardPreOperationProps
) => {

  console.log('Init CardPreOperation ');
  const [containerName, setContainerName] = useState<string>("PreOPVisit");
  const poRef = useRef() as MutableRefObject<HTMLDivElement>;
  const handleSetContainerName = (_event: any, data: any) => {
    setContainerName(data.name);
  };
  const [isORStaff, setIsORStaff] = useState<boolean>(false);

  useEffect(() => {
    console.log("props.DJANGO?.user?.role_types", props.DJANGO?.user?.role_types)
    const isDoctor = props.DJANGO?.user?.role_types?.includes("DOCTOR");
    if (isDoctor) {
      const children = Array.from(
        poRef.current.querySelectorAll(
          ".dropdown, .input, .search, input, textarea, .ui.small.button"
        )
      ) as HTMLElement[];
      const childrenBtn = Array.from(
        poRef.current.querySelectorAll(
          ".ui.small.button"
        )
      ) as HTMLElement[];
      const childrenCheckBtn = Array.from(
        poRef.current.querySelectorAll(
          ".ui.checkbox"
        )
      ) as HTMLElement[];
      console.log("children: ", children);
      children.forEach((e) => {
        e.setAttribute("tabindex", "-1");
        e.classList.add("block-mouse");
      });
      childrenBtn.forEach((e) => {
        e.setAttribute("tabindex", "-1");
        e.setAttribute("disabled", "-1");
      });
      childrenCheckBtn.forEach((e) => {
        e.setAttribute("tabindex", "-1");
        e.setAttribute("disabled", "-1");
        e.classList.add("block-mouse");
      });
    }

  }, [containerName]);

  useEffect(() => {
    if (poRef.current && props.phvReadOnly) {
      const children = Array.from(
        poRef.current.querySelectorAll(
          ".dropdown, .input, .search, input, textarea, .ui.small.button"
        )
      ) as HTMLElement[];

      console.log("children: ", children);
      children.forEach((e) => {
        e.setAttribute("tabindex", -1);
      });
    }
    console.log("phvReadOnly",props.phvReadOnly)
  }, [poRef?.current, props.phvReadOnly, isORStaff]);

  const handlePrintPreOperation = () => {
    props.runSequence({
      sequence: "PreOperation",
      action: "PRINT_DATA",
    })
  };

  return (
    <div ref={poRef}>
      {(props.errorMessage?.[CARD_KEY] || props.successMessage?.[CARD_KEY]) && (
        <SnackMessage
          onEvent={props.onEvent}
          onClose={() => {
            props.setProp(`errorMessage.${CARD_KEY}`, null);
            props.setProp(`successMessage.${CARD_KEY}`, null);
          }}
          error={props.errorMessage?.[CARD_KEY]}
          success={props.successMessage?.[CARD_KEY]}
          languageUX={props.languageUX}
        />
      )}
      <div id="tab-button">
        <CardPreOperationUX
          onSetContainerName={handleSetContainerName}
          onPrint={handlePrintPreOperation}
          containerName={containerName}
          languageUX={props.languageUX}
        />
      </div>

      {containerName === "PreOPVisit" ? (
        <CardPreOPVisit
          // function
          onEvent={props.onEvent}
          setProp={props.setProp}
          // CommonInterface
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          buttonLoadCheck={props.buttonLoadCheck}
          // select OR
          selectedOrOrder={props.selectedOrOrder}
          // options
          masterOptions={props.masterOptions}
          // seq
          runSequence={props.runSequence}
          PreOperationSequence={props.PreOperationSequence}
          languageUX={props.languageUX}
        />
      ) : containerName === "ORRegistrationArea" ? (
        <CardORRegistrationArea
          // function
          onEvent={props.onEvent}
          setProp={props.setProp}
          // CommonInterface
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          buttonLoadCheck={props.buttonLoadCheck}
          // select OR
          selectedOrOrder={props.selectedOrOrder}
          selectedEncounter={props.selectedEncounter}
          // options
          masterOptions={props.masterOptions}
          // seq
          runSequence={props.runSequence}
          PreOperationSequence={props.PreOperationSequence}
          HistoryCentralLabSequence={props.HistoryCentralLabSequence}
          languageUX={props.languageUX}
        />
      ) : containerName === "HoldingRoom" ? (
        <CardHoldingRoom
          // function
          onEvent={props.onEvent}
          setProp={props.setProp}
          // CommonInterface
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          buttonLoadCheck={props.buttonLoadCheck}
          // select OR
          selectedOrOrder={props.selectedOrOrder}
          // options
          masterOptions={props.masterOptions}
          // seq
          runSequence={props.runSequence}
          PreOperationSequence={props.PreOperationSequence}
          HistoryCentralLabSequence={props.HistoryCentralLabSequence}
          languageUX={props.languageUX}
        />
      ) : containerName === "ORRequest" ? (
        <CardORRequest
          // callback
          onEvent={props.onEvent}
          setProp={props.setProp}
          runSequence={props.runSequence}
          // controller
          proxyController={props.proxyController}
          // data
          ORRequestSequence={props.ORRequestSequence}
          searchedItemListWithKey={props.searchedItemListWithKey}
          masterData={props.masterData}
          encounterId={props.selectedOrOrder?.encounter}
          operatingOrderId={props.selectedOrOrder?.id}
          isNewEncounter={props.selectedOrOrder?.is_new_order_encounter}
          patientId={props.selectedOrOrder?.patient}
          loadingStatus={props.loadingStatus}
          buttonLoadCheck={props.buttonLoadCheck}
          userTokenize={props.userTokenize}
          divisionId={props.divisionId}
          cardStyle={{ height: "" }}
          // patientAppointmentId={props.selectedOrOrder?.id}
          errorMessage={props.errorMessage}
          // options
          masterOptions={props.masterOptions}
          // config
          // isNewEncounter={true}
          languageUX={props.languageUX}
        />
      ) : containerName === "AssessmentBundle" ? (
        <CardAssessment
          // callback
          onEvent={props.onEvent}
          setProp={props.setProp}
          runSequence={props.runSequence}
          // controller
          proxyController={props.proxyController}
          // data
          searchedItemListWithKey={props.searchedItemListWithKey}
          ORRequestSequence={props.ORRequestSequence}
          masterData={props.masterData}
          encounterId={props.selectedOrOrder?.encounter}
          loadingStatus={props.loadingStatus}
          buttonLoadCheck={props.buttonLoadCheck}
          cardStyle={{ height: "" }}
          // patientAppointmentId={props.selectedOrOrder?.id}
          operatingOrderId={props.selectedOrOrder?.id}
          errorMessage={props.errorMessage}
          // options
          masterOptions={props.masterOptions}
          // config
          // isNewEncounter={true}
          DJANGO={props.django}
          selectedEncounter={props.selectedEncounter}
          AssessmentSequence={props.AssessmentSequence}
          choiceTriage={props.ChoiceTriage}
          successMessage={props.successMessage}
          showHeader={true}
          languageUX={props.languageUX}
        />
      ) : containerName === "ReassessmentBundle" ? (
        <CardDischargeReassessment
          // callback
          onEvent={props.onEvent}
          setProp={props.setProp}
          runSequence={props.runSequence}
          // controller
          proxyController={props.proxyController}
          // data
          searchedItemListWithKey={props.searchedItemListWithKey}
          ORRequestSequence={props.ORRequestSequence}
          masterData={props.masterData}
          encounterId={props.selectedOrOrder?.encounter}
          loadingStatus={props.loadingStatus}
          buttonLoadCheck={props.buttonLoadCheck}
          cardStyle={{ height: "" }}
          // patientAppointmentId={props.selectedOrOrder?.id}
          operatingOrderId={props.selectedOrOrder?.id}
          errorMessage={props.errorMessage}
          // options
          masterOptions={props.masterOptions}
          // config
          // isNewEncounter={true}
          DJANGO={props.django}
          selectedEncounter={props.selectedEncounter}
          AssessmentSequence={props.AssessmentSequence}
          choiceTriage={props.ChoiceTriage}
          successMessage={props.successMessage}
          showHeader={true}
          patientInfo={props.patientInfo}
          languageUX={props.languageUX}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

CardPreOperation.defaultProps = CardPreOperationInitial;

export default React.memo(CardPreOperation);
