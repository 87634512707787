import { TDocumentDefinitions } from "pdfmake/interfaces";

import HeaderSummaryReportForm from "./HeaderSummaryReportForm";

const FormPaidCompensationWorkingType = async (props: any): Promise<TDocumentDefinitions> => {
  console.log("Props Form Paid Compensation: ", props);

  const headerForm = await HeaderSummaryReportForm({
    data: props.paidCompensationData?.params,
    header: [
      {
        alignment: "center",
        bold: true,
        fontSize: 15,
        text: "รายงานการแบ่งจ่ายค่าตอบแทนตามประเภทงาน และการรักษา",
      },
      {
        alignment: "center",
        bold: true,
        fontSize: 15,
        text: `ประเภทการรักษา : ${props.paidCompensationData?.params?.examination_type_label}`,
      },
      {
        alignment: "center",
        fontSize: 15,
        marginBottom: 5,
        text: `คลินิก : ${props.paidCompensationData?.params?.division_name} วันที่ ${props.paidCompensationData?.params?.start_date} ถึง ${props.paidCompensationData?.params?.end_date}`,
      },
    ],
  });

  const { font, images, styles } = headerForm;

  const localeStringOption = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  const reGroupData = (data: any) => {
    const groupedData = data.reduce((acc: any, row: any) => {
      const key = `${row.doctor_name}-${row.doctor_specialty}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(row);
      return acc;
    }, {});

    return Object.values(groupedData)
      .map((group: any, index: number) => {
        const rows: any = [];
        let lastRow: any = null;
        let reSequence = index + 1;

        group.forEach((row: any, index: number) => {
          if (lastRow && row.payment_method === lastRow.payment_method) {
            rows.push({
              seq: "",
              doctor_name: "",
              doctor_specialty: "",
              revenue_price: row.revenue_price,
              lab_price: row.lab_price,
              revenue_after_lab: row.revenue_after_lab,
              management_fee: row.management_fee,
              leftover_revenue: row.leftover_revenue,
              compensation_percent: row.compensation_percent,
              final_revenue_price: row.final_revenue_price,
              payment_method: row.payment_method,
            });
          } else {
            // New Group
            rows.push({
              seq: reSequence,
              doctor_name: row.doctor_name,
              doctor_specialty: row.doctor_specialty,
              revenue_price: row.revenue_price,
              lab_price: row.lab_price,
              revenue_after_lab: row.revenue_after_lab,
              management_fee: row.management_fee,
              leftover_revenue: row.leftover_revenue,
              compensation_percent: row.compensation_percent,
              final_revenue_price: row.final_revenue_price,
              payment_method: row.payment_method,
              rowSpan: group.length,
            });
            lastRow = row;
          }
        });
        return rows;
      })
      .flat();
  };

  const generateTableBody = (data: any, columns: any) => {
    let body = [];

    // Header of Table
    body.push([
      { text: "ลำดับ", bold: true, alignment: "center" },
      { text: "แพทย์", bold: true, alignment: "center" },
      { text: "สาขาแพทย์", bold: true, alignment: "center" },
      { text: "ประเภทรายรับ", bold: true, alignment: "center" },
      { text: "รายรับ", bold: true, alignment: "center" },
      { text: "ค่า Lab", bold: true, alignment: "center" },
      { text: "รายได้\nหลังหักค่า Lab", bold: true, alignment: "center" },
      { text: "หักค่าบริการ\n20%", bold: true, alignment: "center" },
      { text: "คงเหลือ", bold: true, alignment: "center" },
      { text: "ส่วนแบ่ง", bold: true, alignment: "center" },
      { text: "รวมสุทธิ", bold: true, alignment: "center" },
    ]);

    if (data?.length > 0) {
      const preProcessData = reGroupData(data);
      preProcessData.forEach((row: any) => {
        let dataRow: any = [];

        columns.forEach((column: any) => {
          if (row[column] !== null && row[column] !== undefined) {
            switch (column) {
              case "revenue_price":
              case "lab_price":
              case "revenue_after_lab":
              case "management_fee":
              case "leftover_revenue":
              case "compensation_percent":
              case "final_revenue_price":
                dataRow.push({
                  text: row[column].toLocaleString("th-TH", localeStringOption),
                  alignment: "right",
                });
                break;
              case "payment_method":
                dataRow.push({ text: row[column], alignment: "center" });
                break;
              default:
                dataRow.push({ text: row[column].toString() });
                break;
            }
          } else {
            dataRow.push({ text: "-" });
          }
        });

        // Handle rowSpan
        if (row.rowSpan) {
          dataRow[0].rowSpan = row.rowSpan;
          dataRow[1].rowSpan = row.rowSpan;
          dataRow[2].rowSpan = row.rowSpan;
        }

        body.push(dataRow);
      });
    }

    return body;
  };

  const tableReport = (data: any, columns: any) => {
    return {
      table: {
        // widths: ["auto", "*", "*", "8%", "8%", "8%", "8%", "8%", "auto", "8%"],
        widths: ["auto", "*", "*", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
        headerRows: 1,
        body: generateTableBody(data, columns),
      },
      layout: {
        hLineWidth: (i: number, node: any) => {
          return i === 0 || i === 1 || i === node.table.body.length ? 1 : 0;
        },
        vLineWidth: (i: number, node: any) => {
          return i === 0 || i === node.table.widths.length ? 1 : 0;
        },
        hLineColor: (i: number, node: any) => {
          return "black";
        },
        vLineColor: (i: number, node: any) => {
          return "black";
        },
      },
    };
  };

  return {
    ...headerForm,
    content: [
      tableReport(props.paidCompensationData?.fields || [], [
        "seq",
        "doctor_name",
        "doctor_specialty",
        "payment_method",
        "revenue_price",
        "lab_price",
        "revenue_after_lab",
        "management_fee",
        "leftover_revenue",
        "compensation_percent",
        "final_revenue_price",
      ]),
    ],
    images: {
      ...images,
    },
    styles: {
      ...styles,
    },
    pageOrientation: `landscape`,
    defaultStyle: {
      font,
      // fontSize: 14,
    },
    pageSize: `A4`,
  };
};

export default FormPaidCompensationWorkingType;
