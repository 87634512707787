import { TDocumentDefinitions } from "pdfmake/interfaces";

import HeaderSummaryReportForm from "./HeaderSummaryReportForm";

export default async function toPDFMakeData(props: any): Promise<TDocumentDefinitions> {
  console.log("Props form CoverageReport:", props);

  const headerForm = await HeaderSummaryReportForm({
    data: props.reportOutstandingDebtors?.params,
    header: [
      {
        alignment: "center",
        bold: true,
        fontSize: 14,
        text: "รายงานลูกหนี้คงค้าง",
      },
      {
        text: [
          {
            alignment: "center",
            bold: true,
            fontSize: 14,
            margin: [0, 0, 10, 0],
            text: `สิทธิ : ${props.reportOutstandingDebtors?.params?.coverage_name} `,
          },
          {
            alignment: "center",
            bold: true,
            fontSize: 14,
            text: `ต้นสังกัด : ${props.reportOutstandingDebtors?.params?.payer_name} `,
          },
        ],
      },
      {
        alignment: "center",
        fontSize: 14,
        marginBottom: 5,
        text: `วันที่ออกใบแจ้งหนี้  ตั้งแต่ ${props.reportOutstandingDebtors?.params?.start_date} ถึง ${props.reportOutstandingDebtors?.params?.end_date}`,
      },
    ],
  });

  const { font, images, styles } = headerForm;

  const generateTableBody = (data: any, columns: any) => {
    let body: any[] = [];

    // Header of Table
    body.push([
      { text: "ลำดับ", alignment: "center" },
      { text: "วันที่\n ออกใบแจ้งหนี้", alignment: "left" },
      { text: "เลขที่\n ใบแจ้งหนี้", alignment: "left" },
      { text: "ประเภท ผู้ป่วย", alignment: "center" },
      { text: "สิทธิ", alignment: "center" },
      { text: "หน่วยงานต้นสังกัด", alignment: "center" },
      { text: "สถานะส่งเบิก", alignment: "center" },
      { text: "จำนวน ส่งเบิก", alignment: "center" },
      { text: "ยอดรวม ทุกรายการ", alignment: "center" },
      { text: "รายการ", alignment: "center" },
      { text: "ยอดเบิก", alignment: "center" },
    ]);

    data.forEach((field: any) => {
      if (field.items?.length > 0) {
        (field.items || []).forEach((row: any) => {
          let itemListArray: any = [];

          if (row.item_list.length > 0) {
            (row.item_list || []).forEach((column: any, index: any) => {
              itemListArray.push([
                { text: column, border: [false, false, false, false] },
                {
                  text: row.send_claim_price[index],
                  alignment: "right",
                  border: [true, false, false, false],
                },
              ]);
            });
          }

          body.push([
            { text: row.seq, border: [true, false, true, true] },
            { text: row.created_date, border: [true, false, true, true] },
            { text: row.lot_no, border: [true, false, true, true] },
            { text: row.patient_type, border: [true, false, true, true] },
            { text: row.coverage_name, border: [true, false, true, true] },
            { text: row.payer_name, border: [true, false, true, true] },
            { text: row.send_claim_status, border: [true, false, true, true] },
            { text: row.quantity, border: [true, false, true, true] },
            { text: row.total_price, border: [true, false, true, true] },
            {
              ...(row.item_list.length > 1
                ? {
                    colSpan: 2,
                    table: {
                      widths: ["74%", "26%"],
                      body: itemListArray,
                    },
                    margin: [0, 0, 1, 0],
                    border: [true, false, true, true],
                  }
                : ""),
            },
            {
              text: "",
              margin: [0, 0, 1, 0],
            },
          ]);
        });
      }
    });
    console.log(" ~ body:", body);
    return body;
  };
  // 5
  const tableReport = (data: any, columns: any) => {
    return {
      table: {
        widths: ["4%", "9%", "9%", "5%", "9%", "12%", "9%", "4%", "6%", "24%", "9%"],
        headerRows: 1,
        body: generateTableBody(data, columns),
      },
      layout: {
        paddingTop: (i: any, node: any) => {
          return 0;
        },
        paddingBottom: (i: any, node: any) => {
          return 0;
        },
      },
    };
  };

  const subTableTotals = (data: any, columns: any) => {
    return {
      id: "signature",
      margin: [0, 15, 0, 0],
      colSpan: 11,
      table: {
        widths: ["15%", "19%", "7%", "7%", "7%"],
        body: generateSubTableTotals(data, columns),
      },
    };
  };

  const generateSubTableTotals = (data: any, columns: any) => {
    let subBody: any[] = [];
    // Header of Table
    subBody.push([
      { text: "สิทธิ", alignment: "center" },
      { text: "หน่วยงานต้นสังกัด", alignment: "center" },
      { text: "ยอดรวม", alignment: "center" },
      { text: "OPD", alignment: "center" },
      { text: "IPD", alignment: "center" },
    ]);

    (data?.fields || []).forEach((field: any) => {
      if (field.sub_totals?.length > 0) {
        field.sub_totals.forEach((row: any) => {
          let dataRow: any = [];

          columns.forEach((column: any) => {
            let subRow: any[] = [];
            if (row[column] !== null && row[column] !== undefined) {
              switch (column) {
                case "total":
                  dataRow.push({
                    text: row[column],
                    alignment: "right",
                    border: [true, false, true, false],
                  });
                  break;
                case "OPD":
                  dataRow.push({
                    text: row[column],
                    alignment: "right",
                    border: [true, false, true, false],
                  });
                  break;
                case "IPD":
                  dataRow.push({
                    text: row[column],
                    alignment: "right",
                    border: [true, false, true, false],
                  });
                  break;
                default:
                  dataRow.push({ text: row[column], border: [true, false, true, false] });
                  break;
              }
              // console.log("Form Coverage Debug Row: ", row);
              // console.log("Form Coverage Debug Row[column]: ", row[column].toString());
            } else {
              dataRow.push(" ");
            }
          });

          subBody.push(dataRow);
        });

        subBody.push([
          {
            text: "รวมทั้งสิ้น",
            colSpan: 2,
            alignment: "left",
            bold: true,
            border: [true, true, true, true],
          },
          "",
          {
            text: `${data.params?.total_price}`,
            alignment: "right",
            border: [true, true, true, true],
          },
          {
            text: `${data.params?.total_OPD}`,
            alignment: "right",
            border: [true, true, true, true],
          },
          {
            id: "signature_last_content",
            text: `${data.params?.total_IPD}`,
            alignment: "right",
            border: [true, true, true, true],
          },
        ]);
      }
    });

    return subBody;
  };

  return {
    ...headerForm,
    content: [
      tableReport(props.reportOutstandingDebtors?.fields || [], [
        "seq",
        "created_date",
        "lot_no",
        "patient_type",
        "coverage_name",
        "payer_name",
        "send_claim_status",
        "quantity",
        "total_price",
        "item_list",
        "send_claim_price",
      ]),
      subTableTotals(props.reportOutstandingDebtors, [
        "coverage_name",
        "payer_name",
        "total",
        "OPD",
        "IPD",
      ]),
    ],
    images: {
      ...images,
    },
    styles: {
      ...styles,
    },
    pageOrientation: `landscape`,
    defaultStyle: {
      font,
      // fontSize: 14,
    },
    pageSize: `A4`,
    pageBreakBefore: (
      currentNode: any,
      followingNodesOnPage: any,
      nodesOnNextPage: any,
      previousNodesOnPage: any
    ) => {
      const isPageBreakClass = currentNode.id?.includes("signature");

      if (isPageBreakClass && !currentNode.id?.includes("last_content")) {
        const lastContent = [...followingNodesOnPage, ...nodesOnNextPage].find(
          (node: any) => node.id?.includes("last_content") && node.id?.includes(currentNode.id)
        );

        return lastContent?.pageNumbers?.toString() !== currentNode.pageNumbers.toString();
      }
    },
  };
}
