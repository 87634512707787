import WasmController from 'react-lib/frameworks/WasmController';

export type State = 
  {
  }

export const StateInitial: State = 
  {
  }

export type Event = 
  { message: "getTreatmentOrder", params: any }
  |  { message: "getTreatmentOrderPerformer", params: any }
  |  { message: "getTreatment", params: any }
  |  { message: "postTreatmentOrderEstimate", params: any }
  |  { message: "postTreatmentOrder", params: any }
  |  { message: "putTreatmentOrderEncounterArrive", params: any }

export type Data = 
  {
    division?: number,
  }

export const DataInitial = 
  {
  }

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any 

export const getTreatmentOrder: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, encounterId = _b.encounterId, excludeDraft = _b.excludeDraft, excludeCancel = _b.excludeCancel;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, trtService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            if (encounterId) {
//                                params.encounter_id = encounterId;
//                            }
//                            if (excludeDraft) {
//                                params.exclude_draft = excludeDraft;
//                            }
//                            if (excludeCancel) {
//                                params.exclude_cancel = excludeCancel;
//                            }
//                            trtService = new TRTService_1.default();
//                            return [4 /*yield*/, utils_1.to(trtService.getTreatmentOrder({ params: params }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (params) {
//        return this.client
//            .get(apis_1.TRT.TREATMENT_ORDER, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/TRT/treatment-order/
}

export const getTreatmentOrderPerformer: Handler = async (controller, {}) => {
//function () { return __awaiter(_this, void 0, void 0, function () {
//            var params, trtService, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        params = {};
//                        trtService = new TRTService_1.default();
//                        return [4 /*yield*/, utils_1.to(trtService.getTreatmentOrderPerformer({ params: params }))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params;
//        return this.client
//            .get(apis_1.TRT.TREATMENT_ORDER_PERFORMER, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/TRT/treatment-order-performer/
}

export const getTreatment: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, emrId = _b.emrId, encounterId = _b.encounterId, search = _b.search, limit = _b.limit, offset = _b.offset;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, trtService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            if (emrId) {
//                                params.emr = emrId;
//                            }
//                            if (encounterId) {
//                                params.encounter = encounterId;
//                            }
//                            if (search) {
//                                params.search = search;
//                            }
//                            if (limit) {
//                                params.limit = limit;
//                            }
//                            if (offset) {
//                                params.offset = offset;
//                            }
//                            trtService = new TRTService_1.default();
//                            return [4 /*yield*/, utils_1.to(trtService.getTreatment({ params: params }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params;
//        return this.client
//            .get(apis_1.TRT.TREATMENT, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/TRT/treatment/
}

export const postTreatmentOrderEstimate: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, encounterId = _b.encounterId, orderItems = _b.orderItems;
//            return __awaiter(_this, void 0, void 0, function () {
//                var data, params, trtService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            data = {};
//                            params = {};
//                            if (orderItems) {
//                                data.order_items = orderItems;
//                            }
//                            if (encounterId) {
//                                data.encounter = encounterId;
//                            }
//                            trtService = new TRTService_1.default();
//                            return [4 /*yield*/, utils_1.to(trtService.postTreatmentOrderEstimate({ data: data, params: params }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params, data = _b.data;
//        return this.client
//            .post(apis_1.TRT.TREATMENT_ORDER_ESTIMATE, data, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/TRT/treatment-order/estimate/
}

export const postTreatmentOrder: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, data = _b.data;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, trtService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            trtService = new TRTService_1.default();
//                            return [4 /*yield*/, utils_1.to(trtService.postTreatmentOrder({ data: data, params: params }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params, data = _b.data;
//        return this.client
//            .post(apis_1.TRT.TREATMENT_ORDER, data, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/TRT/treatment-order/
}

export const putTreatmentOrderEncounterArrive: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, encounterId = _b.encounterId, params = _b.params;
//            return __awaiter(_this, void 0, void 0, function () {
//                var trtService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            trtService = new TRTService_1.default();
//                            return [4 /*yield*/, utils_1.to(trtService.putTreatmentOrderEncounterArrive(encounterId, params))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (encounterId, params) {
//        var url = apis_1.TRT.TREATMENT_ORDER_ENCOUNTER_ARRIVE({
//            encounter_id: encounterId
//        });
//        return this.client
//            .put(url, params)
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/TRT/treatment-order/encounter-arrive/' +
//((__t = (encounter_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}
