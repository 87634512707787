import axios from "axios";
// @ts-ignore
import Fingerprint2 from "fingerprintjs2";

import { USER, MIXIN } from "react-lib/configs/apis";
import config from "config/config";
import * as serviceWorker from "serviceWorker";
import {
  registerServiceWorker,
  createNotificationSubscription,
  getUserSubscription,
} from "../../Common/push-notifications";

type CredentialArgument = {
  auth_ver?: "1" | "1_doctor" | "1_patient";
  app?: string;
  sign: string;
  email: string;
  firstName: string;
  lastName: string;
  time: string;
  role: string;
  division: string;
  title?: string;
  security_group?: string;
  care_provider_code?: string;
  hn?: string;
};

export default class PasswordLoginManager {
  requestTokenByCredential = async ({
    authVer = "1",
    sign,
    email,
    firstName,
    lastName,
    time,
    role,
    division,
  }: CredentialArgument) => {
    try {
      const [deviceId, userSubscription] =
        await this.getFingerPrintAndSubscription();
      const data = {
        auth_ver: authVer || "1",
        sign,
        email,
        first_name: firstName,
        last_name: lastName,
        time,
        device_id: deviceId,
        device_type: "webpush",
        device_name: `${this.getBrowserName()} on ${this.getOSName()}`,
        device_token: userSubscription ? JSON.stringify(userSubscription) : "",
        application: config.APP_NAME,
        role,
        division,
      };
      if (userSubscription) {
        data.device_token = JSON.stringify(userSubscription);
      }
      let formData = new FormData();
      for (let key in data) {
        formData.append(key, data[key]);
      }
      const res = await axios.post(MIXIN.REGISTER_BY_TOKEN, formData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      console.log(res);
      return res.data;
    } catch (e) {
      const res = e.response;
      if (400 <= res.status && res.status < 500) {
        throw res.data;
      }
      console.error(res);
      throw res;
    }
  };

  requestTokenByCertLogin = async ({
    sign,
    app,
    email,
    firstName,
    lastName,
    time,
    role,
    division,
    auth_ver,
    title,
    security_group,
    care_provider_code,
  }: CredentialArgument) => {
    try {
      const [deviceId, userSubscription] =
        await this.getFingerPrintAndSubscription();
      const data = {
        auth_ver: auth_ver,
        sign,
        app: app || config.APP_NAME,
        email,
        first_name: firstName,
        last_name: lastName,
        time,
        device_id: deviceId,
        device_type: "webpush",
        device_name: `${this.getBrowserName()} on ${this.getOSName()}`,
        device_token: userSubscription ? JSON.stringify(userSubscription) : "",
        role,
        division,
        mode: "token",
      };
      if (userSubscription) {
        data.device_token = JSON.stringify(userSubscription);
      }
      let formData = new FormData();
      for (let key in data) {
        formData.append(key, data[key]);
      }
      if (auth_ver === "1_doctor") {
        formData.append("title", title);
        formData.append("security_group", security_group);
        formData.append("care_provider_code", care_provider_code);
      }
      const res = await axios.post(USER.CERT_LOGIN, formData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      console.log(res);
      return res.data;
    } catch (e) {
      const res = e.response;
      if (400 <= res.status && res.status < 500) {
        throw res.data;
      }
      console.error(res);
      throw res;
    }
  };

  requestToken = async (username: string, password: string) => {
    try {
      const [deviceId, userSubscription] =
        await this.getFingerPrintAndSubscription();
      const data = {
        username,
        password,
        device_id: deviceId,
        device_type: "webpush",
        device_name: `${this.getBrowserName()} on ${this.getOSName()}`,
        device_token: userSubscription ? JSON.stringify(userSubscription) : "",
        application: config.APP_NAME,
      };
      if (userSubscription) {
        data.device_token = JSON.stringify(userSubscription);
      }
      // console.log(data);
      console.log(USER.REQUEST_TOKEN, "USER.REQUEST_TOKEN");
      const res = await axios.post(USER.REQUEST_TOKEN, data);
      // console.log(res);
      return res.data;
    } catch (e) {
      const res = e.response;
      if (400 <= res.status && res.status < 500) {
        throw res.data;
      }
      console.error(res);
      throw res;
    }
  };

  requestMobileOTP = async (
    cid: string,
    username: string,
    method: "mail" | "otp" = "mail"
  ) => {
    try {
      const data = { cid, username, method };
      const res = await axios.post(USER.MOBILE_REQUEST_OTP, data);
      console.log(res);
      return res.data;
    } catch (e) {
      const res = e.response;
      if (400 <= res.status && res.status < 500) {
        throw res.data;
      }
      console.error(res);
      throw res;
    }
  };

  requestMobileToken = async (username: string, ref: string, otp: string) => {
    try {
      const [deviceId, userSubscription] =
        await this.getFingerPrintAndSubscription();
      const data = {
        username,
        ref,
        password: otp,
        device_id: deviceId,
        device_type: "webpush",
        device_name: `${this.getBrowserName()} on ${this.getOSName()}`,
        // device_token: userSubscription ? JSON.stringify(userSubscription) : null,
        application: config.APP_NAME,
      };
      if (userSubscription) {
        data.device_token = JSON.stringify(userSubscription);
      }
      const res = await axios.post(USER.MOBILE_REQUEST_TOKEN, data);
      console.log(res);
      return res.data;
    } catch (e) {
      const res = e.response;
      if (400 <= res.status && res.status < 500) {
        throw res.data;
      }
      console.error(res);
      throw res;
    }
  };

  getUserProfile = async (token: string) => {
    try {
      let res = await axios.get(USER.USER_PROFILE, {
        headers: { Authorization: `token ${token}` },
      });
      console.log(res);
      return res.data;
    } catch (e) {
      const res = e.response;
      if (400 <= res.status && res.status < 500) {
        throw res.data;
      }
      console.error(res);
      throw res;
    }
  };

  getBrowserName = () => {
    const nAgt = navigator.userAgent;

    // In Opera, the true version is after "Opera" or after "Version"
    if (nAgt.indexOf("Opera") !== -1) {
      return "Opera";
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if (nAgt.indexOf("MSIE") !== -1) {
      return "Microsoft Internet Explorer";
    }
    // In Chrome, the true version is after "Chrome"
    else if (nAgt.indexOf("Chrome") !== -1) {
      return "Chrome";
    }
    // In Safari, the true version is after "Safari" or after "Version"
    else if (nAgt.indexOf("Safari") !== -1) {
      return "Safari";
    }
    // In Firefox, the true version is after "Firefox"
    else if (nAgt.indexOf("Firefox") !== -1) {
      return "Firefox";
    }
    // In most other browsers, "name/version" is at the end of userAgent
    else {
      return navigator.appName;
    }
  };

  getOSName = () => {
    const nAgt = navigator.userAgent;
    let os = "Unknown OS";
    const clientStrings = [
      { s: "Windows 3.11", r: /Win16/ },
      { s: "Windows 95", r: /(Windows 95|Win95|Windows_95)/ },
      { s: "Windows ME", r: /(Win 9x 4.90|Windows ME)/ },
      { s: "Windows 98", r: /(Windows 98|Win98)/ },
      { s: "Windows CE", r: /Windows CE/ },
      { s: "Windows 2000", r: /(Windows NT 5.0|Windows 2000)/ },
      { s: "Windows XP", r: /(Windows NT 5.1|Windows XP)/ },
      { s: "Windows Server 2003", r: /Windows NT 5.2/ },
      { s: "Windows Vista", r: /Windows NT 6.0/ },
      { s: "Windows 7", r: /(Windows 7|Windows NT 6.1)/ },
      { s: "Windows 8.1", r: /(Windows 8.1|Windows NT 6.3)/ },
      { s: "Windows 8", r: /(Windows 8|Windows NT 6.2)/ },
      { s: "Windows NT 4.0", r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
      { s: "Windows ME", r: /Windows ME/ },
      { s: "Android", r: /Android/ },
      { s: "Open BSD", r: /OpenBSD/ },
      { s: "Sun OS", r: /SunOS/ },
      { s: "Linux", r: /(Linux|X11)/ },
      { s: "iOS", r: /(iPhone|iPad|iPod)/ },
      { s: "Mac OS X", r: /Mac OS X/ },
      { s: "Mac OS", r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
      { s: "QNX", r: /QNX/ },
      { s: "UNIX", r: /UNIX/ },
      { s: "BeOS", r: /BeOS/ },
      { s: "OS/2", r: /OS\/2/ },
      {
        s: "Search Bot",
        r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/,
      },
    ];
    for (let id in clientStrings) {
      let cs = clientStrings[id];
      if (cs.r.test(nAgt)) {
        os = cs.s;
        break;
      }
    }
    return os;
  };

  getFingerPrintAndSubscription = async () => {
    let registrations = null;
    try {
      registrations = await navigator.serviceWorker.getRegistrations();
      console.log("check service worker", registrations);
      if (registrations.length <= 0) {
        console.log("Force register service worker again !!!");

        if (!config.BYPASS_SERVICE_WORKER) {
          serviceWorker.register();
          registerServiceWorker();
        }
      }
    } catch (e) {
      if (navigator.serviceWorker) {
        console.log("Force register service worker again !!!");

        if (!config.BYPASS_SERVICE_WORKER) {
          serviceWorker.register();
          registerServiceWorker();
        }
      }
    }
    const options = {};
    let components = await Fingerprint2.getPromise(options);
    let values = components.map((component: any) => component.value);
    let device_id = Fingerprint2.x64hash128(values.join(""), 31);
    console.log("LCB Fingerprint id:", device_id);
    // Get subscription endpoint object
    let subscription;
    try {
      subscription = await getUserSubscription();
      if (!subscription) {
        subscription = await createNotificationSubscription();
        console.log("LCB Success get notification subscription.");
        console.log(subscription);
      }
    } catch (err) {
      console.log("LCB error getSubScriptionObejct");
      console.error(
        "Couldn't create the notification subscription",
        err,
        "name:",
        err.name,
        "message:",
        err.message,
        "code:",
        err.code
      );
    }
    return [device_id, subscription];
  };
}
