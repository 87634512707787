import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button
} from 'semantic-ui-react'

const CardBillingEncountersUX = (props: any) => {
    return(
      <div>
        <div
          style={{fontWeight: "bold", fontSize: "1.2rem",padding: "10px 10px 0px 10px", display: "flex", justifyContent: "space-between"}}>
          ENCOUNTERS
          <div>

          </div>
        </div>
        <div
          style={{ padding: "0px 10px 10px 10px"}}>

          <hr>
          </hr>
        </div>
        <Table
          data={props.encounterListData}
          headers="Encounter,Division,Doctor,Status,Create,Update"
          keys="number,division_name,doctor_name,status_name,created,edited"
          minRows={1}
          showPagination={false}
          style={{height: "150px", margin: "0px 10px"}}>
        </Table>
        <div
          style={{paddingTop: "20px" }}>

          <div
            style={{fontWeight: "bold", fontSize: "1.2rem",padding: "10px", display: "flex", justifyContent: "space-between"}}>

            <div>

            </div>
          </div>
          <Table
            data={props.doctorOrderData}
            headers="ชื่อคำสั่ง,วันที่เวลา,ชำระเงิน,สถานะ,ข้อความ,หมายเหตุ"
            keys="name,order_time,payment_status_name,order_status_name,message,note"
            minRows={8}
            showPagination={false}
            style={{height: "350px",  margin: "0px 10px"}}>
          </Table>
          <div
            style={{display:"flex", margin:"10px "}}>

            <Button
              icon="redo"
              onClick={props.onRefresh}>
            </Button>
          </div>
        </div>
        <div
          style={{width: "100%", height: "200px"}}>

        </div>
      </div>
    )
}


export default CardBillingEncountersUX

export const screenPropsDefault = {"debug":true,"showSupplyList":false}

/* Date Time : Mon Jun 24 2024 16:36:39 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 0
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 2,
      "name": "Table",
      "parent": 63,
      "props": {
        "columns": {
          "type": "code",
          "value": ""
        },
        "data": {
          "type": "code",
          "value": "props.doctorOrderData"
        },
        "getTdProps": {
          "type": "code",
          "value": ""
        },
        "headers": {
          "type": "value",
          "value": "ชื่อคำสั่ง,วันที่เวลา,ชำระเงิน,สถานะ,ข้อความ,หมายเหตุ"
        },
        "keys": {
          "type": "value",
          "value": "name,order_time,payment_status_name,order_status_name,message,note"
        },
        "minRows": {
          "type": "code",
          "value": "8"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"350px\",  margin: \"0px 10px\"}"
        },
        "widths": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "ENCOUNTERS"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.2rem\",padding: \"10px 10px 0px 10px\", display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 26,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.encounterListData"
        },
        "getTdProps": {
          "type": "code",
          "value": ""
        },
        "getTrProps": {
          "type": "code",
          "value": ""
        },
        "headers": {
          "type": "value",
          "value": "Encounter,Division,Doctor,Status,Create,Update"
        },
        "keys": {
          "type": "value",
          "value": "number,division_name,doctor_name,status_name,created,edited"
        },
        "minRows": {
          "type": "code",
          "value": "1"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"150px\", margin: \"0px 10px\"}"
        },
        "widths": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "div",
      "parent": 63,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.2rem\",padding: \"10px\", display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "div",
      "parent": 59,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{paddingTop: \"20px\" }"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"200px\"}"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "div",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"0px 10px 10px 10px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "hr",
      "parent": 87,
      "props": {
      },
      "seq": 88,
      "void": true
    },
    {
      "from": null,
      "id": 89,
      "name": "div",
      "parent": 63,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", margin:\"10px \"}"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 90,
      "name": "Button",
      "parent": 89,
      "props": {
        "icon": {
          "type": "value",
          "value": "redo"
        },
        "onClick": {
          "type": "code",
          "value": "props.onRefresh"
        }
      },
      "seq": 90,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardBillingEncountersUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "debug": true,
    "showSupplyList": false
  },
  "width": 80
}

*********************************************************************************** */
