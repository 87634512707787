import FormSymbolImages from "./AddSymbolPrintList";

export default function toPDFMakeData(props: any) {
  const injectImages = (haveHeader: boolean) => {
    let symbolUsage = {};
    if (!haveHeader) {
      symbolUsage = {
        checked: FormSymbolImages.checked,
        unchecked: FormSymbolImages.unchecked,
        squarebox: FormSymbolImages.squarebox,
        checkmark: FormSymbolImages.checkmark,
      };
    }
    return symbolUsage;
  };

  return {
    content: [
      {
        decoration: ``,
        decorationStyle: ``,
        preserveLeadingSpaces: true,
        text: `หนังสือแสดงความยินยอมให้เปิดเผยข้อมูลด้านสุขภาพของบุคคลทางอิเล็กทรอนิกส์`,
        pageBreak: ``,
        bold: `true`,
        alignment: `center`,
        width: `auto`,
        decorationColor: ``,
        fontSize: `18`,
        color: ``,
      },
      {
        preserveLeadingSpaces: true,
        bold: false,
        decorationColor: ``,
        alignment: `left`,
        decorationStyle: ``,
        width: `auto`,
        text: ` `,
        color: ``,
        decoration: ``,
        pageBreak: ``,
        fontSize: 15,
      },
      {
        columns: [
          {
            decoration: ``,
            pageBreak: ``,
            decorationStyle: ``,
            alignment: `left`,
            width: 300,
            fontSize: 15,
            preserveLeadingSpaces: true,
            color: ``,
            bold: false,
            decorationColor: ``,
            text: ``,
          },
          {
            fontSize: 15,
            bold: false,
            preserveLeadingSpaces: true,
            decoration: ``,
            pageBreak: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: `auto`,
            text: `สถานที่ `,
            alignment: `left`,
            color: ``,
          },
          {
            bold: false,
            color: ``,
            text: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationColor: ``,
            width: 5,
            alignment: `left`,
            fontSize: 15,
            decorationStyle: ``,
          },
          {
            text: `โรงพยาบาลเซเปี้ยนซ์`,
            bold: false,
            preserveLeadingSpaces: true,
            decoration: ``,
            pageBreak: ``,
            alignment: `center`,
            color: ``,
            fontSize: 15,
            decorationStyle: ``,
            decorationColor: ``,
            width: `auto`,
          },
        ],
      },
      {
        columns: [
          {
            text: ``,
            pageBreak: ``,
            decorationColor: ``,
            alignment: `left`,
            decoration: ``,
            color: ``,
            decorationStyle: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            width: 300,
            bold: false,
          },
          {
            bold: false,
            width: `auto`,
            decoration: ``,
            decorationColor: ``,
            text: `วันที่`,
            color: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decorationStyle: ``,
            fontSize: 15,
          },
          {
            fontSize: 15,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationStyle: ``,
            color: ``,
            decorationColor: ``,
            text: ``,
            width: 5,
            bold: false,
            decoration: ``,
            alignment: `left`,
          },
          {
            decorationStyle: ``,
            text: props.items?.printDay,
            decoration: ``,
            bold: false,
            pageBreak: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            alignment: `center`,
            width: `auto`,
            color: ``,
          },
          {
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            text: ``,
            pageBreak: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            alignment: `left`,
            color: ``,
            bold: false,
            decoration: ``,
          },
          {
            color: ``,
            pageBreak: ``,
            decorationStyle: ``,
            decoration: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            bold: false,
            alignment: `left`,
            decorationColor: ``,
            text: `เดือน `,
            width: `auto`,
          },
          {
            decorationColor: ``,
            bold: false,
            width: 5,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decoration: ``,
            fontSize: 15,
            pageBreak: ``,
            color: ``,
            decorationStyle: ``,
            text: ``,
          },
          {
            text: props.items?.printMonth,
            decorationStyle: ``,
            width: `auto`,
            color: ``,
            decoration: ``,
            bold: false,
            pageBreak: ``,
            alignment: `center`,
            fontSize: 15,
            decorationColor: ``,
            preserveLeadingSpaces: true,
          },
          {
            decorationStyle: ``,
            alignment: `left`,
            text: ``,
            width: 5,
            fontSize: 15,
            bold: false,
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationColor: ``,
            color: ``,
            pageBreak: ``,
          },
          {
            alignment: `left`,
            color: ``,
            text: `พ.ศ. `,
            decorationColor: ``,
            fontSize: 15,
            decoration: ``,
            width: `auto`,
            bold: false,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationStyle: ``,
          },
          {
            decorationStyle: ``,
            width: 5,
            pageBreak: ``,
            color: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            alignment: `left`,
            text: ``,
            bold: false,
            decoration: ``,
            decorationColor: ``,
          },
          {
            alignment: `center`,
            decoration: ``,
            fontSize: 15,
            decorationColor: ``,
            color: ``,
            text: props.items?.printYear,
            preserveLeadingSpaces: true,
            bold: false,
            width: `auto`,
            decorationStyle: ``,
            pageBreak: ``,
          },
          {
            pageBreak: ``,
            text: ``,
            bold: false,
            decoration: ``,
            color: ``,
            width: 5,
            fontSize: 15,
            decorationStyle: ``,
            decorationColor: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
          },
          {
            fontSize: 15,
            pageBreak: ``,
            decorationStyle: ``,
            text: `เวลา `,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            width: `auto`,
            color: ``,
            bold: false,
            decoration: ``,
            alignment: `left`,
          },
          {
            decorationStyle: ``,
            color: ``,
            bold: false,
            width: 5,
            pageBreak: ``,
            text: ``,
            decoration: ``,
            decorationColor: ``,
            alignment: `left`,
            fontSize: 15,
            preserveLeadingSpaces: true,
          },
          {
            decoration: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            text: props.items?.printTime,
            pageBreak: ``,
            fontSize: 15,
            decorationColor: ``,
            alignment: `center`,
            bold: false,
            width: `auto`,
            color: ``,
          },
          {
            bold: false,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            alignment: `left`,
            width: 5,
            color: ``,
            pageBreak: ``,
            fontSize: 15,
            decoration: ``,
            text: ``,
            decorationStyle: ``,
          },
          {
            fontSize: 15,
            color: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            bold: false,
            text: `น.`,
            width: `auto`,
            decorationStyle: ``,
            alignment: `left`,
            decoration: ``,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            bold: false,
            width: 300,
            alignment: `left`,
            decorationStyle: ``,
            fontSize: 15,
            decorationColor: ``,
            text: ``,
            color: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
          },
          {
            color: ``,
            alignment: `left`,
            text: `เลขที่ใบ Consent Form `,
            pageBreak: ``,
            decoration: ``,
            decorationColor: ``,
            width: `auto`,
            fontSize: 15,
            bold: false,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
          },
          {
            width: `auto`,
            color: ``,
            decoration: ``,
            bold: false,
            text: `................................................`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            alignment: `center`,
            pageBreak: ``,
            decorationColor: ``,
            fontSize: 15,
          },
        ],
      },
      {
        pageBreak: ``,
        bold: false,
        text: ` `,
        decorationStyle: ``,
        decorationColor: ``,
        decoration: ``,
        color: ``,
        width: `auto`,
        fontSize: 15,
        alignment: `left`,
        preserveLeadingSpaces: true,
      },
      {
        columns: [
          {
            pageBreak: ``,
            decoration: ``,
            decorationColor: ``,
            text: ``,
            width: 30,
            color: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            alignment: `left`,
            fontSize: 15,
            bold: false,
          },
          {
            text: `1. ข้าพเจ้า`,
            bold: false,
            color: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            alignment: `left`,
            width: `auto`,
            decorationStyle: ``,
            decorationColor: ``,
            fontSize: 15,
          },
          {
            decorationStyle: ``,
            text: ` `,
            decoration: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            pageBreak: ``,
            decorationColor: ``,
            alignment: `left`,
            bold: false,
            color: ``,
            width: 5,
            margin: [0, 0, 0, 0],
          },
          {
            stack: [
              {
                alignment: `center`,
                width: `auto`,
                text:
                  props.items?.full_name_th !== ""
                    ? props.items?.full_name_th
                    : props.items?.full_name_en !== ""
                    ? props.items?.full_name_en
                    : props.items?.full_name,
                fontSize: 15,
                color: ``,
                margin: [0, 0, 0, -15],
                decoration: ``,
                bold: false,
                decorationColor: ``,
                pageBreak: ``,
                preserveLeadingSpaces: true,
                decorationStyle: ``,
              },
              {
                width: `auto`,
                text: `...........................................................`,
                decoration: ``,
                margin: [0, 0, 0, 0],
                decorationColor: ``,
                fontSize: 15,
                color: ``,
                alignment: `left`,
                decorationStyle: ``,
                pageBreak: ``,
                bold: false,
                preserveLeadingSpaces: true,
              },
            ],
          },
          {
            pageBreak: ``,
            decorationColor: ``,
            color: ``,
            text: `HN`,
            preserveLeadingSpaces: true,
            fontSize: 15,
            width: `auto`,
            bold: false,
            alignment: `left`,
            decorationStyle: ``,
            decoration: ``,
          },
          {
            text: ` `,
            alignment: `left`,
            decorationStyle: ``,
            width: 5,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            bold: false,
            decoration: ``,
            color: ``,
            pageBreak: ``,
            fontSize: 15,
          },
          {
            stack: [
              {
                decorationStyle: ``,
                decoration: ``,
                preserveLeadingSpaces: true,
                color: ``,
                margin: [28, 0, 0, -15],
                alignment: `left`,
                pageBreak: ``,
                decorationColor: ``,
                width: `auto`,
                text: props.items?.hn,
                fontSize: 15,
                bold: false,
              },
              {
                fontSize: 15,
                color: ``,
                bold: false,
                decoration: ``,
                text: `...........................................`,
                margin: [0, 0, 0, 0],
                preserveLeadingSpaces: true,
                pageBreak: ``,
                decorationStyle: ``,
                alignment: `left`,
                width: `auto`,
                decorationColor: ``,
              },
            ],
          },
          {
            margin: [-40, 0, 0, 0],
            decorationColor: ``,
            bold: false,
            fontSize: 15,
            text: `อายุ`,
            width: `auto`,
            color: ``,
            pageBreak: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decorationStyle: ``,
          },
          {
            color: ``,
            alignment: `left`,
            pageBreak: ``,
            width: 5,
            fontSize: 15,
            decoration: ``,
            preserveLeadingSpaces: true,
            text: ` `,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            bold: false,
          },
          {
            stack: [
              {
                color: ``,
                preserveLeadingSpaces: true,
                pageBreak: ``,
                decorationStyle: ``,
                bold: false,
                alignment: `center`,
                width: `auto`,
                fontSize: 15,
                decorationColor: ``,
                text: props.items?.age,
                margin: [0, 0, 0, -15],
                decoration: ``,
              },
              {
                bold: false,
                width: `auto`,
                decorationStyle: ``,
                preserveLeadingSpaces: true,
                color: ``,
                text: `...........................................................`,
                fontSize: 15,
                decoration: ``,
                alignment: `left`,
                margin: [0, 0, 0, 0],
                pageBreak: ``,
                decorationColor: ``,
              },
            ],
          },
          {
            fontSize: 15,
            text: `ปี`,
            color: ``,
            bold: false,
            width: `auto`,
            decorationStyle: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            decoration: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            pageBreak: ``,
            width: `auto`,
            decorationColor: ``,
            bold: false,
            fontSize: 15,
            decoration: ``,
            decorationStyle: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            text: `เลขประจำตัวบัตรประชาชน `,
          },
          {
            alignment: `left`,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            text: ``,
            pageBreak: ``,
            decoration: ``,
            color: ``,
            width: 5,
            fontSize: 15,
            bold: false,
            decorationStyle: ``,
          },
          {
            stack: [
              {
                color: ``,
                fontSize: 15,
                preserveLeadingSpaces: true,
                text:
                  props.items?.citizen_no === "" ||
                  props.items?.citizen_no === "Non citizen identity"
                    ? " "
                    : props.items?.citizen_no,
                pageBreak: ``,
                alignment: `left`,
                width: `auto`,
                decoration: ``,
                margin: [0, 0, 0, -15],
                bold: false,
                decorationStyle: ``,
                decorationColor: ``,
              },
              {
                decoration: ``,
                bold: false,
                alignment: `left`,
                text: `......................................................................................................................................................................`,
                pageBreak: ``,
                decorationColor: ``,
                fontSize: 15,
                preserveLeadingSpaces: true,
                color: ``,
                width: `auto`,
                decorationStyle: ``,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            width: `auto`,
            pageBreak: ``,
            fontSize: 15,
            decorationStyle: ``,
            text: `ใบสำคัญประจำตัวคนต่างด้าวเลขที่ `,
            bold: false,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            color: ``,
            alignment: `left`,
          },
          {
            pageBreak: ``,
            fontSize: 15,
            decoration: ``,
            width: 5,
            bold: false,
            decorationStyle: ``,
            alignment: `left`,
            decorationColor: ``,
            text: ``,
            preserveLeadingSpaces: true,
            color: ``,
          },
          {
            stack: [
              {
                alignment: `left`,
                bold: false,
                decoration: ``,
                margin: [0, 0, 0, -15],
                text: props.items?.citizen_no
                  ? " "
                  : props.items?.alien_no === "" || props.items?.alien_no === "Non citizen identity"
                  ? " "
                  : props.items?.alien_no,
                preserveLeadingSpaces: true,
                width: `auto`,
                decorationStyle: ``,
                color: ``,
                decorationColor: ``,
                fontSize: 15,
                pageBreak: ``,
              },
              {
                margin: [0, 0, 0, 0],
                decorationColor: ``,
                color: ``,
                pageBreak: ``,
                text: `...........................................................................................................................................................`,
                fontSize: 15,
                decoration: ``,
                alignment: `left`,
                bold: false,
                decorationStyle: ``,
                width: `auto`,
                preserveLeadingSpaces: true,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            decorationColor: ``,
            decorationStyle: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            text: `อื่นๆ เช่น หนังสือเดินทางเลขที่  `,
            bold: false,
            fontSize: 15,
            decoration: ``,
            pageBreak: ``,
            width: `auto`,
          },
          {
            text: ``,
            fontSize: 15,
            decorationStyle: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            width: 5,
            color: ``,
            alignment: `left`,
            decoration: ``,
            bold: false,
            pageBreak: ``,
          },
          {
            stack: [
              {
                color: ``,
                text: props.items?.citizen_no
                  ? " "
                  : props.items?.alien_no
                  ? " "
                  : props.items?.citizen_passport === "" ||
                    props.items?.citizen_passport === "Non citizen identity"
                  ? " "
                  : props.items?.citizen_passport,
                pageBreak: ``,
                decorationColor: ``,
                margin: [0, 0, 0, -15],
                fontSize: 15,
                decoration: ``,
                bold: false,
                alignment: `left`,
                width: `auto`,
                preserveLeadingSpaces: true,
                decorationStyle: ``,
              },
              {
                decoration: ``,
                width: `auto`,
                fontSize: 15,
                text: `................................................................................................................................................................`,
                bold: false,
                margin: [0, 0, 0, 0],
                decorationColor: ``,
                color: ``,
                decorationStyle: ``,
                pageBreak: ``,
                preserveLeadingSpaces: true,
                alignment: `left`,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            text: `วันออกบัตร`,
            decoration: ``,
            decorationStyle: ``,
            pageBreak: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            bold: false,
            fontSize: 15,
            alignment: `left`,
            width: `auto`,
          },
          {
            decorationStyle: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            color: ``,
            decorationColor: ``,
            fontSize: 15,
            bold: false,
            width: 5,
            text: ` `,
            decoration: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
          },
          {
            alignment: `center`,
            width: `auto`,
            fontSize: 15,
            preserveLeadingSpaces: true,
            text: `..................................................................................`,
            decorationColor: ``,
            decoration: ``,
            color: ``,
            bold: false,
            pageBreak: ``,
            decorationStyle: ``,
          },
          {
            fontSize: 15,
            pageBreak: ``,
            decorationStyle: ``,
            width: 10,
            text: ``,
            decorationColor: ``,
            color: ``,
            preserveLeadingSpaces: true,
            bold: false,
            decoration: ``,
            alignment: `left`,
          },
          {
            preserveLeadingSpaces: true,
            pageBreak: ``,
            width: `auto`,
            color: ``,
            bold: false,
            decorationColor: ``,
            fontSize: 15,
            decoration: ``,
            decorationStyle: ``,
            text: `วันหมดอายุ`,
            alignment: `left`,
          },
          {
            text: ``,
            fontSize: 15,
            decorationColor: ``,
            color: ``,
            width: 5,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decoration: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            decorationStyle: ``,
            pageBreak: ``,
          },
          {
            color: ``,
            decorationStyle: ``,
            width: `auto`,
            bold: false,
            alignment: `center`,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decoration: ``,
            text: `.....................................................................................`,
            decorationColor: ``,
            pageBreak: ``,
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decorationStyle: ``,
            color: ``,
            decorationColor: ``,
            width: `auto`,
            bold: false,
            decoration: ``,
            text: `ออกให้โดย `,
            pageBreak: ``,
          },
          {
            width: 5,
            color: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decorationStyle: ``,
            bold: false,
            text: ` `,
            decoration: ``,
            pageBreak: ``,
            fontSize: 15,
            alignment: `left`,
          },
          {
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            pageBreak: ``,
            bold: false,
            text: `.................................................................................................................................................................................................`,
            preserveLeadingSpaces: true,
            fontSize: 15,
            color: ``,
            width: `auto`,
            alignment: `center`,
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            pageBreak: ``,
            bold: false,
            decorationColor: ``,
            decorationStyle: ``,
            width: `auto`,
            margin: [0, 0, 0, 0],
            decoration: ``,
            alignment: `left`,
            color: ``,
            text: `ที่อยู่เลขที่`,
            fontSize: 15,
          },
          {
            text: ` `,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            decorationStyle: ``,
            fontSize: 15,
            alignment: `left`,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            color: ``,
            width: 5,
            decoration: ``,
          },
          {
            stack: [
              {
                bold: false,
                decorationStyle: ``,
                color: ``,
                text:
                  props.items?.formAddress?.haveAddress === true
                    ? props.items?.formAddress?.no !== ""
                      ? props.items?.formAddress?.no
                      : " "
                    : " ",
                decoration: ``,
                decorationColor: ``,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
                fontSize: 15,
                width: `auto`,
                pageBreak: ``,
                alignment: `center`,
              },
              {
                margin: [0, 0, 0, 0],
                color: ``,
                alignment: `left`,
                decorationColor: ``,
                decorationStyle: ``,
                fontSize: 15,
                text: `....................................`,
                preserveLeadingSpaces: true,
                width: `auto`,
                bold: false,
                decoration: ``,
                pageBreak: ``,
              },
            ],
          },
          {
            bold: false,
            text: ` หมู่`,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            width: `auto`,
            pageBreak: ``,
            decoration: ``,
            fontSize: 15,
            color: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
          },
          {
            text: ` `,
            preserveLeadingSpaces: true,
            fontSize: 15,
            alignment: `left`,
            width: 5,
            pageBreak: ``,
            color: ``,
            decorationStyle: ``,
            decoration: ``,
            bold: false,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
          },
          {
            stack: [
              {
                bold: false,
                color: ``,
                pageBreak: ``,
                fontSize: 15,
                decorationColor: ``,
                width: `auto`,
                text:
                  props.items?.formAddress?.haveAddress === true
                    ? props.items?.formAddress?.town !== ""
                      ? props.items?.formAddress?.town
                      : " "
                    : " ",
                decoration: ``,
                alignment: `left`,
                decorationStyle: ``,
                margin: [7, 0, 0, -15],
                preserveLeadingSpaces: true,
              },
              {
                pageBreak: ``,
                decorationColor: ``,
                text: `...........`,
                fontSize: 15,
                decorationStyle: ``,
                margin: [0, 0, 0, 0],
                bold: false,
                decoration: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                color: ``,
                width: `auto`,
              },
            ],
          },
          {
            decoration: ``,
            margin: [-60, 0, 0, 0],
            decorationColor: ``,
            decorationStyle: ``,
            bold: false,
            fontSize: 15,
            color: ``,
            text: `ซอย`,
            width: `auto`,
            preserveLeadingSpaces: true,
            alignment: `left`,
            pageBreak: ``,
          },
          {
            preserveLeadingSpaces: true,
            bold: false,
            decoration: ``,
            text: ` `,
            color: ``,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            alignment: `left`,
            width: 5,
            decorationStyle: ``,
            pageBreak: ``,
            fontSize: 15,
          },
          {
            stack: [
              {
                color: ``,
                width: `auto`,
                decorationStyle: ``,
                bold: false,
                text:
                  props.items?.formAddress?.haveAddress === true
                    ? props.items?.formAddress?.street !== ""
                      ? props.items?.formAddress?.street
                      : " "
                    : " ",
                margin: [0, 0, 0, -15],
                preserveLeadingSpaces: true,
                decoration: ``,
                pageBreak: ``,
                decorationColor: ``,
                fontSize: 15,
                alignment: `center`,
              },
              {
                fontSize: 15,
                preserveLeadingSpaces: true,
                color: ``,
                decorationStyle: ``,
                bold: false,
                text: `...................................`,
                pageBreak: ``,
                decorationColor: ``,
                width: `auto`,
                margin: [0, 0, 0, 0],
                decoration: ``,
                alignment: `left`,
              },
            ],
          },
          {
            decorationStyle: ``,
            text: `ถนน`,
            alignment: `left`,
            decoration: ``,
            bold: false,
            decorationColor: ``,
            pageBreak: ``,
            width: `auto`,
            preserveLeadingSpaces: true,
            color: ``,
            margin: [0, 0, 0, 0],
            fontSize: 15,
          },
          {
            decorationColor: ``,
            text: ` `,
            alignment: `left`,
            width: 5,
            bold: false,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            fontSize: 15,
            color: ``,
            decoration: ``,
          },
          {
            stack: [
              {
                decorationColor: ``,
                preserveLeadingSpaces: true,
                color: ``,
                margin: [0, 0, 0, -15],
                pageBreak: ``,
                decoration: ``,
                bold: false,
                fontSize: 15,
                alignment: `center`,
                width: `auto`,
                decorationStyle: ``,
                text:
                  props.items?.formAddress?.haveAddress === true
                    ? props.items?.formAddress?.road !== ""
                      ? props.items?.formAddress?.road
                      : " "
                    : " ",
              },
              {
                fontSize: 15,
                width: `auto`,
                alignment: `left`,
                bold: false,
                margin: [0, 0, 0, 0],
                text: `...................................`,
                preserveLeadingSpaces: true,
                decorationStyle: ``,
                pageBreak: ``,
                color: ``,
                decorationColor: ``,
                decoration: ``,
              },
            ],
          },
          {
            decorationStyle: ``,
            pageBreak: ``,
            decoration: ``,
            alignment: `left`,
            bold: false,
            width: `auto`,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            text: `ตำบล`,
            preserveLeadingSpaces: true,
            color: ``,
          },
          {
            pageBreak: ``,
            width: 5,
            bold: false,
            margin: [0, 0, 0, 0],
            decoration: ``,
            alignment: `left`,
            decorationColor: ``,
            color: ``,
            text: ` `,
            decorationStyle: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
          },
          {
            stack: [
              {
                alignment: `center`,
                text:
                  props.items?.formAddress?.haveAddress === true
                    ? props.items?.formAddress?.city !== ""
                      ? props.items?.formAddress?.city
                      : " "
                    : " ",
                decorationStyle: ``,
                color: ``,
                margin: [0, 0, 0, -15],
                width: `auto`,
                decorationColor: ``,
                fontSize: 15,
                bold: false,
                decoration: ``,
                preserveLeadingSpaces: true,
                pageBreak: ``,
              },
              {
                fontSize: 15,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
                decorationStyle: ``,
                color: ``,
                pageBreak: ``,
                text: `....................................`,
                width: `auto`,
                decorationColor: ``,
                decoration: ``,
                bold: false,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            color: ``,
            width: `auto`,
            decorationColor: ``,
            fontSize: 15,
            pageBreak: ``,
            text: `อำเภอ`,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            decoration: ``,
            bold: false,
            alignment: `left`,
          },
          {
            color: ``,
            preserveLeadingSpaces: true,
            bold: false,
            decoration: ``,
            pageBreak: ``,
            fontSize: 15,
            width: 5,
            decorationColor: ``,
            alignment: `left`,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            text: ` `,
          },
          {
            stack: [
              {
                alignment: `center`,
                bold: false,
                width: `auto`,
                fontSize: 15,
                preserveLeadingSpaces: true,
                decorationStyle: ``,
                pageBreak: ``,
                decorationColor: ``,
                text:
                  props.items?.formAddress?.haveAddress === true
                    ? props.items?.formAddress?.district !== ""
                      ? props.items?.formAddress?.district
                      : " "
                    : " ",
                decoration: ``,
                color: ``,
                margin: [0, 0, 0, -15],
              },
              {
                decoration: ``,
                fontSize: 15,
                text: `.....................................................`,
                preserveLeadingSpaces: true,
                color: ``,
                pageBreak: ``,
                decorationStyle: ``,
                alignment: `left`,
                decorationColor: ``,
                margin: [0, 0, 0, 0],
                width: `auto`,
                bold: false,
              },
            ],
          },
          {
            decorationColor: ``,
            alignment: `left`,
            fontSize: 15,
            pageBreak: ``,
            decorationStyle: ``,
            decoration: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            width: `auto`,
            text: `จังหวัด`,
            color: ``,
            bold: false,
          },
          {
            margin: [0, 0, 0, 0],
            width: 5,
            decoration: ``,
            pageBreak: ``,
            decorationColor: ``,
            decorationStyle: ``,
            color: ``,
            fontSize: 15,
            text: ` `,
            preserveLeadingSpaces: true,
            bold: false,
            alignment: `left`,
          },
          {
            stack: [
              {
                fontSize: 15,
                color: ``,
                bold: false,
                preserveLeadingSpaces: true,
                width: `auto`,
                decoration: ``,
                decorationStyle: ``,
                alignment: `center`,
                pageBreak: ``,
                margin: [0, 0, 0, -15],
                decorationColor: ``,
                text:
                  props.items?.formAddress?.haveAddress === true
                    ? props.items?.formAddress?.province !== ""
                      ? props.items?.formAddress?.province
                      : " "
                    : " ",
              },
              {
                alignment: `left`,
                pageBreak: ``,
                color: ``,
                decorationColor: ``,
                width: `auto`,
                fontSize: 15,
                preserveLeadingSpaces: true,
                text: `.....................................................`,
                decoration: ``,
                margin: [0, 0, 0, 0],
                bold: false,
                decorationStyle: ``,
              },
            ],
          },
          {
            pageBreak: ``,
            color: ``,
            decorationColor: ``,
            text: `รหัสไปรษณีย์`,
            bold: false,
            decoration: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            width: `auto`,
            fontSize: 15,
            decorationStyle: ``,
          },
          {
            bold: false,
            margin: [0, 0, 0, 0],
            decoration: ``,
            text: ` `,
            fontSize: 15,
            color: ``,
            width: 5,
            pageBreak: ``,
            decorationStyle: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
          },
          {
            stack: [
              {
                width: `auto`,
                alignment: `center`,
                margin: [0, 0, 0, -15],
                fontSize: 15,
                text:
                  props.items?.formAddress?.haveAddress === true
                    ? props.items?.formAddress?.zipcode !== ""
                      ? props.items?.formAddress?.zipcode
                      : " "
                    : " ",
                bold: false,
                decorationColor: ``,
                decorationStyle: ``,
                pageBreak: ``,
                preserveLeadingSpaces: true,
                color: ``,
                decoration: ``,
              },
              {
                alignment: `left`,
                decorationColor: ``,
                preserveLeadingSpaces: true,
                decorationStyle: ``,
                bold: false,
                fontSize: 15,
                color: ``,
                text: `......................................................`,
                width: `auto`,
                decoration: ``,
                pageBreak: ``,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            pageBreak: ``,
            decorationColor: ``,
            bold: false,
            fontSize: 15,
            preserveLeadingSpaces: true,
            width: `auto`,
            decorationStyle: ``,
            alignment: `left`,
            text: `เบอร์โทรศัพท์ `,
            decoration: ``,
          },
          {
            decoration: ``,
            alignment: `left`,
            bold: false,
            text: ``,
            color: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            width: 5,
            pageBreak: ``,
            fontSize: 15,
          },
          {
            stack: [
              {
                margin: [0, 0, 0, -15],
                preserveLeadingSpaces: true,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                pageBreak: ``,
                alignment: `left`,
                fontSize: 15,
                text:
                  props.items?.formAddress?.haveAddress === true
                    ? props.items?.present_address?.tel_mobile
                    : "  ",
                decorationStyle: ``,
                decoration: ``,
                bold: false,
              },
              {
                pageBreak: ``,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                color: ``,
                decoration: ``,
                alignment: `left`,
                margin: [0, 0, 0, 0],
                fontSize: 15,
                text: `............................................................................................................................................................................................`,
                width: `auto`,
                decorationStyle: ``,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            preserveLeadingSpaces: true,
            decoration: ``,
            width: `auto`,
            text: `ในฐานะ: `,
            color: ``,
            decorationStyle: ``,
            bold: false,
            alignment: `left`,
            decorationColor: ``,
            pageBreak: ``,
          },
          {
            color: ``,
            decorationColor: ``,
            decoration: ``,
            text: ``,
            fontSize: 15,
            decorationStyle: ``,
            width: 5,
            bold: false,
            preserveLeadingSpaces: true,
            alignment: `left`,
            pageBreak: ``,
          },
          {
            pageBreak: ``,
            decoration: ``,
            decorationColor: ``,
            alignment: `center`,
            text: `....................................................................................................................................................................................................`,
            preserveLeadingSpaces: true,
            color: ``,
            fontSize: 15,
            decorationStyle: ``,
            bold: false,
            width: `auto`,
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            color: ``,
            pageBreak: ``,
            alignment: `left`,
            decoration: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            text: ``,
            bold: false,
            fontSize: 15,
            width: 30,
          },
          {
            color: ``,
            width: `auto`,
            decorationColor: ``,
            pageBreak: ``,
            bold: false,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decoration: ``,
            fontSize: 15,
            text: `2. ข้าพเจ้าทราบเงื่อนไขและข้อตกลงในการใช้บริการ โครงการพัฒนาระเบียนสุขภาพส่วนบุคคล (Personal Health`,
            decorationStyle: ``,
          },
        ],
      },
      {
        color: ``,
        fontSize: 15,
        decoration: ``,
        bold: false,
        preserveLeadingSpaces: true,
        pageBreak: ``,
        alignment: `left`,
        decorationStyle: ``,
        text: `Record : PHRs) (โรงพยาบาลเซเปี้ยนซ์) และการให้คำยินยอมให้โรงพยาบาลเซเปี้ยนซ์ สามารถนำข้อมูลระดับบุคคลไปใช้ประโยชน์ ในการบริการ ดูแลสุขภาพและแลกเปลี่ยนข้อมูลระหว่างสถานพยาบาล อาทิการจัดเก็บข้อมูลการตรวจรักษา/การผ่าตัด/การใช้ยา/การตรวจรักษาทางรังสีและกระทำหัตถการ ทั้งนี้ รวมถึงความเสี่ยงผลข้างเคียงและภาวะแทรกซ้อนไว้ โดยวิธีการทางอิเล็กทรอนิกส์ เพื่อประโยชน์ในการดูแลสุขภาพของข้าพเจ้า`,
        decorationColor: ``,
        width: `auto`,
      },
      {
        columns: [
          {
            bold: false,
            color: ``,
            pageBreak: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decoration: ``,
            text: ``,
            fontSize: 15,
            alignment: `left`,
            width: 30,
          },
          {
            decorationColor: ``,
            fontSize: 15,
            bold: false,
            width: `auto`,
            color: ``,
            pageBreak: ``,
            decorationStyle: ``,
            decoration: ``,
            text: `3. ข้าพเจ้า `,
            alignment: `left`,
            preserveLeadingSpaces: true,
          },
          {
            color: ``,
            width: 5,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            bold: false,
            text: ``,
            decorationColor: ``,
            pageBreak: ``,
            fontSize: 15,
            alignment: `left`,
          },
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            preserveLeadingSpaces: true,
            alignment: `left`,
            bold: false,
            color: ``,
            decoration: ``,
            decorationColor: ``,
            width: 5,
            decorationStyle: ``,
            fontSize: 15,
            text: ``,
            pageBreak: ``,
          },
          {
            decorationColor: ``,
            width: `auto`,
            bold: false,
            decorationStyle: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            color: ``,
            alignment: `left`,
            pageBreak: ``,
            text: `ยินยอมเข้าสู่โครงการพัฒนาระบบระเบียนสุขภาพส่วนบุคคล (PHRs)`,
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            decorationStyle: ``,
            alignment: `left`,
            color: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationColor: ``,
            text: ``,
            width: 76,
            fontSize: 15,
            bold: false,
          },
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            color: ``,
            pageBreak: ``,
            decoration: ``,
            width: 5,
            decorationColor: ``,
            bold: false,
            text: ``,
            alignment: `left`,
            decorationStyle: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
          },
          {
            pageBreak: ``,
            fontSize: 15,
            color: ``,
            decorationStyle: ``,
            bold: false,
            width: `auto`,
            preserveLeadingSpaces: true,
            text: `ยินยอมให้สถานพยาบาลเปิดเผยข้อมูล/ส่งข้อมูลทางอิเล็กทรอนิกส์ (สำเนาข้อมูล)`,
            alignment: `left`,
            decorationColor: ``,
            decoration: ``,
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            width: 76,
            fontSize: 15,
            bold: false,
            color: ``,
            decoration: ``,
            pageBreak: ``,
            alignment: `left`,
            text: `                   `,
            decorationColor: ``,
          },
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            bold: false,
            fontSize: 15,
            text: ``,
            width: 5,
            decorationStyle: ``,
            pageBreak: ``,
            alignment: `left`,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            color: ``,
            decoration: ``,
          },
          {
            text: `ยินยอมให้สถานพยาบาลนำข้อมูลทางอิเล็กทรอนิกส์ (สำเนาข้อมูล) ใช้ในการศึกษาและวิจัยเพื่อประโยชน์`,
            preserveLeadingSpaces: true,
            color: ``,
            pageBreak: ``,
            decorationColor: ``,
            alignment: `left`,
            bold: false,
            decorationStyle: ``,
            width: `auto`,
            fontSize: 15,
            decoration: ``,
          },
        ],
      },
      {
        columns: [
          {
            text: ``,
            decorationStyle: ``,
            fontSize: 15,
            width: 100,
            decoration: ``,
            color: ``,
            bold: false,
            alignment: `left`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationColor: ``,
          },
          {
            fontSize: 15,
            decorationStyle: ``,
            pageBreak: ``,
            width: `auto`,
            alignment: `left`,
            decoration: ``,
            bold: false,
            preserveLeadingSpaces: true,
            text: `ทางการแพทย์และสาธารณสุขได้`,
            decorationColor: ``,
            color: ``,
          },
        ],
      },
      {
        decoration: ``,
        color: ``,
        decorationStyle: ``,
        preserveLeadingSpaces: true,
        decorationColor: ``,
        fontSize: 15,
        bold: false,
        text: `เพื่อการรักษาพยาบาลแก่ผู้ประกอบวิชาชีพด้านสาธารณสุข (บุคลากรที่ได้รับอนุญาต) ให้บริการรักษาได้ ในการรักษาพยาบาลและ`,
        width: `auto`,
        pageBreak: ``,
        alignment: `left`,
      },
      {
        decorationStyle: ``,
        decoration: ``,
        text: `สถานพยาบาลที่ให้บริการรักษาได้`,
        fontSize: 15,
        decorationColor: ``,
        pageBreak: ``,
        alignment: `left`,
        color: ``,
        preserveLeadingSpaces: true,
        bold: false,
        width: `auto`,
      },
      {
        columns: [
          {
            pageBreak: ``,
            text: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            width: 76,
            bold: false,
            decoration: ``,
            decorationColor: ``,
            alignment: `left`,
            color: ``,
            fontSize: 15,
          },
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            alignment: `left`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decorationStyle: ``,
            color: ``,
            decoration: ``,
            text: ``,
            width: 5,
            decorationColor: ``,
            bold: false,
          },
          {
            bold: false,
            decorationStyle: ``,
            color: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationColor: ``,
            width: `auto`,
            fontSize: 15,
            alignment: `left`,
            pageBreak: ``,
            text: `ไม่ยินยอม* ให้สถานพยาบาลเปิดเผยข้อมูล/ส่งข้อมูลทางอิเล็กทรอนิกส์ (สำเนาข้อมูล)`,
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            text: `		       `,
            bold: false,
            decorationColor: ``,
            fontSize: 15,
            pageBreak: ``,
            decorationStyle: ``,
            color: ``,
            decoration: ``,
            alignment: `left`,
            width: 76,
          },
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            decorationStyle: ``,
            text: ``,
            pageBreak: ``,
            alignment: `left`,
            bold: false,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            color: ``,
            width: 5,
            decoration: ``,
          },
          {
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            width: `auto`,
            decorationColor: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            text: `ไม่ยินยอม* ให้สถานพยาบาลนำข้อมูลทางอิเล็กทรอนิกส์ (สำเนาข้อมูล) ใช้ในการศึกษาและวิจัยเพื่อ`,
            color: ``,
            bold: false,
            decoration: ``,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            alignment: `left`,
            width: 100,
            decorationColor: ``,
            decorationStyle: ``,
            fontSize: 15,
            text: ``,
            color: ``,
            decoration: ``,
          },
          {
            decorationColor: ``,
            pageBreak: ``,
            fontSize: 15,
            decorationStyle: ``,
            color: ``,
            bold: false,
            text: `ประโยชน์ทางการแพทย์และสาธารณสุขได้`,
            width: `auto`,
            decoration: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        bold: false,
        pageBreak: ``,
        text: `เพื่อการรักษาพยาบาลแก่ผู้ประกอบวิชาชีพด้านสาธารณสุข (บุคลากรที่ได้รับอนุญาต) ให้บริการรักษาได้ ในการรักษาพยาบาล`,
        preserveLeadingSpaces: true,
        decorationColor: ``,
        alignment: `left`,
        decorationStyle: ``,
        width: `auto`,
        decoration: ``,
        color: ``,
        fontSize: 15,
      },
      {
        text: `และสถานพยาบาลที่ให้บริการรักษาได้`,
        fontSize: 15,
        decorationStyle: ``,
        bold: false,
        width: `auto`,
        pageBreak: `after`,
        color: ``,
        decoration: ``,
        preserveLeadingSpaces: true,
        alignment: `left`,
        decorationColor: ``,
      },
      {
        columns: [
          {
            decorationStyle: ``,
            decoration: ``,
            fontSize: 15,
            color: ``,
            preserveLeadingSpaces: true,
            bold: false,
            pageBreak: ``,
            width: 30,
            decorationColor: ``,
            alignment: `left`,
            text: ``,
          },
          {
            width: `auto`,
            alignment: `left`,
            decorationColor: ``,
            decorationStyle: ``,
            bold: false,
            text: `4. หากสถานพยาบาลนำข้อมูลสุขภาพส่วนบุคคลของข้าพเจ้าไปใช้เพื่อการอื่นใดอันไม่เป็นประโยชน์ต่อการรักษาพยาบาล`,
            decoration: ``,
            pageBreak: ``,
            color: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
          },
        ],
      },
      {
        decorationStyle: ``,
        text: `ต้องได้รับความยินยอมจากข้าพเจ้าเว้นแต่การเปิดเผยตามที่กฎหมายบัญญัติหรือมีคำสั่งศาลให้เปิดเผย`,
        color: ``,
        fontSize: 15,
        preserveLeadingSpaces: true,
        bold: false,
        decorationColor: ``,
        width: `auto`,
        decoration: ``,
        alignment: `left`,
        pageBreak: ``,
      },
      {
        columns: [
          {
            decorationStyle: ``,
            width: 30,
            pageBreak: ``,
            fontSize: 15,
            color: ``,
            text: ``,
            bold: false,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decoration: ``,
            decorationColor: ``,
          },
          {
            preserveLeadingSpaces: true,
            text: `5. ข้าพเจ้า สามารถยกเลิกความยินยอมได้ แต่ไม่มีผลลบล้างความยินยอมและผลแห่งความยินยอมซึ่งได้กระทำไปแล้วก่อน`,
            fontSize: 15,
            bold: false,
            decorationColor: ``,
            decoration: ``,
            color: ``,
            decorationStyle: ``,
            width: `auto`,
            alignment: `left`,
            pageBreak: ``,
          },
        ],
      },
      {
        width: `auto`,
        fontSize: 15,
        alignment: `left`,
        decorationColor: ``,
        bold: false,
        color: ``,
        decoration: ``,
        text: `หน้านั้น โดยการยกเลิกความยินยอมต้องมีหลักฐานเป็นลายลักษณ์อักษร`,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        pageBreak: ``,
      },
      {
        bold: false,
        decorationColor: ``,
        fontSize: 15,
        decoration: ``,
        text: ` `,
        decorationStyle: ``,
        width: `auto`,
        preserveLeadingSpaces: true,
        pageBreak: ``,
        color: ``,
        alignment: `left`,
      },
      {
        columns: [
          {
            fontSize: 15,
            bold: false,
            pageBreak: ``,
            decorationStyle: ``,
            color: ``,
            decoration: ``,
            alignment: `left`,
            text: ``,
            preserveLeadingSpaces: true,
            width: 300,
            decorationColor: ``,
          },
          {
            text: `ลงชื่อ.................................................................	`,
            bold: false,
            decoration: ``,
            color: ``,
            pageBreak: ``,
            decorationColor: ``,
            alignment: `center`,
            fontSize: 15,
            decorationStyle: ``,
            width: 200,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            bold: false,
            width: 300,
            color: ``,
            decoration: ``,
            alignment: `left`,
            text: ``,
            decorationColor: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
          },
          {
            decorationStyle: ``,
            decoration: ``,
            fontSize: 15,
            color: ``,
            width: 200,
            pageBreak: ``,
            decorationColor: ``,
            alignment: `center`,
            text: props.items?.full_name,
            bold: false,
            preserveLeadingSpaces: true,
          },
        ],
      },
    ],
    pageSize: `A4`,
    defaultStyle: {
      font: `THSarabunNew`,
    },
    pageOrientation: `portrait`,
    images: injectImages(props.items?.haveHeader),
  };
}
