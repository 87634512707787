import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import {
  Form,
  Input,
  Button,
  Dimmer,
  Loader,
  FormTextArea
} from "semantic-ui-react";
import CardLayout from "../common/CardLayout";
import ErrorMessage from "../common/ErrorMessage";
import ModInfo from "../common/ModInfo";
import INFManager from "react-lib/apis/manager/INFManager";
import { INF } from "react-lib/configs/apis";
import { withInterceptor } from "react-lib/utils/hoc";
import moment from "moment";
import * as Util from "react-lib/utils";
import { useIntl } from "react-intl";
class CardOPDClaimResolve extends React.Component<
  any,
  {
    trigger: boolean;

    fiscal_year: string;
    payer_choices: [];
    coverage_choices: [];
    payer: string;
    coverage: string;
    invoiceNo: string;

    arData: { id: number; lot_no: string }[];
    btData: any[];
    btiData: any[];
    dsData: any[];
    dsiData: any[];
    osData: any[];
    osiData: any[];

    selectedAr: any;
    selectedBt: any;
    selectedBti: any;
    selectedDs: any;
    selectedDsi: any;
    selectedOs: any;
    selectedOsi: any;

    loading: boolean;
  }
> {
  constructor(props) {
    super(props);

    this.bilTransItem = React.createRef();
    this.dispensing = React.createRef();
    this.dispensingItem = React.createRef();
    this.operationService = React.createRef();
    this.operationServiceItem = React.createRef();
    this.INFManager = new INFManager();

    this.state = {
      trigger: true,
      fiscal_year: currentYear.toString(),
      payer_choices: [],
      coverage_choices: [],
      payer: "",
      coverage: "",
      invoiceNo: "",
      saveError: "",
      arData: [],
      selectedAr: {},
      btData: [],
      selectedBt: {},
      btiData: [],
      selectedBti: {},
      dsData: [],
      selectedDs: {},
      dsiData: [],
      selectedDsi: {},
      osData: [],
      selectedOs: {},
      osiData: [],
      selectedOsi: {},
      loading: false,
      openModError: false
    };
  }

  componentDidMount = () => {
    this.getInitialData();
  };

  getInitialData = async () => {
    const result = await axios
      .get("/apis/INF/payer-choice/?test=1")
      .catch(err => {
        console.log(err);
      });
    if (result) {
      const payer_choices = result.data.payer_choice.map(item => ({
        key: item.id,
        value: item.id,
        text: item.name
      }));
      const coverage_choices = result.data.coverage_choice.map(item => ({
        key: item.id,
        value: item.id,
        text: item.name
      }));
      this.setState({
        payer_choices: payer_choices,
        coverage_choices: coverage_choices
      });
    }
  };

  handleEvent = async ({
    message,
    params
  }: {
    message: string;
    params: any;
  }) => {
    if (message === "arTransactionFilter") {
      this.setState({
        selectedAr: {},
        arData: [],
        selectedBt: {},
        btData: [],
        selectedBti: {},
        btiData: [],
        dsData: [],
        dsiData: [],
        osData: [],
        osiData: []
      });
      this.setState({ loading: true });
      let params = {};
      if (this.state.fiscal_year) {
        params.year = this.state.fiscal_year;
      }
      if (this.state.coverage) {
        params.coverage = this.state.coverage;
      }
      if (this.state.payer) {
        params.payer = this.state.payer;
      }
      if (this.state.invoiceNo) {
        params.invoice_no = this.state.invoiceNo;
      }
      const result = await axios
        .get(INF.AR_TRANSACTION_FILTER, { params })
        .catch(err => {
          console.log(err);
        });
      this.setState({ loading: false });
      if (result) {
        this.setState({ arData: result.data });
      }
    } else if (message === "chooseAr") {
      console.log("in chooseAr");
      this.setState({
        selectedAr: params.value,
        selectedBt: {},
        selectedBti: {},
        btiData: [],
        dsData: [],
        dsiData: [],
        osData: [],
        osiData: []
      });
      this.setState({ loading: true });
      // const result = await axios.get(`/apis/INF/bill-transaction/?ar_transaction=${params.value.id}&disable_pagination=true`).catch(err => {
      const result = await axios
        .get(
          `/apis/INF/bill-transaction-filter/?ar_transaction=${params.value.id}`
        )
        .catch(err => {
          console.log(err);
        });
      this.setState({ loading: false });
      if (result) {
        result.data = result.data.map((item, index) => ({
          ...item,
          date: item.dt_tran.split("T")[0]
        }));
        console.log(result);
        // this.setState({ btData: result.data.items })
        this.setState({ btData: result.data });
      }
    } else if (message === "unchooseAr") {
      console.log("in unchooseAr");
      this.setState({
        selectedAr: {},
        selectedBt: {},
        btData: [],
        selectedBti: {},
        btiData: [],
        dsData: [],
        dsiData: [],
        osData: [],
        osiData: []
      });
    } else if (message === "chooseBt") {
      console.log("in chooseBt");
      this.setState({ selectedBt: params.value, loading: true });
      const result = await axios
        .get(
          `/apis/INF/bill-transaction-details/?bill_transaction=${params.value.id}`
        )
        .catch(err => {
          console.log(err);
        });
      this.setState({ loading: false });
      if (result) {
        result.data.bt_items = result.data.bt_items.map((item, index) => ({
          ...item,
          id: item.bti_id,
          type:
            item.os_id && !item.ds_id
              ? "Operation Service"
              : !item.os_id && item.ds_id
              ? "Dispensing"
              : item.os_id && item.ds_id
              ? "Both"
              : "None",
          ref_id:
            item.os_id && !item.ds_id
              ? item.os_id
              : !item.os_id && item.ds_id
              ? item.ds_id
              : item.os_id && item.ds_id
              ? `OS : ${item.os_id} / DS : ${item.ds_id}`
              : "-"
        }));
        console.log(result);
        this.setState({
          btiData: result.data.bt_items,
          dsData: result.data.dispensing,
          dsiData: result.data.dispensing_items,
          osData: result.data.operationservice,
          osiData: result.data.operationservice_items
        });
      }
    } else if (message === "unchooseBt") {
      console.log("in unchooseBt");
      this.setState({
        selectedBt: {},
        selectedBti: {},
        btiData: [],
        dsData: [],
        dsiData: [],
        osData: [],
        osiData: []
      });
    } else if (message === "chooseBti") {
      console.log("in chooseBti");
      console.log(params.value);
      this.setState({ selectedBti: params.value });
    } else if (message === "unchooseBti") {
      console.log("in unchooseBti");
      this.setState({ selectedBti: {} });
    }
  };

  handleConfirm = async () => {
    let btId = this.state.selectedBt.id;
    const data = this.getDataForSaveAndConfirm();
    this.setState({ loading: true, saveError: "" });
    const [
      res,
      error,
      network
    ] = await this.INFManager.putBillTransactionUpdateStatus(btId, data);
    this.setState({ loading: false });
    console.log(error, "error");
    if (error && network.response.status !== 500) {
      let err = error;
      if (error.length > 200) {
        err = `${network.response.status}: ${network.response.statusText}`;
      }
      this.setState({ saveError: err, openModError: true });
    }
  };

  handleSave = async () => {
    let btId = this.state.selectedBt.id;
    const data = this.getDataForSaveAndConfirm();
    this.setState({ loading: true, saveError: "" });
    const [res, error, network] = await this.INFManager.putBillTransaction(
      btId,
      data
    );
    this.setState({ loading: false });
    if (error && network.response.status !== 500) {
      let err = error;
      if (error.length > 200) {
        err = `${network.response.status}: ${network.response.statusText}`;
      }
      this.setState({ saveError: err, openModError: true });
    }
  };

  getDataForSaveAndConfirm = () => {
    let btItems = [this.state.selectedBti];
    let dispensing = this.state.dsData.filter(
      item => item.id === this.state.selectedBti.ds_id
    );
    let dispensingItems = this.state.dsiData.filter(item => {
      const dispensing_list = this.state.dsData
        .filter(item => item.id === this.state.selectedBti.ds_id)
        .map(item => item.id);
      return dispensing_list.includes(item.dispensing_ref_id);
    });
    let operationService = this.state.osData.filter(
      item => item.id === this.state.selectedBti.os_id
    );
    let operationServiceItems = this.state.osiData.filter(item => {
      const operationservice_list = this.state.osData
        .filter(item => item.id === this.state.selectedBti.os_id)
        .map(item => item.id);
      return operationservice_list.includes(item.operation_service_ref_id);
    });
    if (this.bilTransItem && this.bilTransItem.getData) {
      btItems = this.bilTransItem.getData();
    }
    if (this.dispensing && this.dispensing.getData) {
      dispensing = this.dispensing.getData();
    }
    if (this.dispensingItem && this.dispensingItem.getData) {
      dispensingItems = this.dispensingItem.getData();
    }
    if (this.operationService && this.operationService.getData) {
      operationService = this.operationService.getData();
    }
    if (this.operationServiceItem && this.operationServiceItem.getData) {
      operationServiceItems = this.operationServiceItem.getData();
    }

    //--------------------- set edit item back to state ------------------------
    const { dsData, dsiData, osData, osiData } = this.state;
    //bt
    let btData = this.state.selectedBti;
    if (Array.isArray(btItems) && btItems.length > 0) {
      btData = btItems[0];
    }
    // ds
    let newDsData = [...dsData];
    for (let item of dispensing) {
      let index = newDsData.findIndex(ds => ds.id === item.id);
      if (index > -1) {
        newDsData[index] = item;
      }
    }
    // dsi
    let newDsiData = [...dsiData];
    for (let item of dispensingItems) {
      let index = newDsiData.findIndex(dsi => dsi.id === item.id);
      if (index > -1) {
        newDsiData[index] = item;
      }
    }
    // os
    let newOsData = [...osData];
    for (let item of operationService) {
      let index = newOsData.findIndex(os => os.id === item.id);
      if (index > -1) {
        newOsData[index] = item;
      }
    }
    // osi
    let newOsiData = [...osiData];
    for (let item of operationServiceItems) {
      let index = newOsiData.findIndex(osi => osi.id === item.id);
      if (index > -1) {
        newOsiData[index] = item;
      }
    }

    this.setState({
      selectedBti: btData,
      dsData: newDsData,
      dsiData: newDsiData,
      osData: newOsData,
      osiData: newOsiData
    });

    // -------------------------------------------------------------------------------

    let data = {
      bt_items: btItems,
      dispensing,
      dispensing_items: dispensingItems,
      operationservice: operationService,
      operationservice_items: operationServiceItems
    };
    return data;
  };

  render() {
    return (
      <CardLayout
        titleText="แก้ไขรายการติด C"
        closeable={false}
        loading={this.state.loading}
      >
        <ModInfo
          type="error"
          open={this.state.openModError}
          alertText={this.state.saveError}
          onClose={() => this.setState({ openModError: false })}
          onApprove={() => this.setState({ openModError: false })}
        />
        <Form>
          <Form.Group inline>
            <Form.Select
              width={2}
              label="ปี"
              selection
              options={FISCAL_YEAR}
              value={this.state.fiscal_year}
              onChange={(e, v) =>
                this.setState({ fiscal_year: v.value as string })
              }
              clearable
            />
            <Form.Select
              width={5}
              label="Payer"
              selection
              options={this.state.payer_choices}
              value={this.state.payer}
              onChange={(e, { value }) => {
                this.setState({ payer: value });
              }}
              clearable
            />
            <Form.Select
              width={5}
              label="Coverage"
              selection
              options={this.state.coverage_choices}
              value={this.state.coverage}
              onChange={(e, { value }) => {
                this.setState({ coverage: value });
              }}
              clearable
            />
            <Form.Field
              width={5}
              control="input"
              label="Invoice No."
              value={this.state.invoiceNo}
              onChange={e => {
                const { value } = e.target;
                this.setState({ invoiceNo: value });
              }}
            />
            <Form.Button
              type="button"
              content="ค้นหา"
              color="blue"
              onClick={e => {
                this.handleEvent({
                  message: "arTransactionFilter",
                  params: {}
                });
              }}
            />
          </Form.Group>
        </Form>
        {this.state.arData.length > 0 && (
          <DataTable
            title={"งวดส่งเบิก"}
            height={300}
            filterC={true}
            data={this.state.arData}
            columns={arTableColumns}
            onEvent={this.handleEvent}
            selectedItem={this.state.selectedAr}
            itemName={"Ar"}
          />
        )}

        {this.state.btData.length > 0 && (
          <DataTable
            title={"รายการ Invoice"}
            height={300}
            filterC={true}
            data={this.state.btData}
            columns={btTableColumns}
            onEvent={this.handleEvent}
            selectedItem={this.state.selectedBt}
            itemName={"Bt"}
          />
        )}

        {this.state.btiData.length > 0 && (
          <DataTable
            title={"Bill Transaction Items"}
            height={300}
            data={this.state.btiData}
            columns={btiTableColumns}
            onEvent={this.handleEvent}
            selectedItem={this.state.selectedBti}
            itemName={"Bti"}
          />
        )}
        <ErrorMessage error={this.state.saveError} />
        {!isEmpty(this.state.selectedBti) && (
          <>
            <br />
            <DetailTable
              ref={ref => (this.bilTransItem = ref)}
              idfield={"id"}
              fieldList={btiFieldList}
              fieldName={btiFieldName}
              title={"Bill Transction Item"}
              data={[this.state.selectedBti]}
              itemName={"Bti"}
            />
          </>
        )}

        {!isEmpty(this.state.selectedBti) && this.state.selectedBti.ds_id && (
          <div>
            <br />
            <DetailTable
              idfield={"id"}
              fieldList={DispFieldList}
              fieldName={DispFieldName}
              title={"Dispensing"}
              ref={ref => (this.dispensing = ref)}
              data={this.state.dsData.filter(
                item => item.id === this.state.selectedBti.ds_id
              )}
              itemName={"Ds"}
            />
            <br />
            <DetailTable
              idfield={"id"}
              fieldList={DispItemFieldList}
              title={"Dispensing Item"}
              ref={ref => (this.dispensingItem = ref)}
              data={this.state.dsiData.filter(item => {
                const dispensing_list = this.state.dsData
                  .filter(item => item.id === this.state.selectedBti.ds_id)
                  .map(item => item.id);
                return dispensing_list.includes(item.dispensing_ref_id);
              })}
              itemName={"Dsi"}
            />
          </div>
        )}

        {!isEmpty(this.state.selectedBti) && this.state.selectedBti.os_id && (
          <div>
            <br />
            <DetailTable
              idfield={"id"}
              fieldList={OSFieldList}
              fieldName={OSFieldName}
              ref={ref => (this.operationService = ref)}
              title={"Operation Service"}
              data={this.state.osData.filter(
                item => item.id === this.state.selectedBti.os_id
              )}
              onEvent={this.handleEvent}
              itemName={"Os"}
            />
            <br />
            <DetailTable
              idfield={"id"}
              fieldList={OSiFieldList}
              fieldName={OSiFieldName}
              title={"Operation Service Item"}
              ref={ref => (this.operationServiceItem = ref)}
              data={this.state.osiData.filter(item => {
                const operationservice_list = this.state.osData
                  .filter(item => item.id === this.state.selectedBti.os_id)
                  .map(item => item.id);
                return operationservice_list.includes(
                  item.operation_service_ref_id
                );
              })}
              onEvent={this.handleEvent}
              itemName={"Osi"}
            />
          </div>
        )}
        {this.state.btData.length > 0 && (
          <>
            <br />
            <div className="rightAlign" style={{ display: "flex" }}>
              <Button
                content="ยืนยันการแก้ไขข้อมูล"
                color="blue"
                onClick={this.handleConfirm}
              />
              <Button
                content="บันทึก"
                color="green"
                onClick={this.handleSave}
              />
            </div>
          </>
        )}
      </CardLayout>
    );
  }
}

const DetailTable = React.forwardRef((props: any, ref) => {
  const [data, setData] = useState([]);
  const intl = useIntl();

  React.useImperativeHandle(ref, () => ({
    getData: () => data
  }));

  useEffect(() => {
    // if (props.fieldList) {
    //   let newArr = []
    //   for(let item in props.data){
    //     const filtered = Object.keys(props.data[item])
    //     .filter(key => props.fieldList.includes(key))
    //     .reduce((obj, key) => {
    //       obj[key] = props.data[item][key];
    //       return obj;
    //     }, {});
    //     newArr.push(filtered)
    //   }
    //   setData(newArr)
    // } else {
    setData([...props.data]);
    // }
  }, [props.data]);

  return (
    <div>
      <div>
        <h3>{props.title}</h3>
      </div>
      {props.data.map((item, index) => (
        <div>
          <div>
            <h3>ID: {item[props.idfield]}</h3>
          </div>
          {Object.keys(item)
            .filter(item =>
              props.fieldList ? props.fieldList.includes(item) : true
            )
            .map((field, field_index) => {
              return (
                <div
                  key={field_index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center"
                  }}
                >
                  <div style={{ flex: 1 }}>
                    {props.fieldName ? props.fieldName[field] : field}
                  </div>
                  <div style={{ flex: 2 }}>{item[field]}</div>
                  <div style={{ flex: 5 }}>
                    <Input
                      style={{ width: "100%" }}
                      value={data[index] ? data[index][field] : ""}
                      onChange={e => {
                        let newDataArr = [...data];
                        if (newDataArr[index][field]) {
                          let newDataObj = { ...newDataArr[index] };
                          newDataObj[field] = e.target.value;
                          newDataArr[index] = newDataObj;
                          setData(newDataArr);
                        }
                      }}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      ))}
    </div>
  );
});

const DataTable = (props: any) => {
  const [hover, setHover] = useState(-1);
  const [filter, setFilter] = useState("");
  const intl = useIntl();

  return (
    <div>
      <div
        style={{
          marginTop: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <div>
          <h3>{props.title}</h3>
        </div>
        {props.filterC && (
          <Input
            placeholder={intl.formatMessage({ id: "กรองด้วย Code C" })}
            value={filter}
            onChange={(e, v) => {
              setFilter(v.value);
            }}
          />
        )}
      </div>
      <div style={{ maxHeight: "300px", overflow: "auto" }}>
        <div
          className="table fixed-header"
          style={{
            width: "100%",
            borderSpacing: 0
          }}
        >
          <div
            className="thead"
            style={{
              color: "white",
              // display: "flex",
              // backgroundColor: "orange",
              // fontWeight: "bold",
              // textAlign: "center"
            }}
          >
            {props.columns.map((column, column_index) => (
              <div
                className="th"
                key={column_index}
                style={{
                  backgroundColor: "orange",
                  borderRight: "1px solid white"
                }}
                // style={{ flex: column.flex }}
              >
                {column.Header}
              </div>
            ))}
          </div>

          {/* Item selected -> only show selected row */}
          {props.selectedItem.id ? (
            <div className="tbody">
              <div
                className="tr"
                style={{
                  // display: "flex",
                  cursor: "pointer",
                  backgroundColor: "#ffffce"
                }}
                onClick={e => {
                  props.onEvent({
                    message: `unchoose${props.itemName}`,
                    params: {}
                  });
                }}
              >
                {props.columns.map((column, column_index) => (
                  <div
                    className="td"
                    key={column_index}
                    style={{
                      // flex: column.flex,
                      textAlign: column.right ? "right" : "center",
                      paddingRight: column.right ? "20px" : "0px",
                      borderRight: "solid #cccccc 1px",
                      borderBottom: "solid #cccccc 1px"
                    }}
                  >
                    {column.float
                      ? props.selectedItem[column.accessor].toLocaleString(
                          "en-US",
                          {
                            style: "decimal",
                            maximumFractionDigits: 1,
                            minimumFractionDigits: 1
                          }
                        )
                      : props.selectedItem[column.accessor]}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            // Item not selected -> show all items
            <div
              className="tbody"
              style={
                props.height
                  ? { maxHeight: props.height, overflow: "scroll" }
                  : {}
              }
            >
              {props.data
                .map((row, row_index) => ({ ...row, index: row_index + 1 }))
                .filter((row, row_index) => {
                  return props.filterC
                    ? filter
                        .split(",")
                        .map(item => item.trim().toLowerCase())
                        .reduce(
                          (acc, cur) =>
                            acc && row.code.toLowerCase().includes(cur),
                          true
                        )
                    : true;
                })
                .map((row, row_index) => (
                  <div
                    className="tr"
                    key={row_index}
                    style={{
                      // display: "flex",
                      cursor: "pointer",
                      backgroundColor: hover === row_index ? "#cccccc" : "white"
                    }}
                    onClick={e => {
                      props.onEvent({
                        message: `choose${props.itemName}`,
                        params: { value: row }
                      });
                    }}
                    onMouseOver={e => {
                      setHover(row_index);
                    }}
                  >
                    {props.columns.map((column, column_index) => (
                      <div
                        className="td"
                        key={column_index}
                        style={{
                          // flex: column.flex,
                          textAlign: column.right ? "right" : "center",
                          paddingRight: column.right ? "20px" : "0px",
                          borderRight: "solid #cccccc 1px",
                          borderBottom: "solid #cccccc 1px"
                        }}
                      >
                        {column.cell ? column.cell(row) : column.float
                          ? row[column.accessor].toLocaleString("en-US", {
                              style: "decimal",
                              maximumFractionDigits: 1,
                              minimumFractionDigits: 1
                            })
                          : row[column.accessor]}
                      </div>
                    ))}
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withInterceptor(CardOPDClaimResolve);

const isEmpty = obj =>
  Object.keys(obj).length === 0 && obj.constructor === Object;

const currentYear = new Date().getFullYear() + 543;

const FISCAL_YEAR = Array(currentYear - 2559)
  .fill(0)
  .map((item, index) => (index + 2560).toString())
  .map(item => ({ key: item, value: item, text: item }));

const arTableColumns = [
  { Header: "ลำดับที่", accessor: "index", flex: "0 0 4rem" },
  { Header: "Id", accessor: "id", flex: "0 0 8rem" },
  { Header: "Lot No", accessor: "lot_no", flex: "0 0 8rem" },
  { Header: "จำนวน Invoice", accessor: "bt_count", right: true, flex: "1" },
  {
    Header: "ยอดส่งเบิกรวม",
    accessor: "claim_amount",
    right: true,
    float: true,
    flex: "1"
  },
  { Header: "Code", accessor: "code", right: true, flex: "3" }
];

const btiFieldName = {
  lc_code: "รหัสบริการ",
  std_code: "รหัสเบิก",
  bill_mode: "Bill Mode",
  invoice_no: "หมายเลข Invoice",
  claim_amount: "ยอดส่งเบิก",
  ref_id: "Ref ID",
  type: "Type"
};

const btiFieldList = [
  "lc_code",
  "std_code",
  "bill_mode",
  "invoice_no",
  "claim_amount",
  "ref_id",
  "type"
];

const OSFieldName = {
  invoice_no: "หมายเลข Invoice",
  service_id: "service_id",
  service_class: "service_class",
  hospital_code: "รหัสโรงพยาบาล",
  hn: "HN",
  pid: "pid",
  care_account: "care_account",
  type_service: "type_service",
  type_in: "type_in",
  type_out: "type_out",
  appointment_dt: "appointment_dt",
  service_pid: "service_pid",
  clinic: "clinic",
  begin_dt: "begin_dt",
  end_dt: "end_dt",
  lc_code: "รหัสบริการ",
  code_set: "code_set",
  standard_code: "standard_code",
  service_charge: "service_charge",
  completion: "completion",
  service_tx_code: "service_tx_code",
  claim_category: "claim_category"
};

const OSFieldList = [
  "invoice_no",
  "service_id",
  "service_class",
  "hospital_code",
  "hn",
  "pid",
  "care_account",
  "type_service",
  "type_in",
  "type_out",
  "appointment_dt",
  "service_pid",
  "clinic",
  "begin_dt",
  "end_dt",
  "lc_code",
  "code_set",
  "standard_code",
  "service_charge",
  "completion",
  "service_tx_code",
  "claim_category"
];

const OSiFieldName = {
  service_class: "service_class",
  service_id: "service_id",
  severity_level: "severity_level",
  code_set: "code_set",
  code: "code",
  description: "description"
};

const OSiFieldList = [
  "service_class",
  "service_id",
  "severity_level",
  "code_set",
  "code",
  "description"
];

const DispFieldName = {
  provider_id: "provider_id",
  dispensing_id: "dispensing_id",
  invoice_no: "หมายเลข Invoice",
  hn: "HN",
  pid: "pid",
  prescription_dt: "prescription_dt",
  prescription_by: "prescription_by",
  item_count: "item_count",
  charge_amount: "charge_amount",
  claim_amount: "ยอดส่งเบิก",
  paid: "paid",
  other_pay: "Other Pay",
  reimburser: "reimburser",
  benefit_plan: "benefit_plan",
  dispensing_stat: "dispensing_stat",
  service_id: "service_id",
  day_cover: "day_cover"
};

const DispFieldList = [
  "provider_id",
  "dispensing_id",
  "invoice_no",
  "hn",
  "pid",
  "prescription_dt",
  "prescription_by",
  "item_count",
  "charge_amount",
  "claim_amount",
  "paid",
  "other_pay",
  "reimburser",
  "benefit_plan",
  "dispensing_stat",
  "service_id",
  "day_cover"
];

const DispItemFieldName = {
  product_category: "product_category",
  hospital_product_id: "hospital_product_id",
  drug_id: "drug_id",
  dfs_code: "dfs_code",
  dfs_text: "dfs_text",
  pack_size: "pack_size",
  sig_code: "sig_code",
  sig_text: "sig_text",
  quantity: "quantity",
  unit_price: "unit_price",
  charge_amount: "charge_amount",
  reim_price: "reim_price",
  reimb_amount: "reimb_amount",
  product_se_code: "product_se_code",
  claim_condition: "claim_condition",
  claim_category: "claim_category",
  multi_dispensing: "multi_dispensing",
  supply_for: "supply_for"
};

const DispItemFieldList = [
  "product_category",
  "hospital_product_id",
  "drug_id",
  "dfs_code",
  "dfs_text",
  "pack_size",
  "sig_code",
  "sig_text",
  "quantity",
  "unit_price",
  "charge_amount",
  "reim_price",
  "reimb_amount",
  "product_se_code",
  "claim_condition",
  "claim_category",
  "multi_dispensing",
  "supply_for"
];

const btTableColumns = [
  { Header: "ลำดับที่", accessor: "index", flex: "0 0 4rem" },
  { Header: "Id", accessor: "id", flex: "0 0 4rem" },
  { Header: "Lot Id", accessor: "ar_transaction_id", flex: "0 0 4rem" },
  { Header: "หมายเลข Invoice", accessor: "invoice_no", flex: "1" },
  { Header: "หมายเลขใบเสร็จ", accessor: "bill_no", flex: "1" },
  { Header: "วันที่", accessor: "date", flex: "1" },
  { Header: "ยอดรวม", accessor: "amount", flex: "1", right: true, float: true },
  {
    Header: "ยอดส่งเบิก",
    accessor: "claim_amount",
    flex: "1",
    right: true,
    float: true
  },
  { Header: "ยอดชำระ", accessor: "paid", flex: "1", right: true, float: true },
  {
    Header: "Other Pay",
    accessor: "other_pay",
    flex: "1",
    right: true,
    float: true
  },
  { Header: "Status", accessor: "status", flex: "1" },
  { Header: "HN", accessor: "hn", flex: "1" },
  { Header: "Verify Code", accessor: "verify_code", flex: "1" },
  { Header: "PID", accessor: "pid", flex: "1" },
  { Header: "Code", accessor: "code", flex: "1" }
];

const btiTableColumns = [
  { Header: "ลำดับที่", accessor: "index", flex: "0 0 4rem" },
  { Header: "Id", accessor: "bti_id", flex: "0 0 8rem" },
  { Header: "Bill Transaction Id", accessor: "bt_id", flex: "0 0 8rem" },
  { Header: "Created", accessor: "created_at", flex: "1", cell: (row) => {
    // let moemntObj = moment(row.created_at)
    let date = Util.formatDatetime(row.created_at)
    let time = moment(row.created_at).format("HH:mm")
    return `${date} ${time}`
  } },
  { Header: "รหัสบริการ", accessor: "lc_code", flex: "1" },
  { Header: "รหัสเบิก", accessor: "std_code", flex: "1" },
  { Header: "Bill Mode", accessor: "bill_mode", flex: "1" },
  { Header: "Invoice Item Id", accessor: "invoice_item_ref_id", flex: "1" },
  { Header: "Invoice No", accessor: "invoice_no", flex: "1" },
  {
    Header: "ยอดส่งเบิก",
    accessor: "claim_amount",
    flex: "1",
    right: true,
    float: true
  },
  { Header: "Type", accessor: "type", flex: "1" },
  { Header: "Ref ID", accessor: "ref_id", flex: "1" },
  { Header: "Operation Service Items Count", accessor: "osi_count", flex: "1" }
  // { Header: 'Dispensing Reference Id', accessor: 'ds_id' },
  // { Header: 'Operation Service Reference Id', accessor: 'os_id' },
];

// export default class extends React.Component<
//     any,
//     {
//         treeSelected: any[],
//         trigger: boolean
//     }
//     >{

//     treeChoices = Array(treeDef.length).fill(null)

//     constructor(props) {
//         super(props)
//         this.state = {
//             treeSelected: Array(treeDef.length).fill(null),
//             trigger: true
//         }
//         this.treeChoices[0] = FISCAL_YEAR
//     }

//     componentDidMount = () => {
//     }

//     handleEvent = ({message, params}: {message: string, params: any}) => {
//         console.log(message, params)
//         if (message == "selectChoice") {
//             this.setTree(params.level, params.value)
//         } else if (message == "unselectChoice") {
//             this.setTree(params.level, null)
//         }
//     }

//     setTree = async (level: number, value: string) => {
//         const newTreeSelected = [...this.state.treeSelected]
//         newTreeSelected[level] = value
//         for (var l=level + 1; l < treeDef.length; l++) {
//             newTreeSelected[l] = null
//         }
//         this.setState({ treeSelected: newTreeSelected })
//         if (value && level < treeDef.length - 1) {
//             console.log(level)
//             console.log(treeDef[level+1])
//             this.treeChoices[level+1] = await treeDef[level+1].getChoices(newTreeSelected)
//             this.setState({ trigger: !this.state.trigger })
//         }
//     }

//     render() {
//         console.log(this.treeChoices)

//         const firstNullLevel = this.state.treeSelected.indexOf(null)
//         const treeDefActive = firstNullLevel === -1 ?
//                                 treeDef:
//                                 treeDef.filter((item, index) => index <= firstNullLevel)

//         return(
//         <CardLayout
//             titleText='OPD Claim Correction'
//             closeable={false}>
//             {treeDefActive.map((treeItem, index) => (
//             <TreeSelect
//                 key={index}
//                 onEvent={this.handleEvent}
//                 level={index}
//                 selectedChoice={this.state.treeSelected[index]}
//                 choices={this.treeChoices[index]}
//                 columns={treeItem.columns}
//             />
//             ))}
//         </CardLayout>
//         )
//     }
// }

// const FISCAL_YEAR = [{year: '2560'}, {year: '2561'}, {year: '2562'}, {year: '2563'}]

// const getYear = async (treeSelected) => {
//     return FISCAL_YEAR
// }

// const getMyData = async (treeSelected) => {
//     const result = await axios.get("/apis/INF/test-ar-improvement/").catch(err => {
//     // const result = await axios.get(`/apis/INF/ar-transaction/?fiscal_year=${treeSelected[0].year}`).catch(err => {
//         console.log(err)
//     })
//     console.log(result)
//     return result ? result.data : null
// }

// const treeDef = [
//     {   level: 0,
//         getChoices: getYear,
//         columns: [
//             { key: "year"}
//         ]
//     },
//     {   level: 1,
//         getChoices: getMyData,
//         columns: [
//             { key: "lot_no" },
//             { key: "fiscal_year" },
//         ]
//     }
// ]

// const TreeSelect = (props: any) => {

//     console.log(props.choices)

//     return (
//     props.selectedChoice ?
//     <div
//         style={{ cursor: "pointer", display: "flex", borderBottom: "solid black 1px" }}
//         onClick={(e) => {props.onEvent({
//             message: "unselectChoice", params: { level: props.level }})}}
//     >
//         {props.columns.map((column, column_index) => {
//             console.log(column)
//             console.log(props.selectedChoice[column.key])
//             return(
//             <div
//                 key={column_index}
//                 style={{ flex: 1 }}
//             >{props.selectedChoice[column.key].toString()}</div>
//             )
//         })}
//     </div>:

//     <div style={{ display: "flex", flexDirection: "column", height: "200px", overflow: "scroll" }}>
//         {props.choices ?
//         props.choices.map((item, index) => (
//         <div
//             key={index}
//             style={{ flex: 1, cursor: "pointer", display: "flex" }}
//             onClick={(e) => {props.onEvent({
//                 message: "selectChoice", params: { level: props.level, value: item }})}}
//         >
//                 {props.columns.map((column, column_index) => {
//                     console.log(column)
//                     console.log(item[column.key])
//                     return(
//                     <div
//                         key={column_index}
//                         style={{ flex: 1 }}
//                     >{item[column.key].toString()}</div>
//                     )
//                 })}
//         </div>
//         )):

//         <div>Please wait while data is being loaded</div>
//         }
//     </div>
//     )
// }
