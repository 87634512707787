import React, { useState, useEffect, CSSProperties } from "react";
import { Form, Input, List } from "semantic-ui-react";
import { useIntl } from "react-intl";

type SubNarcoticUsageProps = {
  // data
  defaultValue?: { apply?: string; reject?: string };
  drugName: string;
  unitName: string;
  style?: CSSProperties;
  contentStyle?: CSSProperties;
  patientName?: string;
  // config
  size?: "mini";
  hideHeader?: boolean;
  // callback
  onChange?: (data: DataType) => any;
};

type DataType = { value: string; name: string };

const SubNarcoticUsage = (props: SubNarcoticUsageProps) => {
  const intl = useIntl();
  //* Narcotic
  const [reject, setReject] = useState<string>("");
  const [apply, setApply] = useState<string>("");

  useEffect(() => {
    if (props.defaultValue?.apply !== apply || props.defaultValue?.reject !== reject) {
      setReject(props.defaultValue?.reject || "");
      setApply(props.defaultValue?.apply || "");
    }
  }, [props.defaultValue]);

  /**
   * Narcotic
   * */
  const handleChangeInput = (e: any, data: any) => {
    const value = data.value;
    const [_, decimal] = value.split(".");

    if (decimal?.length > 2 || Number(value) < 0) {
      return;
    }

    if (data.name === "apply") {
      setApply(value);
    } else if (data.name === "reject") {
      setReject(value);
    }

    props.onChange?.({ value, name: data.name });
  };

  return (
    <>
      <Form.Group
        style={{
          flexDirection: "column",
          marginBottom: "1rem",
          backgroundColor: "#f9d3e5",
          padding: "0.5rem 1rem 0px 1rem",
          ...(props.size === "mini"
            ? {
                margin: "-1rem -3.75rem -1rem -3.75rem",
                transform: "scale(0.85)",
                padding: "0.5rem 0.5rem 0px 0.5rem",
              }
            : {}),
          ...(props.style || {}),
        }}
      >
        {!props.hideHeader && (
          <Form.Field
            style={{
              margin:
                props.size === "mini"
                  ? "0.25rem 0px 0.5rem"
                  : "0.25rem 0 0.75rem",
            }}
          >
            <label>
              Narcotic drug โปรดระบุ จำนวนที่ใช้ และจำนวนที่ทิ้ง
              <span style={{ color: "red" }}>*</span>
            </label>
          </Form.Field>
        )}
        {props.patientName && (
          <List
            bulleted
            style={{
              marginTop: 0,
              marginBottom: "5px",
              paddingLeft: 20,
              fontWeight: "bold",
            }}
          >
            <List.Item>{props.patientName}</List.Item>
          </List>
        )}

        <Form.Group
          inline
          style={{
            margin:
              props.size === "mini" ? "0px 0.5rem 0.75rem" : "0 0.5rem 0.95rem",
            ...(props.contentStyle || {}),
          }}
        >
          <div>{props.drugName || ""}</div>
          <div
            style={{
              margin:
                props.size === "mini" ? "0 1rem 0 1.75rem" : "0 1rem 0 3rem",
            }}
          >{intl.formatMessage({ id: "ใช้" })}</div>
          <Input
            value={apply}
            type="number"
            name="apply"
            size={"small"}
            style={{ width: "100px", marginRight: "1rem" }}
            // callback
            onChange={handleChangeInput}
          />
          <div>{props.unitName || ""}</div>
          <div
            style={{
              margin:
                props.size === "mini" ? "0 1rem 0 1.75rem" : "0 1rem 0 3rem",
            }}
          >{intl.formatMessage({ id: "ทิ้ง" })}</div>
          <Input
            value={reject}
            type="number"
            name="reject"
            size={"small"}
            style={{ width: "100px", marginRight: "1rem" }}
            // callback
            onChange={handleChangeInput}
          />
          <div>{props.unitName || ""}</div>
        </Form.Group>
      </Form.Group>
    </>
  );
};

export default React.memo(SubNarcoticUsage);
