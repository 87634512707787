import WasmController from "react-lib/frameworks/WasmController";

// APIs
// TPD
import DrugPickingList from "issara-sdk/apis/DrugPickingList_apps_TPD";
import DrugPickingOrderList from "issara-sdk/apis/DrugPickingOrderList_apps_TPD";
import DrugPickingOrderDetail from "issara-sdk/apis/DrugPickingOrderDetail_apps_TPD";

// Interface
import { State as MainState } from "../../../../../HIS/MainHISInterface";
import { SetErrorMessage, SetProperty } from "../../common/CommonInterface";
import { ORDER_STATUS_COLORS } from "./DrugOrderAction";

export type State = Partial<{
  // sequence
  DrugPickingOrderSequence: Partial<{
    sequenceIndex: "Start" | "Action" | null;
    pickingList: DrugPickingSerializer[];
    pickingOrderList: DrugPickingOrderSerializer[];
    selectedPickingOrder: DrugPickingOrderSerializer;
    filter: Partial<FilterType>;
  }> | null;
}>;

type PickedState = Partial<
  Pick<MainState, "buttonLoadCheck" | "masterOptions" | "selectedEncounter" | "errorMessage">
>;

export type PickedProps = Partial<Omit<PickedState, "masterOptions">>;

export type FilterType = {
  keyword: string;
};

export type DrugPickingOrderSerializer = {
  id?: number | null;
  time_stamp?: string;
  is_admin?: boolean;
  status_label?: keyof typeof ORDER_STATUS_COLORS;
  items: DrugPickingSerializer[];
};

export type DrugPickingSerializer = {
  id: number;
  product: number;
  code_unit: string;
  code_site: string;
  code_route: string;
  code_method: string;
  frequency: any;
  enabled?: boolean;
  active_flag: keyof typeof PRODUCT_AVAILABILITY;
  generic_name: string;
} & {
  [key in
    | "dose"
    | "unit"
    | "route"
    | "site"
    | "method"
    | "quantity"
    | "default_unit_dose"
    | "default_dosage_unit"
    | "default_route"
    | "default_site"
    | "default_method"]: string | null;
} & {
  [key in (typeof DRUG_OPTION_MAPPING)[number][1]]: number[];
} & {
  [key in "unitOptions" | "routeOptions" | "siteOptions" | "methodOptions"]: OptionType[];
};

export type MasterOptionsType = Record<(typeof Masters)[number][0], OptionType[]>;

export type OptionType = {
  key: number | string;
  value: number | string;
  text: string;
};

// Sequence
type SeqState = {
  sequence: "DrugPickingOrder";
  restart?: boolean;
  clear?: boolean;
  card?: string;
};

// Handle Action
type ActionType =
  // Search
  | { action: "SEARCH"; card: string }
  | { action: "PICKING_ORDER" }
  // Action
  | { action: "ADD_ITEM"; data: DrugPickingSerializer }
  | { action: "REMOVE_ITEM"; data: DrugPickingSerializer }
  | { action: "SELECT_ORDER"; data: DrugPickingOrderSerializer }
  // Method
  | { action: "SAVE"; card: string };

type SeqAct = ActionType & SeqState;
type SeqType<K> = K extends { action: string } ? Extract<SeqAct, K> : SeqState;

export type RunSequence = <K extends keyof SeqAct>(params: SeqType<Pick<SeqAct, K>>) => void;

export type SetProp = SetProperty<State & PickedState>;

type CustomExtract<T, U> = T extends object ? (U extends Partial<T> ? T : never) : never;

type Params<A extends ActionType["action"]> = CustomExtract<ActionType, { action: A }>;

export const StateInitial: State = {
  // sequence
  DrugPickingOrderSequence: {
    sequenceIndex: null,
  },
};

export type Event =
  | { message: "GetMasterData"; params: Record<string, unknown> }
  | { message: "RunSequence"; params: Record<string, unknown> };

export type Data = {
  division?: number;
  device?: number;
};

export const DataInitial = {};

const Masters = [
  ["unit", {}],
  ["route", {}],
  ["site", {}],
  ["method", {}],
] as const;

const DRUG_OPTION_MAPPING = [
  ["unit", "limited_dosage_units"],
  ["route", "limited_routes"],
  ["site", "limited_sites"],
  ["method", "limited_methods"],
] as const;

export const PRODUCT_AVAILABILITY = {
  INACTIVE: "ยังไม่พร้อมขาย",
  ACTIVE: "ขายได้ปกติ",
  INSUFFICIENT: "ขาดชั่วคราว(Temo Unava)",
  UNAVAILABLE: "เลิกใช้งานแต่ของยังไม่หมด",
  TERMINATED: "เลิกใช้งาน",
};

export const CARD_PICKING_ORDER = "CardPickingOrder";

type Optional<T> = {
  [K in keyof T]: `${typeof CARD_PICKING_ORDER}_${T[K] & string}`;
};

export const ACTIONS = {
  SEARCH: "SEARCH",
  ADD_ITEM: "ADD_ITEM",
  REMOVE_ITEM: "REMOVE_ITEM",
  SAVE: "SAVE",
  PICKING_ORDER: "PICKING_ORDER",
  SELECT_ORDER: "SELECT_ORDER",
} as const;

export const BTN_ACTS = Object.fromEntries(
  Object.keys(ACTIONS).map((key) => [key, `${CARD_PICKING_ORDER}_${key}`])
) as Optional<typeof ACTIONS>;

export const DEFAULT_PICKING_ORDER = { items: [] as any[], id: null };

type Handler<P = any, R = any> = (
  controller: WasmController<State & PickedState, Event, Data>,
  params: P
) => R;

/* ------------------------------------------------------ */

/*                          START                         */

/* ------------------------------------------------------ */
export const GetMaster: Handler<SeqState> = async (controller, params) => {
  const state = controller.getState();

  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: Masters,
    },
  });

  controller.setState(
    {
      DrugPickingOrderSequence: {
        ...state.DrugPickingOrderSequence,
        sequenceIndex: "Action",
        selectedPickingOrder: DEFAULT_PICKING_ORDER,
      },
    },
    () => {
      Action(controller, { action: ACTIONS.SEARCH, card: params.card || "" });
      Action(controller, { action: ACTIONS.PICKING_ORDER });
    }
  );
};

/* ------------------------------------------------------ */

/*                      Handle Action                     */

/* ------------------------------------------------------ */
export const Action: Handler<ActionType> = async (controller, params) => {
  const actionHandlers = {
    [ACTIONS.SEARCH]: HandleSearch,
    [ACTIONS.ADD_ITEM]: HandleAddItem,
    [ACTIONS.REMOVE_ITEM]: HandleRemoveItem,
    [ACTIONS.SAVE]: HandleSave,
    [ACTIONS.PICKING_ORDER]: HandleDrugPickingOrder,
    [ACTIONS.SELECT_ORDER]: HandleSelectOrder,
  };

  const action = params.action;

  if (action && actionHandlers[action]) {
    return actionHandlers[action](controller, params as any);
  }
};

const HandleSearch: Handler<Params<"SEARCH">> = async (controller, params) => {
  let state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SEARCH]: "LOADING" },
  });

  const filter = state.DrugPickingOrderSequence?.filter || {};

  const [drugPicking] = await DrugPickingList.list({
    apiToken: controller.apiToken,
    params: { name_code: filter.keyword },
    extra: {
      division: controller.data.division,
      device: controller.data.device,
    },
  });

  state = controller.getState();

  controller.setState({
    DrugPickingOrderSequence: {
      ...state.DrugPickingOrderSequence,
      pickingList: drugPicking?.items || [],
    },
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SEARCH]: "SUCCESS" },
  });
};

const HandleAddItem: Handler<Params<"ADD_ITEM">> = async (controller, params) => {
  const state = controller.getState();

  let selected = state.DrugPickingOrderSequence?.selectedPickingOrder || DEFAULT_PICKING_ORDER;

  selected = structuredClone(selected);

  const formatData = formatDrugPicking({ data: params.data, options: state.masterOptions });

  selected.items.push(formatData);

  controller.setState({
    DrugPickingOrderSequence: {
      ...state.DrugPickingOrderSequence,
      selectedPickingOrder: { ...selected, items: [...selected.items] },
    },
  });
};

const HandleRemoveItem: Handler<Params<"REMOVE_ITEM">> = async (controller, params) => {
  const state = controller.getState();

  const selected = state.DrugPickingOrderSequence?.selectedPickingOrder;
  const items = selected?.items || [];

  const removed = items.filter((item) => item.id !== params.data.id);

  controller.setState({
    DrugPickingOrderSequence: {
      ...state.DrugPickingOrderSequence,
      selectedPickingOrder: { ...selected, items: [...removed] },
    },
  });
};

const HandleSave: Handler<Params<"SAVE">> = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SAVE]: "LOADING" },
  });

  const selected = state.DrugPickingOrderSequence?.selectedPickingOrder;
  const items = selected?.items || [];
  const api = selected?.id ? DrugPickingOrderDetail.update : DrugPickingOrderList.create;

  items.forEach((item) => {
    item.enabled = true;
    delete item.frequency;
  });

  const [result, error] = await api({
    apiToken: controller.apiToken,
    pk: selected?.id,
    data: {
      ...selected,
      items,
      action: selected?.id ? "EDIT" : "PICK",
      encounter: state.selectedEncounter?.id,
      order_doctor: state.selectedEncounter?.doctor,
    },
    extra: {
      division: controller.data.division,
      device: controller.data.device,
    },
  });

  if (error) {
    SetErrorMessage(controller, { ...params, error: error, errorKey: BTN_ACTS.SAVE });
  } else {
    Action(controller, { action: ACTIONS.PICKING_ORDER });
    Action(controller, {
      action: ACTIONS.SELECT_ORDER,
      data: selected?.id ? result : DEFAULT_PICKING_ORDER,
    });

    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SAVE]: "SUCCESS" },
    });
  }
};

const HandleDrugPickingOrder: Handler<Params<"PICKING_ORDER">> = async (controller, params) => {
  let state = controller.getState();

  const [order] = await DrugPickingOrderList.list({
    apiToken: controller.apiToken,
    params: {
      encounter: state.selectedEncounter?.id,
    },
    extra: {
      division: controller.data.division,
      device: controller.data.device,
    },
  });

  state = controller.getState();

  controller.setState({
    DrugPickingOrderSequence: {
      ...state.DrugPickingOrderSequence,
      pickingOrderList: order?.items || [],
    },
  });
};

const HandleSelectOrder: Handler<Params<"SELECT_ORDER">> = async (controller, params) => {
  let state = controller.getState();

  const items = (params.data?.items || []).map((item) => {
    const product = state.DrugPickingOrderSequence?.pickingList?.find((acc) => acc.id === item.id);

    return { ...item, ...filterDrugOptions({ data: product || {}, options: state.masterOptions }) };
  });

  state = controller.getState();

  controller.setState({
    DrugPickingOrderSequence: {
      ...state.DrugPickingOrderSequence,
      selectedPickingOrder: { ...params.data, items },
    },
  });
};

/* ------------------------------------------------------ */

/*                          Utils                         */

/* ------------------------------------------------------ */
const filterDrugOptions = (params: {
  data: Partial<DrugPickingSerializer>;
  options: MasterOptionsType;
}) => {
  const optionsMapping = DRUG_OPTION_MAPPING.map(([key, field]) => {
    const itemOptions = params.options[key];

    return [
      `${key}Options`,
      params.data[field]?.length
        ? itemOptions.filter(
            (option) => typeof option.key === "number" && params.data[field]?.includes(option.key)
          )
        : itemOptions,
    ];
  });

  return Object.fromEntries(optionsMapping);
};

const formatDrugPicking = (params: {
  data: DrugPickingSerializer;
  options: MasterOptionsType;
}): DrugPickingSerializer => {
  const { id, ...data } = params.data;

  return {
    ...data,
    dose: data.default_unit_dose || "",
    unit: data.default_dosage_unit || null,
    route: data.default_route || null,
    site: data.default_site || null,
    method: data.default_method || null,
    quantity: "",
    product: id,
    ...filterDrugOptions({ data, options: params.options }),
  };
};
