import BaseService from "./BaseService";
import { ORM } from "../../configs/apis";
class ORMService extends BaseService {
  // order
  getOperatingOrder(params) {
    return this.client
      .get(ORM.OPERATING_ORDER, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getOperatingOrderById(operatingId) {
    const url = ORM.OPERATING_ORDER_BY_ID({ operating_id: operatingId });
    return this.client
      .get(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  putOperatingOrderById(operatingId, params) {
    const url = ORM.OPERATING_ORDER_BY_ID({ operating_id: operatingId });
    return this.client
      .put(url, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getORAppointment(params) {
    return this.client
      .get(ORM.OR_APPOINTMENT, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  // Todo: postORDeliver && postDeliverOperatingAppointment url duplicated
  postORDeliver(params) {
    return this.client
      .post(ORM.OR_DELIVER, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  postDeliverOperatingAppointment({ params, data }) {
    return this.client
      .post(ORM.DELIVER_OPERATING_APPOINTMENT, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  // detail
  getOperatingDetail(params) {
    const url = ORM.OPERATING_DETAIL;
    return this.client
      .get(url, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  // note
  getOperativeImage(operativeNoteId) {
    const url = ORM.OPERAITVE_IMAGE_BY_OPERATIVE_NOTE({
      operative_note_id: operativeNoteId,
    });
    return this.client
      .get(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  // location
  getOrLocation(params) {
    return this.client
      .get(ORM.OPERATING_LOCATION, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getOperatingProcedureSummary(params) {
    return this.client
      .get(ORM.OPERATING_PROCEDURE_SUMMARY, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  // team
  printOperativeNote(teamId) {
    const url = ORM.TEAM_PRINT_OPERATIVE_NOTE({
      team_id: teamId,
    });
    return this.client
      .get(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
}
export default ORMService;
