import * as SettingPackageI from "react-lib/apps/HISV3/HCU/sequence/SettingPackage";
import * as PackagePurchaseI from "react-lib/apps/HISV3/HCU/sequence/PackagePurchase";
import * as PackageDateTimeI from "react-lib/apps/HISV3/HCU/sequence/PackageDateTime";
import * as SettingQueueI from "react-lib/apps/HISV3/HCU/sequence/SettingQueue";
import * as ApplyPolicyPackageI from "react-lib/apps/HISV3/HCU/sequence/ApplyPolicyPackage";
import * as PersonnelInformationI from "react-lib/apps/HISV3/HCU/sequence/PersonnelInformation"

const SequencePattern: {
  [name: string]: { [flavor: string]: { [func: string]: any } };
} = {
  SettingPackage: {
    default: {
      START: SettingPackageI.GetMaster,
      Action: SettingPackageI.Action,
    },
  },
  PackagePurchase: {
    default: {
      START: PackagePurchaseI.GetMaster,
      SearchPackage: PackagePurchaseI.SearchPackage,
      PurchaseList: PackagePurchaseI.PurchaseList,
      PurchaseHistory: PackagePurchaseI.PurchaseHistory,
    },
  },
  PackageDateTime: {
    default: {
      START: PackageDateTimeI.GetMaster,
      Action: PackageDateTimeI.Action,
    },
  },
  SettingQueue: {
    default: {
      START: SettingQueueI.Start,
      Action: SettingQueueI.Action,
    }
  },
  ApplyPolicyPackage: {
    default: {
      START: ApplyPolicyPackageI.GetMaster,
      Action: ApplyPolicyPackageI.Action,
    }
  },
  PersonnelInformation: {
    default: {
      START: PersonnelInformationI.Start,
      Action: PersonnelInformationI.Action,
    }
  }
};

export default SequencePattern;
