import React, { useEffect } from "react";

// UX
import CardPatientCoverageUX from "./CardPatientCoverageUX";

// Common
import { alignCenter } from "react-lib/appcon/common/PureReactTable";
import moment from "moment";
import { beToAd } from "react-lib/utils/dateUtils";
import { useIntl } from "react-intl";

// Types
type CardPatientCoverageProps = {
  onEvent: (e: any) => any;
  // data
  patientCoverageList?: Record<string, any>[];
};

const CardPatientCoverage = (props: CardPatientCoverageProps) => {
  const intl = useIntl();
  // Effect
  useEffect(() => {
    props.onEvent({ message: "GetPatientCoverageList", params: {} });
  }, []);

  // Memo
  const patientCoverageList = React.useMemo(() => {
    return (props.patientCoverageList || []).map((item: any) => {
      const isExpire = beToAd(item.stop_date)?.isBefore(moment());

      const serviceType: Record<string, string> = {
        I: "IPD",
        O: "OPD",
      };

      return {
        ...item,
        service_type_text: alignCenter(serviceType[item.service_type] || item.service_type),
        start_date_text: alignCenter(item.start_date),
        stop_date_text: (
          <div style={{ color: isExpire ? "red" : "" }}>{alignCenter(item.stop_date)}</div>
        ),
        status_text: (
          <div style={{ color: item.active ? "" : "red" }}>
            {item.active ? intl.formatMessage({id: "รับรองสิทธิ"}) : intl.formatMessage({id: "ระงับสิทธิ"}) }
          </div>
        ),
        priority_text: alignCenter(item.priority),
      };
    });
  }, [props.patientCoverageList]);

  console.log("CardPatientCoverage", props);

  return (
    <div>
      <CardPatientCoverageUX patientCoverageData={patientCoverageList} languageUX={props.languageUX}/>
    </div>
  );
};

CardPatientCoverage.displayName = "CardPatientCoverage";

export default React.memo(CardPatientCoverage);
