import { ContentTable, TDocumentDefinitions } from "pdfmake/interfaces";

import HeaderSummaryReportForm from "react-lib/apps/HISV3/BIL/pdfFormBil/HeaderSummaryReportForm";

// Types
type FormCopyReceiptProps = {
  copy_receipt_count: number;
  end_date: string | null;
  items: {
    coverage_name: string;
    created_date: string;
    created_user_name: string;
    encounter: string;
    hn: string;
    note: string;
    patient_name: string;
    receipt_code: string;
  }[];
  print_date_time: string;
  print_user: string;
  start_date: string | null;
};

interface ContentTableWithId extends Omit<ContentTable, "table"> {
  id?: string;
  table: ContentTable["table"];
}

interface ITDocumentDefinitions extends TDocumentDefinitions {
  content: (ContentTableWithId | TDocumentDefinitions["content"])[];
}

const FormCopyReceipt = async (props: FormCopyReceiptProps): Promise<ITDocumentDefinitions> => {
  const headerForm = await HeaderSummaryReportForm({
    data: { print_date_time: props.print_date_time || "-", print_user: props.print_user || "-" },
    isLightLines: true,
    pageMargins: [10, 102.5, 10, 15],
    header: [
      {
        marginTop: 12.5,
        style: "fieldHeader",
        text: "รายงานการพิมพ์สำเนาใบเสร็จ",
      },
      {
        bold: false,
        margin: [0, 7.5, 0, 12.5],
        style: "fieldHeader",
        text: `วันที่พิมพ์สำเนาใบเสร็จ ตั้งแต่ ${props.start_date || "-"} ถึง ${
          props.end_date || "-"
        }`,
      },
    ],
  });

  const { font, fontSizes, images, styles } = headerForm;

  console.log("FormCopyReceipt", props);

  return {
    ...headerForm,
    defaultStyle: {
      font,
      fontSize: fontSizes[12], // original fontSizes[16]
    },
    images: {
      ...images,
    },
    pageOrientation: "landscape",
    pageSize: "A4",
    styles: {
      tableHeader: {
        alignment: "center",
        bold: true,
      },
      ...styles,
    },
    content: [
      {
        table: {
          dontBreakRows: true,
          headerRows: 1,
          widths: ["auto", "*", "auto", "auto", "auto", "auto", "*", "25.5%"], // issue 67710 original ["auto", "*", "auto", "auto", "auto", "auto", "*", "*"]
          body: [
            // Header Row
            [
              { style: "tableHeader", text: "วันที่พิมพ์สำนาใบเสร็จ" },
              { style: "tableHeader", text: "เจ้าหน้าที่ปฏิบัติงาน" },
              { style: "tableHeader", text: "เลขที่ใบเสร็จ" },
              { style: "tableHeader", text: "HN" },
              { style: "tableHeader", text: "Encounter" },
              { style: "tableHeader", text: "สิทธิการรักษา" },
              { style: "tableHeader", text: "ชื่อ-นามสกุล" },
              { style: "tableHeader", text: "เหตุผลที่พิมพ์สำนาใบเสร็จ" },
            ],
            // Data Rows
            ...props.items.map((item) => [
              { text: item.created_date },
              { text: item.created_user_name },
              { text: item.receipt_code },
              { text: item.hn },
              { text: item.encounter },
              { text: item.coverage_name },
              { text: item.patient_name },
              { text: item.note },
            ]),
          ],
        },
        layout: {
          hLineWidth: () => 0.25,
          vLineWidth: () => 0.25,
        },
      },
      {
        alignment: "right",
        margin: [0, 20, 0, 0],
        text: `จำนวนใบเสร็จที่พิมพ์สำนาใบเสร็จทั้งหมด ${props.copy_receipt_count} ใบ`,
      },
    ],
  };
};

export default FormCopyReceipt;
