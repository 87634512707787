import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Dropdown,
  Label
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardDrugResuscitationOPDUx = (props: any) => {
    return(
      <div
        style={{width: "100%", padding: "15px 10px"}}>
        
        <div
          style={{}}>
          
          <div
            style={{fontSize: "1rem", fontWeight: "bold"}}>
            {props.titleDrug}
          </div>
          <div
            style={{display:"flex", justifyContent: "flex-end", alignItems:"center"}}>
            
            <div
              style={{display:"flex", justifyContent: "row", alignItems:"center"}}>
              
              <label
                style={{marginRight: "10px"}}>
                กรอกรหัส
              </label>
              <div>
                {props.inputDrugCode}
              </div>
              <Dropdown
                name="codeDrugsBox"
                onChange={props.onChangeData}
                options={props.codeDrugsBoxOptions}
                selection={true}
                style={{width: "300px",marginRight: "20px"}}
                value={props.codeDrugsBox}>
              </Dropdown>
            </div>
          </div>
          <div>
            
            <Table
              data={props.labDrugs}
              getTrProps={props.RowProps}
              headers=",Product,Form,Strength/Contain,จำนวนที่ใช้ได้"
              keys="button,trade_name,dosage_form,size,quantity"
              minRows="4"
              showPagination={false}
              style={{marginTop: "2rem",height: "180px"}}>
            </Table>
          </div>
          <div
            style={{display:"flex", justifyContent: "row", alignItems:"center",marginTop: "2rem"}}>
            
            <Label
              style={{flex: 0.4,textAlign: "center", backgroundColor: "#FDDFA3",fontSize: "1rem", minWidth: "max-content"}}>
              รายการยาที่สั่ง
            </Label>
            <Label
              color="blue"
              style={{flex: 0.4,textAlign: "center",fontSize: "1.3rem",marginLeft: "10px", ...(!props.drugCode && {display: "none"}) }}>
              {props.drugCode}
            </Label>
            <div
              style={{flex: 4}}>
              
            </div>
          </div>
          <div>
            
            <Table
              data={props.orderDrugs}
              getTdProps={props.oderDrugsColumnProps}
              getTrProps={props.oderDrugsRowProps}
              headers="Product,Qty,Dose,Unit,Route,Site,Frequency,Method,Time,Nurse,"
              keys="box,quantity,dose,unit,routeText,site,frequency,method,time,nurse,button"
              minRows="4"
              showPagination={false}
              style={{marginTop: "1rem",height: "180px"}}>
            </Table>
          </div>
          <div
            style={{display:"flex", justifyContent: "flex-end", alignItems:"center",marginTop: "2rem"}}>
            
            <div
              style={{display:"flex", justifyContent: "row", alignItems:"center"}}>
              
              <div>
                {props.buttonSave}
              </div>
              <div>
                {props.buttonCancel}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}


export default CardDrugResuscitationOPDUx

export const screenPropsDefault = {}

/* Date Time : Fri Oct 18 2024 14:50:33 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", padding: \"15px 10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.titleDrug"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1rem\", fontWeight: \"bold\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", justifyContent: \"flex-end\", alignItems:\"center\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", justifyContent: \"row\", alignItems:\"center\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "label",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "กรอกรหัส"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"10px\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "Dropdown",
      "parent": 4,
      "props": {
        "name": {
          "type": "value",
          "value": "codeDrugsBox"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.codeDrugsBoxOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"300px\",marginRight: \"20px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.codeDrugsBox"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "code",
          "value": "props.inputDrugCode"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 12,
      "name": "Table",
      "parent": 11,
      "props": {
        "data": {
          "type": "code",
          "value": "props.labDrugs"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.RowProps"
        },
        "headers": {
          "type": "value",
          "value": ",Product,Form,Strength/Contain,จำนวนที่ใช้ได้"
        },
        "keys": {
          "type": "value",
          "value": "button,trade_name,dosage_form,size,quantity"
        },
        "minRows": {
          "type": "value",
          "value": "4"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"2rem\",height: \"180px\"}"
        },
        "widths": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", justifyContent: \"row\", alignItems:\"center\",marginTop: \"2rem\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", justifyContent: \"flex-end\", alignItems:\"center\",marginTop: \"2rem\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "Label",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการยาที่สั่ง"
        },
        "style": {
          "type": "code",
          "value": "{flex: 0.4,textAlign: \"center\", backgroundColor: \"#FDDFA3\",fontSize: \"1rem\", minWidth: \"max-content\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "Label",
      "parent": 13,
      "props": {
        "children": {
          "type": "code",
          "value": "props.drugCode"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "style": {
          "type": "code",
          "value": "{flex: 0.4,textAlign: \"center\",fontSize: \"1.3rem\",marginLeft: \"10px\", ...(!props.drugCode && {display: \"none\"}) }"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: 4}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 19,
      "name": "Table",
      "parent": 14,
      "props": {
        "data": {
          "type": "code",
          "value": "props.orderDrugs"
        },
        "getTdProps": {
          "type": "code",
          "value": "props.oderDrugsColumnProps"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.oderDrugsRowProps"
        },
        "headers": {
          "type": "value",
          "value": "Product,Qty,Dose,Unit,Route,Site,Frequency,Method,Time,Nurse,"
        },
        "keys": {
          "type": "value",
          "value": "box,quantity,dose,unit,routeText,site,frequency,method,time,nurse,button"
        },
        "minRows": {
          "type": "value",
          "value": "4"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"1rem\",height: \"180px\"}"
        },
        "widths": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", justifyContent: \"row\", alignItems:\"center\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonCancel"
        }
      },
      "seq": 25,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 70,
  "isMounted": false,
  "memo": false,
  "name": "CardDrugResuscitationOPDUx",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 75
}

*********************************************************************************** */
