import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Form,
  FormGroup,
  FormField,
  Checkbox,
  Button
} from 'semantic-ui-react'

const CardAnesDoctorFeeListUX = (props: any) => {
    return(
      <div
        id="CardAnesDoctorFeeList"
        style={{padding: "15px"}}>
        <Form>
          <FormGroup>
            <FormField
              width={3}>
              <Checkbox
                checked={props.AnesDoctorFeeListSequence?.anesFilter?.checkedDate}
                label="วันที่ผ่าตัด"
                name="checkedDate"
                onChange={props.onChangeFilter}
                style={{marginBottom: "0.5rem", minWidth: "max-content"}}>
              </Checkbox>
              <div
                style={{width: "100%"}}>
                {props.startDateTextBox}
              </div>
            </FormField>
            <FormField>
              <label>
                {"\u00a0"}
              </label>
              <label
                style={{height: "100%", display: "flex", alignItems: "center", marginTop: "-0.75rem"}}>
                ถึง
              </label>
            </FormField>
            <FormField
              width={3}>
              <label>
                {"\u00a0"}
              </label>
              <div
                style={{width: "100%"}}>
                {props.endDateTextBox}
              </div>
            </FormField>
            <FormField
              width={3}>
              <Checkbox
                checked={props.AnesDoctorFeeListSequence?.anesFilter?.checkedEmployee}
                label="วิสัญญีแพทย์"
                name="checkedEmployee"
                onChange={props.onChangeFilter}
                style={{marginBottom: "0.5rem"}}>
              </Checkbox>
              <div
                style={{width: "100%"}}>
                {props.doctorSearchBox}
              </div>
            </FormField>
            <FormField
              width={3}>
              <Checkbox
                checked={props.AnesDoctorFeeListSequence?.anesFilter?.checkedDrFeeRule}
                label="Service Code/Product Name"
                name="checkedDrFeeRule"
                onChange={props.onChangeFilter}
                style={{marginBottom: "0.5rem", minWidth: "max-content"}}>
              </Checkbox>
              <div
                style={{width: "100%"}}>
                {props.doctorFeeRuleSearchBox}
              </div>
            </FormField>
            <FormField>
              <label
                style={{marginBottom: "0.5rem"}}>
                {"\u00a0"}
              </label>
              <div>
                {props.buttonSearch}
              </div>
            </FormField>
            <FormField>
              <label
                style={{marginBottom: "0.5rem"}}>
                {"\u00a0"}
              </label>
              <div>
                {props.buttonPrint}
              </div>
            </FormField>
            <FormField>
              <label
                style={{marginBottom: "0.5rem"}}>
                {"\u00a0"}
              </label>
              <Button
                color="yellow"
                onClick={props.onClear}
                size="small"
                style={{minWidth: "max-content", margin: 0}}>
                ล้างการค้นหา
              </Button>
            </FormField>
          </FormGroup>
        </Form>
        <div
          style={{ display: props.showStatistic ? "none" : "" }}>
          
          <Table
            data={props.AnesDoctorFeeListSequence?.drFeeList?.items || []}
            headers="วันที่ผ่าตัด, วิสัญญีแพทย์, HN, ชื่อ - สกุล, Service Code, Product Name, จำนวนที่สั่ง, ราคาต่อหน่วย, ราคารวม, ค่าตอบแทน"
            keys="performDT, received_employee_display, hn, patient_name, service_code, doctor_fee_rule_display, quantity, price_unit, price_total, final_compensation_price"
            minRows={10}
            showPagination={false}
            style={{height: "calc(100dvh - 15.5rem)"}}
            widths="^95,^95,^80,^95,^80,^95,^80,^80,^80,^80">
          </Table>
        </div>
      </div>
    )
}


export default CardAnesDoctorFeeListUX

export const screenPropsDefault = {}

/* Date Time : Wed Dec 06 2023 12:24:00 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardAnesDoctorFeeList"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"15px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.showStatistic ? \"none\" : \"\" }"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 33,
      "name": "Table",
      "parent": 22,
      "props": {
        "data": {
          "type": "code",
          "value": "props.AnesDoctorFeeListSequence?.drFeeList?.items || []"
        },
        "headers": {
          "type": "value",
          "value": "วันที่ผ่าตัด, วิสัญญีแพทย์, HN, ชื่อ - สกุล, Service Code, Product Name, จำนวนที่สั่ง, ราคาต่อหน่วย, ราคารวม, ค่าตอบแทน"
        },
        "keys": {
          "type": "value",
          "value": "performDT, received_employee_display, hn, patient_name, service_code, doctor_fee_rule_display, quantity, price_unit, price_total, final_compensation_price"
        },
        "minRows": {
          "type": "code",
          "value": "10"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"calc(100dvh - 15.5rem)\"}"
        },
        "widths": {
          "type": "value",
          "value": "^95,^95,^80,^95,^80,^95,^80,^80,^80,^80"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "FormGroup",
      "parent": 61,
      "props": {
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "FormField",
      "parent": 62,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "FormField",
      "parent": 62,
      "props": {
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "FormField",
      "parent": 62,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "FormField",
      "parent": 62,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "FormField",
      "parent": 62,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "FormField",
      "parent": 62,
      "props": {
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "FormField",
      "parent": 62,
      "props": {
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "FormField",
      "parent": 62,
      "props": {
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "Checkbox",
      "parent": 63,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesDoctorFeeListSequence?.anesFilter?.checkedDate"
        },
        "label": {
          "type": "value",
          "value": "วันที่ผ่าตัด"
        },
        "name": {
          "type": "value",
          "value": "checkedDate"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.5rem\", minWidth: \"max-content\"}"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "div",
      "parent": 63,
      "props": {
        "children": {
          "type": "code",
          "value": "props.startDateTextBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "label",
      "parent": 64,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "label",
      "parent": 64,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "style": {
          "type": "code",
          "value": "{height: \"100%\", display: \"flex\", alignItems: \"center\", marginTop: \"-0.75rem\"}"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "label",
      "parent": 65,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "div",
      "parent": 65,
      "props": {
        "children": {
          "type": "code",
          "value": "props.endDateTextBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 77,
      "name": "Checkbox",
      "parent": 66,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesDoctorFeeListSequence?.anesFilter?.checkedEmployee"
        },
        "label": {
          "type": "value",
          "value": "วิสัญญีแพทย์"
        },
        "name": {
          "type": "value",
          "value": "checkedEmployee"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.5rem\"}"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "Checkbox",
      "parent": 67,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.AnesDoctorFeeListSequence?.anesFilter?.checkedDrFeeRule"
        },
        "label": {
          "type": "value",
          "value": "Service Code/Product Name"
        },
        "name": {
          "type": "value",
          "value": "checkedDrFeeRule"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.5rem\", minWidth: \"max-content\"}"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "div",
      "parent": 66,
      "props": {
        "children": {
          "type": "code",
          "value": "props.doctorSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "div",
      "parent": 67,
      "props": {
        "children": {
          "type": "code",
          "value": "props.doctorFeeRuleSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "label",
      "parent": 68,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.5rem\"}"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "label",
      "parent": 69,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.5rem\"}"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "label",
      "parent": 70,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.5rem\"}"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 86,
      "name": "Button",
      "parent": 70,
      "props": {
        "children": {
          "type": "value",
          "value": "ล้างการค้นหา"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClear"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", margin: 0}"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 68,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearch"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 69,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonPrint"
        }
      },
      "seq": 88,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardAnesDoctorFeeListUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
