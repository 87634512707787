import React, { useEffect } from "react";
// ui ORM
import CardDefibrillatorUX from "./CardDefibrillatorUX";
import { Button, Checkbox, Form, Input } from "semantic-ui-react";
import TimeComboBox from "react-lib/apps/common/TimeComboBox";
import PreOPButtonAction from "./PreOPButtonAction";
import { useIntl } from "react-intl";

const CARD_KEY: string = "defibrillatorData";
const FORM_CODE: string = "CardDefibrillator";
const FORM_NAME: string = "Defibrillator";
const FORM_VERSION: string = "1.0";

type CardDefibrillatorProps = {
  // function
  onEvent: any;
  setProp: any;

  // CommonInterface
  buttonLoadCheck?: any;

  // select OR
  selectedOrOrder?: any;

  // options
  masterOptions?: any;

  // seq
  runSequence?: any;
  PerioperativeNursingSequence?: any;
};

const CardDefibrillatorInitial: CardDefibrillatorProps = {
  // funtion
  onEvent: () => null,
  setProp: () => null,

  // CommonInterface
  buttonLoadCheck: null,

  // select OR
  selectedOrOrder: {},

  // options
  masterOptions: {},

  // seq
  runSequence: null,
  PerioperativeNursingSequence: {},
};
const CardDefibrillator: React.FC<CardDefibrillatorProps> = (props: any) => {
  useEffect(() => {
    props.runSequence({
      sequence: "PerioperativeNursing",
    });
  }, []);

  const handleSave = () => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "SAVE",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formName: FORM_NAME,
      formVersion: FORM_VERSION,
    });
  };

  const handleConfirm = () => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "CONFIRM",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formName: FORM_NAME,
      formVersion: FORM_VERSION,
    });
  };

  const handleUnconfirm = () => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "UNCONFIRM",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formName: FORM_NAME,
      formVersion: FORM_VERSION,
    });
  };

  const handleChangeData = (_event: any, data: any) => {
    let value = "";
    if (data.type === "checkbox") {
      value = data.checked;
    } else {
      value = data.value;
    }
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "SET_DATA",
      cardKey: CARD_KEY,
      key: data.name,
      value: value,
    });
  };

  const handlerAddDefibrillator = () => {
    const Defibrillator = {
      defibrillator: "",
      time: "",
      power: "",
    };
    const items: any[] =
      props.PerioperativeNursingSequence?.defibrillatorData?.data
        ?.defibrillator || [];

    items.push(Defibrillator);
    props.setProp(
      "PerioperativeNursingSequence.defibrillatorData.data.defibrillator",
      items
    );
  };

  const handlerDeleteDefibrillator = (index: any) => {
    const items: any[] =
      props.PerioperativeNursingSequence?.defibrillatorData?.data
        ?.defibrillator || [];

    if (items?.length === 1) {
      props.setProp(
        "PerioperativeNursingSequence.defibrillatorData.data.defibrillator.0",
        {
          ...props.PerioperativeNursingSequence?.defibrillatorData?.data
            ?.defibrillator?.[0],
          defibrillator: "",
          time: "",
          power: "",
        }
      );
    } else if (items.length > 1) {
      items.splice(index, 1);
      props.setProp(
        "PerioperativeNursingSequence.defibrillatorData.data.defibrillator",
        items
      );
    }
  };

  const handleChangeDefibrillator = (value: any, index: any, key: any) => {
    console.log("handleChangeDefibrillator", key, value);

    props.setProp(
      `PerioperativeNursingSequence.defibrillatorData.data.defibrillator.${index}.${key}`,
      value
    );
  };

  return (
    <>
      <CardDefibrillatorUX
        masterOptions={props.masterOptions}
        handleChangeData={handleChangeData}
        PerioperativeNursingSequence={props.PerioperativeNursingSequence}
        status={props.PerioperativeNursingSequence?.defibrillatorData?.status}
        isDefibrillator={
          props.PerioperativeNursingSequence?.defibrillatorData?.data
            ?.is_defibrillator
        }
        defibrillatorPadSite={
          props.PerioperativeNursingSequence?.defibrillatorData?.data
            ?.defibrillator_pad_site
        }
        defibrillator={
          <Form>
            {props.PerioperativeNursingSequence?.defibrillatorData?.data?.defibrillator?.map(
              (item: any, index: any) => {
                return (
                  <DefibrillatorData
                    item={item}
                    index={index}
                    onChangeDefibrillator={handleChangeDefibrillator}
                    handlerDeleteDefibrillator={handlerDeleteDefibrillator}
                    handlerAddDefibrillator={handlerAddDefibrillator}
                    orTourniquetSide={props.masterOptions?.orTourniquetSide}
                  />
                );
              }
            )}
          </Form>
        }
        buttonSave={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PerioperativeNursingSequence}
            type="save"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleSave}
          />
        }
        buttonConfirm={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PerioperativeNursingSequence}
            type="confirm"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleConfirm}
          />
        }
        buttonUnconfirm={
          <PreOPButtonAction
            setProp={props.setProp}
            data={props.PerioperativeNursingSequence}
            type="unconfirm"
            cardKey={CARD_KEY}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleUnconfirm}
          />
        }
        languageUX={props.languageUX}
      />
    </>
  );
};

const DefibrillatorData = (props: any) => {
  const handleChange = (key: string) => (e: any, data?: any) => {
    const value = typeof e === "string" ? e : data.value;
    props.onChangeDefibrillator(value, props.index, key);
  };

  const handleChangeCheck = (key: string) => (e: any, data?: any) => {
    let value = "";
    if (data.checked) {
      value = data.name;
    } else {
      value = "";
    }
    props.onChangeDefibrillator(value, props.index, key);
  };

  return (
    <Form.Group inline>
      <Form.Field inline>
        <Checkbox
          label="External"
          checked={props.item.defibrillator === "external"}
          name="external"
          onChange={handleChangeCheck("defibrillator")}
        ></Checkbox>
      </Form.Field>
      <Form.Field inline>
        <Checkbox
          label="Internal"
          checked={props.item.defibrillator === "internal"}
          name="internal"
          onChange={handleChangeCheck("defibrillator")}
        ></Checkbox>
      </Form.Field>
      <Form.Field inline width={4}>
        <div style={{ minWidth: "max-content", marginLeft: "1rem" }}>
          Time on
        </div>

        <TimeComboBox
          fluid={true}
          style={{ width: "100%" }}
          defaultValue={props.item.time}
          onTextChange={handleChange("time")}
        ></TimeComboBox>
      </Form.Field>
      <Form.Field inline width={4}>
        <div style={{ minWidth: "max-content" }}>Power</div>
        <Input
          fluid={true}
          value={props.item.power}
          onChange={handleChange("power")}
        ></Input>
      </Form.Field>
      <Form.Field inline style={{ display: "flex" }}>
        <Button
          color="red"
          icon="minus"
          size="mini"
          style={{ margin: "0px 20px" }}
          onClick={() => props.handlerDeleteDefibrillator(props.index)}
        ></Button>
        <Button
          color="blue"
          icon="plus"
          size="mini"
          style={{ display: props.index === 0 ? "block" : "none" }}
          onClick={() => props.handlerAddDefibrillator()}
        ></Button>
      </Form.Field>
    </Form.Group>
  );
};

CardDefibrillator.defaultProps = CardDefibrillatorInitial;

export default React.memo(CardDefibrillator);
