import * as SegmentI from "./Segment";

const SequencePattern: {
  [name: string]: { [flavor: string]: { [func: string]: any } };
} = {
  Segment: {
    default: {
      START: SegmentI.Start,
      Action: SegmentI.Action,
    },
  },
};

export default SequencePattern;
