import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button
} from 'semantic-ui-react'

const CardPerioperativeNursingRecordUX = (props: any) => {
    return(
      <div
        id="CardPerioperativeNursingRecord">
        <div
          style={{ paddingTop: "4px", marginBottom: "-4px" }}>
          
          <Button
            color={props.containerName === "BasicInfo" ? "blue":  undefined}
            name="BasicInfo"
            onClick={props.onSetContainerName}
            style={{marginLeft: "15px"}}>
            Basic Info
          </Button>
          <Button
            color={props.containerName === "StepA-H" ? "blue":  undefined}
            name="StepA-H"
            onClick={props.onSetContainerName}>
            Step A-H
          </Button>
          <Button
            color={props.containerName === "OtherForm" ? "blue":  undefined}
            name="OtherForm"
            onClick={props.onSetContainerName}>
            Other Form
          </Button>
          <Button
            color={props.containerName === "ByEquipment" ? "blue":  undefined}
            name="ByEquipment"
            onClick={props.onSetContainerName}>
            By Equipment
          </Button>
          <Button
            color={props.containerName === "PostopeartiveNursingRecord" ? "blue":  undefined}
            name="PostopeartiveNursingRecord"
            onClick={props.onSetContainerName}>
            Postopeartive Nursing Record
          </Button>
        </div>
        <hr>
        </hr>
      </div>
    )
}


export default CardPerioperativeNursingRecordUX

export const screenPropsDefault = {}

/* Date Time : Wed Jul 17 2024 12:23:57 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPerioperativeNursingRecord"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ paddingTop: \"4px\", marginBottom: \"-4px\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "Basic Info"
        },
        "color": {
          "type": "code",
          "value": "props.containerName === \"BasicInfo\" ? \"blue\":  undefined"
        },
        "name": {
          "type": "value",
          "value": "BasicInfo"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSetContainerName"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"15px\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "Step A-H"
        },
        "color": {
          "type": "code",
          "value": "props.containerName === \"StepA-H\" ? \"blue\":  undefined"
        },
        "name": {
          "type": "value",
          "value": "StepA-H"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSetContainerName"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "Other Form"
        },
        "color": {
          "type": "code",
          "value": "props.containerName === \"OtherForm\" ? \"blue\":  undefined"
        },
        "name": {
          "type": "value",
          "value": "OtherForm"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSetContainerName"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "By Equipment"
        },
        "color": {
          "type": "code",
          "value": "props.containerName === \"ByEquipment\" ? \"blue\":  undefined"
        },
        "name": {
          "type": "value",
          "value": "ByEquipment"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSetContainerName"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "Postopeartive Nursing Record"
        },
        "color": {
          "type": "code",
          "value": "props.containerName === \"PostopeartiveNursingRecord\" ? \"blue\":  undefined"
        },
        "name": {
          "type": "value",
          "value": "PostopeartiveNursingRecord"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSetContainerName"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "hr",
      "parent": 0,
      "props": {
      },
      "seq": 9,
      "void": true
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardPerioperativeNursingRecordUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
