import React from 'react'
import {
   Checkbox,
   Dropdown,
   Input,
} from 'semantic-ui-react'



import styles from './OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeft.module.css'

export interface OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeftViewDataType {
    CtReach: string,
    CtReachDisabled: boolean,
    CtReachOptions: any,
    OtPhysicalDysfunctionHandFunctionLeftLeft5: string,
    OtPhysicalDysfunctionHandFunctionLeftLeft5Disabled: boolean,
    CtGrasp: string,
    CtGraspDisabled: boolean,
    CtGraspOptions: any,
    OtPhysicalDysfunctionHandFunctionLeftLeft10: string,
    OtPhysicalDysfunctionHandFunctionLeftLeft10Disabled: boolean,
    CtHoldCarry: string,
    CtHoldCarryDisabled: boolean,
    CtHoldCarryOptions: any,
    OtPhysicalDysfunctionHandFunctionLeftLeft15: string,
    OtPhysicalDysfunctionHandFunctionLeftLeft15Disabled: boolean,
    CtRelease: string,
    CtReleaseDisabled: boolean,
    CtReleaseOptions: any,
    OtPhysicalDysfunctionHandFunctionLeftLeft20: string,
    OtPhysicalDysfunctionHandFunctionLeftLeft20Disabled: boolean,
    CtGraspLarge: string,
    CtGraspLargeDisabled: boolean,
    CtGraspLargeOptions: any,
    OtPhysicalDysfunctionHandFunctionLeft27: string,
    OtPhysicalDysfunctionHandFunctionLeft27Disabled: boolean,
    CtHookGrasp: string,
    CtHookGraspDisabled: boolean,
    CtHookGraspOptions: any,
    OtPhysicalDysfunctionHandFunctionLeft32: string,
    OtPhysicalDysfunctionHandFunctionLeft32Disabled: boolean,
    CtCylindrical: string,
    CtCylindricalDisabled: boolean,
    CtCylindricalOptions: any,
    OtPhysicalDysfunctionHandFunctionLeft37: string,
    OtPhysicalDysfunctionHandFunctionLeft37Disabled: boolean,
    CtSpherical: string,
    CtSphericalDisabled: boolean,
    CtSphericalOptions: any,
    OtPhysicalDysfunctionHandFunctionLeft42: string,
    OtPhysicalDysfunctionHandFunctionLeft42Disabled: boolean,
    CtTripodPinch: string,
    CtTripodPinchDisabled: boolean,
    CtTripodPinchOptions: any,
    OtPhysicalDysfunctionHandFunctionLeft47: string,
    OtPhysicalDysfunctionHandFunctionLeft47Disabled: boolean,
    CtLateralPinch: string,
    CtLateralPinchDisabled: boolean,
    CtLateralPinchOptions: any,
    OtPhysicalDysfunctionHandFunctionLeft52: string,
    OtPhysicalDysfunctionHandFunctionLeft52Disabled: boolean,
    CtPadToPad: string,
    CtPadToPadDisabled: boolean,
    CtPadToPadOptions: any,
    OtPhysicalDysfunctionHandFunctionLeft58: string,
    OtPhysicalDysfunctionHandFunctionLeft58Disabled: boolean,
    CtTipPinch: string,
    CtTipPinchDisabled: boolean,
    CtTipPinchOptions: any,
    OtPhysicalDysfunctionHandFunctionLeft63: string,
    OtPhysicalDysfunctionHandFunctionLeft63Disabled: boolean,
    OtPhysicalDysfunctionHandFunctionLeft65: string,
    OtPhysicalDysfunctionHandFunctionLeft65Disabled: boolean,
    OtPhysicalDysfunctionHandFunctionLeft67: string,
    OtPhysicalDysfunctionHandFunctionLeft67Disabled: boolean,
    OtPhysicalDysfunctionHandFunctionLeft68: boolean,
    OtPhysicalDysfunctionHandFunctionLeft68Disabled: boolean,
    OtPhysicalDysfunctionHandFunctionLeft71: boolean,
    OtPhysicalDysfunctionHandFunctionLeft71Disabled: boolean,
    OtPhysicalDysfunctionHandFunctionLeft73: string,
    OtPhysicalDysfunctionHandFunctionLeft73Disabled: boolean,
    OtPhysicalDysfunctionHandFunctionLeft74: boolean,
    OtPhysicalDysfunctionHandFunctionLeft74Disabled: boolean,
    OtPhysicalDysfunctionHandFunctionLeft76: string,
    OtPhysicalDysfunctionHandFunctionLeft76Disabled: boolean,
    OtPhysicalDysfunctionHandFunctionLeft78: boolean,
    OtPhysicalDysfunctionHandFunctionLeft78Disabled: boolean,
    OtPhysicalDysfunctionHandFunctionLeft79: boolean,
    OtPhysicalDysfunctionHandFunctionLeft79Disabled: boolean,
    OtPhysicalDysfunctionHandFunctionLeft82: boolean,
    OtPhysicalDysfunctionHandFunctionLeft82Disabled: boolean,
    OtPhysicalDysfunctionHandFunctionLeft84: string,
    OtPhysicalDysfunctionHandFunctionLeft84Disabled: boolean,
    OtPhysicalDysfunctionHandFunctionLeft85: boolean,
    OtPhysicalDysfunctionHandFunctionLeft85Disabled: boolean,
    OtPhysicalDysfunctionHandFunctionLeft86: string,
    OtPhysicalDysfunctionHandFunctionLeft86Disabled: boolean,
    OtPhysicalDysfunctionHandFunctionLeft88: boolean,
    OtPhysicalDysfunctionHandFunctionLeft88Disabled: boolean,
    OtPhysicalDysfunctionHandFunctionLeft89: boolean,
    OtPhysicalDysfunctionHandFunctionLeft89Disabled: boolean,
    OtPhysicalDysfunctionHandFunctionLeft92: boolean,
    OtPhysicalDysfunctionHandFunctionLeft92Disabled: boolean,
    OtPhysicalDysfunctionHandFunctionLeft94: string,
    OtPhysicalDysfunctionHandFunctionLeft94Disabled: boolean,
}

export const OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeftInitialViewData: OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeftViewDataType = {
    CtReach: "",
    CtReachDisabled: false,
    CtReachOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalDysfunctionHandFunctionLeftLeft5: "",
    OtPhysicalDysfunctionHandFunctionLeftLeft5Disabled: false,
    CtGrasp: "",
    CtGraspDisabled: false,
    CtGraspOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalDysfunctionHandFunctionLeftLeft10: "",
    OtPhysicalDysfunctionHandFunctionLeftLeft10Disabled: false,
    CtHoldCarry: "",
    CtHoldCarryDisabled: false,
    CtHoldCarryOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalDysfunctionHandFunctionLeftLeft15: "",
    OtPhysicalDysfunctionHandFunctionLeftLeft15Disabled: false,
    CtRelease: "",
    CtReleaseDisabled: false,
    CtReleaseOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalDysfunctionHandFunctionLeftLeft20: "",
    OtPhysicalDysfunctionHandFunctionLeftLeft20Disabled: false,
    CtGraspLarge: "",
    CtGraspLargeDisabled: false,
    CtGraspLargeOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalDysfunctionHandFunctionLeft27: "",
    OtPhysicalDysfunctionHandFunctionLeft27Disabled: false,
    CtHookGrasp: "",
    CtHookGraspDisabled: false,
    CtHookGraspOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalDysfunctionHandFunctionLeft32: "",
    OtPhysicalDysfunctionHandFunctionLeft32Disabled: false,
    CtCylindrical: "",
    CtCylindricalDisabled: false,
    CtCylindricalOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalDysfunctionHandFunctionLeft37: "",
    OtPhysicalDysfunctionHandFunctionLeft37Disabled: false,
    CtSpherical: "",
    CtSphericalDisabled: false,
    CtSphericalOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalDysfunctionHandFunctionLeft42: "",
    OtPhysicalDysfunctionHandFunctionLeft42Disabled: false,
    CtTripodPinch: "",
    CtTripodPinchDisabled: false,
    CtTripodPinchOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalDysfunctionHandFunctionLeft47: "",
    OtPhysicalDysfunctionHandFunctionLeft47Disabled: false,
    CtLateralPinch: "",
    CtLateralPinchDisabled: false,
    CtLateralPinchOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalDysfunctionHandFunctionLeft52: "",
    OtPhysicalDysfunctionHandFunctionLeft52Disabled: false,
    CtPadToPad: "",
    CtPadToPadDisabled: false,
    CtPadToPadOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalDysfunctionHandFunctionLeft58: "",
    OtPhysicalDysfunctionHandFunctionLeft58Disabled: false,
    CtTipPinch: "",
    CtTipPinchDisabled: false,
    CtTipPinchOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalDysfunctionHandFunctionLeft63: "",
    OtPhysicalDysfunctionHandFunctionLeft63Disabled: false,
    OtPhysicalDysfunctionHandFunctionLeft65: "",
    OtPhysicalDysfunctionHandFunctionLeft65Disabled: false,
    OtPhysicalDysfunctionHandFunctionLeft67: "",
    OtPhysicalDysfunctionHandFunctionLeft67Disabled: false,
    OtPhysicalDysfunctionHandFunctionLeft68: false,
    OtPhysicalDysfunctionHandFunctionLeft68Disabled: false,
    OtPhysicalDysfunctionHandFunctionLeft71: false,
    OtPhysicalDysfunctionHandFunctionLeft71Disabled: false,
    OtPhysicalDysfunctionHandFunctionLeft73: "",
    OtPhysicalDysfunctionHandFunctionLeft73Disabled: false,
    OtPhysicalDysfunctionHandFunctionLeft74: false,
    OtPhysicalDysfunctionHandFunctionLeft74Disabled: false,
    OtPhysicalDysfunctionHandFunctionLeft76: "",
    OtPhysicalDysfunctionHandFunctionLeft76Disabled: false,
    OtPhysicalDysfunctionHandFunctionLeft78: false,
    OtPhysicalDysfunctionHandFunctionLeft78Disabled: false,
    OtPhysicalDysfunctionHandFunctionLeft79: false,
    OtPhysicalDysfunctionHandFunctionLeft79Disabled: false,
    OtPhysicalDysfunctionHandFunctionLeft82: false,
    OtPhysicalDysfunctionHandFunctionLeft82Disabled: false,
    OtPhysicalDysfunctionHandFunctionLeft84: "",
    OtPhysicalDysfunctionHandFunctionLeft84Disabled: false,
    OtPhysicalDysfunctionHandFunctionLeft85: false,
    OtPhysicalDysfunctionHandFunctionLeft85Disabled: false,
    OtPhysicalDysfunctionHandFunctionLeft86: "",
    OtPhysicalDysfunctionHandFunctionLeft86Disabled: false,
    OtPhysicalDysfunctionHandFunctionLeft88: false,
    OtPhysicalDysfunctionHandFunctionLeft88Disabled: false,
    OtPhysicalDysfunctionHandFunctionLeft89: false,
    OtPhysicalDysfunctionHandFunctionLeft89Disabled: false,
    OtPhysicalDysfunctionHandFunctionLeft92: false,
    OtPhysicalDysfunctionHandFunctionLeft92Disabled: false,
    OtPhysicalDysfunctionHandFunctionLeft94: "",
    OtPhysicalDysfunctionHandFunctionLeft94Disabled: false,
}

const Sub = (props: any) => {
    const children = React.Children.toArray(props.children)
    return (
        <div className={styles.container}>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft100}>
                <div
                    style={{backgroundColor: "#F3F3F3", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft1}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Hand function</strong></p>
                </div>
            </div>
            <div className={styles.CtReach}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtReach}
                    disabled={ props.viewData.CtReachDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeft",
                                name: "CtReach",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtReachOptions}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeftLeft5}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionHandFunctionLeftLeft5}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionLeftLeft5Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeft',
                            name: 'OtPhysicalDysfunctionHandFunctionLeftLeft5',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CtGrasp}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtGrasp}
                    disabled={ props.viewData.CtGraspDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeft",
                                name: "CtGrasp",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtGraspOptions}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeftLeft9}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Reach</p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeftLeft10}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionHandFunctionLeftLeft10}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionLeftLeft10Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeft',
                            name: 'OtPhysicalDysfunctionHandFunctionLeftLeft10',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeftLeft11}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Grasp</p>
                </div>
            </div>
            <div className={styles.CtHoldCarry}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtHoldCarry}
                    disabled={ props.viewData.CtHoldCarryDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeft",
                                name: "CtHoldCarry",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtHoldCarryOptions}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeftLeft15}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionHandFunctionLeftLeft15}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionLeftLeft15Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeft',
                            name: 'OtPhysicalDysfunctionHandFunctionLeftLeft15',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CtRelease}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtRelease}
                    disabled={ props.viewData.CtReleaseDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeft",
                                name: "CtRelease",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtReleaseOptions}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeftLeft19}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Hold/Carry</p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeftLeft20}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionHandFunctionLeftLeft20}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionLeftLeft20Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeft',
                            name: 'OtPhysicalDysfunctionHandFunctionLeftLeft20',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft21}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Release</p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft22}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Hand prehension</strong></p>
                </div>
            </div>
            <div className={styles.CtGraspLarge}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtGraspLarge}
                    disabled={ props.viewData.CtGraspLargeDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeft",
                                name: "CtGraspLarge",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtGraspLargeOptions}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft27}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionHandFunctionLeft27}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionLeft27Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeft',
                            name: 'OtPhysicalDysfunctionHandFunctionLeft27',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CtHookGrasp}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtHookGrasp}
                    disabled={ props.viewData.CtHookGraspDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeft",
                                name: "CtHookGrasp",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtHookGraspOptions}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft31}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Grasp-large objects</p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft32}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionHandFunctionLeft32}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionLeft32Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeft',
                            name: 'OtPhysicalDysfunctionHandFunctionLeft32',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft33}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Hook grasp</p>
                </div>
            </div>
            <div className={styles.CtCylindrical}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtCylindrical}
                    disabled={ props.viewData.CtCylindricalDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeft",
                                name: "CtCylindrical",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtCylindricalOptions}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft37}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionHandFunctionLeft37}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionLeft37Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeft',
                            name: 'OtPhysicalDysfunctionHandFunctionLeft37',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CtSpherical}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtSpherical}
                    disabled={ props.viewData.CtSphericalDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeft",
                                name: "CtSpherical",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtSphericalOptions}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft41}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Cylindrical</p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft42}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionHandFunctionLeft42}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionLeft42Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeft',
                            name: 'OtPhysicalDysfunctionHandFunctionLeft42',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft43}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Spherical</p>
                </div>
            </div>
            <div className={styles.CtTripodPinch}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtTripodPinch}
                    disabled={ props.viewData.CtTripodPinchDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeft",
                                name: "CtTripodPinch",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtTripodPinchOptions}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft47}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionHandFunctionLeft47}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionLeft47Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeft',
                            name: 'OtPhysicalDysfunctionHandFunctionLeft47',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CtLateralPinch}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtLateralPinch}
                    disabled={ props.viewData.CtLateralPinchDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeft",
                                name: "CtLateralPinch",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtLateralPinchOptions}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft51}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Tripod pinch</p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft52}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionHandFunctionLeft52}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionLeft52Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeft',
                            name: 'OtPhysicalDysfunctionHandFunctionLeft52',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft54}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Lateral pinch</p>
                </div>
            </div>
            <div className={styles.CtPadToPad}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtPadToPad}
                    disabled={ props.viewData.CtPadToPadDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeft",
                                name: "CtPadToPad",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtPadToPadOptions}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft58}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionHandFunctionLeft58}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionLeft58Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeft',
                            name: 'OtPhysicalDysfunctionHandFunctionLeft58',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CtTipPinch}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtTipPinch}
                    disabled={ props.viewData.CtTipPinchDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeft",
                                name: "CtTipPinch",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtTipPinchOptions}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft62}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Pad to pad pinch</p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft63}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionHandFunctionLeft63}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionLeft63Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeft',
                            name: 'OtPhysicalDysfunctionHandFunctionLeft63',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft64}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Tip pinch</p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft65}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionHandFunctionLeft65}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionLeft65Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeft',
                            name: 'OtPhysicalDysfunctionHandFunctionLeft65',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft66}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Hand dexterity</strong></p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft67}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionHandFunctionLeft67}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionLeft67Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeft',
                            name: 'OtPhysicalDysfunctionHandFunctionLeft67',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft68}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtPhysicalDysfunctionHandFunctionLeft68}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionLeft68Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeft",
                                name: "OtPhysicalDysfunctionHandFunctionLeft68"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft69}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Grip strength</strong></p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft70}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>N/A</p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft71}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtPhysicalDysfunctionHandFunctionLeft71}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionLeft71Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeft",
                                name: "OtPhysicalDysfunctionHandFunctionLeft71"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft72}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>N/A</p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft73}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionHandFunctionLeft73}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionLeft73Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeft',
                            name: 'OtPhysicalDysfunctionHandFunctionLeft73',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft74}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtPhysicalDysfunctionHandFunctionLeft74}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionLeft74Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeft",
                                name: "OtPhysicalDysfunctionHandFunctionLeft74"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft75}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Pinch strength</strong></p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft76}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionHandFunctionLeft76}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionLeft76Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeft',
                            name: 'OtPhysicalDysfunctionHandFunctionLeft76',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft77}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Tripod pinch</p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft78}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtPhysicalDysfunctionHandFunctionLeft78}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionLeft78Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeft",
                                name: "OtPhysicalDysfunctionHandFunctionLeft78"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft79}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtPhysicalDysfunctionHandFunctionLeft79}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionLeft79Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeft",
                                name: "OtPhysicalDysfunctionHandFunctionLeft79"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft80}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>N/A</p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft81}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Lateral pinch</p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft82}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtPhysicalDysfunctionHandFunctionLeft82}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionLeft82Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeft",
                                name: "OtPhysicalDysfunctionHandFunctionLeft82"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft83}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>N/A</p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft84}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionHandFunctionLeft84}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionLeft84Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeft',
                            name: 'OtPhysicalDysfunctionHandFunctionLeft84',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft85}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtPhysicalDysfunctionHandFunctionLeft85}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionLeft85Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeft",
                                name: "OtPhysicalDysfunctionHandFunctionLeft85"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft86}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionHandFunctionLeft86}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionLeft86Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeft',
                            name: 'OtPhysicalDysfunctionHandFunctionLeft86',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft87}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Pad to pad pinch</p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft88}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtPhysicalDysfunctionHandFunctionLeft88}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionLeft88Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeft",
                                name: "OtPhysicalDysfunctionHandFunctionLeft88"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft89}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtPhysicalDysfunctionHandFunctionLeft89}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionLeft89Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeft",
                                name: "OtPhysicalDysfunctionHandFunctionLeft89"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft90}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>N/A</p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft91}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Tip pinch</p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft92}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtPhysicalDysfunctionHandFunctionLeft92}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionLeft92Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeft",
                                name: "OtPhysicalDysfunctionHandFunctionLeft92"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft93}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>N/A</p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft94}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionHandFunctionLeft94}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionLeft94Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeft',
                            name: 'OtPhysicalDysfunctionHandFunctionLeft94',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionLeft95}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Other</strong></p>
                </div>
            </div>
        </div>
    )
}

export default Sub

const getTsName = (cssClass: string) => {
    return cssClass.split('-').map((s) => (
        s.charAt(0).toUpperCase() + s.slice(1)
    )).join('')
}
