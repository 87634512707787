import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Label,
  Form,
  FormGroup,
  FormField,
  Dropdown
} from 'semantic-ui-react'

const CardSurgicalPositionUX  = (props: any) => {
    return(
      <div
        id="CardSurgicalPosition"
        style={{ margin: "15px",  padding: "20px",  backgroundColor: "rgba(198, 235, 243, 0.2)" }}>
        <div
          style={{ paddingBottom: "5px" }}>
          
          <label
            style={{ fontWeight: "bold", fontSize: "18px" }}>
            Surgical Position & Position AID (A)
          </label>
          <Label
            color={props.statusColor}
            style={{ float: "right" }}>
            {props.status}
          </Label>
          <hr>
          </hr>
        </div>
        <Form>
          <FormGroup
            inline={true}>
            <FormField
              className="required"
              inline={true}
              width={10}>
              <label
                style={{minWidth: "120px", maxWidth: "120px"}}>
                Position 
              </label>
              <Dropdown
                clearable={true}
                fluid={true}
                multiple={true}
                name="position"
                onChange={props.handleChangeData}
                options={props.masterOptions?.orSurgicalPosition}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.position}>
              </Dropdown>
            </FormField>
          </FormGroup>
        </Form>
        <Form>
          <FormGroup
            inline={true}>
            <FormField
              className="required"
              inline={true}
              width={10}>
              <label
                style={{minWidth: "120px", maxWidth: "120px"}}>
                Positioning AID
              </label>
              <Dropdown
                clearable={true}
                fluid={true}
                multiple={true}
                name="device"
                onChange={props.handleChangeData}
                options={props.masterOptions?.orSurgicalDevice}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.device}>
              </Dropdown>
            </FormField>
          </FormGroup>
        </Form>
        <Form>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={10}>
              <label
                style={{minWidth: "120px", maxWidth: "120px"}}>
                Warmer
              </label>
              <Dropdown
                clearable={true}
                fluid={true}
                multiple={true}
                name="warmer"
                onChange={props.handleChangeData}
                options={props.masterOptions?.orSurgicalWarmer}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.warmer}>
              </Dropdown>
            </FormField>
          </FormGroup>
        </Form>
        <Form>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={10}>
              <label
                style={{minWidth: "120px", maxWidth: "120px"}}>
                Circulatory Support
              </label>
              <Dropdown
                clearable={true}
                fluid={true}
                multiple={true}
                name="circulator_support"
                onChange={props.handleChangeData}
                options={props.masterOptions?.orSurgicalCirculatory}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.circulatorSupport}>
              </Dropdown>
            </FormField>
          </FormGroup>
        </Form>
        <div
          className="ui form"
          style={{ backgroundColor: "#FDF8DD", padding: "20px 15px", margin: "1rem 0px 0px" }}>
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={8}>
              <label
                style={{width: "100%", maxWidth: "max-content"}}>
                Nursing Diagnosis
              </label>
              <label
                style={{fontWeight: "normal"}}>
                {props.nurseDiagnosis}
              </label>
            </FormField>
            <FormField
              inline={true}
              width={8}>
              <label
                style={{width: "100%", maxWidth: "max-content"}}>
                Goal
              </label>
              <label
                style={{fontWeight: "normal"}}>
                {props.goal}
              </label>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{margin:0}}>
            <FormField
              inline={true}
              width={8}>
              <label
                style={{width: "100%", maxWidth: "max-content"}}>
                Plan and implementation
              </label>
              <Dropdown
                clearable={true}
                fluid={true}
                name="plan_and_implementation"
                onChange={props.handleChangeData}
                options={props.masterOptions?.orPlanAndImple}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.planAndImplementation}>
              </Dropdown>
            </FormField>
          </FormGroup>
        </div>
        <div
          style={{ height: "55px", paddingTop: "15px" }}>
          
          <div
            style={{display:"flex",justifyContent:"flex-end"}}>
            
            <div
              style={{margin:"10px"}}>
              {props.buttonSave}
            </div>
            <div
              style={{margin:"10px"}}>
              {props.buttonConfirm}
            </div>
            <div
              style={{margin:"10px"}}>
              {props.buttonUnconfirm}
            </div>
          </div>
        </div>
      </div>
    )
}


export default CardSurgicalPositionUX 

export const screenPropsDefault = {}

/* Date Time : Wed Jul 24 2024 16:12:24 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardSurgicalPosition"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"15px\",  padding: \"20px\",  backgroundColor: \"rgba(198, 235, 243, 0.2)\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ paddingBottom: \"5px\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "label",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Surgical Position & Position AID (A)"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"18px\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "Label",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.status"
        },
        "color": {
          "type": "code",
          "value": "props.statusColor"
        },
        "style": {
          "type": "code",
          "value": "{ float: \"right\" }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "hr",
      "parent": 1,
      "props": {
      },
      "seq": 4,
      "void": true
    },
    {
      "from": null,
      "id": 154,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ height: \"55px\", paddingTop: \"15px\" }"
        }
      },
      "seq": 183,
      "void": false
    },
    {
      "from": null,
      "id": 155,
      "name": "div",
      "parent": 154,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",justifyContent:\"flex-end\"}"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": null,
      "id": 160,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"10px\"}"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": null,
      "id": 161,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonConfirm"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"10px\"}"
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": null,
      "id": 162,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonUnconfirm"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"10px\"}"
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 163,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 139,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 164,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 154,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 165,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 163,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 166,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 164,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 167,
      "name": "FormGroup",
      "parent": 163,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 167,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 168,
      "name": "FormGroup",
      "parent": 164,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 168,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 169,
      "name": "FormGroup",
      "parent": 165,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 169,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 170,
      "name": "FormGroup",
      "parent": 166,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 170,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 171,
      "name": "FormField",
      "parent": 167,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "10"
        }
      },
      "seq": 171,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 172,
      "name": "FormField",
      "parent": 168,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "10"
        }
      },
      "seq": 172,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 173,
      "name": "FormField",
      "parent": 169,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "10"
        }
      },
      "seq": 173,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 174,
      "name": "FormField",
      "parent": 170,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "10"
        }
      },
      "seq": 174,
      "void": false
    },
    {
      "from": null,
      "id": 175,
      "name": "label",
      "parent": 172,
      "props": {
        "children": {
          "type": "value",
          "value": "Positioning AID"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"120px\", maxWidth: \"120px\"}"
        }
      },
      "seq": 175,
      "void": false
    },
    {
      "from": null,
      "id": 176,
      "name": "label",
      "parent": 173,
      "props": {
        "children": {
          "type": "value",
          "value": "Warmer"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"120px\", maxWidth: \"120px\"}"
        }
      },
      "seq": 176,
      "void": false
    },
    {
      "from": null,
      "id": 177,
      "name": "label",
      "parent": 174,
      "props": {
        "children": {
          "type": "value",
          "value": "Circulatory Support"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"120px\", maxWidth: \"120px\"}"
        }
      },
      "seq": 177,
      "void": false
    },
    {
      "from": null,
      "id": 178,
      "name": "label",
      "parent": 171,
      "props": {
        "children": {
          "type": "value",
          "value": "Position "
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"120px\", maxWidth: \"120px\"}"
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 179,
      "name": "Dropdown",
      "parent": 174,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "circulator_support"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.orSurgicalCirculatory"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.circulatorSupport"
        }
      },
      "seq": 179,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 180,
      "name": "Dropdown",
      "parent": 173,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "warmer"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.orSurgicalWarmer"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.warmer"
        }
      },
      "seq": 180,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 181,
      "name": "Dropdown",
      "parent": 172,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "device"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.orSurgicalDevice"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.device"
        }
      },
      "seq": 181,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 182,
      "name": "Dropdown",
      "parent": 171,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "position"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.orSurgicalPosition"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.position"
        }
      },
      "seq": 182,
      "void": false
    },
    {
      "from": null,
      "id": 183,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{ backgroundColor: \"#FDF8DD\", padding: \"20px 15px\", margin: \"1rem 0px 0px\" }"
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 184,
      "name": "FormGroup",
      "parent": 183,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 184,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 185,
      "name": "FormGroup",
      "parent": 183,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{margin:0}"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 185,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 186,
      "name": "FormField",
      "parent": 184,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 186,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 187,
      "name": "FormField",
      "parent": 184,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 187,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 188,
      "name": "FormField",
      "parent": 185,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 188,
      "void": false
    },
    {
      "from": null,
      "id": 189,
      "name": "label",
      "parent": 186,
      "props": {
        "children": {
          "type": "value",
          "value": "Nursing Diagnosis"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", maxWidth: \"max-content\"}"
        }
      },
      "seq": 189,
      "void": false
    },
    {
      "from": null,
      "id": 190,
      "name": "label",
      "parent": 187,
      "props": {
        "children": {
          "type": "value",
          "value": "Goal"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", maxWidth: \"max-content\"}"
        }
      },
      "seq": 190,
      "void": false
    },
    {
      "from": null,
      "id": 191,
      "name": "label",
      "parent": 187,
      "props": {
        "children": {
          "type": "code",
          "value": "props.goal"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 191,
      "void": false
    },
    {
      "from": null,
      "id": 192,
      "name": "label",
      "parent": 186,
      "props": {
        "children": {
          "type": "code",
          "value": "props.nurseDiagnosis"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 192,
      "void": false
    },
    {
      "from": null,
      "id": 193,
      "name": "label",
      "parent": 188,
      "props": {
        "children": {
          "type": "value",
          "value": "Plan and implementation"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", maxWidth: \"max-content\"}"
        }
      },
      "seq": 193,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 194,
      "name": "Dropdown",
      "parent": 188,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "plan_and_implementation"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.orPlanAndImple"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.planAndImplementation"
        }
      },
      "seq": 194,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 85,
  "isMounted": false,
  "memo": false,
  "name": "CardSurgicalPositionUX ",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
