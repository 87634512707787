import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Label,
  Dropdown,
  Checkbox,
  Button,
  Input,
  Radio
} from 'semantic-ui-react'

const CardORRegistrationAreaUX = (props: any) => {
    return(
      <div
        id="CardORRegistrationArea"
        style={{ margin: "15px", padding: "20px", backgroundColor: "rgba(198, 235, 243, 0.2)" }}>
        <div
          style={{ paddingBottom: "5px" }}>
          
          <label
            style={{ fontWeight: "bold", fontSize: "18px" }}>
            OR Registration Area
          </label>
          <Label
            color={props.statusColor}
            style={{ float: "right" }}>
            {props.PreOperationSequence?.orRegistrationAreaData?.status}
          </Label>
          <hr>
          </hr>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <div
            style={{ display: "flex", width: "50%" , alignItems: "center"}}>
            
            <label
              style={{ fontWeight: "bold", margin: "5px 10px 0px 0px" , width: "30%"}}>
              Type of Case
            </label>
            <label>
              {props.PreOperationSequence?.orRegistrationAreaData?.data?.order_type_label}
            </label>
          </div>
          <div
            style={{ display: "flex", width: "50%", alignItems: "center" }}>
            
            <div
              style={{ fontWeight: "bold", margin: "5px 10px" }}>
              
              <label>
                Transfer By
              </label>
              <label
                style={{ color: "red" }}>
                *
              </label>
            </div>
            <Dropdown
              name="transfer_by"
              onChange={props.onChangeData}
              options={props.masterOptions?.arriveStatus || []}
              selection={true}
              style={{ width: "70%" }}
              value={props.PreOperationSequence?.orRegistrationAreaData?.data?.transfer_by}>
            </Dropdown>
          </div>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <label
            style={{ fontWeight: "bold", width: "15%", margin: "5px 10px 0px 0px" }}>
            Type of Surgeon
          </label>
          <Dropdown
            name="type_surgeon"
            onChange={props.onChangeData}
            options={props.masterOptions?.orProcedureType || []}
            selection={true}
            style={{ width: "35%" }}
            value={props.PreOperationSequence?.orRegistrationAreaData?.data?.type_surgeon || ""}>
          </Dropdown>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <div
            style={{ fontWeight: "bold", width: "15%", margin: "5px 10px 0px 0px" }}>
            
            <label>
              Patient identification
            </label>
            <label
              style={{ color: "red" }}>
              *
            </label>
          </div>
          <Checkbox
            checked={props.PreOperationSequence?.orRegistrationAreaData?.data?.is_verbally}
            label="Verbally"
            name="is_verbally"
            onChange={props.onChangeData}
            style={{ fwidth: "10%", margin: "5px 10px 0px 0px" }}>
          </Checkbox>
          <Checkbox
            checked={props.PreOperationSequence?.orRegistrationAreaData?.data?.is_name_band}
            label="Name band"
            name="is_name_band"
            onChange={props.onChangeData}
            style={{ width: "10%", margin: "5px 10px", minWidth: "max-content" }}>
          </Checkbox>
          <Checkbox
            checked={props.PreOperationSequence?.orRegistrationAreaData?.data?.is_chart}
            label="Chart"
            name="is_chart"
            onChange={props.onChangeData}
            style={{ fmargin: "5px 10px" }}>
          </Checkbox>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <div
            style={{ fontWeight: "bold", width: "15%", margin: "5px 10px 0px 0px" }}>
            
            <label>
              Mark site Confirm
            </label>
            <label
              style={{ color: "red" }}>
              *
            </label>
          </div>
          <Radio
            checked={props.PreOperationSequence?.orRegistrationAreaData?.data?.mark_site_confirm === "yes"}
            label="Yes"
            name="mark_site_confirm"
            onChange={props.onChangeData}
            style={{ width: "10%", margin: "5px 10px 0px 0px" }}
            value="yes">
          </Radio>
          <Radio
            checked={props.PreOperationSequence?.orRegistrationAreaData?.data?.mark_site_confirm === "no"}
            label="No"
            name="mark_site_confirm"
            onChange={props.onChangeData}
            style={{width: "10%", margin: "5px 10px 0px 0px" }}
            value="no">
          </Radio>
          <Radio
            checked={props.PreOperationSequence?.orRegistrationAreaData?.data?.mark_site_confirm === "na"}
            label="N/A"
            name="mark_site_confirm"
            onChange={props.onChangeData}
            style={{  width: "10%", margin: "5px 10px 0px 0px" }}
            value="na">
          </Radio>
          <Input
            name="mark_site"
            onChange={props.onChangeData}
            style={{ width: "45%", display: props.PreOperationSequence?.orRegistrationAreaData?.data?.mark_site_confirm === "na" ? "" : "none" }}
            value={props.PreOperationSequence?.orRegistrationAreaData?.data?.mark_site || ""}>
          </Input>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <div
            style={{ fontWeight: "bold", width: "15%", margin: "5px 10px 0px 0px" }}>
            
            <label>
              Procedure Confirm
            </label>
            <label
              style={{ color: "red" }}>
              *
            </label>
          </div>
          <Checkbox
            checked={props.PreOperationSequence?.orRegistrationAreaData?.data?.is_procedure_confirm}
            label={props.PreOperationSequence?.orRegistrationAreaData?.data?.is_procedure_confirm ? "YES" : "NO"}
            name="is_procedure_confirm"
            onChange={props.onChangeData}
            toggle={true}>
          </Checkbox>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <div
            style={{ fontWeight: "bold", width: "15%", margin: "5px 10px 0px 0px" }}>
            
            <label>
              Consent Form
            </label>
            <label
              style={{ color: "red" }}>
              *
            </label>
          </div>
          <Checkbox
            checked={props.PreOperationSequence?.orRegistrationAreaData?.data?.is_consent_form}
            label={props.PreOperationSequence?.orRegistrationAreaData?.data?.is_consent_form ? "YES" : "NO"}
            name="is_consent_form"
            onChange={props.onChangeData}
            toggle={true}>
          </Checkbox>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <div
            style={{ fontWeight: "bold", width: "15%", margin: "5px 10px 0px 0px" }}>
            
            <label>
              NPO
            </label>
            <label
              style={{ color: "red" }}>
              *
            </label>
          </div>
          <Checkbox
            checked={props.PreOperationSequence?.orRegistrationAreaData?.data?.is_npo}
            label={props.PreOperationSequence?.orRegistrationAreaData?.data?.is_npo ? "YES" : "NO"}
            name="is_npo"
            onChange={props.onChangeData}
            toggle={true}>
          </Checkbox>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <div
            style={{ fontWeight: "bold", width: "15%", margin: "5px 10px 0px 0px" }}>
            
            <label>
              Valuable
            </label>
            <label
              style={{ color: "red" }}>
              *
            </label>
          </div>
          <Checkbox
            checked={props.PreOperationSequence?.orRegistrationAreaData?.data?.is_valuable}
            label={props.PreOperationSequence?.orRegistrationAreaData?.data?.is_valuable ? "YES" : "NO"}
            name="is_valuable"
            onChange={props.onChangeData}
            style={{ marginRight: "10px" }}
            toggle={true}>
          </Checkbox>
          <Dropdown
            clearable={true}
            multiple={true}
            name="valuable"
            onChange={props.onChangeData}
            options={props.masterOptions?.orValuable || []}
            search={true}
            selection={true}
            style={{ width: "45%", display: props.PreOperationSequence?.orRegistrationAreaData?.data?.is_valuable ? "" : "none" }}
            value={props.PreOperationSequence?.orRegistrationAreaData?.data?.valuable || []}>
          </Dropdown>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <div
            style={{ fontWeight: "bold", width: "15%", margin: "5px 10px 0px 0px" }}>
            
            <label>
              Implant
            </label>
            <label
              style={{ color: "red" }}>
              *
            </label>
          </div>
          <Checkbox
            checked={props.PreOperationSequence?.orRegistrationAreaData?.data?.is_impant}
            label={props.PreOperationSequence?.orRegistrationAreaData?.data?.is_impant ? "YES" : "NO"}
            name="is_impant"
            onChange={props.onChangeData}
            style={{ marginRight: "10px" }}
            toggle={true}>
          </Checkbox>
          <Input
            name="implant"
            onChange={props.onChangeData}
            style={{ width: "45%", display: props.PreOperationSequence?.orRegistrationAreaData?.data?.is_impant ? "" : "none" }}
            value={props.PreOperationSequence?.orRegistrationAreaData?.data?.implant || ""}>
          </Input>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <label
            style={{ fontWeight: "bold", width: "15%", margin: "5px 10px 0px 0px" }}>
            Lab Data
          </label>
          <label>
            {props.PreOperationSequence?.orRegistrationAreaData?.data?.lab_data}
          </label>
          <Button
            icon="list"
            onClick={props.onShowLabData}
            size="mini">
          </Button>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <label
            style={{ fontWeight: "bold", width: "15%", margin: "5px 10px 0px 0px" }}>
            Lab Remark
          </label>
          <Input
            name="lab_remark"
            onChange={props.onChangeData}
            style={{ width: "50%" }}
            value={props.PreOperationSequence?.orRegistrationAreaData?.data?.lab_remark || ""}>
          </Input>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <label
            style={{ fontWeight: "bold", width: "15%", margin: "5px 10px 0px 0px" }}>
            Blood
          </label>
          <Input
            name="blood"
            onChange={props.onChangeData}
            style={{ width: "50%" }}
            value={props.PreOperationSequence?.orRegistrationAreaData?.data?.blood || ""}>
          </Input>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <label
            style={{ fontWeight: "bold", width: "15%", margin: "5px 10px 0px 0px" }}>
            Imaging Test
          </label>
          <Input
            name="imaging"
            onChange={props.onChangeData}
            style={{ width: "50%" }}
            value={props.PreOperationSequence?.orRegistrationAreaData?.data?.imaging || ""}>
          </Input>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <label
            style={{ fontWeight: "bold", width: "15%", margin: "5px 10px 0px 0px" }}>
            Remark
          </label>
          <Input
            name="remark"
            onChange={props.onChangeData}
            style={{ width: "50%" }}
            value={props.PreOperationSequence?.orRegistrationAreaData?.data?.remark || ""}>
          </Input>
        </div>
        <div
          style={{ height: "55px", paddingTop: "15px" }}>
          
          <div
            style={{ display: "flex", float: "right" }}>
            
            <div>
              {props.buttonSave}
            </div>
            <div>
              {props.buttonConfirm}
            </div>
            <div>
              {props.buttonUnconfirm}
            </div>
          </div>
        </div>
      </div>
    )
}


export default CardORRegistrationAreaUX

export const screenPropsDefault = {}

/* Date Time : Wed Feb 07 2024 11:16:58 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardORRegistrationArea"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"15px\", padding: \"20px\", backgroundColor: \"rgba(198, 235, 243, 0.2)\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ paddingBottom: \"5px\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "label",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "OR Registration Area"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"18px\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "Label",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.PreOperationSequence?.orRegistrationAreaData?.status"
        },
        "color": {
          "type": "code",
          "value": "props.statusColor"
        },
        "style": {
          "type": "code",
          "value": "{ float: \"right\" }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "hr",
      "parent": 1,
      "props": {
      },
      "seq": 4,
      "void": true
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "label",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": "Type of Case"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"5px 10px 0px 0px\" , width: \"30%\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "Dropdown",
      "parent": 13,
      "props": {
        "name": {
          "type": "value",
          "value": "transfer_by"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.arriveStatus || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"70%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.orRegistrationAreaData?.data?.transfer_by"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", width: \"50%\", alignItems: \"center\" }"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", width: \"50%\" , alignItems: \"center\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "label",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "Type of Surgeon"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"15%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "label",
      "parent": 14,
      "props": {
        "children": {
          "type": "code",
          "value": "props.PreOperationSequence?.orRegistrationAreaData?.data?.order_type_label"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "label",
      "parent": 58,
      "props": {
        "children": {
          "type": "value",
          "value": "Transfer By"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "label",
      "parent": 58,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "Dropdown",
      "parent": 15,
      "props": {
        "name": {
          "type": "value",
          "value": "type_surgeon"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.orProcedureType || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"35%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.orRegistrationAreaData?.data?.type_surgeon || \"\""
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "label",
      "parent": 57,
      "props": {
        "children": {
          "type": "value",
          "value": "Patient identification"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "Checkbox",
      "parent": 51,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.orRegistrationAreaData?.data?.is_verbally"
        },
        "label": {
          "type": "value",
          "value": "Verbally"
        },
        "name": {
          "type": "value",
          "value": "is_verbally"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ fwidth: \"10%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "Checkbox",
      "parent": 51,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.orRegistrationAreaData?.data?.is_name_band"
        },
        "label": {
          "type": "value",
          "value": "Name band"
        },
        "name": {
          "type": "value",
          "value": "is_name_band"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10%\", margin: \"5px 10px\", minWidth: \"max-content\" }"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "Checkbox",
      "parent": 51,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.orRegistrationAreaData?.data?.is_chart"
        },
        "label": {
          "type": "value",
          "value": "Chart"
        },
        "name": {
          "type": "value",
          "value": "is_chart"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ fmargin: \"5px 10px\" }"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "label",
      "parent": 57,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 51,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"15%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"5px 10px\" }"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ height: \"55px\", paddingTop: \"15px\" }"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "div",
      "parent": 59,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", float: \"right\" }"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 64,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "div",
      "parent": 64,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"15%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": null,
      "id": 66,
      "name": "label",
      "parent": 65,
      "props": {
        "children": {
          "type": "value",
          "value": "Mark site Confirm"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "label",
      "parent": 65,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "div",
      "parent": 71,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"15%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "label",
      "parent": 72,
      "props": {
        "children": {
          "type": "value",
          "value": "Procedure Confirm"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "label",
      "parent": 72,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "Checkbox",
      "parent": 71,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.orRegistrationAreaData?.data?.is_procedure_confirm"
        },
        "label": {
          "type": "code",
          "value": "props.PreOperationSequence?.orRegistrationAreaData?.data?.is_procedure_confirm ? \"YES\" : \"NO\""
        },
        "name": {
          "type": "value",
          "value": "is_procedure_confirm"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "div",
      "parent": 77,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"15%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "label",
      "parent": 78,
      "props": {
        "children": {
          "type": "value",
          "value": "Consent Form"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "label",
      "parent": 78,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 81,
      "name": "Checkbox",
      "parent": 77,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.orRegistrationAreaData?.data?.is_consent_form"
        },
        "label": {
          "type": "code",
          "value": "props.PreOperationSequence?.orRegistrationAreaData?.data?.is_consent_form ? \"YES\" : \"NO\""
        },
        "name": {
          "type": "value",
          "value": "is_consent_form"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "div",
      "parent": 82,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"15%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 84,
      "name": "label",
      "parent": 83,
      "props": {
        "children": {
          "type": "value",
          "value": "NPO"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "label",
      "parent": 83,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 86,
      "name": "Checkbox",
      "parent": 82,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.orRegistrationAreaData?.data?.is_npo"
        },
        "label": {
          "type": "code",
          "value": "props.PreOperationSequence?.orRegistrationAreaData?.data?.is_npo ? \"YES\" : \"NO\""
        },
        "name": {
          "type": "value",
          "value": "is_npo"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 87,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"15%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "label",
      "parent": 88,
      "props": {
        "children": {
          "type": "value",
          "value": "Valuable"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": null,
      "id": 90,
      "name": "label",
      "parent": 88,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 91,
      "name": "Checkbox",
      "parent": 87,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.orRegistrationAreaData?.data?.is_valuable"
        },
        "label": {
          "type": "code",
          "value": "props.PreOperationSequence?.orRegistrationAreaData?.data?.is_valuable ? \"YES\" : \"NO\""
        },
        "name": {
          "type": "value",
          "value": "is_valuable"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 93,
      "name": "div",
      "parent": 92,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"15%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "label",
      "parent": 93,
      "props": {
        "children": {
          "type": "value",
          "value": "Implant"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "label",
      "parent": 93,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 96,
      "name": "Checkbox",
      "parent": 92,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.orRegistrationAreaData?.data?.is_impant"
        },
        "label": {
          "type": "code",
          "value": "props.PreOperationSequence?.orRegistrationAreaData?.data?.is_impant ? \"YES\" : \"NO\""
        },
        "name": {
          "type": "value",
          "value": "is_impant"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 98,
      "name": "label",
      "parent": 97,
      "props": {
        "children": {
          "type": "value",
          "value": "Lab Data"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"15%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 99,
      "name": "Button",
      "parent": 97,
      "props": {
        "icon": {
          "type": "value",
          "value": "list"
        },
        "onClick": {
          "type": "code",
          "value": "props.onShowLabData"
        },
        "size": {
          "type": "value",
          "value": "mini"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": null,
      "id": 100,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": null,
      "id": 101,
      "name": "label",
      "parent": 100,
      "props": {
        "children": {
          "type": "value",
          "value": "Lab Remark"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"15%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 102,
      "name": "Input",
      "parent": 100,
      "props": {
        "name": {
          "type": "value",
          "value": "lab_remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.orRegistrationAreaData?.data?.lab_remark || \"\""
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": null,
      "id": 103,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": null,
      "id": 104,
      "name": "label",
      "parent": 103,
      "props": {
        "children": {
          "type": "value",
          "value": "Blood"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"15%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 105,
      "name": "Input",
      "parent": 103,
      "props": {
        "name": {
          "type": "value",
          "value": "blood"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.orRegistrationAreaData?.data?.blood || \"\""
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": null,
      "id": 106,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": null,
      "id": 107,
      "name": "label",
      "parent": 106,
      "props": {
        "children": {
          "type": "value",
          "value": "Imaging Test"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"15%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 108,
      "name": "Input",
      "parent": 106,
      "props": {
        "name": {
          "type": "value",
          "value": "imaging"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.orRegistrationAreaData?.data?.imaging || \"\""
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": null,
      "id": 109,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": null,
      "id": 110,
      "name": "label",
      "parent": 109,
      "props": {
        "children": {
          "type": "value",
          "value": "Remark"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"15%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 111,
      "name": "Input",
      "parent": 109,
      "props": {
        "name": {
          "type": "value",
          "value": "remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.orRegistrationAreaData?.data?.remark || \"\""
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": null,
      "id": 112,
      "name": "label",
      "parent": 97,
      "props": {
        "children": {
          "type": "code",
          "value": "props.PreOperationSequence?.orRegistrationAreaData?.data?.lab_data"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 113,
      "name": "Radio",
      "parent": 64,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.orRegistrationAreaData?.data?.mark_site_confirm === \"yes\""
        },
        "label": {
          "type": "value",
          "value": "Yes"
        },
        "name": {
          "type": "value",
          "value": "mark_site_confirm"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10%\", margin: \"5px 10px 0px 0px\" }"
        },
        "value": {
          "type": "value",
          "value": "yes"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 114,
      "name": "Radio",
      "parent": 64,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.orRegistrationAreaData?.data?.mark_site_confirm === \"no\""
        },
        "label": {
          "type": "value",
          "value": "No"
        },
        "name": {
          "type": "value",
          "value": "mark_site_confirm"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width: \"10%\", margin: \"5px 10px 0px 0px\" }"
        },
        "value": {
          "type": "value",
          "value": "no"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 115,
      "name": "Radio",
      "parent": 64,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PreOperationSequence?.orRegistrationAreaData?.data?.mark_site_confirm === \"na\""
        },
        "label": {
          "type": "value",
          "value": "N/A"
        },
        "name": {
          "type": "value",
          "value": "mark_site_confirm"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{  width: \"10%\", margin: \"5px 10px 0px 0px\" }"
        },
        "value": {
          "type": "value",
          "value": "na"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 116,
      "name": "Input",
      "parent": 92,
      "props": {
        "name": {
          "type": "value",
          "value": "implant"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"45%\", display: props.PreOperationSequence?.orRegistrationAreaData?.data?.is_impant ? \"\" : \"none\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.orRegistrationAreaData?.data?.implant || \"\""
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 117,
      "name": "Input",
      "parent": 64,
      "props": {
        "name": {
          "type": "value",
          "value": "mark_site"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"45%\", display: props.PreOperationSequence?.orRegistrationAreaData?.data?.mark_site_confirm === \"na\" ? \"\" : \"none\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.orRegistrationAreaData?.data?.mark_site || \"\""
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 118,
      "name": "Dropdown",
      "parent": 87,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "valuable"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.orValuable || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"45%\", display: props.PreOperationSequence?.orRegistrationAreaData?.data?.is_valuable ? \"\" : \"none\" }"
        },
        "value": {
          "type": "code",
          "value": "props.PreOperationSequence?.orRegistrationAreaData?.data?.valuable || []"
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": null,
      "id": 119,
      "name": "div",
      "parent": 60,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": null,
      "id": 120,
      "name": "div",
      "parent": 60,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonConfirm"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": null,
      "id": 122,
      "name": "div",
      "parent": 60,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonUnconfirm"
        }
      },
      "seq": 122,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardORRegistrationAreaUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
