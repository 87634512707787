import React, { useEffect } from "react";
import EmployeeToken from "react-lib/apps/common/EmployeeToken"
import TimeComboBox from "react-lib/apps/common/TimeComboBox";
// ui ORM
import CardPostopeartiveNursingRecordUX from "./CardPostopeartiveNursingRecordUX";
import PreOPButtonAction from "./PreOPButtonAction";
import { useIntl } from "react-intl";

const CARD_KEY: string = "postOperativeData";
const FORM_CODE: string = "CardPostOperativeNursingRecord";
const FORM_NAME: string = "Post-Operative-Nursing";
const FORM_VERSION: string = "0.1";

type CardPostopeartiveNursingRecordProps = {
  // function
  onEvent: any;
  setProp: any;

  // CommonInterface
  buttonLoadCheck?: any;

  // select OR
  selectedOrOrder?: any;

  // options
  masterOptions?: any;

  // seq
  runSequence?: any;
  PerioperativeNursingSequence?: any;
};

const CardPostopeartiveNursingRecordInitial: CardPostopeartiveNursingRecordProps =
  {
    // funtion
    onEvent: () => null,
    setProp: () => null,

    // CommonInterface
    buttonLoadCheck: null,

    // select OR
    selectedOrOrder: {},

    // options
    masterOptions: {},

    // seq
    runSequence: null,
    PerioperativeNursingSequence: {},
  };

const CardPostopeartiveNursingRecord: React.FC<
  CardPostopeartiveNursingRecordProps
> = (props: any) => {
  useEffect(() => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "FETCH_FORM_DATA_LATEST",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formVersion: FORM_VERSION,
    });
  }, []);

  const handleChangeValue = (_event: any, data: any) => {
    let value = "";
    if (data.type === "checkbox") {
      value = data.checked;
    } else {
      value = data.value;
    }
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "SET_DATA",
      cardKey: CARD_KEY,
      key: data.name,
      value: value,
    });
  };

  const handleSave = () => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "SAVE",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formName: FORM_NAME,
      formVersion: FORM_VERSION,
    });
  };

  const handleConfirm = () => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "CONFIRM",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formName: FORM_NAME,
      formVersion: FORM_VERSION,
    });
  };

  const handleUnconfirm = () => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "UNCONFIRM",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formName: FORM_NAME,
      formVersion: FORM_VERSION,
    });
  };

  return (
    <CardPostopeartiveNursingRecordUX
      // function
      handleChangeValue={handleChangeValue}
      // options
      masterOptions={props.masterOptions}
      pacuToOptions={props.masterOptions?.orPacuTo}
      arriveStatusOptions={props.masterOptions?.arriveStatus}
      status={props.PerioperativeNursingSequence?.postOperativeData?.status}
      note={props.PerioperativeNursingSequence?.postOperativeData?.data?.note}
      isHealthEducation={
        props.PerioperativeNursingSequence?.postOperativeData?.data
          ?.is_health_education
      }
      noHealthEducation={
        props.PerioperativeNursingSequence?.postOperativeData?.data
          ?.no_health_education
      }
      transportFromPacuto={
        props.PerioperativeNursingSequence?.postOperativeData?.data
          ?.transport_from_pacu_to
      }
      transportBy={
        props.PerioperativeNursingSequence?.postOperativeData?.data
          ?.transport_by
      }
      s
      signatureTime={
        <TimeComboBox
          defaultValue={
            props.PerioperativeNursingSequence?.postOperativeData?.data
              ?.signature_time || ""
          }
          onTextChange={(time) => {
            props.setProp(
              "PerioperativeNursingSequence.postOperativeData.data.signature_time",
              time
            );
          }}
        />
      }
      code={
        <EmployeeToken
          onEnterToken={(code) => {
            props.onEvent({
              message: "HandleGetORPostOperativeTokenization",
              params: { code: code, cardKey: CARD_KEY },
            });
          }}
          placeholder="code"
          onClearToken={() =>
            props.setProp(
              "PerioperativeNursingSequence.postOperativeData.data",
              {
                ...props.PerioperativeNursingSequence?.postOperativeData?.data,
                signature: "",
                employeeName: "",
                loading: false,
                error: null,
              }
            )
          }
          error={
            props.PerioperativeNursingSequence?.postOperativeData?.data?.error
          }
          loading={
            props.PerioperativeNursingSequence?.postOperativeData?.data?.loading
          }
          employeeName={
            props.PerioperativeNursingSequence?.postOperativeData?.data
              ?.employeeName
          }
        />
      }
      // component
      buttonSave={
        <PreOPButtonAction
          setProp={props.setProp}
          data={props.PerioperativeNursingSequence}
          type="save"
          cardKey={CARD_KEY}
          buttonLoadCheck={props.buttonLoadCheck}
          onClick={handleSave}
        />
      }
      buttonConfirm={
        <PreOPButtonAction
          setProp={props.setProp}
          data={props.PerioperativeNursingSequence}
          type="confirm"
          cardKey={CARD_KEY}
          buttonLoadCheck={props.buttonLoadCheck}
          onClick={handleConfirm}
        />
      }
      buttonUnconfirm={
        <PreOPButtonAction
          setProp={props.setProp}
          data={props.PerioperativeNursingSequence}
          type="unconfirm"
          cardKey={CARD_KEY}
          buttonLoadCheck={props.buttonLoadCheck}
          onClick={handleUnconfirm}
        />
      }
      languageUX={props.languageUX}
    />
  );
};

CardPostopeartiveNursingRecord.defaultProps =
  CardPostopeartiveNursingRecordInitial;

export default React.memo(CardPostopeartiveNursingRecord);
