import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";

// Utils
import { formatDatetime } from "react-lib/utils/dateUtils";

// PDF Path
const PDF = "/static/pdf/printList/claim_form.pdf";
const Font = "/static/fonts/THSarabunNew-Bold.ttf"; //* กรณีแก้ Font

const FormClaimForInsurance = async (props: any) => {
  try {
    const existingPdfBytes = await fetch(PDF).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Loading Custom Font
    const fontUrl = Font;
    const fontBytes = await fetch(fontUrl).then((res) => res.arrayBuffer());
    pdfDoc.registerFontkit(fontkit);
    const customFont = await pdfDoc.embedFont(fontBytes);
    const zapFont = await pdfDoc.embedFont(StandardFonts.ZapfDingbats);

    const pages = pdfDoc.getPages();
    const firstPage = pages[0];
    const thirdPage = pages[2];
    const fontSize = 10; //*

    // Sysmbol
    const checkSymbol = "\u2713"; // ✓ Check symbol
    const falseText = ""; // Empty text when isMoneyType is false

    // Format Address 1 line
    const presAddress = props.items?.present_address;
    const isValidValue = (value: any) => value && value.trim() !== "";

    // Format Discharge Date / Admit Date
    const getSplitDateNTime = (date: string) => {
      if (!date) {
        return [null, null];
      }
      return formatDatetime(date).split(" ");
    };

    const formatAddress = [
      isValidValue(presAddress?.no) ? `บ้านเลขที่ ${presAddress.no}` : null,
      isValidValue(presAddress?.name) ? presAddress.name : null,
      isValidValue(presAddress?.town) ? `หมู่ที่ ${presAddress.town}` : null,
      isValidValue(presAddress?.street) ? `ซอย ${presAddress.street}` : null,
      isValidValue(presAddress?.road) ? `ถนน ${presAddress.road}` : null,
      isValidValue(presAddress?.city_label) ? `ตำบล ${presAddress.city_label}` : null,
      isValidValue(presAddress?.district_label) ? `อำเภอ ${presAddress.district_label}` : null,
      isValidValue(presAddress?.province_label) ? `จังหวัด ${presAddress.province_label}` : null,
      isValidValue(presAddress?.zipcode) ? presAddress.zipcode : null,
    ]
      .filter(Boolean)
      .join(`, `);

    // Part A
    const textCoordinates = [
      { text: props.items?.patientFullName, x: 160, y: 658 },
      { text: props.items?.citizen_no || ``, x: 417, y: 658 },
      { text: props.items?.birthdate, x: 119, y: 641 },
      { text: props.items?.ageYear.toString(), x: 228, y: 641 },
      { text: props.items?.ageMonth.toString(), x: 272, y: 641 },
      { text: props.items?.career || ``, x: 363, y: 641 },
      { text: props.items?.present_address?.tel_mobile || ``, x: 122, y: 625 },
      { text: props.items?.present_address?.tel_home || ``, x: 254, y: 625 },
      { text: props.items?.present_address?.email || ``, x: 354, y: 625 },
      { text: props.items?.patientFullName, x: 120, y: 113 },
    ];

    for (const { text, x, y } of textCoordinates) {
      firstPage.drawText(text || "", {
        x,
        y,
        size: fontSize,
        font: customFont,
        color: rgb(0, 0, 0),
        lineHeight: 14,
      });
    }

    const addressCoordinates = [{ text: formatAddress || ``, x: 113, y: 608 }];

    for (const { text, x, y } of addressCoordinates) {
      firstPage.drawText(text || "", {
        x,
        y,
        size: fontSize,
        font: customFont,
        color: rgb(0, 0, 0),
        lineHeight: 14,
      });
    }

    const checkSymbolCoordinates = [
      { text: props.items?.gender === "M" ? checkSymbol : falseText, x: 264, y: 655 },
      { text: props.items?.gender === "F" ? checkSymbol : falseText, x: 303, y: 655 },
    ];

    for (const { text, x, y } of checkSymbolCoordinates) {
      firstPage.drawText(text, {
        x,
        y,
        size: fontSize,
        font: zapFont,
        color: rgb(0, 0, 0),
      });
    }

    // Part B
    const textBCoordinates = [
      { text: props.items?.patientFullNameEn || props.items?.patientFullName, x: 104, y: 705 },
      { text: props.items?.hn, x: 318, y: 705 },
      { text: props.items?.patientEncounter, x: 395, y: 705 },
      { text: props.items?.ageYear.toString(), x: 447, y: 705 },
      { text: props.items?.ageMonth.toString(), x: 513, y: 705 },
      {
        text: getSplitDateNTime(props.items?.admitOrderData?.start_admited)[0] || ``,
        x: 108,
        y: 687,
      },
      {
        text: getSplitDateNTime(props.items?.admitOrderData?.start_admited)[1] || ``,
        x: 192,
        y: 687,
      },
      {
        text:
          getSplitDateNTime(props.items?.admitOrderData?.discharge_date)[0] ||
          getSplitDateNTime(props.items?.opdDischarge)[0] ||
          ``,
        x: 290,
        y: 687,
      },
      {
        text:
          getSplitDateNTime(props.items?.admitOrderData?.discharge_date)[1] ||
          getSplitDateNTime(props.items?.opdDischarge)[1] ||
          ``,
        x: 354,
        y: 687,
      },
      { text: ``, x: 478, y: 687 }, //* Consultation date
      { text: props.items?.underlyingDisease || props.items?.underlying_disease || ``, x: 132, y: 289 },
    ];

    for (const { text, x, y } of textBCoordinates) {
      thirdPage.drawText(text || "", {
        x,
        y,
        size: fontSize,
        font: customFont,
        color: rgb(0, 0, 0),
        lineHeight: 14,
      });
    }

    const checkSymbolBCoordinates = [
      { text: props.items?.gender === "M" ? checkSymbol : falseText, x: 212, y: 703 },
      { text: props.items?.gender === "F" ? checkSymbol : falseText, x: 251, y: 703 },
    ];

    for (const { text, x, y } of checkSymbolBCoordinates) {
      thirdPage.drawText(text, {
        x,
        y,
        size: fontSize,
        font: zapFont,
        color: rgb(0, 0, 0),
      });
    }

    const modifiedPdfBytes = await pdfDoc.save();
    const getAsBase64 = await pdfDoc.saveAsBase64();
    const blob = new Blob([modifiedPdfBytes], { type: "application/pdf" });
    const modifiedPdfUrl = URL.createObjectURL(blob);

    return {
      uint8Array: modifiedPdfBytes,
      blobUrl: modifiedPdfUrl,
      blobRaw: blob,
      base64String: getAsBase64,
    };
  } catch (error) {
    console.error("Error modifying PDF: ", error);

    return {};
  }
};

export default FormClaimForInsurance;
