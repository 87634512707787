import WasmController from 'react-lib/frameworks/WasmController';
import DivisionServiceBlockView from "issara-sdk/apis/DivisionServiceBlockView_apps_QUE";
import PatientAppointment from "issara-sdk/apis/PatientAppointmentView_apps_QUE";
import PatientAppointmentUpdate from "issara-sdk/apis/PatientAppointmentUpdate_apps_QUE";
import ServiceSlotView from "issara-sdk/apis/ServiceSlotView_apps_QUE";
import ServiceSlotUpdate from "issara-sdk/apis/ServiceSlotUpdate_apps_QUE";

export type State = 
  {
  }

export const StateInitial: State = 
  {
  }

export type Event = 
  { message: "createServiceSlot", params: any }
  |  { message: "updateServiceSlot", params: any }
  |  { message: "loadServiceSlot", params: any }
  |  { message: "loadDivisionServiceBlock", params: any }
  |  { message: "getDivisionServiceBlockDetail", params: any }
  |  { message: "listPatientAppointment", params: any }
  |  { message: "getPatientAppointment", params: any }
  |  { message: "updatePatientAppointment", params: any }
  |  { message: "getPatientAppointmentUpdate", params: any }

export type Data = 
  {
    division?: number,
  }

export const DataInitial = 
  {
  }

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any 

export const createServiceSlot: Handler = async (controller, {
  apiToken,
  serial,
  status,
  provider,
  division
}) => {
 
  let params: any = {};
  let data: any = {};
  if (serial) {
    data.serial = serial;
  }
  if (status) {
    data.status = status;
  }
  if (provider) {
    data.provider = provider;
  }
  if (division) {
    data.division = division;
  }
  return await ServiceSlotView.create({
    params: params,
    data: data,
    apiToken: controller.apiToken
  });
}

export const updateServiceSlot: Handler = async (controller, {
  apiToken,
  pk,
  serial,
  status,
  provider,
  division
}) => {
  let params: any = {};
  let data: any = {};
  if (serial) {
    data.serial = serial;
  }
  if (status) {
    data.status = status;
  }
  if (provider) {
    data.provider = provider;
  }
  data.division = division; // if it is null, send null; Otherwise, it wont inactive
  return await ServiceSlotUpdate.update({
    pk: pk,
    params: params,
    data: data,
    apiToken: controller.apiToken
  });
}

export const loadServiceSlot: Handler = async (controller, { apiToken, fromSerial, toSerial, providerId }) => {
  let params: any = {};
  if (fromSerial) {
    params.from_serial = fromSerial;
  }
  if (toSerial) {
    params.to_serial = toSerial;
  }
  if (providerId) {
    params.provider_id = providerId;
  }
  return await ServiceSlotView.list({
    params: params,
    apiToken: controller.apiToken
  });
}

export const loadDivisionServiceBlock: Handler = async (controller, 
  {
    apiToken,
    from_serial,
    to_serial,
    divisions,
    onlyHasProvider,
    employeeType
  }) => {
  let params: any = {};
  if (from_serial) {
    params.from_serial = from_serial;
  }
  if (to_serial) {
    params.to_serial = to_serial;
  }
  if (divisions) {
    params.divisions = divisions;
  }
  if (onlyHasProvider) {
    params.only_has_provider = onlyHasProvider;
  }
  if (employeeType) {
    params.employee_type = employeeType;
  }

  return await DivisionServiceBlockView.list({
    params: params,
    apiToken: controller.apiToken,
  });
}

export const getDivisionServiceBlockDetail: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, pk = _b.pk;
//            return __awaiter(_this, void 0, void 0, function () {
//                var queService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            queService = new QUEService_1.default();
//                            return [4 /*yield*/, utils_1.to(queService.getDivisionServiceBlockDetail({
//                                    apiToken: apiToken,
//                                    pk: pk
//                                }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, pk = _b.pk;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        var url = apis_1.QUE.DIVISION_SERVICE_BLOCK_DETAIL + (pk + "/");
//        return this.client
//            .get(url)
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/QUE/division-service-block-detail/
}

export const listPatientAppointment: Handler = async (controller, {apiToken, patientId}) => {
  let params: any = {};
  if (patientId) {
    params.patient_id = patientId;
  }
  return await PatientAppointment.list({
    params: params,
    apiToken: controller.apiToken
  });
}

export const getPatientAppointment: Handler = async (controller, { apiToken, pk }) => {
  let params = {};
  return PatientAppointmentUpdate.retrieve({
    pk: pk,
    params: params,
    apiToken: controller.apiToken
  });    
}

export const updatePatientAppointment: Handler = async (controller, { apiToken, pk, divisionServiceBlock }) => {
  let params: any = {};
  let data: any = {};
  if (divisionServiceBlock) {
    data.division_service_block = divisionServiceBlock;
  }
  return await PatientAppointmentUpdate.patch({
    pk: pk,
    params: params,
    data: data,
    apiToken: controller.apiToken
  });
}

export const getPatientAppointmentUpdate: Handler = async (controller, { apiToken, appointmentId }) => {
  let params: any = {};
  return await PatientAppointmentUpdate.retrieve({
    pk: appointmentId,
    params: params,
    apiToken: controller.apiToken
  });
}
