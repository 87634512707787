import { formatDropdown } from "react-lib/utils";
import { APPOINTMENT_MODEL, STATUS_REQUESTED } from "./Interface/APPI";
import * as CONSTANT from "react-lib/utils/constant";

const setUpController = (context: any) => {
  context.crdFormViewerController = {
    getFormType: context.proxyController.getFormType,
    getDoctor: context.proxyController.getDoctorList,
    getFormDataNurseNote: context.proxyController.getFormDataNurseNote,
  };
  context.nurseNoteController = {
    getEncounterDetail: async ({ encounterId }: { encounterId?: number } = {}) => {
      const [data, error] = await context.proxyController.getEncounter(encounterId);
      return [data, error];
    },
    getNurseNoteLatest: context.proxyController.getNurseNoteLatest,
    getNurseNoteItem: context.proxyController.getNurseNoteItem,
    postNurseNoteItem: context.proxyController.postNurseNoteItem,
    putNurseNoteItem: context.proxyController.putNurseNoteItem,
    deleteNurseNoteItem: context.proxyController.deleteNurseNoteItem,
    putNurseNote: context.proxyController.putNurseNote,
    getDoctorOrder: context.proxyController.getDrOrder,
  };
  context.subICD10DetailController = {
    getICDType: () => { return "ICD10" },
    searchWithICDCode: async (icdCode: string) => {
      var errorMsg = "ไม่สามารถค้นหา ICD10 Code!"
      if (!icdCode) {
        return [null, errorMsg];
      }
      const [data, error] = await context.proxyController.getICD10ListWithCode(icdCode);
      return [data, error];
    },
    searchWithICDTerm: async (icdTerm: string) => {
      var errorMsg = "ไม่สามารถค้นหา ICD10 Term!"
      if (!icdTerm) {
        return [null, errorMsg];
      }
      const [data, error] = await context.proxyController.getICD10ListWithTerm(icdTerm);
      return [data, error];
    },
    searchWithMedTerm: async (medTerm: string) => {
      var errorMsg = "ไม่สามารถค้นหา Medical Term!"
      if (!medTerm) {
        return [null, errorMsg];
      }
      const [data, error] = await context.proxyController.getICD10ListWithMedTerm(medTerm);
      return [data, error];
    },
    searchSublevelWithICDCode: async (icdCode: string) => {
      var errorMsg = "ไม่สามารถค้นหา ICD10 Sublevel"
      if (!icdCode) {
        return [null, errorMsg];
      }
      const [data, error] = await context.proxyController.getICD10SublevelListWithCode(icdCode);
      return [data, error];
    },
    getChoices: async ({ model, field, nameAsId, name }: any) => {
      const [response, error, network] = await context.proxyController.getChoices({
        model,
        field,
        nameAsId
      });
      return [response, error, network];
    },
    getDiagnosis: async (emrId: number) => {
      if (!emrId) {
        return null;
      }
      const [data, error] = await context.proxyController.getDiagnosis(emrId);
      if (error) {
        alert('error' + error.toString());
        return null;
      }
      return data;
    },
    saveDiagnosis: async (emrId: number, pDiagnosis: any, sDiagnosis: any, approved_by_username: any, approved_by_password: any) => {
      if (!emrId) {
        return null;
      }
      const [data, error] = await context.proxyController.updateDiagnosis({
        emrId: emrId,
        dPrimaryList: pDiagnosis,
        dSecondaryList: sDiagnosis,
        approved_by_username: approved_by_username,
        approved_by_password: approved_by_password,
      });
      if (error) {
        console.log(error);
        return null;
      }

      context.controller.handleEvent({
        message: "HandleRefreshEncounter" as any,
        params: {},
      })

      return data;
    },
    getAllDivision: async () => {
      const [data, error] = await context.proxyController.getDivisionChoice();
      if (error) {
        console.log(error);
        return [];
      }
      return data;
    },
    getAllDoctor: async () => {
      const [data, error] = await context.proxyController.getDoctorChoice();
      if (error) {
        console.log(error);
        return [];
      }
      return data;
    },
    searchPatientDiagnosis: (patientId: number) => async (params: any) => {
      const [data, error] = await context.proxyController.getPatientDiagnosis({
        patientId,
        params
      });
      if (error) {
        console.log(error);
        return [];
      }
      return data.items;
    },
    searchDiagnosisTemplate: async (params: any) => {
      const [data, error] = await context.proxyController.getDiagnosisTemplate(params);
      if (error) {
        console.log(error);
        return [];
      }
      return data;
    },
  };
  context.crdAppointmentRequestNewController = {
    getDivision: context.proxyController.getDivisionForOPDChoice,
    getDoctor: context.proxyController.getDoctorWorkAtDivision,
    getAppointment: context.proxyController.getAppointmentOrder,
    getAppointmentById: context.proxyController.getAppointmentWithId,
    estimateDoctorOrder: context.proxyController.estimateDoctorOrder,
    createAppointment: context.proxyController.postAppointmentRequest,
    updateAppointment: context.proxyController.updateAppointmentWithId,
    cancelAppointment: context.proxyController.cancelAppointment,
  };
  context.modSearchDoctorNoteController = {
    getDoctorNoteList: async (params = {}) => {
      const [dataList, error] = await context.proxyController.getDoctorNote(params);
      if (error) {
        return [];
      }
      return dataList;
    },
    getDoctorNoteGroupList: async (params = {}) => {
      const [dataList, error] = await context.proxyController.getDoctorNoteGroup(params);
      if (error) {
        return [];
      }
      return dataList;
    },
  };
  context.subDoctorScheduleController = {
    getDivision: context.proxyController.getDivisionForOPDChoice,
    getDoctor: context.proxyController.getDoctorWorkAtDivision,
    getDoctorScheduleAppointment: context.proxyController.getDoctorScheduleAppointment,
  };
  context.consultController = {
    getDivisionList: async () => {
      const [dataList, error] = await context.proxyController.getDivisionForOPDChoice();
      if (error) {
        return [];
      }
      return dataList;
    },
    searchDoctorList: async (divisionId: number) => {
      // const params = {
      //   division: divisionId
      // }
      const [dataList, error] = await context.proxyController.getDoctorWorkAtDivision(divisionId);
      if (error) {
        return [];
      }

      const choice = await context.proxyController.convertDoctorScheduleToChoice(dataList);
      return choice;
    },
    getConsultOrder: async (patientId: number) => {
      const [dataList, error] = await context.proxyController.getAppointmentOrder({
        patientId: patientId,
        type: "doctorconsultorder"
      });
      if (error) {
        return [];
      }
      return dataList;
    },
    getNewConsultOrder: async (patientId: any) => {
      var params = {
        patientId: patientId,
        type: "doctorconsultorder",
        requestFlag: true,
        excludePostponed: true,
        excludeFinished: true,
      };
      const [dataList, error] = await context.proxyController.getAppointmentOrder(params);
      if (error) {
        return [];
      }
      return dataList;
    },
    getAppointmentWithId: async (appointmentId: number) => {
      const [data, error] = await context.proxyController.getAppointmentWithId(appointmentId)
      if (error) {
        return null
      }
      return data
    },
    getConsultOrderWithId: async (orderId: number) => {
      const [data, error] = await context.proxyController.getDoctorConsultOrderWithId(orderId)
      if (error) {
        return null
      }
      return data
    },
    requestConsultOrder: async (
      patientId: number, encounterId: number, divisionId: number, doctorId: number, urgency: any,
      noteToConsultant: string, noteToNurse: string, noteToPatient: string, doctorNote: string) => {

      let params: any = {
        id: null,
        action: "ORDER",
        encounter: encounterId,
        order_encounter: encounterId,
        order_status: 1,
        division: divisionId,
        consulted_doctor: doctorId,
        note: noteToConsultant,
        urgency: urgency,
      }
      console.log(params);
      const [data1, error1] = await context.proxyController.postDoctorConsultOrder(params);
      if (error1) return false

      let params2: any = {
        ...APPOINTMENT_MODEL,
        id: null,
        action: "REQUEST",
        patient: patientId,
        order_encounter: encounterId,
        doctor: doctorId,
        extend_queue: false,
        note_to_nurse: noteToNurse,
        note_to_patient: noteToPatient,
        doctor_note: doctorNote,
        status: STATUS_REQUESTED,
        order_dict: { [data1.id]: "doctorconsultorder" }
      }
      const [data2, error2] = await context.proxyController.postAppointmentRequest(params2);
      if (error2) return false
      return true
    },
    updateConsultOrder: async (orderModel: any, appModel: any) => {
      let params1 = {
        ...orderModel,
        action: "ORDER"
      }
      const [orderData, orderError] = await context.proxyController.updateDoctorConsultOrderWithId(params1);
      if (orderError) { return false }

      let params2 = {
        ...appModel,
        action: "REQUEST"
      }
      const [appData, appError] = await context.proxyController.updateAppointmentWithId(params2);
      if (appError) { return false }
      return true
    },
    deleteConsultOrder: async (appModel: any) => {
      let params = {
        ...appModel,
        action: "REQUEST"
      }
      const [data, error] = await context.proxyController.deleteAppointmentWithId(params);
      if (error) {
        return false
      }
      return true
    },
    createConsultOrder: async (encounterId: number, divisionId: number, doctorId: number, note: string, urgency: any) => {
      let params = {
        id: null,
        action: "ORDER",
        encounter: encounterId,
        order_encounter: encounterId,
        order_status: 1,
        division: divisionId,
        consulted_doctor: doctorId,
        note: note,
        urgency: urgency,
      }
      const [data, error] = await context.proxyController.postDoctorConsultOrder(params);
      return [data, error]
    },
    createAppointmentRequest: async (appointmentId: number, patientId: number, encounterId: number, doctorId: number,
      noteToNurse: string, noteToPatient: string, doctorNote: string) => {
      let params: any = {
        ...APPOINTMENT_MODEL,
        id: null,
        action: "REQUEST",
        patient: patientId,
        order_encounter: encounterId,
        doctor: doctorId,
        extend_queue: false,
        note_to_nurse: noteToNurse,
        note_to_patient: noteToPatient,
        doctor_note: doctorNote,
        status: STATUS_REQUESTED,
        order_dict: { [appointmentId]: "doctorconsultorder" }
      }
      const [data, error] = await context.proxyController.postAppointmentRequest(params);
      return [data, error]
    },
    putConsultOrder: async (orderModel: any) => {
      let params = {
        ...orderModel,
        action: "ORDER"
      }
      const [data, error] = await context.proxyController.updateDoctorConsultOrderWithId(params);
      return [data, error]
    },
    putAppointment: async (appModel: any) => {
      let params = {
        ...appModel,
        action: "REQUEST"
      }
      const [data, error] = await context.proxyController.updateAppointmentWithId(params);
      return [data, error]
    }
  };
  context.crdAdmitOrderController = {
    getUserPermission: async () => {
      let params = {
        action_ADM_AdmitOrder_PROCESS: false,
        action_ADM_AdmitOrder_REQUEST: false,
        action_ADM_AdmitOrder_CANCEL_PROCESS: false,
        action_ADM_AdmitOrder_CANCEL_REQUEST: false,
        action_ADM_AdmitOrder_EDIT_PROCESS: false,
        action_ADM_AdmitOrder_EDIT_REQUEST: false,
        action_ADM_AdmitOrder_CANCEL: false
      };
      const [data, error] = await context.proxyController.getUserPermission(params);
      return [data, error];
    },
    getPatientCase: async () => {
      const [data, error] = await context.proxyController.getClinicalTerm({
        type: "PATIENT_CASE"
      });
      return [data, error];
    },
    getWardType: async () => {
      const [data, error] = await context.proxyController.getWardType();
      return [data, error];
    },
    getRoomType: async () => {
      const [data, error] = await context.proxyController.getRoomType();
      return [data, error];
    },
    getChoices: async ({ model, field, nameAsId, name }: any) => {
      const [data, error] = await context.proxyController.getChoices({
        model,
        field,
        nameAsId
      });
      return [data, error];
    },
    getDropdownSearchDoctorList: async ({ searchText }: any = {}) => {
      const [data, error] = await context.proxyController.getDoctorList({
        nameCode: searchText
      });
      return [data, error];
    },
    getDiagnosis: async (emrId: number) => {
      const [data, error] = await context.proxyController.getDiagnosis(emrId);
      return [data, error];
    },
    estimatedAdmitCost: async (admitOrderId: number) => {
      const [data, error] = await context.proxyController.estimateAdmit(admitOrderId);
      return [data, error];
    },
    getAdmitOrderById: async (admitOrderId: number) => {
      if (!admitOrderId || admitOrderId === null) {
        return [];
      }
      const [data, error] = await context.proxyController.getAdmitOrderDetail(
        admitOrderId
      );
      return [data, error];
    },
    saveAdmitOrder: async (admitOrderId: number, params: any) => {
      if (!admitOrderId) {
        const [data, error] = await context.proxyController.createAdmitOrder(params);
        return [data["id"], data, error];
      } else {
        const [data, error] = await context.proxyController.updateAdmitOrder({
          admitOrderId: admitOrderId,
          saveData: params
        });
        return [admitOrderId, data, error];
      }
    },
    printReservation: async (admitOrderId: number) => {
      const [data, error] = await context.proxyController.printReservation(admitOrderId);
      return [data, error];
    },
    printAppointment: async (appointmentId: number) => {
      const [data, error] = await context.proxyController.printAppointment(appointmentId);
      return [data, error];
    }
  };
  context.crdAdmissionFormController = {
    getAdmissionForm: async (emrId: number) => {
      const [response, error, network] = await context.proxyController.getAdmissionForm(
        emrId
      );
      return [response, error, network];
    },
    putAdmissionForm: async (emrId: number, data: any) => {
      const [response, error, network] = await context.proxyController.putAdmissionForm({
        emrId: emrId,
        data: data,
      });
      return [response, error, network];
    }
  };
  context.cardDischargeController = {
    setProgressionCycle: (value: any) => {
      context.hasProgressionCycle = value;
    },
    getChoicesCheckout: async () => {
      const [data, error] = await context.proxyController.getChoicesCheckoutCause();
      return [data, error];
    },
    checkDoctorFeeOrder: async (emrId: number) => {
      var hasWarning = false;
      var message = null;
      const [data, error] = await context.proxyController.checkDoctorFeeOrderWithEMR(emrId);
      if (data) {
        hasWarning = data.show_warning;
        message = data.message;
      }
      return [hasWarning, message];
    },
    getZone: async (divisionId: number) => {
      const [data, error] = await context.proxyController.getZoneOfDivision(divisionId);
      // Convert to choices
      const choices = data.map((item: any) => ({
        id: item.id,
        text: item.name_code,
        value: item.code,
      }));
      return [choices, error];
    },
    changeZone: async (encounterId: number, zoneId: number) => {
      var isSuccess = true;
      const [data, error] = await context.proxyController.changeZone({ encounterId: encounterId, zoneId: zoneId });
      if (error) {
        isSuccess = false;
      }
      return isSuccess;
    },
    discharge: async (emrId: number, checkoutCause: any, division = null, require_diagnosis = true, approve_by = null) => {
      var isSuccess = true;
      var errorMessage = null;
      switch (checkoutCause) {
        case CONSTANT.CHECKOUT_CAUSE.WAIT_RESULT:
          break;
        default:
          const [data, error] = await context.proxyController.discharged({
            emrId: emrId,
            checkoutCause: checkoutCause,
            params: {
              require_diagnosis: require_diagnosis,
              approve_by: approve_by
            },
            division: division
          });
          if (error) {
            isSuccess = false;
            errorMessage = error;
          }
          break;
      }
      return [isSuccess, errorMessage];
    }
  };
  context.cardImageGalleryController = {
    getMedicalRecordGallery: async ({ emrId, params }: any) => {
      let [data, error] = await context.proxyController.getMedicalRecordGallery({ emrId: emrId, params: params })
      return [data, error]
    },
    postMedicalRecordGallery: async ({ emrId, data }: any) => {
      let [response, error] = await context.proxyController.postMedicalRecordGallery({ emrId, data })
      return [response, error]
    },
    updateImageDetail: async ({ selectedImage, imageDataURI, detail }: any) => {
      let [data, error] = await context.proxyController.putMedicalRecordPicture({ selectedImage, imageDataURI, detail })
      return [data, error]
    },
    removeImage: async ({ selectedImage, imageDataURI, detail }: any) => {
      let [data, error] = await context.proxyController.putMedicalRecordPicture({ selectedImage, imageDataURI, detail, isActive: false })
      return [data, error]
    },
    getTemplateGalleryImage: async ({ name }: any = {}) => {
      let [response, error] = await context.proxyController.getTemplateGalleryImage({ name })
      return [response, error]
    }
  };
  context.cardMedCertController = {
    getCurrentDoctor: async () => {
      let [data, error, network] = await context.proxyController.getCurrentDoctor()
      return [data, error, network]
    },
    getDoctorCertificateType: async () => {
      let [data, error, network] = await context.proxyController.getDoctorCertificateType()
      return [data, error, network]
    },
    getDiagnosisSummary: async (params: any) => {
      let [data, error, network] = await context.proxyController.getDiagnosisSummary(params)
      return [data, error, network]
    },
    getDoctorCertificatesPrintlog: async (params: any) => {
      let [data, error, network] = await context.proxyController.getDoctorCertificatesPrintlog(params)
      return [data, error, network]
    },
    searchDoctorName: async (params: any) => {
      let [data, error, numPage, network] = await context.proxyController.getDoctorNameCodeList(params)
      return [data, error, numPage, network]
    },
    saveAndPrintMedCert: async (params: any) => {
      let [data, error, network] = await context.proxyController.postDoctorCertificate(params)
      return [data, error, network]
    }
  };
  context.crdMultiDisciplinaryController = {
    getChoices: async ({ model, field, nameAsId, name }: any) => {
      const [data, error] = await context.proxyController.getChoices({
        model,
        field,
        nameAsId
      });
      return [data, error];
    }
  };
  context.modOrderDetailController = {
    getDoctorOrderDetail: async (orderId: number) => {
      const [response, error, network,
      ] = await context.proxyController.getDoctorOrderDetail({ id: orderId });
      return [response, error, network];
    }
  }
  context.scannedDocumentController = {
    getNationalities: async () => {
      const [data, error] = await context.proxyController.getNationalities();
      let newArr = formatDropdown(data, "full_name", "id");
      return [newArr, error];
    },
    getSearchAllPatient: async ({
      hn,
      fullName,
      citizenId,
      nationality,
      is_old_name_search,
      limit,
      offset
    }: any = {}) => {
      const [data, error] = await context.proxyController.getSearchAllPatient({
        hn,
        fullName,
        citizenId,
        nationality,
        is_old_name_search,
        limit,
        offset
      });
      return [data, error];
    },
    getEncounterSearch: async ({ search, encounterId, patientId }: any = {}) => {
      const [data, error] = await context.proxyController.getEncounterSearch({
        search,
        encounterId,
        patientId
      });
      return [data, error];
    },
    getDocumentType: async ({ limit, category, name, division, code }: any = {}) => {
      const [data, error] = await context.proxyController.getDocumentType({
        limit,
        category,
        name,
        division,
        code
      });
      let newArr = formatDropdown(data.items, "name", "id");
      return [newArr, error];
    },
    getDocumentCategory: async ({ limit }: any = {}) => {
      const [data, error] = await context.proxyController.getDocumentCategory({ limit });
      let newArr = formatDropdown(data.items, "name", "id");
      return [newArr, error];
    },
    getDivision: async ({ limit }: any = {}) => {
      const [data, error] = await context.proxyController.getDivision({ limit });
      let newArr = formatDropdown(data.items, "name_code", "id");
      return [newArr, error];
    },
    getUsers: async ({ fullName }: any = {}) => {
      const [data, error] = await context.proxyController.getUsers({ fullName });
      return [data, error];
    },
    getDoctor: async ({ search }: any = {}) => {
      const [data, error] = await context.proxyController.getDoctor({ search });
      return [data, error];
    },
    getScannedDocument: async ({
      patientHn,
      encounterId,
      documentType,
      documentNo,
      scanDivision,
      scanUser,
      ownerDivision,
      encounterDoctor,
      docFromDate,
      docToDate,
      scanFromDate,
      scanToDate,
      expiryFromDate,
      expiryToDate,
      limit,
      offset
    }: any = {}) => {
      const [data, error] = await context.proxyController.getScannedDocument({
        patientHn,
        encounterId,
        documentType,
        documentNo,
        scanDivision,
        scanUser,
        ownerDivision,
        encounterDoctor,
        docFromDate,
        docToDate,
        scanFromDate,
        scanToDate,
        expiryFromDate,
        expiryToDate,
        limit,
        offset
      });
      return [data, error];
    },
    putScannedDocUpdateSecret: async ({ username, password, items }: any = {}) => {
      const [data, error] = await context.proxyController.putScannedDocumentUpdateSecret(
        { username, password, items }
      );
      return [data, error];
    },
    patchScannedDocument: async ({
      username,
      password,
      cancelNote,
      scanDocId,
      active,
      documentType,
      documentNo,
      version,
      ownerDivision,
      documentDate,
      expiryDate,
      remark
    }: any = {}) => {
      const [data, error] = await context.proxyController.patchScannedDocument({
        username,
        password,
        cancelNote,
        scanDocId,
        active,
        documentType,
        documentNo,
        version,
        ownerDivision,
        documentDate,
        expiryDate,
        remark
      });
      return [data, error];
    },
    postScannedDocument: async ({ data }: any = {}) => {
      const [response, error] = await context.proxyController.postScannedDocument({ data })
      if (error) {
        return Promise.reject(error)
      } else {
        return Promise.resolve(response)
      }
      // return [response, error]
    }
  }
  context.printListController = {
    getEncounterSearch: async ({ patientId, patientHn, limit, offset, search }: any = {}) => {
      const [data, error] = await context.proxyController.getEncounterSearch({
        patientId,
        patientHn,
        limit,
        offset,
        search
      });
      return [data, error];
    },
    getEncounterSearchDetail: async ({ encounterId }: any = {}) => {
      const [data, error] = await context.proxyController.getEncounterSearchDetail({
        encounterId
      });
      return [data, error];
    },
    getEncounter: async (encounterId: any) => {
      const [data, error] = await context.proxyController.getEncounter(
        encounterId
      );
      return [data, error];
    },
    getDocumentType: async ({ name, printable, division, offset, limit }: any = {}) => {
      const [data, error] = await context.proxyController.getDocumentType({
        name,
        printable,
        division,
        offset,
        limit
      });
      return [data, error];
    },
    getPrintAPI: async ({
      printAPI,
      patientId,
      encounterId,
      jasperModule,
      jasperPath,
      pdf,
      orderId,
      reason,
      confirm,
    }: any = {}) => {
      const [data, error, network] = await context.proxyController.getPrintAPI({
        printAPI,
        patientId,
        encounterId,
        jasperModule,
        jasperPath,
        pdf,
        orderId,
        reason,
        confirm
      });
      return [data, error, network];
    },
    getTaskTrackingResult: async ({ taskId, action }: any = {}) => {
      const [data, error, network] = await context.proxyController.getTaskTrackingResult(
        { taskId, action }
      );
      return [data, error, network];
    },
    getClinicalTerm: async ({ type }: any = {}) => {
      const [data, error, network] = await context.proxyController.getClinicalTerm({
        type
      });
      let choices = null;
      if (data && Array.isArray(data)) {
        choices = formatDropdown(data, "name", "name");
      }
      return [choices, error, network];
    },
    getOperatingOrderDetailByEmr: async ({ emrId }: any = {}) => {
      const [data, error] = await context.proxyController.getOperatingOrderDetailByEmr({
        emrId
      });
      return [data, error];
    },
    getDoctorDetail: async ({ doctorId }: any = {}) => {
      const [data, error] = await context.proxyController.getDoctorDetail({
        doctorId
      });
      return [data, error];
    },
    getPatientDetailView: async ({ patientId }: any = {}) => {
      const [data, error] = await context.proxyController.getPatientDetailView({
        patientId
      });
      return [data, error];
    },
    getDiagnosisMedicalRecordDetail: async ({ emrId }: any = {}) => {
      const [data, error] = await context.proxyController.getDiagnosisMedicalRecordDetail({
        emrId
      });
      return [data, error];
    },
    getTreatmentDetail: async ({ code }: any = {}) => {
      const [data, error] = await context.proxyController.getTreatmentDetail({
        code
      });
      return [data, error];
    },
    getHospitalNameForPrintList: async ({ language }: any = {}) => {
      const [data, error] = await context.proxyController.getHospitalNameForPrintList({ language });
      return [data, error];
    },
    getPatientAllergyForPrintList: async ({ patientId }: any = {}) => {
      const [data, error] = await context.proxyController.getPatientAllergyForPrintList({ patientId });
      return [data, error];
    },
    getPreNameForPrintList: async ({ language }: any = {}) => {
      const [data, error] = await context.proxyController.getPreNameForPrintList({ language });
      return [data, error];
    },
    getAdmitOrderForPrintList: async ({ encounterId }: any = {}) => {
      const [data, error] = await context.proxyController.getAdmitOrderForPrintList({ encounterId });
      return [data, error];
    }
  }
  context.queueController = {
    loadDivisionServiceBlock: async ({
      apiToken,
      from_serial,
      to_serial,
      divisions,
      onlyHasProvider,
      employeeType
    }: any) => {
      const [data, error] = await context.proxyController.loadDivisionServiceBlock({
        apiToken,
        from_serial,
        to_serial,
        divisions,
        onlyHasProvider,
        employeeType
      });
      return [data, error]
    },

    listPatientAppointment: async ({
      apiToken,
      patientId,
    }: any) => {
      const [data, error, network] = await context.proxyController.listPatientAppointment({
        apiToken,
        patientId,
      });
      return [data, error, network]
    },

    getPatientAppointment: async ({
      apiToken,
      pk
    }: any = {}) => {
      const [data, error, network] = await context.proxyController.getPatientAppointment({
        apiToken,
        pk,
      });
      return [data, error, network]
    },

    getDivisionHasUser: async ({
      apiToken
    }: any = {}) => {
      const [data, error, network] = await context.proxyController.getDivisionHasUser({
        apiToken,
      });
      return [data, error, network]
    },

    loadServiceSlot: async ({
      apiToken,
      fromSerial,
      toSerial,
      providerId
    }: any = {}) => {
      const [data, error, network] = await context.proxyController.loadServiceSlot({
        apiToken,
        fromSerial,
        toSerial,
        providerId
      });
      return [data, error, network]
    },

    createServiceSlot: async ({
      apiToken,
      serial,
      status,
      provider,
      division
    }: any = {}) => {
      const [data, error, network] = await context.proxyController.createServiceSlot({
        apiToken,
        serial,
        status,
        provider,
        division
      });
      return [data, error, network]
    },

    updateServiceSlot: async ({
      pk,
      apiToken,
      serial,
      status,
      provider,
      division
    }: any = {}) => {
      const [data, error, network] = await context.proxyController.updateServiceSlot({
        apiToken,
        pk,
        serial,
        status,
        provider,
        division
      });
      return [data, error, network]
    },

    updatePatientAppointment: async ({
      pk,
      apiToken,
      divisionServiceBlock,
    }: any = {}) => {
      const [data, error, network] = await context.proxyController.updatePatientAppointment({
        apiToken,
        pk,
        divisionServiceBlock,
      });
      return [data, error, network]
    },

    getProviderInfo: async ({
      apiToken,
      userId,
    }: any = {}) => {
      const [data, error, network] = await context.proxyController.getProviderInfo({
        apiToken,
        userId,
      });
      return [data, error, network]
    },

    getDoctorWorkSchedule: async ({
      apiToken,
      providerId,
      startDate,
      endDate,
      divisionId
    }: any = {}) => {
      const [data, error, network] = await context.proxyController.getDoctorWorkSchedule({
        apiToken,
        providerId,
        startDate,
        endDate,
        divisionId
      });
      return [data, error, network]
    },

    getAvailableDivisionServiceBlock: async ({
      apiToken,
      division,
      employee,
      date,
      timeRange,
      providerTypeCategory
    }: any = {}) => {
      const [data, error, network] = await context.proxyController.getAvailableDivisionServiceBlock({
        apiToken,
        division,
        employee,
        date,
        timeRange,
        providerTypeCategory
      });
      return [data, error, network]
    },

    postSelectDivisionServiceBlock: async ({
      apiToken,
      patientAppointmentId,
      division,
      timeRange,
      employee,
      diagFormId,
      providerTypeCategory
    }: any = {}) => {
      const [data, error, network] = await context.proxyController.postSelectDivisionServiceBlock({
        apiToken,
        patientAppointmentId,
        division,
        timeRange,
        employee,
        diagFormId,
        providerTypeCategory
      })
      return [data, error, network]
    },

    getPatientAppointmentUpdate: async ({
      apiToken,
      appointmentId
    }: any = {}) => {
      const [data, error, network] = await context.proxyController.getPatientAppointmentUpdate({
        apiToken,
        appointmentId
      })
      return [data, error, network]
    },

    postRequestChangeDivisionServiceBlock: async ({
      apiToken,
      remark,
      division,
      patientAppointment
    }: any = {}) => {
      const [data, error, network] = await context.proxyController.postRequestChangeDivisionServiceBlock({
        apiToken,
        remark,
        division,
        patientAppointment
      })
      return [data, error, network]
    }
  }
  context.dashboardController = {
    getTriageLevelClassify: async ({
      apiToken,
      divisionId
    }: any = {}) => {
      const [
        response,
        error,
        network
      ] = await context.proxyController.getTriageLevelClassify({
        apiToken,
        divisionId
      });
      return [response, error, network];
    },

    getDivision: async ({ isTelemed, apiToken, organization }: any = {}) => {
      const [data, error] = await context.proxyController.getDivision({ isTelemed, apiToken, organization });
      return [data, error];
    },

    getDashboard: async ({
      apiToken,
      search,
      date,
      triageLevels,
      division,
      paymentStatus,
      organization,
      isTelemed
    }: any = {}) => {
      const [
        response,
        error,
        network
      ] = await context.proxyController.getDashboard({
        apiToken,
        search,
        date,
        triageLevels,
        division,
        paymentStatus,
        organization,
        isTelemed
      });
      return [response, error, network];
    },

    getOfficialAccountDashboard: async ({
      apiToken,
      search,
      date,
      triageLevels,
      division,
      paymentStatus
    }: any = {}) => {
      const [
        response,
        error,
        network
      ] = await context.proxyController.getOfficialAccountDashboard({
        apiToken,
        search,
        date,
        triageLevels,
        division,
        paymentStatus
      });
      return [response, error, network];
    },

    getOAInviteLink: async ({
      apiToken,
      divisionId
    }: any) => {

      const [
        response,
        error,
        network
      ] = await context.proxyController.getOAInviteLink({
        apiToken,
        divisionId
      });
      return [response, error, network];
    },

    prxManager: {
      getListSchedule: context.proxyController.getListSchedule,
      getDiagRule: context.proxyController.getDiagRule,
      postCreateUpdateSchedule: context.proxyController.postCreateUpdateSchedule,
      getProxyPatientAllergy: context.proxyController.getProxyPatientAllergy
    },
  }
  context.ruleListController = {
    getDiagRule: async ({ apiToken, division, active }: any = {}) => {
      // 
      const [response, error, network] = await context.proxyController.getDiagRule({ apiToken, division, active });
      // 
      return [response, error, network];
    },
    postDiagRule: async ({ apiToken, data }: any = {}) => {
      const [response, error, network] = await context.proxyController.postDiagRule({ apiToken, data });
      return [response, error, network];
    },
    patchDiagRulePublish: async ({ apiToken, published, id }: any = {}) => {
      const [
        response,
        error,
        network
      ] = await context.proxyController.patchDiagRulePublish({ apiToken, published, id });
      return [response, error, network];
    },
    patchDiagRuleActive: async ({ apiToken, active, id, published }: any = {}) => {
      const [
        response,
        error,
        network
      ] = await context.proxyController.patchDiagRuleActive({ apiToken, active, id, published });
      return [response, error, network];
    }
  }
  context.chatController = {
    prxManager: {
      getDiagRule: context.proxyController.getDiagRule,
      getListAvailabelTriageLevelDiagRule: context.proxyController.getListAvailabelTriageLevelDiagRule,
      getListChatChannelTriageLevelDiagRule: context.proxyController.getListChatChannelTriageLevelDiagRule,
      getProxyPatientAllergy: context.proxyController.getProxyPatientAllergy,
      getPatientQueue:async ({
        apiToken,
        divisionIds,
        divisionGroupCode,
        unsignedOnly = false,
        signedOnly = false,
        search = "",
        appDate,
        limit
      }: any = {}) => {
        const [
          response,
          error,
          network
        ] = await context.proxyController.getPatientQueue({
          apiToken,
          divisionIds,
          divisionGroupCode,
          unsignedOnly,
          signedOnly,
          search,
          appDate,
          limit
        });
        return [response, error, network];
      }
    },
    msgManager: {
      postChatChannelMessage: async ({
        apiToken,
        content,
        contentType,
        chatChannelId,
        contentFile,
        divisionId
      }: any = {}) => {
        const [
          response,
          error,
          network
        ] = await context.proxyController.postChatChannelMessageMSG({
          apiToken,
          content,
          contentType,
          chatChannelId,
          contentFile,
          divisionId
        });
        return [response, error, network];
      },
      getChatChannelMessageList: async ({
        apiToken,
        last,
        chatChannelId,
        cancelToken,
        offset
      }: any = {}) => {
        const [
          response,
          error,
          network
        ] = await context.proxyController.getChatChannelMessageListMSG({
          apiToken,
          last,
          chatChannelId,
          cancelToken,
          offset
        });
        return [response, error, network];
      },
    },
    postEncounterPatientAppointment: async ({
      encounterId,
      apiToken
    }: any = {}) => {
      const [response, error, network] = await context.proxyController.postEncounterPatientAppointment({
        encounterId,
        apiToken
      });
      return [response, error, network];
    },
    getPatient: async ({
      apiToken
    }: any) => {
      const [data, error] = await context.proxyController.getPatient({
        apiToken
      });
      return [data, error]
    },
    getChatList: async ({
      apiToken,
      filter,
      patientId,
      divisionIds,
      hasPatientApp,
      orderBy,
      date
    }: any = {}) => {
      const [response, error, network] = await context.proxyController.getEncounterTriage({
        apiToken,
        filter,
        patientId,
        divisionIds,
        hasPatientApp,
        orderBy,
        date
      });
      return [response, error, network];
    },
    getEncounterList: async ({
      apiToken,
      filter,
      patientId,
      divisionIds
    }: any = {}) => {
      const [response, error, network] = await context.proxyController.getEncounterTriage({
        apiToken,
        filter,
        patientId,
        divisionIds
      });
      return [response, error, network];
    },
    getEncounterTriageDetail: async ({
      apiToken,
      encounterId
    }: any = {}) => {
      const [response, error, network] = await context.proxyController.getEncounterTriageDetail({
        apiToken,
        encounterId,
      });
      return [response, error, network];
    },
    getLatestChatChannel: async ({
      apiToken,
      userId
    }: any = {}) => {
      const [
        response,
        error,
        network
      ] = await context.proxyController.getLatestChatChannel({
        apiToken,
        userId
      });
      return [response, error, network];
    },
    getChatChannelMessageList: async ({
      apiToken,
      last,
      chatChannelId,
      cancelToken,
      offset,
    }: any = {}) => {
      const [
        response,
        error,
        network
      ] = await context.proxyController.getChatChannelMessageList({
        apiToken,
        last,
        chatChannelId,
        cancelToken,
        offset
      });
      return [response, error, network];
    },
    getMessageFromURL: async ({
      url,
      apiToken
    }: any = {}) => {
      const [response, error, network] = await context.proxyController.getMessageFromURL({
        apiToken,
        url
      });
      return [response, error, network];
    },
    postChatChannelMessage: async ({
      apiToken,
      content,
      contentType,
      chatChannelId,
      contentFile,
      divisionId
    }: any = {}) => {
      const [
        response,
        error,
        network
      ] = await context.proxyController.postChatChannelMessage({
        apiToken,
        content,
        contentType,
        chatChannelId,
        contentFile,
        divisionId
      });
      return [response, error, network];
    },
    getChannelDetail: async ({
      apiToken,
      chatChannelId
    }: any = {}) => {
      const [response, error, network] = await context.proxyController.getChannelDetail({
        apiToken,
        chatChannelId
      });
      return [response, error, network];
    },
    getEncounterDetail: async ({
      encounterId,
      apiToken
    }: any = {}) => {
      const [response, error, network] = await context.proxyController.getEncounter(
        encounterId,
        apiToken
      );
      return [response, error, network];
    },
    getPatientInfo: async ({
      patientId,
      apiToken
    }: any = {}) => {
      const [response, error, network] = await context.proxyController.getPatientInfo({
        patientId,
        apiToken
      });
      return [response, error, network];
    },
    getProxyPatient: async ({
      patientId,
      apiToken
    }: any = {}) => {
      const [response, error, network] = await context.proxyController.getProxyPatient({
        patientId,
        apiToken
      });
      return [response, error, network];
    },
    getTriageLevelClassify: async ({
      apiToken,
      divisionId
    }: any = {}) => {
      const [
        response,
        error,
        network
      ] = await context.proxyController.getTriageLevelClassify({
        apiToken,
        divisionId
      });
      return [response, error, network];
    },
    getClassifyUser: async ({
      apiToken,
      divisionId
    }: any = {}) => {
      const [
        response,
        error,
        network
      ] = await context.proxyController.getClassifyUser({
        apiToken,
        divisionId
      });
      return [response, error, network];
    },
    postReadMessage: async ({
      apiToken,
      chatChannelId
    }: any = {}) => {
      const [
        response,
        error,
        network
      ] = await context.proxyController.postReadMessage({
        apiToken,
        chatChannelId
      });
      return [response, error, network];
    },
    postUpdateSubscription: async ({
      apiToken,
      device_id,
      subscription
    }: any = {}) => {
      console.log("in controller: ", device_id, subscription);
      const [
        response,
        error,
        network
      ] = await context.proxyController.postUpdateSubscription({
        apiToken,
        device_id,
        subscription
      });
      return [response, error, network];
    },
    getDivisionProfile: async ({
      apiToken,
      divisionCode
    }: any = {}) => {
      const [
        response,
        error,
        network
      ] = await context.proxyController.getDivisionProfile({
        apiToken,
        divisionCode
      });
      return [response, error, network];
    },
    getResolveChatChannel: async ({
      apiToken,
      divisionId,
      hn
    }: any = {}) => {
      const [
        response,
        error,
        network
      ] = await context.proxyController.getResolveChatChannel({
        apiToken,
        divisionId,
        hn
      });
      return [response, error, network];
    },
    postRequestBotMessage: async ({
      apiToken,
      code_id,
      chat_channel_id
    }: any = {}) => {
      const [response, error, network] = await context.proxyController.postRequestBotMessage({
        apiToken,
        code_id,
        chat_channel_id
      });
      return [response, error, network];
    }
  }
  context.diagFormController = {
    getDiagForm: async ({
      apiToken,
      patient,
      limit,
      offset,
      division,
      today,
    }: any = {}) => {
      const [response, error, network] = await context.proxyController.getDiagForm({
        apiToken,
        patient,
        limit,
        offset,
        division,
        today,
      });
      return [response, error, network];
    },
    getDiagFormClassify: async ({ apiToken, patient }: any = {}) => {
      const [
        response,
        error,
        network,
      ] = await context.proxyController.getDiagFormClassify({
        apiToken,
        patient,
      });
      return [response, error, network];
    },
    postDiagFormMonitor: async ({ apiToken, patient, division }: any = {}) => {
      const [
        response,
        error,
        network,
      ] = await context.proxyController.postDiagFormMonitor({
        apiToken,
        patient,
        division,
      });
      return [response, error, network];
    },
    getPublishedDiagRule: async ({ apiToken, params, diagRuleId }: any) => {
      const [
        response,
        error,
        network,
      ] = await context.proxyController.getPublishedDiagRule({
        apiToken,
        diagRuleId,
        params,
      });
      return [response, error, network];
    },
    getTriageLevelClassify: async ({ apiToken, divisionId }: any = {}) => {
      const [
        response,
        error,
        network,
      ] = await context.proxyController.getTriageLevelClassify({
        apiToken,
        divisionId,
      });
      return [response, error, network];
    },
  }
  context.patientPanelController = {
    getPatientInfo: async ({ patientId, apiToken }: any = {}) => {
      const [response, error, network] = await context.proxyController.getPatientInfo(
        patientId,
        apiToken
      );
      return [response, error, network];
    },
    getProxyPatien: async ({ patientId, apiToken }: any = {}) => {
      const [response, error, network] = await context.proxyController.getProxyPatient(
        patientId,
        apiToken
      );
      return [response, error, network];
    }
  }
  context.progressNoteController = {
    geProgressNoteList: async ({ apiToken, encounterId }: any = {}) => {
      const [response, error, network] = await context.proxyController.geProgressNoteList(
        {
          apiToken,
          encounterId
        }
      );
      return [response, error, network];
    },
    createProgressionNote: async ({ apiToken, data }: any = {}) => {
      const [
        response,
        error,
        network
      ] = await context.proxyController.createProgressionNote({
        apiToken: apiToken,
        data: data
      });
      return [response, error, network];
    },
    putProgressNote: async ({ apiToken, emrId, data }: any = {}) => {
      const [
        response,
        error,
        network
      ] = await context.proxyController.putProgressNote({
        apiToken: apiToken,
        data: data,
        emrId
      });
      return [response, error, network];
    },
    putProgressNoteById: async ({ apiToken, progressNoteId, data }: any = {}) => {
      const [
        response,
        error,
        network
      ] = await context.proxyController.putProgressNoteById({
        apiToken: apiToken,
        data: data,
        progressNoteId
      });
      return [response, error, network];
    }
  }
  context.classifyController = {
    prxManager: {
      postRequestOA: context.proxyController.postRequestOA
    },
    getTriageLevelClassify: async ({ apiToken, divisionId }: any = {}) => {
      const [response, error, network] =
        await context.proxyController.getTriageLevelClassify({
          apiToken,
          divisionId,
        });
      return [response, error, network];
    },
    createDivisionHasUser: async ({ apiToken, data }: any = {}) => {
      const [response, error, network] =
        await context.proxyController.createDivisionHasUser({ apiToken, data });
      return [response, error, network];
    },
    updateTriage: async ({ apiToken, encounterId, triageLevel, diagFormId = null }: any = {}) => {
      const [response, error, network] =
        await context.proxyController.patchEncounterReclassify({
          apiToken,
          encounterId,
          triageLevel,
          diagFormId
        });
      return [response, error, network];
    },
    getFinishedTriageLevel: async ({ data }: any = {}) => {
      const [response, error, network] = await context.proxyController.getUserPermission(
        data
      );
      return [response, error, network];
    },
    getUserProfile: async ({ apiToken }: any) => {
      const [response, error, network] = await context.proxyController.getUserProfile({
        apiToken,
      });
      return [response, error, network];
    },
    updateUserProfile: async ({ apiToken, firstName, lastName, dob, phone }: any) => {
      const [response, error, network] = await context.proxyController.updateUserProfile(
        { apiToken, firstName, lastName, dob, phone }
      );
      return [response, error, network];
    },
    getListOARequestCheck: async ({ apiToken }: any) => {
      const [response, error, network] =
        await context.proxyControler.getListOARequestCheck({ apiToken });
      return [response, error, network];
    },
    postCreatejoinOARequest: async ({ apiToken, token }: any) => {
      const [response, error, network] =
        await context.proxyController.postCreatejoinOARequest({ apiToken, token });
      return [response, error, network];
    },
    getUserPosition: async ({ apiToken }: any) => {
      const [response, error, network] = await context.proxyController.getUserPosition({
        apiToken,
      });
      return [response, error, network];
    },
    getUserWithPatient: async ({ apiToken, userId }: any) => {
      const [response, error, network] = await context.proxyController.getUserWithPatient({
        apiToken,
        userId
      });
      return [response, error, network];
    },
    getOAUserProfile: async ({ apiToken }: any) => {
      const [response, error, network] = await context.proxyControler.getOAUserProfile({
        apiToken,
      });
      return [response, error, network];
    },
    patchOAUserProfile: async ({
      apiToken,
      email,
      first_name,
      last_name,
      dob,
      position,
      licence,
      image,
    }: any) => {
      const [response, error, network] =
        await context.proxyControler.patchOAUserProfile({
          apiToken,
          email,
          first_name,
          last_name,
          dob,
          position,
          licence,
          image,
        });
      return [response, error, network];
    },
  }
  context.crdVitalSignController = {
    getVitalSign: async ({
      startDate,
      endDate,
      startTime,
      endTime,
      encounterId,
    }: any = {}) => {
      if (!encounterId) {
        return [null, "Vital Sign กรุณาระบุ encounter"];
      }
      let params: any = {
        sort_by_seq: true,
        use_patient: true,
        invert_order: true
      };

      if (encounterId) {
        params.encounter = encounterId;
      }
      if (startDate) {
        params.from_date = startDate;
      }
      if (endDate) {
        params.to_date = endDate;
      }
      if (startTime) {
        params.from_time = startTime;
      }
      if (endTime) {
        params.to_time = endTime;
      }
      const [
        vitalSignData,
        vitalSignError,
        network,
      ] = await context.proxyController.getVitalSign(params);

      return [vitalSignData, vitalSignError, network];
    },
    getVitalSignType: async ({ encounterId }: any = {}) => {
      console.log("getVitalSignType");

      if (!encounterId) {
        return [null, "Vital Sign Type, กรุณาระบุ encounter"];
      }
      let params = {
        encounter: encounterId,
      };

      const [
        vitalSignType,
        vitalSignTypeError,
        network,
      ] = await context.proxyController.getVitalSignType(params);
      return [vitalSignType, vitalSignTypeError, network];
    },
    saveNewMeasurement: async (data: any) => {
      console.log("saveNewMeasurement");
      console.log(data);
      if (data.encounter === undefined || data.encounter === null) {
        data.encounter = context.controller.getState().selectedEncounter.id;
      }
      const [
        vsResponse,
        vsError,
        network,
      ] = await context.proxyController.postMeasurement(data);
      return [vsResponse, vsError, network];
    },
    updateVitalSign: async (data: any) => {
      const [
        vsResponse,
        vsError,
        network,
      ] = await context.proxyController.postVitalSignUpdate(data);
      if (!vsError) {
        console.log(vsResponse);
      }
      return [vsResponse, vsError, network];
    },
    deleteMeasurement: async (measurement_id: any, params: any) => {
      const [vsResponse, vsError, network] = await context.proxyController.patchMeasurement({ measurement_id, params });
      return [vsResponse, vsError, network]
    },
    getLastHeight: async (patientId: any) => {
      if (patientId === "undefined" || patientId === "null") {
        return [null, "ไม่มี patient ID", null]
      }
      const [
        response,
        responseError,
        network
      ] = await context.proxyController.getLastHeight(patientId);
      return [response, responseError];
    }
  }
  context.diagComposerController = {
    getDiagRuleDetail: async ({ apiToken, id }: any = {}) => {
      let [response, error, network] = await context.proxyController.getDiagRuleDetail({
        apiToken,
        id
      });
      return [response, error, network];
    },
    getDiagRule: async ({ apiToken, division }: any = {}) => {
      const [response, error, network] = await context.proxyController.getDiagRule({
        apiToken,
        division
      });
      return [response, error, network];
    },
    patchDiagRuleDetail: async ({ apiToken, content, id }: any = {}) => {
      const [
        response,
        error,
        network
      ] = await context.proxyController.patchDiagRuleDetail({ apiToken, content, id });
      return [response, error, network];
    },
    patchDiagRuleName: async ({ apiToken, name, id }: any = {}) => {
      const [
        response,
        error,
        network
      ] = await context.proxyController.patchDiagRuleName({ apiToken, name, id });
      return [response, error, network];
    },
    patchDiagRulePublish: async ({ apiToken, published, id }: any = {}) => {
      const [
        response,
        error,
        network
      ] = await context.proxyController.patchDiagRulePublish({ apiToken, published, id });
      return [response, error, network];
    },
    postPublishedDiagRule: async ({ apiToken, content, diagRule }: any = {}) => {
      const [
        response,
        error,
        network
      ] = await context.proxyController.postPublishedDiagRule({
        apiToken,
        content,
        diagRule
      });
      return [response, error, network];
    },
    patchPublishedDiagRule: async ({ apiToken, published, id }: any = {}) => {
      const [
        response,
        error,
        network
      ] = await context.proxyController.patchPublishedDiagRule({
        apiToken,
        published,
        id
      });
      return [response, error, network];
    },
    getTriageLevelClassify: async ({ apiToken, divisionId }: any = {}) => {
      console.log(" DiagComposerController, getTriageLevelClassify : divisionId: ", divisionId)
      const [
        response,
        error,
        network
      ] = await context.proxyController.getTriageLevelClassify({
        apiToken,
        divisionId
      });
      return [response, error, network];
    }
  }
  context.drugOrderHistoryController = {
    getNationalities: async () => {
      const [data, error] = await context.proxyController.getNationalities();
      let newArr = formatDropdown(data, "full_name", "id");
      return [newArr, error];
    },
    getSearchAllPatient: async ({
      hn,
      fullName,
      citizenId,
      nationality,
      is_old_name_search,
      limit,
      offset
    }: any = {}) => {
      const [data, error] = await context.proxyController.getSearchAllPatient({
        hn,
        fullName,
        citizenId,
        nationality,
        is_old_name_search,
        limit,
        offset
      });
      return [data, error];
    },
    getPatientByHN: async ({ hn }: any = {}) => {
      const [data, error, network] = await context.proxyController.getPatientByHN({
        hn
      });
      return [data, error, network];
    },
    getDoctor: async ({ search, name_code }: any = {}) => {
      const [data, error] = await context.proxyController.getDoctor({
        search,
        name_code
      })
      return [data, error]
    },
    getDrugSearch: async ({ keyword }: any = {}) => {
      const [data, error, network] = await context.proxyController.DrugSearch({
        keyword
      })
      return [data, error]
    },
    getPatientOldName: async ({ patientId }: any = {}) => {
      const [data, error, network] = await context.proxyController.getPatientOldName({
        patientId,
      });
      return [data, error, network];
    },
  }
  context.drugOrderQueueController = {
    getNationalities: async () => {
      const [data, error] = await context.proxyController.getNationalities();
      let newArr = formatDropdown(data, "full_name", "id");
      return [newArr, error];
    },
    getSearchAllPatient: async ({
      hn,
      fullName,
      citizenId,
      nationality,
      is_old_name_search,
      limit,
      offset,
    }: any = {}) => {
      const [data, error] = await context.proxyController.getSearchAllPatient({
        hn,
        fullName,
        citizenId,
        nationality,
        is_old_name_search,
        limit,
        offset,
      });
      return [data, error];
    },
    getPatientByHN: async ({ hn }: any = {}) => {
      const [data, error, network] = await context.proxyController.getPatientByHN({
        hn,
      });
      return [data, error, network];
    },
    getPatientOldName: async ({ patientId }: any = {}) => {
      const [data, error, network] = await context.proxyController.getPatientOldName({
        patientId,
      });
      return [data, error, network];
    },
  };
  context.appointmentDashboardController = {
    getLocation: async () => {
      const [data, error] = await context.proxyController.getLocation();
      return [data, error];
    },
    getDashboardAppointment: async () => {
      const [data, error] = await context.proxyController.getDashboardAppointment();
      return [data, error];
    },
  }
  context.cardANSBillController = {
    getAnesthesiaTemplateList: async () => {
      const [
        data,
        error,
        network,
      ] = await context.proxyController.getAnesthesiaTemplateList();
      return [data, error];
    },
    getAnesthesiaTemplateWithId: async (templateId: number) => {
      const [
        data,
        error,
        network,
      ] = await context.proxyController.getAnesthesiaTemplateWithId(templateId);
      return [data, error];
    }
  }
  context.cardSentClaimInformationController = {
    /** For "Search AR Transaction" */
    searchARTransaction: async ({
      invoiceDate,
      lotNo,
      fiscalYear,
      onlyContainFailed,
      page,
      pageSize,
    }: Record<string, any> = {}) => {
      const params: Record<string, any> = { groupType: "IPD" };
      if (page) {
        params.page = page;
      }
      if (pageSize) {
        params.pageSize = pageSize;
      }
      if (invoiceDate) {
        params.invoiceDate = invoiceDate;
      }
      if (lotNo) {
        params.lotNo = lotNo;
      }
      if (fiscalYear) {
        params.fiscalYear = fiscalYear;
      }
      if (onlyContainFailed) {
        params.onlyContainFailed = onlyContainFailed;
      }
      const [data, error, network] = await context.proxyController.getARTransaction(params);
      return [data, error];
    },
    loadBillTransactionItems: async ({
      coverageGroupId,
      startDate,
      endDate,
      transactionId,
      status,
      encounter,
      page,
      pageSize,
    }: any) => {
      const params: any = {};

      if (page) {
        params.page = page;
      }
      if (pageSize) {
        params.page_size = pageSize;
      }
      if (coverageGroupId) {
        params.coverage_payer_sent_claim_group = coverageGroupId;
      }
      if (startDate) {
        params.start_date = startDate;
      }
      if (endDate) {
        params.end_date = endDate;
      }
      if (status) {
        params.status = status;
      }
      if (encounter) {
        params.encounter = encounter;
      }
      // Get items
      if (transactionId) {
        params.ar_transaction = transactionId;
      }
      const [data, error, network] = await context.proxyController.getARTransaction(params);
      return [data, error];
    },
    choicesAdmitOrderType: async () => {
      const params = {
        model: "ADM.AdmitOrder",
        field: "admit_type",
        nameAsId: false,
      };
      const [data, error] = await context.cardSentClaimInformationController.getChoices(params);
      return data;
    },
    choicesSentClaimType: async () => {
      const params = {
        model: "INF.BillTransactionIPD",
        field: "submit_type",
        nameAsId: false,
      };
      const [data, error] = await context.cardSentClaimInformationController.getChoices(params);
      if (error) {
        return [];
      }
      return data;
    },
    choicesAdmitSource: async () => {
      const [data, error] = await context.cardSentClaimInformationController.getSentClaimChoices({
        field: "admit_source",
      });
      if (error) {
        return [];
      }
      return data;
    },
    choicesPreDischargeType: async () => {
      const [data, error] = await context.cardSentClaimInformationController.getSentClaimChoices({
        field: "predischarge_condition",
      });
      if (error) {
        return [];
      }
      let newList = data.map((item: any) => ({
        id: item.id,
        text: item.text,
        value: item.text,
      }));
      return newList;
    },
    choicesDischargeType: async () => {
      const [data, error] = await context.cardSentClaimInformationController.getSentClaimChoices({
        field: "discharge_type",
      });
      if (error) {
        return [];
      }
      let newList = data.map((item: any) => ({
        id: item.id,
        text: item.text,
        value: item.text,
      }));
      return newList;
    },
    choicesDivision: async () => {
      const [data, error] = await context.cardSentClaimInformationController.getSentClaimChoices({
        field: "division",
      });
      if (error) {
        return [];
      }
      return data;
    },
    loadDiagnosisInfo: async (transactionId: number) => {
      const params: any = {};
      if (transactionId) {
        params.bill_transaction_ipd = transactionId;
      }
      const [data, error, network] = await context.proxyController.getIPDDiagnosisSentClaim(params);
      return [data, error];
    },
    loadProcedureInfo: async (transactionId: number) => {
      const params: any = {};
      if (transactionId) {
        params.bill_transaction_ipd = transactionId;
      }
      const [data, error, network] = await context.proxyController.getIPDProcedureSentClaim(params);
      return [data, error];
    },
    loadInvoiceItems: async (transactionId: number) => {
      const params: any = {};
      if (transactionId) {
        params.bill_transaction_ipd = transactionId;
      }
      const [data, error, network] = await context.proxyController.getBillTransactionIPDItem(
        params
      );
      return [data, error];
    },
    loadBillTransactionDetail: async (transactionId: number) => {
      const [data, error] = await context.proxyController.getBillTransactionIPDWithId(
        transactionId
      );
      return [data, error];
    },
    updateBillTransaction: async (transactionId: number, data: any) => {
      const [response, error] = await context.proxyController.updateBillTransactionIPDWithId({
        transactionId,
        data,
      });
      return [response, error];
    },
    updateDiagnosis: async (ipdDiagnosisId: number, data: any) => {
      const [response, error] = await context.proxyController.updateIPDDiagnosisSentClaimWithId({
        ipdDiagnosisId,
        data,
      });
      return [response, error];
    },
    updateProcedure: async (ipdProcedureId: number, data: any) => {
      const [response, error] = await context.proxyController.updateIPDProcedureSentClaimWithId({
        ipdProcedureId,
        data,
      });
      return [response, error];
    },
    getUserTokenize: async (code: string) => {
      const [data, error] = await context.proxyController.getUserTokenize({
        code,
      });
      return [data, error];
    },
    getChoices: async ({ model, field, nameAsId, withEmptyItem }: any = {}) => {
      const params: any = {};
      let identificationDocData = [];
      let identificationDocError = null;

      if (model) {
        params.model = model;
      }
      if (field) {
        params.field = field;
      }
      if (nameAsId) {
        params.name_as_id = true;
      }
      if (withEmptyItem) {
        params.withEmptyItem = withEmptyItem;
      }

      const [data, error] = await context.proxyController.getChoices(params);

      if (data) {
        const choices = data.map((item: any) => ({
          id: item.id,
          text: item.text || item.label || item.value,
          value: item.value,
        }));
        if (withEmptyItem) {
          identificationDocData = [{ id: 0, text: "-", value: "" }, ...choices];
        } else {
          identificationDocData = choices;
        }
      } else {
        identificationDocError = error;
      }
      return [identificationDocData, identificationDocError];
    },
    getSentClaimChoices: async ({ field }: any = {}) => {
      let dataList: any[] = [];
      const params: any = {};
      if (field) {
        params.field = field;
      }
      const [data, error] = await context.proxyController.getSentClaimChoices(params);

      if (!error) {
        dataList = (data?.items || []).map((item: any) => ({
          id: item.id,
          text: item.text || item.label || item.value,
          value: item.value,
        }));
      }
      return [dataList, error];
    },
  };
}

export default setUpController;