export default class BindHNPageController {
  constructor(props) {
    this.prxManager = props.prxManager;
  }

  getProxyPatientList = async ({ division, search, apiToken } = {}) => {
    const [response, error, network] = await this.prxManager.getProxyPatientList({
      division,
      search,
      apiToken
    });
    return [response, error, network];
  };

  patchProxyPatientHasDivision = async ({ apiToken, hn, id } = {}) => {
    const [response, error, network] = await this.prxManager.patchProxyPatientHasDivision({
      id,
      hn,
      apiToken
    });
    return [response, error, network];
  }

  patchProxyPatientHasDivisionAdmin = async ({ apiToken, hn, id } = {}) => {
    const [response, error, network] = await this.prxManager.patchProxyPatientHasDivisionAdmin({
      id,
      hn,
      apiToken
    });
    return [response, error, network];
  }
}
