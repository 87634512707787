import React, { useEffect } from 'react';
import CardLayout from '../common/CardLayout';
import { useIntl } from "react-intl";
import { Tab } from 'semantic-ui-react'

const CardDischargePlanningTabView = (props) => {

  useEffect(() => {
    props.onGetDischargePlanning({})
  }, [])

  const findChildren = (key) => {
    return props.children.find((items) => items.key === key)
  } 

  const panes = [
    {
      menuItem: 'Discharge Planning',
      render: () => findChildren('dischargePlanning'),
    },
    {
      menuItem: 'Discharge Teaching',
      render: () => findChildren('dischargeTeaching') ,
    },
    {
      menuItem: 'Discharge Summary',
      render: () => findChildren('dischargeSummary'),
    },
  ]

  return (
    <CardLayout
      titleText='Discharge Planning Information'
      headerColor='pink'
      closeable={props.closeable}
      toggleable={props.toggleable}
      defaultBackground={props.defaultBackground}
      hideHeaderIcon={props.hideHeaderIcon}
      loading={props.dischargePlanningLoading}
      enableMargin={props.enableMargin}
    >
    <Tab panes={panes} menu={{ attached: false }}/>
    </CardLayout>
  )
}

CardDischargePlanningTabView.defaultProps = {
  closeable: true,
  toggleable: true,
  defaultBackground: true,
  hideHeaderIcon: false,
  onGetDischargePlanning: () => {},
  dischargePlanning: {},
  dischargePlanningLoading: false,
  children: [],
  enableMargin: true
};

export default CardDischargePlanningTabView;
