import * as DoctorNoteOrderI from "./DoctorNoteOrder";
import * as SetProgressCycleI from "./SetProgressCycle";
import * as OrderTreatmentI from "./OrderTreatment";
import * as DoctorPendingTask from "./DoctorPendingTask"
import * as TreatmentResultI from"./TreatmentResult";
import * as ConsultationListI from "./ConsultationList";
import * as DoctorCertificateI from "./DoctorCertificate";
import * as PhysicalExamI from "./PhysicalExam";
import * as TimeTrackingI from "./TimeTracking";

const SequencePattern: {
  [name: string]: { [flavor: string]: { [func: string]: any } };
} = {
  SetProgressCycle: {
    default: {
      START: SetProgressCycleI.RefreshProgressCycle,
      AddOrEdit: SetProgressCycleI.AddOrEdit,
    },
  },
  OrderTreatment: {
    default: {
      START: OrderTreatmentI.GetMaster,
      SearchAndAdd: OrderTreatmentI.SearchAndAdd,
    },
  },
  DoctorNoteOrder: {
    default: {
      START: DoctorNoteOrderI.InitData,
      AddOrEdit: DoctorNoteOrderI.AddOrEdit,
    },
  },
  DoctorPendingTask: {
    default: {
      START: DoctorPendingTask.START,
      SearchAndEdit: DoctorPendingTask.SearchAndEdit,
    },
  },
  TreatmentResult: {
    default: {
      START: TreatmentResultI.Start,
      SaveTreatmentResult: TreatmentResultI.SaveTreatmentResult,
    },
  },
  ConsultationList: {
    default: {
      START: ConsultationListI.Start,
      Action: ConsultationListI.Action,
    },
  },
  DoctorCertificate: {
    default: {
      START: DoctorCertificateI.Start,
      Action: DoctorCertificateI.Action,
    },
  },
  PhysicalExam: {
    default: {
      START: PhysicalExamI.Start,
      Action: PhysicalExamI.Action,
    },
  },
  TimeTracking: {
    default: {
      START: TimeTrackingI.Start,
      Action: TimeTrackingI.Action,
    }
  }
};

export default SequencePattern;
