import React, { useMemo } from "react";
import { Icon } from "semantic-ui-react";

// Common
import ModInfo from "react-lib/apps/common/ModInfo";
import { useIntl } from "react-intl";

// types
type ModDrugFactProps = {
  open: boolean;
  // data
  drugDetail?: Record<string, any>;
  // callback
  onClose: () => void;
};

const ModDrugFact = (props: ModDrugFactProps) => {
  const intl = useIntl();
  const factsheetData = useMemo(() => {
    return {
      ...(props.drugDetail?.factsheet_data || {}),
      image: props.drugDetail?.drug_image_url || "",
      name: props.drugDetail?.name || "",
      generic_name: props.drugDetail?.generic_name || "",
      // drug_using:
      //   "รับประทาน ครั้งละ 1 เม็ด วันละ 2 ครั้ง เช้า เย็น ในกรณีที่ติดเชื้อ",
      // suggestions: "เป็นยาฆ่าเชื้อที่ต้อง รับประทานจนหมดตามจำนวนที่แพทย์สั่ง",
      // cautions: "อาจต้องระวังเกิดอาการท้องเสีย",
      // warnings: "ต้องรับประทานจนหมดตามจำนวนที่แพทย์สั่ง",
      // others: "-",
    } as {
      name: string;
      generic_name: string;
      drug_using: string;
      suggestions: string;
      cautions: string;
      warnings: string;
      others: string;
      image: string;
    };
  }, [props.drugDetail]);

  return (
    <ModInfo
      open={props.open}
      titleName={intl.formatMessage({ id: "รายละเอียดรายการยา" })}
      titleColor="blue"
      buttonStyle={{ display: "none" }}
      style={{ width: "40%" }}
      closeOnDimmerClick={true}
      onClose={props.onClose}
    >
      <div style={{ padding: "5px 0 0" }}>
        <Icon
          name="close"
          style={{
            position: "absolute",
            right: "0.5rem",
            top: "-3.3rem",
            cursor: "pointer",
            color: "white",
          }}
          onClick={props.onClose}
        />
        {factsheetData.image && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "15px",
            }}
          >
            <img
              src={factsheetData.image}
              alt="drug_image"
              style={{ maxHeight: "120px" }}
            />
          </div>
        )}
        {[
          ["ชื่อยา", factsheetData.name],
          ["ส่วนประกอบ", factsheetData.generic_name],
          ["วิธีใช้ยา", factsheetData.drug_using],
          ["ข้อแนะนำ", factsheetData.suggestions],
          ["ข้อควรระวัง", factsheetData.cautions],
          ["คำเตือน", factsheetData.warnings],
          ["ข้อมูลอื่นๆ", factsheetData.others],
        ].map(([key, value], index) => (
          <div
            key={key}
            style={{
              display: "grid",
              gridTemplateColumns: "150px auto",
              borderBottom: index === 6 ? "" : "1px solid rgb(200 199 199)",
            }}
          >
            <div
              style={{
                padding: "15px 12px",
                fontWeight: "bold",
                textAlign: "left",
                backgroundColor: "#e8f8fc",
                borderRight: "1px solid rgb(200 199 199)",
              }}
            >
              {key}
            </div>
            <div
              style={{
                padding: "15px 18px",
                textAlign: "left",
                lineHeight: 1.75,
              }}
            >
              {value}
            </div>
          </div>
        ))}
      </div>
    </ModInfo>
  );
};

export default React.memo(ModDrugFact);
