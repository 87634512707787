import * as HistoryCentralLabI from "./HistoryCentralLab";
import * as OrderCentralLabI from "./OrderCentralLab";
import * as LabReportI from"./LabReport";
import * as OPDLabSummaryI from "./OPDLabSummary";


const SequencePattern: { [name: string]: { [flavor: string] : { [func: string]: any }}} = {
  OrderCentralLab: { 
    default: {
      START: OrderCentralLabI.GetMaster,
      SelectLabTest: OrderCentralLabI.SelectLabTest,
    }    
  },
  HistoryCentralLab: {
    default: {
      START: HistoryCentralLabI.Start
    }
  },
  OPDLabSummary: {
    default: {
      START: OPDLabSummaryI.GetMaster,
      Action: OPDLabSummaryI.Action,
    }
  },
  LabReport:{
    default:{
      START: LabReportI.Start,
      Action: LabReportI.Action,
    }
  }
};

export default SequencePattern;
