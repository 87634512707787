import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from "react";

import { Icon, IconProps, Modal } from "semantic-ui-react";

import moment from "moment";
import { SlotInfo, View } from "react-big-calendar";

// Common
import DropdownOptions from "react-lib/appcon/common/DropdownOptions";
import ModInfo from "react-lib/apps/common/ModInfo";
import ModSelectOperatingDateTime from "react-lib/apps/HISV3/ORM/ModSelectOperatingDateTime";
import SnackMessage from "react-lib/apps/common/SnackMessage";

// UX
import CardOperatingCalendar from "./CardOperatingCalendar";
import CardSelectDateTimeOperationUX from "./CardSelectDateTimeOperationUX";

import { getTimeSlotRange, roundTime } from "../HISV3/ORM/sequence/OperatingDateTime";
import { useIntl } from "react-intl";

// Types
type CardSelectDateTimeOperationProps = {
  onEvent: (event: any) => void;
  setProp: (key: string, value: any, callback?: () => void) => any;
  // seq
  runSequence: (params: any) => void;
  ORRequestSequence?: any;
  OperatingDateTimeSequence?: {
    sequenceIndex?: string;
    constance?: {
      core_OPD_ENCOUNTER_EXPIRE?: number;
    };
    estimateEndDate?: any;
    selectedDoctorSchedule?: Record<string, any>;
  };
  // data
  card?: string;
  estimateTimeText?: string;
  operatingBlock?: any[];
  operatingLocationRoom?: any[];
  preOrderList?: any[];
  selectedAnesthesiologist?: any;
  selectedAppointment?: Record<string, any>;
  selectedDoctor?: any;
  selectedOperatingDetail?: any;
  selectedOperatingRoom?: any;
  selectedOrOrder?: Record<string, any>;
  selectOperatingDSBChange?: boolean;
  errorMessage?: Record<string, any>;
  masterOptions?: {
    doctor?: any[];
    orType?: { text: string; value: string }[];
  };
  // callback
  onPostpone?: () => void;
  onUpdated?: () => void;
};

type Styles = Record<
  "dropdown" | "icon" | "majorIcon" | "minorIcon" | "modal" | "modInfo",
  CSSProperties
>;

// Constants
const styles: Styles = {
  dropdown: { width: "100%" },
  icon: {
    cursor: "pointer",
    marginLeft: "5px",
  },
  majorIcon: { color: "#E95959" },
  minorIcon: { color: "#FFC5C5" },
  modal: {
    background: "transparent",
    boxShadow: "none",
    padding: "5vh 0",
  },
  modInfo: { width: "fit-content" },
};

const CARD_SELECT_DATE_TIME_OPERATION = "CardSelectDateTimeOperation";

const DATE_FORMAT = "YYYY-MM-DD";

const CURRENT_DATE = moment().format(DATE_FORMAT);
const START_WEEK_DATE = moment().startOf("week").toDate();
const END_WEEK_DATE = moment().endOf("week").toDate();

const CardSelectDateTimeOperation = (props: CardSelectDateTimeOperationProps) => {
  const intl = useIntl();
  // Mod
  const [openModOperatingDateTime, setOpenModOperatingDateTime] = useState<boolean>(false);
  const [openModDetail, setOpenModDetail] = useState<boolean>(false);
  const [modError, setModError] = useState<string>("");
  // Filter
  const [doctor, setDoctor] = useState<any>(null);
  const [anesthesiologist, setAnesthesiologist] = useState<any>(null);
  const [room, setRoom] = useState<any[]>([]);
  const [isFilterDoctor, setIsFilterDoctor] = useState<boolean>(true);
  const [isFilterAnesthetic, setIsFilterAnesthetic] = useState<boolean>(true);
  // loading
  const [isLoadingTimeSlot, setIsLoadingTimeSlot] = useState<boolean>(false);
  const [operatingId, setOperatingId] = useState<any>(null);
  const [selectedOperatingDetail, setSelectedOperatingDetail] = useState<any>(null);
  // ---
  const [calendarView, setCalendarView] = useState<View>("week");
  const [rangeDate, setRangeDate] = useState<{
    end: Date;
    start: Date;
  }>({
    end: END_WEEK_DATE,
    start: START_WEEK_DATE,
  });

  // Effect
  useEffect(() => {
    props.runSequence({ sequence: "OperatingDateTime", restart: true });

    return () => {
      props.runSequence({ sequence: "OperatingDateTime", clear: true });
    };
  }, []);

  useEffect(() => {
    if (props.OperatingDateTimeSequence?.sequenceIndex === "Action" && props.selectedDoctor) {
      const estimatedAt = props.selectedAppointment?.estimated_at_iso;

      let startMoment = moment(rangeDate.start);
      let endMoment = moment(rangeDate.end);

      if (estimatedAt) {
        startMoment = moment(estimatedAt).startOf("week");
        endMoment = moment(estimatedAt).endOf("week");

        setRangeDate({
          end: endMoment.toDate(),
          start: startMoment.toDate(),
        });
      }

      const startDate = startMoment.format(DATE_FORMAT);
      const endDate = endMoment.format(DATE_FORMAT);

      const params = {
        anesthesiologist: null,
        doctor: props.selectedDoctor,
        end_date: endDate,
        room: [] as number[],
        start_date: startDate,
      };

      setDoctor(props.selectedDoctor || null);

      if (props.selectedAnesthesiologist) {
        params.anesthesiologist = props.selectedAnesthesiologist;
        setAnesthesiologist(props.selectedAnesthesiologist);
      } else {
        setAnesthesiologist(null);
      }

      if (props.selectedOperatingRoom) {
        params.room = [props.selectedOperatingRoom];
        setRoom([props.selectedOperatingRoom]);
      } else {
        setRoom([]);
      }

      const operatingId =
        props.preOrderList?.find((item: any) => item.type === "operatingorder")?.id || null;

      props.runSequence({
        sequence: "OperatingDateTime",
        action: "getDSBBlock",
        card: CARD_SELECT_DATE_TIME_OPERATION,
        ...params,
        operatingId,
      });

      setOperatingId(operatingId);

      return;
    }

    setDoctor(null);
  }, [
    props.OperatingDateTimeSequence?.sequenceIndex,
    props.preOrderList,
    props.selectedAnesthesiologist,
    props.selectedDoctor,
    props.selectedOperatingRoom,
  ]);

  // Callback
  const handleCloseModOperatingDateTime = useCallback(() => {
    setOpenModOperatingDateTime(false);
  }, []);

  const handleOnCancelSelectOperatingTime = useCallback(() => {
    const params = {
      anesthesiologist: null,
      doctor: props.selectedDoctor,
      end_date: moment(rangeDate.end).format(DATE_FORMAT),
      room: null,
      start_date: moment(rangeDate.start).format(DATE_FORMAT),
    };

    setDoctor(props.selectedDoctor || null);

    if (props.selectedAnesthesiologist) {
      params.anesthesiologist = props.selectedAnesthesiologist;
      setAnesthesiologist(props.selectedAnesthesiologist);
    } else {
      setAnesthesiologist(null);
    }

    if (props.selectedOperatingRoom) {
      params.room = props.selectedOperatingRoom;
      setRoom([props.selectedOperatingRoom]);
    } else {
      setRoom([]);
    }

    setIsLoadingTimeSlot(true);

    props.runSequence({
      sequence: "OperatingDateTime",
      action: "unsetAndClose",
      callback: () => {
        setOpenModOperatingDateTime(false);

        props.runSequence({
          sequence: "OperatingDateTime",
          action: "getDSBBlock",
          card: CARD_SELECT_DATE_TIME_OPERATION,
          ...params,
          operatingId,
          onSuccess: () => {
            setIsLoadingTimeSlot(false);
          },
        });
      },
      card: props.card,
    });
  }, [
    operatingId,
    props.card,
    props.selectedAnesthesiologist,
    props.selectedDoctor,
    props.selectedOperatingRoom,
    rangeDate,
  ]);

  const handleChangeOption = useCallback(
    (name: string) => (e: any, data: any) => {
      const params = {
        anesthesiologist,
        doctor,
        end_date: moment(rangeDate.end).format(DATE_FORMAT),
        [name]: data.value,
        room,
        start_date: moment(rangeDate.start).format(DATE_FORMAT),
      };

      if (name === "doctor") {
        setDoctor(data.value);
      } else if (name === "anesthesiologist") {
        setAnesthesiologist(data.value);
      }

      setIsLoadingTimeSlot(true);

      props.runSequence({
        sequence: "OperatingDateTime",
        action: "getDSBBlock",
        card: CARD_SELECT_DATE_TIME_OPERATION,
        ...params,
        operatingId,
        onSuccess: () => {
          setIsLoadingTimeSlot(false);
        },
      });
    },
    [anesthesiologist, doctor, operatingId, rangeDate, room]
  );

  const handleChangeOptions = useCallback(
    (e: any, v: any) => {
      const params = {
        anesthesiologist,
        doctor,
        end_date: moment(rangeDate.end).format(DATE_FORMAT),
        start_date: moment(rangeDate.start).format(DATE_FORMAT),
      };

      setRoom(v.value);

      setIsLoadingTimeSlot(true);

      props.runSequence({
        sequence: "OperatingDateTime",
        action: "getDSBBlock",
        card: CARD_SELECT_DATE_TIME_OPERATION,
        ...params,
        operatingId,
        room: v.value,
        onSuccess: () => {
          setIsLoadingTimeSlot(false);
        },
      });
    },
    [anesthesiologist, doctor, operatingId, rangeDate]
  );

  const handleCloseModWarning = useCallback(() => {
    setModError("");
  }, []);

  const handleCloseModDetail = useCallback(() => {
    setOpenModDetail(false);
  }, []);

  const handleCloseModDoctorSchedule = useCallback(() => {
    props.setProp("OperatingDateTimeSequence.selectedDoctorSchedule", null);
  }, []);

  const handleSelectEvent = useCallback(
    async (event: any) => {
      // #if (event.operating_detail === props.selectedOperatingDetail || event.operating_detail === props.selectedOrOrder?.operating_detail) {
      if (event.operating_detail) {
        await props.setProp("OperatingDateTimeSequence.estimateEndDate", null);

        props.runSequence({
          sequence: "OperatingDateTime",
          action: "selectEvent",
          card: CARD_SELECT_DATE_TIME_OPERATION,
          data: { ...event },
        });

        setSelectedOperatingDetail(event.operating_detail);
        setOpenModOperatingDateTime(true);

        return;
      }

      if (event.is_doctor_schedule) {
        props.runSequence({
          sequence: "OperatingDateTime",
          action: "selectDoctorSchedule",
          card: CARD_SELECT_DATE_TIME_OPERATION,
          data: { ...event },
        });
      } else if (calendarView === "month") {
        // #setLoadingTimeSlot(true);
        // // console.log('CardSelectDateTimeOpeeration event.start: ', event.start);
        // const start = moment(event.start).startOf("week").toDate();
        // const end = moment(event.start).endOf("week").toDate();
        // setRangeDate({ start, end });
        // setCalendarView("week");
        // setTimeout(() => {
        //   setLoadingTimeSlot(false);
        // }, 100);
      }
    },
    [calendarView]
  );

  const handleSelectDatetime = async (info: Partial<SlotInfo>) => {
    await props.setProp("OperatingDateTimeSequence.estimateEndDate", null);
    await props.setProp("OperatingDateTimeSequence.operatingRoom", null);

    props.runSequence({
      sequence: "OperatingDateTime",
      action: "selectDateTime",
      anesthesia: anesthesiologist,
      card: CARD_SELECT_DATE_TIME_OPERATION,
      data: {
        ...info,
        operatingId,
      },
      doctor,
      room,
    });

    setSelectedOperatingDetail(props.selectedOperatingDetail);
    setOpenModOperatingDateTime(true);
  };

  const isValidSelection = (info: SlotInfo, isSelect: boolean, showDetail: boolean): boolean => {
    if (isSelect && !showDetail) {
      if (!doctor) {
        setModError("กรุณาระบุแพทย์ผ่าตัด สำหรับระบุนัดหมายผ่าตัด");

        return false;
      }

      if (room.length === 0) {
        setModError("กรุณาระบุห้องผ่าตัด สำหรับระบุนัดหมายผ่าตัด");

        return false;
      }
    }

    return true;
  };

  const handleMonthViewSelection = (info: SlotInfo, showDetail: boolean): void => {
    if (showDetail) {
      setOpenModDetail(true);

      return;
    }

    const currentMoment = moment();
    const startMoment = moment(info.start).set({
      hour: currentMoment.hour(),
      minute: currentMoment.minute(),
      second: currentMoment.second(),
    });

    handleSelectDatetime({
      start: startMoment.toDate(),
    });
  };

  const isValidOPDTimeSlot = (info: SlotInfo): boolean => {
    const { encounterDatetime } = props.selectedOrOrder || {};

    if (!encounterDatetime) {
      return true;
    }

    const timeSlotRange = getTimeSlotRange(encounterDatetime, opdEncounterExpire);
    const isBetween = moment(info.start).isBetween(timeSlotRange.start, timeSlotRange.end);
    const isTimePast = moment(info.end).diff(roundTime(moment()), "minutes") <= 0;

    return isBetween && !isTimePast;
  };

  const handleDoctorSelection = (info: SlotInfo, showDetail: boolean): void => {
    const { encounterType } = props.selectedOrOrder || {};

    const isTimePast = isTimeSlotInFuture(info.start, encounterType);

    const hasSelectedAppointment = !!props.selectedAppointment;
    const noDivisionServiceBlock = !props.selectedAppointment?.division_service_block;
    const hasSelectOperatingDSBChange = !!props.selectOperatingDSBChange;

    const isValidAppointmentOrDSBChange =
      (hasSelectedAppointment && noDivisionServiceBlock) || hasSelectOperatingDSBChange;

    const isSelectable = isValidAppointmentOrDSBChange && !isLoadingTimeSlot && isTimePast;

    if (isSelectable) {
      handleSelectDatetime(info);
    } else if (showDetail) {
      setOpenModDetail(true);
    }
  };

  const isTimeSlotInFuture = (startTime: Date, encounterType: string | undefined): boolean => {
    if (encounterType === "OPD") {
      return true;
    }

    const selectDate = moment(startTime).format(DATE_FORMAT);
    const currentDate = moment(CURRENT_DATE).format(DATE_FORMAT);

    return (
      moment(selectDate).valueOf() > moment(currentDate).valueOf() ||
      (selectDate === currentDate && moment(startTime).diff(moment(), "minutes") >= 0)
    );
  };

  const handleSelectSlot = useCallback(
    (info: SlotInfo) => {
      const isValidSlotSelection =
        calendarView === "month" ? info.slots.length === 1 : info.slots.length === 2;
      const isSelect = isValidSlotSelection && ["click", "select"].includes(info.action);
      const showDetail =
        props.selectedAppointment?.division_service_block && !props.selectOperatingDSBChange;

      if (!isValidSelection(info, isSelect, showDetail)) {
        return;
      }

      if (calendarView === "month") {
        handleMonthViewSelection(info, showDetail);

        return;
      }

      const { encounterType } = props.selectedOrOrder || {};

      if (encounterType === "OPD" && !isValidOPDTimeSlot(info)) {
        return;
      }

      if (isSelect && doctor) {
        handleDoctorSelection(info, showDetail);
      }
    },
    [
      calendarView,
      doctor,
      handleDoctorSelection,
      handleMonthViewSelection,
      isValidOPDTimeSlot,
      isValidSelection,
      props.selectedAppointment?.division_service_block,
      props.selectOperatingDSBChange,
    ]
  );

  const handleChangeDate = useCallback(
    (date: { end: Date; start: Date }, view: string) => {
      props.runSequence({
        sequence: "OperatingDateTime",
        action: "getDSBBlock",
        anesthesiologist,
        card: CARD_SELECT_DATE_TIME_OPERATION,
        doctor: props.selectedDoctor,
        end_date: moment(date.end).format(DATE_FORMAT),
        operatingId,
        room,
        start_date: moment(date.start).format(DATE_FORMAT),
        view,
        onSuccess: () => {
          setIsLoadingTimeSlot(false);
        },
      });
    },
    [anesthesiologist, doctor, moment, operatingId, props, room]
  );

  const handleCloseErrMsg = useCallback(() => {
    props.setProp(`errorMessage.${CARD_SELECT_DATE_TIME_OPERATION}`, null);
  }, []);

  // Memo
  const disabledFilter = useMemo(
    () => !!props.selectedOrOrder?.operating_room && !props.selectOperatingDSBChange,
    [props.selectedOrOrder, props.selectOperatingDSBChange]
  );

  const operatingRoomOptions = useMemo(
    () => (
      <DropdownOptions
        disabled={disabledFilter}
        // fluid={true}
        style={styles.dropdown}
        value={room}
        checked
        inline
        multiple
        search
        options={props.operatingLocationRoom}
        showSubOptions
        onChange={handleChangeOptions}
      />
    ),
    [disabledFilter, handleChangeOptions, props.operatingLocationRoom, room]
  );

  const selectedDoctorSchedule = useMemo(
    () => props.OperatingDateTimeSequence?.selectedDoctorSchedule,
    [props.OperatingDateTimeSequence?.selectedDoctorSchedule]
  );

  const orOrderType = useMemo(
    () =>
      props.masterOptions?.orType?.find(
        (option: any) => option.value === props.selectedOrOrder?.type
      )?.text || "",
    [props.masterOptions?.orType, props.selectedOrOrder]
  );

  const opdEncounterExpire = useMemo(
    () => props.OperatingDateTimeSequence?.constance?.core_OPD_ENCOUNTER_EXPIRE || 0,
    [props.OperatingDateTimeSequence?.constance]
  );

  const operatingCase = useMemo(() => {
    const caseDetail = props.selectedOrOrder?.case || {};

    if (caseDetail.is_ipd_case) {
      return "IPD";
    } else if (caseDetail.is_one_day_case) {
      return "One day";
    } else if (caseDetail.is_opd_case) {
      return "OPD";
    }

    return "";
  }, [props.selectedOrOrder?.case]);

  const anesthesiaLabel = useMemo(
    () => (
      <FilterableLabel
        backgroundColor="#E3BFF3"
        isFiltered={isFilterAnesthetic}
        text={intl.formatMessage({ id: "วิสัญญีแพทย์" })}
        onFilterToggle={setIsFilterAnesthetic}
      />
    ),
    [isFilterAnesthetic]
  );

  const doctorLabel = useMemo(
    () => (
      <FilterableLabel
        backgroundColor="#FFE1A9"
        isFiltered={isFilterDoctor}
        text={intl.formatMessage({ id: "แพทย์ผ่าตัด" })}
        onFilterToggle={setIsFilterDoctor}
      />
    ),
    [isFilterDoctor]
  );

  const operatingRoomLabel = useMemo(
    () => (
      <FilterableLabel
        backgroundColor="#E95959"
        text={intl.formatMessage({ id: "ห้องผ่าตัด" })}
        additionalInfo={
          <>
            <div style={{ alignItems: "center", display: "flex", marginLeft: "5px" }}>
              <Icon name="circle" style={styles.majorIcon} />
              <div style={{ marginLeft: "3px" }}>Major</div>
            </div>
            <div style={{ alignItems: "center", display: "flex", marginLeft: "5px" }}>
              <Icon name="circle" style={styles.minorIcon} />
              <div style={{ marginLeft: "3px" }}>Minor</div>
            </div>
          </>
        }
      />
    ),
    []
  );

  const selectCalendar = useMemo(
    () => (
      <CardOperatingCalendar
        anesthesiologist={anesthesiologist}
        calendarView={calendarView}
        // Filter
        doctor={doctor}
        isFilterAnesthetic={isFilterAnesthetic}
        isFilterDoctor={isFilterDoctor}
        opdEncounterExpire={opdEncounterExpire}
        operatingBlock={props.operatingBlock}
        operatingLocationRoom={props.operatingLocationRoom}
        rangeDate={rangeDate}
        room={room}
        // data
        selectedOperatingDetail={props.selectedOperatingDetail}
        selectedOrOrder={props.selectedOrOrder}
        selectOperatingDSBChange={props.selectOperatingDSBChange}
        onChangeCalendarView={setCalendarView}
        onChangeDate={handleChangeDate}
        onChangeRangeDate={setRangeDate}
        // callback
        onSelectEvent={handleSelectEvent}
        onSelectSlot={handleSelectSlot}
      />
    ),
    [
      anesthesiologist,
      calendarView,
      doctor,
      handleChangeDate,
      handleSelectEvent,
      handleSelectSlot,
      isFilterAnesthetic,
      isFilterDoctor,
      opdEncounterExpire,
      props.operatingBlock,
      props.operatingLocationRoom,
      props.selectedOperatingDetail,
      props.selectedOrOrder,
      props.selectOperatingDSBChange,
      rangeDate,
      room,
      setCalendarView,
      setRangeDate,
    ]
  );

  return (
    <div id="CardSelectDateTimeOperation">
      <SnackMessage
        onEvent={props.onEvent}
        success={null}
        onClose={handleCloseErrMsg}
        error={
          props.errorMessage?.[CARD_SELECT_DATE_TIME_OPERATION]?.message ||
          props.errorMessage?.[CARD_SELECT_DATE_TIME_OPERATION]
        }
        languageUX={props.languageUX}
      />

      <CardSelectDateTimeOperationUX
        anesthesiaLabel={anesthesiaLabel}
        ansDoctor={anesthesiologist}
        doctorLabel={doctorLabel}
        handleChangeOption={handleChangeOption}
        operatingCase={operatingCase}
        operatingRoomLabel={operatingRoomLabel}
        operationDoctor={doctor}
        orOrderType={orOrderType}
        selectCalendar={selectCalendar}
        disabledAnesthesia
        // ORRoom={room}
        disabledDoctor
        disabledOperatingRoom
        ansDoctorOptions={props.masterOptions?.doctor}
        disabledAnesthesiaOptions={disabledFilter}
        disabledDoctorOptions={disabledFilter}
        operatingRoomOptions={operatingRoomOptions}
        // disabledDoctorOptions={!props.selectOperatingDSBChange}
        operationDoctorOptions={props.masterOptions?.doctor}
      />

      <Modal
        open={openModOperatingDateTime}
        style={styles.modal}
        // size="large"
        onClose={handleCloseModOperatingDateTime}
      >
        <ModSelectOperatingDateTime
          onEvent={props.onEvent}
          setProp={props.setProp}
          runSequence={props.runSequence}
          ORRequestSequence={props.ORRequestSequence}
          OperatingDateTimeSequence={props.OperatingDateTimeSequence}
          anesthesia={anesthesiologist}
          card={CARD_SELECT_DATE_TIME_OPERATION}
          doctor={doctor}
          encounterDatetime={props.selectedOrOrder?.encounterDatetime}
          encounterType={props.selectedOrOrder?.encounterType}
          estimateTimeText={props.estimateTimeText}
          isTargetOperatingDetail={selectedOperatingDetail === props.selectedOperatingDetail}
          opdEncounterExpire={opdEncounterExpire}
          operatingRoom={room}
          room={room}
          selectOperatingDSBChange={props.selectOperatingDSBChange}
          masterOptions={props.masterOptions}
          onCancel={handleOnCancelSelectOperatingTime}
          onClose={handleCloseModOperatingDateTime}
          onPostpone={props.onPostpone}
          onUpdated={props.onUpdated}
          languageUX={props.languageUX}
        />
      </Modal>

      <ModInfo
        btnText={intl.formatMessage({ id: "ปิด" })}
        buttonColor="red"
        open={openModDetail}
        style={styles.modInfo}
        titleColor="red"
        titleName={intl.formatMessage({ id: "รายการนี้มีนัดหมายแล้ว" })}
        closeOnDimmerClick
        onApprove={handleCloseModDetail}
        onClose={handleCloseModDetail}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "-1rem",
          }}
        >
          <div style={{ fontSize: "1.15rem", lineHeight: 1.75, padding: "20px" }}>
            <div style={{ display: "flex", padding: "5px 0px" }}>
              <strong style={{ paddingRight: "5px" }}>HN: </strong>
              <span>{props.selectedAppointment?.patient_hn}</span>
              <strong style={{ padding: "0px 5px 0px 15px" }}>
                {intl.formatMessage({ id: "ชื่อ-สกุล:" })}
              </strong>
              <span>
                {`${
                  props.selectedAppointment?.patient_pre_name
                    ? `${props.selectedAppointment.patient_pre_name} `
                    : ""
                }${props.selectedAppointment?.patient_first_name} ${
                  props.selectedAppointment?.patient_last_name
                }`}
              </span>
            </div>
            <div style={{ display: "flex", padding: "5px 0px" }}>
              <strong style={{ paddingRight: "5px" }}>
                {intl.formatMessage({ id: "ห้องผ่าตัด:" })}
              </strong>
              <span>{`${props.selectedOrOrder?.operating_room_no} (${props.selectedOrOrder?.primary_doctor_name})`}</span>
            </div>
            <div style={{ display: "flex", padding: "5px 0px" }}>
              <strong style={{ paddingRight: "5px" }}>
                {intl.formatMessage({ id: "วัน เวลานัดหมาย:" })}
              </strong>
              <span>{props.selectedAppointment?.estimated_at}</span>
            </div>
          </div>
        </div>
      </ModInfo>

      <ModInfo
        btnText={intl.formatMessage({ id: "ปิด" })}
        buttonColor="yellow"
        open={!!selectedDoctorSchedule}
        style={styles.modInfo}
        titleColor="yellow"
        titleName={intl.formatMessage({ id: "ข้อมูลตารางออกตรวจแพทย์" })}
        closeOnDimmerClick
        onApprove={handleCloseModDoctorSchedule}
        onClose={handleCloseModDoctorSchedule}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "-1rem",
          }}
        >
          <div
            style={{
              fontSize: "1.15rem",
              lineHeight: 1.75,
              padding: "20px",
              textAlign: "left",
            }}
          >
            <div style={{ display: "flex", padding: "5px 0px" }}>
              <strong style={{ paddingRight: "5px", width: "9rem" }}>
                {intl.formatMessage({ id: "แพทย์" })}
              </strong>
              <span>{selectedDoctorSchedule?.doctor_name}</span>
            </div>
            <div style={{ display: "flex", padding: "5px 0px" }}>
              <strong style={{ paddingRight: "5px", width: "9rem" }}>
                {intl.formatMessage({ id: "วันที่" })}
              </strong>
              <span>
                {moment(selectedDoctorSchedule?.start_datetime)
                  .clone()
                  .locale("th")
                  .format("DD MMMM")}{" "}
                {+moment(selectedDoctorSchedule?.start_datetime).format("YYYY") + 543}
              </span>
              <strong style={{ padding: "0 2rem 0 3rem" }}>
                {intl.formatMessage({ id: "เวลา" })}
              </strong>
              <span>{moment(selectedDoctorSchedule?.start_datetime).format("HH:mm")}</span>
              <strong style={{ padding: "0 2rem" }}>{intl.formatMessage({ id: "ถึง" })}</strong>
              <span>{moment(selectedDoctorSchedule?.end_datetime).format("HH:mm")}</span>
            </div>
            <div style={{ display: "flex", padding: "5px 0px" }}>
              <strong style={{ paddingRight: "5px", width: "9rem" }}>
                {intl.formatMessage({ id: "แผนก" })}
              </strong>
              <span>{selectedDoctorSchedule?.division_name}</span>
            </div>
            <div style={{ display: "flex", padding: "5px 0px" }}>
              <strong style={{ paddingRight: "5px", width: "9rem" }}>
                {intl.formatMessage({ id: "จำนวนผู้ป่วย" })}
              </strong>
              <span>{`${selectedDoctorSchedule?.patient_count} คน`}</span>
            </div>
          </div>
        </div>
      </ModInfo>

      <ModInfo
        btnText={intl.formatMessage({ id: "ปิด" })}
        buttonColor="red"
        open={!!modError}
        size="mini"
        titleColor="red"
        titleName={intl.formatMessage({ id: "แจ้งเตือน !" })}
        closeOnDimmerClick
        onApprove={handleCloseModWarning}
        onClose={handleCloseModWarning}
      >
        <div
          style={{
            margin: "1.75rem 0rem 0.75rem",
          }}
        >
          {modError}
        </div>
      </ModInfo>
    </div>
  );
};

/* ====================================================== */
/*                    FilterableLabel;                    */
/* ====================================================== */
type FilterableLabelProps = {
  additionalInfo?: React.ReactNode;
  backgroundColor: string;
  iconProps?: Omit<IconProps, "name" | "onClick">;
  isFiltered?: boolean;
  text: string;
  onFilterToggle?: (value: boolean) => void;
};

const FilterableLabel = (props: FilterableLabelProps) => {
  const handleToggle = useCallback(() => {
    props.onFilterToggle?.(!props.isFiltered);
  }, [props.isFiltered]);

  return (
    <div style={{ alignItems: "center", display: "flex", marginBottom: "5px" }}>
      <div
        style={{
          backgroundColor: props.backgroundColor,
          borderRadius: "2px",
          minWidth: "max-content",
          padding: "2px 4px",
        }}
      >
        {props.text}
      </div>
      {props.onFilterToggle && (
        <Icon
          name={props.isFiltered ? "eye" : "low vision"}
          style={styles.icon}
          onClick={handleToggle}
          {...props.iconProps}
        />
      )}
      {props.additionalInfo}
    </div>
  );
};

CardSelectDateTimeOperation.displayName = "CardSelectDateTimeOperation";

export default React.memo(CardSelectDateTimeOperation);
