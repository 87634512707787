import React, { useState } from "react";
import { Button, Form, Input, Modal, Segment } from "semantic-ui-react";
import { useIntl } from "react-intl";

// types
type ORCancelDischargeComponentProps = {
  onEvent: (e: any) => any;
  // data
  open: boolean;
  selectedOrOrder?: Record<string, any>;
  // callback
  onClose: () => any;
};

const ORCancelDischargeComponent = (props: ORCancelDischargeComponentProps) => {
  const intl = useIntl();
  const [payload, setPayload] = useState<any>({ cancel_reason: "" });

  const closeModORCancelDischarge = () => {
    setPayload({ cancel_reason: "" });
    props.onClose();
  };

  const handleChangeORDischarge = (_event: any, data: any) => {
    setPayload({ ...payload, [data.name]: data.value });
  };

  const handleORCancelDischarge = () => {
    props.onEvent({
      message: "ORCancelDischarge",
      params: {
        order: props.selectedOrOrder,
        cancel_reason: payload.cancel_reason,
      },
    });
    closeModORCancelDischarge();
  };

  return (
    <>
      <Modal
        size="mini"
        closeIcon
        open={props.open}
        onClose={closeModORCancelDischarge}
      >
        {/* @ts-ignore */}
        <Segment inverted className="blue" style={{ margin: "0px" }}>
          ยกเลิก Discharge
        </Segment>

        <Modal.Content>
          {/* @ts-ignore */}
          <Form>
            <Form.Field width={16}>
              <label>{intl.formatMessage({ id: "เหตุผลในการยกเลิก" })}</label>
            </Form.Field>

            <Form.Field width={16}>
              <Input
                name="cancel_reason"
                value={payload.cancel_reason || ""}
                onChange={handleChangeORDischarge}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={handleORCancelDischarge}>
            ใช่
          </Button>
          <Button color="red" onClick={closeModORCancelDischarge}>
            ไม่
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default React.memo(ORCancelDischargeComponent);
