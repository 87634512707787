import React, { useEffect } from "react";
// UI Common
import DateTextBox from "react-lib/apps/common/DateTextBox";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SearchBox from "react-lib/appcon/common/SearchBox";
// UI ANE
import CardAnesDoctorFeeListUX from "./CardAnesDoctorFeeListUX";
import { useIntl } from "react-intl";

const CARD_KEY: string = "CardAnesDoctorFeeList";

type CardAnesDoctorFeeListProps = {
  // function
  onEvent: any;
  setProp: any;

  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;

  // seq
  runSequence?: any;
  AnesDoctorFeeListSequence?: any;

  // SearchBox
  searchedItemList?: any;
};

const CardAnesDoctorFeeList = (props: CardAnesDoctorFeeListProps) => {
  const intl = useIntl();
  useEffect(() => {
    props.runSequence({ sequence: "AnesDoctorFeeList", restart: true });
  }, []);

  // filter
  const handleSelectedEmployee = (item: any) => {
    props.runSequence({
      sequence: "AnesDoctorFeeList",
      action: "SET_FILTER",
      data: { selectedEmployee: item, employee: item?.id || null },
    });
  };

  const handleSelectedDoctorFeeRule = (item: any) => {
    props.runSequence({
      sequence: "AnesDoctorFeeList",
      action: "SET_FILTER",
      data: { selectedDoctorFeeRule: item, doctor_fee_rule: item?.id || null },
    });
  };

  const handleChangeFilter = (_event: any, data: any) => {
    let value: any = data.value;
    if (data.type === "checkbox") {
      value = data.checked;
    }
    props.runSequence({
      sequence: "AnesDoctorFeeList",
      action: "SET_FILTER",
      data: { [data.name]: value },
    });
  };

  // button
  const handleSearch = () => {
    props.runSequence({
      sequence: "AnesDoctorFeeList",
      action: "FETCH",
      cardKey: CARD_KEY,
    });
  };

  const handlePrint = () => {
    props.runSequence({
      sequence: "AnesDoctorFeeList",
      action: "PRINT",
      cardKey: CARD_KEY,
    });
  };

  const handleClear = () => {
    props.runSequence({
      sequence: "AnesDoctorFeeList",
      action: "CLEAR_FILTER",
    });
  };

  return (
    <>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_KEY}`, null);
          props.setProp(`successMessage.${CARD_KEY}`, null);
        }}
        error={props.errorMessage?.[CARD_KEY]}
        success={props.successMessage?.[CARD_KEY]}
        languageUX={props.languageUX}
      />

      <CardAnesDoctorFeeListUX
        // function
        onChangeFilter={handleChangeFilter}
        onClear={handleClear}
        // seq
        AnesDoctorFeeListSequence={props.AnesDoctorFeeListSequence}
        // component
        doctorSearchBox={
          <SearchBox
            onEvent={props.onEvent}
            searchedItemList={props.searchedItemList}
            selectedItem={
              props.AnesDoctorFeeListSequence?.anesFilter?.selectedEmployee ||
              null
            }
            setSelectedItem={handleSelectedEmployee}
            type="Employee"
            toDisplay={(item: any) => item.name_code}
            disabled={
              !props.AnesDoctorFeeListSequence?.anesFilter?.checkedEmployee
            }
            label=""
            style={{ width: "100%" }}
            fluid={true}
          />
        }
        doctorFeeRuleSearchBox={
          <SearchBox
            onEvent={props.onEvent}
            searchedItemList={props.searchedItemList}
            selectedItem={
              props.AnesDoctorFeeListSequence?.anesFilter
                ?.selectedDoctorFeeRule || null
            }
            setSelectedItem={handleSelectedDoctorFeeRule}
            type="DoctorFeeRule"
            toDisplay={(item: any) => item.name}
            disabled={
              !props.AnesDoctorFeeListSequence?.anesFilter?.checkedDrFeeRule
            }
            label=""
            style={{ width: "100%" }}
            fluid={true}
          />
        }
        startDateTextBox={
          <DateTextBox
            value={
              props.AnesDoctorFeeListSequence?.anesFilter?.start_perform_date
            }
            disabled={!props.AnesDoctorFeeListSequence?.anesFilter?.checkedDate}
            onChange={(date: string) => {
              handleChangeFilter(null, {
                name: "start_perform_date",
                value: date,
              });
            }}
          />
        }
        endDateTextBox={
          <DateTextBox
            value={
              props.AnesDoctorFeeListSequence?.anesFilter?.end_perform_date
            }
            disabled={!props.AnesDoctorFeeListSequence?.anesFilter?.checkedDate}
            onChange={(date: string) => {
              handleChangeFilter(null, {
                name: "end_perform_date",
                value: date,
              });
            }}
          />
        }
        buttonSearch={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSearch}
            // data
            paramKey={`${CARD_KEY}_FETCH`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_FETCH`]}
            // config
            color="blue"
            size="small"
            title={intl.formatMessage({ id: "ค้นหา" })}
            style={{ minWidth: "max-content", margin: 0 }}
          />
        }
        buttonPrint={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handlePrint}
            // data
            paramKey={`${CARD_KEY}_PRINT`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_PRINT`]}
            // config
            color="green"
            disabled={
              !(props.AnesDoctorFeeListSequence?.drFeeList?.items?.length > 0)
            }
            size="small"
            title={intl.formatMessage({ id: "พิมพ์รายงาน" })}
            style={{ minWidth: "max-content", margin: 0 }}
          />
        }
        languageUX={props.languageUX}
      />
    </>
  );
};

export default React.memo(CardAnesDoctorFeeList);
