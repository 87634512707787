import WasmController from "react-lib/frameworks/WasmController";

// APIs
// DEN
import TreatmentPackageListView from "issara-sdk/apis/TreatmentPackageListView_apps_DEN";
import TreatmentPackageDetailView from "issara-sdk/apis/TreatmentPackageDetailView_apps_DEN";

// Serializer
import DentalTreatmentPackageSerializerI from "issara-sdk/types/DentalTreatmentPackageSerializer_apps_DEN";

// Interface
import { State as MainState } from "../../../../../HIS/MainHISInterface";
import { SetErrorMessage, SetProperty } from "../../common/CommonInterface";

export type State = Partial<{
  // sequence
  SettingTRTOptionsSequence: Partial<{
    sequenceIndex: "Start" | "Action" | null;
    trtOptionsList: {
      items: DentalTreatmentPackageSerializer[];
      total: number;
      activePage: number;
    };
    filter: FilterType;
    trtOptionDetail: Partial<DentalTreatmentPackageSerializer> | null;
    showRequiredField: RequiredFieldType | null;
  }> | null;
}>;

export type FilterType = Partial<{
  name: string;
  divisions: number[];
}>;

type RequiredFieldKeys = keyof typeof REQUIRED_FIELDS;

type RequiredFieldType = Record<RequiredFieldKeys, string[]>;

export type DentalTreatmentPackageSerializer = {
  divisions: number[];
  estimate_cost_text: string;
} & Omit<DentalTreatmentPackageSerializerI, "divisions">;

type PickedState = Partial<Pick<MainState, "buttonLoadCheck" | "masterOptions" | "errorMessage">>;

export type PickedProps = Partial<Omit<PickedState, "masterOptions">>;

export type MasterOptionsType = Record<(typeof Masters)[number][0], OptionType[]>;

export type OptionType = {
  key: number | string;
  value: number | string;
  text: string;
};

// Sequence
type SeqState = {
  sequence: "SettingTRTOptions";
  restart?: boolean;
  clear?: boolean;
};

// Handle Action
type ActionType =
  // Search
  | { action: "SEARCH"; activePage: number }
  // Action
  | {
      action: "DELETE";
      card: string;
      trtOptionId: number;
      onSuccess?: () => any;
    }
  | { action: "SAVE"; card: string };

type SeqAct = ActionType & SeqState;
type SeqType<K> = K extends { action: string } ? Extract<SeqAct, K> : SeqState;

export type RunSequence = <K extends keyof SeqAct>(params: SeqType<Pick<SeqAct, K>>) => void;

export type SetProp = SetProperty<State & PickedState>;

type CustomExtract<T, U> = T extends object ? (U extends Partial<T> ? T : never) : never;

type Params<A extends ActionType["action"]> = CustomExtract<ActionType, { action: A }>;

export const StateInitial: State = {
  // sequence
  SettingTRTOptionsSequence: {
    sequenceIndex: null,
  },
};

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division?: number;
  device?: number;
};

export const DataInitial = {};

export const REQUIRED_FIELDS = {
  name: "ชื่อทางเลือกการรักษา",
  estimate_cost_text: "ค่าใช้จ่ายโดยประมาณ",
  benefit: "ข้อดี",
  drawback: "ข้อเสีย",
};

const Masters = [["division", {}]] as const;

export const CARD_SETTING_TRT_OPTIONS = "CardSettingTRTOptions";

type Optional<T> = {
  [K in keyof T]: `${typeof CARD_SETTING_TRT_OPTIONS}_${T[K] & string}`;
};

export const ACTIONS = {
  SEARCH: "SEARCH",
  SAVE: "SAVE",
  DELETE: "DELETE",
} as const;

export const BTN_ACTS = Object.fromEntries(
  Object.keys(ACTIONS).map((key) => [key, `${CARD_SETTING_TRT_OPTIONS}_${key}`])
) as Optional<typeof ACTIONS>;

export const LIST_LIMIT = 20;

type Handler<P = any, R = any> = (
  controller: WasmController<State & PickedState, Event, Data>,
  params: P
) => R;

/* ------------------------------------------------------ */

/*                          START                         */

/* ------------------------------------------------------ */
export const GetMaster: Handler<SeqState> = async (controller, params) => {
  const state = controller.getState();

  controller.setState(
    {
      SettingTRTOptionsSequence: {
        ...state.SettingTRTOptionsSequence,
        sequenceIndex: "Action",
      },
    },
    () => {
      Action(controller, {
        action: ACTIONS.SEARCH,
        activePage: 1,
      });
    }
  );
};

/* ------------------------------------------------------ */

/*                      Handle Action                     */

/* ------------------------------------------------------ */
export const Action: Handler<ActionType> = async (controller, params) => {
  const actionHandlers = {
    [ACTIONS.SEARCH]: HandleSearch,
    [ACTIONS.DELETE]: HandleDelete,
    [ACTIONS.SAVE]: HandleSave,
  };

  const action = params.action;

  if (action && actionHandlers[action]) {
    return actionHandlers[action](controller, params as any);
  }
};

const HandleSearch: Handler<Params<"SEARCH">> = async (controller, params) => {
  let state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SEARCH]: "LOADING" },
  });

  const result = await GetTreatmentOptionsList(controller, params);

  state = controller.getState();

  controller.setState({
    SettingTRTOptionsSequence: {
      ...state.SettingTRTOptionsSequence,
      trtOptionsList: {
        items: result?.items || [],
        total: result?.total || 0,
        activePage: params.activePage,
      },
    },
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SEARCH]: "SUCCESS" },
  });
};

const HandleDelete: Handler<Params<"DELETE">> = async (controller, params) => {
  let state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.DELETE]: "LOADING" },
  });

  const [_, error] = await TreatmentPackageDetailView.delete({
    apiToken: controller.apiToken,
    pk: params.trtOptionId,
  });

  if (error) {
    SetErrorMessage(controller, { ...params, error });
  } else {
    const activePage = state.SettingTRTOptionsSequence?.trtOptionsList?.activePage || 1;

    controller.setState(
      {
        SettingTRTOptionsSequence: {
          ...state.SettingTRTOptionsSequence,
          trtOptionDetail: null,
        },
        buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.DELETE]: null },
      },
      () => Action(controller, { action: "SEARCH", activePage })
    );

    params.onSuccess?.();
  }
};

const HandleSave: Handler<Params<"SAVE">> = async (controller, params) => {
  let state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SAVE]: "LOADING" },
  });

  const detail = { ...(state.SettingTRTOptionsSequence?.trtOptionDetail || {}) };

  const isError = CheckRequireField(controller, params);

  if (isError) {
    return;
  }

  const api = detail.id ? TreatmentPackageDetailView.update : TreatmentPackageListView.create;

  const [_, error] = await api({
    apiToken: controller.apiToken,
    data: detail,
    pk: detail.id,
  });

  if (error) {
    controller.setState(
      {
        SettingTRTOptionsSequence: {
          ...state.SettingTRTOptionsSequence,
          showRequiredField: error,
        },
      },
      () => SetErrorMessage(controller, { ...params, error: error })
    );
  } else {
    const activePage = state.SettingTRTOptionsSequence?.trtOptionsList?.activePage || 1;

    controller.setState(
      {
        SettingTRTOptionsSequence: {
          ...state.SettingTRTOptionsSequence,
          trtOptionDetail: null,
          showRequiredField: null,
        },
        buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SAVE]: null },
      },
      () => Action(controller, { action: "SEARCH", activePage })
    );
  }
};

/* ------------------------------------------------------ */

/*                          APIs                          */

/* ------------------------------------------------------ */
const GetTreatmentOptionsList: Handler<
  { activePage: number },
  Promise<{ items: DentalTreatmentPackageSerializer[]; total: number }>
> = async (controller, params) => {
  const state = controller.getState();

  const seq = state.SettingTRTOptionsSequence || {};
  const filter = seq.filter || {};
  const offset = (params.activePage - 1) * LIST_LIMIT;

  const [result] = await TreatmentPackageListView.list({
    apiToken: controller.apiToken,
    params: {
      name__icontains: filter.name,
      divisions: filter.divisions,
      offset,
      limit: LIST_LIMIT,
    },
  });

  let items: any[] = result?.items || [];

  return { items, total: result?.total || 0 };
};

/* ------------------------------------------------------ */

/*                          Utils                         */

/* ------------------------------------------------------ */
const CheckRequireField: Handler = (controller, params) => {
  const state = controller.getState();

  const detail = { ...(state.SettingTRTOptionsSequence?.trtOptionDetail || {}) };

  const errKeys = Object.keys(REQUIRED_FIELDS) as RequiredFieldKeys[];
  const errMsg = Object.fromEntries(
    errKeys.flatMap((key) => (!detail[key] ? [[key, ["This field may not be blank."]]] : []))
  ) as RequiredFieldType;

  if (Object.keys(errMsg).length) {
    controller.setState(
      {
        SettingTRTOptionsSequence: {
          ...state.SettingTRTOptionsSequence,
          showRequiredField: errMsg,
        },
      },
      () => SetErrorMessage(controller, { ...params, error: errMsg })
    );

    return true;
  }

  return false;
};
