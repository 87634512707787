import React, {
  useMemo,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import { Button, Form, FormField, FormGroup, Icon, Label } from "semantic-ui-react";

import moment from "moment";

// UX
import ModSelectOperatingDatetimeUX from "./ModSelectOperatingDateTimeUX";
import TimeComboBox from "react-lib/apps/common/TimeComboBox";

// Common
import DateTextBox from "react-lib/apps/common/DateTextBox";

import { PREFIX_CODE_REGEX } from "./sequence/OperatingDateTime";

// Utils
import { adToBe, formatDate } from "react-lib/utils/dateUtils";
import { useIntl } from "react-intl";

const ModSelectOperatingDateTime = (props: any) => {
  const intl = useIntl();
  const [loadingRoomOption, setLoadingRoomOption] = useState<boolean>(true);

  const prevEstimateEndDateRef = useRef();
  const prevStartTimeRef = useRef();

  // Memo Effect
  const showCloseButton = useMemo(() => {
    return (
      (!props.isTargetOperatingDetail ||
        !props.OperatingDateTimeSequence?.editable) &&
      props.OperatingDateTimeSequence?.id
    );
  }, [
    props.isTargetOperatingDetail,
    props.OperatingDateTimeSequence?.id,
    props.OperatingDateTimeSequence?.editable,
  ]);

  // Callback Effect
  const checkCurrentTimeOnward = useCallback(() => {
    const { startTime = "", selectedDateBackendFormat = "" } =
      props.OperatingDateTimeSequence || {};

    const startDatetime = `${selectedDateBackendFormat} ${startTime}`;
    const currentDate = moment().format("YYYY-MM-DD");

    if (startTime) {
      const selectDate = moment(startDatetime).format("YYYY-MM-DD");

      return (
        selectDate > currentDate ||
        (selectDate === currentDate &&
          moment(startDatetime).diff(moment(), "minutes") >= 0)
      );
    } else {
      return false;
    }
  }, [
    props.OperatingDateTimeSequence?.selectedDateBackendFormat,
    props.OperatingDateTimeSequence?.startTime,
  ]);

  const checkUnavailableRoom = useCallback(
    (room: number, startDate: string, endDate: string) => {
      const dsbOperatingRoom: any[] =
        props.OperatingDateTimeSequence?.dsbOperatingRoom || [];

      const findBlock = dsbOperatingRoom.find((item) => {
        const startDatetime = moment(item.start_datetime).format(
          "YYYY-MM-DD HH:mm"
        );
        const endDatetime = moment(item.end_datetime).format(
          "YYYY-MM-DD HH:mm"
        );

        return (
          item.room === room &&
          ((startDate >= startDatetime && startDate < endDatetime) ||
            (endDate > startDatetime && endDate <= endDatetime))
        );
      });

      return (
        !!findBlock && props.OperatingDateTimeSequence?.id !== findBlock?.id
      );
    },
    [
      props.OperatingDateTimeSequence?.dsbOperatingRoom,
      props.OperatingDateTimeSequence?.id,
    ]
  );

  // Effect
  // เพื่อแสดงห้องผ่าตัด ว่าง ไม่ว่าง
  useEffect(() => {
    if (
      props.OperatingDateTimeSequence?.estimateEndDate &&
      props.OperatingDateTimeSequence?.selectedDateBackendFormat &&
      !showCloseButton
    ) {
      const estimateEndDate = props.OperatingDateTimeSequence.estimateEndDate;
      // เพื่อไม่ต้อง get ข้อมูลเดิมซ้ำ
      if (prevEstimateEndDateRef.current === estimateEndDate) {
        return;
      }

      prevEstimateEndDateRef.current = estimateEndDate;

      setLoadingRoomOption(true);

      props.runSequence({
        sequence: "OperatingDateTime",
        action: "getDSBRoom",
        startDate: props.OperatingDateTimeSequence.selectedDateBackendFormat,
        endDate: estimateEndDate,
        callback: () => setLoadingRoomOption(false),
      });
    } else if (showCloseButton) {
      setLoadingRoomOption(false);
    }
  }, [
    props.OperatingDateTimeSequence?.estimateEndDate,
    props.OperatingDateTimeSequence?.selectedDateBackendFormat,
    showCloseButton,
  ]);

  useEffect(() => {
    const isCurrentTimeOnward = checkCurrentTimeOnward();
    const startTime = props.OperatingDateTimeSequence?.startTime;

    if (
      !!startTime &&
      !isCurrentTimeOnward &&
      !!prevStartTimeRef.current &&
      prevStartTimeRef.current !== startTime
    ) {
      props.setProp(
        `errorMessage.${props.card}`,
        "ไม่สามารถเลือกเวลาก่อนหน้าเวลาปัจจุบันได้"
      );
    }

    prevStartTimeRef.current = startTime;
  }, [
    props.OperatingDateTimeSequence?.selectedDateBackendFormat,
    props.OperatingDateTimeSequence?.startTime,
  ]);

  // เพื่อ default operating room
  useEffect(() => {
    const operatingRoom = props.OperatingDateTimeSequence?.operatingRoom;

    if (!loadingRoomOption && !operatingRoom && !showCloseButton) {
      const {
        endTime = "",
        startTime = "",
        selectedDateBackendFormat = "",
        estimateEndDate = "",
      } = props.OperatingDateTimeSequence || {};

      const startDatetime = `${selectedDateBackendFormat} ${startTime}`;
      const endDatetime = `${estimateEndDate} ${endTime}`;

      const unavailable = checkUnavailableRoom(
        props.room[0],
        startDatetime,
        endDatetime
      );

      if (!unavailable && props.room.length === 1) {
        props.setProp("OperatingDateTimeSequence.operatingRoom", props.room[0]);
      }
    }
  }, [props.OperatingDateTimeSequence, loadingRoomOption, showCloseButton]);

  // Memo
  const operatingRoomOptions = useMemo(() => {
    const options: any[] = props.masterOptions.operatingRoom || [];

    const {
      endTime = "",
      startTime = "",
      selectedDateBackendFormat = "",
      estimateEndDate = "",
    } = props.OperatingDateTimeSequence || {};

    return !showCloseButton
      ? options
          .filter((option) => props.room.includes(option.value))
          .map((option) => {
            const startDatetime = `${selectedDateBackendFormat} ${startTime}`;
            const endDatetime = `${estimateEndDate} ${endTime}`;

            const unavailable = checkUnavailableRoom(
              option.value,
              startDatetime,
              endDatetime
            );

            return {
              ...option,
              text: unavailable ? `${option.text} (ไม่ว่าง)` : option.text,
              content: unavailable ? (
                <div>
                  {option.text}
                  <span style={{ color: "red", marginLeft: "1rem" }}>
                    (ไม่ว่าง)
                  </span>
                </div>
              ) : (
                option.text
              ),
              disabled: unavailable,
            };
          })
      : options;
  }, [
    props.masterOptions.operatingRoom,
    props.OperatingDateTimeSequence,
    props.room,
    showCloseButton,
    loadingRoomOption,
  ]);

  const statusColor = useMemo(() => {
    const detail = props.OperatingDateTimeSequence;

    return detail.order_status === 1 && props.selectOperatingDSBChange
      ? "#4CDCFC4D" // light blue
      : detail.order_status === 1
      ? "#4CDCFC" // blue
      : !detail.is_doctor_schedule
      ? "#64E282" // green
      : "";
  }, [props.selectOperatingDSBChange, props.OperatingDateTimeSequence]);

  const surgeryTeams = useMemo(() => {
    const teams = props.OperatingDateTimeSequence?.surgery_teams || [];

    return teams as { [key: string]: any; chief_surgeon: string | null }[];
  }, [props.OperatingDateTimeSequence?.surgery_teams]);

  const divisionOption = useMemo(() => {
    return (props.OperatingDateTimeSequence?.divisionOption || [])?.map((items:any) => {
      return {
        key: items.id,
        value: items.id,
        text: items.name
      }
    })
  }, [props.OperatingDateTimeSequence?.divisionOption])

  const handleSetValue = (name: string, value: any) => {
    props.runSequence({
      sequence: "OperatingDateTime",
      action: "setValue",
      name,
      value,
    });
  };

  const handleCreateDSB = () => {
    const isCurrentTimeOnward = checkCurrentTimeOnward();

    if (!isCurrentTimeOnward) {
      return props.setProp(
        `errorMessage.${props.card}`,
        "ไม่สามารถเลือกเวลาก่อนหน้าเวลาปัจจุบันได้"
      );
    }

    if (props.encounterType === "OPD" && props.encounterDatetime) {
      const data = props.OperatingDateTimeSequence || {};
      const endDate = moment(props.encounterDatetime).add(
        props.opdEncounterExpire,
        "hours"
      );

      const estimateDate = moment(
        `${data.selectedDateBackendFormat} ${data.endTime}`
      ).add(Number(data.allMinutes), "minutes");

      const diff = estimateDate.diff(endDate, "seconds");

      if (diff > 0) {
        return props.setProp(
          `errorMessage.${props.card}`,
          "ไม่สามารถจองวันเวลานัดหมายผ่าตัดได้ เนื่องจากช่วงเวลาสิ้นสุด เกินเวลาที่ Encounter เดิมหมดอายุ"
        );
      }
    }

    props.runSequence({
      sequence: "OperatingDateTime",
      action: "createDSB",
      card: props.card,
      callback: handleUpdated,
    });
  };

  const handleUpdated = (data: any) => {
    props.onClose?.();
    props.onUpdated?.(data);
  };

  const handleChangeOperatingRoom = (e: any, data: any) => {
    const unavailable = operatingRoomOptions.find(
      (option) => option.value === data.value
    )?.disabled;

    const value = unavailable ? null : data.value;

    handleSetValue("operatingRoom", value);
  };

  const handleChangeDate = (date: string) => {
    if (!date || date.length !== 10) {
      return;
    }

    handleSetValue("admitDate", date);
  };

  const handleChangeValue = async (e: any, v: any) => {
    let value = typeof v.checked === "boolean" ? v.checked : v.value;

    props.setProp(`OperatingDateTimeSequence.${v.name}`, value);
  }

  console.log("ModSelectOperatingDateTime: ", props, surgeryTeams);

  return (
    <div
      style={{
        backgroundColor: "white",
        boxShadow: "1px 3px 3px 0 rgba(0,0,0,.2), 1px 3px 15px 2px rgba(0,0,0,.2)",
      }}
    >
      <ModSelectOperatingDatetimeUX
        // data
        doctorOptions={props.masterOptions.doctor || []}
        anesthesiaOptions={props.masterOptions.doctor || []}
        operatingRoomOptions={operatingRoomOptions}
        disabledDoctor={true}
        disabledAnesthesia={true}
        disabledOperatingRoom={showCloseButton || loadingRoomOption}
        centeredButton={showCloseButton}
        // disabledOperatingRoom={true}
        patientName={props.OperatingDateTimeSequence?.patientName || ""}
        patientHN={props.OperatingDateTimeSequence?.patientHN || ""}
        selectedDate={props.OperatingDateTimeSequence?.selectedDate || ""}
        doctor={props.OperatingDateTimeSequence?.doctor}
        anesthesia={props.OperatingDateTimeSequence?.anesthesia}
        operatingRoom={props.OperatingDateTimeSequence?.operatingRoom || ""}
        admitCase={props.OperatingDateTimeSequence?.admitCase}
        stayDays={props.OperatingDateTimeSequence?.admitStayDate}
        stayDaysICU={props.OperatingDateTimeSequence?.admitICUStay}
        checkedRequestICU={props.OperatingDateTimeSequence?.admitRequestICU}
        anesthesiaMethod={props.OperatingDateTimeSequence?.anesthesiaMethod}
        procedureType={props.OperatingDateTimeSequence?.procedureType}
        isAdmit={props.OperatingDateTimeSequence?.admitOrder}
        division={props.OperatingDateTimeSequence?.division}
        loadingRoomOption={loadingRoomOption}
        disabledOn={
          props.OperatingDateTimeSequence?.id &&
          props.OperatingDateTimeSequence?.editable &&
          !props.selectOperatingDSBChange
        }
        // function
        onChangeDoctor={(e: any, data: any) => {
          handleSetValue("doctor", data.value);
        }}
        onChangeAnesthesia={(e: any, data: any) => {
          handleSetValue("anesthesia", data.value);
        }}
        onChangeOperatingRoom={handleChangeOperatingRoom}
        onChangeStayDays={(e: any, data: any) => {
          handleSetValue("admitStayDate", data.value);
        }}
        onChangeCheckedRequestICU={(e: any, data: any) => {
          handleSetValue("admitRequestICU", data.checked);
        }}
        onChangeStayDaysICU={(e: any, data: any) => {
          handleSetValue("admitICUStay", data.value);
        }}
        // component
        treatmentList={
          <Form style={{ display: "flex", flexDirection: "column" }}>
            {surgeryTeams.map((team, index, self) => {
              const treatment: Record<string, any>[] =
                team.post_operating_order_item?.treatment || [];
              const chiefSurgeonName = team.chief_surgeon?.replace(PREFIX_CODE_REGEX, "").trim();
              const otherTreatment: string = team.post_operating_order_item?.other_treatment || "";

              return (
                <FormGroup
                  key={team.id}
                  inline
                  style={{
                    alignItems: "baseline",
                    marginBottom: self.length - 1 === index ? 0 : "",
                  }}
                >
                  <FormField style={{ fontWeight: "bold", minWidth: "max-content" }} inline>
                    <Icon
                      color="yellow"
                      name="star"
                      size="small"
                      style={{ margin: "0 0.25rem", opacity: team.is_main ? 1 : 0 }}
                    />
                    ทีม {index + 1}
                  </FormField>{" "}
                  <FormField style={{ minWidth: "14.5rem" }}>{chiefSurgeonName}</FormField>
                  <FormField style={{ lineHeight: 1.35 }}>
                    <strong style={{ margin: 0 }}>
                      <span>{treatment.map((acc) => acc.name_code as string).join(", ")}</span>
                    </strong>
                    <span> {otherTreatment.split("\n").join(" ")}</span>
                  </FormField>
                </FormGroup>
              );
            })}
          </Form>
        }
        timeSpent={
          props.estimateTimeText ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <span
                style={{
                  padding: "5px 20px",
                  backgroundColor: "#b5e4ef",
                  fontSize: "16px",
                  borderRadius: "10px",
                }}
              >
                <Icon name="info circle" color="blue" size="big" style={{ paddingRight: "15px" }} />
                {props.estimateTimeText}
              </span>
            </div>
          ) : (
            <div />
          )
        }
        startTime={
          <TimeComboBox
            style={{ minWidth: "150px" }}
            disabled={
              props.OperatingDateTimeSequence?.id &&
              props.OperatingDateTimeSequence?.editable &&
              !props.selectOperatingDSBChange
            }
            defaultValue={props.OperatingDateTimeSequence?.startTime || ""}
            onTextChange={(time: string) => {
              handleSetValue("startTime", time);
            }}
          />
        }
        endTime={
          <TimeComboBox
            style={{ minWidth: "150px" }}
            defaultValue={props.OperatingDateTimeSequence?.endTime || ""}
            disabled={true}
            onTextChange={(time: string) => {
              handleSetValue("endTime", time);
            }}
          />
        }
        admitDate={
          <DateTextBox
            value={props.OperatingDateTimeSequence?.admitDate || ""}
            minDate={
              props.OperatingDateTimeSequence?.admitCase === "Admit หลังผ่าตัด"
                ? adToBe(props.OperatingDateTimeSequence?.selectedDateBackendFormat, "YYYY-MM-DD")
                : formatDate(moment())
            }
            maxDate={
              props.OperatingDateTimeSequence?.admitCase === "Admit ก่อนผ่าตัด"
                ? adToBe(props.OperatingDateTimeSequence?.selectedDateBackendFormat, "YYYY-MM-DD")
                : ""
            }
            disabled={
              props.OperatingDateTimeSequence?.id &&
              props.OperatingDateTimeSequence?.editable &&
              !props.selectOperatingDSBChange
            }
            style={{ width: "150px" }}
            inputStyle={{ width: "150px" }}
            inputFluid={true}
            onChange={handleChangeDate}
          />
        }
        admitTime={
          <TimeComboBox
            disabled={
              props.OperatingDateTimeSequence?.id &&
              props.OperatingDateTimeSequence?.editable &&
              !props.selectOperatingDSBChange
            }
            defaultValue={props.OperatingDateTimeSequence?.admitTime || ""}
            onTextChange={(time: string) => {
              handleSetValue("admitTime", time);
            }}
          />
        }
        buttonList={
          showCloseButton ? (
            <Button
              content={intl.formatMessage({ id: "ปิด" })}
              color="blue"
              style={{ width: "75px" }}
              onClick={() => {
                props.runSequence({
                  sequence: "OperatingDateTime",
                  action: "close",
                  card: props.card,
                  callback: props.onClose,
                });
              }}
            />
          ) : !props.OperatingDateTimeSequence?.id ? (
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <Button
                content={intl.formatMessage({ id: "ตกลง" })}
                color="green"
                disabled={!checkCurrentTimeOnward() || loadingRoomOption}
                style={{ minWidth: "max-content" }}
                onClick={handleCreateDSB}
              />
              <Button
                content={intl.formatMessage({ id: "ยกเลิก" })}
                color="red"
                style={{ minWidth: "max-content" }}
                onClick={() => {
                  props.runSequence({
                    sequence: "OperatingDateTime",
                    action: "close",
                    card: props.card,
                    callback: props.onClose,
                  });
                }}
              />
            </div>
          ) : props.OperatingDateTimeSequence?.id && props.selectOperatingDSBChange ? (
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <Button
                content={intl.formatMessage({ id: "ยืนยันการเปลี่ยนแปลง" })}
                color="yellow"
                style={{ minWidth: "max-content" }}
                onClick={() => {
                  props.runSequence({
                    sequence: "OperatingDateTime",
                    action: "updateDSB",
                    card: props.card,
                    callback: handleUpdated,
                  });
                }}
              />
              <Button content={intl.formatMessage({ id: "ยกเลิก" })} color="red" onClick={props.onCancel} />
            </div>
          ) : props.OperatingDateTimeSequence?.id && props.OperatingDateTimeSequence?.editable ? (
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <Button
                content={intl.formatMessage({ id: "เลื่อนนัดหมายไม่ระบุวัน" })}
                color="orange"
                style={{ minWidth: "max-content" }}
                onClick={() => {
                  props.onPostpone(props.onClose);
                }}
              />
              <Button
                content={intl.formatMessage({ id: "เปลี่ยนแปลงนัดหมาย" })}
                color="yellow"
                style={{ minWidth: "max-content" }}
                onClick={() => {
                  props.runSequence({
                    sequence: "OperatingDateTime",
                    action: "changeAppointment",
                    card: props.card,
                    callback: props.onClose,
                  });
                }}
              />
              <Button
                content={intl.formatMessage({ id: "ยกเลิก" })}
                color="red"
                style={{ minWidth: "max-content" }}
                onClick={() => {
                  props.runSequence({
                    sequence: "OperatingDateTime",
                    action: "close",
                    card: props.card,
                    callback: props.onClose,
                  });
                }}
              />
            </div>
          ) : null
        }
        statusName={
          !!props.OperatingDateTimeSequence?.order_status_label && (
            <Label
              style={{
                backgroundColor: statusColor,
                borderRadius: "3px",
                margin: "0.5rem",
              }}
            >
              {props.OperatingDateTimeSequence?.order_status_label}
            </Label>
          )
        }
        onChangeValue={handleChangeValue}
        divisionOption={divisionOption}
        case={
          props?.OperatingDateTimeSequence?.case?.is_ipd_case ? "IPD" :
          props?.OperatingDateTimeSequence?.case?.is_opd_case ? "OPD" :
          props?.OperatingDateTimeSequence?.case?.is_one_day_case ? "One Day" : ""
        }
        languageUX={props.languageUX}
      />
    </div>
  );
};

export default React.memo(ModSelectOperatingDateTime);
