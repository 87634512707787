import React, { MutableRefObject, SyntheticEvent, useEffect, useRef, useState } from "react";

import { Button, ButtonProps } from "semantic-ui-react";

// ui ORM
import CardSurgicalSafetyChecklistSignin from "./CardSurgicalSafetyChecklistSignin";
import CardSurgicalSafetyChecklistTimeout from "./CardSurgicalSafetyChecklistTimeout";
import CardSurgicalSafetyChecklistSignout from "./CardSurgicalSafetyChecklistSignout";
import CardSurgicalSafetyChecklistHistory from "./CardSurgicalSafetyChecklistHistory";

import "./CardORHistory.scss";
import { useIntl } from "react-intl";

type CardSurgicalSafetyChecklistProps = {
  // function
  onEvent: any;
  setProp: any;
  buttonLoadCheck?: any;
  errorMessage?: any;
  // select OR
  selectedOrOrder?: any;

  // options
  masterOptions?: any;
  masterData?: any;

  // seq
  runSequence?: any;
  PerioperativeNursingSequence?: any;

  phvReadOnly?: boolean;
  DJANGO?: any;
};

const CardSurgicalSafetyChecklistInitial: CardSurgicalSafetyChecklistProps = {
  // funtion
  onEvent: () => null,
  setProp: () => null,
  buttonLoadCheck: null,
};

const CardSurgicalSafetyChecklist: React.FC<
  CardSurgicalSafetyChecklistProps
> = (props: any) => {
  const intl = useIntl();
  const [tab, setTab] = useState<"ASSESSMENT" | "HISTORY">("ASSESSMENT");

  const sscRef = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    if (!props.phvReadOnly) {
      props.runSequence({
        sequence: "PerioperativeNursing",
        action: "FETCH_FORM_DATA_SURGICAL_SAFETY",
      });
    }
  }, []);

  useEffect(() => {
    if (props.phvReadOnly) {
      setTab("HISTORY");
    }
  }, []);

  // #useEffect(() => {
  //   if (sscRef.current && props.phvReadOnly) {
  //     const children = Array.from(
  //       sscRef.current.querySelectorAll(
  //         ".dropdown, .input, .search, input, textarea, .ui.small.button"
  //       )
  //     ) as HTMLElement[];

  //     console.log("children: ", children);
  //     children.forEach((e) => {
  //       e.setAttribute("tabindex", -1);
  //     });
  //   }
  // }, [sscRef?.current, props.phvReadOnly]);

  useEffect(() => {
    console.log(
      "props.DJANGO?.user?.role_types",
      props.DJANGO?.user?.role_types
    );
    const isDoctor = props.DJANGO?.user?.role_types?.includes("DOCTOR");

    if (isDoctor) {
      const children = Array.from(
        sscRef.current.querySelectorAll(
          ".dropdown, .input, .search, input, textarea, .ui.small.button"
        )
      ) as HTMLElement[];
      const childrenBtn = Array.from(
        sscRef.current.querySelectorAll(".ui.small.button")
      ) as HTMLElement[];
      const childrenCheckBtn = Array.from(
        sscRef.current.querySelectorAll(".ui.checkbox")
      ) as HTMLElement[];
      console.log("children: ", children);
      children.forEach((e) => {
        e.setAttribute("tabindex", "-1");
        e.classList.add("block-mouse");
      });
      childrenBtn.forEach((e) => {
        e.setAttribute("tabindex", "-1");
        e.setAttribute("disabled", "-1");
      });
      childrenCheckBtn.forEach((e) => {
        e.setAttribute("tabindex", "-1");
        e.setAttribute("disabled", "-1");
        e.classList.add("block-mouse");
      });
    }
  }, []);

  const handleChangeTab = (e: SyntheticEvent, data: ButtonProps) => {
    setTab(data.name);
  };

  return (
    <div>
      <div
        style={{
          alignItems: "center",
          backgroundColor: "rgba(198, 235, 243, 0.2)",
          display: "flex",
          margin: "15px 15px -5px",
          padding: "10px 20px 10px",
        }}
      >
        <div style={{ fontSize: "1.35rem", fontWeight: "bold", marginRight: "2rem" }}>
          Surgical Safety Checklist
        </div>
        {!props.phvReadOnly && (
          <Button
            color={tab === "ASSESSMENT" ? "blue" : undefined}
            name="ASSESSMENT"
            size="small"
            style={{ marginRight: "1rem" }}
            onClick={handleChangeTab}
          >{intl.formatMessage({ id: "แบบฟอร์มการประเมิน" })}</Button>
        )}
        <Button
          color={tab === "HISTORY" ? "blue" : undefined}
          name="HISTORY"
          size="small"
          onClick={handleChangeTab}
        >{intl.formatMessage({ id: "ประวัติการบันทึก" })}</Button>
      </div>

      {tab === "ASSESSMENT" && (
        <div ref={sscRef} id="CardSurgicalSafetyChecklist">
          <CardSurgicalSafetyChecklistSignin
            onEvent={props.onEvent}
            setProp={props.setProp}
            // seq
            runSequence={props.runSequence}
            PerioperativeNursingSequence={props.PerioperativeNursingSequence}
            // CommonInterface
            buttonLoadCheck={props.buttonLoadCheck}
            selectedOrOrder={props.selectedOrOrder}
            errorMessage={props.errorMessage}
            // options
            masterOptions={props.masterOptions}
            languageUX={props.languageUX}
          />

          <CardSurgicalSafetyChecklistTimeout
            onEvent={props.onEvent}
            setProp={props.setProp}
            // seq
            runSequence={props.runSequence}
            PerioperativeNursingSequence={props.PerioperativeNursingSequence}
            // CommonInterface
            buttonLoadCheck={props.buttonLoadCheck}
            selectedOrOrder={props.selectedOrOrder}
            errorMessage={props.errorMessage}
            // options
            masterOptions={props.masterOptions}
            languageUX={props.languageUX}
          />

          <CardSurgicalSafetyChecklistSignout
            onEvent={props.onEvent}
            setProp={props.setProp}
            // seq
            runSequence={props.runSequence}
            PerioperativeNursingSequence={props.PerioperativeNursingSequence}
            // CommonInterface
            buttonLoadCheck={props.buttonLoadCheck}
            selectedOrOrder={props.selectedOrOrder}
            errorMessage={props.errorMessage}
            // options
            masterOptions={props.masterOptions}
            languageUX={props.languageUX}
          />
        </div>
      )}

      {tab === "HISTORY" && (
        <CardSurgicalSafetyChecklistHistory
          runSequence={props.runSequence}
          PerioperativeNursingSequence={props.PerioperativeNursingSequence}
          django={props.phvReadOnly ? null : props.DJANGO}
          languageUX={props.languageUX}
        />
      )}
    </div>
  );
};

CardSurgicalSafetyChecklist.defaultProps = CardSurgicalSafetyChecklistInitial;

export default React.memo(CardSurgicalSafetyChecklist);
