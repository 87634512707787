import WasmController from 'react-lib/frameworks/WasmController';

export type State = 
  {
  }

export const StateInitial: State = 
  {
  }

export type Event = 
  { message: "getOperatingOrder", params: any }
  |  { message: "postDeliverOperatingAppointment", params: any }
  |  { message: "getOrLocation", params: any }
  |  { message: "getOperativeImage", params: any }
  |  { message: "getOperatingProcedureSummary", params: any }
  |  { message: "printOperativeNote", params: any }
  |  { message: "HandleGetORPostOperativeTokenization", params: any }

export type Data = 
  {
    division?: number,
  }

export const DataInitial = 
  {
  }

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any 

export const getOperatingOrder: Handler = async (controller, {}) => {
//function (encounterId) { return __awaiter(_this, void 0, void 0, function () {
//            var params, order, orderError, ormService, _a, error, response;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        params = {
//                            active: "true",
//                            encounter: encounterId,
//                        };
//                        order = {};
//                        orderError = null;
//                        ormService = new ORMService_1.default();
//                        return [4 /*yield*/, utils_1.to(ormService.getOperatingOrder(params))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1];
//                        orderError = error;
//                        if (response) {
//                            order = response;
//                        }
//                        return [2 /*return*/, [order, orderError]];
//                }
//            });
//        }); }
//function (params) {
//        return this.client
//            .get(apis_1.ORM.OPERATING_ORDER, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ORM/operating-order/
}

export const postDeliverOperatingAppointment: Handler = async (controller, {}) => {
//function (_a) {
//            var encounterId = _a.encounterId, operatingOrderList = _a.operatingOrderList;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, data, deliver, deliverError, ormService, _b, error, response;
//                return __generator(this, function (_c) {
//                    switch (_c.label) {
//                        case 0:
//                            params = {};
//                            data = {};
//                            deliver = [];
//                            deliverError = null;
//                            ormService = new ORMService_1.default();
//                            if (encounterId) {
//                                data.encounter = encounterId;
//                            }
//                            if (operatingOrderList) {
//                                data.operating_order_list = operatingOrderList;
//                            }
//                            return [4 /*yield*/, utils_1.to(ormService.postDeliverOperatingAppointment({ params: params, data: data }))];
//                        case 1:
//                            _b = _c.sent(), error = _b[0], response = _b[1];
//                            if (response) {
//                                deliver = response.items;
//                            }
//                            else {
//                                deliverError = error;
//                            }
//                            return [2 /*return*/, [deliver, deliverError]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var params = _a.params, data = _a.data;
//        return this.client
//            .post(apis_1.ORM.DELIVER_OPERATING_APPOINTMENT, data, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ORM/deliver-operating-appointment/
}

export const getOrLocation: Handler = async (controller, {}) => {
//function () { return __awaiter(_this, void 0, void 0, function () {
//            var params, ormService, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        params = {};
//                        ormService = new ORMService_1.default();
//                        return [4 /*yield*/, utils_1.to(ormService.getOrLocation(params))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (params) {
//        return this.client
//            .get(apis_1.ORM.OPERATING_LOCATION, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ORM/operating-location/
}

export const getOperativeImage: Handler = async (controller, {}) => {
//function (operativeNoteId) { return __awaiter(_this, void 0, void 0, function () {
//            var ormService, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        ormService = new ORMService_1.default();
//                        return [4 /*yield*/, utils_1.to(ormService.getOperativeImage(operativeNoteId))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (operativeNoteId) {
//        var url = apis_1.ORM.OPERAITVE_IMAGE_BY_OPERATIVE_NOTE({
//            operative_note_id: operativeNoteId,
//        });
//        return this.client
//            .get(url)
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/ORM/operative-image/operative-note/' +
//((__t = (operative_note_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const getOperatingProcedureSummary: Handler = async (controller, {}) => {
//function (params) { return __awaiter(_this, void 0, void 0, function () {
//            var ormService, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        ormService = new ORMService_1.default();
//                        return [4 /*yield*/, utils_1.to(ormService.getOperatingProcedureSummary(params))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (params) {
//        return this.client
//            .get(apis_1.ORM.OPERATING_PROCEDURE_SUMMARY, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ORM/operating-procedure-summary/
}

export const printOperativeNote: Handler = async (controller, {}) => {
//function (teamId) { return __awaiter(_this, void 0, void 0, function () {
//            var ormService, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        ormService = new ORMService_1.default();
//                        return [4 /*yield*/, utils_1.to(ormService.printOperativeNote(teamId))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (teamId) {
//        var url = apis_1.ORM.TEAM_PRINT_OPERATIVE_NOTE({
//            team_id: teamId,
//        });
//        return this.client
//            .get(url)
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/ORM/team/' +
//((__t = (team_id)) == null ? '' : __t) +
//'/print/';
//
//}
//return __p
//}
}
