import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button
} from 'semantic-ui-react'

const ModReMedicationUX = (props: any) => {
    return(
      <div>
        <div
          style={{position: "relative"}}>
          
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(45, 1fr)","gridTemplateColumns":"repeat(50, 1fr)"}}>
            <div
              style={{ gridRow: "2/6", gridColumn: "2/50"}}>
              <div
                style={{fontWeight: "bold", fontSize: "20px", paddingBottom: "10px"}}>
                สั่งยาจากประวัติเดิมของผู้ป่วย
              </div>
              <hr>
              </hr>
            </div>
            <div
              style={{ gridRow: "7/10", gridColumn: "2/50",display:"flex", alignItems: "center"}}>
              <div
                style={{width: "25%",  padding: "0px 5px"}}>
                {props.doctorSearchBox}
              </div>
              <div
                style={{width: "25%",padding: "0px 5px"}}>
                {props.divisionSearchBox}
              </div>
              <Button
                color="blue"
                onClick={props.onSearchReMED}>
                ค้นหา
              </Button>
            </div>
            <div
              style={{ gridRow: "11/39", gridColumn: "2/50"}}>
              <div
                style={{fontWeight: "bold", fontSize: "20px", paddingBottom: "10px"}}>
                Past Medication
              </div>
              <Table
                data={props.remedData}
                getTrProps={props.remedTrProps}
                headers=",ชื่อยา,วิธีใช้,จำนวน,หน่วย"
                keys="selected,name,prescription_label,quantity,unit"
                minRows="8"
                showPagination={false}
                widths="50,,,100,100">
              </Table>
            </div>
            <div
              style={{ gridRow: "40/44", gridColumn: "2/12" }}>
              <Button
                color="blue"
                onClick={props.onClickAddToOrder}>
                ADD TO ORDER
              </Button>
            </div>
            <div
              style={{ gridRow: "40/44", gridColumn: "42/50", textAlign:"right"}}>
              <Button
                color="grey"
                onClick={props.onClickCancel}>
                CANCEL
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
}

export default ModReMedicationUX

export const screenPropsDefault = {}

/* Date Time : Wed Jun 15 2022 14:11:05 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "label": "divgrid",
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(45, 1fr)\",\"gridTemplateColumns\":\"repeat(50, 1fr)\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/6\", gridColumn: \"2/50\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/10\", gridColumn: \"2/50\",display:\"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/39\", gridColumn: \"2/50\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"40/44\", gridColumn: \"2/12\" }"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"40/44\", gridColumn: \"42/50\", textAlign:\"right\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "สั่งยาจากประวัติเดิมของผู้ป่วย"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"20px\", paddingBottom: \"10px\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "hr",
      "parent": 3,
      "props": {
      },
      "seq": 9,
      "void": true
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "code",
          "value": "props.doctorSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"25%\",  padding: \"0px 5px\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "code",
          "value": "props.divisionSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"25%\",padding: \"0px 5px\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 13,
      "name": "Table",
      "parent": 5,
      "props": {
        "data": {
          "type": "code",
          "value": "props.remedData"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.remedTrProps"
        },
        "headers": {
          "type": "value",
          "value": ",ชื่อยา,วิธีใช้,จำนวน,หน่วย"
        },
        "keys": {
          "type": "value",
          "value": "selected,name,prescription_label,quantity,unit"
        },
        "minRows": {
          "type": "value",
          "value": "8"
        },
        "pageSize": {
          "type": "value",
          "value": ""
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "subRowsKey": {
          "type": "value",
          "value": ""
        },
        "widths": {
          "type": "value",
          "value": "50,,,100,100"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Button",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "ADD TO ORDER"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickAddToOrder"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "Button",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "CANCEL"
        },
        "color": {
          "type": "value",
          "value": "grey"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickCancel"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "Past Medication"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"20px\", paddingBottom: \"10px\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "Button",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSearchReMED"
        }
      },
      "seq": 17,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 70,
  "isMounted": false,
  "memo": false,
  "name": "ModReMedicationUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 65
}

*********************************************************************************** */
