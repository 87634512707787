import React, {
  ReactElement,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { Button, ButtonProps, Popup } from "semantic-ui-react";

import moment from "moment";

import LoadingIcon from "../HCU/LoadingIcon";

import CardSurgicalSafetyChecklistHistoryUX from "./CardSurgicalSafetyChecklistHistoryUX";

import { beToAd, formatDatetime } from "react-lib/utils/dateUtils";
import { useIntl } from "react-intl";

import { splitStringNewLine } from "../common/CommonInterface";

// Types
type CardSurgicalSafetyChecklistHistoryProps = {
  runSequence: (e: any) => void;
  PerioperativeNursingSequence?: Record<string, any> | null;
  django?: Record<string, any>;
  languageUX?: string;
};

type StepType = {
  signIn: number;
  signOut: number;
  timeOut: number;
};

type LoadingType = Record<CardKeyType, number>;

type FilterType = Record<CardKeyType, Partial<{ endDate: string; startDate: string }>>;

type LogListType = Partial<Record<CardKeyType, Record<string, any>[]>>;

type CardKeyType = keyof typeof CARD_KEY;

// Constants
const SIGN_IN_CHECKLIST = [
  {
    key: "patient_identify",
    name: "Patient Identify",
  },
  {
    key: "site_marked",
    name: "Site Marked",
  },
  {
    key: "know_allergy",
    name: "Known allergy",
  },
  {
    key: "anesthesia_check",
    name: "Anesthesia safety/p check complete",
  },
  {
    key: "airway_risk",
    name: "Difficult airway/aspiration risk",
  },
  {
    key: "pulse_oximeter",
    name: "Pulse Oximeter on patient and functioning",
  },
  {
    key: "risk",
    name: "Risk of > 500 ml blood loss (7 ml/kg in children)",
  },
  {
    key: "special_instrument",
    name: "Sterile instrument and implant check completed",
  },
  {
    key: "blood",
    name: "Blood component",
  },
  {
    key: "blood_available",
    name: "Blood component available",
  },
];

const TIME_OUT_CHECKLIST = [
  {
    key: "team_introduce",
    name: "All team member have introduced themselves",
  },
  {
    key: "patient_identify",
    name: "Patient Identify/Site/Procedure confirmed",
  },
  {
    key: "antibiotic",
    name: "Antibiotic prophylaxis",
  },
  {
    key: "essential_imaging",
    name: "Essential Imaging",
  },
  {
    key: "critical_events",
    name: "Anticipated Critical Events",
  },
  {
    key: "has_sterility",
    name: "<font color='#0147a3'>To Nursing Team :</font><br/>Has sterility (including indicator results) been confirmed",
  },
  {
    key: "equipment_issue",
    name: "Are there equipment issue or any concerns",
  },
  {
    key: "is_critical_step",
    name: "<font color='#0147a3'>To Surgeon :</font><br/>What are Critical or non-routine step?",
  },
  {
    key: "is_long",
    name: "How long will the case take",
  },
  {
    key: "is_blood_loss",
    name: "What is the anticipate blood loss",
  },
  {
    key: "is_specific_concern",
    name: "Are there any patient-specific concerns",
  },
  {
    key: "incision",
    name: "<strong>Time Out</strong>",
  },
];

const SIGN_OUT_CHECKLIST = [
  {
    key: "is_skin_condition",
    name: "Skin Condition",
  },
  {
    key: "estimate_blood_loss",
    name: "Estimate Blood Loss",
  },
  {
    key: "is_blood_administered",
    name: "Blood Administered",
  },
  {
    key: "is_drain",
    name: "Drain",
  },
  {
    key: "is_incision_closure",
    name: "Incision Closure",
  },
  {
    key: "no_specimen",
    name: "Specimen",
  },
  {
    key: "name_procedure",
    name: "The name of the procedure",
  },
  {
    key: "completion",
    name: "Completion of instrument, sponge and needle counts",
  },
  {
    key: "specimen_labelling",
    name: "Specimen labelling",
  },
  {
    key: "equipment_problems",
    name: "Whether there are any equipment problems to be addressed",
  },
  {
    key: "key_concerns",
    name: "<font color='#0147a3'>To Surgeon, Anesthesiologists and Nurse:</font><br/>The key concerns for recovery and manage of this patient",
  },
  {
    key: "remark",
    name: "Remark",
  },
  {
    key: "closure",
    name: "<strong>Closure</strong>",
  },
];

const CARD_KEY = {
  signIn: "CardSurgicalSafetyChecklistSignIn",
  signOut: "CardAfterClosureAndWoundCare",
  timeOut: "CardSurgicalSafetyChecklistTimeOut",
} as const;

const LOG_KEYS = {
  signIn: "surgicalSafetyChecklistSigninLogs",
  signOut: "surgicalSafetyChecklistSigninOutLogs",
  timeOut: "surgicalSafetyChecklistTimeOutLogs",
} as const;

const MAX_VIEW = 3;

const DEFAULT_STEP_INDEX: StepType = {
  signIn: 0,
  signOut: 0,
  timeOut: 0,
};

const CardSurgicalSafetyChecklistHistory = (props: CardSurgicalSafetyChecklistHistoryProps) => {
  const intl = useIntl();
  const [stepIndex, setStepIndex] = useState<StepType>(DEFAULT_STEP_INDEX);
  const [loading, setLoading] = useState<Partial<LoadingType>>({});
  const [filter, setFilter] = useState<Partial<FilterType>>({});
  const [logList, setLogList] = useState<LogListType>({});

  // Callback Effect
  const filterLogsByDateRange = useCallback(
    (logs: Record<string, any>[], filterValue: FilterType[keyof FilterType]) =>
      logs.filter((item) => {
        const itemDate = moment(item.edited_utc);

        let isWithinRange = true;

        if (filterValue.startDate) {
          const startDate = beToAd(filterValue.startDate)?.startOf("day");

          isWithinRange = !!startDate?.isSameOrBefore(itemDate);
        }

        if (filterValue.endDate) {
          const endDate = beToAd(filterValue.endDate)?.endOf("day");

          return isWithinRange && endDate?.isSameOrAfter(itemDate);
        }

        return isWithinRange;
      }),
    []
  );

  // Effect
  useEffect(() => {
    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "FETCH_FORM_DATA_SURGICAL_SAFETY_HISTORY",
    });
  }, []);

  useEffect(() => {
    const checklistTypes = ["signIn", "timeOut", "signOut"] as const;

    const stepIndexUpdates = { ...DEFAULT_STEP_INDEX };
    const logListUpdates: LogListType = {};

    for (const type of checklistTypes) {
      const logs: Record<string, any>[] =
        props.PerioperativeNursingSequence?.[LOG_KEYS[type]] || [];

      const logsMapIndex = logs.map((item, index) => ({ ...item, index }));
      const filteredLogs = filterLogsByDateRange(logsMapIndex, filter[type] || {});

      logListUpdates[type] = filteredLogs;
      stepIndexUpdates[type] = Math.max(0, filteredLogs.length - MAX_VIEW);
    }

    setLogList(logListUpdates);
    setStepIndex(stepIndexUpdates);
  }, [
    props.PerioperativeNursingSequence?.surgicalSafetyChecklistSigninLogs,
    props.PerioperativeNursingSequence?.surgicalSafetyChecklistSigninOutLogs,
    props.PerioperativeNursingSequence?.surgicalSafetyChecklistTimeOutLogs,
  ]);

  // Callback
  const handleStepChange = useCallback(
    (key: keyof StepType) => (e: SyntheticEvent, data: ButtonProps) => {
      const currentIndex = stepIndex[key];
      const index = data.name === "left" ? currentIndex - 1 : currentIndex + 1;

      setStepIndex({ ...stepIndex, [key]: index });
    },
    [stepIndex]
  );

  const handleDelete = useCallback((data: Record<string, any>) => {
    const type = data.type as keyof StepType;

    setLoading((prev) => ({ ...prev, [type]: data.index }));

    props.runSequence({
      sequence: "PerioperativeNursing",
      action: "DELETE_SURGICAL_SAFETY",
      data,
      formCode: CARD_KEY[type],
      formVersion: "0.1",
      onSuccess: () => {
        setLoading((prev) => ({ ...prev, [type]: null }));
      },
    });
  }, []);

  const handleChangeFilter = useCallback(
    (type: keyof FilterType) => (data: Record<string, any>) => {
      setFilter((prev) => ({ ...prev, [type]: { ...prev[type], [data.name]: data.value } }));
    },
    []
  );

  const handleSearch = useCallback(
    (type: keyof FilterType) => () => {
      const logs: Record<string, any>[] =
        props.PerioperativeNursingSequence?.[LOG_KEYS[type]] || [];

      const logsMapIndex = logs.map((item, index) => ({ ...item, index }));
      const filterValue = filter[type];

      const filtered = filterLogsByDateRange(logsMapIndex, filterValue || {});

      setLogList((prev) => ({ ...prev, [type]: filtered }));
      setStepIndex((prev) => ({ ...prev, [type]: Math.max(0, filtered.length - MAX_VIEW) }));
    },
    [
      filter,
      props.PerioperativeNursingSequence?.surgicalSafetyChecklistSigninLogs,
      props.PerioperativeNursingSequence?.surgicalSafetyChecklistSigninOutLogs,
      props.PerioperativeNursingSequence?.surgicalSafetyChecklistTimeOutLogs,
    ]
  );

  const generateChecklistSummary = useCallback(
    (params: {
      checklist: Record<string, any>[];
      formatValue: (item: Record<string, any>, acc: Record<string, any>) => ReactElement | string;
      logs: Record<string, any>[];
      type: keyof StepType;
      onDelete: (item: Record<string, any>) => () => void;
    }) => {
      const { checklist, formatValue, logs, type, onDelete } = params;

      const formattedLogs = [
        ...logs,
        ...Array.from({ length: logs.length < MAX_VIEW ? MAX_VIEW - logs.length : 0 }, () => ({})),
      ] as Record<string, any>[];

      const logHeaders = formattedLogs.map((log) => ({
        created_utc: log.created_utc,
        edit_user: log.edit_user,
        index: log.index,
        header: log.edit_user_name
          ? `${log.edit_user_name} ${formatDatetime(log.edited_utc, true)}`
          : "",
      }));

      const currentIndex = stepIndex[type];
      const visibleLogHeaders = logHeaders.slice(currentIndex, MAX_VIEW + currentIndex);

      return {
        columns: [
          {
            accessor: "checklist",
            Header: "Checklist",
          },
          ...visibleLogHeaders.map((item) => ({
            accessor: `result_${item.index}`,
            Cell: (state: any) => {
              const header = document.querySelector(
                ".card-surgical-safety-checklist-history .rt-thead .rt-th"
              );
              const result: HTMLElement | string = state.original[`result_${item.index}`];

              if (typeof result === "string" && result.includes("Yes")) {
                const [value, ...res] = (result as string).split(" ");

                const descriptionFull = res.join(" ");
                const width = Number(header?.clientWidth) - 80;

                const lines = splitStringNewLine(descriptionFull, {
                  fontSize: 14,
                  width,
                });
                const description = splitStringNewLine(descriptionFull, {
                  fontSize: 14,
                  max: 1,
                  width,
                })[0]?.trim();

                return (
                  <div style={{ display: "grid", gridTemplateColumns: "auto 1fr" }}>
                    <div>
                      {value}
                      {"\u00A0\u00A0"}
                    </div>
                    <div style={{ alignItems: "flex-end", display: "flex" }}>
                      <span>{description}</span>
                      {lines.length > 1 && (
                        <Popup
                          position="bottom left"
                          style={{ wordBreak: "break-word" }}
                          content={
                            <div>
                              {descriptionFull.split("\n").map((text) => (
                                <div key={text}>{text}</div>
                              ))}
                            </div>
                          }
                          trigger={
                            <Button
                              color="blue"
                              icon="info"
                              size="mini"
                              circular
                              style={{
                                padding: "0.35rem",
                                transform: "scale(0.75)",
                              }}
                            />
                          }
                        />
                      )}
                    </div>
                  </div>
                );
              }

              return result as HTMLElement;
            },
            Header: (
              <div
                key={item.header}
                style={{
                  alignItems: "baseline",
                  display: "grid",
                  gridTemplateColumns: "auto 1fr auto",
                }}
              >
                <span />
                <div>{item.header}</div>
                {item.index !== undefined && item.edit_user === props.django?.user?.id ? (
                  <LoadingIcon
                    color="red"
                    loading={item.index !== undefined && loading[type] === item.index}
                    name="close"
                    onClick={onDelete({ ...item, type })}
                  />
                ) : (
                  <span />
                )}
              </div>
            ),
          })),
        ],
        data: checklist.map((item) => {
          const logResults = formattedLogs.map((acc) => {
            const value = acc.edit_user_name ? formatValue(item, acc) : "";

            return [`result_${acc.index}`, value] as [string, string];
          });

          return {
            checklist: <div dangerouslySetInnerHTML={{ __html: item.name }} />,
            ...Object.fromEntries(logResults),
          };
        }),
        disabledNext: currentIndex + MAX_VIEW === formattedLogs.length,
        disabledPrevious: currentIndex === 0,
      };
    },
    [loading, stepIndex]
  );

  // Memo
  const signIn = useMemo(() => {
    const logs = logList.signIn || [];

    const formatValue = (item: Record<string, any>, acc: Record<string, any>) => {
      const value = acc[item.key];

      if (item.key === "site_marked") {
        return value ? "Applicable" : "Not applicable";
      } else if (item.key === "blood") {
        return value ? `Yes  ${acc.bloodRemark}` : "No";
      } else if (item.key === "know_allergy") {
        return value ? `Yes  ${acc.allergyRemark}` : "No";
      }

      return value ? "Yes" : "No";
    };

    return generateChecklistSummary({
      checklist: SIGN_IN_CHECKLIST,
      formatValue,
      logs,
      type: "signIn",
      onDelete: (data) => () => {
        handleDelete(data);
      },
    });
  }, [generateChecklistSummary, logList.signIn]);

  const timeOut = useMemo(() => {
    const logs = logList.timeOut || [];

    const formatValue = (item: Record<string, any>, acc: Record<string, any>) => {
      const value = acc[item.key];

      switch (item.key) {
        case "antibiotic": {
          return value ? `Applicable  ${acc.antibioticRemark}` : "Not applicable";
        }
        case "is_critical_step": {
          return value ? `\nYes  ${acc.critical_step}` : "No";
        }
        case "is_long": {
          return value ? `Yes  ${acc.long}` : "No";
        }
        case "is_blood_loss": {
          return value ? `Yes  ${acc.blood_loss}` : "No";
        }
        case "is_specific_concern": {
          return value ? `Yes  ${acc.specific_concern}` : "No";
        }
        case "has_sterility": {
          return `\n${value ? "Yes" : "No"}`;
        }
        case "incision": {
          return (
            <strong>
              {acc.incision_date} [{acc.incision_time}]
            </strong>
          );
        }

        default: {
          return value ? "Yes" : "No";
        }
      }
    };

    return generateChecklistSummary({
      checklist: TIME_OUT_CHECKLIST,
      formatValue,
      logs,
      type: "timeOut",
      onDelete: (data) => () => {
        handleDelete(data);
      },
    });
  }, [generateChecklistSummary, logList.timeOut]);

  const signOut = useMemo(() => {
    const logs = logList.signOut || [];

    const formatValue = (item: Record<string, any>, acc: Record<string, any>) => {
      const value = acc[item.key];

      switch (item.key) {
        case "estimate_blood_loss": {
          return value as string;
        }
        case "remark": {
          return value as string;
        }
        case "is_skin_condition": {
          return value ? `Yes  ${acc.skin_condition || ""}` : "No";
        }
        case "is_blood_administered": {
          return value ? `Yes  ${acc.blood_administered || ""}` : "No";
        }
        case "is_drain": {
          const drain: string[] = acc.drain || [];

          return value ? `Yes  ${drain.join(", ")}` : "No";
        }
        case "is_incision_closure": {
          const incisionClosure: string[] = acc.incision_closure || [];

          return value ? `Yes  ${incisionClosure.join(", ")}` : "No";
        }
        case "key_concerns": {
          const respiration = acc.respiration ? "Respiration" : "";
          const cardiovaacular = acc.cardiovaacular ? "Cardiovascular" : "";
          const metabolism = acc.metabolism ? "Metabolism" : "";
          const neuroSign = acc.neuro_sign ? "Neuro sign" : "";
          const other = acc.other ? "Other" : "";

          const result = [respiration, cardiovaacular, metabolism, neuroSign, other]
            .filter(Boolean)
            .join(", ");

          return `\n${value ? "Yes" : "No"}  ${result}`;
        }
        case "closure": {
          return (
            <strong>
              {acc.closure_date} [{acc.closure_time}]
            </strong>
          );
        }
        case "no_specimen": {
          const specimenItems: Record<string, any>[] = acc.specimen_items || [];

          const formattedSpecimen = specimenItems.map((specimen) => {
            const tissue = specimen.is_tissue ? `Tissue: ${specimen.tissue || ""}` : "";
            const culture = specimen.is_culture ? `Culture: ${specimen.culture || ""}` : "";
            const frozen = specimen.is_frozen ? "Frozen" : "";
            const cytoSection = specimen.is_cyto_section ? "Cyto section" : "";
            const estrogenRec = specimen.is_estrogen_rec ? "Estrogen rec" : "";
            const other = specimen.is_other_specimen ? ` Other: ${specimen.other_specimen}` : "";

            return [tissue, culture, frozen, cytoSection, estrogenRec, other]
              .filter(Boolean)
              .join(", ");
          });

          return value ? (
            <div style={{ display: "grid", gridTemplateColumns: "auto 1fr" }}>
              <div>Yes </div>
              <div>{formattedSpecimen.join("\n")}</div>
            </div>
          ) : (
            "No"
          );
        }

        default: {
          return value ? "Yes" : "No";
        }
      }
    };

    return generateChecklistSummary({
      checklist: SIGN_OUT_CHECKLIST,
      formatValue,
      logs,
      type: "signOut",
      onDelete: (data) => () => {
        handleDelete(data);
      },
    });
  }, [generateChecklistSummary, logList.signOut]);

  return (
    <div className="card-surgical-safety-checklist-history">
      <CardSurgicalSafetyChecklistHistoryUX
        columns={signIn.columns}
        data={signIn.data}
        disabledNext={signIn.disabledNext}
        disabledPrevious={signIn.disabledPrevious}
        filter={filter.signIn}
        languageUX={props.languageUX}
        title="Sign-In"
        onChangeFilter={handleChangeFilter("signIn")}
        onSearch={handleSearch("signIn")}
        onStepChange={handleStepChange("signIn")}
      />
      <CardSurgicalSafetyChecklistHistoryUX
        columns={timeOut.columns}
        data={timeOut.data}
        disabledNext={timeOut.disabledNext}
        disabledPrevious={timeOut.disabledPrevious}
        filter={filter.timeOut}
        languageUX={props.languageUX}
        title="Time Out"
        onChangeFilter={handleChangeFilter("timeOut")}
        onSearch={handleSearch("timeOut")}
        onStepChange={handleStepChange("timeOut")}
      />
      <CardSurgicalSafetyChecklistHistoryUX
        columns={signOut.columns}
        data={signOut.data}
        disabledNext={signOut.disabledNext}
        disabledPrevious={signOut.disabledPrevious}
        filter={filter.signOut}
        languageUX={props.languageUX}
        title="Sign Out"
        onChangeFilter={handleChangeFilter("signOut")}
        onSearch={handleSearch("signOut")}
        onStepChange={handleStepChange("signOut")}
      />
    </div>
  );
};

CardSurgicalSafetyChecklistHistory.displayName = "CardSurgicalSafetyChecklistHistory";

export default React.memo(CardSurgicalSafetyChecklistHistory);
