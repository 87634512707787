import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button
} from 'semantic-ui-react'

const ModDrugAllVerifyUX = (props: any) => {
    return(
      <div>
        <div
          style={{backgroundColor: "#d80000", color: "white", fontSize: "20px", padding: "10px", fontWeight: "bold"}}>
          แจ้งเตือน
        </div>
        <div
          style={{padding: "20px"}}>
          
          <div
            style={{display: props.showADR? "": "none"}}>
            
            <div
              style={{fontSize: "18px", fontWeight: "bold", color: "#4F4F4F", padding: "10px 0px"}}>
              ตรวจพบรายการยาแพ้
            </div>
            <div>
              {props.adrDrugs}
            </div>
          </div>
          <div
            style={{display: props.showDI? "": "none"}}>
            
            <div
              style={{fontSize: "18px", fontWeight: "bold", color: "#4F4F4F", padding: "10px 0px"}}>
              ตรวจพบยาที่มีอันตรกิริยาต่อกัน
            </div>
            <div>
              {props.interactionDrugs}
            </div>
          </div>
          <div
            style={{display: props.showDIL? "": "none"}}>
            
            <div
              style={{fontSize: "18px", fontWeight: "bold", color: "#4F4F4F", padding: "10px 0px"}}>
              ตรวจพบยาที่มีผลกับแลป
            </div>
            <div>
              {props.interactionLabDrugs}
            </div>
          </div>
          <div
            style={{display: props.showDD? "": "none"}}>
            
            <div
              style={{fontSize: "18px", fontWeight: "bold", color: "#4F4F4F", padding: "10px 0px"}}>
              ตรวจพบยาที่มีผลกับโรค
            </div>
            <div>
              {props.diseaseDrugs}
            </div>
          </div>
          <div
            style={{padding: "20px", display: "flex", justifyContent: "space-evenly"}}>
            
            <Button
              color="green"
              onClick={props.onApprove}>
              ยืนยัน
            </Button>
            <Button
              color="red"
              onClick={props.onCancel}>
              ยกเลิก
            </Button>
          </div>
        </div>
      </div>
    )
}

export default ModDrugAllVerifyUX

export const screenPropsDefault = {}

/* Date Time : Thu Feb 23 2023 10:57:51 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "แจ้งเตือน"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#d80000\", color: \"white\", fontSize: \"20px\", padding: \"10px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"20px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.showADR? \"\": \"none\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.showDI? \"\": \"none\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.showDIL? \"\": \"none\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.showDD? \"\": \"none\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"20px\", display: \"flex\", justifyContent: \"space-evenly\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "ตรวจพบรายการยาแพ้"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"18px\", fontWeight: \"bold\", color: \"#4F4F4F\", padding: \"10px 0px\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "code",
          "value": "props.adrDrugs"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "ตรวจพบยาที่มีอันตรกิริยาต่อกัน"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"18px\", fontWeight: \"bold\", color: \"#4F4F4F\", padding: \"10px 0px\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "code",
          "value": "props.interactionDrugs"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "ตรวจพบยาที่มีผลกับแลป"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"18px\", fontWeight: \"bold\", color: \"#4F4F4F\", padding: \"10px 0px\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "code",
          "value": "props.interactionLabDrugs"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "ตรวจพบยาที่มีผลกับโรค"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"18px\", fontWeight: \"bold\", color: \"#4F4F4F\", padding: \"10px 0px\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "code",
          "value": "props.diseaseDrugs"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "Button",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "ยืนยัน"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onApprove"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "Button",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิก"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancel"
        }
      },
      "seq": 17,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 70,
  "isMounted": false,
  "memo": false,
  "name": "ModDrugAllVerifyUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 65
}

*********************************************************************************** */
