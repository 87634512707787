import React, { useState } from 'react';
import { Input } from "semantic-ui-react";
import EmailLoginManager from "./EmailLoginManager";
import ReCAPTCHA from "react-google-recaptcha";
import { Button } from "semantic-ui-react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom"
import Cookies from "js-cookie";
import config from "config/config";
import { useIntl } from "react-intl";

const Register = props => {
  const intl = useIntl();
  const history = useHistory()
  const reCAPTCHA_KEY = config.RECAPTCHA_KEY === undefined ?
      "6LdvmfUUAAAAAIb2DBfgd_xeN-xk4b6qq_3aR-UH" : config.RECAPTCHA_KEY;
  const manager = new EmailLoginManager();
  let [email, setEmail] = useState("");
  let [firstName, setFirstName] = useState("");
  let [lastName, setLastName] = useState("");
  let [recaptcha, setRecaptcha] = useState();
  let [message, setMessage] = useState("");
  let [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false)

  React.useEffect(() => {
    if(Cookies.get("apiToken")){
      props.onHaveApiToken()
    }
  }, [Cookies.get("apiToken")])

  const onLogin = async () => {
    // setMessage("")
    // setErrorMessage("")
    try {
      let ret = await manager.requestLogin(email)
      // setMessage(ret.detail)
    } catch (e) {
      console.log(e, "LOGIN IN REGISTER PAGE ERR")
      // setErrorMessage(JSON.stringify(e.response.data))
    }
  }

  const onCreate = async () => {
    setErrorMessage('')
    setMessage('')
    setIsLoading(true)
    try {
      let ret = await manager.register(email, recaptcha, firstName, lastName);
      setMessage(ret.detail)
      setIsLoading(false)
      onLogin()
      history.push("/RegisterEmailSuccess/")
    } catch (e) {
      let msg = JSON.stringify(e.response.data)
      setErrorMessage(msg)
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    setMessage("")
    setErrorMessage("")
  }, [])

  return (
    <div className="email-register">
      <br />
      <h2>{intl.formatMessage({ id: "ลงทะเบียนเข้าใช้งาน" })}</h2>
      <br />
      <br />
      <Input className="email-input" placeholder="Email Address" value={email} required onChange={e => setEmail(e.target.value)} />
      <br />
      <br />
      <Input className="first-name-input" placeholder="ชื่อ" value={firstName} required onChange={e => setFirstName(e.target.value)} />
      <br />
      <br />
      <Input className="last-name-input" placeholder="นามสกุล" value={lastName} required onChange={e => setLastName(e.target.value)} />
      <br />
      <br />
      <ReCAPTCHA
        sitekey={reCAPTCHA_KEY}
        size="normal"
        onChange={setRecaptcha}
        data-size="compact"
      />
      <br />
      {errorMessage && (
        <div className="err-message">
          {errorMessage}
        </div>
      )}
      <br />
      <Button className="register-button" loading={isLoading} disabled={isLoading} onClick={onCreate} content="ลงทะเบียน" />
    </div>
  )
}

Register.defaultProps = {
  onHaveApiToken: () => {},
};

Register.propTypes = {
  onHaveApiToken: PropTypes.func,
};


export default Register;
