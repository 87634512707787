import WasmController from "react-lib/frameworks/WasmController";
import NotificationMessageSettingsDetail from "issara-sdk/apis/NotificationMessageSettingsDetail_apps_PRX";
import NotificationMessageSettingsList from "issara-sdk/apis/NotificationMessageSettingsList_apps_PRX";
import MessageTemplateDetail from "issara-sdk/apis/MessageTemplateDetail_apps_PRX";
import MessageTemplateList from "issara-sdk/apis/MessageTemplateList_apps_PRX";

export type State = {
  // Seq
  SettingQueueSequence?: {
    sequenceIndex?: string | null;
    notificationQueueOrder?: {
      searchDivision?: any;
      selectDivision?: any;
      selectAllDivision?: boolean;
      patientCount?: any;
      notificationDetail?: any;
      notificationOrder?: any;
      selectNotificationID?: any;
      errorMessageQueue?: any;
      closeModal?: boolean;
    };
    messageTemplateOrder?: {
      searchDivision?: any;
      selectDivision?: any;
      detail?: any;
      header?: any;
      messageTemplateOrder?: any;
      selectMessageTemplateID?: any;
      errorMessageTemplate?: any;
      closeModal?: boolean;
    };
    appointmentReminderOrder?: {
      searchDivision?: any;
      selectDivision?: any;
      selectAllDivision?: boolean;
      confirmDay?: any;
      appointmentOrder?: any;
      incomingData?: any;
      selectAppointmentReminderID?: any;
      errorMessageAppointment?: any
      closeModal?: boolean;
    };
  } | null;
};

export const StateInitial: State = {
  SettingQueueSequence: null,
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  user?: number;
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const Start: Handler = async (controller, params) => {
  const state = controller.getState();

  // Master data
  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["division", {}],
        ["patientType", {}],
        ["applications", {}],
      ],
    },
  });

  const [
    [notiListRes, notiListErr, notiListNet],
    [messageTemplateRes, messageTemplateErr, messageTemplateNet],
    [appiontmentListRes, appiontmentListErr, appiontmentListNet],
  ] = await Promise.all([
    NotificationMessageSettingsList.list({
      apiToken: controller.apiToken,
      params: {
        setting_type: "DOCTOR_QUEUE",
        divisions: [state.selectedDivision?.id],
      },
    }),
    MessageTemplateList.list({
      apiToken: controller.apiToken,
      params: {
        divisions: [state.selectedDivision?.id],
      },
    }),
    NotificationMessageSettingsList.list({
      apiToken: controller.apiToken,
      params: {
        setting_type: "APPOINTMENT",
        divisions: [state.selectedDivision?.id],
      },
    }),
  ]);

  controller.setState({
    SettingQueueSequence: {
      ...state.SettingQueueSequence,
      sequenceIndex: "Action",
      notificationQueueOrder: {
        searchDivision: [state.selectedDivision?.id],
        notificationOrder: notiListRes?.items,
      },
      messageTemplateOrder: {
        searchDivision: [state.selectedDivision?.id],
        messageTemplateOrder: messageTemplateRes?.items,
      },
      appointmentReminderOrder: {
        searchDivision: [state.selectedDivision?.id],
        appointmentOrder: appiontmentListRes?.items,
        incomingData: [0],
      },
    },
  });
};

export const Action: Handler = async (controller, params) => {
  if (params.action === "saveNotificationMessage") {
    const state = controller.getState();

    if (
      state.SettingQueueSequence?.notificationQueueOrder?.selectNotificationID
    ) {
      const [notiDetailRes, notiDetailErr, notiDetailNet] =
        await NotificationMessageSettingsDetail.update({
          apiToken: controller.apiToken,
          pk: state.SettingQueueSequence?.notificationQueueOrder
            ?.selectNotificationID,
          data: {
            id: state.SettingQueueSequence?.notificationQueueOrder
              ?.selectNotificationID,
            setting_type: "DOCTOR_QUEUE",
            is_all_division: params.is_all_division,
            divisions: params.divisions,
            slot_capacity: params.slot_capacity,
            message_template_text: params.message_template_text,
          },
        });

      if (notiDetailRes) {
        Action(controller, {
          action: "searchNotificationMessage",
          divisions:
            state.SettingQueueSequence?.notificationQueueOrder?.searchDivision,
        });

        controller.setState({
          SettingQueueSequence: {
            ...state.SettingQueueSequence,
            notificationQueueOrder: {
              ...state.SettingQueueSequence?.notificationQueueOrder,
              closeModal: true
            }
          },
        })
      }
    } else {
      const [notiListRes, notiListErr, notiListNet] =
        await NotificationMessageSettingsList.create({
          apiToken: controller.apiToken,
          data: {
            setting_type: "DOCTOR_QUEUE",
            is_all_division: params.is_all_division,
            divisions: params.divisions,
            slot_capacity: params.slot_capacity,
            message_template_text: params.message_template_text,
          },
        });

        if (notiListErr) {
          if (notiListErr.divisions?.[0].includes("had already existed on notification message settings")) {
            const [queueListAllRes, queueListAllErr, queueListAllNet] = await GetNotificationMessageListAll(controller,{type:"DOCTOR_QUEUE"})
            const filterDuplicate = (queueListAllRes.items || []).filter((item:any) => params.divisions.includes(item.division))
    
            controller.setState({
              SettingQueueSequence: {
                ...state.SettingQueueSequence,
                notificationQueueOrder: {
                  ...state.SettingQueueSequence?.notificationQueueOrder,
                  errorMessageQueue: filterDuplicate,
                }
              },
            });
  
            return;
          }
        }

      if (notiListRes) {
        Action(controller, {
          action: "searchNotificationMessage",
          divisions:
            state.SettingQueueSequence?.notificationQueueOrder?.searchDivision,
        });

        controller.setState({
          SettingQueueSequence: {
            ...state.SettingQueueSequence,
            notificationQueueOrder: {
              ...state.SettingQueueSequence?.notificationQueueOrder,
              closeModal: true
            }
          },
        })
      }
    }
  } else if (params.action === "saveMessageTemplate") {
    const state = controller.getState();

    if (
      state.SettingQueueSequence?.messageTemplateOrder?.selectMessageTemplateID
    ) {
      const [messageTemplateRes, messageTemplateErr, messageTemplateNet] =
        await MessageTemplateDetail.update({
          apiToken: controller.apiToken,
          pk: state.SettingQueueSequence?.messageTemplateOrder
            ?.selectMessageTemplateID,
          data: {
            divisions: params.divisions,
            header: params.header,
            detail: params.detail,
          },
        });

      if (messageTemplateRes) {
        Action(controller, {
          action: "searchMessageTemplate",
          divisions:
            state.SettingQueueSequence?.messageTemplateOrder?.searchDivision,
        });
      }
    } else {
      const [messageTemplateRes, messageTemplateErr, messageTemplateNet] =
        await MessageTemplateList.create({
          apiToken: controller.apiToken,
          data: {
            divisions: params.divisions,
            header: params.header,
            detail: params.detail,
          },
        });

      if (messageTemplateRes) {
        Action(controller, {
          action: "searchMessageTemplate",
          divisions:
            state.SettingQueueSequence?.messageTemplateOrder?.searchDivision,
        });
      }
    }
  } else if (params.action === "searchNotificationMessage") {
    const state = controller.getState();

    const [notiListRes, notiListErr, notiListNet] =
      await NotificationMessageSettingsList.list({
        apiToken: controller.apiToken,
        params: {
          setting_type: "DOCTOR_QUEUE",
          divisions: params.divisions,
        },
      });

    if (notiListRes) {
      controller.setState({
        SettingQueueSequence: {
          ...state.SettingQueueSequence,
          notificationQueueOrder: {
            ...state.SettingQueueSequence?.notificationQueueOrder,
            notificationOrder: notiListRes?.items,
            selectAllDivision: false,
            selectDivision: [],
            patientCount: 0,
            notificationDetail: "",
          },
        },
      });
    }
  } else if (params.action === "searchMessageTemplate") {
    const state = controller.getState();

    const [messageTemplateRes, messageTemplateErr, messageTemplateNet] =
      await MessageTemplateList.list({
        apiToken: controller.apiToken,
        params: {
          divisions: params.divisions,
        },
      });

    if (messageTemplateRes) {
      controller.setState({
        SettingQueueSequence: {
          ...state.SettingQueueSequence,
          messageTemplateOrder: {
            ...state.SettingQueueSequence?.messageTemplateOrder,
            messageTemplateOrder: messageTemplateRes?.items,
            selectDivision: [],
            header: "",
            detail: "",
          },
        },
      });
    }
  } else if (params.action === "saveAppointmentReminder") {
    const state = controller.getState();

    controller.setState({
      SettingQueueSequence: {
        ...state.SettingQueueSequence,
        appointmentReminderOrder: {
          ...state.SettingQueueSequence?.appointmentReminderOrder,
          errorMessageAppointment: null,
          closeModal: false,
        }
      },
    });

    if (
      state.SettingQueueSequence?.appointmentReminderOrder
        ?.selectAppointmentReminderID
    ) {
      const [appiontmentListRes, appiontmentListErr, appiontmentListNet] =
        await NotificationMessageSettingsDetail.update({
          apiToken: controller.apiToken,
          pk: state.SettingQueueSequence?.appointmentReminderOrder
            ?.selectAppointmentReminderID,
          data: {
            id: state.SettingQueueSequence?.appointmentReminderOrder
              ?.selectAppointmentReminderID,
            setting_type: "APPOINTMENT",
            is_all_division: params.is_all_division,
            divisions: params.divisions,
            app_confirm_day: params.app_confirm_day,
            app_incoming: { hours: params.app_incoming },
          },
        });

      if (appiontmentListRes) {
        Action(controller, {
          action: "searchAppointmentReminder",
          divisions:
            state.SettingQueueSequence?.appointmentReminderOrder
              ?.searchDivision,
        });
      }

      controller.setState({
        SettingQueueSequence: {
          ...state.SettingQueueSequence,
          appointmentReminderOrder: {
            ...state.SettingQueueSequence?.appointmentReminderOrder,
            selectDivision: [],
            selectAllDivision: false,
            confirmDay: 0,
            incomingData: [0],
            selectAppointmentReminderID: null,
            errorMessageAppointment: null,
            closeModal: true
          },
        },
      });
    } else {
      const [appiontmentListRes, appiontmentListErr, appiontmentListNet] =
        await NotificationMessageSettingsList.create({
          apiToken: controller.apiToken,
          data: {
            setting_type: "APPOINTMENT",
            is_all_division: params.is_all_division,
            divisions: params.divisions,
            app_confirm_day: params.app_confirm_day,
            app_incoming: { hours: params.app_incoming },
          },
        });

      if (appiontmentListErr) {
        if (appiontmentListErr.divisions?.[0].includes("had already existed on notification message settings")) {
          const [appiontmentListAllRes, appiontmentListAllErr, appiontmentListAllNet] = await GetNotificationMessageListAll(controller,{type:"APPOINTMENT"})
          const filterDuplicate = (appiontmentListAllRes.items || []).filter((item:any) => params.divisions.includes(item.division))
  
          controller.setState({
            SettingQueueSequence: {
              ...state.SettingQueueSequence,
              appointmentReminderOrder: {
                ...state.SettingQueueSequence?.appointmentReminderOrder,
                errorMessageAppointment: filterDuplicate,
              }
            },
          });

          return;
        }
      }

      if (appiontmentListRes) {
        Action(controller, {
          action: "searchAppointmentReminder",
          divisions:
            state.SettingQueueSequence?.appointmentReminderOrder
              ?.searchDivision,
        });
      }

      controller.setState({
        SettingQueueSequence: {
          ...state.SettingQueueSequence,
          appointmentReminderOrder: {
            ...state.SettingQueueSequence?.appointmentReminderOrder,
            selectDivision: [],
            selectAllDivision: false,
            confirmDay: 0,
            incomingData: [0],
            selectAppointmentReminderID: null,
            errorMessageAppointment: null,
            closeModal: true
          },
        },
      });
    }
  } else if (params.action === "searchAppointmentReminder") {
    const state = controller.getState();

    const [appiontmentListRes, appiontmentListErr, appiontmentListNet] =
      await NotificationMessageSettingsList.list({
        apiToken: controller.apiToken,
        params: {
          setting_type: "APPOINTMENT",
          divisions: params.divisions,
        },
      });

    if (appiontmentListRes) {
      controller.setState({
        SettingQueueSequence: {
          ...state.SettingQueueSequence,
          appointmentReminderOrder: {
            ...state.SettingQueueSequence?.appointmentReminderOrder,
            appointmentOrder: appiontmentListRes?.items,
          },
        },
      });
    }
  } else if (params.action === "deleteAppointmentReminder") {
    const state = controller.getState();

    const [appiontmentListRes, appiontmentListErr, appiontmentListNet] =
      await NotificationMessageSettingsDetail.update({
        apiToken: controller.apiToken,
        pk: params?.selectAppointmentReminderID,
        data: {
          active: false,
          setting_type: "APPOINTMENT",
          divisions: params.divisions,
          app_confirm_day: params.app_confirm_day,
          app_incoming: { hours: params.app_incoming },
        },
      });

    if (appiontmentListRes) {
      Action(controller, {
        action: "searchAppointmentReminder",
        divisions:
          state.SettingQueueSequence?.appointmentReminderOrder?.searchDivision,
      });
    }
  } else if (params.action === "deleteNotificationMessage") {
    const state = controller.getState();

    const [notiListRes, notiListErr, notiListNet] =
      await NotificationMessageSettingsDetail.delete({
        apiToken: controller.apiToken,
        pk: params?.selectNotificationID,
      });

    Action(controller, {
      action: "searchNotificationMessage",
      divisions:
        state.SettingQueueSequence?.notificationQueueOrder?.searchDivision,
    });
  } else if (params.action === "deleteMessageTemplate") {
    const state = controller.getState();

    const [messageTemplateRes, messageTemplateErr, messageTemplateNet] =
      await MessageTemplateDetail.delete({
        apiToken: controller.apiToken,
        pk: params?.selectMessageTemplateID,
      });

    Action(controller, {
      action: "searchMessageTemplate",
      divisions:
        state.SettingQueueSequence?.messageTemplateOrder?.searchDivision,
    });
  }
};

/* ------------------------------------------------------ */

/*                         GET API                        */

/* ------------------------------------------------------ */
const GetNotificationMessageListAll: Handler = async (controller, params) => {
  const state = controller.getState();
  const {type} = params

  const [appiontmentListRes, appiontmentListErr, appiontmentListNet]
   = await NotificationMessageSettingsList.list({
      apiToken: controller.apiToken,
      params: {
        setting_type: type,
        divisions: [],
      }
    })

  return [appiontmentListRes, appiontmentListErr, appiontmentListNet]
};

