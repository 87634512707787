import WasmController from "react-lib/frameworks/WasmController";
import DrugTransferRequestList from "issara-sdk/apis/DrugTransferRequestList_apps_TPD";
import DrugOrderQueueList from "issara-sdk/apis/DrugOrderQueueList_apps_TPD";
import DrugOrderDetailForAction from "issara-sdk/apis/DrugOrderDetailForAction_apps_TPD";
import StaffDirectMedicationView from "issara-sdk/apis/StaffDirectMedicationView_apps_PRX";
export type State = {
  // Seq
  DrugOrderListSequence?: {
    sequenceIndex?: string | null;
    drugTransferList?: any[] | null;
    drugOrders?: any[] | null;
    drugs?: any | null
  } | null;
  selectedEncounter?: any;
  selectedDivision?: any;
  selectedPatient?: any;
};

export const StateInitial: State = {
  DrugOrderListSequence: null
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  user?: number;
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const Start: Handler = async (controller, params) => {
  console.log('Start params: ', params);
  let state = controller.getState();
  if (!state.DrugOrderListSequence) return;

  // Master data
  // controller.handleEvent({
  //   message: "GetMasterData",
  //   params: {
  //     masters: [
  //       ["division", {}],
  //       ["patientType", {}],
  //       ["applications", {}],
  //     ],
  //   },
  // });
console.log("state.selectedEncounter?.number: ", state.selectedEncounter?.number)
console.log(" state.selectedPatient?.id: ",  state.selectedPatient?.id )
console.log('controller.apiToken: ', controller.apiToken);

  const [drugRes, drugErr] = await StaffDirectMedicationView.get({ 
    // params: { en: "O01-22-0000854" , patient: 12273 },
    params: { en: state.selectedEncounter?.number , patient: state.selectedPatient?.id },
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division,
    },
  })
  console.log('drugErr: ', drugErr);
  console.log('drugRes: ', drugRes);

  // const [drugRes, drugErr] = await DrugOrderQueueList.list({
  //   params: {
  //     patient: state.selectedEncounter?.patient,
  //     //TODO: Should save better than auto selected division 
  //     order_perform_div: state.selectedDivision?.id,
  //     is_queue: true
  //   },
  //   apiToken: controller.apiToken,
  // });

  // const [drugTranferRes, drugTranferErr] = await DrugTransferRequestList.list({
  //   params: { is_queue: true },
  //   apiToken: controller.apiToken,
  //   extra: {
  //     division: controller.data.division,
  //   },
  // });

  controller.setState({
    DrugOrderListSequence: {
      ...state.DrugOrderListSequence,
      sequenceIndex: "Action",
      drugOrders: drugErr ? [] : ( drugRes?.items || [] ),
      // drugTransferList: drugTranferErr ? [] : (drugTranferRes?.items || [])
    },
  });

};

export const Action: Handler = async (controller, params) => {
  console.log('Action params: ', params);
  let state = controller.getState();
  if (params.action === "selectDrugId" && params.rowId) {

    controller.setState({
      DrugOrderListSequence: {
        ...state.DrugOrderListSequence,
        drugs: null
      }
    });

    // Call API 
    const [res, err] = await DrugOrderDetailForAction.retrieve({
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
      pk: params.rowId
    })

    state = controller.getState();
    if (!err && res?.items) {
      controller.setState({
        DrugOrderListSequence: {
          ...state.DrugOrderListSequence,
          drugs: res
        }
      });
    }
 
  }
};
