import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Icon,
  FormGroup,
  FormField,
  Label,
  Input,
  Button
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardStockOrderWorkingUX = (props: any) => {
    return(
      <div
        style={{width:"100%", padding: "10px",  height: "100%"}}>
        <div
          className="ui form">
          
          <div
            style={{display: "flex", justifyContent: "flex-end"}}>
            
            <div
              style={{cursor: "pointer"}}>
              
              <Icon
                className="red large"
                link={true}
                name="close"
                onClick={props.onClose}>
              </Icon>
            </div>
          </div>
        </div>
        <div
          className="ui form"
          style={{marginBottom: "1.5rem"}}>
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label>
                เลขที่ใบโอนสินค้า
              </label>
            </FormField>
            <FormField
              inline={true}>
              <Label
                className="large"
                style={{backgroundColor: "#C3F4FF", color: "rgba(0,0,0,.87)"}}>
                {props.StockTransferOrderSequence?.selectedBox?.request_no || "-"}
              </Label>
            </FormField>
          </FormGroup>
        </div>
        <div
          className="ui form"
          style={{marginBottom: "-0.25rem"}}>
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label
                style={{width: "95px"}}>
                แผนกผู้ขอสินค้า
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Input
                readOnly={true}
                ref={(ref) => ref && (ref.inputRef.current.style.backgroundColor = "#DFDFDF",ref.inputRef.current.style.borderColor = "#DBDBDB")}
                value={props.StockTransferOrderSequence?.selectedBox?.requester_name || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}>
              <label>
                แผนกผู้ให้สินค้า
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Input
                readOnly={true}
                ref={(ref) => ref && (ref.inputRef.current.style.backgroundColor = "#DFDFDF",ref.inputRef.current.style.borderColor = "#DBDBDB")}
                value={props.StockTransferOrderSequence?.selectedBox?.provider_name || ""}>
              </Input>
            </FormField>
            <div
              className="field"
              style={{flex: 1}}>
              
            </div>
            <FormField
              inline={true}>
              <Label
                color="blue"
                style={{ display: props.StockTransferOrderSequence?.selectedBox?.status==="DELIVERED" ? "flex" : "none"}}>
                DELIVERED
              </Label>
            </FormField>
            <FormField
              inline={true}>
              <Label
                color="green"
                style={{ display: props.StockTransferOrderSequence?.selectedBox?.status==="APPROVED" ? "flex" : "none"}}>
                APPROVED
              </Label>
            </FormField>
          </FormGroup>
        </div>
        <div
          className="ui form">
          
          <FormGroup
            inline={true}
            style={{marginBottom: "0.75rem"}}>
            <FormField
              inline={true}>
              <label
                style={{width: "70px"}}>
                ผู้ขอสินค้า
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Input
                fluid={true}
                readOnly={true}
                ref={(ref) => ref && (ref.inputRef.current.style.backgroundColor = "#DFDFDF",ref.inputRef.current.style.borderColor = "#DBDBDB")}
                style={{width: "100%"}}
                value={props.StockTransferOrderSequence?.selectedBox?.requested?.name || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}>
              <label>
                วัน-เวลา
              </label>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <Input
                fluid={true}
                readOnly={true}
                ref={(ref) => ref && (ref.inputRef.current.style.backgroundColor = "#DFDFDF",ref.inputRef.current.style.borderColor = "#DBDBDB")}
                style={{width: "100%"}}
                value={props.StockTransferOrderSequence?.selectedBox?.requested?.datetime_str || ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{marginBottom: "0.75rem"}}>
            <FormField
              inline={true}>
              <label
                style={{width: "70px"}}>
                ผู้อนุมัติ
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Input
                fluid={true}
                readOnly={true}
                ref={(ref) => ref && (ref.inputRef.current.style.backgroundColor = "#DFDFDF",ref.inputRef.current.style.borderColor = "#DBDBDB")}
                style={{width: "100%"}}
                value={props.StockTransferOrderSequence?.selectedBox?.approved?.name || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}>
              <label>
                วัน-เวลา
              </label>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <Input
                fluid={true}
                readOnly={true}
                ref={(ref) => ref && (ref.inputRef.current.style.backgroundColor = "#DFDFDF",ref.inputRef.current.style.borderColor = "#DBDBDB")}
                style={{width: "100%"}}
                value={props.StockTransferOrderSequence?.selectedBox?.approved?.datetime_str || ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{marginBottom: "0.75rem"}}>
            <FormField
              inline={true}>
              <label
                style={{width: "70px"}}>
                ผู้จ่าย
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Input
                fluid={true}
                readOnly={true}
                ref={(ref) => ref && (ref.inputRef.current.style.backgroundColor = "#DFDFDF",ref.inputRef.current.style.borderColor = "#DBDBDB")}
                style={{width: "100%"}}
                value={props.StockTransferOrderSequence?.selectedBox?.delivered?.name || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}>
              <label>
                วัน-เวลา
              </label>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <Input
                fluid={true}
                readOnly={true}
                ref={(ref) => ref && (ref.inputRef.current.style.backgroundColor = "#DFDFDF",ref.inputRef.current.style.borderColor = "#DBDBDB")}
                style={{width: "100%"}}
                value={props.StockTransferOrderSequence?.selectedBox?.delivered?.datetime_str || ""}>
              </Input>
            </FormField>
          </FormGroup>
        </div>
        <div
          className="ui form">
          
          <div
            style={{width: "100%"}}>
            {props.TopTable}
          </div>
          <Table
            data={props.items || []}
            getTheadThProps={props.getTheadThProps}
            getTrProps={props.getTrProps}
            headers="Item code,Name,จำนวนขอ,Lot No.,Expiry Date,จำนวนสินค้า,จำนวนจ่าย,จำนวนจ่ายรวม,จำนวนรับ,จำนวนรับรวม"
            keys="code,name,request_quantity,lot_no,expired_date,quantity,provide_quantity_input,total_amount,receive_quantity_input,total_recv_amount"
            minRows={4}
            showPagination={false}
            style={{height: "200px"}}
            widths="100,,100,100,100,100,100,100,100,100">
          </Table>
          <div>
            {props.ErrorMessage}
          </div>
        </div>
        <div
          className="ui form"
          style={{marginTop: "2rem"}}>
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <Button
                disabled={!props.StockTransferOrderSequence?.selectedBox?.id}
                onClick={props.onActionLog}>
                LOG
              </Button>
            </FormField>
            <div
              className="field"
              style={{flex: 1}}>
              
            </div>
            <FormField
              inline={true}>
              <div>
                {props.buttonBackward}
              </div>
            </FormField>
            <FormField>
              <div>
                {props.buttonForward}
              </div>
            </FormField>
          </FormGroup>
        </div>
      </div>
    )
}

CardStockOrderWorkingUX.displayName = "CardStockOrderWorkingUX";
export default React.memo(CardStockOrderWorkingUX)

export const screenPropsDefault = {}

/* Date Time : Wed Jan 31 2024 11:07:11 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width:\"100%\", padding: \"10px\",  height: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{cursor: \"pointer\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "Icon",
      "parent": 3,
      "props": {
        "className": {
          "type": "value",
          "value": "red large"
        },
        "link": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "close"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"1.5rem\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormGroup",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "label",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขที่ใบโอนสินค้า"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "Label",
      "parent": 8,
      "props": {
        "children": {
          "type": "code",
          "value": "props.StockTransferOrderSequence?.selectedBox?.request_no || \"-\""
        },
        "className": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#C3F4FF\", color: \"rgba(0,0,0,.87)\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "FormGroup",
      "parent": 11,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.75rem\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "FormGroup",
      "parent": 11,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.75rem\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "FormGroup",
      "parent": 11,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.75rem\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "FormField",
      "parent": 27,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "FormField",
      "parent": 27,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "FormField",
      "parent": 27,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "FormField",
      "parent": 27,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "FormField",
      "parent": 28,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "FormField",
      "parent": 28,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "FormField",
      "parent": 28,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "FormField",
      "parent": 28,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "FormField",
      "parent": 29,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "FormField",
      "parent": 29,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "FormField",
      "parent": 29,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "FormField",
      "parent": 29,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "label",
      "parent": 30,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ขอสินค้า"
        },
        "style": {
          "type": "code",
          "value": "{width: \"70px\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "label",
      "parent": 32,
      "props": {
        "children": {
          "type": "value",
          "value": "วัน-เวลา"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "label",
      "parent": 34,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้อนุมัติ"
        },
        "style": {
          "type": "code",
          "value": "{width: \"70px\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "label",
      "parent": 36,
      "props": {
        "children": {
          "type": "value",
          "value": "วัน-เวลา"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "label",
      "parent": 38,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้จ่าย"
        },
        "style": {
          "type": "code",
          "value": "{width: \"70px\"}"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "label",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": "วัน-เวลา"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "Input",
      "parent": 31,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "ref": {
          "type": "code",
          "value": "(ref) => ref && (ref.inputRef.current.style.backgroundColor = \"#DFDFDF\",ref.inputRef.current.style.borderColor = \"#DBDBDB\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.StockTransferOrderSequence?.selectedBox?.requested?.name || \"\""
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "Input",
      "parent": 33,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "ref": {
          "type": "code",
          "value": "(ref) => ref && (ref.inputRef.current.style.backgroundColor = \"#DFDFDF\",ref.inputRef.current.style.borderColor = \"#DBDBDB\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.StockTransferOrderSequence?.selectedBox?.requested?.datetime_str || \"\""
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "Input",
      "parent": 35,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "ref": {
          "type": "code",
          "value": "(ref) => ref && (ref.inputRef.current.style.backgroundColor = \"#DFDFDF\",ref.inputRef.current.style.borderColor = \"#DBDBDB\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.StockTransferOrderSequence?.selectedBox?.approved?.name || \"\""
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "Input",
      "parent": 37,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "ref": {
          "type": "code",
          "value": "(ref) => ref && (ref.inputRef.current.style.backgroundColor = \"#DFDFDF\",ref.inputRef.current.style.borderColor = \"#DBDBDB\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.StockTransferOrderSequence?.selectedBox?.approved?.datetime_str || \"\""
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "Input",
      "parent": 39,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "ref": {
          "type": "code",
          "value": "(ref) => ref && (ref.inputRef.current.style.backgroundColor = \"#DFDFDF\",ref.inputRef.current.style.borderColor = \"#DBDBDB\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.StockTransferOrderSequence?.selectedBox?.delivered?.name || \"\""
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "Input",
      "parent": 41,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "ref": {
          "type": "code",
          "value": "(ref) => ref && (ref.inputRef.current.style.backgroundColor = \"#DFDFDF\",ref.inputRef.current.style.borderColor = \"#DBDBDB\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.StockTransferOrderSequence?.selectedBox?.delivered?.datetime_str || \"\""
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 56,
      "name": "Table",
      "parent": 54,
      "props": {
        "data": {
          "type": "code",
          "value": "props.items || []"
        },
        "getTheadThProps": {
          "type": "code",
          "value": "props.getTheadThProps"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getTrProps"
        },
        "headers": {
          "type": "value",
          "value": "Item code,Name,จำนวนขอ,Lot No.,Expiry Date,จำนวนสินค้า,จำนวนจ่าย,จำนวนจ่ายรวม,จำนวนรับ,จำนวนรับรวม"
        },
        "keys": {
          "type": "value",
          "value": "code,name,request_quantity,lot_no,expired_date,quantity,provide_quantity_input,total_amount,receive_quantity_input,total_recv_amount"
        },
        "minRows": {
          "type": "code",
          "value": "4"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"200px\"}"
        },
        "widths": {
          "type": "value",
          "value": "100,,100,100,100,100,100,100,100,100"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"2rem\"}"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "FormGroup",
      "parent": 57,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "FormField",
      "parent": 58,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "FormField",
      "parent": 58,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "div",
      "parent": 58,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "field"
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "Button",
      "parent": 59,
      "props": {
        "children": {
          "type": "value",
          "value": "LOG"
        },
        "disabled": {
          "type": "code",
          "value": "!props.StockTransferOrderSequence?.selectedBox?.id"
        },
        "onClick": {
          "type": "code",
          "value": "props.onActionLog"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 64,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"-0.25rem\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "FormGroup",
      "parent": 64,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "FormField",
      "parent": 65,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "FormField",
      "parent": 65,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "FormField",
      "parent": 65,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "FormField",
      "parent": 65,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "FormField",
      "parent": 65,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "FormField",
      "parent": 65,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "div",
      "parent": 65,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "field"
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "label",
      "parent": 66,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนกผู้ขอสินค้า"
        },
        "style": {
          "type": "code",
          "value": "{width: \"95px\"}"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "label",
      "parent": 68,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนกผู้ให้สินค้า"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "Label",
      "parent": 70,
      "props": {
        "children": {
          "type": "value",
          "value": "DELIVERED"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.StockTransferOrderSequence?.selectedBox?.status===\"DELIVERED\" ? \"flex\" : \"none\"}"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "Label",
      "parent": 71,
      "props": {
        "children": {
          "type": "value",
          "value": "APPROVED"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "name": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.StockTransferOrderSequence?.selectedBox?.status===\"APPROVED\" ? \"flex\" : \"none\"}"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 77,
      "name": "Input",
      "parent": 67,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "ref": {
          "type": "code",
          "value": "(ref) => ref && (ref.inputRef.current.style.backgroundColor = \"#DFDFDF\",ref.inputRef.current.style.borderColor = \"#DBDBDB\")"
        },
        "value": {
          "type": "code",
          "value": "props.StockTransferOrderSequence?.selectedBox?.requester_name || \"\""
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "Input",
      "parent": 69,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "ref": {
          "type": "code",
          "value": "(ref) => ref && (ref.inputRef.current.style.backgroundColor = \"#DFDFDF\",ref.inputRef.current.style.borderColor = \"#DBDBDB\")"
        },
        "value": {
          "type": "code",
          "value": "props.StockTransferOrderSequence?.selectedBox?.provider_name || \"\""
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "div",
      "parent": 54,
      "props": {
        "children": {
          "type": "code",
          "value": "props.TopTable"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "div",
      "parent": 60,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonBackward"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "div",
      "parent": 85,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonForward"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 84,
      "name": "div",
      "parent": 54,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ErrorMessage"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 85,
      "name": "FormField",
      "parent": 58,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 85,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": true,
  "name": "CardStockOrderWorkingUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
