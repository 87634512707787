
/* Generated apis ---------------------- */
import { to } from 'react-lib/utils'
import BaseService from  'react-lib/apis/services/BaseService'
import config from '../../config/config'
import StockReconcileSerializer from '../types/StockReconcileSerializer_core'


const HOST = `${config.API_HOST}`
/* ['generics.UpdateAPIView', 'generics.DestroyAPIView', 'generics.ListCreateAPIView', 'HISCustomMethodPermissionMixin'] */
/* params:  */
/* data:  */
const ProductStockReconcileView : 
{
  list:     ({storage_id,product_id, params, apiToken, extra}:
             { storage_id: any,product_id: any, 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
  create:   ({storage_id,product_id, params, data, apiToken, extra}:
             { storage_id: any,product_id: any, 
                params?: any,
                data?: StockReconcileSerializer,
                apiToken?: any,
                extra?: any
            }) => any,
  delete: ({storage_id,product_id, params, apiToken, extra}:
             { storage_id: any,product_id: any, 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
  update:   ({storage_id,product_id, params, data, apiToken, extra}:
             { storage_id: any,product_id: any, 
                params?: any,
                data?: StockReconcileSerializer,
                apiToken?: any,
                extra?: any,
            }) => any,
  patch:    ({storage_id,product_id, params, data, apiToken, extra}:
             { storage_id: any,product_id: any, 
                params?: any,
                data?: StockReconcileSerializer,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  list: async ({storage_id,product_id, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken)
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    if (extra?.division)
      base.client.defaults.headers["division"] = extra.division;
    if (extra?.device)
      base.client.defaults.headers["device"] = extra.device;
    const result = await to(base.client.get(`${HOST}/apis/core/stock/reconcile/storage/${storage_id}/product/${product_id}/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  create: async ({storage_id,product_id, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken)
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    if (extra?.division)
      base.client.defaults.headers["division"] = extra.division;
    if (extra?.device)
      base.client.defaults.headers["device"] = extra.device;
    const result = await to(base.client.post(`${HOST}/apis/core/stock/reconcile/storage/${storage_id}/product/${product_id}/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  delete: async ({storage_id,product_id, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken)
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    if (extra?.division)
      base.client.defaults.headers["division"] = extra.division;
    if (extra?.device)
      base.client.defaults.headers["device"] = extra.device;
    const result = await to(base.client.delete(`${HOST}/apis/core/stock/reconcile/storage/${storage_id}/product/${product_id}/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  update: async ({storage_id,product_id, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken)
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    if (extra?.division)
      base.client.defaults.headers["division"] = extra.division;
    if (extra?.device)
      base.client.defaults.headers["device"] = extra.device;
    const result = await to(base.client.put(`${HOST}/apis/core/stock/reconcile/storage/${storage_id}/product/${product_id}/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  patch: async ({storage_id,product_id, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken)
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    if (extra?.division)
      base.client.defaults.headers["division"] = extra.division;
    if (extra?.device)
      base.client.defaults.headers["device"] = extra.device;
    const result = await to(base.client.patch(`${HOST}/apis/core/stock/reconcile/storage/${storage_id}/product/${product_id}/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

export default ProductStockReconcileView

