import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Calendar
} from 'react-big-calendar'

const CardDoctorScheduleUX = (props: any) => {
    return(
      <div
        style={{height: "100%", display: "flex"}}>
        <div
          style={{flex: 1, padding: "10px"}}>
          
          <div>
            {props.providerSelector}
          </div>
          <div
            style={{marginTop: "10px"}}>
            
          </div>
        </div>
        <div
          style={{padding: "10px", flex: 3,  height: "100%"}}>
          
          <Calendar
            eventPropGetter={props.eventPropGetter}
            events={props.blockList || []}
            formats={props.formats}
            localizer={props.localizer}
            onSelectEvent={props.onSelectEvent}
            views={["month", "week"]}>
          </Calendar>
        </div>
      </div>
    )
}


export default CardDoctorScheduleUX

export const screenPropsDefault = {"providerSelector":"[Component for selecting provider]","selectedProvider":{}}

/* Date Time : Fri Jul 26 2024 17:29:44 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{height: \"100%\", display: \"flex\"}"
        }
      },
      "seq": 0
    },
    {
      "from": "react-big-calendar",
      "id": 1,
      "name": "Calendar",
      "parent": 3,
      "props": {
        "eventPropGetter": {
          "type": "code",
          "value": "props.eventPropGetter"
        },
        "events": {
          "type": "code",
          "value": "props.blockList || []"
        },
        "formats": {
          "type": "code",
          "value": "props.formats"
        },
        "localizer": {
          "type": "code",
          "value": "props.localizer"
        },
        "onSelectEvent": {
          "type": "code",
          "value": "props.onSelectEvent"
        },
        "views": {
          "type": "code",
          "value": "[\"month\", \"week\"]"
        }
      },
      "seq": 2,
      "void": true
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "code",
          "value": "props.providerSelector"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", flex: 3,  height: \"100%\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: 1, padding: \"10px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"10px\"}"
        }
      },
      "seq": 5,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardDoctorScheduleUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "providerSelector": "[Component for selecting provider]",
    "selectedProvider": {
    }
  },
  "width": 80
}

*********************************************************************************** */
