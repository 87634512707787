import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import {
  Dropdown,
  Checkbox,
  Pagination,
  PaginationProps,
  Icon,
  Modal,
  Divider,
} from "semantic-ui-react";
import ReactTable from "react-table-6";
import CardCSDivisionSearch from "./CardCSDivisionSearch";
import CardCSDoctorSearch from "./CardCSDoctorSearch";
import { useIntl } from "react-intl";

interface CardCSReplyConsultProps {
  allDivisionList: any;
  allDoctorList: any;
  csReplyConsultList: any;
  patientDataAPI: any;
  handleGetCSReplyConsult: any;
}

const CardCSReplyConsult = forwardRef((props: CardCSReplyConsultProps, ref) => {

  const isMounted = useRef(true)
  const [isReplyConsultTableLoading, setIsReplyConsultTableLoading] = useState(false);
  const [isCSDivisionSearchLoading, setIsCSDivisionSearchLoading] = useState(false);
  const [isCSDoctorSearchLoading, setIsCSDoctorSearchLoading] = useState(false);

  const [activePage, setActivePage] = useState(1);
  const [divisionSearch, setDivisionSearch] = useState("")
  const [doctorSearch, setDoctorSearch] = useState("")

  const [openModal, setOpenModal] = useState(false);
  const [selectedConsult, setSelectedConsult] = useState(null);

  const limit = 6;

  const columns = [
    {
      Header: "Date",
      accessor: "order_time", // String-based value accessors!
      resizeable: false,
      width: 120,
    },
    {
      Header: "Consult Order",
      Cell: (props) => (<div>{props.original.ordered_doctor_name} <br /> {props.original.ordered_div_name}</div>), // Custom cell components!
      resizeable: false,
    },
    {
      Header: "ตอบกลับ Consult",
      Cell: (props) => (<div>{props.original.consulted_doctor_name} <br /> {props.original.division_name}</div>), // Custom cell components!
      resizeable: false,
    },
    {
      Header: "Status",
      Cell: (props) => {
        if (props.original.current_illness || props.original.note_response || props.original.suggestion) {
          return (<Icon name="check circle" color="green" />)
        } else {
          return (<Icon name="times circle outline" color="red" />)
        }
      },
      resizeable: false,
      width: 120,
    },
  ];

  useImperativeHandle(ref, () => ({
    getData: () => {
      return {
        doctor: doctorSearch,
        division: divisionSearch,
        limit: limit,
        offset: activePage - 1,
      };
    },
  }));

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    const loadReplyConsult = async () => {
      setIsReplyConsultTableLoading(true)
      await props.handleGetCSReplyConsult()
      setIsReplyConsultTableLoading(false)
    }
    loadReplyConsult()
  }, [props.patientDataAPI, divisionSearch, doctorSearch, activePage])

  const totalPages = Math.ceil(props.csReplyConsultList?.total / limit);

  // console.log("CardCSReplyConsult props.csReplyConsultList", props.csReplyConsultList)
  // console.log("SelectedCosult", selectedConsult, "openModal", openModal)
  return (
    <div className="card-cs card-replyconsult">
      <div className="card-cs-header">
        <div style={{ width: "100px" }}></div>
        <div className="card-cs-header-title">Division</div>
        <CardCSDivisionSearch
          disabled={isReplyConsultTableLoading}
          allDivisionList={props.allDivisionList}
          setDivisionSearch={(search) => {
            setDivisionSearch(search)
            setActivePage(1)
          }}
          isLoading={isCSDivisionSearchLoading} 
          languageUX={props.languageUX}
        />
        <div className="card-cs-header-title">Doctor</div>
        <CardCSDoctorSearch
          disabled={isReplyConsultTableLoading}
          allDoctorList={props.allDoctorList}
          setDoctorSearch={(search) => {
            setDoctorSearch(search)
            setActivePage(1)
          }}
          isLoading={isCSDoctorSearchLoading}
          languageUX={props.languageUX}
        />
      </div>
      <div className="card-cs-header-title">Reply Consult</div>
      <div className="card-wrap-cs-table">
        <ReactTable
          className="card-cs-table"
          loading={isReplyConsultTableLoading}
          data={props.csReplyConsultList?.items?.length > 0 ? props.csReplyConsultList?.items : []}
          columns={columns}
          style={{ height: "100%" }}
          showPagination={false}
          minRows={6}
          NoDataComponent={() => null}
          getTheadThProps={(state, rowInfo, column, instance) => {
            // console.log(" getTdProps", state, rowInfo, column, instance)
            let style = {
              fontSize: "12px",
              border: "none",
              boxShadow: "none",
              textAlign: "left",
            }
            if (column.Header === "Status") {
              style = { ...style, ...{ textAlign: "center" } }
            }
            return { style }
          }}
          getTrProps={(state, rowInfo, column, instance) => {
            return {
              onClick: (e) => {
                setSelectedConsult(rowInfo.original);
                setOpenModal(true);
              }
            }
          }}
          getTdProps={(state, rowInfo, column, instance) => {
            // console.log(" getTdProps", state, rowInfo, column, instance)
            let style = {
              fontSize: "12px",
              border: "none",
              boxShadow: "none",
              fontWeight: "bolder",
              color: "#4F4F4F",
            };
            if (column.Header === "Order Type" || column.Header === "Division") {
              style = { ...style, ...{ fontWeight: "normal" } };
            }
            if (column.Header === "Status") {
              style = { ...style, ...{ textAlign: "center" } }
            }
            return { style };
          }}
        />
      </div>
      {totalPages > 1 ? (
        <div style={{ textAlign: "center" }}>
          <Pagination
            disabled={!(props.csReplyConsultList?.items?.length > 0)}
            onPageChange={(
              e: React.MouseEvent<HTMLAnchorElement>,
              data: PaginationProps
            ) => {
              setActivePage(data.activePage as number);
            }}
            activePage={activePage}
            firstItem={null}
            lastItem={null}
            ellipsisItem={null}
            pointing
            secondary
            siblingRange={1}
            totalPages={totalPages}
          />
        </div>
      ) : null}
      <ModalDoctorConsult
        open={openModal}
        data={selectedConsult}
        handleOnlClose={() => { setOpenModal(false) }}
      />
    </div>
  );
});


const ModalDoctorConsult = (props: any) => {
  const intl = useIntl();
  return <Modal open={props.open} onClose={props.handleOnlClose} style={{ padding: "30px", minWidth: "40%", width: "auto" }}>
    <h2>Consult Note {props.data?.ordered_doctor_name} - {props.data?.consulted_doctor_name}</h2>
    <div style={{ fontSize: "16px", display: "flex", flexDirection: "column" }}>
      <div style={{ padding: "5px" }}><span style={{ fontWeight: 'bold' }}> {intl.formatMessage({ id: "ผู้ขอ Consult:" })}</span>&ensp;{props.data?.ordered_doctor_name}
        &emsp; <span style={{ fontWeight: 'bold' }}> Division:</span> &ensp;{props.data?.ordered_div_name}</div>
      <div style={{ padding: "5px" }}><span style={{ fontWeight: 'bold' }}>{" "}{intl.formatMessage({ id: "รายละเอียด:" })}</span>&ensp;{props.data?.note}</div>
      <div style={{ padding: "5px" }}><span style={{ fontWeight: 'bold' }}>{" "}{intl.formatMessage({ id: "วันเวลาที่สั่ง:" })}</span>&ensp;{props.data?.order_time}</div>
      <div style={{ padding: "5px" }}><span style={{ fontWeight: 'bold' }}>{" "}{intl.formatMessage({ id: "ความเร่งด่วน:" })}</span>&ensp;{props.data?.urgency_name}</div>
    </div>
    <div style={{ fontSize: "16px", display: "flex", flexDirection: "column" }}>
      <Divider />
      <div style={{ padding: "5px" }}><span style={{ fontWeight: 'bold' }}> {intl.formatMessage({ id: "ตอบกลับ Consult:" })}</span>  {' '} {props.data?.consulted_doctor_name}
        &emsp; <span style={{ fontWeight: 'bold' }}> Division:</span> {' '} {props.data?.division_name}</div>
      <div style={{ padding: "5px" }}> <span style={{ fontWeight: 'bold' }}> Current illness:</span></div>
      <div style={{ padding: "5px" }}>{props.data?.current_illness}</div>
      <div style={{ padding: "5px" }}> <span style={{ fontWeight: 'bold' }}> Suggestion:</span></div>
      <div style={{ padding: "5px" }}>{props.data?.suggestion}</div>
      <div style={{ padding: "5px" }}> <span style={{ fontWeight: 'bold' }}> Remark:</span>{' '} {props.data?.note_response}</div>
      <div style={{ padding: "5px" }}> <span style={{ fontWeight: 'bold' }}> รักษาต่อเนื่อง:</span>{' '} {props.data?.need_continuous_medication}</div>
    </div>
  </Modal>
}

export default React.memo(CardCSReplyConsult);

