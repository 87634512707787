import React from 'react'
import {
   Button,
   Checkbox,
   Dropdown,
   Input,
} from 'semantic-ui-react'



import styles from './OTPhysicalExaminationSwallowingEvaluation2.module.css'

export interface OTPhysicalExaminationSwallowingEvaluation2ViewDataType {
    CtFoisLevel: string,
    CtFoisLevelDisabled: boolean,
    CtFoisLevelOptions: any,
    OtPhysicalSwallowing25: boolean,
    OtPhysicalSwallowing25Disabled: boolean,
    OtPhysicalSwallowing26: boolean,
    OtPhysicalSwallowing26Disabled: boolean,
    OtPhysicalSwallowing29: boolean,
    OtPhysicalSwallowing29Disabled: boolean,
    OtPhysicalSwallowing210: string,
    OtPhysicalSwallowing210Disabled: boolean,
    OtPhysicalSwallowing212: boolean,
    OtPhysicalSwallowing212Disabled: boolean,
    OtPhysicalSwallowing214: boolean,
    OtPhysicalSwallowing214Disabled: boolean,
    OtPhysicalSwallowing216: boolean,
    OtPhysicalSwallowing216Disabled: boolean,
    OtPhysicalSwallowing227Disabled: boolean,
    CtScrFirstStep: string,
    CtScrFirstStepDisabled: boolean,
    CtScrFirstStepOptions: any,
    OtPhysicalSwallowing235: string,
    OtPhysicalSwallowing235Disabled: boolean,
    CtScrSecond1st: string,
    CtScrSecond1stDisabled: boolean,
    CtScrSecond1stOptions: any,
    CtScrSecond2nd: string,
    CtScrSecond2ndDisabled: boolean,
    CtScrSecond2ndOptions: any,
    OtPhysicalSwallowing242: boolean,
    OtPhysicalSwallowing242Disabled: boolean,
    OtPhysicalSwallowing2: boolean,
    OtPhysicalSwallowing2Disabled: boolean,
    OtPhysicalSwallowing249: string,
    OtPhysicalSwallowing249Disabled: boolean,
    CtScrThird1st: string,
    CtScrThird1stDisabled: boolean,
    CtScrThird1stOptions: any,
    CtScrThird2nd: string,
    CtScrThird2ndDisabled: boolean,
    CtScrThird2ndOptions: any,
    OtPhysicalSwallowing257: boolean,
    OtPhysicalSwallowing257Disabled: boolean,
    OtPhysicalSwallowing262: boolean,
    OtPhysicalSwallowing262Disabled: boolean,
    OtPhysicalSwallowing263: string,
    OtPhysicalSwallowing263Disabled: boolean,
    CtScrFourth1st: string,
    CtScrFourth1stDisabled: boolean,
    CtScrFourth1stOptions: any,
    CtScrFourth2nd: string,
    CtScrFourth2ndDisabled: boolean,
    CtScrFourth2ndOptions: any,
    OtPhysicalSwallowing275: boolean,
    OtPhysicalSwallowing275Disabled: boolean,
    OtPhysicalSwallowing280: boolean,
    OtPhysicalSwallowing280Disabled: boolean,
    OtPhysicalSwallowing281: string,
    OtPhysicalSwallowing281Disabled: boolean,
    SaveRequestDisabled: boolean,
}

export const OTPhysicalExaminationSwallowingEvaluation2InitialViewData: OTPhysicalExaminationSwallowingEvaluation2ViewDataType = {
    CtFoisLevel: "",
    CtFoisLevelDisabled: false,
    CtFoisLevelOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalSwallowing25: false,
    OtPhysicalSwallowing25Disabled: false,
    OtPhysicalSwallowing26: false,
    OtPhysicalSwallowing26Disabled: false,
    OtPhysicalSwallowing29: false,
    OtPhysicalSwallowing29Disabled: false,
    OtPhysicalSwallowing210: "",
    OtPhysicalSwallowing210Disabled: false,
    OtPhysicalSwallowing212: false,
    OtPhysicalSwallowing212Disabled: false,
    OtPhysicalSwallowing214: false,
    OtPhysicalSwallowing214Disabled: false,
    OtPhysicalSwallowing216: false,
    OtPhysicalSwallowing216Disabled: false,
    OtPhysicalSwallowing227Disabled: false,
    CtScrFirstStep: "",
    CtScrFirstStepDisabled: false,
    CtScrFirstStepOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalSwallowing235: "",
    OtPhysicalSwallowing235Disabled: false,
    CtScrSecond1st: "",
    CtScrSecond1stDisabled: false,
    CtScrSecond1stOptions: [{key: 0, value: "A", text: "A"}],
    CtScrSecond2nd: "",
    CtScrSecond2ndDisabled: false,
    CtScrSecond2ndOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalSwallowing242: false,
    OtPhysicalSwallowing242Disabled: false,
    OtPhysicalSwallowing2: false,
    OtPhysicalSwallowing2Disabled: false,
    OtPhysicalSwallowing249: "",
    OtPhysicalSwallowing249Disabled: false,
    CtScrThird1st: "",
    CtScrThird1stDisabled: false,
    CtScrThird1stOptions: [{key: 0, value: "A", text: "A"}],
    CtScrThird2nd: "",
    CtScrThird2ndDisabled: false,
    CtScrThird2ndOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalSwallowing257: false,
    OtPhysicalSwallowing257Disabled: false,
    OtPhysicalSwallowing262: false,
    OtPhysicalSwallowing262Disabled: false,
    OtPhysicalSwallowing263: "",
    OtPhysicalSwallowing263Disabled: false,
    CtScrFourth1st: "",
    CtScrFourth1stDisabled: false,
    CtScrFourth1stOptions: [{key: 0, value: "A", text: "A"}],
    CtScrFourth2nd: "",
    CtScrFourth2ndDisabled: false,
    CtScrFourth2ndOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalSwallowing275: false,
    OtPhysicalSwallowing275Disabled: false,
    OtPhysicalSwallowing280: false,
    OtPhysicalSwallowing280Disabled: false,
    OtPhysicalSwallowing281: "",
    OtPhysicalSwallowing281Disabled: false,
    SaveRequestDisabled: false,
}

const Sub = (props: any) => {
    const children = React.Children.toArray(props.children)
    return (
        <div className={styles.container}>
            <div className={styles.OtPhysicalSwallowing21}>
                <div
                    style={{backgroundColor: "#F3F3F3", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.CtFoisLevel}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtFoisLevel}
                    disabled={ props.viewData.CtFoisLevelDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationSwallowingEvaluation2",
                                name: "CtFoisLevel",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtFoisLevelOptions}
                />
            </div>
            <div className={styles.OtPhysicalSwallowing24}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>FOIS level</strong></p>
                </div>
            </div>
            <div className={styles.OtPhysicalSwallowing25}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtPhysicalSwallowing25}
                    disabled={ props.viewData.OtPhysicalSwallowing25Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "OTPhysicalExaminationSwallowingEvaluation2",
                                name: "OtPhysicalSwallowing25"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtPhysicalSwallowing26}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtPhysicalSwallowing26}
                    disabled={ props.viewData.OtPhysicalSwallowing26Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "OTPhysicalExaminationSwallowingEvaluation2",
                                name: "OtPhysicalSwallowing26"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtPhysicalSwallowing28}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Present diet</strong></p>
                </div>
            </div>
            <div className={styles.OtPhysicalSwallowing29}>
                <Checkbox 
                    style={{ height: "100%", width: "100%"}}
                    checked={props.viewData.OtPhysicalSwallowing29}
                    disabled={ props.viewData.OtPhysicalSwallowing29Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "OTPhysicalExaminationSwallowingEvaluation2",
                                name: "OtPhysicalSwallowing29"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtPhysicalSwallowing210}>
                <Input
                    value={props.viewData.OtPhysicalSwallowing210}
                    disabled={ props.viewData.OtPhysicalSwallowing210Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationSwallowingEvaluation2',
                            name: 'OtPhysicalSwallowing210',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalSwallowing211}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p>Liquidized</p>
                </div>
            </div>
            <div className={styles.OtPhysicalSwallowing212}>
                <Checkbox 
                    style={{ height: "100%", width: "100%"}}
                    checked={props.viewData.OtPhysicalSwallowing212}
                    disabled={ props.viewData.OtPhysicalSwallowing212Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "OTPhysicalExaminationSwallowingEvaluation2",
                                name: "OtPhysicalSwallowing212"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtPhysicalSwallowing213}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p>Pureed</p>
                </div>
            </div>
            <div className={styles.OtPhysicalSwallowing214}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtPhysicalSwallowing214}
                    disabled={ props.viewData.OtPhysicalSwallowing214Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "OTPhysicalExaminationSwallowingEvaluation2",
                                name: "OtPhysicalSwallowing214"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtPhysicalSwallowing215}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p>Minced &amp; moist</p>
                </div>
            </div>
            <div className={styles.OtPhysicalSwallowing216}>
                <Checkbox 
                    style={{ height: "100%", width: "100%"}}
                    checked={props.viewData.OtPhysicalSwallowing216}
                    disabled={ props.viewData.OtPhysicalSwallowing216Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "OTPhysicalExaminationSwallowingEvaluation2",
                                name: "OtPhysicalSwallowing216"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtPhysicalSwallowing218}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p>Soft &amp; Bite-sized</p>
                </div>
            </div>
            <div className={styles.OtPhysicalSwallowing222}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p>Regular</p>
                </div>
            </div>
            <div className={styles.OtPhysicalSwallowing223}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p>other</p>
                </div>
            </div>
            <div className={styles.OtPhysicalSwallowing224}>
                <div
                    style={{color: "Red", height: "100%", width: "100%"}}
                >
                    <p><strong>First step : Respetitive saliva swallowing test (Dry) times/30 secords</strong></p>
                </div>
            </div>
            <div className={styles.OtPhysicalSwallowing225}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p><strong>Screening test</strong></p>
                </div>
            </div>
            <div className={styles.OtPhysicalSwallowing227}>
                <Button
                    disabled={ props.viewData.OtPhysicalSwallowing227Disabled }
                    onClick={(e) => { props.onEvent({
                        message: 'clickButton',
                        params: {
                            view: 'OTPhysicalExaminationSwallowingEvaluation2',
                            name: 'OtPhysicalSwallowing227'
                        }
                    })}}
                    style={{color: "#FFFFFF",backgroundColor: "#27AE60", height: "100%", width: "100%"}}
                >
                    Remark
                </Button>
            </div>
            <div className={styles.CtScrFirstStep}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtScrFirstStep}
                    disabled={ props.viewData.CtScrFirstStepDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationSwallowingEvaluation2",
                                name: "CtScrFirstStep",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtScrFirstStepOptions}
                />
            </div>
            <div className={styles.OtPhysicalSwallowing235}>
                <Input
                    value={props.viewData.OtPhysicalSwallowing235}
                    disabled={ props.viewData.OtPhysicalSwallowing235Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationSwallowingEvaluation2',
                            name: 'OtPhysicalSwallowing235',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalSwallowing236}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p>Comment</p>
                </div>
            </div>
            <div className={styles.OtPhysicalSwallowing237}>
                <div
                    style={{color: "Red", height: "100%", width: "100%"}}
                >
                    <p><strong>Second step : modified water swallowing test 4 ml. (syringe)</strong></p>
                </div>
            </div>
            <div className={styles.CtScrSecond1st}>
                <Dropdown selection
                    style={{height: "100%", minWidth: "150px"}}
                    value={props.viewData.CtScrSecond1st}
                    disabled={ props.viewData.CtScrSecond1stDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationSwallowingEvaluation2",
                                name: "CtScrSecond1st",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtScrSecond1stOptions}
                />
            </div>
            <div className={styles.CtScrSecond2nd}>
                <Dropdown selection
                    style={{height: "100%", minWidth: "150px"}}
                    value={props.viewData.CtScrSecond2nd}
                    disabled={ props.viewData.CtScrSecond2ndDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationSwallowingEvaluation2",
                                name: "CtScrSecond2nd",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtScrSecond2ndOptions}
                />
            </div>
            <div className={styles.OtPhysicalSwallowing242}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtPhysicalSwallowing242}
                    disabled={ props.viewData.OtPhysicalSwallowing242Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "OTPhysicalExaminationSwallowingEvaluation2",
                                name: "OtPhysicalSwallowing242"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtPhysicalSwallowing243}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>1st</strong></p>
                </div>
            </div>
            <div className={styles.OtPhysicalSwallowing244}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>2nd</strong></p>
                </div>
            </div>
            <div className={styles.OtPhysicalSwallowing245}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p>N/A</p>
                </div>
            </div>
            <div className={styles.OtPhysicalSwallowing246}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Note : If No and / or  score</strong></p>
                </div>
            </div>
            <div className={styles.OtPhysicalSwallowing247}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>4 prepare to oro-motor stimulation  , score ≥ 4, continue to next step</strong>   </p>
                </div>
            </div>
            <div className={styles.OtPhysicalSwallowing2}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtPhysicalSwallowing2}
                    disabled={ props.viewData.OtPhysicalSwallowing2Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "OTPhysicalExaminationSwallowingEvaluation2",
                                name: "OtPhysicalSwallowing2"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtPhysicalSwallowing249}>
                <Input
                    value={props.viewData.OtPhysicalSwallowing249}
                    disabled={ props.viewData.OtPhysicalSwallowing249Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationSwallowingEvaluation2',
                            name: 'OtPhysicalSwallowing249',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalSwallowing250}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Comment</p>
                </div>
            </div>
            <div className={styles.OtPhysicalSwallowing251}>
                <div
                    style={{color: "Red", height: "90%", width: "100%"}}
                >
                    <p><strong>Third step : Water swallowing test 10 ml. (syringe)</strong></p>
                </div>
            </div>
            <div className={styles.CtScrThird1st}>
                <Dropdown selection
                    style={{height: "100%", minWidth: "150px"}}
                    value={props.viewData.CtScrThird1st}
                    disabled={ props.viewData.CtScrThird1stDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationSwallowingEvaluation2",
                                name: "CtScrThird1st",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtScrThird1stOptions}
                />
            </div>
            <div className={styles.CtScrThird2nd}>
                <Dropdown selection
                    style={{height: "100%", minWidth: "150px"}}
                    value={props.viewData.CtScrThird2nd}
                    disabled={ props.viewData.CtScrThird2ndDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationSwallowingEvaluation2",
                                name: "CtScrThird2nd",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtScrThird2ndOptions}
                />
            </div>
            <div className={styles.OtPhysicalSwallowing256}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>2nd</strong></p>
                </div>
            </div>
            <div className={styles.OtPhysicalSwallowing257}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtPhysicalSwallowing257}
                    disabled={ props.viewData.OtPhysicalSwallowing257Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "OTPhysicalExaminationSwallowingEvaluation2",
                                name: "OtPhysicalSwallowing257"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtPhysicalSwallowing258}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p><strong>1st</strong></p>
                </div>
            </div>
            <div className={styles.OtPhysicalSwallowing259}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p>N/A</p>
                </div>
            </div>
            <div className={styles.OtPhysicalSwallowing260}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p><strong>Note : If No and / or  score</strong></p>
                </div>
            </div>
            <div className={styles.OtPhysicalSwallowing261}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>4 prepare to oro-motor stimulation and swallowing training , score ≥ 4, continue to next step</strong>    </p>
                </div>
            </div>
            <div className={styles.OtPhysicalSwallowing262}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtPhysicalSwallowing262}
                    disabled={ props.viewData.OtPhysicalSwallowing262Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "OTPhysicalExaminationSwallowingEvaluation2",
                                name: "OtPhysicalSwallowing262"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtPhysicalSwallowing263}>
                <Input
                    value={props.viewData.OtPhysicalSwallowing263}
                    disabled={ props.viewData.OtPhysicalSwallowing263Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationSwallowingEvaluation2',
                            name: 'OtPhysicalSwallowing263',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalSwallowing264}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Comment</p>
                </div>
            </div>
            <div className={styles.OtPhysicalSwallowing265}>
                <div
                    style={{color: "Red", height: "100%", width: "100%"}}
                >
                    <p><strong>Fourth step : Water swallowing test 50 ml. (glass)</strong></p>
                </div>
            </div>
            <div className={styles.OtPhysicalSwallowing266}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>4 prepare to swallowing training , score ≥ 4, observe swallowing with planning diet</strong></p>
                </div>
            </div>
            <div className={styles.CtScrFourth1st}>
                <Dropdown selection
                    style={{height: "100%", minWidth: "150px"}}
                    value={props.viewData.CtScrFourth1st}
                    disabled={ props.viewData.CtScrFourth1stDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationSwallowingEvaluation2",
                                name: "CtScrFourth1st",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtScrFourth1stOptions}
                />
            </div>
            <div className={styles.OtPhysicalSwallowing269}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>2nd</strong></p>
                </div>
            </div>
            <div className={styles.CtScrFourth2nd}>
                <Dropdown selection
                    style={{height: "100%", minWidth: "150px"}}
                    value={props.viewData.CtScrFourth2nd}
                    disabled={ props.viewData.CtScrFourth2ndDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationSwallowingEvaluation2",
                                name: "CtScrFourth2nd",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtScrFourth2ndOptions}
                />
            </div>
            <div className={styles.OtPhysicalSwallowing272}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>N/A</p>
                </div>
            </div>
            <div className={styles.OtPhysicalSwallowing275}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtPhysicalSwallowing275}
                    disabled={ props.viewData.OtPhysicalSwallowing275Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "OTPhysicalExaminationSwallowingEvaluation2",
                                name: "OtPhysicalSwallowing275"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtPhysicalSwallowing276}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p><strong>1st</strong></p>
                </div>
            </div>
            <div className={styles.OtPhysicalSwallowing279}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Note : If No and / or  score</strong></p>
                </div>
            </div>
            <div className={styles.OtPhysicalSwallowing280}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtPhysicalSwallowing280}
                    disabled={ props.viewData.OtPhysicalSwallowing280Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "OTPhysicalExaminationSwallowingEvaluation2",
                                name: "OtPhysicalSwallowing280"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtPhysicalSwallowing281}>
                <Input
                    value={props.viewData.OtPhysicalSwallowing281}
                    disabled={ props.viewData.OtPhysicalSwallowing281Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationSwallowingEvaluation2',
                            name: 'OtPhysicalSwallowing281',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalSwallowing282}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Comment</p>
                </div>
            </div>
            <div className={styles.SaveRequest}>
                <Button
                    disabled={ props.viewData.SaveRequestDisabled }
                    onClick={(e) => { props.onEvent({
                        message: 'clickButton',
                        params: {
                            view: 'OTPhysicalExaminationSwallowingEvaluation2',
                            name: 'SaveRequest'
                        }
                    })}}
                    style={{color: "#FFFFFF",backgroundColor: "#27AE60", height: "100%", width: "100%"}}
                >
                    Save
                </Button>
            </div>
        </div>
    )
}

export default Sub

const getTsName = (cssClass: string) => {
    return cssClass.split('-').map((s) => (
        s.charAt(0).toUpperCase() + s.slice(1)
    )).join('')
}
