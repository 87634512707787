import React, { Component, createRef } from "react";
import "semantic-ui-css/semantic.min.css";
import "react-table-6/react-table.css";
import MainScreen from "./MainScreen";
import { IntlProvider, injectIntl } from "react-intl";
import Cookies from "js-cookie";

import { withRouter, Switch, Route } from "react-router-dom";
import {
  Button,
  Menu,
  Dropdown,
  Icon,
  Form,
  Modal,
  Header,
  Image,
  Divider,
  Input,
} from "semantic-ui-react";

// Appcontroller
import * as PlatformI from "./PlatformInterface";
import PlatformController from "./PlatformController";

// Manager
import DPIManager from "react-lib/apis/manager/DPIManager";
import MixInManager from "react-lib/apis/manager/MixInManager";
import MSGManager from "react-lib/apis/manager/MSGManager";
import PRXManager from "react-lib/apis/manager/PRXManager";
import UserManager from "react-lib/apis/manager/UserManager";
import PTMManager from "react-lib/apis/manager/PTMManager";
import REGManager from "react-lib/apis/manager/REGManager";
import CoreManager from "react-lib/apis/manager/CoreManager";
import HRMManager from "react-lib/apis/manager/HRMManager";
import LineLoginManager from "react-lib/apps/IsHealth/Common/LineLoginManager";
import QUEManager from "react-lib/apis/manager/QUEManager";
import BILManager from "react-lib/apis/manager/BILManager";

import {
  askUserPermission,
  createNotificationSubscription,
} from "react-lib/apps/IsHealth/Common/push-notifications";

// Controller
import {
  CardClassifyController,
  CardNurseNoteController,
  CardPatientPanelController,
  CardProgressNoteController,
  CardDiagFormHistoryController,
} from "react-lib/apps/IsHealth/Nurse";
import { ChatController } from "react-lib/apps/IsHealth/Chat";
import AppointmentChatController from "react-lib/apps/IsHealth/Nurse/AppointmentChatController";
import QueueController from "react-lib/apps/QUE/QueueController";
import AppointmentDashboardController from "react-lib/apps/IsHealth/APP/AppointmentDashboardController";
import RuleListController from "react-lib/apps/IsHealth/Studio/RuleListController";
import DiagComposerController from "react-lib/apps/IsHealth/Studio/DiagComposerController";
import CardVitalSignController from "react-lib/apps/PHV/CardVitalSignController";
import BindHNPageController from "react-lib/apps/IsHealth/REG/BindHNPageController";
import BilPatientPanelController from "react-lib/apps/IsHealth/BIL/BilPatientPanelController";

import * as serviceWorker from "../serviceWorker";

import * as IsHealthCommon from "react-lib/apps/IsHealth/Common";
import PlatformLogin from "./PlatformLogin";
import EmailLogin from "react-lib/apps/IsHealth/Common/EmailLogin/EmailLogin";
import EmailRegister from "react-lib/apps/IsHealth/Common/EmailLogin/EmailRegister";
import EmailCallback from "react-lib/apps/IsHealth/Common/EmailLogin/EmailCallback";
import ResolveChat from "react-lib/apps/IsHealth/Common/ResolveChat";
import DashboardController from "react-lib/apps/IsHealth/Dashboard/DashboardController";
import REGChatController from "react-lib/apps/IsHealth/REG/ChatController";
import { vcMessenger } from "react-lib/compat/vc-websocket";

// import "./MainNurse.scss";
import "react-lib/apps/QUE/Time.css";
import "react-lib/apps/IsHealth/Common/FacebookLogin/LoginButton.css";
import config from "../config/config";
import CertLogin from "react-lib/apps/IsHealth/Common/CertLogin/CertLogin";
import CreateOA from "react-lib/apps/IsHealth/Platform/CreateOA";
import ShowDivision from "react-lib/apps/IsHealth/Platform/ShowDivision";
import UserProfile from "react-lib/apps/IsHealth/REG/UserProfile";

import Fingerprint2 from "fingerprintjs2";

import thMessage from "react-lib/localization/bplusClinic/th.json";
import enMessage from "react-lib/localization/bplusClinic/en.json";
import LocalizationWrapper from "react-lib/localization/LocalizationWrapper";

const THEME = {
  DEFAULT: "",
  PENTA: "penta",
  PENGUIN: "penguin",
};

const URL_PREFIX = {
  PENGUIN: "penguin",
  MYBPLUS: "mybplus",
};

const IMAGE = {
  ISHEALTH: process.env.PUBLIC_URL + "/static/images/logo-circle.png",
  IH: process.env.PUBLIC_URL + "/static/images/IH.png",
  QR_CODE: process.env.PUBLIC_URL + "/static/images/qr-code.png",
};

declare global {
  var requestIdleCallback: any;
  var FB: any;
}

class Platform extends Component<
  {
    apiToken: any;
    cookies: any;
    location: any;
    history: any;
  },
  PlatformI.State & {
    isHaveRequestOA: any;
    userProfileInfo: any;
    isVerified: any;
    userId: any;
    fullname: any;
    username: any;
    divisionName: any;
    finishedTriageLevel: any;
    landingLoading: any;
    providerInfo: any;
    isFacebookLogin: any;
    isLineLogin: any;
    isEmailLogin: any;
    facebookName: any;
    lineId: any;
    emailLogin: any;
    myDivisions: any;
    currentDivision: any;
    loginPathName: any;
    loginSearch: any;
    showMenuDivision: any;
    isOpenPopup: any;
    showMenuNoti: any;
    theme?: any;
    device_id?: any;
    subscription?: any;
    firebaseToken?: any;
    cookies?: any;
  }
> {
  mainScreen: any;
  controller: any;
  inputRef: any;
  inputQrRef: any;
  dpiManager: any;
  mixInManager: any;
  msgManager: any;
  ptmManager: any;
  regManager: any;
  prxManager: any;
  coreManager: any;
  userManager: any;
  queManager: any;
  hrmManager: any;
  bilManager: any;
  lineLoginManager: any;
  diagFormController: any;
  classifyController: any;
  nurseNoteController: any;
  patientPanelController: any;
  progressNoteController: any;
  ChatController: any;
  AppointmentChatController: any;
  ruleListController: any;
  diagComposerController: any;
  loginController: any;
  queueController: any;
  dashboardController: any;
  appointmentDashboardController: any;
  bindHNPageController: any;
  crdVitalSignController: any;
  regChatController: any;
  bilPatientPanelController: any;
  lastReadMessage: any;

  constructor(props: any) {
    super(props);

    this.mainScreen = React.createRef();
    this.controller = new PlatformController(
      () => {
        return this.state;
      },
      (state: any, callback?: any) => {
        this.setState(state, callback);
      },
      window
    );

    // const { cookies } = props;

    this.state = {
      division: Cookies.get("division_id"),
      apiToken: Cookies.get("apiToken"),
      userId: Cookies.get("userId"),
      fullname: Cookies.get("fullname"),
      username: Cookies.get("username"),
      divisionName: "",
      finishedTriageLevel: [],
      landingLoading: false,
      providerInfo: {},

      isFacebookLogin: false,
      isLineLogin: false,
      isEmailLogin: false,
      facebookName: "",
      lineId: "",
      emailLogin: "",

      myDivisions: [],
      currentDivision: null,
      // pathName that will redirect after login,
      //use when user open certain page but have no apiToken,
      // redirect to login and redirect back
      loginPathName: "",
      // for cnmi, no queue, so user should not be able to select division
      // hence, his will send division as url/?division=<ishealth_division_id>
      loginSearch: {},

      showMenuDivision: true,
      isOpenPopup: false,
      showMenuNoti: false,
      ...PlatformI.StateInitial,
      isHaveRequestOA: "",

      userProfileInfo: null,
      isVerified: false,
    };

    this.inputRef = createRef();
    this.inputQrRef = createRef();

    this.dpiManager = new DPIManager();
    this.mixInManager = new MixInManager();
    this.msgManager = new MSGManager();
    this.ptmManager = new PTMManager(Cookies.get("apiToken"));
    this.regManager = new REGManager();
    this.prxManager = new PRXManager();
    this.coreManager = new CoreManager();
    this.userManager = new UserManager();
    this.queManager = new QUEManager();
    this.hrmManager = new HRMManager();
    this.lineLoginManager = new LineLoginManager();
    this.bilManager = new BILManager();

    this.diagFormController = new CardDiagFormHistoryController({
      prxManager: this.prxManager,
    });

    this.classifyController = new CardClassifyController({
      coreManager: this.coreManager,
      prxManager: this.prxManager,
      userManager: this.userManager,
    });

    this.nurseNoteController = new CardNurseNoteController({
      prxManager: this.prxManager,
      ptmManager: this.ptmManager,
    });

    this.patientPanelController = new CardPatientPanelController({
      regManager: this.regManager,
      coreManager: this.coreManager,
      prxManager: this.prxManager,
    });

    this.progressNoteController = new CardProgressNoteController({
      dpiManager: this.dpiManager,
      prxManager: this.prxManager,
    });

    this.ChatController = new ChatController({
      msgManager: this.msgManager,
      prxManager: this.prxManager,
      regManager: this.regManager,
      coreManager: this.coreManager,
    });

    this.AppointmentChatController = new AppointmentChatController({
      msgManager: this.msgManager,
      prxManager: this.prxManager,
      regManager: this.regManager,
      coreManager: this.coreManager,
    });

    this.ruleListController = new RuleListController({
      prxManager: this.prxManager,
    });

    this.diagComposerController = new DiagComposerController({
      prxManager: this.prxManager,
    });

    this.loginController = new IsHealthCommon.LoginController({
      mixInManager: this.mixInManager,
    });

    this.queueController = new QueueController({
      queManager: this.queManager,
      prxManager: this.prxManager,
      hrmManager: this.hrmManager,
    });
    this.dashboardController = new DashboardController({
      prxManager: this.prxManager,
      coreManager: this.coreManager,
    });

    this.appointmentDashboardController = new AppointmentDashboardController({
      coreManager: this.coreManager,
      prxManager: this.prxManager,
    });

    this.bindHNPageController = new BindHNPageController({
      prxManager: this.prxManager,
    });

    this.crdVitalSignController = new CardVitalSignController({
      ptmManager: this.ptmManager,
      coreManager: null,
    });

    this.regChatController = new REGChatController({
      msgManager: this.msgManager,
      prxManager: this.prxManager,
      regManager: this.regManager,
      coreManager: this.coreManager,
    });

    this.bilPatientPanelController = new BilPatientPanelController({
      coreManager: this.coreManager,
      bilManager: this.bilManager,
      prxManager: this.prxManager,
    });

    this.lastReadMessage = null;

    vcMessenger.onMessage((message: any) => {
      console.log("========onMessage======");
      console.log(message);
      if (message["data_message_type"] === "MESSAGE") {
        if (this.mainScreen.chatTable) {
          if (this.mainScreen.chatTable.chatListRef) {
            this.mainScreen.chatTable.chatListRef.getChatList();
          }
          if (this.mainScreen.chatTable.chatBoxRef) {
            this.mainScreen.chatTable.chatBoxRef.receivedMessage({
              chatChannelId: parseInt(message["channel_id"]),
            });
          }
        }
        if (config.APPOINTMENT_CHAT_FUNCTION) {
          if (this.mainScreen.appointmentTable) {
            if (this.mainScreen.appointmentTable.chatListRef) {
              this.mainScreen.appointmentTable.chatListRef.getChatList();
            }
            if (this.mainScreen.appointmentTable.chatBoxRef) {
              this.mainScreen.appointmentTable.chatBoxRef.receivedMessage({
                chatChannelId: parseInt(message["channel_id"]),
              });
            }
          }
        }
        if (config.CLASSIFY_CHAT || config.AIRFLOW_CHAT) {
          if (this.mainScreen.qaChatTable) {
            if (this.mainScreen.qaChatTable.chatBoxRef) {
              this.mainScreen.qaChatTable.chatBoxRef.getLastMessage({
                chatChannelId: parseInt(message["channel_id"]),
              });
            }
            if (this.mainScreen.qaChatTable.chatBoxRef) {
              this.mainScreen.qaChatTable.chatBoxRef.receivedMessage({
                chatChannelId: parseInt(message["channel_id"]),
              });
            }
            this.mainScreen.qaChatTable.receivedMessage({
              chatChannelId: parseInt(message["channel_id"]),
            });
          }
        }
      } else if (message["data_message_type"] === "MESSAGE_READ") {
        if (this.lastReadMessage === message.message_id) {
          return;
        }
        this.lastReadMessage = message.message_id;
        if (this.mainScreen.chatTable) {
          if (this.mainScreen.chatTable.chatBoxRef) {
            this.mainScreen.chatTable.chatBoxRef.receivedMessageRead({
              chatChannelId: parseInt(message["channel_id"]),
              messageId: parseInt(message["message_id"]),
            });
          }
        }
        if (this.mainScreen.qaChatTable) {
          if (this.mainScreen.qaChatTable.chatBoxRef) {
            this.mainScreen.qaChatTable.chatBoxRef.getLastMessage({
              chatChannelId: parseInt(message["channel_id"]),
            });
          }
          this.mainScreen.qaChatTable.receivedMessage({
            chatChannelId: parseInt(message["channel_id"]),
          });
        }
        if (config.APPOINTMENT_CHAT_FUNCTION) {
          if (this.mainScreen.appointmentTable) {
            if (this.mainScreen.appointmentTable.chatBoxRef) {
              this.mainScreen.appointmentTable.chatBoxRef.receivedMessageRead({
                chatChannelId: parseInt(message["channel_id"]),
                messageId: parseInt(message["message_id"]),
              });
            }
          }
        }
      } else if (message["event"] === "RECLASSIFY") {
        if (this.mainScreen.qaChatTable) {
          this.mainScreen.qaChatTable.getListTriageLevel();
        }
        if (this.mainScreen.qaChatTable?.chatBoxRef) {
          this.mainScreen.qaChatTable.chatBoxRef?.getLastMessage({
            chatChannelId: parseInt(message["chat_channel"]),
          });
        }
        if (this.mainScreen.dashboard) {
          this.mainScreen.dashboard.refresh();
        } else {
          if (this.mainScreen.chatTable?.chatListRef) {
            this.mainScreen.chatTable.chatListRef.getChatList({
              type: "RECLASSIFY",
            });
          }
          this.mainScreen.chatTable?.getPatientInfo?.();
          // console.log("     this.props.history.push(/Chat/); ")
          // this.props.history.push("/Chat/"); //cause of video call close unexpected
          if (config.CALL_RECEIVED_RECLASSIFY) {
            let filterValue =
              this.mainScreen.chatTable?.chatListRef?.getFilterValue?.();
            this.mainScreen.chatTable?.chatBoxRef?.receivedReclassify?.({
              filterValue: filterValue,
              encounterId: parseInt(message["encounter"]),
              chatChannelId: parseInt(message["chat_channel"]),
            });
          }
        }
      } else if (message["event"] === "DIAG_FORM_UPDATED") {
        if (this.mainScreen.qaChatTable) {
          this.mainScreen.qaChatTable.updateDiagForm({
            patientId: parseInt(message["patient_id"]),
          });
        }
      } else if (message["event"] === "OA_REQUEST") {
        if (this.mainScreen.settings) {
          this.mainScreen.settings.getPendingList();
        }
      } else if (message["event"] === "OA_APPROVE") {
        if (this.mainScreen.settings) {
          this.mainScreen.settings.getMemberList();
        }
      } else if (message["event"] === "OA_UPDATE_ROLE") {
        if (this.mainScreen.settings) {
          this.mainScreen.settings.getUserRole();
        }
      } else if (message["event"] === "SHIPPING_ADDRESS_CHANGED") {
        if (this.mainScreen?.chatTable?.patientPanelRef?.getShippingAddress) {
          this.mainScreen.chatTable.patientPanelRef.getShippingAddress({
            shippingAddressId: message["shipping_address"],
            encounterId: message["encounter"],
            patientId: message["patient"],
          });
        }
      }
    });
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    // console.log(
    //   "componentDidUpdate this.props.location.pathname: ",
    //   this.props.location.pathname
    // );

    if (prevState.division !== this.state.division && this.state.division) {
      this.checkVerified();
    }

    if (this.state.apiToken && this.state.apiToken !== prevState.apiToken) {
      config.updateConfig();
    }

    if (
      !this.props.apiToken &&
      !Cookies.get("apiToken") &&
      this.props.location.pathname !== "/" &&
      this.props.location.pathname !== "/Login" &&
      this.props.location.pathname.includes("/resolve-chat") &&
      !this.props.location.pathname.includes("EmailLogin") &&
      !this.props.location.pathname.includes("EmailRegister") &&
      !this.props.location.pathname.includes("email-callback")
    ) {
      console.log(
        "componentDidUpdate Execute loginPathName",
        this.props.location.pathname
      );
      let params = new URLSearchParams(this.props.location.search);
      if (this.state.loginPathName !== this.props.location.pathname) {
        this.setState({
          loginPathName: this.props.location.pathname,
          loginSearch: params,
        });
      }
      if (!this.props.location.pathname.includes("/resolve-chat")) {
        console.log(" this.props.history.push(/);  ");
        this.props.history.push("/");
      }
    }
    if (
      (this.props.apiToken || Cookies.get("apiToken")) &&
      this.state.apiToken !== prevState.apiToken
    ) {
      this.getProfile(this.props.apiToken || Cookies.get("apiToken"));
    }
  }

  componentWillUpdate(nextProps: any, nextState: any) {
    // when division change, clear selected encounter(chat and patient Panel)
    // however, for cnmi, there is no queue, the chat and patientPanel is base on url only
    // when user get in from certain url, and no apiToken, redirect them to landing page
    // and keep the prev path in state.loginPathName, and redirect to that path after login succ
    // this func prevent redirect because division will change when user login
    // so I add !config.HIDE_QUEUE
    // if (!config.HIDE_QUEUE && this.state.division != nextState.division) {
    //   console.log("       this.props.history.push(/Chat/)")
    //   this.props.history.push("/Chat/");
    // }
  }

  componentDidMount() {
    this.controller.handleEvent({ message: "DidMount", params: {} });
    let currentURL = window.location.href;

    if (
      currentURL.includes(URL_PREFIX.PENGUIN) ||
      currentURL.includes(URL_PREFIX.MYBPLUS)
    ) {
      // Theme for penguin
      this.setState({ theme: THEME.PENGUIN });
      document.documentElement.setAttribute("data-theme", THEME.PENGUIN);
      Cookies.set("theme", THEME.PENGUIN, { path: "/" });
    } else {
      this.setState({ theme: THEME.PENTA });
      document.documentElement.setAttribute("data-theme", THEME.PENTA);
      Cookies.set("theme", THEME.PENTA, { path: "/" });
    }
    let userId = Cookies.get("userId")
      ? Cookies.get("userId")
      : this.state.userId;
    let apiToken = Cookies.get("apiToken")
      ? Cookies.get("apiToken")
      : this.state.apiToken;
    if (apiToken) {
      this.setWebsocket({ apiToken });
    }
    if (!this.state.userProfileInfo && apiToken) {
      this.getProfile(apiToken);
    }

    if (userId) {
      this.getProviderInfo({ userId });
      // this.lagacyLoginFirebase(userId);
    }

    this.handleGetFinishedTriageLevel();

    if (window.requestIdleCallback) {
      requestIdleCallback(() => {
        this.getFingerPrint();
      });
    } else {
      console.log("fallback with timeout");
      setTimeout(() => {
        this.getFingerPrint();
      }, 500);
    }

    // this.getDivision();

    console.log(
      "ComponenetDidMount this.state.division: ",
      this.state.division
    );
    // await this.checkVerified()

    let urlParams = new URLSearchParams(this.props.location.search);
    console.log(" urlParams", urlParams);
    let isHaveRequestOA = urlParams.get("requestOA");
    console.log(" Check ", isHaveRequestOA);

    if (isHaveRequestOA && apiToken) {
      const requestJoinOA = async () => {
        const [requestJoin, error3, network3] =
          await this.classifyController.postCreatejoinOARequest({
            apiToken: apiToken,
            token: isHaveRequestOA,
          });
        console.log(" requestJoin", requestJoin, error3);
        if (
          error3?.detail?.length === 1 &&
          error3?.detail[0] === "This user is already in this division."
        ) {
          alert(
            "เคุณร้องขอเข้าใช้งาน Official Account นี้แล้ว กรุณารอการตรวจสอบจากแอดมิน"
          );
          console.log(" error3", error3);
        } else if (error3) {
          alert("เกิดข้อผิดพลาดในการเข้าร่วม");
          console.log(" error3", error3);
        }
        this.navigateToCorrectPage(apiToken);
      };
      requestJoinOA();
    } else if (isHaveRequestOA) {
      console.log("setSTate  ", isHaveRequestOA);
      Cookies.set("ishaverequestoa", isHaveRequestOA, { path: "/" });
    } else {
      if (!window.location.pathname.includes("callback")) {
        Cookies.remove("ishaverequestoa", { path: "/" });
      }
    }
  }

  isDivisionVerified = async (id: any) => {
    //
    if (!Cookies.get("apiToken")) {
      console.log(" not API Token then return not getDivision Profile");
      return;
    }
    const [response, error, network] = await this.prxManager.getDivisionProfile(
      {
        apiToken: Cookies.get("apiToken"),
        division: id,
      }
    );

    // console.log('response' , response)
    if (response && response.items && response.items.length > 0) {
      if (response.items[0].verified) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  checkVerified = async () => {
    let verified = await this.isDivisionVerified(this.state.division);
    console.log("componentDidMount verified", verified);
    this.setState({ isVerified: verified });
  };

  handleGetFinishedTriageLevel = async () => {
    let data = { config_PRX_FINISHED_TRIAGE_LEVEL: null };
    const [res, error, network] =
      await this.classifyController.getFinishedTriageLevel({ data });
    this.setState({
      finishedTriageLevel:
        res.config_PRX_FINISHED_TRIAGE_LEVEL &&
        res.config_PRX_FINISHED_TRIAGE_LEVEL.split(" "),
    });
  };

  setWebsocket = ({ apiToken }: { apiToken?: any } = {}) => {
    vcMessenger.connect(
      "MSG",
      {
        screens: Cookies.get("division_id"),
        token: apiToken,
      },
      config.WS_HOST
    );
  };

  getFingerPrint = () => {
    var options = {};
    Fingerprint2.getPromise(options).then((components) => {
      // components is array of {key: 'foo', value: 'component value'}
      var values = components.map(function (component) {
        return component.value;
      });
      var device_id = Fingerprint2.x64hash128(values.join(""), 31);
      this.setState({ device_id: device_id });
      console.log("MP Fingerprint id:", device_id);
      // Get subscription endpoint object
      createNotificationSubscription()
        .then((subscription) => {
          this.setState({ subscription: subscription });

          console.log("MP Success get notification subscription..");
          console.log(subscription);
        })
        .catch((err) => {
          console.log("MP error getSubScriptionObejct");
          console.error(
            "Couldn't create the notification subscription",
            err,
            "name:",
            err.name,
            "message:",
            err.message,
            "code:",
            err.code
          );
        });
    });
  };

  // getDivision = async () => {
  //   if (!Cookies.get("apiToken")) {
  //     console.log(' apiToken not have then not getDivision')
  //     return;
  //   }
  //   const [response, error, network] = await this.prxManager.getDivisionHasUser(
  //     {
  //       apiToken: Cookies.get("apiToken")
  //     }
  //   );

  //   if (response && response.items.length > 0) {
  //     if (response.items[0].division && "id" in response.items[0].division ) {
  //       Cookies.set("division_id", response.items[0].division.id, {
  //         path: "/"
  //       });
  //       this.setState({ divisionId: response.items[0].division.id });
  //     }

  //     if (response.items[0].division && "name" in response.items[0].division) {
  //       console.log(" set DivisionNAme Cookies")
  //       Cookies.set("divisionName", response.items[0].division.name, {
  //         path: "/"
  //       });
  //       this.setState({ divisionName: response.items[0].division.name });
  //     }
  //   } else {
  //     alert(
  //       "ผู้ใช้งานนี้ไม่มี โรงพยาบาล (Division) ของระบบ กรุณาติดต่อ ผู้ดูแล"
  //     );
  //   }
  // };

  loadMydivision = async (divisionId: any) => {
    console.log("loadMydivision divisionData: ");
    let apiToken = Cookies.get("apiToken")
      ? Cookies.get("apiToken")
      : this.state.apiToken;
    if (!apiToken) {
      return;
    }
    const [response, error, network] = await this.prxManager.getDivisionHasUser(
      { apiToken: apiToken }
    );

    const divisionData = {
      division: {
        id: "create_oa",
        code: "create_oa",
        name: "+ สร้าง Official Account",
      },
    };

    if (divisionData && response?.items && config.CREATE_OFFICIAL_ACCOUNT) {
      response.items.push(divisionData);
    }
    if (response && response.items.length > 0) {
      console.log("my division", response.items);
      var myDivisions = [];
      for (let d of response.items) {
        var dupicate = false;
        for (let m of myDivisions) {
          if (m.id === d.division.id) {
            dupicate = true;
            break;
          }
        }
        if (!dupicate) {
          myDivisions.push(d.division);
        }
      }
      // Prepare data to dropdown element
      var options = [];
      for (var division of myDivisions) {
        options.push({
          ...division,
          text: division.name,
          value: division.code,
        });
      }

      console.log(options);
      this.setState({ myDivisions: options });

      // Initial division
      var targetDivision = null;
      // from url params
      if (this.props.location.search) {
        let urlParams = new URLSearchParams(this.props.location.search);
        let divisionParams = urlParams.get("division");
        const selected = options.find(
          (division) => division.id.toString() === divisionParams
        );
        if (selected) {
          targetDivision = selected;
        }
      }
      // from cookies
      let selectedDivision = Cookies.get("division_id");
      if (selectedDivision) {
        const selected = options.find(
          (division) => division.id.toString() === selectedDivision
        );
        if (selected) {
          targetDivision = selected;
        }
      }
      // No dafault division
      if (!targetDivision && options.length > 0) {
        targetDivision = options[0];
        Cookies.set("division_id", targetDivision.id, { path: "/" });
      }

      if (targetDivision) {
        this.setState({
          currentDivision: divisionId ? { value: divisionId } : targetDivision,
          division: targetDivision.id,
        });
        vcMessenger.reconnect({
          screens: targetDivision.id,
          token: Cookies.get("apiToken")
            ? Cookies.get("apiToken")
            : this.state.apiToken,
        });
      } else {
        return console.log("There aren't division");
        // alert("ผู้ใช้งานนี้ไม่มี โรงพยาบาล (Division) ของระบบ กรุณาติดต่อ ผู้ดูแล");
      }
    }
  };

  reconnectWs = () => {
    vcMessenger.reconnect({
      screens: this.state.division,
      token: Cookies.get("apiToken")
        ? Cookies.get("apiToken")
        : this.state.apiToken,
    });
  };

  handleChangeDivision = (event: any, data: any) => {
    console.log("handleChangeDivision ", data, this.state.myDivisions);
    let sValue = data.value;
    const selected = this.state.myDivisions.find(
      (division: any) => division.value === sValue
    );
    if (selected) {
      if (selected.id === "create_oa") {
        this.props.history.push("/CreateOA");
        return;
      } else if (this.state.currentDivision?.value === "create_oa") {
        this.props.history.goBack();
      }

      this.setState({
        currentDivision: selected,
        division: selected.id,
      });
      vcMessenger.reconnect({
        screens: selected.id,
        token: Cookies.get("apiToken")
          ? Cookies.get("apiToken")
          : this.state.apiToken,
      });

      console.log("selected", selected);
      Cookies.set("division_id", selected.id, { path: "/" });
      Cookies.set("divisionName", selected.name, { path: "/" });
      if (
        window.location.pathname.includes("/CreateOA") ||
        window.location.pathname.includes("/Profile")
      ) {
        this.props.history.push("/Chat");
      }
    }
  };

  handleLoginSuccess = async (response: any) => {
    let isHaveRequestOA = Cookies.get("ishaverequestoa");
    console.log("handleLoginSuccess isHaveRequestOA", isHaveRequestOA);
    if (isHaveRequestOA && response?.token) {
      const [requestJoin, error3, network3] =
        await this.classifyController.postCreatejoinOARequest({
          apiToken: response?.token,
          token: isHaveRequestOA,
        });
      console.log(" requestJoin", requestJoin, error3);
      if (
        error3?.detail?.length === 1 &&
        error3?.detail[0] === "This user is already in this division."
      ) {
        alert(
          "เคุณร้องขอเข้าใช้งาน Official Account นี้แล้ว กรุณารอการตรวจสอบจากแอดมิน"
        );
        console.log(" error3", error3);
      } else if (error3) {
        alert("เกิดข้อผิดพลาดในการเข้าร่วม");
        console.log(" error3", error3);
      }
      Cookies.remove("ishaverequestoa", { path: "/" });
    }

    console.log(
      " handleLoginSuccess this.state.loginPathName,",
      this.state.loginPathName,
      response
    );
    // if (response && response.profile && response.profile.userId) {
    //   this.lagacyLoginFirebase(response.profile.userId);
    // }

    // From Facebook
    Cookies.set("fullname", response?.profile?.fullname, {
      path: "/",
    });
    Cookies.set("username", response?.profile?.username, {
      path: "/",
    });
    Cookies.set("apiToken", response.token, { path: "/" });
    Cookies.set("userId", response?.profile?.userId, { path: "/" });
    this.getProviderInfo({ userId: response?.profile?.userId });
    this.setState({
      userId: response?.profile?.userId,
      apiToken: response.token,
    });
    this.setWebsocket({ apiToken: response.token });
    this.setState({ landingLoading: false });
    console.log(this.state.loginPathName, "handleLoginSuccess");

    if (this.state.loginPathName) {
      console.log(
        "handleLoginSuccess push history with : ",
        this.state.loginPathName
      );
      this.props.history.push({
        pathname: this.state.loginPathName,
        search: `?${this.state.loginSearch.toString()}`,
      });
      console.log('Clear loginPathName to ""');
      return this.setState({ loginPathName: "", loginSearch: {} });
    }

    // Condition 1
    // New User goto Profile ยังเช็คไม่ได้ ให้ไปอยู่ใน condition สุดท้าย

    // Condition 2
    // Old Have pending Request goto Waiting
    // Old Have Reqeust Key goto Waiting and send Request

    // Condition 3
    // Old User without Request ; goto CreateOA

    // Condition 4
    // Old User with Division Goto Dashboard (no waiting)

    console.log(" handleLoginSuccess response", response);
    this.navigateToCorrectPage(response.token);
  };

  lagacyLoginFirebase = (userId: any) => {
    this.controller.functions
      .httpsCallable("createTokenFromUser")(userId.toString())
      .then((result: any) => {
        console.log("Token", result.data);
        this.setState({ firebaseToken: result.data });
        this.controller.app
          .auth()
          .signInWithCustomToken(result.data)
          .catch(function (error: any) {
            console.log(error);
          });
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  handleRefreshUserInfo = () => {
    let userId = Cookies.get("userId")
      ? Cookies.get("userId")
      : this.state.userId;
    let apiToken = Cookies.get("apiToken")
      ? Cookies.get("apiToken")
      : this.state.apiToken;
    if (userId) {
      this.getProviderInfo({ userId });
    }
    if (apiToken) {
      this.getProfile(apiToken);
    }
  };

  navigateToCorrectPage = async (apiToken: any) => {
    const [userProfile, error, network] =
      await this.classifyController.getOAUserProfile({
        apiToken: apiToken,
      });
    console.log(" userProfile", userProfile);
    // if (!userProfile?.position) {
    //   console.log("handleLoginSuccess Goto /Profile  from Login success");
    //   // position Incomplete
    //   this.props.history.push({ pathname: "/Profile" });
    //   return;
    // }

    // API that check request
    // Logic Same Condition##Goto #1
    const [listDivision, error2, network2] =
      await this.classifyController.getListOARequestCheck({
        apiToken: apiToken,
      });
    console.log(" listDivision", listDivision, error2, network2);
    if (listDivision?.items?.length > 0) {
      let nameList = listDivision?.items?.map(
        (item: any) => JSON.parse(item.division_profile.extra).name
      );
      // Goto Waiting if have pending...
      console.log("handleLoginSuccess Goto /ShowDivision  from Login success");
      this.props.history.push({ pathname: "/ShowDivision" });
      return;
    }

    const [divistionHasUser, divistionHasUserError, divistionHasUserNetwork] =
      await this.prxManager.getDivisionHasUser({ apiToken: apiToken });

    if (divistionHasUser?.total > 0) {
      console.log("handleLoginSuccess Goto /Dashboard  from Login success");
      this.props.history.push({ pathname: "/Chat" });
      return;
    } else {
      console.log("handleLoginSuccess Goto /CreateOA  from Login success");
      this.props.history.push({ pathname: "/CreateOA" });
      return;
    }
  };

  handleLogout = async (backToLandingPage?: boolean) => {
    console.log(" handleLogout");
    // Logout firebase
    this.controller.app.auth().signOut();

    serviceWorker.unregister();
    if (window.FB) {
      window.FB.logout(function (response: any) {
        console.log(" user Logout Facebook !");
      });
    }
    console.log("unregister service worker");
    // Remove data
    Cookies.remove("fullname", { path: "/" });
    Cookies.remove("username", { path: "/" });
    Cookies.remove("apiToken", { path: "/" });
    Cookies.remove("division_id", { path: "/" });
    Cookies.remove("userId", { path: "/" });
    let params = new URLSearchParams(this.props.location.search);
    this.setState({
      isLineLogin: false,
      lineId: "",
      isFacebookLogin: false,
      facebookName: "",
      isEmailLogin: false,
      emailLogin: "",
    });

    console.log(
      'handleLogout      Cookies.get("apiToken: ',
      Cookies.get("apiToken")
    );
    console.log('handleLogout  Cookies.get("userId")', Cookies.get("userId"));
    console.log(
      "handleLogout this.props.location.pathname: ",
      this.props.location.pathname
    );
    console.log("handleLogout params: ", params);
    this.setState({
      apiToken: Cookies.get("apiToken"),
      userId: Cookies.get("userId"),
      loginPathName: "",
      loginSearch: params,
    });
    this.props.history.push({ pathname: "/", state: {} });
  };

  getRightMenuItem = () => {
    if (
      this.state.apiToken ||
      Cookies.get("apiToken") ||
      (this.props.location &&
        this.props.location.state &&
        this.props.location.state.userId)
    ) {
      return (
        <Menu.Item position="right">
          {this.state.showMenuNoti && (
            <>
              <Icon
                name="bell"
                style={{
                  color: "white",
                  fontSize: "1.4em",
                  marginTop: "2px",
                }}
              />
              <div
                style={{
                  width: "8px",
                  height: "8px",
                  backgroundColor: "red",
                  borderRadius: "50%",
                  position: "absolute",
                  marginLeft: "14px",
                  marginTop: "-8px",
                }}
              ></div>
              <h4
                style={{
                  color: "white",
                  paddingLeft: "5px",
                  marginBottom: "25px",
                  marginRight: "25px",
                }}
              >
                {"แจ้งเตือน"}
              </h4>
            </>
          )}
          {this.state.userProfileInfo?.first_name ? (
            <div
              style={{ display: "contents", cursor: "pointer" }}
              onClick={() => {
                // console.log(" this.props.location", this.props.location)
                if (!this.props.location?.pathname?.includes("/Profile")) {
                  this.props.history.push("/Profile");
                }
              }}
            >
              <Icon
                name="user circle"
                style={{ color: "white", fontSize: "1.4em", marginTop: "2px" }}
              />
              <h4
                style={{
                  color: "white",
                  paddingLeft: "5px",
                  marginBottom: "26px",
                }}
              >
                {`${this.state.userProfileInfo?.fullname}`}
              </h4>
            </div>
          ) : (
            ""
          )}
          <Button
            onClick={(e: any) => {
              this.handleLogout();
            }}
          >
            Logout
          </Button>
        </Menu.Item>
      );
    } else if (this.props.location.pathname === "/") {
      return (
        <Menu.Item position="right">
          <Button
            onClick={(e) => {
              this.props.history.push("/Login");
            }}
          >
            Login/Register
          </Button>
        </Menu.Item>
      );
    } else {
      return null;
    }
  };

  handleHomeIconClick = () => {
    this.props.history.push("/Chat");
  };

  getLeftMenuItem = () => {
    if (this.props.location.pathname === "/") {
      return null;
    } else {
      return (
        <Menu.Item>
          <h2 onClick={this.handleHomeIconClick}>{`IsHealth`}</h2>
        </Menu.Item>
      );
    }
  };

  getDivisionMenuItem = () => {
    if (!this.state.showMenuDivision) {
      return <></>;
    }
    if (
      this.state.apiToken ||
      Cookies.get("apiToken") ||
      (this.props.location &&
        this.props.location.state &&
        this.props.location.state.userId)
    ) {
      return (
        <>
          <Menu.Item>
            <Dropdown
              style={{ color: "white", backgroundColor: "transparent" }}
              button
              className={"icon"}
              floating
              labeled
              icon={"hospital"}
              options={this.state.myDivisions}
              search
              value={
                this.state.currentDivision
                  ? this.state.currentDivision.value
                  : ""
              }
              placeholder={"Select Division"}
              onChange={this.handleChangeDivision}
              onOpen={() => {
                this.setState({
                  currentDivision: null,
                  division: null,
                });
              }}
            />
          </Menu.Item>
          <Menu.Item>
            <Modal
              className="qa-chat-table"
              onClose={() => this.setState({ isOpenPopup: false })}
              onOpen={() => this.setState({ isOpenPopup: true })}
              open={this.state.isOpenPopup}
              closeIcon
              trigger={
                config.COPY_LINK && (
                  <Button
                    style={{
                      border: "1px solid white",
                      padding: "7px 12px 8px 18px",
                      borderRadius: "9px",
                      cursor: "pointer",
                    }}
                  >
                    Copy Link
                    <Icon
                      name="linkify"
                      style={{
                        marginLeft: "8px",
                        transform: "rotate(45deg)",
                      }}
                    />
                  </Button>
                )
              }
            >
              <div style={{ padding: "40px" }}>
                <Header as="h2" className="font-primary">
                  Share Link
                </Header>

                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "200px auto",
                    alignItems: "center",
                  }}
                >
                  <div style={{ margin: "0 20px 0 40px" }}>
                    <img
                      alt="isHealth"
                      src={IMAGE.ISHEALTH}
                      style={{
                        borderRadius: "50%",
                        width: "110px",
                        height: "110px",
                      }}
                    />
                  </div>
                  <div style={{ display: "grid" }}>
                    <div style={{ lineHeight: "1.8rem" }}>
                      คุณสามารถโปรโมตบัญชีทางการเพื่อให้มีเพื่อนเพิ่มขึ้นได้หลายวิธี
                      เช่น แชร์ Link และ QR Code
                    </div>
                    <div style={{ lineHeight: "1.8rem" }}>
                      ให้เพื่อนของบัญชีทางการเพื่อสร้างการเข้าถึงผู้ใช้ได้มากขึ้น
                      ขอแนะนำให้โปรโมต
                    </div>
                    <div style={{ lineHeight: "1.8rem" }}>
                      ให้บัญชีทางการมีเพื่อนมากๆ
                      แล้วใช้ประโยชน์อย่างมีประสิทธิภาพ
                    </div>
                  </div>
                </div>
                <Header as="h3" style={{ color: "#4F4F4F" }}>
                  โปรโมตผ่านอีเมล หรือโซเซียลมีเดีย
                </Header>
                <Divider />
                <Form style={{ marginLeft: "90px", marginTop: "30px" }}>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "170px auto",
                      alignItems: "baseline",
                      paddingBottom: "20px",
                    }}
                  >
                    <div style={{ fontWeight: "bold", color: "#4F4F4F" }}>
                      Official Account link
                    </div>
                    <div>
                      <div style={{ display: "grid" }}>
                        <div>
                          <Input
                            ref={this.inputRef}
                            value={`${"https://ishealth.app"}/OA/${
                              this.state.division
                            }${"/?openExternalBrowser=1"}`}
                            readOnly={true}
                            style={{ marginRight: "10px", width: "40%" }}
                            className="font-blue"
                          />
                          <Icon
                            name="copy"
                            className="font-primary"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              var copyText = this.inputRef.current;
                              copyText.select();
                              document.execCommand("copy");
                            }}
                          />
                        </div>
                        <div style={{ marginTop: "15px" }}>
                          ผู้ใช้สามารถเพิ่มบัญชีของคุณเป็นเพื่อนได้ด้วยการกดลิงก์นี้
                          คัดลอกลิงก์แล้วแชร์ให้ทุกคนเลย
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "170px auto",
                      alignItems: "baseline",
                    }}
                  >
                    <div style={{ fontWeight: "bold", color: "#4F4F4F" }}>
                      QR Code
                    </div>
                    <div>
                      <div style={{ display: "grid" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            paddingBottom: "10px",
                          }}
                        >
                          <img
                            alt="isHealth"
                            src={IMAGE.QR_CODE}
                            style={{ width: "80px", height: "80px" }}
                          />
                          <Button
                            className="btn-primary"
                            style={{ marginLeft: "20px" }}
                          >
                            ดาวน์โหลด{" "}
                            <Icon
                              name="download"
                              style={{ marginLeft: "5px" }}
                            />
                          </Button>
                        </div>
                        <div>
                          <Input
                            ref={this.inputQrRef}
                            value={`<img src="https://qr-official.line.me/sid/M/xxx.png">`}
                            readOnly={true}
                            style={{ marginRight: "10px", width: "60%" }}
                            className="font-blue"
                          />
                          <Icon
                            name="copy"
                            className="font-primary"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              var copyText = this.inputQrRef.current;
                              copyText.select();
                              document.execCommand("copy");
                            }}
                          />
                        </div>
                        <div style={{ display: "grid", marginTop: "10px" }}>
                          <div style={{ lineHeight: "1.8rem" }}>
                            ผู้ใช้สามารถเพิ่มบัญชีของคุณเป็นเพื่อนได้ด้วยการสแกนโค้ดนี้
                            ดาวน์โหลดคิวอาร์โค้ด
                          </div>
                          <div style={{ lineHeight: "1.8rem" }}>
                            หรือคัดลอกแท็ก HTML แล้วแชร์ให้ทุกคนเลย
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </Modal>
          </Menu.Item>
        </>
      );
    } else {
      return null;
    }
  };

  handleCredentialLoginSuccess = async (response: any) => {
    Cookies.set("fullname", response?.profile?.fullname, {
      path: "/",
    });
    Cookies.set("username", response?.profile?.username, {
      path: "/",
    });
    Cookies.set("apiToken", response.token, { path: "/" });
    Cookies.set("userId", response?.profile?.userId, { path: "/" });
    this.getProviderInfo({ userId: response?.profile?.userId });
    this.setState({
      userId: response?.profile?.userId,
      apiToken: response.token,
    });
    this.setWebsocket({ apiToken: response.token });
    this.setState({ landingLoading: false });
    console.log(this.state.loginPathName, "handleCredentialLoginSuccess");
    if (this.state.loginPathName) {
      this.props.history.push({
        pathname: this.state.loginPathName,
        search: `?${this.state.loginSearch.toString()}`,
      });
      return this.setState({ loginPathName: "", loginSearch: {} });
    }
  };

  handleLineLoginSuccess = (props: any) => {
    if (props.pentaToken) {
      this.setState({ landingLoading: true });
      setTimeout(() => {
        this.handleLoginSuccess(props.pentaToken);
      }, 0);

      this.setState({ isLineLogin: true, lineId: props.verify.name });
    } else if (props.token) {
      this.setState({ landingLoading: true });
      setTimeout(() => {
        this.handleLoginSuccess(props);
      }, 0);

      this.setState({ isLineLogin: true, lineId: props.verify.name });
    } else {
      this.setState({ landingLoading: false });
      this.props.history.replace({ pathname: "/Login" });
    }
  };

  handleLineLoginFailed = (props: any) => {
    this.setState({ landingLoading: false });
    console.log("handleLineLoginFailed: ", props);
    this.props.history.replace({ pathname: "/Login" });
  };

  getProviderInfo = async ({ userId }: { userId?: any } = {}) => {
    const [response, error] = await this.queueController.getProviderInfo({
      apiToken: Cookies.get("apiToken")
        ? Cookies.get("apiToken")
        : this.state.apiToken,
      userId,
    });
    // After getting provider info, also login Firebase
    this.controller.handleEvent({
      message: "LoginFirebase",
      params: {
        userId: userId,
      },
    });
    console.log(response, "INFO", userId);
    if (response) {
      this.setState({ providerInfo: response });
      Cookies.set("providerId", response.id, { path: "/" });
    } else {
      this.setState({ providerInfo: {} });
    }
  };

  handleGoToRegister = async () => {
    // pass false variable for  not back to Landing Page
    this.handleLogout(false);
    this.props.history.push({
      pathname: "/afterLogin",
      state: { userId: 0 },
    });
  };

  getProfile = async (apiToken: any) => {
    console.log("GET_PROFILE", apiToken, this.state.userProfileInfo);
    const [data, error, network] = await this.classifyController.getUserProfile(
      {
        apiToken: apiToken || Cookies.get("apiToken"),
      }
    );
    this.setState({
      userProfileInfo: data || {},
    });
  };

  render() {
    // console.log("MainNurse this.props", this.props);
    // console.log("MainNurse this.state", this.state);
    // console.log("MainNurse this", this);
    return (
      <IntlProvider
        locale="th-TH"
        messages={
          window?.navigator?.language?.includes("th") ? thMessage : enMessage
        }
      >
        {/* <CookiesProvider> */}
        <div
          style={{ overflowY: "hidden", height: "100vh" }}
          className="main-nurse"
        >
          <Switch>
            <Route
              exact
              path="/EmailLogin/"
              render={(props) => {
                return <EmailLogin {...props} />;
              }}
            />
            <Route
              exact
              path="/EmailRegister/"
              render={(props) => {
                return <EmailRegister {...props} />;
              }}
            />
            <Route
              exact
              path="/email-callback/"
              render={(props) => {
                return (
                  <EmailCallback
                    onLoginSuccess={this.handleLoginSuccess}
                    onLoginFailed={this.handleLineLoginFailed}
                    languageUX={this.state.userSelectedLanguage}
                  />
                );
              }}
            />
            <Route
              exact
              path="/resolve-chat/"
              render={(props) => {
                return (
                  <ResolveChat
                    {...props}
                    apiToken={this.state.apiToken || ""}
                    controller={this.ChatController}
                    onLoginSuccess={this.handleCredentialLoginSuccess}
                    languageUX={this.state.userSelectedLanguage}
                  />
                );
              }}
            />
            <Route exact path="/callback/">
              <IsHealthCommon.LineCallBack
                onLoginSuccess={this.handleLineLoginSuccess}
                onLoginFailed={this.handleLineLoginFailed}
                languageUX={this.state.userSelectedLanguage}
              />
            </Route>
            <Route exact path="/Login">
              <PlatformLogin
                controller={this.loginController}
                lineLoginManager={this.lineLoginManager}
                onLoginSuccess={this.handleLoginSuccess}
                onHaveApiToken={() => {
                  console.log(" Goto Profile !! from PlatformLogin ");
                  this.props.history.push("/Profile");
                }}
                facebookLogin={(name) => {
                  this.setState({
                    isFacebookLogin: true,
                    facebookName: name,
                  });
                }}
                subscription={this.state.subscription}
                device_id={this.state.device_id}
                languageUX={this.state.userSelectedLanguage}
              />
            </Route>
            <Route exact path="/cert-login">
              <CertLogin onLoginSuccess={this.handleLoginSuccess} />
            </Route>
            <Route exact path="/ShowDivision">
              <div className="MainScreen">
                <Menu secondary className="topbar-menu">
                  {this.getLeftMenuItem()}
                  {this.getDivisionMenuItem()}
                  {this.getRightMenuItem()}
                </Menu>
                <ShowDivision
                  controller={this.classifyController}
                  apiToken={this.state.apiToken}
                  myDivisions={this.state.myDivisions}
                  languageUX={this.state.userSelectedLanguage}
                />
              </div>
            </Route>
            <Route exact path="/Profile">
              <div className="MainScreen">
                <Menu secondary className="topbar-menu">
                  {this.getLeftMenuItem()}
                  {this.getDivisionMenuItem()}
                  {this.getRightMenuItem()}
                </Menu>
                <UserProfile
                  controller={this.classifyController}
                  isJustLogin={
                    this.state.isLineLogin ||
                    this.state.isFacebookLogin ||
                    this.state.isEmailLogin
                  }
                  isLineLogin={this.state.isLineLogin}
                  lineId={this.state.lineId}
                  isFacebookLogin={this.state.isFacebookLogin}
                  facebookName={this.state.facebookName}
                  isEmailLogin={this.state.isEmailLogin}
                  emailLogin={this.state.emailLogin}
                  myDivisions={this.state.myDivisions}
                  onGetDivision={this.loadMydivision}
                  onRefreshUserInfo={this.handleRefreshUserInfo}
                  languageUX={this.state.userSelectedLanguage}
                />
              </div>
            </Route>
            {config.CREATE_OFFICIAL_ACCOUNT && (
              <Route exact path="/CreateOA">
                <div className="MainScreen">
                  <Menu secondary className="topbar-menu">
                    {this.getLeftMenuItem()}
                    {this.getDivisionMenuItem()}
                    {this.getRightMenuItem()}
                  </Menu>
                  <CreateOA
                    controller={this.classifyController}
                    onEvent={this.controller.handleEvent}
                    apiToken={this.state.apiToken}
                    userRoleList={this.state.userRoleList}
                    onSetShowMenuDivision={(show: any) => {
                      this.setState({ showMenuDivision: show });
                    }}
                    onSetShowMenuNoti={(show: any) => {
                      this.setState({ showMenuNoti: show });
                    }}
                    onGetDivision={this.loadMydivision}
                    userFullName={
                      this.state.providerInfo?.employee_info?.full_name
                    }
                    myDivisions={this.state.myDivisions}
                    languageUX={this.state.userSelectedLanguage}
                  />
                </div>
              </Route>
            )}

            <Route
              path="/"
              render={(props) => {
                if (this.state.apiToken) {
                  return (
                    <MainScreen
                      {...props}
                      ref={(ref) => (this.mainScreen = ref)}
                      getLeftMenuItem={this.getLeftMenuItem}
                      getDivisionMenuItem={this.getDivisionMenuItem}
                      getRightMenuItem={this.getRightMenuItem}
                      chatController={this.ChatController}
                      appointmentChatController={this.AppointmentChatController}
                      ruleListController={this.ruleListController}
                      diagComposerController={this.diagComposerController}
                      diagFormController={this.diagFormController}
                      patientPanelController={this.patientPanelController}
                      nurseNoteController={this.nurseNoteController}
                      progressNoteController={this.progressNoteController}
                      bindHNPageController={this.bindHNPageController}
                      crdVitalSignController={this.crdVitalSignController}
                      appointmentDashboardController={
                        this.appointmentDashboardController
                      }
                      classifyController={this.classifyController}
                      apiToken={this.state.apiToken}
                      onGetDivision={this.loadMydivision}
                      division={this.state.division}
                      userId={this.state.userId}
                      fullname={this.state.fullname}
                      username={this.state.username}
                      finishedTriageLevel={this.state.finishedTriageLevel}
                      queueController={this.queueController}
                      dashboardController={this.dashboardController}
                      regChatController={this.regChatController}
                      bilPatientPanelController={this.bilPatientPanelController}
                      onReconnectWs={this.reconnectWs}
                      onEvent={this.controller.handleEvent}
                      patientSegmentList={this.state.patientSegmentList}
                      diagFormDetail={this.state.diagFormDetail}
                      divisionProfileList={this.state.divisionProfileList}
                      isLoadingFormAnalytics={this.state.isLoadingFormAnalytics}
                      myDivisions={this.state.myDivisions}
                      storage={this.controller.storage}
                      getDivision={this.getDivision}
                      onSetState={(state: any) => this.setState({ ...state })}
                      isVerified={this.state.isVerified}
                      goToRegister={this.handleGoToRegister}
                      vaccineInfo={this.state.vaccineInfo}
                      appointmentList={this.state.appointmentList}
                      generateUserModal={this.state.generateUserModal}
                      generateUserResult={this.state.generateUserResult}
                      //Feature #54627
                      patientADR={this.state.patientADR}
                      languageUX={this.state.userSelectedLanguage}
                    />
                  );
                } else {
                  return (
                    <IsHealthCommon.LandingPage
                      detail={
                        <>
                          <span className="header darkblue">Is</span>
                          <span className="header">Health Platform</span>
                          <div>
                            แพลตฟอร์มสำหรับใช้ในการสื่อสาร
                            ประเมินและให้คำปรึกษาผู้ป่วยผ่านระบบออนไลน์
                          </div>
                          <br />
                          <div>
                            สามารถตรวจสอบข้อมูลคิวผู้ป่วยที่จำเป็นจะต้องปรึกษาแพทย์
                            และตรวจสอบผลการบันทึกแบบคัดกรองตนเองของผู้ป่วย
                            ข้อมูลโน๊ตการพยาบาล
                            และบันทึกโน๊ตสำหรับแพทย์เพิ่มเติม ผ่านระบบออนไลน์
                          </div>
                          <br />
                          <div>
                            <Button
                              content="คลิกเพื่อเริ่มต้นใช้งานระบบ"
                              size="huge"
                              className="whiteBasicButton"
                              onClick={() =>
                                //   (window.location.pathname = "/Login")
                                this.props.history.push("/Login")
                              }
                            />
                          </div>
                        </>
                      }
                      languageUX={this.state.userSelectedLanguage}
                    />
                    // <div className="LandingPage">
                    //   <PlatformLogin
                    //     facebookLogin={(name) => {
                    //       this.setState({
                    //         isFacebookLogin: true,
                    //         facebookName: name,
                    //       });
                    //     }}
                    //     subscription={this.state.subscription}
                    //     device_id={this.state.device_id}
                    //     controller={this.loginController}
                    //     lineLoginManager={this.lineLoginManager}
                    //     onLoginSuccess={this.handleLoginSuccess}
                    //     onHaveApiToken={() => {
                    //       console.log(" Goto Profile !! from PlatformLogin ");
                    //       this.props.history.push("/Profile");
                    //     }}
                    //   >
                    //     <div className="detail">
                    //       <span className="header darkblue">Is</span>
                    //       <span className="header">Health Platform</span>
                    //       <div>
                    //         แพลตฟอร์มสำหรับใช้ในการสื่อสาร
                    //         ประเมินและให้คำปรึกษาผู้ป่วยผ่านระบบออนไลน์
                    //       </div>
                    //       <br />
                    //       <div>
                    //         สามารถตรวจสอบข้อมูลคิวผู้ป่วยที่จำเป็นจะต้องปรึกษาแพทย์
                    //         และตรวจสอบผลการบันทึกแบบคัดกรองตนเองของผู้ป่วย
                    //         ข้อมูลโน๊ตการพยาบาล
                    //         และบันทึกโน๊ตสำหรับแพทย์เพิ่มเติม ผ่านระบบออนไลน์
                    //       </div>
                    //       <br />
                    //     </div>
                    //   </PlatformLogin>
                    // </div>
                    
                  );
                }
              }}
            />
          </Switch>
        </div>
        {/* </CookiesProvider> */}
      </IntlProvider>
    );
  }
}
export default LocalizationWrapper(injectIntl(withRouter(Platform)))
