import React from "react";
import {Input, Form } from "semantic-ui-react";
import { useIntl } from "react-intl";

interface Props {
  diagnosis: string;
  doctor: string;
}
const styles = {
  qmlCardStyle: {
    marginBottom: "15px"
  }
};

const CardDiagnosisReadOnlyDoctor: React.FC<Props> = (props) => {
  let content = (
    <Form>
      <Form.Group inline>
        <Form.Field width={6}>
          <label> Diagnosis </label>
          <Input fluid value={props.diagnosis} />
        </Form.Field>
        <Form.Field width={6}>
          <label>Doctor</label>
          <Input fluid value={props.doctor} />
        </Form.Field>
      </Form.Group>
    </Form>
  );

  return content;
};

export default CardDiagnosisReadOnlyDoctor;
