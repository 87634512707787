import { TDocumentDefinitions } from "pdfmake/interfaces";

import { splitStringNewLine } from "react-lib/apps/HISV3/common/CommonInterface";

import HeaderSummaryReportForm from "./HeaderSummaryReportForm";

const FormPriceChangeRiskReport = async (props: any): Promise<TDocumentDefinitions> => {
  console.log("Props FormPriceChangeRiskReport: ", props);

  const headerForm = await HeaderSummaryReportForm({
    data: props.reportPriceChangeData?.params,
    header: [
      {
        alignment: "center",
        bold: true,
        fontSize: 15,
        text: "รายงานค่ารักษาที่มีการเปลี่ยนแปลงราคาโดยแพทย์",
      },
      {
        alignment: "center",
        bold: true,
        fontSize: 15,
        text: `ประเภท : ${props.reportPriceChangeData?.params?.encounter_type_label}`,
      },
      {
        alignment: "center",
        fontSize: 15,
        marginBottom: 5,
        text: `คลินิก : ${props.reportPriceChangeData?.params?.division_name} วันที่ ${props.reportPriceChangeData?.params?.start_date} ถึง ${props.reportPriceChangeData?.params?.end_date}`,
      },
    ],
  });

  const { font, images, styles } = headerForm;

  const localeStringOption = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  const generateTableBody = (data: any, columns: any) => {
    let body = [];

    // Header of Table
    body.push([
      { text: "HN", bold: true, alignment: "center" },
      { text: "VN", bold: true, alignment: "center" },
      { text: "วันเวลา\nที่รักษา", bold: true, alignment: "center" },
      { text: "ชื่อ-สกุล", bold: true, alignment: "center" },
      { text: "แพทย์ที่เปลี่ยนแปลงราคา", bold: true, alignment: "center" },
      { text: "รายละเอียด", bold: true, alignment: "center" },
      { text: "ราคาขาย", bold: true, alignment: "center" },
      { text: "ราคา\n(ที่แพทย์แก้ไข)", bold: true, alignment: "center" },
      { text: "เหตุผล", bold: true, alignment: "center" },
    ]);

    if (data?.length > 0) {
      data.forEach((row: any) => {
        let dataRow: any = [];

        columns.forEach((column: any) => {
          if (row[column] !== null && row[column] !== undefined) {
            const descList = row["description"].map((text: string) => {
              return splitStringNewLine(text, { fontSize: 12, width: 305 }); // if width of column set as % => (% * 100) + 5
            });
            const remarkList = row["adjusted_remark"].map((text: string) => {
              return splitStringNewLine(text, { fontSize: 12, width: 325 }); // if width of column set as % => (% * 100) + 5
            });

            const template = Array.from({ length: row["description"].length }, (_, index) => {
              const maxLine = Math.max(descList[index].length, remarkList[index].length);

              return Array.from({ length: maxLine });
            });

            const addBlankLine = (data: string[] | string[][], isPrice = false) => {
              return template.map((array, pIndex) => {
                const texts = Array.isArray(data[pIndex]) ? data[pIndex] : [data[pIndex]];

                return {
                  stack: array.map((_, index) => {
                    const text: any = texts[index] || " ";
                    const formattedText = isPrice
                      ? text.toLocaleString("en-US", localeStringOption)
                      : text;
                    return {
                      text: formattedText,
                      alignment: isPrice ? "right" : "left",
                      noWrap: true,
                    };
                  }),
                };
              });
            };

            switch (column) {
              case "description":
                dataRow.push({
                  stack: addBlankLine(descList),
                });
                break;
              case "adjusted_remark":
                dataRow.push({
                  stack: addBlankLine(remarkList),
                });
                break;
              case "original_price":
                dataRow.push({
                  stack: addBlankLine(row["original_price"], true),
                });
                break;
              case "adjusted_price":
                dataRow.push({
                  stack: addBlankLine(row["adjusted_price"], true),
                });
                break;
              default:
                dataRow.push(row[column].toString());
                break;
            }
          } else {
            dataRow.push({ text: "-" });
          }
        });
        body.push(dataRow);
      });
    }

    return body;
  };

  const tableReport = (data: any, columns: any) => {
    return {
      table: {
        widths: ["auto", "auto", "auto", "auto", "auto", "27%", "auto", "auto", "27%"],
        headerRows: 1,
        body: generateTableBody(data, columns),
      },
    };
  };

  return {
    ...headerForm,
    content: [
      tableReport(props.reportPriceChangeData?.fields || [], [
        "hn",
        "encounter_number",
        "performed_date",
        "patient_name",
        "adjusted_by",
        "description",
        "original_price",
        "adjusted_price",
        "adjusted_remark",
      ]),
    ],
    images: {
      ...images,
    },
    styles: {
      ...styles
    },
    pageOrientation: `landscape`,
    defaultStyle: {
      font: font,
      // fontSize: 14,
    },
    pageSize: `A4`,
    pageMargins: [15, 100, 15, 15],
  };
};

export default FormPriceChangeRiskReport;
