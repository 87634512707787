import {
  AsyncDuckDB,
  AsyncDuckDBConnection,
  getJsDelivrBundles,
  selectBundle,
  ConsoleLogger,
  DuckDBBundles,
} from "@duckdb/duckdb-wasm";

export const getDuckDBCDN: () => Promise<AsyncDuckDB> = async (): Promise<AsyncDuckDB> => {
  const JSDELIVR_BUNDLES = getJsDelivrBundles();

  // Select a bundle based on browser checks
  const bundle = await selectBundle(JSDELIVR_BUNDLES);

  const worker_url = URL.createObjectURL(
    new Blob([`importScripts("${bundle.mainWorker!}");`], { type: "text/javascript" })
  );

  // Instantiate the asynchronus version of DuckDB-Wasm
  const worker = new Worker(worker_url);
  const logger = new ConsoleLogger();
  const db: AsyncDuckDB = new AsyncDuckDB(logger, worker);
  await db.instantiate(bundle.mainModule, bundle.pthreadWorker);
  URL.revokeObjectURL(worker_url);
  return db;
};


// It is possible to manually download the files from https://cdn.jsdelivr.net/npm/@duckdb/duckdb-wasm/dist/.
// (@duckdb/duckdb-wasm@1.28.1-dev106.0 / dist)
export const getDuckDBStatic: () => Promise<AsyncDuckDB> = async (): Promise<AsyncDuckDB> => {

  const MANUAL_BUNDLES: DuckDBBundles = {
    mvp: {
        mainModule: '/static/duckdb-mvp.wasm',
        mainWorker: '/static/duckdb-browser-mvp.worker.js',
    },
    eh: {
        mainModule: '/static/duckdb-eh.wasm',
        mainWorker: '/static/duckdb-browser-eh.worker.js',
    },
};
    // Select a bundle based on browser checks
    const bundle = await selectBundle(MANUAL_BUNDLES);
    // Instantiate the asynchronous version of DuckDB-Wasm
    const worker = new Worker(bundle.mainWorker!);
    const logger = new ConsoleLogger();
    const db = new AsyncDuckDB(logger, worker);
    await db.instantiate(bundle.mainModule, bundle.pthreadWorker);
    return db
};



// CORS error
// export const getDuckDBStaticFromStorage: () => Promise<AsyncDuckDB> = async (): Promise<AsyncDuckDB> => {

//   const MANUAL_BUNDLES: DuckDBBundles = {
//     mvp: {
//         mainModule: 'https://cdn.jsdelivr.net/npm/@duckdb/duckdb-wasm@1.28.1-dev106.0/dist/duckdb-mvp.wasm',
//         mainWorker: 'https://cdn.jsdelivr.net/npm/@duckdb/duckdb-wasm@1.28.1-dev106.0/dist/duckdb-browser-mvp.worker.js',
//     },
//     eh: {
//         mainModule: 'https://cdn.jsdelivr.net/npm/@duckdb/duckdb-wasm@1.28.1-dev106.0/dist/duckdb-eh.wasm',
//         mainWorker: 'https://cdn.jsdelivr.net/npm/@duckdb/duckdb-wasm@1.28.1-dev106.0/dist/duckdb-browser-eh.worker.js',
//     },
// };
//     // Select a bundle based on browser checks
//     const bundle = await selectBundle(MANUAL_BUNDLES);
//     // Instantiate the asynchronous version of DuckDB-Wasm
//     const worker = new Worker(bundle.mainWorker!);
//     const logger = new ConsoleLogger();
//     const db = new AsyncDuckDB(logger, worker);
//     await db.instantiate(bundle.mainModule, bundle.pthreadWorker);
//     return db
// };



export const getDuckDBConnection: (db: AsyncDuckDB) => Promise<AsyncDuckDBConnection> = async (
  db: AsyncDuckDB
) => {
  return await db.connect();
};
