import React, { useMemo, useState, useCallback } from "react";
import { Input, InputProps } from "semantic-ui-react";

import moment from "moment";
import { Column, RowInfo } from "react-table-6";

// Frameworks
import { Table } from "react-lib/frameworks/Table";

// UX
import CardStockOrderWorkingUX from "./CardStockOrderWorkingUX";
import ModActionLog from "./ModActionLog";

// Common
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

// Interface
import { BACKWARD_ACTIONS, FORWARD_ACTIONS, SetProp } from "./sequence/StockTransferOrder";
import { useIntl } from "react-intl";

const CARD_NAME = "CardStockTransferOrder";

// Types
type CardStockOrderWorkingProps = {
  setProp: SetProp;
  onEvent: (e: any) => any;
  // seq
  runSequence: any;
  StockTransferOrderSequence?: any;
  // CommonInterface
  errorMessage?: Record<string, any>;
  buttonLoadCheck?: Record<string, any>;
  selectedDivision?: Record<string, any> | null;
};

const CardStockOrderWorking = (props: CardStockOrderWorkingProps) => {
  const intl = useIntl();
  const [openModConfirmApprove, setOpenModConfirmApprove] = useState<boolean>(false);
  const [openModConfirmReject, setOpenModConfirmReject] = useState(false);
  const [rejectNote, setRejectNote] = useState<string>("");

  // Callback
  const getButtonAction = useCallback(
    (actions: any): { action: string; color: string; by: string } | null => {
      const selectedBox = props.StockTransferOrderSequence?.selectedBox;

      const isProvider = selectedBox?.provider === props.selectedDivision?.id;
      const isRequester = selectedBox?.request_division === props.selectedDivision?.name;

      const status = selectedBox?.status;

      const button = actions[status];

      let by = "";

      if (isProvider) {
        by = "provider";
      } else if (isRequester) {
        by = "requester";
      }

      return button?.by === by ? button : null;
    },
    [props.StockTransferOrderSequence?.selectedBox, props.selectedDivision]
  );

  // Use Memo
  const forwardAction = useMemo(() => {
    return getButtonAction(FORWARD_ACTIONS);
  }, [props.StockTransferOrderSequence?.selectedBox, props.selectedDivision]);

  const backwardAction = useMemo(() => {
    return getButtonAction(BACKWARD_ACTIONS);
  }, [props.StockTransferOrderSequence?.selectedBox, props.selectedDivision]);

  const workItems = useMemo(() => {
    return props.StockTransferOrderSequence?.selectedBox?.items?.map((item: any, idx: any) => ({
      ...item,
      request_quantity: (
        <div style={{ display: "grid", placeContent: "center", height: "100%" }}>
          {item.request_quantity}
        </div>
      ),
      provide_quantity_input: <RenderLotRow data={item} onDisplay={(row) => row.quantity} />,
      total_amount: <RenderLotRow data={item} onDisplay={(row) => row.sum  || ""} />,
      receive_quantity_input: <RenderLotRow data={item} onDisplay={(row) => row.quantity} />,
      total_recv_amount: (
        <RenderLotRow
          data={item}
          onDisplay={(row, index) => {
            if (index === 0) {
              item.items[index]["sum"] = row.quantity;
            } else {
              item.items[index]["sum"] = row.quantity + item.items[index - 1]["sum"];
            }

            return row.sum || "";
          }}
        />
      ),
      lot_no: <RenderLotRow data={item} onDisplay={(row) => row.lot?.mfc_no || "\u00a0"} />,
      expired_date: (
        <RenderLotRow
          data={item}
          onDisplay={(row) =>
            !!row.lot?.exp_datetime &&
            moment(row.lot?.exp_datetime, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY")
          }
        />
      ),
      quantity: <RenderLotRow data={item} onDisplay={(row) => row.lot?.get_quantity || "-"} />,
    }));
  }, [props.StockTransferOrderSequence, props.StockTransferOrderSequence?.selectedBox]);

  // Handler
  const handleGetTopTheadThProps = (
    state: any,
    rowInfo: RowInfo,
    column: Column,
    instance: any
  ) => {
    return {
      style: ["คลังผู้ให้โอนสินค้า", "จำนวนจ่าย จากผู้ให้สินค้า"].includes(
        (column.Header || "") as string
      )
        ? {}
        : { border: "none", backgroundColor: "white" },
    };
  };

  const handleGetTheadThProps = (state: any, rowInfo: RowInfo, column: Column, instance: any) => {
    return {
      style: ["จำนวนขอ", "จำนวนจ่าย"].includes((column.Header || "") as string)
        ? { backgroundColor: "#2185D0" }
        : {},
    };
  };

  const handleGetTrProps = (state: any, rowInfo: RowInfo, column: Column, instance: any) => ({
    onClick: () => {},
  });

  // Mod
  const handleCloseModConfirmApprove = () => {
    setOpenModConfirmApprove(false);
  };

  const handleCloseModConfirmReject = () => {
    setOpenModConfirmReject(false);
    setRejectNote("");
  };

  const handleButtonAction = (action: string) => () => {
    if (action === "REJECT") {
      setOpenModConfirmReject(true);
      setRejectNote("");

      return;
    }

    props.runSequence({
      sequence: "StockTransferOrder",
      action,
      card: CARD_NAME,
    });
  };

  const handleClose = () => {
    props.setProp("StockTransferOrderSequence.selectedBox", null);
  };

  const handleActionLog = () => {
    props.runSequence({
      sequence: "StockTransferOrder",
      action: "LOG",
    });
  };

  const handleCloseActionLog = () => {
    props.setProp("StockTransferOrderSequence.actionLog", null);
  };

  const handleChangeNote = (e: any, data: InputProps) => {
    setRejectNote(data.value);
  };

  const handleConfirmReject = () => {
    props.runSequence({
      sequence: "StockTransferOrder",
      action: "REJECT",
      card: CARD_NAME,
      rejectNote,
      onSuccess: handleCloseModConfirmReject,
    });
  };

  return (
    <div>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_NAME}`, null);
        }}
        error={props.errorMessage?.[CARD_NAME]}
        success={null}
        languageUX={props.languageUX}
      />

      <CardStockOrderWorkingUX
        StockTransferOrderSequence={props.StockTransferOrderSequence}
        // data
        items={workItems}
        // callback
        // table
        getTheadThProps={handleGetTheadThProps}
        getTrProps={handleGetTrProps}
        onClose={handleClose}
        onActionLog={handleActionLog}
        // Element
        TopTable={
          // <Table
          //   headers=",,,คลังผู้ให้โอนสินค้า,,"
          //   widths="150,,150,450,150,150"
          //   showPagination={false}
          //   minRows={0}
          //   resizable={false}
          //   style={{ border: "none" }}
          //   // callback
          //   getTheadThProps={handleGetTopTheadThProps}
          //   // Element
          //   NoDataComponent={() => null}
          // />
          <Table
            headers={intl.formatMessage({ id: ",,,,,,จำนวนจ่าย จากผู้ให้สินค้า," })}
            widths="100,,100,100,100,100,200,200"
            showPagination={false}
            minRows={0}
            resizable={false}
            style={{ border: "none" }}
            // callback
            getTheadThProps={handleGetTopTheadThProps}
            // Element
            NoDataComponent={() => null}
          />
        }
        buttonBackward={
          backwardAction && (
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={handleButtonAction(backwardAction.action)}
              // data
              paramKey={`${CARD_NAME}_${backwardAction.action}`}
              buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_NAME}_${backwardAction.action}`]}
              // config
              color={backwardAction.color}
              title={backwardAction.action.toLowerCase()}
              style={{ textTransform: "capitalize" }}
            />
          )
        }
        buttonForward={
          forwardAction && (
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={handleButtonAction(forwardAction.action)}
              // data
              paramKey={`${CARD_NAME}_${forwardAction.action}`}
              buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_NAME}_${forwardAction.action}`]}
              // config
              color={forwardAction.color}
              title={forwardAction.action.toLowerCase()}
              style={{ textTransform: "capitalize" }}
            />
          )
        }
        languageUX={props.languageUX}
      />

      <ModConfirm
        openModal={openModConfirmApprove}
        titleName={intl.formatMessage({ id: "อนุมัติ คำขอสินค้า" })}
        titleColor="green"
        size="mini"
        denyButtonColor="red"
        denyButtonText={intl.formatMessage({ id: "ยกเลิก" })}
        approveButtonColor="blue"
        approveButtonText={intl.formatMessage({ id: "ตกลง" })}
        content={
          <div
            style={{
              padding: "0.5rem 0",
              lineHeight: 1.5,
              margin: "-1.5rem 0",
            }}
          >
            <div>{intl.formatMessage({ id: "โปรดระบุเหตุผล" })}</div>
            <div>
              <span>{intl.formatMessage({ id: "เนื่องจากจ่ายสินค้าไม่ครบตามจำนวนขอ" })}</span>
              <span style={{ color: "red" }}>*</span>
            </div>
            <Input style={{ width: "100%", marginTop: "1rem" }} />
          </div>
        }
        // onApprove={handleConfirmRemove}
        onDeny={handleCloseModConfirmApprove}
        onCloseWithDimmerClick={handleCloseModConfirmApprove}
      />

      <ModConfirm
        approveButtonColor="blue"
        approveButtonText={intl.formatMessage({ id: "ตกลง" })}
        denyButtonColor="red"
        denyButtonText={intl.formatMessage({ id: "ยกเลิก" })}
        openModal={openModConfirmReject}
        size="mini"
        titleColor="red"
        titleName={intl.formatMessage({ id: "ปฏิเสธ คำขอสินค้า" })}
        onApprove={handleConfirmReject}
        onCloseWithDimmerClick={handleCloseModConfirmReject}
        onDeny={handleCloseModConfirmReject}
        content={
          <div
            style={{
              lineHeight: 1.5,
              margin: "-1.5rem 0",
              padding: "0.5rem 0",
            }}
          >
            <div>
              <span>{intl.formatMessage({ id: "โปรดระบุเหตุผล ปฏิเสธคำขอ" })}</span>
              <span style={{ color: "red" }}>*</span>
            </div>
            <Input
              style={{ marginTop: "1rem", width: "100%" }}
              value={rejectNote}
              onChange={handleChangeNote}
            />
          </div>
        }
      />

      <ModActionLog
        open={!!props.StockTransferOrderSequence?.actionLog}
        data={props.StockTransferOrderSequence?.actionLog || []}
        headers="User,Action,Division,Date-Time"
        // callback
        onClose={handleCloseActionLog}
        languageUX={props.languageUX}
      />
    </div>
  );
};

/* ------------------------------------------------------ */

/*                      RenderLotRow                      */

/* ------------------------------------------------------ */
type RenderLotRowProps = {
  data: any;
  onDisplay: (row: any, index: number) => any;
};

export const RenderLotRow = (props: RenderLotRowProps) => {
  return (
    <div style={{ margin: "-7px -5px" }}>
      {props.data.items?.map((row: any, index: number, self: any[]) => (
        <div
          key={"lot-row" + props.data.id + row.id}
          style={{
            borderBottom: self.length - 1 !== index ? "solid 1px rgba(0,0,0,0.05)" : "",
            padding: "7px",
          }}
        >
          {props.onDisplay(row, index)}
        </div>
      ))}
    </div>
  );
};

export default React.memo(CardStockOrderWorking);
