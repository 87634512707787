import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Checkbox
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardServiceCompensationItemUX = (props: any) => {
    return(
      <div
        style={{ height: "80vh" }}>
        <div
          style={{ width: "100%", height: "100%", display: "grid", position: "relative", gridTemplateRows: "repeat(50, 1fr)", gridTemplateColumns: "repeat(50, 1fr)" }}>
          <div
            style={{ fontSize: "1.5rem", gridRow: "2/4", gridColumn: "2/12",  }}>
            ค่าตอบแทน(หัตถการ)
          </div>
          <div
            style={{ gridRow: "5/7", gridColumn: "2/50", display: 'flex',  justifyContent: "flex-end", alignItems: "center"  }}>

            <Checkbox
              checked={props.checkDate}
              label="วันที่ค่าใช้จ่าย"
              onChange={props.onChangeCheckDate}>
            </Checkbox>
            <DateTextBox
              onChange={props.onChangeStartDate}
              style={{paddingLeft: "10px"}}
              value={props.startDate}>
            </DateTextBox>
            <div
              style={{paddingLeft: "10px"}}>
              -
            </div>
            <DateTextBox
              onChange={props.onChangeEndDate}
              style={{paddingLeft: "10px"}}
              value={props.endDate}>
            </DateTextBox>
          </div>
          <div
            style={{ gridRow: "9/35", gridColumn: "2/50",  }}>
            <Table
              className="card-doctor-fee-order-compensation --rt-tbody-overflow-y-hidden"
              data={props.scPreviewItems}
              getTdProps={props.getTdProps}
              getTrProps={props.getTrProps}
              headers="No,Service Time,Service Code,Product Name,ราคาต่อหน่วย,	 ค่าตอบแทนต่อหน่วย สูงสุดที่สามารถระบุได้,ค่าตอบแทนที่กรอกต่อหน่วย,	 จำนวนสั่ง,	 ผลรวมค่าตอบแทน,เบิกได้,เบิกไม่ได้,ราคารวม,ผู้ได้รับค่าตอบแทน,ผู้บันทึกข้อมูล."
              keys="no,service_date_display,service_code,name,price_unit,price_compensation_max,compensation_price,quantity,total_compensation_price,price_claimable,price_non_claimable,price_total,received_employee_display,edited_by,action"
              minRows="8"
              showPagination={false}
              style={{ height: "47vh", ...(props.tableStyle || {} ) }}
              widths="^40,,,,,,,,,,,,^200,,">
            </Table>
          </div>
          <div
            style={{ gridRow: "36/37", gridColumn: "2/50" }}>
            {props.addBar}
          </div>
        </div>
      </div>
    )
}


export default CardServiceCompensationItemUX

export const screenPropsDefault = {"showSearchResult":false}

/* Date Time : Fri Jul 05 2024 11:55:02 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ height: \"80vh\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "label": "divgrid",
      "name": "div",
      "parent": 0,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"100%\", display: \"grid\", position: \"relative\", gridTemplateRows: \"repeat(50, 1fr)\", gridTemplateColumns: \"repeat(50, 1fr)\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ค่าตอบแทน(หัตถการ)"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"1.5rem\", gridRow: \"2/4\", gridColumn: \"2/12\",  }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/7\", gridColumn: \"2/50\", display: 'flex',  justifyContent: \"flex-end\", alignItems: \"center\"  }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"9/35\", gridColumn: \"2/50\",  }"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 17,
      "name": "Table",
      "parent": 6,
      "props": {
        "className": {
          "type": "value",
          "value": "card-doctor-fee-order-compensation --rt-tbody-overflow-y-hidden"
        },
        "data": {
          "type": "code",
          "value": "props.scPreviewItems"
        },
        "getTdProps": {
          "type": "code",
          "value": "props.getTdProps"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getTrProps"
        },
        "headers": {
          "type": "value",
          "value": "No,Service Time,Service Code,Product Name,ราคาต่อหน่วย,\t ค่าตอบแทนต่อหน่วย สูงสุดที่สามารถระบุได้,ค่าตอบแทนที่กรอกต่อหน่วย,\t จำนวนสั่ง,\t ผลรวมค่าตอบแทน,เบิกได้,เบิกไม่ได้,ราคารวม,ผู้ได้รับค่าตอบแทน,ผู้บันทึกข้อมูล."
        },
        "keys": {
          "type": "value",
          "value": "no,service_date_display,service_code,name,price_unit,price_compensation_max,compensation_price,quantity,total_compensation_price,price_claimable,price_non_claimable,price_total,received_employee_display,edited_by,action"
        },
        "minRows": {
          "type": "value",
          "value": "8"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"47vh\", ...(props.tableStyle || {} ) }"
        },
        "widths": {
          "type": "value",
          "value": "^40,,,,,,,,,,,,^200,,"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "Checkbox",
      "parent": 3,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkDate"
        },
        "label": {
          "type": "value",
          "value": "วันที่ค่าใช้จ่าย"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeCheckDate"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 20,
      "name": "DateTextBox",
      "parent": 3,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeStartDate"
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"10px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.startDate"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "-"
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"10px\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 22,
      "name": "DateTextBox",
      "parent": 3,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeEndDate"
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"10px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.endDate"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.addBar"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"36/37\", gridColumn: \"2/50\" }"
        }
      },
      "seq": 23,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardServiceCompensationItemUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "showSearchResult": false
  },
  "width": 80
}

*********************************************************************************** */
