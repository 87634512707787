import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'

const ModBlockDetailUX = (props: any) => {
    return(
      <div>
        <Table
          data={props.providers}
          getTrProps={props.rowProps}
          headers="แพทย์,วันที่,เริ่ม,ถึง"
          keys="provider_name,date,start_time,end_time"
          minRows="8"
          showPagination={false}
          style={{height: "300px"}}>
        </Table>
      </div>
    )
}

export default ModBlockDetailUX

export const screenPropsDefault = {}

/* Date Time : Sun Apr 17 2022 22:27:50 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 1,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.providers"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.rowProps"
        },
        "headers": {
          "type": "value",
          "value": "แพทย์,วันที่,เริ่ม,ถึง"
        },
        "keys": {
          "type": "value",
          "value": "provider_name,date,start_time,end_time"
        },
        "minRows": {
          "type": "value",
          "value": "8"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"300px\"}"
        }
      },
      "seq": 1,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "ModBlockDetailUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
