import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";

import { Button, Modal } from "semantic-ui-react";

import { RowInfo } from "react-table-6";

// ARM
import CardSentClaimInformationDetail from "react-lib/apps/ARM/CardSentClaimInformationDetail";
// Common
import SnackMessage from "react-lib/apps/common/SnackMessage";
import { formatPrice } from "react-lib/apps/common/PureReactTable";
import ButtonLoadCheck, { BLClickHandler } from "react-lib/appcon/common/ButtonLoadCheck";

// TPD
import CardPatientSearchBox from "../TPD/CardPatientSearchBox";
// ADM
import ModalSearchEncounter from "../ADM/ModalSearchEncounter";

import CardAIPNSendClaimUX from "./CardAIPNSendClaimUX";
import CardSearchInvoiceGroup from "./CardSearchInvoiceGroup";
import ModDownloadZipFile from "./ModDownloadZipFile";

// Interface
import {
  ACTIONS,
  BTN_ACTS,
  CARD_AIPN_SENT_CLAIM,
  FilterAllValues,
  FilterType,
  MasterOptionsType,
  PickedProps,
  RunSequence,
  SetProp,
  State,
} from "./sequence/AIPNSendClaim";

// Utils
import { formatDatetime } from "react-lib/utils/dateUtils";
import { useIntl } from "react-intl";

// Types
type CardAIPNSendClaimProps = {
  onEvent: (e: any) => any;
  setProp: SetProp;
  // controller
  cardSentClaimInformationController: any;
  drugOrderQueueController: any;
  // seq
  AIPNSendClaimSequence: State["AIPNSendClaimSequence"];
  SendClaimDownloadZipFileSequence: any;
  runSequence: RunSequence;
  // options
  masterOptions?: MasterOptionsType;
} & PickedProps;

const STATUSES = {
  NOT_SENT: 8,
  RESENT_SUCCESS: 7,
  RESENT_WAITING_RESULT: 6,
  SENT_SUCCESS: 3,
  SENT_WAITING_RESULT: 2,
};

const BG_COLORS = {
  C: "rgb(255, 197, 197)",
  NOT_SENT: "rgb(201, 255, 229)",
  SENT_SUCCESS: "rgb(153, 255, 153)",
  VALID_FALSE: "rgb(255, 204, 102)",
  WAITING_RESULT: "rgb(153, 204, 255)",
};

const CardAIPNSendClaim = (props: CardAIPNSendClaimProps) => {
  const intl = useIntl();
  const [modSendClaimInfo, setModSendClaimInfo] = useState<number | null>(null);
  const [openModDownloadZipFile, setOpenModDownloadZipFile] = useState<boolean>(false);

  // Effect
  useEffect(() => {
    props.runSequence({ sequence: "AIPNSendClaim", restart: true });
  }, []);

  // Callback
  const getBgColor = (original: Record<string, any>) => {
    if (original.have_failed) {
      return BG_COLORS.C;
    }
    if (original.valid === false) {
      return BG_COLORS.VALID_FALSE;
    }
    if ([STATUSES.RESENT_SUCCESS, STATUSES.SENT_SUCCESS].includes(original.status)) {
      return BG_COLORS.SENT_SUCCESS;
    }
    if ([STATUSES.RESENT_WAITING_RESULT, STATUSES.SENT_WAITING_RESULT].includes(original.status)) {
      return BG_COLORS.WAITING_RESULT;
    }
    if (original.status === STATUSES.NOT_SENT) {
      return BG_COLORS.NOT_SENT;
    }
    return "";
  };

  const handleGetTrProps = useCallback((state: any, rowInfo?: RowInfo) => {
    const original = rowInfo?.original || {};
    const bgColor = getBgColor(original);

    return {
      style: { backgroundColor: bgColor || "" },
    };
  }, []);

  const handleOpenModSendClaim = useCallback(
    (data: Record<string, any>) => () => {
      setModSendClaimInfo(data.id);
    },
    []
  );

  // Memo
  const filter = useMemo(
    () => props.AIPNSendClaimSequence?.filter,
    [props.AIPNSendClaimSequence?.filter]
  );

  // Memo
  const formattedBillTransactionList = useMemo(() => {
    return (props.AIPNSendClaimSequence?.billTransactionList || []).map((item) => ({
      ...item,
      description: (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            color="orange"
            size="mini"
            style={{ padding: "0.5rem 1rem" }}
            onClick={handleOpenModSendClaim(item)}
          >
            รายละเอียด
          </Button>
        </div>
      ),
      dt_tran: formatDatetime(item.dt_tran || item.invoice_datetime, true),
      total_amount_price: formatPrice(item.total_amount_price),
      total_paid_price: formatPrice(item.total_paid_price),
      total_send_claim_price: formatPrice(item.total_send_claim_price),
    }));
  }, [props.AIPNSendClaimSequence?.billTransactionList]);

  const formattedBillTransactionSummary = useMemo(() => {
    const { total_rows, ...summary } = props.AIPNSendClaimSequence?.billTransactionSummary || {};

    return {
      total_rows,
      ...Object.fromEntries(
        Object.entries(summary).map(([key, value]) => [
          key,
          value.toLocaleString("en-US", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            style: "decimal",
          }),
        ])
      ),
    };
  }, [props.AIPNSendClaimSequence?.billTransactionSummary]);

  // Handler
  const handleChangeFilter = (
    e: SyntheticEvent | null,
    data: { checked?: boolean; name: keyof FilterType; value: FilterAllValues }
  ) => {
    const value = data.checked === undefined ? data.value : data.checked;

    props.setProp(`AIPNSendClaimSequence.filter.${data.name}`, value);
  };

  const handleSelectAr = (data: Record<string, any> | null) => {
    const seq = props.AIPNSendClaimSequence;

    props.setProp("AIPNSendClaimSequence", {
      ...seq,
      filter: { ...seq?.filter, arTransaction: data },
      ...(!data && { billTransactionList: [], billTransactionSummary: null }),
    });
  };

  const handleChangePatient = (id: number | null) => {
    handleChangeFilter(null, { name: "patientId", value: id });
  };

  const handleGetEncounterList = async (params: Record<string, any>) =>
    props.onEvent({
      message: "GetEncounterList",
      params,
    });

  const handleSelectEncounter = (data: Record<string, { id: number }>) => {
    handleChangeFilter(null, { name: "encounterId", value: data.id });
  };

  const handleClearEncounter = () => {
    handleChangeFilter(null, { name: "encounterId", value: null });
  };

  const handleSearch: BLClickHandler<typeof ACTIONS.SEARCH> = (e, data) => {
    props.runSequence({ sequence: "AIPNSendClaim", action: data.name });
  };

  const handleCloseModSendClaim = () => {
    setModSendClaimInfo(null);
  };

  const handleClickDownloadPreviousSendClaim = () => {
    setOpenModDownloadZipFile(true);
  };

  const handleCloseModDownloadZipFile = () => {
    setOpenModDownloadZipFile(false);
  };

  const handleDownloadZipFile: BLClickHandler<typeof ACTIONS.DOWNLOAD_ZIP_FILE> = (e, data) => {
    props.runSequence({
      sequence: "AIPNSendClaim",
      action: data.name,
      id: filter?.arTransaction?.id,
      card: CARD_AIPN_SENT_CLAIM,
    });
  };

  const handleCloseErrMsg = () => {
    props.setProp(`errorMessage.${CARD_AIPN_SENT_CLAIM}`, null);
  };

  // #console.log("CardAIPNSendClaim", props);

  return (
    <div>
      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage?.[CARD_AIPN_SENT_CLAIM]}
        success={null}
        onClose={handleCloseErrMsg}
        languageUX={props.languageUX}
      />

      <CardAIPNSendClaimUX
        // data
        billTransactionList={formattedBillTransactionList}
        billTransactionSummary={formattedBillTransactionSummary}
        filter={filter}
        // options
        sentClaimChoiceOptions={props.masterOptions?.sentClaimChoice}
        // callback
        onChangeFilter={handleChangeFilter}
        onClickDownloadPreviousSendClaim={handleClickDownloadPreviousSendClaim}
        onGetTrProps={handleGetTrProps}
        buttonDownloadZipFile={
          <ButtonLoadCheck
            setProp={props.setProp}
            color="green"
            disabled={!filter?.arTransaction?.id}
            name={ACTIONS.DOWNLOAD_ZIP_FILE}
            paramKey={BTN_ACTS.DOWNLOAD_ZIP_FILE}
            size="small"
            title={intl.formatMessage({ id: "ดาวน์โหลด zip ไฟล์" })}
            buttonLoadCheck={props.buttonLoadCheck?.[BTN_ACTS.DOWNLOAD_ZIP_FILE]}
            onClick={handleDownloadZipFile}
          />
        }
        buttonSearch={
          <ButtonLoadCheck
            setProp={props.setProp}
            color="blue"
            name={ACTIONS.SEARCH}
            paramKey={BTN_ACTS.SEARCH}
            size="small"
            title={intl.formatMessage({ id: "ค้นหา" })}
            buttonLoadCheck={props.buttonLoadCheck?.[BTN_ACTS.SEARCH]}
            onClick={handleSearch}
          />
        }
        // Component
        patientSearchBox={
          <CardPatientSearchBox
            // controller
            controller={props.drugOrderQueueController}
            disabled={!filter?.isPatient}
            // callback
            onEnterPatientSearch={handleChangePatient}
            languageUX={props.languageUX}
          />
        }
        searchEncounter={
          <ModalSearchEncounter
            disabledSearch={!filter?.isEncounter}
            patientId={filter?.patientId || null}
            textField="id"
            fluid
            readOnly
            onClearInput={handleClearEncounter}
            onGetEncounter={handleGetEncounterList}
            onSelect={handleSelectEncounter}
          />
        }
        searchInvoiceGroup={
          <CardSearchInvoiceGroup
            onEvent={props.onEvent}
            setProp={props.setProp}
            groupType="IPD"
            buttonLoadCheck={props.buttonLoadCheck}
            // callback
            onSelected={handleSelectAr}
            languageUX={props.languageUX}
          />
        }
        languageUX={props.languageUX}
      />

      <Modal open={!!modSendClaimInfo} size={"fullscreen"}>
        <CardSentClaimInformationDetail
          onEvent={props.onEvent}
          setProp={props.setProp}
          controller={props.cardSentClaimInformationController}
          cardTitle={intl.formatMessage({ id: "รายละเอียดข้อมูลส่งเบิก" })}
          transactionId={modSendClaimInfo}
          searchedItemListWithKey={props.searchedItemListWithKey}
          // options
          diagnosisTypeOptions={props.masterOptions?.diagnosisTypeValue}
          // onCompleted={handleUpdateDetailCompleted}
          onClose={handleCloseModSendClaim}
          languageUX={props.languageUX}
        />
      </Modal>

      <ModDownloadZipFile
        onEvent={props.onEvent}
        setProp={props.setProp as any}
        // seq
        runSequence={props.runSequence as any}
        SendClaimDownloadZipFileSequence={props.SendClaimDownloadZipFileSequence}
        arTransaction={props.AIPNSendClaimSequence?.filter?.arTransaction}
        open={openModDownloadZipFile}
        // data
        type="IPD"
        // CommonInterface
        buttonLoadCheck={props.buttonLoadCheck}
        errorMessage={props.errorMessage}
        // callback
        onClose={handleCloseModDownloadZipFile}
        languageUX={props.languageUX}
      />
    </div>
  );
};

CardAIPNSendClaim.displayName = "CardAIPNSendClaim";

export default React.memo(CardAIPNSendClaim);
