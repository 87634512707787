import React, { useEffect } from "react";
import CardZoneLogUX from "./CardZoneLogUX";
import { useIntl } from "react-intl";

type CardZoneLogProps = {
  // seq
  runSequence: any;
  ZoneLogSequence?: any;
  // data
  encounter?: any;
};

const CardZoneLogInitial: CardZoneLogProps = {
  // seq
  runSequence: null,
  ZoneLogSequence: {},
  // data
  encounter: {},
};

const CardZoneLog = (props: any) => {
  useEffect(() => {
    props.runSequence({
      sequence: "ZoneLog",
      restart: true,
      encounter: props.encounter,
    });
  }, []);

  return (
    <div
      style={{
        margin: "15px",
        padding: "20px 20px 50px 20px",
        backgroundColor: "rgba(198, 235, 243, 0.2)",
      }}
    >
      <div style={{ paddingBottom: "5px" }}>
        <label style={{ fontWeight: "bold", fontSize: "22px" }}>Zone Log</label>

        <hr></hr>
      </div>
      <CardZoneLogUX 
        ZoneLogSequence={props.ZoneLogSequence} 
        languageUX={props.languageUX}
      />
    </div>
  );
};

CardZoneLog.defaultProps = CardZoneLogInitial;

export default React.memo(CardZoneLog);
