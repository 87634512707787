import PropTypes from "prop-types";
import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import { Button, Input, Form, Modal, Label, Dropdown, Checkbox, Grid } from "semantic-ui-react";
import { toast } from "react-toastify";
import * as utils from "react-lib/utils";
import _ from "react-lib/compat/lodashplus";

import CardLayout from "react-lib/apps/common/CardLayout";
import DateTextBox from "react-lib/apps/common/DateTextBox";
import RightLabel from "react-lib/apps/common/RightLabel";
import PureReactTable from "react-lib/apps/common/PureReactTable";
import { formatPrice, formatComma } from "react-lib/apps/common/PureReactTable";

import CardSearchARTransaction from "react-lib/apps/ARM/CardSearchARTransaction";
import CardSentClaimInformationDetail from "react-lib/apps/ARM/CardSentClaimInformationDetail";
import CardDownloadPreviousSentClaimFile from "react-lib/apps/ARM/CardDownloadPreviousSentClaimFile";
import { useIntl } from "react-intl";

const CardConfirmDownload = React.memo(
	({ statusChoices = [], initialValue = "", onClose = () => {}, onConfirm = status => {} }) => {
		const ref = useRef();

		const handleConfirm = (event, data) => {
			let status = ref.current.state.value;
			onConfirm(status);
		};

		useEffect(() => {
			if (ref) {
				ref.current.setValue(initialValue);
			}
		}, []);

		return (
			<CardLayout
				titleText={"Download Zip File"}
				headerColor="orange"
				toggleable={false}
				closeable={true}
				onClose={onClose}
			>
				<Form style={{ marginLeft: "16px", marginRight: "16px" }}>
					<Form.Group inline style={{ marginBottom: "16px" }}>
						<label>
							กรุณาเลืิอกสถานะส่งเบิก<span>*</span>
						</label>
						<Dropdown fluid selection ref={ref} options={statusChoices} />
					</Form.Group>
					<Form.Field width={16} style={{ display: "flex", justifyContent: "flex-end" }}>
						<Button color={"green"} type={"button"} onClick={handleConfirm}>{intl.formatMessage({ id: "ตกลง" })}</Button>
					</Form.Field>
				</Form>
			</CardLayout>
		);
	}
);

var taskUpdating = null;
const CardSentClaimInformation = props => {
	const intl = useIntl();
	const isMounted = useRef(true);
	const [loading, setLoading] = useState(false);
	const [openDetail, setOpenDetail] = useState(false);
	const [openSearch, setOpenSearch] = useState(false);
	const [openConfirmDownload, setOpenConfirmDownload] = useState(false);
	const [openDownloadPrevious, setOpenDownloadPrevious] = useState(false);

	const [targetLotNo, setTargetLotNo] = useState(null);
	// State for filter Date
	const [filterDate, setFilterDate] = useState(false);
	const [startDateSearch, setStartDateSearch] = useState("");
	const [endDateSearch, setEndDateSearch] = useState("");
	// State for filter Status
	const [filterStatus, setFilterStatus] = useState(false);
	const [statusOptions, setStatusOptions] = useState([]);
	const [statusSearch, setStatusSearch] = useState("");
	// State for filter AN
	const [filterAN, setFilterAN] = useState(false);
	const [anOptions, setANOptions] = useState([]);
	const [anSearch, setANSearch] = useState("");
	// State for table
	const [tableLoading, setTableLoading] = useState(false);
	const [billTransaction, setBillTransaction] = useState([]);
	const [transactionSelected, setTransactionSelected] = useState(null);
	const [page, setPage] = useState(1);
	const [pageTotal, setPageTotal] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	// State for summary
	const [transactionSummary, setTransactionSummary] = useState({});
	const [summaryLoading, setSummaryLoading] = useState(false);

	/** Handle modal */
	const showDetail = transactionId => (event, data) => {
		setTransactionSelected(transactionId);
		setOpenDetail(true);
	};

	const hideDetail = () => {
		setOpenDetail(false);
	};

	const showSearch = () => {
		setOpenSearch(true);
	};

	const hideSearch = () => {
		setOpenSearch(false);
	};

	const showConfirmDownload = () => {
		setOpenConfirmDownload(true);
	};

	const hideConfirmDownload = () => {
		setOpenConfirmDownload(false);
	};

	const showDownloadPrevious = () => {
		setOpenDownloadPrevious(true);
	};

	const hideDownloadPrevious = () => {
		setOpenDownloadPrevious(false);
	};
	/************** */
	/** Handle table search */
	const handleFilterDate = (event, data) => {
		if (!filterStatus && !filterAN) {
			setFilterDate(data.checked);
		}
	};

	const handleSearchStartDateChange = date => {
		setStartDateSearch(date);
	};

	const handleSearchEndDateChange = date => {
		setEndDateSearch(date);
	};

	const choicesSentClaimStatus = async () => {
		const [choices, error] = await props.controller.choicesSentClaimStatus();
		if (!isMounted.current) {
			return;
		}
		if (choices) {
			setStatusOptions(choices);
			if (choices.length > 0) {
				setStatusSearch(choices[0].value);
			}
		}
	};

	const choicesEncounter = async transactionId => {
		const [choices, error] = await props.controller.choicesEncounter(transactionId);
		if (!isMounted.current) {
			return;
		}
		if (choices) {
			setANOptions(choices);
			if (choices.length > 0) {
				setANSearch(choices[0].value);
			}
		}
	};

	/** Handle dropdown status */
	const handleStatusChange = (event, data) => {
		setStatusSearch(data.value);
	};

	const handleFilterStatus = (event, data) => {
		if (data.checked) {
			if (filterDate) {
				setFilterDate(false);
			}
			if (filterAN) {
				setFilterAN(false);
			}
		}
		setFilterStatus(data.checked);
	};

	/** Handle dropdown AN */
	const handleANChange = (event, data) => {
		setANSearch(data.value);
	};

	const handleFilterAN = (event, data) => {
		if (data.checked) {
			if (filterDate) {
				setFilterDate(false);
			}
			if (filterStatus) {
				setFilterStatus(false);
			}
		}
		setFilterAN(data.checked);
	};

	/** Handle table */
	const handlePageChange = pageNo => {
		if (targetLotNo) {
			loadBillTransactionItems({
				transactionId: targetLotNo.id,
				targetPage: pageNo + 1,
			});
		}
	};

	const handlePageSizeChange = pageSize => {
		setPageSize(pageSize);
		loadBillTransactionItems({
			transactionId: targetLotNo.id,
			targetPageSize: pageSize,
		});
	};
	/************** */
	/** Handle search AE Transaction */
	const handleSelected = data => {
		// console.log("... handleSelected: ", data);
		if (data) {
			setTargetLotNo(data);
			setStartDateSearch(data.start_date);
			setEndDateSearch(data.end_date);
		}
	};
	/** Handle updating bill completed */
	const handleUpdateDetailCompleted = () => {
		loadBillTransactionItems({ transactionId: targetLotNo.id });
	};

	/** Handle "แก้ไขรายการที่ติด C และ Validate ไม่ผ่าน" Button */
	const handleUpdateCAndValid = async () => {
		if (!targetLotNo) {
			return;
		}
		setLoading(true);
		const [data, error] = await props.controller.updateSentClaimDataIPDFromARId(targetLotNo.id);
		if (!isMounted.current) {
			return;
		}
		if (error) {
			toast.error("แก้ไขรายการที่ติด C และ Validate ไม่ผ่าน ไม่สำเร็จ!");
			setLoading(false);
		} else {
			// Refresh Item
			loadBillTransactionItems({ transactionId: targetLotNo.id });
		}
	};

	// Polling
	const arTaskTrackingUpdate = async () => {
		if (!props.controller) {
			return;
		}
		const [data, error] = await props.controller.arTaskTrackingIPDUpdateSentClaim();
		if (!isMounted.current) {
			clearInterval(taskUpdating);
		}
		if (data) {
			setLoading(!data.is_allowed);
		}
	};

	/** Handle "Download zip file" Button */
	const handleDownloadZip = () => {
		if (!targetLotNo) {
			return;
		}
		if (filterAN) {
			var params = { encounter: anSearch };
			downloadZipFile(targetLotNo.id, params);
		} else {
			showConfirmDownload();
		}
	};

	const handleConfirmDownloadZip = status => {
		hideConfirmDownload();
		var params = { sentClaimStatus: status };
		downloadZipFile(targetLotNo.id, params);
	};

	const downloadZipFile = async (transactionId, params) => {
		const [data, error, network] = await props.controller.generateSentClaimIPDFileFromARId(transactionId, params);
		if (!isMounted.current) {
			return;
		}
		if (error) {
			toast.error("การ Download Zip File ไม่สำเร็จ!");
		} else {
			// Refresh item
			loadBillTransactionItems({ transactionId: targetLotNo.id });
		}
	};

	/** Handle "Download zip file ย้อนหลัง" Button */
	const handleDownloadPrevious = () => {
		if (!targetLotNo) {
			return;
		}
		showDownloadPrevious();
	};
	/**************** */

	const refreshBillTransactionList = async transactionId => {
		loadBillTransactionItems({ transactionId: transactionId });
		loadBillTransactionSummary(transactionId);
	};

	const filterParams = transactionId => {
		var params = { transactionId: transactionId };
		if (filterAN) {
			params.encounter = anSearch;
		}
		if (filterStatus) {
			params.status = statusSearch;
		}
		if (filterDate) {
			params.startDate = startDateSearch;
			params.endDate = endDateSearch;
		}

		return params;
	};

	const loadBillTransactionItems = async ({ transactionId, targetPage, targetPageSize }) => {
		setTableLoading(true);
		var params = filterParams(transactionId);
		params.page = targetPage ? targetPage : page;
		params.pageSize = targetPageSize ? targetPageSize : pageSize;
		var items = [];
		const [data, error] = await props.controller.loadBillTransactionItems(params);
		if (!isMounted.current) {
			return;
		}
		if (data) {
			items = data.items;
			setPage(data.page);
			setPageTotal(data.total);
		}
		setBillTransaction(items);
		setTableLoading(false);
	};

	const loadBillTransactionSummary = async transactionId => {
		// Set loading and clear data
		setSummaryLoading(true);
		setTransactionSummary({});

		let params = filterParams(transactionId);
		const [data, error] = await props.controller.loadBillTransactionSummary(params);
		if (!isMounted.current) {
			return;
		}
		if (data) {
			setTransactionSummary(data.items);
		}
		setSummaryLoading(false);
	};

	useEffect(() => {
		if (targetLotNo && (filterDate || filterStatus || filterAN)) {
			refreshBillTransactionList(targetLotNo.id);
		}
	}, [startDateSearch, endDateSearch, statusSearch, anSearch]);

	useEffect(() => {
		if (targetLotNo) {
			refreshBillTransactionList(targetLotNo.id);
		}
	}, [filterDate, filterStatus, filterAN]);

	useEffect(() => {
		if (targetLotNo) {
			choicesEncounter(targetLotNo.id);
			refreshBillTransactionList(targetLotNo.id);
		}
	}, [targetLotNo]);

	useEffect(() => {
		if (props.controller) {
			choicesSentClaimStatus();
		}
	}, [props.controller]);

	useEffect(() => {
		taskUpdating = setInterval(() => arTaskTrackingUpdate(), 10000);
		return () => {
			isMounted.current = false;
		};
	}, []);

	const columns = [
		{
			Header: "",
			width: 110,
			Cell: props => {
				let data = props.original;
				return (
					<Button fluid size={"mini"} color={"orange"} onClick={showDetail(data.id)}>{intl.formatMessage({ id: "รายละเอียด" })}</Button>
				);
			},
		},
		{ Header: "สถานะส่งเบิก", accessor: "status_display", width: 180 },
		{
			Header: "Date Transaction",
			width: 220,
			Cell: props => {
				let data = props.original;
				return <label>{utils.formatToBEDatetime({ date: data.invoice_datetime })}</label>;
			},
		},
		{ Header: "Hospital Code", accessor: "hospital_code", width: 120 },
		{ Header: "Encounter ID.", accessor: "encounter", width: 120 },
		{ Header: "HN", accessor: "hn", width: 150 },
		{ Header: "AN", accessor: "an", width: 100 },
		{ Header: "Amount", accessor: "total_amount_price", width: 150, Cell: row => formatPrice(row.value) },
		{ Header: "Claim Amount", accessor: "total_send_claim_price", width: 150, Cell: row => formatPrice(row.value) },
		{ Header: "Paid", accessor: "total_paid_price", width: 120, Cell: row => formatPrice(row.value) },
		{ Header: "Personal ID", accessor: "personal_id", width: 150 },
		{ Header: "Name", accessor: "name", width: 250 },
	];

	const handleGetTrProps = (state, rowInfo, column, instance) => {
		const options = {};
		let data = rowInfo.original;
		if (data) {
			let rowStyle = data.row_style;
			options.style = {
				...rowStyle,
				whiteSpace: "pre-line",
			};
		}
		return options;
	};

	return (
		<CardLayout
			titleText={props.cardTitle}
			headerColor={"orange"}
			loading={loading}
			toggleable={true}
			closeable={false}
		>
			<Modal size={"fullscreen"} open={openSearch} onClose={hideSearch}>
				<CardSearchARTransaction 
					controller={props.controller} 
					onSelected={handleSelected} 
					onClose={hideSearch} 
					languageUX={props.languageUX}
				/>
			</Modal>

			<Modal size={"fullscreen"} open={openDetail} onClose={hideDetail}>
				<CardSentClaimInformationDetail
					cardTitle={"รายละเอียดข้อมูลส่งเบิก"}
					controller={props.controller}
					transactionId={transactionSelected}
					onCompleted={handleUpdateDetailCompleted}
					onClose={hideDetail}
					languageUX={props.languageUX}
				/>
			</Modal>

			<Modal size={"tiny"} open={openConfirmDownload} onClose={hideConfirmDownload}>
				<CardConfirmDownload
					statusChoices={statusOptions}
					initialValue={statusSearch}
					onConfirm={handleConfirmDownloadZip}
					onClose={hideConfirmDownload}
					languageUX={props.languageUX}
				/>
			</Modal>

			<Modal size={"fullscreen"} open={openDownloadPrevious} onClose={hideDownloadPrevious}>
				<CardDownloadPreviousSentClaimFile
					controller={props.controller}
					cardTitle={
						targetLotNo
							? `Download Zip File ย้อนหลังของงวด ${targetLotNo.lot_no} ปีงบประมาณ ${targetLotNo.fiscal_year}`
							: "Download Zip File ย้อนหลัง"
					}
					arTransactionId={targetLotNo ? targetLotNo.id : 0}
					onClose={hideDownloadPrevious}
					languageUX={props.languageUX}
				/>
			</Modal>

			<Form>
				{/* Line 1 */}
				<Form.Group inline style={STYLE.FORM_MARGIN}>
					<Form.Field width={3}>
						<label>{intl.formatMessage({ id: "เลือกงวด" })}</label>
						<Input
							action={{ icon: "search", onClick: showSearch }}
							onClick={showSearch}
							value={targetLotNo ? targetLotNo.lot_no : ""}
						/>
					</Form.Field>

					<Form.Field width={3}>
						<label>
							สิทธิ<span>*</span>
						</label>
						<Input value={targetLotNo ? targetLotNo.coverage_code_name : ""} />
					</Form.Field>
				</Form.Group>

				{/* Line 2 */}
				<Form.Group inline style={STYLE.FORM_MARGIN}>
					<Form.Field width={3}>
						<label>{intl.formatMessage({ id: "ปีงบประมาณ" })}</label>
						<Label fluid={"true"} size={"medium"} style={{ width: "100%", textAlign: "center" }}>
							{targetLotNo ? targetLotNo.fiscal_year : "..."}
						</Label>
					</Form.Field>

					<Form.Field control={Checkbox} label={"ระบุวันที่"} checked={filterDate} onChange={handleFilterDate} />
					<Form.Field
						width={2}
						control={DateTextBox}
						value={startDateSearch}
						disabled={!filterDate}
						onChange={handleSearchStartDateChange}
					/>
					<label>{intl.formatMessage({ id: "ถึง" })}</label>
					<Form.Field
						width={2}
						control={DateTextBox}
						value={endDateSearch}
						disabled={!filterDate}
						onChange={handleSearchEndDateChange}
					/>

					<Form.Field control={Checkbox} label={"สถานะส่งเบิก"} checked={filterStatus} onChange={handleFilterStatus} />
					<Form.Field width={3}>
						<Dropdown
							fluid
							selection
							value={statusSearch}
							disabled={!filterStatus}
							options={statusOptions}
							onChange={handleStatusChange}
						/>
					</Form.Field>

					<Form.Field control={Checkbox} label={"AN"} checked={filterAN} onChange={handleFilterAN} />
					<Form.Field width={2}>
						<Dropdown
							fluid
							search
							selection
							value={anSearch}
							disabled={!filterAN}
							options={anOptions}
							onChange={handleANChange}
						/>
					</Form.Field>
				</Form.Group>

				{/* Line 3 */}
				<Form.Group inline style={STYLE.FORM_MARGIN}>
					<Form.Field width={2}>
						<div style={{ ...STYLE.DIV_BOX, backgroundColor: BACKGROUND_COLOR.UNCONFIRMED }}></div>
						<p>{intl.formatMessage({ id: "ยังไม่ได้ Confirm" })}</p>
					</Form.Field>

					<Form.Field width={2}>
						<div style={{ ...STYLE.DIV_BOX, backgroundColor: BACKGROUND_COLOR.CONFIRMED }}></div>
						<p>{intl.formatMessage({ id: "Confirm แล้ว" })}</p>
					</Form.Field>

					<Form.Field width={2}>
						<div style={{ ...STYLE.DIV_BOX, backgroundColor: BACKGROUND_COLOR.WAITING_RESULT }}></div>
						<p>{intl.formatMessage({ id: "รอผลตอบกลับ" })}</p>
					</Form.Field>

					<Form.Field width={2}>
						<div style={{ ...STYLE.DIV_BOX, backgroundColor: BACKGROUND_COLOR.SENT_SUCCESS }}></div>
						<p>{intl.formatMessage({ id: "เบิกสำเร็จ" })}</p>
					</Form.Field>

					<Form.Field width={2}>
						<div style={{ ...STYLE.DIV_BOX, backgroundColor: BACKGROUND_COLOR.SENT_FAILED }}></div>
						<p>{intl.formatMessage({ id: "เบิกไม่สำเร็จ" })}</p>
					</Form.Field>
				</Form.Group>
			</Form>

			{/* Table */}
			<PureReactTable
				style={STYLE.FORM_MARGIN}
				loading={tableLoading}
				pageSize={pageSize}
				pages={pageTotal}
				page={page - 1}
				onPageSizeChange={handlePageSizeChange}
				onPageChange={handlePageChange}
				data={billTransaction}
				columns={columns}
				getTrProps={handleGetTrProps}
			/>

			<Form>
				{/* Line 4 */}
				<Form.Group inline style={{ ...STYLE.FORM_MARGIN, ...STYLE.ELEMENT_RIGHT }}>
					<Form.Field>
						<RightLabel>{intl.formatMessage({ id: "จำนวนข้อมูลที่ส่งเบิกได้" })}</RightLabel>
					</Form.Field>
					<Input
						className={"priceInput"}
						style={{ width: "12%" }}
						loading={summaryLoading}
						value={transactionSummary.total_rows !== undefined ? transactionSummary.total_rows : ""}
					/>
					<Form.Field style={STYLE.FIELD_MARGIN}>
						<RightLabel>{intl.formatMessage({ id: "ยอดเบิก" })}</RightLabel>
					</Form.Field>
					<Input
						className={"priceInput"}
						style={{ width: "12%" }}
						loading={summaryLoading}
						value={
							transactionSummary.total_sent_claim_price !== undefined
								? formatComma(transactionSummary.total_sent_claim_price)
								: ""
						}
					/>

					<Form.Field style={STYLE.FIELD_MARGIN}>
						<RightLabel>{intl.formatMessage({ id: "ยอดผู้ป่วยจ่าย" })}</RightLabel>
					</Form.Field>
					<Input
						className={"priceInput"}
						style={{ width: "12%" }}
						loading={summaryLoading}
						value={
							transactionSummary.total_paid_price !== undefined ? formatComma(transactionSummary.total_paid_price) : ""
						}
					/>

					<Form.Field style={STYLE.FIELD_MARGIN}>
						<RightLabel>{intl.formatMessage({ id: "ยอดร่วมจ่าย" })}</RightLabel>
					</Form.Field>
					<Input
						className={"priceInput"}
						style={{ width: "12%" }}
						loading={summaryLoading}
						value={
							transactionSummary.total_other_pay_price !== undefined
								? formatComma(transactionSummary.total_other_pay_price)
								: ""
						}
					/>
				</Form.Group>

				{/* Line 5 */}
				<Form.Group inline style={STYLE.ELEMENT_RIGHT}>
					<Button color={"blue"} onClick={handleUpdateCAndValid}>{intl.formatMessage({ id: "แก้ไขรายการที่ติด C และ Validate ไม่ผ่าน" })}</Button>
					<Button color={"purple"} onClick={handleDownloadPrevious}>{intl.formatMessage({ id: "Download Zip file ย้อนหลัง" })}</Button>
					<Button color={"green"} onClick={handleDownloadZip}>
						Download Zip file
					</Button>
				</Form.Group>
			</Form>
		</CardLayout>
	);
};

const BACKGROUND_COLOR = {
	UNCONFIRMED: "#FFCC66",
	CONFIRMED: "#FFFFFF",
	WAITING_RESULT: "#99CCFF",
	SENT_SUCCESS: "#99FF99",
	SENT_FAILED: "#AF71D3",
};

const STYLE = {
	FORM_MARGIN: {
		marginBottom: "16px",
	},
	FIELD_MARGIN: {
		marginLeft: "24px",
	},
	DIV_BOX: {
		width: "30px",
		height: "15px",
	},
	ELEMENT_RIGHT: {
		flexDirection: "row",
		justifyContent: "flex-end",
	},
};

CardSentClaimInformation.defaultProps = {
	cardTitle: "รายการข้อมูลส่งเบิก",
	controller: null,
};

CardSentClaimInformation.propTypes = {
	cardTitle: PropTypes.string,
	controller: PropTypes.object,
};

export default React.memo(CardSentClaimInformation);
