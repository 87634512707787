import WasmController from "react-lib/frameworks/WasmController";

// APIs
// IME
import ImagingOrderEstimate from "issara-sdk/apis/ImagingOrderEstimate_apps_IME";
import ImagingOrderList from "issara-sdk/apis/ImagingOrderList_apps_IME";
import ImagingOrderDetail from "issara-sdk/apis/ImagingOrderDetail_apps_IME";

// Interface
import { State as MainState } from "../../../../../HIS/MainHISInterface";
import {
  GetIcd10KeyUp,
  HandleSearchDiagnosis,
} from "../../ADM/sequence/ReportPatientList";

import {
  GetClinicalFindingList,
  GetOrgan,
  getStatusImagingOrder,
  HandleModXrayDetail,
  ImagingItemSerializer,
  ImagingOrderSerializer,
  ImagingQueueSerializer,
  ModXrayDetailType,
  ORDER_ITEM_STATUS_LABEL,
  UpdateOrderItemAction,
} from "./ImagingHandler";
import { SetErrorMessage } from "../../common/CommonInterface";

// Utils
import { formattedLocationsName } from "react-lib/apps/common/CUDENT/DentalRecordInterface";

import CONFIG from "config/config";

export type State = Partial<{
  // Dental disgram
  clinicalFindingId: number | null;
  clinicalFindingIndex: number | null;
  clinicalFindingList: Record<string, any>[];
  filterClinicalFindingIds: number[];
  // -----#

  // CommonInterface
  modXrayDetail: ModXrayDetailType | null;
  imagingOrderEditId?: number | null;

  // sequence
  ImagingOrderSequence: Partial<{
    sequenceIndex: "Start" | "Action" | null;
    imagingItemDetail: Partial<ImagingOrderSerializer> | null;
    imagingItemList: ImagingItemSerializer[];
    orderDoctorId?: number | null;
    orderDoctorNameCode?: string;
    requestOrderList: ImagingOrderSerializer[];
  }> | null;
}>;

type Picked = Partial<
  Pick<
    MainState,
    | "buttonLoadCheck"
    | "errorMessage"
    | "django"
    | "searchedItemListWithKey"
    | "selectedEncounter"
    | "userTokenize"
    | "preOrderList"
  >
>;

export type MasterOptionsType = Record<
  (typeof Masters)[number][0],
  OptionType[]
>;

type OptionType = {
  key: number | string;
  value: number | string;
  text: string;
};

// Sequence
type SeqState = {
  sequence: "ImagingOrder";
  restart?: boolean;
  clear?: boolean;
  orderId?: number | null;
  editMode?: boolean;
  card?: string;
};

// Handle Action
type ActionType =
  // Search
  | { action: "SEARCH"; card?: string; isLoading?: boolean }
  // Action
  | { action: "ADD_ORDER"; orderId?: number | null }
  | { action: "ADD_ITEM"; itemId: number }
  | { action: "SEARCH_DIAGNOSIS"; data: Record<string, any> }
  | {
      action: "MOD_XRAY";
      data?: ModXrayDetailType;
      type: "OPEN" | "CLOSE";
    }
  | { action: "EDIT_REQUEST"; data: ImagingOrderSerializer }
  // Method
  | {
      action: "CANCEL_ORDER";
      card: string;
      data: ImagingOrderSerializer;
      reason: string | string[];
      onSuccess?: Function;
    }
  | {
      action: "CONFIRM_ORDER";
      card: string;
      isAppointment?: boolean;
      editMode?: boolean;
      order_by?: number | null;
      onSuccess?: () => any;
    }
  | {
      action: "EDIT_ITEM";
      data: Partial<ImagingItemSerializer>;
      index: number;
      onSuccess?: Function;
    };

type SeqAct = ActionType & SeqState;
type SeqType<K> = K extends { action: string } ? Extract<SeqAct, K> : SeqState;

export type RunSequence = <K extends keyof SeqAct>(
  params: SeqType<Pick<SeqAct, K>>
) => any;

type CustomExtract<T, U> = T extends T
  ? U extends Partial<T>
    ? T
    : never
  : never;

type Params<A extends ActionType["action"]> = CustomExtract<
  ActionType,
  { action: A }
>;

export const StateInitial: State = {
  // sequence
  ImagingOrderSequence: {
    sequenceIndex: null,
  },
};

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division?: number;
  device?: number;
  organ?: { name: string; items: any[] };
  clinicaltags?: { name: string; items: any[] };
};

export const DataInitial = {};

const Masters = [
  ["eligibilityType", {}],
  ["cancelImagingOrder", {}],
  ["divisionForOrderDiv", {}],
  ["unit", {}],
] as const;

export const XRAY_SEARCH_ID = "ImagingXray_IO";

export const DIAGNOSIS_SEARCH_ID = "Diagnosis_IO";

type Handler<P = any, R = any> = (
  controller: WasmController<State & Picked, Event, Data>,
  params: P
) => R;

/* ------------------------------------------------------ */

/*                          START                         */

/* ------------------------------------------------------ */
export const GetMaster: Handler<SeqState> = async (controller, params) => {
  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: Masters,
    },
  } as any);

  GetClinicalFindingList(controller, {});

  GetOrgan(controller, {});

  const state = controller.getState();

  controller.setState(
    {
      ImagingOrderSequence: {
        ...state.ImagingOrderSequence,
        sequenceIndex: "Action",
        imagingItemList: [],
      },
    },
    () =>
      Action(controller, {
        ...params,
        action: params.editMode ? "ADD_ORDER" : "SEARCH",
      })
  );
};

/* ------------------------------------------------------ */

/*                      Handle Action                     */

/* ------------------------------------------------------ */
export const Action: Handler<ActionType> = async (controller, params) => {
  if (params.action === "SEARCH") {
    HandleSearch(controller, params);
  } else if (params.action === "ADD_ITEM") {
    HandleAddImagingItem(controller, params);
  } else if (params.action === "MOD_XRAY") {
    HandleModXrayDetail(controller, params);
  } else if (params.action === "CANCEL_ORDER") {
    HandleDeleteImagingOrder(controller, params);
  } else if (params.action === "SEARCH_DIAGNOSIS") {
    HandleSearchDiagnosis(controller, params);
  } else if (params.action === "CONFIRM_ORDER") {
    HandleConfirmOrder(controller, params);
  } else if (params.action === "EDIT_ITEM") {
    HandleEditImagingItem(controller, params);
  } else if (params.action === "EDIT_REQUEST") {
    return HandleEditImagingRequest(controller, params);
  } else if (params.action === "ADD_ORDER") {
    HandleAddOrder(controller, params);
  }
};

const HandleSearch: Handler<Params<"SEARCH">> = async (controller, params) => {
  let state = controller.getState();

  // * มี request ที่ไม่ถูกต้องเข้ามา แต่ยังไม่ได้ดูสาเหตุ
  if ("data" in params) {
    return;
  }

  const btnKey = `${params.card}_INIT_ORDER`;
  // เมื่อกด popup ปุ่ม imaging order จากหน้า imaging worklist
  if (state.imagingOrderEditId || params.isLoading) {
    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "LOADING" },
    });
  }

  const [orderList] = await ImagingOrderList.list({
    apiToken: controller.apiToken,
    params: { encounter: state.selectedEncounter?.id },
  });

  state = controller.getState();

  let items: ImagingOrderSerializer[] = orderList?.items || [];

  items = items.map((item) => ({
    ...item,
    order_status_label: getStatusImagingOrder(item.order_items).name as any,
  }));

  controller.setState({
    ImagingOrderSequence: {
      ...state.ImagingOrderSequence,
      requestOrderList: items,
    },
    imagingOrderEditId: null,
  });

  // เมื่อมีการกด edit มาจากรายการ imaging tab order summary
  if (!state.imagingOrderEditId) {
    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: null },
    });

    return;
  }

  const data = items.find((item) => item.id === state.imagingOrderEditId);

  if (data) {
    await Action(controller, {
      action: "EDIT_REQUEST",
      data,
    });
  }

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: null },
  });
};

const HandleAddOrder: Handler<Params<"ADD_ORDER">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState(
    {
      searchedItemListWithKey: {
        ...state.searchedItemListWithKey,
        [DIAGNOSIS_SEARCH_ID]: [],
      },
      ImagingOrderSequence: {
        ...state.ImagingOrderSequence,
        imagingItemList: [],
        imagingItemDetail: {
          order_div:
            state.selectedEncounter?.division || controller.data.division,
          is_emergency: false,
          extra: {
            out_time: false,
            secret: false,
            repeat_medication: false,
            lawsuit: false,
          },
        },
      },
    },
    async () => {
      HandleEditId(controller, params)
    }
  );
};

const HandleEditId: Handler = async (controller, params) => {
  let result: any = null;

  if (params.orderId) {
    [result] = await ImagingOrderDetail.retrieve({
      apiToken: controller.apiToken,
      pk: params.orderId,
    });

    Action(controller, { action: "EDIT_REQUEST", data: result });
  }

  const orderDoctor = await controller.handleEvent({
    message: "GetAppointmentOrderDoctor",
    params: { orderBy: result?.order_by },
  });

  const state = controller.getState();

  controller.setState({
    ImagingOrderSequence: {
      ...state.ImagingOrderSequence,
      ...orderDoctor
    }
  })
};

const HandleAddImagingItem: Handler<Params<"ADD_ITEM">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  const imagingOrder = state.ImagingOrderSequence || {};

  const item = state.searchedItemListWithKey?.[XRAY_SEARCH_ID].find(
    (item: any) => item.id === params.itemId
  );

  if (item) {
    const list = [...(imagingOrder.imagingItemList || [])];

    item.product = item.id;
    item.eligibility_type = 1; // detailt eligibility_type = 1 เพื่อการรักษา
    item.urgency = "ROUTINE"; // detailt urgency = ROUTINE

    delete item.id;

    const estimate = await GetImagingOrderEstimate(controller, {
      encounter: state.selectedEncounter?.id,
      items: [item],
    });

    const index = list.findIndex((item) => item.product === params.itemId);
    const data = list[index];

    const readOnly =
      !!data?.status && ORDER_ITEM_STATUS_LABEL[data.status] !== "REQUESTED";

    if (readOnly) {
      controller.setState({
        searchedItemListWithKey: {
          ...state.searchedItemListWithKey,
          [XRAY_SEARCH_ID]: [],
        },
      });
      return;
    }

    // หาก item มีอยู่แล้วให้เพิ่ม qty
    if (index >= 0) {
      const qty = Number(data.quantity) + 1;

      // หากเป็น item ที่บันทึกเรียบร้อยแล้วกลับมาแก้ไข
      if (data.id) {
        controller.setState({
          searchedItemListWithKey: {
            ...state.searchedItemListWithKey,
            [XRAY_SEARCH_ID]: [],
          },
        });

        return HandleEditImagingItem(controller, {
          action: "EDIT_ITEM",
          data: { quantity: qty },
          index,
        });
      } else {
        list[index].quantity = qty;
      }
    } else {
      list.push({
        ...item,
        quantity: "1",
        active: true,
        ...(estimate.orders?.[0] || {}),
      });
    }

    controller.setState({
      searchedItemListWithKey: {
        ...state.searchedItemListWithKey,
        [XRAY_SEARCH_ID]: [],
      },
      ImagingOrderSequence: {
        ...state.ImagingOrderSequence,
        imagingItemList: list,
      },
    });
  }
};

const HandleEditImagingItem: Handler<Params<"EDIT_ITEM">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  const index = params.index;
  const list = [...(state.ImagingOrderSequence?.imagingItemList || [])];
  const item: any = {
    ...list[index],
  };

  for (const key in params.data) {
    let value = (params.data as any)[key];

    if (key === "quantity") {
      // type number value ไม่น้อยกว่า 1
      value = Number(value) < 1 ? 1 : value;
    }

    if (key === "locations_name" && !!state.clinicalFindingId) {
      const detail = formattedLocationsName(controller as any, {
        index: state.clinicalFindingIndex || 0,
        id: state.clinicalFindingId,
      });

      item[key] = detail.data.locations_name;
    } else {
      item[key] = value;
    }
  }

  item.editing = true;

  const estimate = await GetImagingOrderEstimate(controller, {
    encounter: state.selectedEncounter?.id,
    items: [item],
  });

  list[index] = { ...item, ...(estimate.orders?.[0] || {}) };

  controller.setState({
    ImagingOrderSequence: {
      ...state.ImagingOrderSequence,
      imagingItemList: [...list],
    },
  });

  params.onSuccess?.();
};

const HandleDeleteImagingOrder: Handler<Params<"CANCEL_ORDER">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  const result = await ImagingOrderDetail.delete({
    // เปลี่ยนเป็น delete
    apiToken: controller.apiToken,
    pk: params.data.id,
    extra: {
      data: {
        canceling_user: state.userTokenize?.token,
        note:
          typeof params.reason === "string"
            ? params.reason
            : params.reason.join(","),
      },
      division: controller.data.division,
      device: controller.data?.device,
    },
  } as any);

  if (result[1]) {
    SetErrorMessage(controller, { ...params, error: result[1] });
  } else {
    await controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: null,
      },
    });

    Action(controller, { action: "SEARCH" });

    params.onSuccess?.();
  }
};

const HandleConfirmOrder: Handler<Params<"CONFIRM_ORDER">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.action}`]: "LOADING",
    },
  });

  const imaging = state.ImagingOrderSequence;
  const list = imaging?.imagingItemList || [];
  const filterList = list.filter((item) => !!item.active);
  const detail = imaging?.imagingItemDetail || {};

  const api = detail.id ? ImagingOrderDetail.patch : ImagingOrderList.create;

  const isSomeRequestItem = list.some(
    (item) =>
      !item.status || ORDER_ITEM_STATUS_LABEL[item.status] === "REQUESTED"
  );
  const readyToViewItems = list.filter(
    (item) =>
      item.editing &&
      ["REQUESTED", "REGISTERED", "EXECUTED"].includes(
        ORDER_ITEM_STATUS_LABEL[item.status]
      )
  );

  let result: any[] = [null, null, null];

  if (isSomeRequestItem) {
    if (readyToViewItems.length) {
      // ทำการอัพเดต ready to view เมื่อเป็น status registered หรือ executed ด้วย action เนื่องจาก
      // imaging-order แก้ไขได้เฉพาะเป็น requested
      const promiseArr = readyToViewItems.map((item) =>
        UpdateOrderItemAction(controller, {
          data: {
            id: item.id,
            ready_to_view: item.ready_to_view,
            image_id: item.image_id,
            image_url: item.image_url,
          },
          actionType: item.ready_to_view ? "READY_TO_VIEW" : "UNREADY_TO_VIEW",
          isErrorKey: true,
        })
      );

      const response = await Promise.all(promiseArr);

      const isError = response.some((res) => res[1]);

      if (isError) {
        const errors = Object.fromEntries(
          response.flatMap((res) => Object.entries(res[1]))
        );

        return SetErrorMessage(controller, {
          ...params,
          error: {
            order_items: list.map((item) => errors[item.id] || {}),
          },
        });
      }
    }

    result = await api({
      apiToken: controller.apiToken,
      pk: detail.id,
      data: {
        action: detail.id ? "EDIT" : "ORDER",
        encounter: state.selectedEncounter?.id,
        emr: state.selectedEncounter?.medical_record,
        suspected_diagnosis: detail.suspected_diagnosis,
        is_emergency: detail.is_emergency,
        extra: detail.extra,
        order_items: filterList,
        order_status: params.isAppointment ? 1 : 2,
        order_by: params.isAppointment ? params?.order_by : undefined,
      } as any,
      extra: {
        division: detail.order_div,
        device: controller.data?.device,
      },
    });
  }

  if (result[1]) {
    SetErrorMessage(controller, { ...params, error: result[1] });
  } else {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "SUCCESS",
      },
    });

    params.onSuccess?.();

    if (!params.editMode) {
      Action(controller, { action: "SEARCH" });
    } else {
      // - Update preOrderList หน้า คำสั่งแพทย์ สำหรับผ่าตัด
      let preOrderList = [...(state.preOrderList || [])];

      const mapItem = (item: any) => ({
        ...(item || {}),
        specific_label_type: "imagingorder",
        type: "imagingorder",
        summary_detail: result[0]?.order_summary,
      });

      if (detail.id) {
        preOrderList = preOrderList.map((item: any) =>
          item.id === detail.id ? mapItem(item) : { ...item }
        );
      } else {
        preOrderList.push(mapItem(result[0]));
      }

      controller.setState({ preOrderList: preOrderList });
    }
  }
};

const HandleEditImagingRequest: Handler<Params<"EDIT_REQUEST">> = async (
  controller,
  params
) => {
  let state = controller.getState();

  const estimate = await GetImagingOrderEstimate(controller, {
    encounter: state.selectedEncounter?.id,
    items: params.data.order_items,
  });

  const [icd10KeyUp] = await GetIcd10KeyUp(controller, {
    method: "icdcode",
    search: params.data.suspected_diagnosis_code,
  });

  const icd10 = icd10KeyUp?.response?.[0];

  const items = params.data.order_items.map((item: any, index: number) => ({
    ...item,
    name: item.product_name,
    code: Array.from((item.name_code as string).matchAll(/\[(\w+)\]/g))?.[0]?.[1] || "",
    active: true,
    ...(estimate.orders?.[index] || {}),
    allow_editing: CONFIG.RIS_BASE_URL ? false : item.allow_editing,
  }));

  state = controller.getState();

  return controller.setState({
    searchedItemListWithKey: {
      ...state.searchedItemListWithKey,
      [DIAGNOSIS_SEARCH_ID]: icd10
        ? [
            {
              ...icd10,
              id: icd10.icd10_id,
              name: `[${icd10.icdcode}] ${icd10.icdterm}`,
            },
          ]
        : [],
    },
    ImagingOrderSequence: {
      ...state.ImagingOrderSequence,
      imagingItemDetail: params.data,
      imagingItemList: items,
    },
  });
};

const GetImagingOrderEstimate: Handler<{
  encounter: number;
  items: any;
}> = async (controller, params) => {
  const result = await ImagingOrderEstimate.post({
    apiToken: controller.apiToken,
    data: {
      encounter: params.encounter,
      order_items: params.items,
    },
    extra: { division: controller.data.division },
  });

  return result?.[0] || {};
};
