import WasmController from "react-lib/frameworks/WasmController";

// APIs
import SegmentGroupList from "issara-sdk/apis/SegmentGroupList_apps_PHR";
import SegmentGroupDetail from "issara-sdk/apis/SegmentGroupDetail_apps_PHR";
import PatientSegmentList from "issara-sdk/apis/PatientSegmentList_apps_PHR";
import PatientSegmentNameList from "issara-sdk/apis/PatientSegmentNameList_apps_PHR";

// Serializer
import SegmentGroupSerializer from "issara-sdk/types/SegmentGroupSerializer_apps_PHR";
import SegmentGroupCreateUpdateSerializer from "issara-sdk/types/SegmentGroupCreateUpdateSerializer_apps_PHR";

// Interface
import { State as MainState } from "../../../../../HIS/MainHISInterface";

export type State = Partial<{
  // sequence
  UnderlyingDiseaseSequence: Partial<{
    sequenceIndex: "Start" | "Action" | null;
    segmentGroupList: SegmentGroupType[];
    segmentGroupDetail: SegmentGroupDetailType;
    subSegmentList: SubSegmentType[];
    patientSegmentList?: any[];
  }> | null;
}>;

export type SegmentGroupType = SegmentGroupSerializer;

export type SegmentGroupDetailType = SegmentGroupCreateUpdateSerializer | null;

export type SubSegmentType = Partial<{
  id?: number;
  code: string;
  name: string;
  active: boolean;
  error: Partial<{
    name: boolean;
    code: true;
  }>;
}>;

export type FilterPatientSegmentType = Partial<{
  hn: string;
  fullName: string;
  subSegmentId: number;
}>;

type Picked = Partial<
  Pick<MainState, "buttonLoadCheck" | "errorMessage" | "selectedDivision">
>;

// Sequence
type SeqState = {
  sequence: "UnderlyingDisease";
  restart?: boolean;
  clear?: boolean;
};
// Handle Action
type ActionType =
  | {
      action: "CREATE_SEGMENT_GROUP";
      data: Record<string, string>;
      card: string;
      errorKey?: string;
      // callback
      onSuccess?: Function;
    }
  | {
      action: "DELETE_SEGMENT_GROUP";
      card: string;
      errorKey?: string;
      // callback
      onSuccess?: Function;
    }
  | { action: "FETCH_SEGMENT_GROUP"; name?: string }
  | {
      action: "SELECT_SEGMENT_GROUP";
      data: SegmentGroupSerializer;
      fetchPatientSegment?: boolean;
      card?: string;
    }
  | {
      action: "CHANGE_SUB_SEGMENT";
      method: "ADD" | "UPDATE" | "DELETE" | "CHECK";
      index?: number;
      name?: keyof SubSegmentType;
      value?: string | boolean;
      confirm?: boolean;
      card?: string;
      errorKey?: string;
      // callback
      callback?: (type: string, data?: Record<string, any>) => any;
    }
  | { action: "CREATE_SEGMENT_RESULT"; card: string; errorKey: string }
  | {
      action: "CREATE_PATIENT_SEGMENT";
      card: string;
      errorKey: string;
      buttonKey: string;
      patients: { hn: string; result: string }[];
      group: string;
      method?: "DELETE" | "EDIT";
      oldResult?: string;
      // callback
      onSuccess?: Function;
    }
  | ({
      action: "FETCH_PATIENT_SEGMENT";
      card: string;
    } & FilterPatientSegmentType);

type SeqAct = ActionType & SeqState;
type SeqType<K> = K extends { action: string } ? Extract<SeqAct, K> : SeqState;

export type RunSequence = <K extends keyof SeqAct>(
  params: SeqType<Pick<SeqAct, K>>
) => any;

type Params<A extends ActionType["action"]> = Extract<
  ActionType,
  { action: A }
>;

export const StateInitial: State = {
  // sequence
  UnderlyingDiseaseSequence: {
    sequenceIndex: null,
    segmentGroupList: [],
  },
};

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State & Picked, Event, Data>,
  params?: any
) => any;

/* ------------------------------------------------------ */

/*                          START                         */

/* ------------------------------------------------------ */
export const GetMaster: Handler = async (controller, params: SeqState) => {
  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [],
    },
  } as any);

  const state = controller.getState();

  controller.setState(
    {
      UnderlyingDiseaseSequence: {
        ...state.UnderlyingDiseaseSequence,
        sequenceIndex: "Action",
      },
    },
    () =>
      controller.handleEvent({ message: "RunSequence", params: { ...params } })
  );
};

/* ------------------------------------------------------ */

/*                      Handle Action                     */

/* ------------------------------------------------------ */
export const Action: Handler = async (controller, params: ActionType) => {
  if (params.action === "FETCH_SEGMENT_GROUP") {
    HandleFetchSegmentGroup(controller, params);
  } else if (params.action === "CREATE_SEGMENT_GROUP") {
    HandleCreateSegmentGroup(controller, params);
  } else if (params.action === "DELETE_SEGMENT_GROUP") {
    HandleDeleteSegmentGroup(controller, params);
  } else if (params.action === "SELECT_SEGMENT_GROUP") {
    HandleSelectSegmentGroup(controller, params);
  } else if (params.action === "CHANGE_SUB_SEGMENT") {
    HandleChangeSubSegment(controller, params);
  } else if (params.action === "CREATE_SEGMENT_RESULT") {
    HandleCreateSegmentResult(controller, params);
  } else if (params.action === "CREATE_PATIENT_SEGMENT") {
    HandleCreatePatientSegment(controller, params);
  } else if (params.action === "FETCH_PATIENT_SEGMENT") {
    HandleFetchPatientSegment(controller, params);
  }
};

const HandleCreateSegmentGroup: Handler = async (
  controller,
  params: Params<"CREATE_SEGMENT_GROUP">
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_SAVE`]: "LOADING",
    },
  });

  const segment = await SegmentGroupList.create({
    apiToken: controller.apiToken,
    data: {
      ...params.data,
      // "code": "TT02", // SegementGroup code,
      // "name": "TEST SEGMENT GROUP 02", // SegementGroup name
      // "name_en": "", // SegementGroup name_en
      active: true, // SegementGroup
      sub_segments: [],
    } as any,
  });

  if (segment[0]) {
    params.onSuccess?.();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_SAVE`]: "SUCCESS",
      },
    });
    HandleFetchSegmentGroup(controller, params);
  } else {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_SAVE`]: "ERROR",
      },
      errorMessage: {
        ...state.errorMessage,
        [params.errorKey || ""]: segment[1],
      },
    });
  }
};

const HandleDeleteSegmentGroup: Handler = async (
  controller,
  params: Params<"DELETE_SEGMENT_GROUP">
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_DELETE`]: "LOADING",
    },
  });

  const id = state.UnderlyingDiseaseSequence?.segmentGroupDetail?.id;

  const detail = await SegmentGroupDetail.delete({
    pk: id,
    apiToken: controller.apiToken,
  });

  if (detail[1]) {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_DELETE`]: "ERROR",
      },
      errorMessage: {
        ...state.errorMessage,
        [params.errorKey || ""]: detail[1],
      },
    });
  } else {
    params.onSuccess?.();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_DELETE`]: "SUCCESS",
      },
      UnderlyingDiseaseSequence: {
        ...state.UnderlyingDiseaseSequence,
        segmentGroupDetail: null,
        subSegmentList: [],
      },
    });
    HandleFetchSegmentGroup(controller, params);
  }
};

const HandleFetchSegmentGroup: Handler = async (
  controller,
  params: Params<"FETCH_SEGMENT_GROUP">
) => {
  const segment = await SegmentGroupList.list({
    apiToken: controller.apiToken,
    params: { type: "UD", name: params.name },
  });

  const state = controller.getState();

  controller.setState({
    UnderlyingDiseaseSequence: {
      ...state.UnderlyingDiseaseSequence,
      segmentGroupList: segment[0]?.items || [],
    },
  });
};

const HandleSelectSegmentGroup: Handler = async (
  controller,
  params: Params<"SELECT_SEGMENT_GROUP">
) => {
  const detail = await SegmentGroupDetail.retrieve({
    pk: params.data.id,
    apiToken: controller.apiToken,
  });

  const state = controller.getState();

  controller.setState(
    {
      UnderlyingDiseaseSequence: {
        ...state.UnderlyingDiseaseSequence,
        segmentGroupDetail: detail[0] || {},
        subSegmentList: [...(detail[0]?.sub_segments || [])],
      },
    },
    () =>
      params.fetchPatientSegment &&
      HandleFetchPatientSegment(controller, params)
  );
};

const HandleChangeSubSegment: Handler = async (
  controller,
  params: Params<"CHANGE_SUB_SEGMENT">
) => {
  const state = controller.getState();
  const data = state.UnderlyingDiseaseSequence || {};

  const list = [...(state.UnderlyingDiseaseSequence?.subSegmentList || [])];
  const { index = 0, name = "", value = "" } = params;

  // let isCorrect = false;

  // ADD
  if (params.method === "ADD") {
    list.push({ name: "", code: "", active: true });
  }
  // UPDATE
  else if (params.method === "UPDATE") {
    (list as any)[index][name] = value;
  }
  // DELETE
  else if (params.method === "DELETE") {
    const item = (list as any)[index];

    // ยังไม่ได้บันทึก
    if (!item.id) {
      (list as any)[index]["active"] = false;
    }
    // หากบันทึกแล้วต้องการลบ
    else {
      // let message = "CONFIRM"; //HAVE_MEMBERS, CONFIRM

      // const haveMembers = (data.patientSegmentList || [])?.some(
      //   (acc) => acc.result === item.code
      // );

      if (params.confirm) {
        const updateRes = await UpdateSegmentGroup(controller, {
          list: list.filter((_, idx) => idx !== index),
        });

        if (updateRes?.[1]) {
          if (updateRes[1]?.[0]?.includes("ไม่สามารถลบกลุ่มย่อย")) {
            params.callback?.("HAVE_MEMBERS");
          } else {
            controller.setState({
              errorMessage: {
                ...state.errorMessage,
                [params.errorKey || ""]: updateRes[1],
              },
            });
          }
          return;
        }

        HandleSelectSegmentGroup(controller, { data: data.segmentGroupDetail });

        params.callback?.("");
      } else {
        params.callback?.("CONFIRM", { index });
      }

      return;
    }
  }
  // else if (params.method === "CHECK") {
  // for (const item of list) {
  //   item.error = {
  //     ...(!item.name && { name: true }),
  //     ...(!item.code && { code: true }),
  //   };
  // }
  // isCorrect = true; //list.every((item) => !Object.keys(item.error || {}).length);
  // }

  controller.setState({
    UnderlyingDiseaseSequence: {
      ...state.UnderlyingDiseaseSequence,
      subSegmentList: list,
    },
  });
};

const HandleCreateSegmentResult: Handler = async (
  controller,
  params: Params<"CREATE_SEGMENT_RESULT">
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_SAVE`]: "LOADING",
    },
  });

  const data = state.UnderlyingDiseaseSequence || {};
  const updateData = (data.subSegmentList || []).filter(
    (item) => item.active && item.name && item.code
  );

  const [updateRes, updateError] = await UpdateSegmentGroup(controller, {
    list: updateData,
  });

  if (updateRes) {
    const list = (data.segmentGroupList || []).map((item) =>
      item.id === updateRes.id
        ? {
            ...item,
            code: updateRes.code,
            name: updateRes.name,
            name_en: updateRes.name_en,
          }
        : item
    );

    controller.setState({
      UnderlyingDiseaseSequence: {
        ...state.UnderlyingDiseaseSequence,
        segmentGroupList: [...list],
      },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_SAVE`]: "SUCCESS",
      },
    });

    HandleSelectSegmentGroup(controller, { data: data.segmentGroupDetail });
  } else {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_SAVE`]: "ERROR",
      },
      errorMessage: {
        ...state.errorMessage,
        [params.errorKey]: updateError,
      },
    });
  }
};

const HandleCreatePatientSegment: Handler = async (
  controller,
  params: Params<"CREATE_PATIENT_SEGMENT">
) => {  
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.buttonKey}`]: "LOADING",
    },
  });

  // const group = params.patients.reduce((result, item) => {
  //   const key = item.result;
  //   if (key in result) {
  //     result[key].push(item.hn);
  //   } else {
  //     result[key] = [item.hn];
  //   }
  //   return result;
  // }, {} as Record<string, string[]>);

  // const promiseArr = Object.entries(group).map(([key, hnList]) =>
  //   PatientSegmentNameList.post({
  //     apiToken: controller.apiToken,
  //     data: {
  //       hn_list: hnList, //Patient hn list
  //       segment_type: "CLINICAL", //required as "CLINICAL"
  //       segment_group: params.group, // SegmentGroup code
  //       old_result: params.oldResult, // เมื่อทำการเปลี่ยนกลุ่มย่อย,
  //       result: key, //SubSegmentGroup code
  //       // patients: params.patients,
  //       action: params.method || "CREATE",
  //     },
  //   })
  // );
  
  // const segments = await Promise.all(promiseArr);

  // if (segments.some((res) => res[1])) {
  //   controller.setState({
  //     buttonLoadCheck: {
  //       ...state.buttonLoadCheck,
  //       [`${params.card || ""}_${params.buttonKey}`]: "ERROR",
  //     },
  //     errorMessage: {
  //       ...state.errorMessage,
  //       [params.errorKey]: segments[0][1],
  //     },
  //   });
  // } else {
  //   params.onSuccess?.();

  //   controller.setState({
  //     buttonLoadCheck: {
  //       ...state.buttonLoadCheck,
  //       [`${params.card || ""}_${params.buttonKey}`]: "SUCCESS",
  //     },
  //   });

  //   HandleFetchPatientSegment(controller, params);
  // }
  const hnList = params.patients?.map((item: any) => item.hn);
  const [resp, err, netw] = await PatientSegmentNameList.post({
    apiToken: controller.apiToken,
    data: {
      hn_list: hnList, //Patient hn list
      segment_type: "MARKETING", //required as "CLINICAL"
      group: params.group,
      action: params.method || "CREATE",
    },
  });
  if (err) {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card || ""}_${params.buttonKey}`]: "ERROR",
      },
      errorMessage: {
        ...state.errorMessage,
        [params.errorKey]: err,
      },
    });
  } else {
    params.onSuccess?.();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card || ""}_${params.buttonKey}`]: "SUCCESS",
      },
    });

    HandleFetchPatientSegment(controller, params);
  }
};

const HandleFetchPatientSegment: Handler = async (
  controller,
  params: Params<"FETCH_PATIENT_SEGMENT">
) => {
  let state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_SEARCH`]: "LOADING",
    },
  });

  const segment_group = state.UnderlyingDiseaseSequence?.segmentGroupDetail?.code || "";
  const group = state.UnderlyingDiseaseSequence?.segmentGroupDetail?.id || "";

  const segment = await PatientSegmentList.list({
    apiToken: controller.apiToken,
    params: {
      // hn: params.hn || undefined, //<Patient hn>
      // use_segment_group_code: true, //(REQUIRED)
      // //   proxy_patient: <Patient id>
      // segment_type: "CLINICAL", //(CLINICAL || MARKETING)
      // segment_group: segment_group, //as SegementGroup name
      // //   result: string as SubSegmentGroup name
      // //   result_contain: find string like%
      // //   keyword: find Patient data like keyword
      // //   segment_group_id: <SegmentGroup id>
      // sub_segment_group_id: params.subSegmentId || undefined, // <SubSegmentGroup id>
      // full_name: params.fullName || undefined, //Patient full name search
      group: group,
    },
  });

  state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_SEARCH`]: segment[1] ? "ERROR" : "SUCCESS",
    },
    UnderlyingDiseaseSequence: {
      ...state.UnderlyingDiseaseSequence,
      patientSegmentList: segment[0]?.items || [],
    },
  });
};

// API
const UpdateSegmentGroup: Handler = (controller, params) => {
  const state = controller.getState();

  const detail = state.UnderlyingDiseaseSequence?.segmentGroupDetail || {};

  return SegmentGroupDetail.update({
    pk: detail.id,
    apiToken: controller.apiToken,
    data: {
      ...detail,
      sub_segments: params.list,
    } as any,
  });
};

/* ------------------------------------------------------ */

/*                          Utils                         */

/* ------------------------------------------------------ */
