import React, { CSSProperties, useState, useMemo, useEffect } from "react";

import { Button, Icon, Input, Modal, Dimmer, Loader } from "semantic-ui-react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

// @ts-ignore
import Cookies from "js-cookie";

// Interface
import {
  Event as MobEvent,
  State as MobState,
} from "../../../../patient-lib/MobTransformInterface";

// UI
import TFHead from "./TFHead";
import { DisplayTitle, PencilIcon } from "./TFRegister";
import ModMessage from "./ModMessage";

// Common

// Style
import "./Transform.scss";
import { useIntl } from "react-intl";

type TransformRegisterVerifyIdentityProps = {
  onEvent: (e: MobEvent) => any;
  userId?: number | null;
  history?: any;
  onHaveApiToken?: (history: any) => any;
} & Pick<
  MobState,
  | "openModVerify"
  | "userProfile"
  | "successMessage"
  | "errorMessage"
  | "loadingMainTransform"
>;

const COLOR = {
  primary: "var(--primary-theme-color)",
  secondary: "#F2C94C",
  violet_blue: "#2D247F",
  font_white: "white",
  bg_white: "white",
  dark_grey: "#333333",
  orange_light: "#EB5757",
};

const ICON = {
  call_center: "/images/tuh/live-support-agent headset.png",
  email: "/images/tuh/email.png",
};

const styles = {
  label: {
    color: COLOR.primary,
    fontSize: "16px",
    marginBottom: "10px",
    textAlign: "left",
    fontWeight: "bold",
  } as CSSProperties,
  button: {
    background: COLOR.primary,
    color: COLOR.font_white,
    fontSize: "16px",
    padding: "15px 0",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  } as CSSProperties,
  button_rounded: {
    background: COLOR.bg_white,
    color: COLOR.violet_blue,
    fontSize: "16px",
    // padding: "15px 0",
    height: "66.5px",
    borderRadius: "50rem",
    boxShadow: "0 2px 4px 1px rgb(131 131 131 / 30%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  } as CSSProperties,
  icon: {
    color: COLOR.violet_blue,
    width: "50px",
    fontSize: "25px",
    marginBottom: "-5px",
  } as CSSProperties,
  hn_number: {
    fontSize: "26px",
    fontWeight: "bold",
    color: COLOR.violet_blue,
    margin: "20px",
  } as CSSProperties,
};

type TFModInfoProps = {
  open?: boolean;
  type?: "check_email" | "not_available" | "staff" | "no_hn";
  style?: CSSProperties;
  buttonName?: string;
  titleName?: string;
  // callback
  onClose?: () => any;
  onApprove?: () => any;
};

export const TFModInfo: React.FunctionComponent<TFModInfoProps> = (props) => {
  const intl = useIntl();
  return (
    <Modal
      open={props.open || false}
      className="tf-register tf-mod-info"
      onClose={props.onClose}
      closeOnDimmerClick
    >
      <div>
        <div className="mod-header">
          {props.type === "check_email" ? (
            <div style={{ color: COLOR.primary }}>{intl.formatMessage({ id: "กรุณาตรวจสอบอีเมล" })}</div>
          ) : props.type === "not_available" ? (
            <div style={{ color: COLOR.orange_light }}>{intl.formatMessage({ id: "ไม่สามารใช้งาน HN นี้ได้" })}</div>
          ) : props.type === "staff" ? (
            <div style={{ color: COLOR.primary }}>{intl.formatMessage({ id: "กรุณาติดต่อเจ้าหน้าที่" })}</div>
          ) : props.type === "no_hn" ? (
            <div style={{ color: COLOR.orange_light }}>{intl.formatMessage({ id: "ไม่สามารถยืนยันตัวตนได้" })}</div>
          ) : (
            props.titleName
          )}
        </div>
        <div className="box-modal-detail" style={props.style || {}}>
          {props.children}
        </div>
        <Button fluid style={styles.button} onClick={props.onApprove}>
          {props.buttonName}
        </Button>
      </div>
    </Modal>
  );
};

const TransformRegisterVerifyIdentity: React.FunctionComponent<
  TransformRegisterVerifyIdentityProps
> = (props) => {
  useEffect(() => {
    if (Cookies.get("apiToken")) {
      props.onHaveApiToken?.(props.history);
    }
  }, []);

  /* ----------------------- handle ----------------------- */
  const handleConfirmByEmail = () => {
    props.onEvent({
      message: "HandleVerifyByEmail",
      params: {
        hn: JSON.parse(props.userProfile?.extra || "{}")?.hn || "",
      },
    });
  };

  const handleConfirmAtCounter = () => {
    props.onEvent({
      message: "HandleSetOpenModVerify",
      params: { open: true, type: "staff" },
    });
  };

  const handleCloseModal = () => {
    props.onEvent({
      message: "HandleSetOpenModVerify",
      params: {},
    });
  };

  const handleLeftIconClick = () => {
    props.onEvent({
      message: "HandleGoBackVerify",
      params: { history: props.history },
    });
  };

  const handleGoToEditProfile = () => {
    props.onEvent({
      message: "HandleGoToEditProfile",
      params: { history: props.history },
    });
  };

  const handleGoToEditHN = () => {
    props.onEvent({
      message: "HandleGoToEditHN",
      params: { history: props.history },
    });
  };

  const handleOpenLink = () => {
    props.onEvent({ message: "HandleOpenLinkEKYC", params: {} });
  };

  return (
    <div className="tf-register">
      <Dimmer
        active={props.loadingMainTransform}
        inverted
        style={{ zIndex: 1100 }}
      >
        <Loader inverted>Loading</Loader>
      </Dimmer>

      <TFHead title={"รอยืนยันตัวตน"} leftIconClick={handleLeftIconClick} />

      <div
        className="tf-main"
        style={{ height: "calc(100vh - 38px)", overflow: "auto" }}
      >
        <div>
          <DisplayTitle
            title="ท่านลงทะเบียนสำเร็จแล้ว"
            boxStyle={{ fontSize: "22px", marginTop: "10%" }}
          />

          <div style={{ display: "grid" }}>
            <label style={styles.label}>{intl.formatMessage({ id: "ชื่อ-นามสกุล" })}</label>
            <Input
              value={props.userProfile?.fullname || ""}
              icon
              placeholder=""
              style={{ fontSize: "16px" }}
              readOnly={true}
            >
              <input />
              <PencilIcon onClick={handleGoToEditProfile} />
            </Input>
          </div>
          <div style={{ display: "grid", marginTop: "15px" }}>
            <label style={styles.label}>HN</label>
            <Input
              value={
                JSON.parse(props.userProfile?.extra || "{}")?.hn || "ยังไม่ระบุ"
              }
              icon
              placeholder=""
              style={{ fontSize: "16px" }}
              readOnly={true}
            >
              <input />
              <PencilIcon onClick={handleGoToEditHN} />
            </Input>
          </div>
        </div>

        <div style={{ marginTop: "35px" }}>
          <DisplayTitle
            descriptStyle={{ fontWeight: "bold", color: COLOR.dark_grey }}
            description={[
              "กรุณายืนยันตัวตนกับโรงพยาบาล",
              "หรือจากอีเมลเพื่อให้สามารถใช้งาน",
              "TUH Transform ได้",
            ]}
          />
        </div>

        <Button
          fluid
          style={styles.button_rounded}
          onClick={handleConfirmByEmail}
        >
          <img
            src={ICON.email}
            alt="email.icon"
            style={{ margin: "0px 14px 0 5px", width: "35px" }}
          />
          ยืนยันตัวตนผ่าน Email
        </Button>

        <Button
          fluid
          style={{ ...styles.button_rounded, marginTop: "15px" }}
          onClick={handleConfirmAtCounter}
        >
          <img
            src={ICON.call_center}
            alt="call_center.icon"
            style={{ margin: "0 17px", width: "30px" }}
          />
          ยืนยันตัวตนที่เคาน์เตอร์
        </Button>

        <div style={{ marginTop: "40px" }}>
          <DisplayTitle
            descriptStyle={{ fontSize: "12px", color: COLOR.dark_grey }}
            description={[
              "หากท่านยืนยันตัวตนเรียบร้อยแล้ว",
              "แต่ยังไม่สามารถใช้งานได้ กรุณาติดต่อเจ้าหน้าที่",
              "เบอร์โทร 02-001-1234",
            ]}
          />
        </div>
      </div>

      {/* Modal */}
      <TFModInfo
        open={props.openModVerify?.open || false}
        onClose={handleCloseModal}
        style={
          props.openModVerify?.type === "staff" ? { marginBottom: "10px" } : {}
        }
        onApprove={() => {
          if (
            ["check_email", "staff", "no_hn"].includes(
              props.openModVerify?.type || ""
            )
          ) {
            handleCloseModal();
          } else if (props.openModVerify?.type === "not_available") {
            handleOpenLink();
          }
        }}
        buttonName={
          ["check_email", "staff", "no_hn"].includes(
            props.openModVerify?.type || ""
          )
            ? "ปิด"
            : "ลงทะเบียน HN"
        }
        type={props.openModVerify?.type}
      >
        {props.openModVerify?.type === "check_email" ? (
          <>
            <div>{intl.formatMessage({ id: "ขณะนี้ได้ส่ง link สำหรับเริ่มต้นใช้งาน" })}</div>
            <div>{intl.formatMessage({ id: "ไปที่ Email ที่ได้ลงทะเบียนไว้" })}</div>
            <div>{intl.formatMessage({ id: "กับทางโรงพยาบาลเรียบร้อยแล้ว" })}</div>
            <div>{intl.formatMessage({ id: "กรุณาตรวจสอบเพื่อเข้าสู่ระบบ" })}</div>
          </>
        ) : props.openModVerify?.type === "not_available" ? (
          <>
            <div>{intl.formatMessage({ id: "เนื่องจาก HN นี้ยังไม่ได้ถูกลงทะเบียน" })}</div>
            <div>{intl.formatMessage({ id: "กับโรงพยาบาลเฉลิมพระเกียรติ" })}</div>
            <div>{intl.formatMessage({ id: "กรุณาลงทะเบียนก่อนเริ่มต้นใช้งาน" })}</div>
          </>
        ) : props.openModVerify?.type === "staff" ? (
          <>
            <div style={styles.hn_number}>
              IH ID : {props.userProfile?.patient}
            </div>
            <div>{intl.formatMessage({ id: "กรุณาแจ้งเลข ID ของท่าน" })}</div>
            <div>{intl.formatMessage({ id: "กับเจ้าหน้าที่พยาบาลเพื่อยืนยันตัวตน" })}</div>
          </>
        ) : props.openModVerify?.type === "no_hn" ? (
          <>
            <div>{intl.formatMessage({ id: "เนื่องจากยังไม่ได้ระบุ HN" })}</div>
            <div>{intl.formatMessage({ id: "กรุณากรอก HN ให้ถูกต้อง" })}</div>
          </>
        ) : null}
      </TFModInfo>

      <ModMessage
        onEvent={props.onEvent}
        successMessage={props.successMessage}
        errorMessage={props.errorMessage}
      />
    </div>
  );
};

TransformRegisterVerifyIdentity.defaultProps = {
  userProfile: {},
  userId: null,
  history: {},
} as TransformRegisterVerifyIdentityProps;

export default React.memo(TransformRegisterVerifyIdentity);
