import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button,
  Input,
  Dropdown,
  Icon
} from 'semantic-ui-react'

const CardStaffSearchUX = (props: any) => {
    return(
      <div
        style={{padding: "10px"}}>
        
        <div
          style={{display:"flex",marginBottom:"10px", alignItems: "center"}}>
          {}
          <div
            style={{marginLeft:"10px",display:props.organizationFilter ? "flex": "none",alignItems:"center", minWidth: "max-content"}}>
            องค์กร
          </div>
          <div
            style={{marginLeft:"10px", display: props.organizationFilter ? "" : "none"}}>
            
            <Dropdown
              clearable={true}
              onChange={props.changeValue("organization")}
              options={props.divisionOptions || []}
              search={true}
              selection={true}
              value={props.organization || ""}>
            </Dropdown>
          </div>
          <div
            style={{marginLeft:"10px",display: props.organizationFilter ? "none" :"flex",alignItems:"center", minWidth: "max-content"}}>
            รหัสพนักงาน
          </div>
          <div
            style={{marginLeft:"10px", display: props.organizationFilter ? "none" : ""}}>
            {}
            <Input
              onChange={props.changeValue("searchCode")}
              value={props.searchCode}>
            </Input>
          </div>
          <div
            style={{marginLeft:"10px",display:"flex",alignItems:"center", minWidth: "max-content"}}>
            ชื่อ
          </div>
          <div
            style={{marginLeft:"10px"}}>
            
            <Input
              onChange={props.changeValue("firstName")}
              value={props.firstName}>
            </Input>
          </div>
          <div
            style={{marginLeft:"10px",display:"flex",alignItems:"center", minWidth: "max-content"}}>
            นามสกุล
          </div>
          <div
            style={{marginLeft:"10px"}}>
            
            <Input
              onChange={props.changeValue("lastName")}
              value={props.lastName}>
            </Input>
          </div>
          <div
            style={{marginLeft:"10px",display:"flex",alignItems:"center", minWidth: "max-content"}}>
            ตำแหน่งงาน
          </div>
          <div
            style={{marginLeft:"10px"}}>
            
            <Dropdown
              clearable={true}
              onChange={props.changeValue("position")}
              options={props.positionOptions}
              selection={true}
              value={props.position}>
            </Dropdown>
          </div>
          <div
            style={{flex:"1"}}>
            
          </div>
          <div
            style={{display:"flex", flexDirection: "row", height: "fit-content", margin: "-0.35rem 0 0 0.25rem"}}>
            
            <Button
              color="green"
              onClick={props.OnSearch}
              style={{minWidth: "max-content"}}>
              ค้นหา
            </Button>
            <Button
              color="blue"
              onClick={props.addStaff}
              style={{minWidth: "max-content"}}>
              <Icon
                name={"add user"}>
              </Icon>
              <label>
                เพิ่มบุคลากร
              </label>
            </Button>
          </div>
        </div>
        <div>
          
          <Table
            data={props.staffList}
            getTrProps={props.getTrProps}
            headers={`${props.organizationFilter ? "สังกัดองค์กร" : "รหัสพนักงาน"},คำนำหน้า,ชื่อ-สกุล,ตำแหน่งงาน,สถานะ`}
            keys={`${props.organizationFilter ? "divisions" : "employee.code"},pre_nameText,name,employee.position_name,employee.status_name`}
            minRows="15"
            showPagination={false}
            style={{height: "calc(100vh - 14rem)"}}>
          </Table>
        </div>
        <div
          style={{marginTop: "1rem", float: "right"}}>
          {props.Pagination}
        </div>
      </div>
    )
}


export default CardStaffSearchUX

export const screenPropsDefault = {"title":"9:00-12:00"}

/* Date Time : Mon Nov 04 2024 09:47:47 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",marginBottom:\"10px\", alignItems: \"center\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 2,
      "name": "Table",
      "parent": 3,
      "props": {
        "data": {
          "type": "code",
          "value": "props.staffList"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getTrProps"
        },
        "headers": {
          "type": "code",
          "value": "`${props.organizationFilter ? \"สังกัดองค์กร\" : \"รหัสพนักงาน\"},คำนำหน้า,ชื่อ-สกุล,ตำแหน่งงาน,สถานะ`"
        },
        "keys": {
          "type": "code",
          "value": "`${props.organizationFilter ? \"divisions\" : \"employee.code\"},pre_nameText,name,employee.position_name,employee.status_name`"
        },
        "minRows": {
          "type": "value",
          "value": "15"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"calc(100vh - 14rem)\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "รหัสพนักงาน"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\",display: props.organizationFilter ? \"none\" :\"flex\",alignItems:\"center\", minWidth: \"max-content\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\", display: props.organizationFilter ? \"none\" : \"\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\",display:\"flex\",alignItems:\"center\", minWidth: \"max-content\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "นามสกุล"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\",display:\"flex\",alignItems:\"center\", minWidth: \"max-content\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:\"1\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", flexDirection: \"row\", height: \"fit-content\", margin: \"-0.35rem 0 0 0.25rem\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "Button",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": ""
        },
        "onClick": {
          "type": "code",
          "value": "props.OnSearch"
        },
        "size": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "Input",
      "parent": 7,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"firstName\")"
        },
        "value": {
          "type": "code",
          "value": "props.firstName"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "Input",
      "parent": 10,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"lastName\")"
        },
        "value": {
          "type": "code",
          "value": "props.lastName"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ตำแหน่งงาน"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\",display:\"flex\",alignItems:\"center\", minWidth: \"max-content\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "Dropdown",
      "parent": 22,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"position\")"
        },
        "options": {
          "type": "code",
          "value": "props.positionOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.position"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "Input",
      "parent": 5,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"searchCode\")"
        },
        "value": {
          "type": "code",
          "value": "props.searchCode"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "Button",
      "parent": 16,
      "props": {
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.addStaff"
        },
        "size": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "Icon",
      "parent": 25,
      "props": {
        "name": {
          "type": "code",
          "value": "\"add user\""
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "label",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มบุคลากร"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.Pagination"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"1rem\", float: \"right\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "องค์กร"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\",display:props.organizationFilter ? \"flex\": \"none\",alignItems:\"center\", minWidth: \"max-content\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\", display: props.organizationFilter ? \"\" : \"none\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "Dropdown",
      "parent": 30,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"organization\")"
        },
        "options": {
          "type": "code",
          "value": "props.divisionOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.organization || \"\""
        }
      },
      "seq": 31,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardStaffSearchUX",
  "project": "cudent2",
  "screenPropsDefault": {
    "title": "9:00-12:00"
  },
  "width": 80
}

*********************************************************************************** */
