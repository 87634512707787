import React, { FC, useEffect, useRef, useState } from "react";
import { Button, Form, Modal, Popup } from "semantic-ui-react";
import CardLayout from "react-lib/apps/common/CardLayout"
import ErrorMessage from "react-lib/apps/common/ErrorMessage"
import PureReactTable from "react-lib/apps/common/PureReactTable"
import CardProgressNote from "../DPI/CardProgressNote";
import { ProgressionNoteList } from "../../apis/master/apps/DPI/ProgressionNote";
import { useIntl } from "react-intl";

export interface CardRehabilitationProgressNoteViewDataType {
  titleCard: string;
  closeable: boolean;
  toggleable: boolean;
  hideCallback: (...args: any[]) => any;

  django: {
    user: {
      id: number;
    };
  };
  isTechnicianPMR: boolean;
  patientData: {
    EMR: {
      emr_id: number;
    };
    ENCOUNTER: {
      doctor_id: number;
    };
  };
}

const CardRehabilitationProgressNote: FC<CardRehabilitationProgressNoteViewDataType> = (
  props
) => {
  const intl = useIntl();
  const isMounted = useRef(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<any>(null);
  const [data, setData] = useState<any>([]);
  const [openModProgressNote, setOpenModProgressNote] = useState<boolean>(
    false
  );
  const [progressNoteId, setProgressNoteId] = useState<number>(null);
  const [progressNoteReadOnly, setProgressNoteReadOnly] = useState<boolean>(
    true
  );

  const columns = [
    { Header: "วัน-เวลาที่แก้ไข", accessor: "last_edit", minWidth: 150 },
    { Header: "ผู้บันทึก", accessor: "editer", minWidth: 150 },
    { Header: "แพทย์เจ้าของไข้", accessor: "reporter", minWidth: 150 },
    { Header: "Subjective", accessor: "s_txt", minWidth: 240 },
    { Header: "Objective", accessor: "o_txt", minWidth: 240 },
    { Header: "Assessment", accessor: "a_txt", minWidth: 240 },
    { Header: "Plan", accessor: "p_txt", minWidth: 240 },
    {
      Header: "Menu",
      accessor: "menu",
      minWidth: 50,
      Cell: (row) => {
        if (props.django.user.id === row.original.editer_id) {
          return (
            <div>
              <Popup
                trigger={<Button type="button" icon="list ul" />}
                content={
                  <Button
                    color="yellow"
                    content="แก้ไข"
                    onClick={(e) => {
                      setOpenModProgressNote(true);
                      setProgressNoteId(row.original.id);
                      setProgressNoteReadOnly(false);
                    }}
                  />
                }
                on="click"
                position="bottom right"
              />
            </div>
          );
        } else {
          return (
            <div>
              <Popup
                trigger={<Button type="button" icon="list ul" />}
                content={
                  <Button
                    color="yellow"
                    content="View"
                    onClick={(e) => {
                      setOpenModProgressNote(true);
                      setProgressNoteId(row.original.id);
                      setProgressNoteReadOnly(true);
                    }}
                  />
                }
                on="click"
                position="bottom right"
              />
            </div>
          );
        }
      },
    },
  ];

  useEffect(() => {
    if (props.patientData) {
      refresh();
    }
  }, [props.patientData]);

  const refresh = async () => {
    setIsLoading(true);
    const [response, error, network] = await ProgressionNoteList.list({
      params: { emr: props.patientData.EMR.emr_id },
    });
    if (isMounted.current) {
      if (error) {
        setErrors(error);
      } else {
        setData(response.items);
      }
    }
    setIsLoading(false);
  };

  const handleOpenModCreateProgressNote = () => {
    setOpenModProgressNote(true);
    setProgressNoteId(null);
    setProgressNoteReadOnly(false);
  };

  const handleSuccessCallback = () => {
    refresh();
    setOpenModProgressNote(false);
  };

  const handleCloseModProgressNote = () => {
    setOpenModProgressNote(false);
  };

  return (
    <CardLayout
      titleText={props.titleCard}
      headerColor="orange"
      loading={isLoading}
      closeable={props.closeable}
      toggleable={props.toggleable}
      onClose={props.hideCallback}
    >
      <ErrorMessage error={errors} />
      <PureReactTable
        data={data}
        columns={columns}
        showPagination={false}
        pageSize={10}
        manual
      />
      <br />
      <Form>
        <Form.Group inline className="rightAlign">
          <Form.Field width={3}>
            <Button
              fluid
              color="green"
              type="button"
              style={{ display: props.isTechnicianPMR ? "block" : "none" }}
              onClick={handleOpenModCreateProgressNote}
            >{intl.formatMessage({ id: "สร้าง Progress note" })}</Button>
          </Form.Field>
        </Form.Group>
      </Form>

      <Modal
        open={openModProgressNote}
        closeOnDimmerClick={true}
        onClose={handleCloseModProgressNote}
      >
        <CardProgressNote
          successCallback={handleSuccessCallback}
          hideCallback={handleCloseModProgressNote}
          django={props.django}
          createModule="TEC"
          doctorId={props.patientData.ENCOUNTER.doctor_id}
          emrId={props.patientData.EMR.emr_id}
          progressionNoteId={progressNoteId}
          readOnly={progressNoteReadOnly}
          languageUX={props.languageUX}
        />
      </Modal>
    </CardLayout>
  );
};

export const CardRehabilitationProgressNoteInitialViewData: CardRehabilitationProgressNoteViewDataType = {
  titleCard: "Rehabilitation Progress note",
  closeable: false,
  toggleable: true,
  hideCallback: () => {},

  django: {
    user: {
      id: null,
    },
  },
  isTechnicianPMR: false,
  patientData: {
    EMR: {
      emr_id: null,
    },
    ENCOUNTER: {
      doctor_id: null,
    },
  },
};

CardRehabilitationProgressNote.defaultProps = CardRehabilitationProgressNoteInitialViewData;

export default CardRehabilitationProgressNote;
