import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Label,
  Form,
  FormGroup,
  FormField,
  Dropdown,
  Checkbox,
  Input
} from 'semantic-ui-react'

const CardElectricalXrayUltrasoundUX = (props: any) => {
    return(
      <div
        id="CardElectricalXrayUltrasound"
        style={{ margin: "15px",  padding: "20px",  backgroundColor: "rgba(198, 235, 243, 0.2)" }}>
        <div
          style={{ paddingBottom: "5px" }}>
          
          <label
            style={{ fontWeight: "bold", fontSize: "18px" }}>
            Electrical & X-ray & Ultrasound (D-F)
          </label>
          <Label
            color={props.statusColor}
            style={{ float: "right" }}>
            {props.status}
          </Label>
          <hr>
          </hr>
        </div>
        <Form>
          <FormGroup
            inline={true}>
            <FormField
              className="required"
              inline={true}
              style={{display: "flex"}}>
              <label
                style={{minWidth: "120px", maxWidth: "120px"}}>
                Surgical Cautery
              </label>
              <div>
                
                <Checkbox
                  checked={props.isSurgicalCautery}
                  label={props.isSurgicalCautery ? "YES" : "NO"}
                  name="is_surgical_cautery"
                  onChange={props.handleChangeData}
                  style={{minWidth: "90px", maxWidth: "90px"}}
                  toggle={true}>
                </Checkbox>
              </div>
            </FormField>
            <FormField
              inline={true}
              style={{display : props.isSurgicalCautery ? "" :"none"}}
              width={7}>
              <Dropdown
                clearable={true}
                fluid={true}
                multiple={true}
                name="surgical_cautery"
                onChange={props.handleChangeData}
                options={props.masterOptions?.orSurgicalCautery}
                search={true}
                selection={true}
                style={{width: "100%"}}>
              </Dropdown>
            </FormField>
          </FormGroup>
        </Form>
        <Form>
          <FormGroup
            inline={true}>
            <FormField
              className="required"
              inline={true}
              style={{display: "flex"}}>
              <label
                style={{minWidth: "120px", maxWidth: "120px"}}>
                X-Ray
              </label>
              <div>
                
                <Checkbox
                  checked={props.isXray}
                  label={props.isXray ? "YES" : "NO"}
                  name="is_xray"
                  onChange={props.handleChangeData}
                  style={{minWidth: "90px", maxWidth: "90px"}}
                  toggle={true}>
                </Checkbox>
              </div>
            </FormField>
            <FormField
              inline={true}
              style={{display : props.isXray ? "" :"none"}}
              width={7}>
              <Dropdown
                clearable={true}
                fluid={true}
                multiple={true}
                name="xray"
                onChange={props.handleChangeData}
                options={props.masterOptions?.specialEquipment}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.xay}>
              </Dropdown>
            </FormField>
          </FormGroup>
        </Form>
        <Form>
          <FormGroup
            inline={true}>
            <FormField
              className="required"
              inline={true}
              style={{display: "flex"}}>
              <label
                style={{minWidth: "120px", maxWidth: "120px"}}>
                Ground Pad Site
              </label>
              <div
                style={{minWidth: "90px", maxWidth: "90px"}}>
                
              </div>
            </FormField>
            <FormField
              inline={true}
              width={7}>
              <Dropdown
                clearable={true}
                fluid={true}
                multiple={true}
                name="ground_pad_site"
                onChange={props.handleChangeData}
                options={props.masterOptions?.orGroundPadSite}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.groundPadSite}>
              </Dropdown>
            </FormField>
          </FormGroup>
        </Form>
        <Form>
          <FormGroup
            inline={true}>
            <FormField
              className="required"
              inline={true}
              style={{display: "flex"}}>
              <label
                style={{minWidth: "120px", maxWidth: "120px"}}>
                U/S
              </label>
              <div>
                {}
                <Checkbox
                  checked={props.isUs}
                  className="electricalXrayUltrasound.chkUS"
                  label={props.isUs ? "YES" : "NO"}
                  name="is_us"
                  onChange={props.handleChangeData}
                  style={{minWidth: "90px", maxWidth: "90px"}}
                  toggle={true}>
                </Checkbox>
              </div>
            </FormField>
            <FormField
              inline={true}
              style={{display : props.isUs ? "" :"none" }}>
              <label>
                frequency
              </label>
              <Input
                name="frequency"
                onChange={props.handleChangeData}
                value={props.frequency}>
              </Input>
              <label
                style={{marginLeft: "1rem"}}>
                MHz
              </label>
            </FormField>
          </FormGroup>
        </Form>
        <div
          style={{ height: "55px", paddingTop: "15px" }}>
          
          <div
            style={{display:"flex",justifyContent:"flex-end"}}>
            
            <div
              style={{margin:"10px"}}>
              {props.buttonSave}
            </div>
            <div
              style={{margin:"10px"}}>
              {props.buttonConfirm}
            </div>
            <div
              style={{margin:"10px"}}>
              {props.buttonUnconfirm}
            </div>
          </div>
        </div>
      </div>
    )
}


export default CardElectricalXrayUltrasoundUX

export const screenPropsDefault = {}

/* Date Time : Mon Jan 15 2024 14:43:35 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardElectricalXrayUltrasound"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"15px\",  padding: \"20px\",  backgroundColor: \"rgba(198, 235, 243, 0.2)\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ paddingBottom: \"5px\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "label",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Electrical & X-ray & Ultrasound (D-F)"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"18px\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "Label",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.status"
        },
        "color": {
          "type": "code",
          "value": "props.statusColor"
        },
        "style": {
          "type": "code",
          "value": "{ float: \"right\" }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "hr",
      "parent": 1,
      "props": {
      },
      "seq": 4,
      "void": true
    },
    {
      "from": null,
      "id": 154,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ height: \"55px\", paddingTop: \"15px\" }"
        }
      },
      "seq": 187,
      "void": false
    },
    {
      "from": null,
      "id": 155,
      "name": "div",
      "parent": 154,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",justifyContent:\"flex-end\"}"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": null,
      "id": 181,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"10px\"}"
        }
      },
      "seq": 181,
      "void": false
    },
    {
      "from": null,
      "id": 182,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonConfirm"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"10px\"}"
        }
      },
      "seq": 182,
      "void": false
    },
    {
      "from": null,
      "id": 183,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonUnconfirm"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"10px\"}"
        }
      },
      "seq": 183,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 184,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 161,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 185,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 184,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 186,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 185,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 187,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 186,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 188,
      "name": "FormGroup",
      "parent": 184,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 188,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 189,
      "name": "FormGroup",
      "parent": 185,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 189,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 190,
      "name": "FormGroup",
      "parent": 186,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 190,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 191,
      "name": "FormGroup",
      "parent": 187,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 191,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 192,
      "name": "FormField",
      "parent": 188,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 192,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 193,
      "name": "FormField",
      "parent": 189,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 193,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 194,
      "name": "FormField",
      "parent": 190,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 194,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 195,
      "name": "FormField",
      "parent": 191,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 195,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 196,
      "name": "FormField",
      "parent": 188,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display : props.isSurgicalCautery ? \"\" :\"none\"}"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 196,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 197,
      "name": "FormField",
      "parent": 189,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display : props.isXray ? \"\" :\"none\"}"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 197,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 198,
      "name": "FormField",
      "parent": 190,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 198,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 199,
      "name": "FormField",
      "parent": 191,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display : props.isUs ? \"\" :\"none\" }"
        }
      },
      "seq": 199,
      "void": false
    },
    {
      "from": null,
      "id": 200,
      "name": "label",
      "parent": 192,
      "props": {
        "children": {
          "type": "value",
          "value": "Surgical Cautery"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"120px\", maxWidth: \"120px\"}"
        }
      },
      "seq": 200,
      "void": false
    },
    {
      "from": null,
      "id": 201,
      "name": "label",
      "parent": 193,
      "props": {
        "children": {
          "type": "value",
          "value": "X-Ray"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"120px\", maxWidth: \"120px\"}"
        }
      },
      "seq": 201,
      "void": false
    },
    {
      "from": null,
      "id": 202,
      "name": "label",
      "parent": 194,
      "props": {
        "children": {
          "type": "value",
          "value": "Ground Pad Site"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"120px\", maxWidth: \"120px\"}"
        }
      },
      "seq": 202,
      "void": false
    },
    {
      "from": null,
      "id": 203,
      "name": "label",
      "parent": 195,
      "props": {
        "children": {
          "type": "value",
          "value": "U/S"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"120px\", maxWidth: \"120px\"}"
        }
      },
      "seq": 203,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 207,
      "name": "Dropdown",
      "parent": 196,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "surgical_cautery"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.orSurgicalCautery"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 207,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 208,
      "name": "Dropdown",
      "parent": 197,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "xray"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.specialEquipment"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.xay"
        }
      },
      "seq": 208,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 209,
      "name": "Dropdown",
      "parent": 198,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "ground_pad_site"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.orGroundPadSite"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.groundPadSite"
        }
      },
      "seq": 209,
      "void": false
    },
    {
      "from": null,
      "id": 211,
      "name": "div",
      "parent": 192,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 211,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 212,
      "name": "Checkbox",
      "parent": 211,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isSurgicalCautery"
        },
        "label": {
          "type": "code",
          "value": "props.isSurgicalCautery ? \"YES\" : \"NO\""
        },
        "name": {
          "type": "value",
          "value": "is_surgical_cautery"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"90px\", maxWidth: \"90px\"}"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 212,
      "void": false
    },
    {
      "from": null,
      "id": 213,
      "name": "div",
      "parent": 193,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 213,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 214,
      "name": "Checkbox",
      "parent": 213,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isXray"
        },
        "label": {
          "type": "code",
          "value": "props.isXray ? \"YES\" : \"NO\""
        },
        "name": {
          "type": "value",
          "value": "is_xray"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"90px\", maxWidth: \"90px\"}"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 214,
      "void": false
    },
    {
      "from": null,
      "id": 215,
      "name": "div",
      "parent": 195,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 215,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 216,
      "name": "Checkbox",
      "parent": 215,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isUs"
        },
        "className": {
          "type": "value",
          "value": "electricalXrayUltrasound.chkUS"
        },
        "label": {
          "type": "code",
          "value": "props.isUs ? \"YES\" : \"NO\""
        },
        "name": {
          "type": "value",
          "value": "is_us"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"90px\", maxWidth: \"90px\"}"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 216,
      "void": false
    },
    {
      "from": null,
      "id": 217,
      "name": "label",
      "parent": 199,
      "props": {
        "children": {
          "type": "value",
          "value": "frequency"
        }
      },
      "seq": 217,
      "void": false
    },
    {
      "from": null,
      "id": 218,
      "name": "label",
      "parent": 199,
      "props": {
        "children": {
          "type": "value",
          "value": "MHz"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"1rem\"}"
        }
      },
      "seq": 219,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 219,
      "name": "Input",
      "parent": 199,
      "props": {
        "fluid": {
          "type": "code",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "frequency"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.frequency"
        }
      },
      "seq": 218,
      "void": false
    },
    {
      "from": null,
      "id": 221,
      "name": "div",
      "parent": 194,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"90px\", maxWidth: \"90px\"}"
        }
      },
      "seq": 221,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 85,
  "isMounted": false,
  "memo": false,
  "name": "CardElectricalXrayUltrasoundUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
