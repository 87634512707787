import React, { CSSProperties } from "react";

import { Modal } from "semantic-ui-react";

import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import { useIntl } from "react-intl";

import ModChairDetailUX from "./ModChairDetailUX"; // Assuming this component exists

// Types and Interfaces
type ModChairDetailProps = {
  setProp: (key: string, value: any) => void;
  // data
  chairDetailData?: any[];
  dateSelected: string;
  open: boolean;
  timePeriodSelected: string;
  buttonLoadCheck?: Record<string, any>;
  // callback
  onClose: () => void;
  onPrintReport: () => void;
};

type Styles = Record<"modal", CSSProperties>;

// Constants
const styles: Styles = {
  modal: { padding: "1rem" },
};

const CARD_CHAIR = "CardChair";

const ModChairDetail = (props: ModChairDetailProps) => {
  const intl = useIntl();
  return (
    <Modal open={props.open} style={styles.modal} closeOnDimmerClick onClose={props.onClose}>
      <div style={{ fontSize: "1.15rem" }}>
        <span style={{ alignItems: "center", display: "flex", fontWeight: "bold" }}>
          {intl.formatMessage({ id: "รายการจองเก้าอี้" })}
        </span>
      </div>
      <div style={{ display: "flex", padding: "1rem" }}>
        <label style={{ alignItems: "center", display: "flex", padding: "0rem 5rem 0rem 0rem" }}>
          {props.dateSelected}
        </label>
        <label style={{ alignItems: "center", display: "flex", flex: 1 }}>
          {props.timePeriodSelected}
        </label>
        <ButtonLoadCheck
          setProp={props.setProp}
          color="blue"
          disabled={props.chairDetailData?.length === 0}
          paramKey={`${CARD_CHAIR}_PRINT_REPORT`}
          title="พิมพ์รายงานจองเก้าอี้"
          buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_CHAIR}_PRINT_REPORT`]}
          onClick={props.onPrintReport}
        />
      </div>
      <ModChairDetailUX data={props.chairDetailData} languageUX={props.languageUX} />
    </Modal>
  );
};

ModChairDetail.displayName = "ModChairDetail";

export default React.memo(ModChairDetail);
