import React from 'react';
import { IntlProvider, FormattedMessage, useIntl } from 'react-intl';

import {
  Form,
  FormGroup,
  Button
} from 'semantic-ui-react'

const CardSapAccountingReportUX = (props: any) => {
  return (
    <div
      style={{ position: "relative", padding: "1em" }}>
      <div
        style={{ display: "flex" }}>

        <Form>
          <FormGroup
            style={{ padding: "1em 0em 0em 1em" }}>
            <Button
              onClick={() => props.onHandleSelectedMenu("SAP FI Export")}
              style={{ width: "20em" }}>
              SAP FI Export
            </Button>
          </FormGroup>
          <FormGroup
            style={{ padding: "1em 0em 0em 1em" }}>
            <Button
              onClick={() => props.onHandleSelectedMenu("รายงานสรุปรายได้ตามศูนย์ต้นทุน")}
              style={{ width: "20em" }}>
              รายงานสรุปรายได้ตามศูนย์ต้นทุน
            </Button>
          </FormGroup>
          <FormGroup
            style={{ padding: "1em 0em 0em 1em" }}>
            <Button
              onClick={() => props.onHandleSelectedMenu("รายงานสรุปสถิติการรับชำระเงิน")}
              style={{ width: "20em" }}>
              รายงานสรุปสถิติการรับชำระเงิน
            </Button>
          </FormGroup>
        </Form>
      </div>
    </div>
  )
}


export default CardSapAccountingReportUX

export const screenPropsDefault = {}

/* Date Time : Mon Apr 22 2024 16:35:31 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{position: \"relative\", padding: \"1em\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 2,
      "name": "Form",
      "parent": 1,
      "props": {
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "FormGroup",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding: \"1em 0em 0em 1em\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "FormGroup",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding: \"1em 0em 0em 1em\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "FormGroup",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding: \"1em 0em 0em 1em\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "Button",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "SAP FI Export"
        },
        "onClick": {
          "type": "code",
          "value": "() => props.onHandleSelectedMenu(\"SAP FI Export\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"20em\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "Button",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "รายงานสรุปรายได้ตามศูนย์ต้นทุน"
        },
        "onClick": {
          "type": "code",
          "value": "() => props.onHandleSelectedMenu(\"รายงานสรุปรายได้ตามศูนย์ต้นทุน\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"20em\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "Button",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "รายงานสรุปสถิติการรับชำระเงิน"
        },
        "onClick": {
          "type": "code",
          "value": "() => props.onHandleSelectedMenu(\"รายงานสรุปสถิติการรับชำระเงิน\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"20em\"}"
        }
      },
      "seq": 8,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardSapAccountingReportUX",
  "project": "CLM CU",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
