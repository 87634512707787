import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  FormField,
  Checkbox,
  Button,
  Icon
} from 'semantic-ui-react'

const CardFilterOnlineQueueUX = (props: any) => {
    return(
      <div
        id="CardFilterOnlineQueue"
        style={{"padding":"10px","boxShadow":"rgba(34, 36, 38, 0.12) 0px 2px 4px 0px, rgba(34, 36, 38, 0.15) 0px 2px 10px 0px","borderRadius":"0.285714rem","backgroundColor":"white","width":"302px"}}>
        <Form>
          <FormGroup
            inline={true}
            style={{borderBottom: "1px solid rgba(85, 85, 85, 1)", padding: "0 0 0.25rem 0.5rem"}}>
            <FormField>
              <label>
                Call type
              </label>
            </FormField>
            <FormField
              style={{flex:1}}>
            </FormField>
            <FormField>
              <button
                onClick={props.onClearType}
                style={{background: "white", border: "none",textDecoration: "underline", color: "rgba(13, 113, 187, 1)", fontWeight: "bold", fontSize: "0.85rem"}}>
                Clear
              </button>
            </FormField>
          </FormGroup>
          <FormGroup
            style={{paddingLeft: "1rem"}}>
            <FormField>
              <Checkbox
                checked={props.filter?.type?.includes("CONSULT")}
                className="light-blue"
                label="Consult"
                name="CONSULT"
                onChange={props.onChangeValue}>
              </Checkbox>
            </FormField>
          </FormGroup>
          <FormGroup
            style={{paddingLeft: "1rem"}}>
            <FormField>
              <Checkbox
                checked={props.filter?.type?.includes("INTRA")}
                className="light-blue"
                label="Telepharmacy"
                name="INTRA"
                onChange={props.onChangeValue}>
              </Checkbox>
            </FormField>
          </FormGroup>
          <FormGroup
            style={{paddingLeft: "1rem"}}>
            <FormField>
              <Checkbox
                checked={props.filter?.type?.includes("NURSE_COUNSELLING")}
                className="light-blue"
                label="Nurse counselling"
                name="NURSE_COUNSELLING"
                onChange={props.onChangeValue}>
              </Checkbox>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{borderBottom: "1px solid rgba(85, 85, 85, 1)",borderTop: "1px solid rgba(85, 85, 85, 1)", padding: "0.25rem 0 0.25rem 0.5rem"}}>
            <FormField>
              <label>
                Status
              </label>
            </FormField>
            <FormField
              style={{flex: 1}}>
            </FormField>
            <FormField>
              <button
                onClick={props.onClearStatus}
                style={{background: "white", border: "none",textDecoration: "underline", color: "rgba(13, 113, 187, 1)", fontWeight: "bold", fontSize: "0.85rem"}}>
                Clear
              </button>
            </FormField>
          </FormGroup>
          <FormGroup
            style={{paddingLeft: "1rem"}}>
            <FormField>
              <Checkbox
                checked={props.filter?.status?.includes("CHECKED")}
                className="light-blue"
                label="Waiting"
                name="CHECKED"
                onChange={props.onChangeValue}>
              </Checkbox>
            </FormField>
          </FormGroup>
          <FormGroup
            style={{paddingLeft: "1rem"}}>
            <FormField>
              <Checkbox
                checked={props.filter?.status?.includes("TRANSPORTED")}
                className="light-blue"
                label="Transported"
                name="TRANSPORTED"
                onChange={props.onChangeValue}>
              </Checkbox>
            </FormField>
          </FormGroup>
          <FormGroup
            style={{paddingLeft: "1rem"}}>
            <FormField>
              <Checkbox
                checked={props.filter?.status?.includes("RECEIVED")}
                className="light-blue"
                label="Received"
                name="RECEIVED"
                onChange={props.onChangeValue}>
              </Checkbox>
            </FormField>
          </FormGroup>
          <FormGroup
            style={{paddingLeft: "1rem"}}>
            <FormField>
              <Checkbox
                checked={props.filter?.status?.includes("COUNSELLING")}
                className="light-blue"
                label="Counselling"
                name="COUNSELLING"
                onChange={props.onChangeValue}>
              </Checkbox>
            </FormField>
          </FormGroup>
          <FormGroup
            style={{paddingLeft: "1rem"}}>
            <FormField>
              <Checkbox
                checked={props.filter?.status?.includes("DELIVERED")}
                className="light-blue"
                label="Delivered"
                name="DELIVERED"
                onChange={props.onChangeValue}>
              </Checkbox>
            </FormField>
          </FormGroup>
          <FormGroup
            style={{borderBottom: "1px solid rgba(85, 85, 85, 1)", margin: "0 0 0.75rem"}}>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{minWidth: "max-content", marginBottom: "0.35rem"}}>
            <FormField
              inline={true}
              style={{padding:0}}>
              <button
                onClick={props.onResetDefault}
                style={{background: "white", border: "none"}}>
                <Icon
                  name="retweet">
                </Icon>
                <label>
                  Back to default
                </label>
              </button>
            </FormField>
            <FormField
              style={{flex:1}}>
            </FormField>
            <FormField
              style={{padding: 0}}>
              <Button
                color="green"
                onClick={props.onConfirm}
                size="mini"
                style={{width: "4rem"}}>
                OK
              </Button>
              <Button
                color="red"
                onClick={props.onClose}
                size="mini"
                style={{width: "4rem", paddingLeft: "0.5rem", paddingRight: "0.5rem"}}>
                CLOSE
              </Button>
            </FormField>
          </FormGroup>
        </Form>
      </div>
    )
}


export default CardFilterOnlineQueueUX

export const screenPropsDefault = {}

/* Date Time : Wed Dec 20 2023 11:40:01 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardFilterOnlineQueue"
        },
        "style": {
          "type": "code",
          "value": "{\"padding\":\"10px\",\"boxShadow\":\"rgba(34, 36, 38, 0.12) 0px 2px 4px 0px, rgba(34, 36, 38, 0.15) 0px 2px 10px 0px\",\"borderRadius\":\"0.285714rem\",\"backgroundColor\":\"white\",\"width\":\"302px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": "semantic-ui-react",
      "id": 1,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 2,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{borderBottom: \"1px solid rgba(85, 85, 85, 1)\", padding: \"0 0 0.25rem 0.5rem\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "FormField",
      "parent": 2,
      "props": {
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "FormField",
      "parent": 2,
      "props": {
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "label",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "Call type"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormField",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"1rem\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "FormField",
      "parent": 8,
      "props": {
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"1rem\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"1rem\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "FormField",
      "parent": 11,
      "props": {
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "FormField",
      "parent": 10,
      "props": {
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Checkbox",
      "parent": 12,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filter?.type?.includes(\"NURSE_COUNSELLING\")"
        },
        "className": {
          "type": "value",
          "value": "light-blue"
        },
        "label": {
          "type": "value",
          "value": "Nurse counselling"
        },
        "name": {
          "type": "value",
          "value": "NURSE_COUNSELLING"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "Checkbox",
      "parent": 13,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filter?.type?.includes(\"INTRA\")"
        },
        "className": {
          "type": "value",
          "value": "light-blue"
        },
        "label": {
          "type": "value",
          "value": "Telepharmacy"
        },
        "name": {
          "type": "value",
          "value": "INTRA"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "Checkbox",
      "parent": 9,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filter?.type?.includes(\"CONSULT\")"
        },
        "className": {
          "type": "value",
          "value": "light-blue"
        },
        "label": {
          "type": "value",
          "value": "Consult"
        },
        "name": {
          "type": "value",
          "value": "CONSULT"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{borderBottom: \"1px solid rgba(85, 85, 85, 1)\",borderTop: \"1px solid rgba(85, 85, 85, 1)\", padding: \"0.25rem 0 0.25rem 0.5rem\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "FormField",
      "parent": 17,
      "props": {
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "FormField",
      "parent": 17,
      "props": {
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "FormField",
      "parent": 17,
      "props": {
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "label",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "Status"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"1rem\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"1rem\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"1rem\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"1rem\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"1rem\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "FormField",
      "parent": 23,
      "props": {
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "FormField",
      "parent": 24,
      "props": {
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "FormField",
      "parent": 25,
      "props": {
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "FormField",
      "parent": 26,
      "props": {
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "FormField",
      "parent": 27,
      "props": {
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "Checkbox",
      "parent": 32,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filter?.status?.includes(\"DELIVERED\")"
        },
        "className": {
          "type": "value",
          "value": "light-blue"
        },
        "label": {
          "type": "value",
          "value": "Delivered"
        },
        "name": {
          "type": "value",
          "value": "DELIVERED"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "Checkbox",
      "parent": 31,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filter?.status?.includes(\"COUNSELLING\")"
        },
        "className": {
          "type": "value",
          "value": "light-blue"
        },
        "label": {
          "type": "value",
          "value": "Counselling"
        },
        "name": {
          "type": "value",
          "value": "COUNSELLING"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "Checkbox",
      "parent": 30,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filter?.status?.includes(\"RECEIVED\")"
        },
        "className": {
          "type": "value",
          "value": "light-blue"
        },
        "label": {
          "type": "value",
          "value": "Received"
        },
        "name": {
          "type": "value",
          "value": "RECEIVED"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "Checkbox",
      "parent": 29,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filter?.status?.includes(\"TRANSPORTED\")"
        },
        "className": {
          "type": "value",
          "value": "light-blue"
        },
        "label": {
          "type": "value",
          "value": "Transported"
        },
        "name": {
          "type": "value",
          "value": "TRANSPORTED"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "Checkbox",
      "parent": 28,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filter?.status?.includes(\"CHECKED\")"
        },
        "className": {
          "type": "value",
          "value": "light-blue"
        },
        "label": {
          "type": "value",
          "value": "Waiting"
        },
        "name": {
          "type": "value",
          "value": "CHECKED"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{borderBottom: \"1px solid rgba(85, 85, 85, 1)\", margin: \"0 0 0.75rem\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", marginBottom: \"0.35rem\"}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "FormField",
      "parent": 39,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding:0}"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "FormField",
      "parent": 39,
      "props": {
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "FormField",
      "parent": 39,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding: 0}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "Button",
      "parent": 42,
      "props": {
        "children": {
          "type": "value",
          "value": "OK"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onConfirm"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{width: \"4rem\"}"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "Button",
      "parent": 42,
      "props": {
        "children": {
          "type": "value",
          "value": "CLOSE"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{width: \"4rem\", paddingLeft: \"0.5rem\", paddingRight: \"0.5rem\"}"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "button",
      "parent": 40,
      "props": {
        "onClick": {
          "type": "code",
          "value": "props.onResetDefault"
        },
        "style": {
          "type": "code",
          "value": "{background: \"white\", border: \"none\"}"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "Icon",
      "parent": 47,
      "props": {
        "link": {
          "type": "code",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "retweet"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "label",
      "parent": 47,
      "props": {
        "children": {
          "type": "value",
          "value": "Back to default"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "button",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "Clear"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClearType"
        },
        "style": {
          "type": "code",
          "value": "{background: \"white\", border: \"none\",textDecoration: \"underline\", color: \"rgba(13, 113, 187, 1)\", fontWeight: \"bold\", fontSize: \"0.85rem\"}"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "button",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": "Clear"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClearStatus"
        },
        "style": {
          "type": "code",
          "value": "{background: \"white\", border: \"none\",textDecoration: \"underline\", color: \"rgba(13, 113, 187, 1)\", fontWeight: \"bold\", fontSize: \"0.85rem\"}"
        }
      },
      "seq": 51,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardFilterOnlineQueueUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
