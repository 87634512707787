import React from "react";
import { serial_to_datestr, DaySlotDisplay, serial_to_hour } from "./Time";
import { useHistory } from "react-router-dom";
import { Popup } from "semantic-ui-react";
import { useIntl } from "react-intl";

interface ServiceSlotSummaryProps {
  weekSlotsDisplay: DaySlotDisplay[][];
  min_hour: number;
  max_hour: number;
  weekStartSerial: number;
  onClickDaySlot?: ({ index, weekStartSerial }: { index: number, weekStartSerial: number }) => void,
}

const border = (index: number) => (index % 4 === 3 ? "bottomBorder" : "");

const ServiceSlotSummary = (props: ServiceSlotSummaryProps) => {
  const history = useHistory();

  const createDaySlot = (index: number, daySlotsDisplay: DaySlotDisplay[]) => {
    return daySlotsDisplay.map((d, d_index) => {
      return (
        serial_to_hour(d.serial) >= props.min_hour &&
        serial_to_hour(d.serial) < props.max_hour && (
          <div
            key={d_index}
            onClick={() => {
              if (d.active && props.onClickDaySlot) {
                props.onClickDaySlot({ index, weekStartSerial: props.weekStartSerial })
              }
            }}
            className={`service_slot 
						${d.status === 1 ? "active" : ""}
            ${index === 0 ? "border" : ""} ${d.active ? "" : "disabled"}`}
          >
            <div className={`slotTime ${border(d_index)}`}>
              {d.status === 1 || d.serial % 4 === 0
                ? serial_to_datestr(d.serial, "time")
                : "\u00A0"}
            </div>
            {d.status === 1 ? (
              <Popup
                content={d.division_name}
                trigger={
                  <div className={`slotName ${border(d_index)}`}>
                  {d.division_name}
                    {/* {d.division_name.length < 20
                      ? d.division_name
                      : d.division_name.slice(0, 20) + "..."} */}
                  </div>
                }
              />
            ) : (
              <div className={`slotName ${border(d_index)}`}/>
            )}
          </div>
        )
      );
    });
  };

  return (
    <div className="calendar">
      {props.weekSlotsDisplay.map(
        (daySlotsDisplay: DaySlotDisplay[], index) => {
          return (
            <div className="weekDisplay" key={index}>
              <div className="dateHeader">
                <b>
                  {serial_to_datestr(daySlotsDisplay[0].serial, "localeDate", {
                    weekday: "short",
                    year: "numeric",
                    month: "long",
                    day: "numeric"
                  })}
                </b>
              </div>
              {createDaySlot(index, daySlotsDisplay)}
            </div>
          );
        }
      )}
    </div>
  );
};

export default React.memo(ServiceSlotSummary);
