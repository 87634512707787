import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'

export default class QmlComponent extends React.Component {
    static cardName = `DPO/CardCovidExamination.qml`;
    
    constructor() {
        super()
        this._ismounted = false
        this.state = {
            qml : null
        }
    }

    componentDidMount() {
        this._ismounted = true
        //console.log("!!!<<<<<<CDM>>>>>>>!!! ", this.cardName)
        

        //console.log('about to this.loadQML')
// Soure code from QML file starts here 
setTimeout( () => { 
this.loadQML(String.raw`
import Semantic.Html 1.0
import QmlWeb 1.0
import '../Common' as Common
import '../Common/Util.js' as Util

Common.CardLayout {
   id: _crdCovidExamination
   title: "Progress note ผู้ป่วยต้องสงสัยติดเชื้อ Acute Respiratory Infection"
   hideCloseButton: true
   toggleCard: false
   headerColor: "red"

   property var FORM_DATA_URL: '/apis/DPO/form-data/'
   property bool is_doctor: false
   property var encounter_id
   property var patient_id
   property var readOnly: false

   signal orderConfirmed()

   RestModel {
      id: rmdADR
      property var items

      function fetchADR() {
         txtADR.text = ""
         rmdCovidData.is_drug_allergy = false
         console.log(_crdCovidExamination.patient_id)
         if (!_crdCovidExamination.patient_id) return
         var url = '/apis/ADR/adverse-reaction/?exclude_unused=true&severe_first=true&patient=' + _crdCovidExamination.patient_id
         rmdADR.url = url
         rmdADR.fetch()
      }

      onFetched: {
         if (items.length > 0){
            txtADR.text = items[0].adr_short_info
            rmdCovidData.is_drug_allergy = true
         } else {
            txtADR.text = ""
            rmdCovidData.is_drug_allergy = false
         }
      }
    }

   RestModel {
      id: rmdCovidData

      property alias is_travel_history: chkTravelCountry.checked
      property alias travel_history: cboTravelCountry.text
      property alias is_travel_risk_history: chkTravelRiskPlaces.checked
      property alias travel_risk_history: txtTravelRiskPlaces.text
      property alias come_back_date: dtbComeBack.text
      property alias is_contact: chkContact.checked
      property alias contact_date: dtbContact.text
      property alias is_home: chkHome.checked
      property alias is_work: chkWork.checked
      property alias is_other: chkOther.checked
      property alias other: txtOther.text
      property alias history_remark: txtHistoryRemark.text
      property alias remark: txtRemark.text

      property alias is_has_symptom: cboHasSymptom.value
      property alias is_fever: chkFever.checked
      property alias is_cold: chkCold.checked
      property alias is_cough: chkCough.checked
      property alias is_phlegm: chkPhlegm.checked
      property alias is_snot: chkSnot.checked
      property alias is_sore_throat: chkSoreTroat.checked
      property alias is_tired: chkTired.checked
      property alias is_headache: chkHeadache.checked
      property alias is_ache: chkAche.checked
      property alias is_diarrhea: chkDiarrhea.checked
      property alias is_squeamish: chkSqueamish.checked
      property alias is_gasp: chkGasp.checked
      property alias is_symptom_etc: chkSymptomEtc.checked
      property alias symptom_etc: txtSymptomEtc.text

      property alias first_notice_date: dtbFirstNotice.text
      property var is_drug_allergy
      property alias drug_allergy: txtADR.text
      property alias is_congenital_disease: chkCongenitalDisease.checked
      property alias is_copd: chkCopd.checked
      property alias is_dm: chkDm.checked
      property alias is_ht: chkHt.checked
      property alias is_heart_disease: chkHeartDisease.checked
      property alias heart_disease: txtHeartDisease.text
      property alias is_congenital_etc: chkCongenitalEtc.checked
      property alias congenital_etc: txtCongenitalEtc.text

      // Physical Examination
      property alias is_injected_pharynx: chkInjectedPharynx.checked
      property alias is_enlarged_tonsils: chkEnlargedTonsils.checked
      property alias enlarged_tonsils: txtEnlargedTonsils.text
      property alias is_enlarged_cervical: chkEnlargedCervical.checked
      property alias enlarged_cervical: txtEnlargedCervical.text
      property alias is_rash: chkRash.checked
      property alias rash: txtRash.text
      property alias is_lungs: chkLungs.checked
      property alias lungs_status: cboLungs.value
      property alias lungs: txtLungs.text
      property alias is_physical_others: chkPhysicalOthers.checked
      property alias physical_others: txtPhysicalOthers.text

      // Lab Investigations
      property alias is_none_lab: chkNoneLabInvestigations.checked
      property alias is_nasal_throat_SARs_Cov2: chkLabNasalAndThroatSARsCov2.checked
      property alias is_nasal_throat_virus_22: chkLabNasalAndThroatVirus22.checked
      property alias is_nasal_throat_virus33: chkLabNasalAndThroatVirus33.checked
      property alias is_nasal_AB: chkLabNasalAB.checked
      property alias is_cbc: chkCBC.checked
      property alias is_electrolytes: chkElectrolytes.checked
      property alias is_liver: chkLiver.checked
      property alias is_xray: chkXRay.checked

      // Diagnosis
      property alias is_diagnosis: chkDiagnosis.checked
      property alias is_other_diagnosis: chkOtherDiagnosis.checked
      property alias other_diagnosis: txtOtherDiagnosis.text

      // Treatment
      property alias is_adviced: chkAdviced.checked
      property alias is_quarantine: chkQuarantine.checked
      property alias quarantine_until: dtbQuarantine.text
      property alias is_doctor_ceritficate: chkDoctorCeritficate.checked
      property alias is_drug: chkDrug.checked
      property alias drug: txtDrug.text

   }

   function checkHasSymptom() {
      if ( rmdCovidData.is_has_symptom == "1" || rmdCovidData.is_fever || rmdCovidData.is_cold || rmdCovidData.is_cough || rmdCovidData.is_phlegm
            || rmdCovidData.is_snot || rmdCovidData.is_sore_troat || rmdCovidData.is_tired || rmdCovidData.is_headache
            || rmdCovidData.is_ache || rmdCovidData.is_diarrhea || rmdCovidData.is_squeamish || rmdCovidData.is_gasp || rmdCovidData.symptom_etc)
      {
         cboHasSymptom.value = "1"
      } else {
         cboHasSymptom.value = "0"
      }
   }

   function clearContact(){
      rmdCovidData.is_contact = false
      rmdCovidData.contact_date = ""
      rmdCovidData.is_home = false
      rmdCovidData.is_work = false
      rmdCovidData.is_other = false
      rmdCovidData.other = ""
   }

   function clearSymptoms(){
      rmdCovidData.is_has_symptom = "0"
      rmdCovidData.is_fever = false
      rmdCovidData.is_cold = false
      rmdCovidData.is_cough = false
      rmdCovidData.is_phlegm = false
      rmdCovidData.is_snot = false
      rmdCovidData.is_sore_troat = false
      rmdCovidData.is_tired = false
      rmdCovidData.is_headache = false
      rmdCovidData.is_ache = false
      rmdCovidData.is_diarrhea = false
      rmdCovidData.is_squeamish = false
      rmdCovidData.is_gasp = false
      rmdCovidData.symptom_etc = ""
      rmdCovidData.first_notice_date = ""
   }

   function clearLabInvestigations() {
      chkNoneLabInvestigations.checked = true
      chkLabNasalAndThroatSARsCov2.checked = false
      chkLabNasalAndThroatVirus22.checked = false
      chkLabNasalAndThroatVirus33.checked = false
      chkLabNasalAB.checked = false
      chkCBC.checked = false
      chkElectrolytes.checked = false
      chkLiver.checked = false
      chkXRay.checked = false
   }

   function clear() {
      rmdFormData.form_data_id = null
      chkTravelCountry.checked = false
      cboTravelCountry.text = ""
      chkTravelRiskPlaces.checked = false
      txtTravelRiskPlaces.text = ""
      dtbComeBack.text = ""
      chkContact.checked = false
      dtbContact.text = ""
      chkHome.checked = false
      chkWork.checked = false
      chkOther.checked = false
      txtOther.text = ""
      txtHistoryRemark.text = ""
      txtRemark.text = ""

      cboHasSymptom.value = "0"
      chkFever.checked = false
      chkCold.checked = false
      chkCough.checked = false
      chkPhlegm.checked = false
      chkSnot.checked = false
      chkSoreTroat.checked = false
      chkTired.checked = false
      chkHeadache.checked = false
      chkAche.checked = false
      chkDiarrhea.checked = false
      chkSqueamish.checked = false
      chkGasp.checked = false
      chkSymptomEtc.checked = false
      txtSymptomEtc.text = ""

      dtbFirstNotice.text = ""
      chkCongenitalDisease.checked = false
      chkCopd.checked = false
      chkDm.checked = false
      chkHt.checked = false
      chkHeartDisease.checked = false
      txtHeartDisease.text = ""
      chkCongenitalEtc.checked = false
      txtCongenitalEtc.text = ""

      // Physical Examination
      chkInjectedPharynx.checked = false
      chkEnlargedTonsils.checked = false
      txtEnlargedTonsils.text = ""
      chkEnlargedCervical.checked = false
      txtEnlargedCervical.text = ""
      chkRash.checked = false
      txtRash.text = ""
      chkLungs.checked = false
      cboLungs.value
      txtLungs.text = ""
      chkPhysicalOthers.checked = false
      txtPhysicalOthers.text = ""

      // Lab Investigations
      chkNoneLabInvestigations.checked = false
      clearLabInvestigations()

      // Diagnosis
      chkDiagnosis.checked = false
      chkOtherDiagnosis.checked = false
      txtOtherDiagnosis.text = ""

      // Treatment
      chkAdviced.checked = false
      chkQuarantine.checked = false
      dtbQuarantine.text = ""
      chkDoctorCeritficate.checked = false
      chkDrug.checked = false
      txtDrug.text = ""
   }

   RestModel {
      id: rmdFormData

      property var form_data_id: null
      property var form__form_type__code: 'SARsCov2Examination'
      property var form__version: '1.0'
      property var form__form_type__name: 'SARsCov2Examination'
      property var encounter: _crdCovidExamination.encounter_id
      property int form: null

      hierarchy: {
         return {
            data: rmdCovidData
         }
      }

      onFetched: {
         checkHasSymptom()
      }

      onCreated: {
         Util.alert('บันทึกข้อมูลสำเร็จ', 'green')
         _crdCovidExamination.loading = false
      }

      onSaved: {
         Util.alert('บันทึกข้อมูลสำเร็จ', 'green')
         _crdCovidExamination.loading = false
      }

      onFailed: {
         var error_text = ""
         if (error.detail) {
            error_text = error.detail
         } else {
            error_text = error
         }
         Util.alert('บันทึกไม่สำเร็จ', 'red', 'ok', null, function() {}, error_text)
         _crdCovidExamination.loading = false
      }
   }

   RestModel {
      id: rmdCountry
      url: '/apis/core/country/?limit=99999'
      property var items

      onFetched: {
         cboTravelCountry.items = items
      }
   }

   RestModel {
      id: rmdPrintFormData
      url: '/apis/DPO/msword-form-covid-print/'
      property var form
      property var version
      property var code

      onFetched: {
         Util.alert('พิมพ์รายการสำเร็จ', 'green')
         _crdCovidExamination.loading = false
      }

      onFailed: {
         Util.alert('เกิดข้อผิดพลาด', 'red', 'ok', null, function() {}, error)
         _crdCovidExamination.loading = false
      }
   }

   RestModel {
      id: rmdConfirmLabOrder
      url: '/apis/DPO/create-confirmed-lab-order/'
      property var encounter_id
      property var form_id

      onSaved: {
         Util.alert('ยืนยันข้อมูลแลปสำเร็จ', 'green')
         _crdCovidExamination.loading = false
         orderConfirmed()
      }

      onFailed: {
         var error_text = ""
         if (error.message) {
            if (error.message.length > 1) {
               error_text = ""
               for (var i=0; i< error.message.length; i++) {
                  error_text += error.message[i] + '<br/>'
               }
            } else {
               error_text = error.message
            }
         } else if (error.detail) {
            error_text = error.detail
         } else {
            error_text = error
         }
         Util.alert('ยืนยันข้อมูลแลปไม่สำเร็จ', 'red', 'ok', null, function() {}, error_text)
         _crdCovidExamination.loading = false
      }
   }

   function refresh(){
      clear()
      fetchFormData()
      rmdADR.fetchADR()
   }

   function fetchFormData() {
      if (_crdCovidExamination.encounter_id) {
         rmdFormData.url = FORM_DATA_URL + '?encounter=' + _crdCovidExamination.encounter_id
         rmdFormData.fetch()
      }
   }

   function saveFormData() {
      _crdCovidExamination.loading = true
      if (!rmdFormData.encounter){
         rmdFormData.encounter = _crdCovidExamination.encounter_id
      }
      if (!rmdFormData.form__form_type__code){
         rmdFormData.form__form_type__code = 'SARsCov2Examination'
      }
      if (!rmdFormData.form__version){
         rmdFormData.form__version = '1.0'
      }
      if (!rmdFormData.form__form_type__name){
         rmdFormData.form__form_type__name = 'SARsCov2Examination'
      }

      if (!rmdFormData.form_data_id){
         rmdFormData.url = FORM_DATA_URL
         rmdFormData.create()
      } else {
         rmdFormData.url = FORM_DATA_URL + rmdFormData.form_data_id + '/'
         rmdFormData.save()
      }
   }

   function printFormData() {
      _crdCovidExamination.loading = true
      rmdPrintFormData.query = {
         form: rmdFormData.form_data_id,
         version: rmdFormData.form__version,
         code: rmdFormData.form__form_type__code
      }
      rmdPrintFormData.fetch()
   }

   function confirmLabInvestigations() {
      _crdCovidExamination.loading = true
      if (!rmdFormData.form_data_id){
         Util.alert("กรุณาบันทึกข้อมูลก่อนทำการยืนยันข้อมูลแลป")
         _crdCovidExamination.loading = false
         return
      }
      rmdFormData.url = FORM_DATA_URL + rmdFormData.form_data_id + '/'
      rmdFormData.save().then(function() {
         _crdCovidExamination.loading = true
         rmdConfirmLabOrder.encounter_id = rmdFormData.encounter
         rmdConfirmLabOrder.form_id = rmdFormData.form_data_id
         rmdConfirmLabOrder.create()
      })

   }

   Component.onCompleted: {
      rmdCountry.fetch()
      fetchFormData()
      rmdADR.fetchADR()
   }

   content: [
      Segment{
         className: 'defaultBackground'
         Form{
            id: frmCovidExamination
            Fields{
               className: 'inline'
               Field{
                  className: 'one wide'
                  label: _('ปัจจัยเสี่ยง')
               }
               Field{
                  className: 'three wide'
                  CheckBox{
                     id: chkTravelCountry
                     text: _('ประวัติการเดินทางไปยังต่างประเทศ')
                     checked: rmdCovidData.travel_history !== ""
                     enabled: !_crdCovidExamination.readOnly

                     onChanged: {
                        if (!chkTravelCountry.checked) {
                           cboTravelCountry.text = ""
                           dtbComeBack.text = ""
                        }
                     }
                  }
               }
               Field{
                  className: 'six wide'
                  ComboBox{
                        id:cboTravelCountry
                        search: true
                        fullTextSearch: true
                        multipleSelection:true
                        enabled: chkTravelCountry.checked && !_crdCovidExamination.readOnly
                  }
               }
               Field{
                  className: 'five wide'
                  label: _('เดินทางกลับมาประเทศไทยวันที่')
                  DateTextBox{
                     id: dtbComeBack
                     enabled: chkTravelCountry.checked && !_crdCovidExamination.readOnly
                  }
               }
            }
            Fields{
               className: 'inline'
               Field{
                  className: 'one wide'
               }
               Field{
                  className: 'six wide'
                  CheckBox{
                     id: chkTravelRiskPlaces
                     text: _('ประวัติการเดินทางไปในสถานที่เสี่ยงในประเทศที่มีการแพร่ระบาดอย่างต่อเนื่อง')
                     enabled: !_crdCovidExamination.readOnly

                     onChanged: {
                        if (!chkTravelRiskPlaces.checked) {
                           txtTravelRiskPlaces.text = ""
                        }
                     }
                  }
               }
               Field{
                  className: 'nine wide'
                  TextBox{
                        id:txtTravelRiskPlaces
                        enabled: chkTravelRiskPlaces.checked && !_crdCovidExamination.readOnly
                  }
               }

            }
            Fields{
               className: 'inline'
               Field{
                  className: 'one wide'
               }
               Field{
                  className: 'four wide'
                  CheckBox{
                        id: chkContact
                        text: _('ประวัติสัมผัสใกล้ชิดกับผู้ป่วยที่ยืนยันว่าเป็นโรค COVID-19')
                        enabled: !_crdCovidExamination.readOnly

                        onChanged: {
                           if (!chkContact.checked) {
                              clearContact()
                           }
                        }
                     }
               }
               Field{
                  className: 'three wide'
                  label: _('เมื่อวันที่')
                  DateTextBox{
                     id: dtbContact
                     enabled: chkContact.checked && !_crdCovidExamination.readOnly
                  }
               }
               Field{
                  className: 'one wide'
                  label: _('สถานที่')
               }
               Field{
                  className: 'one wide'
                  CheckBox{
                     id: chkHome
                     text: _('บ้าน')
                     enabled: chkContact.checked && !_crdCovidExamination.readOnly
                  }
               }
               Field{
                  className: 'one wide'
                  CheckBox{
                     id: chkWork
                     text: _('ที่ทำงาน')
                     enabled: chkContact.checked && !_crdCovidExamination.readOnly
                  }
               }
               Field{
                  className: 'four wide'
                  CheckBox{
                     id: chkOther
                     text: _('อื่นๆ')
                     enabled: chkContact.checked && !_crdCovidExamination.readOnly

                     onChanged: {
                        if (!chkOther.checked) {
                           txtOther.text = ""
                        }
                     }
                  }
                  TextBox{
                     id: txtOther
                     enabled: chkOther.checked && !_crdCovidExamination.readOnly
                  }
               }
            }
            Fields{
               className: 'inline'
               Field{
                  className: 'one wide'
               }
               Field{
                  className: 'two wide'
                  label: _('ข้อมูลเพิ่มเติมอื่นๆ')
               }
               Field{
                  className: 'thirteen wide'
                  TextArea{
                     id: txtRemark
                     rows: 3
                     readOnly: _crdCovidExamination.readOnly
                  }
               }
            }
            Fields{
               className: 'inline'
               Field{
                  className: 'one wide'
                  label: _('อาการสำคัญ')
               }
               Field{
                  className: 'two wide'
                  ComboBox{
                     id: cboHasSymptom
                     items: [
                        { id: "0", text: 'ไม่มีอาการ' },
                        { id: "1", text: 'มีอาการ' },
                     ]

                     value: "0"
                     enabled: !_crdCovidExamination.readOnly

                     onChanged: {
                        if (value == "0"){
                           clearSymptoms()
                        }
                     }
                  }
               }
               Field{
                  className: 'one wide'
               }
               Field{
                  className: 'one wide'
                  CheckBox{
                     id: chkFever
                     text: _('ไข้')
                     enabled: cboHasSymptom.value == "1" && !_crdCovidExamination.readOnly
                  }
               }
               Field{
                  className: 'one wide'
                  CheckBox{
                     id: chkCold
                     text: _('หนาวสั่น')
                     enabled: cboHasSymptom.value == "1"&& !_crdCovidExamination.readOnly
                  }
               }
               Field{
                  className: 'one wide'
                  CheckBox{
                     id: chkCough
                     text: _('ไอ')
                     enabled: cboHasSymptom.value == "1" && !_crdCovidExamination.readOnly
                  }
               }
               Field{
                  className: 'one wide'
                  CheckBox{
                     id: chkPhlegm
                     text: _('เสมหะ')
                     enabled: cboHasSymptom.value == "1" && !_crdCovidExamination.readOnly
                  }
               }
               Field{
                  className: 'one wide'
                  CheckBox{
                     id: chkSnot
                     text: _('น้ำมูก')
                     enabled: cboHasSymptom.value == "1" && !_crdCovidExamination.readOnly
                  }
               }
               Field{
                  className: 'one wide'
                  CheckBox{
                     id: chkSoreTroat
                     text: _('เจ็บคอ')
                     enabled: cboHasSymptom.value == "1" && !_crdCovidExamination.readOnly
                  }
               }
               Field{
                  className: 'one wide'
                  CheckBox{
                     id: chkTired
                     text: _('อ่อนเพลีย')
                     enabled: cboHasSymptom.value == "1" && !_crdCovidExamination.readOnly
                  }
               }
               Field{
                  className: 'two wide'
                  CheckBox{
                     id: chkHeadache
                     text: _('ปวดศีรษะ')
                     enabled: cboHasSymptom.value == "1" && !_crdCovidExamination.readOnly
                  }
               }
            }
            Fields{
               className: 'inline'
               Field{
                  className: 'four  wide'

               }
               Field{
                  className: 'two wide'
                  CheckBox{
                     id: chkAche
                     text: _('ปวดเมื่อยตามตัว/ข้อ')
                     enabled: cboHasSymptom.value == "1" && !_crdCovidExamination.readOnly
                  }
               }
               Field{
                  className: 'one wide'
                  CheckBox{
                     id: chkDiarrhea
                     text: _('ท้องเสีย')
                     enabled: cboHasSymptom.value == "1" && !_crdCovidExamination.readOnly
                  }
               }
               Field{
                  className: 'two wide'
                  CheckBox{
                     id: chkSqueamish
                     text: _('คลื่นไส้/อาเจียน')
                     enabled: cboHasSymptom.value == "1" && !_crdCovidExamination.readOnly
                  }
               }
               Field{
                  className: 'two wide'
                  CheckBox{
                     id: chkGasp
                     text: _('หายใจเหนื่อย')
                     enabled: cboHasSymptom.value == "1" && !_crdCovidExamination.readOnly
                  }
               }
               Field{
                  className: 'one wide'
                  CheckBox{
                     id: chkSymptomEtc
                     text: _('อื่นๆ')
                     enabled: cboHasSymptom.value == "1" && !_crdCovidExamination.readOnly

                     onChanged: {
                        if (!chkSymptomEtc.checked) {
                           txtSymptomEtc.text = ""
                        }
                     }
                  }
               }
               Field{
                  className: 'two wide'
                  TextBox{
                     id: txtSymptomEtc
                     enabled: chkSymptomEtc.checked && !_crdCovidExamination.readOnly
                  }
               }
            }
            Fields{
               className: 'inline'
               Field{
                  className: 'one  wide'
               }
               Field{
                  className: 'two wide'
                  enabled: cboHasSymptom.value == "1"
                  label: _('วันที่เริ่มมีอาการ')
               }
               Field{
                  className: 'two wide'
                  DateTextBox{
                     id: dtbFirstNotice
                     enabled: !_crdCovidExamination.readOnly
                  }
               }
               Field{
                  className: 'one wide'
               }
               Field{
                  className: 'ten wide'
                  label: _('ประวัติแพ้ยา')
                  TextBox{
                     id: txtADR
                     enabled: false
                  }
               }
            }
            Fields{
               className: 'inline'
               Field{
                  className: 'one  wide'
               }
               Field{
                  className: 'two wide'
                  label: _('โรคประจำตัว')
               }
               Field{
                  className: 'one wide'
                  CheckBox{
                     id: chkCongenitalDisease
                     text: _('มี')
                     enabled: !_crdCovidExamination.readOnly
                  }
               }
               Field{
                  className: 'one wide'
                  CheckBox{
                     id: chkCopd
                     text: _('COPD')
                     enabled: !_crdCovidExamination.readOnly
                  }
               }
               Field{
                  className: 'one wide'
                  CheckBox{
                     id: chkDm
                     text: _('DM')
                     enabled: !_crdCovidExamination.readOnly
                  }
               }
               Field{
                  className: 'one wide'
                  CheckBox{
                     id: chkHt
                     text: _('HT')
                     enabled: !_crdCovidExamination.readOnly
                  }
               }
               Field{
                  className: 'two wide'
                  CheckBox{
                     className: 'two wide'
                     id: chkHeartDisease
                     text: _('Heart Disease')
                     enabled: !_crdCovidExamination.readOnly

                     onChanged: {
                        if (!chkHeartDisease.checked) {
                           txtHeartDisease.text = ""
                        }
                     }
                  }
               }
               Field{
                  className: 'two wide'
                  TextBox{
                     className: 'two wide'
                     id: txtHeartDisease
                     enabled: chkHeartDisease.checked && !_crdCovidExamination.readOnly
                  }
               }
               Field{
                  className: 'four wide'
                  CheckBox{
                     id: chkCongenitalEtc
                     text: _('อื่นๆ')
                     enabled: !_crdCovidExamination.readOnly

                     onChanged: {
                        if (!chkCongenitalEtc.checked) {
                           txtCongenitalEtc.text = ""
                        }
                     }
                  }
                  TextBox{
                     id: txtCongenitalEtc
                     enabled: chkCongenitalEtc.checked && !_crdCovidExamination.readOnly
                  }
               }
            }
         }
      },

      Segment{
         className: 'defaultBackground'
         Form{
            Fields{
               className: 'inline'
               Field{
                  className: 'two wide'
                  label: _('ประวัติเพิ่มเติม')
               }
               Field{
                  className: 'fourteen wide'
                  TextArea{
                     id: txtHistoryRemark
                     rows: 3
                     readOnly: !_crdCovidExamination.is_doctor || _crdCovidExamination.readOnly
                  }
               }
            }
            Fields{
               className: 'inline'
               Field{
                  className: 'two wide'
                  label: 'Physical Examination'
               }
               Field{
                  className: 'three wide'
                  CheckBox{
                     id: chkInjectedPharynx
                     enabled: _crdCovidExamination.is_doctor && !_crdCovidExamination.readOnly
                     text: 'Injected Pharynx'
                  }
               }
               Field{
                  className: 'two wide'
                  CheckBox{
                     id: chkEnlargedTonsils
                     text: 'Enlarged Tonsils'
                     enabled: _crdCovidExamination.is_doctor && !_crdCovidExamination.readOnly

                     onChanged: {
                        if (!chkEnlargedTonsils.checked) {
                           txtEnlargedTonsils.text = ""
                        }
                     }
                  }
               }
               Field{
                  className: 'two wide'
                  TextBox{
                     id: txtEnlargedTonsils
                     enabled: chkEnlargedTonsils.checked && _crdCovidExamination.is_doctor && !_crdCovidExamination.readOnly
                  }
               }
               Field{
                  className: 'two wide'
                  CheckBox{
                     id: chkEnlargedCervical
                     text: 'Enlarged Cervical'
                     enabled: _crdCovidExamination.is_doctor && !_crdCovidExamination.readOnly

                     onChanged: {
                        if (!chkEnlargedCervical.checked) {
                           txtEnlargedCervical.text = ""
                        }
                     }
                  }
               }
               Field{
                  className: 'two wide'
                  TextBox{
                     id: txtEnlargedCervical
                     enabled: chkEnlargedCervical.checked && _crdCovidExamination.is_doctor && !_crdCovidExamination.readOnly
                  }
               }
               Field{
                  className: 'one wide'
                  CheckBox{
                     id: chkRash
                     text: 'Rash'
                     enabled: _crdCovidExamination.is_doctor && !_crdCovidExamination.readOnly

                     onChanged: {
                        if (!chkRash.checked) {
                           txtRash.text = ""
                        }
                     }
                  }
               }
               Field{
                  className: 'two wide'
                  TextBox{
                     id: txtRash
                     enabled: chkRash.checked && _crdCovidExamination.is_doctor && !_crdCovidExamination.readOnly
                  }
               }
            }
            Fields{
               className: 'inline'
               Field{
                  className: 'two wide'
               }
               Field{
                  className: 'two wide'
                  CheckBox{
                     id: chkLungs
                     text: 'Lungs'
                     enabled: _crdCovidExamination.is_doctor && !_crdCovidExamination.readOnly

                     onChanged: {
                        if (!chkLungs.checked) {
                           cboLungs.value = 'NORMAL'
                           txtLungs.text = ""
                        }
                     }
                  }
               }
               Field{
                  className: 'two wide'
                  ComboBox{
                     id: cboLungs
                     enabled: chkLungs.checked && _crdCovidExamination.is_doctor && !_crdCovidExamination.readOnly
                     items: [
                        { id: 'NORMAL', text: 'Normal breath sounds' },
                        { id: 'ABNORMAL', text: 'Abnormal' },
                     ]
                  }
               }
               Field{
                  className: 'three wide'
                  TextBox{
                     id: txtLungs
                     enabled: chkLungs.checked && _crdCovidExamination.is_doctor && !_crdCovidExamination.readOnly
                  }
               }
               Field{
                  className: 'one wide'
                  CheckBox{
                     className: 'one wide'
                     id: chkPhysicalOthers
                     text: 'Others'
                     enabled: _crdCovidExamination.is_doctor && !_crdCovidExamination.readOnly

                     onChanged: {
                        if (!chkPhysicalOthers.checked) {
                           txtPhysicalOthers.text = ""
                        }
                     }
                  }
               }
               Field{
                  className: 'six wide'
                  TextBox{
                     id: txtPhysicalOthers
                     enabled: chkPhysicalOthers.checked && _crdCovidExamination.is_doctor && !_crdCovidExamination.readOnly
                  }
               }
            }
            Fields{
               className: 'inline'
               Field{
                  className: 'two wide'
                  label: 'การตรวจทางห้องปฏิบัติการ<br/>(Investigations)'
               }
               Field{
                  className: 'one wide'
                  CheckBox{
                     id: chkNoneLabInvestigations
                     enabled: _crdCovidExamination.is_doctor && !_crdCovidExamination.readOnly
                     text: "None"

                     onChanged: {
                        if (checked) {
                           clearLabInvestigations()
                        }
                     }
                  }
               }
            }
            Fields{
               className: 'inline'
               Field{
                  className: 'two wide'
               }
               Field{
                  className: 'seven wide'
                  CheckBox{
                     id: chkLabNasalAndThroatSARsCov2
                     text: 'Nasal and throat swab for SAR-CoV2 (Coronavirus disease-19)'
                     enabled: _crdCovidExamination.is_doctor && !_crdCovidExamination.readOnly && !chkNoneLabInvestigations.checked
                  }
               }
               Field{
                  CheckBox{
                     id: chkLabNasalAndThroatVirus22
                     text: 'Nasal and throat swab for respiratory virus 22 สายพันธุ์'
                     enabled: _crdCovidExamination.is_doctor && !_crdCovidExamination.readOnly && !chkNoneLabInvestigations.checked
                  }
               }
            }
            Fields{
               className: 'inline'
               Field{
                  className: 'two wide'
               }
               Field{
                  className: 'seven wide'
                  CheckBox{
                     id: chkLabNasalAndThroatVirus33
                     text: 'Nasal and throat swab for respiratory virus 33 สายพันธุ์'
                     enabled: _crdCovidExamination.is_doctor && !_crdCovidExamination.readOnly && !chkNoneLabInvestigations.checked
                  }
               }
               Field{
                  CheckBox{
                     id: chkLabNasalAB
                     text: 'Nasal swab for rapid test Influenza A and B'
                     enabled: _crdCovidExamination.is_doctor && !_crdCovidExamination.readOnly && !chkNoneLabInvestigations.checked
                  }
               }
            }
            Fields{
               className: 'inline'
               Field{
                  className: 'two wide'
               }
               Field{
                  className: 'three wide'
                  CheckBox{
                     id: chkCBC
                     text: 'CBC'
                     enabled: _crdCovidExamination.is_doctor && !_crdCovidExamination.readOnly && !chkNoneLabInvestigations.checked
                  }
               }
               Field{
                  className: 'three wide'
                  CheckBox{
                     id: chkElectrolytes
                     text: 'Electrolytes and BUN, Cr'
                     enabled: _crdCovidExamination.is_doctor && !_crdCovidExamination.readOnly && !chkNoneLabInvestigations.checked
                  }
               }
               Field{
                  className: 'three wide'
                  CheckBox{
                     id: chkLiver
                     text: 'Liver function test'
                     enabled: _crdCovidExamination.is_doctor && !_crdCovidExamination.readOnly && !chkNoneLabInvestigations.checked
                  }
               }
               Field{
                  className: 'three wide'
                  CheckBox{
                     id: chkXRay
                     text: 'Chest X-ray'
                     enabled: _crdCovidExamination.is_doctor && !_crdCovidExamination.readOnly && !chkNoneLabInvestigations.checked
                  }
               }
            }
            Fields{
               className: 'inline'
               Field{
                  className: 'two wide'
                  label: 'Diagnosis'
               }
               Field{
                  className: 'five wide'
                  CheckBox{
                     id: chkDiagnosis
                     enabled: _crdCovidExamination.is_doctor && !_crdCovidExamination.readOnly
                     text: "Z20.8 Contact with and exposure to other communicable diseases"
                  }
               }
               Field{
                  className: 'two wide'
                  CheckBox{
                     id: chkOtherDiagnosis
                     enabled: _crdCovidExamination.is_doctor && !_crdCovidExamination.readOnly
                     text: 'Others'

                     onChanged: {
                        if (!chkOtherDiagnosis.checked) {
                           txtOtherDiagnosis.text = ""
                        }
                     }
                  }
               }
               Field{
                  className: 'seven wide'
                  TextBox{
                     id: txtOtherDiagnosis
                     enabled: chkOtherDiagnosis.checked && _crdCovidExamination.is_doctor && !_crdCovidExamination.readOnly
                  }
               }
            }
            Fields{
               className: 'inline'
               Field{
                  className: 'two wide'
                  label: 'Treatment<br/>ได้ให้คำแนะนำเกี่ยวกับ'
               }
               Field{
                  className: 'five wide'
                  CheckBox{
                     id: chkAdviced
                     enabled: _crdCovidExamination.is_doctor && !_crdCovidExamination.readOnly
                     text: 'ให้คำแนะนำการปฏิบัติตัว'
                  }
               }
               Field{
                  className: 'three wide'
                  CheckBox{
                     id: chkQuarantine
                     text: 'Self-quarantine จนถึงวันที่'
                     enabled: _crdCovidExamination.is_doctor && !_crdCovidExamination.readOnly

                     onChanged: {
                        if (!chkQuarantine.checked) {
                           dtbQuarantine.text = ""
                        }
                     }
                  }
               }
               Field{
                  className: 'two wide'
                  DateTextBox{
                     id: dtbQuarantine
                     enabled: chkQuarantine.checked && _crdCovidExamination.is_doctor && !_crdCovidExamination.readOnly
                  }
               }
            }
            Fields{
               className: 'inline'
               Field{
                  className: 'two wide'
               }
               Field{
                  className: 'five wide'
                  CheckBox{
                     id: chkDoctorCeritficate
                     enabled: _crdCovidExamination.is_doctor && !_crdCovidExamination.readOnly
                     text: 'ใบรับรองแพทย์'
                  }
               }
               Field{
                  className: 'one wide'
                  CheckBox{
                     id: chkDrug
                     enabled: _crdCovidExamination.is_doctor && !_crdCovidExamination.readOnly
                     text: 'ยา'

                     onChanged: {
                        if (!chkDrug.checked) {
                           txtDrug.text = ""
                        }
                     }
                  }
               }
               Field{
                  className: 'eight wide'
                  TextBox{
                     id: txtDrug
                     enabled: chkDrug.checked && _crdCovidExamination.is_doctor && !_crdCovidExamination.readOnly
                  }
               }
            }
         }
      },
      Form{
         Fields{
            className: 'inline'
            Field{
               className: 'twelve wide'
            }
            Field{
               className: 'two wide'
               Button{
                  className: 'green fluid'
                  displayNone: !_crdCovidExamination.is_doctor || _crdCovidExamination.readOnly
                  text: 'Confirm order'

                  onClicked: {
                     confirmLabInvestigations()
                  }
               }
            }
            Field{
               className: 'one wide'
               Button{
                  className: 'blue fluid'
                  text: 'Print'
                  enabled: rmdFormData.form_data_id
                  onClicked: {
                     printFormData()
                  }
               }
            }
            Field{
               className: 'one wide'
               Button{
                  className: 'green fluid'
                  text: 'Save'
                  displayNone: _crdCovidExamination.readOnly

                  onClicked: {
                     saveFormData()
                  }
               }
            }
         }
      }

   ]
}
        `)
}, 0)

    }

    componentWillUnmount() {
        this._ismounted = false
        //console.log(" ***** componentWillUnmount ", this.cardName)
        document.onkeypress = null;
        document.onkeyup = null;

        // this.engine.stop()
        if(this.qml){
            this.qml.destroy()
            this.removeChildProperties(this.qml)
        }
        
        // this.qml = null
        // this.engine = null
        if(this.props.onUnmount){
            this.props.onUnmount()
         }
        // window.QmlWeb.engine.dom = null
        // window.QmlWeb.engine.domTarget = null
        // window.QmlWeb.engine.rootObject = null
        // window.QmlWeb.engine.completedSignals = []
        // window.QmlWeb.engine = {}
        //console.log(" ***** componentWillUnmount Finish ", this.cardName)
    }

    loadQML = (src, parentComponent = null, file = undefined) => {
        this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        
        // let component = this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        // this.qml = this.engine.rootObject
        // return component
    }

    loadQMLTree = (tree, parentComponent = null, file = undefined) => {

        // Part 1
        let QMLComponent; 
        let component;

        setTimeout( () => { 

            if (!this._ismounted){
                //console.log(" Shutdown Part 1", this.cardName)
                return;
            }
            this.engine = window.QmlWeb.engine;
        
            if (!this.engine) {
                this.engine = new window.QmlWeb.QMLEngine(ReactDOM.findDOMNode(this));
                // window.addEventListener("resize", () => this.engine.updateGeometry());
            } else {
                this.engine.cleanEngine(ReactDOM.findDOMNode(this))
            }

            this.engine.$basePathA = document.createElement('a')
            this.engine.$basePathA.href = this.extractBasePath(`/static/qml/DPO/CardCovidExamination.qml`)
            this.engine.$basePath = this.engine.$basePathA.href
            //console.log(" CDM this.engine.$basePathA.href: ", this.engine.$basePathA.href)

            window.QmlWeb.engine = this.engine;

            // Create and initialize objects
            QMLComponent = window.QmlWeb.getConstructor("QtQml", "2.0", "Component");
            component = new QMLComponent({
                object: tree,
                parent: parentComponent
            });
            //console.log("Part 1", this.cardName)
        },0)

        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 2", this.cardName)
                return;
            }
            
            this.engine.loadImports(tree.$imports, undefined, component.importContextId);
            component.$basePath = this.engine.$basePath;
            component.$imports = tree.$imports; // for later use
            component.$file = file; // just for debugging
            //console.log("Part 2", this.cardName)
        }, 0);
    

        // Part 3,4,5
        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 3", this.cardName)
                return;
            }
            this.engine.rootObject = component.$createObject(parentComponent);
            
            if (this.engine.rootObject.dom) {
                this.engine.domTarget.appendChild(this.engine.rootObject.dom);
            }
     
            this.qml = this.engine.rootObject
            this.setUpSignals()
            this.setUpProperties()
            //console.log("3. setUpSignals(), setUpProperties() Done", this.cardName)
 
            this.engine.$initializePropertyBindings();
            this.engine.start();

            this.engine.updateGeometry();
            this.qml = this.engine.rootObject

            this.setState({qml: this.qml})
            //console.log("4. finish loadQMLTree", this.cardName)

            
            this.engine.firstCallCompleted = false;
            this.engine.callCompletedSignals();
            this.engine.firstCallCompleted = true;
            //console.log("5. CallCompletedSignal", this.cardName)

            if (this.props.completedQMLLoad) {
                //console.log("Callback completedQMLLoad !!")
                setTimeout( () => {
                    this.props.completedQMLLoad()
                })
            }
        }, 0);
    }
    
    deCapitalizeFirstLetter(string) {
        return string.charAt(0).toLowerCase() + string.slice(1);
    }

    hasSignalName(signalName) {
        return (
            typeof this.qml[signalName] === 'function' 
            && typeof this.qml[signalName].connect === 'function'
        )
    }

    setUpSignals() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let startsWithOn = key.startsWith('on')
            let typeFunction = typeof value === 'function'
            if (!startsWithOn || !typeFunction) {
                return
            }
            if (!this.hasSignalName(signalName)) {
                console.warn('Cannot find a signal name: ' + signalName)
                return
            }
            this.qml[signalName].disconnect()
            this.qml[signalName].connect(this.qml, value)
        })
    }

    setUpProperties() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let propertyExists = typeof this.qml.$properties[key] !== 'undefined'
            if (this.hasSignalName(signalName)) {
                return
            }
            if (!propertyExists) {
                const createProperty = window.QmlWeb.createProperty;
                createProperty("variant", this.qml, key, value);
                //console.warn('Cannot find a property name: ' + key)
                // return
            }
            this.qml[key] = value
        })
    }

    extractBasePath(file) {
        const basePath = file.split(/[/\\\\]/)
        basePath[basePath.length - 1] = ''
        return basePath.join('/')
    }

    extractFileName(file) {
        return file.split(/[/\\\\]/).pop()
    }

    removeChildProperties(child) {
        const signals = this.engine.completedSignals
        if (signals) {
            signals.splice(signals.indexOf(child.Component.completed), 1)
        }
        if(child.children) {
            for (let i = 0; i < child.children.length; i++) {
                this.removeChildProperties(child.children[i])
            }
        }
        child.$signals = null
    }

    render() {
        if (this.state.qml) {
            this.setUpSignals()
            this.setUpProperties()
        }
        return React.createElement('div')
    }
}