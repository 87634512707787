import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Dropdown,
  Button,
  Checkbox,
  FormGroup,
  FormField
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardBedUX = (props: any) => {
    return(
      <div
        style={{width: "100%", height: "100%"}}>
        <div
          style={{padding: "10px", fontWeight: "bold", fontSize: "1.2rem", display: props.hideAdmitOrder ? "none" : ""}}>
          Admit Order
        </div>
        <Table
          data={props.admitOrderList}
          getTrProps={props.orderRowProps}
          headers="Code,HN,1st Room Type,Admit Type,Coverage,Room No,Status"
          keys="code,hn,first_room_type_name,admit_type,coverage,room_no,status_label"
          minRows="2"
          showPagination={false}
          style={{height: "100px", display: props.hideAdmitOrder ? "none": ""}}>
        </Table>
        <Button
          color="green"
          disabled={props.disableAdmit}
          onClick={props.onAdmit}
          size="small"
          style={{display : props.hideAdmitOrder? "none":""}}>
          รับเข้าห้อง
        </Button>
        <div
          style={{padding: "10px", fontWeight: "bold", fontSize: "1.2rem"}}>
          รายการเตียง
        </div>
        <div
          style={{display: "none",alignItems: "center",flexWrap:"wrap",margin: "10px 100px", gap: "10px", justifyContent: "center"}}>
          
          <div
            style={{display: "flex", alignItems: "center"}}>
            
            <Checkbox
              checked={props.isHN}
              onChange={props.changeSetRoom("isHN")}>
            </Checkbox>
            <div
              style={{marginLeft: "5px", marginRight: "5px"}}>
              HN
            </div>
            <div
              style={{width: "100%"}}>
              {props.PatientSearchBox}
            </div>
          </div>
          <div
            style={{display: "flex", alignItems: "center"}}>
            
            <div
              style={{marginLeft: "5px", marginRight: "5px"}}>
              ประเภทห้อง
            </div>
            <Dropdown
              onChange={props.changeSetRoom("selectedRoomType")}
              options={props.roomTypeOptions}
              selection={true}
              value={props.selectedRoomType}>
            </Dropdown>
          </div>
          <div
            style={{display: "flex", alignItems: "center"}}>
            
            <div
              style={{marginLeft: "5px", marginRight: "5px"}}>
              ประเภทหอผู้ป่วย
            </div>
            <Dropdown
              onChange={props.changeSetRoom("selectedWardType")}
              options={props.wardTypeOptions}
              selection={true}
              value={props.selectedWardType}>
            </Dropdown>
          </div>
          <div
            style={{display: "flex", alignItems: "center"}}>
            
            <div
              style={{marginLeft: "5px", marginRight: "5px"}}>
              หน่วยตรวจ
            </div>
            <Dropdown
              onChange={props.changeSetRoom("selectedDivision")}
              options={props.divisionOptions}
              selection={true}
              value={props.selectedDivision}>
            </Dropdown>
          </div>
          <div
            style={{display: "flex", alignItems: "center"}}>
            
            <div
              style={{marginLeft: "5px", marginRight: "5px"}}>
              สถานะห้อง
            </div>
            <Dropdown
              onChange={props.changeSetRoom("selectedRoomStatus")}
              options={props.roomStatusOptions}
              selection={true}
              value={props.selectedRoomStatus}>
            </Dropdown>
          </div>
          <Button
            color="blue"
            loading={props.searching}
            onClick={props.onSearch}
            size="small"
            style={{marginLeft: "5px"}}>
            ค้นหา
          </Button>
          <Button
            color="brown"
            onClick={props.onPrint}
            style={{...(!props.showPrintBtn && {display: "none"})}}>
            พิมพ์รายงาน
          </Button>
        </div>
        <div
          className="ui form">
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <Checkbox
                checked={props.isHN}
                label="HN"
                onChange={props.changeSetRoom("isHN")}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <div
                style={{width: "100%"}}>
                {props.PatientSearchBox}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <div>
                ประเภทห้อง
              </div>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <Dropdown
                fluid={true}
                onChange={props.changeSetRoom("selectedRoomType")}
                options={props.roomTypeOptions}
                selection={true}
                style={{width: "100%"}}
                value={props.selectedRoomType}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}>
              <div>
                ประเภทหอผู้ป่วย
              </div>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <Dropdown
                fluid={true}
                onChange={props.changeSetRoom("selectedWardType")}
                options={props.wardTypeOptions}
                selection={true}
                style={{width: "100%"}}
                value={props.selectedWardType}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}>
              <div>
                หน่วยตรวจ
              </div>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <Dropdown
                fluid={true}
                onChange={props.changeSetRoom("selectedDivision")}
                options={props.divisionOptions}
                selection={true}
                style={{width: "100%"}}
                value={props.selectedDivision}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}>
              <div>
                สถานะห้อง
              </div>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <Dropdown
                fluid={true}
                onChange={props.changeSetRoom("selectedRoomStatus")}
                options={props.roomStatusOptions}
                selection={true}
                style={{width: "100%"}}
                value={props.selectedRoomStatus}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}>
              <Button
                color="blue"
                loading={props.searching}
                onClick={props.onSearch}
                size="small"
                style={{minWidth: "max-content"}}>
                ค้นหา
              </Button>
            </FormField>
            <FormField
              inline={true}
              style={{...(!props.showPrintBtn && {display: "none"})}}>
              <Button
                color="brown"
                onClick={props.onPrint}
                style={{minWidth: "max-content"}}>
                พิมพ์รายงาน
              </Button>
            </FormField>
          </FormGroup>
        </div>
        <Table
          data={props.roomList}
          getTrProps={props.roomRowProps}
          headers="เลขห้อง/เตียง,Ward,ประเภทห้อง,HN,AN,ชื่อ-สกุลผู้ป่วย,เพศ,สถานะห้อง"
          keys="room_no,ward_name,room_name,hn,an,patient_name,patient_gender,room_status"
          minRows={10}
          showPagination={false}
          style={{height: "400px"}}>
        </Table>
        <div
          style={{display: "flex", alignItems: "center", justifyContent: "space-between", ...(props.hiddenCardBedAction && {display: "none"})}}>
          
          <div
            style={{display: "flex",alignItems:"center"}}>
            
            <div>
              ห้องที่เลือก: 
            </div>
            <div
              style={{marginLeft: "5px", marginRight: "15px"}}>
              {props.roomDetail}
            </div>
            <Button
              color={props.config?.disableRoomReserve ? "" : "facebook"}
              disabled={props.config?.disableRoomReserve}
              onClick={props.onRoomReserve}
              style={{display: props.config?.hideRoomReserve ? "none": ""}}>
              จองห้อง
            </Button>
            <Button
              color="facebook"
              disabled={props.disableRoomTransfer}
              onClick={props.onRoomTransfer}
              size="mini"
              style={{display: "none"}}>
              ย้ายเตียง
            </Button>
            <Button
              color={props.config?.disableRoomAdmit ? "" : "green"}
              disabled={props.config?.disableRoomAdmit}
              onClick={props.onRoomAdmit}
              style={{display: props.config?.hideRoomAdmit ? "none": ""}}>
              รับเข้าห้อง
            </Button>
            <Button
              color={props.config?.disableCancelReserve ? "" : "red"}
              disabled={props.config?.disableCancelReserve}
              onClick={props.onCancelReserve}
              style={{display: props.config?.hideCancelReserve ? "none": ""}}>
              ยกเลิกจอง
            </Button>
            <Button
              color={props.config?.disableCancelAdmit ? "" : "red"}
              disabled={props.config?.disableCancelAdmit}
              onClick={props.onCancelAdmit}
              style={{display: props.config?.hideCancelAdmit ? "none": ""}}>
              ยกเลิกสถานะ Admit
            </Button>
            <Button
              color={props.config?.disableBedMove ? "" : "teal"}
              disabled={props.config?.disableBedMove}
              onClick={props.onBedMove}
              style={{display: props.config?.hideBedMove ? "none": ""}}>
              ย้ายเตียง
            </Button>
            <Button
              color={props.config?.disableWardMove ? "" : "teal"}
              disabled={props.config?.disableWardMove}
              onClick={props.onMoveWard}
              style={{display: props.config?.hideWardMove ? "none": ""}}>
              ย้าย Ward
            </Button>
          </div>
          <div
            style={{paddingRight: "20px"}}>
            {props.paginationBed}
          </div>
        </div>
      </div>
    )
}


export default CardBedUX

export const screenPropsDefault = {"roomDetail":"7C06 Ward  อายุรกรรม ห้อง Standard","searchLabel":"ค้นหา"}

/* Date Time : Mon Nov 13 2023 16:48:37 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการเตียง"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", fontWeight: \"bold\", fontSize: \"1.2rem\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"none\",alignItems: \"center\",flexWrap:\"wrap\",margin: \"10px 100px\", gap: \"10px\", justifyContent: \"center\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "HN"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"5px\", marginRight: \"5px\"}"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทห้อง"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"5px\", marginRight: \"5px\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทหอผู้ป่วย"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"5px\", marginRight: \"5px\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": "หน่วยตรวจ"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"5px\", marginRight: \"5px\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะห้อง"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"5px\", marginRight: \"5px\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "Dropdown",
      "parent": 7,
      "props": {
        "compact": {
          "type": "code",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.changeSetRoom(\"selectedRoomType\")"
        },
        "options": {
          "type": "code",
          "value": "props.roomTypeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.selectedRoomType"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "Dropdown",
      "parent": 10,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changeSetRoom(\"selectedWardType\")"
        },
        "options": {
          "type": "code",
          "value": "props.wardTypeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.selectedWardType"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "Dropdown",
      "parent": 13,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changeSetRoom(\"selectedDivision\")"
        },
        "options": {
          "type": "code",
          "value": "props.divisionOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.selectedDivision"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "Dropdown",
      "parent": 16,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changeSetRoom(\"selectedRoomStatus\")"
        },
        "options": {
          "type": "code",
          "value": "props.roomStatusOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.selectedRoomStatus"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 23,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.roomList"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.roomRowProps"
        },
        "headers": {
          "type": "value",
          "value": "เลขห้อง/เตียง,Ward,ประเภทห้อง,HN,AN,ชื่อ-สกุลผู้ป่วย,เพศ,สถานะห้อง"
        },
        "keys": {
          "type": "value",
          "value": "room_no,ward_name,room_name,hn,an,patient_name,patient_gender,room_status"
        },
        "minRows": {
          "type": "code",
          "value": "10"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"400px\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "Button",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "loading": {
          "type": "code",
          "value": "props.searching"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSearch"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"5px\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "Admit Order"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", fontWeight: \"bold\", fontSize: \"1.2rem\", display: props.hideAdmitOrder ? \"none\" : \"\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 31,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.admitOrderList"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.orderRowProps"
        },
        "headers": {
          "type": "value",
          "value": "Code,HN,1st Room Type,Admit Type,Coverage,Room No,Status"
        },
        "keys": {
          "type": "value",
          "value": "code,hn,first_room_type_name,admit_type,coverage,room_no,status_label"
        },
        "minRows": {
          "type": "value",
          "value": "2"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"100px\", display: props.hideAdmitOrder ? \"none\": \"\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "Button",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "รับเข้าห้อง"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.disableAdmit"
        },
        "onClick": {
          "type": "code",
          "value": "props.onAdmit"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{display : props.hideAdmitOrder? \"none\":\"\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", justifyContent: \"space-between\", ...(props.hiddenCardBedAction && {display: \"none\"})}"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 33,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",alignItems:\"center\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "div",
      "parent": 34,
      "props": {
        "children": {
          "type": "value",
          "value": "ห้องที่เลือก: "
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 34,
      "props": {
        "children": {
          "type": "code",
          "value": "props.roomDetail"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"5px\", marginRight: \"15px\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "Button",
      "parent": 34,
      "props": {
        "children": {
          "type": "value",
          "value": "จองห้อง"
        },
        "color": {
          "type": "code",
          "value": "props.config?.disableRoomReserve ? \"\" : \"facebook\""
        },
        "disabled": {
          "type": "code",
          "value": "props.config?.disableRoomReserve"
        },
        "onClick": {
          "type": "code",
          "value": "props.onRoomReserve"
        },
        "size": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.config?.hideRoomReserve ? \"none\": \"\"}"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "Button",
      "parent": 34,
      "props": {
        "children": {
          "type": "value",
          "value": "ย้ายเตียง"
        },
        "color": {
          "type": "value",
          "value": "facebook"
        },
        "disabled": {
          "type": "code",
          "value": "props.disableRoomTransfer"
        },
        "onClick": {
          "type": "code",
          "value": "props.onRoomTransfer"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{display: \"none\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 33,
      "props": {
        "children": {
          "type": "code",
          "value": "props.paginationBed"
        },
        "style": {
          "type": "code",
          "value": "{paddingRight: \"20px\"}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "Button",
      "parent": 34,
      "props": {
        "children": {
          "type": "value",
          "value": "รับเข้าห้อง"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "color": {
          "type": "code",
          "value": "props.config?.disableRoomAdmit ? \"\" : \"green\""
        },
        "disabled": {
          "type": "code",
          "value": "props.config?.disableRoomAdmit"
        },
        "onClick": {
          "type": "code",
          "value": "props.onRoomAdmit"
        },
        "style": {
          "type": "code",
          "value": "{display: props.config?.hideRoomAdmit ? \"none\": \"\"}"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "Button",
      "parent": 34,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิกจอง"
        },
        "color": {
          "type": "code",
          "value": "props.config?.disableCancelReserve ? \"\" : \"red\""
        },
        "disabled": {
          "type": "code",
          "value": "props.config?.disableCancelReserve"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancelReserve"
        },
        "style": {
          "type": "code",
          "value": "{display: props.config?.hideCancelReserve ? \"none\": \"\"}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "Button",
      "parent": 34,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิกสถานะ Admit"
        },
        "color": {
          "type": "code",
          "value": "props.config?.disableCancelAdmit ? \"\" : \"red\""
        },
        "disabled": {
          "type": "code",
          "value": "props.config?.disableCancelAdmit"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancelAdmit"
        },
        "style": {
          "type": "code",
          "value": "{display: props.config?.hideCancelAdmit ? \"none\": \"\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "code",
          "value": "props.PatientSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "Button",
      "parent": 34,
      "props": {
        "children": {
          "type": "value",
          "value": "ย้ายเตียง"
        },
        "color": {
          "type": "code",
          "value": "props.config?.disableBedMove ? \"\" : \"teal\""
        },
        "disabled": {
          "type": "code",
          "value": "props.config?.disableBedMove"
        },
        "onClick": {
          "type": "code",
          "value": "props.onBedMove"
        },
        "style": {
          "type": "code",
          "value": "{display: props.config?.hideBedMove ? \"none\": \"\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "Button",
      "parent": 34,
      "props": {
        "children": {
          "type": "value",
          "value": "ย้าย Ward"
        },
        "color": {
          "type": "code",
          "value": "props.config?.disableWardMove ? \"\" : \"teal\""
        },
        "disabled": {
          "type": "code",
          "value": "props.config?.disableWardMove"
        },
        "onClick": {
          "type": "code",
          "value": "props.onMoveWard"
        },
        "style": {
          "type": "code",
          "value": "{display: props.config?.hideWardMove ? \"none\": \"\"}"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "Checkbox",
      "parent": 6,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isHN"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeSetRoom(\"isHN\")"
        },
        "onClick": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "Button",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์รายงาน"
        },
        "color": {
          "type": "value",
          "value": "brown"
        },
        "disabled": {
          "type": "code",
          "value": ""
        },
        "onClick": {
          "type": "code",
          "value": "props.onPrint"
        },
        "style": {
          "type": "code",
          "value": "{...(!props.showPrintBtn && {display: \"none\"})}"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "FormGroup",
      "parent": 48,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "FormField",
      "parent": 49,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "FormField",
      "parent": 49,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "FormField",
      "parent": 49,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "FormField",
      "parent": 49,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "FormField",
      "parent": 49,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "FormField",
      "parent": 49,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 56,
      "name": "FormField",
      "parent": 49,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "FormField",
      "parent": 49,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "FormField",
      "parent": 49,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "FormField",
      "parent": 49,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "FormField",
      "parent": 49,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "FormField",
      "parent": 49,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{...(!props.showPrintBtn && {display: \"none\"})}"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "Checkbox",
      "parent": 50,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isHN"
        },
        "label": {
          "type": "value",
          "value": "HN"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeSetRoom(\"isHN\")"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "div",
      "parent": 51,
      "props": {
        "children": {
          "type": "code",
          "value": "props.PatientSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": null,
      "id": 64,
      "name": "div",
      "parent": 52,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทห้อง"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "Dropdown",
      "parent": 53,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeSetRoom(\"selectedRoomType\")"
        },
        "options": {
          "type": "code",
          "value": "props.roomTypeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedRoomType"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": null,
      "id": 66,
      "name": "div",
      "parent": 54,
      "props": {
        "children": {
          "type": "value",
          "value": "หน่วยตรวจ"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "Dropdown",
      "parent": 55,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeSetRoom(\"selectedDivision\")"
        },
        "options": {
          "type": "code",
          "value": "props.divisionOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedDivision"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "div",
      "parent": 56,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะห้อง"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "div",
      "parent": 58,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทหอผู้ป่วย"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "Dropdown",
      "parent": 57,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeSetRoom(\"selectedRoomStatus\")"
        },
        "options": {
          "type": "code",
          "value": "props.roomStatusOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedRoomStatus"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "Dropdown",
      "parent": 59,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeSetRoom(\"selectedWardType\")"
        },
        "options": {
          "type": "code",
          "value": "props.wardTypeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedWardType"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "Button",
      "parent": 60,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "loading": {
          "type": "code",
          "value": "props.searching"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSearch"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "Button",
      "parent": 61,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์รายงาน"
        },
        "color": {
          "type": "value",
          "value": "brown"
        },
        "onClick": {
          "type": "code",
          "value": "props.onPrint"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 73,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 75,
  "isMounted": false,
  "memo": false,
  "name": "CardBedUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "roomDetail": "7C06 Ward  อายุรกรรม ห้อง Standard",
    "searchLabel": "ค้นหา"
  },
  "width": 75
}

*********************************************************************************** */
