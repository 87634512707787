import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'

const CardMedReconcileLogsUX = (props: any) => {
    return(
      <div>
        <div
          style={{padding: "10px", fontSize: "1.6rem", fontWeight: "bold" }}>
          ประวัติการทำงาน
          <div>
            
            <Table
              data={props.medReconcileLogs}
              defaultPageSize={props.medReconcileLogs?.length}
              headers="User,Action,Remark,Division,Date-time"
              keys="user_name, action_label, remark, division_name, date_time"
              showPagination={false}
              style={{height: "300px", margin: "10px",fontSize: "1.2rem", fontWeight: "normal"}}
              widths="200,200,auto,200,200">
            </Table>
          </div>
        </div>
      </div>
    )
}

export default CardMedReconcileLogsUX

export const screenPropsDefault = {}

/* Date Time : Tue Feb 28 2023 02:10:11 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติการทำงาน"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", fontSize: \"1.6rem\", fontWeight: \"bold\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 3,
      "name": "Table",
      "parent": 2,
      "props": {
        "data": {
          "type": "code",
          "value": "props.medReconcileLogs"
        },
        "defaultPageSize": {
          "type": "code",
          "value": "props.medReconcileLogs?.length"
        },
        "headers": {
          "type": "value",
          "value": "User,Action,Remark,Division,Date-time"
        },
        "keys": {
          "type": "value",
          "value": "user_name, action_label, remark, division_name, date_time"
        },
        "pageSize": {
          "type": "code",
          "value": ""
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"300px\", margin: \"10px\",fontSize: \"1.2rem\", fontWeight: \"normal\"}"
        },
        "widths": {
          "type": "value",
          "value": "200,200,auto,200,200"
        }
      },
      "seq": 3,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardMedReconcileLogsUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
