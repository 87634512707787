import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Segment,
  Icon,
  FormGroup,
  FormField,
  Input,
  Radio,
  Button
} from 'semantic-ui-react'

const ModOpenEmergencyUX = (props: any) => {
    return(
      <div
        style={{ width : "100%", height: "100%" }}>
        <Segment>
          <div
            style={{display: "flex", justifyContent: "space-between"}}>
            
            <div
              style={{fontWeight: "bold", fontSize: "1.2rem"}}>
              ลงทะเบียนฉุกเฉิน
            </div>
            <div
              style={{cursor: "pointer"}}>
              
              <Icon
                className="red"
                name={"close"}
                onClick={props.onClose}>
              </Icon>
            </div>
          </div>
          <hr>
          </hr>
          <div
            className="ui form">
            
            <div
              style={{width: "100%"}}>
              {props.ErrorMessage}
            </div>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={2}>
                <label
                  style={{textAlign: "left", width: "100%"}}>
                  HN : 
                </label>
              </FormField>
              <FormField
                inline={true}
                width={5}>
                <Input
                  disabled={true}
                  readOnly={true}
                  style={{width:"100%"}}
                  value={props.hn}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                className="required"
                inline={true}
                width={2}>
                <label
                  style={{textAlign: "left", width: "100%"}}>
                  เพศ
                </label>
              </FormField>
              <FormField
                inline={true}
                width={10}>
                <Radio
                  checked={props.gender  === "M"}
                  label="ชาย"
                  name="gender"
                  onChange={props.changeValue("gender")}
                  style={{ marginRight: "20px" }}
                  value="M">
                </Radio>
                <Radio
                  checked={props.gender  === "F"}
                  label="หญิง"
                  name="gender"
                  onChange={props.changeValue("gender")}
                  style={{ marginRight: "20px" }}
                  value="F">
                </Radio>
                <Radio
                  checked={props.gender  === "CM"}
                  label="เด็กชาย"
                  name="gender"
                  onChange={props.changeValue("gender")}
                  style={{ marginRight: "20px" }}
                  value="CM">
                </Radio>
                <Radio
                  checked={props.gender  === "CF"}
                  label="เด็กหญิง"
                  name="gender"
                  onChange={props.changeValue("gender")}
                  style={{ marginRight: "20px" }}
                  value="CF">
                </Radio>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={2}>
                <label
                  style={{textAlign: "left", width: "100%"}}>
                  ชื่อ :
                </label>
              </FormField>
              <FormField
                inline={true}
                width={5}>
                <Input
                  onChange={props.changeValue("first_name")}
                  style={{width:"100%"}}
                  value={props.first_name}>
                </Input>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <label
                  style={{textAlign: "left", width: "100%"}}>
                  นามสกุล :
                </label>
              </FormField>
              <FormField
                inline={true}
                width={5}>
                <Input
                  onChange={props.changeValue("last_name")}
                  style={{width:"100%"}}
                  value={props.last_name}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={3}>
                <Button
                  color="green"
                  onClick={props.OnSave}
                  style={{width:"100%"}}>
                  Save
                </Button>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <Button
                  color="teal"
                  onClick={props.OnClear}
                  style={{width:"100%"}}>
                  Clear
                </Button>
              </FormField>
            </FormGroup>
          </div>
        </Segment>
      </div>
    )
}

export default ModOpenEmergencyUX

export const screenPropsDefault = {"cannotPay":false,"cashAmountText":"14","citizenID":"31020000552","oweText":123,"patientNameAndHN":"HN: xxxxx นายทดสอบ","sumAmount":{"price":100,"reimbursable":42}}

/* Date Time : Mon Aug 01 2022 16:18:34 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width : \"100%\", height: \"100%\" }"
        }
      },
      "seq": 0
    },
    {
      "from": "semantic-ui-react",
      "id": 1,
      "name": "Segment",
      "parent": 0,
      "props": {
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "ลงทะเบียนฉุกเฉิน"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.2rem\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "hr",
      "parent": 1,
      "props": {
      },
      "seq": 6,
      "void": true
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{cursor: \"pointer\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Icon",
      "parent": 12,
      "props": {
        "className": {
          "type": "value",
          "value": "red"
        },
        "name": {
          "type": "code",
          "value": "\"close\""
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ErrorMessage"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "FormGroup",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "FormField",
      "parent": 16,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "label",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "HN : "
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"left\", width: \"100%\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "FormField",
      "parent": 16,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "Input",
      "parent": 19,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.hn"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "FormGroup",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "FormField",
      "parent": 21,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "label",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": "เพศ"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"left\", width: \"100%\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "FormField",
      "parent": 21,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "10"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "Radio",
      "parent": 24,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.gender  === \"M\""
        },
        "label": {
          "type": "value",
          "value": "ชาย"
        },
        "name": {
          "type": "value",
          "value": "gender"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"gender\")"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\" }"
        },
        "value": {
          "type": "value",
          "value": "M"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "Radio",
      "parent": 24,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.gender  === \"F\""
        },
        "label": {
          "type": "value",
          "value": "หญิง"
        },
        "name": {
          "type": "value",
          "value": "gender"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"gender\")"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\" }"
        },
        "value": {
          "type": "value",
          "value": "F"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "Radio",
      "parent": 24,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.gender  === \"CM\""
        },
        "label": {
          "type": "value",
          "value": "เด็กชาย"
        },
        "name": {
          "type": "value",
          "value": "gender"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"gender\")"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\" }"
        },
        "value": {
          "type": "value",
          "value": "CM"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "Radio",
      "parent": 24,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.gender  === \"CF\""
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "label": {
          "type": "value",
          "value": "เด็กหญิง"
        },
        "name": {
          "type": "value",
          "value": "gender"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"gender\")"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\" }"
        },
        "value": {
          "type": "value",
          "value": "CF"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "FormGroup",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "FormField",
      "parent": 29,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "label",
      "parent": 30,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ :"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"left\", width: \"100%\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "FormField",
      "parent": 29,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "Input",
      "parent": 32,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"first_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.first_name"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "FormField",
      "parent": 29,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "label",
      "parent": 34,
      "props": {
        "children": {
          "type": "value",
          "value": "นามสกุล :"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"left\", width: \"100%\"}"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "FormField",
      "parent": 29,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "Input",
      "parent": 36,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changeValue(\"last_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.last_name"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "FormGroup",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "FormField",
      "parent": 38,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "FormField",
      "parent": 38,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "Button",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": "Clear"
        },
        "color": {
          "type": "value",
          "value": "teal"
        },
        "onClick": {
          "type": "code",
          "value": "props.OnClear"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "Button",
      "parent": 39,
      "props": {
        "children": {
          "type": "value",
          "value": "Save"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.OnSave"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        }
      },
      "seq": 43,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 40,
  "isMounted": false,
  "memo": false,
  "name": "ModOpenEmergencyUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "cannotPay": false,
    "cashAmountText": "14",
    "citizenID": "31020000552",
    "oweText": 123,
    "patientNameAndHN": "HN: xxxxx นายทดสอบ",
    "sumAmount": {
      "price": 100,
      "reimbursable": 42
    }
  },
  "width": 60
}

*********************************************************************************** */
