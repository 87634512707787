import React, { useState } from "react";
import {
  Dimmer,
  Loader,
  Table,
  Button,
  Form,
  Divider,
  Label,
  Tab,
  Icon
} from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import styles from "react-lib/apps/IsHealth/css/styles";
import * as Studio from "react-lib/apps/IsHealth/Studio";
import { useIntl } from "react-intl";

const MainRuleList = (props:any) => {
  const intl = useIntl();
  const history = useHistory();
  const [diagRuleListActive, setDiagRuleListActive] = React.useState([]);
  const [diagRuleListDeactive, setDiagRuleListDeactive] = React.useState([]);
  const [openModCreateForm, setOpenModCreateForm] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  
  React.useEffect(() => {
    if (props.division) {
      props.onGetDivision();
      getDiagRuleListActive();
      getDiagRuleListDeactive();
    }
  }, [props.division]);

  let apiToken = Cookies.get("apiToken")
  // // guard backdoor url
  React.useEffect(() => {
    if (apiToken || (apiToken === "anonymous")) {
      history.push("/");
    }
  }, [apiToken]);

  const openComposer = id => e => {
    console.log("openComposer", id)
    props.setShowProp({screen: "DiagComposer", id: id});
    // history.push("/composer/" + id + "/");
  };

  const handleActivate = id => async e => {
    let [response, error, network] = await props.controller.patchDiagRuleActive(
      {
        id,
        active: true,
        apiToken: Cookies.get("apiToken") ? Cookies.get("apiToken") : props.apiToken
      }
    );
    // setIsLoading(false);
    if (response) {
      getDiagRuleListActive();
      getDiagRuleListDeactive();
    }
  };

  const handleDeactivate = id => async e => {
    let [response, error, network] = await props.controller.patchDiagRuleActive(
      {
        id,
        active: false,
        published: false,
        apiToken: Cookies.get("apiToken") ? Cookies.get("apiToken") : props.apiToken
      }
    );
    // setIsLoading(false);
    if (response) {
      getDiagRuleListActive();
      getDiagRuleListDeactive();
    }
  };

  const handleUnpublish = id => async e => {
    let [
      response,
      error,
      network
    ] = await props.controller.patchDiagRulePublish({
      id,
      published: false,
      apiToken: Cookies.get("apiToken") ? Cookies.get("apiToken") : props.apiToken
    });
    // setIsLoading(false);
    if (response) {
      getDiagRuleListActive();
    }
  };

  const handlePublish = id => async e => {
    let [
      response,
      error,
      network
    ] = await props.controller.patchDiagRulePublish({
      id,
      published: true,
      apiToken: Cookies.get("apiToken") ? Cookies.get("apiToken") : props.apiToken
    });
    // setIsLoading(false);
    if (response) {
      getDiagRuleListActive();
    }
  };

  const ruleListPanes = [
    {
      menuItem: "Active",
      render: () => (
        <Tab.Pane>
          {diagRuleListActive.length > 0 && (
            <Table color={"teal"} stackable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell textAlign="center">{intl.formatMessage({ id: 'สถานะ' })}</Table.HeaderCell>
                  <Table.HeaderCell>{intl.formatMessage({ id: 'ชื่อ' })}</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">Action</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">Action</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {diagRuleListActive.map((item, index) => (
                  <Table.Row key={index}>
                    <Table.Cell
                      width={1}
                      textAlign="center"
                      onClick={openComposer(item.id)}
                    >
                      <Label
                        style={
                          item.published ? styles.darkgreen : styles.lightOrange
                        }
                        content={item.published ? "Published" : "Unpublished"}
                      />
                    </Table.Cell>
                    <Table.Cell width={10} onClick={openComposer(item.id)}>
                      {item.name}
                    </Table.Cell>

                    <Table.Cell textAlign="right" width={2}>
                      <Button
                        size="small"
                        fluid
                        color="yellow"
                        icon
                        labelPosition="left"
                        onClick={handleDeactivate(item.id)}
                      >
                        <Icon name="hide" />
                        <div> Deactivate </div>
                      </Button>
                    </Table.Cell>

                    <Table.Cell textAlign="right" width={2}>
                      {item.published ? (
                        <Button
                          fluid
                          color="orange"
                          icon
                          labelPosition="left"
                          onClick={handleUnpublish(item.id)}
                        >
                          <Icon name="close" />
                          <div> Unpublish </div>
                        </Button>
                      ) : (
                        <Button
                          fluid
                          color="teal"
                          icon
                          labelPosition="left"
                          onClick={handlePublish(item.id)}
                          disabled={!props.isVerified}
                        >
                          <Icon name="server" />
                          <div> Publish </div>
                        </Button>
                      )}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          )}
        </Tab.Pane>
      )
    },
    {
      menuItem: "Deactivate",
      render: () => (
        <Tab.Pane>
          {diagRuleListDeactive.length > 0 && (
            <Table color={"teal"} stackable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell textAlign="center">{intl.formatMessage({ id: 'สถานะ' })}</Table.HeaderCell>
                  <Table.HeaderCell>{intl.formatMessage({ id: 'ชื่อ' })}</Table.HeaderCell>

                  <Table.HeaderCell textAlign="center">Action</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {diagRuleListDeactive.map((item, index) => (
                  <Table.Row key={index}>
                    <Table.Cell
                      width={1}
                      textAlign="center"
                      onClick={openComposer(item.id)}
                    >
                      <Label
                        style={
                          item.published ? styles.darkgreen : styles.lightOrange
                        }
                        content={item.published ? "Published" : "Unpublished"}
                      />
                    </Table.Cell>
                    <Table.Cell width={10} onClick={openComposer(item.id)}>
                      {item.name}
                    </Table.Cell>

                    <Table.Cell textAlign="right" width={2}>
                      <Button
                        size="small"
                        fluid
                        color="green"
                        icon
                        labelPosition="left"
                        onClick={handleActivate(item.id)}
                      >
                        <Icon name="eye" />
                        <div> Activate </div>
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          )}
        </Tab.Pane>
      )
    }
  ];

  const getDiagRuleListActive = async () => {
    if (!props.apiToken && !Cookies.get("apiToken") && !(Cookies.get("apiToken") == "anonymous")) {
      return;
    }
    setIsLoading(true);

    const [response, error, network] = await props.controller.getDiagRule({
      apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
      division: Cookies.get("divisionId") ? Cookies.get("divisionId") : props.division,
      active: true
    });

    setIsLoading(false);
    if (response) {
      setDiagRuleListActive(response.items);
    }
  };

  const getDiagRuleListDeactive = async () => {
    if (!props.apiToken && !Cookies.get("apiToken")) {
      return;
    }
    setIsLoading(true);
    const [response, error, network] = await props.controller.getDiagRule({
      apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
      division: Cookies.get("divisionId") ? Cookies.get("divisionId") : props.division,
      active: false
    });
    setIsLoading(false);
    if (response) {
      setDiagRuleListDeactive(response.items);
    }
  };

  const handleCreateForm = async name =>{
    setOpenModCreateForm(false);
    setIsLoading(true);
    let data = {
      name: name,
      content: JSON.stringify({
        0: {
          id: 0,
          parent_id: null,
          label: "เริ่มต้นคำถามแรก",
          condition: "first",
          text: "",
          type: "root"
        }
      }),
      division: Cookies.get("divisionId") ? Cookies.get("divisionId") : props.division
    };
    const [response, error, network] = await props.controller.postDiagRule({
      data,
      apiToken: Cookies.get("apiToken") ? Cookies.get("apiToken") : props.apiToken
    });
    setIsLoading(false);
    if (response) {
      props.setShowProp({screen: "DiagComposer", id: response.id});
      // history.push("/composer/" + response.id + "/");
    }
  };

  return (<Dimmer.Dimmable>
      <div style={{ padding: "1%" }}>
        <br />
        <Form>
          <Studio.ModCreateForm
            open={openModCreateForm}
            onClose={() => setOpenModCreateForm(false)}
            onSave={handleCreateForm}
          />
          <Form.Group inline style={{ justifyContent: "space-between" }}>
            <Form.Field style={styles.header}>{intl.formatMessage({ id: 'รายการแบบฟอร์ม' })}</Form.Field>
            <Form.Field width={2}>
              <Button
                content="Add Form"
                fluid
                color="blue"
                basic
                style={styles.basicButton}
                onClick={() => {
                  setOpenModCreateForm(true);
                }}
              />
            </Form.Field>
          </Form.Group>
        </Form>
        <Divider style={styles.divider} />
        <Tab menu={{ color: "teal" }} panes={ruleListPanes} />
      </div>
      <Dimmer active={isLoading} inverted>
        <Loader inverted>{"Loading"}</Loader>
      </Dimmer>
    </Dimmer.Dimmable>
  );
};

MainRuleList.defaultProps = {
  controller: {},
  onGetDivision: () => {}
};

MainRuleList.propTypes = {
  controller: PropTypes.object,
  onGetDivision: PropTypes.func
};

export default MainRuleList;
