import React, { useState, useEffect, useRef } from "react";
import { Button, Dimmer, Loader, Dropdown } from "semantic-ui-react";
import {
  serial_to_datestr,
  get_week_slots_display,
  get_startofweek_serial,
  DivisionServiceBlock,
  serial_to_hour,
  QueueController,
  TIME_CONST,
} from "./Time";
import ServiceSlotSummary from "./ServiceSlotSummary";
import Cookies from "js-cookie";
import HourRangeDropdown from "react-lib/apps/common/HourRangeDropdown"
import { useIntl } from "react-intl";

interface TimeDoctorProps {
  apiToken: string;
  controller: QueueController & { prxManager: any; getProviderInfo: any };
  providerId?: number;
  fullName?: string;
  userId?: string;
  isNurse?: boolean;
  onClickDaySlot?: ({
    index,
    weekStartSerial,
  }: {
    index: number;
    weekStartSerial: number;
  }) => {};
}

const TimeDoctor = (props: TimeDoctorProps) => {
  const intl = useIntl();
  const [weekStartSerial, setWeekStartSerial] = useState(
    get_startofweek_serial()
  );
  const [serviceSlots, setServiceSlots] = useState([]);
  const [divisionServiceBlocks, setDivisionServiceBlocks] = useState<
    DivisionServiceBlock[]
  >([]);
  const [doctorDivisions, setDoctorDivisions] = useState<any[]>([]);
  const isMounted = React.useRef(true);
  const [minHour, setMinHour] = useState(0);
  const [maxHour, setMaxHour] = useState(24);
  const [isLoading, setIsLoading] = useState(false);
  const [doctorOptions, setDoctorOptions] = useState<any[]>([]);
  const [selectedDoctor, setSelectedDoctor] = useState<number | string>("");

  const min_hour = Math.min(
    ...divisionServiceBlocks.map((dsb, index) =>
      serial_to_hour(dsb.start_serial)
    )
  );
  const max_hour = Math.max(
    ...divisionServiceBlocks.map((dsb, index) => serial_to_hour(dsb.end_serial))
  );

  const weekSlotsDisplay = get_week_slots_display(
    doctorDivisions,
    serviceSlots,
    weekStartSerial,
    divisionServiceBlocks
  );

  useEffect(() => {
    setMinHour(min_hour);
  }, [min_hour]);

  useEffect(() => {
    if (max_hour > 0) {
      setMaxHour(max_hour);
    }
  }, [max_hour]);

  useEffect(() => {
    handleGetDivisionList();
    handleLoadServiceSlot();

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (props.isNurse) {
      handleGetDoctorList();
    }
  }, []);

  const handleGetDoctorList = async () => {
    const [response] =
      await props.controller.prxManager.getOfficialAccountDoctor({
        divisionId: Cookies.get("division_id"),
        apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
      });

    const key = "user";

    const arrayUniqueByKey = Array.from(
      new Map(
        [
          {
            user_doctor: { full_name: props.fullName },
            user: Number(props.userId),
          },
          ...(response?.items || []),
        ].map((item) => [item[key], item])
      ).values()
    );

    const options = arrayUniqueByKey.map((item: any) => ({
      key: item.user,
      value: item.user,
      text: item.user_doctor?.full_name || "",
    }));

    setDoctorOptions(options);
    setSelectedDoctor(options[0]?.value || null);
  };

  const handleGetDivisionList = async () => {
    setIsLoading(true);
    const [response, error, network] =
      await props.controller.getDivisionHasUser({
        apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
      });
    if (isMounted.current) {
      setIsLoading(false);
      if (response) {
        let item = response.items.map(
          (d: { division: { id: number | string } }) => d.division.id
        );
        setDoctorDivisions(item);
      } else {
        setDoctorDivisions([]);
      }
    }
  };

  const handleLoadServiceSlot = async (providerId?: number | null) => {
    setIsLoading(true);

    providerId = providerId || (props.providerId ? props.providerId : Cookies.get("providerId"));

    const [response, error, network] = await props.controller.loadServiceSlot({
      apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
      providerId,
      fromSerial: weekStartSerial,
      toSerial: weekStartSerial + 96 * 7,
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (response) {
        setServiceSlots(response.items);
      } else {
        setServiceSlots([]);
      }
    }
  };

  useEffect(() => {
    if (doctorDivisions.length > 0) {
      handleLoadDoctorDivisionServiceBlock();
    }
  }, [doctorDivisions, weekStartSerial]);

  useEffect(() => {
    handleLoadServiceSlot();
  }, [weekStartSerial]);

  const handleLoadDoctorDivisionServiceBlock = async () => {
    setIsLoading(true);
    const [response, error, network] =
      await props.controller.loadDivisionServiceBlock({
        apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
        from_serial: weekStartSerial,
        to_serial: weekStartSerial + 96 * 7,
        divisions: doctorDivisions.toString(),
      });
    if (isMounted.current) {
      setIsLoading(false);
      if (response) {
        setDivisionServiceBlocks(response.items);
      } else {
        setDivisionServiceBlocks([]);
      }
    }
  };

  const handleChangeDoctor = async (e: any, v: any) => {
    setIsLoading(true);

    const userId = v.value;
    const [response] = await props.controller.getProviderInfo({
      apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
      userId,
    });

    handleLoadServiceSlot(response?.id);

    setSelectedDoctor(userId);
  };

  const handleClickDaySlot = (params: any) => {
    props.onClickDaySlot?.({
      ...params,
      user: props.isNurse ? selectedDoctor : "",
    });
  }

  const setWeekSerial = async (type: string) => {
    let unit = TIME_CONST.UNIT_PER_WEEK;
    if (type === "back") {
      unit = -TIME_CONST.UNIT_PER_WEEK;
    }
    setWeekStartSerial(weekStartSerial + unit);
  };

  return (
    <div className="DoctorSchedule">
      <Dimmer.Dimmable
        dimmed={isLoading}
        style={{ height: "100%" }}
        className="MainLayout"
      >
        <Dimmer active={isLoading} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
        <div className="header">
          <div>
            <Button compact content="<" onClick={() => setWeekSerial("back")} />
            <span className="dateRange">
              {`${serial_to_datestr(weekStartSerial, "localeDate", {
                month: "long",
                day: "numeric",
              })} -
            ${serial_to_datestr(weekStartSerial + 6 * 96, "localeDate", {
                month: "long",
                day: "numeric",
              })}`}
            </span>
            <Button compact content=">" onClick={() => setWeekSerial("next")} />
          </div>
        </div>
        <div className="content">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <h1>
              สรุปตารางออกตรวจ &ensp;
              <span className="header5">
                &ensp; ช่วงเวลา&ensp;
                <HourRangeDropdown
                  onChange={(e: any) => setMinHour(e.currentTarget.value)}
                  defaultValue={minHour}
                />
                &ensp; ถึง &ensp;{" "}
                <HourRangeDropdown
                  onChange={(e: any) => setMaxHour(e.currentTarget.value)}
                  defaultValue={maxHour}
                />
              </span>
            </h1>
            <div style={{ display: "flex", alignItems: "center" }}>
              {
                props.isNurse && <>
                  <span
                    className="header5"
                    style={{
                      paddingRight: "1rem",
                      fontWeight: "bold",
                    }}
                  >{intl.formatMessage({ id: "ผู้ออกตรวจ:" })}</span>
                  <Dropdown
                    value={selectedDoctor}
                    selection={true}
                    options={doctorOptions}
                    onChange={handleChangeDoctor}
                  />
                </>
              }
            </div>
          </div>
          <div className="calendarContent">
            <ServiceSlotSummary
              weekStartSerial={weekStartSerial}
              weekSlotsDisplay={weekSlotsDisplay}
              min_hour={minHour}
              max_hour={maxHour}
              onClickDaySlot={handleClickDaySlot}
            />
          </div>
        </div>
      </Dimmer.Dimmable>
    </div>
  );
};

export default React.memo(TimeDoctor);
