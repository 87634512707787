import React, {
  CSSProperties,
  ReactElement,
  SyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from "react";

import { Button, DropdownProps, Form, InputProps, Popup, PopupProps } from "semantic-ui-react";

import { ColumnStyle } from "react-lib/appcon/common/DropDownColumn";
import { useIntl } from "react-intl";

// Types
type PopupEditFrequencyDescProps = {
  backgroundColor?: any;
  children: ReactElement;
  description: string;
  disabled?: boolean;
  frequency: number | null;
  options: Record<string, any>[];
  // callback
  onSave: (data: { description: string; frequency: number | null }) => void;
};

type Styles = Record<"popup", CSSProperties>;

const styles: Styles = {
  popup: { minWidth: "22rem" },
};

const POPUP_ON: PopupProps["on"] = ["click"];

const PopupEditFrequencyDesc = (props: PopupEditFrequencyDescProps) => {
  const intl = useIntl();
  const [description, setDescription] = useState("");
  const [frequency, setFrequency] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  // Callback
  const handleOpen = useCallback(() => {
    setIsOpen(true);

    setDescription(props.description);
    setFrequency(props.frequency);
  }, [props.description, props.frequency]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleSave = useCallback(() => {
    props.onSave({ description, frequency });

    handleClose();
  }, [description, frequency]);

  const handleChangeDescription = useCallback((e: SyntheticEvent, data: InputProps) => {
    setDescription(data.value);
  }, []);

  const handleChangeFrequency = useCallback((e: SyntheticEvent, data: DropdownProps) => {
    setFrequency(data.value as number);
  }, []);

  // Memo
  const trigger = useMemo(
    () => (
      <div
        style={{
          ...ColumnStyle,
          backgroundColor: props.backgroundColor ?? ColumnStyle.background,
          textAlign: "center",
        }}
      >
        {props.children}
      </div>
    ),
    [props.backgroundColor, props.children]
  );

  const content = useMemo(
    () => (
      <Form>
        <Form.Group style={{ marginBottom: "0.5rem" }}>
          <Form.Dropdown
            label="Frequency"
            style={{ width: "100%" }}
            value={frequency || ""}
            width={16}
            fluid
            search
            selection
            options={props.options}
            onChange={handleChangeFrequency}
          />
        </Form.Group>
        <Form.Group>
          <Form.Input
            label="Description"
            style={{ width: "100%" }}
            value={description || ""}
            width={16}
            fluid
            onChange={handleChangeDescription}
          />
        </Form.Group>
        <Form.Group style={{ marginBottom: 0 }}>
          <Form.Field style={{ flex: 1 }} />
          <Form.Field style={{ padding: 0 }}>
            <Button color="green" size="mini" onClick={handleSave}>
              SAVE
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>
    ),
    [description, frequency, handleSave, props.options]
  );

  return (
    <Popup
      content={content}
      disabled={props.disabled}
      open={isOpen}
      position="bottom center"
      style={styles.popup}
      trigger={trigger}
      on={POPUP_ON}
      onClose={handleClose}
      onOpen={handleOpen}
    />
  );
};

PopupEditFrequencyDesc.displayName = "PopupEditFrequencyDesc";

export default React.memo(PopupEditFrequencyDesc);
