import React from 'react'
import {
   Button,
   Checkbox,
   Dropdown,
   Input,
   TextArea
} from 'semantic-ui-react'

import styles from './PTprogressionnoteOPDOrtho.module.css'

export interface PTprogressionnoteOPDOrthoViewDataType {
    PtProgressnoteOpdOrtho4: string,
    PtProgressnoteOpdOrtho4Disabled: boolean,
    PtProgressnoteOpdOrtho7: boolean,
    PtProgressnoteOpdOrtho7Disabled: boolean,
    PtProgressnoteOpdOrtho8: string,
    PtProgressnoteOpdOrtho8Disabled: boolean,
    PtProgressnoteOpdOrtho10: string,
    PtProgressnoteOpdOrtho10Disabled: boolean,
    PtProgressnoteOpdOrtho11: boolean,
    PtProgressnoteOpdOrtho11Disabled: boolean,
    PtProgressnoteOpdOrtho13: boolean,
    PtProgressnoteOpdOrtho13Disabled: boolean,
    PtProgressnoteOpdOrtho14: string,
    PtProgressnoteOpdOrtho14Disabled: boolean,
    PtProgressnoteOpdOrtho16: boolean,
    PtProgressnoteOpdOrtho16Disabled: boolean,
    PtProgressnoteOpdOrtho17: string,
    PtProgressnoteOpdOrtho17Disabled: boolean,
    PtProgressnoteOpdOrtho19: boolean,
    PtProgressnoteOpdOrtho19Disabled: boolean,
    PtProgressnoteOpdOrtho20: string,
    PtProgressnoteOpdOrtho20Disabled: boolean,
    PtProgressnoteOpdOrtho21: string,
    PtProgressnoteOpdOrtho21Disabled: boolean,
    PtProgressnoteOpdOrtho23: boolean,
    PtProgressnoteOpdOrtho23Disabled: boolean,
    PtProgressnoteOpdOrtho25: boolean,
    PtProgressnoteOpdOrtho25Disabled: boolean,
    PtProgressnoteOpdOrtho26: string,
    PtProgressnoteOpdOrtho26Disabled: boolean,
    PtProgressnoteOpdOrtho28: boolean,
    PtProgressnoteOpdOrtho28Disabled: boolean,
    PtProgressnoteOpdOrtho29: string,
    PtProgressnoteOpdOrtho29Disabled: boolean,
    PtProgressnoteOpdOrtho31: boolean,
    PtProgressnoteOpdOrtho31Disabled: boolean,
    PtProgressnoteOpdOrtho32: string,
    PtProgressnoteOpdOrtho32Disabled: boolean,
    PtProgressnoteOpdOrtho34: boolean,
    PtProgressnoteOpdOrtho34Disabled: boolean,
    PtProgressnoteOpdOrtho35: string,
    PtProgressnoteOpdOrtho35Disabled: boolean,
    PtProgressnoteOpdOrtho37: boolean,
    PtProgressnoteOpdOrtho37Disabled: boolean,
    PtProgressnoteOpdOrtho38: string,
    PtProgressnoteOpdOrtho38Disabled: boolean,
    PtProgressnoteOpdOrtho40: boolean,
    PtProgressnoteOpdOrtho40Disabled: boolean,
    PtProgressnoteOpdOrtho41: string,
    PtProgressnoteOpdOrtho41Disabled: boolean,
    PtProgressnoteOpdOrtho43: boolean,
    PtProgressnoteOpdOrtho43Disabled: boolean,
    PtProgressnoteOpdOrtho44: string,
    PtProgressnoteOpdOrtho44Disabled: boolean,
    PtProgressnoteOpdOrtho46: boolean,
    PtProgressnoteOpdOrtho46Disabled: boolean,
    PtProgressnoteOpdOrtho47: string,
    PtProgressnoteOpdOrtho47Disabled: boolean,
    PtProgressnoteOpdOrtho49: boolean,
    PtProgressnoteOpdOrtho49Disabled: boolean,
    PtProgressnoteOpdOrtho50: string,
    PtProgressnoteOpdOrtho50Disabled: boolean,
    PtProgressnoteOpdOrtho52: boolean,
    PtProgressnoteOpdOrtho52Disabled: boolean,
    PtProgressnoteOpdOrtho53: string,
    PtProgressnoteOpdOrtho53Disabled: boolean,
    PtProgressnoteOpdOrtho55: string,
    PtProgressnoteOpdOrtho55Disabled: boolean,
    PtProgressnoteOpdOrtho56: boolean,
    PtProgressnoteOpdOrtho56Disabled: boolean,
    PtProgressnoteOpdOrtho58: boolean,
    PtProgressnoteOpdOrtho58Disabled: boolean,
    PtProgressnoteOpdOrtho61: string,
    PtProgressnoteOpdOrtho61Disabled: boolean,
    Ct3DischargeToday: string,
    Ct3DischargeTodayDisabled: boolean,
    Ct3DischargeTodayOptions: any,
    PtProgressnoteOpdOrtho67: string,
    PtProgressnoteOpdOrtho67Disabled: boolean,
    PtProgressnoteOpdOrtho69: boolean,
    PtProgressnoteOpdOrtho69Disabled: boolean,
    PtProgressnoteOpdOrtho72: boolean,
    PtProgressnoteOpdOrtho72Disabled: boolean,
    PtProgressnoteOpdOrtho75: string,
    PtProgressnoteOpdOrtho75Disabled: boolean,
    PtProgressnoteOpdOrtho77: string,
    PtProgressnoteOpdOrtho77Disabled: boolean,
    PtProgressnoteOpdOrtho79: string,
    PtProgressnoteOpdOrtho79Disabled: boolean,
    PtProgressnoteOpdOrtho86: string,
    PtProgressnoteOpdOrtho86Disabled: boolean,
    PtProgressnoteOpdOrtho87: string,
    PtProgressnoteOpdOrtho87Disabled: boolean,
    SaveRequestDisabled: boolean,
}

export const PTprogressionnoteOPDOrthoInitialViewData: PTprogressionnoteOPDOrthoViewDataType = {
    PtProgressnoteOpdOrtho4: "",
    PtProgressnoteOpdOrtho4Disabled: false,
    PtProgressnoteOpdOrtho7: false,
    PtProgressnoteOpdOrtho7Disabled: false,
    PtProgressnoteOpdOrtho8: "",
    PtProgressnoteOpdOrtho8Disabled: false,
    PtProgressnoteOpdOrtho10: "",
    PtProgressnoteOpdOrtho10Disabled: false,
    PtProgressnoteOpdOrtho11: false,
    PtProgressnoteOpdOrtho11Disabled: false,
    PtProgressnoteOpdOrtho13: false,
    PtProgressnoteOpdOrtho13Disabled: false,
    PtProgressnoteOpdOrtho14: "",
    PtProgressnoteOpdOrtho14Disabled: false,
    PtProgressnoteOpdOrtho16: false,
    PtProgressnoteOpdOrtho16Disabled: false,
    PtProgressnoteOpdOrtho17: "",
    PtProgressnoteOpdOrtho17Disabled: false,
    PtProgressnoteOpdOrtho19: false,
    PtProgressnoteOpdOrtho19Disabled: false,
    PtProgressnoteOpdOrtho20: "",
    PtProgressnoteOpdOrtho20Disabled: false,
    PtProgressnoteOpdOrtho21: "",
    PtProgressnoteOpdOrtho21Disabled: false,
    PtProgressnoteOpdOrtho23: false,
    PtProgressnoteOpdOrtho23Disabled: false,
    PtProgressnoteOpdOrtho25: false,
    PtProgressnoteOpdOrtho25Disabled: false,
    PtProgressnoteOpdOrtho26: "",
    PtProgressnoteOpdOrtho26Disabled: false,
    PtProgressnoteOpdOrtho28: false,
    PtProgressnoteOpdOrtho28Disabled: false,
    PtProgressnoteOpdOrtho29: "",
    PtProgressnoteOpdOrtho29Disabled: false,
    PtProgressnoteOpdOrtho31: false,
    PtProgressnoteOpdOrtho31Disabled: false,
    PtProgressnoteOpdOrtho32: "",
    PtProgressnoteOpdOrtho32Disabled: false,
    PtProgressnoteOpdOrtho34: false,
    PtProgressnoteOpdOrtho34Disabled: false,
    PtProgressnoteOpdOrtho35: "",
    PtProgressnoteOpdOrtho35Disabled: false,
    PtProgressnoteOpdOrtho37: false,
    PtProgressnoteOpdOrtho37Disabled: false,
    PtProgressnoteOpdOrtho38: "",
    PtProgressnoteOpdOrtho38Disabled: false,
    PtProgressnoteOpdOrtho40: false,
    PtProgressnoteOpdOrtho40Disabled: false,
    PtProgressnoteOpdOrtho41: "",
    PtProgressnoteOpdOrtho41Disabled: false,
    PtProgressnoteOpdOrtho43: false,
    PtProgressnoteOpdOrtho43Disabled: false,
    PtProgressnoteOpdOrtho44: "",
    PtProgressnoteOpdOrtho44Disabled: false,
    PtProgressnoteOpdOrtho46: false,
    PtProgressnoteOpdOrtho46Disabled: false,
    PtProgressnoteOpdOrtho47: "",
    PtProgressnoteOpdOrtho47Disabled: false,
    PtProgressnoteOpdOrtho49: false,
    PtProgressnoteOpdOrtho49Disabled: false,
    PtProgressnoteOpdOrtho50: "",
    PtProgressnoteOpdOrtho50Disabled: false,
    PtProgressnoteOpdOrtho52: false,
    PtProgressnoteOpdOrtho52Disabled: false,
    PtProgressnoteOpdOrtho53: "",
    PtProgressnoteOpdOrtho53Disabled: false,
    PtProgressnoteOpdOrtho55: "",
    PtProgressnoteOpdOrtho55Disabled: false,
    PtProgressnoteOpdOrtho56: false,
    PtProgressnoteOpdOrtho56Disabled: false,
    PtProgressnoteOpdOrtho58: false,
    PtProgressnoteOpdOrtho58Disabled: false,
    PtProgressnoteOpdOrtho61: "",
    PtProgressnoteOpdOrtho61Disabled: false,
    Ct3DischargeToday: "",
    Ct3DischargeTodayDisabled: false,
    Ct3DischargeTodayOptions: [{key: 0, value: "A", text: "A"}],
    PtProgressnoteOpdOrtho67: "",
    PtProgressnoteOpdOrtho67Disabled: false,
    PtProgressnoteOpdOrtho69: false,
    PtProgressnoteOpdOrtho69Disabled: false,
    PtProgressnoteOpdOrtho72: false,
    PtProgressnoteOpdOrtho72Disabled: false,
    PtProgressnoteOpdOrtho75: "",
    PtProgressnoteOpdOrtho75Disabled: false,
    PtProgressnoteOpdOrtho77: "",
    PtProgressnoteOpdOrtho77Disabled: false,
    PtProgressnoteOpdOrtho79: "",
    PtProgressnoteOpdOrtho79Disabled: false,
    PtProgressnoteOpdOrtho86: "",
    PtProgressnoteOpdOrtho86Disabled: false,
    PtProgressnoteOpdOrtho87: "",
    PtProgressnoteOpdOrtho87Disabled: false,
    SaveRequestDisabled: false,
}

const Sub = (props: any) => {
    // const children = React.Children.toArray(props.children)
    return (
        <div className={styles.container}>
            <div className={styles.PtProgressnoteOpdOrtho2}>
                <div
                    style={{background: "#F3F3F3", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.PtProgressnoteOpdOrtho3}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Problem/ Orthopedic</strong></p>
                </div>
            </div>
            <div className={styles.PtProgressnoteOpdOrtho4}>
                <Input
                    value={props.viewData.PtProgressnoteOpdOrtho4}
                    disabled={ props.viewData.PtProgressnoteOpdOrtho4Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTprogressionnoteOPDOrtho',
                            name: 'PtProgressnoteOpdOrtho4',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtProgressnoteOpdOrtho5}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>diagnosis*</strong></p>
                </div>
            </div>
            <div className={styles.PtProgressnoteOpdOrtho6}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Treatment*</strong></p>
                </div>
            </div>
            <div className={styles.PtProgressnoteOpdOrtho7}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtProgressnoteOpdOrtho7}
                    disabled={ props.viewData.PtProgressnoteOpdOrtho7Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTprogressionnoteOPDOrtho",
                                name: "PtProgressnoteOpdOrtho7"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtProgressnoteOpdOrtho8}>
                <Input
                    value={props.viewData.PtProgressnoteOpdOrtho8}
                    disabled={ props.viewData.PtProgressnoteOpdOrtho8Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTprogressionnoteOPDOrtho',
                            name: 'PtProgressnoteOpdOrtho8',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtProgressnoteOpdOrtho9}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Hot pack</p>
                </div>
            </div>
            <div className={styles.PtProgressnoteOpdOrtho10}>
                <Input
                    value={props.viewData.PtProgressnoteOpdOrtho10}
                    disabled={ props.viewData.PtProgressnoteOpdOrtho10Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTprogressionnoteOPDOrtho',
                            name: 'PtProgressnoteOpdOrtho10',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtProgressnoteOpdOrtho11}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtProgressnoteOpdOrtho11}
                    disabled={ props.viewData.PtProgressnoteOpdOrtho11Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTprogressionnoteOPDOrtho",
                                name: "PtProgressnoteOpdOrtho11"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtProgressnoteOpdOrtho12}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Cold pack</p>
                </div>
            </div>
            <div className={styles.PtProgressnoteOpdOrtho13}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtProgressnoteOpdOrtho13}
                    disabled={ props.viewData.PtProgressnoteOpdOrtho13Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTprogressionnoteOPDOrtho",
                                name: "PtProgressnoteOpdOrtho13"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtProgressnoteOpdOrtho14}>
                <Input
                    value={props.viewData.PtProgressnoteOpdOrtho14}
                    disabled={ props.viewData.PtProgressnoteOpdOrtho14Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTprogressionnoteOPDOrtho',
                            name: 'PtProgressnoteOpdOrtho14',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtProgressnoteOpdOrtho15}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Ultrasound</p>
                </div>
            </div>
            <div className={styles.PtProgressnoteOpdOrtho16}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtProgressnoteOpdOrtho16}
                    disabled={ props.viewData.PtProgressnoteOpdOrtho16Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTprogressionnoteOPDOrtho",
                                name: "PtProgressnoteOpdOrtho16"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtProgressnoteOpdOrtho17}>
                <Input
                    value={props.viewData.PtProgressnoteOpdOrtho17}
                    disabled={ props.viewData.PtProgressnoteOpdOrtho17Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTprogressionnoteOPDOrtho',
                            name: 'PtProgressnoteOpdOrtho17',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtProgressnoteOpdOrtho18}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Ultrasound combine</p>
                </div>
            </div>
            <div className={styles.PtProgressnoteOpdOrtho19}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtProgressnoteOpdOrtho19}
                    disabled={ props.viewData.PtProgressnoteOpdOrtho19Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTprogressionnoteOPDOrtho",
                                name: "PtProgressnoteOpdOrtho19"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtProgressnoteOpdOrtho20}>
                <Input
                    value={props.viewData.PtProgressnoteOpdOrtho20}
                    disabled={ props.viewData.PtProgressnoteOpdOrtho20Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTprogressionnoteOPDOrtho',
                            name: 'PtProgressnoteOpdOrtho20',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtProgressnoteOpdOrtho21}>
                <Input
                    value={props.viewData.PtProgressnoteOpdOrtho21}
                    disabled={ props.viewData.PtProgressnoteOpdOrtho21Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTprogressionnoteOPDOrtho',
                            name: 'PtProgressnoteOpdOrtho21',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtProgressnoteOpdOrtho22}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Shortwave diathermy</p>
                </div>
            </div>
            <div className={styles.PtProgressnoteOpdOrtho23}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtProgressnoteOpdOrtho23}
                    disabled={ props.viewData.PtProgressnoteOpdOrtho23Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTprogressionnoteOPDOrtho",
                                name: "PtProgressnoteOpdOrtho23"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtProgressnoteOpdOrtho24}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>LASER</p>
                </div>
            </div>
            <div className={styles.PtProgressnoteOpdOrtho25}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtProgressnoteOpdOrtho25}
                    disabled={ props.viewData.PtProgressnoteOpdOrtho25Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTprogressionnoteOPDOrtho",
                                name: "PtProgressnoteOpdOrtho25"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtProgressnoteOpdOrtho26}>
                <Input
                    value={props.viewData.PtProgressnoteOpdOrtho26}
                    disabled={ props.viewData.PtProgressnoteOpdOrtho26Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTprogressionnoteOPDOrtho',
                            name: 'PtProgressnoteOpdOrtho26',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtProgressnoteOpdOrtho27}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Cervical traction</p>
                </div>
            </div>
            <div className={styles.PtProgressnoteOpdOrtho28}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtProgressnoteOpdOrtho28}
                    disabled={ props.viewData.PtProgressnoteOpdOrtho28Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTprogressionnoteOPDOrtho",
                                name: "PtProgressnoteOpdOrtho28"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtProgressnoteOpdOrtho29}>
                <Input
                    value={props.viewData.PtProgressnoteOpdOrtho29}
                    disabled={ props.viewData.PtProgressnoteOpdOrtho29Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTprogressionnoteOPDOrtho',
                            name: 'PtProgressnoteOpdOrtho29',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtProgressnoteOpdOrtho30}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Lumbar traction</p>
                </div>
            </div>
            <div className={styles.PtProgressnoteOpdOrtho31}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtProgressnoteOpdOrtho31}
                    disabled={ props.viewData.PtProgressnoteOpdOrtho31Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTprogressionnoteOPDOrtho",
                                name: "PtProgressnoteOpdOrtho31"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtProgressnoteOpdOrtho32}>
                <Input
                    value={props.viewData.PtProgressnoteOpdOrtho32}
                    disabled={ props.viewData.PtProgressnoteOpdOrtho32Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTprogressionnoteOPDOrtho',
                            name: 'PtProgressnoteOpdOrtho32',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtProgressnoteOpdOrtho33}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Paraffin</p>
                </div>
            </div>
            <div className={styles.PtProgressnoteOpdOrtho34}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtProgressnoteOpdOrtho34}
                    disabled={ props.viewData.PtProgressnoteOpdOrtho34Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTprogressionnoteOPDOrtho",
                                name: "PtProgressnoteOpdOrtho34"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtProgressnoteOpdOrtho35}>
                <Input
                    value={props.viewData.PtProgressnoteOpdOrtho35}
                    disabled={ props.viewData.PtProgressnoteOpdOrtho35Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTprogressionnoteOPDOrtho',
                            name: 'PtProgressnoteOpdOrtho35',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtProgressnoteOpdOrtho36}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Stretching</p>
                </div>
            </div>
            <div className={styles.PtProgressnoteOpdOrtho37}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtProgressnoteOpdOrtho37}
                    disabled={ props.viewData.PtProgressnoteOpdOrtho37Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTprogressionnoteOPDOrtho",
                                name: "PtProgressnoteOpdOrtho37"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtProgressnoteOpdOrtho38}>
                <Input
                    value={props.viewData.PtProgressnoteOpdOrtho38}
                    disabled={ props.viewData.PtProgressnoteOpdOrtho38Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTprogressionnoteOPDOrtho',
                            name: 'PtProgressnoteOpdOrtho38',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtProgressnoteOpdOrtho39}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Strengthening</p>
                </div>
            </div>
            <div className={styles.PtProgressnoteOpdOrtho40}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtProgressnoteOpdOrtho40}
                    disabled={ props.viewData.PtProgressnoteOpdOrtho40Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTprogressionnoteOPDOrtho",
                                name: "PtProgressnoteOpdOrtho40"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtProgressnoteOpdOrtho41}>
                <Input
                    value={props.viewData.PtProgressnoteOpdOrtho41}
                    disabled={ props.viewData.PtProgressnoteOpdOrtho41Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTprogressionnoteOPDOrtho',
                            name: 'PtProgressnoteOpdOrtho41',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtProgressnoteOpdOrtho42}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Hydrotherapy</p>
                </div>
            </div>
            <div className={styles.PtProgressnoteOpdOrtho43}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtProgressnoteOpdOrtho43}
                    disabled={ props.viewData.PtProgressnoteOpdOrtho43Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTprogressionnoteOPDOrtho",
                                name: "PtProgressnoteOpdOrtho43"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtProgressnoteOpdOrtho44}>
                <Input
                    value={props.viewData.PtProgressnoteOpdOrtho44}
                    disabled={ props.viewData.PtProgressnoteOpdOrtho44Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTprogressionnoteOPDOrtho',
                            name: 'PtProgressnoteOpdOrtho44',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtProgressnoteOpdOrtho45}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Ambulation training</p>
                </div>
            </div>
            <div className={styles.PtProgressnoteOpdOrtho46}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtProgressnoteOpdOrtho46}
                    disabled={ props.viewData.PtProgressnoteOpdOrtho46Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTprogressionnoteOPDOrtho",
                                name: "PtProgressnoteOpdOrtho46"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtProgressnoteOpdOrtho47}>
                <Input
                    value={props.viewData.PtProgressnoteOpdOrtho47}
                    disabled={ props.viewData.PtProgressnoteOpdOrtho47Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTprogressionnoteOPDOrtho',
                            name: 'PtProgressnoteOpdOrtho47',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtProgressnoteOpdOrtho48}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>TENS</p>
                </div>
            </div>
            <div className={styles.PtProgressnoteOpdOrtho49}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtProgressnoteOpdOrtho49}
                    disabled={ props.viewData.PtProgressnoteOpdOrtho49Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTprogressionnoteOPDOrtho",
                                name: "PtProgressnoteOpdOrtho49"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtProgressnoteOpdOrtho50}>
                <Input
                    value={props.viewData.PtProgressnoteOpdOrtho50}
                    disabled={ props.viewData.PtProgressnoteOpdOrtho50Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTprogressionnoteOPDOrtho',
                            name: 'PtProgressnoteOpdOrtho50',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtProgressnoteOpdOrtho51}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Electrical stimulation</p>
                </div>
            </div>
            <div className={styles.PtProgressnoteOpdOrtho52}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtProgressnoteOpdOrtho52}
                    disabled={ props.viewData.PtProgressnoteOpdOrtho52Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTprogressionnoteOPDOrtho",
                                name: "PtProgressnoteOpdOrtho52"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtProgressnoteOpdOrtho53}>
                <Input
                    value={props.viewData.PtProgressnoteOpdOrtho53}
                    disabled={ props.viewData.PtProgressnoteOpdOrtho53Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTprogressionnoteOPDOrtho',
                            name: 'PtProgressnoteOpdOrtho53',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtProgressnoteOpdOrtho54}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Others</p>
                </div>
            </div>
            <div className={styles.PtProgressnoteOpdOrtho55}>
                <Input
                    value={props.viewData.PtProgressnoteOpdOrtho55}
                    disabled={ props.viewData.PtProgressnoteOpdOrtho55Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTprogressionnoteOPDOrtho',
                            name: 'PtProgressnoteOpdOrtho55',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtProgressnoteOpdOrtho56}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtProgressnoteOpdOrtho56}
                    disabled={ props.viewData.PtProgressnoteOpdOrtho56Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTprogressionnoteOPDOrtho",
                                name: "PtProgressnoteOpdOrtho56"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtProgressnoteOpdOrtho57}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Plan</strong></p>
                </div>
            </div>
            <div className={styles.PtProgressnoteOpdOrtho58}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtProgressnoteOpdOrtho58}
                    disabled={ props.viewData.PtProgressnoteOpdOrtho58Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTprogressionnoteOPDOrtho",
                                name: "PtProgressnoteOpdOrtho58"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtProgressnoteOpdOrtho59}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>continue program</p>
                </div>
            </div>
            <div className={styles.PtProgressnoteOpdOrtho60}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Other</p>
                </div>
            </div>
            <div className={styles.PtProgressnoteOpdOrtho61}>
                <Input
                    value={props.viewData.PtProgressnoteOpdOrtho61}
                    disabled={ props.viewData.PtProgressnoteOpdOrtho61Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTprogressionnoteOPDOrtho',
                            name: 'PtProgressnoteOpdOrtho61',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtProgressnoteOpdOrtho62}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Note</strong></p>
                </div>
            </div>
            <div className={styles.Ct3DischargeToday}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct3DischargeToday}
                    disabled={ props.viewData.Ct3DischargeTodayDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "PTprogressionnoteOPDOrtho",
                                name: "Ct3DischargeToday",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct3DischargeTodayOptions}
                />
            </div>
            <div className={styles.PtProgressnoteOpdOrtho66}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Discharge status today</strong></p>
                </div>
            </div>
            <div className={styles.PtProgressnoteOpdOrtho67}>
                <Input
                    value={props.viewData.PtProgressnoteOpdOrtho67}
                    disabled={ props.viewData.PtProgressnoteOpdOrtho67Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTprogressionnoteOPDOrtho',
                            name: 'PtProgressnoteOpdOrtho67',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtProgressnoteOpdOrtho68}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Instruction*</strong></p>
                </div>
            </div>
            <div className={styles.PtProgressnoteOpdOrtho69}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtProgressnoteOpdOrtho69}
                    disabled={ props.viewData.PtProgressnoteOpdOrtho69Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTprogressionnoteOPDOrtho",
                                name: "PtProgressnoteOpdOrtho69"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtProgressnoteOpdOrtho70}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Patient and/or family was/were informed about instruction and plan of treatment</p>
                </div>
            </div>
            <div className={styles.PtProgressnoteOpdOrtho72}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtProgressnoteOpdOrtho72}
                    disabled={ props.viewData.PtProgressnoteOpdOrtho72Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTprogressionnoteOPDOrtho",
                                name: "PtProgressnoteOpdOrtho72"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtProgressnoteOpdOrtho73}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Need to be reviewed</p>
                </div>
            </div>
            <div className={styles.SaveRequest}>
                <Button
                    disabled={ props.viewData.SaveRequestDisabled }
                    onClick={(e) => { props.onEvent({
                        message: 'clickButton',
                        params: {
                            view: 'PTprogressionnoteOPDOrtho',
                            name: 'SaveRequest'
                        }
                    })}}
                    color="green"
                    style={{height: "100%", width: "100%"}}
                >
                    Save
                </Button>
            </div>
        </div>
    )
}

export default Sub

// const getTsName = (cssClass: string) => {
//     return cssClass.split('-').map((s) => (
//         s.charAt(0).toUpperCase() + s.slice(1)
//     )).join('')
// }
