import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Input
} from 'semantic-ui-react'

const CardRejectOrderUX = (props: any) => {
    return(
      <div
        style={{padding:"5px"}}>
        <div
          style={{display: "flex",marginTop:"10px" }}>
          
          <div
            style={{display:"flex",alignItems:"center",marginRight:"10px"}}>
            HN 
          </div>
          <Input
            icon="search"
            onChange={props.handleChangeValue("selectedHN")}
            placeholder="Search..."
            style={{marginRight:"10px",width:"25%"}}
            value={props.RejectOrderSequence?.selectedHN}>
          </Input>
          <div
            style={{display:"flex",alignItems:"center",marginRight:"10px"}}>
            ชื่อ
          </div>
          <Input
            fluid={true}
            onChange={props.handleChangeValue("selectedFirstname")}
            style={{marginRight:"10px",width:"25%"}}
            value={props.RejectOrderSequence?.selectedFirstname}>
          </Input>
          <div
            style={{display:"flex",alignItems:"center",marginRight:"10px"}}>
            นามสกุล
          </div>
          <Input
            fluid={true}
            onChange={props.handleChangeValue("selectedLastname")}
            style={{width:"25%"}}
            value={props.RejectOrderSequence?.selectedLastname}>
          </Input>
          <div
            style={{flex:"1"}}>
            
          </div>
          <div>
            {props.buttonSearch}
          </div>
        </div>
        <div
          style={{marginTop:"15px"}}>
          
          <Table
            data={props.rejectOrderList}
            getTrProps={props.getTrProps}
            headers="HN,ชื่อ - นามสกุล,รายละเอียด,ประเภท"
            keys="hn,patient_name,reject_detail,drugType,emr"
            minRows="10"
            showPagination={false}
            style={{ height: "500px" }}
            widths="auto,auto,auto,auto,150">
          </Table>
        </div>
      </div>
    )
}


export default CardRejectOrderUX

export const screenPropsDefault = {"patientSearch":"[Patient Search Box]"}

/* Date Time : Thu Jan 25 2024 11:19:42 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding:\"5px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",marginTop:\"10px\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"15px\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 4,
      "name": "Table",
      "parent": 3,
      "props": {
        "data": {
          "type": "code",
          "value": "props.rejectOrderList"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getTrProps"
        },
        "headers": {
          "type": "value",
          "value": "HN,ชื่อ - นามสกุล,รายละเอียด,ประเภท"
        },
        "keys": {
          "type": "value",
          "value": "hn,patient_name,reject_detail,drugType,emr"
        },
        "minRows": {
          "type": "value",
          "value": "10"
        },
        "pageSize": {
          "type": "code",
          "value": ""
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"500px\" }"
        },
        "widths": {
          "type": "value",
          "value": "auto,auto,auto,auto,150"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ"
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",alignItems:\"center\",marginRight:\"10px\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "Input",
      "parent": 2,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedFirstname\")"
        },
        "style": {
          "type": "code",
          "value": "{marginRight:\"10px\",width:\"25%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.RejectOrderSequence?.selectedFirstname"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "นามสกุล"
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",alignItems:\"center\",marginRight:\"10px\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "Input",
      "parent": 2,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedLastname\")"
        },
        "style": {
          "type": "code",
          "value": "{width:\"25%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.RejectOrderSequence?.selectedLastname"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:\"1\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "HN "
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",alignItems:\"center\",marginRight:\"10px\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "Input",
      "parent": 2,
      "props": {
        "icon": {
          "type": "value",
          "value": "search"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedHN\")"
        },
        "placeholder": {
          "type": "value",
          "value": "Search..."
        },
        "style": {
          "type": "code",
          "value": "{marginRight:\"10px\",width:\"25%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.RejectOrderSequence?.selectedHN"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearch"
        }
      },
      "seq": 14,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardRejectOrderUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "patientSearch": "[Patient Search Box]"
  },
  "width": 80
}

*********************************************************************************** */
