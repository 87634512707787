import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  Button
} from 'semantic-ui-react'

const CardFinancialReportUX = (props: any) => {
    return(
      <div
        style={{position: "relative", padding: "1em"}}>
        <div
          style={{display: "flex"}}>

          <Form>
            <FormGroup
              className="ui form"
              style={{padding: "1em 0em 1em 1em", gap: "5em"}}>
              <Button
                name="REMITTANCE"
                onClick={props.onSelectMenu}
                style={{width: "22em"}}>
                รายงานใบนำส่งเงิน
              </Button>
              <Button
                name="OUTSTANDING_DEBTOR"
                onClick={props.onSelectMenu}
                style={{width: "22em"}}>
                รายงานรายการลูกหนี้คงค้าง
              </Button>
            </FormGroup>
            <FormGroup
              className="ui form"
              style={{padding: "1em 0em 1em 1em", gap: "5em"}}>
              <Button
                name="SEPARATE_COVERAGE"
                onClick={props.onSelectMenu}
                style={{width: "22em"}}>
                รายงานค่ารักษาพยาบาลแยกสิทธิ
              </Button>
              <Button
                name="REPAY_DEBT"
                onClick={props.onSelectMenu}
                style={{width: "22em"}}>
                รายงานการชำระหนี้
              </Button>
            </FormGroup>
            <FormGroup
              className="ui form"
              style={{padding: "1em 0em 1em 1em", gap: "5em"}}>
              <Button
                name="OVERDUE"
                onClick={props.onSelectMenu}
                style={{width: "22em"}}>
                รายงานค่ารักษาพยาบาลค้างชำระ
              </Button>
              <Button
                name="COVERAGE"
                onClick={props.onSelectMenu}
                style={{width: "22em"}}>
                รายงานการใช้สิทธิ
              </Button>
            </FormGroup>
            <FormGroup
              className="ui form"
              style={{padding: "1em 0em 1em 1em", gap: "5em"}}>
              <Button
                name="EXPENSE_EXCEPTION"
                onClick={props.onSelectMenu}
                style={{width: "22em"}}>
                รายงานค่าใช้จ่ายที่ยกเว้นค่ารักษาพยาบาล
              </Button>
            </FormGroup>
          </Form>
        </div>
      </div>
    )
}


export default CardFinancialReportUX

export const screenPropsDefault = {}

/* Date Time : Mon Apr 29 2024 13:42:30 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{position: \"relative\", padding: \"1em\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 2,
      "name": "Form",
      "parent": 1,
      "props": {
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "FormGroup",
      "parent": 2,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"1em 0em 1em 1em\", gap: \"5em\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "FormGroup",
      "parent": 2,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"1em 0em 1em 1em\", gap: \"5em\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "FormGroup",
      "parent": 2,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"1em 0em 1em 1em\", gap: \"5em\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormGroup",
      "parent": 2,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"1em 0em 1em 1em\", gap: \"5em\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "Button",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "รายงานใบนำส่งเงิน"
        },
        "name": {
          "type": "value",
          "value": "REMITTANCE"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSelectMenu"
        },
        "style": {
          "type": "code",
          "value": "{width: \"22em\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "Button",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "รายงานรายการลูกหนี้คงค้าง"
        },
        "name": {
          "type": "value",
          "value": "OUTSTANDING_DEBTOR"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSelectMenu"
        },
        "style": {
          "type": "code",
          "value": "{width: \"22em\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "Button",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "รายงานค่ารักษาพยาบาลแยกสิทธิ"
        },
        "name": {
          "type": "value",
          "value": "SEPARATE_COVERAGE"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSelectMenu"
        },
        "style": {
          "type": "code",
          "value": "{width: \"22em\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "Button",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "รายงานการชำระหนี้"
        },
        "name": {
          "type": "value",
          "value": "REPAY_DEBT"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSelectMenu"
        },
        "style": {
          "type": "code",
          "value": "{width: \"22em\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "Button",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "รายงานค่ารักษาพยาบาลค้างชำระ"
        },
        "name": {
          "type": "value",
          "value": "OVERDUE"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSelectMenu"
        },
        "style": {
          "type": "code",
          "value": "{width: \"22em\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "Button",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "รายงานการใช้สิทธิ"
        },
        "name": {
          "type": "value",
          "value": "COVERAGE"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSelectMenu"
        },
        "style": {
          "type": "code",
          "value": "{width: \"22em\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "Button",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "รายงานค่าใช้จ่ายที่ยกเว้นค่ารักษาพยาบาล"
        },
        "name": {
          "type": "value",
          "value": "EXPENSE_EXCEPTION"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSelectMenu"
        },
        "style": {
          "type": "code",
          "value": "{width: \"22em\"}"
        }
      },
      "seq": 13,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardFinancialReportUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
