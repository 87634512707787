import moment from "moment"
import { formatDate } from "react-lib/utils/dateUtils"
import CompanyLogoForm from "react-lib/apps/HISV3/common/CompanyLogoForm";
import CONFIG from "config/config";

const FORM_NAME = "FormAppointmentSummary";

const FormAppointmentSummary = async (props: any) => {
  const companyLogoForm = await CompanyLogoForm({
    type: 2,
    font: "THSarabunNew",
    height: 26.93,
    form: FORM_NAME,
  });
  const { font, images } = companyLogoForm;

  return {
    pageSize: "A4",
    defaultStyle: {
      font: "THSarabunNew",
      lineHeight: 1,
      fontSize: 14,
    },
    pageMargins: [20, props.data?.provider_name?.trim() ? 135 : 110, 20, 35],
    styles: {
      facultyHeader: {
        fontSize: 19,
        bold: true,
      },
      divisionHeader: {
        fontSize: 18,
      },
      tableHeader: {
        fontSize: 12,
        alignment: "center"
      },
      fieldKey: {
        bold: true,
        fontSize: 13,
      },
      fieldValue: {
        fontSize: 12,
        alignment: "center"
      },
    },
    header: {
      margin: [20, 10, 20, 10],
      stack: [
        {
          columns: [
            !CONFIG.HIDE_COMPANY_LOGO_PRINT ? { margin: [0, 0, 0, 0], width: 80, image: "logo", alignment: "left" } : null,
            {
              margin: [-15, 10, 0, 0],
              width: "*",
              stack: [
                !CONFIG.HIDE_COMPANY_LOGO_PRINT ? {
                  text: "CHULALONGKORN UNIVERSITY, FACULTY OF DENTISTRY",
                  style: "facultyHeader",
                  alignment: "center",
                } : null,
                { text: props.data?.division_name || "", style: "divisionHeader", alignment: "center" },
                ...(props.data?.provider_name?.trim() ? [
                  {
                    alignment: "center",
                    text: [
                      { text: "ทันตแพทย์/นิสิต : ", style: "divisionHeader" },
                      { text: props.data?.provider_name, style: "divisionHeader" }
                    ]
                  },
                ] : []),
                {
                  alignment: "center",
                  text: [
                    { text: "รายชื่อผู้ป่วยนัดหมายช่วงวันที่ ", style: "divisionHeader" },
                    { text: props.data?.date_range || "", style: "divisionHeader" }
                  ]
                },
              ],
            },
          ],
        },
      ],
    },
    content: [
      {
        table: {
          headerRows: 1,
          widths: ["4.5%", "8%", "11%", "12%", "9%", "8.5%","8.5%","8.5%", "9%", "12%", "9%"],
          body: [
            [
              { text: "ลำดับ", style: "tableHeader" },
              { text: "HN", style: "tableHeader" },
              { text: "ชื่อ-สกุล", style: "tableHeader" },
              { text: "เบอร์โทร", style: "tableHeader" },
              { text: "ที่อยู่ติดต่อได้", style: "tableHeader" },
              { text: "คลินิก", style: "tableHeader" },
              { text: "เก้าอี้", style: "tableHeader" },
              { text: "zone", style: "tableHeader" },
              { text: "แพทย์/นิสิต", style: "tableHeader" },
              { text: "วันเวลานัดหมาย", style: "tableHeader" },
              { text: "รายละเอียด", style: "tableHeader" },
            ],
          ].concat((props.data?.orders || [])?.map((row: any, index: number) => ([
            { text: (index + 1).toString(), style: "fieldValue" },
            { text: row.patient_hn || "", style: "fieldValue" },
            { text: row.patient || "", style: "fieldValue" },
            { text: row.patient_tel ? row.patient_tel.replace(/(^\d{3})/g, "$1-") : "", style: "fieldValue" },
            { text: row.address || "", style: "fieldValue" },
            { text: row.division_name || "", style: "fieldValue" },
            { text: row.chair || "", style: "fieldValue" },
            { text: row.zone || "", style: "fieldValue" },
            { text: row.provider_name ? row.provider_name : "ไม่ระบุ", style: "fieldValue" },
            { text: row.estimated_at || "", style: "fieldValue" },
            { text: row.order_note || "", style: "fieldValue" }
          ])))
        },
      },
    ],
    footer: (currentPage: number, pageCount: number) => ({
      margin: [20, 0, 20, 0],
      columns: [
        { width: "*", text: `วันเวลาที่พิมพ์ : ${formatDate(moment())} [${moment().format("HH:mm")}]` },
        { text: `หน้าที่ ${currentPage.toString()} / ${pageCount}`, alignment: "right", }
      ]
    }),
    images: {
      ...images,
    },
  }
}

export default FormAppointmentSummary;