const AddTableLivingWillTH = {
  unbreakable: true,
  table: {
    widths: ["85%", "15%"],
    body: [
      [
        {
          text: `บริการสาธารณสุขที่ข้าพเจ้าขอปฏิเสธ`,
          fontSize: 15,
          bold: true,
          alignment: `center`,
        },
        {
          text: `ลงนามผู้ป่วย\n(แต่ละรายการ)`,
          fontSize: 15,
          bold: true,
          alignment: `center`,
        },
      ],
      [
        {
          text: `กระบวนการฟื้นคืนชีพเมื่อหัวใจหยุดเต้น`,
          fontSize: 15
        },
        {},
      ],
      [
        {
          text: `การใส่ท่อช่วยหายใจ`,
          fontSize: 15
        },
        {},
      ],
      [
        {
          text: `การเจาะคอ`,
          fontSize: 15
        },
        {},
      ],
      [
        {
          text: `การใช้เครื่องช่วยหายใจ`,
          fontSize: 15
        },
        {},
      ],
      [
        {
          text: `การให้สารอาหารและสารน้ำทางหลอดเลือดดำ`,
          fontSize: 15
        },
        {},
      ],
      [
        {
          text: `การกระตุ้นระบบไหลเวียนด้วยยาและเครื่องมือแพทย์`,
          fontSize: 15
        },
        {},
      ],
      [
        {
          text: `การเข้ารับการรักษาในแผนกผู้ป่วยวิกฤต`,
          fontSize: 15
        },
        {},
      ],
      [
        {
          text: `การฟอกเลือดเมื่อเกิดภาวะไตวาย`,
          fontSize: 15
        },
        {},
      ],
      [
        {
          text: `อื่น ๆ ระบุ ...........................................................................................................................................................\n.............................................................................................................................................................................`,
          fontSize: 15,
        },
        {},
      ],
    ]
  }
}

export default AddTableLivingWillTH;