import { TDocumentDefinitions } from "pdfmake/interfaces";
import moment from "moment";

import { HeaderLogoWithAddress } from "react-lib/apps/HISV3/common/HeaderPdfFormTemplate";
import { splitStringNewLine } from "react-lib/apps/HISV3/common/CommonInterface";

import { formatDatetime } from "react-lib/utils/dateUtils";

type FormPackageInformationProps = {
  currentPage: number;
  items: FlatItemType[];
  package_discount: string;
  package_name: string;
  package_name_en: string;
  package_price: string;
  pageCount: number;
  patient_hn: string;
  patient_name: string;
  patient_name_en: string;
  print_date: string;
  total_item_price: string;
};

type ItemType = {
  name: string;
  price: string;
  quantity: number;
  seq: number;
  unit_price: string;
};

type FlatItemType = Partial<ItemType> & {
  header?: boolean;
};

const FONT_SIZE = 9.5;

const FORM_NAME = "FormPackageInformation";

const FormPackageInformation = async (
  props: FormPackageInformationProps
): Promise<TDocumentDefinitions> => {
  const packageFullName = [
    props.package_name,
    props.package_name_en ? ` (${props.package_name_en})` : "",
  ].join(" ");

  const englishNameSuffix = props.patient_name_en ? ` (${props.patient_name_en})` : "";

  const patientName = props.patient_name
    ? `${props.patient_name}${englishNameSuffix}`
    : props.patient_name_en || "";

  const packageNameLines = splitStringNewLine(packageFullName, { fontSize: FONT_SIZE, width: 420 });
  const extraLines = packageNameLines.length - 1;

  const headerForm = await HeaderLogoWithAddress({
    font: "KanitLM",
    form: FORM_NAME,
    headerMargins: [10, 10, 10, 0],
    logoHeight: 50,
    pageMargins: [15, 182.5 + (FONT_SIZE + 4) * extraLines, 0, 30],
    styles: {
      fieldHeader: { alignment: "center", bold: true, fontSize: 11, marginTop: 5 },
      fieldKey: { bold: true },
      fontEN: { font: "PoppinsLM", fontSize: 9.25 },
      tableHeader: { bold: true },
    },
    titleContent: [
      {
        alignment: "right",
        relativePosition: { x: -5, y: -4 },
        text: ` หน้า ${props.currentPage} / ${props.pageCount}`,
      },
      {
        style: "fieldHeader",
        text: [
          { text: "ใบแสดงรายละเอียดข้อมูลแพ็กเกจ" },
          { fontSize: 10.25, style: "fontEN", text: " (Package information Details)" },
        ],
      },
      {
        margin: [5, 10, 5, 0],
        table: {
          widths: ["auto", "*"],
          body: [
            [
              {
                noWrap: true,
                style: "fieldKey",
                text: [{ text: "ชื่อ - นามสกุล" }, { style: "fontEN", text: " / Patient name" }],
              },
              {
                columns: [
                  {
                    preserveLeadingSpaces: true,
                    text: `:  ${patientName}`,
                    width: "*",
                  },
                  {
                    alignment: "right",
                    marginRight: 12,
                    text: [
                      { bold: true, style: "fontEN", text: "HN: " },
                      { text: ` ${props.patient_hn}` },
                    ],
                    width: "auto",
                  },
                ],
              },
            ],
            [
              {
                style: "fieldKey",
                text: [{ text: "ชื่อแพ็กเกจ" }, { style: "fontEN", text: " / Package Name" }],
              },
              {
                columns: [
                  { text: ": ", width: 6 },
                  {
                    stack: packageNameLines.map((text) => ({ noWrap: true, text })),
                    width: "*",
                  },
                ],
              },
            ],
            [
              {
                style: "fieldKey",
                text: [{ text: "ราคา" }, { style: "fontEN", text: " / Package pricing" }],
              },
              {
                paddingBottom: 10,
                preserveLeadingSpaces: true,
                text: `:  ${props.package_price} บาท`,
              },
            ],
          ],
        },
        layout: {
          hLineColor: () => "#606060",
          hLineWidth: (index) => ([0, 3].includes(index) ? 0.25 : 0),
          paddingBottom: (index) => (index === 2 ? 5 : 0),
          paddingLeft: (index) => (index === 0 ? 12 : 4),
          paddingRight: (index) => (index === 2 ? 12 : 0),
          paddingTop: () => 5,
          vLineWidth: (index) => ([0, 2].includes(index) ? 0.25 : 0),
        },
      },
    ],
  });

  const { font, images, styles } = headerForm;

  const isLastPage = props.currentPage === props.pageCount;
  const maxRows = isLastPage ? 28 - extraLines : 32 - extraLines;

  const formattedTables = [
    ...props.items,
    ...Array.from(
      { length: props.items.length < maxRows ? maxRows - props.items.length : 0 },
      () => ({})
    ),
  ] as FlatItemType[];

  console.log("FormPackageInformation:", props);

  return {
    defaultStyle: {
      font,
      fontSize: FONT_SIZE,
      lineHeight: 1,
    },
    pageSize: "A4",
    ...styles,
    ...headerForm,
    images: {
      ...images,
    },
    content: [
      {
        margin: [0, 0, 15, 0],
        table: {
          headerRows: 1,
          widths: ["5%", "*", "10.5%", "22%", "15.5%"],
          body: [
            [
              { style: "tableHeader", text: "No." },
              {
                style: "tableHeader",
                text: [{ text: "รายการตรวจ" }, { style: "fontEN", text: "/Item List" }],
              },
              {
                style: "tableHeader",
                text: [{ text: "จำนวน" }, { style: "fontEN", text: "/QTY" }],
              },
              {
                style: "tableHeader",
                text: [{ text: "ราคาต่อหน่วย" }, { style: "fontEN", text: "/Price Unit" }],
              },
              {
                style: "tableHeader",
                text: [{ text: "ราคารวม" }, { style: "fontEN", text: "/Amount" }],
              },
            ],
            ...formattedTables.map((item) => [
              item.header
                ? { bold: true, noWrap: true, text: item.name }
                : { text: item.seq ? `${item.seq}.` : " " },
              {
                text: item.header ? "" : item.name,
              },
              { alignment: "right", marginRight: 5, text: item.quantity },
              { alignment: "right", marginRight: 5, text: item.unit_price },
              { alignment: "right", marginRight: 5, text: item.price },
            ]),
          ],
        },
        layout: {
          hLineColor: () => "#606060",
          hLineWidth: (index) => ([0, 1, formattedTables.length + 1].includes(index) ? 0.25 : 0),
          paddingBottom: (index) => (index === 0 ? 5 : 2),
          paddingTop: (index) => (index === 0 ? 5 : 2),
          vLineWidth: (index) => (index === 1 ? 0 : 0.25),
        },
      },
      isLastPage
        ? {
            marginRight: 15,
            table: {
              widths: ["auto", "*"],
              body: [
                [
                  {
                    text: [{ text: "ยอดรวมการตรวจ" }],
                  },
                  {
                    alignment: "right",
                    marginRight: 10,
                    text: props.total_item_price,
                    width: "auto",
                  },
                ],
                [
                  {
                    text: [{ text: "ส่วนลดสำหรับแพ็กเกจ" }],
                  },
                  {
                    alignment: "right",
                    marginRight: 10,
                    text: props.package_discount,
                    width: "auto",
                  },
                ],
                [
                  {
                    text: [{ text: "ราคาแพ็กเกจ" }],
                  },
                  {
                    alignment: "right",
                    marginRight: 10,
                    text: props.package_price,
                    width: "auto",
                  },
                ],
              ],
            },
            layout: {
              hLineColor: () => "#606060",
              hLineWidth: (index) => ([3].includes(index) ? 0.25 : 0),
              paddingBottom: (index) => (index === 2 ? 10 : 0),
              paddingLeft: (index) => (index === 0 ? 32.5 : 4),
              paddingRight: (index) => (index === 2 ? 12 : 0),
              paddingTop: () => 5,
              vLineWidth: (index) => ([0, 2].includes(index) ? 0.25 : 0),
            },
          }
        : { text: "" },
    ],
    footer: () => ({
      alignment: "right",
      marginRight: 15,
      text: `วันที่พิมพ์: ${formatDatetime(moment(), true)}`,
    }),
  };
};

export default FormPackageInformation;
