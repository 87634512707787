import axios from 'axios'
import { to } from '../../../utils'
import BaseService from  '../../services/BaseService'
import config from '../../../../config/config'
import { Response, Error } from '../common'
const HOST = `${config.API_HOST}`

export interface MedicalRecordSerializer {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    encounter?: any,
    doctor?: any,
    active?: any,
    checkout_time?: any,
    checkout_cause?: any,
    doctor_name?: any,
    doctor_user_id?: any,
    checkout_cause_label?: any,
}


/* ['generics.ListCreateAPIView'] */
/* params:  */
/* data:  */
export const EncounterMedicalRecordList : 
{
  list:     ({pk, params, apiToken, extra}:
             { pk: any, 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
  create:   ({pk, params, data, apiToken, extra}:
             { pk: any, 
                params?: any,
                data?: MedicalRecordSerializer,
                apiToken?: any,
                extra?: any
            }) => any,
} = 
{
  list: async ({pk, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/core/encounter/(?P<pk>\\d+)/medical-record/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  create: async ({pk, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.post(`${HOST}/apis/core/encounter/(?P<pk>\\d+)/medical-record/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

/* ['generics.ListAPIView'] */
/* params: 'patient', 'encounter_type', 'active_only', 'latest_only' */
/* data:  */
export const MedicalRecordList : 
{
  list:     ({params, apiToken, extra}:
             { 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  list: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/core/medical_record/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

export interface DoctorOrderInMedicalRecordSerializer {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    encounter?: any,
    doctor?: any,
    active?: any,
    checkout_time?: any,
    checkout_cause?: any,
    progression_cycles?: any,
    doctor_orders?: any,
}


/* ['generics.RetrieveAPIView'] */
/* params:  */
/* data:  */
export const DoctorOrderHierarchy : 
{
  retrieve: ({pk, params, apiToken, extra}:
             { pk: any, 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  retrieve: async ({pk, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/core/doctor-order-hierarchy/${pk}/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

export interface DPIMedicalRecordDischargeSerializer {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    encounter?: any,
    doctor?: any,
    active?: any,
    checkout_time?: any,
    checkout_cause?: any,
    user?: any,
    death_datetime?: any,
}


/* ['generics.RetrieveUpdateAPIView'] */
/* params:  */
/* data:  */
export const DPIMedicalRecordDischarge : 
{
  retrieve: ({pk, params, apiToken, extra}:
             { pk: any, 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
  update:   ({pk, params, data, apiToken, extra}:
             { pk: any, 
                params?: any,
                data?: DPIMedicalRecordDischargeSerializer,
                apiToken?: any,
                extra?: any,
            }) => any,
  patch:    ({pk, params, data, apiToken, extra}:
             { pk: any, 
                params?: any,
                data?: DPIMedicalRecordDischargeSerializer,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  retrieve: async ({pk, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/DPI/medical-record/${pk}/discharge/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  update: async ({pk, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.put(`${HOST}/apis/DPI/medical-record/${pk}/discharge/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  patch: async ({pk, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.patch(`${HOST}/apis/DPI/medical-record/${pk}/discharge/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

export interface DPIAdmissionNotePrintSerializer {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    encounter?: any,
    doctor?: any,
    active?: any,
    checkout_time?: any,
    checkout_cause?: any,
    information?: any,
    info_reliability?: any,
    chief_complaint?: any,
    present_illness?: any,
    pass_illness?: any,
    personal_history?: any,
    immunization?: any,
    allergy_drug_reaction?: any,
    current_medications?: any,
    social_condition?: any,
    underlying_disease_checked?: any,
    underlying_disease?: any,
    surgery_checked?: any,
    surgery?: any,
    current_medication_checked?: any,
    past_other_checked?: any,
    past_other?: any,
    smoking_checked?: any,
    smoking?: any,
    alcohol_checked?: any,
    alcohol?: any,
    herbal_checked?: any,
    herbal?: any,
    allergies_checked?: any,
    allergies?: any,
    personal_other_checked?: any,
    personal_other?: any,
    immunization_checked?: any,
    family_history_checked?: any,
    family_history?: any,
    social_and_economic_issue_checked?: any,
    social_and_economic_issue?: any,
    psychological_issue_checked?: any,
    psychological_issue?: any,
    sensitive_note_checked?: any,
    sensitive_note?: any,
    systemic_review_general_weak?: any,
    systemic_review_general_weight?: any,
    systemic_review_general_fever?: any,
    systemic_review_general_other?: any,
    systemic_review_general_other_detail?: any,
    systemic_review_skin_color?: any,
    systemic_review_skin_allergy?: any,
    systemic_review_skin_scratch?: any,
    systemic_review_skin_node?: any,
    systemic_review_skin_hair?: any,
    systemic_review_skin_other?: any,
    systemic_review_skin_other_detail?: any,
    systemic_review_eye_normal?: any,
    systemic_review_eye_red?: any,
    systemic_review_eye_hurt?: any,
    systemic_review_eye_other?: any,
    systemic_review_eye_other_detail?: any,
    systemic_review_ear_sound?: any,
    systemic_review_ear_other?: any,
    systemic_review_ear_other_detail?: any,
    systemic_review_nose_nasal?: any,
    systemic_review_nose_snot?: any,
    systemic_review_nose_bleed?: any,
    systemic_review_nose_hoarseness?: any,
    systemic_review_nose_other?: any,
    systemic_review_nose_other_detail?: any,
    systemic_review_breast_node?: any,
    systemic_review_breast_hurt?: any,
    systemic_review_breast_discharge?: any,
    systemic_review_breast_other?: any,
    systemic_review_breast_other_detail?: any,
    systemic_review_res_cough?: any,
    systemic_review_res_cough_blood?: any,
    systemic_review_res_gasp?: any,
    systemic_review_res_infection?: any,
    systemic_review_res_wheezing?: any,
    systemic_review_res_other?: any,
    systemic_review_res_other_detail?: any,
    systemic_review_car_chest?: any,
    systemic_review_car_tired?: any,
    systemic_review_car_sleep?: any,
    systemic_review_car_heart?: any,
    systemic_review_car_foot?: any,
    systemic_review_car_blood?: any,
    systemic_review_car_beriberi?: any,
    systemic_review_car_other?: any,
    systemic_review_car_other_detail?: any,
    systemic_review_gas_vomit?: any,
    systemic_review_gas_stomach?: any,
    systemic_review_gas_black?: any,
    systemic_review_gas_swallow?: any,
    systemic_review_gas_yellow?: any,
    systemic_review_gas_other?: any,
    systemic_review_gas_other_detail?: any,
    systemic_review_urinary_hard?: any,
    systemic_review_urinary_cant?: any,
    systemic_review_urinary_frequent?: any,
    systemic_review_urinary_night?: any,
    systemic_review_urinary_stone?: any,
    systemic_review_urinary_blood?: any,
    systemic_review_urinary_other?: any,
    systemic_review_urinary_other_detail?: any,
    systemic_review_gen_weep?: any,
    systemic_review_gen_sex_m?: any,
    systemic_review_gen_pain?: any,
    systemic_review_gen_leucorrhoea?: any,
    systemic_review_gen_sex_f?: any,
    systemic_review_gen_other?: any,
    systemic_review_endo_hot?: any,
    systemic_review_endo_cold?: any,
    systemic_review_endo_water?: any,
    systemic_review_endo_eat?: any,
    systemic_review_endo_thyroid?: any,
    systemic_review_endo_other?: any,
    systemic_review_endo_other_detail?: any,
    systemic_review_hemo_pale?: any,
    systemic_review_hemo_bleeding?: any,
    systemic_review_hemo_blood?: any,
    systemic_review_hemo_lymph_node?: any,
    systemic_review_hemo_other?: any,
    systemic_review_hemo_other_detail?: any,
    systemic_review_mus_pain_joint?: any,
    systemic_review_mus_allergy?: any,
    systemic_review_mus_pain_muscle?: any,
    systemic_review_mus_pain_other?: any,
    systemic_review_mus_pain_other_detail?: any,
    systemic_review_neuro_head?: any,
    systemic_review_neuro_whip?: any,
    systemic_review_neuro_faint?: any,
    systemic_review_neuro_dizzy?: any,
    systemic_review_neuro_double_vision?: any,
    systemic_review_neuro_weak?: any,
    systemic_review_neuro_beriberi?: any,
    systemic_review_neuro_other?: any,
    systemic_review_neuro_other_detail?: any,
    systemic_review_sleep?: any,
    systemic_review_edgy?: any,
    systemic_review_depress?: any,
    systemic_review_nightmare?: any,
    systemic_review_other?: any,
    systemic_review_other_detail?: any,
    investigation_plan?: any,
    treatment_plan?: any,
    patient_education?: any,
    problem_list?: any,
    primary_provisional_diagnosis?: any,
    secondary_provisional_diagnosis?: any,
    version?: any,
}

/*----------------------------------------------------------------------------------------------------*/

export interface DPIDischargeSummaryPrintSerializer {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    encounter?: any,
    doctor?: any,
    active?: any,
    checkout_time?: any,
    checkout_cause?: any,
    admission_date?: any,
    discharge_date?: any,
    length_of_stay?: any,
    reason_for_admission?: any,
    provisional_diagnosis?: any,
    final_diagnosis_primary?: any,
    final_diagnosis_secondary?: any,
    brief_history_and_physical_finding?: any,
    hospital_course?: any,
    procedures_primary?: any,
    procedures_secondary?: any,
    discharge_status?: any,
    discharge_type?: any,
    follow_up_instructions?: any,
    follow_up_date?: any,
    follow_up_time?: any,
    follow_up_division?: any,
    follow_up_division_name?: any,
    follow_up_doctor_name?: any,
    home_medication?: any,
}

/*----------------------------------------------------------------------------------------------------*/

export interface DPOMedicalRecordSerializer {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    encounter?: any,
    doctor?: any,
    active?: any,
    checkout_time?: any,
    checkout_cause?: any,
    pk?: any,
    checkout_cause_name?: any,
    deathdate?: any,
    has_doctor_fee?: any,
    encounter__division?: any,
    encounter__type?: any,
    profile_image?: any,
    patient_name?: any,
    doctor_name?: any,
}


/* ['generics.ListCreateAPIView'] */
/* params: encounter, doctor, 'current_doctor' */
/* data:  */
export const DPOMedicalRecordList : 
{
  list:     ({params, apiToken, extra}:
             { 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
  create:   ({params, data, apiToken, extra}:
             { 
                params?: any,
                data?: DPOMedicalRecordSerializer,
                apiToken?: any,
                extra?: any
            }) => any,
} = 
{
  list: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/DPO/medical_record/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  create: async ({params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.post(`${HOST}/apis/DPO/medical_record/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

/* ['generics.RetrieveUpdateDestroyAPIView'] */
/* params:  */
/* data:  */
export const DPOMedicalRecordDetail : 
{
  retrieve: ({pk, params, apiToken, extra}:
             { pk: any, 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
  update:   ({pk, params, data, apiToken, extra}:
             { pk: any, 
                params?: any,
                data?: DPOMedicalRecordSerializer,
                apiToken?: any,
                extra?: any,
            }) => any,
  patch:    ({pk, params, data, apiToken, extra}:
             { pk: any, 
                params?: any,
                data?: DPOMedicalRecordSerializer,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  retrieve: async ({pk, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/DPO/medical_record/${pk}/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  update: async ({pk, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.put(`${HOST}/apis/DPO/medical_record/${pk}/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  patch: async ({pk, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.patch(`${HOST}/apis/DPO/medical_record/${pk}/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

export interface DPODiagnosisMedicalRecord {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    encounter?: any,
    doctor?: any,
    active?: any,
    checkout_time?: any,
    checkout_cause?: any,
    principal_diagnosis?: any,
    secondary_diagnosis?: any,
    pre_principal_diagnosis?: any,
    pre_secondary_diagnosis?: any,
}


/* ['generics.RetrieveUpdateDestroyAPIView'] */
/* params: 'source' */
/* data:  */
export const DPODiagnosisMedicalRecordDetail : 
{
  retrieve: ({pk, params, apiToken, extra}:
             { pk: any, 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
  update:   ({pk, params, data, apiToken, extra}:
             { pk: any, 
                params?: any,
                data?: DPODiagnosisMedicalRecord,
                apiToken?: any,
                extra?: any,
            }) => any,
  patch:    ({pk, params, data, apiToken, extra}:
             { pk: any, 
                params?: any,
                data?: DPODiagnosisMedicalRecord,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  retrieve: async ({pk, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/apis/DPO/medical_record/${pk}/diagnosis/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  update: async ({pk, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.put(`${HOST}/apis/DPO/medical_record/${pk}/diagnosis/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  patch: async ({pk, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.patch(`${HOST}/apis/DPO/medical_record/${pk}/diagnosis/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

export interface DPODoctorDischargePatientSerializer {
    id?: any,
    edit_user?: any,
    created?: any,
    edited?: any,
    encounter?: any,
    doctor?: any,
    active?: any,
    checkout_time?: any,
    checkout_cause?: any,
    death_datetime?: any,
}


/* ['generics.UpdateAPIView'] */
/* params:  */
/* data:  */
export const DPODoctorDischargePatient : 
{
  update:   ({pk, params, data, apiToken, extra}:
             { pk: any, 
                params?: any,
                data?: DPODoctorDischargePatientSerializer,
                apiToken?: any,
                extra?: any,
            }) => any,
  patch:    ({pk, params, data, apiToken, extra}:
             { pk: any, 
                params?: any,
                data?: DPODoctorDischargePatientSerializer,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  update: async ({pk, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.put(`${HOST}/apis/DPO/doctor-discharge-patient/${pk}/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  patch: async ({pk, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.patch(`${HOST}/apis/DPO/doctor-discharge-patient/${pk}/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

