import React, { useEffect, useState } from "react";
import { Loader, Menu } from "semantic-ui-react";
// UI Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
// UI
import CardSegmentSidebarUX from "./CardSegmentSidebarUX";

import { CARD_KEY } from "./CardSegment";
import { useIntl } from "react-intl";

type CardSegmentSidebarProps = {
  // function
  setProp: any;

  // CommonInterface
  buttonLoadCheck?: any;

  // seq
  runSequence?: any;
  SegmentSequence?: any;
};

const CardSegmentSidebar = (props: CardSegmentSidebarProps) => {
  const intl = useIntl();
  const handleNewSegment = () => {
    props.runSequence({ sequence: "Segment", action: "CLEAR" });
  };

  const handleChangeFilterSegment = (_event: any, data: any) => {
    props.setProp(`SegmentSequence.filterSegment.${data.name}`, data.value);
  };

  const handleKeyDownFilterSegment = (event: any) => {
    if (event.keyCode === 13) {
      props.runSequence({
        sequence: "Segment",
        action: "SEARCH_SEGMENT",
        cardKey: CARD_KEY,
      });
    }
  };

  const handleSelectedSegment = (item: any) => {
    props.setProp("SegmentSequence.selectedSegment", item);
  };

  return (
    <CardSegmentSidebarUX
      // function
      onChangeFilterSegment={handleChangeFilterSegment}
      onKeyDownFilterSegment={handleKeyDownFilterSegment}
      // data
      filterSegment={props.SegmentSequence?.filterSegment}
      // component
      buttonNew={
        <ButtonLoadCheck
          // function
          setProp={props.setProp}
          onClick={handleNewSegment}
          // data
          paramKey={`${CARD_KEY}_SEARCH_SEGMENT`}
          buttonLoadCheck={
            props.buttonLoadCheck?.[`${CARD_KEY}_SEARCH_SEGMENT`]
          }
          // config
          color="blue"
          size="small"
          title="NEW"
        />
      }
      segmentList={
        <Menu vertical fluid>
          {props.SegmentSequence?.segmentList?.items?.map(
            (item: any, index: number) => {
              return (
                <Menu.Item
                  key={index}
                  disabled={props.SegmentSequence?.loadingSegmentList}
                  style={{
                    display: "flex",
                    background:
                      props.SegmentSequence?.selectedSegment?.id === item?.id
                        ? "#A1DDE5"
                        : "rgba(0, 0, 0, 0.03)",
                  }}
                  active={
                    props.SegmentSequence?.selectedSegment?.id === item?.id
                  }
                  onClick={() => handleSelectedSegment(item)}
                >
                  {item.name}
                  {props.SegmentSequence?.selectedSegment?.id === item?.id &&
                    props.SegmentSequence?.loadingSegmentList && (
                      <div style={{ marginLeft: "auto" }}>
                        <Loader active inline size="small" />
                      </div>
                    )}
                </Menu.Item>
              );
            }
          )}
        </Menu>
      }
    />
  );
};

export default React.memo(CardSegmentSidebar);
