import WasmController from "react-lib/frameworks/WasmController";

// APIs
// INF
import BillTransactionIPDList from "issara-sdk/apis/BillTransactionIPDList_apps_INF";
import BillTransactionIPDSummary from "issara-sdk/apis/BillTransactionIPDSummary_apps_INF";
import GenerateSentClaimIPDFileFromARList from "issara-sdk/apis/GenerateSentClaimIPDFileFromARList_apps_INF";

// Serializer
import BillTransactionListSerializerI from "issara-sdk/types/BillTransactionListSerializer_apps_INF";
import SentClaimTransactionIPDSerializerI from "issara-sdk/types/SentClaimTransactionIPDSerializer_apps_INF";

// Interface
import { State as MainState } from "../../../../../HIS/MainHISInterface";
import { SetErrorMessage, SetProperty } from "../../common/CommonInterface";

// Utils
import { downloadFile } from "react-lib/utils/utils";

export type State = Partial<{
  // sequence
  AIPNSendClaimSequence: Partial<{
    sequenceIndex?: "Action" | "START" | null;
    billTransactionList: BillTransactionListSerializer[];
    billTransactionSummary: {
      total_other_pay_price: number;
      total_paid_price: number;
      total_rows: number;
      total_sent_claim_price: number;
    } | null;
    filter: Partial<FilterType>;
    sentClaimTransactionList: SentClaimTransactionIPDSerializer[];
    sentClaimTransactionSummary: SentClaimTransactionSummary;
  }> | null;
}>;

type PickedState = Partial<
  Pick<MainState, "buttonLoadCheck" | "errorMessage" | "searchedItemListWithKey">
>;

export type PickedProps = Partial<Omit<PickedState, "">>;

export type FilterAllValues = FilterType[keyof FilterType];

export type FilterType = {
  arTransaction: Record<string, any> | null;
  encounterId: number | string;
  endDate?: string;
  isDate?: boolean;
  isEncounter?: boolean;
  isPatient?: boolean;
  isStatus?: boolean;
  patientId: number;
  startDate?: string;
  status: string;
};

type BillTransactionListSerializer = {
  invoice_datetime?: string;
  total_amount_price: string;
  total_paid_price: string;
  total_send_claim_price: string;
} & BillTransactionListSerializerI;

export type SentClaimTransactionIPDSerializer = SentClaimTransactionIPDSerializerI;

export type SentClaimTransactionSummary = Partial<{
  total_other_pay_price: number;
  total_paid_price: number;
  total_rows: number;
  total_sent_claim_price: number;
}>;

export type MasterOptionsType = Record<(typeof Masters)[number][0], OptionType[]>;

export type OptionType = {
  key: number | string;
  text: string;
  value: number | string;
};

// Sequence
type SeqState = {
  sequence: "AIPNSendClaim";
  clear?: boolean;
  restart?: boolean;
};

// Handle Action
type ActionType =
  // Action
  | {
      action: "SEND_CLAIM_SUMMARY";
      arTransactionId: number;
      card: string;
      sentClaimTransactionId: number;
    }
  | { action: "DOWNLOAD_ZIP_FILE"; id: number; card: string }
  | { action: "INIT" }
  | { action: "SEARCH" };

type SeqAct = ActionType & SeqState;
type SeqType<K> = K extends { action: string } ? Extract<SeqAct, K> : SeqState;

export type RunSequence = <K extends keyof SeqAct>(params: SeqType<Pick<SeqAct, K>>) => void;

export type SetProp = SetProperty<PickedState & State>;

type CustomExtract<T, U> = T extends object ? (U extends Partial<T> ? T : never) : never;

type Params<A extends ActionType["action"]> = CustomExtract<ActionType, { action: A }>;

export const StateInitial: State = {
  // sequence
  AIPNSendClaimSequence: {
    sequenceIndex: null,
  },
};

export type Event =
  | { message: "GetMasterData"; params: { masters: typeof Masters } }
  | { message: "RunSequence"; params: { sequence: string; [key: string]: any } };

export type Data = {
  device?: number;
  division?: number;
};

export const DataInitial = {};

const Masters = [
  ["sentClaimChoice", {}],
  ["diagnosisTypeValue", {}],
] as const;

export const CARD_AIPN_SENT_CLAIM = "CardAIPNSentClaim";

type Optional<T> = {
  [K in keyof T]: `${typeof CARD_AIPN_SENT_CLAIM}_${T[K] & string}`;
};

export const ACTIONS = {
  INIT: "INIT",
  DOWNLOAD_ZIP_FILE: "DOWNLOAD_ZIP_FILE",
  SEARCH: "SEARCH",
  SEND_CLAIM_SUMMARY: "SEND_CLAIM_SUMMARY",
} as const;

export const BTN_ACTS = Object.fromEntries(
  Object.keys(ACTIONS).map((key) => [key, `${CARD_AIPN_SENT_CLAIM}_${key}`])
) as Optional<typeof ACTIONS>;

type Handler<P = any, R = any> = (
  controller: WasmController<PickedState & State, Event, Data>,
  params: P
) => R;

/* ------------------------------------------------------ */

/*                          START                         */

/* ------------------------------------------------------ */
export const GetMaster: Handler<SeqState> = async (controller) => {
  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: Masters,
    },
  });

  const state = controller.getState();

  controller.setState({
    AIPNSendClaimSequence: { ...state.AIPNSendClaimSequence, sequenceIndex: "Action" },
  });
};

/* ------------------------------------------------------ */

/*                      Handle Action                     */

/* ------------------------------------------------------ */
export const Action: Handler<ActionType> = async (controller, params) => {
  const actionHandlers: Partial<{ [K in ActionType["action"]]: Handler<Params<K>> }> = {
    [ACTIONS.DOWNLOAD_ZIP_FILE]: HandleDownloadZipFile,
    [ACTIONS.SEARCH]: HandleSearch,
  };

  const { action } = params;

  return actionHandlers[action]?.(controller, params as Params<typeof params.action>);
};

/* ------------------------------------------------------ */

/*                       Handle AIPN                      */

/* ------------------------------------------------------ */
const HandleSearch: Handler<Params<"SEARCH">> = async (controller) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SEARCH]: "LOADING" },
  });

  const filter = state.AIPNSendClaimSequence?.filter || {};

  const formatParams = (isKey: keyof FilterType, value: FilterAllValues) =>
    filter[isKey] ? value : undefined;

  const urlParams = {
    ar_transaction: filter.arTransaction?.id,
    encounter: formatParams("isEncounter", filter.encounterId),
    end_date: formatParams("isDate", filter.endDate),
    start_date: formatParams("isDate", filter.startDate),
    status: formatParams("isStatus", filter.status),
  };

  const [[list], [summary]] = await Promise.all([
    BillTransactionIPDList.list({
      apiToken: controller.apiToken,
      params: urlParams,
    }),
    BillTransactionIPDSummary.list({
      apiToken: controller.apiToken,
      params: urlParams,
    }),
  ]);

  controller.setState({
    AIPNSendClaimSequence: {
      ...state.AIPNSendClaimSequence,
      billTransactionList: list?.items || [],
      billTransactionSummary: summary?.items || summary || {},
    },
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SEARCH]: "SUCCESS" },
  });
};

const HandleDownloadZipFile: Handler<Params<"DOWNLOAD_ZIP_FILE">> = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.DOWNLOAD_ZIP_FILE]: "LOADING" },
  });

  const [, error, net] = await GenerateSentClaimIPDFileFromARList.get({
    apiToken: controller.apiToken,
    ar_transaction_id: params.id,
    extra: { responseType: "blob" },
  });

  if (error) {
    SetErrorMessage(controller, { ...params, error });

    return;
  }

  downloadFile(net);

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.DOWNLOAD_ZIP_FILE]: "SUCCESS" },
  });
};
