import React, { useMemo } from "react";

import moment from "moment";

// UX
import ModStockAuditLogUX from "./ModStockAuditLogUX";
import {
  ProductStockSerializer,
  StockLogSerializer,
} from "./sequence/StockManagement";

// Utils
import { formatDate } from "react-lib/utils/dateUtils";
import { useIntl } from "react-intl";

// types
type ModStockAuditLogProps = {
  // data
  data: Partial<ProductStockSerializer>;
  auditLogList?: StockLogSerializer[];
  // callback
  onClose?: () => any;
};

const ModStockAuditLog = (props: ModStockAuditLogProps) => {
  // Ues memo
  const titleName = useMemo(() => {
    return `[${props.data?.product?.code || ""}]-${
      props.data?.product?.drug_name_from_format || props.data?.product?.name || ""
    }`;
  }, [props.data]);

  const auditLogItems = useMemo(() => {
    return (props.auditLogList || []).map((item) => ({
      ...item,
      datetime: item.datetime
        ? `${formatDate(moment(item.datetime))} [${moment(item.datetime).format(
            "HH:mm"
          )}]`
        : "",
      lot_no: item.lot?.mfc_no || "",
      expiry_date: item.lot?.exp_datetime
        ? formatDate(moment(item.lot.exp_datetime))
        : "",
      bin_location: props.data.bin_location,
      user_name: item.edit_user_name,
      note: item.reference_text,
    }));
  }, [props.auditLogList]);

  return (
    <ModStockAuditLogUX
      // data
      auditLogList={auditLogItems}
      name={titleName}
      storage={props.data.storage?.name}
      binLocation={props.data.bin_location}
      // callback
      onClose={props.onClose}
      languageUX={props.languageUX}
    />
  );
};

export default React.memo(ModStockAuditLog);
