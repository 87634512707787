import React, { MutableRefObject, useEffect, useRef, useState } from "react";

// ui ORM
import CardPerioperativeNursingRecordUX from "./CardPerioperativeNursingRecordUX";
import CardBasicInfo from "./CardBasicInfo";
import CardPostopeartiveNursingRecord from "./CardPostopeartiveNursingRecord";
import CardByEquipment from "./CardByEquipment";
import CardStepAH from "./CardStepAH";
import CardOtherForm from "./CardOtherForm";
import "./CardORHistory.scss";
import { useIntl } from "react-intl";

type CardPerioperativeNursingRecordProps = {
  // function
  onEvent: any;
  setProp: any;

  // CommonInterface
  buttonLoadCheck?: any;
  errorMessage?: any;
  successMessage?: any;

  // select OR
  selectedOrOrder?: any;

  // options
  masterOptions?: any;
  masterData?: any;

  // seq
  runSequence?: any;
  PerioperativeNursingSequence?: any;
  searchedItemListWithKey?: any;

  phvReadOnly?: boolean;
  DJANGO?: any;
};

const CardPerioperativeNursingRecordInitial: CardPerioperativeNursingRecordProps =
  {
    // funtion
    onEvent: () => null,
    setProp: () => null,

    // CommonInterface
    buttonLoadCheck: null,

    // select OR
    selectedOrOrder: {},

    // options
    masterOptions: {},

    // seq
    runSequence: null,
    PerioperativeNursingSequence: {},
  };

const CardPerioperativeNursingRecord: React.FC<
  CardPerioperativeNursingRecordProps
> = (props: any) => {
  const [containerName, setContainerName] = useState<string>("BasicInfo");
  const pnrRef = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    props.runSequence({ sequence: "PerioperativeNursing", restart: true });
  }, []);

  const handleSetContainerName = (_event: any, data: any) => {
    setContainerName(data.name);
  };

  useEffect(() => {
    if (pnrRef.current && props.phvReadOnly) {
      const children = Array.from(
        pnrRef.current.querySelectorAll(
          ".dropdown, .input, .search, input, textarea, .ui.small.button"
        )
      ) as HTMLElement[];

      console.log("children: ", children);
      children.forEach((e) => {
        e.setAttribute("tabindex", -1);
      });
    }
  }, [pnrRef?.current, props.phvReadOnly]);

  useEffect(() => {
    console.log("props.DJANGO?.user?.role_types", props.DJANGO?.user?.role_types)
    const isDoctor = props.DJANGO?.user?.role_types?.includes("DOCTOR");
    if (isDoctor) {
      const children = Array.from(
        pnrRef.current.querySelectorAll(
          ".dropdown, .input, .search, input, textarea, .ui.small.button"
        )
      ) as HTMLElement[];
      const childrenBtn = Array.from(
        pnrRef.current.querySelectorAll(
          ".ui.small.button"
        )
      ) as HTMLElement[];
      const childrenCheckBtn = Array.from(
        pnrRef.current.querySelectorAll(
          ".ui.checkbox"
        )
      ) as HTMLElement[];
      console.log("children: ", children);
      children.forEach((e) => {
        e.setAttribute("tabindex", "-1");
        e.classList.add("block-mouse");
      });
      childrenBtn.forEach((e) => {
        e.setAttribute("tabindex", "-1");
        e.setAttribute("disabled", "-1");
      });
      childrenCheckBtn.forEach((e) => {
        e.setAttribute("tabindex", "-1");
        e.setAttribute("disabled", "-1");
        e.classList.add("block-mouse");
      });
    }

  }, [containerName]);

  return (
    <div ref={pnrRef}>
      <div id="tab-button">
        <CardPerioperativeNursingRecordUX
          onSetContainerName={handleSetContainerName}
          containerName={containerName}
          languageUX={props.languageUX}
        />
      </div>

      {containerName === "BasicInfo" ? (
        <CardBasicInfo
          // function
          onEvent={props.onEvent}
          setProp={props.setProp}
          // CommonInterface
          buttonLoadCheck={props.buttonLoadCheck}
          // select OR
          selectedOrOrder={props.selectedOrOrder}
          // options
          masterOptions={props.masterOptions}
          // seq
          runSequence={props.runSequence}
          PerioperativeNursingSequence={props.PerioperativeNursingSequence}
          searchedItemListWithKey={props.searchedItemListWithKey}
          errorMessage={props.errorMessage}
          successMessage={props.successMessage}
          languageUX={props.languageUX}
        />
      ) : containerName === "StepA-H" ? (
        <CardStepAH
          onEvent={props.onEvent}
          setProp={props.setProp}
          buttonLoadCheck={props.buttonLoadCheck}
          selectedOrOrder={props.selectedOrOrder}
          masterOptions={props.masterOptions}
          runSequence={props.runSequence}
          PerioperativeNursingSequence={props.PerioperativeNursingSequence}
          languageUX={props.languageUX}
        />
      ) : containerName === "OtherForm" ? (
        <CardOtherForm
          onEvent={props.onEvent}
          setProp={props.setProp}
          buttonLoadCheck={props.buttonLoadCheck}
          selectedOrOrder={props.selectedOrOrder}
          masterOptions={props.masterOptions}
          runSequence={props.runSequence}
          PerioperativeNursingSequence={props.PerioperativeNursingSequence}
          languageUX={props.languageUX}
        />
      ) : containerName === "ByEquipment" ? (
        <CardByEquipment
          onEvent={props.onEvent}
          setProp={props.setProp}
          buttonLoadCheck={props.buttonLoadCheck}
          selectedOrOrder={props.selectedOrOrder}
          masterOptions={props.masterOptions}
          runSequence={props.runSequence}
          PerioperativeNursingSequence={props.PerioperativeNursingSequence}
          languageUX={props.languageUX}
        />
      ) : containerName === "PostopeartiveNursingRecord" ? (
        <CardPostopeartiveNursingRecord
          onEvent={props.onEvent}
          setProp={props.setProp}
          buttonLoadCheck={props.buttonLoadCheck}
          selectedOrOrder={props.selectedOrOrder}
          masterOptions={props.masterOptions}
          runSequence={props.runSequence}
          PerioperativeNursingSequence={props.PerioperativeNursingSequence}
          languageUX={props.languageUX}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

CardPerioperativeNursingRecord.defaultProps =
  CardPerioperativeNursingRecordInitial;

export default React.memo(CardPerioperativeNursingRecord);
