import React, { CSSProperties, useEffect, useMemo, useState } from "react";

import { Button, Checkbox, Divider, Icon, Modal } from "semantic-ui-react";

import { useIntl } from "react-intl";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import ModConfirmCNMI from "react-lib/apps/common/cnmi/ModConfirm";
import ModInfo from "react-lib/apps/common/ModInfo";
import SnackMessage from "react-lib/apps/common/SnackMessage";

// UX
import CardSettingDivisionUX from "./CardSettingDivisionUX";
import ModSelectDivision from "./ModSelectDivision";
import ReactTable, { CellInfo, RowInfo } from "react-table-6";

// Types
type CardSettingDivisionProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: () => any) => any;
  // data
  organizationFilter?: boolean;
  profileDivision?: {
    data: Record<string, any>[];
    raw: Record<string, any>[];
  };
  tableHeaders?: string;
  tableKeys?: string;
  settingsUser?: Record<string, any> | null;
  // CommonInterface
  buttonLoadCheck?: Record<string, any>;
  errorMessage?: Record<string, any>;
  // options
  divisionOptions?: Record<string, any>[];
};

// styles
const styles = {
  title: {
    fontSize: "1.2rem",
    fontWeight: "bold",
    marginTop: "5px",
  } as CSSProperties,
  header: {
    backgroundColor: "#C6EBF3",
    fontSize: "1.2rem",
    padding: "15px",
  } as CSSProperties,
};

// Const
const CARD_SETTING_DIVIDION = "CardSettingDivision";

const CardSettingDivision = (props: CardSettingDivisionProps) => {
  const intl = useIntl();

  const [openDivision, setOpenDivision] = useState<boolean>(false);
  const [openAddExtra, setOpenAddExtra] = useState<boolean>(false);
  const [modConfirmDelete, setModConfirmDelete] = useState<Record<string, any> | null>(null);
  const [modInfo, setModInfo] = useState<{
    color: "green" | "yellow";
    msg: string;
  } | null>(null);

  const [indexDivision, setIndexDivision] = useState<number>();
  const [selectedExtra, setSelectedExtra] = useState<string[]>([]);
  const [divisionsNotInclude, setDivisionsNotInclude] = useState<Record<string, any>[]>([]);

  // const [divisionTypeName, setDivisionTypeName] = useState<any[]>([]);
  const [dataScreen, setDataScreen] = useState<any[]>([]);
  const [selectedSettingDivisionsHasUser, setSelectedSettingDivisionsHasUser] = useState<{
    id?: number;
    name?: string;
  }>({});

  useEffect(() => {
    props.onEvent({ message: "SettingDivision", params: {} });

    return () => {
      props.setProp(`errorMessage.${CARD_SETTING_DIVIDION}`, {
        error: null,
      });
    };
  }, []);

  useEffect(() => {
    const divisionHasUser = props.profileDivision?.data.map((item: any) => item.division.id);
    const divisions = (props.divisionOptions || []).filter(
      (item: any) => !divisionHasUser?.includes(item.value)
    );

    setDivisionsNotInclude(divisions);
  }, [props.divisionOptions, props.profileDivision]);

  useEffect(() => {
    let data = props.profileDivision?.data?.find(
      (data: any) => data.id === selectedSettingDivisionsHasUser?.id
    );

    let prepare = Object.keys(ADMIN_NAME_LIST)?.map((name: any) =>


     ({
      menuName: name,
      isActive: !!data?.extra?.adminMenuList?.includes(name),
    })
  )
    setDataScreen( prepare );

  }, [selectedSettingDivisionsHasUser?.id, props.profileDivision]);

  const titleAddDivision = useMemo(
    () => (props.organizationFilter ? intl.formatMessage({ id: "key871" }) : "เพิ่มแผนก"),
    [props.organizationFilter]
  );

  const dataDivision = useMemo(() => {
    const data = props.profileDivision?.data || [];

    return data.map((item: any, index: number) => {
      const useRights: string[] = item.extra?.useRights || [];

      return {
        ...item,
        activeDiv: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Checkbox
              checked={!!item.extra?.is_Active}
              onChange={(e: any, v: any) => {
                props.setProp(`profileDivision.data.${index}.extra.is_Active`, v.checked);
                if (!v.checked && selectedSettingDivisionsHasUser?.id === item.id) {
                  setSelectedSettingDivisionsHasUser({});
                }
              }}
            />
          </div>
        ),
        code: item.division.code,
        delete: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Icon
              color="red"
              name="trash alternate"
              link
              onClick={() => {
                setModConfirmDelete(item);
              }}
            />
          </div>
        ),
        name: item.division.name,
        type_label: item.division.type_label,
        extraDiv: (
          <div style={{ display: "flex" }}>
            <div> {useRights.join(", ")}</div>
            <div style={{ flex: "1" }}> </div>
            <Button
              color="yellow"
              icon="edit"
              size="mini"
              style={{ height: "fit-content" }}
              onClick={() => {
                setOpenAddExtra(true);
                setIndexDivision(index);
                setSelectedExtra(item.extra?.useRights || []);
              }}
            />
          </div>
        ),
      };
    });
  }, [props.profileDivision]);

  // Hanler
  const handleGetTdProps = (state: any, rowInfo?: RowInfo, column?: undefined, instance?: any) => ({
    style: {
      paddingLeft: "1rem",
    },
  });

  const handleGetTrProps = (state: any, rowInfo?: RowInfo, column?: undefined, instance?: any) => ({
    style: {
      ...(rowInfo?.original?.extra.is_Active && { cursor: "pointer" }),
    },
    className:
      rowInfo?.original?.id && rowInfo?.original?.id === selectedSettingDivisionsHasUser?.id
        ? "blueSelectedRow"
        : "",
    onClick: () => {
      console.log("A Td Element was clicked! rowInfo: ", rowInfo);
      if (rowInfo?.original?.extra.is_Active) {
        if (selectedSettingDivisionsHasUser?.id === rowInfo?.original?.id) {
          setSelectedSettingDivisionsHasUser({});
        } else {
          setSelectedSettingDivisionsHasUser({
            id: rowInfo?.original.id,
            name: rowInfo?.original?.name,
          });
        }
      }
    },
  });

  const handleSave = () => {
    props.onEvent({
      message: "SettingDivision",
      params: {
        action: "SAVE",
        id: props.settingsUser?.id,
        buttonLoadKey: `${CARD_SETTING_DIVIDION}_SAVE`,
        card: CARD_SETTING_DIVIDION,
        onSuccess: () => {
          setModInfo({ color: "green", msg: "บันทึกข้อมูลสำเร็จ" });
        },
      },
    });
  };

  const handleSelect = (divisions: any[]) => {
    props.onEvent({
      message: "SettingDivision",
      params: {
        action: "ADD_DIVISION",
        id: props.settingsUser?.id,
        card: CARD_SETTING_DIVIDION,
        selectedDivision: divisions,
      },
    });

    setOpenDivision(false);
  };

  const handleChangeRight = (e: any, v: any) => {
    let useRights = selectedExtra;

    if (v.checked === false) {
      useRights = useRights.filter((value: any) => value !== v.name);
    } else if (v.checked === true) {
      useRights.push(v.name);
    }

    setSelectedExtra([...useRights]);
  };

  const handleSaveRight = () => {
    if (indexDivision === undefined) {
      return;
    }

    const { extra } = props.profileDivision?.data[indexDivision] || {};

    extra.useRights = selectedExtra;

    props.setProp(`profileDivision.data.${indexDivision}.extra`, { ...extra });

    setOpenAddExtra(false);
  };

  const handleCancel = () => {
    props.setProp("profileDivision", {
      data: JSON.parse(JSON.stringify(props.profileDivision?.raw || [])),
      raw: props.profileDivision?.raw || [],
    });
  };

  const handleCloseModConfirmDelete = () => {
    setModConfirmDelete(null);

    props.setProp(`buttonLoadCheck.${CARD_SETTING_DIVIDION}_DELETE`, null);
  };

  const handleConfirmDelete = () => {
    props.onEvent({
      message: "SettingDivision",
      params: {
        action: "DELETE_DIVISION",
        buttonLoadKey: `${CARD_SETTING_DIVIDION}_DELETE`,
        card: CARD_SETTING_DIVIDION,
        data: modConfirmDelete,
        onSuccess: handleCloseModConfirmDelete,
      },
    });
  };

  const handleCloseModInfo = () => {
    setModInfo(null);
  };

  const handleOpenDivision = () => {
    setOpenDivision(true);
  };

  // #console.log("CardSettingDivision", props);

  const adminScreenColumns = [
    {
      Header: "ชื่อเมนูใน Admin",
      accessor: "menuName", // String-based value accessors!
    },
    {
      Header: "Active",

      Cell: (cell: CellInfo) => {
        return (
          <div style={{ textAlign: "center" }}>
            <Checkbox
              checked={cell.original.isActive}
              onChange={(e: any, value: any) => {
                console.log("cell: ", cell);
                let idx = (props.profileDivision?.data || []).findIndex(
                  (data: any) => data.id === selectedSettingDivisionsHasUser?.id
                );
                if (idx !== -1) {
                  console.log("props.profileDivision?.data: ", props.profileDivision?.data);
                  let adminMenuList =
                    props.profileDivision?.data?.[idx]?.extra?.adminMenuList || [];
                  if (value.checked) {
                    if (!adminMenuList.includes(cell.original.menuName)) {
                      adminMenuList.push(cell.original.menuName);
                    }
                  } else {
                    adminMenuList = adminMenuList.filter((i: any) => i !== cell.original.menuName);
                  }
                  props.setProp(`profileDivision.data.${idx}.extra.adminMenuList`, adminMenuList);
                }
              }}
            />
          </div>
        );
      },
    },
  ];
  // console.log("CardSettingDivision adminScreenColumns: ", adminScreenColumns);
  // console.log(
  //   "CardSettingDivision props.controller.data.masterData: ",
  //   props.controller.data.masterData
  // );

  return (
    <div style={{ overflow: "scroll", height: "90%" }}>
      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage?.[CARD_SETTING_DIVIDION]?.error}
        success={null}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_SETTING_DIVIDION}`, null);
        }}
        languageUX={props.languageUX}
      />

      <CardSettingDivisionUX
        // data
        dataDivision={dataDivision}
        headers={props.tableHeaders}
        keys={props.tableKeys}
        titleAddDivision={titleAddDivision}
        showTable
        // callback
        getTdProps={handleGetTdProps}
        getTrProps={handleGetTrProps}
        onCancel={handleCancel}
        onOpenDivision={handleOpenDivision}
        divider={selectedSettingDivisionsHasUser?.name ? <Divider /> : null}
        titleTableAdminScreen={
          selectedSettingDivisionsHasUser?.name
            ? `กำหนดรายการเมนู Admin ของแผนก ${selectedSettingDivisionsHasUser?.name}`
            : ""
        }
        tableAdminScreen={
          selectedSettingDivisionsHasUser?.id ? (
            <ReactTable
              data={dataScreen}
              columns={adminScreenColumns}
              showPagination={false}
              pageSize={dataScreen?.length}
              style={{ height: "400px", width: "50%" }}
            />
          ) : null
        }
        // Element
        buttonSave={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            // config
            color={props.profileDivision?.raw.length ? "yellow" : "green"}
            disabled={!(props.profileDivision?.data.length || props.profileDivision?.raw.length)}
            name="SAVE"
            // data
            paramKey={`${CARD_SETTING_DIVIDION}_SAVE`}
            size="small"
            style={{ width: "100%" }}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_SETTING_DIVIDION}_SAVE`]}
            onClick={handleSave}
            title={
              props.profileDivision?.raw.length
                ? intl.formatMessage({ id: "key805", defaultMessage: "บันทึกการแก้ไข" })
                : intl.formatMessage({ id: "key180", defaultMessage: "บันทึก" })
            }
          />
        }
        languageUX={props.languageUX}
      />

      <ModSelectDivision
        open={openDivision}
        organizationFilter
        divisionOptions={divisionsNotInclude}
        onClose={() => {
          setOpenDivision(false);
        }}
        onSelect={handleSelect}
        languageUX={props.languageUX}
      />

      <Modal
        open={openAddExtra}
        onClose={() => {
          setOpenAddExtra(false);
        }}
      >
        <>
          <div style={styles.header}>
            <div style={styles.title}>{intl.formatMessage({ id: "เลือกสิทธิ์การใช้งาน" })}</div>
          </div>

          {["แก้ไขระดับราคาและส่วนลดค่าบริการ"].map(
            (value) => (
              <div key={`Radio-${value}`}>
                <Checkbox
                  checked={selectedExtra.includes(value)}
                  label={value}
                  name={value}
                  style={{ margin: "10px" }}
                  onChange={handleChangeRight}
                />
              </div>
            )
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "20px",
            }}
          >
            <Button color="green" style={{ marginRight: "20px" }} onClick={handleSaveRight}>
              บันทึก
            </Button>
            <Button
              color="red"
              style={{ marginRight: "20px" }}
              onClick={() => {
                setOpenAddExtra(false);
              }}
            >
              ยกเลิก
            </Button>
          </div>
        </>
      </Modal>

      <ModConfirmCNMI
        denyButtonColor="red"
        denyButtonText={intl.formatMessage({ id: "ยกเลิก" })}
        openModal={!!modConfirmDelete}
        size="mini"
        titleColor="red"
        onCloseWithDimmerClick={handleCloseModConfirmDelete}
        onDeny={handleCloseModConfirmDelete}
        approveButton={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            // config
            color={"green"}
            name="DELETE"
            // data
            paramKey={`${CARD_SETTING_DIVIDION}_DELETE`}
            size="medium"
            title={intl.formatMessage({ id: "ตกลง" })}
            basic
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_SETTING_DIVIDION}_DELETE`]}
            onClick={handleConfirmDelete}
          />
        }
        content={
          <div
            style={{
              fontWeight: "bold",
              margin: "0rem 0 -1rem",
              textAlign: "center",
            }}
          >
            ต้องการลบข้อมูลหรือไม่
          </div>
        }
      />

      <ModInfo
        open={!!modInfo}
        titleColor={modInfo?.color}
        titleName={modInfo?.msg}
        onApprove={handleCloseModInfo}
        onClose={handleCloseModInfo}
      />
    </div>
  );
};

export const ADMIN_NAME_LIST = Object.freeze({
  ตั้งค่าแพ็กเกจ: "ตั้งค่าแพ็กเกจ",
  สร้างแผนกเพิ่ม: "สร้างแผนกเพิ่ม",
  ตั้งค่าเอกสารยินยอม: "ตั้งค่าเอกสารยินยอม",
  ตั้งค่าชุดทางเลือกการรักษา: "ตั้งค่าชุดทางเลือกการรักษา",
  ตั้งค่าแผนก: "ตั้งค่าแผนก",
  จัดการสิทธิ: "จัดการสิทธิ",
  ตั้งค่าแจ้งเตือนเรียกคิว: "ตั้งค่าแจ้งเตือนเรียกคิว",
  ตั้งค่าแจ้งเตือนนัดหมาย: "ตั้งค่าแจ้งเตือนนัดหมาย",
  ลงตารางแพทย์: "ลงตารางแพทย์",
  บริหารเก้าอี้: "บริหารเก้าอี้",
  ลงตารางเภสัช: "ลงตารางเภสัช",
  สร้างตารางคลินิก: "สร้างตารางคลินิก",
  ตั้งค่าวันหยุด: "ตั้งค่าวันหยุด",
  ข้อมูลสถิติ: "ข้อมูลสถิติ",
  ข้อมูลบุคลากร: "ข้อมูลบุคลากร",
  Studio: "Studio",
  VaccineUpload: "VaccineUpload",
  "Upload ข้อมูลผู้ใช้สิทธิ": "Upload ข้อมูลผู้ใช้สิทธิ",
  ตั้งค่ากฏการเบิก: "ตั้งค่ากฏการเบิก",
  รับชำระเงินต้นสังกัด: "รับชำระเงินต้นสังกัด",
  รายงานทางการเงิน: "รายงานทางการเงิน",
  รายงานประจำคลินิก: "รายงานประจำคลินิก",
  "ออกใบแจ้งหนี้ / ตั้งงวด": "ออกใบแจ้งหนี้ / ตั้งงวด",
  "เตรียมข้อมูล E-Claim": "เตรียมข้อมูล E-Claim",
  "AIPN Send Claim": "AIPN Send Claim",
  บันทึกไฟล์ตอบกลับ: "บันทึกไฟล์ตอบกลับ",
  "import ค่า Lab สำหรับคำนวณรายได้แพทย์": "import ค่า Lab สำหรับคำนวณรายได้แพทย์",
  พิมพ์เอกสารภาษี: "พิมพ์เอกสารภาษี",
  สรุปค่าตอบแทนแพทย์: "สรุปค่าตอบแทนแพทย์",
  ศูนย์ต้นทุนรายใบเสร็จ: "ศูนย์ต้นทุนรายใบเสร็จ",
  รายงานบัญชี: "รายงานบัญชี",
  "OPD Claim Resolve": "OPD Claim Resolve",
  "OPD AR Transaction": "OPD AR Transaction",
  "OPD รายการที่สร้างเลขงวดแล้ว": "OPD รายการที่สร้างเลขงวดแล้ว",
  "OPD Send Claim": "OPD Send Claim",
  "IPD AR Transaction": "IPD AR Transaction",
  "IPD Send Claim Info": "IPD Send Claim Info",
  "IPD Send Claim Result": "IPD Send Claim Result",
  จัดการตารางห้องผ่าตัด: "จัดการตารางห้องผ่าตัด",
  "Anesthesia Template": "Anesthesia Template",
  "Segment tool": "Segment tool",
  Debug: "Debug",
  ตรวจสอบรายการสินค้าและบริการ: "ตรวจสอบรายการสินค้าและบริการ",
});

export default React.memo(CardSettingDivision);
