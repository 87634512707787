import React, { useState } from "react";
import EmailLoginManager from "./EmailLoginManager";
import { Form, Input, Button } from "semantic-ui-react";
import ErrorMessage from "react-lib/apps/common/ErrorMessage"
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

const Login = (props) => {
  const history = useHistory();
  const manager = new EmailLoginManager();
  let [email, setEmail] = useState("");
  let [message, setMessage] = useState("");
  let [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onLogin = async () => {
    setMessage("");
    setErrorMessage("");
    setIsLoading(true);
    try {
      let ret = await manager.requestLogin(email);
      setMessage(ret.detail);
      setIsLoading(false);
    } catch (e) {
      console.log("e", e);
      if (e.response.status === 404) {
        history.push("/EmailRegister/", { email: email });
      }
      setErrorMessage(JSON.stringify(e.response.data));
      setIsLoading(false);
    }
  };

  return (
    <div style={{ height: "100vh", padding: "3rem" }}>
      <h2>Login</h2>
      <ErrorMessage error={errorMessage} />
      <ErrorMessage success={message} />
      <Form>
        <Form.Group>
          <Form.Field width={16}>
            <label htmlFor="inputEmail">Email address</label>
            <Input
              type="email"
              className="form-control"
              id="inputEmail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Field>
        </Form.Group>
      </Form>
      <Button
        primary
        loading={isLoading}
        disabled={isLoading}
        onClick={onLogin}
      >
        Login
      </Button>
    </div>
  );
};

export default Login;
