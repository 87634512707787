import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  Input,
  Radio,
  Checkbox,
  TextArea
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardFiveDiseasesCertificateUX = (props: any) => {
    return(
      <div>
        <Form>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "1em 0em 0em 1em", gap: "9em"}}>
            <label
              style={{display: "flex", alignItems: "center", fontWeight: "bold"}}>
              {props.languageUX === "en" ? "Date of Examination" : "วันที่ตรวจ"}
            </label>
            <DateTextBox
              disabled={props.onHandleDisableForm}
              id="CardFiveDiseasesCertificate-DateTextBox-ExamDate"
              minDate={props.onMinDate}
              onChange={props.onHandleExamDate}
              value={props.examDate}>
            </DateTextBox>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 0em 1em", gap: "5em"}}>
            <label
              style={{display: "flex", alignItems: "center", fontWeight: "bold"}}>
              {props.languageUX === "en" ? "Identification" : "หนังสือยืนยันตัวตน"}
            </label>
            <Input
              disabled={props.onHandleDisableForm}
              id="CardFiveDiseasesCertificate-Input-IdentNo"
              readOnly={true}
              style={{width: "22em"}}
              value={props.identNo}>
            </Input>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 0em 1em", gap: "5em"}}>
            <label
              style={{display: "flex", alignItems: "center", fontWeight: "bold"}}>
              {props.languageUX === "en" ? "Patient" : "ผู้ป่วย"}
            </label>
            <Radio
              checked={props.onCheckRadio("Free")}
              disabled={props.onHandleDisableForm}
              id="CardFiveDiseasesCertificate-Radio-IsFree"
              label={props.languageUX === "en" ? "free of the following diseases" : "ปราศจากโรคดังกล่าว"}
              onChange={props.onHandleSelectedRadio}
              value="Free">
            </Radio>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 1em 8.42em"}}>
            <Radio
              checked={props.onCheckRadio("Found")}
              disabled={props.onHandleDisableForm}
              id="CardFiveDiseasesCertificate-Radio-IsFound"
              label={props.languageUX === "en" ? "found some condition(s)" : "พบเงื่อนไขบางอย่าง"}
              onChange={props.onHandleSelectedRadio}
              value="Found">
            </Radio>
          </FormGroup>
          <FormGroup
            id="ui form"
            style={{display: "flex", padding: "0em 0em 0em 10em"}}>
            <Checkbox
              checked={props.onCheckCheckbox("isFirstDisease")}
              disabled={props.onCheckboxCheck() || props.onHandleDisableForm}
              id="CardFiveDiseasesCertificate-Checkbox-Disease-1"
              label="1. โรคเรื้อน (Leprosy)"
              onChange={(e, data) => props.onHandleSelectedDisease(e, "isFirstDisease", data)}>
            </Checkbox>
          </FormGroup>
          <FormGroup
            style={{display: "flex", padding: "0em 0em 0em 10em"}}>
            <Checkbox
              checked={props.onCheckCheckbox("isSecondDisease")}
              disabled={props.onCheckboxCheck() || props.onHandleDisableForm}
              id="CardFiveDiseasesCertificate-Checkbox-Disease-2"
              label="2. วัณโรคระยะอันตราย (Tuberculosis)"
              onChange={(e, data) => props.onHandleSelectedDisease(e, "isSecondDisease", data)}>
            </Checkbox>
          </FormGroup>
          <FormGroup
            style={{display: "flex", padding: "0em 0em 0em 10em"}}>
            <Checkbox
              checked={props.onCheckCheckbox("isThirdDisease")}
              disabled={props.onCheckboxCheck() || props.onHandleDisableForm}
              id="CardFiveDiseasesCertificate-Checkbox-Disease-3"
              label="3. โรคเท้าช้าง (Elephantiasis)"
              onChange={(e, data) => props.onHandleSelectedDisease(e, "isThirdDisease", data)}>
            </Checkbox>
          </FormGroup>
          <FormGroup
            style={{display: "flex", padding: "0em 0em 0em 10em"}}>
            <Checkbox
              checked={props.onCheckCheckbox("isFourthDisease")}
              disabled={props.onCheckboxCheck() || props.onHandleDisableForm}
              id="CardFiveDiseasesCertificate-Checkbox-Disease-4"
              label="4. โรคยาเสพติดให้โทษ (Drug Addiction)"
              onChange={(e, data) => props.onHandleSelectedDisease(e, "isFourthDisease", data)}>
            </Checkbox>
          </FormGroup>
          <FormGroup
            style={{display: "flex", padding: "0em 0em 0em 10em"}}>
            <Checkbox
              checked={props.onCheckCheckbox("isFifthDisease")}
              disabled={props.onCheckboxCheck() || props.onHandleDisableForm}
              id="CardFiveDiseasesCertificate-Checkbox-Disease-5"
              label="5. โรคซิฟิลิสในระยะที่ 3 (Third step of Syphilis)"
              onChange={(e, data) => props.onHandleSelectedDisease(e, "isFifthDisease", data)}>
            </Checkbox>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 0em 1em"}}>
            <label
              style={{display: "flex", fontWeight: "bold", width: "7rem"}}>
              อื่น ๆ (ถ้ามี)
            </label>
            <TextArea
              disabled={props.onHandleDisableForm}
              id="CardFiveDiseasesCertificate-TextArea-Note"
              onChange={(e) => props.onHandleChangeNote(e)}
              rows="15"
              value={props.otherNote}>
            </TextArea>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", justifyContent: "flex-end"}}>
            <label>
              {props.onCloseSelected}
            </label>
          </FormGroup>
        </Form>
      </div>
    )
}


export default CardFiveDiseasesCertificateUX

export const screenPropsDefault = {"language":"TH"}

/* Date Time : Wed Oct 09 2024 12:01:20 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 0
    },
    {
      "from": "semantic-ui-react",
      "id": 130,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 130,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 131,
      "name": "FormGroup",
      "parent": 130,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"1em 0em 0em 1em\", gap: \"9em\"}"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": null,
      "id": 132,
      "name": "label",
      "parent": 131,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่ตรวจ",
          "valueEN": "Date of Examination"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", fontWeight: \"bold\"}"
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 133,
      "name": "DateTextBox",
      "parent": 131,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardFiveDiseasesCertificate-DateTextBox-ExamDate"
        },
        "minDate": {
          "type": "code",
          "value": "props.onMinDate"
        },
        "onChange": {
          "type": "code",
          "value": "props.onHandleExamDate"
        },
        "value": {
          "type": "code",
          "value": "props.examDate"
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 134,
      "name": "FormGroup",
      "parent": 130,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 1em\", gap: \"5em\"}"
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": null,
      "id": 135,
      "name": "label",
      "parent": 134,
      "props": {
        "children": {
          "type": "value",
          "value": "หนังสือยืนยันตัวตน",
          "valueEN": "Identification"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", fontWeight: \"bold\"}"
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 136,
      "name": "Input",
      "parent": 134,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "icon": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardFiveDiseasesCertificate-Input-IdentNo"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "size": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"22em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.identNo"
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 137,
      "name": "FormGroup",
      "parent": 130,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 1em\", gap: \"5em\"}"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": null,
      "id": 138,
      "name": "label",
      "parent": 137,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ป่วย",
          "valueEN": "Patient"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", fontWeight: \"bold\"}"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 139,
      "name": "Radio",
      "parent": 137,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.onCheckRadio(\"Free\")"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardFiveDiseasesCertificate-Radio-IsFree"
        },
        "label": {
          "type": "value",
          "value": "ปราศจากโรคดังกล่าว",
          "valueEN": "free of the following diseases"
        },
        "onChange": {
          "type": "code",
          "value": "props.onHandleSelectedRadio"
        },
        "value": {
          "type": "value",
          "value": "Free"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 140,
      "name": "Radio",
      "parent": 141,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.onCheckRadio(\"Found\")"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardFiveDiseasesCertificate-Radio-IsFound"
        },
        "label": {
          "type": "value",
          "value": "พบเงื่อนไขบางอย่าง",
          "valueEN": "found some condition(s)"
        },
        "onChange": {
          "type": "code",
          "value": "props.onHandleSelectedRadio"
        },
        "value": {
          "type": "value",
          "value": "Found"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 141,
      "name": "FormGroup",
      "parent": 130,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 1em 8.42em\"}"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 142,
      "name": "FormGroup",
      "parent": 130,
      "props": {
        "id": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 10em\"}"
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 143,
      "name": "FormGroup",
      "parent": 130,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 10em\"}"
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 144,
      "name": "FormGroup",
      "parent": 130,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 10em\"}"
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 145,
      "name": "FormGroup",
      "parent": 130,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 10em\"}"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 146,
      "name": "FormGroup",
      "parent": 130,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 10em\"}"
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 147,
      "name": "FormGroup",
      "parent": 130,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 1em\"}"
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 148,
      "name": "Checkbox",
      "parent": 142,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.onCheckCheckbox(\"isFirstDisease\")"
        },
        "disabled": {
          "type": "code",
          "value": "props.onCheckboxCheck() || props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardFiveDiseasesCertificate-Checkbox-Disease-1"
        },
        "label": {
          "type": "value",
          "value": "1. โรคเรื้อน (Leprosy)"
        },
        "onChange": {
          "type": "code",
          "value": "(e, data) => props.onHandleSelectedDisease(e, \"isFirstDisease\", data)"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 149,
      "name": "Checkbox",
      "parent": 143,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.onCheckCheckbox(\"isSecondDisease\")"
        },
        "disabled": {
          "type": "code",
          "value": "props.onCheckboxCheck() || props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardFiveDiseasesCertificate-Checkbox-Disease-2"
        },
        "label": {
          "type": "value",
          "value": "2. วัณโรคระยะอันตราย (Tuberculosis)"
        },
        "onChange": {
          "type": "code",
          "value": "(e, data) => props.onHandleSelectedDisease(e, \"isSecondDisease\", data)"
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 150,
      "name": "Checkbox",
      "parent": 144,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.onCheckCheckbox(\"isThirdDisease\")"
        },
        "disabled": {
          "type": "code",
          "value": "props.onCheckboxCheck() || props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardFiveDiseasesCertificate-Checkbox-Disease-3"
        },
        "label": {
          "type": "value",
          "value": "3. โรคเท้าช้าง (Elephantiasis)"
        },
        "onChange": {
          "type": "code",
          "value": "(e, data) => props.onHandleSelectedDisease(e, \"isThirdDisease\", data)"
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 151,
      "name": "Checkbox",
      "parent": 145,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.onCheckCheckbox(\"isFourthDisease\")"
        },
        "disabled": {
          "type": "code",
          "value": "props.onCheckboxCheck() || props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardFiveDiseasesCertificate-Checkbox-Disease-4"
        },
        "label": {
          "type": "value",
          "value": "4. โรคยาเสพติดให้โทษ (Drug Addiction)"
        },
        "onChange": {
          "type": "code",
          "value": "(e, data) => props.onHandleSelectedDisease(e, \"isFourthDisease\", data)"
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 152,
      "name": "Checkbox",
      "parent": 146,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.onCheckCheckbox(\"isFifthDisease\")"
        },
        "disabled": {
          "type": "code",
          "value": "props.onCheckboxCheck() || props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardFiveDiseasesCertificate-Checkbox-Disease-5"
        },
        "label": {
          "type": "value",
          "value": "5. โรคซิฟิลิสในระยะที่ 3 (Third step of Syphilis)"
        },
        "onChange": {
          "type": "code",
          "value": "(e, data) => props.onHandleSelectedDisease(e, \"isFifthDisease\", data)"
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": null,
      "id": 153,
      "name": "label",
      "parent": 147,
      "props": {
        "children": {
          "type": "value",
          "value": "อื่น ๆ (ถ้ามี)"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\", width: \"7rem\"}"
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 154,
      "name": "TextArea",
      "parent": 147,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardFiveDiseasesCertificate-TextArea-Note"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleChangeNote(e)"
        },
        "rows": {
          "type": "value",
          "value": "15"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.otherNote"
        }
      },
      "seq": 154,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 155,
      "name": "FormGroup",
      "parent": 130,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\"}"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": null,
      "id": 156,
      "name": "label",
      "parent": 155,
      "props": {
        "children": {
          "type": "value",
          "value": "{props.onCloseSelected}"
        }
      },
      "seq": 156,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardFiveDiseasesCertificateUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "language": "TH"
  },
  "width": 55
}

*********************************************************************************** */
