import WasmController from "react-lib/frameworks/WasmController";

import { AxiosError } from "axios";

// APIs
// REG
import PatientList from "issara-sdk/apis/PatientList_apps_REG";
import PatientDetailView from "issara-sdk/apis/PatientDetailView_apps_REG";
// USERs
import UserProfileAPI from "issara-sdk/apis/UserProfileAPI_users";
import UserTokenizeView from "issara-sdk/apis/UserTokenizeView_users";
// QUE
import PatientAppointmentView from "issara-sdk/apis/PatientAppointmentView_apps_QUE";
import PatientAppointmentUpdate from "issara-sdk/apis/PatientAppointmentUpdate_apps_QUE";
import DivisionServiceBlockView from "issara-sdk/apis/DivisionServiceBlockView_apps_QUE";
import DivisionServiceBlockDetail from "issara-sdk/apis/DivisionServiceBlockDetail_apps_QUE";
import CreateDSBFromTemplateItems from "issara-sdk/apis/CreateDSBFromTemplateItems_apps_QUE";
import WaitingListList from "issara-sdk/apis/WaitingListList_apps_QUE";
import WaitingListItemList from "issara-sdk/apis/WaitingListItemList_apps_QUE";
import WaitingListItemDetail from "issara-sdk/apis/WaitingListItemDetail_apps_QUE";
import Scheduling from "issara-sdk/apis/Scheduling_apps_QUE";
import DoctorReportList from "issara-sdk/apis/DoctorReportList_apps_QUE";
import AppointmentReportList from "issara-sdk/apis/AppointmentReportList_apps_QUE";
import WaitingQueueReport from "issara-sdk/apis/WaitingQueueReport_apps_QUE";
// import WaitingListAssign from "issara-sdk/apis/WaitingListAssign_apps_QUE";
import DSBExceptionList from "issara-sdk/apis/DSBExceptionList_apps_QUE";
import DSBExceptionDetail from "issara-sdk/apis/DSBExceptionDetail_apps_QUE";
import WaitingListCancelReport from "issara-sdk/apis/WaitingListCancelReport_apps_QUE";
import WaitingListTime from "issara-sdk/apis/WaitingListTime_apps_QUE";
import GetAvailableChairListAPI from "issara-sdk/apis/GetAvailableChairListAPI_apps_QUE";
// PRX
import CreateUpdateScheduleView from "issara-sdk/apis/CreateUpdateScheduleView_apps_PRX";
import ScheduleList from "issara-sdk/apis/ScheduleList_apps_PRX";
import V3DashboardAppointmentView from "issara-sdk/apis/V3DashboardAppointmentView_apps_PRX";
// CORE
import EncounterMedicalRecordList from "issara-sdk/apis/EncounterMedicalRecordList_core";
import ChairList from "issara-sdk/apis/ChairList_core";
import EncounterList from "issara-sdk/apis/EncounterList_core";
import MiscellaneousList from "issara-sdk/apis/MiscellaneousList_core";
import EncounterPatientListOptimized from "issara-sdk/apis/EncounterPatientListOptimized_core";
import DivisionDetail from "issara-sdk/apis/DivisionDetail_core";
// APP
import DoctorNoteTemplateList from "issara-sdk/apis/DoctorNoteTemplateList_apps_APP";
import DoctorNoteTemplateGroupList from "issara-sdk/apis/DoctorNoteTemplateGroupList_apps_APP";
// IME
import ImagingOrderEstimate from "issara-sdk/apis/ImagingOrderEstimate_apps_IME";
import ImagingOrderlist from "issara-sdk/apis/ImagingOrderList_apps_IME";
import ImagingOrderDetail from "issara-sdk/apis/ImagingOrderDetail_apps_IME";
// ORM
import OperatingOrderDetail from "issara-sdk/apis/OperatingOrderDetail_apps_ORM";
import OperatingOrderBundleCreate from "issara-sdk/apis/OperatingOrderBundleCreate_apps_ORM";
// LAB
import CentralLabOrderList from "issara-sdk/apis/CentralLabOrderList_apps_LAB";
// APP
import AppointmentDetail from "issara-sdk/apis/AppointmentDetail_apps_APP";

import ProviderList from "issara-sdk/apis/ProviderList_penta_appointment";
import UserDetailAPIView from "issara-sdk/apis/UserDetailAPIView_users";

import { DsbStatus } from "react-lib/apps/Scheduling/common/Model";
import { APPOINTMENT_STATUS } from "react-lib/apps/Scheduling/common/BlockList";

// FORM
import FormAppointmentSummary from "./FormAppointmentSummary";
import FormChartAppointmentSummary from "./FormChartAppointmentSummary";
import FormDentistSchedule from "./FormDentistSchedule";
import FormAppointmentDetail from "./FormAppointmentDetail";
import FormWaitingList from "./FormWaitingList";
import FormAppointmentQueue from "./FormAppointmentQueue";
import FormAppointmentQueueEN from "./FormAppointmentQueueEN";
import ORAppointmentForm from "./ORAppointmentForm";
import FormAppointmentSummaryList from "react-lib/apps/Scheduling/FormAppointmentSummaryList";
import FormChairBooking from "./FormChairBooking";

import fontkit from "@pdf-lib/fontkit";
import { degrees, PDFDocument } from "pdf-lib";
import moment, { Moment } from "moment";

// Utils
import {
  adToBe,
  adToBeWithSetFormat,
  beStringToDateObject,
  beStringToMoment,
  beToAd,
  formatDateToYYYYMMDD,
} from "react-lib/utils/dateUtils";
import getPdfMake from "react-lib/appcon/common/pdfMake";
import {
  formatADtoBEString,
  formatDate,
  YYYYMMDDadStringDateTimeToDateObject,
} from "react-lib/utils/dateUtils";
import { base64toBlob } from "react-lib/apps/HISV3/common/CommonInterface";
import { serialToDate, DATE_FORMAT } from "react-lib/apps/Scheduling/common/Utils";
import DoctorConsultAppointmentDetail from "issara-sdk/apis/DoctorConsultAppointmentDetail_apps_DPO";
import DoctorConsultOrderList from "issara-sdk/apis/DoctorConsultOrderList_apps_DPO";
import DoctorConsultOrderDetail from "issara-sdk/apis/DoctorConsultOrderDetail_apps_DPO";
import DoctorConsultResponseList from "issara-sdk/apis/DoctorConsultResponseList_apps_DPO";
import DoctorConsultResponseDetail from "issara-sdk/apis/DoctorConsultResponseDetail_apps_DPO";
import AppointmentFinish from "issara-sdk/apis/AppointmentFinish_apps_APP";
import DoctorList from "issara-sdk/apis/DoctorList_core";
import DoctorDetail from "issara-sdk/apis/DoctorDetail_core";
import UserEmployeeDetailAPIView from "issara-sdk/apis/UserEmployeeDetailAPIView_users";
import EncounterDetail from "issara-sdk/apis/EncounterDetail_core";

// config
import config from "config/config";
import DivisionList from "issara-sdk/apis/DivisionList_core";
import UserList from "issara-sdk/apis/UserList_users";

export const ORDER_STATUS = {
  APPOINTMENT: 1,
  PENDING: 2,
  PERFORMED: 3,
  CANCEL: 4,
};

export const CHAIR_STATUS = {
  1: "พร้อมใช้งาน",
  2: "ไม่พร้อมใช้งาน",
};

export type State = {
  masterOptions?: any;
  ORRequestSequence?: any;
  loadingStatus?: any;
  errorMessage?: any;
  preOrderList?: any;
  selectedEncounter?: any;
  patientList?: any[];
  patientListMyBplus?: any[];
  operatingBlock?: any[];
  currentDoctor?: any;

  divisionList?: any[];
  selectedEmr?: any;
  selectedPatient?: any;
  chairList?: any[];
  chairExistDSBList?: any[];
  appointmentList?: any[];
  rescheduleList?: any[];
  reconfirmList?: any[];
  waitingList?: any[];
  waitingListItemLoading?: boolean;
  waitingListLoading?: boolean;
  patientAppointmentList?: any[];
  selectedAppointment?: any;
  appointmentSummaryList?: any[];
  appointmentSummaryPage?: {
    activePage: number | null;
    total: number | null;
  };
  blockList?: any[];
  providerBlockList?: any[];
  selectedBlock?: any;
  selectedDivision?: any;
  selectedWaitingListDivisionId?: any;
  selectedAppointmentDivision?: any;
  availableSlots?: any[];
  scheduleTemplates?: any[];
  duplicateAppointment?: any;
  reoccureDuplicateAppointment?: boolean;
  holiday?: any;
  summaryDoctorList?: any[];
  summaryWaitingQueueList?: any[];
  summaryStatisticsList?: any[];
  allWaitingList?: any[];
  waitingQueueList?: any[];
  staticCanCelQueueList?: any[];
  loadingFilterAppList?: boolean;
  loadingPrintAppList?: boolean;
  buttonLoadCheck?: any;
  successMessage?: any;
  searchBlockList?: any[];
  encounterPatientList?: any[];
  selectOperatingDSBChange?: boolean;

  userTokenize?: {
    // UserTokenize
    token?: any;
    loading?: boolean;
    error?: any;
    employeeName?: any;
    employeeCode?: string | null;
    doctorId?: number | null;
  } | null;
  openModConfirmAddBlock?: {
    datetime: string;
    provider_name: string;
    number: number;
    params: any;
  } | null;
  dsbAppointments?: any[];
  // imaging
  imagingList?: any[];
  orderImagingList?: any[];
  selectedEncounterId?: number | null;
  selectedImaging?: any;
  selectedOrOrder?: any;

  // drugorder
  drugOrder?: any;

  // mod Doctor certificate confirm
  modDoctorCertificate?: {
    open?: boolean;
    narcoticDrugType?: string;
    doctorCertificate?: string;
    openModConfirm?: boolean;
  };

  django?: any;

  // Appointment Consult Order
  consultDetail?: {
    id?: number | null;
    consult_response_id?: number | null;
    sourceDivision?: number | null;
    sourceProviderDoctor?: number | null;
    destinationDivision?: number | null;
    destinationDivisionNameCode?: string;
    destinationProviderDoctor?: number | null;
    status?: any;
    note?: string;
    consult_response_suggestion?: string | null;
    date?: any;
    time?: any;
    consulted_doctor_name?: string | "";
  };

  consultData?: {
    divisionServiceBlockOptions?: any[];
    providerDoctorOptions?: any[];
  };

  waitingListItemAbort?: AbortController;
};

export const StateInitial: State = {
  patientList: [],
  patientListMyBplus: [],
  selectedPatient: null,
  chairList: [],
  chairExistDSBList: [],
  appointmentList: [],
  rescheduleList: [],
  waitingList: [],
  waitingListItemLoading: false,
  patientAppointmentList: [],
  appointmentSummaryList: [],
  appointmentSummaryPage: {
    activePage: 0,
    total: 0,
  },
  selectedAppointment: null,
  blockList: [],
  providerBlockList: [],
  selectedBlock: null,
  selectedDivision: null,
  selectedAppointmentDivision: null,
  availableSlots: [],
  scheduleTemplates: [],
  duplicateAppointment: null,
  reoccureDuplicateAppointment: false,
  holiday: null,
  userTokenize: null,
  openModConfirmAddBlock: null,
  dsbAppointments: [],
  summaryDoctorList: [],
  summaryWaitingQueueList: [],
  summaryStatisticsList: [],
  encounterPatientList: [],

  // imaging
  imagingList: [],
  orderImagingList: [],
  selectedEncounterId: null,
  selectedImaging: null,
  selectedOrOrder: null,

  modDoctorCertificate: {
    open: false,
    narcoticDrugType: "",
    doctorCertificate: "",
    openModConfirm: false,
  },

  consultDetail: {
    id: null,
    consult_response_id: null,
    sourceDivision: null,
    sourceProviderDoctor: null,
    destinationDivision: null,
    destinationDivisionNameCode: "",
    destinationProviderDoctor: null,
    status: "",
    note: "",
    consult_response_suggestion: "",
    date: "",
    time: "",
  },

  consultData: {
    providerDoctorOptions: [],
    divisionServiceBlockOptions: [],
  },
};

export type Event =
  | { message: "SearchPatient"; params: {} }
  | { message: "ClearAppointment"; params: {} }
  | { message: "SelectPatient"; params: { id: number } }
  | { message: "RefreshAppointment"; params: {} }
  | { message: "CreatePatientAppointment"; params: {} }
  | { message: "SelectAppointment"; params: {} }
  | { message: "HandleDownloadAppointmentSummary"; params: {} }
  | { message: "HandlePrintAppointmentSummaryList"; params: {} }
  | { message: "AssignAppointmentToBlock"; params: {} }
  // | { message: "ClearSelectedBlock"; params: {}}
  | { message: "FilterSchedule"; params: any }
  | { message: "FilterAppointmentSchedule"; params: any }
  | { message: "AddBlock"; params: any }
  | { message: "GetChairList"; params: any }
  | { message: "BookChair"; params: any }
  | { message: "SetScheduling"; params: any }
  | { message: "GetListSchedule"; params: any }
  | { message: "CreateUpdateSchedule"; params: any }
  | { message: "Holiday"; params: any }
  | { message: "PrintScheduling"; params: any }
  | { message: "UserTokenize"; params: any }
  | { message: "getDoctorNoteList"; params: any }
  | { message: "getDoctorNoteGroupList"; params: any }
  | { message: "GetSummaryWaitingQueue"; params: {} }
  | { message: "GetSummaryStatistics"; params: {} }
  | { message: "GetSummaryDoctor"; params: {} }
  | { message: "ChangeDivision"; params: {} }
  | { message: "GetChairWithDivisionServiceBlock"; params: {} }
  | { message: "SearchBlockList"; params: {} }
  | {
      message: "HandleActionPreOrderList";
      params: { action: string; id: number };
    }
  | { message: "ImagingRequestOrder"; params: any }
  | { message: "HandleSelectAppointmentByOROrder"; params: any }
  | { message: "GetEncounterWithPatient"; params: any }
  | { message: "HandlePrintORAppointmentForm"; params: any }
  | { message: "CreatePatientAppointmentForBloodBank"; params: any }
  | { message: "GetMasterData"; params: any }
  | { message: "HandleInitialConsultData"; params: any }
  | { message: "HandleSetConsultDetail"; params: any }
  | { message: "HandleMakeConsultAppointment"; params: any }
  | { message: "HandleConfirmConsultAppointment"; params: any }
  | { message: "HandleFinishConsultAppointment"; params: any }
  | { message: "HandleSaveConsultResponse"; params: any }
  | { message: "HandleEditConsultResponse"; params: any }
  | { message: "HandleCancelConsultAppointment"; params: any }
  | { message: "HandleGetUsername"; params: any }
  | { message: "HandlePrintChairBooking"; params: any };

export type Data = {
  division?: number;
  provider?: number;
  divisionDict?: any;
  chairDict?: any;
  device?: any;
  user?: string;
};

export const DataInitial = {};

export const DOCTOR_CONSULT_ORDER = "doctorconsultorder";

export const TYPE_DISPLAY = {
  or: "นัดหมายผ่าตัด",
  package: "นัดหมาย Package",
  consult_opd: "นัดหมาย Consult OPD",
  consult_ipd: "นัดหมาย Consult IPD",
};

export const APPOINTMENT_STATUS_OPTIONS = [
  { key: 1, text: "รอยืนยัน", value: "1" },
  { key: 2, text: "ยืนยันนัดหมาย", value: "2,8" },
  { key: 5, text: "ยกเลิก", value: "5,6" },
];

export const APPOINTMENT_TYPE_OPTIONS = [
  { key: 1, text: "นัดหมายผ่าตัด", value: "OR" },
  { key: 2, text: "นัดหมาย Consult", value: "CONSULT" },
  { key: 3, text: "นัดหมาย Package", value: "PACKAGE" },
  { key: 4, text: "นัดหมายทั่วไป", value: "NORMAL" },
  { key: 5, text: "Waiting list", value: "WAITING_LIST" },
];
// export const APPOINTMENT_POSTPONE_OPTIONS = [
//   {
//     key: "คลินิกปิดทำการ",
//     text: "คลินิกปิดทำการ",
//     value: "คลินิกปิดทำการ",
//   },
//   { key: "ทันตแพทย์ลา", text: "ทันตแพทย์ลา", value: "ทันตแพทย์ลา" },
//   {
//     key: "มีปัญหาเรื่องค่าใช้จ่าย",
//     text: "มีปัญหาเรื่องค่าใช้จ่าย",
//     value: "มีปัญหาเรื่องค่าใช้จ่าย",
//   },
//   {
//     key: "ส่งเตรียมช่องปากก่อน",
//     text: "ส่งเตรียมช่องปากก่อน",
//     value: "ส่งเตรียมช่องปากก่อน",
//   },
//   {
//     key: "บันทึกข้อมูลผิด",
//     text: "บันทึกข้อมูลผิด",
//     value: "บันทึกข้อมูลผิด",
//   },
//   {
//     key: "ทพ.ไม่ให้ลงนัด ให้มานั่งรอวันที่มีคลินิกเท่านั้น",
//     text: "ทพ.ไม่ให้ลงนัด ให้มานั่งรอวันที่มีคลินิกเท่านั้น",
//     value: "ทพ.ไม่ให้ลงนัด ให้มานั่งรอวันที่มีคลินิกเท่านั้น",
//   },
//   {
//     key: "ส่งรักษาโรคประจำตัว",
//     text: "ส่งรักษาโรคประจำตัว",
//     value: "ส่งรักษาโรคประจำตัว",
//   },
//   {
//     key: "คนไข้มีโรคประจำตัว/ป่วย",
//     text: "คนไข้มีโรคประจำตัว/ป่วย",
//     value: "คนไข้มีโรคประจำตัว/ป่วย",
//   },
//   {
//     key: "สิทธิ์การรักษาไม่เรียบร้อย",
//     text: "สิทธิ์การรักษาไม่เรียบร้อย",
//     value: "สิทธิ์การรักษาไม่เรียบร้อย",
//   },
//   {
//     key: "ผู้ป่วยติดธุระ ไม่สะดวกวันนัดเดิม",
//     text: "ผู้ป่วยติดธุระ ไม่สะดวกวันนัดเดิม",
//     value: "ผู้ป่วยติดธุระ ไม่สะดวกวันนัดเดิม",
//   },
//   {
//     key: "ยาที่จ่ายหมดก่อนวันนัดเดิม",
//     text: "ยาที่จ่ายหมดก่อนวันนัดเดิม",
//     value: "ยาที่จ่ายหมดก่อนวันนัดเดิม",
//   },
//   {
//     key: "ผู้ป่วยมาสายทันตแพทย์ให้นัดหมาย",
//     text: "ผู้ป่วยมาสายทันตแพทย์ให้นัดหมาย",
//     value: "ผู้ป่วยมาสายทันตแพทย์ให้นัดหมาย",
//   },
//   {
//     key: "Lab/อุปกรณ์ไม่พร้อม",
//     text: "Lab/อุปกรณ์ไม่พร้อม",
//     value: "Lab/อุปกรณ์ไม่พร้อม",
//   },
//   {
//     key: "เปลี่ยนแผนการรักษา",
//     text: "เปลี่ยนแผนการรักษา",
//     value: "เปลี่ยนแผนการรักษา",
//   },
//   {
//     key: "สถานการณ์ Covid 19",
//     text: "สถานการณ์ Covid 19",
//     value: "สถานการณ์ Covid 19",
//   },
// ];

// export const APPOINTMENT_CANCEL_OPTIONS = [
//   { key: "ทันตแพทย์ลา", text: "ทันตแพทย์ลา", value: "ทันตแพทย์ลา" },
//   {
//     key: "ผิดนัดหลายครั้ง ให้นัดทันตแพทย์ท่านอื่น",
//     text: "ผิดนัดหลายครั้ง ให้นัดทันตแพทย์ท่านอื่น",
//     value: "ผิดนัดหลายครั้ง ให้นัดทันตแพทย์ท่านอื่น",
//   },
//   {
//     key: "ผู้ป่วยขอพักคิว",
//     text: "ผู้ป่วยขอพักคิว",
//     value: "ผู้ป่วยขอพักคิว",
//   },
//   {
//     key: "ผู้ป่วนเสียชีวิต",
//     text: "ผู้ป่วนเสียชีวิต",
//     value: "ผู้ป่วนเสียชีวิต",
//   },
//   {
//     key: "ติดต่อผู้ป่วยไม่ได้",
//     text: "ติดต่อผู้ป่วยไม่ได้",
//     value: "ติดต่อผู้ป่วยไม่ได้",
//   },
//   {
//     key: "มีปัญหาเรื่องค่าใช้จ่าย",
//     text: "มีปัญหาเรื่องค่าใช้จ่าย",
//     value: "มีปัญหาเรื่องค่าใช้จ่าย",
//   },
//   {
//     key: "รอคิวนานเกินไป",
//     text: "รอคิวนานเกินไป",
//     value: "รอคิวนานเกินไป",
//   },
//   {
//     key: "ผู้ป่วยไม่มีอาการแล้ว",
//     text: "ผู้ป่วยไม่มีอาการแล้ว",
//     value: "ผู้ป่วยไม่มีอาการแล้ว",
//   },
//   {
//     key: "ส่งเตรียมช่องปากก่อน",
//     text: "ส่งเตรียมช่องปากก่อน",
//     value: "ส่งเตรียมช่องปากก่อน",
//   },
//   {
//     key: "ผู้ป่วยทำการักษาที่อื่น",
//     text: "ผู้ป่วยทำการักษาที่อื่น",
//     value: "ผู้ป่วยทำการักษาที่อื่น",
//   },
//   {
//     key: "บันทึกข้อมูลผิด",
//     text: "บันทึกข้อมูลผิด",
//     value: "บันทึกข้อมูลผิด",
//   },
//   {
//     key: "คนไข้มีโรคประจำตัว",
//     text: "คนไข้มีโรคประจำตัว",
//     value: "คนไข้มีโรคประจำตัว",
//   },
//   {
//     key: "ทพ.ไม่ให้ลงนัด ให้มานั่งรอวันที่มีคลินิกเท่านั้น",
//     text: "ทพ.ไม่ให้ลงนัด ให้มานั่งรอวันที่มีคลินิกเท่านั้น",
//     value: "ทพ.ไม่ให้ลงนัด ให้มานั่งรอวันที่มีคลินิกเท่านั้น",
//   },
//   {
//     key: "คนไข้มารักษาก่อนวันนัด",
//     text: "คนไข้มารักษาก่อนวันนัด",
//     value: "คนไข้มารักษาก่อนวันนัด",
//   },
//   {
//     key: "เปลี่ยนแผนการรักษา",
//     text: "เปลี่ยนแผนการรักษา",
//     value: "เปลี่ยนแผนการรักษา",
//   },
//   {
//     key: "ผู้ป่วยขอพักคิว",
//     text: "ผู้ป่วยขอพักคิว",
//     value: "ผู้ป่วยขอพักคิว",
//   },
//   {
//     key: "รอคิวนานเกินไป",
//     text: "รอคิวนานเกินไป",
//     value: "รอคิวนานเกินไป",
//   },
//   {
//     key: "ผู้ป่วยไม่มีอาการแล้ว",
//     text: "ผู้ป่วยไม่มีอาการแล้ว",
//     value: "ผู้ป่วยไม่มีอาการแล้ว",
//   },
//   {
//     key: "ผู้ป่วยทำการรักษาที่อื่น",
//     text: "ผู้ป่วยทำการรักษาที่อื่น",
//     value: "ผู้ป่วยทำการรักษาที่อื่น",
//   },
//   {
//     key: "สถานการณ์ Covid 19",
//     text: "สถานการณ์ Covid 19",
//     value: "สถานการณ์ Covid 19",
//   },
//   {
//     key: "ผู้ป่วยโทรมายกเลิกนัด Recall Oper",
//     text: "ผู้ป่วยโทรมายกเลิกนัด Recall Oper",
//     value: "ผู้ป่วยโทรมายกเลิกนัด Recall Oper",
//   },
//   {
//     key: "ผู้ป่วยโทรมายกเลิกนัด Recall endo",
//     text: "ผู้ป่วยโทรมายกเลิกนัด Recall endo",
//     value: "ผู้ป่วยโทรมายกเลิกนัด Recall endo",
//   },
//   {
//     key: "ผู้ป่วยโทรมายกเลิกนัดตรวจ",
//     text: "ผู้ป่วยโทรมายกเลิกนัดตรวจ",
//     value: "ผู้ป่วยโทรมายกเลิกนัดตรวจ",
//   },
//   {
//     key: "ผิดนัดหลายครั้ง ให้ตรวจใหม่",
//     text: "ผิดนัดหลายครั้ง ให้ตรวจใหม่",
//     value: "ผิดนัดหลายครั้ง ให้ตรวจใหม่",
//   },
// ];

type Handler = (controller: WasmController<State, Event, Data>, params?: any) => any;

export const GetListSchedule: Handler = async (controller, params) => {
  const state = controller.getState();
  const scheduleList = await ScheduleList.list({
    params: { division: params.divisionId },
    apiToken: controller.apiToken,
  });
  console.log(
    "GetListSchedule scheduleList[0/1]: ",
    scheduleList[1] ? scheduleList[1] : scheduleList[0]
  );
  if (scheduleList[1]) {
    if (params.card) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: scheduleList[1] },
      });
    }
  }
  controller.setState({
    scheduleTemplates: (scheduleList[0]?.items || []).map((item: any) => ({
      ...item,
      start: item?.start_datetime?.split("T")?.[0] || "",
      end: item?.end_datetime?.split("T")?.[0] || "",
    })),
  });
  console.log("GetListSchedule scheduleList: ", scheduleList);
  return scheduleList;
};

export const CreateUpdateSchedule: Handler = async (controller, params) => {
  const state = controller.getState();
  const {
    apiToken,
    division,
    providerTypeCategory,
    startDate,
    endDate,
    patientPerSlot,
    items,
    workOnMonday,
    workOnTuesday,
    workOnWednesday,
    workOnThursday,
    workOnFriday,
    workOnSaturday,
    workOnSunday,
    id,
    confirmed,
    diagRule,
    multi,
    check_overlap,
  } = params?.data;

  let data: any = {};
  if (id) {
    data.id = id;
  }

  if (division) {
    data.division = division;
  }
  if (providerTypeCategory) {
    data.provider_type_category = providerTypeCategory;
  }
  if (startDate) {
    data.start_date = startDate;
  }
  if (endDate) {
    data.end_date = endDate;
  }
  if (patientPerSlot) {
    data.patient_per_slot = patientPerSlot;
  }
  if (items) {
    data.items = items;
  }
  if (diagRule) {
    data.diag_rule = diagRule;
  }
  if (workOnMonday !== undefined) {
    data.work_on_monday = workOnMonday;
  }
  if (workOnTuesday !== undefined) {
    data.work_on_tuesday = workOnTuesday;
  }
  if (workOnWednesday !== undefined) {
    data.work_on_wednesday = workOnWednesday;
  }
  if (workOnThursday !== undefined) {
    data.work_on_thursday = workOnThursday;
  }
  if (workOnFriday !== undefined) {
    data.work_on_friday = workOnFriday;
  }
  if (workOnSaturday !== undefined) {
    data.work_on_saturday = workOnSaturday;
  }
  if (workOnSunday !== undefined) {
    data.work_on_sunday = workOnSunday;
  }
  if (confirmed !== undefined) {
    data.confirmed = confirmed;
  }
  if (multi) {
    data.multi = multi;
  }
  if (typeof check_overlap !== "undefined") {
    data.check_overlap = check_overlap;
  }

  const result = await CreateUpdateScheduleView.post({
    data: data,
    apiToken: controller.apiToken,
  });
  console.log(result[1] ? result[1] : result[0]);
  FilterSchedule(controller, { divisionId: state.selectedDivision.id });
  return result;
};

export const Holiday: Handler = async (controller, params) => {
  const state = controller.getState();
  if (params?.action === "refresh") {
    const holidays = await DSBExceptionList.list({
      params: { division_id: state.selectedDivision?.id },
      apiToken: controller.apiToken,
    });
    console.log(holidays[1] ? holidays[1] : holidays[0]);
    if (holidays[0]?.items?.length && holidays[0].items.length > 0) {
      let holidayItem = holidays[0].items[0];
      holidayItem.items = holidayItem.items
        .map((item: any) => ({
          ...item,
          // day: item.day?.toString(),
          // month: item.month?.toString(),
          ...(item.day &&
            item.month && {
              date: [
                item.day?.toString() +
                  "/" +
                  item.month?.toString() +
                  "/" +
                  (item.date?.[0]?.split("/")[2] || "2565"),
              ],
            }), // migrate
        }))
        ?.map((item: any) => {
          let { day, month, ...rest } = item;
          return { ...rest };
        });
      controller.setState({
        holiday: holidayItem,
      });
    } else {
      controller.setState({
        holiday: {
          id: null,
          division: state.selectedDivision?.id,
          items: [],
          follow_parent: true,
        },
      });
    }
  } else if (["update", "delete"].includes(params?.action)) {
    let items = state.holiday.items;

    // modify items if applicable
    if (params?.action === "update" && params?.item) {
      const { isNew, date, ...itemToAdd } = params.item;
      let dateList: string[] = date?.map((d: any) => d.replace(/-/g, "/"));
      // console.log('update date: ', date);
      // console.log('update dateList: ', dateList);
      // console.log("update params.item", params.item)
      if (params.item.isNew) {
        // NEW CASE
        items = items.filter((item: any) => {
          // console.log("item check map new item: " , item)
          return !dateList?.includes(item.date?.[0]);
        });

        dateList?.forEach((date: any) => {
          items.push({ ...itemToAdd, date: [date] });
        });
        console.log("New Holiday NEW items: ", items);
      } else {
        // EDIT CASE
        // Append if not have
        dateList?.forEach((date: any) => {
          if (!items?.find((item: any) => item.date?.[0] === date)) {
            items.push({ ...itemToAdd, date: [date] });
          }
        });

        // Edit If match
        items = items.map((item: any) => {
          return dateList?.includes(item.date?.[0])
            ? { ...params.item, date: [item.date?.[0]] }
            : item;
        });

        console.log(" Holiday EDIT items: ", items);
      }
    } else if (params?.action === "delete" && params?.item) {
      items = items.filter((item: any) => !params.item?.date?.includes(item.date?.[0]));
    }

    // sort
    items = items.sort(
      (a: any, b: any) => a.date?.[0].replace(/\//g, "") - b.date?.[0].replace(/\//g, "")
    );

    // Add day, month
    items = items.map((item: any) => ({
      ...item,
      day: parseInt(item.date?.[0]?.split("/")[0]),
      month: parseInt(item.date?.[0]?.split("/")[1]),
    }));

    console.log("++++ Holiday items: ", items);

    // Then create or update
    let holiday;
    if (state.holiday?.id) {
      holiday = await DSBExceptionDetail.update({
        pk: state.holiday.id,
        data: {
          ...state.holiday,
          items: items,
        },
        apiToken: controller.apiToken,
      });
    } else {
      holiday = await DSBExceptionList.create({
        data: {
          ...state.holiday,
          items: items,
        },
        apiToken: controller.apiToken,
      });
    }
    console.log(holiday[1] ? holiday[1] : holiday[0]);
    let holidayItem = holiday[0];
    holidayItem.items = holidayItem.items.map((item: any) => ({
      ...item,
      // day: item.day.toString(),
      // month: item.month.toString(),
    }));
    controller.setState({
      holiday: holidayItem,
    });
  }
};

export const SetScheduling: Handler = async (controller, params) => {
  var state = controller.getState();

  if (params?.action === "GetReconfirmAppointment") {
    // Get Reconfirm
    // Issue 55606

    // console.log("SetScheduling  GetReconfirmAppointment ")

    // query parameters:
    // hn: เลข hn ของผู้ป่วย
    // provider_id: provider id ของ หมอ
    // division_id : id ของ หน่วย/แผนก
    // waiting_list: <string>
    // start_date: วันที่เริ่มต้น ในรูปแบบ format DD/MM/YYYY BE
    // end_date: วันที่สิ้นสุด ในรูปแบบ format DD/MM/YYYY BE
    // type: ชนิดของนัดหมาย (id ของ clinicalTermset ของ ประเภทนัดหมาย)

    const reconfirm = await PatientAppointmentView.list({
      params: {
        division_id: params.division_id,
        reconfirm_only: true,
        hn: /\[(.*)\] ?(?= )/g.exec(params?.hn)?.[1] || params?.hn,
        provider_id: params?.provider_id,
        waiting_list: params?.waiting_list,
        start_date: params?.start_date,
        end_date: params?.end_date,
        appointment_related_type: params?.appointment_type,
      },
      apiToken: controller.apiToken,
    });

    console.log("reconfirm: ", reconfirm);
    controller.setState({
      reconfirmList: (reconfirm[0]?.items || []).map((app: any) => ({
        ...app,
        patient_name: app?.patient_pre_name
          ? `${app.patient_pre_name} ${app.patient_first_name} ${app.patient_last_name}`
          : `${app.patient_first_name} ${app.patient_last_name}`,
        provider_name: `${app.display_info?.provider_name}`,
      })),
    });
  } else if (params?.action === "GetRescheduleAppointment") {
    // Get Reschedule
    // Issue 55606

    // query parameters:
    // hn: เลข hn ของผู้ป่วย
    // provider_id: provider id ของ หมอ
    // division_id : id ของ หน่วย/แผนก
    // waiting_list: <string>
    // start_date: วันที่เริ่มต้น ในรูปแบบ format DD/MM/YYYY BE
    // end_date: วันที่สิ้นสุด ในรูปแบบ format DD/MM/YYYY BE
    // type: ชนิดของนัดหมาย (id ของ clinicalTermset ของ ประเภทนัดหมาย)
    const reschedule = await PatientAppointmentView.list({
      params: {
        division_id: state.selectedDivision?.id,
        reschedule_only: true,
        hn: /\[(.*)\] ?(?= )/g.exec(params?.hn)?.[1] || params?.hn,
        provider_id: params?.provider_id,
        waiting_list: params?.waiting_list,
        start_date: params?.start_date,
        end_date: params?.end_date,
        waiting_list_status: params?.type,
      },
      apiToken: controller.apiToken,
    });

    controller.setState({
      rescheduleList: (reschedule[0]?.items || []).map((app: any) => ({
        ...app,
        // patient_name: `${app.patient_pre_name} ${app.patient_first_name} ${app.patient_last_name}`,
        patient_name: app?.patient_pre_name
          ? `${app.patient_pre_name} ${app.patient_first_name} ${app.patient_last_name}`
          : `${app.patient_first_name} ${app.patient_last_name}`,
        provider_name: `${app.display_info?.provider_name}`,
      })),
    });
  }
  // Waiting กับ ยกเลิก ใช้ ตัวเดียวกัน เพราะหลังบ้าน ยังไม่มี status
  else if (params?.action === "WaitItems") {
    const promiseArr = params?.item?.map((item: any) => {
      if (item.status !== "APPOINTMENT" && item.status !== "CANCEL") {
        return WaitingListItemDetail.patch({
          pk: item.id,
          data: {
            status: "STALL",
            reason: params.note,
          },
          apiToken: controller.apiToken,
        });
      } else {
        return;
      }
    });
    const waiting = await Promise.all(promiseArr);
    // console.log('params?.selectedWaitingList?.id: ', params?.waitinglist?.id);
    SetScheduling(controller, {
      action: "GetCardPatientAppointmentData",
      waiting_list_id: params?.waitinglist?.id,
      wlSearchParams: params?.wlSearchParams,
    });

    // console.log(" WaitItems !!!");
  } else if (params?.action === "ResumeWaiting") {
    const promiseArr = params?.item?.map((item: any) => {
      if (item.status !== "APPOINTMENT" && item.status !== "CANCEL") {
        return WaitingListItemDetail.patch({
          pk: item.id,
          data: {
            status: "PENDING",
          },
          apiToken: controller.apiToken,
        });
      } else {
        return;
      }
    });
    const resumeWaiting = await Promise.all(promiseArr);

    SetScheduling(controller, {
      action: "GetCardPatientAppointmentData",
      waiting_list_id: params?.waitinglist?.id,
      wlSearchParams: params?.wlSearchParams,
    });
  } else if (params?.action === "RemoveItems") {
    const promiseArr = params?.item?.map((item: any) => {
      if (item.status !== "APPOINTMENT") {
        return WaitingListItemDetail.patch({
          pk: item.id,
          data: {
            status: "CANCEL",
            reason: params.note,
          },
          apiToken: controller.apiToken,
        });
      } else {
        return;
      }
    });
    const resumeItems = await Promise.all(promiseArr);

    SetScheduling(controller, {
      action: "GetCardPatientAppointmentData",
      waiting_list_id: params?.waitinglist?.id,
      wlSearchParams: params?.wlSearchParams,
    });
  } else if (params?.action === "SaveAppointment" && state.selectedAppointment?.id) {
    HandleSaveAppointment(controller, params);
  } else if (params?.action === "confirmAppointment") {
    controller.setState({
      // loadingStatus: { ...state.loadingStatus, [params?.card || ""]: true },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params?.buttonLoadCheck]: "LOADING",
      },
    });

    const patientAppointment = await PatientAppointmentUpdate.patch({
      pk: state.selectedAppointment.id,
      data: {
        status: 2,
        ...(params.repetition_note && {
          repetition_note: params.repetition_note,
          ...(state.userTokenize?.employeeName && {
            user: state.userTokenize?.employeeName,
          }),
        }),
      },
      apiToken: controller.apiToken,
    });

    const isDuplicate = handleCheckDuplicateAppointment(controller, {
      error: patientAppointment[1],
      payload: params,
    });

    if (isDuplicate) {
      return;
    }

    if (patientAppointment?.[1]) {
      controller.setState({
        duplicateAppointment: null,
        errorMessage: {
          ...state.errorMessage,
          [params.card]: patientAppointment[1],
        },
        // loadingStatus: { ...state.loadingStatus, [params?.card || ""]: false },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params?.buttonLoadCheck]: "ERROR",
        },
      });
    } else {
      controller.setState(
        {
          userTokenize: null,
          duplicateAppointment: null,
          loadingStatus: {
            ...state.loadingStatus,
            [params?.card || ""]: false,
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [params?.buttonLoadCheck]: "SUCCESS",
          },
        },
        () => {
          setTimeout(() => {
            controller.setState(
              {
                appointmentList: state.appointmentList?.map((item) =>
                  item.id === state.selectedAppointment?.id ? { ...item, status: 2 } : item
                ),
                selectedAppointment: {
                  ...state.selectedAppointment,
                  status: 2,
                },
              },
              () => {
                if (params?.callback) {
                  params?.callback?.();
                }
              }
            );
          }, 2000);
        }
      );
    }
  } else if (params?.action === "GetScheduleTemplate") {
    const scheduleList = await ScheduleList.list({
      params: { division: state.selectedDivision?.id },
      apiToken: controller.apiToken,
    });
    console.log(scheduleList[1] ? scheduleList[1] : scheduleList[0]);
    controller.setState({
      scheduleTemplates: (scheduleList[0]?.items || []).map((item: any) => ({
        ...item,
        start: item?.start_datetime?.split("T")?.[0] || "",
        end: item?.end_datetime?.split("T")?.[0] || "",
      })),
    });
  } else if (params?.action === "CreateDSBFromTemplateItems") {
    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [params.card]: "LOADING" },
    });
    const dsb = await CreateDSBFromTemplateItems.post({
      data: {
        items: params?.items,
        providers: params?.providers,
        start_date: params?.startDateTime,
        end_date: params?.endDateTime,
        zone: params?.zone?.id,
        only_old_patient: params?.exceptNewPatientAll || null,
        examination_type: params?.examinationType === "null" ? null : params?.examinationType,
      },
      apiToken: controller.apiToken,
    });
    if (dsb[1]) {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "ERROR",
        },
      });
    } else {
      controller.setState({
        buttonLoadCheck: { ...state.buttonLoadCheck, [params.card]: "SUCCESS" },
        successMessage: {
          ...state.successMessage,
          [params.buttonLoadKey]: dsb[0],
        },
      });
    }
    console.log(dsb[1] ? dsb[1] : dsb[0]);
    // Refresh schedule
    controller.handleEvent({
      message: "FilterSchedule",
      params: { divisionId: state.selectedDivision?.id },
    });
  } else if (params?.action === "GetCardPatientAppointmentData") {
    // Get Appointment to reschedule (doctor change scheduling)

    console.log("GetCardPatientAppointmentData");
    controller.handleEvent({
      message: "GetMasterData",
      params: {
        masters: [["orCancelNote", {}]],
      },
    } as any);

    let patientAppointmentP = PatientAppointmentView.list({
      params: {
        division_id: state.selectedDivision?.id,
        limit: 99999,
      },
      apiToken: controller.apiToken,
    });

    const [reschedule, reconfirm, waitingList] = await Promise.all([
      PatientAppointmentView.list({
        params: {
          division_id: state.selectedDivision?.id,
          reschedule_only: true,
        },
        apiToken: controller.apiToken,
      }),
      PatientAppointmentView.list({
        params: {
          division_id: state.selectedDivision?.id,
          reconfirm_only: true,
        },
        apiToken: controller.apiToken,
      }),
      GetWaitingListData(controller, {
        ...params.wlSearchParams,
        forceFetch: true,
        divisionId: params.divisionId,
      }),
    ]);

    console.log("GetCardPatientAppointmentData reschedule: ", reschedule);
    console.log("GetCardPatientAppointmentData reconfirm: ", reconfirm);
    console.log("GetCardPatientAppointmentData waitingList: ", waitingList);

    controller.setState({
      patientAppointmentList: [],
      rescheduleList: (reschedule[0]?.items || []).map((app: any) => ({
        ...app,
        // patient_name: `${app.patient_pre_name} ${app.patient_first_name} ${app.patient_last_name}`,
        patient_name: app?.patient_pre_name
          ? `${app.patient_pre_name} ${app.patient_first_name} ${app.patient_last_name}`
          : `${app.patient_first_name} ${app.patient_last_name}`,
        provider_name: `${app.display_info?.provider_name}`,
      })),

      reconfirmList: (reconfirm[0]?.items || []).map((app: any) => ({
        ...app,
        // patient_name: `${app.patient_pre_name} ${app.patient_first_name} ${app.patient_last_name}`,
        patient_name: app?.patient_pre_name
          ? `${app.patient_pre_name} ${app.patient_first_name} ${app.patient_last_name}`
          : `${app.patient_first_name} ${app.patient_last_name}`,
        provider_name: `${app.display_info?.provider_name}`,
      })),
      ...(waitingList !== AxiosError.ERR_CANCELED && { waitingList: [...waitingList] }),
    });

    let [r, e, n] = await patientAppointmentP;
    console.log("PatientAppointmentView r: ", r);

    controller.setState({
      patientAppointmentList: r?.items ? r?.items : [],
    });
  } else if (params?.action === "AddPatientToWaitingList") {
    console.log("AddPatientToWaitingList params: ", params);

    // patient_id
    const appointmentPatient = await WaitingListItemList.list({
      params: {
        division_id: state.selectedDivision?.id,
        waiting_list: params.waiting_list_id,
        patient_id: params.patientId,
      },
      apiToken: controller.apiToken,
    });

    if (!appointmentPatient[1] && appointmentPatient[0]) {
      let dup = appointmentPatient[0]?.items
        ?.filter((item: any) => item?.appointment === null)
        .filter((item: any) => item.status !== "CANCEL")
        .map((item: any) => item?.patient)
        .includes(params?.patientId);

      if (dup) {
        console.log("duplicated patient id:", params?.patientId);
        alert("คนไข้ อยู่ใน waitingList นี้แล้ว");
        return;
      }
    }

    // Prevent duplicate addition
    // if (
    //   params?.waitingList?.items
    //     ?.filter((item: any) => item?.appointment === null)
    //     .filter((item: any) => item.status !== "CANCEL")
    //     .map((item: any) => item?.patient)
    //     .includes(params?.patientId)
    // ) {
    //   console.log("duplicated patient id:", params?.patientId);
    //   return;
    // }

    // Create new waitinglist item
    const seq = Math.max(
      ...[0].concat((params?.waitingList?.items || []).map((item: any) => item?.seq || 0))
    );
    const item = await WaitingListItemList.create({
      data: {
        waiting_list: params?.waitingList?.id,
        patient: params?.patientId,
        provider: params?.provider?.provider_id,
        seq: seq + 1,
      },
      apiToken: controller.apiToken,
    });

    console.log("WaitingListItemList create", item[1] ? item[1] : item[0]);

    // Refresh waiting list after create
    SetScheduling(controller, {
      action: "GetWaitingListItem",
      ...params.wlSearchParams,
    });
  } else if (params?.action === "CancelAppointment") {
    const cancelPatientApp = await PatientAppointmentUpdate.patch({
      pk: state.selectedAppointment.id,
      data: {
        status: APPOINTMENT_STATUS.CANCEL,
        status_note: JSON.stringify(params?.reason),
        ...(state?.userTokenize?.employeeName ? { user: state?.userTokenize?.employeeName } : {}),
      },
      apiToken: controller.apiToken,
    });
    controller.setState({
      userTokenize: null,
      selectedAppointment: null,
      selectedBlock: null,
    });
    console.log(cancelPatientApp[1] ? cancelPatientApp[1] : cancelPatientApp[0]);
    RefreshAppointment(controller, params);
    params?.callback?.();
  } else if (params?.action === "CancelAppointmentByPatient") {
    const cancelPatientApp = await PatientAppointmentUpdate.patch({
      pk: state.selectedAppointment.id,
      data: {
        status: APPOINTMENT_STATUS.REJECT,
        status_note: JSON.stringify(params?.reason),
        ...(state?.userTokenize?.employeeName ? { user: state?.userTokenize?.employeeName } : {}),
      },
      apiToken: controller.apiToken,
    });
    if (state.selectedAppointment?.orders) {
      const promiseArray = state.selectedAppointment?.orders?.map((item: any) => {
        if (item.type === "operatingorder") {
          return OperatingOrderDetail.update({
            apiToken: controller.apiToken,
            pk: item.id,
            data: {
              action: "CANCEL",
              cancel_reason: JSON.stringify(params?.reason),
            },
          });
        }
      });
      const [cancelOR] = await Promise.all(promiseArray);
      console.log("cancel OR: ", cancelOR[1] ? cancelOR[1] : cancelOR[0]);
    }
    controller.setState({
      userTokenize: null,
      selectedAppointment: null,
      selectedBlock: null,
    });
    console.log(cancelPatientApp[1] ? cancelPatientApp[1] : cancelPatientApp[0]);
    RefreshAppointment(controller, params);
    params?.callback?.();
  } else if (params?.action === "PostponeAppointment") {
    const [cancelPatientApp] = await PatientAppointmentUpdate.patch({
      pk: state.selectedAppointment.id,
      data: {
        status: APPOINTMENT_STATUS.RECONFIRM,
        status_note: JSON.stringify(params?.reason),
        ...(state?.userTokenize?.employeeName ? { user: state?.userTokenize?.employeeName } : {}),
      },
      apiToken: controller.apiToken,
    });

    controller.setState({
      userTokenize: null,
      selectedAppointment: {
        ...state.selectedAppointment,
        status: cancelPatientApp?.status,
        status_note: cancelPatientApp?.status_note,
      },
    });

    RefreshAppointment(controller, params);

    params?.callback?.();
  } else if (params?.action === "GetWaitingList") {
    controller.setState({ waitingListLoading: true });

    const waitingList = await GetWaitingListData(controller, { forceFetch: true });
    // Send to ux
    if (waitingList === AxiosError.ERR_CANCELED) {
      controller.setState({ waitingListLoading: false });

      return;
    }

    controller.setState({ waitingList: [...waitingList] });

    console.log("GetWaitingList params?.selectedWaitingListId: ", params?.selectedWaitingListId);
    if (params?.selectedWaitingListId) {
      await SetScheduling(controller, {
        action: "GetWaitingListItem",
        waiting_list_id: params?.selectedWaitingListId,
        limit: 99999,
        includeCancel: params.includeCancel,
        ...params?.wlSearchParams,
      });
    }

    controller.setState({ waitingListLoading: false });
  } else if (params?.action === "GetWaitingListItem") {
    controller.setState({ waitingListItemLoading: true });

    const waitingList = await GetWaitingListData(controller, params);

    if (waitingList === AxiosError.ERR_CANCELED) {
      return;
    }

    controller.setState({
      waitingList: [...waitingList],
      waitingListItemLoading: false,
    });
  } else if (params?.action === "EditWaitingListItem") {
    // EditWaitingListItem
    const promiseArr = params?.list?.map((item: any) => {
      if (item.status !== "CANCEL") {
        return WaitingListItemDetail.patch({
          pk: item.id,
          data: {
            provider: params?.selectedProvider?.provider_id || item.provider,
            note: params?.note || item.note,
            ...(params?.completeCaseDate && {
              completed_at: beStringToMoment(params?.completeCaseDate).format(),
            }),
            type: params?.type || item.type,
            ...(params?.status && { status: params?.status }),
          },
          apiToken: controller.apiToken,
        });
      } else {
        console.warn("Can't edit because waitinglistItem have status: ", item.status);
        return;
      }
    });
    const assign = await Promise.all(promiseArr);
    // Refresh waiting list after edit
    await SetScheduling(controller, {
      action: "GetWaitingList",
      selectedWaitingListId: params?.selectedWaitingListId,
      wlSearchParams: params?.wlSearchParams,
    });
  } else if (params?.action === "FilterByAppointmentSummary") {
    controller.setState({ loadingFilterAppList: true });

    const result = await GetPatientAppointmentList(controller, params);

    controller.setState({
      appointmentSummaryList: result.items,
      loadingFilterAppList: false,
      appointmentSummaryPage: {
        ...state.appointmentSummaryPage,
        ...(params?.activePage
          ? {
              activePage: params?.activePage,
              total: Math.ceil(result.total / 10),
            }
          : {
              activePage: 1,
              total: Math.ceil(result.total / 10),
            }),
      },
    });
  } else if (params?.action === "GetAllWaitingList") {
    const waitingList = await WaitingListList.list({
      apiToken: controller.apiToken,
    });

    controller.setState({
      allWaitingList: waitingList?.[0],
    });
  } else if (params?.action === "SelectedWaitingList") {
    const waitingList = await WaitingListList.list({
      params: {
        division_id: params?.division?.id,
        limit: 99999,
      },
      apiToken: controller.apiToken,
    });

    controller.setState({
      allWaitingList: waitingList?.[0],
    });
  } else if (params?.action === "SendWaitingListToClinic") {
    // console.log(" SendWaitingListToClinic ", params);
    const promiseArr = params?.patient?.map((item: any) => {
      return WaitingListItemDetail.patch({
        pk: item.id,
        data: {
          waiting_list: params?.waitinglist?.id,
        },
        apiToken: controller.apiToken,
      });
    });

    const sendWaitingList = await Promise.all(promiseArr);
    // Refresh waiting list after send waitingList to Clinic
    SetScheduling(controller, {
      action: "GetWaitingList",
      selectedWaitingListId: params?.waitinglist?.id,
      wlSearchParams: params?.wlSearchParams,
    });
  } else if (params?.action === "SummaryStatic") {
    const staterDate = new Date().setMonth(new Date().getMonth() - 1);
    const startDateDefault = moment(staterDate)?.format(DATE_FORMAT);
    const endDateDefault = moment(new Date())?.format(DATE_FORMAT);

    if (!params?.card) {
      const waitingListTime = await WaitingListTime.get({
        params: {
          start_date: startDateDefault,
          end_date: endDateDefault,
          waiting_list_id: params?.waitingList?.id,
          division_id: params?.waitingList?.division,
          appointment: false,
        },
        apiToken: controller.apiToken,
      });

      const waitingListCancel = await WaitingListCancelReport.get({
        params: {
          start_date: startDateDefault,
          end_date: endDateDefault,
          waiting_list_id: params?.waitingList?.id,
          division_id: params?.waitingList?.division,
          appointment: false,
        },
        apiToken: controller.apiToken,
      });

      controller.setState({
        waitingQueueList: waitingListTime?.[0]?.items,
        staticCanCelQueueList: waitingListCancel?.[0]?.items,
      });
    }

    if (params?.card === "ModSummaryWaitingList") {
      const [r, e, n] = await WaitingListTime.get({
        params: {
          start_date: params?.startDateModWL,
          end_date: params?.endDateModWL,
          waiting_list_id: params?.waitingList?.id,
          division_id: params?.waitingList?.division,
          appointment: true,
        },
        apiToken: controller.apiToken,
      });
      controller.setState({
        waitingQueueList: r?.items,
      });
    } else if (params?.card === "ModSummaryStatic") {
      const [r, e, n] = await WaitingListCancelReport.get({
        params: {
          start_date: params?.startDateModST,
          end_date: params?.endDateModST,
          waiting_list_id: params?.waitingList?.id,
          division_id: params?.waitingList?.division,
          appointment: false,
        },
        apiToken: controller.apiToken,
      });
      controller.setState({
        staticCanCelQueueList: r?.items,
      });
    }
  }
  //  else if (params?.action === "SearchWaitingList") {
};

export const GetChairList: Handler = async (controller, params) => {
  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [["examinationType", {}]],
    },
  } as any);

  // Get Provider with provider_type__name == "Chair"
  const chairProvider = await ProviderList.list({
    params: { provider_type__name: "Chair", limit: 99999 },
  });
  console.log("chairProvider: ", chairProvider[1] ? chairProvider[1] : chairProvider[0]);
  const chairDict = Object.fromEntries(
    (chairProvider[0]?.items || []).map((item: any) => [item.object_id, item])
  );
  controller.data = {
    ...controller.data,
    chairDict: chairDict,
  };

  console.log("ChairDict: ", chairDict);
  // Get Chair
  const chair = await ChairList.list({
    params: { limit: 99999 },
    apiToken: controller.apiToken,
  });
  console.log(chair[1] ? chair[1] : chair[0]);
  const chairList = (chair[0]?.items || [])
    .map((item: any) => ({
      ...item,
      division_name: controller.data.divisionDict?.[item.division]?.name,
      status_name: CHAIR_STATUS?.[item.status as 1 | 2] || "",
      provider: chairDict?.[item.id]?.id || null,
    }))
    .filter((chair: any) => chair.provider);
  // console.log("chairList: ", chairList);
  controller.setState({
    chairList: chairList,
  });
};

export const GetChairWithDivisionServiceBlock: Handler = async (controller, params) => {
  // can filter with
  // division: <division_id>
  // show_all: boolean to show all chair with dsb
  const chairDSB = await GetAvailableChairListAPI.list({
    apiToken: controller.apiToken,
    params: {
      start_date: params.start_date || "",
      end_date: params.end_date || "",
      start_serial: params.start_serial || "",
      end_serial: params.end_serial || "",
      only_chair: params.only_chair || false,
      division: params.division || "",
      exclude: params.exclude || [],
    },
  });
  controller.setState({
    chairExistDSBList: chairDSB?.[0]?.items || [],
  });
};

export const BookChair: Handler = async (controller, params) => {
  const state = controller.getState();
  const dentistDsb = params?.event?.providers?.[0];
  console.log(dentistDsb);
  if (params?.chair && dentistDsb) {
    const block = await DivisionServiceBlockView.create({
      data: {
        division: dentistDsb?.division_id,
        provider: params?.chair,
        start_serial: dentistDsb?.start_serial,
        end_serial: dentistDsb?.end_serial,
        parent: dentistDsb?.dsb_id,
      } as any,
      apiToken: controller.apiToken,
    });
    console.log(block[1] ? block[1] : block[0]);
    if (block[1]) return console.log("Error booking chair");

    // debugger
    controller.handleEvent({
      message: "FilterSchedule",
      params: { divisionId: state.selectedDivision?.id },
    });
  }
};

export const HandlePrintChairBooking: Handler = async (controller, params) => {
  const state = controller.getState();
  const BTN_ACTION = "CardChair_PRINT_REPORT";

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTION]: "LOADING" },
  });

  if (params.booked.length > 0) {
    const booked: Record<string, any>[] = params.booked;
    const [staffMember] = await UserProfileAPI.retrieve({ apiToken: controller.apiToken });

    const chairMap = new Map<string, Record<string, any>>();

    for (const item of [...booked, ...params.providers]) {
      const existingItem = chairMap.get(item.doctor_provider_id) || {};

      chairMap.set(item.doctor_provider_id, { ...existingItem, ...item, dsb: item.dsb || null });
    }

    const fetchBookingData = async (dsbId: number) => {
      if (!dsbId) {
        return null;
      }

      try {
        const [data] = await DivisionServiceBlockDetail.patch({
          apiToken: controller.apiToken,
          pk: dsbId,
          extra: { division: controller.data.division },
        });

        return data as Record<string, any>;
      } catch (error) {
        console.warn(`No DSB ID found for ${dsbId}:`, error);

        return null;
      }
    };

    const bookingDataPromises = [...chairMap.values()].map(async (item) =>
      fetchBookingData(item.dsb?.doctor_dsb_id)
    );

    const responseList = await Promise.all(bookingDataPromises);

    const dentalChairBookings = responseList.filter(Boolean).flatMap((response) => {
      const serviceAppointments: Record<string, any>[] = response?.service_appointments || [];

      const chairInfo = [...chairMap.values()].find(
        (item) => item.dsb?.doctor_dsb_id === response?.id
      );

      return serviceAppointments.map((app): Record<string, any> => {
        const estimatedAt = moment(app?.estimated_at_iso);

        const periodTime =
          app?.estimated_at_iso && app.estimated_duration
            ? `${estimatedAt.format("HH:mm")} - ${estimatedAt
                .add(app.estimated_duration, "minutes")
                .format("HH:mm")}`
            : "";

        return {
          ...chairInfo,
          service_appointment: app,
          provider_object: response?.provider_object,
          period_time: periodTime,
        };
      });
    });

    const chairBookings = booked.flatMap((item) => {
      const filttered = dentalChairBookings.filter(
        (info) =>
          info.chair_provider_id === item.chair_provider_id &&
          info.doctor_provider_id === item.doctor_provider_id
      );

      const providerObject = responseList.find(
        (acc) => acc && acc.provider === item.doctor_provider_id
      )?.provider_object;

      return filttered.length ? filttered : [{ ...item, provider_object: providerObject }];
    });

    const sortChairs = (a: Record<string, any>, b: Record<string, any>): number => {
      const getOrder = (chair: Record<string, any>): number => {
        const hasDoctor = chair.doctor_provider_id !== null;
        const hasAppointments = chair.dsb?.appointments && chair.dsb.appointments.length > 0;

        const conditions: Record<string, number> = {
          "hasDoctor,hasAppointments": 0,
          "hasDoctor,noAppointments": 1,
          "noDoctor,noAppointments": 2,
        };

        const statuses = [
          hasDoctor ? "hasDoctor" : "noDoctor",
          hasAppointments ? "hasAppointments" : "noAppointments",
        ];

        return conditions[statuses.join(",")] ?? 3;
      };

      const orderA = getOrder(a);
      const orderB = getOrder(b);

      // ถ้าลำดับหลักเท่ากัน
      if (orderA === orderB) {
        // เรียงตาม chair_name ก่อน
        const chairCompare = (a.chair_name || "").localeCompare(b.chair_name || "");

        // ถ้า chair_name เท่ากัน จึงเรียงตาม position_name
        if (chairCompare === 0) {
          // เรียง position_name ตามปกติ แม้จะมีค่าว่าง
          const posA = a.provider_object?.position?.name || "";
          const posB = b.provider_object?.position?.name || "";

          // ถ้า position เท่ากัน เรียงตาม time
          if (posA === posB) {
            const timeA = a.period_time || "";
            const timeB = b.period_time || "";

            return timeA.localeCompare(timeB);
          }

          return posA.localeCompare(posB);
        }

        return chairCompare;
      }

      return orderA - orderB;
    };

    // Sort the chairs
    const sortedChairs = chairBookings.sort(sortChairs);

    console.log("New Object Data Report: ", sortedChairs, params.booked);

    const staffName =
      staffMember?.fullname ||
      `${staffMember?.first_name || ""} ${staffMember?.last_name || ""}`.trim();

    const docDef: any = await FormChairBooking({
      data: sortedChairs,
      ...params,
      division_name: responseList[0]?.division_name,
      staff: staffName,
    });

    const pdfMake = await getPdfMake(true);
    const pdf = pdfMake.createPdf(docDef);

    // Print PDF
    pdf.open();
    // #const blob = await new Promise<Blob>((resolve) => {
    //   pdf.getBlob((blob) => {
    //     resolve(blob);
    //   });
    // });
    // printURL(URL.createObjectURL(blob)); // import printURL

    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTION]: "SUCCESS" },
    });

    return;
  }

  console.warn("Yeti Have No Chair Booking in This Block!!");

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTION]: "ERROR" },
  });
};

export const AddBlock: Handler = async (controller, params) => {
  const state = controller.getState();
  const providerId = params?.selectedProvider?.provider_id;
  console.log(params);
  const getISODateTime = (date: string, isStart: boolean) => {
    const [start, end] = date.match(/\d+:\d+/g) || [];
    return `${date.match(/.* ?(?= )/g)?.[0]} ${isStart ? start : end}`;
  };

  let appointment: any = {};

  if (params.inactive && !state.openModConfirmAddBlock) {
    [appointment] = await PatientAppointmentView.list({
      apiToken: controller.apiToken,
      params: {
        start_datetime: getISODateTime(params.event.block_datetime, true),
        end_datetime: getISODateTime(params.event.block_datetime, false),
        provider_id: providerId,
      },
    });
    // status PatientAppointment 1 as REQUEST, 2 as CONFIRM
    appointment["items"] = appointment.items?.filter((item: any) => [1, 2].includes(item.status));
  }

  if (params.inactive && appointment?.items?.length && !state.openModConfirmAddBlock) {
    controller.setState({
      dsbAppointments: appointment?.items || [],
      openModConfirmAddBlock: {
        datetime: `${params.event.date} [${params.event.title}]`,
        provider_name: appointment?.items?.[0]?.display_info?.provider_name || "",
        number: appointment?.items?.length,
        params,
      },
    });
    return;
  }

  controller.setState({ openModConfirmAddBlock: null });

  if (
    providerId &&
    params?.event &&
    !params?.event?.providers.map((p: any) => p.doctor_provider_id).includes(providerId)
  ) {
    const [result] = await CreateDivisionServiceBlock(controller, {
      ...params,
      providerId,
    });

    // * Update ข้อมูลหลังจาก created
    if (result?.id) {
      await UpdateDivisionServiceBlock(controller, {
        ...params,
        doctorDsbId: result.id,
      });
    }
    // debugger
    // Refresh schedule
    controller.handleEvent({
      message: "FilterSchedule",
      params: { divisionId: state.selectedDivision?.id },
    });
  } else {
    await UpdateDivisionServiceBlock(controller, {
      ...params,
      doctorDsbId: params.event?.providers?.[0]?.doctor_dsb_id,
    });
    // debugger
    // Refresh schedule
    controller.handleEvent({
      message: "FilterSchedule",
      params: { divisionId: state.selectedDivision?.id },
    });
  }

  params.callback?.();
};

const getDivisionServiceBlockFilter: Handler = async (controller, params) => {
  const dsb = await Scheduling.get({
    command: "DivisionServiceBlockFilter",
    params: {
      divisions: [params.divisionId],
      start_date: "2000-01-01",
      end_date: "2100-12-29",
      only_new_patient: params.onlyNewPatient || null,
      specialty: params.specialty,
    },
    apiToken: controller.apiToken,
  });
  console.log("dsb: getDivisionServiceBlockFilter ", dsb);
  const state = controller.getState();
  if (dsb[1]) {
    if (params?.card) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params?.card]: dsb[1] },
      });
    }
  }

  const blockList = (dsb[0] || []).map((item: any, index: number) => {
    return {
      ...item,
      id: index,
      start: serialToDate(item.start_serial),
      end: serialToDate(item.end_serial),
      title: `${item.start_time} - ${item.end_time}`,
    };
  });

  return blockList;
};

export const FilterAppointmentSchedule: Handler = async (controller, params) => {
  // FilterAppointmentSchedule
  console.log("FilterAppointmentSchedule params: ", params);

  // Filter by division
  const blockList = await getDivisionServiceBlockFilter(controller, params);
  console.log("FilterSchedule blockList: ", blockList);

  controller.setState({
    blockList: blockList,
  });

  GetListSchedule(controller, params);
};

export const FilterSchedule: Handler = async (controller, params) => {
  // debugger
  console.log("FilterSchedule params: ", params);
  const state = controller.getState();
  if (Object.keys(params).includes("divisionId")) {
    if (params.divisionId === -1) {
      controller.setState({
        selectedDivision: null,
        // blockList: [],
        // providerBlockList: [],
      });
      return;
    } else {
      // If this is for initial, only do if divisionId is changed
      if (
        params?.initial &&
        params?.divisionId ===
          (state.selectedAppointmentDivision?.id || state.selectedDivision?.id) &&
        state.blockList?.length
      )
        return console.log("No need to load as division id is the same");

      // Clear calendar
      const division = (state.divisionList || []).find((div: any) => div.id === params.divisionId);
      controller.setState({
        selectedDivision: division,
        blockList: [],
        // providerBlockList: []
      });

      // debugger
      // Filter by division
      const blockList = await getDivisionServiceBlockFilter(controller, params);
      console.log("FilterSchedule blockList: ", blockList);

      controller.setState({
        blockList: blockList,
      });
      GetListSchedule(controller, params);

      // Example for future use: calling embedded-rust module
      // const schedulingRust = await Scheduling.list({
      //   command: "rust",
      //   params: {},
      //   apiToken: controller.apiToken
      // });
      // console.log(schedulingRust[1] ? schedulingRust[1] : schedulingRust[0]);
    }
  }
  if (Object.keys(params).includes("providerId")) {
    console.log(params);
    if (params?.providerId && typeof params?.providerId === "object") {
      const dsbChairs = await Promise.all(
        params?.providerId.map((item: any) => {
          return Scheduling.get({
            command: "DSBProviderFilter",
            params: {
              providerId: item,
              start_date: "2000-01-01",
              end_date: "2100-12-29",
            },
            apiToken: controller.apiToken,
          });
        })
      );

      let providerBlockList: any = [];
      dsbChairs.forEach((chair: any, index: number) => {
        if (chair[0]) {
          providerBlockList = providerBlockList.concat(
            (chair[0] || []).map((item: any, index: number) => {
              return {
                ...item,
                id: index,
                start: serialToDate(item.start_serial),
                end: serialToDate(item.end_serial),
                title: `${item.start_time} - ${item.end_time}`,
              };
            })
          );
        } else {
          console.log("Can't get chair: ", params?.providerId[index]);
        }
      });
      controller.setState({
        providerBlockList: providerBlockList,
      });
    } else if (params?.providerId) {
      const dsb = await Scheduling.get({
        command: "DSBProviderFilter",
        params: {
          providerId: params?.providerId,
          start_date: "2000-01-01",
          end_date: "2100-12-29",
        },
        apiToken: controller.apiToken,
      });
      console.log(dsb[1] ? dsb[1] : dsb[0]);
      const providerBlockList = (dsb[0] || []).map((item: any, index: number) => {
        return {
          ...item,
          id: index,
          start: serialToDate(item.start_serial),
          end: serialToDate(item.end_serial),
          title: `${item.start_time} - ${item.end_time}`,
        };
      });
      controller.setState({
        providerBlockList: providerBlockList,
      });
    } else {
      // Reset if providerId is undefined
      controller.setState({
        providerBlockList: [],
      });
    }
  }
  if (params?.range) {
    console.log(params.range);
  }
};

export const SearchPatient: Handler = async (controller, params) => {
  controller.setState({
    patientList: [],
    selectedPatient: null,
    appointmentList: [],
    selectedAppointment: null,
    selectedBlock: null,
  });

  // HN Search ,
  let [r, e, n]: any = [null, null, null];
  if (params?.type !== "mybplus") {
    if (/\d/.test(params?.patientSearchText)) {
      // มี ตัวเลข
      [r, e, n] = await PatientList.list({
        params: {
          hn: params?.patientSearchText,
          is_old_name_search: true,
          offset: 0,
          limit: 40,
        },
        apiToken: controller.apiToken,
      });
    } else {
      // ไม่มี ตัวเลข
      [r, e, n] = await PatientList.list({
        params: {
          name_search: params?.patientSearchText,
          is_old_name_search: true,
          offset: 0,
          limit: 40,
        },
        apiToken: controller.apiToken,
      });
    }
    if (e) return console.log("Error", e.toString());
    controller.setState({
      patientList: r?.items,
    });
  } else {
    /// My Bplus V3

    // patient_name = request.query_params.get('patient_name')
    // patient_dob = request.query_params.get('patient_dob')
    // division_id = request.query_params.get('division')
    // doctor_name = request.query_params.get('doctor_name')
    // start_date = request.query_params.get('start_date')
    // end_date = request.query_params.get('end_date')
    // is_telemed = request.query_params.get('telemed', '')

    let parameters = {
      ...(params?.hn && { hn: params?.hn }),
      ...(params?.name && { patient_name: params?.name }),
      ...(params?.doctor && { doctor_name: params?.doctor }),
      ...(params?.division && { division: params?.division }),
      ...(params?.startDate && {
        start_date: moment(beToAd(params?.startDate), "DD/MM/YYYY").toISOString(),
      }),
      ...(params?.endDate && {
        end_date: moment(beToAd(params?.endDate), "DD/MM/YYYY").toISOString(),
      }),
      ...(params?.hospital && { hospital: params?.hospital }),
      telemed: true,
      offset: 0,
      // limit: 40, เพราะต้องทำ filter หน้าบ้าน (feature 56561)
    };
    const [r, e, n] = await V3DashboardAppointmentView.get({
      params: parameters,
      apiToken: controller.apiToken,
    });
    if (e) return console.log("Error", e.toString());
    // console.log("r", r)
    controller.setState({
      patientListMyBplus: (r.items || r.results)?.map((item: any) => ({
        ...item,
        full_name: item.patient_name,
      })),
    });
  }
};

export const ClearAppointment: Handler = async (controller, params) => {
  controller.setState({
    patientList: [],
    selectedPatient: null,
    appointmentList: [],
    selectedAppointment: null,
    selectedBlock: null,
  });
};

export const SelectPatient: Handler = async (controller, params) => {
  console.log("SelectPatient params: ", params);
  if (params?.id) {
    controller.setState(
      {
        selectedPatient: params,
        appointmentList: [],
        selectedAppointment: null,
        selectedBlock: null,
      },
      async () => {
        // console.log(" RefreshAppointment params", params)
        await GetEncounterWithPatient(controller, { patientId: params.id });
        await RefreshAppointment(controller, { ...params, noGetWaiting: true });
        // RefreshAppointment(controller,  {params: {...params, noGetWaiting: true}});
      }
    );
  } else if (params === null) {
    controller.setState({
      selectedPatient: null,
      appointmentList: [],
      selectedAppointment: null,
      selectedBlock: null,
    });
  } else {
    controller.setState({
      selectedPatient: params,
      appointmentList: [],
      selectedAppointment: null,
      selectedBlock: null,
    });
  }
};

export const RefreshAppointment: Handler = async (controller, params) => {
  console.log("RefreshAppointment params: ", params);
  const state = controller.getState();
  // console.log('RefreshAppointment state.selectedPatient: ', state.selectedPatient);
  const appointment = await PatientAppointmentView.list({
    params: {
      ...(state.selectedPatient && { patient_id: state.selectedPatient?.id }),
      ...(params?.id && { patient_id: params?.id }),
      exclude_cancel: true,
    },
    apiToken: controller.apiToken,
  });
  console.log(
    "RefreshAppointment appointment[1/0]: ",
    appointment[1] ? appointment[1] : appointment[0]
  );

  if (appointment[1] && params?.card) {
    controller.setState({
      errorMessage: { ...state.errorMessage, [params.card]: appointment[1] },
    });
  }

  const items = appointment[0]?.items.map((item: any) => ({
    ...item,
  }));

  console.log("RefreshAppointment items: ", items);
  const selectedAppointmentId = state.selectedAppointment?.id;
  const appointmentUpdate = items?.find((item: any) => item.id === selectedAppointmentId) || null;
  console.log("RefreshAppointment appointmentUpdate: ", appointmentUpdate);

  controller.setState({
    appointmentList: items,
    ...(appointmentUpdate
      ? {
          // selectedAppointment: {...state.selectedAppointment, ...appointmentUpdate},
          preOrderList: [...(appointmentUpdate?.orders || [])],
        }
      : {}),
  });

  console.log(
    "FilterSchedule state.selectedAppointmentDivision?.id: ",
    state.selectedAppointmentDivision?.id
  );
  // debugger
  FilterAppointmentSchedule(controller, {
    divisionId: state.selectedAppointmentDivision?.id, // Feature 68560 (แยก division ออก จาก division กลาง)
    card: params?.card,
  });

  console.log(
    " Refresh waiting List params: ",
    params,
    "params?.noGetWaiting: ",
    params?.noGetWaiting
  );

  if (!params?.noGetWaiting) {
    SetScheduling(controller, {
      action: "GetWaitingList",
      selectedWaitingListId: params?.waitingList?.id,
    });
  }
};

export const CreatePatientAppointment: Handler = async (controller, params) => {
  console.log("CreatePatientAppointment params: ", params);
  const { selectedPatient, django, errorMessage, currentDoctor } = controller.getState();
  if (Number.isInteger(selectedPatient?.id) && Number.isInteger(django?.division)) {
    const [r, e, n] = await PatientAppointmentView.create({
      data: {
        patient: selectedPatient?.id,
        division: django?.division,
        waiting_list_item_id: params?.waitingListItem?.id,
        type: params?.waitingListItem?.type,
        is_or_type: params?.is_or_type,
        encounter_id: params?.encounter_id,
        ...(params?.consult_type ? { consult_type: params?.consult_type } : {}),
        ...(currentDoctor?.id ? { order_doctor: currentDoctor?.id } : {}),
      } as any,
      apiToken: controller.apiToken,
    });
    console.log(r);
    if (e) {
      if (params.card) {
        controller.setState({
          errorMessage: { ...errorMessage, [params.card]: e },
        });
      }
    } else {
      SelectAppointment(controller, { ...r, card: params.card });
    }

    RefreshAppointment(controller, params);
  }
};

// Ishealth-v3 port into CUDent
export const HandleActionPreOrderList: Handler = async (controller, params) => {
  const state = controller.getState();

  let cloneArr = [...state.preOrderList];

  if (params.action === "delete") {
    let itemTarget = cloneArr.find((item: any) => item.id === params.id);
    cloneArr = cloneArr.filter((item: any) => item.id !== params.id);

    // if(itemTarget && itemTarget.type === "imagingorder"){
    //   const order = await ImagingOrderDetail.delete({
    //     pk: params.id,
    //     apiToken: controller.apiToken,
    //     extra: {
    //       division: controller.data.division,
    //       device: controller.data?.device,
    //     },
    //   });

    //   if (order[1]) {
    //     // console.log("error: ", order[1]);
    //     if (params.card) {
    //       controller.setState({
    //         errorMessage: { ...state.errorMessage, [params.card]: order[1] },
    //       });
    //     }
    //     return;
    //   }
    // }
  }

  controller.setState({
    preOrderList: cloneArr,
  });
};

// Ishealth-v3 port into CUDent
export const ImagingRequestOrder: Handler = async (controller, params) => {
  const state = controller.getState();

  let encounterId = params.isAppointment
    ? state.selectedAppointment?.order_encounter || null
    : state.selectedEncounter?.id || state.selectedEncounterId || null;
  if (params?.action === "initial") {
    controller.handleEvent({
      message: "GetMasterData",
      params: {
        masters: [
          ["claimImaging", {}],
          ["unit", {}],
          ["eligibilityType", {}],
        ],
      },
    });

    if (params?.orderId) {
      const order = await ImagingOrderDetail.retrieve({
        pk: params.orderId,
        apiToken: controller.apiToken,
      });

      if (order[1]) {
        // console.log("error: ", order[1]);
        if (params.card) {
          controller.setState({
            errorMessage: { ...state.errorMessage, [params.card]: order[1] },
          });
        }
        return;
      }
      let items = order[0]?.order_items || [];
      const estimateProduct = await ImagingOrderEstimate.post({
        apiToken: controller.apiToken,
        data: {
          encounter: encounterId,
          order_items: [...items],
        },
        extra: {
          division: controller.data.division,
          device: controller.data?.device,
        },
      });

      if (estimateProduct[1]) {
        if (params.card) {
          controller.setState({
            errorMessage: {
              ...state.errorMessage,
              [params.card]: estimateProduct[1],
            },
          });
        }
        return;
      }

      items = items.map((item: any, index: number) => ({
        ...item,
        ...estimateProduct[0].orders[index],
        active: true,
        name: item?.product_name || "",
      }));

      return controller.setState({
        orderImagingList: items,
        imagingList: [],
      });
    } else {
      return controller.setState({
        imagingList: [],
        orderImagingList: [],
      });
    }
  } else if (params?.action === "search_product") {
    if (!encounterId) {
      const encounter = await EncounterList.list({
        apiToken: controller.apiToken,
        params: {
          patient: state.selectedPatient,
          active_only: true,
        },
      });

      if (encounter[1]) {
        console.log("error: ", encounter[1]);
        if (params.card) {
          controller.setState({
            errorMessage: {
              ...state.errorMessage,
              [params.card]: encounter[1],
            },
          });
        }
        return;
      }
      encounterId = encounter[0]?.items[0].id;
    }

    const product = await MiscellaneousList.list({
      apiToken: controller.apiToken,
      params: {
        encounter: encounterId,
        group_code: "XRAY",
        name: params.searchName,
        limit: 40,
      },
    });

    if (product[1]) {
      console.log("error: ", product[1]);
      if (params.card) {
        controller.setState({
          errorMessage: { ...state.errorMessage, [params.card]: product[1] },
        });
      }
      return;
    }

    controller.setState({
      imagingList: product[0]?.items || [],
      selectedEncounterId: encounterId,
    });
  } else if (params?.action === "add_product" && params?.item?.id) {
    // set pre process quantity
    let estimateItem = {
      ...params.item,
      product: params?.item.id,
      quantity: 1,
      encounter: encounterId,
      eligibility_type: 1,
      active: true,
      // charges_date_iso: moment().format("YYYY-MM-DD"),
    };
    delete estimateItem["id"];
    const estimateProduct = await ImagingOrderEstimate.post({
      apiToken: controller.apiToken,
      data: {
        encounter: encounterId,
        order_items: [estimateItem],
      },
      extra: {
        division: controller.data.division,
        device: controller.data?.device,
      },
    });

    if (estimateProduct[1]) {
      if (params.card) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.card]: estimateProduct[1],
          },
        });
      }
      return;
    }
    console.log("estimate item: ", estimateProduct[0], estimateItem);
    estimateItem = { ...estimateItem, ...estimateProduct[0]?.orders[0] };
    // add item
    let items = [...(state.orderImagingList || [])];
    let orderItems = [...items].filter(
      (item: any) => item.product === estimateItem.product && item.active
    );

    if (orderItems?.length > 0) {
      items = items.map((item: any) =>
        item.product === estimateItem.product
          ? { ...item, quantity: item.quantity + 1 }
          : { ...item }
      );
    } else {
      items.push(estimateItem);
    }
    console.log("orderImagingList: ", items);
    return controller.setState({
      imagingList: [],
      orderImagingList: items,
    });
  } else if (params?.action === "edit_order_item") {
    let targetItem = state.orderImagingList?.find(
      (item: any) => item.product === params.targetProduct
    );
    targetItem = {
      ...targetItem,
      [params.name]: params.value,
      // charges_date_iso: moment().format("YYYY-MM-DD"),
    };

    const estimateProduct = await ImagingOrderEstimate.post({
      apiToken: controller.apiToken,
      data: {
        encounter: encounterId,
        order_items: [targetItem],
      },
      extra: {
        division: controller.data.division,
        device: controller.data?.device,
      },
    });

    if (estimateProduct[1]) {
      if (params.card) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.card]: estimateProduct[1],
          },
        });
      }
      return;
    }

    let items = [...(state.orderImagingList || [])].map((item: any) =>
      item.product === params.targetProduct
        ? {
            ...item,
            [params.name]: params.value,
            ...estimateProduct[0]?.orders[0],
          }
        : { ...item }
    );

    return controller.setState({
      orderImagingList: items,
    });
  } else if (params?.action === "delete_order_item") {
    return controller.setState({
      orderImagingList: [...(state.orderImagingList || [])].map((item) =>
        item.product === params.targetProduct ? { ...item, active: false } : { ...item }
      ),
    });
  } else if (params?.action === "clear_data") {
    return controller.setState({
      orderImagingList: [],
      imagingList: [],
    });
  } else if (params?.action === "save_orders") {
    // console.log("save_orders", params)
    let order: any = null;
    if (params.orderId) {
      const orders = await ImagingOrderDetail.patch({
        pk: params.orderId,
        apiToken: controller.apiToken,
        data: {
          action: "EDIT",
          order_status: params.isAppointment ? 1 : 2,
          encounter: encounterId,
          order_items: [...(state.orderImagingList?.filter((item: any) => item.active) || [])],
          extra: {},
        },
        extra: {
          division: controller.data.division,
          device: controller.data?.device,
        },
      });

      if (orders[1]) {
        // console.log("error: ", order[1]);
        if (params.card) {
          controller.setState({
            errorMessage: { ...state.errorMessage, [params.card]: orders[1] },
          });
        }
        return;
      }
      order = orders[0];
    } else {
      const orders = await ImagingOrderlist.create({
        apiToken: controller.apiToken,
        data: {
          action: "ORDER",
          order_status: params.isAppointment ? 1 : 2,
          encounter: state.selectedAppointment?.order_encounter || state.selectedEncounterId,
          order_items: [...(state.orderImagingList?.filter((item: any) => item.active) || [])],
          extra: {},
        },
        extra: {
          division: controller.data.division,
          device: controller.data?.device,
        },
      });
      if (orders[1]) {
        if (params.card) {
          controller.setState({
            errorMessage: { ...state.errorMessage, [params.card]: orders[1] },
          });
        }
        return;
      }
      order = orders[0];
    }

    if (params.onSuccess) {
      let preOrderList = [...(state.preOrderList || [])];
      if (params.orderId) {
        preOrderList = preOrderList.map((item: any) =>
          item.id === params.orderId
            ? {
                ...item,
                specific_label_type: "imagingorder",
                type: "imagingorder",
                summary_detail: order.order_summary,
              }
            : { ...item }
        );
      } else {
        preOrderList.push({
          ...order,
          specific_label_type: "imagingorder",
          type: "imagingorder",
          summary_detail: order.order_summary,
        });
      }
      controller.setState(
        { preOrderList: preOrderList, orderImagingList: [], imagingList: [] },
        () => {
          params?.onSuccess();
        }
      );
    } else {
      controller.setState({ selectedImaging: order });
    }
  }
};

export const SelectAppointment: Handler = async (controller, params) => {
  const state = controller.getState();

  console.log("SelectAppointment params", params);
  const [appointment, medRecord, encounter] = await Promise.all([
    PatientAppointmentUpdate.retrieve({
      pk: params.id,
      apiToken: controller.apiToken,
    }),
    EncounterMedicalRecordList.list({
      pk: params.order_encounter,
      extra: {
        division: controller.data.division,
      },
      apiToken: controller.apiToken,
    }),
    EncounterDetail.retrieve({
      pk: params.order_encounter,
      apiToken: controller.apiToken,
    }),
  ]);

  if (appointment[1]) {
    if (params.card) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: appointment[1] },
      });
    }
  }

  console.log("SelectAppointment medRecord", medRecord);
  console.log("SelectAppointment appointment", appointment);

  let date = params.display_info?.start_datetime?.split("T")?.[0] || "";
  let start = params.display_info?.start_datetime?.split("T")?.[1]?.substring(0, 5) || "";
  let end = params.display_info?.end_datetime?.split("T")?.[1]?.substring(0, 5) || "";
  let datetime = `${date} ${start}-${end}`;

  const [response, error] = await PatientDetailView.retrieve({
    pk: appointment[0]?.patient,
    apiToken: controller.apiToken,
  });

  controller.setState(
    {
      selectedAppointment: {
        ...(params || {}),
        ...appointment[0],
        date: params.estimated_at?.split(" ")?.[0] || "",
        patient_name: `${params.patient_first_name} ${params.patient_last_name}`,
        detail: `${params.division_name || ""} (${params.display_info?.provider_name || ""} ${
          datetime || ""
        })`,
        datetime,
      },
      ...(!params?.isSave && { selectedBlock: null }),
      // selectedBlock: params?.isSave ? : null,
      selectedEncounter: {
        id: params.order_encounter,
        patient_gender_name: response?.gender_name,
        division: encounter[0]?.division,
        number: encounter[0]?.number,
        type: encounter[0]?.type,
      },
      preOrderList: appointment[0]?.orders || [],
      selectedEmr: medRecord[0]?.items?.[0],
      selectOperatingDSBChange: false,
      selectedPatient: { ...state.selectedPatient, ...response },
    },
    async () => {
      console.log("GetAppointmentDetail: ", params, appointment[0]);
      if (params.onFinished) {
        params.onFinished?.();
      }
      await GetAppointmentDetail(controller, {
        card: params.card,
        selectedAppointment: params,
        doctor_dsb_id: params?.division_service_block,
        estimated_at_iso: appointment[0]?.estimated_at_iso,
      });
    }
  );

  // const [r, e, n] = await DivisionServiceBlockView.list({
  //   params: {
  //     divisions: [controller.data.division]
  //   },
  //   apiToken: controller.apiToken
  // });
  // if (e) return console.log("Error", e.toString());
  // // console.log(r.items);
};

export const AssignAppointmentToBlock: Handler = async (controller, params) => {
  const state = controller.getState();
  console.log("AssignAppointmentToBlock params: ", params);
  // console.log('AssignAppointmentToBlock state.selectedAppointment?.id: ', state.selectedAppointment?.id);

  if (state.selectedAppointment?.id) {
    // controller.setState({ selectedBlock: params });
    console.log(
      "AssignAppointmentToBlock state.selectedAppointment?.id",
      state.selectedAppointment?.id
    );

    // Ishealth-v3 port into CUDent
    let datetime = `${adToBe(params.dsb.date, "YYYY-MM-DD")} ${params.doctor_start_time || ""}-${
      params?.doctor_end_time || ""
    }`;
    // console.log('datetime: ', datetime);
    controller.setState(
      {
        selectedBlock: params,
        selectedAppointment: {
          ...state.selectedAppointment,
          detail: `${params.division_name || ""} (${params.doctor_provider_name || ""} ${
            datetime || ""
          })`,
          division: params.division_id,
          division_name: params.division_name,
          dsb_full: false,
          extra: {
            chair: params.chair,
            appointmentFor: state.selectedAppointment.extra?.appointmentFor,
            jobToDo: state.selectedAppointment.extra?.jobToDo,
          },
        },
      },
      async () => {
        // console.log('AssignAppointmentToBlock state.selectedAppointment.detail: ', state.selectedAppointment?.detail);
        await GetAppointmentDetail(controller, {
          selectedAppointment: state.selectedAppointment,
          doctor_dsb_id: params.doctor_dsb_id,
          selectedBlock: params,
          card: params.card,
        });
      }
    );
  }

  // const update = await PatientAppointmentUpdate.patch({
  //   pk: state.selectedAppointment?.id,
  //   data: { division_service_block: params.dsb_id },
  //   apiToken: controller.apiToken
  // });
  // console.log(update[1] ? update[1] : update[0]);
  // RefreshAppointment(controller, params);
};

export const GetAppointmentDetail: Handler = async (controller, params) => {
  /// use params?.selectedBlock
  // console.log(" GetAppointmentDetail params: ", params);

  const state = controller.getState();
  // console.log('GetAppointmentDetail state: ', state);

  if (params?.doctor_dsb_id) {
    const dsb = await DivisionServiceBlockDetail.retrieve({
      pk: params.doctor_dsb_id,
      apiToken: controller.apiToken,
    });

    if (dsb[1]) {
      if (params.card) {
        controller.setState({
          errorMessage: { ...state.errorMessage, [params.card]: dsb[1] },
        });
      }
      return;
    }

    // console.log("DivisionServiceBlockDetail dsb: ", dsb);
    const reservedSlots = (dsb[0]?.service_appointments || [])
      .filter((app: any) => app?.id !== params.selectedAppointment?.id)
      .map((app: any) => [app?.estimated_at?.split(" ")?.[1], app?.estimated_duration])
      .map((slot: [string, number]) => [slot[0].substring(1, slot[0].length - 1), slot[1]]);
    console.log("available reservedSlots: ", reservedSlots);
    const slotLength = dsb[0]?.slot_length;
    const start = moment(serialToDate(dsb[0]?.start_serial));
    const end = moment(serialToDate(dsb[0]?.end_serial));
    const slotCount = Math.floor(end.diff(start, "minutes") / slotLength);
    // console.log('slotCount: ', slotCount);

    const slots = Array.from({ length: slotCount })
      .map((v: any, i: number) =>
        start
          .clone()
          .add(slotLength * i, "minutes")
          .format("HH:mm")
      )
      .filter((s: string) => {
        let date = new Date();
        let hourS = parseInt(s?.split(":")[0]);
        let minS = parseInt(s?.split(":")[1]);

        let slotStart = new Date(date.getTime());
        slotStart.setHours(hourS, minS, 0);
        let slotEnd = new Date(slotStart.getTime());
        slotEnd.setMinutes(slotEnd.getMinutes() + slotLength);
        let match = reservedSlots?.find((item: [string, number]) => {
          let reservedStart = new Date(date.getTime());
          let hour = parseInt(item[0]?.split(":")[0]);
          let min = parseInt(item[0]?.split(":")[1]);
          reservedStart.setHours(hour, min, 0);
          let reservedEnd = new Date(reservedStart.getTime());
          reservedEnd.setMinutes(reservedEnd.getMinutes() + item[1]);

          if (
            (reservedStart?.getTime() <= slotStart?.getTime() &&
              reservedEnd?.getTime() > slotStart?.getTime()) ||
            (reservedStart?.getTime() < slotEnd?.getTime() &&
              reservedEnd?.getTime() >= slotEnd?.getTime()) ||
            (reservedStart?.getTime() <= slotStart?.getTime() &&
              reservedEnd?.getTime() >= slotEnd?.getTime()) ||
            (reservedStart?.getTime() > slotStart?.getTime() &&
              reservedEnd?.getTime() < slotEnd?.getTime())
          ) {
            // overlap
            return true;
          } else {
            return false;
          }
        });

        // console.log('s: ', s, ' match: ', match);
        return !Array.isArray(match);
      })
      .map((s: string, index: number) => ({ key: index, value: s, text: s }));

    console.log("availableSlots: ", slots);

    const date = dsb[0]?.start_datetime.split("T")?.[0];
    const selectedBlock = params.selectedBlock;
    const datetime = selectedBlock
      ? `${formatADtoBEString(selectedBlock.date) || ""} ${selectedBlock.start_time} - ${
          selectedBlock.end_time
        }`
      : "";
    // console.log("start", start)
    // console.log("end", end)
    // console.log(" get slots?.[0]?.value: ", slots?.[0]?.value)
    // console.log(" state.selectedAppointment?.estimated_at", state.selectedAppointment?.estimated_at)
    controller.setState({
      availableSlots: slots,
      selectedAppointment: {
        ...state.selectedAppointment,
        date: date,
        slotLength: slotLength,
        start: start?.clone()?.format("HH:mm"),
        end: end?.clone()?.format("HH:mm"),

        /// use params?.selectedBlock
        ...(selectedBlock
          ? {
              ...(slots?.[0]?.value && date && { estimated_at: `${date} [${slots?.[0]?.value}]` }),
              datetime,
              detail: `${selectedBlock.division_name || ""} (${
                selectedBlock.doctor_provider_name || ""
              } ${datetime})`,
              display_info: {
                provider_name: selectedBlock.doctor_provider_name,
                // division: selectedBlock.division_id
              },
              division_name: selectedBlock.division_name,
              // division: selectedBlock.division_id
            }
          : {}),
      },
      ...(selectedBlock && { selectedBlock }),
    });
  } else {
    const slotLength = 5; // this may be hard code
    const start = moment(params.estimated_at_iso);
    const end = moment(params.estimated_at_iso).add(1, "days");
    const slotCount = Math.floor(end.diff(start, "minutes") / slotLength) - 1;

    const slots = Array.from({ length: slotCount })
      .map((v: any, i: number) =>
        start
          .clone()
          .add(slotLength * i, "minutes")
          .format("HH:mm")
      )
      .map((s: string, index: number) => ({ key: index, value: s, text: s }));
    controller.setState({
      availableSlots: slots,
    });
  }
};

export const PrintScheduling: Handler = async (controller, params) => {
  let docDef: any = { content: [] };
  if (params.form === "FormAppointmentSummary") {
    const state = controller.getState();

    if (params.data?.is_search && params.data?.orders?.[0]) {
      controller.setState({ loadingPrintAppList: true });

      const patientIds = params.data.orders.map((item: any) => item.patient_id);
      const promiseArr = Array.from(new Set(patientIds)).map((id) =>
        PatientDetailView.retrieve({ pk: id, apiToken: controller.apiToken })
      );
      const patientList = await Promise.all(promiseArr);

      params.data.orders = params.data.orders.map((item: any, index: number) => {
        const patient = patientList.find((patient: any) => patient[0]?.id === item.patient_id);
        return { ...item, address: patient[0]?.main_address_doc || "" };
      });
    }

    docDef = await FormAppointmentSummary({ data: params.data });
  } else if (params.form === "FormChartAppointmentSummary") {
    docDef = await FormChartAppointmentSummary({ data: params.data });
  } else if (params.form === "FormDentistSchedule") {
    docDef = await FormDentistSchedule({ data: params.data });
  } else if (params.form === "FormAppointmentDetail") {
    const [[patient], [user], [division], [doctor]] = await Promise.all([
      PatientDetailView.retrieve({
        apiToken: controller.apiToken,
        pk: params.data.patient,
      }),
      UserEmployeeDetailAPIView.retrieve({
        apiToken: controller.apiToken,
        pk: controller.data.user,
        extra: { division: controller.data.division },
      }),
      DivisionDetail.retrieve({
        pk: params.data.division,
        apiToken: controller.apiToken,
      }),
      DoctorDetail.retrieve({
        apiToken: controller.apiToken,
        pk: params.data?.display_info?.provider_object_id,
      }),
    ]);

    const [start, end] = params.data?.datetime ? params.data.datetime.match(/\d+:\d+/g) : ["", ""];

    const appointmentTime = params.data?.estimated_at_iso
      ? moment(params.data?.estimated_at_iso).format("HH:mm")
      : "";

    let ageEN = patient.full_age
      .replaceAll("ปี", "Y")
      .replaceAll("เดือน", "M")
      .replaceAll("วัน", "D");
    let doctorEN = `${doctor?.first_name_en || ""} ${doctor?.last_name_en || ""}`;

    docDef = await FormAppointmentDetail({
      data: {
        ...params.data,
        start,
        end,
        appointment_time: appointmentTime,
        full_age: patient.full_age,
        full_name: patient.full_name,
        staff: user?.full_name
          ? user?.full_name
          : `${user?.first_name || ""} ${user?.last_name || ""}`,
        divisionQR: division?.qrcode_div,
        divisionDetail: division?.division_detail,
        divisionDetailEN: division?.division_detail_en,
        divisionEN: division?.name_en,
        fullAgeEN: ageEN,
        fullNameEN: patient.full_name_en,
        doctorEN: doctorEN,
        staffEN: `${user?.first_name_en || ""} ${user?.last_name_en || ""}`,
      },
    });
    console.log("FormAppointmentDetail docDef: ", docDef);
  } else if (params.form === "FormWaitingList") {
    console.log("FormWaitingList params: ", params);

    // get Full Order
    const waitingListItem = await GetWaitingListItemList(controller, {
      ...params.currentQueryParams,
      limit: 9999,
      offset: 0,
    });

    if (waitingListItem[2].code === AxiosError.ERR_CANCELED) {
      return;
    }

    let allOrders: any[] = [];

    console.log("waitingListItem: ", waitingListItem);
    if (waitingListItem?.[0]?.items && !waitingListItem?.[1]) {
      allOrders = waitingListItem?.[0]?.items;
    }

    docDef = await FormWaitingList({
      data: {
        ...params.data,
        orders: allOrders,
      },
    });
  } else if (params.form === "FormAppointmentQueue") {
    docDef = await FormAppointmentQueue({
      data: params.data,
    });
  } else if (params.form === "FormAppointmentQueueEN") {
    docDef = await FormAppointmentQueueEN({
      data: params.data,
    });
  }
  if (["FormAppointmentSummary"].includes(params.form || "")) {
    (await getPdfMake()).createPdf(docDef).getDataUrl((dataUrl: any) => waterMarkToPdf(dataUrl));
    controller.setState({ loadingPrintAppList: false });
  }
  if (["FormWaitingList"].includes(params.form || "")) {
    (await getPdfMake())
      .createPdf(docDef)
      .getDataUrl((dataUrl: any) => waterMarkToPdf(dataUrl, true));
    controller.setState({ loadingPrintAppList: false });
  } else {
    (await getPdfMake(true)).createPdf(docDef).open();
  }
};

export const HandlePrintORAppointmentForm: Handler = async (controller, params) => {
  const state = controller.getState();

  if (!state.selectedOrOrder?.id) {
    console.log("No or data");
    return;
  }

  let isDoctor = state.django?.user?.role_types?.includes("DOCTOR");

  const [
    [patientRes, patientErr, patientNet],
    [doctorPrimaryRes, doctorPrimaryErr, doctorPrimaryNet],
    [divisionRes, divisionErr, divisionNet],
  ] = await Promise.all([
    PatientDetailView.retrieve({
      apiToken: controller.apiToken,
      pk: state.selectedPatient?.id,
    }),
    DoctorList.list({
      apiToken: controller.apiToken,
      params: { name_code: state.selectedOrOrder?.primary_doctor_name },
    }),
    DivisionList.list({
      params: { code: state.selectedOrOrder?.division_code, limit: 10 },
      apiToken: controller.apiToken,
    }),
  ]);

  let doctorPrimary = doctorPrimaryRes?.items?.[0];

  let startDateTime: string | Moment = state.selectedAppointment?.display_info?.start_datetime_iso
    ? moment(state.selectedAppointment?.display_info?.start_datetime_iso)
    : "";
  // let endDateTime = moment(state.selectedAppointment?.display_info?.end_datetime_iso) || ""
  let findMatchDSB = [...(state.operatingBlock || [])].find(
    (dsb: any) => dsb.operating_detail === state.selectedOrOrder.operating_detail
  );

  if (findMatchDSB && findMatchDSB.admit_case === "Admit ก่อนผ่าตัด") {
    startDateTime = moment(
      `${findMatchDSB?.admit_date}T${findMatchDSB?.admit_time}`,
      "YYYY-MM-DDTHH:mm"
    );
    // console.log(`findMatchDSB: ${findMatchDSB?.admit_date}T${findMatchDSB?.admit_time}`, startDateTime)
  }

  const caseDetail = state.selectedOrOrder?.case || {};
  let operatingType = "";
  // is_one_day_case: false
  // is_opd_case: false

  if (state.selectedOrOrder?.type) {
    if (state.selectedOrOrder?.type.includes("EL")) {
      operatingType = "Elective case";
    }
    if (state.selectedOrOrder?.type.includes("ER")) {
      operatingType = "Emergency case";
    }
  }

  let note = "-";

  if (state.selectedOrOrder?.case?.is_one_day_case) {
    note = "One Day Surgery";
  } else if (state.selectedOrOrder?.case?.is_ipd_case) {
    note = `นัดหมาย ${state.selectedOrOrder?.case?.ipd_case}`;
  }

  const patientFullName =
    params.printerLanguage === "EN" && patientRes?.full_name_en
      ? patientRes?.full_name_en
      : patientRes?.full_name;

  const patientBirthdate =
    params.printerLanguage === "EN"
      ? `${beToAd(patientRes.birthdate)?.format("DD/MM/YYYY")} ${
          patientRes.full_age
            ? `(${patientRes.full_age
                .replaceAll("ปี", "Y")
                .replaceAll("เดือน", "M")
                .replaceAll("วัน", "D")})`
            : ""
        }`
      : `${patientRes.birthdate} ${patientRes.full_age ? `(${patientRes.full_age})` : ""}`;

  const appointmentDate = adToBeWithSetFormat(
    (startDateTime as Moment)?.format("YYYY-MM-DD"),
    "YYYY-MM-DD",
    "DD MMMM",
    params.printerLanguage === "EN" ? "en" : "th"
  );

  const appointmentTime = (startDateTime as Moment)?.format("HH:mm");

  const doctorName =
    params.printerLanguage === "EN" && (doctorPrimary?.first_name_en || doctorPrimary?.last_name_en)
      ? `${
          (state.masterOptions?.prenameEn || []).find(
            (items: any) => items.value === doctorPrimary?.pre_name_en
          )?.text || ""
        } ${doctorPrimary?.first_name_en || ""} ${doctorPrimary?.last_name_en || ""}`
      : doctorPrimary?.full_name;

  const operatingCase = caseDetail.is_ipd_case
    ? "IPD"
    : caseDetail.is_one_day_case
    ? "One day"
    : caseDetail.is_opd_case && "OPD";

  const admitDate = adToBeWithSetFormat(
    moment(beToAd(state.selectedOrOrder?.case?.app_admit_date))?.format("YYYY-MM-DD"),
    "YYYY-MM-DD",
    "DD MMMM",
    params.printerLanguage === "EN" ? "en" : "th"
  );

  const admitTime =
    state.selectedOrOrder?.case?.app_admit_time || "ไม่ระบุเวลา";

  const divisionName =
    params.printerLanguage === "EN"
      ? divisionRes?.items?.[0]?.name_en
      : divisionRes?.items?.[0]?.name;

  const divisionDetail =
    params.printerLanguage === "EN"
      ? divisionRes?.items?.[0]?.division_detail_en
      : divisionRes?.items?.[0]?.division_detail;

  let data = {
    patientHn: patientRes?.hn || "",
    patientFullName: patientFullName || "",
    patientBirthday: patientBirthdate || "",
    patientCoverage: patientRes?.coverage || "",
    appointmentDate:
      `${appointmentDate} ${
        Number(moment(startDateTime)?.format("YYYY")) +
        Number(params.printerLanguage === "EN" ? 0 : 543)
      }` || "ไม่ระบุวัน",
    appointmentTime: appointmentTime || "ไม่ระบุเวลา",
    doctor: doctorName || "",
    division:
      config.OR_APPOINTMENT_FORM === "cudent" &&
      state.selectedOrOrder?.anesthesia_method_name === "GA"
        ? "หออภิบาลผู้ป่วย (GA)"
        : `${state.selectedOrOrder?.division_name} (${state.selectedOrOrder?.anesthesia_method_name})` ||
          "",
    appointmentType: "นัดหมายผ่าตัด",
    operatingType: `${operatingType} (${operatingCase})`,
    operatingCase: operatingCase,
    note: note,
    remark: state.selectedOrOrder?.remark?.split("\n") || [],
    contentId: state.selectedAppointment?.content_id || "",
    staff: state.django?.user?.full_name || "",
    npo: `* ${
      state.selectedOrOrder?.anti_platelet_coagulant?.npo === "NO_NPO"
        ? "ไม่ต้อง NPO"
        : state.selectedOrOrder?.anti_platelet_coagulant?.npo === "NPO"
        ? "NPO ทั้งหมด"
        : state.selectedOrOrder?.anti_platelet_coagulant?.npo === "NO_NPO_DRUG"
        ? "NPO ยกเว้น ยา"
        : ""
    }  `,
    npoDetail:
      state.selectedOrOrder?.anti_platelet_coagulant?.npo !== "NO_NPO"
        ? `${state.selectedOrOrder?.anti_platelet_coagulant?.npo_date || ""} ${
            state.selectedOrOrder?.anti_platelet_coagulant?.npo_time || ""
          }`
        : "",
    admitDate:
      `${admitDate} ${
        Number(beToAd(state.selectedOrOrder?.case?.app_admit_date)?.format("YYYY")) +
        Number(params.printerLanguage === "EN" ? 0 : 543)
      }` || "ไม่ระบุวัน",
    admitTime: admitTime,
    anesthesiaMethodName: state.selectedOrOrder?.anesthesia_method_name || "",
    divisionName: divisionName || "",
    divisionDetail: divisionDetail || "",
    language: params.printerLanguage || "TH",
  };

  const promiseArr: Promise<string>[] = [];
  const pdfMake = await getPdfMake(true);

  const createPDFBase64 = async (data: any): Promise<string> => {
    let docDef: any = { content: [] };
    docDef = await ORAppointmentForm(data);
    return new Promise(async (resolve, reject) =>
      pdfMake.createPdf(docDef).getBase64((result: any) => resolve(result))
    );
  };

  promiseArr.push(createPDFBase64({ ...data }));

  if (state.selectedOrOrder?.blood_request_summary === "Yes") {
    const list = state.appointmentList || [];
    const orOrderAppointmentId: string = state.selectedOrOrder?.appointment_id;
    const appointmentId = list.find((app) => app.content_id === Number(orOrderAppointmentId))?.id;
    const bloodTestAppointment = list.find((app) => app.main_patient_appointment === appointmentId);
    // * วันดงที่นัดหมายเจาะเลือด: ถ้ายังไม่ได้นัดหมายเจาะเลือดให้แสวันที่ของวันที่ผ่าตัด
    const crossMatchDate =
      bloodTestAppointment?.estimated_at_iso ||
      state.selectedAppointment?.display_info?.start_datetime_iso;

    let fullThaiCrossMatchDate = "ไม่ระบุวัน";
    let crossMatchTime = "ไม่ระบุเวลา";

    if (crossMatchDate) {
      fullThaiCrossMatchDate = adToBeWithSetFormat(
        moment(crossMatchDate).format("YYYY-MM-DD"),
        "YYYY-MM-DD",
        "DD MMMM YYYY",
        "th"
      );
      crossMatchTime = moment(crossMatchDate).format("HH:mm");
    }

    promiseArr.push(
      createPDFBase64({
        ...data,
        appointmentDate: fullThaiCrossMatchDate,
        appointmentTime: crossMatchTime,
        appointmentType: "นัดหมายเจาะเลือด",
        division: "ห้องแลป",
        title: "บัตรนัดหมายเจาะเลือด สำหรับการขอเลือดผ่าตัด",
        hideNote: true,
        hideBeforeAnesthesia: true,
      })
    );
  }

  const pdfBase64 = await Promise.all(promiseArr);

  const pdfDoc = await PDFDocument.create();

  for (const base64 of pdfBase64) {
    const doc = await PDFDocument.load(base64);
    const copiedPages = await pdfDoc.copyPages(doc, doc.getPageIndices());

    for (const page of copiedPages) {
      pdfDoc.addPage(page);
    }
  }

  const base64Data = await pdfDoc.saveAsBase64();

  const blob = base64toBlob("data:application/pdf;base64," + base64Data);

  const bloburl = URL.createObjectURL(blob);
  window.open(bloburl);
};

export const UserTokenize: Handler = async (controller, params) => {
  var state = controller.getState();

  console.log("UserTokenize", params);
  if (params?.action === "clear") {
    controller.setState({
      userTokenize: {
        ...state.userTokenize,
        token: "",
        employeeName: "",
        employeeCode: "",
        loading: false,
      },
    });
  } else if (
    params?.action &&
    ["CheckUserToken", "GetDoctorCode"].includes(params?.action) &&
    params?.code?.length > 0
  ) {
    console.log("UserTokenize", params);

    controller.setState({
      userTokenize: {
        ...state.userTokenize,
        loading: true,
        error: null,
        employeeName: "",
      },
    });

    if (params.action === "CheckUserToken") {
      const [response, error, network] = await UserTokenizeView.post({
        apiToken: controller.apiToken,
        data: { code: params?.code },
      });

      state = controller.getState();

      console.log("UserTokenizeView response", response);
      console.log("UserTokenizeView error", error);

      if (error) {
        controller.setState({
          userTokenize: {
            ...state.userTokenize,
            error: error,
            loading: false,
          },
        });
        return;
      }
      // console.log("decodeURIComponent", decodeURIComponent(atob(response["token"])))
      controller.setState({
        userTokenize: {
          ...state.userTokenize,
          token: response?.token || "",
          employeeName: decodeURIComponent(atob(response?.token.split(".")[1])) || "",
          employeeCode: params?.code,
          loading: false,
        },
      });
    } else if (params.action === "GetDoctorCode") {
      const [response, error, network] = await DoctorList.list({
        apiToken: controller.apiToken,
        params: { employee_code: params?.code },
      });

      state = controller.getState();

      console.log("response: ", response);
      if (error) {
        controller.setState({
          userTokenize: {
            ...state.userTokenize,
            error: error,
            loading: false,
          },
        });
        return;
      }
      // console.log("decodeURIComponent", decodeURIComponent(atob(response["token"])))

      if (response?.items?.length === 1) {
        let doctor = response?.items?.find(Boolean);

        controller.setState({
          userTokenize: {
            ...state.userTokenize,
            token: "-",
            employeeName: doctor.full_name,
            employeeCode: params?.code,
            doctorId: doctor.id,
            loading: false,
          },
        });
      } else {
        console.warn("มี doctor มากกว่า 1 คน ใน employee Code นี้");
        controller.setState({
          // userTokenize: {
          //   ...state.userTokenize,
          //   token: "-",
          //   employeeName: doctor.full_name,
          //   employeeCode: params?.code,
          //   doctorId: doctor.id,
          //   loading: false,
          // }
          userTokenize: {
            ...state.userTokenize,
            error: "มี doctor มากกว่า 1 คน ใน employee code นี้",
            loading: false,
          },
        });
      }
    }
  }
};
export const HandleGetUsername: Handler = async (controller, params) => {
  const { django } = controller.getState();
  if (!params?.callback) {
    return;
  }
  const user = await UserDetailAPIView.retrieve({
    apiToken: controller.apiToken,
    pk: django.user.id,
  });
  if (user?.[0]) {
    params.callback(user[0].username);
  }
};

export const getDoctorNoteList: Handler = async (controller, params) => {
  const [r, e, n] = await DoctorNoteTemplateList.list({
    apiToken: controller.apiToken,
    params,
  });

  return r?.items || [];
};

export const getDoctorNoteGroupList: Handler = async (controller, params) => {
  const [r, e, n] = await DoctorNoteTemplateGroupList.list({
    apiToken: controller.apiToken,
    params,
  });

  return r?.items || [];
};

export const GetSummaryDoctor: Handler = async (controller, params) => {
  const dateDefault = moment(new Date())?.format(DATE_FORMAT);

  const [r, e, n] = await DoctorReportList.get({
    params: {
      start_date: dateDefault,
      end_date: dateDefault,
    },
    apiToken: controller.apiToken,
  });

  let filter = r?.items?.filter((item: any) => item.doctor__first_name !== null);

  controller.setState({
    summaryDoctorList: filter,
  });

  if (params?.action === "search") {
    const [r, e, n] = await DoctorReportList.get({
      params: {
        start_date: params?.data.startDate,
        end_date: params?.data?.endDate,
        doctor_code: params?.data?.doctor?.code,
      },
      apiToken: controller.apiToken,
    });

    let filter = r?.items?.filter((item: any) => item.doctor__first_name !== null);

    controller.setState({
      summaryDoctorList: filter,
    });
  }
};

export const CreatePatientAppointmentForBloodBank: Handler = async (controller, params) => {
  const { selectedPatient, selectedDivision, selectedAppointment, loadingStatus } =
    controller.getState();
  if (Number.isInteger(selectedPatient?.id) && Number.isInteger(params.divisionId)) {
    const patientAppointment = await PatientAppointmentView.create({
      data: {
        main_patient_appointment: selectedAppointment?.id,
        patient: selectedPatient?.id,
        division: params.divisionId,
      } as any,
      apiToken: controller.apiToken,
    });

    if (patientAppointment[0]) {
      const medicalRecord = await EncounterMedicalRecordList.list({
        pk: patientAppointment[0]?.order_encounter,
        extra: {
          division: controller.data.division,
        },
        apiToken: controller.apiToken,
      });

      const labData = await CentralLabOrderList.create({
        data: {
          action: "ORDER",
          allow_duplicate_flag: false,
          emr: medicalRecord?.[0].id,
          encounter: patientAppointment[0]?.order_encounter,
          note: params.note,
          order_items: params.order_items || [],
          order_status: 1,
          is_advance: false,
        },
        extra: { division: controller.data.division },
        apiToken: controller.apiToken,
      });

      if (labData[0]) {
        /// save
        // save other treatment, lab, imaging บันทึกส่วน
        let orderDict: Record<string, string> = {};
        let preLabList =
          labData[0]?.order_list?.map((item: any) => ({
            ...item,
            summary_detail: item.order_summary,
            type: "centrallaborder",
          })) || [];
        if (preLabList) {
          for (const item of preLabList) {
            orderDict[item.id] = item.type;
          }
        }

        const a = await PatientAppointmentUpdate.patch({
          pk: patientAppointment[0].id,
          apiToken: controller.apiToken,
          data: {
            order_dict: orderDict,
          } as any,
        });

        if (params.refreshPage) {
          controller.setState({
            loadingStatus: { ...loadingStatus, [params?.sequence]: false },
          });

          if (params.onSuccess) {
            params.onSuccess();
          }
          await RefreshAppointment(controller, params);
          await SelectAppointment(controller, {
            ...patientAppointment[0],
            isSave: true,
          });
        }
      }
    }
  }
};

export const GetSummaryWaitingQueue: Handler = async (controller, params) => {
  const staterDate = new Date().setMonth(new Date().getMonth() - 1);
  const startDateDefault = moment(staterDate)?.format(DATE_FORMAT);
  const endDateDefault = moment(new Date())?.format(DATE_FORMAT);

  const [r, e, n] = await WaitingQueueReport.get({
    params: {
      start_date: startDateDefault,
      end_date: endDateDefault,
    },
    apiToken: controller.apiToken,
  });

  controller.setState({
    summaryWaitingQueueList: r?.items,
  });

  if (params?.action === "search") {
    const [r, e, n] = await WaitingQueueReport.get({
      params: {
        start_date: params?.data.startDate,
        end_date: params?.data?.endDate,
        division_id: params?.data?.division?.id,
      },
      apiToken: controller.apiToken,
    });

    controller.setState({
      summaryWaitingQueueList: r?.items,
    });
  }
};

export const GetSummaryStatistics: Handler = async (controller, params) => {
  const dateDefault = moment(new Date())?.format(DATE_FORMAT);

  const [r, e, n] = await AppointmentReportList.get({
    params: {
      start_date: dateDefault,
      end_date: dateDefault,
    },
    apiToken: controller.apiToken,
  });

  controller.setState({
    summaryStatisticsList: r?.items,
  });

  if (params?.action === "search") {
    const [r, e, n] = await AppointmentReportList.get({
      params: {
        start_date: params?.data.startDate,
        end_date: params?.data?.endDate,
        division_id: params?.data?.division?.id,
      },
      apiToken: controller.apiToken,
    });

    controller.setState({
      summaryStatisticsList: r?.items,
    });
  }
};

export const GetEncounterWithPatient: Handler = async (controller, params) => {
  const result = await EncounterPatientListOptimized.get({
    apiToken: controller.apiToken,
    params: {
      patient: params.patientId,
      ...(params.excludeCancel && { exclude_canceled: true }),
    },
  });

  controller.setState({
    encounterPatientList: (result[0]?.items || []).filter(
      (item: any) => !item.is_expired && item.status !== "CANCELED"
    ),
  });
};

export const HandleInitialConsultData: Handler = async (controller, params) => {
  console.log("HandleInitialConsultData params: ", params);

  const { consultData, consultDetail } = controller.getState();

  if (params?.action === "INITIAL") {
    let consultDetailData = {
      id: null,
      consult_response_id: null,
      note: "",
      sourceDivision: null,
      sourceProviderDoctor: null,
      destinationDivision: null,
      destinationDivisionNameCode: "",
      destinationProviderDoctor: null,
      consult_response_suggestion: "",
      status: "",
      date: "",
      time: "",
    };

    let consultId = params.consultId || null;
    console.log("HandleInitialConsultData INITAIL DATA: ", consultDetailData);
    if (consultId) {
      const [resp, err, net] = await DoctorConsultAppointmentDetail.retrieve({
        apiToken: controller.apiToken,
        pk: params.consultId,
        extra: {
          device: controller.data.device,
          division: controller.data.division,
        },
      });

      let doctorProviderId = null;
      if (resp?.consulted_doctor_name) {
        // let targetDoctor = (controller.data as any)?.masterdata?.doctor?.find(
        //   (item: any) => item?.id === resp?.consulted_doctor
        // );
        doctorProviderId =
          consultData?.providerDoctorOptions?.find(
            (i: any) => i.text === resp?.consulted_doctor_name
          )?.value || null;
      }
      // console.log("doctorProviderId: ", doctorProviderId, consultData?.providerDoctorOptions, resp?.consulted_doctor_name)
      if (!err) {
        consultDetailData = {
          ...resp,
          id: resp?.consult_id,
          consult_response_id: resp?.consult_response_id,
          sourceDivision: resp?.order_div || null,
          sourceProviderDoctor: resp?.order_by || null,
          destinationDivision: resp?.division || null,
          destinationProviderDoctor: doctorProviderId,
          status: resp?.status,
          note: resp.note,
          consult_response_suggestion: resp.consult_response_suggestion || "",
          date: formatDate(moment(resp?.app_date, "DD/MM/YYYY")) || "",
          time: moment(resp?.app_start_time, "HH:mm:ss").format("HH:mm") || "",
        };
      }
    }

    controller.setState(
      {
        consultDetail: consultDetailData,
      },
      async () => {
        if (consultDetailData?.destinationDivision) {
          await HandleInitialConsultData(controller, {
            action: "PROVIDER",
            divisionId: consultDetailData?.destinationDivision,
          });
        }
      }
    );

    return;
  } else if (params?.action === "PROVIDER") {
    const dsb = await Scheduling.get({
      command: "DivisionServiceBlockFilter",
      params: {
        divisions: [params.divisionId],
        start_date: "2000-01-01",
        end_date: "2100-12-29",
      },
      apiToken: controller.apiToken,
    });

    const availableConsultDoctors = [...((controller.data as any)?.masterData?.doctor || [])]
      .filter((doctor: any) => doctor.is_consult)
      .map((doctor: any) => doctor.full_name);

    console.log("availableConsultDoctors: ", availableConsultDoctors);

    const doctorOptions = (dsb?.[0] || [])
      .map((item: any) => item.providers.map((p: any) => p.dsb))
      .flat(2)
      .filter((item: any) => item.doctor_provider_type === "Doctor")
      .reduce((result: any, item: any) => {
        if (availableConsultDoctors.includes(item.doctor_provider_name)) {
          if (
            result &&
            !result?.find((i: any) => i.doctor_provider_id === item.doctor_provider_id)
          ) {
            result.push(item);
          }
          if (!result && item?.doctor_provider_id) {
            return [item];
          }
        }
        return result;
      }, [])
      ?.map((item: any) => ({
        key: item.doctor_provider_id,
        text: item.doctor_provider_name,
        value: item.doctor_provider_id,
      }));

    let doctorProviderId = null;
    let doctorProviderMapping = doctorOptions?.map((item: any) => item.value);
    if (
      consultDetail?.consulted_doctor_name &&
      !doctorProviderMapping.includes(consultDetail?.destinationProviderDoctor)
    ) {
      // let targetDoctor = (controller.data as any)?.masterdata?.doctor?.find(
      //   (item: any) => item?.id === resp?.consulted_doctor
      // );
      doctorProviderId =
        doctorOptions?.find((i: any) => i.text === consultDetail?.consulted_doctor_name)?.value ||
        null;
    }

    controller.setState({
      consultData: {
        ...consultData,
        divisionServiceBlockOptions: dsb?.[0] || [],
        providerDoctorOptions: doctorOptions || [],
      },
      consultDetail: {
        ...consultDetail,
        ...(params.divisionId !== consultDetail?.destinationDivision
          ? { destinationProviderDoctor: null }
          : doctorProviderId
          ? { destinationProviderDoctor: doctorProviderId }
          : {}),
      },
    });
  }
};

export const HandleSetConsultDetail: Handler = async (controller, params) => {
  const { consultDetail } = controller.getState();
  if (params?.name === "destinationDivision") {
    const divNameCode =
      (params.divOption || []).find((item: any) => item.value === params.value)?.text || "";

    controller.setState(
      {
        consultDetail: {
          ...consultDetail,
          [params?.name]: params.value,
          destinationDivisionNameCode: divNameCode,
        },
      },
      async () => {
        await HandleInitialConsultData(controller, {
          divisionId: params.value,
          action: "PROVIDER",
        });
      }
    );
  } else if (params?.name === "destinationProviderDoctor") {
    controller.setState(
      {
        consultDetail: {
          ...consultDetail,
          [params?.name]: params.value,
        },
      },
      async () => {
        await HandleInitialConsultData(controller, {
          doctor: params.value,
          action: "DIVISION_SERVICE_BLOCK",
        });
      }
    );
  } else {
    controller.setState({
      consultDetail: {
        ...consultDetail,
        [params?.name]: params.value,
      },
    });
  }
};

export const HandleMakeConsultAppointment: Handler = async (controller, params) => {
  const defaultCard = params?.card || "CardPatientAppointment";
  const {
    consultDetail,
    selectedAppointment,
    consultData,
    preOrderList,
    errorMessage,
    buttonLoadCheck,
    userTokenize,
  } = controller.getState();

  if (params?.action === "MAKE_APPOINTMENT") {
    // params isToday use to create immediatly encounter and set order_status to PENDING
    const masterData = (controller.data as any).masterData;
    controller.setState({
      buttonLoadCheck: {
        ...buttonLoadCheck,
        [params.buttonLoadCheck]: "LOADING",
      },
    });
    console.log(
      "HandleMakeConsultAppointment",
      consultDetail,
      params.selectedEvent,
      selectedAppointment
    );
    const selectedEvent = params.selectedEvent;

    let targetDoctor = null;
    if (consultDetail?.destinationProviderDoctor) {
      const doctor =
        consultData?.providerDoctorOptions?.find(
          (i: any) => i.value === consultDetail?.destinationProviderDoctor
        )?.text || "";
      targetDoctor = masterData?.doctor?.find((item: any) => item.full_name === doctor);
    } else if (params?.doctorProvider) {
      const doctor =
        consultData?.providerDoctorOptions?.find((i: any) => i.value === params?.doctorProvider)
          ?.text || "";
      targetDoctor = masterData?.doctor?.find((item: any) => item.full_name === doctor);
    }

    let consultOrder: any = [null, null];
    if (consultDetail?.id) {
      consultOrder = await DoctorConsultOrderDetail.patch({
        pk: consultDetail?.id,
        apiToken: controller.apiToken,
        data: {
          action: "ORDER",
          division: consultDetail?.destinationDivision || null,
          consulted_doctor: targetDoctor?.id || null,
          // order_encounter: selectedAppointment?.order_encounter,
          note: consultDetail?.note,
        },
        extra: {
          device: (controller.data as any).device,
          division: controller.data.division,
        },
      });
    } else {
      consultOrder = await DoctorConsultOrderList.create({
        apiToken: controller.apiToken,
        data: {
          action: "ORDER",
          division: consultDetail?.destinationDivision || null,
          consulted_doctor: targetDoctor?.id || null,
          encounter: selectedAppointment?.order_encounter,
          emr: selectedAppointment?.order_emr,
          order_encounter: selectedAppointment?.order_encounter,
          note: consultDetail?.note,
          order_status:
            selectedAppointment?.type_display === "นัดหมาย Consult IPD"
              ? ORDER_STATUS.PENDING
              : ORDER_STATUS.APPOINTMENT,
          urgency: "C",
        },
        extra: {
          device: (controller.data as any).device,
          division: controller.data.division,
        },
      });
    }

    if (consultOrder[1]) {
      controller.setState({
        errorMessage: { ...errorMessage, [defaultCard]: consultOrder[1] },
        buttonLoadCheck: {
          ...buttonLoadCheck,
          [params.buttonLoadCheck]: "ERROR",
        },
      });
      return;
    }

    let orderDict: Record<string, string> = {};
    if (preOrderList?.length) {
      for (const item of preOrderList) {
        if (item.specific_label_type === "imagingorder") {
          orderDict[item.id] = "imagingorder";
        } else {
          orderDict[item.id] = item.type;
        }
      }
    }

    orderDict[consultOrder?.[0]?.id] = DOCTOR_CONSULT_ORDER;

    const dataForUpdateAppointment = {
      division: consultDetail?.destinationDivision || null,
      provider: targetDoctor ? selectedEvent?.doctor_provider_id : null,
      estimated_duration: targetDoctor ? selectedEvent?.doctor_dsb_slot_length : 0,
      division_service_block: targetDoctor ? selectedEvent.doctor_dsb_id : selectedEvent.dsb_id,
      order_dict: orderDict,
      ...(targetDoctor
        ? {
            estimated_at_iso: moment(
              new Date(
                `${selectedEvent?.date} ${
                  params?.time || (params?.isToday ? selectedEvent?.start_time : null)
                }`
              )
            ).toISOString(),
          }
        : {}),
      ...(params?.isToday && selectedAppointment?.type_display === "นัดหมาย Consult OPD" // Case select on today
        ? { status: 2 }
        : consultDetail?.id && selectedAppointment?.status === 2 // Case edit on confirm patientapp
        ? { status: 1 }
        : {}),
    };

    const patientAppUpdate = await PatientAppointmentUpdate.patch({
      pk: selectedAppointment.id,
      apiToken: controller.apiToken,
      data: {
        ...(params.repetition_note && {
          repetition_note: params.repetition_note,
          ...(userTokenize?.employeeName && {
            user: userTokenize?.employeeName,
          }),
        }),
        ...dataForUpdateAppointment,
      } as any,
    });

    const isDuplicate = handleCheckDuplicateAppointment(controller, {
      error: patientAppUpdate[1],
      payload: params,
      message: "HandleMakeConsultAppointment",
    });

    if (isDuplicate) {
      return;
    }

    controller.setState({ userTokenize: null, duplicateAppointment: null });

    if (patientAppUpdate[1]) {
      controller.setState({
        errorMessage: { ...errorMessage, [defaultCard]: patientAppUpdate[1] },
        consultDetail: { ...consultDetail, id: consultOrder?.[0]?.id },
        buttonLoadCheck: {
          ...buttonLoadCheck,
          [params.buttonLoadCheck]: "ERROR",
        },
      });
      return;
    } else {
      if (patientAppUpdate[0]?.type_display === "นัดหมาย Consult OPD" && params?.isToday) {
        const openEncounter = await AppointmentFinish.post({
          apiToken: controller.apiToken,
          data: {
            items: [{ id: patientAppUpdate[0]?.content_id, checked: true }],
            extra: {
              division: controller.data?.division || null,
              device: controller.data?.device || null,
            },
          },
          extra: {
            device: controller.data.device,
            division: controller.data.division,
          },
        });
        if (openEncounter[1]) {
          controller.setState(
            {
              errorMessage: {
                ...errorMessage,
                [defaultCard]: openEncounter[1],
              },
              buttonLoadCheck: {
                ...buttonLoadCheck,
                [params.buttonLoadCheck]: "ERROR",
              },
              consultDetail: {
                ...consultDetail,
                id: consultOrder?.[0]?.id,
                ...(params?.doctorProvider
                  ? { destinationProviderDoctor: params?.doctorProvider }
                  : {}),
                ...(params?.time ? { time: params?.time } : {}),
              },
              selectedAppointment: {
                ...selectedAppointment,
                ...patientAppUpdate?.[0],
              },
            },
            async () => {
              await RefreshAppointment(controller, params);
              params?.onRefresh?.();
              params?.onSuccess?.();
            }
          );
          return;
        }
      }

      const isNotFilterStatusMatch =
        patientAppUpdate?.[0]?.status &&
        params?.filter?.appointmentStatus &&
        params?.filter?.appointmentStatus !== patientAppUpdate?.[0]?.status;

      controller.setState(
        {
          consultDetail: isNotFilterStatusMatch
            ? {}
            : {
                ...consultDetail,
                id: consultOrder?.[0]?.id,
                ...(params?.doctorProvider
                  ? { destinationProviderDoctor: params?.doctorProvider }
                  : {}),
                ...(params?.time ? { time: params?.time } : {}),
              },
          selectedAppointment: isNotFilterStatusMatch
            ? {}
            : {
                ...selectedAppointment,
                ...patientAppUpdate?.[0],
                // set start and end time
                ...(patientAppUpdate?.[0]?.display_info?.start_datetime_iso
                  ? {
                      date: adToBe(
                        moment(patientAppUpdate?.[0]?.display_info?.start_datetime_iso).format(
                          "DD/MM/YYYY"
                        )
                      ),
                      start: moment(patientAppUpdate?.[0]?.display_info?.start_datetime_iso).format(
                        "HH:mm"
                      ),
                      end: moment(patientAppUpdate?.[0]?.display_info?.end_datetime_iso).format(
                        "HH:mm"
                      ),
                    }
                  : {}),
              },
          buttonLoadCheck: {
            ...buttonLoadCheck,
            [params.buttonLoadCheck]: "SUCCESS",
          },
        },
        async () => {
          await RefreshAppointment(controller, params);
          await HandleInitialConsultData(controller, {
            action: "PROVIDER",
            divisionId: consultDetail?.destinationDivision,
          });
          params?.onRefresh?.();
          params?.onSuccess?.();
        }
      );
    }
  } else if (params?.action === "EDIT_NOTE") {
    controller.setState({
      buttonLoadCheck: {
        ...buttonLoadCheck,
        [params.buttonLoad]: "LOADING",
      },
    });
    const consultOrder = await DoctorConsultOrderDetail.patch({
      pk: consultDetail?.id,
      apiToken: controller.apiToken,
      data: {
        action: "ORDER",
        note: consultDetail?.note,
      },
      extra: {
        device: controller.data.device,
        division: controller.data.division,
      },
    });

    if (consultOrder[1]) {
      controller.setState({
        errorMessage: { ...errorMessage, [defaultCard]: consultOrder[1] },
        buttonLoadCheck: {
          ...buttonLoadCheck,
          [params.buttonLoad]: "ERROR",
        },
      });
      return;
    }

    return controller.setState({
      buttonLoadCheck: {
        ...buttonLoadCheck,
        [params.buttonLoad]: "SUCCESS",
      },
    });
  }
};

export const HandleConfirmConsultAppointment: Handler = async (controller, params) => {
  const state = controller.getState();
  // console.log("HandleConfirmConsultAppointment: ", params);
  const [resp, err, network] = await PatientAppointmentUpdate.patch({
    pk: params.patientAppointmentId,
    apiToken: controller.apiToken,
    data: {
      status: 2,
      orders: [{ [params.consultId]: "doctorconsultorder" }],
    } as any,
  });
  if (err) {
    controller.setState({
      errorMessage: { ...state.errorMessage, [params.card]: err },
    });
    return;
  } else {
    const isNotFilterStatusMatch =
      resp?.status &&
      params?.filter?.appointmentStatus &&
      params?.filter?.appointmentStatus !== resp?.status;

    // console.log("isNotFilterStatusMatch", isNotFilterStatusMatch);

    controller.setState(
      {
        consultDetail: isNotFilterStatusMatch ? {} : { ...state.consultDetail },
        selectedAppointment: isNotFilterStatusMatch
          ? {}
          : {
              ...state.selectedAppointment,
              status: resp?.status || state.selectedAppointment.status,
              related_detail: resp?.related_detail || null,
              related_detail_status: resp?.related_detail_status || null,
            },
      },
      () => {
        params?.onSuccess?.();
        params?.onRefresh?.();
      }
    );
  }
};

export const HandleFinishConsultAppointment: Handler = async (controller, params) => {
  const [resp, err, netw] = await DoctorConsultOrderDetail.patch({
    apiToken: controller.apiToken,
    pk: params.consultId,
    extra: { division: controller.data.division },
    data: params.data,
  });
  if (err) {
    const state = controller.getState();
    controller.setState({
      errorMessage: { ...state.errorMessage, [params.card]: err },
    });
    return;
  } else {
    params?.onSuccess?.();
    params?.onRefresh?.();
  }
};

export const HandleSaveConsultResponse: Handler = async (controller, params) => {
  const state = controller.getState();

  const [patientApp, consultResp] = await Promise.all([
    PatientAppointmentUpdate.patch({
      pk: params.patientAppointmentId,
      apiToken: controller.apiToken,
      data: {
        status: 8,
        orders: [{ [params.consultId]: "doctorconsultorder" }],
      } as any,
    }),
    DoctorConsultResponseList.create({
      apiToken: controller.apiToken,
      data: {
        consult_order: params.consultId,
        emr: params.emrId,
        // doctor:
        current_illness: params.note.trim() || "-",
        suggestion: params.consult_response_suggestion,
        need_continuous_medication: false,
      },
      extra: {
        device: controller.data.device,
        division: controller.data.division,
      },
    }),
  ]);
  if (patientApp?.[1] || consultResp?.[1]) {
    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [params.card]: patientApp?.[1] || consultResp?.[1],
      },
    });
    return;
  } else {
    await HandleInitialConsultData(controller, {
      action: "INITIAL",
      consultId: params.consultId,
    });
  }
};

export const HandleEditConsultResponse: Handler = async (controller, params) => {
  const state = controller.getState();

  const [patientApp, consultResp] = await Promise.all([
    PatientAppointmentUpdate.patch({
      pk: params.patientAppointmentId,
      apiToken: controller.apiToken,
      data: {
        status: 8,
        orders: [{ [params.consultId]: "doctorconsultorder" }],
      } as any,
    }),
    DoctorConsultResponseDetail.patch({
      apiToken: controller.apiToken,
      pk: params.consult_response_id,
      data: { suggestion: params.consult_response_suggestion },
      extra: {
        device: controller.data.device,
        division: controller.data.division,
      },
    }),
  ]);
  if (patientApp?.[1] || consultResp?.[1]) {
    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [params.card]: patientApp?.[1] || consultResp?.[1],
      },
    });
    return;
  } else {
    await HandleInitialConsultData(controller, {
      action: "INITIAL",
      consultId: params.consultId,
    });
  }
};

export const HandleCancelConsultAppointment: Handler = async (controller, params) => {
  const { consultDetail, selectedAppointment, errorMessage, selectedDivision } =
    controller.getState();

  // verified
  const verifiedField = ["cancelCause", "cancelReason", "username", "password"];
  const errorsReport = [
    "กรุณาระบุ การยกเลิกนัดหมาย Consult",
    "กรุณาระบุ เหตุผลในการยกเลิก",
    "กรุณาระบุ Username",
    "กรุณาระบุ Password",
  ];
  const errors: any = [];
  verifiedField.forEach((item: any, idx: number) => {
    if (!params?.[item]) {
      errors.push(errorsReport[idx]);
    }
  });

  if (errors.length > 0) {
    controller.setState({
      errorMessage: { ...errorMessage, [params.card]: errors },
    });
    return;
  }

  if (!params?.patientAppointment && !selectedAppointment?.id) {
    return;
  }

  const patientAppUpdate = await PatientAppointmentUpdate.patch({
    pk: params?.patientAppointment || selectedAppointment.id,
    apiToken: controller.apiToken,
    data: {
      status: 5,
      status_note: `${params?.cancelCause}: ${params?.cancelReason}`,
      username: params?.username,
      password: params?.password,
      division: selectedDivision.id || selectedAppointment.division,
    } as any,
  });
  if (patientAppUpdate[1]) {
    controller.setState({
      errorMessage: { ...errorMessage, [params.card]: patientAppUpdate[1] },
    });
    return;
  }

  const consultOrder = await DoctorConsultOrderDetail.delete({
    pk: consultDetail?.id,
    apiToken: controller.apiToken,
    extra: {
      device: controller.data.device,
      division: controller.data.division,
    },
  });

  if (consultOrder[1]) {
    controller.setState({
      errorMessage: { ...errorMessage, [params.card]: consultOrder[1] },
    });
    return;
  }

  const isNotFilterStatusMatch =
    patientAppUpdate?.[0].status &&
    params?.filter?.appointmentStatus &&
    params?.filter?.appointmentStatus !== patientAppUpdate?.[0].status;

  controller.setState(
    {
      consultDetail:
        params?.filter === null || isNotFilterStatusMatch
          ? {
              id: null,
              consult_response_id: null,
              note: "",
              sourceDivision: null,
              sourceProviderDoctor: null,
              destinationDivision: null,
              destinationProviderDoctor: null,
              consult_response_suggestion: "",
              status: "",
              date: "",
              time: "",
            }
          : { ...consultDetail },
      selectedAppointment:
        params?.filter === null
          ? null
          : isNotFilterStatusMatch
          ? {}
          : {
              ...selectedAppointment,
              status: patientAppUpdate?.[0].status || selectedAppointment.status,
              related_detail: patientAppUpdate?.[0]?.related_detail || null,
              related_detail_status: patientAppUpdate?.[0]?.related_detail_status || null,
            },
    },
    async () => {
      await RefreshAppointment(controller, params);
      params?.onSuccess?.();
      params?.onRefresh?.();
    }
  );
};

export const ChangeDivision: Handler = (controller, params) => {
  const state = controller.getState();

  controller.setState({
    selectedDivision: state.divisionList?.find((item: any) => item?.id === params.value),
    appointmentList: [],
    selectedAppointment: null,
  });
};

export const SearchBlockList: Handler = async (controller, params) => {
  if (params.action === "clear") {
    return controller.setState({ searchBlockList: [] });
  }

  const blockList = await getDivisionServiceBlockFilter(controller, params);

  controller.setState({
    searchBlockList: blockList,
  });
};

export const HandleSelectAppointmentByOROrder: Handler = async (controller, params) => {
  const state = controller.getState();

  const orOrder = { ...(state.selectedOrOrder || {}) };
  if (!orOrder?.id) {
    console.log("Not selected Operating order");
    return;
  }

  console.log("HandleSelectAppointmentByOROrder patient: ", state.selectedPatient?.id);
  const appointments = await PatientAppointmentView.list({
    params: {
      patient_id: orOrder?.patient_id,
      exclude_cancel: true,
      status: "1,2,8",
    },
    apiToken: controller.apiToken,
  });

  let selectedPatientApp = appointments?.[0]?.items?.find((pa: any) => {
    let filterOrder = pa.orders?.filter(
      (item: any) => item.id === orOrder.id && item.type === "operatingorder"
    );
    return filterOrder?.length > 0;
  });

  console.log("HandleSelectAppointmentByOROrder: ", appointments?.[0], selectedPatientApp);
  // if (selectedPatientApp) {
  controller.setState({
    preOrderList: selectedPatientApp?.orders || [],
    selectedAppointment: selectedPatientApp || {},
    drugOrder: {},
  });
  // }
};

const CreateDivisionServiceBlock: Handler = async (controller, params) => {
  const state = controller.getState();

  const { providerId } = params;

  const ISO_FORMAT = "YYYY-MM-DDTHH:mm:ssZZ";

  const getISODate = (datetime: string) => {
    return moment(datetime).format(ISO_FORMAT);
  };

  const block = await DivisionServiceBlockView.create({
    data: {
      division: state.selectedDivision?.id,
      provider: providerId,
      only_old_patient: params?.exceptNewPatient,
      chair: params?.chair || 0,
      zone: params?.zone,
      parent: params?.event?.dsb_id,

      slot_length: parseInt(params?.slot_length || params.slotLength),
      start_datetime_iso: getISODate(params.event.start),
      end_datetime_iso: getISODate(params.event.end),

      // Use division dsb serial as default
      start_serial: params.event?.start_serial,
      end_serial: params.event?.end_serial,
      // Override if providing doctorStartSerial, doctorEndSerial
      ...(params?.doctorStartSerial ? { start_serial: params?.doctorStartSerial } : {}),
      ...(params?.doctorEndSerial ? { end_serial: params?.doctorEndSerial } : {}),
      examination_type: params?.examinationType === "null" ? null : params?.examinationType,
    } as any,
    apiToken: controller.apiToken,
  });

  console.log(block[1] ? block[1] : block[0]);

  // // reserve chair if specified
  // if (block[0] && params?.chair) {
  //   const chairBlock = await DivisionServiceBlockView.create({
  //     data: {
  //       division: controller.data.division,
  //       provider: params?.chair,
  //       start_serial: params.event?.start_serial,
  //       end_serial: params.event?.end_serial,
  //       parent: block[0].id,
  //     } as any,
  //     apiToken: controller.apiToken
  //   });
  //   console.log(chairBlock[1] ? chairBlock[1] : chairBlock[0]);
  //   if (chairBlock[1]) console.log("Error booking chair")
  // }
  return block;
};

const UpdateDivisionServiceBlock: Handler = async (controller, params) => {
  console.log("DSB with this provider already exists.");

  const state = controller.getState();

  const { doctorDsbId } = params;
  console.log("UpdateDivisionServiceBlock params: ", params);

  const data = {
    status: params?.inactive ? DsbStatus.INACTIVE : DsbStatus.REVISE,
    only_old_patient: params?.exceptNewPatient,
    division: state.selectedDivision?.id,
    chair: params?.chair || 0,
    zone: params?.zone,
    slot_length: parseInt(params?.slotLength),

    // Use division dsb serial as default
    start_serial: params.event?.start_serial,
    end_serial: params.event?.end_serial,
    // Override if providing doctorStartSerial, doctorEndSerial
    ...(params?.doctorStartSerial ? { start_serial: params?.doctorStartSerial } : {}),
    ...(params?.doctorEndSerial ? { end_serial: params?.doctorEndSerial } : {}),
    ...(params?.examinationType
      ? { examination_type: params?.examinationType === "null" ? null : params?.examinationType }
      : {}),
  } as any;

  console.log("UpdateDivisionServiceBlock patch data: ", data);

  let block: any[] = [];
  //
  if (params.isDelete) {
    block = await DivisionServiceBlockDetail.delete({
      pk: doctorDsbId,
      apiToken: controller.apiToken,
    });
  } else {
    block = await DivisionServiceBlockDetail.patch({
      pk: doctorDsbId,
      data,
      apiToken: controller.apiToken,
    });
  }

  console.log(block[1] ? block[1] : block[0]);

  if (params?.inactive) {
    // const relatedAppointments = [...(state.dsbAppointments || [])];
    // await Promise.all(
    //   relatedAppointments.map((item: any) => {
    //     return PatientAppointmentUpdate.patch({
    //       pk: item.id,
    //       apiToken: controller.apiToken,
    //       data: {
    //         division_service_block: null,
    //         status: 4, // RECONFIRM STATUS
    //       } as any,
    //     });
    //   })
    // );
    controller.setState({
      dsbAppointments: [],
    });
  }
};

const HandleSaveAppointment: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [params.card]: "LOADING" },
  });

  const operatingOrder = state.selectedAppointment?.orders?.find(
    (item: any) => item.type === "operatingorder"
  );
  const isOrAppointment =
    !!operatingOrder && state?.selectedAppointment?.type_display === "นัดหมายผ่าตัด";

  if (isOrAppointment) {
    /// save
    // save other treatment, lab, imaging บันทึกส่วน
    let orderDict: Record<string, string> = {};

    if (state.preOrderList?.length) {
      for (const item of state.preOrderList) {
        orderDict[item.id] =
          item.specific_label_type === "imagingorder" ? "imagingorder" : item.type;
      }
    }

    await UpdatePatientAppointment(controller, {
      data: {
        order_dict: orderDict,
      },
    });

    // save ยา
    /// Case OR
    const result = await UpdateDrugOperatingOrder(controller, {
      ...params,
      operatingOrder,
    });

    // * Return เมื่อ error
    if (result[1]) {
      return;
    }

    controller.setState(
      {
        buttonLoadCheck: { ...state.buttonLoadCheck, [params.card]: "SUCCESS" },
        ...(!result[1] && {
          drugOrder: {
            ...state.drugOrder,
            id: result[0]?.drug_operating_order?.id,
          },
        }), // TODO: ต้องimplement เรื่องใบยา
      },
      async () => {
        const isCreate = !state.drugOrder?.id && result[0]?.drug_operating_order?.id;

        if (isCreate) {
          orderDict = {
            ...orderDict,
            [result[0].drug_operating_order.id]: "drugoperatingorder",
          };

          await UpdatePatientAppointment(controller, {
            data: {
              order_dict: orderDict,
            },
          });
        }

        await RefreshAppointment(controller, params);
      }
    );
    return;
  }

  const appointmentStatus = state.selectedAppointment?.status;
  const needReConfirm = appointmentStatus === 4;
  console.log("needReConfirm: ", needReConfirm);
  const needReSchedule = appointmentStatus === 3;
  console.log("needReSchedule: ", needReSchedule);
  const confirmedStatus = appointmentStatus === 2;

  // Ishealth-v3 port into CUDent
  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [params?.card || ""]: "LOADING" },
  });

  const divisionId = state.selectedBlock?.division_id || state.selectedAppointment?.division;

  const patientAppointment = await UpdatePatientAppointmentDetail(controller, {
    ...params,
    needReConfirm,
    needReSchedule,
    confirmedStatus,
    divisionId,
  });

  const isDuplicate = handleCheckDuplicateAppointment(controller, {
    error: patientAppointment[1],
    payload: params,
  });

  if (isDuplicate) {
    return;
  }

  controller.setState({
    userTokenize: null,
    duplicateAppointment: null,
    errorMessage: { ...state.errorMessage, [params.card]: null },
    buttonLoadCheck: { ...state.buttonLoadCheck, [params?.card || ""]: "SUCCESS" },
  });

  console.log(patientAppointment[1] ? patientAppointment[1] : patientAppointment[0]);

  const block = await DivisionServiceBlockDetail.patch({
    pk: state.selectedBlock?.doctor_dsb_id || state.selectedAppointment?.division_service_block,
    data: {
      division: divisionId,
      is_full: params.full ?? false,
    } as any,
    apiToken: controller.apiToken,
  });

  console.log(block[1] ? block[1] : block[0]);

  // Ishealth-v3 port into CUDent
  await RefreshAppointment(controller, params);

  if (needReConfirm) {
    SetScheduling(controller, { action: "GetReconfirmAppointment" });
    controller.setState({ selectedAppointment: null });
  } else if (needReSchedule) {
    SetScheduling(controller, { action: "GetRescheduleAppointment" });
    controller.setState({ selectedAppointment: null });
  } else {
    SelectAppointment(controller, {
      ...(patientAppointment[0] || state.selectedAppointment),
      isSave: true,
    });
  }
};

const handleCheckDuplicateAppointment: Handler = (controller, params) => {
  console.log("handleCheckDuplicateAppointment params: ", params);
  const state = controller.getState();

  if (params.error?.rep_patient_appointments && params.payload.card) {
    if (state.duplicateAppointment) {
      // ครั้งที่สอง และ ต่อๆ ไป
      controller.setState({
        duplicateAppointment: { ...params.error, payload: params.payload, message: params.message },
        reoccureDuplicateAppointment: true,
        userTokenize: null,
        // errorMessage: { ...state.errorMessage, [params.card]: patientAppointment[1] },
        loadingStatus: {
          ...state.loadingStatus,
          [params?.payload?.card || ""]: false,
        },
      });
    } else {
      // ครั้งแรก
      controller.setState({
        duplicateAppointment: { ...params.error, payload: params.payload, message: params.message },
        reoccureDuplicateAppointment: false,
        userTokenize: null,
        // errorMessage: { ...state.errorMessage, [params.card]: patientAppointment[1] },
        loadingStatus: {
          ...state.loadingStatus,
          [params?.payload?.card || ""]: false,
        },
      });
    }

    return true;
  }

  return false;
};

/* ------------------------------------------------------ */

/*                          APIS                          */

/* ------------------------------------------------------ */
const UpdatePatientAppointment: Handler = async (controller, params) => {
  const state = controller.getState();

  const data = params.data || {};

  return await PatientAppointmentUpdate.patch({
    pk: state.selectedAppointment.id,
    apiToken: controller.apiToken,
    data: {
      order_dict: data.order_dict,
    } as any,
  });
};

const UpdateDrugOperatingOrder: Handler = async (controller, params) => {
  const state = controller.getState();

  const { operatingOrder } = params;

  let ood = await OperatingOrderDetail.retrieve({
    pk: operatingOrder?.id,
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division,
    },
  });
  ood[0].pre_op_medical_assessment.action = "SAVE";

  const data = {
    drug_operating_order_id: state.drugOrder?.id || null,
    drug_operating_order:
      state.drugOrder?.items?.length > 0
        ? {
            ...state.drugOrder,
            items: state.drugOrder.items.map((item: any) => ({
              ...item,
              frequency: item.frequency ?? undefined,
              route: item.route ?? undefined,
            })),
            action: state.drugOrder?.id ? "EDIT" : "REQUEST",
            emr: ood[0]?.emr,
            order_status: params.isAppointment ? 1 : 2,
            out_perform_div: (controller.data as any).division,
            order_status_name: params.isAppointment ? "APPOINTMENT" : "PENDING",
          }
        : {},
    operating_order_id: ood[0].id,
    operating_order: { ...ood[0], action: "EDIT_REQUEST" },
  };

  const result = await OperatingOrderBundleCreate.post({
    // pk: operatingorder?.id,
    apiToken: controller.apiToken,
    data: data,
    extra: {
      division: controller.data.division,
    },
  });

  if (result[1]) {
    if (result[1]?.code === "NEED_VERIFY_ORDER") {
      controller.setState({
        modDoctorCertificate: {
          ...state.modDoctorCertificate,
          open: true,
          narcoticDrugType: result[1]?.detail,
          action: params.action,
          card: params.card,
        },
      });
      return result;
    }
    // else if (result[1]?.code === "DUPLICATE_DRUG_ORDERS") {
    // }
    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        ...(params.card ? { [params.card]: result[1] } : {}),
        // [CARD_DUPLICATE_REASON]: result[1],
      },
      buttonLoadCheck: { ...state.buttonLoadCheck, [params.card]: "ERROR" },
      // successMessage: { ...state.successMessage, [params.card]: null },
    });
  }

  return result;
};

export const HandleDownloadAppointmentSummary: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [params.buttonLoadKey]: "LOADING",
    },
  });

  const total = state.appointmentSummaryPage?.total || 0;
  const urlParams = { ...params, limit: 99999, activePage: 1 };
  const items =
    total > 1
      ? (await GetPatientAppointmentList(controller, urlParams)).items
      : state.appointmentSummaryList;

  const XLSX = await import("xlsx");

  let workbook = XLSX.utils.book_new();

  workbook.SheetNames.push("Sheet1");

  const sheet_data = [
    [`รายงานสรุปนัดหมาย ${state.selectedDivision?.name || ""}`],
    [`วันที่นัดหมาย ${params.startDate || ""} - ${params.endDate}`],
    [],
    [`แพทย์: ${params.doctorName}`],
    [
      "ลำดับที่",
      "HN",
      "ชื่อ-สกุล",
      "เบอร์โทรศัพท์",
      "แพทย์",
      "ความเชี่ยวชาญ",
      "ประเภทนัดหมาย",
      "นัดหมายเพื่อ",
      "วันที่นัดหมาย",
      "สถานะนัดหมาย",
      "วันที่รับบริการ",
      "สถานะบริการ",
    ],
    ...items.map((item: any, index: number) => [
      index + 1,
      item.patient_hn,
      item.patient_name_text,
      item.tel_text,
      item.doctor_name_text,
      item.specialty_text,
      item.appointment_type_text,
      item.appointment_for_text,
      item.appointment_date,
      `${item.appointment_status_text} ${item.appointment_status_note}`,
      item.service_date_text || "",
      item.service_status_text || "",
    ]),
  ];
  const sheet = XLSX.utils.aoa_to_sheet(sheet_data);

  workbook.Sheets["Sheet1"] = sheet;

  const output = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });

  const url = URL.createObjectURL(
    new Blob([output], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    })
  );

  const link = document.createElement("a");
  link.href = url;
  link.download = "download.xlsx";
  link.click();
  link.remove();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [params.buttonLoadKey]: "SUCCESS",
    },
  });
};

export const HandlePrintAppointmentSummaryList: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [params.buttonLoadKey]: "LOADING",
    },
  });

  const total = state.appointmentSummaryPage?.total || 0;
  const urlParams = { ...params, limit: 99999, activePage: 1 };
  const items =
    total > 1
      ? (await GetPatientAppointmentList(controller, urlParams)).items
      : state.appointmentSummaryList;

  const docDef: any = await FormAppointmentSummaryList({
    ...params,
    data: items,
    divisionName: state.selectedDivision?.name,
    userName: state.django?.user?.full_name || "",
  });

  const pdfMake = (await getPdfMake()).createPdf(docDef);

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [params.buttonLoadKey]: "SUCCESS",
    },
  });

  pdfMake.open();
};

// APIs
const GetPatientAppointmentList: Handler = async (controller, params) => {
  console.log("GetPatientAppointmentList");
  const state = controller.getState();

  let [result] = await PatientAppointmentView.list({
    params: {
      division_id: state.selectedDivision?.id,
      limit: params.limit || 10,
      offset: ((params.activePage || 1) - 1) * 10,
      start_date: params.startDate?.replaceAll("-", "/") || "",
      end_date: params.endDate?.replaceAll("-", "/") || "",
      ...(params.startTime ? { start_time: params.startTime } : {}),
      ...(params.endTime ? { end_time: params.endTime } : {}),
      include_cancel: true,
      provider_id: params.isUnspecifiedDoctor ? "NONE" : params?.providerDoctor || null,
      appointment_related_type: params.appointmentType || "",
      status: params.appointmentStatus || "1,2,5,6,8",
      order_status: params.orderStatus || "",
      patient_id: params.patientId || null,
      order_by_app_date: true,
      waiting_list_id: params.waiting_list_id,
      type: params.type,
      specialty: params.specialty,
    },
    apiToken: controller.apiToken,
  });

  const items = (result?.items || []).map((item: any, index: number) => {
    const appointmentDate = item.display_info?.provider
      ? item.estimated_at || ""
      : item.display_info
      ? `${item.display_info?.start_datetime?.split("T")?.[0]} [${item.display_info?.start_datetime
          ?.split("T")?.[1]
          ?.substring(0, 5)} - ${item.display_info?.end_datetime
          ?.split("T")?.[1]
          ?.substring(0, 5)}]`
      : [5, 6].includes(item.status)
      ? item.estimated_at || ""
      : "";

    const serviceDate =
      item.status === 8 &&
      `${moment(item.updated).format("DD/MM/")}${
        parseInt(moment(item.updated).format("YYYY")) + 543
      } [${moment(item.updated).format("HH:mm")}]`;

    const doctorName =
      item.type_display === TYPE_DISPLAY.or
        ? item.doctor_full_name
        : item.display_info?.provider_type_category === "doctor"
        ? item.display_info.provider_name
        : item.status === 5 || item.status === 6
        ? item.doctor_full_name
        : "ยังไม่ระบุ";

    const appointmentStatus =
      APPOINTMENT_STATUS_OPTIONS.find((i: any) => i.value.includes(item.status.toString()))?.text ||
      "";
    const statusNote = getStatusNote(item);

    const serviceStatusText = item.status === 8 && serviceStatus(item);

    const type = handleGetAppointmentType(item);

    const appointmentType = APPOINTMENT_TYPE_OPTIONS.find((option) =>
      type.includes("appointment") ? option.value === "NORMAL" : option.value.toLowerCase() === type
    )?.text;

    const patientName = `${item.patient_pre_name ? item.patient_pre_name : ""}${
      item.patient_first_name
    } ${item.patient_last_name}`;
    const tel = item.patient_tel || "";
    const appointmentFor = item.extra?.appointmentFor ? ` (${item.extra?.appointmentFor})` : "";

    return {
      ...item,
      patient: item?.patient_pre_name
        ? `${item.patient_pre_name} ${item.patient_first_name} ${item.patient_last_name}`
        : `${item.patient_first_name} ${item.patient_last_name}`,
      datetime: item?.estimated_at_iso
        ? moment(item.estimated_at_iso).format("DD/MM/YYYY HH:mm:ss")
        : "",
      provider: item?.display_info?.provider_name,
      patient_id: item.patient,
      appointment_date: appointmentDate,
      doctor_name_text: doctorName,
      tel_text: tel,
      patient_name_text: patientName,
      appointment_status_text: appointmentStatus,
      appointment_status_note: statusNote,
      service_date_text: serviceDate,
      service_status_text: serviceStatusText,
      appointment_type_text: appointmentType,
      appointment_for_text: `${item.waiting_list_name || item.type_display}${appointmentFor}`,
      specialty_text: (item.doctor_specialties || [])
        .map((specialty: any) => specialty.name)
        .join(", "),
    };
  });

  return { ...result, items };
};

const UpdatePatientAppointmentDetail: Handler = async (controller, params) => {
  const state = controller.getState();

  const orderDict: Record<string, string> = {};

  if (state.preOrderList?.length) {
    for (const item of state.preOrderList) {
      orderDict[item.id] = item.specific_label_type === "imagingorder" ? "imagingorder" : item.type;
    }
  }

  const date = formatDateToYYYYMMDD(state.selectedAppointment?.date, "-", true);
  const ADDateTimeObj = YYYYMMDDadStringDateTimeToDateObject(
    `${date}/${
      params?.estimatedAt ||
      (state.selectedAppointment?.main_patient_appointment
        ? moment(state.selectedAppointment?.estimated_at_iso).format("HH:mm")
        : "")
    }`
  );

  const estimatedDuration =
    params?.estimatedDuration || (state.selectedAppointment?.main_patient_appointment ? 5 : null);

  const appointmentData = {
    division_service_block: state.selectedBlock?.doctor_dsb_id,
    division: params.divisionId,
    ...(!Number.isNaN(ADDateTimeObj.getTime()) && {
      estimated_at_iso: ADDateTimeObj.toISOString(),
    }),
    estimated_duration: estimatedDuration,
    order_note: `${params.orderNote || ""}`,
    order_dict: orderDict,
    ...((params.needReConfirm || params.needReSchedule || params.confirmedStatus) && {
      status: 1,
    }),
    type: state.selectedAppointment?.type,
    ...(params.repetition_note && {
      repetition_note: params.repetition_note,
      ...(state.userTokenize?.employeeName && {
        user: state.userTokenize?.employeeName,
      }),
    }),
    extra: {
      allergy: state.selectedAppointment?.extra?.allergy,
      premedication: state.selectedAppointment?.extra?.premedication,
      orderLab: state.selectedAppointment?.extra?.orderLab,
      orderXray: state.selectedAppointment?.extra?.orderXray,
      jobToDo: state.selectedAppointment?.extra?.jobToDo,
      chair: state.selectedAppointment?.extra?.chair,
      appointmentFor: state.selectedAppointment?.extra?.appointmentFor,
    },
  };

  const patientAppointment = await PatientAppointmentUpdate.patch({
    pk: state.selectedAppointment.id,
    data: appointmentData,
    apiToken: controller.apiToken,
  });

  if (patientAppointment[0]?.content_id) {
    await AppointmentDetail.patch({
      apiToken: controller.apiToken,
      pk: patientAppointment[0].content_id,
      data: { division: patientAppointment[0].division, action: "EDIT" },
      extra: { division: patientAppointment[0].order_division },
    });
  }

  return patientAppointment;
};

const GetWaitingListData: Handler = async (controller, params) => {
  const state = controller.getState();
  // ส่งมาจาก params, จาก Division ที่เลือกในหน้า Waiting list, จาก Division ที่ทำงาน
  const divisionId = params.divisionId || state.selectedWaitingListDivisionId || state.selectedDivision?.id;

  let wL = state.waitingList || [];

  // ถ้า wL ว่างเปล่าหรือถูกบังคับให้ fetch ใหม่ ให้ดึงข้อมูล Waiting List ใหม่
  if (params.forceFetch) {
    const waitingList = await WaitingListList.list({
      params: {
        division_id: divisionId,
        limit: 99999,
      },
      apiToken: controller.apiToken,
    });

    wL = (waitingList[0]?.items || []).map((item: any) => ({
      ...item,
      patient_count: item.waiting_list_state?.item_count,
      pending: item.waiting_list_state?.pending,
      ...(isNaN(item.waiting_list_state?.item_count) && {
        patient_count: item?.items?.length || item?.patient_count || 0,
      }),
      ...(isNaN(item.waiting_list_state?.pending) && {
        pending: item?.items?.filter((wli: any) => wli.status === "PENDING")?.length || 0,
      }),
    }));

    // Remove items ออก เพื่อทดสอบ API ใหม่ของหลง
    wL = wL.map(({ items, ...rest }) => rest);
  }

  // ทำการดึง waitingListITem จาก API 2 ถ้ามี params pass เข้ามา
  if (params.waiting_list_id) {
    const waitingListItem = await GetWaitingListItemList(controller, params);

    if (waitingListItem[2].code === AxiosError.ERR_CANCELED) {
      return AxiosError.ERR_CANCELED;
    }

    if (Array.isArray(wL) && wL?.length > 0 && waitingListItem?.[0]) {
      const idx = wL.findIndex((item: any) => item.id === params.waiting_list_id);
      if (idx >= 0) {
        wL[idx].items = waitingListItem[0].items;
        wL[idx].total = waitingListItem[0].total;
        wL[idx].next = waitingListItem[0].next;
        wL[idx].previous = waitingListItem[0].previous;
      }
    }
  }

  return wL;
};

const GetWaitingListItemList: Handler = (controller, params) => {
  const state = controller.getState();
  // ส่งมาจาก params, จาก Division ที่เลือกในหน้า Waiting list, จาก Division ที่ทำงาน
  const divisionId =
    params.divisionId || state.selectedWaitingListDivisionId || state.selectedDivision?.id;

  state.waitingListItemAbort?.abort();

  const abortCtrl = new AbortController();

  controller.setState({
    waitingListItemAbort: abortCtrl,
  });

  const waitingListItemParams = {
    division_id: divisionId,
    waiting_list: params.waiting_list_id,
    limit: params.pageLimit,
    offset: params.offset,
    provider: params.doctor?.provider_id,
    only_provider_null: params.onlyProviderNull,
    ...(params.includeCancel && { cancel: true }),
    ...(params.endDate && { created__lt: params.endDate }),
    ...(params.startDate && { created__gte: params.startDate }),
    ...(params.hn && { hn: params.hn }),
    ...(params.queueNo && { code__icontains: params.queueNo }),
    ...(params.completeStart && { completed_at__gte: params.completeStart }),
    ...(params.completeEnd && { completed_at__lt: params.completeEnd }),
    ...(Array.isArray(params.statusList) &&
      params.statusList.length > 0 && { status__in: params.statusList.join(",") }),
  };

  return WaitingListItemList.list({
    params: waitingListItemParams,
    apiToken: controller.apiToken,
    extra: {
      signal: abortCtrl.signal,
    },
  });
};

/* ------------------------------------------------------ */

/*                          Utils                         */

/* ------------------------------------------------------ */
const waterMarkToPdf = async (dataUrl: string, isLandscape = false) => {
  const pdfDoc = await PDFDocument.create();
  const doc = await PDFDocument.load(dataUrl);
  const copiedPages = await pdfDoc.copyPages(doc, doc.getPageIndices());

  const url = "/static/fonts/THSarabunNew-Bold.ttf";
  pdfDoc.registerFontkit(fontkit);
  const fontBytes = await fetch(url).then((res) => res.arrayBuffer());

  const timesRomanFont = await pdfDoc.embedFont(fontBytes);
  copiedPages.forEach((page) => {
    const pageDraw = pdfDoc.addPage(page);
    let x = 125;
    let y = 290;
    let size = 32;
    let lineHeight = 30;

    if (isLandscape) {
      x = 240;
      y = 180;
      size = 32;
      lineHeight = 30;
    }
    pageDraw.drawText(
      "เอกสารควบคุม ห้ามเผยแพร่โดยไม่ได้รับอนุญาต \n\n คณะทันตแพทยศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย",
      {
        font: timesRomanFont,
        x,
        y,
        size,
        lineHeight,
        opacity: 0.15,
        rotate: degrees(45),
      }
    );
  });
  // pdfDoc.setTitle(props.patient_name);
  // pdfDoc.setAuthor(props.author);

  const base64Data = await pdfDoc.saveAsBase64();

  const blob = base64toBlob("data:application/pdf;base64," + base64Data);
  const bloburl = URL.createObjectURL(blob);
  window.open(bloburl);
};

const getStatusNote = (data: any) => {
  let note = "";
  try {
    note = JSON.parse(data.status_note).join(", ");
  } catch (error) {
    note = data.status_note;
  }
  return note && `(${note})`;
};

const serviceStatus = (data: any) => {
  let appointmentDate = data?.display_info?.provider
    ? moment(data?.estimated_at_iso).format("YYYY/MM/DD")
    : moment(data?.display_info?.start_datetime).format("YYYY/MM/DD");

  let serviceDate = moment(data?.updated).format("YYYY/MM/DD");

  if (appointmentDate > serviceDate) {
    return "มาไม่ตรงนัด";
  } else if (appointmentDate < serviceDate) {
    return "ไม่มาตามนัด";
  } else {
    return "มาตรงนัดหมาย";
  }
};

export const handleGetAppointmentType = (item: any) => {
  return [TYPE_DISPLAY.consult_ipd, TYPE_DISPLAY.consult_opd].includes(item.type_display)
    ? "consult"
    : item.type_display === TYPE_DISPLAY.or
    ? "or"
    : item.waiting_list_name
    ? "waiting_list"
    : item.appointment_type === "PACKAGE"
    ? "package"
    : item.is_telemed
    ? "appointment_telemed"
    : "appointment_onsite";
};
