import React from 'react';
import { toast } from 'react-toastify';
import moment from "moment";
import _ from "react-lib/compat/lodashplus";
import { vcMessenger } from '../../../react-lib/compat/vc-websocket';
import CardCreateARTransaction from '../../../react-lib/component-js/hbill/CardCreateARTransaction';
import BillService from '../../../react-lib/services/BillService';
import { formatDateToStringBe } from 'react-lib/utils/dateUtils';
import { useIntl } from "react-intl";

const PAYER_CODE = '50257';
const PATIENT_TYPE = 'OPD';
const COVERAGE_CODE = 'GGO';

let today = moment() ;

const INITIAL_STATE = {
  date: formatDateToStringBe(today),
  items: [],
  page: 1,
  total: 1,
  pageSize: 10,
  isLoading: false,
  useFilterDate: true,
  selectedRows: {},
  error: null,
  totalRows: '',
  totalPrice: '',
  totalSentClaim: '',
  totalOtherPay: '',
  totalPaid: '',
  totalPriceBillItemMode_2: '',
  totalSentClaimPriceBillItemMode_2: '',
  totalPriceBillItemMode_3_5: '',
  totalSentClaimPriceBillItemMode_3_5: '',
  totalPriceBillItemModeRemain: '',
  totalSentClaimPriceBillItemModeRemain: '',
  totalPriceDispensing: '',
  totalSentClaimPriceDispensing: '',
  totalPriceOperationService: '',
  generateSentClaimDataDate: formatDateToStringBe(today),
  startDate: formatDateToStringBe(today),
  endDate: formatDateToStringBe(today),
  coveragePayerGroup: [],
  coveragePayerId: null,
  isCalulating: false,
  runningARTask: false,
};

class CardCreateARTransactionContainer extends React.PureComponent<{
  apiToken?: string;
  tableStyle?: Record<string, any>
}> {
  constructor(props) {
    super(props);
    this.billService = new BillService();
    this.state = INITIAL_STATE;

    vcMessenger.onMessage((msg) => {
      console.log(msg.event);
      switch (msg.event) {
        case "GENERATE_SENT_CLAIM_DATA_TASK_IN_PROGRESS":
          return this.setState({ runningARTask: true });
        case "GENERATE_SENT_CLAIM_DATA_TASK_WAITING":
          return this.setState({ runningARTask: true });
        case "GENERATE_SENT_CLAIM_DATA_TASK_COMPLETED":
          return this.setState({ runningARTask: false }, () =>
            toast.success("สร้างข้อมูลส่งเบิกสำเร็จ")
          );
        case "GENERATE_SENT_CLAIM_DATA_TASK_FAILED":
          return this.setState({ runningARTask: false }, () =>
            toast.error("สร้างข้อมูลส่งเบิกไม่สำเร็จ")
          );
      }
    });
  }

  loadBillTransaction() {
    const {
      date,
      page,
      pageSize,
      startDate,
      endDate,
      useFilterDate,
      coveragePayerId,
    } = this.state;

    if (coveragePayerId === null) {
      toast.error("กรุณาเลือสิทธิก่อนทำการค้นหา");
      return;
    }

    this.setState({ isLoading: true });

    if (useFilterDate) {
      var params = {
        page,
        page_size: pageSize,
        coverage_payer_sent_claim_group: coveragePayerId,
        start_date: startDate,
        end_date: endDate,
      };
    } else {
      params = {
        page,
        page_size: pageSize,
        coverage_payer_sent_claim_group: coveragePayerId,
      };
    }

    this.billService
      .getBillTransactions({ ...params, apiToken: this.props.apiToken })
      .then((data) => {
        this.setState({
          isLoading: false,
          items: [...data.items],
          page: data.page,
          total: data.total,
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false, error });
      });
  }

  loadSummary() {
    const {
      date,
      page,
      pageSize,
      startDate,
      endDate,
      useFilterDate,
      coveragePayerId,
    } = this.state;

    this.setState({ isCalulating: true, error: null });

    if (useFilterDate) {
      var params = {
        coverage_payer_sent_claim_group: coveragePayerId,
        start_date: startDate,
        end_date: endDate,
      };
    } else {
      params = {
        coverage_payer_sent_claim_group: coveragePayerId,
      };
    }

    this.billService
      .getBillTransactionSummary({ ...params, apiToken: this.props.apiToken })
      .then((data) => {
        this.setState({
          isCalulating: false,
          totalRows: data.total_rows,
          totalPrice: data.total_price,
          totalSentClaim: data.total_sent_claim_price,
          totalPaid: data.total_paid,
          totalOtherPay: data.total_other_pay,
          totalPriceBillItemMode_2: data.total_price_bill_item_mode_2,
          totalSentClaimPriceBillItemMode_2:
            data.total_sent_claim_price_bill_item_mode_2,
          totalPriceBillItemMode_3_5: data.total_price_bill_item_mode_3_5,
          totalSentClaimPriceBillItemMode_3_5:
            data.total_sent_claim_price_bill_item_mode_3_5,
          totalPriceBillItemModeRemain: data.total_price_bill_item_mode_remain,
          totalSentClaimPriceBillItemModeRemain:
            data.total_sent_claim_price_bill_item_mode_remain,
          totalPriceDispensing: data.total_price_dispensing,
          totalSentClaimPriceDispensing: data.total_sent_claim_price_dispensing,
          totalPriceOperationService: data.total_price_operation_service,
        });
      })
      .catch((error) => {
        this.setState({
          isCalulating: false,
          totalRows: "",
          totalPrice: "",
          totalSentClaim: "",
          totalPaid: "",
          totalOtherPay: "",
          totalPriceBillItemMode_2: "",
          totalSentClaimPriceBillItemMode_2: "",
          totalPriceBillItemMode_3_5: "",
          totalSentClaimPriceBillItemMode_3_5: "",
          totalPriceBillItemModeRemain: "",
          totalSentClaimPriceBillItemModeRemain: "",
          totalPriceDispensing: "",
          totalSentClaimPriceDispensing: "",
          totalPriceOperationService: "",
          error,
        });
      });
  }

  createARTransaction() {
    let {
      date,
      useFilterDate,
      startDate,
      endDate,
      coveragePayerId,
      coveragePayerGroup,
    } = this.state;

    let promise;

    if (coveragePayerId === null) {
      toast.error("กรุณาเลือสิทธิก่อนทำการสร้างเลขงวด");
      return;
    }

    this.setState({ isLoading: true, error: null });

    if (useFilterDate) {
      promise = this.billService.createARTransaction(
        coveragePayerId,
        startDate,
        endDate,
        this.props.apiToken
      );
    } else {
      promise = this.billService.createARTransaction(
        coveragePayerId,
        null,
        null,
        this.props.apiToken
      );
    }

    promise
      .then(() => {
        this.setState({
          ...INITIAL_STATE,
          coveragePayerGroup,
        });
        toast.success("สร้างเลขงวดสำเร็จ");
      })
      .catch((error) => {
        this.setState({
          error,
          isLoading: false,
          items: [],
        });
        toast.error("สร้างเลขงวดไม่สำเร็จ");
      });
  }

  createSentClaimData() {
    const {
      generateSentClaimDataDate,
      coveragePayerId,
      coveragePayerGroup,
      startDate,
      endDate,
      useFilterDate,
    } = this.state;

    if (coveragePayerId === null) {
      toast.error("กรุณาเลือสิทธิก่อนทำการสร้างข้อมูลส่งเบิก");
      return;
    }

    this.setState({ runningARTask: true, error: null });

    this.billService
      .createSentClaimDataHIS({
        coveragePayerId: coveragePayerId,
        startDate: generateSentClaimDataDate,
        endDate: generateSentClaimDataDate,
        apiToken: this.props.apiToken,
      })
      .then(() => {
        this.setState({
          ...INITIAL_STATE,
          generateSentClaimDataDate,
          coveragePayerGroup,
          coveragePayerId,
          startDate,
          endDate,
          useFilterDate,
          runningARTask: true,
        });
        // toast.success('สร้างข้อมูลส่งเบิกสำเร็จ');
      })
      .catch((error) => {
        this.setState({
          error,
          items: [],
          isLoading: false,
          runningARTask: false,
        });
        toast.error("สร้างข้อมูลส่งเบิกไม่สำเร็จ");
      });
  }

  handleDateChange = (date) => {
    this.setState({ date });
  };

  handlePaginationChange = (pageSize, page) => {
    this.setState({ page: page, pageSize: pageSize }, () => {
      this.loadBillTransaction();
    });
  };

  handleSearchClick = async () => {
    await this.loadBillTransaction();
    this.loadSummary();
  };

  handleToggleFilterDate = (useFilterDate) => {
    this.setState((state) => ({
      date: useFilterDate ? state.date : "",
      useFilterDate: useFilterDate,
    }));
  };

  handleCreateARTransactionClick = () => {
    this.createARTransaction();
  };

  handleGenerateSentClaimDataDateChange = (generateSentClaimDataDate) => {
    this.setState({ generateSentClaimDataDate });
  };

  handleGenerateSentClaimDataClick = () => {
    this.createSentClaimData();
  };

  handleChangeCoveragePayerId = (value) => {
    this.setState({ coveragePayerId: value });
  };

  handleStartDateChange = (date) => {
    this.setState({ startDate: date });
  };

  handleEndDateChange = (date) => {
    this.setState({ endDate: date });
  };

  handleGetARTask = () => {
    this.billService
      .getGenerateSentClaim({ apiToken: this.props.apiToken })
      .then((data) => {
        this.setState({
          runningARTask: !data.is_allowed,
        });
      })
      .catch((error) => {
        this.setState({
          error,
        });
      });
  };

  componentDidMount() {
    this.billService
      .getCoveragePayerGroup({ apiToken: this.props.apiToken })
      .then((data) => {
        let items = [];
        _.map(data.items, (resp) => {
          var item = {};
          item["key"] = resp.code;
          item["text"] = resp.name;
          item["value"] = resp.id;
          item["id"] = resp.id;
          items.push(item);
        });
        items = _.orderBy(items, ["value"]);
        this.setState({
          coveragePayerGroup: items,
        });
      })
      .catch((error) => {
        toast.error("โหลดรายชื่อสิทธิไม่สำเร็จ");
        this.setState({
          error,
        });
      });

    this.handleGetARTask();
    setInterval(() => this.handleGetARTask(), 10000);
  }

  render() {
    return (
      <CardCreateARTransaction
        {...this.state}
        tableStyle={this.props.tableStyle}
        onDateChange={this.handleDateChange}
        onPaginationChange={this.handlePaginationChange}
        onSearchClick={this.handleSearchClick}
        onToggleFilterDate={this.handleToggleFilterDate}
        onCreateARTransactionClick={this.handleCreateARTransactionClick}
        onGenerateSentClaimDataDateChange={
          this.handleGenerateSentClaimDataDateChange
        }
        onGenerateSentClaimDataClick={this.handleGenerateSentClaimDataClick}
        onChangeCoveragePayerId={this.handleChangeCoveragePayerId}
        onStartDateChange={this.handleStartDateChange}
        onEndDateChange={this.handleEndDateChange}
      />
    );
  }
}

export default CardCreateARTransactionContainer;