import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button,
  FormGroup,
  FormField
} from 'semantic-ui-react'

const ModRecontinueMedUX = (props: any) => {
    return(
      <div>
        <div
          style={{position: "relative"}}>
          
          <div
            style={{"width":"100%","height":"100%", padding: "20px"}}>
            <div>
              <div
                style={{fontWeight: "bold", fontSize: "20px", paddingBottom: "10px"}}>
                สั่งยาจากประวัติยา Continue
              </div>
              <hr>
              </hr>
            </div>
            <div
              className="ui form">
              <FormGroup
                inline={true}>
                <FormField
                  inline={true}
                  width={7}>
                  <div
                    style={{width: "100%"}}>
                    {props.doctorSearchBox}
                  </div>
                </FormField>
                <FormField
                  inline={true}
                  width={7}>
                  <div
                    style={{width: "100%"}}>
                    {props.divisionSearchBox}
                  </div>
                </FormField>
                <FormField
                  inline={true}
                  width={2}>
                  <div
                    style={{display: "flex", justifyContent: "flex-end", flex:1}}>
                    
                    <Button
                      color="blue"
                      onClick={props.onSearchRecontinueMed}>
                      ค้นหา
                    </Button>
                  </div>
                </FormField>
              </FormGroup>
            </div>
            <div
              style={{height: "350px", marginBottom: "30px"}}>
              <Table
                data={props.recontinueMedData}
                getTrProps={props.onSelectRecontinueMed}
                headers=",แพทย์ผู้สั่ง,รหัสยา,ชื่อยา,วิธีใช้,จำนวน,หน่วย"
                keys="selected,order_by_name,code,name,label,quantity,unit_name"
                minRows="8"
                showPagination={false}
                style={{height: "100%"}}
                widths="50,,150,,150,150,150">
              </Table>
            </div>
            <div
              style={{display: "flex", justifyContent: "space-between"}}>
              <Button
                color="blue"
                onClick={props.onClickAddToOrder}>
                ADD TO ORDER
              </Button>
              <Button
                color="grey"
                onClick={props.onClickCancel}>
                CANCEL
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
}

export default ModRecontinueMedUX

export const screenPropsDefault = {}

/* Date Time : Fri Jan 27 2023 16:33:32 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\", padding: \"20px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "สั่งยาจากประวัติยา Continue"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"20px\", paddingBottom: \"10px\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "hr",
      "parent": 3,
      "props": {
      },
      "seq": 5,
      "void": true
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 2,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "id": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{height: \"350px\", marginBottom: \"30px\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 12,
      "name": "Table",
      "parent": 10,
      "props": {
        "data": {
          "type": "code",
          "value": "props.recontinueMedData"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.onSelectRecontinueMed"
        },
        "headers": {
          "type": "value",
          "value": ",แพทย์ผู้สั่ง,รหัสยา,ชื่อยา,วิธีใช้,จำนวน,หน่วย"
        },
        "keys": {
          "type": "value",
          "value": "selected,order_by_name,code,name,label,quantity,unit_name"
        },
        "minRows": {
          "type": "value",
          "value": "8"
        },
        "pageSize": {
          "type": "value",
          "value": ""
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"100%\"}"
        },
        "subRowsKey": {
          "type": "value",
          "value": ""
        },
        "widths": {
          "type": "value",
          "value": "50,,150,,150,150,150"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Button",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": "ADD TO ORDER"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickAddToOrder"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "FormGroup",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "FormField",
      "parent": 17,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "FormField",
      "parent": 17,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "FormField",
      "parent": 17,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 19,
      "props": {
        "children": {
          "type": "code",
          "value": "props.divisionSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "code",
          "value": "props.doctorSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", flex:1}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "Button",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSearchRecontinueMed"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "Button",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": "CANCEL"
        },
        "color": {
          "type": "value",
          "value": "grey"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickCancel"
        }
      },
      "seq": 26,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "ModRecontinueMedUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
