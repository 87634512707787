import { TDocumentDefinitions } from "pdfmake/interfaces";

import HeaderSummaryReportForm from "./HeaderSummaryReportForm";

const FormDoctorIncomeMonthlySummaryReport = async (props: any): Promise<TDocumentDefinitions> => {
  console.log("Props Form Doctor Income Monthly Summary: ", props);

  const headerForm = await HeaderSummaryReportForm({
    data: props.doctorIncomeMonthlyData?.params,
    header: [
      {
        alignment: "center",
        bold: true,
        fontSize: 15,
        text: "รายงานสรุปรายได้ส่วนแบ่งแพทย์ ตามแพทย์",
      },
      {
        alignment: "center",
        bold: true,
        fontSize: 15,
        text: `สาขาแพทย์ : ${props.doctorIncomeMonthlyData?.params?.specialty_name}`,
      },
      {
        alignment: "center",
        fontSize: 15,
        marginBottom: 5,
        text: `วันที่ ${props.doctorIncomeMonthlyData?.params?.start_date} ถึง ${props.doctorIncomeMonthlyData?.params?.end_date}`,
      },
    ],
  });

  const { font, images, styles } = headerForm;

  const localeStringOption = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  const formatPercent = (value: string | number) => {
    if (value == null && value === "") {
      return "";
    }
  
    const numberVal = typeof value === "string" ? parseInt(value) : value;
  
    if (isNaN(numberVal)) {
      return "";
    }
  
    return `${numberVal}%`;
  };

  const generateTableBody = (data: any, columns: any) => {
    let body = [];

    // Header of Table
    body.push([
      { text: "ลำดับ", bold: true, alignment: "center", border: [true, true, true, false] },
      {
        text: "ชื่อ-สกุลแพทย์",
        bold: true,
        alignment: "center",
        border: [true, true, true, false],
      },
      { text: "รายได้", bold: true, alignment: "center", border: [true, true, true, false] },
      { text: "รายรับรวม", bold: true, alignment: "center", border: [true, true, true, false] },
      { text: "รายได้คลินิก", bold: true, alignment: "center", colSpan: 4 },
      { text: "", bold: true, alignment: "center" },
      { text: "", bold: true, alignment: "center" },
      { text: "", bold: true, alignment: "center" },
      {
        text: "%ส่วนแบ่งแพทย์",
        bold: true,
        alignment: "center",
        border: [true, true, true, false],
      },
      { text: "ส่วนแบ่งแพทย์", bold: true, alignment: "center", colSpan: 2 },
      { text: "", bold: true, alignment: "center" },
    ]);

    body.push([
      { text: "", border: [true, false, true, true] },
      { text: "", border: [true, false, true, true] },
      { text: "", border: [true, false, true, true] },
      { text: "", border: [true, false, true, true] },
      { text: "%ค่าบริหาร", bold: true, alignment: "center" },
      { text: "หักค่าบริหาร", bold: true, alignment: "center" },
      { text: "ส่วนแบ่งคลินิก", bold: true, alignment: "center" },
      { text: "รายได้คลินิกรวม", bold: true, alignment: "center" },
      { text: "", bold: true, alignment: "center", border: [true, false, true, true] },
      { text: "รับเป็นเงิน", bold: true, alignment: "center" },
      { text: "รับเป็นแต้มสะสม (โครงการ)", bold: true, alignment: "center" },
    ]);

    if (data?.length > 0) {
      data.forEach((row: any) => {
        let dataRow: any = [];

        columns.forEach((column: any) => {
          if (row[column] !== null && row[column] !== undefined) {
            switch (column) {
              case "item_list":
                dataRow.push({ text: row[column].join("\n"), border: [true, true, true, false] });
                break;
              case "revenue_price_list":
              case "management_fee_list":
              case "clinic_compensation_list":
              case "clinic_revenue_list":
              case "final_revenue_credit_list":
              case "final_revenue_point_list":
                // Covert from number to string
                let convertedPrice = row[column]
                  .map((item: any) => item.toLocaleString("th-TH", localeStringOption))
                  .join("\n");
                dataRow.push({
                  text: convertedPrice,
                  alignment: "right",
                  border: [true, true, true, false],
                });
                break;
              case "compensation_percent_list":
                dataRow.push({
                  text: row[column].join("\n"),
                  alignment: "center",
                  border: [true, true, true, false],
                });
                break;
              case "management_fee_percent_list":
                // Format from number to persentage string
                let convertedPercent = row[column]
                  .map((item: any) => formatPercent(item))
                  .join("\n");
                dataRow.push({
                  text: convertedPercent,
                  alignment: "center",
                  border: [true, true, true, false],
                });
                break;
              default:
                dataRow.push({ text: row[column].toString(), border: [true, true, true, false] });
                break;
            }
          } else {
            dataRow.push({ text: "-" });
          }
        });
        body.push(dataRow);

        // Total each Seq
        body.push([
          { text: "", border: [true, false, true, true] },
          { text: "", border: [true, false, true, true] },
          { text: "Total รายรับ", bold: true, border: [true, false, true, true] },
          {
            text: row["total_revenue_price"].toLocaleString("th-TH", localeStringOption),
            bold: true,
            alignment: "right",
            border: [true, false, true, true],
          },
          { text: "", border: [true, false, true, true]  },
          {
            text: row["total_management_fee"].toLocaleString("th-TH", localeStringOption),
            bold: true,
            alignment: "right",
            border: [true, false, true, true],
          },
          {
            text: row["total_clinic_compensation"].toLocaleString("th-TH", localeStringOption),
            bold: true,
            alignment: "right",
            border: [true, false, true, true],
          },
          {
            text: row["total_clinic_revenue"].toLocaleString("th-TH", localeStringOption),
            bold: true,
            alignment: "right",
            border: [true, false, true, true],
          },
          { text: "", border: [true, false, true, true] },
          {
            text: row["total_final_revenue_credit"].toLocaleString("th-TH", localeStringOption),
            bold: true,
            alignment: "right",
            border: [true, false, true, true],
          },
          {
            text: row["total_final_revenue_point"].toLocaleString("th-TH", localeStringOption),
            bold: true,
            alignment: "right",
            border: [true, false, true, true],
          },
        ]);

        // Total Row
        body.push([
          { text: "Total", bold: true, colSpan: 9 },
          ...Array(8).fill({}),
          {
            text: row["total_final_revenue_credit"].toLocaleString("th-TH", localeStringOption),
            alignment: "right",
          },
          {
            text: row["total_final_revenue_point"].toLocaleString("th-TH", localeStringOption),
            alignment: "right",
          },
        ]);

        // Lab Row
        body.push([
          { text: "หักค่า Lab", bold: true, colSpan: 9 },
          ...Array(8).fill({}),
          {
            text: row["total_final_revenue_credit_lab"].toLocaleString("th-TH", localeStringOption),
            alignment: "right",
          },
          {
            text: row["total_final_revenue_point_lab"].toLocaleString("th-TH", localeStringOption),
            alignment: "right",
          },
        ]);

        // Deduct Lab Row
        body.push([
          { text: "รวมรายได้หลังหักค่า Lab", bold: true, colSpan: 9 },
          ...Array(8).fill({}),
          {
            text: row["total_final_revenue_credit_deduct_lab"].toLocaleString(
              "th-TH",
              localeStringOption
            ),
            bold: true,
            alignment: "right",
          },
          {
            text: row["total_final_revenue_point_deduct_lab"].toLocaleString(
              "th-TH",
              localeStringOption
            ),
            bold: true,
            alignment: "right",
          },
        ]);
      });
    }

    return body;
  };

  const tableReport = (data: any, columns: any) => {
    return {
      table: {
        widths: ["auto", "auto", "*", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
        headerRows: 2,
        body: generateTableBody(data, columns),
      },
    };
  };

  const generateSummaryTableBody = (data: any) => {
    let body = [];

    body.push([
      { text: "รายรับรวม" },
      {
        text: data.total_revenue_price_all.toLocaleString("th-TH", localeStringOption),
        alignment: "right",
      },
    ]);

    body.push([
      { text: "ส่วนแบ่งค่าบริหาร (20%)" },
      {
        text: data.total_management_fee_all.toLocaleString("th-TH", localeStringOption),
        alignment: "right",
      },
    ]);

    body.push([
      { text: "ส่วนแบ่งคลินิก" },
      {
        text: data.total_clinic_compensation_all.toLocaleString("th-TH", localeStringOption),
        alignment: "right",
      },
    ]);

    body.push([
      { text: "รายได้คลินิกรวม" },
      {
        text: data.total_clinic_revenue_all.toLocaleString("th-TH", localeStringOption),
        alignment: "right",
      },
    ]);

    body.push([
      { text: "ส่วนแบ่งแพทย์รับเป็นเงิน" },
      {
        text: data.total_final_revenue_credit_all.toLocaleString("th-TH", localeStringOption),
        alignment: "right",
      },
    ]);

    body.push([
      { text: "หักค่า Lab แพทย์ (รวม)" },
      {
        text: data.total_final_revenue_lab_all.toLocaleString("th-TH", localeStringOption),
        alignment: "right",
      },
    ]);

    body.push([
      { text: "รวมส่วนแบ่งแพทย์หลังหักค่า Lab" },
      {
        text: data.total_final_revenue_deduct_lab_all.toLocaleString("th-TH", localeStringOption),
        alignment: "right",
      },
    ]);

    body.push([
      { text: "ส่วนแบ่งแพทย์รับเป็นแต้มสะสม (โครงการ)" },
      {
        text: data.total_final_revenue_point_all.toLocaleString("th-TH", localeStringOption),
        alignment: "right",
      },
    ]);

    return body;
  };

  const tableSummaryReport = (data: any) => {
    return {
      unbreakable: true,
      table: {
        widths: ["20%", "10%"],
        body: generateSummaryTableBody(data),
      },
    };
  };

  return {
    ...headerForm,
    content: [
      tableReport(props.doctorIncomeMonthlyData?.fields || [], [
        "seq",
        "doctor_name",
        "item_list",
        "revenue_price_list",
        "management_fee_percent_list",
        "management_fee_list",
        "clinic_compensation_list",
        "clinic_revenue_list",
        "compensation_percent_list",
        "final_revenue_credit_list",
        "final_revenue_point_list",
      ]),
      { text: " ", preserveLeadingSpaces: true },
      tableSummaryReport(props.doctorIncomeMonthlyData?.params || []),
    ],
    images: {
      ...images,
    },
    styles: {
      ...styles
    },
    pageOrientation: `landscape`,
    defaultStyle: {
      font,
      // fontSize: 14,
    },
    pageSize: `A4`,
  };
};

export default FormDoctorIncomeMonthlySummaryReport;
