import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Label,
  Form,
  FormGroup,
  FormField,
  Checkbox,
  Input
} from 'semantic-ui-react'

const CardIrrigationUX = (props: any) => {
    return(
      <div
        id="CardIrrigation"
        style={{ margin: "15px",  padding: "20px",  backgroundColor: "rgba(198, 235, 243, 0.2)" }}>
        <div
          style={{ paddingBottom: "5px" }}>
          
          <label
            style={{ fontWeight: "bold", fontSize: "18px" }}>
            Irrigation
          </label>
          <Label
            color={props.statusColor}
            style={{display: props.config?.hideStatus ? "none" : "", float: "right" }}>
            {props.status}
          </Label>
          <hr>
          </hr>
        </div>
        <Form>
          <FormGroup>
            <FormField>
              <label
                style={{minWidth: "120px", maxWidth: "120px"}}>
                Irrigation
              </label>
            </FormField>
            <FormField>
              <Checkbox
                checked={props.isIrrigation || false}
                label={props.isIrrigation ? "Yes" : "No"}
                name="is_irrigation"
                onChange={props.onChangeData}
                toggle={true}>
              </Checkbox>
            </FormField>
            <FormField
              width={16}>
              <Form>
                <FormGroup
                  inline={true}>
                  <FormField
                    inline={true}>
                    <Checkbox
                      checked={props.isNss || false}
                      disabled={!props.config?.enabledNss}
                      label="NSS"
                      name="is_nss"
                      onChange={props.onChangeData}>
                    </Checkbox>
                  </FormField>
                </FormGroup>
                <FormGroup
                  inline={true}>
                  <FormField
                    inline={true}>
                    <Checkbox
                      checked={props.isWater || false}
                      disabled={!props.config?.enabledWater}
                      label="Water"
                      name="is_water"
                      onChange={props.onChangeData}>
                    </Checkbox>
                  </FormField>
                </FormGroup>
                <FormGroup
                  inline={true}>
                  <FormField
                    inline={true}>
                    <Checkbox
                      checked={props.isGlycline || false}
                      disabled={!props.config?.enabledGlycline}
                      label="Glycline"
                      name="is_glycline"
                      onChange={props.onChangeData}>
                    </Checkbox>
                  </FormField>
                </FormGroup>
                <FormGroup
                  inline={true}>
                  <FormField
                    inline={true}>
                    <Checkbox
                      checked={props.isBss || false}
                      disabled={!props.config?.enabledBss}
                      label="BSS"
                      name="is_bss"
                      onChange={props.onChangeData}
                      style={{minWidth: "65px", maxWidth: "65px"}}>
                    </Checkbox>
                  </FormField>
                  <FormField
                    inline={true}
                    width={7}>
                    <Input
                      disabled={!props.config?.enabledBssDetail}
                      name="bss"
                      onChange={props.onChangeData}
                      value={props.bss || ""}>
                    </Input>
                  </FormField>
                </FormGroup>
                <FormGroup
                  inline={true}>
                  <FormField
                    inline={true}>
                    <Checkbox
                      checked={props.isOtherIrrigation || false}
                      disabled={!props.config?.enabledOther}
                      label="Other"
                      name="is_other_irrigation"
                      onChange={props.onChangeData}
                      style={{minWidth: "65px", maxWidth: "65px"}}>
                    </Checkbox>
                  </FormField>
                  <FormField
                    inline={true}
                    width={7}>
                    <Input
                      disabled={!props.config?.enabledOtherDetail}
                      name="other_irrigation"
                      onChange={props.onChangeData}
                      value={props.otherIrrigation || ""}>
                    </Input>
                  </FormField>
                </FormGroup>
                <FormGroup
                  inline={true}
                  style={{margin:0}}>
                  <FormField
                    width={8}>
                    <label
                      style={{fontWeight: "normal"}}>
                      In
                    </label>
                    <Input
                      disabled={!props.config?.enabledIn}
                      name="irrigation_in"
                      onChange={props.onChangeData}
                      value={props.irrigationIn || ""}>
                    </Input>
                    <label
                      style={{fontWeight: "normal", marginLeft: "0.5rem"}}>
                      ml
                    </label>
                    <label
                      style={{fontWeight: "normal", marginLeft: "1rem", marginRight: "1rem"}}>
                      Out
                    </label>
                    <Input
                      disabled={!props.config?.enabledOut}
                      name="irrigation_out"
                      onChange={props.onChangeData}
                      value={props.irrigationOut || ""}>
                    </Input>
                    <label
                      style={{fontWeight: "normal", marginLeft: "0.5rem"}}>
                      ml
                    </label>
                  </FormField>
                </FormGroup>
              </Form>
            </FormField>
          </FormGroup>
        </Form>
        <div
          style={{ height: "55px", paddingTop: "15px" }}>
          
          <div
            style={{ float: "right", display: "flex" }}>
            
            <div
              style={{margin: "0 0.25rem"}}>
              {props.ButtonSave}
            </div>
            <div
              style={{margin: "0 0.25rem"}}>
              {props.ButtonConfirm}
            </div>
            <div
              style={{margin: "0 0.25rem"}}>
              {props.ButtonUnConfirm}
            </div>
          </div>
        </div>
      </div>
    )
}


export default CardIrrigationUX

export const screenPropsDefault = {}

/* Date Time : Wed Dec 27 2023 16:32:05 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardIrrigation"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"15px\",  padding: \"20px\",  backgroundColor: \"rgba(198, 235, 243, 0.2)\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ paddingBottom: \"5px\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "label",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Irrigation"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"18px\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "Label",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.status"
        },
        "color": {
          "type": "code",
          "value": "props.statusColor"
        },
        "style": {
          "type": "code",
          "value": "{display: props.config?.hideStatus ? \"none\" : \"\", float: \"right\" }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "hr",
      "parent": 1,
      "props": {
      },
      "seq": 4,
      "void": true
    },
    {
      "from": null,
      "id": 154,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ height: \"55px\", paddingTop: \"15px\" }"
        }
      },
      "seq": 192,
      "void": false
    },
    {
      "from": null,
      "id": 155,
      "name": "div",
      "parent": 154,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ float: \"right\", display: \"flex\" }"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": null,
      "id": 189,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSave"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 0.25rem\"}"
        }
      },
      "seq": 189,
      "void": false
    },
    {
      "from": null,
      "id": 190,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonConfirm"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 0.25rem\"}"
        }
      },
      "seq": 190,
      "void": false
    },
    {
      "from": null,
      "id": 191,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonUnConfirm"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 0.25rem\"}"
        }
      },
      "seq": 191,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 192,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 169,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 193,
      "name": "FormGroup",
      "parent": 192,
      "props": {
      },
      "seq": 193,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 194,
      "name": "FormField",
      "parent": 193,
      "props": {
      },
      "seq": 194,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 195,
      "name": "FormField",
      "parent": 193,
      "props": {
      },
      "seq": 195,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 196,
      "name": "FormField",
      "parent": 193,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 196,
      "void": false
    },
    {
      "from": null,
      "id": 198,
      "name": "label",
      "parent": 194,
      "props": {
        "children": {
          "type": "value",
          "value": "Irrigation"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"120px\", maxWidth: \"120px\"}"
        }
      },
      "seq": 198,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 199,
      "name": "Checkbox",
      "parent": 195,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isIrrigation || false"
        },
        "label": {
          "type": "code",
          "value": "props.isIrrigation ? \"Yes\" : \"No\""
        },
        "name": {
          "type": "value",
          "value": "is_irrigation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 199,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 200,
      "name": "Form",
      "parent": 196,
      "props": {
      },
      "seq": 200,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 201,
      "name": "FormGroup",
      "parent": 200,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 201,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 202,
      "name": "FormGroup",
      "parent": 200,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 202,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 203,
      "name": "FormGroup",
      "parent": 200,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 203,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 204,
      "name": "FormGroup",
      "parent": 200,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 204,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 205,
      "name": "FormGroup",
      "parent": 200,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 205,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 206,
      "name": "FormGroup",
      "parent": 200,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{margin:0}"
        }
      },
      "seq": 206,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 207,
      "name": "FormField",
      "parent": 201,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 207,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 208,
      "name": "FormField",
      "parent": 202,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 208,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 209,
      "name": "FormField",
      "parent": 203,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 209,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 210,
      "name": "FormField",
      "parent": 204,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 210,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 211,
      "name": "FormField",
      "parent": 205,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 211,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 212,
      "name": "FormField",
      "parent": 206,
      "props": {
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 212,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 213,
      "name": "Checkbox",
      "parent": 207,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isNss || false"
        },
        "disabled": {
          "type": "code",
          "value": "!props.config?.enabledNss"
        },
        "label": {
          "type": "value",
          "value": "NSS"
        },
        "name": {
          "type": "value",
          "value": "is_nss"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "toggle": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 213,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 214,
      "name": "Checkbox",
      "parent": 208,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isWater || false"
        },
        "disabled": {
          "type": "code",
          "value": "!props.config?.enabledWater"
        },
        "label": {
          "type": "value",
          "value": "Water"
        },
        "name": {
          "type": "value",
          "value": "is_water"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 214,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 215,
      "name": "Checkbox",
      "parent": 209,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isGlycline || false"
        },
        "disabled": {
          "type": "code",
          "value": "!props.config?.enabledGlycline"
        },
        "label": {
          "type": "value",
          "value": "Glycline"
        },
        "name": {
          "type": "value",
          "value": "is_glycline"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 215,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 216,
      "name": "Checkbox",
      "parent": 210,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isBss || false"
        },
        "disabled": {
          "type": "code",
          "value": "!props.config?.enabledBss"
        },
        "label": {
          "type": "value",
          "value": "BSS"
        },
        "name": {
          "type": "value",
          "value": "is_bss"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"65px\", maxWidth: \"65px\"}"
        }
      },
      "seq": 216,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 217,
      "name": "FormField",
      "parent": 204,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 217,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 218,
      "name": "Input",
      "parent": 217,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.config?.enabledBssDetail"
        },
        "name": {
          "type": "value",
          "value": "bss"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.bss || \"\""
        }
      },
      "seq": 218,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 219,
      "name": "FormField",
      "parent": 205,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 219,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 220,
      "name": "Checkbox",
      "parent": 211,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isOtherIrrigation || false"
        },
        "disabled": {
          "type": "code",
          "value": "!props.config?.enabledOther"
        },
        "label": {
          "type": "value",
          "value": "Other"
        },
        "name": {
          "type": "value",
          "value": "is_other_irrigation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"65px\", maxWidth: \"65px\"}"
        }
      },
      "seq": 220,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 221,
      "name": "Input",
      "parent": 219,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.config?.enabledOtherDetail"
        },
        "name": {
          "type": "value",
          "value": "other_irrigation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.otherIrrigation || \"\""
        }
      },
      "seq": 221,
      "void": false
    },
    {
      "from": null,
      "id": 222,
      "name": "label",
      "parent": 212,
      "props": {
        "children": {
          "type": "value",
          "value": "In"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 222,
      "void": false
    },
    {
      "from": null,
      "id": 223,
      "name": "label",
      "parent": 212,
      "props": {
        "children": {
          "type": "value",
          "value": "ml"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", marginLeft: \"0.5rem\"}"
        }
      },
      "seq": 224,
      "void": false
    },
    {
      "from": null,
      "id": 224,
      "name": "label",
      "parent": 212,
      "props": {
        "children": {
          "type": "value",
          "value": "Out"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", marginLeft: \"1rem\", marginRight: \"1rem\"}"
        }
      },
      "seq": 225,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 225,
      "name": "Input",
      "parent": 212,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.config?.enabledIn"
        },
        "name": {
          "type": "value",
          "value": "irrigation_in"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.irrigationIn || \"\""
        }
      },
      "seq": 223,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 226,
      "name": "Input",
      "parent": 212,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.config?.enabledOut"
        },
        "name": {
          "type": "value",
          "value": "irrigation_out"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.irrigationOut || \"\""
        }
      },
      "seq": 226,
      "void": false
    },
    {
      "from": null,
      "id": 227,
      "name": "label",
      "parent": 212,
      "props": {
        "children": {
          "type": "value",
          "value": "ml"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", marginLeft: \"0.5rem\"}"
        }
      },
      "seq": 227,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 85,
  "isMounted": false,
  "memo": false,
  "name": "CardIrrigationUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
