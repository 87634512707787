import React, { FC, forwardRef, useImperativeHandle, useRef } from "react";
import { Checkbox, Form, Grid, Header, Input, TextArea } from "semantic-ui-react";
import CardMotorPower from "./CardMotorPower";
import CardMuscleStrength from "./CardMuscleStrength";
import CardMuscleTone from "./CardMuscleTone";
import CardRangeOfMotion from "./CardRangeOfMotion";
import { useIntl } from "react-intl";

const styles = {
  gridBG: {
    background: "#F3F3F3",
    marginLeft: "2px",
    marginRight: "1px",
    paddingLeft: "11px",
  },
  noPadding: {
    padding: "0px",
  },
  topPaddingSec: {
    paddingTop: "15px",
  },
  topPaddingTop: {
    paddingTop: "30px",
  },
  bottomPadding: {
    paddingBottom: "15px",
  },
  leftPadding: {
    paddingLeft: "5px",
  },
};

export interface CardPTPhysicalExamNeuroogialMIDViewDataType {
  viewData: {
    // muscleStrength: {
    //   isNormal: boolean;
    //   items: [
    //     {
    //       normalRemark: string;
    //       grade: string;
    //     }
    //   ];
    // };
    motorPower: {
      isRightLeft: boolean;
      rigthTop: string;
      rigthBottom: string;
      leftTop: string;
      leftBottom: string;
      isNA: boolean;
      motorDetail: string;
    };
    muscleTone: {
      isNormal: boolean;
      normal: string;
      isSpasticity: boolean;
      spasticity: string;
      isFlaccid: boolean;
      flaccid: string;
      isNA: boolean;
    };
    rangeOfMotion: {
      isFull: boolean;
      items: [{ limit: string }];
    };
  };
  readonly: boolean;
}

const CardPTPhysicalExamNeuroogialMID: FC<CardPTPhysicalExamNeuroogialMIDViewDataType> = forwardRef(
  (props, ref) => {
    // Muscle Strength
    // const subMuscleStrengthRef = useRef();

    // Motor Power
    const subMotorPowerRef = useRef();

    // Muscle tone
    const subMuscleToneRef = useRef();

    // Range Of Motion
    const subRangeOfMotionRef = useRef();

    useImperativeHandle(ref, () => ({
      getData: () => {
        return {
          // muscleStrength: subMuscleStrengthRef.current.getData(),
          motorPower: subMotorPowerRef.current.getData(),
          muscleTone: subMuscleToneRef.current.getData(),
          rangeOfMotion: subRangeOfMotionRef.current.getData(),
        };
      },
    }));

    return (
      <div style={styles.gridBG}>
        {/* Muscle strength */}
        <Grid style={styles.topPaddingTop}>
          <Grid.Row columns={2} style={styles.noPadding}>
            <Grid.Column width={2}>
              <Form>
                <Form.Group inline>
                  <Form.Field width={2}></Form.Field>
                  <Form.Field width={14}>
                    <Header.Content>
                      <strong>Motor Power</strong>
                    </Header.Content>
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
            <Grid.Column width={8}>
              <CardMotorPower 
                ref={subMotorPowerRef}
                data={props.viewData.motorPower}
                readonly={props.readonly}
                languageUX={props.languageUX}
              />
              {/* <Form>
                <Form.Group inline>
                  <Form.Field width={2}>
                    <Checkbox />
                  </Form.Field>
                  <Form.Field width={7} style={{justifyContent: "center"}}>
                    Right
                  </Form.Field>
                  <Form.Field width={7} style={{justifyContent: "center"}}>
                    Left
                  </Form.Field>
                </Form.Group>

                <Form.Group inline>
                  <Form.Field width={2} />
                  <Form.Field width={7}>
                    <Input />
                  </Form.Field>
                  <Form.Field width={7}>
                    <Input />
                  </Form.Field>
                </Form.Group>

                <Form.Group>
                  <Form.Field width={2}/>
                  <Form.Field width={14}>
                  <div
                    style={{border: "solid lightgrey 1px", height: "100%", width: "95%"}}
                    />
                  </Form.Field>
                </Form.Group>

                <Form.Group inline>
                  <Form.Field width={2} />
                  <Form.Field width={7}>
                    <Input />
                  </Form.Field>
                  <Form.Field width={7}>
                    <Input />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
            <Grid.Column width={4}>
              <Form>
                <Form.Group inline>
                  <Form.Field width={1}>
                    <Checkbox/> 
                  </Form.Field>
                  <Form.Field width={1}>
                    NA
                    </Form.Field>
                </Form.Group>

                  <Form.Group>
                    <Form.Field width={2} />
                    <Form.Field width={14}>
                      <TextArea rows="3" style={{resize:"auto"}}/>
                    </Form.Field>
                  </Form.Group>
              </Form> */}
            </Grid.Column>
          </Grid.Row>
          {/* <Grid.Row columns={2} style={styles.noPadding}>
            <Grid.Column width={2}>
              <Form>
                <Form.Group inline>
                  <Form.Field width={2}></Form.Field>
                  <Form.Field width={14}>
                    <Header.Content>
                      <strong>Muscle strength</strong>
                    </Header.Content>
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>

            <Grid.Column width={14} style={styles.leftPadding}>
              <CardMuscleStrength
                ref={subMuscleStrengthRef}
                data={props.viewData.muscleStrength}
                readonly={props.readonly}
              />
            </Grid.Column>
          </Grid.Row> */}
        </Grid>

        {/* Muscle Tone */}
        <Grid style={styles.topPaddingSec}>
          <Grid.Row columns={1} style={styles.noPadding}>
            <Grid.Column width={2}>
              <Form>
                <Form.Group inline>
                  <Form.Field width={2}></Form.Field>
                  <Form.Field width={14}>
                    <Header.Content>
                      <strong>Muscle tone</strong>
                    </Header.Content>
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
            <Grid.Column width={14} style={styles.leftPadding}>
              <CardMuscleTone
                ref={subMuscleToneRef}
                data={props.viewData.muscleTone}
                readonly={props.readonly}
                languageUX={props.languageUX}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {/* Range of motion */}
        <Grid style={styles.bottomPadding}>
          <Grid.Row columns={2} style={styles.noPadding}>
            <Grid.Column width={2}>
              <Form>
                <Form.Group inline>
                  <Form.Field width={1}></Form.Field>
                  <Form.Field width={15}>
                    <Header.Content>
                      <strong>Range of motion</strong>
                    </Header.Content>
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
            <Grid.Column width={14} style={styles.leftPadding}>
              <CardRangeOfMotion
                ref={subRangeOfMotionRef}
                data={props.viewData.rangeOfMotion}
                readonly={props.readonly}
                languageUX={props.languageUX}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
);

export const CardPTPhysicalExamNeuroogialMIDInitialViewData: CardPTPhysicalExamNeuroogialMIDViewDataType = {
  viewData: {
    // muscleStrength: {
    //   isNormal: false,
    //   items: [{ normalRemark: "", grade: "" }],
    // },
    motorPower: {
      isRightLeft: false,
      rigthTop: "",
      rigthBottom: "",
      leftTop: "",
      leftBottom: "",
      isNA: false,
      motorDetail: ""
    },
    muscleTone: {
      isNormal: true,
      normal: "",
      isSpasticity: false,
      spasticity: "",
      isFlaccid: false,
      flaccid: "",
      isNA: false,
    },
    rangeOfMotion: {
      isFull: true,
      items: [{ limit: "" }],
    },
  },
  readonly: false,
};


CardPTPhysicalExamNeuroogialMID.defaultProps = CardPTPhysicalExamNeuroogialMIDInitialViewData;

export default CardPTPhysicalExamNeuroogialMID;
