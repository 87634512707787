import React from 'react'
import {
   Button,
   Checkbox,
   Dropdown,
   Input,
   Radio,
   TextArea
} from 'semantic-ui-react'



import styles from './CardOTDiagnosisPhysicalDysfunctionLOW.module.css'

export interface CardOTDiagnosisPhysicalDysfunctionLOWViewDataType {
    OtDiagnosisPhysicalDysfunctionLow3: string,
    OtDiagnosisPhysicalDysfunctionLow3Disabled: boolean,
    OtDiagnosisPhysicalDysfunctionLow4: string,
    OtDiagnosisPhysicalDysfunctionLow4Disabled: boolean,
    OtDiagnosisPhysicalDysfunctionLow7: boolean,
    OtDiagnosisPhysicalDysfunctionLow7Disabled: boolean,
    OtDiagnosisPhysicalDysfunctionLow8: boolean,
    OtDiagnosisPhysicalDysfunctionLow8Disabled: boolean,
    OtDiagnosisPhysicalDysfunctionLow10: boolean,
    OtDiagnosisPhysicalDysfunctionLow10Disabled: boolean,
    OtDiagnosisPhysicalDysfunctionLow12: boolean,
    OtDiagnosisPhysicalDysfunctionLow12Disabled: boolean,
    OtDiagnosisPhysicalDysfunctionLow15: boolean,
    OtDiagnosisPhysicalDysfunctionLow15Disabled: boolean,
    OtDiagnosisPhysicalDysfunctionLow16: boolean,
    OtDiagnosisPhysicalDysfunctionLow16Disabled: boolean,
    OtDiagnosisPhysicalDysfunctionLow20: boolean,
    OtDiagnosisPhysicalDysfunctionLow20Disabled: boolean,
    OtDiagnosisPhysicalDysfunctionLow21: string,
    OtDiagnosisPhysicalDysfunctionLow21Disabled: boolean,
    OtDiagnosisPhysicalDysfunctionLow22: boolean,
    OtDiagnosisPhysicalDysfunctionLow22Disabled: boolean,
    OtDiagnosisPhysicalDysfunctionLow23: boolean,
    OtDiagnosisPhysicalDysfunctionLow23Disabled: boolean,
    OtDiagnosisPhysicalDysfunctionLow24: boolean,
    OtDiagnosisPhysicalDysfunctionLow24Disabled: boolean,
    OtDiagnosisPhysicalDysfunctionLow29: boolean,
    OtDiagnosisPhysicalDysfunctionLow29Disabled: boolean,
    OtDiagnosisPhysicalDysfunctionLow30: boolean,
    OtDiagnosisPhysicalDysfunctionLow30Disabled: boolean,
    OtDiagnosisPhysicalDysfunctionLow31: boolean,
    OtDiagnosisPhysicalDysfunctionLow31Disabled: boolean,
    OtDiagnosisPhysicalDysfunctionLow33: boolean,
    OtDiagnosisPhysicalDysfunctionLow33Disabled: boolean,
    OtDiagnosisPhysicalDysfunctionLow36: boolean,
    OtDiagnosisPhysicalDysfunctionLow36Disabled: boolean,
    OtDiagnosisPhysicalDysfunctionLow37: boolean,
    OtDiagnosisPhysicalDysfunctionLow37Disabled: boolean,
    OtDiagnosisPhysicalDysfunctionLow39: boolean,
    OtDiagnosisPhysicalDysfunctionLow39Disabled: boolean,
    OtDiagnosisPhysicalDysfunctionLow44: boolean,
    OtDiagnosisPhysicalDysfunctionLow44Disabled: boolean,
    OtDiagnosisPhysicalDysfunctionLow45: string,
    OtDiagnosisPhysicalDysfunctionLow45Disabled: boolean,
    OtDiagnosisPhysicalDysfunctionLow46: boolean,
    OtDiagnosisPhysicalDysfunctionLow46Disabled: boolean,
    OtDiagnosisPhysicalDysfunctionLow47: boolean,
    OtDiagnosisPhysicalDysfunctionLow47Disabled: boolean,
    OtDiagnosisPhysicalDysfunctionLow49: boolean,
    OtDiagnosisPhysicalDysfunctionLow49Disabled: boolean,
    OtDiagnosisPhysicalDysfunctionLow51: boolean,
    OtDiagnosisPhysicalDysfunctionLow51Disabled: boolean,
    OtDiagnosisPhysicalDysfunctionLow52: boolean,
    OtDiagnosisPhysicalDysfunctionLow52Disabled: boolean,
    OtDiagnosisPhysicalDysfunctionLow57: string,
    OtDiagnosisPhysicalDysfunctionLow57Disabled: boolean,
    OtDiagnosisPhysicalDysfunctionLow58: boolean,
    OtDiagnosisPhysicalDysfunctionLow58Disabled: boolean,
    OtDiagnosisPhysicalDysfunctionLow60: string,
    OtDiagnosisPhysicalDysfunctionLow60Disabled: boolean,
    OtDiagnosisPhysicalDysfunctionLow62: boolean,
    OtDiagnosisPhysicalDysfunctionLow62Disabled: boolean,
    Ct3Premorbid: string,
    Ct3PremorbidDisabled: boolean,
    Ct3PremorbidOptions: any,
    OtDiagnosisPhysicalDysfunctionLow67: string,
    OtDiagnosisPhysicalDysfunctionLow67Disabled: boolean,
    OtDiagnosisPhysicalDysfunctionLow69: boolean,
    OtDiagnosisPhysicalDysfunctionLow69Disabled: boolean,
    OtDiagnosisPhysicalDysfunctionLow71: boolean,
    OtDiagnosisPhysicalDysfunctionLow71Disabled: boolean,
    OtDiagnosisPhysicalDysfunctionLow74: string,
    OtDiagnosisPhysicalDysfunctionLow74Disabled: boolean,
    SaveRequestDisabled: boolean,
}

export const CardOTDiagnosisPhysicalDysfunctionLOWInitialViewData: CardOTDiagnosisPhysicalDysfunctionLOWViewDataType = {
    OtDiagnosisPhysicalDysfunctionLow3: "",
    OtDiagnosisPhysicalDysfunctionLow3Disabled: false,
    OtDiagnosisPhysicalDysfunctionLow4: "",
    OtDiagnosisPhysicalDysfunctionLow4Disabled: false,
    OtDiagnosisPhysicalDysfunctionLow7: false,
    OtDiagnosisPhysicalDysfunctionLow7Disabled: false,
    OtDiagnosisPhysicalDysfunctionLow8: false,
    OtDiagnosisPhysicalDysfunctionLow8Disabled: false,
    OtDiagnosisPhysicalDysfunctionLow10: false,
    OtDiagnosisPhysicalDysfunctionLow10Disabled: false,
    OtDiagnosisPhysicalDysfunctionLow12: false,
    OtDiagnosisPhysicalDysfunctionLow12Disabled: false,
    OtDiagnosisPhysicalDysfunctionLow15: false,
    OtDiagnosisPhysicalDysfunctionLow15Disabled: false,
    OtDiagnosisPhysicalDysfunctionLow16: false,
    OtDiagnosisPhysicalDysfunctionLow16Disabled: false,
    OtDiagnosisPhysicalDysfunctionLow20: false,
    OtDiagnosisPhysicalDysfunctionLow20Disabled: false,
    OtDiagnosisPhysicalDysfunctionLow21: "",
    OtDiagnosisPhysicalDysfunctionLow21Disabled: false,
    OtDiagnosisPhysicalDysfunctionLow22: false,
    OtDiagnosisPhysicalDysfunctionLow22Disabled: false,
    OtDiagnosisPhysicalDysfunctionLow23: false,
    OtDiagnosisPhysicalDysfunctionLow23Disabled: false,
    OtDiagnosisPhysicalDysfunctionLow24: false,
    OtDiagnosisPhysicalDysfunctionLow24Disabled: false,
    OtDiagnosisPhysicalDysfunctionLow29: false,
    OtDiagnosisPhysicalDysfunctionLow29Disabled: false,
    OtDiagnosisPhysicalDysfunctionLow30: false,
    OtDiagnosisPhysicalDysfunctionLow30Disabled: false,
    OtDiagnosisPhysicalDysfunctionLow31: false,
    OtDiagnosisPhysicalDysfunctionLow31Disabled: false,
    OtDiagnosisPhysicalDysfunctionLow33: false,
    OtDiagnosisPhysicalDysfunctionLow33Disabled: false,
    OtDiagnosisPhysicalDysfunctionLow36: false,
    OtDiagnosisPhysicalDysfunctionLow36Disabled: false,
    OtDiagnosisPhysicalDysfunctionLow37: false,
    OtDiagnosisPhysicalDysfunctionLow37Disabled: false,
    OtDiagnosisPhysicalDysfunctionLow39: false,
    OtDiagnosisPhysicalDysfunctionLow39Disabled: false,
    OtDiagnosisPhysicalDysfunctionLow44: false,
    OtDiagnosisPhysicalDysfunctionLow44Disabled: false,
    OtDiagnosisPhysicalDysfunctionLow45: "",
    OtDiagnosisPhysicalDysfunctionLow45Disabled: false,
    OtDiagnosisPhysicalDysfunctionLow46: false,
    OtDiagnosisPhysicalDysfunctionLow46Disabled: false,
    OtDiagnosisPhysicalDysfunctionLow47: false,
    OtDiagnosisPhysicalDysfunctionLow47Disabled: false,
    OtDiagnosisPhysicalDysfunctionLow49: false,
    OtDiagnosisPhysicalDysfunctionLow49Disabled: false,
    OtDiagnosisPhysicalDysfunctionLow51: false,
    OtDiagnosisPhysicalDysfunctionLow51Disabled: false,
    OtDiagnosisPhysicalDysfunctionLow52: false,
    OtDiagnosisPhysicalDysfunctionLow52Disabled: false,
    OtDiagnosisPhysicalDysfunctionLow57: "",
    OtDiagnosisPhysicalDysfunctionLow57Disabled: false,
    OtDiagnosisPhysicalDysfunctionLow58: false,
    OtDiagnosisPhysicalDysfunctionLow58Disabled: false,
    OtDiagnosisPhysicalDysfunctionLow60: "",
    OtDiagnosisPhysicalDysfunctionLow60Disabled: false,
    OtDiagnosisPhysicalDysfunctionLow62: false,
    OtDiagnosisPhysicalDysfunctionLow62Disabled: false,
    Ct3Premorbid: "",
    Ct3PremorbidDisabled: false,
    Ct3PremorbidOptions: [{key: 0, value: "A", text: "A"}],
    OtDiagnosisPhysicalDysfunctionLow67: "",
    OtDiagnosisPhysicalDysfunctionLow67Disabled: false,
    OtDiagnosisPhysicalDysfunctionLow69: false,
    OtDiagnosisPhysicalDysfunctionLow69Disabled: false,
    OtDiagnosisPhysicalDysfunctionLow71: false,
    OtDiagnosisPhysicalDysfunctionLow71Disabled: false,
    OtDiagnosisPhysicalDysfunctionLow74: "",
    OtDiagnosisPhysicalDysfunctionLow74Disabled: false,
    SaveRequestDisabled: false,
}

const Sub = (props: any) => {
    const children = React.Children.toArray(props.children)
    return (
        <div className={styles.container}>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow2}>
                <div
                    style={{background: "#F3F3F3", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow3}>
                <Input
                    value={props.viewData.OtDiagnosisPhysicalDysfunctionLow3}
                    disabled={ props.viewData.OtDiagnosisPhysicalDysfunctionLow3Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTDiagnosisPhysicalDysfunctionLOW',
                            name: 'OtDiagnosisPhysicalDysfunctionLow3',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow4}>
                <Input
                    value={props.viewData.OtDiagnosisPhysicalDysfunctionLow4}
                    disabled={ props.viewData.OtDiagnosisPhysicalDysfunctionLow4Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTDiagnosisPhysicalDysfunctionLOW',
                            name: 'OtDiagnosisPhysicalDysfunctionLow4',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow5}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Chief complaint*</strong></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow6}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Underlying disease</strong></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow7}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPhysicalDysfunctionLow7}
                    disabled={ props.viewData.OtDiagnosisPhysicalDysfunctionLow7Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPhysicalDysfunctionLOW",
                                name: "OtDiagnosisPhysicalDysfunctionLow7"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow8}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPhysicalDysfunctionLow8}
                    disabled={ props.viewData.OtDiagnosisPhysicalDysfunctionLow8Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPhysicalDysfunctionLOW",
                                name: "OtDiagnosisPhysicalDysfunctionLow8"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow9}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Consult for*</strong></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow10}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPhysicalDysfunctionLow10}
                    disabled={ props.viewData.OtDiagnosisPhysicalDysfunctionLow10Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPhysicalDysfunctionLOW",
                                name: "OtDiagnosisPhysicalDysfunctionLow10"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow11}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Developmental Stimulation</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow12}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPhysicalDysfunctionLow12}
                    disabled={ props.viewData.OtDiagnosisPhysicalDysfunctionLow12Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPhysicalDysfunctionLOW",
                                name: "OtDiagnosisPhysicalDysfunctionLow12"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow13}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Kinetic activites</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow14}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>ADL evaluation &amp; training</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow15}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPhysicalDysfunctionLow15}
                    disabled={ props.viewData.OtDiagnosisPhysicalDysfunctionLow15Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPhysicalDysfunctionLOW",
                                name: "OtDiagnosisPhysicalDysfunctionLow15"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow16}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPhysicalDysfunctionLow16}
                    disabled={ props.viewData.OtDiagnosisPhysicalDysfunctionLow16Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPhysicalDysfunctionLOW",
                                name: "OtDiagnosisPhysicalDysfunctionLow16"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow17}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Cognitive/Perceptual evaluation &amp; training</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow18}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Adaptive device</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow19}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Multi-sensory room</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow20}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPhysicalDysfunctionLow20}
                    disabled={ props.viewData.OtDiagnosisPhysicalDysfunctionLow20Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPhysicalDysfunctionLOW",
                                name: "OtDiagnosisPhysicalDysfunctionLow20"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow21}>
                <Input
                    value={props.viewData.OtDiagnosisPhysicalDysfunctionLow21}
                    disabled={ props.viewData.OtDiagnosisPhysicalDysfunctionLow21Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTDiagnosisPhysicalDysfunctionLOW',
                            name: 'OtDiagnosisPhysicalDysfunctionLow21',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow22}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPhysicalDysfunctionLow22}
                    disabled={ props.viewData.OtDiagnosisPhysicalDysfunctionLow22Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPhysicalDysfunctionLOW",
                                name: "OtDiagnosisPhysicalDysfunctionLow22"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow23}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPhysicalDysfunctionLow23}
                    disabled={ props.viewData.OtDiagnosisPhysicalDysfunctionLow23Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPhysicalDysfunctionLOW",
                                name: "OtDiagnosisPhysicalDysfunctionLow23"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow24}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPhysicalDysfunctionLow24}
                    disabled={ props.viewData.OtDiagnosisPhysicalDysfunctionLow24Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPhysicalDysfunctionLOW",
                                name: "OtDiagnosisPhysicalDysfunctionLow24"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow25}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Swallowing evaluation &amp; training</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow26}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Hand orthosis/splint</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow27}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Home program</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow28}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Other</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow29}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPhysicalDysfunctionLow29}
                    disabled={ props.viewData.OtDiagnosisPhysicalDysfunctionLow29Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPhysicalDysfunctionLOW",
                                name: "OtDiagnosisPhysicalDysfunctionLow29"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow30}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPhysicalDysfunctionLow30}
                    disabled={ props.viewData.OtDiagnosisPhysicalDysfunctionLow30Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPhysicalDysfunctionLOW",
                                name: "OtDiagnosisPhysicalDysfunctionLow30"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow31}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPhysicalDysfunctionLow31}
                    disabled={ props.viewData.OtDiagnosisPhysicalDysfunctionLow31Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPhysicalDysfunctionLOW",
                                name: "OtDiagnosisPhysicalDysfunctionLow31"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow32}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Precaution*</strong></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow33}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPhysicalDysfunctionLow33}
                    disabled={ props.viewData.OtDiagnosisPhysicalDysfunctionLow33Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPhysicalDysfunctionLOW",
                                name: "OtDiagnosisPhysicalDysfunctionLow33"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow34}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>None</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow35}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Infectious precaution</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow36}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPhysicalDysfunctionLow36}
                    disabled={ props.viewData.OtDiagnosisPhysicalDysfunctionLow36Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPhysicalDysfunctionLOW",
                                name: "OtDiagnosisPhysicalDysfunctionLow36"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow37}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPhysicalDysfunctionLow37}
                    disabled={ props.viewData.OtDiagnosisPhysicalDysfunctionLow37Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPhysicalDysfunctionLOW",
                                name: "OtDiagnosisPhysicalDysfunctionLow37"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow38}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Malignancy</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow39}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPhysicalDysfunctionLow39}
                    disabled={ props.viewData.OtDiagnosisPhysicalDysfunctionLow39Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPhysicalDysfunctionLOW",
                                name: "OtDiagnosisPhysicalDysfunctionLow39"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow40}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Vascular disorder</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow41}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Internal Instrument</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow42}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Osteoporosis</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow43}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Pregnancy</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow44}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPhysicalDysfunctionLow44}
                    disabled={ props.viewData.OtDiagnosisPhysicalDysfunctionLow44Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPhysicalDysfunctionLOW",
                                name: "OtDiagnosisPhysicalDysfunctionLow44"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow45}>
                <Input
                    value={props.viewData.OtDiagnosisPhysicalDysfunctionLow45}
                    disabled={ props.viewData.OtDiagnosisPhysicalDysfunctionLow45Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTDiagnosisPhysicalDysfunctionLOW',
                            name: 'OtDiagnosisPhysicalDysfunctionLow45',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow46}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPhysicalDysfunctionLow46}
                    disabled={ props.viewData.OtDiagnosisPhysicalDysfunctionLow46Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPhysicalDysfunctionLOW",
                                name: "OtDiagnosisPhysicalDysfunctionLow46"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow47}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPhysicalDysfunctionLow47}
                    disabled={ props.viewData.OtDiagnosisPhysicalDysfunctionLow47Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPhysicalDysfunctionLOW",
                                name: "OtDiagnosisPhysicalDysfunctionLow47"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow48}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Hypertension</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow49}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPhysicalDysfunctionLow49}
                    disabled={ props.viewData.OtDiagnosisPhysicalDysfunctionLow49Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPhysicalDysfunctionLOW",
                                name: "OtDiagnosisPhysicalDysfunctionLow49"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow50}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Falling</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow51}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPhysicalDysfunctionLow51}
                    disabled={ props.viewData.OtDiagnosisPhysicalDysfunctionLow51Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPhysicalDysfunctionLOW",
                                name: "OtDiagnosisPhysicalDysfunctionLow51"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow52}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPhysicalDysfunctionLow52}
                    disabled={ props.viewData.OtDiagnosisPhysicalDysfunctionLow52Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPhysicalDysfunctionLOW",
                                name: "OtDiagnosisPhysicalDysfunctionLow52"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow53}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Diabetes mellitus</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow54}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Fracture</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow55}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Aspiration</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow56}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Seizure</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow57}>
                <Input
                    value={props.viewData.OtDiagnosisPhysicalDysfunctionLow57}
                    disabled={ props.viewData.OtDiagnosisPhysicalDysfunctionLow57Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTDiagnosisPhysicalDysfunctionLOW',
                            name: 'OtDiagnosisPhysicalDysfunctionLow57',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow58}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPhysicalDysfunctionLow58}
                    disabled={ props.viewData.OtDiagnosisPhysicalDysfunctionLow58Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPhysicalDysfunctionLOW",
                                name: "OtDiagnosisPhysicalDysfunctionLow58"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow59}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Other</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow60}>
                <Input
                    value={props.viewData.OtDiagnosisPhysicalDysfunctionLow60}
                    disabled={ props.viewData.OtDiagnosisPhysicalDysfunctionLow60Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTDiagnosisPhysicalDysfunctionLOW',
                            name: 'OtDiagnosisPhysicalDysfunctionLow60',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow61}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Functional Limitation:</strong></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow62}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPhysicalDysfunctionLow62}
                    disabled={ props.viewData.OtDiagnosisPhysicalDysfunctionLow62Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisPhysicalDysfunctionLOW",
                                name: "OtDiagnosisPhysicalDysfunctionLow62"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow63}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>None</p>
                </div>
            </div>
            <div className={styles.Ct3Premorbid}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct3Premorbid}
                    disabled={ props.viewData.Ct3PremorbidDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTDiagnosisPhysicalDysfunctionLOW",
                                name: "Ct3Premorbid",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct3PremorbidOptions}
                />
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow67}>
                <Input
                    value={props.viewData.OtDiagnosisPhysicalDysfunctionLow67}
                    disabled={ props.viewData.OtDiagnosisPhysicalDysfunctionLow67Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTDiagnosisPhysicalDysfunctionLOW',
                            name: 'OtDiagnosisPhysicalDysfunctionLow67',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow68}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Premorbid</strong></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow69}>
                <Radio 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPhysicalDysfunctionLow69}
                    disabled={ props.viewData.OtDiagnosisPhysicalDysfunctionLow69Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "radio",
                            params: {
                                view: "CardOTDiagnosisPhysicalDysfunctionLOW",
                                name: "OtDiagnosisPhysicalDysfunctionLow69"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow70}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Family Status</strong></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow71}>
                <Radio 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisPhysicalDysfunctionLow71}
                    disabled={ props.viewData.OtDiagnosisPhysicalDysfunctionLow71Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "radio",
                            params: {
                                view: "CardOTDiagnosisPhysicalDysfunctionLOW",
                                name: "OtDiagnosisPhysicalDysfunctionLow71"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow72}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Caregiver</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow73}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Non-Caregiver</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow74}>
                <TextArea
                    value={props.viewData.OtDiagnosisPhysicalDysfunctionLow74}
                    disabled={ props.viewData.OtDiagnosisPhysicalDysfunctionLow74Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTDiagnosisPhysicalDysfunctionLOW',
                            name: 'OtDiagnosisPhysicalDysfunctionLow74',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </TextArea>
            </div>
            <div className={styles.OtDiagnosisPhysicalDysfunctionLow75}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Present and Past History</strong></p>
                </div>
            </div>
            <div className={styles.SaveRequest}>
                <Button
                    disabled={ props.viewData.SaveRequestDisabled }
                    onClick={(e) => { props.onEvent({
                        message: 'clickButton',
                        params: {
                            view: 'CardOTDiagnosisPhysicalDysfunctionLOW',
                            name: 'SaveRequest'
                        }
                    })}}
                    color="green"
                    style={{height: "100%", width: "100%"}}
                >
                    Save
                </Button>
            </div>
        </div>
    )
}

export default Sub

const getTsName = (cssClass: string) => {
    return cssClass.split('-').map((s) => (
        s.charAt(0).toUpperCase() + s.slice(1)
    )).join('')
}
