export default function toPDFMakeData(props: any) {
  return {
    content: [
      {
        decorationStyle: ``,
        color: ``,
        decoration: ``,
        alignment: `center`,
        text: `ใบรับรองแพทย์`,
        fontSize: `18`,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        bold: `true`,
        width: `auto`,
        pageBreak: ``,
      },
      {
        text: `5 โรค`,
        alignment: `center`,
        pageBreak: ``,
        bold: `true`,
        decorationStyle: ``,
        decorationColor: ``,
        width: `auto`,
        color: ``,
        decoration: ``,
        preserveLeadingSpaces: true,
        fontSize: `18`,
      },
      {
        text: ` `,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        bold: false,
        pageBreak: ``,
        alignment: `left`,
        color: ``,
        width: `auto`,
        decoration: ``,
        decorationStyle: ``,
        fontSize: 15,
      },
      {
        columns: [
          {
            decorationStyle: ``,
            pageBreak: ``,
            decoration: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            text: ``,
            width: 420,
            alignment: `left`,
            bold: false,
            color: ``,
          },
          {
            decoration: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            bold: true,
            width: `auto`,
            decorationStyle: ``,
            fontSize: 15,
            text: `วันที่`,
            color: ``,
            pageBreak: ``,
            decorationColor: ``,
          },
          {
            width: 5,
            alignment: `left`,
            bold: false,
            text: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationColor: ``,
            decoration: ``,
            decorationStyle: ``,
            fontSize: 15,
            color: ``,
          },
          {
            alignment: `center`,
            preserveLeadingSpaces: true,
            text: props.items?.formatCreatedDate,
            color: ``,
            decorationColor: ``,
            bold: true,
            decoration: ``,
            decorationStyle: ``,
            fontSize: 15,
            width: `auto`,
            pageBreak: ``,
          },
        ],
      },
      {
        width: `auto`,
        bold: false,
        fontSize: 15,
        decorationStyle: ``,
        decorationColor: ``,
        decoration: ``,
        pageBreak: ``,
        preserveLeadingSpaces: true,
        color: ``,
        alignment: `left`,
        text: ` `,
      },
      {
        columns: [
          {
            decorationStyle: ``,
            pageBreak: ``,
            color: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            width: 40,
            decoration: ``,
            bold: false,
            fontSize: 15,
            text: ``,
          },
          {
            decorationStyle: ``,
            text: `ขอรับรองว่า `,
            decoration: ``,
            decorationColor: ``,
            bold: false,
            color: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            width: `auto`,
            alignment: `left`,
          },
          {
            width: 10,
            text: ``,
            decorationStyle: ``,
            color: ``,
            preserveLeadingSpaces: true,
            bold: false,
            pageBreak: ``,
            decoration: ``,
            alignment: `left`,
            fontSize: 15,
            decorationColor: ``,
          },
          {
            stack: [
              {
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
                alignment: `left`,
                fontSize: 15,
                decoration: ``,
                color: ``,
                decorationColor: ``,
                pageBreak: ``,
                width: `auto`,
                bold: false,
                text: props.items?.patientInfo?.full_name,
                decorationStyle: ``,
              },
              {
                margin: [0, 0, 0, 0],
                alignment: `left`,
                decorationColor: ``,
                width: `auto`,
                decorationStyle: ``,
                color: ``,
                decoration: ``,
                preserveLeadingSpaces: true,
                pageBreak: ``,
                text: `....................................................................................................................................................................`,
                bold: false,
                fontSize: 15,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            decoration: ``,
            alignment: `left`,
            text: `หมายเลขประจำตัวประชาชน / เลขที่หนังสือเดินทาง / อื่นๆ`,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            bold: false,
            pageBreak: ``,
            fontSize: 15,
            width: `auto`,
            decorationColor: ``,
          },
          {
            width: 10,
            bold: false,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            text: ``,
            color: ``,
            alignment: `left`,
            pageBreak: ``,
            fontSize: 15,
            decorationStyle: ``,
            decoration: ``,
          },
          {
            stack: [
              {
                preserveLeadingSpaces: true,
                alignment: `left`,
                decorationStyle: ``,
                text:
                  props.items?.patientInfo?.citizen_no === "" ||
                  props.items?.patientInfo?.citizen_no ===
                    "Non citizen identity"
                    ? props.items?.patientInfo?.citizen_passport === "" ||
                      props.items?.patientInfo?.citizen_passport ===
                        "Non citizen identity"
                      ? " "
                      : props.items?.patientInfo?.citizen_passport
                    : props.items?.patientInfo?.citizen_no,
                margin: [0, 0, 0, -15],
                bold: false,
                width: `auto`,
                fontSize: 15,
                decorationColor: ``,
                pageBreak: ``,
                color: ``,
                decoration: ``,
              },
              {
                bold: false,
                decorationStyle: ``,
                width: `auto`,
                pageBreak: ``,
                decorationColor: ``,
                text: `........................................................................................................`,
                decoration: ``,
                margin: [0, 0, 0, 0],
                preserveLeadingSpaces: true,
                color: ``,
                fontSize: 15,
                alignment: `left`,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            preserveLeadingSpaces: true,
            color: ``,
            width: `auto`,
            pageBreak: ``,
            text: `อายุ `,
            decorationColor: ``,
            fontSize: 15,
            decorationStyle: ``,
            alignment: `left`,
            bold: false,
          },
          {
            color: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            bold: false,
            text: ``,
            decoration: ``,
            width: 10,
            alignment: `left`,
            pageBreak: ``,
            fontSize: 15,
            decorationColor: ``,
          },
          {
            stack: [
              {
                alignment: `left`,
                fontSize: 15,
                width: `auto`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
                decorationStyle: ``,
                decorationColor: ``,
                text: `${props.items?.ageYear} ปี ${props.items?.ageMonth} เดือน ${props.items?.ageDay} วัน`,
                bold: false,
                decoration: ``,
                pageBreak: ``,
                color: ``,
              },
              {
                alignment: `left`,
                width: `auto`,
                decorationColor: ``,
                fontSize: 15,
                preserveLeadingSpaces: true,
                pageBreak: ``,
                text: `..............................................................`,
                margin: [0, 0, 0, 0],
                decorationStyle: ``,
                color: ``,
                bold: false,
                decoration: ``,
              },
            ],
          },
          {
            text: `ได้รับการตรวจพบว่า`,
            alignment: `left`,
            decorationColor: ``,
            pageBreak: ``,
            width: `auto`,
            preserveLeadingSpaces: true,
            color: ``,
            fontSize: 15,
            decorationStyle: ``,
            bold: false,
            decoration: ``,
          },
          {
            fontSize: 15,
            alignment: `left`,
            width: 10,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            bold: false,
            color: ``,
            pageBreak: ``,
            text: ``,
            decoration: ``,
          },
          {
            stack: [
              {
                text:
                  props.items?.isFoundDisease === "Free"
                    ? "ปราศจากโรคดังต่อไปนี้"
                    : "",
                decoration: ``,
                pageBreak: ``,
                decorationStyle: ``,
                alignment: `left`,
                fontSize: 15,
                bold: false,
                color: ``,
                decorationColor: ``,
                margin: [0, 0, 0, -15],
                width: `auto`,
                preserveLeadingSpaces: true,
              },
              {
                margin: [0, 0, 0, 0],
                fontSize: 15,
                decorationStyle: ``,
                alignment: `left`,
                decoration: ``,
                preserveLeadingSpaces: true,
                pageBreak: ``,
                color: ``,
                decorationColor: ``,
                text: `...........................................................................`,
                bold: false,
                width: `auto`,
              },
            ],
          },
        ],
      },
      {
        bold: false,
        alignment: `left`,
        pageBreak: ``,
        text: ` `,
        color: ``,
        decorationColor: ``,
        width: `auto`,
        margin: [0, 0, 0, 0],
        fontSize: 15,
        decoration: ``,
        decorationStyle: ``,
        preserveLeadingSpaces: true,
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            bold: false,
            decorationStyle: ``,
            text: ``,
            width: 55,
            decorationColor: ``,
            alignment: `left`,
            pageBreak: ``,
            decoration: ``,
            fontSize: 15,
            color: ``,
          },
          {
            color: ``,
            pageBreak: ``,
            width: `auto`,
            text: `1. โรคเรื้อน (Leprosy)`,
            alignment: `left`,
            bold: false,
            fontSize: 15,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            width: 55,
            decorationColor: ``,
            fontSize: 15,
            color: ``,
            pageBreak: ``,
            decoration: ``,
            text: ``,
            bold: false,
            alignment: `left`,
            preserveLeadingSpaces: true,
          },
          {
            decorationStyle: ``,
            bold: false,
            preserveLeadingSpaces: true,
            fontSize: 15,
            alignment: `left`,
            pageBreak: ``,
            width: `auto`,
            text: `2. วัณโรคระยะอันตราย (Tuberculosis)`,
            decorationColor: ``,
            decoration: ``,
            color: ``,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            preserveLeadingSpaces: true,
            alignment: `left`,
            fontSize: 15,
            pageBreak: ``,
            decoration: ``,
            text: ``,
            decorationStyle: ``,
            width: 55,
            color: ``,
            decorationColor: ``,
          },
          {
            alignment: `left`,
            fontSize: 15,
            width: `auto`,
            color: ``,
            decorationColor: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            bold: false,
            decorationStyle: ``,
            text: `3. โรคเท้าช้าง (Elephantiasis)`,
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            text: ``,
            width: 55,
            fontSize: 15,
            decoration: ``,
            alignment: `left`,
            decorationColor: ``,
            bold: false,
            pageBreak: ``,
            decorationStyle: ``,
            color: ``,
          },
          {
            bold: false,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            color: ``,
            alignment: `left`,
            width: `auto`,
            decorationStyle: ``,
            fontSize: 15,
            decorationColor: ``,
            text: `4. โรคยาเสพติดให้โทษ (Drug Addiction)`,
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            alignment: `left`,
            width: 55,
            decoration: ``,
            decorationStyle: ``,
            color: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            bold: false,
            fontSize: 15,
            text: ``,
          },
          {
            preserveLeadingSpaces: true,
            width: `auto`,
            decorationStyle: ``,
            color: ``,
            pageBreak: ``,
            bold: false,
            fontSize: 15,
            decoration: ``,
            alignment: `left`,
            decorationColor: ``,
            text: `5. โรคซิฟิลิสในระยะที่ 3 (Third step of syphilis)`,
          },
        ],
      },
      {
        decorationColor: ``,
        text: ` `,
        bold: false,
        decoration: ``,
        width: `auto`,
        pageBreak: ``,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        color: ``,
        fontSize: 15,
        alignment: `left`,
      },
      {
        columns: [
          {
            decoration: ``,
            pageBreak: ``,
            alignment: `left`,
            text: `อื่นๆ (ถ้ามี)`,
            fontSize: 15,
            decorationStyle: ``,
            bold: false,
            decorationColor: ``,
            width: `auto`,
            color: ``,
            preserveLeadingSpaces: true,
          },
          {
            bold: false,
            decoration: ``,
            color: ``,
            fontSize: 15,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            pageBreak: ``,
            decorationColor: ``,
            width: 10,
            text: ``,
          },
          {
            stack: [
              {
                bold: false,
                decoration: ``,
                decorationStyle: ``,
                width: `auto`,
                alignment: `left`,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                color: ``,
                fontSize: 15,
                text:
                  props.items?.otherNote === "" ? " " : props.items?.otherNote,
                margin: [0, 0, 0, -15],
                pageBreak: ``,
              },
              {
                margin: [0, 0, 0, 0],
                preserveLeadingSpaces: true,
                fontSize: 15,
                bold: false,
                decorationColor: ``,
                decoration: ``,
                width: `auto`,
                text: `.......................................................................................................................................................................................`,
                color: ``,
                decorationStyle: ``,
                alignment: `left`,
                pageBreak: ``,
              },
            ],
          },
        ],
      },
      {
        bold: false,
        decoration: ``,
        preserveLeadingSpaces: true,
        alignment: `left`,
        margin: [0, 0, 0, 0],
        decorationColor: ``,
        color: ``,
        pageBreak: ``,
        text: `.............................................................................................................................................................................................................`,
        fontSize: 15,
        decorationStyle: ``,
        width: `auto`,
      },
      {
        decorationStyle: ``,
        alignment: `left`,
        color: ``,
        fontSize: 15,
        preserveLeadingSpaces: true,
        bold: false,
        text: `.............................................................................................................................................................................................................`,
        width: `auto`,
        pageBreak: ``,
        decoration: ``,
        margin: [0, 0, 0, 0],
        decorationColor: ``,
      },
      {
        decoration: ``,
        bold: false,
        decorationColor: ``,
        width: `auto`,
        text: ` `,
        fontSize: 15,
        decorationStyle: ``,
        color: ``,
        alignment: `left`,
        preserveLeadingSpaces: true,
        pageBreak: ``,
      },
      {
        decorationStyle: ``,
        fontSize: 15,
        color: ``,
        decoration: ``,
        bold: false,
        width: `auto`,
        pageBreak: ``,
        preserveLeadingSpaces: true,
        decorationColor: ``,
        text: `ผู้ป่วยมีสุขภาพร่างกายสมบูรณ์ และสุขภาพจิตที่ดี และปราศจากข้อบกพร่องใดๆ`,
        alignment: `left`,
      },
      {
        text: ` `,
        decoration: ``,
        fontSize: 15,
        bold: false,
        decorationColor: ``,
        alignment: `left`,
        decorationStyle: ``,
        pageBreak: ``,
        color: ``,
        width: `auto`,
        preserveLeadingSpaces: true,
      },
      {
        decorationStyle: ``,
        fontSize: 15,
        bold: false,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        alignment: `left`,
        width: `auto`,
        decoration: ``,
        text: ` `,
        pageBreak: ``,
        color: ``,
      },
      {
        text: ` `,
        alignment: `left`,
        preserveLeadingSpaces: true,
        bold: false,
        width: `auto`,
        color: ``,
        decorationStyle: ``,
        pageBreak: ``,
        fontSize: 15,
        decoration: ``,
        decorationColor: ``,
      },
      {
        color: ``,
        fontSize: 15,
        width: `auto`,
        alignment: `left`,
        decoration: ``,
        preserveLeadingSpaces: true,
        text: ` `,
        pageBreak: ``,
        bold: false,
        decorationStyle: ``,
        decorationColor: ``,
      },
      {
        alignment: `left`,
        decorationStyle: ``,
        text: ` `,
        bold: false,
        fontSize: 15,
        decorationColor: ``,
        pageBreak: ``,
        color: ``,
        decoration: ``,
        preserveLeadingSpaces: true,
        width: `auto`,
      },
      {
        alignment: `left`,
        color: ``,
        decorationColor: ``,
        bold: false,
        decorationStyle: ``,
        preserveLeadingSpaces: true,
        pageBreak: ``,
        width: `auto`,
        decoration: ``,
        text: ` `,
        fontSize: 15,
      },
      {
        columns: [
          {
            pageBreak: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
            alignment: `left`,
            decorationColor: ``,
            text: `ลงนามแพทย์ผู้ตรวจ `,
            bold: false,
            color: ``,
            decorationStyle: ``,
            fontSize: 15,
            decoration: ``,
          },
          {
            bold: false,
            pageBreak: ``,
            decorationColor: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decoration: ``,
            color: ``,
            width: 30,
            text: ``,
            decorationStyle: ``,
          },
          {
            preserveLeadingSpaces: true,
            decoration: ``,
            color: ``,
            alignment: `center`,
            text: `.............................................................`,
            width: `auto`,
            fontSize: 15,
            bold: false,
            pageBreak: ``,
            decorationColor: ``,
            decorationStyle: ``,
          },
          {
            color: ``,
            preserveLeadingSpaces: true,
            bold: false,
            alignment: `center`,
            text: ` `,
            decoration: ``,
            decorationColor: ``,
            width: 30,
            fontSize: 15,
            pageBreak: ``,
            decorationStyle: ``,
          },
          {
            color: ``,
            bold: false,
            decoration: ``,
            text: ` เลขใบประกอบวิชาชีพแพทย์ `,
            fontSize: 15,
            pageBreak: ``,
            width: `auto`,
            decorationStyle: ``,
            decorationColor: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
          },
          {
            fontSize: 15,
            color: ``,
            pageBreak: ``,
            width: 30,
            alignment: `left`,
            text: ``,
            decorationStyle: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            bold: false,
            decoration: ``,
          },
          {
            width: `auto`,
            decoration: `underline`,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            fontSize: 15,
            pageBreak: ``,
            decorationStyle: `dotted`,
            bold: false,
            text: props.items?.doctorCertNumber
              ? props.items?.doctorCertNumber
              : "                    ",
            alignment: `center`,
            color: ``,
          },
        ],
      },
      {
        columns: [
          {
            alignment: `left`,
            color: ``,
            decorationColor: ``,
            pageBreak: ``,
            decorationStyle: ``,
            fontSize: 15,
            text: `		      `,
            width: 128,
            preserveLeadingSpaces: true,
            decoration: ``,
            bold: false,
          },
          {
            decoration: ``,
            alignment: `left`,
            width: `auto`,
            decorationStyle: ``,
            bold: false,
            text: `(`,
            color: ``,
            decorationColor: ``,
            pageBreak: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
          },
          {
            pageBreak: ``,
            fontSize: 15,
            decoration: ``,
            bold: false,
            alignment: `center`,
            text: props.items?.doctorName,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            color: ``,
            width: `auto`,
            decorationColor: ``,
          },
          {
            decoration: ``,
            decorationStyle: ``,
            pageBreak: ``,
            width: `auto`,
            alignment: `left`,
            fontSize: 15,
            color: ``,
            text: `)`,
            bold: false,
            preserveLeadingSpaces: true,
            decorationColor: ``,
          },
        ],
      },
    ],
    pageOrientation: `portrait`,
    defaultStyle: {
      font: `THSarabunNew`,
    },
    pageSize: `A4`,
    header: props.header,
    footer: props.footer,
  };
}
