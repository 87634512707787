import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button,
  Dropdown,
  Input,
  Checkbox,
  FormGroup,
  FormField,
  Form,
  Icon
} from 'semantic-ui-react'

const CardMedReconcileUX = (props: any) => {
    return(
      <div
        style={{padding: "5px"}}>
        <div
          style={{display: "flex", justifyContent: "space-between"}}>
          
          <div
            style={{fontWeight: "bold", fontSize: "1.3rem", padding: "5px"}}>
            Medication Reconcilliation
          </div>
          <div
            style={{display: "flex",  paddingTop: "10px", textAlign: "right"}}>
            
            <div>
              
              <Button
                onClick={props.onShowMedReconcileList}>
                เลือกใบ Medication Reconciliation
              </Button>
              <div
                style={{display: props.showMedReconcileList? "block": "none", position: "absolute", zIndex: 100, padding: "10px", backgroundColor: "#FFF", right:"10%"}}>
                
                <Table
                  data={props.medReconcileList}
                  getTrProps={props.getTrPropsMedReconcileList}
                  headers="Type,Encounter,วันที่และเวลาสร้าง"
                  keys="med_reconciliation_type_label,name,created_datetime"
                  minRows="3"
                  showPagination={false}
                  style={{height: "250px", width: "600px", textAlign: "center"}}
                  widths="150,,200">
                </Table>
                <div
                  style={{paddingTop: "5px", display: "flex", justifyContent: props.enableNewButtonInMedReconcileList ? "space-between" : "flex-end"}}>
                  
                  <Button
                    color="green"
                    onClick={props.onNewMedReconcile}
                    style={{...(!props.enableNewButtonInMedReconcileList && {display: "none"})}}>
                    New
                  </Button>
                  <Button
                    color="blue"
                    onClick={props.onMedReconcileSelect}>
                    เลือก
                  </Button>
                </div>
              </div>
            </div>
            <div>
              
              <Dropdown
                disabled={!!props.disabledMedReconcileType}
                onChange={props.changeMedReconcileType}
                options={props.medReconcileTypeOptions}
                selection={true}
                style={{transform: "scale(0.85)"}}
                value={props.medReconcileType}>
              </Dropdown>
            </div>
          </div>
        </div>
        <div
          style={{display: props.medReconcileType === "DISCHARGE" ||  props.medReconcileType === "TRANSFER" ?"none":"flex", flexDirection: "column"}}>
          
          <Checkbox
            checked={props.hasNoPastMed}
            label="ไม่มีรายการยาเดิม"
            onChange={props.changeHasNoPastMed}
            style={{padding: "10px", width: "fit-content"}}>
          </Checkbox>
          <Checkbox
            checked={props.notAvailableAtAdmit}
            disabled={props.notAvailableDisabled}
            label={`Not Available at time of admission will be completed in ${props.configMedReconcileAvailable} hour(s). ${props.limitDateTime}`}
            onChange={props.changeNotAvailableAtAdmit}
            style={{padding: "10px", width: "fit-content"}}>
          </Checkbox>
        </div>
        <div
          style={{display: "none"}}>
          
          <div
            style={{marginTop: "10px"}}>
            จากประวัติการสั่งยาจากโรงพยาบาล
          </div>
          <Table
            data={props.internalItems}
            headers="รหัสยา,ชื่อยาและความแรง,วิธีใช้,QTY,Start Date,Time,Last Date,Time,Note,Off Hold Cont.,"
            keys="code,name,label,quantity,start_date,start_time,last_dose_date,last_dose_time,note,_action,remove"
            minRows="6"
            showPagination={false}
            style={{height: "150px"}}>
          </Table>
        </div>
        <div
          style={{display: "none"}}>
          
          <div
            style={{marginTop: "10px"}}>
            ประวัติการใช้ยานอกโรงพยาบาล
          </div>
          <Table
            data={props.externalItems}
            getTrProps={props.externalRowProps}
            headers="ประเภท,ชื่อ,QTY,Start Date,Time,Last Date,Time,Note,Off Hold Cont.,"
            keys="typeName,name,quantity,start_date,start_time,last_dose_date,last_dose_time,note,_action,remove"
            minRows="6"
            showPagination={false}
            style={{height: "150px"}}>
          </Table>
        </div>
        <div
          style={{...(props.medReconcileType === "TRANSFER" && {display: "none"}), marginTop: "10px", borderBottom: "solid #cccccc 1px"}}>
          
          <Button
            color={props.external ? null : "blue"}
            onClick={props.setInternal}
            size="small">
            {props.medReconcileType === "DISCHARGE" ? "เลือกรายการยาจาก Medication Reconciliation แรกรับ":" เพิ่มจากประวัติการสั่งยาจากโรงพยาบาล"}
          </Button>
          <Button
            color={props.external ? "blue" :  null}
            onClick={props.setExternal}
            size="small">
            {props.externalNameBtn}
          </Button>
        </div>
        <div
          style={{...(props.medReconcileType === "TRANSFER" && {display: "none"})}}>
          
          <div
            style={{display: !props.external && props.medReconcileType !== "DISCHARGE" ?  "block" : "none", height: "350px"}}>
            
            <div
              className="ui form search-order-history"
              style={{ marginTop: "1rem"  }}>
              {}
              <FormGroup
                inline={true}
                widths={16}>
                <FormField
                  inline={true}>
                  <Checkbox
                    checked={props.checkedDoctor}
                    label="ชื่อหรือรหัสแพทย์"
                    onChange={props.changeCheckedDoctor}
                    style={{width: "100%", minWidth: "max-content"}}>
                  </Checkbox>
                </FormField>
                <FormField
                  inline={true}
                  width={4}>
                  <div
                    style={{width: "100%"}}>
                    {props.searchDoctor}
                  </div>
                </FormField>
                <FormField
                  inline={true}>
                  <Checkbox
                    checked={props.checkedDrug}
                    label="ชื่อหรือรหัสยา"
                    onChange={props.changeCheckedDrug}
                    style={{width: "100%",minWidth: "max-content"}}>
                  </Checkbox>
                </FormField>
                <FormField
                  inline={true}
                  width={4}>
                  <div
                    style={{width: "100%"}}>
                    {props.searchDrug}
                  </div>
                </FormField>
                <FormField
                  inline={true}>
                  <Checkbox
                    checked={props.checkedDateInternal}
                    label="วันที่สั่งยา"
                    onChange={props.changeCheckedDateInternal}
                    style={{width: "100%",minWidth: "max-content"}}>
                  </Checkbox>
                </FormField>
                <FormField
                  inline={true}>
                  <div
                    style={{width: "100%"}}>
                    {props.startDateInternal}
                  </div>
                </FormField>
                <FormField
                  inline={true}>
                  <div
                    style={{width: "100%"}}>
                    {props.endDateInternal}
                  </div>
                </FormField>
                <FormField
                  inline={true}>
                  <Button
                    color="blue"
                    fluid={true}
                    loading={props.medReconcileLoading}
                    onClick={props.onSearchOrderHistory}
                    size="tiny"
                    style={{width: "100%"}}>
                    ค้นหา
                  </Button>
                </FormField>
              </FormGroup>
            </div>
            <Form>
              <Table
                data={props.drugOrderHistoryItems}
                getTrProps={props.drugOrderHistoryTrProps}
                headers=",รหัสยา,ชื่อยา,จำนวนจ่าย,แพทย์,วันที่,หน่วยงาน"
                keys="_add,code,product_name,quantity,order_by_name,order_time,order_div_name"
                minRows={5}
                showPagination={false}
                style={{height:"250px"}}
                widths="^50,^100,^200,^90,^100,^90,^100">
              </Table>
            </Form>
            <Form>
              <FormGroup
                inline={true}
                style={{marginTop: "1rem"}}
                widths={16}>
                <FormField
                  style={{flex:1}}>
                </FormField>
                <FormField>
                  <Button
                    color="green"
                    onClick={props.onAddInternalItem}>
                    Add
                  </Button>
                </FormField>
              </FormGroup>
            </Form>
          </div>
          <div
            style={{display: !props.external && props.medReconcileType === "DISCHARGE" ?  "block" : "none", height: "280px"}}>
            
            <div>
              
              <Table
                data={props.drugIPDAdmit}
                headers=",รหัสยา, ชื่อยาและความแรง, วิธีใช้, QTY, Start Date, Time, Last Date, Time, Note,Off,Hold,Cont."
                id="drugIPDAdmit"
                keys="_add,code,nameDiv,label,quantity, start_date_text, start_time_text, last_date_text, last_time_text, btn_note,_action_off,_action_hold,_action_cont"
                minRows="5"
                showPagination={false}
                style={{height:"220px"}}
                widths="80,80,,200,50,100,80,100,80,100,60,60,60">
              </Table>
            </div>
            <div
              style={{display: "flex", justifyContent: "flex-end", marginTop: "10px"}}>
              
              <Button
                color="green"
                disabled={props.medReconcileIPDAdmitSelectOrder?.length === 0 && props.medReconcileIPDAdmitSelectItem?.length === 0}
                onClick={props.onAddDrugToMRDischarge}>
                Add to MR discharge List
              </Button>
            </div>
          </div>
          <div
            style={{display: props.external ? "block" : "none", height: "300px"}}>
            
            <div
              style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(14, 1fr)","gridTemplateColumns":"repeat(50, 1fr)"}}>
              <div
                style={{gridRow: "2/3", gridColumn: "3/6", }}>
                ประเภท
              </div>
              <div
                style={{ gridRow: "10/11", gridColumn: "3/50",}}>
                
                <Form>
                  <FormGroup
                    widths={16}>
                    <FormField
                      style={{marginRight: "0.55rem"}}>
                      <label
                        style={{fontWeight: "normal"}}>
                        Start Date
                      </label>
                      <div
                        style={{width: "100%"}}>
                        {props.startDateElement}
                      </div>
                    </FormField>
                    <FormField
                      style={{marginRight: "0.55rem"}}>
                      <label
                        style={{fontWeight: "normal"}}>
                        Time
                      </label>
                      <div>
                        {props.StartTimeFreeTextBox}
                      </div>
                    </FormField>
                    <FormField
                      style={{marginRight: "0.55rem"}}>
                      <label
                        style={{fontWeight: "normal"}}>
                        Last Date
                      </label>
                      <div
                        style={{width: "100%"}}>
                        {props.endDateElement}
                      </div>
                    </FormField>
                    <FormField>
                      <label
                        style={{fontWeight: "normal"}}>
                        Time
                      </label>
                      <div>
                        {props.EndTimeFreeTextBox}
                      </div>
                    </FormField>
                  </FormGroup>
                </Form>
              </div>
              <div
                style={{ padding: "5px", textAlign: "right", gridRow: "11/13", gridColumn: "2/6", }}>
                
              </div>
              <div
                style={{ gridRow: "3/5", gridColumn: "3/13", }}>
                <Dropdown
                  disabled={props.disableDrugType}
                  fluid={true}
                  onChange={props.changeExternalType}
                  options={props.externalTypeOptions}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.externalType}>
                </Dropdown>
              </div>
              <div
                style={{ gridRow: "12/14", gridColumn: "3/14", }}>
                <Checkbox
                  checked={props.checkedUnknowLastDose}
                  label="ไม่สามารถระบุ Last dose"
                  onChange={props.changeUnknowLastDose}
                  style={{padding: "5px", marginTop:"0.25rem"}}>
                </Checkbox>
              </div>
              <div
                style={{ gridRow: "12/14", gridColumn: "31/41", justifyContent:"flex-end", display: "flex"}}>
                <Button
                  color="green"
                  onClick={props.addOtherDrug}
                  size="small"
                  style={{width: "fit-content", height: "100%"}}>
                  เพิ่ม/อัพเดทรายการนี้
                </Button>
              </div>
              <div
                style={{ gridRow: "2/3", gridColumn: "14/20", }}>
                ชื่อ/รายละเอียด
              </div>
              <div
                style={{ gridRow: "3/5", gridColumn: "14/28", }}>
                {props.externalDrug}
              </div>
              <div
                style={{gridRow: "2/3", gridColumn: "29/32", }}>
                Qty
              </div>
              <div
                style={{ gridRow: "3/5", gridColumn: "29/36",}}>
                <Input
                  fluid={true}
                  min={0}
                  onChange={props.changeQty}
                  style={{height: "100%"}}
                  type="number"
                  value={props.qty}>
                </Input>
              </div>
              <div
                style={{ gridRow: "12/14", gridColumn: "42/48", border: "solid 1px rgb(200, 200, 200, 0.5)" }}>
                <Button
                  color="orange"
                  onClick={props.clearExternalDrug}
                  size="small"
                  style={{width: "100%", height: "100%"}}>
                  เคลียร์
                </Button>
              </div>
              <div
                style={{ gridRow: "6/7", gridColumn: "3/6"}}>
                
                <label>
                  Dose
                </label>
              </div>
              <div
                style={{ gridRow: "7/9", gridColumn: "3/9"}}>
                <Input
                  fluid={true}
                  min={0}
                  onChange={props.changeDose}
                  type="number"
                  value={props.dose}>
                </Input>
              </div>
              <div
                style={{ gridRow: "6/7", gridColumn: "10/12"}}>
                Unit
              </div>
              <div
                style={{ gridRow: "7/9", gridColumn: "10/17"}}>
                <Dropdown
                  fluid={true}
                  onChange={props.changeUnit}
                  options={props.unitOptions}
                  search={true}
                  selection={true}
                  style={{height: "auto"}}
                  value={props.unit}>
                </Dropdown>
              </div>
              <div
                style={{ gridRow: "6/7", gridColumn: "18/20"}}>
                Route
              </div>
              <div
                style={{ gridRow: "7/9", gridColumn: "18/25"}}>
                <Dropdown
                  fluid={true}
                  onChange={props.changeRoute}
                  options={props.routeOptions}
                  search={true}
                  selection={true}
                  value={props.route}>
                </Dropdown>
              </div>
              <div
                style={{ gridRow: "6/7", gridColumn: "26/28"}}>
                Site
              </div>
              <div
                style={{ gridRow: "7/9", gridColumn: "26/33"}}>
                <Dropdown
                  fluid={true}
                  onChange={props.changeSite}
                  options={props.siteOptions}
                  search={true}
                  selection={true}
                  value={props.site}>
                </Dropdown>
              </div>
              <div
                style={{ gridRow: "6/7", gridColumn: "34/37",}}>
                Frequency
              </div>
              <div
                style={{ gridRow: "7/9", gridColumn: "34/42"}}>
                <Dropdown
                  fluid={true}
                  onChange={props.changeFrequency}
                  options={props.frequencyOptions}
                  search={true}
                  selection={true}
                  value={props.frequency}>
                </Dropdown>
              </div>
              <div
                style={{ gridRow: "6/7", gridColumn: "43/45"}}>
                Method
              </div>
              <div
                style={{ gridRow: "7/9", gridColumn: "43/50"}}>
                <Dropdown
                  fluid={true}
                  onChange={props.changeMethod}
                  options={props.methodOptions}
                  search={true}
                  selection={true}
                  value={props.method}>
                </Dropdown>
              </div>
              <div
                style={{ gridRow: "2/3", gridColumn: "37/40"}}>
                Note
              </div>
              <div
                style={{ gridRow: "3/5", gridColumn: "37/50"}}>
                <Input
                  fluid={true}
                  onChange={props.changeNote}
                  style={{height: "100%"}}
                  value={props.note}>
                </Input>
              </div>
              <div
                style={{ gridRow: "12/14", gridColumn: "12/20", display:  props.checkedUnknowLastDose? "block": "none"}}>
                <Dropdown
                  onChange={props.changeReason}
                  options={props.reasonOptions}
                  selection={true}
                  value={props.reason}>
                </Dropdown>
              </div>
              <div
                style={{ gridRow: "14/16", gridColumn: "21/34", display:  props.reason === "etc"? "block": "none"}}>
                <Input
                  fluid={true}
                  onChange={props.changeOtherReason}
                  style={{height: "100%"}}
                  value={props.otherReason}>
                </Input>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{...(props.medReconcileType === "TRANSFER" && {display: "none"})}}>
          
          <div
            style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            
            <div
              style={{margin: "10px 0px", fontSize: "16px", fontWeight: "bold", flex: 1}}>
              {props.medReconcileType === "DISCHARGE" ? "Home Medication" : `Past Medication ${props.pastMedRecordDate}`}
            </div>
            <div
              style={{display: props.medReconcileType === "DISCHARGE" ? "flex" : "none", justifyContent: "space-evenly", alignItems: "center", flex: "1"}}>
              
              <Checkbox
                checked={props.checkedIPDHome}
                label="IPD Home"
                onChange={props.onChangeCheckedIPDHome}>
              </Checkbox>
              <Checkbox
                checked={props.checkedMRIPD}
                label="MR IPD แรกรับ"
                onChange={props.onChangeCheckedMRIPD}>
              </Checkbox>
              <Checkbox
                checked={props.checkedSuggestion}
                label="Suggestion"
                onChange={props.onChangeCheckedSuggestion}>
              </Checkbox>
            </div>
          </div>
          <Table
            className="--rt-tbody-overflow-y-hidden"
            data={props.medicationItems}
            getTrProps={props.medicationRowProps}
            headers={props.medReconcileType === "DISCHARGE"? "TYPE,รหัสยา, ชื่อยาและความแรง, วิธีใช้, QTY, Start Date, Time, Last Date, Time, Note,ใช้ยาต่อ,หยุดยา," : "TYPE,รหัสยา, ชื่อยาและความแรง, วิธีใช้, QTY, Start Date, Time, Last Date, Time, Note,Off,Hold,Cont.,Edited date / time,"}
            keys={props.medReconcileType === "DISCHARGE"? "isExternal,code, name, label,quantity, start_date_text, start_time_text, last_date_text, last_time_text, btn_note,_is_cont,_is_stop,delete" : "isExternal,code,name,label,quantity, start_date_text, start_time_text, last_date_text, last_time_text, btn_note,_action_off,_action_hold,_action_cont,edit_time,delete"}
            minRows="6"
            showPagination={false}
            style={{height: "300px"}}
            widths={props.medReconcileType === "DISCHARGE"? "120,80,,200,50,100,80,100,80,100,100,100,60" : "80,80,200,200,50,100,80,100,80,100,60,60,60,120,60"}>
          </Table>
        </div>
        <div
          style={{...(props.medReconcileType !== "TRANSFER" && {display: "none"})}}>
          
          <Button
            color="purple"
            onClick={props.onClickSyncDrugTransfer}>
            Sync รายการยา (ต้องรับทราบทุกครั้ง)
          </Button>
          <div
            style={{fontWeight: "bolder", fontSize: "larger", margin: "20px 0px 5px 0px"}}>
            Current Medication (One Day)
          </div>
          <Table
            data={props.transferOneDayData}
            getTdProps={props.transferOneDayTdProps}
            getTrProps={props.transferOneDayTrProps}
            headers="TYPE,รหัสยา,ชื่อยาและความแรง,วิธีใช้,QTY,Start Date,Time,Last Date,Time,Note,Off,On"
            keys="type, code, name, label, quantity, startDate, startTime, lastDoseDate, lastDoseTime,noteIcon,off,on"
            minRows="5"
            showPagination={false}
            widths="80,100,auto,auto,60,100,60,100,60,auto,60,60">
          </Table>
          <div
            style={{fontWeight: "bolder", fontSize: "larger",margin: "20px 0px 5px 0px"}}>
            Current Medication (Continue)
          </div>
          <Table
            data={props.transferContData}
            getTdProps={props.transferContTdProps}
            getTrProps={props.transferContTrProps}
            headers="TYPE,รหัสยา,ชื่อยาและความแรง,วิธีใช้,QTY,Start Date,Time,Last Date,Time,Note,Off,Hold,Cont"
            keys="type, code, name, label, quantity, startDate, startTime, lastDoseDate, lastDoseTime,noteIcon,off,hold,continue"
            minRows="5"
            showPagination={false}
            widths="80,100,auto,auto,60,100,60,100,60,auto,60,60,80">
          </Table>
        </div>
        <div
          style={{ borderTop: "solid #cccccc 1px", marginBottom: "4rem", paddingTop: "1rem"}}>
          
          <Form>
            <FormGroup
              inline={true}
              style={{padding: "0 4rem 0 2rem"}}
              widths={16}>
              <FormField
                inline={true}
                width={7}>
                <div
                  style={{width: "170px"}}>
                  แพทย์
                </div>
                <div
                  style={{  borderRadius: "3px",padding: "5px 5px 5px 10px", backgroundColor: "#eeeeee",width: "100%", minHeight: "34px"}}>
                  {props.doctor}
                </div>
              </FormField>
              <FormField
                inline={true}
                width={9}>
                <div
                  style={{padding: "0 0.75rem 0 2rem"}}>
                  หมายเหตุ
                </div>
                <div
                  style={{  borderRadius: "3px",padding: "5px 5px 5px 10px", backgroundColor: "#eeeeee",width: "100%", minHeight: "34px"}}>
                  {props.doctorRemark}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{padding: "0 4rem 0 2rem"}}
              widths={16}>
              <FormField
                inline={true}
                width={7}>
                <div
                  style={{width: "170px"}}>
                  เภสัชกร
                </div>
                <div
                  style={{  borderRadius: "3px",padding: "5px 5px 5px 10px", backgroundColor: "#eeeeee",width: "100%", minHeight: "34px"}}>
                  {props.pharmacist}
                </div>
              </FormField>
              <FormField
                inline={true}
                width={9}>
                <div
                  style={{padding: "0 0.75rem 0 2rem"}}>
                  หมายเหตุ
                </div>
                <div
                  style={{  borderRadius: "3px",padding: "5px 5px 5px 10px", backgroundColor: "#eeeeee",width: "100%", minHeight: "34px"}}>
                  {props.pharmacistRemark}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{padding: "0 4rem 0 2rem"}}
              widths={16}>
              <FormField
                inline={true}
                width={7}>
                <div
                  style={{width: "170px"}}>
                  พยาบาล OPD
                </div>
                <div
                  style={{  borderRadius: "3px",padding: "5px 5px 5px 10px", backgroundColor: "#eeeeee",width: "100%", minHeight: "34px"}}>
                  {props.nurseOPD}
                </div>
              </FormField>
              <FormField
                inline={true}
                width={9}>
                <div
                  style={{padding: "0 0.75rem 0 2rem"}}>
                  หมายเหตุ
                </div>
                <div
                  style={{  borderRadius: "3px",padding: "5px 5px 5px 10px", backgroundColor: "#eeeeee",width: "100%", minHeight: "34px"}}>
                  {props.nurseOPDRemark}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{padding: "0 4rem 0 2rem"}}
              widths={16}>
              <FormField
                inline={true}
                width={7}>
                <div
                  style={{width: "170px"}}>
                  พยาบาล IPD
                </div>
                <div
                  style={{  borderRadius: "3px",padding: "5px 5px 5px 10px", backgroundColor: "#eeeeee",width: "100%", minHeight: "34px"}}>
                  {props.nurseIPD}
                </div>
              </FormField>
              <FormField
                inline={true}
                width={9}>
                <div
                  style={{padding: "0 0.75rem 0 2rem"}}>
                  หมายเหตุ
                </div>
                <div
                  style={{  borderRadius: "3px",padding: "5px 5px 5px 10px", backgroundColor: "#eeeeee",width: "100%", minHeight: "34px"}}>
                  {props.nurseIPDRemark}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{padding: "0 4rem 0 2rem"}}
              widths={16}>
              <FormField
                inline={true}
                width={7}>
                <div
                  style={{width: "170px"}}>
                  ผู้บันทึกข้อมูลล่าสุด
                </div>
                <div
                  style={{  borderRadius: "3px",padding: "5px 5px 5px 10px", backgroundColor: "#eeeeee",width: "100%", minHeight: "34px"}}>
                  {props.editUser}
                </div>
              </FormField>
              <FormField
                inline={true}
                width={9}>
                <div
                  style={{padding: "0 0.75rem 0 50px", minWidth: "max-content"}}>
                  วันเวลาที่บันทึกล่าสุด
                </div>
                <div
                  style={{  borderRadius: "3px",padding: "5px 5px 5px 10px", backgroundColor: "#eeeeee",width: "100%", minHeight: "34px", width: "175px", minWidth: "max-content"}}>
                  {props.editDate}
                </div>
                <Button
                  color="blue"
                  disabled={props.disabledLogBtn}
                  onClick={props.onOpenLog}
                  style={{marginLeft: "1rem"}}>
                  Log
                </Button>
                <Button
                  color="yellow"
                  disabled={props.disabledPrintMedReconcile}
                  onClick={props.onPrintMedReconcile}
                  style={{minWidth: "max-content"}}>
                  <Icon
                    name="print">
                  </Icon>
                  <label>
                    พิมพ์ใบ Med reconcile
                  </label>
                </Button>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{padding: "0 4rem 0 2rem"}}
              widths={16}>
              <FormField
                inline={true}
                style={{marginRight: "4rem"}}
                width={5}>
                <div
                  style={{width: "calc(170px - -1rem)"}}>
                  หมายเหตุ
                </div>
                <Input
                  fluid={true}
                  onChange={props.changeRemark}
                  value={props.remark}>
                </Input>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <div>
                  Username
                </div>
                <Input
                  fluid={true}
                  onChange={props.changeUsername}
                  value={props.username}>
                </Input>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <div>
                  Password
                </div>
                <Input
                  fluid={true}
                  onChange={props.changePassword}
                  type="password"
                  value={props.password}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{marginTop: "3rem"}}
              widths={16}>
              <FormField
                style={{flex:1}}>
              </FormField>
              <FormField>
                <Button
                  color="yellow"
                  onClick={props.onSaveDraft}
                  style={{...(props.hideSaveDraft && {display: "none"})}}>
                  SAVE DRAFT
                </Button>
              </FormField>
              <FormField>
                <Button
                  color="green"
                  disabled={props.disabledSaveBtn}
                  onClick={props.onSaveMedReconcile}>
                  SAVE & ACKNOWLEDGE
                </Button>
              </FormField>
              <FormField>
                <Button
                  color="blue"
                  disabled={props.disabledOrderButton}
                  onClick={props.onOrder}
                  style={{...(!!props.hideAckOrderBtn && {display: "none"})}}>
                  ACKNOWLEDGE & ORDER
                </Button>
              </FormField>
            </FormGroup>
          </Form>
        </div>
      </div>
    )
}


export default CardMedReconcileUX

export const screenPropsDefault = {"external":true}

/* Date Time : Tue May 28 2024 13:47:57 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding: \"5px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"none\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"none\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{...(props.medReconcileType === \"TRANSFER\" && {display: \"none\"}), marginTop: \"10px\", borderBottom: \"solid #cccccc 1px\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{...(props.medReconcileType === \"TRANSFER\" && {display: \"none\"})}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ borderTop: \"solid #cccccc 1px\", marginBottom: \"4rem\", paddingTop: \"1rem\"}"
        }
      },
      "seq": 176,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "จากประวัติการสั่งยาจากโรงพยาบาล"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"10px\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 8,
      "name": "Table",
      "parent": 2,
      "props": {
        "data": {
          "type": "code",
          "value": "props.internalItems"
        },
        "headers": {
          "type": "value",
          "value": "รหัสยา,ชื่อยาและความแรง,วิธีใช้,QTY,Start Date,Time,Last Date,Time,Note,Off Hold Cont.,"
        },
        "keys": {
          "type": "value",
          "value": "code,name,label,quantity,start_date,start_time,last_dose_date,last_dose_time,note,_action,remove"
        },
        "minRows": {
          "type": "value",
          "value": "6"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"150px\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติการใช้ยานอกโรงพยาบาล"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"10px\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: !props.external && props.medReconcileType !== \"DISCHARGE\" ?  \"block\" : \"none\", height: \"350px\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.external ? \"block\" : \"none\", height: \"300px\"}"
        }
      },
      "seq": 165,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "Button",
      "parent": 4,
      "props": {
        "children": {
          "type": "code",
          "value": "props.medReconcileType === \"DISCHARGE\" ? \"เลือกรายการยาจาก Medication Reconciliation แรกรับ\":\" เพิ่มจากประวัติการสั่งยาจากโรงพยาบาล\""
        },
        "color": {
          "type": "code",
          "value": "props.external ? null : \"blue\""
        },
        "onClick": {
          "type": "code",
          "value": "props.setInternal"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Button",
      "parent": 4,
      "props": {
        "children": {
          "type": "code",
          "value": "props.externalNameBtn"
        },
        "color": {
          "type": "code",
          "value": "props.external ? \"blue\" :  null"
        },
        "onClick": {
          "type": "code",
          "value": "props.setExternal"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 15,
      "name": "Table",
      "parent": 3,
      "props": {
        "data": {
          "type": "code",
          "value": "props.externalItems"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.externalRowProps"
        },
        "headers": {
          "type": "value",
          "value": "ประเภท,ชื่อ,QTY,Start Date,Time,Last Date,Time,Note,Off Hold Cont.,"
        },
        "keys": {
          "type": "value",
          "value": "typeName,name,quantity,start_date,start_time,last_dose_date,last_dose_time,note,_action,remove"
        },
        "minRows": {
          "type": "value",
          "value": "6"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"150px\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "label": "divgrid",
      "name": "div",
      "parent": 12,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(14, 1fr)\",\"gridTemplateColumns\":\"repeat(50, 1fr)\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภท"
        },
        "style": {
          "type": "code",
          "value": "{gridRow: \"2/3\", gridColumn: \"3/6\", }"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"3/50\",}"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"5px\", textAlign: \"right\", gridRow: \"11/13\", gridColumn: \"2/6\", }"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "div",
      "parent": 18,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/5\", gridColumn: \"3/13\", }"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "div",
      "parent": 18,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"12/14\", gridColumn: \"3/14\", }"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "div",
      "parent": 18,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"12/14\", gridColumn: \"31/41\", justifyContent:\"flex-end\", display: \"flex\"}"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "Button",
      "parent": 68,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่ม/อัพเดทรายการนี้"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.addOtherDrug"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{width: \"fit-content\", height: \"100%\"}"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "Dropdown",
      "parent": 60,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disableDrugType"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeExternalType"
        },
        "options": {
          "type": "code",
          "value": "props.externalTypeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.externalType"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ/รายละเอียด"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"14/20\", }"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "code",
          "value": "props.externalDrug"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/5\", gridColumn: \"14/28\", }"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "Qty"
        },
        "style": {
          "type": "code",
          "value": "{gridRow: \"2/3\", gridColumn: \"29/32\", }"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "div",
      "parent": 18,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/5\", gridColumn: \"29/36\",}"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "Input",
      "parent": 81,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "min": {
          "type": "code",
          "value": "0"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeQty"
        },
        "style": {
          "type": "code",
          "value": "{height: \"100%\"}"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.qty"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "div",
      "parent": 18,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"12/14\", gridColumn: \"42/48\", border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 84,
      "name": "Button",
      "parent": 83,
      "props": {
        "children": {
          "type": "value",
          "value": "เคลียร์"
        },
        "color": {
          "type": "value",
          "value": "orange"
        },
        "onClick": {
          "type": "code",
          "value": "props.clearExternalDrug"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\"}"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"3/6\"}"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": null,
      "id": 86,
      "name": "div",
      "parent": 18,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/9\", gridColumn: \"3/9\"}"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "Unit"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"10/12\"}"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 18,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/9\", gridColumn: \"10/17\"}"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "Route"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"18/20\"}"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": null,
      "id": 90,
      "name": "div",
      "parent": 18,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/9\", gridColumn: \"18/25\"}"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": null,
      "id": 91,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "Site"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"26/28\"}"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "div",
      "parent": 18,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/9\", gridColumn: \"26/33\"}"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 93,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "Frequency"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"34/37\",}"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "div",
      "parent": 18,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/9\", gridColumn: \"34/42\"}"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "Method"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"43/45\"}"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": null,
      "id": 96,
      "name": "div",
      "parent": 18,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/9\", gridColumn: \"43/50\"}"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 97,
      "name": "Input",
      "parent": 86,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "min": {
          "type": "code",
          "value": "0"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeDose"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.dose"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 98,
      "name": "Dropdown",
      "parent": 88,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeUnit"
        },
        "options": {
          "type": "code",
          "value": "props.unitOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{height: \"auto\"}"
        },
        "value": {
          "type": "code",
          "value": "props.unit"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 99,
      "name": "Dropdown",
      "parent": 90,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeRoute"
        },
        "options": {
          "type": "code",
          "value": "props.routeOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.route"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 100,
      "name": "Dropdown",
      "parent": 92,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeSite"
        },
        "options": {
          "type": "code",
          "value": "props.siteOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.site"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 101,
      "name": "Dropdown",
      "parent": 94,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeFrequency"
        },
        "options": {
          "type": "code",
          "value": "props.frequencyOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.frequency"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 102,
      "name": "Dropdown",
      "parent": 96,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeMethod"
        },
        "options": {
          "type": "code",
          "value": "props.methodOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.method"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": null,
      "id": 103,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "Note"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"37/40\"}"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": null,
      "id": 104,
      "name": "div",
      "parent": 18,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/5\", gridColumn: \"37/50\"}"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 105,
      "name": "Input",
      "parent": 104,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeNote"
        },
        "style": {
          "type": "code",
          "value": "{height: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.note"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": null,
      "id": 118,
      "name": "div",
      "parent": 18,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"12/14\", gridColumn: \"12/20\", display:  props.checkedUnknowLastDose? \"block\": \"none\"}"
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": null,
      "id": 119,
      "name": "div",
      "parent": 18,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"14/16\", gridColumn: \"21/34\", display:  props.reason === \"etc\"? \"block\": \"none\"}"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 120,
      "name": "Dropdown",
      "parent": 118,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changeReason"
        },
        "options": {
          "type": "code",
          "value": "props.reasonOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.reason"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 121,
      "name": "Input",
      "parent": 119,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeOtherReason"
        },
        "style": {
          "type": "code",
          "value": "{height: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.otherReason"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 122,
      "name": "Checkbox",
      "parent": 67,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedUnknowLastDose"
        },
        "label": {
          "type": "value",
          "value": "ไม่สามารถระบุ Last dose"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeUnknowLastDose"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"5px\", marginTop:\"0.25rem\"}"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": null,
      "id": 134,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  paddingTop: \"10px\", textAlign: \"right\"}"
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": null,
      "id": 135,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Medication Reconcilliation"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.3rem\", padding: \"5px\"}"
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": null,
      "id": 136,
      "name": "div",
      "parent": 134,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": null,
      "id": 137,
      "name": "div",
      "parent": 134,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 138,
      "name": "Button",
      "parent": 136,
      "props": {
        "children": {
          "type": "value",
          "value": "เลือกใบ Medication Reconciliation"
        },
        "onClick": {
          "type": "code",
          "value": "props.onShowMedReconcileList"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 139,
      "name": "Dropdown",
      "parent": 137,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!!props.disabledMedReconcileType"
        },
        "fluid": {
          "type": "code",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.changeMedReconcileType"
        },
        "options": {
          "type": "code",
          "value": "props.medReconcileTypeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{transform: \"scale(0.85)\"}"
        },
        "value": {
          "type": "code",
          "value": "props.medReconcileType"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": null,
      "id": 141,
      "name": "div",
      "parent": 136,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.showMedReconcileList? \"block\": \"none\", position: \"absolute\", zIndex: 100, padding: \"10px\", backgroundColor: \"#FFF\", right:\"10%\"}"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 142,
      "name": "Table",
      "parent": 141,
      "props": {
        "data": {
          "type": "code",
          "value": "props.medReconcileList"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getTrPropsMedReconcileList"
        },
        "headers": {
          "type": "value",
          "value": "Type,Encounter,วันที่และเวลาสร้าง"
        },
        "keys": {
          "type": "value",
          "value": "med_reconciliation_type_label,name,created_datetime"
        },
        "minRows": {
          "type": "value",
          "value": "3"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"250px\", width: \"600px\", textAlign: \"center\"}"
        },
        "widths": {
          "type": "value",
          "value": "150,,200"
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": null,
      "id": 143,
      "name": "div",
      "parent": 141,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{paddingTop: \"5px\", display: \"flex\", justifyContent: props.enableNewButtonInMedReconcileList ? \"space-between\" : \"flex-end\"}"
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 144,
      "name": "Button",
      "parent": 143,
      "props": {
        "children": {
          "type": "value",
          "value": "New"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onNewMedReconcile"
        },
        "style": {
          "type": "code",
          "value": "{...(!props.enableNewButtonInMedReconcileList && {display: \"none\"})}"
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 145,
      "name": "Button",
      "parent": 143,
      "props": {
        "children": {
          "type": "value",
          "value": "เลือก"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onMedReconcileSelect"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": null,
      "id": 146,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.medReconcileType === \"DISCHARGE\" ||  props.medReconcileType === \"TRANSFER\" ?\"none\":\"flex\", flexDirection: \"column\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 147,
      "name": "Checkbox",
      "parent": 146,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.hasNoPastMed"
        },
        "label": {
          "type": "value",
          "value": "ไม่มีรายการยาเดิม"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeHasNoPastMed"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", width: \"fit-content\"}"
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 149,
      "name": "Checkbox",
      "parent": 146,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.notAvailableAtAdmit"
        },
        "disabled": {
          "type": "code",
          "value": "props.notAvailableDisabled"
        },
        "label": {
          "type": "code",
          "value": "`Not Available at time of admission will be completed in ${props.configMedReconcileAvailable} hour(s). ${props.limitDateTime}`"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeNotAvailableAtAdmit"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", width: \"fit-content\"}"
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": null,
      "id": 155,
      "name": "label",
      "parent": 85,
      "props": {
        "children": {
          "type": "value",
          "value": "Dose"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": null,
      "id": 157,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{...(props.medReconcileType === \"TRANSFER\" && {display: \"none\"})}"
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 159,
      "name": "Table",
      "parent": 157,
      "props": {
        "className": {
          "type": "value",
          "value": "--rt-tbody-overflow-y-hidden"
        },
        "data": {
          "type": "code",
          "value": "props.medicationItems"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.medicationRowProps"
        },
        "headers": {
          "type": "code",
          "value": "props.medReconcileType === \"DISCHARGE\"? \"TYPE,รหัสยา, ชื่อยาและความแรง, วิธีใช้, QTY, Start Date, Time, Last Date, Time, Note,ใช้ยาต่อ,หยุดยา,\" : \"TYPE,รหัสยา, ชื่อยาและความแรง, วิธีใช้, QTY, Start Date, Time, Last Date, Time, Note,Off,Hold,Cont.,Edited date / time,\""
        },
        "keys": {
          "type": "code",
          "value": "props.medReconcileType === \"DISCHARGE\"? \"isExternal,code, name, label,quantity, start_date_text, start_time_text, last_date_text, last_time_text, btn_note,_is_cont,_is_stop,delete\" : \"isExternal,code,name,label,quantity, start_date_text, start_time_text, last_date_text, last_time_text, btn_note,_action_off,_action_hold,_action_cont,edit_time,delete\""
        },
        "minRows": {
          "type": "value",
          "value": "6"
        },
        "name": {
          "type": "value",
          "value": ""
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"300px\"}"
        },
        "widths": {
          "type": "code",
          "value": "props.medReconcileType === \"DISCHARGE\"? \"120,80,,200,50,100,80,100,80,100,100,100,60\" : \"80,80,200,200,50,100,80,100,80,100,60,60,60,120,60\""
        }
      },
      "seq": 170,
      "void": false
    },
    {
      "from": null,
      "id": 165,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: !props.external && props.medReconcileType === \"DISCHARGE\" ?  \"block\" : \"none\", height: \"280px\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 166,
      "name": "div",
      "parent": 165,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": null,
      "id": 167,
      "name": "div",
      "parent": 165,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", marginTop: \"10px\"}"
        }
      },
      "seq": 167,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 168,
      "name": "Table",
      "parent": 166,
      "props": {
        "data": {
          "type": "code",
          "value": "props.drugIPDAdmit"
        },
        "headers": {
          "type": "value",
          "value": ",รหัสยา, ชื่อยาและความแรง, วิธีใช้, QTY, Start Date, Time, Last Date, Time, Note,Off,Hold,Cont."
        },
        "id": {
          "type": "value",
          "value": "drugIPDAdmit"
        },
        "keys": {
          "type": "value",
          "value": "_add,code,nameDiv,label,quantity, start_date_text, start_time_text, last_date_text, last_time_text, btn_note,_action_off,_action_hold,_action_cont"
        },
        "minRows": {
          "type": "value",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height:\"220px\"}"
        },
        "widths": {
          "type": "value",
          "value": "80,80,,200,50,100,80,100,80,100,60,60,60"
        }
      },
      "seq": 168,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 169,
      "name": "Button",
      "parent": 167,
      "props": {
        "children": {
          "type": "value",
          "value": "Add to MR discharge List"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.medReconcileIPDAdmitSelectOrder?.length === 0 && props.medReconcileIPDAdmitSelectItem?.length === 0"
        },
        "fluid": {
          "type": "code",
          "value": ""
        },
        "onClick": {
          "type": "code",
          "value": "props.onAddDrugToMRDischarge"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 169,
      "void": false
    },
    {
      "from": null,
      "id": 170,
      "name": "div",
      "parent": 157,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"space-between\", alignItems: \"center\"}"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": null,
      "id": 171,
      "name": "div",
      "parent": 170,
      "props": {
        "children": {
          "type": "code",
          "value": "props.medReconcileType === \"DISCHARGE\" ? \"Home Medication\" : `Past Medication ${props.pastMedRecordDate}`"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px 0px\", fontSize: \"16px\", fontWeight: \"bold\", flex: 1}"
        }
      },
      "seq": 171,
      "void": false
    },
    {
      "from": null,
      "id": 172,
      "name": "div",
      "parent": 170,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.medReconcileType === \"DISCHARGE\" ? \"flex\" : \"none\", justifyContent: \"space-evenly\", alignItems: \"center\", flex: \"1\"}"
        }
      },
      "seq": 172,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 173,
      "name": "Checkbox",
      "parent": 172,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedIPDHome"
        },
        "label": {
          "type": "value",
          "value": "IPD Home"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeCheckedIPDHome"
        }
      },
      "seq": 173,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 174,
      "name": "Checkbox",
      "parent": 172,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedMRIPD"
        },
        "label": {
          "type": "value",
          "value": "MR IPD แรกรับ"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeCheckedMRIPD"
        }
      },
      "seq": 174,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 175,
      "name": "Checkbox",
      "parent": 172,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedSuggestion"
        },
        "label": {
          "type": "value",
          "value": "Suggestion"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeCheckedSuggestion"
        }
      },
      "seq": 175,
      "void": false
    },
    {
      "from": null,
      "id": 176,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{...(props.medReconcileType !== \"TRANSFER\" && {display: \"none\"})}"
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 178,
      "name": "Button",
      "parent": 176,
      "props": {
        "children": {
          "type": "value",
          "value": "Sync รายการยา (ต้องรับทราบทุกครั้ง)"
        },
        "color": {
          "type": "value",
          "value": "purple"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickSyncDrugTransfer"
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 179,
      "name": "Table",
      "parent": 176,
      "props": {
        "data": {
          "type": "code",
          "value": "props.transferOneDayData"
        },
        "getTdProps": {
          "type": "code",
          "value": "props.transferOneDayTdProps"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.transferOneDayTrProps"
        },
        "headers": {
          "type": "value",
          "value": "TYPE,รหัสยา,ชื่อยาและความแรง,วิธีใช้,QTY,Start Date,Time,Last Date,Time,Note,Off,On"
        },
        "keys": {
          "type": "value",
          "value": "type, code, name, label, quantity, startDate, startTime, lastDoseDate, lastDoseTime,noteIcon,off,on"
        },
        "minRows": {
          "type": "value",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "widths": {
          "type": "value",
          "value": "80,100,auto,auto,60,100,60,100,60,auto,60,60"
        }
      },
      "seq": 180,
      "void": false
    },
    {
      "from": null,
      "id": 180,
      "name": "div",
      "parent": 176,
      "props": {
        "children": {
          "type": "value",
          "value": "Current Medication (Continue)"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bolder\", fontSize: \"larger\",margin: \"20px 0px 5px 0px\"}"
        }
      },
      "seq": 181,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 181,
      "name": "Table",
      "parent": 176,
      "props": {
        "data": {
          "type": "code",
          "value": "props.transferContData"
        },
        "getTdProps": {
          "type": "code",
          "value": "props.transferContTdProps"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.transferContTrProps"
        },
        "headers": {
          "type": "value",
          "value": "TYPE,รหัสยา,ชื่อยาและความแรง,วิธีใช้,QTY,Start Date,Time,Last Date,Time,Note,Off,Hold,Cont"
        },
        "keys": {
          "type": "value",
          "value": "type, code, name, label, quantity, startDate, startTime, lastDoseDate, lastDoseTime,noteIcon,off,hold,continue"
        },
        "minRows": {
          "type": "value",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "widths": {
          "type": "value",
          "value": "80,100,auto,auto,60,100,60,100,60,auto,60,60,80"
        }
      },
      "seq": 182,
      "void": false
    },
    {
      "from": null,
      "id": 182,
      "name": "div",
      "parent": 176,
      "props": {
        "children": {
          "type": "value",
          "value": "Current Medication (One Day)"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bolder\", fontSize: \"larger\", margin: \"20px 0px 5px 0px\"}"
        }
      },
      "seq": 179,
      "void": false
    },
    {
      "from": null,
      "id": 183,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form search-order-history"
        },
        "style": {
          "type": "code",
          "value": "{ marginTop: \"1rem\"  }"
        }
      },
      "seq": 183,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 184,
      "name": "FormGroup",
      "parent": 183,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 184,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 185,
      "name": "FormField",
      "parent": 184,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 185,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 186,
      "name": "FormField",
      "parent": 184,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 186,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 187,
      "name": "FormField",
      "parent": 184,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 187,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 188,
      "name": "FormField",
      "parent": 184,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 188,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 189,
      "name": "FormField",
      "parent": 184,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 189,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 190,
      "name": "FormField",
      "parent": 184,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 190,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 191,
      "name": "FormField",
      "parent": 184,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 191,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 192,
      "name": "FormField",
      "parent": 184,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 192,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 193,
      "name": "Button",
      "parent": 192,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "loading": {
          "type": "code",
          "value": "props.medReconcileLoading"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSearchOrderHistory"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 193,
      "void": false
    },
    {
      "from": null,
      "id": 194,
      "name": "div",
      "parent": 191,
      "props": {
        "children": {
          "type": "code",
          "value": "props.endDateInternal"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 194,
      "void": false
    },
    {
      "from": null,
      "id": 195,
      "name": "div",
      "parent": 190,
      "props": {
        "children": {
          "type": "code",
          "value": "props.startDateInternal"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 195,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 196,
      "name": "Checkbox",
      "parent": 185,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedDoctor"
        },
        "label": {
          "type": "value",
          "value": "ชื่อหรือรหัสแพทย์"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeCheckedDoctor"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", minWidth: \"max-content\"}"
        }
      },
      "seq": 196,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 197,
      "name": "Checkbox",
      "parent": 187,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedDrug"
        },
        "label": {
          "type": "value",
          "value": "ชื่อหรือรหัสยา"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeCheckedDrug"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\",minWidth: \"max-content\"}"
        }
      },
      "seq": 197,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 198,
      "name": "Checkbox",
      "parent": 189,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedDateInternal"
        },
        "label": {
          "type": "value",
          "value": "วันที่สั่งยา"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeCheckedDateInternal"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\",minWidth: \"max-content\"}"
        }
      },
      "seq": 198,
      "void": false
    },
    {
      "from": null,
      "id": 199,
      "name": "div",
      "parent": 186,
      "props": {
        "children": {
          "type": "code",
          "value": "props.searchDoctor"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 219,
      "void": false
    },
    {
      "from": null,
      "id": 200,
      "name": "div",
      "parent": 188,
      "props": {
        "children": {
          "type": "code",
          "value": "props.searchDrug"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 200,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 201,
      "name": "Form",
      "parent": 11,
      "props": {
      },
      "seq": 201,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 202,
      "name": "Table",
      "parent": 201,
      "props": {
        "data": {
          "type": "code",
          "value": "props.drugOrderHistoryItems"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.drugOrderHistoryTrProps"
        },
        "headers": {
          "type": "value",
          "value": ",รหัสยา,ชื่อยา,จำนวนจ่าย,แพทย์,วันที่,หน่วยงาน"
        },
        "keys": {
          "type": "value",
          "value": "_add,code,product_name,quantity,order_by_name,order_time,order_div_name"
        },
        "minRows": {
          "type": "code",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height:\"250px\"}"
        },
        "widths": {
          "type": "value",
          "value": "^50,^100,^200,^90,^100,^90,^100"
        }
      },
      "seq": 202,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 203,
      "name": "Form",
      "parent": 11,
      "props": {
      },
      "seq": 203,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 205,
      "name": "FormGroup",
      "parent": 203,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"1rem\"}"
        },
        "widths": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 205,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 206,
      "name": "FormField",
      "parent": 205,
      "props": {
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 206,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 207,
      "name": "FormField",
      "parent": 205,
      "props": {
      },
      "seq": 207,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 208,
      "name": "Button",
      "parent": 207,
      "props": {
        "children": {
          "type": "value",
          "value": "Add"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onAddInternalItem"
        }
      },
      "seq": 208,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 219,
      "name": "Form",
      "parent": 6,
      "props": {
      },
      "seq": 219,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 220,
      "name": "FormGroup",
      "parent": 219,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 4rem 0 2rem\"}"
        },
        "widths": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 220,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 221,
      "name": "FormGroup",
      "parent": 219,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 4rem 0 2rem\"}"
        },
        "widths": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 221,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 222,
      "name": "FormGroup",
      "parent": 219,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 4rem 0 2rem\"}"
        },
        "widths": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 222,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 223,
      "name": "FormGroup",
      "parent": 219,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 4rem 0 2rem\"}"
        },
        "widths": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 223,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 224,
      "name": "FormGroup",
      "parent": 219,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 4rem 0 2rem\"}"
        },
        "widths": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 224,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 225,
      "name": "FormGroup",
      "parent": 219,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 4rem 0 2rem\"}"
        },
        "widths": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 225,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 226,
      "name": "FormGroup",
      "parent": 219,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"3rem\"}"
        },
        "widths": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 226,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 227,
      "name": "FormField",
      "parent": 220,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 227,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 228,
      "name": "FormField",
      "parent": 220,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "9"
        }
      },
      "seq": 228,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 229,
      "name": "FormField",
      "parent": 221,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 229,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 230,
      "name": "FormField",
      "parent": 221,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "9"
        }
      },
      "seq": 230,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 231,
      "name": "FormField",
      "parent": 222,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 231,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 232,
      "name": "FormField",
      "parent": 222,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "9"
        }
      },
      "seq": 232,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 233,
      "name": "FormField",
      "parent": 223,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 233,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 234,
      "name": "FormField",
      "parent": 223,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "9"
        }
      },
      "seq": 234,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 235,
      "name": "FormField",
      "parent": 224,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 235,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 236,
      "name": "FormField",
      "parent": 224,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "9"
        }
      },
      "seq": 236,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 237,
      "name": "FormField",
      "parent": 225,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"4rem\"}"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 237,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 238,
      "name": "FormField",
      "parent": 225,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 238,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 239,
      "name": "FormField",
      "parent": 225,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 239,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 240,
      "name": "FormField",
      "parent": 226,
      "props": {
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 240,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 241,
      "name": "FormField",
      "parent": 226,
      "props": {
      },
      "seq": 241,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 242,
      "name": "FormField",
      "parent": 226,
      "props": {
      },
      "seq": 242,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 243,
      "name": "FormField",
      "parent": 226,
      "props": {
      },
      "seq": 243,
      "void": false
    },
    {
      "from": null,
      "id": 245,
      "name": "div",
      "parent": 227,
      "props": {
        "children": {
          "type": "value",
          "value": "แพทย์"
        },
        "style": {
          "type": "code",
          "value": "{width: \"170px\"}"
        }
      },
      "seq": 245,
      "void": false
    },
    {
      "from": null,
      "id": 246,
      "name": "div",
      "parent": 227,
      "props": {
        "children": {
          "type": "code",
          "value": "props.doctor"
        },
        "style": {
          "type": "code",
          "value": "{  borderRadius: \"3px\",padding: \"5px 5px 5px 10px\", backgroundColor: \"#eeeeee\",width: \"100%\", minHeight: \"34px\"}"
        }
      },
      "seq": 246,
      "void": false
    },
    {
      "from": null,
      "id": 247,
      "name": "div",
      "parent": 228,
      "props": {
        "children": {
          "type": "value",
          "value": "หมายเหตุ"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 0.75rem 0 2rem\"}"
        }
      },
      "seq": 247,
      "void": false
    },
    {
      "from": null,
      "id": 248,
      "name": "div",
      "parent": 228,
      "props": {
        "children": {
          "type": "code",
          "value": "props.doctorRemark"
        },
        "style": {
          "type": "code",
          "value": "{  borderRadius: \"3px\",padding: \"5px 5px 5px 10px\", backgroundColor: \"#eeeeee\",width: \"100%\", minHeight: \"34px\"}"
        }
      },
      "seq": 248,
      "void": false
    },
    {
      "from": null,
      "id": 249,
      "name": "div",
      "parent": 229,
      "props": {
        "children": {
          "type": "value",
          "value": "เภสัชกร"
        },
        "style": {
          "type": "code",
          "value": "{width: \"170px\"}"
        }
      },
      "seq": 249,
      "void": false
    },
    {
      "from": null,
      "id": 250,
      "name": "div",
      "parent": 229,
      "props": {
        "children": {
          "type": "code",
          "value": "props.pharmacist"
        },
        "style": {
          "type": "code",
          "value": "{  borderRadius: \"3px\",padding: \"5px 5px 5px 10px\", backgroundColor: \"#eeeeee\",width: \"100%\", minHeight: \"34px\"}"
        }
      },
      "seq": 250,
      "void": false
    },
    {
      "from": null,
      "id": 251,
      "name": "div",
      "parent": 230,
      "props": {
        "children": {
          "type": "value",
          "value": "หมายเหตุ"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 0.75rem 0 2rem\"}"
        }
      },
      "seq": 251,
      "void": false
    },
    {
      "from": null,
      "id": 252,
      "name": "div",
      "parent": 230,
      "props": {
        "children": {
          "type": "code",
          "value": "props.pharmacistRemark"
        },
        "className": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{  borderRadius: \"3px\",padding: \"5px 5px 5px 10px\", backgroundColor: \"#eeeeee\",width: \"100%\", minHeight: \"34px\"}"
        }
      },
      "seq": 252,
      "void": false
    },
    {
      "from": null,
      "id": 253,
      "name": "div",
      "parent": 231,
      "props": {
        "children": {
          "type": "value",
          "value": "พยาบาล OPD"
        },
        "style": {
          "type": "code",
          "value": "{width: \"170px\"}"
        }
      },
      "seq": 253,
      "void": false
    },
    {
      "from": null,
      "id": 254,
      "name": "div",
      "parent": 231,
      "props": {
        "children": {
          "type": "code",
          "value": "props.nurseOPD"
        },
        "style": {
          "type": "code",
          "value": "{  borderRadius: \"3px\",padding: \"5px 5px 5px 10px\", backgroundColor: \"#eeeeee\",width: \"100%\", minHeight: \"34px\"}"
        }
      },
      "seq": 254,
      "void": false
    },
    {
      "from": null,
      "id": 255,
      "name": "div",
      "parent": 232,
      "props": {
        "children": {
          "type": "value",
          "value": "หมายเหตุ"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 0.75rem 0 2rem\"}"
        }
      },
      "seq": 255,
      "void": false
    },
    {
      "from": null,
      "id": 256,
      "name": "div",
      "parent": 232,
      "props": {
        "children": {
          "type": "code",
          "value": "props.nurseOPDRemark"
        },
        "style": {
          "type": "code",
          "value": "{  borderRadius: \"3px\",padding: \"5px 5px 5px 10px\", backgroundColor: \"#eeeeee\",width: \"100%\", minHeight: \"34px\"}"
        }
      },
      "seq": 256,
      "void": false
    },
    {
      "from": null,
      "id": 257,
      "name": "div",
      "parent": 233,
      "props": {
        "children": {
          "type": "value",
          "value": "พยาบาล IPD"
        },
        "style": {
          "type": "code",
          "value": "{width: \"170px\"}"
        }
      },
      "seq": 257,
      "void": false
    },
    {
      "from": null,
      "id": 258,
      "name": "div",
      "parent": 233,
      "props": {
        "children": {
          "type": "code",
          "value": "props.nurseIPD"
        },
        "style": {
          "type": "code",
          "value": "{  borderRadius: \"3px\",padding: \"5px 5px 5px 10px\", backgroundColor: \"#eeeeee\",width: \"100%\", minHeight: \"34px\"}"
        }
      },
      "seq": 258,
      "void": false
    },
    {
      "from": null,
      "id": 259,
      "name": "div",
      "parent": 234,
      "props": {
        "children": {
          "type": "value",
          "value": "หมายเหตุ"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 0.75rem 0 2rem\"}"
        }
      },
      "seq": 259,
      "void": false
    },
    {
      "from": null,
      "id": 260,
      "name": "div",
      "parent": 234,
      "props": {
        "children": {
          "type": "code",
          "value": "props.nurseIPDRemark"
        },
        "style": {
          "type": "code",
          "value": "{  borderRadius: \"3px\",padding: \"5px 5px 5px 10px\", backgroundColor: \"#eeeeee\",width: \"100%\", minHeight: \"34px\"}"
        }
      },
      "seq": 260,
      "void": false
    },
    {
      "from": null,
      "id": 261,
      "name": "div",
      "parent": 235,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้บันทึกข้อมูลล่าสุด"
        },
        "style": {
          "type": "code",
          "value": "{width: \"170px\"}"
        }
      },
      "seq": 261,
      "void": false
    },
    {
      "from": null,
      "id": 262,
      "name": "div",
      "parent": 235,
      "props": {
        "children": {
          "type": "code",
          "value": "props.editUser"
        },
        "style": {
          "type": "code",
          "value": "{  borderRadius: \"3px\",padding: \"5px 5px 5px 10px\", backgroundColor: \"#eeeeee\",width: \"100%\", minHeight: \"34px\"}"
        }
      },
      "seq": 262,
      "void": false
    },
    {
      "from": null,
      "id": 263,
      "name": "div",
      "parent": 236,
      "props": {
        "children": {
          "type": "value",
          "value": "วันเวลาที่บันทึกล่าสุด"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 0.75rem 0 50px\", minWidth: \"max-content\"}"
        }
      },
      "seq": 263,
      "void": false
    },
    {
      "from": null,
      "id": 264,
      "name": "div",
      "parent": 236,
      "props": {
        "children": {
          "type": "code",
          "value": "props.editDate"
        },
        "style": {
          "type": "code",
          "value": "{  borderRadius: \"3px\",padding: \"5px 5px 5px 10px\", backgroundColor: \"#eeeeee\",width: \"100%\", minHeight: \"34px\", width: \"175px\", minWidth: \"max-content\"}"
        }
      },
      "seq": 264,
      "void": false
    },
    {
      "from": null,
      "id": 265,
      "name": "div",
      "parent": 237,
      "props": {
        "children": {
          "type": "value",
          "value": "หมายเหตุ"
        },
        "style": {
          "type": "code",
          "value": "{width: \"calc(170px - -1rem)\"}"
        }
      },
      "seq": 265,
      "void": false
    },
    {
      "from": null,
      "id": 266,
      "name": "div",
      "parent": 238,
      "props": {
        "children": {
          "type": "value",
          "value": "Username"
        }
      },
      "seq": 266,
      "void": false
    },
    {
      "from": null,
      "id": 267,
      "name": "div",
      "parent": 239,
      "props": {
        "children": {
          "type": "value",
          "value": "Password"
        }
      },
      "seq": 267,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 268,
      "name": "Input",
      "parent": 237,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeRemark"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.remark"
        }
      },
      "seq": 268,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 269,
      "name": "Input",
      "parent": 238,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeUsername"
        },
        "value": {
          "type": "code",
          "value": "props.username"
        }
      },
      "seq": 269,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 270,
      "name": "Input",
      "parent": 239,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changePassword"
        },
        "type": {
          "type": "value",
          "value": "password"
        },
        "value": {
          "type": "code",
          "value": "props.password"
        }
      },
      "seq": 270,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 273,
      "name": "Button",
      "parent": 243,
      "props": {
        "children": {
          "type": "value",
          "value": "ACKNOWLEDGE & ORDER"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledOrderButton"
        },
        "onClick": {
          "type": "code",
          "value": "props.onOrder"
        },
        "style": {
          "type": "code",
          "value": "{...(!!props.hideAckOrderBtn && {display: \"none\"})}"
        }
      },
      "seq": 273,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 274,
      "name": "Button",
      "parent": 242,
      "props": {
        "children": {
          "type": "value",
          "value": "SAVE & ACKNOWLEDGE"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledSaveBtn"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSaveMedReconcile"
        }
      },
      "seq": 274,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 275,
      "name": "Button",
      "parent": 241,
      "props": {
        "children": {
          "type": "value",
          "value": "SAVE DRAFT"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSaveDraft"
        },
        "style": {
          "type": "code",
          "value": "{...(props.hideSaveDraft && {display: \"none\"})}"
        }
      },
      "seq": 275,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 276,
      "name": "Button",
      "parent": 236,
      "props": {
        "children": {
          "type": "value",
          "value": "Log"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledLogBtn"
        },
        "onClick": {
          "type": "code",
          "value": "props.onOpenLog"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"1rem\"}"
        }
      },
      "seq": 276,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 279,
      "name": "Form",
      "parent": 57,
      "props": {
      },
      "seq": 279,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 280,
      "name": "FormGroup",
      "parent": 279,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "widths": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 280,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 281,
      "name": "FormField",
      "parent": 280,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.55rem\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 281,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 282,
      "name": "FormField",
      "parent": 280,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.55rem\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 282,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 283,
      "name": "FormField",
      "parent": 280,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.55rem\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 283,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 284,
      "name": "FormField",
      "parent": 280,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 284,
      "void": false
    },
    {
      "from": null,
      "id": 285,
      "name": "label",
      "parent": 281,
      "props": {
        "children": {
          "type": "value",
          "value": "Start Date"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 285,
      "void": false
    },
    {
      "from": null,
      "id": 286,
      "name": "div",
      "parent": 281,
      "props": {
        "children": {
          "type": "code",
          "value": "props.startDateElement"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 286,
      "void": false
    },
    {
      "from": null,
      "id": 287,
      "name": "label",
      "parent": 282,
      "props": {
        "children": {
          "type": "value",
          "value": "Time"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 287,
      "void": false
    },
    {
      "from": null,
      "id": 288,
      "name": "label",
      "parent": 283,
      "props": {
        "children": {
          "type": "value",
          "value": "Last Date"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 288,
      "void": false
    },
    {
      "from": null,
      "id": 289,
      "name": "label",
      "parent": 284,
      "props": {
        "children": {
          "type": "value",
          "value": "Time"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 289,
      "void": false
    },
    {
      "from": null,
      "id": 290,
      "name": "div",
      "parent": 282,
      "props": {
        "children": {
          "type": "code",
          "value": "props.StartTimeFreeTextBox"
        }
      },
      "seq": 290,
      "void": false
    },
    {
      "from": null,
      "id": 291,
      "name": "div",
      "parent": 283,
      "props": {
        "children": {
          "type": "code",
          "value": "props.endDateElement"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 291,
      "void": false
    },
    {
      "from": null,
      "id": 292,
      "name": "div",
      "parent": 284,
      "props": {
        "children": {
          "type": "code",
          "value": "props.EndTimeFreeTextBox"
        }
      },
      "seq": 292,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 293,
      "name": "Button",
      "parent": 236,
      "props": {
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledPrintMedReconcile"
        },
        "onClick": {
          "type": "code",
          "value": "props.onPrintMedReconcile"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 293,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 294,
      "name": "Icon",
      "parent": 293,
      "props": {
        "name": {
          "type": "value",
          "value": "print"
        }
      },
      "seq": 294,
      "void": false
    },
    {
      "from": null,
      "id": 295,
      "name": "label",
      "parent": 293,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์ใบ Med reconcile"
        }
      },
      "seq": 295,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardMedReconcileUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "external": true
  },
  "width": 80
}

*********************************************************************************** */
