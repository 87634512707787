import WasmController from 'react-lib/frameworks/WasmController';

export type State = 
  {
  }

export const StateInitial: State = 
  {
  }

export type Event = 
  { message: "putArriveTRTOrder", params: any }
  |  { message: "getTECSession", params: any }

export type Data = 
  {
    division?: number,
  }

export const DataInitial = 
  {
  }

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any 

export const putArriveTRTOrder: Handler = async (controller, {}) => {
//function (_a) {
//            var treatmentOrderId = _a.treatmentOrderId, data = _a.data;
//            return __awaiter(_this, void 0, void 0, function () {
//                var tecService, _b, error, response, network;
//                return __generator(this, function (_c) {
//                    switch (_c.label) {
//                        case 0:
//                            tecService = new TECService_1.default();
//                            return [4 /*yield*/, utils_1.to(tecService.putArriveTRTOrder(treatmentOrderId, data))];
//                        case 1:
//                            _b = _c.sent(), error = _b[0], response = _b[1], network = _b[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (treatmentOrderId, data) {
//        var url = apis_1.TEC.ARRIVE_TRT_ORDER({
//            treatment_order_id: treatmentOrderId
//        });
//        return this.client
//            .put(url, data)
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/TEC/treatment-order-arrive/' +
//((__t = (treatment_order_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const getTECSession: Handler = async (controller, {}) => {
//function (_a) {
//            var encounterId = _a.encounterId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var tecService, _b, error, response, network;
//                return __generator(this, function (_c) {
//                    switch (_c.label) {
//                        case 0:
//                            tecService = new TECService_1.default();
//                            return [4 /*yield*/, utils_1.to(tecService.getTECSession(encounterId))];
//                        case 1:
//                            _b = _c.sent(), error = _b[0], response = _b[1], network = _b[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (encounterId) {
//        var url = apis_1.TEC.GET_SESSION_FROM_ENCOUNTER({
//            encounterId: encounterId
//        });
//        return this.client
//            .get(url)
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/TEC/get-tec-sessions/enconter/' +
//((__t = (encounterId)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}
