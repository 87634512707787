import BaseService from "./BaseService";
import { DPO , HOST} from "../../configs/apis";

class DPOService extends BaseService {
  /**
   * get medical record data
   * @param {object} params
   */
  getClinialType() {
    return this.client
      .get(DPO.GET_CLINICAL_NOTE_TYPE)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getMedicalRecord(params) {
    return this.client
      .get(DPO.MEDICAL_RECORD, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getMedicalRecordEmrId({ emrId }) {
    return this.client
      .get(DPO.MEDICAL_RECORD_EMRID({ emrId }))
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getDiagnosis(emrId) {
    const url = DPO.MEDICAL_RECORD_DIAGNOSIS({ emrId: emrId });
    return this.client
      .get(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  putDiagnosis(emrId, params) {
    const url = DPO.MEDICAL_RECORD_DIAGNOSIS({ emrId: emrId });
    return this.client
      .put(url, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getPatientDiagnosis(params) {
    return this.client
      .get(DPO.DIAGNOSIS, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  patchPatientDiagnosis(diagnosisId, data) {
    const url = DPO.UPDATE_DIAGNOSIS({diagnosisId: diagnosisId})
    return this.client
      .patch(url, data)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getDiagnosisTemplate(params) {
    return this.client
      .get(DPO.DIAGNOSIS_TEMPLATE, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getSearchHistory(params) {
    return this.client
      .get(DPO.SEARCH_TASK_HISTORY, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  postDoctorPendingTaskOrder(params) {
    return this.client
      .post(DPO.DOCTOR_PENDING_TASK_ORDER, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  putDoctorPendingTaskOrder(doctorPendingId, params) {
    const url = DPO.DOCTOR_PENDING_TASK_ORDER_EDIT({
      doctor_pending_id: doctorPendingId,
    });
    return this.client
      .put(url, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  postCancelCheckIn(emrId) {
    const url = DPO.CANCEL_CHECKIN({ patient_emr_id: emrId });
    return this.client
      .put(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getEncounterHistory(params) {
    return this.client
      .get(DPO.ENCOUNTER_HISTORY, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getDoctorConsultOrder(params) {
    return this.client
      .get(DPO.DOCTOR_CONSULT_ORDER, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getDoctorConsultOrderWithId(orderId) {
    const url = DPO.DOCTOR_CONSULT_ORDER_ID({ order_id: orderId });
    return this.client
      .get(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  /**
   * Update doctor consult order
   * Method: PUT
   * @param {*} orderId
   */
  putDoctorConsultOrderWithId(orderId, params) {
    const url = DPO.DOCTOR_CONSULT_ORDER_ID({ order_id: orderId });
    return this.client
      .put(url, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  /**
   * Create doctor consult order
   * Method: POST
   * @param {*} params
   */
  postDoctorConsultOrder(params) {
    return this.client
      .post(DPO.DOCTOR_CONSULT_ORDER, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getSensitiveNote(emrId) {
    const url = DPO.SENSITIVE_NOTE_EDIT({ emr: emrId });
    return this.client
      .get(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getPrintOPDOrderSummary(params) {
    return this.client
      .get(DPO.DOCTOR_ORDER_PRINT, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getPatientQueue(params) {
    return this.client
      .get(DPO.PATIENT_QUEUE, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  // Verify ( หรือ Get Detail) จริงๆ
  discharge(emrId, params, division=null) {
    if (division) {
      this.client.defaults.headers["division"] = division;
    }
    const url = DPO.DISCHARGE({ emrId: emrId });
    return this.client
      .put(url, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  // Verify
  getDoctorCertificateType(params) {
    return this.client
      .get(DPO.DOCTOR_CERTIFICATES_TYPE, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  postDoctorCertificate(params) {
    return this.client
      .post(DPO.DOCTOR_CERTIFICATES, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  putDoctorCertificateCertId({ certId, params }) {
    const url = DPO.DOCTOR_CERTIFICATES_ID({ certId: certId });
    return this.client
      .put(url, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getDiagnosisSummary(params) {
    return this.client
      .get(DPO.DIAGNOSIS_SUMMARY, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getDoctorOrder(params) {
    return this.client
      .get(DPO.DOCTOR_ORDER, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  putDoctorOrder({ params, orderId, data }) {
    const url = DPO.DOCTOR_ORDER_CANCEL({ order_id: orderId });
    return this.client
      .put(url, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getDoctorCertificatesPrintlog(params) {
    return this.client
      .get(DPO.DOCTOR_CERTIFICATES_PRINTLOG, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getMedicalRecordGallery(emrId) {
    const url = DPO.MEDICAL_RECORD_GALLERY({ emrId: emrId });
    return this.client
      .get(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  postMedicalRecordGallery({ emrId, data }) {
    const url = DPO.MEDICAL_RECORD_GALLERY({ emrId: emrId });
    return this.client
      .post(url, data)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  putMedicalRecordPicture({ picId, data }) {
    const url = DPO.MEDICAL_RECORD_PICTURE({ pictureId: picId });
    return this.client
      .put(url, data)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getTemplateGalleryImage(params) {
    return this.client
      .get(DPO.TEMPLATE_GALLERY, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  estimateDoctorOrder(params) {
    return this.client
      .get(DPO.ESTIMATE, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getMedicalRecordEmrDetail({ params, emrId } = {}) {
    const url = DPO.MEDICAL_RECORD_EMR_DETAIL({ emrId: emrId });
    return this.client
      .get(url, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getPatientHistoryEMR({ params, emrId } = {}) {
    const url = DPO.PATIENT_HISTORY_EMR({ emrId: emrId });
    return this.client
      .get(url, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getSensitiveNoteEMR({ params, emrId } = {}) {
    const url = DPO.SENSITIVE_NOTE_EMR({ emrId: emrId });
    return this.client
      .get(url, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  postSensitiveNoteEMR({ data } = {}) {
    return this.client
      .post(DPO.SENSITIVE_NOTE, data)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  putSensitiveNoteEMR({ params, data, emrId } = {}) {
    const url = DPO.SENSITIVE_NOTE_EMR({ emrId: emrId });
    return this.client
      .put(url, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  putPatientHistoryEMR({ params, data, emrId } = {}) {
    const url = DPO.PATIENT_HISTORY_EMR({ emrId: emrId });
    return this.client
      .put(url, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getPhysicalExamOrgan({ params } = {}) {
    return this.client
      .get(DPO.PHYSICAL_EXAM_ORGAN, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  postPatientScreenNew({ params, data } = {}) {
    return this.client
      .post(DPO.PATIENT_SCREEN_NEW, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  putPatientScreenUpdate({ params, data } = {}) {
    return this.client
      .put(DPO.PATIENT_SCREEN_UPDATE, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getProgressionCycleDoctorOrders({ params } = {}) {
    return this.client
      .get(DPO.PROGRESSION_CYCLE_DOCTOR_ORDERS, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  putDoctorOrderEdit({ params, data }) {
    return this.client
      .put(DPO.DOCTOR_PENDING_TASK_ORDER_WITH_EDIT, data, params)
      .then((response) => response.data)
      .catch(this.throwErrorMessage);
  }

  getPhysicalExamByEmr({ params, emrId } = {}) {
    return this.client
      .get(DPO.PHYSICAL_EXAM_EMR_BY_DOCTOR({ emrId }), params)
      .then((response) => response.data)
      .catch(this.throwErrorMessage);
  }

  postPhysicalExamEmrCreate({ params, data } = {}) {
    return this.client
      .post(DPO.PHYSICAL_EXAM_EMR_CREATE_BY_DOCTOR, data, params)
      .then((response) => response.data)
      .catch(this.throwErrorMessage);
  }

  postStandingOrder({ params, data }) {
    // NOT USE URL MAP , use URL inside params
    console.log("postStandingOrder params", params);
    if (params?.url && data) {
      let URL = HOST + params?.url;
      let dataPost = data;
      return this.client
        .post(URL, dataPost)
        .then((response) => response.data)
        .catch(this.throwErrorMessage);
    } else {
      const promise = new Promise((resolve, reject) => {
        reject({
          responseData: "Parameter dataPost are null or undefined",
          error: "Parameter dataPost are null or undefined",
        });
      });
      return promise;
    }
  }

  getStandingOrder({ params }) {
    // NOT USE URL MAP , use URL inside params
    if (params?.url) {
      let URL = HOST + params?.url;
        return this.client
        .get(URL, {})
        .then((response) => response.data)
        .catch(this.throwErrorMessage);
    } else {
      const promise = new Promise((resolve, reject) => {
        reject({
          responseData: "Parameter no url are null or undefined",
          error: "Parameter no url are null or undefined",
        });
      });
      return promise;
    }
  }

  getPhyicalExaminationEmr({ params, emrId }) {
    return this.client
      .get(DPO.PHYSICAL_EXAMINATION_EMR({ emrId }), { params })
      .then((response) => response.data)
      .catch(this.throwErrorMessage);
  }

  putEncounterOPDCheckin({ params = {}, data = {}, encounterId = null } = {}) {
    return this.client
      .put(DPO.ENCOUNTER_OPD_CHECK_IN({ encounterId }), data, { params })
      .then((response) => response.data)
      .catch(this.throwErrorMessage);
  }

  putDoctorCheckoutProgressionCycle({
    params = {},
    data = {},
    progressionCycleId = null,
  } = {}) {
    return this.client
      .put(
        DPO.DOCTOR_CHECKOUT_PROGRESSION_CYCLE({ progressionCycleId }),
        data,
        { params }
      )
      .then((response) => response.data)
      .catch(this.throwErrorMessage);
  }
}
export default DPOService;
