import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';


const CardAssessmentBundleUX = (props: any) => {
    return(
      <div>
        <div
          style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(1, 1fr)","gridTemplateColumns":"repeat(20, 1fr)"}}>
          <div
            style={{"gridRow":"2/3","gridColumn":"16/20"}}>
          </div>
          <div
            style={{"gridRow":"2/3","gridColumn":"16/20"}}>
            <div
              style={{width: "100%" }}>
              {props.buttonSaveCompleted}
            </div>
          </div>
        </div>
      </div>
    )
}

export default CardAssessmentBundleUX

export const screenPropsDefault = {}

/* Date Time : Wed Apr 26 2023 02:55:02 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "label": "divgrid",
      "name": "div",
      "parent": 0,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(1, 1fr)\",\"gridTemplateColumns\":\"repeat(20, 1fr)\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"2/3\",\"gridColumn\":\"16/20\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"2/3\",\"gridColumn\":\"16/20\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSaveCompleted"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\" }"
        }
      },
      "seq": 6,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 15,
  "isMounted": false,
  "memo": false,
  "name": "CardAssessmentBundleUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 65
}

*********************************************************************************** */
