import React, { CSSProperties, useState } from "react";
import {
  Modal,
  Segment,
  Button,
  SemanticCOLORS,
  Form,
  TextArea,
  TextAreaProps,
} from "semantic-ui-react";
import "../../css/ModInfo.css";
import { useIntl } from "react-intl";

type ModMedReconcileNeedRemarkProps = {
  open?: boolean;
  onClose?: any;
  onApprove?: any;
  onCancel?: any;
  btnLoading?: boolean;
  children?: any;
};

const ModMedReconcileNeedRemark = (props: ModMedReconcileNeedRemarkProps) => {
  const intl = useIntl();
  const [textArea, setTextArea] = useState<string>("");

  const titleName = intl.formatMessage({ id: "แจ้งเตือนทบทวนการใช้ยา" });

  let styles: any = {
    header: {
      lineHeight: "1.3em",
    },
    button: {
      marginTop: "10px",
    },
  };
  // console.log(" style", styles.header );

  return (
    <Modal open={props.open} onClose={props.onClose} size="mini">
      <Segment inverted className={"modHeader red"} style={styles.header}>
        {titleName}
      </Segment>
      <Segment padded align="center" className="modContent">
        <div
          style={{
            color: "black",
            fontWeight: "bold",
            fontSize: "larger",
            lineHeight: 1.5,
          }}
        >
          {props.children}
        </div>
        <Form>
          <TextArea
            placeholder={intl.formatMessage({ id: "กรุณาระบุเหตุผล" })}
            value={textArea}
            onChange={(e, d: TextAreaProps) => {
              let text: string =
                typeof d.value === "number"
                  ? d.value.toString()
                  : d.value || "";
              setTextArea(text);
            }}
          />
        </Form>
        <Modal.Actions>
          <Button
            basic
            color="green"
            className="approveButton"
            style={styles.button}
            onClick={() => {
              props.onApprove(textArea);
            }}
            loading={props.btnLoading}
          >
            บันทึก
          </Button>
          <Button
            basic
            color="red"
            className="approveButton"
            style={styles.button}
            onClick={props.onCancel}
            loading={props.btnLoading}
          >
            ยกเลิก
          </Button>
        </Modal.Actions>
      </Segment>
    </Modal>
  );
};

const TYPE = {
  ERROR: "error",
  SUCCESS: "success",
  CANCEL: "cancel",
};

// ModInfo.propTypes = {
//   color: PropTypes.oneOfType([null, PropTypes.string]),
//   type: PropTypes.string,
//   open: PropTypes.bool,
//   onApprove: PropTypes.func,
//   alertText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
//   btnText: PropTypes.string,
//   titleName: PropTypes.string,
//   titleColor: PropTypes.string,
//   size: PropTypes.string,
//   className: PropTypes.string,
//   closeOnDimmerClick: PropTypes.bool,
//   onClose: PropTypes.func,
//   duration: PropTypes.number,
//   buttonColor: PropTypes.string,
//   btnLoading: PropTypes.bool,
//   textAlign: PropTypes.string,
//   blueBackground: PropTypes.bool,
// };

export default ModMedReconcileNeedRemark;
