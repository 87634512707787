const FormChartAppointmentSummary = (props: any) => {
  return {
    pageOrientation: "landscape",
    defaultStyle: {
      font: "THSarabunNew",
      lineHeight: 1,
      fontSize: 13,
    },
    content: [
      {
        image:  props.data
      },
    ]}
}

export default FormChartAppointmentSummary;