import React, { useState, ChangeEventHandler, useEffect, useRef } from "react";
import { DateFormatter, DayPicker, WeekNumberFormatter, CaptionDropdowns, CaptionNavigation, useDayPicker } from "react-day-picker-8";
import { format, isValid, parse } from "date-fns";
import { Input, Popup, Icon, InputOnChangeData, Button } from "semantic-ui-react";
import th from "date-fns/locale/th";

import "react-day-picker-8/dist/style.css";
import "./DateTextBox8.scss";
import { useIntl } from "react-intl";

const DateTextBox8 = (props: any) => {
  const intl = useIntl();
  const [fromYear, setFromYear] = useState(props.fromYear ?? 1872);
  const [toYear, setToYear] = useState(props.toYear ?? 2172);

  const [openCalendar, setOpenCalendar] = useState(false);
  const [selected, setSelected] = useState<Date | Date[]>();

  const [inputValue, setInputValue] = useState<string>("");
  const formatDay: DateFormatter = (day) => day.getDate().toString();
  const contextRef = useRef(null);

  // const formatCaption: DateFormatter = (date, options) => {
  //   const y = (date.getFullYear() + 543).toString();
  //   // const m = date.getFullMonth().toString()
  //   const m = format(date, "LLLL", { locale: options?.locale });
  //   return `${m} ${y}`;
  // };

  const getDateFromValue = (value: string, useAd: boolean) => {
    let date;
    if (useAd) {
      date = parse(value, "dd-MM-y", new Date());
    } else {
      // Substact 543
      let sp = value?.split("-");
      let year = parseInt(sp[2]) ?? 0;
      year = year - 543;
      sp[2] = year.toString();
      date = parse(sp.join("-"), "dd-MM-y", new Date());
    }
    return date;
  };

  const dateToString = (date: Date): string => {
    if (date) {
      let ddmm = format(date, "dd-MM");
      let year = format(date, "yyyy");

      // Add 543
      let value: string = "";
      if (props.useAD) {
        value = ddmm + "-" + year;
      } else {
        value = ddmm + "-" + (parseInt(year) + 543);
      }
      return value;
    }
    return "";
  };

  const formatYearCaption: DateFormatter = (date, options) => {
    let y = "";
    if (props.useAD) {
      y = date.getFullYear().toString();
    } else {
      y = (date.getFullYear() + 543).toString();
    }
    return `${y}`;
  };

  const handleDaySelect = (date: any) => {
    // ไม่ได้ ใช้ mode ในการแยก

    setSelected(date);
    let prepareInputValue = "";
    if (Array.isArray(date)) {
      // multiple selected
      if (date?.length > 0) {
        let list = date?.map((item: any) => dateToString(item));
        prepareInputValue = list?.join(",");
        setInputValue(prepareInputValue);
      } else if (date?.length === 0) {
        setInputValue(prepareInputValue);
      }
    } else {
      // single selected
      if (date) {
        // 543 inside
        prepareInputValue = dateToString(date);
        setInputValue(prepareInputValue);
        setOpenCalendar(false);
      } else {
        setInputValue(prepareInputValue);
      }
    }
    // เลือกจาก ปฏิทิน ส่งไปแจ้ง parent
    props.onChange({ string: prepareInputValue, date: date });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    const pattern = /^[\d-,]*$/;
    if (!pattern.test(event.currentTarget.value)) {
      return;
    } else {
      setInputValue(event.currentTarget.value);
    }
  };

  const handleInputOnBlur = (checkInputText: string, needUpdate: boolean = true) => {
    // ให้ใส่ ได้ แค่ตัวเดียว ก่อน (multiple)
    // ใช้ mode ในการแยก
    var date: Date;

    /// Clear
    if (checkInputText === "") {
      setSelected(undefined);
      setInputValue("");
      if (needUpdate) {
        props.onChange({ string: "", date: undefined });
      }
      return;
    }

    if (props.mode === "multiple") {
      let inputList = checkInputText?.split(",");

      let prepare: any[] = [];
      inputList?.forEach((inputV: any) => {
        date = getDateFromValue(inputV, props.useAd);

        if (isValid(date)) {
          //
          let fullYear = date.getFullYear()?.toString();
          if (fullYear <= toYear && fullYear >= fromYear) {
            prepare = [...prepare, date];
          }
        } else {
          // TODO: when not valid in some date (string)
        }
      });
      //
      if (prepare?.length > 0) {
        setSelected(prepare);
        setInputValue(checkInputText);
        if (needUpdate) {
          props.onChange({ string: checkInputText, date: prepare });
        }

        return;
      }
    } else {
      // single
      date = getDateFromValue(checkInputText, props.useAd);
      if (isValid(date)) {
        //
        let fullYear = date.getFullYear()?.toString();
        if (fullYear <= toYear && fullYear >= fromYear) {
          setSelected(date);
          setInputValue(checkInputText);
          if (needUpdate) {
            props.onChange({ string: checkInputText, date: date });
          }
        }
        return;
      }
    }

    // Not Valid
    if (selected) {
      // load selected to inputTexd
      handleDaySelect(selected);
    } else {
      setSelected(undefined);
      setInputValue("");
    }
  };

  useEffect(() => {
    if (props.mode === "single") {
      handleInputOnBlur(props.valueList, false);
    } else {
      handleInputOnBlur(props.valueList?.join(","), false);
    }
  }, [props.valueList]);

  //
  return (
    <div ref={contextRef}>
      <Popup
        disabled={props.mode === "single" || !inputValue?.includes(",")}
        content={
          inputValue?.includes(",")
            ? inputValue?.split(",")?.map((item: any) => (
                <div key={item}>
                  {item} <br />
                </div>
              ))
            : inputValue
        }
        trigger={
          <Input
            style={{ maxWidth: "190px" }}
            value={inputValue}
            onChange={handleInputChange}
            onBlur={() => {
              handleInputOnBlur(inputValue);
            }}
            icon={
              <Popup
                className="date-text-box-8"
                basic
                context={contextRef}
                open={openCalendar}
                onClose={() => {
                  setOpenCalendar(false);
                }}
                onOpen={() => {
                  setOpenCalendar(true);
                }}
                content={
                  <DayPicker
                    components={{
                      Caption: (props: any) => {
                        const { classNames, disableNavigation, styles, captionLayout, components } = useDayPicker();
                        return (
                          <div className={classNames.caption} style={styles.caption}>
                            <CaptionDropdowns displayMonth={props.displayMonth} id={props.id} />
                            <CaptionNavigation displayMonth={props.displayMonth} id={props.id} />
                          </div>
                        );
                      },
                      CaptionLabel: () => null,
                    }}
                    initialFocus={openCalendar}
                    defaultMonth={Array.isArray(selected) ? (selected?.length > 0 ? selected[0] : undefined) : selected}
                    selected={selected}
                    onSelect={handleDaySelect}
                    // mode="single"
                    // mode="multiple"
                    mode={props.mode}
                    locale={th}
                    formatters={{ formatDay, formatYearCaption }}
                    fromYear={fromYear}
                    toYear={toYear}
                    modifiers={props.modifiers}
                    modifiersStyles={props.modifiersStyles}
                    // captionLayout="dropdown"
                  />
                }
                on="click"
                // position='bottom center'
                trigger={<Icon link name="calendar" />}
              />
            }
            placeholder={"DD-MM-YYYY" + (!props.useAD ? " (พ.ศ.)" : " (ค.ศ.)")}
          />
        }
      ></Popup>
    </div>
  );
};

export default DateTextBox8;

/*{/*  */
