import { to, setStateAsync } from "../../utils";
import _ from "../../compat/lodashplus";
import APPService from "../services/APPService";
export default class APPManager {
  apiToken?: string | null;

  constructor(apiToken: string | null = null) {
    this.apiToken = apiToken
  }
  /**
   * Get appointment with appointment ID
   */
  getAppointmentWithId = async (appointmentId) => {
    const appService = new APPService(this.apiToken);
    const [error, response] = await to(
      appService.getAppointmentWithId(appointmentId)
    );
    return [response, error];
  };
  /**
   * Delete appointment with appointment ID
   */
  deleteAppointmentWithId = async (app = APPOINTMENT_MODEL) => {
    let params = this._convertToAppointmentModel(app);
    const appService = new APPService(this.apiToken);
    const [error, response] = await to(
      appService.deleteAppointmentWithId(app.id, params)
    );
    return [response, error];
  };
  cancelAppointment = async (appointmentId, params) => {
    const appService = new APPService(this.apiToken);
    const [error, response] = await to(
      appService.cancelAppointment(appointmentId, params)
    );
    return [response, error];
  };
  /**
   * Update appointment with appointment ID
   */
  updateAppointmentWithId = async (app = APPOINTMENT_MODEL) => {
    let params = this._convertToAppointmentModel(app);
    const appService = new APPService(this.apiToken);
    const [error, response] = await to(
      appService.putAppointmentWithId(app.id, params)
    );
    return [response, error];
  };
  postponeAppointmentWithId = async (app = APPOINTMENT_MODEL) => {
    let params = this._convertToAppointmentModel(app);
    const appService = new APPService(this.apiToken);
    const [error, response] = await to(
      appService.postponeAppointmentWithId(app.id, params)
    );
    return [response, error];
  };
  /**
   * Get doctor schedule with division
   */
  getDoctorWorkAtDivision = async (params) => {
    var doctorData = [];
    var doctorError = null;
    const appService = new APPService(this.apiToken);
    const [error, response] = await to(
      appService.getDoctorWorkAtDivision(params)
    );
    if (error) {
      doctorError = error;
    } else {
      doctorData = response.items;
    }
    return [doctorData, doctorError];
  };
  /**
   * Convert doctor schedule list to choice list
   */
  convertDoctorScheduleToChoice = (doctorList) => {
    var choices = [];
    if (doctorList) {
      if (doctorList.length > 0) {
        choices = _.map(doctorList, (item) => ({
          id: item.id,
          text: item.name,
          value: item.code,
        }));
      }
    }
    return choices;
  };
  getAppointmentOrder = async ({
    patientId = null,
    type = null,
    appointment_type = null,
    excludeCanceled = true,
    requestFlag,
    excludePostponed,
    excludeFinished,
    status=null,
  } = {}) => {
    var consultData = [];
    var consultError = null;
    let params = {
      exclude_canceled: excludeCanceled,
    };
    if (patientId != null) {
      params.patient = patientId;
    }
    if (type != null) {
      params.type = type;
    }
    if (appointment_type != null) {
      params.appointment_type = appointment_type;
    }
    if (requestFlag != null) {
      params.request_flag = requestFlag;
    }
    if (excludePostponed != null) {
      params.exclude_postponed = excludePostponed;
    }
    if (excludeFinished != null) {
      params.exclude_finished = excludeFinished;
    }
    if(status!=null){
      params.status = status;
    }
    const appService = new APPService(this.apiToken);
    const [error, response] = await to(appService.getAppointment(params));
    if (error) {
      consultError = error;
    } else {
      consultData = response.items;
    }
    return [consultData, consultError];
  };

  getAppointmentOrderRaw = async (params) => {
    const appService = new APPService(this.apiToken);
    var consultData = [];
    var consultError = null;
    const [error, response] = await to(appService.getAppointment(params));
    if (error) {
      consultError = error;
    } else {
      consultData = response;
    }
    return [consultData, consultError];
  };
  /**
   * Get list of doctor note
   */
  getDoctorNote = async ({ language, searchText } = {}) => {
    var noteData = [];
    var noteError = null;
    let params = {};
    if (language) {
      params.language = language;
    }
    if (searchText) {
      params.text__contains = searchText;
    }
    const appService = new APPService(this.apiToken);
    const [error, response] = await to(appService.getDoctorNote(params));
    if (error) {
      noteError = error;
    } else {
      noteData = response.items;
    }
    return [noteData, noteError];
  };
  /**
   * Get list of doctor note group
   */
  getDoctorNoteGroup = async ({ language, searchText } = {}) => {
    var noteGroupData = [];
    var noteGroupError = null;
    let params = {};
    if (language) {
      params.language = language;
    }
    if (searchText) {
      params.name__contains = searchText;
    }
    const appService = new APPService(this.apiToken);
    const [error, response] = await to(appService.getDoctorNoteGroup(params));
    if (error) {
      noteGroupError = error;
    } else {
      noteGroupData = response.items;
    }
    return [noteGroupData, noteGroupError];
  };
  getDoctorScheduleAppointment = async (params) => {
    let doctorData = [];
    let doctorError = null;
    const appService = new APPService(this.apiToken);
    const [error, response] = await to(
      appService.getDoctorScheduleAppointment(params)
    );
    if (error) {
      doctorError = error;
    } else {
      doctorData = response.items;
    }
    return [doctorData, doctorError];
  };
  /**
   * Request appointment
   */
  postAppointmentRequest = async (app = APPOINTMENT_MODEL) => {
    let params = this._convertToAppointmentModel(app);
    const appService = new APPService();
    const [error, response] = await to(appService.postAppointmentRequest(app));
    return [response, error];
  };
  /**
   * Print Appointment
   */
  printAppointment = async (appointmentId) => {
    var result = [];
    const appService = new APPService(this.apiToken);
    const [error, response] = await to(
      appService.printAppointment(appointmentId)
    );
    if (response) {
      result = response;
    }
    return [result, error];
  };
  /**
   * Private function
   */
  _convertToAppointmentModel = (app) => {
    let model = {
      id: app.id ? app.id : null,
      action: app.action ? app.action : "",
      patient: app.patient ? app.patient : null,
      order_encounter: app.order_encounter ? app.order_encounter : null,
      doctor: app.doctor ? app.doctor : "",
      date: app.date ? app.date : "",
      start_time: app.start_time ? app.start_time : "",
      end_time: app.end_time ? app.end_time : "",
      extend_queue: app.extend_queue !== null ? app.extend_queue : false,
      note_to_nurse: app.note_to_nurse ? app.note_to_nurse : "",
      note_to_patient: app.note_to_patient ? app.note_to_patient : "",
      doctor_note: app.doctor_note ? app.doctor_note : "",
      from_schedule: app.from_schedule ? app.from_schedule : null,
      from_schedule_detail: app.from_schedule_detail
        ? app.from_schedule_detail
        : "",
      from_schedule_doctor_name: app.from_schedule_doctor_name
        ? app.from_schedule_doctor_name
        : "",
      is_editable: app.is_editable !== null ? app.is_editable : false,
      from_follow_up: app.from_follow_up !== null ? app.from_follow_up : 0,
      from_follow_up_division: app.from_follow_up_division
        ? app.from_follow_up_division
        : null,
      from_follow_up_doctor: app.from_follow_up_doctor
        ? app.from_follow_up_doctor
        : null,
      repeat_number: app.repeat_number !== null ? app.repeat_number : 0,
      repeat_frequency:
        app.repeat_frequency !== null ? app.repeat_frequency : 0,
      status: app.status !== null ? app.status : null,
      add_to_order_episode: app.add_to_order_episode
        ? app.add_to_order_episode
        : false,
      order_dict: app.order_dict ? app.order_dict : {},
      order_doctor: app.order_doctor ? app.order_doctor : null,
      children: app.children ? app.children : {},
    };
    return model;
  };
}
export const APPOINTMENT_MODEL = {
  id: null,
  action: "REQUEST",
  patient: null,
  order_encounter: null,
  doctor: "",
  date: null,
  start_time: "",
  end_time: "",
  extend_queue: false,
  note_to_nurse: "",
  note_to_patient: "",
  doctor_note: "",
  from_schedule: null,
  from_schedule_detail: "",
  from_schedule_doctor_name: "",
  is_editable: false,
  from_follow_up: 0,
  repeat_number: 0,
  repeat_frequency: 0,
  status: "",
  order_dict: {},
  children: {}
};
