import React from 'react'
import {
   Button,
   Checkbox,
   Dropdown,
   Input,
} from 'semantic-ui-react'



import styles from './POPhysicalexaminationAnkleFootOrthosisLOW.module.css'

export interface POPhysicalexaminationAnkleFootOrthosisLOWViewDataType {
    PoPhysicalAnkleFootOrthosisLow2: string,
    PoPhysicalAnkleFootOrthosisLow2Disabled: boolean,
    PoPhysicalAnkleFootOrthosisLow2Options: any,
    PoPhysicalAnkleFootOrthosisLow5: string,
    PoPhysicalAnkleFootOrthosisLow5Disabled: boolean,
    PoPhysicalAnkleFootOrthosisLow7: string,
    PoPhysicalAnkleFootOrthosisLow7Disabled: boolean,
    PoPhysicalAnkleFootOrthosisLow7Options: any,
    PoPhysicalAnkleFootOrthosisLow10: string,
    PoPhysicalAnkleFootOrthosisLow10Disabled: boolean,
    PoPhysicalAnkleFootOrthosisLow12: string,
    PoPhysicalAnkleFootOrthosisLow12Disabled: boolean,
    PoPhysicalAnkleFootOrthosisLow12Options: any,
    PoPhysicalAnkleFootOrthosisLow15: string,
    PoPhysicalAnkleFootOrthosisLow15Disabled: boolean,
    PoPhysicalAnkleFootOrthosisLow17: string,
    PoPhysicalAnkleFootOrthosisLow17Disabled: boolean,
    PoPhysicalAnkleFootOrthosisLow17Options: any,
    PoPhysicalAnkleFootOrthosisLow20: string,
    PoPhysicalAnkleFootOrthosisLow20Disabled: boolean,
    PoPhysicalAnkleFootOrthosisLow22: string,
    PoPhysicalAnkleFootOrthosisLow22Disabled: boolean,
    PoPhysicalAnkleFootOrthosisLow25: boolean,
    PoPhysicalAnkleFootOrthosisLow25Disabled: boolean,
    PoPhysicalAnkleFootOrthosisLow27: string,
    PoPhysicalAnkleFootOrthosisLow27Disabled: boolean,
    PoPhysicalAnkleFootOrthosisLow29: boolean,
    PoPhysicalAnkleFootOrthosisLow29Disabled: boolean,
    SaveRequestDisabled: boolean,
}

export const POPhysicalexaminationAnkleFootOrthosisLOWInitialViewData: POPhysicalexaminationAnkleFootOrthosisLOWViewDataType = {
    PoPhysicalAnkleFootOrthosisLow2: "",
    PoPhysicalAnkleFootOrthosisLow2Disabled: false,
    PoPhysicalAnkleFootOrthosisLow2Options: [{key: 0, value: "A", text: "A"}],
    PoPhysicalAnkleFootOrthosisLow5: "",
    PoPhysicalAnkleFootOrthosisLow5Disabled: false,
    PoPhysicalAnkleFootOrthosisLow7: "",
    PoPhysicalAnkleFootOrthosisLow7Disabled: false,
    PoPhysicalAnkleFootOrthosisLow7Options: [{key: 0, value: "A", text: "A"}],
    PoPhysicalAnkleFootOrthosisLow10: "",
    PoPhysicalAnkleFootOrthosisLow10Disabled: false,
    PoPhysicalAnkleFootOrthosisLow12: "",
    PoPhysicalAnkleFootOrthosisLow12Disabled: false,
    PoPhysicalAnkleFootOrthosisLow12Options: [{key: 0, value: "A", text: "A"}],
    PoPhysicalAnkleFootOrthosisLow15: "",
    PoPhysicalAnkleFootOrthosisLow15Disabled: false,
    PoPhysicalAnkleFootOrthosisLow17: "",
    PoPhysicalAnkleFootOrthosisLow17Disabled: false,
    PoPhysicalAnkleFootOrthosisLow17Options: [{key: 0, value: "A", text: "A"}],
    PoPhysicalAnkleFootOrthosisLow20: "",
    PoPhysicalAnkleFootOrthosisLow20Disabled: false,
    PoPhysicalAnkleFootOrthosisLow22: "",
    PoPhysicalAnkleFootOrthosisLow22Disabled: false,
    PoPhysicalAnkleFootOrthosisLow25: false,
    PoPhysicalAnkleFootOrthosisLow25Disabled: false,
    PoPhysicalAnkleFootOrthosisLow27: "",
    PoPhysicalAnkleFootOrthosisLow27Disabled: false,
    PoPhysicalAnkleFootOrthosisLow29: false,
    PoPhysicalAnkleFootOrthosisLow29Disabled: false,
    SaveRequestDisabled: false,
}

const Sub = (props: any) => {
    const children = React.Children.toArray(props.children)
    return (
        <div className={styles.container}>
            <div className={styles.PoPhysicalAnkleFootOrthosisLow1}>
                <div
                    style={{background: "#F3F3F3", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.PoPhysicalAnkleFootOrthosisLow2}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.PoPhysicalAnkleFootOrthosisLow2}
                    disabled={ props.viewData.PoPhysicalAnkleFootOrthosisLow2Disabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "POPhysicalexaminationAnkleFootOrthosisLOW",
                                name: "PoPhysicalAnkleFootOrthosisLow2",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.PoPhysicalAnkleFootOrthosisLow2Options}
                />
            </div>
            <div className={styles.PoPhysicalAnkleFootOrthosisLow5}>
                <Input
                    value={props.viewData.PoPhysicalAnkleFootOrthosisLow5}
                    disabled={ props.viewData.PoPhysicalAnkleFootOrthosisLow5Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationAnkleFootOrthosisLOW',
                            name: 'PoPhysicalAnkleFootOrthosisLow5',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalAnkleFootOrthosisLow6}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Ankle stability</strong></p>
                </div>
            </div>
            <div className={styles.PoPhysicalAnkleFootOrthosisLow7}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.PoPhysicalAnkleFootOrthosisLow7}
                    disabled={ props.viewData.PoPhysicalAnkleFootOrthosisLow7Disabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "POPhysicalexaminationAnkleFootOrthosisLOW",
                                name: "PoPhysicalAnkleFootOrthosisLow7",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.PoPhysicalAnkleFootOrthosisLow7Options}
                />
            </div>
            <div className={styles.PoPhysicalAnkleFootOrthosisLow10}>
                <Input
                    value={props.viewData.PoPhysicalAnkleFootOrthosisLow10}
                    disabled={ props.viewData.PoPhysicalAnkleFootOrthosisLow10Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationAnkleFootOrthosisLOW',
                            name: 'PoPhysicalAnkleFootOrthosisLow10',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalAnkleFootOrthosisLow11}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Knee stability</strong></p>
                </div>
            </div>
            <div className={styles.PoPhysicalAnkleFootOrthosisLow12}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.PoPhysicalAnkleFootOrthosisLow12}
                    disabled={ props.viewData.PoPhysicalAnkleFootOrthosisLow12Disabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "POPhysicalexaminationAnkleFootOrthosisLOW",
                                name: "PoPhysicalAnkleFootOrthosisLow12",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.PoPhysicalAnkleFootOrthosisLow12Options}
                />
            </div>
            <div className={styles.PoPhysicalAnkleFootOrthosisLow15}>
                <Input
                    value={props.viewData.PoPhysicalAnkleFootOrthosisLow15}
                    disabled={ props.viewData.PoPhysicalAnkleFootOrthosisLow15Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationAnkleFootOrthosisLOW',
                            name: 'PoPhysicalAnkleFootOrthosisLow15',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalAnkleFootOrthosisLow16}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Hip</strong></p>
                </div>
            </div>
            <div className={styles.PoPhysicalAnkleFootOrthosisLow17}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.PoPhysicalAnkleFootOrthosisLow17}
                    disabled={ props.viewData.PoPhysicalAnkleFootOrthosisLow17Disabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "POPhysicalexaminationAnkleFootOrthosisLOW",
                                name: "PoPhysicalAnkleFootOrthosisLow17",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.PoPhysicalAnkleFootOrthosisLow17Options}
                />
            </div>
            <div className={styles.PoPhysicalAnkleFootOrthosisLow20}>
                <Input
                    value={props.viewData.PoPhysicalAnkleFootOrthosisLow20}
                    disabled={ props.viewData.PoPhysicalAnkleFootOrthosisLow20Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationAnkleFootOrthosisLOW',
                            name: 'PoPhysicalAnkleFootOrthosisLow20',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalAnkleFootOrthosisLow21}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Deformity</strong></p>
                </div>
            </div>
            <div className={styles.PoPhysicalAnkleFootOrthosisLow22}>
                <Input
                    value={props.viewData.PoPhysicalAnkleFootOrthosisLow22}
                    disabled={ props.viewData.PoPhysicalAnkleFootOrthosisLow22Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationAnkleFootOrthosisLOW',
                            name: 'PoPhysicalAnkleFootOrthosisLow22',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalAnkleFootOrthosisLow23}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Frontal plain</strong></p>
                </div>
            </div>
            <div className={styles.PoPhysicalAnkleFootOrthosisLow24}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Gait pattern</strong></p>
                </div>
            </div>
            <div className={styles.PoPhysicalAnkleFootOrthosisLow25}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalAnkleFootOrthosisLow25}
                    disabled={ props.viewData.PoPhysicalAnkleFootOrthosisLow25Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationAnkleFootOrthosisLOW",
                                name: "PoPhysicalAnkleFootOrthosisLow25"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalAnkleFootOrthosisLow26}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>N/A</p>
                </div>
            </div>
            <div className={styles.PoPhysicalAnkleFootOrthosisLow27}>
                <Input
                    value={props.viewData.PoPhysicalAnkleFootOrthosisLow27}
                    disabled={ props.viewData.PoPhysicalAnkleFootOrthosisLow27Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationAnkleFootOrthosisLOW',
                            name: 'PoPhysicalAnkleFootOrthosisLow27',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalAnkleFootOrthosisLow28}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Sagittal plain</strong></p>
                </div>
            </div>
            <div className={styles.PoPhysicalAnkleFootOrthosisLow29}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalAnkleFootOrthosisLow29}
                    disabled={ props.viewData.PoPhysicalAnkleFootOrthosisLow29Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationAnkleFootOrthosisLOW",
                                name: "PoPhysicalAnkleFootOrthosisLow29"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalAnkleFootOrthosisLow30}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>N/A</p>
                </div>
            </div>
            <div className={styles.SaveRequest}>
                <Button
                    disabled={ props.viewData.SaveRequestDisabled }
                    onClick={(e) => { props.onEvent({
                        message: 'clickButton',
                        params: {
                            view: 'POPhysicalexaminationAnkleFootOrthosisLOW',
                            name: 'SaveRequest'
                        }
                    })}}
                    color="green"
                    style={{height: "100%", width: "100%"}}
                >
                    Save
                </Button>
            </div>
        </div>
    )
}

export default Sub

const getTsName = (cssClass: string) => {
    return cssClass.split('-').map((s) => (
        s.charAt(0).toUpperCase() + s.slice(1)
    )).join('')
}
