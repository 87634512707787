import React, { useEffect, useState } from "react";
import CardLayout from '../common/CardLayout';
import { Grid, Label, Header , Dimmer , Loader, Button, Icon} from 'semantic-ui-react'
import { formatToBEDatetime } from '../../utils';
import moment from 'moment';
import { useIntl } from "react-intl";
import CONFIG from "config/config";

const styles = {
  gridHeader: {
    fontWeight: 'bold',
    backgroundColor: '#F2F2F2'
  },
  subGridHeader: {
    fontWeight: 'bold',
  }
}

const CardDischargeSummaryView = (props) => {
  const [isLoadingPrintReport, setIsLoadingPrintReport] = useState(false);

  const intl = useIntl();

  const { dischargeSummaryData } = props;


  const admittedStatus = dischargeSummaryData.status ? dischargeSummaryData.status : ""
  const dischargeSummary = dischargeSummaryData.dischargeSummary ? dischargeSummaryData.dischargeSummary : {}
  const diagnosis = dischargeSummaryData.diagnosis ? dischargeSummaryData.diagnosis : {}
  const admissionForm = dischargeSummaryData.admissionForm ? dischargeSummaryData.admissionForm : {}
  const procedure = dischargeSummaryData.procedure ? dischargeSummaryData.procedure : {}
  const drg = dischargeSummaryData.drg ? dischargeSummaryData.drg : {}

  useEffect(() => {
    props.onGetDischargeSummary({})
  }, [])

  const generateDiagnosis = (diagnosisList, type) => {
    return diagnosisList.map((items, index) => {
      if (!items) {
        return ''
      }
      return (
        <div key={index}>
          {(items.icd10_code) + ' ' + items.icd10_term}
          <label style={{ color: '#939999' }}>&ensp;{type === 'Primary' ? 'Primary' : 'Secondary'}</label>
        </div>
      )
    })
  }

  const generateProcedure = (procedureList) => {
    return procedureList.map((items, index) => {
      return (
        <div key={index}>
          {items.icd9cm_code ? items.icd9cm_code : ''}{' '}{items.icd9cm_term ? items.icd9cm_term : ''}
          {items.medical_description ? '(' + items.medical_description + ')' : ''}
        </div>
      )
    })
  }

  const generateData = () => {
    return (
      <div>
        <Grid celled>
          <Grid.Row >
            <Grid.Column width={3} style={styles.gridHeader}>
              Plan Discharge
                </Grid.Column>
            <Grid.Column width={13}>
              {dischargeSummary.plan_discharge} &ensp; {dischargeSummary.plan_discharge_type_label}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row >
            <Grid.Column width={3} style={styles.gridHeader}>
              Provisional Diagnosis
                </Grid.Column>
            <Grid.Column width={13}>
              {admissionForm.provisional_diagnosis ? generateDiagnosis([admissionForm.provisional_diagnosis.primary], 'Primary') : ''}
              {admissionForm.provisional_diagnosis ? generateDiagnosis(admissionForm.provisional_diagnosis.secondary, 'Secondary') : ''}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row >
            <Grid.Column width={3} style={styles.gridHeader}>
              Final Diagnosis
                </Grid.Column>
            <Grid.Column width={13}>
              {diagnosis.primary ? generateDiagnosis([diagnosis.primary], 'Primary') : ''}
              {diagnosis.secondary ? generateDiagnosis(diagnosis.secondary, 'Secondary') : ''}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row >
            <Grid.Column width={3} style={styles.gridHeader}>
              Primary Procedure
                </Grid.Column>
            <Grid.Column width={6}>
              {procedure.primary_procedure ? generateProcedure([procedure.primary_procedure]) : ''}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={3} style={styles.gridHeader}>
              Secondary Procedure
                </Grid.Column>
            <Grid.Column width={6}>
              {procedure.secondary_procedures ? generateProcedure(procedure.secondary_procedures) : ''}
            </Grid.Column>
          </Grid.Row>
          {/* <Grid.Row >
            <Grid.Column width={3} style={styles.gridHeader}>
              DRG
                </Grid.Column>
            <Grid.Column width={13}>
              <span style={styles.subGridHeader}>DRG</span> &ensp; {drg.drg}
              &ensp; <span style={styles.subGridHeader}>RW</span> &ensp; {drg.rw}
              &ensp; <span style={styles.subGridHeader}>Adjust RW</span> &ensp; {drg.adj_rw}
            </Grid.Column>
          </Grid.Row> */}
          <Grid.Row >
            <Grid.Column width={3} style={styles.gridHeader}>
              Brief History and Physical Findlings
                </Grid.Column>
            <Grid.Column width={13}>
            {dischargeSummary.brief ? dischargeSummary.brief.split('\n').map((item, key) => {
              return <React.Fragment key={key}>{item}<br/></React.Fragment>
            }) : null}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row >
            <Grid.Column width={3} style={styles.gridHeader}>
              Hospital Course
                </Grid.Column>
            <Grid.Column width={13}>
            {dischargeSummary.course ? dischargeSummary.course.split('\n').map((item, key) => {
              return <React.Fragment key={key}>{item}<br/></React.Fragment>
            }) : null}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row >
            <Grid.Column width={3} style={styles.gridHeader}>
              Disposition/Condition at discharge
                </Grid.Column>
            <Grid.Column width={13}>
              {dischargeSummary.condition}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row >
            <Grid.Column width={3} style={styles.gridHeader}>
              Follow up plan
                </Grid.Column>
            <Grid.Column width={13}>
              {dischargeSummary.followup_plan}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row >
            <Grid.Column width={3} style={styles.gridHeader}>
              Discharge status
                </Grid.Column>
            <Grid.Column width={13}>
              {dischargeSummary.discharge_status_label}
            </Grid.Column>
          </Grid.Row>
          {CONFIG.COMPANY !== "CU" && <Grid.Row >
            <Grid.Column width={3} style={styles.gridHeader}>
              Re-admission Type
                </Grid.Column>
            <Grid.Column width={13}>
              {dischargeSummary.readmission_type_label}
            </Grid.Column>
          </Grid.Row>}
        </Grid>
        <br />
      </div>
    )
  }

  const handlePrintReport = async () => {
    if (isLoadingPrintReport) {
      return;
    }

    setIsLoadingPrintReport(true);

    await props.onPrintDischargeSummary({ dischargeSummaryData });

    setIsLoadingPrintReport(false);
  };

  let titleContent = null;
  if (dischargeSummary && !props.showBtnPrint) {
    console.log("discharge:", dischargeSummary.edited);
    titleContent = (
      <Label
        style={{ color: "black", marginLeft: "1rem" }}
        content={formatToBEDatetime({ date: moment(dischargeSummary.edited) })}
      />
    );
    //
  }

  const content = (
    <CardLayout
      titleText="Discharge Summary"
      headerColor="teal"
      closeable={props.closeable}
      toggleable={props.toggleable}
      defaultBackground={props.defaultBackground}
      hideHeaderIcon={props.hideHeaderIcon}
      loading={props.dischargeSummaryLoading}
      titleContent={titleContent}
      enableMargin={props.enableMargin}
    >
      {props.showBtnPrint && props.encounterType === "IPD" && (
        <Button
          color="green"
          size="mini"
          loading={isLoadingPrintReport}
          style={{
            left: "14rem",
            position: "absolute",
            top: "11px",
          }}
          onClick={handlePrintReport}
        >
          <Icon name="print" />
          IPD Report
        </Button>
      )}

      <div style={{ height: 480, overflowY: "auto" }}>
        {props.encounterType === "IPD" ? generateData() : null}
      </div>
    </CardLayout>
  );


  return (
    <>
      {admittedStatus !== "NODISCHARGESUMMARY" ? (
        content
      ) : (
        <CardLayout
          titleText="Discharge Summary"
          closeable={false}
          toggleable={false}
          hideHeaderIcon={true}
        >
          <Dimmer active={props.dischargeSummaryLoading} inverted>
            <Loader />
          </Dimmer>
          Encounter นี้ไม่สามารถดูข้อมูล Discharge Summary ได้
        </CardLayout>
      )}
    </>
  );


}

CardDischargeSummaryView.defaultProps = {
  closeable: true,
  toggleable: true,
  defaultBackground: true,
  hideHeaderIcon: false,
  dischargeSummaryData: {},
  dischargeSummaryLoading: false,
  onGetDischargeSummary: () => {},
  enableMargin: true,
  showBtnPrint: false
};

export default CardDischargeSummaryView;