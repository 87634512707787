import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  FormField,
  Dropdown,
  Checkbox,
  TextArea
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardSystemicEvaluationUX = (props: any) => {
    return(
      <div>
        <div
          style={{ display: "flex", justifyContent: "flex-end" }}>
          
          <div
            style={{display: "flex",height:"40px", fontWeight:"bold",alignItems:"center", ...(props.systemicEvaluation?.status !== "DRAFT" && { display: "none" })}}>
            ผู้บันทึกล่าสุด:
          </div>
          <div
            style={{ width: "10%", margin: "0px 10px", ...(props.systemicEvaluation?.status !== "DRAFT" && { display: "none" }) }}>
            {props.systemicEvaluation?.edit_user_name || ""}
          </div>
          <div
            style={{ width: "10%", margin: "0px 10px", ...(props.systemicEvaluation?.status !== "DRAFT" && { display: "none" }) }}>
            {props.systemicEvaluation?.edited || ""}
          </div>
          <div>
            {props.buttonSave}
          </div>
        </div>
        <Form>
          <FormGroup
            inline={true}
            style={{ display: "flex", alignItems: "center" }}>
            <FormField
              inline={true}
              width={2}>
              <div
                style={{ fontWeight: "bold" }}>
                Systemic Evaluation
              </div>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{ width: "80%" }}>
                
                <Dropdown
                  fluid={true}
                  name="systemicEvaluationType"
                  onChange={props.handleChangeData}
                  options={props.systemicEvaluationOptions}
                  selection={true}
                  value={props.systemicEvaluation?.systemicEvaluationType}>
                </Dropdown>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={2}>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div>
                
                <Checkbox
                  checked={props.systemicEvaluation?.respiratoryCheck}
                  disabled={props.systemicEvaluation?.systemicEvaluationType !== "Abnormal"}
                  label="Respiratory"
                  name="respiratoryCheck"
                  onChange={props.handleChangeData}>
                </Checkbox>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={7}>
              <div
                style={{ width: "100%" }}>
                
                <Dropdown
                  allowAdditions={true}
                  disabled={!props.systemicEvaluation?.respiratoryCheck || props.systemicEvaluation?.systemicEvaluationType !== "Abnormal"}
                  fluid={true}
                  multiple={true}
                  name="respiratoryDetail"
                  onAddItem={props.handleAddOptions}
                  onChange={props.handleChangeData}
                  options={props.respiratoryOptions}
                  search={true}
                  selection={true}
                  value={props.systemicEvaluation?.respiratoryDetail}>
                </Dropdown>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={2}>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div>
                
                <Checkbox
                  checked={props.systemicEvaluation?.cardiovascularCheck}
                  disabled={props.systemicEvaluation?.systemicEvaluationType !== "Abnormal"}
                  label="Cardiovascular"
                  name="cardiovascularCheck"
                  onChange={props.handleChangeData}>
                </Checkbox>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={7}>
              <div
                style={{ width: "100%" }}>
                
                <Dropdown
                  allowAdditions={true}
                  disabled={!props.systemicEvaluation?.cardiovascularCheck || props.systemicEvaluation?.systemicEvaluationType !== "Abnormal"}
                  fluid={true}
                  multiple={true}
                  name="cardiovascularDetail"
                  onAddItem={props.handleAddOptions}
                  onChange={props.handleChangeData}
                  options={props.cardiovascularOptions}
                  search={true}
                  selection={true}
                  value={props.systemicEvaluation?.cardiovascularDetail}>
                </Dropdown>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={2}>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div>
                
                <Checkbox
                  checked={props.systemicEvaluation?.neurologicCheck}
                  disabled={props.systemicEvaluation?.systemicEvaluationType !== "Abnormal"}
                  label="Neurologic"
                  name="neurologicCheck"
                  onChange={props.handleChangeData}>
                </Checkbox>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={7}>
              <div
                style={{ width: "100%" }}>
                
                <Dropdown
                  allowAdditions={true}
                  disabled={!props.systemicEvaluation?.neurologicCheck || props.systemicEvaluation?.systemicEvaluationType !== "Abnormal"}
                  fluid={true}
                  multiple={true}
                  name="neurologicDetail"
                  onAddItem={props.handleAddOptions}
                  onChange={props.handleChangeData}
                  options={props.neurologicOptions}
                  search={true}
                  selection={true}
                  value={props.systemicEvaluation?.neurologicDetail}>
                </Dropdown>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={2}>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div>
                
                <Checkbox
                  checked={props.systemicEvaluation?.eentCheck}
                  disabled={props.systemicEvaluation?.systemicEvaluationType !== "Abnormal"}
                  label="HEENT"
                  name="eentCheck"
                  onChange={props.handleChangeData}>
                </Checkbox>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={7}>
              <div
                style={{ width: "100%" }}>
                
                <Dropdown
                  allowAdditions={true}
                  disabled={!props.systemicEvaluation?.eentCheck || props.systemicEvaluation?.systemicEvaluationType !== "Abnormal"}
                  fluid={true}
                  multiple={true}
                  name="eentDetail"
                  onAddItem={props.handleAddOptions}
                  onChange={props.handleChangeData}
                  options={props.eentOptions}
                  search={true}
                  selection={true}
                  value={props.systemicEvaluation?.eentDetail}>
                </Dropdown>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={2}>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div>
                
                <Checkbox
                  checked={props.systemicEvaluation?.giCheck}
                  disabled={props.systemicEvaluation?.systemicEvaluationType !== "Abnormal"}
                  label="GI"
                  name="giCheck"
                  onChange={props.handleChangeData}>
                </Checkbox>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={7}>
              <div
                style={{ width: "100%" }}>
                
                <Dropdown
                  allowAdditions={true}
                  disabled={!props.systemicEvaluation?.giCheck || props.systemicEvaluation?.systemicEvaluationType !== "Abnormal"}
                  fluid={true}
                  multiple={true}
                  name="giDetail"
                  onAddItem={props.handleAddOptions}
                  onChange={props.handleChangeData}
                  options={props.giOptions}
                  search={true}
                  selection={true}
                  value={props.systemicEvaluation?.giDetail}>
                </Dropdown>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            widths={4}>
            <FormField
              inline={true}
              width={2}>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div>
                
                <Checkbox
                  checked={props.systemicEvaluation?.genitourinaryCheck}
                  disabled={props.systemicEvaluation?.systemicEvaluationType !== "Abnormal"}
                  label="Genitourinary"
                  name="genitourinaryCheck"
                  onChange={props.handleChangeData}>
                </Checkbox>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={7}>
              <div
                style={{ width: "100%" }}>
                
                <Dropdown
                  allowAdditions={true}
                  disabled={!props.systemicEvaluation?.genitourinaryCheck || props.systemicEvaluation?.systemicEvaluationType !== "Abnormal"}
                  fluid={true}
                  multiple={true}
                  name="genitourinaryDetail"
                  onAddItem={props.handleAddOptions}
                  onChange={props.handleChangeData}
                  options={props.genitourinaryOptions}
                  search={true}
                  selection={true}
                  value={props.systemicEvaluation?.genitourinaryDetail}>
                </Dropdown>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={2}>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div>
                
                <Checkbox
                  checked={props.systemicEvaluation?.endrocrineCheck}
                  disabled={props.systemicEvaluation?.systemicEvaluationType !== "Abnormal"}
                  label="Endocrine & metabolic"
                  name="endrocrineCheck"
                  onChange={props.handleChangeData}>
                </Checkbox>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={7}>
              <div
                style={{ width: "100%" }}>
                
                <Dropdown
                  allowAdditions={true}
                  disabled={!props.systemicEvaluation?.endrocrineCheck || props.systemicEvaluation?.systemicEvaluationType !== "Abnormal"}
                  fluid={true}
                  multiple={true}
                  name="endrocrineDetail"
                  onAddItem={props.handleAddOptions}
                  onChange={props.handleChangeData}
                  options={props.endrocrineOptions}
                  search={true}
                  selection={true}
                  value={props.systemicEvaluation?.endrocrineDetail}>
                </Dropdown>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={2}>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div>
                
                <Checkbox
                  checked={props.systemicEvaluation?.skinCheck}
                  disabled={props.systemicEvaluation?.systemicEvaluationType !== "Abnormal"}
                  label="Skin/ Breast"
                  name="skinCheck"
                  onChange={props.handleChangeData}>
                </Checkbox>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={7}>
              <div
                style={{ width: "100%" }}>
                
                <Dropdown
                  allowAdditions={true}
                  disabled={!props.systemicEvaluation?.skinCheck || props.systemicEvaluation?.systemicEvaluationType !== "Abnormal"}
                  fluid={true}
                  multiple={true}
                  name="skinDetail"
                  onAddItem={props.handleAddOptions}
                  onChange={props.handleChangeData}
                  options={props.skinOptions}
                  search={true}
                  selection={true}
                  value={props.systemicEvaluation?.skinDetail}>
                </Dropdown>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={2}>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div>
                
                <Checkbox
                  checked={props.systemicEvaluation?.immunologyCheck}
                  disabled={props.systemicEvaluation?.systemicEvaluationType !== "Abnormal"}
                  label="Immunology/ Hematology"
                  name="immunologyCheck"
                  onChange={props.handleChangeData}>
                </Checkbox>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={7}>
              <div
                style={{ width: "100%" }}>
                
                <Dropdown
                  allowAdditions={true}
                  disabled={!props.systemicEvaluation?.immunologyCheck || props.systemicEvaluation?.systemicEvaluationType !== "Abnormal"}
                  fluid={true}
                  multiple={true}
                  name="immunologyDetail"
                  onAddItem={props.handleAddOptions}
                  onChange={props.handleChangeData}
                  options={props.immunologyOptions}
                  search={true}
                  selection={true}
                  value={props.systemicEvaluation?.immunologyDetail}>
                </Dropdown>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{ alignItems: "flex-start", margin: "30px 0px" }}>
            <FormField
              inline={true}
              width={2}>
              <div
                style={{ display: "flex", alignItems: "center", width: "100%" }}>
                
                <div
                  style={{ fontWeight: "bold" }}>
                  Lab Data:
                </div>
                <div
                  style={{ margin: "0px 10px" }}>
                  {props.logoLab}
                </div>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{ width: "80%" }}>
                
                <Dropdown
                  fluid={true}
                  name="labDataType"
                  onChange={props.handleChangeData}
                  options={props.systemicEvaluationOptions}
                  selection={true}
                  value={props.systemicEvaluation?.labDataType}>
                </Dropdown>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={7}>
              <div
                style={{ width: "100%"  }}>
                
                <TextArea
                  name="labDataDetail"
                  onChange={props.handleChangeData}
                  rows={2}
                  style={{ resize: "none" }}
                  value={props.systemicEvaluation?.labDataDetail}>
                </TextArea>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <div
                style={{ display: "flex", alignItems: "center", width: "100%" }}>
                
                <div
                  style={{ fontWeight: "bold" , width: "30%"}}>
                  Result date:
                </div>
                <div
                  style={{ width: "60%" }}>
                  
                  <DateTextBox
                    onChange={props.handleChangeLabDate}
                    value={props.systemicEvaluation?.labDataDate}>
                  </DateTextBox>
                </div>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{ alignItems: "flex-start" }}>
            <FormField
              inline={true}
              width={2}>
              <div
                style={{ fontWeight: "bold" }}>
                Other Investigations:
              </div>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div>
                
                <Checkbox
                  checked={props.systemicEvaluation?.xRayCheck}
                  label="Chest X-ray"
                  name="xRayCheck"
                  onChange={props.handleChangeData}>
                </Checkbox>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={7}>
              <div
                style={{ width: "100%" }}>
                
                <TextArea
                  disabled={!props.systemicEvaluation?.xRayCheck}
                  name="xRayDetail"
                  onChange={props.handleChangeData}
                  rows={2}
                  style={{ resize: "none" }}
                  value={props.systemicEvaluation?.xRayDetail}>
                </TextArea>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <div
                style={{ display: "flex", alignItems: "center", width: "100%" }}>
                
                <div
                  style={{ fontWeight: "bold" , width: "30%"}}>
                  Result date:
                </div>
                <div
                  style={{ width: "60%" }}>
                  
                  <DateTextBox
                    disabled={!props.systemicEvaluation?.xRayCheck}
                    onChange={props.handleChangeXRayDate}
                    value={props.systemicEvaluation?.xRayDate}>
                  </DateTextBox>
                </div>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{ alignItems: "flex-start" }}>
            <FormField
              inline={true}
              width={2}>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div>
                
                <Checkbox
                  checked={props.systemicEvaluation?.imagingCheck}
                  label="Imaging"
                  name="imagingCheck"
                  onChange={props.handleChangeData}>
                </Checkbox>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={7}>
              <div
                style={{ width: "100%" }}>
                
                <TextArea
                  disabled={!props.systemicEvaluation?.imagingCheck}
                  name="imagingDetail"
                  onChange={props.handleChangeData}
                  rows={2}
                  style={{ resize: "none" }}
                  value={props.systemicEvaluation?.imagingDetail}>
                </TextArea>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <div
                style={{ display: "flex", alignItems: "center", width: "100%" }}>
                
                <div
                  style={{ fontWeight: "bold" , width: "30%"}}>
                  Result date:
                </div>
                <div
                  style={{ width: "60%" }}>
                  
                  <DateTextBox
                    disabled={!props.systemicEvaluation?.imagingCheck}
                    onChange={props.handleChangeImagingDate}
                    value={props.systemicEvaluation?.imagingDate}>
                  </DateTextBox>
                </div>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{ alignItems: "flex-start" }}>
            <FormField
              inline={true}
              width={2}>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div>
                
                <Checkbox
                  checked={props.systemicEvaluation?.ekgCheck}
                  label="EKG"
                  name="ekgCheck"
                  onChange={props.handleChangeData}>
                </Checkbox>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={7}>
              <div
                style={{ width: "100%" }}>
                
                <TextArea
                  disabled={!props.systemicEvaluation?.ekgCheck}
                  name="ekgDetail"
                  onChange={props.handleChangeData}
                  rows={2}
                  style={{ resize: "none" }}
                  value={props.systemicEvaluation?.ekgDetail}>
                </TextArea>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <div
                style={{ display: "flex", alignItems: "center", width: "100%" }}>
                
                <div
                  style={{ fontWeight: "bold" , width: "30%"}}>
                  Result date:
                </div>
                <div
                  style={{ width: "60%" }}>
                  
                  <DateTextBox
                    disabled={!props.systemicEvaluation?.ekgCheck}
                    onChange={props.handleChangeEKGDate}
                    value={props.systemicEvaluation?.ekgDate}>
                  </DateTextBox>
                </div>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{ alignItems: "flex-start" }}>
            <FormField
              inline={true}
              width={2}>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div>
                
                <Checkbox
                  checked={props.systemicEvaluation?.otherCheck}
                  label="Other"
                  name="otherCheck"
                  onChange={props.handleChangeData}>
                </Checkbox>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={7}>
              <div
                style={{ width: "100%" }}>
                
                <TextArea
                  disabled={!props.systemicEvaluation?.otherCheck}
                  name="otherDetail"
                  onChange={props.handleChangeData}
                  rows={2}
                  style={{ resize: "none" }}
                  value={props.systemicEvaluation?.otherDetail}>
                </TextArea>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <div
                style={{ display: "flex", alignItems: "center", width: "100%" }}>
                
                <div
                  style={{ fontWeight: "bold" , width: "30%"}}>
                  Result date:
                </div>
                <div
                  style={{ width: "60%" }}>
                  
                  <DateTextBox
                    disabled={!props.systemicEvaluation?.otherCheck}
                    onChange={props.handleChangeOtherDate}
                    value={props.systemicEvaluation?.otherDate}>
                  </DateTextBox>
                </div>
              </div>
            </FormField>
          </FormGroup>
        </Form>
      </div>
    )
}


export default CardSystemicEvaluationUX

export const screenPropsDefault = {}

/* Date Time : Thu Nov 21 2024 15:56:17 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 0
    },
    {
      "from": "semantic-ui-react",
      "id": 1,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 156,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ alignItems: \"flex-start\", margin: \"30px 0px\" }"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ alignItems: \"flex-start\" }"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "FormField",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "FormField",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "FormField",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "FormField",
      "parent": 8,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "FormField",
      "parent": 8,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "FormField",
      "parent": 8,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "FormField",
      "parent": 9,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "FormField",
      "parent": 9,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "FormField",
      "parent": 10,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "FormField",
      "parent": 10,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "FormField",
      "parent": 10,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "FormField",
      "parent": 11,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "FormField",
      "parent": 11,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "FormField",
      "parent": 11,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "FormField",
      "parent": 12,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "FormField",
      "parent": 12,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "FormField",
      "parent": 12,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"80%\" }"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "Systemic Evaluation"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "Dropdown",
      "parent": 47,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "systemicEvaluationType"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.systemicEvaluationOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.systemicEvaluation?.systemicEvaluationType"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "div",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "div",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "div",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "div",
      "parent": 28,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 29,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 31,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "div",
      "parent": 32,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "div",
      "parent": 35,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "div",
      "parent": 36,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "div",
      "parent": 38,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "div",
      "parent": 39,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": null,
      "id": 64,
      "name": "div",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "div",
      "parent": 42,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": null,
      "id": 66,
      "name": "div",
      "parent": 44,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "div",
      "parent": 45,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "Checkbox",
      "parent": 50,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.systemicEvaluation?.respiratoryCheck"
        },
        "disabled": {
          "type": "code",
          "value": "props.systemicEvaluation?.systemicEvaluationType !== \"Abnormal\""
        },
        "label": {
          "type": "value",
          "value": "Respiratory"
        },
        "name": {
          "type": "value",
          "value": "respiratoryCheck"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "onClick": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "Checkbox",
      "parent": 52,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.systemicEvaluation?.cardiovascularCheck"
        },
        "disabled": {
          "type": "code",
          "value": "props.systemicEvaluation?.systemicEvaluationType !== \"Abnormal\""
        },
        "label": {
          "type": "value",
          "value": "Cardiovascular"
        },
        "name": {
          "type": "value",
          "value": "cardiovascularCheck"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "Checkbox",
      "parent": 54,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.systemicEvaluation?.neurologicCheck"
        },
        "disabled": {
          "type": "code",
          "value": "props.systemicEvaluation?.systemicEvaluationType !== \"Abnormal\""
        },
        "label": {
          "type": "value",
          "value": "Neurologic"
        },
        "name": {
          "type": "value",
          "value": "neurologicCheck"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "Checkbox",
      "parent": 56,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.systemicEvaluation?.eentCheck"
        },
        "disabled": {
          "type": "code",
          "value": "props.systemicEvaluation?.systemicEvaluationType !== \"Abnormal\""
        },
        "label": {
          "type": "value",
          "value": "HEENT"
        },
        "name": {
          "type": "value",
          "value": "eentCheck"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "Checkbox",
      "parent": 58,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.systemicEvaluation?.giCheck"
        },
        "disabled": {
          "type": "code",
          "value": "props.systemicEvaluation?.systemicEvaluationType !== \"Abnormal\""
        },
        "label": {
          "type": "value",
          "value": "GI"
        },
        "name": {
          "type": "value",
          "value": "giCheck"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "Checkbox",
      "parent": 60,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.systemicEvaluation?.genitourinaryCheck"
        },
        "disabled": {
          "type": "code",
          "value": "props.systemicEvaluation?.systemicEvaluationType !== \"Abnormal\""
        },
        "label": {
          "type": "value",
          "value": "Genitourinary"
        },
        "name": {
          "type": "value",
          "value": "genitourinaryCheck"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "Checkbox",
      "parent": 62,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.systemicEvaluation?.endrocrineCheck"
        },
        "disabled": {
          "type": "code",
          "value": "props.systemicEvaluation?.systemicEvaluationType !== \"Abnormal\""
        },
        "label": {
          "type": "value",
          "value": "Endocrine & metabolic"
        },
        "name": {
          "type": "value",
          "value": "endrocrineCheck"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "Checkbox",
      "parent": 64,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.systemicEvaluation?.skinCheck"
        },
        "disabled": {
          "type": "code",
          "value": "props.systemicEvaluation?.systemicEvaluationType !== \"Abnormal\""
        },
        "label": {
          "type": "value",
          "value": "Skin/ Breast"
        },
        "name": {
          "type": "value",
          "value": "skinCheck"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "Checkbox",
      "parent": 66,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.systemicEvaluation?.immunologyCheck"
        },
        "disabled": {
          "type": "code",
          "value": "props.systemicEvaluation?.systemicEvaluationType !== \"Abnormal\""
        },
        "label": {
          "type": "value",
          "value": "Immunology/ Hematology"
        },
        "name": {
          "type": "value",
          "value": "immunologyCheck"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 84,
      "name": "Dropdown",
      "parent": 63,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "!props.systemicEvaluation?.endrocrineCheck || props.systemicEvaluation?.systemicEvaluationType !== \"Abnormal\""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "endrocrineDetail"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.handleAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.endrocrineOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.systemicEvaluation?.endrocrineDetail"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 85,
      "name": "Dropdown",
      "parent": 51,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "!props.systemicEvaluation?.respiratoryCheck || props.systemicEvaluation?.systemicEvaluationType !== \"Abnormal\""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "respiratoryDetail"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.handleAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.respiratoryOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.systemicEvaluation?.respiratoryDetail"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 86,
      "name": "FormField",
      "parent": 9,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 87,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ alignItems: \"flex-start\" }"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 88,
      "name": "FormField",
      "parent": 13,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 89,
      "name": "FormField",
      "parent": 14,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 90,
      "name": "FormField",
      "parent": 13,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 91,
      "name": "FormField",
      "parent": 14,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "div",
      "parent": 88,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", width: \"100%\" }"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 93,
      "name": "div",
      "parent": 90,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"80%\" }"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "div",
      "parent": 89,
      "props": {
        "children": {
          "type": "value",
          "value": "Other Investigations:"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "div",
      "parent": 91,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": null,
      "id": 96,
      "name": "div",
      "parent": 92,
      "props": {
        "children": {
          "type": "value",
          "value": "Lab Data:"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "div",
      "parent": 92,
      "props": {
        "children": {
          "type": "code",
          "value": "props.logoLab"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 10px\" }"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 98,
      "name": "FormField",
      "parent": 98,
      "props": {
      },
      "seq": 98,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 99,
      "name": "FormField",
      "parent": 13,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 101,
      "name": "FormField",
      "parent": 13,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 102,
      "name": "Dropdown",
      "parent": 93,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "labDataType"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.systemicEvaluationOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.systemicEvaluation?.labDataType"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": null,
      "id": 103,
      "name": "div",
      "parent": 99,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\"  }"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": null,
      "id": 105,
      "name": "div",
      "parent": 101,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", width: \"100%\" }"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": null,
      "id": 106,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": "Result date:"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" , width: \"30%\"}"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": null,
      "id": 107,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"60%\" }"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 108,
      "name": "DateTextBox",
      "parent": 107,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeLabDate"
        },
        "value": {
          "type": "code",
          "value": "props.systemicEvaluation?.labDataDate"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 109,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ alignItems: \"flex-start\" }"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 110,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ alignItems: \"flex-start\" }"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 111,
      "name": "FormField",
      "parent": 14,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 112,
      "name": "FormField",
      "parent": 87,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 113,
      "name": "FormField",
      "parent": 87,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 114,
      "name": "FormField",
      "parent": 87,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 115,
      "name": "FormField",
      "parent": 109,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 116,
      "name": "FormField",
      "parent": 109,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 117,
      "name": "FormField",
      "parent": 109,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 118,
      "name": "FormField",
      "parent": 110,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 119,
      "name": "FormField",
      "parent": 110,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 120,
      "name": "FormField",
      "parent": 110,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": null,
      "id": 122,
      "name": "div",
      "parent": 113,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": null,
      "id": 123,
      "name": "div",
      "parent": 114,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": null,
      "id": 124,
      "name": "div",
      "parent": 117,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": null,
      "id": 125,
      "name": "div",
      "parent": 116,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": null,
      "id": 126,
      "name": "div",
      "parent": 120,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": null,
      "id": 127,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 128,
      "name": "Checkbox",
      "parent": 95,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.systemicEvaluation?.xRayCheck"
        },
        "label": {
          "type": "value",
          "value": "Chest X-ray"
        },
        "name": {
          "type": "value",
          "value": "xRayCheck"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 129,
      "name": "Checkbox",
      "parent": 122,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.systemicEvaluation?.imagingCheck"
        },
        "label": {
          "type": "value",
          "value": "Imaging"
        },
        "name": {
          "type": "value",
          "value": "imagingCheck"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 130,
      "name": "Checkbox",
      "parent": 125,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.systemicEvaluation?.ekgCheck"
        },
        "label": {
          "type": "value",
          "value": "EKG"
        },
        "name": {
          "type": "value",
          "value": "ekgCheck"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 131,
      "name": "Checkbox",
      "parent": 127,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.systemicEvaluation?.otherCheck"
        },
        "label": {
          "type": "value",
          "value": "Other"
        },
        "name": {
          "type": "value",
          "value": "otherCheck"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": null,
      "id": 132,
      "name": "div",
      "parent": 111,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 137,
      "name": "FormField",
      "parent": 109,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": null,
      "id": 138,
      "name": "div",
      "parent": 137,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", width: \"100%\" }"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": null,
      "id": 139,
      "name": "div",
      "parent": 138,
      "props": {
        "children": {
          "type": "value",
          "value": "Result date:"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" , width: \"30%\"}"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": null,
      "id": 140,
      "name": "div",
      "parent": 138,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"60%\" }"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 141,
      "name": "DateTextBox",
      "parent": 140,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.systemicEvaluation?.ekgCheck"
        },
        "id": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeEKGDate"
        },
        "value": {
          "type": "code",
          "value": "props.systemicEvaluation?.ekgDate"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 142,
      "name": "TextArea",
      "parent": 126,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.systemicEvaluation?.otherCheck"
        },
        "name": {
          "type": "value",
          "value": "otherDetail"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "rows": {
          "type": "code",
          "value": "2"
        },
        "style": {
          "type": "code",
          "value": "{ resize: \"none\" }"
        },
        "value": {
          "type": "code",
          "value": "props.systemicEvaluation?.otherDetail"
        }
      },
      "seq": 142,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 143,
      "name": "TextArea",
      "parent": 124,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.systemicEvaluation?.ekgCheck"
        },
        "name": {
          "type": "value",
          "value": "ekgDetail"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "rows": {
          "type": "code",
          "value": "2"
        },
        "style": {
          "type": "code",
          "value": "{ resize: \"none\" }"
        },
        "value": {
          "type": "code",
          "value": "props.systemicEvaluation?.ekgDetail"
        }
      },
      "seq": 143,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 144,
      "name": "TextArea",
      "parent": 123,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.systemicEvaluation?.imagingCheck"
        },
        "name": {
          "type": "value",
          "value": "imagingDetail"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "rows": {
          "type": "code",
          "value": "2"
        },
        "style": {
          "type": "code",
          "value": "{ resize: \"none\" }"
        },
        "value": {
          "type": "code",
          "value": "props.systemicEvaluation?.imagingDetail"
        }
      },
      "seq": 144,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 145,
      "name": "TextArea",
      "parent": 132,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.systemicEvaluation?.xRayCheck"
        },
        "name": {
          "type": "value",
          "value": "xRayDetail"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "rows": {
          "type": "code",
          "value": "2"
        },
        "style": {
          "type": "code",
          "value": "{ resize: \"none\" }"
        },
        "value": {
          "type": "code",
          "value": "props.systemicEvaluation?.xRayDetail"
        }
      },
      "seq": 145,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 146,
      "name": "TextArea",
      "parent": 103,
      "props": {
        "name": {
          "type": "value",
          "value": "labDataDetail"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "rows": {
          "type": "code",
          "value": "2"
        },
        "style": {
          "type": "code",
          "value": "{ resize: \"none\" }"
        },
        "value": {
          "type": "code",
          "value": "props.systemicEvaluation?.labDataDetail"
        }
      },
      "seq": 146,
      "void": true
    },
    {
      "from": null,
      "id": 156,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"flex-end\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 157,
      "name": "div",
      "parent": 156,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้บันทึกล่าสุด:"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontWeight:\"bold\",alignItems:\"center\", ...(props.systemicEvaluation?.status !== \"DRAFT\" && { display: \"none\" })}"
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": null,
      "id": 158,
      "name": "div",
      "parent": 156,
      "props": {
        "children": {
          "type": "code",
          "value": "props.systemicEvaluation?.edit_user_name || \"\""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10%\", margin: \"0px 10px\", ...(props.systemicEvaluation?.status !== \"DRAFT\" && { display: \"none\" }) }"
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": null,
      "id": 159,
      "name": "div",
      "parent": 156,
      "props": {
        "children": {
          "type": "code",
          "value": "props.systemicEvaluation?.edited || \"\""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10%\", margin: \"0px 10px\", ...(props.systemicEvaluation?.status !== \"DRAFT\" && { display: \"none\" }) }"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": null,
      "id": 160,
      "name": "div",
      "parent": 156,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 162,
      "name": "Dropdown",
      "parent": 53,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "!props.systemicEvaluation?.cardiovascularCheck || props.systemicEvaluation?.systemicEvaluationType !== \"Abnormal\""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "cardiovascularDetail"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.handleAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.cardiovascularOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.systemicEvaluation?.cardiovascularDetail"
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 163,
      "name": "Dropdown",
      "parent": 55,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "!props.systemicEvaluation?.neurologicCheck || props.systemicEvaluation?.systemicEvaluationType !== \"Abnormal\""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "neurologicDetail"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.handleAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.neurologicOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.systemicEvaluation?.neurologicDetail"
        }
      },
      "seq": 163,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 164,
      "name": "Dropdown",
      "parent": 57,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "!props.systemicEvaluation?.eentCheck || props.systemicEvaluation?.systemicEvaluationType !== \"Abnormal\""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "eentDetail"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.handleAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.eentOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.systemicEvaluation?.eentDetail"
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 165,
      "name": "Dropdown",
      "parent": 59,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "!props.systemicEvaluation?.giCheck || props.systemicEvaluation?.systemicEvaluationType !== \"Abnormal\""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "giDetail"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.handleAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.giOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.systemicEvaluation?.giDetail"
        }
      },
      "seq": 165,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 166,
      "name": "Dropdown",
      "parent": 61,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "!props.systemicEvaluation?.genitourinaryCheck || props.systemicEvaluation?.systemicEvaluationType !== \"Abnormal\""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "genitourinaryDetail"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.handleAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.genitourinaryOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.systemicEvaluation?.genitourinaryDetail"
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 167,
      "name": "Dropdown",
      "parent": 65,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "!props.systemicEvaluation?.skinCheck || props.systemicEvaluation?.systemicEvaluationType !== \"Abnormal\""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "skinDetail"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.handleAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.skinOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.systemicEvaluation?.skinDetail"
        }
      },
      "seq": 167,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 168,
      "name": "Dropdown",
      "parent": 67,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "!props.systemicEvaluation?.immunologyCheck || props.systemicEvaluation?.systemicEvaluationType !== \"Abnormal\""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "immunologyDetail"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.handleAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.immunologyOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.systemicEvaluation?.immunologyDetail"
        }
      },
      "seq": 168,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 169,
      "name": "FormField",
      "parent": 110,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 169,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 170,
      "name": "FormField",
      "parent": 87,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 170,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 171,
      "name": "FormField",
      "parent": 14,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 171,
      "void": false
    },
    {
      "from": null,
      "id": 172,
      "name": "div",
      "parent": 170,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", width: \"100%\" }"
        }
      },
      "seq": 172,
      "void": false
    },
    {
      "from": null,
      "id": 173,
      "name": "div",
      "parent": 169,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", width: \"100%\" }"
        }
      },
      "seq": 173,
      "void": false
    },
    {
      "from": null,
      "id": 174,
      "name": "div",
      "parent": 171,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", width: \"100%\" }"
        }
      },
      "seq": 174,
      "void": false
    },
    {
      "from": null,
      "id": 175,
      "name": "div",
      "parent": 174,
      "props": {
        "children": {
          "type": "value",
          "value": "Result date:"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" , width: \"30%\"}"
        }
      },
      "seq": 175,
      "void": false
    },
    {
      "from": null,
      "id": 176,
      "name": "div",
      "parent": 174,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"60%\" }"
        }
      },
      "seq": 176,
      "void": false
    },
    {
      "from": null,
      "id": 177,
      "name": "div",
      "parent": 172,
      "props": {
        "children": {
          "type": "value",
          "value": "Result date:"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" , width: \"30%\"}"
        }
      },
      "seq": 177,
      "void": false
    },
    {
      "from": null,
      "id": 178,
      "name": "div",
      "parent": 172,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"60%\" }"
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": null,
      "id": 179,
      "name": "div",
      "parent": 173,
      "props": {
        "children": {
          "type": "value",
          "value": "Result date:"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" , width: \"30%\"}"
        }
      },
      "seq": 179,
      "void": false
    },
    {
      "from": null,
      "id": 180,
      "name": "div",
      "parent": 173,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"60%\" }"
        }
      },
      "seq": 180,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 181,
      "name": "DateTextBox",
      "parent": 180,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.systemicEvaluation?.otherCheck"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeOtherDate"
        },
        "value": {
          "type": "code",
          "value": "props.systemicEvaluation?.otherDate"
        }
      },
      "seq": 181,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 182,
      "name": "DateTextBox",
      "parent": 178,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.systemicEvaluation?.imagingCheck"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeImagingDate"
        },
        "value": {
          "type": "code",
          "value": "props.systemicEvaluation?.imagingDate"
        }
      },
      "seq": 182,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 183,
      "name": "DateTextBox",
      "parent": 176,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.systemicEvaluation?.xRayCheck"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeXRayDate"
        },
        "value": {
          "type": "code",
          "value": "props.systemicEvaluation?.xRayDate"
        }
      },
      "seq": 183,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardSystemicEvaluationUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
