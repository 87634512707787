import { getLogoReportNResize } from "../common/CommonInterface";
import CompanyLogoForm from "react-lib/apps/HISV3/common/CompanyLogoForm";
import { HeaderLogoWithTitleContent } from "../common/HeaderPdfFormTemplate";

// Config
import CONFIG from "config/config";

type FormLabExamSummaryProps = {
  date: string;
  doctorDetail?: Record<string, any>;
  doctorRecommends?: Record<number, string>;
  parentLabID: string[];
  patient: Record<string, any>;
  time: string;
  labResultList: LabResultListType;
  hideRecommendation?: boolean;
};

type LabResultListType = Record<
  string,
  {
    id: number;
    items: {
      name: string;
      ref_value?: string;
      unit: string;
      recommendation_text?: string;
      dates?: { value: string; status: string }[];
    }[];
  }
>;

const blankSpaces5 = Array(5).fill("\u00a0").join("");

const FORM_NAME = `FormLabExamSummary`;
const LOGO_HEIGHT = 24;

const FormLabExamSummary = async (props: FormLabExamSummaryProps) => {
  const headerForm = await HeaderLogoWithTitleContent({
    form: FORM_NAME,
    font: "THSarabunNew",
    logoHeight: LOGO_HEIGHT,
    pageMargins: [20, 90, 20, 40],
    headerMargins: [20, 20, 20, 0],
    styles: {
      hiddenHeader: {
        fontSize: 0, // Set font size to 0 to hide the text
      },
      fieldHeader: {
        fontSize: 14,
        bold: true,
        color: "white",
      },
    },
    titleContent: [
      {
        margin: CONFIG.COMPANY === "SAPIENS" ? [70, 2, 0, 0] : [0, 2, 0, 0],
        text: [
          { text: "Laboratory Examination Summary\n" },
          {
            text: "รายงานสรุปผลตรวจทางห้องปฏิบัติการ",
          },
        ],
        alignment: "center",
        style: {
          fontSize: 20,
          bold: true,
        },
      },
    ],
  });

  const { images, styles } = headerForm;

  const logo = await getLogoReportNResize(LOGO_HEIGHT);

  const setColumns = (ids: string[], data: LabResultListType) => {
    let tableData: any[] = [];
    const borderColorGrey = ["#646464", "#646464", "#646464", "#646464"];

    ids.map((key, index: number) => {
      let tableLabData: any[] = [];
      data[key].items.map((item, idx: number, self: any[]) => {
        const lastIndex = props.hideRecommendation ? self.length - 1 === idx : true;
        const marginBottom = props.hideRecommendation && lastIndex ? 5 : 0;
        const indent = props.hideRecommendation && self.length > 1 ? 5 : 0;
        const alignment = props.hideRecommendation ? "center" : "left";

        if (item?.dates) {
          tableLabData.push([
            {
              borderColor: borderColorGrey,
              border: [true, false, false, lastIndex],
              text: item?.name || " ",
              marginBottom,
              marginLeft: indent,
            },
            {
              borderColor: borderColorGrey,
              border: [false, false, false, lastIndex],
              text: [
                {
                  text: `${item?.dates[0]?.value || " "}`,
                  preserveLeadingSpaces: true,
                },
                props.hideRecommendation
                  ? {
                      text: `${blankSpaces5}${item?.dates[0]?.status || " "}`,
                      bold: true,
                      preserveLeadingSpaces: true,
                    }
                  : { text: "" },
              ],
              marginBottom,
              marginLeft: props.hideRecommendation ? 52.5 : 0,
            },
            {
              borderColor: borderColorGrey,
              border: [false, false, false, lastIndex],
              text: [
                {
                  text: `${item?.ref_value || " "}\n`,
                },
                !props.hideRecommendation ? { text: item?.unit || " " } : { text: "" },
              ],
              marginBottom,
              alignment,
            },
            // {
            //   borderColor: borderColorGrey,
            //   border: [false, false, false, true],
            //   text: " ",
            // },
            {
              borderColor: borderColorGrey,
              border: [false, false, true, lastIndex],
              text: (props.hideRecommendation ? item?.unit : item.recommendation_text) || "",
              marginBottom,
              alignment,
            },
          ]);
        } else {
          tableLabData.push([
            {
              borderColor: borderColorGrey,
              border: [true, false, false, false],
              text: item?.name,
              style: {
                fontSize: 12,
                bold: true,
              },
            },
            { border: [false, false, false, false], text: "" },
            { border: [false, false, false, false], text: "" },
            // { border: [false, false, false, false], text: "" },
            {
              borderColor: borderColorGrey,
              border: [false, false, true, false],
              text: "",
            },
          ]);
        }
      });

      const showHeader = props.hideRecommendation ? index === 0 : true;
      const hideBorder = props.hideRecommendation && index !== 0;
      const fillColor = showHeader ? "#646464" : "white";
      const style = showHeader ? "fieldHeader" : "hiddenHeader";
      const widths = props.hideRecommendation
        ? ["32.5%", "22.5%", "22.5%", "22.5%"]
        : ["25%", "17.5%", "22.5%", "35%"];
      const alignment = props.hideRecommendation ? "center" : "left";

      tableData.push([
        {
          // marginTop: index === 0 ? 0 : -5,
          table: {
            headerRows: 1,
            widths,
            body: [
              [
                {
                  borderColor: borderColorGrey,
                  border: hideBorder ? [true, false, false, false] : [true, true, false, true],
                  text: [{ text: "ชื่อแลป\n" }, { text: "Lab Name" }],
                  fillColor: fillColor,
                  style,
                },
                {
                  borderColor: borderColorGrey,
                  border: hideBorder ? [false, false, false, false] : [false, true, false, true],
                  text: [{ text: "ผลแลป\n" }, { text: "Result" }],
                  fillColor: fillColor,
                  style,
                  alignment,
                },
                {
                  borderColor: borderColorGrey,
                  border: hideBorder ? [false, false, false, false] : [false, true, false, true],
                  text: [{ text: "ช่วงค่าปกติ\n" }, { text: "Ref. value" }],
                  fillColor: fillColor,
                  style,
                  alignment,
                },
                // {
                //   borderColor: borderColorGreen,
                //   border: [false, true, false, true],
                //   text: [{ text: "การแปลผล\n" }, { text: "Interpretation" }],
                //   style: {
                //     fontSize: 14,
                //     bold: true,
                //   },
                // },
                {
                  borderColor: borderColorGrey,
                  border: hideBorder ? [false, false, true, false] : [false, true, true, true],
                  text: props.hideRecommendation
                    ? [{ text: "หน่วย\n" }, { text: "Unit" }]
                    : [{ text: "คำแนะนำ\n" }, { text: "Recommendation" }],
                  fillColor: fillColor,
                  style,
                  alignment,
                },
              ],
              ...tableLabData,
            ],
          },
        },
        !props.hideRecommendation
          ? {
              margin: [0, 0, 0, -5],
              table: {
                headerRows: 1,
                widths: ["100%"],
                body: [
                  // box outer
                  [
                    {
                      borderColor: borderColorGrey,
                      border: [true, false, true, ids.length - 1 === index],
                      margin: [0, 0, 0, 0],
                      table: {
                        headerRows: 1,
                        widths: ["100%"],
                        body: [
                          // box inner
                          [
                            {
                              margin: [5, 5, 5, 5],
                              stack: [
                                {
                                  margin: [0, 0, 0, 0],
                                  columns: [
                                    {
                                      width: 75,
                                      text: "คำแนะนำจากแพทย์",
                                      style: {
                                        fontSize: 12,
                                        bold: true,
                                      },
                                    },
                                    /* A table that is not used. */
                                    {
                                      image: "queue",
                                      width: 10,
                                    },
                                  ],
                                },
                                {
                                  text: props.doctorRecommends?.[data[key].id] || "-",
                                },
                              ],
                            },
                          ],
                          [{ border: [false, false, false, false], text: "" }],
                        ],
                      },
                      layout: {
                        hLineStyle: function (i: any, node: any) {
                          return { dash: { length: 2, space: 2 } };
                        },
                        vLineStyle: function (i: any, node: any) {
                          return { dash: { length: 2, space: 2 } };
                        },
                        hLineColor: function (i: any) {
                          return "white";
                        },
                        vLineColor: function (i: any) {
                          return "white";
                        },
                      },
                    },
                  ],
                  [{ border: [false, false, false, false], text: "" }],
                ],
              },
            }
          : { text: "" },
      ]);
    });
    return tableData;
  };

  let columns = setColumns(props.parentLabID, props.labResultList);

  console.log("FormLabExamSummary", columns, props);

  return {
    defaultStyle: {
      font: "THSarabunNew",
      // alignment: 'justify'
      lineHeight: 1,
      fontSize: 13,
    },
    // A4: [595.28, 841.89]
    pageSize: "A4",
    ...styles,
    ...headerForm,
    // header: {
    //   margin: [20, 20, 20, 0],

    //   stack: [
    //     {
    //       layout: "noBorders",
    //       // fillColor: "#1b9d2c",
    //       fillOpacity: 0.4,
    //       table: {
    //         widths: ["10%", "90%"],
    //         heights: [55, 55],
    //         body: [
    //           [
    //             {
    //               margin: [5, 2, 0, 0],
    //               layout: "noBorders",
    //               // fillColor: "#1b9d2c",
    //               fillOpacity: 0.4,
    //               stack: [
    //                 !CONFIG.HIDE_COMPANY_LOGO_PRINT ? {
    //                   image: "logo",
    //                   // fillColor: "#1b9d2c",
    //                   fillOpacity: 0.4,
    //                   width: CONFIG.COMPANY === "SAPIENS" ? 160 : logo.width,
    //                   height: LOGO_HEIGHT,
    //                 }: null,
    //               ],
    //             },
    //             {
    //               margin: [0, 2, 0, 0],
    //               text: [
    //                 { text: "Laboratory Examination Summary\n" },
    //                 {
    //                   text: "รายงานสรุปผลตรวจทางห้องปฏิบัติการ",
    //                 },
    //               ],
    //               alignment: "center",
    //               style: {
    //                 fontSize: 20,
    //                 bold: true,
    //               },
    //             },
    //           ],
    //         ],
    //       },
    //     },
    //   ],
    // },
    content: [
      {
        stack: [...columns],
      },
    ],
    footer: function (currentPage: number, pageCount: number) {
      return {
        margin: [20, 0, 20, 0],
        stack: [
          {
            text: props.doctorDetail
              ? `Approved by: ${props.doctorDetail.full_name || ""} (ว.${
                  props.doctorDetail?.certificate_no || ""
                })`
              : "",
            style: {
              bold: true,
              alignment: "right",
            },
          },
          {
            table: {
              widths: ["50%", "40%", "10%"],
              body: [
                [
                  {
                    borderColor: ["#000000", "#000000", "#000000", "#000000"],
                    border: [false, true, false, false],
                    text: `HN: ${props.patient?.hn} ${props.patient?.full_name_th} DOB: ${props.patient?.birthdate}`,
                    italics: true,
                    style: {
                      fontSize: 12,
                      color: "#5A5A5A",
                    },
                  },
                  {
                    border: [false, true, false, false],
                    text: `Printed date: ${props.date} [${props.time}]`,
                    italics: true,
                    style: {
                      fontSize: 12,
                      color: "#5A5A5A",
                    },
                  },
                  {
                    border: [false, true, false, false],
                    text: `${currentPage.toString()} / ${pageCount}`,
                    italics: true,
                    style: {
                      fontSize: 12,
                      color: "#5A5A5A",
                      alignment: "right",
                    },
                  },
                ],
              ],
            },
          },
        ],
      };
    },
    images: {
      ...images,
      queue: origin + "/static/images/menus/queue.png",
    },
  };
};

export default FormLabExamSummary;
