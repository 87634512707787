import { TDocumentDefinitions } from "pdfmake/interfaces";

import { splitStringNewLine } from "../../common/CommonInterface";

import HeaderSummaryReportForm from "./HeaderSummaryReportForm";

export default async function toPDFMakeData(props: any): Promise<TDocumentDefinitions> {
  console.log("saika Props form CoverageReport: ", props);

  const headerForm = await HeaderSummaryReportForm({
    data: props.reportRepayDEBT?.params,
    header: [
      {
        alignment: "center",
        bold: true,
        fontSize: 14,
        text: "รายงานการชำระหนี้",
      },
      {
        text: [
          {
            alignment: "center",
            bold: true,
            fontSize: 14,
            margin: [0, 0, 15, 0],
            text: `สิทธิ : ${props.reportRepayDEBT?.params?.coverage}  `,
          },
          {
            alignment: "center",
            bold: true,
            fontSize: 14,
            margin: [20, 0, 0, 0],
            noWrap: true,
            text: ` ต้นสังกัด : ${props.reportRepayDEBT?.params?.payer} `,
          },
        ],
      },
      {
        alignment: "center",
        fontSize: 14,
        text: `วันที่รับชำระ ตั้งแต่ ${props.reportRepayDEBT?.params?.start_date} ถึง ${props.reportRepayDEBT?.params?.end_date}`,
      },
    ],
  });

  const { font, images, styles } = headerForm;

  const generateTableBody = (data: any, columns: any) => {
    console.log("saika ~ generateTableBody:", data);
    let body: any[] = [];

    // Header of Table
    body.push([
      {
        text: "ลำดับ",
        margin: [0, 2, 0, 2],
        alignment: "center",
        border: [true, true, false, true],
      },
      {
        text: "เลขที่\n ใบแจ้งหนี้",
        margin: [0, 2, 0, 2],
        alignment: "center",
        border: [false, true, false, true],
      },
      {
        text: "วันที่รับชำระ",
        margin: [0, 2, 0, 2],
        alignment: "center",
        border: [false, true, false, true],
      },
      {
        text: "เลขที่ใบเสร็จ/ \n REP NO.",
        margin: [0, 2, 0, 2],
        alignment: "center",
        border: [false, true, false, true],
      },
      {
        text: "สิทธิ",
        margin: [0, 2, 0, 2],
        alignment: "center",
        border: [false, true, false, true],
      },
      {
        text: "หน่วยงานต้นสังกัด",
        margin: [0, 2, 0, 2],
        alignment: "center",
        border: [false, true, false, true],
      },
      {
        text: "สถานะ\nส่งเบิก",
        margin: [0, 2, 0, 2],
        alignment: "center",
        border: [false, true, false, true],
      },
      {
        text: "จำนวน \nส่งเบิก",
        margin: [0, 2, 0, 2],
        alignment: "center",
        border: [false, true, false, true],
      },
      {
        text: "ยอดเบิก",
        margin: [0, 2, 0, 2],
        alignment: "right",
        border: [false, true, false, true],
      },
      {
        text: "ยอดรับชำระ",
        margin: [0, 2, 0, 2],
        alignment: "right",
        border: [false, true, false, true],
      },
      {
        text: "ค้างชำระ",
        margin: [0, 2, 0, 2],
        alignment: "right",
        border: [false, true, false, true],
      },
      {
        text: "ประเภท \n การรับชำระ",
        margin: [0, 2, 0, 2],
        alignment: "center",
        border: [false, true, true, true],
      },
    ]);

    data.forEach((field: any) => {
      if (field.items?.length > 0) {
        (field.items || []).forEach((row: any, index: any) => {
          const coverageName = row.coverage_name.split().map((text: string) => {
            return splitStringNewLine(text, { fontSize: 12, width: 335 });
          });

          let rowData = [
            {
              text: row.seq || "",
              alignment: "center",
              border: [true, true, false, true],
              id: `column_1-${index}`,
            },
            {
              text: row.lot_no || "",
              alignment: "center",
              border: [false, true, false, true],
              id: `column_2-${index}`,
            },
            {
              text: row.paid_date || "",
              alignment: "center",
              border: [false, true, false, true],
              id: `column_3-${index}`,
            },
            {
              text: row.receipt_code || "",
              alignment: "center",
              border: [false, true, false, true],
              id: `column_4-${index}`,
            },
            {
              stack: coverageName.map((texts: string[]) => {
                return {
                  stack: texts.map((text: string) => {
                    return { text };
                  }),
                };
              }),
              alignment: "left",
              border: [false, true, false, true],
              id: `column_5-${index}`,
            },
            {
              text: row.payer_name || "",
              alignment: "left",
              border: [false, true, false, true],
              id: `column_6-${index}`,
            },
            {
              text: row.send_claim_status || "",
              alignment: "center",
              border: [false, true, false, true],
              id: `column_7-${index}`,
            },
            {
              text: row.send_claim_amount || "",
              alignment: "center",
              border: [false, true, false, true],
              id: `column_8-${index}`,
            },
            {
              text: row.send_claim_price || "",
              alignment: "right",
              border: [false, true, false, true],
              id: `column_9-${index}`,
            },
            {
              text: row.paid_price || "",
              alignment: "right",
              border: [false, true, false, true],
              id: `column_A-${index}`,
            },
            {
              text: row.overdue_price || "",
              alignment: "right",
              border: [false, true, false, true],
              id: `column_B-${index}`,
            },
            {
              text: row.payment_method || "",
              alignment: "center",
              border: [false, true, true, true],
              id: `column_C-${index}`,
            },
          ];
          body.push(rowData);
        });
      }
    });

    console.log("saika ~ data[1]?.items:", data[0]?.items);
    if (data[1]?.sub_totals) {
      body.push([
        subTableTotals(
          data[1]?.sub_totals,
          ["coverage_name", "payer_name", "total_send_claim", "total_transfer", "total_cheque","total_cash"],
          (data[0]?.items || 0).length + 1
        ),
        {
          text: "",
          // id: `column_2-${(data[0]?.items || 0).length+1}`
        },
        {
          text: "",
          // id: `column_3-${(data[0]?.items || 0).length+1}`
        },
        {
          text: "",
          // id: `column_4-${(data[0]?.items || 0).length+1}`
        },
        {
          text: "",
          // id: `column_5-${(data[0]?.items || 0).length+1}`
        },
        {
          text: "",
          // id: `column_6-${(data[0]?.items || 0).length+1}`
        },
        {
          text: "",
          // id: `column_7-${(data[0]?.items || 0).length+1}`
        },
        {
          text: "",
          // id: `column_8-${(data[0]?.items || 0).length+1}`
        },
        {
          text: "",
          // id: `column_9-${(data[0]?.items || 0).length+1}`
        },
        {
          text: "",
          // id: `column_A-${(data[0]?.items || 0).length+1}`
        },
        {
          text: "",
          // id: `column_B-${(data[0]?.items || 0).length+1}`
        },
        {
          text: "",
          // id: `column_C-${(data[0]?.items || 0).length+1}`
        },
      ]);
    }

    return body;
  };
  // 5
  const tableReport = (data: any, columns: any) => {
    return {
      table: {
        widths: ["4%", "10%", "7%", "7%", "16%", "*", "5%", "5%", "6%", "6%", "6%", "10%"],
        headerRows: 1,
        dontBreakRow: true,
        body: generateTableBody(data, columns),
      },
    };
  };

  const subTableTotals = (data: any, columns: any, index: any) => {
    console.log("saika ~ index:", index);

    return {
      // ...(index % 20 === 0 && {pageBreak: 'after'}),
      margin: [5, 15, 0, 5],
      colSpan: 12,
      id: `sub-table`,
      table: {
        widths: ["19%", "27%", "7%", "7%", "7%","7%"],
        body: generateSubTableTotals(data, columns),
      },
      layout: {
        paddingLeft: (i: any, node: any) => {
          return 5;
        },
        paddingRight: (i: any, node: any) => {
          return 5;
        },
        paddingTop: (i: any, node: any) => {
          return 2;
        },
        paddingBottom: (i: any, node: any) => {
          return 2;
        },
      },
    };
  };

  const generateSubTableTotals = (data: any, columns: any) => {
    let subBody: any[] = [];
    let sumTotalSendClaim = 0;
    let sumTotalTransferOPD = 0;
    let sumTotalCheque = 0;
    let sumTotalCash = 0;

    // Header of Table
    subBody.push([
      { text: "สิทธิ", alignment: "center" },
      { text: "หน่วยงานต้นสังกัด", alignment: "center" },
      { text: "ยอดรวม", alignment: "center" },
      { text: "เงินโอน", alignment: "center" },
      { text: "เช็ค", alignment: "center" },
      { text: "เงินสด", alignment: "center" },
    ]);

    if (data?.length > 0) {
      console.log("saika ~ data:", data)
      data.forEach((row: any) => {
        let dataRow: any = [];

        columns.forEach((column: any) => {
          let subRow: any[] = [];
          if (row[column] !== null && row[column] !== undefined) {
            switch (column) {
              case "total_send_claim":
                sumTotalSendClaim = sumTotalSendClaim + Number(row[column].replaceAll(",", ""));
                dataRow.push({
                  text: row[column],
                  alignment: "right",
                  border: [true, false, true, false],
                });
                break;
              case "total_transfer":
                sumTotalTransferOPD = sumTotalTransferOPD + Number(row[column].replaceAll(",", ""));
                dataRow.push({
                  text: row[column],
                  alignment: "right",
                  border: [true, false, true, false],
                });
                break;
              case "total_cheque":
                sumTotalCheque = sumTotalCheque + Number(row[column].replaceAll(",", ""));
                dataRow.push({
                  text: row[column],
                  alignment: "right",
                  border: [true, false, true, false],
                });
                break;
              case "total_cash":
                sumTotalCash = sumTotalCash + Number(row[column].replaceAll(",", ""));
                dataRow.push({
                  text: row[column],
                  alignment: "right",
                  border: [true, false, true, false],
                });
                break;
              default:
                dataRow.push({ text: row[column], border: [true, false, true, false] });
                break;
            }
          } else {
            dataRow.push(" ");
          }
        });

        subBody.push(dataRow);
      });
    }

    subBody.push([
      {
        text: "รวมทั้งสิ้น",
        colSpan: 2,
        alignment: "left",
        bold: true,
        border: [true, true, true, true],
      },
      "",
      {
        text: handleToString(sumTotalSendClaim),
        alignment: "right",
        border: [true, true, true, true],
      },
      {
        text: handleToString(sumTotalTransferOPD),
        alignment: "right",
        border: [true, true, true, true],
      },
      {
        text: handleToString(sumTotalCheque),
        alignment: "right",
        border: [true, true, true, true],
      },
      {
        text: handleToString(sumTotalCash),
        alignment: "right",
        border: [true, true, true, true],
      },
    ]);
    return subBody;
  };

  const handleToString = (num: any) => {
    return num.toLocaleString("en-US", {
      style: "decimal",
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  };

  return {
    ...headerForm,
    content: [
      tableReport(props.reportRepayDEBT?.fields || [], [
        "seq",
        "lot_no",
        "paid_date",
        "receipt_code",
        "coverage_name",
        "payer_name",
        "send_claim_status",
        "send_claim_amount",
        "send_claim_price",
        "paid_price",
        "overdue_price",
        "payment_method",
      ]),
    ],
    images: {
      ...images,
    },
    styles: {
      ...styles
    },
    pageOrientation: `landscape`,
    defaultStyle: {
      font,
      // fontSize: 14,
    },
    pageSize: `A4`,
    pageBreakBefore: (
      currentNode: any,
      followingNodesOnPage: any,
      nodesOnNextPage: any,
      previousNodesOnPage: any
    ) => {
      const checkMorePage = (node: any) => node.pageNumbers.length > 1;
      const hasFollowingNodesMore = previousNodesOnPage.some(checkMorePage);
      const isColumnOnPage = (node: any) =>
        node.id?.startsWith("column") && node?.id !== currentNode?.id;

      // let isNodeNewLine = ""
      // const isNodeMore = (previousNodesOnPage || []).filter((node:any)=> (node.pageNumbers.length > 1))?.[0]?.id || ""
      // if (isNodeMore?.startsWith("column")) {
      //   isNodeNewLine = isNodeMore.split("-")[1]
      // }
      // console.log("saika ~ isNodeNewLine:", isNodeNewLine)
      // console.log(`saika ~ currentNode?.id?.includes(-${isNodeNewLine}):`, currentNode?.id?.includes(`-${isNodeNewLine}`))
      // console.log("saika ~ hasFollowingNodesMore:", hasFollowingNodesMore)

      // if (isNodeNewLine && currentNode?.id?.includes(`-${isNodeNewLine}`) && hasFollowingNodesMore) {
      //   return true
      // }

      const hasPreviousColumn = previousNodesOnPage.some(isColumnOnPage);

      if (!hasPreviousColumn && currentNode.id === "sub-table") {
        return true;
      }

      if (currentNode.id === "sub-table" && currentNode.pageNumbers.length > 1) {
        return true;
      }
    },
  };
}
