import DPOService from "../services/DPOService";
import { to } from "../../utils";
import _ from "../../compat/lodashplus";
export default class DPOManager {
  apiToken?: string | null = null;
  dpoService = new DPOService(this.apiToken);

  constructor(apiToken: string | null = null) {
    this.apiToken = apiToken;
    this.dpoService = new DPOService(this.apiToken);
  }

  getMedicalRecord = async (params) => {
    var medicalRecord = [];
    var medicalRecordError = null;
    const dpoService = new DPOService(this.apiToken);
    const [error, response, network] = await to(dpoService.getMedicalRecord(params));
    if (response) {
      medicalRecord = response.items;
    } else {
      medicalRecordError = error;
    }
    return [medicalRecord, medicalRecordError, network];
  };
  discharged = async (emrId, checkoutCause, { deathDateTime = "", doctorFee = "", doctorFeeRule = "", doctorFeeRemark = "", require_diagnosis = true, approve_by = "" }, division) => {
    var params = {
      checkout_cause: checkoutCause,
      death_datetime: deathDateTime,
      doctor_fee: doctorFee,
      doctor_fee_rule: doctorFeeRule,
      doctor_fee_remark: doctorFeeRemark,
      require_diagnosis: require_diagnosis,
      approve_by: approve_by,
    };
    const [error, response, network] = await to(this.dpoService.discharge(emrId, params, division));
    return [response, error, network];
  };
  cancelCheckIn = async (emrId) => {
    const dpoService = new DPOService(this.apiToken);
    const [error, response, network] = await to(dpoService.postCancelCheckIn(emrId));
    return [response, error, network];
  };
  getDiagnosis = async (emrId) => {
    const dpoService = new DPOService(this.apiToken);
    const [error, response, network] = await to(dpoService.getDiagnosis(emrId));
    return [response, error, network];
  };
  updateDiagnosis = async (emrId, dPrimaryList, dSecondaryList) => {
    var params = {
      principal_diagnosis: dPrimaryList,
      secondary_diagnosis: dSecondaryList,
    };
    const dpoService = new DPOService(this.apiToken);
    const [error, response, network] = await to(dpoService.putDiagnosis(emrId, params));
    return [response, error, network];
  };
  /**
   * Get diagnosis list wth condition
   */
  getPatientDiagnosis = async (patientId, { divisionId, doctorId, isOnlyICD10 } = {}) => {
    var diagnosisData = [];
    var diagnosisError = null;
    let params = {
      patient_id: patientId,
    };
    if (divisionId) {
      params.division = divisionId;
    }
    if (doctorId) {
      params.doctor = doctorId;
    }
    if (isOnlyICD10) {
      params.only_current_icd10_version = isOnlyICD10;
    }
    const dpoService = new DPOService(this.apiToken);
    const [error, response, network] = await to(dpoService.getPatientDiagnosis(params));
    if (error) {
      diagnosisError = error;
    } else {
      diagnosisData = response.items;
    }
    return [diagnosisData, diagnosisError, network];
  };
  getClinialType = async () => {
    const dpoService = new DPOService(this.apiToken);
    var noteType = [];
    var noteError = [];
    const [error, response, network] = await to(dpoService.getClinialType());
    if (response) {
      noteType = response.items;
    } else {
      noteError = error;
    }
    return [noteType, noteError, network];
  };
  /**
   * Get diagnosis template
   */
  getDiagnosisTemplate = async ({ name } = {}) => {
    var templateData = [];
    var templateError = null;
    let params = {};
    if (name) {
      params.name__icontains = name;
    }
    const dpoService = new DPOService(this.apiToken);
    const [error, response, network] = await to(dpoService.getDiagnosisTemplate(params));
    if (error) {
      templateError = error;
    } else {
      templateData = response.items;
    }
    return [templateData, templateError, network];
  };
  // Correct Way to send GET with params
  getDoctorCertificateType = async () => {
    const dpoService = new DPOService(this.apiToken);
    const [error, response, network] = await to(dpoService.getDoctorCertificateType());
    // transform data
    let addKeyResponse = [];
    if (response) {
      const addKey = (e) => {
        let a = _.set(e, "key", e.id);
        let b = _.set(a, "text", e.name);
        let c = _.set(b, "value", e.name);
        return c;
      };
      //TODO: use items so It's not have pagination feature
      addKeyResponse = _.map(response.items, addKey);
    }
    return [addKeyResponse, error, network];
  };
  postDoctorCertificate = async ({ id, emr, pdf, pdf_b64data, original, detail, type, language, diagnosis, doctor }) => {
    //TODO : Check emr value, If not have
    let params = {
      id: id ? id : "",
      emr: emr ? emr : "",
      pdf: pdf ? pdf : false,
      pdf_b64data: pdf_b64data ? pdf_b64data : "",
      original: !!original,
      detail: detail ? detail : "",
      type: type ? type : "",
      language: language ? language : "",
      diagnosis: diagnosis ? diagnosis : "",
      doctor: doctor ? doctor : "",
    };
    if (!id) {
      const dpoService = new DPOService(this.apiToken);
      const [error, response, network] = await to(dpoService.postDoctorCertificate(params));
      return [response, error, network];
    } else {
      const dpoService = new DPOService(this.apiToken);
      const [error, response, network] = await to(dpoService.putDoctorCertificateCertId({ certId: id, params }));
      return [response, error, network];
    }
  };
  getDiagnosisSummary = async ({ emrId }) => {
    const dpoService = new DPOService(this.apiToken);
    let params = {};
    if (emrId) {
      params.emr = emrId;
    }
    const [error, response, network] = await to(dpoService.getDiagnosisSummary(params));
    return [response, error, network];
  };
  getDoctorCertificatesPrintlog = async ({ startDate, endDate, patient, doctorId }) => {
    const dpoService = new DPOService(this.apiToken);
    let params = {};
    if (startDate) {
      params.start_date = startDate;
    }
    if (endDate) {
      params.end_date = endDate;
    }
    if (patient) {
      params.patient = patient;
    }
    if (doctorId) {
      params.doctor = doctorId;
    }
    const [error, response, network] = await to(dpoService.getDoctorCertificatesPrintlog(params));
    if (response && response.items) {
      return [response.items, error, network];
    } else {
      return [response, error, network];
    }
  };
  getDoctorOrder = async ({ emrId, progressionId } = {}) => {
    var doctorOrderData = null;
    var doctorOrderError = null;
    let params = {};
    if (emrId) {
      params.emr = emrId;
    }
    if (progressionId) {
      params.progression_cycle = progressionId;
    }
    const dpoService = new DPOService(this.apiToken);
    const [error, response, network] = await to(dpoService.getDoctorOrder(params));
    if (error) {
      doctorOrderError = error;
    } else {
      doctorOrderData = response.items;
    }
    return [doctorOrderData, doctorOrderError, network];
  };
  putDoctorOrder = async ({ orderId, action } = {}) => {
    let data = {};
    if (action) {
      data.action = action;
    }
    const dpoService = new DPOService(this.apiToken);
    const [error, response, network] = await to(dpoService.putDoctorOrder({ orderId, data }));
    return [response, error, network];
  };
  /**
   * Create doctor consult order
   */
  postDoctorConsultOrder = async (order = CONSULT_ORDER_MODEL) => {
    let params = this._convertToConsultOrderModel(order);
    const dpoService = new DPOService(this.apiToken);
    const [error, response, network] = await to(dpoService.postDoctorConsultOrder(params));
    return [response, error, network];
  };
  /**
   * Get doctor consult order with ID
   */
  getDoctorConsultOrderWithId = async (orderId) => {
    const dpoService = new DPOService(this.apiToken);
    const [error, response, network] = await to(dpoService.getDoctorConsultOrderWithId(orderId));
    return [response, error, network];
  };
  /**
   * Update doctor consult order with ID
   */
  updateDoctorConsultOrderWithId = async (order = CONSULT_ORDER_MODEL) => {
    let params = this._convertToConsultOrderModel(order);
    const dpoService = new DPOService(this.apiToken);
    const [error, response, network] = await to(dpoService.putDoctorConsultOrderWithId(order.id, params));
    return [response, error, network];
  };
  /**
   * Get Medical Record Gallery from EMR id
   */
  getMedicalRecordGallery = async (emrId) => {
    const dpoService = new DPOService(this.apiToken);
    const [error, response, network] = await to(this.dpoService.getMedicalRecordGallery(emrId));
    return [response, error, network];
  };
  /**
   * Get Medical Record Gallery from EMR id
   */
  postMedicalRecordGallery = async ({ emrId, data }) => {
    const dpoService = new DPOService(this.apiToken);
    const [error, response, network] = await to(this.dpoService.postMedicalRecordGallery({ emrId, data }));
    return [response, error, network];
  };
  putMedicalRecordPicture = async ({ selectedImage, imageDataURI, detail, isActive = true }) => {
    const dpoService = new DPOService();
    let picId = selectedImage.id;
    let data = {
      emr: selectedImage.emr,
      description: detail,
      image: imageDataURI,
      is_active: isActive,
    };
    const [error, response, network] = await to(this.dpoService.putMedicalRecordPicture({ picId, data }));
    return [response, error, network];
  };

  getTemplateGalleryImage = async ({ name }) => {
    const dpoService = new DPOService(this.apiToken);
    let params = {};
    if (name) {
      params.name = name;
    }
    const [error, response, network] = await to(this.dpoService.getTemplateGalleryImage(params));
    return [response, error, network];
  };

  /**
   * Private function
   */
  _convertToConsultOrderModel = (order) => {
    let model = {
      id: order.id ? order.id : null,
      action: order.action ? order.action : "",
      encounter: order.encounter ? order.encounter : null,
      order_encounter: order.order_encounter ? order.order_encounter : null,
      order_status: order.order_status ? order.order_status : null,
      division: order.division ? order.division : "",
      consulted_doctor: order.consulted_doctor ? order.consulted_doctor : "",
      note: order.note ? order.note : "",
      urgency: order.urgency ? order.urgency : "",
    };
    return model;
  };
  estimateDoctorOrder = async (params) => {
    const dpoService = new DPOService(this.apiToken);
    const [error, response, network] = await to(dpoService.estimateDoctorOrder(params));
    return [response, error, network];
  };
  getMedicalRecordEmrDetail = async ({ emrId } = {}) => {
    let params = {};
    const dpoService = new DPOService(this.apiToken);
    const [error, response, network] = await to(dpoService.getMedicalRecordEmrDetail({ params, emrId }));
    return [response, error, network];
  };
  getPatientHistoryEMR = async ({ emrId } = {}) => {
    let params = {};
    const dpoService = new DPOService(this.apiToken);
    const [error, data, network] = await to(dpoService.getPatientHistoryEMR({ params, emrId }));
    return [data, error, network];
  };
  getSensitiveNoteEMR = async ({ emrId, divisionId } = {}) => {
    let params = {};
    if (divisionId) {
      params.div = divisionId;
    }
    const dpoService = new DPOService(this.apiToken);
    const [error, data, network] = await to(dpoService.getSensitiveNoteEMR({ params, emrId }));
    return [data, error, network];
  };
  postSensitiveNoteEMR = async ({ emrId, divisionId, sensitiveNote } = {}) => {
    let data = {};
    if (divisionId) {
      data.division = [divisionId];
    }
    if (emrId) {
      data.emr = emrId;
    }
    if (sensitiveNote) {
      data.sensitive_note = sensitiveNote;
    }
    const dpoService = new DPOService(this.apiToken);
    const [error, response, network] = await to(dpoService.postSensitiveNoteEMR({ data }));
    return [response, error, network];
  };
  putSensitiveNoteEMR = async ({ emrId, divisionId, sensitiveNote } = {}) => {
    let params = {};
    let data = {};
    if (divisionId) {
      params.div = divisionId;
      data.division = [divisionId];
    }
    if (emrId) {
      data.emr = emrId;
    }
    if (sensitiveNote) {
      data.sensitive_note = sensitiveNote;
    }
    const dpoService = new DPOService(this.apiToken);
    const [error, response, network] = await to(dpoService.putSensitiveNoteEMR({ params, data, emrId }));
    return [response, error, network];
  };
  putPatientHistoryEMR = async ({ emrId, pastIllness, personalHistory, familyHistory } = {}) => {
    let params = {};
    let data = {};
    if (emrId) {
      data.emr = emrId;
    }
    if (pastIllness) {
      data.past_illness = pastIllness;
    }
    if (personalHistory) {
      data.personal_history = personalHistory;
    }
    if (familyHistory) {
      data.family_history = familyHistory;
    }
    const dpoService = new DPOService(this.apiToken);
    const [error, response, network] = await to(dpoService.putPatientHistoryEMR({ params, data, emrId }));
    return [response, error, network];
  };
  getPhysicalExamOrgan = async () => {
    const dpoService = new DPOService(this.apiToken);
    const [error, response, network] = await to(dpoService.getPhysicalExamOrgan());
    return [response, error, network];
  };
  postPatientScreenNew = async ({ data } = {}) => {
    let params = {};
    const dpoService = new DPOService(this.apiToken);
    const [error, response, network] = await to(dpoService.postPatientScreenNew({ params, data }));
    return [response, error, network];
  };
  putPatientScreenUpdate = async ({ data } = {}) => {
    let params = {};
    const dpoService = new DPOService(this.apiToken);
    const [error, response, network] = await to(dpoService.putPatientScreenUpdate({ params, data }));
    return [response, error, network];
  };

  patchPatientDiagnosis = async (diagnosisId, data) => {
    const dpoService = new DPOService(this.apiToken);
    const [error, response, network] = await to(dpoService.patchPatientDiagnosis(diagnosisId, data));
    return [response, error, network];
  };
}
export const CONSULT_ORDER_MODEL = {
  id: null,
  action: "",
  encounter: null,
  order_encounter: null,
  order_status: null,
  division: "",
  consulted_doctor: "",
  note: "",
  urgency: ""
};
