import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Form,
  FormGroup,
  FormField,
  Checkbox,
  Input,
  Dropdown,
  Button
} from 'semantic-ui-react'

const CardORCancelListUX = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        id="CardORCancelList"
        style={{padding: "15px"}}>
        <Form>
          <FormGroup>
            <FormField
              width={4}>
              <Checkbox
                checked={props.ORCancelListSequence?.orFilter?.checkedHn}
                label="HN"
                name="checkedHn"
                onChange={props.onChangeFilter}
                style={{fontWeight: "bold", marginBottom: "0.45rem"}}>
              </Checkbox>
              <div
                style={{width: "100%"}}>
                {props.patientSearchBox}
              </div>
            </FormField>
            <FormField
              width={4}>
              <label>
                ชื่อ-สกุลผู้ป่วย
              </label>
              <Input
                disabled={true}
                fluid={true}
                style={{opacity:1}}
                value={props.ORCancelListSequence?.orFilter?.patientFullName || ""}>
              </Input>
            </FormField>
            <FormField
              width={4}>
              <Checkbox
                checked={props.ORCancelListSequence?.orFilter?.checkedDoctor}
                label="แพทย์ผ่าตัด"
                name="checkedDoctor"
                onChange={props.onChangeFilter}
                style={{fontWeight: "bold",marginBottom: "0.45rem"}}>
              </Checkbox>
              <div
                style={{width: "100%"}}>
                {props.doctorSearchBox}
              </div>
            </FormField>
            <FormField
              width={4}>
              <label>
                Location
              </label>
              <Dropdown
                clearable={true}
                fluid={true}
                name="operating_detail__operating_room__location_id"
                onChange={props.onChangeLocation}
                options={props.locationOptions || []}
                search={true}
                selection={true}
                style={{minWidth: "100%", width: 0}}
                value={props.ORCancelListSequence?.orFilter ?.operating_detail__operating_room__location_id || ""}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField
              width={3}>
              <label>
                วันที่ผ่าตัด
              </label>
              <div
                style={{width: "100%"}}>
                {props.startDateTextBox}
              </div>
            </FormField>
            <FormField>
              <label>
                {"\u00a0"}
              </label>
              <label
                style={{height: "100%", display: "flex", alignItems: "center", marginTop: "-0.75rem"}}>
                ถึง
              </label>
            </FormField>
            <FormField
              width={3}>
              <label>
                {"\u00a0"}
              </label>
              <div
                style={{width: "100%"}}>
                {props.endDateTextBox}
              </div>
            </FormField>
            <FormField
              width={3}>
              <label>
                วันที่จองผ่าตัด
              </label>
              <div
                style={{width: "100%"}}>
                {props.createdDateTextBox}
              </div>
            </FormField>
            <FormField
              width={3}>
              <label>
                Room
              </label>
              <Dropdown
                clearable={true}
                fluid={true}
                name="operating_detail__operating_room__id"
                onChange={props.onChangeFilter}
                options={props.roomOptions || []}
                search={true}
                selection={true}
                style={{width: 0, minWidth: "100%"}}
                value={props.ORCancelListSequence?.orFilter ?.operating_detail__operating_room__id || ""}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{marginBottom: "0.5rem"}}>
                {"\u00a0"}
              </label>
              <div>
                {props.buttonSearch}
              </div>
            </FormField>
            <FormField>
              <label
                style={{marginBottom: "0.5rem"}}>
                {"\u00a0"}
              </label>
              <Button
                color="yellow"
                onClick={props.onClear}
                size="small"
                style={{minWidth: "max-content", margin: 0}}>
                ล้างการค้นหา
              </Button>
            </FormField>
            <FormField>
              <label
                style={{marginBottom: "0.5rem"}}>
                {"\u00a0"}
              </label>
              <div>
                {props.buttonPrint}
              </div>
            </FormField>
          </FormGroup>
        </Form>
        <div>
          
          <Table
            className="--rt-tbody-overflow-y-hidden"
            data={props.ORCancelListSequence?.cancelOrder?.items || []}
            headers="วันที่ผ่าตัด, HN, ข้อมูลผู้ป่วย, Location/Room, แพทย์ผ่าตัด, Diagnosis, Type, Operation, Special Equipment, Blood, Anesthesia"
            keys="start_date, hn, patient_name, locationRoom, primary_doctor_name, diagnosis, typeLabel, pre_operation_summary, special_equipments_text, blood_request_summary, anesthesia_method_name"
            minRows={10}
            showPagination={false}
            style={{height: "calc(100dvh - 21rem)"}}
            widths="^90,^85,^100,^100,^100,^100,^100,^120,^90,^70,^90">
          </Table>
        </div>
      </div>
    )
}


export default CardORCancelListUX

export const screenPropsDefault = {}

/* Date Time : Wed Dec 06 2023 11:56:40 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardORCancelList"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"15px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 33,
      "name": "Table",
      "parent": 22,
      "props": {
        "className": {
          "type": "value",
          "value": "--rt-tbody-overflow-y-hidden"
        },
        "data": {
          "type": "code",
          "value": "props.ORCancelListSequence?.cancelOrder?.items || []"
        },
        "headers": {
          "type": "value",
          "value": "วันที่ผ่าตัด, HN, ข้อมูลผู้ป่วย, Location/Room, แพทย์ผ่าตัด, Diagnosis, Type, Operation, Special Equipment, Blood, Anesthesia"
        },
        "keys": {
          "type": "value",
          "value": "start_date, hn, patient_name, locationRoom, primary_doctor_name, diagnosis, typeLabel, pre_operation_summary, special_equipments_text, blood_request_summary, anesthesia_method_name"
        },
        "minRows": {
          "type": "code",
          "value": "10"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"calc(100dvh - 21rem)\"}"
        },
        "widths": {
          "type": "value",
          "value": "^90,^85,^100,^100,^100,^100,^100,^120,^90,^70,^90"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "FormGroup",
      "parent": 48,
      "props": {
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "FormGroup",
      "parent": 48,
      "props": {
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "FormField",
      "parent": 49,
      "props": {
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "FormField",
      "parent": 49,
      "props": {
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "FormField",
      "parent": 49,
      "props": {
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "FormField",
      "parent": 49,
      "props": {
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "Checkbox",
      "parent": 51,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ORCancelListSequence?.orFilter?.checkedHn"
        },
        "label": {
          "type": "value",
          "value": "HN"
        },
        "name": {
          "type": "value",
          "value": "checkedHn"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", marginBottom: \"0.45rem\"}"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "label",
      "parent": 52,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ-สกุลผู้ป่วย"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "Input",
      "parent": 52,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{opacity:1}"
        },
        "value": {
          "type": "code",
          "value": "props.ORCancelListSequence?.orFilter?.patientFullName || \"\""
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "Checkbox",
      "parent": 53,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ORCancelListSequence?.orFilter?.checkedDoctor"
        },
        "label": {
          "type": "value",
          "value": "แพทย์ผ่าตัด"
        },
        "name": {
          "type": "value",
          "value": "checkedDoctor"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\",marginBottom: \"0.45rem\"}"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "div",
      "parent": 53,
      "props": {
        "children": {
          "type": "code",
          "value": "props.doctorSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "div",
      "parent": 51,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "label",
      "parent": 54,
      "props": {
        "children": {
          "type": "value",
          "value": "Location"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "Dropdown",
      "parent": 54,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "operating_detail__operating_room__location_id"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeLocation"
        },
        "options": {
          "type": "code",
          "value": "props.locationOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"100%\", width: 0}"
        },
        "value": {
          "type": "code",
          "value": "props.ORCancelListSequence?.orFilter ?.operating_detail__operating_room__location_id || \"\""
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "FormField",
      "parent": 50,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "FormField",
      "parent": 50,
      "props": {
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "FormField",
      "parent": 50,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "FormField",
      "parent": 50,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "FormField",
      "parent": 50,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "FormField",
      "parent": 50,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "label",
      "parent": 63,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่ผ่าตัด"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "div",
      "parent": 63,
      "props": {
        "children": {
          "type": "code",
          "value": "props.startDateTextBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "label",
      "parent": 64,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "label",
      "parent": 64,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "style": {
          "type": "code",
          "value": "{height: \"100%\", display: \"flex\", alignItems: \"center\", marginTop: \"-0.75rem\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "label",
      "parent": 65,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "div",
      "parent": 65,
      "props": {
        "children": {
          "type": "code",
          "value": "props.endDateTextBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "label",
      "parent": 66,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่จองผ่าตัด"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "div",
      "parent": 66,
      "props": {
        "children": {
          "type": "code",
          "value": "props.createdDateTextBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "label",
      "parent": 67,
      "props": {
        "children": {
          "type": "value",
          "value": "Room"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "Dropdown",
      "parent": 67,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "operating_detail__operating_room__id"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "options": {
          "type": "code",
          "value": "props.roomOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: 0, minWidth: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ORCancelListSequence?.orFilter ?.operating_detail__operating_room__id || \"\""
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "div",
      "parent": 68,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearch"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "FormField",
      "parent": 50,
      "props": {
      },
      "seq": 82,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 83,
      "name": "FormField",
      "parent": 50,
      "props": {
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 84,
      "name": "label",
      "parent": 83,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.5rem\"}"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "label",
      "parent": 82,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.5rem\"}"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 86,
      "name": "Button",
      "parent": 82,
      "props": {
        "children": {
          "type": "value",
          "value": "ล้างการค้นหา"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClear"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", margin: 0}"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 83,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonPrint"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "label",
      "parent": 68,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.5rem\"}"
        }
      },
      "seq": 79,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "CardORCancelListUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
