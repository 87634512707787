import React from 'react';
import PropTypes from "prop-types";
import { Input } from 'semantic-ui-react';
import { formatToBEDatetime } from '../../utils';
import moment from 'moment';
import { useIntl } from "react-intl";

const TimeFreeTextBox = React.forwardRef((props: any, ref) => {

  const [time, setTime] = React.useState('')

  React.useEffect(() => {
    setTime(props.value)
  }, [props.value])

  React.useImperativeHandle(ref, () => ({
    getTime: () => {
      return time
    }
  }))

  const timeChange = (e: any) => {
    const { value } = e.target;
    setTime(value)
    props.onChange(value)
  }

  const setCurrentTime = () => {
    if (!props.disabledClick) {
      let dateTime = formatToBEDatetime({ date: moment() })
      let time = dateTime.split(' ')[1]
      setTime(time)
      props.onChange(time)
    } else {
      if (!time) {
        let dateTime = formatToBEDatetime({ date: moment() })
        let time = dateTime.split(' ')[1]
        setTime(time)
        props.onChange(time)
      }
    }
  }

  return (
    <Input
      disabled={props.disabled ? true : false}
      type='time'
      name="time"
      value={time}
      onChange={timeChange}
      icon='clock'
      className={props.className}
      onClick={setCurrentTime }
    />
  )
})

TimeFreeTextBox.defaultProps = {
  disabled: false,
  onChange: () => {},
  value: '',
  className: '',
  disabledClick: false,
};

TimeFreeTextBox.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  className: PropTypes.string,
  disabledClick: PropTypes.bool,
};

export default React.memo(TimeFreeTextBox)

