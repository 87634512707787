import React, { useMemo, useState, useEffect, useCallback } from "react";
import { Modal } from "semantic-ui-react";

// UX
import ModDrugOrderRejectUX from "./ModDrugOrderRejectUX";

// Common
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import EditorColumn from "react-lib/appcon/common/EditorColumn";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import { useIntl } from "react-intl";

// Types
type ModDrugOrderRejectProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
  // data
  open: boolean;
  items?: any[];
  // CommonInterface
  buttonLoadCheck?: Record<string, any>;
  // callback
  onClose: () => any;
};

const BUTTON_ACTIONS = {
  reject: "REJECT",
};

const MOD_DRUG_ORDER_REJECT = "ModDrugOrderReject";

const ACTION_REJECT = `${MOD_DRUG_ORDER_REJECT}_${BUTTON_ACTIONS.reject}`;

const ModDrugOrderReject = (props: ModDrugOrderRejectProps) => {
  const intl = useIntl();
  const [selectedDrugList, setSelectedDrugList] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState<string | null | any[]>(null);
  const [isLoadingSuccess, setIsLoadingSuccess] = useState(false);

  // Effect
  useEffect(() => {
    setSelectedDrugList(props.items || []);
  }, [props.items]);

  useEffect(() => {
    const btn = props.buttonLoadCheck?.[ACTION_REJECT];

    if (btn === "SUCCESS") {
      setIsLoadingSuccess(true);
    } else if (isLoadingSuccess && !btn) {
      handleClose();
    }
  }, [props.buttonLoadCheck]);

  // Callback
  const handleChange = useCallback(
    (data: any) => (value: string) => {
      const items = selectedDrugList.map((o: any) =>
        o.id === data.id
          ? {
              ...o,
              note_reject: value,
            }
          : o
      );

      setSelectedDrugList(items);
    },

    [selectedDrugList]
  );

  // Memo
  const items = useMemo(() => {
    return selectedDrugList.map((item: any) => ({
      ...item,
      name: item.drug_name_from_format,
      note: (
        <EditorColumn
          value={item.note_reject}
          align="left"
          backgroundColor={"rgb(255, 255, 204)"}
          onDataChanged={handleChange(item)}
        />
      ),
    }));
  }, [selectedDrugList]);

  // Handler
  const handleConfirmReject = () => {
    const isNoted = selectedDrugList.every((item: any) => item.note_reject);

    if (!isNoted) {
      return setErrorMessage("กรุณาระบุเหตุผลการ Reject ยาให้ครบทุกตัว");
    }

    props.onEvent({
      message: "RunSequence",
      params: {
        sequence: "DrugOrderAction",
        action: "REJECT",
        confirm: true,
        items: selectedDrugList,
        buttonLoadKey: ACTION_REJECT,
      },
    });

    setErrorMessage(null);
  };

  const handleClose = () => {
    setErrorMessage(null);
    setIsLoadingSuccess(false);

    props.onClose();
  };

  return (
    <Modal open={props.open} onClose={handleClose}>
      <ModDrugOrderRejectUX
        // data
        dataRow={items}
        // callback
        onConfirm={handleConfirmReject}
        onClose={handleClose}
        // Element
        ErrorMessage={
          <ErrorMessage
            error={errorMessage}
            style={{ margin: "0.5rem 1rem -0.25rem" }}
          />
        }
        ButtonSave={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleConfirmReject}
            // data
            paramKey={ACTION_REJECT}
            buttonLoadCheck={props.buttonLoadCheck?.[ACTION_REJECT]}
            // config
            color={"green"}
            size="medium"
            name={BUTTON_ACTIONS.reject}
            title={intl.formatMessage({ id: "ใช่" })}
            basic={true}
            style={{ width: "200px" }}
          />
        }
        languageUX={props.languageUX}
      />
    </Modal>
  );
};

ModDrugOrderReject.displayName = "ModDrugOrderReject";

export default React.memo(ModDrugOrderReject);
