import WasmController from "../../../../frameworks/WasmController";
// APIs
import EncounterDetail from "issara-sdk/apis/EncounterDetail_core";
import OperatingOrderList from "issara-sdk/apis/OperatingOrderList_apps_ORM";

export type State = {
  // sequence
  ORHistorySequence?: {
    sequenceIndex?: string | null;

    selectedEncounter?: any;
    selectedOrOrder?: any;
  } | null;
};

export const StateInitial: State = {
  ORHistorySequence: {
    sequenceIndex: null,

    selectedEncounter: null,
    selectedOrOrder: null,
  },
};

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const Start: Handler = async (controller, params) => {
  let state = controller.getState();

  if (params?.action === "SELECTED_ENCOUNTER") {
    let selectedEncounter: any = null;

    if (params.enId) {
      const [enResp, enError, enNetwork] = await EncounterDetail.retrieve({
        apiToken: controller.apiToken,
        pk: params.enId,
      });
      if (!enError) {
        selectedEncounter = enResp;

        Start(controller, { ...params, action: "SELECTED_OR_ORDER" });
      }
    }
    state = controller.getState();
    controller.setState({
      ORHistorySequence: {
        ...state.ORHistorySequence,
        selectedEncounter: selectedEncounter,
      },
    });
  } else if (params?.action === "SELECTED_OR_ORDER") {
    const [orResp, orError, orNetwork] = await OperatingOrderList.list({
      apiToken: controller.apiToken,
      params: { encounter: params.enId, status_name: "COMPLETED" },
    });
    if (orError) {
      state = controller.getState();
      controller.setState({
        ORHistorySequence: {
          ...state.ORHistorySequence,
          selectedOrOrder: null,
        },
      });
    } else {
      state = controller.getState();
      controller.setState({
        ORHistorySequence: {
          ...state.ORHistorySequence,
          selectedOrOrder: orResp?.items?.[0],
        },
      });
    }
  }
};
