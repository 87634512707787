import React, { useState, useEffect, useMemo } from "react";
import Tab from "react-lib/frameworks/Tab";
import { Button } from "semantic-ui-react";
import CardSummaryWaitingQueue from "./CardSummaryWaitingQueue";
import CardSummaryDoctor from "./CardSummaryDoctor";
import CardSummaryStatistics from "./CardSummaryStatistics";
import { useIntl } from "react-intl";

const CardStatistics = (props: any) => {
  const intl = useIntl();
  const [tab, setTab] = useState("queue");

  return (
    <>
      <div style={{ height: "100%", width: "100%", padding: "10px" }}>
        <div style={{ display: "flex" }}>
          <MenuItem
            menu="queue"
            tab={tab}
            name={intl.formatMessage({ id: "ระยะเวลาการรอคิว (Patient Journey)" })}
            setTab={setTab}
          />
          <MenuItem menu="doctor" tab={tab} name={intl.formatMessage({ id: "สรุปผลการปฏิบัติงานทันตแพทย์" })} setTab={setTab} />
          <MenuItem menu="statistics" tab={tab} name={intl.formatMessage({ id: "สถิติการนัดหมาย" })} setTab={setTab} />
        </div>

        {tab === "queue" ? (
          <CardSummaryWaitingQueue
            controller={props.controller}
            proxyController={props.proxyController}
            setProp={props.setProp}
            onEvent={props.onEvent}
            divisionList={props.divisionList}
            selectedDivision={props.selectedDivision}
            searchedItemListWithKey={props.searchedItemListWithKey}
            searchedItemList={props.searchedItemList}
            summaryWaitingQueueList={props.summaryWaitingQueueList}
            languageUX={props.languageUX}
          />
        ) : tab === "doctor" ? (
          <CardSummaryDoctor
            controller={props.controller}
            proxyController={props.proxyController}
            setProp={props.setProp}
            onEvent={props.onEvent}
            divisionList={props.divisionList}
            selectedDivision={props.selectedDivision}
            searchedItemListWithKey={props.searchedItemListWithKey}
            searchedItemList={props.searchedItemList}
            summaryDoctorList={props.summaryDoctorList}
            languageUX={props.languageUX}
          />
        ) : tab === "statistics" ? (
          <CardSummaryStatistics
            controller={props.controller}
            proxyController={props.proxyController}
            setProp={props.setProp}
            onEvent={props.onEvent}
            divisionList={props.divisionList}
            selectedDivision={props.selectedDivision}
            searchedItemListWithKey={props.searchedItemListWithKey}
            searchedItemList={props.searchedItemList}
            summaryStatisticsList={props.summaryStatisticsList}
            languageUX={props.languageUX}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

const MenuItem = (props: any) => (
  <Button
    size="tiny"
    onClick={(e: any) => props.setTab(props.menu)}
    color={props.menu === props.tab ? "blue" : undefined}
  >
    {props.name}
  </Button>
);

export default CardStatistics;
