import _ from "../../compat/lodashplus";

export default class CardVitalSignController {
  constructor({ ptmManager } = {}) {
    this.ptmManager = ptmManager;
  }

  getVitalSign = async ({
    startDate,
    endDate,
    startTime,
    endTime,
    encounterId,
  } = {}) => {
    console.log("handleGetVitalSign");
    // const encounterId = this.state.patientData.ENCOUNTER.encounter_id
    // if (!encounterId) {
    //   toast.error("[Card vital sign]กรุณาระบุ encounter");
    // }
    // let fromDate = this.state.patientInfo["date_time"] ? this.state.patientInfo["date_time"].split("[")[0].trim() : ''
    // let params = {
    //   encounter: encounterId,
    //   use_patient: true,
    // }
    // if (fromDate) {
    //   params.from_date = fromDate
    // }
    if (!encounterId) {
      return [null, "Vital Sign กรุณาระบุ encounter"];
    }
    let params = {
      sort_by_seq: true,
      use_patient: true,
      invert_order: true
    };

    if (encounterId) {
      params.encounter = encounterId;
    }
    if (startDate) {
      params.from_date = startDate;
    }
    if (endDate) {
      params.to_date = endDate;
    }
    if (startTime) {
      params.from_time = startTime;
    }
    if (endTime) {
      params.to_time = endTime;
    }
    const [
      vitalSignData,
      vitalSignError,
      network,
    ] = await this.ptmManager.getVitalSign(params);

    return [vitalSignData, vitalSignError, network];
  };

  getVitalSignType = async ({ encounterId } = {}) => {
    console.log("getVitalSignType");

    if (!encounterId) {
      return [null, "Vital Sign Type, กรุณาระบุ encounter"];
    }
    let params = {
      encounter: encounterId,
    };

    const [
      vitalSignType,
      vitalSignTypeError,
      network,
    ] = await this.ptmManager.getVitalSignType(params);
    return [vitalSignType, vitalSignTypeError, network];
  };

  saveNewMeasurement = async (data) => {
    console.log("saveNewMeasurement");
    console.log(data);
    if (data.encounter === undefined || data.encounter === null) {
      data.encounter = this.state.encounterId;
    }
    const [
      vsResponse,
      vsError,
      network,
    ] = await this.ptmManager.postMeasurement(data);
    return [vsResponse, vsError, network];
  };

  updateVitalSign = async (data) => {
    const [
      vsResponse,
      vsError,
      network,
    ] = await this.ptmManager.postVitalSignUpdate(data);
    if (!vsError) {
      console.log(vsResponse);
    }
    return [vsResponse, vsError, network];
  };

  deleteMeasurement = async (measurement_id, params) => {
		const [vsResponse, vsError, network] = await this.ptmManager.patchMeasurement(measurement_id, params);
	  return [vsResponse, vsError, network] 
  };

  getLastHeight = async (patientId) => {
    if (patientId === "undefined" || patientId === "null") {
      return [null, "ไม่มี patient ID", null]
    }
    const [
      response,
      responseError,
      network
    ] = await this.ptmManager.getLastHeight(patientId);
    return [response, responseError];
  };
}
