import React, { useState } from "react";
import { Dimmer, Loader, Icon } from "semantic-ui-react";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { useSize } from "../../../utils/hooksUtils";
import LineLoginButton from "./Line/LoginButton";
import FBLoginButton from "./FacebookLogin/FBLoginButton";
import settings from "../../../../config/config";
import { useIntl } from "react-intl";

const AnonymousButton = (props) => (
  <h3>
    <a style={{ cursor: "pointer" }} onClick={props.onClick}>
      ทดลองใช้งาน IsHealth Studio โดยไม่ล็อกอิน
    </a>
  </h3>
);

const Login = (props) => {
  const intl = useIntl();
  const history = useHistory();
  const lineButtonRef = React.useRef();
  const { width, height } = useSize(lineButtonRef);
  // const [username, setUsername] = useState("");
  // const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const loadFbLoginApi = () => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "3314489911914234",
        cookie: true,
        xfbml: true,
        version: "v9.0"
      });
      window.FB.AppEvents.logPageView();
      // window.FB.Event.subscribe("auth.statusChange", response => {
      //   if (response.authResponse) {
      //     checkLoginState();
      //   } else {
      //     checkLoginState();
      //   }
      // });
      checkLoginState()

    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/th_TH/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  };

  const responseFacebook = async fbResponse => {
    if (
      fbResponse.status === "not_authorized" ||
      fbResponse.status === "unknown"
    ) {
      return;
    }
    setIsLoading(true);
    if (fbResponse.authResponse && fbResponse.authResponse.accessToken) {
      if (fbResponse.authResponse) {
        props.onLoginFacebookSiteSuccess(fbResponse.authResponse);
        await window.FB.api(
          "/me",
          "get",
          { fields: "email,name,picture" },
          async res => {
            console.log(res, "EMAIL")
            // if (!("email" in res)) {
            //   alert("User ไม่ได้อนุญาติ ให้เข้าถึง email-address");
            //   setIsLoading(false);
            //   props.openSetupEmail();
            //   return;
            // }

            let [
              response,
              error,
              network
            ] = await props.controller.getRegisterByTokenFacebook({
              accessToken: fbResponse.authResponse.accessToken,
              app: settings.APP_NAME
            });

            if (response) {
              props.onLoginSuccess(response);
            } else {
              setIsLoading(false);
            }
          }
        );
      }
    }
  };

  React.useEffect(() => {
    loadFbLoginApi();
    // console.log(" Cookies.get("apiToken")", Cookies.get("apiToken"))
    if (Cookies.get("apiToken")) {
      props.onHaveApiToken();
    }
    if (props.IsAnonymousLogin && Cookies.get("apiToken") == "anonymous") {
      props.onHaveAnonymousLogin();
    }

  }, []);

  const gotoLineLogin = () => {
    props.loginLine()
    // if (props.lineLoginManager) {
    //   props.lineLoginManager.openLogin();
    // }
  };

  const checkLoginState = () => {
    window.FB.getLoginStatus(response => {
      responseFacebook(response);
    });
  };

  const handleFBClick = () => {
    // console.log("handleFBClick !!! ")
    // window.FB.login();

    window.FB.login(function (response) {
      if (response.authResponse) {

        console.log("Login success ", response)
        checkLoginState()
      } else {
        console.log('User cancelled login or did not fully authorize.');
      }
    });

  };

  return (
    // <a
    //   class="btn-login btn btn-primary"
    //   href="https://accounts.thevcgroup.com/login/facebook/?next=http://localhost:3000/#/"
    //   data-loading-text="กำลัง Login...."
    //   style={{ backgroundColor: "#3b5998" }}
    // >
    //   <img
    //     src="https://showtime.sgp1.digitaloceanspaces.com/static/images/facebook.png"
    //     style={{ marginTop: "-6px", paddingRight: "5px", width: "24px" }}
    //   />
    //   Login with Facebook
    // </a>
    <Dimmer.Dimmable dimmed={isLoading}>
      <div className="Login">
        <div className="fbLogin">
          {props.detail}
          <LineLoginButton
            onClick={gotoLineLogin}
            ref={lineButtonRef}
            textStyle={{ margin: "25px 70px" }}
            logoStyle={{ width: "40px", height: "40px" }}
          />
          <br />
          <br />
          <FBLoginButton
            onClick={handleFBClick}
            style={{ height, width }}
            textStyle={{ margin: "25px 45px" }}
            logoStyle={{ height, padding: "5px" }}
          />
          <br />
          <br />
          <div
            onClick={() => {
              history.push("/EmailLogin");
            }}
            style={{
              backgroundColor: "transparent",
              color: "#5DBCD2",
              border: "2px solid #5DBCD2",
              margin: "0 auto",
              width,
              height,
              lineHeight: "38px",
              cursor: "pointer"
            }}
          >
            <Icon style={{ padding: "5px", float: "left" }} name="mail" size="big" />
            <span>{intl.formatMessage({ id: "ลงชื่อเข้าใช้งานด้วยอีเมล" })}</span>
          </div>
          <br />
          {props.IsAnonymousLogin && (
            <div
              style={{
                display: "flex",
                margin: "10px",
                justifyContent: "center",
              }}
            >
              <AnonymousButton
                onClick={() => {
                  props.OnAnonymousLoginSuccess();
                }}
              />
            </div>
          )}
        </div>
      </div>
      <Dimmer active={isLoading} inverted>
        <Loader size="massive">{"Loading"}</Loader>
      </Dimmer>
    </Dimmer.Dimmable>
  );
};

Login.defaultProps = {
  onLoginSuccess: () => { },
  IsAnonymousLogin: false,
  OnAnonymousLoginSuccess: () => { },
  onLoginFacebookSiteSuccess: () => { },
  onHaveApiToken: () => { },
  onHaveAnonymousLogin: () => { },
  openSetupEmail: () => { },
  detail: null,
  redirectUri: null,
  device_id: null,
  subscription: null
};

Login.propTypes = {
  onLoginSuccess: PropTypes.func,
  IsAnonymousLogin: PropTypes.bool,
  OnAnonymousLoginSuccess: PropTypes.func,
  onLoginFacebookSiteSuccess: PropTypes.func,
  onHaveApiToken: PropTypes.func,
  onHaveAnonymousLogin: PropTypes.func,
  openSetupEmail: PropTypes.func,
  redirectUri: PropTypes.string,
  device_id: PropTypes.string,
  subscription: PropTypes.object
};

export default Login;
