// NPM: MAIN
import React, { useEffect, useState, useMemo, useRef, ReactElement } from "react"
import {
  Tab,
  Form,
  Grid,
  Button,
  Input,
  Segment,
  Checkbox,
  Label,
  Icon,
  Popup,
  Dimmer,
  Loader,
  SemanticCOLORS,
  Modal,
  Header,
  Dropdown,
  TextArea,
  List,
  Image,
} from "semantic-ui-react"
import moment from "moment"
import _ from "../../compat/lodashplus"

// CARD
import DateTextBox from "react-lib/apps/common/DateTextBox"
import SemanticTable from "./SemanticTable"
import TimeField from "react-simple-timefield";
import SubNarcoticUsage from "./SubNarcoticUsage";
import PasswordRecheck from "../common/PasswordRecheck";

//Mod
import ErrorMessage from "react-lib/apps/common/ErrorMessage"
import ModInfo from "../common/ModInfo"
import ModMedicationRecordDetail, { getEmployeeToken } from "./ModMedicationRecordDetail"
import SubUserToken from "./SubUserToken"
import ModConfirmNurse2 from "./ModConfirmNurse2";

//Constant
import * as Util from "../../utils";
// import { MedAdminEvent } from "../../../TPD/MedAdminInterface"
// import MedAdminController from "../../../TPD/MedAdminController"
import { DisplayDrugDetail, FilterMedRecordType, TYPE_COLOR } from "./CardQueueWard";
import { useIntl } from "react-intl";

// STYLE
import "./CardMedAdmin.scss"

export type CardMedAdminQueueWardStateType = {
  patientInfo: any;
  medicationRecord: any;
  onEvent: (e: any) => any;
  originalOnEvent?: any;
  django: any;
  division: any[];
  wardTypeList: any[];
  controller: any;
  filterMedRecord?: FilterMedRecordType;
  barcodeHNRef: {
    current: any;
  };
  tabCurrentIndex: number;
  tableHeight?: string;
  userProfile?: Record<string, any>
  // Element
  SubDrugOrderStatusAlert?: any;
  // config
  checkEditablePreAdmin?: boolean;
  checkDrugAdminByCanAdmin?: boolean;
  preAdminNarcotic?: boolean;
  drugDetailByLabel?: boolean;
  // callback
  onSetFilter?: (key: keyof FilterMedRecordType, value: any) => any;
  onSelectRow?: (params: { emr: number; patient: number }) => any;
  onAddMedicationRecord: (drugInfo: any) => null;
};

export type DrugBarcodeType = {
  drug_item_id: number;
  expected_admin_date: string
  expected_admin_time: string;
  admin_time: string;
  reference_standard_admin_time: string | number,
  original: any
}

export type PopupConfig = {
  color?: SemanticCOLORS,
  offset?: number,
  inverted?: boolean
  position?: any
}

export type InputNurseProps = {
  error: boolean;
  token: string;
  password?: string;
  username?: string;
};

export type InputNurseType = {
  nurse1: InputNurseProps;
  nurse2: InputNurseProps;
}

export type HardDrugType = {
  HIGH_ALERT?: string[],
  LASA?: string[];
  PREGCAT?: string[];
  INTERACTION?: string[];
}

export type CorrectionReasonType = {
  time: string;
  reason: any;
  token: string;
  error: boolean;
  id: number | null;
  timeOld: string;
}

export type ModErrorType = {
  error?: any,
  open: boolean,
  type?: string;
  title?: string;
}

export type ModDescriptionType = {
  title?: string;
  open: boolean;
  textContent?: any;
  color?: string;
}

const currentTime = () => Util.formatDatetime(moment()).split(" ")[1]

const initialFilterMedRecord: FilterMedRecordType = {
  ward: null,
  date: Util.formatDate(moment()),
  time: currentTime(),
  checkedTime: false,
  division: " ",
  hn: null,
}

export const initialInput = {
  nurse1: {
    error: false,
    token: ""
  },
  nurse2: {
    error: false,
    token: ""
  }
}

export const initialCorReason = {
  time: currentTime(),
  reason: "",
  token: "",
  error: false,
  id: null,
  timeOld: ""
}

const initialModError: ModErrorType = {
  error: null,
  type: "",
  open: false,
  title: ""
}

const initialModDescription: ModDescriptionType = {
  textContent: null,
  color: "",
  open: false,
  title: ""
}

export const HARD_DRUG = {
  HIGH_ALERT: "high_alert",
  LASA: "lasa",
  PREGCAT: "pregcat",
  INTERACTION: "interaction"
}

const PREADMIN_KEYWORD = {
  "Admin date": "Pre date",
  "Admin time": "Pre time"
}

export const CardMedAdminQueueWardInitial: CardMedAdminQueueWardStateType = {
  patientInfo: null,
  medicationRecord: null,
  onEvent: () => null,
  django: null,
  division: [],
  wardTypeList: [],
  controller: null,
  filterMedRecord: initialFilterMedRecord,
  barcodeHNRef: {
    current: null
  },
  tabCurrentIndex: 0,
  onSetFilter: () => null,
  onAddMedicationRecord: (drugInfo: any) => null,
};

// Images
export const IMAGES = {
  drugEXT: "/static/images/medAdmin/drugExt.png",
  drugIM: "/static/images/medAdmin/drugIm.png",
  drugIV: "/static/images/medAdmin/drugIv.png",
  drugORAL: "/static/images/medAdmin/drugOral.png",
};

export const reduceCreateOrUpdateDrug = ({
  action,
  adminDate,
  drugBarcode,
  tokenNurse1,
  tokenNurse2,
}: {
  action: "ADMIN" | "PRE_ADMIN";
  adminDate?: string;
  drugBarcode: any[];
  tokenNurse1?: string;
  tokenNurse2?: string;
}) => {
  const result: any[][] = [[], []];

  for (const item of drugBarcode) {
    let cloneItem = { ...item };
    const { original } = cloneItem;
    if (original?.can_add_std_time && original?.frequency_dose_per_day === 0) {
      cloneItem.expected_admin_date = Util.formatDate(moment());
      cloneItem.expected_admin_time = currentTime();
      cloneItem.reference_standard_admin_time = null;
    }

    delete cloneItem.original;

    if (original.medication_record_id) {
      const { medication_record_id: id, nurse1, nurse2, rate = "", reason, remark } = original;

      result[1].push({
        action,
        admin_date: adminDate,
        items: [cloneItem],
        medication_record_id: id,
        nurse1: tokenNurse1 || nurse1,
        nurse2: tokenNurse2 || nurse2,
        rate,
        reason,
        remark,
      });
    } else {
      result[0].push(cloneItem);
    }
  }

  return result;
};

export const adminTimeStatus = ({
  isIgnoreStdTime = false, isEditable = false,
  item,
  useKeywordReplacement = false,
}: {
  isIgnoreStdTime: boolean;
  item: any;
  useKeywordReplacement?: boolean;
}) => {
  const style = {
    backgroundColor: "gray",
    cursor: "",
  };

  let innerHTML = "";

  if (!item) {
    return { innerHTML, style };
  }

  if (isEditable) {
    style.backgroundColor = "lightyellow";
  }

  if (item.is_omitted && !isIgnoreStdTime) {
    style.backgroundColor = "#ff8383";
  }

  if (style.backgroundColor !== "gray") {
    item.is_editable = true;
    style.cursor = "pointer";
  }

  const summary: string = item.summary || "";
  innerHTML =
    useKeywordReplacement && summary
      ? summary.replaceAll(
          /(Admin date)|(Admin time)/g,
          (reg) => (PREADMIN_KEYWORD as Record<string, string>)[reg]
        )
      : item.summary || "&nbsp;";
  return { innerHTML, style };
};

export const getAdminItemStatus = ({
  checkedEditablePreAdmin,
  date,
  drugInfo,
  item,
  useKeywordReplacement,
  configPreAdminTime
}: {
  checkedEditablePreAdmin?: boolean;
  date?: string;
  drugInfo: any;
  item: any;
  useKeywordReplacement?: boolean;
  configPreAdminTime?: number;
}) => {
  const isIgnoreStdTime =
    !!drugInfo.prn ||
    ["ONE_DOSE", "STAT"].includes(drugInfo.type_name || "") ||
    (drugInfo.can_add_std_time && drugInfo.frequency_dose_per_day === 0);

  const isEditable = !!drugInfo.prn
    ? true
    : drugInfo.type_name === "CONTINUE_PLAN"
    ? item?.on_date_status === "ACTIVE"
    : item.is_editable;

  const adminStatus = adminTimeStatus({
    isIgnoreStdTime,
    isEditable,
    item,
    useKeywordReplacement,
  });

  const isStatusOff = item?.on_date_status === "OFF";

  const isAdminDateMismatch =
    drugInfo.can_add_std_time && drugInfo.frequency_dose_per_day === 0
      ? false
      : date && date !== item?.expected_admin_date && drugInfo.type_name === "ONE_DAY"
      ? true
      : false;

  let style: Record<string, any> = { backgroundColor: isStatusOff ? "#474744" : "grey" };

  if (isAdminDateMismatch) {
    style = { backgroundColor: "grey" };
  } else if (isEditable || !checkedEditablePreAdmin || (isIgnoreStdTime && !isStatusOff)) {
    style = {
      ...adminStatus.style,
      backgroundColor: adminStatus.style.backgroundColor,
      color: "",
    };
  }
  return { adminStatus, isEditable, isIgnoreStdTime, style };
};

export const displayDrugName = (drug_info: any) => {
  return drug_info.drug_name_from_format;
  // if (drug_info.trade_name && drug_info?.strength && drug_info?.dosage_form_name) {
  //   return `${drug_info?.trade_name || ""} ${
  //     drug_info?.generic_name && `(${drug_info?.generic_name})`
  //   } ${drug_info?.strength || ""} ${drug_info?.dosage_form_name || ""}`;
  // } else {
  //   return drug_info.changing_detail?.split("\n")[0] || drug_info?.product_name || drug_info.name;
  // }
};

export const findDrugOrder = (
  medicationRecord: any[],
  id: number | string | { info?: string },
  key?: string
) => {
  let findOrder: any;

  //* ข้อมูล object ที่มี field info
  if (typeof id !== "number" && typeof id !== "string" && key === "product" && id.info) {
    // * แยกคำต่างๆ
    const REG_WORD = /\b([\w\-\/])+\b/gm;
    const words: string[] = id.info.replace(/^\d\. /gm, "").match(REG_WORD) || [];
    const list: any[] = medicationRecord.flatMap((data: any) =>
      data.items.map((item: any) => {
        const values: string[] = displayDrugName(item.drug_info).match(REG_WORD) || [];

        return {
          ...item,
          // * คำซ้ำ
          intersection: values.filter((value) => words.includes(value)).length,
        };
      })
    );

    // * sort drug name ที่ซ้ำมากที่สุด
    findOrder = list.sort((a: any, b: any) => b.intersection - a.intersection);
  } else {
    findOrder = medicationRecord
      .map((data: any) =>
        data.items.find((item: any) =>
          key === "product" ? item.drug_info.product === Number(id) : item.drug_info.id === id
        )
      )
      .filter(Boolean);
  }

  return findOrder?.[0];
};

const CardPreAdminQueueWard: React.FC<CardMedAdminQueueWardStateType> = (props) => {
  const intl = useIntl();
  //* Show loading icon when fetch api
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingModal, setIsLoadingModal] = useState<boolean>(false)
  const [tpdConfig, setTpdConfig] = useState<any>({});

  //* Show Modal
  const [openModalRemark, setOpenModalRemark] = useState<boolean>(false)
  const [openModalDetail, setOpenModalDetail] = useState<string>("")
  const [openModalNurse, setOpenModalNurse] = useState<boolean>(false)
  const [openModalReason, setOpenModalReason] = useState(false)
  const [openModSuccess, setOpenModSuccess] = useState(false)
  const [openModError, setOpenModError] = useState<ModErrorType>(initialModError)
  const [openModDescription, setOpenModDescription] = useState(initialModDescription)

  //* Show Mod
  const [errMessageNurse1, setErrMessageNurse1] = useState(null)
  const [errMessageNurse2, setErrMessageNurse2] = useState(null)

  const [remarkDivision, setRemarkDivision] = useState<any>(" ")
  const [reasonList, setReasonList] = useState<any[]>([])
  const [reason, setReason] = useState<any>("")

  //* Selected drug order
  const [selectedDetail, setSelectedDetail] = useState<any>(null)
  const [selectedDrugItem, setSelectedDrugItem] = useState<Record<string, any> | null>(null)

  const [correctionReason, setCorrectionReason] = useState<CorrectionReasonType>(initialCorReason)
  const [showInputNurse, setShowInputNurse] = useState<InputNurseType>(initialInput)
  const [drugBarcode, setDrugBarcode] = useState<DrugBarcodeType[]>([])
  const [hardDrugOrder, setHardDrugOrder] = useState<HardDrugType>({})
  const [narcoticOrder, setNarcoticOrder] = useState<
    Record<
      string,
      { apply?: string; reject?: string; info?: any; patient_name?: string }
    >
  >({});

  const [isLoadingSave, setIsLoadingSave] = useState(false)

  const textAreaRef = useRef<HTMLTextAreaElement>()
  const isMounted = useRef(true)

  useEffect(() => {
    getUserPermission();
  }, [])

  useEffect(() => {
    if (isMounted.current) {
      if (props.django && props.filterMedRecord.ward) {
        onFetchTableData(props.django)
        isMounted.current = false
      }
    }
  }, [props.django, props.filterMedRecord.ward])

  // Component
  const SubDrugOrderStatusAlert = useMemo(() => {
    return props.SubDrugOrderStatusAlert;
  }, [props.SubDrugOrderStatusAlert]);

  const onFetchTableData = async (django: any) => {
    setIsLoading(true)
    drugBarcode[0] && setDrugBarcode([])
    await getListMedAdminRecord(django)
    setIsLoading(false)
  }

  // ---------------------------- FETCH API, method: GET ---------------------
  const getListMedAdminRecord = async (_django: any, setParams: any = {}) => {
    const { division, checkedTime, time, ward } = props.filterMedRecord
    const hn = props.barcodeHNRef.current.value

    if (typeof division !== "string") {
      setParams.admin_division = division
    }
    if (checkedTime) {
      setParams.time = time
    }
    if (hn) {
      setParams.hn = hn
    }
    if (typeof ward !== "string") {
      setParams.ward = ward
    }
    return await props.onEvent({
      message: "getListMedAdminRecord",
      params: {
        data: {
          mode: "PREADMIN",
          date: props.filterMedRecord.date,
          ...setParams
        },
        tabCurrentIndex: 0
      }
    })
  }

  const getUserPermission = async () => {
    const [res]: any[] = await props.onEvent({
      message: "getUserPermission",
      params: {
        config_TPD_PRE_ADMIN_TIME: 0,
      },
    });
    setTpdConfig(res);
  }

  const getUserTokenize = async (code) => {
    return props.onEvent({
      message: "getUserTokenize",
      params: {
        code
      }
    })
  }

  const getClinicalTermSet = async (params) => {
    return props.onEvent({
      message: "getClinicalTermSet",
      params
    })
  }

  // ---------------------------- FETCH API, method: POST ---------------------
  const postCreateMedAdminRecord = async (isConfirm = false) => {
    let orders = [...drugBarcode];

    //* Narcotic
    if (!!Object.keys(narcoticOrder).length) {
      orders = orders.map((item) =>
        narcoticOrder[item.drug_item_id]
          ? {
            ...item,
            apply: narcoticOrder[item.drug_item_id].apply,
            reject: narcoticOrder[item.drug_item_id].reject,
          }
          : item
      );
    }

    const { nurse1, nurse2 } = showInputNurse;

    const { error, tokenNurse1, tokenNurse2 } = await getEmployeeToken({
      controller: props.controller,
      inputNurse: { nurse1: { ...nurse1, username: props.userProfile?.username }, nurse2 },
      needTwoNurse: isConfirm,
    });

    if (error) {
      return [
        null,
        {
          ...(!tokenNurse1 && { nurse1: "กรุณาระบุรหัสผ่านให้ถูกต้อง" }),
          ...(!tokenNurse2 && isConfirm && { nurse2: error }),
        },
        null,
      ];
    }

    return await props.onEvent({
      message: "postCreateMedAdminRecord",
      params: {
        action: "PRE_ADMIN",
        items: orders,
        nurse1: tokenNurse1,
        nurse2: tokenNurse2,
        admin_date: props.filterMedRecord.date,
        rate: "",
        remark: "",
      },
    });
  }

  // ---------------------------- FETCH API, method: PUT ---------------------
  const putDrugOrderItemIdAdminDivision = async (id, params) => {
    return await props.onEvent({
      message: "putDrugOrderItemIdAdminDivision",
      params: {
        drugOrderItemId: id,
        data: params
      }
    })
  }

  const putDrugOrderItemIdAddStdTimeManual = async (id, params) => {
    return await props.onEvent({
      message: "putDrugOrderItemIdAddStdTimeManual",
      params: {
        drugOrderItemId: id,
        data: params
      }
    })
  }

  const putDrugOrderItemIdEditStdTimeManual = async (id, params) => {
    return await props.onEvent({
      message: "putDrugOrderItemIdEditStdTimeManual",
      params: {
        drugOrderItemId: id,
        data: params
      }
    })
  }

  // ---------------------------- END FETCH API -----------------------------

  const createPopup = (
    textContent: string,
    textLabel: string | JSX.Element,
    config: PopupConfig) => {

    return <Popup
      popperModifiers={{
        preventOverflow: {
          boundariesElement: "offsetParent",
        },
      }}
      offset={config.offset}
      trigger={
        typeof textLabel === "string" ?
          <Label
            size="tiny"
            circular
            style={{ cursor: "pointer" }}
            color={config.color}
          >
            {textLabel}
          </Label> : textLabel
      }
      wide
      inverted={config.inverted}
      position={config.position || "bottom left"}
      content={
        <div
          dangerouslySetInnerHTML={{
            __html: textContent
          }}
        />
      }
    />
  }

  const findDrugBarcode = (id, time?: string) => {
    const params: any = { drug_item_id: id }

    if (time) {
      params.expected_admin_time = time
    }
    return _.find(drugBarcode, params)
  }

  const onSetStyleError = (key: keyof InputNurseType, isError = true) => {
    setShowInputNurse({
      ...showInputNurse,
      [key]: {
        ...showInputNurse[key],
        error: isError,
      },
    });
  };

  const onSetDrugBarcode = (drug_info: any, medrecord: any, isNotFilter = false) => {
    if (medrecord.admin_time) {
      setOpenModError({
        ...openModError,
        open: true,
        error: "รายการยานี้ Pre Admin แล้ว",
        type: "warning",
        title: `ฉลากยาของ ${displayDrugName(drug_info)}`
      })
      return
    }
    const find = findDrugBarcode(medrecord.drug_order_item_id)

    if (find && !isNotFilter) {
      const filter = _.reject(drugBarcode, { drug_item_id: find.drug_item_id })

      setDrugBarcode(filter)
    } else {
      const curentTime = currentTime()
      const { expected_admin_time,
        expected_admin_date,
        drug_order_item_id,
        reference_standard_admin_time } = medrecord

      setDrugBarcode([...drugBarcode, {
        drug_item_id: drug_order_item_id,
        expected_admin_date,
        expected_admin_time,
        admin_time: curentTime,
        reference_standard_admin_time,
        original: medrecord
      }])
    }

  }

  const handleOnSelectWard = (_ev: any, type: any) => {
    props.onSetFilter?.("ward", type.value)
  }

  const handleOnKeyPress = async (ev: any) => {
    if (ev.key === "Enter") {
      ev.preventDefault()
      const value = ev.target.value
      const data = findDrugOrder(props.medicationRecord, +value.replace(/^D10/i, ""));

      if (data) {
        handleClickCheckbox(data.medication_record, data.drug_info, true)
        ev.target.value = ""
      }
    }
  }

  const handleOnClickDetail = (drugInfo: any) => {
    setOpenModalDetail("ADD")
    setRemarkDivision(props.django?.division?.id)
    setTimeout(() => {
      textAreaRef.current.value = drugInfo.admin_remark || ""
    });
    if (drugInfo.admin_division) {
      setRemarkDivision(drugInfo.admin_division)
    }
    setSelectedDetail(drugInfo)
  }

  const handleOnSaveOrder = async () => {
    if (!drugBarcode[0]) {
      setOpenModError({
        ...openModError,
        open: true,
        error: "กรุณาสแกนฉลากยาก่อนบันทึก",
        type: "warning"
      })
      return
    }

    setIsLoadingSave(true);

    const [, err] = await postCreateMedAdminRecord()

    setIsLoadingSave(false);

    if (err) {
      if (err.hasOwnProperty("nurse1")) {
        setErrMessageNurse1(err)
        onSetStyleError("nurse1")

        return
      }
      if (err.hasOwnProperty("nurse2")) {
        const list: HardDrugType = {}
        const narcotic: Record<string, any> = {}

        Object.keys(HARD_DRUG).map((key) => {
          const value = err[HARD_DRUG[key]]
          if (value[0]) {
            list[key] = value.map((item: any) => {
              const data = findDrugOrder(props.medicationRecord, item, "product");

              return displayDrugName(data?.drug_info || {})
            })
          }
        })

        setHardDrugOrder(list)
        onSetStyleError("nurse1", false);

        if (props.preAdminNarcotic) {
          // unique product id
          const products = Array.from(
            new Set([
              ...err[HARD_DRUG.HIGH_ALERT],
              ...err[HARD_DRUG.LASA],
              ...err[HARD_DRUG.PREGCAT],
            ])
          ).map(Number);
          // drug_item_id ที่ต้องการบันทึก
          const drugIds: number[] = drugBarcode.map(
            (item: any) => item.drug_item_id
          );

          const filterOrder = props.medicationRecord.flatMap((item: any) =>
            item.items.flatMap((acc: any) =>
              products.includes(acc.drug_info.product) &&
                drugIds.includes(acc.drug_info.id) &&
                acc.drug_info.is_narcotic
                ? [
                  {
                    // * แสดงชื่อผู้ป่วยของยานั้นๆ
                    ...acc,
                    patient_name: `${item.patient_full_name} [${item.hn}]`,
                  },
                ]
                : []
            )
          );

          for (const order of filterOrder) {
            narcotic[order.drug_info.id] = {
              info: order.drug_info,
              patient_name: order.patient_name,
            };
          }

          setNarcoticOrder(narcotic);
        }

        setOpenModalNurse(true)
        setErrMessageNurse1(null)

        return
      }
      setOpenModError({
        ...openModError,
        open: true,
        error: err
      })
    } else {
      setOpenModSuccess(true)
      onFetchTableData(props.django)
      setShowInputNurse(initialInput)
    }
  }

  const handleOnSaveConfirm = async () => {
    setIsLoadingModal(true)
    const [, err] = await postCreateMedAdminRecord(true)

    setIsLoadingModal(false)
    if (err) {
      if (err.hasOwnProperty("nurse2")) {
        setErrMessageNurse2({ nurse2: err.nurse2 })
        onSetStyleError("nurse2")

        return
      }
      setOpenModError({
        ...openModError,
        open: true,
        error: err
      })
    } else {
      setOpenModSuccess(true)
      setOpenModalNurse(false)
      onFetchTableData(props.django)
      setShowInputNurse(initialInput)
      setHardDrugOrder({})
      setNarcoticOrder({})
      setErrMessageNurse2(null)
    }
  }

  const handleSaveRemark = async () => {
    const params = {
      admin_division: remarkDivision,
      admin_remark: textAreaRef.current.value
    }

    setIsLoadingModal(true)
    const [, err] = await putDrugOrderItemIdAdminDivision(selectedDetail.id, params)

    setIsLoadingModal(false)
    if (err) {
      setOpenModError({
        ...openModError,
        open: true,
        error: err
      })
    } else {
      setOpenModSuccess(true)
      setSelectedDetail(null)
      onFetchTableData(props.django)
      handleCloseModalDetail()
    }

  }

  const handleCloseModalDetail = () => {
    setOpenModalDetail("")
  }

  const handleOnSaveDrug = () => {
    setOpenModalRemark(false)
    setSelectedDrugItem(null)
    setOpenModSuccess(true)
    onFetchTableData(props.django)
  }

  const handleChangeToken = (key: keyof InputNurseType, token: any, error = null) => {
    setShowInputNurse({
      ...showInputNurse,
      [key]: {
        ...showInputNurse[key],
        token
      }
    })

    if (key === "nurse1") {
      setErrMessageNurse1(error)
    } else if (key === "nurse2") {
      setErrMessageNurse2(error)
    }

    if (error) {
      onSetStyleError(key)
    }
  }

  const handleChangeInputNurse = (
    key: keyof InputNurseType,
    data: { name: string; value: any }
  ) => {
    setShowInputNurse({
      ...showInputNurse,
      [key]: {
        ...showInputNurse[key],
        [data.name]: data.value,
      },
    });
  };

  const handleOnSaveReason = async () => {
    const { time, token, id, timeOld } = correctionReason
    const params: any = {
      remark: correctionReason.reason,
      user: token
    }

    let response = []
    setIsLoadingModal(true)
    if (timeOld) {
      params.std_time_old = timeOld
      params.std_time_new = time
      response = await putDrugOrderItemIdEditStdTimeManual(id, params)
    } else {
      params.std_time = time
      response = await putDrugOrderItemIdAddStdTimeManual(id, params)
    }
    const [, err] = response

    setIsLoadingModal(false)
    if (err) {
      setOpenModError({
        ...openModError,
        open: true,
        error: err
      })
    } else {
      setOpenModSuccess(true)
      handleCloseCorReason()
      onFetchTableData(props.django)
    }
  }

  const handleCloseCorReason = () => {
    setCorrectionReason(initialCorReason)
    setReason("")
    setOpenModalReason(false)
  }

  const handleCloseModalNurse = () => {
    setOpenModalNurse(false)
    setErrMessageNurse2(null)
    setShowInputNurse({
      ...showInputNurse,
      nurse2: {
        error: false,
        token: ""
      }
    })
  }


  const handleClickCheckbox = async (medication_record, drug_info, isNotFilter = false) => {
    const present = moment().format("HH:mm:ss");
    // const travelTime = moment()
    //   .add(`${res.config_TPD_PRE_ADMIN_TIME}`, "minutes")
    //   .format("HH:mm:ss");
    // const filterMedRecord = medication_record.filter(
    //   (item: any) =>
    //     present <= item.expected_admin_time &&
    //     item.expected_admin_time <= travelTime
    // );

    const isPrnOrStat = !!drug_info.prn || drug_info.type_name === "STAT";

    let filterMedRecord = medication_record;

    if (!isPrnOrStat) {
      filterMedRecord = medication_record.filter((item: any) => {
        const maxTime = moment(item.expected_admin_time, "HH:mm:ss")
          .add(`${tpdConfig?.config_TPD_PRE_ADMIN_TIME}`, "minutes")
          .format("HH:mm:ss");
        const minTime = moment(item.expected_admin_time, "HH:mm:ss")
          .subtract(`${tpdConfig?.config_TPD_PRE_ADMIN_TIME}`, "minutes")
          .format("HH:mm:ss");

        return minTime <= present && present <= maxTime;
      });
    }

    const findMedRecord = filterMedRecord.find((item: any) => !item.admin_time);

    const checkedDrug = findMedRecord || filterMedRecord[0];

    if (checkedDrug) {
      onSetDrugBarcode(drug_info, checkedDrug, isNotFilter)
    } else {
      const find = findDrugBarcode(drug_info.id)

      if (find) {
        const filter = _.reject(drugBarcode, { drug_item_id: find.drug_item_id })

        setDrugBarcode(filter)
      } else {
        setOpenModError({
          ...openModError,
          open: true,
          error: "ไม่ตรง Standard time",
          type: "warning",
          title: `ฉลากยาของ ${displayDrugName(drug_info)}`
        })
      }
    }

  }

  const handleClickAddStdTime = async () => {
    if (!reasonList[0]) {
      const [res] = await getClinicalTermSet({ questionList: ["ADD_STD_TIME_REMARK"] })

      if (res?.ADD_STD_TIME_REMARK) {
        setReasonList(res.ADD_STD_TIME_REMARK.items)
      }
    }
    setOpenModalReason(true)
  }

  const handleClickEditStdTime = async (drugInfo, timeManual) => {
    const { manual_remark, expected_admin_time, manual_user } = timeManual
    const expectedTime = expected_admin_time?.replace(/:\w+$/i, "")
    const userCode = manual_user.match(/\[(.*?)\]/i)[0]
    const [res, err] = await getUserTokenize(userCode.match(/\w+/i)[0])

    setCorrectionReason({
      ...correctionReason,
      reason: manual_remark,
      time: expectedTime,
      id: drugInfo.id,
      timeOld: expectedTime,
      token: res.token
    })

    setReason(manual_remark)
    handleClickAddStdTime()
  }

  //* Handle click order for redirect to main
  const handleGetProps = (() => {
    return {
      onDoubleClick: (_ev: any, row: any) => {
        if (props.onSelectRow) {
          return props.onSelectRow({ emr: row.emr, patient: row.patient });
        }
        window.location.href = `?app=MainMedAdmin&patient_id=${row.patient}&emr=${row.emr}`;
      },
      style: {
        cursor: "pointer"
      }
    }
  })

  const handleClickMedRecord = (drugInfo, medRecord) => {
    let needTwoNurse=false
    if(drugInfo?.alerts?.H || drugInfo?.alerts?.L === "LASA" ||
       drugInfo?.pregcat !== "NA" || drugInfo?.alerts?.I){
      needTwoNurse=true;
    }
    setOpenModalRemark(true)
    setSelectedDrugItem({
      ...medRecord,
      admin_remark: drugInfo.admin_remark,
      admin_division: drugInfo.admin_division,
      is_narcotic: drugInfo.is_narcotic,
      drug_name: drugInfo.name,
      drug_unit: drugInfo.code_unit,
      needTwoNurse: needTwoNurse,
      rate: drugInfo?.solvent?.rate
    });
  }

  /**
   * Narcotic
   */
  const handleChangeInput = (key: string) => (data: any) => {
    setNarcoticOrder({
      ...narcoticOrder,
      [key]: {
        ...narcoticOrder[key],
        [data.name]: data.value
      },
    });
  }

  const createMedicationDoseLabel = (drug_info, isSolvent = false) => {
    return (
      <Form.Field
        width={5}
        style={{
          justifyContent: "flex-end",
          display: "grid",
          marginRight: isSolvent ? "1.9rem" : "",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: isSolvent ? "" : "0em 0.86666667em 0em 0em",
          }}
        >
          {drug_info.is_external && (
            <Label size="mini" color="orange">
              own med
            </Label>
          )}
        </div>
        <p style={{ marginTop: "5px" }} />
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {/* TODO เรียกใช้ Component แบบนี้เพราะ จะได้ไม่กระทบของเดิม CNMI และเรียกใช้ Component ได้โดยตรงจาก HISV3 */}
          {SubDrugOrderStatusAlert ? (
            <SubDrugOrderStatusAlert
              item={{ ...drug_info, claim_payload: null }}
              isFemale={true}
              onEvent={props.originalOnEvent}
              targetStateOrder={"medRecordSummary"}
              languageUX={props.languageUX}
            />
          ) : (
            <>
              {drug_info.alerts["I"] && (
                <Label
                  circular
                  size="tiny"
                  color="orange"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setOpenModDescription({
                      open: true,
                      title: `แจ้งเตือน !!! Drug Interaction`,
                      textContent: drug_info.alerts["I"].info_tpd,
                      color: "orange",
                    });
                  }}
                >
                  I
                </Label>
              )}
              {drug_info.pregcat &&
                drug_info.pregcat !== "NA" &&
                createPopup(drug_info.pregcat_description, "P", {
                  color: "pink",
                  offset: -14,
                })}
              {drug_info.alerts["H"] &&
                createPopup(drug_info.alerts["H"], "HAD", {
                  color: "red",
                })}
              {drug_info.alerts["L"] &&
                createPopup(drug_info.alerts["L"], "LASA", {
                  color: "yellow",
                })}
            </>
          )}
        </div>
      </Form.Field>
    );
  }

  const createDrugLabel = (drug_info) => {
    return <Label
      circular
      size="tiny"
      style={{ cursor: "pointer" }}
      onClick={() => {
        setOpenModDescription({
          open: true,
          title: `ฉลากยาของ ${displayDrugName(drug_info)}`,
          textContent: drug_info.label,
        })
      }}>
      <Icon name="info" style={{ margin: "0" }} />
    </Label>
  }

  const createAddStdTime = (drugInfo) => {
    return <>
      <br />
      <div style={{ width: "100%", marginLeft: "-8px" }}>
        <Button icon='plus'
          size="tiny"
          style={{ transform: "scale(0.7)" }}
          color="green"
          onClick={() => {
            setCorrectionReason({
              ...correctionReason,
              id: drugInfo.id
            })
            handleClickAddStdTime()
          }}
        />
      </div>
    </>
  }

  const gridColumns = [
    {
      Header: "Room",
      columns: [
        {
          Header: "",
          width: 120,
          accessor: " ",
          Cell: (row) => {
            const { drug_info, medication_record } = row
            const findTime = medication_record.find((item) => !item.admin_time)

            return <div style={{
              display: "flex", justifyContent: "center",
              height: "100%", alignItems: "center"
            }}>
              {findTime ?
                <Checkbox
                  style={{ transform: "scale(1.3)" }}
                  checked={!!findDrugBarcode(drug_info.id)}
                  onChange={() =>
                    handleClickCheckbox(medication_record, drug_info)
                  }
                /> : null}
            </div>
          },
        },
      ],
      Cell: (row: any) => <b>{row.room_no}</b>,
    },
    {
      Header: "HN/AN",
      width: 100,
      Cell: (row: any) => (
        <Form>
          <Form.Field>
            <b>{row.hn}</b>
            <p>{row.encounter_number}</p>
          </Form.Field>
        </Form>
      ),
      columns: [
        {
          Header: "MEDICATION AND DOSAGE",
          accessor: "hn",
          colSpan: 2,
          Cell: (row) => {
            const { drug_info } = row
            const { solvent } = drug_info
            const drugType: string =
              drug_info?.type_name === "CONTINUE_PLAN" ? "CONTINUE" : drug_info?.type_name;
            return drug_info ? (
              <Form>
                <Form.Group
                  inline
                  style={{ alignItems: "flex-start", marginBottom: "0px" }}
                >
                  <Form.Field width={2}>
                    {drugType && (
                      <Label
                        color={TYPE_COLOR[drugType] || undefined}
                        style={{ textTransform: "lowercase" }}
                      >
                        {drugType.replace(/_/g, " ")}
                      </Label>
                    )}
                  </Form.Field>
                  {/* <Form.Field width={1}>
                      {drug_info.code_route}
                      <Image src={IMAGES?.[`drug${drug_info.code_route}`]} style={{width: 32, height: 32}} />
                  </Form.Field> */}
                  <Form.Field width={9}>
                    <div>
                      <b>{displayDrugName(drug_info)}</b>
                      <DisplayDrugDetail
                        drugInfo={drug_info}
                        byLabel={props.drugDetailByLabel}
                      />
                    </div>
                  </Form.Field>
                  {createMedicationDoseLabel(drug_info)}
                </Form.Group>
                {solvent && (
                  <Form.Group style={{ marginTop: "5px" }}>
                    <Form.Field width={2}></Form.Field>
                    <Form.Field width={9}>
                      <div>
                        <b>+ {solvent?.drug_label || solvent?.name}</b>
                        <p style={{ margin: "0.2em" }}>
                          VOLUME: {solvent.volume}
                        </p>
                      </div>
                    </Form.Field>
                    {createMedicationDoseLabel(solvent, true)}
                  </Form.Group>
                )}
                <Form.Group inline>
                  <Form.Field width={2}></Form.Field>
                  <Form.Field>
                    <Button
                      content="Detail"
                      color="orange"
                      size="tiny"
                      className="button-tiny"
                      onClick={() => {
                        handleOnClickDetail(drug_info);
                      }}
                    />
                  </Form.Field>
                  <Form.Field>
                    <div>
                      {drug_info.admin_remark?.split("\n").map((str) => (
                        <div>{str}</div>
                      ))}
                    </div>
                  </Form.Field>
                </Form.Group>
              </Form>
            ) : null;
          },

        },
      ],
      getProps: handleGetProps,

    },
    {
      Header: "ผู้ป่วย",
      accessor: "",
      width: 650,
      rowSpan: 1,
      colSpan: 2,
      Cell: (row: any) => <b>{row.patient_full_name}</b>,
      getProps: handleGetProps
    },
    {
      Header: "",
      accessor: "",
      columns: [
        {
          Header: "ออกฉลากยา",
          accessor: "",
          width: 100,
          Cell: (row) => {
            const { drug_info } = row

            return <div style={{
              display: "flex", justifyContent: "center",
            }}>
              <Form>
                <Form.Group>
                  <Form.Field>
                    {createDrugLabel(drug_info)}
                  </Form.Field>
                </Form.Group>
                {
                  drug_info.solvent &&
                  <Form.Group style={{
                    marginTop: "24px"
                  }}>
                    <Form.Field>
                      {createDrugLabel(drug_info.solvent)}
                    </Form.Field>
                  </Form.Group>
                }

              </Form>
            </div>
          }

        },
      ],
    },
    {
      Header: "อายุ",
      Cell: (row: any) => <b>{row.patient_age}</b>,
      columns: [
        {
          Header: "Std Time",
          accessor: "age",
          width: 70,
          Cell: (row) => {
            const { medication_record, drug_info } = row,
              { length } = medication_record;
            const isPrnOrStat = !!drug_info.prn || drug_info.type_name === "STAT";


            return ["CONTINUE", "ONE_DAY", "CONTINUE_PLAN"].includes(drug_info.type_name) ? (
              isPrnOrStat ? (
                <div style={{ height: "100%", display: "grid", placeContent: "center" }}>PRN</div>
              ) : !medication_record[0] && drug_info.can_add_std_time ? (
                <Form
                  style={{
                    display: "grid",
                    alignItems: "flex-end",
                    height: "100%",
                    marginLeft: "11px",
                  }}
                >
                  {createAddStdTime(drug_info)}
                </Form>
              ) : (
                _.map(medication_record, (item: any, index: number) => {
                  return (
                    <Form
                      style={{
                        display: "grid",
                        margin: "0 auto",
                        alignItems: "center",
                      }}
                    >
                      <Form.Group inline>
                        <Form.Field width={15} style={{ paddingRight: "0" }}>
                          <b>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.expected_admin_time?.replace(/:\w+$/i, "") || "&nbsp;",
                              }}
                            />
                          </b>
                        </Form.Field>
                        {item.is_manual_time && (
                          <Form.Field
                            width={1}
                            style={{
                              position: "absolute",
                              right: "-15px",
                            }}
                          >
                            {createPopup(
                              `<p>Edited: ${item.manual_user}</p>
                          <p>${item.manual_remark}</p>`,
                              <div
                                style={{
                                  position: "absolute",
                                  cursor: "pointer",
                                  fontSize: "1.5em",
                                  color: "red",
                                  marginLeft: "3px",
                                }}
                                onClick={() => {
                                  handleClickEditStdTime(drug_info, item);
                                }}
                              >
                                {" "}
                                *
                              </div>,
                              {
                                offset: 15,
                                inverted: true,
                                position: "bottom right",
                              }
                            )}
                          </Form.Field>
                        )}
                      </Form.Group>
                      {drug_info.can_add_std_time &&
                        index === length - 1 &&
                        createAddStdTime(drug_info)}
                    </Form>
                  );
                })
              )
            ) : null;

          }
        },
        {
          Header: "Pre Time",
          accessor: "",
          width: 100,
          Cell: (row) => {
            const { medication_record, drug_info } = row

            return _.map(medication_record, (item: any) => {
              const { style } = getAdminItemStatus({
                checkedEditablePreAdmin: props.checkEditablePreAdmin,
                date: props.filterMedRecord?.dateFiltered,
                drugInfo: drug_info,
                item,
                useKeywordReplacement: true,
                configPreAdminTime: tpdConfig?.config_TPD_PRE_ADMIN_TIME,
              });
              const findDrug = findDrugBarcode(item.drug_order_item_id, item.expected_admin_time)

              return {
                Cell: (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: findDrug
                        ? findDrug.admin_time
                        : item.admin_time?.replace(/:\w+$/i, "") || "&nbsp",
                    }}
                  />
                ),
                getProps: {
                  onClick: (ev: any, rows: any, self: any) => {
                    if (props.checkEditablePreAdmin) {
                      if (self.getProps.style?.cursor === "pointer") {
                        handleClickMedRecord(drug_info, item);
                      }
                    } else {
                      if (style.backgroundColor !== "gray") {
                        handleClickMedRecord(drug_info, item);
                      }
                    }
                  },
                  style: {
                    ...style,
                    backgroundColor: findDrug ? "#c8ffcd" : style.backgroundColor,
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    alignItems: "center",
                  },
                },
              };
            }
            )

          },
          getProps: (row) => ({
            style: {
              backgroundColor: !row.medication_record[0] ? "lightyellow" : "",

            }
          })

        },
        {
          Header: "Nurse",
          accessor: "",
          width: 250,
          Cell: (row: any) => {
            const { medication_record, drug_info } = row

            return _.map(medication_record, (item: any) => {
              const statusText =
                item?.on_date_status === "HELD" ? "HOLD" : item?.on_date_status;
              const narcoticUsage =
                props.preAdminNarcotic &&
                  drug_info.is_narcotic &&
                  item.summary !== null
                  ? `\n<div><strong>ใช้/ทิ้ง : ใช้ ${item.apply} mg ทิ้ง ${item.reject} mg<strong></div>`
                  : "";

              const { isEditable, adminStatus, style } = getAdminItemStatus({
                item,
                drugInfo: drug_info,
                checkedEditablePreAdmin: props.checkEditablePreAdmin,
                useKeywordReplacement: true,
                date: props.filterMedRecord?.dateFiltered,
                configPreAdminTime: tpdConfig?.config_TPD_PRE_ADMIN_TIME,
              });

              return {
                Cell:
                  isEditable || !props.checkEditablePreAdmin ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: adminStatus.innerHTML + narcoticUsage,
                      }}
                    />
                  ) : (
                    <div>{statusText}</div>
                  ),
                getProps: {
                  onClick: (ev: any, rows: any, self: any) => {
                    if (props.checkEditablePreAdmin) {
                      if (self.getProps.style?.cursor === "pointer") {
                        handleClickMedRecord(drug_info, item);
                      }
                    } else {
                      if (style.backgroundColor !== "gray") {
                        handleClickMedRecord(drug_info, item);
                      }
                    }
                  },
                  style: {
                    color: "white",
                    ...style,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontWeight: "500",
                  },
                },
              };
            });
          },
          getProps: (row) => ({

            style: {
              backgroundColor: !row.medication_record[0] ? "lightyellow" : "",
            }
          })
        },
      ],
      getProps: handleGetProps
    },
  ];

  const mapOptions = (option) => _.map(option, (item, index) => ({ key: index, text: item.name, value: item.id }))
  /**
   * option choose admin division
   */
  const adminDivisionOptions = useMemo(() => {
    return props.division ?
      [{ key: "", text: "All", value: " " },
      ...mapOptions(props.division)
      ] : []
  }, [props.division])

  const wardTypeOptions = useMemo(() => {
    if (props.wardTypeList) {
      return [{ key: "", text: "All", value: " " },
      ...mapOptions(props.wardTypeList)
      ]
    } else {
      return []
    }
  }, [props.wardTypeList, props.django])

  const reasonOptions = useMemo(() => {
    return reasonList ?
      _.map(reasonList, (item, index) => ({ key: index, text: item.name, value: item.name }))
      : []
  }, [reasonList])

  console.log(props)

  return (
    <>
      <Segment className="medication-admin-ward-table">
        <Dimmer active={isLoading} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
        {/* Form display and filter queue-ward list*/}
        <Form>
          <Form.Group inline>
            <Form.Field>
              <label>Division/Ward</label>
            </Form.Field>
            <Form.Field width={3}>
              <Dropdown
                size="tiny"
                className="fluidDropdown"
                selection
                search
                value={props.filterMedRecord.ward}
                options={wardTypeOptions}
                onChange={handleOnSelectWard}
                style={{ marginRight: 14 }}
              />
            </Form.Field>

            <Form.Field>
              <label>{intl.formatMessage({ id: "วันที่" })}</label>
            </Form.Field>
            <Form.Field>
              <DateTextBox
                value={props.filterMedRecord.date}
                onChange={(value) => props.onSetFilter("date", value)}
              />
            </Form.Field>
            <Form.Field
              style={{
                display: "flex",
                paddingRight: "5px",
              }}
            >
              <Checkbox
                checked={props.filterMedRecord.checkedTime}
                onChange={() =>
                  props.onSetFilter("checkedTime", !props.filterMedRecord.checkedTime)
                }
              />
            </Form.Field>

            <Form.Field width={2}>
              <label>{intl.formatMessage({ id: "เวลา" })}</label>
              <TimeField
                input={<Input icon="clock outline" />}
                value={props.filterMedRecord.time}
                onChange={(_ev, value) => {
                  props.onSetFilter("time", value);
                }}
              />
            </Form.Field>

            <Form.Field>
              <label>Admin Division</label>
            </Form.Field>
            <Form.Field width={3}>
              <Dropdown
                size="tiny"
                className="fluidDropdown"
                selection
                search
                value={props.filterMedRecord.division}
                options={adminDivisionOptions}
                onChange={(_ev, input) => {
                  props.onSetFilter("division", input.value);
                }}
                style={{ marginRight: 14 }}
              />
            </Form.Field>

            <Form.Field>
              <Button color="blue" onClick={() => onFetchTableData(props.django)}>
                ค้นหา
              </Button>
            </Form.Field>
          </Form.Group>

          <Form.Group inline>
            <Form.Field>
              <label>Search HN</label>
            </Form.Field>
            <Form.Field>
              <Input
                ref={(instance: any) => {
                  if (instance) {
                    if (props.barcodeHNRef.current) {
                      const value = props.barcodeHNRef.current.value;

                      props.barcodeHNRef.current = instance.inputRef.current;
                      props.barcodeHNRef.current.value = value;
                    } else {
                      props.barcodeHNRef.current = instance.inputRef.current;
                    }
                  }
                }}
              />
            </Form.Field>
            <Form.Field>
              <label>Search Label</label>
            </Form.Field>
            <Form.Field>
              <Input placeholder={intl.formatMessage({ id: "สแกนฉลากยา" })} onKeyPress={handleOnKeyPress} />
            </Form.Field>
          </Form.Group>
        </Form>

        {/* Table display queue-ward list */}
        <SemanticTable
          data={props.medicationRecord}
          columns={gridColumns as any[]}
          headerLevel={0}
          toggleChildBy="items"
          defaultToggle={true}
          pageSize={5}
          tableHeight={props.tableHeight}
        />

        <br />
        <Form>
          <Form.Group inline>
            <Form.Field
              width={10}
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <ErrorMessage error={errMessageNurse1} style={{ width: "100%" }} />
            </Form.Field>
            <Form.Field>
              <label>{intl.formatMessage({ id: "รหัสผ่านพยาบาล 1" })}</label>
            </Form.Field>
            <Form.Field width={2}>
              {/* <SubUserToken
                token={showInputNurse.nurse1.token}
                controller={props.controller}
                error={showInputNurse.nurse1.error}
                onChangeToken={(token, error) => {
                  handleChangeToken("nurse1", token, error);
                }}
              /> */}
              <PasswordRecheck
                className="error-hard"
                error={!!showInputNurse.nurse1.error}
                password={showInputNurse?.nurse1?.password || ""}
                setPassword={(value) =>
                  handleChangeInputNurse("nurse1", { name: "password", value })
                }
                placeholder="ระบุรหัสผ่าน"
              />
            </Form.Field>
            <Form.Field width={1}>
              <Button color="green" size="tiny" loading={isLoadingSave} onClick={handleOnSaveOrder}>
                SAVE
              </Button>
            </Form.Field>
          </Form.Group>
        </Form>

        <ModInfo
          type={openModError.type || "error"}
          titleName={openModError.title}
          closeOnDimmerClick
          open={openModError.open}
          onApprove={() => setOpenModError(initialModError)}
          onClose={() => setOpenModError(initialModError)}
        >
          {typeof openModError.error === "string" ? (
            <p>{openModError.error}</p>
          ) : (
            <ErrorMessage error={openModError.error} />
          )}
        </ModInfo>

        <ModInfo
          color={openModDescription.color || "blue"}
          titleName={openModDescription.title}
          closeOnDimmerClick
          open={openModDescription.open}
          onApprove={() => setOpenModDescription(initialModDescription)}
          onClose={() => setOpenModDescription(initialModDescription)}
        >
          <div
            style={{
              display: "grid",
              textAlign: "left",
            }}
          >
            {openModDescription.textContent?.split("\n").map((str, index) => (
              <p key={index}>{str}</p>
            ))}
          </div>
        </ModInfo>

        <ModInfo
          type="success"
          titleName={""}
          closeOnDimmerClick
          open={openModSuccess}
          onApprove={() => setOpenModSuccess(false)}
          onClose={() => setOpenModSuccess(false)}
        />

        <Modal
          size="tiny"
          open={openModalReason}
          style={{ margin: "auto" }}
          onClose={handleCloseCorReason}
        >
          <Segment>
            <Dimmer active={isLoadingModal} inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
            <Grid>
              <Grid.Row>
                <Grid.Column textAlign="right" width={16}>
                  <Icon
                    name="close"
                    size="large"
                    color="red"
                    style={{ cursor: "pointer" }}
                    onClick={handleCloseCorReason}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Form>
              <Form.Group inline>
                <Form.Field width={4}>
                  <label>Time</label>
                </Form.Field>
                <Form.Field width={5}>
                  <TimeField
                    input={<Input icon="clock outline" />}
                    value={correctionReason.time || props.filterMedRecord.time}
                    onChange={(_ev, value) => {
                      setCorrectionReason({
                        ...correctionReason,
                        time: value,
                      });
                    }}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group inline>
                <Form.Field width={4}>
                  <label>{intl.formatMessage({ id: "เหตุผลการแก้ไข" })}</label>
                </Form.Field>
                <Form.Field width={12}>
                  <Dropdown
                    size="tiny"
                    className="fluidDropdown"
                    selection
                    search
                    value={reason}
                    options={reasonOptions}
                    onChange={(_ev, input) => {
                      setCorrectionReason({
                        ...correctionReason,
                        reason: input.value,
                      });
                      setReason(input.value);
                    }}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group inline>
                <Form.Field width={4}>
                  <label>{intl.formatMessage({ id: "รหัสพยาบาล" })}</label>
                </Form.Field>
                <Form.Field width={7}>
                  <SubUserToken
                    token={correctionReason.token}
                    controller={props.controller}
                    error={correctionReason.error}
                    onChangeToken={(token) => {
                      setCorrectionReason({
                        ...correctionReason,
                        token,
                      });
                    }}
                    languageUX={props.languageUX}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group inline>
                <Form.Field width={12} />
                <Form.Field width={2}>
                  <Button color="green" onClick={handleOnSaveReason}>{intl.formatMessage({ id: "บันทึก" })}</Button>
                </Form.Field>
              </Form.Group>
            </Form>
          </Segment>
        </Modal>

        {props.django && (
          <ModMedicationRecordDetail
            onEvent={props.onEvent}
            // controller
            controller={props.controller}
            // data
            type="PRE_ADMIN"
            DJANGO={props.django}
            orderItemId={selectedDrugItem?.drug_order_item_id}
            open={openModalRemark}
            record={selectedDrugItem}
            // config
            isNarcotic={selectedDrugItem?.is_narcotic && props.preAdminNarcotic}
            // callback
            onSaved={handleOnSaveDrug}
            onClose={() => {
              setOpenModalRemark(false);
              setSelectedDrugItem(null);
            }}
          />
        )}

        {/* Modal manage admin_remark */}
        <Modal
          size="tiny"
          open={!!openModalDetail}
          style={{ margin: "auto" }}
          onClose={handleCloseModalDetail}
        >
          <Form>
            <Dimmer active={isLoadingModal} inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
            <Form.Group>
              <Form.Field inline width={16}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column textAlign="right" width={16}>
                      <Icon
                        name="close"
                        color="red"
                        style={{ cursor: "pointer" }}
                        onClick={handleCloseModalDetail}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form.Field>
            </Form.Group>

            <Form.Group inline style={{ paddingLeft: "10px" }}>
              <Form.Field width={12}>
                <Header as="h5">Admin Division</Header>
                <Dropdown
                  size="tiny"
                  className="fluidDropdown"
                  selection
                  search
                  value={remarkDivision}
                  options={adminDivisionOptions}
                  onChange={(_ev, input) => {
                    setRemarkDivision(input.value);
                  }}
                  style={{ marginRight: 14 }}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group inline style={{ paddingLeft: "10px" }}>
              <Form.Field width={16}>
                <Header as="h5">Remark</Header>
                <TextArea
                  placeholder=""
                  rows={3}
                  style={{ resize: "none" }}
                  ref={(instance: any) => {
                    if (instance) {
                      textAreaRef.current = instance.ref.current;
                    }
                  }}
                />
              </Form.Field>
            </Form.Group>

            <br />
            {openModalDetail === "ADD" && (
              <Button color="green" fluid onClick={handleSaveRemark}>
                SAVE
              </Button>
            )}
          </Form>
        </Modal>

        <ModConfirmNurse2
          open={openModalNurse}
          isLoading={isLoadingModal}
          hardDrugOrder={hardDrugOrder}
          narcoticOrder={narcoticOrder}
          errMessageNurse2={errMessageNurse2}
          showInputNurse={showInputNurse}
          onClose={handleCloseModalNurse}
          onChangeInput={handleChangeInput}
          onChangeInputNurse={handleChangeInputNurse}
          onSaveConfirm={handleOnSaveConfirm}
        />
      </Segment>
    </>
  );
}

CardPreAdminQueueWard.defaultProps = CardMedAdminQueueWardInitial

export default React.memo(CardPreAdminQueueWard);
