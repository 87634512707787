import WasmController from "react-lib/frameworks/WasmController";
import FormDataDetailByEncounter from "issara-sdk/apis/FormDataDetailByEncounter_apps_PTM";
import ClinicalTermList from "issara-sdk/apis/ClinicalTermList_core";
import ClinicalTermSetView from "issara-sdk/apis/ClinicalTermSetView_core";
import DischargePlanningSummaryDetail from "issara-sdk/apis/DischargePlanningSummaryDetail_apps_ADM";
import FormDataList from "issara-sdk/apis/FormDataList_apps_PTM";
import FormDataDetail from "issara-sdk/apis/FormDataDetail_apps_PTM";
import ReportMR1Print from "issara-sdk/apis/ReportMR1Print_apps_ADM";
import { el } from "date-fns/locale";

export type State = {
  DischaegeNurseSequence?: {
    sequenceIndex?: string | null;
  } | null;
  FormDataDischargePlanning?: any;
  FormDataDischargeTeaching?: any;
  FormDataDischargeNursing?: any;
  ClinicalTermPlanning?: any;
  ClinicalTermTeaching?: any;
  ClinicalTermNursing?: any;
  dischargePlanning?: any;
  selectedEncounter?: any;
  typeOptionsMethod?: any;
  typeOptionsRelative?: any;
  typeOptionKnowledge?: any;
  buttonLoadCheck?: Record<string, any>;
};

export const StateInitial: State = {
  DischaegeNurseSequence: null,
  FormDataDischargePlanning: [],
  FormDataDischargeTeaching: [],
  FormDataDischargeNursing: [],
  ClinicalTermPlanning: [],
  ClinicalTermTeaching: [],
  ClinicalTermNursing: [],
  dischargePlanning: {},
  typeOptionsMethod: [
    { key: 1, value: "-", text: "-" },
    { key: 2, value: "อธิบาย", text: "อธิบาย" },
    { key: 3, value: "สาธิต", text: "สาธิต" },
    { key: 4, value: "เปิดวิดีโอ", text: "เปิดวิดีโอ" },
    { key: 5, value: "แจกแผ่นพับ", text: "แจกแผ่นพับ" },
    { key: 6, value: "อื่น ๆ ระบุ", text: "อื่น ๆ ระบุ" },
  ],
  typeOptionsRelative: [
    { key: 1, value: "-", text: "-" },
    { key: 2, value: "บิดา", text: "บิดา" },
    { key: 3, value: "มารดา", text: "มารดา" },
    { key: 4, value: "บุตร", text: "บุตร" },
    { key: 5, value: "อื่น ๆ ระบุ", text: "อื่น ๆ ระบุ" },
  ],
  typeOptionKnowledge: [
    { key: 1, value: "-", text: "-" },
    { key: 2, value: "ความรู้ดี/ทำได้ดี", text: "ความรู้ดี/ทำได้ดี" },
    { key: 3, value: "ความรู้ปานกลาง/พอทำได้", text: "ความรู้ปานกลาง/พอทำได้" },
    { key: 4, value: "รู้น้อย/ทำได้น้อย", text: "รู้น้อย/ทำได้น้อย" },
    { key: 5, value: "ไม่ทราบ/ไม่เคยทำ", text: "ไม่ทราบ/ไม่เคยทำ" },
  ],
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: any };

export type Data = {
  division?: number;
  masterData?: any;
};

export const DataInitial = {};

type Handler = (controller: WasmController<State, Event, Data>, params?: any) => any;

export const Start: Handler = async (controller, params) => {
  const state = controller.getState();

  console.log("is Get GET Master every time ?");
  // Master data
  await controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["disc_plan1", {}],
        ["disc_plan2", {}],
        ["disc_plan4", {}],
        ["disc_plan5", {}],
        ["disc_plan6", {}],
        ["disc_teaching1", {}],
        ["disc_teaching2", {}],
        ["disc_teaching3", {}],
        ["disc_teaching4", {}],
        ["disc_teaching5", {}],
        ["disc_teaching6", {}],
        ["disc_teaching7", {}],
        ["disc_teaching8", {}],
        ["disc_nursing1", {}],
        ["disc_nursing2", {}],
        ["disc_nursing3", {}],
        ["disc_nursing4", {}],
        ["disc_nursing5", {}],
        ["disc_nursing6", {}],
      ],
    },
  });

  //   if (!state.DischaegeNurseSequence) return;
  if (!params?.action) return;
  if (params?.action === "Formdata") {
    const state = controller.getState();
    const masterData = controller.data?.masterData;

    const [itemPlan1, itemPlan2, itemPlan4, itemPlan5, itemPlan6, itemNursing2, itemNursing6] = [
      ...questionPlan,
      "disc_nursing2",
      "disc_nursing6",
    ].map((item: any) => {
      const fillterData = masterData?.[item].map((data: any) => ({
        answer: "",
        checked: false,
        ...data,
      }));

      return fillterData;
    });

    const itemTeach1 = masterData?.disc_teaching1.map((item: any) => ({
      id: item.key,
      name: item.text,
      ...defaultTeach,
    }));

    const itemTeach7 = masterData?.disc_teaching7.map((item: any) => ({
      id: item.key,
      name: item.text,
      ...defaultTeach,
    }));

    const [
      [dischargePlanRes, dischargePlanErr, dischargePlanNet],
      [dischargeTeachRes, dischargeTeachErr, dischargeTeachNet],
      [dischargeNurseRes, dischargeNurseErr, dischargeNurseNet],
      [dischargeSummaryRes, dischargeSummaryErr, dischargeSummaryNet],
    ] = await Promise.all([
      FormDataDetailByEncounter.retrieve({
        pk: state?.selectedEncounter.pk,
        params: { form_data_code: "discharge_planning" },
        apiToken: controller.apiToken,
        extra: {
          division: controller.data.division,
        },
      }),
      FormDataDetailByEncounter.retrieve({
        pk: state?.selectedEncounter.pk,
        params: { form_data_code: "discharge_teaching" },
        apiToken: controller.apiToken,
        extra: {
          division: controller.data.division,
        },
      }),
      FormDataDetailByEncounter.retrieve({
        pk: state?.selectedEncounter.pk,
        params: { form_data_code: "discharge_nursing" },
        apiToken: controller.apiToken,
        extra: {
          division: controller.data.division,
        },
      }),
      DischargePlanningSummaryDetail.retrieve({
        pk: state?.selectedEncounter.pk,
        apiToken: controller.apiToken,
      }),
    ]);

    // let next_appointments = [
    //   { date: "a", start_time: "b", end_time: "c", doctor_name: "d", division_name: "e" },
    //   { date: "a", start_time: "b", end_time: "c", doctor_name: "d", division_name: "e" },
    //   { date: "a", start_time: "b", end_time: "c", doctor_name: "d", division_name: "e" },
    //   { date: "a", start_time: "b", end_time: "c", doctor_name: "d", division_name: "e" },
    // ];

    // let nextAppointmentsData = (next_appointments || [])
    //   ?.map((item: any) => {
    //     return `วันที่ ${item.date} เวลานัด ${item.start_time} - ${item.end_time} แพทย์ ${item.doctor_name} แพทย์ ${item.division_name}`;
    //   })
    //   ?.join("\n");

    if (dischargePlanRes?.data && dischargeTeachRes?.data && dischargeNurseRes?.data) {
      let methodKey: any[] = StateInitial.typeOptionsMethod.map((items: any) => {
        return items.text;
      });

      let relativeKey: any[] = StateInitial.typeOptionsRelative.map((items: any) => {
        return items.text;
      });

      let knowledgeKey: any[] = StateInitial.typeOptionKnowledge.map((items: any) => {
        return items.text;
      });

      let mainKey = ["before", "first", "home", "method", "relative", "second"];

      const medAdditionKey = (mainData: any, items: any) => {
        if (items === "relative") {
          (mainData || []).forEach((datas: any) => {
            if (!relativeKey.includes(datas)) {
              return relativeKey.push(datas);
            }
          });
        } else if (items === "method") {
          (mainData || []).forEach((datas: any) => {
            if (!methodKey.includes(datas)) {
              return methodKey.push(datas);
            }
          });
        } else {
          (mainData || []).forEach((datas: any) => {
            if (!knowledgeKey.includes(datas)) {
              return knowledgeKey.push(datas);
            }
          });
        }
      };

      for (let dataDischargeTeach in Object(dischargeTeachRes?.data) || []) {
        mainKey.forEach((items: any) => {
          let mainData = dischargeTeachRes?.data?.[dataDischargeTeach]?.[`main_${items}`];
          medAdditionKey(mainData, items);

          if (dataDischargeTeach === "disc_teaching1" || dataDischargeTeach === "disc_teaching7") {
            let mainItem = dischargeTeachRes?.data?.[dataDischargeTeach]?.items;
            mainItem.forEach((values: any) => {
              mainKey.forEach((keys: any) => {
                let mainItemData = values?.[`sub_${items}`];
                medAdditionKey(mainItemData, keys);
              });
            });
          }
        });
      }

      const filterOption = (key: any) => {
        return key.map((items: any) => {
          return {
            key: items,
            text: items,
            value: items,
          };
        });
      };

      controller.setState({
        FormDataDischargePlanning: dischargePlanRes,
        FormDataDischargeTeaching: dischargeTeachRes,
        FormDataDischargeNursing: dischargeNurseRes,
        ClinicalTermPlanning: dischargePlanRes?.data,
        ClinicalTermTeaching: dischargeTeachRes?.data,
        ClinicalTermNursing: {
          ...dischargeNurseRes?.data,
          // disc_nursing5: {
          //   ...dischargeNurseRes?.data?.disc_nursing5,
          //   // ...(!dischargeNurseRes?.data?.disc_nursing5?.nextAppointments && {
          //   //   nextAppointments: nextAppointmentsData,
          //   }),
          // },
        },
        typeOptionsMethod: filterOption(methodKey),
        typeOptionsRelative: filterOption(relativeKey),
        typeOptionKnowledge: filterOption(knowledgeKey),
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_Save`]: "SUCCESS",
        },
      });
    } else {
      let planData = {
        disc_plan1: {
          code: "disc_plan1",
          id: 16,
          items: itemPlan1,
          name: "ปัญหาสุขภาพที่ต้องได้รับความช่วยเหลือ",
          no: 1,
        },
        disc_plan2: {
          code: "disc_plan2",
          id: 17,
          items: itemPlan2,
          name: "ปัญหาทางด้านครอบครัว สังคมและสิ่งแวดล้อม",
          no: 2,
        },
        disc_plan4: {
          code: "disc_plan4",
          id: 22,
          items: itemPlan4,
          name: "ผู้ดูแลผู้ป่วยหลังจำหน่ายออกจากโรงพยาบาล",
          no: 3,
        },
        disc_plan5: {
          code: "disc_plan5",
          id: 23,
          items: itemPlan5,
          name: "ครอบครัวผู้ป่วยมีความพร้อมให้การช่วยเหลือดูแลผู้ป่วยเมื่อจำหน่ายกลับบ้าน",
          no: 4,
        },
        disc_plan6: {
          code: "disc_plan6",
          id: 24,
          items: itemPlan6,
          name: "สรุป ผู้ป่วย/ครอบครัว จำเป็นต้องได้รับการดูแลต่อเนื่องที่บ้าน",
          no: 5,
        },
        question: questionPlan,
      };

      let teachData = {
        disc_teaching1: {
          id: 1005,
          items: [itemTeach1],
          name: "ความรู้เกี่ยวกับโรค",
          no: 1,
          ...defaultSetTeach,
        },
        disc_teaching2: {
          id: 1006,
          items: [],
          name: "Special care",
          no: 2,
          ...defaultSetTeach,
        },
        disc_teaching3: {
          id: 1007,
          items: [],
          name: "การป้องกัน/การส่งเสริม/การฟื้นฟูสุขภาพ",
          no: 3,
          ...defaultSetTeach,
        },
        disc_teaching4: {
          id: 1008,
          items: [],
          name: "อาการสำคัญที่ควรมาพบแพทย์ก่อนวันนัด",
          no: 4,
          ...defaultSetTeach,
        },
        disc_teaching5: {
          id: 1009,
          items: [],
          name: "การรับประทานอาหาร/อาการข้างเคียง Side effects",
          no: 5,
          ...defaultSetTeach,
        },
        disc_teaching6: {
          id: 1010,
          items: [],
          name: "อาหาร/นม",
          no: 6,
          ...defaultSetTeach,
        },
        disc_teaching7: {
          id: 1011,
          items: [itemTeach7],
          name: "Activity",
          no: 7,
          ...defaultSetTeach,
        },
        disc_teaching8: {
          id: 1012,
          items: [],
          name: "การมาตรวจตามนัด",
          no: 8,
          ...defaultSetTeach,
        },

        question: questionTeach,
      };

      let nurseData = {
        disc_nursing1: {
          code: "disc_nursing1",
          id: 1142,
          items: [],
          name: "สภาพผู้ป่วยก่อนจำหน่าย",
          no: 1,
        },
        disc_nursing2: {
          code: "disc_nursing2",
          id: 25,
          items: [itemNursing2],
          name: "อุปกรณ์การแพทย์ที่จำหน่ายพร้อมผู้ป่วย",
          no: 2,
        },
        disc_nursing3: {
          code: "disc_nursing3",
          id: 1002,
          items: [itemNursing2],
          name: "ปัญหาที่ต้องได้รับการดูแลต่อเนื่องที่บ้านและความช่วยเหลือที่ให้",
          no: 3,
        },
        disc_nursing4: {
          code: "disc_nursing4",
          id: 1003,
          items: [],
          name: "การจำหน่าย",
          no: 4,
        },
        disc_nursing5: {
          code: "disc_nursing5",
          id: 1004,
          items: [],
          name: "การนัดตรวจครั้งต่อไป",
          no: 5,
        },
        disc_nursing6: {
          code: "disc_nursing6",
          id: 26,
          items: [itemNursing6],
          name: "สิ่งที่ผู้ป่วยได้รับก่อนจำหน่าย",
          no: 6,
        },
        disc_nursing7: {
          code: "disc_nursing7",
          id: 1143,
          items: [],
          name: "จำหน่ายออกจากโรงพยาบาลโดย",
          no: 7,
        },

        question: questionNurse,
      };

      const [
        [clinicalPlanRes, clinicalPlanErr, clinicalPlanNet],
        [clinicalTeachRes, clinicalTeachErr, clinicalTeachNet],
        [clinicalNurseRes, clinicalNurseErr, clinicalNurseNet],
      ] = await Promise.all([
        ClinicalTermSetView.post({
          data: planData,
          apiToken: controller.apiToken,
          extra: {
            division: controller.data.division,
          },
        }),
        ClinicalTermSetView.post({
          data: teachData,
          apiToken: controller.apiToken,
          extra: {
            division: controller.data.division,
          },
        }),
        ClinicalTermSetView.post({
          data: nurseData,
          apiToken: controller.apiToken,
          extra: {
            division: controller.data.division,
          },
        }),
      ]);

      if (clinicalPlanRes && clinicalTeachRes && clinicalNurseRes) {
        let newClinicalTermPlanning = {
          ...clinicalPlanRes,
          disc_plan1: {
            has_problem: "N",
            ...clinicalPlanRes?.disc_plan1,
          },
          disc_plan2: {
            has_problem: "N",
            ...clinicalPlanRes?.disc_plan2,
          },
          disc_plan6: {
            has_problem: "N",
            ...clinicalPlanRes?.disc_plan6,
          },
        };

        controller.setState({
          ClinicalTermPlanning: newClinicalTermPlanning,
          ClinicalTermTeaching: clinicalTeachRes,
          ClinicalTermNursing: {
            ...clinicalNurseRes,
            // disc_nursing5: {
            //   ...clinicalNurseRes?.disc_nursing5,
            //   nextAppointments: nextAppointmentsData,
            // },
          },
          typeOptionsMethod: StateInitial.typeOptionsMethod,
          typeOptionsRelative: StateInitial.typeOptionsRelative,
          typeOptionKnowledge: StateInitial.typeOptionKnowledge,
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.card}_Save`]: "SUCCESS",
          },
        });
      } else {
        controller.setState({
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.card}_Save`]: "ERROR",
          },
        });
      }
    }
  } else if (params?.action === "GetDischargePlanning") {
    const state = controller.getState();

    const [resp, error, netw] = await DischargePlanningSummaryDetail.retrieve({
      pk: state?.selectedEncounter.pk,
      apiToken: controller.apiToken,
    });

    if (!error) {
      controller.setState({
        dischargePlanning: resp,
      });
    }
  } else if (params?.action === "Save") {
    const state = controller.getState();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "LOADING",
      },
    });

    if (
      state.FormDataDischargePlanning?.form_data_id &&
      state.FormDataDischargeTeaching?.form_data_id &&
      state.FormDataDischargeNursing?.form_data_id
    ) {
      const formPlanUpdate = await FormDataDetail.update({
        pk: state.FormDataDischargePlanning?.form_data_id,
        data: {
          action: "SAVE",
          data: {
            ...state.ClinicalTermPlanning,
            question: questionPlan,
          },
          encounter: state?.selectedEncounter.pk,
          form_code: "discharge_planning",
          form_data_id: state.FormDataDischargePlanning?.form_data_id,
          form_data_status_name: state.FormDataDischargePlanning?.form_data_status_name,
          form_data_status_value: state.FormDataDischargePlanning?.form_data_status_value,
          form_name: "Discharge Planning (Nurse)",
          form_version: "1.0",
        },
        apiToken: controller.apiToken,
        extra: {
          division: controller.data.division,
        },
      });

      const formTeachUpdate = await FormDataDetail.update({
        pk: state.FormDataDischargeTeaching?.form_data_id,
        data: {
          action: "SAVE",
          data: {
            disc_teaching1: state.ClinicalTermTeaching?.disc_teaching1,
            disc_teaching2: state.ClinicalTermTeaching?.disc_teaching2,
            disc_teaching3: state.ClinicalTermTeaching?.disc_teaching3,
            disc_teaching4: state.ClinicalTermTeaching?.disc_teaching4,
            disc_teaching5: state.ClinicalTermTeaching?.disc_teaching5,
            disc_teaching6: state.ClinicalTermTeaching?.disc_teaching6,
            disc_teaching7: state.ClinicalTermTeaching?.disc_teaching7,
            disc_teaching8: state.ClinicalTermTeaching?.disc_teaching8,

            question: questionTeach,
          },
          encounter: state?.selectedEncounter.pk,
          form_code: "discharge_teaching",
          form_data_id: state.FormDataDischargeTeaching?.form_data_id,
          form_data_status_name: state.FormDataDischargeTeaching?.form_data_status_name,
          form_data_status_value: state.FormDataDischargeTeaching?.form_data_status_value,
          form_name: "Discharge Teaching (Nurse)",
          form_version: "1.0",
        },
        apiToken: controller.apiToken,
        extra: {
          division: controller.data.division,
        },
      });

      const formNurseUpdate = await FormDataDetail.update({
        pk: state.FormDataDischargeNursing?.form_data_id,
        data: {
          action: "SAVE",
          data: {
            disc_nursing1: state.ClinicalTermNursing?.disc_nursing1,
            disc_nursing2: state.ClinicalTermNursing?.disc_nursing2,
            disc_nursing3: state.ClinicalTermNursing?.disc_nursing3,
            disc_nursing4: state.ClinicalTermNursing?.disc_nursing4,
            disc_nursing5: state.ClinicalTermNursing?.disc_nursing5,
            disc_nursing6: state.ClinicalTermNursing?.disc_nursing6,
            disc_nursing7: state.ClinicalTermNursing?.disc_nursing7,
            question: questionNurse,
          },
          encounter: state?.selectedEncounter.pk,
          form_code: "discharge_nursing",
          form_data_id: state.FormDataDischargeNursing?.form_data_id,
          form_data_status_name: state.FormDataDischargeNursing?.form_data_status_name,
          form_data_status_value: state.FormDataDischargeNursing?.form_data_status_value,
          form_name: "Discharge Nursing (Nurse)",
          form_version: "1.0",
        },
        apiToken: controller.apiToken,
        extra: {
          division: controller.data.division,
        },
      });

      if (formPlanUpdate?.[0] && formTeachUpdate?.[0] && formNurseUpdate?.[0]) {
        Start(controller, { action: "Formdata", card: params.card });
      } else {
        controller.setState({
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.card}_${params.action}`]: "LOADING",
          },
        });
      }
    } else {
      const formPlan = await FormDataList.create({
        data: {
          action: "SAVE",
          data: {
            disc_plan1: state.ClinicalTermPlanning?.disc_plan1,
            disc_plan2: state.ClinicalTermPlanning?.disc_plan2,
            disc_plan4: state.ClinicalTermPlanning?.disc_plan4,
            disc_plan5: state.ClinicalTermPlanning?.disc_plan5,
            disc_plan6: state.ClinicalTermPlanning?.disc_plan6,
            question: questionPlan,
          },
          encounter: state?.selectedEncounter.pk,
          form_code: "discharge_planning",
          form_data_id: null,
          form_data_status_name: null,
          form_data_status_value: null,
          form_name: "Discharge Planning (Nurse)",
          form_version: "1.0",
        },
        apiToken: controller.apiToken,
        extra: {
          division: controller.data.division,
        },
      });

      const formTeach = await FormDataList.create({
        data: {
          action: "SAVE",
          data: {
            disc_teaching1: state.ClinicalTermTeaching?.disc_teaching1,
            disc_teaching2: state.ClinicalTermTeaching?.disc_teaching2,
            disc_teaching3: state.ClinicalTermTeaching?.disc_teaching3,
            disc_teaching4: state.ClinicalTermTeaching?.disc_teaching4,
            disc_teaching5: state.ClinicalTermTeaching?.disc_teaching5,
            disc_teaching6: state.ClinicalTermTeaching?.disc_teaching6,
            disc_teaching7: state.ClinicalTermTeaching?.disc_teaching7,
            disc_teaching8: state.ClinicalTermTeaching?.disc_teaching8,

            question: questionTeach,
          },
          encounter: state?.selectedEncounter.pk,
          form_code: "discharge_teaching",
          form_data_id: null,
          form_data_status_name: null,
          form_data_status_value: null,
          form_name: "Discharge Teaching (Nurse)",
          form_version: "1.0",
        },
        apiToken: controller.apiToken,
        extra: {
          division: controller.data.division,
        },
      });

      const formNurse = await FormDataList.create({
        data: {
          action: "SAVE",
          data: {
            disc_nursing1: state.ClinicalTermNursing?.disc_nursing1,
            disc_nursing2: state.ClinicalTermNursing?.disc_nursing2,
            disc_nursing3: state.ClinicalTermNursing?.disc_nursing3,
            disc_nursing4: state.ClinicalTermNursing?.disc_nursing4,
            disc_nursing5: state.ClinicalTermNursing?.disc_nursing5,
            disc_nursing6: state.ClinicalTermNursing?.disc_nursing6,
            disc_nursing7: state.ClinicalTermNursing?.disc_nursing7,
            question: questionNurse,
          },
          encounter: state?.selectedEncounter.pk,
          form_code: "discharge_nursing",
          form_data_id: null,
          form_data_status_name: null,
          form_data_status_value: null,
          form_name: "Discharge Nursing (Nurse)",
          form_version: "1.0",
        },
        apiToken: controller.apiToken,
        extra: {
          division: controller.data.division,
        },
      });

      if (formPlan?.[0] && formTeach?.[0] && formNurse?.[0]) {
        Start(controller, { action: "Formdata", card: params.card });
      } else {
        controller.setState({
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.card}_${params.action}`]: "LOADING",
          },
        });
      }
    }
  } else if (params.action === "printDischargeSummary") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.cardName]: "LOADING",
      },
    });

    const [data, error] = await ReportMR1Print.retrieve({
      params: { encounter: state?.selectedEncounter.id, pdf: true },
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });

    if (data?.pdf_b64data) {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.cardName]: "SUCCESS",
        },
      });

      const pdfWindow = window.open("_blank", `data:application/pdf;base64,${data.pdf_b64data}`);
      pdfWindow?.document?.write(
        "<iframe width='100%' height='100%' src='data:application/pdf;base64," +
          data.pdf_b64data +
          "'></iframe>"
      );

      return;
    }

    if (data?.error?.message) {
      alert(data?.error?.message);
    }
    if (error) {
      console.warn("error", error);
    }

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.cardName]: "ERROR",
      },
    });
  }
};

const questionPlan = ["disc_plan1", "disc_plan2", "disc_plan4", "disc_plan5", "disc_plan6"];

const questionTeach = [
  "disc_teaching1",
  "disc_teaching2",
  "disc_teaching3",
  "disc_teaching4",
  "disc_teaching5",
  "disc_teaching6",
  "disc_teaching7",
  "disc_teaching8",
];

const questionNurse = [
  "disc_nursing1",
  "disc_nursing2",
  "disc_nursing3",
  "disc_nursing4",
  "disc_nursing5",
  "disc_nursing6",
  "disc_nursing7",
];

const defaultTeach = {
  sub_before: "",
  sub_before_user: "",
  sub_first: "",
  sub_first_user: "",
  sub_home: "",
  sub_home_user: "",
  sub_method: "",
  sub_method_note: "",
  sub_relative: "",
  sub_relative_name: "",
  sub_relative_note: "",
  sub_second: "",
  sub_second_user: "",
};

const defaultSetTeach = {
  main_before: "",
  main_before_user: "",
  main_first: "",
  main_first_user: "",
  main_home: "",
  main_home_user: "",
  main_method: "",
  main_method_note: "",
  main_relative: "",
  main_relative_name: "",
  main_relative_note: "",
  main_second: "",
  main_second_user: "",
};
