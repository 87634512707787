import WasmController from "react-lib/frameworks/WasmController";
import ShippingOrderPerformedList from "issara-sdk/apis/ShippingOrderPerformedList_apps_PRX";
import ShippingOrderPerformedDetail from "issara-sdk/apis/ShippingOrderPerformedDetail_apps_PRX";
import ShippingOrderDrugView from "issara-sdk/apis/ShippingOrderDrugView_apps_PRX";
import PatientAddressDetail from "issara-sdk/apis/PatientAddressDetail_core"

export type State = {
  // Seq
  TransportationSequence?: {
    sequenceIndex?: string | null;
    selectedDrugOrder?: {
      id: number | null;
      encounterType: string | null;
      encounterNo: string | null;
      delivery_datetime: string | null;
      delivery_received_datetime: string | null;
      tracking_number: string | null;
      delivery_type: string | null;
      // deliveryStatusOptions: any[] | null;
      delivery_status: string | null;
      shipping_company: number | null;
      telephoneNo: string | null;
      additionAddress: string | null;
      delivery_address: string | null;
      addressName: string | null;
      name: string | null;
      parcel_id?: string | null;
    } | null;
    drugOrders?: any[] | null;
    shippingOrders?: any[] | null;
    listCheckbox?: any[] | null;
    isSaving?: boolean | null;
    popUpLoading?: boolean | null;
    saveStatus?: string | null;
  } | null;
  selectedEncounter?: any;
  selectedDivision?: any;
  selectedPatient?: any;
  masterOptions?: any | null;
};

export const StateInitial: State = {
  TransportationSequence: null,
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  user?: number;
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const Start: Handler = async (controller, params) => {
  console.log("Transportation Start: ", params);
  let state = controller.getState();
  if (!state.TransportationSequence) return;

  // Master data
  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [["shippingCompany", {}]],
    },
  });

  let urlParams = {
    hn: state.selectedPatient?.hn,
  };
  const [shippingOrderDrug, shippingOrderDrugError] =
    await ShippingOrderDrugView.list({
      params: urlParams,
      apiToken: controller.apiToken,
    });

  if (shippingOrderDrugError) {
    console.log("Drug queue error fetching");
    controller.setState({
      TransportationSequence: {
        ...state.TransportationSequence,
        drugOrderQueue: [],
        listCheckbox: [],
        searchLoading: false,
      },
    });
    return;
  }

  let drugOrders = shippingOrderDrug?.items;
  console.log("shippingOrderDrug drugOrders: ", drugOrders);
  console.log("params.encounter_id: ", params.encounter_id);
  if (params.encounter_id) {
    drugOrders = drugOrders.filter(
      (item: any) => item.encounter_id === Number(params.encounter_id)
    );
    console.log("Filter drugOrders: ", drugOrders);
  }

  controller.setState({
    TransportationSequence: {
      ...state.TransportationSequence,
      sequenceIndex: "Action",
      drugOrders: drugOrders,
    },
  });
};

export const Action: Handler = async (controller, params) => {
  let state = controller.getState();
  if (params.action === "getTransport") {
    // getTransport params.selectedRow
    let drugOrder = state.TransportationSequence?.drugOrders?.find(
      (item: any) => item.id === params.selectedRow
    );

    const [res] = await ShippingOrderPerformedDetail.retrieve({
      apiToken: controller.apiToken,
      pk: drugOrder?.shipping_order?.id,
      extra: {
        division: controller.data.division,
      },
    });

    if (res) {
      controller.setState({
        TransportationSequence: {
          ...state.TransportationSequence,
          selectedDrugOrder: res,
        },
      });
    }

    // console.log("err: ", err);
    // console.log("res: ", res);
    // // console.log('res: ', res);
    // // console.log('err: ', err);
    // // console.log('network.response: ', network.response);
    // // console.log('network: ', network.response.status);
    // if (
    //   !err &&
    //   !res?.items?.some((item: any) =>
    //     item?.drug_orders?.includes(params.selectedRow)
    //   )
    // ) {
    //   // ไม่มี สร้าง

    //   // console.log(
    //   //   "      state.masterOptions?.shippingCompany?.[0]: ",
    //   //   state.masterOptions?.shippingCompany?.[0]
    //   // );
    //   // if (state.masterOptions?.shippingCompany?.[0]) {
    //   //   let [create, createError] = await ShippingOrderPerformedList.create({
    //   //     apiToken: controller.apiToken,
    //   //     extra: {
    //   //       division: controller.data.division,
    //   //     },
    //   //     data: {
    //   //       encounter: drugOrder?.encounter_id,
    //   //       shipping_company: state.masterOptions?.shippingCompany?.[0]?.key,
    //   //       ...(params.selectedRow && {
    //   //         drug_orders: [params.selectedRow],
    //   //       }),
    //   //     },
    //   //   });
    //   //   // TODO: ทำต่อ
    //   // }
    // } else {
    //   state = controller.getState();
    //   // find
    //   let shippingOrder = res?.items?.find(
    //     (item: any) => item.id === drugOrder?.shipping_order?.id
    //   );

    //   controller.setState({
    //     TransportationSequence: {
    //       ...state.TransportationSequence,
    //       selectedDrugOrder: shippingOrder,
    //     },
    //   });
    // }
    // if (network.response.status === 404) {
    //   //
    //   let [shippingCompany , shippingCompanyError] = await ShippingCompanyList.list({
    //     apiToken: controller.apiToken,
    //     extra: {
    //       division: controller.data.division,
    //     }
    //   })
    //   console.log('shippingCompany: ', shippingCompany);
    //   // let [create, createError] = await ShippingOrderPerformedList.create({
    //   //   apiToken: controller.apiToken,
    //   //   extra: {
    //   //     division: controller.data.division,
    //   //   }
    //   // })
    // }
    // if (!err && res) {
    //   controller.setState({
    //     TransportationSequence: {
    //       ...state.TransportationSequence,

    //     }
    //   })
    // }
  } else if (params.action === "save") {
    let state = controller.getState();
    controller.setState({
      TransportationSequence: {
        ...state.TransportationSequence,
        isSaving: true,
        saveStatus: "PENDING",
      },
    });

    const [res, err, network] = await ShippingOrderPerformedDetail.patch({
      pk: state.TransportationSequence?.selectedDrugOrder?.id,

      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
      data: {
        ...(state.TransportationSequence?.selectedDrugOrder
          ?.tracking_number && {
          tracking_number:
            state.TransportationSequence?.selectedDrugOrder?.tracking_number,
        }),
        ...(state.TransportationSequence?.selectedDrugOrder
          ?.shipping_company && {
          shipping_company:
            state.TransportationSequence?.selectedDrugOrder?.shipping_company,
        }),
        ...(state.TransportationSequence?.selectedDrugOrder
          ?.delivery_status && {
          delivery_status:
            state.TransportationSequence?.selectedDrugOrder?.delivery_status,
        }),
        ...(state.TransportationSequence?.selectedDrugOrder
          ?.delivery_address && {
          delivery_address:
            state.TransportationSequence?.selectedDrugOrder?.delivery_address,
        }),
      },
    });

    if (res) {
      await controller.handleEvent({
        message: "RunSequence",
        params: { action: "getTransport", selectedRow: params.selectedRow },
      });
    }
    // Duplicate code ###
    controller.setState(
      {
        TransportationSequence: {
          ...state.TransportationSequence,
          isSaving: false,
          saveStatus: res ? "SUCCESS" : err ? "ERROR" : "",
        },
      },
      () => {
        state = controller.getState();
        setTimeout(() => {
          controller.setState({
            TransportationSequence: {
              ...state.TransportationSequence,
              saveStatus: "",
            },
          });
          Action(controller, {
            action: "orderDrug",
            encounter_id: params.encounter_id,
          });
        }, 2000);
      }
    );
    // Duplicate code ###
  } else if (params.action === "orderDrug") {
    let state = controller.getState();
    let urlParams = {
      hn: state.selectedPatient?.hn,
    };

    const [shippingOrderDrug, shippingOrderDrugError] =
      await ShippingOrderDrugView.list({
        params: urlParams,
        apiToken: controller.apiToken,
      });

    let drugOrders = shippingOrderDrug?.items;
    if (params.encounter_id) {
      drugOrders = drugOrders.filter(
        (item: any) => item.encounter_id === Number(params.encounter_id)
      );
      console.log("Filter drugOrders: ", drugOrders);
    }

    controller.setState({
      TransportationSequence: {
        ...state.TransportationSequence,
        sequenceIndex: "Action",
        drugOrders: drugOrders,
        popUpLoading: false,
      },
    });
  } else if (params.action === "multiTracking") {
    let state = controller.getState();
    controller.setState({
      TransportationSequence: {
        ...state.TransportationSequence,
        sequenceIndex: "Action",
        popUpLoading: true,
      },
    });

    if (params.newTrckingList?.length > 0) {
      let [create, createError] = await ShippingOrderPerformedList.create({
        apiToken: controller.apiToken,
        extra: {
          division: controller.data.division,
        },
        data: {
          encounter: params.encounter_id,
          shipping_company: params.shippingCompany,
          ...(params.newTrckingList && {
            drug_orders: params.newTrckingList,
          }),
          ...(params.trackingNumber && {
            tracking_number: params.trackingNumber,
          }),
        },
      });
    }

    if (params.updateTrckingList?.length > 0) {
      const promiseArr = params.updateTrckingList.map((item: any) => {
        return ShippingOrderPerformedDetail.patch({
          pk: item,

          apiToken: controller.apiToken,
          extra: {
            division: controller.data.division,
          },
          data: {
            ...(params.trackingNumber && {
              tracking_number: params.trackingNumber,
            }),
            ...(params.shippingCompany && {
              shipping_company: params.shippingCompany,
            }),
          },
        });
      });

      await Promise.all(promiseArr);
    }

    setTimeout(() => {
      Action(controller, {
        action: "orderDrug",
        encounter_id: params.encounter_id,
      });
    }, 1000);
  } else if (params.action === "multiUpdateStatus") {
    let state = controller.getState();
    controller.setState({
      TransportationSequence: {
        ...state.TransportationSequence,
        sequenceIndex: "Action",
        popUpLoading: true,
      },
    });

    const promiseArr = (state.TransportationSequence?.listCheckbox || []).map(
      (item: any) => {
        return ShippingOrderPerformedDetail.patch({
          pk: item.shipping_order.id,

          apiToken: controller.apiToken,
          extra: {
            division: controller.data.division,
          },
          data: {
            ...(params.statusOrder && {
              delivery_status: params.statusOrder,
            }),
          },
        });
      }
    );

    await Promise.all(promiseArr);

    controller.setState({
      TransportationSequence: {
        ...state.TransportationSequence,
        sequenceIndex: "Action",
        popUpLoading: false,
      },
    });

    setTimeout(() => {
      Action(controller, {
        action: "orderDrug",
        encounter_id: params.encounter_id,
      });
    }, 1000);
  }
};
