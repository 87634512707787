import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Icon,
  FormGroup,
  FormField,
  Button,
  Dropdown,
  Input
} from 'semantic-ui-react'

const CardEditMedOrderUX = (props: any) => {
    return(
      <div
        style={{width:"100%", padding: "10px",  height: "100%"}}>
        <div
          style={{display: props.showHeader? "flex": "none", justifyContent: "space-between", alignItems: "center"}}>
          
          <div
            style={{fontSize: "1.15rem", fontWeight: "bold", padding: "2.5px 0", color: "#0072BC"}}>
            แก้ไขคำสั่งยา
          </div>
          <div
            style={{cursor: "pointer"}}>
            
            <Icon
              className="red"
              name="close"
              onClick={props.onClose}>
            </Icon>
          </div>
        </div>
        <div
          className="ui divider"
          style={{display: props.showHeader? "": "none"}}>
          
        </div>
        <div
          className="ui form"
          style={{ position: "relative", padding: props.showHeader? "0 0.5rem": ""}}>
          
          <div
            style={{display: props.openCalCulator ? "inline" : "none", position:"absolute", width:"80%", height:"180px", right:"16px", top:"-35rem"}}>
            {props.pediatricUI}
          </div>
          <FormGroup
            style={{marginBottom: "0.5rem"}}>
            <FormField
              className="--override-disabled --grey"
              width={6}>
              <FormField
                className="required">
                <label>
                  Drug
                </label>
              </FormField>
              <div
                style={{width: "100%"}}>
                {props.DrugSearchBox}
              </div>
            </FormField>
            <FormField
              style={{paddingLeft:0}}>
              <label>
                {"\u00a0"}
              </label>
              <Button
                color={props.hasFactSheet ? "blue" : undefined}
                disabled={!props.hasFactSheet}
                fluid={true}
                onClick={props.openFactSheet}
                style={{ whiteSpace: "nowrap"}}>
                DRUG FACT
              </Button>
            </FormField>
            <FormField
              style={{paddingLeft:0}}>
              <label>
                {"\u00a0"}
              </label>
              <div>
                {props.calculatorIcon}
              </div>
            </FormField>
            <FormField
              style={{paddingLeft:0}}
              width={3}>
              <label>
                PRN
              </label>
              <Dropdown
                allowAdditions={true}
                className="inline-label"
                clearable={true}
                disabled={!props.selectedDrug }
                fluid={true}
                onAddItem={props.onAdditionPrn}
                onChange={props.ChangeDrugPrn}
                options={props.prnOptions}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.drugPrn? props.drugPrn : ""}>
              </Dropdown>
            </FormField>
            <FormField
              style={{ display: props.drugPermission?.config_TPD_ENABLE_IV_COMPATIBLE ? "" : "none",paddingLeft:0}}>
              <label>
                {"\u00a0"}
              </label>
              <Button
                basic={!props.modSolventSelectionConfig?.activeButtonSolvent}
                color={props.modSolventSelectionConfig?.showButtonSolvent ?"blue": "grey"}
                disabled={!props.modSolventSelectionConfig?.showButtonSolvent }
                fluid={true}
                onClick={props.onOpenModSolventSelection}
                style={{cursor: props.modSolventSelectionConfig?.activeButtonSolvent || typeof props.modSolventSelectionConfig?.admixtureIndex === "number"?"auto":"pointer"}}>
                SOLVENT
              </Button>
            </FormField>
            <FormField
              style={{display: props.selectedDrug?.original?.is_taper_dose ? "": "none",  fontWeight: "bold",paddingLeft:0 }}>
              <label>
                {"\u00a0"}
              </label>
              <div>
                {props.buttonTaperSchedule}
              </div>
            </FormField>
            <FormField
              style={{flex:1}}>
            </FormField>
            <FormField
              style={{ display: props.drugPermission?.config_TPD_ENABLE_PTC_DISPENSE  ? "" : "none",paddingLeft:0}}>
              <label>
                {"\u00a0"}
              </label>
              <Button
                color="blue"
                disabled={!props.enabledButtonDispense}
                onClick={props.onClickDispense}>
                ยาแบ่งจ่าย
              </Button>
            </FormField>
            <FormField
              style={{paddingLeft:0}}>
              <label>
                {"\u00a0"}
              </label>
              <Button
                disabled={props.statusRequest ? false : true}
                icon="file"
                onClick={props.clearDrugOrderForm}
                style={{display: props.showHeader? "none": ""}}>
              </Button>
            </FormField>
          </FormGroup>
          <FormGroup
            style={{marginBottom: "0.5rem", paddingRight: "0.25rem"}}>
            <FormField
              className={`required noPadding${props.errorMessage &&!props.drugDose  ? " error" :""}${props.hideDrugForm?.dose ? " hidden": ""}`}
              width={3}>
              <label>
                Dose
              </label>
              <div>
                {props.doseInput}
              </div>
            </FormField>
            <FormField
              className={`required noPadding${props.errorMessage &&!props.selectedDrugUnitId ? " error" :""}${props.hideDrugForm?.unit ? " hidden": ""}`}
              width={3}>
              <label>
                Unit
              </label>
              <Dropdown
                className="inline-label"
                disabled={!props.selectedDrug}
                fluid={true}
                id="cb-drugUnit"
                onChange={props.changeDrugUnit}
                options={props.drugUnits}
                search={true}
                selection={true}
                value={props.selectedDrugUnitId}>
              </Dropdown>
            </FormField>
            <FormField
              className={`required noPadding${props.errorMessage &&!props.selectedDrugRouteId ? " error" :""}${props.hideDrugForm?.route ? " hidden": ""}`}
              width={3}>
              <label>
                Route
              </label>
              <Dropdown
                className="inline-label"
                disabled={!props.selectedDrug}
                fluid={true}
                id="cb-drugRoute"
                onChange={props.changeDrugRoute}
                options={props.drugRoutes}
                search={true}
                selection={true}
                value={props.selectedDrugRouteId}>
              </Dropdown>
            </FormField>
            <FormField
              className={`required noPadding${props.errorMessage &&!props.selectedDrugSiteId? " error" :"" }${props.hideDrugForm?.site ? " hidden": ""}`}
              width={3}>
              <label>
                Site
              </label>
              <Dropdown
                className="inline-label"
                disabled={!props.selectedDrug}
                fluid={true}
                id="cb-drugSite"
                onChange={props.changeDrugSite}
                options={props.drugSites}
                search={true}
                selection={true}
                value={props.selectedDrugSiteId}>
              </Dropdown>
            </FormField>
            <FormField
              className={`required noPadding${props.errorMessage &&!props.selectedDrugFrequencyId? " error" :"" }${props.hideDrugForm?.frequency ? " hidden": ""}`}
              width={3}>
              <label>
                Frequency
              </label>
              <Dropdown
                className="inline-label"
                disabled={!props.selectedDrug}
                fluid={true}
                id="cb-drugFrequency"
                onChange={props.changeDrugFrequency}
                options={props.drugFrequencies}
                search={true}
                selection={true}
                value={props.selectedDrugFrequencyId}>
              </Dropdown>
            </FormField>
            <FormField
              className={`required noPadding${props.hideDrugForm?.method ? " hidden": ""}`}
              style={{display: "none"}}
              width={3}>
              <label>
                Method
              </label>
              <Dropdown
                className="inline-label"
                disabled={!props.selectedDrug}
                fluid={true}
                id="cb-drugMedthod"
                onChange={props.changeDrugMethod}
                options={props.drugMethods}
                search={true}
                selection={true}
                value={props.selectedDrugMethodId}>
              </Dropdown>
            </FormField>
            <FormField
              className={`required noPadding${props.errorMessage &&!props.drugQty? " error" :""}`}
              width={3}>
              <label>
                Qty
              </label>
              <Input
                disabled={!props.selectedDrug}
                fluid={true}
                id="txt-drugQty"
                onChange={props.changeDrugQty}
                style={{opacity: 1}}
                value={props.drugQty ? props.drugQty : ""}>
              </Input>
            </FormField>
            <FormField
              className={`${props.noRequiredDuration ? "":"required "}noPadding${props.hideDrugForm?.duration ? " hidden": ""}`}
              width={3}>
              <label>
                Duration (วัน)
              </label>
              <Input
                disabled={!props.selectedDrug}
                fluid={true}
                id="txt-drugDuration"
                onChange={props.changeDrugDuration}
                readOnly={props.readOnlyDuration || false}
                style={{opacity: 1}}
                value={props.drugDuration ? props.drugDuration: ""}>
              </Input>
            </FormField>
            <FormField
              className="noPadding"
              style={{flex: props.drugFormFlexStyle ? 1 : "", minWidth: "150px"}}>
            </FormField>
          </FormGroup>
          <FormGroup
            className="--override-disabled --grey"
            style={{display: props.isRequireRate? "": "none",marginBottom: "0.5rem"}}>
            <FormField
              width={3}>
              <div
                style={{marginBottom: "-1px"}}>
                {props.dripRateLabel}
              </div>
              <Input
                disabled={props.modSolventSelectionConfig?.dripRate !== "rate"}
                fluid={true}
                name="mixture_iv_rate"
                onChange={props.onChangeModData}
                onKeyDown={(e: any)=> ["-","e"].includes(e.key) && e.preventDefault()}
                type="number"
                value={props.modSolventSelectionConfig?.mixture_iv_rate}>
              </Input>
            </FormField>
            <FormField
              width={3}>
              <label>
                {"\u00a0"}
              </label>
              <Dropdown
                className="inline-label"
                clearable={true}
                disabled={props.modSolventSelectionConfig?.dripRate !== "rate"}
                fluid={true}
                name="mixture_iv_rate_unit"
                onChange={props.onChangeModData}
                options={props.modSolventSelectionConfig?.ivRateOptions || []}
                search={true}
                selection={true}
                value={props.modSolventSelectionConfig?.mixture_iv_rate_unit}>
              </Dropdown>
            </FormField>
            <FormField
              width={3}>
              <div
                style={{marginBottom: "-1px"}}>
                {props.dripDurationLabel}
              </div>
              <Input
                disabled={props.modSolventSelectionConfig?.dripRate === "rate"}
                fluid={true}
                name="drug_drip_in"
                onChange={props.onChangeModData}
                onKeyDown={(e: any)=> ["-","e"].includes(e.key) && e.preventDefault()}
                type="number"
                value={props.modSolventSelectionConfig?.drug_drip_in}>
              </Input>
            </FormField>
            <FormField
              width={3}>
              <label>
                {"\u00a0"}
              </label>
              <Dropdown
                className="inline-label"
                clearable={true}
                disabled={props.modSolventSelectionConfig?.dripRate === "rate"}
                fluid={true}
                name="drug_drip_in_unit"
                onChange={props.onChangeModData}
                options={props.modSolventSelectionConfig?.dripInOptions || []}
                search={true}
                selection={true}
                value={props.modSolventSelectionConfig?.drug_drip_in_unit}>
              </Dropdown>
            </FormField>
            <FormField
              width={(Object.values(props.hideDrugForm||{}).filter(Boolean).length - 2)*3}>
            </FormField>
            <FormField
              style={{minWidth: "150px"}}>
            </FormField>
          </FormGroup>
          <FormGroup
            style={{display:props.errorPediatricData? "": "none", marginBottom: "0.25rem"}}>
            <FormField>
              <div>
                {props.pediatricWarning}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            style={{ display: props.hideDrugDescription ? "none" : "",marginBottom: "0.5rem", paddingRight: "0.25rem"}}>
            <FormField
              width={14}>
              <label>
                Description
              </label>
              <Input
                disabled={!props.selectedDrug}
                fluid={true}
                id="txt-drugDescription"
                onChange={props.changeDrugDescription}
                style={{opacity: 1}}
                value={props.drugDescription || ""}>
              </Input>
            </FormField>
            <FormField
              style={{minWidth: "150px"}}>
              <label>
                {"\u00a0"}
              </label>
              <div
                style={{display: props.hideSolvent? "": "none"}}>
                {props.buttonAddToOrder}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            style={{display: props.hideSolvent? "none": "",marginBottom: "0.5rem"}}>
            <FormField
              style={{ border: "1px solid rgba(34,36,38,.15)",borderRight: "none", paddingTop: "1rem", paddingBottom: "1rem" }}
              width={6}>
              <label>
                Solvent
              </label>
              <div>
                {props.searchBoxSolvent}
              </div>
            </FormField>
            <FormField
              style={{ border: "1px solid rgba(34,36,38,.15)",borderRight: "none", paddingTop: "1rem", paddingBottom: "1rem" , borderLeft: "none"}}
              width={2}>
              <label>
                Volume (For mixing)
              </label>
              <Input
                fluid={true}
                name="volume"
                onChange={props.onChangeModData}
                onKeyDown={(e: any)=> ["-","e"].includes(e.key) && e.preventDefault()}
                type="number"
                value={props.modSolventSelectionConfig?.volume  ?? ""}>
              </Input>
            </FormField>
            <FormField
              style={{ border: "1px solid rgba(34,36,38,.15)",borderRight: "none", paddingTop: "1rem", paddingBottom: "1rem" , borderLeft: "none"}}
              width={2}>
              <label>
                Unit (of volume)
              </label>
              <Dropdown
                className="inline-label"
                fluid={true}
                name="unit"
                onChange={props.onChangeModData}
                options={props.unitOptions}
                search={true}
                selection={true}
                value={props.modSolventSelectionConfig?.unit || ""}>
              </Dropdown>
            </FormField>
            <FormField
              style={{ border: "1px solid rgba(34,36,38,.15)",borderRight: "none", paddingTop: "1rem", paddingBottom: "1rem" , borderLeft: "none"}}
              width={2}>
              <label
                style={{minWidth: "max-content"}}>
                Total Qty (per dispense)
              </label>
              <Input
                fluid={true}
                name="quantity"
                onChange={props.onChangeModData}
                onKeyDown={(e: any)=> ["-","e"].includes(e.key) && e.preventDefault()}
                type="number"
                value={props.modSolventSelectionConfig?.quantity ?? ""}>
              </Input>
            </FormField>
            <FormField
              style={{ border: "1px solid rgba(34,36,38,.15)",borderLeft: "none", paddingTop: "1rem", paddingBottom: "1rem", position: "relative", flex:1, marginRight: props.showHeader?"0.75rem":"" }}>
              <div
                style={{position: "absolute", top: "0", right: "0.25rem", display: typeof props.modSolventSelectionConfig?.admixtureIndex === "number"?"none":""}}>
                
                <Icon
                  className="red small"
                  link={true}
                  name="close"
                  onClick={props.onCloseButtonSolvent}>
                </Icon>
              </div>
            </FormField>
            <FormField
              style={{display: props.hideSolvent? "none": "", margin: "auto", minWidth: "150px"}}>
              <label
                style={{margin: 0}}>
                {"\u00a0"}
              </label>
              <div>
                {props.buttonAddToOrder}
              </div>
            </FormField>
          </FormGroup>
        </div>
      </div>
    )
}

CardEditMedOrderUX.displayName = "CardEditMedOrderUX";
export default React.memo(CardEditMedOrderUX)

export const screenPropsDefault = {}

/* Date Time : Wed Nov 20 2024 12:23:12 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width:\"100%\", padding: \"10px\",  height: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.showHeader? \"flex\": \"none\", justifyContent: \"space-between\", alignItems: \"center\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        },
        "style": {
          "type": "code",
          "value": "{display: props.showHeader? \"\": \"none\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "แก้ไขคำสั่งยา"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.15rem\", fontWeight: \"bold\", padding: \"2.5px 0\", color: \"#0072BC\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{cursor: \"pointer\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "Icon",
      "parent": 4,
      "props": {
        "className": {
          "type": "value",
          "value": "red"
        },
        "name": {
          "type": "value",
          "value": "close"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{ position: \"relative\", padding: props.showHeader? \"0 0.5rem\": \"\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormGroup",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.5rem\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "FormGroup",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.5rem\", paddingRight: \"0.25rem\"}"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "FormField",
      "parent": 8,
      "props": {
        "className": {
          "type": "value",
          "value": "--override-disabled --grey"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "FormField",
      "parent": 12,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "label",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": "Drug"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "code",
          "value": "props.DrugSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "FormField",
      "parent": 8,
      "props": {
        "style": {
          "type": "code",
          "value": "{paddingLeft:0}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "FormField",
      "parent": 8,
      "props": {
        "style": {
          "type": "code",
          "value": "{paddingLeft:0}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "FormField",
      "parent": 8,
      "props": {
        "style": {
          "type": "code",
          "value": "{paddingLeft:0}"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "FormField",
      "parent": 8,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: props.drugPermission?.config_TPD_ENABLE_IV_COMPATIBLE ? \"\" : \"none\",paddingLeft:0}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "FormField",
      "parent": 8,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: props.selectedDrug?.original?.is_taper_dose ? \"\": \"none\",  fontWeight: \"bold\",paddingLeft:0 }"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "label",
      "parent": 16,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "Button",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": "DRUG FACT"
        },
        "color": {
          "type": "code",
          "value": "props.hasFactSheet ? \"blue\" : undefined"
        },
        "disabled": {
          "type": "code",
          "value": "!props.hasFactSheet"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "props.openFactSheet"
        },
        "style": {
          "type": "code",
          "value": "{ whiteSpace: \"nowrap\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "label",
      "parent": 17,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 17,
      "props": {
        "children": {
          "type": "code",
          "value": "props.calculatorIcon"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "label",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "PRN"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "label",
      "parent": 19,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "Button",
      "parent": 19,
      "props": {
        "basic": {
          "type": "code",
          "value": "!props.modSolventSelectionConfig?.activeButtonSolvent"
        },
        "children": {
          "type": "value",
          "value": "SOLVENT"
        },
        "color": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.showButtonSolvent ?\"blue\": \"grey\""
        },
        "disabled": {
          "type": "code",
          "value": "!props.modSolventSelectionConfig?.showButtonSolvent "
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "props.onOpenModSolventSelection"
        },
        "style": {
          "type": "code",
          "value": "{cursor: props.modSolventSelectionConfig?.activeButtonSolvent || typeof props.modSolventSelectionConfig?.admixtureIndex === \"number\"?\"auto\":\"pointer\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "label",
      "parent": 20,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonTaperSchedule"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "FormField",
      "parent": 8,
      "props": {
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "FormField",
      "parent": 8,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: props.drugPermission?.config_TPD_ENABLE_PTC_DISPENSE  ? \"\" : \"none\",paddingLeft:0}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "FormField",
      "parent": 8,
      "props": {
        "style": {
          "type": "code",
          "value": "{paddingLeft:0}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "label",
      "parent": 32,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "Button",
      "parent": 32,
      "props": {
        "children": {
          "type": "value",
          "value": "ยาแบ่งจ่าย"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "!props.enabledButtonDispense"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickDispense"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "label",
      "parent": 33,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "Button",
      "parent": 33,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.statusRequest ? false : true"
        },
        "icon": {
          "type": "value",
          "value": "file"
        },
        "onClick": {
          "type": "code",
          "value": "props.clearDrugOrderForm"
        },
        "style": {
          "type": "code",
          "value": "{display: props.showHeader? \"none\": \"\"}"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "FormField",
      "parent": 9,
      "props": {
        "className": {
          "type": "code",
          "value": "`required noPadding${props.errorMessage &&!props.drugDose  ? \" error\" :\"\"}${props.hideDrugForm?.dose ? \" hidden\": \"\"}`"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "FormField",
      "parent": 9,
      "props": {
        "className": {
          "type": "code",
          "value": "`required noPadding${props.errorMessage &&!props.selectedDrugUnitId ? \" error\" :\"\"}${props.hideDrugForm?.unit ? \" hidden\": \"\"}`"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "FormField",
      "parent": 9,
      "props": {
        "className": {
          "type": "code",
          "value": "`required noPadding${props.errorMessage &&!props.selectedDrugRouteId ? \" error\" :\"\"}${props.hideDrugForm?.route ? \" hidden\": \"\"}`"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "FormField",
      "parent": 9,
      "props": {
        "className": {
          "type": "code",
          "value": "`required noPadding${props.errorMessage &&!props.selectedDrugSiteId? \" error\" :\"\" }${props.hideDrugForm?.site ? \" hidden\": \"\"}`"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "FormField",
      "parent": 9,
      "props": {
        "className": {
          "type": "code",
          "value": "`required noPadding${props.errorMessage &&!props.selectedDrugFrequencyId? \" error\" :\"\" }${props.hideDrugForm?.frequency ? \" hidden\": \"\"}`"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "FormField",
      "parent": 9,
      "props": {
        "className": {
          "type": "code",
          "value": "`required noPadding${props.hideDrugForm?.method ? \" hidden\": \"\"}`"
        },
        "style": {
          "type": "code",
          "value": "{display: \"none\"}"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "FormField",
      "parent": 9,
      "props": {
        "className": {
          "type": "code",
          "value": "`required noPadding${props.errorMessage &&!props.drugQty? \" error\" :\"\"}`"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "FormField",
      "parent": 9,
      "props": {
        "className": {
          "type": "code",
          "value": "`${props.noRequiredDuration ? \"\":\"required \"}noPadding${props.hideDrugForm?.duration ? \" hidden\": \"\"}`"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "FormField",
      "parent": 9,
      "props": {
        "className": {
          "type": "value",
          "value": "noPadding"
        },
        "style": {
          "type": "code",
          "value": "{flex: props.drugFormFlexStyle ? 1 : \"\", minWidth: \"150px\"}"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "label",
      "parent": 38,
      "props": {
        "children": {
          "type": "value",
          "value": "Dose"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "label",
      "parent": 39,
      "props": {
        "children": {
          "type": "value",
          "value": "Unit"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "label",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": "Route"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "label",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": "Site"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "label",
      "parent": 42,
      "props": {
        "children": {
          "type": "value",
          "value": "Frequency"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "label",
      "parent": 43,
      "props": {
        "children": {
          "type": "value",
          "value": "Method"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "label",
      "parent": 44,
      "props": {
        "children": {
          "type": "value",
          "value": "Qty"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "label",
      "parent": 45,
      "props": {
        "children": {
          "type": "value",
          "value": "Duration (วัน)"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 38,
      "props": {
        "children": {
          "type": "code",
          "value": "props.doseInput"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "Dropdown",
      "parent": 39,
      "props": {
        "className": {
          "type": "value",
          "value": "inline-label"
        },
        "disabled": {
          "type": "code",
          "value": "!props.selectedDrug"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "cb-drugUnit"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeDrugUnit"
        },
        "options": {
          "type": "code",
          "value": "props.drugUnits"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.selectedDrugUnitId"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "Dropdown",
      "parent": 40,
      "props": {
        "className": {
          "type": "value",
          "value": "inline-label"
        },
        "disabled": {
          "type": "code",
          "value": "!props.selectedDrug"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "cb-drugRoute"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeDrugRoute"
        },
        "options": {
          "type": "code",
          "value": "props.drugRoutes"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.selectedDrugRouteId"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "Dropdown",
      "parent": 41,
      "props": {
        "className": {
          "type": "value",
          "value": "inline-label"
        },
        "disabled": {
          "type": "code",
          "value": "!props.selectedDrug"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "cb-drugSite"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeDrugSite"
        },
        "options": {
          "type": "code",
          "value": "props.drugSites"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.selectedDrugSiteId"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "Dropdown",
      "parent": 42,
      "props": {
        "className": {
          "type": "value",
          "value": "inline-label"
        },
        "disabled": {
          "type": "code",
          "value": "!props.selectedDrug"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "cb-drugFrequency"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeDrugFrequency"
        },
        "options": {
          "type": "code",
          "value": "props.drugFrequencies"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.selectedDrugFrequencyId"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "Dropdown",
      "parent": 43,
      "props": {
        "className": {
          "type": "value",
          "value": "inline-label"
        },
        "disabled": {
          "type": "code",
          "value": "!props.selectedDrug"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "cb-drugMedthod"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeDrugMethod"
        },
        "options": {
          "type": "code",
          "value": "props.drugMethods"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.selectedDrugMethodId"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "Input",
      "parent": 44,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.selectedDrug"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "txt-drugQty"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeDrugQty"
        },
        "style": {
          "type": "code",
          "value": "{opacity: 1}"
        },
        "value": {
          "type": "code",
          "value": "props.drugQty ? props.drugQty : \"\""
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "Input",
      "parent": 45,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.selectedDrug"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "txt-drugDuration"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeDrugDuration"
        },
        "readOnly": {
          "type": "code",
          "value": "props.readOnlyDuration || false"
        },
        "style": {
          "type": "code",
          "value": "{opacity: 1}"
        },
        "value": {
          "type": "code",
          "value": "props.drugDuration ? props.drugDuration: \"\""
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "FormGroup",
      "parent": 6,
      "props": {
        "className": {
          "type": "value",
          "value": "--override-disabled --grey"
        },
        "style": {
          "type": "code",
          "value": "{display: props.isRequireRate? \"\": \"none\",marginBottom: \"0.5rem\"}"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "FormField",
      "parent": 66,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "FormField",
      "parent": 66,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "FormField",
      "parent": 66,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "FormField",
      "parent": 66,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "label",
      "parent": 70,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "label",
      "parent": 68,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 81,
      "name": "Input",
      "parent": 69,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.dripRate === \"rate\""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "drug_drip_in"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeModData"
        },
        "onKeyDown": {
          "type": "code",
          "value": "(e: any)=> [\"-\",\"e\"].includes(e.key) && e.preventDefault()"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.drug_drip_in"
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "Dropdown",
      "parent": 68,
      "props": {
        "className": {
          "type": "value",
          "value": "inline-label"
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.dripRate !== \"rate\""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "mixture_iv_rate_unit"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeModData"
        },
        "options": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.ivRateOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.mixture_iv_rate_unit"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 83,
      "name": "Dropdown",
      "parent": 70,
      "props": {
        "className": {
          "type": "value",
          "value": "inline-label"
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.dripRate === \"rate\""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "drug_drip_in_unit"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeModData"
        },
        "options": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.dripInOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.drug_drip_in_unit"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 91,
      "name": "FormGroup",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: props.hideSolvent? \"none\": \"\",marginBottom: \"0.5rem\"}"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 92,
      "name": "FormField",
      "parent": 91,
      "props": {
        "style": {
          "type": "code",
          "value": "{ border: \"1px solid rgba(34,36,38,.15)\",borderRight: \"none\", paddingTop: \"1rem\", paddingBottom: \"1rem\" }"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 93,
      "name": "FormField",
      "parent": 91,
      "props": {
        "style": {
          "type": "code",
          "value": "{ border: \"1px solid rgba(34,36,38,.15)\",borderRight: \"none\", paddingTop: \"1rem\", paddingBottom: \"1rem\" , borderLeft: \"none\"}"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 94,
      "name": "FormField",
      "parent": 91,
      "props": {
        "style": {
          "type": "code",
          "value": "{ border: \"1px solid rgba(34,36,38,.15)\",borderRight: \"none\", paddingTop: \"1rem\", paddingBottom: \"1rem\" , borderLeft: \"none\"}"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 95,
      "name": "FormField",
      "parent": 91,
      "props": {
        "style": {
          "type": "code",
          "value": "{ border: \"1px solid rgba(34,36,38,.15)\",borderRight: \"none\", paddingTop: \"1rem\", paddingBottom: \"1rem\" , borderLeft: \"none\"}"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": null,
      "id": 96,
      "name": "label",
      "parent": 92,
      "props": {
        "children": {
          "type": "value",
          "value": "Solvent"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "label",
      "parent": 93,
      "props": {
        "children": {
          "type": "value",
          "value": "Volume (For mixing)"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": null,
      "id": 98,
      "name": "label",
      "parent": 94,
      "props": {
        "children": {
          "type": "value",
          "value": "Unit (of volume)"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 99,
      "name": "label",
      "parent": 95,
      "props": {
        "children": {
          "type": "value",
          "value": "Total Qty (per dispense)"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 101,
      "name": "Dropdown",
      "parent": 94,
      "props": {
        "className": {
          "type": "value",
          "value": "inline-label"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "unit"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeModData"
        },
        "options": {
          "type": "code",
          "value": "props.unitOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.unit || \"\""
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 102,
      "name": "Input",
      "parent": 93,
      "props": {
        "disabled": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "volume"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeModData"
        },
        "onKeyDown": {
          "type": "code",
          "value": "(e: any)=> [\"-\",\"e\"].includes(e.key) && e.preventDefault()"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.volume  ?? \"\""
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 103,
      "name": "Input",
      "parent": 95,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "quantity"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeModData"
        },
        "onKeyDown": {
          "type": "code",
          "value": "(e: any)=> [\"-\",\"e\"].includes(e.key) && e.preventDefault()"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.quantity ?? \"\""
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 104,
      "name": "FormField",
      "parent": 91,
      "props": {
        "style": {
          "type": "code",
          "value": "{ border: \"1px solid rgba(34,36,38,.15)\",borderLeft: \"none\", paddingTop: \"1rem\", paddingBottom: \"1rem\", position: \"relative\", flex:1, marginRight: props.showHeader?\"0.75rem\":\"\" }"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 105,
      "name": "FormField",
      "parent": 91,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: props.hideSolvent? \"none\": \"\", margin: \"auto\", minWidth: \"150px\"}"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": null,
      "id": 106,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonAddToOrder"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 109,
      "name": "FormGroup",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: props.hideDrugDescription ? \"none\" : \"\",marginBottom: \"0.5rem\", paddingRight: \"0.25rem\"}"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 110,
      "name": "FormField",
      "parent": 109,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "14"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": null,
      "id": 111,
      "name": "label",
      "parent": 110,
      "props": {
        "children": {
          "type": "value",
          "value": "Description"
        },
        "className": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 112,
      "name": "Input",
      "parent": 110,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.selectedDrug"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "txt-drugDescription"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeDrugDescription"
        },
        "style": {
          "type": "code",
          "value": "{opacity: 1}"
        },
        "value": {
          "type": "code",
          "value": "props.drugDescription || \"\""
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 113,
      "name": "FormField",
      "parent": 109,
      "props": {
        "style": {
          "type": "code",
          "value": "{minWidth: \"150px\"}"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": null,
      "id": 114,
      "name": "label",
      "parent": 113,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": null,
      "id": 115,
      "name": "div",
      "parent": 113,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonAddToOrder"
        },
        "style": {
          "type": "code",
          "value": "{display: props.hideSolvent? \"\": \"none\"}"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 116,
      "name": "FormGroup",
      "parent": 6,
      "props": {
        "style": {
          "type": "code",
          "value": "{display:props.errorPediatricData? \"\": \"none\", marginBottom: \"0.25rem\"}"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 117,
      "name": "FormField",
      "parent": 116,
      "props": {
      },
      "seq": 117,
      "void": false
    },
    {
      "from": null,
      "id": 118,
      "name": "div",
      "parent": 117,
      "props": {
        "children": {
          "type": "code",
          "value": "props.pediatricWarning"
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": null,
      "id": 120,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "code",
          "value": "props.pediatricUI"
        },
        "style": {
          "type": "code",
          "value": "{display: props.openCalCulator ? \"inline\" : \"none\", position:\"absolute\", width:\"80%\", height:\"180px\", right:\"16px\", top:\"-35rem\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 121,
      "name": "div",
      "parent": 104,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\", top: \"0\", right: \"0.25rem\", display: typeof props.modSolventSelectionConfig?.admixtureIndex === \"number\"?\"none\":\"\"}"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 122,
      "name": "Icon",
      "parent": 121,
      "props": {
        "className": {
          "type": "value",
          "value": "red small"
        },
        "link": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "close"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCloseButtonSolvent"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": null,
      "id": 123,
      "name": "label",
      "parent": 105,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": "{margin: 0}"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 129,
      "name": "Input",
      "parent": 67,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.dripRate !== \"rate\""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "mixture_iv_rate"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeModData"
        },
        "onKeyDown": {
          "type": "code",
          "value": "(e: any)=> [\"-\",\"e\"].includes(e.key) && e.preventDefault()"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.modSolventSelectionConfig?.mixture_iv_rate"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": null,
      "id": 130,
      "name": "div",
      "parent": 69,
      "props": {
        "children": {
          "type": "code",
          "value": "props.dripDurationLabel"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"-1px\"}"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 131,
      "name": "div",
      "parent": 67,
      "props": {
        "children": {
          "type": "code",
          "value": "props.dripRateLabel"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"-1px\"}"
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": null,
      "id": 132,
      "name": "div",
      "parent": 92,
      "props": {
        "children": {
          "type": "code",
          "value": "props.searchBoxSolvent"
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 133,
      "name": "FormField",
      "parent": 66,
      "props": {
        "width": {
          "type": "code",
          "value": "(Object.values(props.hideDrugForm||{}).filter(Boolean).length - 2)*3"
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 134,
      "name": "FormField",
      "parent": 66,
      "props": {
        "style": {
          "type": "code",
          "value": "{minWidth: \"150px\"}"
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 135,
      "name": "Dropdown",
      "parent": 18,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "className": {
          "type": "value",
          "value": "inline-label"
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "!props.selectedDrug "
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAdditionPrn"
        },
        "onChange": {
          "type": "code",
          "value": "props.ChangeDrugPrn"
        },
        "options": {
          "type": "code",
          "value": "props.prnOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.drugPrn? props.drugPrn : \"\""
        }
      },
      "seq": 135,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": true,
  "name": "CardEditMedOrderUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
