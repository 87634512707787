import WasmController from 'react-lib/frameworks/WasmController';
import CheckDoctorFeeOrderAPIView from "issara-sdk/apis/CheckDoctorFeeOrderAPIView_apps_DFC";

export type State = 
  {
  }

export const StateInitial: State = 
  {
  }

export type Event = 
  { message: "checkDoctorFeeOrderWithEMR", params: any }

export type Data = 
  {
    division?: number,
  }

export const DataInitial = 
  {
  }

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any 

export const checkDoctorFeeOrderWithEMR: Handler = async (controller, emrId) => {
  return await CheckDoctorFeeOrderAPIView.get({
    params: { medical_record: emrId },
    apiToken: controller.apiToken
  });
}
