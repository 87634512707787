import React, {
  FC,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Label, Button, Icon } from "semantic-ui-react";
import CardBodyOutline from "./CardBodyOutline";
import CardFootOutline from "./CardFootOutline";
import { useIntl } from "react-intl";

export interface CardMultiSelectPositionHandle {
  getData(): { position: any };
}

const CardMultiSelectPosition: FC<CardMultiSelectPositionViewDataType> = forwardRef(
  (props, ref) => {
    const intl = useIntl();
    const [position, setPosition] = useState<any>(defaultData.position);
    const [newItem, setNewItem] = useState("");
    const [autoComplete, setAutoComplete] = useState([]);
    const [mode, setMode] = useState("");
    const [editId, setEditId] = useState(-1);

    useEffect(() => {
      if (props.viewData.position) {
        setPosition(props.viewData.position);
      } else {
        setPosition([]);
      }
    }, [props.viewData, props.dropDownOptions]);

    useEffect(() => {
      if (newItem.length > 1) {
        const candidates = bodyDefaultOption.positionOptions.filter((item) =>
          item.text.toLowerCase().includes(newItem.toLowerCase())
        );
        console.log(candidates.length);
        if (candidates.length > 0) {
          setAutoComplete(candidates);
        } else {
          setAutoComplete([]);
        }
      } else {
        setAutoComplete([]);
      }
    }, [newItem]);

    useImperativeHandle(ref, () => ({
      getData: () => {
        return { position: position };
      },
    }));

    const handleEvent = ({
      message,
      params,
    }: {
      message: string;
      params: any;
    }) => {
      if (props.readonly) {
        return 
      }
      if (message === "click" && params.name === "position") {
        console.log(params.value, mode);
        console.log(position[params.value]);
        if (mode === "Remove") {
          console.log("Remove");
          const newPosition = position.filter(
            (item, index) => index !== params.value
          );
          setPosition(newPosition);
          setMode("");
        } else if (mode === "Edit") {
          setEditId(params.value);
        } else if (mode === "Add") {
          let newPosition = null
          if (props.isBody) {
            newPosition = Object.assign(
              [],
              [...position, { ...bodyDefaultOption.positionOptions[params.value] }]
            );
          } else {
            newPosition = Object.assign(
              [],
              [...position, { ...footDefaultOption.positionOptions[params.value] }]
            );
          }
          
          setPosition(newPosition);
          setMode("");
        }
      }
      if (message === "move" && editId >= 0) {
        console.log(params);
        let newPosition;
        if (params.name === "up") {
          newPosition = position.map((item, index) => {
            if (index === editId) {
              item.y = item.y - 10;
              return item;
            } else {
              return item;
            }
          });
        } else if (params.name === "down") {
          newPosition = position.map((item, index) => {
            if (index === editId) {
              item.y = item.y + 10;
              return item;
            } else {
              return item;
            }
          });
        } else if (params.name === "left") {
          newPosition = position.map((item, index) => {
            if (index === editId) {
              item.x = item.x - 10;
              return item;
            } else {
              return item;
            }
          });
        } else if (params.name === "right") {
          newPosition = position.map((item, index) => {
            if (index === editId) {
              item.x = item.x + 10;
              return item;
            } else {
              return item;
            }
          });
        }
        setPosition(newPosition);
      } else if (message === "remove" && params.name === "position") {
        const newPosition = position.filter(
          (item, index) => index !== params.value
        );
        setPosition(newPosition);
      }
    };

    return (
      <div
        style={{
          display: "flex",
          height: "400px",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "100%", minHeight: "75px", maxHeight: "75px",
            overflowY: "scroll",
            border: "solid black 1px",
            backgroundColor: "white",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {position.map((item, index) => (
            <Label key={index} style={{height: "30px"}}>
              {item.text}
              <Icon
                name="close"
                onClick={(e) => {
                  if (!props.readonly) {
                    console.log(item);
                    handleEvent({
                      message: "remove",
                      params: { name: "position", value: index },
                    });
                  }
                }}
              />
            </Label>
          ))}
          <input
            value={newItem}
            onChange={ (e) => { 
              if (!props.readonly) {
                setNewItem(e.target.value) 
              }
            }}
            type="text"
            style={{
              border: "none",
              outline: "none",
              float: "left",
              whiteSpace: "nowrap",
            }}
          />
        </div>
        <div style={{ position: "relative", width: "100%" }}>
          <div style={{ position: "absolute", width: "100%", top: "0px" }}>
            {autoComplete.length > 0 &&
              autoComplete.map((item, index) => (
                <div
                  key={index}
                  style={{
                    width: "100%",
                    backgroundColor: "white",
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                  onClick={(e) => {
                    setPosition([...position, item]);
                    setNewItem("");
                  }}
                >
                  {item.text}
                </div>
              ))}
          </div>

          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {mode === "Edit" && (
              <Icon
                onClick={(e) => {
                  handleEvent({ message: "move", params: { name: "up" } });
                }}
                name="angle up"
                size="big"
              />
            )}

            {mode === "Edit" && (
              <Icon
                onClick={(e) => {
                  handleEvent({ message: "move", params: { name: "down" } });
                }}
                name="angle down"
                size="big"
              />
            )}

            {mode === "Edit" && (
              <Icon
                onClick={(e) => {
                  handleEvent({ message: "move", params: { name: "left" } });
                }}
                name="angle left"
                size="big"
              />
            )}

            {mode === "Edit" && (
              <Icon
                onClick={(e) => {
                  handleEvent({ message: "move", params: { name: "right" } });
                }}
                name="angle right"
                size="big"
              />
            )}
            {
              !props.readonly ? (
                <>
                  <Button
                    onClick={(e) => {
                      if (mode === "Add") setMode("");
                      else setMode("Add");
                    }}
                    color={mode === "Add" ? "green" : "grey"}
                  >
                    Add
                  </Button>
                  <Button
                    onClick={(e) => {
                      if (mode === "Edit") {
                        setMode("");
                        setEditId(-1);
                      } else setMode("Edit");
                    }}
                    color={mode === "Edit" ? "green" : "grey"}
                  >
                    Edit
                  </Button>
                  <Button
                    onClick={(e) => {
                      if (mode === "Remove") setMode("");
                      else setMode("Remove");
                    }}
                    color={mode === "Remove" ? "green" : "grey"}
                  >
                    Remove
                  </Button>
                </>
              ) : null 
            }
          </div>

          <div style={{ width: "100%", textAlign: "right" }}>
            {mode === "Edit" ? (
              <div>{intl.formatMessage({ id: "กรุณาเลือกตำแหน่งที่ต้องการแก้ไข" })}</div>
            ) : mode === "Remove" ? (
              <div>{intl.formatMessage({ id: "กรุณาเลือกตำแหน่งที่ต้องการลบ" })}</div>
            ) : (
              <div>&nbsp;</div>
            )}
          </div>

          { props.isBody ? (
            <CardBodyOutline
              width={400}
              height={400}
              viewBox="0 80 450 500"
              position={position}
              onEvent={handleEvent}
              editId={editId}
              mode={mode}
              positionOptions={bodyDefaultOption.positionOptions}
              languageUX={props.languageUX}
            />
          ) : (
            <>
            <CardFootOutline
              width={400}
              height={500}
              viewBox="-100 -20 450 500"
              position={position}
              onEvent={handleEvent}
              editId={editId}
              mode={mode}
              positionOptions={footDefaultOption.positionOptions}
              languageUX={props.languageUX}
            />
            </>
          )}
        </div>
      </div>
    );
  }
);

export interface CardMultiSelectPositionViewDataType {
  dropDownOptions: {
    positionOptions: any[];
  };
  viewData: {
    position: any[];
  };
  isBody: boolean;
  readonly: boolean;
}

const bodyDefaultOption = {
  positionOptions: [
{text: "frontalis", x: 101, y: 155},
{text: "alaeque nasi", x: 106, y: 170},
{text: "mentalis", x: 110, y: 185},
{text: "platysma", x: 110, y: 200},
{text: "scalenus anterior", x: 104, y: 200},
{text: "scalenus medius", x: 117, y: 200},
{text: "scalenus posterior", x: 117, y: 200},
{text: "sternocleidomastoid", x: 104, y: 202},
{text: "subclavius", x: 124, y: 220},
{text: "splenius capitis", x: 358, y: 200},
{text: "splenius cervicis", x: 358, y: 210},
{text: "aryepiglotticus", x:130, y: 160},
{text: "auricularis", x:130, y: 165},
{text: "buccinator", x:122, y: 184},
{ text :"constrictor of pharynx -inferior", x: 104, y: 210},
{ text :"constrictor of pharynx -middle", x: 118, y: 205},
{ text :"constrictor of pharynx -superior", x: 110, y: 204},
{ text :"corrugator supercilii", x: 104, y: 167},
{ text :"cricothyroid", x: 110, y: 208},
{ text :"depressor anguli oris", x: 103, y: 190},
{ text :"depressor labii inferioris", x: 108, y: 190},
{ text :"digastric", x: 110, y: 195},
{ text :"digastric (anterior view)", x: 350, y: 195},
{ text :"frontalis", x: 115, y: 155},
{ text :"genioglossus", x: 110, y: 198},
{ text :"geniohyoid", x: 110, y: 202},
{ text :"hyoglossus", x: 110, y: 189},
{ text :"inferior oblique ", x: 104, y: 173},
{ text :"inferior rectus", x: 104, y: 175},
{ text :"intrinsic muscles of tongue", x: 110, y: 185},
{ text :"lateral cricoarytenoid", x: 110, y: 206},
{ text :"lateral pterygoid", x: 97, y: 175},
{ text :"lateral rectus", x: 102, y: 170},
{ text :"levator anguli oris", x: 105, y: 189},
{ text :"levator labii superioris", x: 104, y: 176},
{ text :"levator labii superioris", x: 104, y: 176},
{ text :"alaeque nasi", x: 107, y: 180},
{ text :"levator palpebrae superioris", x: 104, y: 169},
{ text :"levator veli palatini", x: 335, y: 178},
{ text :"longus capitis", x: 357, y: 184},
{ text :"longus colli", x: 345, y: 187},
{ text :"masseter", x: 102, y: 180},
{ text :"medial pterygoid", x: 102, y: 185},
{ text :"medial rectus", x: 102, y: 172},
{ text :"mentalis", x: 110, y: 196},
{ text :"m. uvulae", x: 110, y: 185},
{ text :"mylohyoid", x: 110, y: 188},
{ text :"nasalis", x: 110, y: 177},
{ text :"oblique arytenoid", x: 110, y: 199},
{ text :"obliquus capitis inferior", x: 340, y: 187},
{ text :"obliquus capitis superior", x: 345, y: 185},
{ text :"omohyoid", x: 110, y: 201},
{ text :"orbicularis oculi", x: 118, y: 170},
{ text :"orbicularis oris", x: 115, y: 179},
{ text :"palatoglossus", x: 111, y: 182},
{ text :"palatopharyngeus", x: 111, y: 183},
{ text :"platysma", x: 102, y: 200},
{ text :"posterior cricoarytenoid", x: 108, y: 200},
{ text :"procerus", x: 110, y: 170},
{ text :"rectus capitus anterior", x: 347, y: 195},
{ text :"rectus capitus lateralis", x: 350, y: 195},
{ text :"rectus capitus posterior major", x: 343, y: 195},
{ text :"rectus capitus posterior minor", x: 343, y: 195},
{ text :"risorius", x: 104, y: 180},
{ text :"salpingopharyngeus", x: 98, y: 180},
{ text :"scalenus anterior", x: 104, y: 199},
{ text :"scalenus medius", x: 102, y: 199},
{ text :"scalenus minimus", x: 90, y: 205},
{ text :"scalenus posterior", x: 90, y: 210},
{ text :"splenius capitis", x: 355, y: 200},
{ text :"splenius cervicis", x: 355, y: 205},
{ text :"stapedius", x: 93, y: 178},
{ text :"sternocleidomastoid", x: 98, y: 199},
{ text :"sternohyoid", x: 116, y: 199},
{ text :"sternothyroid", x: 110, y: 205},
{ text :"styloglossus", x: 110, y: 180},
{ text :"stylohyoid", x: 110, y: 181},
{ text :"stylohyoid (anterior view)", x: 350, y: 206},
{ text :"stylopharyngeus", x: 350, y: 190},
{ text :"superior oblique ", x: 102, y: 170},
{ text :"superior rectus", x: 102, y: 170},
{ text :"temporalis", x: 102, y: 170},
{ text :"temporoparietalis", x: 95, y: 172},
{ text :"tensor tympani", x: 95, y: 171},
{ text :"tensor veli palatini", x: 350, y: 175},
{ text :"thyro-arytenoid & vocalis", x: 110, y: 180},
{ text :"thyro-epiglotticus", x: 110, y: 210},
{ text :"thyrohyoid", x: 110, y: 209},
{ text :"transverse arytenoid", x: 114, y: 208},
{ text :"zygomaticus major", x: 103, y: 175},
{ text :"zygomaticus minor", x: 103, y: 177},

{text: "rotator cuff", x: 78, y: 220},
{text: "subscapularis", x: 78, y: 225},
{text: "supraspinatus", x: 74, y: 217},
{text: "teres minor",x: 327, y: 248},
{text: "teres major",x: 322, y: 250},
{text: "triceps", x: 70, y: 250},
{text: "deltoid", x: 65, y: 230},
{text: "biceps brachii", x: 62, y: 257},
{text: "brachialis", x: 52, y: 260},
{text: "brachioradialis", x: 52, y: 280},
{text: "flexor carpi radialis ",x: 290, y: 290},
{text: "flexor carpi ulnaris  ",x: 290, y: 300},
{text: "flexor digiti minimi brevis (hand) " ,x: 284,y: 330},
{text: "flexor digitorum brevis " ,x: 105,y: 500},
{text: "flexor digitorum profundus " ,x: 298,y: 300},
{text: "flexor digitorum superficialis " ,x: 290,y: 300},
{text: "flexor pollicis brevis " ,x: 270,y: 330},
{text: "flexor pollicis longus " ,x: 288,y: 300},
{text: "palmaris brevis " ,x: 277,y: 336},
{text: "palmaris longus " ,x: 290,y: 300},
{text: "pronator quadratus", x: 49,y: 304},
{text: "pronator teres", x: 49,y: 280},
{text: "extensor carpi radialis brevis " ,x: 287,y: 298},
{text: "extensor carpi radialis longus " ,x: 290,y: 280},
{text: "extensor carpi ulnaris " ,x: 290,y: 298},
{text: "extensor digiti minimi " ,x: 290,y: 290},
{text: "extensor digitorum" ,x: 292,y: 292},
{text: "extensor indicis " ,x: 285,y: 310},
{text: "extensor pollicis brevis " ,x: 286,y: 299},
{text: "extensor pollicis longus " ,x: 285,y: 315},
{text: "supinator" ,x: 298,y: 280},
{text: "abductor digiti minimi  " ,x: 290,y: 335},
{text: "abductor pollicis brevis " ,x: 268,y: 328},
{text: "abductor pollicis longus " ,x: 270,y: 320},
{text: "adductor pollicis " ,x: 270,y: 333},
{text: "interossei -DI/PI" ,x: 275,y: 333},
{text: "lumbricals" ,x: 276,y: 340},
{text: "opponens digiti minimi" ,x: 280,y: 340},
{text: "opponens pollicis " ,x: 275,y: 320},
{text: "pectoralis major " ,x: 130,y: 320},
{text: "pectoralis minor", x: 85,y: 230},
{text: "intercostals external", x: 90,y: 260},
{text: "intercostals innermost " ,x: 100,y: 260},
{text: "intercostals internal " ,x: 120,y: 260},
{text: "serratus anterior" ,x: 80,y: 260},
{text: "serratus posterior inferior " ,x: 120,y: 290},
{text: "serratus posterior superior" ,x: 380,y: 220},
{text: "Transversus Thoracis" ,x: 105,y: 260},
{text: "Subcostals" ,x: 100,y: 260},
{text: "external oblique abdominis", x: 85,y: 290},
{text: "internal oblique abdominis", x: 90,y: 290},
{text: "psoas major", x: 95,y: 310},
{text: "psoas minor", x: 95,y: 300},
{text: "pyramidalis" ,x: 110,y: 314},
{text: "quadratus lumborum" ,x: 120,y: 308},
{text: "rectus abdominis" ,x: 120,y: 290},
{text: "transversus abdominis" ,x: 100,y: 290},
{text: "psoas major /minor", x: 95,y: 310},
{text: "adductor group" ,x: 360,y: 350},
{text: "gracilis " ,x: 340,y: 350},
{text: "iliacus" ,x: 90,y: 310},
{text: "quadratus femoris" ,x: 90,y: 350},
{text: "sartorius", x: 95,y: 360},
{text: "tensor fasciae lata", x: 80,y: 340},
{text: "biceps femoris " ,x: 370,y: 360},
{text: "semimembranosus " ,x: 360,y: 380},
{text: "semitendinosus" ,x: 360,y: 370},
{text: "tibialis anterior", x: 90,y: 410},
{text: "peroneus tertius",  x: 93,y: 460},
{text: "gastrocnemius " ,x: 370,y: 440},
{text: "soleus " ,x: 370,y: 460},
{text: "tibialis posterior" ,x: 368,y: 445},
{text: "peroneus brevis",  x: 93,y: 450},
{text: "peroneus longus",  x: 93,y: 420},
{text: "adductor hallucis " ,x: 100,y: 480},
{text: "extensor digitorum brevis",  x: 95,y: 498},
{text: "extensor digitorum longus " ,x: 100,y: 498},
{text: "extensor hallucis longus", x: 95,y: 470},
{text: "flexor digiti minimi brevis", x: 90,y: 498},
{text: "flexor digitorum longus" ,x: 368,y: 460},
{text: "interossei - DI/PI", x: 96,y: 500},
{text: "lumbricals of foot", x: 96,y: 505},
{text: "plantar fascia" ,x: 365,y: 499},
{text: "paraspinal " ,x: 350,y: 270},
{text: "latissimus dorsi " ,x: 330,y: 280},
{text: "Levator Scapulae" ,x: 339,y: 198},
{text: "rhomboid major " ,x: 360,y: 240},
{text: "rhomboid minor" ,x: 360,y: 220},
{text: "trapezius " ,x: 340,y: 230},
{text: "paraspinal " ,x: 350,y: 280},
{text: "trapezius " ,x: 340,y: 210},
{text: "quadratus lumbrorum" ,x: 370,y: 298},
{text: "gluteus maximus " ,x: 370,y: 328},
{text: "gluteus medius " ,x: 315,y: 328},
{text: "gluteus minimus " ,x: 320,y: 328},
{text: "piriformis" ,x: 335,y: 330},

    // { text: "Forehead", x: 110, y: 150 },
    // { text: "Left Front Bicep", x: 155, y: 250 },
    // { text: "Right Front Bicep", x: 60, y: 250 },
    // { text: "Left Front Thigh", x: 130, y: 350 },
    // { text: "Right Front Thigh", x: 90, y: 350 },
    // { text: "Left Back Bicep", x: 305, y: 250 },
    // { text: "Right Back Bicep", x: 395, y: 250 },
    // { text: "Left Back Thigh", x: 330, y: 350 },
    // { text: "Right Back Thigh", x: 370, y: 350 },
  ],
};

const footDefaultOption = {
  positionOptions: [
    // { text: "foot", x: 240, y: 130 },

{text: "Right first toe	", x: 150	,y: 163},
{text: "Right second toe	", x: 175	,y: 155},
{text: "Right third toe	", x: 192	,y: 150},
{text: "Right forth toe	", x: 205	,y: 139},
{text: "Right fifth toe	", x: 218	,y: 130},
{text: "Right medial aspect of first  toe	", x: 142	,y: 152},
{text: "Right first to second web space	", x: 166	,y: 133},
{text: "Right second to third web space	", x: 180	,y: 130},
{text: "Right third to forth web space	", x: 195	,y: 130},
{text: "Right forth to fifth web space	", x: 210	,y: 120},
{text: "Right first metatarsal head	", x: 152	,y: 115},
{text: "Right second metatarsal head	", x: 173	,y: 118},
{text: "Right third metatarsal head	", x: 187	,y: 118},
{text: "Right forth metatarsal head	", x: 198	,y: 115},
{text: "Right fifth metatarsal head", x: 	214,y: 	98},
{text: "Right base of fifth metatarsal bone", x: 	206,y: 	40},
{text: "Right Arch of foot", x: 	156,y: 	40},
{text: "Right medial aspect of heel", x:	156,y:0},
{text: "Right lateral aspect of heel", x: 200,y:0},
{text: "Right mid heel	", x: 175	,y: -35},
{text: "Left first toe	", x: 102	,y: 163},
{text: "Left second toe", x: 	80	,y: 155},
{text: "Left third toe", x: 	65	,y: 150},
{text: "Left forth toe", x: 	50	,y: 139},
{text: "Left fifth toe", x: 	37	,y: 130},
{text: "Left medial aspect of first  toe	", x: 109	,y: 152},
{text: "Left first to second web space", x: 	90	,y: 133},
{text: "Left second to third web space", x: 	72	,y: 133},
{text: "Left third to forth web space", x: 	60	,y: 130},
{text: "Left forth to fifth web space", x: 	45	,y: 120},
{text: "Left first metatarsal head	", x: 102	,y: 115},
{text: "Left second metatarsal head", x: 	82	,y: 118},
{text: "Left third metatarsal head", x: 	69	,y: 118},
{text: "Left forth metatarsal head", x: 	56	,y: 115},
{text: "Left fifth metatarsal head", x: 38,y: 	98},
{text: "Left base of fifth metatarsal bond", x: 	48,y: 	40},
{text: "Left Arch of foot", x:	98,y: 	40},
{text: "Left medial aspect of heel", x:	98,y: 0},
{text: "Left lateral aspect of heel", x: 54,y: 	0},
{text: "Left mid heel", x: 	80	,y: -35},
{text: "Right interphalageal joint of first toe	", x: 140	,y: 235},
{text: "Right interphalageal joint of second toe	", x: 165	,y: 230},
{text: "Right interphalageal joint of third toe	", x: 182	,y: 235},
{text: "Right interphalageal joint of forth toe	", x: 192	,y: 245},
{text: "Right interphalageal joint of fifth toe	", x: 208	,y: 252},
{text: "Right metatarsophalangeal joint of first  toe	", x: 143	,y: 255},
{text: "Right first to second web space	", x: 155	,y: 245},
{text: "Right second to third web space	", x: 170	,y: 246},
{text: "Right third to forth web space	", x: 184	,y: 252},
{text: "Right forth to fifth web space	", x: 198	,y: 262},
{text: "Right medial aspect of first metatarsal head	", x: 130	,y: 270},
{text: "Right metatarsophalangeal joint of second  toe	",x: 163	,y: 265},
{text: "Right metatarsophalangeal joint of third  toe	",x: 175	,y: 265},
{text: "Right metatarsophalangeal joint of forth  toe	",x: 187	,y: 270},
{text: "Right metatarsophalangeal joint of fifth  toe	",x: 205	,y: 285},
{text: "Right dorsum of foot	",x: 170	,y: 320},
{text: "Left interphalageal joint of first toe	",x: 100	,y: 235},
{text: "Left interphalageal joint of second toe",x: 	77	,y: 230},
{text: "Left interphalageal joint of third toe",x: 	60	,y: 235},
{text: "Left interphalageal joint of forth toe",x: 	49	,y: 245},
{text: "Left interphalageal joint of fifth toe",x: 	34	,y: 252},
{text: "Left metatarsophalangeal joint of first  toe",x: 	99	,y: 255},
{text: "Left first to second web space",x: 	88	,y: 247},
{text: "Left second to third web space",x: 	70	,y: 245},
{text: "Left third to forth web space",x: 	56	,y: 252},
{text: "Left forth to fifth web space",x: 	43	,y: 262},
{text: "Left medial aspect of first metatarsal head	",x: 110	,y: 270},
{text: "Left metatarsophalangeal joint of second  toe",x: 	78	,y: 265},
{text: "Left metatarsophalangeal joint of third  toe",x: 	64	,y: 265},
{text: "Left metatarsophalangeal joint of forth  toe",x: 	54	,y: 270},
{text: "Left metatarsophalangeal joint of fifth  toe",x: 	35	,y: 285},
{text: "Left dorsum of foot", x: 70, y:	320},
  ],
};

const defaultData = {
  position: [],
};

export const CardMultiSelectPositionInitialViewData: CardMultiSelectPositionViewDataType = {
  dropDownOptions: bodyDefaultOption,
  viewData: defaultData,
  isBody: true,
  readonly: false,
};

CardMultiSelectPosition.defaultProps = CardMultiSelectPositionInitialViewData;

export default CardMultiSelectPosition;
