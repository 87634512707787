export default class DiagComposerController {
  constructor(props) {
    this.prxManager = props.prxManager;
  }

  getDiagRuleDetail = async ({ apiToken, id } = {}) => {
    let [response, error, network] = await this.prxManager.getDiagRuleDetail({
      apiToken,
      id
    });
    return [response, error, network];
  };

  getDiagRule = async ({ apiToken, division } = {}) => {
    const [response, error, network] = await this.prxManager.getDiagRule({
      apiToken,
      division
    });
    return [response, error, network];
  };

  patchDiagRuleDetail = async ({ apiToken, content, id } = {}) => {
    const [
      response,
      error,
      network
    ] = await this.prxManager.patchDiagRuleDetail({ apiToken, content, id });
    return [response, error, network];
  };

  patchDiagRuleName = async ({ apiToken, name, id } = {}) => {
    const [
      response,
      error,
      network
    ] = await this.prxManager.patchDiagRuleName({ apiToken, name, id });
    return [response, error, network];
  };


  patchDiagRulePublish = async ({ apiToken, published, id } = {}) => {
    const [
      response,
      error,
      network
    ] = await this.prxManager.patchDiagRulePublish({ apiToken, published, id });
    return [response, error, network];
  };


  getTagEconsentOptions = async ({ apiToken} = {}) => {
    const [
      response,
      error,
      network
    ] = await this.prxManager.getTagEconsentOptions({
      apiToken
    });
    return [response, error, network];
  }
  

  postPublishedDiagRule = async ({ apiToken, content, diagRule } = {}) => {
    const [
      response,
      error,
      network
    ] = await this.prxManager.postPublishedDiagRule({
      apiToken,
      content,
      diagRule
    });
    return [response, error, network];
  };

  patchPublishedDiagRule = async ({ apiToken, published, id } = {}) => {
    const [
      response,
      error,
      network
    ] = await this.prxManager.patchPublishedDiagRule({
      apiToken,
      published,
      id
    });
    return [response, error, network];
  };

  getTriageLevelClassify = async ({ apiToken, divisionId } = {}) => {
    console.log(" DiagComposerController, getTriageLevelClassify : divisionId: ", divisionId)
    const [
      response,
      error,
      network
    ] = await this.prxManager.getTriageLevelClassify({
      apiToken,
      divisionId
    });
    return [response, error, network];
  };
}
