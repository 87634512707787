import { TDocumentDefinitions } from "pdfmake/interfaces";

import HeaderSummaryReportForm from "../../BIL/pdfFormBil/HeaderSummaryReportForm";

const formatPercent = (value: string | number) => {
  if (typeof value !== "string") {
    return value;
  }

  return `${parseInt(value)}%`
};

const FormDFPayrollItemReport = async (props: any): Promise<TDocumentDefinitions> => {
  console.log("Form DF Payroll Item Props: ", props);

  const headerForm = await HeaderSummaryReportForm({
    data: props.result?.params,
    pageMarginTop: 120,
    header: [
      {
        alignment: "center",
        bold: true,
        fontSize: 15,
        marginLeft: 10,
        text: "รายงานสรุปรายได้ (ประจำวัน)",
      },
      {
        alignment: "center",
        bold: true,
        fontSize: 15,
        marginLeft: 10,
        preserveLeadingSpaces: true,
        text: `${props.result?.params?.doctor_name}`,
      },
      {
        alignment: "center",
        bold: true,
        fontSize: 15,
        marginLeft: 10,
        preserveLeadingSpaces: true,
        text: `ออกตรวจ ${props.result?.params?.division_name} คาบ : ${
          props.result?.params?.period?.length === 0 ? "ทั้งหมด" : props.result?.params?.period
        }`,
      },
      {
        alignment: "center",
        fontSize: 15,
        marginBottom: 5,
        marginLeft: 10,
        text: `วันที่ ${props.result?.params?.start_date} ถึง ${props.result?.params?.end_date}`,
      },
    ],
  });

  const { font, images, styles } = headerForm;

  const localeStringOption = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  const generateTableBody = (data: any, columns: any) => {
    let body: any = [];

    // Header of Table
    body.push(
      [
        { text: "", border: [true, true, true, false] },
        { text: "", border: [true, true, true, false] },
        { text: "", border: [true, true, true, false] },
        { text: "", border: [true, true, true, false] },
        { text: "", border: [true, true, true, false] },
        { text: "", border: [true, true, true, false] },
        { text: "", border: [true, true, true, false] },
        { text: "", border: [true, true, true, false] },
        { text: "", border: [true, true, true, false] },
        { text: "", border: [true, true, true, false] },
        { text: "", border: [true, true, true, false] },
        { text: "", border: [true, true, true, false] },
        { text: "", border: [true, true, true, false] },
        { text: "", border: [true, true, true, false] },
        { text: "รายรับที่แบ่ง", bold: true, alignment: "center", colSpan: 3 },
        {},
        {},
        { text: "", border: [true, true, true, false] },
      ],
      [
        {
          text: "ประเภท\nลงตรวจ/\n(ตำแหน่ง\nแพทย์)",
          bold: true,
          alignment: "center",
          border: [true, false, true, true],
        },
        {
          text: "VN/\nวันเวลา\nที่รักษา",
          bold: true,
          alignment: "center",
          border: [true, false, true, true],
        },
        {
          text: "HN\nชื่อ-นามสกุล",
          bold: true,
          alignment: "center",
          border: [true, false, true, true],
        },
        { text: "สิทธิ", bold: true, alignment: "center", border: [true, false, true, true] },
        {
          text: "วันเวลาที่\nชำระเงิน",
          bold: true,
          alignment: "center",
          border: [true, false, true, true],
        },
        {
          text: "เลขที่ใบเสร็จ\n(วิธีรับชำระ)",
          bold: true,
          alignment: "center",
          border: [true, false, true, true],
        },
        { text: "รหัส", bold: true, alignment: "center", border: [true, false, true, true] },
        { text: "รายการ", bold: true, alignment: "center", border: [true, false, true, true] },
        { text: "จำนวน", bold: true, alignment: "center", border: [true, false, true, true] },
        { text: "รายรับรวม", bold: true, alignment: "center", border: [true, false, true, true] },
        {
          text: "หักค่า\nบริหาร",
          bold: true,
          alignment: "center",
          border: [true, false, true, true],
        },
        {
          text: "หักค่า\nธรรมเนียม\nบัตรเครดิต",
          bold: true,
          alignment: "center",
          border: [true, false, true, true],
        },
        { text: "คงเหลือ", bold: true, alignment: "center", border: [true, false, true, true] },
        {
          text: "ส่วนแบ่ง\n(%)",
          bold: true,
          alignment: "center",
          border: [true, false, true, true],
        },
        { text: "รายรับรวม\nหลังหัก\nส่วนแบ่ง", bold: true, alignment: "center" },
        { text: "ส่งเบิก", bold: true, alignment: "center" },
        { text: "ผู้ป่วย\nจ่าย", bold: true, alignment: "center" },
        {
          text: "ประเภท\nรายรับ",
          bold: true,
          alignment: "center",
          border: [true, false, true, true],
        },
      ]
    );

    if (data?.length > 0) {
      data.forEach((row: any) => {
        let dataRow: any = [];

        columns.forEach((column: any) => {
          if (row[column] !== null && row[column] !== undefined) {
            switch (column) {
              case "examination_type":
                dataRow.push({
                  text:
                    row[column] +
                    "\n" +
                    (row["doctor_position"]?.trim().length > 0
                      ? `(${row["doctor_position"]})`
                      : ``),
                  alignment: "center",
                });
                break;
              case "encounter_number":
                dataRow.push({ text: row[column] + "\n" + row["service_date"] });
                break;
              case "hn":
                dataRow.push({ text: row[column] + "\n" + row["patient_name"] });
                break;
              case "receipt_code":
                dataRow.push({
                  text:
                    row[column] +
                    "\n" +
                    (row["payment_type"]?.trim().length > 0 ? `(${row["payment_type"]})` : ``),
                });
                break;
              case "quantity":
                dataRow.push({ text: row[column], alignment: "right" });
                break;
              // join("\n") when it was array
              case "management_fee":
                dataRow.push({
                  text: `${row[column]?.toLocaleString("en-US", localeStringOption)}\n(${formatPercent(row["management_fee_percent"])})`,
                  alignment: "right",
                });
                break;
              case "revenue_price":
              case "credit_card_fee":
              case "leftover_revenue_price":
              case "compensation_percent":
              case "final_revenue_price":
              case "send_claim":
              case "payable":
                dataRow.push({
                  text: row[column]?.toLocaleString("en-US", localeStringOption),
                  alignment: "right",
                });
                break;
              // join("\n") when it was array
              case "payment_method":
                dataRow.push({ text: row[column], alignment: "center" });
                break;
              default:
                dataRow.push({ text: row[column].toString() });
                break;
            }
          } else {
            dataRow.push({ text: "-" });
          }
        });

        body.push(dataRow);
      });
    }

    return body;
  };

  const tableReport = (data: any, columns: any) => {
    return {
      table: {
        widths: [
          "auto",
          "auto",
          "*",
          "8%", // "สิทธิ" width = auto จะทำให้ Layout ตกกระดาษ
          "auto",
          "auto",
          "auto",
          "11.55%", // "รายการ" width = 12% คือ max ที่ข้อมูลแสดงตารางได้ครบ, width >= 15% Layout ตกกระดาษ  [11.3% - 11.8%]
          "auto",
          "auto",
          "auto",
          "auto",
          "auto",
          "auto",
          "auto",
          "auto",
          "auto",
          "auto",
        ],
        headerRows: 2,
        body: generateTableBody(data, columns),
      },
    };
  };

  const generateSummaryTableBody = (data: any) => {
    let body: any = [];

    // Row 1 (Total)
    body.push([
      {},
      {},
      { text: "รายรับรวมทั้งหมด" },
      {
        text: data?.total_revenue_price?.toLocaleString("en-US", localeStringOption),
        alignment: "right",
      },
      {},
    ]);

    // Row 2
    body.push([
      { text: "ค่าบริการผู้ป่วยนอก" },
      {
        text: data?.total_fee_outpatient?.toLocaleString("en-US", localeStringOption),
        alignment: "right",
      },
      { text: "รายรับจากการรักษา (รับเป็นเงิน)" },
      {
        text: data?.total_revenue_credit?.toLocaleString("en-US", localeStringOption),
        alignment: "right",
      },
      {
        text: data?.final_revenue_credit?.toLocaleString("en-US", localeStringOption),
        alignment: "right",
      },
    ]);

    // Row 3
    body.push([
      { text: "รายรับที่ไม่แบ่ง" },
      {
        text: data?.total_compensation_price?.toLocaleString("en-US", localeStringOption),
        alignment: "right",
      },
      { text: "รายรับจากโครงการ (แต้มสะสม)" },
      {
        text: data?.total_revenue_point?.toLocaleString("en-US", localeStringOption),
        alignment: "right",
      },
      {
        text: data?.final_revenue_point?.toLocaleString("en-US", localeStringOption),
        alignment: "right",
        // issue 68226
      },
    ]);

    return body;
  };

  const tableSummaryReport = (data: any) => {
    return {
      columns: [
        { text: "", width: "*" },
        {
          table: {
            widths: ["auto", "auto", "auto", "auto", "auto"],
            body: generateSummaryTableBody(data),
          },
          layout: "noBorders",
          width: "auto",
        },
      ],
    };
  };

  return {
    ...headerForm,
    content: [
      tableReport(props.result?.fields || [], [
        "examination_type",
        "encounter_number",
        "hn",
        "coverage_name",
        "paid_date",
        "receipt_code",
        "item_code",
        "item_name",
        "quantity",
        "revenue_price",
        "management_fee",
        "credit_card_fee",
        "leftover_revenue_price",
        "compensation_percent",
        "final_revenue_price",
        "send_claim",
        "payable",
        "payment_method",
      ]),
      { text: " ", preserveLeadingSpaces: true },
      { text: "หมายเหตุ ส่วนแบ่ง (%) ยังไม่ได้ถูกหักค่า LAB (ถ้ามี)", preserveLeadingSpaces: true },
      { text: "C - ได้รับเป็นเงิน P - ได้รับเป็นแต้มสะสม", preserveLeadingSpaces: true },
      { text: " ", preserveLeadingSpaces: true },
      tableSummaryReport(props.result?.params || {}),
    ],
    images: {
      ...images,
    },
    styles: {
      ...styles,
    },
    pageOrientation: `landscape`,
    defaultStyle: {
      font,
      fontSize: 10, // issue 67845 default of FontSize = 12 แต่ตกกระดาษ 
    },
    pageSize: `A4`,
  };
};

export default FormDFPayrollItemReport;
