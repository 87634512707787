import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  FormField,
  Button,
  Input,
  Dropdown,
  Checkbox,
  Icon
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardIntraTelepharQueueUX = (props: any) => {
    return(
      <div
        style={{  height: "calc(100dvh - 5.5rem)", padding: "6px 0 2rem", overflow: "auto", marginRight: "-10px", paddingRight: "10px"}}>
        <Form
          className="mini">
          <FormGroup
            style={{width: "calc(100% - 20px)"}}>
            <FormField
              width={2}>
              <label
                style={{ marginBottom: 0}}>
                Scan
              </label>
              <Input
                fluid={true}
                name="code"
                onChange={props.onChangeValue}
                placeholder="Prescription, Label"
                value={props.filterRx?.code}>
              </Input>
            </FormField>
            <FormField
              width={2}>
              <label
                style={{ marginBottom: 0}}>
                Patient
              </label>
              <div
                style={{width: "100%"}}>
                {props.PatientSearchBox}
              </div>
            </FormField>
            <FormField
              width={2}>
              <label
                style={{marginBottom: 0}}>
                Ordered by
              </label>
              <div
                style={{width: "100%"}}>
                {props.OrderBySearchBox}
              </div>
            </FormField>
            <FormField
              width={2}>
              <label
                style={{minWidth: "max-content", marginBottom: 0}}>
                Order form division 
              </label>
              <Dropdown
                className="inline-label"
                clearable={true}
                fluid={true}
                name="fromDivisionId"
                onChange={props.onChangeValue}
                options={props.divisionOptions|| []}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.filterRx?.fromDivisionId ?? ""}>
              </Dropdown>
            </FormField>
            <FormField
              width={2}>
              <label
                style={{ marginBottom: 0}}>
                Date 
              </label>
              <div
                style={{width: "100%"}}>
                {}
                <DateTextBox
                  onChange={props.onChangeDate?.("startDate")}
                  value={props.filterRx?.startDate ?? ""}>
                </DateTextBox>
              </div>
            </FormField>
            <FormField>
              <label>
                {"\u00a0"}
              </label>
              <label
                style={{height: "100%", display: "flex", alignItems: "center", marginTop: "-1rem"}}>
                to
              </label>
            </FormField>
            <FormField
              width={2}>
              <label
                style={{marginBottom: 0}}>
                {"\u00a0"}
              </label>
              <div
                style={{width: "100%"}}>
                
                <DateTextBox
                  onChange={props.onChangeDate?.("endDate")}
                  value={props.filterRx?.endDate ?? ""}>
                </DateTextBox>
              </div>
            </FormField>
            <FormField
              width={2}>
              <label
                style={{minWidth: "max-content", marginBottom: 0}}>
                Order to division
              </label>
              <Dropdown
                className="inline-label"
                clearable={true}
                fluid={true}
                name="toDivisionId"
                onChange={props.onChangeValue}
                options={props.divisionPharmaOptions || []}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.filterRx?.toDivisionId ?? ""}>
              </Dropdown>
            </FormField>
            <FormField
              width={2}>
              <label
                style={{minWidth: "max-content", marginBottom: 0}}>
                Approve order 
              </label>
              <Dropdown
                className="inline-label"
                clearable={true}
                fluid={true}
                name="approveId"
                onChange={props.onChangeValue}
                options={props.approveOptions||[]}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.filterRx?.approveId ?? ""}>
              </Dropdown>
            </FormField>
            <FormField>
              <label
                style={{ marginBottom: 0}}>
                {"\u00a0"}
              </label>
              <div>
                {props.ButtonSearch}
              </div>
            </FormField>
            <FormField>
              <label
                style={{ marginBottom: 0}}>
                {"\u00a0"}
              </label>
              <Button
                color="orange"
                icon="eraser"
                onClick={props.onClickClear}
                size={"mini"}
                style={{padding: "0.7rem 0.6rem 0.6rem"}}>
              </Button>
            </FormField>
          </FormGroup>
        </Form>
        <div
          style={{display: "grid", gridTemplateColumns: "1fr 320px"}}>
          {}
          <div
            style={{display: "grid", height: "fit-content"}}>
            
            <div
              style={{border: "1px solid rgba(0, 0, 0, 1)"}}>
              
              <div
                className="ui form mini"
                style={{backgroundColor: "rgba(93, 188, 210, 0.2)", padding: "5px"}}>
                
                <FormGroup
                  inline={true}
                  style={{margin:0}}>
                  <FormField
                    inline={true}
                    style={{display: "flex"}}>
                    <div
                      style={{position: "relative",margin: "0 2rem 0 0.25rem"}}>
                      {props.CXIElement}
                    </div>
                    <label
                      style={{color: "rgba(13, 113, 187, 1)", minWidth: "max-content", fontSize: "0.85rem"}}>
                      INPROGRESS PRESCRIPTION
                    </label>
                  </FormField>
                  <FormField
                    style={{flex:1}}>
                  </FormField>
                  <FormField
                    inline={true}
                    width={8}>
                    <label
                      style={{minWidth: "max-content", color: "rgba(85, 85, 85, 1)"}}>
                      Type :
                    </label>
                    <div
                      style={{width: "100%"}}>
                      {props.InprogressTypeDropdown}
                    </div>
                  </FormField>
                  <FormField
                    inline={true}
                    width={4}>
                    <label
                      style={{minWidth: "max-content", color: "rgba(85, 85, 85, 1)"}}>
                      Status :
                    </label>
                    <div
                      style={{width: "100%"}}>
                      {props.InprogressStatusDropdown}
                    </div>
                  </FormField>
                </FormGroup>
              </div>
              <div
                style={{ width: "calc(100vw - 401px)" }}>
                
                <Table
                  className="--rt-tbody-overflow-y-hidden"
                  data={props.inprogressRxList||[]}
                  getTdProps={props.onGetTdProps}
                  getTheadThProps={props.onGetTheadThProps}
                  getTrProps={props.onGetTrProps}
                  headers="HN,Encounter,Patient name,Type,Status,Ordered by,Division,Ordered date/time"
                  keys="patient.hn,encounter.number,patient.full_name,type_label,status,order_by,order_div.name,order_time"
                  minRows={props.tableInprogress?.minRows ?? 7}
                  showPagination={false}
                  style={{height: props.tableInprogress?.height ?? "calc(50dvh - 7rem)"}}
                  widths="^80,^70,^120,^90,^100,^120,^110,^110">
                </Table>
              </div>
            </div>
            <div
              style={{border: "1px solid rgba(0, 0, 0, 1)", marginTop: "0.5rem"}}>
              
              <div
                className="ui form mini"
                style={{backgroundColor: "rgba(93, 188, 210, 0.2)", padding: "5px"}}>
                
                <FormGroup
                  inline={true}
                  style={{margin:0}}>
                  <FormField
                    style={{display: "flex"}}>
                    <div
                      style={{position: "relative",margin: "0 2rem 0 0.25rem"}}>
                      {props.CXBElement}
                    </div>
                    <label
                      style={{color: "rgba(13, 113, 187, 1)", fontSize: "0.85rem"}}>
                      {`BILLED PRESCRIPTION ${props.billedRxCount?`(${props.billedRxCount})`: ""}`}
                    </label>
                  </FormField>
                  <FormField
                    style={{flex:1}}>
                  </FormField>
                  <FormField
                    inline={true}
                    width={5}>
                    <label
                      style={{minWidth: "max-content", color: "rgba(85, 85, 85, 1)"}}>
                      Type :
                    </label>
                    <div
                      style={{width: "100%"}}>
                      {props.BilledTypeDropdown}
                    </div>
                  </FormField>
                  <FormField
                    inline={true}
                    width={3}>
                    <label
                      style={{minWidth: "max-content", color: "rgba(85, 85, 85, 1)"}}>
                      Status :
                    </label>
                    <Dropdown
                      className="inline-label"
                      fluid={true}
                      name="status"
                      onChange={props.onChangeFilterBilled}
                      options={props.billedRxStatusOptions || []}
                      search={true}
                      selection={true}
                      style={{width: "100%", fontSize: "1em"}}
                      value={props.filterBilled?.status ?? ""}>
                    </Dropdown>
                  </FormField>
                  <FormField>
                    <Checkbox
                      checked={props.filterBilled?.isHideMissing ?? false}
                      className="black"
                      label="Hide missing"
                      name="isHideMissing"
                      onChange={props.onChangeFilterBilled}
                      style={{width: "100%", fontSize: "1em"}}>
                    </Checkbox>
                  </FormField>
                </FormGroup>
              </div>
              <div
                style={{ width: "calc(100vw - 401px)" }}>
                
                <Table
                  className="--rt-tbody-overflow-y-hidden"
                  data={props.billedRxList||[]}
                  getTdProps={props.onGetTdProps}
                  getTheadThProps={props.onGetTheadThProps}
                  getTrProps={props.onGetTrProps}
                  headers="HN,Encounter,Patient name,Type,Ordered by,Division,Ordered date/time, Billed date/time"
                  keys="patient.hn,encounter.number,patient.full_name,type_label,order_by,order_div.name,order_time,bill_time"
                  minRows={props.tableBilled?.minRows ?? 4}
                  showPagination={false}
                  style={{height: props.tableBilled?.height ?? "calc(50dvh - 12.5rem)"}}
                  widths="^80,^70,^120,^105,^120,^120,^110,^110">
                </Table>
              </div>
            </div>
          </div>
          <div
            style={{}}>
            
            <div
              style={{border: "1px solid rgba(0, 0, 0, 1)", marginLeft: "0.5rem", height: "100%", overflow: "hidden"}}>
              
              <div
                className="ui form mini"
                style={{backgroundColor: "rgba(93, 188, 210, 0.2)", padding: "5px"}}>
                
                <FormGroup
                  inline={true}
                  style={{margin:0}}>
                  <FormField
                    inline={true}>
                    <label
                      style={{color: "rgba(13, 113, 187, 1)", minWidth: "max-content", fontSize: "0.85rem"}}>
                      ONLINE QUEUE
                    </label>
                    <Icon
                      className={props.isEqualFilter?"blue":"yellow"}
                      link={true}
                      name="filter"
                      onClick={props.onClickFilter}>
                    </Icon>
                    <div
                      style={{position: "relative"}}>
                      {}
                      <div
                        style={{"position":"absolute","zIndex":"1","left":"-4px", top: "7px"}}>
                        {props.CardFilterOnlineQueue}
                      </div>
                    </div>
                    <div
                      style={{position: "relative", display: props.isEqualFilter? "none":""}}>
                      
                      <div
                        style={{position: "absolute", display: "flex", right: "6px", top: "-2px"}}>
                        
                        <div
                          style={{backgroundColor: "#fbbd08", width: "2px", height: "2px"}}>
                          
                        </div>
                        <div
                          style={{backgroundColor: "#fbbd08", width: "2px", height: "2px", marginLeft: "1px"}}>
                          
                        </div>
                        <div
                          style={{backgroundColor: "#fbbd08", width: "2px", height: "2px", marginLeft: "1px"}}>
                          
                        </div>
                      </div>
                    </div>
                  </FormField>
                </FormGroup>
              </div>
              <div
                style={{height: "100%"}}>
                
                <Table
                  className="--rt-tbody-overflow-y-hidden table-online-queue"
                  data={props.onlineQueueList||[]}
                  getTdProps={props.onGetTdProps}
                  getTheadThProps={props.onGetTheadThProps}
                  getTrProps={props.onGetTrProps}
                  headers=",Patient name,Call type/Status"
                  keys="call,patient.full_name,call_type_status"
                  minRows={props.tableOnlineQueue?.minRows?? 11}
                  showPagination={false}
                  style={{height: "0", minHeight: "calc(100% - 2rem)"}}
                  widths="30,^150,^110">
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}


export default CardIntraTelepharQueueUX

export const screenPropsDefault = {}

/* Date Time : Fri Dec 22 2023 21:31:28 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{  height: \"calc(100dvh - 5.5rem)\", padding: \"6px 0 2rem\", overflow: \"auto\", marginRight: \"-10px\", paddingRight: \"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": "semantic-ui-react",
      "id": 1,
      "name": "Form",
      "parent": 0,
      "props": {
        "className": {
          "type": "value",
          "value": "mini"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 2,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width: \"calc(100% - 20px)\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "FormField",
      "parent": 2,
      "props": {
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "FormField",
      "parent": 2,
      "props": {
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormField",
      "parent": 2,
      "props": {
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormField",
      "parent": 2,
      "props": {
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormField",
      "parent": 2,
      "props": {
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "FormField",
      "parent": 2,
      "props": {
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "FormField",
      "parent": 2,
      "props": {
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "FormField",
      "parent": 2,
      "props": {
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "FormField",
      "parent": 2,
      "props": {
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "label",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "Scan"
        },
        "style": {
          "type": "code",
          "value": "{ marginBottom: 0}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "label",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "Patient"
        },
        "style": {
          "type": "code",
          "value": "{ marginBottom: 0}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "label",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "Ordered by"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: 0}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "label",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "Order form division "
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", marginBottom: 0}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "label",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "Date "
        },
        "style": {
          "type": "code",
          "value": "{ marginBottom: 0}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "to"
        },
        "style": {
          "type": "code",
          "value": "{height: \"100%\", display: \"flex\", alignItems: \"center\", marginTop: \"-1rem\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "label",
      "parent": 9,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: 0}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "label",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "Order to division"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", marginBottom: 0}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "label",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": "Approve order "
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", marginBottom: 0}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "FormField",
      "parent": 2,
      "props": {
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "label",
      "parent": 12,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": "{ marginBottom: 0}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "label",
      "parent": 23,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": "{ marginBottom: 0}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "Button",
      "parent": 23,
      "props": {
        "color": {
          "type": "value",
          "value": "orange"
        },
        "icon": {
          "type": "value",
          "value": "eraser"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickClear"
        },
        "size": {
          "type": "code",
          "value": "\"mini\""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0.7rem 0.6rem 0.6rem\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "Input",
      "parent": 3,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "code"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "placeholder": {
          "type": "value",
          "value": "Prescription, Label"
        },
        "value": {
          "type": "code",
          "value": "props.filterRx?.code"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "code",
          "value": "props.PatientSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "code",
          "value": "props.OrderBySearchBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "Dropdown",
      "parent": 6,
      "props": {
        "className": {
          "type": "value",
          "value": "inline-label"
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "fromDivisionId"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.divisionOptions|| []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filterRx?.fromDivisionId ?? \"\""
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 33,
      "name": "DateTextBox",
      "parent": 32,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeDate?.(\"startDate\")"
        },
        "value": {
          "type": "code",
          "value": "props.filterRx?.startDate ?? \"\""
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 35,
      "name": "DateTextBox",
      "parent": 34,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeDate?.(\"endDate\")"
        },
        "value": {
          "type": "code",
          "value": "props.filterRx?.endDate ?? \"\""
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "Dropdown",
      "parent": 10,
      "props": {
        "className": {
          "type": "value",
          "value": "inline-label"
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "toDivisionId"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.divisionPharmaOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filterRx?.toDivisionId ?? \"\""
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "Dropdown",
      "parent": 11,
      "props": {
        "className": {
          "type": "value",
          "value": "inline-label"
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "approveId"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.approveOptions||[]"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filterRx?.approveId ?? \"\""
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSearch"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"grid\", gridTemplateColumns: \"1fr 320px\"}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 39,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"grid\", height: \"fit-content\"}"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 39,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{border: \"1px solid rgba(0, 0, 0, 1)\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{border: \"1px solid rgba(0, 0, 0, 1)\", marginTop: \"0.5rem\"}"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 42,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form mini"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"rgba(93, 188, 210, 0.2)\", padding: \"5px\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "FormGroup",
      "parent": 44,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{margin:0}"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "FormField",
      "parent": 45,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "FormField",
      "parent": 45,
      "props": {
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "FormField",
      "parent": 45,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "FormField",
      "parent": 45,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "label",
      "parent": 46,
      "props": {
        "children": {
          "type": "value",
          "value": "INPROGRESS PRESCRIPTION"
        },
        "style": {
          "type": "code",
          "value": "{color: \"rgba(13, 113, 187, 1)\", minWidth: \"max-content\", fontSize: \"0.85rem\"}"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "label",
      "parent": 48,
      "props": {
        "children": {
          "type": "value",
          "value": "Type :"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", color: \"rgba(85, 85, 85, 1)\"}"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "label",
      "parent": 49,
      "props": {
        "children": {
          "type": "value",
          "value": "Status :"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", color: \"rgba(85, 85, 85, 1)\"}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 48,
      "props": {
        "children": {
          "type": "code",
          "value": "props.InprogressTypeDropdown"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "div",
      "parent": 49,
      "props": {
        "children": {
          "type": "code",
          "value": "props.InprogressStatusDropdown"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "div",
      "parent": 42,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"calc(100vw - 401px)\" }"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 56,
      "name": "Table",
      "parent": 55,
      "props": {
        "className": {
          "type": "value",
          "value": "--rt-tbody-overflow-y-hidden"
        },
        "data": {
          "type": "code",
          "value": "props.inprogressRxList||[]"
        },
        "getTdProps": {
          "type": "code",
          "value": "props.onGetTdProps"
        },
        "getTheadThProps": {
          "type": "code",
          "value": "props.onGetTheadThProps"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.onGetTrProps"
        },
        "headers": {
          "type": "value",
          "value": "HN,Encounter,Patient name,Type,Status,Ordered by,Division,Ordered date/time"
        },
        "keys": {
          "type": "value",
          "value": "patient.hn,encounter.number,patient.full_name,type_label,status,order_by,order_div.name,order_time"
        },
        "minRows": {
          "type": "code",
          "value": "props.tableInprogress?.minRows ?? 7"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: props.tableInprogress?.height ?? \"calc(50dvh - 7rem)\"}"
        },
        "widths": {
          "type": "value",
          "value": "^80,^70,^120,^90,^100,^120,^110,^110"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 43,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form mini"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"rgba(93, 188, 210, 0.2)\", padding: \"5px\"}"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 43,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"calc(100vw - 401px)\" }"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "FormGroup",
      "parent": 57,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{margin:0}"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "FormField",
      "parent": 59,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "FormField",
      "parent": 59,
      "props": {
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "FormField",
      "parent": 59,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "FormField",
      "parent": 59,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "FormField",
      "parent": 59,
      "props": {
      },
      "seq": 64,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "label",
      "parent": 60,
      "props": {
        "children": {
          "type": "code",
          "value": "`BILLED PRESCRIPTION ${props.billedRxCount?`(${props.billedRxCount})`: \"\"}`"
        },
        "style": {
          "type": "code",
          "value": "{color: \"rgba(13, 113, 187, 1)\", fontSize: \"0.85rem\"}"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": null,
      "id": 66,
      "name": "label",
      "parent": 62,
      "props": {
        "children": {
          "type": "value",
          "value": "Type :"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", color: \"rgba(85, 85, 85, 1)\"}"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "label",
      "parent": 63,
      "props": {
        "children": {
          "type": "value",
          "value": "Status :"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", color: \"rgba(85, 85, 85, 1)\"}"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "div",
      "parent": 62,
      "props": {
        "children": {
          "type": "code",
          "value": "props.BilledTypeDropdown"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "Checkbox",
      "parent": 64,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filterBilled?.isHideMissing ?? false"
        },
        "className": {
          "type": "value",
          "value": "black"
        },
        "label": {
          "type": "value",
          "value": "Hide missing"
        },
        "name": {
          "type": "value",
          "value": "isHideMissing"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilterBilled"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", fontSize: \"1em\"}"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "Dropdown",
      "parent": 63,
      "props": {
        "className": {
          "type": "value",
          "value": "inline-label"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "status"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilterBilled"
        },
        "options": {
          "type": "code",
          "value": "props.billedRxStatusOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", fontSize: \"1em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filterBilled?.status ?? \"\""
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 72,
      "name": "Table",
      "parent": 58,
      "props": {
        "className": {
          "type": "value",
          "value": "--rt-tbody-overflow-y-hidden"
        },
        "data": {
          "type": "code",
          "value": "props.billedRxList||[]"
        },
        "getTdProps": {
          "type": "code",
          "value": "props.onGetTdProps"
        },
        "getTheadThProps": {
          "type": "code",
          "value": "props.onGetTheadThProps"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.onGetTrProps"
        },
        "headers": {
          "type": "value",
          "value": "HN,Encounter,Patient name,Type,Ordered by,Division,Ordered date/time, Billed date/time"
        },
        "keys": {
          "type": "value",
          "value": "patient.hn,encounter.number,patient.full_name,type_label,order_by,order_div.name,order_time,bill_time"
        },
        "minRows": {
          "type": "code",
          "value": "props.tableBilled?.minRows ?? 4"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: props.tableBilled?.height ?? \"calc(50dvh - 12.5rem)\"}"
        },
        "widths": {
          "type": "value",
          "value": "^80,^70,^120,^105,^120,^120,^110,^110"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "div",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{border: \"1px solid rgba(0, 0, 0, 1)\", marginLeft: \"0.5rem\", height: \"100%\", overflow: \"hidden\"}"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "div",
      "parent": 73,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form mini"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"rgba(93, 188, 210, 0.2)\", padding: \"5px\"}"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "FormGroup",
      "parent": 74,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{margin:0}"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "FormField",
      "parent": 75,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "label",
      "parent": 76,
      "props": {
        "children": {
          "type": "value",
          "value": "ONLINE QUEUE"
        },
        "style": {
          "type": "code",
          "value": "{color: \"rgba(13, 113, 187, 1)\", minWidth: \"max-content\", fontSize: \"0.85rem\"}"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "Icon",
      "parent": 76,
      "props": {
        "className": {
          "type": "code",
          "value": "props.isEqualFilter?\"blue\":\"yellow\""
        },
        "link": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "filter"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickFilter"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "div",
      "parent": 73,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height: \"100%\"}"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 80,
      "name": "Table",
      "parent": 79,
      "props": {
        "className": {
          "type": "value",
          "value": "--rt-tbody-overflow-y-hidden table-online-queue"
        },
        "data": {
          "type": "code",
          "value": "props.onlineQueueList||[]"
        },
        "getTdProps": {
          "type": "code",
          "value": "props.onGetTdProps"
        },
        "getTheadThProps": {
          "type": "code",
          "value": "props.onGetTheadThProps"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.onGetTrProps"
        },
        "headers": {
          "type": "value",
          "value": ",Patient name,Call type/Status"
        },
        "keys": {
          "type": "value",
          "value": "call,patient.full_name,call_type_status"
        },
        "minRows": {
          "type": "code",
          "value": "props.tableOnlineQueue?.minRows?? 11"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"0\", minHeight: \"calc(100% - 2rem)\"}"
        },
        "widths": {
          "type": "value",
          "value": "30,^150,^110"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "div",
      "parent": 76,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\"}"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "div",
      "parent": 81,
      "props": {
        "children": {
          "type": "code",
          "value": "props.CardFilterOnlineQueue"
        },
        "style": {
          "type": "code",
          "value": "{\"position\":\"absolute\",\"zIndex\":\"1\",\"left\":\"-4px\", top: \"7px\"}"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "div",
      "parent": 76,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\", display: props.isEqualFilter? \"none\":\"\"}"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 84,
      "name": "div",
      "parent": 83,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{position: \"absolute\", display: \"flex\", right: \"6px\", top: \"-2px\"}"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "div",
      "parent": 84,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#fbbd08\", width: \"2px\", height: \"2px\"}"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": null,
      "id": 86,
      "name": "div",
      "parent": 84,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#fbbd08\", width: \"2px\", height: \"2px\", marginLeft: \"1px\"}"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 84,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#fbbd08\", width: \"2px\", height: \"2px\", marginLeft: \"1px\"}"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 46,
      "props": {
        "children": {
          "type": "code",
          "value": "props.CXIElement"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\",margin: \"0 2rem 0 0.25rem\"}"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "div",
      "parent": 60,
      "props": {
        "children": {
          "type": "code",
          "value": "props.CXBElement"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\",margin: \"0 2rem 0 0.25rem\"}"
        }
      },
      "seq": 65,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardIntraTelepharQueueUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
