import React from 'react'
import {
   Button,
   Checkbox,
   Dropdown,
   Input,
   Radio,
   TextArea
} from 'semantic-ui-react'



import styles from './CardOTDiagnosisSwallowingEvaluationLOW.module.css'

export interface CardOTDiagnosisSwallowingEvaluationLOWViewDataType {
    OtDiagnosisSwallowingLow2: string,
    OtDiagnosisSwallowingLow2Disabled: boolean,
    OtDiagnosisSwallowingLow3: string,
    OtDiagnosisSwallowingLow3Disabled: boolean,
    OtDiagnosisSwallowingLow7: boolean,
    OtDiagnosisSwallowingLow7Disabled: boolean,
    OtDiagnosisSwallowingLow11: boolean,
    OtDiagnosisSwallowingLow11Disabled: boolean,
    OtDiagnosisSwallowingLow13: boolean,
    OtDiagnosisSwallowingLow13Disabled: boolean,
    OtDiagnosisSwallowingLow14: boolean,
    OtDiagnosisSwallowingLow14Disabled: boolean,
    OtDiagnosisSwallowingLow15: boolean,
    OtDiagnosisSwallowingLow15Disabled: boolean,
    OtDiagnosisSwallowingLow18: boolean,
    OtDiagnosisSwallowingLow18Disabled: boolean,
    OtDiagnosisSwallowingLow20: boolean,
    OtDiagnosisSwallowingLow20Disabled: boolean,
    OtDiagnosisSwallowingLow24: boolean,
    OtDiagnosisSwallowingLow24Disabled: boolean,
    OtDiagnosisSwallowingLow25: string,
    OtDiagnosisSwallowingLow25Disabled: boolean,
    OtDiagnosisSwallowingLow26: boolean,
    OtDiagnosisSwallowingLow26Disabled: boolean,
    OtDiagnosisSwallowingLow29: boolean,
    OtDiagnosisSwallowingLow29Disabled: boolean,
    OtDiagnosisSwallowingLow30: boolean,
    OtDiagnosisSwallowingLow30Disabled: boolean,
    OtDiagnosisSwallowingLow31: boolean,
    OtDiagnosisSwallowingLow31Disabled: boolean,
    OtDiagnosisSwallowingLow34: boolean,
    OtDiagnosisSwallowingLow34Disabled: boolean,
    OtDiagnosisSwallowingLow36: boolean,
    OtDiagnosisSwallowingLow36Disabled: boolean,
    OtDiagnosisSwallowingLow38: boolean,
    OtDiagnosisSwallowingLow38Disabled: boolean,
    OtDiagnosisSwallowingLow40: boolean,
    OtDiagnosisSwallowingLow40Disabled: boolean,
    OtDiagnosisSwallowingLow44: boolean,
    OtDiagnosisSwallowingLow44Disabled: boolean,
    OtDiagnosisSwallowingLow46: boolean,
    OtDiagnosisSwallowingLow46Disabled: boolean,
    OtDiagnosisSwallowingLow47: boolean,
    OtDiagnosisSwallowingLow47Disabled: boolean,
    OtDiagnosisSwallowingLow48: string,
    OtDiagnosisSwallowingLow48Disabled: boolean,
    OtDiagnosisSwallowingLow50: boolean,
    OtDiagnosisSwallowingLow50Disabled: boolean,
    OtDiagnosisSwallowingLow52: boolean,
    OtDiagnosisSwallowingLow52Disabled: boolean,
    OtDiagnosisSwallowingLow54: boolean,
    OtDiagnosisSwallowingLow54Disabled: boolean,
    OtDiagnosisSwallowingLow58: string,
    OtDiagnosisSwallowingLow58Disabled: boolean,
    OtDiagnosisSwallowingLow59: boolean,
    OtDiagnosisSwallowingLow59Disabled: boolean,
    OtDiagnosisSwallowingLow61: string,
    OtDiagnosisSwallowingLow61Disabled: boolean,
    OtDiagnosisSwallowingLow63: boolean,
    OtDiagnosisSwallowingLow63Disabled: boolean,
    Ct4Premorbid: string,
    Ct4PremorbidDisabled: boolean,
    Ct4PremorbidOptions: any,
    OtDiagnosisSwallowingLow66: string,
    OtDiagnosisSwallowingLow66Disabled: boolean,
    OtDiagnosisSwallowingLow68: boolean,
    OtDiagnosisSwallowingLow68Disabled: boolean,
    OtDiagnosisSwallowingLow70: boolean,
    OtDiagnosisSwallowingLow70Disabled: boolean,
    OtDiagnosisSwallowingLow73: string,
    OtDiagnosisSwallowingLow73Disabled: boolean,
    OtDiagnosisSwallowingLow75: string,
    OtDiagnosisSwallowingLow75Disabled: boolean,
    OtDiagnosisSwallowingLow78: boolean,
    OtDiagnosisSwallowingLow78Disabled: boolean,
    OtDiagnosisSwallowingLow80: string,
    OtDiagnosisSwallowingLow80Disabled: boolean,
    OtDiagnosisSwallowingLow82: boolean,
    OtDiagnosisSwallowingLow82Disabled: boolean,
    SaveRequestDisabled: boolean,
}

export const CardOTDiagnosisSwallowingEvaluationLOWInitialViewData: CardOTDiagnosisSwallowingEvaluationLOWViewDataType = {
    OtDiagnosisSwallowingLow2: "",
    OtDiagnosisSwallowingLow2Disabled: false,
    OtDiagnosisSwallowingLow3: "",
    OtDiagnosisSwallowingLow3Disabled: false,
    OtDiagnosisSwallowingLow7: false,
    OtDiagnosisSwallowingLow7Disabled: false,
    OtDiagnosisSwallowingLow11: false,
    OtDiagnosisSwallowingLow11Disabled: false,
    OtDiagnosisSwallowingLow13: false,
    OtDiagnosisSwallowingLow13Disabled: false,
    OtDiagnosisSwallowingLow14: false,
    OtDiagnosisSwallowingLow14Disabled: false,
    OtDiagnosisSwallowingLow15: false,
    OtDiagnosisSwallowingLow15Disabled: false,
    OtDiagnosisSwallowingLow18: false,
    OtDiagnosisSwallowingLow18Disabled: false,
    OtDiagnosisSwallowingLow20: false,
    OtDiagnosisSwallowingLow20Disabled: false,
    OtDiagnosisSwallowingLow24: false,
    OtDiagnosisSwallowingLow24Disabled: false,
    OtDiagnosisSwallowingLow25: "",
    OtDiagnosisSwallowingLow25Disabled: false,
    OtDiagnosisSwallowingLow26: false,
    OtDiagnosisSwallowingLow26Disabled: false,
    OtDiagnosisSwallowingLow29: false,
    OtDiagnosisSwallowingLow29Disabled: false,
    OtDiagnosisSwallowingLow30: false,
    OtDiagnosisSwallowingLow30Disabled: false,
    OtDiagnosisSwallowingLow31: false,
    OtDiagnosisSwallowingLow31Disabled: false,
    OtDiagnosisSwallowingLow34: false,
    OtDiagnosisSwallowingLow34Disabled: false,
    OtDiagnosisSwallowingLow36: false,
    OtDiagnosisSwallowingLow36Disabled: false,
    OtDiagnosisSwallowingLow38: false,
    OtDiagnosisSwallowingLow38Disabled: false,
    OtDiagnosisSwallowingLow40: false,
    OtDiagnosisSwallowingLow40Disabled: false,
    OtDiagnosisSwallowingLow44: false,
    OtDiagnosisSwallowingLow44Disabled: false,
    OtDiagnosisSwallowingLow46: false,
    OtDiagnosisSwallowingLow46Disabled: false,
    OtDiagnosisSwallowingLow47: false,
    OtDiagnosisSwallowingLow47Disabled: false,
    OtDiagnosisSwallowingLow48: "",
    OtDiagnosisSwallowingLow48Disabled: false,
    OtDiagnosisSwallowingLow50: false,
    OtDiagnosisSwallowingLow50Disabled: false,
    OtDiagnosisSwallowingLow52: false,
    OtDiagnosisSwallowingLow52Disabled: false,
    OtDiagnosisSwallowingLow54: false,
    OtDiagnosisSwallowingLow54Disabled: false,
    OtDiagnosisSwallowingLow58: "",
    OtDiagnosisSwallowingLow58Disabled: false,
    OtDiagnosisSwallowingLow59: false,
    OtDiagnosisSwallowingLow59Disabled: false,
    OtDiagnosisSwallowingLow61: "",
    OtDiagnosisSwallowingLow61Disabled: false,
    OtDiagnosisSwallowingLow63: false,
    OtDiagnosisSwallowingLow63Disabled: false,
    Ct4Premorbid: "",
    Ct4PremorbidDisabled: false,
    Ct4PremorbidOptions: [{key: 0, value: "A", text: "A"}],
    OtDiagnosisSwallowingLow66: "",
    OtDiagnosisSwallowingLow66Disabled: false,
    OtDiagnosisSwallowingLow68: false,
    OtDiagnosisSwallowingLow68Disabled: false,
    OtDiagnosisSwallowingLow70: false,
    OtDiagnosisSwallowingLow70Disabled: false,
    OtDiagnosisSwallowingLow73: "",
    OtDiagnosisSwallowingLow73Disabled: false,
    OtDiagnosisSwallowingLow75: "",
    OtDiagnosisSwallowingLow75Disabled: false,
    OtDiagnosisSwallowingLow78: false,
    OtDiagnosisSwallowingLow78Disabled: false,
    OtDiagnosisSwallowingLow80: "",
    OtDiagnosisSwallowingLow80Disabled: false,
    OtDiagnosisSwallowingLow82: false,
    OtDiagnosisSwallowingLow82Disabled: false,
    SaveRequestDisabled: false,
}

const Sub = (props: any) => {
    const children = React.Children.toArray(props.children)
    return (
        <div className={styles.container}>
            <div className={styles.OtDiagnosisSwallowingLow1}>
                <div
                    style={{background: "#F3F3F3", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow2}>
                <Input
                    value={props.viewData.OtDiagnosisSwallowingLow2}
                    disabled={ props.viewData.OtDiagnosisSwallowingLow2Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTDiagnosisSwallowingEvaluationLOW',
                            name: 'OtDiagnosisSwallowingLow2',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow3}>
                <Input
                    value={props.viewData.OtDiagnosisSwallowingLow3}
                    disabled={ props.viewData.OtDiagnosisSwallowingLow3Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTDiagnosisSwallowingEvaluationLOW',
                            name: 'OtDiagnosisSwallowingLow3',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow4}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Chief complaint*</strong></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow6}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Underlying disease</strong></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow7}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisSwallowingLow7}
                    disabled={ props.viewData.OtDiagnosisSwallowingLow7Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisSwallowingEvaluationLOW",
                                name: "OtDiagnosisSwallowingLow7"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisSwallowingLow8}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Consult for*</strong></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow9}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Swallowing evaluation &amp; training</strong></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow10}>
                <div
                    style={{background: "#E0E0E0", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow11}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisSwallowingLow11}
                    disabled={ props.viewData.OtDiagnosisSwallowingLow11Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisSwallowingEvaluationLOW",
                                name: "OtDiagnosisSwallowingLow11"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisSwallowingLow12}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Evaluation &amp; training program</strong></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow13}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisSwallowingLow13}
                    disabled={ props.viewData.OtDiagnosisSwallowingLow13Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisSwallowingEvaluationLOW",
                                name: "OtDiagnosisSwallowingLow13"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisSwallowingLow14}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisSwallowingLow14}
                    disabled={ props.viewData.OtDiagnosisSwallowingLow14Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisSwallowingEvaluationLOW",
                                name: "OtDiagnosisSwallowingLow14"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisSwallowingLow15}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisSwallowingLow15}
                    disabled={ props.viewData.OtDiagnosisSwallowingLow15Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisSwallowingEvaluationLOW",
                                name: "OtDiagnosisSwallowingLow15"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisSwallowingLow16}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Liquid modification</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow17}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Food modification</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow18}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisSwallowingLow18}
                    disabled={ props.viewData.OtDiagnosisSwallowingLow18Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisSwallowingEvaluationLOW",
                                name: "OtDiagnosisSwallowingLow18"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisSwallowingLow19}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Exercise</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow20}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisSwallowingLow20}
                    disabled={ props.viewData.OtDiagnosisSwallowingLow20Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisSwallowingEvaluationLOW",
                                name: "OtDiagnosisSwallowingLow20"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisSwallowingLow21}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Compensation technique</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow22}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Sensory stimulation</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow23}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>NMES</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow24}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisSwallowingLow24}
                    disabled={ props.viewData.OtDiagnosisSwallowingLow24Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisSwallowingEvaluationLOW",
                                name: "OtDiagnosisSwallowingLow24"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisSwallowingLow25}>
                <Input
                    value={props.viewData.OtDiagnosisSwallowingLow25}
                    disabled={ props.viewData.OtDiagnosisSwallowingLow25Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTDiagnosisSwallowingEvaluationLOW',
                            name: 'OtDiagnosisSwallowingLow25',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow26}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisSwallowingLow26}
                    disabled={ props.viewData.OtDiagnosisSwallowingLow26Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisSwallowingEvaluationLOW",
                                name: "OtDiagnosisSwallowingLow26"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisSwallowingLow27}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>EMG Bio-feedback program</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow28}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Other suggestion</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow29}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisSwallowingLow29}
                    disabled={ props.viewData.OtDiagnosisSwallowingLow29Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisSwallowingEvaluationLOW",
                                name: "OtDiagnosisSwallowingLow29"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisSwallowingLow30}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisSwallowingLow30}
                    disabled={ props.viewData.OtDiagnosisSwallowingLow30Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisSwallowingEvaluationLOW",
                                name: "OtDiagnosisSwallowingLow30"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisSwallowingLow31}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisSwallowingLow31}
                    disabled={ props.viewData.OtDiagnosisSwallowingLow31Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisSwallowingEvaluationLOW",
                                name: "OtDiagnosisSwallowingLow31"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisSwallowingLow32}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Precaution*</strong></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow33}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>None</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow34}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisSwallowingLow34}
                    disabled={ props.viewData.OtDiagnosisSwallowingLow34Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisSwallowingEvaluationLOW",
                                name: "OtDiagnosisSwallowingLow34"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisSwallowingLow35}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Infectious precaution</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow36}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisSwallowingLow36}
                    disabled={ props.viewData.OtDiagnosisSwallowingLow36Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisSwallowingEvaluationLOW",
                                name: "OtDiagnosisSwallowingLow36"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisSwallowingLow37}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Malignancy</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow38}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisSwallowingLow38}
                    disabled={ props.viewData.OtDiagnosisSwallowingLow38Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisSwallowingEvaluationLOW",
                                name: "OtDiagnosisSwallowingLow38"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisSwallowingLow39}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Vascular disorder</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow40}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisSwallowingLow40}
                    disabled={ props.viewData.OtDiagnosisSwallowingLow40Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisSwallowingEvaluationLOW",
                                name: "OtDiagnosisSwallowingLow40"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisSwallowingLow41}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Internel Instrument</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow42}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Osteoporosis</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow43}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Pregnancy</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow44}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisSwallowingLow44}
                    disabled={ props.viewData.OtDiagnosisSwallowingLow44Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisSwallowingEvaluationLOW",
                                name: "OtDiagnosisSwallowingLow44"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisSwallowingLow46}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisSwallowingLow46}
                    disabled={ props.viewData.OtDiagnosisSwallowingLow46Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisSwallowingEvaluationLOW",
                                name: "OtDiagnosisSwallowingLow46"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisSwallowingLow47}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisSwallowingLow47}
                    disabled={ props.viewData.OtDiagnosisSwallowingLow47Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisSwallowingEvaluationLOW",
                                name: "OtDiagnosisSwallowingLow47"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisSwallowingLow48}>
                <Input
                    value={props.viewData.OtDiagnosisSwallowingLow48}
                    disabled={ props.viewData.OtDiagnosisSwallowingLow48Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTDiagnosisSwallowingEvaluationLOW',
                            name: 'OtDiagnosisSwallowingLow48',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow49}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Hypertension</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow50}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisSwallowingLow50}
                    disabled={ props.viewData.OtDiagnosisSwallowingLow50Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisSwallowingEvaluationLOW",
                                name: "OtDiagnosisSwallowingLow50"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisSwallowingLow51}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Falling</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow52}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisSwallowingLow52}
                    disabled={ props.viewData.OtDiagnosisSwallowingLow52Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisSwallowingEvaluationLOW",
                                name: "OtDiagnosisSwallowingLow52"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisSwallowingLow53}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Diabetes militus</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow54}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisSwallowingLow54}
                    disabled={ props.viewData.OtDiagnosisSwallowingLow54Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisSwallowingEvaluationLOW",
                                name: "OtDiagnosisSwallowingLow54"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisSwallowingLow55}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Fracture</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow56}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Aspriration</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow57}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Seizure</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow58}>
                <Input
                    value={props.viewData.OtDiagnosisSwallowingLow58}
                    disabled={ props.viewData.OtDiagnosisSwallowingLow58Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTDiagnosisSwallowingEvaluationLOW',
                            name: 'OtDiagnosisSwallowingLow58',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow59}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisSwallowingLow59}
                    disabled={ props.viewData.OtDiagnosisSwallowingLow59Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisSwallowingEvaluationLOW",
                                name: "OtDiagnosisSwallowingLow59"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisSwallowingLow60}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Other</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow61}>
                <Input
                    value={props.viewData.OtDiagnosisSwallowingLow61}
                    disabled={ props.viewData.OtDiagnosisSwallowingLow61Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTDiagnosisSwallowingEvaluationLOW',
                            name: 'OtDiagnosisSwallowingLow61',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow62}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Functional Limitation</strong></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow63}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisSwallowingLow63}
                    disabled={ props.viewData.OtDiagnosisSwallowingLow63Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisSwallowingEvaluationLOW",
                                name: "OtDiagnosisSwallowingLow63"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisSwallowingLow64}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>None</p>
                </div>
            </div>
            <div className={styles.Ct4Premorbid}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct4Premorbid}
                    disabled={ props.viewData.Ct4PremorbidDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTDiagnosisSwallowingEvaluationLOW",
                                name: "Ct4Premorbid",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct4PremorbidOptions}
                />
            </div>
            <div className={styles.OtDiagnosisSwallowingLow66}>
                <Input
                    value={props.viewData.OtDiagnosisSwallowingLow66}
                    disabled={ props.viewData.OtDiagnosisSwallowingLow66Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTDiagnosisSwallowingEvaluationLOW',
                            name: 'OtDiagnosisSwallowingLow66',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow67}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Premorbid</strong></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow68}>
                <Radio 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisSwallowingLow68}
                    disabled={ props.viewData.OtDiagnosisSwallowingLow68Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "radio",
                            params: {
                                view: "CardOTDiagnosisSwallowingEvaluationLOW",
                                name: "OtDiagnosisSwallowingLow68"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisSwallowingLow69}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Family Status</strong></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow70}>
                <Radio 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisSwallowingLow70}
                    disabled={ props.viewData.OtDiagnosisSwallowingLow70Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "radio",
                            params: {
                                view: "CardOTDiagnosisSwallowingEvaluationLOW",
                                name: "OtDiagnosisSwallowingLow70"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisSwallowingLow71}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Caregiver</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow72}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Non-Caregiver</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow73}>
                <TextArea
                    value={props.viewData.OtDiagnosisSwallowingLow73}
                    disabled={ props.viewData.OtDiagnosisSwallowingLow73Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTDiagnosisSwallowingEvaluationLOW',
                            name: 'OtDiagnosisSwallowingLow73',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </TextArea>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow74}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Present and Past History</strong></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow75}>
                <Input
                    value={props.viewData.OtDiagnosisSwallowingLow75}
                    disabled={ props.viewData.OtDiagnosisSwallowingLow75Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTDiagnosisSwallowingEvaluationLOW',
                            name: 'OtDiagnosisSwallowingLow75',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow77}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Videofluoroscopic swallawing study date</strong></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow78}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisSwallowingLow78}
                    disabled={ props.viewData.OtDiagnosisSwallowingLow78Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisSwallowingEvaluationLOW",
                                name: "OtDiagnosisSwallowingLow78"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisSwallowingLow79}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>None</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow80}>
                <Input
                    value={props.viewData.OtDiagnosisSwallowingLow80}
                    disabled={ props.viewData.OtDiagnosisSwallowingLow80Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTDiagnosisSwallowingEvaluationLOW',
                            name: 'OtDiagnosisSwallowingLow80',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow82}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisSwallowingLow82}
                    disabled={ props.viewData.OtDiagnosisSwallowingLow82Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisSwallowingEvaluationLOW",
                                name: "OtDiagnosisSwallowingLow82"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisSwallowingLow83}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Fiberoptic endoscopic evaluation of swallowing date</strong></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisSwallowingLow84}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>None</p>
                </div>
            </div>
            <div className={styles.SaveRequest}>
                <Button
                    disabled={ props.viewData.SaveRequestDisabled }
                    onClick={(e) => { props.onEvent({
                        message: 'clickButton',
                        params: {
                            view: 'CardOTDiagnosisSwallowingEvaluationLOW',
                            name: 'SaveRequest'
                        }
                    })}}
                    color="green"
                    style={{width: "100%"}}
                >
                    Save
                </Button>
            </div>
        </div>
    )
}

export default Sub

const getTsName = (cssClass: string) => {
    return cssClass.split('-').map((s) => (
        s.charAt(0).toUpperCase() + s.slice(1)
    )).join('')
}
