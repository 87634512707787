import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  Input,
  Dropdown,
  Form
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardDeathCertificateUX = (props: any) => {
    return(
      <div>
        <Form>
          <FormGroup
            style={{display: "flex", padding: "1em 0em 0em 1em", gap: "1em"}}>
            <label
              style={{display: "flex", alignItems: "center", fontWeight: "bold"}}>
              วันเสียชีวิต
            </label>
            <label
              style={{display: "flex", alignItems: "center", width: "6.5em"}}>
              
            </label>
            <label
              style={{display: "flex", alignItems: "center", fontWeight: "bold"}}>
              สถานที่เสียชีวิต
            </label>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 2em 1em", gap: "1em"}}>
            <DateTextBox
              disabled={props.onHandleDisableForm}
              id="CardDeathCertificate-DateTextBox-DeathDate"
              onChange={props.handleDeathDate}
              style={{width: "12em"}}
              value={props.deathDate}>
            </DateTextBox>
            <Input
              disabled={props.onHandleDisableForm}
              id="CardDeathCertificate-Input-DeathPlace"
              onChange={(e) => props.onHandleHospital(e)}
              style={{width: "20em"}}
              value={props.deathPlace}>
            </Input>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 0em 1em", gap: "0em"}}>
            <label
              style={{display: "flex", alignItems: "center", width: "14em"}}>
              
            </label>
            <label
              style={{display: "flex", alignItems: "center", fontWeight: 'bold', fontSize: "16px", width: "11em"}}>
              Code ICD-10
            </label>
            <label
              style={{display: "flex", alignItems: "center", fontWeight: 'bold', fontSize: "12px", width: "12em"}}>
              ระยะเวลาของการเจ็บป่วยจนกระทั่งเสียชีวิต
            </label>
          </FormGroup>
          <FormGroup
            className="ui mini form"
            style={{display: "flex", padding: "0em 0em 0em 1em", gap: "1em"}}>
            <label
              style={{display: "flex", alignItems: "center", fontWeight: 'bold', fontSize: "14px", height: "30px", width: "8.2em"}}>
              I สาเหตุในขณะนั้น
            </label>
            <label
              style={{display: "flex", alignItems: "center", height: "30px", width: "2.3em"}}>
              {props.onToolTipOne}
            </label>
            <label
              style={{display: "flex", alignItems: "center", fontWeight: 'bold', height: "30px", padding: "0em 0em 0em 1em"}}>
              (a)
            </label>
            <Dropdown
              clearable={true}
              disabled={props.onHandleDisableForm}
              icon="search"
              id="CardDeathCertificate-Dropdown-Icd10-A"
              onChange={(e, { value }) => props.onHandleICD10("icd10TypeA", value)}
              options={props.icd10Options}
              search={true}
              selection={true}
              style={{minWidth: "15em", maxWidth: "15em"}}
              value={props.onTypeValue("icd10TypeA")}>
            </Dropdown>
            <Input
              disabled={props.onHandleDisableForm}
              id="CardDeathCertificate-Input-Amount-A"
              onChange={(e) => props.onHandleAmount("amountA", e)}
              style={{width: "4em", height: "30px"}}
              type="text"
              value={props.onAmount("amountA")}>
            </Input>
            <Dropdown
              clearable={true}
              disabled={props.onHandleDisableForm}
              id="CardDeathCertificate-Dropdown-AmountType-A"
              onChange={(e, {value}) => props.onHandleDateType("amountTypeA", value)}
              options={props.dateTypeOptions}
              selection={true}
              style={{minWidth: "6em", height: "30px"}}
              value={props.onAmountTypeValue("amountTypeA")}>
            </Dropdown>
          </FormGroup>
          <FormGroup
            className="ui mini form"
            style={{display: "flex", padding: "0em 0em 0em 1em", gap: "1em"}}>
            <label
              style={{display: "flex", alignItems: "center", width: "17em"}}>
              
            </label>
            <label
              style={{display: "flex", alignItems: "center", fontWeight: 'bold', fontSize: "12px"}}>
              เนื่องจาก (หรือเป็นผลที่เกิดจาก)
            </label>
          </FormGroup>
          <FormGroup
            className="ui mini form"
            style={{display: "flex", padding: "0em 0em 0em 1em", gap: "1em"}}>
            <label
              style={{display: "flex", alignItems: "center", fontWeight: 'bold', fontSize: "14px", height: "30px", width: "4.3em"}}>
              สาเหตุนำ
            </label>
            <label
              style={{display: "flex", alignItems: "center", height: "30px", width: "8.2em"}}>
              {props.onToolTipTwo}
            </label>
            <label
              style={{display: "flex", alignItems: "center", fontWeight: 'bold', height: "30px"}}>
              (b)
            </label>
            <Dropdown
              clearable={true}
              disabled={props.onHandleDisableForm}
              icon="search"
              id="CardDeathCertificate-Dropdown-Icd10-B"
              onChange={(e, { value }) => props.onHandleICD10("icd10TypeB", value)}
              options={props.icd10Options}
              search={true}
              selection={true}
              style={{minWidth: "15em", maxWidth: "15em"}}
              value={props.onTypeValue("icd10TypeB")}>
            </Dropdown>
            <Input
              disabled={props.onHandleDisableForm}
              id="CardDeathCertificate-Input-Amount-B"
              onChange={(e) => props.onHandleAmount("amountB", e)}
              style={{width: "4em", height: "30px"}}
              type="text"
              value={props.onAmount("amountB")}>
            </Input>
            <Dropdown
              clearable={true}
              disabled={props.onHandleDisableForm}
              id="CardDeathCertificate-Dropdown-AmountType-B"
              onChange={(e, {value}) => props.onHandleDateType("amountTypeB", value)}
              options={props.dateTypeOptions}
              selection={true}
              style={{minWidth: "6em", height: "30px"}}
              value={props.onAmountTypeValue("amountTypeB")}>
            </Dropdown>
          </FormGroup>
          <FormGroup
            className="ui mini form"
            style={{display: "flex", padding: "0em 0em 0em 1em", gap: "1em"}}>
            <label
              style={{display: "flex", alignItems: "center", width: "17em"}}>
              
            </label>
            <label
              style={{display: "flex", alignItems: "center", fontWeight: 'bold', fontSize: "12px"}}>
              เนื่องจาก (หรือเป็นผลที่เกิดจาก)
            </label>
          </FormGroup>
          <FormGroup
            className="ui mini form"
            style={{display: "flex", padding: "0em 0em 0em 1em", gap: "1em"}}>
            <label
              style={{display: "flex", alignItems: "center", fontWeight: 'bold', fontSize: "14px", height: "30px", width: "11.5em"}}>
              
            </label>
            <label
              style={{display: "flex", alignItems: "center", fontWeight: 'bold', height: "30px"}}>
              (c)
            </label>
            <Dropdown
              clearable={true}
              disabled={props.onHandleDisableForm}
              icon="search"
              id="CardDeathCertificate-Dropdown-Icd10-C"
              onChange={(e, { value }) => props.onHandleICD10("icd10TypeC", value)}
              options={props.icd10Options}
              search={true}
              selection={true}
              style={{minWidth: "15em", maxWidth: "15em"}}
              value={props.onTypeValue("icd10TypeC")}>
            </Dropdown>
            <Input
              disabled={props.onHandleDisableForm}
              id="CardDeathCertificate-Input-Amount-C"
              onChange={(e) => props.onHandleAmount("amountC", e)}
              style={{width: "4em", height: "30px"}}
              type="text"
              value={props.onAmount("amountC")}>
            </Input>
            <Dropdown
              clearable={true}
              disabled={props.onHandleDisableForm}
              id="CardDeathCertificate-Dropdown-AmountType-C"
              onChange={(e, {value}) => props.onHandleDateType("amountTypeC", value)}
              options={props.dateTypeOptions}
              selection={true}
              style={{minWidth: "6em", height: "30px"}}
              value={props.onAmountTypeValue("amountTypeC")}>
            </Dropdown>
          </FormGroup>
          <FormGroup
            className="ui mini form"
            style={{display: "flex", padding: "0em 0em 0em 1em", gap: "1em"}}>
            <label
              style={{display: "flex", alignItems: "center", width: "17em"}}>
              
            </label>
            <label
              style={{display: "flex", alignItems: "center", fontWeight: 'bold', fontSize: "12px"}}>
              เนื่องจาก (หรือเป็นผลที่เกิดจาก)
            </label>
          </FormGroup>
          <FormGroup
            className="ui mini form"
            style={{display: "flex", padding: "0em 0em 0em 1em", gap: "1em"}}>
            <label
              style={{display: "flex", alignItems: "center", fontWeight: 'bold', fontSize: "14px", height: "30px", width: "11.5em"}}>
              
            </label>
            <label
              style={{display: "flex", alignItems: "center", fontWeight: 'bold', height: "30px"}}>
              (d)
            </label>
            <Dropdown
              clearable={true}
              disabled={props.onHandleDisableForm}
              icon="search"
              id="CardDeathCertificate-Dropdown-Icd10-D"
              onChange={(e, { value }) => props.onHandleICD10("icd10TypeD", value)}
              options={props.icd10Options}
              search={true}
              selection={true}
              style={{minWidth: "15em", maxWidth: "15em"}}
              value={props.onTypeValue("icd10TypeD")}>
            </Dropdown>
            <Input
              disabled={props.onHandleDisableForm}
              id="CardDeathCertificate-Input-Amount-D"
              onChange={(e) => props.onHandleAmount("amountD", e)}
              style={{width: "4em", height: "30px"}}
              type="text"
              value={props.onAmount("amountD")}>
            </Input>
            <Dropdown
              clearable={true}
              disabled={props.onHandleDisableForm}
              id="CardDeathCertificate-Dropdown-AmountType-D"
              onChange={(e, {value}) => props.onHandleDateType("amountTypeD", value)}
              options={props.dateTypeOptions}
              selection={true}
              style={{minWidth: "6em", height: "30px"}}
              value={props.onAmountTypeValue("amountTypeD")}>
            </Dropdown>
          </FormGroup>
          <FormGroup
            className="ui mini form"
            style={{display: "flex", padding: "0em 0em 0em 1em", gap: "1em"}}>
            <label
              style={{display: "flex", alignItems: "center", width: "17em"}}>
              
            </label>
            <label
              style={{display: "flex", alignItems: "center", fontWeight: 'bold', fontSize: "12px"}}>
              เนื่องจาก (หรือเป็นผลที่เกิดจาก)
            </label>
          </FormGroup>
          <FormGroup
            className="ui mini form"
            style={{display: "flex", padding: "0em 0em 0em 1em", gap: "1em"}}>
            <label
              style={{display: "flex", alignItems: "center", fontWeight: 'bold', fontSize: "14px", height: "30px", width: "10.2em"}}>
              II เงื่อนไขที่สำคัญอื่น ๆ
            </label>
            <label
              style={{display: "flex", alignItems: "center", height: "30px", width: "2.8em"}}>
              {props.onToolTipThree}
            </label>
            <Dropdown
              clearable={true}
              disabled={props.onHandleDisableForm}
              icon="search"
              id="CardDeathCertificate-Dropdown-Condition-1"
              onChange={(e, {value}) => props.onHandleCondition("condition1Type", value)}
              options={props.conditionOptions}
              search={true}
              selection={true}
              style={{minWidth: "15em", maxWidth: "15em"}}
              value={props.onTypeValue("condition1Type")}>
            </Dropdown>
            <Input
              disabled={props.onHandleDisableForm}
              id="CardDeathCertificate-Input-Amount-Condition-1"
              onChange={(e) => props.onHandleAmount("amountCondition1", e)}
              style={{width: "4em", height: "30px"}}
              type="text"
              value={props.onAmount("amountCondition1")}>
            </Input>
            <Dropdown
              clearable={true}
              disabled={props.onHandleDisableForm}
              id="CardDeathCertificate-Dropdown-AmountType-Condition-1"
              onChange={(e, {value}) => props.onHandleDateType("amountTypeCondition1", value)}
              options={props.dateTypeOptions}
              selection={true}
              style={{minWidth: "6em", height: "30px"}}
              value={props.onAmountTypeValue("amountTypeCondition1")}>
            </Dropdown>
          </FormGroup>
          <FormGroup
            className="ui mini form"
            style={{display: "flex", padding: "0em 0em 2em 1em", gap: "1em"}}>
            <label
              style={{display: "flex", alignItems: "center", fontWeight: 'bold', fontSize: "14px", height: "30px", width: "13.1em"}}>
              
            </label>
            <Dropdown
              clearable={true}
              disabled={props.onHandleDisableForm}
              icon="search"
              id="CardDeathCertificate-Dropdown-Condition-2"
              onChange={(e, {value}) => props.onHandleCondition("condition2Type", value)}
              options={props.conditionOptions}
              search={true}
              selection={true}
              style={{minWidth: "15em", maxWidth: "15em"}}
              value={props.onTypeValue("condition2Type")}>
            </Dropdown>
            <Input
              disabled={props.onHandleDisableForm}
              id="CardDeathCertificate-Input-Amount-Condition-2"
              onChange={(e) => props.onHandleAmount("amountCondition2", e)}
              style={{width: "4em", height: "30px"}}
              type="text"
              value={props.onAmount("amountCondition2")}>
            </Input>
            <Dropdown
              clearable={true}
              disabled={props.onHandleDisableForm}
              id="CardDeathCertificate-Dropdown-AmountType-Condition-2"
              onChange={(e, {value}) => props.onHandleDateType("amountTypeCondition2", value)}
              options={props.dateTypeOptions}
              selection={true}
              style={{minWidth: "6em", height: "30px"}}
              value={props.onAmountTypeValue("amountTypeCondition2")}>
            </Dropdown>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 1em 1em", gap: "2em"}}>
            <label
              style={{display: "flex", alignItems: "center", fontWeight: "bold"}}>
              พฤติการณ์ที่ตาย
            </label>
            <Dropdown
              allowAdditions={true}
              clearable={true}
              disabled={props.onHandleDisableForm}
              id="CardDeathCertificate-Dropdown-Manner"
              onAddItem={props.onAdditionManner}
              onChange={props.onHandleManner}
              options={props.mannerOptions}
              search={true}
              selection={true}
              value={props.mannerValue}>
            </Dropdown>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", justifyContent: "flex-end"}}>
            <label>
              {props.onCloseSelected}
            </label>
          </FormGroup>
        </Form>
      </div>
    )
}


export default CardDeathCertificateUX

export const screenPropsDefault = {"language":"TH"}

/* Date Time : Thu Aug 01 2024 16:22:19 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 0
    },
    {
      "from": "semantic-ui-react",
      "id": 137,
      "name": "FormGroup",
      "parent": 169,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"1em 0em 0em 1em\", gap: \"1em\"}"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 138,
      "name": "FormGroup",
      "parent": 169,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 2em 1em\", gap: \"1em\"}"
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": null,
      "id": 139,
      "name": "label",
      "parent": 137,
      "props": {
        "children": {
          "type": "value",
          "value": "วันเสียชีวิต"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", fontWeight: \"bold\"}"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": null,
      "id": 140,
      "name": "label",
      "parent": 137,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", width: \"6.5em\"}"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": null,
      "id": 141,
      "name": "label",
      "parent": 137,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานที่เสียชีวิต"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", fontWeight: \"bold\"}"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 142,
      "name": "DateTextBox",
      "parent": 138,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardDeathCertificate-DateTextBox-DeathDate"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleDeathDate"
        },
        "style": {
          "type": "code",
          "value": "{width: \"12em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.deathDate"
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 143,
      "name": "Input",
      "parent": 138,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardDeathCertificate-Input-DeathPlace"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleHospital(e)"
        },
        "style": {
          "type": "code",
          "value": "{width: \"20em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.deathPlace"
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 144,
      "name": "FormGroup",
      "parent": 169,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 1em\", gap: \"0em\"}"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 145,
      "name": "FormGroup",
      "parent": 169,
      "props": {
        "className": {
          "type": "value",
          "value": "ui mini form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 1em\", gap: \"1em\"}"
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 146,
      "name": "FormGroup",
      "parent": 169,
      "props": {
        "className": {
          "type": "value",
          "value": "ui mini form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 1em\", gap: \"1em\"}"
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": null,
      "id": 155,
      "name": "label",
      "parent": 144,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", width: \"14em\"}"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": null,
      "id": 156,
      "name": "label",
      "parent": 144,
      "props": {
        "children": {
          "type": "value",
          "value": "Code ICD-10"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", fontWeight: 'bold', fontSize: \"16px\", width: \"11em\"}"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": null,
      "id": 158,
      "name": "label",
      "parent": 144,
      "props": {
        "children": {
          "type": "value",
          "value": "ระยะเวลาของการเจ็บป่วยจนกระทั่งเสียชีวิต"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", fontWeight: 'bold', fontSize: \"12px\", width: \"12em\"}"
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": null,
      "id": 159,
      "name": "label",
      "parent": 145,
      "props": {
        "children": {
          "type": "value",
          "value": "I สาเหตุในขณะนั้น"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", fontWeight: 'bold', fontSize: \"14px\", height: \"30px\", width: \"8.2em\"}"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 161,
      "name": "Dropdown",
      "parent": 145,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "icon": {
          "type": "value",
          "value": "search"
        },
        "id": {
          "type": "value",
          "value": "CardDeathCertificate-Dropdown-Icd10-A"
        },
        "onChange": {
          "type": "code",
          "value": "(e, { value }) => props.onHandleICD10(\"icd10TypeA\", value)"
        },
        "options": {
          "type": "code",
          "value": "props.icd10Options"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"15em\", maxWidth: \"15em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.onTypeValue(\"icd10TypeA\")"
        }
      },
      "seq": 163,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 162,
      "name": "Input",
      "parent": 145,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardDeathCertificate-Input-Amount-A"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleAmount(\"amountA\", e)"
        },
        "style": {
          "type": "code",
          "value": "{width: \"4em\", height: \"30px\"}"
        },
        "type": {
          "type": "value",
          "value": "text"
        },
        "value": {
          "type": "code",
          "value": "props.onAmount(\"amountA\")"
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 163,
      "name": "Dropdown",
      "parent": 145,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardDeathCertificate-Dropdown-AmountType-A"
        },
        "onChange": {
          "type": "code",
          "value": "(e, {value}) => props.onHandleDateType(\"amountTypeA\", value)"
        },
        "options": {
          "type": "code",
          "value": "props.dateTypeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"6em\", height: \"30px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.onAmountTypeValue(\"amountTypeA\")"
        }
      },
      "seq": 200,
      "void": false
    },
    {
      "from": null,
      "id": 164,
      "name": "label",
      "parent": 145,
      "props": {
        "children": {
          "type": "value",
          "value": "(a)"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", fontWeight: 'bold', height: \"30px\", padding: \"0em 0em 0em 1em\"}"
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": null,
      "id": 165,
      "name": "label",
      "parent": 146,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", width: \"17em\"}"
        }
      },
      "seq": 165,
      "void": false
    },
    {
      "from": null,
      "id": 166,
      "name": "label",
      "parent": 146,
      "props": {
        "children": {
          "type": "value",
          "value": "เนื่องจาก (หรือเป็นผลที่เกิดจาก)"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", fontWeight: 'bold', fontSize: \"12px\"}"
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 169,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 169,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 170,
      "name": "FormGroup",
      "parent": 169,
      "props": {
        "className": {
          "type": "value",
          "value": "ui mini form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 1em\", gap: \"1em\"}"
        }
      },
      "seq": 170,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 171,
      "name": "FormGroup",
      "parent": 169,
      "props": {
        "className": {
          "type": "value",
          "value": "ui mini form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 1em\", gap: \"1em\"}"
        }
      },
      "seq": 171,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 172,
      "name": "FormGroup",
      "parent": 169,
      "props": {
        "className": {
          "type": "value",
          "value": "ui mini form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 1em\", gap: \"1em\"}"
        }
      },
      "seq": 172,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 173,
      "name": "FormGroup",
      "parent": 169,
      "props": {
        "className": {
          "type": "value",
          "value": "ui mini form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 1em\", gap: \"1em\"}"
        }
      },
      "seq": 173,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 174,
      "name": "FormGroup",
      "parent": 169,
      "props": {
        "className": {
          "type": "value",
          "value": "ui mini form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 1em\", gap: \"1em\"}"
        }
      },
      "seq": 174,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 175,
      "name": "FormGroup",
      "parent": 169,
      "props": {
        "className": {
          "type": "value",
          "value": "ui mini form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 1em\", gap: \"1em\"}"
        }
      },
      "seq": 175,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 176,
      "name": "FormGroup",
      "parent": 169,
      "props": {
        "className": {
          "type": "value",
          "value": "ui mini form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 1em\", gap: \"1em\"}"
        }
      },
      "seq": 176,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 177,
      "name": "FormGroup",
      "parent": 169,
      "props": {
        "className": {
          "type": "value",
          "value": "ui mini form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 2em 1em\", gap: \"1em\"}"
        }
      },
      "seq": 177,
      "void": false
    },
    {
      "from": null,
      "id": 178,
      "name": "label",
      "parent": 170,
      "props": {
        "children": {
          "type": "value",
          "value": "สาเหตุนำ"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", fontWeight: 'bold', fontSize: \"14px\", height: \"30px\", width: \"4.3em\"}"
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": null,
      "id": 179,
      "name": "label",
      "parent": 170,
      "props": {
        "children": {
          "type": "value",
          "value": "{props.onToolTipTwo}"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", height: \"30px\", width: \"8.2em\"}"
        }
      },
      "seq": 179,
      "void": false
    },
    {
      "from": null,
      "id": 180,
      "name": "label",
      "parent": 170,
      "props": {
        "children": {
          "type": "value",
          "value": "(b)"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", fontWeight: 'bold', height: \"30px\"}"
        }
      },
      "seq": 180,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 181,
      "name": "Dropdown",
      "parent": 170,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "icon": {
          "type": "value",
          "value": "search"
        },
        "id": {
          "type": "value",
          "value": "CardDeathCertificate-Dropdown-Icd10-B"
        },
        "onChange": {
          "type": "code",
          "value": "(e, { value }) => props.onHandleICD10(\"icd10TypeB\", value)"
        },
        "options": {
          "type": "code",
          "value": "props.icd10Options"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"15em\", maxWidth: \"15em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.onTypeValue(\"icd10TypeB\")"
        }
      },
      "seq": 181,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 182,
      "name": "Input",
      "parent": 170,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardDeathCertificate-Input-Amount-B"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleAmount(\"amountB\", e)"
        },
        "style": {
          "type": "code",
          "value": "{width: \"4em\", height: \"30px\"}"
        },
        "type": {
          "type": "value",
          "value": "text"
        },
        "value": {
          "type": "code",
          "value": "props.onAmount(\"amountB\")"
        }
      },
      "seq": 182,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 183,
      "name": "Dropdown",
      "parent": 170,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardDeathCertificate-Dropdown-AmountType-B"
        },
        "onChange": {
          "type": "code",
          "value": "(e, {value}) => props.onHandleDateType(\"amountTypeB\", value)"
        },
        "options": {
          "type": "code",
          "value": "props.dateTypeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"6em\", height: \"30px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.onAmountTypeValue(\"amountTypeB\")"
        }
      },
      "seq": 183,
      "void": false
    },
    {
      "from": null,
      "id": 184,
      "name": "label",
      "parent": 171,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", width: \"17em\"}"
        }
      },
      "seq": 184,
      "void": false
    },
    {
      "from": null,
      "id": 185,
      "name": "label",
      "parent": 171,
      "props": {
        "children": {
          "type": "value",
          "value": "เนื่องจาก (หรือเป็นผลที่เกิดจาก)"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", fontWeight: 'bold', fontSize: \"12px\"}"
        }
      },
      "seq": 185,
      "void": false
    },
    {
      "from": null,
      "id": 186,
      "name": "label",
      "parent": 172,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", fontWeight: 'bold', fontSize: \"14px\", height: \"30px\", width: \"11.5em\"}"
        }
      },
      "seq": 186,
      "void": false
    },
    {
      "from": null,
      "id": 187,
      "name": "label",
      "parent": 172,
      "props": {
        "children": {
          "type": "value",
          "value": "(c)"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", fontWeight: 'bold', height: \"30px\"}"
        }
      },
      "seq": 187,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 188,
      "name": "Dropdown",
      "parent": 172,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "icon": {
          "type": "value",
          "value": "search"
        },
        "id": {
          "type": "value",
          "value": "CardDeathCertificate-Dropdown-Icd10-C"
        },
        "onChange": {
          "type": "code",
          "value": "(e, { value }) => props.onHandleICD10(\"icd10TypeC\", value)"
        },
        "options": {
          "type": "code",
          "value": "props.icd10Options"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"15em\", maxWidth: \"15em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.onTypeValue(\"icd10TypeC\")"
        }
      },
      "seq": 188,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 189,
      "name": "Dropdown",
      "parent": 172,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardDeathCertificate-Dropdown-AmountType-C"
        },
        "onChange": {
          "type": "code",
          "value": "(e, {value}) => props.onHandleDateType(\"amountTypeC\", value)"
        },
        "options": {
          "type": "code",
          "value": "props.dateTypeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"6em\", height: \"30px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.onAmountTypeValue(\"amountTypeC\")"
        }
      },
      "seq": 190,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 190,
      "name": "Input",
      "parent": 172,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardDeathCertificate-Input-Amount-C"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleAmount(\"amountC\", e)"
        },
        "style": {
          "type": "code",
          "value": "{width: \"4em\", height: \"30px\"}"
        },
        "type": {
          "type": "value",
          "value": "text"
        },
        "value": {
          "type": "code",
          "value": "props.onAmount(\"amountC\")"
        }
      },
      "seq": 189,
      "void": false
    },
    {
      "from": null,
      "id": 191,
      "name": "label",
      "parent": 173,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", width: \"17em\"}"
        }
      },
      "seq": 191,
      "void": false
    },
    {
      "from": null,
      "id": 192,
      "name": "label",
      "parent": 173,
      "props": {
        "children": {
          "type": "value",
          "value": "เนื่องจาก (หรือเป็นผลที่เกิดจาก)"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", fontWeight: 'bold', fontSize: \"12px\"}"
        }
      },
      "seq": 192,
      "void": false
    },
    {
      "from": null,
      "id": 193,
      "name": "label",
      "parent": 174,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", fontWeight: 'bold', fontSize: \"14px\", height: \"30px\", width: \"11.5em\"}"
        }
      },
      "seq": 193,
      "void": false
    },
    {
      "from": null,
      "id": 194,
      "name": "label",
      "parent": 174,
      "props": {
        "children": {
          "type": "value",
          "value": "(d)"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", fontWeight: 'bold', height: \"30px\"}"
        }
      },
      "seq": 194,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 195,
      "name": "Dropdown",
      "parent": 174,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "icon": {
          "type": "value",
          "value": "search"
        },
        "id": {
          "type": "value",
          "value": "CardDeathCertificate-Dropdown-Icd10-D"
        },
        "onChange": {
          "type": "code",
          "value": "(e, { value }) => props.onHandleICD10(\"icd10TypeD\", value)"
        },
        "options": {
          "type": "code",
          "value": "props.icd10Options"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"15em\", maxWidth: \"15em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.onTypeValue(\"icd10TypeD\")"
        }
      },
      "seq": 195,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 196,
      "name": "Dropdown",
      "parent": 174,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardDeathCertificate-Dropdown-AmountType-D"
        },
        "onChange": {
          "type": "code",
          "value": "(e, {value}) => props.onHandleDateType(\"amountTypeD\", value)"
        },
        "options": {
          "type": "code",
          "value": "props.dateTypeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"6em\", height: \"30px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.onAmountTypeValue(\"amountTypeD\")"
        }
      },
      "seq": 197,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 197,
      "name": "Input",
      "parent": 174,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardDeathCertificate-Input-Amount-D"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleAmount(\"amountD\", e)"
        },
        "style": {
          "type": "code",
          "value": "{width: \"4em\", height: \"30px\"}"
        },
        "type": {
          "type": "value",
          "value": "text"
        },
        "value": {
          "type": "code",
          "value": "props.onAmount(\"amountD\")"
        }
      },
      "seq": 196,
      "void": false
    },
    {
      "from": null,
      "id": 198,
      "name": "label",
      "parent": 175,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", width: \"17em\"}"
        }
      },
      "seq": 198,
      "void": false
    },
    {
      "from": null,
      "id": 199,
      "name": "label",
      "parent": 175,
      "props": {
        "children": {
          "type": "value",
          "value": "เนื่องจาก (หรือเป็นผลที่เกิดจาก)"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", fontWeight: 'bold', fontSize: \"12px\"}"
        }
      },
      "seq": 199,
      "void": false
    },
    {
      "from": null,
      "id": 200,
      "name": "label",
      "parent": 145,
      "props": {
        "children": {
          "type": "value",
          "value": "{props.onToolTipOne}"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", height: \"30px\", width: \"2.3em\"}"
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": null,
      "id": 201,
      "name": "label",
      "parent": 176,
      "props": {
        "children": {
          "type": "value",
          "value": "II เงื่อนไขที่สำคัญอื่น ๆ"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", fontWeight: 'bold', fontSize: \"14px\", height: \"30px\", width: \"10.2em\"}"
        }
      },
      "seq": 201,
      "void": false
    },
    {
      "from": null,
      "id": 202,
      "name": "label",
      "parent": 176,
      "props": {
        "children": {
          "type": "value",
          "value": "{props.onToolTipThree}"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", height: \"30px\", width: \"2.8em\"}"
        }
      },
      "seq": 202,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 204,
      "name": "Dropdown",
      "parent": 176,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "icon": {
          "type": "value",
          "value": "search"
        },
        "id": {
          "type": "value",
          "value": "CardDeathCertificate-Dropdown-Condition-1"
        },
        "onChange": {
          "type": "code",
          "value": "(e, {value}) => props.onHandleCondition(\"condition1Type\", value)"
        },
        "options": {
          "type": "code",
          "value": "props.conditionOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"15em\", maxWidth: \"15em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.onTypeValue(\"condition1Type\")"
        }
      },
      "seq": 204,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 205,
      "name": "Dropdown",
      "parent": 176,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardDeathCertificate-Dropdown-AmountType-Condition-1"
        },
        "onChange": {
          "type": "code",
          "value": "(e, {value}) => props.onHandleDateType(\"amountTypeCondition1\", value)"
        },
        "options": {
          "type": "code",
          "value": "props.dateTypeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"6em\", height: \"30px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.onAmountTypeValue(\"amountTypeCondition1\")"
        }
      },
      "seq": 206,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 206,
      "name": "Input",
      "parent": 176,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardDeathCertificate-Input-Amount-Condition-1"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleAmount(\"amountCondition1\", e)"
        },
        "style": {
          "type": "code",
          "value": "{width: \"4em\", height: \"30px\"}"
        },
        "type": {
          "type": "value",
          "value": "text"
        },
        "value": {
          "type": "code",
          "value": "props.onAmount(\"amountCondition1\")"
        }
      },
      "seq": 205,
      "void": false
    },
    {
      "from": null,
      "id": 207,
      "name": "label",
      "parent": 177,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", fontWeight: 'bold', fontSize: \"14px\", height: \"30px\", width: \"13.1em\"}"
        }
      },
      "seq": 207,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 208,
      "name": "Dropdown",
      "parent": 177,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "icon": {
          "type": "value",
          "value": "search"
        },
        "id": {
          "type": "value",
          "value": "CardDeathCertificate-Dropdown-Condition-2"
        },
        "onChange": {
          "type": "code",
          "value": "(e, {value}) => props.onHandleCondition(\"condition2Type\", value)"
        },
        "options": {
          "type": "code",
          "value": "props.conditionOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"15em\", maxWidth: \"15em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.onTypeValue(\"condition2Type\")"
        }
      },
      "seq": 208,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 209,
      "name": "Dropdown",
      "parent": 177,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardDeathCertificate-Dropdown-AmountType-Condition-2"
        },
        "onChange": {
          "type": "code",
          "value": "(e, {value}) => props.onHandleDateType(\"amountTypeCondition2\", value)"
        },
        "options": {
          "type": "code",
          "value": "props.dateTypeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"6em\", height: \"30px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.onAmountTypeValue(\"amountTypeCondition2\")"
        }
      },
      "seq": 210,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 210,
      "name": "Input",
      "parent": 177,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardDeathCertificate-Input-Amount-Condition-2"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleAmount(\"amountCondition2\", e)"
        },
        "style": {
          "type": "code",
          "value": "{width: \"4em\", height: \"30px\"}"
        },
        "type": {
          "type": "value",
          "value": "text"
        },
        "value": {
          "type": "code",
          "value": "props.onAmount(\"amountCondition2\")"
        }
      },
      "seq": 209,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 211,
      "name": "FormGroup",
      "parent": 169,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 1em 1em\", gap: \"2em\"}"
        }
      },
      "seq": 211,
      "void": false
    },
    {
      "from": null,
      "id": 212,
      "name": "label",
      "parent": 211,
      "props": {
        "children": {
          "type": "value",
          "value": "พฤติการณ์ที่ตาย"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", fontWeight: \"bold\"}"
        }
      },
      "seq": 212,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 213,
      "name": "Dropdown",
      "parent": 211,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardDeathCertificate-Dropdown-Manner"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAdditionManner"
        },
        "onChange": {
          "type": "code",
          "value": "props.onHandleManner"
        },
        "options": {
          "type": "code",
          "value": "props.mannerOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.mannerValue"
        }
      },
      "seq": 213,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 214,
      "name": "FormGroup",
      "parent": 169,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\"}"
        }
      },
      "seq": 214,
      "void": false
    },
    {
      "from": null,
      "id": 215,
      "name": "label",
      "parent": 214,
      "props": {
        "children": {
          "type": "value",
          "value": "{props.onCloseSelected}"
        }
      },
      "seq": 215,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardDeathCertificateUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "language": "TH"
  },
  "width": 55
}

*********************************************************************************** */
