import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Form,
  FormGroup,
  FormField,
  Checkbox,
  Input,
  Button,
  Dropdown
} from 'semantic-ui-react'

const CardORCaseListUX = (props: any) => {
    return(
      <div
        id="CardORCaseList"
        style={{padding: "15px"}}>
        <Form>
          <FormGroup>
            <FormField
              width={3}>
              <Checkbox
                checked={props.ORCaseListSequence?.orFilter?.checkedHn}
                label="HN"
                name="checkedHn"
                onChange={props.onChangeFilter}
                style={{marginBottom: "0.5rem"}}>
              </Checkbox>
              <div>
                {props.patientSearchBox}
              </div>
            </FormField>
            <FormField
              width={3}>
              <label>
                ชื่อ-สกุลผู้ป่วย
              </label>
              <Input
                disabled={true}
                fluid={true}
                style={{opacity: 1}}
                value={props.ORCaseListSequence?.orFilter?.patientFullName || ""}>
              </Input>
            </FormField>
            <FormField
              width={3}>
              <Checkbox
                checked={props.ORCaseListSequence?.orFilter?.checkedDate}
                label="วันที่ผ่าตัด"
                name="checkedDate"
                onChange={props.onChangeFilter}
                style={{marginBottom: "0.5rem"}}>
              </Checkbox>
              <div
                style={{width: "100%"}}>
                {props.startDateTextBox}
              </div>
            </FormField>
            <FormField>
              <label>
                {"\u00a0"}
              </label>
              <label
                style={{height: "100%", display: "flex", alignItems: "center", marginTop: "-0.75rem"}}>
                ถึง
              </label>
            </FormField>
            <FormField
              width={3}>
              <label>
                {"\u00a0"}
              </label>
              <div
                style={{width: "100%"}}>
                {props.endDateTextBox}
              </div>
            </FormField>
            <FormField
              width={3}>
              <Checkbox
                checked={props.ORCaseListSequence?.orFilter?.checkedDoctor}
                label="แพทย์ผ่าตัด"
                name="checkedDoctor"
                onChange={props.onChangeFilter}
                style={{marginBottom: "0.5rem"}}>
              </Checkbox>
              <div
                style={{width: "100%"}}>
                {props.doctorSearchBox}
              </div>
            </FormField>
            <FormField
              style={{minWidth: "max-content"}}>
              <label
                style={{marginBottom: "0.5rem"}}>
                {"\u00a0"}
              </label>
              <Button
                color="yellow"
                onClick={props.onClear}
                size="small"
                style={{minWidth: "max-content"}}>
                ล้างการค้นหา
              </Button>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField
              width={4}>
              <Checkbox
                checked={props.ORCaseListSequence?.orFilter?.checkedCase}
                label="Case"
                name="checkedCase"
                onChange={props.onChangeFilter}
                style={{marginBottom: "0.5rem"}}>
              </Checkbox>
              <Dropdown
                clearable={true}
                disabled={!props.ORCaseListSequence?.orFilter?.checkedCase}
                fluid={true}
                multiple={true}
                name="cases"
                onChange={props.onChangeFilter}
                options={[ { key: "is_opd_case", value: "is_opd_case", text: "OPD" }, { key: "is_ipd_case", value: "is_ipd_case", text: "IPD" }, { key: "is_one_day_case", value: "is_one_day_case", text: "One Day", }, ]}
                search={true}
                selection={true}
                style={{minWidth: "100%", width: 0}}
                value={props.ORCaseListSequence?.orFilter?.cases || ""}>
              </Dropdown>
            </FormField>
            <FormField
              width={4}>
              <Checkbox
                checked={props.ORCaseListSequence?.orFilter?.checkedLocation}
                label="Location"
                name="checkedLocation"
                onChange={props.onChangeFilter}
                style={{marginBottom: "0.5rem"}}>
              </Checkbox>
              <Dropdown
                clearable={true}
                disabled={!props.ORCaseListSequence?.orFilter?.checkedLocation}
                fluid={true}
                name="operating_detail__operating_room__location_id"
                onChange={props.onChangeLocation}
                options={props.locationOptions || []}
                search={true}
                selection={true}
                style={{minWidth: "100%", width: 0}}
                value={props.ORCaseListSequence?.orFilter ?.operating_detail__operating_room__location_id || ""}>
              </Dropdown>
            </FormField>
            <FormField
              width={4}>
              <Checkbox
                checked={props.ORCaseListSequence?.orFilter?.checkedRoom}
                label="Room"
                name="checkedRoom"
                onChange={props.onChangeFilter}
                style={{marginBottom: "0.5rem"}}>
              </Checkbox>
              <Dropdown
                clearable={true}
                disabled={!props.ORCaseListSequence?.orFilter?.checkedRoom}
                fluid={true}
                name="operating_detail__operating_room__id"
                onChange={props.onChangeFilter}
                options={props.roomOptions || []}
                search={true}
                selection={true}
                style={{minWidth: "100%", width: 0}}
                value={props.ORCaseListSequence?.orFilter ?.operating_detail__operating_room__id || ""}>
              </Dropdown>
            </FormField>
            <FormField
              style={{minWidth: "max-content"}}>
              <label
                style={{marginBottom: "0.5rem"}}>
                {"\u00a0"}
              </label>
              <div>
                {props.buttonSearch}
              </div>
            </FormField>
            <FormField
              style={{minWidth: "max-content"}}>
              <label
                style={{marginBottom: "0.5rem"}}>
                {"\u00a0"}
              </label>
              <div>
                {props.buttonPrint}
              </div>
            </FormField>
            <FormField
              style={{minWidth: "max-content"}}>
              <label
                style={{marginBottom: "0.5rem"}}>
                {"\u00a0"}
              </label>
              <div>
                {props.buttonStat}
              </div>
            </FormField>
            <FormField
              style={{minWidth: "max-content"}}>
              <label
                style={{marginBottom: "0.5rem"}}>
                {"\u00a0"}
              </label>
              <div>
                {props.buttonPrintStat}
              </div>
            </FormField>
          </FormGroup>
        </Form>
        <div
          style={{ display: props.showStatistic ? "none" : "" }}>
          
          <Table
            className="--rt-tbody-overflow-y-hidden"
            data={props.ORCaseListSequence?.caseList?.items || []}
            headers="วันที่ผ่าตัด, HN, ชื่อ - สกุล, อายุ, Cases, Location/Room, แพทย์ผ่าตัด, Operation, Anesthesia"
            keys="start_date, hn, patient_name, patient_age, caseText, locationRoom, primary_doctor_name, pre_operation_summary, anesthesia_method_name"
            minRows={10}
            showPagination={false}
            style={{height: "calc(100dvh - 21rem)"}}
            widths="^100,^80,^100,^60,^60,^120,^100,^140,^80">
          </Table>
        </div>
        <div
          style={{  display: props.showStatistic ? "" : "none" }}>
          
          <Table
            className="--rt-tbody-overflow-y-hidden"
            data={props.ORCaseListSequence?.statCaseList || []}
            headers="Location/Room, Cases, Anesthesia, Age range, Gender, QTY"
            keys="locationRoom, caseText, anesthesia_method_name, ageRangeText, gender, count"
            minRows={10}
            style={{height: "calc(100dvh - 21rem)"}}
            widths="^180,^100,^100,^100,^100,^100">
          </Table>
        </div>
      </div>
    )
}


export default CardORCaseListUX

export const screenPropsDefault = {}

/* Date Time : Fri Dec 08 2023 10:59:45 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardORCaseList"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"15px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.showStatistic ? \"none\" : \"\" }"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 33,
      "name": "Table",
      "parent": 22,
      "props": {
        "className": {
          "type": "value",
          "value": "--rt-tbody-overflow-y-hidden"
        },
        "data": {
          "type": "code",
          "value": "props.ORCaseListSequence?.caseList?.items || []"
        },
        "headers": {
          "type": "value",
          "value": "วันที่ผ่าตัด, HN, ชื่อ - สกุล, อายุ, Cases, Location/Room, แพทย์ผ่าตัด, Operation, Anesthesia"
        },
        "keys": {
          "type": "value",
          "value": "start_date, hn, patient_name, patient_age, caseText, locationRoom, primary_doctor_name, pre_operation_summary, anesthesia_method_name"
        },
        "minRows": {
          "type": "code",
          "value": "10"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"calc(100dvh - 21rem)\"}"
        },
        "widths": {
          "type": "value",
          "value": "^100,^80,^100,^60,^60,^120,^100,^140,^80"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{  display: props.showStatistic ? \"\" : \"none\" }"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 56,
      "name": "Table",
      "parent": 55,
      "props": {
        "className": {
          "type": "value",
          "value": "--rt-tbody-overflow-y-hidden"
        },
        "data": {
          "type": "code",
          "value": "props.ORCaseListSequence?.statCaseList || []"
        },
        "headers": {
          "type": "value",
          "value": "Location/Room, Cases, Anesthesia, Age range, Gender, QTY"
        },
        "keys": {
          "type": "value",
          "value": "locationRoom, caseText, anesthesia_method_name, ageRangeText, gender, count"
        },
        "minRows": {
          "type": "code",
          "value": "10"
        },
        "style": {
          "type": "code",
          "value": "{height: \"calc(100dvh - 21rem)\"}"
        },
        "widths": {
          "type": "value",
          "value": "^180,^100,^100,^100,^100,^100"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "FormGroup",
      "parent": 57,
      "props": {
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "FormGroup",
      "parent": 57,
      "props": {
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "FormField",
      "parent": 58,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "FormField",
      "parent": 58,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "FormField",
      "parent": 58,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "FormField",
      "parent": 58,
      "props": {
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "FormField",
      "parent": 58,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "FormField",
      "parent": 58,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "FormField",
      "parent": 58,
      "props": {
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "Checkbox",
      "parent": 60,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ORCaseListSequence?.orFilter?.checkedHn"
        },
        "label": {
          "type": "value",
          "value": "HN"
        },
        "name": {
          "type": "value",
          "value": "checkedHn"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.5rem\"}"
        },
        "toggle": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "Checkbox",
      "parent": 62,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ORCaseListSequence?.orFilter?.checkedDate"
        },
        "label": {
          "type": "value",
          "value": "วันที่ผ่าตัด"
        },
        "name": {
          "type": "value",
          "value": "checkedDate"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.5rem\"}"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "label",
      "parent": 61,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ-สกุลผู้ป่วย"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "div",
      "parent": 60,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientSearchBox"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "div",
      "parent": 62,
      "props": {
        "children": {
          "type": "code",
          "value": "props.startDateTextBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "Input",
      "parent": 61,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{opacity: 1}"
        },
        "value": {
          "type": "code",
          "value": "props.ORCaseListSequence?.orFilter?.patientFullName || \"\""
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "label",
      "parent": 63,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "label",
      "parent": 63,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "style": {
          "type": "code",
          "value": "{height: \"100%\", display: \"flex\", alignItems: \"center\", marginTop: \"-0.75rem\"}"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "label",
      "parent": 64,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "div",
      "parent": 64,
      "props": {
        "children": {
          "type": "code",
          "value": "props.endDateTextBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 77,
      "name": "Checkbox",
      "parent": 65,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ORCaseListSequence?.orFilter?.checkedDoctor"
        },
        "label": {
          "type": "value",
          "value": "แพทย์ผ่าตัด"
        },
        "name": {
          "type": "value",
          "value": "checkedDoctor"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.5rem\"}"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "div",
      "parent": 65,
      "props": {
        "children": {
          "type": "code",
          "value": "props.doctorSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 79,
      "name": "Button",
      "parent": 66,
      "props": {
        "children": {
          "type": "value",
          "value": "ล้างการค้นหา"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClear"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 80,
      "name": "FormField",
      "parent": 59,
      "props": {
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 81,
      "name": "FormField",
      "parent": 59,
      "props": {
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "FormField",
      "parent": 59,
      "props": {
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 83,
      "name": "FormField",
      "parent": 59,
      "props": {
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 87,
      "name": "Dropdown",
      "parent": 80,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "!props.ORCaseListSequence?.orFilter?.checkedCase"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "cases"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "options": {
          "type": "code",
          "value": "[ { key: \"is_opd_case\", value: \"is_opd_case\", text: \"OPD\" }, { key: \"is_ipd_case\", value: \"is_ipd_case\", text: \"IPD\" }, { key: \"is_one_day_case\", value: \"is_one_day_case\", text: \"One Day\", }, ]"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"100%\", width: 0}"
        },
        "value": {
          "type": "code",
          "value": "props.ORCaseListSequence?.orFilter?.cases || \"\""
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 88,
      "name": "Dropdown",
      "parent": 81,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "!props.ORCaseListSequence?.orFilter?.checkedLocation"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "operating_detail__operating_room__location_id"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeLocation"
        },
        "options": {
          "type": "code",
          "value": "props.locationOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"100%\", width: 0}"
        },
        "value": {
          "type": "code",
          "value": "props.ORCaseListSequence?.orFilter ?.operating_detail__operating_room__location_id || \"\""
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 89,
      "name": "Dropdown",
      "parent": 82,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "!props.ORCaseListSequence?.orFilter?.checkedRoom"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "operating_detail__operating_room__id"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "options": {
          "type": "code",
          "value": "props.roomOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"100%\", width: 0}"
        },
        "value": {
          "type": "code",
          "value": "props.ORCaseListSequence?.orFilter ?.operating_detail__operating_room__id || \"\""
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 94,
      "name": "FormField",
      "parent": 59,
      "props": {
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 95,
      "name": "FormField",
      "parent": 59,
      "props": {
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 96,
      "name": "FormField",
      "parent": 59,
      "props": {
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "label",
      "parent": 83,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.5rem\"}"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": null,
      "id": 98,
      "name": "label",
      "parent": 94,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.5rem\"}"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 99,
      "name": "label",
      "parent": 95,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.5rem\"}"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": null,
      "id": 100,
      "name": "label",
      "parent": 96,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.5rem\"}"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": null,
      "id": 101,
      "name": "div",
      "parent": 96,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonPrintStat"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": null,
      "id": 102,
      "name": "div",
      "parent": 95,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonStat"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": null,
      "id": 103,
      "name": "div",
      "parent": 94,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonPrint"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": null,
      "id": 104,
      "name": "div",
      "parent": 83,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearch"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 105,
      "name": "Checkbox",
      "parent": 80,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ORCaseListSequence?.orFilter?.checkedCase"
        },
        "label": {
          "type": "value",
          "value": "Case"
        },
        "name": {
          "type": "value",
          "value": "checkedCase"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.5rem\"}"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 106,
      "name": "Checkbox",
      "parent": 81,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ORCaseListSequence?.orFilter?.checkedLocation"
        },
        "label": {
          "type": "value",
          "value": "Location"
        },
        "name": {
          "type": "value",
          "value": "checkedLocation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.5rem\"}"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 107,
      "name": "Checkbox",
      "parent": 82,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ORCaseListSequence?.orFilter?.checkedRoom"
        },
        "label": {
          "type": "value",
          "value": "Room"
        },
        "name": {
          "type": "value",
          "value": "checkedRoom"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.5rem\"}"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": null,
      "id": 108,
      "name": "label",
      "parent": 66,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.5rem\"}"
        }
      },
      "seq": 79,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardORCaseListUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
