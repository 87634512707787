import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";

// PDF Path
const PDF = "/static/pdf/printList/price_estimate.pdf";
const Font = "/static/fonts/THSarabunNew-Bold.ttf"; //* กรณีแก้ Font

const FormPriceEstimateTH = async (props: any) => {
  try {
    const existingPdfBytes = await fetch(PDF).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Loading Custom Font
    const fontUrl = Font;
    const fontBytes = await fetch(fontUrl).then((res) => res.arrayBuffer());
    pdfDoc.registerFontkit(fontkit);
    const customFont = await pdfDoc.embedFont(fontBytes);
    const zapFont = await pdfDoc.embedFont(StandardFonts.ZapfDingbats);

    const pages = pdfDoc.getPages();
    const firstPage = pages[0];
    const fontSize = 12; //*

    // format allergy
    const formatAllergy = props.items?.patientAllergy?.trim().length > 0
      ? props.items?.patientAllergy
        .replace(/((.*?,){2})/g, '$1\n')
        .replace(/^\s+|\s+$/gm, '')
      : props.items?.patientAllergy;

    const textCoordinates = [
      { text: props.items?.formatPatient, x: 389, y: 774 },
      { text: props.items?.hn, x: 528, y: 774 },
      { text: props.items?.patientEncounter, x: 393, y: 745 },
      { text: props.items?.birthdate, x: 399, y: 759 },
      { text: props.items?.age.toString(), x: 472, y: 759 },
      { text: props.items?.formatGender, x: 536, y: 759 },
      { text: props.items?.encounter_created, x: 493, y: 745 },
      { text: formatAllergy, x: 389, y: 714 },
      { text: props.items?.attendingDoctor, x: 422, y: 728 },
      { text: props.items?.operatingRoom, x: 542, y: 728 },
    ];

    for (const { text, x, y } of textCoordinates) {
      firstPage.drawText(text || "", {
        x,
        y,
        size: fontSize,
        font: customFont,
        color: rgb(0, 0, 0),
        lineHeight: 14,
      });
    }

    const modifiedPdfBytes = await pdfDoc.save();
    const getAsBase64 = await pdfDoc.saveAsBase64();
    const blob = new Blob([modifiedPdfBytes], { type: "application/pdf" });
    const modifiedPdfUrl = URL.createObjectURL(blob);

    return { uint8Array: modifiedPdfBytes, blobUrl: modifiedPdfUrl, blobRaw: blob, base64String: getAsBase64 }
  } catch (error) {
    console.error("Error modifying PDF: ", error);

    return {};
  }
}

export default FormPriceEstimateTH;