import PropTypes from "prop-types";
import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import { Button, Input, Form, Modal, Label, Dropdown, Checkbox, Grid } from "semantic-ui-react";
import { toast } from "react-toastify";
import _ from "react-lib/compat/lodashplus";
import * as utils from "react-lib/utils";
import moment from "moment";

import CardLayout from "react-lib/apps/common/CardLayout";
import DateTextBox from "react-lib/apps/common/DateTextBox";
import RightLabel from "react-lib/apps/common/RightLabel";
import PureReactTable from "react-lib/apps/common/PureReactTable";
import { formatPrice, formatComma } from "react-lib/apps/common/PureReactTable";
import { useIntl } from "react-intl";

const columns = [
	{
		Header: "Date Transaction",
		width: 150,
		Cell: props => {
			let data = props.original;
			let date = new Date(data.invoice_datetime);
			return <label>{utils.formatToBEDatetime({ date })}</label>;
		},
	},
	{ Header: "Hospital Code", accessor: "hospital_code", width: 130 },
	{ Header: "Encounter ID.", accessor: "encounter", width: 130 },
	{ Header: "HN", accessor: "hn", width: 120 },
	{ Header: "AN", accessor: "an", width: 120 },
	{ Header: "Amount", accessor: "total_amount_price", Cell: row => formatPrice(row.value) },
	{ Header: "Claim Amount", accessor: "total_send_claim_price", Cell: row => formatPrice(row.value) },
	{ Header: "Paid", accessor: "total_paid_price", Cell: row => formatPrice(row.value) },
	{ Header: "Personal ID", accessor: "personal_id", width: 150 },
	{ Header: "Name", accessor: "name", width: 220 },
];

var refreshIntervalId = null;
const CardCreateARTransaction = props => {
	const intl = useIntl();
	const isMounted = useRef(true);
	const [loading, setLoading] = useState(false);
	const [choiceCoveragePayer, setChoiceCoveragePayer] = useState([]);
	const [startDateCreated, setStartDateCreated] = useState(
		moment()
			.add(543, "year")
			.format("DD/MM/YYYY")
	);
	const [endDateCreated, setEndDateCreated] = useState(
		moment()
			.add(543, "year")
			.format("DD/MM/YYYY")
	);
	const [filterSearchDate, setFilterSearchDate] = useState(false);
	const [startDateSearch, setStartDateSearch] = useState(
		moment()
			.add(543, "year")
			.format("DD/MM/YYYY")
	);
	const [endDateSearch, setEndDateSearch] = useState(
		moment()
			.add(543, "year")
			.format("DD/MM/YYYY")
	);

	const [transactionSum, setTransactionSum] = useState({});
	const [summaryLoading, setSummaryLoading] = useState(false);
	// Handle table
	const [transactionItems, setTransactionItems] = useState([]);
	const [tableLoading, setTableLoading] = useState(false);
	const [page, setPage] = useState(1);
	const [pageTotal, setPageTotal] = useState(1);
	const [pageSize, setPageSize] = useState(10);

	const coveragePayerRef = useRef();

	const loadCoveragePayerChoices = async () => {
		const choices = await props.controller.choiceCoveragePayer();
		setChoiceCoveragePayer(choices);
	};

	/** Handle event DateTextBox changed */
	const handleCreateStartDateChange = date => {
		setStartDateCreated(date);
	};

	const handleCreateEndDateChange = date => {
		setEndDateCreated(date);
	};

	const handleSearchStartDateChange = date => {
		setStartDateSearch(date);
	};

	const handleSearchEndDateChange = date => {
		setEndDateSearch(date);
	};

	const handleFilterSearchDate = (event, data) => {
		setFilterSearchDate(data.checked);
	};

	/** Handle pagination */
	const handlePageChange = pageNo => {
		let params = getSearchParams({ targetPage: pageNo + 1 });
		searchBillTransaction(params);
	};

	const handlePageSizeChange = pageSize => {
		setPageSize(pageSize);
		let params = getSearchParams({ targetPageSize: pageSize });
		searchBillTransaction(params);
	};

	const getSearchParams = ({ targetPage, targetPageSize } = {}) => {
		let coveragePayer = coveragePayerRef.current.getSelectedItem();
		var params = {
			page: targetPage ? targetPage : page,
			pageSize: targetPageSize ? targetPageSize : pageSize,
			coverageGroupId: coveragePayer.id,
		};
		if (filterSearchDate) {
			params.startDate = startDateSearch;
			params.endDate = endDateSearch;
		}

		return params;
	};

	/** Handle "ค้นหา" button */
	const handleSearch = async (event, data) => {
		let params = getSearchParams();
		searchBillTransaction(params);
		loadARTransactionSummary(params);
	};

	/** Handle "สร้างข้อมูลส่งเบิก" button */
	const handleCreate = async (event, data) => {
		setLoading(true);
		let coveragePayer = coveragePayerRef.current.getSelectedItem();
		const [response, error] = await props.controller.generateSentClaimDataIPD(
			coveragePayer.id,
			startDateCreated,
			endDateCreated
		);
		if (!isMounted.current) {
			return;
		}
		if (error) {
			toast.error("สร้างข้อมูลส่งเบิกไม่สำเร็จ!");
			setLoading(false);
		}
	};

	/** Handle "ตั้งหนี้" button */
	const handleCreateARTransaction = async (event, data) => {
		setLoading(true);
		let params = getSearchParams();
		const [response, error] = await props.controller.createARTransaction(params);
		if (!isMounted.current) {
			return;
		}
		if (error) {
			toast.error("ตั้งหนี้ไม่สำเร็จ!");
		} else {
			toast.success("ตั้งหนี้สำเร็จ");
			// Refresh data
			searchBillTransaction(params);
			loadARTransactionSummary(params);
		}
		setLoading(false);
	};

	const searchBillTransaction = async params => {
		setTableLoading(true);
		const [data, error] = await props.controller.searchBillTransaction(params);
		if (!isMounted.current) {
			return;
		}
		var items = [];
		if (data) {
			items = data.items;
			setPage(data.page);
			setPageTotal(data.total);
		}
		setTransactionItems(items);
		setTableLoading(false);
	};

	const loadARTransactionSummary = async params => {
		// Set loading and clear data
		setSummaryLoading(true);
		setTransactionSum({});

		const [data, error] = await props.controller.loadARTransactionSummary(params);
		if (!isMounted.current) {
			return;
		}
		if (data) {
			setTransactionSum(data.items);
		}
		setSummaryLoading(false);
	};

	// For Polling
	const loadARTransaction = async () => {
		if (!props.controller) {
			return;
		}

		const [data, error] = await props.controller.loadARTransaction();
		if (!isMounted.current) {
			clearInterval(refreshIntervalId);
		}
		if (data) {
			setLoading(!data.is_allowed);
		}
	};

	useEffect(() => {
		if (props.controller) {
			loadCoveragePayerChoices();
		}
	}, [props.controller]);

	useEffect(() => {
		refreshIntervalId = setInterval(() => loadARTransaction(), 10000);
		return () => {
			isMounted.current = false;
		};
	}, []);

	return (
		<CardLayout titleText={props.cardTitle} headerColor={"blue"} toggleable={true} closeable={false} loading={loading}>
			<Form>
				{/* Create sent claim info */}
				<Form.Group inline style={STYLE.FORM_MARGIN}>
					<label>{intl.formatMessage({ id: "ระบุวันที่สร้างข้อมูลส่งเบิก" })}</label>
					<Form.Field width={3} control={DateTextBox} value={startDateCreated} onChange={handleCreateStartDateChange} />
					<label>{intl.formatMessage({ id: "ถึง" })}</label>
					<Form.Field width={3} control={DateTextBox} value={endDateCreated} onChange={handleCreateEndDateChange} />
					<Form.Field>
						<Button fluid color={"purple"} onClick={handleCreate}>{intl.formatMessage({ id: "สร้างข้อมูลส่งเบิก" })}</Button>
					</Form.Field>
				</Form.Group>

				{/* ค้นหา */}
				<Form.Group inline style={STYLE.FORM_MARGIN}>
					<Form.Field width={4}>
						<label>{intl.formatMessage({ id: "เลือกสิทธิ" })}</label>
						<Dropdown fluid selection ref={coveragePayerRef} defaultValue={"GGO_IPD"} options={choiceCoveragePayer} />
					</Form.Field>
					<Form.Field width={1} />
					<Form.Field
						control={Checkbox}
						label={"ระบุวันที่ Discharge"}
						checked={filterSearchDate}
						onChange={handleFilterSearchDate}
					/>
					<Form.Field
						width={2}
						control={DateTextBox}
						value={startDateSearch}
						disabled={!filterSearchDate}
						onChange={handleSearchStartDateChange}
					/>
					<label>{intl.formatMessage({ id: "ถึง" })}</label>
					<Form.Field
						width={2}
						control={DateTextBox}
						value={endDateSearch}
						disabled={!filterSearchDate}
						onChange={handleSearchEndDateChange}
					/>
					<Form.Field>
						<Button fluid color={"blue"} onClick={handleSearch}>{intl.formatMessage({ id: "ค้นหา" })}</Button>
					</Form.Field>
				</Form.Group>
			</Form>

			<PureReactTable
				style={STYLE.FORM_MARGIN}
				loading={tableLoading}
				pageSize={pageSize}
				pages={pageTotal}
				page={page - 1}
				onPageSizeChange={handlePageSizeChange}
				onPageChange={handlePageChange}
				data={transactionItems}
				columns={columns}
			/>

			<Form>
				<Form.Group inline style={{ ...STYLE.FORM_MARGIN, ...STYLE.ELEMENT_RIGHT }}>
					<Form.Field>
						<RightLabel>{intl.formatMessage({ id: "จำนวนข้อมูลที่ส่งเบิกได้" })}</RightLabel>
					</Form.Field>
					<Input
						className={"priceInput"}
						style={{ width: "12%" }}
						loading={summaryLoading}
						value={transactionSum.total_rows !== undefined ? transactionSum.total_rows : ""}
					/>

					<Form.Field style={STYLE.FIELD_MARGIN}>
						<RightLabel>{intl.formatMessage({ id: "ยอดเบิก" })}</RightLabel>
					</Form.Field>
					<Input
						className={"priceInput"}
						style={{ width: "12%" }}
						loading={summaryLoading}
						value={
							transactionSum.total_sent_claim_price !== undefined
								? formatComma(transactionSum.total_sent_claim_price)
								: ""
						}
					/>

					<Form.Field style={STYLE.FIELD_MARGIN}>
						<RightLabel>{intl.formatMessage({ id: "ยอดผู้ป่วยจ่าย" })}</RightLabel>
					</Form.Field>
					<Input
						className={"priceInput"}
						style={{ width: "12%" }}
						loading={summaryLoading}
						value={
							transactionSum.total_paid_price !== undefined 
								? formatComma(transactionSum.total_paid_price) 
								: ""
						}
					/>

					<Form.Field style={STYLE.FIELD_MARGIN}>
						<RightLabel>{intl.formatMessage({ id: "ยอดร่วมจ่าย" })}</RightLabel>
					</Form.Field>
					<Input
						className={"priceInput"}
						style={{ width: "12%" }}
						loading={summaryLoading}
						value={
							transactionSum.total_other_pay_price !== undefined
								? formatComma(transactionSum.total_other_pay_price)
								: ""
						}
					/>
				</Form.Group>

				{/* Line 5 */}
				<Form.Group inline style={STYLE.ELEMENT_RIGHT}>
					<Button color={"green"} onClick={handleCreateARTransaction}>{intl.formatMessage({ id: "ตั้งหนี้" })}</Button>
				</Form.Group>
			</Form>
		</CardLayout>
	);
};

const STYLE = {
	FORM_MARGIN: {
		marginBottom: "16px",
	},
	FIELD_MARGIN: {
		marginLeft: "24px",
	},
	ELEMENT_RIGHT: {
		flexDirection: "row",
		justifyContent: "flex-end",
	},
};

CardCreateARTransaction.defaultProps = {
	cardTitle: "สร้างสิทธิตั้งเบิก",
	controller: null,
};

CardCreateARTransaction.propTypes = {
	cardTitle: PropTypes.string,
	controller: PropTypes.object,
};

export default React.memo(CardCreateARTransaction);
