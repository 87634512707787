import * as ARInvoiceGroupI from "./ARInvoiceGroup";
import * as ExportEClaimFileI from "./ExportEClaimFile";
import * as DownloadSentClaimFileI from "./DownloadSentClaimFile";
import * as ImportLabValuesCalcDFI from "./ImportLabValuesCalcDF";
import * as DoctorFeeSummaryI from "./DoctorFeeSummary";
import * as DoctorFeeReportI from "./DoctorFeeReport";
import * as UploadPatientCoverageI from "./UploadPatientCoverage";
import * as ClaimTaxDocumentI from "./ClaimTaxDocument";
import * as AIPNSendClaimI from "./AIPNSendClaim";
import * as SendClaimResponseFileI from "./SendClaimResponseFile";
import * as CostCenterReceiptI from "./CostCenterReceipt";
import * as SapAccountingReportI from "./SapAccountingReport";
import * as SendClaimDownloadZipFileI from "./SendClaimDownloadZipFile";

const SequencePattern: {
  [name: string]: { [flavor: string]: { [func: string]: any } };
} = {
  ARInvoiceGroup: {
    default: {
      START: ARInvoiceGroupI.Start,
      IssueInv: ARInvoiceGroupI.IssueInv,
      InvIssued: ARInvoiceGroupI.InvIssued,
    },
  },
  ExportEClaimFile: {
    default: {
      START: ExportEClaimFileI.GetMaster,
      ACTION: ExportEClaimFileI.Action,
    },
  },
  DownloadSentClaimFile: {
    default: {
      START: DownloadSentClaimFileI.GetMaster,
      ACTION: DownloadSentClaimFileI.Action,
    },
  },
  ImportLabValuesCalcDF: {
    default: {
      START: ImportLabValuesCalcDFI.GetMaster,
      Action: ImportLabValuesCalcDFI.Action,
    },
  },
  DoctorFeeSummary: {
    default: {
      START: DoctorFeeSummaryI.Start,
      Create: DoctorFeeSummaryI.Create,
      Fee: DoctorFeeSummaryI.Fee,
      Summary: DoctorFeeSummaryI.Summary,
    },
  },
  DoctorFeeReport: {
    default: {
      START: DoctorFeeReportI.Start,
      Description: DoctorFeeReportI.Description,
      Overview: DoctorFeeReportI.Overview,
    },
  },
  UploadPatientCoverage: {
    default: {
      START: UploadPatientCoverageI.GetMaster,
      Action: UploadPatientCoverageI.Action,
    },
  },
  ClaimTaxDocument: {
    default: {
      START: ClaimTaxDocumentI.Start,
      Action: ClaimTaxDocumentI.Action,
    },
  },
  AIPNSendClaim: {
    default: {
      START: AIPNSendClaimI.GetMaster,
      Action: AIPNSendClaimI.Action,
    },
  },
  SendClaimResponseFile: {
    default: {
      START: SendClaimResponseFileI.Start,
      EClaim: SendClaimResponseFileI.EClaim,
      AIPN: SendClaimResponseFileI.AIPN,
    },
  },
  CostCenterReceipt: {
    default: {
      START: CostCenterReceiptI.Start,
      Action: CostCenterReceiptI.Action,
    },
  },
  SapAccountingReport: {
    default: {
      START: SapAccountingReportI.Start,
      Action: SapAccountingReportI.Action,
    },
  },
  SendClaimDownloadZipFile: {
    default: {
      START: SendClaimDownloadZipFileI.GetMaster,
      Action: SendClaimDownloadZipFileI.Action,
    },
  },
};

export default SequencePattern;
