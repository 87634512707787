import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button,
  Icon
} from 'semantic-ui-react'

const CardStationSummaryUX = (props: any) => {
    return(
      <div
        style={{ width : "100%", height: "100%" , padding: "10px"}}>
        <div>
          <div
            style={{fontWeight: "bold", fontSize: "1.2rem"}}>
            รายงานสรุปยอดและปิดกะ
          </div>
          <div
            className="ui divider">
            
          </div>
          <Table
            data={props.stationSummaryItems}
            headers="วันที่-เวลา,เลขที่เอกสาร,สถานะ,เงินสด,เงินโอน,บัตร,เช็ค,คูปอง, คืนเงิน"
            keys="receipt_date, receipt_no, receipt_status, payment_cash, payment_transfer, payment_credit, payment_cheque, payment_coupon, payment_refund"
            showPagination={false}
            style={{height: "30rem"}}>
          </Table>
          <div
            className="ui divider">
            
          </div>
          <div>
            {props.ErrorMessage}
          </div>
          <div
            style={{display: "flex", justifyContent: "flex-end"}}>
            
            <Button
              className="icon"
              color={"blue"}
              onClick={props.onRefresh}>
              <Icon
                name={"refresh"}>
              </Icon>
              <label
                style={{marginLeft: "5px"}}>
                refresh
              </label>
            </Button>
            <Button
              color="blue"
              onClick={props.printSummarySheet}>
              พิมพ์ใบสรุปยอดระหว่างกะ
            </Button>
            <Button
              color="green"
              onClick={props.offShift}>
              ปิดกะและพิมพ์ใบสรุปยอด
            </Button>
          </div>
        </div>
      </div>
    )
}

export default CardStationSummaryUX

export const screenPropsDefault = {"cannotPay":false,"cashAmountText":"14","citizenID":"31020000552","oweText":123,"patientNameAndHN":"HN: xxxxx นายทดสอบ","sumAmount":{"price":100,"reimbursable":42}}

/* Date Time : Tue May 31 2022 10:15:41 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width : \"100%\", height: \"100%\" , padding: \"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "รายงานสรุปยอดและปิดกะ"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.2rem\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 4,
      "name": "Table",
      "parent": 1,
      "props": {
        "data": {
          "type": "code",
          "value": "props.stationSummaryItems"
        },
        "headers": {
          "type": "value",
          "value": "วันที่-เวลา,เลขที่เอกสาร,สถานะ,เงินสด,เงินโอน,บัตร,เช็ค,คูปอง, คืนเงิน"
        },
        "keys": {
          "type": "value",
          "value": "receipt_date, receipt_no, receipt_status, payment_cash, payment_transfer, payment_credit, payment_cheque, payment_coupon, payment_refund"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"30rem\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "Button",
      "parent": 7,
      "props": {
        "className": {
          "type": "value",
          "value": "icon"
        },
        "color": {
          "type": "code",
          "value": "\"blue\""
        },
        "onClick": {
          "type": "code",
          "value": "props.onRefresh"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "Button",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์ใบสรุปยอดระหว่างกะ"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.printSummarySheet"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "Button",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "ปิดกะและพิมพ์ใบสรุปยอด"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.offShift"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "Icon",
      "parent": 8,
      "props": {
        "name": {
          "type": "code",
          "value": "\"refresh\""
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "refresh"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"5px\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ErrorMessage"
        }
      },
      "seq": 7,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 45,
  "isMounted": false,
  "memo": false,
  "name": "CardStationSummaryUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "cannotPay": false,
    "cashAmountText": "14",
    "citizenID": "31020000552",
    "oweText": 123,
    "patientNameAndHN": "HN: xxxxx นายทดสอบ",
    "sumAmount": {
      "price": 100,
      "reimbursable": 42
    }
  },
  "width": 60
}

*********************************************************************************** */
