import React from "react";
import PropTypes from "prop-types";
import * as Chat from "../Chat";
import { useIntl } from "react-intl";

const ChatDetail = props => {

  const genUserList = () => {
    if(!props.userData.items){
      return
    }
    return props.userData.items.map((item) => {
      if(parseInt(item.user_obj.id) === parseInt(props.userId)){
        return null
      }
      return <Chat.UserProfile key={item.id} user={item} userId={props.userId}/>
    })
  }

  return (
    <div style={{ margin: "2%" }}>
      {genUserList()}
    </div>
  );
};

ChatDetail.defaultProps = {
  userData: {},
  userError: null,
  userLoading: false,
  userId: null,
};

ChatDetail.propTypes = {
  userData: PropTypes.object,
  userError: PropTypes.object,
  userLoading: PropTypes.bool,
  userId: PropTypes.string,
};

export default React.memo(ChatDetail);
