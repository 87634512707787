import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  MutableRefObject,
} from "react";
import { Form, Button, Grid, Input, Header, Icon, Modal, Dropdown, Label } from "semantic-ui-react";

import ReactTable from "react-table-6";
import { Table } from "react-lib/frameworks/Table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { toast } from "react-toastify";
import moment from "moment";
import $ from "jquery";

// Common
import CardLayout from "react-lib/apps/common/CardLayout";
import ModInfo from "react-lib/apps/common/ModInfo";

// CSS
import "react-table-hoc-fixed-columns/lib/styles.css";
import "react-lib/css/ReactTableFixedColumns.css";

// Utils
import { formatUTCtoBEString } from "react-lib/utils/dateUtils";
import { formatDate } from "react-lib/utils/dateUtils";
import { adToBe, beToAd } from "react-lib/utils";
import { useIntl } from "react-intl";

import {
  getVitalSignFiles,
  SourceVitalSignData,
  ConsolidatedData,
  FileDetails,
  MergedVitalSignData,
  ConvertValue,
  groupAndAverageValues,
  consolidateVitalSigns,
} from "./VitalSignService";

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const CardHealthMonitor = React.forwardRef(
  (
    props: {
      closeable?: boolean;
      toggleable?: boolean;
      cardStyle?: object;
      hideHeaderIcon?: boolean;
      headerColor?: string;
      selectedPatient?: object

      // Function
      hideCallback?: any;
      setProp?: any;
      buttonLoadCheck?: any;
    },
    ref
  ) => {
    const intl = useIntl();
    const [isLoading, setIsLoading] = useState(false);
    const [showModInfo, setShowModInfo] = useState(false);
    const [alertText, setAlertText] = useState("");
    const [alertTitleText, setAlertTitleText] = useState("");
    const [alertTitleColor, setAlertTitleColor] = useState("yellow");
    const [vitalSignData, setVitalSignData] = useState<ConsolidatedData>({});

    const [tableColumnData, setTableColumnData] = useState<any>([]);
    const [tableRowData, setTableRowData] = useState<any>([]);
    const [rowSelected, setRowSelected] = useState<any>({});
    const [selectedCell, setSelectedCell] = useState<any>({});
    const [vitalSignFiles, setVitalSignFiles] = useState<{ [key: string]: FileDetails[] }>({});
    const [openDetailTable, setOpenDetailTable] = useState(false);
    const [selectedDetailData, setSelectedDetailData] = useState<any>({});
    const [currentPage, setCurrentPage] = useState(0);
    const columnsPerPage = 7;

    const myRef = useRef<any>(null);
    const isMounted = useRef(false);
    const divRef = useRef() as MutableRefObject<HTMLDivElement>;
    const rightRef = useRef() as MutableRefObject<HTMLDivElement>;

    const rangeOptions = [
      {
        key: "7",
        value: "7",
        text: "7 วัน",
        default: true, // Indicate this as the default option
      },
      {
        key: "14",
        value: "14",
        text: "14 วัน",
      },
      {
        key: "30",
        value: "30",
        text: "1 เดือน",
      },
      {
        key: "90",
        value: "90",
        text: "3 เดือน",
      },
      {
        key: "180",
        value: "180",
        text: "6 เดือน",
      },
      {
        key: "365",
        value: "365",
        text: "1 ปี",
      },
    ];

    const [selectedRange, setSelectedRange] = useState(
      rangeOptions.find((option) => option.default)?.value || rangeOptions[0].value
    );

    const handleRageChange = (e, { value }) => {
      setSelectedRange(value);
      setCurrentPage(0);
    };

    useEffect(() => {
      createTableHeader();
      createTableRow();

      if (vitalSignData) {
        // createChartLabel();
      } else {
        // setTableColumnData([])
        // setTableRowData([])
      }
    }, [vitalSignData, currentPage]);

    // useEffect(() => {
    //   createTableHeader();

    //   const timer = setTimeout(() => {
    //     scrollToRight();
    //   }, 2000);
    //   return () => clearTimeout(timer);
    // }, [selectedCell, originalTableRowData, tableRowData]);

    useEffect(() => {
      let key = Object.keys(selectedCell)[0];
      let value = selectedCell[key];
      fetchFromGroupAndDate(key, value);
    }, [selectedCell]);

    const fetchFiles = async (patientId: string, selectedRange: string) => {
      setIsLoading(true);
      const { filesList, vitalSignData } = await getVitalSignFiles(patientId, selectedRange);
      setVitalSignFiles(filesList);
      const consolidatedVitalSignData = consolidateVitalSigns(Object.values(vitalSignData).flat());
      setVitalSignData(consolidatedVitalSignData);
      setIsLoading(false);
    };

    const getVitalSign = async () => {
      console.log("getVitalSign patientId = ", props.selectedPatient?.id, "range", selectedRange);
      fetchFiles("1461", selectedRange);
      // fetchFiles(props.selectedPatient?.id, selectedRange)
    };

    const scrollToRight = () => {
      var rtTable: any = $("." + myRef.current.uniqClassName + " > .rt-table").first();
      var rtTBody: any = $("." + myRef.current.uniqClassName + " .rt-tbody").first();
      rtTable.scrollLeft(rtTBody.width() - rtTable.width());
    };

    const fetchFromGroupAndDate = (dateKey: string, type: string) => {
      if (!dateKey && !type) {
        return;
      }
      const groupedValues = groupAndAverageValues(Object.values(vitalSignData));
      let detail = groupedValues[dateKey][type];
      console.log(detail);
      if (detail) {
        setOpenDetailTable(true);
      }
      setSelectedDetailData(detail);
    };

    const createTableHeader = () => {
      let headerColumn: any[] = [
        {
          Header: "Parameter",
          accessor: "name",
          fixed: "left",
          resizable: false,
          width: 200,
        },
      ];

      // const allValues = Object.values(vitalSignData).flatMap((v) => v.values);
      console.log("vitalSignData", vitalSignData);
      const groupedValues = groupAndAverageValues(Object.values(vitalSignData));
      console.log(groupedValues);

      const sortedDates = Object.keys(groupedValues).sort((a, b) => {
        return moment(b).diff(moment(a)); // Sort dates in descending order (newest first)
      });

      // Slice dates for the current page
      const paginatedDates = sortedDates.slice(
        currentPage * columnsPerPage,
        (currentPage + 1) * columnsPerPage
      );

      paginatedDates.forEach((dateKey) => {
        headerColumn.push({
          Header: () => {
            return (
              <div>
                <label>{moment(dateKey).format("YYYY-MM-DD")}</label>
              </div>
            );
          },
          accessor: dateKey,
          className: "noPadding",
          resizable: false,
          width: 90,
          Cell: (props: any) => {
            return (
              <div className="reactTablePadding">
                {props.original[dateKey]?.value ? props.original[dateKey]?.value : "-"}
              </div>
            );
          },
        });
      });

      setTableColumnData(headerColumn);
    };

    const createTableRow = () => {
      const groupedValues = groupAndAverageValues(Object.values(vitalSignData));

      const rowData = Object.keys(vitalSignData).map((vitalSignKey) => {
        const vitalSign = vitalSignData[vitalSignKey];
        const info: any = {
          code: vitalSign.name,
          name: `${vitalSign.name} (${vitalSign.unitType})`,
        };

        // Object.keys(groupedValues).forEach((dateKey) => {
        //   const valueObj = groupedValues[dateKey][vitalSign.name];
        //   if (valueObj) {
        //     info[dateKey] = {
        //       value: valueObj.value,
        //       color: "#000000",
        //     };
        //   }
        // });

        const sortedDates = Object.keys(groupedValues).sort((a, b) => {
          return moment(b).diff(moment(a)); // Sort dates in descending order (newest first)
        });

        const paginatedDates = sortedDates.slice(
          currentPage * columnsPerPage,
          (currentPage + 1) * columnsPerPage
        );

        paginatedDates.forEach((dateKey) => {
          const valueObj = groupedValues[dateKey][vitalSign.name];
          if (valueObj) {
            info[dateKey] = {
              value: valueObj.value,
              color: "#000000",
            };
          }
        });

        return info;
      });

      setTableRowData(rowData);
    };

    const DetailLog = (props: any) => {
      const _data = useMemo(
        () =>
          (selectedDetailData?.original || [])?.map((value: any) => ({
            ...value,
            datetime: formatUTCtoBEString(value.timeStampISO)?.join(" "),
          })),
        [selectedDetailData?.original]
      );
      return (
        <div style={{ padding: "10px" }}>
          <Header>
            {`ประวัติการวัดผล`}
            <Label color="yellow">{selectedDetailData?.valueName}</Label>
          </Header>
          <Table
            data={_data}
            headers="วันที่/เวลา,ค่าที่วัด"
            keys="datetime,value"
            minRows="10"
            showPagination={false}
            style={{ height: "300px" }}
          ></Table>
        </div>
      );
    };

    return (
      <div ref={divRef} style={{ width: "" }}>
        <div ref={rightRef} style={{ position: "fixed", right: 0 }}></div>

        <CardLayout
          titleText="Health Monitor"
          closeable={props.closeable}
          toggleable={props.toggleable}
          loading={isLoading}
          onClose={props.hideCallback}
          style={props.cardStyle}
          hideHeaderIcon={props.hideHeaderIcon}
          headerColor={props.headerColor}
          dimmerStyle={{ minWidth: "max-content" }}
        >
          <ModInfo
            open={showModInfo}
            titleColor={alertTitleColor}
            alertText={alertText}
            titleName={alertTitleText}
            onApprove={() => setShowModInfo(false)}
          />
          <Form>
            <Form.Group inline>
              <Form.Field inline="true" width="4">
                <label style={{ minWidth: "fit-content" }}>{intl.formatMessage({ id: "ดูข้อมูลย้อนหลัง" })}</label>
                <Dropdown
                  placeholder="เลือกช่วงเวลา"
                  fluid
                  selection
                  options={rangeOptions}
                  value={selectedRange}
                  onChange={handleRageChange}
                  style={{ width: "100%" }}
                />
              </Form.Field>

              <Button
                color="blue"
                onClick={(e) => {
                  getVitalSign();
                }}
              >
                ค้นหา
              </Button>
            </Form.Group>
          </Form>

          <Grid columns={1}>
            <Grid.Column style={{ width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "right",
                  marginTop: "1rem",
                }}
              >
                <Button
                  size="large"
                  icon="step backward"
                  onClick={() => setCurrentPage(Math.max(0, currentPage - 1))}
                  disabled={currentPage === 0} // Disable button if on the first page
                />
                <Button
                  size="large"
                  icon="step forward"
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={
                    (currentPage + 1) * columnsPerPage >= Object.keys(groupAndAverageValues(Object.values(vitalSignData))).length // Disable button if on the last page
                  }
                />
              </div>
              <ReactTableFixedColumns
                ref={myRef}
                style={{ height: "390px" }}
                showPagination={false}
                data={tableRowData}
                columns={tableColumnData}
                className={"fix-height"}
                pageSize={7}
                getTrGroupProps={(state, rowInfo) => {
                  if (rowInfo && rowInfo.row) {
                    return {
                      style: { height: "auto" },
                    };
                  } else {
                    return {};
                  }
                }}
                getTrProps={(state, rowInfo, column) => {
                  if (rowInfo && rowInfo.row) {
                    let background =
                      rowInfo.index === (rowSelected ? rowSelected.index : null)
                        ? "blueSelectedRow"
                        : "";
                    return {
                      onClick: (e) => {
                        if (rowInfo.original?.code !== rowSelected.original?.code) {
                          setRowSelected(rowInfo);
                        }
                      },
                      className: background,
                    };
                  } else {
                    return {};
                  }
                }}
                getTdProps={(state, rowInfo, column, instance) => {
                  return {
                    onClick: (e, handleOriginal) => {
                      if (!rowInfo || !column) {
                        return;
                      }
                      setSelectedCell({ [column.id]: rowInfo.original?.code });
                    },
                  };
                }}
              />
            </Grid.Column>
          </Grid>
          <Grid columns={2}>
            <>{/* Footer */}</>
          </Grid>
        </CardLayout>
        <div>
          <h1>Vital Sign Files</h1>
          {Object.keys(vitalSignFiles).map((type) => (
            <div key={type}>
              <h2>{type}</h2>
              <ul>
                {vitalSignFiles[type].map((file) => (
                  <li key={file.name}>
                    <a href={file.url} target="_blank" rel="noopener noreferrer">
                      {file.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        <Modal
          size="large"
          open={openDetailTable}
          onClose={() => {
            setOpenDetailTable(false);
          }}
        >
          <DetailLog />
        </Modal>
      </div>
    );
  }
);

CardHealthMonitor.displayName = "CardHealthMonitor";
export default React.memo(CardHealthMonitor);
