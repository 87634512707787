import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Checkbox,
  Label,
  Dropdown,
  Button,
  Form,
  FormGroup,
  FormField
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardBillingHistoryUX = (props: any) => {
    return(
      <div
        style={{ zoom: "90%" }}>
        <div
          style={{fontWeight: "bold", fontSize: "1.2rem",padding: "10px 10px 0px 10px", display: "flex", justifyContent: "space-between"}}>
          BILLING - HISTORY
          <div>
            ประวัติการชำระเงินค่ารักษาพยาบาล
          </div>
        </div>
        <div
          style={{padding: "0px 10px"}}>
          
          <hr>
          </hr>
        </div>
        <div
          style={{display:"flex", justifyContent:"flex-end",alignItems:"center", padding: "10px 0px 20px 0px"}}>
          
          <Checkbox
            checked={props.divisionCheck}
            label="หน่วยงาน"
            onChange={props.onChangeDivisionCheck}
            style={{padding:"0px 10px"}}>
          </Checkbox>
          <div
            style={{width: "170px", margin: "0px 5px"}}>
            
            <Dropdown
              fluid="true"
              onChange={props.onChangeDivision}
              options={props.division}
              search={true}
              selection={true}
              value={props.divisionSelect}>
            </Dropdown>
          </div>
          <Checkbox
            checked={props.statusCheck}
            label="สถานะ"
            onChange={props.onChangeStatusCheck}
            style={{padding:"0px 5px"}}>
          </Checkbox>
          <div
            style={{width: "170px", margin: "0px 5px"}}>
            
            <Dropdown
              fluid="true"
              onChange={props.onChangeStatus}
              options={props.statusOptions}
              selection={true}
              value={props.status}>
            </Dropdown>
          </div>
          <div
            style={{width: "170px", margin: "0px 5px"}}>
            
            <Dropdown
              fluid="true"
              id="cb-billingType"
              onChange={props.onChangeType}
              options={props.typeOptions}
              selection={true}
              value={props.type}>
            </Dropdown>
          </div>
          <Checkbox
            checked={props.dateCheck}
            label="วันที่ออกเอกสาร"
            onChange={props.onChangeDateCheck}
            style={{padding:"0px 5px"}}>
          </Checkbox>
          <DateTextBox
            onChange={props.onChangeStartDate}
            style={{padding:"0px 5px",}}
            value={props.startDate}>
          </DateTextBox>
          <DateTextBox
            onChange={props.onChangeEndDate}
            style={{padding:"0px 5px",}}
            value={props.endDate}>
          </DateTextBox>
          <div>
            {props.buttonSearch}
          </div>
        </div>
        <Table
          className="--rt-tbody-overflow-y-hidden"
          data={props.billingData}
          getTrProps={props.onSelectedItems}
          headers="ลำดับ, รหัสเอกสาร, ประเภทเอกสารค่าใช้จ่าย, ผู้ป่วย, วันที่-เวลา ออกเอกสาร, มูลค่า, สถานะ, หน่วยงาน, กะ"
          id="tb-billHistory"
          keys="no,code,type_name,patient_name,created,price,status_label,division_name,station_name"
          minRows={7}
          showPagination={false}
          style={{height: "300px",  zoom: "87%", margin: "0px 10px", ...(props.tableStyle || {})}}>
        </Table>
        <div
          style={{paddingTop: "20px", display: props.receiptId === "" ?  "none" : "block", ...(props.selectedReceipt?.type_name === "DEPOSIT" && { display: "none" }) }}>
          
          <div
            style={{fontWeight: "bold", fontSize: "1.2rem",padding: "10px", display: "flex", justifyContent: "space-between"}}>
            {props.showBillType === "credit_note" ? "CREDIT NOTE" :  props.showBillType === "invoice" ? "INVOICE" : props.showBillType === "receipt" ? "RECEIPT" : ""}
            <div>
              {props.showBillType === "credit_note" ? "ข้อมูลรายการใบสำคัญคืนเงิน" :  "ข้อมูลใบเสร็จค่ารักษาพยาบาล"}
            </div>
          </div>
          <div
            style={{display: props?.searchHistorySelected  ? "flex" :"none" ,alignItems:"center", margin: "10px 10px", fontWeight: "normal", fontSize: "1.2rem"}}>
            
            <div
              style={{margin: "0px 10px 0px 0px"}}>
              เลขที่เอกสาร
            </div>
            <Label>
              {props.editCode}
            </Label>
            <div
              style={{margin: "0px 10px 0px 30px"}}>
              encounter
            </div>
            <Label
              name={{margin: "0px 20px 0px 0px"}}>
              {props.editEncounter}
            </Label>
            <div
              style={{margin: "0px 10px 0px 30px"}}>
              แพทย์
            </div>
            <Label
              name={{margin: "0px 20px 0px 0px"}}>
              {props.editDoctor}
            </Label>
            <div
              style={{margin: "0px 10px 0px 30px"}}>
              หน่วยงานสั่ง
            </div>
            <Label
              name={{margin: "0px 20px 0px 0px"}}>
              {props.editOrderDiv}
            </Label>
            <div
              style={{margin: "0px 10px 0px 30px"}}>
              หน่วยงานจ่าย
            </div>
            <Label
              name={{margin: "0px 20px 0px 0px"}}>
              {props.editOrderPerformDiv}
            </Label>
          </div>
          <div
            style={{ margin: "0px 10px 10px", ...(props.selectedReceipt?.type_name !== "PAYMENT" && { display: "none" })  }}>
            
            <Form>
              <FormGroup
                inline={true}
                style={{ margin: "0px" }}>
                <FormField
                  width={2}>
                  <div
                    style={{ fontWeight: "bold", marginRight: "10px" }}>
                    เลขที่ใบเสร็จ
                  </div>
                </FormField>
                <FormField
                  width={2}>
                  <div
                    style={{ fontWeight: "bold", margin: "0px 10px" }}>
                    Encounter
                  </div>
                </FormField>
                <FormField
                  width={3}>
                  <div
                    style={{ fontWeight: "bold", margin: "0px 10px" }}>
                    หน่วยงาน
                  </div>
                </FormField>
                <FormField
                  width={2}>
                  <div
                    style={{ fontWeight: "bold", margin: "0px 10px" }}>
                    วันที่-เวลา
                  </div>
                </FormField>
                <FormField
                  width={2}>
                  <div
                    style={{ fontWeight: "bold", margin: "0px 10px" }}>
                    สถานะเอกสาร
                  </div>
                </FormField>
                <FormField
                  width={4}>
                  <div
                    style={{ fontWeight: "bold", margin: "0px 10px" }}>
                    Station
                  </div>
                </FormField>
              </FormGroup>
              <FormGroup
                inline={true}
                style={{ margin: "0px" }}>
                <FormField
                  width={2}>
                  <div
                    style={{ width: "100%", background: "rgb(221, 221, 221)", padding: "3px 10px", marginRight: "10px" }}>
                    {props.selectedReceipt?.code}
                  </div>
                </FormField>
                <FormField
                  width={2}>
                  <div
                    style={{ width: "100%", background: "rgb(221, 221, 221)", padding: "3px 10px", margin: "0px 10px" }}>
                    {props.selectedReceipt?.encounter_no || "-"}
                  </div>
                </FormField>
                <FormField
                  width={3}>
                  <div
                    style={{ width: "100%", background: "rgb(221, 221, 221)", padding: "3px 10px", margin: "0px 10px" }}>
                    {props.selectedReceipt?.division_name}
                  </div>
                </FormField>
                <FormField
                  width={2}>
                  <div
                    style={{ width: "100%", background: "rgb(221, 221, 221)", padding: "3px 10px", margin: "0px 10px" }}>
                    {props.selectedReceipt?.created}
                  </div>
                </FormField>
                <FormField
                  width={2}>
                  <div
                    style={{ width: "100%", background: "rgb(221, 221, 221)", padding: "3px 10px", margin: "0px 10px" }}>
                    {props.selectedReceipt?.status_label}
                  </div>
                </FormField>
                <FormField
                  width={4}>
                  <div
                    style={{ width: "100%", background: "rgb(221, 221, 221)", padding: "3px 10px", margin: "0px 10px" }}>
                    {props.selectedReceipt?.station_name}
                  </div>
                </FormField>
              </FormGroup>
            </Form>
          </div>
          <div
            style={{ margin: "0px 10px 10px", ...(props.selectedReceipt?.type_name !== "FULL_PAYMENT" && { display: "none" })  }}>
            
            <Form>
              <FormGroup
                inline={true}
                style={{ margin: "0px" }}>
                <FormField
                  width={2}>
                  <div
                    style={{ fontWeight: "bold", marginRight: "10px" }}>
                    เลขที่ใบเสร็จ
                  </div>
                </FormField>
                <FormField
                  width={2}>
                  <div
                    style={{ fontWeight: "bold", margin: "0px 10px" }}>
                    สถานะ
                  </div>
                </FormField>
                <FormField
                  width={2}>
                  <div
                    style={{ fontWeight: "bold", margin: "0px 10px" }}>
                    วันที่-เวลา
                  </div>
                </FormField>
                <FormField
                  width={2}>
                  <div
                    style={{ fontWeight: "bold", margin: "0px 10px" }}>
                    Encounter
                  </div>
                </FormField>
                <FormField
                  width={2}>
                  <div
                    style={{ fontWeight: "bold", margin: "0px 10px" }}>
                    รับเข้า
                  </div>
                </FormField>
                <FormField
                  width={2}>
                  <div
                    style={{ fontWeight: "bold", margin: "0px 10px" }}>
                    จำหน่าย
                  </div>
                </FormField>
              </FormGroup>
              <FormGroup
                inline={true}
                style={{ margin: "0px" }}>
                <FormField
                  width={2}>
                  <div
                    style={{ width: "100%", background: "rgb(221, 221, 221)", padding: "3px 10px", marginRight: "10px" }}>
                    {props.selectedReceipt?.code}
                  </div>
                </FormField>
                <FormField
                  width={2}>
                  <div
                    style={{ width: "100%", background: "rgb(221, 221, 221)", padding: "3px 10px", margin: "0px 10px" }}>
                    {props.selectedReceipt?.status_label}
                  </div>
                </FormField>
                <FormField
                  width={2}>
                  <div
                    style={{ width: "100%", background: "rgb(221, 221, 221)", padding: "3px 10px", margin: "0px 10px" }}>
                    {props.selectedReceipt?.created}
                  </div>
                </FormField>
                <FormField
                  width={2}>
                  <div
                    style={{ width: "100%", background: "rgb(221, 221, 221)", padding: "3px 10px", margin: "0px 10px" }}>
                    {props.selectedReceipt?.encounter_no}
                  </div>
                </FormField>
                <FormField
                  width={2}>
                  <div
                    style={{ width: "100%", background: "rgb(221, 221, 221)", padding: "3px 10px", margin: "0px 10px" }}>
                    {props.receiptEncounterData?.checkIn || "-"}
                  </div>
                </FormField>
                <FormField
                  width={2}>
                  <div
                    style={{ width: "100%", background: "rgb(221, 221, 221)", padding: "3px 10px", margin: "0px 10px" }}>
                    {props.receiptEncounterData?.checkOut || "-"}
                  </div>
                </FormField>
              </FormGroup>
            </Form>
          </div>
          <Table
            className="--rt-tbody-overflow-y-hidden"
            data={props.receiptData}
            headers="MODE, MODE NAME, ราคา, ส่งเบิก, absorb, เบิกเอง, เบิกไม่ได้, ส่วนลด, ผู้ป่วยชำระ"
            keys="bill_mode__code,bill_mode__name,price__sum,send_claim__sum,absorb__sum,patient_claim__sum,non_claimable__sum,discount__sum,payable__sum"
            minRows={7}
            showPagination={false}
            style={{height: "300px", margin: "0px 10px",  ...(props.tableModeStyle || {}), ...(props.showBillType === "credit_note" && { display: "none"})}}>
          </Table>
          <Table
            className="--rt-tbody-overflow-y-hidden"
            data={props.receiptData}
            getTrProps={props.byOrderRowProps}
            headers="DIVISION, ORDER NAME, ประเภท, DATE, ราคา, ส่งเบิก, absorb, เบิกเอง, เบิกไม่ได้, ส่วนลด, ผู้ป่วยชำระ, TYPE, STATUS, ENCOUNTER"
            keys="division__name, display_name, encounter__type, created, price__sum, send_claim__sum, absorb__sum, patient_claim__sum, non_claimable__sum, discount__sum, payable__sum , type__name, status__name, encounter__number"
            minRows={7}
            showPagination={ false}
            style={{height: "300px",  margin: "0px 10px",  ...(props.tableOrderStyle || {}), ...(props.showBillType === "credit_note" && { display: "none"})}}>
          </Table>
          <Table
            className="--rt-tbody-overflow-y-hidden"
            data={props.receiptData}
            headers="MODE, CODE, PRODUCT, จำนวน, ราคาต่อหน่วย, ราคา, ส่งเบิก, absorb, เบิกเอง, เบิกไม่ได้, ส่วนลด, ผู้ป่วยชำระ, ส่วนลดสิทธิ, รหัสส่วนลด"
            id="tb-billHistoryDetails"
            keys="mode_code, code, product, quantity, unitprice, price__sum, send_claim__sum, absorb__sum, patient_claim__sum, non_claimable__sum, discount__sum, payable__sum, drg_discount_amount, discount_code"
            minRows={7}
            showPagination={ false}
            style={{height: "300px",  margin: "0px 10px",  ...(props.tableItemStyle || {}), ...(props.showBillType === "credit_note" && { display: "none"})}}>
          </Table>
          <div
            style={{display: "flex", alignItems: "center",padding: "0px 10px", justifyContent: "space-around", paddingTop: "20px", ...(props.showBillType === "credit_note" && { display: "none"})}}>
            
            <div
              style={{display: "flex", justifyContent: "flex-end", width: "160px", margin: "0px 20px"}}>
              ราคารวม
            </div>
            <div
              style={{display: "flex", justifyContent: "flex-end", width: "160px", margin: "0px 20px"}}>
              ส่งเบิก
            </div>
            <div
              style={{display: "flex", justifyContent: "flex-end", width: "160px", margin: "0px 20px"}}>
              absorb
            </div>
            <div
              style={{display: "flex", justifyContent: "flex-end", width: "160px", margin: "0px 20px"}}>
              เบิกเอง
            </div>
            <div
              style={{display: "flex", justifyContent: "flex-end", width: "160px", margin: "0px 20px"}}>
              เบิกไม่ได้
            </div>
            <div
              style={{display: "flex", justifyContent: "flex-end", width: "160px", margin: "0px 20px"}}>
              ส่วนลด
            </div>
            <div
              style={{display: "flex", justifyContent: "flex-end", width: "160px", margin: "0px 20px"}}>
              ผู้ป่วยชำระ
            </div>
            <div
              style={{display: "flex", justifyContent: "flex-end", width: "160px", margin: "0px 20px"}}>
              ผ่อนชำระ
            </div>
          </div>
          <div
            style={{display: "flex", alignItems: "center",padding: "0px 10px", justifyContent: "space-around", ...(props.showBillType === "credit_note" && { display: "none"})}}>
            
            <div
              style={{padding: "8px", background: "rgb(221, 221, 221)", display: "flex", justifyContent: "flex-end", width: "160px", margin: "0px 20px"}}>
              {props.price}
            </div>
            <div
              style={{padding: "8px", background: "rgb(221, 221, 221)", display: "flex", justifyContent: "flex-end", width: "160px", margin: "0px 20px"}}>
              {props.send_claim}
            </div>
            <div
              style={{padding: "8px", background: "rgb(221, 221, 221)", display: "flex", justifyContent: "flex-end", width: "160px", margin: "0px 20px"}}>
              {props.absorb}
            </div>
            <div
              style={{padding: "8px", background: "rgb(221, 221, 221)", display: "flex", justifyContent: "flex-end", width: "160px", margin: "0px 20px"}}>
              {props.patient_claim}
            </div>
            <div
              style={{padding: "8px", background: "rgb(221, 221, 221)", display: "flex", justifyContent: "flex-end", width: "160px", margin: "0px 20px"}}>
              {props.non_claimable}
            </div>
            <div
              style={{padding: "8px", background: "rgb(221, 221, 221)", display: "flex", justifyContent: "flex-end", width: "160px", margin: "0px 20px"}}>
              {props.discount}
            </div>
            <div
              style={{padding: "8px", background: "rgb(221, 221, 221)", display: "flex", justifyContent: "flex-end", width: "160px", margin: "0px 20px"}}>
              {props.payable}
            </div>
            <div
              style={{padding: "8px", background: "rgb(221, 221, 221)", display: "flex", justifyContent: "flex-end", width: "160px", margin: "0px 20px"}}>
              {props.installment_sum}
            </div>
          </div>
          <div
            style={{display: "flex", alignItems: "center",padding: "0px 10px", justifyContent: "space-between", paddingTop: "30px", ...(props.showBillType === "credit_note" && { display: "none"})}}>
            
            <div
              style={{padding: "0px 20px"}}>
              
              <Button
                className={props.orderBy === "Mode" ? "active" : ""}
                color="teal"
                id="btn-billModeHistory"
                name="Mode"
                onClick={props.onChangeOrderBy}
                size="mini"
                style={{margin: "0px 3px"}}>
                BY MODE
              </Button>
              <Button
                className={props.orderBy === "Order" ? "active" : ""}
                color="teal"
                id="btn-billOrderHistory"
                name="Order"
                onClick={props.onChangeOrderBy}
                size="mini"
                style={{margin: "0px 3px"}}>
                BY ORDER
              </Button>
              <Button
                className={props.orderBy === "Item" ? "active" : ""}
                color="teal"
                id="btn-billItemHistory"
                name="Item"
                onClick={props.onChangeOrderBy}
                size="mini"
                style={{margin: "0px 3px"}}>
                BY ITEM
              </Button>
            </div>
            <div
              style={{padding: "0px 20px", display: props.showBillType === "receipt" ? "flex" : "none", alignItems: "center"}}>
              
              <div
                style={{margin: "0px 3px", minWidth: "max-content", ...(props.isCudent && { display: "none" })}}>
                ชื่อแพทย์บนใบเสร็จ
              </div>
              <div
                style={{margin: "0px 3px", ...(props.isCudent && { display: "none" })}}>
                
                <Dropdown
                  disabled={true}
                  onChange={props.onChangeDoctorName}
                  options={props.doctorNameOptions}
                  search={true}
                  selection={true}
                  value={props.doctorName}>
                </Dropdown>
              </div>
              <div
                style={{margin: "0px 3px", minWidth: "max-content"}}>
                ชื่อผู้ป่วยบนใบเสร็จ
              </div>
              <div
                style={{margin: "0px 3px"}}>
                
                <Dropdown
                  onChange={props.onChangePatientName}
                  options={props.patientNameOptions}
                  selection={true}
                  value={props.patientName}>
                </Dropdown>
              </div>
              <div>
                {props.buttonPrintReceipt}
              </div>
              <Button
                color="blue"
                id="btn-billDetailReportHistory"
                onClick={props.printReceipt}
                size="mini"
                style={{margin: "0px 3px"}}>
                พิมพ์ใบแสดงรายละเอียด
              </Button>
              <Button
                color="yellow"
                onClick={props.confirmReceipt}
                size="mini"
                style={{margin: "0px 3px", ...(props.roleDoctor && {display: "none"})}}>
                ยืนยันสิทธิ
              </Button>
              <Button
                color="red"
                id="btn-cancelBillHistory"
                onClick={props.cancelReceipt}
                size="mini"
                style={{margin: "0px 3px", ...(props.roleDoctor && {display: "none"}), ...(props.receiptDataStatus === "CANCELED" && {display: "none"})}}>
                ยกเลิกใบเสร็จ
              </Button>
            </div>
            <div
              style={{padding: "0px 20px", display: props.showBillType === "invoice" ? "flex" : "none", alignItems: "center"}}>
              
              <Button
                color="blue"
                onClick={props.printInvoice}
                size="mini"
                style={{margin: "0px 3px"}}>
                พิมพ์ใบแสดงรายละเอียด
              </Button>
              <Button
                color="green"
                disabled={props.hideBillPayment || false}
                onClick={props.confirmInvoice}
                size="mini"
                style={{margin: "0px 3px", ...(props.hideBillPayment && { display: "none"})}}>
                รับชำระเงิน
              </Button>
              <Button
                color="red"
                onClick={props.cancelInvoice}
                size="mini"
                style={{margin: "0px 3px", ...((props.receiptDataStatus === "CANCELED") && { display: "none"})}}>
                ยกเลิกใบแจ้งหนี้
              </Button>
            </div>
          </div>
          <div
            style={{display: "flex", alignItems: "center",padding: "10px", paddingTop: "20px", ...(props.showBillType !== "credit_note" && { display: "none"})}}>
            
            <div>
              เลขที่ใบสำคัญคืนเงิน
            </div>
            <div
              style={{margin: "0px 8px", padding: "4px 15px", background: "rgb(221, 221, 221)", display: "flex", borderRadius: "5px "}}>
              {props.creditNoteDetail?.code}
            </div>
            <div>
              สถานะ
            </div>
            <div
              style={{margin: "0px 8px", padding: "4px 15px", background: "rgb(221, 221, 221)", display: "flex", borderRadius: "5px "}}>
              {props.creditNoteDetail?.status_label}
            </div>
            <div>
              วันที่-เวลา
            </div>
            <div
              style={{margin: "0px 8px", padding: "4px 15px", background: "rgb(221, 221, 221)", display: "flex", borderRadius: "5px "}}>
              {props.creditNoteDetail?.editedText}
            </div>
          </div>
          <Table
            className="--rt-tbody-overflow-y-hidden"
            data={props.creditNoteData}
            headers="หมวด,รหัส,สินค้า,ใบเสร็จ,จำนวนรวม,ราคาต่อหน่วย,ยอดชำระ,จำนวนคืน,ยอดคืน"
            keys="mode_code,pricing_code,pricing_name,receipts,pricing_quantity,unitprice,payable,quantity,price"
            minRows={7}
            showPagination={ false}
            style={{height: "300px",  margin: "0px 10px",  ...(props.tableOrderStyle || {}), ...(props.showBillType !== "credit_note" && { display: "none"})}}>
          </Table>
          <div
            style={{display: "flex", alignItems: "center",padding: "0px 10px 10px", justifyContent: "space-around", paddingTop: "20px", ...(props.showBillType !== "credit_note" && { display: "none"})}}>
            
            <div>
              ยอดคืนรวม
            </div>
            <div
              style={{padding: "8px", background: "rgb(221, 221, 221)", display: "flex", justifyContent: "flex-end", width: "80%", margin: "0px 20px", borderRadius: "5px"}}>
              {props.price}
            </div>
            <div>
              {props.buttonCancelCreditNote}
            </div>
          </div>
        </div>
        <div
          style={{paddingTop: "20px"}}>
          
          <Table
            className="--rt-tbody-overflow-y-hidden"
            data={props.edcData}
            headers="Referral Code,Approval Code,Perform Date	,Type,Status,Total Price,Actual Price,Actions"
            keys="reference_code_edited,approval_code_edited,effective_date,approval_type,status,total_price,actual_price,actions"
            minRows={7}
            showPagination={ false}
            style={{display: props.showEDC ? "" : "none", height: "300px",  zoom: "100%", margin: "0px 10px"}}
            widths="auto,auto,auto,auto,auto,auto,auto,220">
          </Table>
        </div>
        <div
          style={{width: "100%",paddingBottom: "3rem" }}>
          
        </div>
      </div>
    )
}


export default CardBillingHistoryUX

export const screenPropsDefault = {"debug":true,"showSupplyList":false}

/* Date Time : Wed Oct 23 2024 14:24:36 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ zoom: \"90%\" }"
        }
      },
      "seq": 0
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 2,
      "name": "Table",
      "parent": 63,
      "props": {
        "className": {
          "type": "value",
          "value": "--rt-tbody-overflow-y-hidden"
        },
        "columns": {
          "type": "code",
          "value": ""
        },
        "data": {
          "type": "code",
          "value": "props.receiptData"
        },
        "getTdProps": {
          "type": "code",
          "value": ""
        },
        "headers": {
          "type": "value",
          "value": "MODE, MODE NAME, ราคา, ส่งเบิก, absorb, เบิกเอง, เบิกไม่ได้, ส่วนลด, ผู้ป่วยชำระ"
        },
        "keys": {
          "type": "value",
          "value": "bill_mode__code,bill_mode__name,price__sum,send_claim__sum,absorb__sum,patient_claim__sum,non_claimable__sum,discount__sum,payable__sum"
        },
        "minRows": {
          "type": "code",
          "value": "7"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"300px\", margin: \"0px 10px\",  ...(props.tableModeStyle || {}), ...(props.showBillType === \"credit_note\" && { display: \"none\"})}"
        },
        "widths": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "BILLING - HISTORY"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.2rem\",padding: \"10px 10px 0px 10px\", display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 26,
      "name": "Table",
      "parent": 0,
      "props": {
        "className": {
          "type": "value",
          "value": "--rt-tbody-overflow-y-hidden"
        },
        "data": {
          "type": "code",
          "value": "props.billingData"
        },
        "getTdProps": {
          "type": "code",
          "value": ""
        },
        "getTrProps": {
          "type": "code",
          "value": "props.onSelectedItems"
        },
        "headers": {
          "type": "value",
          "value": "ลำดับ, รหัสเอกสาร, ประเภทเอกสารค่าใช้จ่าย, ผู้ป่วย, วันที่-เวลา ออกเอกสาร, มูลค่า, สถานะ, หน่วยงาน, กะ"
        },
        "id": {
          "type": "value",
          "value": "tb-billHistory"
        },
        "keys": {
          "type": "value",
          "value": "no,code,type_name,patient_name,created,price,status_label,division_name,station_name"
        },
        "minRows": {
          "type": "code",
          "value": "7"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"300px\",  zoom: \"87%\", margin: \"0px 10px\", ...(props.tableStyle || {})}"
        },
        "widths": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", justifyContent:\"flex-end\",alignItems:\"center\", padding: \"10px 0px 20px 0px\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "Checkbox",
      "parent": 28,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.divisionCheck"
        },
        "label": {
          "type": "value",
          "value": "หน่วยงาน"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeDivisionCheck"
        },
        "onClick": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding:\"0px 10px\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 63,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props?.searchHistorySelected  ? \"flex\" :\"none\" ,alignItems:\"center\", margin: \"10px 10px\", fontWeight: \"normal\", fontSize: \"1.2rem\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขที่เอกสาร"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 10px 0px 0px\"}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": "encounter"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 10px 0px 30px\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "div",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": "แพทย์"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 10px 0px 30px\"}"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "div",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": "หน่วยงานสั่ง"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 10px 0px 30px\"}"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "div",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": "หน่วยงานจ่าย"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 10px 0px 30px\"}"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "Label",
      "parent": 40,
      "props": {
        "children": {
          "type": "code",
          "value": "props.editCode"
        },
        "name": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "Label",
      "parent": 40,
      "props": {
        "children": {
          "type": "code",
          "value": "props.editEncounter"
        },
        "name": {
          "type": "code",
          "value": "{margin: \"0px 20px 0px 0px\"}"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "Label",
      "parent": 40,
      "props": {
        "children": {
          "type": "code",
          "value": "props.editDoctor"
        },
        "name": {
          "type": "code",
          "value": "{margin: \"0px 20px 0px 0px\"}"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "Label",
      "parent": 40,
      "props": {
        "children": {
          "type": "code",
          "value": "props.editOrderDiv"
        },
        "name": {
          "type": "code",
          "value": "{margin: \"0px 20px 0px 0px\"}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "Label",
      "parent": 40,
      "props": {
        "children": {
          "type": "code",
          "value": "props.editOrderPerformDiv"
        },
        "name": {
          "type": "code",
          "value": "{margin: \"0px 20px 0px 0px\"}"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "div",
      "parent": 63,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showBillType === \"credit_note\" ? \"CREDIT NOTE\" :  props.showBillType === \"invoice\" ? \"INVOICE\" : props.showBillType === \"receipt\" ? \"RECEIPT\" : \"\""
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.2rem\",padding: \"10px\", display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "div",
      "parent": 59,
      "props": {
        "children": {
          "type": "code",
          "value": "props.showBillType === \"credit_note\" ? \"ข้อมูลรายการใบสำคัญคืนเงิน\" :  \"ข้อมูลใบเสร็จค่ารักษาพยาบาล\""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{paddingTop: \"20px\", display: props.receiptId === \"\" ?  \"none\" : \"block\", ...(props.selectedReceipt?.type_name === \"DEPOSIT\" && { display: \"none\" }) }"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\",paddingBottom: \"3rem\" }"
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "div",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติการชำระเงินค่ารักษาพยาบาล"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "Checkbox",
      "parent": 28,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.statusCheck"
        },
        "label": {
          "type": "value",
          "value": "สถานะ"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeStatusCheck"
        },
        "style": {
          "type": "code",
          "value": "{padding:\"0px 5px\"}"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "Checkbox",
      "parent": 28,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.dateCheck"
        },
        "label": {
          "type": "value",
          "value": "วันที่ออกเอกสาร"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeDateCheck"
        },
        "style": {
          "type": "code",
          "value": "{padding:\"0px 5px\"}"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 79,
      "name": "DateTextBox",
      "parent": 28,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeStartDate"
        },
        "style": {
          "type": "code",
          "value": "{padding:\"0px 5px\",}"
        },
        "value": {
          "type": "code",
          "value": "props.startDate"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 80,
      "name": "DateTextBox",
      "parent": 28,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeEndDate"
        },
        "placeholder": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding:\"0px 5px\",}"
        },
        "value": {
          "type": "code",
          "value": "props.endDate"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "div",
      "parent": 28,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"170px\", margin: \"0px 5px\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "Dropdown",
      "parent": 81,
      "props": {
        "fluid": {
          "type": "value",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeDivision"
        },
        "options": {
          "type": "code",
          "value": "props.division"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.divisionSelect"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "div",
      "parent": 28,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"170px\", margin: \"0px 5px\"}"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 84,
      "name": "div",
      "parent": 28,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"170px\", margin: \"0px 5px\"}"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 85,
      "name": "Dropdown",
      "parent": 83,
      "props": {
        "fluid": {
          "type": "value",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeStatus"
        },
        "options": {
          "type": "code",
          "value": "props.statusOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.status"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 86,
      "name": "Dropdown",
      "parent": 84,
      "props": {
        "fluid": {
          "type": "value",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "cb-billingType"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeType"
        },
        "options": {
          "type": "code",
          "value": "props.typeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.type"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 63,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\",padding: \"0px 10px\", justifyContent: \"space-around\", paddingTop: \"20px\", ...(props.showBillType === \"credit_note\" && { display: \"none\"})}"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 63,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\",padding: \"0px 10px\", justifyContent: \"space-around\", ...(props.showBillType === \"credit_note\" && { display: \"none\"})}"
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "div",
      "parent": 63,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\",padding: \"0px 10px\", justifyContent: \"space-between\", paddingTop: \"30px\", ...(props.showBillType === \"credit_note\" && { display: \"none\"})}"
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": null,
      "id": 90,
      "name": "div",
      "parent": 87,
      "props": {
        "children": {
          "type": "value",
          "value": "ราคารวม"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", width: \"160px\", margin: \"0px 20px\"}"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": null,
      "id": 91,
      "name": "div",
      "parent": 87,
      "props": {
        "children": {
          "type": "value",
          "value": "ส่งเบิก"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", width: \"160px\", margin: \"0px 20px\"}"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "div",
      "parent": 87,
      "props": {
        "children": {
          "type": "value",
          "value": "absorb"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", width: \"160px\", margin: \"0px 20px\"}"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 93,
      "name": "div",
      "parent": 87,
      "props": {
        "children": {
          "type": "value",
          "value": "เบิกเอง"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", width: \"160px\", margin: \"0px 20px\"}"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "div",
      "parent": 87,
      "props": {
        "children": {
          "type": "value",
          "value": "เบิกไม่ได้"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", width: \"160px\", margin: \"0px 20px\"}"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "div",
      "parent": 87,
      "props": {
        "children": {
          "type": "value",
          "value": "ส่วนลด"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", width: \"160px\", margin: \"0px 20px\"}"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": null,
      "id": 96,
      "name": "div",
      "parent": 87,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ป่วยชำระ"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", width: \"160px\", margin: \"0px 20px\"}"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "div",
      "parent": 88,
      "props": {
        "children": {
          "type": "code",
          "value": "props.price"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"8px\", background: \"rgb(221, 221, 221)\", display: \"flex\", justifyContent: \"flex-end\", width: \"160px\", margin: \"0px 20px\"}"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": null,
      "id": 98,
      "name": "div",
      "parent": 88,
      "props": {
        "children": {
          "type": "code",
          "value": "props.send_claim"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"8px\", background: \"rgb(221, 221, 221)\", display: \"flex\", justifyContent: \"flex-end\", width: \"160px\", margin: \"0px 20px\"}"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 99,
      "name": "div",
      "parent": 88,
      "props": {
        "children": {
          "type": "code",
          "value": "props.absorb"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"8px\", background: \"rgb(221, 221, 221)\", display: \"flex\", justifyContent: \"flex-end\", width: \"160px\", margin: \"0px 20px\"}"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": null,
      "id": 100,
      "name": "div",
      "parent": 88,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patient_claim"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"8px\", background: \"rgb(221, 221, 221)\", display: \"flex\", justifyContent: \"flex-end\", width: \"160px\", margin: \"0px 20px\"}"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": null,
      "id": 101,
      "name": "div",
      "parent": 88,
      "props": {
        "children": {
          "type": "code",
          "value": "props.non_claimable"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"8px\", background: \"rgb(221, 221, 221)\", display: \"flex\", justifyContent: \"flex-end\", width: \"160px\", margin: \"0px 20px\"}"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": null,
      "id": 102,
      "name": "div",
      "parent": 88,
      "props": {
        "children": {
          "type": "code",
          "value": "props.discount"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"8px\", background: \"rgb(221, 221, 221)\", display: \"flex\", justifyContent: \"flex-end\", width: \"160px\", margin: \"0px 20px\"}"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": null,
      "id": 103,
      "name": "div",
      "parent": 88,
      "props": {
        "children": {
          "type": "code",
          "value": "props.payable"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"8px\", background: \"rgb(221, 221, 221)\", display: \"flex\", justifyContent: \"flex-end\", width: \"160px\", margin: \"0px 20px\"}"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": null,
      "id": 112,
      "name": "div",
      "parent": 89,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0px 20px\"}"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": null,
      "id": 113,
      "name": "div",
      "parent": 89,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0px 20px\", display: props.showBillType === \"receipt\" ? \"flex\" : \"none\", alignItems: \"center\"}"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 114,
      "name": "Button",
      "parent": 112,
      "props": {
        "children": {
          "type": "value",
          "value": "BY MODE"
        },
        "className": {
          "type": "code",
          "value": "props.orderBy === \"Mode\" ? \"active\" : \"\""
        },
        "color": {
          "type": "value",
          "value": "teal"
        },
        "id": {
          "type": "value",
          "value": "btn-billModeHistory"
        },
        "name": {
          "type": "value",
          "value": "Mode"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeOrderBy"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 3px\"}"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 115,
      "name": "Button",
      "parent": 112,
      "props": {
        "children": {
          "type": "value",
          "value": "BY ORDER"
        },
        "className": {
          "type": "code",
          "value": "props.orderBy === \"Order\" ? \"active\" : \"\""
        },
        "color": {
          "type": "value",
          "value": "teal"
        },
        "id": {
          "type": "value",
          "value": "btn-billOrderHistory"
        },
        "name": {
          "type": "value",
          "value": "Order"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeOrderBy"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 3px\"}"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 116,
      "name": "Button",
      "parent": 112,
      "props": {
        "children": {
          "type": "value",
          "value": "BY ITEM"
        },
        "className": {
          "type": "code",
          "value": "props.orderBy === \"Item\" ? \"active\" : \"\""
        },
        "color": {
          "type": "value",
          "value": "teal"
        },
        "id": {
          "type": "value",
          "value": "btn-billItemHistory"
        },
        "name": {
          "type": "value",
          "value": "Item"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeOrderBy"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 3px\"}"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": null,
      "id": 117,
      "name": "div",
      "parent": 113,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อผู้ป่วยบนใบเสร็จ"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 3px\", minWidth: \"max-content\"}"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 119,
      "name": "Button",
      "parent": 113,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์ใบแสดงรายละเอียด"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "id": {
          "type": "value",
          "value": "btn-billDetailReportHistory"
        },
        "onClick": {
          "type": "code",
          "value": "props.printReceipt"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 3px\"}"
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 120,
      "name": "Button",
      "parent": 113,
      "props": {
        "children": {
          "type": "value",
          "value": "ยืนยันสิทธิ"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.confirmReceipt"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 3px\", ...(props.roleDoctor && {display: \"none\"})}"
        }
      },
      "seq": 226,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 121,
      "name": "Button",
      "parent": 113,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิกใบเสร็จ"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "id": {
          "type": "value",
          "value": "btn-cancelBillHistory"
        },
        "onClick": {
          "type": "code",
          "value": "props.cancelReceipt"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 3px\", ...(props.roleDoctor && {display: \"none\"}), ...(props.receiptDataStatus === \"CANCELED\" && {display: \"none\"})}"
        }
      },
      "seq": 227,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 122,
      "name": "Table",
      "parent": 63,
      "props": {
        "className": {
          "type": "value",
          "value": "--rt-tbody-overflow-y-hidden"
        },
        "data": {
          "type": "code",
          "value": "props.receiptData"
        },
        "getTheadThProps": {
          "type": "code",
          "value": ""
        },
        "getTrProps": {
          "type": "code",
          "value": "props.byOrderRowProps"
        },
        "headers": {
          "type": "value",
          "value": "DIVISION, ORDER NAME, ประเภท, DATE, ราคา, ส่งเบิก, absorb, เบิกเอง, เบิกไม่ได้, ส่วนลด, ผู้ป่วยชำระ, TYPE, STATUS, ENCOUNTER"
        },
        "keys": {
          "type": "value",
          "value": "division__name, display_name, encounter__type, created, price__sum, send_claim__sum, absorb__sum, patient_claim__sum, non_claimable__sum, discount__sum, payable__sum , type__name, status__name, encounter__number"
        },
        "minRows": {
          "type": "code",
          "value": "7"
        },
        "showPagination": {
          "type": "code",
          "value": " false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"300px\",  margin: \"0px 10px\",  ...(props.tableOrderStyle || {}), ...(props.showBillType === \"credit_note\" && { display: \"none\"})}"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 123,
      "name": "Table",
      "parent": 63,
      "props": {
        "className": {
          "type": "value",
          "value": "--rt-tbody-overflow-y-hidden"
        },
        "data": {
          "type": "code",
          "value": "props.receiptData"
        },
        "headers": {
          "type": "value",
          "value": "MODE, CODE, PRODUCT, จำนวน, ราคาต่อหน่วย, ราคา, ส่งเบิก, absorb, เบิกเอง, เบิกไม่ได้, ส่วนลด, ผู้ป่วยชำระ, ส่วนลดสิทธิ, รหัสส่วนลด"
        },
        "id": {
          "type": "value",
          "value": "tb-billHistoryDetails"
        },
        "keys": {
          "type": "value",
          "value": "mode_code, code, product, quantity, unitprice, price__sum, send_claim__sum, absorb__sum, patient_claim__sum, non_claimable__sum, discount__sum, payable__sum, drg_discount_amount, discount_code"
        },
        "minRows": {
          "type": "code",
          "value": "7"
        },
        "showPagination": {
          "type": "code",
          "value": " false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"300px\",  margin: \"0px 10px\",  ...(props.tableItemStyle || {}), ...(props.showBillType === \"credit_note\" && { display: \"none\"})}"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": null,
      "id": 124,
      "name": "div",
      "parent": 113,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 3px\"}"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 125,
      "name": "Dropdown",
      "parent": 124,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangePatientName"
        },
        "options": {
          "type": "code",
          "value": "props.patientNameOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.patientName"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": null,
      "id": 126,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{paddingTop: \"20px\"}"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 127,
      "name": "Table",
      "parent": 126,
      "props": {
        "className": {
          "type": "value",
          "value": "--rt-tbody-overflow-y-hidden"
        },
        "data": {
          "type": "code",
          "value": "props.edcData"
        },
        "headers": {
          "type": "value",
          "value": "Referral Code,Approval Code,Perform Date\t,Type,Status,Total Price,Actual Price,Actions"
        },
        "keys": {
          "type": "value",
          "value": "reference_code_edited,approval_code_edited,effective_date,approval_type,status,total_price,actual_price,actions"
        },
        "minRows": {
          "type": "code",
          "value": "7"
        },
        "showPagination": {
          "type": "code",
          "value": " false"
        },
        "style": {
          "type": "code",
          "value": "{display: props.showEDC ? \"\" : \"none\", height: \"300px\",  zoom: \"100%\", margin: \"0px 10px\"}"
        },
        "widths": {
          "type": "value",
          "value": "auto,auto,auto,auto,auto,auto,auto,220"
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": null,
      "id": 128,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0px 10px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 129,
      "name": "hr",
      "parent": 128,
      "props": {
      },
      "seq": 129,
      "void": true
    },
    {
      "from": null,
      "id": 130,
      "name": "div",
      "parent": 89,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0px 20px\", display: props.showBillType === \"invoice\" ? \"flex\" : \"none\", alignItems: \"center\"}"
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 131,
      "name": "Button",
      "parent": 130,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์ใบแสดงรายละเอียด"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.printInvoice"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 3px\"}"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 132,
      "name": "Button",
      "parent": 130,
      "props": {
        "children": {
          "type": "value",
          "value": "รับชำระเงิน"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.hideBillPayment || false"
        },
        "onClick": {
          "type": "code",
          "value": "props.confirmInvoice"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 3px\", ...(props.hideBillPayment && { display: \"none\"})}"
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 133,
      "name": "Button",
      "parent": 130,
      "props": {
        "basic": {
          "type": "code",
          "value": ""
        },
        "children": {
          "type": "value",
          "value": "ยกเลิกใบแจ้งหนี้"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.cancelInvoice"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 3px\", ...((props.receiptDataStatus === \"CANCELED\") && { display: \"none\"})}"
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": null,
      "id": 134,
      "name": "div",
      "parent": 113,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonPrintReceipt"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 135,
      "name": "Table",
      "parent": 63,
      "props": {
        "className": {
          "type": "value",
          "value": "--rt-tbody-overflow-y-hidden"
        },
        "data": {
          "type": "code",
          "value": "props.creditNoteData"
        },
        "headers": {
          "type": "value",
          "value": "หมวด,รหัส,สินค้า,ใบเสร็จ,จำนวนรวม,ราคาต่อหน่วย,ยอดชำระ,จำนวนคืน,ยอดคืน"
        },
        "keys": {
          "type": "value",
          "value": "mode_code,pricing_code,pricing_name,receipts,pricing_quantity,unitprice,payable,quantity,price"
        },
        "minRows": {
          "type": "code",
          "value": "7"
        },
        "showPagination": {
          "type": "code",
          "value": " false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"300px\",  margin: \"0px 10px\",  ...(props.tableOrderStyle || {}), ...(props.showBillType !== \"credit_note\" && { display: \"none\"})}"
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": null,
      "id": 136,
      "name": "div",
      "parent": 63,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\",padding: \"0px 10px 10px\", justifyContent: \"space-around\", paddingTop: \"20px\", ...(props.showBillType !== \"credit_note\" && { display: \"none\"})}"
        }
      },
      "seq": 198,
      "void": false
    },
    {
      "from": null,
      "id": 137,
      "name": "div",
      "parent": 136,
      "props": {
        "children": {
          "type": "value",
          "value": "ยอดคืนรวม"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": null,
      "id": 138,
      "name": "div",
      "parent": 136,
      "props": {
        "children": {
          "type": "code",
          "value": "props.price"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"8px\", background: \"rgb(221, 221, 221)\", display: \"flex\", justifyContent: \"flex-end\", width: \"80%\", margin: \"0px 20px\", borderRadius: \"5px\"}"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": null,
      "id": 139,
      "name": "div",
      "parent": 136,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonCancelCreditNote"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": null,
      "id": 142,
      "name": "div",
      "parent": 63,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\",padding: \"10px\", paddingTop: \"20px\", ...(props.showBillType !== \"credit_note\" && { display: \"none\"})}"
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": null,
      "id": 143,
      "name": "div",
      "parent": 142,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขที่ใบสำคัญคืนเงิน"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": null,
      "id": 144,
      "name": "div",
      "parent": 142,
      "props": {
        "children": {
          "type": "code",
          "value": "props.creditNoteDetail?.code"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 8px\", padding: \"4px 15px\", background: \"rgb(221, 221, 221)\", display: \"flex\", borderRadius: \"5px \"}"
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": null,
      "id": 145,
      "name": "div",
      "parent": 142,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะ"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": null,
      "id": 146,
      "name": "div",
      "parent": 142,
      "props": {
        "children": {
          "type": "code",
          "value": "props.creditNoteDetail?.status_label"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 8px\", padding: \"4px 15px\", background: \"rgb(221, 221, 221)\", display: \"flex\", borderRadius: \"5px \"}"
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": null,
      "id": 147,
      "name": "div",
      "parent": 142,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่-เวลา"
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": null,
      "id": 148,
      "name": "div",
      "parent": 142,
      "props": {
        "children": {
          "type": "code",
          "value": "props.creditNoteDetail?.editedText"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 8px\", padding: \"4px 15px\", background: \"rgb(221, 221, 221)\", display: \"flex\", borderRadius: \"5px \"}"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": null,
      "id": 149,
      "name": "div",
      "parent": 87,
      "props": {
        "children": {
          "type": "value",
          "value": "ผ่อนชำระ"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", width: \"160px\", margin: \"0px 20px\"}"
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": null,
      "id": 150,
      "name": "div",
      "parent": 88,
      "props": {
        "children": {
          "type": "code",
          "value": "props.installment_sum"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"8px\", background: \"rgb(221, 221, 221)\", display: \"flex\", justifyContent: \"flex-end\", width: \"160px\", margin: \"0px 20px\"}"
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": null,
      "id": 151,
      "name": "div",
      "parent": 63,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 10px 10px\", ...(props.selectedReceipt?.type_name !== \"PAYMENT\" && { display: \"none\" })  }"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 152,
      "name": "Form",
      "parent": 151,
      "props": {
      },
      "seq": 152,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 153,
      "name": "FormGroup",
      "parent": 152,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px\" }"
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 155,
      "name": "FormField",
      "parent": 153,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 156,
      "name": "FormField",
      "parent": 153,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 157,
      "name": "FormField",
      "parent": 153,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 158,
      "name": "FormField",
      "parent": 153,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 159,
      "name": "FormField",
      "parent": 153,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 160,
      "name": "FormField",
      "parent": 153,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": null,
      "id": 167,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขที่ใบเสร็จ"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", marginRight: \"10px\" }"
        }
      },
      "seq": 167,
      "void": false
    },
    {
      "from": null,
      "id": 168,
      "name": "div",
      "parent": 156,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะเอกสาร"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"0px 10px\" }"
        }
      },
      "seq": 168,
      "void": false
    },
    {
      "from": null,
      "id": 169,
      "name": "div",
      "parent": 157,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่-เวลา"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"0px 10px\" }"
        }
      },
      "seq": 169,
      "void": false
    },
    {
      "from": null,
      "id": 170,
      "name": "div",
      "parent": 158,
      "props": {
        "children": {
          "type": "value",
          "value": "Encounter"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"0px 10px\" }"
        }
      },
      "seq": 170,
      "void": false
    },
    {
      "from": null,
      "id": 171,
      "name": "div",
      "parent": 159,
      "props": {
        "children": {
          "type": "value",
          "value": "Station"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"0px 10px\" }"
        }
      },
      "seq": 171,
      "void": false
    },
    {
      "from": null,
      "id": 172,
      "name": "div",
      "parent": 160,
      "props": {
        "children": {
          "type": "value",
          "value": "หน่วยงาน"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"0px 10px\" }"
        }
      },
      "seq": 172,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 185,
      "name": "FormGroup",
      "parent": 152,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px\" }"
        }
      },
      "seq": 185,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 186,
      "name": "FormField",
      "parent": 185,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 186,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 187,
      "name": "FormField",
      "parent": 185,
      "props": {
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 187,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 188,
      "name": "FormField",
      "parent": 185,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 188,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 189,
      "name": "FormField",
      "parent": 185,
      "props": {
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 189,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 190,
      "name": "FormField",
      "parent": 185,
      "props": {
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 190,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 191,
      "name": "FormField",
      "parent": 185,
      "props": {
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 191,
      "void": false
    },
    {
      "from": null,
      "id": 192,
      "name": "div",
      "parent": 191,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectedReceipt?.station_name"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", background: \"rgb(221, 221, 221)\", padding: \"3px 10px\", margin: \"0px 10px\" }"
        }
      },
      "seq": 192,
      "void": false
    },
    {
      "from": null,
      "id": 193,
      "name": "div",
      "parent": 190,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectedReceipt?.status_label"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", background: \"rgb(221, 221, 221)\", padding: \"3px 10px\", margin: \"0px 10px\" }"
        }
      },
      "seq": 193,
      "void": false
    },
    {
      "from": null,
      "id": 194,
      "name": "div",
      "parent": 189,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectedReceipt?.created"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", background: \"rgb(221, 221, 221)\", padding: \"3px 10px\", margin: \"0px 10px\" }"
        }
      },
      "seq": 194,
      "void": false
    },
    {
      "from": null,
      "id": 195,
      "name": "div",
      "parent": 188,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectedReceipt?.division_name"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", background: \"rgb(221, 221, 221)\", padding: \"3px 10px\", margin: \"0px 10px\" }"
        }
      },
      "seq": 195,
      "void": false
    },
    {
      "from": null,
      "id": 196,
      "name": "div",
      "parent": 187,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectedReceipt?.encounter_no || \"-\""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", background: \"rgb(221, 221, 221)\", padding: \"3px 10px\", margin: \"0px 10px\" }"
        }
      },
      "seq": 196,
      "void": false
    },
    {
      "from": null,
      "id": 197,
      "name": "div",
      "parent": 186,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectedReceipt?.code"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", background: \"rgb(221, 221, 221)\", padding: \"3px 10px\", marginRight: \"10px\" }"
        }
      },
      "seq": 197,
      "void": false
    },
    {
      "from": null,
      "id": 198,
      "name": "div",
      "parent": 63,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 10px 10px\", ...(props.selectedReceipt?.type_name !== \"FULL_PAYMENT\" && { display: \"none\" })  }"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 199,
      "name": "Form",
      "parent": 198,
      "props": {
      },
      "seq": 199,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 200,
      "name": "FormGroup",
      "parent": 199,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px\" }"
        }
      },
      "seq": 200,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 201,
      "name": "FormGroup",
      "parent": 199,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px\" }"
        }
      },
      "seq": 201,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 202,
      "name": "FormField",
      "parent": 200,
      "props": {
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 202,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 203,
      "name": "FormField",
      "parent": 200,
      "props": {
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 203,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 204,
      "name": "FormField",
      "parent": 200,
      "props": {
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 204,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 205,
      "name": "FormField",
      "parent": 200,
      "props": {
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 205,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 206,
      "name": "FormField",
      "parent": 200,
      "props": {
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 206,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 207,
      "name": "FormField",
      "parent": 200,
      "props": {
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 207,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 208,
      "name": "FormField",
      "parent": 201,
      "props": {
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 208,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 209,
      "name": "FormField",
      "parent": 201,
      "props": {
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 209,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 210,
      "name": "FormField",
      "parent": 201,
      "props": {
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 210,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 211,
      "name": "FormField",
      "parent": 201,
      "props": {
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 211,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 212,
      "name": "FormField",
      "parent": 201,
      "props": {
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 212,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 213,
      "name": "FormField",
      "parent": 201,
      "props": {
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 213,
      "void": false
    },
    {
      "from": null,
      "id": 214,
      "name": "div",
      "parent": 213,
      "props": {
        "children": {
          "type": "code",
          "value": "props.receiptEncounterData?.checkOut || \"-\""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", background: \"rgb(221, 221, 221)\", padding: \"3px 10px\", margin: \"0px 10px\" }"
        }
      },
      "seq": 214,
      "void": false
    },
    {
      "from": null,
      "id": 215,
      "name": "div",
      "parent": 212,
      "props": {
        "children": {
          "type": "code",
          "value": "props.receiptEncounterData?.checkIn || \"-\""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", background: \"rgb(221, 221, 221)\", padding: \"3px 10px\", margin: \"0px 10px\" }"
        }
      },
      "seq": 215,
      "void": false
    },
    {
      "from": null,
      "id": 216,
      "name": "div",
      "parent": 211,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectedReceipt?.encounter_no"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", background: \"rgb(221, 221, 221)\", padding: \"3px 10px\", margin: \"0px 10px\" }"
        }
      },
      "seq": 216,
      "void": false
    },
    {
      "from": null,
      "id": 217,
      "name": "div",
      "parent": 210,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectedReceipt?.created"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", background: \"rgb(221, 221, 221)\", padding: \"3px 10px\", margin: \"0px 10px\" }"
        }
      },
      "seq": 217,
      "void": false
    },
    {
      "from": null,
      "id": 218,
      "name": "div",
      "parent": 209,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectedReceipt?.status_label"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", background: \"rgb(221, 221, 221)\", padding: \"3px 10px\", margin: \"0px 10px\" }"
        }
      },
      "seq": 218,
      "void": false
    },
    {
      "from": null,
      "id": 219,
      "name": "div",
      "parent": 208,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectedReceipt?.code"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", background: \"rgb(221, 221, 221)\", padding: \"3px 10px\", marginRight: \"10px\" }"
        }
      },
      "seq": 219,
      "void": false
    },
    {
      "from": null,
      "id": 220,
      "name": "div",
      "parent": 207,
      "props": {
        "children": {
          "type": "value",
          "value": "จำหน่าย"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"0px 10px\" }"
        }
      },
      "seq": 220,
      "void": false
    },
    {
      "from": null,
      "id": 221,
      "name": "div",
      "parent": 206,
      "props": {
        "children": {
          "type": "value",
          "value": "รับเข้า"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"0px 10px\" }"
        }
      },
      "seq": 221,
      "void": false
    },
    {
      "from": null,
      "id": 222,
      "name": "div",
      "parent": 205,
      "props": {
        "children": {
          "type": "value",
          "value": "Encounter"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"0px 10px\" }"
        }
      },
      "seq": 222,
      "void": false
    },
    {
      "from": null,
      "id": 223,
      "name": "div",
      "parent": 204,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่-เวลา"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"0px 10px\" }"
        }
      },
      "seq": 223,
      "void": false
    },
    {
      "from": null,
      "id": 224,
      "name": "div",
      "parent": 203,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะ"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", margin: \"0px 10px\" }"
        }
      },
      "seq": 224,
      "void": false
    },
    {
      "from": null,
      "id": 225,
      "name": "div",
      "parent": 202,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขที่ใบเสร็จ"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", marginRight: \"10px\" }"
        }
      },
      "seq": 225,
      "void": false
    },
    {
      "from": null,
      "id": 226,
      "name": "div",
      "parent": 113,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อแพทย์บนใบเสร็จ"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 3px\", minWidth: \"max-content\", ...(props.isCudent && { display: \"none\" })}"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": null,
      "id": 227,
      "name": "div",
      "parent": 113,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 3px\", ...(props.isCudent && { display: \"none\" })}"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 228,
      "name": "Dropdown",
      "parent": 227,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeDoctorName"
        },
        "options": {
          "type": "code",
          "value": "props.doctorNameOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.doctorName"
        }
      },
      "seq": 228,
      "void": false
    },
    {
      "from": null,
      "id": 229,
      "name": "div",
      "parent": 28,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearch"
        }
      },
      "seq": 229,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardBillingHistoryUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "debug": true,
    "showSupplyList": false
  },
  "width": 80
}

*********************************************************************************** */
