export default class CardPatientPanelController {
  constructor(props) {
    this.regManager = props.regManager;
    this.coreManager = props.coreManager;
    this.prxManager = props.prxManager;
  }

  getPatientInfo = async ({ patientId, apiToken } = {}) => {
    const [response, error, network] = await this.regManager.getPatientInfo(
      patientId,
      apiToken
    );
    return [response, error, network];
  };

  getProxyPatient = async ({ patientId, apiToken } = {}) => {
    const [response, error, network] = await this.prxManager.getProxyPatient(
      patientId,
      apiToken
    );
    return [response, error, network];
  };

}
