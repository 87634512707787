import React, { FormEvent } from "react";
import {
  Button,
  Icon,
  Form,
  CheckboxProps,
  InputProps,
  Divider
} from "semantic-ui-react";
import {
  beDateToSerial,
  serial_to_datestr,
  DivisionServiceBlock,
  get_startofday_serial,
  QueueController,
  TIME_CONST
} from "./Time";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
// import { convertToThaiDate } from "../../utils/tsUtils";
import moment from "moment";
import { useIntl } from "react-intl";
interface TimeNurseProps {
  division: number;
  division_service_blocks: DivisionServiceBlock[];
  date: string;
  onIncreaseDayOffset: () => void;
  onDecreaseDayOffset: () => void;
  queueController: QueueController;
  apiToken: string;
  filterProvider: boolean;
  userProviderId: string | number;
  hideScheduleButton: boolean;
}

const TimeNurse = (props: TimeNurseProps) => {
  const intl = useIntl();
  const history = useHistory();
  const [dayStartSerial, setDayStartSerial] = React.useState(
    get_startofday_serial()
  );
  const [nurseDivServiceBlocks, setNurseDivServiceBlocks] = React.useState<
    DivisionServiceBlock[]
  >([]);
  const [chkDoctor, setChkDoctor] = React.useState<boolean>(true)
  const [chkNurse, setChkNurse] = React.useState<boolean>(true)
  const [searchText, setSearchText] = React.useState<string>("")
  const [isLoading, setIsLoading] = React.useState(false);
  const isMounted = React.useRef(true);

  React.useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleLoadNurseDivisionServiceBlock = async () => {
    setIsLoading(true);
    let employeeType = null
    if(chkDoctor){
      employeeType = "DOCTOR"
    } else if (chkNurse){
      employeeType = "NURSE"
    }
    if(chkDoctor && chkNurse){
      employeeType = null
    } 
    if(!chkDoctor && !chkNurse){
      setIsLoading(false);
      return setNurseDivServiceBlocks([])
    }
    const [
      response,
      error,
      network
    ] = await props.queueController.loadDivisionServiceBlock({
      apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
      from_serial: dayStartSerial,
      to_serial: dayStartSerial + 96,
      divisions: props.division ? [props.division] : [Cookies.get("division_id")],
      employeeType
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (response) {
        setNurseDivServiceBlocks(response.items);
      } else {
        setNurseDivServiceBlocks([]);
      }
    }
  };

  const setDaySerial = async ({ type }: { type: string }) => {
    let unit = TIME_CONST.UNIT_PER_DAY;
    if (type === "back") {
      unit = -unit;
    }
    setDayStartSerial(dayStartSerial + unit);
  };

  React.useEffect(() => {
    handleLoadNurseDivisionServiceBlock();
  }, [dayStartSerial, chkNurse, chkDoctor]);

  const handleDocScheduleClick = ({
    providerId
  }: { providerId?: number | null } = {}) => {
    if (providerId) {
      let date = serial_to_datestr(dayStartSerial, "localeDate")
        .split("/")
        .join("-");
      history.push(`/DoctorSchedule/${providerId}/${date}/`);
    }
  };

  const handleCheckDoctor = (e: FormEvent<HTMLInputElement>, { checked }: CheckboxProps) => {
    setChkDoctor(!chkDoctor)
  }

  const handleCheckNurse = (e: FormEvent<HTMLInputElement>, { checked }: CheckboxProps) => {
    setChkNurse(!chkNurse)
  }

  const handleSearchChange = (e: InputProps) => {
    const { value } = e.target;
    setSearchText(value.toLowerCase())
  }

  return (
    <div className="TimeNurse">
      <div className="title">
        <Button
          compact
          disabled={isLoading}
          content="<"
          onClick={(e: any) => {
            setDaySerial({ type: "back" });
          }}
        />
        <span className="currentDate">
          {serial_to_datestr(dayStartSerial, "localeDate", {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric"
          })}
        </span>
        <Button
          compact
          content=">"
          disabled={isLoading}
          onClick={(e: any) => {
            setDaySerial({ type: "next" });
          }}
        />
      </div>
      <div className="sub-title">
        <h2>{intl.formatMessage({ id: "รายละเอียดคิวคนไข้" })}</h2>
      </div>
      <Form className="queue-filter">
        <Form.Group inline>
          <Form.Checkbox width={2} label={intl.formatMessage({ id: "แพทย์" })} checked={chkDoctor} onChange={handleCheckDoctor}/>
          <Form.Checkbox width={2} label={intl.formatMessage({ id: "พยาบาล" })} checked={chkNurse} onChange={handleCheckNurse}/>
          <Form.Input width={12} icon="search" value={searchText} onChange={handleSearchChange} placeholder={intl.formatMessage({ id: "ค้นหาผู้ให้คำปรึกษา..." })}/>
        </Form.Group>
      </Form>
      {nurseDivServiceBlocks
        .filter(item => {
            // if (props.userProviderId && props.filterProvider) { 
              // return item.provider === props.userProviderId && item.provider_name.toLowerCase().includes(searchText);
              // return item.provider === props.userProviderId;
            // } 
              if(!item.provider){
                return item
              } else {
                return item.provider_name.toLowerCase().includes(searchText);
              }
        })
        .filter(d => d.division === props.division)
        .sort((a, b) => a.start_serial - b.start_serial)
        .map((d, index) => {
          return (
            <div key={index}>
              {d.provider === null ? (
                <div className="noProvider">
                  <h3>{d.division_name} {serial_to_datestr(d.start_serial, "time")} - {serial_to_datestr(d.end_serial, "time")}</h3>
                </div>
              ) : (
                <div>
                  <div className="provider">
                    <h3 className="provider-name">
                      {d.provider_name} (จำนวนคนไข้{" "}
                      {d.service_appointments.length} คน / รับได้ {d.capacity}{" "}
                      คน)
                    </h3>
                    &nbsp;&nbsp;
                    {!props.hideScheduleButton &&
                    <Button
                      className="primary outline"
                      size="small"
                      onClick={() =>
                        handleDocScheduleClick({ providerId: d.provider })
                      }
                    >
                      <Icon name="checked calendar" />
                      ดูตารางออกตรวจ
                    </Button>}
                  </div>
                  {d.service_appointments.map((a, a_index) => (
                    <div className="appointment" key={a_index}>
                      <h4>
                      {`${a_index + 1}. ${a.patient_first_name} ${a.patient_last_name} เวลาโดยประมาณ ${a.estimate_service_at
                          ? moment(a.estimate_service_at).format("HH:mm")
                          : "-"} น.`}
                      </h4>
                    </div>
                  ))}
                  <Divider />
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
};

TimeNurse.defaultProps = {
  division_service_blocks: [],
  queueController: {},
  filterProvider: false,
  hideScheduleButton: false,
};

export default React.memo(TimeNurse);
