import React, { useMemo, useState } from "react";

// UX
import CardEssentialImagingUX from "./CardEssentialImagingUX";
import { ACTION, checkHideButton } from "./CardOtherForm";
import PreOPButtonAction from "./PreOPButtonAction";
import { useIntl } from "react-intl";

export const ESSENTIAL_IMAGING = "essentialImaging";
const CARD_ESSENTIAL_IMAGING = "CardEssentialImaging";

const CardEssentialImaging = (props: any) => {
  const essentialImagingOptions = useMemo(() => {
    return [
      ...(props.masterOptions?.essentialImaging || [])
        .map((item: any) => {
          return {
            key: item.key,
            text: item.text,
            value: item.value,
          };
        }),
    ];
  }, [props.masterOptions?.essentialImaging]);

  const handleSave = (action: string) => (e: any, v: any) => {
    props.onSave(action)(e, {
      ...v,
      formCode: CARD_ESSENTIAL_IMAGING,
      formName: "Essentail Imaging",
      key: ESSENTIAL_IMAGING,
    });
  };

  const handleAdditionOptions = (e: any, { value }: any) => {
    props.setProp("masterOptions.essentialImaging", [
      { text: value, value: value, key: value },
      ...props.masterOptions?.essentialImaging,
    ]);
  };

  console.log(props);

  return (
    <>
      <CardEssentialImagingUX
        // data
        essentialImaging={props.data?.data?.special_equipment}
        status={props.data?.status}
        // options
        essentialImagingOptions={essentialImagingOptions}
        // callback
        onChangeData={props.onChangeData}
        onAdditionOptions={handleAdditionOptions}
        // config
        config={{
          hideStatus: checkHideButton(props.data, "STATUS"),
        }}
        // Element
        ButtonSave={
          <PreOPButtonAction
            setProp={props.setProp}
            data={{ [ESSENTIAL_IMAGING]: props.data }}
            type="save"
            cardKey={ESSENTIAL_IMAGING}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleSave(ACTION.SAVE)}
          />
        }
        ButtonConfirm={
          <PreOPButtonAction
            setProp={props.setProp}
            data={{ [ESSENTIAL_IMAGING]: props.data }}
            type="confirm"
            cardKey={ESSENTIAL_IMAGING}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleSave(ACTION.CONFIRM)}
          />
        }
        ButtonUnConfirm={
          <PreOPButtonAction
            setProp={props.setProp}
            data={{ [ESSENTIAL_IMAGING]: props.data }}
            type="unconfirm"
            cardKey={ESSENTIAL_IMAGING}
            buttonLoadCheck={props.buttonLoadCheck}
            onClick={handleSave(ACTION.UNCONFIRM)}
          />
        }
        languageUX={props.languageUX}
      />
    </>
  );
};

export default React.memo(CardEssentialImaging);
