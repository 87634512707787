import CONFIG from "config/config";
import moment from "moment";

// Utils
import { formatDate, formatDatetime } from "../../../utils/dateUtils";
import CompanyLogoForm from "react-lib/apps/HISV3/common/CompanyLogoForm";
import { TDocumentDefinitions } from "pdfmake/interfaces";

// Constant
const COLORS = {
  primary: "#5dbcd2",
  blue: "#063bab",
  light_blue: "#b2f1ff",
  grey: "#d9d9d9",
};

const FORM_NAME = "FormTransferStock";

// var logoExists: boolean;

// LogoFormCheck(CONFIG.COMPANY, FORM_NAME).then(result => logoExists = result);

const FormTransferStock = async (props: any): Promise<TDocumentDefinitions> => {
  console.log("FormTransferStock", props);

  const companyLogoForm = await CompanyLogoForm({
    type: 2,
    font: "THSarabunNew",
    height: 30,
    form: FORM_NAME,
  });
  const { font, images } = companyLogoForm;

  return {
    pageSize: "A4",
    defaultStyle: {
      font: font,
      lineHeight: 1,
      fontSize: 18,
    },
    pageMargins: [25, 270, 30, 30],
    styles: {
      fieldHeader: {
        fontSize: 28,
        bold: true,
        color: COLORS.blue,
      },
      tableHeader: {
        fontSize: 23,
        bold: true,
      },
      fieldKey: {
        bold: true,
        fontSize: 23,
      },
      fieldValue: {
        fontSize: 23,
      },
      miniFieldKey: {
        bold: true,
        fontSize: 18,
      },
      miniFieldValue: {
        fontSize: 18,
      },
      footerValue: {
        fontSize: 18,
      },
    },
    header: (currentPage: number, pageCount: number) => {
      return {
        margin: [25, 20, 25, 0],
        stack: [
          {
            text: "ใบโอนสินค้า",
            style: "fieldHeader",
            alignment: "center",
          },
          {
            layout: "noBorders",
            table: {
              widths: ["100%"],
              heights: 1,
              body: [[{ text: "", fillColor: COLORS.primary }]],
            },
          },
          {
            margin: [0, 10, 0, 0],
            alignment: "right",
            stack: [
              {
                svg: `<svg width="190" height="30">
            <rect width="190" height="30" style="fill:${COLORS.light_blue};"  rx="5"/>
          </svg>`,
              },
              {
                text: ["เลขที่ใบขอ: ", { text: props.code, bold: false }],
                style: "fieldKey",
                relativePosition: { x: -13.5, y: -27.5 },
              },
            ],
          },
          {
            margin: [0, 25, 0, 0],
            stack: [
              [
                [
                  "วันที่ขอ: ",
                  `${formatDate(moment(props.requested?.datetime))} [${moment(
                    props.requested?.datetime
                  ).format("HH:mm")}]`,
                ],
                [
                  "วันที่อนุมัติ: ",
                  props.approved?.datetime
                    ? `${formatDate(
                        moment(props.approved?.datetime)
                      )} [${moment(props.approved?.datetime).format("HH:mm")}]`
                    : "",
                ],
              ],
              [
                ["แผนกผู้ขอ: ", props.requester_name],
                ["แผนกผู้โอน: ", props.provider_name],
              ],
              [
                ["ผู้ขอ: ", props.requested?.name || ""],
                ["ผู้อนุมัติ: ", props.approved?.name || ""],
              ],
            ].map((item) => ({
              columns: [
                {
                  width: "52.5%",
                  text: [item[0][0], { text: item[0][1], bold: false }],
                  style: "fieldKey",
                },
                {
                  width: "*",
                  text: [item[1][0], { text: item[1][1], bold: false }],
                  style: "fieldKey",
                },
              ],
            })),
          },
          {
            margin: [0, 20, 0, 0],
            text: "รายการสินค้า",
            style: "fieldKey",
          },
        ],
      };
    },
    content: [
      {
        table: {
          headerRows: 1,
          widths: ["17%", "30%", "14%", "19%", "10%", "10%"],
          body: [
            [
              { text: "Code", style: "tableHeader", fillColor: COLORS.grey },
              { text: "Name", style: "tableHeader", fillColor: COLORS.grey },
              {
                text: "Lot No.",
                style: "tableHeader",
                alignment: "center",
                fillColor: COLORS.grey,
              },
              {
                text: "Expiry date",
                style: "tableHeader",
                alignment: "center",
                fillColor: COLORS.grey,
              },
              {
                text: "จำนวน",
                style: "tableHeader",
                alignment: "center",
                fillColor: COLORS.grey,
              },
              {
                text: "รวม",
                style: "tableHeader",
                alignment: "center",
                fillColor: COLORS.grey,
              },
            ],
            ...props.items.map((item: any) => [
              { text: item.code, style: "fieldValue", rowSpan: item.rowSpan },

              { text: item.name, style: "fieldValue", rowSpan: item.rowSpan },
              { text: item.lot_no, style: "fieldValue", alignment: "center" },
              {
                text: item.expire_date,
                style: "fieldValue",
                alignment: "center",
              },
              {
                text: item.quantity,
                style: "fieldValue",
                alignment: "center",
              },
              { text: item.total, style: "fieldValue", alignment: "center" },
            ]),
          ],
        },
      },
    ],
    footer: function (currentPage: number, pageCount: number) {
      return {
        margin: [25, 0, 25, 0],
        stack: [
          {
            table: {
              widths: ["100%"],
              body: [[{ text: "", border: [false, true, false, false] }]],
            },
          },
          {
            columns: [
              {
                width: "auto",
                text: "พิมพ์เอกสารโดย งานของยาผู้ป่วยนอก",
                style: "footerValue",
              },
              {
                width: "*",
                margin: [15, 0, 0, 0],
                text: `${formatDatetime(moment())}`,
                style: "footerValue",
              },
              {
                width: "auto",
                text: `หน้าที่ ${currentPage}/${pageCount}`,
                style: "footerValue",
              },
            ],
          },
        ],
      };
    },
    images: {
      ...images,
    },
  };
};

export default FormTransferStock;
