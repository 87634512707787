import WasmController from 'react-lib/frameworks/WasmController';

export type State = 
  {
  }

export const StateInitial: State = 
  {
  }

export type Event = 
  { message: "getRoomList", params: any }
  |  { message: "getEventTypeChoices", params: any }
  |  { message: "getEncounterDoctorOrder", params: any }
  |  { message: "putMonitorEvent", params: any }
  |  { message: "getMonitorEvent", params: any }
  |  { message: "deleteMonitorEvent", params: any }
  |  { message: "getMedicationsRecordChoices", params: any }
  |  { message: "createMedicationsRecordChoices", params: any }
  |  { message: "getFlowSheetVitalSigns", params: any }
  |  { message: "getFlowSheetMedication", params: any }
  |  { message: "getFlowSheetVentilater", params: any }
  |  { message: "getFlowSheetArterial", params: any }
  |  { message: "getFlowSheetOverALLBalance", params: any }
  |  { message: "getGraphicSheetOverAllBalance", params: any }
  |  { message: "getIcuIntakeOutputDetail", params: any }
  |  { message: "getIntakeOutputChoise", params: any }
  |  { message: "getIntakeOutput", params: any }
  |  { message: "getFlowsheetDiet", params: any }
  |  { message: "putMedicationRecordItem", params: any }
  |  { message: "postMedicationRecordItem", params: any }
  |  { message: "postGraphicSheetPrint", params: any }
  |  { message: "postFlowSheetPrint", params: any }
  |  { message: "postIntakeOutputRecordItemCreateUpdate", params: any }
  |  { message: "getPeriodWorkerTimeSlot", params: any }
  |  { message: "icuService", params: any }

export type Data = 
  {
    division?: number,
  }

export const DataInitial = 
  {
  }

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any 

export const getRoomList: Handler = async (controller, {}) => {
//function (_a) {
//            var roomDivision = _a.roomDivision, status = _a.status, an = _a.an, hn = _a.hn, cancelToken = _a.cancelToken;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, _b, error, response, network;
//                return __generator(this, function (_c) {
//                    switch (_c.label) {
//                        case 0:
//                            params = {
//                                room__division: roomDivision,
//                                status: status,
//                                an: an,
//                                hn: hn,
//                                cancelToken: cancelToken
//                            };
//                            return [4 /*yield*/, utils_1.to(this.icuService.getRoomList(params))];
//                        case 1:
//                            _b = _c.sent(), error = _b[0], response = _b[1], network = _b[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (params) {
//        return this.client
//            .get(apis_1.ICU.ROOM_LIST, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ICU/room-list/
}

export const getEventTypeChoices: Handler = async (controller, {}) => {
//function () { return __awaiter(_this, void 0, void 0, function () {
//            var _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0: return [4 /*yield*/, utils_1.to(this.icuService.getEventTypeChoice())];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//
//----> Name mismatch between manager and service
}

export const getEncounterDoctorOrder: Handler = async (controller, {}) => {
//function (_a) {
//            var encounterId = _a.encounterId, eventType = _a.eventType, cancelToken = _a.cancelToken, limit = _a.limit, offset = _a.offset;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, _b, error, response, network;
//                return __generator(this, function (_c) {
//                    switch (_c.label) {
//                        case 0:
//                            params = {
//                                encounter: encounterId,
//                                event_type: eventType,
//                                cancelToken: cancelToken,
//                                limit: limit,
//                                offset: offset
//                            };
//                            return [4 /*yield*/, utils_1.to(this.icuService.getEncounterDoctorOrder(params))];
//                        case 1:
//                            _b = _c.sent(), error = _b[0], response = _b[1], network = _b[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (params) {
//        return this.client
//            .get(apis_1.ICU.GET_DOCTOR_ORDER, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ICU/encounter-doctor-orders/
}

export const putMonitorEvent: Handler = async (controller, {}) => {
//function (_a) {
//            var encounterId = _a.encounterId, item = _a.item;
//            return __awaiter(_this, void 0, void 0, function () {
//                var _b, error, response, network;
//                return __generator(this, function (_c) {
//                    switch (_c.label) {
//                        case 0: return [4 /*yield*/, utils_1.to(this.icuService.putMonitorEvent(encounterId, item))];
//                        case 1:
//                            _b = _c.sent(), error = _b[0], response = _b[1], network = _b[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (encounterId, item) {
//        var url = apis_1.ICU.ADD_MONITOR_EVENT({ encounter_id: encounterId });
//        return this.client
//            .put(url, { items: item })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/ICU/encounter-doctor-orders/add/encounter/' +
//((__t = (encounter_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const getMonitorEvent: Handler = async (controller, {}) => {
//function (_a) {
//            var encounterId = _a.encounterId, cancelToken = _a.cancelToken;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, _b, error, response, network;
//                return __generator(this, function (_c) {
//                    switch (_c.label) {
//                        case 0:
//                            params = {
//                                encounter: encounterId,
//                                cancelToken: cancelToken
//                            };
//                            return [4 /*yield*/, utils_1.to(this.icuService.getMonitorEvent(params))];
//                        case 1:
//                            _b = _c.sent(), error = _b[0], response = _b[1], network = _b[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (params) {
//        return this.client
//            .get(apis_1.ICU.GET_MONITOR_EVENT, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ICU/graphic-sheet/monitor-events/
}

export const deleteMonitorEvent: Handler = async (controller, {}) => {
//function (_a) {
//            var monitorId = _a.monitorId, item = _a.item;
//            return __awaiter(_this, void 0, void 0, function () {
//                var _b, error, response, network;
//                return __generator(this, function (_c) {
//                    switch (_c.label) {
//                        case 0: return [4 /*yield*/, utils_1.to(this.icuService.deleteMonitorEvent(monitorId, item))];
//                        case 1:
//                            _b = _c.sent(), error = _b[0], response = _b[1], network = _b[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (monitorId, item) {
//        var url = apis_1.ICU.REMOVE_MONITOR_EVENT({ monitor_id: monitorId });
//        return this.client
//            .put(url, { items: item })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/ICU/encounter-doctor-orders/remove/' +
//((__t = (monitor_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const getMedicationsRecordChoices: Handler = async (controller, {}) => {
//function () { return __awaiter(_this, void 0, void 0, function () {
//            var params, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        params = {};
//                        return [4 /*yield*/, utils_1.to(this.icuService.getMedicationsRecordChoices({
//                                params: params
//                            }))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params;
//        return this.client
//            .get(apis_1.ICU.ICU_MEDICATION_RECORD_ITEM_CHOICE, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ICU/icu-medication/
}

export const createMedicationsRecordChoices: Handler = async (controller, {}) => {
//function (_a) {
//            var choiceName = _a.choiceName;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, data, _b, error, response, network;
//                return __generator(this, function (_c) {
//                    switch (_c.label) {
//                        case 0:
//                            params = {};
//                            data = {
//                                name: choiceName
//                            };
//                            return [4 /*yield*/, utils_1.to(this.icuService.createMedicationsRecordChoices({
//                                    params: params,
//                                    data: data
//                                }))];
//                        case 1:
//                            _b = _c.sent(), error = _b[0], response = _b[1], network = _b[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params, data = _b.data;
//        return this.client
//            .post(apis_1.ICU.ICU_MEDICATION_RECORD_ITEM_CHOICE, data, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ICU/icu-medication/
}

export const getFlowSheetVitalSigns: Handler = async (controller, {}) => {
//function (_a, cancelToken) {
//            var _b = _a === void 0 ? {} : _a, encounter = _b.encounter, hourly = _b.hourly, date = _b.date, startTime = _b.startTime, endTime = _b.endTime;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {
//                                encounter: encounter,
//                                hourly: hourly,
//                                date: date,
//                                start_time: startTime,
//                                end_time: endTime
//                            };
//                            return [4 /*yield*/, utils_1.to(this.icuService.getFlowSheetVitalSigns(params, cancelToken))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (params, cancelToken) {
//        return this.client
//            .get(apis_1.ICU.FLOW_SHEET_VITAL_SIGNS, { params: params, cancelToken: cancelToken })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ICU/flow-sheet/vital-signs/
}

export const getFlowSheetMedication: Handler = async (controller, {}) => {
//function (_a) {
//            var encounter = _a.encounter, hourly = _a.hourly, date = _a.date, startTime = _a.startTime, endTime = _a.endTime, cancelToken = _a.cancelToken;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, _b, error, response, network;
//                return __generator(this, function (_c) {
//                    switch (_c.label) {
//                        case 0:
//                            params = {
//                                encounter: encounter,
//                                hourly: hourly,
//                                date: date,
//                                start_time: startTime,
//                                end_time: endTime
//                            };
//                            return [4 /*yield*/, utils_1.to(this.icuService.getFlowSheetMedication(params, cancelToken))];
//                        case 1:
//                            _b = _c.sent(), error = _b[0], response = _b[1], network = _b[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (params, cancelToken) {
//        return this.client
//            .get(apis_1.ICU.FLOW_SHEET_MEDICATIONS, { params: params, cancelToken: cancelToken })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ICU/flow-sheet/medications/
}

export const getFlowSheetVentilater: Handler = async (controller, {}) => {
//function (_a) {
//            var encounter = _a.encounter, hourly = _a.hourly, date = _a.date, startTime = _a.startTime, endTime = _a.endTime, cancelToken = _a.cancelToken;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, _b, error, response, network;
//                return __generator(this, function (_c) {
//                    switch (_c.label) {
//                        case 0:
//                            params = {
//                                encounter: encounter,
//                                hourly: hourly,
//                                date: date,
//                                start_time: startTime,
//                                end_time: endTime
//                            };
//                            return [4 /*yield*/, utils_1.to(this.icuService.getFlowSheetVentilater(params, cancelToken))];
//                        case 1:
//                            _b = _c.sent(), error = _b[0], response = _b[1], network = _b[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (params, cancelToken) {
//        return this.client
//            .get(apis_1.ICU.FLOW_SHEET_VENTILATER, { params: params, cancelToken: cancelToken })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ICU/flow-sheet/ventilater/
}

export const getFlowSheetArterial: Handler = async (controller, {}) => {
//function (_a) {
//            var encounter = _a.encounter, hourly = _a.hourly, date = _a.date, startTime = _a.startTime, endTime = _a.endTime, cancelToken = _a.cancelToken;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, _b, error, response, network;
//                return __generator(this, function (_c) {
//                    switch (_c.label) {
//                        case 0:
//                            params = {
//                                encounter: encounter,
//                                hourly: hourly,
//                                date: date,
//                                start_time: startTime,
//                                end_time: endTime
//                            };
//                            return [4 /*yield*/, utils_1.to(this.icuService.getFlowSheetArterial(params, cancelToken))];
//                        case 1:
//                            _b = _c.sent(), error = _b[0], response = _b[1], network = _b[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (params, cancelToken) {
//        return this.client
//            .get(apis_1.ICU.FLOW_SHEET_ARTERIAL, { params: params, cancelToken: cancelToken })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ICU/flow-sheet/arterial/
}

export const getFlowSheetOverALLBalance: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, encounterId = _b.encounterId, date = _b.date, cancelToken = _b.cancelToken;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {
//                                encounter: encounterId,
//                                date: date
//                            };
//                            return [4 /*yield*/, utils_1.to(this.icuService.getFlowSheetOverAllBalance(params, cancelToken))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//
//----> Name mismatch between manager and service
}

export const getGraphicSheetOverAllBalance: Handler = async (controller, {}) => {
//function (_a) {
//            var encounterId = _a.encounterId, startDate = _a.startDate, endDate = _a.endDate, cancelToken = _a.cancelToken;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, _b, error, response, network;
//                return __generator(this, function (_c) {
//                    switch (_c.label) {
//                        case 0:
//                            params = {
//                                encounter: encounterId,
//                                start_date: startDate,
//                                end_date: endDate
//                            };
//                            return [4 /*yield*/, utils_1.to(this.icuService.getGraphicSheetOverAllBalance(params, cancelToken))];
//                        case 1:
//                            _b = _c.sent(), error = _b[0], response = _b[1], network = _b[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (params, cancelToken) {
//        return this.client
//            .get(apis_1.ICU.GRAPHIC_SHEET_OVERALL_BALANCE, { params: params, cancelToken: cancelToken })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ICU/graphic-sheet/overall-balance/
}

export const getIcuIntakeOutputDetail: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, type = _b.type;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            if (type) {
//                                params.intake_output_type = type;
//                            }
//                            return [4 /*yield*/, utils_1.to(this.icuService.getIcuIntakeOutputDetail({
//                                    params: params
//                                }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params;
//        return this.client
//            .get(apis_1.ICU.ICU_INTAKE_OUTPUT_DETAIL, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ICU/icu-intake-output-detail/
}

export const getIntakeOutputChoise: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, type = _b.type;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            if (type) {
//                                params.intake_output_type = type;
//                            }
//                            return [4 /*yield*/, utils_1.to(this.icuService.getIntakeOutputChoise({
//                                    params: params
//                                }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params;
//        return this.client
//            .get(apis_1.ICU.ICU_INTAKE_OUTPUT, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ICU/icu-intake-output/
}

export const getIntakeOutput: Handler = async (controller, {}) => {
//function (_a) {
//            var params = _a.params, cancelToken = _a.cancelToken;
//            return __awaiter(_this, void 0, void 0, function () {
//                var _b, error, response, network;
//                return __generator(this, function (_c) {
//                    switch (_c.label) {
//                        case 0: return [4 /*yield*/, utils_1.to(this.icuService.getIntakeOutput(params, cancelToken))];
//                        case 1:
//                            _b = _c.sent(), error = _b[0], response = _b[1], network = _b[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (params, cancelToken) {
//        return this.client
//            .get(apis_1.ICU.ICU_INTAKE_OUTPUT_FLOWSHEET, { params: params, cancelToken: cancelToken })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ICU/flow-sheet/intake-output/
}

export const getFlowsheetDiet: Handler = async (controller, {}) => {
//function (_a) {
//            var params = _a.params, cancelToken = _a.cancelToken;
//            return __awaiter(_this, void 0, void 0, function () {
//                var _b, error, response, network;
//                return __generator(this, function (_c) {
//                    switch (_c.label) {
//                        case 0: return [4 /*yield*/, utils_1.to(this.icuService.getFlowsheetDiet(params, cancelToken))];
//                        case 1:
//                            _b = _c.sent(), error = _b[0], response = _b[1], network = _b[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (params, cancelToken) {
//        return this.client
//            .get(apis_1.ICU.FLOW_SHEET_DIET, { params: params, cancelToken: cancelToken })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ICU/flow-sheet/diet/
}

export const putMedicationRecordItem: Handler = async (controller, {}) => {
//function (params) { return __awaiter(_this, void 0, void 0, function () {
//            var _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0: return [4 /*yield*/, utils_1.to(this.icuService.putMedicationRecordItem(params))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (_a) {
//        var params = _a.params, data = _a.data, medicationRecordItemId = _a.medicationRecordItemId;
//        var url = apis_1.ICU.MEDICATION_RECORD_ITEM_ID({
//            medication_record_item_id: medicationRecordItemId
//        });
//        return this.client
//            .put(url, data, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/ICU/icu-medication-record-item/' +
//((__t = (medication_record_item_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const postMedicationRecordItem: Handler = async (controller, {}) => {
//function (params) { return __awaiter(_this, void 0, void 0, function () {
//            var _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0: return [4 /*yield*/, utils_1.to(this.icuService.postMedicationRecordItem(params))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (_a) {
//        var params = _a.params, data = _a.data;
//        return this.client
//            .post(apis_1.ICU.MEDICATION_RECORD_ITEM, data, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ICU/icu-medication-record-item/
}

export const postGraphicSheetPrint: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, encounterId = _b.encounterId, startDate = _b.startDate, endDate = _b.endDate, pdf = _b.pdf, image = _b.image;
//            return __awaiter(_this, void 0, void 0, function () {
//                var data, params, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            data = {};
//                            params = {};
//                            if (encounterId) {
//                                data.encounter = encounterId;
//                            }
//                            if (startDate) {
//                                data.start_date = startDate;
//                            }
//                            if (endDate) {
//                                data.end_date = endDate;
//                            }
//                            if (pdf || pdf === false) {
//                                data.pdf = pdf;
//                            }
//                            if (image) {
//                                data.image = image;
//                            }
//                            return [4 /*yield*/, utils_1.to(this.icuService.postGraphicSheetPrint({
//                                    data: data,
//                                    params: params
//                                }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params, data = _b.data;
//        return this.client
//            .post(apis_1.ICU.GRAPHIC_SHEET_PRINT, data, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ICU/graphic-sheet/print/
}

export const postFlowSheetPrint: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, encounterId = _b.encounterId, startTime = _b.startTime, endTime = _b.endTime, date = _b.date, hourly = _b.hourly, pdf = _b.pdf;
//            return __awaiter(_this, void 0, void 0, function () {
//                var data, params, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            data = {};
//                            params = {};
//                            if (encounterId) {
//                                data.encounter = encounterId;
//                            }
//                            if (startTime) {
//                                data.start_time = startTime;
//                            }
//                            if (endTime) {
//                                data.end_time = endTime;
//                            }
//                            if (pdf || pdf === false) {
//                                data.pdf = pdf;
//                            }
//                            if (date) {
//                                data.date = date;
//                            }
//                            if (hourly) {
//                                data.hourly = hourly;
//                            }
//                            return [4 /*yield*/, utils_1.to(this.icuService.postFlowSheetPrint({
//                                    data: data,
//                                    params: params
//                                }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params, data = _b.data;
//        return this.client
//            .post(apis_1.ICU.FLOW_SHEET_PRINT, data, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ICU/flow-sheet/print/
}

export const postIntakeOutputRecordItemCreateUpdate: Handler = async (controller, {}) => {
//function (_a) {
//            var encounterId = _a.encounterId, id = _a.id, group = _a.group, intake_output_type = _a.intake_output_type, name = _a.name, measurement = _a.measurement, date = _a.date, time = _a.time, stool_type = _a.stool_type, value = _a.value, detail = _a.detail;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, prepare, data, _b, error, response, network;
//                return __generator(this, function (_c) {
//                    switch (_c.label) {
//                        case 0:
//                            params = {};
//                            prepare = {
//                                group: group,
//                                value: value
//                            };
//                            if (id) {
//                                prepare.id = id;
//                            }
//                            if (intake_output_type) {
//                                prepare.intake_output_type = intake_output_type;
//                            }
//                            if (measurement) {
//                                prepare.measurement = measurement;
//                            }
//                            if (name) {
//                                prepare.name = name;
//                            }
//                            if (date) {
//                                prepare.date = date;
//                            }
//                            if (time) {
//                                prepare.time = time;
//                            }
//                            if (stool_type) {
//                                prepare.stool_type = stool_type;
//                            }
//                            data = {
//                                items: [prepare],
//                                encounter: encounterId
//                            };
//                            return [4 /*yield*/, utils_1.to(this.icuService.postIntakeOutputRecordItemCreateUpdate({
//                                    data: data,
//                                    params: params
//                                }))];
//                        case 1:
//                            _b = _c.sent(), error = _b[0], response = _b[1], network = _b[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var params = _a.params, data = _a.data;
//        return this.client
//            .post(apis_1.ICU.INTAKE_OUTPUT_CREATE_UPDATE, data, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ICU/icu-intake-output-record-item-create-update/
}

export const getPeriodWorkerTimeSlot: Handler = async (controller, {}) => {
//function () { return __awaiter(_this, void 0, void 0, function () {
//            var params, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        params = {};
//                        return [4 /*yield*/, utils_1.to(this.icuService.getPeriodWorkerTimeSlot({
//                                params: params
//                            }))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params;
//        return this.client
//            .get(apis_1.ICU.PERIOD_WORKER_TIME_SLOT, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ICU/period-worker-time-slot/
}

export const icuService: Handler = async (controller, {}) => {
//[object Object]
//
//----> Name mismatch between manager and service
}
