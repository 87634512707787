import BaseService from "./BaseService";
import { TPD } from "../../configs/apis";
class TPDService extends BaseService {
  /**
   * get patient data by hn
   * @param {encounter_id} int
   */
  printMedRecord(encounterId) {
    const url = TPD.PRINT_MED_RECORD({ encounter_id: encounterId });
    return this.client
      .get(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getPrintMedRecord(params) {
    const url = TPD.PRINT_MED_RECORD
    return this.client
      .get(url, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getDrugAvailable(encounterId) {
    const url = TPD.GET_DRUG_ORDER_TYPE_AVAILABLE({
      encounter_id: encounterId
    });
    return this.client
      .get(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getMedicationRecordSummary(params) {
    const url = TPD.MED_RECORD_LIST_SUMMARY;
    return this.client
      .get(url, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getMedicationRecordSummaryPrint(params) {
    const url = TPD.MED_RECORD_LIST_SUMMARY_PRINT;
    return this.client
      .get(url, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getHomeMedicationDrugOrderItems(params) {
    const url = TPD.GET_HOME_MED_DRUG_ITEMS(params);
    return this.client
      .get(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getAllDrugOrderItems(params) {
    const url = TPD.GET_ALL_DRUG_ITEMS(params);
    return this.client
      .get(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getListMedAdminRecord(params) {
    const url = TPD.LIST_MED_ADMIN_RECORD;
    return this.client
      .get(url, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  postCreateMedAdminRecord(params) {
    const url = TPD.CREATE_MED_ADMIN_RECORD;
    return this.client
      .post(url, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  putUpdateMedAdminRecord(medicationRecordId, params) {
    const url = TPD.UPDATE_MED_ADMIN_RECORD_ID({ medication_record_id: medicationRecordId });
    return this.client
      .put(url, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  putMedAdminRecord(medicationRecordId, params) {
    const url = TPD.MED_RECORD_ID({ medication_record_id: medicationRecordId });
    return this.client
      .put(url, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  postMedAdminRecord(params) {
    const url = TPD.MED_RECORD;
    return this.client
      .post(url, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  putDrugOrderItemIdAddStdTimeManual(drugOrderItemId, params) {
    const url = TPD.DRUG_ORDER_ITEM_ID_ADD_STD_TIME_MANUAL({ drug_order_item_id: drugOrderItemId });
    return this.client
      .put(url, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  putDrugOrderItemIdEditStdTimeManual(drugOrderItemId, params) {
    const url = TPD.DRUG_ORDER_ITEM_ID_EDIT_STD_TIME_MANUAL({ drug_order_item_id: drugOrderItemId });
    return this.client
      .put(url, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  putDrugOrderItemIdAdminDivision(drugOrderItemId, params) {
    const url = TPD.DRUG_ORDER_ITEM_ID_ADMIN_DIVISION({ drug_order_item_id: drugOrderItemId });
    return this.client
      .put(url, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getDrugOrderItemForMedReconcile(params) {
    const url = TPD.DRUG_ORDER_ITEM_FOR_MED_RECONCILE;
    return this.client.get(url, { params })
      .then((response) => response.data)
      .catch(this.throwErrorMessage);
  }
  getMedReconciliation(params) {
    const url = TPD.MED_RECONCILIATION;
    return this.client.get(url, { params })
      .then((response) => response.data)
      .catch(this.throwErrorMessage);
  }
  getMedReconciliationWithId(medReconciliationId, params) {
    const url = TPD.MED_RECONCILIATION_ID({
      medication_reconciliation_id: medReconciliationId
    })
    return this.client.get(url, { params })
      .then((response) => response.data)
      .catch(this.throwErrorMessage);
  }
  getMedReconciliationLog(params) {
    const url = TPD.MED_RECONCILIATION_LOG
    return this.client.get(url, { params })
      .then((response) => response.data)
      .catch(this.throwErrorMessage);
  }

}
export default TPDService;
