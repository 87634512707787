import WasmController from "react-lib/frameworks/WasmController";
import DrugOrderTemplateList from "issara-sdk/apis/DrugOrderTemplateList_apps_TPDM";
import DrugOrderTemplateDetail from "issara-sdk/apis/DrugOrderTemplateDetail_apps_TPD"

export type State = {
  errorMessage?: any;
  currentDoctor?: any;
  DrugOrderTemplateSequence?: {
    sequenceIndex?: string | null;
    loading?: boolean;
    open?: boolean;
    drugTemplateList?: any[];
    drugItemList?: any[];
    drugOrderTemplateList?: any[];
    selectedDrugOrderItem?: any[];
    selectedDrugOrderItemId?: any[];
    selectedDrugOrderTemplates?: any;
    selectedTab?: null | "doctor" | "central";
    // selectedOrderId?: any; // ?
  } | null;
}

export const StateInitial: State = {
  DrugOrderTemplateSequence: null
}


export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: any }
  | { message: "AllVerifyDrug"; params: {}};

export type Data = {
  division?: number;
  device?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;



export const Start: Handler = async (controller, params) => {
  const state = controller.getState();
  const drugOrderTemplate = await DrugOrderTemplateList.list({
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division,
      device: controller.data.device
    }
  });

  if (drugOrderTemplate[1]) {
    return controller.setState({
      errorMessage: { ...state.errorMessage, [params?.card]: drugOrderTemplate[1] }
    })
  }

  let drugItem: any = []
  drugOrderTemplate[0]?.items.forEach((item: any) => {
    drugItem = drugItem.concat(item?.items || [])
  })

  controller.setState({
    DrugOrderTemplateSequence: {
      sequenceIndex: "Action",
      loading: false,
      open: true,
      drugTemplateList: [],
      drugOrderTemplateList: drugOrderTemplate[0]?.items || [],
      selectedDrugOrderTemplates: null,
      selectedDrugOrderItem: [],
      selectedDrugOrderItemId: [],
      selectedTab: "doctor",
      drugItemList: drugItem
      // selectedOrderId?: any; // ?
    }
  })
}

export const HandleAction: Handler = async (controller, params) => {
  const state = controller.getState();
  if (params.action === "change_tab" && params?.tabName) {
    if (params.tabName === state.DrugOrderTemplateSequence?.selectedTab) return;
    const drugOrderTemplate = await DrugOrderTemplateList.list({
      apiToken: controller.apiToken,
      params: {
        central_display: params?.tabName === "central" ? true : false
      },
      extra: {
        division: controller.data.division,
        device: controller.data.device
      }
    });

    if (drugOrderTemplate[1]) {
      return controller.setState({
        errorMessage: { ...state.errorMessage, [params?.card]: drugOrderTemplate[1] }
      })
    }

    let drugOrderTemplateList = [...drugOrderTemplate[0]?.items] || []
    if(params?.tabName === "central"){
      drugOrderTemplateList = drugOrderTemplateList.sort((a: any, b: any) => {
        if(a.favourite_doctors.includes(state.currentDoctor?.id) && !b.favourite_doctors.includes(state.currentDoctor?.id)){
          return -1
        }else if(b.favourite_doctors.includes(state.currentDoctor?.id) && !a.favourite_doctors.includes(state.currentDoctor?.id)){
          return 1
        }
        return 0
      })
    }

    let drugItem: any = []
    drugOrderTemplate[0]?.items.forEach((item: any) => {
      drugItem = drugItem.concat(item?.items || [])
    })

    controller.setState({
      DrugOrderTemplateSequence: {
        ...state.DrugOrderTemplateSequence,
        drugTemplateList: [],
        drugOrderTemplateList: drugOrderTemplateList,
        drugItemList: drugItem,
        selectedTab: params?.tabName
        // selectedOrderId?: any; // ?
      }
    })
  } else if (params?.action === "search") {
    if (params.tabName === state.DrugOrderTemplateSequence?.selectedTab) return;
    const drugOrderTemplate = await DrugOrderTemplateList.list({
      apiToken: controller.apiToken,
      params: {
        central_display: state.DrugOrderTemplateSequence?.selectedTab === "central" ? true : false,
        search_name: params?.searchText
      },
      extra: {
        division: controller.data.division,
        device: controller.data.device
      }
    });

    if (drugOrderTemplate[1]) {
      return controller.setState({
        errorMessage: { ...state.errorMessage, [params?.card]: drugOrderTemplate[1] }
      })
    }

    let drugOrderTemplateList = [...drugOrderTemplate[0]?.items] || []
    if(state.DrugOrderTemplateSequence?.selectedTab === "central"){
      drugOrderTemplateList = drugOrderTemplateList.sort((a: any, b: any) => {
        if(a.favourite_doctors.includes(state.currentDoctor?.id) && !b.favourite_doctors.includes(state.currentDoctor?.id)){
          return -1
        }else if(b.favourite_doctors.includes(state.currentDoctor?.id) && !a.favourite_doctors.includes(state.currentDoctor?.id)){
          return 1
        }
        return 0
      })
    }

    let drugItem: any = []
    drugOrderTemplate[0]?.items.forEach((item: any) => {
      drugItem = drugItem.concat(item?.items || [])
    })

    controller.setState({
      DrugOrderTemplateSequence: {
        ...state.DrugOrderTemplateSequence,
        drugTemplateList: [],
        drugOrderTemplateList: drugOrderTemplateList,
        drugItemList: drugItem
        // selectedOrderId?: any; // ?
      }
    })
  } else if (
    params.action === "select_drug_order" &&
    params?.selectedDrugOrder
  ) {
    // order
    let selectedDrugOrderList = [
      ...(state.DrugOrderTemplateSequence?.selectedDrugOrderTemplates || []),
    ];
    // drug item
    let selectedDrugItemList = [
      ...(state.DrugOrderTemplateSequence?.selectedDrugOrderItem || [])
    ]

    let targetDrugOrder = [...(state.DrugOrderTemplateSequence?.drugOrderTemplateList || [])]
      .find((order: any) => order.id === params.selectedDrugOrder)

    if (!targetDrugOrder) return;


    if (selectedDrugOrderList.includes(params.selectedDrugOrder)) {
      // order
      selectedDrugOrderList = selectedDrugOrderList.filter(
        (i) => i !== params.selectedDrugOrder
      );

      // drug item
      let drugOrderItemIdMap = targetDrugOrder?.items.map((drug: any) => drug.id)
      selectedDrugItemList = selectedDrugItemList.filter((drug: any) => !drugOrderItemIdMap.includes(drug.id))
    } else {
      // order
      selectedDrugOrderList.push(params.selectedDrugOrder);

      // drug item
      let selectedDrugItemMap = selectedDrugItemList.map((drug: any) => drug.id)
      targetDrugOrder?.items.forEach((drug: any) => {
        if (!selectedDrugItemMap.includes(drug.id))
          selectedDrugItemList.push(drug)
      })
    }
    controller.setState({
      DrugOrderTemplateSequence: {
        ...state.DrugOrderTemplateSequence,
        selectedDrugOrderTemplates: selectedDrugOrderList,
        selectedDrugOrderItem: selectedDrugItemList,
        selectedDrugOrderItemId: selectedDrugItemList.map((drug: any) => drug.id)
      },
    }, async () => {
      await HandleAction(controller, {action: "show", selectedDrugOrder: params.selectedDrugOrder})
    });

  } else if (params?.action === "selected_drug_item" && params?.selectedDrugItemId) {
    let selectedDrugItemList = [
      ...(state.DrugOrderTemplateSequence?.selectedDrugOrderItem || [])
    ]
    let selectedDrugOrderItemId = [...(state.DrugOrderTemplateSequence?.selectedDrugOrderItemId || [])]

    if (selectedDrugOrderItemId.includes(params.selectedDrugItemId)) {
      selectedDrugItemList = selectedDrugItemList.filter((drug: any) => drug.id !== params?.selectedDrugItemId)
    } else {
      let targetDrug = [
        ...(state.DrugOrderTemplateSequence?.drugItemList || [])
      ].find((drug: any) => drug.id === params.selectedDrugItemId)
      if (targetDrug) {
        selectedDrugItemList.push(targetDrug)
      }
    }

    controller.setState({
      DrugOrderTemplateSequence: {
        ...state.DrugOrderTemplateSequence,
        selectedDrugOrderItem: selectedDrugItemList,
        selectedDrugOrderItemId: selectedDrugItemList.map((drug: any) => drug.id)
      },
    });

  } else if (params?.action === "selected_all" && params?.selectedDrugOrder) {
    let targetDrugOrder = [...(state.DrugOrderTemplateSequence?.drugOrderTemplateList || [])]
      .find((order: any) => order.id === params.selectedDrugOrder)

    if (!targetDrugOrder) return;
    let drugItem = [...(state.DrugOrderTemplateSequence?.selectedDrugOrderItem || [])]

    let targetItemIdMap = targetDrugOrder.items?.map((drug: any) => drug.id)
    let isSomeExist = drugItem.some((drug: any) => targetItemIdMap.includes(drug.id))
    if (isSomeExist) {
      drugItem = drugItem.filter((drug: any) => !targetItemIdMap.includes(drug.id))
    } else {
      drugItem = drugItem.concat(targetDrugOrder?.items)
    }

    controller.setState({
      DrugOrderTemplateSequence: {
        ...state.DrugOrderTemplateSequence,
        selectedDrugOrderItem: drugItem,
        selectedDrugOrderItemId: drugItem.map((drug: any) => drug.id)
      },
    });

  } else if (params?.action === "show" && params?.selectedDrugOrder) {
    let drugOrderTarget = [
      ...(state.DrugOrderTemplateSequence?.drugOrderTemplateList || []),
    ].find((order) => order.id === params.selectedDrugOrder);
    controller.setState({
      DrugOrderTemplateSequence: {
        ...state.DrugOrderTemplateSequence,
        drugTemplateList: drugOrderTarget?.items || [],
      },
    });
  } else if (params.action === "close") {
    controller.setState({
      DrugOrderTemplateSequence: {
        ...state.DrugOrderTemplateSequence,
        open: false
      },
    });
  } else if (params?.action === "add_to_favourite" && params?.selectedDrugOrderData) {
    const drugOrderTemplateDetail = await DrugOrderTemplateDetail.update({
      pk: params.selectedDrugOrderData?.id,
      apiToken: controller.apiToken,
      data: {
        doctor:params.selectedDrugOrderData?.doctor,
        name: params.selectedDrugOrderData?.name,
        add_to_favourite: true
      },
      extra: {
        division: controller.data.division,
        device: controller.data.device
      }
    });

    if(drugOrderTemplateDetail[1]){
      return controller.setState({
        errorMessage: {...state.errorMessage, [params?.card]: drugOrderTemplateDetail[1]}
      })
    }

    return await HandleAction(controller, {...params, action: "search"})

  } else if (
    params.action === "add_to_order" &&
    (state.DrugOrderTemplateSequence?.selectedDrugOrderTemplates || [])?.length > 0
  ) {
    let drugDataTargets: any[] = [];
    let selectedDrugItemIdList = state?.DrugOrderTemplateSequence?.selectedDrugOrderItem?.map((drug: any) => drug.id)
    state.DrugOrderTemplateSequence?.drugOrderTemplateList
      ?.filter((order) =>
        state.DrugOrderTemplateSequence?.selectedDrugOrderTemplates?.includes(
          order.id
        )
      )
      .forEach((order) => {
        drugDataTargets = drugDataTargets.concat(
          order?.items.filter((drug: any) => selectedDrugItemIdList?.includes(drug.id)).map((drug: any) => {
            let newDrug = { ...drug };
            delete newDrug["id"];
            return newDrug;
          }) || []
        );
      });

    // remove drug item pk
    // drugDataTargets = drugDataTargets.()
    console.log("pre add to order: ", state.DrugOrderTemplateSequence?.selectedDrugOrderTemplates);
    controller.setState(
      {
        DrugOrderTemplateSequence: {
          ...state.DrugOrderTemplateSequence,
          open: false
        },
      },
        async () =>
        // TO DO: Change to verify
        controller.handleEvent({
          message: "AllVerifyDrug", 
          params: {
            action: "make_verify",
            orderType: params.orderType,
            drugItems: drugDataTargets
          }
        })
    );
    return;
  }
}
