import React from "react";
import PropTypes from "prop-types";
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import * as TPD from "../TPD";
import { Form, Radio, Input, Button, Modal } from "semantic-ui-react";
import _ from "../../compat/lodashplus";
import { useIntl } from "react-intl";

const SubADRAndOther = React.forwardRef((props, ref) => {
  const intl = useIntl();
  const [history, setHistory] = React.useState("not_know");
  const [openADRNoteDetail, setOpenADRNoteDetail] = React.useState(false);
  const [adrId, setAdrId] = React.useState(null);
  const [statusName, setStatusName] = React.useState(null);
  const [allergyText, setAllergyText] = React.useState("");
  const [action, setAction] = React.useState("NOT_KNOW");
  const [error, setError] = React.useState(null);
  const isMounted = React.useRef(false);

  React.useImperativeHandle(ref, () => ({
    refresh: () => {
      refresh();
    },
    save: () => {
      save();
    },
    clearError: () => {
      setError(null)
    },
    validate: () => validate()

  }));

  const clear = () => {
    setAllergyText("");
    setHistory("not_know");
    setAdrId(null);
    setStatusName(null);
  };

  const getAdverseReaction = async ({
    patientId,
    today,
    excludeCancel,
    byUser
  } = {}) => {
    const [data, error] = await props.controller.getAdverseReactionList({
      patientId,
      today,
      excludeCancel,
      byUser
    });
    if (data?.items?.length > 0) {
      setAllergyText(data.items[0].note);
      setAdrId(data.items[0].id);
      setStatusName(data.items[0].status);
      if (data.items[0].status == "NOTED") {
        setHistory("true");
        setAction("NOTE");
      } else if (data.items[0].status == "NO_REACTION") {
        setHistory("false");
        setAction("NO_REACTION");
      } else if (data.items[0].status == "NOT_KNOWN") {
        setHistory("not_know");
        setAction("NOT_KNOW");
      }
    }
  };

  const refresh = () => {
    clear();
    getAdverseReaction({
      patientId: props.patientData.patient_id,
      today: true,
      excludeCancel: true,
      byUser: true
    });
  };

  const save = () => {
    if (validate()) {
      if (adrId && statusName === "NOTED" && action === "NOTE") {
        putAdverseReaction();
      } else {
        postAdverseReaction();
      }
    } else {
      // validation failed, notify that we failed to save
      props.onSaveAllergy(false);
    }
  };

  React.useState(() => {
    isMounted.current = true;

    refresh();

    return () => {
      isMounted.current = false;
    };
  }, []);

  React.useState(() => {
    setAdrId(props.adrId);
  }, [props.adrId]);

  React.useState(() => {
    setStatusName(props.statusName);
  }, [props.statusName]);

  const postAdverseReaction = async () => {
    const [data, error] = await props.controller.postAdverseReaction({
      patientId: props.patientData.patient_id,
      action,
      note: allergyText
    });
    if (isMounted.current) {
      if (error) {
        props.onSaveAllergy(false);
      } else if (data) {
        props.onSaveAllergy(true);
      }
    }
  };

  const putAdverseReaction = async () => {
    const [data, error] = await props.controller.putAdverseReaction({
      patientId: props.patientData.patient_id,
      action,
      note: allergyText,
      adrId,
      changeNote: true
    });
    if (isMounted.current) {
      if (error) {
        props.onSaveAllergy(false);
      } else if (data) {
        props.onSaveAllergy(true);
      }
    }
  };

  const validate = () => {
    if (!history) {
      setError("กรุณาระบุ อาการไม่พึงประสงค์จากยาและอาหาร");
      return false;
    } else if (history === "true" && !allergyText) {
      setError("กรุณาระบุ รายละเอียดอาการไม่พึงประสงค์จากยาและอาหาร");
      return false;
    }
    return true;

  };

  return (
    <>
      {!props.hideForm && (
        <Form>
          <ErrorMessage error={error} />
          <Modal
            open={openADRNoteDetail}
            closeOnDimmerClick={true}
            onClose={() => setOpenADRNoteDetail(false)}
          >
            <TPD.CardADRNoteDetail
              controller={props.controller}
              patientData={props.patientData}
              onClose={() => setOpenADRNoteDetail(false)}
            />
          </Modal>

          <Form.Group inline>
            <Form.Field width={4}>
              <Radio
                label={_("ไม่ทราบประวัติ")}
                value="not_know"
                checked={history === "not_know"}
                onChange={() => setHistory("not_know")}
              />
            </Form.Field>
            <Form.Field width={2}>
              <Radio
                label={intl.formatMessage({ id: "ไม่มี" })}
                value="false"
                checked={history === "false"}
                onChange={() => setHistory("false")}
              />
            </Form.Field>
            <Form.Field width={2}>
              <Radio
                label={intl.formatMessage({ id: "มี" })}
                value="true"
                checked={history === "true"}
                onChange={() => setHistory("true")}
              />
            </Form.Field>
            <Form.Field
              width={5}
              error={error && history === "true" && !allergyText}
            >
              <Input
                disabled={history !== "true"}
                value={allergyText}
                onChange={(e) => setAllergyText(e.target.value)}
              />
            </Form.Field>
            <Form.Field width={3}>
              <Button
                color="blue"
                content="ประวัติ"
                onClick={() => setOpenADRNoteDetail(true)}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      )}
    </>
  );
});

SubADRAndOther.defaultProps = {
  patientData: {},
  controller: {},
  adrId: null,
  statusName: null,
  onSaveAllergy: () => { },
  hideForm: false
};

SubADRAndOther.propTypes = {
  controller: PropTypes.object,
  patientData: PropTypes.object,
  adrId: PropTypes.number,
  statusName: PropTypes.string,
  onSaveAllergy: PropTypes.func,
  hideForm: PropTypes.bool
};

export default React.memo(SubADRAndOther);
