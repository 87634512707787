import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import {
  Dropdown,
  Checkbox,
  Pagination,
  PaginationProps,
} from "semantic-ui-react";
import ReactTable from "react-table-6";
import CardCSDivisionSearch from "./CardCSDivisionSearch";
import CardCSDoctorSearch from "./CardCSDoctorSearch";
import { useIntl } from "react-intl";

/// Appointment 

interface CardCSPendingOrderProps {
  allDivisionList: any;
  allDoctorList: any;
  patientDataAPI: any;
  handleGetCSPendingOrderList: any;
  csPendingOrderList: any;
  pendingList: any;
}

// http://localhost:8000/apis/APP/appointment/?date__gte=2563-07-01&date__lte=2564-1-19&exclude_canceled=true&exclude_postponed=true&exclude_unconfirmed=true&offset=0&limit=100

const CardCSPendingOrder = forwardRef((props: CardCSPendingOrderProps, ref) => {
  const isMounted = useRef(true)
  const [isPendingTableLoading, setIsPendingTableLoading] = useState(false);
  const [isCSDivisionSearchLoading, setIsCSDivisionSearchLoading] = useState(false);
  const [isCSDoctorSearchLoading, setIsCSDoctorSearchLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [divisionSearch, setDivisionSearch] = useState("")
  const [doctorSearch, setDoctorSearch] = useState("")
  const limit = 6;
  const columns = [
    {
      Header: "Date",
      resizeable: false,
      Cell: (props) => (<div> {props.original.date} <br /> <span dangerouslySetInnerHTML={{ __html: props.original.time }}></span> </div>),
      width: 120,
    },
    {
      id: 'division',
      Header: "Division/Doctor",
      Cell: (props) => (<div> {props.original.division_code} <br /> {props.original.order_doctor_name} </div>),
      resizeable: false,
    },
    {
      Header: "Order",
      accessor: "type_name",
      resizeable: false,
      width: 300,
    },
  ];

  useImperativeHandle(ref, () => ({
    getData: () => {
      return {
        doctor: doctorSearch,
        division: divisionSearch,
        limit: limit,
        offset: activePage - 1,
      };
    },
  }));

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    const loadPendingOrder = async () => {
      setIsPendingTableLoading(true)
      await props.handleGetCSPendingOrderList()
      setIsPendingTableLoading(false)
    }
    loadPendingOrder()
  }, [props.patientDataAPI, doctorSearch, divisionSearch, activePage])

  const totalPages = Math.ceil(props.csPendingOrderList?.total / limit);
  // console.log(" props.csPendingOrderList", props.csPendingOrderList)

  return (
    <div className="card-cs card-pending-order">
      <div className="card-cs-header">
        <div style={{ width: "100px" }}></div>
        <div className="card-cs-header-title">Division</div>
        <CardCSDivisionSearch
          disabled={isPendingTableLoading}
          allDivisionList={props.allDivisionList}
          setDivisionSearch={(search) => {
            setDivisionSearch(search)
            setActivePage(1)
          }}
          isLoading={isCSDivisionSearchLoading} 
          languageUX={props.languageUX}
        />
        <div className="card-cs-header-title">Doctor</div>
        <CardCSDoctorSearch
          disabled={isPendingTableLoading}
          allDoctorList={props.allDoctorList}
          setDoctorSearch={(search) => {
            setDoctorSearch(search)
            setActivePage(1)
          }}
          isLoading={isCSDoctorSearchLoading}
          languageUX={props.languageUX}
        />
      </div>
      <div className="card-cs-header-title">Pending Order</div>
      <div className="card-wrap-cs-table">
        <ReactTable
          className="card-cs-table"
          loading={isPendingTableLoading}
          data={props.csPendingOrderList?.items?.length > 0 ? props.csPendingOrderList?.items : []}
          columns={columns}
          style={{ height: "100%" }}
          showPagination={false}
          minRows={6}
          NoDataComponent={() => null}
          getTheadThProps={(state, rowInfo, column, instance) => {
            // console.log(" getTdProps", state, rowInfo, column, instance)
            return {
              style: {
                fontSize: "12px",
                border: "none",
                boxShadow: "none",
                textAlign: "left",
              },
            };
          }}
          getTdProps={(state, rowInfo, column, instance) => {
            // console.log(" getTdProps", state, rowInfo, column, instance)
            let style = {
              fontSize: "12px",
              border: "none",
              boxShadow: "none",
              fontWeight: "bolder",
              color: "#4F4F4F",
            };
            if (column.Header === "Order Type" || column.Header === "Division") {
              style = { ...style, ...{ fontWeight: "normal" } };
            }
            return {
              style: style,
            };
          }}
        />
      </div>
      {totalPages > 1 ? (
        <div style={{ textAlign: "center" }}>
          <Pagination
            disabled={(totalPages === 1)}
            onPageChange={(
              e: React.MouseEvent<HTMLAnchorElement>,
              data: PaginationProps
            ) => {
              setActivePage(data.activePage as number);
            }}
            activePage={activePage}
            firstItem={null}
            lastItem={null}
            // ...( activePage === 1 && {firstItem : null} )
            // ...( activePage === totalPages && {lastItem : null} )
            ellipsisItem={null}
            pointing
            secondary
            siblingRange={1}
            totalPages={totalPages}
          />
        </div>
      ) : null}
    </div>
  );
});

export default React.memo(CardCSPendingOrder);
