import React from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "semantic-ui-react";
import { useIntl } from "react-intl";


const ModalUnauthrizedUser = props => {
  const intl = useIntl();
  return ( 
  <Modal size='tiny' open={props.open} onClose={props.onClose}>
    <Modal.Header>ขออภัยท่านไม่สามารถเข้าถึงผลการตรวจนี้ได้</Modal.Header>
    <Modal.Content>
      <p>{intl.formatMessage({ id: "เนื่องจากปัญหาด้านสิทธิการเข้าถึง กรุณาติดต่อ Admin เพื่อดำเนินการต่อ!" })}</p>
    </Modal.Content>
    <Modal.Actions>
      <Button negative onClick={props.onClose}>Close</Button>
    </Modal.Actions>
  </Modal>
  )
}

ModalUnauthrizedUser.defaultProps = {
  open: false,
  onClose: (() => {})
}

ModalUnauthrizedUser.propsTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ModalUnauthrizedUser;