import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'

export default class QmlComponent extends React.Component {
    static cardName = `DPI/SubIVSelection.qml`;
    
    constructor() {
        super()
        this._ismounted = false
        this.state = {
            qml : null
        }
    }

    componentDidMount() {
        this._ismounted = true
        //console.log("!!!<<<<<<CDM>>>>>>>!!! ", this.cardName)
        

        //console.log('about to this.loadQML')
// Soure code from QML file starts here 
setTimeout( () => { 
this.loadQML(String.raw`
import Semantic.Html 1.0
import QmlWeb 1.0
import DGrid 1.0
import '../Common' as Common

Common.CardLayout {
    expanded: true
    toggleCard: false
    headerColor: 'red'
    title: 'เลือก LAB'

    signal selected(var items)
    property string LAB_URL: '/apis/DPI/initial-investigation-lab/'
    property var ivDetails: new Object({})
    property var LAB_TYPE: new Object({
        central: 'CentralLabOrder',
        micrology: 'TODO:model_name',
        patho: 'TODO:model_name',
        cyto: 'TODO:model_name',
        xrays: 'TODO:model_name',
    })

    function filterAndShow() {
        subIvDetail.clear()
        // TODO: remove 9 from patient
        var query = {
            patient: typeof PATIENT_DATA !== 'undefined' ? PATIENT_DATA.patient_id : null
        }
        if (rdoCentralLab.checked) {
            query[LAB_TYPE.central] = true
        }
        if (rdoMicrology.checked) {
            query[LAB_TYPE.micrology] = true
        }
        if (rdoPatho.checked) {
            query[LAB_TYPE.patho] = true
        }
        if (rdoCyto.checked) {
            query[LAB_TYPE.cyto] = true
        }
        if (rdoXrays.checked) {
            query[LAB_TYPE.xrays] = true
        }
        if (chkDate.checked) {
            query['start_date'] = dtbStartDate.text
            query['end_date'] = dtbEndDate.text
        }
        if (chkOnlySelected.checked) {
            query['only_selected'] = true
            query['emr'] = typeof PATIENT_DATA !== 'undefined' ? PATIENT_DATA.EMR.emr_id : null
        }

        rmdLab.query = query
        rmdLab.fetch()
    }

    function clear() {
        grdSelection.model = []
        rdoCentralLab.checked = true
        rdoMicrology.checked = false
        rdoPatho.checked = false
        rdoCyto.checked = false
        rdoXrays.checked = false
        chkDate.checked = true
        chkOnlySelected.checked = false
        dtbStartDate.date = moment()
        dtbEndDate.date = moment()
        ivDetails = {}
    }

    RestModel {
        id: rmdLab
        url: LAB_URL
        property alias items: grdSelection.model
    }
    
    content: [

        Container {
            className: 'fluid'
            Form{
                Fields{
                    className: 'inline'
                    Field{
                        className: 'two wide'
                        RadioButton{
                            id: rdoCentralLab
                            text: 'Central Lab'
                            name: 'central'
                            group: 'labType'
                            onChanged: {
                                filterAndShow()
                            }
                        }
                    }
                    Field{
                        className: 'two wide'
                        RadioButton{
                            id: rdoMicrology
                            text: 'Micrology'
                            name: 'micrology'
                            group: 'labType'
                            onChanged: {
                                filterAndShow()
                            }
                        }
                    }
                    Field{
                        className: 'two wide'
                        RadioButton{
                            id: rdoPatho
                            text: 'Patho'
                            name: 'patho'
                            group: 'labType'
                            onChanged: {
                                filterAndShow()
                            }
                        }
                    }
                    Field{
                        className: 'two wide'
                        RadioButton{
                            id: rdoCyto
                            text: 'Cyto'
                            name: 'cyto'
                            group: 'labType'
                            onChanged: {
                                filterAndShow()
                            }
                        }
                    }
                    Field{
                        className: 'two wide'
                        RadioButton{
                            id: rdoXrays
                            text: 'X-rays'
                            name: 'xrays'
                            group: 'labType'
                            onChanged: {
                                filterAndShow()
                            }
                        }
                    }
                }
            }
            Br{}
            Form{
                Fields{
                    className: 'inline'
                    Field{
                        CheckBox{
                            id: chkDate
                            text: 'From'
                            doc_label: 'Date'
                            checked: true
                            onChanged: {
                                dtbStartDate.enabled = checked
                                dtbEndDate.enabled = checked
                                filterAndShow()
                            }
                        }
                    }
                    Field{
                        DateTextBox{
                            id: dtbStartDate
                            doc_label: 'From Date'
                            onChanged: {
                                filterAndShow()
                            }
                        }
                    }
                    Field{
                        label: 'To'
                        DateTextBox{
                            id: dtbEndDate
                            doc_label: 'To Date'
                            onChanged: {
                                filterAndShow()
                            }
                        }
                    }
                    Field{
                        CheckBox {
                            id: chkOnlySelected
                            text: 'แสดงเฉพาะที่เลือก'
                            onChanged: {
                                rdoCentralLab.enabled = !checked
                                rdoMicrology.enabled = !checked
                                rdoPatho.enabled = !checked
                                rdoCyto.enabled = !checked
                                rdoXrays.enabled = !checked
                                chkDate.enabled = !checked
                                dtbStartDate.enabled = !checked
                                dtbEndDate.enabled = !checked
                                filterAndShow()
                            }
                        }
                    }
                }
            }
            Br{}
            Grid {
                Column {
                    className: 'six wide'
                    DGrid {
                        id: grdSelection
                        showHeader: false
                        height: 450
                        columns: [
                            DCheckBoxColumn {
                                field: 'checked'
                                label: ''
                                width: 5
                                onChanged: {
                                    grdSelection.selectedRow.checked = checked
                                }
                            }, 
                            DColumn {
                                field: 'created'
                                label: 'วันที่'
                                width: 15
                            },
                            DColumn {
                                field: 'division'
                                label: 'แผนก'
                                width: 10
                            },
                            DColumn {
                                field: 'doctor'
                                label: 'แพทย์'
                                width: 30
                            },
                        ]

                        model: [
                            { menu_icon: 'list layout'}
                        ]

                        Component.onCompleted: {
                            $('.ui-icon.dgrid-hider-toggle').remove()
                        }

                        onSelected: {
                            var id = grdSelection.selectedRow.id
                            var type = grdSelection.selectedRow.type
                            subIvDetail.fetchLab(id, type).then(function(xhr) {
                                ivDetails[id + ':' + type] = JSON.parse(xhr.response)
                            })
                        }
                    }
                }
                Column {
                    className: 'ten wide'
                    SubIVDetail{
                        id: subIvDetail
                    }
                }
            }

            Br{}

            Button {
                id: btnChoose
                backgroundColor: 'green'
                text: 'เลือก'
                onClicked: {
                    var selectedItems = grdSelection.model.filter(function(item) {
                        return item.checked
                    })
                    selectedItems = selectedItems.map(function(item) {
                        return ivDetails[item.id + ':' + item.type]
                    })
                    selected(selectedItems)
                }
            }
        }
    ]
}
        `)
}, 0)

    }

    componentWillUnmount() {
        this._ismounted = false
        //console.log(" ***** componentWillUnmount ", this.cardName)
        document.onkeypress = null;
        document.onkeyup = null;

        // this.engine.stop()
        if(this.qml){
            this.qml.destroy()
            this.removeChildProperties(this.qml)
        }
        
        // this.qml = null
        // this.engine = null
        if(this.props.onUnmount){
            this.props.onUnmount()
         }
        // window.QmlWeb.engine.dom = null
        // window.QmlWeb.engine.domTarget = null
        // window.QmlWeb.engine.rootObject = null
        // window.QmlWeb.engine.completedSignals = []
        // window.QmlWeb.engine = {}
        //console.log(" ***** componentWillUnmount Finish ", this.cardName)
    }

    loadQML = (src, parentComponent = null, file = undefined) => {
        this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        
        // let component = this.loadQMLTree(window.QmlWeb.parseQML(src, file), parentComponent, file);
        // this.qml = this.engine.rootObject
        // return component
    }

    loadQMLTree = (tree, parentComponent = null, file = undefined) => {

        // Part 1
        let QMLComponent; 
        let component;

        setTimeout( () => { 

            if (!this._ismounted){
                //console.log(" Shutdown Part 1", this.cardName)
                return;
            }
            this.engine = window.QmlWeb.engine;
        
            if (!this.engine) {
                this.engine = new window.QmlWeb.QMLEngine(ReactDOM.findDOMNode(this));
                // window.addEventListener("resize", () => this.engine.updateGeometry());
            } else {
                this.engine.cleanEngine(ReactDOM.findDOMNode(this))
            }

            this.engine.$basePathA = document.createElement('a')
            this.engine.$basePathA.href = this.extractBasePath(`/static/qml/DPI/SubIVSelection.qml`)
            this.engine.$basePath = this.engine.$basePathA.href
            //console.log(" CDM this.engine.$basePathA.href: ", this.engine.$basePathA.href)

            window.QmlWeb.engine = this.engine;

            // Create and initialize objects
            QMLComponent = window.QmlWeb.getConstructor("QtQml", "2.0", "Component");
            component = new QMLComponent({
                object: tree,
                parent: parentComponent
            });
            //console.log("Part 1", this.cardName)
        },0)

        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 2", this.cardName)
                return;
            }
            
            this.engine.loadImports(tree.$imports, undefined, component.importContextId);
            component.$basePath = this.engine.$basePath;
            component.$imports = tree.$imports; // for later use
            component.$file = file; // just for debugging
            //console.log("Part 2", this.cardName)
        }, 0);
    

        // Part 3,4,5
        setTimeout(() => {
            if (!this._ismounted){
                //console.log(" Shutdown Part 3", this.cardName)
                return;
            }
            this.engine.rootObject = component.$createObject(parentComponent);
            
            if (this.engine.rootObject.dom) {
                this.engine.domTarget.appendChild(this.engine.rootObject.dom);
            }
     
            this.qml = this.engine.rootObject
            this.setUpSignals()
            this.setUpProperties()
            //console.log("3. setUpSignals(), setUpProperties() Done", this.cardName)
 
            this.engine.$initializePropertyBindings();
            this.engine.start();

            this.engine.updateGeometry();
            this.qml = this.engine.rootObject

            this.setState({qml: this.qml})
            //console.log("4. finish loadQMLTree", this.cardName)

            
            this.engine.firstCallCompleted = false;
            this.engine.callCompletedSignals();
            this.engine.firstCallCompleted = true;
            //console.log("5. CallCompletedSignal", this.cardName)

            if (this.props.completedQMLLoad) {
                //console.log("Callback completedQMLLoad !!")
                setTimeout( () => {
                    this.props.completedQMLLoad()
                })
            }
        }, 0);
    }
    
    deCapitalizeFirstLetter(string) {
        return string.charAt(0).toLowerCase() + string.slice(1);
    }

    hasSignalName(signalName) {
        return (
            typeof this.qml[signalName] === 'function' 
            && typeof this.qml[signalName].connect === 'function'
        )
    }

    setUpSignals() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let startsWithOn = key.startsWith('on')
            let typeFunction = typeof value === 'function'
            if (!startsWithOn || !typeFunction) {
                return
            }
            if (!this.hasSignalName(signalName)) {
                console.warn('Cannot find a signal name: ' + signalName)
                return
            }
            this.qml[signalName].disconnect()
            this.qml[signalName].connect(this.qml, value)
        })
    }

    setUpProperties() {
        _.forOwn(this.props, (value, key) => {
            let signalName = this.deCapitalizeFirstLetter(key.replace('on', ''))
            let propertyExists = typeof this.qml.$properties[key] !== 'undefined'
            if (this.hasSignalName(signalName)) {
                return
            }
            if (!propertyExists) {
                const createProperty = window.QmlWeb.createProperty;
                createProperty("variant", this.qml, key, value);
                //console.warn('Cannot find a property name: ' + key)
                // return
            }
            this.qml[key] = value
        })
    }

    extractBasePath(file) {
        const basePath = file.split(/[/\\\\]/)
        basePath[basePath.length - 1] = ''
        return basePath.join('/')
    }

    extractFileName(file) {
        return file.split(/[/\\\\]/).pop()
    }

    removeChildProperties(child) {
        const signals = this.engine.completedSignals
        if (signals) {
            signals.splice(signals.indexOf(child.Component.completed), 1)
        }
        if(child.children) {
            for (let i = 0; i < child.children.length; i++) {
                this.removeChildProperties(child.children[i])
            }
        }
        child.$signals = null
    }

    render() {
        if (this.state.qml) {
            this.setUpSignals()
            this.setUpProperties()
        }
        return React.createElement('div')
    }
}