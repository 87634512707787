import React from "react";
import { IntlProvider, FormattedMessage, useIntl } from "react-intl";

import { Button, Input, Dropdown } from "semantic-ui-react";

const ANES_ChargeItem_SetupTemplate_Supply = (props: any) => {
  const isMounted = React.useRef(true);
  const intl = useIntl();

  React.useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <div style={{ height: "300px", width: "100%", display: "flex", flexDirection: "column" }}>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "grid",
          position: "relative",
          gridTemplateRows: "repeat(19, 1fr)",
          gridTemplateColumns: "repeat(50, 1fr)",
        }}
      >
        <div
          style={{
            gridRow: "2/5",
            gridColumn: "1/51",
            border: "solid 0px rgb(200, 200, 200, 0.5)",
          }}
        >
          <div style={{ width: "100%", flexGrow: 1 }}>
            <label style={{ fontSize: "22px", fontWeight: "bold", paddingLeft: "1rem" }}>
              {props.languageUX === "en"
                ? "Edit template : "
                : "ชุดรายการค่าใช้จ่ายที่กำลังแก้ไข : "}
            </label>
            <label style={{ fontSize: "16px", fontWeight: "normal" }}>{props.template?.name}</label>
          </div>
        </div>
        <div
          style={{ gridRow: "6/8", gridColumn: "2/8", border: "solid 0px rgb(200, 200, 200, 0.5)" }}
        >
          <div style={{ width: "100%", flexGrow: 9, flexShrink: 4 }}>
            <label style={{ fontWeight: "bold" }}>รายการยา ที่เลือก :</label>
          </div>
        </div>
        <div style={{ gridRow: "6/7", gridColumn: "8/31" }}>
          <div>{props.drugSearchBox}</div>
        </div>
        <div style={{ gridRow: "6/8", gridColumn: "31/38" }}>
          <Button
            color="red"
            onClick={props.onClearDrugSearch}
            style={{
              width: "100%",
              flexShrink: 9,
              display: props.data?.drug?.full_name ? "" : "none",
            }}
          >
            ยกเลิกการเลือก
          </Button>
        </div>
        <div
          style={{
            gridRow: "6/8",
            gridColumn: "39/41",
            border: "solid 0px rgb(200, 200, 200, 0.5)",
          }}
        >
          <label style={{ fontWeight: "bold" }}>PRN</label>
        </div>
        <div
          style={{
            gridRow: "6/9",
            gridColumn: "41/50",
            border: "solid 0px rgb(200, 200, 200, 0.5)",
          }}
        >
          <Input
            name="prn"
            onChange={props.onChangeDrugANS}
            style={{ height: "100%", width: "100%" }}
            value={props.data?.prn || ""}
          ></Input>
        </div>
        <div
          style={{
            gridRow: "10/14",
            gridColumn: "2/10",
            border: "solid 0px rgb(200, 200, 200, 0.5)",
          }}
        >
          <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
            <label style={{ fontWeight: "bold" }}>Dose</label>
            <Input
              fluid={true}
              name="dose"
              onChange={props.onChangeDrugANS}
              style={{ width: "100%" }}
              value={props.data?.dose || ""}
            ></Input>
          </div>
        </div>
        <div
          style={{
            gridRow: "10/14",
            gridColumn: "10/18",
            border: "solid 0px rgb(200, 200, 200, 0.5)",
          }}
        >
          <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
            <label style={{ fontWeight: "bold" }}>Unit</label>
            <Dropdown
              fluid={true}
              name="unit"
              onChange={props.onChangeDrugANS}
              options={props.masterOptions?.unit || []}
              search={true}
              selection={true}
              value={props.data?.unit || ""}
            ></Dropdown>
          </div>
        </div>
        <div
          style={{
            gridRow: "10/14",
            gridColumn: "18/26",
            border: "solid 0px rgb(200, 200, 200, 0.5)",
          }}
        >
          <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
            <label style={{ fontWeight: "bold" }}>Route</label>
            <Dropdown
              fluid={true}
              name="route"
              onChange={props.onChangeDrugANS}
              options={props.masterOptions?.route || []}
              search={true}
              selection={true}
              value={props.data?.route || ""}
            ></Dropdown>
          </div>
        </div>
        <div
          style={{
            gridRow: "10/14",
            gridColumn: "26/34",
            border: "solid 0px rgb(200, 200, 200, 0.5)",
          }}
        >
          <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
            <label style={{ fontWeight: "bold" }}>Site</label>
            <Dropdown
              fluid={true}
              name="site"
              onChange={props.onChangeDrugANS}
              options={props.masterOptions?.site || []}
              search={true}
              selection={true}
              value={props.data?.site || ""}
            ></Dropdown>
          </div>
        </div>
        <div
          style={{
            gridRow: "10/14",
            gridColumn: "34/42",
            border: "solid 0px rgb(200, 200, 200, 0.5)",
          }}
        >
          <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
            <label style={{ fontWeight: "bold" }}>Frequency</label>
            <Dropdown
              fluid={true}
              name="frequency"
              onChange={props.onChangeDrugANS}
              options={props.masterOptions?.frequency || []}
              search={true}
              selection={true}
              value={props.data?.frequency || ""}
            ></Dropdown>
          </div>
        </div>
        <div
          style={{
            gridRow: "10/14",
            gridColumn: "42/50",
            border: "solid 0px rgb(200, 200, 200, 0.5)",
          }}
        >
          <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
            <label style={{ fontWeight: "bold" }}>Method</label>
            <Dropdown
              fluid={true}
              name="method"
              onChange={props.onChangeDrugANS}
              options={props.masterOptions?.method || []}
              search={true}
              selection={true}
              value={props.data?.method || ""}
            ></Dropdown>
          </div>
        </div>
        <div
          style={{
            gridRow: "16/18",
            gridColumn: "2/5",
            border: "solid 0px rgb(200, 200, 200, 0.5)",
          }}
        >
          <label style={{ fontWeight: "bold" }}>Remark</label>
        </div>
        <div
          style={{
            gridRow: "16/19",
            gridColumn: "5/21",
            border: "solid 0px rgb(200, 200, 200, 0.5)",
          }}
        >
          <Input
            name="remark"
            onChange={props.onChangeDrugANS}
            style={{ height: "100%", width: "100%" }}
            value={props.data?.remark || ""}
          ></Input>
        </div>
        <div
          style={{
            gridRow: "16/18",
            gridColumn: "38/42",
            border: "solid 1px rgb(200, 200, 200, 0.5)",
          }}
        >
          <div>{props.buttonCreateDrug}</div>
        </div>
        <div
          style={{
            gridRow: "16/18",
            gridColumn: "43/50",
            border: "solid 1px rgb(200, 200, 200, 0.5)",
          }}
        >
          <Button color="red" onClick={props.onCancelEdit} style={{ width: "100%", flexShrink: 9 }}>
            ยกเลิกการแก้ไข
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ANES_ChargeItem_SetupTemplate_Supply;

export const screenPropsDefault = {};

/* Date Time : Mon Aug 26 2024 12:44:21 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height:\"300px\",width:\"100%\",display:\"flex\",flexDirection: \"column\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 34,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\", flexGrow:1}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "label",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "ชุดรายการค่าใช้จ่ายที่กำลังแก้ไข : ",
          "valueEN": "Edit template : "
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"22px\", fontWeight:\"bold\", paddingLeft: \"1rem\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "Button",
      "parent": 35,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิกการเลือก"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClearDrugSearch"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\", flexShrink: 9, display: props.data?.drug?.full_name ? \"\" : \"none\" }"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 36,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\",flexGrow:9, flexShrink:4}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "label",
      "parent": 4,
      "props": {
        "children": {
          "type": "code",
          "value": "props.template?.name"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"16px\", fontWeight:\"normal\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "label",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการยา ที่เลือก : "
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bold\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "label",
      "parent": 37,
      "props": {
        "children": {
          "type": "value",
          "value": "PRN"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bold\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "Input",
      "parent": 38,
      "props": {
        "name": {
          "type": "value",
          "value": "prn"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeDrugANS"
        },
        "style": {
          "type": "code",
          "value": "{height:\"100%\",width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.data?.prn || \"\""
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "label": "divgrid",
      "name": "div",
      "parent": 0,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(19, 1fr)\",\"gridTemplateColumns\":\"repeat(50, 1fr)\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 33,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"2/5\",\"gridColumn\":\"1/51\",\"border\":\"solid 0px rgb(200, 200, 200, 0.5)\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "div",
      "parent": 33,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"6/8\",\"gridColumn\":\"31/38\"}"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 33,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"6/8\",\"gridColumn\":\"2/8\",\"border\":\"solid 0px rgb(200, 200, 200, 0.5)\"}"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 33,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"6/8\",\"gridColumn\":\"39/41\",\"border\":\"solid 0px rgb(200, 200, 200, 0.5)\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "div",
      "parent": 33,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"6/9\",\"gridColumn\":\"41/50\",\"border\":\"solid 0px rgb(200, 200, 200, 0.5)\"}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 33,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"10/14\",\"gridColumn\":\"2/10\",\"border\":\"solid 0px rgb(200, 200, 200, 0.5)\"}"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 33,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"10/14\",\"gridColumn\":\"10/18\",\"border\":\"solid 0px rgb(200, 200, 200, 0.5)\"}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 33,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"10/14\",\"gridColumn\":\"18/26\",\"border\":\"solid 0px rgb(200, 200, 200, 0.5)\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 33,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"10/14\",\"gridColumn\":\"26/34\",\"border\":\"solid 0px rgb(200, 200, 200, 0.5)\"}"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 33,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"10/14\",\"gridColumn\":\"34/42\",\"border\":\"solid 0px rgb(200, 200, 200, 0.5)\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 33,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"10/14\",\"gridColumn\":\"42/50\",\"border\":\"solid 0px rgb(200, 200, 200, 0.5)\"}"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "div",
      "parent": 39,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\",height:\"100%\",display:\"flex\",flexDirection:\"column\"}"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "label",
      "parent": 45,
      "props": {
        "children": {
          "type": "value",
          "value": "Dose"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bold\"}"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "Input",
      "parent": 45,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "dose"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeDrugANS"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.data?.dose || \"\""
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "div",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\",height:\"100%\",display:\"flex\",flexDirection:\"column\"}"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "label",
      "parent": 48,
      "props": {
        "children": {
          "type": "value",
          "value": "Unit"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bold\"}"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\",height:\"100%\",display:\"flex\",flexDirection:\"column\"}"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "label",
      "parent": 51,
      "props": {
        "children": {
          "type": "value",
          "value": "Route"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bold\"}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "div",
      "parent": 42,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\",height:\"100%\",display:\"flex\",flexDirection:\"column\"}"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "label",
      "parent": 54,
      "props": {
        "children": {
          "type": "value",
          "value": "Site"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bold\"}"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 43,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\",height:\"100%\",display:\"flex\",flexDirection:\"column\"}"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "label",
      "parent": 57,
      "props": {
        "children": {
          "type": "value",
          "value": "Frequency"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bold\"}"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "div",
      "parent": 44,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\",height:\"100%\",display:\"flex\",flexDirection:\"column\"}"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "label",
      "parent": 60,
      "props": {
        "children": {
          "type": "value",
          "value": "Method"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bold\"}"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "div",
      "parent": 33,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"16/18\", gridColumn: \"2/5\", border: \"solid 0px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": null,
      "id": 64,
      "name": "div",
      "parent": 33,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"16/19\", gridColumn: \"5/21\", border: \"solid 0px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "label",
      "parent": 63,
      "props": {
        "children": {
          "type": "value",
          "value": "Remark"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bold\"}"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "Input",
      "parent": 64,
      "props": {
        "name": {
          "type": "value",
          "value": "remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeDrugANS"
        },
        "style": {
          "type": "code",
          "value": "{height:\"100%\",width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.data?.remark || \"\""
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "div",
      "parent": 33,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"16/18\",\"gridColumn\":\"38/42\",\"border\":\"solid 1px rgb(200, 200, 200, 0.5)\"}"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "div",
      "parent": 33,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"gridRow\":\"16/18\",\"gridColumn\":\"43/50\",\"border\":\"solid 1px rgb(200, 200, 200, 0.5)\"}"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "Button",
      "parent": 69,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิกการแก้ไข"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancelEdit"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\", flexShrink: 9 }"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "Dropdown",
      "parent": 48,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "unit"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeDrugANS"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.unit || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.data?.unit || \"\""
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "Dropdown",
      "parent": 51,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "route"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeDrugANS"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.route || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.data?.route || \"\""
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "Dropdown",
      "parent": 54,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "site"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeDrugANS"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.site || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.data?.site || \"\""
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "Dropdown",
      "parent": 57,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "frequency"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeDrugANS"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.frequency || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.data?.frequency || \"\""
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "Dropdown",
      "parent": 60,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "method"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeDrugANS"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.method || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.data?.method || \"\""
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "div",
      "parent": 33,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"8/31\" }"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "div",
      "parent": 67,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonCreateDrug"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "div",
      "parent": 78,
      "props": {
        "children": {
          "type": "code",
          "value": "props.drugSearchBox"
        }
      },
      "seq": 83,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "ANES_ChargeItem_SetupTemplate_Supply",
  "project": "IsHealth_by_Front-end",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
