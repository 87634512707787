import CONFIG from "config/config";
import JsBarcode from "jsbarcode";

// Utils
import { truncateString } from "react-lib/apps/HISV3/common/CommonInterface";
import CompanyLogoForm from "react-lib/apps/HISV3/common/CompanyLogoForm";

const FORM_NAME = "FormStickerSpecimen";

const textToBase64BarcodeBlob = (text: string) => {
  const canvas = document.createElement("canvas");
  JsBarcode(canvas, text, { displayValue: false });
  return canvas.toDataURL("image/png");
};

const FormStickerSpecimen = async (props: any) => {
  console.log("FormStickerSpecimen props: ", props);
  const companyLogoForm = await CompanyLogoForm({type: 2, font: "THSarabunNew", height: 30.3, form: FORM_NAME});
  const {font, images} = companyLogoForm;

  return {
    pageSize: {
      width: 188.976,
      height: 75.59,
    },
    pageOrientation: "landscape",
    defaultStyle: {
      font: font,
      lineHeight: 0.8,
      fontSize: 15,
    },
    pageMargins: [0, 0, 0, 0],
    styles: {
      fieldKey: {
        bold: true,
        fontSize: 15,
      },
      fieldValue: {
        fontSize: 15,
      },
      miniFieldValue: {
        fontSize: 14,
      },
    },
    content: [
      {
        stack: props.data.map((item: any) => ({
          layout: "noBorders",
          // pageBreak: "after",
          table: {
            widths: [12, "*"],
            body: [
              [
                {
                  layout: "noBorders",
                  fillColor: "#000000",
                  stack: [
                    {
                      svg: `<svg width="12" height="75.590">
                          <rect width="12" height="75.590" fill="black" />
                          <text x="-297.5%" y="9" dominant-baseline="middle" text-anchor="middle"
                            fill="white" transform="rotate(270 0,0)"
                            font-size="15" font-family="THSarabunNew" font-weight="bold">${item.code}</text>
                        </svg>`,
                    },
                  ],
                },
                {
                  layout: "noBorders",
                  margin: [2.5, 0, 2.5, 0],
                  stack: [
                    {
                      columns: [
                        { width: "40%", text: item.hn, style: "fieldKey" },
                        {
                          width: "*",
                          text: ["DOB: ", item.dob],
                          style: "fieldValue",
                          alignment: "right",
                          margin: [0, 0, 2.5, 0]
                        },
                        // {
                        //   width: "*",
                        //   text: `${item.age} Y`,
                        //   style: "fieldValue",
                        //   alignment: "right",
                        // },
                      ],
                    },
                    {
                      columns: [
                        {
                          width: "90%",
                          text: truncateString(item.full_name, 200),
                          style: "fieldKey",
                          noWrap: true
                        },
                        {
                          width: "*",
                          text: item.urgency,
                          alignment: "right",
                          style: "fieldKey",
                          margin: [0, 0, 5, 0]
                        },
                      ],
                    },
                    {
                      columns: [
                        {
                          image: textToBase64BarcodeBlob(`${item.code || ""}`),
                          width: 110,
                          height: 25.5,
                          margin: [-3, 1, 0, 0],
                        },
                        {
                          width: "*",
                          margin: [0, 11, 2.5, 0],
                          text: item.date,
                          alignment: "right",
                          style: "fieldValue",
                        },
                      ],
                    },
                    {
                      margin: [0, -3, 0, 0],
                      columns: [
                        {
                          text: item.name,
                          width: "80%",
                          style: "miniFieldValue",
                        },
                        {
                          width: "*",
                          margin: [0, 0, 2.5, 0],
                          text: item.time,
                          alignment: "right",
                          style: "fieldValue",
                        },
                      ],
                    },
                  ],
                },
              ],
            ],
          },
        })),
      },
    ],
    images: {
      ...images,
      before_anesthesia: origin + "/static/images/before_anesthesia.png",
      before_treatment: origin + "/static/images/before_treatment.jpg",
    },
  };
};

export default FormStickerSpecimen;
