import React, {
  FC,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Table, Checkbox, Input } from "semantic-ui-react";
import { type } from "os";
import { useIntl } from "react-intl";
import "./../../css/TableMotion.css"

const styles = {
  header: {
    color:"#FFFFFF",
    backgroundColor:"#2F3E52",
    fontWeight:"bold",
    borderRight:"0.5px solid #FFFFFF"
  },
  bigHeader:{
    color:"#FFFFFF",
    backgroundColor:"#2F3E52",
    fontWeight:"bold",
    borderRight:"0.5px solid #FFFFFF",
    borderBottom:"0.5px solid #FFFFFF"
  },
  inputTable: {
      width: "100%"
  },
  cellOdd: {
      fontWeight:"bold",
  },
  cellEven: {
      fontWeight:"bold",
      backgroundColor: "rgb(79,79,79,0.1)"
  },
  noBottomPadding: {
    paddingBottom: "5px",
  },
};

export interface TableFunctionalMotionViewDataType {
  tableDisable: boolean;
  viewData: { 
    shoulder: shoulderDataType;
    elbow: elbowDataType;
    forearm: forearmDataType;
    wrist: wristDataType;
    massFinger: massFingerDataType;
  }
}

interface cellDataType {
  oneOfFour: boolean,
  twoOfFour: boolean,
  threeOfFour: boolean,
  full: boolean,
  noMovement: boolean,
  noted: string
}

interface shoulderDataType { 
  flexion: cellDataType;
  extension: cellDataType;
  adduction: cellDataType;
  abduction: cellDataType;
  internalRotation: cellDataType;
  externalRotation: cellDataType;
  horizontalAdduction: cellDataType;
  horizontalAbduction: cellDataType;
}

interface elbowDataType {
  flexion: cellDataType;
  extension: cellDataType;
}

interface forearmDataType {
  pronation: cellDataType;
  supination: cellDataType;
}

interface wristDataType {
  flexion: cellDataType;
  extension: cellDataType;
}

interface massFingerDataType {
  flexion: cellDataType;
  extension: cellDataType;
}

const TableInitialData: TableFunctionalMotionViewDataType = {
  tableDisable: false, 
  viewData: {
    shoulder: {
      flexion : {
        oneOfFour: false,
        twoOfFour: false,
        threeOfFour: false,
        full: false,
        noMovement: false,
        noted: ""
      },
      extension : {
        oneOfFour: false,
        twoOfFour: false,
        threeOfFour: false,
        full: false,
        noMovement: false,
        noted: ""
      },
      adduction : {
        oneOfFour: false,
        twoOfFour: false,
        threeOfFour: false,
        full: false,
        noMovement: false,
        noted: ""
      },
      abduction: {
        oneOfFour: false,
        twoOfFour: false,
        threeOfFour: false,
        full: false,
        noMovement: false,
        noted: ""
      },
      internalRotation: {
        oneOfFour: false,
        twoOfFour: false,
        threeOfFour: false,
        full: false,
        noMovement: false,
        noted: ""
      },
      externalRotation : {
        oneOfFour: false,
        twoOfFour: false,
        threeOfFour: false,
        full: false,
        noMovement: false,
        noted: ""
      },
      horizontalAdduction : {
        oneOfFour: false,
        twoOfFour: false,
        threeOfFour: false,
        full: false,
        noMovement: false,
        noted: ""
      },
      horizontalAbduction : {
        oneOfFour: false,
        twoOfFour: false,
        threeOfFour: false,
        full: false,
        noMovement: false,
        noted: ""
      }
    },
    elbow: {
      flexion : {
        oneOfFour: false,
        twoOfFour: false,
        threeOfFour: false,
        full: false,
        noMovement: false,
        noted: ""
      },
      extension: {
        oneOfFour: false,
        twoOfFour: false,
        threeOfFour: false,
        full: false,
        noMovement: false,
        noted: ""
      }
    },
    forearm : {
      pronation : {
        oneOfFour: false,
        twoOfFour: false,
        threeOfFour: false,
        full: false,
        noMovement: false,
        noted: ""
      },
      supination: {
        oneOfFour: false,
        twoOfFour: false,
        threeOfFour: false,
        full: false,
        noMovement: false,
        noted: ""
      }
    }, 
    wrist : {
      flexion : {
        oneOfFour: false,
        twoOfFour: false,
        threeOfFour: false,
        full: false,
        noMovement: false,
        noted: ""
      },
      extension: {
        oneOfFour: false,
        twoOfFour: false,
        threeOfFour: false,
        full: false,
        noMovement: false,
        noted: ""
      }
    },
    massFinger : {
      flexion : {
        oneOfFour: false,
        twoOfFour: false,
        threeOfFour: false,
        full: false,
        noMovement: false,
        noted: ""
      },
      extension: {
        oneOfFour: false,
        twoOfFour: false,
        threeOfFour: false,
        full: false,
        noMovement: false,
        noted: ""
      }
    }
  }
}

interface CustomRowProps {
  rowStyle: {
    fontWeight: string,
    backgroundColor?: string,
  }
  title: string
  rowHeader?: string
  rowSpan?: string
  mainName: string
  name: string
}

const TableFunctionalMotion: FC<TableFunctionalMotionViewDataType> = forwardRef((props, ref)  => {

  const [data, setData] = useState(Object.create(TableInitialData).viewData)
  const [tableDisable, setTableDisable] = useState(Object.create(TableInitialData).tableDisable)
  useEffect(()=>{
    if(props.viewData){
      setData(props.viewData)
      setTableDisable(props.tableDisable)
    }
  })

  useImperativeHandle(ref, () => ({
    getData: () => {
      return data;
    },
  }));

  const handleCheckBoxChange = (mainRow, name, subName, checkValue) => {
    let targetData = Object.create(data)
    targetData[mainRow][name][subName] = !checkValue
    // console.log('handleCheckBoxChange targetData',targetData)
    setData(targetData)
  } 

  const handleTextBoxChange = (mainRow, name, subName, event) => {
    let targetData = Object.assign({}, data)
    targetData[mainRow][name][subName] = event.target.value
    setData(targetData)
  } 
  
  const CustomRow: FC<CustomRowProps> = (props) => {
    let inputData = data[props.mainName][props.name]

    return <Table.Row>
        {props.rowHeader?<Table.Cell rowSpan={props.rowSpan} style={props.rowStyle} textAlign="center">{props.rowHeader}</Table.Cell>:<></>}
        <Table.Cell style={props.rowStyle}>{props.title}</Table.Cell>
        {Object.keys(inputData).map(item => {
          if(typeof(inputData[item]) === "boolean"){
            return <Table.Cell key={item} textAlign="center">
              <Checkbox disabled={tableDisable} checked={inputData[item]} onChange={()=>{handleCheckBoxChange(props.mainName, props.name, item, inputData[item])}}/>
            </Table.Cell>
          }else{
            return <Table.Cell key={item}>
              <Input 
                style={styles.inputTable}
                disabled={tableDisable} 
                defaultValue={inputData[item]} 
                onBlur={(e)=>{handleTextBoxChange(props.mainName, props.name, item, e)}}/>
            </Table.Cell>
          }
        })}
      </Table.Row>  
  }

  // console.log('TableFunctionMotion', data)

  return( <div> 
    <Table celled structured>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell style={styles.header} textAlign="center" rowSpan="2" colSpan="2" width="4">Action</Table.HeaderCell>
          <Table.HeaderCell style={{...styles.header, borderBottom:"0.5px solid #FFFFFF"}} colSpan="4" textAlign="center" width="4">Unassisred</Table.HeaderCell>
          <Table.HeaderCell style={styles.header} textAlign="center" width="1" rowSpan="2">No Movement</Table.HeaderCell>
          <Table.HeaderCell style={styles.header} textAlign="center" width="5" rowSpan="2">Note</Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell style={styles.header} textAlign="center" width="1">1/4</Table.HeaderCell>
          <Table.HeaderCell style={styles.header} textAlign="center" width="1">2/4</Table.HeaderCell>
          <Table.HeaderCell style={styles.header} textAlign="center" width="1">3/4</Table.HeaderCell>
          <Table.HeaderCell style={styles.header} textAlign="center" width="1">Full</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body className="table-motion" style={{backgroundColor:tableDisable? "rgba(0, 0, 0, 0.09)" : "#FFFFFF"}}>
          <CustomRow
            rowHeader={"Shoulder"}
            rowSpan={"8"}
            mainName={"shoulder"} 
            title={"flexion"}
            name={"flexion"}
            rowStyle={styles.cellOdd}
          />
          <CustomRow
            mainName={"shoulder"} 
            title={"extension"}
            name={"extension"}
            rowStyle={styles.cellOdd}
          />
          <CustomRow
            mainName={"shoulder"} 
            title={"adduction"}
            name={"adduction"}
            rowStyle={styles.cellOdd}
          />
          <CustomRow
            mainName={"shoulder"} 
            title={"Abduction"}
            name={"abduction"}
            rowStyle={styles.cellOdd}
          />
          <CustomRow
            mainName={"shoulder"} 
            title={"Internal rotation"}
            name={"internalRotation"}
            rowStyle={styles.cellOdd}
          />
          <CustomRow
            mainName={"shoulder"} 
            title={"External rotation"}
            name={"externalRotation"}
            rowStyle={styles.cellOdd}
          />
          <CustomRow
            mainName={"shoulder"} 
            title={"horizontal adduction"}
            name={"horizontalAdduction"}
            rowStyle={styles.cellOdd}
          />
          <CustomRow
            mainName={"shoulder"} 
            title={"horizontal abduction"}
            name={"horizontalAbduction"}
            rowStyle={styles.cellOdd}
          />

          <CustomRow
            rowHeader={"Elbow"}
            rowSpan={"2"}
            mainName={"elbow"} 
            title={"flexion"}
            name={"flexion"}
            rowStyle={styles.cellEven}
          />
          <CustomRow
            mainName={"elbow"} 
            title={"extension"}
            name={"extension"}
            rowStyle={styles.cellEven}
          />

          <CustomRow
            rowHeader={"Foearm"}
            rowSpan={"2"}
            mainName={"forearm"} 
            title={"pronation"}
            name={"pronation"}
            rowStyle={styles.cellOdd}
          />
          <CustomRow
            mainName={"forearm"} 
            title={"supination"}
            name={"supination"}
            rowStyle={styles.cellOdd}
          />

          <CustomRow
            rowHeader={"Wrist"}
            rowSpan={"2"}
            mainName={"wrist"} 
            title={"flexion"}
            name={"flexion"}
            rowStyle={styles.cellEven}
          />
          <CustomRow
            mainName={"wrist"} 
            title={"extension"}
            name={"extension"}
            rowStyle={styles.cellEven}
          />

          <CustomRow
            rowHeader={"Mass finger"}
            rowSpan={"2"}
            mainName={"massFinger"} 
            title={"flexion"}
            name={"flexion"}
            rowStyle={styles.cellOdd}
          />
          <CustomRow
            mainName={"massFinger"} 
            title={"extension"}
            name={"extension"}
            rowStyle={styles.cellOdd}
          />
      </Table.Body>
    </Table>

  </div>)
})

export const TableFunctionalMotionInitialViewData: TableFunctionalMotionViewDataType = {
  tableDisable: false,
  viewData: Object.assign({}, TableInitialData).viewData,
};

TableFunctionalMotion.defaultProps = TableFunctionalMotionInitialViewData;

export default TableFunctionalMotion
