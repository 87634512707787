import WasmController from 'react-lib/frameworks/WasmController';
import LatestChatChannelView from "issara-sdk/apis/LatestChatChannelView_apps_MSG";
import MSGManager from "react-lib/apis/manager/MSGManager";
import MessageReadCountView from "issara-sdk/apis/MessageReadCountView_apps_MSG";
import MobileDeviceUpdateAPI from "issara-sdk/apis/MobileDeviceUpdateAPI_users";

// Read, Write
import MessageListView from "issara-sdk/apis/MessageListView_apps_PRX"
import MessageListViewMSG from "issara-sdk/apis/MessageListView_apps_MSG"

import MessagePostNoSubscriptionView from "issara-sdk/apis/MessagePostNoSubscriptionView_apps_PRXM";
import MessagePostView from "issara-sdk/apis/MessagePostView_apps_MSGM"

export type State = 
  {
  }

export const StateInitial: State = 
  {
  }

export type Event = 
  { message: "getLatestChatChannel", params: any }
  |  { message: "getChatChannelMessageList", params: any }
  | { message: "getChatChannelMessageListMSG", params: any }
  |  { message: "getMessageFromURL", params: any }
  |  { message: "postChatChannelMessage", params: any }
  | { message: "postChatChannelMessageMSG", params: any }
  |  { message: "postReadMessage", params: any }
  |  { message: "postUpdateSubscription", params: any }

export type Data = 
  {
    division?: number,
  }

export const DataInitial = 
  {
  }

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any 

export const getLatestChatChannel: Handler = async (controller, { apiToken, userId }) => {
  let params: any = {};
  if (userId) {
    params.user_id = userId;
  }
  return await LatestChatChannelView.get({
    params: params,
    apiToken: controller.apiToken
  });
}

export const getChatChannelMessageList: Handler = async (controller, {
  apiToken,
  chatChannelId,
  last,
  cancelToken,
  offset
}) => {
  let params: any = {};
  if (last) {
    params.last = last;
  }
  params.offset = offset;
  return await MessageListView.list({
    chat_channel_id: chatChannelId,
    params: params,
    apiToken: controller.apiToken
  });
}

export const getChatChannelMessageListMSG: Handler = async (controller, {
  apiToken,
  chatChannelId,
  last,
  cancelToken,
  offset
}) => {
  let params: any = {};
  if (last) {
    params.last = last;
  }
  params.offset = offset;
  return await MessageListViewMSG.list({
    chat_channel_id: chatChannelId,
    params: params,
    apiToken: controller.apiToken
  });
}


// export const getChatChannelMessageList: Handler = async (controller, {}) => {
// //function (_a) {
// //            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, chatChannelId = _b.chatChannelId, last = _b.last, cancelToken = _b.cancelToken;
// //            return __awaiter(_this, void 0, void 0, function () {
// //                var params, msgService, _c, error, response, network;
// //                return __generator(this, function (_d) {
// //                    switch (_d.label) {
// //                        case 0:
// //                            params = {};
// //                            if (last) {
// //                                params.last = last;
// //                            }
// //                            msgService = new MSGService_1.default();
// //                            return [4 /*yield*/, utils_1.to(msgService.getChatChannelMessageList({ params: params, apiToken: apiToken, chatChannelId: chatChannelId, cancelToken: cancelToken }))];
// //                        case 1:
// //                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
// //                            return [2 /*return*/, [response, error, network]];
// //                    }
// //                });
// //            });
// //        }
// //function (_a) {
// //        var _b = _a === void 0 ? {} : _a, params = _b.params, apiToken = _b.apiToken, chatChannelId = _b.chatChannelId, cancelToken = _b.cancelToken;
// //        if (apiToken) {
// //            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
// //        }
// //        var url = apis_1.MSG.CHAT_CHANNEL_MESSAGE_LIST({ chatChannelId: chatChannelId });
// //        return this.client
// //            .get(url, { params: params, cancelToken: cancelToken })
// //            .then(this.handleResponse)
// //            .catch(this.throwErrorMessage);
// //    }
// ////function(obj) {
// //obj || (obj = {});
// //var __t, __p = '';
// //with (obj) {
// //__p += 'undefined/apis/MSG/chat-channel/' +
// //((__t = (chatChannelId)) == null ? '' : __t) +
// //'/message/list/';
// //
// //}
// //return __p
// //}
// }

export const getMessageFromURL: Handler = async (controller, { apiToken, url }) => {
  // Need to use the old method
  const msgManager = new MSGManager();
  return msgManager.getMessageFromURL({apiToken, url});
}

// export const postChatChannelMessage: Handler = async (controller, {}) => {
// //function (_a) {
// //            var _b = _a === void 0 ? {} : _a, apiToken = _b.apiToken, content = _b.content, contentType = _b.contentType, chatChannelId = _b.chatChannelId, contentFile = _b.contentFile, divisionId = _b.divisionId;
// //            return __awaiter(_this, void 0, void 0, function () {
// //                var params, data, msgService, _c, error, response, network;
// //                return __generator(this, function (_d) {
// //                    switch (_d.label) {
// //                        case 0:
// //                            params = {};
// //                            data = {};
// //                            if (content) {
// //                                data.content = content;
// //                            }
// //                            if (contentType) {
// //                                data.content_type = contentType;
// //                            }
// //                            if (contentFile) {
// //                                data.content_file = contentFile;
// //                            }
// //                            msgService = new MSGService_1.default();
// //                            return [4 /*yield*/, utils_1.to(msgService.postChatChannelMessage({ params: params, apiToken: apiToken, data: data, chatChannelId: chatChannelId, divisionId: divisionId }))];
// //                        case 1:
// //                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
// //                            return [2 /*return*/, [response, error, network]];
// //                    }
// //                });
// //            });
// //        }
// //function (_a) {
// //        var _b = _a === void 0 ? {} : _a, params = _b.params, data = _b.data, apiToken = _b.apiToken, chatChannelId = _b.chatChannelId, divisionId = _b.divisionId;
// //        if (apiToken) {
// //            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
// //            this.client.defaults.headers["division"] = divisionId;
// //        }
// //        var url = apis_1.MSG.CHAT_CHANNEL_MESSAGE_POST({ chatChannelId: chatChannelId });
// //        return this.client
// //            .post(url, data, { params: params })
// //            .then(this.handleResponse)
// //            .catch(this.throwErrorMessage);
// //    }
// ////function(obj) {
// //obj || (obj = {});
// //var __t, __p = '';
// //with (obj) {
// //__p += 'undefined/apis/MSG/chat-channel/' +
// //((__t = (chatChannelId)) == null ? '' : __t) +
// //'/message/post/';
// //
// //}
// //return __p
// //}
// }


export const postChatChannelMessage: Handler = async (controller,{
  apiToken,
  content,
  contentType,
  chatChannelId,
  contentFile,
  divisionId
}) => {
  let params: any = {};
  let data: any = {};
  if (content && contentType !== "image" && contentType !== "file") {
    data.content = content;
  }
  if (contentType) {
    data.content_type = contentType;
  }
  if (contentFile) {
    data.content_file = contentFile;
  }
  // var fd = new FormData();
  // for (var key in data) {
  //     fd.append(key, data[key]);
  // }

  let prepare = {
    chat_channel_id: chatChannelId,
    params: params,
    data: data,
    extra: { division: divisionId },
    apiToken: apiToken,
  }
  console.log('prepare: ', prepare);
  return await MessagePostNoSubscriptionView.post(prepare);
}

export const postChatChannelMessageMSG: Handler = async (controller, {
  apiToken,
  content,
  contentType,
  chatChannelId,
  contentFile,
  divisionId
}) => {
  let params: any = {};
  let data: any = {};
  if (content && contentType !== "image" && contentType !== "file") {
    data.content = content;
  }
  if (contentType) {
    data.content_type = contentType;
  }
  if (contentFile) {
    data.content_file = contentFile;
  }
  // var fd = new FormData();
  // for (var key in data) {
  //     fd.append(key, data[key]);
  // }

  let prepare = {
    chat_channel_id: chatChannelId,
    params: params,
    data: data,
    extra: { division: divisionId },
    apiToken: apiToken,
  }
  console.log('prepare: ', prepare);
  return await MessagePostView.post(prepare);
}

export const postReadMessage: Handler = async (controller, { apiToken, chatChannelId }) => {
  let params: any = {};
  let data: any = {};
  return await MessageReadCountView.post({
    chat_channel_id: chatChannelId,
    params: params,
    data: data,
    apiToken: controller.apiToken
  });
}

export const postUpdateSubscription: Handler = async (controller, { apiToken, device_id, subscription }) => {
  let params: any = {};
  let data: any = {};
  if (subscription && device_id) {
    data.device_token = JSON.stringify(subscription);
    data.unique_id = device_id;
    data.device_id = device_id;
    data.device_name = device_id;
    data.device_type = 4;
    data.application = "ishealth";
  }
  return await MobileDeviceUpdateAPI.post({
    params: params,
    data: data,
    apiToken: controller.apiToken
  });
}
