import WasmController from "react-lib/frameworks/WasmController";
import moment from "moment";

// APIs
import OperatingLocationList from "issara-sdk/apis/OperatingLocationList_apps_ORM";
import OperatingRoomList from "issara-sdk/apis/OperatingRoomList_apps_ORM";
import DivisionServiceBlockOperatingAndRoom from "issara-sdk/apis/DivisionServiceBlockOperatingAndRoom_apps_QUE";
import CreateUpdateClosedRoom from "issara-sdk/apis/CreateUpdateClosedRoom_apps_QUE";

// Utils
import { beToAd, formatDate } from "../../../../utils/dateUtils";

export type State = {
  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;
  loadingStatus?: any;
  masterOptions?: any;

  // sequence
  ManageORSequence?: Partial<{
    sequenceIndex: string | null;
    locationRoomList: any[];
    selectedRoom: any;
    selectedSlot: any;
    blockList: any;
    settingTimeOR: Partial<{
      start_date: string;
      end_date: string;
      start_time: string;
      end_time: string;
      select_day: number[];
      everyday: boolean;
      all_hour: boolean;
      repeat: boolean;
    }>;
  }> | null;
};

export const StateInitial: State = {
  // sequence
  ManageORSequence: {
    sequenceIndex: null,
  },
};

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division?: number;
  device?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

// export const CARD_OR_QUEUE_ID: string = "CardORQueue";

export const GetMaster: Handler = async (controller, params) => {
  const [location, room] = await Promise.all([
    OperatingLocationList.list({
      apiToken: controller.apiToken,
      params: {},
    }),
    OperatingRoomList.list({
      apiToken: controller.apiToken,
      params: {},
      extra: {
        division: controller.data.division,
      },
    }),
  ]);

  const state = controller.getState();

  const locationItems: any[] = location[0]?.items || [];
  const roomItems: any[] = room[0]?.items || [];

  const groupRooms = locationItems.map((item: any, index: number) => {
    return {
      ...item,
      items: roomItems.filter((acc) => acc.location === item.id),
    };
  });

  controller.setState(
    {
      ManageORSequence: {
        ...state.ManageORSequence,
        locationRoomList: groupRooms,
        sequenceIndex: "Action",
      },
    },
    () => controller.handleEvent({ message: "RunSequence", params })
  );
};

export const Action: Handler = async (controller, params) => {
  const state = controller.getState();

  if (params.action === "select_room") {
    HandleSelectRoom(controller, params);
  } else if (params.action === "select_slot") {
    HandleSelectSlot(controller, params);
  } else if (params.action === "save_time") {
    HandleSaveTime(controller, params);
  } else if (params.action === "change_setting_time") {
    HandleChangeSettingTime(controller, params);
  }
};

/* ------------------------------------------------------ */

/*                    Handle Action                    */

/* ------------------------------------------------------ */

const HandleSelectRoom: Handler = async (controller, params) => {
  const state = controller.getState();

  let block: any[] = [];

  if (params.room) {
    const dsb = await DivisionServiceBlockOperatingAndRoom.list({
      apiToken: controller.apiToken,
      params: {
        operating_room: params.room?.id,
        filter_type: "room",
      },
    });

    block = (dsb[0]?.items || []).map((item: any, index: number) => {
      return {
        ...item,
        start: new Date(item.start_datetime),
        end: new Date(item.end_datetime),
      };
    });
  }

  controller.setState({
    ManageORSequence: {
      ...state.ManageORSequence,
      selectedRoom: params.room,
      blockList: block,
      settingTimeOR: {},
    },
  });
};

const HandleSelectSlot: Handler = (controller, params) => {
  const state = controller.getState();

  let data = {
    ...state.ManageORSequence,
    selectedSlot: params.slot,
  };

  if (params.slot) {
    const momentDate = moment(params.slot.start);
    const date = formatDate(momentDate);

    data.settingTimeOR = {
      start_date: date,
      end_date: date,
      start_time: "",
      end_time: "",
      ...getActiveDay(date, date),
    };
  }

  controller.setState({ ManageORSequence: { ...data } });
};

const HandleSaveTime: Handler = async (controller, params) => {
  const state = controller.getState();

  const data = state.ManageORSequence?.settingTimeOR || {};

  const result = await CreateUpdateClosedRoom.post({
    apiToken: controller.apiToken,
    data: {
      ...data,
      start_date: data.start_date
        ? beToAd(data.start_date)?.format("YYYY-MM-DD")
        : "",
      end_date: data.end_date
        ? beToAd(data.end_date)?.format("YYYY-MM-DD")
        : "",
      operating_room: state.ManageORSequence?.selectedRoom?.id,
      select_day: data.select_day || [],
      everyday: !data.repeat ? true : data.everyday ?? true,
      all_hour: data.all_hour || false,
    },
    extra: {
      division: controller.data.division,
      device: controller.data.device,
    },
  });

  if (result[0]?.count) {
    controller.setState(
      {
        errorMessage: { ...state.errorMessage, [params.card]: result[1] },
        ManageORSequence: {
          ...state.ManageORSequence,
          selectedSlot: null,
          settingTimeOR: {},
        },
      },
      () =>
        controller.handleEvent({
          message: "RunSequence",
          params: {
            ...params,
            action: "select_room",
            room: state.ManageORSequence?.selectedRoom,
          },
        })
    );
  } else {
    let message: any = "";
    if (result[1]) {
      message = result[1];
    } else if (result[0]?.duplicate) {
      message = "มีช่วงเวลาปิดห้องผ่าตัดซ้ำ";
    } else if (!result[0]?.count) {
      message = "ไม่สามารถบันทึกได้";
    }

    controller.setState({
      errorMessage: { ...state.errorMessage, [params.card]: message },
    });
  }
};

const HandleChangeSettingTime: Handler = (controller, params) => {
  const state = controller.getState();

  const value = params.value;
  const name = params.name;
  let data = state.ManageORSequence?.settingTimeOR || {};

  if (name !== "select_day") {
    (data as any)[name] = value;
  }

  if (name === "everyday" && value) {
    data.select_day = [];
  } else if (name === "select_day") {
    if (data.select_day?.includes(value)) {
      data.select_day = (data.select_day || []).filter(
        (day: number) => day !== value
      );
    } else {
      data.select_day = [...(data.select_day || []), value];
    }
  } else if (name === "all_hour" && value) {
    data.start_time = "00:00";
    data.end_time = "23:59";
  } else if (name === "repeat" && !value) {
    data.everyday = undefined;
    data.select_day = [];
  } else if (["start_date", "end_date"].includes(name)) {
    data = {
      ...data,
      ...getActiveDay(
        data.start_date || "",
        data.end_date || "",
        data.select_day
      ),
    };
  }

  controller.setState({
    ManageORSequence: {
      ...state.ManageORSequence,
      settingTimeOR: { ...data },
    },
  });
};

/* ------------------------------------------------------ */

/*                          Utils                         */

/* ------------------------------------------------------ */

/**
 *
 * @param startDate dd/mm/yyyy (BE.)
 * @param endDate dd/mm/yyyy (BE.)
 */
const DAY_ITEMS = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const getActiveDay = (
  startDate: string,
  endDate: string,
  selectDay: number[] = []
) => {
  let activeDay: string[] = [];

  if (startDate && endDate) {
    const fromDate = beToAd(startDate) as moment.Moment;
    const toDate = beToAd(endDate) as moment.Moment;

    const diffDay = toDate.diff(fromDate, "days"); // 1

    if (diffDay >= 7) {
      activeDay = DAY_ITEMS;
    } else {
      while (fromDate.toISOString() <= toDate.toISOString()) {
        activeDay.push(fromDate.format("dddd"));
        fromDate.add(1, "days");
      }
    }
  } else {
    activeDay = DAY_ITEMS;
  }

  const dayIndex = activeDay.map((day) => DAY_ITEMS.indexOf(day));
  // @ts-ignore
  for (const day of selectDay) {
    if (!dayIndex.includes(day)) {
      selectDay = selectDay.filter((value) => value !== day);
    }
  }

  return {
    active_day: activeDay,
    select_day: selectDay,
  };
};
