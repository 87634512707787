import React from "react";
import { useIntl } from "react-intl";

const RightLabel = (props: any) => (
  <label
    style={{ flex: 1, textAlign: "right", whiteSpace: "unset", ...props.style }}
  >
    {props.children}
  </label>
);

export default RightLabel;
