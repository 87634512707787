import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  FormField,
  Button,
  Icon,
  FormGroup,
  TextArea
} from 'semantic-ui-react'

const CardLaboratoryExaminationSummaryUX = (props: any) => {
    return(
      <div
        style={{width: "100%", padding: "15px"}}>
        <div>
          
        </div>
        <div
          className="ui form"
          style={{paddingTop: "0rem"}}>
          
          <div
            style={{display:  props.config?.hideCloseIcon ? "none" : "flex", justifyContent: "flex-end", padding: "0 0 0.5rem 0"}}>
            
            <div
              style={{fontSize: "1.35rem", cursor: "pointer"}}>
              
              <Icon
                className="grey"
                name="close"
                onClick={props.onClose}>
              </Icon>
            </div>
          </div>
          <div
            style={{display: props.config?.hideHeader ? "none" : "flex", justifyContent: "space-between", padding:"0 1em 0 0"}}>
            
            <div
              style={{display: "flex", alignItems: "center", marginTop: "-0.75rem"}}>
              
              <div
                style={{marginRight: "0.5rem", fontSize: "1.2rem"}}>
                
                <Icon
                  name="lab">
                </Icon>
              </div>
              <label
                style={{fontWeight: "bold"}}>
                Laboratory Examination Summary
              </label>
            </div>
            <div
              style={{display: "flex"}}>
              
              <FormField>
                <div>
                  {props.approveButton}
                </div>
              </FormField>
              <FormField>
                <div>
                  {props.cancelButton}
                </div>
              </FormField>
              <FormField>
                <div>
                  {props.downloadButton}
                </div>
              </FormField>
              <FormField>
                <div>
                  {props.sendToButton}
                </div>
              </FormField>
            </div>
          </div>
          <div
            className="inline fields"
            style={{display: "none"}}>
            
            <FormField
              inline={true}
              width={8}>
            </FormField>
            <FormField
              inline={true}>
              <Button
                color="green"
                floated="right"
                onClick={props.onApprove}>
                Approve
              </Button>
            </FormField>
            <FormField
              inline={true}>
              <Button
                color="orange"
                floated="right"
                onClick={props.onCreatePDF}>
                Create PDF
              </Button>
            </FormField>
            <FormField
              inline={true}>
              <Button
                color="teal"
                fluid={true}>
                Send to Chat
              </Button>
            </FormField>
          </div>
        </div>
        <div>
          
          <Table
            data={props.labResultList || []}
            defaultPageSize={1}
            getTdProps={props.getTdProps}
            getTheadThProps={props.getTheadThProps}
            headers={`Lab Name,Reference value, Unit, Current Result, Recommendation`}
            keys="lab_name, ref_value,unit,result, recommend"
            showPagination={false}
            style={{maxHeight: "600px"}}
            widths="250, 150, 150, 150,">
          </Table>
        </div>
        <div
          className="ui form"
          style={{marginTop: "1.5rem"}}>
          
          <FormGroup>
            <FormField
              inline={true}
              width={16}>
              <div
                style={{display: "flex"}}>
                
                <label
                  style={{color: "#437682", fontWeight: "bold"}}>
                  Doctor's recommendation
                </label>
                <div
                  style={{color: "#437682", marginLeft: "0.5rem"}}>
                  
                  <Icon
                    name="stethoscope">
                  </Icon>
                </div>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={16}>
              <TextArea
                disabled={props.config?.disabledRecommend || false}
                name="recommendation"
                onChange={props.onChangeValue}
                style={{opacity: 1, backgroundColor: props.config?.isDoctor ? "": "rgba(232,232,232,0.45)"}}
                value={props.recommendation || ""}>
              </TextArea>
            </FormField>
          </FormGroup>
        </div>
      </div>
    )
}

export default CardLaboratoryExaminationSummaryUX

export const screenPropsDefault = {"price_claimable":0,"price_non_claimable":0,"price_total":0}

/* Date Time : Mon Apr 10 2023 05:32:31 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width: \"100%\", padding: \"15px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{paddingTop: \"0rem\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 25,
      "name": "Table",
      "parent": 24,
      "props": {
        "data": {
          "type": "code",
          "value": "props.labResultList || []"
        },
        "defaultPageSize": {
          "type": "code",
          "value": "1"
        },
        "getTdProps": {
          "type": "code",
          "value": "props.getTdProps"
        },
        "getTheadThProps": {
          "type": "code",
          "value": "props.getTheadThProps"
        },
        "getTrProps": {
          "type": "code",
          "value": ""
        },
        "headers": {
          "type": "code",
          "value": "`Lab Name,Reference value, Unit, Current Result, Recommendation`"
        },
        "keys": {
          "type": "value",
          "value": "lab_name, ref_value,unit,result, recommend"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{maxHeight: \"600px\"}"
        },
        "widths": {
          "type": "value",
          "value": "250, 150, 150, 150,"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "inline fields"
        },
        "style": {
          "type": "code",
          "value": "{display: \"none\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "FormField",
      "parent": 26,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "FormField",
      "parent": 26,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "Button",
      "parent": 28,
      "props": {
        "children": {
          "type": "value",
          "value": "Approve"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "floated": {
          "type": "value",
          "value": "right"
        },
        "fluid": {
          "type": "code",
          "value": ""
        },
        "onClick": {
          "type": "code",
          "value": "props.onApprove"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "FormField",
      "parent": 26,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "FormField",
      "parent": 26,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "Button",
      "parent": 36,
      "props": {
        "children": {
          "type": "value",
          "value": "Create PDF"
        },
        "color": {
          "type": "value",
          "value": "orange"
        },
        "floated": {
          "type": "value",
          "value": "right"
        },
        "fluid": {
          "type": "code",
          "value": ""
        },
        "onClick": {
          "type": "code",
          "value": "props.onCreatePDF"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "Button",
      "parent": 37,
      "props": {
        "children": {
          "type": "value",
          "value": "Send to Chat"
        },
        "color": {
          "type": "value",
          "value": "teal"
        },
        "floated": {
          "type": "value",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.config?.hideHeader ? \"none\" : \"flex\", justifyContent: \"space-between\", padding:\"0 1em 0 0\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", marginTop: \"-0.75rem\"}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "FormField",
      "parent": 42,
      "props": {
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "FormField",
      "parent": 42,
      "props": {
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "FormField",
      "parent": 42,
      "props": {
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "label",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": "Laboratory Examination Summary"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.5rem\", fontSize: \"1.2rem\"}"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "Icon",
      "parent": 53,
      "props": {
        "name": {
          "type": "value",
          "value": "lab"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:  props.config?.hideCloseIcon ? \"none\" : \"flex\", justifyContent: \"flex-end\", padding: \"0 0 0.5rem 0\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "div",
      "parent": 55,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.35rem\", cursor: \"pointer\"}"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "Icon",
      "parent": 56,
      "props": {
        "className": {
          "type": "value",
          "value": "grey"
        },
        "name": {
          "type": "value",
          "value": "close"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"1.5rem\"}"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "FormGroup",
      "parent": 58,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "FormField",
      "parent": 59,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "TextArea",
      "parent": 60,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.config?.disabledRecommend || false"
        },
        "name": {
          "type": "value",
          "value": "recommendation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{opacity: 1, backgroundColor: props.config?.isDoctor ? \"\": \"rgba(232,232,232,0.45)\"}"
        },
        "value": {
          "type": "code",
          "value": "props.recommendation || \"\""
        }
      },
      "seq": 61,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "FormGroup",
      "parent": 58,
      "props": {
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "FormField",
      "parent": 62,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "div",
      "parent": 63,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "label",
      "parent": 67,
      "props": {
        "children": {
          "type": "value",
          "value": "Doctor's recommendation"
        },
        "style": {
          "type": "code",
          "value": "{color: \"#437682\", fontWeight: \"bold\"}"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "div",
      "parent": 67,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{color: \"#437682\", marginLeft: \"0.5rem\"}"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "Icon",
      "parent": 69,
      "props": {
        "name": {
          "type": "value",
          "value": "stethoscope"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "div",
      "parent": 43,
      "props": {
        "children": {
          "type": "code",
          "value": "props.approveButton"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "div",
      "parent": 44,
      "props": {
        "children": {
          "type": "code",
          "value": "props.downloadButton"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "div",
      "parent": 45,
      "props": {
        "children": {
          "type": "code",
          "value": "props.sendToButton"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "FormField",
      "parent": 42,
      "props": {
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "div",
      "parent": 74,
      "props": {
        "children": {
          "type": "code",
          "value": "props.cancelButton"
        }
      },
      "seq": 75,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 90,
  "isMounted": false,
  "memo": false,
  "name": "CardLaboratoryExaminationSummaryUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "price_claimable": 0,
    "price_non_claimable": 0,
    "price_total": 0
  },
  "width": 55
}

*********************************************************************************** */
