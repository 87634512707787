import React, {
  useState,
  useRef,
  MutableRefObject,
  KeyboardEvent,
  useCallback,
  useEffect,
} from "react";
import { Button, Form, Input, Modal, Segment } from "semantic-ui-react";

// Common
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import { useIntl } from "react-intl";

// Types
type ModReceiptCodeArProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
  // data
  bilReceiptCodeDetail?: {
    code: string;
    BIL_RECEIPT_SYSTEM_PREFIX: string;
    BIL_RECEIPT_PREFIX_SIZE: number;
    BIL_RECEIPT_RUNNING_SIZE: number;
    receipt_code: string;
    receipt_type: string;
    station_name: string;
    require_set_receipt_code: boolean;
  };
  // CommonInterface
  successMessage?: Record<string, any>;
  errorMessage?: Record<string, any>;
  // config
  isDefault?: boolean;
};

const MOD_RECEIPT_CODE = "ModReceiptCodeAr";

const ModReceiptCodeAr = (props: ModReceiptCodeArProps) => {
  const intl = useIntl();
  const [openModReceiptCodeAr, setOpenModReceiptCodeAr] = useState<boolean>(false);
  const [receiptPrefix, setReceiptPrefix] = useState<string>("");
  const [receiptCode, setReceiptCode] = useState<string>("");

  // Ref
  const codeRef = useRef() as MutableRefObject<HTMLInputElement>;

  // Callback Effect
  const handleGetReceiptCode = useCallback(() => {
    props.onEvent({
      message: "HandleGetReceiptCode",
      params: {},
    });
  }, []);

  // useEffect(() => {
  //   handleGetReceiptCode();
  // }, []);

  useEffect(() => {
    const code = props.bilReceiptCodeDetail?.code || props.bilReceiptCodeDetail?.receipt_code;
    // เมื่อ default ให้แสดง prefix code ปัจจุบีน
    if (props.isDefault && code && code !== "AUTO") {
      const prefixSize = props.bilReceiptCodeDetail?.BIL_RECEIPT_PREFIX_SIZE || 0;
      const receiptCode = code.substring(
        props.bilReceiptCodeDetail?.BIL_RECEIPT_SYSTEM_PREFIX?.length || 0
      );

      const prefix = receiptCode.substring(0, prefixSize);
      const running = receiptCode.substring(prefixSize);

      setReceiptPrefix(prefix);
      setReceiptCode(running);
    }
    // เมื่อ Receipt code ที่ get มาเป็นค่าว่าง หรือ isDefaul
    if (code === "" || (props.isDefault && code !== "AUTO")) {
      setOpenModReceiptCodeAr(true);
    }
  }, [props.bilReceiptCodeDetail?.code]);

  useEffect(() => {
    if (props.successMessage?.[MOD_RECEIPT_CODE]) {
      props.setProp(`successMessage.${MOD_RECEIPT_CODE}`, null);
      setOpenModReceiptCodeAr(false);

      handleGetReceiptCode();
    }
  }, [props.successMessage]);

  // Handler
  const getSubString = (value: string, length: number) => {
    return value.substring(0, length);
  };

  const handleChangePrefix = (e: any, data: any) => {
    setReceiptPrefix(getSubString(data.value, 3));
  };

  const handleChangeCode = (e: any, data: any) => {
    setReceiptCode(getSubString(data.value, 7));
  };

  const handleOnKeyPressPrefix = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      codeRef.current.focus();
    }
  };

  const handleOnKeyPressCode = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();

      handleReceiptCode();
    }
  };

  const handleReceiptCode = () => {
    props.onEvent({
      message: "HandlePostReceiptCode",
      params: {
        prefix: receiptPrefix,
        code: receiptCode,
        card: MOD_RECEIPT_CODE,
        isAr: true,
      },
    });
  };

  // Mod
  const handleCloseModReceiptCodeAr = () => {
    setOpenModReceiptCodeAr(false);
  };

  return (
    <Modal
      open={openModReceiptCodeAr}
      size="small"
      closeOnDimmerClick
      // callback
      onClose={handleCloseModReceiptCodeAr}
    >
      <Segment>
        <div>
          <label style={{ fontWeight: "bold" }}>{intl.formatMessage({ id: "ตั้งเลขที่ใบเสร็จ" })}</label>
        </div>
        <hr style={{ color: "#787878" }} />
        <ErrorMessage error={props.errorMessage?.[MOD_RECEIPT_CODE]} />
        <Form>
          <Form.Group className="noMargin" inline style={{ padding: "15px" }}>
            <Form.Field inline width={3}>
              <label style={{ minWidth: "max-content" }}>{intl.formatMessage({ id: "จุดเก็บเงิน" })}</label>
            </Form.Field>
            <Form.Field inline width={9}>
              <div style={STYLE.ReceiptCodeDetail}>{props.bilReceiptCodeDetail?.station_name}</div>
            </Form.Field>
          </Form.Group>
          <Form.Group className="noMargin" inline style={{ padding: "15px" }}>
            <Form.Field inline width={3}>
              <label style={{ minWidth: "max-content" }}>{intl.formatMessage({ id: "ประเภทใบเสร็จ" })}</label>
            </Form.Field>
            <Form.Field inline width={9}>
              <div style={STYLE.ReceiptCodeDetail}>{props.bilReceiptCodeDetail?.receipt_type}</div>
            </Form.Field>
          </Form.Group>
          <Form.Group className="noMargin" inline style={{ padding: "15px" }}>
            <Form.Field inline width={3}>
              <label style={{ minWidth: "max-content" }}>{intl.formatMessage({ id: "เล่มที่/เลขที่ใบเสร็จ" })}</label>
            </Form.Field>
            <Form.Field inline width={2}>
              <Input
                placeholder="XXX"
                value={receiptPrefix}
                // callback
                onChange={handleChangePrefix}
                onKeyPress={handleOnKeyPressPrefix}
              />
            </Form.Field>
            <Form.Field inline>/</Form.Field>
            <Form.Field inline width={6}>
              <Input
                ref={(ref: any) => ref && (codeRef.current = ref.inputRef.current)}
                placeholder="XXXXXXX"
                value={receiptCode}
                onChange={handleChangeCode}
                onKeyPress={handleOnKeyPressCode}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group
            className="noMargin"
            inline
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button color="green" onClick={handleReceiptCode}>
              ตกลง
            </Button>
          </Form.Group>
        </Form>
      </Segment>
    </Modal>
  );
};

export default React.memo(ModReceiptCodeAr);

const STYLE = {
  ReceiptCodeDetail: {
    background: "#F1F1F1",
    padding: "10px",
    borderRadius: 4,
    width: "100%",
  },
};
