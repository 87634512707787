import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  FormField,
  Button,
  Dropdown
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardPHVListUX = (props: any) => {
    return(
      <div
        style={{ margin: "10px" }}>
        <Form
          className="mini">
          <FormGroup>
            <FormField
              style={{minWidth: "max-content"}}>
              <label
                style={{margin: 0}}>
                Encounter type
              </label>
              <div
                className="ui buttons mini">
                
                <Button
                  name="IPD"
                  onClick={props.onChangeEncounterType}
                  style={ {borderRight: "1px solid rgba(34,36,38,.15)", ...(props.encounterType?.includes("IPD")?{backgroundColor: "#56ccf2", color: "white"}:{})}}>
                  IPD
                </Button>
                <Button
                  name="OPD"
                  onClick={props.onChangeEncounterType}
                  style={props.encounterType?.includes("OPD")?{backgroundColor: "#56ccf2", color: "white"}:{}}>
                  OPD
                </Button>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <label
                style={{minWidth: "max-content"}}>
                Visit date
              </label>
              <div
                style={{width: "100%"}}>
                
                <DateTextBox
                  onChange={props.onChangeDate?.("startDate")}
                  value={props.filter?.startDate|| ""}>
                </DateTextBox>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <label>
                To
              </label>
              <div
                style={{width: "100%"}}>
                
                <DateTextBox
                  onChange={props.onChangeDate?.("endDate")}
                  value={props.filter?.endDate|| ""}>
                </DateTextBox>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <label>
                Division
              </label>
              <Dropdown
                className="inline-label"
                clearable={true}
                fluid={true}
                name="divisionId"
                onChange={props.onChangeFilter}
                options={props.divisionOptions || []}
                search={true}
                selection={true}
                style={{width: "100%", fontSize: "1em"}}
                value={props.filter?.divisionId || ""}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <label>
                Doctor
              </label>
              <div
                style={{width: "100%"}}>
                {props.DoctorSearchBox}
              </div>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <label>
                Dr.Order
              </label>
              <div>
                {props.DoctorOrderOptions}
              </div>
            </FormField>
            <FormField>
              <label
                style={{margin:0}}>
                {"\u00a0"}
              </label>
              <div>
                {props.ButtonSearch}
              </div>
            </FormField>
            <FormField>
              <label
                style={{margin:0}}>
                {"\u00a0"}
              </label>
              <Button
                color="orange"
                onClick={props.onClickClear}
                size="mini">
                Clear
              </Button>
            </FormField>
          </FormGroup>
        </Form>
        <div>
          
          <Table
            data={props.encounterList || []}
            getTrProps={props.onGetTrProps}
            headers={props.headers}
            keys={props.keys}
            minRows={5}
            showPagination={false}
            style={{height: "200px"}}
            widths={props.widths}>
          </Table>
        </div>
        <div
          style={{marginTop: "0.5rem", display: "flex", justifyContent: "flex-end"}}>
          {props.Pagination}
        </div>
      </div>
    )
}


export default CardPHVListUX

export const screenPropsDefault = {}

/* Date Time : Mon Aug 26 2024 12:23:12 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ margin: \"10px\" }"
        }
      },
      "seq": 0
    },
    {
      "from": "semantic-ui-react",
      "id": 1,
      "name": "Form",
      "parent": 0,
      "props": {
        "className": {
          "type": "value",
          "value": "mini"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 2,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "label",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "Encounter type"
        },
        "style": {
          "type": "code",
          "value": "{margin: 0}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "label",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "Visit date"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "label",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "To"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "label",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "Division"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "label",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "Doctor"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui buttons mini"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "Button",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "IPD"
        },
        "name": {
          "type": "value",
          "value": "IPD"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeEncounterType"
        },
        "size": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": " {borderRight: \"1px solid rgba(34,36,38,.15)\", ...(props.encounterType?.includes(\"IPD\")?{backgroundColor: \"#56ccf2\", color: \"white\"}:{})}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "Button",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "OPD"
        },
        "name": {
          "type": "value",
          "value": "OPD"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeEncounterType"
        },
        "style": {
          "type": "code",
          "value": "props.encounterType?.includes(\"OPD\")?{backgroundColor: \"#56ccf2\", color: \"white\"}:{}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 19,
      "name": "DateTextBox",
      "parent": 18,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeDate?.(\"startDate\")"
        },
        "value": {
          "type": "code",
          "value": "props.filter?.startDate|| \"\""
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 21,
      "name": "DateTextBox",
      "parent": 20,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeDate?.(\"endDate\")"
        },
        "value": {
          "type": "code",
          "value": "props.filter?.endDate|| \"\""
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "Dropdown",
      "parent": 6,
      "props": {
        "className": {
          "type": "value",
          "value": "inline-label"
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "divisionId"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "options": {
          "type": "code",
          "value": "props.divisionOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", fontSize: \"1em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filter?.divisionId || \"\""
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "code",
          "value": "props.DoctorSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSearch"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "Button",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "Clear"
        },
        "color": {
          "type": "value",
          "value": "orange"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickClear"
        },
        "size": {
          "type": "value",
          "value": "mini"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 27,
      "name": "Table",
      "parent": 26,
      "props": {
        "data": {
          "type": "code",
          "value": "props.encounterList || []"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.onGetTrProps"
        },
        "headers": {
          "type": "code",
          "value": "props.headers"
        },
        "keys": {
          "type": "code",
          "value": "props.keys"
        },
        "minRows": {
          "type": "code",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"200px\"}"
        },
        "widths": {
          "type": "code",
          "value": "props.widths"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.Pagination"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"0.5rem\", display: \"flex\", justifyContent: \"flex-end\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "label",
      "parent": 9,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": "{margin:0}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": "{margin:0}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "label",
      "parent": 31,
      "props": {
        "children": {
          "type": "value",
          "value": "Dr.Order"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "div",
      "parent": 31,
      "props": {
        "children": {
          "type": "code",
          "value": "props.DoctorOrderOptions"
        }
      },
      "seq": 35,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardPHVListUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
