import moment from "moment";

// Utils
import { formatDate } from "react-lib/utils/dateUtils";
import { HeaderLogoWithAddress } from "react-lib/apps/HISV3/common/HeaderPdfFormTemplate";

const FORM_NAME = "FormPatientList";

const FormPatientList = async (props: any) => {
  const headerForm = await HeaderLogoWithAddress({
    pageMargins: [15, 140, 15, 35],
    font: "THSarabunNew",
    form: FORM_NAME,
    logoHeight: 38,
    styles: {
      fieldHeader: {
        fontSize: 18,
        bold: true,
        alignment: "center",
      },
      tableHeader: {
        bold: true,
        fontSize: 13,
        color: "black",
      },
      tableData: {},
      miniFieldKey: {
        fontSize: 12.5,
        bold: true,
      },
      miniFieldValue: {
        fontSize: 12.5,
      },
    },
    titleContent: [
      {
        text: "รายงานสถิติผู้ป่วยนอก/ใน แยกตามประเภท",
        style: "fieldHeader",
      },
      {
        marginTop: -2.5,
        text: `วันที่ ${props.start_date || ""} - ${props.end_date || ""}`,
        style: "fieldHeader",
      },
    ],
  });
  const { font, images, styles } = headerForm;

  return {
    defaultStyle: {
      font: font,
      lineHeight: 1,
      fontSize: 14,
    },
    pageOrientation: "landscape",
    pageSize: "A4",
    ...styles,
    ...headerForm,
    content: [
      {
        // style: "table",
        table: {
          headerRows: 1,
          widths: ["4.5%", "9.75%", "14%", "9%", "14%", "9%", "9%", "15%", "16%"],
          body: [
            [
              {
                text: "ลำดับ",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "วันที่รับเข้า",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "คลินิก/Ward",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "HN (VN/AN)",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "ชื่อ-นามสกุล",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "ประเภทผู้ป่วย",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "ประเภท Visit",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "แพทย์เจ้าของไข้",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "Diagnosis",
                style: "tableHeader",
                alignment: "center",
              },
            ],
            ...props.items.map((item: any, index: number) => [
              {
                text: index + 1,
                style: "tableData",
                alignment: "center",
              },
              {
                text: item.encounter_datetime,
                style: "tableData",
                alignment: "center",
              },
              {
                text: item.division_name,
                style: "tableData",
                alignment: "center",
              },
              {
                text: item.hn_encounter_number.replace(/ /g, "\n"),
                style: "tableData",
                alignment: "center",
              },
              {
                text: item.patient_fullname,
                style: "tableData",
                alignment: "center",
              },
              {
                text: item.patient_case_name,
                style: "tableData",
                alignment: "center",
              },
              {
                text: item.encounter_general_type,
                style: "tableData",
                alignment: "center",
              },
              {
                text: item.doctor_name,
                style: "tableData",
                alignment: "center",
              },
              {
                text: item.icd10_name,
                style: "tableData",
                alignment: "center",
              },
            ]),
          ],
        },
        layout: {
          vLineWidth: function (i: number, node: any) {
            return 0.25;
          },
          hLineWidth: function (i: number, node: any) {
            return 0.25;
          },
          paddingBottom: function (i: number, node: any) {
            return i === 0 ? 1 : 12;
          },
          paddingTop: function (i: number, node: any) {
            return i === 0 ? 1 : 12;
          },
        },
      },
    ],
    footer: function (currentPage: number, pageCount: number) {
      return {
        margin: [12.5, 0, 15, 0],
        columns: [
          {
            stack: [
              {
                text: [
                  { text: "วันเวลาที่พิมพ์ ", style: "miniFieldKey" },
                  {
                    text: `${formatDate(moment())} [${moment().format("HH:mm")}]`,
                    style: "miniFieldValue",
                  },
                ],
              },
              {
                text: `ผู้ออกรายงาน : ${props.staff}`,
                style: "miniFieldValue",
                marginTop: -2,
              },
            ],
          },
          {
            margin: [0, 5, 5, 0],
            text: `หน้าที่ ${currentPage}/${pageCount}`,
            alignment: "right",
          },
        ],
      };
    },
    images: {
      ...images,
    },
  };
};

export default FormPatientList;
