import { TDocumentDefinitions } from "pdfmake/interfaces";

import { splitStringNewLine } from "react-lib/apps/HISV3/common/CommonInterface";

import HeaderSummaryReportForm from "./HeaderSummaryReportForm";

export default async function toPDFMakeData(props: any): Promise<TDocumentDefinitions> {
  console.log("Props form CoverageReport:", props);

  const headerForm = await HeaderSummaryReportForm({
    data: props.reportCovData?.params,
    header: [
      {
        alignment: "center",
        bold: true,
        fontSize: 15,
        text: `รายงานการใช้สิทธิ ${
          props.reportCovData?.params?.coverage_name?.length === 0
            ? "ทุกสิทธิ"
            : props.reportCovData?.params?.coverage_name
        }`,
      },
      {
        alignment: "center",
        bold: true,
        fontSize: 15,
        text: `สังกัด ${
          props.reportCovData?.params?.payer_name?.length === 0
            ? "ทุกต้นสังกัด"
            : props.reportCovData?.params?.payer_name
        }`,
      },
      {
        alignment: "center",
        fontSize: 15,
        marginBottom: 5,
        text: `วันที่รับการรักษา ตั้งแต่ ${props.items?.filterStartDate} ถึง ${props.items?.filterEndDate}`,
      },
    ],
  });

  const { font, images, styles } = headerForm;

  const generateTableBody = (data: any, columns: any) => {
    let body = [];

    // Header of Table
    body.push([
      { text: "ลำดับ", bold: true, alignment: "center" },
      { text: "HN", bold: true, alignment: "center" },
      { text: "VN", bold: true, alignment: "center" },
      { text: "วันเวลาที่รักษา", bold: true, alignment: "center" },
      { text: "คลินิก", bold: true, alignment: "center" },
      { text: "ชื่อ - สกุล", bold: true, alignment: "center" },
      { text: "บัตรพนักงาน / ครอบครัว", bold: true, alignment: "center" },
      { text: "ยอดรวม", bold: true, alignment: "center" },
      { text: "รายละเอียด", bold: true, alignment: "center" },
      { text: "ค่ารักษา", bold: true, alignment: "center" },
    ]);

    if (data?.length > 0) {
      data.forEach((row: any) => {
        let dataRow: any = [];

        columns.forEach((column: any) => {
          if (row[column] !== null && row[column] !== undefined) {
            const descList = row["description_list"].map((text: string) => {
              return splitStringNewLine(text, { fontSize: 12, width: 335 });
            });
            const priceList = descList.map((texts: string[], index: number) => {
              return [row["price_list"][index], ...Array(texts.length - 1).fill("\u00A0")];
            });
            switch (column) {
              case "total":
                dataRow.push({ text: row[column], alignment: "right" });
                break;
              case "description_list":
                dataRow.push({
                  stack: descList.map((texts: string[]) => {
                    return {
                      stack: texts.map((text: string) => {
                        return { text };
                      }),
                    };
                  }),
                });
                break;
              case "price_list":
                dataRow.push({
                  stack: priceList.map((texts: string[]) => {
                    return {
                      stack: texts.map((text: string) => {
                        return { text, alignment: "right" };
                      }),
                    };
                  }),
                });
                break;
              default:
                dataRow.push(row[column].toString());
                break;
            }
          } else {
            dataRow.push("-");
          }
        });

        body.push(dataRow);
      });

      // Calculate Total
      body.push([
        { text: "ยอดรวมทั้งสิ้น", bold: true, alignment: "center", colSpan: 7 },
        "",
        "",
        "",
        "",
        "",
        "",
        {
          text: props.reportCovData?.params?.total_price,
          alignment: "right",
          border: [true, true, false, true],
        },
        {
          text: "",
          colSpan: 2,
          border: [false, true, true, true],
        },
        "",
      ]);
    }

    return body;
  };

  const tableReport = (data: any, columns: any) => {
    return {
      table: {
        widths: ["4%", "7%", "5%", "7%", "8%", "14%", "8%", "7%", "33%", "7%"],
        headerRows: 1,
        body: generateTableBody(data, columns),
      },
    };
  };

  return {
    ...headerForm,
    content: [
      tableReport(props.reportCovData?.fields || [], [
        "seq",
        "hn",
        "encounter_number",
        "performed_date",
        "division_name",
        "patient_name",
        "employee_card",
        "total",
        "description_list",
        "price_list",
      ]),
    ],
    images: {
      ...images,
    },
    styles: {
      ...styles,
    },
    pageOrientation: `landscape`,
    defaultStyle: {
      font,
      // Font Size Default 12
      fontSize: 12,
    },
    pageSize: `A4`,
  };
}
