import React, { useReducer, useEffect } from 'react';
import DateTextBox from '../common/DateTextBox';
import { Form, Button } from 'semantic-ui-react';
import { formatDate } from '../../utils/dateUtils';
import { UIReducer } from '../../utils/UIReducers';
import PropTypes from 'prop-types';
import { useIntl } from "react-intl";

const styles = {
  label: {
    marginLeft: "1em"
  }
};

const FilterDateRange = (props: any) => {
  const intl = useIntl();

  const {
    fromText,
    toText,
    searchText, 
    searchColor,
    dispatchAction,
    onStartDateChange,
    onEndDateChange,
    dateTextBoxSize,
    onFilterDate,
    hideSearchButton,
    defaultEndDate, 
  } = props

  let fromTxt = fromText || "ตั้งแต่"
  let toTxt = toText || "วันที่สิ้นสุด"
  let searchTxt = searchText || "ค้นหา"
  let searchCol = searchColor || 'blue'
  
  const initialUIState = {
    startDate: '',
    endDate: formatDate(defaultEndDate ? defaultEndDate : new Date() ),
    // startDate: null,
    // formatDate: null
  }

  // const [startDate, setStartDate] = useState(formatDate(defaultStartDate))
  // const [endDate, setEndDate] = useState(formatDate(d))
  const [UIState, dispatchUIState] = useReducer(UIReducer, initialUIState)

  useEffect(() => {
    if (!dispatchAction) {
      return
    }
    if (dispatchAction.type === 'CLEAR') {
      dispatchUIState({ ...dispatchAction, initialState: initialUIState })
    }
    if (dispatchAction.type === 'GETFILTERDATE'){
        const callbackFilter = dispatchAction.callbackFilter 
        const requestField = dispatchAction.requestField
        callbackFilter( requestField.reduce((total: any, currentValue: any) => ( {...total, [currentValue]: UIState[currentValue]} ) , {} ))
      }
  }, [dispatchAction])

  useEffect(() => {
    onStartDateChange(UIState.startDate)
  }, [UIState.startDate])

  useEffect(() => {
    onEndDateChange(UIState.endDate)
  }, [UIState.endDate])


  const handleDateTextBoxChange = (date: any, field: any) => {
    dispatchUIState({
      type: 'CHANGE_STATE',
      actionList: [{ field, value: date }]
    }) 
  }

  return (
    <Form.Group inline style={props.style}>
      {fromTxt}
      <Form.Field style={styles.label}>
        <DateTextBox
          disabled={props.disabled}
          id={'StartDateTextBox'}
          placeholder={intl.formatMessage({ id: "ไม่ระบุ" })}
          size={dateTextBoxSize}
          value={props.startDate ? props.startDate : UIState.startDate}
          onChange={(date: any) => handleDateTextBoxChange(date, 'startDate')}
        />
      </Form.Field>
      {toTxt}
      <Form.Field style={styles.label}>
        <DateTextBox
          disabled={props.disabled}
          id={'EndDateTextBox'}
          placeholder={intl.formatMessage({ id: "ไม่ระบุ" })}
          size={dateTextBoxSize}
          value={props.endDate ? props.endDate : UIState.endDate}
          onChange={(date: any) => handleDateTextBoxChange(date, 'endDate')}
        />
      </Form.Field>
      <Form.Field style={{display: hideSearchButton ? 'none' : null}}>
      <Button type='submit' color={searchCol}
        disabled={props.disabled}
        onClick={onFilterDate}>
        {searchTxt}
        </Button>
      </Form.Field>
    </Form.Group>
  )
}

FilterDateRange.defaultProps = {

  fromText: 'From',
  toText: 'To',
  searchText: 'ค้นหา',
  searchColor: 'blue',
  dispatchAction: {},
  onStartDateChange: () => {},
  onEndDateChange: () => {},
  dateTextBoxSize: 'small',
  onFilterDate: () => {},
  hideSearchButton: false,
};

FilterDateRange.propTypes = {
  fromText: PropTypes.string,
  toText: PropTypes.string,
  searchText: PropTypes.string,
  searchColor: PropTypes.string,
  dispatchAction: PropTypes.object,
  onStartDateChange: PropTypes.func, 
  onEndDateChange: PropTypes.func,
  dateTextBoxSize: PropTypes.string,
  onFilterDate: PropTypes.func,
  hideSearchButton: PropTypes.bool,

}


export default React.memo(FilterDateRange);
