import WasmController from 'react-lib/frameworks/WasmController';

export type State = 
  {
  }

export const StateInitial: State = 
  {
  }

export type Event = 
  { message: "getListMedAdminRecord", params: any }
  |  { message: "postCreateMedAdminRecord", params: any }
  |  { message: "putUpdateMedAdminRecord", params: any }
  |  { message: "putMedAdminRecord", params: any }
  |  { message: "putDrugOrderItemIdAdminDivision", params: any }
  |  { message: "postMedAdminRecord", params: any }
  |  { message: "getPrintMedRecord", params: any }
  |  { message: "getDrugOrderItemForMedReconcile", params: any }
  |  { message: "tpdService", params: any }

export type Data = 
  {
    division?: number,
  }

export const DataInitial = 
  {
  }

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any 

export const getListMedAdminRecord: Handler = async (controller, {}) => {
//function (params) { return __awaiter(_this, void 0, void 0, function () {
//            var vsResponse, vsError, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        vsResponse = {};
//                        vsError = null;
//                        return [4 /*yield*/, utils_1.to(this.tpdService.getListMedAdminRecord(params))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        if (response) {
//                            vsResponse = response.items;
//                        }
//                        else {
//                            vsError = error;
//                        }
//                        return [2 /*return*/, [vsResponse, vsError, network]];
//                }
//            });
//        }); }
//function (params) {
//        var url = apis_1.TPD.LIST_MED_ADMIN_RECORD;
//        return this.client
//            .get(url, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/TPD/list-medication-admin-record/
}

export const postCreateMedAdminRecord: Handler = async (controller, {}) => {
//function (params) { return __awaiter(_this, void 0, void 0, function () {
//            var _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0: return [4 /*yield*/, utils_1.to(this.tpdService.postCreateMedAdminRecord(params))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (params) {
//        var url = apis_1.TPD.CREATE_MED_ADMIN_RECORD;
//        return this.client
//            .post(url, params)
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/TPD/create-medication-admin-record/
}

export const putUpdateMedAdminRecord: Handler = async (controller, {}) => {
//function (medicationRecordId, params) { return __awaiter(_this, void 0, void 0, function () {
//            var _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0: return [4 /*yield*/, utils_1.to(this.tpdService.putUpdateMedAdminRecord(medicationRecordId, params))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (medicationRecordId, params) {
//        var url = apis_1.TPD.UPDATE_MED_ADMIN_RECORD_ID({ medication_record_id: medicationRecordId });
//        return this.client
//            .put(url, params)
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/TPD/update-medication-admin-record/' +
//((__t = (medication_record_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const putMedAdminRecord: Handler = async (controller, {}) => {
//function (medicationRecordId, params) { return __awaiter(_this, void 0, void 0, function () {
//            var _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0: return [4 /*yield*/, utils_1.to(this.tpdService.putMedAdminRecord(medicationRecordId, params))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (medicationRecordId, params) {
//        var url = apis_1.TPD.MED_RECORD_ID({ medication_record_id: medicationRecordId });
//        return this.client
//            .put(url, params)
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/TPD/medication-record/' +
//((__t = (medication_record_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const putDrugOrderItemIdAdminDivision: Handler = async (controller, {}) => {
//function (drugOrderItemId, params) { return __awaiter(_this, void 0, void 0, function () {
//            var _a, response, error, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0: return [4 /*yield*/, utils_1.to(this.tpdService.putDrugOrderItemIdAdminDivision(drugOrderItemId, params))];
//                    case 1:
//                        _a = _b.sent(), response = _a[0], error = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (drugOrderItemId, params) {
//        var url = apis_1.TPD.DRUG_ORDER_ITEM_ID_ADMIN_DIVISION({ drug_order_item_id: drugOrderItemId });
//        return this.client
//            .put(url, params)
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/TPD/drug-order-item/' +
//((__t = (drug_order_item_id)) == null ? '' : __t) +
//'/admin-division/';
//
//}
//return __p
//}
}

export const postMedAdminRecord: Handler = async (controller, {}) => {
//function (params) { return __awaiter(_this, void 0, void 0, function () {
//            var _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0: return [4 /*yield*/, utils_1.to(this.tpdService.postMedAdminRecord(params))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (params) {
//        var url = apis_1.TPD.MED_RECORD;
//        return this.client
//            .post(url, params)
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/TPD/medication-record/
}

export const getPrintMedRecord: Handler = async (controller, {}) => {
//function (params) { return __awaiter(_this, void 0, void 0, function () {
//            var vsResponse, vsError, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        vsResponse = {};
//                        vsError = null;
//                        return [4 /*yield*/, utils_1.to(this.tpdService.getPrintMedRecord(params))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        if (response) {
//                            vsResponse = response;
//                        }
//                        else {
//                            vsError = error;
//                        }
//                        return [2 /*return*/, [vsResponse, vsError, network]];
//                }
//            });
//        }); }
//function (params) {
//        var url = apis_1.TPD.PRINT_MED_RECORD;
//        return this.client
//            .get(url, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/TPD/medication-record-print/
}

export const getDrugOrderItemForMedReconcile: Handler = async (controller, {}) => {
//function (params) { return __awaiter(_this, void 0, void 0, function () {
//            var vsResponse, vsError, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        vsResponse = {};
//                        vsError = null;
//                        return [4 /*yield*/, utils_1.to(this.tpdService.getDrugOrderItemForMedReconcile(params))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        if (response) {
//                            vsResponse = response;
//                        }
//                        else {
//                            vsError = error;
//                        }
//                        return [2 /*return*/, [vsResponse, vsError, network]];
//                }
//            });
//        }); }
//function (params) {
//        var url = apis_1.TPD.DRUG_ORDER_ITEM_FOR_MED_RECONCILE;
//        return this.client.get(url, { params: params })
//            .then(function (response) { return response.data; })
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/TPD/drug-order-item-for-med-reconcile/
}

export const tpdService: Handler = async (controller, {}) => {
//[object Object]
//
//----> Name mismatch between manager and service
}

