import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Input,
  Button,
  Checkbox,
  Label,
  Dropdown,
  FormGroup,
  FormField
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardSupplyOrderUX = (props: any) => {
    return(
      <div
        style={{ ...(props.style || {}) }}>
        <div
          style={{fontWeight: "bold", fontSize: "1.2rem",padding: "10px",display:props.isEdit ?  "none":""}}>
          ประวัติการสั่งเวชภัณฑ์
        </div>
        <div
          style={{display:props.isEdit ? "none":"flex" , justifyContent:"row",alignItems:"center", padding: "0px 0px 20px 0px"}}>

          <Checkbox
            checked={props.OrderSupplySequence?.isUseRangeSearch}
            label="วันที่สั่ง"
            onClick={props.handleToggleUseRange}
            style={{padding:"0px 30px",minWidth:"max-content"}}>
          </Checkbox>
          <DateTextBox
            onChange={props.handleFromHistory}
            style={{padding:"0px 30px 0px 60px"}}
            value={props.OrderSupplySequence?.searchFromHistorySearch}>
          </DateTextBox>
          <DateTextBox
            onChange={props.handleToHistory}
            style={{padding:"0px 30px 0px 0px"}}
            value={props.OrderSupplySequence?.searchToHistorySearch}>
          </DateTextBox>
          <Button
            color="blue"
            loading={props.OrderSupplySequence?.searchBtnLoading}
            onClick={props.handleHistorySearch}
            style={{width: "200px"}}>
            ค้นหา
          </Button>
        </div>
        <div>

          <Table
            className="ReactTable --rt-tbody-overflow-y-hidden"
            data={props.historySupplyOrder}
            getTdProps={props.getTdPropsHistory}
            getTrProps={props.getTrPropsHistory}
            headers="รหัส,ประเภท,สถานะ,วันที่สั่ง,หน่วยงานสั่ง,ผู้ป่วย,แพทย์,วันที่จ่าย,หน่วยงานที่จ่าย,ผู้บันทึกล่าสุด"
            keys="code,type,status,order_time,order_div,patient,doctor,order_perform_time,order_perform_div,edit_user"
            minRows={7}
            showPagination={false}
            style={{display:props.isEdit ? "none ":"",height: "300px",  ...(props.tableStyle || {})}}
            widths="105,110,105,130,150,120,150,130,120,*">
          </Table>
        </div>
        <div
          style={{display:props.isEdit ? "none":"flex" , justifyContent: "space-between", margin: "20px 10px"}}>

          <div
            style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>

            <Label
              size="large"
              style={{marginRight: "40px"}}>
              เริ่มสั่งเวชภัณฑ์ชุดใหม่
            </Label>
            <Button
              className={props.supplyOrderType === "HOME_OPD" ? "active" : ""}
              name="HOME_OPD"
              onClick={props.onNewSupplyOrder}
              size="small"
              style={{...(!props.opdHomeDisplay && {display: "none"})}}>
              HOME_OPD
            </Button>
            <Button
              className={props.supplyOrderType === "REFILL_OPD" ? "active" : ""}
              name="REFILL_OPD"
              onClick={props.onNewSupplyOrder}
              size="small"
              style={{...(!props.opdRefillDisplay && {display: "none"})}}>
              REFILL_OPD
            </Button>
            <Button
              className={props.supplyOrderType === "REFILL_IPD" ? "active" : ""}
              name="REFILL_IPD"
              onClick={props.onNewSupplyOrder}
              size="small"
              style={{...(!props.ipdRefillDisplay && {display: "none"})}}>
              REFILL_IPD
            </Button>
            <Button
              className={props.supplyOrderType === "HOME_IPD" ? "active" : ""}
              name="HOME_IPD"
              onClick={props.onNewSupplyOrder}
              size="small"
              style={{...(!props.ipdHomeDisplay && {display: "none"})}}>
              HOME_IPD
            </Button>
            <Button
              className={props.supplyOrderType === "ONE_DAY" ? "active" : ""}
              name="ONE_DAY"
              onClick={props.onNewSupplyOrder}
              size="small"
              style={{...(!props.ipdOneDayDisplay && {display: "none"})}}>
              ONE_DAY
            </Button>
            <Button
              className={props.supplyOrderType === "STAT" ? "active" : ""}
              name="STAT"
              onClick={props.onNewSupplyOrder}
              size="small"
              style={{...(!props.isStatDisplay && {display: "none"})}}>
              STAT
            </Button>
            <Button
              className={props.supplyOrderType === "COST_CENTER" ? "active" : ""}
              name="COST_CENTER"
              onClick={props.onNewSupplyOrder}
              size="small"
              style={{...(!props.costCenterDisplay && {display: "none"})}}>
              Cost center
            </Button>
          </div>
          <div
            style={{textAlign: "right"}}>
            {props.paginationComponent}
          </div>
        </div>
        <div
          style={{width: "10px", height: "10px"}}>

        </div>
        <div
          style={props.styleEditSupply}>

          <div
            style={{display:"flex", justifyContent:"space-between",alignItems:"center",padding:"0px 10px"}}>

            <div
              style={{display:"flex", alignItems:"center"}}>

              <Label
                color={props.isStatus && props.ribbonColor ?  props.ribbonColor : "gray"}>
                {props.titleSupplyOrder}
              </Label>
              <div
                style={{fontWeight: "bold", fontSize: "1.2rem",padding: "10px"}}>
                { props?.searchHistorySelected ?  props.isCostCenter?"แก้ไขรายการสั่งเวชภัณฑ์เหมารวมในหัตถการ":"แก้ไขรายการสั่งเวชภัณฑ์" : props.isCostCenter? "รายการสั่งเวชภัณฑ์เหมารวมในหัตถการ":"รายการสั่งเวชภัณฑ์ใหม่"}
              </div>
            </div>
            <div>
              {props.statusSupplyOrder}
            </div>
          </div>
          <div
            style={{display: props?.searchHistorySelected  ? "flex" :"none" ,alignItems:"center", margin: "10px 10px", fontWeight: "normal", fontSize: "1.2rem"}}>

            <div
              style={{margin: "0px 10px 0px 0px"}}>
              เลขที่เอกสาร
            </div>
            <Label>
              {props.editCode}
            </Label>
            <div
              style={{margin: "0px 10px 0px 30px"}}>
              encounter
            </div>
            <Label
              name={{margin: "0px 20px 0px 0px"}}>
              {props.editEncounter}
            </Label>
            <div
              style={{margin: "0px 10px 0px 30px"}}>
              แพทย์
            </div>
            <Label
              name={{margin: "0px 20px 0px 0px"}}>
              {props.editDoctor}
            </Label>
            <div
              style={{margin: "0px 10px 0px 30px"}}>
              หน่วยงานสั่ง
            </div>
            <Label
              name={{margin: "0px 20px 0px 0px"}}>
              {props.editOrderDiv}
            </Label>
            <div
              style={{margin: "0px 10px 0px 30px"}}>
              หน่วยงานจ่าย
            </div>
            <Label
              name={{margin: "0px 20px 0px 0px"}}>
              {props.editOrderPerformDiv}
            </Label>
          </div>
          <div
            style={{display: "flex", alignItems: "center",marginBottom:"5px"}}>

            <div
              style={{marginLeft: "10px", marginRight: "5px"}}>
              ชื่อหรือรหัสเวชภัณฑ์
            </div>
            <div
              style={{flex: 1, padding: "3px", position: "relative"}}>

              <Input
                id="txt-searchSupplyItem"
                onChange={props.onChangeSearchSupply}
                onKeyDown={(e: any) => { e.key === "Enter" && props.search() }}
                style={{width:"100%"}}
                value={props.searchSupply}>
              </Input>
              <div
                style={{overflowY: "scroll", display: props.showSupplyList ? "block" : "none", width: "99%", height: "200px", position: "absolute", top: 40, zIndex: 1000, backgroundColor: "white", border: "solid #cccccc 1px", opacity: props.debug ? "90%" : "100%"}}>
                {props.supplyList}
              </div>
            </div>
            <Button
              color="blue"
              id="btn-searchSupply"
              onClick={props.search}
              style={{marginLeft: "10px"}}>
              ค้นหาเวชภัณฑ์
            </Button>
          </div>
          <div
            style={{display: props.showAddDivision ? "flex" : "none", alignItems: "center",marginBottom:"10px"}}>

            <div
              style={{marginLeft: "10px", marginRight: "5px"}}>
              ห้องเวชภัณฑ์
            </div>
            <Dropdown
              fluid={true}
              onChange={props.handleChangeValue("order_perform_div")}
              options={props.masterOptions?.divisionSupply}
              search={true}
              selection={true}
              style={{width:"30%"}}
              value={props.selectedPerform}>
            </Dropdown>
            <div
              style={{marginLeft: "10px", marginRight: "5px"}}>
              หน่วยงานขอเบิก
            </div>
            <Dropdown
              fluid={true}
              onChange={props.handleChangeValue("order_div")}
              options={props.masterOptions?.division}
              search={true}
              selection={true}
              style={{width:"30%"}}
              value={props.selectedOrderDiv}>
            </Dropdown>
          </div>
          <div
            style={{display: "none", alignItems: "center", padding: "3px"}}>

            <div
              style={{marginLeft: "10px", marginRight: "5px"}}>
              จำนวน
            </div>
            <Input
              disabled={props.mainQuatityDisabled}
              onChange={props.onChangeQty}
              value={props.qty}>
            </Input>
            <Button
              color="green"
              disabled={props.mainQuatityDisabled}
              onClick={props.addToOrder}
              style={{marginLeft: "10px"}}>
              เพิ่มในรายการสั่งเวชภัณฑ์
            </Button>
          </div>
          <div>

            <Table
              className="ReactTable --rt-tbody-overflow-y-hidden"
              data={props.supplyOrderItems}
              getTdProps={props.getTdPropsSupplyOrder}
              headers="เบิก,No,Code,Mode,Product Name,ขนาดบรรจุ,คงคลัง,จำนวนสั่ง,หน่วยสั่ง,ราคาต่อหน่วย,ราคารวม,เบิกได้,เบิกไม่ได้,ส่วนลด,Eligibility Type,"
              id="tb-supplyOrderList"
              keys="condition,no,code,mode,name,package_size,stock_balance,quantity_request,stock_unit,price_unit,price_total,price_claimable,price_non_claimable,price_discount,eligibility_type,delete"
              minRows="6"
              showPagination={false}
              style={{height: "300px",  ...(props.tableStyle || {})}}
              widths="40,40,90,135,150,80,60,80,80,80,80,80,80,80,140,*">
            </Table>
          </div>
          <div
            style={{margin:"10px"}}>
            {props.errorMessage}
          </div>
          <div
            className="ui form"
            style={{margin: "2rem 0px 2rem 1rem"}}>

            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                style={{flex: 1}}
                width={3}>
                <div
                  style={{width:"100%"}}>

                  <div
                    style={{flex: 1, minWidth: "max-content"}}>
                    {props.isCostCenter?"มูลค่ารวม":"ราคารวม"}
                  </div>
                  <Input
                    fluid={true}
                    readOnly={true}
                    style={{width: "100%", minWidth: "6.5rem"}}
                    value={Number(props.priceTotal||"0").toLocaleString("en-US", {minimumFractionDigits: 2,maximumFractionDigits: 2,})}>
                  </Input>
                </div>
              </FormField>
              <FormField
                inline={true}
                style={{flex: 1, display: props.isCostCenter ? "none": ""}}
                width={3}>
                <div
                  style={{width:"100%"}}>

                  <div
                    style={{ minWidth: "max-content"}}>
                    เบิกได้
                  </div>
                  <Input
                    fluid={true}
                    style={{width: "100%", minWidth: "6.5rem"}}
                    value={Number(props.priceClaimable||"0").toLocaleString("en-US", {minimumFractionDigits: 2,maximumFractionDigits: 2,})}>
                  </Input>
                </div>
              </FormField>
              <FormField
                inline={true}
                style={{flex: 1, display: props.isCostCenter ? "none": ""}}
                width={3}>
                <div
                  style={{width:"100%"}}>

                  <div
                    style={{ minWidth: "max-content"}}>
                    เบิกไม่ได้
                  </div>
                  <Input
                    fluid={true}
                    style={{width: "100%", minWidth: "6.5rem"}}
                    value={Number(props.priceNonClaimable||"0").toLocaleString("en-US", {minimumFractionDigits: 2,maximumFractionDigits: 2,})}>
                  </Input>
                </div>
              </FormField>
              <FormField
                inline={true}
                style={{flex: 1, display: props.isCostCenter ? "none": ""}}
                width={3}>
                <div
                  style={{width:"100%"}}>

                  <div
                    style={{ minWidth: "max-content"}}>
                    ส่วนลด
                  </div>
                  <Input
                    fluid={true}
                    style={{width: "100%", minWidth: "6.5rem"}}
                    value={Number(props.priceDiscount||"0").toLocaleString("en-US", {minimumFractionDigits: 2,maximumFractionDigits: 2,})}>
                  </Input>
                </div>
              </FormField>
              <FormField
                style={{flex: props.isCostCenter ? 1: ""}}>
              </FormField>
              <FormField
                inline={true}>
                <label>
                  {"\u00a0"}
                </label>
                <div
                  style={{display:"flex"}}>

                  <Button
                    color="blue"
                    id="btn-calculateDisbursement"
                    onClick={props.onCalc}
                    size={{minWidth: "max-content"}}
                    style={{ minWidth: "max-content",display: props.isCostCenter ? "none": ""}}>
                    คำนวณเบิก
                  </Button>
                  <div
                    style={{display: !props.status ? "" : "none"}}>
                    {props.buttonSave}
                  </div>
                  <div
                    style={{display:props.status ==="REQUESTED"&& props.status !== "CANCELED"  ? "":"none"}}>
                    {props.buttonEdit}
                  </div>
                  <Button
                    color="red"
                    onClick={props.onCancel}
                    style={{marginLeft: "0.25rem", minWidth: "max-content",display:props.status ==="REQUESTED"&& props.status !== "CANCELED"  ? "":"none"}}>
                    ยกเลิก
                  </Button>
                </div>
              </FormField>
            </FormGroup>
          </div>
        </div>
      </div>
    )
}


export default CardSupplyOrderUX

export const screenPropsDefault = {"debug":true,"showSupplyList":false}

/* Date Time : Mon Jul 08 2024 12:31:34 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ ...(props.style || {}) }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 63,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\",marginBottom:\"5px\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 2,
      "name": "Table",
      "parent": 113,
      "props": {
        "className": {
          "type": "value",
          "value": "ReactTable --rt-tbody-overflow-y-hidden"
        },
        "columns": {
          "type": "code",
          "value": ""
        },
        "data": {
          "type": "code",
          "value": "props.supplyOrderItems"
        },
        "getTdProps": {
          "type": "code",
          "value": "props.getTdPropsSupplyOrder"
        },
        "headers": {
          "type": "value",
          "value": "เบิก,No,Code,Mode,Product Name,ขนาดบรรจุ,คงคลัง,จำนวนสั่ง,หน่วยสั่ง,ราคาต่อหน่วย,ราคารวม,เบิกได้,เบิกไม่ได้,ส่วนลด,Eligibility Type,"
        },
        "id": {
          "type": "value",
          "value": "tb-supplyOrderList"
        },
        "keys": {
          "type": "value",
          "value": "condition,no,code,mode,name,package_size,stock_balance,quantity_request,stock_unit,price_unit,price_total,price_claimable,price_non_claimable,price_discount,eligibility_type,delete"
        },
        "minRows": {
          "type": "value",
          "value": "6"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"300px\",  ...(props.tableStyle || {})}"
        },
        "widths": {
          "type": "value",
          "value": "40,40,90,135,150,80,60,80,80,80,80,80,80,80,140,*"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 62,
      "props": {
        "children": {
          "type": "code",
          "value": " props?.searchHistorySelected ?  props.isCostCenter?\"แก้ไขรายการสั่งเวชภัณฑ์เหมารวมในหัตถการ\":\"แก้ไขรายการสั่งเวชภัณฑ์\" : props.isCostCenter? \"รายการสั่งเวชภัณฑ์เหมารวมในหัตถการ\":\"รายการสั่งเวชภัณฑ์ใหม่\""
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.2rem\",padding: \"10px\"}"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อหรือรหัสเวชภัณฑ์"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"10px\", marginRight: \"5px\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "Input",
      "parent": 8,
      "props": {
        "fluid": {
          "type": "code",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "txt-searchSupplyItem"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeSearchSupply"
        },
        "onKeyDown": {
          "type": "code",
          "value": "(e: any) => { e.key === \"Enter\" && props.search() }"
        },
        "size": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.searchSupply"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "Button",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหาเวชภัณฑ์"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "id": {
          "type": "value",
          "value": "btn-searchSupply"
        },
        "onClick": {
          "type": "code",
          "value": "props.search"
        },
        "size": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"10px\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: 1, padding: \"3px\", position: \"relative\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "code",
          "value": "props.supplyList"
        },
        "style": {
          "type": "code",
          "value": "{overflowY: \"scroll\", display: props.showSupplyList ? \"block\" : \"none\", width: \"99%\", height: \"200px\", position: \"absolute\", top: 40, zIndex: 1000, backgroundColor: \"white\", border: \"solid #cccccc 1px\", opacity: props.debug ? \"90%\" : \"100%\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 63,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"none\", alignItems: \"center\", padding: \"3px\"}"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": "จำนวน"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"10px\", marginRight: \"5px\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "Input",
      "parent": 19,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.mainQuatityDisabled"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeQty"
        },
        "size": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.qty"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "Button",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มในรายการสั่งเวชภัณฑ์"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.mainQuatityDisabled"
        },
        "onClick": {
          "type": "code",
          "value": "props.addToOrder"
        },
        "size": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"10px\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติการสั่งเวชภัณฑ์"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.2rem\",padding: \"10px\",display:props.isEdit ?  \"none\":\"\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 26,
      "name": "Table",
      "parent": 112,
      "props": {
        "className": {
          "type": "value",
          "value": "ReactTable --rt-tbody-overflow-y-hidden"
        },
        "data": {
          "type": "code",
          "value": "props.historySupplyOrder"
        },
        "getTdProps": {
          "type": "code",
          "value": "props.getTdPropsHistory"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getTrPropsHistory"
        },
        "headers": {
          "type": "value",
          "value": "รหัส,ประเภท,สถานะ,วันที่สั่ง,หน่วยงานสั่ง,ผู้ป่วย,แพทย์,วันที่จ่าย,หน่วยงานที่จ่าย,ผู้บันทึกล่าสุด"
        },
        "keys": {
          "type": "value",
          "value": "code,type,status,order_time,order_div,patient,doctor,order_perform_time,order_perform_div,edit_user"
        },
        "minRows": {
          "type": "code",
          "value": "7"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{display:props.isEdit ? \"none \":\"\",height: \"300px\",  ...(props.tableStyle || {})}"
        },
        "widths": {
          "type": "value",
          "value": "105,110,105,130,150,120,150,130,120,*"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"10px\", height: \"10px\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:props.isEdit ? \"none\":\"flex\" , justifyContent:\"row\",alignItems:\"center\", padding: \"0px 0px 20px 0px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "Checkbox",
      "parent": 28,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.OrderSupplySequence?.isUseRangeSearch"
        },
        "label": {
          "type": "value",
          "value": "วันที่สั่ง"
        },
        "onClick": {
          "type": "code",
          "value": "props.handleToggleUseRange"
        },
        "style": {
          "type": "code",
          "value": "{padding:\"0px 30px\",minWidth:\"max-content\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 30,
      "name": "DateTextBox",
      "parent": 28,
      "props": {
        "disabled": {
          "type": "code",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleFromHistory"
        },
        "style": {
          "type": "code",
          "value": "{padding:\"0px 30px 0px 60px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.OrderSupplySequence?.searchFromHistorySearch"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 31,
      "name": "DateTextBox",
      "parent": 28,
      "props": {
        "disabled": {
          "type": "code",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleToHistory"
        },
        "style": {
          "type": "code",
          "value": "{padding:\"0px 30px 0px 0px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.OrderSupplySequence?.searchToHistorySearch"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "Button",
      "parent": 28,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "loading": {
          "type": "code",
          "value": "props.OrderSupplySequence?.searchBtnLoading"
        },
        "name": {
          "type": "value",
          "value": ""
        },
        "onClick": {
          "type": "code",
          "value": "props.handleHistorySearch"
        },
        "style": {
          "type": "code",
          "value": "{width: \"200px\"}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 38,
      "props": {
        "children": {
          "type": "code",
          "value": "props.paginationComponent"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"right\"}"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:props.isEdit ? \"none\":\"flex\" , justifyContent: \"space-between\", margin: \"20px 10px\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "Button",
      "parent": 57,
      "props": {
        "children": {
          "type": "value",
          "value": "HOME_OPD"
        },
        "className": {
          "type": "code",
          "value": "props.supplyOrderType === \"HOME_OPD\" ? \"active\" : \"\""
        },
        "color": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "HOME_OPD"
        },
        "onClick": {
          "type": "code",
          "value": "props.onNewSupplyOrder"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{...(!props.opdHomeDisplay && {display: \"none\"})}"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 63,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props?.searchHistorySelected  ? \"flex\" :\"none\" ,alignItems:\"center\", margin: \"10px 10px\", fontWeight: \"normal\", fontSize: \"1.2rem\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขที่เอกสาร"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 10px 0px 0px\"}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": "encounter"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 10px 0px 30px\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "div",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": "แพทย์"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 10px 0px 30px\"}"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "div",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": "หน่วยงานสั่ง"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 10px 0px 30px\"}"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "div",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": "หน่วยงานจ่าย"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 10px 0px 30px\"}"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "Label",
      "parent": 40,
      "props": {
        "children": {
          "type": "code",
          "value": "props.editCode"
        },
        "name": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "Label",
      "parent": 40,
      "props": {
        "children": {
          "type": "code",
          "value": "props.editEncounter"
        },
        "name": {
          "type": "code",
          "value": "{margin: \"0px 20px 0px 0px\"}"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "Label",
      "parent": 40,
      "props": {
        "children": {
          "type": "code",
          "value": "props.editDoctor"
        },
        "name": {
          "type": "code",
          "value": "{margin: \"0px 20px 0px 0px\"}"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "Label",
      "parent": 40,
      "props": {
        "children": {
          "type": "code",
          "value": "props.editOrderDiv"
        },
        "name": {
          "type": "code",
          "value": "{margin: \"0px 20px 0px 0px\"}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "Label",
      "parent": 40,
      "props": {
        "children": {
          "type": "code",
          "value": "props.editOrderPerformDiv"
        },
        "name": {
          "type": "code",
          "value": "{margin: \"0px 20px 0px 0px\"}"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 56,
      "name": "Label",
      "parent": 57,
      "props": {
        "children": {
          "type": "value",
          "value": "เริ่มสั่งเวชภัณฑ์ชุดใหม่"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "color": {
          "type": "code",
          "value": ""
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"40px\"}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 38,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", justifyContent:\"space-between\", alignItems:\"center\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "Button",
      "parent": 57,
      "props": {
        "children": {
          "type": "value",
          "value": "REFILL_OPD"
        },
        "className": {
          "type": "code",
          "value": "props.supplyOrderType === \"REFILL_OPD\" ? \"active\" : \"\""
        },
        "color": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "REFILL_OPD"
        },
        "onClick": {
          "type": "code",
          "value": "props.onNewSupplyOrder"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{...(!props.opdRefillDisplay && {display: \"none\"})}"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "div",
      "parent": 63,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", justifyContent:\"space-between\",alignItems:\"center\",padding:\"0px 10px\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "Label",
      "parent": 62,
      "props": {
        "children": {
          "type": "code",
          "value": "props.titleSupplyOrder"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "color": {
          "type": "code",
          "value": "props.isStatus && props.ribbonColor ?  props.ribbonColor : \"gray\""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "div",
      "parent": 59,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", alignItems:\"center\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "props.styleEditSupply"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": null,
      "id": 64,
      "name": "div",
      "parent": 59,
      "props": {
        "children": {
          "type": "code",
          "value": "props.statusSupplyOrder"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "Button",
      "parent": 57,
      "props": {
        "children": {
          "type": "value",
          "value": "REFILL_IPD"
        },
        "className": {
          "type": "code",
          "value": "props.supplyOrderType === \"REFILL_IPD\" ? \"active\" : \"\""
        },
        "name": {
          "type": "value",
          "value": "REFILL_IPD"
        },
        "onClick": {
          "type": "code",
          "value": "props.onNewSupplyOrder"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{...(!props.ipdRefillDisplay && {display: \"none\"})}"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "Button",
      "parent": 57,
      "props": {
        "children": {
          "type": "value",
          "value": "HOME_IPD"
        },
        "className": {
          "type": "code",
          "value": "props.supplyOrderType === \"HOME_IPD\" ? \"active\" : \"\""
        },
        "name": {
          "type": "value",
          "value": "HOME_IPD"
        },
        "onClick": {
          "type": "code",
          "value": "props.onNewSupplyOrder"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{...(!props.ipdHomeDisplay && {display: \"none\"})}"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "Button",
      "parent": 57,
      "props": {
        "children": {
          "type": "value",
          "value": "ONE_DAY"
        },
        "className": {
          "type": "code",
          "value": "props.supplyOrderType === \"ONE_DAY\" ? \"active\" : \"\""
        },
        "name": {
          "type": "value",
          "value": "ONE_DAY"
        },
        "onClick": {
          "type": "code",
          "value": "props.onNewSupplyOrder"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{...(!props.ipdOneDayDisplay && {display: \"none\"})}"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "Button",
      "parent": 57,
      "props": {
        "children": {
          "type": "value",
          "value": "STAT"
        },
        "className": {
          "type": "code",
          "value": "props.supplyOrderType === \"STAT\" ? \"active\" : \"\""
        },
        "name": {
          "type": "value",
          "value": "STAT"
        },
        "onClick": {
          "type": "code",
          "value": "props.onNewSupplyOrder"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{...(!props.isStatDisplay && {display: \"none\"})}"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "div",
      "parent": 63,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.showAddDivision ? \"flex\" : \"none\", alignItems: \"center\",marginBottom:\"10px\"}"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "div",
      "parent": 72,
      "props": {
        "children": {
          "type": "value",
          "value": "ห้องเวชภัณฑ์"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"10px\", marginRight: \"5px\"}"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "Dropdown",
      "parent": 72,
      "props": {
        "clearable": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"order_perform_div\")"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.divisionSupply"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width:\"30%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedPerform"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "div",
      "parent": 72,
      "props": {
        "children": {
          "type": "value",
          "value": "หน่วยงานขอเบิก"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"10px\", marginRight: \"5px\"}"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "Dropdown",
      "parent": 72,
      "props": {
        "clearable": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"order_div\")"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.division"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width:\"30%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedOrderDiv"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "div",
      "parent": 63,
      "props": {
        "children": {
          "type": "code",
          "value": "props.errorMessage"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"10px\"}"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "div",
      "parent": 63,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"2rem 0px 2rem 1rem\"}"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 84,
      "name": "FormGroup",
      "parent": 83,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 85,
      "name": "FormField",
      "parent": 84,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 87,
      "name": "FormField",
      "parent": 84,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{flex: 1, display: props.isCostCenter ? \"none\": \"\"}"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 89,
      "name": "FormField",
      "parent": 84,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{flex: 1, display: props.isCostCenter ? \"none\": \"\"}"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 91,
      "name": "FormField",
      "parent": 84,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{flex: 1, display: props.isCostCenter ? \"none\": \"\"}"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 93,
      "name": "FormField",
      "parent": 84,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "code",
          "value": "props.isCostCenter?\"มูลค่ารวม\":\"ราคารวม\""
        },
        "style": {
          "type": "code",
          "value": "{flex: 1, minWidth: \"max-content\"}"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": null,
      "id": 98,
      "name": "div",
      "parent": 116,
      "props": {
        "children": {
          "type": "value",
          "value": "เบิกได้"
        },
        "style": {
          "type": "code",
          "value": "{ minWidth: \"max-content\"}"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 99,
      "name": "div",
      "parent": 117,
      "props": {
        "children": {
          "type": "value",
          "value": "เบิกไม่ได้"
        },
        "style": {
          "type": "code",
          "value": "{ minWidth: \"max-content\"}"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": null,
      "id": 100,
      "name": "div",
      "parent": 118,
      "props": {
        "children": {
          "type": "value",
          "value": "ส่วนลด"
        },
        "style": {
          "type": "code",
          "value": "{ minWidth: \"max-content\"}"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 102,
      "name": "Button",
      "parent": 114,
      "props": {
        "children": {
          "type": "value",
          "value": "คำนวณเบิก"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "id": {
          "type": "value",
          "value": "btn-calculateDisbursement"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCalc"
        },
        "size": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        },
        "style": {
          "type": "code",
          "value": "{ minWidth: \"max-content\",display: props.isCostCenter ? \"none\": \"\"}"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 103,
      "name": "Button",
      "parent": 114,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิก"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancel"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"0.25rem\", minWidth: \"max-content\",display:props.status ===\"REQUESTED\"&& props.status !== \"CANCELED\"  ? \"\":\"none\"}"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 104,
      "name": "Input",
      "parent": 115,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", minWidth: \"6.5rem\"}"
        },
        "value": {
          "type": "code",
          "value": "Number(props.priceTotal||\"0\").toLocaleString(\"en-US\", {minimumFractionDigits: 2,maximumFractionDigits: 2,})"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 105,
      "name": "Input",
      "parent": 116,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", minWidth: \"6.5rem\"}"
        },
        "value": {
          "type": "code",
          "value": "Number(props.priceClaimable||\"0\").toLocaleString(\"en-US\", {minimumFractionDigits: 2,maximumFractionDigits: 2,})"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 106,
      "name": "Input",
      "parent": 117,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", minWidth: \"6.5rem\"}"
        },
        "value": {
          "type": "code",
          "value": "Number(props.priceNonClaimable||\"0\").toLocaleString(\"en-US\", {minimumFractionDigits: 2,maximumFractionDigits: 2,})"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 107,
      "name": "Input",
      "parent": 118,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", minWidth: \"6.5rem\"}"
        },
        "value": {
          "type": "code",
          "value": "Number(props.priceDiscount||\"0\").toLocaleString(\"en-US\", {minimumFractionDigits: 2,maximumFractionDigits: 2,})"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": null,
      "id": 109,
      "name": "div",
      "parent": 114,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonEdit"
        },
        "style": {
          "type": "code",
          "value": "{display:props.status ===\"REQUESTED\"&& props.status !== \"CANCELED\"  ? \"\":\"none\"}"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 110,
      "name": "Button",
      "parent": 57,
      "props": {
        "children": {
          "type": "value",
          "value": "Cost center"
        },
        "className": {
          "type": "code",
          "value": "props.supplyOrderType === \"COST_CENTER\" ? \"active\" : \"\""
        },
        "name": {
          "type": "value",
          "value": "COST_CENTER"
        },
        "onClick": {
          "type": "code",
          "value": "props.onNewSupplyOrder"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{...(!props.costCenterDisplay && {display: \"none\"})}"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 111,
      "name": "FormField",
      "parent": 84,
      "props": {
        "style": {
          "type": "code",
          "value": "{flex: props.isCostCenter ? 1: \"\"}"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": null,
      "id": 112,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 113,
      "name": "div",
      "parent": 63,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": null,
      "id": 114,
      "name": "div",
      "parent": 93,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\"}"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": null,
      "id": 115,
      "name": "div",
      "parent": 85,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": null,
      "id": 116,
      "name": "div",
      "parent": 87,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": null,
      "id": 117,
      "name": "div",
      "parent": 89,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": null,
      "id": 118,
      "name": "div",
      "parent": 91,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": null,
      "id": 119,
      "name": "label",
      "parent": 93,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": null,
      "id": 120,
      "name": "div",
      "parent": 114,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "style": {
          "type": "code",
          "value": "{display: !props.status ? \"\" : \"none\"}"
        }
      },
      "seq": 109,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardSupplyOrderUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "debug": true,
    "showSupplyList": false
  },
  "width": 75
}

*********************************************************************************** */
