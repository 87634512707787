import React, { useState, useMemo, useEffect, useRef } from "react";
import {
  serial_to_datestr,
  get_week_slots_display,
  get_startofweek_serial,
  DivisionServiceBlock,
  serial_to_hour,
  QueueController,
  TIME_CONST
} from "./Time";
import Cookies from "js-cookie";
import { useHistory, RouteComponentProps } from "react-router-dom";
import ServiceSlotSelect from "./ServiceSlotSelect";
import HourRangeDropdown from "react-lib/apps/common/HourRangeDropdown";
import DateTextBox from "react-lib/apps/common/DateTextBox";
import ModInfo from "react-lib/apps/common/ModInfo";
import { Dimmer, Loader, Button, Input } from "semantic-ui-react";
import moment from "moment"
import { parseDate, formatADtoBEString } from "../../utils/dateUtils";
import { useIntl } from "react-intl";

interface RouterProps {
  id: string;
  weekStartSerial: string;
}

interface TimeDoctorProps extends RouteComponentProps<RouterProps> {
  apiToken: string;
  controller: QueueController & { prxManager: any; getProviderInfo: any };
  providerId?: number;
  // config
  allowChangeDate?: boolean;
  selectBeforeSave?: boolean;
  displayUniqueDivision?: boolean;
  isNurse?: boolean;
}

const DATE_FORMAT = "YYYY-MM-DD";

const TimeDoctorEdit = (props: TimeDoctorProps) => {
  const intl = useIntl();
  const [dayIndex, setDayIndex] = useState(0);
  const [weekStartSerial, setWeekStartSerial] = useState(
    get_startofweek_serial()
  );
  const [serviceSlots, setServiceSlots] = React.useState<any[]>([]);
  const [divisionServiceBlocks, setDivisionServiceBlocks] = React.useState<
    DivisionServiceBlock[]
  >([]);
  const [doctorDivisions, setDoctorDivisions] = React.useState<any[]>([]);
  const [minHour, setMinHour] = React.useState(0);
  const [maxHour, setMaxHour] = React.useState(24);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedServiceSlot, setSelectedServiceSlot] = useState<{ create?: any[]; update?: any[] }>({})
  const [openModInfo, setOpenModInfo] = useState<boolean>(false)
  const isMounted = useRef(true);
  const history = useHistory();

  const providerIdRef = useRef<any>(null)

  const min_hour = Math.min(
    ...divisionServiceBlocks.map((dsb, index) =>
      serial_to_hour(dsb.start_serial)
    )
  );
  const max_hour = Math.max(
    ...divisionServiceBlocks.map((dsb, index) => serial_to_hour(dsb.end_serial))
  );

  const week_slots_display = get_week_slots_display(
    doctorDivisions,
    serviceSlots,
    weekStartSerial,
    divisionServiceBlocks
  );

  useEffect(() => {
    setMinHour(min_hour);
  }, [min_hour]);

  useEffect(() => {
    if (max_hour > 0) {
      setMaxHour(max_hour);
    }
  }, [max_hour]);

  useEffect(() => {
    handleGetDivisionList();
    handleLoadServiceSlot();

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!isNaN(parseInt(props.match.params.id))) {
      setDayIndex(parseInt(props.match.params.id));
    } else {
      history.push("/Schedule/");
    }
  }, [props.match.params.id]);

  useEffect(() => {
    if (!isNaN(parseInt(props.match.params.weekStartSerial))) {
      setWeekStartSerial(parseInt(props.match.params.weekStartSerial));
    } else {
      history.push("/Schedule/");
    }
  }, [props.match.params.weekStartSerial]);

  const handleGetDivisionList = async () => {
    setIsLoading(true);
    const [
      response,
      error,
      network
    ] = await props.controller.getDivisionHasUser({
      apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken")
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (response) {
        let item = response.items.map(
          (d: { division: { id: number | string } }) => d.division.id
        );
        setDoctorDivisions(item);
      } else {
        setDoctorDivisions([]);
      }
    }
  };

  const handleSelectedServiceSlot = (action: "create" | "update", data: any) => {
    const cloneData = { ...selectedServiceSlot };
    const divisions = data.divisions;

    delete data.divisions;

    if (!(action in cloneData)) {
      cloneData[action] = []
    }

    const findIndex = (cloneData[action] || [])?.findIndex(
      (item: any) => item.serial === data.serial
    );

    if (findIndex !== -1) {
      cloneData[action]?.splice(findIndex, 1, data);
    } else {
      if (!(action === "update" && !data.pk)) {
        cloneData[action]?.push(data);
      }
    }

    let cloneArray = [...serviceSlots];
    const findSlotIndex = cloneArray.findIndex(
      (item: any) => item.serial === data.serial
    );

    const updateData = {
      id: data.pk || null,
      division: data.division || null,
      division_name:
        divisions.find((item: any) => item.id === data.division)?.name || null,
      provider: data.provider,
      serial: data.serial,
      status: data.status,
    };

    if (findSlotIndex === -1) {
      cloneArray = [...cloneArray, updateData];
    } else {
      if (!data.pk && !data.division) {
        cloneArray = cloneArray.filter(
          (item: any) => item.serial !== data.serial
        );
      } else {
        cloneArray?.splice(findSlotIndex, 1, updateData);
      }
    }

    setServiceSlots(cloneArray)
    setSelectedServiceSlot(cloneData);

  };

  const handleCreateServiceSlot = async ({
    serial,
    status,
    provider,
    division,
    divisions,
  }: {
    serial?: any;
    status?: any;
    provider?: any;
    division?: any;
    divisions?: any[]
  } = {}) => {
    if (props.selectBeforeSave) {
      handleSelectedServiceSlot("create", {
        serial,
        status,
        provider,
        division,
        divisions,
      });
      return;
    }

    setIsLoading(true);
    const [response, error, network] = await props.controller.createServiceSlot(
      {
        apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
        serial,
        status,
        provider,
        division,
      }
    );
    if (isMounted.current) {
      setIsLoading(false);
      handleLoadServiceSlot();
    }
  };

  const handleUpdateServiceSlot = async ({
    pk,
    serial,
    status,
    provider,
    division,
    divisions,
  }: {
    pk?: any;
    serial?: any;
    status?: any;
    provider?: any;
    division?: any;
    divisions?: any[]
  } = {}) => {
    if (props.selectBeforeSave) {
      handleSelectedServiceSlot("update", {
        pk,
        serial,
        status,
        provider,
        division,
        divisions,
      });
      return;
    }

    setIsLoading(true);
    const [response, error, network] = await props.controller.updateServiceSlot(
      {
        apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
        pk,
        serial,
        status,
        provider,
        division,
      }
    );
    if (isMounted.current) {
      setIsLoading(false);
      handleLoadServiceSlot();
    }
  };

  const handleGetProviderId = async () => {
    const params = new URLSearchParams(props.location.search);
    const userId = params.get("user");
    let providerId = props.providerId ? props.providerId : Cookies.get("providerId");

    if (userId) {
      if (props.isNurse) {
        const [response] =
          await props.controller.prxManager.getOfficialAccountDoctor({
            divisionId: Cookies.get("division_id"),
            apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
          });
        const checkUserId = !!(response?.items || []).find((item: any) => item.user === Number(userId));

        if (checkUserId) {
          const [response, error] = await props.controller.getProviderInfo({
            apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
            userId,
          });

          providerId = response?.id
        } else {
          history.replace(history.location.pathname)
        }
      } else {
        history.replace(history.location.pathname)
      }
    }

    providerIdRef.current = providerId
  }

  const handleLoadServiceSlot = async () => {
    setIsLoading(true);

    if (!providerIdRef.current) {
      await handleGetProviderId()
    }

    const [response, error, network] = await props.controller.loadServiceSlot({
      apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
      providerId: providerIdRef.current,
      fromSerial: weekStartSerial,
      toSerial: weekStartSerial + 96 * 7
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (response) {
        setServiceSlots(response.items);
      } else {
        setServiceSlots([]);
      }
    }
  };

  React.useEffect(() => {
    if (doctorDivisions.length > 0) {
      handleLoadDoctorDivisionServiceBlock();
    }
  }, [doctorDivisions, weekStartSerial]);

  React.useEffect(() => {
    handleLoadServiceSlot();
  }, [weekStartSerial]);

  const serialDateStr = useMemo(() => {
    return serial_to_datestr(weekStartSerial + dayIndex * 96, "date", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }, [weekStartSerial, dayIndex]);

  const filterDaySlots = useMemo(() => {
    return week_slots_display?.[dayIndex]?.filter(
      (d: any) =>
        serial_to_hour(d.serial) >= minHour &&
        serial_to_hour(d.serial) < maxHour
    );
  }, [week_slots_display, dayIndex, minHour, maxHour]);

  const handleLoadDoctorDivisionServiceBlock = async () => {
    setIsLoading(true);
    const [
      response,
      error,
      network
    ] = await props.controller.loadDivisionServiceBlock({
      apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
      from_serial: weekStartSerial,
      to_serial: weekStartSerial + 96 * 7,
      divisions: doctorDivisions.toString()
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (response) {
        setDivisionServiceBlocks(response.items);
      } else {
        setDivisionServiceBlocks([]);
      }
    }
  };

  const setWeekSerial = async (type: "back" | "next") => {
    let addDay = 0
    if (type === "back") {
      addDay = -1;
    } else if (type === "next") {
      addDay = 1;
    }

    handleChangeDate(formatADtoBEString(
      moment(serialDateStr).add(addDay, "days").format(DATE_FORMAT)
    ) || "")
  };

  const handleChangeDate = (date: string) => {
    const currDate = moment(serialDateStr);
    const targetDate = parseDate(date, true) as moment.Moment

    const diff = moment.duration(targetDate.diff(currDate));
    let weeks =
      Math.floor(Math.abs(diff.asWeeks())) * Math.sign(diff.asWeeks());
    const days = diff.asDays();
    const diffDay = dayIndex + days;

    if (weeks === 0) {
      weeks = diffDay < 0 ? (weeks -= 1) : diffDay > 6 ? (weeks += 1) : weeks;
    }

    if (weeks === 0) {
      history.replace(`/ManageSchedule/${diffDay}/${weekStartSerial}`);
    } else {
      let unit = TIME_CONST.UNIT_PER_WEEK;
      let day = targetDate.day()
      if (day === 0) {
        day = 7
      }

      history.replace(
        `/ManageSchedule/${day - 1}/${weekStartSerial + unit * weeks}`
      );
    }
  }

  const handleSaveServiceSlot = async () => {
    setIsLoading(true);

    for await (let data of (selectedServiceSlot?.create || [])) {
      await props.controller.createServiceSlot({
        apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
        ...data,
      });
    }

    for await (let data of (selectedServiceSlot?.update || [])) {
      await props.controller.updateServiceSlot({
        apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
        ...data
      })
    }

    setSelectedServiceSlot({})
    setOpenModInfo(true)
    handleLoadServiceSlot();
  }

  const handleCloseModInfo = () => {
    setOpenModInfo(false)
  }

  return (
    <div className="TimeDoctorEdit">
      <Dimmer.Dimmable
        dimmed={isLoading}
        style={{ height: "100%" }}
        className="MainLayout"
      >
        <Dimmer active={isLoading} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
        {!props.allowChangeDate ? (
          <div className="dateHeader">
            <h3>
              {serial_to_datestr(
                weekStartSerial + dayIndex * 96,
                "localeDate",
                {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }
              )}
            </h3>
          </div>
        ) : (
          <div className="dateHeader">
            <Button compact content="<" onClick={() => setWeekSerial("back")} />
            <div className="groupDate">
              <DateTextBox
                value={formatADtoBEString(
                  moment(serialDateStr).format(DATE_FORMAT)
                )}
                onChange={handleChangeDate}
              />
              <Input
                icon="calendar"
                placeholder=""
                value={serial_to_datestr(
                  weekStartSerial + dayIndex * 96,
                  "localeDate",
                  {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  }
                )}
              />
            </div>
            <Button compact content=">" onClick={() => setWeekSerial("next")} />
          </div>
        )}

        <div className="content">
          <h2>
            เพิ่ม/แก้ไขตารางออกตรวจ
            <span className="header5">
              &ensp; ช่วงเวลา&ensp;
              <HourRangeDropdown
                onChange={(e: any) => setMinHour(e.currentTarget.value)}
                defaultValue={minHour}
              />
              &ensp; ถึง &ensp;{" "}
              <HourRangeDropdown
                onChange={(e: any) => setMaxHour(e.currentTarget.value)}
                defaultValue={maxHour}
              />
            </span>
          </h2>
          <div className="calendarContent">
            <ServiceSlotSelect
              day_slots_display={week_slots_display[dayIndex]}
              divisions={doctorDivisions}
              createServiceSlot={handleCreateServiceSlot}
              updateServiceSlot={handleUpdateServiceSlot}
              provider={
                providerIdRef.current
              }
              min_hour={minHour}
              max_hour={maxHour}
              displayUniqueDivision={props.displayUniqueDivision}
            />
          </div>

          {props.selectBeforeSave && filterDaySlots?.[0] && (
            <div className="box-btn-save">
              <Button
                disabled={
                  !(
                    selectedServiceSlot?.create?.[0] ||
                    selectedServiceSlot?.update?.[0]
                  )
                }
                onClick={handleSaveServiceSlot}
              >{intl.formatMessage({ id: "บันทึก" })}</Button>
            </div>
          )}

          <ModInfo
            open={openModInfo}
            color="green"
            closeOnDimmerClick
            onClose={handleCloseModInfo}
            onApprove={handleCloseModInfo}
          >
            <div style={{ fontWeight: "bold", margin: "-5px 0 15px" }}>{intl.formatMessage({ id: "ท่านบันทึกตารางออกตรวจสำเร็จแล้ว" })}</div>
          </ModInfo>
        </div>
      </Dimmer.Dimmable>
    </div>
  );
};

export default React.memo(TimeDoctorEdit);
