import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
  useCallback,
} from "react";
import {
  Dropdown,
  Checkbox,
  Pagination,
  PaginationProps,
  DropdownProps,
  Button,
  Icon,
  Modal,
  Header,
} from "semantic-ui-react";
import ReactTable from "react-table-6";
import { useIntl } from "react-intl";
import _ from "react-lib/compat/lodashplus"

interface CardCSLabProps {
  patientDataAPI: any;
  handleCSLabTable: any;
  handleGetCSLabList: any;
  allLabDivisionList: any;
  csLabList: any;
  labList: any;
}

const CardCSLab = forwardRef((props: CardCSLabProps, ref) => {
  const isMounted = useRef(true);
  const [isCSLabTableLoading, setIsCSLabTableLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [checkLab, setCheckLab] = useState(true);
  const [checkXray, setCheckXray] = useState(true);
  const [checkAll, setCheckAll] = useState(true);
  const [divisionId, setDivisionId] = useState<number>(0);
  const limit = 9;
  const [openComment, setOpenComment] = useState(false);
  const [selectedLab, setSelectedLab] = useState(null);

  useImperativeHandle(ref, () => ({
    getData: () => {
      // console.log(" useImperativeHandle CardCSLab", division);
      var isLab = null;
      var isXray = null;
      var division_search = divisionId;

      if (divisionId === 0) {
        division_search = null;
      }

      if (checkLab) {
        isLab = true;
      }
      if (checkXray) {
        isXray = true;
      }

      // Guard
      if (checkLab && checkXray) {
        isXray = null;
        isLab = null;
      }

      return {
        ...(isLab && { isLab: true }),
        ...(isXray && { isXray: true }),
        ...(checkLab && division_search && { division_search: division_search }),
        // limit: limit,
        // page: activePage - 1,
      };
    },
  }));

  const checkNormalRange = (lab) => {
    const max = lab.lab_reference_value_max;
    const min = lab.lab_reference_value_min;
    const text = lab.lab_reference_value_text;
    const result = lab.lab_result_value;
    // Normal
    if (result <= max && result >= min && min !== null && max !== null) {
      return <Icon size="large" name="check" color="green" />;
    }
    if (result > max && max !== null) {
      return <Icon size="large" name="arrow up" color="red" />;
    }
    if (result < min && min !== null) {
      return <Icon size="large" name="arrow down" color="red" />;
    }
    // Normal
    if (result === text && text !== null) {
      return <Icon size="large" name="check" color="green" />;
    }
    return null;
  };

  const formatChildLab = (labChild) => {
    return (
      <div className="cs-child-lab-row">
        <div className="cs-lab-name">
          <Icon className="cs-rotate-90" name="level up alternate" />
          <div className="cs-lab-title">
            <div className="cs-break-word cs-bold">{labChild?.lab_name}</div>
            {labChild?.lab_reference_value_min !== null &&
              labChild?.lab_reference_value_max !== null ? (
              <div className="cs-normal-range-color">
                ({labChild?.lab_reference_value_min} -{" "}
                {labChild?.lab_reference_value_max})
              </div>
            ) : labChild?.lab_reference_value_text !== null ? (
              <div className="cs-normal-range-color">
                {labChild?.lab_reference_value_text}{" "}
              </div>
            ) : (
              <div> </div>
            )}
          </div>
        </div>

        <div className="cs-lab-value">
          {labChild?.lab_is_confidential ? (
            <span className="cs-confidential-color cs-flex">
              <Icon size="large" name="envelope" color="orange" />
              ปกปิด{" "}
            </span>
          ) : (
            <div className="cs-shrink-0">
              {checkNormalRange(labChild)}{" "}
              <span className="cs-bold">
                {labChild?.lab_result_value}{" "}
              </span>
            </div>
          )}
          <div className="cs-shrink-1 cs-break-word ">{labChild?.lab_unit}</div>
        </div>
      </div>
    );
  };

  const formatLabName = (labValue) => {
    if (labValue.product_type === "LAB") {
      return (
        <>
          <div className="cs-lab-type">
            {labValue.lab_name} <span className="cs-lab-code"> ({labValue.lab_code}){" "} </span>
          </div>
          <div>
            {labValue?.children?.length === 0 ? formatChildLab(labValue) : null}
            {labValue?.children?.length > 0
              ? labValue?.children?.map((item) => formatChildLab(item))
              : null}
          </div>
        </>
      );
    } else if (labValue?.product_type === "XRAY") {
      return (
        <div className="cs-xray-type cs-break-word">
          {labValue?.lab_name}<span className="cs-lab-code"> ({labValue?.lab_code})</span>
        </div>
      );
    }
  };

  const formatComment = (labValue) => {
    if (labValue?.product_type === "LAB") {
      return (
        <div className="cs-text-align-center cs-margin-top">
          {labValue?.children?.length === 0 && labValue?.lab_comment ? (
            <Icon
              size="large"
              name="warning sign"
              color="red"
              onClick={() => {
                setOpenComment(true);
                setSelectedLab(labValue);
              }}
            />
          ) : null}
          {labValue?.children?.length > 0 &&
            labValue?.children?.filter((item) => item.lab_comment).length > 0 ? (
            <Icon
              size="large"
              name="warning sign"
              color="red"
              onClick={() => {
                setOpenComment(true);
                setSelectedLab(labValue);
              }}
            />
          ) : null}
        </div>
      );
    } else if (labValue?.product_type === "XRAY") {
      return (
        <Button
          style={{ width: "min-content" }}
          size="mini"
          color="blue"
          onClick={() => {
            window.open(labValue?.access_x_ray_link, "_blank");
          }}
        >
          {" "}
          PACS{" "}
        </Button>
      );
    }
  };

  const columns = [
    {
      Header: "Laboratory",
      accessor: "finished_time", // String-based value accessors!
      resizeable: false,
      Cell: (props) => <>{props.value.split(" ").join("\n")} </>,
      width: 90,
    },
    {
      Header: "",
      resizeable: false,
      Cell: (props) => formatLabName(props.original),
    },
    {
      Header: "Comment",
      resizeable: false,
      width: 90,
      Cell: (props) => formatComment(props.original),
    },
  ];

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const loadLab = async () => {
    setIsCSLabTableLoading(true);
    await props.handleGetCSLabList();
    setIsCSLabTableLoading(false);
  };

  useEffect(() => {
    // console.log("useEffect CardCSLab ");
    debounceClicking()
  }, [
    props.patientDataAPI,
    props.handleGetCSLabList,
    checkLab,
    checkXray,
    divisionId,
  ]);

  let divisionOptions =
    props.allLabDivisionList && props.allLabDivisionList.items
      ? props.allLabDivisionList.items.map((item) => {
        return {
          key: item.id,
          value: item.id,
          text: item.name,
        };
      })
      : [];

  divisionOptions.unshift({
    key: 0,
    value: 0,
    text: "ALL",
  });

  const debounceClicking = useCallback(_.debounce(() => {
    loadLab()
  }, 2000, { leading: false }),
    []
  )

  // console.log("props.csLabList: ", props.csLabList);

  return (
    <>
      {openComment ? (
        <Modal
          closeIcon
          open={openComment}
          onClose={() => setOpenComment(false)}
        >
          <Header icon="write" content={selectedLab.lab_name} color="blue" />
          <Modal.Content>
            <p>
              {selectedLab.lab_comment ? (
                <div>
                  {" "}
                  <div style={{ fontWeight: "bold", color: "#2F80ED" }}>
                    {selectedLab.lab_name}:{" "}
                  </div>{" "}
                  {selectedLab.lab_comment}{" "}
                </div>
              ) : null}

              {selectedLab?.children?.filter((item) => item.lab_comment)
                .length > 0 ? (
                <>
                  {selectedLab?.children
                    ?.filter((item) => item.lab_comment)
                    .map((item) => {
                      return (
                        <div>
                          {" "}
                          <span
                            style={{ fontWeight: "bold", color: "#2F80ED" }}
                          >
                            {" "}
                            {item.lab_name}:{" "}
                          </span>{" "}
                          <span style={{ fontWeight: "bolder" }}>
                            {" "}
                            {item.lab_comment}{" "}
                          </span>{" "}
                        </div>
                      );
                    })}
                </>
              ) : null}
            </p>
          </Modal.Content>
        </Modal>
      ) : null}

      <div className="card-cs card-lab">
        <div className="card-cs-header">
          <div className="card-cs-header-title">Lab Division</div>
          <Dropdown
            disabled={isCSLabTableLoading || !checkLab}
            placeholder="ALL"
            fluid
            selection
            options={divisionOptions}
            onChange={(e, data: DropdownProps) => {
              console.log("divisionOptions", divisionOptions)
              console.log("onChange data", data)
              setDivisionId(data?.value as number);
            }}
            value={divisionId}
            style={{ width: "50%" }}
          />
          <Checkbox
            disabled={isCSLabTableLoading}
            checked={checkLab}
            label="Lab"
            style={{ fontSize: "x-small" }}
            onClick={(e, { checked }) => {
              if (checked) {
                if (checkXray) {
                  setCheckAll(true)
                }
                setCheckLab(true);
              } else if (!checked) {
                if (checkAll) {
                  setCheckAll(false)
                }
                if (checkXray) {
                  setCheckLab(false)
                }
              }
            }}
          />
          <Checkbox
            disabled={isCSLabTableLoading}
            checked={checkXray}
            label="Xray"
            style={{ fontSize: "x-small" }}
            onChange={(e, { checked }) => {
              if (checked) {
                if (checkLab) {
                  setCheckAll(true)
                }
                setCheckXray(true)
              } else if (!checked) {
                if (checkAll) {
                  setCheckAll(false)
                }
                if (checkLab) {
                  setCheckXray(false)
                }
              }
            }}
          />
          <Checkbox
            disabled={isCSLabTableLoading}
            checked={checkAll}
            label="All"
            style={{ fontSize: "x-small" }}
            onChange={(e, { checked }) => {
              if (checked) {
                setCheckXray(checked)
                setCheckLab(checked)
                setCheckAll(checked)
              }
            }}
          />
        </div>
        <div className="card-cs-header-title">Lab History</div>
        <div className="card-wrap-cs-table">
          <ReactTable
            className="card-cs-table"
            loading={isCSLabTableLoading}
            data={props.csLabList}
            columns={columns}
            style={{ height: "100%" }}
            showPagination={false}
            minRows={12}
            NoDataComponent={() => null}
            getTheadThProps={(state, rowInfo, column, instance) => {
              // console.log(" getTdProps", state, rowInfo, column, instance)
              return {
                style: {
                  fontSize: "12px",
                  border: "none",
                  boxShadow: "none",
                  textAlign: "left",
                },
              };
            }}
            getTdProps={(state, rowInfo, column, instance) => {
              // console.log(" getTdProps", state, rowInfo, column, instance)
              let style = {
                fontSize: "12px",
                border: "none",
                boxShadow: "none",
                color: "#4F4F4F",
              };
              if (
                column.Header === "Order Type" ||
                column.Header === "Division"
              ) {
                style = { ...style, ...{ fontWeight: "normal" } };
              }
              return {
                style: style,
              };
            }}
          />
        </div>
        {/* {totalPages > 1 ? (
        <div style={{ textAlign: "center" }}>
          <Pagination
            disabled={!(props.csLabList?.items?.length > 0)}
            onPageChange={(
              e: React.MouseEvent<HTMLAnchorElement>,
              data: PaginationProps
            ) => {
              setActivePage(data.activePage as number);
            }}
            activePage={activePage}
            firstItem={null}
            lastItem={null}
            ellipsisItem={null}
            pointing
            secondary
            siblingRange={1}
            totalPages={totalPages}
          />
        </div>
      ) : null} */}
      </div>
    </>
  );
});

export default React.memo(CardCSLab);
