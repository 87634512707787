import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Form,
  Modal,
  Grid,
  Icon
} from "semantic-ui-react";
import * as Chat from "react-lib/apps/IsHealth/Chat"
import CardClassify from "react-lib/apps/IsHealth/Nurse/CardClassify";
import CardPatientPanel from "react-lib/apps/IsHealth/BIL/CardBILPatientPanel";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import ModConfirm from "react-lib/apps/common/ModConfirm";
import CardDiagFormHistory from "react-lib/apps/IsHealth/Nurse/CardDiagFormHistory";
import config from "../config/config";
import { useIntl } from "react-intl";

const AppointmentChatTable = React.forwardRef((props: any, ref) => {
  const intl = useIntl();
  const history = useHistory();
  const isMounted = useRef(true);
  const chatListRef = React.useRef();
  const chatBoxRef = React.useRef();
  const [allClassify, setAllClassify] = React.useState([]);
  const [triageLevelList, setTriageLevelList] = React.useState<any[]>([]);
  const [openModDiagHis, setOpenModDiagHis] = React.useState(false)

  React.useImperativeHandle(ref, () => ({
    chatListRef: chatListRef.current,
    chatBoxRef: chatBoxRef.current,
  }));

  React.useEffect(() => {
    handleGetClassify();
  }, [props.finishedTriageLevel, allClassify]);

  const handleGetClassify = async () => {
    if (!allClassify) {
      return;
    }
    let newArr = allClassify.filter((item: any) => {
      if (props.finishedTriageLevel && !props.finishedTriageLevel.includes(item.triage_level)) {
        return item;
      }
    });
    let options = newArr.map(({ triage_level, description, active, codeColor  }) => ({ 
      key: triage_level, 
      value: triage_level,
      triage_level: triage_level,
      code_color: codeColor,
      text: description,
      active: active
    }))
    setTriageLevelList([
      { key: "all", text: "ALL", value: "all" },
      { key: "default", text: "DEFAULT", value: "default" },
      { key: "app", text: "มีนัดหมาย", value: "app" },
      { key: "noApp", text: "ไม่มีนัดหมาย", value: "noApp" },
      ...options
    ]);
  };

  React.useEffect(() => {
    const getTriageLevelClassify = async () => {
      const [
        response,
        error,
        network
      ] = await props.controller.getTriageLevelClassify({
        apiToken: Cookies.get("apiToken") ? Cookies.get("apiToken") : props.apiToken
      });
      if (!isMounted.current) {
        return;
      }
      if (response) {
        setAllClassify(response.classify);
      } else {
        setAllClassify([]);
        // setTriageLevelList([]);
      }
    };

    getTriageLevelClassify();
    props.onGetDivision();
    return () => {isMounted.current = false};
  }, []);

  const handleModDiagHis = () => {
    setOpenModDiagHis(!openModDiagHis);
  };

  return (
    <Grid className="ChatTable noMargin" celled>
      {/* <Table.Body>
        <Table.Row> */}
      <Grid.Column width={4} style={{ padding: 0 }} className="chatList">
        <Chat.ChatList
          {...props}
          ref={chatListRef}
          hideReclassify={true}
          controller={props.controller}
          userId={props.userId}
          apiToken={props.apiToken}
          division={props.division}
          finishedTriageLevel={props.finishedTriageLevel}
          triageLevelList={triageLevelList}
          usePatientId={true}
          hideFilterDate={false}
        />
      </Grid.Column>
      <Grid.Column width={12} style={{ padding: 0 }} className="chatBox">
        {props.match &&
        props.match.params &&
        props.match.params.chatChannelId ? (
          <>
            <Form className="chatbox-header">
              <Form.Group>
              <Form.Field>
                  <Button
                    className="inverted-btn"
                    onClick={() => window.open(config.API_HOST)}
                  >
                    Manage
                  </Button>
                </Form.Field>
              <Form.Field>
                  <Button
                    className="inverted-btn"
                    onClick={handleModDiagHis}
                  >
                    <Icon name="briefcase" />{intl.formatMessage({ id:"แบบคัดกรอง" })}</Button>
                </Form.Field>
                {/* <Form.Field>
                  <Button
                    onClick={openModalClassify}
                    disabled={readOnly}
                  >
                    reclassify
                  </Button>
                </Form.Field> */}
              </Form.Group>
            </Form>
            <Chat.ChatBox
              {...props}
              ref={chatBoxRef}
              readOnly={false}
              nullId={props.nullId}
              controller={props.controller}
              userId={props.userId}
              apiToken={props.apiToken}
              fullname={props.fullname}
              username={props.username}
              onNavigationMessage={props.onNavigationMessage}
              isPatient={props.isPatient}
            />
          </>
        ) : null}
      </Grid.Column>
      {/* <Grid.Column width={4} style={{ padding: 0 }} className="chatDetail">
          <iframe src="https://staging.penguin.drjaysayhi.com/" frameBorder='no' />
      </Grid.Column> */}
      {/* </Table.Row> */}
      {/* </Table.Body> */}
      <Modal
        open={openModDiagHis}
        closeOnDimmerClick
        onClose={handleModDiagHis}
        size="large"
      >
        <CardDiagFormHistory
          {...props}
          controller={props.diagFormController}
          hideCallback={handleModDiagHis}
          // userId={props.userId}
          // division={props.division}
          // apiToken={props.apiToken}
          languageUX={props.languageUX}
        />
      </Modal>
    </Grid>
  );
});

AppointmentChatTable.defaultProps = {
  className: "",
  onGetDivision: () => {},
  // ChatList
  chatListFetch: {},
  getLatestChatChannel: () => {},
  onSelectChat: () => {},

  // ChatBox
  messageFetch: {},
  userId: null,
  chatChannelId: null,
  onSendMessage: () => {},
  messagePost: {},

  // ChatDetail
  chatUserFetch: {},
  fullname: null,
  username: null,

  // DiagFormHistory
  diagFormController: null,

  finishedTriageLevel: [],
  allowCreateAppoint: false,

  isPatient: false,
};

AppointmentChatTable.propTypes = {
  onGetDivision: PropTypes.func,
  className: PropTypes.string,
  // ChatList
  chatListFetch: PropTypes.object,
  getLatestChatChannel: PropTypes.func,
  onSelectChat: PropTypes.func,

  // ChatBox
  messageFetch: PropTypes.object,
  userId: PropTypes.string,
  chatChannelId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSendMessage: PropTypes.func,

  // ChatDetail
  chatUserFetch: PropTypes.object,
  fullname: PropTypes.string,
  username: PropTypes.string,

  // DiagFormHistroy
  diagFormController: PropTypes.object,
  finishedTriageLevel: PropTypes.array,
  allowCreateAppoint: PropTypes.bool,

  isPatient: PropTypes.bool,
};

export default React.memo(AppointmentChatTable);
