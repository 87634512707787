import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Dropdown,
  Button,
  Label,
  Checkbox
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardTelepharPostponeUX = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div>
        <div
          style={{boxShadow: "none", padding: "10px"}}>
          
          <div
            className="ui form small"
            style={{background:"#E9E9E9",margin:"10px 0",padding:"10px 0"}}>
            {}
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={2}>
                <Label
                  size="large"
                  style={{minWidth:"max-content"}}>
                  ชื่อ-สกุล
                </Label>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <Label
                  size="large"
                  style={{minWidth:"max-content"}}>
                  {props.patientName}
                </Label>
              </FormField>
              <FormField
                inline={true}>
                <Label
                  size="large">
                  HN
                </Label>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <Label
                  size="large"
                  style={{minWidth:"max-content"}}>
                  {props.patientHn}
                </Label>
              </FormField>
              <FormField
                inline={true}>
                <Label
                  size="large"
                  style={{minWidth:"max-content"}}>
                  ประเภทผู้ป่วย
                </Label>
              </FormField>
              <FormField
                inline={true}>
                <Dropdown
                  onChange={props.handleChangeValue("patientType")}
                  options={props.patientOption}
                  selection={true}
                  style={{width: "100%",display: props.isPostpone? "none":"flex"}}
                  value={props.patientType}>
                </Dropdown>
                <Label
                  size="large"
                  style={{display: props.isPostpone? "flex":"none"}}>
                  {props.patientType}
                </Label>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={2}>
                <Label
                  size="large"
                  style={{...(!props.isPostpone && {display: "none"}),minWidth:"max-content"}}>
                  วันที่นัดหมายเดิม
                </Label>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <Label
                  size="large"
                  style={{...(!props.isPostpone && {display: "none"}),minWidth:"max-content"}}>
                  {props.patientOldAppointmentDate}
                </Label>
              </FormField>
              <FormField
                inline={true}>
                <Label
                  size="large"
                  style={{...(!props.isPostpone && {display: "none"}),minWidth:"max-content"}}>
                  เวลาที่นัดมหายเดิม
                </Label>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <Label
                  size="large"
                  style={{...(!props.isPostpone && {display: "none"}),minWidth:"max-content"}}>
                  {props.patientOldAppointmentTime}
                </Label>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={2}>
                <Label
                  size="large"
                  style={{minWidth:"max-content"}}>
                  วันที่นัดหมาย
                </Label>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <div
                  style={{flex:"1"}}>
                  {}
                  <Dropdown
                    disabled={props.disableDate}
                    loading={props.divisionLoading}
                    onChange={props.handleChangeValue("patientAppointmentDate")}
                    options={props.dateOption}
                    selection={true}
                    value={props.patientAppointmentDate}>
                  </Dropdown>
                </div>
              </FormField>
              <FormField
                inline={true}>
                <Label
                  size="large"
                  style={{minWidth:"max-content",marginLeft:"20px"}}>
                  เวลาที่นัดหมาย
                </Label>
              </FormField>
              <FormField
                inline={true}>
                <div
                  style={{flex:"1"}}>
                  
                  <Dropdown
                    disabled={props.disableTime}
                    loading={props.divisionLoading}
                    onChange={props.handleChangeValue("divisionServiceBlockSelected")}
                    options={props.timeOption}
                    selection={true}
                    value={props.divisionServiceBlockSelected}>
                  </Dropdown>
                </div>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <Checkbox
                  checked={props.checkedUnAppointment}
                  label="ไม่ระบุนัดหมาย"
                  onChange={props.handleChangeChecked("checkedUnAppointment")}
                  style={{marginLeft:"10px"}}>
                </Checkbox>
              </FormField>
              <div
                className="field"
                style={{flex:1}}>
                
              </div>
              <FormField
                inline={true}>
                <div
                  style={{display: props.isPostpone? "none":"flex",minWidth:"max-content",justifyContent: "end",width:"100%"}}>
                  
                  <Button
                    color="blue"
                    disabled={props.handleCheckDisable("addPatient")}
                    loading={props.buttonLogin}
                    onClick={props.handleAddPatient}>
                    เพิ่มผู้ป่วย
                  </Button>
                </div>
              </FormField>
            </FormGroup>
          </div>
          <div
            style={{display:props.isPostpone && "none" }}>
            
            <Table
              data={props.tableItems}
              getTbodyProps={props.getTbodyProps}
              getTdProps={props.getTdProps}
              getTrProps={props.getTrProps}
              headers="ชื่อ - นามสกุล,HN,ประเภทผู้ป่วย,วันที่นัดหมาย,เวลาที่นัดหมาย,"
              id="tb-bilQueueReq"
              keys="patient_name,hn,patientType,appointmentDate,appointmentTime,delete"
              minRows="8"
              pageSize="5"
              showPagination={false}
              style={{height: "55vh"}}>
            </Table>
          </div>
        </div>
      </div>
    )
}

export default CardTelepharPostponeUX

export const screenPropsDefault = {}

/* Date Time : Tue Apr 11 2023 07:53:15 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{boxShadow: \"none\", padding: \"10px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form small"
        },
        "style": {
          "type": "code",
          "value": "{background:\"#E9E9E9\",margin:\"10px 0\",padding:\"10px 0\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "FormGroup",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:props.isPostpone && \"none\" }"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 32,
      "name": "Table",
      "parent": 31,
      "props": {
        "data": {
          "type": "code",
          "value": "props.tableItems"
        },
        "defaultPageSize": {
          "type": "value",
          "value": ""
        },
        "getTbodyProps": {
          "type": "code",
          "value": "props.getTbodyProps"
        },
        "getTdProps": {
          "type": "code",
          "value": "props.getTdProps"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getTrProps"
        },
        "headers": {
          "type": "value",
          "value": "ชื่อ - นามสกุล,HN,ประเภทผู้ป่วย,วันที่นัดหมาย,เวลาที่นัดหมาย,"
        },
        "id": {
          "type": "value",
          "value": "tb-bilQueueReq"
        },
        "keys": {
          "type": "value",
          "value": "patient_name,hn,patientType,appointmentDate,appointmentTime,delete"
        },
        "minRows": {
          "type": "value",
          "value": "8"
        },
        "pageSize": {
          "type": "value",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"55vh\"}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "FormGroup",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "FormField",
      "parent": 39,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "FormField",
      "parent": 39,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "Dropdown",
      "parent": 43,
      "props": {
        "clearable": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"patientType\")"
        },
        "options": {
          "type": "code",
          "value": "props.patientOption"
        },
        "search": {
          "type": "code",
          "value": ""
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\",display: props.isPostpone? \"none\":\"flex\"}"
        },
        "value": {
          "type": "code",
          "value": "props.patientType"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 83,
      "name": "FormField",
      "parent": 39,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 85,
      "name": "FormField",
      "parent": 39,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 86,
      "name": "FormField",
      "parent": 39,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 93,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "div",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.isPostpone? \"none\":\"flex\",minWidth:\"max-content\",justifyContent: \"end\",width:\"100%\"}"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 97,
      "name": "Button",
      "parent": 95,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มผู้ป่วย"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.handleCheckDisable(\"addPatient\")"
        },
        "icon": {
          "type": "code",
          "value": ""
        },
        "loading": {
          "type": "code",
          "value": "props.buttonLogin"
        },
        "name": {
          "type": "value",
          "value": ""
        },
        "onClick": {
          "type": "code",
          "value": "props.handleAddPatient"
        },
        "size": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 99,
      "name": "Label",
      "parent": 40,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientName"
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{minWidth:\"max-content\"}"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 100,
      "name": "Label",
      "parent": 83,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ-สกุล"
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{minWidth:\"max-content\"}"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 101,
      "name": "Label",
      "parent": 85,
      "props": {
        "children": {
          "type": "value",
          "value": "HN"
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 102,
      "name": "Label",
      "parent": 86,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทผู้ป่วย"
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{minWidth:\"max-content\"}"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 103,
      "name": "FormField",
      "parent": 39,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 104,
      "name": "Label",
      "parent": 103,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientHn"
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{minWidth:\"max-content\"}"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 105,
      "name": "FormGroup",
      "parent": 114,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 106,
      "name": "Label",
      "parent": 75,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่นัดหมาย"
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{minWidth:\"max-content\"}"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 108,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 109,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 111,
      "name": "Label",
      "parent": 108,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลาที่นัดหมาย"
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{minWidth:\"max-content\",marginLeft:\"20px\"}"
        },
        "tag": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 112,
      "name": "Checkbox",
      "parent": 109,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.checkedUnAppointment"
        },
        "disabled": {
          "type": "code",
          "value": ""
        },
        "label": {
          "type": "value",
          "value": "ไม่ระบุนัดหมาย"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedUnAppointment\")"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\"}"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 113,
      "name": "Dropdown",
      "parent": 143,
      "props": {
        "clearable": {
          "type": "code",
          "value": ""
        },
        "disabled": {
          "type": "code",
          "value": "props.disableTime"
        },
        "fluid": {
          "type": "code",
          "value": ""
        },
        "loading": {
          "type": "code",
          "value": "props.divisionLoading"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"divisionServiceBlockSelected\")"
        },
        "options": {
          "type": "code",
          "value": "props.timeOption"
        },
        "search": {
          "type": "code",
          "value": ""
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.divisionServiceBlockSelected"
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": null,
      "id": 114,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",}"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 115,
      "name": "FormField",
      "parent": 114,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 116,
      "name": "FormField",
      "parent": 114,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 117,
      "name": "FormField",
      "parent": 114,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 118,
      "name": "FormField",
      "parent": 114,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 119,
      "name": "Label",
      "parent": 115,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่นัดหมายเดิม"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 120,
      "name": "Label",
      "parent": 117,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลาที่นัดหมายเดิม"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 121,
      "name": "Label",
      "parent": 116,
      "props": {
      },
      "seq": 121,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 122,
      "name": "Label",
      "parent": 118,
      "props": {
      },
      "seq": 122,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 125,
      "name": "FormField",
      "parent": 134,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 127,
      "name": "FormField",
      "parent": 134,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 129,
      "name": "Label",
      "parent": 125,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่นัดหมายเดิม"
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{...(!props.isPostpone && {display: \"none\"}),minWidth:\"max-content\"}"
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 130,
      "name": "Label",
      "parent": 127,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientOldAppointmentDate"
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{...(!props.isPostpone && {display: \"none\"}),minWidth:\"max-content\"}"
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 131,
      "name": "Label",
      "parent": 138,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลาที่นัดมหายเดิม"
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{...(!props.isPostpone && {display: \"none\"}),minWidth:\"max-content\"}"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 132,
      "name": "Label",
      "parent": 132,
      "props": {
      },
      "seq": 132,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 134,
      "name": "FormGroup",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 135,
      "name": "Label",
      "parent": 139,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientOldAppointmentTime"
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{...(!props.isPostpone && {display: \"none\"}),minWidth:\"max-content\"}"
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 137,
      "name": "Label",
      "parent": 43,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientType"
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{display: props.isPostpone? \"flex\":\"none\"}"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 138,
      "name": "FormField",
      "parent": 134,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 139,
      "name": "FormField",
      "parent": 134,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": null,
      "id": 142,
      "name": "div",
      "parent": 93,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:\"1\"}"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": null,
      "id": 143,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:\"1\"}"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": null,
      "id": 144,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "field"
        },
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 145,
      "name": "Dropdown",
      "parent": 142,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disableDate"
        },
        "loading": {
          "type": "code",
          "value": "props.divisionLoading"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"patientAppointmentDate\")"
        },
        "options": {
          "type": "code",
          "value": "props.dateOption"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.patientAppointmentDate"
        }
      },
      "seq": 145,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "CardTelepharPostponeUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
