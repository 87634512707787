import { TDocumentDefinitions } from "pdfmake/interfaces";

import HeaderSummaryReportForm from "./HeaderSummaryReportForm";

const FormExpenseExceptionReport = async (props: any): Promise<TDocumentDefinitions> => {
  console.log("Props Form Expense Exception Report:", props);

  const headerForm = await HeaderSummaryReportForm({
    data: props.reportExpenseExceptData?.params,
    header: [
      {
        alignment: "center",
        bold: true,
        fontSize: 15,
        text: "รายงานรายการค่าใช้จ่ายที่ยกเว้นค่ารักษาพยาบาล",
      },
      {
        alignment: "center",
        fontSize: 15,
        preserveLeadingSpaces: true,
        text: " ",
      },
      {
        alignment: "center",
        fontSize: 15,
        marginBottom: 5,
        text: `วันที่รับการรักษา ตั้งแต่ ${props.reportExpenseExceptData?.params?.start_date} ถึง ${props.reportExpenseExceptData?.params?.end_date}`,
      },
    ],
  });

  const { font, images, styles } = headerForm;

  const defaultPadding = [0, 4, 0, 0];

  const generateTableBody = (data: any, columns: any) => {
    let body = [];

    body.push([
      { text: "No.", bold: true, alignment: "center", margin: defaultPadding },
      { text: "HN", bold: true, alignment: "center", margin: defaultPadding },
      { text: "VN\nวันเวลารักษา", bold: true, alignment: "center", margin: defaultPadding },
      { text: "คลินิก", bold: true, alignment: "center", margin: defaultPadding },
      { text: "ชื่อ-สกุล", bold: true, alignment: "center", margin: defaultPadding },
      { text: "สิทธิรักษา", bold: true, alignment: "center", margin: defaultPadding },
      { text: "รายละเอียด", bold: true, alignment: "center", margin: defaultPadding },
      { text: "ราคา", bold: true, alignment: "center", margin: defaultPadding },
      { text: "เบิกไม่ได้", bold: true, alignment: "center", margin: defaultPadding },
      {
        text: "ค่ารักษา\nที่ได้รับยกเว้น",
        bold: true,
        alignment: "center",
        margin: defaultPadding,
      },
      { text: "ที่ต้องชำระ", bold: true, alignment: "center", margin: defaultPadding },
    ]);

    if (data?.length > 0) {
      data.forEach((row: any) => {
        let dataRow: any = [];

        let itemListArray: any = [];

        if (row.description_list.length > 0) {
          (row.description_list || []).forEach((column: any, index: any) => {
            itemListArray.push([
              { text: column },
              {
                text: row.price_list[index] || "",
                alignment: "right",
              },
              {
                text: row.non_claimable_list[index] || "",
                alignment: "right",
              },
              {
                text: row.discount_list[index] || "",
                alignment: "right",
              },
              {
                text: row.payable_list[index] || "",
                alignment: "right",
              },
            ]);
          });
        }

        if (itemListArray.length < 2) {
          itemListArray.push([" ", "", "", "", ""]);
        }

        body.push([
          { text: row.seq, margin: defaultPadding, alignment: "center" },
          { text: row.hn, margin: defaultPadding },
          { text: row.performed_date, margin: defaultPadding, alignment: "center" },
          { text: row.division_name, margin: defaultPadding },
          { text: row.patient_name, margin: defaultPadding },
          { text: row.coverage_name, margin: defaultPadding },
          {
            colSpan: 5,
            table: {
              widths: ["53.8%", "11.3%", "11.4%", "13.2%", "10.3%"],
              body: itemListArray,
            },
            layout: {
              vLineWidth: (i: any, node: any) => {
                return i === 0 || i === node.table.widths.length ? 0 : 1;
              },
              hLineWidth: (i: any, node: any) => {
                return 0;
              },
              paddingTop: (i: any, node: any) => {
                return 4;
              },
            },
          },
          { text: row.price_list, margin: defaultPadding },
          { text: row.non_claimable_list, margin: defaultPadding },
          { text: row.discount_list, margin: defaultPadding },
          { text: row.payable_list, margin: defaultPadding },
        ]);

        // Total Row
        body.push([
          { text: "", colSpan: 5, border: [true, false, false, true], margin: defaultPadding },
          {},
          {},
          {},
          {},
          {
            text: "รวม",
            bold: true,
            alignment: "center",
            border: [false, false, true, true],
            margin: defaultPadding,
          },
          {},
          { text: row["total_price"], bold: true, alignment: "right", margin: defaultPadding },
          {
            text: row["total_non_claimable"],
            bold: true,
            alignment: "right",
            margin: defaultPadding,
          },
          { text: row["total_discount"], bold: true, alignment: "right", margin: defaultPadding },
          { text: row["total_payable"], bold: true, alignment: "right", margin: defaultPadding },
        ]);
      });
    }

    // Summary Total Row
    if (props.reportExpenseExceptData?.params) {
      body.push([
        {
          text: "รวมทั้งสิ้น",
          colSpan: 7,
          bold: true,
          alignment: "center",
          margin: defaultPadding,
        },
        {},
        {},
        {},
        {},
        {},
        {},
        {
          text: props.reportExpenseExceptData?.params?.total_price_all,
          bold: true,
          alignment: "right",
          margin: defaultPadding,
        },
        {
          text: props.reportExpenseExceptData?.params?.total_non_claimable_all,
          bold: true,
          alignment: "right",
          margin: defaultPadding,
        },
        {
          text: props.reportExpenseExceptData?.params?.total_discount_all,
          bold: true,
          alignment: "right",
          margin: defaultPadding,
        },
        {
          text: props.reportExpenseExceptData?.params?.total_payable_all,
          bold: true,
          alignment: "right",
          margin: defaultPadding,
        },
      ]);
    }

    return body;
  };

  const tableReport = (data: any, columns: any) => {
    return {
      table: {
        widths: ["3%", "5%", "7%", "15%", "10%", "6%", "29%", "6%", "6%", "7%", "6%"],
        headerRows: 1,
        body: generateTableBody(data, columns),
      },
      layout: {
        paddingTop: (i: any, node: any) => {
          return 0;
        },
        paddingBottom: (i: any, node: any) => {
          return 0;
        },
      },
    };
  };

  return {
    ...headerForm,
    content: [
      tableReport(props.reportExpenseExceptData?.fields || [], [
        "seq",
        "hn",
        "encounter_number",
        "division_name",
        "patient_name",
        "coverage_name",
        "description_list",
        "price_list",
        "non_claimable_list",
        "discount_list",
        "payable_list",
      ]),
    ],
    images: {
      ...images,
    },
    styles: {
      ...styles,
    },
    pageOrientation: `landscape`,
    defaultStyle: {
      font,
      // fontSize: 14,
    },
    pageSize: `A4`,
  };
};

export default FormExpenseExceptionReport;
