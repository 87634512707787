import moment from "moment";
import { formatADtoBEString, formatDate } from "react-lib/utils/dateUtils";
import CompanyLogoForm from "react-lib/apps/HISV3/common/CompanyLogoForm";
import CONFIG from "config/config";

const FORM_NAME = "FormDentistSchedule";

const FormDentistSchedule = async (props: any) => {
  console.log("props.data", props.data);
  const companyLogoForm = await CompanyLogoForm({
    type: 2,
    font: "THSarabunNew",
    height: 26.93,
    form: FORM_NAME,
  });
  const { font, images } = companyLogoForm;
  const blockList: Record<string, any[]> = (props?.data?.orders || [])
    .filter((block: any) => block?.providers?.length === 1)
    .map((block: any) => block.providers[0])
    .sort((a: any, b: any) => (a?.block_datetime < b?.block_datetime ? -1 : 1))
    .reduce((result: any, item: any) => {
      return result[item.date] ? { ...result, [item.date]: [...result[item.date], item] } : { ...result, [item.date]: [item] };
    }, {});

  console.log("blockList", blockList);

  return {
    pageSize: "A4",
    defaultStyle: {
      font: font,
      lineHeight: 1,
      fontSize: 14,
    },
    pageMargins: [20, 115, 20, 20],
    styles: {
      facultyHeader: {
        fontSize: 19,
        bold: true,
      },
      divisionHeader: {
        fontSize: 18,
      },
      tableHeader: {
        fontSize: 14,
        alignment: "center",
      },
      fieldKey: {
        bold: true,
        fontSize: 13,
      },
      fieldValue: {
        fontSize: 13,
        alignment: "center",
      },
    },
    header: {
      margin: [20, 10, 20, 10],
      stack: [
        {
          columns: [
            !CONFIG.HIDE_COMPANY_LOGO_PRINT ? { margin: [0, 0, 0, 0], width: 80, image: "logo", alignment: "left" } : null,
            {
              margin: [-15, 10, 0, 0],
              width: "*",
              stack: [
                !CONFIG.HIDE_COMPANY_LOGO_PRINT ? {
                  text: "CHULALONGKORN UNIVERSITY, FACULTY OF DENTISTRY",
                  style: "facultyHeader",
                  alignment: "center",
                } : null ,
                { alignment: "center", text: "ตารางออกตรวจทันตแพทย์", style: "divisionHeader" },
                {
                  alignment: "center",
                  text: [
                    { text: "ทันตแพทย์/นิสิต : ", style: "divisionHeader" },
                    { text: props.data?.provider_name, style: "divisionHeader" },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    content: [
      {
        table: {
          headerRows: 1,
          widths: ["15%", "17.5%", "30%", "17.5%", "20%"],
          body: [
            [
              { text: "วันที่ออกตรวจ", style: "tableHeader" },
              { text: "เวลาออกตรวจ", style: "tableHeader" },
              { text: "หน่วยงาน/คลินิก", style: "tableHeader" },
              { text: "เลขเก้าอี้", style: "tableHeader" },
              { text: "โซน", style: "tableHeader" },
            ],
            ...Object.keys(blockList).map((date: string) => [
              { text: formatADtoBEString(date), style: "fieldValue" },
              ...["time", "division", "chair", "zone"].map((column) => ({
                margin: [-5, -3, -5, -3],
                table: {
                  widths: ["100%"],
                  body: [
                    ...blockList[date].map((block, index) => [
                      {
                        text: {
                          time: `${block.start_time} - ${block.end_time}`,
                          division: block.division_name,
                          chair: block.chairName || " ",
                          zone: props.data?.divisionDict?.[block.chair_zone_id]?.name || " ",
                        }[column],
                        style: "fieldValue",
                        border: [false, false, false, blockList[date].length - 1 === index ? false : true],
                      },
                    ]),
                  ],
                },
              })),
            ]),
          ],
        },
        // table: {
        //   headerRows: 1,
        //   widths: ["5%", "8%", "13%", "11%", "13%", "13%", "14%", "13%", "10%"],
        //   body: [
        //     [
        //       { text: "ลำดับ", style: "tableHeader" },
        //       { text: "HN", style: "tableHeader" },
        //       { text: "ชื่อ-สกุล", style: "tableHeader" },
        //       { text: "เบอร์โทร", style: "tableHeader" },
        //       { text: "ที่อยู่ติดต่อได้", style: "tableHeader" },
        //       { text: "คลินิก", style: "tableHeader" },
        //       { text: "แพทย์/นิสิต", style: "tableHeader" },
        //       { text: "วันเวลานัดหมาย", style: "tableHeader" },
        //       { text: "รายละเอียด", style: "tableHeader" },
        //     ],
        //   ].concat((props.data?.orders || [])?.map((row: any, index: number) => ([
        //     { text: (index + 1).toString(), style: "fieldValue" },
        //     { text: row.patient_hn || "", style: "fieldValue" },
        //     { text: row.patient || "", style: "fieldValue" },
        //     { text: row.patient_tel ? row.patient_tel.replace(/(^\d{3})/g, "$1-") : "", style: "fieldValue" },
        //     { text: row.address || "", style: "fieldValue" },
        //     { text: row.division_name || "", style: "fieldValue" },
        //     { text: row.provider_name ? row.provider_name : "ไม่ระบุ", style: "fieldValue" },
        //     { text: row.estimated_at || "", style: "fieldValue" },
        //     { text: row.order_note || "", style: "fieldValue" }
        //   ])))
        // },
      },
    ],
    footer: (currentPage: number, pageCount: number) => ({
      margin: [20, -5, 20, 0],
      columns: [
        { width: "*", text: `วันเวลาที่พิมพ์ : ${formatDate(moment())} [${moment().format("HH:mm")}]` },
        { text: `หน้าที่ ${currentPage.toString()} / ${pageCount}`, alignment: "right" },
      ],
    }),
    images: {
      ...images,
    },
  };
};

export default FormDentistSchedule;
