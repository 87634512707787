import React from 'react';
import Interceptor from '../apps/common/Interceptor';
import { useIntl } from "react-intl";

function getDisplayName(WrappedComponent: any) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export const withInterceptor = (WrappedComponent: React.ComponentType<any>) => {
  class WithInterceptor extends React.PureComponent {
    static displayName = `withInterceptor(${getDisplayName(WrappedComponent)})`;

    render() {
      return (
        <React.Fragment>
          <Interceptor />
          <WrappedComponent ref={this.props.forwardedRef} {...this.props} />
        </React.Fragment>
      );
    }
  }

  // Forwarding ref with React.forwardRef
  return React.forwardRef((props , ref) => (
    <WithInterceptor {...props} forwardedRef={ref} />
  ));
};
