import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Button,
  Icon
} from 'semantic-ui-react'

const CardUDSegmentResultUX = (props: any) => {
    return(
      <div
        style={{padding: "15px"}}>
        <div
          className="ui form">
          
          <FormGroup
            inline={true}>
            <div
              className="field"
              style={{flex: 1}}>
              
            </div>
            <FormField
              inline={true}>
              <div>
                {props.ButtonSave}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <Button
                color="red"
                disabled={props.disabledDelete || false}
                onClick={props.onDelete}>
                Delete Segment
              </Button>
            </FormField>
          </FormGroup>
        </div>
        <div
          style={{width: "100%", padding: "1rem 0"}}>
          {props.SegmentGroupDetail}
        </div>
        <div>
          
          <Button
            color="blue"
            onClick={props.onAddSegmentResult}
            style={{marginBottom: "1rem"}}>
            
            <Icon
              name="plus circle">
            </Icon>
            <label>
              เพิ่มกลุ่มย่อย
            </label>
          </Button>
        </div>
        <div>
          {props.SubSegmentList}
        </div>
      </div>
    )
}

export default React.memo(CardUDSegmentResultUX)

export const screenPropsDefault = {}

/* Date Time : Mon Jan 23 2023 13:16:32 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding: \"15px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.SegmentGroupDetail"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", padding: \"1rem 0\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "field"
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "Button",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "Delete Segment"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledDelete || false"
        },
        "onClick": {
          "type": "code",
          "value": "props.onDelete"
        },
        "size": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "Button",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onAddSegmentResult"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"1rem\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "Icon",
      "parent": 10,
      "props": {
        "name": {
          "type": "value",
          "value": "plus circle"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "label",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มกลุ่มย่อย"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.SubSegmentList"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSave"
        }
      },
      "seq": 14,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 70,
  "isMounted": false,
  "memo": true,
  "name": "CardUDSegmentResultUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 65
}

*********************************************************************************** */
