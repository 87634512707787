import { formatDatetime } from "react-lib/utils/dateUtils";

export const appointmentListForPatientAppointment = (
  appointmentList: any[]
) => {
  // Prepare appointmentList for display
  return (appointmentList || [])
    .map((app: any) => ({
      ...app,
      date: app.display_info?.start_datetime?.split("T")?.[0],
      start: app.display_info?.start_datetime?.split("T")?.[1]?.substring(0, 5),
      end: app.display_info?.end_datetime?.split("T")?.[1]?.substring(0, 5),
    }))
    .map((app: any) => ({
      ...app,
      datetime: app.date
        ? `${app.date || ""} ${app.start || ""} ${app.end || ""}`
        : "ยังไม่ระบุ",
    }))
    .map((app: any) => ({
      ...app,
      patient_name: app.patient_name
        ? app.patient_name
        : app?.patient_pre_name
        ? `${app.patient_pre_name} ${app.patient_first_name} ${app.patient_last_name}`
        : `${app.patient_first_name} ${app.patient_last_name}`,
      detail:
        ["นัดหมาย Consult OPD", "นัดหมาย Consult IPD"].includes(
          app.type_display
        ) && !app.provider
          ? `${app.division_name || ""} (ยังไม่ระบุ)`
          : `${app.division_name || ""} (${
              app.display_info?.provider_name || ""
            } ${app.datetime || ""})`,
    }))
    .map((app: any) => ({
      ...app,
      // Issue 56214 => 61913
      // display_info_division_name: app.status !== 1 ? "-" : app.display_info?.division_name,
      // display_info_provider_name: app.status !== 1 ? "-" : app.display_info?.provider_name,
      display_info_division_name: app.display_info?.division_name
        ? app.display_info?.division_name
        : app?.division_name || "-",
      display_info_provider_name: app.display_info?.provider_name
        ? app.display_info?.provider_name
        : app?.doctor_full_name || "-",
      display_info_start_datetime_iso:
        app.status === 1 && !app.estimated_at_iso ? "" :
        app.status === 1 && app.estimated_at_iso ? `${formatDatetime(app.estimated_at_iso)} (รอยืนยันนัดหมาย)` :
        app.status === 4 ? "เลื่อนนัดหมาย (เลือกเวลาอีกครั้ง)" : formatDatetime(app.estimated_at_iso),
    }))
    .sort((a: any, b: any) =>
      a.display_info?.start_serial < b.display_info?.start_serial ? -1 : 1
    );
};
