import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Checkbox,
  Input,
  Dropdown,
  FormGroup,
  FormField
} from 'semantic-ui-react'

const CardPACURecordReferralUX = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        id="CardPACURecordReferral"
        style={{ width: "100%", height: "100%" ,padding:"10px"}}>
        <div
          className="ui form"
          style={{backgroundColor: "#EDF6F9",width: "100%",height:"80%",padding:"10px",borderRadius: 3,border : "solid 0.5px ",borderColor:"#5DBCD2"}}>
          
          <FormGroup>
            <FormField
              inline={true}
              width={2}>
              <div
                style={{ display: "flex",  alignItems:"center",height:"100%",width:"100%"}}>
                
                <Checkbox
                  checked={props.PACURecordSequence?.data?.NotPACU || false}
                  name="NotPACU"
                  onChange={props.onChangeData}>
                </Checkbox>
                <label
                  style={{ marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                  ไม่ผ่าน PACU
                </label>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{display:"flex",gap:"10px"}}>
                
                <label
                  style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                  BP
                </label>
                <Input
                  fluid={true}
                  name="BPRemark"
                  onChange={props.onChangeData}
                  style={{ display: "flex" ,height:"90%",width:"100%"}}
                  value={props.PACURecordSequence?.data?.BPRemark || ""}>
                </Input>
                <label
                  style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center", marginLeft:"5px"}}>
                  mmHg
                </label>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{display:"flex",gap:"10px"}}>
                
                <label
                  style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                  Pulse
                </label>
                <Input
                  fluid={true}
                  name="PulseRemark"
                  onChange={props.onChangeData}
                  style={{ display: "flex" ,height:"90%",width:"100%"}}
                  value={props.PACURecordSequence?.data?.PulseRemark || ""}>
                </Input>
                <label
                  style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center", marginLeft:"5px"}}>
                  BPM
                </label>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{display:"flex",gap:"10px"}}>
                
                <label
                  style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                  SpO2
                </label>
                <Input
                  fluid={true}
                  name="SpO2Remark"
                  onChange={props.onChangeData}
                  style={{ display: "flex" ,height:"90%",width:"100%"}}
                  value={props.PACURecordSequence?.data?.SpO2Remark || ""}>
                </Input>
                <label
                  style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center", marginLeft:"5px"}}>
                  %
                </label>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{display:"flex",gap:"10px"}}>
                
                <label
                  style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center", marginLeft:"5px"}}>
                  Temp
                </label>
                <Input
                  fluid={true}
                  name="TempRemark"
                  onChange={props.onChangeData}
                  style={{ display: "flex" ,height:"90%",width:"100%"}}
                  value={props.PACURecordSequence?.data?.TempRemark || ""}>
                </Input>
                <label
                  style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center", marginLeft:"5px"}}>
                  ํC
                </label>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField
              inline={true}
              width={3}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                O2 Transfer
              </label>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <Dropdown
                clearable={true}
                fluid={true}
                name="O2Transfer"
                onChange={props.onChangeData}
                options={props.masterOptions?.pacu_o2transfer || []}
                selection={true}
                value={props.PACURecordSequence?.data?.O2Transfer || ""}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}
              width={7}>
              <Input
                fluid={true}
                name="O2TransferRemark"
                onChange={props.onChangeData}
                style={{marginLeft:"10px"}}
                value={props.PACURecordSequence?.data?.O2TransferRemark || ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField
              inline={true}
              width={3}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                Patient-Controlled Analgesia
              </label>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <Dropdown
                clearable={true}
                fluid={true}
                name="PatientControlledAnalgesia"
                onChange={props.onChangeData}
                options={props.masterOptions?.pacu_ptctrlanal || []}
                selection={true}
                value={props.PACURecordSequence?.data?.PatientControlledAnalgesia || ""}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}
              width={7}>
              <Input
                fluid={true}
                name="PatientControlledAnalgesiaRemark"
                onChange={props.onChangeData}
                style={{marginLeft:"10px"}}
                value={props.PACURecordSequence?.data?.PatientControlledAnalgesiaRemark || ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField
              inline={true}
              width={3}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                Spinal/Epidural Morphine
              </label>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <Dropdown
                clearable={true}
                fluid={true}
                name="SpinalEpiduralMorphine"
                onChange={props.onChangeData}
                options={props.masterOptions?.pacu_spiepimorph || []}
                selection={true}
                value={props.PACURecordSequence?.data?.SpinalEpiduralMorphine || ""}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}
              width={7}>
              <Input
                fluid={true}
                name="SpinalEpiduralMorphineRemark"
                onChange={props.onChangeData}
                style={{marginLeft:"10px"}}
                value={props.PACURecordSequence?.data?.SpinalEpiduralMorphineRemark || ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField
              inline={true}
              width={1}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                ผู้รับ
              </label>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <Input
                name="ReceiverRemark"
                onChange={props.onChangeData}
                style={{ display: "flex" ,width:"100%"}}
                value={props.PACURecordSequence?.data?.ReceiverRemark || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}
              width={1}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                ผู้ส่ง
              </label>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <Input
                name="SenderRemark"
                onChange={props.onChangeData}
                style={{ display: "flex" ,width:"100%"}}
                value={props.PACURecordSequence?.data?.SenderRemark || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <div
                style={{ display: "none"}}>
                <label
                  style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                  License number
                </label>
                <Input
                  name="LicenseNumberRemark"
                  onChange={props.onChangeData}
                  style={{ display: "flex" ,height:"90%",width:"100%"}}
                  value={props.PACURecordSequence?.data?.LicenseNumberRemark || ""}>
                </Input>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField
              inline={true}
              width={2}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                Trasfer to
              </label>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <div
                style={{ display: "flex",  alignItems:"center",height:"100%",width:"100%"}}>
                
                <Checkbox
                  checked={props.PACURecordSequence?.data?.IsTransferToWard || false}
                  disabled={props.PACURecordSequence?.data?.IsTrsnsferToDischarge === true || props.PACURecordSequence?.data?.IsTransferToICU === true}
                  name="IsTransferToWard"
                  onChange={props.onChangeData}>
                </Checkbox>
                <label
                  style={{ marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                  ward
                </label>
                <Input
                  disabled={props.PACURecordSequence?.data?.IsTransferToWard === false}
                  name="TransferToWardRemark"
                  onChange={props.onChangeData}
                  style={{ display: "flex" ,width:"100%",marginLeft:"10px"}}
                  value={props.PACURecordSequence?.data?.TransferToWardRemark || ""}>
                </Input>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <div
                style={{ display: "flex",  alignItems:"center",height:"100%",width:"100%"}}>
                
                <Checkbox
                  checked={props.PACURecordSequence?.data?.IsTransferToICU || false}
                  disabled={props.PACURecordSequence?.data?.IsTrsnsferToDischarge === true || props.PACURecordSequence?.data?.IsTransferToWard === true}
                  name="IsTransferToICU"
                  onChange={props.onChangeData}>
                </Checkbox>
                <label
                  style={{ marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                  ICU
                </label>
                <Input
                  disabled={props.PACURecordSequence?.data?.IsTransferToICU === false}
                  name="TransferToICURemark"
                  onChange={props.onChangeData}
                  style={{ display: "flex" ,width:"100%",marginLeft:"10px"}}
                  value={props.PACURecordSequence?.data?.TransferToICURemark || ""}>
                </Input>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <div
                style={{ display: "flex",  alignItems:"center",height:"100%",width:"100%"}}>
                
                <Checkbox
                  checked={props.PACURecordSequence?.data?.IsTrsnsferToDischarge || false}
                  disabled={props.PACURecordSequence?.data?.IsTransferToICU === true || props.PACURecordSequence?.data?.IsTransferToWard === true}
                  name="IsTrsnsferToDischarge"
                  onChange={props.onChangeData}>
                </Checkbox>
                <label
                  style={{ marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                  Discharge
                </label>
                <Input
                  disabled={props.PACURecordSequence?.data?.IsTrsnsferToDischarge === false}
                  name="TrsnsferToDischargeRemark"
                  onChange={props.onChangeData}
                  style={{ display: "flex" ,width:"100%",marginLeft:"10px"}}
                  value={props.PACURecordSequence?.data?.TrsnsferToDischargeRemark || ""}>
                </Input>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField
              inline={true}
              width={2}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                Risk for fall
              </label>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <div
                style={{ display: "flex",  alignItems:"center",height:"100%",width:"100%"}}>
                
                <Checkbox
                  checked={props.PACURecordSequence?.data?.IsLow || false}
                  name="IsLow"
                  onChange={props.onChangeData}>
                </Checkbox>
                <label
                  style={{ marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                  Low
                </label>
              </div>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <div
                style={{ display: "flex",  alignItems:"center",height:"100%",width:"100%"}}>
                
                <Checkbox
                  checked={props.PACURecordSequence?.data?.IsHigh || false}
                  name="IsHigh"
                  onChange={props.onChangeData}>
                </Checkbox>
                <label
                  style={{ marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                  High
                </label>
              </div>
            </FormField>
          </FormGroup>
        </div>
      </div>
    )
}


export default CardPACURecordReferralUX

export const screenPropsDefault = {}

/* Date Time : Tue May 21 2024 12:46:44 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPACURecordReferral"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"100%\" ,padding:\"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 26,
      "name": "label",
      "parent": 145,
      "props": {
        "children": {
          "type": "value",
          "value": "O2 Transfer"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 136,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "Checkbox",
      "parent": 37,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.NotPACU || false"
        },
        "name": {
          "type": "value",
          "value": "NotPACU"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "label",
      "parent": 37,
      "props": {
        "children": {
          "type": "value",
          "value": "ไม่ผ่าน PACU"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "label",
      "parent": 141,
      "props": {
        "children": {
          "type": "value",
          "value": "BP"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "Input",
      "parent": 141,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "BPRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"90%\",width:\"100%\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.BPRemark || \"\""
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "label",
      "parent": 141,
      "props": {
        "children": {
          "type": "value",
          "value": "mmHg"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\", marginLeft:\"5px\"}"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "label",
      "parent": 142,
      "props": {
        "children": {
          "type": "value",
          "value": "Pulse"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "Input",
      "parent": 142,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "PulseRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"90%\",width:\"100%\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.PulseRemark || \"\""
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "label",
      "parent": 142,
      "props": {
        "children": {
          "type": "value",
          "value": "BPM"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\", marginLeft:\"5px\"}"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "label",
      "parent": 143,
      "props": {
        "children": {
          "type": "value",
          "value": "SpO2"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "Input",
      "parent": 143,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "SpO2Remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"90%\",width:\"100%\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.SpO2Remark || \"\""
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "label",
      "parent": 143,
      "props": {
        "children": {
          "type": "value",
          "value": "%"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\", marginLeft:\"5px\"}"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "label",
      "parent": 144,
      "props": {
        "children": {
          "type": "value",
          "value": "Temp"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\", marginLeft:\"5px\"}"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "Input",
      "parent": 144,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "TempRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"90%\",width:\"100%\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.TempRemark || \"\""
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "label",
      "parent": 144,
      "props": {
        "children": {
          "type": "value",
          "value": "ํC"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\", marginLeft:\"5px\"}"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "label",
      "parent": 148,
      "props": {
        "children": {
          "type": "value",
          "value": "Patient-Controlled Analgesia"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "label",
      "parent": 151,
      "props": {
        "children": {
          "type": "value",
          "value": "Spinal/Epidural Morphine"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "Dropdown",
      "parent": 149,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "PatientControlledAnalgesia"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.pacu_ptctrlanal || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.PatientControlledAnalgesia || \"\""
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 79,
      "name": "Dropdown",
      "parent": 146,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "O2Transfer"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.pacu_o2transfer || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.O2Transfer || \"\""
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 80,
      "name": "Dropdown",
      "parent": 152,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "SpinalEpiduralMorphine"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.pacu_spiepimorph || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.SpinalEpiduralMorphine || \"\""
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 81,
      "name": "Input",
      "parent": 147,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "O2TransferRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.O2TransferRemark || \"\""
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "Input",
      "parent": 150,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "PatientControlledAnalgesiaRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.PatientControlledAnalgesiaRemark || \"\""
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 83,
      "name": "Input",
      "parent": 153,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "SpinalEpiduralMorphineRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.SpinalEpiduralMorphineRemark || \"\""
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "div",
      "parent": 168,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: \"none\"}"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": null,
      "id": 91,
      "name": "label",
      "parent": 154,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้รับ"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 94,
      "name": "Input",
      "parent": 155,
      "props": {
        "name": {
          "type": "value",
          "value": "ReceiverRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,width:\"100%\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.ReceiverRemark || \"\""
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "label",
      "parent": 158,
      "props": {
        "children": {
          "type": "value",
          "value": "Trasfer to"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": null,
      "id": 96,
      "name": "label",
      "parent": 156,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ส่ง"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 97,
      "name": "Input",
      "parent": 157,
      "props": {
        "fluid": {
          "type": "code",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "SenderRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,width:\"100%\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.SenderRemark || \"\""
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": null,
      "id": 98,
      "name": "label",
      "parent": 89,
      "props": {
        "children": {
          "type": "value",
          "value": "License number"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 99,
      "name": "Input",
      "parent": 89,
      "props": {
        "name": {
          "type": "value",
          "value": "LicenseNumberRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"90%\",width:\"100%\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.LicenseNumberRemark || \"\""
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": null,
      "id": 101,
      "name": "div",
      "parent": 159,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 102,
      "name": "Checkbox",
      "parent": 101,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.IsTransferToWard || false"
        },
        "disabled": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.IsTrsnsferToDischarge === true || props.PACURecordSequence?.data?.IsTransferToICU === true"
        },
        "name": {
          "type": "value",
          "value": "IsTransferToWard"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": null,
      "id": 103,
      "name": "label",
      "parent": 101,
      "props": {
        "children": {
          "type": "value",
          "value": "ward"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 104,
      "name": "Input",
      "parent": 101,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.IsTransferToWard === false"
        },
        "name": {
          "type": "value",
          "value": "TransferToWardRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,width:\"100%\",marginLeft:\"10px\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.TransferToWardRemark || \"\""
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": null,
      "id": 106,
      "name": "div",
      "parent": 160,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 107,
      "name": "Checkbox",
      "parent": 106,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.IsTransferToICU || false"
        },
        "disabled": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.IsTrsnsferToDischarge === true || props.PACURecordSequence?.data?.IsTransferToWard === true"
        },
        "name": {
          "type": "value",
          "value": "IsTransferToICU"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": null,
      "id": 108,
      "name": "label",
      "parent": 106,
      "props": {
        "children": {
          "type": "value",
          "value": "ICU"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 109,
      "name": "Input",
      "parent": 106,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.IsTransferToICU === false"
        },
        "name": {
          "type": "value",
          "value": "TransferToICURemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,width:\"100%\",marginLeft:\"10px\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.TransferToICURemark || \"\""
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": null,
      "id": 111,
      "name": "div",
      "parent": 161,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 112,
      "name": "Checkbox",
      "parent": 111,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.IsTrsnsferToDischarge || false"
        },
        "disabled": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.IsTransferToICU === true || props.PACURecordSequence?.data?.IsTransferToWard === true"
        },
        "name": {
          "type": "value",
          "value": "IsTrsnsferToDischarge"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": null,
      "id": 113,
      "name": "label",
      "parent": 111,
      "props": {
        "children": {
          "type": "value",
          "value": "Discharge"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 114,
      "name": "Input",
      "parent": 111,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.IsTrsnsferToDischarge === false"
        },
        "name": {
          "type": "value",
          "value": "TrsnsferToDischargeRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,width:\"100%\",marginLeft:\"10px\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.TrsnsferToDischargeRemark || \"\""
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": null,
      "id": 117,
      "name": "label",
      "parent": 162,
      "props": {
        "children": {
          "type": "value",
          "value": "Risk for fall"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": null,
      "id": 122,
      "name": "div",
      "parent": 166,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 123,
      "name": "Checkbox",
      "parent": 122,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.IsLow || false"
        },
        "name": {
          "type": "value",
          "value": "IsLow"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": null,
      "id": 124,
      "name": "label",
      "parent": 122,
      "props": {
        "children": {
          "type": "value",
          "value": "Low"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": null,
      "id": 126,
      "name": "div",
      "parent": 167,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 127,
      "name": "Checkbox",
      "parent": 126,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.IsHigh || false"
        },
        "name": {
          "type": "value",
          "value": "IsHigh"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": null,
      "id": 128,
      "name": "label",
      "parent": 126,
      "props": {
        "children": {
          "type": "value",
          "value": "High"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": null,
      "id": 129,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#EDF6F9\",width: \"100%\",height:\"80%\",padding:\"10px\",borderRadius: 3,border : \"solid 0.5px \",borderColor:\"#5DBCD2\"}"
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 130,
      "name": "FormGroup",
      "parent": 129,
      "props": {
      },
      "seq": 130,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 131,
      "name": "FormGroup",
      "parent": 129,
      "props": {
      },
      "seq": 131,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 132,
      "name": "FormGroup",
      "parent": 129,
      "props": {
      },
      "seq": 132,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 133,
      "name": "FormGroup",
      "parent": 129,
      "props": {
      },
      "seq": 133,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 134,
      "name": "FormGroup",
      "parent": 129,
      "props": {
      },
      "seq": 134,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 135,
      "name": "FormGroup",
      "parent": 129,
      "props": {
      },
      "seq": 135,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 136,
      "name": "FormField",
      "parent": 130,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 137,
      "name": "FormField",
      "parent": 130,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 138,
      "name": "FormField",
      "parent": 130,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 139,
      "name": "FormField",
      "parent": 130,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 140,
      "name": "FormField",
      "parent": 130,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": null,
      "id": 141,
      "name": "div",
      "parent": 137,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",gap:\"10px\"}"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": null,
      "id": 142,
      "name": "div",
      "parent": 138,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",gap:\"10px\"}"
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": null,
      "id": 143,
      "name": "div",
      "parent": 139,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",gap:\"10px\"}"
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": null,
      "id": 144,
      "name": "div",
      "parent": 140,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",gap:\"10px\"}"
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 145,
      "name": "FormField",
      "parent": 131,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 146,
      "name": "FormField",
      "parent": 131,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 147,
      "name": "FormField",
      "parent": 131,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 148,
      "name": "FormField",
      "parent": 132,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 149,
      "name": "FormField",
      "parent": 132,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 150,
      "name": "FormField",
      "parent": 132,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 151,
      "name": "FormField",
      "parent": 133,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 152,
      "name": "FormField",
      "parent": 133,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 153,
      "name": "FormField",
      "parent": 133,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 154,
      "name": "FormField",
      "parent": 134,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 155,
      "name": "FormField",
      "parent": 134,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 156,
      "name": "FormField",
      "parent": 134,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 157,
      "name": "FormField",
      "parent": 134,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 158,
      "name": "FormField",
      "parent": 135,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 159,
      "name": "FormField",
      "parent": 135,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 160,
      "name": "FormField",
      "parent": 135,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 161,
      "name": "FormField",
      "parent": 135,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 162,
      "name": "FormField",
      "parent": 163,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 163,
      "name": "FormGroup",
      "parent": 129,
      "props": {
      },
      "seq": 163,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 166,
      "name": "FormField",
      "parent": 163,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 167,
      "name": "FormField",
      "parent": 163,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 167,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 168,
      "name": "FormField",
      "parent": 134,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 168,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "CardPACURecordReferralUX",
  "project": "IsHealth_by_Front-end",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
