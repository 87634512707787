import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Input,
  Button
} from 'semantic-ui-react'

const ModCheckDoctorCertificate = (props: any) => {
    return(
      <div>
        <div
          style={{ backgroundColor: "orange", color: "white", fontSize: "1.4em", padding: "8px", fontWeight: "bold"}}>
          ยืนยันการสั่งยา
        </div>
        <div
          style={{padding: "15px", display: "flex", flexDirection: "column"}}>
          
          <div
            style={{padding: "10px 0px", lineHeight: "2rem", fontSize: "1.2rem"}}>
            {props.narcoticTypeDetail || ""}
          </div>
          <div
            style={{...(props.hideInput ? {display:"none"} : {display: "flex"}), padding: "10px", alignItems: "center"}}>
            
            <div
              style={{flex: 3, fontWeight: "bold"}}>
              เลขที่ใบประกอบวิชาชีพเวชกรรมผู้สั่งยา
            </div>
            <div
              style={{marginRight:"4px"}}>
              ว
            </div>
            <Input
              onChange={props.onInputChange}
              style={{textAlign: "center", flex: 1}}
              value={props.doctorCertificate}>
            </Input>
            <div
              style={{color: "red", textAlign: "center", flex: 2}}>
              *ในช่องระบุเพียงตัวเลข ตัวอย่าง 11705
            </div>
          </div>
          <div
            style={{padding: "10px", display: "flex", alignItems: "center", justifyContent: "space-evenly"}}>
            
            <Button
              color="green"
              onClick={props.onApprove}
              style={{width: "200px"}}>
              ยืนยัน
            </Button>
            <Button
              color="red"
              onClick={props.onCancel}
              style={{width: "200px"}}>
              ยกเลิก
            </Button>
          </div>
        </div>
      </div>
    )
}


export default ModCheckDoctorCertificate

export const screenPropsDefault = {}

/* Date Time : Wed Jun 26 2024 05:06:10 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "ยืนยันการสั่งยา"
        },
        "style": {
          "type": "code",
          "value": "{ backgroundColor: \"orange\", color: \"white\", fontSize: \"1.4em\", padding: \"8px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"15px\", display: \"flex\", flexDirection: \"column\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "code",
          "value": "props.narcoticTypeDetail || \"\""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px 0px\", lineHeight: \"2rem\", fontSize: \"1.2rem\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",...(props.hideInput && {display:\"none\"}), padding: \"10px\", alignItems: \"center\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\", display: \"flex\", alignItems: \"center\", justifyContent: \"space-evenly\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขที่ใบประกอบวิชาชีพเวชกรรมผู้สั่งยา"
        },
        "style": {
          "type": "code",
          "value": "{flex: 3, fontWeight: \"bold\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "*ในช่องระบุเพียงตัวเลข ตัวอย่าง 11705"
        },
        "style": {
          "type": "code",
          "value": "{color: \"red\", textAlign: \"center\", flex: 2}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "Input",
      "parent": 10,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onInputChange"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"center\", flex: 1}"
        },
        "value": {
          "type": "code",
          "value": "props.doctorCertificate"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "Button",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": "ยืนยัน"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onApprove"
        },
        "style": {
          "type": "code",
          "value": "{width: \"200px\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "Button",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิก"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancel"
        },
        "style": {
          "type": "code",
          "value": "{width: \"200px\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "ว"
        },
        "style": {
          "type": "code",
          "value": "{marginRight:\"4px\"}"
        }
      },
      "seq": 15,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 50,
  "isMounted": false,
  "memo": false,
  "name": "ModCheckDoctorCertificate",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 50
}

*********************************************************************************** */
