import moment from "moment";

// Common
import { splitStringNewLine } from "../HISV3/common/CommonInterface";

// Utils
import { formatDatetime } from "react-lib/utils/dateUtils";
import { HeaderLogoWithPublishDetail } from "../HISV3/common/HeaderPdfFormTemplate";

const FORM_NAME = "FormAppointmentSummaryList";

// Types
type FormAppointmentSummaryProps = {
  divisionName: string;
  startDate: string;
  endDate: string;
  userName: string;
  data: {
    patient_hn: string;
    patient_name_text: string;
    tel_text: string;
    doctor_name_text: string;
    specialty: string;
    appointment_type_text: string;
    appointment_for_text: string;
    appointment_status_text: string;
    appointment_status_note: string;
    appointment_date: string;
    service_date_text: string;
    specialty_text: string;
  }[];
};

const FONT_SIZE = 14;
const NEW_LINE_OPTION = { fontSize: FONT_SIZE, ellipse: "" };

const FormAppointmentSummary = async (props: FormAppointmentSummaryProps) => {
  const currentDate = moment();

  const headerForm = await HeaderLogoWithPublishDetail({
    form: FORM_NAME,
    font: "THSarabunNew",
    logoHeight: 24,
    isHospitalVertical: true,
    pageMargins: [6.5, 75, 6.5, 15],
    headerMargins: [6.5, 6.5, 6.5, 0],
    styles: {
      fieldHeader: {
        fontSize: 15,
      },
      tableHeader: {
        bold: true,
      },
      miniField: {
        fontSize: 10,
      },
    },
    titleContent: [
      {
        text: "รายงานสรุปนัดหมาย",
        alignment: "center",
        bold: true,
        style: "fieldHeader",
      },
      {
        text: props.divisionName,
        alignment: "center",
        style: "fieldHeader",
        marginTop: -2.5,
      },
      {
        text: `วันที่ ${props.startDate || "-"} ถึง ${props.endDate || "-"}`,
        alignment: "center",
        style: "fieldHeader",
        marginTop: -2.5,
      },
    ],
    publishContent: [
      {
        text: [{ text: "ผู้พิมพ์:", bold: true }, { text: props.userName }],
        style: "miniField",
        alignment: "right",
      },
      {
        text: [{ text: "วันที่พิมพ์:", bold: true }, { text: formatDatetime(currentDate, true) }],
        style: "miniField",
        alignment: "right",
      },
    ],
  });
  const { font, images, styles } = headerForm;

  console.log("FormAppointmentSummary:", props);

  return {
    pageSize: "A4",
    defaultStyle: {
      font: font,
      lineHeight: 1,
      fontSize: FONT_SIZE,
    },
    ...styles,
    ...headerForm,
    content: [
      {
        table: {
          headerRows: 1,
          // heights: 100,
          widths: ["9%", "15.5%", "18%", "14%", "18.5%", "14%", "11%"],
          body: [
            [
              { text: "HN", style: "tableHeader" },
              { text: "ชื่อ-สกุล", style: "tableHeader" },
              { text: "แพทย์ (ความเชี่ยวชาญ)", style: "tableHeader" },
              { text: "วันที่เวลานัดหมาย", style: "tableHeader" },
              { text: "นัดหมายเพื่อ", style: "tableHeader" },
              { text: "สถานะนัดหมาย", style: "tableHeader" },
              { text: "วันที่รับบริการ", style: "tableHeader" },
            ],
            ...props.data.map((item, index) => {
              const appointmentFor = item.appointment_for_text
                ? `${item.appointment_for_text}`
                : "\u00a0";

              const specialtyLines = splitStringNewLine(item.specialty_text || "\u00a0", {
                width: 142,
                ...NEW_LINE_OPTION,
              });
              const doctorLines = splitStringNewLine(item.doctor_name_text, {
                width: 142,
                ...NEW_LINE_OPTION,
              });
              const nameLines = splitStringNewLine(item.patient_name_text, {
                width: 125,
                ...NEW_LINE_OPTION,
              });
              const appointmentForLines = splitStringNewLine(appointmentFor, {
                width: 149,
                ...NEW_LINE_OPTION,
              });

              const maxLine = Math.max(
                doctorLines.length + specialtyLines.length,
                nameLines.length + 1,
                appointmentForLines.length + 1
              );

              const addBlankLine = (stack: Record<string, any>[], key: string) => {
                const stackCount = stack.reduce((r, s) => r + (s.stack?.length || 1), 0);

                const stacks: any[] = [
                  {},
                  ...stack,
                  ...Array(maxLine - stackCount)
                    .fill("")
                    .map(() => ({ text: "\u00a0" })),
                  {},
                ];

                const lIndex = stacks.length - 1;

                stacks[0] = {
                  text: "\u00a0",
                  id: `table_row_${index}_${key}`,
                  marginBottom: -FONT_SIZE - 4,
                };

                stacks[lIndex] = {
                  text: "\u00a0",
                  id: `table_row_${index}_${key}_last_content`,
                  marginTop: -FONT_SIZE - 4,
                };

                return stacks;
              };

              return [
                {
                  stack: addBlankLine([{ text: item.patient_hn }], "patient_hn"),
                },
                {
                  stack: addBlankLine(
                    [
                      {
                        stack: nameLines.map((text) => ({
                          text: text.trim(),
                        })),
                      },
                      {
                        text: item.tel_text || "\u00a0",
                      },
                    ],
                    "name"
                  ),
                },
                {
                  stack: addBlankLine(
                    [
                      {
                        stack: doctorLines.map((text) => ({
                          text: text.trim(),
                        })),
                      },
                      {
                        stack: specialtyLines.map((text) => ({
                          text: text.trim() || "\u00a0",
                        })),
                      },
                    ],
                    "doctor"
                  ),
                },
                {
                  stack: addBlankLine(
                    [
                      {
                        text: item.appointment_date
                          ? item.appointment_date.split(" ")[0]
                          : "\u00a0",
                      },
                      {
                        text: item.appointment_date
                          ? item.appointment_date.split(" ")[1]
                          : "\u00a0",
                      },
                    ],
                    "app_date"
                  ),
                },
                {
                  stack: addBlankLine(
                    [
                      // { stack: [{ text: item.appointment_type_text }] },
                      {
                        stack: appointmentForLines.map((text) => ({
                          text: text.trim() || "\u00a0",
                        })),
                      },
                    ],
                    "type"
                  ),
                },
                {
                  stack: addBlankLine(
                    [
                      { text: item.appointment_status_text || "\u00a0" },
                      {
                        text: item.appointment_status_note || "\u00a0",
                      },
                    ],
                    "status"
                  ),
                },
                {
                  stack: addBlankLine(
                    [
                      {
                        text: item.service_date_text
                          ? item.service_date_text.split(" ")[0]
                          : "\u00a0",
                      },
                      {
                        text: item.service_date_text
                          ? item.service_date_text.split(" ")[1]
                          : "\u00a0",
                      },
                    ],
                    "service_date"
                  ),
                },
              ];
            }),
            Array(7)
              .fill("")
              .map((_, index) => ({
                id: `table_row_${index}_blank`,
                text: "",
              })),
          ],
        },
        layout: {
          hLineWidth: () => 0.25,
          vLineWidth: (i: any, node: any) => {
            return 0.25;
          },
          paddingBottom: (i: any, node: any) => {
            if (i === props.data.length + 1) {
              return 800 - node.positions[node.positions.length - 2]?.top || 0;
            } else {
              return 0;
            }
          },
        },
      },
    ],
    pageBreakBefore: (currentNode: any, followingNodesOnPage: any, nodesOnNextPage: any) => {
      const isPageBreakClass = currentNode.id?.includes("table_row");

      if (isPageBreakClass && !currentNode.id?.includes("last_content")) {
        const lastContent = [...followingNodesOnPage, ...nodesOnNextPage].find(
          (node: any) => node.id?.includes("last_content") && node.id?.includes(currentNode.id)
        );

        return lastContent?.pageNumbers?.toString() !== currentNode.pageNumbers.toString();
      }
    },
    images: {
      ...images,
    },
  };
};

export default FormAppointmentSummary;
