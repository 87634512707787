import config from '../config/config';
import fetch from 'node-fetch';
import FormData from 'form-data';

export async function preflight() {
    try{
      const response = await fetch(`${config.API_HOST}/preflight/`, {
        credentials: 'include',
      });
      const data = await response.json();
      // console.log('preflight response', data);
      return data;
    }catch(e){
      if(config.API_HOST.includes("localhost") || !config.API_HOST){
        window.location.href = "http://localhost:8000/"
        return
      }
      window.location = "/users/login/?next=/&comname="
    }
}

export async function login_micro(username, password, csrf, comname) {
  // Create a form to submit
  var form = new FormData();
  form.append("username", username);
  form.append("password", password);
  form.append("csrfmiddlewaretoken", csrf);

  // Construct url
  var url = new URL(`${config.API_HOST}/login_micro/`);
  url.searchParams.append("comname", comname);

  // Submit form to api
  const response = await fetch(url, {
    method: "POST",
    credentials: "include",
    body: form,
  });
  const status = await response.json();
  return status;
}

export async function getMaster() {
  const response = await fetch(
    `${config.API_HOST}/apis/core/nationality/?limit=99999`,
    {
      method: "GET",
      credentials: "include",
    }
  );
  const nationality = await response.json();
  const master = {
    nationality: nationality.items,
  };
  return master;
}

// To be deprecated
// export async function login(event, csrf_token) {
//   event.preventDefault();
//   const response = await fetch(`${config.API_HOST}/react_spa_login/`, {
//     method: 'POST',
//     credentials: 'include',
//     headers: {
//       'Accept': 'application/json',
//       'Content-Type': 'application/json',
//       'X-CSRFToken': csrf_token,
//     },
//     body: JSON.stringify({
//       username: event.target.username.value,
//       password: event.target.password.value,
//    })
//   });
//   const data = await response.json();
//   console.log(data);
//   return data;
// }
