import React, { useEffect } from "react";
import { Button } from "semantic-ui-react";
import { useIntl } from "react-intl";

const Debug = (props: any) => {
    const refresh = () => props.onEvent({message: "GetDebugInfo", params: {}})
    useEffect(() => { refresh() }, [])
    return (
      <div style={{ width: "80vw", height: "95vh", border: "solid #cccccc 1px" }}>
        <div style={{ height: "15vh", overflowY: "scroll", border: "solid #cccccc 1px"}}>
          <Button color="blue" onClick={(e: any) => refresh()}>Refresh</Button>
          <div>{
            Object.entries(props.debugInfo)
            .map((entry: any) => (`${entry[0]} (${entry[1].length})`))
            .sort()
            .join(", ")}
          </div>
        </div>
        <div style={{ overflowY: "scroll", height: "80vh" }}>
          {Object.keys(props.debugInfo).length > 0 &&
          <pre>{
            JSON.stringify(
              Object.fromEntries(
                Object.entries(props.debugInfo)
                      .sort((a: any, b: any) => a[0] < b[0] ? -1 : 1))
            || {}, null, 4)}
          </pre>}
        </div>
        
      </div>
    )
}

export default Debug