import moment from "moment";
import { formatDate } from "react-lib/utils/dateUtils";
import { HeaderLogoWithAddress } from "react-lib/apps/HISV3/common/HeaderPdfFormTemplate";

const FORM_NAME = "FormORCancelList";

const FormORCancelList = async (props: any) => {
  const headerForm = await HeaderLogoWithAddress({
    pageMargins: [15, 140, 0, 50],
    font: "THSarabunNew",
    form: FORM_NAME,
    logoHeight: 38,
    styles: {
      title: {
        bold: true,
        fontSize: 20,
        alignment: "center",
      },
      header: {
        fontSize: 18,
        alignment: "center",
      },
      tableHeader: {
        fontSize: 16,
        alignment: "center",
      },
      tableDetail: {
        fontSize: 14,
        alignment: "center",
      },
      tableInfo: {
        fontSize: 12,
        alignment: "center",
      },
      addressRightStyle: {
        fontSize: 12,
        alignment: "right",
      },
      addressLeftStyle: {
        fontSize: 12,
        alignment: "left",
      },
    },
    titleContent: [
      { text: "รายงานการยกเลิกการผ่าตัด", style: "title" },
      { text: `วันที่ ${props?.start_date} - ${props?.end_date}`, style: "header" },
    ],
  });
  const { font, images, styles } = headerForm;

  return {
    defaultStyle: {
      font: font,
      lineHeight: 1,
      fontSize: 12,
    },
    pageSize: "A4",
    pageOrientation: "landscape",
    ...styles,
    ...headerForm,
    content: [
      {
        margin: [0, 0, 10, 10], // Original [20, 0, 10, 10]
        table: {
          widths: ["4%", "11%", "12%", "12%", "14%", "*", "5%", "8%", "10%"],
          body: [
            [
              { text: "ลำดับ", style: "tableHeader", bold: true },
              { text: "วันที่ผ่าตัด", style: "tableHeader", bold: true },
              { text: "ข้อมูลผู้ป่วย", style: "tableHeader", bold: true },
              { text: "แพทย์ผ่าตัด", style: "tableHeader", bold: true },
              { text: "Diagnosis", style: "tableHeader", bold: true },
              { text: "Operation", style: "tableHeader", bold: true },
              { text: "Blood", style: "tableHeader", bold: true },
              { text: "Anesthesia", style: "tableHeader", bold: true },
              { text: "เหตุผล", style: "tableHeader", bold: true },
            ],
            ...(props?.data || []).map((item: any, index: number) => [
              { text: `${index + 1}.`, style: "tableDetail" },
              {
                stack: [
                  { text: item.start_date, style: "tableDetail" },
                  { text: item.location_name, style: "tableInfo" },
                  { text: item.operating_room_no, style: "tableInfo" },
                ],
              },
              {
                stack: [
                  { text: item.hn, style: "tableDetail" },
                  { text: item.patient_name, style: "tableInfo" },
                  { text: item.patient_tel, style: "tableInfo" },
                ],
              },
              { text: item.primary_doctor_name, style: "tableDetail" },
              { text: item.diagnosis, style: "tableDetail" },
              { text: item.pre_operation_summary, fontSize: 14 },
              { text: item.blood_request_summary, style: "tableDetail" },
              { text: item.anesthesia_method_name, style: "tableDetail" },
              { text: item.cancel_note, style: "tableDetail" },
            ]),
          ],
        },
      },
    ],
    footer: function (currentPage: number, pageCount: number) {
      const printDateMM = moment();
      return {
        stack: [
          {
            margin: [15, 0, 15, 0], // original [40, 0, 20, 0]
            columns: [
              {
                width: "50%",
                stack: [
                  {
                    text: `วันเวลาที่พิมพ์ : ${formatDate(printDateMM)} [${printDateMM.format(
                      "HH:mm"
                    )}]`,
                    style: "addressLeftStyle",
                  },
                  {
                    text: `ผู้ออกรายงาน : ${props?.print_user || ""}`,
                    style: "addressLeftStyle",
                  },
                ],
              },
              {
                width: "50%",
                stack: [
                  {
                    text: `หน้าที่ ${currentPage} / ${pageCount}`,
                    style: "addressRightStyle",
                  },
                ],
              },
            ],
          },
        ],
      };
    },
    images: {
      ...images,
    },
  };
};

export default FormORCancelList;
