import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Icon
} from 'semantic-ui-react'

const ModCoverageDrugDiscountUX = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{width: "100%"}}>
        <div
          style={{ padding: "14px 10px", fontWeight: "bold", color: "white", backgroundColor: "#5dbcd2", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          
          <div>
            ส่วนลดค่ายา
          </div>
          <div>
            
            <Icon
              link={true}
              name="close"
              onClick={props.onClose}>
            </Icon>
          </div>
        </div>
        <div
          style={{margin: "10px"}}>
          
          <Table
            data={props.data}
            headers=",Name,โครงการ,ส่วนสด"
            keys="_checkbox,name, project, discount"
            minRows={12}
            showPagination={false}
            style={{height: "420px"}}
            widths="50,^175,^150,^75">
          </Table>
        </div>
        <div
          style={{display: "flex", justifyContent: "flex-end", margin: "0 10px 10px"}}>
          
          <div>
            {props.ButtonEdit}
          </div>
        </div>
      </div>
    )
}


export default ModCoverageDrugDiscountUX

export const screenPropsDefault = {"adrDetail":"รายละเอียด","adrType":"LOCKED","needNote":true}

/* Date Time : Tue Mar 19 2024 11:46:18 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"14px 10px\", fontWeight: \"bold\", color: \"white\", backgroundColor: \"#5dbcd2\", display: \"flex\", alignItems: \"center\", justifyContent: \"space-between\" }"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 28,
      "name": "Table",
      "parent": 27,
      "props": {
        "data": {
          "type": "code",
          "value": "props.data"
        },
        "headers": {
          "type": "value",
          "value": ",Name,โครงการ,ส่วนสด"
        },
        "keys": {
          "type": "value",
          "value": "_checkbox,name, project, discount"
        },
        "minRows": {
          "type": "code",
          "value": "12"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"420px\"}"
        },
        "widths": {
          "type": "value",
          "value": "50,^175,^150,^75"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", margin: \"0 10px 10px\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 29,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonEdit"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": "ส่วนลดค่ายา"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "Icon",
      "parent": 32,
      "props": {
        "link": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "close"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        }
      },
      "seq": 33,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 35,
  "isMounted": true,
  "memo": false,
  "name": "ModCoverageDrugDiscountUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "adrDetail": "รายละเอียด",
    "adrType": "LOCKED",
    "needNote": true
  },
  "width": 25
}

*********************************************************************************** */
