import React from 'react'
import {
   Checkbox,
   Dropdown,
   Input,
} from 'semantic-ui-react'



import styles from './CardOTPhysicalExaminationSwallowingEvaluation1.module.css'

export interface CardOTPhysicalExaminationSwallowingEvaluation1ViewDataType {
    CardOtPhysicalSwallowing11: boolean,
    CardOtPhysicalSwallowing11Disabled: boolean,
    CardOtPhysicalSwallowing12: boolean,
    CardOtPhysicalSwallowing12Disabled: boolean,
    CardOtPhysicalSwallowing14: boolean,
    CardOtPhysicalSwallowing14Disabled: boolean,
    CardOtPhysicalSwallowing16: boolean,
    CardOtPhysicalSwallowing16Disabled: boolean,
    CardOtPhysicalSwallowing18: boolean,
    CardOtPhysicalSwallowing18Disabled: boolean,
    CardOtPhysicalSwallowing110: boolean,
    CardOtPhysicalSwallowing110Disabled: boolean,
    CardOtPhysicalSwallowing112: boolean,
    CardOtPhysicalSwallowing112Disabled: boolean,
    CardOtPhysicalSwallowing115: boolean,
    CardOtPhysicalSwallowing115Disabled: boolean,
    CardOtPhysicalSwallowing122: boolean,
    CardOtPhysicalSwallowing122Disabled: boolean,
    CardOtPhysicalSwallowing123: boolean,
    CardOtPhysicalSwallowing123Disabled: boolean,
    CardOtPhysicalSwallowing124: boolean,
    CardOtPhysicalSwallowing124Disabled: boolean,
    CardOtPhysicalSwallowing125: boolean,
    CardOtPhysicalSwallowing125Disabled: boolean,
    CardOtPhysicalSwallowing132: boolean,
    CardOtPhysicalSwallowing132Disabled: boolean,
    CardOtPhysicalSwallowing133: string,
    CardOtPhysicalSwallowing133Disabled: boolean,
    CardOtPhysicalSwallowing136: boolean,
    CardOtPhysicalSwallowing136Disabled: boolean,
    CardOtPhysicalSwallowing139: boolean,
    CardOtPhysicalSwallowing139Disabled: boolean,
    CardOtPhysicalSwallowing143: string,
    CardOtPhysicalSwallowing143Disabled: boolean,
    Ct3GeneralAppearance: string,
    Ct3GeneralAppearanceDisabled: boolean,
    Ct3GeneralAppearanceOptions: any,
    Ct3Consciousness: string,
    Ct3ConsciousnessDisabled: boolean,
    Ct3ConsciousnessOptions: any,
    Ct6Communication: string,
    Ct6CommunicationDisabled: boolean,
    Ct6CommunicationOptions: any,
    Ct6CoOperation: string,
    Ct6CoOperationDisabled: boolean,
    Ct6CoOperationOptions: any,
    Ct4Cognitive: string,
    Ct4CognitiveDisabled: boolean,
    Ct4CognitiveOptions: any,
    Ct3Attention: string,
    Ct3AttentionDisabled: boolean,
    Ct3AttentionOptions: any,
    Ct2Pathology: string,
    Ct2PathologyDisabled: boolean,
    Ct2PathologyOptions: any,
    Ct3Sensory: string,
    Ct3SensoryDisabled: boolean,
    Ct3SensoryOptions: any,
    CardOtPhysicalSwallowing177: string,
    CardOtPhysicalSwallowing177Disabled: boolean,
    CardOtPhysicalSwallowing178: boolean,
    CardOtPhysicalSwallowing178Disabled: boolean,
    CardOtPhysicalSwallowing179: string,
    CardOtPhysicalSwallowing179Disabled: boolean,
    CardOtPhysicalSwallowing182: boolean,
    CardOtPhysicalSwallowing182Disabled: boolean,
    CardOtPhysicalSwallowing183: string,
    CardOtPhysicalSwallowing183Disabled: boolean,
    CardOtPhysicalSwallowing185: boolean,
    CardOtPhysicalSwallowing185Disabled: boolean,
    CardOtPhysicalSwallowing188: boolean,
    CardOtPhysicalSwallowing188Disabled: boolean,
    Ct2SittingStatic: string,
    Ct2SittingStaticDisabled: boolean,
    Ct2SittingStaticOptions: any,
    Ct2SittingDynamic: string,
    Ct2SittingDynamicDisabled: boolean,
    Ct2SittingDynamicOptions: any,
    CardOtPhysicalSwallowing1103: string,
    CardOtPhysicalSwallowing1103Disabled: boolean,
    CardOtPhysicalSwallowing1104: string,
    CardOtPhysicalSwallowing1104Disabled: boolean,
    CardOtPhysicalSwallowing1109: string,
    CardOtPhysicalSwallowing1109Disabled: boolean,
    CtFollowCommand: string,
    CtFollowCommandDisabled: boolean,
    CtFollowCommandOptions: any,
    CtHeadAndNeck: string,
    CtHeadAndNeckDisabled: boolean,
    CtHeadAndNeckOptions: any,
    CtAbleToUpright: string,
    CtAbleToUprightDisabled: boolean,
    CtAbleToUprightOptions: any,
    CtDegree: string,
    CtDegreeDisabled: boolean,
    CtDegreeOptions: any,
    CtDentureUse: string,
    CtDentureUseDisabled: boolean,
    CtDentureUseOptions: any,
    CtSwallowFeeding: string,
    CtSwallowFeedingDisabled: boolean,
    CtSwallowFeedingOptions: any,
    CtRespriration: string,
    CtResprirationDisabled: boolean,
    CtResprirationOptions: any,
    CardOtPhysicalSwallowing1149: string,
    CardOtPhysicalSwallowing1149Disabled: boolean,
    CtRecurrentStroke: string,
    CtRecurrentStrokeDisabled: boolean,
    CtRecurrentStrokeOptions: any,
    CtCop: string,
    CtCopDisabled: boolean,
    CtCopOptions: any,
    CtUnderlyingOther: string,
    CtUnderlyingOtherDisabled: boolean,
    CtUnderlyingOtherOptions: any,
    CardOtPhysicalSwallowing1164: string,
    CardOtPhysicalSwallowing1164Disabled: boolean,
    CtJawMovement: string,
    CtJawMovementDisabled: boolean,
    CtJawMovementOptions: any,
    CtLipClosure: string,
    CtLipClosureDisabled: boolean,
    CtLipClosureOptions: any,
    CtCheekControl: string,
    CtCheekControlDisabled: boolean,
    CtCheekControlOptions: any,
    CtTongueMovement: string,
    CtTongueMovementDisabled: boolean,
    CtTongueMovementOptions: any,
    CtSoftPalete: string,
    CtSoftPaleteDisabled: boolean,
    CtSoftPaleteOptions: any,
    CtVoiceQuality: string,
    CtVoiceQualityDisabled: boolean,
    CtVoiceQualityOptions: any,
    CtVoluntaryCough: string,
    CtVoluntaryCoughDisabled: boolean,
    CtVoluntaryCoughOptions: any,
    CtSalivaControl: string,
    CtSalivaControlDisabled: boolean,
    CtSalivaControlOptions: any,
    CtGagReflex: string,
    CtGagReflexDisabled: boolean,
    CtGagReflexOptions: any,
    CtSwallowingReflex: string,
    CtSwallowingReflexDisabled: boolean,
    CtSwallowingReflexOptions: any,
    CtCoughReflex: string,
    CtCoughReflexDisabled: boolean,
    CtCoughReflexOptions: any,
    CtBiteReflex: string,
    CtBiteReflexDisabled: boolean,
    CtBiteReflexOptions: any,
}

export const CardOTPhysicalExaminationSwallowingEvaluation1InitialViewData: CardOTPhysicalExaminationSwallowingEvaluation1ViewDataType = {
    CardOtPhysicalSwallowing11: false,
    CardOtPhysicalSwallowing11Disabled: false,
    CardOtPhysicalSwallowing12: false,
    CardOtPhysicalSwallowing12Disabled: false,
    CardOtPhysicalSwallowing14: false,
    CardOtPhysicalSwallowing14Disabled: false,
    CardOtPhysicalSwallowing16: false,
    CardOtPhysicalSwallowing16Disabled: false,
    CardOtPhysicalSwallowing18: false,
    CardOtPhysicalSwallowing18Disabled: false,
    CardOtPhysicalSwallowing110: false,
    CardOtPhysicalSwallowing110Disabled: false,
    CardOtPhysicalSwallowing112: false,
    CardOtPhysicalSwallowing112Disabled: false,
    CardOtPhysicalSwallowing115: false,
    CardOtPhysicalSwallowing115Disabled: false,
    CardOtPhysicalSwallowing122: false,
    CardOtPhysicalSwallowing122Disabled: false,
    CardOtPhysicalSwallowing123: false,
    CardOtPhysicalSwallowing123Disabled: false,
    CardOtPhysicalSwallowing124: false,
    CardOtPhysicalSwallowing124Disabled: false,
    CardOtPhysicalSwallowing125: false,
    CardOtPhysicalSwallowing125Disabled: false,
    CardOtPhysicalSwallowing132: false,
    CardOtPhysicalSwallowing132Disabled: false,
    CardOtPhysicalSwallowing133: "",
    CardOtPhysicalSwallowing133Disabled: false,
    CardOtPhysicalSwallowing136: false,
    CardOtPhysicalSwallowing136Disabled: false,
    CardOtPhysicalSwallowing139: false,
    CardOtPhysicalSwallowing139Disabled: false,
    CardOtPhysicalSwallowing143: "",
    CardOtPhysicalSwallowing143Disabled: false,
    Ct3GeneralAppearance: "",
    Ct3GeneralAppearanceDisabled: false,
    Ct3GeneralAppearanceOptions: [{key: 0, value: "A", text: "A"}],
    Ct3Consciousness: "",
    Ct3ConsciousnessDisabled: false,
    Ct3ConsciousnessOptions: [{key: 0, value: "A", text: "A"}],
    Ct6Communication: "",
    Ct6CommunicationDisabled: false,
    Ct6CommunicationOptions: [{key: 0, value: "A", text: "A"}],
    Ct6CoOperation: "",
    Ct6CoOperationDisabled: false,
    Ct6CoOperationOptions: [{key: 0, value: "A", text: "A"}],
    Ct4Cognitive: "",
    Ct4CognitiveDisabled: false,
    Ct4CognitiveOptions: [{key: 0, value: "A", text: "A"}],
    Ct3Attention: "",
    Ct3AttentionDisabled: false,
    Ct3AttentionOptions: [{key: 0, value: "A", text: "A"}],
    Ct2Pathology: "",
    Ct2PathologyDisabled: false,
    Ct2PathologyOptions: [{key: 0, value: "A", text: "A"}],
    Ct3Sensory: "",
    Ct3SensoryDisabled: false,
    Ct3SensoryOptions: [{key: 0, value: "A", text: "A"}],
    CardOtPhysicalSwallowing177: "",
    CardOtPhysicalSwallowing177Disabled: false,
    CardOtPhysicalSwallowing178: false,
    CardOtPhysicalSwallowing178Disabled: false,
    CardOtPhysicalSwallowing179: "",
    CardOtPhysicalSwallowing179Disabled: false,
    CardOtPhysicalSwallowing182: false,
    CardOtPhysicalSwallowing182Disabled: false,
    CardOtPhysicalSwallowing183: "",
    CardOtPhysicalSwallowing183Disabled: false,
    CardOtPhysicalSwallowing185: false,
    CardOtPhysicalSwallowing185Disabled: false,
    CardOtPhysicalSwallowing188: false,
    CardOtPhysicalSwallowing188Disabled: false,
    Ct2SittingStatic: "",
    Ct2SittingStaticDisabled: false,
    Ct2SittingStaticOptions: [{key: 0, value: "A", text: "A"}],
    Ct2SittingDynamic: "",
    Ct2SittingDynamicDisabled: false,
    Ct2SittingDynamicOptions: [{key: 0, value: "A", text: "A"}],
    CardOtPhysicalSwallowing1103: "",
    CardOtPhysicalSwallowing1103Disabled: false,
    CardOtPhysicalSwallowing1104: "",
    CardOtPhysicalSwallowing1104Disabled: false,
    CardOtPhysicalSwallowing1109: "",
    CardOtPhysicalSwallowing1109Disabled: false,
    CtFollowCommand: "",
    CtFollowCommandDisabled: false,
    CtFollowCommandOptions: [{key: 0, value: "A", text: "A"}],
    CtHeadAndNeck: "",
    CtHeadAndNeckDisabled: false,
    CtHeadAndNeckOptions: [{key: 0, value: "A", text: "A"}],
    CtAbleToUpright: "",
    CtAbleToUprightDisabled: false,
    CtAbleToUprightOptions: [{key: 0, value: "A", text: "A"}],
    CtDegree: "",
    CtDegreeDisabled: false,
    CtDegreeOptions: [{key: 0, value: "A", text: "A"}],
    CtDentureUse: "",
    CtDentureUseDisabled: false,
    CtDentureUseOptions: [{key: 0, value: "A", text: "A"}],
    CtSwallowFeeding: "",
    CtSwallowFeedingDisabled: false,
    CtSwallowFeedingOptions: [{key: 0, value: "A", text: "A"}],
    CtRespriration: "",
    CtResprirationDisabled: false,
    CtResprirationOptions: [{key: 0, value: "A", text: "A"}],
    CardOtPhysicalSwallowing1149: "",
    CardOtPhysicalSwallowing1149Disabled: false,
    CtRecurrentStroke: "",
    CtRecurrentStrokeDisabled: false,
    CtRecurrentStrokeOptions: [{key: 0, value: "A", text: "A"}],
    CtCop: "",
    CtCopDisabled: false,
    CtCopOptions: [{key: 0, value: "A", text: "A"}],
    CtUnderlyingOther: "",
    CtUnderlyingOtherDisabled: false,
    CtUnderlyingOtherOptions: [{key: 0, value: "A", text: "A"}],
    CardOtPhysicalSwallowing1164: "",
    CardOtPhysicalSwallowing1164Disabled: false,
    CtJawMovement: "",
    CtJawMovementDisabled: false,
    CtJawMovementOptions: [{key: 0, value: "A", text: "A"}],
    CtLipClosure: "",
    CtLipClosureDisabled: false,
    CtLipClosureOptions: [{key: 0, value: "A", text: "A"}],
    CtCheekControl: "",
    CtCheekControlDisabled: false,
    CtCheekControlOptions: [{key: 0, value: "A", text: "A"}],
    CtTongueMovement: "",
    CtTongueMovementDisabled: false,
    CtTongueMovementOptions: [{key: 0, value: "A", text: "A"}],
    CtSoftPalete: "",
    CtSoftPaleteDisabled: false,
    CtSoftPaleteOptions: [{key: 0, value: "A", text: "A"}],
    CtVoiceQuality: "",
    CtVoiceQualityDisabled: false,
    CtVoiceQualityOptions: [{key: 0, value: "A", text: "A"}],
    CtVoluntaryCough: "",
    CtVoluntaryCoughDisabled: false,
    CtVoluntaryCoughOptions: [{key: 0, value: "A", text: "A"}],
    CtSalivaControl: "",
    CtSalivaControlDisabled: false,
    CtSalivaControlOptions: [{key: 0, value: "A", text: "A"}],
    CtGagReflex: "",
    CtGagReflexDisabled: false,
    CtGagReflexOptions: [{key: 0, value: "A", text: "A"}],
    CtSwallowingReflex: "",
    CtSwallowingReflexDisabled: false,
    CtSwallowingReflexOptions: [{key: 0, value: "A", text: "A"}],
    CtCoughReflex: "",
    CtCoughReflexDisabled: false,
    CtCoughReflexOptions: [{key: 0, value: "A", text: "A"}],
    CtBiteReflex: "",
    CtBiteReflexDisabled: false,
    CtBiteReflexOptions: [{key: 0, value: "A", text: "A"}],
}

const Sub = (props: any) => {
    const intl = useIntl();
    const children = React.Children.toArray(props.children)
    return (
        <div className={styles.container}>
            <div className={styles.CardOtPhysicalSwallowing1240}>
                <div
                    style={{backgroundColor: "#F3F3F3", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalSwallowing11}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalSwallowing11}
                    disabled={ props.viewData.CardOtPhysicalSwallowing11Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "CardOtPhysicalSwallowing11"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalSwallowing12}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalSwallowing12}
                    disabled={ props.viewData.CardOtPhysicalSwallowing12Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "CardOtPhysicalSwallowing12"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalSwallowing13}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Observation</strong></p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalSwallowing14}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalSwallowing14}
                    disabled={ props.viewData.CardOtPhysicalSwallowing14Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "CardOtPhysicalSwallowing14"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalSwallowing15}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Normal</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalSwallowing16}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalSwallowing16}
                    disabled={ props.viewData.CardOtPhysicalSwallowing16Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "CardOtPhysicalSwallowing16"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalSwallowing17}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Wheelchair</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalSwallowing18}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalSwallowing18}
                    disabled={ props.viewData.CardOtPhysicalSwallowing18Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "CardOtPhysicalSwallowing18"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalSwallowing19}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Stroller</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalSwallowing110}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalSwallowing110}
                    disabled={ props.viewData.CardOtPhysicalSwallowing110Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "CardOtPhysicalSwallowing110"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalSwallowing111}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Cane/walker</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalSwallowing112}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalSwallowing112}
                    disabled={ props.viewData.CardOtPhysicalSwallowing112Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "CardOtPhysicalSwallowing112"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalSwallowing114}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p>Arm sling</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalSwallowing115}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalSwallowing115}
                    disabled={ props.viewData.CardOtPhysicalSwallowing115Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "CardOtPhysicalSwallowing115"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalSwallowing117}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>On Ventilator</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalSwallowing118}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>On ET</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalSwallowing121}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>TT tube</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalSwallowing122}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalSwallowing122}
                    disabled={ props.viewData.CardOtPhysicalSwallowing122Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "CardOtPhysicalSwallowing122"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalSwallowing123}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalSwallowing123}
                    disabled={ props.viewData.CardOtPhysicalSwallowing123Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "CardOtPhysicalSwallowing123"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalSwallowing124}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalSwallowing124}
                    disabled={ props.viewData.CardOtPhysicalSwallowing124Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "CardOtPhysicalSwallowing124"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalSwallowing125}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalSwallowing125}
                    disabled={ props.viewData.CardOtPhysicalSwallowing125Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "CardOtPhysicalSwallowing125"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalSwallowing129}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>On Foley's catheter</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalSwallowing130}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p>On IV fluid</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalSwallowing132}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalSwallowing132}
                    disabled={ props.viewData.CardOtPhysicalSwallowing132Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "CardOtPhysicalSwallowing132"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalSwallowing133}>
                <Input
                    value={props.viewData.CardOtPhysicalSwallowing133}
                    disabled={ props.viewData.CardOtPhysicalSwallowing133Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTPhysicalExaminationSwallowingEvaluation1',
                            name: 'CardOtPhysicalSwallowing133',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CardOtPhysicalSwallowing135}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>On ICD drainage</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalSwallowing136}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalSwallowing136}
                    disabled={ props.viewData.CardOtPhysicalSwallowing136Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "CardOtPhysicalSwallowing136"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalSwallowing138}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p>NG Tube</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalSwallowing139}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalSwallowing139}
                    disabled={ props.viewData.CardOtPhysicalSwallowing139Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "CardOtPhysicalSwallowing139"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalSwallowing140}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>OG Tube</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalSwallowing141}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p>PEG</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalSwallowing142}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Other</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalSwallowing143}>
                <Input
                    value={props.viewData.CardOtPhysicalSwallowing143}
                    disabled={ props.viewData.CardOtPhysicalSwallowing143Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTPhysicalExaminationSwallowingEvaluation1',
                            name: 'CardOtPhysicalSwallowing143',
                            value: e.target.value
                        }
                    })}}
                    style={{ width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CardOtPhysicalSwallowing144}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>General appearance</strong></p>
                </div>
            </div>
            <div className={styles.Ct3GeneralAppearance}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct3GeneralAppearance}
                    disabled={ props.viewData.Ct3GeneralAppearanceDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "Ct3GeneralAppearance",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct3GeneralAppearanceOptions}
                />
            </div>
            <div className={styles.Ct3Consciousness}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct3Consciousness}
                    disabled={ props.viewData.Ct3ConsciousnessDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "Ct3Consciousness",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct3ConsciousnessOptions}
                />
            </div>
            <div className={styles.Ct6Communication}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct6Communication}
                    disabled={ props.viewData.Ct6CommunicationDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "Ct6Communication",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct6CommunicationOptions}
                />
            </div>
            <div className={styles.Ct6CoOperation}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct6CoOperation}
                    disabled={ props.viewData.Ct6CoOperationDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "Ct6CoOperation",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct6CoOperationOptions}
                />
            </div>
            <div className={styles.CardOtPhysicalSwallowing158}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Consciousness</strong></p>
                </div>
            </div>
            <div className={styles.Ct4Cognitive}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct4Cognitive}
                    disabled={ props.viewData.Ct4CognitiveDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "Ct4Cognitive",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct4CognitiveOptions}
                />
            </div>
            <div className={styles.CardOtPhysicalSwallowing162}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p><strong>Communication</strong></p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalSwallowing163}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Co-operation</strong></p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalSwallowing164}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p><strong>Cognitive</strong></p>
                </div>
            </div>
            <div className={styles.Ct3Attention}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct3Attention}
                    disabled={ props.viewData.Ct3AttentionDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "Ct3Attention",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct3AttentionOptions}
                />
            </div>
            <div className={styles.Ct2Pathology}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct2Pathology}
                    disabled={ props.viewData.Ct2PathologyDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "Ct2Pathology",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct2PathologyOptions}
                />
            </div>
            <div className={styles.Ct3Sensory}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct3Sensory}
                    disabled={ props.viewData.Ct3SensoryDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "Ct3Sensory",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct3SensoryOptions}
                />
            </div>
            <div className={styles.CardOtPhysicalSwallowing174}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Attention</strong></p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalSwallowing175}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Pathology</strong></p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalSwallowing176}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Sensory</strong></p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalSwallowing177}>
                <Input
                    value={props.viewData.CardOtPhysicalSwallowing177}
                    disabled={ props.viewData.CardOtPhysicalSwallowing177Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTPhysicalExaminationSwallowingEvaluation1',
                            name: 'CardOtPhysicalSwallowing177',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CardOtPhysicalSwallowing178}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalSwallowing178}
                    disabled={ props.viewData.CardOtPhysicalSwallowing178Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "CardOtPhysicalSwallowing178"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalSwallowing179}>
                <Input
                    value={props.viewData.CardOtPhysicalSwallowing179}
                    disabled={ props.viewData.CardOtPhysicalSwallowing179Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTPhysicalExaminationSwallowingEvaluation1',
                            name: 'CardOtPhysicalSwallowing179',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CardOtPhysicalSwallowing180}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Muscle tone</strong></p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalSwallowing182}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalSwallowing182}
                    disabled={ props.viewData.CardOtPhysicalSwallowing182Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "CardOtPhysicalSwallowing182"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalSwallowing183}>
                <Input
                    value={props.viewData.CardOtPhysicalSwallowing183}
                    disabled={ props.viewData.CardOtPhysicalSwallowing183Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTPhysicalExaminationSwallowingEvaluation1',
                            name: 'CardOtPhysicalSwallowing183',
                            value: e.target.value
                        }
                    })}}
                    style={{ width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CardOtPhysicalSwallowing184}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Normal</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalSwallowing185}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalSwallowing185}
                    disabled={ props.viewData.CardOtPhysicalSwallowing185Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "CardOtPhysicalSwallowing185"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalSwallowing186}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Spasticity</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalSwallowing188}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalSwallowing188}
                    disabled={ props.viewData.CardOtPhysicalSwallowing188Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "CardOtPhysicalSwallowing188"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalSwallowing190}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Flaccid</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalSwallowing191}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>N/A</p>
                </div>
            </div>
            <div className={styles.Ct2SittingStatic}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct2SittingStatic}
                    disabled={ props.viewData.Ct2SittingStaticDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "Ct2SittingStatic",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct2SittingStaticOptions}
                />
            </div>
            <div className={styles.Ct2SittingDynamic}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct2SittingDynamic}
                    disabled={ props.viewData.Ct2SittingDynamicDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "Ct2SittingDynamic",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct2SittingDynamicOptions}
                />
            </div>
            <div className={styles.CardOtPhysicalSwallowing197}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Static</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalSwallowing198}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Balance test (Sitting)</strong></p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalSwallowing1102}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Dynamic</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalSwallowing1103}>
                <Input
                    value={props.viewData.CardOtPhysicalSwallowing1103}
                    disabled={ props.viewData.CardOtPhysicalSwallowing1103Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTPhysicalExaminationSwallowingEvaluation1',
                            name: 'CardOtPhysicalSwallowing1103',
                            value: e.target.value
                        }
                    })}}
                    style={{ width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CardOtPhysicalSwallowing1104}>
                <Input
                    value={props.viewData.CardOtPhysicalSwallowing1104}
                    disabled={ props.viewData.CardOtPhysicalSwallowing1104Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTPhysicalExaminationSwallowingEvaluation1',
                            name: 'CardOtPhysicalSwallowing1104',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CardOtPhysicalSwallowing1105}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Glasgow coma scale</strong></p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalSwallowing1107}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>E</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalSwallowing1109}>
                <Input
                    value={props.viewData.CardOtPhysicalSwallowing1109}
                    disabled={ props.viewData.CardOtPhysicalSwallowing1109Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTPhysicalExaminationSwallowingEvaluation1',
                            name: 'CardOtPhysicalSwallowing1109',
                            value: e.target.value
                        }
                    })}}
                    style={{ width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CardOtPhysicalSwallowing1110}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>V</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalSwallowing1111}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>M</p>
                </div>
            </div>
            <div className={styles.CtFollowCommand}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtFollowCommand}
                    disabled={ props.viewData.CtFollowCommandDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "CtFollowCommand",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtFollowCommandOptions}
                />
            </div>
            <div className={styles.CtHeadAndNeck}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtHeadAndNeck}
                    disabled={ props.viewData.CtHeadAndNeckDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "CtHeadAndNeck",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtHeadAndNeckOptions}
                />
            </div>
            <div className={styles.CardOtPhysicalSwallowing1118}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Follow command 1 step</strong></p>
                </div>
            </div>
            <div className={styles.CtAbleToUpright}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtAbleToUpright}
                    disabled={ props.viewData.CtAbleToUprightDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "CtAbleToUpright",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtAbleToUprightOptions}
                />
            </div>
            <div className={styles.CtDegree}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtDegree}
                    disabled={ props.viewData.CtDegreeDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "CtDegree",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtDegreeOptions}
                />
            </div>
            <div className={styles.CardOtPhysicalSwallowing1129}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Head and neck control</strong></p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalSwallowing1133}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Able to upright position -15 min</strong></p>
                </div>
            </div>
            <div className={styles.CtDentureUse}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtDentureUse}
                    disabled={ props.viewData.CtDentureUseDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "CtDentureUse",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtDentureUseOptions}
                />
            </div>
            <div className={styles.CtSwallowFeeding}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtSwallowFeeding}
                    disabled={ props.viewData.CtSwallowFeedingDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "CtSwallowFeeding",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtSwallowFeedingOptions}
                />
            </div>
            <div className={styles.CardOtPhysicalSwallowing1145}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Dentures use</strong></p>
                </div>
            </div>
            <div className={styles.CtRespriration}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtRespriration}
                    disabled={ props.viewData.CtResprirationDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "CtRespriration",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtResprirationOptions}
                />
            </div>
            <div className={styles.CardOtPhysicalSwallowing1149}>
                <Input
                    value={props.viewData.CardOtPhysicalSwallowing1149}
                    disabled={ props.viewData.CardOtPhysicalSwallowing1149Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTPhysicalExaminationSwallowingEvaluation1',
                            name: 'CardOtPhysicalSwallowing1149',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CardOtPhysicalSwallowing1151}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Feeding</strong></p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalSwallowing1152}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Respiration</strong></p>
                </div>
            </div>
            <div className={styles.CtRecurrentStroke}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtRecurrentStroke}
                    disabled={ props.viewData.CtRecurrentStrokeDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "CtRecurrentStroke",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtRecurrentStrokeOptions}
                />
            </div>
            <div className={styles.CtCop}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtCop}
                    disabled={ props.viewData.CtCopDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "CtCop",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtCopOptions}
                />
            </div>
            <div className={styles.CardOtPhysicalSwallowing1159}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Recurrent stroke</strong></p>
                </div>
            </div>
            <div className={styles.CtUnderlyingOther}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtUnderlyingOther}
                    disabled={ props.viewData.CtUnderlyingOtherDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "CtUnderlyingOther",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtUnderlyingOtherOptions}
                />
            </div>
            <div className={styles.CardOtPhysicalSwallowing1164}>
                <Input
                    value={props.viewData.CardOtPhysicalSwallowing1164}
                    disabled={ props.viewData.CardOtPhysicalSwallowing1164Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTPhysicalExaminationSwallowingEvaluation1',
                            name: 'CardOtPhysicalSwallowing1164',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CardOtPhysicalSwallowing1165}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>COPD</strong></p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalSwallowing1168}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>{intl.formatMessage({ id: "Underlying อื่นๆ ที่เกี่ยวข้องกับกลืน" })}</strong></p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalSwallowing1169}>
                <div
                    style={{backgroundColor: "#E0E0E0", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.CtJawMovement}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtJawMovement}
                    disabled={ props.viewData.CtJawMovementDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "CtJawMovement",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtJawMovementOptions}
                />
            </div>
            <div className={styles.CtLipClosure}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtLipClosure}
                    disabled={ props.viewData.CtLipClosureDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "CtLipClosure",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtLipClosureOptions}
                />
            </div>
            <div className={styles.CardOtPhysicalSwallowing1176}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Jaw movement</p>
                </div>
            </div>
            <div className={styles.CtCheekControl}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtCheekControl}
                    disabled={ props.viewData.CtCheekControlDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "CtCheekControl",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtCheekControlOptions}
                />
            </div>
            <div className={styles.CardOtPhysicalSwallowing1181}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Lip closure</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalSwallowing1183}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Cheek control</p>
                </div>
            </div>
            <div className={styles.CtTongueMovement}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtTongueMovement}
                    disabled={ props.viewData.CtTongueMovementDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "CtTongueMovement",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtTongueMovementOptions}
                />
            </div>
            <div className={styles.CtSoftPalete}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtSoftPalete}
                    disabled={ props.viewData.CtSoftPaleteDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "CtSoftPalete",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtSoftPaleteOptions}
                />
            </div>
            <div className={styles.CardOtPhysicalSwallowing1191}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Tongue movement</p>
                </div>
            </div>
            <div className={styles.CtVoiceQuality}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtVoiceQuality}
                    disabled={ props.viewData.CtVoiceQualityDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "CtVoiceQuality",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtVoiceQualityOptions}
                />
            </div>
            <div className={styles.CardOtPhysicalSwallowing1196}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Soft palete “say ahh”</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalSwallowing1201}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Voice quality</p>
                </div>
            </div>
            <div className={styles.CtVoluntaryCough}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtVoluntaryCough}
                    disabled={ props.viewData.CtVoluntaryCoughDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "CtVoluntaryCough",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtVoluntaryCoughOptions}
                />
            </div>
            <div className={styles.CtSalivaControl}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtSalivaControl}
                    disabled={ props.viewData.CtSalivaControlDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "CtSalivaControl",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtSalivaControlOptions}
                />
            </div>
            <div className={styles.CardOtPhysicalSwallowing1208}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Voluntary cough</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalSwallowing1210}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Saliva control</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalSwallowing1214}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Functional test</strong></p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalSwallowing1216}>
                <div
                    style={{backgroundColor: "#E0E0E0", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.CtGagReflex}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtGagReflex}
                    disabled={ props.viewData.CtGagReflexDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "CtGagReflex",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtGagReflexOptions}
                />
            </div>
            <div className={styles.CtSwallowingReflex}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtSwallowingReflex}
                    disabled={ props.viewData.CtSwallowingReflexDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "CtSwallowingReflex",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtSwallowingReflexOptions}
                />
            </div>
            <div className={styles.CtCoughReflex}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtCoughReflex}
                    disabled={ props.viewData.CtCoughReflexDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "CtCoughReflex",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtCoughReflexOptions}
                />
            </div>
            <div className={styles.CardOtPhysicalSwallowing1227}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Gag reflex</p>
                </div>
            </div>
            <div className={styles.CtBiteReflex}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtBiteReflex}
                    disabled={ props.viewData.CtBiteReflexDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationSwallowingEvaluation1",
                                name: "CtBiteReflex",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtBiteReflexOptions}
                />
            </div>
            <div className={styles.CardOtPhysicalSwallowing1231}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Swallowing reflex</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalSwallowing1233}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Cough reflex</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalSwallowing1236}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Bite reflex</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalSwallowing1239}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Reflexes</strong></p>
                </div>
            </div>
        </div>
    )
}

export default Sub

const getTsName = (cssClass: string) => {
    return cssClass.split('-').map((s) => (
        s.charAt(0).toUpperCase() + s.slice(1)
    )).join('')
}
