import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Checkbox, Modal } from "semantic-ui-react";

// UX
import ModCoverageDrugDiscountUX from "./ModCoverageDrugDiscountUX";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import { useIntl } from "react-intl";

// Types
type ModCoverageDrugDiscountProps = {
  setProp: (key: string, value: any, callback?: Function) => any;
  // data
  open: boolean;
  encounterId?: number;
  orderId?: number;
  items: Record<string, any>[];
  action: keyof typeof ACTION_TYPE;
  // callback
  onEditDiscount: (params: { encounterId?: number; orderId?: number; items?: any[] }) => any;
  onClose: () => any;
};

const ACTION_TYPE = {
  EDIT: "EDIT",
  APPLY: "APPLY",
};

const ModCoverageDrugDiscount = (props: ModCoverageDrugDiscountProps) => {
  const [items, setItems] = useState<Record<string, any>[]>([]);

  const addRemoveData = useCallback((data: Record<string, any>, checked: boolean) => {
    if (checked) {
      return [{ ...data, remove: false }];
    } else if (data.need_remove) {
      return [{ ...data, remove: true }];
    } else {
      return [];
    }
  }, []);

  // Effect
  useEffect(() => {
    if (props.open) {
      const mapItems = props.items.flatMap((item) => {
        // * หากเป็น apply ให้ default check
        const checked = item.checked || props.action === ACTION_TYPE.APPLY;

        return addRemoveData(item, checked);
      });

      setItems(mapItems);
    }
  }, [props.items, props.open, props.action]);

  // Callback
  const handleChecked = useCallback(
    (item: Record<string, any>) => (e: any, data: any) => {
      let cloneItems = [...items];
      const target = cloneItems.find((acc) => acc.id === item.id);

      if (target) {
        cloneItems = cloneItems.flatMap((acc) =>
          item.id === acc.id ? addRemoveData(item, data.checked) : [acc]
        );
      } else {
        cloneItems.push({ ...item, remove: false });
      }

      setItems(cloneItems);
    },
    [items]
  );

  // Memo
  const allowConfirm = useMemo(() => {
    return props.action === ACTION_TYPE.EDIT ? true : items.some((item) => !item.remove);
  }, [items, props.action]);

  const discountItems = useMemo(() => {
    return props.items.map((item) => {
      const isRemove = items.find((acc) => acc.id === item.id)?.remove;

      return {
        ...item,
        _checkbox: (
          <div style={{ display: "grid", placeContent: "center" }}>
            <Checkbox
              // Checked ที่ isRemove ไม่เป็น true และ undefined
              checked={isRemove === false}
              onChange={handleChecked(item)}
            />
          </div>
        ),
      };
    });
  }, [props.items, items]);

  // Handler
  const handleEdit = () => {
    props.onEditDiscount({
      encounterId: props.encounterId,
      orderId: props.orderId,
      items: items,
    });
  };

  const handleClose = () => {
    if (props.action === ACTION_TYPE.APPLY) {
      props.onEditDiscount({ encounterId: props.encounterId, orderId: props.orderId });
    } else {
      props.onClose();
    }
  };

  return (
    <Modal
      open={props.open}
      closeOnDimmerClick={true}
      size="small"
      style={{ overflow: "hidden" }}
      onClose={handleClose}
    >
      <ModCoverageDrugDiscountUX
        // data
        data={discountItems}
        // callback
        onClose={handleClose}
        // Element
        ButtonEdit={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleEdit}
            // data
            // paramKey={ACTION_EDIT}
            // buttonLoadCheck={props.buttonLoadCheck?.[ACTION_EDIT]}
            // config
            disabled={!allowConfirm}
            color={props.action === ACTION_TYPE.EDIT ? "yellow" : "blue"}
            name={props.action}
            size="small"
            title={props.action === ACTION_TYPE.EDIT ? "EDIT Discount" : "Apply Discount"}
          />
        }
      />
    </Modal>
  );
};

ModCoverageDrugDiscount.displayName = "ModCoverageDrugDiscount";

export default React.memo(ModCoverageDrugDiscount);
