
/* Generated apis ---------------------- */
import { to } from 'react-lib/utils'
import BaseService from  'react-lib/apis/services/BaseService'
import config from '../../config/config'
import UserSerializer from '../types/UserSerializer_core'


const HOST = `${config.API_HOST}`
/* ['generics.ListAPIView'] */
/* params: 'pk', 'full_name', 'role', 'nurse_or_doctor', 'name_username', 'name_code' */
/* data:  */
const UserListM : 
{
  list:     ({params, apiToken, extra}:
             { 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  list: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${HOST}/users/apis/users/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

export default UserListM

