import React from "react";
import { IntlProvider, FormattedMessage, useIntl } from "react-intl";

import { Checkbox, Button } from "semantic-ui-react";
import { Table } from "react-lib/frameworks/Table";

const CardInpatientDiagnosisUX = (props: any) => {
  return (
    <div>
      <div style={{ padding: "30px 20px 20px" }}>
        <div style={{ display: "flex", marginBottom: "10px" }}>
          <label style={{ margin: "10px 10px 0px 0px", fontWeight: "bold" }}>
            วันที่
          </label>
          <div style={{ marginRight: "10px" }}>{props.startDateTextBox}</div>
          <label style={{ margin: "10px 10px 0px 0px", fontWeight: "bold" }}>
            ถึง
          </label>
          <div style={{ marginRight: "10px" }}>{props.endDateTextBox}</div>
          <Checkbox
            checked={
              props.InpatientDiagnosisSequence?.diagFilter?.checkedIcd10Code
            }
            label="ICD-10 Code"
            name="checkedIcd10Code"
            onChange={props.onChangeFilter}
            style={{ marginTop: "10px", fontWeight: "bold" }}
          ></Checkbox>
          <div style={{ marginRight: "10px" }}>{props.icd10CodeSearchBox}</div>
          <Checkbox
            checked={
              props.InpatientDiagnosisSequence?.diagFilter?.checkedIcd10Term
            }
            label="ICD-10 Term"
            name="checkedIcd10Term"
            onChange={props.onChangeFilter}
            style={{ marginTop: "10px", fontWeight: "bold" }}
          ></Checkbox>
          <div style={{ marginRight: "5px" }}>{props.icd10TermSearchBox}</div>
          <div style={{ marginRight: "5px" }}>{props.buttonSearch}</div>
          <div style={{ marginRight: "5px" }}>{props.buttonPrint}</div>
          <Button
            color="yellow"
            onClick={props.onClear}
            size="small"
            style={{ marginRight: "10px" }}
          >
            ล้างการค้นหา
          </Button>
        </div>
      </div>
      <div
        style={{
          padding: "0px 20px",
          display: props.showStatistic ? "none" : "",
        }}
      >
        <Table
          data={props.InpatientDiagnosisSequence?.diagStatList || []}
          headers="ลำดับ, รหัสโรค / ICD-10 Code, Diagnosis / ICD-10 Term, วันนอน (เฉลี่ย)"
          keys="no, icd10_code, icd10_term, avg_admit_date"
          minRows={10}
          widths="70, auto, auto, 100"
        ></Table>
      </div>
    </div>
  );
};

export default CardInpatientDiagnosisUX;

export const screenPropsDefault = {};

/* Date Time : Fri Mar 17 2023 17:06:13 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"30px 20px 20px\" }"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", marginBottom: \"10px\" }"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"0px 20px\", display: props.showStatistic ? \"none\" : \"\" }"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "Checkbox",
      "parent": 12,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.InpatientDiagnosisSequence?.diagFilter?.checkedIcd10Code"
        },
        "label": {
          "type": "value",
          "value": "ICD-10 Code"
        },
        "name": {
          "type": "value",
          "value": "checkedIcd10Code"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "style": {
          "type": "code",
          "value": "{ marginTop: \"10px\", fontWeight: \"bold\" }"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "code",
          "value": "props.icd10CodeSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 33,
      "name": "Table",
      "parent": 22,
      "props": {
        "data": {
          "type": "code",
          "value": "props.InpatientDiagnosisSequence?.diagStatList || []"
        },
        "headers": {
          "type": "value",
          "value": "ลำดับ, รหัสโรค / ICD-10 Code, Diagnosis / ICD-10 Term, วันนอน (เฉลี่ย)"
        },
        "keys": {
          "type": "value",
          "value": "no, icd10_code, icd10_term, avg_admit_date"
        },
        "minRows": {
          "type": "code",
          "value": "10"
        },
        "widths": {
          "type": "value",
          "value": "70, auto, auto, 100"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "label",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"10px 10px 0px 0px\", fontWeight: \"bold\" }"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "Button",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "ล้างการค้นหา"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClear"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "code",
          "value": "props.startDateTextBox"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "code",
          "value": "props.endDateTextBox"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearch"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"5px\" }"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonPrint"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"5px\" }"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "Checkbox",
      "parent": 12,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.InpatientDiagnosisSequence?.diagFilter?.checkedIcd10Term"
        },
        "label": {
          "type": "value",
          "value": "ICD-10 Term"
        },
        "name": {
          "type": "value",
          "value": "checkedIcd10Term"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "style": {
          "type": "code",
          "value": "{ marginTop: \"10px\", fontWeight: \"bold\" }"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "code",
          "value": "props.icd10TermSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"5px\" }"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "label",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"10px 10px 0px 0px\", fontWeight: \"bold\" }"
        }
      },
      "seq": 34,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardInpatientDiagnosisUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
