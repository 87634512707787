import FormSymbolImages from "./AddSymbolPrintList";

export default function toPDFMakeData(props: any) {
  const injectImages = (haveHeader: boolean) => {
    let symbolUsage = {};
    if (!haveHeader) {
      symbolUsage = {
        checked: FormSymbolImages.checked,
        unchecked: FormSymbolImages.unchecked,
        squarebox: FormSymbolImages.squarebox,
        checkmark: FormSymbolImages.checkmark,
      };
    }
    return symbolUsage;
  };

  return {
    pageOrientation: `portrait`,
    defaultStyle: {
      font: `THSarabunNew`,
    },
    pageSize: `A4`,
    content: [
      {
        alignment: `center`,
        decorationStyle: ``,
        preserveLeadingSpaces: true,
        decorationColor: ``,
        margin: [0, 0, 0, 0],
        text: `Consent : Against Medical Advice`,
        pageBreak: ``,
        decoration: ``,
        bold: `true`,
        fontSize: `18`,
        color: ``,
        width: `auto`,
      },
      {
        decorationStyle: ``,
        decorationColor: ``,
        pageBreak: ``,
        margin: [0, 0, 0, 0],
        width: `auto`,
        bold: false,
        color: ``,
        preserveLeadingSpaces: true,
        alignment: `left`,
        decoration: ``,
        text: ` `,
        fontSize: 15,
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decoration: ``,
            bold: false,
            width: 30,
            color: ``,
            text: ``,
            pageBreak: ``,
            fontSize: 15,
            alignment: `left`,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
          },
          {
            color: ``,
            fontSize: 15,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decoration: ``,
            width: `auto`,
            decorationColor: ``,
            decorationStyle: ``,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            text: `I (Mr./Mrs./Ms.)`,
            bold: false,
          },
          {
            alignment: `left`,
            text: ``,
            pageBreak: ``,
            fontSize: 15,
            bold: false,
            margin: [0, 0, 0, 0],
            color: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decorationStyle: ``,
            width: 50,
            decoration: ``,
          },
          {
            bold: false,
            width: `auto`,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            fontSize: 15,
            decorationColor: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decoration: ``,
            color: ``,
            text: props.items?.formatPatient,
          },
        ],
      },
      {
        columns: [
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            fontSize: 15,
            bold: false,
            alignment: `left`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            width: 5,
            decorationColor: ``,
            decorationStyle: ``,
            decoration: ``,
            text: ``,
            margin: [0, 0, 0, 0],
            color: ``,
          },
          {
            bold: `true`,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            color: ``,
            width: `auto`,
            alignment: `left`,
            text: `Leaving hospital against medical advice`,
            pageBreak: ``,
            decorationStyle: ``,
            decoration: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            width: 30,
            color: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            text: ``,
            decoration: ``,
            decorationColor: ``,
            bold: false,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            fontSize: 15,
          },
          {
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            bold: false,
            decorationStyle: ``,
            width: `auto`,
            decoration: ``,
            color: ``,
            decorationColor: ``,
            text: `I certified that I am leaving that hospital against the advice of my attending physician. I have been`,
            fontSize: 15,
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            color: ``,
            alignment: `left`,
            width: 20,
            bold: false,
            preserveLeadingSpaces: true,
            text: ``,
            pageBreak: ``,
            decoration: ``,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            decorationColor: ``,
          },
          {
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decoration: ``,
            alignment: `left`,
            color: ``,
            text: `informed and understand the risks and possible consequences of my decision. I hereby release my `,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            width: `auto`,
            bold: false,
          },
        ],
      },
      {
        columns: [
          {
            text: ``,
            bold: false,
            width: 20,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            color: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            alignment: `left`,
            pageBreak: ``,
            decorationStyle: ``,
            decoration: ``,
          },
          {
            color: ``,
            decorationStyle: ``,
            text: `attending physician and hospital staff from any and all responsibilities form any adverse effects which`,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            decoration: ``,
            alignment: `left`,
            bold: false,
            decorationColor: ``,
            pageBreak: ``,
            width: `auto`,
            fontSize: 15,
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            alignment: `left`,
            text: ``,
            margin: [0, 0, 0, 0],
            width: 20,
            decorationColor: ``,
            bold: false,
            decoration: ``,
            color: ``,
            decorationStyle: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
          },
          {
            decoration: ``,
            width: `auto`,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decorationColor: ``,
            bold: false,
            alignment: `left`,
            decorationStyle: ``,
            pageBreak: ``,
            color: ``,
            margin: [0, 0, 0, 0],
            text: `may result from my decision.`,
          },
        ],
      },
      {
        columns: [
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            bold: false,
            pageBreak: ``,
            text: ``,
            fontSize: 15,
            color: ``,
            alignment: `left`,
            decorationStyle: ``,
            width: 5,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            margin: [0, 0, 0, 0],
          },
          {
            preserveLeadingSpaces: true,
            text: `Refusing treatment and investigation against medical advice`,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            decoration: ``,
            bold: `true`,
            fontSize: 15,
            color: ``,
            pageBreak: ``,
            decorationStyle: ``,
            width: `auto`,
            alignment: `left`,
          },
        ],
      },
      {
        columns: [
          {
            text: ``,
            margin: [0, 0, 0, 0],
            decoration: ``,
            alignment: `left`,
            width: 20,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            bold: false,
            decorationColor: ``,
            color: ``,
            pageBreak: ``,
          },
          {
            bold: false,
            alignment: `left`,
            width: `auto`,
            pageBreak: ``,
            color: ``,
            text: `(Procedure, equipment, device, etc. being refused is .......................................................................................................)`,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decorationColor: ``,
            decoration: ``,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            text: ``,
            decorationStyle: ``,
            color: ``,
            width: 30,
            preserveLeadingSpaces: true,
            decoration: ``,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            fontSize: 15,
            alignment: `left`,
            pageBreak: ``,
          },
          {
            margin: [0, 0, 0, 0],
            fontSize: 15,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationStyle: ``,
            bold: false,
            text: `I certified that I refuse the hospital treatment and investigation against the advice of my attending `,
            color: ``,
            decoration: ``,
            width: `auto`,
            decorationColor: ``,
            alignment: `left`,
          },
        ],
      },
      {
        columns: [
          {
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            text: ``,
            decoration: ``,
            fontSize: 15,
            color: ``,
            pageBreak: ``,
            alignment: `left`,
            width: 20,
            bold: false,
          },
          {
            decoration: ``,
            decorationStyle: ``,
            width: `auto`,
            fontSize: 15,
            bold: false,
            text: `physician. I have been informed and understand the risks and  possible consequences of my decision. I `,
            preserveLeadingSpaces: true,
            color: ``,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            pageBreak: ``,
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            text: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            width: 20,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            bold: false,
            color: ``,
            decorationColor: ``,
          },
          {
            text: `hereby release my attending physician and hospital staff from any and all responsibilities from any adverse`,
            width: `auto`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            bold: false,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            decoration: ``,
            color: ``,
            decorationColor: ``,
            decorationStyle: ``,
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            text: ``,
            color: ``,
            decoration: ``,
            decorationStyle: ``,
            fontSize: 15,
            width: 20,
            decorationColor: ``,
            bold: false,
            preserveLeadingSpaces: true,
          },
          {
            decoration: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            bold: false,
            color: ``,
            fontSize: 15,
            text: `effects which may result from my decision.`,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            width: `auto`,
            alignment: `left`,
            pageBreak: ``,
          },
        ],
      },
      {
        decorationStyle: ``,
        preserveLeadingSpaces: true,
        color: ``,
        fontSize: 15,
        text: ` `,
        pageBreak: ``,
        decorationColor: ``,
        width: `auto`,
        decoration: ``,
        margin: [0, 0, 0, 0],
        bold: false,
        alignment: `left`,
      },
      {
        columns: [
          {
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            color: ``,
            decoration: ``,
            fontSize: 15,
            width: `auto`,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            bold: false,
            pageBreak: ``,
            text: `Signature ......................................................`,
          },
          {
            width: 100,
            bold: false,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            pageBreak: ``,
            text: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            decorationStyle: ``,
            color: ``,
            fontSize: 15,
          },
          {
            alignment: `left`,
            pageBreak: ``,
            text: `Witness 1 ......................................................`,
            width: `auto`,
            decorationStyle: ``,
            bold: false,
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationColor: ``,
            color: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            stack: [
              {
                text: props.items?.full_name_en || props.items?.full_name,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `(.............................................................)`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
            width: `auto`,
          },
          {
            fontSize: 15,
            bold: false,
            decoration: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationColor: ``,
            decorationStyle: ``,
            width: 120,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            color: ``,
            text: ``,
          },
          {
            fontSize: 15,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            width: `auto`,
            decoration: ``,
            text: `(.......................................................................)`,
            color: ``,
            decorationStyle: ``,
            bold: false,
            pageBreak: ``,
            alignment: `left`,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 10,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            stack: [
              {
                text: props.items?.signedDate,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `........................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
            width: `auto`,
          },
          {
            preserveLeadingSpaces: true,
            fontSize: 15,
            bold: false,
            width: 45,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            decorationColor: ``,
            color: ``,
            decoration: ``,
            pageBreak: ``,
            text: ``,
          },
          {
            stack: [
              {
                text: props.items?.signedTime,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `..................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
            width: `auto`,
          },
          {
            width: 120,
            pageBreak: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            text: ``,
            alignment: `left`,
            bold: false,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            color: ``,
            decorationColor: ``,
          },
          {
            decorationStyle: ``,
            text: `Witness 2 ......................................................`,
            fontSize: 15,
            color: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationColor: ``,
            decoration: ``,
            margin: [0, 0, 0, 0],
            width: `auto`,
            bold: false,
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            alignment: `left`,
            decoration: ``,
            margin: [0, 0, 0, 0],
            text: ``,
            bold: false,
            decorationColor: ``,
            decorationStyle: ``,
            width: 25,
            color: ``,
          },
          {
            color: ``,
            decorationColor: ``,
            text: `Date`,
            fontSize: 15,
            alignment: `left`,
            bold: false,
            decorationStyle: ``,
            decoration: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            width: `auto`,
          },
          {
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            width: 80,
            color: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            text: ``,
            fontSize: 15,
            decoration: ``,
            pageBreak: ``,
            bold: false,
          },
          {
            text: `Time`,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            color: ``,
            decorationStyle: ``,
            alignment: `left`,
            pageBreak: ``,
            fontSize: 15,
            width: `auto`,
            decoration: ``,
            bold: false,
            margin: [0, 0, 0, 0],
          },
          {
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationColor: ``,
            alignment: `left`,
            color: ``,
            bold: false,
            text: ``,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            width: 130,
            decoration: ``,
          },
          {
            margin: [0, 0, 0, 0],
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            decoration: ``,
            decorationColor: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            text: `(.......................................................................)`,
            width: `auto`,
            alignment: `left`,
            color: ``,
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            text: ``,
            pageBreak: ``,
            bold: false,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            color: ``,
            width: 285,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decoration: ``,
          },
          {
            decoration: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            decorationColor: ``,
            width: `auto`,
            color: ``,
            alignment: `left`,
            pageBreak: ``,
            text: `(Fingerprint/Consent over telephone)`,
            fontSize: 15,
            bold: false,
            decorationStyle: ``,
          },
        ],
      },
      {
        text: ` `,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        fontSize: 15,
        decoration: ``,
        width: `auto`,
        margin: [0, 0, 0, 0],
        pageBreak: ``,
        color: ``,
        decorationStyle: ``,
        bold: false,
        alignment: `left`,
      },
      {
        text: `Interpreter’s Statement`,
        decoration: `underline`,
        decorationStyle: ``,
        decorationColor: ``,
        width: `auto`,
        color: ``,
        bold: true,
        fontSize: 15,
        pageBreak: ``,
        alignment: `left`,
        preserveLeadingSpaces: true,
        margin: [0, 0, 0, 0],
      },
      {
        pageBreak: ``,
        preserveLeadingSpaces: true,
        decoration: ``,
        width: `auto`,
        bold: false,
        decorationStyle: ``,
        decorationColor: ``,
        margin: [0, 0, 0, 0],
        alignment: `left`,
        text: `I have given a translation of information Consent : Against Medical Advice including information that physician has explained to patient/patient’s representative.`,
        fontSize: 15,
        color: ``,
      },
      {
        width: `auto`,
        margin: [0, 0, 0, 0],
        bold: false,
        decorationColor: ``,
        pageBreak: ``,
        text: ` `,
        decoration: ``,
        preserveLeadingSpaces: true,
        fontSize: 15,
        alignment: `left`,
        color: ``,
        decorationStyle: ``,
      },
      {
        columns: [
          {
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            bold: false,
            text: `Translate to Language ........................................`,
            pageBreak: ``,
            fontSize: 15,
            decorationStyle: ``,
            width: `auto`,
            color: ``,
            decorationColor: ``,
            decoration: ``,
          },
          {
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            decorationStyle: ``,
            width: 100,
            alignment: `left`,
            bold: false,
            decoration: ``,
            text: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            color: ``,
          },
          {
            decorationStyle: ``,
            text: `Interpreter................................................`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            width: `auto`,
            pageBreak: ``,
            color: ``,
            decorationColor: ``,
            fontSize: 15,
            alignment: `left`,
            bold: false,
            decoration: ``,
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            preserveLeadingSpaces: true,
            width: 308,
            margin: [0, 0, 0, 0],
            text: ``,
            bold: false,
            decorationStyle: ``,
            decoration: ``,
            pageBreak: ``,
            alignment: `left`,
            decorationColor: ``,
            fontSize: 15,
          },
          {
            alignment: `left`,
            color: ``,
            text: `(.............................................................)`,
            decorationColor: ``,
            fontSize: 15,
            width: `auto`,
            margin: [0, 0, 0, 0],
            decoration: ``,
            pageBreak: ``,
            bold: false,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        fontSize: 15,
        alignment: `left`,
        preserveLeadingSpaces: true,
        decorationColor: ``,
        pageBreak: ``,
        width: `auto`,
        text: ` `,
        bold: false,
        decorationStyle: ``,
        decoration: ``,
        color: ``,
        margin: [0, 0, 0, 0],
      },
      {
        text: `Status of Signer (According to Thai Civil and Commercial Code)`,
        decoration: `underline`,
        decorationStyle: ``,
        decorationColor: ``,
        width: `auto`,
        color: ``,
        bold: true,
        fontSize: 15,
        pageBreak: props.items?.haveHeader ? `before` : ``,
        alignment: `left`,
        preserveLeadingSpaces: true,
        margin: [0, 0, 0, 0],
      },
      {
        columns: [
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            preserveLeadingSpaces: true,
            fontSize: 15,
            text: ``,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            decorationColor: ``,
            width: 5,
            alignment: `left`,
            color: ``,
            decoration: ``,
            pageBreak: ``,
          },
          {
            bold: false,
            alignment: `left`,
            decorationColor: ``,
            text: `Patient, who is 20 years old or above, and capable of giving consent`,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            color: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            width: `auto`,
          },
        ],
      },
      {
        columns: [
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            width: 5,
            decoration: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            text: ``,
            bold: false,
            alignment: `left`,
            color: ``,
            decorationColor: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
          },
          {
            decoration: ``,
            bold: false,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            width: `auto`,
            text: `Legal hasband or wife in case that the patient is not capable of giving consent (unconscious)`,
            fontSize: 15,
            color: ``,
            alignment: `left`,
            decorationStyle: ``,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            fontSize: 15,
            bold: false,
            decorationStyle: ``,
            width: 5,
            decorationColor: ``,
            pageBreak: ``,
            alignment: `left`,
            text: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            color: ``,
            decoration: ``,
          },
          {
            decorationStyle: ``,
            alignment: `left`,
            color: ``,
            text: `Holder of parental responsibility in case that the patient is minor (under 20 years old)`,
            bold: false,
            fontSize: 15,
            width: `auto`,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            decoration: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        columns: [
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            text: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            bold: false,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            pageBreak: ``,
            width: 5,
            decorationColor: ``,
            decoration: ``,
            color: ``,
          },
          {
            color: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            decoration: ``,
            pageBreak: ``,
            bold: false,
            text: `Curator in case that the patient is quasi incompetent person (adjudged by the court)`,
            fontSize: 15,
            width: `auto`,
          },
        ],
      },
      {
        columns: [
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            decoration: ``,
            margin: [0, 0, 0, 0],
            color: ``,
            alignment: `left`,
            decorationColor: ``,
            bold: false,
            pageBreak: ``,
            decorationStyle: ``,
            width: 5,
            fontSize: 15,
            preserveLeadingSpaces: true,
            text: ``,
          },
          {
            margin: [0, 0, 0, 0],
            decoration: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
            color: ``,
            alignment: `left`,
            decorationStyle: ``,
            pageBreak: ``,
            fontSize: 15,
            bold: false,
            text: `Guardian in case that the patient is incompetent person (adjudged by the court)`,
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            alignment: `left`,
            decoration: ``,
            text: ``,
            preserveLeadingSpaces: true,
            bold: false,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            fontSize: 15,
            width: 25,
            decorationColor: ``,
          },
          {
            decorationStyle: ``,
            width: `auto`,
            bold: `true`,
            alignment: `left`,
            decoration: ``,
            color: ``,
            pageBreak: ``,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            preserveLeadingSpaces: true,
            text: `Plase provide the evidence such as a certified true copy of the identification card / passport / document issued by govemmental authority, which religion and blood type information are concealed (if any)`,
          },
        ],
      },
      {
        text: `Relationship with patient ....................................................................................................................................................`,
        preserveLeadingSpaces: true,
        width: `auto`,
        margin: [0, 0, 0, 0],
        alignment: `left`,
        bold: false,
        decoration: ``,
        fontSize: 15,
        color: ``,
        decorationStyle: ``,
        pageBreak: ``,
        decorationColor: ``,
      },
      {
        columns: [
          {
            width: `auto`,
            text: `Identification number of the patient’s representative`,
            alignment: `left`,
            pageBreak: ``,
            decorationStyle: ``,
            color: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            bold: false,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            fontSize: 15,
          },
          {
            text: ` .................................................................................................`,
            bold: false,
            color: ``,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            decorationStyle: ``,
            pageBreak: ``,
            decorationColor: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decoration: ``,
            width: `auto`,
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            text: `Telephone number `,
            bold: false,
            decorationStyle: ``,
            pageBreak: ``,
            decoration: ``,
            width: `auto`,
            margin: [0, 0, 0, 0],
            color: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            fontSize: 15,
          },
          {
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            width: `auto`,
            pageBreak: ``,
            text: ` .................................................................................................................................................................`,
            bold: false,
            fontSize: 15,
            decoration: ``,
            alignment: `left`,
            decorationColor: ``,
            decorationStyle: ``,
            color: ``,
          },
        ],
      },
      {
        columns: [
          {
            alignment: `left`,
            pageBreak: ``,
            text: `Email `,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            color: ``,
            bold: false,
            width: `auto`,
            fontSize: 15,
          },
          {
            decoration: ``,
            bold: false,
            fontSize: 15,
            text: ` ...........................................................................................................................................................................................`,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            width: `auto`,
            color: ``,
            alignment: `left`,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
          },
        ],
      },
    ],
    images: injectImages(props.items?.haveHeader),
  };
}
