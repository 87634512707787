import WasmController, {
  WasmHandler,
} from "react-lib/frameworks/WasmController";

import {
  tidyLocation2,
  deTinyLocation2,
  ClinicalAbsenceTermSet,
  getOnlyFullTeeth,
  MissingToothClinical,
  isTooth,
  getAllChildren,
} from "./DentalRecordUtil";

export type State = {
  // CommonInterface
  clinicalFindings?: any[];
  clinicalFindingList?: any[];
  clinicalFindingIndex?: number | null;
  // editRow?: number,
  clinicalFindingId?: string;
};

export const StateInitial = {};

export type Event =
  | { message: "AddLocation"; params: { items: any[] } }
  | { message: "ToggleLocation"; params: { items: any[] } };

type Handler<P = any, R = any> = (
  controller: WasmController<State, Event, any>,
  params: P
) => R;

// use for function drag mouse to select all fragment tooth
export const AddLocation: Handler = (controller, params) => {
  const state = controller.getState();
  const organItems = controller.data.organ.items;

  // check teeth in clinical absence group
  let absenceTooth = state.clinicalFindingList
    ?.filter(
      (item) =>
        item.clinicaltags &&
        item.clinicaltags
          .split(",")
          .filter((o: any) => ClinicalAbsenceTermSet.includes(o.trim()))
          .length > 0
    )
    .map((item) => item.locations_name.split(",").map((o: any) => o.trim()))
    .join(",")
    .split(",");

  let tooth = params.items;
  if ((absenceTooth?.length || 0) > 0) {
    absenceTooth = getOnlyFullTeeth(absenceTooth || [], organItems);
    if (absenceTooth.length > 0) {
      absenceTooth = deTinyLocation2(
        absenceTooth.map((item: any) => item.nickName),
        organItems
      );
      console.log("Absence tooth:", absenceTooth);
      tooth = tooth.filter((t: any) => !absenceTooth?.includes(t.location));
    }
  }

  let currentLocationList = [];
  if (
    state.clinicalFindingList?.[state.clinicalFindingIndex as any]
      ?.locations_name
  ) {
    currentLocationList = state.clinicalFindingList[
      state.clinicalFindingIndex as any
    ].locations_name
      ?.split(",")
      .map((item: any) => item.trim());
  }

  // prepare location array
  let selectedLocationList = tooth.map((item: any) => item.location.trim());

  console.log(
    "AddLocation selectedLocationList",
    selectedLocationList,
    state.clinicalFindingIndex
  );

  // Add
  let locationList: any[] = currentLocationList.concat(selectedLocationList);
  // console.log(currentLocationList, new Set(), "new set")

  // Toggle location

  const teethSetData: any[] = params?.teethSet || [];
  const duplicateLocation = new Set([
    ...currentLocationList,
    ...teethSetData,
    ...selectedLocationList,
  ]);
  const filterUniq = Array.from(duplicateLocation).filter(
    (list: any) => !locationList.includes(list)
  );
  const filterRemove = currentLocationList.filter((list: any) =>
    teethSetData.find((value) => list[0] === value[0])
  );

  if (
    Array.from(duplicateLocation).length === locationList.length &&
    teethSetData[0]
  ) {
    locationList = locationList.filter(
      (list) => ![...teethSetData, ...selectedLocationList].includes(list)
    );
  } else if (filterRemove[0] && filterUniq[0] && currentLocationList[0]) {
    if (
      Array.from(duplicateLocation).length ===
      [...locationList, ...filterUniq].length
    ) {
      locationList = [...locationList].filter(
        (list) => ![...filterRemove, ...selectedLocationList].includes(list)
      );
    }
  }

  // Tiny
  let newLocationList = tidyLocation2(
    locationList.filter((item) => item !== ""),
    organItems
  );

  // setProp
  let currentClinicalList = [...(state.clinicalFindingList || [])];
  currentClinicalList[state.clinicalFindingIndex as any].locations_name =
    newLocationList.join(", ");
  controller.setState({
    clinicalFindingList: currentClinicalList,
  });
  // controller.setProp(`clinicalFindingList.${state.clinicalFindingIndex}.locations_name`, newLocationList.join(", "))
};

// use for function drag mouse to select one of fragment tooth
export const ToggleLocation: Handler = (controller, params) => {
  const state = controller.getState();
  const organItems = controller.data.organ.items;

  console.log("ToggleLocation params", params);
  // Fragment All
  let currentLocationList = state.clinicalFindingList?.[
    state.clinicalFindingIndex as any
  ].locations_name
    ?.split(",")
    .map((item: any) => item.trim());
  // console.log("ToggleLocations:", currentLocationList);
  let fragmentAll = deTinyLocation2(currentLocationList, organItems);
  // console.log(" ToggleLocation : fragmentAll ", fragmentAll);
  let locationList: any[] = [];
  let selectedLocationList: any[] = params.items.map((item: any) =>
    item.location.trim()
  );

  // Maybe remove if have one

  let isAlreadyHave = true;

  params.items.forEach((tooth: any) => {
    console.log("tooth", tooth);
    if (!fragmentAll.includes(tooth.location)) {
      isAlreadyHave = false;
    }
  });

  if (isAlreadyHave) {
    // remove
    console.log("framgmentAll", fragmentAll);

    params.items.forEach((tooth: any) => {
      let idx = fragmentAll.indexOf(tooth.location);
      fragmentAll.splice(idx, 1);
      console.log("Toggle Remove fragmentAll", fragmentAll);
      locationList = fragmentAll;
    });
  } else {
    // Add
    console.log(" selectedLocationList", selectedLocationList);
    locationList = currentLocationList.concat(selectedLocationList);
    console.log("Toggle Add locationList", locationList);
  }

  // check teeth in clinical absence group
  if (
    !state.clinicalFindingList?.[state.clinicalFindingIndex as any]
      .clinicaltags ||
    state.clinicalFindingList[state.clinicalFindingIndex as any].clinicaltags
      ?.split(",")
      .filter((o: any) => MissingToothClinical.includes(o.trim())).length === 0
  ) {
    let absenceTooth = state.clinicalFindingList
      ?.filter(
        (item) =>
          item.clinicaltags &&
          item.clinicaltags
            .split(",")
            .filter((o: any) => MissingToothClinical.includes(o.trim()))
            .length > 0
      )
      .map((item) => item.locations_name.split(",").map((o: any) => o.trim()))
      .join(",")
      .split(",");

    if ((absenceTooth?.length || 0) > 0) {
      absenceTooth = getOnlyFullTeeth(absenceTooth || [], organItems);
      if (absenceTooth.length > 0) {
        absenceTooth = deTinyLocation2(
          absenceTooth.map((item: any) => item.nickName),
          organItems
        );
        console.log("Absence tooth:", absenceTooth);
        locationList = locationList.filter((t) => !absenceTooth?.includes(t));
      }
    }
  }
  // if (params.items.length === 1 && fragmentAll.includes( params.items[0].location) || () ) {
  //   // Remove
  //   let idx = fragmentAll.indexOf(params.items[0].location )
  //   fragmentAll.splice(idx, 1)
  //   locationList = fragmentAll

  // } else {
  //   // Add
  //   let selectedLocationList = params.items.map(item => item.location.trim())
  //   console.log(" selectedLocationList", selectedLocationList)
  //   locationList  = currentLocationList.concat(selectedLocationList)
  //   console.log("Toggle Add locationList", locationList)

  // }

  // Tiny
  let newLocationList = tidyLocation2(
    locationList.filter((item) => item !== ""),
    organItems
  );
  console.log(
    "ToggleLocation : newLocationList",
    newLocationList,
    newLocationList.join(", ")
  );
  controller.setProp(
    `clinicalFindingList.${state.clinicalFindingIndex}.locations_name`,
    newLocationList.join(", ")
  );
};

export const formattedLocationsName: Handler<
  {
    index: number;
    id?: number;
  },
  {
    clinicalFindingsData: any[];
    data: Record<string, any>;
    locationName: string;
  }
> = (controller, params) => {
  const state = controller.getState();

  const clinicalFindings: any[] = state.clinicalFindingList || [];
  const index = params.index;
  const { id, ...data } = clinicalFindings[index];
  let clinicalFindingsData = [...(state.clinicalFindingList || [])];

  const organItems = controller.data.organ.items;
  const clinicalItems = controller.data.clinicaltags?.items || [];
  const icd10Items = controller.data.icd10?.items || [];

  let locationList = clinicalFindings[index].locations_name
    ?.split(",")
    .map((item: any) => item.trim())
    .filter((item: any) => item !== "");

  if (
    !clinicalFindings[index].clinicaltags ||
    clinicalFindings[index].clinicaltags
      .split(",")
      .filter((o: any) => MissingToothClinical.includes(o.trim())).length === 0
  ) {
    let absenceTooth = clinicalFindings
      .filter(
        (item) =>
          item.clinicaltags &&
          item.clinicaltags
            .split(",")
            .filter((o: any) => MissingToothClinical.includes(o.trim()))
            .length > 0
      )
      .map((item) => item.locations_name.split(",").map((o: any) => o.trim()))
      .join(",")
      .split(",");

    if (absenceTooth.length > 0) {
      absenceTooth = getOnlyFullTeeth(absenceTooth, organItems);
      if (absenceTooth.length > 0) {
        absenceTooth = deTinyLocation2(
          absenceTooth.map((item: any) => item.nickName),
          organItems
        );
        console.log("Absence tooth:", absenceTooth);
        locationList = deTinyLocation2(locationList, organItems);
        locationList = locationList.filter(
          (t: string) => !absenceTooth.includes(t)
        );
      }
    }
  }

  let tidyLocationList: any[] = [];

  if (locationList && locationList.length > 0) {
    tidyLocationList = tidyLocation2(locationList, organItems);
  }

  const tidyLocationName = tidyLocationList.join(", ");

  clinicalFindingsData = clinicalFindingsData.map((examination) => {
    return examination.id === params.id
      ? { ...examination, locations_name: tidyLocationName }
      : examination;
  });

  controller.setProp(
    `clinicalFindings.${index}.locations_name`,
    tidyLocationName
  );

  data.locations_name = tidyLocationName;

  let subTooth = "";

  data.locations_name?.split(",").forEach((item: any) => {
    if (isTooth(item.trim())) {
      let childTooths = getAllChildren(item.trim(), organItems)
        .map((item: any) => {
          let superTooth = organItems.find((o: any) => o.nickName === item);
          if (superTooth) {
            return superTooth.fullName;
          }
          return item;
        })
        .join(", ");

      if (subTooth.length === 0) {
        subTooth = childTooths;
      } else {
        subTooth = subTooth + ", " + childTooths;
      }
    } else {
      if (subTooth.length === 0) {
        subTooth = item.trim();
      } else {
        subTooth = subTooth + ", " + item.trim();
      }
    }
  });
  // console.log("SaveClinicalFindingById allSubTooth:", subTooth);
  // console.log("SaveClinicalFindingById Data for set:", data, params);
  data.locations_name = subTooth;

  //Process to get clinical id
  if (data.clinicaltags && data.clinicaltags.length > 0) {
    let clinicalSet: string[] = data.clinicaltags
      .split(",")
      .map((item: any) => item.trim());

    // let clinicalIds = clinicalItems.filter((item) => clinicalSet.includes(item.name)).map((item) => item.id);
    let clinicalIds = clinicalSet.flatMap((value) => {
      const data = clinicalItems.find((item: any) => item.name === value);
      return data ? [data.id] : [];
    });

    data.clinical_findings = clinicalIds;
  }

  if (data.icd10_label && data.icd10_label.length > 0) {
    let icd10Id = icd10Items.filter((item: any) =>
      item.icd10_label.includes(data.icd10_label.trim())
    )[0].id;
    data.icd10 = icd10Id;
  }

  return {
    clinicalFindingsData,
    data,
    locationName: tidyLocationName,
  };
};
