import WasmController from 'react-lib/frameworks/WasmController';

export type State = 
  {
  }

export const StateInitial: State = 
  {
  }

export type Event = 
  { message: "getCenterLabDivision", params: any }
  |  { message: "getCentalLabTestResultFitlerList", params: any }
  |  { message: "getCentalLabTestResultComparable", params: any }
  |  { message: "getInterfaceSummaryReportDetail", params: any }
  |  { message: "getCentralLabResultList", params: any }

export type Data = 
  {
    division?: number,
  }

export const DataInitial = 
  {
  }

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any 

export const getCenterLabDivision: Handler = async (controller, {}) => {
//function () { return __awaiter(_this, void 0, void 0, function () {
//            var labDivisionList, labDivisionError, labService, _a, error, response;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        labDivisionList = {};
//                        labDivisionError = [];
//                        labService = new LABService_1.default();
//                        return [4 /*yield*/, utils_1.to(labService.getCenterLabDivision())];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1];
//                        if (response) {
//                            labDivisionList = response;
//                        }
//                        else {
//                            labDivisionError = error;
//                        }
//                        return [2 /*return*/, [labDivisionList, labDivisionError]];
//                }
//            });
//        }); }
//function () {
//        return this.client
//            .get(apis_1.LAB.CENTRAL_LAB_DIVISION)
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/LAB/central-lab-division/
}

export const getCentalLabTestResultFitlerList: Handler = async (controller, {}) => {
//function (params) { return __awaiter(_this, void 0, void 0, function () {
//            var labFilterList, labFilterError, labService, _a, error, response;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        labFilterList = [];
//                        labFilterError = [];
//                        labService = new LABService_1.default();
//                        return [4 /*yield*/, utils_1.to(labService.getCentalLabTestResultFitlerList(params))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1];
//                        if (response) {
//                            labFilterList = response;
//                        }
//                        else {
//                            labFilterError = error;
//                        }
//                        return [2 /*return*/, [labFilterList, labFilterError]];
//                }
//            });
//        }); }
//function (params) {
//        return this.client
//            .get(apis_1.LAB.CENTRAL_LABTEST_RESULT_FILTER, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/LAB/central-lab-test-filter-result/
}

export const getCentalLabTestResultComparable: Handler = async (controller, {}) => {
//function (params) { return __awaiter(_this, void 0, void 0, function () {
//            var labDataList, labDataError, labService, _a, error, response;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        labDataList = [];
//                        labDataError = [];
//                        labService = new LABService_1.default();
//                        return [4 /*yield*/, utils_1.to(labService.postCenterLabResultComparable(params))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1];
//                        if (response) {
//                            labDataList = response;
//                        }
//                        else {
//                            labDataError = error;
//                        }
//                        return [2 /*return*/, [labDataList, labDataError]];
//                }
//            });
//        }); }
//
//----> Name mismatch between manager and service
}

export const getInterfaceSummaryReportDetail: Handler = async (controller, {}) => {
//function (params) { return __awaiter(_this, void 0, void 0, function () {
//            var reportData, reportError, labService, _a, error, response;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        reportData = {};
//                        reportError = {};
//                        labService = new LABService_1.default();
//                        return [4 /*yield*/, utils_1.to(labService.getInterfaceSummaryReportDetail(params))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1];
//                        if (response) {
//                            reportData = response;
//                        }
//                        else {
//                            reportError = error;
//                        }
//                        return [2 /*return*/, [reportData, reportError]];
//                }
//            });
//        }); }
//function (_a) {
//        var cloi = _a.cloi;
//        var url = apis_1.LAB.INTERFACE_SUMMARY_REPORT_DETAIL({ cloi: cloi });
//        return this.client
//            .get(url)
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/LAB/interface-summary-report-detail/' +
//((__t = (cloi)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const getCentralLabResultList: Handler = async (controller, {}) => {
//function (params) { return __awaiter(_this, void 0, void 0, function () {
//            var labData, labError, labService, _a, error, response;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        labData = {};
//                        labError = {};
//                        labService = new LABService_1.default();
//                        return [4 /*yield*/, utils_1.to(labService.getCentralLabResultList(params))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1];
//                        if (response) {
//                            labData = response;
//                        }
//                        else {
//                            labError = error;
//                        }
//                        return [2 /*return*/, [labData, labError]];
//                }
//            });
//        }); }
//function (params) {
//        return this.client
//            .get(apis_1.LAB.CENTRAL_LAB_RESULT_LIST, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/LAB/central-lab-result-list/
}
