import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";

// PDF Path
const PDF = "/static/pdf/printList/price_evaluate.pdf";
const Font = "/static/fonts/THSarabunNew-Bold.ttf"; //* กรณีแก้ Font

const FormPriceEvaluate = async (props: any) => {
  try {
    const existingPdfBytes = await fetch(PDF).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Loading Custom Font
    const fontUrl = Font;
    const fontBytes = await fetch(fontUrl).then((res) => res.arrayBuffer());
    pdfDoc.registerFontkit(fontkit);
    const customFont = await pdfDoc.embedFont(fontBytes);
    const zapFont = await pdfDoc.embedFont(StandardFonts.ZapfDingbats);

    const pages = pdfDoc.getPages();
    const firstPage = pages[0];
    const fontSize = 15; //*
    const textCoordinates = [
      { text: props.items?.hn, x: 115, y: 712 },
      { text: props.items?.formatPatient, x: 255, y: 712 },
      { text: props.items?.formatDoctor, x: 175, y: 108 },
      { text: props.items?.doctorNumber, x: 402, y: 108 },
    ];

    for (const { text, x, y } of textCoordinates) {
      firstPage.drawText(text || "", {
        x,
        y,
        size: fontSize,
        font: customFont,
        color: rgb(0, 0, 0),
      });
    }

    const modifiedPdfBytes = await pdfDoc.save();
    const getAsBase64 = await pdfDoc.saveAsBase64();
    const blob = new Blob([modifiedPdfBytes], { type: "application/pdf" });
    const modifiedPdfUrl = URL.createObjectURL(blob);

    return { uint8Array: modifiedPdfBytes, blobUrl: modifiedPdfUrl, blobRaw: blob, base64String: getAsBase64 }
  } catch (error) {
    console.error("Error modifying PDF: ", error);

    return {};
  }
}

export default FormPriceEvaluate;