import React, { useState, useRef, useEffect, memo, useContext } from "react";
import { Dimmer, Loader, Icon } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import { injectIntl } from "react-intl";
import { AppContext } from "../patient-lib/PatientContext";

const SelectAddress = React.forwardRef((props, ref) => {
  const [isLoading, setIsLoading] = useState(false);
  const [shippingAddress, setShippingAddress] = useState()
  const [addressList, setAddressList] = useState([]);

  const isMounted = useRef(true);
  const history = useHistory();

  const context = useContext(AppContext)

  React.useImperativeHandle(ref, () => ({
    goToDrugAddressConfirm: () => {
      return history.push({
        pathname: `/drug-address-confirm/${props.match.params.encounterId}`,
        patientAddress: shippingAddress,
        channelId: props.location.channelId
      })
    }
  }));

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!props.apiToken && !Cookies.get("apiToken")) {
      props.noApiToken();
    }
  }, [props.apiToken, Cookies.get("apiToken")]);

  useEffect(() => {
    if ((props.apiToken || Cookies.get("apiToken")) && props.patientData.patient) {
      getPatientAddressList();
    }
  }, [
    props.apiToken,
    Cookies.get("apiToken"),
    props.patientData && props.patientData.patient
  ]);

  useEffect(() => {
    if(props.location.shippingAddress){
      setShippingAddress(props.location.shippingAddress)
    }
  }, [props.location.shippingAddress])

  const getPatientAddressList = async () => {
    setIsLoading(true);
    const [res, err, network] = await props.controller.getPatientAddressList({
      apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
      patientId: props.patientData.patient
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (res) {
        setAddressList(res.items);
      }
    }
  };

  const handleSelectAddress = (patientAddress) => {
    // postShippingAddress(patientAddress)
    setShippingAddress(patientAddress)
  }

  const createAddressList = () => {
    return addressList.map(item => {
      if (!item.address) {
        return null;
      }
      return (
        <div key={item.id} className="address-list">
          <div className="address-detail" onClick={() => handleSelectAddress(item)}>
            {displayAddress(item.address)}
          </div>
          <span
            className="edit-address-detail"
            onClick={() => handleEditAddress({ id: item.id })}
          >
            แก้ไข
          </span>
        </div>
      );
    });
  };

  const handleEditAddress = ({ id }) => {
    history.push(`/edit-address/${id}/`);
  };

  const handleAddAddress = () => {
    if(addressList.length === 0){
      history.push({
        pathname: "/edit-address/",
        init: true
      });
    } else {
      history.push("/edit-address/");
    }
  };

  const displayAddress = (address) => {
    if(!address){
      return
    }
    let districtField = "district_label"
    let cityField = "city_label"
    let provinceField = "province_label"
    if(context && context.language === "en-US"){
      districtField = "district_en_label"
      cityField = "city_en_label"
      provinceField = "province_en_label"
    }
    let no = address.no ? `${address.no} ` : ""
    let district = address[districtField] ? `${address[districtField]} ` : ""
    let city = address[cityField] ? `${address[cityField]} ` : ""
    let province = address[provinceField] ? `${address[provinceField]} ` : ""
    let zipcode = address.zipcode ? `${address.zipcode} ` : ""
    let note = address.note ? `${address.note} ` : ""
    return (
      <>
        <p>{note + no + district + city + province + zipcode}</p>
      </>
    )
  }

  return (
    <div className="select-address">
      {props.menuBar}
      <Dimmer.Dimmable>
        <Dimmer active={isLoading} inverted>
          <Loader />
        </Dimmer>
        <div className="selected-address">
          <div>
            <div>
              <h4>{props.intl.formatMessage({ id: "drug_delivery.shipping_address" })}</h4>
              {shippingAddress && shippingAddress.address && displayAddress(shippingAddress.address)}
            </div>
          </div>
        </div>
        <div className="section-header">
          <h4>{props.intl.formatMessage({ id: "drug_delivery.change_shipping_address" })}</h4>
        </div>
        {createAddressList()}
        <div className="add-new-address" onClick={handleAddAddress}>
          <div>
            <div className="add-address">{props.intl.formatMessage({ id: "drug_delivery.add_new_address" })}</div>
            <Icon name="plus" />
          </div>
        </div>
      </Dimmer.Dimmable>
    </div>
  );
});

export default memo(injectIntl(SelectAddress, { forwardRef: true }));
