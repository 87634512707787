import React, { useMemo } from "react";
import { Modal, Button, Segment, Dropdown, Loader, Form, TextArea } from "semantic-ui-react";
import EmployeeToken from "react-lib/apps/common/EmployeeToken";
import { useIntl } from "react-intl";

const ModDuplicateAppointment = (props: any) => {
  const intl = useIntl();
  const styles = {
    header: {
      lineHeight: "1.3em",
      borderBottomLeftRadius: "0px",
      borderBottomRightRadius: "0px",
      backgroundColor: "red",
    },
    button: {
      // marginTop: "10px",
      marginRight: "20px",
      minWidth: "100px",
    },
    line: {
      display: "flex",
      justifyContent: "flex-start",
      margin: "5px 0px",
    },
    fontBig: {
      fontSize: "1.5em",
    },
    appointmented: {
      margin: "10px 0px 30px 0px",
    },
    boldlefthalf: {
      width: "50%",
      fontWeight: "bolder",
      textAlign: "left" as const, // https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-4.html#const-assertions
    },
    column: {
      display: "flex",
      width: "50%",
      flexDirection: "column" as const,
      alignItems: "flex-start",
    },
    left: {
      textAlign: "left" as const,
    },
    boldleft: {
      fontWeight: "bolder",
      textAlign: "left" as const,
    },
  };

  const disabledButtonLeft = useMemo(
    () => props.buttonRightLoading || !props.userTokenize?.employeeName || !props.reason,
    [props.buttonRightLoading, props.reason, props.userTokenize]
  );

  const repPatientApp = useMemo(
    () =>
      props.duplicateAppointment?.rep_patient_appointments?.[0] as Record<string, any> | undefined,
    [props.duplicateAppointment]
  );

  const patientName = useMemo(
    () =>
      repPatientApp?.patient_pre_name
        ? `${repPatientApp.patient_pre_name} ${repPatientApp.patient_first_name} ${repPatientApp.patient_last_name}`
        : `${repPatientApp?.patient_first_name} ${repPatientApp?.patient_last_name}`,
    [repPatientApp]
  );

  const duplicateDate = useMemo(() => {
    const estimatedAt: string = props.duplicateDate || repPatientApp?.estimated_at;

    return estimatedAt;
  }, [props.duplicateDate, repPatientApp]);

  const handleButtonLeftClick = () => {
    props.onButtonLeftClick();
  };

  const handleButtonRightClick = () => {
    props.onButtonRightClick();
  };

  return (
    <>
      <Modal open={props.open} size={props.size} onClose={props.onClose}>
        <Segment inverted className={"modHeader " + props.titleColor} style={styles.header}>
          {props.title}
        </Segment>
        <Segment align="center" className="modContent" style={styles.fontBig}>
          <div style={{ margin: "0px 10px" }}>
            <div style={styles.appointmented}>
              <div style={styles.line}>
                <div style={styles.boldlefthalf}>
                  {"HN: "} {repPatientApp?.patient_hn}
                </div>
                <div style={styles.boldlefthalf}>
                  {"ชื่อ: "} {patientName}
                </div>
              </div>

              <div style={styles.line}>
                <div style={styles.boldlefthalf}>
                  {"มีนัดหมายแล้วในวันที่ "} {repPatientApp?.estimated_at}
                </div>
              </div>

              <div style={styles.line}>
                <div style={styles.boldlefthalf}>
                  {"คลินิก: "} {repPatientApp?.division_name}
                </div>
                <div style={styles.boldlefthalf}>
                  {"แพทย์: "} {repPatientApp?.display_info?.provider_name}
                </div>
              </div>
            </div>

            <div style={styles.line}>
              <div style={styles.column}>
                <div style={styles.boldleft}>{"ระบุเหตุผลการนัดหมายซ้ำซ้อน "}</div>
                <div style={styles.left}>
                  {"คลินิก: "}
                  {props.duplicateClinicName}
                </div>
              </div>

              <div style={styles.column}>
                <div style={styles.left}>
                  {"วันที่ "} {duplicateDate}
                </div>
                <div style={styles.left}>
                  {"แพทย์: "} {props.duplicateDoctorName}
                </div>
              </div>
            </div>
            <div style={{ width: "100%", margin: "20px 0px" }}>
              <Form>
                <div style={{ textAlign: "left", fontWeight: "bolder", fontSize: "1.5em" }}>
                  {" "}
                  {"Repetition Note"}
                  <span style={{ color: "red" }}>*</span>{" "}
                </div>
                {props.reoccureDuplicateAppointment && (
                  <div
                    style={{
                      textAlign: "left",
                      fontWeight: "bolder",
                      fontSize: "1.5em",
                      color: "red",
                      margin: "10px 0px",
                    }}
                  >
                    {" "}
                    กรุณาใส่เหตุผล{" "}
                  </div>
                )}
                <TextArea
                  style={{ width: "100%" }}
                  rows={6}
                  value={props.reason}
                  onChange={props.onChangeReason}
                />
              </Form>
            </div>
            <div style={{ display: "flex", margin: "30px 0px", justifyContent: "space-between", alignItems: "center" }}>
              <div style={{ display: "flex" }}>
                <span style={{ marginRight: "10px", fontWeight: "bold", minWidth: "max-content" }}>
                  {"ผู้ยืนยันนัดหมาย"}
                  <span style={{ color: "red" }}>*</span>{" "}
                </span>

                <EmployeeToken
                  placeholder={intl.formatMessage({ id: "รหัสผู้บันทึก" })}
                  onEnterToken={(code) => {
                    props.onEvent({
                      message: "UserTokenize",
                      params: {
                        action: "CheckUserToken",
                        code: code,
                      },
                    });

                    // props.onEvent({
                    //   message: "HandleGetDeliverDrugTokenization",
                    //   params: { code },
                    // });
                  }}
                  onClearToken={() =>
                    props.setProp("userTokenize", {
                      ...props.userTokenize,
                      token: "",
                      employeeName: "",
                      loading: false,
                      error: null,
                    })
                  }
                  error={!!props.userTokenize?.error}
                  loading={props.userTokenize?.loading}
                  employeeName={props.userTokenize?.employeeName}
                />
              </div>
              <div>
                <Button
                  style={styles.button}
                  {...(props.isButtonBasic && { basic: true })}
                  loading={props.buttonLeftLoading}
                  disabled={disabledButtonLeft}
                  color={props.buttonLeftColor}
                  onClick={handleButtonLeftClick}
                >
                  {props.buttonLeftLabel}
                </Button>
                <Button
                  style={styles.button}
                  {...(props.isButtonBasic && { basic: true })}
                  loading={props.buttonRightLoading}
                  disabled={props.buttonLeftLoading}
                  color={props.buttonRightColor}
                  onClick={handleButtonRightClick}
                >
                  {props.buttonRightLabel}
                </Button>
              </div>
            </div>
          </div>
        </Segment>
      </Modal>
    </>
  );
};

export default ModDuplicateAppointment;
