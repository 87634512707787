const toothDetails = [
  { q: 1, i: 1, surfaceName: ["Labial", "Distal", "Palatal", "Mesial", "Incisal", "Whole"] },
  { q: 1, i: 2, surfaceName: ["Labial", "Distal", "Palatal", "Mesial", "Incisal", "Whole"] },
  { q: 1, i: 3, surfaceName: ["Labial", "Distal", "Palatal", "Mesial", "Incisal", "Whole"] },
  { q: 1, i: 4, surfaceName: ["Buccal", "Distal", "Palatal", "Mesial", "Occlusal", "Whole"] },
  { q: 1, i: 5, surfaceName: ["Buccal", "Distal", "Palatal", "Mesial", "Occlusal", "Whole"] },
  { q: 1, i: 6, surfaceName: ["Buccal", "Distal", "Palatal", "Mesial", "Occlusal", "Whole"] },
  { q: 1, i: 7, surfaceName: ["Buccal", "Distal", "Palatal", "Mesial", "Occlusal", "Whole"] },
  { q: 1, i: 8, surfaceName: ["Buccal", "Distal", "Palatal", "Mesial", "Occlusal", "Whole"] },
  { q: 2, i: 1, surfaceName: ["Labial", "Mesial", "Palatal", "Distal", "Incisal", "Whole"] },
  { q: 2, i: 2, surfaceName: ["Labial", "Mesial", "Palatal", "Distal", "Incisal", "Whole"] },
  { q: 2, i: 3, surfaceName: ["Labial", "Mesial", "Palatal", "Distal", "Incisal", "Whole"] },
  { q: 2, i: 4, surfaceName: ["Buccal", "Mesial", "Palatal", "Distal", "Occlusal", "Whole"] },
  { q: 2, i: 5, surfaceName: ["Buccal", "Mesial", "Palatal", "Distal", "Occlusal", "Whole"] },
  { q: 2, i: 6, surfaceName: ["Buccal", "Mesial", "Palatal", "Distal", "Occlusal", "Whole"] },
  { q: 2, i: 7, surfaceName: ["Buccal", "Mesial", "Palatal", "Distal", "Occlusal", "Whole"] },
  { q: 2, i: 8, surfaceName: ["Buccal", "Mesial", "Palatal", "Distal", "Occlusal", "Whole"] },
  { q: 3, i: 1, surfaceName: ["Lingual", "Mesial", "Labial", "Distal", "Incisal", "Whole"] },
  { q: 3, i: 2, surfaceName: ["Lingual", "Mesial", "Labial", "Distal", "Incisal", "Whole"] },
  { q: 3, i: 3, surfaceName: ["Lingual", "Mesial", "Labial", "Distal", "Incisal", "Whole"] },
  { q: 3, i: 4, surfaceName: ["Lingual", "Mesial", "Buccal", "Distal", "Occlusal", "Whole"] },
  { q: 3, i: 5, surfaceName: ["Lingual", "Mesial", "Buccal", "Distal", "Occlusal", "Whole"] },
  { q: 3, i: 6, surfaceName: ["Lingual", "Mesial", "Buccal", "Distal", "Occlusal", "Whole"] },
  { q: 3, i: 7, surfaceName: ["Lingual", "Mesial", "Buccal", "Distal", "Occlusal", "Whole"] },
  { q: 3, i: 8, surfaceName: ["Lingual", "Mesial", "Buccal", "Distal", "Occlusal", "Whole"] },
  { q: 4, i: 1, surfaceName: ["Lingual", "Distal", "Labial", "Mesial", "Incisal", "Whole"] },
  { q: 4, i: 2, surfaceName: ["Lingual", "Distal", "Labial", "Mesial", "Incisal", "Whole"] },
  { q: 4, i: 3, surfaceName: ["Lingual", "Distal", "Labial", "Mesial", "Incisal", "Whole"] },
  { q: 4, i: 4, surfaceName: ["Lingual", "Distal", "Buccal", "Mesial", "Occlusal", "Whole"] },
  { q: 4, i: 5, surfaceName: ["Lingual", "Distal", "Buccal", "Mesial", "Occlusal", "Whole"] },
  { q: 4, i: 6, surfaceName: ["Lingual", "Distal", "Buccal", "Mesial", "Occlusal", "Whole"] },
  { q: 4, i: 7, surfaceName: ["Lingual", "Distal", "Buccal", "Mesial", "Occlusal", "Whole"] },
  { q: 4, i: 8, surfaceName: ["Lingual", "Distal", "Buccal", "Mesial", "Occlusal", "Whole"] },
  { q: 5, i: 1, surfaceName: ["Labial", "Distal", "Palatal", "Mesial", "Incisal", "Whole"] },
  { q: 5, i: 2, surfaceName: ["Labial", "Distal", "Palatal", "Mesial", "Incisal", "Whole"] },
  { q: 5, i: 3, surfaceName: ["Labial", "Distal", "Palatal", "Mesial", "Incisal", "Whole"] },
  { q: 5, i: 4, surfaceName: ["Buccal", "Distal", "Palatal", "Mesial", "Occlusal", "Whole"] },
  { q: 5, i: 5, surfaceName: ["Buccal", "Distal", "Palatal", "Mesial", "Occlusal", "Whole"] },
  { q: 6, i: 1, surfaceName: ["Labial", "Mesial", "Palatal", "Distal", "Incisal", "Whole"] },
  { q: 6, i: 2, surfaceName: ["Labial", "Mesial", "Palatal", "Distal", "Incisal", "Whole"] },
  { q: 6, i: 3, surfaceName: ["Labial", "Mesial", "Palatal", "Distal", "Incisal", "Whole"] },
  { q: 6, i: 4, surfaceName: ["Buccal", "Mesial", "Palatal", "Distal", "Occlusal", "Whole"] },
  { q: 6, i: 5, surfaceName: ["Buccal", "Mesial", "Palatal", "Distal", "Occlusal", "Whole"] },
  { q: 7, i: 1, surfaceName: ["Lingual", "Mesial", "Labial", "Distal", "Incisal", "Whole"] },
  { q: 7, i: 2, surfaceName: ["Lingual", "Mesial", "Labial", "Distal", "Incisal", "Whole"] },
  { q: 7, i: 3, surfaceName: ["Lingual", "Mesial", "Labial", "Distal", "Incisal", "Whole"] },
  { q: 7, i: 4, surfaceName: ["Lingual", "Mesial", "Buccal", "Distal", "Occlusal", "Whole"] },
  { q: 7, i: 5, surfaceName: ["Lingual", "Mesial", "Buccal", "Distal", "Occlusal", "Whole"] },
  { q: 8, i: 1, surfaceName: ["Lingual", "Distal", "Labial", "Mesial", "Incisal", "Whole"] },
  { q: 8, i: 2, surfaceName: ["Lingual", "Distal", "Labial", "Mesial", "Incisal", "Whole"] },
  { q: 8, i: 3, surfaceName: ["Lingual", "Distal", "Labial", "Mesial", "Incisal", "Whole"] },
  { q: 8, i: 4, surfaceName: ["Lingual", "Distal", "Buccal", "Mesial", "Occlusal", "Whole"] },
  { q: 8, i: 5, surfaceName: ["Lingual", "Distal", "Buccal", "Mesial", "Occlusal", "Whole"] },
]

export default toothDetails