import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Label,
  Form,
  FormGroup,
  FormField,
  Checkbox,
  Dropdown
} from 'semantic-ui-react'

const CardDefibrillatorUX = (props: any) => {
    return(
      <div
        id="CardDefibrillator"
        style={{ margin: "15px",  padding: "20px",  backgroundColor: "rgba(198, 235, 243, 0.2)" }}>
        <div
          style={{ paddingBottom: "5px" }}>
          
          <label
            style={{ fontWeight: "bold", fontSize: "18px" }}>
            Defibrillator (G)
          </label>
          <Label
            color={props.statusColor}
            style={{ float: "right" }}>
            {props.status}
          </Label>
          <hr>
          </hr>
        </div>
        <Form>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "130px", maxWidth: "130px"}}>
                Defibrillator
              </label>
              <Checkbox
                checked={props.isDefibrillator}
                label={props.isDefibrillator ? "YES" :"NO"}
                name="is_defibrillator"
                onChange={props.handleChangeData}
                toggle={true}>
              </Checkbox>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{alignItems: "baseline"}}>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "130px", maxWidth: "130px"}}>
                Defibrillator
              </label>
            </FormField>
            <FormField
              width={16}>
              <div
                style={{width: "100%"}}>
                {props.defibrillator}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              className="required"
              inline={true}>
              <label
                style={{minWidth: "130px", maxWidth: "130px"}}>
                Defibrillator Pad Site 
              </label>
            </FormField>
            <FormField
              inline={true}
              width={7}>
              <Dropdown
                clearable={true}
                fluid={true}
                multiple={true}
                name="defibrillator_pad_site"
                onChange={props.handleChangeData}
                options={props.masterOptions?.orDefibrillatorSite}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.defibrillatorPadSite}>
              </Dropdown>
            </FormField>
          </FormGroup>
        </Form>
        <div
          style={{ height: "55px", paddingTop: "15px" }}>
          
          <div
            style={{display:"flex",justifyContent:"flex-end"}}>
            
            <div
              style={{margin:"10px"}}>
              {props.buttonSave}
            </div>
            <div
              style={{margin:"10px"}}>
              {props.buttonConfirm}
            </div>
            <div
              style={{margin:"10px"}}>
              {props.buttonUnconfirm}
            </div>
          </div>
        </div>
      </div>
    )
}


export default CardDefibrillatorUX

export const screenPropsDefault = {}

/* Date Time : Wed Dec 27 2023 16:29:52 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardDefibrillator"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"15px\",  padding: \"20px\",  backgroundColor: \"rgba(198, 235, 243, 0.2)\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ paddingBottom: \"5px\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "label",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Defibrillator (G)"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"18px\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "Label",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.status"
        },
        "color": {
          "type": "code",
          "value": "props.statusColor"
        },
        "style": {
          "type": "code",
          "value": "{ float: \"right\" }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "hr",
      "parent": 1,
      "props": {
      },
      "seq": 4,
      "void": true
    },
    {
      "from": null,
      "id": 154,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ height: \"55px\", paddingTop: \"15px\" }"
        }
      },
      "seq": 186,
      "void": false
    },
    {
      "from": null,
      "id": 155,
      "name": "div",
      "parent": 154,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",justifyContent:\"flex-end\"}"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": null,
      "id": 183,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"10px\"}"
        }
      },
      "seq": 183,
      "void": false
    },
    {
      "from": null,
      "id": 184,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonConfirm"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"10px\"}"
        }
      },
      "seq": 184,
      "void": false
    },
    {
      "from": null,
      "id": 185,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonUnconfirm"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"10px\"}"
        }
      },
      "seq": 185,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 186,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 161,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 187,
      "name": "FormGroup",
      "parent": 186,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 187,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 188,
      "name": "FormGroup",
      "parent": 186,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{alignItems: \"baseline\"}"
        }
      },
      "seq": 188,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 189,
      "name": "FormGroup",
      "parent": 186,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 189,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 190,
      "name": "FormField",
      "parent": 187,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 190,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 191,
      "name": "FormField",
      "parent": 188,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 191,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 192,
      "name": "FormField",
      "parent": 189,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 192,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 193,
      "name": "FormField",
      "parent": 188,
      "props": {
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 193,
      "void": false
    },
    {
      "from": null,
      "id": 194,
      "name": "label",
      "parent": 190,
      "props": {
        "children": {
          "type": "value",
          "value": "Defibrillator"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"130px\", maxWidth: \"130px\"}"
        }
      },
      "seq": 194,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 195,
      "name": "Checkbox",
      "parent": 190,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isDefibrillator"
        },
        "label": {
          "type": "code",
          "value": "props.isDefibrillator ? \"YES\" :\"NO\""
        },
        "name": {
          "type": "value",
          "value": "is_defibrillator"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 195,
      "void": false
    },
    {
      "from": null,
      "id": 196,
      "name": "label",
      "parent": 191,
      "props": {
        "children": {
          "type": "value",
          "value": "Defibrillator"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"130px\", maxWidth: \"130px\"}"
        }
      },
      "seq": 196,
      "void": false
    },
    {
      "from": null,
      "id": 197,
      "name": "label",
      "parent": 192,
      "props": {
        "children": {
          "type": "value",
          "value": "Defibrillator Pad Site "
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"130px\", maxWidth: \"130px\"}"
        }
      },
      "seq": 197,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 198,
      "name": "FormField",
      "parent": 189,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 198,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 199,
      "name": "Dropdown",
      "parent": 198,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "defibrillator_pad_site"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.orDefibrillatorSite"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.defibrillatorPadSite"
        }
      },
      "seq": 199,
      "void": false
    },
    {
      "from": null,
      "id": 200,
      "name": "div",
      "parent": 193,
      "props": {
        "children": {
          "type": "code",
          "value": "props.defibrillator"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 200,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 85,
  "isMounted": false,
  "memo": false,
  "name": "CardDefibrillatorUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
