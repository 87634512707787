import React, { SyntheticEvent, useMemo, useState, useEffect, useCallback } from "react";
import { Button } from "semantic-ui-react";

import moment from "moment";

// UX
import CardStockManagementTabAddUX from "./CardStockManagementTabAddUX";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

// Interface
import {
  AggStockSerializer,
  BUTTON_ACTIONS,
  PermissionsType,
  ProductLotSerializer,
  RunSequence,
  StockDetailType,
} from "./sequence/StockManagement";
import { CARD_STOCK_MANAGEMENT } from "./CardStockManagement";

// Utils
import { parseDate, formatDate } from "../../../utils/dateUtils";
import { useIntl } from "react-intl";

type CardStockManagementTabAddProps = {
  setProp: (key: string, value: any, callback?: Function) => any;
  // seq
  runSequence: RunSequence;
  // data
  data: Partial<AggStockSerializer>;
  productLotList?: ProductLotSerializer[];
  permissions?: PermissionsType;
  // CommonInterface
  buttonLoadCheck?: Record<string, any>;
  // options
  storageOptions?: any[];
  referenceTextOptions?: any[];
  productTypeOptions: any[];
};

const CARD_STOCK_MANAGEMENT_TAB_ADD = "CardStockManagementTabAdd";

const CardStockManagementTabAdd = (props: CardStockManagementTabAddProps) => {
  const intl = useIntl();
  // options
  const [lotNoOptions, setLotNoOptions] = useState<any[]>([]);
  // const [fromOptions, setFromOptions] = useState<any[]>([]);
  // data
  const [stockDetail, setStockDetail] = useState<Partial<StockDetailType>>({});
  const [stockList, setStockList] = useState<Partial<StockDetailType>[]>([]);

  // Callback Effect
  const handleSetStockDetail = useCallback(
    (data: any = {}) => {
      setStockDetail({
        product: props.data.product,
        ...data,
      });
    },
    [props.data]
  );

  // Memo Effect
  const productLotOptions = useMemo((): any[] => {
    return (props.productLotList || []).map((item) => ({
      key: item.id,
      value: item.mfc_no,
      text: item.mfc_no,
    }));
  }, [props.productLotList]);

  useEffect(() => {
    handleSetStockDetail({
      storage: props.data.storage?.id,
      bin_location: props.data.bin_location,
    });
  }, [props.data]);

  useEffect(() => {
    setLotNoOptions(productLotOptions);
  }, [productLotOptions]);

  // Callback Memo
  const handleRemove = useCallback((index: number) => {
    setStockList((stockList) => stockList.filter((_, idx) => idx !== index));
  }, []);

  const handleEdit = useCallback((item: any, index: number) => {
    setStockDetail({ ...item, index });
    setLotNoOptions(item.options);
  }, []);

  const productItems = useMemo(() => {
    return stockList.map((item, index) => {
      const name = item.product?.p_type_name;
      const option = props.productTypeOptions.find((acc) => acc.text === name);
      const storageName = props.storageOptions?.find((acc) => acc.value === item.storage)?.text;

      return {
        ...item,
        code: item.product?.code,
        name: item.product?.name,
        type: !!option ? `${option.value}: ${option.text}` : "",
        expire_date: formatDate(item.expire_date),
        storage: storageName,
        action: (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              icon="edit"
              color="yellow"
              size="mini"
              style={{ marginRight: "1rem" }}
              onClick={() => {
                handleEdit(item, index);
              }}
            />
            <Button
              icon="trash alternate"
              color="red"
              size="mini"
              onClick={() => {
                handleRemove(index);
              }}
            />
          </div>
        ),
        quantity: Number(item.quantity).toLocaleString("en-US"),
      };
    });
  }, [stockList]);

  const allowAdd = useMemo(() => {
    const isAllow = (
      ["storage", "bin_location", "lot_no", "expire_date", "quantity", "reference_text"] as const
    ).every((key) => !!stockDetail[key]);

    return isAllow && props.permissions?.TAB_ADD_ADD;
  }, [stockDetail, props.permissions]);

  const disabledExpireDate = useMemo(() => {
    const id = lotNoOptions.find((option) => option.value === stockDetail.lot_no)?.key;

    return typeof id === "number";
  }, [stockDetail, lotNoOptions]);

  // Handler
  // Table
  const handleGetTrProps = useCallback(
    (_state: any, rowInfo: any, _column: any, _instance: any) => {
      return {
        className:
          Number.isInteger(stockDetail.index) && rowInfo?.index === stockDetail.index
            ? "blueSelectedRow"
            : "",
      };
    },
    [stockDetail]
  );

  const handleAddLotNoOptions = (e: SyntheticEvent, v: any) => {
    setLotNoOptions([...lotNoOptions, { key: v.value, text: v.value, value: v.value }]);
  };

  const handleAddFromOptions = (e: SyntheticEvent, v: any) => {
    // setFromOptions([
    //   ...fromOptions,
    //   { key: v.value, text: v.value, value: v.value },
    // ]);
  };

  const handleChangeValue = (e: any, data: any) => {
    if (!props.permissions?.TAB_ADD_ADD) {
      return;
    }

    let value = data.value;
    const detail = { ...stockDetail };

    // quantity ต้องมากกว่าเท่ากับ 0
    if (data.name === "quantity") {
      value = Number(value) < 0 ? 0 : value;
    }
    // clear bin location เมื่อเปลี่ยน storage
    else if (data.name === "storage") {
      detail.bin_location = "";
    }
    // เก็บ expire date เป็น moment
    else if (data.name === "expire_date") {
      value = parseDate(value, true);
    }
    // เมื่อเปลี่ยน lot no ให้ fill expire date auto
    else if (data.name === "lot_no") {
      const expireDate =
        props.productLotList?.find((item) => item.mfc_no === value)?.exp_datetime || "";

      detail.expire_date = expireDate ? moment(expireDate) : detail.expire_date;
    }

    (detail as any)[data.name] = value;

    setStockDetail(detail);
  };

  const handleChangeDate = (name: string) => (value: string) => {
    handleChangeValue(null, { name, value });
  };

  const handleAddOrder = () => {
    const list = [...stockList];
    const detail = { ...stockDetail };
    const lotId = lotNoOptions.find((option) => option.value === detail.lot_no)?.key || null;

    const data = {
      ...detail,
      lot_id: typeof lotId === "number" ? lotId : null,
      options: lotNoOptions,
    };

    if (Number.isInteger(detail.index)) {
      list[detail.index || 0] = data;
    } else {
      list.push(data);
    }

    setStockList(list);
    setLotNoOptions(productLotOptions);

    handleSetStockDetail({
      storage: props.data.storage?.id,
      bin_location: props.data.bin_location,
    });
  };

  const handleClear = () => {
    handleSetStockDetail({
      storage: props.data.storage?.id,
      bin_location: props.data.bin_location,
    });

    setLotNoOptions(productLotOptions);
  };

  const handleCancel = () => {
    setStockList([]);

    handleClear();
  };

  const handleConfirm = () => {
    props.runSequence({
      sequence: "StockManagement",
      action: "ADD_STOCK",
      card: CARD_STOCK_MANAGEMENT_TAB_ADD,
      errorKey: CARD_STOCK_MANAGEMENT,
      btnAction: BUTTON_ACTIONS.SAVE,
      stockList: stockList,
      onSuccess: () => {
        handleCancel();
      },
    });
  };

  console.log("stockList", stockList);

  return (
    <div>
      <CardStockManagementTabAddUX
        // data
        name={stockDetail.product?.name}
        detail={stockDetail}
        expireDate={formatDate(stockDetail.expire_date)}
        productItems={productItems}
        // options
        storageOptions={props.storageOptions}
        fromOptions={props.referenceTextOptions}
        lotNoOptions={lotNoOptions}
        // config
        readOnly={!props.data.product?.name}
        disabledAdd={!allowAdd}
        disabledCancel={!stockList.length}
        disabledExpireDate={disabledExpireDate}
        // callback
        onAddItemLotNo={handleAddLotNoOptions}
        onAddItemFrom={handleAddFromOptions}
        onChangeValue={handleChangeValue}
        onChangeDate={handleChangeDate}
        onAdd={handleAddOrder}
        onClear={handleClear}
        onCancel={handleCancel}
        // Table
        getTrProps={handleGetTrProps}
        // Element
        ButtonConfirm={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleConfirm}
            // data
            paramKey={`${CARD_STOCK_MANAGEMENT_TAB_ADD}_${BUTTON_ACTIONS.SAVE}`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[`${CARD_STOCK_MANAGEMENT_TAB_ADD}_${BUTTON_ACTIONS.SAVE}`]
            }
            // config
            disabled={!stockList.length}
            color={"green"}
            name="SAVE"
            size="medium"
            title={intl.formatMessage({ id: "ยืนยันการเติมสินค้า" })}
          />
        }
        languageUX={props.languageUX}
      />
    </div>
  );
};

export default React.memo(CardStockManagementTabAdd);
