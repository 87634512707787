import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Form,
  FormGroup,
  FormField
} from 'semantic-ui-react'

const ANES_ChargeItem = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        id="CardAnesBillTable"
        style={{padding: "0 15px"}}>
        <div>
          
          <Table
            columns={props.columnsBill}
            data={props.data || []}
            headers={props.headers}
            keys={props.keys}
            minRows={10}
            showPagination={false}
            widths="80,200">
          </Table>
        </div>
        <Form>
          <FormGroup
            inline={true}
            style={{marginTop: "1rem"}}>
            <FormField
              inline={true}
              style={{flex:1}}>
            </FormField>
            <FormField
              inline={true}>
              <div>
                {props.buttonCalculate}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <div>
                {props.buttonSave}
              </div>
            </FormField>
          </FormGroup>
        </Form>
      </div>
    )
}


export default ANES_ChargeItem

export const screenPropsDefault = {}

/* Date Time : Fri Dec 08 2023 15:49:19 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardAnesBillTable"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 15px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 2,
      "name": "Table",
      "parent": 1,
      "props": {
        "columns": {
          "type": "code",
          "value": "props.columnsBill"
        },
        "data": {
          "type": "code",
          "value": "props.data || []"
        },
        "defaultPageSize": {
          "type": "value",
          "value": ""
        },
        "headers": {
          "type": "code",
          "value": "props.headers"
        },
        "keys": {
          "type": "code",
          "value": "props.keys"
        },
        "minRows": {
          "type": "code",
          "value": "10"
        },
        "pageSize": {
          "type": "code",
          "value": ""
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "widths": {
          "type": "value",
          "value": "80,200"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "FormGroup",
      "parent": 11,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"1rem\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "FormField",
      "parent": 12,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "FormField",
      "parent": 12,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "FormField",
      "parent": 12,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonCalculate"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        }
      },
      "seq": 17,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "ANES_ChargeItem",
  "project": "IsHealth_by_Front-end",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
