import bplusClinicTH from "./bplusClinic/th.json";
import ishealthTH from "./IsHealth/th.json";
import cudentTH from "./cudent/th.json";
import raksthaiTH from "./raksthai/th.json"

import bplusClinicEN from "./bplusClinic/en.json";
import ishealthEN from "./IsHealth/en.json";
import cudentEN from "./cudent/en.json";
import raksthaiEN from "./raksthai/en.json"

export const localizationTH = {
  ...raksthaiTH,
  ...cudentTH,
  ...bplusClinicTH,
  ...ishealthTH,
}

export const localizationEN = {
  ...raksthaiEN,
  ...cudentEN,
  ...bplusClinicEN,
  ...ishealthEN,
}