import { useIntl } from "react-intl";
import React, {
  useState,
  useRef,
  CSSProperties,
  useEffect,
  useMemo,
  AllHTMLAttributes,
  MutableRefObject,
  MouseEvent,
} from "react";
import { Form } from "semantic-ui-react";

// UX gen
import DentalRecord1UX, { HotSpot } from "./DentalRecordUX";
import DentalRecordNoBaby1UX, { HotSpotNoBaby } from "./DentalRecordNoBabyUX";

// Common
import toothDetails from "./ToothDetails";

// Interface
import {
  getAbbrSurface,
  getSurfaceName,
  getOrgansWithRecord,
  superTeethTSOrganToTSHuman,
  superTeethTSHumanToTSOrgan,
  superTeethSHumanToSOrgan,
  superTeethSOrganToSHuman,
  deTinyLocation2,
  getSurfaceBorderLine,
  MissingToothClinical,
  getOnlyFullTeeth,
} from "./DentalRecordUtil";
import { SyntheticEvent } from "react";

// Types
type DentalDiagramProps = {
  onEvent: (e: any) => any;
  // data
  clinicalFindingId?: number | null;
  clinicalFindingIndex?: number | null;
  clinicalFindings: Record<string, any>[]; // {location: "16-18", locations_name: "16-18", clinicaltags: "Cavity"}
  masterData: Partial<{
    organ: { name: string; items: any[] };
    clinicaltags: { name: string; items: any[] };
  }>;
  superteeth: string[]; // superteeth: ['TS38D'];
  missingteeth: Record<string, any>[]; // [ { id: "tooth37", tissue: "tooth", nickName: "37", fullName: "3-7", primary: "37", secondary: [], q: 3, i: 7, hasChildren: true, children: [], }, ];;
  implantteeth: Record<string, any>[]; // [ { id: "tooth47", tissue: "tooth", nickName: "47", fullName: "4-7", primary: "47", secondary: [], q: 4, i: 7, hasChildren: true, children: [], }, ];
  filterClinicalFindingIds: number[]; // map id ของ clinicalFindings;
  filterItem?: string | null;
  // config
  selectorMode?: boolean;
  hideBabyTooth: boolean;
  sub?:
    | "DiagnosisToday"
    | "TreatmentToday"
    | "DiagnosisHistory"
    | "TreatmentHistory";
  historyReviewSubTab?: number;
  showDrawingChecked: Record<string, any>[];
  svgZoom?: number;
  // callback
  setSuperTooth?: (tooth: string) => any;
};

const DentalDiagram = (props: DentalDiagramProps) => {
  const intl = useIntl();

  const tooltipRef = useRef<any>();
  const [selecting, setSelecting] = useState(false);
  const [startPoint, setStartPoint] = useState<{ x: number; y: number } | null>(
    null
  );
  const [endPoint, setEndPoint] = useState<{ x: number; y: number } | null>(
    null
  );
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  // const [chooseMissingTeeth, setChooseMissingTeeth] = useState(false);
  const [chooseSupernumerary, setChooseSupernumerary] = useState(false);
  const [openGallery, setOpenGallery] = useState(false);
  const [openPhysicalExam, setOpenPhysicalExam] = useState(false);

  const [organMaster, setOrganMaster] = useState<any[] | null>(null);
  const [superteethPatient, setSuperTeethPatient] = useState<any[]>([]); // e.g. TS12S13
  const [colorTag, setColorTag] = useState<any[]>([]);

  // const [offsetTop, setOffsetTop] = useState(0)
  // const [offsetLeft, setOffsetLeft] = useState(0)

  // const [toothFilter, setToothFilter] = useState("")

  const selectionBoxRef = useRef() as MutableRefObject<HTMLDivElement>;

  //   useEffect(() => {
  //     if ( startPoint && endPoint ) {
  //         let svg = document.getElementById("svg-dr");
  //         let offset = getOffset(svg)
  //         if (offsetLeft !== offset.left) {
  //             setOffsetLeft(offset.left)
  //         }
  //         if (offsetTop !== offset.top) {
  //             setOffsetTop(offset.top)
  //         }
  //     }
  //     // console.log("useEffect DentalRecord offset", offsetLeft, offsetTop, props.selectionBox)
  // }, [startPoint, endPoint])

  // เปลี่ยน Clinical Finding ยกเลิกออกจากMode ใดๆ
  useEffect(() => {
    if (props.clinicalFindingId) {
      setChooseSupernumerary(false);
      // setChooseMissingTeeth(false);
    }
  }, [props.clinicalFindingId]);

  useEffect(() => {
    if (props.masterData?.organ?.items) {
      const organ = props.masterData?.organ?.items?.filter(
        (item) => item.tissue === "tooth"
      );

      setOrganMaster(organ);
    }
  }, [props.masterData?.organ]);

  useEffect(() => {
    if (props.superteeth?.length > 0) {
      const toothList = props.superteeth.map((item) =>
        superTeethTSHumanToTSOrgan(item)
      );

      setSuperTeethPatient(toothList);
    } else {
      setSuperTeethPatient([]);
    }
  }, [props.superteeth]);

  useEffect(() => {
    // console.log("DentalRecord1 useEffect props.controller?.data?.clinicaltags?.items", props.controller?.data?.clinicaltags?.items)
    if ((props.masterData?.clinicaltags?.items?.length || 0) > 0) {
      const toothTags: any[] =
        props.masterData?.clinicaltags?.items?.filter(
          (item) => item.diagram === "teeth"
        ) || [];

      toothTags.map((item) => {
        const prepare = item;

        prepare.detail = item.is_group ? !item.is_group : item.detail;
        prepare.color = item.colors ? item.colors : item.color;

        return prepare;
      });

      setColorTag(toothTags);
      // console.log("DentalRecord1useEffect props.controller?.data?.clinicaltags?.items setColorTag colorTag", toothTags);
    }
  }, [props.masterData.clinicaltags?.items]);

  const abbrSurfaceDict = useMemo(() => {
    return toothDetails.reduce(
      (result, item) => ({
        ...result,
        [`${item.q}${item.i}`]: item.surfaceName.map((name) =>
          name === "Labial"
            ? "La"
            : name === "Palatal"
            ? "Pa"
            : name === "Lingual"
            ? "Li"
            : name[0]
        ),
      }),
      {} as Record<string, string[]>
    );
  }, []);

  // console.log("DentalRecord1 props: ->", props)
  // console.log("DentalRecord1 props.controller.data",props.controller.data , "colorTag", colorTag)

  let selectingBoxStyle: CSSProperties | null = null;
  let settings: Record<
    string,
    CSSProperties &
      AllHTMLAttributes<SVGAElement> & { missing?: string; implant?: boolean }
  > = {};
  let spot: any[] = [];

  const handleSelectAll = (teethSet: string[]) => {
    if (props.clinicalFindingIndex !== null && props.clinicalFindingId) {
      let tooth = deTinyLocation2(teethSet, organMaster || []);
      console.log("hanndleSelectAll:", tooth);
      tooth = tooth.map((item) => {
        let tt = organMaster?.find((o) => item === o.nickName);
        tt.location = tt.nickName;
        return tt;
      });

      console.log("select item", tooth);
      props.onEvent({
        message: "AddLocation",
        params: { items: tooth, teethSet },
      });
    }
  };

  settings["q1btn"] = {
    ...settings["q1btn"],
    cursor: "pointer",
    pointerEvents: "auto",
    visibility: props.selectorMode ? "visible" : "hidden",
    onClick: () => handleSelectAll(["11-18"]),
  };
  settings["q2btn"] = {
    ...settings["q2btn"],
    cursor: "pointer",
    pointerEvents: "auto",
    visibility: props.selectorMode ? "visible" : "hidden",
    onClick: () => handleSelectAll(["21-28"]),
  };
  settings["q3btn"] = {
    ...settings["q3btn"],
    cursor: "pointer",
    pointerEvents: "auto",
    visibility: props.selectorMode ? "visible" : "hidden",
    onClick: () => handleSelectAll(["31-38"]),
  };
  settings["q4btn"] = {
    ...settings["q4btn"],
    cursor: "pointer",
    pointerEvents: "auto",
    visibility: props.selectorMode ? "visible" : "hidden",
    onClick: () => handleSelectAll(["41-48"]),
  };
  settings["ubtn"] = {
    ...settings["ubtn"],
    cursor: "pointer",
    pointerEvents: "auto",
    visibility: props.selectorMode ? "visible" : "hidden",
    onClick: () => handleSelectAll(["11-18", "21-28"]),
  };
  settings["lbtn"] = {
    ...settings["lbtn"],
    cursor: "pointer",
    pointerEvents: "auto",
    visibility: props.selectorMode ? "visible" : "hidden",
    onClick: () => handleSelectAll(["31-38", "41-48"]),
  };
  settings["fmbtn"] = {
    ...settings["fmbtn"],
    cursor: "pointer",
    pointerEvents: "auto",
    visibility: props.selectorMode ? "visible" : "hidden",
    onClick: () => handleSelectAll(["11-18", "21-28", "31-38", "41-48"]),
  };

  // const handleMissing = (e) => {
  //   console.log(" handleMissing !!!!! ", e.target);
  //   let q = e.target.id.slice(1, 2);
  //   let i = e.target.id.slice(2, 3);
  //   props.setMissingTooth({ q: Number(q), i: Number(i) });
  // };

  const handleSetSupernumerary = (key: string) => (e: SyntheticEvent) => {
    console.log(" set handleSetSupernumerary", key); // e.g. TS34B TS31S41
    const tooth = superTeethTSOrganToTSHuman(key); // e.gg TS34B TS31/41
    props.setSuperTooth?.(tooth);
  };

  const handleAddSupernumerary = (key: string) => (e: SyntheticEvent) => {
    console.log(" handleAddSupernumerary to Location", key);
  };

  //   const getOffset = (element) => {
  //     let bound = element.getBoundingClientRect();
  //     let html = document.documentElement;
  //     let offset = {
  //         top: bound.top + window.pageYOffset - html.clientTop,
  //         left: bound.left + window.pageXOffset - html.clientLeft
  //     }
  //     // console.log(" getOffset offset", offset)
  //     return offset;
  // }

  const priorityColor = (colorList?: string[], defaultColor?: string[]) => {
    // แก้จากเดิม
    // (i) Cavity (แดง) ร่วมกับ เลือก Filling (น้ำเงิน) ใน location เดียวกัน ให้แสดง สีน้ำเงินขอบแดง
    // (ii) Cavity (แดง) ร่วมกับ เลือกDirect restoration (เหลือง) ใน location เดียวกัน ให้แสดง สีเหลืองขอบแดง
    // (iii) การแสดง Clinical finding บน dental diagram ที่ไม่เข้ากรณี i และ ii ให้ แสดงสี หรือสัญลักษณ์ตามการลง Examination ลำดับหลังสุด
    // เป็น
    // 1. กากบาท สีดำ (Congenital absence & Extracte tooth)  //grey
    // 2. สีดำ (Clinical absence, Partial eruption, Unerupted tooth) //grey
    // 3. Implant teal
    // 4.1. Filling + Cavity น้ำเงินขอบแดง  // blue
    // 4.2. Indirect restoration + Cavity เหลืองขอบแดง // yellow
    // 5. เงื่อนไข อื่น ๆ ให้แสดงตาม Clinical finding ที่ลงหลังสุด
    // console.log(" priorityColor colorList: ", colorList)

    let colorTagList = [...(colorList || [])] ?? [];

    // console.debug("finishColor priorityColor Pre colorTagList: ", colorTagList);

    if (!!colorTagList.length) {
      // // 50594
      // if(colorTagList.includes("pink")){
      //   // missing tooth and clinical in missing
      //   return ["pink"]
      // } else if (colorTagList.includes("grey")){
      //   // implant tooth
      //   return ["grey"]
      // } else if (colorTagList.includes("red") && colorTagList.length > 1) {
      //   if (colorTagList.includes("blue")){
      //     colorTagList = ["blue", "red"];
      //   }else if (colorTagList.includes("yellow")){
      //     colorTagList = ["yellow", "red"];
      //   }else{
      //     colorTagList = [colorTagList.pop()];
      //   }
      // } else {
      //   colorTagList = [colorTagList.pop()];
      // }

      if (colorTagList.includes("grey")) {
        // console.log("priorityColor Return Gray")
        return ["grey"];
      } else if (colorTagList.includes("green")) {
        // console.log("priorityColor Return green")
        return ["green"];
      } else if (colorTagList.includes("red") && colorTagList.length > 1) {
        let list = colorTagList.filter(
          (item) => item !== "red" && item !== "black"
        );
        // maybe stoke
        if (list.length > 0) {
          // have second
          let primaryColor =
            list.reverse().find((item) => item !== "red") || "";
          colorTagList = [primaryColor, "red"];
          // console.log("colorTagList after inject red", colorTagList)
        } else {
          colorTagList = ["red"];
          // console.log("colorTagList else colorTagList: ", colorTagList)
        }
      } else {
        colorTagList = [colorTagList.pop() as any];
        // console.log("colorTagList else colorTagList: ", colorTagList)
      }
      // console.debug("finishColor priorityColor is colorTagList", colorTagList);
      return colorTagList;
    }

    // if (colorTagList?.length > 0) {
    //   console.log("colorTagList if", colorTagList)
    //   // colorTagList = [...new Set(colorTagList)]  // lost last info
    //   // priority
    //   if (colorTagList.includes("red") && colorTagList.length > 1) {
    //     // maybe stoke
    //     if (colorTagList.filter(item => item !== "red").length > 0) {
    //       // have second
    //       let primaryColor = colorTagList.reverse().find(item => item !== "red")
    //       colorTagList = [primaryColor, "red"]
    //       console.log("colorTagList after inject red", colorTagList)
    //     } else {
    //       colorTagList = [colorTagList.pop()]
    //       console.log("colorTagList else colorTagList: ", colorTagList)
    //     }
    //   } else {
    //     colorTagList = [colorTagList.pop()]
    //     console.log("colorTagList else colorTagList: ", colorTagList)
    //   }
    //   console.log(" priorityColor is colorTagList", colorTagList)
    //   return colorTagList
    // }
    //console.debug("finishColor priorityColor is defaultColor", defaultColor);
    return defaultColor;
  };

  const getColorListFrom = (tags: string, defaultColor: string) => {
    let tagsList = tags.split(",").map((item) => item.trim());
    //console.debug("finishColorgetColorListFrom finishColor priorityColor colorTag: ", colorTag);
    let colorTagList = tagsList
      .map((tag) => colorTag.find((colorT) => colorT.name === tag) ?? "")
      .filter((item) => item);
    colorTagList = colorTagList.map((item) =>
      item.is_group
        ? item.color
        : colorTag
            .filter((colorT) => colorT.is_group)
            .find((colorT) => colorT.name === item.group).color
    );
    //console.debug("getColorListFrom finishColor priorityColor colorTagList: ", colorTagList);
    colorTagList = colorTagList.flat().map((item) => item.toLowerCase());

    let prioColorList = priorityColor(colorTagList, [defaultColor]);
    //console.debug("getColorListFrom finishColor priorityColor Return ColorList prioColorList", prioColorList);
    return prioColorList;
  };

  /********************************************************************/
  /******* Mouse Down *******/
  /********************************************************************/
  const handleMouseDown = (e: any) => {
    if (openGallery || openPhysicalExam) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    if (props.selectorMode) {
      /*********************** selctorMode ***********************/
      // console.log("Just Click (onMouseDown) at by onClick ,", e.target.id, "e.target?.id.substring(0,1)", e.target?.id.substring(0, 1), "e.target.id && e.target?.id.substring(0,1) === T ", e.target.id && e.target?.id.substring(0, 1) === "T")
      if (e.target.id && e.target?.id.substring(0, 1) === "T") {
        // e.target.id  e.g. T33-4 , T36-cs,
        let q = e.target?.id.substring(1, 2);
        let i = e.target?.id.substring(2, 3);
        let numberI = Number(i);
        let numberQ = Number(q);

        let section = e.target.id.substring(4, 5);

        if (section === "c") {
          // Super Teeth
          // console.log(" props.controller.data.organ.items", props.controller.data.organ.items)
          const supertoothOrgan = organMaster?.filter((item) =>
            item.id?.includes("supertooth")
          );
          console.log("DataMaster:", organMaster);
          // console.log(" supertoothOrgan", supertoothOrgan)
          const superSection = e.target.id.substring(5)?.toUpperCase();
          // console.log()
          const surfaceName = supertoothOrgan?.find(
            (item) => item.id === "supertooth" + q + i + superSection
          );
          // console.log("!!!!!!  surfaceName ", surfaceName)
          // let location = q + i + superSection
          if (surfaceName) {
            const location = surfaceName.nickName;
            const items = [{ q: numberQ, i: numberI, location }];
            // console.log("🚀 ~ file: DentalRecord1.tsx ~ line 364 ~ DentalRecord1 ~ items", items)
            // console.log("Just Click surfaceName  q", q, "i", i, "seciton", section, "location", location)
            setSelectedItems(items);
          }
        } else {
          // console.log("section ", section)

          // Normal Teeth
          if (section !== "5") {
            const surfaceName = toothDetails.find(
              (item) => item.i === numberI && item.q === numberQ
            )?.surfaceName[section];

            if (surfaceName) {
              // console.log(" surfaceName", surfaceName)
              const location =
                q +
                i +
                (surfaceName ? getAbbrSurface(surfaceName) : surfaceName);
              const items = [{ q: numberQ, i: numberI, location }];
              // console.log("Just Click  q", q, "i", i, "seciton", section, "surfaceName", surfaceName, "location", location)
              setSelectedItems(items);
            }
          } else if (section === "5") {
            // console.log("Just Click  q", q, "i", i, "seciton", section)
            const surfaceNameList =
              toothDetails.find(
                (item) => item.i === numberI && item.q === numberQ
              )?.surfaceName || [];
            const items = surfaceNameList.slice(0, -1).map((item) => {
              return {
                q: numberQ,
                i: numberI,
                location: q + i + (item ? getAbbrSurface(item) : item),
              };
            });
            // console.log(" items ", items)
            setSelectedItems(items);
          }
        }
      }
      setSelecting(true);

      // var bounds = e.target.getBoundingClientRect();
      // var x = e.clientX - bounds.left;
      // var y = e.clientY - bounds.top;

      // console.log("onmouse e", e.pageX, e.pageY, e)
      const { x, y } = selectionBoxRef.current.getBoundingClientRect();

      e.pageX = e.pageX - x;
      e.pageY = e.pageY - y;

      setStartPoint({
        x: e.pageX,
        y: e.pageY,
      });
      // setStartPoint({ x: ((e.pageX * 100) / 65), y: ((e.pageY * 100) / 65) });
      e.persist();
    } else {
      /*********************** Normal  ***********************/
      if (!chooseSupernumerary) {
        // console.log("onMouseDown :  e.target ", e.target)
        props.onEvent({
          message: "SelectedDiagramItem",
          params: { selectedDiagram: e.target?.id },
        });
        // props.controller.setState({selectedDiagram: e.target?.id })
        // setToothFilter(
      }
    }
  };

  /********************************************************************/
  /******* Mouse Move *******/
  /********************************************************************/
  const handleMouseMove = (e: MouseEvent<HTMLDivElement>) => {
    if (!selecting) {
      return;
    }
    // var bounds = e.target.getBoundingClientRect();
    // var x = e.clientX - bounds.left;
    // var y = e.clientY - bounds.top;

    // var x = e.pageX - e.target.offset.left
    // var y = e.pageY - e.target.offset.top

    // console.log(
    //   "Prepare Selected onmouse x ,y , e.PageX, e.PageY ",
    //   e,
    //   e.pageX,
    //   e.pageY,
    //   e.clientX,
    //   e.screenX,
    //   e.movementX
    // );
    const { x, y } = selectionBoxRef.current.getBoundingClientRect();

    e.pageX = e.pageX - x;
    e.pageY = e.pageY - y;

    setEndPoint({
      x: e.pageX,
      y: e.pageY,
    });
    // setEndPoint({ x: ((e.pageX * 100) / 65), y: ((e.pageY * 100) / 65) });
    // console.log(" props.superteeth", props.superteeth) // ["T38S", "T38SL", "T65S", "T36SN", "T36S", "T36SS", "T37S"]
    let items = getSelectedItems(
      selecting,
      selectionBoxRef,
      startPoint,
      endPoint,
      props.hideBabyTooth,
      superteethPatient,
      props.svgZoom
    );
    // console.log("Just Click onMouseMove ", items)
    if (items.length > 0) {
      setSelectedItems(items);
      // console.log("Just Click item ", items)
    } else if (items.length === 0) {
      setSelectedItems([]);
    }
  };

  /********************************************************************/
  /******* Mouse Leave *******/
  /********************************************************************/
  const handleMouseLeave = () => {
    if (!selecting) {
      return;
    }
    handleFinishDragMouse();
  };

  /********************************************************************/
  /******* Mouse Up *******/
  /********************************************************************/
  const handleMouseUp = (e: any) => {
    if (openGallery || openPhysicalExam) {
      return;
    }
    handleFinishDragMouse();
  };

  /********************************************************************/
  /******* Mouse Over / Mouse Leave Hover *******/
  /********************************************************************/
  const handleMouseOver = (e: any, key: string) => {
    // const organMaster = props.masterData?.organ?.items
    // console.log("handleMouseOver e, ", e, "key",  key, organMaster)

    let organ = spot?.find((item) => item.location === key.slice(1));
    // console.log("handleMouseOver organ:", organ)

    if (organ) {
      const tooltip = tooltipRef.current;
      const svgZoom = selectionBoxRef.current?.querySelector(
        ".tooth-area .svg-zoom-out"
      ) as HTMLFormElement;
      let zoom = 0.65;

      if (!!props.svgZoom) {
        zoom = props.svgZoom;
      } else if (svgZoom) {
        let compStyles = window.getComputedStyle(svgZoom);
        zoom = Number.parseFloat(compStyles.getPropertyValue("zoom"));
      }

      let { top, left, height, right } = e.target.getBoundingClientRect();
      let { x, y } = selectionBoxRef.current.getBoundingClientRect();

      // issue 52820
      top = top * zoom - y;
      left = left * zoom - x;
      height *= zoom;
      right = right * zoom - x;

      tooltip.style.top = `${top + height + 10}px`;
      tooltip.innerHTML = superTeethSOrganToSHuman(organ.location);
      tooltip.style.display = "";

      const { width } = tooltip.getBoundingClientRect();
      const center = (left + right) / 2 - width / 2;

      tooltip.style.left = `${center}px`;
    }
  };

  const handleMouseLeaveHover = () => {
    tooltipRef.current.style.display = "none";
  };

  /********************* Hide Baby Teeth Case ************************/
  if (props.hideBabyTooth) {
    spot = HotSpotNoBaby;
  } else {
    spot = HotSpot;
  }

  // console.log(" spot", spot)
  /********************* Super Teeth  ************************/
  // Clear All
  // console.log(" spot ", spot )
  spot
    .filter((item) => item.location.startsWith("S"))
    .forEach((item) => {
      const key = "T" + item.location;
      settings[key] = {
        visibility: "hidden",
      };
      settings[key + "t"] = {
        visibility: "hidden",
      };
    });

  // console.log("settings", settings, "props.superteeth", props.superteeth)
  // Add Super from db

  for (const key of superteethPatient || []) {
    settings[key] = {
      ...settings[key],
      visibility: "visible",
      fill: "white",
    };
    settings[key + "t"] = {
      ...settings[key + "t"],
      visibility: "visible",
      fill: "black",
    };
  }

  // console.log("Color Check render!!  props.clinicalFindingIndex", props.clinicalFindingIndex)

  if (props.selectorMode) {
    /*******************************************************************/
    /************************* selectorMode Selecting Case *************/
    /*******************************************************************/
    if (selecting) {
      const parentNode = selectionBoxRef?.current || null;
      // console.log(parentNode.offsetLeft)
      if (
        typeof parentNode !== "undefined" &&
        parentNode &&
        startPoint &&
        endPoint
      ) {
        const left = Math.min(startPoint.x, endPoint.x);
        const top = Math.min(startPoint.y, endPoint.y);
        const width = Math.abs(startPoint.x - endPoint.x);
        const height = Math.abs(startPoint.y - endPoint.y);

        // console.log(" onmouse position", left, top, width, height);
        // console.log(" onmouse startPoint", startPoint, "endPoint", endPoint);
        // console.log(
        //   "onmouse selectionBoxRef?.current ",
        //   selectionBoxRef?.current
        // );

        selectingBoxStyle = {
          position: "absolute",
          left: left,
          top: top,
          width: width,
          height: height,
          border: "solid black 1px",
        };
      }
    } else {
      selectingBoxStyle = null;
    }

    // console.log(" DentalRecord1 ")
    // From clinicla Findings
    // console.log("props.clinicalFindingIndex", props.clinicalFindingIndex)
    // console.log("props.clinicalFindings", props.clinicalFindings)
    let organsWithMulitSelected = getOrgansWithRecord(organMaster, [
      props.clinicalFindings[props.clinicalFindingIndex as any],
    ]);

    console.log("organsWithMulitSelected: ", organsWithMulitSelected);

    // console.log("props.clinicalFindingIndex", props.clinicalFindingIndex)

    for (const organ of organsWithMulitSelected) {
      const key = "T" + organ;

      settings[key] = { ...settings[key], fill: "orange", opacity: 1 };
    }

    // From Selecting Item
    for (const item of selectedItems) {
      let toothLocation = superTeethSHumanToSOrgan(item.location); // S13/14 to S13S14
      const key = "T" + toothLocation;

      settings[key] = { ...settings[key], fill: "#5bd05b", opacity: 1 };
    }

    /********************* Super Teeth  ************************/
    // Add Super from db  and add Selected
    // let selectedSuperTeeth = props.selectedClinicalFinding.location?.split(",").filter(item => item.includes("S") || item.includes("SL"))
    for (const key of superteethPatient || []) {
      settings[key] = {
        ...settings[key],
        onClick: handleAddSupernumerary(key),
      };
      settings[key + "t"] = {
        ...settings[key + "t"],
        onClick: handleAddSupernumerary(key),
      };
    }

    // console.log("settings ", settings)

    let fullTeethEditing: any[] = organsWithMulitSelected
      .filter((item) => !item.startsWith("S"))
      .map((item) => item.slice(0, 2));
    let superTeethEditing = organsWithMulitSelected.filter((item) =>
      item.startsWith("S")
    );
    fullTeethEditing = [...Array.from(new Set(fullTeethEditing))];
    superTeethEditing = [...Array.from(new Set(superTeethEditing))];
    // console.log("Check superTeethEditing", superTeethEditing)
    /********************* Missing Teeth  ************************/
    for (const t of props.missingteeth || []) {
      // const key = "T" + `${t.q}${t.i}`;
      let key = "T";
      if (t.nickName.startsWith("S")) {
        // console.log("Check t.nickName", t.nickName)
        // Super teeth
        let name = t.nickName.replace("/", "S");
        if (!superTeethEditing.includes(name)) {
          key = key + name;
          settings[key] = {
            ...settings[key],
            missing: "true",
            visibility: "hidden",
          };
          settings[key + "t"] = {
            ...settings[key + "t"],
            missing: "true",
            visibility: "hidden",
          };
        }
      } else {
        // on Edit no hide teeth
        if (!fullTeethEditing.includes(`${t.q}${t.i}`)) {
          // Full teeth
          key = key + `${t.q}${t.i}`;
          // Big circle
          settings[key + "C"] = {
            ...settings[key + "C"],
            missing: "true",
            visibility: "hidden",
          };

          let oMaster = props.masterData?.organ?.items
            .filter((item: any) => item.tissue === "tooth")
            .filter((item: any) => item.children?.length === 5)
            .find((item: any) => item.i === t.i && item.q === t.q);
          oMaster.children.forEach((item: any) => {
            let key = item.replace("tooth", "T");
            // console.log("orgainMaster key", key )
            settings[key] = {
              ...settings[key],
              missing: "true",
              visibility: "hidden",
            };
          });
        }
      }
    }

    console.log(" props.implantteeth", props.implantteeth);

    /********************* Implant Teeth  ************************/
    for (const t of props.implantteeth || []) {
      const key = "T" + `${t.q}${t.i}`;
      settings[key] = {
        ...settings[key],
        implant: true,
      };
    }
  } else {
    /*******************************************************************/
    /************* Normal Case ** or Choose Super Numerary *************/
    /*******************************************************************/

    let organsWithRecord;

    // Enabled Supernumerary
    if (chooseSupernumerary) {
      /********************* Choose Super Numerary *********************/
      for (const key in settings) {
        if (Object.prototype.hasOwnProperty.call(settings, key)) {
          if (key.includes("SL") || key.includes("S")) {
            if (settings[key].visibility === "visible") {
              settings[key] = {
                fill: "red",
                onClick: handleSetSupernumerary(key),
                cursor: "pointer",
              };

              if (key.includes("t")) {
                settings[key] = {
                  fill: "white",
                  onClick: handleSetSupernumerary(key),
                  cursor: "pointer",
                };
              }
            } else {
              settings[key] = {
                visibility: "visible",
                fill: "gray",
                color: "black",
                cursor: "pointer",
                onClick: handleSetSupernumerary(key),
              };

              if (key.includes("t")) {
                settings[key] = {
                  visibility: "visible",
                  fill: "white",
                  color: "black",
                  cursor: "pointer",
                  onClick: handleSetSupernumerary(key),
                };
              }
            }
          }
        }
      }
    } else {
      /********************* Normal *********************/

      // console.log("Color props.clinicalFindingIndex ", props.clinicalFindingIndex)
      // console.log("Color props.filterClinicalFindingIds ", props.filterClinicalFindingIds)
      if (props.clinicalFindingIndex !== null) {
        // Selected 1 Row
        let colorList: any;

        if (
          props.clinicalFindings[props.clinicalFindingIndex as any]
            ?.clinicaltags
        ) {
          colorList = getColorListFrom(
            props.clinicalFindings[props.clinicalFindingIndex as any]
              ?.clinicaltags,
            "orange"
          );
        } else {
          colorList = ["orange"];
        }
        // console.log("Color 1 row colorList", colorList, props.clinicalFindings[props.clinicalFindingIndex])

        organsWithRecord = getOrgansWithRecord(organMaster, [
          props.clinicalFindings[props.clinicalFindingIndex as any],
        ]);

        // console.log("colorList: : organsWithRecord #1",key,colorList, organsWithRecord)
        // Do Color
        for (const organ of organsWithRecord) {
          /// All Organ throw here
          const key = "T" + organ;

          // แสดงขอบ surface
          const lineKeys = getSurfaceBorderLine(
            organ,
            abbrSurfaceDict,
            organsWithRecord
          );

          lineKeys.forEach((key) => {
            settings[key] = {
              ...(colorList?.length > 1 && {
                stroke: colorList[1],
                strokeWidth: 2,
                opacity: 1,
              }),
            };
          });

          settings[key] = {
            ...settings[key],
            fill: colorList[0],
            ...(colorList?.length > 1 && {
              stroke: colorList[1],
              strokeWidth: 2,
            }),
            opacity: 1,
          };
        }
      } else {
        // Normal Case use Filter

        // organsWithRecord = getOrgansWithRecord(
        //   organMaster,
        //   props.clinicalFindings.filter(item => props.filterClinicalFindingIds.includes(item.id))
        // );
        // // Do Color
        // for (const organ of organsWithRecord) {
        //   /// All Organ throw here
        //   const key = "T" + organ;
        //   settings[key] = {
        //     ...settings[key],
        //     fill: "orange",
        //     opacity: 1,
        //   };
        // }

        // Normal Case use Filter

        // console.log("props.filterClinicalFindingIds ", props.filterClinicalFindingIds)
        let fcfs = props.clinicalFindings.filter((item) =>
          props.filterClinicalFindingIds.includes(item.id)
        );

        const organsWithRecordAll = getOrgansWithRecord(organMaster, fcfs);
        //console.debug("finishColor priorityColor Color All row fcfs: ", fcfs);
        fcfs.forEach((fcf) => {
          let colorList: any[] = [];
          //console.debug("finishColor priorityColor fcf", fcf);

          // TODO : have bug when first cf has clinical tag then (RED color) after next row no clincical tag then override with orange
          if (fcf?.clinicaltags) {
            // console.log(" fcf?.clinicaltags ", fcf?.clinicaltags)
            colorList = getColorListFrom(fcf?.clinicaltags, "orange") || [];
          } else {
            colorList = ["orange"];
          }

          // console.log("Color colorList: ", colorList, "fcf?.clinicaltags: ", fcf?.clinicaltags)
          //console.debug("!!!!!!!!!!!!!!!!! finishColor priorityColor colorList: :", colorList);
          // console.log("colorList: : fcf", fcf)

          organsWithRecord = getOrgansWithRecord(organMaster, [fcf]);
          // Do Color
          // console.debug("finishColor priorityColor colorList: : organsWithRecord #2", organsWithRecord, organsWithRecordAll, fcf);
          for (const organ of organsWithRecord) {
            /// All Organ throw here
            const key = "T" + organ;
            //console.debug("finishColor priorityColor key: ", key);
            //console.debug("finishColor priorityColor settings: ", settings[key]);

            let finishColor: any[] = [...colorList];
            // Combile Color in Vertical
            if (settings[key] && settings[key].fill !== "white") {
              //console.debug("colorList: ", colorList);
              let primaryColor = settings[key].fill ?? "";
              //console.debug("primaryColor: ", primaryColor);
              let strokeColor = settings[key].stroke ?? "";
              //console.debug("strokeColor: ", strokeColor);
              let combileColor = [primaryColor, strokeColor, ...colorList]
                .filter((item) => item)
                .filter((item) => item !== "orange" && item !== "black");
              //console.debug("combileColor: ", combileColor);
              finishColor = priorityColor(combileColor, ["orange"]) || [];
              // if (key.includes("18")) {
              //   console.log('combileColor: ',key, combileColor, colorList);
              // }
            }

            //console.debug("finishColor Vertical colorList", key, colorList);
            // แสดงขอบ surface
            const lineKeys = getSurfaceBorderLine(
              organ,
              abbrSurfaceDict,
              organsWithRecordAll
            );

            lineKeys.forEach((key) => {
              settings[key] = {
                ...(finishColor?.length > 1 && {
                  stroke: finishColor[1],
                  strokeWidth: 2,
                  opacity: 1,
                }),
              };
            });

            settings[key] = {
              ...settings[key],
              fill: finishColor[0],
              ...(finishColor?.length <= 1 && {
                stroke: "black",
                strokeWidth: 1,
              }),
              ...(finishColor?.length > 1 && {
                stroke: finishColor[1],
                strokeWidth: 2,
              }),
              opacity: 1,
            };
            //console.debug("finishColor priorityColor key", key, settings[key]);
          }
        });
      }

      // console.log("settings:", settings);
      // Filter
      if (props.filterItem) {
        /// T42-2
        // console.log(" props.filterItem", props.filterItem)

        let q = props.filterItem.substring(1, 2);
        let i = props.filterItem.substring(2, 3);
        let section = props.filterItem.substring(4, 5);

        let surfaceName = toothDetails.find(
          (item) => item.q === Number(q) && item.i === Number(i)
        )?.surfaceName[Number(section)];
        if (surfaceName) {
          let abbr = getAbbrSurface(surfaceName);
          let key = "T" + q + i + abbr;
          settings[key] = {
            ...settings[key],
            fill: "green",
          };
        } else if (section === "c") {
          //super T36-css
          let superSection = props.filterItem.substring(5)?.toUpperCase();
          // let location = q + i + superSection
          let key = "T" + q + i + superSection;
          settings[key] = {
            ...settings[key],
            fill: "green",
          };
        }
      }
    }

    /*************************************************************************
     *
     *     FIND MISSING TEETH and IMPLANT TEETH
     *
     *
     *
     *************************************************************************/

    let missingTeethOrgan: any[] = props.missingteeth || [];
    //  let partialMissingTeethOrgan: any[] = [];
    let implantTeethOrgan: any[] = props.implantteeth || [];

    if (props.sub === "DiagnosisToday" || props.sub === "TreatmentToday") {
      // Current Visit
      // History Review (Visit Tab)
    } else {
      //  console.log("DiagnosisHistory case and TreatmentToday case")
      let missingTeeths: any[] = [];
      //  let partialMissingTeeths: any[] = [];
      let implantTeeths: any[] = [];
      const organItems = props.masterData?.organ?.items || [];
      for (const exam of props.clinicalFindings) {
        if (
          exam.clinicaltags &&
          exam.clinicaltags
            .split(",")
            .filter((o: any) => MissingToothClinical.includes(o.trim()))
            .length > 0
        ) {
          let teethLocations = exam.locations_name
            .split(",")
            .map((item: any) => item.trim());
          missingTeeths = missingTeeths.concat(
            getOnlyFullTeeth(teethLocations, organItems).map(
              (item: any) => item.nickName
            )
          );
        }

        //  if (exam.clinicaltags && exam.clinicaltags.split(",").filter((o: any) => PartialMissingToothClinical.includes(o.trim())).length > 0) {
        //    let teethLocations = exam.locations_name.split(",").map((item: any) => item.trim());
        //    partialMissingTeeths = partialMissingTeeths.concat(getOnlyFullTeeth(teethLocations, organItems).map((item) => item.nickName));
        //  }

        if (
          exam.clinicaltags &&
          exam.clinicaltags
            .split(",")
            .filter((o: any) => "Implant" === o.trim()).length > 0
        ) {
          let teethLocations = exam.locations_name
            .split(",")
            .map((item: any) => item.trim())
            .filter((item: any) => !item.startsWith("S"));
          implantTeeths = implantTeeths.concat(
            getOnlyFullTeeth(teethLocations, organItems).map(
              (item: any) => item.nickName
            )
          );
        }
      } // end for

      // // Unique
      missingTeeths = Array.from(new Set(missingTeeths));
      implantTeeths = Array.from(new Set(implantTeeths));
      //  partialMissingTeeths = Array.from(new Set(partialMissingTeeths));

      // DiagnosisToday Visit Tab
      if (props.sub === "DiagnosisHistory" && props.historyReviewSubTab === 0) {
        missingTeethOrgan = missingTeeths.map((item) => {
          return props.masterData?.organ?.items.find(
            (o: any) => o.nickName === item
          );
        });
      }

      //  partialMissingTeethOrgan = partialMissingTeeths.map((item) => {
      //    return props.masterData?.organ?.items.find((o:any) => o.nickName === item);
      //  });

      implantTeethOrgan = implantTeeths.map((item) => {
        return props.masterData?.organ?.items.find(
          (o: any) => o.nickName === item
        );
      });
    }

    /********************* Missing Teeth onClick on Cross ************************/
    // console.log("!!!!  props.missingteeth", props.missingteeth)
    for (const t of missingTeethOrgan || []) {
      // const key = "T" + `${t.q}${t.i}`;
      // console.log("props.missingteeth t: ", t)
      let key = "T";
      if (t.nickName.startsWith("S")) {
        // Super teeth
        key = key + t.nickName.replace("/", "S");
        settings[key] = {
          ...settings[key],
          missing: "true",
          visibility: "hidden",
        };
        settings[key + "t"] = {
          ...settings[key + "t"],
          missing: "true",
          visibility: "hidden",
        };
      } else {
        // Full teeth
        key = key + `${t.q}${t.i}`;
        // Big circle
        settings[key + "C"] = {
          ...settings[key + "C"],
          missing: "true",
          visibility: "hidden",
        };

        let oMaster = props.masterData?.organ?.items
          .filter((item: any) => item.tissue === "tooth")
          .filter((item: any) => item.children?.length === 5)
          .find((item: any) => item.i === t.i && item.q === t.q);
        oMaster.children.forEach((item: any) => {
          let key = item.replace("tooth", "T");
          // console.log("orgainMaster key", key )
          settings[key] = {
            ...settings[key],
            missing: "true",
            visibility: "hidden",
          };
        });
      }
    }

    /********************* Implant Teeth  ************************/

    // if (!props.plate) {
    //   console.log(" props.implantteeth", props.implantteeth);
    // }
    // DiagnosisHistory, DiagnosisToday, TreatmentToday
    // console.log("props.sub", props.sub)

    // Do implant
    for (const t of implantTeethOrgan) {
      const key = "T" + `${t.q}${t.i}`;
      // console.log("settings", t, settings);
      let fragment = Object.keys(settings)
        .filter((item) => item.includes(key))
        .filter((item) => item != key);
      let isHasGreen = 0;
      // console.log("implant fragment", fragment);
      for (const frag of fragment) {
        if (settings[frag].fill === "green") {
          isHasGreen++;
        } else {
          isHasGreen--;
        }
      }
      // console.log("implant isHasGreen", isHasGreen);
      if (isHasGreen === 5) {
        settings[key] = {
          ...settings[key],
          implant: true,
        };
      }
    }
  }

  /********************* Missing Teeth onClick on Teeth  ************************/
  spot.forEach((item) => {
    const key = "T" + `${item.location}`;
    // if (chooseMissingTeeth) {
    //   // settings[key] = {
    //   //   ...settings[key],
    //   //   onClick: handleMissing,
    //   // };
    // } else {
    // Only 5 part of tooth not cover whole circle
    settings[key] = {
      ...settings[key],
      // onClick : () => { console.log( "Just Click at any tooth", key)},
      onMouseOver: (e) => {
        handleMouseOver(e, key);
      },
      onMouseLeave: handleMouseLeaveHover,
    };
    // }
  });

  // console.log("DentalRecord1.tsx settings: ", settings)

  const handleFinishDragMouse = () => {
    // console.log("handleFinishDragMouse ")
    if (props.selectorMode && selecting) {
      let items = getSelectedItems(
        selecting,
        selectionBoxRef,
        startPoint,
        endPoint,
        props.hideBabyTooth,
        superteethPatient,
        props.svgZoom
      );
      // console.log("handleFinishDragMouse  items ", items)
      if (items.length > 0) {
        props.onEvent({ message: "AddLocation", params: { items: items } });
      } else if (selectedItems.length > 0) {
        // Maby remove
        // console.log("selectedITems !!! ", selectedItems)
        props.onEvent({
          message: "ToggleLocation",
          params: { items: selectedItems },
        });
      }
    }

    setSelectedItems([]);
    setSelecting(false);
    setStartPoint(null);
    setEndPoint(null);
  };

  // let isPrimaryTeeth = false
  // let isPermanentTeeth = false
  let isNeedToggle = false; // To show relate image with
  if (
    props.sub === "DiagnosisHistory" &&
    props.historyReviewSubTab === 1 &&
    props.clinicalFindings &&
    props.clinicalFindingIndex &&
    props.clinicalFindings.length > props.clinicalFindingIndex
  ) {
    const image = props.clinicalFindings[props.clinicalFindingIndex]?.image;

    if (image) {
      let isPrimaryTeeth = "primary_teeth" in image; // ฟันแท้ + ฟันน้ำนม
      let isPermanentTeeth = "permanent_teeth" in image; // ฟันแท้

      isNeedToggle =
        (props.hideBabyTooth && isPrimaryTeeth && !isPermanentTeeth) ||
        (!props.hideBabyTooth && !isPrimaryTeeth && isPermanentTeeth);
    }
  }

  // console.log(" setting", settings)

  // if (props.plate) {
  //   console.log("props.plate props.sub", props.sub)
  //   console.log("props.plate props.historyReviewSubTab",  props.historyReviewSubTab)
  //   console.log("props.plate props.clinicalFindings",  props.clinicalFindings)
  //   console.log("props.plate  props.showDrawingChecked",   props.showDrawingChecked)

  // }
  // console.log("DentalDiagram", props);

  return (
    <div
      className={chooseSupernumerary ? "choosesuper" : "normal"}
      ref={selectionBoxRef}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      onMouseUp={handleMouseUp}
      // issue 52820
      style={{
        ...{ cursor: props.selectorMode ? "cell" : "default" },
        display: "flex",
        // paddingTop: "20px",
        flexDirection: "column",
        alignItems: "left",
        position: "relative",
      }}
    >
      {/* alway show if in VisitTab under History review */}
      {selecting && <div style={selectingBoxStyle || {}} />}
      {/* @ts-ignore */}
      <Form style={{ width: "fit-content" }}>
        <div className="tooth-area" style={{ width: "fit-content" }}>
          {props.sub === "DiagnosisHistory" && props.historyReviewSubTab === 0
            ? props.clinicalFindings.map((data, index) => {
                // if (props.plate && data.image) {
                //   console.log("Should see me data: ", data)
                // }

                return (
                  (props.hideBabyTooth
                    ? data.image?.["permanent_teeth"]
                    : data.image?.["primary_teeth"]) && (
                    <img
                      className="svg-zoom-out image-drawing"
                      key={index}
                      src={
                        props.hideBabyTooth
                          ? data.image?.["permanent_teeth"]
                          : data.image?.["primary_teeth"]
                      }
                      alt={`tooth-${index}`}
                      width="1200"
                      height={props.hideBabyTooth ? "200" : "340"}
                      style={{
                        position: "absolute",
                        pointerEvents: "none",
                        top: 0,
                        left: 0,
                      }}
                    />
                  )
                );
              })
            : props.sub === "DiagnosisHistory" &&
              props.historyReviewSubTab === 1 &&
              isNeedToggle
            ? props.clinicalFindings
                .filter((item) =>
                  props.filterClinicalFindingIds.includes(item.id)
                )
                .map((data, index) => {
                  // console.log(data, props.showDrawingChecked.includes(data.id))
                  return (
                    props.showDrawingChecked.includes(data.id) &&
                    (props.hideBabyTooth
                      ? data.image?.["permanent_teeth"]
                      : data.image?.["primary_teeth"]) && (
                      <img
                        className="svg-zoom-out image-drawing"
                        key={index}
                        src={
                          props.hideBabyTooth
                            ? data.image?.["permanent_teeth"]
                            : data.image?.["primary_teeth"]
                        }
                        alt={`tooth-${index}`}
                        width="1200"
                        height={props.hideBabyTooth ? "200" : "340"}
                        style={{
                          position: "absolute",
                          pointerEvents: "none",
                          top: 0,
                          left: 0,
                        }}
                      />
                    )
                  );
                })
            : props.sub === "TreatmentHistory"
            ? props.clinicalFindings
                .filter((item: any) =>
                  props.filterClinicalFindingIds.includes(item.id)
                )
                .map((data: any, index: number) => {
                  return (
                    (props.hideBabyTooth
                      ? data.image?.["permanent_teeth"]
                      : data.image?.["primary_teeth"]) && (
                      <img
                        className="svg-zoom-out image-drawing"
                        key={index}
                        src={
                          props.hideBabyTooth
                            ? data.image?.["permanent_teeth"]
                            : data.image?.["primary_teeth"]
                        }
                        alt={`tooth-${index}`}
                        width="1200"
                        height={props.hideBabyTooth ? "200" : "340"}
                        style={{
                          position: "absolute",
                          pointerEvents: "none",
                          top: 0,
                          left: 0,
                        }}
                      />
                    )
                  );
                })
            : props.clinicalFindings
                .filter((item) =>
                  props.filterClinicalFindingIds.includes(item.id)
                )
                .map((data, index) => {
                  // console.log(data, props.showDrawingChecked.includes(data.id))
                  return (
                    props.showDrawingChecked.includes(data.id) &&
                    (props.hideBabyTooth
                      ? data.image?.["permanent_teeth"]
                      : data.image?.["primary_teeth"]) && (
                      <img
                        className="svg-zoom-out image-drawing"
                        key={index}
                        src={
                          props.hideBabyTooth
                            ? data.image?.["permanent_teeth"]
                            : data.image?.["primary_teeth"]
                        }
                        alt={`tooth-${index}`}
                        width="1200"
                        height={props.hideBabyTooth ? "200" : "340"}
                        style={{
                          position: "absolute",
                          pointerEvents: "none",
                          top: 0,
                          left: 0,
                        }}
                      />
                    )
                  );
                })}
          {props.sub === "DiagnosisHistory" &&
          props.historyReviewSubTab === 1 &&
          isNeedToggle ? (
            !props.hideBabyTooth ? (
              <div className="svg-zoom-out" style={{ zoom: props.svgZoom }}>
                <DentalRecordNoBaby1UX
                  {...props}
                  settings={settings}
                  startPoint={startPoint}
                  endPoint={endPoint}
                />{" "}
              </div>
            ) : (
              <div className="svg-zoom-out" style={{ zoom: props.svgZoom }}>
                <DentalRecord1UX
                  {...props}
                  settings={settings}
                  startPoint={startPoint}
                  endPoint={endPoint}
                />{" "}
              </div>
            )
          ) : props.hideBabyTooth ? (
            <div className="svg-zoom-out" style={{ zoom: props.svgZoom }}>
              <DentalRecordNoBaby1UX
                {...props}
                settings={settings}
                startPoint={startPoint}
                endPoint={endPoint}
              />{" "}
            </div>
          ) : (
            <div className="svg-zoom-out" style={{ zoom: props.svgZoom }}>
              <DentalRecord1UX
                {...props}
                settings={settings}
                startPoint={startPoint}
                endPoint={endPoint}
              />{" "}
            </div>
          )}
        </div>
      </Form>

      <div
        ref={tooltipRef}
        style={{
          position: "absolute",
          backgroundColor: "black",
          color: "white",
          maxWidth: "150px",
          left: "334px",
          top: "109px",
          borderRadius: "5px",
          // width: "100%",
          padding: "6px",
          display: "none",
        }}
      ></div>
    </div>
  );
};

export default React.memo(DentalDiagram);

const getSelectedItems = (
  selecting: any,
  selectionBoxRef: any,
  startPoint: any,
  endPoint: any,
  hideBabyTooth: boolean,
  superTeethList: any[] = [],
  svgZoom?: number
) => {
  // superTeethList in S domain (TS37S38) easily to map
  let items: any[] = [];
  let spot: any[] = [];
  const parentNode = selectionBoxRef?.current || null;
  const svgZoomOut = selectionBoxRef.current?.querySelector(
    ".tooth-area .svg-zoom-out"
  ) as HTMLFormElement;
  let zoom = 0.65;

  if (!!svgZoom) {
    zoom = svgZoom;
  } else if (svgZoom) {
    let compStyles = window.getComputedStyle(svgZoomOut);
    zoom = Number.parseFloat(compStyles.getPropertyValue("zoom"));
  }

  if (startPoint && endPoint) {
    const scrollTop = document.querySelector("#main-dpo")?.scrollTop || 0;
    const { x, y } = selectionBoxRef.current.getBoundingClientRect();

    const startPointX = startPoint.x;
    const startPointY = startPoint.y + scrollTop;
    const endPointX = endPoint.x;
    const endPointY = endPoint.y + scrollTop;

    if (hideBabyTooth) {
      // console.log(" Use No Baby Tooth ")
      spot = HotSpotNoBaby;
    } else {
      // console.log(" Use With Baby Tooth ")
      spot = HotSpot;
    }

    if (superTeethList.length > 0) {
      // console.log(" superTeethList", superTeethList);
      let subSuperTeeth = superTeethList.map((item) => item.substring(1));
      // spot with normal tooth + superteeth
      spot = spot.filter(
        (item) =>
          !item.location.includes("S") || subSuperTeeth.includes(item.location)
      );
    } else {
      // sport with normal tooth
      spot = spot.filter((item) => !item.location.includes("S"));
    }

    // 20 is Padding 20px
    // issue 52820
    items = spot.filter(
      (item) =>
        item.x * zoom > Math.min(startPointX, endPointX) &&
        item.x * zoom < Math.max(endPointX, startPointX) &&
        item.y * zoom > Math.min(startPointY, endPointY) &&
        item.y * zoom < Math.max(endPointY, startPointY)
    );
    // console.log(items) //[{i: 6, q: 3, x: 957, y: 297, location: "S35S36"}]

    items = items.map((item) => {
      return {
        i: item.i,
        q: item.q,
        location: superTeethSOrganToSHuman(item.location),
      };
    });
  }

  return items;
};

export const getLocationName = (item: any) => {
  const location =
    `${item.toothIdQ}${item.toothIdI}` +
    `${
      typeof item.toothIdSection === "number"
        ? " (" +
          getSurfaceName(item.toothIdQ, item.toothIdI, item.toothIdSection) +
          ")"
        : ""
    }`;
  return location;
};
