import BaseService from "./BaseService";
import { TRT } from "../../configs/apis";
class TRTService extends BaseService {
  /**
   * get treatment order
   * @param {object} data
   */
  getTreatmentOrder(params) {
    return this.client
      .get(TRT.TREATMENT_ORDER, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getTreatmentOrderPerformer({ params } = {}) {
    return this.client
      .get(TRT.TREATMENT_ORDER_PERFORMER, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getTreatment({ params } = {}) {
    return this.client
      .get(TRT.TREATMENT, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  postTreatmentOrderEstimate({ params, data } = {}) {
    return this.client
      .post(TRT.TREATMENT_ORDER_ESTIMATE, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  postTreatmentOrder({ params, data } = {}) {
    return this.client
      .post(TRT.TREATMENT_ORDER, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  putTreatmentOrderEncounterArrive(encounterId, params) {
    const url = TRT.TREATMENT_ORDER_ENCOUNTER_ARRIVE({
      encounter_id: encounterId
    });
    return this.client
      .put(url, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
}
export default TRTService;
