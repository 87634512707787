import BaseService from "./BaseService";
import { IME } from "../../configs/apis";
class IMEService extends BaseService {
  /**
   * get patient data by hn
   * @param {encounter_id} int
   */
  getImagingExamResult(params) {
    const url = IME.IMAGING_EXAM_RESULT(params);
    return this.client
      .get(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
}
export default IMEService;
