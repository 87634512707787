import WasmController from "react-lib/frameworks/WasmController";
import SupplyReturnRequestList from "issara-sdk/apis/SupplyReturnRequestList_apps_MSD";

export type State = {
  MedRequestReturnSequence?: {
    sequenceIndex?: any;
    selectedDivisionReturn?: any | null;
    selectedDivisionReceive?: any | null;
    selectedStartDate?: string | null;
    selectedEndDate?: string | null;
    selectedStatus?: string | null;
    checkedStatus?: boolean | null;
    checkedDivisionReturn?: boolean | null;
    checkedDivisionReceive?: boolean | null;
    checkedFilterDate?: boolean | null;
    MedRequestReturnList?: any;
  } | null;
  successMessage?: any;
  errorMessage?: any;
};

export const StateInitial: State = {
  MedRequestReturnSequence: {},
  successMessage: null,
  errorMessage: null,
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  division?: number;
};

const LIMIT = 40;

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const SearchAndSelect: Handler = async (controller, params) => {
  var state = controller.getState();

  // Master data
  await controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [["division", {}]],
    },
  });

  controller.setState({
    MedRequestReturnSequence: {
      ...state.MedRequestReturnSequence,
    },
  });

  if (params?.action === "search") {
    const [response, error, network] = await SupplyReturnRequestList.list({
      params: {
        status: state?.MedRequestReturnSequence?.checkedStatus
          ? state?.MedRequestReturnSequence?.selectedStatus
          : null,
        from_date: state?.MedRequestReturnSequence?.checkedFilterDate
          ? state?.MedRequestReturnSequence?.selectedStartDate
          : null,
        to_date: state?.MedRequestReturnSequence?.checkedFilterDate
          ? state?.MedRequestReturnSequence?.selectedEndDate
          : null,
        requester: state?.MedRequestReturnSequence?.checkedDivisionReturn
          ? state?.MedRequestReturnSequence?.selectedDivisionReturn
          : null,
        receiver: state?.MedRequestReturnSequence?.checkedDivisionReceive
          ? state?.MedRequestReturnSequence?.selectedDivisionReceive
          : null,
        offset: 0,
        limit: LIMIT,
      },
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });
    controller.setState({
      MedRequestReturnSequence: {
        ...state.MedRequestReturnSequence,
        MedRequestReturnList: response?.items || [],
      },
    });
  } else if (params?.action === "clear") {
    controller.setState({
      MedRequestReturnSequence: {
        ...state.MedRequestReturnSequence,
        checkedDivisionReceive: false,
        checkedDivisionReturn: false,
        checkedFilterDate: false,
        checkedStatus: false,
        selectedDivisionReceive: "แสดงทุกหน่วยงาน",
        selectedDivisionReturn: "แสดงทุกหน่วยงาน",
        selectedEndDate: null,
        selectedStartDate: null,
        selectedStatus: "แสดงทุกสถานะ",
      },
    });
  }
};
