import React, { useRef, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Button, Form, Icon, Modal, Grid } from "semantic-ui-react";
import ChatList from "react-lib/apps/IsHealth/Chat/ChatList";
import ChatBox from "react-lib/apps/IsHealth/Chat/ChatBox";
import CardClassify from "../Nurse/CardClassify";
import CardSendDoctorQueue from "../Nurse/CardSendDoctorQueue";
import CardPatientPanel from "../Nurse/CardPatientPanel";
import CardBILPatientPanel from "../BIL/CardBILPatientPanel";
import CardDiagFormHistory from "../Nurse/CardDiagFormHistory";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import ModConfirm from "../../common/ModConfirm";
import config from "../../../../config/config";
import { useInterval } from "../../../utils/hooksUtils";
import { useIntl } from "react-intl";

const ChatTable = React.forwardRef((props, ref) => {
  const history = useHistory();
  const isMounted = useRef(true);
  const [openClassify, setOpenClassify] = useState(false);
  const [openDoctorQueue, setOpenDoctorQueue] = useState(false);
  const [openModDiagHis, setOpenModDiagHis] = useState(false);
  const [encounterData, setEncounterData] = useState({});
  const [patientData, setPatientData] = useState({});
  const [patientLoading, setPatientLoading] = React.useState(false);
  const chatListRef = React.useRef();
  const chatBoxRef = React.useRef();
  const patientPanelRef = React.useRef();
  const [readOnly, setReadOnly] = React.useState(false);
  const [openModAppoint, setOpenModAppoint] = React.useState(false);
  const [modAppointLoading, setModAppointLoading] = React.useState(false);
  const [modAppointError, setModAppointError] = React.useState(null);
  const [patientAppointmentId, setPatientAppointmentId] = React.useState(null);
  const [allClassify, setAllClassify] = React.useState([]);
  const [triageLevelList, setTriageLevelList] = React.useState([]);
  const [hasWSReclassify, setHasWSReclassify] = React.useState(false);

  React.useImperativeHandle(ref, () => ({
    chatListRef: chatListRef.current,
    chatBoxRef: chatBoxRef.current,
    patientPanelRef: patientPanelRef.current,
    getPatientInfo: () => {
      if (!config.PATIENT_PANEL_INVOICE) {
        setHasWSReclassify(true);
      } else {
        getPatientDetail();
      }
    },
  }));

  const openModalClassify = () => {
    setOpenClassify(true);
  };

  const closeModalClassify = () => {
    setOpenClassify(false);
  };

  React.useEffect(() => {
    handleGetClassify();
  }, [props.finishedTriageLevel, allClassify]);

  useInterval(() => {
    if (hasWSReclassify) {
      getPatientDetail();
    }
  }, 10000);

  const handleGetClassify = async () => {
    if (!allClassify) {
      return;
    }
    let newArr = allClassify.filter((item) => {
      if (
        props.finishedTriageLevel &&
        !props.finishedTriageLevel.includes(item.triage_level)
      ) {
        return item;
      }
    });
    let options = newArr.map(
      ({ triage_level, description, active, codeColor }) => ({
        key: triage_level,
        value: triage_level,
        triage_level: triage_level,
        code_color: codeColor,
        text: description,
        active: active,
      })
    );
    let appTriageList: any[] = [];
    if (
      (config.BOOKING_FUNCTION && !config.PATIENT_PANEL_INVOICE) ||
      config.SHOW_APP_TRIAGE_FILTER_CHAT
    ) {
      appTriageList = [
        { key: "app", text: "มีนัดหมาย", value: "app" },
        { key: "noApp", text: "ไม่มีนัดหมาย", value: "noApp" },
      ];
    }
    setTriageLevelList([
      { key: "default", text: "DEFAULT", value: "default" },
      ...appTriageList,
      ...options,
    ]);
  };

  React.useEffect(() => {
    props.onGetDivision();
    return () => (isMounted.current = false);
  }, []);

  React.useEffect(() => {
    const getClassifyUser = async () => {
      const [response, error, network] = await props.controller.getClassifyUser(
        {
          apiToken: Cookies.get("apiToken") ? Cookies.get("apiToken") : props.apiToken,
          divisionId: props.division,
        }
      );
      if (!isMounted.current) {
        return;
      }
      if (response) {
        setAllClassify(response.classify);
      } else {
        setAllClassify([]);
        // setTriageLevelList([]);
      }
    };
    if (props.division) {
      getClassifyUser();
    }
  }, [props.division]);

  React.useEffect(() => {
    const getEncounterDetail = async () => {
      setPatientLoading(true);
      const [data, error] = await props.controller.getEncounterDetail({
        encounterId: props.match.params.encounterId,
        apiToken: Cookies.get("apiToken") ? Cookies.get("apiToken") : props.apiToken,
      });

      if (isMounted.current) {
        setPatientLoading(false);
        if (error) {
          console.log(error);
        } else {
          setEncounterData(data);
        }
      }
    };

    if (props.match.params.encounterId) {
      getEncounterDetail();
    }
  }, [props.match.params.encounterId]);

  React.useEffect(() => {
    setPatientLoading(true);

    if (encounterData.patient) {
      getPatientDetail();
    }
  }, [encounterData.patient]);

  const getPatientDetail = async () => {
    setHasWSReclassify(false);
    if (!encounterData.patient) {
      return;
    }
    const [data, error] = await props.controller.getProxyPatient({
      patientId: encounterData.patient,
      apiToken: Cookies.get("apiToken") ? Cookies.get("apiToken") : props.apiToken,
    });

    if (isMounted.current) {
      setPatientLoading(false);
      if (error) {
        console.log(error);
        setPatientData({});
      } else {
        setPatientData(data);
      }
    }
  };

  const CardPatientPanelChat = useMemo(() => {
    return !config.PATIENT_PANEL_INVOICE
      ? CardPatientPanel
      : CardBILPatientPanel;
  }, [config.PATIENT_PANEL_INVOICE, config.CONFIG_UPDATE_SUCCESS]);

  const handleModDoctorQueue = () => {
    setOpenDoctorQueue(!openDoctorQueue);
  };

  const handleModDiagHis = () => {
    setOpenModDiagHis(!openModDiagHis);
  };

  const handleEnNoDiv = () => {
    setReadOnly(true);
  };

  const handleEnHasDiv = () => {
    setReadOnly(false);
  };

  React.useEffect(() => {
    if (!openModAppoint) {
      setModAppointError(null);
    }
  }, [openModAppoint]);

  const handleOpenAppointment = async () => {
    if (props.match && props.match.params && props.match.params.encounterId) {
      setModAppointError(null);
      setModAppointLoading(true);
      const [response, error] =
        await props.controller.postEncounterPatientAppointment({
          encounterId: props.match.params.encounterId,
          apiToken: Cookies.get("apiToken") ? Cookies.get("apiToken") : props.apiToken,
        });
      if (isMounted.current) {
        setModAppointLoading(false);
        chatBoxRef.current.getLastMessage();
        if (response) {
          setOpenModAppoint(false);
        } else {
          setModAppointError(error);
        }
      }
    }
  };

  const handleSetAppointmentId = ({ appointmentId } = {}) => {
    setPatientAppointmentId(appointmentId);
  };

  const handleCreateEditAppointment = () => {
    if (!patientAppointmentId) {
      setOpenModAppoint(true);
    } else {
      props.onEditAppointment({ appointmentId: patientAppointmentId });
    }
  };

  const handleRefetchChat = () => {
    if (chatBoxRef.current && chatBoxRef.current.getLastMessage) {
      chatBoxRef.current.getLastMessage();
    }
  };

  return (
    <Grid className="ChatTable noMargin" celled>
      {/* <Table.Body>
        <Table.Row> */}
      {!config.HIDE_QUEUE && (
        <Grid.Column width={4} style={{ padding: 0 }} className="chatList">
          <ChatList
            {...props}
            ref={chatListRef}
            controller={props.controller}
            userId={props.userId}
            apiToken={props.apiToken}
            division={props.division}
            finishedTriageLevel={props.finishedTriageLevel}
            onSetAppointmentId={handleSetAppointmentId}
            triageLevelList={triageLevelList}
            filterTriageAppByAppDate={props.filterTriageAppByAppDate}
          />
        </Grid.Column>
      )}
      <Grid.Column width={config.HIDE_QUEUE ? 12 : 8} style={{ padding: 0 }} className="chatBox">
        {props.match && props.match.params && props.match.params.chatChannelId ? (
          <>
            <Form className="chatbox-header">
              <Form.Group>
                {props.allowCreateAppoint && (
                  <Form.Field>
                    <Button
                      className={patientAppointmentId ? "edit-appointment" : "create-appointment"}
                      onClick={handleCreateEditAppointment}
                      // onClick={() => setOpenModAppoint(true)}
                    >
                      <Icon name="calendar" />
                      {patientAppointmentId ? "แก้ไขนัดหมาย" : "สร้างนัดหมาย"}
                    </Button>
                  </Form.Field>
                )}
                {!config.HIDE_ASSESSMENT && (
                  <Form.Field>
                    <Button className="inverted-btn" onClick={handleModDiagHis}>
                      <Icon name="briefcase" />
                      แบบคัดกรอง
                    </Button>
                  </Form.Field>
                )}
                {!config.HIDE_CLASSIFY && (
                  <Form.Field>
                    <Button onClick={openModalClassify} disabled={readOnly}>
                      reclassify
                    </Button>
                  </Form.Field>
                )}
              </Form.Group>
            </Form>
            <ChatBox
              {...props}
              ref={chatBoxRef}
              nullId={props.nullId}
              controller={props.controller}
              userId={props.userId}
              apiToken={props.apiToken}
              fullname={props.fullname}
              username={props.username}
              patientData={patientData}
              enHasNoDiv={handleEnNoDiv}
              enHasDiv={handleEnHasDiv}
              onNavigationMessage={props.onNavigationMessage}
              isPatient={props.isPatient}
              onOpenVideoCall={(url: string) => {
                props?.onOpenVideoCall(url, props.match.params.chatChannelId);
              }}
            />
          </>
        ) : null}
      </Grid.Column>
      <Grid.Column width={4} style={{ padding: 0 }} className="chatDetail">
        {props.match &&
        props.match.params &&
        props.match.params.encounterId &&
        config.CONFIG_UPDATE_SUCCESS !== false ? (
          <CardPatientPanelChat
            {...props}
            ref={patientPanelRef}
            controller={props.patientPanelController}
            nurseNoteController={props.nurseNoteController}
            progressNoteController={props.progressNoteController}
            userId={props.userId}
            division={props.division}
            apiToken={props.apiToken}
            patientData={patientData}
            encounterData={encounterData}
            loading={patientLoading}
            readOnly={readOnly}
            triageLevelList={triageLevelList}
            hideNote={config.HIDE_NOTE}
            hideClassify={config.HIDE_CLASSIFY}
            onRefetchChat={handleRefetchChat}
            languageUX={props.languageUX}
          />
        ) : null}
      </Grid.Column>
      {/* </Table.Row> */}
      {/* </Table.Body> */}

      <ModConfirm
        open={openModAppoint}
        alertText="ต้องการเปิดนัดหมายให้ผู้ป่วยใช่หรือไม่"
        onButtonLeftClick={() => setOpenModAppoint(false)}
        onButtonRightClick={handleOpenAppointment}
        loading={modAppointLoading}
        error={modAppointError}
      />

      <Modal open={openClassify} className="classify">
        <CardClassify
          controller={props.classifyController}
          hideCallback={closeModalClassify}
          userId={props.userId}
          division={props.division}
          apiToken={props.apiToken}
          patientData={patientData}
          encounterData={encounterData}
          // onSuccess={() => chatListRef.current.getChatList()}
          languageUX={props.languageUX}
        />
      </Modal>

      <Modal open={openDoctorQueue} size="tiny">
        <CardSendDoctorQueue
          // controller={props.classifyController}
          hideCallback={handleModDoctorQueue}
          // userId={props.userId}
          // division={props.division}
          // apiToken={props.apiToken}
          // patientData={patientData}
          languageUX={props.languageUX}
        />
      </Modal>

      <Modal open={openModDiagHis} closeOnDimmerClick onClose={handleModDiagHis} size="large">
        <CardDiagFormHistory
          controller={props.diagFormController}
          hideCallback={handleModDiagHis}
          patientData={patientData}
          // userId={props.userId}
          // division={props.division}
          // apiToken={props.apiToken}
          languageUX={props.languageUX}
        />
      </Modal>
    </Grid>
  );
});

ChatTable.defaultProps = {
  className: "",
  onGetDivision: () => {},
  // ChatList
  chatListFetch: {},
  getLatestChatChannel: () => {},
  onSelectChat: () => {},

  // ChatBox
  messageFetch: {},
  userId: null,
  chatChannelId: null,
  onSendMessage: () => {},
  messagePost: {},

  // ChatDetail
  chatUserFetch: {},
  fullname: null,
  username: null,

  // DiagFormHistory
  diagFormController: null,

  finishedTriageLevel: [],
  allowCreateAppoint: false,

  isPatient: true,
};

ChatTable.propTypes = {
  onGetDivision: PropTypes.func,
  className: PropTypes.string,
  // ChatList
  chatListFetch: PropTypes.object,
  getLatestChatChannel: PropTypes.func,
  onSelectChat: PropTypes.func,

  // ChatBox
  messageFetch: PropTypes.object,
  userId: PropTypes.string,
  chatChannelId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSendMessage: PropTypes.func,

  // ChatDetail
  chatUserFetch: PropTypes.object,
  fullname: PropTypes.string,
  username: PropTypes.string,

  // DiagFormHistroy
  diagFormController: PropTypes.object,
  finishedTriageLevel: PropTypes.array,
  allowCreateAppoint: PropTypes.bool,

  isPatient: PropTypes.bool,
};

export default React.memo(ChatTable);
