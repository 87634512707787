import React, { useState, useRef, useEffect } from "react";
import {
  Segment,
  Grid,
  Image,
  Label,
  Icon,
  List,
  Input,
  Divider,
} from "semantic-ui-react";
import "./App.css";
import "semantic-ui-css/semantic.min.css";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import MobileMenuBar from "react-lib/apps/IsHealth/Common/MobileMenuBar";
const DEFAULT_IMAGE =
  "https://react.semantic-ui.com/images/wireframe/image.png";

const OfficialAccountList = props => {
  const isMounted = useRef(true);
  const history = useHistory();
  const [childList, setChildList] = useState([]);
  const [divisionDetail, setDivisionDetail] = useState({});
  const [divisionProfile, setDivisionProfile] = useState({});
  const [searchValue, setSearchValue] = useState("")

  useEffect(() => {
    return () => (isMounted.current = false);
  }, []);

  useEffect(() => {
    if (props.match.params.id) {
      getDivisionChild({ parent: props.match.params.id });
      getDivisionDetail({ divisionId: props.match.params.id });
      getDivisionProfile({ divisionId: props.match.params.id });
    }
  }, [props.match.params.id]);

  const getDivisionDetail = async ({ divisionId } = {}) => {
    const [res, error] = await props.controller.getDivisionDetail({
      divisionId,
      apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken")
    });
    if (isMounted.current) {
      if (res) {
        setDivisionDetail(res);
      } else {
        setDivisionDetail({});
      }
    }
  };

  const getDivisionChild = async ({ parent } = {}) => {
    const [res, error] = await props.controller.getDivision({
      parent,
      apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken")
    });
    if (isMounted.current) {
      if (res) {
        setChildList(res.items);
      } else {
        setChildList([]);
      }
    }
  };

  const getDivisionProfile = async ({ divisionId } = {}) => {
    const [data, error] = await props.controller.loadDivisionProfile(
      props.apiToken ? props.apiToken : Cookies.get("apiToken"),
      divisionId ? divisionId : props.match.params.id
    );
    if (!isMounted.current) {
      return;
    }
    if (data) {
      var obj = JSON.parse(data.items[0].extra);
      setDivisionProfile(obj);
    }
  };

  const createDiagRule = () => {
    return childList.filter(item => item.name.toLowerCase().includes(searchValue.toLowerCase())).map((item, index) => (
      <List.Item
        key={index}
        className="chat-list-item"
        onClick={() => {
          history.push(`/oa/greeting/${item.id}`);
        }}
      >
        <Label size={"large"}>
          <p>{item.name}</p>
        </Label>
      </List.Item>
    ));
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchValue(value)
  }

  return (
    <div>
      <MobileMenuBar
        menuBarClassName="oa-list"
        hideLeftItem
        middleItem={<h2 className="menu-bar-name">{divisionDetail.name}</h2>}
      />
      <div className="official-account-list">
        <Segment basic>
          <p className="division-name">
            {divisionProfile.name ? divisionProfile.name : divisionDetail.name}
            <br />
            {divisionProfile.full_name}
          </p>
          <Grid>
            <Grid.Column width={6}>
              <Image
                src={
                  divisionProfile.image ? divisionProfile.image : DEFAULT_IMAGE
                }
                onError={i => (i.target.src = DEFAULT_IMAGE)}
              />
            </Grid.Column>
            <Grid.Column width={10}>
              <Label>
                <Icon name={"map marker alternate"} color={"red"} />
                {divisionProfile.address
                  ? divisionProfile.address
                  : "No Location"}
              </Label>
            </Grid.Column>
          </Grid>
        </Segment>

        <p className="sub-header">เลือกแผนก</p>

        <Input
          fluid
          icon="search"
          iconPosition="left"
          placeholder="Search..."
          onChange={handleSearchChange}
        />
        <Divider />
        <div>
          <List verticalAlign="middle" className="division-list">
            {createDiagRule()}
          </List>
        </div>
      </div>
    </div>
  );
};

OfficialAccountList.defaultProps = {
  controller: {}
};

export default OfficialAccountList;
