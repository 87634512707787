import * as I from "./SetDoctorFee";

const SequencePattern: { [name: string]: { [flavor: string] : { [func: string]: any }}} = {
  SetDoctorFee: { 
    default: {
      START: I.Start,
      EditDF: I.EditDF
    }    
  }
};

export default SequencePattern;
