import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  FormField,
  Input,
  Button,
  TextArea,
  Icon
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardSettingTRTOptionsUX = (props: any) => {
    return(
      <div
        style={{width: "100%"}}>
        <div
          style={{display: "flex", alignItems: "center", justifyContent: "space-between", padding: "15px 10px", backgroundColor: "#A5D9FF"}}>
          
          <div
            style={{fontSize: "1.15rem", fontWeight: "bold"}}>
            การตั้งค่าชุดทางเลือกการรักษา
          </div>
        </div>
        <div
          className="ui divider"
          style={{marginTop: 0}}>
          
        </div>
        <Form>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              style={{paddingLeft: "1rem"}}
              width={6}>
              <label
                style={{minWidth: "max-content"}}>
                ชื่อทางเลือกการรักษา
              </label>
              <Input
                className="small"
                fluid={true}
                name="name"
                onChange={props.onChangeFilter}
                value={props.filter?.name || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}
              width={8}>
              <label>
                แผนก
              </label>
              <div
                style={{width: "100%"}}>
                {props.FDivisionDropdownOptions}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <div>
                {props.ButtonSearch}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <Button
                color="green"
                onClick={props.onAddNew}
                size="small"
                style={{minWidth: "max-content"}}>
                เพิ่มรายการใหม่
              </Button>
            </FormField>
          </FormGroup>
        </Form>
        <div
          style={{padding: "0 1rem"}}>
          
          <Table
            data={props.trtOptionsList || []}
            getTrProps={props.onGetTrProps}
            headers="ชื่อทางเลือกการรักษา,แผนก,"
            keys="name,divisionName,_action"
            minRows={props.showDetail ? 3 : 12}
            showPagination={false}
            style={{height: props.showDetail ? "calc(-45rem + 100dvh)" :"calc(-18rem + 100dvh)"}}
            widths="^200,^150,150">
          </Table>
        </div>
        <div
          style={{margin: "0.5rem 1rem 0", display: "flex", justifyContent: "flex-end"}}>
          {props.Pagination}
        </div>
        <div
          style={{paddingLeft: "1rem", display: props.showDetail?"":"none"}}>
          
          <div
            className="ui divider"
            style={{margin: "0.75rem 1rem 0.5rem 0"}}>
            
          </div>
          <div
            style={{margin: "0.5rem 1rem 0.75rem", display: "flex", justifyContent: "flex-end", fontSize: "1.15rem"}}>
            
            <Icon
              link={true}
              name="close"
              onClick={props.onClickClose}>
            </Icon>
          </div>
          <Form>
            <FormGroup
              inline={true}
              style={{marginBottom: "0.75rem"}}>
              <FormField
                className="required"
                inline={true}
                style={{minWidth: "11rem", maxWidth: "11rem"}}>
                <label>
                  ชื่อทางเลือกการรักษา
                </label>
              </FormField>
              <FormField
                className={props.showRequiredField?.name ? "error" : ""}
                inline={true}
                style={{padding:0}}
                width={14}>
                <Input
                  name="name"
                  onChange={props.onChangeValue}
                  value={props.trtOptionDetail?.name || ""}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{marginBottom: "0.75rem"}}>
              <FormField
                inline={true}
                style={{minWidth: "11rem", maxWidth: "11rem"}}>
                <label>
                  แผนก
                </label>
              </FormField>
              <FormField
                inline={true}
                style={{padding:0}}
                width={14}>
                <div
                  style={{width: "100%"}}>
                  {props.DDivisionDropdownOptions}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{marginBottom: "0.75rem"}}>
              <FormField
                className="required"
                inline={true}
                style={{minWidth: "11rem", maxWidth: "11rem"}}>
                <label>
                  ค่าใช้จ่ายโดยประมาณ
                </label>
              </FormField>
              <FormField
                className={props.showRequiredField?.estimate_cost_text? "error" : ""}
                inline={true}
                style={{padding:0}}
                width={14}>
                <Input
                  name="estimate_cost_text"
                  onChange={props.onChangeValue}
                  value={props.trtOptionDetail?.estimate_cost_text|| ""}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{marginBottom: "0.75rem", alignItems: "flex-start"}}>
              <FormField
                className="required"
                inline={true}
                style={{minWidth: "11rem", maxWidth: "11rem"}}>
                <label>
                  ข้อดี
                </label>
              </FormField>
              <FormField
                className={props.showRequiredField?.benefit? "error" : ""}
                inline={true}
                style={{padding:0}}
                width={14}>
                <TextArea
                  name="benefit"
                  onChange={props.onChangeValue}
                  value={props.trtOptionDetail?.benefit || ""}>
                </TextArea>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{marginBottom: "0.75rem", alignItems: "flex-start"}}>
              <FormField
                className="required"
                inline={true}
                style={{minWidth: "11rem", maxWidth: "11rem"}}>
                <label>
                  ข้อเสีย
                </label>
              </FormField>
              <FormField
                className={props.showRequiredField?.drawback? "error" : ""}
                inline={true}
                style={{padding:0}}
                width={14}>
                <TextArea
                  name="drawback"
                  onChange={props.onChangeValue}
                  value={props.trtOptionDetail?.drawback || ""}>
                </TextArea>
              </FormField>
            </FormGroup>
          </Form>
        </div>
        <Form>
          <FormGroup
            inline={true}
            style={{marginTop: "1.5rem", display: props.showDetail?"":"none"}}>
            <FormField
              style={{flex:1}}>
            </FormField>
            <FormField>
              <div>
                {props.ButtonSave}
              </div>
            </FormField>
            <FormField>
              <Button
                color="red"
                onClick={props.onClickCancel}
                size="small">
                ยกเลิก
              </Button>
            </FormField>
          </FormGroup>
        </Form>
      </div>
    )
}


export default CardSettingTRTOptionsUX

export const screenPropsDefault = {"current":null,"price_claimable":0,"price_non_claimable":0,"price_total":0}

/* Date Time : Tue Apr 02 2024 12:16:28 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", justifyContent: \"space-between\", padding: \"15px 10px\", backgroundColor: \"#A5D9FF\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "การตั้งค่าชุดทางเลือกการรักษา"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.15rem\", fontWeight: \"bold\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: 0}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "Form",
      "parent": 0,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "FormGroup",
      "parent": 4,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"1rem\"}"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "label",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อทางเลือกการรักษา"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "label",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนก"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "Input",
      "parent": 6,
      "props": {
        "className": {
          "type": "value",
          "value": "small"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "name"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "value": {
          "type": "code",
          "value": "props.filter?.name || \"\""
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSearch"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "Button",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มรายการใหม่"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onAddNew"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 1rem\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 17,
      "name": "Table",
      "parent": 16,
      "props": {
        "data": {
          "type": "code",
          "value": "props.trtOptionsList || []"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.onGetTrProps"
        },
        "headers": {
          "type": "value",
          "value": "ชื่อทางเลือกการรักษา,แผนก,"
        },
        "keys": {
          "type": "value",
          "value": "name,divisionName,_action"
        },
        "minRows": {
          "type": "code",
          "value": "props.showDetail ? 3 : 12"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: props.showDetail ? \"calc(-45rem + 100dvh)\" :\"calc(-18rem + 100dvh)\"}"
        },
        "widths": {
          "type": "value",
          "value": "^200,^150,150"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.Pagination"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0.5rem 1rem 0\", display: \"flex\", justifyContent: \"flex-end\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"1rem\", display: props.showDetail?\"\":\"none\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "Form",
      "parent": 19,
      "props": {
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "FormGroup",
      "parent": 20,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.75rem\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "FormGroup",
      "parent": 20,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.75rem\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "FormGroup",
      "parent": 20,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.75rem\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "FormGroup",
      "parent": 20,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.75rem\", alignItems: \"flex-start\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "FormGroup",
      "parent": 20,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.75rem\", alignItems: \"flex-start\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "FormField",
      "parent": 21,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"11rem\", maxWidth: \"11rem\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "FormField",
      "parent": 21,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.name ? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding:0}"
        },
        "width": {
          "type": "code",
          "value": "14"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "FormField",
      "parent": 22,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"11rem\", maxWidth: \"11rem\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "FormField",
      "parent": 22,
      "props": {
        "className": {
          "type": "code",
          "value": ""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding:0}"
        },
        "width": {
          "type": "code",
          "value": "14"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "FormField",
      "parent": 23,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"11rem\", maxWidth: \"11rem\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "FormField",
      "parent": 23,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.estimate_cost_text? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding:0}"
        },
        "width": {
          "type": "code",
          "value": "14"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "FormField",
      "parent": 24,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"11rem\", maxWidth: \"11rem\"}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "FormField",
      "parent": 24,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.benefit? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding:0}"
        },
        "width": {
          "type": "code",
          "value": "14"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "FormField",
      "parent": 25,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"11rem\", maxWidth: \"11rem\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "FormField",
      "parent": 25,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.drawback? \"error\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding:0}"
        },
        "width": {
          "type": "code",
          "value": "14"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "label",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อทางเลือกการรักษา"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "label",
      "parent": 28,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนก"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "label",
      "parent": 30,
      "props": {
        "children": {
          "type": "value",
          "value": "ค่าใช้จ่ายโดยประมาณ"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "label",
      "parent": 32,
      "props": {
        "children": {
          "type": "value",
          "value": "ข้อดี"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "label",
      "parent": 34,
      "props": {
        "children": {
          "type": "value",
          "value": "ข้อเสีย"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "Input",
      "parent": 27,
      "props": {
        "name": {
          "type": "value",
          "value": "name"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.trtOptionDetail?.name || \"\""
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "Input",
      "parent": 31,
      "props": {
        "name": {
          "type": "value",
          "value": "estimate_cost_text"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.trtOptionDetail?.estimate_cost_text|| \"\""
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "TextArea",
      "parent": 35,
      "props": {
        "name": {
          "type": "value",
          "value": "drawback"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.trtOptionDetail?.drawback || \"\""
        }
      },
      "seq": 44,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "TextArea",
      "parent": 33,
      "props": {
        "name": {
          "type": "value",
          "value": "benefit"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.trtOptionDetail?.benefit || \"\""
        }
      },
      "seq": 45,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "FormGroup",
      "parent": 46,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"1.5rem\", display: props.showDetail?\"\":\"none\"}"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "FormField",
      "parent": 47,
      "props": {
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "FormField",
      "parent": 47,
      "props": {
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "FormField",
      "parent": 47,
      "props": {
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 49,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSave"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "Button",
      "parent": 50,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิก"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickCancel"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0.5rem 1rem 0.75rem\", display: \"flex\", justifyContent: \"flex-end\", fontSize: \"1.15rem\"}"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "Icon",
      "parent": 53,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "link": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "close"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickClose"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "div",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0.75rem 1rem 0.5rem 0\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "div",
      "parent": 29,
      "props": {
        "children": {
          "type": "code",
          "value": "props.DDivisionDropdownOptions"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "code",
          "value": "props.FDivisionDropdownOptions"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 57,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 95,
  "isMounted": false,
  "memo": false,
  "name": "CardSettingTRTOptionsUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "current": null,
    "price_claimable": 0,
    "price_non_claimable": 0,
    "price_total": 0
  },
  "width": 55
}

*********************************************************************************** */
