import React, { useCallback, useEffect, useMemo, useState } from "react";
import CardInvoicesSummaryUX from "./CardInvoicesSummaryUX";
import { Icon } from "semantic-ui-react";
import Card from "react-lib/apps/TEC/CardTECSession";
import { INVOICE_ITEM_STATUS } from "react-lib/apps/IsHealth/BIL/CardBILPatientPanel";
import { Column, RowInfo } from "react-table-6";
import { useIntl } from "react-intl";

const NoDataComponent = (props: any) => <> </>;

type CardInvoicesSummaryProps = {
  setProp: any;
  onEvent: any;
  runSequence: any;
  InvoicesSummarySequence: any;
};

const CardInvoicesSummary = ({ ...rest }: CardInvoicesSummaryProps) => {
  const intl = useIntl();
  const props = { ...rest };
  // console.log("CardInvoicesSummary props: ", props);

  const [summaryList, setSummaryList] = useState<any[]>([]);
  const [noPaidList, setNoPaidList] = useState<any[]>([]);
  const [addressText, setAddressText] = useState<string>("");
  const [addressComponent, setAddressComponent] = useState<JSX.Element>(<> </>);

  useEffect(() => {
    if (props.InvoicesSummarySequence?.invoiceLists) {
      let list: any[] = [...props.InvoicesSummarySequence?.invoiceLists];

      let npList = list.filter(
        (item: any) => item.status !== INVOICE_ITEM_STATUS.PAID
      );

      list.push({
        product: "รวม",
        price: list
          .reduce((a, b) => a + (parseFloat(b.price) || 0), 0)
          .toFixed(2),
      });
      setSummaryList(list);
      setNoPaidList(npList);
    }
  }, [props.InvoicesSummarySequence?.invoiceLists]);

  useEffect(() => {
    let addressActive = props.InvoicesSummarySequence?.addressLists?.filter(
      (item: any) => item.active
    );
    let add = addressActive?.[0];

    if (!add) {
      setAddressComponent(<> </>);
    }
    if (add?.self_pickup) {
      setAddressComponent(
        <div style={{ textAlign: "left" }}>{intl.formatMessage({ id: "รับยาที่โรงพยาบาล" })}</div>
      );
    }

    let name = add?.receiver_name ? `${add?.receiver_name} ` : "";
    let no = add?.no ? `${add?.no} ` : "";
    let district = add?.district_label ? `${add?.district_label} ` : "";
    let city = add?.city_label ? `${add?.city_label} ` : "";
    let province = add?.province_label ? `${add?.province_label} ` : "";
    let zipcode = add?.zipcode ? `${add?.zipcode} ` : "";
    let note = add?.note ? `${add?.note} ` : "";
    let tel = add?.receiver_tel ? `${add?.receiver_tel} ` : "";
    let textAddress = note + no + district + city + province + zipcode;

    let nameLabel = "ชื่อ-สกุล : ";
    let addressLabel = "ที่อยู่ : ";
    let telLabel = "เบอร์โทร : ";

    const addressText = textAddress
      ? `${nameLabel}${name} ${addressLabel}${textAddress}`
      : "" + tel
      ? `${telLabel}${tel}`
      : "";

    const addressComponent = (
      <>
        {textAddress ? (
          <table>
            <tr>
              <td>
                <span style={{ fontWeight: "bolder" }}> {nameLabel} </span>
              </td>
              <td>
                <span style={{ textIndent: "10px" }}> {name} </span> <br />
              </td>
            </tr>
            <tr>
              <td>
                <span style={{ fontWeight: "bolder" }}> {addressLabel} </span>
              </td>
              <td>
                <span style={{ textIndent: "10px" }}>{textAddress}</span>
              </td>
            </tr>

            {tel ? (
              <tr>
                <td>
                  <span style={{ fontWeight: "bolder" }}>{telLabel}</span>
                </td>
                <td>
                  <span style={{ textIndent: "10px" }}> {tel} </span>
                </td>
              </tr>
            ) : null}
          </table>
        ) : null}
      </>
    );

    setAddressText(addressText);
    setAddressComponent(addressComponent);
  }, [props.InvoicesSummarySequence?.addressLists]);

  console.log(" CardInvoicesSummary  props: ", props);
  useEffect(() => {
    props.runSequence({ sequence: "InvoicesSummary" });
  }, []);

  const handleCopyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(addressText);
  }, [addressText]);

  const handleGetTdProps = (
    state: any,
    rowInfo?: RowInfo,
    column?: Column,
    instance?: any
  ) => {
    return {
      style: {
        textAlign: column?.id === "price" ? "right" : "left",
      },
    };
  };

  return (
    <div style={{ margin: "10px 10px 30px 10px" }}>
      <CardInvoicesSummaryUX
        paidStatus={noPaidList?.length === 0 ? "PAID" : ""}
        colorPaidStatus="green"
        data={summaryList}
        copyBtnChild={
          <>
            <Icon name="copy" /> คัดลอกที่อยู่
          </>
        }
        // addressText={address}
        addressComponent={addressComponent}
        noDataComponent={NoDataComponent}
        onClickCopyToClipboard={handleCopyToClipboard}
        getTdProps={handleGetTdProps}
        languageUX={props.languageUX}
      />
    </div>
  );
};

export default CardInvoicesSummary;
