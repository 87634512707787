import TECService from "../services/TECService";
import { to, setStateAsync } from "../../utils";

import {TECSessionSerializer} from "../master/apps/TEC/TECSession"
import BaseService from  '../services/BaseService'
import config from '../../../config/config'
const HOST = `${config.API_HOST}`

export default class TECManager {
  apiToken?: string | null;

  constructor(apiToken: string | null = null) {
    this.apiToken = apiToken
  }

  putArriveTRTOrder = async ({ treatmentOrderId, data }: any={}) => {
    const tecService = new TECService(this.apiToken);
    const [error, response, network] = await to(
      tecService.putArriveTRTOrder(treatmentOrderId, data)
    );
    return [response, error, network];
  };
  getTECSession = async ({ encounterId }: any={}) => {
    const tecService = new TECService(this.apiToken);
    const [error, response, network] = await to(
      tecService.getTECSession(encounterId)
    );
    return [response, error, network];
  };
}

/* ['generics.ListCreateAPIView'] */
/* params: 'request_id', 'patient', 'encounter_type', 'created_date', 'gte_created_date', 'order_by', 'technician_assign', 'status', 'exclude_app_status', 'tec_type' */
/* data:  */
export const TECSessions : 
{
  list:     ({params, apiToken, extra}:
             { 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
  create:   ({params, data, apiToken, extra}:
             { 
                params?: any,
                data?: TECSessionSerializer,
                apiToken?: any,
                extra?: any
            }) => any,
} = 
{
  list: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
      base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    console.log( "Add no cached header")
    base.client.defaults.headers["Cache-Control"] = "no-cache"
    base.client.defaults.headers["Pragma"] = "no-cache"
    base.client.defaults.headers["Expires"] = "0"
    const result = await to(base.client.get(`${HOST}/apis/TEC/tec-sessions/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  create: async ({params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.post(`${HOST}/apis/TEC/tec-sessions/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
