import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  FormField,
  Checkbox,
  Dropdown
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardConsultRequestListUX = (props: any) => {
    return(
      <div
        style={{ width: "100%", height: "auto", padding: "15px 15px 0px 10px" }}>
        <Form>
          <FormGroup
            inline={true}
            style={{marginBottom: "0.25rem"}}>
            <FormField
              width={(!props.isCardQueue && props.isWard) ? 2 : 3}>
              <label>
                ค้นหาผู้ป่วย
              </label>
            </FormField>
            <FormField
              width={4}>
              <label>
                วันที่นัดส่งปรึกษา
              </label>
            </FormField>
            <FormField
              style={{ display: !props.isCardQueue && props.isWard ? "" : "none" }}
              width={(!props.isCardQueue && props.isWard) ? 2 : 3}>
              <label>
                แพทย์ผู้ส่งปรึกษา
              </label>
            </FormField>
            <FormField
              width={(!props.isCardQueue && props.isWard) ? 2 : 3}>
              <label>
                ถึงแผนก
              </label>
            </FormField>
            <FormField
              width={(!props.isCardQueue && props.isWard) ? 2 : 3}>
              <div
                style={{width:"100%"}}>
                
                <label
                  style={{fontWeight: "bold"}}>
                  แพทย์ผู้รับปรึกษา
                </label>
                <Checkbox
                  checked={props.filterParams?.noDoctor || false}
                  disabled={props.filterParams?.consulted_doctor}
                  label="ยังไม่ระบุ"
                  name="noDoctor"
                  onChange={(_event: any, data: any)=> props.onChangeValue(null, {value: data.checked, name: data.name})}
                  style={{marginLeft:"10px"}}>
                </Checkbox>
              </div>
            </FormField>
            <FormField
              width={(!props.isCardQueue && props.isWard) ? 2 : 3}>
              <label>
                สถานะการนัดหมาย
              </label>
            </FormField>
            <FormField
              style={{ display: !props.isCardQueue && props.isWard ? "" : "none" }}
              width={(!props.isCardQueue && props.isWard) ? 2 : ""}>
              <label>
                สถานะการ Consult
              </label>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              width={(!props.isCardQueue && props.isWard) ? 2 : 3}>
              <div
                style={{width: "100%"}}>
                {props.patientSearchBox}
              </div>
            </FormField>
            <FormField
              width={4}>
              <div
                style={{ width: "50%" }}>
                
                <DateTextBox
                  onChange={(date: string)=> props.onChangeValue(null, {value: date, name: "start_app_date"})}
                  value={props.filterParams?.start_app_date || ""}>
                </DateTextBox>
              </div>
              <label
                style={{marginRight: "0.75rem"}}>
                ถึง
              </label>
              <div
                style={{ width: "50%" }}>
                
                <DateTextBox
                  onChange={(date: string)=> props.onChangeValue(null, {value: date, name: "end_app_date"})}
                  value={props.filterParams?.end_app_date || ""}>
                </DateTextBox>
              </div>
            </FormField>
            <FormField
              style={{ display: !props.isCardQueue && props.isWard ? "" : "none" }}
              width={(!props.isCardQueue && props.isWard) ? 2 : 3}>
              <div
                style={{ width: "100%" }}>
                {props.doctorRequestSearchBox}
              </div>
            </FormField>
            <FormField
              width={(!props.isCardQueue && props.isWard) ? 2 : 3}>
              <div
                style={{ width: "100%" }}>
                {props.divisionSearchBox}
              </div>
            </FormField>
            <FormField
              width={(!props.isCardQueue && props.isWard) ? 2 : 3}>
              <div
                style={{ width: "100%" }}>
                {props.doctorAcceptSearchBox}
              </div>
            </FormField>
            <FormField
              width={(!props.isCardQueue && props.isWard) ? 2 : 3}>
              <Dropdown
                clearable={true}
                fluid={true}
                name="app_status"
                onChange={props.onChangeValue}
                options={props.appointmentStatusOptions || []}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.filterParams?.app_status}>
              </Dropdown>
            </FormField>
            <FormField
              style={{ display: !props.isCardQueue && props.isWard ? "" : "none" }}
              width={(!props.isCardQueue && props.isWard) ? 2 : 3}>
              <Dropdown
                clearable={true}
                fluid={true}
                name="status"
                onChange={props.onChangeValue}
                options={props.consultStatusOptions || []}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.filterParams?.status}>
              </Dropdown>
            </FormField>
            <FormField
              style={{ display: !props.isCardQueue && props.isWard ? "" : "none" }}
              width={(!props.isCardQueue && props.isWard) ? 2 : 0}>
              <div>
                {props.buttonSearch}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{ display: props.isWard ? "" : "none" }}>
            <FormField
              width={3}>
            </FormField>
            <FormField
              width={4}>
              <label>
                ประเภท Encounter
              </label>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{ display: props.isWard ? "none" : "" , marginBottom: "0.25rem"}}>
            <FormField
              width={3}>
              <label>
                สถานะการ Consult
              </label>
            </FormField>
            <FormField
              width={3}>
              <label>
                ประเภท Encounter
              </label>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{ display: props.isWard ? "" : "none" }}>
            <FormField
              width={3}>
              <Checkbox
                checked={props.filterParams?.current_encounter}
                label="เฉพาะ Encounter ปัจจุบัน"
                name="current_encounter"
                onChange={(_event: any, data: any)=> props.onChangeValue(null, {value: data.checked, name: data.name})}>
              </Checkbox>
            </FormField>
            <FormField
              width={3}>
              <Dropdown
                clearable={true}
                disabled={props.filterParams?.current_encounter}
                name="encounter_type"
                onChange={props.onChangeValue}
                options={props.encounterType || []}
                search={true }
                selection={true}
                value={props.filterParams?.encounter_type}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <div
                style={{justifyContent: "flex-end", display: "flex", width: "100%"}}>
                {props.buttonSearch}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{ display: props.isWard ? "none" : "" }}>
            <FormField
              inline={true}
              width={3}>
              <Dropdown
                clearable={true}
                fluid={true}
                name="status"
                onChange={props.onChangeValue}
                options={props.consultStatusOptions || []}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.filterParams?.status}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Dropdown
                clearable={true}
                fluid={true}
                name="encounter_type"
                onChange={props.onChangeValue}
                options={props.encounterType || []}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.filterParams?.encounter_type}>
              </Dropdown>
            </FormField>
            <div
              style={{flex:1}}>
              
            </div>
            <FormField
              inline={true}
              width={2}>
              <div
                style={{justifyContent: "flex-end", display: "flex", width: "100%"}}>
                {props.buttonSearch}
              </div>
            </FormField>
          </FormGroup>
        </Form>
        <Table
          data={props.tableData}
          defaultPageSize={10}
          headers={props.tableHeader}
          keys={props.tableKeys}
          widths="130, 130, 300, 300, 140, 180, 180,">
        </Table>
      </div>
    )
}


export default CardConsultRequestListUX

export const screenPropsDefault = {}

/* Date Time : Thu Feb 29 2024 10:09:51 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"auto\", padding: \"15px 15px 0px 10px\" }"
        }
      },
      "seq": 0
    },
    {
      "from": "semantic-ui-react",
      "id": 2,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "FormGroup",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.25rem\"}"
        },
        "widths": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "value",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "FormField",
      "parent": 3,
      "props": {
        "width": {
          "type": "code",
          "value": "(!props.isCardQueue && props.isWard) ? 2 : 3"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "label",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่นัดส่งปรึกษา"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "label",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึงแผนก"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: !props.isCardQueue && props.isWard ? \"\" : \"none\" }"
        },
        "width": {
          "type": "code",
          "value": "(!props.isCardQueue && props.isWard) ? 2 : 3"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "แพทย์ผู้ส่งปรึกษา"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "(!props.isCardQueue && props.isWard) ? 2 : 3"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "label",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะการนัดหมาย"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: !props.isCardQueue && props.isWard ? \"\" : \"none\" }"
        },
        "width": {
          "type": "code",
          "value": "(!props.isCardQueue && props.isWard) ? 2 : \"\""
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "label",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะการ Consult"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "label",
      "parent": 78,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภท Encounter"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "Checkbox",
      "parent": 75,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filterParams?.noDoctor || false"
        },
        "disabled": {
          "type": "code",
          "value": "props.filterParams?.consulted_doctor"
        },
        "label": {
          "type": "value",
          "value": "ยังไม่ระบุ"
        },
        "name": {
          "type": "value",
          "value": "noDoctor"
        },
        "onChange": {
          "type": "code",
          "value": "(_event: any, data: any)=> props.onChangeValue(null, {value: data.checked, name: data.name})"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\"}"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "FormGroup",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "widths": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "FormField",
      "parent": 19,
      "props": {
        "inline": {
          "type": "value",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "FormField",
      "parent": 19,
      "props": {
        "inline": {
          "type": "value",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "(!props.isCardQueue && props.isWard) ? 2 : 3"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "FormField",
      "parent": 19,
      "props": {
        "inline": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: !props.isCardQueue && props.isWard ? \"\" : \"none\" }"
        },
        "width": {
          "type": "code",
          "value": "(!props.isCardQueue && props.isWard) ? 2 : 3"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "FormField",
      "parent": 19,
      "props": {
        "inline": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "(!props.isCardQueue && props.isWard) ? 2 : 3"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "FormField",
      "parent": 19,
      "props": {
        "inline": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: !props.isCardQueue && props.isWard ? \"\" : \"none\" }"
        },
        "width": {
          "type": "code",
          "value": "(!props.isCardQueue && props.isWard) ? 2 : 3"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 34,
      "name": "DateTextBox",
      "parent": 62,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(date: string)=> props.onChangeValue(null, {value: date, name: \"start_app_date\"})"
        },
        "value": {
          "type": "code",
          "value": "props.filterParams?.start_app_date || \"\""
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "Dropdown",
      "parent": 28,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "app_status"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.appointmentStatusOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filterParams?.app_status"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "Dropdown",
      "parent": 30,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "status"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.consultStatusOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filterParams?.status"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "Dropdown",
      "parent": 81,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "encounter_type"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.encounterType || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filterParams?.encounter_type"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 43,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.tableData"
        },
        "defaultPageSize": {
          "type": "code",
          "value": "10"
        },
        "headers": {
          "type": "code",
          "value": "props.tableHeader"
        },
        "keys": {
          "type": "code",
          "value": "props.tableKeys"
        },
        "pageSize": {
          "type": "value",
          "value": ""
        },
        "widths": {
          "type": "value",
          "value": "130, 130, 300, 300, 140, 180, 180,"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "label",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.75rem\"}"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 45,
      "name": "DateTextBox",
      "parent": 63,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(date: string)=> props.onChangeValue(null, {value: date, name: \"end_app_date\"})"
        },
        "value": {
          "type": "code",
          "value": "props.filterParams?.end_app_date || \"\""
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 24,
      "props": {
        "children": {
          "type": "code",
          "value": "props.divisionSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "code",
          "value": "props.doctorRequestSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "FormGroup",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.isWard ? \"none\" : \"\" }"
        },
        "widths": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "FormField",
      "parent": 92,
      "props": {
        "inline": {
          "type": "value",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "FormField",
      "parent": 92,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "Checkbox",
      "parent": 49,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filterParams?.current_encounter"
        },
        "label": {
          "type": "value",
          "value": "เฉพาะ Encounter ปัจจุบัน"
        },
        "name": {
          "type": "value",
          "value": "current_encounter"
        },
        "onChange": {
          "type": "code",
          "value": "(_event: any, data: any)=> props.onChangeValue(null, {value: data.checked, name: data.name})"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "Dropdown",
      "parent": 51,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.filterParams?.current_encounter"
        },
        "fluid": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "encounter_type"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.encounterType || []"
        },
        "search": {
          "type": "code",
          "value": "true "
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.filterParams?.encounter_type"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "FormGroup",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.isWard ? \"none\" : \"\" , marginBottom: \"0.25rem\"}"
        },
        "widths": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "FormField",
      "parent": 91,
      "props": {
        "inline": {
          "type": "value",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "label",
      "parent": 55,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภท Encounter"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "FormField",
      "parent": 91,
      "props": {
        "inline": {
          "type": "value",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "value",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "(!props.isCardQueue && props.isWard) ? 2 : 3"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "label",
      "parent": 75,
      "props": {
        "children": {
          "type": "value",
          "value": "แพทย์ผู้รับปรึกษา"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "FormField",
      "parent": 19,
      "props": {
        "inline": {
          "type": "value",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "(!props.isCardQueue && props.isWard) ? 2 : 3"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "div",
      "parent": 60,
      "props": {
        "children": {
          "type": "code",
          "value": "props.doctorAcceptSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\" }"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\" }"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "FormField",
      "parent": 19,
      "props": {
        "inline": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: !props.isCardQueue && props.isWard ? \"\" : \"none\" }"
        },
        "width": {
          "type": "code",
          "value": "(!props.isCardQueue && props.isWard) ? 2 : 0"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "div",
      "parent": 66,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearch"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "FormField",
      "parent": 3,
      "props": {
        "width": {
          "type": "code",
          "value": "(!props.isCardQueue && props.isWard) ? 2 : 3"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "FormField",
      "parent": 19,
      "props": {
        "width": {
          "type": "code",
          "value": "(!props.isCardQueue && props.isWard) ? 2 : 3"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "label",
      "parent": 71,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหาผู้ป่วย"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "div",
      "parent": 72,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "div",
      "parent": 58,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 77,
      "name": "FormField",
      "parent": 54,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "FormField",
      "parent": 54,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 80,
      "name": "FormField",
      "parent": 48,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "value",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 81,
      "name": "FormField",
      "parent": 48,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "value",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "label",
      "parent": 77,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะการ Consult"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "div",
      "parent": 48,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 86,
      "name": "FormField",
      "parent": 48,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 86,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearch"
        },
        "style": {
          "type": "code",
          "value": "{justifyContent: \"flex-end\", display: \"flex\", width: \"100%\"}"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 89,
      "name": "Dropdown",
      "parent": 80,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "status"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.consultStatusOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filterParams?.status"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 91,
      "name": "FormGroup",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.isWard ? \"\" : \"none\" }"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 92,
      "name": "FormGroup",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.isWard ? \"\" : \"none\" }"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 93,
      "name": "FormField",
      "parent": 92,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "div",
      "parent": 93,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearch"
        },
        "style": {
          "type": "code",
          "value": "{justifyContent: \"flex-end\", display: \"flex\", width: \"100%\"}"
        }
      },
      "seq": 94,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardConsultRequestListUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
