import WasmController from 'react-lib/frameworks/WasmController';
import WardTypeList from "issara-sdk/apis/WardTypeList_apps_ADM";
import RoomTypeList from "issara-sdk/apis/RoomTypeList_apps_ADM";
import AdmitOrderProcessEstimate from "issara-sdk/apis/AdmitOrderProcessEstimate_apps_ADM";
import AdmitOrderDetail from "issara-sdk/apis/AdmitOrderDetail_apps_ADM";
import AdmitOrderList from "issara-sdk/apis/AdmitOrderList_apps_ADM";
import AdmitOrderDetailPrint from "issara-sdk/apis/AdmitOrderDetailPrint_apps_ADM";

export type State = 
  {
    encounterDetailDict?: any,
    hasAdmitOrder?: boolean,
  }

export const StateInitial: State = 
  {
  }

export type Event = 
  { message: "getWardType", params: any }
  |  { message: "getRoomType", params: any }
  |  { message: "getAdmitStatusChoice", params: any }
  |  { message: "getAdmitOrder", params: any }
  |  { message: "getAdmitOrderDetail", params: any }
  |  { message: "estimateAdmit", params: any }
  |  { message: "createAdmitOrder", params: any }
  |  { message: "updateAdmitOrder", params: any }
  |  { message: "printReservation", params: any }
  |  { message: "getAdmitDay", params: any }
  |  { message: "getAdmitOrderRoomItemStatus", params: any }
  |  { message: "putAdmitOrderRoomItem", params: any }
  |  { message: "admService", params: any }

export type Data = 
  {
    division?: number,
  }

export const DataInitial = 
  {
  }

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any 

export const getWardType: Handler = async (controller, { newborn }) => {
  let params: any = {};
  if (newborn) {
    params.newborn = newborn;
  }
  const result = await WardTypeList.list({
    params: params,
    apiToken: controller.apiToken,
  });
  return [result[0]?.items || {}, result[1]];
}

export const getRoomType: Handler = async (controller, {}) => {
  const result = await RoomTypeList.list({
    apiToken: controller.apiToken
  });
  return [result[0]?.items || {}, result[1]];
}

export const getAdmitStatusChoice: Handler = async (controller, {}) => {
//function () { return __awaiter(_this, void 0, void 0, function () {
//            var admService, _a, error, response;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        admService = new ADMService_1.default();
//                        return [4 /*yield*/, utils_1.to(admService.getAdmitOrderRoomItemStatus())];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1];
//                        return [2 /*return*/, [response, error]];
//                }
//            });
//        }); }
//
//----> Name mismatch between manager and service
}

export const getAdmitOrder: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, encounterId = _b.encounterId, active = _b.active;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, admitOrderData, admitOrderError, admService, _c, error, response;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            admitOrderData = {};
//                            admitOrderError = null;
//                            if (encounterId) {
//                                params.encounter = encounterId;
//                            }
//                            if (active) {
//                                params.active = active;
//                            }
//                            admService = new ADMService_1.default();
//                            return [4 /*yield*/, utils_1.to(this.admService.getAdmitOrder(params))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1];
//                            if (response) {
//                                admitOrderData = response.items;
//                            }
//                            else {
//                                admitOrderError = error;
//                            }
//                            return [2 /*return*/, [admitOrderData, admitOrderError]];
//                    }
//                });
//            });
//        }
//function (params) {
//        return this.client
//            .get(apis_1.ADM.ADMIT_ORDER, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ADM/admit-order/
}

export const getAdmitOrderDetail: Handler = async (controller, admitOrderId: number) => {
  return await AdmitOrderDetail.retrieve({
    pk: admitOrderId,
    apiToken: controller.apiToken
  });
}

export const estimateAdmit: Handler = async (controller, admitorderId: number) => {
  const result = await AdmitOrderProcessEstimate.get({
    pk: admitorderId,
    apiToken: controller.apiToken
  });
  return [result[0] || [], result[1]];
}

export const createAdmitOrder: Handler = async (controller, saveData) => {
  const result = await AdmitOrderList.create({
    data: saveData,
    extra: { division: controller.data.division },
    apiToken: controller.apiToken
  });
  console.log(result[1] ? result[1] : result[0]);
  if (result[0]) {
    // Add admit order info to encounterDetailDict if succeed
    let detailDict = controller.getState().encounterDetailDict;
    
    let items = detailDict?.admitorder?.[result[0]?.encounter];
    if (items && items.length > 0) {
      let replace = true;
      for (const index in items) {
        if (items[index] === result[0].id) {
          items[index] = result[0];
          replace = false;
        }
      }
      if (!replace)
        detailDict.admitorder[result[0].encounter].push(result[0])
    } else
      detailDict.admitorder[result[0].encounter] = [result[0]]

    items = detailDict?.admitorderfrom?.[result[0]?.encounter_opd];
    if (items && items.length > 0) {
      let replace = true;
      for (const index in items) {
        if (items[index] === result[0].id) {
          items[index] = result[0];
          replace = false;
        }
      }
      if (!replace)
        detailDict.admitorderfrom[result[0].encounter_opd].push(result[0])
    } else
      detailDict.admitorderfrom[result[0].encounter_opd] = [result[0]]
    console.log(detailDict);
    controller.setState({
      encounterDetailDict: detailDict,
      hasAdmitOrder: true,
    });
  }
  return [result[0] || [], result[1]]
}

export const updateAdmitOrder: Handler = async (controller, {admitOrderId, saveData}) => {
  const result = await AdmitOrderDetail.update({
    pk: admitOrderId,
    data: saveData,
    extra: { division: controller.data.division },
    apiToken: controller.apiToken
  });
  return [result[0] || [], result[1]];
}

export const printReservation: Handler = async (controller, admitOrderId) => {
  const result = await AdmitOrderDetailPrint.put({
    pk: admitOrderId,
    apiToken: controller.apiToken,
    extra:{
      division: controller.data.division
    }
  });
  return [result[0]?.data || [], result[1]];
}

export const getAdmitDay: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, encounterId = _b.encounterId, endDate = _b.endDate, cancelToken = _b.cancelToken;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, admService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            if (endDate) {
//                                params.end_date = endDate;
//                            }
//                            admService = new ADMService_1.default();
//                            return [4 /*yield*/, utils_1.to(admService.getAdmitDay(encounterId, params, cancelToken))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (encounterId, params, cancelToken) {
//        var url = apis_1.ADM.START_END_WARD({ encounter_id: encounterId });
//        return this.client
//            .get(url, { params: params, cancelToken: cancelToken })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/ADM/start-end-ward/encounter/' +
//((__t = (encounter_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const getAdmitOrderRoomItemStatus: Handler = async (controller, {}) => {
//function () { return __awaiter(_this, void 0, void 0, function () {
//            var params, admService, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        params = {};
//                        admService = new ADMService_1.default();
//                        return [4 /*yield*/, utils_1.to(admService.getAdmitOrderRoomItemStatus(params))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function () {
//        return this.client
//            .get(apis_1.ADM.ADMIT_ORDER_ROOM_ITEM_STATUS_CHOICE)
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ADM/admit-order-room-item/status-choice/
}

export const putAdmitOrderRoomItem: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, admitOrderRoomItemId = _b.admitOrderRoomItemId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, data, admService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            data = {
//                                action: "WARD"
//                            };
//                            admService = new ADMService_1.default();
//                            return [4 /*yield*/, utils_1.to(admService.putAdmitOrderRoomItem(admitOrderRoomItemId, params, data))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (admitOrderRoomItemId, params, data) {
//        var url = apis_1.ADM.ADMIT_ORDER_ROOM_ITEM_DETAIL({
//            admit_order_room_item_id: admitOrderRoomItemId
//        });
//        return this.client
//            .put(url, data, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/ADM/admit-order-room-item/' +
//((__t = (admit_order_room_item_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const admService: Handler = async (controller, {}) => {
//[object Object]
//
//----> Name mismatch between manager and service
}
