import React, { useEffect, useState } from "react";
import {
  Button,
  Dimmer,
  Divider,
  Dropdown,
  DropdownProps,
  Form,
  Header,
  Input,
  InputProps,
  Loader,
  Segment,
} from "semantic-ui-react";

// Common
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import ModInfo from "react-lib/apps/common/ModInfo";
import { useIntl } from "react-intl";

// Types
type DischargeFormProps = {
  // data
  loading: boolean;
  showCleaning: boolean;
  // CommonInterface
  errorMessage?: Record<string, any>;
  // options
  patientCondition?: any[];
  patientDischarge?: any[];
  cleaningChoice?: any[];
  // callback
  onSave: (data: Record<string, any>) => any;
  onComplete?: () => any;
};

const DischargeForm = (props: DischargeFormProps) => {
  const intl = useIntl();
  const [condition, setCondition] = useState<any>("");
  const [status, setStatus] = useState<any>("");
  const [specify, setSpecify] = useState<string>("");
  const [cleaning, setCleaning] = useState<any>("");
  const [modInfo, setModInfo] = useState<string[] | null>(null);

  // TODO: Check if need same as 68657
  // useEffect(() => {
  //   if (props.patientCondition && props.patientCondition?.length > 0) {
  //     setCondition(props.patientCondition[0]?.value);
  //   }
  // }, [props.patientCondition]);

  // useEffect(() => {
  //   if (props.patientDischarge && props.patientDischarge?.length > 0) {
  //     setStatus(props.patientDischarge[0]?.value);
  //   }
  // }, [props.patientDischarge]);

  const handleChangeCondition = (e: any, v: DropdownProps) => {
    setCondition(v.value);
  };

  const handleDischarge = (e: any, v: DropdownProps) => {
    setStatus(v.value);
  };

  const handleChangeSpecify = (e: any, v: InputProps) => {
    setSpecify(v.value);
  };

  const handleCleaning = (e: any, v: DropdownProps) => {
    setCleaning(v.value);
  };

  const handleSave = () => {
    props.onSave({
      predischarge_condition: condition,
      discharge_status: status,
      discharge_note: specify,
      cleaning: cleaning,
      onModInfo: setModInfo,
    });
  };

  const handleComplete = () => {
    setModInfo(null);
    props.onComplete?.();
  };

  return (
    // @ts-ignore
    <Segment>
      {/* @ts-ignore */}
      <Header>Discharge</Header>
      <Divider />
      {/* @ts-ignore */}
      <Form>
        <ErrorMessage error={props.errorMessage} />
        <Dimmer active={props.loading} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
        <Form.Group style={{ alignItems: "center" }}>
          <Form.Field width={4}>
            <label style={{ textAlign: "right", width: "100%" }}>
              สภาพผู้ป่วยก่อนจำหน่าย
            </label>
          </Form.Field>
          <Form.Field width={5}>
            <Dropdown
              selection={true}
              options={props.patientCondition}
              value={condition}
              onChange={handleChangeCondition}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group style={{ alignItems: "center" }}>
          <Form.Field width={4}>
            <label style={{ textAlign: "right", width: "100%" }}>
              Discharge Status
            </label>
          </Form.Field>
          <Form.Field width={5}>
            <Dropdown
              selection={true}
              options={props.patientDischarge}
              value={status}
              onChange={handleDischarge}
            />
          </Form.Field>
          <Form.Field width={1}>
            <label style={{ textAlign: "right", width: "100%" }}>{intl.formatMessage({ id: "ระบุ" })}</label>
          </Form.Field>
          <Form.Field width={6}>
            <Input value={specify} onChange={handleChangeSpecify} />
          </Form.Field>
        </Form.Group>

        {props.showCleaning && (
          <>
            <br />
            <Form.Group style={{ alignItems: "center" }}>
              <Form.Field width={4}>
                <label style={{ textAlign: "right", width: "100%" }}>
                  การทำความสะอาด
                </label>
              </Form.Field>
              <Form.Field width={5}>
                <Dropdown
                  selection={true}
                  options={props.cleaningChoice}
                  value={cleaning}
                  onChange={handleCleaning}
                />
              </Form.Field>
            </Form.Group>
          </>
        )}

        <Form.Group>
          <Form.Field width={14}></Form.Field>
          <Form.Field width={2}>
            <Button color={"green"} fluid onClick={handleSave}>
              บันทึก
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>

      <ModInfo
        open={!!modInfo}
        titleName="Discharge Complete"
        titleColor="blue"
        onApprove={handleComplete}
      >
        <div style={{ textAlign: "initial", lineHeight: 1.75 }}>
          {modInfo?.map((text: string, index) => (
            <div key={"item" + index}>{text}</div>
          ))}
        </div>
      </ModInfo>
    </Segment>
  );
};

export default React.memo(DischargeForm);
