import React, { useEffect, useRef, useState } from "react";
// UX
import CardDrivingCertificateUX from "./CardDrivingCertificateUX";
// Common

// Interface
import { State } from "./sequence/DoctorCertificate";
// Utils
import moment from "moment";
import { useIntl } from "react-intl";

// Main Props
type CardDrivingCertificateProps = {
  // Function
  setProp: any;

  // Options
  masterOptions?: any;

  // Sequence
  runSequence?: any;
  DoctorCertificateSequence: State["DoctorCertificateSequence"];
};

// Current Date
const currentDate = new Date().toISOString().split("T")[0];
const christYear = moment(currentDate).format("YYYY");
const buddhistYear = (parseInt(christYear) + 543).toString();
const formattedDate = moment(currentDate).format(
  "DD/MM/YYYY".replace("YYYY", buddhistYear)
);

// Main
const CardDrivingCertificate = (props: CardDrivingCertificateProps) => {
  const intl = useIntl();
  // log Props
  // console.log("CardDrivingCertificate Props: ", props.DoctorCertificateSequence);

  // Call Sequence ( Driving Certificate )
  useEffect(() => {
    props.runSequence({
      sequence: "DoctorCertificate",
      action: "FETCH_CERTIFICATE",
      fetchType: "CardDrivingCertificate",
    });
  }, []);

  // Change Date
  const handleChangeDate = (name: any, date: string) => {
    // console.log("Key: ", name + " Date: ", date);
    props.setProp(`DoctorCertificateSequence.drivingReport.${name}`, date);
  };

  // Checkbox Option
  const checkboxOption = (name: string, value: string) => {
    switch (name) {
      case "isFirstOption":
      case "isSecondOption":
      case "isThirdOption":
      case "isFourthOption":
      case "isFifthOption":
      case "summaryCheck":
        return (
          props.DoctorCertificateSequence?.drivingReport?.[name] === `${value}`
        );
      default:
        console.log("Doesn't Exist!!");
        break;
    }
  };

  // Change Checkbox
  const handleChangeCheckbox = (name: string, value: string) => {
    // value Option => "no", "yes"
    // summary Option => "normal", "abnormal"
    props.setProp(`DoctorCertificateSequence.drivingReport.${name}`, value);

    console.log(
      "Drv Checkbox First Status: ",
      props.DoctorCertificateSequence?.drivingReport?.isFirstOption
    );
    console.log(
      "Drv Checkbox Second Status: ",
      props.DoctorCertificateSequence?.drivingReport?.isSecondOption
    );
    console.log(
      "Drv Checkbox Summary Status: ",
      props.DoctorCertificateSequence?.drivingReport?.summaryCheck
    );
  };

  // Select Checkbox
  const handleSelectedCheckbox = (e: any, name: string, data: any) => {
    console.log("Drv Checkbox Select: ", name + " Value: ", data);

    handleChangeCheckbox(name, data);
  };

  // Change Text
  const handleChangeText = (
    name: any,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    switch (name) {
      case "explainFirst":
      case "explainSecond":
      case "explainThird":
      case "explainFourth":
      case "explainFifth":
      case "explainSummary":
      case "comment":
        props.setProp(
          `DoctorCertificateSequence.drivingReport.${name}`,
          e.target.value
        );
        break;
      default:
        console.log("Doesn't Exist!!");
        break;
    }
  };

  // Enable/Disable Input
  const handleInputDisable = (name: any) => {
    const drivingReport = props.DoctorCertificateSequence?.drivingReport;

    switch (name) {
      case "isFirstOption":
        return handleOptionDisable(
          drivingReport?.isFirstOption,
          drivingReport?.explainFirst,
          "explainFirst"
        );
      case "isSecondOption":
        return handleOptionDisable(
          drivingReport?.isSecondOption,
          drivingReport?.explainSecond,
          "explainSecond"
        );
      case "isThirdOption":
        return handleOptionDisable(
          drivingReport?.isThirdOption,
          drivingReport?.explainThird,
          "explainThird"
        );
      case "isFourthOption":
        return handleOptionDisable(
          drivingReport?.isFourthOption,
          drivingReport?.explainFourth,
          "explainFourth"
        );
      case "isFifthOption":
        return handleOptionDisable(
          drivingReport?.isFifthOption,
          drivingReport?.explainFifth,
          "explainFifth"
        );
      case "summaryCheck":
        if (drivingReport?.summaryCheck === "abnormal") {
          return false;
        } else if (drivingReport?.summaryCheck === "normal") {
          if (drivingReport?.explainSummary) {
            props.setProp(
              `DoctorCertificateSequence.drivingReport.explainSummary`,
              ""
            );
          }
          return true;
        } else {
          return false;
        }
      default:
        console.log("Doesn't Exist!!");
        return true;
    }
  };

  // Logic Enable/Disable of Input
  const handleOptionDisable = (option: any, explain: any, value: string) => {
    if (option === "yes") {
      return false;
    } else if (option === "no") {
      if (explain) {
        props.setProp(`DoctorCertificateSequence.drivingReport.${value}`, "");
      }
      return true;
    } else {
      return false;
    }
  };

  // Condition Close Button
  // const clearSelectedData = () => {
  //   // Set selectedReport = null
  //   props.setProp(`DoctorCertificateSequence.selectedReport`, null);

  //   // Re-Fetch
  //   props.runSequence({
  //     sequence: "DoctorCertificate",
  //     action: "CLEAR_REPORT",
  //     callReport: "CardDrivingCertificate",
  //   });
  // };

  // const closeSelectedCheck = (reportSelected: any) => {
  //   if (reportSelected != null) {
  //     return (
  //       <Button
  //         color="red"
  //         style={{ width: "8em" }}
  //         onClick={clearSelectedData}
  //       >
  //         ปิด
  //       </Button>
  //     );
  //   } else {
  //     return null;
  //   }
  // };

  // Disable when select FiveForm
  const handleDisableForm = (selected: any, reportType?: any) => {
    if (selected == null) {
      return false;
    } else if (
      selected !== null &&
      reportType === "ใบรับรองแพทย์สำหรับสมัคร/ต่อใบขับขี่"
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <CardDrivingCertificateUX
        // Exam Date
        onExamDate={
          props.DoctorCertificateSequence?.drivingReport?.examDate ||
          formattedDate
        }
        onHandleChangeDate={handleChangeDate}
        // Min Date
        onMinDate={moment().subtract(1, "days")}
        // Identification
        onIdentNo={
          props.DoctorCertificateSequence?.drivingReport?.patientInfo
            ?.citizen_no ||
          props.DoctorCertificateSequence?.drivingReport?.patientInfo
            ?.citizen_passport ||
          "-"
        }
        // Weight
        onWeight={
          props.DoctorCertificateSequence?.drivingReport?.patientWeight || "-"
        }
        // Height
        onHeight={
          props.DoctorCertificateSequence?.drivingReport?.patientHeight || "-"
        }
        // Blood Pressure
        onBloodPressure={
          props.DoctorCertificateSequence?.drivingReport?.patientPressure || "-"
        }
        // Pulse
        onPulse={
          props.DoctorCertificateSequence?.drivingReport?.patientPulse || "-"
        }
        // Checkbox Option
        onCheckboxOption={checkboxOption}
        onHandleSelectedCheckbox={handleSelectedCheckbox}
        // Input
        onHandleChangeText={handleChangeText}
        onHandleInputDisable={handleInputDisable}
        onExplainFirst={
          props.DoctorCertificateSequence?.drivingReport?.explainFirst || ""
        }
        onExplainSecond={
          props.DoctorCertificateSequence?.drivingReport?.explainSecond || ""
        }
        onExplainThird={
          props.DoctorCertificateSequence?.drivingReport?.explainThird || ""
        }
        onExplainFourth={
          props.DoctorCertificateSequence?.drivingReport?.explainFourth || ""
        }
        onExplainFifth={
          props.DoctorCertificateSequence?.drivingReport?.explainFifth || ""
        }
        onExplainSummary={
          props.DoctorCertificateSequence?.drivingReport?.explainSummary || ""
        }
        // Comment
        onComment={
          props.DoctorCertificateSequence?.drivingReport?.comment || ""
        }
        // Close Button When Selected Report
        // onCloseSelected={closeSelectedCheck(
        //   null
        // )}
        // Disable Input
        // onHandleDisableForm={handleDisableForm(
        //   null,
        //   props.DoctorCertificateSequence?.selectedReport?.data?.reportType
        // )}
        languageUX={props.languageUX}
      />
    </>
  );
};

export default CardDrivingCertificate;
