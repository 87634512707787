import { Content, TDocumentDefinitions } from "pdfmake/interfaces";

import ComponyLogoForm, { FontType } from "../../common/CompanyLogoForm";

type HeaderSapiensFormProps = Partial<{
  font?: FontType;
  header: Content | Content[];
  images: TDocumentDefinitions["images"];
  pageMargins: TDocumentDefinitions["pageMargins"];
  styles: TDocumentDefinitions["styles"];
  logoHight?: number;
  hospitalAddress?: any;
}>;

type ReturnType = {
  font?: string;
  fontSizes: Record<number, number>;
  lineHeights: Record<number, number>;
} & Pick<TDocumentDefinitions, "header" | "images" | "pageMargins" | "styles">;

const FORM_NAME = `HeaderSapiensForm`;

const HeaderSapiensForm = async (props: HeaderSapiensFormProps = {}): Promise<ReturnType> => {
  const defaultFont = props.font || "KanitLM";
  const companyLogo = await ComponyLogoForm({
    font: defaultFont,
    height: props.logoHight || 35,
    type: 2,
    form: FORM_NAME,
  });
  const { font, fontSizes, images, lineHeights } = companyLogo;

  return {
    font,
    fontSizes,
    images: {
      ...images,
      ...props.images,
    },
    lineHeights,
    // * Logo height 100
    pageMargins: props.pageMargins || [43, 100, 43, 40],
    styles: {
      hospitalInfo: {
        fontSize: 7,
      },
      hospitalName: {
        bold: true,
        fontSize: 7,
      },
      header: {
        bold: true,
        fontSize: 20,
        margin: [0, 0, 0, 2],
      },
      ...props.styles,
    },
    header: () => ({
      margin: [15, 0, 0, 0],
      stack: [
        {
          columnGap: 10,
          columns: props.hospitalAddress
            ? [{ ...companyLogo, margin: [0, 24, 0, 0], width: "*" }, props.hospitalAddress]
            : [
                { ...companyLogo, margin: [0, 12.5, 0, 0] },
                { text: "", width: "*" },
                {
                  relativePosition: { x: -20, y: 0 },
                  table: {
                    body: [
                      [
                        {
                          alignment: "right",
                          border: [false, false, true, false],
                          stack: [
                            {
                              font: "KanitLM",
                              style: "hospitalName",
                              text: "โรงพยาบาลเซเปี้ยนซ์",
                            },
                            {
                              font: "KanitLM",
                              noWrap: true,
                              style: "hospitalInfo",
                              text: "498/7 ซอยรามคำแหง 39 (เทพลีลา 1)",
                            },
                            {
                              font: "KanitLM",
                              noWrap: true,
                              style: "hospitalInfo",
                              text: "แขวงวังทองหลาง เขตวังทองหลาง",
                            },
                            {
                              font: "KanitLM",
                              style: "hospitalInfo",
                              text: "กรุงเทพมหานคร 10310",
                            },
                          ],
                        },
                        {
                          alignment: "right",
                          border: [true, false, false, false],
                          stack: [
                            {
                              font: "Poppins",
                              style: "hospitalName",
                              text: "Sapiens Pain Hospital",
                            },
                            {
                              font: "Poppins",
                              noWrap: true,
                              style: "hospitalInfo",
                              text: "498/7 Soi Ramkhamhaeng 39 (Thepleela 1)",
                            },
                            {
                              font: "Poppins",
                              noWrap: true,
                              style: "hospitalInfo",
                              text: "Wang Thonglang, Wang thonglang",
                            },
                            {
                              font: "Poppins",
                              style: "hospitalInfo",
                              text: "Bangkok 10310",
                            },
                          ],
                        },
                      ],
                      [
                        {
                          alignment: "right",
                          border: [false, false, false, false],
                          colSpan: 2,
                          font: "Poppins",
                          style: "hospitalInfo",
                          text: "Tel. 02-111-3703",
                        },
                        {},
                      ],
                    ],
                  },
                  width: "auto",
                },
              ],
          lineHeight: 1.175,
          margin: [0, 16, 0, 27.5],
        },
        ...(Array.isArray(props.header) ? props.header : [props.header || { text: "" }]),
      ],
    }),
  };
};

export default HeaderSapiensForm;
