import WasmController from "react-lib/frameworks/WasmController";

import axios from "axios";

// APIs
// DEN
import DentalClinicalFindingList from "issara-sdk/apis/DentalClinicalFindingList_apps_DEN";
// IME
import ImagingOrderItemAction from "issara-sdk/apis/ImagingOrderItemAction_apps_IME";
import ImagingOrderItemActionLogList from "issara-sdk/apis/ImagingOrderItemActionLogList_apps_IME";
import ImagingOrderItemImageDetail from "issara-sdk/apis/ImagingOrderItemImageDetail_apps_IME";
import ImagingOrderItemImageList from "issara-sdk/apis/ImagingOrderItemImageList_apps_IME";
import ImagingOrderResultReportList from "issara-sdk/apis/ImagingOrderResultReportList_apps_IME";

// Serializer
import ImagingOrderItemActionLogSerializerI from "issara-sdk/types/ImagingOrderItemActionLogSerializer_apps_IME";
import ImagingOrderResultSerializer from "issara-sdk/types/ImagingOrderResultSerializer_apps_IME";
import ImagingOrderSerializerI from "issara-sdk/types/ImagingOrderSerializer_apps_IME";
import ImagingQueueSerializerI from "issara-sdk/types/ImagingQueueSerializer_apps_IME";
import ImagingTestSerializer from "issara-sdk/types/ImagingTestSerializer_apps_IME";

import { mapOptions } from "react-lib/apps/HISV3/common/CommonInterface";
import { getLocationsSet, tidyLocation2 } from "react-lib/apps/common/CUDENT/DentalRecordUtil";

import CONFIG from "config/config";

export type ModXrayDetailType = {
  image_id?: number | null;
  // Pacs
  image_url?: string;
  index?: number;
  order?: number;
  payment_status?: BillingStatusType;
  status?: OrderStatusType;
} & Pick<
  ImagingItemSerializer,
  | "allow_editing"
  | "eligibility_type"
  | "group_name"
  | "group_type"
  | "id"
  | "locations"
  | "locations_name"
  | "name_code"
  | "note"
  | "ready_to_view"
  | "urgency"
>;

export type GenderType = "F" | "M";

export type ImagingQueueSerializer = {
  id: number;
  accession_id: string | null;
  executed_by_name: string | null;
  execution_note: string | null;
  group_name: string;
  group_type: "DENTAL" | "GENERAL";
  note?: string;
  order: number;
  patient_gender: "" | GenderType;
  payment_status: BillingStatusType;
  ready_to_view: boolean;
  result_summary: keyof typeof RESULT_SUMMARY_LABEL | null;
  status: OrderStatusType;
  suspected_diagnosis_code: string;
} & Omit<
  ImagingQueueSerializerI,
  "execution_note" | "group_type" | "order" | "patient_gender" | "payment_status" | "status"
>;

export type ImagingItemSerializer = {
  active?: boolean;
  allow_editing: boolean;
  editing?: boolean;
  eligibility_type?: number;
  image_id?: number | null;
  // Pacs
  image_url?: string;
  locations?: number[];
  locations_name?: string;
  name_code: string;
  payment_status: BillingStatusType;
  price_claimable: number;
  price_non_claimable: number;
  price_total: number;
  product?: number;
  product_name: string;
  quantity?: number | string;
  ready_to_view: boolean;
  status: OrderItemStatusType;
  urgency?: "ROUTINE" | "STAT";
} & Pick<ImagingQueueSerializer, "group_name" | "group_type" | "note"> &
  Pick<ImagingTestSerializer, "id" | "name" | "price_unit" | "unit">;

export type ImagingOrderSerializer = {
  care_provider: string;
  extra: {
    lawsuit: boolean;
    out_time: boolean;
    repeat_medication: boolean;
    secret: boolean;
  };
  order_items: ImagingItemSerializer[];
  order_status: OrderStatusNumber;
  order_status_label: "Complete" | "In Progress" | "Pending";
  status: OrderStatusType;
  suspected_diagnosis_code: string;
} & Omit<ImagingOrderSerializerI, "extra" | "order_items" | "order_status" | "status">;

type OrderStatusNumber = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export type PacsGalleryDetailType = {
  accessionId: string | null;
  items: { id: number; description: string; image: string }[];
  name: string;
};

type CUIRResponse = Promise<
  [
    {
      id: number;
      order_item: number;
      order_item_status: OrderStatusType;
      status: OrderStatusType;
    } | null,
    any
  ]
>;

type UOIAResponse = Promise<
  [
    {
      [key: string]: any;
      order_item: number;
      radiologist_name: string;
    },
    any
  ]
>;

export type ImagingOrderItemActionLogSerializer = {
  action_name: string;
  datetime: string;
  note: string;
  user_name: string;
} & Omit<ImagingOrderItemActionLogSerializerI, "action_name" | "datetime" | "note" | "user_name">;

export type ReportActionStatusType = keyof typeof REPORT_ACTION_STATUS;

export type ActionStatusType = keyof typeof ACTION_STATUS;

export type OrderItemStatusType = keyof typeof ORDER_ITEM_STATUS_LABEL;

export type OrderStatusType = keyof typeof ORDER_STATUS;

export type BillingStatusType = keyof typeof BILLING_STATUS;

export const REPORT_ACTION_STATUS = {
  APPROVE: "APPROVE",
  EDIT: "EDIT",
  REPORT: "REPORT",
  UNAPPROVE: "UNAPPROVE",
};

export const ACTION_STATUS = {
  EXECUTE: "EXECUTE",
  READY_TO_VIEW: "READY_TO_VIEW",
  REGISTER: "REGISTER",
  UNEXECUTE: "UNEXECUTE",
  UNREADY_TO_VIEW: "UNREADY_TO_VIEW",
  UNREGISTER: "UNREGISTER",
};

export const ORDER_STATUS = {
  "": "",
  APPROVED: "APPROVED",
  CANCELED: "CANCELED",
  EXECUTED: "EXECUTED",
  ORDERED: "REQUESTED",
  REGISTERED: "REGISTERED",
  REPORTED: "REPORTED",
  REQUESTED: "REQUESTED",
} as const;

export const ORDER_ITEM_STATUS_LABEL = {
  1: ORDER_STATUS.REQUESTED,
  5: ORDER_STATUS.REGISTERED,
  6: ORDER_STATUS.EXECUTED,
  7: ORDER_STATUS.REPORTED,
  8: ORDER_STATUS.APPROVED,
  9: ORDER_STATUS.CANCELED,
} as const;

export const BILLING_STATUS = {
  BILLED: "PENDING",
  PAID: "PAID",
  PARTIAL: "PARTIAL",
  PENDING: "PENDING",
} as const;

export const RESULT_SUMMARY = { ABNORMAL: "ABNORMAL", NORMAL: "NORMAL", UNSPECIFIED: "UNSPECIFIED" };

export const RESULT_SUMMARY_LABEL = {
  1: RESULT_SUMMARY.NORMAL,
  2: RESULT_SUMMARY.ABNORMAL,
  3: RESULT_SUMMARY.UNSPECIFIED,
};

type Handler<P = unknown, R = void> = (
  controller: WasmController<any, any, any>,
  ...params: unknown extends P ? [params?: P] : [params: P]
) => R;

export const HandleModXrayDetail: Handler<{
  data?: ModXrayDetailType;
  type: "CLOSE" | "OPEN";
}> = async (controller, params) => {
  const { data } = params;

  const { organ } = controller.data;

  if (params.type === "OPEN" && !!data) {
    const id = data.id || "MOCK_ID";

    let imageRes: any = null;

    if (data.id && !data.image_url && !data.image_id) {
      [imageRes] = await ImagingOrderItemImageList.list({
        apiToken: controller.apiToken,
        pk: data.id,
      });
    }

    const organItems: any[] = organ?.items || [];

    const locationsSet = getLocationsSet(data.locations_name || "", organItems);

    const locationsName = tidyLocation2(locationsSet, organItems).join(",");

    const items: any[] = imageRes?.items || [];

    const imageDetail = items.slice(-1)[0];

    controller.setState({
      clinicalFindingId: id,
      clinicalFindingIndex: 0,
      clinicalFindingList: [
        {
          id,
          location: "-",
          locations_name: locationsName,
        },
      ],
      filterClinicalFindingIds: [id],
      modXrayDetail: {
        ...data,
        image_id: imageDetail?.id || data.image_id,
        image_url: imageDetail?.image_url || data.image_url,
      },
    });

    return;
  }

  if (params.type === "CLOSE") {
    controller.setState({
      clinicalFindingId: null,
      clinicalFindingIndex: null,
      clinicalFindingList: [],
      filterClinicalFindingIds: [],
      modXrayDetail: null,
    });
  }
};

/* ------------------------------------------------------ */

/*                           API                          */

/* ------------------------------------------------------ */
export const GetClinicalFindingList: Handler = async (controller) => {
  if (controller.data.clinicaltags) {
    return;
  }

  const [r, e] = await DentalClinicalFindingList.list({
    apiToken: controller.apiToken,
    params: {
      limit: 99999,
      offset: 0,
    },
  });

  if (e) {
    console.log("masterdata clinicalFinding error.", e);

    return;
  }

  if (r?.items && r?.items?.length > 0) {
    controller.data.clinicaltags = {
      items: r.items,
      name: "clinicaltags",
    };
  }
};

export const GetOrgan: Handler = async (controller) => {
  if (controller.data.organ) {
    return;
  }

  try {
    const organ = await axios.get("/static/organ.json");
    const otherOrgan = await axios.get("/static/otherOrgan.json");

    const all = [...organ.data, ...otherOrgan.data];

    controller.data.organ = {
      items: all,
      name: "organ",
    };
  } catch (error) {
    console.error("error load organ", error);
  }
};

export const CreateUpdateImagingResult: Handler<
  {
    actionType: ReportActionStatusType;
    data: Partial<ImagingOrderResultSerializer>;
    orderItemId?: number;
  },
  CUIRResponse
> = async (controller, params) => {
  const { actionType, orderItemId } = params;

  let { data } = params;

  if (orderItemId) {
    const [report] = await ImagingOrderResultReportList.list({
      apiToken: controller.apiToken,
      params: {
        order_item: orderItemId,
      },
    });

    data = {
      ...report?.items?.[0],
      ...data,
    };
  }

  const api = ImagingOrderResultReportList.create;

  const promise: Promise<any> = api({
    apiToken: controller.apiToken,
    // pk: data.id,
    data: {
      action: actionType,
      ...data,
    } as any,
    extra: {
      device: controller.data?.device,
      division: controller.data.division,
    },
  });

  return promise.then(([res, err]: any) => [
    {
      ...res,
      order_item: data.order_item,
      status: res?.order_item_status,
    },
    err,
  ]);
};

export const GetAuditLogList: Handler<
  { orderItemId: number },
  Promise<ImagingOrderItemActionLogSerializer[]>
> = async (controller, params) => {
  const result = await ImagingOrderItemActionLogList.list({
    apiToken: controller.apiToken,
    order_item_id: params.orderItemId,
  });

  const items: ImagingOrderItemActionLogSerializer[] = result[0]?.items || [];

  return items.filter((item) => !["READY_TO_VIEW", "UNREADY_TO_VIEW"].includes(item.action_name));
};

// Handler<Params<"OPEN_PACS"> & { btnAction?: string }>
export const GetOrderItemImageList: Handler<
  {
    action: string;
    btnAction?: string;
    card: string;
    data: ImagingQueueSerializer;
  },
  Promise<{ items: any[] }>
> = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.btnAction || params.action}`]: "LOADING",
    },
  });

  const result = await ImagingOrderItemImageList.list({
    apiToken: controller.apiToken,
    pk: params.data.id,
  });

  // #const name = params.data.product_name;
  const items: any[] = result[0]?.items || [];

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [`${params.card}_${params.btnAction || params.action}`]: null,
    },
  });

  return {
    // accessionId: params.data.accession_id || "AC511801213",
    // name: params.data.product_name,
    items,
    // items: items.length
    //   ? []
    //   : [
    //       {
    //         id: 1,
    //         image:
    //           "https://www.imaginghealthcare.com/wp-content/uploads/2020/06/x-ray.jpg",
    //         description: `${name}/imaging no.1`,
    //       },
    //       {
    //         id: 2,
    //         image:
    //           "https://hai.stanford.edu/sites/default/files/styles/media/public/2022-11/chestxraysstablediffusion.jpg?itok=NYm-q7Yk",
    //         description: `${name}/imaging no.2`,
    //       },
    //     ],
  };
};

export const OpenPacsViewer: Handler<{
  action: string;
  btnAction?: string;
  card: string;
  data: ImagingQueueSerializer;
}> = async (controller, params) => {
  const detail = await GetOrderItemImageList(controller, params);

  const url: string = detail.items.slice(-1)[0]?.image_url || "";

  if (url) {
    globalThis.open(url, "_blank");
  } else if (CONFIG.PACS_BASE_URL) {
    const username = controller.data.userProfile?.username || "";
    const password = "Sapiens123";
    const accno = params.data.accession_id ? `&ACCNO=${params.data.accession_id}` : "";

    const url = `${CONFIG.PACS_BASE_URL}?User=${username}&Password=${password}${accno}&PTNID=${params.data.patient_hn}`;

    globalThis.open(url, "_blank");
  }
};

export const UpdateOrderItemAction: Handler<
  {
    actionType: ActionStatusType;
    // data: Params<"UPDATE_STATUS">["orderItems"][number];
    data: any;
    isErrorKey?: boolean;
  },
  UOIAResponse
> = async (controller, params) => {
  const { actionType, data, isErrorKey } = params;

  if ([ACTION_STATUS.READY_TO_VIEW, ACTION_STATUS.UNREADY_TO_VIEW].includes(actionType)) {
    const api = data.image_id
      ? ImagingOrderItemImageDetail.update
      : ImagingOrderItemImageList.create;

    const [, error] = await api({
      apiToken: controller.apiToken,
      data: { image_url: data.image_url, order_item: data.id },
      image_id: data.image_id,
      pk: data.id,
    });

    if (error) {
      return [null, isErrorKey ? { [data.id]: error } : error];
    }
  }

  const promise: Promise<any> = ImagingOrderItemAction.update({
    apiToken: controller.apiToken,
    data: {
      action: actionType,
      ...data,
    },
    pk: data.id,
    extra: {
      device: controller.data?.device,
      division: controller.data.division,
    },
  });

  return promise.then(([res, err]: any) => [
    {
      ...res,
      order_item: data.id,
      radiologist_name: data.radiologist_name,
    },
    err,
  ]);
};

// Utils
export const getStatusImagingOrder = (items: { status: number | string }[]) => {
  const orderItems = items.filter((item)=> ![9, "CANCELED"].includes(item.status));

  const hasAllApprovedStatus = orderItems.length > 0
    ? orderItems.every((item) => [8, "APPROVED"].includes(item.status))
    : false;

  const hasNoOrderedStatus = orderItems.length > 0
    ? orderItems.every((item) => ![1, "ORDERED"].includes(item.status))
    : false;

  if (hasAllApprovedStatus) {
    return { label: "COMPLETE", name: "Complete", specific: "PERFORMED" };
  } else if (hasNoOrderedStatus) {
    return { label: "IN PROGRESS", name: "In progress", specific: "INPROGRESS" };
  }

  return { label: "PENDING", name: "Pending", specific: "PENDING" };
};