import React from 'react';
import PropTypes from 'prop-types';
import CardLayout from '../common/CardLayout';
import {
  Modal,
  Form,
  Button,
  Input,
} from 'semantic-ui-react';
import PureReactTable from '../common/PureReactTable';
import _ from 'lodash';

class ModBillTransactionDetail extends React.PureComponent {
  static propTypes = {
    billTransactionId: PropTypes.number,
    open: PropTypes.bool,
    invoiceNo: PropTypes.string,
    invalidateReasons: PropTypes.string,
    rectificationDetail: PropTypes.string,
    tryFixUrls: PropTypes.array,
    hn: PropTypes.string,
    name: PropTypes.string,
    encounterIds: PropTypes.array,
    items: PropTypes.array,
    page: PropTypes.number,
    total: PropTypes.number,
    pageSize: PropTypes.number,
    isLoading: PropTypes.bool,
    modalStyle: PropTypes.object,
    onClose: PropTypes.func,
    onFetchTableData: PropTypes.func,
    onAdminRedirect: PropTypes.func,
    onUrlRedirect: PropTypes.func,
    onShowAdmin: PropTypes.func,
    onPageSizeChange: PropTypes.func,
    onPageChange: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.gridColumns = [
      {
        Header: 'Element',
        accessor: 'element',
        width: 170,
      },
      {
        Header: 'ID',
        accessor: 'unique_key',
        width: 170,
      },
      {
        Header: 'Parent',
        accessor: 'parent_key',
        width: 150,
      },
      {
        Header: 'Error message',
        accessor: 'error_message',
      },
      {
        Header: '',
        accessor: '',
        width: 120,
        Cell: (row) => (
          <Button
            compact
            color='orange'
            onClick={() => {
              const pk = row.original.pk;
              const element = row.original.element.toLowerCase();
              this.props.onShowAdmin(pk, element);
            }}
          >
            Admin
          </Button>
        )
      }
    ];
  }

  handleGetTrProps = (state, rowInfo, column, instance) => {
    const options = {};
    if (rowInfo && _.get(rowInfo, 'original.error_message') === 'bill_transaction valid=False'){
      return options;
    }
    if (rowInfo && _.get(rowInfo, 'original.valid') === false) {
      options.style = {
        background: 'rgb(255, 204, 102)',
        color: 'black',
        whiteSpace: 'pre-line'
      };
    }
    return options;
  }

  formatPatient = (hn, name) => {
    const formattedHn = 'C' + parseInt(hn.substring(1));
    return '[' + formattedHn + ']' + ' ' + name;
  }

  formatEncounterId = (encounterIds) => {
    return encounterIds.join(', ');
  }

  render() {
    const {
      invoiceNo,
      invalidateReasons,
      rectificationDetail,
      tryFixUrls,
      hn,
      name,
      encounterIds,
      items,
      page,
      total,
      pageSize,
      isLoading,
      open,
      modalStyle,
      onClose,
      onAdminRedirect,
      onUrlRedirect,
      onPageSizeChange,
      onPageChange,
    } = this.props;
    
    return (
      <Modal
        open={open}
        size='fullscreen'
        style={modalStyle}
        onClose={onClose}
      >
        <CardLayout
          titleText='รายละเอียด BillTransaction'
          headerColor='orange'
          onClose={onClose}
          loading={isLoading}
          titleContent={
            <Button
              color='orange'
              onClick={onAdminRedirect}
            >
              ดูรายละเอียดใน Admin
            </Button>
          }
        >
          <Form>
            <Form.Group inline>
              <Form.Field width={4}>
                <label style={{ minWidth:"fit-content" }}>Invoice No</label>
                <Input value={invoiceNo} />
              </Form.Field>
              <Form.Field width={5}>
                <label style={{ minWidth:"fit-content" }}>ผู้ป่วย</label>
                <Input value={this.formatPatient(hn,name)} />
              </Form.Field>
              <Form.Field width={5}>
                <label style={{ minWidth:"fit-content" }}>Encounter ID ที่เกี่ยวข้อง</label>
                <Input value={this.formatEncounterId(encounterIds)} />
              </Form.Field>
            </Form.Group>

            <br />

            <Form.TextArea
              autoHeight
              label='สาเหตุที่ validate ไม่ผ่าน'
              value={invalidateReasons}
            />

            <br />

            <Form.TextArea
              autoHeight
              label='รายละเอียดการติด C ที่ต้องแก้ไข'
              value={rectificationDetail}
            />

            <br />

            {tryFixUrls.length !== 0 && (
              <Button
                color='purple'
                onClick={ () => {
                  onUrlRedirect(tryFixUrls);
                }}
              >
                <i className='icon wrench'></i>
                แก้ไขข้อมูล
              </Button>
            )}
          </Form>

          <br />

          <PureReactTable
            data={items}
            page={page - 1}
            pages={total}
            pageSize={pageSize}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            columns={this.gridColumns}
            getTrProps={this.handleGetTrProps}
          />
        </CardLayout>
      </Modal>
    );
  }
}

export default ModBillTransactionDetail;
