import React from "react";
import { Button } from "semantic-ui-react";

// Common
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import { useIntl } from "react-intl";

// Types
type ModConfirmVoidProps = {
  setProp: (key: string, value: any, callback?: Function) => any;
  // seq
  runSequence: any;
  open: boolean;
  data?: Record<string, any> | null;
  receiptId?: string;
  // config
  errorKey?: string;
  type?: "UNVOID" | "VOID" | "SAVE";
  // commonInterface
  buttonLoadCheck?: Record<string, any>;
  // callback
  onClose: () => any;
};

const BUTTON_ACTIONS = {
  EDC: "EDC",
  NO_EDC: "NO_EDC",
};

const MOD_CONFIRM_VOID = "ModConfirmVoid";

const ModConfirmVoid = (props: ModConfirmVoidProps) => {
  const intl = useIntl();
  const handleConfirmVoid = (useEDC: boolean) => (e: any, data: any) => {
    props.runSequence({
      sequence: "BillingHistory",
      action: "confirm_void",
      data: props.data,
      useEDC,
      receipt: props.receiptId,
      card: MOD_CONFIRM_VOID,
      errorKey: props.errorKey,
      btnAction: data.name,
      type: props.type,
      onSuccess: props.onClose,
    });
  };

  return (
    <ModConfirm
      closeIcon={true}
      titleColor={props.type === "SAVE" ? "green" : "yellow"}
      openModal={props.open}
      titleName={
        props.type === "VOID" ? "ยืนยันการ VOID รายการนี้ใช่หรือไม่" : "-"
      }
      content={
        <div style={{ margin: "-0.5rem 0 -1.5rem" }}>
          {["VOID", "SAVE"].includes(props.type || "") ? (
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={handleConfirmVoid(true)}
              // data
              paramKey={`${MOD_CONFIRM_VOID}_${BUTTON_ACTIONS.EDC}`}
              buttonLoadCheck={
                props.buttonLoadCheck?.[
                  `${MOD_CONFIRM_VOID}_${BUTTON_ACTIONS.EDC}`
                ]
              }
              // config
              name={BUTTON_ACTIONS.EDC}
              size="medium"
              title={intl.formatMessage({ id: "ยืนยัน พร้อมส่งข้อมูลไปยังเครื่อง EDC" })}
              fluid={true}
              style={{ width: "100%" }}
            />
          ) : (
            <div>{intl.formatMessage({ id: "ยืนยันการ UNVOID รายการนี้ใช่หรือไม่" })}</div>
          )}
        </div>
      }
      onDeny={props.onClose}
      onApprove={handleConfirmVoid(false)}
      onClose={props.onClose}
      approveButton={
        <ButtonLoadCheck
          // function
          setProp={props.setProp}
          onClick={handleConfirmVoid(false)}
          // data
          paramKey={`${MOD_CONFIRM_VOID}_${BUTTON_ACTIONS.NO_EDC}`}
          buttonLoadCheck={
            props.buttonLoadCheck?.[
              `${MOD_CONFIRM_VOID}_${BUTTON_ACTIONS.NO_EDC}`
            ]
          }
          // config
          color={"green"}
          name={BUTTON_ACTIONS.NO_EDC}
          size="medium"
          title={intl.formatMessage({ id: "ใช่" })}
          basic={true}
          style={{ width: "100%" }}
          iconStyle={{ margin: 0 }}
        />
      }
      denyButtonText={intl.formatMessage({ id: "ไม่" })}
      denyButtonColor={"red"}
    />
  );
};

export default React.memo(ModConfirmVoid);
