import WasmController from "react-lib/frameworks/WasmController";
//apis
import TreatmentOrderList from "issara-sdk/apis/TreatmentOrderList_apps_TRT";
import TreatmentOrderDetail from "issara-sdk/apis/TreatmentOrderDetail_apps_TRT";
import UserTokenizeView from "issara-sdk/apis/UserTokenizeView_users";
import TreatmentOrderUndoPerform from "issara-sdk/apis/TreatmentOrderUndoPerform_apps_TRT";
import TreatmentOrderEducationApproveView from "issara-sdk/apis/TreatmentOrderEducationApproveView_apps_TRT";
import TreatmentOrderResultEducationApproveView from "issara-sdk/apis/TreatmentOrderResultEducationApproveView_apps_TRT";
import icd9cmKeyUp from "issara-sdk/apis/icd9cmKeyUp_core";
import moment from "moment";
import { formatDatetime } from "react-lib/utils/dateUtils";

export type State = {
  buttonLoadCheck?: any;
  TreatmentResultSequence?: {
    sequenceIndex?: string | null;
    isOpenError?: boolean;
    isOpenErrorNote?: boolean;
    note?: string | null;
    index?: number | null;
    diagnosisListPrincipal?: any[] | null;
    diagnosisListSecondary?: any[] | null;
    showSearchResultPrincipal?: boolean;
    showSearchResultSecondary?: boolean;
    originalICD9Principal?: {
      icd10_id?: string | null;
      icdcode?: string | null;
      medterm?: string | null;
      icdterm?: string | null;
    } | null;
    originalICD9Secondary?: {
      icd10_id?: string | null;
      icdcode?: string | null;
      medterm?: string | null;
      icdterm?: string | null;
    } | null;
  } | null;
  selectedEmr?: any;
  selectedEncounter?: any;
  TreatmentResultList?: any;
  treatmentToken?: {
    token?: string;
    employeeName?: string;
    error?: any;
    loading?: boolean;
  };
  errorMessage?: any;
  successMessage?: any;
  procedureItems?: any[] | null;
  secondaryItems?: any[] | null;
};

export const StateInitial: State = {
  TreatmentResultSequence: {
    sequenceIndex: null,
    isOpenError: false,
    note: null,
    isOpenErrorNote: false,
    diagnosisListPrincipal: [],
    diagnosisListSecondary: [],
    showSearchResultPrincipal: false,
    showSearchResultSecondary: false,
    originalICD9Principal: {
      icd10_id: null,
      icdcode: null,
      medterm: null,
      icdterm: null,
    },
    originalICD9Secondary: {
      icd10_id: null,
      icdcode: null,
      medterm: null,
      icdterm: null,
    },
  },
  TreatmentResultList: [],
  treatmentToken: {
    token: "",
    employeeName: "",

    error: null,
    loading: false,
  },
  successMessage: {},
  errorMessage: [],
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: any }
  | { message: "HandleGetTreatmentTokenization"; params: { code?: string } };

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const Start: Handler = async (controller, params) => {
  const state = controller.getState();

  if (!state.TreatmentResultSequence) {
    return;
  }

  const [r, e, n] = await TreatmentOrderList.list({
    params: {
      encounter_id: state?.selectedEncounter?.pk,
    },
    apiToken: controller.apiToken,
    extra: { division: controller.data?.division },
  });

  if (r?.items?.length > 0) {
    const coreProcedureSecondary = r.items?.[0]?.core_procedure
      ?.filter((acc: any) => acc.type === "SECONDARY")
      .map((item: any) => ({
        ...item,
        medical_description: !!item.icd9cm_med_term
          ? item.icd9cm_term
          : item.medical_description,
        icd9cm_med_term: item.medical_description,
      }));
    const coreProcedurePrincipal = r.items?.[0]?.core_procedure
      ?.filter((acc: any) => acc.type === "PRIMARY")
      .map((item: any) => ({
        ...item,
        medical_description: !!item.icd9cm_med_term
          ? item.icd9cm_term
          : item.medical_description,
        icd9cm_med_term: item.medical_description,
      }));

    controller.setState({
      TreatmentResultSequence: {
        ...state.TreatmentResultSequence,
        sequenceIndex: "SaveTreatmentResult",
        diagnosisListPrincipal: coreProcedurePrincipal,
        diagnosisListSecondary: coreProcedureSecondary,
        showSearchResultPrincipal: false,
        showSearchResultSecondary: false,
      },
      TreatmentResultList: r.items,
    });
  } else {
    controller.setState({
      TreatmentResultList: [],
    });
  }
};

export const SaveTreatmentResult: Handler = async (controller, params) => {
  var state = controller.getState();
  if (params?.action === "Save") {
    const state = controller.getState();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "LOADING",
      },
    });

    const TreatmentResult = state?.TreatmentResultList || [];
    const ItemResult = TreatmentResult.filter(
      (item: any, index: number) => index === params?.id
    );

    const principal =
      state?.TreatmentResultSequence?.diagnosisListPrincipal || [];
    const secondary =
      state?.TreatmentResultSequence?.diagnosisListSecondary || [];
    const coreProcedure = [...principal, ...secondary];

    const startDateTime = `${params.datetime?.startDate}-${params.datetime?.startTime}`;
    const finishDateTime = `${params.datetime?.endDate}-${params.datetime?.endTime}`;

    const procedure = coreProcedure?.map((item: any) => ({
      active: item.active,
      created: item.created,
      edit_user: item.edited,
      emr: state?.selectedEmr?.id,
      icd9cm: item.icd9cm,
      icd9cm_code: item.icd9cm_code,
      icd9cm_med_term: item.medterm_id,
      icd9cm_term: item.icd9cm_term,
      id: item.id,
      medical_description: item.medical_description,
      order: item.order,
      type: item.type,
    }));

    const [response, error, network] = await TreatmentOrderDetail.update({
      pk: ItemResult?.[0].id,
      data: {
        action: "PERFORM",
        auto_ack: false,
        complications: params.complications || ItemResult?.[0]?.complications || "",
        core_procedure: procedure,
        finish: finishDateTime,
        order_perform_by: state?.treatmentToken?.token
          ? state?.treatmentToken?.token
          : "",
        result: params.result || ItemResult?.[0]?.result || "",
        start: startDateTime,
        data_changed: params.data_changed,
      },
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });

    if (error) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: error },
        TreatmentResultSequence: {
          ...state.TreatmentResultSequence,
          isOpenError: true,
        },
        // TreatmentResultList: state.TreatmentResultList,
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "ERROR",
        },
      });
    } else {
      const [r, e, n] = await TreatmentOrderList.list({
        params: {
          encounter_id: state?.selectedEncounter?.pk,
        },
        apiToken: controller.apiToken,
        extra: { division: controller.data?.division },
      });

      if (r?.items?.length > 0) {
        const coreProcedureSecondary = r.items?.[params.id]?.core_procedure
          ?.filter((acc: any) => acc.type === "SECONDARY")
          .map((item: any) => ({
            ...item,
            medical_description: !!item.icd9cm_med_term
              ? item.icd9cm_term
              : item.medical_description,
            icd9cm_med_term: item.medical_description,
          }));
        const coreProcedurePrincipal = r.items?.[params.id]?.core_procedure
          ?.filter((acc: any) => acc.type === "PRIMARY")
          .map((item: any) => ({
            ...item,
            medical_description: !!item.icd9cm_med_term
              ? item.icd9cm_term
              : item.medical_description,
            icd9cm_med_term: item.medical_description,
          }));

        controller.setState({
          TreatmentResultSequence: {
            ...state.TreatmentResultSequence,
            diagnosisListPrincipal: coreProcedurePrincipal,
            diagnosisListSecondary: coreProcedureSecondary,
            showSearchResultPrincipal: false,
            showSearchResultSecondary: false,
          },
          TreatmentResultList: r.items,
          successMessage: { ...state.successMessage, [params.card]: null },
          errorMessage: { ...state.errorMessage, [params.card]: null },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.card}_${params.action}`]: "SUCCESS",
          },
        });

        controller.handleEvent({
          message: "HandleRefreshEncounter" as any,
          params: {},
        });
      }
    }
  } else if (params?.action === "Cancel") {
    const [response, error, network] = await TreatmentOrderUndoPerform.update({
      pk: params?.id,
      data: {
        note: state.TreatmentResultSequence?.note,
      },
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });
    if (error) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: error },
        TreatmentResultSequence: {
          ...state.TreatmentResultSequence,
          isOpenErrorNote: true,
        },
        // TreatmentResultList: state.TreatmentResultList,
      });
    } else {
      const [r, e, n] = await TreatmentOrderList.list({
        params: {
          encounter_id: state?.selectedEncounter?.pk,
        },
        apiToken: controller.apiToken,
        extra: { division: controller.data?.division },
      });

      controller.handleEvent({
        message: "HandleRefreshEncounter" as any,
        params: {},
      });

      if (r?.items?.length > 0) {
        const coreProcedureSecondary = r.items?.[params.id]?.core_procedure
          ?.filter((acc: any) => acc.type === "SECONDARY")
          .map((item: any) => ({
            ...item,
            medical_description: !!item.icd9cm_med_term
              ? item.icd9cm_term
              : item.medical_description,
            icd9cm_med_term: item.medical_description,
          }));
        const coreProcedurePrincipal = r.items?.[params.id]?.core_procedure
          ?.filter((acc: any) => acc.type === "PRIMARY")
          .map((item: any) => ({
            ...item,
            medical_description: !!item.icd9cm_med_term
              ? item.icd9cm_term
              : item.medical_description,
            icd9cm_med_term: item.medical_description,
          }));

        controller.setState({
          TreatmentResultSequence: {
            ...state.TreatmentResultSequence,
            diagnosisListPrincipal: coreProcedurePrincipal,
            diagnosisListSecondary: coreProcedureSecondary,
            showSearchResultPrincipal: false,
            showSearchResultSecondary: false,
            note: "",
          },
          TreatmentResultList: r.items,
          successMessage: { ...state.successMessage, [params.card]: true },
          errorMessage: { ...state.errorMessage, [params.card]: null },
          treatmentToken: {
            token: "",
            employeeName: "",
            loading: false,
            error: null,
          },
        });
      }
    }
  } else if (params?.action === "addPrincipal") {
    state = controller.getState();

    let diagnosis: any = [
      ...(state?.TreatmentResultSequence?.diagnosisListPrincipal || []),
    ];
    const defaultList = {
      emr: state?.selectedEmr?.id,
      id: null,
      order: null,
      icd9cm: null,
      icd9cm_code: null,
      icd9cm_med_term: null,
      icd9cm_term: null,
      medical_description: "",
      type: "PRIMARY",
    };

    diagnosis.push(defaultList);

    controller.setState({
      TreatmentResultSequence: {
        ...state.TreatmentResultSequence,
        diagnosisListPrincipal: diagnosis,
      },
    });
  } else if (params?.action === "addSecondary") {
    state = controller.getState();

    let diagnosis: any = [
      ...(state?.TreatmentResultSequence?.diagnosisListSecondary || []),
    ];
    const defaultList = {
      emr: state?.selectedEmr?.id,
      id: null,
      order: null,
      icd9cm: null,
      icd9cm_code: null,
      icd9cm_med_term: null,
      icd9cm_term: null,
      medical_description: "",
      type: "SECONDARY",
    };

    diagnosis.push(defaultList);

    controller.setState({
      TreatmentResultSequence: {
        ...state.TreatmentResultSequence,
        diagnosisListSecondary: diagnosis,
        index: null,
      },
    });
  } else if (params?.action === "removeDiagnosis") {
    state = controller.getState();

    let diagnosisPrincipal: any = [
      ...(state?.TreatmentResultSequence?.diagnosisListPrincipal || []),
    ];
    let diagnosisSecondary: any = [
      ...(state?.TreatmentResultSequence?.diagnosisListSecondary || []),
    ];

    if (params.value.type === "PRIMARY") {
      const diagnosisPrincipalFilter = diagnosisPrincipal?.filter(
        (acc: any, index: number) => index !== params.index
      );

      controller.setState({
        TreatmentResultSequence: {
          ...state.TreatmentResultSequence,
          diagnosisListPrincipal: diagnosisPrincipalFilter,
        },
      });
    } else if (params.value.type === "SECONDARY") {
      state = controller.getState();
      const diagnosisSecondaryFilter = diagnosisSecondary?.filter(
        (acc: any, index: number) => index !== params.index
      );
      controller.setState({
        TreatmentResultSequence: {
          ...state.TreatmentResultSequence,
          diagnosisListSecondary: [...diagnosisSecondaryFilter],
        },
      });
    }
  } else if (params?.action === "searchPrincipal") {
    const state = controller.getState();
    if (params?.name === "icdcode") {
      if (params?.value.length < 3) {
        return;
      }
      controller.setState({
        TreatmentResultSequence: {
          ...state.TreatmentResultSequence,
          showSearchResultPrincipal: true,
        },
      });

      const icd9Search = await icd9cmKeyUp.post({
        method: params?.name,
        data: {
          icdcode: params?.value,
        },
        apiToken: controller.apiToken,
      });

      controller.setState({
        TreatmentResultSequence: {
          ...state.TreatmentResultSequence,
          showSearchResultPrincipal: true,
        },
        procedureItems: icd9Search?.[0]?.response,
      });
    } else if (params?.name === "medterm") {
      if (params?.value.length < 3) {
        return;
      }
      controller.setState({
        TreatmentResultSequence: {
          ...state.TreatmentResultSequence,
          showSearchResultPrincipal: true,
        },
      });
      const icd9Search = await icd9cmKeyUp.post({
        method: params?.name,
        data: {
          medterm: params?.value,
        },
        apiToken: controller.apiToken,
      });
      controller.setState({
        TreatmentResultSequence: {
          ...state.TreatmentResultSequence,
          showSearchResultPrincipal: true,
        },
        procedureItems: icd9Search?.[0]?.response,
      });
    } else if (params?.name === "icdterm") {
      if (params?.value.length < 3) {
        return;
      }
      controller.setState({
        TreatmentResultSequence: {
          ...state.TreatmentResultSequence,
          showSearchResultPrincipal: true,
        },
      });
      const icd9Search = await icd9cmKeyUp.post({
        method: params?.name,
        data: {
          icdterm: params?.value,
        },
        apiToken: controller.apiToken,
      });
      controller.setState({
        TreatmentResultSequence: {
          ...state.TreatmentResultSequence,
          showSearchResultPrincipal: true,
        },
        procedureItems: icd9Search?.[0]?.response,
      });
    }
  } else if (params?.action === "selectPrincipal") {
    const state = controller.getState();

    let selectPrincipal =
      state?.TreatmentResultSequence?.diagnosisListPrincipal?.map(
        (item: any, index: any) => {
          if (index === params.index) {
            return {
              ...item,
              icd9cm: params.value.icd10_id,
              icd9cm_code: params.value.icdcode,
              icd9cm_med_term: params.value.medterm,
              icd9cm_term: params.value.icdterm,
              medical_description: params.value.medterm,
              medterm_id: params.value.medterm_id,
            };
          } else {
            return item;
          }
        }
      );

    controller.setState({
      TreatmentResultSequence: {
        ...state.TreatmentResultSequence,
        diagnosisListPrincipal: selectPrincipal,
        showSearchResultPrincipal: false,
      },
    });
  } else if (params?.action === "searchSecondary") {
    if (params?.name === "icdcode") {
      if (params?.value.length < 3) {
        return;
      }
      controller.setState({
        TreatmentResultSequence: {
          ...state.TreatmentResultSequence,
          showSearchResultSecondary: true,
        },
      });

      const icd9Search = await icd9cmKeyUp.post({
        method: params?.name,
        data: {
          icdcode: params?.value,
        },
        apiToken: controller.apiToken,
      });

      controller.setState({
        TreatmentResultSequence: {
          ...state.TreatmentResultSequence,
          showSearchResultSecondary: true,
        },
        secondaryItems: icd9Search?.[0]?.response,
      });
    } else if (params?.name === "medterm") {
      if (params?.value.length < 3) {
        return;
      }
      controller.setState({
        TreatmentResultSequence: {
          ...state.TreatmentResultSequence,
          showSearchResultSecondary: true,
        },
      });
      const icd9Search = await icd9cmKeyUp.post({
        method: params?.name,
        data: {
          medterm: params?.value,
        },
        apiToken: controller.apiToken,
      });
      controller.setState({
        TreatmentResultSequence: {
          ...state.TreatmentResultSequence,
          showSearchResultSecondary: true,
        },
        secondaryItems: icd9Search?.[0]?.response,
      });
    } else if (params?.name === "icdterm") {
      if (params?.value.length < 3) {
        return;
      }
      controller.setState({
        TreatmentResultSequence: {
          ...state.TreatmentResultSequence,
          showSearchResultSecondary: true,
        },
      });
      const icd9Search = await icd9cmKeyUp.post({
        method: params?.name,
        data: {
          icdterm: params?.value,
        },
        apiToken: controller.apiToken,
      });
      controller.setState({
        TreatmentResultSequence: {
          ...state.TreatmentResultSequence,
          showSearchResultSecondary: true,
        },
        secondaryItems: icd9Search?.[0]?.response,
      });
    }
  } else if (params?.action === "selectSencondary") {
    state = controller.getState();

    let selectPrincipal =
      state?.TreatmentResultSequence?.diagnosisListSecondary?.map(
        (item: any, index: any) => {
          if (index === params.index) {
            return {
              ...item,
              icd9cm: params.value.icd10_id,
              icd9cm_code: params.value.icdcode,
              icd9cm_med_term: params.value.medterm,
              icd9cm_term: params.value.icdterm,
              medical_description: params.value.medterm,
              medterm_id: params.value.medterm_id,
            };
          } else {
            return item;
          }
        }
      );

    controller.setState({
      TreatmentResultSequence: {
        ...state.TreatmentResultSequence,
        diagnosisListSecondary: selectPrincipal,
        showSearchResultSecondary: false,
      },
    });
  } else if (params?.action === "saveApprove") {
    const state = controller.getState();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.type}`]: "LOADING",
      },
    });

    if (params.type === "treatmentResult") {
      const TreatmentResult = state?.TreatmentResultList || [];
      const ItemResult = TreatmentResult.filter(
        (item: any, index: number) => index === params?.id
      );

      const principal =
        state?.TreatmentResultSequence?.diagnosisListPrincipal || [];
      const secondary =
        state?.TreatmentResultSequence?.diagnosisListSecondary || [];
      const coreProcedure = [...principal, ...secondary];

      const startDateTime = `${params.datetime?.startDate}-${params.datetime?.startTime}`;
      const finishDateTime = `${params.datetime?.endDate}-${params.datetime?.endTime}`;

      const procedure = coreProcedure?.map((item: any) => ({
        active: item.active,
        created: item.created,
        edit_user: item.edited,
        emr: state?.selectedEmr?.id,
        icd9cm: item.icd9cm,
        icd9cm_code: item.icd9cm_code,
        icd9cm_med_term: item.medterm_id,
        icd9cm_term: item.icd9cm_term,
        id: item.id,
        medical_description: item.medical_description,
        order: item.order,
        type: item.type,
      }));

      const [userTokenizeRes, userTokenizeErr, userTokenizeNet] =
        await UserTokenizeView.post({
          apiToken: controller.apiToken,
          data: { code: params.approved_by_username },
        });

      if (userTokenizeRes) {
        const [response, error, network] = await TreatmentOrderDetail.update({
          pk: ItemResult?.[0]?.id,
          data: {
            action: "PERFORM",
            auto_ack: false,
            complications: params.complications || ItemResult?.[0]?.complications || "",
            core_procedure: procedure,
            finish: finishDateTime,
            order_perform_by: state?.treatmentToken?.token
              ? state?.treatmentToken?.token
              : userTokenizeRes?.token,
            result: params.result || ItemResult?.[0]?.result || "",
            start: startDateTime,
            data_changed: params.data_changed,
          },
          apiToken: controller.apiToken,
          extra: {
            division: controller.data.division,
          },
        });

        if (error) {
          controller.setState({
            errorMessage: { ...state.errorMessage, [params.card]: error },
            TreatmentResultSequence: {
              ...state.TreatmentResultSequence,
              isOpenError: true,
            },
            // TreatmentResultList: state.TreatmentResultList,
            buttonLoadCheck: {
              ...state.buttonLoadCheck,
              [`${params.card}_${params.type}`]: "ERROR",
            },
          });
        }

        const [treatmentResultRes, treatmentResultErr, treatmentResultNet] =
          await TreatmentOrderResultEducationApproveView.put({
            pk: params.value.id,
            data: {
              approved_by_username: params.approved_by_username,
              approved_by_password: params.approved_by_password,
            },
            apiToken: controller.apiToken,
          });

        if (treatmentResultRes) {
          controller.setState({
            buttonLoadCheck: {
              ...state.buttonLoadCheck,
              [`${params.card}_${params.type}`]: "SUCCESS",
            },
            successMessage: {
              ...state.successMessage,
              [`${params.card}_${params.action}`]: treatmentResultRes,
            },
          });
        } else {
          controller.setState({
            buttonLoadCheck: {
              ...state.buttonLoadCheck,
              [`${params.card}_${params.type}`]: "ERROR",
            },
            errorMessage: {
              ...state.errorMessage,
              [`${params.card}_${params.action}`]: {
                error: treatmentResultErr,
                showMessage: false,
              },
            },
          });
        }
      }
    } else if (params.type === "treatmentOrder") {
      const [treatmentOrderRes, treatmentOrderErr, treatmentOrderNet] =
        await TreatmentOrderEducationApproveView.put({
          pk: params.value.id,
          data: {
            approved_by_username: params.approved_by_username,
            approved_by_password: params.approved_by_password,
            data_changed: params.data_changed,
          },
          apiToken: controller.apiToken,
        });

      if (treatmentOrderRes) {
        controller.setState({
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.card}_${params.type}`]: "SUCCESS",
          },
          successMessage: {
            ...state.successMessage,
            [`${params.card}_${params.action}`]: treatmentOrderRes,
          },
        });
      } else {
        controller.setState({
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.card}_${params.type}`]: "ERROR",
          },
          errorMessage: {
            ...state.errorMessage,
            [`${params.card}_${params.action}`]: {
              error: treatmentOrderErr,
              showMessage: false,
            },
          },
        });
      }
    }

    const [r, e, n] = await TreatmentOrderList.list({
      params: {
        encounter_id: state?.selectedEncounter?.pk,
      },
      apiToken: controller.apiToken,
      extra: { division: controller.data?.division },
    });

    controller.handleEvent({
      message: "HandleRefreshEncounter" as any,
      params: {},
    });

    if (r?.items?.length > 0) {
      const coreProcedureSecondary = r.items?.[params.id]?.core_procedure
        ?.filter((acc: any) => acc.type === "SECONDARY")
        .map((item: any) => ({
          ...item,
          medical_description: !!item.icd9cm_med_term
            ? item.icd9cm_term
            : item.medical_description,
          icd9cm_med_term: item.medical_description,
        }));
      const coreProcedurePrincipal = r.items?.[params.id]?.core_procedure
        ?.filter((acc: any) => acc.type === "PRIMARY")
        .map((item: any) => ({
          ...item,
          medical_description: !!item.icd9cm_med_term
            ? item.icd9cm_term
            : item.medical_description,
          icd9cm_med_term: item.medical_description,
        }));

      controller.setState({
        TreatmentResultSequence: {
          ...state.TreatmentResultSequence,
          diagnosisListPrincipal: coreProcedurePrincipal,
          diagnosisListSecondary: coreProcedureSecondary,
          showSearchResultPrincipal: false,
          showSearchResultSecondary: false,
        },
        TreatmentResultList: r.items,
      });
    }
  }
};

export const HandleGetTokenization: Handler = async (controller, params) => {
  // params should only have code
  const [response, error, network] = await UserTokenizeView.post({
    apiToken: controller.apiToken,
    data: { ...params },
  });

  return [response, error, network];
};

export const HandleGetTreatmentTokenization: Handler = async (
  controller,
  params
) => {
  let state = controller.getState();

  controller.setState({
    treatmentToken: {
      ...state.treatmentToken,
      loading: true,
    },
  });

  const [response, error, network] = await HandleGetTokenization(
    controller,
    params
  );
  if (error) {
    controller.setState({
      treatmentToken: {
        ...state.treatmentToken,
        error: error,
        loading: false,
      },
    });
    return;
  }

  state = controller.getState();
  const ItemResult = (state?.TreatmentResultList || []).filter(
    (item: any, index: number) => index === params?.id
  );
  const itemToken = ItemResult?.map((item: any) => ({
    ...item,
    order_perform_by: response?.token || "",
    order_perform_by_name:
      decodeURIComponent(atob(response?.token.split(".")[1])) || "",
  }));

  let items: any = [...(state?.TreatmentResultList || [])];

  controller.setState({
    treatmentToken: {
      ...state.treatmentToken,
      token: response?.token || "",
      employeeName:
        decodeURIComponent(atob(response?.token.split(".")[1])) || "",
      loading: false,
    },
  });
};
