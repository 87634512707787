import React from 'react'
import {
   Button,
   Checkbox,
   Dropdown,
   Input,
   Radio,
   TextArea
} from 'semantic-ui-react'

import DateTextBox from '../common/DateTextBox';
import TimeFreeTextBox24 from '../common/TimeFreeTextBox24';
import { useIntl } from "react-intl";


import styles from './CardPORehabilitationTreatmentOrthoticFittingandCheckout.module.css'

export interface PORehabilitationTreatmentOrthoticFittingandCheckoutViewDataType {
    PoRehabilitationTreatmentOrthoticFittingCheckout2: string,
    PoRehabilitationTreatmentOrthoticFittingCheckout2Disabled: boolean,
    PoRehabilitationTreatmentOrthoticFittingCheckout4: string,
    PoRehabilitationTreatmentOrthoticFittingCheckout4Disabled: boolean,
    PoRehabilitationTreatmentOrthoticFittingCheckout6: string,
    PoRehabilitationTreatmentOrthoticFittingCheckout6Disabled: boolean,
    PoRehabilitationTreatmentOrthoticFittingCheckout8: string,
    PoRehabilitationTreatmentOrthoticFittingCheckout8Disabled: boolean,
    Ct4PainScore: string,
    Ct4PainScoreDisabled: boolean,
    Ct4PainScoreOptions: any,
    PoRehabilitationTreatmentOrthoticFittingCheckout13: string,
    PoRehabilitationTreatmentOrthoticFittingCheckout13Disabled: boolean,
    PoRehabilitationTreatmentOrthoticFittingCheckout16: string,
    PoRehabilitationTreatmentOrthoticFittingCheckout16Disabled: boolean,
    PoRehabilitationTreatmentOrthoticFittingCheckout18: boolean,
    PoRehabilitationTreatmentOrthoticFittingCheckout18Disabled: boolean,
    PoRehabilitationTreatmentOrthoticFittingCheckout20: boolean,
    PoRehabilitationTreatmentOrthoticFittingCheckout20Disabled: boolean,
    PoRehabilitationTreatmentOrthoticFittingCheckout23: string,
    PoRehabilitationTreatmentOrthoticFittingCheckout23Disabled: boolean,
    PoRehabilitationTreatmentOrthoticFittingCheckout24: boolean,
    PoRehabilitationTreatmentOrthoticFittingCheckout24Disabled: boolean,
    PoRehabilitationTreatmentOrthoticFittingCheckout26: string,
    PoRehabilitationTreatmentOrthoticFittingCheckout26Disabled: boolean,
    PoRehabilitationTreatmentOrthoticFittingCheckout28: boolean,
    PoRehabilitationTreatmentOrthoticFittingCheckout28Disabled: boolean,
    PoRehabilitationTreatmentOrthoticFittingCheckout30: boolean,
    PoRehabilitationTreatmentOrthoticFittingCheckout30Disabled: boolean,
    PoRehabilitationTreatmentOrthoticFittingCheckout33: string,
    PoRehabilitationTreatmentOrthoticFittingCheckout33Disabled: boolean,
    PoRehabilitationTreatmentOrthoticFittingCheckout35: string,
    PoRehabilitationTreatmentOrthoticFittingCheckout35Disabled: boolean,
    PoRehabilitationTreatmentOrthoticFittingCheckout37: string,
    PoRehabilitationTreatmentOrthoticFittingCheckout37Disabled: boolean,
    PoRehabilitationTreatmentOrthoticFittingCheckout44: string,
    PoRehabilitationTreatmentOrthoticFittingCheckout44Disabled: boolean,
    PoRehabilitationTreatmentOrthoticFittingCheckout45: string,
    PoRehabilitationTreatmentOrthoticFittingCheckout45Disabled: boolean,
    SaveRequestDisabled: boolean,
}

export const PORehabilitationTreatmentOrthoticFittingandCheckoutInitialViewData: PORehabilitationTreatmentOrthoticFittingandCheckoutViewDataType = {
    PoRehabilitationTreatmentOrthoticFittingCheckout2: "",
    PoRehabilitationTreatmentOrthoticFittingCheckout2Disabled: false,
    PoRehabilitationTreatmentOrthoticFittingCheckout4: "",
    PoRehabilitationTreatmentOrthoticFittingCheckout4Disabled: false,
    PoRehabilitationTreatmentOrthoticFittingCheckout6: "",
    PoRehabilitationTreatmentOrthoticFittingCheckout6Disabled: false,
    PoRehabilitationTreatmentOrthoticFittingCheckout8: "",
    PoRehabilitationTreatmentOrthoticFittingCheckout8Disabled: false,
    Ct4PainScore: "",
    Ct4PainScoreDisabled: false,
    Ct4PainScoreOptions: [{key: 0, value: "A", text: "A"}],
    PoRehabilitationTreatmentOrthoticFittingCheckout13: "",
    PoRehabilitationTreatmentOrthoticFittingCheckout13Disabled: false,
    PoRehabilitationTreatmentOrthoticFittingCheckout16: "",
    PoRehabilitationTreatmentOrthoticFittingCheckout16Disabled: false,
    PoRehabilitationTreatmentOrthoticFittingCheckout18: false,
    PoRehabilitationTreatmentOrthoticFittingCheckout18Disabled: false,
    PoRehabilitationTreatmentOrthoticFittingCheckout20: false,
    PoRehabilitationTreatmentOrthoticFittingCheckout20Disabled: false,
    PoRehabilitationTreatmentOrthoticFittingCheckout23: "",
    PoRehabilitationTreatmentOrthoticFittingCheckout23Disabled: false,
    PoRehabilitationTreatmentOrthoticFittingCheckout24: false,
    PoRehabilitationTreatmentOrthoticFittingCheckout24Disabled: false,
    PoRehabilitationTreatmentOrthoticFittingCheckout26: "",
    PoRehabilitationTreatmentOrthoticFittingCheckout26Disabled: false,
    PoRehabilitationTreatmentOrthoticFittingCheckout28: false,
    PoRehabilitationTreatmentOrthoticFittingCheckout28Disabled: false,
    PoRehabilitationTreatmentOrthoticFittingCheckout30: false,
    PoRehabilitationTreatmentOrthoticFittingCheckout30Disabled: false,
    PoRehabilitationTreatmentOrthoticFittingCheckout33: "",
    PoRehabilitationTreatmentOrthoticFittingCheckout33Disabled: false,
    PoRehabilitationTreatmentOrthoticFittingCheckout35: "",
    PoRehabilitationTreatmentOrthoticFittingCheckout35Disabled: false,
    PoRehabilitationTreatmentOrthoticFittingCheckout37: "",
    PoRehabilitationTreatmentOrthoticFittingCheckout37Disabled: false,
    PoRehabilitationTreatmentOrthoticFittingCheckout44: "",
    PoRehabilitationTreatmentOrthoticFittingCheckout44Disabled: false,
    PoRehabilitationTreatmentOrthoticFittingCheckout45: "",
    PoRehabilitationTreatmentOrthoticFittingCheckout45Disabled: false,
    SaveRequestDisabled: false,
}

const Sub = (props: any) => {
    const children = React.Children.toArray(props.children)
    return (
        <div className={styles.container}>
            <div className={styles.PoRehabilitationTreatmentOrthoticFittingCheckout1}>
                <div
                    style={{backgroundColor: "#F3F3F3", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFittingCheckout2}>
                <Input
                    value={props.viewData.PoRehabilitationTreatmentOrthoticFittingCheckout2}
                    disabled={ props.viewData.PoRehabilitationTreatmentOrthoticFittingCheckout2Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PORehabilitationTreatmentOrthoticFittingandCheckout',
                            name: 'PoRehabilitationTreatmentOrthoticFittingCheckout2',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFittingCheckout3}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p><strong>Problem*</strong></p>
                </div>
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFittingCheckout4}>
                <Input
                    value={props.viewData.PoRehabilitationTreatmentOrthoticFittingCheckout4}
                    disabled={ props.viewData.PoRehabilitationTreatmentOrthoticFittingCheckout4Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PORehabilitationTreatmentOrthoticFittingandCheckout',
                            name: 'PoRehabilitationTreatmentOrthoticFittingCheckout4',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFittingCheckout5}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Goal of Treatment*</strong></p>
                </div>
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFittingCheckout6}>
                <Input
                    value={props.viewData.PoRehabilitationTreatmentOrthoticFittingCheckout6}
                    disabled={ props.viewData.PoRehabilitationTreatmentOrthoticFittingCheckout6Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PORehabilitationTreatmentOrthoticFittingandCheckout',
                            name: 'PoRehabilitationTreatmentOrthoticFittingCheckout6',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFittingCheckout7}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p><strong>Treatment*</strong></p>
                </div>
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFittingCheckout8}>
                <TextArea
                    value={props.viewData.PoRehabilitationTreatmentOrthoticFittingCheckout8}
                    disabled={ props.viewData.PoRehabilitationTreatmentOrthoticFittingCheckout8Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PORehabilitationTreatmentOrthoticFittingandCheckout',
                            name: 'PoRehabilitationTreatmentOrthoticFittingCheckout8',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </TextArea>
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFittingCheckout9}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Post of treatment</strong></p>
                </div>
            </div>
            {/* <div className={styles.Ct4PainScore}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct4PainScore}
                    disabled={ props.viewData.Ct4PainScoreDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "PORehabilitationTreatmentOrthoticFittingandCheckout",
                                name: "Ct4PainScore",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct4PainScoreOptions}
                />
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFittingCheckout13}>
                <Input
                    value={props.viewData.PoRehabilitationTreatmentOrthoticFittingCheckout13}
                    disabled={ props.viewData.PoRehabilitationTreatmentOrthoticFittingCheckout13Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PORehabilitationTreatmentOrthoticFittingandCheckout',
                            name: 'PoRehabilitationTreatmentOrthoticFittingCheckout13',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFittingCheckout14}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Pain score</strong></p>
                </div>
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFittingCheckout15}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p><strong>Other</strong></p>
                </div>
            </div> */}
            <div className={styles.PoRehabilitationTreatmentOrthoticFittingCheckout16}>
                <TextArea
                    value={props.viewData.PoRehabilitationTreatmentOrthoticFittingCheckout16}
                    disabled={ props.viewData.PoRehabilitationTreatmentOrthoticFittingCheckout16Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PORehabilitationTreatmentOrthoticFittingandCheckout',
                            name: 'PoRehabilitationTreatmentOrthoticFittingCheckout16',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </TextArea>
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFittingCheckout17}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p><strong>Note</strong></p>
                </div>
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFittingCheckout18}>
                <Checkbox 
                    style={{ height: "100%", width: "100%"}}
                    checked={props.viewData.PoRehabilitationTreatmentOrthoticFittingCheckout18}
                    disabled={ props.viewData.PoRehabilitationTreatmentOrthoticFittingCheckout18Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PORehabilitationTreatmentOrthoticFittingandCheckout",
                                name: "PoRehabilitationTreatmentOrthoticFittingCheckout18"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFittingCheckout19}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>N/A</p>
                </div>
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFittingCheckout20}>
                <Radio 
                    style={{ height: "100%", width: "100%"}}
                    checked={props.viewData.PoRehabilitationTreatmentOrthoticFittingCheckout20}
                    disabled={ props.viewData.PoRehabilitationTreatmentOrthoticFittingCheckout20Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "radio",
                            params: {
                                view: "PORehabilitationTreatmentOrthoticFittingandCheckout",
                                name: "PoRehabilitationTreatmentOrthoticFittingCheckout20"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFittingCheckout21}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Note to doctor</strong></p>
                </div>
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFittingCheckout22}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Yes, able to perform treatment follow doctor’s presciption</p>
                </div>
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFittingCheckout23}>
                <Input
                    value={props.viewData.PoRehabilitationTreatmentOrthoticFittingCheckout23}
                    disabled={ props.viewData.PoRehabilitationTreatmentOrthoticFittingCheckout23Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PORehabilitationTreatmentOrthoticFittingandCheckout',
                            name: 'PoRehabilitationTreatmentOrthoticFittingCheckout23',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFittingCheckout24}>
                <Radio 
                    style={{ height: "100%", width: "100%"}}
                    checked={props.viewData.PoRehabilitationTreatmentOrthoticFittingCheckout24}
                    disabled={ props.viewData.PoRehabilitationTreatmentOrthoticFittingCheckout24Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "radio",
                            params: {
                                view: "PORehabilitationTreatmentOrthoticFittingandCheckout",
                                name: "PoRehabilitationTreatmentOrthoticFittingCheckout24"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFittingCheckout25}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>No,</p>
                </div>
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFittingCheckout26}>
                <Input
                    value={props.viewData.PoRehabilitationTreatmentOrthoticFittingCheckout26}
                    disabled={ props.viewData.PoRehabilitationTreatmentOrthoticFittingCheckout26Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PORehabilitationTreatmentOrthoticFittingandCheckout',
                            name: 'PoRehabilitationTreatmentOrthoticFittingCheckout26',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFittingCheckout27}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p><strong>Instruction*</strong></p>
                </div>
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFittingCheckout28}>
                <Checkbox 
                    style={{ height: "100%", width: "100%"}}
                    checked={props.viewData.PoRehabilitationTreatmentOrthoticFittingCheckout28}
                    disabled={ props.viewData.PoRehabilitationTreatmentOrthoticFittingCheckout28Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PORehabilitationTreatmentOrthoticFittingandCheckout",
                                name: "PoRehabilitationTreatmentOrthoticFittingCheckout28"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFittingCheckout29}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Patient and/or family was/were informed about instruction and plan of treatment</p>
                </div>
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFittingCheckout30}>
                <Checkbox 
                    style={{ height: "100%", width: "100%"}}
                    checked={props.viewData.PoRehabilitationTreatmentOrthoticFittingCheckout30}
                    disabled={ props.viewData.PoRehabilitationTreatmentOrthoticFittingCheckout30Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PORehabilitationTreatmentOrthoticFittingandCheckout",
                                name: "PoRehabilitationTreatmentOrthoticFittingCheckout30"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFittingCheckout31}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Need to be reviewed</p>
                </div>
            </div>
            <div className={styles.SaveRequest}>
                <Button
                    disabled={ props.viewData.SaveRequestDisabled }
                    onClick={(e) => { props.onEvent({
                        message: 'clickButton',
                        params: {
                            view: 'PORehabilitationTreatmentOrthoticFittingandCheckout',
                            name: 'SaveRequest'
                        }
                    })}}
                    color="green"
                    style={{ height: "100%", width: "100%"}}
                >
                    Save
                </Button>
            </div>
            <div className={styles.PoRehabilitationTreatmentOrthoticFittingCheckout49}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
        </div>
    )
}

export default Sub

const getTsName = (cssClass: string) => {
    return cssClass.split('-').map((s) => (
        s.charAt(0).toUpperCase() + s.slice(1)
    )).join('')
}
