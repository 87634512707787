import React, {useEffect, useState, useRef} from "react";
import Cookies from "js-cookie";
import { useHistory, useLocation } from "react-router-dom";
import PasswordLoginManager from "../../../apps/IsHealth/Common/PasswordLogin/PasswordLoginManager";
import {ChatController} from "../Chat";
import { useIntl } from "react-intl";


type ResolveChatProp = {
  controller: ChatController,
  apiToken: string,
  onLoginSuccess: (token: string) => void,
}


const ResolveChat = (props: ResolveChatProp) => {
  const intl = useIntl();
  const history = useHistory();
  const params = new URLSearchParams(useLocation().search);
  const hisDivision = params.get("division");
  const hisHN = params.get("hn");
  const authVer = params.get("auth_ver");
  const application = params.get("application");
  const email = params.get("email");
  const firstName = params.get("first_name");
  const lastName = params.get("last_name");
  const time = params.get("time");
  const sign = params.get("sign");
  const role = params.get("role");
  const division = params.get("division");

  const [divisionId, setDivisionId] = useState(null);
  const [errorMsg, setErrorMsg] = useState<any>(null);
  const isMounted = useRef(true);
  const manager = new PasswordLoginManager();

  const getCredential = async () => {
    setErrorMsg(null);
    const data = await manager.requestTokenByCredential({
      email,
      application,
      firstName,
      lastName,
      time,
      sign,
      authVer,
      role,
      division
    });
    if(data){
      props.onLoginSuccess(data);
    } else {
      setErrorMsg("Access denied");
    }
  };

  useEffect(() => {
    if(authVer && email && firstName && lastName && time && sign && role && division){
      getCredential();
    } else {
      setErrorMsg("Access denied");
    }
  }, [authVer, email, firstName, lastName, time, sign, role, division]);

  useEffect(() => {
    const getResolveChatChannel = async () => {
      setErrorMsg(null);
      const [data, error, network] = await props.controller.getResolveChatChannel({
        divisionId: divisionId,
        apiToken: Cookies.get("apiToken") ? Cookies.get("apiToken") : props.apiToken,
        hn: hisHN
      });
      if (isMounted.current) {
        if (error) {
          console.log(error);
          setErrorMsg("เปิดแชทไม่สำเร็จ ไม่พบ Encounter นี้");
        } else {
          console.log({data,error,network});
          history.push(`/Chat/${data.encounter}/${data.chat_channel_id}/`);
        }
      }
    };
    if(divisionId && hisHN && (props.apiToken || Cookies.get("apiToken"))){
      getResolveChatChannel();
    }
  }, [divisionId, hisHN, props.apiToken, Cookies.get("apiToken")]);
  
  useEffect(() => {
    const getResolveDivisionProfile = async () => {
      setErrorMsg(null);
      const [data, error, network] = await props.controller.getDivisionProfile({
        divisionCode: hisDivision,
        apiToken: Cookies.get("apiToken") ? Cookies.get("apiToken") : props.apiToken
      });
      if (isMounted.current) {
        if(data?.items && data.items[0]?.division){
          setDivisionId(data.items[0].division);
        } else if (data?.items && data.items.length === 0){
          setErrorMsg("ผู้ใช้ไม่ได้อยู่ในหน่วยงานนี้");
        } else {
          let err = error;
          if(typeof(err) === "object"){
            for(let key in error){
              err = `${key}: ${error[key]} \n`;
            }
          }
          else if (error.length > 200) {
            console.log(network.response);
            err = `${network.response.status}: ${network.response.statusText}`;
          }
          setErrorMsg(err);
        }
      }
    };

    if (hisDivision && (props.apiToken || Cookies.get("apiToken"))) {
      getResolveDivisionProfile();
    }
  }, [hisDivision, props.apiToken, Cookies.get("apiToken"), props.controller]);

  if(errorMsg){
    return <h1 className='resolve-chat'>{errorMsg}</h1>;
  }
  if(!hisDivision || !hisHN){
    return <h1 className='resolve-chat'>{intl.formatMessage({ id: "ไม่สามารถระบุหน่วยงาน หรือผู้ป่วยได้" })}</h1>;
  }
  return (
    <h1 className='resolve-chat'>Redirecting to Chat...</h1>
  );
};

export default ResolveChat;