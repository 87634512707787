import React from 'react'
import {
   Checkbox,
   Dropdown,
   Input,
} from 'semantic-ui-react'



import styles from './CardPTPhysicalexaminationOrthopedicOPDTOP.module.css'

export interface CardPTPhysicalexaminationOrthopedicOPDTOPViewDataType {
    Ct5Observation: string,
    Ct5ObservationDisabled: boolean,
    Ct5ObservationOptions: any,
    PtPhysicalOrthoOpdTop6: string,
    PtPhysicalOrthoOpdTop6Disabled: boolean,
    PtPhysicalOrthoOpdTop8: string,
    PtPhysicalOrthoOpdTop8Disabled: boolean,
    Ct10GeneralAppearance: string,
    Ct10GeneralAppearanceDisabled: boolean,
    Ct10GeneralAppearanceOptions: any,
    Ct12Consciousness: string,
    Ct12ConsciousnessDisabled: boolean,
    Ct12ConsciousnessOptions: any,
    Ct15Communication: string,
    Ct15CommunicationDisabled: boolean,
    Ct15CommunicationOptions: any,
    Ct15CoOperation: string,
    Ct15CoOperationDisabled: boolean,
    Ct15CoOperationOptions: any,
    PtPhysicalOrthoOpdTop25: boolean,
    PtPhysicalOrthoOpdTop25Disabled: boolean,
    Ct3BedMobilityTestRolling: string,
    Ct3BedMobilityTestRollingDisabled: boolean,
    Ct3BedMobilityTestRollingOptions: any,
    PtPhysicalOrthoOpdTop29: string,
    PtPhysicalOrthoOpdTop29Disabled: boolean,
    PtPhysicalOrthoOpdTop29Options: any,
    PtPhysicalOrthoOpdTop35: string,
    PtPhysicalOrthoOpdTop35Disabled: boolean,
    PtPhysicalOrthoOpdTop35Options: any,
    PtPhysicalOrthoOpdTop38: string,
    PtPhysicalOrthoOpdTop38Disabled: boolean,
    PtPhysicalOrthoOpdTop38Options: any,
    PtPhysicalOrthoOpdTop41: string,
    PtPhysicalOrthoOpdTop41Disabled: boolean,
    PtPhysicalOrthoOpdTop41Options: any,
    PtPhysicalOrthoOpdTop44: string,
    PtPhysicalOrthoOpdTop44Disabled: boolean,
    PtPhysicalOrthoOpdTop44Options: any,
    PtPhysicalOrthoOpdTop47: string,
    PtPhysicalOrthoOpdTop47Disabled: boolean,
    PtPhysicalOrthoOpdTop48: boolean,
    PtPhysicalOrthoOpdTop48Disabled: boolean,
    PtPhysicalOrthoOpdTop49: string,
    PtPhysicalOrthoOpdTop49Disabled: boolean,
    PtPhysicalOrthoOpdTop51: boolean,
    PtPhysicalOrthoOpdTop51Disabled: boolean,
    PtPhysicalOrthoOpdTop52: string,
    PtPhysicalOrthoOpdTop52Disabled: boolean,
    PtPhysicalOrthoOpdTop54: boolean,
    PtPhysicalOrthoOpdTop54Disabled: boolean,
    PtPhysicalOrthoOpdTop55: string,
    PtPhysicalOrthoOpdTop55Disabled: boolean,
    PtPhysicalOrthoOpdTop57: boolean,
    PtPhysicalOrthoOpdTop57Disabled: boolean,
    PtPhysicalOrthoOpdTop59: boolean,
    PtPhysicalOrthoOpdTop59Disabled: boolean,
}

export const CardPTPhysicalexaminationOrthopedicOPDTOPInitialViewData: CardPTPhysicalexaminationOrthopedicOPDTOPViewDataType = {
    Ct5Observation: "",
    Ct5ObservationDisabled: false,
    Ct5ObservationOptions: [{key: 0, value: "A", text: "A"}],
    PtPhysicalOrthoOpdTop6: "",
    PtPhysicalOrthoOpdTop6Disabled: false,
    PtPhysicalOrthoOpdTop8: "",
    PtPhysicalOrthoOpdTop8Disabled: false,
    Ct10GeneralAppearance: "",
    Ct10GeneralAppearanceDisabled: false,
    Ct10GeneralAppearanceOptions: [{key: 0, value: "A", text: "A"}],
    Ct12Consciousness: "",
    Ct12ConsciousnessDisabled: false,
    Ct12ConsciousnessOptions: [{key: 0, value: "A", text: "A"}],
    Ct15Communication: "",
    Ct15CommunicationDisabled: false,
    Ct15CommunicationOptions: [{key: 0, value: "A", text: "A"}],
    Ct15CoOperation: "",
    Ct15CoOperationDisabled: false,
    Ct15CoOperationOptions: [{key: 0, value: "A", text: "A"}],
    PtPhysicalOrthoOpdTop25: false,
    PtPhysicalOrthoOpdTop25Disabled: false,
    Ct3BedMobilityTestRolling: "",
    Ct3BedMobilityTestRollingDisabled: false,
    Ct3BedMobilityTestRollingOptions: [{key: 0, value: "A", text: "A"}],
    PtPhysicalOrthoOpdTop29: "",
    PtPhysicalOrthoOpdTop29Disabled: false,
    PtPhysicalOrthoOpdTop29Options: [{key: 0, value: "A", text: "A"}],
    PtPhysicalOrthoOpdTop35: "",
    PtPhysicalOrthoOpdTop35Disabled: false,
    PtPhysicalOrthoOpdTop35Options: [{key: 0, value: "A", text: "A"}],
    PtPhysicalOrthoOpdTop38: "",
    PtPhysicalOrthoOpdTop38Disabled: false,
    PtPhysicalOrthoOpdTop38Options: [{key: 0, value: "A", text: "A"}],
    PtPhysicalOrthoOpdTop41: "",
    PtPhysicalOrthoOpdTop41Disabled: false,
    PtPhysicalOrthoOpdTop41Options: [{key: 0, value: "A", text: "A"}],
    PtPhysicalOrthoOpdTop44: "",
    PtPhysicalOrthoOpdTop44Disabled: false,
    PtPhysicalOrthoOpdTop44Options: [{key: 0, value: "A", text: "A"}],
    PtPhysicalOrthoOpdTop47: "",
    PtPhysicalOrthoOpdTop47Disabled: false,
    PtPhysicalOrthoOpdTop48: false,
    PtPhysicalOrthoOpdTop48Disabled: false,
    PtPhysicalOrthoOpdTop49: "",
    PtPhysicalOrthoOpdTop49Disabled: false,
    PtPhysicalOrthoOpdTop51: false,
    PtPhysicalOrthoOpdTop51Disabled: false,
    PtPhysicalOrthoOpdTop52: "",
    PtPhysicalOrthoOpdTop52Disabled: false,
    PtPhysicalOrthoOpdTop54: false,
    PtPhysicalOrthoOpdTop54Disabled: false,
    PtPhysicalOrthoOpdTop55: "",
    PtPhysicalOrthoOpdTop55Disabled: false,
    PtPhysicalOrthoOpdTop57: false,
    PtPhysicalOrthoOpdTop57Disabled: false,
    PtPhysicalOrthoOpdTop59: false,
    PtPhysicalOrthoOpdTop59Disabled: false,
}

const Sub = (props: any) => {
    const children = React.Children.toArray(props.children)
    return (
        <div className={styles.container}>
            <div className={styles.PtPhysicalOrthoOpdTop2}>
                <div
                    style={{background: "#F3F3F3", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.Ct5Observation}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct5Observation}
                    disabled={ props.viewData.Ct5ObservationDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardPTPhysicalexaminationOrthopedicOPDTOP",
                                name: "Ct5Observation",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct5ObservationOptions}
                />
            </div>
            <div className={styles.PtPhysicalOrthoOpdTop6}>
                <Input
                    value={props.viewData.PtPhysicalOrthoOpdTop6}
                    disabled={ props.viewData.PtPhysicalOrthoOpdTop6Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPTPhysicalexaminationOrthopedicOPDTOP',
                            name: 'PtPhysicalOrthoOpdTop6',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtPhysicalOrthoOpdTop7}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Observation</strong></p>
                </div>
            </div>
            <div className={styles.PtPhysicalOrthoOpdTop8}>
                <Input
                    value={props.viewData.PtPhysicalOrthoOpdTop8}
                    disabled={ props.viewData.PtPhysicalOrthoOpdTop8Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPTPhysicalexaminationOrthopedicOPDTOP',
                            name: 'PtPhysicalOrthoOpdTop8',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.Ct10GeneralAppearance}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct10GeneralAppearance}
                    disabled={ props.viewData.Ct10GeneralAppearanceDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardPTPhysicalexaminationOrthopedicOPDTOP",
                                name: "Ct10GeneralAppearance",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct10GeneralAppearanceOptions}
                />
            </div>
            <div className={styles.PtPhysicalOrthoOpdTop12}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>General appearance</strong></p>
                </div>
            </div>
            <div className={styles.Ct12Consciousness}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct12Consciousness}
                    disabled={ props.viewData.Ct12ConsciousnessDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardPTPhysicalexaminationOrthopedicOPDTOP",
                                name: "Ct12Consciousness",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct12ConsciousnessOptions}
                />
            </div>
            <div className={styles.Ct15Communication}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct15Communication}
                    disabled={ props.viewData.Ct15CommunicationDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardPTPhysicalexaminationOrthopedicOPDTOP",
                                name: "Ct15Communication",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct15CommunicationOptions}
                />
            </div>
            <div className={styles.Ct15CoOperation}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct15CoOperation}
                    disabled={ props.viewData.Ct15CoOperationDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardPTPhysicalexaminationOrthopedicOPDTOP",
                                name: "Ct15CoOperation",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct15CoOperationOptions}
                />
            </div>
            <div className={styles.PtPhysicalOrthoOpdTop22}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Consciousness</strong></p>
                </div>
            </div>
            <div className={styles.PtPhysicalOrthoOpdTop23}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Communication</strong></p>
                </div>
            </div>
            <div className={styles.PtPhysicalOrthoOpdTop24}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Co-operation</strong></p>
                </div>
            </div>
            <div className={styles.PtPhysicalOrthoOpdTop25}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtPhysicalOrthoOpdTop25}
                    disabled={ props.viewData.PtPhysicalOrthoOpdTop25Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTPhysicalexaminationOrthopedicOPDTOP",
                                name: "PtPhysicalOrthoOpdTop25"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.Ct3BedMobilityTestRolling}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct3BedMobilityTestRolling}
                    disabled={ props.viewData.Ct3BedMobilityTestRollingDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardPTPhysicalexaminationOrthopedicOPDTOP",
                                name: "Ct3BedMobilityTestRolling",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct3BedMobilityTestRollingOptions}
                />
            </div>
            <div className={styles.PtPhysicalOrthoOpdTop29}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.PtPhysicalOrthoOpdTop29}
                    disabled={ props.viewData.PtPhysicalOrthoOpdTop29Disabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardPTPhysicalexaminationOrthopedicOPDTOP",
                                name: "PtPhysicalOrthoOpdTop29",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.PtPhysicalOrthoOpdTop29Options}
                />
            </div>
            <div className={styles.PtPhysicalOrthoOpdTop32}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Functional test</strong></p>
                </div>
            </div>
            <div className={styles.PtPhysicalOrthoOpdTop33}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Rolling</p>
                </div>
            </div>
            <div className={styles.PtPhysicalOrthoOpdTop34}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>independent</p>
                </div>
            </div>
            <div className={styles.PtPhysicalOrthoOpdTop35}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.PtPhysicalOrthoOpdTop35}
                    disabled={ props.viewData.PtPhysicalOrthoOpdTop35Disabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardPTPhysicalexaminationOrthopedicOPDTOP",
                                name: "PtPhysicalOrthoOpdTop35",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.PtPhysicalOrthoOpdTop35Options}
                />
            </div>
            <div className={styles.PtPhysicalOrthoOpdTop38}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.PtPhysicalOrthoOpdTop38}
                    disabled={ props.viewData.PtPhysicalOrthoOpdTop38Disabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardPTPhysicalexaminationOrthopedicOPDTOP",
                                name: "PtPhysicalOrthoOpdTop38",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.PtPhysicalOrthoOpdTop38Options}
                />
            </div>
            <div className={styles.PtPhysicalOrthoOpdTop40}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Supine to sit</p>
                </div>
            </div>
            <div className={styles.PtPhysicalOrthoOpdTop41}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.PtPhysicalOrthoOpdTop41}
                    disabled={ props.viewData.PtPhysicalOrthoOpdTop41Disabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardPTPhysicalexaminationOrthopedicOPDTOP",
                                name: "PtPhysicalOrthoOpdTop41",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.PtPhysicalOrthoOpdTop41Options}
                />
            </div>
            <div className={styles.PtPhysicalOrthoOpdTop44}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.PtPhysicalOrthoOpdTop44}
                    disabled={ props.viewData.PtPhysicalOrthoOpdTop44Disabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardPTPhysicalexaminationOrthopedicOPDTOP",
                                name: "PtPhysicalOrthoOpdTop44",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.PtPhysicalOrthoOpdTop44Options}
                />
            </div>
            <div className={styles.PtPhysicalOrthoOpdTop46}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Sit to stand</p>
                </div>
            </div>
            <div className={styles.PtPhysicalOrthoOpdTop47}>
                <Input
                    value={props.viewData.PtPhysicalOrthoOpdTop47}
                    disabled={ props.viewData.PtPhysicalOrthoOpdTop47Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPTPhysicalexaminationOrthopedicOPDTOP',
                            name: 'PtPhysicalOrthoOpdTop47',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtPhysicalOrthoOpdTop48}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtPhysicalOrthoOpdTop48}
                    disabled={ props.viewData.PtPhysicalOrthoOpdTop48Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTPhysicalexaminationOrthopedicOPDTOP",
                                name: "PtPhysicalOrthoOpdTop48"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtPhysicalOrthoOpdTop49}>
                <Input
                    value={props.viewData.PtPhysicalOrthoOpdTop49}
                    disabled={ props.viewData.PtPhysicalOrthoOpdTop49Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPTPhysicalexaminationOrthopedicOPDTOP',
                            name: 'PtPhysicalOrthoOpdTop49',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtPhysicalOrthoOpdTop50}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Muscle length</strong></p>
                </div>
            </div>
            <div className={styles.PtPhysicalOrthoOpdTop51}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtPhysicalOrthoOpdTop51}
                    disabled={ props.viewData.PtPhysicalOrthoOpdTop51Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTPhysicalexaminationOrthopedicOPDTOP",
                                name: "PtPhysicalOrthoOpdTop51"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtPhysicalOrthoOpdTop52}>
                <Input
                    value={props.viewData.PtPhysicalOrthoOpdTop52}
                    disabled={ props.viewData.PtPhysicalOrthoOpdTop52Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPTPhysicalexaminationOrthopedicOPDTOP',
                            name: 'PtPhysicalOrthoOpdTop52',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtPhysicalOrthoOpdTop53}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Normal</p>
                </div>
            </div>
            <div className={styles.PtPhysicalOrthoOpdTop54}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtPhysicalOrthoOpdTop54}
                    disabled={ props.viewData.PtPhysicalOrthoOpdTop54Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTPhysicalexaminationOrthopedicOPDTOP",
                                name: "PtPhysicalOrthoOpdTop54"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtPhysicalOrthoOpdTop55}>
                <Input
                    value={props.viewData.PtPhysicalOrthoOpdTop55}
                    disabled={ props.viewData.PtPhysicalOrthoOpdTop55Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPTPhysicalexaminationOrthopedicOPDTOP',
                            name: 'PtPhysicalOrthoOpdTop55',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtPhysicalOrthoOpdTop56}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Tightness</p>
                </div>
            </div>
            <div className={styles.PtPhysicalOrthoOpdTop57}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtPhysicalOrthoOpdTop57}
                    disabled={ props.viewData.PtPhysicalOrthoOpdTop57Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTPhysicalexaminationOrthopedicOPDTOP",
                                name: "PtPhysicalOrthoOpdTop57"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtPhysicalOrthoOpdTop58}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Shortening</p>
                </div>
            </div>
            <div className={styles.PtPhysicalOrthoOpdTop59}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtPhysicalOrthoOpdTop59}
                    disabled={ props.viewData.PtPhysicalOrthoOpdTop59Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTPhysicalexaminationOrthopedicOPDTOP",
                                name: "PtPhysicalOrthoOpdTop59"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtPhysicalOrthoOpdTop60}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Contracture</p>
                </div>
            </div>
            <div className={styles.PtPhysicalOrthoOpdTop61}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>N/A</p>
                </div>
            </div>
        </div>
    )
}

export default Sub

const getTsName = (cssClass: string) => {
    return cssClass.split('-').map((s) => (
        s.charAt(0).toUpperCase() + s.slice(1)
    )).join('')
}
