import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  FormField,
  Dropdown,
  Input,
  TextArea,
  Checkbox
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardFoodRequestUX = (props: any) => {
    return(
      <div>
        <div
          style={{ height: "50px" }}>
          
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(4, 1fr)","gridTemplateColumns":"repeat(34, 1fr)"}}>
            <div
              style={{ gridRow: "2/4", gridColumn: "2/18", fontWeight: "bold", fontSize: "17px", borderBottom: "3px solid grey"}}>
              สั่งอาหาร
            </div>
            <div
              style={{ gridRow: "2/4", gridColumn: "18/34", fontSize: "17px", borderBottom: "3px solid grey", textAlign: "right"}}>
              {props.status}
            </div>
          </div>
        </div>
        <div
          style={{ display: "flex" }}>
          
          <div
            style={{ width: "60%" }}>
            
            <div
              style={{ margin: "0px 40px 0px 40px" }}>
              
              <Form>
                <FormGroup
                  inline={true}>
                  <FormField
                    width={3}>
                    <div
                      style={{ fontWeight: "bold" }}>
                      
                      <label>
                        ชนิดอาหาร
                      </label>
                      <label
                        style={{ color: "red" }}>
                        *
                      </label>
                    </div>
                  </FormField>
                  <FormField
                    style={{ width: "100%"}}>
                    <div>
                      
                      <Dropdown
                        clearable={true}
                        disabled={props.formReadOnly || !!props.FoodRequestSequence?.id}
                        fluid={true}
                        name="food_type"
                        onChange={props.onChangeData}
                        options={props.foodRequestOptions?.foodTypeOptions || []}
                        search={true}
                        selection={true}
                        value={props.FoodRequestSequence?.food_type || ""}>
                      </Dropdown>
                    </div>
                  </FormField>
                </FormGroup>
                <FormGroup
                  inline={true}
                  style={{ display: props.foodType !== "R" && "none",  }}>
                  <FormField
                    width={3}>
                    <div>
                      
                    </div>
                  </FormField>
                  <FormField
                    style={{ width: "100%" }}>
                    <div>
                      
                      <Dropdown
                        allowAdditions={true}
                        clearable={true}
                        disabled={props.formReadOnly || !!props.FoodRequestSequence?.id}
                        fluid={true}
                        multiple={true}
                        name="routine_type"
                        onAddItem={props.onAddOptions}
                        onChange={props.onChangeData}
                        options={props.foodRequestOptions?.routineTypeOptions || []}
                        search={true}
                        selection={true}
                        value={props.FoodRequestSequence?.routine_type || []}>
                      </Dropdown>
                    </div>
                  </FormField>
                </FormGroup>
                <FormGroup
                  inline={true}>
                  <FormField
                    width={3}>
                    <div
                      style={{ fontWeight: "bold" }}>
                      
                      <label>
                        จำนวนมื้อ
                      </label>
                      <label
                        style={{ color: "red" }}>
                        *
                      </label>
                    </div>
                  </FormField>
                  <FormField
                    style={{ width: "100%" }}>
                    <div
                      style={{ display: "flex", alignItems: "center" }}>
                      
                      <div
                        style={{ width: "25%" }}>
                        
                        <Input
                          disabled={props.formReadOnly || !!props.FoodRequestSequence?.id}
                          fluid={true}
                          name="quantity"
                          onChange={props.onChangeData}
                          type="number"
                          value={props.FoodRequestSequence?.quantity || ""}>
                        </Input>
                      </div>
                      <div
                        style={{ width: "100%", marginLeft: "20px" }}>
                        
                        <Checkbox
                          checked={props.FoodRequestSequence?.is_extra_meal}
                          disabled={props.formReadOnly || !!props.FoodRequestSequence?.id}
                          label="มื้อ Extra"
                          name="is_extra_meal"
                          onChange={props.onChangeData}>
                        </Checkbox>
                        <label
                          style={{ color: "red" }}>
                          *
                        </label>
                      </div>
                    </div>
                  </FormField>
                </FormGroup>
                <FormGroup
                  inline={true}>
                  <FormField
                    width={3}>
                    <div
                      style={{ fontWeight: "bold" }}>
                      
                      <label>
                        มื้ออาหาร
                      </label>
                      <label
                        style={{ color: "red" }}>
                        *
                      </label>
                    </div>
                  </FormField>
                  <FormField
                    style={{ width: "100%" }}>
                    <div>
                      
                      <div
                        style={{ display: props.foodType === "G" ? "" : "none" }  }>
                        {props.generalTimeComponent}
                      </div>
                      <div
                        style={{ display: props.foodType === "R" ? "" : "none" }  }>
                        
                        <Dropdown
                          clearable={true}
                          disabled={props.formReadOnly || !!props.FoodRequestSequence?.id}
                          fluid={true}
                          multiple={true}
                          name="routine_time"
                          onChange={props.onChangeData}
                          options={props.foodRequestOptions?.routineTimeOptions || []}
                          search={true}
                          selection={true}
                          value={props.FoodRequestSequence?.routine_time || []}>
                        </Dropdown>
                      </div>
                    </div>
                  </FormField>
                </FormGroup>
                <div
                  style={{ display: props.foodType === "R" ? "" : "none",  }}>
                  
                  <FormGroup
                    inline={true}>
                    <FormField
                      width={3}>
                      <div
                        style={{ fontWeight: "bold" }}>
                        
                        <label>
                          ปริมาตร
                        </label>
                        <label
                          style={{ color: "red" }}>
                          *
                        </label>
                      </div>
                    </FormField>
                    <FormField
                      style={{ width: "100%"}}
                      width={7}>
                      <div
                        style={{ display: "flex", width: "100%", margin: "0px 0px 0px 10px" }}>
                        
                        <Input
                          disabled={props.formReadOnly || !!props.FoodRequestSequence?.id}
                          fluid={true}
                          name="routine_size"
                          onChange={props.onChangeData}
                          style={{ display: props.foodType === "R" ? "" : "none" }}
                          type="number"
                          value={props.FoodRequestSequence?.routine_size || ""}>
                        </Input>
                        <Dropdown
                          clearable={true}
                          disabled={props.formReadOnly || !!props.FoodRequestSequence?.id}
                          fluid={true}
                          name="routine_unit"
                          onChange={props.onChangeData}
                          options={props.foodRequestOptions?.routineUnitOptions || []}
                          search={true}
                          selection={true}
                          style={{ display: props.foodType === "R" ? "" : "none" }}
                          value={props.FoodRequestSequence?.routine_unit || ""}>
                        </Dropdown>
                      </div>
                    </FormField>
                    <FormField
                      width={3}>
                      <div
                        style={{ fontWeight: "bold", justifyContent: "right", display: "flex", width: "100%" }}>
                        
                        <label>
                          ความเข้มข้น
                        </label>
                      </div>
                    </FormField>
                    <FormField
                      style={{ width: "100%"}}
                      width={7}>
                      <div
                        style={{ display: "flex", width: "100%" }}>
                        
                        <Input
                          disabled={props.formReadOnly || !!props.FoodRequestSequence?.id}
                          fluid={true}
                          name="intensity"
                          onChange={props.onChangeData}
                          style={{ display: props.foodType === "R" ? "" : "none" }}
                          type="number"
                          value={props.FoodRequestSequence?.intensity || ""}>
                        </Input>
                        <Dropdown
                          clearable={true}
                          disabled={props.formReadOnly || !!props.FoodRequestSequence?.id}
                          fluid={true}
                          name="intensity_unit"
                          onChange={props.onChangeData}
                          options={props.foodRequestOptions?.intensityUnitOptions || []}
                          search={true}
                          selection={true}
                          style={{ display: props.foodType === "R" ? "" : "none" }}
                          value={props.FoodRequestSequence?.intensity_unit || ""}>
                        </Dropdown>
                      </div>
                    </FormField>
                  </FormGroup>
                  <FormGroup
                    inline={true}>
                    <FormField
                      width={3}>
                      <div
                        style={{ fontWeight: "bold" }}>
                        
                        <label>
                          Mixture
                        </label>
                      </div>
                    </FormField>
                    <FormField
                      style={{ width: "100%"}}
                      width={7}>
                      <div
                        style={{ display: "flex", width: "100%", margin: "0px 0px 0px 10px" }}>
                        
                        <Dropdown
                          allowAdditions={true}
                          clearable={true}
                          disabled={props.formReadOnly || !!props.FoodRequestSequence?.id}
                          fluid={true}
                          multiple={true}
                          name="mixture"
                          onAddItem={props.onAddOptions}
                          onChange={props.onChangeData}
                          options={props.foodRequestOptions?.mixtureOptions || []}
                          search={true}
                          selection={true}
                          style={{ display: props.foodType === "R" ? "" : "none" }}
                          value={props.FoodRequestSequence?.mixture || []}>
                        </Dropdown>
                      </div>
                    </FormField>
                    <FormField
                      width={3}>
                      <div
                        style={{ fontWeight: "bold", justifyContent: "right", display: "flex", width: "100%" }}>
                        
                        <label>
                          Volume
                        </label>
                      </div>
                    </FormField>
                    <FormField
                      style={{ width: "100%"}}
                      width={7}>
                      <div
                        style={{ width: "100%" }}>
                        
                        <Input
                          disabled={props.formReadOnly || !!props.FoodRequestSequence?.id}
                          fluid={true}
                          name="mixture_volume"
                          onChange={props.onChangeData}
                          style={{ display: props.foodType === "R" ? "" : "none" }}
                          value={props.FoodRequestSequence?.mixture_volume || ""}>
                        </Input>
                      </div>
                    </FormField>
                  </FormGroup>
                  <FormGroup
                    inline={true}>
                    <FormField
                      width={3}>
                      <div
                        style={{ fontWeight: "bold" }}>
                        
                        <label>
                          หมายเหตุ Mixture
                        </label>
                      </div>
                    </FormField>
                    <FormField
                      style={{ width: "100%"}}>
                      <div>
                        
                        <Input
                          disabled={props.formReadOnly || !!props.FoodRequestSequence?.id}
                          fluid={true}
                          name="mixture_remark"
                          onChange={props.onChangeData}
                          style={{ display: props.foodType === "R" ? "" : "none" }}
                          value={props.FoodRequestSequence?.mixture_remark || ""}>
                        </Input>
                      </div>
                    </FormField>
                  </FormGroup>
                </div>
                <FormGroup
                  inline={true}>
                  <FormField
                    width={3}>
                    <div
                      style={{ fontWeight: "bold" }}>
                      
                      <label>
                        เสริม
                      </label>
                    </div>
                  </FormField>
                  <FormField
                    style={{ width: "100%" }}>
                    <div>
                      
                      <Dropdown
                        allowAdditions={true}
                        clearable={true}
                        disabled={props.formReadOnly}
                        fluid={true}
                        multiple={true}
                        name="supplement_food"
                        onAddItem={props.onAddOptions}
                        onChange={props.onChangeData}
                        options={props.foodRequestOptions?.supplementFoodOptions || []}
                        search={true}
                        selection={true}
                        value={props.FoodRequestSequence?.supplement_food || []}>
                      </Dropdown>
                    </div>
                  </FormField>
                </FormGroup>
                <FormGroup
                  inline={true}>
                  <FormField
                    width={3}>
                    <div
                      style={{ fontWeight: "bold" }}>
                      
                      <label>
                        แพ้
                      </label>
                    </div>
                  </FormField>
                  <FormField
                    style={{ width: "100%" }}>
                    <div>
                      
                      <Dropdown
                        allowAdditions={true}
                        clearable={true}
                        disabled={props.formReadOnly}
                        fluid={true}
                        multiple={true}
                        name="allergy_food"
                        onAddItem={props.onAddOptions}
                        onChange={props.onChangeData}
                        options={props.foodRequestOptions?.allergyFoodOptions || []}
                        search={true}
                        selection={true}
                        style={{ display: "none" }}
                        value={props.FoodRequestSequence?.allergy_food || []}>
                      </Dropdown>
                      <Input
                        fluid={true}
                        readOnly={true}
                        value={props.allergyFood}>
                      </Input>
                    </div>
                  </FormField>
                </FormGroup>
                <FormGroup
                  inline={true}>
                  <FormField
                    width={3}>
                    <div
                      style={{ fontWeight: "bold" }}>
                      
                      <label>
                        ข้อจำกัด
                      </label>
                    </div>
                  </FormField>
                  <FormField
                    style={{ width: "100%" }}>
                    <div>
                      
                      <Dropdown
                        allowAdditions={true}
                        clearable={true}
                        disabled={props.formReadOnly}
                        fluid={true}
                        multiple={true}
                        name="restriction_food"
                        onAddItem={props.onAddOptions}
                        onChange={props.onChangeData}
                        options={props.foodRequestOptions?.restrictionFoodOptions || []}
                        search={true}
                        selection={true}
                        value={props.FoodRequestSequence?.restriction_food || []}>
                      </Dropdown>
                    </div>
                  </FormField>
                </FormGroup>
                <FormGroup
                  inline={true}>
                  <FormField
                    width={3}>
                    <div
                      style={{ fontWeight: "bold" }}>
                      
                      <label>
                        หมายเหตุ
                      </label>
                    </div>
                  </FormField>
                  <FormField
                    style={{ width: "100%" }}>
                    <div>
                      
                      <TextArea
                        disabled={props.formReadOnly}
                        name="remark"
                        onChange={props.onChangeData}
                        value={props.FoodRequestSequence?.remark || ""}>
                      </TextArea>
                    </div>
                  </FormField>
                </FormGroup>
                <FormGroup
                  inline={true}>
                  <FormField
                    width={3}>
                    <div
                      style={{ fontWeight: "bold" }}>
                      
                      <label>
                        NPO ล่วงหน้า
                      </label>
                    </div>
                  </FormField>
                  <FormField
                    style={{ width: "100%" }}>
                    <div>
                      
                      <DateTextBox
                        disabled={props.formReadOnly}
                        minDate={props.betoday}
                        onChange={props.onChangeNPODate}
                        style={{ width: "100%" }}
                        value={props.FoodRequestSequence?.npo_date || ''}>
                      </DateTextBox>
                    </div>
                  </FormField>
                </FormGroup>
                <FormGroup
                  inline={true}>
                  <FormField
                    width={3}>
                    <div>
                      
                    </div>
                  </FormField>
                  <FormField
                    style={{ width: "100%" }}>
                    <div
                      style={{ display: "flex" }}>
                      
                      <div>
                        {props.buttonSave}
                      </div>
                      <div>
                        {props.buttonResume}
                      </div>
                      <div>
                        {props.buttonNPO}
                      </div>
                      <div>
                        {props.buttonOff}
                      </div>
                      <div>
                        {props.buttonRenew}
                      </div>
                      <div>
                        {props.buttonClear}
                      </div>
                    </div>
                  </FormField>
                </FormGroup>
              </Form>
            </div>
          </div>
          <div
            style={{ width: "40%", paddingRight: "40px" }}>
            
            <div>
              {props.specialDiet}
            </div>
            <div>
              {props.nutrition}
            </div>
          </div>
        </div>
      </div>
    )
}


export default CardFoodRequestUX

export const screenPropsDefault = {}

/* Date Time : Wed Sep 04 2024 09:07:34 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ height: \"50px\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "label": "divgrid",
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(4, 1fr)\",\"gridTemplateColumns\":\"repeat(34, 1fr)\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "code",
          "value": "props.status"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"18/34\", fontSize: \"17px\", borderBottom: \"3px solid grey\", textAlign: \"right\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "สั่งอาหาร"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"2/18\", fontWeight: \"bold\", fontSize: \"17px\", borderBottom: \"3px solid grey\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 114,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"60%\" }"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 114,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 115,
      "name": "div",
      "parent": 114,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"40%\", paddingRight: \"40px\" }"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": null,
      "id": 116,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "code",
          "value": "props.specialDiet"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": null,
      "id": 117,
      "name": "div",
      "parent": 115,
      "props": {
        "children": {
          "type": "code",
          "value": "props.nutrition"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": null,
      "id": 138,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 40px 0px 40px\" }"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 139,
      "name": "Form",
      "parent": 138,
      "props": {
      },
      "seq": 139,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 140,
      "name": "FormGroup",
      "parent": 139,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 141,
      "name": "FormGroup",
      "parent": 139,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 142,
      "name": "FormGroup",
      "parent": 139,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 143,
      "name": "FormGroup",
      "parent": 139,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 144,
      "name": "FormGroup",
      "parent": 139,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 145,
      "name": "FormGroup",
      "parent": 139,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 146,
      "name": "FormGroup",
      "parent": 139,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 147,
      "name": "FormGroup",
      "parent": 139,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 218,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 148,
      "name": "FormGroup",
      "parent": 139,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 224,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 149,
      "name": "FormField",
      "parent": 148,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 150,
      "name": "FormField",
      "parent": 148,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 151,
      "name": "FormField",
      "parent": 147,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 152,
      "name": "FormField",
      "parent": 147,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 153,
      "name": "FormField",
      "parent": 146,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 154,
      "name": "FormField",
      "parent": 146,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 155,
      "name": "FormField",
      "parent": 145,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 156,
      "name": "FormField",
      "parent": 145,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 157,
      "name": "FormField",
      "parent": 144,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 158,
      "name": "FormField",
      "parent": 144,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 159,
      "name": "FormField",
      "parent": 143,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 160,
      "name": "FormField",
      "parent": 143,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 161,
      "name": "FormField",
      "parent": 142,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 162,
      "name": "FormField",
      "parent": 142,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 163,
      "name": "FormField",
      "parent": 141,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 163,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 164,
      "name": "FormField",
      "parent": 141,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 165,
      "name": "FormField",
      "parent": 140,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 165,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 166,
      "name": "FormField",
      "parent": 140,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": null,
      "id": 167,
      "name": "div",
      "parent": 165,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 167,
      "void": false
    },
    {
      "from": null,
      "id": 168,
      "name": "div",
      "parent": 166,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 168,
      "void": false
    },
    {
      "from": null,
      "id": 169,
      "name": "div",
      "parent": 163,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 169,
      "void": false
    },
    {
      "from": null,
      "id": 170,
      "name": "div",
      "parent": 164,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 170,
      "void": false
    },
    {
      "from": null,
      "id": 171,
      "name": "div",
      "parent": 161,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 171,
      "void": false
    },
    {
      "from": null,
      "id": 172,
      "name": "div",
      "parent": 162,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 172,
      "void": false
    },
    {
      "from": null,
      "id": 173,
      "name": "div",
      "parent": 159,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 173,
      "void": false
    },
    {
      "from": null,
      "id": 174,
      "name": "div",
      "parent": 160,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 174,
      "void": false
    },
    {
      "from": null,
      "id": 175,
      "name": "div",
      "parent": 157,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 175,
      "void": false
    },
    {
      "from": null,
      "id": 176,
      "name": "div",
      "parent": 158,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 176,
      "void": false
    },
    {
      "from": null,
      "id": 177,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 177,
      "void": false
    },
    {
      "from": null,
      "id": 178,
      "name": "div",
      "parent": 156,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": null,
      "id": 179,
      "name": "div",
      "parent": 153,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 179,
      "void": false
    },
    {
      "from": null,
      "id": 180,
      "name": "div",
      "parent": 154,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 180,
      "void": false
    },
    {
      "from": null,
      "id": 181,
      "name": "div",
      "parent": 151,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 181,
      "void": false
    },
    {
      "from": null,
      "id": 182,
      "name": "div",
      "parent": 152,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 182,
      "void": false
    },
    {
      "from": null,
      "id": 183,
      "name": "div",
      "parent": 149,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 183,
      "void": false
    },
    {
      "from": null,
      "id": 184,
      "name": "div",
      "parent": 150,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" }"
        }
      },
      "seq": 184,
      "void": false
    },
    {
      "from": null,
      "id": 185,
      "name": "label",
      "parent": 167,
      "props": {
        "children": {
          "type": "value",
          "value": "ชนิดอาหาร"
        }
      },
      "seq": 185,
      "void": false
    },
    {
      "from": null,
      "id": 186,
      "name": "label",
      "parent": 167,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 186,
      "void": false
    },
    {
      "from": null,
      "id": 187,
      "name": "label",
      "parent": 169,
      "props": {
        "children": {
          "type": "value",
          "value": "จำนวนมื้อ"
        }
      },
      "seq": 187,
      "void": false
    },
    {
      "from": null,
      "id": 188,
      "name": "label",
      "parent": 169,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 188,
      "void": false
    },
    {
      "from": null,
      "id": 189,
      "name": "label",
      "parent": 171,
      "props": {
        "children": {
          "type": "value",
          "value": "มื้ออาหาร"
        }
      },
      "seq": 189,
      "void": false
    },
    {
      "from": null,
      "id": 190,
      "name": "label",
      "parent": 171,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 190,
      "void": false
    },
    {
      "from": null,
      "id": 191,
      "name": "label",
      "parent": 173,
      "props": {
        "children": {
          "type": "value",
          "value": "เสริม"
        }
      },
      "seq": 191,
      "void": false
    },
    {
      "from": null,
      "id": 192,
      "name": "label",
      "parent": 175,
      "props": {
        "children": {
          "type": "value",
          "value": "แพ้"
        }
      },
      "seq": 192,
      "void": false
    },
    {
      "from": null,
      "id": 193,
      "name": "label",
      "parent": 177,
      "props": {
        "children": {
          "type": "value",
          "value": "ข้อจำกัด"
        }
      },
      "seq": 193,
      "void": false
    },
    {
      "from": null,
      "id": 194,
      "name": "label",
      "parent": 179,
      "props": {
        "children": {
          "type": "value",
          "value": "หมายเหตุ"
        }
      },
      "seq": 194,
      "void": false
    },
    {
      "from": null,
      "id": 195,
      "name": "label",
      "parent": 181,
      "props": {
        "children": {
          "type": "value",
          "value": "NPO ล่วงหน้า"
        }
      },
      "seq": 195,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 196,
      "name": "Dropdown",
      "parent": 168,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.formReadOnly || !!props.FoodRequestSequence?.id"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "food_type"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.foodRequestOptions?.foodTypeOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.FoodRequestSequence?.food_type || \"\""
        }
      },
      "seq": 196,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 197,
      "name": "Dropdown",
      "parent": 174,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.formReadOnly"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "supplement_food"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.foodRequestOptions?.supplementFoodOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.FoodRequestSequence?.supplement_food || []"
        }
      },
      "seq": 197,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 198,
      "name": "Dropdown",
      "parent": 178,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.formReadOnly"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "restriction_food"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.foodRequestOptions?.restrictionFoodOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.FoodRequestSequence?.restriction_food || []"
        }
      },
      "seq": 198,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 199,
      "name": "Input",
      "parent": 176,
      "props": {
        "disabled": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.allergyFood"
        }
      },
      "seq": 217,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 200,
      "name": "TextArea",
      "parent": 180,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.formReadOnly"
        },
        "name": {
          "type": "value",
          "value": "remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "code",
          "value": "props.FoodRequestSequence?.remark || \"\""
        }
      },
      "seq": 200,
      "void": true
    },
    {
      "from": "react-lib/apps/common",
      "id": 201,
      "name": "DateTextBox",
      "parent": 182,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.formReadOnly"
        },
        "minDate": {
          "type": "code",
          "value": "props.betoday"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeNPODate"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.FoodRequestSequence?.npo_date || ''"
        }
      },
      "seq": 201,
      "void": false
    },
    {
      "from": null,
      "id": 202,
      "name": "div",
      "parent": 170,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"25%\" }"
        }
      },
      "seq": 202,
      "void": false
    },
    {
      "from": null,
      "id": 203,
      "name": "div",
      "parent": 170,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", marginLeft: \"20px\" }"
        }
      },
      "seq": 203,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 204,
      "name": "Input",
      "parent": 202,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.formReadOnly || !!props.FoodRequestSequence?.id"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "quantity"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.FoodRequestSequence?.quantity || \"\""
        }
      },
      "seq": 204,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 205,
      "name": "Checkbox",
      "parent": 203,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.FoodRequestSequence?.is_extra_meal"
        },
        "disabled": {
          "type": "code",
          "value": "props.formReadOnly || !!props.FoodRequestSequence?.id"
        },
        "label": {
          "type": "value",
          "value": "มื้อ Extra"
        },
        "name": {
          "type": "value",
          "value": "is_extra_meal"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 205,
      "void": false
    },
    {
      "from": null,
      "id": 206,
      "name": "label",
      "parent": 203,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 206,
      "void": false
    },
    {
      "from": null,
      "id": 208,
      "name": "div",
      "parent": 172,
      "props": {
        "children": {
          "type": "code",
          "value": "props.generalTimeComponent"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.foodType === \"G\" ? \"\" : \"none\" }  "
        }
      },
      "seq": 208,
      "void": false
    },
    {
      "from": null,
      "id": 209,
      "name": "div",
      "parent": 172,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.foodType === \"R\" ? \"\" : \"none\" }  "
        }
      },
      "seq": 209,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 210,
      "name": "Dropdown",
      "parent": 209,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.formReadOnly || !!props.FoodRequestSequence?.id"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "routine_time"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.foodRequestOptions?.routineTimeOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.FoodRequestSequence?.routine_time || []"
        }
      },
      "seq": 210,
      "void": false
    },
    {
      "from": null,
      "id": 211,
      "name": "div",
      "parent": 184,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        }
      },
      "seq": 211,
      "void": false
    },
    {
      "from": null,
      "id": 212,
      "name": "div",
      "parent": 184,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonResume"
        }
      },
      "seq": 212,
      "void": false
    },
    {
      "from": null,
      "id": 213,
      "name": "div",
      "parent": 184,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonNPO"
        }
      },
      "seq": 213,
      "void": false
    },
    {
      "from": null,
      "id": 214,
      "name": "div",
      "parent": 184,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonOff"
        }
      },
      "seq": 214,
      "void": false
    },
    {
      "from": null,
      "id": 215,
      "name": "div",
      "parent": 184,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonRenew"
        }
      },
      "seq": 215,
      "void": false
    },
    {
      "from": null,
      "id": 216,
      "name": "div",
      "parent": 184,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonClear"
        }
      },
      "seq": 216,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 217,
      "name": "Dropdown",
      "parent": 176,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.formReadOnly"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "allergy_food"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.foodRequestOptions?.allergyFoodOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"none\" }"
        },
        "value": {
          "type": "code",
          "value": "props.FoodRequestSequence?.allergy_food || []"
        }
      },
      "seq": 199,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 218,
      "name": "FormGroup",
      "parent": 139,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.foodType !== \"R\" && \"none\",  }"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 219,
      "name": "FormField",
      "parent": 218,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 219,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 220,
      "name": "FormField",
      "parent": 218,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 220,
      "void": false
    },
    {
      "from": null,
      "id": 221,
      "name": "div",
      "parent": 220,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 221,
      "void": false
    },
    {
      "from": null,
      "id": 222,
      "name": "div",
      "parent": 219,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 222,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 223,
      "name": "Dropdown",
      "parent": 221,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.formReadOnly || !!props.FoodRequestSequence?.id"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "routine_type"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.foodRequestOptions?.routineTypeOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.FoodRequestSequence?.routine_type || []"
        }
      },
      "seq": 223,
      "void": false
    },
    {
      "from": null,
      "id": 224,
      "name": "div",
      "parent": 139,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.foodType === \"R\" ? \"\" : \"none\",  }"
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 225,
      "name": "FormGroup",
      "parent": 224,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 225,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 226,
      "name": "FormGroup",
      "parent": 224,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 226,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 227,
      "name": "FormGroup",
      "parent": 224,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 227,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 228,
      "name": "FormField",
      "parent": 225,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 228,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 229,
      "name": "FormField",
      "parent": 225,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\"}"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 229,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 230,
      "name": "FormField",
      "parent": 225,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 230,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 231,
      "name": "FormField",
      "parent": 225,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\"}"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 231,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 232,
      "name": "FormField",
      "parent": 226,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 232,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 233,
      "name": "FormField",
      "parent": 226,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\"}"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 233,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 234,
      "name": "FormField",
      "parent": 226,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 234,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 235,
      "name": "FormField",
      "parent": 226,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\"}"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 235,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 236,
      "name": "FormField",
      "parent": 227,
      "props": {
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 236,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 237,
      "name": "FormField",
      "parent": 227,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\"}"
        }
      },
      "seq": 237,
      "void": false
    },
    {
      "from": null,
      "id": 238,
      "name": "div",
      "parent": 228,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 238,
      "void": false
    },
    {
      "from": null,
      "id": 239,
      "name": "div",
      "parent": 229,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", width: \"100%\", margin: \"0px 0px 0px 10px\" }"
        }
      },
      "seq": 239,
      "void": false
    },
    {
      "from": null,
      "id": 240,
      "name": "div",
      "parent": 230,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", justifyContent: \"right\", display: \"flex\", width: \"100%\" }"
        }
      },
      "seq": 240,
      "void": false
    },
    {
      "from": null,
      "id": 241,
      "name": "div",
      "parent": 231,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", width: \"100%\" }"
        }
      },
      "seq": 241,
      "void": false
    },
    {
      "from": null,
      "id": 242,
      "name": "div",
      "parent": 232,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 242,
      "void": false
    },
    {
      "from": null,
      "id": 243,
      "name": "div",
      "parent": 233,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", width: \"100%\", margin: \"0px 0px 0px 10px\" }"
        }
      },
      "seq": 243,
      "void": false
    },
    {
      "from": null,
      "id": 244,
      "name": "div",
      "parent": 234,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", justifyContent: \"right\", display: \"flex\", width: \"100%\" }"
        }
      },
      "seq": 244,
      "void": false
    },
    {
      "from": null,
      "id": 245,
      "name": "div",
      "parent": 235,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 245,
      "void": false
    },
    {
      "from": null,
      "id": 246,
      "name": "div",
      "parent": 236,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" }"
        }
      },
      "seq": 246,
      "void": false
    },
    {
      "from": null,
      "id": 247,
      "name": "div",
      "parent": 237,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 247,
      "void": false
    },
    {
      "from": null,
      "id": 248,
      "name": "label",
      "parent": 238,
      "props": {
        "children": {
          "type": "value",
          "value": "ปริมาตร"
        }
      },
      "seq": 248,
      "void": false
    },
    {
      "from": null,
      "id": 249,
      "name": "label",
      "parent": 238,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 249,
      "void": false
    },
    {
      "from": null,
      "id": 250,
      "name": "label",
      "parent": 242,
      "props": {
        "children": {
          "type": "value",
          "value": "Mixture"
        }
      },
      "seq": 250,
      "void": false
    },
    {
      "from": null,
      "id": 251,
      "name": "label",
      "parent": 246,
      "props": {
        "children": {
          "type": "value",
          "value": "หมายเหตุ Mixture"
        }
      },
      "seq": 251,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 252,
      "name": "Input",
      "parent": 247,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.formReadOnly || !!props.FoodRequestSequence?.id"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "mixture_remark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.foodType === \"R\" ? \"\" : \"none\" }"
        },
        "value": {
          "type": "code",
          "value": "props.FoodRequestSequence?.mixture_remark || \"\""
        }
      },
      "seq": 252,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 253,
      "name": "Dropdown",
      "parent": 243,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.formReadOnly || !!props.FoodRequestSequence?.id"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "mixture"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.foodRequestOptions?.mixtureOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.foodType === \"R\" ? \"\" : \"none\" }"
        },
        "value": {
          "type": "code",
          "value": "props.FoodRequestSequence?.mixture || []"
        }
      },
      "seq": 253,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 254,
      "name": "Input",
      "parent": 245,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.formReadOnly || !!props.FoodRequestSequence?.id"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "mixture_volume"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.foodType === \"R\" ? \"\" : \"none\" }"
        },
        "value": {
          "type": "code",
          "value": "props.FoodRequestSequence?.mixture_volume || \"\""
        }
      },
      "seq": 254,
      "void": false
    },
    {
      "from": null,
      "id": 255,
      "name": "label",
      "parent": 244,
      "props": {
        "children": {
          "type": "value",
          "value": "Volume"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 255,
      "void": false
    },
    {
      "from": null,
      "id": 256,
      "name": "label",
      "parent": 240,
      "props": {
        "children": {
          "type": "value",
          "value": "ความเข้มข้น"
        }
      },
      "seq": 256,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 257,
      "name": "Input",
      "parent": 239,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.formReadOnly || !!props.FoodRequestSequence?.id"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "routine_size"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.foodType === \"R\" ? \"\" : \"none\" }"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.FoodRequestSequence?.routine_size || \"\""
        }
      },
      "seq": 257,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 258,
      "name": "Dropdown",
      "parent": 239,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.formReadOnly || !!props.FoodRequestSequence?.id"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "routine_unit"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.foodRequestOptions?.routineUnitOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.foodType === \"R\" ? \"\" : \"none\" }"
        },
        "value": {
          "type": "code",
          "value": "props.FoodRequestSequence?.routine_unit || \"\""
        }
      },
      "seq": 258,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 259,
      "name": "Input",
      "parent": 241,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.formReadOnly || !!props.FoodRequestSequence?.id"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "intensity"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.foodType === \"R\" ? \"\" : \"none\" }"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.FoodRequestSequence?.intensity || \"\""
        }
      },
      "seq": 259,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 260,
      "name": "Dropdown",
      "parent": 241,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.formReadOnly || !!props.FoodRequestSequence?.id"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "intensity_unit"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.foodRequestOptions?.intensityUnitOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.foodType === \"R\" ? \"\" : \"none\" }"
        },
        "value": {
          "type": "code",
          "value": "props.FoodRequestSequence?.intensity_unit || \"\""
        }
      },
      "seq": 260,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardFoodRequestUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
