import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Calendar
} from 'react-big-calendar'
import {
  Button,
  Dropdown
} from 'semantic-ui-react'

const CardAppointmentManageUX = (props: any) => {
    return(
      <div
        style={{padding: "10px"}}>
        
        <Table
          data={props.appointmentList}
          getTrProps={props.appointmentRowProps}
          headers="วันที่,ผู้ป่วย,รายละเอียด"
          keys="datetime,patient_name,detail"
          minRows="3"
          showPagination={false}
          style={{height: "150px"}}>
        </Table>
        <div
          style={{paddingTop: "15px", paddingBottom: "5px", display: "flex"}}>
          
          <Button
            color={props.tab==="calendar" ? "blue" : null}
            onClick={() => props.setTab("calendar")}
            size="small">
            กำหนดวันเวลา
          </Button>
          <Button
            color={props.tab==="detail" ? "blue" : null}
            disabled={props.disableDetail}
            onClick={() => props.setTab("detail")}
            size="small">
            รายละเอียดอื่นๆ
          </Button>
          <div
            style={{flex: 1}}>
            
          </div>
          <Dropdown
            selection={true}
            style={{display: props.tab === "calendar" ? "block" : "none"}}>
          </Dropdown>
          <div
            style={{display: props.tab === "calendar" ? "block" : "none"}}>
            {props.providerSelector}
          </div>
        </div>
        <div
          style={{display: props.tab === "calendar" ? "block" : "none"}}>
          
          <Calendar
            endAccessor="end"
            eventPropGetter={props.eventPropGetter}
            events={props.blockList || []}
            localizer={props.localizer}
            max={new Date(1972, 0, 1, 19, 0, 0)}
            min={new Date(1972, 0, 1, 7, 0, 0)}
            onNavigate={props.onNavigate}
            onRangeChange={props.onRangeChange}
            onSelectEvent={props.onSelectEvent}
            startAccessor="start"
            style={{minHeight: "70vh"}}
            tooltipAccessor="tooltip"
            views={["month", "week"]}>
          </Calendar>
        </div>
        <div
          style={{minHeight: "70vh", display: props.tab === "detail" ? "block" : "none"}}>
          xx
        </div>
      </div>
    )
}

export default CardAppointmentManageUX

export const screenPropsDefault = {"providerSelector":"[ProviderSelector]","tab":"calendar"}

/* Date Time : Sun Apr 17 2022 22:05:30 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 1,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.appointmentList"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.appointmentRowProps"
        },
        "headers": {
          "type": "value",
          "value": "วันที่,ผู้ป่วย,รายละเอียด"
        },
        "keys": {
          "type": "value",
          "value": "datetime,patient_name,detail"
        },
        "minRows": {
          "type": "value",
          "value": "3"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"150px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{paddingTop: \"15px\", paddingBottom: \"5px\", display: \"flex\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "react-big-calendar",
      "id": 3,
      "name": "Calendar",
      "parent": 4,
      "props": {
        "endAccessor": {
          "type": "value",
          "value": "end"
        },
        "eventPropGetter": {
          "type": "code",
          "value": "props.eventPropGetter"
        },
        "events": {
          "type": "code",
          "value": "props.blockList || []"
        },
        "localizer": {
          "type": "code",
          "value": "props.localizer"
        },
        "max": {
          "type": "code",
          "value": "new Date(1972, 0, 1, 19, 0, 0)"
        },
        "min": {
          "type": "code",
          "value": "new Date(1972, 0, 1, 7, 0, 0)"
        },
        "onNavigate": {
          "type": "code",
          "value": "props.onNavigate"
        },
        "onRangeChange": {
          "type": "code",
          "value": "props.onRangeChange"
        },
        "onSelectEvent": {
          "type": "code",
          "value": "props.onSelectEvent"
        },
        "startAccessor": {
          "type": "value",
          "value": "start"
        },
        "style": {
          "type": "code",
          "value": "{minHeight: \"70vh\"}"
        },
        "tooltipAccessor": {
          "type": "value",
          "value": "tooltip"
        },
        "views": {
          "type": "code",
          "value": "[\"month\", \"week\"]"
        }
      },
      "seq": 3,
      "void": true
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.tab === \"calendar\" ? \"block\" : \"none\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "กำหนดวันเวลา"
        },
        "color": {
          "type": "code",
          "value": "props.tab===\"calendar\" ? \"blue\" : null"
        },
        "onClick": {
          "type": "code",
          "value": "() => props.setTab(\"calendar\")"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "รายละเอียดอื่นๆ"
        },
        "color": {
          "type": "code",
          "value": "props.tab===\"detail\" ? \"blue\" : null"
        },
        "disabled": {
          "type": "code",
          "value": "props.disableDetail"
        },
        "onClick": {
          "type": "code",
          "value": "() => props.setTab(\"detail\")"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "Dropdown",
      "parent": 2,
      "props": {
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: props.tab === \"calendar\" ? \"block\" : \"none\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "xx"
        },
        "style": {
          "type": "code",
          "value": "{minHeight: \"70vh\", display: props.tab === \"detail\" ? \"block\" : \"none\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "code",
          "value": "props.providerSelector"
        },
        "style": {
          "type": "code",
          "value": "{display: props.tab === \"calendar\" ? \"block\" : \"none\"}"
        }
      },
      "seq": 10,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardAppointmentManageUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "providerSelector": "[ProviderSelector]",
    "tab": "calendar"
  },
  "width": 80
}

*********************************************************************************** */
