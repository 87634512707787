const AddTableLivingWillEN = {
  unbreakable: true,
  table: {
    widths: ["75%", "25%"],
    body: [
      [
        {
          text: `I have my will to decline these following life-sustaining treatments`,
          fontSize: 15,
          bold: true,
          alignment: `center`,
        },
        {
          text: `Patient's signature\n(by selected item)`,
          fontSize: 15,
          bold: true,
          alignment: `center`,
        },
      ],
      [
        {
          text: `Cardiopulmonary Resuscitation`,
          fontSize: 15
        },
        {},
      ],
      [
        {
          text: `Tracheal Intubation`,
          fontSize: 15
        },
        {},
      ],
      [
        {
          text: `Tracheostomy`,
          fontSize: 15
        },
        {},
      ],
      [
        {
          text: `Artificial Ventilation`,
          fontSize: 15
        },
        {},
      ],
      [
        {
          text: `Parenteral Nutrition and Intravenous Fluid`,
          fontSize: 15
        },
        {},
      ],
      [
        {
          text: `Circulatory system stimulation using medications or medical equipment`,
          fontSize: 15
        },
        {},
      ],
      [
        {
          text: `Care in Intensive Care Unit (ICU)`,
          fontSize: 15
        },
        {},
      ],
      [
        {
          text: `Hemodialysis in case of renal failure`,
          fontSize: 15
        },
        {},
      ],
      [
        {
          text: `Others, please spectify ..............................................................................................................\n..........................................................................................................................................................`,
          fontSize: 15,
        },
        {},
      ],
    ]
  }
}

export default AddTableLivingWillEN;