import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Segment,
  Form,
  FormGroup,
  FormField,
  Button
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardApplyPolicyPackageUX = (props: any) => {
    return(
      <div
        style={{ width : "100%", height: "100%" }}>
        <Segment
          className="segment-no-shadow">
          <div
            style={{display: "flex", justifyContent: "space-between"}}>
            
            <div
              style={{fontWeight: "bold", fontSize: "1.2rem"}}>
              บันทึกใช้แพ็กเกจ
            </div>
            <div
              style={{cursor: "pointer"}}>
              
            </div>
          </div>
          <hr>
          </hr>
          <Form>
            <FormGroup
              inline={true}
              style={{paddingTop: "0.5rem"}}>
              <FormField
                style={{flex:1}}>
              </FormField>
              <FormField
                style={{padding:0}}>
                <Button
                  color="blue"
                  disabled={props.disabledAdd || false}
                  onClick={props.onClickAdd}>
                  เพิ่มรายการ
                </Button>
              </FormField>
            </FormGroup>
            <FormGroup>
              <FormField
                width={16}>
                <Table
                  data={props.applyPolicyList||[]}
                  headers="ลำดับที่,ชื่อแพ็กเกจ,Encounter,"
                  keys="seq,name,encounters,action"
                  minRows={13}
                  showPagination={false}
                  style={{height:"calc(100dvh - 19rem)"}}
                  widths="80,^200,^120,100">
                </Table>
              </FormField>
            </FormGroup>
            <FormGroup>
              <FormField
                style={{flex:1}}>
              </FormField>
              <FormField>
                <div>
                  {props.ButtonConfirm}
                </div>
              </FormField>
            </FormGroup>
          </Form>
        </Segment>
      </div>
    )
}


export default CardApplyPolicyPackageUX

export const screenPropsDefault = {"cannotPay":false,"cashAmountText":"14","citizenID":"31020000552","oweText":123,"patientNameAndHN":"HN: xxxxx นายทดสอบ","sumAmount":{"price":100,"reimbursable":42}}

/* Date Time : Wed Jan 24 2024 10:03:36 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width : \"100%\", height: \"100%\" }"
        }
      },
      "seq": 0
    },
    {
      "from": "semantic-ui-react",
      "id": 1,
      "name": "Segment",
      "parent": 0,
      "props": {
        "className": {
          "type": "value",
          "value": "segment-no-shadow"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "บันทึกใช้แพ็กเกจ"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.2rem\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "hr",
      "parent": 1,
      "props": {
      },
      "seq": 6,
      "void": true
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{cursor: \"pointer\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "Form",
      "parent": 1,
      "props": {
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "FormGroup",
      "parent": 13,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{paddingTop: \"0.5rem\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "FormField",
      "parent": 14,
      "props": {
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "FormField",
      "parent": 14,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding:0}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "Button",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มรายการ"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledAdd || false"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickAdd"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "FormGroup",
      "parent": 13,
      "props": {
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "FormGroup",
      "parent": 13,
      "props": {
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "FormField",
      "parent": 19,
      "props": {
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "FormField",
      "parent": 19,
      "props": {
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "FormField",
      "parent": 18,
      "props": {
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 23,
      "name": "Table",
      "parent": 22,
      "props": {
        "data": {
          "type": "code",
          "value": "props.applyPolicyList||[]"
        },
        "headers": {
          "type": "value",
          "value": "ลำดับที่,ชื่อแพ็กเกจ,Encounter,"
        },
        "keys": {
          "type": "value",
          "value": "seq,name,encounters,action"
        },
        "minRows": {
          "type": "code",
          "value": "13"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height:\"calc(100dvh - 19rem)\"}"
        },
        "widths": {
          "type": "value",
          "value": "80,^200,^120,100"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 21,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonConfirm"
        }
      },
      "seq": 24,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardApplyPolicyPackageUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "cannotPay": false,
    "cashAmountText": "14",
    "citizenID": "31020000552",
    "oweText": 123,
    "patientNameAndHN": "HN: xxxxx นายทดสอบ",
    "sumAmount": {
      "price": 100,
      "reimbursable": 42
    }
  },
  "width": 75
}

*********************************************************************************** */
