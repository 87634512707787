import React, { useState, useEffect } from "react";
import Pills from "./pills.png";
import { DOCTOR_ORDER_MAPPING_ICON } from "react-lib/apps/HISV3/REG/REGInterface";
import {
  Segment,
  Header,
  Divider,
  Label,
  Grid,
  Image,
  Dropdown,
  Button,
  Form,
  Modal,
} from "semantic-ui-react";
import ReactTable from "react-table-6";
import PropTypes from "prop-types";
import SimplePagination from "./SimplePagination";
import ModalADRInfo from "./ModalADRInfo";
import { useIntl } from "react-intl";

const styles = {
  label: {
    background: "none",
    color: "black",
    fontSize: "0.9em",
    paddingLeft: 0,
    paddingRight: 0,
  },
  gridRow: {
    padding: 1.5,
  },
};

const PatientPanel = ({
  patientPanelData,
  isLoading,
  selectedRowEncounter,
  showContentOnly,
  handleOnChangeEncounterFilter,
  handleOnChangeEncounterId,
  forcedSelectEncounterId,
}) => {
  const intl = useIntl();
  let patientData = patientPanelData.patientData
    ? patientPanelData.patientData
    : {};
  let encounterHistory = patientPanelData.encounterHistory
    ? patientPanelData.encounterHistory
    : {};
  let adrReaction = patientPanelData.adrReaction
    ? patientPanelData.adrReaction
    : {};
  let documentNote = patientPanelData.documentNote
    ? patientPanelData.documentNote
    : {};

  let hn = patientData.hn ? patientData.hn : "-";
  let fullName = patientData.full_name ? patientData.full_name : "--";
  let gender = patientData.gender_name ? patientData.gender_name : "-";
  let adr = patientData.hn
    ? adrReaction.total > 0
      ? "มีประวัติแพ้ยา"
      : "ไม่มีประวัติแพ้ยา"
    : "-";
  let birthDay = patientData.birthdate ? patientData.birthdate : "-";
  let age = patientData.full_age ? patientData.full_age : "-";
  let noteTypeList = documentNote ? documentNote : [];

  let isPagination = !!encounterHistory.next || !!encounterHistory.previous;
  let encounterData = encounterHistory.items;
  let limit = 5; // Know from CardPHV
  let totalPage = encounterHistory.total
    ? Math.ceil(encounterHistory.total / limit)
    : 1;

  let [enableSelect, setEnableSelect] = useState(false);
  let [ipdType, setIPDType] = useState(true);
  let [opdType, setOPDType] = useState(true);
  let [noteType, setNoteType] = useState("");
  let [page, setPage] = useState(0);
  const [openModADR, setOpenModADR] = useState(false);

  let [selectedPatient, setSelectedPatient] = useState({});
  let [selectedRow, setSelectedRow] = useState(null);

  let [alertAuthorized, setAlertAuthroized] = useState(false);

  // TODO: Sort Date,
  // TODO: Pagination,

  const enListColumns = [
    {
      id: "type",
      Header: "Type",
      accessor: "type",
      Cell: (row) => {
        let colorType;
        if (row.value === "IPD") {
          colorType = "#2F80ED";
        } else {
          colorType = "green";
        }
        return (
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column style={{ padding: "0px 5px 0px 15px" }}>
                <center>
                  <font color={colorType}>{row.value}</font>
                  <br />
                  <font color="gray">{row.original.number}</font>
                </center>
              </Grid.Column>
              <Grid.Column style={{ padding: "0px" }}>
                {row.original?.completed_or_order?.length > 0 ? (
                  <Image
                    style={{ width: 25, height: 25 }}
                    src={DOCTOR_ORDER_MAPPING_ICON.operatingorder.performed}
                  ></Image>
                ) : (
                  <></>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        );
      },
      minWidth: 70,
      headerStyle: {
        fontSize: 12,
      },
      style: {
        fontSize: 12,
      },
    },
    {
      Header: "Date",
      accessor: (d) => {
        let lines = d.date.split(" ");
        let linesHTML = lines.map((line, key) => <div key={key}>{line}</div>);
        return linesHTML;
      },
      id: "datetime",
      minWidth: 70,
      headerStyle: {
        fontSize: 12,
      },
      style: {
        fontSize: 12,
      },
    },
    {
      Header: "Doctor/Division",
      id: "doctorDivision",
      accessor: (d) => {
        let lines = [d.doctor_name, d.division_name];
        let linesHTML = lines.map((line, key) => <div key={key}>{line}</div>);
        return linesHTML;
      },
      //TODO: Change it to Overflow
      minWidth: 200,
      headerStyle: {
        fontSize: 12,
      },
      style: {
        fontSize: 12,
      },
    },
  ];

  useEffect(() => {
    if (
      encounterHistory.items &&
      encounterHistory.items.length &&
      typeof selectedRowEncounter === "undefined"
    ) {
      setSelectedPatient({
        hnPatient: encounterHistory.items[0].hn,
        encounterId: encounterHistory.items[0].id,
        emrId: encounterHistory.items[0].medical_record,
        encounterType: encounterHistory.items[0].type,
        checkInTime: encounterHistory.items[0].date,
        dischargeTime: encounterHistory.items[0].discharge_date,
        isSecret: encounterHistory.items[0].is_secret,
        status: encounterHistory.items[0].status,
      });

      let firstselectRow = 0;
      if (forcedSelectEncounterId !== null) {
        let idx = encounterHistory.items.findIndex(
          (item) => item.id === forcedSelectEncounterId
        );
        console.log(" idx", idx);
        if (idx !== -1) {
          firstselectRow = idx;
        }
      }
      setSelectedRow(firstselectRow);
    } else if (encounterHistory.items && encounterHistory.items.length === 0) {
      setSelectedRow(null);
      setSelectedPatient({});
    }
  }, [encounterHistory]);

  useEffect(() => {
    if (typeof selectedRowEncounter !== "undefined") {
      if (selectedRowEncounter) {
        handleSelectRow(selectedRowEncounter);
      } else {
        setSelectedRow(null);
        setSelectedPatient({});
      }
    }
  }, [selectedRowEncounter]);

  useEffect(() => {
    const { isSecret } = selectedPatient;
    if (isSecret) {
      //PopUp Block
      setAlertAuthroized(true);
    }

    if (selectedPatient) {
      handleOnChangeEncounterId(selectedPatient);
    }
  }, [selectedPatient]);

  // TODO: Fixed loading on Patient Panel
  useEffect(() => {
    console.log(
      "  useEffect forcedSelectEncounterId ",
      forcedSelectEncounterId
    );
    if (forcedSelectEncounterId) {
      handleOnChangeEncounterFilter({
        ipdType,
        opdType,
        noteType,
        page,
        forcedSelectEncounterId,
      });
    } else {
      handleOnChangeEncounterFilter({
        ipdType,
        opdType,
        noteType,
        page,
        forcedSelectEncounterId: 0,
      });
    }
  }, [ipdType, opdType, noteType, page, forcedSelectEncounterId]);

  useEffect(() => {
    // setEnableSelect(true)
    if (
      patientPanelData.patientData &&
      patientPanelData.patientData.id &&
      !forcedSelectEncounterId
    ) {
      setEnableSelect(true);
    } else {
      setEnableSelect(false);
    }
  }, [patientPanelData.patientData]);

  const handleClickEncounterType = (e, { name }) => {
    if (name === "IPD") {
      if (ipdType === true && opdType === false) {
        setOPDType(!opdType);
      }
      setIPDType(!ipdType);
    } else {
      if (ipdType === false && opdType === true) {
        setIPDType(!ipdType);
      }
      setOPDType(!opdType);
    }
  };

  const handleOnChangeNurseRecord = (e, data) => {
    setNoteType(data.value);
  };

  const handleOnClickADR = () => {
    setOpenModADR(true);
  };
  const handleOnClickCloseADR = () => {
    setOpenModADR(false);
  };

  const handleSelectRow = (rowInfo: any) => {
    setSelectedRow(rowInfo.index);

    setSelectedPatient({
      hnPatient: rowInfo.original.hn,
      encounterId: rowInfo.original.id,
      emrId: rowInfo.original.medical_record,
      encounterType: rowInfo.original.type,
      doctorId: rowInfo.original.doctor,
      checkInTime: rowInfo.original.date,
      dischargeTime: rowInfo.original.discharge_date,
      isSecret: rowInfo.original.is_secret,
      status: rowInfo.original.status,
    });
  };

  const IOPDButton = () => {
    return (
      <Button.Group compact size="mini">
        <Button
          disabled={!enableSelect}
          attached="left"
          name="IPD"
          toggle
          active={ipdType}
          onClick={handleClickEncounterType}
          style={{ paddingLeft: 7, paddingRight: 7 }}
        >
          IPD
        </Button>
        <Button
          disabled={!enableSelect}
          attached="right"
          name="OPD"
          toggle
          active={opdType}
          onClick={handleClickEncounterType}
          style={{ paddingLeft: 7, paddingRight: 7 }}
        >
          OPD
        </Button>
      </Button.Group>
    );
  };

  const BasicTitle = ({ name, value }) => {
    let title = (
      <>
        <Grid.Row style={styles.gridRow}>
          <Grid.Column
            textAlign="right"
            width={3}
            style={{ paddingLeft: 0, paddingRight: 0 }}
          >
            <Label style={styles.label}>
              <Header size="tiny"> {name ? name : "--"} </Header>
            </Label>
          </Grid.Column>
          <Grid.Column
            width={13}
            style={{ alignSelf: "center", paddingLeft: 4, paddingRight: 0 }}
          >
            <Label className="fluid">{value ? value : "-"}</Label>
          </Grid.Column>
        </Grid.Row>
      </>
    );
    return title;
  };

  const IconTitle = ({ name, value, isColor, disabled }) => {
    let title = (
      <>
        <Grid.Row style={styles.gridRow}>
          <Grid.Column
            textAlign="right"
            width={3}
            style={{ paddingLeft: 0, paddingRight: 0 }}
          >
            <Label style={styles.label}>
              <Header size="tiny"> {name ? name : "--"} </Header>
            </Label>
          </Grid.Column>
          <Grid.Column
            width={9}
            style={{ alignSelf: "center", paddingLeft: 4, paddingRight: 0 }}
          >
            <Label className="fluid">{value ? value : "-"}</Label>
          </Grid.Column>
          <Grid.Column size="mini" width={2}>
            <Button
              size="mini"
              color={isColor ? "red" : "teal"}
              icon
              style={{ padding: 4 }}
              disabled={disabled}
              onClick={handleOnClickADR}
            >
              <img src={Pills} />
            </Button>
          </Grid.Column>
        </Grid.Row>
      </>
    );
    return title;
  };

  return (
    <React.Fragment>
      <Modal
        size="tiny"
        open={alertAuthorized}
        onClose={() => {
          setAlertAuthroized(false);
        }}
      >
        <Modal.Header>แจ้งเตือน</Modal.Header>
        <Modal.Content>Encounter ถูกปกปิด</Modal.Content>
        <Modal.Actions>
          <Button
            negative
            onClick={() => {
              setAlertAuthroized(false);
            }}
          >
            {" "}
            ตกลง{" "}
          </Button>
        </Modal.Actions>
      </Modal>

      <ModalADRInfo
        open={openModADR}
        onClose={handleOnClickCloseADR}
        data={adrReaction}
      />
      <Segment style={showContentOnly ? { display: "none" } : { padding: 6 }}>
        <Form>
          <Form.Group inline style={{ marginBottom: 0 }}>
            <Grid padded columns={2}>
              <BasicTitle name="HN" value={hn} />
              <BasicTitle name={intl.formatMessage({id: "ชื่อ"})} value={fullName} />
              <BasicTitle name={intl.formatMessage({id: "เพศ"})} value={gender} />

              <IconTitle
                name="ADR"
                value={adr}
                disabled={!(selectedPatient && selectedPatient.hnPatient)}
                isColor={
                  adrReaction &&
                  adrReaction.items &&
                  adrReaction.items.length > 0
                    ? true
                    : false
                }
              />

              <BasicTitle name={intl.formatMessage({id: "วันเกิด"})} value={birthDay} />
              <BasicTitle name={intl.formatMessage({id: "อายุ"})} value={age} />
            </Grid>
          </Form.Group>
        </Form>
        <Divider hidden />
        <Header size="tiny" textAlign="center" style={{ margin: "10px" }}>
          Medical Note / Nurse’s record
        </Header>
        <Dropdown
          multiple
          disabled={!enableSelect}
          onChange={handleOnChangeNurseRecord}
          placeholder={intl.formatMessage({ id: "เลือกทั้งหมด" })}
          fluid
          search
          selection
          options={noteTypeList}
        />

        <Header size="tiny" textAlign="center">
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            ประเภทการรักษา
            <IOPDButton />
          </div>
        </Header>

        <div style={{ color: "#2F80ED" }}>Encounter List</div>

        <ReactTable
          data={encounterData}
          columns={enListColumns}
          minRows={3}
          showPagination={isPagination}
          defaultPageSize={limit}
          pages={totalPage}
          loading={isLoading}
          showPageSizeOptions={false}
          onPageChange={(e) => {
            setPage(e);
          }}
          getTrProps={(state, rowInfo) => {
            if (rowInfo && rowInfo.row) {
              return {
                onClick: (e) => {
                  handleSelectRow(rowInfo);
                },
                className:
                  rowInfo.index === selectedRow ? "blueSelectedRow" : "",
              };
            } else {
              return {};
            }
          }}
          getNoDataProps={() => {
            return { style: { display: "none" } };
          }}
          PaginationComponent={SimplePagination}
        />
      </Segment>
    </React.Fragment>
  );
};

PatientPanel.defaultProps = {
  patientPanelData: {},
  isLoading: false,
  handleOnChangeEncounterId: () => {},
  handleOnChangeEncounterFilter: () => {},
};

PatientPanel.propTypes = {
  patientPanelData: PropTypes.object,
  isLoading: PropTypes.bool,
  selectedRowEncounter: PropTypes.object,
  showContentOnly: PropTypes.bool,
  handleOnChangeEncounterId: PropTypes.func,
  handleOnChangeEncounterFilter: PropTypes.func,
};

export default PatientPanel;
