import React, { useMemo, useState, useEffect, CSSProperties } from "react";
import { Icon } from "semantic-ui-react";
// MUI
import MuiButton from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import axios, { Method } from "axios";
import moment from "moment";
import Cookies from "js-cookie";
import { useIntl } from "react-intl";

// Common
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";

// Config
import CONFIG from "config/config";

// Types
type DialogMessageProps = {
  content: string;
  contentPayload: {
    meta: {
      ok_action: ActionType;
      ok_target: string;
      ok_label: string;
      ok_label_en: string;
      cancel_action: ActionType;
      cancel_target: string;
      cancel_label: string;
      response_limit: "ONCE" | "NO_LIMIT";
      text: string;
      text_en: string;
      title: string;
      title_en: string;
      expire: string;
    };
    response: any;
  };
  getLastMessage?: Function;
};

type ActionType = "OPEN" | "CALL_GET" | "CALL_POST";

const COLORS = {
  light_green: "rgba(193, 235, 197, 0.6)",
  green: "rgba(45, 172, 1, 1)",
  half_blue: "rgba(1, 71, 163, 0.4)",
  blue: "#0147a3",
  light_blue: "#d6e1f1",
};

const styles = {
  button: {
    color: "white",
    boxShadow: "none",
    textTransform: "capitalize",
    backgroundColor: COLORS.blue,
    width: "auto",
    minWidth: "100px",
    padding: "3px 9px",
    fontSize: "1rem",
    borderRadius: "8px",
  } as CSSProperties,
};

const DialogMessage = (props: DialogMessageProps) => {
  const intl = useIntl();

  const [open, setOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isExpired, setIsExpired] = useState(false);

  // Memo Effect
  const meta = useMemo(() => {
    return props.contentPayload?.meta;
  }, [props.contentPayload]);

  // Effect
  useEffect(() => {
    const date = moment(meta?.expire);

    if (date.isValid()) {
      let interval = setInterval(() => {
        const diff = moment(date).diff(moment(), "minutes");

        if (diff < 0) {
          setIsExpired(true);
          clearInterval(interval);
        }
      }, 100);
    }
  }, [meta]);

  // Memo
  const exceeded = useMemo(() => {
    return meta?.response_limit === "ONCE" && props.contentPayload?.response;
  }, [meta?.response_limit, props.contentPayload?.response]);

  const checked = useMemo(() => {
    return !!(exceeded && !meta?.cancel_label);
  }, [exceeded, meta]);

  const okLabel = useMemo(() => {
    const label = intl.locale === "en" ? meta?.ok_label_en : meta?.ok_label;

    return checked ? props.contentPayload?.response : label;
  }, [checked, meta, props.contentPayload]);

  const content = useMemo(() => {
    const title = intl.locale === "en" ? meta?.text_en : meta?.text;

    return title || props.content;
  }, [meta, props.content]);

  const disabledButton = useMemo(() => {
    return isLoading || isExpired;
  }, [isLoading, isExpired]);

  /* ------------------------------------------------------ */
  /*                          APIs                          */
  /* ------------------------------------------------------ */
  const requestApiFromURL = async (method: Method, target: string) => {
    try {
      const response = await axios({
        method,
        url: `${CONFIG.API_HOST}${target}`,
        headers: {
          Authorization: `Token ${Cookies.get("apiToken")}`,
        },
      });

      return [response.data, null];
    } catch (error: any) {
      return [null, error.response?.data];
    }
  };

  // Handler
  const handleApprove = () => {
    if (!checked) {
      handleAction("ok");
    }
  };

  const handleDeny = () => {
    handleAction("cancel");
  };

  const handleAction = async (prefix: "ok" | "cancel") => {
    const actionType = meta[`${prefix}_action`] as ActionType;
    const target = meta[`${prefix}_target`];

    const action = {
      OPEN: handleOpen,
      CALL_GET: handleCallGet,
      CALL_POST: handleCallPost,
    }[actionType];

    if (target && action && !exceeded) {
      setIsLoading(true);

      const [_, err] = await action(target);

      if (err) {
        setIsLoading(false);

        return setErrorMessage(err);
      }
    }
    props.getLastMessage?.()
    handleCloseDialog();
  };

  const handleOpen = (target: string) => {
    globalThis.open(target, "_blank");

    return ["opened", null];
  };

  const handleCallGet = async (target: string) => {
    return await requestApiFromURL("get", target);
  };

  const handleCallPost = async (target: string) => {
    return await requestApiFromURL("post", target);
  };

  // -const handleOpenDialog = () => {
  //   setOpen(true);
  // };

  const handleCloseDialog = () => {
    setOpen(false);

    setIsLoading(false);
    setErrorMessage(null);
  };

  return (
    <>
      <div style={{ fontWeight: "bold", marginBottom: "0.5rem" }}>
        {intl.locale === "en" ? meta?.title_en : meta?.title}
      </div>
      <div
        // aria-hidden="true"
        // // callback
        // onClick={handleOpenDialog}
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      ></div>
      <div style={{ padding: "0.5rem 0px 0.25rem" }}>
        <MuiButton
          disabled={disabledButton}
          // style
          style={{
            ...styles.button,
            ...(disabledButton
              ? {
                  backgroundColor: COLORS.light_blue,
                  color: COLORS.half_blue,
                }
              : {}),
            ...(checked
              ? {
                  backgroundColor: COLORS.light_green,
                  color: COLORS.green,
                }
              : {}),
          }}
          // callback
          onClick={handleApprove}
        >
          <ButtonTitle label={okLabel} checked={checked} loading={isLoading} />
        </MuiButton>
      </div>

      <ModConfirm
        openModal={open}
        titleName="Dialog message"
        titleColor="blue"
        approveButtonText={meta?.ok_label}
        denyButtonText={meta?.cancel_label}
        errorMessageText={errorMessage}
        loading={isLoading}
        // config
        closeIcon={true}
        // callback
        onApprove={handleApprove}
        onDeny={handleDeny}
        onCloseWithDimmerClick={handleCloseDialog}
        // Element
        content={
          <div
            style={{
              textAlign: "center",
              marginBottom: errorMessage ? "-2rem" : "",
            }}
          >
            {meta?.text}
          </div>
        }
      />
    </>
  );
};

/* ------------------------------------------------------ */

/*                       ButtonTitle                      */

/* ------------------------------------------------------ */
type ButtonTitleProps = {
  loading: boolean;
  checked: boolean;
  label: string;
};

const ButtonTitle = (props: ButtonTitleProps) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {props.loading ? (
        <CircularProgress
          style={{
            margin: "0 0.25rem 0 0.15rem",
          }}
          size={16}
        />
      ) : (
        props.checked && (
          <Icon
            name="check circle"
            color="green"
            style={{ marginTop: "-10px" }}
          />
        )
      )}

      <label>{props.label || "\u00a0"}</label>
    </div>
  );
};

export default React.memo(DialogMessage);