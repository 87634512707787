import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Input,
  Dropdown,
  Button
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardWardQueueUX = (props: any) => {
    return(
      <div
        style={{padding: "0 1rem"}}>
        <div
          className="ui form">
          
          <FormGroup
            inline={true}
            widths={16}>
            <FormField
              inline={true}>
              <div
                style={{fontWeight: "bold", fontSize: "20px"}}>
                รายชื่อผู้ป่วยใน
              </div>
            </FormField>
            <FormField
              inline={true}
              style={{flex:1}}>
            </FormField>
            <FormField
              inline={true}
              style={{marginTop: "0.5rem", marginBottom: "1rem"}}>
              <label
                style={{fontSize: "16px", fontWeight: "bold"}}>
                Barcode/AN/HN
              </label>
              <Input
                onKeyDown={props.onBarcodeKeyDown}
                ref={props.barcodeRef}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            widths={16}>
            <FormField
              inline={true}
              width={4}>
              <label
                style={{width: "150px", fontSize: "16px", fontWeight: "bold"}}>
                Ward
              </label>
              <Dropdown
                clearable={true}
                disabled={props.loadingFilter}
                fluid={true}
                onChange={props.onChangeWard}
                options={props.wardOptions}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.ward}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <label
                style={{width: "150px", fontSize: "16px", fontWeight: "bold", textAlign: "center"}}>
                ช่วงวันที่
              </label>
              <div
                style={{width: "100%"}}>
                {props.startDateElement}
              </div>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <label
                style={{width: "60px", fontSize: "16px", fontWeight: "bold", textAlign: "center"}}>
                ถึง
              </label>
              <div
                style={{width: "100%"}}>
                
                <DateTextBox
                  disabled={props.loadingFilter}
                  onChange={props.onChangeEndDate}
                  value={props.endDate}>
                </DateTextBox>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            widths={16}>
            <FormField
              inline={true}
              width={4}>
              <label
                style={{width: "150px", fontSize: "16px", fontWeight: "bold"}}>
                สถานะผู้ป่วย
              </label>
              <Dropdown
                clearable={true}
                disabled={props.loadingFilter}
                fluid={true}
                onChange={props.onChangePatientStatus}
                options={props.patientStatusOptions}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.patientStatus}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <label
                style={{width: "150px", fontSize: "16px", fontWeight: "bold", textAlign: "center", margin: 0}}>
                เลขห้อง
              </label>
              <div
                style={{width: "100%"}}>
                {props.roomNumberSearch}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{width: "40px", fontSize: "16px", fontWeight: "bold", textAlign: "center"}}>
                
              </label>
              <Button
                color="blue"
                disabled={props.loadingFilter}
                loading={props.loadingFilter}
                onClick={props.onSearch}>
                ค้นหา
              </Button>
              <Button
                color="brown"
                disabled={props.disabledPrintReportBtn}
                onClick={props.onPrintReport}
                style={{...(!props.showPrintReportBtn && {display: "none"})}}>
                พิมพ์รายงาน
              </Button>
            </FormField>
            <FormField
              style={{flex:1}}>
            </FormField>
            <FormField
              inline={true}
              style={{padding:0, display: "none"}}>
              <Button
                color="orange"
                disabled={props.config?.disabledAdmitButton || false}
                onClick={props.onAdmitReceive}>
                รับเข้า
              </Button>
              <Button
                color="green"
                disabled={props.config?.disabledBedMoveButton || false}
                onClick={props.onBedMove}
                style={{display: props.config?.hideBedMoveButton ? "none" : ""}}>
                ย้ายเตียง
              </Button>
              <Button
                color="red"
                disabled={props.config?.disabledCancelAdmitButton || false}
                onClick={props.onCancelAdmit}>
                ยกเลิกรับเข้า
              </Button>
            </FormField>
          </FormGroup>
        </div>
        <div>
          
          <Table
            className="--rt-tbody-overflow-y-hidden"
            data={props.wardQueueList}
            getTrProps={props.getSelectedRow}
            headers="ห้อง,Ward,HN/AN,ข้อมูลผู้ป่วย,สถานะผู้ป่วย,สถานะห้อง,สิทธิ/Payer,แพทย์เจ้าของไข้,Diagnosis,New order, MENU"
            keys="room_no,ward_name,hn,patient_info,patient_status,room_status,coverage,primary_doctor,diagnosis,new_order,menu"
            minRows={10}
            showPagination={false}
            style={{height: "calc(100vh - 21.5rem)"}}
            widths="^70,^90,^90,^90,^90,^80,^80,^90,^90,^60,^60">
          </Table>
        </div>
      </div>
    )
}


export default CardWardQueueUX

export const screenPropsDefault = {"current":null}

/* Date Time : Mon Jan 08 2024 09:42:11 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding: \"0 1rem\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "FormGroup",
      "parent": 57,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "FormGroup",
      "parent": 57,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "FormGroup",
      "parent": 57,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "FormField",
      "parent": 58,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "FormField",
      "parent": 60,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "FormField",
      "parent": 61,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "FormField",
      "parent": 60,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "FormField",
      "parent": 60,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "FormField",
      "parent": 61,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "FormField",
      "parent": 61,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "FormField",
      "parent": 61,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding:0, display: \"none\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "div",
      "parent": 62,
      "props": {
        "children": {
          "type": "value",
          "value": "รายชื่อผู้ป่วยใน"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"20px\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "FormField",
      "parent": 58,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"0.5rem\", marginBottom: \"1rem\"}"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "label",
      "parent": 73,
      "props": {
        "children": {
          "type": "value",
          "value": "Barcode/AN/HN"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"16px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "Input",
      "parent": 73,
      "props": {
        "onKeyDown": {
          "type": "code",
          "value": "props.onBarcodeKeyDown"
        },
        "ref": {
          "type": "code",
          "value": "props.barcodeRef"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "FormField",
      "parent": 58,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "label",
      "parent": 65,
      "props": {
        "children": {
          "type": "value",
          "value": "Ward"
        },
        "style": {
          "type": "code",
          "value": "{width: \"150px\", fontSize: \"16px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "label",
      "parent": 67,
      "props": {
        "children": {
          "type": "value",
          "value": "ช่วงวันที่"
        },
        "style": {
          "type": "code",
          "value": "{width: \"150px\", fontSize: \"16px\", fontWeight: \"bold\", textAlign: \"center\"}"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "label",
      "parent": 68,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "style": {
          "type": "code",
          "value": "{width: \"60px\", fontSize: \"16px\", fontWeight: \"bold\", textAlign: \"center\"}"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "label",
      "parent": 66,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะผู้ป่วย"
        },
        "style": {
          "type": "code",
          "value": "{width: \"150px\", fontSize: \"16px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "label",
      "parent": 69,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขห้อง"
        },
        "style": {
          "type": "code",
          "value": "{width: \"150px\", fontSize: \"16px\", fontWeight: \"bold\", textAlign: \"center\", margin: 0}"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "Dropdown",
      "parent": 65,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.loadingFilter"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeWard"
        },
        "options": {
          "type": "code",
          "value": "props.wardOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ward"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "div",
      "parent": 67,
      "props": {
        "children": {
          "type": "code",
          "value": "props.startDateElement"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 85,
      "name": "Dropdown",
      "parent": 66,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.loadingFilter"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangePatientStatus"
        },
        "options": {
          "type": "code",
          "value": "props.patientStatusOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.patientStatus"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 68,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 88,
      "name": "DateTextBox",
      "parent": 87,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.loadingFilter"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeEndDate"
        },
        "value": {
          "type": "code",
          "value": "props.endDate"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "div",
      "parent": 69,
      "props": {
        "children": {
          "type": "code",
          "value": "props.roomNumberSearch"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 90,
      "name": "Button",
      "parent": 70,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.loadingFilter"
        },
        "loading": {
          "type": "code",
          "value": "props.loadingFilter"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSearch"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 91,
      "name": "Button",
      "parent": 70,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์รายงาน"
        },
        "color": {
          "type": "value",
          "value": "brown"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledPrintReportBtn"
        },
        "onClick": {
          "type": "code",
          "value": "props.onPrintReport"
        },
        "style": {
          "type": "code",
          "value": "{...(!props.showPrintReportBtn && {display: \"none\"})}"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 92,
      "name": "Button",
      "parent": 71,
      "props": {
        "children": {
          "type": "value",
          "value": "รับเข้า"
        },
        "color": {
          "type": "value",
          "value": "orange"
        },
        "disabled": {
          "type": "code",
          "value": "props.config?.disabledAdmitButton || false"
        },
        "onClick": {
          "type": "code",
          "value": "props.onAdmitReceive"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 93,
      "name": "Button",
      "parent": 71,
      "props": {
        "children": {
          "type": "value",
          "value": "ย้ายเตียง"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.config?.disabledBedMoveButton || false"
        },
        "onClick": {
          "type": "code",
          "value": "props.onBedMove"
        },
        "style": {
          "type": "code",
          "value": "{display: props.config?.hideBedMoveButton ? \"none\" : \"\"}"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 94,
      "name": "Button",
      "parent": 71,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิกรับเข้า"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "disabled": {
          "type": "code",
          "value": "props.config?.disabledCancelAdmitButton || false"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancelAdmit"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 95,
      "name": "FormField",
      "parent": 61,
      "props": {
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 96,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 97,
      "name": "Table",
      "parent": 96,
      "props": {
        "className": {
          "type": "value",
          "value": "--rt-tbody-overflow-y-hidden"
        },
        "data": {
          "type": "code",
          "value": "props.wardQueueList"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getSelectedRow"
        },
        "headers": {
          "type": "value",
          "value": "ห้อง,Ward,HN/AN,ข้อมูลผู้ป่วย,สถานะผู้ป่วย,สถานะห้อง,สิทธิ/Payer,แพทย์เจ้าของไข้,Diagnosis,New order, MENU"
        },
        "keys": {
          "type": "value",
          "value": "room_no,ward_name,hn,patient_info,patient_status,room_status,coverage,primary_doctor,diagnosis,new_order,menu"
        },
        "minRows": {
          "type": "code",
          "value": "10"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"calc(100vh - 21.5rem)\"}"
        },
        "widths": {
          "type": "value",
          "value": "^70,^90,^90,^90,^90,^80,^80,^90,^90,^60,^60"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": null,
      "id": 98,
      "name": "label",
      "parent": 70,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"40px\", fontSize: \"16px\", fontWeight: \"bold\", textAlign: \"center\"}"
        }
      },
      "seq": 90,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardWardQueueUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "current": null
  },
  "width": 80
}

*********************************************************************************** */
