import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Checkbox,
  Button,
  Dropdown,
  Input,
  Form,
  FormGroup,
  FormField
} from 'semantic-ui-react'

const CardWaitingListUX = (props: any) => {
    return(
      <div>

        <Form
          className="small">
          <FormGroup
            inline={true}
            style={{margin: "0.75rem 0"}}>
            <FormField
              inline={true}
              style={{padding:0, paddingLeft: "0.5rem"}}
              width={11}>
              <label>
                คลินิก
              </label>
              <Dropdown
                fluid={true}
                onChange={props.onChangeWaitingListDivision}
                options={props.divisionHasWaitingListOptions}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.selectedWaitingListDivisionId??""}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}
              style={{minWidth: "14.9rem"}}>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{marginBottom: "0.75rem"}}>
            <FormField
              inline={true}
              style={{padding:0, paddingLeft: "0.5rem"}}
              width={11}>
              <Input
                fluid={true}
                icon="filter"
                onChange={props.onChangeWaitingListSearch}
                placeholder="ระบุชื่อ WaitingList"
                style={{marginRight: "10px" , width: "100%"}}
                value={props.waitingListSearch}>
              </Input>
            </FormField>
            <FormField
              style={{padding:0}}>
              <Button
                color="blue"
                icon="refresh"
                loading={props.waitingListLoading}
                onClick={props.onRefresh}
                size="small"
                style={{marginRight: "10px"}}>
              </Button>
            </FormField>
            <FormField
              style={{paddingRight:"0.5rem", minWidth: "max-content"}}>
              <Button
                color="blue"
                onClick={props.onNewWaitingList}
                size="small"
                style={{...(props.hiddenCreateWaitingList && {display: "none"})}}>
                สร้าง Waiting List
              </Button>
            </FormField>
          </FormGroup>
        </Form>
        <Table
          NoDataComponent={() => null}
          data={props.waitingList}
          getTrProps={props.waitingListRowProps}
          headers="ชื่อ Waiting List, ผู้ป่วยรอทำนัดหมาย,ผู้ป่วยทั้งหมด"
          keys="name,pending,patient_count"
          minRows="3"
          showPagination={false}
          style={{height: "15vh"}}>
        </Table>
        <div
          style={{display: "flex", margin:"15px 0 15px 0", justifyContent: "space-between", alignItems: "center"}}>

          <div
            style={{display: "flex"}}>

            <div
              style={{fontSize: "1.2rem", fontWeight: "bold"}}>
              รายชื่อผู้ป่วย :
            </div>
            <div
              style={{fontSize: "1.2rem", fontWeight: "bold", paddingLeft: "5px"}}>
              { props.selectedWaitingListName}
            </div>
          </div>
          <div>

            <Button
              color="orange"
              disabled={props.disableWaitingListManage}
              onClick={props.setShowModStatic}
              size="tiny">
              สถิติ
            </Button>
            <Button
              color="blue"
              disabled={props.disableWaitingListManage}
              onClick={props.onShowModWaitingList}
              size="tiny">
              จัดการ
            </Button>
          </div>
        </div>
        <div
          style={{display: "flex",justifyContent: "flex-start", alignItems:"flex-start "}}>

          <div
            style={{paddingRight: "10px"}}>

            <div>
              ชื่อ - สกุล / HN
            </div>
            <Input
              disabled={props.disabledFilter}
              icon="filter"
              onChange={props.onSearchPatientNameBox}
              value={props.searchPatientNameBox}>
            </Input>
          </div>
          <div
            style={{paddingRight: "10px"}}>

            <div>
              สถานะ
            </div>
            <Dropdown
              disabled={props.disabledFilter}
              onChange={props.onSelectedStatus}
              options={props.statusOptions}
              selection={true}
              value={props.selectedStatus}>
            </Dropdown>
          </div>
          <div
            style={{paddingRight: "10px"}}>

            <div>
              แพทย์ / นิสิต
            </div>
            <Dropdown
              disabled={props.disabledFilterDoctor}
              onChange={props.onChangeFilterSelectedDoctor}
              options={props.filterDoctorOptions}
              selection={true}
              value={props.filterSelectedDoctor}>
            </Dropdown>
          </div>
          <div
            style={{display: "none", justifyContent: "center",alignSelf:"flex-end", padding: "5px 10px"}}>

            <Button
              color="brown">
              ค้นหา
            </Button>
          </div>
        </div>
        <div
          style={{display: "none", justifyContent: "space-between", alignItems: "center", padding: "20px 0px 10px 0px"}}>

          <Checkbox
            checked={props.isAppointed}
            disabled={props.disabledFilter}
            label="แสดงเฉพาะผู้ป่วยที่มีนัดหมายแล้ว"
            onClick={props.toggleIsAppointed}
            style={{display: "none"}}>
          </Checkbox>
        </div>
        <Table
          NoDataComponent={() => null}
          data={props.waitingListItems}
          getTrProps={props.waitingListItemRowProps}
          headers="ชื่อ-สกุล,เบอร์โทรติดต่อ,คลินิก,แพทย์/นิสิต,สถานะ"
          keys="patient_name,patient_tel,division_name,provider_name,status_name"
          minRows="4"
          showPagination={false}
          style={{height: "20vh",margin:"10px 0px"}}
          widths="150,auto,auto,auto,auto">
        </Table>
        <div
          style={{display: "flex", alignItems: "center", justifyContent: "space-between",marginTop: "15px", fontSize: "1.2rem", fontWeight: "bold", padding: "10px 0px"}}>
          {}
          <div
            style={{display:"flex", gap:"10px"}}>

            <div>
              นัดหมายก่อนหน้า
            </div>
            <div>
              {props.selectedName }
            </div>
          </div>
          <Button
            color="orange"
            disabled={props.disabledCreatePatientAppointment}
            onClick={props.createPatientAppointment}
            size="small">
            เพิ่มนัดหมายจาก waiting list
          </Button>
        </div>
        <Table
          NoDataComponent={() => null}
          data={props.appointmentList}
          getTrProps={props.appointmentRowProps}
          headers="ชื่อ-สกุล,คลินิก,แพทย์/นิสิต,ประเภทนัดหมาย,Waiting List,วันเวลานัดหมาย"
          keys="patient_name,display_info_division_name,display_info_provider_name,type_display,waiting_list_name,display_info_start_datetime_iso"
          minRows="4"
          showPagination={false}
          style={{height: "30vh"}}>
        </Table>
      </div>
    )
}


export default CardWaitingListUX

export const screenPropsDefault = {"patientSelector":"[Patient Selector]"}

/* Date Time : Wed Nov 20 2024 15:40:41 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 0
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 1,
      "name": "Table",
      "parent": 0,
      "props": {
        "NoDataComponent": {
          "type": "code",
          "value": "() => null"
        },
        "data": {
          "type": "code",
          "value": "props.waitingList"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.waitingListRowProps"
        },
        "headers": {
          "type": "value",
          "value": "ชื่อ Waiting List, ผู้ป่วยรอทำนัดหมาย,ผู้ป่วยทั้งหมด"
        },
        "keys": {
          "type": "value",
          "value": "name,pending,patient_count"
        },
        "minRows": {
          "type": "value",
          "value": "3"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"15vh\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 2,
      "name": "Table",
      "parent": 0,
      "props": {
        "NoDataComponent": {
          "type": "code",
          "value": "() => null"
        },
        "data": {
          "type": "code",
          "value": "props.waitingListItems"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.waitingListItemRowProps",
          "valueEN": ""
        },
        "headers": {
          "type": "value",
          "value": "ชื่อ-สกุล,เบอร์โทรติดต่อ,คลินิก,แพทย์/นิสิต,สถานะ"
        },
        "keys": {
          "type": "value",
          "value": "patient_name,patient_tel,division_name,provider_name,status_name"
        },
        "minRows": {
          "type": "value",
          "value": "4"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"20vh\",margin:\"10px 0px\"}"
        },
        "widths": {
          "type": "value",
          "value": "150,auto,auto,auto,auto"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", margin:\"15px 0 15px 0\", justifyContent: \"space-between\", alignItems: \"center\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"none\", justifyContent: \"space-between\", alignItems: \"center\", padding: \"20px 0px 10px 0px\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "Checkbox",
      "parent": 8,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isAppointed"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledFilter"
        },
        "label": {
          "type": "value",
          "value": "แสดงเฉพาะผู้ป่วยที่มีนัดหมายแล้ว"
        },
        "onChange": {
          "type": "code",
          "value": ""
        },
        "onClick": {
          "type": "code",
          "value": "props.toggleIsAppointed"
        },
        "style": {
          "type": "code",
          "value": "{display: \"none\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", justifyContent: \"space-between\",marginTop: \"15px\", fontSize: \"1.2rem\", fontWeight: \"bold\", padding: \"10px 0px\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 12,
      "name": "Table",
      "parent": 0,
      "props": {
        "NoDataComponent": {
          "type": "code",
          "value": "() => null"
        },
        "data": {
          "type": "code",
          "value": "props.appointmentList"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.appointmentRowProps"
        },
        "headers": {
          "type": "value",
          "value": "ชื่อ-สกุล,คลินิก,แพทย์/นิสิต,ประเภทนัดหมาย,Waiting List,วันเวลานัดหมาย"
        },
        "keys": {
          "type": "value",
          "value": "patient_name,display_info_division_name,display_info_provider_name,type_display,waiting_list_name,display_info_start_datetime_iso"
        },
        "minRows": {
          "type": "value",
          "value": "4"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"30vh\"}"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "Button",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มนัดหมายจาก waiting list"
        },
        "color": {
          "type": "value",
          "value": "orange"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledCreatePatientAppointment"
        },
        "onClick": {
          "type": "code",
          "value": "props.createPatientAppointment"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 28,
      "props": {
        "children": {
          "type": "value",
          "value": "รายชื่อผู้ป่วย : "
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.2rem\", fontWeight: \"bold\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 28,
      "props": {
        "children": {
          "type": "code",
          "value": " props.selectedWaitingListName"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.2rem\", fontWeight: \"bold\", paddingLeft: \"5px\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",justifyContent: \"flex-start\", alignItems:\"flex-start \"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{paddingRight: \"10px\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{paddingRight: \"10px\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "Button",
      "parent": 29,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "brown"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะ"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ - สกุล / HN"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "Dropdown",
      "parent": 22,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disabledFilter"
        },
        "onChange": {
          "type": "code",
          "value": "props.onSelectedStatus"
        },
        "options": {
          "type": "code",
          "value": "props.statusOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.selectedStatus"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"none\", justifyContent: \"center\",alignSelf:\"flex-end\", padding: \"5px 10px\"}"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "Input",
      "parent": 21,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disabledFilter"
        },
        "icon": {
          "type": "value",
          "value": "filter"
        },
        "onChange": {
          "type": "code",
          "value": "props.onSearchPatientNameBox"
        },
        "value": {
          "type": "code",
          "value": "props.searchPatientNameBox"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "Button",
      "parent": 34,
      "props": {
        "children": {
          "type": "value",
          "value": "สถิติ"
        },
        "color": {
          "type": "value",
          "value": "orange"
        },
        "disabled": {
          "type": "code",
          "value": "props.disableWaitingListManage"
        },
        "onClick": {
          "type": "code",
          "value": "props.setShowModStatic"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "Button",
      "parent": 34,
      "props": {
        "children": {
          "type": "value",
          "value": "จัดการ"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.disableWaitingListManage"
        },
        "onClick": {
          "type": "code",
          "value": "props.onShowModWaitingList"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", gap:\"10px\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "div",
      "parent": 37,
      "props": {
        "children": {
          "type": "value",
          "value": "นัดหมายก่อนหน้า"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 37,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectedName "
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{paddingRight: \"10px\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": "แพทย์ / นิสิต"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "Dropdown",
      "parent": 40,
      "props": {
        "clearable": {
          "type": "code",
          "value": ""
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledFilterDoctor"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilterSelectedDoctor"
        },
        "onClose": {
          "type": "code",
          "value": ""
        },
        "options": {
          "type": "code",
          "value": "props.filterDoctorOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.filterSelectedDoctor"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "Form",
      "parent": 0,
      "props": {
        "className": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "FormGroup",
      "parent": 43,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0.75rem 0\"}"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "FormGroup",
      "parent": 43,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.75rem\"}"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "FormField",
      "parent": 44,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding:0, paddingLeft: \"0.5rem\"}"
        },
        "width": {
          "type": "code",
          "value": "11"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "FormField",
      "parent": 45,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding:0, paddingLeft: \"0.5rem\"}"
        },
        "width": {
          "type": "code",
          "value": "11"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "FormField",
      "parent": 45,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding:0}"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "FormField",
      "parent": 45,
      "props": {
        "style": {
          "type": "code",
          "value": "{paddingRight:\"0.5rem\", minWidth: \"max-content\"}"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "Input",
      "parent": 47,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "icon": {
          "type": "value",
          "value": "filter"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeWaitingListSearch"
        },
        "placeholder": {
          "type": "value",
          "value": "ระบุชื่อ WaitingList"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"10px\" , width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.waitingListSearch"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "Button",
      "parent": 48,
      "props": {
        "color": {
          "type": "value",
          "value": "blue"
        },
        "icon": {
          "type": "value",
          "value": "refresh"
        },
        "loading": {
          "type": "code",
          "value": "props.waitingListLoading"
        },
        "onClick": {
          "type": "code",
          "value": "props.onRefresh"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"10px\"}"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "Button",
      "parent": 49,
      "props": {
        "children": {
          "type": "value",
          "value": "สร้าง Waiting List"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onNewWaitingList"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{...(props.hiddenCreateWaitingList && {display: \"none\"})}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "Dropdown",
      "parent": 46,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeWaitingListDivision"
        },
        "options": {
          "type": "code",
          "value": "props.divisionHasWaitingListOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedWaitingListDivisionId??\"\""
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "FormField",
      "parent": 44,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"14.9rem\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "label",
      "parent": 46,
      "props": {
        "children": {
          "type": "value",
          "value": "คลินิก"
        }
      },
      "seq": 53,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardWaitingListUX",
  "project": "cudent2",
  "screenPropsDefault": {
    "patientSelector": "[Patient Selector]"
  },
  "width": 25
}

*********************************************************************************** */
