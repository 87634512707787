import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Input
} from 'semantic-ui-react'

const CardSegmentSidebarUX = (props: any) => {
    return(
      <div>
        <div
          style={{ padding: "10px 0px", display: "flex" , alignItems: "center"}}>

          <Input
            fluid={true}
            icon="search"
            name="name"
            onChange={props.onChangeFilterSegment}
            onKeyDown={props.onKeyDownFilterSegment}
            size="small"
            style={{width: "100%"}}
            value={props.filterSegment?.name || ""}>
          </Input>
          <div>
            {props.buttonNew}
          </div>
        </div>
        <div
          style={{ height: "100%" }}>

          <div>
            {props.segmentList}
          </div>
        </div>
      </div>
    )
}


export default CardSegmentSidebarUX

export const screenPropsDefault = {}

/* Date Time : Thu Oct 17 2024 11:47:30 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"10px 0px\", display: \"flex\" , alignItems: \"center\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 2,
      "name": "Input",
      "parent": 1,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "icon": {
          "type": "value",
          "value": "search"
        },
        "name": {
          "type": "value",
          "value": "name"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilterSegment"
        },
        "onKeyDown": {
          "type": "code",
          "value": "props.onKeyDownFilterSegment"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filterSegment?.name || \"\""
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "code",
          "value": "props.segmentList"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ height: \"100%\" }"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonNew"
        }
      },
      "seq": 6,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardSegmentSidebarUX",
  "project": "cnmi",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
