import React from "react";
import PropTypes from "prop-types";
import * as CONSTANT from "../../utils/constant";
import CardLayout from "react-lib/apps/common/CardLayout";
import SubFormViewer from "react-lib/apps/PTM/SubFormViewer";
import { Label } from "semantic-ui-react";
import { fallRiskHigOptions } from "react-lib/apps/HISV3/PTM/CardAssessmentBundle";
import { useIntl } from "react-intl";

const CardFallRiskAssessment = React.forwardRef((props, ref) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [status, setStatus] = React.useState(null);
  const [fallRiskAssessmentData, setFallRiskAssessmentData] = React.useState({});

  React.useImperativeHandle(ref, () => ({
    refresh: ({ encounterId } = {}) => {
      refresh({ encounterId });
    },
  }));

  React.useEffect(() => {
    refresh({ encounterId: props.encounterId });
  }, [props.encounterId]);

  const refresh = ({ encounterId } = {}) => {
    getFormDataLatest({ encounterId });
  };

  const getFormDataLatest = async ({ encounterId }) => {
    setIsLoading(true);

    // checkForm
    const [formAssessmentV1, formAssessmentV2] = await Promise.all([
      props.controller.getFormDataLatest({
        encounterId: encounterId ? encounterId : props.patientData.ENCOUNTER.encounter_id,
        formCode: "CardFallRiskAssessment",
        formVersion: "0.1",
      }),
      props.controller.getFormDataLatest({
        encounterId: encounterId ? encounterId : props.patientData.ENCOUNTER.encounter_id,
        formCode: "CardFallRiskAssessment",
        formVersion: "0.2",
      }),
    ]);

    let fallRiskDataV2 = [];

    if (formAssessmentV2?.[0]) {
      let assessmentData = formAssessmentV2?.[0]?.data;

      let newData = (assessmentData || [])?.map((item: any) => {
        return {
          ...item,
          fall_risk: item?.fall_riskV2,
        };
      });

      fallRiskDataV2 = newData;
    }

    let data = formAssessmentV1?.[0]
      ? formAssessmentV1?.[0]
      : {
          ...formAssessmentV2?.[0],
          data: fallRiskDataV2,
        };

    // const [data, error] = await props.controller.getFormDataLatest({
    //   encounterId: encounterId
    //     ? encounterId
    //     : props.patientData.ENCOUNTER.encounter_id,
    //   formCode: "CardFallRiskAssessment",
    //   formVersion: "0.1"
    // });

    if (data) {
      let fallRiskData = data?.data.map((item: any) => {
        let fallRiskHigValue;

        if (item?.fall_riskHigValue) {
          fallRiskHigValue = item?.fall_riskHigValue.map((values: number) => {
            return (
              <div> - {fallRiskHigOptions.find((data: any) => data.value === values)?.text}</div>
            );
          });
        }

        return {
          ...item,
          fall_risk:
            item?.fall_risk === "HIG"
              ? "High"
              : item?.fall_risk === "LOW"
              ? "Low"
              : item?.fall_risk,
          ...(item?.fall_risk === "HIG" &&
            item?.fall_riskHigValue && {
              fallRiskHigValue: <>{fallRiskHigValue}</>,
            }),
          ...(item?.fall_risk === "HIG" &&
            (item?.fall_riskHigValue).includes(9) &&
            item?.fall_riskText && {
              fallRiskText: item?.fall_riskText,
            }),
        };
      });

      setStatus(data.status);
      setFallRiskAssessmentData(fallRiskData);
    }
    setIsLoading(false);
  };

  let statusColor = "black";
  if (status === CONSTANT.FORM_DATA_STATUS.DRAFT) {
    statusColor = "yellow";
  } else if (status === CONSTANT.FORM_DATA_STATUS.EDITED) {
    statusColor = "olive";
  } else if (status === CONSTANT.FORM_DATA_STATUS.CONFIRMED) {
    statusColor = "green";
  } else if (status === CONSTANT.FORM_DATA_STATUS.CANCELLED) {
    statusColor = "red";
  }

  let labelStatus = <Label color={statusColor}>{status}</Label>;

  return (
    <CardLayout
      headerColor="grey"
      loading={isLoading}
      titleText="Fall Risk Assessment"
      onClose={props.onClose}
      titleContent={labelStatus}
    >
      {props.showSummary ? (
        <SubFormViewer
          fieldList={rmdFieldList}
          fieldMap={rmdFieldMap}
          data={fallRiskAssessmentData}
          isFallRisk={true}
          languageUX={props.languageUX}
        />
      ) : (
        "TODO ---> Implement ShowSummary = false"
      )}
    </CardLayout>
  );
});

const rmdFieldList = [
  "user_fullname",
  "fall_risk",
  "fallRiskHigValue",
  "fallRiskText",
  "nursing",
  "date",
];

const rmdFieldMap = {
  user_fullname: "ผู้บันทึก/แก้ไข",
  fall_risk: "Fall Risk Assessment",
  nursing: "การให้การพยาบาลผู้ป่วย",
  date: "Date",
};

CardFallRiskAssessment.defaultProps = {
  onClose: () => {},
  controller: {},
  encounterId: null,
  showSummary: false,
};

CardFallRiskAssessment.propTypes = {
  onClose: PropTypes.func,
  controller: PropTypes.object,
  encounterId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showSummary: PropTypes.bool,
};

export default React.memo(CardFallRiskAssessment);
