import BaseService from "./BaseService";
import { BLB } from "../../configs/apis";
export default class BLBService extends BaseService {
  getBloodGroupWithPatient(patientId) {
    return this.client
      .get(BLB.BLOOD_GROUP_WITH_PATIENT({ patient_id: patientId }))
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getBloodTransfusion(params) {
    return this.client
      .get(BLB.BLOOD_TRANSFUSION_LIST, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
}
