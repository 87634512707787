import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardDoctorFeeReportUX = (props: any) => {
    return(
      <div
        style={{ height: "calc(100dvh - 9rem)" , margin: "0 -0.75rem"}}>
        <div
          style={{ width: "100%", height: "100%", display: "grid", position: "relative", gridTemplateRows: "repeat(50, 1fr)", gridTemplateColumns: "repeat(50, 1fr)" }}>
          <div
            style={{ fontSize: "1.25rem", gridRow: "2/4", gridColumn: "2/21",  display: "flex", alignItems: "basline",minWidth: "max-content"}}>

            <div>
              รายงานค่าตอบแทน :
            </div>
            <div
              style={{marginLeft: "20px"}}>
              {props.doctorName}
            </div>
          </div>
          <div
            style={{ gridRow: "2/4", gridColumn: "20/50", display: 'flex',  justifyContent: "flex-end", alignItems: "center"  }}>

            <div>
              วันที่รักษาเริ่มต้น
            </div>
            <DateTextBox
              onChange={props.onChangeStartDate}
              style={{paddingLeft: "10px"}}
              value={props.startDate}>
            </DateTextBox>
            <div
              style={{paddingLeft: "10px"}}>
              ถึง
            </div>
            <DateTextBox
              onChange={props.onChangeEndDate}
              style={{paddingLeft: "10px"}}
              value={props.endDate}>
            </DateTextBox>
            <div
              style={{margin:"0 0.15rem 0 0.5rem"}}>
              {props.buttonSearch}
            </div>
            <div>
              {props.buttonExportReport}
            </div>
          </div>
          <div
            style={{ gridRow: "6/40", gridColumn: "2/50",  }}>
            <Table
              className="card-doctor-fee-order-compensation"
              columns={props.doctorFeeColumns}
              data={props.doctorFeeData}
              getTrProps={props.test}
              minRows="10"
              showPagination={false}
              style={{ height: "calc(100dvh - 15rem)", ...(props.tableStyle || {} ) }}>
            </Table>
          </div>
        </div>
      </div>
    )
}


export default CardDoctorFeeReportUX

export const screenPropsDefault = {"showSearchResult":false}

/* Date Time : Wed Aug 28 2024 09:56:54 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ height: \"calc(100dvh - 9rem)\" , margin: \"0 -0.75rem\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "label": "divgrid",
      "name": "div",
      "parent": 0,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"100%\", display: \"grid\", position: \"relative\", gridTemplateRows: \"repeat(50, 1fr)\", gridTemplateColumns: \"repeat(50, 1fr)\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"1.25rem\", gridRow: \"2/4\", gridColumn: \"2/21\",  display: \"flex\", alignItems: \"basline\",minWidth: \"max-content\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/4\", gridColumn: \"20/50\", display: 'flex',  justifyContent: \"flex-end\", alignItems: \"center\"  }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/40\", gridColumn: \"2/50\",  }"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 17,
      "name": "Table",
      "parent": 6,
      "props": {
        "className": {
          "type": "value",
          "value": "card-doctor-fee-order-compensation"
        },
        "columns": {
          "type": "code",
          "value": "props.doctorFeeColumns"
        },
        "data": {
          "type": "code",
          "value": "props.doctorFeeData"
        },
        "getTdProps": {
          "type": "code",
          "value": ""
        },
        "getTrProps": {
          "type": "code",
          "value": "props.test"
        },
        "headers": {
          "type": "value",
          "value": ""
        },
        "keys": {
          "type": "value",
          "value": ""
        },
        "minRows": {
          "type": "value",
          "value": "10"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"calc(100dvh - 15rem)\", ...(props.tableStyle || {} ) }"
        },
        "widths": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 20,
      "name": "DateTextBox",
      "parent": 3,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeStartDate"
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"10px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.startDate"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"10px\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 22,
      "name": "DateTextBox",
      "parent": 3,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeEndDate"
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"10px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.endDate"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "รายงานค่าตอบแทน :"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "code",
          "value": "props.doctorName"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"20px\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่รักษาเริ่มต้น"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonExportReport"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearch"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"0 0.15rem 0 0.5rem\"}"
        }
      },
      "seq": 27,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardDoctorFeeReportUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "showSearchResult": false
  },
  "width": 80
}

*********************************************************************************** */
