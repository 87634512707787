import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Checkbox,
  Input,
  Label,
  TextArea
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardSurgicalSafetyChecklistTimeoutUX = (props: any) => {
    return(
      <div
        style={{ margin: "15px", padding: "20px", backgroundColor: "rgba(198, 235, 243, 0.2)" }}>
        <div
          style={{ paddingBottom: "5px" }}>

          <div
            style={{display: "flex", justifyContent: "space-between"}}>

            <label
              style={{ fontWeight: "bold", fontSize: "18px" }}>
              Surgical Safety Checklist - Time out
            </label>
            <Label
              style={{display: props.status? "": "none"}}>
              {props.status}
            </Label>
          </div>
          <hr>
          </hr>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>

          <div
            style={{ fontWeight: "bold", width: "30%", margin: "5px 10px 0px 0px" }}>

            <label>
              All team member have introduced themselves
            </label>
            <label
              style={{ color: "red" }}>
              *
            </label>
          </div>
          <Checkbox
            checked={props.surgicalSafety?.data?.team_introduce}
            label={props.surgicalSafety?.data?.team_introduce ? "Yes" : "No"}
            onChange={props.handleChangeValue("team_introduce")}
            toggle={true}>
          </Checkbox>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>

          <div
            style={{ fontWeight: "bold", width: "30%", margin: "5px 10px 0px 0px" }}>

            <label>
              Patient Identify/Site/Procedure confirmed
            </label>
            <label
              style={{ color: "red" }}>
              *
            </label>
          </div>
          <Checkbox
            checked={props.surgicalSafety?.data?.patient_identify}
            label={props.surgicalSafety?.data?.patient_identify ? "Yes" : "No"}
            onChange={props.handleChangeValue("patient_identify")}
            toggle={true}>
          </Checkbox>
        </div>
        <div
          className="ui form"
          style={{ display: "flex", paddingBottom: "5px" }}>

          <div
            style={{ fontWeight: "bold", width: "30%", margin: "5px 10px 0px 0px" }}>

            <label>
              Antibiotic prophylaxis
            </label>
            <label
              style={{ color: "red" }}>
              *
            </label>
          </div>
          <Checkbox
            checked={props.surgicalSafety?.data?.antibiotic}
            label={props.surgicalSafety?.data?.antibiotic ? "Applicable" : "Not applicable"}
            onChange={props.handleChangeValue("antibiotic")}
            toggle={true}>
          </Checkbox>
          <TextArea
            name="antibioticRemark"
            onChange={props.handleChangeValue("antibioticRemark")}
            style={{ width: "30%", margin: "0px 30px", ...(!props.surgicalSafety?.data?.antibiotic && { display: "none" }) }}
            value={props.surgicalSafety?.data?.antibioticRemark}>
          </TextArea>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>

          <div
            style={{ fontWeight: "bold", width: "30%", margin: "5px 10px 0px 0px" }}>

            <label>
              Essential Imaging
            </label>
            <label
              style={{ color: "red" }}>
              *
            </label>
          </div>
          <Checkbox
            checked={props.surgicalSafety?.data?.essential_imaging}
            label={props.surgicalSafety?.data?.essential_imaging ? "Yes" : "No"}
            onChange={props.handleChangeValue("essential_imaging")}
            toggle={true}>
          </Checkbox>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>

          <label
            style={{ fontWeight: "bold", width: "30%", margin: "5px 10px 0px 0px" }}>
            Anticipated Critical Events
          </label>
          <Checkbox
            checked={props.surgicalSafety?.data?.critical_events}
            label={props.surgicalSafety?.data?.critical_events ? "Yes" : "No"}
            onChange={props.handleChangeValue("critical_events")}
            toggle={true}>
          </Checkbox>
        </div>
        <hr>
        </hr>
        <div
          style={{ display: "flex", padding: "15px 0px 5px", color: "#0C5185", fontWeight: "bold" }}>
          To Nursing Team :
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>

          <label
            style={{ fontWeight: "bold", width: "30%", margin: "5px 10px 0px 0px" }}>
            Has sterility (including indicator results) been confined
          </label>
          <Checkbox
            checked={props.surgicalSafety?.data?.has_sterility}
            label={props.surgicalSafety?.data?.has_sterility ? "Yes" : "No"}
            onChange={props.handleChangeValue("has_sterility")}
            toggle={true}>
          </Checkbox>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>

          <label
            style={{ fontWeight: "bold", width: "30%", margin: "5px 10px 0px 0px" }}>
            Are there equipment issue or any concerns
          </label>
          <Checkbox
            checked={props.surgicalSafety?.data?.equipment_issue}
            label={props.surgicalSafety?.data?.equipment_issue ? "Yes" : "No"}
            onChange={props.handleChangeValue("equipment_issue")}
            toggle={true}>
          </Checkbox>
        </div>
        <div
          style={{ display: "flex", padding: "15px 0px 5px", color: "#0C5185", fontWeight: "bold" }}>
          To Surgeon :
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>

          <label
            style={{ fontWeight: "bold", width: "30%", margin: "5px 10px 0px 0px" }}>
            What are Critical or non-routine step?
          </label>
          <Checkbox
            checked={props.surgicalSafety?.data?.is_critical_step}
            label={props.surgicalSafety?.data?.is_critical_step ? "Yes" : "No"}
            onChange={props.handleChangeValue("is_critical_step")}
            toggle={true}>
          </Checkbox>
          <Input
            disabled={props.surgicalSafety?.data?.is_critical_step ? false : true}
            onChange={props.handleChangeValue("critical_step")}
            style={{ width: "50%", marginLeft: "20px" }}
            value={props.surgicalSafety?.data?.critical_step}>
          </Input>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>

          <label
            style={{ fontWeight: "bold", width: "30%", margin: "5px 10px 0px 0px" }}>
            How long will the case take
          </label>
          <Checkbox
            checked={props.surgicalSafety?.data?.is_long}
            label={props.surgicalSafety?.data?.is_long ? "Yes" : "No"}
            onChange={props.handleChangeValue("is_long")}
            toggle={true}>
          </Checkbox>
          <Input
            disabled={props.surgicalSafety?.data?.is_long ? false : true}
            onChange={props.handleChangeValue("long")}
            style={{ width: "50%", marginLeft: "20px" }}
            value={props.surgicalSafety?.data?.long}>
          </Input>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>

          <label
            style={{ fontWeight: "bold", width: "30%", margin: "5px 10px 0px 0px" }}>
            What is the anticipate blood loss
          </label>
          <Checkbox
            checked={props.surgicalSafety?.data?.is_blood_loss}
            label={props.surgicalSafety?.data?.is_blood_loss ? "Yes" : "No"}
            onChange={props.handleChangeValue("is_blood_loss")}
            toggle={true}>
          </Checkbox>
          <Input
            disabled={props.surgicalSafety?.data?.is_blood_loss ? false : true}
            onChange={props.handleChangeValue("blood_loss")}
            style={{ width: "50%", marginLeft: "20px" }}
            value={props.surgicalSafety?.data?.blood_loss}>
          </Input>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>

          <label
            style={{ fontWeight: "bold", width: "30%", margin: "5px 10px 0px 0px" }}>
            Are there any patient-specific concerns
          </label>
          <Checkbox
            checked={props.surgicalSafety?.data?.is_specific_concern}
            label={props.surgicalSafety?.data?.is_specific_concern ? "Yes" : "No"}
            onChange={props.handleChangeValue("is_specific_concern")}
            toggle={true}>
          </Checkbox>
          <Input
            disabled={props.surgicalSafety?.data?.is_specific_concern ? false : true}
            onChange={props.handleChangeValue("specific_concern")}
            style={{ width: "50%", marginLeft: "20px" }}
            value={props.surgicalSafety?.data?.specific_concern}>
          </Input>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>

          <div
            style={{ fontWeight: "bold", width: "30%", margin: "5px 10px 0px 0px" }}>

            <label>
              Time out
            </label>
            <label
              style={{ color: "red" }}>
              *
            </label>
          </div>
          <DateTextBox
            onChange={props.handleChangeDate("incision_date")}
            value={props.surgicalSafety?.data?.incision_date}>
          </DateTextBox>
          <div
            style={{ marginLeft: "10px" }}>
            {props.timeTextBox}
          </div>
        </div>
        <div
          style={{ height: "55px", paddingTop: "15px", display: "flex", justifyContent: "flex-end" }}>

          <div>
            {props.buttonSave}
          </div>
          <div
            style={{margin: "0px 10px"}}>
            {props.buttonConfirm}
          </div>
          <div>
            {props.buttonUnconfirm}
          </div>
        </div>
      </div>
    )
}


export default CardSurgicalSafetyChecklistTimeoutUX

export const screenPropsDefault = {}

/* Date Time : Fri Oct 11 2024 17:35:32 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ margin: \"15px\", padding: \"20px\", backgroundColor: \"rgba(198, 235, 243, 0.2)\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ paddingBottom: \"5px\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "hr",
      "parent": 1,
      "props": {
      },
      "seq": 159,
      "void": true
    },
    {
      "from": null,
      "id": 59,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ height: \"55px\", paddingTop: \"15px\", display: \"flex\", justifyContent: \"flex-end\" }"
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "div",
      "parent": 77,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"30%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "label",
      "parent": 78,
      "props": {
        "children": {
          "type": "value",
          "value": "All team member have introduced themselves"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "label",
      "parent": 78,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 81,
      "name": "Checkbox",
      "parent": 77,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.team_introduce"
        },
        "label": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.team_introduce ? \"Yes\" : \"No\""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"team_introduce\")"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "div",
      "parent": 82,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"30%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 84,
      "name": "label",
      "parent": 83,
      "props": {
        "children": {
          "type": "value",
          "value": "Patient Identify/Site/Procedure confirmed"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "label",
      "parent": 83,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 86,
      "name": "Checkbox",
      "parent": 82,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.patient_identify"
        },
        "label": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.patient_identify ? \"Yes\" : \"No\""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"patient_identify\")"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 87,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"30%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "label",
      "parent": 88,
      "props": {
        "children": {
          "type": "value",
          "value": "Antibiotic prophylaxis"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": null,
      "id": 90,
      "name": "label",
      "parent": 88,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 91,
      "name": "Checkbox",
      "parent": 87,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.antibiotic"
        },
        "label": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.antibiotic ? \"Applicable\" : \"Not applicable\""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"antibiotic\")"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 93,
      "name": "div",
      "parent": 92,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"30%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "label",
      "parent": 93,
      "props": {
        "children": {
          "type": "value",
          "value": "Essential Imaging"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "label",
      "parent": 93,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 96,
      "name": "Checkbox",
      "parent": 92,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.essential_imaging"
        },
        "label": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.essential_imaging ? \"Yes\" : \"No\""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"essential_imaging\")"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": null,
      "id": 99,
      "name": "label",
      "parent": 97,
      "props": {
        "children": {
          "type": "value",
          "value": "Anticipated Critical Events"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"30%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 101,
      "name": "Checkbox",
      "parent": 97,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.critical_events"
        },
        "label": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.critical_events ? \"Yes\" : \"No\""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"critical_events\")"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": null,
      "id": 125,
      "name": "hr",
      "parent": 0,
      "props": {
      },
      "seq": 102,
      "void": true
    },
    {
      "from": null,
      "id": 126,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "To Nursing Team :"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", padding: \"15px 0px 5px\", color: \"#0C5185\", fontWeight: \"bold\" }"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": null,
      "id": 127,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": null,
      "id": 128,
      "name": "label",
      "parent": 127,
      "props": {
        "children": {
          "type": "value",
          "value": "Has sterility (including indicator results) been confined"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"30%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 129,
      "name": "Checkbox",
      "parent": 127,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.has_sterility"
        },
        "label": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.has_sterility ? \"Yes\" : \"No\""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"has_sterility\")"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": null,
      "id": 130,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": null,
      "id": 131,
      "name": "label",
      "parent": 130,
      "props": {
        "children": {
          "type": "value",
          "value": "Are there equipment issue or any concerns"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"30%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 132,
      "name": "Checkbox",
      "parent": 130,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.equipment_issue"
        },
        "label": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.equipment_issue ? \"Yes\" : \"No\""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"equipment_issue\")"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": null,
      "id": 133,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "To Surgeon :"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", padding: \"15px 0px 5px\", color: \"#0C5185\", fontWeight: \"bold\" }"
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": null,
      "id": 134,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": null,
      "id": 135,
      "name": "label",
      "parent": 134,
      "props": {
        "children": {
          "type": "value",
          "value": "What are Critical or non-routine step?"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"30%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 136,
      "name": "Checkbox",
      "parent": 134,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.is_critical_step"
        },
        "label": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.is_critical_step ? \"Yes\" : \"No\""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"is_critical_step\")"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 137,
      "name": "Input",
      "parent": 134,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.is_critical_step ? false : true"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"critical_step\")"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\", marginLeft: \"20px\" }"
        },
        "value": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.critical_step"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": null,
      "id": 138,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": null,
      "id": 139,
      "name": "label",
      "parent": 138,
      "props": {
        "children": {
          "type": "value",
          "value": "How long will the case take"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"30%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 140,
      "name": "Checkbox",
      "parent": 138,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.is_long"
        },
        "label": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.is_long ? \"Yes\" : \"No\""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"is_long\")"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 141,
      "name": "Input",
      "parent": 138,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.is_long ? false : true"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"long\")"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\", marginLeft: \"20px\" }"
        },
        "value": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.long"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": null,
      "id": 142,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": null,
      "id": 143,
      "name": "label",
      "parent": 142,
      "props": {
        "children": {
          "type": "value",
          "value": "What is the anticipate blood loss"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"30%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 144,
      "name": "Checkbox",
      "parent": 142,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.is_blood_loss"
        },
        "label": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.is_blood_loss ? \"Yes\" : \"No\""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"is_blood_loss\")"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 145,
      "name": "Input",
      "parent": 142,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.is_blood_loss ? false : true"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"blood_loss\")"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\", marginLeft: \"20px\" }"
        },
        "value": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.blood_loss"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": null,
      "id": 146,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": null,
      "id": 147,
      "name": "label",
      "parent": 146,
      "props": {
        "children": {
          "type": "value",
          "value": "Are there any patient-specific concerns"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"30%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 148,
      "name": "Checkbox",
      "parent": 146,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.is_specific_concern"
        },
        "label": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.is_specific_concern ? \"Yes\" : \"No\""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"is_specific_concern\")"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 149,
      "name": "Input",
      "parent": 146,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.is_specific_concern ? false : true"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"specific_concern\")"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"50%\", marginLeft: \"20px\" }"
        },
        "value": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.specific_concern"
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": null,
      "id": 150,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": null,
      "id": 151,
      "name": "div",
      "parent": 150,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", width: \"30%\", margin: \"5px 10px 0px 0px\" }"
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": null,
      "id": 152,
      "name": "label",
      "parent": 151,
      "props": {
        "children": {
          "type": "value",
          "value": "Time out"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": null,
      "id": 153,
      "name": "label",
      "parent": 151,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 154,
      "name": "DateTextBox",
      "parent": 150,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDate(\"incision_date\")"
        },
        "value": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.incision_date"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": null,
      "id": 156,
      "name": "div",
      "parent": 59,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": null,
      "id": 157,
      "name": "div",
      "parent": 59,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonConfirm"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0px 10px\"}"
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": null,
      "id": 158,
      "name": "div",
      "parent": 59,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonUnconfirm"
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": null,
      "id": 159,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 160,
      "name": "label",
      "parent": 159,
      "props": {
        "children": {
          "type": "value",
          "value": "Surgical Safety Checklist - Time out"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"18px\" }"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 161,
      "name": "Label",
      "parent": 159,
      "props": {
        "children": {
          "type": "code",
          "value": "props.status"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.status? \"\": \"none\"}"
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": null,
      "id": 162,
      "name": "div",
      "parent": 150,
      "props": {
        "children": {
          "type": "code",
          "value": "props.timeTextBox"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"10px\" }"
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 163,
      "name": "TextArea",
      "parent": 87,
      "props": {
        "name": {
          "type": "value",
          "value": "antibioticRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"antibioticRemark\")"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\", margin: \"0px 30px\", ...(!props.surgicalSafety?.data?.antibiotic && { display: \"none\" }) }"
        },
        "value": {
          "type": "code",
          "value": "props.surgicalSafety?.data?.antibioticRemark"
        }
      },
      "seq": 163,
      "void": true
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardSurgicalSafetyChecklistTimeoutUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
