import React from 'react'
import {
   Button,
   Checkbox,
   Dropdown,
   Input,
} from 'semantic-ui-react'



import styles from './PTObjectiveNeuro.module.css'

export interface PTObjectiveNeuroViewDataType {
    PtObjectiveNeuro5: string,
    PtObjectiveNeuro5Disabled: boolean,
    Ct2Observation: string,
    Ct2ObservationDisabled: boolean,
    Ct2ObservationOptions: any,
    PtObjectiveNeuro10: string,
    PtObjectiveNeuro10Disabled: boolean,
    Ct8Consciousness: string,
    Ct8ConsciousnessDisabled: boolean,
    Ct8ConsciousnessOptions: any,
    Ct11Communication: string,
    Ct11CommunicationDisabled: boolean,
    Ct11CommunicationOptions: any,
    Ct11CoOperation: string,
    Ct11CoOperationDisabled: boolean,
    Ct11CoOperationOptions: any,
    PtObjectiveNeuro24: string,
    PtObjectiveNeuro24Disabled: boolean,
    PtObjectiveNeuro25: string,
    PtObjectiveNeuro25Disabled: boolean,
    PtObjectiveNeuro26: boolean,
    PtObjectiveNeuro26Disabled: boolean,
    PtObjectiveNeuro28: string,
    PtObjectiveNeuro28Disabled: boolean,
    PtObjectiveNeuro29: boolean,
    PtObjectiveNeuro29Disabled: boolean,
    PtObjectiveNeuro30: string,
    PtObjectiveNeuro30Disabled: boolean,
    PtObjectiveNeuro32: boolean,
    PtObjectiveNeuro32Disabled: boolean,
    PtObjectiveNeuro34: boolean,
    PtObjectiveNeuro34Disabled: boolean,
    PtObjectiveNeuro36: boolean,
    PtObjectiveNeuro36Disabled: boolean,
    PtObjectiveNeuro39: string,
    PtObjectiveNeuro39Disabled: boolean,
    PtObjectiveNeuro40: string,
    PtObjectiveNeuro40Disabled: boolean,
    PtObjectiveNeuro41: boolean,
    PtObjectiveNeuro41Disabled: boolean,
    PtObjectiveNeuro43: string,
    PtObjectiveNeuro43Disabled: boolean,
    PtObjectiveNeuro44: boolean,
    PtObjectiveNeuro44Disabled: boolean,
    PtObjectiveNeuro46: boolean,
    PtObjectiveNeuro46Disabled: boolean,
    PtObjectiveNeuro48: boolean,
    PtObjectiveNeuro48Disabled: boolean,
    PtObjectiveNeuro51: boolean,
    PtObjectiveNeuro51Disabled: boolean,
    PtObjectiveNeuro53: boolean,
    PtObjectiveNeuro53Disabled: boolean,
    PtObjectiveNeuro57: string,
    PtObjectiveNeuro57Disabled: boolean,
    PtObjectiveNeuro58: string,
    PtObjectiveNeuro58Disabled: boolean,
    PtObjectiveNeuro59: string,
    PtObjectiveNeuro59Disabled: boolean,
    PtObjectiveNeuro98: string,
    PtObjectiveNeuro98Disabled: boolean,
    Ct3SittingStatic: string,
    Ct3SittingStaticDisabled: boolean,
    Ct3SittingStaticOptions: any,
    Ct3SittingDynamic: string,
    Ct3SittingDynamicDisabled: boolean,
    Ct3SittingDynamicOptions: any,
    Ct2StandingStatic: string,
    Ct2StandingStaticDisabled: boolean,
    Ct2StandingStaticOptions: any,
    Ct2StandingDynamic: string,
    Ct2StandingDynamicDisabled: boolean,
    Ct2StandingDynamicOptions: any,
    CtTransferDependent: string,
    CtTransferDependentDisabled: boolean,
    CtTransferDependentOptions: any,
    PtObjectiveNeuro85: boolean,
    PtObjectiveNeuro85Disabled: boolean,
    PtObjectiveNeuro86: boolean,
    PtObjectiveNeuro86Disabled: boolean,
    PtObjectiveNeuro90: string,
    PtObjectiveNeuro90Disabled: boolean,
    CtGaitPatternAmbulation: string,
    CtGaitPatternAmbulationDisabled: boolean,
    CtGaitPatternAmbulationOptions: any,
    SaveRequestDisabled: boolean,
}

export const PTObjectiveNeuroInitialViewData: PTObjectiveNeuroViewDataType = {
    PtObjectiveNeuro5: "",
    PtObjectiveNeuro5Disabled: false,
    Ct2Observation: "",
    Ct2ObservationDisabled: false,
    Ct2ObservationOptions: [{key: 0, value: "A", text: "A"}],
    PtObjectiveNeuro10: "",
    PtObjectiveNeuro10Disabled: false,
    Ct8Consciousness: "",
    Ct8ConsciousnessDisabled: false,
    Ct8ConsciousnessOptions: [{key: 0, value: "A", text: "A"}],
    Ct11Communication: "",
    Ct11CommunicationDisabled: false,
    Ct11CommunicationOptions: [{key: 0, value: "A", text: "A"}],
    Ct11CoOperation: "",
    Ct11CoOperationDisabled: false,
    Ct11CoOperationOptions: [{key: 0, value: "A", text: "A"}],
    PtObjectiveNeuro24: "",
    PtObjectiveNeuro24Disabled: false,
    PtObjectiveNeuro25: "",
    PtObjectiveNeuro25Disabled: false,
    PtObjectiveNeuro26: false,
    PtObjectiveNeuro26Disabled: false,
    PtObjectiveNeuro28: "",
    PtObjectiveNeuro28Disabled: false,
    PtObjectiveNeuro29: false,
    PtObjectiveNeuro29Disabled: false,
    PtObjectiveNeuro30: "",
    PtObjectiveNeuro30Disabled: false,
    PtObjectiveNeuro32: false,
    PtObjectiveNeuro32Disabled: false,
    PtObjectiveNeuro34: false,
    PtObjectiveNeuro34Disabled: false,
    PtObjectiveNeuro36: false,
    PtObjectiveNeuro36Disabled: false,
    PtObjectiveNeuro39: "",
    PtObjectiveNeuro39Disabled: false,
    PtObjectiveNeuro40: "",
    PtObjectiveNeuro40Disabled: false,
    PtObjectiveNeuro41: false,
    PtObjectiveNeuro41Disabled: false,
    PtObjectiveNeuro43: "",
    PtObjectiveNeuro43Disabled: false,
    PtObjectiveNeuro44: false,
    PtObjectiveNeuro44Disabled: false,
    PtObjectiveNeuro46: false,
    PtObjectiveNeuro46Disabled: false,
    PtObjectiveNeuro48: false,
    PtObjectiveNeuro48Disabled: false,
    PtObjectiveNeuro51: false,
    PtObjectiveNeuro51Disabled: false,
    PtObjectiveNeuro53: false,
    PtObjectiveNeuro53Disabled: false,
    PtObjectiveNeuro57: "",
    PtObjectiveNeuro57Disabled: false,
    PtObjectiveNeuro58: "",
    PtObjectiveNeuro58Disabled: false,
    PtObjectiveNeuro59: "",
    PtObjectiveNeuro59Disabled: false,
    PtObjectiveNeuro98: "",
    PtObjectiveNeuro98Disabled: false,
    Ct3SittingStatic: "",
    Ct3SittingStaticDisabled: false,
    Ct3SittingStaticOptions: [{key: 0, value: "A", text: "A"}],
    Ct3SittingDynamic: "",
    Ct3SittingDynamicDisabled: false,
    Ct3SittingDynamicOptions: [{key: 0, value: "A", text: "A"}],
    Ct2StandingStatic: "",
    Ct2StandingStaticDisabled: false,
    Ct2StandingStaticOptions: [{key: 0, value: "A", text: "A"}],
    Ct2StandingDynamic: "",
    Ct2StandingDynamicDisabled: false,
    Ct2StandingDynamicOptions: [{key: 0, value: "A", text: "A"}],
    CtTransferDependent: "",
    CtTransferDependentDisabled: false,
    CtTransferDependentOptions: [{key: 0, value: "A", text: "A"}],
    PtObjectiveNeuro85: false,
    PtObjectiveNeuro85Disabled: false,
    PtObjectiveNeuro86: false,
    PtObjectiveNeuro86Disabled: false,
    PtObjectiveNeuro90: "",
    PtObjectiveNeuro90Disabled: false,
    CtGaitPatternAmbulation: "",
    CtGaitPatternAmbulationDisabled: false,
    CtGaitPatternAmbulationOptions: [{key: 0, value: "A", text: "A"}],
    SaveRequestDisabled: false,
}

const Sub = (props: any) => {
    const children = React.Children.toArray(props.children)
    return (
        <div className={styles.container}>
            <div className={styles.PtObjectiveNeuro1}>
                <div
                    style={{border: "solid black 1px", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.PtObjectiveNeuro4}>
                <div
                    style={{backgroundColor: "#F3F3F3", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.PtObjectiveNeuro5}>
                <Input
                    value={props.viewData.PtObjectiveNeuro5}
                    disabled={ props.viewData.PtObjectiveNeuro5Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTObjectiveNeuro',
                            name: 'PtObjectiveNeuro5',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtObjectiveNeuro6}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <h4><strong>Objective</strong></h4>
                </div>
            </div>
            <div className={styles.Ct2Observation}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct2Observation}
                    disabled={ props.viewData.Ct2ObservationDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "PTObjectiveNeuro",
                                name: "Ct2Observation",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct2ObservationOptions}
                />
            </div>
            <div className={styles.PtObjectiveNeuro10}>
                <Input
                    value={props.viewData.PtObjectiveNeuro10}
                    disabled={ props.viewData.PtObjectiveNeuro10Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTObjectiveNeuro',
                            name: 'PtObjectiveNeuro10',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtObjectiveNeuro11}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <h4><strong>Observation</strong></h4>
                </div>
            </div>
            <div className={styles.Ct8Consciousness}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct8Consciousness}
                    disabled={ props.viewData.Ct8ConsciousnessDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "PTObjectiveNeuro",
                                name: "Ct8Consciousness",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct8ConsciousnessOptions}
                />
            </div>
            <div className={styles.Ct11Communication}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct11Communication}
                    disabled={ props.viewData.Ct11CommunicationDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "PTObjectiveNeuro",
                                name: "Ct11Communication",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct11CommunicationOptions}
                />
            </div>
            <div className={styles.Ct11CoOperation}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct11CoOperation}
                    disabled={ props.viewData.Ct11CoOperationDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "PTObjectiveNeuro",
                                name: "Ct11CoOperation",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct11CoOperationOptions}
                />
            </div>
            <div className={styles.PtObjectiveNeuro21}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <h4><strong>Consciousness</strong></h4>
                </div>
            </div>
            <div className={styles.PtObjectiveNeuro22}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <h4><strong>Communication</strong></h4>
                </div>
            </div>
            <div className={styles.PtObjectiveNeuro23}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <h4><strong>Co-operation</strong></h4>
                </div>
            </div>
            <div className={styles.PtObjectiveNeuro24}>
                <Input
                    value={props.viewData.PtObjectiveNeuro24}
                    disabled={ props.viewData.PtObjectiveNeuro24Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTObjectiveNeuro',
                            name: 'PtObjectiveNeuro24',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtObjectiveNeuro25}>
                <Input
                    value={props.viewData.PtObjectiveNeuro25}
                    disabled={ props.viewData.PtObjectiveNeuro25Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTObjectiveNeuro',
                            name: 'PtObjectiveNeuro25',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtObjectiveNeuro26}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtObjectiveNeuro26}
                    disabled={ props.viewData.PtObjectiveNeuro26Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTObjectiveNeuro",
                                name: "PtObjectiveNeuro26"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtObjectiveNeuro27}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <h4><strong>Muscle length</strong></h4>
                </div>
            </div>
            <div className={styles.PtObjectiveNeuro28}>
                <Input
                    value={props.viewData.PtObjectiveNeuro28}
                    disabled={ props.viewData.PtObjectiveNeuro28Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTObjectiveNeuro',
                            name: 'PtObjectiveNeuro28',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtObjectiveNeuro29}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtObjectiveNeuro29}
                    disabled={ props.viewData.PtObjectiveNeuro29Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTObjectiveNeuro",
                                name: "PtObjectiveNeuro29"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtObjectiveNeuro30}>
                <Input
                    value={props.viewData.PtObjectiveNeuro30}
                    disabled={ props.viewData.PtObjectiveNeuro30Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTObjectiveNeuro',
                            name: 'PtObjectiveNeuro30',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtObjectiveNeuro31}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Normal</p>
                </div>
            </div>
            <div className={styles.PtObjectiveNeuro32}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtObjectiveNeuro32}
                    disabled={ props.viewData.PtObjectiveNeuro32Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTObjectiveNeuro",
                                name: "PtObjectiveNeuro32"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtObjectiveNeuro33}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Tightness</p>
                </div>
            </div>
            <div className={styles.PtObjectiveNeuro34}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtObjectiveNeuro34}
                    disabled={ props.viewData.PtObjectiveNeuro34Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTObjectiveNeuro",
                                name: "PtObjectiveNeuro34"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtObjectiveNeuro35}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Shortening</p>
                </div>
            </div>
            <div className={styles.PtObjectiveNeuro36}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtObjectiveNeuro36}
                    disabled={ props.viewData.PtObjectiveNeuro36Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTObjectiveNeuro",
                                name: "PtObjectiveNeuro36"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtObjectiveNeuro37}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Contracture</p>
                </div>
            </div>
            <div className={styles.PtObjectiveNeuro38}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>N/A</p>
                </div>
            </div>
            <div className={styles.PtObjectiveNeuro39}>
                <Input
                    value={props.viewData.PtObjectiveNeuro39}
                    disabled={ props.viewData.PtObjectiveNeuro39Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTObjectiveNeuro',
                            name: 'PtObjectiveNeuro39',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtObjectiveNeuro40}>
                <Input
                    value={props.viewData.PtObjectiveNeuro40}
                    disabled={ props.viewData.PtObjectiveNeuro40Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTObjectiveNeuro',
                            name: 'PtObjectiveNeuro40',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtObjectiveNeuro41}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtObjectiveNeuro41}
                    disabled={ props.viewData.PtObjectiveNeuro41Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTObjectiveNeuro",
                                name: "PtObjectiveNeuro41"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtObjectiveNeuro42}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <h4><strong>Muscle tone</strong></h4>
                </div>
            </div>
            <div className={styles.PtObjectiveNeuro43}>
                <Input
                    value={props.viewData.PtObjectiveNeuro43}
                    disabled={ props.viewData.PtObjectiveNeuro43Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTObjectiveNeuro',
                            name: 'PtObjectiveNeuro43',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtObjectiveNeuro44}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtObjectiveNeuro44}
                    disabled={ props.viewData.PtObjectiveNeuro44Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTObjectiveNeuro",
                                name: "PtObjectiveNeuro44"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtObjectiveNeuro45}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Normal</p>
                </div>
            </div>
            <div className={styles.PtObjectiveNeuro46}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtObjectiveNeuro46}
                    disabled={ props.viewData.PtObjectiveNeuro46Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTObjectiveNeuro",
                                name: "PtObjectiveNeuro46"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtObjectiveNeuro47}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Spasticity</p>
                </div>
            </div>
            <div className={styles.PtObjectiveNeuro48}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtObjectiveNeuro48}
                    disabled={ props.viewData.PtObjectiveNeuro48Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTObjectiveNeuro",
                                name: "PtObjectiveNeuro48"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtObjectiveNeuro49}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Flaccid</p>
                </div>
            </div>
            <div className={styles.PtObjectiveNeuro50}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>N/A</p>
                </div>
            </div>
            <div className={styles.PtObjectiveNeuro51}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtObjectiveNeuro51}
                    disabled={ props.viewData.PtObjectiveNeuro51Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTObjectiveNeuro",
                                name: "PtObjectiveNeuro51"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtObjectiveNeuro52}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <h4><strong>Motor Power:</strong></h4>
                </div>
            </div>
            <div className={styles.PtObjectiveNeuro53}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtObjectiveNeuro53}
                    disabled={ props.viewData.PtObjectiveNeuro53Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTObjectiveNeuro",
                                name: "PtObjectiveNeuro53"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtObjectiveNeuro54}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Left</p>
                </div>
            </div>
            <div className={styles.PtObjectiveNeuro55}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Right</p>
                </div>
            </div>
            <div className={styles.PtObjectiveNeuro56}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>N/A</p>
                </div>
            </div>
            <div className={styles.PtObjectiveNeuro57}>
                <Input
                    value={props.viewData.PtObjectiveNeuro57}
                    disabled={ props.viewData.PtObjectiveNeuro57Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTObjectiveNeuro',
                            name: 'PtObjectiveNeuro57',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtObjectiveNeuro58}>
                <Input
                    value={props.viewData.PtObjectiveNeuro58}
                    disabled={ props.viewData.PtObjectiveNeuro58Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTObjectiveNeuro',
                            name: 'PtObjectiveNeuro58',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtObjectiveNeuro59}>
                <Input
                    value={props.viewData.PtObjectiveNeuro59}
                    disabled={ props.viewData.PtObjectiveNeuro59Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTObjectiveNeuro',
                            name: 'PtObjectiveNeuro59',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtObjectiveNeuro98}>
                <Input
                    value={props.viewData.PtObjectiveNeuro98}
                    disabled={ props.viewData.PtObjectiveNeuro98Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTObjectiveNeuro',
                            name: 'PtObjectiveNeuro98',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtObjectiveNeuro60}>
                <div
                    style={{backgroundColor: "Lightgrey", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.Ct3SittingStatic}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct3SittingStatic}
                    disabled={ props.viewData.Ct3SittingStaticDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "PTObjectiveNeuro",
                                name: "Ct3SittingStatic",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct3SittingStaticOptions}
                />
            </div>
            <div className={styles.Ct3SittingDynamic}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct3SittingDynamic}
                    disabled={ props.viewData.Ct3SittingDynamicDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "PTObjectiveNeuro",
                                name: "Ct3SittingDynamic",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct3SittingDynamicOptions}
                />
            </div>
            <div className={styles.PtObjectiveNeuro67}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Sitting</strong></p>
                </div>
            </div>
            <div className={styles.PtObjectiveNeuro68}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Static</p>
                </div>
            </div>
            <div className={styles.PtObjectiveNeuro69}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Dynamic</p>
                </div>
            </div>
            <div className={styles.PtObjectiveNeuro70}>
                <div
                    style={{backgroundColor: "Lightgrey", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.Ct2StandingStatic}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct2StandingStatic}
                    disabled={ props.viewData.Ct2StandingStaticDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "PTObjectiveNeuro",
                                name: "Ct2StandingStatic",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct2StandingStaticOptions}
                />
            </div>
            <div className={styles.Ct2StandingDynamic}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct2StandingDynamic}
                    disabled={ props.viewData.Ct2StandingDynamicDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "PTObjectiveNeuro",
                                name: "Ct2StandingDynamic",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct2StandingDynamicOptions}
                />
            </div>
            <div className={styles.PtObjectiveNeuro77}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Standing</strong></p>
                </div>
            </div>
            <div className={styles.PtObjectiveNeuro78}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Static</p>
                </div>
            </div>
            <div className={styles.PtObjectiveNeuro79}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Dynamic</p>
                </div>
            </div>
            <div className={styles.PtObjectiveNeuro80}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <h4><strong>Balance test</strong></h4>
                </div>
            </div>
            <div className={styles.PtObjectiveNeuro81}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <h4><strong>Transfer to wheel chair</strong></h4>
                </div>
            </div>
            <div className={styles.CtTransferDependent}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtTransferDependent}
                    disabled={ props.viewData.CtTransferDependentDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "PTObjectiveNeuro",
                                name: "CtTransferDependent",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtTransferDependentOptions}
                />
            </div>
            <div className={styles.PtObjectiveNeuro85}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtObjectiveNeuro85}
                    disabled={ props.viewData.PtObjectiveNeuro85Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTObjectiveNeuro",
                                name: "PtObjectiveNeuro85"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtObjectiveNeuro86}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtObjectiveNeuro86}
                    disabled={ props.viewData.PtObjectiveNeuro86Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTObjectiveNeuro",
                                name: "PtObjectiveNeuro86"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtObjectiveNeuro87}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Independent</p>
                </div>
            </div>
            <div className={styles.PtObjectiveNeuro88}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Dependent</p>
                </div>
            </div>
            <div className={styles.PtObjectiveNeuro89}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <h4><strong>Gait pattern &amp; Ambulation</strong></h4>
                </div>
            </div>
            <div className={styles.PtObjectiveNeuro90}>
                <Input
                    value={props.viewData.PtObjectiveNeuro90}
                    disabled={ props.viewData.PtObjectiveNeuro90Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTObjectiveNeuro',
                            name: 'PtObjectiveNeuro90',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CtGaitPatternAmbulation}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtGaitPatternAmbulation}
                    disabled={ props.viewData.CtGaitPatternAmbulationDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "PTObjectiveNeuro",
                                name: "CtGaitPatternAmbulation",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtGaitPatternAmbulationOptions}
                />
            </div>
            <div className={styles.PtObjectiveNeuro94}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>By</strong></p>
                </div>
            </div>
            <div className={styles.SaveRequest}>
                <Button
                    disabled={ props.viewData.SaveRequestDisabled }
                    onClick={(e) => { props.onEvent({
                        message: 'clickButton',
                        params: {
                            view: 'PTObjectiveNeuro',
                            name: 'SaveRequest'
                        }
                    })}}
                    color="green"
                    style={{height: "100%", width: "100%"}}
                >
                    Save
                </Button>
            </div>
        </div>
    )
}

export default Sub

const getTsName = (cssClass: string) => {
    return cssClass.split('-').map((s) => (
        s.charAt(0).toUpperCase() + s.slice(1)
    )).join('')
}
