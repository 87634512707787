import LABService from "../services/LABService";
import { to } from "../../utils";
export default class LABManager {
  apiToken?: string | null;

  constructor(apiToken?: string | null) {
    this.apiToken = apiToken
  }

  getCenterLabDivision = async () => {
    var labDivisionList = {};
    var labDivisionError = [];
    const labService = new LABService(this.apiToken);
    const [error, response] = await to(labService.getCenterLabDivision());
    if (response) {
      labDivisionList = response;
    } else {
      labDivisionError = error;
    }
    return [labDivisionList, labDivisionError];
  };
  getCentalLabTestResultFitlerList = async (params: any) => {
    var labFilterList = [];
    var labFilterError = [];
    const labService = new LABService(this.apiToken);
    const [error, response] = await to(
      labService.getCentalLabTestResultFitlerList(params)
    );
    if (response) {
      labFilterList = response;
    } else {
      labFilterError = error;
    }
    return [labFilterList, labFilterError];
  };
  getCentalLabTestResultComparable = async (params: any) => {
    var labDataList = [];
    var labDataError = [];
    const labService = new LABService(this.apiToken);
    const [error, response] = await to(
      labService.postCenterLabResultComparable(params)
    );
    if (response) {
      labDataList = response;
    } else {
      labDataError = error;
    }
    return [labDataList, labDataError];
  };
  getInterfaceSummaryReportDetail = async (params: { cloi: any; }) => {
    var reportData = {};
    var reportError = {};
    const labService = new LABService(this.apiToken);
    const [error, response] = await to(
      labService.getInterfaceSummaryReportDetail(params)
    );
    if (response) {
      reportData = response;
    } else {
      reportError = error;
    }
    return [reportData, reportError];
  };

  getCentralLabResultList = async (params: any) => {
    var labData = {};
    var labError = {};
    const labService = new LABService(this.apiToken);
    const [error, response] = await to(
      labService.getCentralLabResultList(params)
    );
    if (response) {
      labData = response;
    } else {
      labError = error;
    }
    return [labData, labError];
  }

}
