import React, { useCallback, useEffect, useMemo, useRef } from "react";

import { toast } from "react-toastify";

// Types
export type NotificationItem = {
  id: number | string; // ใช้ Filter ข้อมูลภายใน Array
  time: string; // ใช้ เป็น toastId
  update?: string; // ใช้เมื่อ Update โดยเก็บ time ก่อนหน้าเพื่อใช้ลบ toast ก่อนหน้า
};

type PositionType = typeof toast.POSITION;

export type NotificationContainerProps<T extends NotificationItem> = {
  closeAllText?: string;
  notifications?: T[];
  noWidth?: boolean;
  position?: PositionType[keyof PositionType];
  renderCard: (props: T) => React.ReactNode;
  showCloseAll?: boolean;
  setNotifications: (notifications: T[]) => void;
};

const NotificationContainer = <T extends NotificationItem>(
  props: NotificationContainerProps<T>
) => {
  const notificationsRef = useRef<any[]>([]);

  useEffect(() => {
    notificationsRef.current = props.notifications || [];
  }, [props.notifications]);

  const handleCloseAll = useCallback(() => {
    props.setNotifications([]);
    toast.dismiss();
  }, []);

  useEffect(() => {
    let listDisableUpdate: T[] = [];

    const notifications = props.notifications || [];

    for (const item of notifications) {
      const widthClassName = props.noWidth ? "NotificationToast__width-unset" : "";

      const toastOption = {
        bodyClassName: "NotificationToast",
        className: `NotificationToast ${widthClassName}`,
        closeOnClick: false,
        position: props.position || toast.POSITION.TOP_RIGHT,
        toastId: item.time,
        onClose: () => {
          toast.dismiss(item.time);
          props.setNotifications(notificationsRef.current.filter((n) => n.id !== item.id));
        },
      };

      if (item.update) {
        toast.dismiss(item.update);

        setTimeout(() => {
          toast(props.renderCard(item), {
            ...toastOption,
            autoClose: false,
          });
        }, 1000 * 3);

        listDisableUpdate = notifications.map((notification) => ({
          ...notification,
          update: notification.id === item.id ? "" : notification.update,
        }));
      } else {
        toast(props.renderCard(item), {
          ...toastOption,
          autoClose: false,
        });
      }
    }

    if (notifications.length > 0 && listDisableUpdate.length > 0) {
      props.setNotifications(listDisableUpdate);
    }
  }, [props.notifications, props.position, props.renderCard]);

  const shouldShowCloseAll = useMemo(
    () => (props.notifications || []).length > 0 && props.showCloseAll,
    [props.notifications, props.showCloseAll]
  );

  return shouldShowCloseAll ? (
    <div
      aria-hidden="true"
      onClick={handleCloseAll}
      style={{
        background: "white",
        borderRadius: "15px",
        cursor: "pointer",
        padding: "5px 10px",
        position: "fixed",
        right: "1.5em",
        top: "0.5em",
        zIndex: "10000",
      }}
    >
      {props.closeAllText || "ปิดทั้งหมด"}
    </div>
  ) : null;
};

NotificationContainer.displayName = "NotificationContainer";

export default React.memo(NotificationContainer);
