import React, { useMemo } from "react";

import { styled } from "@mui/material/styles";
import { FormControlLabel, FormControlLabelProps } from "@mui/material";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { useIntl } from "react-intl";

interface Android12SwitchProps extends Omit<SwitchProps, "color"> {
  background?: string;
  label?: string;
  labelPlacement?: FormControlLabelProps["labelPlacement"];
  labelStyle?: React.CSSProperties;
  switchStyle?: React.CSSProperties;
}

const StyledSwitch = styled(Switch, {
  shouldForwardProp: (property) => !["background", "switchStyle"].includes(property as string),
})<Android12SwitchProps>(({ background = "#1976d2", switchStyle = {} }) => ({
  "& .MuiSwitch-switchBase": {
    "&.Mui-checked": {
      "& + .MuiSwitch-track": {
        backgroundColor: `${background} !important`,
        opacity: 1,
      },
      color: "white !important",
      left: 1,
      top: 1,
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.12,
    },
    color: `${background} !important`,
    left: 2,
    top: 1,
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    height: 14,
    margin: 2,
    width: 14,
  },
  "& .MuiSwitch-track": {
    "&:before, &:after": {
      height: 14,
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 14,
      content: '""',
    },
    backgroundColor: "white",
    border: `3px solid ${background}`,
    borderRadius: 22 / 2,
    opacity: 1,
  },
  padding: 8,
  ...switchStyle,
}));

const StyledFormControlLabel = styled(FormControlLabel, {
  shouldForwardProp: (property) => property !== "labelStyle",
})<{ labelStyle?: React.CSSProperties }>(({ labelStyle = {} }) => ({
  "& .MuiFormControlLabel-label": {
    fontFamily: "unset",
    fontSize: ".92857143em",
    fontWeight: "normal",
    ...labelStyle,
  },
}));

const Android12Switch: React.FC<Android12SwitchProps> = (props) => {
  const {
    background,
    label,
    labelPlacement = "end",
    labelStyle,
    switchStyle,
    ...otherProps
  } = props;

  const control = useMemo(
    () => (
      <StyledSwitch
        background={background}
        switchStyle={switchStyle}
        disableRipple
        {...otherProps}
      />
    ),
    [background, otherProps, switchStyle]
  );

  return (
    <StyledFormControlLabel
      checked={!!props.value}
      control={control}
      label={label}
      labelPlacement={labelPlacement}
      labelStyle={labelStyle}
    />
  );
};

export default React.memo(Android12Switch);
