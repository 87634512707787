import WasmController from "react-lib/frameworks/WasmController";
// apis
import FoodOrderList from "issara-sdk/apis/FoodOrderList_apps_FOD";
import FoodOrderReportList from "issara-sdk/apis/FoodOrderReportList_apps_FOD";
import moment from "moment";
import { formatDate } from "react-lib/utils/dateUtils";
import getPdfMake from "react-lib/appcon/common/pdfMake";
import FormFoodReport from "react-lib/apps/HISV3/FOD/FormFoodReport";
import FormStickerFood from "../FormStickerFood";
import { combinePdfFiles } from "../../common/CommonInterface";

export type State = {
  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;
  django?: any;
  foodOrderEditId?: number | null;
  // common
  selectedEmr?: any;

  // sequence
  FoodListSequence?: {
    sequenceIndex?: string | null;

    // data
    filterQueue?: any;
    foodData?: any;
    items?: any[];
    next?: any;
    previous?: any;
    total?: number;
    loadingData?: boolean;
  } | null;
};

export const StateInitial: State = {
  // sequence
  FoodListSequence: {
    sequenceIndex: null,

    // data
    filterQueue: {
      date: formatDate(moment()),
      meal: "",
    },
    foodData: {},
    items: [],
    next: null,
    previous: null,
    total: 0,
    loadingData: false,
  },
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  division?: number;
  masterData?: any;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const FetchData: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["divisionIpd", {}],
        ["regularMeal", {}],
      ],
    },
  });

  const [response] = await FoodOrderList.list({
    apiToken: controller.apiToken,
    params: { emr: state.selectedEmr?.id, limit: 200 },
  });

  controller.setState({
    FoodListSequence: {
      sequenceIndex: "PrintReport",
      filterQueue: {
        division: controller.data.division,
        date: formatDate(moment()),
        meal: "",
      },
      ...response,
    },
    foodOrderEditId: null,
  });

  if (!params.orderOnly) {
    PrintReport(controller, { action: "getFoodDate" });
  }

  // เมื่อมีการกด edit มาจากรายการอาหาร tab order summary
  if (!state.foodOrderEditId) {
    return;
  }

  const items: Record<string, any>[] = response?.items || [];

  const data = items.filter((item: any) => state.foodOrderEditId?.includes(item.id));

  if (data.length > 0) {
    controller.handleEvent({
      message: "RunSequence",
      params: {
        sequence: "FoodRequest",
        action: "SELECTED",
        data: data[0],
      },
    });
  }
};

export const PrintReport: Handler = async (controller, params) => {
  let state = controller.getState();

  if (params?.action === "getFoodDate") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        CardFoodReport_Search: "LOADING",
      },
      FoodListSequence: {
        ...state.FoodListSequence,
        loadingData: true,
      },
    });

    const [foodResp, foodErr, foodNet] = await FoodOrderReportList.list({
      apiToken: controller.apiToken,
      params: {
        hn: state.FoodListSequence?.filterQueue?.hn || null,
        first_name: state.FoodListSequence?.filterQueue?.fristName || null,
        last_name: state.FoodListSequence?.filterQueue?.lastName || null,
        division: state.FoodListSequence?.filterQueue?.division || null,
        date: state.FoodListSequence?.filterQueue?.date || null,
      },
    });

    if (foodErr) {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          CardFoodReport_Search: "SUCCESS",
        },
        FoodListSequence: {
          ...state.FoodListSequence,
          foodData: {},
          loadingData: false,
        },
      });
    } else {
      let result = {};
      foodResp?.items?.forEach((item: any) => {
        let tmp = { ...item };
        tmp.food_type_label = item?.food_type_name || "";
        if (item?.food_type_type === "R") {
          tmp.food_type_label =
            item?.food_type_name +
              "<br/>" +
              "<b>ปริมาตร : </b>" +
              item?.routine_size +
              "<br/>" +
              "<b>ความเข้มข้น : </b>" +
              item?.intensity +
              "<br/>" +
              "<b>Mixture : </b>" +
              item?.mixture +
              "<br/>" +
              "<b>Volume : </b>" +
              item?.mixture_volume +
              "<br/>" +
              "<b>หมายเหตุ : </b>" +
              item?.remark || "";
        }

        tmp.special_diet_label =
          "<b>อาหารเฉพาะโรค : </b>" +
          item?.special_diet_detail +
          "<br/>" +
          "<b>หมายเหตุ : </b>" +
          item?.special_diet_remark;
        tmp.nutrition_control_detail_label =
          "<b>คำสั่งควบคุมต่อวัน : </b>" +
            "<br/>" +
            item?.nutrition_control_detail +
            "<br/>" +
            "<b>หมายเหตุ : </b>" +
            item?.nutrition_control_remark || "";
        tmp.detail_label = item?.detail + "<br/>" + "<b>หมายเหตุ : </b>" + item?.remark || "";

        item?.times?.forEach((itm: any) => {
          if (result.hasOwnProperty(itm)) {
            result[itm].push(tmp);
          } else {
            result[itm] = [tmp];
          }
        });
      });
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          CardFoodReport_Search: "SUCCESS",
        },
        FoodListSequence: {
          ...state.FoodListSequence,
          foodData: result,
          loadingData: false,
        },
      });
    }
  } else if (params?.action === "printReport") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        CardFoodReport_Print: "LOADING",
      },
    });

    const pdfMake = await createPDF(controller, params);
    pdfMake.open();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        CardFoodReport_Print: "SUCCESS",
      },
    });
  } else if (params?.action === "stickerReport") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        CardFoodReport_Sticker: "LOADING",
      },
    });

    const regularMealData: any[] = controller.data.masterData?.regularMeal || [];

    const foodData: Record<string, any[]> = state.FoodListSequence?.foodData || {};

    const idEntries = Object.entries(params.checkedIds) as [string, number[]][];

    const forms = idEntries.map(([key, value]) =>
      value.map((id) => {
        const data = foodData[key].find((item: any) => item.id === id);
        const regularMeal = regularMealData.find((item) => item.regular_time === key)?.name || "";

        return FormStickerFood({
          ...data,
          printUser: state.django?.user?.full_name,
          regularMeal: regularMeal || key,
        });
      })
    );

    const blobUrl = await combinePdfFiles(await Promise.all(forms.flat()));

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        CardFoodReport_Sticker: "SUCCESS",
      },
    });

    params.onSuccess?.();

    globalThis.open(blobUrl);
  }

  if (!state.FoodListSequence) {
    return;
  }
};

const createPDF: Handler = async (controller, params) => {
  const state = controller.getState();

  var divisionName = controller?.data?.masterData?.divisionIpd?.filter(
    (item: any) => item?.id === state.FoodListSequence?.filterQueue?.division
  );

  let result:any[] = []
  Object.keys(state.FoodListSequence?.foodData || {}).sort()?.map((item: any) => {
    if (
      state.FoodListSequence?.filterQueue?.meal === undefined ||
      state.FoodListSequence?.filterQueue?.meal === "" ||
      state.FoodListSequence?.filterQueue?.meal === item
    ) {
      const foodData: any[] = state.FoodListSequence?.foodData?.[item] || [];
      const ids = params.checkedIds?.[item] || [];

      const filteredFood = foodData.filter((acc) => ids.includes(acc.id));

      if (filteredFood.length) {
        result.push({ time: item, data: filteredFood });
      }
    }
  })

  const data = Object.assign({
    date: moment(state.FoodListSequence?.filterQueue?.date, "DD/MM/YYYY")
      .locale("th")
      .format("DD MMMM YYYY"),
    division: divisionName[0]?.name,
    reportDetail: state.FoodListSequence?.filterQueue,
    foodData: result
  });

  let docDef: any = { content: [] };

  docDef = await FormFoodReport(data);

  return (await getPdfMake()).createPdf(docDef);
};
