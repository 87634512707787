import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button,
  Label,
  Icon,
  FormGroup,
  FormField,
  Dropdown,
  Input
} from 'semantic-ui-react'

const CardDrugTransferRequestUX = (props: any) => {
    return(
      <div
        style={{padding: "10px"}}>
        <div
          style={{display: "flex", justifyContent: "space-between"}}>
          
          <div
            style={{display: "flex", alignItems: "center"}}>
            
            <div
              className="ui label violet ribbon"
              style={{display: props.showStatus ? "" : "none"}}>
              {props.code}
            </div>
            <div
              style={{fontWeight: "bold", fontSize: "1.2rem"}}>
              ขอยาจากห้องยาอื่นๆ
            </div>
          </div>
          <div
            style={{display: "flex"}}>
            
            <Label
              color={props.status ? "red" : ""}
              style={{display: props.showStatus ? "" : "none"}}>
              {props.status}
            </Label>
            <div
              style={{cursor: "pointer", margin: "3px -5px 0 15px", display: props.closeIcon ? "" : "none"}}>
              
              <Icon
                className="red large"
                name={"close"}
                onClick={props.onClose}>
              </Icon>
            </div>
          </div>
        </div>
        <div
          className="ui divider"
          style={{margin: "0.5rem 0",  display: props.showStatus ? "" : "none"}}>
          
        </div>
        <div>
          {props.ErrorMessage}
        </div>
        <div
          className="ui form">
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <div
                style={{minWidth: "max-content"}}>
                หน่วยงานที่ขอ
              </div>
            </FormField>
            <FormField
              inline={true}>
              <Label
                style={{minWidth: "max-content"}}>
                {props.requesterName || "\u00a0-"}
              </Label>
            </FormField>
            <FormField
              inline={true}>
              <div
                style={{minWidth: "max-content"}}>
                หน่วยงานจ่าย
              </div>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Dropdown
                clearable={true}
                fluid={true}
                onChange={props.onChangeValue("selectedDivisionPharma")}
                options={props.divisionPharmaOptions}
                selection={true}
                style={{width: "100%"}}
                value={props.selectedDivisionPharma}>
              </Dropdown>
            </FormField>
            <div
              style={{"margin":"0px 1rem 0px 0.25rem","width":"10px","height":"10px","borderRadius":"50%","backgroundColor":"#b3b0b0", display: props.notAllowedAdd ? "none" : ""}}>
              
            </div>
            <FormField
              className={props.notAllowedAdd ? "hidden" : ""}
              inline={true}>
              <div
                style={{minWidth: "max-content"}}>
                เลือกยา
              </div>
            </FormField>
            <FormField
              className={props.notAllowedAdd ? "hidden" : ""}
              inline={true}
              width={3}>
              <div
                style={{width: "100%"}}>
                {props.drugSearch}
              </div>
            </FormField>
            <FormField
              className={props.notAllowedAdd ? "hidden" : ""}
              inline={true}>
              <div
                style={{minWidth: "max-content"}}>
                จำนวน
              </div>
            </FormField>
            <FormField
              className={props.notAllowedAdd ? "hidden" : ""}
              inline={true}
              width={2}>
              <Input
                fluid={true}
                onChange={props.onChangeValue("selectedDrugDose")}
                style={{width: "100%"}}
                type="number"
                value={props.selectedDrugDose || ""}>
              </Input>
            </FormField>
            <FormField
              className={props.notAllowedAdd ? "hidden" : ""}
              inline={true}>
              <Button
                color="green"
                onClick={props.onAddDrug}
                size="tiny">
                ADD
              </Button>
            </FormField>
            <FormField
              className={props.notAllowedAdd ? "hidden" : ""}
              inline={true}>
              <Button
                color="yellow"
                onClick={props.onClear}
                size="tiny">
                เคลียร์
              </Button>
            </FormField>
          </FormGroup>
          <FormGroup
            className={props.requested?.id ? "":"hidden"}
            inline={true}>
            <FormField
              inline={true}>
              <div
                style={{width: "50px"}}>
                ผู้ขอ
              </div>
            </FormField>
            <FormField
              inline={true}>
              <Input
                readOnly={true}
                value={props.requested?.name || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}>
              <div>
                เวลา
              </div>
            </FormField>
            <FormField
              inline={true}>
              <Input
                readOnly={true}
                value={props.requestedDate || ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            className={!props.canceled  ? "hidden":""}
            inline={true}>
            <FormField
              inline={true}>
              <div
                style={{width: "50px"}}>
                ผู้ยกเลิก
              </div>
            </FormField>
            <FormField
              inline={true}>
              <Input
                readOnly={true}
                value={props.canceled?.name || ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}>
              <div>
                เวลา
              </div>
            </FormField>
            <FormField
              inline={true}>
              <Input
                readOnly={true}
                value={props.canceledDate || ""}>
              </Input>
            </FormField>
          </FormGroup>
        </div>
        <div>
          
          <Table
            data={props.drugTransferRequestlist}
            getTrProps={props.drugRowProps}
            headers={`ลำดับ,รหัสยา,ชื่อยา,จำนวนขอ,จำนวนจ่าย,Onhand คลังขอ,Onhand คลังจ่าย,หน่วย${props.notAllowedAdd ? "": ","}`}
            keys={`no,code,name,request_quantity,storekey,onhand_requester,onhand_provider,stock_unit_name${props.notAllowedAdd ? "": ",remove"}`}
            minRows="10"
            showPagination={false}
            style={{height: "300px"}}
            widths={props.notAllowedAdd ? "" : ",,,,,,,,75"}>
          </Table>
        </div>
        <div
          style={{display: "flex",  marginTop: "10px"}}>
          
          <Button
            color="blue"
            onClick={props.onNew}
            size="tiny"
            style={{display: !props.requested?.id || props.notAllowedAdd ? "none":""}}>
            NEW
          </Button>
          <Button
            color="blue"
            onClick={props.onLog}
            size="tiny"
            style={{display: props.requested?.id ? "":"none"}}>
            LOG
          </Button>
          <div
            style={{flex:"1"}}>
            
          </div>
          <Button
            color="green"
            onClick={props.onRequest}
            size="tiny"
            style={{display: props.requested?.id ? "none":""}}>
            REQUEST
          </Button>
          <Button
            color="green"
            onClick={props.onEdit}
            size="tiny"
            style={{display: props.requested?.id  && !props.canceled ? "":"none"}}>
            EDIT
          </Button>
          <Button
            color="red"
            onClick={props.onCancel}
            size="tiny"
            style={{display: props.requested?.id  && !props.canceled ? "":"none"}}>
            CANCEL
          </Button>
        </div>
      </div>
    )
}

export default CardDrugTransferRequestUX

export const screenPropsDefault = {"drugSearch":"[Drug Search Box]"}

/* Date Time : Thu Mar 30 2023 04:23:35 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding: \"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  marginTop: \"10px\"}"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 5,
      "name": "Table",
      "parent": 3,
      "props": {
        "data": {
          "type": "code",
          "value": "props.drugTransferRequestlist"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.drugRowProps"
        },
        "headers": {
          "type": "code",
          "value": "`ลำดับ,รหัสยา,ชื่อยา,จำนวนขอ,จำนวนจ่าย,Onhand คลังขอ,Onhand คลังจ่าย,หน่วย${props.notAllowedAdd ? \"\": \",\"}`"
        },
        "keys": {
          "type": "code",
          "value": "`no,code,name,request_quantity,storekey,onhand_requester,onhand_provider,stock_unit_name${props.notAllowedAdd ? \"\": \",remove\"}`"
        },
        "minRows": {
          "type": "value",
          "value": "10"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"300px\"}"
        },
        "widths": {
          "type": "code",
          "value": "props.notAllowedAdd ? \"\" : \",,,,,,,,75\""
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "Button",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "REQUEST"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onRequest"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        },
        "style": {
          "type": "code",
          "value": "{display: props.requested?.id ? \"none\":\"\"}"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "Button",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "EDIT"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onEdit"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        },
        "style": {
          "type": "code",
          "value": "{display: props.requested?.id  && !props.canceled ? \"\":\"none\"}"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "Button",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "CANCEL"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancel"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        },
        "style": {
          "type": "code",
          "value": "{display: props.requested?.id  && !props.canceled ? \"\":\"none\"}"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:\"1\"}"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "Button",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "NEW"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onNew"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        },
        "style": {
          "type": "code",
          "value": "{display: !props.requested?.id || props.notAllowedAdd ? \"none\":\"\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "Button",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "LOG"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onLog"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        },
        "style": {
          "type": "code",
          "value": "{display: props.requested?.id ? \"\":\"none\"}"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ErrorMessage"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 91,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0.5rem 0\",  display: props.showStatus ? \"\" : \"none\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "div",
      "parent": 88,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": null,
      "id": 98,
      "name": "div",
      "parent": 88,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 99,
      "name": "div",
      "parent": 97,
      "props": {
        "children": {
          "type": "code",
          "value": "props.code"
        },
        "className": {
          "type": "value",
          "value": "ui label violet ribbon"
        },
        "style": {
          "type": "code",
          "value": "{display: props.showStatus ? \"\" : \"none\"}"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": null,
      "id": 100,
      "name": "div",
      "parent": 97,
      "props": {
        "children": {
          "type": "value",
          "value": "ขอยาจากห้องยาอื่นๆ"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.2rem\"}"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 101,
      "name": "Label",
      "parent": 98,
      "props": {
        "children": {
          "type": "code",
          "value": "props.status"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "color": {
          "type": "code",
          "value": "props.status ? \"red\" : \"\""
        },
        "name": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.showStatus ? \"\" : \"none\"}"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": null,
      "id": 102,
      "name": "div",
      "parent": 98,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{cursor: \"pointer\", margin: \"3px -5px 0 15px\", display: props.closeIcon ? \"\" : \"none\"}"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 103,
      "name": "Icon",
      "parent": 102,
      "props": {
        "className": {
          "type": "value",
          "value": "red large"
        },
        "name": {
          "type": "code",
          "value": "\"close\""
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": null,
      "id": 104,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 105,
      "name": "FormGroup",
      "parent": 104,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 106,
      "name": "FormField",
      "parent": 105,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 107,
      "name": "FormField",
      "parent": 105,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 108,
      "name": "FormField",
      "parent": 105,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 109,
      "name": "FormField",
      "parent": 105,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 110,
      "name": "FormField",
      "parent": 105,
      "props": {
        "className": {
          "type": "code",
          "value": "props.notAllowedAdd ? \"hidden\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 111,
      "name": "FormField",
      "parent": 105,
      "props": {
        "className": {
          "type": "code",
          "value": "props.notAllowedAdd ? \"hidden\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 112,
      "name": "FormField",
      "parent": 105,
      "props": {
        "className": {
          "type": "code",
          "value": "props.notAllowedAdd ? \"hidden\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 113,
      "name": "FormField",
      "parent": 105,
      "props": {
        "className": {
          "type": "code",
          "value": "props.notAllowedAdd ? \"hidden\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 114,
      "name": "FormField",
      "parent": 105,
      "props": {
        "className": {
          "type": "code",
          "value": "props.notAllowedAdd ? \"hidden\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": null,
      "id": 116,
      "name": "div",
      "parent": 106,
      "props": {
        "children": {
          "type": "value",
          "value": "หน่วยงานที่ขอ"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 117,
      "name": "Label",
      "parent": 107,
      "props": {
        "children": {
          "type": "code",
          "value": "props.requesterName || \"\\u00a0-\""
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": null,
      "id": 118,
      "name": "div",
      "parent": 108,
      "props": {
        "children": {
          "type": "value",
          "value": "หน่วยงานจ่าย"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 119,
      "name": "Dropdown",
      "parent": 109,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue(\"selectedDivisionPharma\")"
        },
        "options": {
          "type": "code",
          "value": "props.divisionPharmaOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedDivisionPharma"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": null,
      "id": 120,
      "name": "div",
      "parent": 110,
      "props": {
        "children": {
          "type": "value",
          "value": "เลือกยา"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": null,
      "id": 121,
      "name": "div",
      "parent": 111,
      "props": {
        "children": {
          "type": "code",
          "value": "props.drugSearch"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": null,
      "id": 122,
      "name": "div",
      "parent": 112,
      "props": {
        "children": {
          "type": "value",
          "value": "จำนวน"
        },
        "className": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 123,
      "name": "Input",
      "parent": 113,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue(\"selectedDrugDose\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.selectedDrugDose || \"\""
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 124,
      "name": "Button",
      "parent": 114,
      "props": {
        "children": {
          "type": "value",
          "value": "ADD"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onAddDrug"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 125,
      "name": "FormGroup",
      "parent": 104,
      "props": {
        "className": {
          "type": "code",
          "value": "props.requested?.id ? \"\":\"hidden\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 126,
      "name": "FormField",
      "parent": 125,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 127,
      "name": "FormField",
      "parent": 125,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 128,
      "name": "FormField",
      "parent": 125,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 129,
      "name": "FormField",
      "parent": 125,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": null,
      "id": 132,
      "name": "div",
      "parent": 126,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ขอ"
        },
        "style": {
          "type": "code",
          "value": "{width: \"50px\"}"
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 133,
      "name": "Input",
      "parent": 127,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.requested?.name || \"\""
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": null,
      "id": 134,
      "name": "div",
      "parent": 128,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลา"
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 135,
      "name": "Input",
      "parent": 129,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.requestedDate || \"\""
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 138,
      "name": "FormGroup",
      "parent": 104,
      "props": {
        "className": {
          "type": "code",
          "value": "!props.canceled  ? \"hidden\":\"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 139,
      "name": "FormField",
      "parent": 138,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 140,
      "name": "FormField",
      "parent": 138,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 141,
      "name": "FormField",
      "parent": 138,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 142,
      "name": "FormField",
      "parent": 138,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": null,
      "id": 145,
      "name": "div",
      "parent": 139,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ยกเลิก"
        },
        "style": {
          "type": "code",
          "value": "{width: \"50px\"}"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": null,
      "id": 146,
      "name": "div",
      "parent": 141,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลา"
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 149,
      "name": "Input",
      "parent": 142,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.canceledDate || \"\""
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 150,
      "name": "Input",
      "parent": 140,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.canceled?.name || \"\""
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 151,
      "name": "FormField",
      "parent": 105,
      "props": {
        "className": {
          "type": "code",
          "value": "props.notAllowedAdd ? \"hidden\" : \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 152,
      "name": "Button",
      "parent": 151,
      "props": {
        "children": {
          "type": "value",
          "value": "เคลียร์"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClear"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": null,
      "id": 153,
      "name": "div",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{\"margin\":\"0px 1rem 0px 0.25rem\",\"width\":\"10px\",\"height\":\"10px\",\"borderRadius\":\"50%\",\"backgroundColor\":\"#b3b0b0\", display: props.notAllowedAdd ? \"none\" : \"\"}"
        }
      },
      "seq": 110,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardDrugTransferRequestUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "drugSearch": "[Drug Search Box]"
  },
  "width": 80
}

*********************************************************************************** */
