import React from "react";
import { IntlProvider, FormattedMessage, useIntl } from "react-intl";

import { Table } from "react-lib/frameworks/Table";

const CardFoodReportUX = (props: any) => {
  return (
    <div style={{ margin: "0px 20px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          fontSize: "17px",
          fontWeight: "bold",
          alignItems: "center",
          background: "rgba(161, 221, 230, 0.49)",
          padding: "15px",
        }}
      >
        {props.title}
      </div>
      <Table
        data={props.foodData}
        headers={props.headers}
        keys="checkbox,room_lbl,hn,patient_fullname,food_type_lbl,nutrition_control_detail_lbl,detail_lbl"
        minRows="5"
        resizable={false}
        showPagination={false}
        style={{ height: "60vh" }}
        widths="50,^120, ^80,^120 ,^175,^225,^150"
      ></Table>
    </div>
  );
};

export default CardFoodReportUX;

export const screenPropsDefault = {};

/* Date Time : Fri Sep 27 2024 11:07:07 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 20px\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1062,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.title"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\",  fontSize: \"17px\", fontWeight: \"bold\", alignItems: \"center\", background: \"rgba(161, 221, 230, 0.49)\", padding: \"15px\" } "
        }
      },
      "seq": 571,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 1329,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.foodData"
        },
        "headers": {
          "type": "code",
          "value": "props.headers"
        },
        "keys": {
          "type": "value",
          "value": "checkbox,room_lbl,hn,patient_fullname,food_type_lbl,nutrition_control_detail_lbl,detail_lbl"
        },
        "minRows": {
          "type": "value",
          "value": "5"
        },
        "resizable": {
          "type": "code",
          "value": "false"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"60vh\" }"
        },
        "widths": {
          "type": "value",
          "value": "50,^120, ^80,^120 ,^175,^225,^150"
        }
      },
      "seq": 1329,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 90,
  "isMounted": false,
  "memo": false,
  "name": "CardFoodReportUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
