import React, {
  CSSProperties,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { Button, Checkbox, Dropdown, Form, Input, Menu, MenuItemProps } from "semantic-ui-react";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import DateTextBox from "react-lib/apps/common/DateTextBox";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import ModInfo from "react-lib/apps/common/ModInfo";
// #import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";
import SnackMessage from "react-lib/apps/common/SnackMessage";

// UX
import CardSettingPackageUX from "./CardSettingPackageUX";
import LoadingIcon from "./LoadingIcon";
import CardProductSearch, { OnSearchHandler } from "./CardProductSearch";
import SubSearchPackage, { SUB_SEARCH_PACKAGE } from "./SubSearchPackage";

// Interface
import {
  MasterOptionsType,
  PACKAGE_USAGE_STATUS,
  PRODUCT_TYPES,
  PackageDetailType,
  ProductDetailType,
  ProductTypeKey,
  RunSequence,
  SetProp,
  State,
} from "./sequence/SettingPackage";

// Utils
import { beToAd } from "react-lib/utils/dateUtils";

// Config
import config from "config/config";
import { useIntl } from "react-intl";

// Const c.
const API_HOST = `${config.API_HOST}`;

// Types
type CardSettingPackageProps = {
  onEvent: (e: any) => any;
  setProp: SetProp;
  // seq
  runSequence: RunSequence;
  SettingPackageSequence?: State["SettingPackageSequence"];
  // CommonInterface
  buttonLoadCheck?: Record<string, any>;
  errorMessage?: Record<string, any>;
  searchedItemListWithKey?: Record<string, any>;
  // options
  masterOptions?: MasterOptionsType;
  languageUX?: "th" | "en";
};

type PSTypeKey = keyof typeof PS_TYPES;

// Const
const TAB_PANS = [
  "ALL",
  "LAB",
  "IMAGING",
  "TREATMENT",
  "SUPPLY",
  "DRUG",
  "MISCELLANEOUS",
  "DOCTOR_FEE",
  "ROOM",
  "NURSE",
  "FOOD",
] as const;

const BUTTON_ACTIONS = {
  active: "ACTIVE",
  dup: "DUPLICATE",
  refresh: "REFRESH_OPTION",
  save: "SAVE",
};

const PS_TYPES = {
  PACKAGE_TYPE: "PACKAGE_TYPE",
  SERVICE_TYPE: "SERVICE_TYPE",
} as const;

const ADMINS = {
  [PS_TYPES.PACKAGE_TYPE]: "/admin/core/packagetype/",
  [PS_TYPES.SERVICE_TYPE]: "/admin/core/packageservicetype/",
};

const GridCenter = { display: "grid", placeContent: "center" } as CSSProperties;

export const CARD_SETTING_PACKAGE = "CardSettingPackage";

const ACTION_SAVE = `${CARD_SETTING_PACKAGE}_${BUTTON_ACTIONS.save}`;
const ACTION_DUP = `${CARD_SETTING_PACKAGE}_${BUTTON_ACTIONS.dup}`;
const ACTION_ACTIVE = `${CARD_SETTING_PACKAGE}_${BUTTON_ACTIONS.active}`;
const ACTION_REFRESH = `${CARD_SETTING_PACKAGE}_${BUTTON_ACTIONS.refresh}`;

const CardSettingPackage = (props: CardSettingPackageProps) => {
  const intl = useIntl();
  const [activeItem, setActiveItem] = useState<"ALL" | ProductTypeKey>("ALL");
  const [isNewProduct, setIsNewProduct] = useState(false);
  const [productType, setProductType] = useState<"" | ProductTypeKey>("");
  // Mod
  const [openModProductType, setOpenModProductType] = useState<boolean>(false);
  const [openModDuplicate, setOpenModDuplicate] = useState<boolean>(false);
  const [modDeleteProduct, setModDeleteProduct] = useState<ProductDetailType | null>(null);
  // data
  const [packageName, setPackageName] = useState<string>("");
  const [packageCode, setPackageCode] = useState<string>("");

  const windowRef = useRef<Record<string, Window | null>>({});

  // Use Effect
  useEffect(() => {
    props.runSequence({
      sequence: "SettingPackage",
      card: SUB_SEARCH_PACKAGE,
      restart: true,
    });

    return () => {
      props.runSequence({
        sequence: "SettingPackage",
        clear: true,
      });
    };
  }, []);

  useEffect(() => {
    if (
      !props.SettingPackageSequence?.showDetail &&
      Object.keys(props.SettingPackageSequence?.rawPackageDetail || {}).length > 0
    ) {
      props.setProp("SettingPackageSequence.rawPackageDetail", {});
    }
  }, [props.SettingPackageSequence?.rawPackageDetail, props.SettingPackageSequence?.showDetail]);

  useEffect(() => {
    setIsNewProduct(false);
  }, [props.SettingPackageSequence?.packageDetail?.id]);

  // Memo CAllback
  const productList = useMemo(
    () => props.SettingPackageSequence?.packageDetail?.items || [],
    [props.SettingPackageSequence?.packageDetail?.items]
  );

  const isActive = useMemo(
    () =>
      // props.SettingPackageSequence?.packageDetail?.active_flag === 1 &&
      !props.SettingPackageSequence?.packageDetail?.can_delete,
    [props.SettingPackageSequence?.packageDetail]
  );

  const tabName = useMemo(
    () =>
      productType in PRODUCT_TYPES ? PRODUCT_TYPES[productType as ProductTypeKey].name : "ALL",
    [productType]
  );

  // Use Callback
  const handleEditProduct = useCallback(
    (item: ProductDetailType) => (e: any, data: any) => {
      const list = [...productList];

      let value = typeof data.checked === "boolean" ? data.checked : data.value;

      const name = data.name as keyof ProductDetailType;

      // type number value ไม่น้อยกว่า 0
      if (["quantity"].includes(name)) {
        value = Number(value) < 0 ? 0 : value;

        const unitPrice = list[item.index].unit_price;

        (list as any)[item.index].price = Number(value) * Number(unitPrice);
      }

      (list as any)[item.index][name] = value;

      props.setProp("SettingPackageSequence.packageDetail.items", [...list]);
    },
    [productList]
  );

  const handleDeleteProduct = useCallback(
    (data: ProductDetailType) => () => {
      setModDeleteProduct(data);
    },
    []
  );

  // #const handleInputBlur = useCallback(
  //   (e: ChangeEvent<HTMLInputElement>) => {
  //     const name = e.target.name;
  //     const value = e.target.valueAsNumber;
  //     if (["age_limits_year_start", "age_limits_year_end"].includes(name)) {
  //       const detail = props.SettingPackageSequence?.packageDetail || {};
  //       const start = detail.age_limits_year_start || "";
  //       const end = detail.age_limits_year_end || "";
  //       let isError = false;
  //       // หากระบุ year start มากกว่า year end หรือ year end น้อยกว่า year start ให้แสดงข้อความแจ้งเตือน
  //       if (
  //         (name === "age_limits_year_start" &&
  //           value > Number(end) &&
  //           end !== "") ||
  //         (name === "age_limits_year_end" &&
  //           value < Number(start) &&
  //           start !== "")
  //       ) {
  //         isError = true;
  //       } else {
  //         isError = false;
  //       }
  //       props.setProp(
  //         "SettingPackageSequence.showRequiredField.age_range",
  //         isError
  //       );
  //     }
  //   },
  //   [props.SettingPackageSequence?.packageDetail]
  // );

  const formatPrice = useCallback(
    (price: string) =>
      Number(price).toLocaleString("en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        style: "decimal",
      }),
    []
  );

  const summary = useCallback((list: Record<string, any>[] = []) => {
    const total = list.reduce((result, item) => Number(result) + Number(item.price), 0).toFixed(2);

    return list.length > 0 ? formatPrice(total) : "";
  }, []);

  // #const handleSelectedItem = useCallback(
  //   (data: { id: string }) => async (value: any, key: any) => {
  //     if (!!productType && !!value) {
  //       props.runSequence({
  //         sequence: "SettingPackage",
  //         action: "ADD_PRODUCT",
  //         type: productType,
  //         productId: value,
  //       });
  //     }
  //   },
  //   [productType, props.searchedItemListWithKey]
  // );

  const handleGetTrProps = useCallback(
    () => ({
      className: isActive ? "--override-disabled" : "",
    }),
    [isActive]
  );

  const handleInputRef = useCallback(
    (e: any) => {
      if (e) {
        const target = e.inputRef.current;

        if (target && isActive) {
          e.inputRef.current.value = formatPrice(target.value);
        }
      }
    },
    [isActive]
  );

  // Use Memo
  const filterProductListByType = useMemo(
    () =>
      productList.filter((item) => {
        const hasMatchingType =
          activeItem === "ALL" ? true : activeItem === item.package_group_code;

        return hasMatchingType && !item.deleted;
      }),
    [activeItem, productList]
  );

  const countProductList = useMemo(() => {
    const result: Record<ProductTypeKey, number> = {
      DOCTOR_FEE: 0,
      DRUG: 0,
      FOOD: 0,
      IMAGING: 0,
      LAB: 0,
      MISCELLANEOUS: 0,
      NURSE: 0,
      ROOM: 0,
      SUPPLY: 0,
      TREATMENT: 0,
    };

    // ใช้ for...of แทน reduce
    for (const item of productList) {
      result[item.package_group_code]++;
    }

    // คำนวณ ALL โดยใช้ Object.values และ for...of
    let total = 0;

    for (const count of Object.values(result)) {
      total += count;
    }

    return {
      ...result,
      ALL: total,
    };
  }, [productList]);

  const productTypeOptions = useMemo(
    () =>
      Object.entries(PRODUCT_TYPES).map(([key, value]) => ({
        key,
        text: value.name,
        value: key,
      })),
    []
  );

  const priceTotal = useMemo(() => summary(productList), [productList]);

  const priceTotalProduct = useMemo(
    () => summary(filterProductListByType),
    [filterProductListByType]
  );

  const labelActive = useMemo(() => {
    const active = props.SettingPackageSequence?.packageDetail?.active;

    return active
      ? { color: "green", text: PACKAGE_USAGE_STATUS.active }
      : { color: "red", text: PACKAGE_USAGE_STATUS.inactive };
  }, [props.SettingPackageSequence?.packageDetail?.active]);

  const errorMsgLabel = useMemo(() => {
    const error = props.errorMessage?.[CARD_SETTING_PACKAGE];

    const label = {
      code: "Code รหัสแพ็กเกจ",
      end_date: "End date",
      name: "Package name ชื่อแพ็กเกจ",
      package_service_type: "Service type ประเภทบริการ",
      package_type: "Package type ชนิดแพ็กเกจ",
      price_foreign: "Package pricing (Foreign)",
      price_normal: "Package pricing ราคาขายของสินค้านี้",
      price_premium: "Package pricing (Premium)",
      start_date: "Start date",
      usage_limit: "Package type",
    } as any;

    if (!error) {
      return null;
    }

    return Object.fromEntries(
      Object.entries(error).map(([key, value]) => [label[key] || key, value])
    ) as Record<string, string>;
  }, [props.errorMessage]);

  const productItems = useMemo(
    () =>
      filterProductListByType.map((item) => ({
        ...item,
        delete: (
          <div style={GridCenter}>
            <Button
              color="red"
              disabled={isActive}
              icon="minus"
              size="mini"
              style={{ fontSize: "0.65rem" }}
              circular
              onClick={handleDeleteProduct(item)}
            />
          </div>
        ),
        is_display: (
          <div style={GridCenter}>
            <Checkbox
              checked={item.is_display}
              disabled={isActive}
              name="is_display"
              onChange={handleEditProduct(item)}
            />
          </div>
        ),
        price: <div style={{ textAlign: "right" }}>{formatPrice(item.price)}</div>,
        quantity: (
          <Input
            disabled={isActive}
            name="quantity"
            type="number"
            value={item.quantity}
            onChange={handleEditProduct(item)}
          />
        ),
        unit_name: <div style={{ textAlign: "center" }}>{item.unit_name}</div>,
      })),
    [filterProductListByType, isActive]
  );

  // Handler
  const handleChangeValue = (e: any, data: any) => {
    let value = typeof data.checked === "boolean" ? data.checked : data.value;

    const detail = { ...props.SettingPackageSequence?.packageDetail };
    const numberKey: (keyof PackageDetailType)[] = [
      "age_limits_year_start",
      "age_limits_year_end",
      "price_normal",
      "price_premium",
      "price_foreign",
    ];

    // type number value ไม่น้อยกว่า 0
    if (numberKey.includes(data.name)) {
      value = Number(value) < 0 ? 0 : value;
    }

    (detail as any)[data.name] = value;

    props.setProp("SettingPackageSequence.packageDetail", { ...detail });
  };

  const handleChangeTab = (e: any, data: any) => {
    setActiveItem(data.name);

    if (!isActive) {
      handleAddNewProduct(data.name);
    }
  };

  const handleAddNewProduct = async (tab = activeItem) => {
    if (tab === "ALL") {
      // #setProductType("LAB");
      // #setOpenModProductType(true);

      handleCloseModProductType();

      return;
    }

    await props.setProp("SettingPackageSequence.searchedProductList", { items: [], total: 0 });

    await props.setProp(`searchedItemListWithKey.${tab}_name`, []);
    props.setProp(`searchedItemListWithKey.${tab}_code`, []);

    setProductType(tab);
    setIsNewProduct(true);
  };

  const handleAddSuccess = () => {
    if (productType) {
      // #setActiveItem(productType);
      handleCloseModProductType();
    }
  };

  const handleChangeDate = (name: keyof PackageDetailType) => async (date: string) => {
    if (!!date && date.length !== 10) {
      return;
    }

    if (name === "start_date") {
      const start = beToAd(date);
      const end = beToAd(props.SettingPackageSequence?.packageDetail?.end_date || "");
      const diff = start?.diff(end, "days") || 0;

      // clear end date หาก start date มากกว่า end date
      if (start && end && diff >= 1) {
        await props.setProp("SettingPackageSequence.packageDetail.end_date", "");
      }
    }

    handleChangeValue(null, { name, value: date });
  };

  const handleChangeProductType = (e: any, data: any) => {
    setProductType(data.value);
  };

  const handleCloseModProductType = () => {
    setIsNewProduct(false);
    setOpenModProductType(false);
    setProductType("");
  };

  const handleConfirmProductType = async () => {
    await props.setProp(`searchedItemListWithKey.${productType}_name`, []);
    props.setProp(`searchedItemListWithKey.${productType}_code`, []);

    setIsNewProduct(true);
    setOpenModProductType(false);
  };

  const handleSave = () => {
    props.runSequence({
      sequence: "SettingPackage",
      action: "SAVE",
      card: CARD_SETTING_PACKAGE,
      data: props.SettingPackageSequence?.packageDetail || {},
      // onSuccess: handleCloseModProductType,
    });
  };

  const handleActive = () => {
    props.runSequence({
      sequence: "SettingPackage",
      action: "ACTIVE",
      card: CARD_SETTING_PACKAGE,
      data: props.SettingPackageSequence?.rawPackageDetail || {},
      onSuccess: handleCloseModProductType,
    });
  };

  const handleOpenModDuplicate = () => {
    setPackageName(
      `Copy of Package ${props.SettingPackageSequence?.packageDetail?.name || ""} (1)`
    );
    setOpenModDuplicate(true);
  };

  const handleConfirmDuplicate = () => {
    props.runSequence({
      sequence: "SettingPackage",
      action: "DUPLICATE",
      card: CARD_SETTING_PACKAGE,
      code: packageCode,
      name: packageName,
      onSuccess: handleCloseModDuplicate,
    });
  };

  const handleCloseModDuplicate = () => {
    setOpenModDuplicate(false);
    setPackageCode("");
    setPackageName("");
  };

  const handleCloseModInfo = () => {
    props.setProp(`errorMessage.${CARD_SETTING_PACKAGE}_${BUTTON_ACTIONS.dup}`, null);
  };

  const handleEventSearch: OnSearchHandler = (data) => {
    props.runSequence({
      sequence: "SettingPackage",
      action: "SEARCH_PRODUCT",
      card: data.card,
      data: data.params,
    });
  };

  const handleOpenAdmin = (type: PSTypeKey) => {
    if (isActive) {
      return;
    }

    windowRef.current[type]?.close();

    windowRef.current[type] = globalThis.open(`${API_HOST}${(ADMINS as any)[type]}`, "_blank");
  };

  const handleRefreshOptionType = (type: PSTypeKey) => () => {
    if (isActive) {
      return;
    }

    props.runSequence({
      sequence: "SettingPackage",
      action: "REFRESH_OPTION",
      card: CARD_SETTING_PACKAGE,
      type,
    });
  };

  const handleCancel = () => {
    props.runSequence({ sequence: "SettingPackage", action: "CLEAR" });
  };

  const handleConfirmDeleteProduct = () => {
    if (!modDeleteProduct) {
      return;
    }

    const list = [...productList];

    if (modDeleteProduct.id) {
      // หา item ที่มี index ตรงกับ data.index แล้วเปลี่ยน deleted เป็น true
      const itemToUpdate = list.find((item) => item.index === modDeleteProduct.index);

      if (itemToUpdate) {
        itemToUpdate.deleted = true;
      }
    } else {
      // ลบ item ที่มี index ตรงกับ data.index
      const indexToRemove = list.findIndex((item) => item.index === modDeleteProduct.index);

      if (indexToRemove !== -1) {
        list.splice(indexToRemove, 1);
      }
    }

    props.setProp("SettingPackageSequence.packageDetail.items", [...list]);

    handleCloseModDeleteProduct();
  };

  const handleCloseModDeleteProduct = () => {
    setModDeleteProduct(null);
  };

  const handleSelectedProduct = (items: any[]) => {
    if (productType) {
      props.runSequence({
        sequence: "SettingPackage",
        action: "ADD_PRODUCT",
        items,
        type: productType,
      });
    }
  };

  const handleAddNewPackage = () => {
    handleChangeTab(null, { name: "ALL" });
  };

  const handleEditPackage = () => {
    handleCloseModProductType();

    setActiveItem("ALL");
  };

  console.log("CardSettingPackage", props);

  return (
    <div
      style={{
        height: "calc(100vh - 3rem)",
        overflow: "auto",
        paddingBottom: "3rem",
        width: "calc(100vw - 10.5rem)",
      }}
    >
      <SnackMessage
        onEvent={props.onEvent}
        error={errorMsgLabel}
        success={null}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_SETTING_PACKAGE}`, null);
        }}
        languageUX={props.languageUX}
      />

      <CardSettingPackageUX
        // config
        disabledAddNewProduct={isActive}
        hideDetail={!props.SettingPackageSequence?.showDetail}
        // ref
        inputRef={handleInputRef}
        isActive={isActive}
        isNewProduct={isNewProduct}
        labelActive={labelActive}
        // data
        packageDetail={props.SettingPackageSequence?.packageDetail}
        priceTotal={priceTotal}
        priceTotalProduct={priceTotalProduct}
        productItems={productItems}
        showAgeError={!!props.SettingPackageSequence?.showRequiredField?.age_range}
        showPriceTotalProduct={activeItem !== "ALL"}
        showRequiredField={props.SettingPackageSequence?.showRequiredField}
        tabName={tabName}
        genderOptions={props.SettingPackageSequence?.genderOptions}
        // options
        packageTypeOptions={props.SettingPackageSequence?.packageTypeOptions}
        serviceTypeOptions={props.SettingPackageSequence?.serviceTypeOptions}
        usageLimitOptions={props.SettingPackageSequence?.usageLimitOptions}
        // callback
        getTrProps={handleGetTrProps}
        onAddNewProduct={handleAddNewProduct}
        onAddSuccess={handleAddSuccess}
        onChangeValue={handleChangeValue}
        onClickCancel={handleCancel}
        onClickDuplicate={handleOpenModDuplicate}
        onOpenAdmin={handleOpenAdmin}
        onRefreshOptionType={handleRefreshOptionType}
        ButtonActive={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            // disabled={!props.SettingPackageSequence?.packageDetail?.id}
            color={props.SettingPackageSequence?.packageDetail?.active ? "red" : "blue"}
            name={BUTTON_ACTIONS.active}
            // data
            paramKey={ACTION_ACTIVE}
            size="medium"
            // config
            buttonLoadCheck={props.buttonLoadCheck?.[ACTION_ACTIVE]}
            onClick={handleActive}
            title={
              props.SettingPackageSequence?.packageDetail?.active
                ? PACKAGE_USAGE_STATUS.inactive
                : PACKAGE_USAGE_STATUS.active
            }
          />
        }
        ButtonSave={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            color={props.SettingPackageSequence?.packageDetail?.id ? "yellow" : "green"}
            // config
            disabled={!!props.SettingPackageSequence?.showRequiredField?.age_range}
            name={BUTTON_ACTIONS.save}
            // data
            paramKey={ACTION_SAVE}
            size="medium"
            title={props.SettingPackageSequence?.packageDetail?.id ? intl.formatMessage({id: "บันทึกการแก้ไข"}) : intl.formatMessage({id: "บันทึก"})}
            buttonLoadCheck={props.buttonLoadCheck?.[ACTION_SAVE]}
            onClick={handleSave}
          />
        }
        CardProductSearch={
          productType && (
            <CardProductSearch
              setProp={props.setProp}
              // data
              data={props.SettingPackageSequence?.searchedProductList}
              type={productType}
              // CommonInterface
              buttonLoadCheck={props.buttonLoadCheck}
              onSearch={handleEventSearch}
              onSelected={handleSelectedProduct}
              languageUX={props.languageUX}
            />
          )
        }
        EndDate={
          <DateTextBox
            id="CardSettingPackage-DateTextBox-EndDate"
            inputStyle={{ width: "100%" }}
            minDate={props.SettingPackageSequence?.packageDetail?.start_date || ""}
            value={props.SettingPackageSequence?.packageDetail?.end_date || ""}
            inputFluid
            onChange={handleChangeDate("end_date")}
          />
        }
        PackageRefreshIcon={
          <LoadingIcon
            loading={!!props.buttonLoadCheck?.[`${ACTION_REFRESH}_${PS_TYPES.PACKAGE_TYPE}`]}
            name="undo"
            onClick={handleRefreshOptionType(PS_TYPES.PACKAGE_TYPE)}
          />
        }
        // }
        ServiceRefreshIcon={
          <LoadingIcon
            loading={!!props.buttonLoadCheck?.[`${ACTION_REFRESH}_${PS_TYPES.SERVICE_TYPE}`]}
            name="undo"
            onClick={handleRefreshOptionType(PS_TYPES.SERVICE_TYPE)}
          />
        }
        // SearchBoxPCode={
        //   <SearchBoxDropdown
        //     key="SearchBoxPCode"
        //     onEvent={handleEventSearch}
        //     id="code"
        //     icon="search"
        //     limit={20}
        //     selectedItem={props.SettingPackageSequence?.selectedProduct?.code || null}
        //     style={{ width: "100%" }}
        //     // config
        //     type={productType}
        //     fluid
        //     useWithKey
        //     // Select
        //     searchedItemListWithKey={props.searchedItemListWithKey}
        //     setSelectedItem={handleSelectedItem({ id: "code" })}
        //   />
        // }
        // SearchBoxPName={
        //   <SearchBoxDropdown
        //     key="SearchBoxPName"
        //     onEvent={handleEventSearch}
        //     id="name"
        //     icon="search"
        //     limit={20}
        //     selectedItem={props.SettingPackageSequence?.selectedProduct?.name || null}
        //     style={{ width: "100%" }}
        //     // config
        //     type={productType}
        //     fluid
        //     useWithKey
        //     // Select
        //     searchedItemListWithKey={props.searchedItemListWithKey}
        //     setSelectedItem={handleSelectedItem({ id: "name" })}
        //   />
        StartDate={
          <DateTextBox
            id="CardSettingPackage-DateTextBox-StartDate"
            inputStyle={{ width: "100%" }}
            value={props.SettingPackageSequence?.packageDetail?.start_date || ""}
            inputFluid
            onChange={handleChangeDate("start_date")}
          />
        }
        // Element
        SubSearchPackage={
          <SubSearchPackage
            onEvent={props.onEvent}
            setProp={props.setProp}
            // seq
            runSequence={props.runSequence}
            SettingPackageSequence={props.SettingPackageSequence}
            // CommonInterface
            buttonLoadCheck={props.buttonLoadCheck}
            errorMessage={props.errorMessage}
            searchedItemListWithKey={props.searchedItemListWithKey}
            // options
            masterOptions={props.masterOptions}
            // callback
            onEditPackage={handleEditPackage}
            onNewPackage={handleAddNewPackage}
            languageUX={props.languageUX}
          />
        }
        TabPans={
          <TabPan
            activeItem={activeItem}
            countProductList={countProductList}
            // config
            // #disabled={isNewProduct}
            productType={productType}
            onChangeTab={handleChangeTab}
          />
        }
        languageUX={props.languageUX}
      />

      <ModConfirm
        approveButtonColor="green"
        approveButtonText={intl.formatMessage({ id: "ตกลง" })}
        denyButtonColor="red"
        denyButtonText={intl.formatMessage({ id: "ยกเลิก" })}
        openModal={openModProductType}
        size="mini"
        titleColor="blue"
        titleName={intl.formatMessage({ id: "โปรดระบุประเภทสินค้า ที่ต้องการเพิ่ม" })}
        onApprove={handleConfirmProductType}
        onCloseWithDimmerClick={handleCloseModProductType}
        onDeny={handleCloseModProductType}
        content={
          <div
            style={{
              margin: "0rem 1.5rem -1rem 0rem",
            }}
          >
            <Dropdown
              style={{ marginLeft: "10px", width: "100%" }}
              value={productType}
              fluid
              selection
              options={productTypeOptions}
              onChange={handleChangeProductType}
            />
          </div>
        }
      />

      <ModConfirm
        denyButtonText={intl.formatMessage({ id: "ยกเลิก" })}
        openModal={!!modDeleteProduct}
        size="mini"
        titleColor="red"
        titleName={intl.formatMessage({ id: "แจ้งเตือน" })}
        // callback
        onApprove={handleConfirmDeleteProduct}
        onCloseWithDimmerClick={handleCloseModDeleteProduct}
        onDeny={handleCloseModDeleteProduct}
        // ELement
        content={
          <div
            style={{
              fontWeight: "bold",
              margin: "0rem 1.5rem -1rem 0rem",
              textAlign: "center",
            }}
          >
            ต้องการลบรายการใช่หรือไม่
          </div>
        }
      />

      <ModConfirm
        denyButtonColor="red"
        denyButtonText={intl.formatMessage({ id: "ยกเลิก" })}
        modalStyle={{ width: "40%" }}
        openModal={openModDuplicate}
        size="small"
        titleColor="blue"
        titleName={intl.formatMessage({ id: "ยืนยันการคัดลอกแพ็กเกจ โปรดระบุข้อมูลดังนี้" })}
        onCloseWithDimmerClick={handleCloseModDuplicate}
        onDeny={handleCloseModDuplicate}
        approveButton={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            color={"green"}
            // config
            disabled={!packageCode}
            name={BUTTON_ACTIONS.dup}
            // data
            paramKey={ACTION_DUP}
            size="medium"
            title={intl.formatMessage({ id: "ตกลง" })}
            basic
            fluid
            buttonLoadCheck={props.buttonLoadCheck?.[ACTION_DUP]}
            onClick={handleConfirmDuplicate}
          />
        }
        content={
          <div
            style={{
              margin: "0.5rem 0rem -1.5rem 0.5rem",
            }}
          >
            {/* @ts-ignore */}
            <Form>
              <Form.Group style={{ marginBottom: "1.5rem" }} inline>
                <Form.Field width={3} inline>
                  <label htmlFor="package-code">{intl.formatMessage({ id: "รหัสแพ็กเกจ" })}</label>
                </Form.Field>
                <Form.Field width={13} inline>
                  <Input
                    name="package-code"
                    value={packageCode}
                    onChange={(e, d) => {
                      setPackageCode(d.value);
                    }}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group inline>
                <Form.Field width={3} inline>
                  <label htmlFor="package-name">{intl.formatMessage({ id: "ชื่อแพ็กเกจ" })}</label>
                </Form.Field>
                <Form.Field width={13} inline>
                  <Input
                    name="package-name"
                    value={packageName}
                    onChange={(e, d) => {
                      setPackageName(d.value);
                    }}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </div>
        }
      />

      <ModInfo
        btnText={intl.formatMessage({ id: "ปิด" })}
        buttonColor="blue"
        titleColor={"red"}
        titleName={intl.formatMessage({ id: "แจ้งเตือน!" })}
        onApprove={handleCloseModInfo}
        onClose={handleCloseModInfo}
        open={
          props.errorMessage?.[`${CARD_SETTING_PACKAGE}_${BUTTON_ACTIONS.dup}`] ===
          "DUPLICATE_PACKAGE"
        }
      >
        <div
          style={{
            fontWeight: "bold",
            lineHeight: 2.5,
            padding: "0 0 0.25rem",
          }}
        >
          <div>รหัสแพ็กเกจ {packageCode} ถูกสร้างในระบบแล้ว</div>
          <div>{intl.formatMessage({ id: "โปรดระบุข้อมูลรหัสแพ็กเกจใหม่" })}</div>
        </div>
      </ModInfo>
    </div>
  );
};

/* ------------------------------------------------------ */

/*                         TabPan                         */

/* ------------------------------------------------------ */
type TabPanProps = {
  activeItem: "ALL" | ProductTypeKey;
  countProductList: Record<"ALL" | ProductTypeKey, number>;
  // config
  disabled?: boolean;
  productType: "" | ProductTypeKey;
  // callback
  onChangeTab: (event: SyntheticEvent, data: MenuItemProps) => void;
};

const TabPan = (props: TabPanProps) => {
  const getProductTypeTab = useCallback(
    (menu: "ALL" | ProductTypeKey) =>
      menu in PRODUCT_TYPES ? PRODUCT_TYPES[menu as ProductTypeKey].tab : menu,
    []
  );

  return (
    <Menu tabular>
      {TAB_PANS.map((menu) => (
        <Menu.Item
          key={`menu-${menu}`}
          active={props.activeItem === menu}
          disabled={props.disabled && menu !== props.productType && menu !== "ALL"}
          name={menu}
          // callback
          onClick={props.onChangeTab}
          // style
          style={
            props.activeItem === menu
              ? {
                  color: "#0370B7",
                }
              : {}
          }
        >
          {getProductTypeTab(menu)}
          {!!props.countProductList[menu] && (
            <span
              style={{
                bottom: 5,
                color: "rgba(0,0,0,.95)",
                fontSize: "0.7rem",
                position: "absolute",
                right: 5,
              }}
            >
              ({props.countProductList[menu]})
            </span>
          )}
        </Menu.Item>
      ))}
    </Menu>
  );
};

CardSettingPackage.displayName = "CardSettingPackage";

export default React.memo(CardSettingPackage);
