import React, { useState, useEffect, useMemo } from "react";

// Ux
import ModProductDetailUX from "./ModProductDetailUX";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

// Interface
import {
  AggStockSerializer,
  BUTTON_ACTIONS,
  PermissionsType,
  RunSequence,
} from "./sequence/StockManagement";
import { CARD_STOCK_MANAGEMENT } from "./CardStockManagement";
import { useIntl } from "react-intl";

// Types
type ModProductDetailProps = {
  setProp: (key: string, value: any, callback?: Function) => any;
  // seq
  runSequence: RunSequence;
  // data
  data: Partial<AggStockSerializer>;
  permissions?: PermissionsType;
  // options
  productTypeOptions: any[];
  // CommonInterface
  buttonLoadCheck?: Record<string, any>;
  // callback
  onClose?: Function;
};

// Const
const MOD_PRODUCT_DETAIL = "ModProductDetail";

const ModProductDetail = (props: ModProductDetailProps) => {
  const intl = useIntl();
  const [detail, setDetail] = useState<Partial<AggStockSerializer>>({});

  useEffect(() => {
    setDetail({
      ...props.data,
      active:
        (props.data.active_flag && props.data.active_flag !== 5) ||
        !!props.data.active_count,
    });
  }, [props.data]);

  const productTypeName = useMemo(() => {
    const name = props.data.product?.p_type_name;

    const option = props.productTypeOptions.find((acc) => acc.text === name);

    return !!option ? `${option.value}: ${option.text}` : "";
  }, [props.productTypeOptions, props.data.product]);

  // Handler
  const handleChangeValue = (e: any, data: any) => {
    if (!props.permissions?.STOCK_EDIT) {
      return;
    }

    let value = typeof data.checked === "boolean" ? data.checked : data.value;

    if (["max_qty", "min_qty"].includes(data.name)) {
      value = Number(value) < 0 ? 0 : value;
    }

    setDetail({
      ...detail,
      [data.name]: value,
    });
  };

  const handleSave = () => {
    props.runSequence({
      sequence: "StockManagement",
      action: "EDIT_STOCK",
      card: MOD_PRODUCT_DETAIL,
      errorKey: CARD_STOCK_MANAGEMENT,
      data: detail,
      btnAction: BUTTON_ACTIONS.SAVE,
      // callback
      onSuccess: props.onClose,
    });
  };

  const handleClose = () => {
    setDetail({});

    props.onClose?.();
  };

  console.log("ModProductDetail", detail);

  return (
    <div>
      <ModProductDetailUX
        // data
        // readonly
        code={detail.product?.code}
        name={detail.product?.name}
        binLocation={detail.bin_location}
        storage={detail.storage?.name}
        type={productTypeName}
        unit={detail.product?.unit_name}
        minQty={detail.min_qty}
        maxQty={detail.max_qty}
        active={detail.active}
        // config
        disabledActive={!props.permissions?.STOCK_ACTIVE}
        // callback
        onChangeValue={handleChangeValue}
        onCancel={handleClose}
        // Element
        ButtonSave={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSave}
            // data
            paramKey={`${MOD_PRODUCT_DETAIL}_${BUTTON_ACTIONS.SAVE}`}
            buttonLoadCheck={
              props.buttonLoadCheck?.[
                `${MOD_PRODUCT_DETAIL}_${BUTTON_ACTIONS.SAVE}`
              ]
            }
            // config
            disabled={!props.permissions?.STOCK_EDIT}
            color={"green"}
            name={BUTTON_ACTIONS.SAVE}
            size="medium"
            title={intl.formatMessage({ id: "บันทึกการแก้ไข" })}
          />
        }
        languageUX={props.languageUX}
      />
    </div>
  );
};

export default React.memo(ModProductDetail);
