import * as I from "./PlanTransfer";

const SequencePattern: { [name: string]: { [flavor: string] : { [func: string]: any }}} = {
  PlanTransfer: { 
    default: {
      START: I.Start,
      EDIT: I.Edit
    }    
  }
};

export default SequencePattern;
