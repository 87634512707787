import React from "react";
import { renderToStaticMarkup } from "react-dom/server";

import QRCode from "react-qr-code";
import moment from "moment";

// Common
import getHtmlToPdfmake from "react-lib/appcon/common/getHtmlToPdfmake";
import { splitStringNewLine } from "../common/CommonInterface";
import { getLogoReportNResize } from "react-lib/apps/HISV3/common/CommonInterface";

// Utils
import {
  adToBeWithSetFormat,
  beToAd,
  formatDate,
  formatDatetime,
} from "react-lib/utils/dateUtils";

// Config
import CONFIG from "config/config";

// Types
type FormVisitSlipProps = {
  patient_name: string;
  hn: string;
  patient_birthdate: string;
  patient_age: string;
  coverage: string;
  items: {
    [key: string]: any;
    number: string;
    division_name: string;
    doctor_name: string;
    patient_case: string;
    note: string;
  }[];
};

const generatePDF = async (props: { value: string; size: number }) => {
  const qrCodeElement = React.createElement(QRCode, {
    value: props.value,
    size: props.size,
  });
  const qrCodeHtml = renderToStaticMarkup(qrCodeElement as any);

  let htmlToPdfmake = (await getHtmlToPdfmake()).default;

  const pdfDefinition = await htmlToPdfmake(qrCodeHtml);

  return pdfDefinition;
};

const getTable = (columns: any[], padding = { bottom: 5, top: 5 }) => {
  return {
    stack: [
      {
        table: {
          widths: ["15%", "32.5%", "32.5%", "20%"],
          body: [
            [
              columns[0],
              {
                alignment: "center",
                ...columns[1],
              },
              {
                alignment: "center",
                ...columns[2],
              },
              {
                text: columns[3],
                alignment: "center",
              },
            ],
          ],
        },
        layout: {
          hLineWidth: () => 0.25,
          vLineWidth: () => 0,
          paddingTop: () => padding.top,
          paddingBottom: () => padding.bottom,
          hLineColor: () => "#d9d9d9",
        },
      },
    ],
  };
};

const FONT_SIZE = 7.5;
const NEW_LINE_OPTION = { fontSize: FONT_SIZE, ellipse: "" };
const FORM_NAME = `FormVisitSlip`;
const LOGO_HEIGHT = 30;

const FormVisitSlip = async (props: FormVisitSlipProps) => {
  const logoResize = await getLogoReportNResize(LOGO_HEIGHT, 1, FORM_NAME);
  const pdfDefinition = await generatePDF({ value: props.hn, size: 35 });
  const nameLines = splitStringNewLine(props.patient_name, {
    width: 115,
    ...NEW_LINE_OPTION,
  });
  const coverageLines = splitStringNewLine(
    `สิทธิ์ : ${props.coverage || "-"}`,
    {
      width: 84.5,
      ...NEW_LINE_OPTION,
    }
  );

  const pageMarginTop =
    107.5 + (Math.max(nameLines.length, coverageLines.length - 1) - 1) * 10;

  return {
    pageSize: "A6",
    pageMargins: [6.5, pageMarginTop, 6.5, 15],
    defaultStyle: {
      font: "KanitLM",
      lineHeight: 1,
      fontSize: FONT_SIZE,
    },
    header: () => {
      return {
        margin: [8.5, 0, 8.5, 10],
        stack: [
          {
            margin: !CONFIG.HIDE_COMPANY_LOGO_PRINT ? [-10, -2.5, 0, 0] : [-10, -2.5, 0, 15],
            columns: [
              !CONFIG.HIDE_COMPANY_LOGO_PRINT
                ? {
                    width: "25%",
                    stack: [{ image: "logo", width: logoResize.width, height: LOGO_HEIGHT, margin: [5, 10, 0, 0] }],
                  }
                : null,
              {
                width: !CONFIG.HIDE_COMPANY_LOGO_PRINT ? "60%" : "100%",
                margin: [5, 12, 0, 0], // original [5, 22.5, 0, 0]
                stack: [
                  {
                    text: [
                      { text: "บัตรเข้ารับการรักษา " },
                      { text: "(Visit slip)", font: "PoppinsLM" },
                    ],
                    style: "fieldHeader",
                  },
                  {
                    text: `วันที่เข้ารับบริการ ${formatDate(moment())}`,
                    marginTop: 1,
                  },
                ],
                alignment: "center",
              },
            ],
          },
          {
            margin: [5, 5, 5, 0], // original [5, 0, 5, 0]
            columns: [
              {
                width: "80%",
                columns: [
                  {
                    width: "60%",
                    stack: [
                      ...nameLines.map((text) => ({
                        text: text.trim(),
                        style: "fieldLabel",
                      })),
                      {
                        width: "100%",
                        columns: [
                          { text: "วันเกิด : ", width: 25 },
                          {
                            text: props.patient_birthdate
                              ? adToBeWithSetFormat(
                                  beToAd(props.patient_birthdate) as any,
                                  "YYYY-MM-DD",
                                  "DD MMMM YYYY",
                                  "th"
                                )
                              : "-",
                          },
                        ],
                        marginTop: 5,
                      },
                      {
                        width: "100%",
                        columns: [
                          { text: "อายุ : ", width: 25 },
                          { text: props.patient_age || "-" },
                        ],
                      },
                    ],
                  },
                  {
                    width: "40%",
                    stack: [
                      {
                        text: `HN : ${props.hn}`,
                        style: "fieldLabel",
                        marginBottom: 5,
                      },
                      ...coverageLines.map((text) => ({
                        text: text.trim(),
                      })),
                    ],
                  },
                ],
              },
              {
                margin: [12.5, -2.5, 0, 0],
                stack: [pdfDefinition],
              },
            ],
          },
        ],
      };
    },
    content: [
      getTable([
        {
          text: "เลขที่อ้างอิง",
          alignment: "center",
        },
        { text: "หน่วยตรวจ" },
        { text: "แพทย์" },
        "ประเภท",
      ]),
      ...props.items.map((item) => {
        const divisionLines = splitStringNewLine(item.division_name, {
          width: 79,
          ...NEW_LINE_OPTION,
        });
        const doctorLines = splitStringNewLine(item.doctor_name, {
          width: 82,
          ...NEW_LINE_OPTION,
        });

        const paddingTop =
          Math.max(divisionLines.length, doctorLines.length) - 1;

        return getTable(
          [
            {
              stack: [
                {
                  text: item.number,
                  alignment: "center",
                  bold: true,
                  fontSize: 12.5,
                  margin: [0, -2.5, 0, 0],
                },
                {
                  text: "\u00a0",
                },
                ...Array(paddingTop)
                  .fill("")
                  .map(() => ({
                    text: "\u00a0",
                    fontSize: 5,
                  })),
                {
                  relativePosition: { x: 0, y: 0 },
                  text: `หมายเหตุ : ${item.note || "-"}`,
                  noWrap: true,
                  style: "miniField",
                  margin: [15, -7.5, 0, 0],
                },
                {
                  text: "\u00a0",
                },
              ],
            },
            {
              stack: divisionLines.map((text) => ({
                text: text.trim(),
              })),
            },
            {
              stack: doctorLines.map((text) => ({
                text: text.trim(),
              })),
            },
            item.patient_case || "-",
          ],
          { top: 6.5, bottom: 7.5 }
        );
      }),
    ],
    footer: () => {
      return {
        text: `Print Date/Time : ${formatDatetime(moment())}`,
        alignment: "right",
        style: "miniField",
        marginRight: 7.5,
        font: "PoppinsLM",
      };
    },
    images: {
      logo: origin + logoResize.src,
    },
    styles: {
      fieldHeader: {
        fontSize: 9,
        bold: true,
      },
      fieldLabel: {
        fontSize: 8.5,
        bold: true,
      },
      miniField: {
        fontSize: 6.5,
      },
    },
  };
};

export default FormVisitSlip;
