import WasmController from "react-lib/frameworks/WasmController";

// APIs
import SegmentGroupList from "issara-sdk/apis/SegmentGroupList_apps_PHR";
import SegmentGroupDetail from "issara-sdk/apis/SegmentGroupDetail_apps_PHR";
import PatientSegmentList from "issara-sdk/apis/PatientSegmentList_apps_PHR";
import PatientSegmentNameList from "issara-sdk/apis/PatientSegmentNameList_apps_PHR";
import ProxyPatientNoUserListView from "issara-sdk/apis/ProxyPatientNoUserListView_apps_PRX";
import DivisionList from "issara-sdk/apis/DivisionList_core";

// Serializer
import SegmentGroupSerializer from "issara-sdk/types/SegmentGroupSerializer_apps_PHR";
import SegmentGroupCreateUpdateSerializer from "issara-sdk/types/SegmentGroupCreateUpdateSerializer_apps_PHR";

// Interface
import { State as MainState } from "../../../../../HIS/MainHISInterface";

export type State = Partial<{
  // sequence
  HealthMemberSequence: Partial<{
    sequenceIndex: "Start" | "Action" | null;
    segmentGroupList: SegmentGroupType[];
    segmentGroupDetail: SegmentGroupDetailType;
    patientSegmentList?: any[];
    selectedPatientSegment?: {};
    division?: {}
  }> | null;
}>;

export type SegmentGroupType = SegmentGroupSerializer;

export type SegmentGroupDetailType = SegmentGroupCreateUpdateSerializer | null;

type Picked = Partial<Pick<MainState, "buttonLoadCheck" | "errorMessage" | "selectedDivision">>;

// Sequence
type SeqState = {
  sequence: "HealthMember";
  restart?: boolean;
  clear?: boolean;
};
// Handle Action
type ActionType =
  | {
    action: "CREATE_SEGMENT_GROUP";
    data: Record<string, string>;
    card: string;
    btnKey: string;
    // callback
    onSuccess?: Function;
  }
  | {
    action: "DELETE_SEGMENT_GROUP";
    card: string;
    btnKey: string;
    // callback
    onSuccess?: Function;
  }
  | { action: "FETCH_SEGMENT_GROUP"; name?: string }
  | {
    action: "SELECT_SEGMENT_GROUP";
    data: SegmentGroupSerializer;
  }
  | {
    action: "CREATE_PATIENT_SEGMENT";
    card: string;
    btnKey: string;
    hnList?: any[];
    citizenList?: any[];
    passportList?: any[];
    checkErrorByIndex: boolean;
    // callback
    onSuccess?: Function;
  }
  | {
    action: "DELETE_PATIENT_SEGMENT";
    card: string;
    btnKey: string;
    hnList?: any[];
    // callback
    onSuccess?: Function;
  }
  | {
    action: "FETCH_PATIENT_SEGMENT";
    btnKey: string;
  };

type SeqAct = ActionType & SeqState;
type SeqType<K> = K extends { action: string } ? Extract<SeqAct, K> : SeqState;

export type RunSequence = <K extends keyof SeqAct>(params: SeqType<Pick<SeqAct, K>>) => any;

type Params<A extends ActionType["action"]> = Extract<ActionType, { action: A }>;

export const StateInitial: State = {
  // sequence
  HealthMemberSequence: {
    sequenceIndex: null,
    segmentGroupList: [],
    segmentGroupDetail: null,
    patientSegmentList: [],
    division: {},
  },
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetHealthMemberSegment"; params: any }
  | { message: "SelectSegmentGroup"; params: any }
  | { message: "SelectPatientSegment"; params: any };

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler = (controller: WasmController<State & Picked, Event, Data>, params?: any) => any;

const SEGMENT_TYPE: string = "health member";
/* ------------------------------------------------------ */

/*                          START                         */

/* ------------------------------------------------------ */
export const GetMaster: Handler = async (controller, params: SeqState) => {
  const state = controller.getState();

  controller.setState(
    {
      HealthMemberSequence: {
        ...state.HealthMemberSequence,
        sequenceIndex: "Action",
      },
    },
    () => controller.handleEvent({ message: "RunSequence", params: { ...params } })
  );
};

/* ------------------------------------------------------ */

/*                      Handle Action                     */

/* ------------------------------------------------------ */
export const Action: Handler = async (controller, params: ActionType) => {
  if (params.action === "FETCH_SEGMENT_GROUP") {
    HandleFetchSegmentGroup(controller, params);
  } else if (params.action === "CREATE_SEGMENT_GROUP") {
    const state = controller.getState();
    if (state.HealthMemberSequence?.segmentGroupDetail?.id) {
      HandleUpdateSegmentGroup(controller, params);
    } else {
      HandleCreateSegmentGroup(controller, params);
    }
  } else if (params.action === "DELETE_SEGMENT_GROUP") {
    HandleDeleteSegmentGroup(controller, params);
  } else if (params.action === "SELECT_SEGMENT_GROUP") {
    HandleSelectSegmentGroup(controller, params);
  } else if (params.action === "CREATE_PATIENT_SEGMENT") {
    HandleCreatePatientSegment(controller, params);
  } else if (params.action === "DELETE_PATIENT_SEGMENT") {
    HandleDeletePatientSegment(controller, params);
  } else if (params.action === "FETCH_PATIENT_SEGMENT") {
    HandleFetchPatientSegment(controller, params);
  }
};

const HandleCreateSegmentGroup: Handler = async (
  controller,
  params: Params<"CREATE_SEGMENT_GROUP">
) => {
  let state = controller.getState();
  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [params.btnKey]: "LOADING",
    },
  });

  const segment = await SegmentGroupList.create({
    apiToken: controller.apiToken,
    data: {
      code: params.data.name.substring(0, 10),
      name: params.data.name,
      name_en: params.data.name,
      type: SEGMENT_TYPE,
    } as any,
  });

  if (segment[0]) {
    params.onSuccess?.();

    state = controller.getState();
    controller.setState(
      {
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.btnKey]: "SUCCESS",
        },
        HealthMemberSequence: {
          ...state.HealthMemberSequence,
          segmentGroupDetail: segment[0],
        },
      },
      () => HandleFetchSegmentGroup(controller, params)
    );
  } else {
    state = controller.getState();
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.btnKey]: "ERROR",
      },
      errorMessage: {
        ...state.errorMessage,
        [params.card]: segment[1],
      },
    });
  }
};

const HandleUpdateSegmentGroup: Handler = async (
  controller,
  params: Params<"CREATE_SEGMENT_GROUP">
) => {
  let state = controller.getState();
  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [params.btnKey]: "LOADING",
    },
  });

  const sel = state.HealthMemberSequence?.segmentGroupDetail;
  const segment = await SegmentGroupDetail.patch({
    pk: sel?.id,
    apiToken: controller.apiToken,
    data: {
      id: sel?.id,
      code: sel?.code,
      name: params.data.name,
    } as any,
  });

  if (segment[0]) {
    params.onSuccess?.();

    state = controller.getState();
    controller.setState(
      {
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.btnKey]: "SUCCESS",
        },
        HealthMemberSequence: {
          ...state.HealthMemberSequence,
          segmentGroupDetail: segment[0],
        },
      },
      () => HandleFetchSegmentGroup(controller, params)
    );
  } else {
    state = controller.getState();
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.btnKey]: "ERROR",
      },
      errorMessage: {
        ...state.errorMessage,
        [params.card]: segment[1],
      },
    });
  }
};

const HandleDeleteSegmentGroup: Handler = async (
  controller,
  params: Params<"DELETE_SEGMENT_GROUP">
) => {
  let state = controller.getState();

  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [params.btnKey]: "LOADING",
    },
  });

  const id = state.HealthMemberSequence?.segmentGroupDetail?.id;

  const detail = await SegmentGroupDetail.delete({
    pk: id,
    apiToken: controller.apiToken,
  });

  if (detail[1]) {
    state = controller.getState();
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.btnKey]: "ERROR",
      },
      errorMessage: {
        ...state.errorMessage,
        [params.card]: detail[1],
      },
    });
  } else {
    params.onSuccess?.();

    state = controller.getState();
    controller.setState(
      {
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.btnKey]: "SUCCESS",
        },
        HealthMemberSequence: {
          ...state.HealthMemberSequence,
          segmentGroupDetail: null,
        },
      },
      () => HandleFetchSegmentGroup(controller, params)
    );
  }
};

const HandleFetchSegmentGroup: Handler = async (
  controller,
  params: Params<"FETCH_SEGMENT_GROUP">
) => {

  const [divisions, segment] = await Promise.all([
    DivisionList.list({
      apiToken: controller.apiToken,
      params: { code: "health_member" },
    }),
    SegmentGroupList.list({
      apiToken: controller.apiToken,
      params: { type: SEGMENT_TYPE, name: params.name },
    })
  ]);

  const division: any = divisions[0]?.items[0] || {};
  let items = segment[0]?.items

  const state = controller.getState();
  controller.setState({
    HealthMemberSequence: {
      ...state.HealthMemberSequence,
      segmentGroupList: items || [],
      division: division
    },
  });
};

const HandleSelectSegmentGroup: Handler = async (
  controller,
  params: Params<"SELECT_SEGMENT_GROUP">
) => {
  const state = controller.getState();
  controller.setState(
    {
      HealthMemberSequence: {
        ...state.HealthMemberSequence,
        segmentGroupDetail: params.data,
      },
    },
    () => HandleFetchPatientSegment(controller, params)
  );
};

const HandleCreatePatientSegment: Handler = async (
  controller,
  params: Params<"CREATE_PATIENT_SEGMENT">
) => {
  let state = controller.getState();
  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [params.btnKey]: "LOADING",
    },
  });

  let data: any = {
    action: "CREATE",
    result: state.HealthMemberSequence?.segmentGroupDetail?.name,
    segment_type: "MARKETING",
    segment_group: state.HealthMemberSequence?.segmentGroupDetail?.name?.substring(0, 20),
    group: state.HealthMemberSequence?.segmentGroupDetail?.id,
    check_error_by_index: params?.checkErrorByIndex,
  };
  if (params?.hnList) {
    data.hn_list = params.hnList;
  }
  if (params?.citizenList) {
    data.citizen_list = params.citizenList;
  }
  if (params?.passportList) {
    data.passport_list = params.passportList;
  }
  const result = await PatientSegmentNameList.post({
    apiToken: controller.apiToken,
    data: data,
  });

  if (result[1]) {
    state = controller.getState();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.btnKey]: "ERROR",
      },
      errorMessage: {
        ...state.errorMessage,
        [params?.checkErrorByIndex ? `${params.card}_IMPORT` : params.card]: result[1],
      },
    });
  } else {
    params.onSuccess?.();

    controller.setState(
      {
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.btnKey]: "SUCCESS",
        },
        errorMessage: {
          [`${params.card}_IMPORT`]: null,
        },
      },
      () => HandleFetchPatientSegment(controller, params)
    );
  }
};

const HandleDeletePatientSegment: Handler = async (
  controller,
  params: Params<"DELETE_PATIENT_SEGMENT">
) => {
  let state = controller.getState();
  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [params.btnKey]: "LOADING",
    },
  });

  const result = await PatientSegmentNameList.post({
    apiToken: controller.apiToken,
    data: {
      action: "DELETE",
      segment_type: "MARKETING",
      group: state.HealthMemberSequence?.segmentGroupDetail?.id,
      hn_list: params.hnList,
    },
  });

  if (result[1]) {
    state = controller.getState();
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.btnKey]: "ERROR",
      },
      errorMessage: {
        ...state.errorMessage,
        [params.card]: result[1],
      },
    });
  } else {
    params.onSuccess?.();

    controller.setState(
      {
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.btnKey]: "SUCCESS",
        },
      },
      () => HandleFetchPatientSegment(controller, params)
    );
  }
};

const HandleFetchPatientSegment: Handler = async (
  controller,
  params: Params<"FETCH_PATIENT_SEGMENT">
) => {
  let state = controller.getState();
  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [params?.btnKey]: "LOADING",
    },
  });

  const group = state.HealthMemberSequence?.segmentGroupDetail?.id || "";
  const segment = await PatientSegmentList.list({
    apiToken: controller.apiToken,
    params: { group: group, use_segment_group_code: true },
  });

  let items = segment[0]?.items
  items.sort((a: any, b: any) => {
    let unreadA = a.chat_channel ? a.chat_channel.unread_messages_count || 0 : 0;
    let unreadB = b.chat_channel ? b.chat_channel.unread_messages_count || 0 : 0;
    return unreadB - unreadA;
  });

  state = controller.getState();
  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      [params?.btnKey]: segment[1] ? "ERROR" : "SUCCESS",
    },
    HealthMemberSequence: {
      ...state.HealthMemberSequence,
      patientSegmentList: items || [],
    },
  });
};

// API
export const GetHealthMemberSegment: Handler = async (controller, params) => {
  const state = controller.getState();

  HandleFetchSegmentGroup(controller, params);
};

export const SelectSegmentGroup: Handler = async (controller, params) => {
  const state = controller.getState();

  HandleSelectSegmentGroup(controller, { data: params.segmentGroupDetail });
};

export const SelectPatientSegment: Handler = async (controller, params) => {
  const state = controller.getState();

  // get proxy patient has division
  const group = state.HealthMemberSequence?.segmentGroupDetail?.id || "";
  const pp = await ProxyPatientNoUserListView.list({
    apiToken: controller.apiToken,
    params: { hn: params.selectedPatientSegment?.hn, division: state.HealthMemberSequence?.division?.id },
  });

  let tmp = params.selectedPatientSegment
  tmp = {
    ...tmp,
    chat_channel: pp[0]?.items[0]?.proxy_patient_has_division?.chat_channel || null,
    first_name: pp[0]?.items[0]?.first_name || null,
    last_name: pp[0]?.items[0]?.last_name || null,
  }

  controller.setState({
    HealthMemberSequence: {
      ...state.HealthMemberSequence,
      selectedPatientSegment: tmp,
    },
  });
};

/* ------------------------------------------------------ */

/*                          Utils                         */

/* ------------------------------------------------------ */
