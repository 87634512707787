import BaseService from "./BaseService";
import { ADR } from "../../configs/apis";
class ADRService extends BaseService {
  /**
   * get AdverseReaction
   * @param {object} id
   */
  getAdverseReaction(id) {
    const url = ADR.ADVERSE_REACTION({ patient: id });
    return this.client
      .get(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getAdverseReactionList({ params } = {}) {
    return this.client
      .get(ADR.ADVERSE_REACTION_LIST, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  postAdverseReaction({ params, data } = {}) {
    return this.client
      .post(ADR.ADVERSE_REACTION_LIST, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  putAdverseReaction({ params, data, adrId } = {}) {
    const url = ADR.ADVERSE_REACTION_DETAIL({ adr_id: adrId });
    return this.client
      .put(url, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
}
export default ADRService;
