import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  FormGroup,
  FormField,
  Checkbox,
  Button
} from 'semantic-ui-react'

const CardSupplyOrderQueueListUX = (props: any) => {
    return(
      <div
        id="CardSupplyOrderQueueList"
        style={{margin: "10px"}}>
        <div
          style={{fontWeight: "bold", fontSize: "large"}}>
          รายการใบสั่งเวชภัณฑ์
        </div>
        <div
          className="ui form"
          style={{display: "", alignItems: "center", margin: "10px 0px"}}>
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              style={{padding:0}}>
              <label
                style={{textAlign: "right", width: "100%"}}>
                Patient
              </label>
            </FormField>
            <FormField
              inline={true}
              style={{padding:0}}
              width={3}>
              <div
                style={{width: "100%"}}>
                {props.cardPatientSearchBox}
              </div>
            </FormField>
            <FormField
              inline={true}
              style={{minWidth: "max-content", padding:0}}>
              <label>
                Division/Ward
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{width: "100%"}}>
                {props.divisionSearchBox}
              </div>
            </FormField>
            <FormField
              inline={true}
              style={{minWidth: "max-content", paddingLeft: "0.5em"}}>
              <label>
                Order Status
              </label>
            </FormField>
            <FormField
              inline={true}
              width={6}>
              <div
                id="cd-supplyOrderStatus"
                style={{width: "100%"}}>
                {props.orderSearchBox}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <Checkbox
                checked={props.isOPD}
                id="chk-supplyOPD"
                label="OPD"
                onChange={props.onChangeOPD}
                style={{width: "100%"}}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}>
              <Checkbox
                checked={props.isIPD}
                id="chk-supplyIPD"
                label="IPD"
                onClick={props.onChangeIPD}
                style={{width: "100%"}}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}>
              <Button
                color="blue"
                id="btn-filterSupply"
                onClick={props.onFilter}>
                ค้นหา
              </Button>
            </FormField>
          </FormGroup>
        </div>
        <div>
          
          <Table
            data={props.supplyOrderQueueList}
            getTrProps={props.getTrProps}
            headers="ประเภท,ผู้ป่วย,แผนกที่สั่ง,สั่ง,ตรวจ,จัด,จ่าย"
            id="tb-supplyOrderReqList"
            keys="type,patient,clinic,requested,printed,checked,delivered"
            minRows="10"
            showPagination={false}
            style={{height: "calc(100dvh - 19rem)"}}
            widths="^100,^150,^150,^110,^110,^110,^110">
          </Table>
        </div>
        <div
          style={{display: "flex", justifyContent: "flex-end", margin:"0.75rem 0.5rem 0"}}>
          
          <div>
            {props.Pagination}
          </div>
        </div>
      </div>
    )
}


export default CardSupplyOrderQueueListUX

export const screenPropsDefault = {}

/* Date Time : Fri Mar 15 2024 11:22:26 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardSupplyOrderQueueList"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการใบสั่งเวชภัณฑ์"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"large\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"\", alignItems: \"center\", margin: \"10px 0px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 11,
      "name": "Table",
      "parent": 34,
      "props": {
        "NoDataComponent": {
          "type": "value",
          "value": " "
        },
        "data": {
          "type": "code",
          "value": "props.supplyOrderQueueList"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getTrProps"
        },
        "headers": {
          "type": "value",
          "value": "ประเภท,ผู้ป่วย,แผนกที่สั่ง,สั่ง,ตรวจ,จัด,จ่าย"
        },
        "id": {
          "type": "value",
          "value": "tb-supplyOrderReqList"
        },
        "keys": {
          "type": "value",
          "value": "type,patient,clinic,requested,printed,checked,delivered"
        },
        "minRows": {
          "type": "value",
          "value": "10"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"calc(100dvh - 19rem)\"}"
        },
        "widths": {
          "type": "value",
          "value": "^100,^150,^150,^110,^110,^110,^110"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "FormGroup",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "FormField",
      "parent": 12,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding:0}"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "FormField",
      "parent": 12,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "FormField",
      "parent": 12,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "FormField",
      "parent": 12,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "FormField",
      "parent": 12,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "FormField",
      "parent": 12,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "code",
          "value": "props.cardPatientSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "code",
          "value": "props.divisionSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "code",
          "value": "props.orderSearchBox"
        },
        "id": {
          "type": "value",
          "value": "cd-supplyOrderStatus"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "Checkbox",
      "parent": 16,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isOPD"
        },
        "id": {
          "type": "value",
          "value": "chk-supplyOPD"
        },
        "label": {
          "type": "value",
          "value": "OPD"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeOPD"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "Checkbox",
      "parent": 17,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isIPD"
        },
        "id": {
          "type": "value",
          "value": "chk-supplyIPD"
        },
        "label": {
          "type": "value",
          "value": "IPD"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeIPD"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "Button",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "id": {
          "type": "value",
          "value": "btn-filterSupply"
        },
        "onClick": {
          "type": "code",
          "value": "props.onFilter"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "FormField",
      "parent": 12,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding:0}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "FormField",
      "parent": 12,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", padding:0}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "FormField",
      "parent": 12,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", paddingLeft: \"0.5em\"}"
        },
        "width": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "label",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": "Patient"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"right\", width: \"100%\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "label",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": "Division/Ward"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "label",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": "Order Status"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", margin:\"0.75rem 0.5rem 0\"}"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 35,
      "props": {
        "children": {
          "type": "code",
          "value": "props.Pagination"
        }
      },
      "seq": 36,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardSupplyOrderQueueListUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
