import BaseService from "./BaseService";
import { DEN } from "../../configs/apis";
import _ from "../../compat/lodashplus";

class DENService extends BaseService {
  getListTreatmentIssue({ params }: any) {
    return this.client
      .get(DEN.TREATMENT_ISSUE, { params })
      .then((response) => response.data)
      .catch(this.throwErrorMessage);
  }
}
export default DENService;
