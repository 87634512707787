const FormTaxCertificate = (props: any) => {
  // Define content for pdfmake
  // Define content for pdfmake
  const content: any[] = [];

  const getDecimal = (value: number) => {
    let rounded = Math.floor(value);

    return (value - rounded || 0).toFixed(2)?.toLocaleString()?.slice(2, 4) || "";
  };

  // Iterate over JSON data and add rows to the table
  props.fields.forEach((data: any) => {
    // Concatenate fullName and LastName
    const earnPerYear =
      Number(data?.total_net_price) >= 0
        ? `${Number(data?.total_net_price?.toFixed(0))?.toLocaleString() || ""}.${
            data?.total_net_price === 0 ? "00" : getDecimal(Number(data?.total_net_price))
          }`
        : " ";

    const taxPerYear =
      Number(data?.total_tax) >= 0
        ? `${Number(data?.total_tax?.toFixed(0))?.toLocaleString() || ""}.${
            data?.total_tax === 0 ? "00" : getDecimal(Number(data?.total_tax))
          }`
        : " ";

    // Add hyphen between each letter in idCard

    const formattedIdCard =
      data?.citizen_id?.slice(0, 1) +
      (data?.citizen_id ? "-" : "") +
      data?.citizen_id?.slice(1, 5) +
      (data?.citizen_id ? "-" : "") +
      data?.citizen_id?.slice(5, 10) +
      (data?.citizen_id ? "-" : "") +
      data?.citizen_id?.slice(10, 12) +
      (data?.citizen_id ? "-" : "") +
      data?.citizen_id?.slice(12);

    const formattedtaxId =
      data?.tax_payer_id?.slice(0, 1) +
      (data?.tax_payer_id ? "-" : "") +
      data?.tax_payer_id?.slice(1, 5) +
      (data?.tax_payer_id ? "-" : "") +
      data?.tax_payer_id?.slice(5, 9) +
      (data?.tax_payer_id ? "-" : "") +
      data?.tax_payer_id?.slice(9);

    const row = [
      {
        text: `${data?.seq || ""}.`,
        fontSize: 12,
        alignment: "left",
        border: [true, true, true, false],
      },
      {
        text: data?.doctor_name || "",
        fontSize: 12,
        alignment: "left",
        border: [true, true, true, false],
      },
      {
        text: data?.citizen_id ? formattedIdCard : "",
        fontSize: 12,
        alignment: "center",
        border: [true, true, true, false],
      },
      {
        text: data?.tax_payer_id ? formattedtaxId : "",
        fontSize: 12,
        alignment: "center",
        border: [true, true, true, false],
      },
      {
        text: earnPerYear,
        fontSize: 12,
        alignment: "right",
        border: [true, true, true, false],
      },
      {
        text: taxPerYear,
        fontSize: 12,
        alignment: "right",
        border: [true, true, true, false],
      },
    ];

    content.push(row);
  });

  let fieldsLength = props.fields?.length > 30 ? props.fields?.length / 30 : props.fields?.length;
  let numberRows = 30 - fieldsLength;

  const rowEmpty = Array.from({ length: numberRows }).map((item: any, index: number) => {
    return [
      {
        text: " ",
        border: index + 1 === numberRows ? [true, false, true, true] : [true, false, true, false],
      },
      {
        text: " ",
        border: index + 1 === numberRows ? [true, false, true, true] : [true, false, true, false],
      },
      {
        text: " ",
        border: index + 1 === numberRows ? [true, false, true, true] : [true, false, true, false],
      },
      {
        text: " ",
        border: index + 1 === numberRows ? [true, false, true, true] : [true, false, true, false],
      },
      {
        text: " ",
        border: index + 1 === numberRows ? [true, false, true, true] : [true, false, true, false],
      },
      {
        text: " ",
        border: index + 1 === numberRows ? [true, false, true, true] : [true, false, true, false],
      },
    ];
  });

  // Define PDF document definition
  return {
    defaultStyle: {
      font: `THSarabunNew`,
      fontSize: 14,
    },
    pageSize: "A4",
    pageMargins: [10, 20, 10, 10],
    content: [
      {
        text: "ใบแนบ หนังสือรับรองหักภาษี ณ ที่จ่าย",
        style: "header",
      },
      {
        text: `เดือน ${props.params?.start_month} - ${props.params?.end_month} ${props.params?.year}`,
        style: "header",
      },
      {
        style: "tableExample",
        table: {
          widths: ["5%", "*", 120, 120, 60, 60],
          body: [
            [
              {
                text: "ลำดับ",
                rowSpan: 2,
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "ชื่อ-สกุล",
                rowSpan: 2,
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "เลขประจำตัวประชาชน",
                rowSpan: 2,
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "เลขที่ผู้เสียภาษี",
                rowSpan: 2,
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "รายได้ปี",
                colSpan: 2,
                style: "tableHeader",
                alignment: "center",
              },
              {},
            ],
            [
              "",
              "",
              "",
              "",
              {
                text: "รายได้",
                style: "tableHeader",
                alignment: "center",
              },
              { text: "ภาษี", style: "tableHeader", alignment: "center" },
            ],
            ...content,
            ...rowEmpty,
          ],
        },
      },
    ],
    styles: {
      header: {
        fontSize: 14,
        bold: true,
        alignment: "center",
        margin: [0, 0, 0, 10],
      },
      tableExample: {
        margin: [0, 5, 0, 15],
      },
      tableHeader: {
        fontSize: 14,
        bold: true,
        alignment: "center",
      },
    },
  };
};

export default FormTaxCertificate;
