import React, {
  FC,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Form, Grid, Header } from "semantic-ui-react";
import CardMuscleStrength from "./CardMuscleStrength";
import CardRangeOfMotion from "./CardRangeOfMotion";
import { useIntl } from "react-intl";

const styles = {
  gridBG: {
    background: "#F3F3F3",
    marginLeft: "5px",
    marginRight: "5px",
    padding: "15px 0px 15px 1px",
  },
  noPadding: {
    padding: "0px",
  },
  leftPadding: {
    paddingLeft: "1px",
  },
};

export interface CardPTPhysicalExamOrthopedicMIDViewDataType {
  viewData: {
    muscleStrength: {
      isNormal: boolean;
      items: [
        {
          normalRemark: string;
          grade: string;
        }
      ];
    };
    rangeOfMotion: {
      isFull: boolean;
      items: [{ limit: string }];
    };
  };
  readonly: boolean,
}

const CardPTPhysicalExamOrthopedicMID: FC<CardPTPhysicalExamOrthopedicMIDViewDataType> = forwardRef(
  (props, ref) => {
    // Muscle Strength
    const subMuscleStrengthRef = useRef();

    // Range Of Motion
    const subRangeOfMotionRef = useRef();

    useImperativeHandle(ref, () => ({
      getData: () => {
        return {
          muscleStrength: subMuscleStrengthRef.current.getData(),
          rangeOfMotion: subRangeOfMotionRef.current.getData(),
        };
      },
    }));

    return (
      <>
        {/* Muscle strength */}
        <Grid style={styles.gridBG}>
          <Grid.Row columns={2} style={styles.noPadding}>
            <Grid.Column width={2}>
              <Form>
                <Form.Group inline>
                  <Form.Field width={1}></Form.Field>
                  <Form.Field width={15}>
                    <Header.Content>
                      <strong>Muscle strength</strong>
                    </Header.Content>
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>

            <Grid.Column width={14} style={styles.leftPadding}>
              <CardMuscleStrength
                ref={subMuscleStrengthRef}
                data={props.viewData.muscleStrength}
                readonly={props.readonly}
                languageUX={props.languageUX}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {/* Range of motion */}
        <Grid style={styles.gridBG}>
          <Grid.Row columns={2} style={styles.noPadding}>
            <Grid.Column width={2}>
              <Form>
                <Form.Group inline>
                  <Form.Field width={1}></Form.Field>
                  <Form.Field width={15}>
                    <Header.Content>
                      <strong>Range of motion</strong>
                    </Header.Content>
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>

            <Grid.Column width={14} style={styles.leftPadding}>
              <CardRangeOfMotion
                ref={subRangeOfMotionRef}
                data={props.viewData.rangeOfMotion}
                readonly={props.readonly}
                languageUX={props.languageUX}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    );
  }
);

export const CardPTPhysicalExamOrthopedicMIDInitialViewData: CardPTPhysicalExamOrthopedicMIDViewDataType = {
  viewData: {
    muscleStrength: {
      isNormal: false,
      items: [{ normalRemark: "", grade: "" }],
    },
    rangeOfMotion: {
      isFull: true,
      items: [{ limit: "" }],
    },
  },
  readonly: false,
};

CardPTPhysicalExamOrthopedicMID.defaultProps = CardPTPhysicalExamOrthopedicMIDInitialViewData;

export default CardPTPhysicalExamOrthopedicMID;
