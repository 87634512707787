import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  FormField,
  Input,
  Dropdown
} from 'semantic-ui-react'

const SubAddressUX = (props: any) => {
    return(
      <div
        id="SubAddress"
        style={{height: "100%", padding: "15px 5px 15px 15px"}}>
        <Form
          className={props.disabled  ? "--override-disabled" : ""}>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={6}>
              <label
                style={{fontWeight: "normal", minWidth: "95px", maxWidth: "95px"}}>
                บ้านเลขที่ / Current address
              </label>
              <Input
                disabled={props.disabled || false}
                fluid={true}
                id={`SubAddress-Input-${props.idPrefix}-No`}
                onChange={props.changeAddress("no")}
                style={{width: "100%"}}
                value={props.no}>
              </Input>
            </FormField>
            <FormField
              inline={true}
              width={6}>
              <label
                style={{fontWeight: "normal", minWidth: "115px", maxWidth: "115px"}}>
                ชื่อหมู่บ้าน/อาคาร / Village name
              </label>
              <Input
                disabled={props.disabled || false}
                fluid={true}
                id={`SubAddress-Input-${props.idPrefix}-Name`}
                onChange={props.changeAddress("name")}
                style={{width: "100%"}}
                value={props.name}>
              </Input>
            </FormField>
            <FormField
              inline={true}
              style={{padding:0}}
              width={6}>
              <label
                style={{fontWeight: "normal", minWidth: "100px", maxWidth: "100px"}}>
                ประเภทที่พักอาศัย / Village type
              </label>
              <Dropdown
                clearable={true}
                disabled={props.disabled || false}
                fluid={true}
                id={`SubAddress-Dropdown-${props.idPrefix}-Type`}
                onChange={props.changeAddress("type")}
                options={props.homeTypeOptions}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.type}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={6}>
              <label
                style={{fontWeight: "normal", minWidth: "95px", maxWidth: "95px"}}>
                หมู่ที่ / Village number
              </label>
              <Input
                disabled={props.disabled || false}
                fluid={true}
                id={`SubAddress-Input-${props.idPrefix}-Town`}
                onChange={props.changeAddress("town")}
                style={{width: "100%"}}
                value={props.town}>
              </Input>
            </FormField>
            <FormField
              inline={true}
              width={6}>
              <label
                style={{fontWeight: "normal", minWidth: "115px", maxWidth: "115px"}}>
                ซอย / Soi
              </label>
              <Input
                disabled={props.disabled || false}
                fluid={true}
                id={`SubAddress-Input-${props.idPrefix}-Street`}
                onChange={props.changeAddress("street")}
                style={{width: "100%"}}
                value={props.street}>
              </Input>
            </FormField>
            <FormField
              inline={true}
              style={{padding:0}}
              width={6}>
              <label
                style={{fontWeight: "normal", minWidth: "100px", maxWidth: "100px"}}>
                ถนน / Street
              </label>
              <Input
                disabled={props.disabled || false}
                fluid={true}
                id={`SubAddress-Input-${props.idPrefix}-Road`}
                onChange={props.changeAddress("road")}
                style={{width: "100%"}}
                value={props.road}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={4}>
              <label
                style={{fontWeight: "normal", minWidth: "95px", maxWidth: "95px"}}>
                จังหวัด / Province
              </label>
              <Dropdown
                clearable={true}
                disabled={props.disabled || false}
                fluid={true}
                id={`SubAddress-Dropdown-${props.idPrefix}-Province`}
                onChange={props.changeAddress("province")}
                options={props.provinceOptions}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.province}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <label
                style={{fontWeight: "normal"}}>
                อำเภอ / District
              </label>
              <Dropdown
                clearable={true}
                disabled={props.disabled || false}
                fluid={true}
                id={`SubAddress-Dropdown-${props.idPrefix}-District`}
                onChange={props.changeAddress("district")}
                options={props.districtOptions}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.district}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <label
                style={{fontWeight: "normal"}}>
                ตำบล / Sub-district
              </label>
              <Dropdown
                clearable={true}
                disabled={props.disabled || false}
                fluid={true}
                id={`SubAddress-Dropdown-${props.idPrefix}-City`}
                onChange={props.changeAddress("city")}
                options={props.cityOptions}
                search={true}
                selection={true}
                style={{width:"100%"}}
                value={props.city}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}
              style={{padding:0}}
              width={4}>
              <label
                style={{fontWeight: "normal"}}>
                รหัสไปรษณีย์ / Zip code
              </label>
              <Input
                disabled={props.disabled || false}
                fluid={true}
                id={`SubAddress-Input-${props.idPrefix}-Zipcode`}
                onChange={props.changeAddress("zipcode")}
                style={{width: "100%"}}
                value={props.zipcode}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={6}>
              <label
                style={{fontWeight: "normal", minWidth: "95px", maxWidth: "95px"}}>
                โทรศัพท์บ้าน / Landline
              </label>
              <Input
                disabled={props.disabled || false}
                fluid={true}
                id={`SubAddress-Input-${props.idPrefix}-TelHome`}
                onChange={props.changeAddress("tel_home")}
                style={{width: "100%"}}
                value={props.tel_home}>
              </Input>
              <Input
                disabled={props.disabled || false}
                fluid={true}
                id={`SubAddress-Input-${props.idPrefix}-TelHome-Suffix`}
                onChange={props.changeAddress("tel_home_suffix")}
                style={{width: "7rem"}}
                value={props.tel_home_suffix}>
              </Input>
            </FormField>
            <FormField
              inline={true}
              width={6}>
              <label
                style={{fontWeight: "normal", minWidth: "115px", maxWidth: "115px"}}>
                โทรศัพท์ที่ทำงาน
              </label>
              <Input
                disabled={props.disabled || false}
                fluid={true}
                id={`SubAddress-Input-${props.idPrefix}-TelOffice`}
                onChange={props.changeAddress("tel_office")}
                style={{width: "100%"}}
                value={props.tel_office}>
              </Input>
              <Input
                disabled={props.disabled || false}
                fluid={true}
                id={`SubAddress-Input-${props.idPrefix}-TelOffice-Suffix`}
                onChange={props.changeAddress("tel_office_suffix")}
                style={{width: "7rem"}}
                value={props.tel_office_suffix}>
              </Input>
            </FormField>
            <FormField
              inline={true}
              style={{padding:0}}
              width={6}>
              <label
                style={{fontWeight: "normal", minWidth: "100px", maxWidth: "100px"}}>
                โทรศัพท์มือถือ / Mobile phone
              </label>
              <Input
                disabled={props.disabled || false}
                fluid={true}
                id={`SubAddress-Input-${props.idPrefix}-TelMobile`}
                onChange={props.changeAddress("tel_mobile")}
                style={{width: "100%"}}
                value={props.tel_mobile}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{margin:0}}>
            <FormField
              inline={true}
              width={6}>
              <label
                style={{fontWeight: "normal", minWidth: "95px", maxWidth: "95px"}}>
                E-mail
              </label>
              <Input
                disabled={props.disabled || false}
                fluid={true}
                id={`SubAddress-Input-${props.idPrefix}-Email`}
                onChange={props.changeAddress("email")}
                style={{width: "100%"}}
                value={props.email}>
              </Input>
            </FormField>
            <FormField
              inline={true}
              width={6}>
              <label
                style={{fontWeight: "normal", minWidth: "115px", maxWidth: "115px"}}>
                ช่องทางการส่งข้อมูล / Information Channel
              </label>
              <Dropdown
                clearable={true}
                disabled={props.disabled || false}
                fluid={true}
                id={`SubAddress-Dropdown-${props.idPrefix}-Channel`}
                onChange={props.changeAddress("channel")}
                options={props.contactOptions}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.channel || ""}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}
              style={{padding:0}}
              width={6}>
              <label
                style={{fontWeight: "normal", minWidth: "100px", maxWidth: "100px"}}>
                หมายเหตุ  / Note
              </label>
              <Input
                disabled={props.disabled || false}
                fluid={true}
                id={`SubAddress-Input-${props.idPrefix}-Note`}
                onChange={props.changeAddress("note")}
                style={{width: "100%"}}
                value={props.note}>
              </Input>
            </FormField>
          </FormGroup>
        </Form>
      </div>
    )
}


export default SubAddressUX

export const screenPropsDefault = {}

/* Date Time : Thu Nov 14 2024 12:46:21 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "SubAddress"
        },
        "style": {
          "type": "code",
          "value": "{height: \"100%\", padding: \"15px 5px 15px 15px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "Form",
      "parent": 0,
      "props": {
        "className": {
          "type": "code",
          "value": "props.disabled  ? \"--override-disabled\" : \"\""
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "FormGroup",
      "parent": 57,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "FormGroup",
      "parent": 57,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "FormGroup",
      "parent": 57,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "FormGroup",
      "parent": 57,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "FormGroup",
      "parent": 57,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{margin:0}"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "FormField",
      "parent": 58,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "FormField",
      "parent": 58,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "FormField",
      "parent": 58,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding:0}"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "FormField",
      "parent": 59,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "FormField",
      "parent": 59,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "FormField",
      "parent": 59,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding:0}"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "FormField",
      "parent": 60,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "FormField",
      "parent": 60,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "FormField",
      "parent": 60,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "FormField",
      "parent": 60,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding:0}"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "FormField",
      "parent": 61,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "FormField",
      "parent": 61,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "FormField",
      "parent": 61,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding:0}"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "FormField",
      "parent": 62,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 77,
      "name": "FormField",
      "parent": 62,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "FormField",
      "parent": 62,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding:0}"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "label",
      "parent": 63,
      "props": {
        "children": {
          "type": "value",
          "value": "บ้านเลขที่ / Current address"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", minWidth: \"95px\", maxWidth: \"95px\"}"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "label",
      "parent": 64,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อหมู่บ้าน/อาคาร / Village name"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", minWidth: \"115px\", maxWidth: \"115px\"}"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "label",
      "parent": 65,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทที่พักอาศัย / Village type"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", minWidth: \"100px\", maxWidth: \"100px\"}"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "Input",
      "parent": 63,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disabled || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "code",
          "value": "`SubAddress-Input-${props.idPrefix}-No`"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeAddress(\"no\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.no"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 83,
      "name": "Input",
      "parent": 64,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disabled || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "code",
          "value": "`SubAddress-Input-${props.idPrefix}-Name`"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeAddress(\"name\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.name"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 84,
      "name": "Dropdown",
      "parent": 65,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabled || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "code",
          "value": "`SubAddress-Dropdown-${props.idPrefix}-Type`"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeAddress(\"type\")"
        },
        "options": {
          "type": "code",
          "value": "props.homeTypeOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.type"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "label",
      "parent": 66,
      "props": {
        "children": {
          "type": "value",
          "value": "หมู่ที่ / Village number"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", minWidth: \"95px\", maxWidth: \"95px\"}"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": null,
      "id": 86,
      "name": "label",
      "parent": 67,
      "props": {
        "children": {
          "type": "value",
          "value": "ซอย / Soi"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", minWidth: \"115px\", maxWidth: \"115px\"}"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "label",
      "parent": 68,
      "props": {
        "children": {
          "type": "value",
          "value": "ถนน / Street"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", minWidth: \"100px\", maxWidth: \"100px\"}"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 88,
      "name": "Input",
      "parent": 66,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disabled || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "code",
          "value": "`SubAddress-Input-${props.idPrefix}-Town`"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeAddress(\"town\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.town"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 89,
      "name": "Input",
      "parent": 67,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disabled || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "code",
          "value": "`SubAddress-Input-${props.idPrefix}-Street`"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeAddress(\"street\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.street"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 90,
      "name": "Input",
      "parent": 68,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disabled || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "code",
          "value": "`SubAddress-Input-${props.idPrefix}-Road`"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeAddress(\"road\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.road"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": null,
      "id": 91,
      "name": "label",
      "parent": 69,
      "props": {
        "children": {
          "type": "value",
          "value": "จังหวัด / Province"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", minWidth: \"95px\", maxWidth: \"95px\"}"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "label",
      "parent": 70,
      "props": {
        "children": {
          "type": "value",
          "value": "อำเภอ / District"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 93,
      "name": "label",
      "parent": 71,
      "props": {
        "children": {
          "type": "value",
          "value": "ตำบล / Sub-district"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "label",
      "parent": 72,
      "props": {
        "children": {
          "type": "value",
          "value": "รหัสไปรษณีย์ / Zip code"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 95,
      "name": "Dropdown",
      "parent": 69,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabled || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "code",
          "value": "`SubAddress-Dropdown-${props.idPrefix}-Province`"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeAddress(\"province\")"
        },
        "options": {
          "type": "code",
          "value": "props.provinceOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.province"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 96,
      "name": "Dropdown",
      "parent": 70,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabled || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "code",
          "value": "`SubAddress-Dropdown-${props.idPrefix}-District`"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeAddress(\"district\")"
        },
        "options": {
          "type": "code",
          "value": "props.districtOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.district"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 98,
      "name": "Input",
      "parent": 72,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disabled || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "code",
          "value": "`SubAddress-Input-${props.idPrefix}-Zipcode`"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeAddress(\"zipcode\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.zipcode"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 99,
      "name": "label",
      "parent": 73,
      "props": {
        "children": {
          "type": "value",
          "value": "โทรศัพท์บ้าน / Landline"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", minWidth: \"95px\", maxWidth: \"95px\"}"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": null,
      "id": 100,
      "name": "label",
      "parent": 74,
      "props": {
        "children": {
          "type": "value",
          "value": "โทรศัพท์ที่ทำงาน"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", minWidth: \"115px\", maxWidth: \"115px\"}"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": null,
      "id": 101,
      "name": "label",
      "parent": 75,
      "props": {
        "children": {
          "type": "value",
          "value": "โทรศัพท์มือถือ / Mobile phone"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", minWidth: \"100px\", maxWidth: \"100px\"}"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": null,
      "id": 102,
      "name": "label",
      "parent": 76,
      "props": {
        "children": {
          "type": "value",
          "value": "E-mail"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", minWidth: \"95px\", maxWidth: \"95px\"}"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": null,
      "id": 103,
      "name": "label",
      "parent": 77,
      "props": {
        "children": {
          "type": "value",
          "value": "ช่องทางการส่งข้อมูล / Information Channel"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", minWidth: \"115px\", maxWidth: \"115px\"}"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": null,
      "id": 104,
      "name": "label",
      "parent": 78,
      "props": {
        "children": {
          "type": "value",
          "value": "หมายเหตุ  / Note"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", minWidth: \"100px\", maxWidth: \"100px\"}"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 105,
      "name": "Input",
      "parent": 76,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disabled || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "code",
          "value": "`SubAddress-Input-${props.idPrefix}-Email`"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeAddress(\"email\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.email"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 109,
      "name": "Input",
      "parent": 73,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disabled || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "code",
          "value": "`SubAddress-Input-${props.idPrefix}-TelHome`"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeAddress(\"tel_home\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.tel_home"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 110,
      "name": "Input",
      "parent": 73,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disabled || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "code",
          "value": "`SubAddress-Input-${props.idPrefix}-TelHome-Suffix`"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeAddress(\"tel_home_suffix\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"7rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.tel_home_suffix"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 111,
      "name": "Input",
      "parent": 74,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disabled || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "code",
          "value": "`SubAddress-Input-${props.idPrefix}-TelOffice`"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeAddress(\"tel_office\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.tel_office"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 112,
      "name": "Input",
      "parent": 74,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disabled || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "code",
          "value": "`SubAddress-Input-${props.idPrefix}-TelOffice-Suffix`"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeAddress(\"tel_office_suffix\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"7rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.tel_office_suffix"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 113,
      "name": "Input",
      "parent": 75,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disabled || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "code",
          "value": "`SubAddress-Input-${props.idPrefix}-TelMobile`"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeAddress(\"tel_mobile\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.tel_mobile"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 114,
      "name": "Dropdown",
      "parent": 77,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabled || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "code",
          "value": "`SubAddress-Dropdown-${props.idPrefix}-Channel`"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeAddress(\"channel\")"
        },
        "options": {
          "type": "code",
          "value": "props.contactOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.channel || \"\""
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 115,
      "name": "Input",
      "parent": 78,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disabled || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "code",
          "value": "`SubAddress-Input-${props.idPrefix}-Note`"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeAddress(\"note\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.note"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 116,
      "name": "Dropdown",
      "parent": 71,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabled || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "code",
          "value": "`SubAddress-Dropdown-${props.idPrefix}-City`"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeAddress(\"city\")"
        },
        "options": {
          "type": "code",
          "value": "props.cityOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.city"
        }
      },
      "seq": 116,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 40,
  "isMounted": false,
  "memo": false,
  "name": "SubAddressUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 65
}

*********************************************************************************** */
