import React, {
  FC,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Button, Checkbox, Form, Grid, Icon, Input } from "semantic-ui-react";
import RightLabel from "react-lib/apps/common/RightLabel";
import { useIntl } from "react-intl";

const styles = {
  noPadding: {
    padding: "0px",
  },
  noRightPadding: {
    paddingRight: "0px",
  },
  noBottomPadding: {
    paddingBottom: "5px",
  },
  rightMargin: {
    marginRight: "-30px",
  },
};

interface SubPhysicalExamMuscleStrengthProps {
  index: number;
  data: { normalRemark: string; grade: string };
  onAddComponent: (any) => any;
  onRemoveComponent: (any) => any;
  readonly: boolean;
}

const SubPhysicalExamMuscleStrength: FC<SubPhysicalExamMuscleStrengthProps> = forwardRef(
  (props, ref) => {
    const [item, setItem] = useState<any>(defaultItemData);

    useEffect(() => {
      setItem(props.data);
    }, [props.data]);

    useImperativeHandle(ref, () => ({
      getData: () => {
        return item;
      },
    }));

    const handleValue = (event, data) => {
      if (!props.readonly) {
        setItem({ ...item, [data.name]: data.value });
      }
    };

    const handleRemove = () => {
      if (!props.readonly) {
        props.onRemoveComponent(props.index);
      }
    };

    return (
      <Form>
        <Form.Group inline>
          <Form.Field width={6}>
            <Input
              readOnly={props.readonly}
              value={item.normalRemark || ""}
              name="normalRemark"
              onChange={handleValue}
            />
          </Form.Field>
          <Form.Field width={1}>
            <RightLabel>Grade</RightLabel>
          </Form.Field>
          <Form.Field width={7}>
            <Input
              readOnly={props.readonly}
              value={item.grade || ""}
              name="grade"
              onChange={handleValue}
            />
          </Form.Field>
          <Form.Field width={1}>
            <Button
              icon
              color="green"
              type="button"
              size="small"
              disabled={props.readonly}
              onClick={props.onAddComponent}
            >
              <Icon name="plus" />
            </Button>
          </Form.Field>
          <Form.Field width={1}>
            <Button
              icon
              color="red"
              type="button"
              size="small"
              disabled={props.readonly}
              onClick={handleRemove}
            >
              <Icon name="delete" />
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>
    );
  }
);

/* ----- CardMuscleStrength ----- */
export interface CardMuscleStrengthViewDataType {
  data: {
    isNormal: boolean;
    items: [
      {
        normalRemark: string;
        grade: string;
      }
    ];
  };
  readonly: boolean;
}

const CardMuscleStrength: FC<CardMuscleStrengthViewDataType> = forwardRef(
  (props, ref) => {
    const subMuscleStrengthRef = useRef([]);
    const [muscleStrength, setMuscleStrength] = useState<any>(defaultData);

    useEffect(() => {
      let mItems: any = { ...props.data };
      if (mItems.items.length === 0) {
        mItems.items.push(defaultItemData);
      }
      setMuscleStrength(mItems);
    }, [props.data]);

    useImperativeHandle(ref, () => ({
      getData: () => {
        let mItems: any = [];
        subMuscleStrengthRef.current.map((item) => {
          try {
            mItems.push(item.getData());
          } catch (err) {}
        });
        const muscleStrengthResult = { ...muscleStrength, items: mItems };

        return muscleStrengthResult;
      },
    }));

    // Muscle Strength
    const handleAddMuscleStrengthComponent = () => {
      if (!props.readonly) {
        let newItems: any = [...muscleStrength.items];
        newItems.push(defaultItemData);
        setMuscleStrength({ ...muscleStrength, items: newItems });
      }
    };

    const handleRemoveMuscleStrengthComponent = (index) => {
      if (!props.readonly) {
        let newItems: any = [];
        subMuscleStrengthRef.current.map((item, i) => {
          if (i !== index) {
            try {
              newItems.push(item.getData());
            } catch (err) {}
          }
        });
        if (newItems.length === 0) {
          newItems.push(defaultItemData);
        }
        setMuscleStrength({ ...muscleStrength, items: newItems });
      }
    };

    const handleChangeMuscleStrength = (event, data) => {
      if (!props.readonly) {
        setMuscleStrength({ ...muscleStrength, [data.name]: data.checked });
      }
    };

    return (
      <Grid>
        <Grid.Row columns={2} style={styles.noBottomPadding}>
          <Grid.Column width={2} style={styles.rightMargin}>
            <Form>
              <Form.Group inline>
                <Form.Field width={16}>
                  <Checkbox
                    readOnly={props.readonly}
                    label="Normal"
                    name="isNormal"
                    checked={muscleStrength.isNormal}
                    onChange={handleChangeMuscleStrength}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
          <Grid.Column width={14} style={styles.noRightPadding}>
            <SubPhysicalExamMuscleStrength
              ref={(el) => (subMuscleStrengthRef.current[0] = el)}
              key="muscleStrength0"
              index={0}
              readonly={props.readonly}
              data={muscleStrength.items ? muscleStrength.items[0] : {}}
              onAddComponent={handleAddMuscleStrengthComponent}
              onRemoveComponent={handleRemoveMuscleStrengthComponent}
              languageUX={props.languageUX}
            />
          </Grid.Column>
        </Grid.Row>
        {muscleStrength.items && (
          <Grid.Row columns={2} style={styles.noPadding}>
            <Grid.Column width={2} style={styles.rightMargin}></Grid.Column>
            <Grid.Column width={14} style={styles.noRightPadding}>
              {muscleStrength.items.map((item, index) => {
                if (index !== 0) {
                  return (
                    <SubPhysicalExamMuscleStrength
                      ref={(el) => (subMuscleStrengthRef.current[index] = el)}
                      key={`muscleStrength${index}`}
                      index={index}
                      data={item}
                      onAddComponent={handleAddMuscleStrengthComponent}
                      onRemoveComponent={handleRemoveMuscleStrengthComponent}
                      readonly={props.readonly}
                      languageUX={props.languageUX}
                    />
                  );
                }
              })}
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    );
  }
);

const defaultItemData = { normalRemark: "", grade: "" };

const defaultData = {
  isNormal: false,
  items: [defaultItemData],
};

export const CardMuscleStrengthInitialViewData: CardMuscleStrengthViewDataType = {
  data: defaultData,
  readonly: false,
};

CardMuscleStrength.defaultProps = CardMuscleStrengthInitialViewData;

export default CardMuscleStrength;
