import CONFIG from "config/config";
import {
  registerServiceWorker,
  createNotificationSubscription,
  getUserSubscription,
} from "react-lib/apps/IsHealth/Common/push-notifications";
import Fingerprint2 from "fingerprintjs2";

const getFingerPrintAndSubscription = async () => {
  // let registrations = null;
  // console.log('config.BYPASS_NOTIFICATION_SUBSCRIPTION: ', config.BYPASS_NOTIFICATION_SUBSCRIPTION);
  // if (!(config.BYPASS_NOTIFICATION_SUBSCRIPTION)) {
  //   try {
  //     registrations = await navigator.serviceWorker.getRegistrations();
  //     console.log("check service worker", registrations);
  //     if (registrations.length <= 0) {
  //       console.log("Force register service worker again !!!");
  //       serviceWorker.register();
  //       registerServiceWorker();
  //     }
  //   } catch (e) {
  //     console.log('Catch (e): ', e);
  //     if (navigator.serviceWorker) {
  //       console.log("Force register service worker again !!!");
  //       serviceWorker.register();
  //       registerServiceWorker();
  //     } else {
  //       console.log("Not have ", navigator.serviceWorker);
  //     }
  //   }
  // }
  const options = {};
  let components = await Fingerprint2.getPromise(options);
  let values = components.map((component: any) => component.value);
  let device_id = Fingerprint2.x64hash128(values.join(""), 31);
  console.log("getFingerPrintAndSubscription LCB Fingerprint id:", device_id);
  // Get subscription endpoint object
  let subscription;
  try {
    console.log("getFingerPrintAndSubscription call getUserSubscription");
    subscription = await getUserSubscription();
    console.log("getFingerPrintAndSubscription return subscription: ", subscription);
    if (!subscription) {
      subscription = await createNotificationSubscription();
      console.log("LCB Success get notification subscription.");
      console.log(subscription);
    }
  } catch (err) {
    console.log("LCB error getSubScriptionObejct");
    console.error(
      "Couldn't create the notification subscription",
      err,
      "name:",
      err.name,
      "message:",
      err.message,
      "code:",
      err.code
    );
  }
  return [device_id, subscription];
};

export const getDeviceIdAndUserSubScription = async () => {
  let deviceId = "";
  let userSubscription = null;
  if (CONFIG.BYPASS_SERVICE_WORKER) {
    // ไม่มี Service worker
    const options = {};
    let components = await Fingerprint2.getPromise(options);
    let values = components.map((component: any) => component.value);
    deviceId = Fingerprint2.x64hash128(values.join(""), 31);
    console.log("bypass sw.js");
  } else {
    console.log(" getFingerPrintAndSubscription ");
    let FPAndUS = await getFingerPrintAndSubscription();
    console.log(" getFingerPrintAndSubscription FPAndUS: ", FPAndUS);
    deviceId = FPAndUS[0];
    userSubscription = FPAndUS[1];
    console.log(" sw.js");
  }
  return [deviceId, userSubscription];
};

export const getOSName = () => {
  const nAgt = navigator.userAgent;
  let os = "Unknown OS";
  const clientStrings = [
    { s: "Windows 3.11", r: /Win16/ },
    { s: "Windows 95", r: /(Windows 95|Win95|Windows_95)/ },
    { s: "Windows ME", r: /(Win 9x 4.90|Windows ME)/ },
    { s: "Windows 98", r: /(Windows 98|Win98)/ },
    { s: "Windows CE", r: /Windows CE/ },
    { s: "Windows 2000", r: /(Windows NT 5.0|Windows 2000)/ },
    { s: "Windows XP", r: /(Windows NT 5.1|Windows XP)/ },
    { s: "Windows Server 2003", r: /Windows NT 5.2/ },
    { s: "Windows Vista", r: /Windows NT 6.0/ },
    { s: "Windows 7", r: /(Windows 7|Windows NT 6.1)/ },
    { s: "Windows 8.1", r: /(Windows 8.1|Windows NT 6.3)/ },
    { s: "Windows 8", r: /(Windows 8|Windows NT 6.2)/ },
    { s: "Windows NT 4.0", r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
    { s: "Windows ME", r: /Windows ME/ },
    { s: "Android", r: /Android/ },
    { s: "Open BSD", r: /OpenBSD/ },
    { s: "Sun OS", r: /SunOS/ },
    { s: "Linux", r: /(Linux|X11)/ },
    { s: "iOS", r: /(iPhone|iPad|iPod)/ },
    { s: "Mac OS X", r: /Mac OS X/ },
    { s: "Mac OS", r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
    { s: "QNX", r: /QNX/ },
    { s: "UNIX", r: /UNIX/ },
    { s: "BeOS", r: /BeOS/ },
    { s: "OS/2", r: /OS\/2/ },
    {
      s: "Search Bot",
      r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/,
    },
  ];
  for (let id in clientStrings) {
    let cs = clientStrings[id];
    if (cs.r.test(nAgt)) {
      os = cs.s;
      break;
    }
  }
  return os;
};

export const getBrowserName = () => {
  const nAgt = navigator.userAgent;

  // In Opera, the true version is after "Opera" or after "Version"
  if (nAgt.indexOf("Opera") !== -1) {
    return "Opera";
  }
  // In MSIE, the true version is after "MSIE" in userAgent
  else if (nAgt.indexOf("MSIE") !== -1) {
    return "Microsoft Internet Explorer";
  }
  // In Chrome, the true version is after "Chrome"
  else if (nAgt.indexOf("Chrome") !== -1) {
    return "Chrome";
  }
  // In Safari, the true version is after "Safari" or after "Version"
  else if (nAgt.indexOf("Safari") !== -1) {
    return "Safari";
  }
  // In Firefox, the true version is after "Firefox"
  else if (nAgt.indexOf("Firefox") !== -1) {
    return "Firefox";
  }
  // In most other browsers, "name/version" is at the end of userAgent
  else {
    return navigator.appName;
  }
};
