import React from 'react'
import {
   Checkbox,
   Dropdown,
   Input,
} from 'semantic-ui-react'



import styles from './CardPTPhysicalexaminationNeuroogialOPDTOP.module.css'

export interface CardPTPhysicalexaminationNeuroogialOPDTOPViewDataType {
    Ct4Observation: string,
    Ct4ObservationDisabled: boolean,
    Ct4ObservationOptions: any,
    PtPhysicalNeuroOpdTop5: string,
    PtPhysicalNeuroOpdTop5Disabled: boolean,
    PtPhysicalNeuroOpdTop7: string,
    PtPhysicalNeuroOpdTop7Disabled: boolean,
    Ct9GeneralAppearance: string,
    Ct9GeneralAppearanceDisabled: boolean,
    Ct9GeneralAppearanceOptions: any,
    Ct11Consciousness: string,
    Ct11ConsciousnessDisabled: boolean,
    Ct11ConsciousnessOptions: any,
    Ct14Communication: string,
    Ct14CommunicationDisabled: boolean,
    Ct14CommunicationOptions: any,
    Ct14CoOperation: string,
    Ct14CoOperationDisabled: boolean,
    Ct14CoOperationOptions: any,
    PtPhysicalNeuroOpdTop24: boolean,
    PtPhysicalNeuroOpdTop24Disabled: boolean,
    Ct2BedMobilityTestRolling: string,
    Ct2BedMobilityTestRollingDisabled: boolean,
    Ct2BedMobilityTestRollingOptions: any,
    PtPhysicalNeuroOpdTop28: string,
    PtPhysicalNeuroOpdTop28Disabled: boolean,
    PtPhysicalNeuroOpdTop28Options: any,
    PtPhysicalNeuroOpdTop34: string,
    PtPhysicalNeuroOpdTop34Disabled: boolean,
    PtPhysicalNeuroOpdTop34Options: any,
    PtPhysicalNeuroOpdTop36: string,
    PtPhysicalNeuroOpdTop36Disabled: boolean,
    PtPhysicalNeuroOpdTop36Options: any,
    PtPhysicalNeuroOpdTop40: string,
    PtPhysicalNeuroOpdTop40Disabled: boolean,
    PtPhysicalNeuroOpdTop40Options: any,
    PtPhysicalNeuroOpdTop42: string,
    PtPhysicalNeuroOpdTop42Disabled: boolean,
    PtPhysicalNeuroOpdTop42Options: any,
    PtPhysicalNeuroOpdTop46: string,
    PtPhysicalNeuroOpdTop46Disabled: boolean,
    PtPhysicalNeuroOpdTop47: string,
    PtPhysicalNeuroOpdTop47Disabled: boolean,
    PtPhysicalNeuroOpdTop48: string,
    PtPhysicalNeuroOpdTop48Disabled: boolean,
    PtPhysicalNeuroOpdTop49: string,
    PtPhysicalNeuroOpdTop49Disabled: boolean,
    PtPhysicalNeuroOpdTop50: boolean,
    PtPhysicalNeuroOpdTop50Disabled: boolean,
    PtPhysicalNeuroOpdTop52: boolean,
    PtPhysicalNeuroOpdTop52Disabled: boolean,
    PtPhysicalNeuroOpdTop54: boolean,
    PtPhysicalNeuroOpdTop54Disabled: boolean,
    PtPhysicalNeuroOpdTop56: boolean,
    PtPhysicalNeuroOpdTop56Disabled: boolean,
    PtPhysicalNeuroOpdTop58: boolean,
    PtPhysicalNeuroOpdTop58Disabled: boolean,
}

export const CardPTPhysicalexaminationNeuroogialOPDTOPInitialViewData: CardPTPhysicalexaminationNeuroogialOPDTOPViewDataType = {
    Ct4Observation: "",
    Ct4ObservationDisabled: false,
    Ct4ObservationOptions: [{key: 0, value: "A", text: "A"}],
    PtPhysicalNeuroOpdTop5: "",
    PtPhysicalNeuroOpdTop5Disabled: false,
    PtPhysicalNeuroOpdTop7: "",
    PtPhysicalNeuroOpdTop7Disabled: false,
    Ct9GeneralAppearance: "",
    Ct9GeneralAppearanceDisabled: false,
    Ct9GeneralAppearanceOptions: [{key: 0, value: "A", text: "A"}],
    Ct11Consciousness: "",
    Ct11ConsciousnessDisabled: false,
    Ct11ConsciousnessOptions: [{key: 0, value: "A", text: "A"}],
    Ct14Communication: "",
    Ct14CommunicationDisabled: false,
    Ct14CommunicationOptions: [{key: 0, value: "A", text: "A"}],
    Ct14CoOperation: "",
    Ct14CoOperationDisabled: false,
    Ct14CoOperationOptions: [{key: 0, value: "A", text: "A"}],
    PtPhysicalNeuroOpdTop24: false,
    PtPhysicalNeuroOpdTop24Disabled: false,
    Ct2BedMobilityTestRolling: "",
    Ct2BedMobilityTestRollingDisabled: false,
    Ct2BedMobilityTestRollingOptions: [{key: 0, value: "A", text: "A"}],
    PtPhysicalNeuroOpdTop28: "",
    PtPhysicalNeuroOpdTop28Disabled: false,
    PtPhysicalNeuroOpdTop28Options: [{key: 0, value: "A", text: "A"}],
    PtPhysicalNeuroOpdTop34: "",
    PtPhysicalNeuroOpdTop34Disabled: false,
    PtPhysicalNeuroOpdTop34Options: [{key: 0, value: "A", text: "A"}],
    PtPhysicalNeuroOpdTop36: "",
    PtPhysicalNeuroOpdTop36Disabled: false,
    PtPhysicalNeuroOpdTop36Options: [{key: 0, value: "A", text: "A"}],
    PtPhysicalNeuroOpdTop40: "",
    PtPhysicalNeuroOpdTop40Disabled: false,
    PtPhysicalNeuroOpdTop40Options: [{key: 0, value: "A", text: "A"}],
    PtPhysicalNeuroOpdTop42: "",
    PtPhysicalNeuroOpdTop42Disabled: false,
    PtPhysicalNeuroOpdTop42Options: [{key: 0, value: "A", text: "A"}],
    PtPhysicalNeuroOpdTop46: "",
    PtPhysicalNeuroOpdTop46Disabled: false,
    PtPhysicalNeuroOpdTop47: "",
    PtPhysicalNeuroOpdTop47Disabled: false,
    PtPhysicalNeuroOpdTop48: "",
    PtPhysicalNeuroOpdTop48Disabled: false,
    PtPhysicalNeuroOpdTop49: "",
    PtPhysicalNeuroOpdTop49Disabled: false,
    PtPhysicalNeuroOpdTop50: false,
    PtPhysicalNeuroOpdTop50Disabled: false,
    PtPhysicalNeuroOpdTop52: false,
    PtPhysicalNeuroOpdTop52Disabled: false,
    PtPhysicalNeuroOpdTop54: false,
    PtPhysicalNeuroOpdTop54Disabled: false,
    PtPhysicalNeuroOpdTop56: false,
    PtPhysicalNeuroOpdTop56Disabled: false,
    PtPhysicalNeuroOpdTop58: false,
    PtPhysicalNeuroOpdTop58Disabled: false,
}

const Sub = (props: any) => {
    const children = React.Children.toArray(props.children)
    return (
        <div className={styles.container}>
            <div className={styles.PtPhysicalNeuroOpdTop1}>
                <div
                    style={{background: "#F3F3F3", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.Ct4Observation}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct4Observation}
                    disabled={ props.viewData.Ct4ObservationDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardPTPhysicalexaminationNeuroogialOPDTOP",
                                name: "Ct4Observation",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct4ObservationOptions}
                />
            </div>
            <div className={styles.PtPhysicalNeuroOpdTop5}>
                <Input
                    value={props.viewData.PtPhysicalNeuroOpdTop5}
                    disabled={ props.viewData.PtPhysicalNeuroOpdTop5Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPTPhysicalexaminationNeuroogialOPDTOP',
                            name: 'PtPhysicalNeuroOpdTop5',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtPhysicalNeuroOpdTop6}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Observation</strong></p>
                </div>
            </div>
            <div className={styles.PtPhysicalNeuroOpdTop7}>
                <Input
                    value={props.viewData.PtPhysicalNeuroOpdTop7}
                    disabled={ props.viewData.PtPhysicalNeuroOpdTop7Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPTPhysicalexaminationNeuroogialOPDTOP',
                            name: 'PtPhysicalNeuroOpdTop7',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.Ct9GeneralAppearance}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct9GeneralAppearance}
                    disabled={ props.viewData.Ct9GeneralAppearanceDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardPTPhysicalexaminationNeuroogialOPDTOP",
                                name: "Ct9GeneralAppearance",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct9GeneralAppearanceOptions}
                />
            </div>
            <div className={styles.PtPhysicalNeuroOpdTop11}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>General appearance</strong></p>
                </div>
            </div>
            <div className={styles.Ct11Consciousness}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct11Consciousness}
                    disabled={ props.viewData.Ct11ConsciousnessDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardPTPhysicalexaminationNeuroogialOPDTOP",
                                name: "Ct11Consciousness",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct11ConsciousnessOptions}
                />
            </div>
            <div className={styles.Ct14Communication}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct14Communication}
                    disabled={ props.viewData.Ct14CommunicationDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardPTPhysicalexaminationNeuroogialOPDTOP",
                                name: "Ct14Communication",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct14CommunicationOptions}
                />
            </div>
            <div className={styles.Ct14CoOperation}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct14CoOperation}
                    disabled={ props.viewData.Ct14CoOperationDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardPTPhysicalexaminationNeuroogialOPDTOP",
                                name: "Ct14CoOperation",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct14CoOperationOptions}
                />
            </div>
            <div className={styles.PtPhysicalNeuroOpdTop21}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Consciousness</strong></p>
                </div>
            </div>
            <div className={styles.PtPhysicalNeuroOpdTop22}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Communication</strong></p>
                </div>
            </div>
            <div className={styles.PtPhysicalNeuroOpdTop23}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Co-operation</strong></p>
                </div>
            </div>
            <div className={styles.PtPhysicalNeuroOpdTop24}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtPhysicalNeuroOpdTop24}
                    disabled={ props.viewData.PtPhysicalNeuroOpdTop24Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTPhysicalexaminationNeuroogialOPDTOP",
                                name: "PtPhysicalNeuroOpdTop24"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.Ct2BedMobilityTestRolling}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct2BedMobilityTestRolling}
                    disabled={ props.viewData.Ct2BedMobilityTestRollingDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardPTPhysicalexaminationNeuroogialOPDTOP",
                                name: "Ct2BedMobilityTestRolling",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct2BedMobilityTestRollingOptions}
                />
            </div>
            <div className={styles.PtPhysicalNeuroOpdTop28}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.PtPhysicalNeuroOpdTop28}
                    disabled={ props.viewData.PtPhysicalNeuroOpdTop28Disabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardPTPhysicalexaminationNeuroogialOPDTOP",
                                name: "PtPhysicalNeuroOpdTop28",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.PtPhysicalNeuroOpdTop28Options}
                />
            </div>
            <div className={styles.PtPhysicalNeuroOpdTop31}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Functional test</strong></p>
                </div>
            </div>
            <div className={styles.PtPhysicalNeuroOpdTop32}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>independent</p>
                </div>
            </div>
            <div className={styles.PtPhysicalNeuroOpdTop33}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Rolling</p>
                </div>
            </div>
            <div className={styles.PtPhysicalNeuroOpdTop34}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.PtPhysicalNeuroOpdTop34}
                    disabled={ props.viewData.PtPhysicalNeuroOpdTop34Disabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardPTPhysicalexaminationNeuroogialOPDTOP",
                                name: "PtPhysicalNeuroOpdTop34",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.PtPhysicalNeuroOpdTop34Options}
                />
            </div>
            <div className={styles.PtPhysicalNeuroOpdTop36}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.PtPhysicalNeuroOpdTop36}
                    disabled={ props.viewData.PtPhysicalNeuroOpdTop36Disabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardPTPhysicalexaminationNeuroogialOPDTOP",
                                name: "PtPhysicalNeuroOpdTop36",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.PtPhysicalNeuroOpdTop36Options}
                />
            </div>
            <div className={styles.PtPhysicalNeuroOpdTop39}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Supine to sit</p>
                </div>
            </div>
            <div className={styles.PtPhysicalNeuroOpdTop40}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.PtPhysicalNeuroOpdTop40}
                    disabled={ props.viewData.PtPhysicalNeuroOpdTop40Disabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardPTPhysicalexaminationNeuroogialOPDTOP",
                                name: "PtPhysicalNeuroOpdTop40",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.PtPhysicalNeuroOpdTop40Options}
                />
            </div>
            <div className={styles.PtPhysicalNeuroOpdTop42}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.PtPhysicalNeuroOpdTop42}
                    disabled={ props.viewData.PtPhysicalNeuroOpdTop42Disabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardPTPhysicalexaminationNeuroogialOPDTOP",
                                name: "PtPhysicalNeuroOpdTop42",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.PtPhysicalNeuroOpdTop42Options}
                />
            </div>
            <div className={styles.PtPhysicalNeuroOpdTop45}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Sit to stand</p>
                </div>
            </div>
            <div className={styles.PtPhysicalNeuroOpdTop46}>
                <Input
                    value={props.viewData.PtPhysicalNeuroOpdTop46}
                    disabled={ props.viewData.PtPhysicalNeuroOpdTop46Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPTPhysicalexaminationNeuroogialOPDTOP',
                            name: 'PtPhysicalNeuroOpdTop46',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtPhysicalNeuroOpdTop47}>
                <Input
                    value={props.viewData.PtPhysicalNeuroOpdTop47}
                    disabled={ props.viewData.PtPhysicalNeuroOpdTop47Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPTPhysicalexaminationNeuroogialOPDTOP',
                            name: 'PtPhysicalNeuroOpdTop47',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtPhysicalNeuroOpdTop48}>
                <Input
                    value={props.viewData.PtPhysicalNeuroOpdTop48}
                    disabled={ props.viewData.PtPhysicalNeuroOpdTop48Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPTPhysicalexaminationNeuroogialOPDTOP',
                            name: 'PtPhysicalNeuroOpdTop48',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtPhysicalNeuroOpdTop49}>
                <Input
                    value={props.viewData.PtPhysicalNeuroOpdTop49}
                    disabled={ props.viewData.PtPhysicalNeuroOpdTop49Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPTPhysicalexaminationNeuroogialOPDTOP',
                            name: 'PtPhysicalNeuroOpdTop49',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtPhysicalNeuroOpdTop50}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtPhysicalNeuroOpdTop50}
                    disabled={ props.viewData.PtPhysicalNeuroOpdTop50Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTPhysicalexaminationNeuroogialOPDTOP",
                                name: "PtPhysicalNeuroOpdTop50"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtPhysicalNeuroOpdTop51}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Muscle length</strong></p>
                </div>
            </div>
            <div className={styles.PtPhysicalNeuroOpdTop52}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtPhysicalNeuroOpdTop52}
                    disabled={ props.viewData.PtPhysicalNeuroOpdTop52Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTPhysicalexaminationNeuroogialOPDTOP",
                                name: "PtPhysicalNeuroOpdTop52"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtPhysicalNeuroOpdTop53}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Normal</p>
                </div>
            </div>
            <div className={styles.PtPhysicalNeuroOpdTop54}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtPhysicalNeuroOpdTop54}
                    disabled={ props.viewData.PtPhysicalNeuroOpdTop54Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTPhysicalexaminationNeuroogialOPDTOP",
                                name: "PtPhysicalNeuroOpdTop54"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtPhysicalNeuroOpdTop55}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Tightness</p>
                </div>
            </div>
            <div className={styles.PtPhysicalNeuroOpdTop56}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtPhysicalNeuroOpdTop56}
                    disabled={ props.viewData.PtPhysicalNeuroOpdTop56Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTPhysicalexaminationNeuroogialOPDTOP",
                                name: "PtPhysicalNeuroOpdTop56"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtPhysicalNeuroOpdTop57}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Shortening</p>
                </div>
            </div>
            <div className={styles.PtPhysicalNeuroOpdTop58}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtPhysicalNeuroOpdTop58}
                    disabled={ props.viewData.PtPhysicalNeuroOpdTop58Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTPhysicalexaminationNeuroogialOPDTOP",
                                name: "PtPhysicalNeuroOpdTop58"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtPhysicalNeuroOpdTop59}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Contracture</p>
                </div>
            </div>
            <div className={styles.PtPhysicalNeuroOpdTop60}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>N/A</p>
                </div>
            </div>
        </div>
    )
}

export default Sub

const getTsName = (cssClass: string) => {
    return cssClass.split('-').map((s) => (
        s.charAt(0).toUpperCase() + s.slice(1)
    )).join('')
}
