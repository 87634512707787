import React, { useEffect, useRef, useState, useMemo } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import CardLayout from "../../common/CardLayout";
import ModAuthen from "../../common/ModAuthen";
import { prepareForm, QuestionSection, updateThread } from "../Common/Diag";
import PureReactTable from "../../../component-js/common/PureReactTable";
import {
  Button,
  Grid,
  Segment,
  Loader,
  Dimmer,
  Icon,
  SemanticCOLORS,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { formatDatetime } from "../../../utils/dateUtils";
import { createPDF } from "../../../utils/createPDF";
import { conFormHomeIsolation } from "../../../utils/conFormHomeIsolation";
import { convertToBEDate } from "../../../utils/tsUtils";
import config from "../../../../config/config";
import ReactTable from "react-table-6";
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import ModInfo from "react-lib/apps/common/ModInfo";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm"
import { conFormHospitelIRCovid } from "../../../utils/conFomHospitelIRCovid";
import { useIntl } from "react-intl";

const blankSpace20 =
  "\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0";
const blankSpace15 =
  "\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0";

export type ModInfoType = {
  open: boolean;
  error?: any;
  title?: string;
  color?: SemanticCOLORS;
  loading?: boolean;
};

export const modInfoInitial: ModInfoType = {
  open: false,
  error: null,
  title: "",
  color: undefined,
  loading: false,
};

const CardDiagFormHistoryAirFlow = (props) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [diagFormLoading, setDiagFormLoading] = useState(false);
  const [diagForm, setDiagForm] = useState<any[]>([]);
  const [selectDiagForm, setSelectDiagForm] = useState(0);
  const [openModInfo, setOpenModInfo] = useState<ModInfoType>(modInfoInitial);
  const [openModMonitor, setOpenModMonitor] = useState<ModInfoType>(
    modInfoInitial
  );
  const [publishDiagRule, setPublishDiagRule] = useState(null);
  const [classifyRule, setClassifyRule] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [isToday, setIsToday] = useState(false);
  const [thread, setThread] = useState([]);
  const [masterTriageLevel, setMasterTriageLevel] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);
  const limit = 5;

  const isMounted = useRef(true);
  const isMountedDiagForm = useRef(false);

  useEffect(() => {
    // getDiagForm();
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!isMountedDiagForm.current) {
      return;
    }
    if (page === 1) {
      getDiagForm();
    } else {
      setPage(1);
    }
  }, [isToday]);

  useEffect(() => {
    const getTriageLevelClassify = async () => {
      const [
        response,
        error,
        network,
      ] = await props.controller.getTriageLevelClassify({
        apiToken: Cookies.get("apiToken") ? Cookies.get("apiToken") : props.apiToken,
        divisionId: props.division ? props.division : Cookies.get("division_id"),
      });

      if (isMounted.current) {
        if (response) {
          setMasterTriageLevel(response);
        }
      }
    };

    if (props.division || Cookies.get("division_id")) {
      getTriageLevelClassify();
    }
  }, [props.division, Cookies.get("division_id")]);

  const getDiagFormClassify = async () => {
    let patientId = null;
    if (props.patientData && props.patientData.patient) {
      patientId = props.patientData.patient;
    } else if (
      props.match &&
      props.match.params &&
      props.match.params.patientId
    ) {
      patientId = props.match.params.patientId;
    }
    if (!patientId) {
      return;
    }
    let [response, error, network] = await props.controller.getDiagFormClassify(
      {
        apiToken: Cookies.get("apiToken") ? Cookies.get("apiToken") : props.apiToken,
        patient: patientId,
      }
    );
    if (isMounted.current) {
      if (response) {
        if (response.items.length > 0) {
          let dataClassify = [];
          response.items.forEach((item) => {
            let dataDict = {};
            dataDict.diag_rule = item.id;
            dataDict.classify = JSON.parse(item.classify);
            dataClassify.push(dataDict);
          });
          setClassifyRule(dataClassify);
        }
      }
    }
  };

  const getDiagForm = async () => {
    setDiagForm([]);
    setThread([]);

    let patientId = null;
    if (props.patientData && props.patientData.patient) {
      patientId = props.patientData.patient;
    } else if (
      props.match &&
      props.match.params &&
      props.match.params.patientId
    ) {
      patientId = props.match.params.patientId;
    }
    if (!patientId) {
      return;
    }
    setIsLoading(true);
    let today = isToday ? "true" : "";
    let [response, error, network] = await props.controller.getDiagForm({
      apiToken: Cookies.get("apiToken") ? Cookies.get("apiToken") : props.apiToken,
      patient: patientId,
      division: props.division ? props.division : Cookies.get("division_id"),
      today: today,
      limit,
      offset: limit * (page - 1),
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (response) {
        if (props.maxDiagFormId) {
          response.items = response.items?.filter(
            (item) => item.id <= props.maxDiagFormId
          );
        }
        setTotalPage(Math.ceil(response.total / limit));
        // setTotalDiagForm(response.total)
        setDiagForm(response.items);
      }
    }
  };

  useEffect(() => {
    if (Object.keys(diagForm).length > 0) {
      getPublish(selectDiagForm);
    }
  }, [diagForm]);

  useEffect(() => {
    if (Object.keys(diagForm).length > 0) {
      getPublish(selectDiagForm);
    }

    // Perpare Triage Level
    let prepareTriage =
      diagForm &&
      Object.keys(diagForm).length > 0 &&
      masterTriageLevel &&
      masterTriageLevel.classify &&
      masterTriageLevel.classify.length > 0 &&
      masterTriageLevel.classify.find((triage) => {
        return triage.triage_level == diagForm[selectDiagForm].triage_level;
      });
    prepareTriage = prepareTriage ? prepareTriage : null;
    // setSelectedTriageLevel(prepareTriage);
  }, [selectDiagForm]);

  const getPublish = async (selectDiagForm, isReturn = false) => {
    setDiagFormLoading(true);
    let answerForm =
      diagForm && diagForm.length > selectDiagForm - 1
        ? diagForm[selectDiagForm]
        : {};
    let answer = answerForm.answers ? JSON.parse(answerForm.answers) : {};
    let diagRuleId = answerForm.published_diag_rule;

    let [
      response,
      error,
      network,
    ] = await props.controller.getPublishedDiagRule({
      apiToken: Cookies.get("apiToken") ? Cookies.get("apiToken") : props.apiToken,
      diagRuleId: diagRuleId ? diagRuleId : null,
    });

    if (response) {
      if (isMounted.current) {
        if (!isReturn) {
          setPublishDiagRule(response);
        }
        if (response) {
          let x = prepareForm(response);

          // Combine answer into Question
          for (var key in x) {
            // check if the property/key is defined in the object itself, not in parent
            if (x.hasOwnProperty(key)) {
              if (typeof answer[key] === "boolean") {
                x[key].selected = answer[key] ? true : false;
              } else {
                if (Object.keys(answer).length > 0) {
                  if (answer[key]) {
                    x[key].answer = answer[key].answer;
                    x[key].selected = answer[key].selected;
                    x[key].extra_finished = answer[key].extra_finished;
                  } else {
                    x[key].answer = "";
                    x[key].selected = "";
                    x[key].extra_finished = "";
                  }
                }

                if (
                  answer[key] &&
                  answer[key].extra_items &&
                  x[key] &&
                  x[key].extra_items
                ) {
                  x[key].extra_items.forEach((item) => {
                    let z = answer[key].extra_items.find((ans) => {
                      return ans.label == item.label;
                    });
                    if (z) {
                      item.value = z.value;
                    }
                  });
                }
              }
            }
          }

          let y = Object.values(x);
          var targetThread = [];
          targetThread = updateThread([], y, y[0]);

          if (isReturn) {
            setDiagFormLoading(false);
            return targetThread;
          }
          setThread(targetThread);
        }
      }
    }
    setDiagFormLoading(false);
  };

  useEffect(() => {
    setSelectDiagForm(0);
    getDiagFormClassify();
    getDiagForm();
    isMountedDiagForm.current = true;
  }, [
    page,
    props.patientData && props.patientData.patient,
    props.match && props.match.params && props.match.params.patientId,
  ]);

  useEffect(() => {
    let pdfPrintDiagRule = [];
    for (let [key, value] of Object.entries(config.PDF_PRINT_DIAGFORM_ID)) {
      pdfPrintDiagRule = pdfPrintDiagRule.concat(value);
    }
    let newColumns = [
      {
        Header: "วันที่ / เวลา",
        width: 100,
        accessor: "date",
        Cell: (row) => {
          if (row.original.updated) {
            let dateTime = formatDatetime(row.original.updated).split(" ");
            return (
              <div>
                <div>{dateTime[0]}</div>
                <div>{dateTime[1] + ":00"}</div>
              </div>
            );
          }
        },
      },
      {
        Header: <div style={{ minWidth: "200px" }}>{intl.formatMessage({ id: "แบบคัดกรอง" })}</div>,
        // width: 150,
        accessor: "diag_rule_name",
        rowSpan: "2",
      },
      {
        Header: <div style={{ minWidth: "50px" }}>{intl.formatMessage({ id: "แบบประเมิน" })}</div>,
        width: 130,
        accessor: "original_triage_level",
        style: {
          display: "flex",
          justifyContent: "center",
        },
        Cell: (row) => {
          if (
            row.original.original_triage_level &&
            classifyRule.length > 0
          ) {
            return (
              <div>
                {getClassifyContext(
                  row.original.published_diag_rule,
                  row.original.original_triage_level
                )}
              </div>
            );
          }
        },
      },
      {
        Header: <div style={{ minWidth: "50px" }}>{intl.formatMessage({ id: "การคำนวณ" })}</div>,
        width: 130,
        accessor: "triage_level",
        style: {
          display: "flex",
          justifyContent: "center",
        },
        Cell: (row) => {
          if (row.original.triage_level && classifyRule.length > 0) {
            return (
              <div>
                {getClassifyContext(
                  row.original.published_diag_rule,
                  row.original.triage_level
                )}
              </div>
            );
          }
        },
      },
      {
        Header: <div style={{ minWidth: "50px" }}>{intl.formatMessage({ id: "สถานะ" })}</div>,
        width: 100,
        accessor: "tracking_status",
        style: {
          display: "flex",
          justifyContent: "center",
        },
        rowSpan: "2",
        Cell: (row) => {
          if (!row.original.finished) {
            return (
              <div style={{ color: "blue", fontWeight: "bold" }}>
                ยังทำแบบคัดกรองไม่สำเร็จ
              </div>
            );
          }
          if (row.original.tracking_status === "WAITING") {
            return (
              <div style={{ color: "#EB001B", fontWeight: "bold" }}>
                ยังไม่ได้ monitor
              </div>
            );
          }
          if (row.original.tracking_status === "COMPLETED") {
            return (
              <div style={{ color: "#27AE60", fontWeight: "bold" }}>
                Monitor แล้ว
              </div>
            );
          }
        },
      },
      {
        Header: "",
        width: 100,
        accessor: "published_diag_rule",
        Cell: (row) => {
          if (pdfPrintDiagRule.includes(+row.original.diag_rule_id)) {
            return (
              <div
                style={{
                  bottom: "14px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  color="teal"
                  size="mini"
                  onClick={() => handleCreatePdf(row.index)}
                >
                  PDF
                </Button>
              </div>
            );
          }
          // style: { minWidth: "10px", width: "auto" },
        },
      },
    ];
    setTableColumns(newColumns);
  }, [diagForm]);

  const classifyRuleFlat = useMemo(() => {
    const arrayFlat = classifyRule.map((item: any) => item.classify).flat();
    const key = "triage_level";
    // @ts-ignore
    const arrayUniqueByKey = [
      ...new Map(arrayFlat.map((item) => [item[key], item])).values(),
    ];
    return arrayUniqueByKey;
  }, [classifyRule]);

  const dummyFunc = () => () => {};

  const getClassifyContext = (diag_rule: number, triage_level: string) => {
    if (classifyRule.length > 0) {
      // let diagRuleClassify =
      //   classifyRule?.find((item) => item.diag_rule === parseInt(diag_rule)) ||
      //   { };
      let showData =
        classifyRuleFlat?.find((i) => i.triage_level === triage_level) || {};
      return showData?.description || "";
    }
    return "";
  };

  const createPage = () => {
    let a = [];
    for (let i = 1; i < totalPage + 1; i++) {
      a.push(
        <Button
          style={{ margin: 5 }}
          color={page === i ? "green" : "blue"}
          compact
          content={i}
          onClick={() => setPage(i)}
          toggle={page === i}
        />
      );
    }
    return a;
  };

  const handleCreatePdf = async (index) => {
    const thread = await getPublish(index, true);
    
    const shortLang = {
      English: "EN",
      ภาษาไทย: "TH",
    };

    const genderChoice = {
      "TH":{
      0: "ชาย",
      1: "หญิง",
      2: "ไม่ระบุ",
      "M": "ชาย",
      "F": "หญิง",
      "U": "ไม่ระบุ"
      },
      "EN": {
        0: "Man",
        1: "Female",
        2: "Undetermine",
        "M": "Man",
        "F": "Female",
        "U": "Undetermine"
      }
    }

    const thaiToEng = {
      ไม่มีปัญหาเลย: "Not difficult at all",
      มีปัญหาบ้าง: "Somewhat difficult",
      มีปัญหามาก: "Very difficult",
      มีปัญหามากที่สุด: "Extremely difficult",
    };
    const patient: any = props.patientData || {};
    const patientAllergy = props.patientAllergyData || {};
    const form = diagForm[index];
    let allergy = patientAllergy?.note_dict?.allergy || "None";
    let sideEffect = patientAllergy?.note_dict?.side_effect || "";
    if (allergy.length > 50) {
      allergy = allergy.substring(0, 49) + ",...";
    }
    if (sideEffect.length > 50) {
      sideEffect = sideEffect.substring(0, 49) + ",...";
    }

    // console.log("patientData:", patient)

    if (
      config.PDF_PRINT_DIAGFORM_ID?.HOME_ISOLATION.includes(+form.diag_rule_id)
    ) {
      const language: "TH" | "EN" = shortLang[thread[1].label];
      const stateQuaranthine = thread[3]?.extra_items || [];

      let sex = ""
      if(patient.gender){
        sex = genderChoice[language][patient.gender]
      }else if(patient.extra_data.gender !== undefined && typeof(patient.extra_data.gender) === "number"){
        sex = genderChoice[language][patient.extra_data.gender]
      }


      if (language === "TH") {
        const nationality = patient.extra_data.nationality?.split(" ")?.[1]?.replace(/\(|\)/g, "") || ""
        let patientName = `${patient.first_name} ${patient.last_name}`
        if(nationality === "ไทย"){
          patientName = `${patient.pre_name} ${patient.first_name} ${patient.last_name}`
        }

        const quaranthineTime =
          formatDatetime(
            moment(form?.updated.split("T"), "YYYY-MM-DD").add(14, "days")
          ).split(" ")[0] || "";
        const generateData = {
          language,
          patientName: patientName,
          nationality: nationality,
          room: "",
          hn: patient.hn,
          physicial: "",
          visitDate: form?.created
            ? formatDatetime(form.created).split(" ")[0]
            : "",
          department: "",
          birthDate: patient.birthdate
            ? convertToBEDate({
                date: moment(patient.birthdate, [
                  "DD/MM/YYYY",
                  "YYYY-MM-DD",
                ]).format("DD/MM/YYYY"),
              })
            : "",
          age: patient.age,
          sex: sex,
          allergies: allergy,
          sideEffect: sideEffect,
          citizenNumber: patient.cid,
          // dateRTPCR: "",
          // monthRTPCR: "",
          // yearRTPCR: "",
          dateUntil: quaranthineTime?.split("/")[0] || "",
          monthUntil:
            moment(quaranthineTime?.split("/")[1], "MM")
              .locale("th")
              .format("MMMM") || "",
          yearUntil: quaranthineTime?.split("/")[2] || "",
          homeNumber:
            stateQuaranthine.length > 0 ? stateQuaranthine[0].value : "",
          villageNumber:
            stateQuaranthine.length > 0 ? stateQuaranthine[1].value : "",
          laneName:
            stateQuaranthine.length > 0 ? stateQuaranthine[2].value : "",
          street: stateQuaranthine.length > 0 ? stateQuaranthine[3].value : "",
          subDistrictName:
            stateQuaranthine.length > 0 ? stateQuaranthine[4].value : "",
          districtName:
            stateQuaranthine.length > 0 ? stateQuaranthine[5].value : "",
          provinceName:
            stateQuaranthine.length > 0 ? stateQuaranthine[6].value : "",
          postalCode:
            stateQuaranthine.length > 0 ? stateQuaranthine[7].value : "",
          phoneNumber:
            stateQuaranthine.length > 0 ? stateQuaranthine[8].value : "",
          emergencyContactName:
            stateQuaranthine.length > 0 ? stateQuaranthine[9].value : "",
          emergencyContactPhoneNumber:
            stateQuaranthine.length > 0 ? stateQuaranthine[10].value : "",
          patientSignature: `Digitally signed by ${patientName}`,
          patientSignDate: form?.updated
            ? formatDatetime(form.updated).split(" ")[0]
            : "",
          patientSignTime: form?.updated
            ? formatDatetime(form.updated).split(" ")[1]
            : "",
        };

        conFormHomeIsolation(generateData);
        return;
      }
      if (language === "EN") {
        const nationality = patient.extra_data.nationality?.split(" ")?.[0]?.replace(/\(|\)/g, "") || ""
        let patientName = `${patient.first_name_en} ${patient.last_name_en}`
        if(nationality !== "THAI"){
          patientName = `${patient.pre_name} ${patient.first_name_en} ${patient.last_name_en}`
        }

        const quaranthineTime = moment(form?.updated)
          .add(14, "days")
          .format("DD/MM/YYYY");
        const generateData = {
          language,
          patientName: patientName,
          nationality: nationality,
          room: "",
          hn: patient.hn,
          physicial: "",
          visitDate: form?.created
            ? moment(form?.created).format("DD/MM/YYYY")
            : "",
          department: "",
          birthDate: patient.birthdate
            ? moment(patient.birthdate, ["DD/MM/YYYY", "YYYY-MM-DD"]).format(
                "DD/MM/YYYY"
              )
            : "",
          age: patient.age,
          sex: sex,
          allergies: allergy,
          sideEffect: sideEffect,
          citizenNumber: patient.cid,
          // dateRTPCR: "",
          // monthRTPCR: "",
          // yearRTPCR: "",
          dateUntil: quaranthineTime?.split("/")[0] || "",
          monthUntil:
            moment(quaranthineTime?.split("/")[1], "MM")
              .locale("en")
              .format("MMMM") || "",
          yearUntil: quaranthineTime?.split("/")[2] || "",
          homeNumber:
            stateQuaranthine.length > 0 ? stateQuaranthine[0].value : "",
          villageNumber:
            stateQuaranthine.length > 0 ? stateQuaranthine[1].value : "",
          laneName:
            stateQuaranthine.length > 0 ? stateQuaranthine[2].value : "",
          street: stateQuaranthine.length > 0 ? stateQuaranthine[3].value : "",
          subDistrictName:
            stateQuaranthine.length > 0 ? stateQuaranthine[4].value : "",
          districtName:
            stateQuaranthine.length > 0 ? stateQuaranthine[5].value : "",
          provinceName:
            stateQuaranthine.length > 0 ? stateQuaranthine[6].value : "",
          postalCode:
            stateQuaranthine.length > 0 ? stateQuaranthine[7].value : "",
          phoneNumber:
            stateQuaranthine.length > 0 ? stateQuaranthine[8].value : "",
          emergencyContactName:
            stateQuaranthine.length > 0 ? stateQuaranthine[9].value : "",
          emergencyContactPhoneNumber:
            stateQuaranthine.length > 0 ? stateQuaranthine[10].value : "",
          patientSignature: `${patientName}`,
          patientSignDate: form?.updated
            ? moment(form?.updated).format("DD/MM/YYYY")
            : "",
          patientSignTime: form?.updated
            ? moment(form?.updated).format("HH:mm")
            : "",
        };
        console.log("pdfData English:", generateData);
        conFormHomeIsolation(generateData);
      }
    }
    if (config.PDF_PRINT_DIAGFORM_ID?.PHQ9.includes(+form.diag_rule_id)) {
      const score = [0, 1, 2, 3];
      // const patient: any = props.patientData || {};
      // const patientAllergy = props.patientAllergyData || {};
      const topicList: any[] = thread[1].extra_items;
      const answer: any = {};
      const sum = {
        answerMentalSum0: 0,
        answerMentalSum1: 0,
        answerMentalSum2: 0,
        answerMentalSum3: 0,
      };
      const total = {
        answerMentalTotal0: 0,
        answerMentalTotal1: 0,
        answerMentalTotal2: 0,
        answerMentalTotal3: 0,
      };
      const language: "TH" | "EN" = shortLang[thread[0].answer];
      const difficult = thread?.[2]?.node?.answer;

      for (const [index, list] of topicList.entries()) {
        const answerIndex = list.options.indexOf(list.value);
        const keySum = `answerMentalSum${answerIndex}`;

        answer[`answerMentalno${index + 1}`] = answerIndex;
        sum[keySum] += 1;
        total[`answerMentalTotal${answerIndex}`] =
          sum[keySum] * score[answerIndex];
      }

      let allergy = patientAllergy?.note_dict?.allergy || "";
      let sideEffect = patientAllergy?.note_dict?.side_effect || "";
      if (allergy.length > 50) {
        allergy = allergy.substring(0, 49) + ",...";
      }
      if (sideEffect.length > 50) {
        sideEffect = sideEffect.substring(0, 49) + ",...";
      }

      let sex = ""
      if(patient.gender){
        sex = genderChoice[language][patient.gender]
      }
      else if(patient.extra_data.gender !== undefined && typeof(patient.extra_data.gender) === "number"){
        sex = genderChoice[language][patient.extra_data.gender]
      }

      const genPdfData = {
        language, // "EN"
        patientName: `${patient.first_name} ${patient.last_name}`,
        nationality: patient.extra_data.nationality
          ?.split(" ")?.[1]
          ?.replace(/\(|\)/g, "") || "",
        room: "",
        hn: patient.hn,
        physician: "",
        visitDate: "",
        department: "",
        birthDate: patient.birthdate
          ? convertToBEDate({
              date: moment(patient.birthdate, [
                "DD/MM/YYYY",
                "YYYY-MM-DD",
              ]).format("DD/MM/YYYY"),
            })
          : "",
        age: patient.age,
        sex: sex,
        allergies: allergy,
        sideEffect: sideEffect,
        ...answer,
        answerMentalSum0: `${sum.answerMentalSum0} x 0 = ${total.answerMentalTotal0}`,
        answerMentalSum1: `${sum.answerMentalSum1} x 1 = ${total.answerMentalTotal1}`,
        answerMentalSum2: `${sum.answerMentalSum2} x 2 = ${total.answerMentalTotal2}`,
        answerMentalSum3: `${sum.answerMentalSum3} x 3 = ${total.answerMentalTotal3}`,
        answerMentalTotal:
          total.answerMentalTotal0 +
          total.answerMentalTotal1 +
          total.answerMentalTotal2 +
          total.answerMentalTotal3,
        answerDifficult: !difficult
          ? ""
          : language === "EN"
          ? difficult
          : thaiToEng[difficult],

        // unableToAccess: false,
        // consultPsychiatrist: false,
        // consultPsychologist: true,
        consultPsychologistDate: blankSpace20,
        consultPsychologistTime: blankSpace15,
        // notConsultPsy: false,
        // registeredNurse: "",
        registeredNurseDate: blankSpace20,
        registeredNurseTime: blankSpace15,
        scanBy: "",
      };
      console.log(genPdfData);
      createPDF(genPdfData);
    }

    if (
      config.PDF_PRINT_DIAGFORM_ID?.HOPITEL_IR_COVID.includes(
        +form.diag_rule_id
      )
    ) {
      const answer = {
        รับทราบ: true,
        Acknowledged: true,
        ยินยอม: true,
        ไม่ยินยอม: false,
        Consent: true,
        Refuse: false,
        "": false,
      };

      const language: "TH" | "EN" = shortLang[thread[0].answer];
      const dataAnswer = thread[1]?.extra_items;

      let sex = ""
      if(patient.gender){
        sex = genderChoice[language][patient.gender]
      }
      else if(patient.extra_data.gender !== undefined && typeof(patient.extra_data.gender) === "number"){
        sex = genderChoice[language][patient.extra_data.gender]
      }
      if (language === "TH") {
        const nationality = patient.extra_data.nationality?.split(" ")?.[1]?.replace(/\(|\)/g, "") || ""
        let patientName = `${patient.first_name} ${patient.last_name}`
        if(nationality === "ไทย"){
          patientName = `${patient.pre_name} ${patient.first_name} ${patient.last_name}`
        }

        const generateData = {
          language,
          patientName: patientName,
          nationality: nationality,
          room: "",
          hn: patient.hn,
          physicial: "",
          visitDate: form?.created
            ? formatDatetime(form.created).split(" ")[0]
            : "",
          department: "",
          birthDate: patient.birthdate
            ? convertToBEDate({
                date: moment(patient.birthdate, [
                  "DD/MM/YYYY",
                  "YYYY-MM-DD",
                ]).format("DD/MM/YYYY"),
              })
            : "",
          age: patient.age,
          sex: sex,
          allergies: allergy,
          sideEffect: sideEffect,
          citizenNumber: patient.cid,
          acknowledged1: answer[dataAnswer[0].value.trim()],
          acknowledged2: answer[dataAnswer[1].value.trim()],
          consent1: answer[dataAnswer[2].value.trim()],
          refuse1: !answer[dataAnswer[2].value.trim()],
          consent2: answer[dataAnswer[3].value.trim()],
          refuse2: !answer[dataAnswer[3].value.trim()],
          consent3: answer[dataAnswer[4].value.trim()],
          refuse3: !answer[dataAnswer[4].value.trim()],
          consent4: answer[dataAnswer[5].value.trim()],
          refuse4: !answer[dataAnswer[5].value.trim()],
          patientSignature: `Digitally signed by ${patientName}`,
          patientSignDate: form?.updated
            ? formatDatetime(form.updated).split(" ")[0]
            : "",
          patientSignTime: form?.updated
            ? formatDatetime(form.updated).split(" ")[1]
            : "",
        };
        conFormHospitelIRCovid(generateData);
      }

      if (language === "EN") {
        const nationality = patient.extra_data.nationality?.split(" ")?.[0]?.replace(/\(|\)/g, "") || ""
        let patientName = `${patient.first_name_en} ${patient.last_name_en}`
        if(nationality !== "THAI"){
          patientName = `${patient.pre_name} ${patient.first_name_en} ${patient.last_name_en}`
        }

        const generateData = {
          language,
          patientName: patientName,
          nationality: nationality,
          room: "",
          hn: patient.hn,
          physicial: "",
          visitDate: form?.created
            ? moment(form?.created).format("DD/MM/YYYY")
            : "",
          department: "",
          birthDate: patient.birthdate
            ? moment(patient.birthdate, ["DD/MM/YYYY", "YYYY-MM-DD"]).format(
                "DD/MM/YYYY"
              )
            : "",
          age: patient.age,
          sex: sex,
          allergies: allergy,
          sideEffect: sideEffect,
          citizenNumber: patient.cid,
          acknowledged1: answer[dataAnswer[0].value.trim()],
          acknowledged2: answer[dataAnswer[1].value.trim()],
          consent1: answer[dataAnswer[2].value.trim()],
          refuse1: !answer[dataAnswer[2].value.trim()],
          consent2: answer[dataAnswer[3].value.trim()],
          refuse2: !answer[dataAnswer[3].value.trim()],
          consent3: answer[dataAnswer[4].value.trim()],
          refuse3: !answer[dataAnswer[4].value.trim()],
          consent4: answer[dataAnswer[5].value.trim()],
          refuse4: !answer[dataAnswer[5].value.trim()],
          patientSignature: patientName,
          patientSignDate: form?.updated
            ? moment(form?.updated).format("DD/MM/YYYY")
            : "",
          patientSignTime: form?.updated
            ? moment(form?.updated).format("HH:mm")
            : "",
        };

        conFormHospitelIRCovid(generateData);
      }
      return;
    }
  };

  const handleConfirmMonitor = async () => {
    setOpenModMonitor({ ...openModMonitor, loading: true });
    const [res, error, networt] = await props.controller.postDiagFormMonitor({
      apiToken: Cookies.get("apiToken") ? Cookies.get("apiToken") : props.apiToken,
      patient: props.patientData?.id,
      division: props.division,
    });
    if (error) {
      setOpenModMonitor(modInfoInitial);
      setOpenModInfo({
        open: true,
        error,
        title: "เกิดข้อผิดพลาด",
      });
      return;
    } else if (res) {
      setOpenModMonitor(modInfoInitial);
      if (page !== 1) {
        setPage(1);
      } else {
        getDiagForm();
      }
      setOpenModInfo({
        open: true,
        color: "green",
        title: "บันทึกสำเร็จ",
      });
    }
  };

  console.log(classifyRule);

  return (
    <CardLayout
      titleText={
        <div style={{ display: "flex" }}>
          <div style={{ padding: "10px" }}>{intl.formatMessage({ id: "ประวัติการทำแบบคัดกรอง" })}</div>
          <div style={{ padding: "10px" }}>
            <Button
              content={intl.formatMessage({ id: "วันนี้" })}
              size="mini"
              color={isToday ? "blue" : "grey"}
              onClick={() => {
                setIsToday(true);
              }}
            />
          </div>
          <div style={{ padding: "10px" }}>
            <Button
              content={intl.formatMessage({ id: "ทั้งหมด" })}
              size="mini"
              color={!isToday ? "blue" : "grey"}
              onClick={() => {
                setIsToday(false);
              }}
            />
          </div>
        </div>
      }
      titleContent={
        <div>
          <Button
            style={{ backgroundColor: "#21ba45" }}
            onClick={() => {
              setOpenModMonitor({ ...openModMonitor, open: true });
            }}
            color="green"
            size="mini"
          >
            Monitor
          </Button>
        </div>
      }
      headerColor={props.headerColor}
      closeable={true}
      toggleable={false}
      hideHeaderIcon={props.hideHeaderIcon}
      dividerStyle={props.dividerStyle}
      loading={isLoading}
      onClose={props.hideCallback}
      defaultBackground={true}
      style={{ backgroundColor: "defaultBackground", ...props.cardStyle }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            flexGrow: 4,
            flexBasis: 0,
            height: "40vh",
            marginRight: "3px",
            paddingBottom: "10px",
          }}
        >
          {/* {diagForm && Array.isArray(diagForm) && diagForm.length > 0 && ( */}
          <>
            <ReactTable
              className="customHeaderBorder"
              style={{ height: "30vh" }}
              columns={tableColumns}
              showPagination={false}
              data={diagForm.length > 0 ? diagForm : []}
              getTrProps={(state, rowInfo) => {
                if (rowInfo && rowInfo.row) {
                  return {
                    onClick: (e) => {
                      setSelectDiagForm(rowInfo.index);
                    },
                    style: {
                      backgroundColor:
                        rowInfo.index === selectDiagForm ? "#C7F1FF" : "white",
                    },
                  };
                } else {
                  return {};
                }
              }}
              noDataText={intl.formatMessage({ id: "ไม่พบข้อมูลแบบฟอร์ม" })}
              pageSize={5}
            />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <DiagFormTablePagination
                page={page}
                total={totalPage}
                setPage={setPage}
              />
            </div>
          </>
          {/* )} */}
        </div>
        <div style={{ flexGrow: 6, flexBasis: 0, height: "40vh" }}>
          <Segment
            style={{ height: "40vh", overflow: "auto", marginRight: "3px" }}
          >
            <Dimmer active={diagFormLoading} inverted>
              <Loader>Loading</Loader>
            </Dimmer>
            <div>
              {Object.keys(diagForm).length > 0 ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ paddingRight: "40px" }}>
                    <div
                      style={{
                        color: "#828282",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      {diagForm[selectDiagForm]?.diag_rule_name || ""}
                    </div>
                    <div style={{ padding: "10px" }}>
                      {diagForm[selectDiagForm]?.updated
                        ? `${
                            formatDatetime(
                              diagForm[selectDiagForm].updated
                            ).split(" ")[0]
                          }
                  [${
                    formatDatetime(diagForm[selectDiagForm].updated).split(
                      " "
                    )[1]
                  }]`
                        : ""}
                    </div>
                  </div>

                  <div
                    style={{
                      color: "#828282",
                      fontSize: "20px",
                      fontWeight: "bold",
                      display: "flex",
                      justifyContent: "flex-end",
                      whiteSpace: "nowrap",
                      width: "max-content",
                    }}
                  >
                    {"ผลการคัดกรอง : "}
                    {getClassifyContext(
                      diagForm[selectDiagForm]?.published_diag_rule,
                      diagForm[selectDiagForm]?.original_triage_level
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
              <div style={{ marginTop: "5px" }}>
                {thread.length > 0 ? (
                  <QuestionSection
                    threadQuestion={thread}
                    selectedAnswer={dummyFunc}
                  />
                ) : null}
              </div>
            </div>
            {/* {thread.length > 0 ? (
              <QuestionSection
                threadQuestion={thread}
                selectedAnswer={dummyFunc}
              />
            ) : null} */}
            {/* {publishDiagRule ? publishDiagRule.content : null} */}
          </Segment>
        </div>
      </div>
      {/* <div style={{ textAlign: "right" }}>{createPage()}</div> */}
      {/* <Grid style={{ height: "550px", padding: "2%" }}>
      <Grid.Column width={6} style={{ overflow: 'auto', maxHeight: 200, backgroundColor: "white", border: "1px solid grey", padding: 10 }}>
       
      </Grid.Column>
      <Grid.Column width={10} style={{ backgroundColor: "white", border: "1px solid grey" }}>
        aweawewaewaqe
      </Grid.Column>



    </Grid> */}

      <ModConfirm
        openModal={openModMonitor.open}
        titleName={intl.formatMessage({ id: "ยืนยันการ Monitor" })}
        onDeny={() => {
          setOpenModMonitor(modInfoInitial);
        }}
        onApprove={handleConfirmMonitor}
        loading={openModMonitor.loading}
        content={<ErrorMessage error={openModMonitor.error} />}
      />

      <ModInfo
        open={openModInfo.open}
        titleColor={openModInfo.color || "red"}
        titleName={openModInfo.title}
        onApprove={() => {
          setOpenModInfo(modInfoInitial);
        }}
        onClose={() => {
          setOpenModInfo(modInfoInitial);
        }}
      >
        {openModInfo.error === "string" ? (
          <div>{openModInfo.error}</div>
        ) : (
          <ErrorMessage error={openModInfo.error} />
        )}
      </ModInfo>
    </CardLayout>
  );
};

const DiagFormTablePagination = (props) => {
  const [visiblePages, setVisiblePages] = useState([]);

  useEffect(() => {
    handleVisiblePageSet();
  }, [props.page, props.total]);

  const handleVisiblePageSet = () => {
    if (props.total < 7) {
      setVisiblePages([1, 2, 3, 4, 5, 6].filter((item) => item <= props.total));
    } else {
      if (
        props.page % 5 >= 0 &&
        props.page > 4 &&
        props.page + 2 < props.total
      ) {
        setVisiblePages([
          1,
          props.page - 1,
          props.page,
          props.page + 1,
          props.total,
        ]);
      } else if (
        props.page % 5 >= 0 &&
        props.page > 4 &&
        props.page + 2 >= props.total
      ) {
        setVisiblePages([
          1,
          props.total - 3,
          props.total - 2,
          props.total - 1,
          props.total,
        ]);
      } else {
        setVisiblePages([1, 2, 3, 4, 5, props.total]);
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "10px",
      }}
    >
      <Icon
        name="caret left"
        size="big"
        color={props.page === 1 ? "grey" : "blue"}
        onClick={() => {
          props.page > 1
            ? props.setPage(props.page - 1)
            : console.log("No setPage");
        }}
      />
      {visiblePages.map((page, index, array) => {
        return (
          <div key={page} style={{ display: "flex", alignItems: "flex-end" }}>
            {array[index - 1] + 2 < page ? "..." : ""}
            <div
              style={{
                color: props.page === page ? "white" : "#333333",
                backgroundColor: props.page === page ? "#0072bc" : "",
                fontSize: "16px",
                fontWeight: "bold",
                padding: "10px",
                textAlign: "center",
                borderRadius: "20px",
                height: "40px",
                width: "40px",
              }}
              onClick={() => {
                props.page !== page
                  ? props.setPage(page)
                  : console.log("No setPage");
              }}
            >
              {page}
            </div>
          </div>
        );
      })}
      <Icon
        name="caret right"
        size="big"
        color={props.page < props.total ? "blue" : "grey"}
        onClick={() => {
          props.page < props.total
            ? props.setPage(props.page + 1)
            : console.log("No setPage");
        }}
      />
    </div>
  );
};

CardDiagFormHistoryAirFlow.defaultProps = {
  hideCallback: () => {},
  controller: null,
  hideHeaderIcon: false,
  headerColor: "red",
  dividerStyle: {},
  cardStyle: {},
  patientAllergyData: {},
  maxDiagFormId: null,
};

CardDiagFormHistoryAirFlow.propTypes = {
  hideCallback: PropTypes.func,
  controller: PropTypes.object,
  hideHeaderIcon: PropTypes.bool,
  headerColor: PropTypes.string,
  dividerStyle: PropTypes.object,
  cardStyle: PropTypes.object,
  patientAllergyData: PropTypes.object,
  maxDiagFormId: PropTypes.number,
};

export default React.memo(CardDiagFormHistoryAirFlow);
