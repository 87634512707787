import moment from 'moment';
import CONFIG from "config/config";

export const serial_increment = CONFIG.SERIAL_INCREMENTAL_KALA || 5;
export const DATE_FORMAT = "YYYY-MM-DD";
export const dayNames = ["จันทร์", "อังคาร", "พุธ", "พฤหัส", "ศุกร์", "เสาร์", "อาทิตย์"];
export const shortDayNames = ["จ", "อ", "พ", "พฤ", "ศ", "ส", "อา"];
export const engDayNames = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

export const serialToDate = (serial: number) => new Date(
  1483203600000 + (serial + 7*60/serial_increment)*(serial_increment)*60*1000
)

export const timeSlotList = 
  (start_serial: number, end_serial: number, gap: number, stringValue: boolean = true) => {
    return stringValue ? 
      Array.from({length: (end_serial - start_serial) / gap + 1})
        .map((v: any, i: number) => 
          moment(serialToDate(start_serial))
            .clone()
            .add(gap * serial_increment * i, 'm')
            .format("HH:mm"))
        .map((s: string, index: number) => 
          ({key: index, value: s, text: s}))
      :
      Array.from({length: (end_serial - start_serial) / gap + 1})
        .map((v: any, i: number) => ({
          key: i,
          value: start_serial + gap * i,
          text: moment(serialToDate(start_serial))
                .clone()
                .add(gap * serial_increment * i, 'm')
                .format("HH:mm")
        }))
  };