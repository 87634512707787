import React, {
  useEffect,
  useMemo,
  useCallback,
  useState,
  SyntheticEvent,
} from "react";
import { Button, InputProps, Menu, MenuItemProps } from "semantic-ui-react";

// UX
import CardDrugPickingOrderUX from "./CardDrugPickingOrderUX";

// Interface
import {
  ACTIONS,
  BTN_ACTS,
  CARD_PICKING_ORDER,
  DEFAULT_PICKING_ORDER,
  DrugPickingOrderSerializer,
  DrugPickingSerializer,
  FilterType,
  PRODUCT_AVAILABILITY,
  PickedProps,
  RunSequence,
  SetProp,
  State,
} from "./sequence/DrugPickingOrder";
import { ORDER_STATUS_COLORS } from "./sequence/DrugOrderAction";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import EditorColumn from "react-lib/appcon/common/EditorColumn";
import DropDownColumn from "react-lib/appcon/common/DropDownColumn";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import { useIntl } from "react-intl";

// Types
type CardPickingOrderProps = {
  onEvent: (e: any) => any;
  setProp: SetProp;
  // seq
  runSequence: RunSequence;
  DrugPickingOrderSequence: State["DrugPickingOrderSequence"];
} & PickedProps;

const CardPickingOrder = (props: CardPickingOrderProps) => {
  const intl = useIntl();
  const [modRemove, setModRemove] = useState<DrugPickingSerializer | null>(
    null
  );

  // Effect
  useEffect(() => {
    props.runSequence({
      sequence: "DrugPickingOrder",
      restart: true,
      card: CARD_PICKING_ORDER,
    });
  }, []);

  // Memo Callback
  const selectedPickingOrder = useMemo(() => {
    return props.DrugPickingOrderSequence?.selectedPickingOrder;
  }, [props.DrugPickingOrderSequence?.selectedPickingOrder]);

  // Callback
  const handleAddItem = useCallback(
    (data: DrugPickingSerializer) => () => {
      props.runSequence({
        sequence: "DrugPickingOrder",
        action: "ADD_ITEM",
        data,
      });
    },
    []
  );

  const handleRemoveItem = useCallback(
    (data: DrugPickingSerializer) => () => {
      setModRemove(data);
    },
    []
  );

  const handleChangeData = useCallback(
    (key: keyof DrugPickingSerializer, index: number) =>
      (value: string | number) => {
        let items = selectedPickingOrder?.items || [];

        items = items.map((item, idx) =>
          index === idx ? { ...item, [key]: value } : item
        );

        props.setProp(`DrugPickingOrderSequence.selectedPickingOrder`, {
          ...selectedPickingOrder,
          items,
        });
      },
    [selectedPickingOrder]
  );

  // Memo
  const tabMenu = useMemo(() => {
    const list = props.DrugPickingOrderSequence?.pickingOrderList || [];

    return [
      { text: "NEW", order: DEFAULT_PICKING_ORDER },
      ...list.map((item) => ({
        text: item.time_stamp || "",
        order: item,
      })),
    ];
  }, [props.DrugPickingOrderSequence?.pickingOrderList]);

  const statusColor = useMemo(() => {
    return selectedPickingOrder?.status_label
      ? ORDER_STATUS_COLORS[selectedPickingOrder?.status_label]
      : "";
  }, [selectedPickingOrder]);

  const readOnlyOrder = useMemo(() => {
    return (
      selectedPickingOrder?.status_label &&
      selectedPickingOrder?.status_label !== "PICKED"
    );
  }, [selectedPickingOrder]);

  const hideButtonSave = useMemo(() => {
    return !!selectedPickingOrder?.is_admin || readOnlyOrder;
  }, [selectedPickingOrder]);

  const pickingList = useMemo(() => {
    const list = props.DrugPickingOrderSequence?.pickingList || [];
    const items = selectedPickingOrder?.items || [];

    return list.map((item) => {
      const unavailableMsg =
        item.active_flag !== "ACTIVE"
          ? PRODUCT_AVAILABILITY[item.active_flag]
          : "";

      const disabledAdd =
        !!items.find((acc) => item.id === acc.product) || !!unavailableMsg;

      return {
        ...item,
        _add: (
          <div style={{ display: "grid", placeContent: "center" }}>
            <Button
              icon="plus"
              color="green"
              size="mini"
              disabled={disabledAdd}
              style={{ transform: "scale(0.85)" }}
              onClick={handleAddItem(item)}
            />
          </div>
        ),
        generic_name: (
          <>
            {item.generic_name}{" "}
            {unavailableMsg ? (
              <span style={{ color: "red" }}>{`(${unavailableMsg})`}</span>
            ) : (
              ""
            )}
          </>
        ),
      };
    });
  }, [props.DrugPickingOrderSequence?.pickingList, selectedPickingOrder]);

  const drugPickingOrderItems = useMemo(() => {
    const items = selectedPickingOrder?.items || [];

    return items.map((item, index) => {
      return {
        ...item,
        dose: (
          <EditorColumn
            value={item.dose ?? ""}
            disabled={readOnlyOrder}
            selectOnFocus={true}
            valueOnFocus={true}
            backgroundColor={readOnlyOrder ? "" : "rgb(255, 255, 204)"}
            onDataChanged={handleChangeData("dose", index)}
          />
        ),
        code_unit: (
          <DropDownColumn
            showText={item.code_unit}
            options={item.unitOptions}
            value={item.unit}
            readOnly={readOnlyOrder}
            backgroundColor={readOnlyOrder ? "" : null}
            onDataChanged={handleChangeData("unit", index)}
          />
        ),
        code_route: (
          <DropDownColumn
            showText={item.code_route}
            options={item.routeOptions}
            value={item.route}
            readOnly={readOnlyOrder}
            backgroundColor={readOnlyOrder ? "" : null}
            onDataChanged={handleChangeData("route", index)}
          />
        ),
        code_site: (
          <DropDownColumn
            showText={item.code_site}
            options={item.siteOptions}
            value={item.site}
            readOnly={readOnlyOrder}
            backgroundColor={readOnlyOrder ? "" : null}
            onDataChanged={handleChangeData("site", index)}
          />
        ),
        code_method: (
          <DropDownColumn
            showText={item.code_method}
            options={item.methodOptions}
            value={item.method}
            readOnly={readOnlyOrder}
            backgroundColor={readOnlyOrder ? "" : null}
            onDataChanged={handleChangeData("method", index)}
          />
        ),
        quantity: (
          <EditorColumn
            value={item.quantity ?? ""}
            inputNumber={true}
            disabled={readOnlyOrder}
            selectOnFocus={true}
            valueOnFocus={true}
            backgroundColor={readOnlyOrder ? "" : "rgb(255, 255, 204)"}
            onDataChanged={handleChangeData("quantity", index)}
          />
        ),
        _remove: (
          <div style={{ display: "grid", placeContent: "center" }}>
            {!readOnlyOrder && (
              <Button
                icon="minus"
                color="red"
                size="mini"
                style={{ transform: "scale(0.85)" }}
                onClick={handleRemoveItem(item)}
              />
            )}
          </div>
        ),
      };
    });
  }, [selectedPickingOrder, readOnlyOrder]);

  // Handler
  const handleSearch = () => {
    props.runSequence({
      sequence: "DrugPickingOrder",
      action: ACTIONS.SEARCH,
      card: CARD_PICKING_ORDER,
    });
  };

  const handleChangeFilter = (
    e: SyntheticEvent,
    data: InputProps & { name: keyof FilterType }
  ) => {
    props.setProp(`DrugPickingOrderSequence.filter.${data.name}`, data.value);
  };

  const handleChangeTab =
    (menu: { text: string; order: DrugPickingOrderSerializer }) =>
    (e: SyntheticEvent, data: MenuItemProps) => {
      props.runSequence({
        sequence: "DrugPickingOrder",
        action: ACTIONS.SELECT_ORDER,
        data: menu.order,
      });
    };

  const handleSave = () => {
    props.runSequence({
      sequence: "DrugPickingOrder",
      action: ACTIONS.SAVE,
      card: CARD_PICKING_ORDER,
    });
  };

  const handleCloseModRemove = () => {
    setModRemove(null);
  };

  const handleConfirmRemove = () => {
    if (modRemove) {
      props.runSequence({
        sequence: "DrugPickingOrder",
        action: ACTIONS.REMOVE_ITEM,
        data: modRemove,
      });

      handleCloseModRemove();
    }
  };

  console.log("CardPickingOrder", props);
  return (
    <div>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${BTN_ACTS.SAVE}`, null);
        }}
        error={props.errorMessage?.[BTN_ACTS.SAVE]}
        success={null}
        languageUX={props.languageUX}
      />

      <CardDrugPickingOrderUX
        // data
        drugPickingData={pickingList}
        drugPickingOrderItemsData={drugPickingOrderItems}
        filter={props.DrugPickingOrderSequence?.filter}
        selectedPickingOrder={selectedPickingOrder}
        statusColor={statusColor}
        // callback
        onChangeFilter={handleChangeFilter}
        // Element
        TabPans={
          <Menu tabular style={{ flexWrap: "wrap" }}>
            {tabMenu.map((menu, index) => (
              <Menu.Item
                key={"menu-" + menu.text}
                active={selectedPickingOrder?.id === menu.order.id}
                onClick={handleChangeTab(menu)}
              >
                {menu.text}
              </Menu.Item>
            ))}
          </Menu>
        }
        ButtonSearch={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSearch}
            // // data
            paramKey={BTN_ACTS.SEARCH}
            buttonLoadCheck={props.buttonLoadCheck?.[BTN_ACTS.SEARCH]}
            // // config
            color="blue"
            name={ACTIONS.SEARCH}
            size="small"
            title={intl.formatMessage({ id: "ค้นหา" })}
          />
        }
        ButtonSave={
          !hideButtonSave && (
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={handleSave}
              // data
              paramKey={BTN_ACTS.SAVE}
              buttonLoadCheck={props.buttonLoadCheck?.[BTN_ACTS.SAVE]}
              // config
              disabled={!selectedPickingOrder?.items?.length}
              color={selectedPickingOrder?.id ? "yellow" : "green"}
              name={ACTIONS.SAVE}
              size="medium"
              title={selectedPickingOrder?.id ? "แก้ไข" : "บันทึก"}
            />
          )
        }
        languageUX={props.languageUX}
      />

      <ModConfirm
        openModal={!!modRemove}
        titleColor="red"
        size="mini"
        denyButtonText={intl.formatMessage({ id: "ไม่" })}
        approveButtonText={intl.formatMessage({ id: "ใช่" })}
        // callback
        onDeny={handleCloseModRemove}
        onApprove={handleConfirmRemove}
        onCloseWithDimmerClick={handleCloseModRemove}
        // Element
        content={
          <div
            style={{
              margin: "0rem 1.5rem -1rem 0rem",
              textAlign: "center",
            }}
          >
            ต้องการลบรายการใช่หรือไม่
          </div>
        }
      />
    </div>
  );
};

CardPickingOrder.displayName = "CardPickingOrder";

export default React.memo(CardPickingOrder);
