import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  MutableRefObject,
  KeyboardEvent,
  SyntheticEvent,
} from "react";
import { Button, Input, Form, Icon, Modal, Divider, Checkbox, Dropdown } from "semantic-ui-react";
import CardDoctorFeeOrderAndCompensationUX from "./CardDoctorFeeOrderAndCompensationUX";
import CardServiceCompensationItemUX from "./CardServiceCompensationItemUX";
import formatComma from "react-lib/utils/formatComma";
// Common
import CardLayout from "react-lib/apps/common/CardLayout";
import EmployeeToken from "react-lib/apps/common/EmployeeToken";
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import PureReactTable from "react-lib/apps/common/PureReactTable";
import ModInfo from "react-lib/apps/common/ModInfo";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import ModLockExpense from "react-lib/apps/HISV3/BIL/ModLockExpense";

import PickerColumn from "react-lib/appcon/common/PickerColumn";
import ModAuthen from "react-lib/apps/common/ModAuthen";
import ModCancelExpense from "./ModCancelExpense";

// Utils
import { formatDatetime, formatDate } from "react-lib/utils/dateUtils";
import moment from "moment";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import { Column, RowInfo } from "react-table-6";

import CONFIG from "config/config";
import { useIntl } from "react-intl";

const ColumnStyle = {
  backgroundColor: "rgb(255, 255, 204)",
  marginTop: -7,
  marginLeft: -4,
  width: "calc(100% + 10px)",
  height: "calc(100% + 14px)",
  padding: "7px 7px",
};

const ColumnStyleWhite = {
  marginTop: -7,
  marginLeft: -4,
  width: "calc(100% + 10px)",
  height: "calc(100% + 14px)",
  padding: "7px 7px",
};

// 2566-02-28T15:48:40
const BE_FORMAT_REG = /^(\d{4})-(\d{2})-(\d{2})/g;

// "2566-02-28T15:48:40" To "2023-02-28T15:48:40";
const formatADDatetime = (datetime: string) => {
  return datetime.replace(BE_FORMAT_REG, (...res) => {
    return `${Number(res[1]) - 543}-${res[2]}-${res[3]}`;
  });
};

const CardDoctorFeeOrderAndCompensation = (props: any) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [errorMessage, setErrorMessage] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  // const [open, setOpen] = useState(false);
  const [filterTimeOut, setFilterTimeOut] = useState(null);
  const [doctorFeeOrder, setDoctorFeeOrder] = useState(true);
  const [serviceCompensation, setServiceCompensation] = useState(false);
  const [startDate, setStartDate] = useState(formatDate(moment()));
  const [endDate, setEndDate] = useState(formatDate(moment()));
  const [checkDate, setCheckDate] = useState(true);
  const [openSearch, setOpenSearch] = useState(false);
  const [selectItem, setSelectItem] = useState({});
  const [selectItemData, setSelectItemData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchCategory, setSearchCategory] = useState<any>("");
  const [selectedRow, setSelectedRow] = useState({});

  // Feature 64106
  const [openModDeleteId, setOpenModDeleteId] = useState(0); // id
  const [addService, setAddService] = useState<{ name?: string; invoiceId?: string }>({});
  const [appendOfficer, setAppendOfficer] = useState(0);
  const [appendAmount, setAppendAmount] = useState(null);

  useEffect(() => {
    props.runSequence({ sequence: "SetDoctorFee", restart: true });
    return () => {
      props.runSequence({ sequence: "SetDoctorFee", clear: true });

      props.setProp("errorMessage.SetDoctorFee", null);
      props.setProp("successMessage.SetDoctorFee", null);
    };
  }, []);

  // useEffect(() => {
  //   if (
  //     props.errorMessage?.SetDoctorFee !== null &&
  //     props.errorMessage?.SetDoctorFee !== undefined
  //   ) {
  //     setLoading(false);
  //     setTimeout(() => {
  //       setOpen(false);
  //       props.runSequence({
  //         sequence: "SetDoctorFee",
  //         action: "clear",
  //       });
  //     }, 2000);
  //   }
  //   if (
  //     props.successMessage?.SetDoctorFee !== null &&
  //     props.successMessage?.SetDoctorFee !== undefined
  //   ) {
  //     setLoading(false);
  //     setCompleted(true);
  //     setTimeout(() => {
  //       setCompleted(false);
  //     }, 2000);
  //     props.runSequence({
  //       sequence: "SetDoctorFee",
  //       action: "clear",
  //     });
  //   }
  // }, [props.successMessage?.SetDoctorFee, props.errorMessage?.SetDoctorFee]);

  useEffect(() => {
    setIsLoading(false);
  }, [props?.SetDoctorFeeSequence?.searchProductResult]);

  const orderSearchItems = useMemo(() => {
    let orderData = [
      {
        Header: "",
        accessor: "checked",
        width: 50,
        Cell: (row: any) => {
          return (
            <div style={{ display: "grid", placeContent: "center" }}>
              <Checkbox
                id={`CardDoctorFeeOrderAndCompensation-Checkbox-Selected-${row.index}`}
                checked={Object.keys(selectItem).includes(row.original.id.toString())}
                onChange={(e, { checked }) => handleCheckBoxChange(e, checked, row)}
              />
            </div>
          );
        },
      },
      {
        Header: "Service Code",
        accessor: "service_code",
        width: 100,
      },
      {
        Header: "Product Name",
        accessor: "name",
        width: 350,
      },
    ];

    if (CONFIG.ENABLE_PRICE_LEVEL) {
      orderData.push(
        {
          Header: "ราคา Normal (ระดับที่1)",
          accessor: "price_normal",
          resizable: false,
          Cell: (row) => {
            return <div style={{ textAlign: "right" }}>{formatComma(row.value)}</div>;
          },
        },
        {
          Header: "ราคา Special (ระดับที่2)",
          accessor: "price_special",
          resizable: false,
          Cell: (row) => {
            return <div style={{ textAlign: "right" }}>{formatComma(row.value)}</div>;
          },
        },
        {
          Header: "ราคา Premium (ระดับที่3)",
          accessor: "price_premium",
          resizable: false,
          Cell: (row) => {
            return <div style={{ textAlign: "right" }}>{formatComma(row.value)}</div>;
          },
        },
        {
          Header: "ราคาชาวต่างชาติ",
          accessor: "price_foreign",
          resizable: false,
          Cell: (row) => {
            return <div style={{ textAlign: "right" }}>{formatComma(row.value)}</div>;
          },
        }
      );
    } else {
      orderData.push({
        Header: "ราคา",
        accessor: "price_unit",
        resizable: false,
        Cell: (row: any) => {
          return (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {formatComma(row.value)}
            </div>
          );
        },
      });
    }

    return orderData;
  }, [props?.SetDoctorFeeSequence?.searchProductResult, selectItem]);

  const handleChangeEdit = (data: any) => {
    data.value = Number(data.value) < 0 ? "" : data.value;

    if (data.value && Number(data.value) > Number(data.price_compensation_max)) {
      setErrorMessage(
        `ขออภัยข้อมูลที่ใส่ไม่ถูกต้อง (${
          data.value
        } บาท) กรุณาระบุเป็นตัวเลขโดยมียอดไม่เกิน ${formatComma(data.price_compensation_max)} บาท`
      );
    } else {
      props.runSequence({
        sequence: "SetDoctorFee",
        action: "update",
        data,
        onFailed: (error) => {
          if (data.onFailed) {
            return data.onFailed(error);
          }

          setErrorMessage(error);
        },
        onSuccess: data.onSuccess,
      });

      // unCheck in searchProductResult
      // if (data.type === "delete") {
      //   let stateItem: any = { ...selectItem };
      //   let stateItemData: any = [];
      //   let selectPreviewItems = props?.SetDoctorFeeSequence?.dfPreviewItems[data.index];

      //   delete stateItem[selectPreviewItems?.doctor_fee_rule];
      //   let select_data = [selectItemData];
      //   select_data[0]?.map((item: any, index: any) => {
      //     if (item?.doctor_fee_rule !== selectPreviewItems?.doctor_fee_rule) {
      //       stateItemData.push(item);
      //     }
      //   });

      //   setSelectItemData(stateItemData);
      //   setSelectItem(stateItem);
      // }
    }
  };

  const dfPreviewItems = useMemo(() => {
    const employeeId = props.userProfile?.employee;
    const userId = props.userProfile?.id;

    const dfPreviewItems: any[] = props?.SetDoctorFeeSequence?.dfPreviewItems || [];

    return dfPreviewItems.map((item: any, index: number) => {
      const isEditable = employeeId === item.received_employee || userId === item.edited_by_user_id;
      // เมื่อการเงิน delete ต้องยืนยันการลบ
      const isConfirm = props.divisionType === "การเงิน";
      const isDeletable = isConfirm || isEditable;

      return {
        ...item,
        no: <div style={{ textAlign: "center" }}>{index + 1}</div>,
        price_compensation_max: (
          <div style={{ textAlign: "right" }}>{formatComma(item.compensation_price)}</div>
        ),
        price_unit: <div style={{ textAlign: "right" }}>{formatComma(item.price_unit)}</div>,
        compensation_price: (
          <EditorColumn
            value={formatComma(item.compensation_price)}
            onDataChanged={(value: string) =>
              handleChangeEdit({
                value,
                index,
                type: "compensation_price",
                price_compensation_max: item.price_compensation_max,
              })
            }
            isNoPriceUnit={item.price_unit === ""}
          />
        ),
        price_claimable: (
          <div style={{ textAlign: "end" }}>{formatComma(item.price_claimable)}</div>
        ),
        price_non_claimable: (
          <div style={{ textAlign: "end" }}>{formatComma(item.price_non_claimable)}</div>
        ),
        price_total: <div style={{ textAlign: "end" }}>{formatComma(item.price_total)}</div>,
        received_employee_display: (
          <EditorColumnWrap
            value={item.received_employee_display}
            disabled={!isEditable}
            input={
              <EmployeeToken
                onClick={(e: EventTarget) => {
                  e.stopPropagation();
                }}
                placeholder="code"
                disabled={!isEditable}
                onEnterToken={(code) => {
                  props.runSequence({
                    sequence: "SetDoctorFee",
                    action: "setTokenization",
                    params: {
                      code,
                      idx: index,
                      name: "dfPreviewItems",
                    },
                    onFailed: (error: any) => setErrorMessage(error),
                  });
                }}
                onClearToken={() => {
                  props.runSequence({
                    sequence: "SetDoctorFee",
                    action: "clearTokenization",
                    params: {
                      idx: index,
                      name: "dfPreviewItems",
                    },
                    onFailed: (error: any) => setErrorMessage(error),
                  });
                }}
                error={props?.SetDoctorFeeSequence?.dfPreviewItems?.[index]?.error}
                loading={props?.SetDoctorFeeSequence?.dfPreviewItems?.[index]?.loading}
                employeeName={props?.SetDoctorFeeSequence?.dfPreviewItems?.[index]?.employeeName}
              />
            }
          />
        ),
        // <EditorColumn
        //   value={item.received_employee_display}
        //   onDataChanged={(value: string) =>
        //     handleChangeEdit({ value, index, type: "received_employee" })
        //   }
        // />
        perform_datetime: (
          <PickerColumn
            value={
              item.perform_datetime
                ? formatDatetime(
                    item.perform_datetime.includes("Z")
                      ? item.perform_datetime
                      : formatADDatetime(item.perform_datetime)
                  )
                : ""
            }
            onDataChanged={(value: string) =>
              handleChangeEdit({ value, index, type: "perform_datetime" })
            }
          />
        ),
        menu: isDeletable && (
          <DeleteColumn
            value={item.cancelable}
            id={item.id}
            onConfirm={(data: any) =>
              handleChangeEdit({ value: item.id, index, type: "delete", ...data })
            }
            isConfirm={isConfirm && item.id}
            languageUX={props.languageUX}
            options={props.masterOptions?.doctorFeeCancelNote}
          />
        ),
      };
    });
  }, [
    props?.SetDoctorFeeSequence?.dfPreviewItems,
    props.currentDoctor,
    props.divisionType,
    props.masterOptions?.doctorFeeCancelNote,
  ]);

  const handleChangeEditSC = (data: any) => {
    props.runSequence({
      sequence: "SetDoctorFee",
      action: "UpdateSC",
      data,
      onFailed: (error: any) => setErrorMessage(error),
    });
  };

  const scPreviewItems = useMemo(() => {
    {
      /** Issue 66641
      * price_unit,
      price_compensation_max,
      compensation_price,
      quantity,
      total_compensation_price,
      price_claimable,
      price_non_claimable,
      price_total
      */
    }

    return (props?.SetDoctorFeeSequence?.scPreviewItems || []).map((item: any, index: number) => ({
      ...item,
      no: <div style={{ textAlign: "center" }}>{index + 1}</div>,
      compensation_price: (
        <EditorColumn
          id={`CardDoctorFeeOrderAndCompensation-EditorColumn-CompensationPrice-${index}`}
          style={{
            ...(props?.SetDoctorFeeSequence?.editSCIdxType?.some(
              (i: any) => i.type === "compensation_price" && i.index === index
            ) && { backgroundColor: "orange" }),
          }}
          value={formatComma(item.compensation_price)}
          onDataChanged={(value: string) =>
            handleChangeEditSC({
              value,
              index,
              type: "compensation_price",
            })
          }
          isNoPriceUnit={item.price_unit === ""}
          isServiceCompensation={true}
        />
      ),
      received_employee_display: (
        <EditorColumnWrap
          id={`CardDoctorFeeOrderAndCompensation-EditorColumnWrap-Receiver-${index}`}
          style={{
            ...(props?.SetDoctorFeeSequence?.editSCIdxType?.some(
              (i: any) => i.type === "received_employee" && i.index === index
            ) && { backgroundColor: "orange" }),
          }}
          value={item.received_employee_display}
          isServiceCompensation={true}
          isNoPriceUnit={item.price_unit === ""}
          input={
            <EmployeeToken
              onClick={(e: EventTarget) => {
                e.stopPropagation();
              }}
              placeholder="code"
              onEnterToken={(code) => {
                props.runSequence({
                  sequence: "SetDoctorFee",
                  action: "setTokenization",
                  params: {
                    code,
                    idx: index,
                    name: "scPreviewItems",
                    type: "received_employee",
                  },
                  onFailed: (error: any) => setErrorMessage(error),
                });
              }}
              onClearToken={() => {
                props.runSequence({
                  sequence: "SetDoctorFee",
                  action: "clearTokenization",
                  params: {
                    idx: index,
                    name: "scPreviewItems",
                    type: "received_employee",
                  },
                  onFailed: (error: any) => setErrorMessage(error),
                });
              }}
              error={props?.SetDoctorFeeSequence?.scPreviewItems?.[index]?.error}
              loading={props?.SetDoctorFeeSequence?.scPreviewItems?.[index]?.loading}
              employeeName={props?.SetDoctorFeeSequence?.scPreviewItems?.[index]?.employeeName}
              /// ไม่ให้แก้ไข คือเงื่อนไขร่วมของ ServiceCompensation type และ price unit มีค่า !== ""
              disabled={item.price_unit !== ""}
            />
          }
        />

        // <EditorColumn
        //   value={item.received_employee_display}
        //   onDataChanged={(value: string) =>
        //     handleChangeEditSc({
        //       value,
        //       index,
        //       type: "received_employee",
        //     })
        //   }
        //   isServiceCompensation={true}
        //   isNoPriceUnit={item.price_unit === "" ? true : false}
        // />
      ),
      price_unit: <div style={{ textAlign: "right" }}> {formatComma(item.price_unit)} </div>,
      price_compensation_max: (
        <div style={{ textAlign: "right" }}> {formatComma(item.price_compensation_max)} </div>
      ),
      quantity: <div style={{ textAlign: "right" }}>{formatComma(item.quantity)} </div>,
      total_compensation_price: (
        <div style={{ textAlign: "right" }}>{formatComma(item.total_compensation_price)} </div>
      ),
      price_claimable: (
        <div style={{ textAlign: "right" }}>{formatComma(item.price_claimable)} </div>
      ),
      price_non_claimable: (
        <div style={{ textAlign: "right" }}>{formatComma(item.price_non_claimable)} </div>
      ),
      price_total: <div style={{ textAlign: "right" }}>{formatComma(item.price_total)} </div>,
      edited_by: <div>{item.received_employee_display ? item.edited_by : ""}</div>,
      action: item.service_code ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Button
            icon
            circular
            onClick={() => {
              console.log("onclick addServiceCode: ", addService);
              console.log("onclick addServiceCode item.service_code: ", item.id);
              if (!addService?.invoiceId) {
                // ไม่ได้เลือกเลย
                setAddService({ name: item.name, invoiceId: item.id });
              } else if (addService?.invoiceId === item.id) {
                // เลือกตัวเดิม
                setAddService({});
                setAppendOfficer(0);
                setAppendAmount(0);
              } else if (addService?.invoiceId && addService?.invoiceId !== item.id) {
                // เลือกตัวใหม่
                setAddService({ name: item.name, invoiceId: item.id });
              }
            }}
          >
            <Icon name="add circle" color="green" />
          </Button>
        </div>
      ) : (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Button
            icon
            onClick={() => {
              setOpenModDeleteId(item.id);
            }}
          >
            <Icon name="trash alternate" color="red" />
          </Button>
        </div>
      ),
    }));
  }, [
    props?.SetDoctorFeeSequence?.scPreviewItems,
    addService,
    openModDeleteId,
    props?.SetDoctorFeeSequence?.editSCIdxType,
  ]);

  const sum = useMemo(() => {
    const [total, claimable, nonClaimable] = (
      props?.SetDoctorFeeSequence?.dfPreviewItems || []
    ).reduce(
      (result: any, item: any) => {
        result[0] += Number.parseFloat(item.price_total);
        result[1] += Number.parseFloat(item.price_claimable);
        result[2] += Number.parseFloat(item.price_non_claimable);
        return result;
      },
      [0, 0, 0]
    );

    return {
      total: total.toFixed(2),
      claimable: claimable.toFixed(2),
      nonClaimable: nonClaimable.toFixed(2),
    };
  }, [props?.SetDoctorFeeSequence?.dfPreviewItems]);

  const dfRuleCategoryOptions = useMemo(() => {
    const options = props.masterOptions?.dfRuleCategory || [];

    return [{ key: 0, text: "ทั้งหมด", value: "ALL" }, ...options];
  }, [props.masterOptions?.dfRuleCategory]);

  const handleSearchText = (text: string) => {
    let timeOut;

    if (filterTimeOut !== null) {
      clearTimeout(filterTimeOut);
    }

    setSearchText(text);

    timeOut = setTimeout(() => {
      if (searchText.length >= 3) {
        props.runSequence({
          sequence: "SetDoctorFee",
          action: "search",
          searchText: searchText,
        });
      } else if (searchText.length === 1) {
        props.setProp(`SetDoctorFeeSequence.searchResult`, null);
      }
    }, 800);

    setFilterTimeOut(timeOut as any);
  };

  const handleOpenSearch = async () => {
    const isLocked = await props.onEvent({ message: "CheckEncounterLocker" });

    if (isLocked) {
      return;
    }

    setIsLoading(true);
    props.runSequence({
      sequence: "SetDoctorFee",
      action: "open_search",
    });
    setOpenSearch(true);
    setSearchCategory("ALL");
    setSelectItemData([]);
    setSelectItem({});
  };

  const handleSearchItem = () => {
    setIsLoading(true);
    props.runSequence({
      sequence: "SetDoctorFee",
      action: "open_search",
      keyword: searchValue,
      category: searchCategory === "ALL" ? "" : searchCategory,
    });
  };

  const handleAddItem = () => {
    setSearchValue("");
    setSearchCategory("");
    props.runSequence({
      sequence: "SetDoctorFee",
      action: "add_select",
      item: selectItemData,
    });
    setOpenSearch(false);
  };

  const handleCheckBoxChange = (e: any, checked: any, row: any) => {
    let stateItem: any = { ...selectItem };
    let stateItemData: any = [];
    if (!checked) {
      delete stateItem[row.original.id];
      let select_data = [selectItemData];
      select_data[0]?.map((item: any, index: any) => {
        if (item?.doctor_fee_rule !== row.original.id) {
          stateItemData.push(item);
        }
      });
    } else {
      stateItem[row.original.id] = row.original;
      stateItemData.push(...selectItemData, {
        encounter: props?.selectedEncounter?.id,
        quantity: 1,
        doctor_fee_rule: row.original?.id,
        doctor_fee_rule_display: row.original?.name,
        service_code: row.original?.service_code,
        price_unit: row.original?.price_unit,
        received_employee: props?.providerEmployeeInfo?.employee_info?.id,
        received_employee_display: props?.providerEmployeeInfo?.employee_info?.full_name,
        compensation_price: row.original?.price_unit,
      });
    }
    setSelectItemData(stateItemData);
    setSelectItem(stateItem);
  };

  const handleSave = async () => {
    const isLocked = await props.onEvent({ message: "CheckEncounterLocker" });

    if (isLocked) {
      return;
    }

    props.runSequence({
      sequence: "SetDoctorFee",
      action: "save",
      buttonLoadKey: `CardDoctorFeeOrderAndCompensation_save`,
      onFailed: (error: any) => setErrorMessage(error),
    });
  };

  // const formatComma = (val: any) =>
  //   !isNaN(parseFloat(val))
  //     ? parseFloat(val)
  //         .toFixed(2)
  //         .toString()
  //         .replace(/\B(?=(?:\d{3})+(?!\d))/g, ",")
  //     : val;

  const searchResult = props?.SetDoctorFeeSequence?.searchResult || [];

  console.log("dfPreviewItems", dfPreviewItems);

  // console.log(" CardDoctorFee 1", props?.SetDoctorFeeSequence?.tokenize?.tokenizeEmployeeId);
  // console.log(" CardDoctorFee 2", appendAmount);
  // console.log(" CardDoctorFee 3", appendOfficer);
  // console.log(" CardDoctorFee 4", addService?.invoiceId);

  return (
    <>
      {!!props.errorMessage?.SetDoctorFee || !!props.successMessage?.SetDoctorFee ? (
        <SnackMessage
          onEvent={props.onEvent}
          onClose={() => {
            // setOpen(false);
            props.runSequence({
              sequence: "SetDoctorFee",
              action: "clear",
            });
          }}
          error={props.errorMessage?.SetDoctorFee}
          success={props.successMessage?.SetDoctorFee}
          languageUX={props.languageUX}
        />
      ) : null}
      <div style={{ margin: "15px 25px" }}>
        <div style={{ fontSize: "1.5rem", marginBottom: "15px" }}>
          {intl.formatMessage({ id: "ค่าตอบแทนแพทย์" })}
        </div>
        <Button
          color={doctorFeeOrder ? "blue" : undefined}
          onClick={() => {
            setDoctorFeeOrder(true);
            setServiceCompensation(false);
          }}
        >
          ค่าธรรมเนียมตรวจ/วิสัญญี
        </Button>
        <Button
          color={serviceCompensation ? "blue" : undefined}
          onClick={() => {
            setDoctorFeeOrder(false);
            setServiceCompensation(true);
          }}
          style={{ marginLeft: "10px" }}
        >
          ค่าตอบแทน(หัตถการ)
        </Button>
      </div>

      {doctorFeeOrder && (
        <CardDoctorFeeOrderAndCompensationUX
          open={handleOpenSearch}
          searchText={searchText}
          onChangeSearchText={(e: any, v: any) => handleSearchText(v.value)}
          showSearchResult={searchResult && searchResult?.length > 0 ? true : false}
          searchResult={searchResult.map((item: any, index: number) => (
            <div
              key={index}
              style={{
                cursor: "pointer",
                padding: "5px",
                borderBottom: "1px solid #EEEEEE",
              }}
              onClick={(e: any) => {
                setSearchText("");
                props.runSequence({
                  sequence: "SetDoctorFee",
                  action: "add",
                  item: item,
                });
              }}
            >
              <div>{item.name}</div>
              <div style={{ color: "rgba(174,174,174)" }}>{item.code}</div>
            </div>
          ))}
          // Issue 63021
          dfPreviewItems={dfPreviewItems}
          tableStyle={{ zoom: "85%" }}
          priceTotal={sum.total}
          priceClaimable={sum.claimable}
          priceNonClaimable={sum.nonClaimable}
          // onSaveDF={() => {
          //   setLoading(true);
          //   props.runSequence({
          //     sequence: "SetDoctorFee",
          //     action: "save",
          //     onFailed: (error: any) => setErrorMessage(error),
          //   });
          // }}
          // button_save={completed ? <Icon name="check"></Icon> : "SAVE"}
          // loadingSave={loading}
          buttonSave={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={handleSave}
              // data
              paramKey={`CardDoctorFeeOrderAndCompensation_save`}
              fluid
              buttonLoadCheck={props.buttonLoadCheck?.[`CardDoctorFeeOrderAndCompensation_save`]}
              // config
              name="save"
              size="medium"
              title="SAVE"
              color="green"
            />
          }
          languageUX={props.languageUX}
        />
      )}

      {serviceCompensation && (
        <CardServiceCompensationItemUX
          // Issue 63021
          scPreviewItems={scPreviewItems}
          tableStyle={{ zoom: "85%" }}
          checkDate={checkDate}
          onChangeCheckDate={(e: any, { checked }: any) => setCheckDate(checked)}
          startDate={startDate}
          endDate={endDate}
          onChangeStartDate={(date: any) => setStartDate(date)}
          onChangeEndDate={(date: any) => setEndDate(date)}
          getTrProps={(state: any, rowInfo: RowInfo, column: Column) => {
            return {
              className:
                rowInfo?.original?.id && rowInfo?.original?.id === addService?.invoiceId
                  ? "blueSelectedRow"
                  : "",
            };
          }}
          addBar={
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "20px",
              }}
            >
              {"invoiceId" in addService ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <label>
                      {addService?.name ? (
                        <>
                          {" "}
                          <span style={{ fontWeight: "bold" }}> Product: </span> {addService?.name}{" "}
                        </>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <label>{intl.formatMessage({ id: "ตำแหน่งผู้ได้รับค่าตอบแทน" })}</label>
                    <Dropdown
                      selection
                      options={props.masterOptions?.officerList || []}
                      placeholder={intl.formatMessage({ id: "ตำแหน่งผู้ได้รับค่าตอบแทน" })}
                      value={appendOfficer}
                      onChange={(e, { value }) => {
                        setAppendOfficer(value as number);
                      }}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <label>{intl.formatMessage({ id: "จำนวนเงิน" })}</label>
                    <Input
                      type="number"
                      placeholder={intl.formatMessage({ id: "จำนวนเงิน" })}
                      value={appendAmount}
                      min={0}
                      onChange={(e, { value }) => {
                        setAppendAmount(value as number);
                      }}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <label>{intl.formatMessage({ id: "รหัสพนักงาน" })}</label>
                    <EmployeeToken
                      notWidth100={true}
                      onClick={(e: EventTarget) => {
                        e.stopPropagation();
                      }}
                      placeholder={intl.formatMessage({ id: "รหัสพนักงาน" })}
                      onEnterToken={(code) => {
                        props.runSequence({
                          sequence: "SetDoctorFee",
                          action: "setTokenizationNoneIndex",
                          code,
                          onFailed: (error: any) => setErrorMessage(error),
                        });
                      }}
                      onClearToken={() => {
                        props.runSequence({
                          sequence: "SetDoctorFee",
                          action: "clearTokenizationNoneIndex",

                          onFailed: (error: any) => setErrorMessage(error),
                        });
                      }}
                      error={props?.SetDoctorFeeSequence?.tokenize?.tokennizeError}
                      loading={props?.SetDoctorFeeSequence?.tokenize?.tokenizeLoading}
                      employeeName={props?.SetDoctorFeeSequence?.tokenize?.tokenizeEmployeeName}
                    />
                  </div>

                  <Button
                    color="blue"
                    disabled={
                      !props?.SetDoctorFeeSequence?.tokenize?.tokenizeEmployeeName ||
                      !appendAmount ||
                      !appendOfficer ||
                      !addService?.invoiceId
                    }
                    onClick={() => {
                      console.log(" addServiceCode", addService);
                      props.runSequence({
                        sequence: "SetDoctorFee",
                        action: "AddSC",
                        officer: appendOfficer,
                        amount: appendAmount,
                        employee: props?.SetDoctorFeeSequence?.tokenize?.tokenizeEmployeeId,
                        invoiceId: addService?.invoiceId,
                      });
                      setAppendOfficer(0);
                      setAppendAmount(null);
                      setAddService({});
                    }}
                  >
                    เพิ่มรายการ
                  </Button>
                </>
              ) : null}

              <ButtonLoadCheck
                // function
                setProp={props.setProp}
                onClick={() => {
                  props.runSequence({
                    sequence: "SetDoctorFee",
                    action: "SaveSC",
                    buttonLoadKey: `CardDoctorFeeOrderAndCompensation_SaveSC`,
                    onFailed: (error: any) => setErrorMessage(error),
                  });
                }}
                // data
                paramKey={`CardDoctorFeeOrderAndCompensation_SaveSC`}
                buttonLoadCheck={
                  props.buttonLoadCheck?.[`CardDoctorFeeOrderAndCompensation_SaveSC`]
                }
                // config
                name="SaveSC"
                size="medium"
                title="SAVE"
                color="green"
              />
            </div>
          }
          languageUX={props.languageUX}
        />
      )}

      <ModInfo
        open={!!errorMessage}
        titleColor="red"
        onApprove={() => setErrorMessage(null)}
        onClose={() => setErrorMessage(null)}
      >
        <ErrorMessage error={errorMessage} />
      </ModInfo>
      {openModDeleteId && (
        <ModConfirm
          openModal={openModDeleteId}
          content={intl.formatMessage({ id: "ต้องการลบรายการค่าตอบแทนหรือไม่" })}
          titleColor={"red"}
          onApprove={() => {
            // console.log
            console.log(" Fired delete API with ");

            props.runSequence({
              sequence: "SetDoctorFee",
              action: "DeleteSC",
              id: openModDeleteId,
            });
            setOpenModDeleteId(0);
          }}
          onDeny={() => {
            setOpenModDeleteId(0);
          }}
        ></ModConfirm>
      )}

      <Modal onClose={() => setOpenSearch(false)} open={openSearch} size="large">
        <CardLayout
          headerColor="orange"
          titleText={intl.formatMessage({ id: "ค้นหารายการค่าตอบแทน" })}
          loading={isLoading}
          closeable={false}
          toggleable={false}
          hideHeaderIcon={true}
        >
          {/* @ts-ignore */}
          <Form>
            <Form.Group inline>
              <Form.Field style={{ minWidth: "max-content" }}>
                <label>{"หมวดรายการ"}</label>
              </Form.Field>
              <Form.Field width={4}>
                <Dropdown
                  options={dfRuleCategoryOptions}
                  onChange={(e, data) => setSearchCategory(data.value)}
                  value={searchCategory}
                  search
                  selection
                  fluid
                  style={{ width: "100%" }}
                />
              </Form.Field>
              <Form.Field style={{ minWidth: "max-content" }}>
                <label>{intl.formatMessage({ id: "ชื่อหรือรหัส" })}</label>
              </Form.Field>
              <Form.Field width={8}>
                <Input onChange={(e) => setSearchValue(e.target.value)} value={searchValue} />
              </Form.Field>
              <Form.Field width={2}>
                <Button
                  color="blue"
                  content={intl.formatMessage({ id: "ค้นหา" })}
                  fluid
                  onClick={handleSearchItem}
                />
              </Form.Field>
            </Form.Group>
          </Form>
          <Divider />
          <PureReactTable
            style={{ height: "440px" }}
            data={props?.SetDoctorFeeSequence?.searchProductResult}
            showPagination={false}
            minRows={10}
            onSelect={(originalRow: any) => setSelectedRow(originalRow)}
            selectedRow={selectedRow}
            columns={orderSearchItems}
          />
          <Divider />
          {/* @ts-ignore */}
          <Form>
            <Form.Group inline className="rightAlign">
              <Form.Field width={2}>
                <Button
                  type="button"
                  fluid
                  color="green"
                  content={intl.formatMessage({ id: "เลือก" })}
                  onClick={handleAddItem}
                />
              </Form.Field>
              <Form.Field width={2}>
                <Button
                  type="button"
                  fluid
                  color="red"
                  content={intl.formatMessage({ id: "ยกเลิก" })}
                  onClick={() => setOpenSearch(false)}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </CardLayout>
      </Modal>

      <ModLockExpense
        onEvent={props.onEvent}
        setProp={props.setProp}
        buttonLoadCheck={props.buttonLoadCheck}
        errorMessage={props.errorMessage}
      />
    </>
  );
};

const EditorColumnWrap: React.FunctionComponent<any> = (props) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const handleClick = () => {
    if (props.disabled) {
      return;
    }

    if (props.value && isEdit) {
      setIsEdit(false);
    } else {
      setIsEdit(true);
    }
  };

  useEffect(() => {
    setIsEdit(false);
  }, [props]);

  // props.onDataChanged?.(inputRef.current.inputRef.current.value);
  return (
    <div
      id={props.id}
      style={{
        ...((!props.isNoPriceUnit && props.isServiceCompensation) || props.disabled
          ? ColumnStyleWhite
          : ColumnStyle),
        ...(props.style || {}),
      }}
      onClick={handleClick}
      // onBlur={()=>{ setIsEdit(false)}}
      // onKeyDown={()=>{ setIsEdit(false)}}
    >
      {isEdit ? props.input : <div style={{ textAlign: "right" }}>{props.value}</div>}
    </div>
  );
};

const EditorColumn: React.FunctionComponent<any> = (props) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const inputRef = useRef() as MutableRefObject<any>;

  const handleClick = () => {
    if (!props.isNoPriceUnit && props.isServiceCompensation) {
      setIsEdit(false);
    } else {
      setIsEdit(true);

      setTimeout(() => {
        inputRef.current.inputRef.current.focus();
      }, 100);
    }
  };

  const handleBlur = () => {
    props.onDataChanged?.(inputRef.current.inputRef.current.value);

    setIsEdit(false);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleBlur();
    }
  };

  return (
    <div
      id={props.id}
      style={{
        ...(!props.isNoPriceUnit && props.isServiceCompensation ? ColumnStyleWhite : ColumnStyle),
        ...(props.style || {}),
      }}
      onClick={handleClick}
    >
      {isEdit ? (
        <Input
          ref={inputRef}
          style={{ width: "100%", height: "100%" }}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
        />
      ) : (
        <div style={{ textAlign: "right" }}>{props.value}</div>
      )}
    </div>
  );
};

export const DeleteColumn: React.FC<any> = (props) => {
  const [openModConfirm, setOpenModConfirm] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<any>(null);

  const handleCancel = () => {
    props.onConfirm?.({});
    // handleCloseModConfirm();
  };

  const handleCloseModConfirm = () => {
    setOpenModConfirm(false);
    setErrorMessage(null);
  };

  const handleDeleteItem = (data: any) => {
    setErrorMessage(null);
    props.onConfirm?.({
      ...data,
      onFailed: (error: any) => {
        setErrorMessage({ detail: error });
      },
      onSuccess: () => {
        handleCloseModConfirm();
      },
    });
  };

  return (
    <>
      {!(!props.value && props.id) && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            color="red"
            icon="minus"
            onClick={() => setOpenModConfirm(true)}
            size={props.size}
          />
        </div>
      )}
      {props.isConfirm ? (
        <ModCancelExpense
          title="ยืนยันการยกเลิก/แก้ไขรายการค่าใช้จ่าย"
          error={errorMessage?.detail}
          open={openModConfirm}
          options={props.options}
          onApprove={handleDeleteItem}
          onClose={handleCloseModConfirm}
        />
      ) : (
        <ModConfirm
          openModal={openModConfirm}
          titleName={props.titleName ?? "ต้องการลบรายการค่าธรรมเนียมหรือไม่"}
          titleColor={props.titleColor}
          onApprove={handleCancel}
          onDeny={handleCloseModConfirm}
        />
      )}
    </>
  );
};

export default CardDoctorFeeOrderAndCompensation;
