import WasmController from "react-lib/frameworks/WasmController";
import SupplyOrderPrintForDivisionViewM from "issara-sdk/apis/SupplyOrderPrintForDivisionViewM";

export type State = {
  RefillDivisionSequence?: {
    sequenceIndex?: any;
    coUser?: string;
    coUserToken?: string;
    divisionPrintItems?: any[];
    openModInfo?: boolean;
    titlePopup?: string;
  } | null;
  successMessage?: any;
  errorMessage?: any;
};

export const StateInitial: State = {
  RefillDivisionSequence: {
    coUser: "",
    coUserToken: "",
    divisionPrintItems: [],
  },
  successMessage: null,
  errorMessage: null,
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  division?: number;
};

const LIMIT = 40;

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const Start: Handler = async (controller, params) => {
  const state = controller.getState();
  console.log("RefillDivisionPrint start !!");
  // if (!state.RefillDivisionSequence) return;
  console.log("aaaa");

  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [["supplyOrderStatus", {}]],
    },
  });
  console.log("getDivPrintList....0");
  if (["save"].includes(params?.action)) {
    await postDivPrintList(controller, params);
  }

  await getDivPrintList(controller, params, true);
};

export const SearchAndSelect: Handler = async (controller, params) => {
  var state = controller.getState();
  if (!state.RefillDivisionSequence) return;
};

const getDivPrintList = async (controller: any, params: any, init = false) => {
  console.log("getDivPrintList....1");
  const [r, e, n] = await SupplyOrderPrintForDivisionViewM.get({
    params: {
      limit: LIMIT,
    },
    extra: { division: controller.data.division },
    apiToken: controller.apiToken,
  });

  controller.setState({
    RefillDivisionSequence: {
      ...StateInitial.RefillDivisionSequence,
      sequenceIndex: "SearchAndSelect",
      divisionPrintItems: r ? r.items : [],
    },
  });
};

const postDivPrintList = async (controller: any, params: any, init = false) => {
  var state = controller.getState();
  console.log("postDivPrintList....1");
  controller.setState({
    buttonLoadCheck: {
      ...state.buttonLoadCheck,
      "RefillDivisionPrint_SAVE": "LOADING",
    },
  });

  const [r, e, n] = await SupplyOrderPrintForDivisionViewM.post({
    params: {},
    data: {
      items: params?.items,
    },
    extra: { division: controller.data.division, device: controller.data.device },
    apiToken: controller.apiToken,
  });

  controller.setState({
    RefillDivisionSequence: {
      ...StateInitial.RefillDivisionSequence,
      sequenceIndex: "SearchAndSelect",
      divisionPrintItems: r ? r.items : [],
      openModInfo:true,
      titlePopup:"REFILL สำเร็จ"
    },
    successMessage: { ...state.successMessage, [params?.card]: r },
  });

  if (e) {
    console.log("error:...", e);
    controller.setState({
      errorMessage: { ...state.errorMessage, [params?.sequence]: e },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        "RefillDivisionPrint_SAVE": "ERROR",
      },
    });
    
  } else {
    controller.setState({
      errorMessage: { ...state.errorMessage, [params?.sequence]: "" },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        "RefillDivisionPrint_SAVE": "SUCCESS",
      },
    });
  }
};
