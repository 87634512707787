import React, { useEffect, useMemo, useState } from "react";

import moment from "moment";
import { Calendar, DateLocalizer, View, momentLocalizer } from "react-big-calendar";

// UX
import CardSelectDateTimePackageUX from "./CardSelectDateTimePackageUX";
import ModMakeAppointmentPackage from "./ModMakeAppointmentPackage";

// Common
import SnackMessage from "react-lib/apps/common/SnackMessage";

// Interface
import {
  PackageBlockDetailType,
  RunSequence,
  SEQ_ACTIONS,
  State,
} from "./sequence/PackageDateTime";
import { Icon, Modal } from "semantic-ui-react";
import { useIntl } from "react-intl";

// Types
type CardSelectDateTimePackageProps = {
  setProp: (key: string, value: any, callback?: Function) => any;
  onEvent: (e: any) => any;
  // data
  selectedPatient?: Record<string, any> | null;
  // controller
  proxyController?: any;
  // seq
  runSequence: RunSequence;
  PackageDateTimeSequence?: State["PackageDateTimeSequence"];
  // CommonInterface
  buttonLoadCheck?: Record<string, any>;
  errorMessage?: Record<string, any>;
  selectedDivision?: any;
  selectedAppointment?: Record<string, any> | null;
};

// Const
const localizer = momentLocalizer(moment);
const formats = {
  timeGutterFormat: (date: any, culture: any, localizer: any) =>
    localizer.format(date, "H:mm", culture),
  monthHeaderFormat: (date: Date, culture?: string, localize?: DateLocalizer) => {
    let defaultMonth = localize?.format(date, "MMMM YYYY", culture)
    let BEYear = Number(localize?.format(date, "YYYY")) + 543
    let thaiMonth = localize?.format(date, "MMMM", "th-TH")
    return `${defaultMonth}  (${thaiMonth} ${BEYear})`
  }
};

const START_MONTH_DATE = moment().clone().startOf("months").toDate();

const END_MONTH_DATE = moment().clone().endOf("months").toDate();

export const CARD_SELECT_DATE_TIME_PACKAGE = "CardSelectDateTimePackage";

const CardSelectDateTimePackage = (props: CardSelectDateTimePackageProps) => {
  const intl = useIntl();
  const [selectedSlot, setSelectedSlot] =
    useState<PackageBlockDetailType | null>(null);

  // Use Effect
  useEffect(() => {
    props.runSequence({
      sequence: "PackageDateTime",
      action: SEQ_ACTIONS.GET_DSB,
      startDate: START_MONTH_DATE,
      endDate: END_MONTH_DATE,
    });
  }, []);

  const blockItems = useMemo(() => {
    return (props.PackageDateTimeSequence?.packageBlockList || []).map(
      (item) => {
        return {
          ...item,
          title: <DisplayTitle data={item} />,
        };
      }
    );
  }, [props.PackageDateTimeSequence?.packageBlockList]);

  const selectedPackageItems = useMemo(() => {
    return props.PackageDateTimeSequence?.selectedPackageItems || [];
  }, [props.PackageDateTimeSequence?.selectedPackageItems]);

  const handleCancel = () => {
    setSelectedSlot(null);
  };

  const handleSelectEvent = (event: any) => {
    const orderNote =
      props.selectedAppointment?.order_note ||
      props.PackageDateTimeSequence?.appointmentPackageDetail?.doctor_note ||
      "";

    setSelectedSlot({
      ...event,
      doctor_note: orderNote,
    });
  };

  console.log("CardSelectDateTimePackageUX", props);

  return (
    <div style={{ padding: "0.5rem 1rem" }}>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_SELECT_DATE_TIME_PACKAGE}`, null);
        }}
        error={props.errorMessage?.[CARD_SELECT_DATE_TIME_PACKAGE]}
        success={null}
        languageUX={props.languageUX}
      />

      <CardSelectDateTimePackageUX
        selectedDivision={props.selectedDivision?.name}
        // Element
        Calendar={
          <Calendar
            defaultView="month"
            localizer={localizer}
            events={blockItems}
            views={["week", "month"]}
            startAccessor="start"
            endAccessor="end"
            formats={formats}
            style={{ height: "calc(100vh - 13rem)" }}
            selectable={true}
            tooltipAccessor={(event) =>
              event.time + (event.count ? ` (${event.count})` : "")
            }
            eventPropGetter={(
              event: any,
              start: Date,
              end: Date,
              isSelected: boolean
            ) => {
              return {
                style: {
                  backgroundColor: "#FFC0CB",
                  color: "#000000",
                  marginBottom: "1px",
                },
              };
            }}
            onSelectEvent={(event: any) => {
              handleSelectEvent(event);
            }}
            onRangeChange={(range: any, view?: View) => {
              // #console.log("onRangeChange", range, view);
            }}
          />
        }
        languageUX={props.languageUX}
      />

      <Modal
        open={!!selectedSlot}
        closeOnDimmerClick={true}
        style={{ width: "40%" }}
        onClose={handleCancel}
      >
        <ModMakeAppointmentPackage
          setProp={props.setProp}
          // controller
          proxyController={props.proxyController}
          // seq
          runSequence={props.runSequence}
          // data
          data={selectedSlot || {}}
          selectedPatient={props.selectedPatient}
          selectedPackageItems={selectedPackageItems}
          // CommonInterface
          selectedDivision={props.selectedDivision}
          buttonLoadCheck={props.buttonLoadCheck}
          // callback
          onCancel={handleCancel}
          languageUX={props.languageUX}
        />
      </Modal>
    </div>
  );
};

/* ------------------------------------------------------ */

/*                      DisplayTitle;                     */

/* ------------------------------------------------------ */
const DisplayTitle = (props: any) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div style={{ fontSize: "0.75rem" }}>{props.data.time}</div>
      <div
        style={{
          display: "flex",
          fontSize: "0.65rem",
          marginTop: "0px",
          color: "#575656",
        }}
      >
        {!!props.data.count && (
          <>
            <Icon name="user" style={{ marginRight: 1 }} />
            <label style={{ margin: "0 5px", fontSize: "0.75rem" }}>
              {props.data.count}
            </label>
          </>
        )}
      </div>
    </div>
  );
};

export default React.memo(CardSelectDateTimePackage);
