import React, { useRef, useState, useEffect } from "react";
import { Modal, Form, Icon, Button } from "semantic-ui-react";

import Cookies from "js-cookie";
import PropTypes from "prop-types";
import moment from "moment";

import ErrorMessages from "../../common/ErrorMessage";
import { useIntl } from "react-intl";

const ModSelectAppointment = React.forwardRef((props, ref) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [employeeChoices, setEmployeeChoices] = useState([]);
  const [dateChoices, setDateChoices] = useState([]);
  const [timeRangeChoices, setTimeRangeChoices] = useState([]);
  const [employee, setEmployee] = useState(null);
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  const isMounted = useRef(true);

  useEffect(() => {
    if(props.open){
      isMounted.current = true;
    }
  }, [props.open])

  useEffect(() => {
    return () => (isMounted.current = false);
  }, []);

  useEffect(() => {
    clearErrorSuccess()
    if (props.open) {
      handleGetChoices();
    } else {
      clear();
    }
  }, [props.open, employee, date, time]);

  useEffect(() => {
    handleGetAppointmentDetail()
  }, [props.patientAppointmentId])

  const clearErrorSuccess = () => {
    setSuccess(null)
    setError(null)
  }

  const clear = () => {
    setEmployee(null);
    setDate(null);
    setTime(null);
  };

  const handleGetChoices = async () => {
    setIsLoading(true);
    if(!props.division && !Cookies.get("division_id")){
      return
    }
    const [res, err] = await props.controller.getAvailableDivisionServiceBlock({
      employee,
      date,
      timeRange: time,
      apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
      division: props.division ? props.division : Cookies.get("division_id")
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (res) {
        const options = res.time_ranges.map((option: any) => ({
          ...option,
          text: option.text
            .split(" - ")
            .map((date: any) => moment(date).format("HH:mm"))
            .join(" - "),
        }));

        setEmployeeChoices(res.employees);
        setDateChoices(res.dates);
        setTimeRangeChoices(options);
      }
    }
  };

  const handleGetAppointmentDetail = async () => {
    setIsLoading(true)
    if(!props.patientAppointmentId){
      return
    }
    const [res, err, network] = await props.controller.getPatientAppointmentUpdate({
      appointmentId: props.patientAppointmentId,
      apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
    })
    if(isMounted.current){
      setIsLoading(false)
      if(res){
        if(res.display_info){
          setEmployee(res.display_info.employee)
          setTime(`${res.display_info.start_datetime_iso},${res.display_info.end_datetime_iso}`)
          setDate(res.display_info.start_datetime_iso.split("T")[0])
        }
      }
    }
  }
  const handleEmployeeChange = async (e, { value }) => {
    setEmployee(value);
  };

  const handleDateChange = async (e, { value }) => {
    if (!value) {
      setTime(null);
    }

    setDate(value);
  };

  const handleTimeChange = async (e, { value }) => {
    setTime(value);
  };

  const handleConfirmAppointment = async () => {
    clearErrorSuccess()
    setIsLoading(true);
    const [
      res,
      err,
      network
    ] = await props.controller.postSelectDivisionServiceBlock({
      apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
      patientAppointmentId: props.patientAppointmentId,
      division: props.division ? props.division : Cookies.get("division_id"),
      timeRange: time,
      employee
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (res) {
        setSuccess("สร้างนัดหมายสำเร็จ")
      } else if (err) {
        if(network.response.status === 500){
          setError(" Error 500: เกิดข้อผิดพลาด")
        } else {
          setError(err);
        }
      }
    }
  };

  return (
    <Modal
      closeOnDimmerClick
      onClose={props.onClose}
      open={props.open}
      size="tiny"
      className="mod-select-appointment"
    >
      <Modal.Header>
        เลือกนัดหมาย
        <Icon size="large" color="red" name="close" onClick={props.onClose} />
      </Modal.Header>
      <Modal.Content>
        <Form>
          <ErrorMessages error={error} />
          <ErrorMessages success={success} />
          <Form.Group inline>
            <Form.Field width={2}>แพทย์<span style={{ color: "red" }}>*</span></Form.Field>
            <Form.Dropdown
              width={14}
              search
              selection
              clearable
              style={{ width: "100%" }}
              loading={isLoading}
              options={employeeChoices}
              onChange={handleEmployeeChange}
              value={employee}
            />
          </Form.Group>
          <Form.Group inline>
            <Form.Field width={2}>วันที่<span style={{ color: "red" }}>*</span></Form.Field>
            <Form.Dropdown
              width={14}
              search
              selection
              clearable
              style={{ width: "100%" }}
              loading={isLoading}
              options={dateChoices}
              onChange={handleDateChange}
              value={date}
            />
          </Form.Group>
          <Form.Group inline>
            <Form.Field width={2}>เวลา<span style={{ color: "red" }}>*</span></Form.Field>
            <Form.Dropdown
              width={14}
              search
              selection
              clearable
              style={{ width: "100%" }}
              loading={isLoading}
              options={timeRangeChoices}
              onChange={handleTimeChange}
              value={time}
            />
          </Form.Group>
          <Form.Group>
            <Form.Field width={4} />
            <Form.Field width={4}>
              <Button
                content={intl.formatMessage({ id: "ยกเลิก" })}
                fluid
                color="grey"
                onClick={props.onClose}
              />
            </Form.Field>
            <Form.Field  width={4} >
              <Button
                content={intl.formatMessage({ id: "ตกลง" })}
                fluid
                primary
                onClick={handleConfirmAppointment}
                loading={isLoading}
                disabled={isLoading || (!employee || !time || !date)}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </Modal.Content>
    </Modal>
  );
});

ModSelectAppointment.defaultProps = {
  controller: {},
  patientAppointmentId: null,
};

ModSelectAppointment.propTypes = {
  controller: PropTypes.object,
  patientAppointmentId: PropTypes.number,
};

export default ModSelectAppointment;
