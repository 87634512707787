import React from 'react'
import {
   Checkbox,
   Dropdown,
   Input,
} from 'semantic-ui-react'



import styles from './POPhysicalexaminationOrthoticFittingandCheckout2TOP.module.css'

export interface POPhysicalexaminationOrthoticFittingandCheckout2TOPViewDataType {
    PoPhysicalOrthoFittingCheckout2Top3: string,
    PoPhysicalOrthoFittingCheckout2Top3Disabled: boolean,
    Ct2GeneralAppearanceDysfunction: string,
    Ct2GeneralAppearanceDysfunctionDisabled: boolean,
    Ct2GeneralAppearanceDysfunctionOptions: any,
    PoPhysicalOrthoFittingCheckout2Top10: string,
    PoPhysicalOrthoFittingCheckout2Top10Disabled: boolean,
    Ct5GeneralAppearance: string,
    Ct5GeneralAppearanceDisabled: boolean,
    Ct5GeneralAppearanceOptions: any,
    Ct5Consciousness: string,
    Ct5ConsciousnessDisabled: boolean,
    Ct5ConsciousnessOptions: any,
    Ct8Communication: string,
    Ct8CommunicationDisabled: boolean,
    Ct8CommunicationOptions: any,
    Ct8CoOperation: string,
    Ct8CoOperationDisabled: boolean,
    Ct8CoOperationOptions: any,
    PoPhysicalOrthoFittingCheckout2Top26: string,
    PoPhysicalOrthoFittingCheckout2Top26Disabled: boolean,
    PoPhysicalOrthoFittingCheckout2Top27: string,
    PoPhysicalOrthoFittingCheckout2Top27Disabled: boolean,
    PoPhysicalOrthoFittingCheckout2Top28: boolean,
    PoPhysicalOrthoFittingCheckout2Top28Disabled: boolean,
    PoPhysicalOrthoFittingCheckout2Top30: string,
    PoPhysicalOrthoFittingCheckout2Top30Disabled: boolean,
    PoPhysicalOrthoFittingCheckout2Top31: boolean,
    PoPhysicalOrthoFittingCheckout2Top31Disabled: boolean,
    PoPhysicalOrthoFittingCheckout2Top33: string,
    PoPhysicalOrthoFittingCheckout2Top33Disabled: boolean,
    PoPhysicalOrthoFittingCheckout2Top34: boolean,
    PoPhysicalOrthoFittingCheckout2Top34Disabled: boolean,
    PoPhysicalOrthoFittingCheckout2Top36: boolean,
    PoPhysicalOrthoFittingCheckout2Top36Disabled: boolean,
    PoPhysicalOrthoFittingCheckout2Top38: boolean,
    PoPhysicalOrthoFittingCheckout2Top38Disabled: boolean,
}

export const POPhysicalexaminationOrthoticFittingandCheckout2TOPInitialViewData: POPhysicalexaminationOrthoticFittingandCheckout2TOPViewDataType = {
    PoPhysicalOrthoFittingCheckout2Top3: "",
    PoPhysicalOrthoFittingCheckout2Top3Disabled: false,
    Ct2GeneralAppearanceDysfunction: "",
    Ct2GeneralAppearanceDysfunctionDisabled: false,
    Ct2GeneralAppearanceDysfunctionOptions: [{key: 0, value: "A", text: "A"}],
    PoPhysicalOrthoFittingCheckout2Top10: "",
    PoPhysicalOrthoFittingCheckout2Top10Disabled: false,
    Ct5GeneralAppearance: "",
    Ct5GeneralAppearanceDisabled: false,
    Ct5GeneralAppearanceOptions: [{key: 0, value: "A", text: "A"}],
    Ct5Consciousness: "",
    Ct5ConsciousnessDisabled: false,
    Ct5ConsciousnessOptions: [{key: 0, value: "A", text: "A"}],
    Ct8Communication: "",
    Ct8CommunicationDisabled: false,
    Ct8CommunicationOptions: [{key: 0, value: "A", text: "A"}],
    Ct8CoOperation: "",
    Ct8CoOperationDisabled: false,
    Ct8CoOperationOptions: [{key: 0, value: "A", text: "A"}],
    PoPhysicalOrthoFittingCheckout2Top26: "",
    PoPhysicalOrthoFittingCheckout2Top26Disabled: false,
    PoPhysicalOrthoFittingCheckout2Top27: "",
    PoPhysicalOrthoFittingCheckout2Top27Disabled: false,
    PoPhysicalOrthoFittingCheckout2Top28: false,
    PoPhysicalOrthoFittingCheckout2Top28Disabled: false,
    PoPhysicalOrthoFittingCheckout2Top30: "",
    PoPhysicalOrthoFittingCheckout2Top30Disabled: false,
    PoPhysicalOrthoFittingCheckout2Top31: false,
    PoPhysicalOrthoFittingCheckout2Top31Disabled: false,
    PoPhysicalOrthoFittingCheckout2Top33: "",
    PoPhysicalOrthoFittingCheckout2Top33Disabled: false,
    PoPhysicalOrthoFittingCheckout2Top34: false,
    PoPhysicalOrthoFittingCheckout2Top34Disabled: false,
    PoPhysicalOrthoFittingCheckout2Top36: false,
    PoPhysicalOrthoFittingCheckout2Top36Disabled: false,
    PoPhysicalOrthoFittingCheckout2Top38: false,
    PoPhysicalOrthoFittingCheckout2Top38Disabled: false,
}

const Sub = (props: any) => {
    const children = React.Children.toArray(props.children)
    return (
        <div className={styles.container}>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Top1}>
                <div
                    style={{background: "#F3F3F3", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Top3}>
                <Input
                    value={props.viewData.PoPhysicalOrthoFittingCheckout2Top3}
                    disabled={ props.viewData.PoPhysicalOrthoFittingCheckout2Top3Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationOrthoticFittingandCheckout2TOP',
                            name: 'PoPhysicalOrthoFittingCheckout2Top3',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Top5}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>General appearance</strong></p>
                </div>
            </div>
            <div className={styles.Ct2GeneralAppearanceDysfunction}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct2GeneralAppearanceDysfunction}
                    disabled={ props.viewData.Ct2GeneralAppearanceDysfunctionDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "POPhysicalexaminationOrthoticFittingandCheckout2TOP",
                                name: "Ct2GeneralAppearanceDysfunction",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct2GeneralAppearanceDysfunctionOptions}
                />
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Top10}>
                <Input
                    value={props.viewData.PoPhysicalOrthoFittingCheckout2Top10}
                    disabled={ props.viewData.PoPhysicalOrthoFittingCheckout2Top10Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationOrthoticFittingandCheckout2TOP',
                            name: 'PoPhysicalOrthoFittingCheckout2Top10',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.Ct5GeneralAppearance}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct5GeneralAppearance}
                    disabled={ props.viewData.Ct5GeneralAppearanceDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "POPhysicalexaminationOrthoticFittingandCheckout2TOP",
                                name: "Ct5GeneralAppearance",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct5GeneralAppearanceOptions}
                />
            </div>
            <div className={styles.Ct5Consciousness}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct5Consciousness}
                    disabled={ props.viewData.Ct5ConsciousnessDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "POPhysicalexaminationOrthoticFittingandCheckout2TOP",
                                name: "Ct5Consciousness",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct5ConsciousnessOptions}
                />
            </div>
            <div className={styles.Ct8Communication}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct8Communication}
                    disabled={ props.viewData.Ct8CommunicationDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "POPhysicalexaminationOrthoticFittingandCheckout2TOP",
                                name: "Ct8Communication",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct8CommunicationOptions}
                />
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Top20}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Consciousness</strong></p>
                </div>
            </div>
            <div className={styles.Ct8CoOperation}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct8CoOperation}
                    disabled={ props.viewData.Ct8CoOperationDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "POPhysicalexaminationOrthoticFittingandCheckout2TOP",
                                name: "Ct8CoOperation",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct8CoOperationOptions}
                />
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Top24}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Communication</strong></p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Top25}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Co-operation</strong></p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Top26}>
                <Input
                    value={props.viewData.PoPhysicalOrthoFittingCheckout2Top26}
                    disabled={ props.viewData.PoPhysicalOrthoFittingCheckout2Top26Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationOrthoticFittingandCheckout2TOP',
                            name: 'PoPhysicalOrthoFittingCheckout2Top26',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Top27}>
                <Input
                    value={props.viewData.PoPhysicalOrthoFittingCheckout2Top27}
                    disabled={ props.viewData.PoPhysicalOrthoFittingCheckout2Top27Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationOrthoticFittingandCheckout2TOP',
                            name: 'PoPhysicalOrthoFittingCheckout2Top27',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Top28}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalOrthoFittingCheckout2Top28}
                    disabled={ props.viewData.PoPhysicalOrthoFittingCheckout2Top28Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationOrthoticFittingandCheckout2TOP",
                                name: "PoPhysicalOrthoFittingCheckout2Top28"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Top29}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Muscle length</strong></p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Top30}>
                <Input
                    value={props.viewData.PoPhysicalOrthoFittingCheckout2Top30}
                    disabled={ props.viewData.PoPhysicalOrthoFittingCheckout2Top30Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationOrthoticFittingandCheckout2TOP',
                            name: 'PoPhysicalOrthoFittingCheckout2Top30',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Top31}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalOrthoFittingCheckout2Top31}
                    disabled={ props.viewData.PoPhysicalOrthoFittingCheckout2Top31Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationOrthoticFittingandCheckout2TOP",
                                name: "PoPhysicalOrthoFittingCheckout2Top31"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Top32}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Normal</p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Top33}>
                <Input
                    value={props.viewData.PoPhysicalOrthoFittingCheckout2Top33}
                    disabled={ props.viewData.PoPhysicalOrthoFittingCheckout2Top33Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationOrthoticFittingandCheckout2TOP',
                            name: 'PoPhysicalOrthoFittingCheckout2Top33',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Top34}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalOrthoFittingCheckout2Top34}
                    disabled={ props.viewData.PoPhysicalOrthoFittingCheckout2Top34Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationOrthoticFittingandCheckout2TOP",
                                name: "PoPhysicalOrthoFittingCheckout2Top34"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Top35}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Tightness</p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Top36}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalOrthoFittingCheckout2Top36}
                    disabled={ props.viewData.PoPhysicalOrthoFittingCheckout2Top36Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationOrthoticFittingandCheckout2TOP",
                                name: "PoPhysicalOrthoFittingCheckout2Top36"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Top37}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Shortening</p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Top38}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalOrthoFittingCheckout2Top38}
                    disabled={ props.viewData.PoPhysicalOrthoFittingCheckout2Top38Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationOrthoticFittingandCheckout2TOP",
                                name: "PoPhysicalOrthoFittingCheckout2Top38"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Top39}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Contracture</p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoFittingCheckout2Top40}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>N/A</p>
                </div>
            </div>
        </div>
    )
}

export default Sub

const getTsName = (cssClass: string) => {
    return cssClass.split('-').map((s) => (
        s.charAt(0).toUpperCase() + s.slice(1)
    )).join('')
}
