import React from 'react'
import {
   Checkbox,
   Dropdown,
   Input,
} from 'semantic-ui-react'



import styles from './OTPhysicalExaminationPhysicalDysfunctionHandFunctionRight.module.css'

export interface OTPhysicalExaminationPhysicalDysfunctionHandFunctionRightViewDataType {
    CtReach: string,
    CtReachDisabled: boolean,
    CtReachOptions: any,
    OtPhysicalDysfunctionHandFunctionRightRight5: string,
    OtPhysicalDysfunctionHandFunctionRightRight5Disabled: boolean,
    CtGrasp: string,
    CtGraspDisabled: boolean,
    CtGraspOptions: any,
    OtPhysicalDysfunctionHandFunctionRightRight10: string,
    OtPhysicalDysfunctionHandFunctionRightRight10Disabled: boolean,
    CtHoldCarry: string,
    CtHoldCarryDisabled: boolean,
    CtHoldCarryOptions: any,
    OtPhysicalDysfunctionHandFunctionRightRight15: string,
    OtPhysicalDysfunctionHandFunctionRightRight15Disabled: boolean,
    CtRelease: string,
    CtReleaseDisabled: boolean,
    CtReleaseOptions: any,
    OtPhysicalDysfunctionHandFunctionRight20: string,
    OtPhysicalDysfunctionHandFunctionRight20Disabled: boolean,
    CtGraspLarge: string,
    CtGraspLargeDisabled: boolean,
    CtGraspLargeOptions: any,
    OtPhysicalDysfunctionHandFunctionRight27: string,
    OtPhysicalDysfunctionHandFunctionRight27Disabled: boolean,
    CtHookGrasp: string,
    CtHookGraspDisabled: boolean,
    CtHookGraspOptions: any,
    OtPhysicalDysfunctionHandFunctionRight32: string,
    OtPhysicalDysfunctionHandFunctionRight32Disabled: boolean,
    CtCylindrical: string,
    CtCylindricalDisabled: boolean,
    CtCylindricalOptions: any,
    OtPhysicalDysfunctionHandFunctionRight37: string,
    OtPhysicalDysfunctionHandFunctionRight37Disabled: boolean,
    CtSpherical: string,
    CtSphericalDisabled: boolean,
    CtSphericalOptions: any,
    OtPhysicalDysfunctionHandFunctionRight42: string,
    OtPhysicalDysfunctionHandFunctionRight42Disabled: boolean,
    CtTripodPinch: string,
    CtTripodPinchDisabled: boolean,
    CtTripodPinchOptions: any,
    OtPhysicalDysfunctionHandFunctionRight47: string,
    OtPhysicalDysfunctionHandFunctionRight47Disabled: boolean,
    CtLateralPinch: string,
    CtLateralPinchDisabled: boolean,
    CtLateralPinchOptions: any,
    OtPhysicalDysfunctionHandFunctionRight52: string,
    OtPhysicalDysfunctionHandFunctionRight52Disabled: boolean,
    CtPadToPad: string,
    CtPadToPadDisabled: boolean,
    CtPadToPadOptions: any,
    OtPhysicalDysfunctionHandFunctionRight58: string,
    OtPhysicalDysfunctionHandFunctionRight58Disabled: boolean,
    CtTipPinch: string,
    CtTipPinchDisabled: boolean,
    CtTipPinchOptions: any,
    OtPhysicalDysfunctionHandFunctionRight63: string,
    OtPhysicalDysfunctionHandFunctionRight63Disabled: boolean,
    OtPhysicalDysfunctionHandFunctionRight65: string,
    OtPhysicalDysfunctionHandFunctionRight65Disabled: boolean,
    OtPhysicalDysfunctionHandFunctionRight67: string,
    OtPhysicalDysfunctionHandFunctionRight67Disabled: boolean,
    OtPhysicalDysfunctionHandFunctionRight68: boolean,
    OtPhysicalDysfunctionHandFunctionRight68Disabled: boolean,
    OtPhysicalDysfunctionHandFunctionRight71: boolean,
    OtPhysicalDysfunctionHandFunctionRight71Disabled: boolean,
    OtPhysicalDysfunctionHandFunctionRight73: string,
    OtPhysicalDysfunctionHandFunctionRight73Disabled: boolean,
    OtPhysicalDysfunctionHandFunctionRight74: boolean,
    OtPhysicalDysfunctionHandFunctionRight74Disabled: boolean,
    OtPhysicalDysfunctionHandFunctionRight76: string,
    OtPhysicalDysfunctionHandFunctionRight76Disabled: boolean,
    OtPhysicalDysfunctionHandFunctionRight78: boolean,
    OtPhysicalDysfunctionHandFunctionRight78Disabled: boolean,
    OtPhysicalDysfunctionHandFunctionRight79: boolean,
    OtPhysicalDysfunctionHandFunctionRight79Disabled: boolean,
    OtPhysicalDysfunctionHandFunctionRight82: boolean,
    OtPhysicalDysfunctionHandFunctionRight82Disabled: boolean,
    OtPhysicalDysfunctionHandFunctionRight84: string,
    OtPhysicalDysfunctionHandFunctionRight84Disabled: boolean,
    OtPhysicalDysfunctionHandFunctionRight85: boolean,
    OtPhysicalDysfunctionHandFunctionRight85Disabled: boolean,
    OtPhysicalDysfunctionHandFunctionRight86: string,
    OtPhysicalDysfunctionHandFunctionRight86Disabled: boolean,
    OtPhysicalDysfunctionHandFunctionRight88: boolean,
    OtPhysicalDysfunctionHandFunctionRight88Disabled: boolean,
    OtPhysicalDysfunctionHandFunctionRight89: boolean,
    OtPhysicalDysfunctionHandFunctionRight89Disabled: boolean,
    OtPhysicalDysfunctionHandFunctionRight92: boolean,
    OtPhysicalDysfunctionHandFunctionRight92Disabled: boolean,
    OtPhysicalDysfunctionHandFunctionRight94: string,
    OtPhysicalDysfunctionHandFunctionRight94Disabled: boolean,
}

export const OTPhysicalExaminationPhysicalDysfunctionHandFunctionRightInitialViewData: OTPhysicalExaminationPhysicalDysfunctionHandFunctionRightViewDataType = {
    CtReach: "",
    CtReachDisabled: false,
    CtReachOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalDysfunctionHandFunctionRightRight5: "",
    OtPhysicalDysfunctionHandFunctionRightRight5Disabled: false,
    CtGrasp: "",
    CtGraspDisabled: false,
    CtGraspOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalDysfunctionHandFunctionRightRight10: "",
    OtPhysicalDysfunctionHandFunctionRightRight10Disabled: false,
    CtHoldCarry: "",
    CtHoldCarryDisabled: false,
    CtHoldCarryOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalDysfunctionHandFunctionRightRight15: "",
    OtPhysicalDysfunctionHandFunctionRightRight15Disabled: false,
    CtRelease: "",
    CtReleaseDisabled: false,
    CtReleaseOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalDysfunctionHandFunctionRight20: "",
    OtPhysicalDysfunctionHandFunctionRight20Disabled: false,
    CtGraspLarge: "",
    CtGraspLargeDisabled: false,
    CtGraspLargeOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalDysfunctionHandFunctionRight27: "",
    OtPhysicalDysfunctionHandFunctionRight27Disabled: false,
    CtHookGrasp: "",
    CtHookGraspDisabled: false,
    CtHookGraspOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalDysfunctionHandFunctionRight32: "",
    OtPhysicalDysfunctionHandFunctionRight32Disabled: false,
    CtCylindrical: "",
    CtCylindricalDisabled: false,
    CtCylindricalOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalDysfunctionHandFunctionRight37: "",
    OtPhysicalDysfunctionHandFunctionRight37Disabled: false,
    CtSpherical: "",
    CtSphericalDisabled: false,
    CtSphericalOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalDysfunctionHandFunctionRight42: "",
    OtPhysicalDysfunctionHandFunctionRight42Disabled: false,
    CtTripodPinch: "",
    CtTripodPinchDisabled: false,
    CtTripodPinchOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalDysfunctionHandFunctionRight47: "",
    OtPhysicalDysfunctionHandFunctionRight47Disabled: false,
    CtLateralPinch: "",
    CtLateralPinchDisabled: false,
    CtLateralPinchOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalDysfunctionHandFunctionRight52: "",
    OtPhysicalDysfunctionHandFunctionRight52Disabled: false,
    CtPadToPad: "",
    CtPadToPadDisabled: false,
    CtPadToPadOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalDysfunctionHandFunctionRight58: "",
    OtPhysicalDysfunctionHandFunctionRight58Disabled: false,
    CtTipPinch: "",
    CtTipPinchDisabled: false,
    CtTipPinchOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalDysfunctionHandFunctionRight63: "",
    OtPhysicalDysfunctionHandFunctionRight63Disabled: false,
    OtPhysicalDysfunctionHandFunctionRight65: "",
    OtPhysicalDysfunctionHandFunctionRight65Disabled: false,
    OtPhysicalDysfunctionHandFunctionRight67: "",
    OtPhysicalDysfunctionHandFunctionRight67Disabled: false,
    OtPhysicalDysfunctionHandFunctionRight68: false,
    OtPhysicalDysfunctionHandFunctionRight68Disabled: false,
    OtPhysicalDysfunctionHandFunctionRight71: false,
    OtPhysicalDysfunctionHandFunctionRight71Disabled: false,
    OtPhysicalDysfunctionHandFunctionRight73: "",
    OtPhysicalDysfunctionHandFunctionRight73Disabled: false,
    OtPhysicalDysfunctionHandFunctionRight74: false,
    OtPhysicalDysfunctionHandFunctionRight74Disabled: false,
    OtPhysicalDysfunctionHandFunctionRight76: "",
    OtPhysicalDysfunctionHandFunctionRight76Disabled: false,
    OtPhysicalDysfunctionHandFunctionRight78: false,
    OtPhysicalDysfunctionHandFunctionRight78Disabled: false,
    OtPhysicalDysfunctionHandFunctionRight79: false,
    OtPhysicalDysfunctionHandFunctionRight79Disabled: false,
    OtPhysicalDysfunctionHandFunctionRight82: false,
    OtPhysicalDysfunctionHandFunctionRight82Disabled: false,
    OtPhysicalDysfunctionHandFunctionRight84: "",
    OtPhysicalDysfunctionHandFunctionRight84Disabled: false,
    OtPhysicalDysfunctionHandFunctionRight85: false,
    OtPhysicalDysfunctionHandFunctionRight85Disabled: false,
    OtPhysicalDysfunctionHandFunctionRight86: "",
    OtPhysicalDysfunctionHandFunctionRight86Disabled: false,
    OtPhysicalDysfunctionHandFunctionRight88: false,
    OtPhysicalDysfunctionHandFunctionRight88Disabled: false,
    OtPhysicalDysfunctionHandFunctionRight89: false,
    OtPhysicalDysfunctionHandFunctionRight89Disabled: false,
    OtPhysicalDysfunctionHandFunctionRight92: false,
    OtPhysicalDysfunctionHandFunctionRight92Disabled: false,
    OtPhysicalDysfunctionHandFunctionRight94: "",
    OtPhysicalDysfunctionHandFunctionRight94Disabled: false,
}

const Sub = (props: any) => {
    const children = React.Children.toArray(props.children)
    return (
        <div className={styles.container}>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight100}>
                <div
                    style={{backgroundColor: "#F3F3F3", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRightRight1}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Hand function</strong></p>
                </div>
            </div>
            <div className={styles.CtReach}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtReach}
                    disabled={ props.viewData.CtReachDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationPhysicalDysfunctionHandFunctionRight",
                                name: "CtReach",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtReachOptions}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRightRight5}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionHandFunctionRightRight5}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionRightRight5Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPhysicalDysfunctionHandFunctionRight',
                            name: 'OtPhysicalDysfunctionHandFunctionRightRight5',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CtGrasp}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtGrasp}
                    disabled={ props.viewData.CtGraspDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationPhysicalDysfunctionHandFunctionRight",
                                name: "CtGrasp",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtGraspOptions}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRightRight9}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Reach</p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRightRight10}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionHandFunctionRightRight10}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionRightRight10Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPhysicalDysfunctionHandFunctionRight',
                            name: 'OtPhysicalDysfunctionHandFunctionRightRight10',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRightRight11}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Grasp</p>
                </div>
            </div>
            <div className={styles.CtHoldCarry}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtHoldCarry}
                    disabled={ props.viewData.CtHoldCarryDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationPhysicalDysfunctionHandFunctionRight",
                                name: "CtHoldCarry",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtHoldCarryOptions}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRightRight15}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionHandFunctionRightRight15}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionRightRight15Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPhysicalDysfunctionHandFunctionRight',
                            name: 'OtPhysicalDysfunctionHandFunctionRightRight15',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CtRelease}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtRelease}
                    disabled={ props.viewData.CtReleaseDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationPhysicalDysfunctionHandFunctionRight",
                                name: "CtRelease",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtReleaseOptions}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRightRight19}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Hold/Carry</p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight20}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionHandFunctionRight20}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionRight20Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPhysicalDysfunctionHandFunctionRight',
                            name: 'OtPhysicalDysfunctionHandFunctionRight20',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight21}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Release</p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight22}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Hand prehension</strong></p>
                </div>
            </div>
            <div className={styles.CtGraspLarge}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtGraspLarge}
                    disabled={ props.viewData.CtGraspLargeDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationPhysicalDysfunctionHandFunctionRight",
                                name: "CtGraspLarge",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtGraspLargeOptions}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight27}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionHandFunctionRight27}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionRight27Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPhysicalDysfunctionHandFunctionRight',
                            name: 'OtPhysicalDysfunctionHandFunctionRight27',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CtHookGrasp}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtHookGrasp}
                    disabled={ props.viewData.CtHookGraspDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationPhysicalDysfunctionHandFunctionRight",
                                name: "CtHookGrasp",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtHookGraspOptions}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight31}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Grasp-large objects</p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight32}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionHandFunctionRight32}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionRight32Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPhysicalDysfunctionHandFunctionRight',
                            name: 'OtPhysicalDysfunctionHandFunctionRight32',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight33}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Hook grasp</p>
                </div>
            </div>
            <div className={styles.CtCylindrical}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtCylindrical}
                    disabled={ props.viewData.CtCylindricalDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationPhysicalDysfunctionHandFunctionRight",
                                name: "CtCylindrical",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtCylindricalOptions}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight37}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionHandFunctionRight37}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionRight37Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPhysicalDysfunctionHandFunctionRight',
                            name: 'OtPhysicalDysfunctionHandFunctionRight37',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CtSpherical}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtSpherical}
                    disabled={ props.viewData.CtSphericalDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationPhysicalDysfunctionHandFunctionRight",
                                name: "CtSpherical",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtSphericalOptions}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight41}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Cylindrical</p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight42}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionHandFunctionRight42}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionRight42Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPhysicalDysfunctionHandFunctionRight',
                            name: 'OtPhysicalDysfunctionHandFunctionRight42',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight43}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Spherical</p>
                </div>
            </div>
            <div className={styles.CtTripodPinch}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtTripodPinch}
                    disabled={ props.viewData.CtTripodPinchDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationPhysicalDysfunctionHandFunctionRight",
                                name: "CtTripodPinch",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtTripodPinchOptions}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight47}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionHandFunctionRight47}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionRight47Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPhysicalDysfunctionHandFunctionRight',
                            name: 'OtPhysicalDysfunctionHandFunctionRight47',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CtLateralPinch}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtLateralPinch}
                    disabled={ props.viewData.CtLateralPinchDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationPhysicalDysfunctionHandFunctionRight",
                                name: "CtLateralPinch",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtLateralPinchOptions}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight51}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Tripod pinch</p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight52}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionHandFunctionRight52}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionRight52Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPhysicalDysfunctionHandFunctionRight',
                            name: 'OtPhysicalDysfunctionHandFunctionRight52',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight54}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Lateral pinch</p>
                </div>
            </div>
            <div className={styles.CtPadToPad}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtPadToPad}
                    disabled={ props.viewData.CtPadToPadDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationPhysicalDysfunctionHandFunctionRight",
                                name: "CtPadToPad",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtPadToPadOptions}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight58}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionHandFunctionRight58}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionRight58Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPhysicalDysfunctionHandFunctionRight',
                            name: 'OtPhysicalDysfunctionHandFunctionRight58',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CtTipPinch}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtTipPinch}
                    disabled={ props.viewData.CtTipPinchDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationPhysicalDysfunctionHandFunctionRight",
                                name: "CtTipPinch",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtTipPinchOptions}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight62}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Pad to pad pinch</p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight63}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionHandFunctionRight63}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionRight63Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPhysicalDysfunctionHandFunctionRight',
                            name: 'OtPhysicalDysfunctionHandFunctionRight63',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight64}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Tip pinch</p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight65}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionHandFunctionRight65}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionRight65Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPhysicalDysfunctionHandFunctionRight',
                            name: 'OtPhysicalDysfunctionHandFunctionRight65',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight66}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Hand dexterity</strong></p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight67}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionHandFunctionRight67}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionRight67Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPhysicalDysfunctionHandFunctionRight',
                            name: 'OtPhysicalDysfunctionHandFunctionRight67',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight68}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtPhysicalDysfunctionHandFunctionRight68}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionRight68Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "OTPhysicalExaminationPhysicalDysfunctionHandFunctionRight",
                                name: "OtPhysicalDysfunctionHandFunctionRight68"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight69}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Grip strength</strong></p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight70}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>N/A</p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight71}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtPhysicalDysfunctionHandFunctionRight71}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionRight71Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "OTPhysicalExaminationPhysicalDysfunctionHandFunctionRight",
                                name: "OtPhysicalDysfunctionHandFunctionRight71"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight72}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>N/A</p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight73}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionHandFunctionRight73}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionRight73Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPhysicalDysfunctionHandFunctionRight',
                            name: 'OtPhysicalDysfunctionHandFunctionRight73',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight74}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtPhysicalDysfunctionHandFunctionRight74}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionRight74Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "OTPhysicalExaminationPhysicalDysfunctionHandFunctionRight",
                                name: "OtPhysicalDysfunctionHandFunctionRight74"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight75}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Pinch strength</strong></p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight76}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionHandFunctionRight76}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionRight76Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPhysicalDysfunctionHandFunctionRight',
                            name: 'OtPhysicalDysfunctionHandFunctionRight76',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight77}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Tripod pinch</p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight78}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtPhysicalDysfunctionHandFunctionRight78}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionRight78Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "OTPhysicalExaminationPhysicalDysfunctionHandFunctionRight",
                                name: "OtPhysicalDysfunctionHandFunctionRight78"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight79}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtPhysicalDysfunctionHandFunctionRight79}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionRight79Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "OTPhysicalExaminationPhysicalDysfunctionHandFunctionRight",
                                name: "OtPhysicalDysfunctionHandFunctionRight79"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight80}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>N/A</p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight81}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Lateral pinch</p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight82}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtPhysicalDysfunctionHandFunctionRight82}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionRight82Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "OTPhysicalExaminationPhysicalDysfunctionHandFunctionRight",
                                name: "OtPhysicalDysfunctionHandFunctionRight82"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight83}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>N/A</p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight84}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionHandFunctionRight84}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionRight84Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPhysicalDysfunctionHandFunctionRight',
                            name: 'OtPhysicalDysfunctionHandFunctionRight84',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight85}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtPhysicalDysfunctionHandFunctionRight85}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionRight85Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "OTPhysicalExaminationPhysicalDysfunctionHandFunctionRight",
                                name: "OtPhysicalDysfunctionHandFunctionRight85"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight86}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionHandFunctionRight86}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionRight86Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPhysicalDysfunctionHandFunctionRight',
                            name: 'OtPhysicalDysfunctionHandFunctionRight86',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight87}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Pad to pad pinch</p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight88}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtPhysicalDysfunctionHandFunctionRight88}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionRight88Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "OTPhysicalExaminationPhysicalDysfunctionHandFunctionRight",
                                name: "OtPhysicalDysfunctionHandFunctionRight88"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight89}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtPhysicalDysfunctionHandFunctionRight89}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionRight89Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "OTPhysicalExaminationPhysicalDysfunctionHandFunctionRight",
                                name: "OtPhysicalDysfunctionHandFunctionRight89"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight90}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>N/A</p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight91}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Tip pinch</p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight92}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtPhysicalDysfunctionHandFunctionRight92}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionRight92Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "OTPhysicalExaminationPhysicalDysfunctionHandFunctionRight",
                                name: "OtPhysicalDysfunctionHandFunctionRight92"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight93}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>N/A</p>
                </div>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight94}>
                <Input
                    value={props.viewData.OtPhysicalDysfunctionHandFunctionRight94}
                    disabled={ props.viewData.OtPhysicalDysfunctionHandFunctionRight94Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPhysicalDysfunctionHandFunctionRight',
                            name: 'OtPhysicalDysfunctionHandFunctionRight94',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalDysfunctionHandFunctionRight95}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Other</strong></p>
                </div>
            </div>
        </div>
    )
}

export default Sub

const getTsName = (cssClass: string) => {
    return cssClass.split('-').map((s) => (
        s.charAt(0).toUpperCase() + s.slice(1)
    )).join('')
}
