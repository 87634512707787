import React from "react";
import { IntlProvider, FormattedMessage, useIntl } from "react-intl";

import { Input, Dropdown, Label } from "semantic-ui-react";

const CardAdmitChangeUX = (props: any) => {
  return (
    <div>
      <div style={{ height: "30vh", width: "100%", padding: "10px" }}>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "grid",
            position: "relative",
            gridTemplateRows: "repeat(5, 1fr)",
            gridTemplateColumns: "repeat(16, 1fr)",
          }}
        >
          <div style={{ gridRow: "1/2", gridColumn: "1/3" }}>
            <Label className="large title" color="blue" ribbon={true}>
              {props.title}
            </Label>
          </div>
          <div
            style={{
              gridRow: "2/3",
              gridColumn: "1/3",
              textAlign: "right",
              padding: "10px 10px 0px 0px",
              fontWeight: "bold",
            }}
          >
            <label>HN</label>
            <label style={{ color: "red" }}>*</label>
          </div>
          <div style={{ gridRow: "2/3", gridColumn: "3/6" }}>
            {props.patientSearchBox}
          </div>
          <div
            style={{
              gridRow: "2/3",
              gridColumn: "6/8",
              textAlign: "right",
              padding: "10px 10px 0px 0px",
              fontWeight: "bold",
            }}
          >
            <label>ชื่อ-สกุล</label>
          </div>
          <div style={{ gridRow: "2/3", gridColumn: "8/12" }}>
            <Input
              fluid={true}
              readOnly={true}
              value={props.AdmitChangeSequence?.fullName || ""}
            ></Input>
          </div>
          <div
            style={{
              gridRow: "2/3",
              gridColumn: "12/14",
              textAlign: "right",
              padding: "10px 10px 0px 0px",
              fontWeight: "bold",
            }}
          >
            <label>วัน/เดือน/ปีเกิด</label>
          </div>
          <div style={{ gridRow: "2/3", gridColumn: "14/17" }}>
            <Input
              fluid={true}
              readOnly={true}
              value={props.AdmitChangeSequence?.birthdate || ""}
            ></Input>
          </div>
          <div
            style={{
              gridRow: "3/4",
              gridColumn: "1/3",
              textAlign: "right",
              padding: "10px 10px 0px 0px",
              fontWeight: "bold",
            }}
          >
            <label>เลขห้องเดิม</label>
            <label style={{ color: "red" }}>*</label>
          </div>
          <div style={{ gridRow: "3/4", gridColumn: "3/6" }}>
            <Input
              fluid={true}
              readOnly={true}
              value={props.AdmitChangeSequence?.oldRoom || ""}
            ></Input>
          </div>
          <div
            style={{
              gridRow: "3/4",
              gridColumn: "6/8",
              textAlign: "right",
              padding: "10px 10px 0px 0px",
              fontWeight: "bold",
            }}
          >
            <label>ประเภทหอผู้ป่วย</label>
          </div>
          <div style={{ gridRow: "3/4", gridColumn: "8/12" }}>
            <Input
              fluid={true}
              readOnly={true}
              value={props.AdmitChangeSequence?.oldWardType || ""}
            ></Input>
          </div>
          <div
            style={{
              gridRow: "3/4",
              gridColumn: "12/14",
              textAlign: "right",
              padding: "10px 10px 0px 0px",
              fontWeight: "bold",
              display: props.action !== "CHANGE_BED" ? "none" : "",
            }}
          >
            <label>ทำความสะอาด</label>
            <label style={{ color: "red" }}>*</label>
          </div>
          <div
            style={{
              gridRow: "3/4",
              gridColumn: "14/17",
              display: props.action !== "CHANGE_BED" ? "none" : "",
            }}
          >
            <Dropdown
              clearable={true}
              fluid={true}
              onChange={props.onChangeCleaning}
              options={props.AdmitChangeSequence?.cleaningOptions}
              search={true}
              selection={true}
              value={props.AdmitChangeSequence?.cleaning}
            ></Dropdown>
          </div>
          <div
            style={{
              gridRow: "4/5",
              gridColumn: "1/3",
              textAlign: "right",
              padding: "10px 10px 0px 0px",
              fontWeight: "bold",
            }}
          >
            <label>เลขห้องใหม่</label>
            <label style={{ color: "red" }}>*</label>
          </div>
          <div style={{ gridRow: "4/5", gridColumn: "3/6" }}>
            <Dropdown
              clearable={true}
              fluid={true}
              onChange={props.onChangeNewRoom}
              options={props.AdmitChangeSequence?.newRoomOptions}
              search={true}
              selection={true}
              style={{ display: props.action !== "CHANGE_BED" ? "none" : "" }}
              value={props.AdmitChangeSequence?.room}
            ></Dropdown>
            <div
              style={{
                display: props.action !== "RESERVE_TRANSFER" ? "none" : "",
              }}
            >
              {props.roomSearchBox}
            </div>
          </div>
          <div
            style={{
              gridRow: "4/5",
              gridColumn: "6/8",
              textAlign: "right",
              padding: "10px 10px 0px 0px",
              fontWeight: "bold",
            }}
          >
            <label>ประเภทหอผู้ป่วย</label>
          </div>
          <div style={{ gridRow: "4/5", gridColumn: "8/12" }}>
            <Input
              fluid={true}
              readOnly={true}
              value={props.AdmitChangeSequence?.newWardType || ""}
            ></Input>
          </div>
          <div
            style={{
              gridRow: "4/5",
              gridColumn: "12/14",
              textAlign: "right",
              padding: "10px 10px 0px 0px",
              fontWeight: "bold",
            }}
          >
            <label>สถานะห้อง</label>
          </div>
          <div style={{ gridRow: "4/5", gridColumn: "14/17" }}>
            <Input
              fluid={true}
              readOnly={true}
              value={props.AdmitChangeSequence?.newRoomStatus || ""}
            ></Input>
          </div>
          <div style={{ gridRow: "5/6", gridColumn: "14/17" }}>
            {props.buttonSave}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardAdmitChangeUX;

export const screenPropsDefault = {};

/* Date Time : Fri Oct 14 2022 10:21:38 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height: \"30vh\", width: \"100%\", padding: \"10px\"}"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "label": "divgrid",
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(5, 1fr)\",\"gridTemplateColumns\":\"repeat(16, 1fr)\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"1/3\", textAlign: \"right\", padding: \"10px 10px 0px 0px\", fontWeight: \"bold\" }"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"3/6\" }"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"6/8\", textAlign: \"right\", padding: \"10px 10px 0px 0px\", fontWeight: \"bold\" }"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"8/12\" }"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"12/14\", textAlign: \"right\", padding: \"10px 10px 0px 0px\", fontWeight: \"bold\" }"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"14/17\" }"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "label",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "HN"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "label",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ-สกุล"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "label",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": "วัน/เดือน/ปีเกิด"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "Input",
      "parent": 18,
      "props": {
        "disabled": {
          "type": "value",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.AdmitChangeSequence?.fullName || \"\""
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "Input",
      "parent": 20,
      "props": {
        "disabled": {
          "type": "value",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.AdmitChangeSequence?.birthdate || \"\""
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"1/3\", textAlign: \"right\", padding: \"10px 10px 0px 0px\", fontWeight: \"bold\" }"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"3/6\" }"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"6/8\", textAlign: \"right\", padding: \"10px 10px 0px 0px\", fontWeight: \"bold\" }"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"8/12\" }"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"12/14\", textAlign: \"right\", padding: \"10px 10px 0px 0px\", fontWeight: \"bold\", display: props.action !== \"CHANGE_BED\" ? \"none\" : \"\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"14/17\", display: props.action !== \"CHANGE_BED\" ? \"none\" : \"\" }"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "label",
      "parent": 39,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขห้องเดิม"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "label",
      "parent": 39,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "label",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "label",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทหอผู้ป่วย"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "label",
      "parent": 43,
      "props": {
        "children": {
          "type": "value",
          "value": "ทำความสะอาด"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "label",
      "parent": 43,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "Input",
      "parent": 40,
      "props": {
        "disabled": {
          "type": "value",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.AdmitChangeSequence?.oldRoom || \"\""
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "Input",
      "parent": 42,
      "props": {
        "disabled": {
          "type": "value",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.AdmitChangeSequence?.oldWardType || \"\""
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "Dropdown",
      "parent": 44,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeCleaning"
        },
        "options": {
          "type": "code",
          "value": "props.AdmitChangeSequence?.cleaningOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.AdmitChangeSequence?.cleaning"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "Label",
      "parent": 57,
      "props": {
        "children": {
          "type": "code",
          "value": "props.title"
        },
        "className": {
          "type": "value",
          "value": "large title"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "ribbon": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/3\" }"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"1/3\", textAlign: \"right\", padding: \"10px 10px 0px 0px\", fontWeight: \"bold\" }"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"3/6\" }"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"6/8\", textAlign: \"right\", padding: \"10px 10px 0px 0px\", fontWeight: \"bold\" }"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"8/12\" }"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"12/14\", textAlign: \"right\", padding: \"10px 10px 0px 0px\", fontWeight: \"bold\" }"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"14/17\" }"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "label",
      "parent": 70,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขห้องใหม่"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "label",
      "parent": 72,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทหอผู้ป่วย"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "label",
      "parent": 74,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะห้อง"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "label",
      "parent": 70,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ color: \"red\" }"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 80,
      "name": "Dropdown",
      "parent": 71,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeNewRoom"
        },
        "options": {
          "type": "code",
          "value": "props.AdmitChangeSequence?.newRoomOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.action !== \"CHANGE_BED\" ? \"none\" : \"\" }"
        },
        "value": {
          "type": "code",
          "value": "props.AdmitChangeSequence?.room"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 81,
      "name": "Input",
      "parent": 73,
      "props": {
        "disabled": {
          "type": "value",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.AdmitChangeSequence?.newWardType || \"\""
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "Input",
      "parent": 75,
      "props": {
        "disabled": {
          "type": "value",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.AdmitChangeSequence?.newRoomStatus || \"\""
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"14/17\" }"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": null,
      "id": 86,
      "name": "div",
      "parent": 71,
      "props": {
        "children": {
          "type": "code",
          "value": "props.roomSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.action !== \"RESERVE_TRANSFER\" ? \"none\" : \"\" }"
        }
      },
      "seq": 86,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardAdmitChangeUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
