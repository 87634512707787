// Port from UtilMSD.js (qml)

export const STATUS_COLOR = {
    '':            'black',
    'REQUESTED':   'red',
    'VERIFIED':    'yellow',
    'APPROVED':    'yellow',
    'PRINTED':     'yellow',
    'CHECKED':     'green',
    'DELIVERED':   'blue',
    'TRANSPORTED': 'blue',
    'RECEIVED':    'violet',
    'REJECTED':    'gray',
    'CANCELED':    'gray',
}

export function get_status_color(status) {
    return STATUS_COLOR[status] || STATUS_COLOR['']
}

export const TYPE_COLOR = {
    '':             'black',
    'STAT':         'red',
    'ONE_DAY':      'olive',
    'HOME_OPD':     'violet',
    'HOME_IPD':     'violet',
    'REFILL_OPD':   'teal',
    'REFILL_IPD':   'teal',
    'COST_CENTER':  'brown',
}

export function get_type_color(status) {
    return TYPE_COLOR[status] || TYPE_COLOR['']
}
