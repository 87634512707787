import BaseService from "./BaseService";
import { LAB } from "../../configs/apis";
class LABService extends BaseService {
  /**
   * get patient data by hn
   * @param {encounter_id} int
   */
  getInterfaceSummaryReportDetail({ cloi }) {
    const url = LAB.INTERFACE_SUMMARY_REPORT_DETAIL({ cloi: cloi });
    return this.client
      .get(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getCentalLabTestResultFitlerList(params) {
    return this.client
      .get(LAB.CENTRAL_LABTEST_RESULT_FILTER, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getCenterLabDivision() {
    return this.client
      .get(LAB.CENTRAL_LAB_DIVISION)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  postCenterLabResultComparable(params) {
    return this.client
      .post(LAB.CENTRAL_LAB_RESULT_COMPARABLE, { ...params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getCentralLabResultList(params) {
    return this.client
    .get(LAB.CENTRAL_LAB_RESULT_LIST, { params })
    .then(this.handleResponse)
    .catch(this.throwErrorMessage);
  }
}
export default LABService;
