import React from 'react'
import {
   Button,
   Checkbox,
   Dropdown,
   Input,
   TextArea
} from 'semantic-ui-react'

import styles from './PTprogressionnoteOPDCardioNeuro.module.css'

export interface PTprogressionnoteOPDCardioNeuroViewDataType {
    PtProgressnoteOpdCardioNeuro6: string,
    PtProgressnoteOpdCardioNeuro6Disabled: boolean,
    PtProgressnoteOpdCardioNeuro8: string,
    PtProgressnoteOpdCardioNeuro8Disabled: boolean,
    PtProgressnoteOpdCardioNeuro10: string,
    PtProgressnoteOpdCardioNeuro10Disabled: boolean,
    PtProgressnoteOpdCardioNeuro11: boolean,
    PtProgressnoteOpdCardioNeuro11Disabled: boolean,
    PtProgressnoteOpdCardioNeuro13: boolean,
    PtProgressnoteOpdCardioNeuro13Disabled: boolean,
    PtProgressnoteOpdCardioNeuro16: string,
    PtProgressnoteOpdCardioNeuro16Disabled: boolean,
    Ct2DischargeToday: string,
    Ct2DischargeTodayDisabled: boolean,
    Ct2DischargeTodayOptions: any,
    PtProgressnoteOpdCardioNeuro22: string,
    PtProgressnoteOpdCardioNeuro22Disabled: boolean,
    PtProgressnoteOpdCardioNeuro24: boolean,
    PtProgressnoteOpdCardioNeuro24Disabled: boolean,
    PtProgressnoteOpdCardioNeuro27: boolean,
    PtProgressnoteOpdCardioNeuro27Disabled: boolean,
    PtProgressnoteOpdCardioNeuro30: string,
    PtProgressnoteOpdCardioNeuro30Disabled: boolean,
    PtProgressnoteOpdCardioNeuro31: string,
    PtProgressnoteOpdCardioNeuro31Disabled: boolean,
    PtProgressnoteOpdCardioNeuro32: string,
    PtProgressnoteOpdCardioNeuro32Disabled: boolean,
    PtProgressnoteOpdCardioNeuro37: string,
    PtProgressnoteOpdCardioNeuro37Disabled: boolean,
    PtProgressnoteOpdCardioNeuro38: string,
    PtProgressnoteOpdCardioNeuro38Disabled: boolean,
    SaveRequestDisabled: boolean,
}

export const PTprogressionnoteOPDCardioNeuroInitialViewData: PTprogressionnoteOPDCardioNeuroViewDataType = {
    PtProgressnoteOpdCardioNeuro6: "",
    PtProgressnoteOpdCardioNeuro6Disabled: false,
    PtProgressnoteOpdCardioNeuro8: "",
    PtProgressnoteOpdCardioNeuro8Disabled: false,
    PtProgressnoteOpdCardioNeuro10: "",
    PtProgressnoteOpdCardioNeuro10Disabled: false,
    PtProgressnoteOpdCardioNeuro11: false,
    PtProgressnoteOpdCardioNeuro11Disabled: false,
    PtProgressnoteOpdCardioNeuro13: false,
    PtProgressnoteOpdCardioNeuro13Disabled: false,
    PtProgressnoteOpdCardioNeuro16: "",
    PtProgressnoteOpdCardioNeuro16Disabled: false,
    Ct2DischargeToday: "",
    Ct2DischargeTodayDisabled: false,
    Ct2DischargeTodayOptions: [{key: 0, value: "A", text: "A"}],
    PtProgressnoteOpdCardioNeuro22: "",
    PtProgressnoteOpdCardioNeuro22Disabled: false,
    PtProgressnoteOpdCardioNeuro24: false,
    PtProgressnoteOpdCardioNeuro24Disabled: false,
    PtProgressnoteOpdCardioNeuro27: false,
    PtProgressnoteOpdCardioNeuro27Disabled: false,
    PtProgressnoteOpdCardioNeuro30: "",
    PtProgressnoteOpdCardioNeuro30Disabled: false,
    PtProgressnoteOpdCardioNeuro31: "",
    PtProgressnoteOpdCardioNeuro31Disabled: false,
    PtProgressnoteOpdCardioNeuro32: "",
    PtProgressnoteOpdCardioNeuro32Disabled: false,
    PtProgressnoteOpdCardioNeuro37: "",
    PtProgressnoteOpdCardioNeuro37Disabled: false,
    PtProgressnoteOpdCardioNeuro38: "",
    PtProgressnoteOpdCardioNeuro38Disabled: false,
    SaveRequestDisabled: false,
}

const Sub = (props: any) => {
    // const children = React.Children.toArray(props.children)
    return (
        <div className={styles.container}>
            <div className={styles.PtProgressnoteOpdCardioNeuro4}>
                <div
                    style={{background: "#F3F3F3", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.PtProgressnoteOpdCardioNeuro5}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Problem/ Physical therapy diagnosis*</strong></p>
                </div>
            </div>
            <div className={styles.PtProgressnoteOpdCardioNeuro6}>
                <Input
                    value={props.viewData.PtProgressnoteOpdCardioNeuro6}
                    disabled={ props.viewData.PtProgressnoteOpdCardioNeuro6Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTprogressionnoteOPDCardioNeuro',
                            name: 'PtProgressnoteOpdCardioNeuro6',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtProgressnoteOpdCardioNeuro8}>
                <TextArea
                    value={props.viewData.PtProgressnoteOpdCardioNeuro8}
                    disabled={ props.viewData.PtProgressnoteOpdCardioNeuro8Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTprogressionnoteOPDCardioNeuro',
                            name: 'PtProgressnoteOpdCardioNeuro8',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </TextArea>
            </div>
            <div className={styles.PtProgressnoteOpdCardioNeuro9}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Treatment*</strong></p>
                </div>
            </div>
            <div className={styles.PtProgressnoteOpdCardioNeuro10}>
                <Input
                    value={props.viewData.PtProgressnoteOpdCardioNeuro10}
                    disabled={ props.viewData.PtProgressnoteOpdCardioNeuro10Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTprogressionnoteOPDCardioNeuro',
                            name: 'PtProgressnoteOpdCardioNeuro10',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtProgressnoteOpdCardioNeuro11}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtProgressnoteOpdCardioNeuro11}
                    disabled={ props.viewData.PtProgressnoteOpdCardioNeuro11Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTprogressionnoteOPDCardioNeuro",
                                name: "PtProgressnoteOpdCardioNeuro11"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtProgressnoteOpdCardioNeuro12}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Plan</strong></p>
                </div>
            </div>
            <div className={styles.PtProgressnoteOpdCardioNeuro13}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtProgressnoteOpdCardioNeuro13}
                    disabled={ props.viewData.PtProgressnoteOpdCardioNeuro13Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTprogressionnoteOPDCardioNeuro",
                                name: "PtProgressnoteOpdCardioNeuro13"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtProgressnoteOpdCardioNeuro14}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Other</p>
                </div>
            </div>
            <div className={styles.PtProgressnoteOpdCardioNeuro15}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>continue program</p>
                </div>
            </div>
            <div className={styles.PtProgressnoteOpdCardioNeuro16}>
                <Input
                    value={props.viewData.PtProgressnoteOpdCardioNeuro16}
                    disabled={ props.viewData.PtProgressnoteOpdCardioNeuro16Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTprogressionnoteOPDCardioNeuro',
                            name: 'PtProgressnoteOpdCardioNeuro16',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtProgressnoteOpdCardioNeuro17}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Note</strong></p>
                </div>
            </div>
            <div className={styles.Ct2DischargeToday}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct2DischargeToday}
                    disabled={ props.viewData.Ct2DischargeTodayDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "PTprogressionnoteOPDCardioNeuro",
                                name: "Ct2DischargeToday",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct2DischargeTodayOptions}
                />
            </div>
            <div className={styles.PtProgressnoteOpdCardioNeuro21}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Discharge status today</strong></p>
                </div>
            </div>
            <div className={styles.PtProgressnoteOpdCardioNeuro22}>
                <Input
                    value={props.viewData.PtProgressnoteOpdCardioNeuro22}
                    disabled={ props.viewData.PtProgressnoteOpdCardioNeuro22Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTprogressionnoteOPDCardioNeuro',
                            name: 'PtProgressnoteOpdCardioNeuro22',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtProgressnoteOpdCardioNeuro23}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Instruction*</strong></p>
                </div>
            </div>
            <div className={styles.PtProgressnoteOpdCardioNeuro24}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtProgressnoteOpdCardioNeuro24}
                    disabled={ props.viewData.PtProgressnoteOpdCardioNeuro24Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTprogressionnoteOPDCardioNeuro",
                                name: "PtProgressnoteOpdCardioNeuro24"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtProgressnoteOpdCardioNeuro25}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Patient and/or family was/were informed about instruction and plan of treatment</p>
                </div>
            </div>
            <div className={styles.PtProgressnoteOpdCardioNeuro27}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtProgressnoteOpdCardioNeuro27}
                    disabled={ props.viewData.PtProgressnoteOpdCardioNeuro27Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTprogressionnoteOPDCardioNeuro",
                                name: "PtProgressnoteOpdCardioNeuro27"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtProgressnoteOpdCardioNeuro28}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Need to be reviewed</p>
                </div>
            </div>
            <div className={styles.SaveRequest}>
                <Button
                    disabled={ props.viewData.SaveRequestDisabled }
                    onClick={(e) => { props.onEvent({
                        message: 'clickButton',
                        params: {
                            view: 'PTprogressionnoteOPDCardioNeuro',
                            name: 'SaveRequest'
                        }
                    })}}
                    color="green"
                    style={{height: "100%", width: "100%"}}
                >
                    Save
                </Button>
            </div>
        </div>
    )
}

export default Sub

// const getTsName = (cssClass: string) => {
//     return cssClass.split('-').map((s) => (
//         s.charAt(0).toUpperCase() + s.slice(1)
//     )).join('')
// }
