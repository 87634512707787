import React, { useState, useEffect, useRef } from "react";
import {formatDatetime} from "react-lib/utils/dateUtils";
import { Loader } from "semantic-ui-react";
import { useIntl } from "react-intl";

interface CardCSPatientCareFlowSheetProps {
  csPatientCareList: any;
  patientDataAPI: any;
  handleGetCSPatientCare: any;
}

// Prepare for filter frontend
const bpName = [
  {
    name: "Diastolic",
    vitalsign_type: 7,
  },
  {
    name: "Systolic",
    italsign_type: 6,
  },
];

const vsName = [
  {
    name: "ส่วนสูง",
    vitalsign_type: 2,
  },
  {
    name: "น้ำหนัก",
    vitalsign_type: 1,
  },
  {
    name: "BMI",
    vitalsign_type: 3,
  },
  {
    name: "อุณหภูมิ",
    vitalsign_type: 8,
  },
  {
    name: "Pulse Rate",
    vitalsign_type: 5,
  },
  {
    name: "Respiratory",
    vitalsign_type: 4,
  },
];

const CardCSPatientCareFlowSheet = (props: CardCSPatientCareFlowSheetProps) => {
  const isMounted = useRef(true);
  const [isPatientCareLoading, setIsPatientCareLoading] = useState(false);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const loadPatientCare = async () => {
      setIsPatientCareLoading(true);
      await props.handleGetCSPatientCare();
      setIsPatientCareLoading(false);
    };
    loadPatientCare();
  }, [props.patientDataAPI]);

  // console.log(
  //   "CardCSPatientCareFlowSheet:  props.csPatientCareList",
  //   props.csPatientCareList
  // );

  const vitalSignRows = () => {
    if (props.csPatientCareList?.items?.length > 0) {
      return props.csPatientCareList?.items?.map((item, index) => {
        return (
          <div key={index} className="row-content-text">
            <div>{item.name}</div>
            <div>{item.formatted_string + " " + item.unit}</div>
            <div className="row-right">{formatDatetime(item.created)}</div>
          </div>
        );
      });
    } else {
      return <div className="row-content-text">No data</div>;
    }
  };
  return (
    <div className="card-cs card-cs-patient-care" style={{
        ...( isPatientCareLoading && { 
          opacity:" 0.5",
          top:0,
          left:0,
          width:"100%",
          height:"100%",
        })}
      }>

      <>
        <div className="patient-card-header">Patient Care Flowsheet</div>
        <div className="patient-flow-sheet">
          {/* <div className="row-content-header">
            <div className="row-left">Vital Signs</div>
            {props.csPatientCareList?.items?.length > 0 ? (
              <div className="row-right">{ isPatientCareLoading ? null : props.csPatientCareList?.items[0].created}</div>
            ) : null}
          </div> */}
          { isPatientCareLoading ? <div style={{textAlign: "center"}}> Loading... </div>: vitalSignRows()}
        </div>
      </>
    </div>
  );
};

export default CardCSPatientCareFlowSheet;
