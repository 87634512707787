import WasmController from 'react-lib/frameworks/WasmController';
import UserPermissionView from "issara-sdk/apis/UserPermissionView_users";
import UserList from "issara-sdk/apis/UserList_users";
import UserProfileAPI from "issara-sdk/apis/UserProfileAPI_users";
import PositionList from "issara-sdk/apis/PositionList_users";
import UserWithPatientDetail from "issara-sdk/apis/UserWithPatientDetail_users";
import UserTokenizeView from "issara-sdk/apis/UserTokenizeView_users"
import UserDetailAPIView from "issara-sdk/apis/UserDetailAPIView_users";
import UserLoginView from "issara-sdk/apis/UserLoginView_core";
import UserEmployeeDetailAPIView from "issara-sdk/apis/UserEmployeeDetailAPIView_users";

import { formatDateToYYYYMMDD } from 'react-lib/utils';
import { useParams } from 'react-router';

export type State =
  {
  }

export const StateInitial: State =
  {
  }

export type Event =
  { message: "getUserPermission", params: any }
  |  { message: "getUserTokenize", params: any }
  |  { message: "getUserTokenizeLicense", params: any }
  |  { message: "getUsers", params: any }
  |  { message: "getUserDetail", params: any }
  |  { message: "postUserLogin", params: any }
  |  { message: "getUserEmployee", params: any }
  |  { message: "getUserProfile", params: any }
  |  { message: "updateUserProfile", params: any }
  |  { message: "getUserPosition", params: any }
  |  { message: "getUserWithPatient", params: any }
  |  { message: "postUserSetPassword", params: any }

export type Data =
  {
    division?: number,
  }

export const DataInitial =
  {
  }

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any

export const getUserPermission: Handler = async (controller, params) => {
  const result = await UserPermissionView.post({
    data: params,
    apiToken: controller.apiToken
  });
  return [result[0] || [], result[1]];
}

export const getUserTokenize: Handler = async (controller, {code}) => {
  let data = {}

  if(code){
    data["code"] = code
  }

  const [response, error, network] = await UserTokenizeView.post({
    apiToken: controller.apiToken,
    data
  })

  return [response, error, network]
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, code = _b.code, apiToken = _b.apiToken;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, tokenData, tokenError, userService, _c, error, response;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            tokenData = [];
//                            tokenError = null;
//                            userService = new UserService_1.default();
//                            if (code) {
//                                params.code = code;
//                            }
//                            return [4 /*yield*/, utils_1.to(userService.getUserTokenize(params, apiToken))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1];
//                            if (response) {
//                                tokenData = response;
//                            }
//                            else {
//                                tokenError = error;
//                            }
//                            return [2 /*return*/, [tokenData, tokenError]];
//                    }
//                });
//            });
//        }
//function (params, apiToken) {
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        return this.client
//            .post(apis_1.USER.USER_TOKENIZE, params)
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/users/apis/users/tokenize/
}

export const getUserTokenizeLicense: Handler = async (controller, {}) => {
//function (_a) {
//            var code = _a.code;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, tokenData, tokenError, userService, _b, error, response;
//                return __generator(this, function (_c) {
//                    switch (_c.label) {
//                        case 0:
//                            params = {};
//                            tokenData = [];
//                            tokenError = null;
//                            userService = new UserService_1.default();
//                            if (code) {
//                                params.code = code;
//                            }
//                            return [4 /*yield*/, utils_1.to(userService.getUserTokenizeLicense(params))];
//                        case 1:
//                            _b = _c.sent(), error = _b[0], response = _b[1];
//                            if (response) {
//                                tokenData = response;
//                            }
//                            else {
//                                tokenError = error;
//                            }
//                            return [2 /*return*/, [tokenData, tokenError]];
//                    }
//                });
//            });
//        }
//function (params) {
//        return this.client
//            .post(apis_1.USER.USER_TOKENIZE_LICENSE, params)
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/users/apis/users/tokenize-license/
}

export const getUsers: Handler = async (controller, {nameUserName, nurseOrDoctor, role, fullName}) => {
  let params: any = {};
  if (nameUserName) {
    params.name_username = nameUserName;
  }
  if (nurseOrDoctor) {
    params.nurse_or_doctor = nurseOrDoctor;
  }
  if (role) {
    params.role = role;
  }
  if (fullName) {
    params.full_name = fullName;
  }
  const result = await UserList.list({
    params: params,
    apiToken: controller.apiToken
  });
  return [result[0]?.items || [], result[1]]
}

export const getUserProfile: Handler = async (controller, { apiToken }) => {
  let params: any = {};
  return await UserProfileAPI.retrieve({
    params: params,
    apiToken: controller.apiToken
  });
}

export const getUserDetail: Handler = async (controller, { id }) => {
  return await UserDetailAPIView.retrieve({
    pk: id,
    apiToken: controller.apiToken,
    extra: { division: controller.data.division },
  });
}

export const postUserLogin: Handler = async (controller, params) => {
  return await UserLoginView.post({
    data: params,
    apiToken: controller.apiToken,
    extra: { division: controller.data.division },
  });
};

export const getUserEmployee: Handler = async (controller, { id }) => {
  return await UserEmployeeDetailAPIView.retrieve({
    pk: id,
    apiToken: controller.apiToken,
    extra: { division: controller.data.division },
  });
};

export const updateUserProfile: Handler = async (controller, { apiToken, firstName, lastName, dob, phone }) => {
  let data: any = {
    first_name: firstName,
    last_name: lastName,
    phone_no: phone,
  }
  if (dob) {
    data.dob = formatDateToYYYYMMDD(dob);
  }
  return await UserProfileAPI.patch({
    data: data,
    apiToken: controller.apiToken
  });
}

export const getUserPosition: Handler = async (controller, {}) => {
  return await PositionList.list({
    params: {},
    apiToken: controller.apiToken
  });
}

export const getUserWithPatient: Handler = async (controller, { apiToken, userId }) => {
  return await UserWithPatientDetail.retrieve({
    pk: userId,
    params: useParams,
    apiToken: controller.apiToken
  });
}

export const postUserSetPassword: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, password = _b.password, apiToken = _b.apiToken, id = _b.id;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, data, userService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            data = {};
//                            if (password) {
//                                data.password = password;
//                            }
//                            if (id) {
//                                data.id = id;
//                            }
//                            userService = new UserService_1.default(apiToken);
//                            return [4 /*yield*/, utils_1.to(userService.postUserSetPassword({ apiToken: apiToken, params: params, data: data }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params, data = _b.data, apiToken = _b.apiToken;
//        if (apiToken) {
//            this.client.defaults.headers["Authorization"] = "Token " + apiToken;
//        }
//        return this.client
//            .post(apis_1.USER.SET_PASSWORD, data, {
//            params: params
//        })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/users/apis/users/set-password/
}
