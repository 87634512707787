import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Input,
  Radio,
  Dropdown
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const ANES_PACU_Post = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        id="CardPACURecordForm"
        style={{ width: "100%", padding:"10px"}}>
        <div>
          
          <div
            style={{ gridRow: "1/2", gridColumn: "1/3",marginLeft:"10px",marginTop:"10px", justifyContent: "space-between", display: "flex", alingItem: "center", marginBottom: "10px"}}>
            <div
              style={{ display: "flex" ,fontWeight:"Bold",fontSize:"18px",marginBottom:"10px"}}>
              Post Anesthetic Care Unit Record
            </div>
            <div
              style={{display: "flex"}}>
              
              <div>
                {props.buttonLog}
              </div>
              <div
                style={{ display: "none" }}>
                {props.buttonPrint}
              </div>
              <div
                style={{ display: props.buttonStatus === "CONFIRMED" ?  "none" : "",}}>
                {props.buttonSave}
              </div>
              <div
                style={{ display: props.buttonStatus === "CONFIRMED" ?  "none" : "",}}>
                {props.buttonConfirm}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{backgroundColor: "#EDF6F9",width: "100%",height:"50%",padding:"10px",borderRadius: 3,border : "solid 0.5px ",borderColor:"#5DBCD2"}}>
          
          <div
            className="ui form">
            
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={7}>
                <label
                  style={{minWidth: "105px", maxWidth: "105px"}}>
                  Operative Date
                </label>
                <div
                  style={{ width: "100%" }}>
                  
                  <DateTextBox
                    disabled={true}
                    onChange={props.onChangeDateForm}
                    style={{ display: "flex" ,height:"100%", opacity: 1, }}
                    value={props.PACURecordSequence?.data?.DateRamrk || ""}>
                  </DateTextBox>
                </div>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <label
                  style={{minWidth: "max-content"}}>
                  OR Location
                </label>
                <Input
                  disabled={true}
                  fluid={true}
                  name="ORRmark"
                  onChange={props.onChangeData}
                  style={{ opacity: 1, }}
                  value={props.PACURecordSequence?.data?.ORRmark || ""}>
                </Input>
              </FormField>
              <FormField
                inline={true}
                style={{padding:0}}
                width={7}>
                <label
                  style={{minWidth: "max-content"}}>
                  Patient location
                </label>
                <Radio
                  checked={props.PACURecordSequence?.data?.PatientLocation === "OPD"}
                  label="OPD"
                  name="PatientLocation"
                  style={{minWidth: "max-content", marginRight: "1rem"}}
                  value="OPD">
                </Radio>
                <Radio
                  checked={props.PACURecordSequence?.data?.PatientLocation === "Ward"}
                  label="Ward"
                  name="PatientLocation"
                  style={{minWidth: "max-content", marginRight: "1rem"}}
                  value="Ward">
                </Radio>
                <Dropdown
                  disabled={ true}
                  fluid={true}
                  options={props.masterOptions?.divisionWard}
                  selection={true}
                  style={{ opacity: 1, width: "100%" }}
                  value={ props.PACURecordSequence?.data?.IsWardRemark || ""}>
                </Dropdown>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={7}>
                <label
                  style={{minWidth: "105px", maxWidth: "105px"}}>
                  PACU Time : Start
                </label>
                <div
                  style={{ width: "100%" }}>
                  
                  <DateTextBox
                    disabled={!props.isPACU}
                    onChange={props.onChangeStartDate}
                    value={props.PACURecordSequence?.data?.StartDate || ""}>
                  </DateTextBox>
                </div>
                <div
                  style={{width: "fit-content"}}>
                  {props.startTime}
                </div>
              </FormField>
              <FormField
                inline={true}
                width={6}>
                <label>
                  End
                </label>
                <div
                  style={{ width: "100%" }}>
                  
                  <DateTextBox
                    disabled={!props.isPACU}
                    onChange={props.onChangeEndDate}
                    value={props.PACURecordSequence?.data?.EndDate || ""}>
                  </DateTextBox>
                </div>
                <div
                  style={{width: "fit-content"}}>
                  {props.endTime}
                </div>
              </FormField>
              <FormField
                inline={true}
                style={{padding:0}}
                width={4}>
                <label
                  style={{minWidth : "max-content"}}>
                  Total time
                </label>
                <Input
                  disabled={true}
                  fluid={true}
                  name="PACUDurartionTime"
                  onChange={props.onChangeData}
                  readOnly={true}
                  style={{ display: "flex" ,height:"90%",width:"100%", opacity: 1,}}
                  value={props.PACURecordSequence?.data?.PACUDurartionTime || ""}>
                </Input>
                <label
                  style={{marginLeft: "0.5rem"}}>
                  min
                </label>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={8}>
                <label
                  style={{minWidth: "105px", maxWidth: "105px"}}>
                  Anesthesia team
                </label>
                <Input
                  disabled={true}
                  name="AnesthesiaTeamRemark"
                  onChange={props.onChangeData}
                  style={{ display: "flex" ,height:"100%",width:"100%", opacity: 1,}}
                  value={props.PACURecordSequence?.data?.AnesthesiaTeamRemark || ""}>
                </Input>
              </FormField>
              <FormField
                inline={true}
                style={{padding:0}}
                width={8}>
                <label
                  style={{minWidth: "105px", maxWidth: "105px"}}>
                  Post-Operative Diagnosis
                </label>
                <Input
                  disabled={true}
                  name="DiagnosisRemark"
                  readOnly={true}
                  style={{ display: "flex" ,height:"100%",width:"100%", opacity: 1,}}
                  value={props.PACURecordSequence?.data?.DiagnosisRemark || "รอลงผลหัตถการ"}>
                </Input>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={8}>
                <label
                  style={{minWidth: "105px", maxWidth: "105px"}}>
                  Anesthesia nurse
                </label>
                <div
                  style={{ width: "100%" }}>
                  
                  <Input
                    disabled={true}
                    fluid={true}
                    readOnly={true}
                    style={{ opacity: 1, }}
                    value={props.PACURecordSequence?.data?.AnesthesiaNurse || ""}>
                  </Input>
                </div>
              </FormField>
              <FormField
                inline={true}
                style={{padding:0}}
                width={8}>
                <label
                  style={{minWidth: "105px", maxWidth: "105px"}}>
                  Performed Operation
                </label>
                <Input
                  disabled={true}
                  name="OperationRemark"
                  readOnly={true}
                  style={{display: "flex" ,height:"100%",width:"100%", opacity: 1,}}
                  value={props.PACURecordSequence?.data?.OperationRemark || "รอลงผลหัตถการ"}>
                </Input>
              </FormField>
            </FormGroup>
          </div>
        </div>
      </div>
    )
}


export default ANES_PACU_Post

export const screenPropsDefault = {}

/* Date Time : Thu Jun 06 2024 10:05:33 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPACURecordForm"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", padding:\"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#EDF6F9\",width: \"100%\",height:\"50%\",padding:\"10px\",borderRadius: 3,border : \"solid 0.5px \",borderColor:\"#5DBCD2\"}"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "div",
      "parent": 69,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/3\",marginLeft:\"10px\",marginTop:\"10px\", justifyContent: \"space-between\", display: \"flex\", alingItem: \"center\", marginBottom: \"10px\"}"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "div",
      "parent": 70,
      "props": {
        "children": {
          "type": "value",
          "value": "Post Anesthetic Care Unit Record"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,fontWeight:\"Bold\",fontSize:\"18px\",marginBottom:\"10px\"}"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "div",
      "parent": 70,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "div",
      "parent": 78,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonLog"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "div",
      "parent": 78,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonPrint"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"none\" }"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "div",
      "parent": 78,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.buttonStatus === \"CONFIRMED\" ?  \"none\" : \"\",}"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "div",
      "parent": 78,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonConfirm"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.buttonStatus === \"CONFIRMED\" ?  \"none\" : \"\",}"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 96,
      "name": "FormGroup",
      "parent": 95,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 98,
      "name": "FormField",
      "parent": 96,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 100,
      "name": "FormField",
      "parent": 96,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 102,
      "name": "FormField",
      "parent": 96,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding:0}"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 110,
      "name": "Input",
      "parent": 100,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "ORRmark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "readOnly": {
          "type": "value",
          "value": ""
        },
        "readonly": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ opacity: 1, }"
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.ORRmark || \"\""
        }
      },
      "seq": 174,
      "void": false
    },
    {
      "from": null,
      "id": 111,
      "name": "div",
      "parent": 98,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 173,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 112,
      "name": "DateTextBox",
      "parent": 111,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeDateForm"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"100%\", opacity: 1, }"
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.DateRamrk || \"\"",
          "valueEN": ""
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 117,
      "name": "FormGroup",
      "parent": 95,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 123,
      "name": "FormField",
      "parent": 117,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding:0}"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 131,
      "name": "Input",
      "parent": 123,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "PACUDurartionTime"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"90%\",width:\"100%\", opacity: 1,}"
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.PACUDurartionTime || \"\""
        }
      },
      "seq": 171,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 132,
      "name": "FormGroup",
      "parent": 95,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 133,
      "name": "FormGroup",
      "parent": 95,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 136,
      "name": "FormField",
      "parent": 132,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 138,
      "name": "FormField",
      "parent": 132,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding:0}"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 139,
      "name": "FormField",
      "parent": 133,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 141,
      "name": "FormField",
      "parent": 133,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{padding:0}"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 146,
      "name": "Input",
      "parent": 141,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "OperationRemark"
        },
        "open": {
          "type": "value",
          "value": ""
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\" ,height:\"100%\",width:\"100%\", opacity: 1,}"
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.OperationRemark || \"รอลงผลหัตถการ\""
        }
      },
      "seq": 165,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 147,
      "name": "Input",
      "parent": 138,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "DiagnosisRemark"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"100%\",width:\"100%\", opacity: 1,}"
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.DiagnosisRemark || \"รอลงผลหัตถการ\""
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 148,
      "name": "Input",
      "parent": 136,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "AnesthesiaTeamRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"100%\",width:\"100%\", opacity: 1,}"
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.AnesthesiaTeamRemark || \"\""
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": null,
      "id": 149,
      "name": "div",
      "parent": 139,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 182,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 153,
      "name": "FormField",
      "parent": 117,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": null,
      "id": 154,
      "name": "div",
      "parent": 153,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 167,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 155,
      "name": "DateTextBox",
      "parent": 154,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.isPACU"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeStartDate"
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.StartDate || \"\""
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 156,
      "name": "FormField",
      "parent": 117,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": null,
      "id": 157,
      "name": "div",
      "parent": 156,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 168,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 158,
      "name": "DateTextBox",
      "parent": 157,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.isPACU"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeEndDate"
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.EndDate || \"\""
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 159,
      "name": "Radio",
      "parent": 102,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.PatientLocation === \"OPD\""
        },
        "disabled": {
          "type": "code",
          "value": ""
        },
        "label": {
          "type": "value",
          "value": "OPD"
        },
        "name": {
          "type": "value",
          "value": "PatientLocation"
        },
        "onChange": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", marginRight: \"1rem\"}"
        },
        "value": {
          "type": "value",
          "value": "OPD"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": null,
      "id": 162,
      "name": "label",
      "parent": 136,
      "props": {
        "children": {
          "type": "value",
          "value": "Anesthesia team"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"105px\", maxWidth: \"105px\"}"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": null,
      "id": 164,
      "name": "label",
      "parent": 139,
      "props": {
        "children": {
          "type": "value",
          "value": "Anesthesia nurse"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"105px\", maxWidth: \"105px\"}"
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": null,
      "id": 165,
      "name": "label",
      "parent": 141,
      "props": {
        "children": {
          "type": "value",
          "value": "Performed Operation"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"105px\", maxWidth: \"105px\"}"
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": null,
      "id": 166,
      "name": "label",
      "parent": 138,
      "props": {
        "children": {
          "type": "value",
          "value": "Post-Operative Diagnosis"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"105px\", maxWidth: \"105px\"}"
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": null,
      "id": 167,
      "name": "label",
      "parent": 153,
      "props": {
        "children": {
          "type": "value",
          "value": "PACU Time : Start"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"105px\", maxWidth: \"105px\"}"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": null,
      "id": 168,
      "name": "label",
      "parent": 156,
      "props": {
        "children": {
          "type": "value",
          "value": "End"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": null,
      "id": 169,
      "name": "div",
      "parent": 153,
      "props": {
        "children": {
          "type": "code",
          "value": "props.startTime"
        },
        "style": {
          "type": "code",
          "value": "{width: \"fit-content\"}"
        }
      },
      "seq": 169,
      "void": false
    },
    {
      "from": null,
      "id": 170,
      "name": "div",
      "parent": 156,
      "props": {
        "children": {
          "type": "code",
          "value": "props.endTime"
        },
        "style": {
          "type": "code",
          "value": "{width: \"fit-content\"}"
        }
      },
      "seq": 170,
      "void": false
    },
    {
      "from": null,
      "id": 171,
      "name": "label",
      "parent": 123,
      "props": {
        "children": {
          "type": "value",
          "value": "Total time"
        },
        "style": {
          "type": "code",
          "value": "{minWidth : \"max-content\"}"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": null,
      "id": 172,
      "name": "label",
      "parent": 123,
      "props": {
        "children": {
          "type": "value",
          "value": "min"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"0.5rem\"}"
        }
      },
      "seq": 172,
      "void": false
    },
    {
      "from": null,
      "id": 173,
      "name": "label",
      "parent": 98,
      "props": {
        "children": {
          "type": "value",
          "value": "Operative Date"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"105px\", maxWidth: \"105px\"}"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": null,
      "id": 174,
      "name": "label",
      "parent": 100,
      "props": {
        "children": {
          "type": "value",
          "value": "OR Location"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": null,
      "id": 175,
      "name": "label",
      "parent": 102,
      "props": {
        "children": {
          "type": "value",
          "value": "Patient location"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 176,
      "name": "Radio",
      "parent": 102,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.PatientLocation === \"Ward\""
        },
        "disabled": {
          "type": "code",
          "value": ""
        },
        "label": {
          "type": "value",
          "value": "Ward"
        },
        "name": {
          "type": "value",
          "value": "PatientLocation"
        },
        "onChange": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", marginRight: \"1rem\"}"
        },
        "value": {
          "type": "value",
          "value": "Ward"
        }
      },
      "seq": 176,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 179,
      "name": "Input",
      "parent": 149,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ opacity: 1, }"
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.AnesthesiaNurse || \"\""
        }
      },
      "seq": 179,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 181,
      "name": "Dropdown",
      "parent": 102,
      "props": {
        "disabled": {
          "type": "code",
          "value": " true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.divisionWard"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ opacity: 1, width: \"100%\" }"
        },
        "value": {
          "type": "code",
          "value": " props.PACURecordSequence?.data?.IsWardRemark || \"\""
        }
      },
      "seq": 181,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "ANES_PACU_Post",
  "project": "New Project",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
