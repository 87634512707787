import { AxiosError } from "axios";

import EncounterDetail from "issara-sdk/apis/EncounterDetail_core";
import EncounterList from "issara-sdk/apis/EncounterList_core";
import EpisodeDetail from "issara-sdk/apis/EpisodeDetail_core";
import EpisodeEncounterList from "issara-sdk/apis/EpisodeEncounterList_core";
import EpisodeList from "issara-sdk/apis/EpisodeList_core";

import EncounterPatientDiagnosisSerializer from "issara-sdk/types/EncounterPatientDiagnosisSerializer_core";
import EncounterSerializer from "issara-sdk/types/EncounterSerializer_core";
import EpisodeSerializer from "issara-sdk/types/EpisodeSerializer_core";

import {
  ActionHandler,
  BLC_STATUS,
  BaseSeqState,
  FormInputChangeHandler,
  GenerateActionTypes,
  MasterMapOptions,
  PickFromPartialNullable,
  PickMainState,
  SetErrorMessage,
  generatePrefixedActions,
} from "react-lib/apps/HISV3/common/CommonInterface";

/** ============== Type-Defining Constants =============  */
export const ACTIONS = {
  DELETE_ENCOUNTER: "DELETE_ENCOUNTER",
  DELETE_EPISODE: "DELETE_EPISODE",
  NEW_EPISODE: "NEW_EPISODE",
  SAVE: "SAVE",
  SEARCH_ENCOUNTER: "SEARCH_ENCOUNTER",
  SEARCH_EPISODE: "SEARCH_EPISODE",
  SELECT: "SELECT",
} as const;

export const SEQUENCE_NAME = "EpisodeOfCare" as const;

/** =============== Types and Interfaces ===============  */
export type State = Partial<{
  EpisodeOfCareSequence: Partial<{
    sequenceIndex: "Action" | "START" | null;
    activeEncounterList: Partial<{
      abortCtrl: AbortController;
      activePage: number;
      items: EncounterSerializer[];
      total: number;
    }>;
    checkedActiveEncounter: number[];
    episodeDetail: EpisodeSerializer | null;
    episodeEncounterList: EncounterPatientDiagnosisSerializer[];
    episodeList: EpisodeSerializer[];
    filterEncounter: FilterEncounterType;
    filterEpisode: FilterEpisodeType;
    uncheckedEpisodeEncounter: number[];
  }> | null;
}>;

type PickedState = PickMainState<
  "buttonLoadCheck" | "errorMessage" | "searchedItemListWithKey" | "selectedPatient"
>;

export type PickedProps = Omit<PickedState, "selectedPatient">;

export type MasterOptions = MasterMapOptions<typeof Masters>;

type FilterEpisodeType = Partial<{ include_finished: boolean }>;

export type FilterEncounterType = Partial<{
  ended: string;
  exclude_has_episode: boolean;
  include_expired: boolean;
  started: string;
}>;

export type StateChangeHandler<T> = FormInputChangeHandler<T, EpisodeSerializer>;

export type FilterEpisodeChangeHandler<T> = FormInputChangeHandler<T, FilterEpisodeType>;

export type FilterEncounterChangeHandler<T> = FormInputChangeHandler<T, FilterEncounterType>;

export type Event =
  | { message: "GetMasterData"; params: Record<string, unknown> }
  | { message: "RunSequence"; params: Record<string, unknown> };

export type Data = {
  device?: number;
  division?: number;
};

export type SeqState = BaseSeqState<SequenceName>;

export type Actions = typeof ACTIONS;

type SequenceName = typeof SEQUENCE_NAME;

type ActionPayloadMap = {
  [ACTIONS.DELETE_ENCOUNTER]: {
    id: number;
    note: string;
    password: string;
    username: string;
    onSuccess?: () => void;
  };
  [ACTIONS.DELETE_EPISODE]: { onSuccess?: () => void };
  [ACTIONS.SEARCH_ENCOUNTER]: { activePage: number; updateFilter?: FilterEncounterType };
  [ACTIONS.SEARCH_EPISODE]: { selected?: EpisodeSerializer | null };
  [ACTIONS.SELECT]: { data: EpisodeSerializer | null; isResetEncounter?: boolean };
};

type ActionTypes = GenerateActionTypes<
  Actions,
  ActionPayloadMap,
  SequenceName,
  PickedState & State
>;

type ActionType = ActionTypes["ActionType"];

export type RunSequence = ActionTypes["RunSequence"];

export type SetProp = ActionTypes["SetProp"];

export type OnEvent = ActionTypes["OnEvent"];

type Params<A extends ActionType["action"]> = ActionTypes["Params"][A];

export type Handler<P = unknown, R = void> = ActionHandler<PickedState & State, Event, Data, P, R>;

/** ===================== Constants ====================  */
export const StateInitial: State = {
  EpisodeOfCareSequence: null,
};

export const DataInitial: Data = {};

const Masters = [["episodeType", {}]] as const;

export const BTN_ACTS = generatePrefixedActions(ACTIONS, SEQUENCE_NAME);

const DEFAULT_EPISODE: PickFromPartialNullable<
  State["EpisodeOfCareSequence"],
  "checkedActiveEncounter" | "episodeEncounterList" | "uncheckedEpisodeEncounter"
> = {
  checkedActiveEncounter: [],
  episodeEncounterList: [],
  uncheckedEpisodeEncounter: [],
};

export const ENCOUNTER_LIMIT = 5;

/** ===================== Functions ====================  */
export const Start: Handler<SeqState> = async (controller, params) => {
  const state = controller.getState();

  controller.handleEvent({ message: "GetMasterData", params: { masters: Masters } });

  controller.setState(
    {
      EpisodeOfCareSequence: {
        ...state.EpisodeOfCareSequence,
        sequenceIndex: "Action",
        filterEncounter: { include_expired: false },
        filterEpisode: { include_finished: false },
      },
    },
    () => {
      Action(controller, { ...params, action: ACTIONS.SEARCH_EPISODE });
    }
  );
};

/** ====================================================  */
/**                        Action                         */
/** ====================================================  */
export const Action: Handler<ActionType> = async (controller, params) => {
  const actionHandlers: Partial<{ [K in ActionType["action"]]: Handler<Params<K>> }> = {
    [ACTIONS.DELETE_ENCOUNTER]: HandleDeleteEncounter,
    [ACTIONS.DELETE_EPISODE]: HandleDeleteEpisode,
    [ACTIONS.NEW_EPISODE]: HandleNewEpisode,
    [ACTIONS.SAVE]: HandleSave,
    [ACTIONS.SEARCH_ENCOUNTER]: HandleSearchEncounter,
    [ACTIONS.SEARCH_EPISODE]: HandleSearchEpisode,
    [ACTIONS.SELECT]: HandleSelect,
  };

  const { action } = params;

  const handler = actionHandlers[action] as Handler<Params<typeof action>> | undefined;

  if (handler) {
    handler(controller, params);
  } else {
    console.error(`No handler found for action: ${action}`);
  }
};

/** ====================================================  */
/**                        Handler                        */
/** ====================================================  */

const HandleSearchEpisode: Handler<Params<Actions["SEARCH_EPISODE"]>> = async (
  controller,
  params
) => {
  let state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SEARCH_EPISODE]: BLC_STATUS.LOADING },
  });

  const filterEpisode = state.EpisodeOfCareSequence?.filterEpisode || {};
  const filterEncounter = state.EpisodeOfCareSequence?.filterEncounter || {};

  const [[encounter], [episode]] = await Promise.all([
    GetEncounterList(controller, { activePage: 1, filter: filterEncounter }),
    EpisodeList.list({
      apiToken: controller.apiToken,
      params: {
        include_finished: filterEpisode.include_finished,
        patient: state.selectedPatient?.id,
      },
    }),
  ]);

  const episodeList: EpisodeSerializer[] = episode?.items || [];

  const selectedId = params.selected?.id || state.EpisodeOfCareSequence?.episodeDetail?.id;
  const foundSelected = episodeList.find((item) => item.id === selectedId);

  // Update
  await HandleSelect(controller, {
    ...params,
    action: ACTIONS.SELECT,
    data: foundSelected || null,
  });

  state = controller.getState();

  controller.setState({
    EpisodeOfCareSequence: {
      ...state.EpisodeOfCareSequence,
      activeEncounterList: encounter,
      checkedActiveEncounter: [],
      episodeList: episode?.items || [],
      uncheckedEpisodeEncounter: [],
    },
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SEARCH_EPISODE]: null },
  });
};

const HandleSearchEncounter: Handler<Params<Actions["SEARCH_ENCOUNTER"]>> = async (
  controller,
  params
) => {
  let state = controller.getState();

  const seq = state.EpisodeOfCareSequence;

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SEARCH_ENCOUNTER]: BLC_STATUS.LOADING },
  });

  seq?.activeEncounterList?.abortCtrl?.abort();

  const abortCtrl = new AbortController();
  const filterEncounter = params.updateFilter || state.EpisodeOfCareSequence?.filterEncounter || {};

  controller.setState({
    EpisodeOfCareSequence: {
      ...seq,
      activeEncounterList: { ...seq?.activeEncounterList, abortCtrl },
      filterEncounter,
    },
  });

  const [encounter, error] = await GetEncounterList(controller, {
    abortCtrl,
    activePage: params.activePage,
    filter: filterEncounter,
  });

  if (error.code === AxiosError.ERR_CANCELED) {
    return;
  }

  state = controller.getState();

  controller.setState({
    EpisodeOfCareSequence: {
      ...state.EpisodeOfCareSequence,
      activeEncounterList: encounter,
    },
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SEARCH_ENCOUNTER]: BLC_STATUS.SUCCESS },
  });
};

const HandleSave: Handler<Params<Actions["SAVE"]>> = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SAVE]: BLC_STATUS.LOADING },
  });

  const [result, error] = await CreateUpdateEpisode(controller, {
    detail: state.EpisodeOfCareSequence?.episodeDetail || null,
  });

  if (error) {
    SetErrorMessage(controller, { ...params, error });

    return;
  }

  const [, patchError] = await UpdateEncounterEpisode(controller, { episodeId: result.id });

  if (patchError) {
    HandleSearchEpisode(controller, {
      ...params,
      action: ACTIONS.SEARCH_EPISODE,
      selected: result,
    });

    SetErrorMessage(controller, { ...params, error: patchError });

    return;
  }

  // Refresh
  HandleSearchEpisode(controller, { ...params, action: ACTIONS.SEARCH_EPISODE, selected: result });

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SAVE]: BLC_STATUS.SUCCESS },
  });
};

const HandleNewEpisode: Handler<SeqState> = async (controller, params) => {
  const state = controller.getState();

  controller.setState(
    {
      EpisodeOfCareSequence: {
        ...state.EpisodeOfCareSequence,
        episodeDetail: { name: "Episode of care" },
        ...DEFAULT_EPISODE,
      },
    },
    () => {
      HandleResetEncounterList(controller, params);
    }
  );
};

const HandleSelect: Handler<Params<Actions["SELECT"]>, Promise<void>> = async (
  controller,
  params
) => {
  let state = controller.getState();

  if (params.isResetEncounter) {
    HandleResetEncounterList(controller, params);
  }

  // หากไม่มีข้อมูล Episode
  if (!params.data) {
    controller.setState({
      EpisodeOfCareSequence: {
        ...state.EpisodeOfCareSequence,
        episodeDetail: null,
        ...DEFAULT_EPISODE,
      },
    });

    return;
  }

  const [result] = await EpisodeEncounterList.list({
    apiToken: controller.apiToken,
    params: { episode: params.data.id },
  });

  state = controller.getState();

  controller.setState({
    EpisodeOfCareSequence: {
      ...state.EpisodeOfCareSequence,
      episodeDetail: params.data,
      episodeEncounterList: result?.items || [],
    },
  });
};

const HandleResetEncounterList: Handler<SeqState> = (controller, params) => {
  const state = controller.getState();

  const filterEncounter = state.EpisodeOfCareSequence?.filterEncounter || {};

  if (filterEncounter.include_expired) {
    HandleSearchEncounter(controller, {
      ...params,
      action: ACTIONS.SEARCH_ENCOUNTER,
      activePage: 1,
      updateFilter: { include_expired: false },
    });
  }
};

const HandleDeleteEncounter: Handler<Params<Actions["DELETE_ENCOUNTER"]>> = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.DELETE_ENCOUNTER]: BLC_STATUS.LOADING },
  });

  const [, error] = await EncounterDetail.delete({
    apiToken: controller.apiToken,
    pk: params.id,
    extra: {
      data: {
        note: params.note,
        password: params.password,
        username: params.username,
      },
      device: controller.data.device,
      division: controller.data.division,
    },
  });

  if (error) {
    SetErrorMessage(controller, { ...params, btnError: "", error });

    return;
  }

  // Refresh
  HandleSearchEpisode(controller, { ...params, action: ACTIONS.SEARCH_EPISODE });

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.DELETE_ENCOUNTER]: null },
  });

  params.onSuccess?.();
};

const HandleDeleteEpisode: Handler<Params<Actions["DELETE_EPISODE"]>> = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.DELETE_EPISODE]: BLC_STATUS.LOADING },
  });

  const seq = state.EpisodeOfCareSequence || {};

  const detail = seq.episodeList?.find((item) => item.id === seq.episodeDetail?.id);

  const [, error] = await CreateUpdateEpisode(controller, {
    detail: { ...detail, active: false },
  });

  if (error) {
    SetErrorMessage(controller, { ...params, error });

    return;
  }

  // Refresh
  HandleSearchEpisode(controller, { ...params, action: ACTIONS.SEARCH_EPISODE });

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.DELETE_ENCOUNTER]: null },
  });

  params.onSuccess?.();
};

/** ====================================================  */
/**                         APIS                          */
/** ====================================================  */

const UpdateEncounterEpisode: Handler<{ episodeId: number }, Promise<[unknown, unknown]>> = async (
  controller,
  params
) => {
  const state = controller.getState();

  const checkedActiveEncounter = state.EpisodeOfCareSequence?.checkedActiveEncounter || [];
  const uncheckedEpisodeEncounter = state.EpisodeOfCareSequence?.uncheckedEpisodeEncounter || [];

  const mappedChecked = checkedActiveEncounter.map((enId) => ({
    encounter: enId,
    episode: params.episodeId,
  }));

  const mappedUnchecked = uncheckedEpisodeEncounter.map((enId) => ({
    encounter: enId,
    episode: null,
  }));

  const allMappedEncounters = [...mappedChecked, ...mappedUnchecked];

  const encounterPatchPromises = allMappedEncounters.map(
    async (item) =>
      EncounterDetail.patch({
        apiToken: controller.apiToken,
        data: { action: "EDIT_EPISODE", episode: item.episode },
        pk: item.encounter,
      }) as Promise<[unknown, unknown]>
  );

  const results = await Promise.all(encounterPatchPromises);

  const hasError = results.some((res) => res[1]);

  return hasError ? [null, results.map((res) => res[1])] : [results.map((res) => res[0]), null];
};

const CreateUpdateEpisode: Handler<
  { detail: EpisodeSerializer | null },
  Promise<[EpisodeSerializer, unknown]>
> = async (controller, params) => {
  const state = controller.getState();

  const { detail } = params;

  const api = detail?.id ? EpisodeDetail.update : EpisodeList.create;

  const data = { ...detail };

  delete data.end_date;

  return (await api({
    apiToken: controller.apiToken,
    data: {
      ...data,
      patient: state.selectedPatient?.id,
    },
    pk: data.id,
  })) as Promise<[EpisodeSerializer, unknown]>;
};

const GetEncounterList: Handler<
  { abortCtrl?: AbortController; activePage: number; filter: FilterEncounterType },
  Promise<
    [
      {
        activePage: number;
        items: EncounterSerializer[];
        total: number;
      },
      { code: string }
    ]
  >
> = async (controller, params) => {
  const state = controller.getState();

  const offset = (params.activePage - 1) * ENCOUNTER_LIMIT;

  const [result, , network] = await EncounterList.list({
    apiToken: controller.apiToken,
    params: {
      ended: params.filter.ended,
      exclude_canceled: true,
      exclude_has_episode: true,
      limit: ENCOUNTER_LIMIT,
      offset,
      patient: state.selectedPatient?.id,
      started: params.filter.started,
      unexpired_only: !params.filter.include_expired,
    },
    extra: {
      signal: params.abortCtrl?.signal,
    },
  });

  return [
    { activePage: params.activePage, items: result?.items || [], total: result?.total || 0 },
    { code: network.code },
  ];
};
