import moment from "moment";
import { adToBe } from "react-lib/utils/dateUtils";
import { HeaderLogoWithTitleContent } from "../common/HeaderPdfFormTemplate";
import CONFIG from "config/config";
// const FIRST_PAGE_TOP = 325;
// const ANOTHER_PAGE_TOP = 345;
// const BOTTOM = 364;
const BACKEND_DATE_FORMAT = "YYYY-MM-DD";
const BE_DATE_FORMAT = "DD/MM/YYYY";

const FORM_NAME = "FormLabCrossMatchQueue";

const FormLabCrossMatchQueue = async (props: any) => {
  console.log("FormLabCrossMatchQueue", props);

  const headerForm = await HeaderLogoWithTitleContent({
    form: FORM_NAME,
    font: "THSarabunNew",
    logoHeight: 40,
    styles: {
      facultyHeader: {
        fontSize: 20,
        bold: true,
      },
      fieldKey: {
        bold: true,
        fontSize: 15,
      },
      fieldValue: {
        fontSize: 15,
      },
      miniFieldKey: {
        bold: true,
        fontSize: 13,
      },
      miniFieldValue: {
        fontSize: 13,
      },
    },
    titleContent:
      CONFIG.COMPANY === "SAPIENS"
        ? [
            {
              text: "รายงานสรุปผู้ป่วยที่ต้องการทำ Cross Match",
              style: "fieldKey",
              alignment: "center",
            },
            {
              text: "และจองส่วนประกอบของโลหิต",
              style: "fieldKey",
              alignment: "center",
            },
            {
              text: [
                { text: "วันที่ " },
                { text: props.start_date },
                { text: " - " },
                { text: props.end_date },
              ],
              style: "fieldKey",
              alignment: "center",
            },
          ]
        : [
            {
              text: "ห้องปฏิบัติการ รพ.คณะฯ (ห้องตรวจเลือด)",
              style: "fieldKey",
              alignment: "center",
            },
            {
              text: "รายงานสรุปผู้ป่วยที่ต้องการทำ Cross Match",
              style: "fieldKey",
              alignment: "center",
            },
            {
              text: [
                { text: "วันที่ " },
                { text: props.start_date },
                { text: " - " },
                { text: props.end_date },
              ],
              style: "fieldKey",
              alignment: "center",
            },
          ],
  });
  const { font, images, styles } = headerForm;

  const generatedTableRow = (data: any) => {
    let rowTable: any[] = [
      [
        { text: "ลำดับ", style: "fieldKey", alignment: "center" },
        { text: "วันที่/เวลานัด", style: "fieldKey", alignment: "center" },
        { text: "HN/AN", style: "fieldKey", alignment: "center" },
        { text: "ชื่อ - สกุลผู้ป่วย", style: "fieldKey", alignment: "center" },
        { text: "เบอร์โทรศัพท์", style: "fieldKey", alignment: "center" },
        { text: "แพทย์ผู้สั่ง", style: "fieldKey", alignment: "center" },
        { text: "นัดเพื่อ", style: "fieldKey", alignment: "center" },
      ],
    ];

    data.forEach((item: any, index: number) => {
      rowTable.push([
        { text: index + 1, alignment: "center" },
        {
          stack: [
            {
              text: item.date
                ? adToBe(moment(item.date, BACKEND_DATE_FORMAT).format(BE_DATE_FORMAT))
                : "",
              alignment: "center",
            },
            {
              text: item.time ? moment(item.time, "HH:mm:sss").format("[[]HH:mm[]]") : "",
              alignment: "center",
            },
          ],
        },
        {
          stack: [
            { text: item.hn, alignment: "center" },
            { text: item.an, alignment: "center" },
          ],
        },
        { text: item.patient_name },
        {
          text: item.phone_number ? item.phone_number : "-",
          alignment: "center",
        },
        { text: item.doctor_name },
        {
          stack: [
            { text: item.blood_components, alignment: "center" },
            { text: item.note, alignment: "center" },
          ],
        },
      ]);
    });
    return rowTable;
  };

  return {
    pageSize: "A4",
    pageOrientation: "portrait",
    defaultStyle: {
      font: font,
      lineHeight: 1,
      fontSize: 14,
    },
    ...styles,
    ...headerForm,
    content: [
      {
        margin: [10, 0, 10, 0],
        table: {
          headerRows: 1,
          dontBreakRows: true,
          widths: ["5%", "15%", "10%", "20%", "15%", "15%", "20%"],
          body: generatedTableRow(props.labData),
        },
      },
    ],
    footer: function (currentPage: number, pageCount: number) {
      return {
        margin: [10, 0, 10, 0],
        stack: [
          {
            columns: [
              {
                width: "50%",
                text: [
                  { text: "วันเวลาที่พิมพ์: ", style: "fieldKey" },
                  {
                    text: adToBe(moment().format(BE_DATE_FORMAT)),
                    style: "fieldKey",
                  },
                  {
                    text: " " + moment().format("[[]HH:mm[]]"),
                    style: "fieldKey",
                  },
                ],
              },
              {
                width: "50%",
                alignment: "right",
                text: [
                  { text: "หน้าที่ ", style: "fieldKey" },
                  { text: currentPage + "/", style: "fieldKey" },
                  { text: pageCount, style: "fieldKey" },
                ],
              },
            ],
          },
          {
            text: [
              { text: "ผู้พิมพ์เอกสาร: ", style: "fieldKey" },
              { text: props.printed_user, style: "fieldKey" },
            ],
          },
        ],
      };
    },
    images: {
      logoIH: origin + "/static/images/IHimage.png",
      ...images,
    },
  };
};

export default FormLabCrossMatchQueue;
