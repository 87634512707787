import IMEService from "../services/IMEService";
import { to } from "../../utils";
export default class IMEManager {
  apiToken?: string | null;

  constructor(apiToken?: string | null) {
    this.apiToken = apiToken
  }

  getImagingExamResult = async (params: any) => {
    var imagingExamResult = [];
    var imagingExamError = [];
    const imeService = new IMEService(this.apiToken);
    const [error, response] = await to(imeService.getImagingExamResult(params));
    if (response) {
      imagingExamResult = response;
    } else {
      imagingExamError = error;
    }
    return [imagingExamResult, imagingExamError];
  };
}
