import React, { useState, useEffect, forwardRef } from "react";
import SubQAType from "./SubQAType";
import { Form } from "semantic-ui-react";
import { useIntl } from "react-intl";

const SubQAField = forwardRef<any, any>((props, ref) => {
  const [answerDict, setAnswerDict] = useState<Record<string, any>>({});

  useEffect(() => {
    setAnswerDict(props.currentPayload?.["answer_dict"] || {});
  }, [props.currentPayload?.["answer_dict"]]);

  const handleChange = (e: any, v: any) => {
    let checked: string = v.checked?.toString();
    if (typeof v.checked === "boolean") {
      checked = `${checked[0].toUpperCase()}${checked.slice(1)}`;
    }

    const dict = {
      ...answerDict,
      [v.name]: typeof v.checked === "boolean" ? checked : v.value,
    };

    setAnswerDict(dict);
    props.onChangeAnswerDict?.(dict);
  };

  return (
    <Form>
      {props.questions.map((item: any) => (
        <SubQAType
          label={item.label}
          type={item.type}
          answer={answerDict[item.id]}
          choices={item.choices}
          dataValidateKey={item.id}
          questionId={item.id}
          default_answer={item.default_answer}
          statusRequest={props.statusRequest}
          onChange={handleChange}
          languageUX={props.languageUX}
        />
      ))}
    </Form>
  );
});

SubQAField.displayName = "SubQAField";
export default SubQAField;
