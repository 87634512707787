import React, { CSSProperties, useCallback, useMemo } from "react";

import { Checkbox, Modal } from "semantic-ui-react";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import ErrorMessage from "react-lib/apps/common/ErrorMessage";

// UX
import ModApplyPackageUX from "./ModApplyPackageUX";
import { useIntl } from "react-intl";

// Interface
import {
  ApplyPackageDetailType,
  OptionType,
  RunSequence,
  SetProp,
} from "./sequence/ApplyPolicyPackage";

// Types
type ModApplyPackageProps = {
  setProp: SetProp;
  // seq
  runSequence: RunSequence;
  data: Partial<ApplyPackageDetailType>;
  // data
  open: boolean;
  // CommonInterface
  buttonLoadCheck?: Record<string, any>;
  errorMessage?: Record<string, any>;
  // options
  packageOptions?: OptionType[];
  // callback
  onClose: () => any;
};

const BUTTON_ACTIONS = {
  save: "_SAVE",
};

const AlignCenter = {
  display: "grid",
  placeContent: "center",
  textAlign: "center",
} as CSSProperties;

const MOD_APPLY_PACKAGE = "ModApplyPackage";

export const MOD_APPLY_PACKAGE_SAVE = `${MOD_APPLY_PACKAGE}${BUTTON_ACTIONS.save}`;

const ModApplyPackage = (props: ModApplyPackageProps) => {
  const intl = useIntl();
  // Callback
  const handleChecked = useCallback(
    (id: number) => (e: any, data: any) => {
      const encounters = props.data.targetEncounters || [];

      const checked = data.checked
        ? [...encounters, id]
        : encounters.filter((value) => value !== id);

      props.setProp("ApplyPolicyPackageSequence.applyPackageDetail.targetEncounters", checked);
    },
    [props.data.targetEncounters]
  );

  const handleSave = useCallback(() => {
    props.runSequence({
      sequence: "ApplyPolicyPackage",
      action: "SAVE",
      card: MOD_APPLY_PACKAGE_SAVE,
    });
  }, []);

  const handleChangePackage = useCallback((e: any, data: any) => {
    props.runSequence({
      sequence: "ApplyPolicyPackage",
      action: "CHANGE_PACKAGE",
      packageId: data.value,
    });
  }, []);

  const handleSelectAll = useCallback(() => {
    const items = props.data.encounterPackageOrderList || [];

    props.setProp(
      "ApplyPolicyPackageSequence.applyPackageDetail.targetEncounters",
      items.map((item) => item.id)
    );
  }, [props.data.encounterPackageOrderList]);

  const handleDeselect = useCallback(() => {
    props.setProp("ApplyPolicyPackageSequence.applyPackageDetail.targetEncounters", []);
  }, []);

  // Memo
  const encounterItems = useMemo(() => {
    const items = props.data.encounterPackageOrderList || [];

    return items.map((item) => ({
      ...item,
      checkbox: (
        <div style={AlignCenter}>
          <Checkbox
            checked={props.data.targetEncounters?.includes(item.id)}
            onChange={handleChecked(item.id)}
          />
        </div>
      ),
      number: `${item.number} [${item.id}]`,
      performed_date: <div style={AlignCenter}>{item.performed_date}</div>,
      type: <div style={AlignCenter}>{item.type}</div>,
    }));
  }, [handleChecked, props.data.encounterPackageOrderList, props.data.targetEncounters]);

  const buttonSave = useMemo(
    () => (
      <ButtonLoadCheck
        // function
        setProp={props.setProp}
        // config
        color={"green"}
        name={BUTTON_ACTIONS.save}
        // data
        paramKey={MOD_APPLY_PACKAGE_SAVE}
        size="medium"
        title={intl.formatMessage({ id: "บันทึกรายการ" })}
        buttonLoadCheck={props.buttonLoadCheck?.[MOD_APPLY_PACKAGE_SAVE]}
        onClick={handleSave}
      />
    ),
    [handleSave, props.buttonLoadCheck]
  );

  const errorMessage = useMemo(
    () => <ErrorMessage error={props.errorMessage?.[MOD_APPLY_PACKAGE_SAVE]} />,
    [props.errorMessage]
  );

  return (
    <Modal open={props.open}>
      <ModApplyPackageUX
        // data
        buttonSave={buttonSave}
        disabledPackage={props.data.packageApplyPolicyId}
        encounterList={encounterItems}
        packageId={props.data.packageId}
        errorMessage={errorMessage}
        // options
        packageOptions={props.packageOptions}
        // callback
        onChangePackage={handleChangePackage}
        onClose={props.onClose}
        onDeselect={handleDeselect}
        onSelectAll={handleSelectAll}
        languageUX={props.languageUX}
      />
    </Modal>
  );
};

ModApplyPackage.displayName = "ModApplyPackage";

export default React.memo(ModApplyPackage);
