import React, { useState, forwardRef } from "react";

import { Button, Icon, Modal } from "semantic-ui-react";
import Dashboard from "./Dashboard";
import OPDDashboard from "./OPDDashboard";
import CardQueueBill from "./CardQueueBill";
import CardQueueDrug from "./CardQueueDrug";
import CardQueuePrepareDrug from "./CardQueuePrepareDrug";
import CardQueueDoctor from "./CardQueueDoctor";
import CONFIG from "config/config";
// import { AsyncDuckDB, AsyncDuckDBConnection } from "@duckdb/duckdb-wasm";
import { useIntl } from "react-intl";

type CardQueueForPatientProps = {
  runSequence: any;
  onEvent: any;
  TimeTrackingSequence: any;
  setProp: any;
  duckDBLoaded?: boolean;
  match: any;
  apiToken: any;
  division: any;
  controller: any;
  selectedDivision: any;
  queueBill: any;
  masterOptions: any[];
  queueDrug: any;
  queuePrepareDrug: any;
  queueDoctor: any;
  appController: any;
  searchedItemListWithKey: any;
}

const CardQueueForPatient = forwardRef((props: CardQueueForPatientProps, ref) => {
  const intl = useIntl();
  const [tab, setTab] = useState("Dashboard");
  const [openFullScreen, setOpenFullScreen] = useState<boolean>(false);
  
  console.log('CardQueueForPatient CONFIG: ', CONFIG);
  return (
    <>
      <div
        style={{
          display: "flex",
          padding: "15px 15px 15px 10px",
        }}
      >
        <MenuItem
          tab={tab}
          name="OPDDashboard"
          title="OPD Dashboard"
          setTab={setTab}
        />

        <MenuItem
          tab={tab}
          name="Dashboard"
          title="TeleMed Dashboard"
          setTab={setTab}
        />

        { !CONFIG.V3_HIDDEN_TAB_LIST.some( (i:any) => i === "submenu_dashboard") ? (
          <>
            <MenuItem
              tab={tab}
              name="doctor"
              title={intl.formatMessage({ id: "คิวหน้าห้องแพทย์" })}
              setTab={setTab}
            />
            <MenuItem
              tab={tab}
              name="prepareDrug"
              title={intl.formatMessage({ id: "คิวรอจัดยา" })}
              setTab={setTab}
            />
            <MenuItem
              tab={tab}
              name="bill"
              title={intl.formatMessage({ id: "คิวรอชำระเงิน" })}
              setTab={setTab}
            />
            <MenuItem
              tab={tab}
              name="drug"
              title={intl.formatMessage({ id: "คิวรอรับยา" })}
              setTab={setTab}
            />
          </>
        ) : null}

        <div style={{ flex: "1" }}></div>

        <div style={{ ...(tab === "Dashboard" || tab === "OPDDashboard") && {display: "none"}  }}>
          <span
            className="textButton"
            onClick={() => {
              setOpenFullScreen(true);
            }}
          >
            FullScreen
          </span>
        </div>
      </div>

      {tab === "OPDDashboard" ? (
        <OPDDashboard 
          runSequence={props.runSequence}
          onEvent={props.onEvent}
          TimeTrackingSequence={props.TimeTrackingSequence}
          setProp={props.setProp}
          duckDBLoaded={props.duckDBLoaded}
          appController={props.appController}
          masterOptions={props.masterOptions}
          searchedItemListWithKey={props.searchedItemListWithKey}
        />
      ) 
      : tab === "Dashboard" ? (
        <Dashboard
          match={props.match}
          ref={ref}
          apiToken={props.apiToken}
          division={props.division}
          controller={props.controller}
          // config
          organization={props.selectedDivision?.organization?.id}
        />
      ) : tab === "bill" ? (
        <>
          <CardQueueBill
            onEvent={props.onEvent}
            queueBill={props.queueBill}
            masterOptions={props.masterOptions}
            setOpenFullScreen={setOpenFullScreen}
            openFullScreen={openFullScreen}
            languageUX={props.languageUX}
          />
        </>
      ) : tab === "drug" ? (
        <>
          <CardQueueDrug
            onEvent={props.onEvent}
            masterOptions={props.masterOptions}
            setOpenFullScreen={setOpenFullScreen}
            openFullScreen={openFullScreen}
            queueDrug={props.queueDrug}
            languageUX={props.languageUX}
          />
        </>
      ) : tab === "prepareDrug" ? (
        <>
          <CardQueuePrepareDrug
            onEvent={props.onEvent}
            masterOptions={props.masterOptions}
            setOpenFullScreen={setOpenFullScreen}
            openFullScreen={openFullScreen}
            queuePrepareDrug={props.queuePrepareDrug}
            languageUX={props.languageUX}
          />
        </>
      ) : tab === "doctor" ? (
        <>
          <CardQueueDoctor
            onEvent={props.onEvent}
            masterOptions={props.masterOptions}
            setOpenFullScreen={setOpenFullScreen}
            openFullScreen={openFullScreen}
            queueDoctor={props.queueDoctor}
            languageUX={props.languageUX}
          />
        </>
      ) : (
        <></>
      )}

      <Modal
        style={{
          width: "100%",
          height: "100%",
          borderRadius: 0,
          boxShadow: "none",
          margin: "-15px 0",
        }}
        open={openFullScreen}
        onClose={() => setOpenFullScreen(false)}
        closeOnDimmerClick
      >
        {tab === "bill" ? (
          <>
            <CardQueueBill
              onEvent={props.onEvent}
              queueBill={props.queueBill}
              masterOptions={props.masterOptions}
              setOpenFullScreen={setOpenFullScreen}
              onClose={() => setOpenFullScreen(false)}
              openFullScreen={openFullScreen}
              languageUX={props.languageUX}
            />
          </>
        ) : tab === "drug" ? (
          <>
            <CardQueueDrug
              onEvent={props.onEvent}
              masterOptions={props.masterOptions}
              setOpenFullScreen={setOpenFullScreen}
              openFullScreen={openFullScreen}
              queueDrug={props.queueDrug}
              onClose={() => setOpenFullScreen(false)}
              languageUX={props.languageUX}
            />
          </>
        ) : tab === "prepareDrug" ? (
          <>
            <CardQueuePrepareDrug
              onEvent={props.onEvent}
              masterOptions={props.masterOptions}
              setOpenFullScreen={setOpenFullScreen}
              openFullScreen={openFullScreen}
              queuePrepareDrug={props.queuePrepareDrug}
              onClose={() => setOpenFullScreen(false)}
              languageUX={props.languageUX}
            />
          </>
        ) : tab === "doctor" ? (
          <>
            <CardQueueDoctor
              onEvent={props.onEvent}
              masterOptions={props.masterOptions}
              setOpenFullScreen={setOpenFullScreen}
              openFullScreen={openFullScreen}
              queueDoctor={props.queueDoctor}
              onClose={() => setOpenFullScreen(false)}
              languageUX={props.languageUX}
            />
          </>
        ) : (
          <></>
        )}
      </Modal>
    </>
  );
});

const MenuItem = (props: any) => (
  <Button
    size="tiny"
    color={props.name === props.tab ? "blue" : undefined}
    onClick={(e: any) => props.setTab(props.name)}
  >
    {props.title}
  </Button>
);

export default CardQueueForPatient;
