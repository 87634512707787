import moment from "moment";
import {
  Alignment,
  ContentStack,
  ContentText,
  ContextPageSize,
  Position,
  TDocumentDefinitions,
} from "pdfmake/interfaces";

import getPdfMake from "react-lib/appcon/common/pdfMake";

import { getDataURLFromURL } from "./CommonInterface";
import CompanyLogoForm, { CompanyLogoLayoutConfig, CompanyType, FontType } from "./CompanyLogoForm";

import { beToAd } from "react-lib/utils/dateUtils";

import CONFIG from "config/config";

// Common type for shared properties
type CommonProps = PageMarginsProps & {
  font?: FontType;
  form: string;
  headerMargins?: TDocumentDefinitions["pageMargins"];
  images?: Record<string, any>;
  logoHeight?: number;
  maxWidth?: number;
  styles?: TDocumentDefinitions["styles"];
  type?: number;
};

type PageMarginsProps = {
  pageMarginBottom?: number;
  pageMarginLeft?: number;
  pageMarginRight?: number;
  pageMargins?: TDocumentDefinitions["pageMargins"];
  pageMarginTop?: number;
};

type BaseHeaderProps = CommonProps & {
  titleContent?: ContentStack["stack"];
};

type HeaderWithTitleContentProps = BaseHeaderProps & {
  hideLogo?: boolean;
  logoPosition?: Position;
  titleMargins?: TDocumentDefinitions["pageMargins"];
  titleName?: string;
};

type HeaderLogoWithPublishProps = BaseHeaderProps & {
  haveBorder?: boolean;
  hospitalFontSize?: number;
  isHospitalVertical?: boolean;
  publishContent: ContentStack["stack"];
};

// Common fields between both types
export type BaseHeaderDetail = {
  divisionName?: string;
  doctorName?: string;
  hn: string;
  number: string;
  patient_age: string; // 20 ปี 5 เดือน 14 วัน
  patient_birthdate: string;
  patient_gender_name: string;
  patientName: string;
  telMobile?: string;
};

// Original types with extended base interfaces
export type HeaderLogoWithPatientProps = BaseHeaderProps & {
  detail: BaseHeaderDetail & {
    address?: string;
    citizen_no?: string;
    citizen_passport?: string;
    profileImage?: string | null;
    started: string;
  };
};

export type HeaderPrintListProps = BaseHeaderProps & {
  detail: BaseHeaderDetail & {
    address?: string;
    citizen_no?: string;
    citizen_passport?: string;
    doctor_number?: string;
    patient_citizen_no?: string;
    started: string;
  };
  formName: string;
  nameFontSize?: number;
};

export type HeaderPrintListLandscapeProps = BaseHeaderProps & {
  detail: BaseHeaderDetail & {
    allergies: string;
    ipd_room: string;
    visit_date: string;
  };
  formName: string;
};

type HeaderOperativeScheduleProps = CommonProps & {
  hospitalContent: ContentStack["stack"];
  printDateContent: ContentStack["stack"];
  titleContent: ContentText["text"];
};

const FORM_NAME = "HeaderPdfFormTemplate";

const getNameCompanyTH = (company: CompanyType = CONFIG.COMPANY) =>
  company === "CU" ? "โรงพยาบาลคณะทันตแพทยศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย" : "";

const getNameCompanyEN = (company: CompanyType = CONFIG.COMPANY) =>
  company === "CU" ? "Chulalongkorn University Faculty of Dentistry Hospital" : "";

const getAddressCompany = (company: CompanyType = CONFIG.COMPANY) => {
  switch (company) {
    case "CU": {
      return {
        stack: [
          { alignment: "right", text: "โรงพยาบาลคณะทันตแพทยศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย" },
          // {
          //   text: [
          //     { alignment: "right", bold: true, text: "ที่อยู่ : " },
          //     { alignment: "right", text: "34  ถนนอังรีดูนังต์  แขวงวังใหม่  เขตปทุมวัน" },
          //   ],
          // },
          { alignment: "right", text: "34  ถนนอังรีดูนังต์  แขวงวังใหม่  เขตปทุมวัน" },
          { alignment: "right", text: "กรุงเทพมหานคร  10330  โทร 0 2218 8635" },
        ],
      };
    }
    case "SAPIENS": {
      return {
        table: {
          body: [
            [
              {
                alignment: "right",
                border: [false, false, true, false],
                stack: [
                  {
                    bold: true,
                    font: "KanitLM",
                    fontSize: 7,
                    lineHeight: 1.05,
                    text: "โรงพยาบาลเซเปี้ยนซ์",
                  },
                  {
                    font: "KanitLM",
                    fontSize: 7,
                    lineHeight: 1.05,
                    noWrap: true,
                    text: "498/7 ซอยรามคำแหง 39 (เทพลีลา 1)",
                  },
                  {
                    font: "KanitLM",
                    fontSize: 7,
                    lineHeight: 1.05,
                    noWrap: true,
                    text: "แขวงวังทองหลาง เขตวังทองหลาง",
                  },
                  {
                    font: "KanitLM",
                    fontSize: 7,
                    lineHeight: 1.05,
                    text: "กรุงเทพมหานคร 10310",
                  },
                ],
              },
              {
                alignment: "right",
                border: [true, false, false, false],
                stack: [
                  {
                    bold: true,
                    font: "Poppins",
                    fontSize: 7,
                    lineHeight: 1.175,
                    text: "Sapiens Pain Hospital",
                  },
                  {
                    font: "Poppins",
                    fontSize: 7,
                    lineHeight: 1.175,
                    noWrap: true,
                    text: "498/7 Soi Ramkhamhaeng 39 (Thepleela 1)",
                  },
                  {
                    font: "Poppins",
                    fontSize: 7,
                    lineHeight: 1.175,
                    noWrap: true,
                    text: "Wang Thonglang, Wang thonglang",
                  },
                  {
                    font: "Poppins",
                    fontSize: 7,
                    lineHeight: 1.175,
                    text: "Bangkok 10310",
                  },
                ],
              },
            ],
            [
              {
                alignment: "right",
                border: [false, false, false, false],
                colSpan: 2,
                font: "Poppins",
                fontSize: 7,
                text: "Tel. 02-111-3703",
              },
              {},
            ],
          ],
        },
      };
    }

    default: {
      return {
        alignment: "right",
        text: `${company} Have no Address`,
      };
    }
  }
};

// Common setup functions
const setupLogoAndFont = async (props: CommonProps & { alignment?: Alignment }) =>
  CompanyLogoForm({
    alignment: props.alignment,
    font: props.font || "KanitLM",
    form: props.form || FORM_NAME,
    height: props.logoHeight || 50,
    maxWidth: props.maxWidth,
    type: props.type,
  });

const getPageMargins = (
  props: PageMarginsProps,
  options: { overrideMargins?: boolean } = {}
): TDocumentDefinitions["pageMargins"] => {
  const defaultMargin = 15;
  const defaultTop = 100;

  // ฟังก์ชันย่อยสำหรับกำหนด margins array
  const buildMarginsArray = (
    left: number = defaultMargin,
    top: number = defaultTop,
    right: number = defaultMargin,
    bottom: number = defaultMargin
  ): [number, number, number, number] => [left, top, right, bottom];

  if (props.pageMargins !== undefined) {
    if (Array.isArray(props.pageMargins) && options.overrideMargins) {
      const [originalLeft, originalTop, originalRight, originalBottom] = props.pageMargins;

      return buildMarginsArray(
        props.pageMarginLeft ?? originalLeft,
        props.pageMarginTop ?? originalTop,
        props.pageMarginRight ?? originalRight,
        props.pageMarginBottom ?? originalBottom
      );
    }

    return props.pageMargins;
  }

  return buildMarginsArray(
    props.pageMarginLeft,
    props.pageMarginTop,
    props.pageMarginRight,
    props.pageMarginBottom
  );
};

const shouldShowLogo = () => !CONFIG.HIDE_COMPANY_LOGO_PRINT;

const getCompanyName = (isVertical: boolean, fontSize: number) => ({
  stack: [
    { bold: true, fontSize, text: getNameCompanyTH() },
    { fontSize, text: getNameCompanyEN() },
  ],
});

type CalculateHeaderBasedMarginsProps = Pick<
  TDocumentDefinitions,
  "header" | "images" | "pageMargins" | "styles"
> &
  Pick<CompanyLogoLayoutConfig, "font" | "fonts" | "fontSizes" | "lineHeights">;

const calculateHeaderBasedMargins = async (headerForm: CalculateHeaderBasedMarginsProps) => {
  const headerDef: TDocumentDefinitions = {
    images: headerForm.images,
    pageMargins: [0, 0, 0, 0],
    styles: headerForm.styles,
    content:
      typeof headerForm.header === "function"
        ? headerForm.header(0, 0, {} as ContextPageSize) || []
        : headerForm.header || [],
  };

  const pdfMake = await getPdfMake(true);

  const pdfHeader = pdfMake.createPdf(headerDef);

  return {
    ...headerForm,
    pageMargins: getPageMargins(
      { ...headerForm, pageMarginTop: pdfHeader.getStream().y + 24.5214 },
      { overrideMargins: true }
    ),
  };
};

// Template #1 Logo Left and Address Right (Can Add Title Content in Center)
export const HeaderLogoWithAddress = async (props: BaseHeaderProps) => {
  const { columns, font, fontSizes, images, lineHeights } = await setupLogoAndFont({
    ...props,
    maxWidth: props.maxWidth ?? 250,
  });
  const pageMargins = getPageMargins(props);

  return {
    font,
    fontSizes,
    images: { ...images, ...props.images },
    lineHeights,
    pageMargins,
    styles: { ...props.styles },
    header: () => ({
      margin: props.headerMargins || [15, 15, 15, 0],
      table: {
        widths: shouldShowLogo() ? ["25%", "*", "auto"] : ["25%", "*", "45%"],
        body: [
          ...(columns.length > 0 ? [[{ columns }, "", getAddressCompany()]] : []),
          props.titleContent
            ? [{ colSpan: 3, stack: props.titleContent }]
            : [{ colSpan: 3, text: "" }],
        ],
      },
      layout: "noBorders", // Comment this For Checking Widths and Layout of Header
    }),
  };
};

// Template #2 Logo and Title Content Report
export const HeaderLogoWithTitleContent = async (props: HeaderWithTitleContentProps) => {
  const { columns, font, fontSizes, images, lineHeights } = await setupLogoAndFont(props);
  const pageMargins = getPageMargins(props);
  const defaultLogoPosition = props.logoPosition || { x: 20, y: 30 };

  return {
    font,
    fontSizes,
    images: { ...images, ...props.images },
    lineHeights,
    pageMargins,
    styles: { ...props.styles },
    header: () => ({
      margin: props.headerMargins || [15, 15, 15, 0],
      // Table Solution
      // table: {
      //   widths: ["10%", "90%"],
      //   body: !CONFIG.HIDE_COMPANY_LOGO_PRINT
      //   ? [
      //       [
      //         {image: "logo", width: companyLogoForm.width, height: defaultLogoHeight, absolutePosition: {x: 20, y: 24}},
      //         {
      //           stack: CONFIG.COMPANY === "CU"
      //             ? [
      //                 {text: `CHULALONGKORN UNIVERSITY, FACULTY OF DENTISTRY`, bold: true, fontSize: 18},
      //                 ...props.titleContent
      //             ]
      //             : props.titleContent
      //         }
      //       ], // issue 65030
      //   ]
      //   : [
      //     [{stack: props.titleContent, colSpan: 2}],
      //   ],
      // },
      // layout: 'noBorders', // Comment this For Checking Widths and Layout of Header

      // Stack Solution
      stack: [
        props.hideLogo
          ? { text: "" }
          : {
              absolutePosition: defaultLogoPosition,
              columns,
            },
        {
          stack:
            CONFIG.COMPANY === "CU"
              ? [
                  {
                    alignment: "center",
                    bold: true,
                    fontSize: 18,
                    margin: props.titleMargins || [0, 0, 0, 0],
                    text: props.titleName || "CHULALONGKORN UNIVERSITY, FACULTY OF DENTISTRY",
                  },
                  ...(props.titleContent || []),
                ]
              : props.titleContent,
        },
      ],
    }),
  };
};

// Tempalte #3 Header Logo + Title + Publish Detail
export const HeaderLogoWithPublishDetail = async (props: HeaderLogoWithPublishProps) => {
  const { columns, font, fontSizes, images, lineHeights } = await setupLogoAndFont(props);
  const pageMargins = getPageMargins(props);
  const defaultHospitalFontSize = props.hospitalFontSize || 10;
  const isDefaultHaveBorder = props.haveBorder ?? false;

  return {
    font,
    fontSizes,
    images: { ...images, ...props.images },
    lineHeights,
    pageMargins,
    styles: {
      ...props.styles,
    },
    header: (currentPage: number, countPage: number) => ({
      margin: props.headerMargins || [15, 15, 15, 0],
      table: {
        widths: ["auto", "*", "20%"],
        body: [
          [
            props.isHospitalVertical
              ? {
                  border: isDefaultHaveBorder ? [true, true, false, true] : [],
                  margin: [2, 1, 0, 1],
                  stack:
                    columns.length > 0
                      ? [{ columns }, getCompanyName(true, defaultHospitalFontSize)]
                      : [],
                }
              : {
                  border: isDefaultHaveBorder ? [true, true, false, true] : [],
                  columns:
                    columns.length > 0
                      ? [{ columns }, getCompanyName(false, defaultHospitalFontSize)]
                      : [],
                  margin: [2, 1, 0, 1],
                },
            {
              border: isDefaultHaveBorder ? [false, true, false, true] : [],
              stack: props.titleContent,
            },
            {
              border: isDefaultHaveBorder ? [false, true, true, true] : [],
              margin: [0, 7.5, 2, 0],
              stack: [
                ...props.publishContent,
                {
                  alignment: "right",
                  fontSize: 12,
                  text: `หน้าที่ ${currentPage}/${countPage}`,
                },
              ],
            },
          ],
        ],
      },
    }),
  };
};

// Tempalte #4 Header for FormOperativeSchedule
export const HeaderOperativeSchedule = async (props: HeaderOperativeScheduleProps) => {
  const { columns, font, fontSizes, images, lineHeights } = await setupLogoAndFont(props);
  const pageMargins = getPageMargins(props);

  return {
    font,
    fontSizes,
    images: { ...images, ...props.images },
    lineHeights,
    pageMargins,
    styles: { ...props.styles },
    header: () => ({
      margin: props.headerMargins || [15, 15, 15, 0],
      stack: [
        {
          columns: [
            { columns },
            { stack: props.hospitalContent, width: "*" },
            { stack: props.printDateContent, width: "auto" },
          ],
        },
        {
          columns: [
            {
              canvas: [
                {
                  lineColor: "#E25F9E",
                  lineWidth: 2,
                  type: "line",
                  x1: 0,
                  x2: 890,
                  y1: 5,
                  y2: 5,
                },
              ],
            },
          ],
        },
        {
          columns: [props.titleContent],
        },
      ],
    }),
  };
};

// Tempalte #5 Header Logo + Title + Patient Detail + Image
export const HeaderLogoWithPatientDetailImage = async (props: HeaderLogoWithPatientProps) => {
  const { columns, font, fontSizes, images, lineHeights } = await setupLogoAndFont({
    ...props,
    alignment: "center",
    maxWidth: 230,
  });
  const pageMargins = getPageMargins(props);

  const profileImages = await getDataURLFromURL([{ image: props.detail.profileImage || "" }]);

  return {
    font,
    fontSizes,
    images: {
      ...images,
      ...props.images,
      userProfile: profileImages[0].image || `${origin}/static/images/user.png`,
    },
    lineHeights,
    pageMargins,
    styles: {
      ...props.styles,
      fieldHeader: { alignment: "center", bold: true, fontSize: fontSizes[14.5] },
      fieldHeaderDivision: { alignment: "center", fontSize: fontSizes[13] },
      fieldHeaderKey: { bold: true, font: "PoppinsLM", fontSize: fontSizes[13] },
      fieldHeaderValue: { fontSize: fontSizes[13.5] },
    } as TDocumentDefinitions["styles"],
    header: () => ({
      stack: [
        {
          columns: [
            {
              stack: [
                {
                  columns,
                  margin: [-60, -5, 0, 0],
                },
                {
                  margin: [-60, 10, 0, 0],
                  style: "fieldHeader",
                  text: "OPD SUMMARY REPORT",
                },
                {
                  margin: [-60, -2.5, 0, 0],
                  style: "fieldHeaderDivision",
                  text: props.detail.divisionName,
                },
              ],
              width: "51%",
            },
            {
              lineHeight: lineHeights[0.9],
              marginTop: -2.5,
              stack: [
                {
                  text: [
                    { style: "fieldHeaderKey", text: "HN [EN]: " },
                    {
                      style: "fieldHeaderValue",
                      text: `${props.detail.hn} [${props.detail.number}]`,
                    },
                  ],
                },
                {
                  text: [
                    { style: "fieldHeaderKey", text: "Name: " },
                    { style: "fieldHeaderValue", text: props.detail.patientName },
                  ],
                },
                {
                  columns: [
                    {
                      text: [
                        { style: "fieldHeaderKey", text: "Birthdate: " },
                        {
                          style: "fieldHeaderValue",
                          text: beToAd(props.detail.patient_birthdate)?.format("D MMMM YYYY"),
                        },
                      ],
                      width: "auto",
                    },
                    {
                      marginLeft: 20,
                      text: [
                        { style: "fieldHeaderKey", text: "Sex: " },
                        { style: "fieldHeaderValue", text: props.detail.patient_gender_name },
                      ],
                      width: "*",
                    },
                  ],
                },
                {
                  text: [
                    { style: "fieldHeaderKey", text: "Age: " },
                    { style: "fieldHeaderValue", text: formatAge(props.detail.patient_age) },
                  ],
                },
                {
                  text: [
                    { style: "fieldHeaderKey", text: "Visit date: " },
                    {
                      style: "fieldHeaderValue",
                      text: moment(props.detail.started).format("D/M/YYYY"),
                    },
                  ],
                },
                {
                  text: [
                    { style: "fieldHeaderKey", text: "Physician: " },
                    { style: "fieldHeaderValue", text: props.detail.doctorName },
                  ],
                },
              ],
              style: "miniField",
              width: "*",
            },
            { text: "", width: 12.5 },
            {
              alignment: "center",
              height: 85,
              image: "userProfile",
              width: 70,
            },
            { text: "", width: 0 },
          ],
          margin: [10, 10, 10, 0],
        },
        {
          marginTop: 5,
          table: {
            widths: ["100%"],
            body: [[{ border: [false, true, false, false], text: "" }]],
          },
        },
      ] as ContentStack["stack"],
    }),
  };
};

export const HeaderPatientDetail = async (
  props: HeaderPrintListProps & {
    content: ContentStack["stack"];
    nameFontSize?: number;
  }
) => {
  const { columns, font, fonts, fontSizes, images, lineHeights } = await setupLogoAndFont({
    ...props,
    alignment: "center",
    maxWidth: 230,
  });

  const pageMargins = getPageMargins(props);

  return {
    font,
    fonts,
    fontSizes,
    images: {
      ...images,
      ...props.images,
    },
    lineHeights,
    pageMargins,
    styles: {
      ...props.styles,
      fieldHeader: {
        alignment: "center",
        bold: true,
        font: fonts.KANIT_LM,
        fontSize: fontSizes[14.5],
      },
      fieldHeaderDivision: { alignment: "center", font: fonts.KANIT_LM, fontSize: fontSizes[13] },
      fieldHeaderKey: { bold: true, font: fonts.POPPINS_LM, fontSize: fontSizes[13] },
      fieldHeaderValue: { font: fonts.KANIT_LM, fontSize: fontSizes[13.5] },
    } as TDocumentDefinitions["styles"],
    header: () => ({
      stack: [
        {
          columns: [
            {
              stack: [
                {
                  columns,
                  margin: [-15, -5, 0, 0],
                },
                {
                  margin: [-15, 10, 0, 0],
                  style: "fieldHeader",
                  text: props.formName,
                  ...(props.nameFontSize && { fontSize: props.nameFontSize }),
                },
                {
                  margin: [-15, -2.5, 0, 0],
                  style: "fieldHeaderDivision",
                  text: props.detail.divisionName,
                },
              ],
              width: "45%",
            },
            {
              lineHeight: lineHeights[0.9],
              marginTop: -2.5,
              stack: [
                {
                  text: [
                    { style: "fieldHeaderKey", text: "HN [EN]: " },
                    {
                      style: "fieldHeaderValue",
                      text: `${props.detail.hn} [${props.detail.number}]`,
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: [
                        { style: "fieldHeaderKey", text: "Name: " },
                        {
                          style: "fieldHeaderValue",
                          text: props.detail.patientName,
                        },
                      ],
                      width: "auto",
                    },
                    { text: "", width: "*" },
                    {
                      alignment: "right",
                      stack: [
                        {
                          alignment: "left",
                          columns: [
                            { style: "fieldHeaderKey", text: "Sex: ", width: 30 },
                            {
                              style: "fieldHeaderValue",
                              text: props.detail.patient_gender_name,
                              width: 70,
                            },
                          ],
                        },
                      ],
                      width: "auto",
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: [
                        { style: "fieldHeaderKey", text: "Birthdate: " },
                        {
                          style: "fieldHeaderValue",
                          text: beToAd(props.detail.patient_birthdate)?.format("D MMMM YYYY"),
                        },
                      ],
                      width: "auto",
                    },
                    { text: "", width: "*" },
                    {
                      alignment: "right",
                      stack: [
                        {
                          alignment: "left",
                          columns: [
                            { style: "fieldHeaderKey", text: "Age: ", width: 30 },
                            {
                              style: "fieldHeaderValue",
                              text: formatAge(props.detail.patient_age),
                              width: 70,
                            },
                          ],
                        },
                      ],
                      width: "auto",
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: [
                        { style: "fieldHeaderKey", text: "CitizenID/ Passport: " },
                        {
                          style: "fieldHeaderValue",
                          text:
                            props.detail.patient_citizen_no ||
                            props.detail.citizen_passport ||
                            props.detail.citizen_no,
                        },
                      ],
                      width: "auto",
                    },
                    { text: "", width: "*" },
                    {
                      alignment: "right",
                      stack: [
                        {
                          alignment: "left",
                          columns: [
                            { style: "fieldHeaderKey", text: "Tel: ", width: 30 },
                            {
                              style: "fieldHeaderValue",
                              text: props.detail.telMobile,
                              width: 70,
                            },
                          ],
                        },
                      ],
                      width: "auto",
                    },
                  ],
                },
                ...props.content,
              ],
              style: "miniField",
              width: "*",
            },
          ],
          margin: [10, 10, 10, 0],
        },
        {
          marginTop: 5,
          table: {
            widths: ["100%"],
            body: [[{ border: [false, true, false, false], text: "" }]],
          },
          layout: {
            hLineColor: () => "#606060",
            hLineWidth: () => 1,
            vLineWidth: () => 0,
          },
        },
      ] as ContentStack["stack"],
    }),
  };
};

// Tempalte #6 Header Logo + Title + Patient Detail
export const HeaderLogoWithPatientDetail = async (props: HeaderPrintListProps) => {
  const headerForm = await HeaderPatientDetail({
    ...props,
    content: [
      {
        columns: [
          {
            marginRight: 2.5,
            style: "fieldHeaderKey",
            text: "Address:",
            width: "auto",
          },
          {
            style: "fieldHeaderValue",
            text: props.detail.address || "",
            width: "*",
          },
        ],
      },
    ],
  });

  return calculateHeaderBasedMargins(headerForm);
};

// Template #7 Header Print List (based on Sapiens)
export const HeaderPrintList = async (props: HeaderPrintListProps) => {
  const doctorNumber = props.detail.doctor_number ? ` [${props.detail.doctor_number}]` : "";

  return HeaderPatientDetail({
    ...props,
    nameFontSize: props.nameFontSize,
    content: [
      {
        style: "fieldHeaderValue",
        text: [
          { bold: true, text: "Visit date: " },
          { text: moment(props.detail.started).format("D/M/YYYY") },
        ],
      },
      {
        style: "fieldHeaderValue",
        text: [
          { bold: true, text: "Physician: " },
          { text: `${props.detail.doctorName}${doctorNumber}` },
        ],
      },
    ],
  });
};

export const HeaderPrintListLandscape = async (props: HeaderPrintListLandscapeProps) => {
  const { columns, font, fonts, fontSizes, images, lineHeights } = await setupLogoAndFont({
    ...props,
    alignment: "left",
    maxWidth: 230,
  });

  const pageMargins = getPageMargins(props);

  const isSarabun = props.font === "THSarabunNew";

  return {
    font,
    fonts,
    fontSizes,
    images: {
      ...images,
      ...props.images,
    },
    lineHeights,
    pageMargins,
    styles: {
      ...props.styles,
      fieldHeader: { alignment: "center", bold: true, font: fonts.KANIT_LM, fontSize: fontSizes[18] },
      fieldHeaderDivision: { alignment: "center", font: fonts.KANIT_LM, fontSize: fontSizes[13] },
      fieldHeaderKey: { bold: true, font: fonts.POPPINS_LM, fontSize: isSarabun ? fontSizes[14] : fontSizes[12] },
      fieldHeaderValue: { font: fonts.KANIT_LM, fontSize: isSarabun ? fontSizes[14] : fontSizes[12] },
    } as TDocumentDefinitions["styles"],
    header: () => ([
      {
        margin: props.headerMargins || [0, 0, 0, 0],
        table: {
          widths: ["60%", "40%"],
          body: [
            [
              {
                stack: [
                  {
                    columns,
                    margin: [5, 0, 0, 8],
                  },
                  {
                    margin: [16, 10, 0, 0],
                    table: {
                      body: [
                        [
                          {
                            margin: [16, 0, 16, 0],
                            style: "fieldHeader",
                            text: props.formName
                          }
                        ]
                      ]
                    },
                  }
                ]
              },
              {
                stack: [
                  {
                    columns: [
                      {
                        style: "fieldHeaderKey",
                        text: "Name:",
                        width: "auto",
                      },
                      {
                        canvas: [
                          isSarabun ? {
                            lineWidth: 1,
                            type: "line", x1: 0,
                            x2: 176, y1: 15,
                            y2: 15
                          }
                          : {
                            lineWidth: 1,
                            type: "line", x1: 0,
                            x2: 176, y1: 13,
                            y2: 13
                          }
                        ],
                        width: "auto"
                      },
                      {
                        style: "fieldHeaderKey",
                        text: "HN:",
                        width: "auto",
                      },
                      {
                        canvas: [
                          isSarabun ? {
                            lineWidth: 1,
                            type: "line", x1: 0,
                            x2: 100, y1: 15,
                            y2: 15
                          } : {
                            lineWidth: 1,
                            type: "line", x1: 0,
                            x2: 100, y1: 13,
                            y2: 13
                          }
                        ],
                        width: "auto"
                      },
                    ],
                    width: "auto"
                  },
                  {
                    columns: [
                      {
                        style: "fieldHeaderKey",
                        text: "Date of Birth:",
                        width: "auto",
                      },
                      {
                        canvas: [
                          isSarabun ? {
                            lineWidth: 1,
                            type: "line", x1: 0,
                            x2: 100, y1: 15,
                            y2: 15
                          } : {
                            lineWidth: 1,
                            type: "line", x1: 0,
                            x2: 100, y1: 13,
                            y2: 13
                          },
                        ],
                        width: "auto"
                      },
                      {
                        style: "fieldHeaderKey",
                        text: "Age:",
                        width: "auto",
                      },
                      {
                        canvas: [
                          isSarabun ? {
                            lineWidth: 1,
                            type: "line", x1: 0,
                            x2: 50, y1: 15,
                            y2: 15
                          } : {
                            lineWidth: 1,
                            type: "line", x1: 0,
                            x2: 50, y1: 13,
                            y2: 13
                          }
                        ],
                        width: "auto"
                      },
                      {
                        style: "fieldHeaderKey",
                        text: "Gender:",
                        width: "auto",
                      },
                      {
                        canvas: [
                          isSarabun ? {
                            lineWidth: 1,
                            type: "line", x1: 0,
                            x2: 56, y1: 15,
                            y2: 15
                          } : {
                            lineWidth: 1,
                            type: "line", x1: 0,
                            x2: 56, y1: 13,
                            y2: 13
                          }
                        ],
                        width: "auto"
                      },
                    ],
                    width: "auto"
                  },
                  {
                    columns: [
                      {
                        style: "fieldHeaderKey",
                        text: "VN/ AN:",
                        width: "auto",
                      },
                      {
                        canvas: [
                          isSarabun ? {
                            lineWidth: 1,
                            type: "line", x1: 0,
                            x2: 120, y1: 15,
                            y2: 15
                          } : {
                            lineWidth: 1,
                            type: "line", x1: 0,
                            x2: 120, y1: 13,
                            y2: 13
                          }
                        ],
                        width: "auto"
                      },
                      {
                        style: "fieldHeaderKey",
                        text: "Visit Date:",
                        width: "auto",
                      },
                      {
                        canvas: [
                          isSarabun ? {
                            lineWidth: 1,
                            type: "line", x1: 0,
                            x2: 120, y1: 15,
                            y2: 15
                          } : {
                            lineWidth: 1,
                            type: "line", x1: 0,
                            x2: 120, y1: 13,
                            y2: 13
                          }
                        ],
                        width: "auto"
                      },
                    ],
                    width: "auto"
                  },
                  {
                    columns: [
                      {
                        style: "fieldHeaderKey",
                        text: "Attending Physician:",
                        width: "auto",
                      },
                      {
                        canvas: [
                          isSarabun ? {
                            lineWidth: 1,
                            type: "line", x1: 0,
                            x2: 150, y1: 15,
                            y2: 15
                          } : {
                            lineWidth: 1,
                            type: "line", x1: 0,
                            x2: 150, y1: 13,
                            y2: 13
                          }
                        ],
                        width: "auto"
                      },
                      {
                        style: "fieldHeaderKey",
                        text: "Room:",
                        width: "auto",
                      },
                      {
                        canvas: [
                          isSarabun ? {
                            lineWidth: 1,
                            type: "line", x1: 0,
                            x2: 50, y1: 15,
                            y2: 15
                          } : {
                            lineWidth: 1,
                            type: "line", x1: 0,
                            x2: 50, y1: 13,
                            y2: 13
                          }
                        ],
                        width: "auto"
                      },
                    ],
                    width: "auto"
                  },
                  {
                    columns: [
                      {
                        style: "fieldHeaderKey",
                        text: "Allergies:",
                        width: "auto",
                      },
                      {
                        canvas: [
                          isSarabun ? {
                            lineWidth: 1,
                            type: "line", x1: 0,
                            x2: 279, y1: 15,
                            y2: 15
                          } : {
                            lineWidth: 1,
                            type: "line", x1: 0,
                            x2: 279, y1: 13,
                            y2: 13
                          }
                        ],
                        width: "auto"
                      },
                    ],
                    width: "auto"
                  },
                ]
              }
            ]
          ]
        },
        layout: "noBorders",
      },
      // Position
      {
        absolutePosition: {x: 540, y: 2},
        style: "fieldHeaderValue",
        text: props.detail.patientName
      },
      {
        absolutePosition: {x: 740, y: 2},
        style: "fieldHeaderValue",
        text: props.detail.hn
      },
      {
        absolutePosition: {x: 600, y: isSarabun ? 20 : 17},
        style: "fieldHeaderValue",
        text: props.detail.patient_birthdate
      },
      {
        absolutePosition: {x: 705, y: isSarabun ? 20 : 17},
        style: "fieldHeaderValue",
        text: props.detail.patient_age
      },
      {
        absolutePosition: {x: 790, y: isSarabun ? 20 : 17},
        style: "fieldHeaderValue",
        text: props.detail.patient_gender_name
      },
      {
        absolutePosition: {x: 590, y: isSarabun ? 38 : 32},
        style: "fieldHeaderValue",
        text: props.detail.number || "-"
      },
      {
        absolutePosition: {x: 740, y: isSarabun ? 38 : 32},
        style: "fieldHeaderValue",
        text: props.detail.visit_date
      },
      {
        absolutePosition: {x: 600, y: isSarabun ? 56 : 45},
        style: "fieldHeaderValue",
        text: props.detail.doctorName
      },
      {
        absolutePosition: {x: 780, y: isSarabun ? 56 : 45},
        noWrap: true,
        style: "fieldHeaderValue",
        text: props.detail.ipd_room || "-"
      },
      {
        absolutePosition: {x: 550, y: isSarabun ? 74 : 60},
        noWrap: true,
        style: "fieldHeaderValue",
        text: truncateTextSimple(props.detail.allergies, isSarabun ? 580: 400, isSarabun ? fontSizes[14] : fontSizes[12]) || "-"
      },
    ])
  }
}

/* ------------------------------------------------------ */

/*                          Utils                         */

/* ------------------------------------------------------ */
const NUMBER_REGEX = /\d+/;

const formatAge = (ageString: string) => {
  // แยกข้อมูลปี เดือน วัน
  const [years, months, days] = ageString
    .split(" ")
    .filter((part) => NUMBER_REGEX.exec(part))
    .map((part) => Number.parseInt(part));

  // สร้างรูปแบบใหม่
  return `${years} Y ${months} M ${days} D`;
};

export const truncateTextSimple = (text: string, maxWidth: number, fontSize: number) => {
  const charWidth = fontSize * 0.6; // ประมาณ

  const maxChars = Math.floor(maxWidth / charWidth);

  if (text.length > maxChars) {
    return `${text.slice(0, Math.max(0, maxChars - 3)) }...`;
  }

  return text;
};