import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Input
} from 'semantic-ui-react'

const SubUserActionLogUX = (props: any) => {
    return(
      <div
        style={{display: "flex", margin: "10px 0px",gap: "20px"}}>
        <div
          style={{flex: "0 0 33%",display:"flex",alignItems:"center"}}>
          
          <div
            style={{flex: `0 0 16%`,  fontWeight: "bolder", fontSize:props.fontSize ?? "larger",}}>
            {props.typeName}
          </div>
          <Input
            disabled={props.disabled}
            style={{width: "75%"}}
            value={props.name}>
          </Input>
        </div>
        <div
          style={{flex: "0 0 33%",display:"flex",alignItems:"center"}}>
          
          <div
            style={{flex: `0 0 ${props.timeWidth || "15%"}`, fontWeight: "bolder", fontSize: props.fontSize ?? "larger"}}>
            {props.timeLabel || "เวลา"}
          </div>
          <Input
            disabled={props.disabled}
            style={{width: "75%"}}
            value={props.datetime}>
          </Input>
        </div>
        <div
          style={{flex: "0 0 33%",display:"flex",alignItems:"center"}}>
          
          <div
            style={{flex:  `0 0 ${props.divisionWidth || "15%"}`, fontWeight: "bolder", fontSize: props.fontSize ?? "larger"}}>
            {props.divisionLabel || "แผนก"}
          </div>
          <Input
            disabled={props.disabled}
            style={{width: "75%"}}
            value={props.division}>
          </Input>
        </div>
      </div>
    )
}


export default SubUserActionLogUX

export const screenPropsDefault = {}

/* Date Time : Thu Mar 21 2024 11:27:48 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: \"flex\", margin: \"10px 0px\",gap: \"20px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: \"0 0 33%\",display:\"flex\",alignItems:\"center\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: \"0 0 33%\",display:\"flex\",alignItems:\"center\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: \"0 0 33%\",display:\"flex\",alignItems:\"center\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.typeName"
        },
        "style": {
          "type": "code",
          "value": "{flex: `0 0 16%`,  fontWeight: \"bolder\", fontSize:props.fontSize ?? \"larger\",}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "code",
          "value": "props.timeLabel || \"เวลา\""
        },
        "style": {
          "type": "code",
          "value": "{flex: `0 0 ${props.timeWidth || \"15%\"}`, fontWeight: \"bolder\", fontSize: props.fontSize ?? \"larger\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "code",
          "value": "props.divisionLabel || \"แผนก\""
        },
        "style": {
          "type": "code",
          "value": "{flex:  `0 0 ${props.divisionWidth || \"15%\"}`, fontWeight: \"bolder\", fontSize: props.fontSize ?? \"larger\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "Input",
      "parent": 1,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disabled"
        },
        "fluid": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"75%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.name"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "Input",
      "parent": 2,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disabled"
        },
        "fluid": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"75%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.datetime"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "Input",
      "parent": 3,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disabled"
        },
        "fluid": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"75%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.division"
        }
      },
      "seq": 12,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "SubUserActionLogUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
