import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Input,
  Button,
  Form,
  FormGroup,
  FormField,
  TextArea
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const ANES_AnesComplication05 = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{ margin: "0px 0px 50px" }}>
        <div
          style={{height:"50px"}}>
          
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(1, 1fr)","gridTemplateColumns":"repeat(40, 1fr)"}}>
            <div
              style={{ gridRow: "1/2", gridColumn: "1/41",display: "flex" , border: "solid 1px rgb(200, 200, 200, 0.5)","backgroundColor":"rgb(161, 221, 230, 0.49)", justifyContent: "space-between", alignItems: "center"}}>
              <label
                style={{textAlign:"center",marginLeft:"20px","fontWeight":"bold","fontSize":"16px"}}>
                ลงชื่อผู้ประเมินและข้อมูลส่งต่อ
              </label>
              <div
                style={{ margin: "5px", display: props.buttonStatus === "CONFIRMED" ?  "none" : "", }}>
                {props.buttonSave}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{height:"155px"}}>
          
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(4, 1fr)","gridTemplateColumns":"repeat(1, 1fr)"}}>
            <div
              style={{ gridRow: "1/2", gridColumn: "1/2",display: "flex"}}>
              <label
                style={{textAlign:"center",marginLeft:"20px",paddingTop:"17px","fontSize":"16px"}}>
                รหัสเจ้าหน้าที่
              </label>
              <label
                style={{textAlign:"center",paddingTop:"20px","fontSize":"16px",color:"red"}}>
                *
              </label>
              <Input
                onChange={props?.handleCodeChange}
                style={{marginLeft:"1rem",paddingTop:"10px",width:"55%"}}
                value={props.code}>
              </Input>
              <Button
                color="blue"
                onClick={props.handleAddInformationData}
                style={{marginLeft:"20px",marginTop:"10px",width:"100px"}}>
                ADD
              </Button>
            </div>
            <Form>
              <FormGroup
                inline={true}
                style={{paddingLeft: "20px", marginTop: "1rem"}}>
                <FormField
                  inline={true}
                  style={{alignItems: "flex-start"}}
                  width={8}>
                  <label
                    style={{minWidth: "max-content", fontWeight: "normal", fontSize: "1.15rem"}}>
                    ข้อมูลส่งต่อ
                  </label>
                  <TextArea
                    onChange={props?.handleTransferChange}
                    value={props.transfer}>
                  </TextArea>
                </FormField>
                <FormField
                  inline={true}
                  style={{alignItems: "flex-start"}}
                  width={8}>
                  <label
                    style={{minWidth: "max-content", fontWeight: "normal"}}>
                    Comment
                  </label>
                  <TextArea
                    onChange={props?.handleCommentChange}
                    value={props.comment}>
                  </TextArea>
                </FormField>
              </FormGroup>
            </Form>
          </div>
        </div>
        <Table
          data={props.transferInformationData}
          headers="Date/Time,ผู้ประเมิน Code,ข้อมูลส่งต่อ,Comment"
          keys="datetime,code,transfer,comment"
          minRows={10}
          showPagination={false}
          style={{height: "400px"}}>
        </Table>
      </div>
    )
}


export default ANES_AnesComplication05

export const screenPropsDefault = {}

/* Date Time : Tue Apr 02 2024 13:19:37 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 0px 50px\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height:\"50px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "label": "divgrid",
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(1, 1fr)\",\"gridTemplateColumns\":\"repeat(40, 1fr)\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/41\",display: \"flex\" , border: \"solid 1px rgb(200, 200, 200, 0.5)\",\"backgroundColor\":\"rgb(161, 221, 230, 0.49)\", justifyContent: \"space-between\", alignItems: \"center\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "label",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "ลงชื่อผู้ประเมินและข้อมูลส่งต่อ"
        },
        "style": {
          "type": "code",
          "value": "{textAlign:\"center\",marginLeft:\"20px\",\"fontWeight\":\"bold\",\"fontSize\":\"16px\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height:\"155px\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "label": "divgrid",
      "name": "div",
      "parent": 42,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(4, 1fr)\",\"gridTemplateColumns\":\"repeat(1, 1fr)\"}"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 43,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/2\",display: \"flex\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "label",
      "parent": 44,
      "props": {
        "children": {
          "type": "value",
          "value": "รหัสเจ้าหน้าที่"
        },
        "style": {
          "type": "code",
          "value": "{textAlign:\"center\",marginLeft:\"20px\",paddingTop:\"17px\",\"fontSize\":\"16px\"}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "label",
      "parent": 44,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{textAlign:\"center\",paddingTop:\"20px\",\"fontSize\":\"16px\",color:\"red\"}"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "Input",
      "parent": 44,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props?.handleCodeChange"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"1rem\",paddingTop:\"10px\",width:\"55%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.code"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 56,
      "name": "Button",
      "parent": 44,
      "props": {
        "children": {
          "type": "value",
          "value": "ADD"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.handleAddInformationData"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\",marginTop:\"10px\",width:\"100px\"}"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 69,
      "name": "Table",
      "parent": 0,
      "props": {
        "columns": {
          "type": "code",
          "value": ""
        },
        "data": {
          "type": "code",
          "value": "props.transferInformationData"
        },
        "headers": {
          "type": "value",
          "value": "Date/Time,ผู้ประเมิน Code,ข้อมูลส่งต่อ,Comment"
        },
        "keys": {
          "type": "value",
          "value": "datetime,code,transfer,comment"
        },
        "minRows": {
          "type": "code",
          "value": "10"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"400px\"}"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"5px\", display: props.buttonStatus === \"CONFIRMED\" ?  \"none\" : \"\", }"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "Form",
      "parent": 43,
      "props": {
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "FormGroup",
      "parent": 71,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"20px\", marginTop: \"1rem\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "FormField",
      "parent": 72,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{alignItems: \"flex-start\"}"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "FormField",
      "parent": 72,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{alignItems: \"flex-start\"}"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "label",
      "parent": 73,
      "props": {
        "children": {
          "type": "value",
          "value": "ข้อมูลส่งต่อ"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", fontWeight: \"normal\", fontSize: \"1.15rem\"}"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "label",
      "parent": 74,
      "props": {
        "children": {
          "type": "value",
          "value": "Comment"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", fontWeight: \"normal\"}"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 77,
      "name": "TextArea",
      "parent": 73,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props?.handleTransferChange"
        },
        "value": {
          "type": "code",
          "value": "props.transfer"
        }
      },
      "seq": 77,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "TextArea",
      "parent": 74,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props?.handleCommentChange"
        },
        "value": {
          "type": "code",
          "value": "props.comment"
        }
      },
      "seq": 78,
      "void": true
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "ANES_AnesComplication05",
  "project": "IsHealth_by_Front-end",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
