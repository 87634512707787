import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from "react-table-6";
import _ from 'lodash';

export const formatComma = val => (
  !isNaN(parseFloat(val))
    ? parseFloat(val).toFixed(2).toString().replace(/\B(?=(?:\d{3})+(?!\d))/g, ',')
    : val
);

export default class PureReactTable extends React.PureComponent {
  constructor() {
    super()
    this.clickTimer = null;
    this.clickCount = 0;
  }
  
  static propTypes = {
    selectedRow: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    loading: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.object),
    columns: PropTypes.arrayOf(PropTypes.object),
    page: PropTypes.number,
    pages: PropTypes.number,
    pageSize: PropTypes.number,
    style: PropTypes.object,
    onPageSizeChange: PropTypes.func,
    onPageChange: PropTypes.func,
    onClick: PropTypes.func,
    onEnter: PropTypes.func,
  };

  static defaultProps = {
    loading: false,
    data: [],
    columns: [],
    selectedRow: null,
    onSelect() {},
    onEnter() {},
  };

  render() {
    const {
      height,
      onSelect,
      onEnter,
      selectedRow,
      data,
      getTrProps = () => ({}),
      ...rest
    } = this.props;

    const noId = _.find(data, item => typeof item.id === 'undefined');

    if (noId) {
      throw new Error(`
        Every row should have an 'id' field\n
        got: ${JSON.stringify(noId)}
      `);
    }

    return (
      <ReactTable
        manual
        className='-striped -highlight'
        getTrProps={(state, rowInfo, column, instance) => {
          if (rowInfo && rowInfo.row) {
            const id = rowInfo.original.id;
            const options = getTrProps(state, rowInfo, column, instance);
            let selectedRowId = null;

            if (selectedRow && typeof selectedRow === 'object') {
              selectedRowId = selectedRow.id;
            }
            if (selectedRow && typeof selectedRow === 'number') {
              selectedRowId = selectedRow;
            }
            return {
              ...options,
              onClick: (event, handleOriginal) => {
                if (handleOriginal) {
                  handleOriginal();
                }

                this.clickCount++;
                if (this.clickCount === 1) {
                  this.clickTimer = setTimeout(() => {
                    this.clickCount = 0;
                    // Single Click
                    onSelect(rowInfo.original);
                  }, 400)
                } else if (this.clickCount === 2) {
                  clearTimeout(this.clickTimer);
                  this.clickCount = 0;
                  // Double Click
                  onEnter(rowInfo.original);
                }
              },
              onDoubleClick(event, handleOriginal) {
                if (handleOriginal) {
                  handleOriginal();
                }
                onEnter(rowInfo.original);
              },
              onKeyUp(event) {
                // TODO: handle keyboard navigation
              },
              className: id === selectedRowId ? 'selected' : '',
            };
          }
          return {};
        }}
        data={data}
        {...rest}
      />
    );
  }
}


export const alignRight = val => <div style={{textAlign: 'right'}}>{val}</div>;
export const alignCenter = val => <div style={{textAlign: 'center'}}>{val}</div>;
export const formatPrice = val => <div style={{textAlign: 'right'}}>{formatComma(val)}</div>;
