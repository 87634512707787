import WasmController from "react-lib/frameworks/WasmController";

// APIs
// CORE
import PackageOrderList from "issara-sdk/apis/PackageOrderList_core";
// BIL
import EncounterPackageOrderMatchedList from "issara-sdk/apis/EncounterPackageOrderMatchedList_apps_BIL";
import PackageApplyPolicyList from "issara-sdk/apis/PackageApplyPolicyList_apps_BIL";
import PackageApplyPolicyDetail from "issara-sdk/apis/PackageApplyPolicyDetail_apps_BIL";
import PackageApplyPolicySequenceView from "issara-sdk/apis/PackageApplyPolicySequenceView_apps_BIL";
import PackageApplyPolicyApplyView from "issara-sdk/apis/PackageApplyPolicyApplyView_apps_BIL";

// Serializer

// Interface
import { State as MainState } from "../../../../../HIS/MainHISInterface";
import {
  SetErrorMessage,
  SetProperty,
  mapOptions,
} from "../../common/CommonInterface";
import {
  PackageOrderSerializer,
  ORDER_PAYMENT_STATUS,
  USAGE_STATUS,
} from "./PackagePurchase";

export type State = Partial<{
  // sequence
  ApplyPolicyPackageSequence: Partial<{
    sequenceIndex: "Start" | "Action" | null;
    applyPolicyList: PackageApplyPolicyApplySerializer[];
    // * package order ทั้งหมด
    packageOrderList: PackageOrderSerializer[];
    // * package ที่สามารถ apply ได้
    packageOptions: OptionType[];
    applyPackageDetail: Partial<ApplyPackageDetailType> | null;
  }> | null;
}>;

type PickedState = Partial<
  Pick<MainState, "buttonLoadCheck" | "errorMessage" | "selectedPatient">
>;

export type PickedProps = Partial<Omit<PickedState, "selectedPatient">>;

export type ApplyPackageDetailType = {
  packageId: number;
  encounterPackageOrderList: EncounterPackageOrderMatchedSerializer[];
  targetEncounters: number[];
  packageApplyPolicyId: number;
};

type PackageApplyPolicyApplySerializer = {
  id: number;
  package_order: number;
  sequence: number;
  created_at: string;
  edited_at: string;
  active: boolean;
  created_by: number;
  edited_by: number;
  target_encounters: number[];
};

type EncounterPackageOrderMatchedSerializer = {
  id: number;
  division: number;
  division_name: string;
  doctor: number;
  doctor_name: string;
  number: string;
  performed_date: string;
  type: string;
};

export type MasterOptionsType = Record<
  (typeof Masters)[number][0],
  OptionType[]
>;

export type OptionType = {
  key: number | string;
  value: number | string;
  text: string;
};

// Sequence
type SeqState = {
  sequence: "ApplyPolicyPackage";
  restart?: boolean;
  clear?: boolean;
};

// Handle Action
type ActionType =
  // Search
  | { action: "SEARCH"; fetchPurchaseList?: boolean }
  // Action
  | { action: "ADD_ITEM" }
  | {
      action: "CHANGE_PACKAGE";
      packageId: number;
      packageApplyPolicyId?: number;
      targetEncounters?: number[];
    }
  | { action: "EDIT"; data: PackageApplyPolicyApplySerializer }

  // Method
  | { action: "SAVE"; card: string }
  | { action: "CONFIRM"; card: string }
  | {
      action: "UPDATE_SEQUENCE";
      data: { data: any[]; type: string; index: number };
      card: string;
    }
  | {
      action: "DELETE";
      orderId: number;
      card: string;
      index: number;
      // callback
      onSuccess?: () => any;
    };

type SeqAct = ActionType & SeqState;
type SeqType<K> = K extends { action: string } ? Extract<SeqAct, K> : SeqState;

export type RunSequence = <K extends keyof SeqAct>(
  params: SeqType<Pick<SeqAct, K>>
) => any;

export type SetProp = SetProperty<State & PickedState>;

type CustomExtract<T, U> = T extends T
  ? U extends Partial<T>
    ? T
    : never
  : never;

type Params<A extends ActionType["action"]> = CustomExtract<
  ActionType,
  { action: A }
>;

export const StateInitial: State = {
  // sequence
  ApplyPolicyPackageSequence: {
    sequenceIndex: null,
  },
};

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division?: number;
  device?: number;
};

export const DataInitial = {};

const Masters = [] as const;

type Handler<P = any, R = any> = (
  controller: WasmController<State & PickedState, Event, Data>,
  params: P
) => R;

/* ------------------------------------------------------ */

/*                          START                         */

/* ------------------------------------------------------ */
export const GetMaster: Handler<SeqState> = async (controller) => {
  const state = controller.getState();

  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: Masters,
    },
  } as any);

  controller.setState(
    {
      ApplyPolicyPackageSequence: {
        ...state.ApplyPolicyPackageSequence,
        sequenceIndex: "Action",
      },
    },
    () => Action(controller, { action: "SEARCH", fetchPurchaseList: true })
  );
};

/* ------------------------------------------------------ */

/*                      Handle Action                     */

/* ------------------------------------------------------ */
export const Action: Handler<ActionType> = async (controller, params) => {
  if (params.action === "ADD_ITEM") {
    HandleAddItem(controller, params);
  } else if (params.action === "SEARCH") {
    HandleSearch(controller, params);
  } else if (params.action === "CHANGE_PACKAGE") {
    HandleChangePackage(controller, params);
  } else if (params.action === "SAVE") {
    HandleSave(controller, params);
  } else if (params.action === "DELETE") {
    HandleDelete(controller, params);
  } else if (params.action === "EDIT") {
    HandleEdit(controller, params);
  } else if (params.action === "UPDATE_SEQUENCE") {
    HandleUpdateSequence(controller, params);
  } else if (params.action === "CONFIRM") {
    HandleConfirm(controller, params);
  }
};

const HandleSearch: Handler<Params<"SEARCH">> = async (controller, params) => {
  let state = controller.getState();
  const patientId = state.selectedPatient?.id;

  const promiseArr = [
    PackageApplyPolicyList.list({
      apiToken: controller.apiToken,
      params: { patient: patientId },
    }),
  ];

  if (params.fetchPurchaseList) {
    promiseArr.push(
      PackageOrderList.list({
        apiToken: controller.apiToken,
        params: { patient: patientId },
      })
    );
  }

  const [[applyPolicy], packageOrder] = await Promise.all(promiseArr);

  state = controller.getState();

  const sortedItems = (applyPolicy?.items || []).sort(
    (a: any, b: any) => a.sequence - b.sequence
  );

  const items: any[] = !params.fetchPurchaseList
    ? state.ApplyPolicyPackageSequence?.packageOrderList
    : packageOrder?.[0]?.items || [];

  const filterPackage = items.filter(
    (item) =>
      item.usage_status === USAGE_STATUS.INCOMPLETED &&
      item.status === ORDER_PAYMENT_STATUS.PAID
  );

  const packageOptions = filterPackage.map((item) => ({
    key: item.id,
    text: `[${item.product_code}] ${item.product_name}`,
    value: item.id,
  }));

  controller.setState({
    ApplyPolicyPackageSequence: {
      ...state.ApplyPolicyPackageSequence,
      applyPolicyList: sortedItems,
      packageOrderList: items,
      packageOptions,
    },
  });
};

const HandleAddItem: Handler<Params<"ADD_ITEM">> = async (controller) => {
  const state = controller.getState();

  const packageId =
    state.ApplyPolicyPackageSequence?.packageOptions?.[0]?.value;

  if (typeof packageId === "number") {
    Action(controller, { action: "CHANGE_PACKAGE", packageId: packageId });
  }
};

const HandleChangePackage: Handler<Params<"CHANGE_PACKAGE">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  const [encounter] = await EncounterPackageOrderMatchedList.list({
    apiToken: controller.apiToken,
    params: params.packageApplyPolicyId
      ? { package_apply_policy: params.packageApplyPolicyId }
      : {
          package_order: params.packageId,
        },
  });

  controller.setState({
    ApplyPolicyPackageSequence: {
      ...state.ApplyPolicyPackageSequence,
      applyPackageDetail: {
        encounterPackageOrderList: encounter?.items || [],
        packageId: params.packageId,
        targetEncounters: params.targetEncounters || [],
        packageApplyPolicyId: params.packageApplyPolicyId,
      },
    },
  });
};

const HandleSave: Handler<Params<"SAVE">> = async (controller, params) => {
  const state = controller.getState();

  const detail = state.ApplyPolicyPackageSequence?.applyPackageDetail || {};

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [params.card]: "LOADING" },
  });

  const api = detail.packageApplyPolicyId
    ? PackageApplyPolicyDetail.update
    : PackageApplyPolicyList.create;

  const [_, error] = await api({
    apiToken: controller.apiToken,
    pk: detail.packageApplyPolicyId,
    data: {
      patient: state.selectedPatient?.id,
      package_order: detail.packageId,
      target_encounters: detail.targetEncounters || [],
    } as any,
  });

  if (error) {
    SetErrorMessage(controller, { ...params, action: "", error });
  } else {
    controller.setState(
      {
        ApplyPolicyPackageSequence: {
          ...state.ApplyPolicyPackageSequence,
          applyPackageDetail: null,
        },
        buttonLoadCheck: { ...state.buttonLoadCheck, [params.card]: null },
        errorMessage: { ...state.errorMessage, [params.card]: null },
      },
      () => Action(controller, { action: "SEARCH" })
    );
  }
};

const HandleDelete: Handler<Params<"DELETE">> = async (controller, params) => {
  const state = controller.getState();

  const btnKey = `${params.card}_${params.action}_${params.index}`;

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "LOADING" },
  });

  const [_, error] = await PackageApplyPolicyDetail.delete({
    apiToken: controller.apiToken,
    pk: params.orderId,
  });

  if (error) {
    SetErrorMessage(controller, {
      ...params,
      btnAction: `${params.action}_${params.index}`,
      error,
      btnError: "",
    });
  } else {
    controller.setState(
      {
        buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: null },
        errorMessage: { ...state.errorMessage, [params.card]: null },
      },
      () => Action(controller, { action: "SEARCH" })
    );

    params.onSuccess?.();
  }
};

const HandleEdit: Handler<Params<"EDIT">> = async (controller, params) => {
  Action(controller, {
    action: "CHANGE_PACKAGE",
    packageId: params.data.package_order,
    packageApplyPolicyId: params.data.id,
    targetEncounters: params.data.target_encounters,
  });
};

const HandleUpdateSequence: Handler<Params<"UPDATE_SEQUENCE">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  const data = params.data;
  const btnKey = `${params.card}_${params.action}_${data.type}_${data.index}`;

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "LOADING" },
  });

  const [_, error] = await PackageApplyPolicySequenceView.put({
    apiToken: controller.apiToken,
    data: { items: data.data },
  });

  if (error) {
    SetErrorMessage(controller, {
      ...params,
      btnAction: `${params.action}_${data.type}_${data.index}`,
      error,
      btnError: "",
    });
  } else {
    controller.setState(
      {
        buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: null },
        errorMessage: { ...state.errorMessage, [params.card]: null },
      },
      () => Action(controller, { action: "SEARCH" })
    );
  }
};

const HandleConfirm: Handler<Params<"CONFIRM">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  const btnKey = `${params.card}_${params.action}`;

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "LOADING" },
  });

  const [_, error] = await PackageApplyPolicyApplyView.post({
    apiToken: controller.apiToken,
    data: {
      patient: state.selectedPatient?.id,
    },
  });

  if (error) {
    SetErrorMessage(controller, { ...params, error });
  } else {
    controller.setState(
      {
        buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "SUCCESS" },
        errorMessage: { ...state.errorMessage, [params.card]: null },
      },
      () => Action(controller, { action: "SEARCH" })
    );
  }
};
