import React, { useEffect, useRef, useState, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import CardLayout from "../../common/CardLayout";
import PureReactTable from "../../common/PureReactTable";
import { Button, TextArea } from "semantic-ui-react";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import * as Util from "../../../utils";
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import { momentToStringBE } from "react-lib/utils/dateUtils";
import { useIntl } from "react-intl";

const style = {
  buttonBG: {
    backgroundColor: "#5DBCD2",
    color: "white"
  }
};

const beFormatDate = "DD/MM/YYYY";
const beFormatTime = "HH:mm";
const DEFAULT_NOTE = {
  id: "mockId"
};

const CardProgressNote = React.forwardRef((props, ref) => {
  const intl = useIntl();
  const isMounted = useRef(true);
  const [loading, setLoading] = useState(false);

  const dateRef = React.useRef([]);
  const sRef = React.useRef();
  const oRef = React.useRef();
  const aRef = React.useRef();
  const pRef = React.useRef();

  const [progressNoteList, setProgressNoteList] = useState([DEFAULT_NOTE]);
  const [originalNote, setOriginalNote] = useState([])
  const [selectedCell, setSelectedCell] = useState({});
  const [stateError, setError] = useState(null)

  useImperativeHandle(ref, () => ({
    getNote: () => {
      getNote()
    }
  }))

  const columns = [
    {
      Header: "วันเวลาที่บันทึก",
      accessor: "created",
      width: 160,
      Cell: row => {
        return (
          <label
            ref={el => (dateRef.current[row.index] = el)}
            style={{ width: "100%" }}
          >
            {row.original.created
              ? Util.formatDatetime(row.original.created)
              : momentToStringBE(moment())}
          </label>
        );
      }
    },
    {
      Header: "S: Subjective",
      accessor: "s_txt",
      minWidth: 150,
      Cell: row => {
        if (
          selectedCell &&
          selectedCell.hasOwnProperty("s_txt") &&
          row.index === selectedCell.s_txt
        ) {
          return (
            <TextArea
              autoFocus
              ref={el => (sRef.current = el)}
              style={{ width: "100%" }}
              onBlur={e => {
                const { value } = e.target;
                if (
                  progressNoteList[row.index].s_txt ===
                  sRef.current.ref.current.value ||
                  !sRef.current.ref.current.value
                ) {
                  return setSelectedCell({});
                }
                handleSetData({ value, field: "s_txt", index: row.index });
              }}
              onKeyPress={e => {
                const { value } = e.target;
                if (e.key === "Enter" && e.shiftKey) {
                  let text = sRef.current.ref.current.value;
                  e.preventDefault();
                  sRef.current.ref.current.value = text + "\n";
                } else if (e.key === "Enter") {
                  if (
                    progressNoteList[row.index].s_txt ===
                    sRef.current.ref.current.value ||
                    !sRef.current.ref.current.value
                  ) {
                    return setSelectedCell({});
                  }
                  handleSetData({
                    value,
                    field: "s_txt",
                    index: row.index
                  });
                }
              }}
            />
          );
        }
        else {
          return <label>{row.original.s_txt}</label>;
        }
      }
    },
    {
      Header: "O: Objective",
      accessor: "o_txt",
      minWidth: 150,
      Cell: row => {
        if (
          selectedCell &&
          selectedCell.hasOwnProperty("o_txt") &&
          row.index === selectedCell.o_txt
        ) {
          return (
            <TextArea
              autoFocus
              ref={el => (oRef.current = el)}
              style={{ width: "100%" }}
              onBlur={e => {
                const { value } = e.target;
                if (
                  progressNoteList[row.index].o_txt ===
                  oRef.current.ref.current.value ||
                  !oRef.current.ref.current.value
                ) {
                  return setSelectedCell({});
                }
                handleSetData({ value, field: "o_txt", index: row.index });
              }}
              onKeyPress={e => {
                const { value } = e.target;
                if (e.key === "Enter" && e.shiftKey) {
                  let text = oRef.current.ref.current.value;
                  e.preventDefault();
                  oRef.current.ref.current.value = text + "\n";
                } else if (e.key === "Enter") {
                  if (
                    progressNoteList[row.index].o_txt ===
                    oRef.current.ref.current.value ||
                    !oRef.current.ref.current.value
                  ) {
                    return setSelectedCell({});
                  }
                  handleSetData({
                    value,
                    field: "o_txt",
                    index: row.index
                  });
                }
              }}
            />
          );
        }
        else {
          return <label>{row.original.o_txt}</label>;
        }
      }
    },
    {
      Header: "A: Assessment",
      accessor: "a_txt",
      minWidth: 150,
      Cell: row => {
        if (
          selectedCell &&
          selectedCell.hasOwnProperty("a_txt") &&
          row.index === selectedCell.a_txt
        ) {
          return (
            <TextArea
              autoFocus
              ref={el => (aRef.current = el)}
              style={{ width: "100%" }}
              onBlur={e => {
                const { value } = e.target;
                if (
                  progressNoteList[row.index].a_txt ===
                  aRef.current.ref.current.value ||
                  !aRef.current.ref.current.value
                ) {
                  return setSelectedCell({});
                }
                handleSetData({ value, field: "a_txt", index: row.index });
              }}
              onKeyPress={e => {
                const { value } = e.target;
                if (e.key === "Enter" && e.shiftKey) {
                  let text = aRef.current.ref.current.value;
                  e.preventDefault();
                  aRef.current.ref.current.value = text + "\n";
                } else if (e.key === "Enter") {
                  if (
                    progressNoteList[row.index].a_txt ===
                    aRef.current.ref.current.value ||
                    !aRef.current.ref.current.value
                  ) {
                    return setSelectedCell({});
                  }
                  handleSetData({
                    value,
                    field: "a_txt",
                    index: row.index
                  });
                }
              }}
            />
          );
        }
        else {
          return <label>{row.original.a_txt}</label>;
        }
      }
    },
    {
      Header: "P:Plan",
      accessor: "p_txt",
      minWidth: 150,
      Cell: row => {
        if (
          selectedCell &&
          selectedCell.hasOwnProperty("p_txt") &&
          row.index === selectedCell.p_txt
        ) {
          return (
            <TextArea
              autoFocus
              ref={el => (pRef.current = el)}
              style={{ width: "100%" }}
              onBlur={e => {
                const { value } = e.target;
                if (
                  progressNoteList[row.index].p_txt ===
                  pRef.current.ref.current.value ||
                  !pRef.current.ref.current.value
                ) {
                  return setSelectedCell({});
                }
                handleSetData({ value, field: "p_txt", index: row.index });
              }}
              onKeyPress={e => {
                const { value } = e.target;
                if (e.key === "Enter" && e.shiftKey) {
                  let text = pRef.current.ref.current.value;
                  e.preventDefault();
                  pRef.current.ref.current.value = text + "\n";
                } else if (e.key === "Enter") {
                  if (
                    progressNoteList[row.index].p_txt ===
                    pRef.current.ref.current.value ||
                    !pRef.current.ref.current.value
                  ) {
                    return setSelectedCell({});
                  }
                  handleSetData({
                    value,
                    field: "p_txt",
                    index: row.index
                  });
                }
              }}
            />
          );
        }
        else {
          return <label>{row.original.p_txt}</label>;
        }
      }
    }
  ];

  useEffect(() => {
    if (props.encounterId) {
      getNote();
    }
  }, [props.encounterId]);

  const handleSetData = ({ value, field, index } = {}) => {
    let newArr = [...progressNoteList];
    let newObj = { ...progressNoteList[index] };
    newObj[field] = value;
    newArr[index] = newObj;
    setProgressNoteList(newArr);
    setSelectedCell({});
  };

  const getNote = async () => {
    setLoading(true);
    const [data, error] = await props.controller.geProgressNoteList({
      apiToken: Cookies.get("apiToken") ? Cookies.get("apiToken") : props.apiToken,
      encounterId: props.encounterId
    });
    if (isMounted.current) {
      setLoading(false);
      if (error) {
        toast.error(error);
      } else {
        setProgressNoteList([...data.items, DEFAULT_NOTE]);
        setOriginalNote(data.items)
        // setProgressNoteId(data.id);
      }
    }
  };

  const handleCreateNote = async () => {
    setError(null)
    setLoading(true);
    const savingPromise = [];
    setLoading(true);
    for (var index in progressNoteList) {
      if (index === progressNoteList.length - 1) {
        continue;
      }
      let objIndex = originalNote.findIndex(
        item => item.id === progressNoteList[index].id
      );
      if (objIndex < 0) {
        continue;
      }
      if (
        originalNote[objIndex].s_txt ===
        progressNoteList[index].s_txt &&
        originalNote[objIndex].o_txt ===
        progressNoteList[index].o_txt &&
        originalNote[objIndex].a_txt ===
        progressNoteList[index].a_txt &&
        originalNote[objIndex].p_txt ===
        progressNoteList[index].p_txt
      )
        continue;
      const prom = props.controller.putProgressNote({
        apiToken: Cookies.get("apiToken") ? Cookies.get("apiToken") : props.apiToken,
        emrId: props.emrId,
        data: progressNoteList[index]
      });
      savingPromise.push(prom);
    }
    if (progressNoteList[progressNoteList.length - 1].s_txt || progressNoteList[progressNoteList.length - 1].o_txt || progressNoteList[progressNoteList.length - 1].a_txt || progressNoteList[progressNoteList.length - 1].p_txt) {
      const postProm = await props.controller.createProgressionNote({
        apiToken: Cookies.get("apiToken") ? Cookies.get("apiToken") : props.apiToken,
        data: {
          emr: props.emrId,
          s_txt: progressNoteList[progressNoteList.length - 1].s_txt,
          o_txt: progressNoteList[progressNoteList.length - 1].o_txt,
          a_txt: progressNoteList[progressNoteList.length - 1].a_txt,
          p_txt: progressNoteList[progressNoteList.length - 1].p_txt,
        }
      });
      savingPromise.push(postProm);
    }
    if (isMounted.current) {
      setLoading(false);
      Promise.all(savingPromise).then(response => {
        if (isMounted.current) {
          setLoading(false);
          let err = {};
          for (let item of response) {
            if (item[1]) {
              if (!Object.hasOwnProperty(item[2].response.status)) {
                if (typeof item[1] === "string" && item[1].length > 100) {
                  err[item[2].response.status] = "เกิดข้อผิดพลาด";
                } else {
                  err[item[2].response.status] = item[1];
                }
              }
            }
          }
          if (Object.keys(err).length > 0) {
            return setError(err);
          } else {
            getNote();
          }
        }
      });
    }
  };

  return (
    <CardLayout
      titleText="Progress Note"
      headerColor="gray"
      closeable={true}
      toggleable={false}
      hideHeaderIcon={props.hideHeaderIcon}
      loading={loading}
      onClose={props.hideCallback}
      style={props.cardStyle}
      dividerStyle={props.dividerStyle}
    >
      <ErrorMessage error={stateError} />
      {React.useMemo(
        () => (
          <PureReactTable
            data={progressNoteList}
            columns={columns}
            showPagination={false}
            pageSize={props.tablePageSize}
            manual
            style={props.tableStyle}
            getTdProps={(state, rowInfo, column, instance) => {
              return {
                onClick: (e, handleOriginal) => {
                  props.onSelectedCell(rowInfo)

                  if (props.readOnly) {
                    return;
                  }
                  if (!rowInfo || !column) {
                    return;
                  }
                  if (
                    !selectedCell ||
                    selectedCell[column.id] !== rowInfo.index
                  ) {
                    setSelectedCell({ [column.id]: rowInfo.index });
                  }
                },
                style: {
                  backgroundColor: rowInfo?.index === props.selectedRowIndex ? "rgba(93, 188, 210, 0.5)" : ""
                }
              };
            }}
          />
        ),
        [progressNoteList, selectedCell, props.selectedRowIndex]
      )}
      <Button fluid style={{ ...style.buttonBG, display: props.readOnly ? "none" : null }} onClick={handleCreateNote}>{intl.formatMessage({ id: "บันทึก" })}</Button>
    </CardLayout>
  );
});

CardProgressNote.defaultProps = {
  controller: null,
  hideCallback: () => { },
  encounterId: null,
  emrId: null,
  cardStyle: {},
  hideHeaderIcon: false,
  tablePageSize: 10,
  dividerStyle: {},
  tableStyle: {},
  onSelectedCell: () => { },
  selectedRowIndex: null
};

CardProgressNote.propTypes = {
  controller: PropTypes.object,
  hideCallback: PropTypes.func,
  encounterId: PropTypes.number,
  emrId: PropTypes.number,
  cardStyle: PropTypes.object,
  hideHeaderIcon: PropTypes.bool,
  tablePageSize: PropTypes.number,
  dividerStyle: PropTypes.object,
  tableStyle: PropTypes.object,
  onSelectedCell: PropTypes.func,
  selectedRowIndex: PropTypes.number
};

export default React.memo(CardProgressNote);
