import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from "react";

import { Checkbox } from "semantic-ui-react";

// Common
import { Table } from "react-lib/frameworks/Table";

import ModConfirmCNMI from "react-lib/apps/common/cnmi/ModConfirm";
import { alignCenter } from "react-lib/apps/common/PureReactTable";
import { useIntl } from "react-intl";

// Types
type ModProductPackageAlertProps = {
  // data
  items?: ProductPackage[];
  type: "drug" | "supply";
  onAddToOrder: (selected: any[]) => any;
  // callback
  onClose?: () => any;
};

type ProductPackage = {
  id: number;
  drug: number;
  drug_code: string;
  drug_name: string;
  quantity: string;
  supply: number;
  supply_code: string;
  supply_name: string;
};

type Styles = Record<"denyButton", CSSProperties>;

const styles: Styles = { denyButton: { marginLeft: "3.5rem", minWidth: "max-content" } };

const ModProductPackageAlert = (props: ModProductPackageAlertProps) => {
  const intl = useIntl();
  const [checkedIds, setCheckedIds] = useState<number[]>([]);

  // Effect
  useEffect(() => {
    setCheckedIds([]);
  }, [props.items]);

  // Callback
  const updateCheckedIds = useCallback(
    (checked: number[], id: number, isChecked: boolean) =>
      isChecked ? [...checked, id] : checked.filter((value) => value !== id),
    []
  );

  const handleChecked = useCallback(
    (id: number) => (e: any, data: any) => {
      setCheckedIds((checked) => updateCheckedIds(checked, id, data.checked));
    },
    []
  );

  const getLabel = useCallback((item: ProductPackage) => {
    const code = `${props.type}_code` as const;
    const name = `${props.type}_name` as const;

    return `[${item[code]}] ${item[name]}`;
  }, []);

  const handleAddToOrder = useCallback(() => {
    const selected = (props.items || []).filter((item) => checkedIds.includes(item.id));

    props.onAddToOrder(selected);
  }, [checkedIds, props.items]);

  // Memo
  const titleName = useMemo(() => (props.type === "supply" ? "เวชภัณฑ์" : "ยา"), [props.type]);

  const formattedItems = useMemo(
    () =>
      props.items?.map((item) => ({
        ...item,
        checkbox: (
          <div style={{ display: "grid", placeContent: "center" }}>
            <Checkbox checked={checkedIds.includes(item.id)} onChange={handleChecked(item.id)} />
          </div>
        ),
        name: getLabel(item),
        quantity: alignCenter(item.quantity),
      })),
    [checkedIds]
  );

  const content = useMemo(
    () => (
      <div
        style={{
          margin: "-1rem 0px -2rem 0",
        }}
      >
        <Table
          className="--rt-tbody-overflow-y-hidden"
          data={formattedItems}
          headers={intl.formatMessage({ id: ",ชื่อรายการ,จำนวน (Qty)" })}
          keys="checkbox,name,quantity"
          minRows="1"
          showPagination={false}
          widths="40,^200,100"
        />
      </div>
    ),
    [formattedItems]
  );

  return (
    <ModConfirmCNMI
      approveButton={" "}
      backgroundColor="var(--primary-theme-color)"
      basic={false}
      content={content}
      denyButtonColor="green"
      denyButtonStyle={styles.denyButton}
      denyButtonText="Add to order"
      disabledDenyButton={checkedIds.length === 0}
      openModal={!!props.items?.length}
      size="tiny"
      titleName={`รายการ${titleName}ใน Package`}
      closeIcon
      // callback
      onCloseWithDimmerClick={props.onClose}
      onDeny={handleAddToOrder}
    />
  );
};

ModProductPackageAlert.displayName = "ModProductPackageAlert";

export default React.memo(ModProductPackageAlert);
