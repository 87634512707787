import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button,
  Checkbox,
  Dropdown
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardDrugReturnRequestHistoryUX = (props: any) => {
    return(
      <div
        style={{padding:"5px"}}>
        <div
          style={{fontWeight: "bold", fontSize: "1.2rem"}}>
          ประวัติการขอคืนยา
        </div>
        <div
          style={{display: "flex", alignItems: "center", flexWrap: "wrap" }}>
          
          <div
            style={{display: "flex", alignItems: "center", margin: "0 10px 10px 0"}}>
            
            <Checkbox
              checked={props?.checkedDivisionReturn}
              onChange={props.handleChangeChecked("checkedDivisionReturn")}>
            </Checkbox>
            <div
              style={{margin: "0 5px 0 5px"}}>
              หน่วยงานคืน
            </div>
            <Dropdown
              defaultValue="แสดงทุกหน่วยงาน"
              onChange={props.handleChangeValue("selectedDivisionReturn","checkedDivisionReturn")}
              options={props?.divisionOptions}
              search={true}
              selection={true}
              value={props?.selectedDivisionReturn}>
            </Dropdown>
          </div>
          <div
            style={{display: "flex", alignItems: "center", marginRight: "10px"}}>
            
            <Checkbox
              checked={props?.checkedDivisionReceive}
              onChange={props.handleChangeChecked("checkedDivisionReceive")}>
            </Checkbox>
            <div
              style={{margin: "0 5px 0 5px"}}>
              หน่วยงานรับ
            </div>
            <Dropdown
              defaultValue="แสดงทุกหน่วยงาน"
              onChange={props.handleChangeValue("selectedDivisionReceive","checkedDivisionReceive")}
              options={props?.divisionOptions}
              search={true}
              selection={true}
              value={props?.selectedDivisionReceive}>
            </Dropdown>
          </div>
          <div
            style={{display: "flex", alignItems: "center", marginRight: "10px"}}>
            
            <Checkbox
              checked={props?.checkedFilterDate}
              onChange={props.handleChangeChecked("checkedFilterDate")}>
            </Checkbox>
            <div
              style={{margin: "0 5px 0 5px"}}>
              วันที่แจ้ง
            </div>
            <DateTextBox
              onChange={props.handleChangeDate("selectedStartDate","checkedFilterDate")}
              value={props?.selectedStartDate}>
            </DateTextBox>
            <DateTextBox
              onChange={props.handleChangeDate("selectedEndDate","checkedFilterDate")}
              value={props?.selectedEndDate}>
            </DateTextBox>
          </div>
          <div
            style={{display: "flex", alignItems: "center", marginRight: "10px"}}>
            
            <Checkbox
              checked={props?.checkedStatus}
              onChange={props.handleChangeChecked("checkedStatus")}>
            </Checkbox>
            <div
              style={{margin: "0 5px 0 5px"}}>
              สถานะ
            </div>
            <Dropdown
              defaultValue="แสดงทุกสถานะ"
              onChange={props.handleChangeValue("selectedStatus","checkedStatus")}
              options={props?.statuOptions}
              selection={true}
              value={props?.selectedStatus}>
            </Dropdown>
          </div>
          <div
            style={{display: "flex", alignItems: "center", marginRight: "10px"}}>
            
            <Checkbox
              checked={props?.checkedPatient}
              onChange={props.handleChangeChecked("checkedPatient")}>
            </Checkbox>
            <div
              style={{margin: "0 5px 0 5px"}}>
              ชื่อหรือรหัสผู้ป่วย
            </div>
            <div
              style={{margin: "0 5px 0 5px"}}>
              {props.patientSearch}
            </div>
          </div>
          <Button
            color="blue"
            onClick={props.OnSearch}
            size="tiny"
            style={{marginLeft:"20px"}}>
            ค้นหา
          </Button>
          <Button
            color="yellow"
            onClick={props.OnClear}
            size="tiny"
            style={{marginLeft:"10px"}}>
            ล้าง
          </Button>
        </div>
        <div>
          
          <Table
            data={props?.dataDrugReturnRequestHistoryList}
            getTrProps={props.getTrProps}
            headers="เลขที่,ใบยา,ผู้ป่วย,หน่วยงาน,สถานะ,ขอคืน,อนุมัติ,ส่ง,รับ,รายการยา"
            keys="code,orderCode,patientName,requesterName,statusName,request,approve,delivery,receive,listDrug"
            minRows="10"
            showPagination={false}
            style={{height: "200px"}}>
          </Table>
        </div>
      </div>
    )
}

export default CardDrugReturnRequestHistoryUX

export const screenPropsDefault = {"patientSearch":"[Patient Search Box]"}

/* Date Time : Fri Feb 24 2023 17:17:57 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding:\"5px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติการขอคืนยา"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.2rem\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", flexWrap: \"wrap\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 4,
      "name": "Table",
      "parent": 3,
      "props": {
        "data": {
          "type": "code",
          "value": "props?.dataDrugReturnRequestHistoryList"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getTrProps"
        },
        "headers": {
          "type": "value",
          "value": "เลขที่,ใบยา,ผู้ป่วย,หน่วยงาน,สถานะ,ขอคืน,อนุมัติ,ส่ง,รับ,รายการยา"
        },
        "keys": {
          "type": "value",
          "value": "code,orderCode,patientName,requesterName,statusName,request,approve,delivery,receive,listDrug"
        },
        "minRows": {
          "type": "value",
          "value": "10"
        },
        "pageSize": {
          "type": "code",
          "value": ""
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"200px\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", margin: \"0 10px 10px 0\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", marginRight: \"10px\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", marginRight: \"10px\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", marginRight: \"10px\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", marginRight: \"10px\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.OnSearch"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"20px\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "ล้าง"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.OnClear"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "Checkbox",
      "parent": 5,
      "props": {
        "checked": {
          "type": "code",
          "value": "props?.checkedDivisionReturn"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedDivisionReturn\")"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "หน่วยงานคืน"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 5px 0 5px\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Dropdown",
      "parent": 5,
      "props": {
        "defaultValue": {
          "type": "value",
          "value": "แสดงทุกหน่วยงาน"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedDivisionReturn\",\"checkedDivisionReturn\")"
        },
        "options": {
          "type": "code",
          "value": "props?.divisionOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props?.selectedDivisionReturn"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "Checkbox",
      "parent": 6,
      "props": {
        "checked": {
          "type": "code",
          "value": "props?.checkedDivisionReceive"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedDivisionReceive\")"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "หน่วยงานรับ"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 5px 0 5px\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "Dropdown",
      "parent": 6,
      "props": {
        "defaultValue": {
          "type": "value",
          "value": "แสดงทุกหน่วยงาน"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedDivisionReceive\",\"checkedDivisionReceive\")"
        },
        "options": {
          "type": "code",
          "value": "props?.divisionOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props?.selectedDivisionReceive"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "Checkbox",
      "parent": 7,
      "props": {
        "checked": {
          "type": "code",
          "value": "props?.checkedFilterDate"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedFilterDate\")"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่แจ้ง"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 5px 0 5px\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 20,
      "name": "DateTextBox",
      "parent": 7,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDate(\"selectedStartDate\",\"checkedFilterDate\")"
        },
        "value": {
          "type": "code",
          "value": "props?.selectedStartDate"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 21,
      "name": "DateTextBox",
      "parent": 7,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDate(\"selectedEndDate\",\"checkedFilterDate\")"
        },
        "value": {
          "type": "code",
          "value": "props?.selectedEndDate"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "Checkbox",
      "parent": 8,
      "props": {
        "checked": {
          "type": "code",
          "value": "props?.checkedStatus"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedStatus\")"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะ"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 5px 0 5px\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "Dropdown",
      "parent": 8,
      "props": {
        "defaultValue": {
          "type": "value",
          "value": "แสดงทุกสถานะ"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedStatus\",\"checkedStatus\")"
        },
        "options": {
          "type": "code",
          "value": "props?.statuOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props?.selectedStatus"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "Checkbox",
      "parent": 9,
      "props": {
        "checked": {
          "type": "code",
          "value": "props?.checkedPatient"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeChecked(\"checkedPatient\")"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 9,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientSearch"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 5px 0 5px\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อหรือรหัสผู้ป่วย"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 5px 0 5px\"}"
        }
      },
      "seq": 26,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardDrugReturnRequestHistoryUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "patientSearch": "[Patient Search Box]"
  },
  "width": 80
}

*********************************************************************************** */
