import WasmController from "../../../../frameworks/WasmController";
import moment from "moment";
import { formatDate } from "../../../../utils/dateUtils";
import getPdfMake from "react-lib/appcon/common/pdfMake";
// PDF
import FormInpatientDiagnosis from "../FormInpatientDiagnosis";
// APIs
import InpatientDiagnosisList from "issara-sdk/apis/InpatientDiagnosisList_apps_ADM";

export type State = {
  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;
  django?: any;

  // sequence
  InpatientDiagnosisSequence?: {
    sequenceIndex?: string | null;

    diagFilter?: {
      start_discharge_date?: string;
      end_discharge_date?: string;
      checkedIcd10Code?: boolean;
      checkedIcd10Term?: boolean;
      icd10?: any;
      selectedIcd10?: any;
    };

    diagStatList?: any[];
  } | null;
};

export const StateInitial: State = {
  InpatientDiagnosisSequence: {
    sequenceIndex: null,

    diagFilter: {
      start_discharge_date: formatDate(moment()),
      end_discharge_date: formatDate(moment()),
      checkedIcd10Code: false,
      checkedIcd10Term: false,
      icd10: null,
      selectedIcd10: null,
    },

    diagStatList: [],
  },
};

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const Start: Handler = async (controller, params) => {
  controller.setState(
    {
      InpatientDiagnosisSequence: { sequenceIndex: "Action" },
    },
    () => {
      Action(controller, { action: "CLEAR_FILTER" });
    }
  );
};

export const Action: Handler = async (controller, params) => {
  const state = controller.getState();

  if (params?.action === "FETCH") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params?.action}`]: "LOADING",
      },
    });

    const queryParams: any = {
      limit: 100,
      start_discharge_date:
        state.InpatientDiagnosisSequence?.diagFilter?.start_discharge_date,
      end_discharge_date:
        state.InpatientDiagnosisSequence?.diagFilter?.end_discharge_date,
      icd10:
        state.InpatientDiagnosisSequence?.diagFilter?.checkedIcd10Code ||
        state.InpatientDiagnosisSequence?.diagFilter?.checkedIcd10Term
          ? state.InpatientDiagnosisSequence?.diagFilter?.icd10
          : "",
    };
    const [resp, error, network] = await InpatientDiagnosisList.get({
      apiToken: controller.apiToken,
      params: queryParams,
    });

    if (error) {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params?.action}`]: "ERROR",
        },
        InpatientDiagnosisSequence: {
          ...state.InpatientDiagnosisSequence,
          diagStatList: [],
        },
      });
    } else {
      const items: any[] = resp?.map((item: any, index: number) => {
        return { ...item, no: `${index + 1}.` };
      });

      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params?.action}`]: "SUCCESS",
        },
        InpatientDiagnosisSequence: {
          ...state.InpatientDiagnosisSequence,
          diagStatList: items,
        },
      });
    }
  } else if (params?.action === "SET_FILTER") {
    controller.setState({
      InpatientDiagnosisSequence: {
        ...state.InpatientDiagnosisSequence,
        diagFilter: {
          ...state.InpatientDiagnosisSequence?.diagFilter,
          ...params.data,
        },
      },
    });
  } else if (params?.action === "CLEAR_FILTER") {
    controller.setState({
      InpatientDiagnosisSequence: {
        ...state.InpatientDiagnosisSequence,
        diagFilter: {
          start_discharge_date: formatDate(moment()),
          end_discharge_date: formatDate(moment()),
          checkedIcd10Code: false,
          checkedIcd10Term: false,
          icd10: null,
          selectedIcd10: null,
        },
      },
    });
  } else if (params?.action === "PRINT") {
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    const data = Object.assign({
      start_date:
        state.InpatientDiagnosisSequence?.diagFilter?.start_discharge_date ||
        "  ",
      end_date:
        state.InpatientDiagnosisSequence?.diagFilter?.end_discharge_date ||
        "  ",
      print_user: state.django?.user?.full_name || "",
      data: state.InpatientDiagnosisSequence?.diagStatList || [],
    });

    let docDef: any = { content: [] };

    docDef = await FormInpatientDiagnosis(data);

    const pdfMake = (await getPdfMake(true)).createPdf(docDef);
    pdfMake.open();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "SUCCESS",
      },
      successMessage: "Print Success",
    });
    return;
  }
};
