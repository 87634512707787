export default function toPDFMakeData(props: any) {
  return {
    pageSize: `A4`,
    defaultStyle: {
      font: `THSarabunNew`,
    },
    content: [
      {
        color: ``,
        preserveLeadingSpaces: true,
        pageBreak: ``,
        alignment: `center`,
        bold: `true`,
        width: `auto`,
        fontSize: `18`,
        decoration: ``,
        decorationColor: ``,
        text: `ใบรับรองแพทย์`,
        decorationStyle: ``,
      },
      {
        width: `auto`,
        bold: `true`,
        color: ``,
        decorationColor: ``,
        pageBreak: ``,
        decoration: ``,
        fontSize: `18`,
        decorationStyle: ``,
        alignment: `center`,
        preserveLeadingSpaces: true,
        text: `ยืนยันการกักตัวหลังตรวจพบเชื้อ โควิด-19`,
      },
      {
        preserveLeadingSpaces: true,
        decoration: ``,
        width: `auto`,
        bold: false,
        color: ``,
        fontSize: 15,
        alignment: `left`,
        text: ` `,
        decorationStyle: ``,
        decorationColor: ``,
        pageBreak: ``,
      },
      {
        columns: [
          {
            text: ``,
            color: ``,
            bold: false,
            decorationColor: ``,
            fontSize: 15,
            decoration: ``,
            pageBreak: ``,
            alignment: `left`,
            decorationStyle: ``,
            width: 420,
            preserveLeadingSpaces: true,
          },
          {
            decoration: ``,
            fontSize: 15,
            decorationColor: ``,
            decorationStyle: ``,
            color: ``,
            pageBreak: ``,
            width: `auto`,
            alignment: `left`,
            bold: true,
            text: `วันที่ ${props.items?.formatCreatedDate}`,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            text: ``,
            decoration: ``,
            bold: false,
            pageBreak: ``,
            width: 40,
            decorationColor: ``,
            alignment: `left`,
            fontSize: 15,
            color: ``,
            preserveLeadingSpaces: true,
          },
          {
            alignment: `left`,
            width: `auto`,
            color: ``,
            fontSize: 15,
            text: `ขอรับรองว่า `,
            bold: false,
            decorationStyle: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationColor: ``,
          },
          {
            decorationColor: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decoration: ``,
            text: ``,
            pageBreak: ``,
            alignment: `left`,
            bold: false,
            color: ``,
            decorationStyle: ``,
            width: 10,
          },
          {
            stack: [
              {
                alignment: `left`,
                text: props.items?.patientInfo?.full_name,
                bold: false,
                margin: [0, 0, 0, -15],
                width: `auto`,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                decorationStyle: ``,
                pageBreak: ``,
                color: ``,
                decoration: ``,
                fontSize: 15,
              },
              {
                pageBreak: ``,
                decoration: ``,
                color: ``,
                alignment: `left`,
                fontSize: 15,
                bold: false,
                width: `auto`,
                margin: [0, 0, 0, 0],
                decorationColor: ``,
                text: `....................................................................................................................................................................`,
                decorationStyle: ``,
                preserveLeadingSpaces: true,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            decorationColor: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            bold: false,
            fontSize: 15,
            text: `หมายเลขประจำตัวประชาชน / เลขที่หนังสือเดินทาง / อื่นๆ`,
            color: ``,
            width: `auto`,
            decoration: ``,
          },
          {
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            color: ``,
            bold: false,
            pageBreak: ``,
            decorationColor: ``,
            width: 10,
            alignment: `left`,
            decoration: ``,
            fontSize: 15,
            text: ``,
          },
          {
            stack: [
              {
                alignment: `left`,
                text:
                  props.items?.patientInfo?.citizen_no === "" ||
                  props.items?.patientInfo?.citizen_no === "Non citizen identity"
                    ? props.items?.patientInfo?.citizen_passport === "" ||
                      props.items?.patientInfo?.citizen_passport === "Non citizen identity"
                      ? " "
                      : props.items?.patientInfo?.citizen_passport
                    : props.items?.patientInfo?.citizen_no,
                width: `auto`,
                fontSize: 15,
                preserveLeadingSpaces: true,
                decoration: ``,
                bold: false,
                decorationColor: ``,
                decorationStyle: ``,
                color: ``,
                pageBreak: ``,
                margin: [0, 0, 0, -15],
              },
              {
                color: ``,
                alignment: `left`,
                bold: false,
                margin: [0, 0, 0, 0],
                pageBreak: ``,
                decorationColor: ``,
                decoration: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                preserveLeadingSpaces: true,
                text: `........................................................................................................`,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            decorationColor: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            bold: false,
            pageBreak: ``,
            width: `auto`,
            text: `อายุ `,
            color: ``,
            alignment: `left`,
          },
          {
            decorationColor: ``,
            decorationStyle: ``,
            width: 10,
            text: ``,
            fontSize: 15,
            color: ``,
            decoration: ``,
            pageBreak: ``,
            alignment: `left`,
            bold: false,
            preserveLeadingSpaces: true,
          },
          {
            stack: [
              {
                decorationStyle: ``,
                preserveLeadingSpaces: true,
                decoration: ``,
                color: ``,
                alignment: `left`,
                margin: [0, 0, 0, -15],
                bold: false,
                text: `${props.items?.ageYear} ปี ${props.items?.ageMonth} เดือน ${props.items?.ageDay} วัน`,
                width: `auto`,
                decorationColor: ``,
                fontSize: 15,
                pageBreak: ``,
              },
              {
                preserveLeadingSpaces: true,
                text: `............................................................................`,
                margin: [0, 0, 0, 0],
                bold: false,
                color: ``,
                width: `auto`,
                alignment: `left`,
                pageBreak: ``,
                decorationStyle: ``,
                decorationColor: ``,
                fontSize: 15,
                decoration: ``,
              },
            ],
          },
          {
            width: `auto`,
            pageBreak: ``,
            text: `หมายเลขผู้ป่วย   `,
            decoration: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decorationStyle: ``,
            bold: false,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            decorationColor: ``,
            color: ``,
          },
          {
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decoration: ``,
            width: 10,
            pageBreak: ``,
            bold: false,
            color: ``,
            decorationStyle: ``,
            alignment: `left`,
            text: ``,
          },
          {
            stack: [
              {
                decorationColor: ``,
                pageBreak: ``,
                text: props.items?.patientInfo?.hn,
                color: ``,
                margin: [0, 0, 0, -15],
                fontSize: 15,
                preserveLeadingSpaces: true,
                width: `auto`,
                decoration: ``,
                alignment: `left`,
                decorationStyle: ``,
                bold: false,
              },
              {
                text: `............................................................................`,
                decorationColor: ``,
                pageBreak: ``,
                decoration: ``,
                width: `auto`,
                decorationStyle: ``,
                margin: [0, 0, 0, 0],
                color: ``,
                fontSize: 15,
                bold: false,
                alignment: `left`,
                preserveLeadingSpaces: true,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            width: `auto`,
            alignment: `left`,
            text: `มีประวัติของ การเจ็บปวด โดยตรวจ RT-PCR พบเชื้อ COVID-19 ในวันที่ `,
            bold: false,
            decoration: ``,
            decorationColor: ``,
            decorationStyle: ``,
          },
          {
            width: 10,
            decoration: ``,
            color: ``,
            decorationColor: ``,
            bold: false,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            alignment: `left`,
            decorationStyle: ``,
            text: ``,
            fontSize: 15,
          },
          {
            stack: [
              {
                pageBreak: ``,
                alignment: `left`,
                decorationColor: ``,
                bold: false,
                width: `auto`,
                margin: [0, 0, 0, -15],
                text: props.items?.detectDate,
                decoration: ``,
                fontSize: 15,
                color: ``,
                decorationStyle: ``,
                preserveLeadingSpaces: true,
              },
              {
                alignment: `left`,
                width: `auto`,
                decorationStyle: ``,
                bold: false,
                pageBreak: ``,
                decorationColor: ``,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
                fontSize: 15,
                text: `.....................................................................................`,
                color: ``,
                decoration: ``,
              },
            ],
          },
        ],
      },
      {
        preserveLeadingSpaces: true,
        color: ``,
        width: `auto`,
        text: ` `,
        pageBreak: ``,
        decorationColor: ``,
        decorationStyle: ``,
        decoration: ``,
        fontSize: 15,
        bold: false,
        alignment: `left`,
      },
      {
        text: `ผู้ป่วยเสร็จสิ้นกระบวนการการรักษา / กักตัวตามคำแนะนำของกระทรวงสาธารณสุขแล้ว`,
        fontSize: 15,
        color: ``,
        alignment: `left`,
        decorationStyle: ``,
        pageBreak: ``,
        bold: false,
        width: `auto`,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        decoration: ``,
      },
      {
        width: `auto`,
        text: ` `,
        decorationColor: ``,
        alignment: `left`,
        decorationStyle: ``,
        pageBreak: ``,
        color: ``,
        fontSize: 15,
        bold: false,
        preserveLeadingSpaces: true,
        decoration: ``,
      },
      {
        decorationColor: ``,
        preserveLeadingSpaces: true,
        alignment: `left`,
        decorationStyle: ``,
        width: `auto`,
        fontSize: 15,
        color: ``,
        pageBreak: ``,
        decoration: ``,
        text: `ข้อสรุป และ / หรือ ข้อเสนอแนะ`,
        bold: false,
      },
      {
        alignment: `left`,
        width: `auto`,
        pageBreak: ``,
        decoration: ``,
        preserveLeadingSpaces: true,
        text: `หลังการติดเชื้อ COVID-19 : เมื่อหายป่วยตามอาการทางคลินิก แนะนำให้สวมหน้ากากอนามัย ล้างมือ เว้นระยะห่างทางสังคม และหลีกเลี่ยงสถานที่แออัด`,
        fontSize: 15,
        decorationColor: ``,
        decorationStyle: ``,
        color: ``,
        bold: false,
      },
      {
        text: ` `,
        decorationColor: ``,
        color: ``,
        bold: false,
        alignment: `left`,
        decoration: ``,
        decorationStyle: ``,
        fontSize: 15,
        preserveLeadingSpaces: true,
        width: `auto`,
        pageBreak: ``,
      },
      {
        columns: [
          {
            bold: false,
            decoration: ``,
            alignment: `left`,
            decorationStyle: ``,
            text: `ข้อเสนอแนะอื่นๆ :`,
            pageBreak: ``,
            decorationColor: ``,
            color: ``,
            fontSize: 15,
            width: `auto`,
            preserveLeadingSpaces: true,
          },
          {
            width: 10,
            bold: false,
            color: ``,
            fontSize: 15,
            decorationColor: ``,
            decoration: ``,
            alignment: `left`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            text: ``,
          },
          {
            stack: [
              {
                alignment: `left`,
                fontSize: 15,
                decorationColor: ``,
                pageBreak: ``,
                preserveLeadingSpaces: true,
                text:
                  props.items?.comment == null || props.items?.comment === ""
                    ? " "
                    : props.items?.comment,
                bold: false,
                decoration: ``,
                decorationStyle: ``,
                width: `auto`,
                margin: [0, 0, 0, -15],
                color: ``,
              },
              {
                preserveLeadingSpaces: true,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                decorationColor: ``,
                margin: [0, 0, 0, 0],
                decoration: ``,
                color: ``,
                pageBreak: ``,
                alignment: `left`,
                text: `...........................................................................................................................................................................`,
                bold: false,
              },
            ],
          },
        ],
      },
      {
        text: `.............................................................................................................................................................................................................`,
        color: ``,
        margin: [0, 0, 0, 0],
        preserveLeadingSpaces: true,
        decorationColor: ``,
        alignment: `left`,
        bold: false,
        decorationStyle: ``,
        pageBreak: ``,
        width: `auto`,
        fontSize: 15,
        decoration: ``,
      },
      {
        preserveLeadingSpaces: true,
        decoration: ``,
        bold: false,
        width: `auto`,
        fontSize: 15,
        alignment: `left`,
        decorationColor: ``,
        decorationStyle: ``,
        pageBreak: ``,
        color: ``,
        margin: [0, 0, 0, 0],
        text: `.............................................................................................................................................................................................................`,
      },
      {
        fontSize: 15,
        decorationColor: ``,
        margin: [0, 0, 0, 0],
        preserveLeadingSpaces: true,
        color: ``,
        pageBreak: ``,
        alignment: `left`,
        width: `auto`,
        text: ` `,
        decoration: ``,
        bold: false,
        decorationStyle: ``,
      },
      {
        columns: [
          {
            width: `auto`,
            decoration: ``,
            decorationColor: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            fontSize: 15,
            text: `ผู้ป่วยจำเป็นต้องพักงานเป็นระยะเวลา จำนวน`,
            pageBreak: ``,
            bold: false,
            color: ``,
          },
          {
            preserveLeadingSpaces: true,
            alignment: `left`,
            pageBreak: ``,
            bold: false,
            decoration: ``,
            text: ``,
            width: 10,
            decorationColor: ``,
            fontSize: 15,
            color: ``,
            decorationStyle: ``,
          },
          {
            stack: [
              {
                text: props.items?.absenceDuration ? props.items?.absenceDuration : " ",
                color: ``,
                width: `auto`,
                decorationColor: ``,
                alignment: `center`,
                pageBreak: ``,
                decoration: ``,
                bold: false,
                decorationStyle: ``,
                fontSize: 15,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `....................`,
                color: ``,
                width: `auto`,
                decorationColor: ``,
                alignment: `left`,
                pageBreak: ``,
                decoration: ``,
                bold: false,
                decorationStyle: ``,
                fontSize: 15,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
            width: `auto`,
          },
          {
            decorationStyle: ``,
            alignment: `left`,
            width: 10,
            text: ``,
            decoration: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            bold: false,
            color: ``,
            decorationColor: ``,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            decoration: ``,
            decorationStyle: ``,
            fontSize: 15,
            bold: false,
            text: ` วัน             ตั้งแต่`,
            pageBreak: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            color: ``,
            alignment: `left`,
          },
          {
            decorationColor: ``,
            decoration: ``,
            width: 10,
            color: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            text: ``,
            pageBreak: ``,
            fontSize: 15,
            alignment: `left`,
            bold: false,
          },
          {
            stack: [
              {
                text: props.items?.absenceStartDate ? props.items?.absenceStartDate : " ",
                color: ``,
                width: `auto`,
                decorationColor: ``,
                alignment: `center`,
                pageBreak: ``,
                decoration: ``,
                bold: false,
                decorationStyle: ``,
                fontSize: 15,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.........................`,
                color: ``,
                width: `auto`,
                decorationColor: ``,
                alignment: `left`,
                pageBreak: ``,
                decoration: ``,
                bold: false,
                decorationStyle: ``,
                fontSize: 15,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
            width: `auto`,
          },
          {
            margin: [0, 0, 0, 0],
            color: ``,
            decoration: ``,
            decorationStyle: ``,
            width: 10,
            alignment: `left`,
            bold: false,
            decorationColor: ``,
            text: ``,
            fontSize: 15,
            pageBreak: ``,
            preserveLeadingSpaces: true,
          },
          {
            color: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decorationColor: ``,
            alignment: `left`,
            decoration: ``,
            decorationStyle: ``,
            width: `auto`,
            pageBreak: ``,
            text: ` ถึง `,
            bold: false,
          },
          {
            decoration: ``,
            alignment: `left`,
            decorationColor: ``,
            fontSize: 15,
            pageBreak: ``,
            decorationStyle: ``,
            width: 10,
            color: ``,
            text: ``,
            preserveLeadingSpaces: true,
            bold: false,
          },
          {
            stack: [
              {
                text: props.items?.absenceEndDate ? props.items?.absenceEndDate : " ",
                color: ``,
                width: `auto`,
                decorationColor: ``,
                alignment: `center`,
                pageBreak: ``,
                decoration: ``,
                bold: false,
                decorationStyle: ``,
                fontSize: 15,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.........................`,
                color: ``,
                width: `auto`,
                decorationColor: ``,
                alignment: `left`,
                pageBreak: ``,
                decoration: ``,
                bold: false,
                decorationStyle: ``,
                fontSize: 15,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
            width: `auto`,
          },
        ],
      },
      {
        width: `auto`,
        alignment: `left`,
        pageBreak: ``,
        bold: false,
        text: ` `,
        fontSize: 15,
        decoration: ``,
        decorationColor: ``,
        decorationStyle: ``,
        color: ``,
        preserveLeadingSpaces: true,
      },
      {
        alignment: `left`,
        color: ``,
        pageBreak: ``,
        text: ` `,
        preserveLeadingSpaces: true,
        decorationColor: ``,
        decorationStyle: ``,
        decoration: ``,
        bold: false,
        fontSize: 15,
        width: `auto`,
      },
      {
        preserveLeadingSpaces: true,
        text: ` `,
        bold: false,
        pageBreak: ``,
        decoration: ``,
        decorationColor: ``,
        width: `auto`,
        decorationStyle: ``,
        alignment: `left`,
        fontSize: 15,
        color: ``,
      },
      {
        decoration: ``,
        bold: false,
        preserveLeadingSpaces: true,
        decorationColor: ``,
        decorationStyle: ``,
        width: `auto`,
        alignment: `left`,
        pageBreak: ``,
        color: ``,
        fontSize: 15,
        text: ` `,
      },
      {
        pageBreak: ``,
        width: `auto`,
        bold: false,
        fontSize: 15,
        decorationColor: ``,
        decoration: ``,
        text: ` `,
        decorationStyle: ``,
        preserveLeadingSpaces: true,
        color: ``,
        alignment: `left`,
      },
      {
        preserveLeadingSpaces: true,
        bold: false,
        decorationColor: ``,
        decorationStyle: ``,
        pageBreak: ``,
        text: ` `,
        decoration: ``,
        color: ``,
        alignment: `left`,
        width: `auto`,
        fontSize: 15,
      },
      {
        columns: [
          {
            decorationColor: ``,
            text: `ลงนามแพทย์ผู้ตรวจ `,
            alignment: `left`,
            width: `auto`,
            fontSize: 15,
            color: ``,
            decorationStyle: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            bold: false,
          },
          {
            width: 30,
            fontSize: 15,
            color: ``,
            decorationColor: ``,
            bold: false,
            decoration: ``,
            pageBreak: ``,
            alignment: `center`,
            preserveLeadingSpaces: true,
            text: ` `,
            decorationStyle: ``,
          },
          {
            fontSize: 15,
            alignment: `center`,
            bold: false,
            preserveLeadingSpaces: true,
            decoration: ``,
            pageBreak: ``,
            decorationStyle: ``,
            decorationColor: ``,
            text: `.............................................................`,
            color: ``,
            width: `auto`,
          },
          {
            fontSize: 15,
            width: 30,
            decoration: ``,
            decorationColor: ``,
            bold: false,
            text: ``,
            alignment: `center`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            color: ``,
            decorationStyle: ``,
          },
          {
            preserveLeadingSpaces: true,
            decorationColor: ``,
            bold: false,
            decoration: ``,
            alignment: `left`,
            text: `เลขใบประกอบวิชาชีพแพทย์ `,
            decorationStyle: ``,
            fontSize: 15,
            pageBreak: ``,
            width: `auto`,
            color: ``,
          },
          {
            alignment: `left`,
            color: ``,
            decorationColor: ``,
            decorationStyle: ``,
            width: 15,
            bold: false,
            text: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            pageBreak: ``,
          },
          {
            color: ``,
            decoration: `underline`,
            decorationColor: ``,
            width: `auto`,
            preserveLeadingSpaces: true,
            fontSize: 15,
            pageBreak: ``,
            alignment: `center`,
            decorationStyle: `dotted`,
            text: props.items?.doctorCertNumber
              ? props.items?.doctorCertNumber
              : "                    ",
            bold: false,
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            alignment: `left`,
            decorationColor: ``,
            decorationStyle: ``,
            text: ``,
            preserveLeadingSpaces: true,
            color: ``,
            width: 134,
            bold: false,
            decoration: ``,
            fontSize: 15,
          },
          {
            decoration: ``,
            text: `(${props.items?.doctorName})`,
            color: ``,
            decorationStyle: ``,
            alignment: `center`,
            fontSize: 15,
            decorationColor: ``,
            width: `auto`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            bold: false,
          },
        ],
      },
    ],
    pageOrientation: `portrait`,
    header: props.header,
    footer: props.footer,
  };
}
