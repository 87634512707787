import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Label,
  Input,
  Button
} from 'semantic-ui-react'

const CardSupplyDeliveryManagerUX = (props: any) => {
    return(
      <div
        style={{margin: "10px", backgroundColor: "#F3F3F3", height:"90%"}}>
        <div
          style={{display: "flex", alignItems: "center", margin: "10px 0px"}}>

          <Label
            className="teal"
            style={{fontSize:"medium"}}>
            {props.supplyDeliveryManagerData?.code}
          </Label>
          <label
            className="header"
            style={{fontWeight: "bold", fontSize: "20px", marginLeft: "4px"}}>
             จัดเวชภัณฑ์เพื่อจัดส่ง
          </label>
        </div>
        <div
          style={{display: "flex", alignItems: "center", margin: "10px 0px"}}>

          <div
            style={{margin: "2px"}}>

            <Label
              className="black"
              size="large">
              from: {props.supplyDeliveryManagerData?.from_division_name}
            </Label>
          </div>
          <div
            style={{margin: "2px"}}>

            <Label
              className="black"
              size="large">
              to: {props.supplyDeliveryManagerData?.to_division_name}
            </Label>
          </div>
          <div
            style={{margin: "2px"}}>

            <Label
              className="yellow"
              color={props.statusColor}
              size="large">
              {props.supplyDeliveryManagerData?.status_name}
            </Label>
          </div>
        </div>
        <div>

          <hr>
          </hr>
        </div>
        <div
          style={{margin: "5px 0 0 15px",fontSize: "medium"}}>

          <Input
            label={ {content: "ผู้ตรวจ",style: {padding: "0.8em 0.8em", width: "70px"}}}
            readonly="true"
            size="mini"
            style={{alignItems: "center", width: "46%"}}
            value={props.supplyDeliveryManagerData?.printed?.name}>
          </Input>
          <Input
            label={ {content: "เวลา",style: {padding: "0.8em 0.8em"}}}
            readonly="true"
            size="mini"
            style={{alignItems: "center", width: "20%", marginLeft: "20px"}}
            value={props.supplyDeliveryManagerData?.printed?.datetime}>
          </Input>
          <Input
            label={  {content: "แผนก",style: {padding: "0.8em 0.8em"}}}
            readonly="true"
            size="mini"
            style={{alignItems: "center", width: "25%", marginLeft: "20px"}}
            value={props.supplyDeliveryManagerData?.printed?.division}>
          </Input>
        </div>
        <div
          style={{margin: "5px 0 0 15px",fontSize: "medium",display: props.supplyDeliveryManagerData?.status_name ===  "PRINTED"  || props.supplyDeliveryManagerData?.status_name ===  "CANCELED" ? "none" : ""}}>

          <Input
            label={ {content: "ผู้จัด",style: {padding: "0.8em 0.8em", width: "70px"}}}
            size="mini"
            style={{alignItems: "center", width: "46%"}}
            value={props.supplyDeliveryManagerData?.checked?.name}>
          </Input>
          <Input
            label={ {content: "เวลา",style: {padding: "0.8em 0.8em"}}}
            size="mini"
            style={{alignItems: "center", width: "20%", marginLeft: "20px"}}
            value={props.supplyDeliveryManagerData?.checked?.datetime}>
          </Input>
          <Input
            label={  {content: "แผนก",style: {padding: "0.8em 0.8em"}}}
            size="mini"
            style={{alignItems: "center", width: "25%", marginLeft: "20px"}}
            value={props.supplyDeliveryManagerData?.checked?.division}>
          </Input>
        </div>
        <div
          style={{margin: "5px 0 0 15px",fontSize: "medium",display: props.supplyDeliveryManagerData?.status_name ===  "CHECKED" || props.supplyDeliveryManagerData?.status_name ===  "PRINTED"  || props.supplyDeliveryManagerData?.status_name ===  "CANCELED" ? "none" : ""}}>

          <Input
            label={ {content: "ผู้จ่าย",style: {padding: "0.8em 0.8em", width: "70px"}}}
            size="mini"
            style={{alignItems: "center", width: "46%"}}
            value={props.supplyDeliveryManagerData?.delivered?.name}>
          </Input>
          <Input
            label={ {content: "เวลา",style: {padding: "0.8em 0.8em"}}}
            size="mini"
            style={{alignItems: "center", width: "20%", marginLeft: "20px"}}
            value={props.supplyDeliveryManagerData?.delivered?.datetime}>
          </Input>
          <Input
            label={  {content: "แผนก",style: {padding: "0.8em 0.8em"}}}
            size="mini"
            style={{alignItems: "center", width: "25%", marginLeft: "20px"}}
            value={props.supplyDeliveryManagerData?.delivered?.division}>
          </Input>
        </div>
        <div
          style={{margin: "5px 0 0 15px", fontSize: "medium",display: props.supplyDeliveryManagerData?.status_name ===  "CHECKED" || props.supplyDeliveryManagerData?.status_name ===  "PRINTED"  || props.supplyDeliveryManagerData?.status_name ===  "CANCELED" ? "none" : ""}}>

          <Input
            label={ {content: "ผู้ขนส่ง",style: {padding: "0.8em 0.8em", width: "70px"}}}
            size="mini"
            style={{alignItems: "center", width: "46%"}}
            value={props.supplyDeliveryManagerData?.transported?.name}>
          </Input>
          <Input
            label={ {content: "เวลา",style: {padding: "0.8em 0.8em"}}}
            size="mini"
            style={{alignItems: "center", width: "20%", marginLeft: "20px"}}
            value={props.supplyDeliveryManagerData?.transported?.datetime}>
          </Input>
          <Input
            label={  {content: "แผนก",style: {padding: "0.8em 0.8em"}}}
            size="mini"
            style={{alignItems: "center", width: "25%", marginLeft: "20px"}}
            value={props.supplyDeliveryManagerData?.transported?.division}>
          </Input>
        </div>
        <div
          style={{ position: "relative", padding: "15px" }}>

          <Table
            data={props.supplyDeliveryManagerItems}
            headers="no,Code,Product Name,ขนาดบรรจุ,จะจ่าย,หน่วยสั่ง,มูลค่า,มูลค่ารวม"
            keys="no,code,name,package_size,quantity_issue,stock_unit,value_unit,value_total"
            showPagination={false}
            style={{fontSize: "large", height: "39dvh"}}
            widths="40,200,auto,150,100,150,150,150">
          </Table>
        </div>
        <div
          style={{display: "flex", alignItems: "center", margin: "10px 0px", backgroundColor: "white", padding: "1rem 0"}}>

          <Button
            color="blue"
            onClick={props.actionLogClick}
            size="small">
            LOG
          </Button>
          <div>
            {props.buttonReprint}
          </div>
          <Input
            label={{content: "มูลค่ารวม", style: {display: "flex", alignItems: "center"}}}
            readOnly="true"
            style={{width: "300px"}}
            value={props.total}>
          </Input>
          <div
            style={{display:"flex", width:"auto", marginLeft: "auto"}}>

            <div
              style={{display: props.supplyDeliveryManagerData?.status_name ===  "CHECKED"  ? "flex" : "none",marginRight:"5px"}}>

            </div>
            <Input
              label={{content: "ผู้จัดส่ง", style: {display: "flex", alignItems: "center"}}}
              onChange={props.changeCoUser}
              onKeyDown={(e: any) => { e.key === "Enter" && props.coUserVerify(); }}
              placeholder="code"
              style={{display: props.supplyDeliveryManagerData?.status_name ===  "CHECKED"  ? "flex" : "none",marginRight:"5px"}}
              value={props.coUser}>
            </Input>
            <div
              style={{display: props.showButton  ? "none" : ""}}>
              {props.buttonSave}
            </div>
            <Button
              color="red"
              onClick={props.actionUnCheck}
              size="small"
              style={{display: props.supplyDeliveryManagerData?.status_name ===  "CHECKED" ? "" : "none"}}>
              ยกเลิกการจัด
            </Button>
            <Button
              color="red"
              onClick={props.actionUnDeliver}
              size="small"
              style={{display: props.supplyDeliveryManagerData?.status_name ===  "TRANSPORTED" && !props.isCardReceive ? "" : "none"}}>
              ยกเลิกการจ่าย
            </Button>
            <Button
              color="red"
              onClick={props.actionUnCancel}
              size="small"
              style={{display: props.supplyDeliveryManagerData?.status_name ===  "PRINTED" ? "" : "none"}}>
              ยกเลิกจัดส่ง
            </Button>
            <Button
              color="red"
              onClick={props.actionUnReceive}
              size="small"
              style={{display: props.supplyDeliveryManagerData?.status_name ===  "RECEIVED" && props.isCardReceive ? "" : "none"}}>
              ยกเลิกรับเวชภัณฑ์
            </Button>
          </div>
        </div>
        <div
          style={{width:"100%"}}>
          {props.ErrorMessage}
        </div>
      </div>
    )
}


export default CardSupplyDeliveryManagerUX

export const screenPropsDefault = {}

/* Date Time : Thu Aug 29 2024 16:17:59 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{margin: \"10px\", backgroundColor: \"#F3F3F3\", height:\"90%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", margin: \"10px 0px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", margin: \"10px 0px\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 3,
      "name": "Table",
      "parent": 25,
      "props": {
        "data": {
          "type": "code",
          "value": "props.supplyDeliveryManagerItems"
        },
        "getTrProps": {
          "type": "code",
          "value": ""
        },
        "headers": {
          "type": "value",
          "value": "no,Code,Product Name,ขนาดบรรจุ,จะจ่าย,หน่วยสั่ง,มูลค่า,มูลค่ารวม"
        },
        "keys": {
          "type": "value",
          "value": "no,code,name,package_size,quantity_issue,stock_unit,value_unit,value_total"
        },
        "minRows": {
          "type": "value",
          "value": ""
        },
        "pageSize": {
          "type": "value",
          "value": ""
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"large\", height: \"39dvh\"}"
        },
        "widths": {
          "type": "value",
          "value": "40,200,auto,150,100,150,150,150"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{margin: \"2px\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{margin: \"2px\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{margin: \"2px\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "Label",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "from: {props.supplyDeliveryManagerData?.from_division_name}"
        },
        "className": {
          "type": "value",
          "value": "black"
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "Label",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "to: {props.supplyDeliveryManagerData?.to_division_name}"
        },
        "className": {
          "type": "value",
          "value": "black"
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "Label",
      "parent": 6,
      "props": {
        "children": {
          "type": "code",
          "value": "props.supplyDeliveryManagerData?.status_name"
        },
        "className": {
          "type": "value",
          "value": "yellow"
        },
        "color": {
          "type": "code",
          "value": "props.statusColor"
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "label",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": " จัดเวชภัณฑ์เพื่อจัดส่ง"
        },
        "className": {
          "type": "value",
          "value": "header"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"20px\", marginLeft: \"4px\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "Label",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.supplyDeliveryManagerData?.code"
        },
        "className": {
          "type": "value",
          "value": "teal"
        },
        "style": {
          "type": "code",
          "value": "{fontSize:\"medium\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{margin: \"5px 0 0 15px\",fontSize: \"medium\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "Input",
      "parent": 12,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "label": {
          "type": "code",
          "value": " {content: \"ผู้ตรวจ\",style: {padding: \"0.8em 0.8em\", width: \"70px\"}}"
        },
        "readonly": {
          "type": "value",
          "value": "true"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{alignItems: \"center\", width: \"46%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.supplyDeliveryManagerData?.printed?.name"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Input",
      "parent": 12,
      "props": {
        "label": {
          "type": "code",
          "value": " {content: \"เวลา\",style: {padding: \"0.8em 0.8em\"}}"
        },
        "readonly": {
          "type": "value",
          "value": "true"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{alignItems: \"center\", width: \"20%\", marginLeft: \"20px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.supplyDeliveryManagerData?.printed?.datetime"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "Input",
      "parent": 12,
      "props": {
        "label": {
          "type": "code",
          "value": "  {content: \"แผนก\",style: {padding: \"0.8em 0.8em\"}}"
        },
        "readonly": {
          "type": "value",
          "value": "true"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{alignItems: \"center\", width: \"25%\", marginLeft: \"20px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.supplyDeliveryManagerData?.printed?.division"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "hr",
      "parent": 16,
      "props": {
      },
      "seq": 17,
      "void": true
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", margin: \"10px 0px\", backgroundColor: \"white\", padding: \"1rem 0\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "Button",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": "LOG"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.actionLogClick"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "Input",
      "parent": 18,
      "props": {
        "label": {
          "type": "code",
          "value": "{content: \"มูลค่ารวม\", style: {display: \"flex\", alignItems: \"center\"}}"
        },
        "readOnly": {
          "type": "value",
          "value": "true"
        },
        "readonly": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"300px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.total"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "Button",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิกการจัด"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.actionUnCheck"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{display: props.supplyDeliveryManagerData?.status_name ===  \"CHECKED\" ? \"\" : \"none\"}"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", width:\"auto\", marginLeft: \"auto\"}"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ position: \"relative\", padding: \"15px\" }"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{margin: \"5px 0 0 15px\",fontSize: \"medium\",display: props.supplyDeliveryManagerData?.status_name ===  \"PRINTED\"  || props.supplyDeliveryManagerData?.status_name ===  \"CANCELED\" ? \"none\" : \"\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "Input",
      "parent": 26,
      "props": {
        "label": {
          "type": "code",
          "value": " {content: \"ผู้จัด\",style: {padding: \"0.8em 0.8em\", width: \"70px\"}}"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{alignItems: \"center\", width: \"46%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.supplyDeliveryManagerData?.checked?.name"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "Input",
      "parent": 26,
      "props": {
        "label": {
          "type": "code",
          "value": " {content: \"เวลา\",style: {padding: \"0.8em 0.8em\"}}"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{alignItems: \"center\", width: \"20%\", marginLeft: \"20px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.supplyDeliveryManagerData?.checked?.datetime"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "Input",
      "parent": 26,
      "props": {
        "label": {
          "type": "code",
          "value": "  {content: \"แผนก\",style: {padding: \"0.8em 0.8em\"}}"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{alignItems: \"center\", width: \"25%\", marginLeft: \"20px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.supplyDeliveryManagerData?.checked?.division"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{margin: \"5px 0 0 15px\",fontSize: \"medium\",display: props.supplyDeliveryManagerData?.status_name ===  \"CHECKED\" || props.supplyDeliveryManagerData?.status_name ===  \"PRINTED\"  || props.supplyDeliveryManagerData?.status_name ===  \"CANCELED\" ? \"none\" : \"\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "Input",
      "parent": 30,
      "props": {
        "icon": {
          "type": "value",
          "value": ""
        },
        "iconPosition": {
          "type": "value",
          "value": ""
        },
        "label": {
          "type": "code",
          "value": " {content: \"ผู้จ่าย\",style: {padding: \"0.8em 0.8em\", width: \"70px\"}}"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{alignItems: \"center\", width: \"46%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.supplyDeliveryManagerData?.delivered?.name"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "Input",
      "parent": 30,
      "props": {
        "label": {
          "type": "code",
          "value": " {content: \"เวลา\",style: {padding: \"0.8em 0.8em\"}}"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{alignItems: \"center\", width: \"20%\", marginLeft: \"20px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.supplyDeliveryManagerData?.delivered?.datetime"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "Input",
      "parent": 30,
      "props": {
        "label": {
          "type": "code",
          "value": "  {content: \"แผนก\",style: {padding: \"0.8em 0.8em\"}}"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{alignItems: \"center\", width: \"25%\", marginLeft: \"20px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.supplyDeliveryManagerData?.delivered?.division"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{margin: \"5px 0 0 15px\", fontSize: \"medium\",display: props.supplyDeliveryManagerData?.status_name ===  \"CHECKED\" || props.supplyDeliveryManagerData?.status_name ===  \"PRINTED\"  || props.supplyDeliveryManagerData?.status_name ===  \"CANCELED\" ? \"none\" : \"\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "Input",
      "parent": 34,
      "props": {
        "icon": {
          "type": "value",
          "value": ""
        },
        "label": {
          "type": "code",
          "value": " {content: \"ผู้ขนส่ง\",style: {padding: \"0.8em 0.8em\", width: \"70px\"}}"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{alignItems: \"center\", width: \"46%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.supplyDeliveryManagerData?.transported?.name"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "Input",
      "parent": 34,
      "props": {
        "label": {
          "type": "code",
          "value": " {content: \"เวลา\",style: {padding: \"0.8em 0.8em\"}}"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{alignItems: \"center\", width: \"20%\", marginLeft: \"20px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.supplyDeliveryManagerData?.transported?.datetime"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "Input",
      "parent": 34,
      "props": {
        "label": {
          "type": "code",
          "value": "  {content: \"แผนก\",style: {padding: \"0.8em 0.8em\"}}"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{alignItems: \"center\", width: \"25%\", marginLeft: \"20px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.supplyDeliveryManagerData?.transported?.division"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "Button",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิกการจ่าย"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.actionUnDeliver"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{display: props.supplyDeliveryManagerData?.status_name ===  \"TRANSPORTED\" && !props.isCardReceive ? \"\" : \"none\"}"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ErrorMessage"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "div",
      "parent": 24,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "style": {
          "type": "code",
          "value": "{display: props.showButton  ? \"none\" : \"\"}"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "Button",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิกจัดส่ง"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.actionUnCancel"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{display: props.supplyDeliveryManagerData?.status_name ===  \"PRINTED\" ? \"\" : \"none\"}"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "div",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.supplyDeliveryManagerData?.status_name ===  \"CHECKED\"  ? \"flex\" : \"none\",marginRight:\"5px\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "Input",
      "parent": 24,
      "props": {
        "disabled": {
          "type": "code",
          "value": ""
        },
        "label": {
          "type": "code",
          "value": "{content: \"ผู้จัดส่ง\", style: {display: \"flex\", alignItems: \"center\"}}"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeCoUser"
        },
        "onKeyDown": {
          "type": "code",
          "value": "(e: any) => { e.key === \"Enter\" && props.coUserVerify(); }"
        },
        "placeholder": {
          "type": "value",
          "value": "code"
        },
        "size": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.supplyDeliveryManagerData?.status_name ===  \"CHECKED\"  ? \"flex\" : \"none\",marginRight:\"5px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.coUser"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "Button",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิกรับเวชภัณฑ์ "
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.actionUnReceive"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{display: props.supplyDeliveryManagerData?.status_name ===  \"RECEIVED\" && props.isCardReceive ? \"\" : \"none\"}"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonReprint"
        }
      },
      "seq": 21,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardSupplyDeliveryManagerUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
