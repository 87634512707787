import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form, Button, Modal, Header } from "semantic-ui-react";
import { useIntl } from "react-intl";

const ModalVerifyUser = props => {
  const intl = useIntl();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handlePasswordChange = (e, item) => {
    setPassword(item.value);
  };
  const handleUsernameChange = (e, item) => {
    setUsername(item.value);
  };


  const handleOnCancel = (e, value) => {
    setUsername('')
    setPassword('')
    props.onCancel();
  }


  const handleSubmit = (e, value) => {
    props.handleSubmitVerify({ username, password})
    setUsername('')
    setPassword('')
  }

  return (
    <> 
   
    <Modal
      open={props.open}
      onClose={handleOnCancel}
      size="small"
      closeIcon
    >
      <Header icon="key" content={intl.formatMessage({ id: "กรุณาลงชื่อใหม่อีกครั้งเพื่อยืนยันตัวตน" })} />
      <Modal.Content>
        <Form>
          <Form.Group>
            <Form.Input
              placeholder="Username"
              name="name"
              value={username}
              label='Username'
              onChange={handleUsernameChange}
            />
            <Form.Input
              placeholder="Password"
              name="email"
              value={password}
              type="password"
              label='Password'
              onChange={handlePasswordChange}
            />
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button color="red" onClick={handleOnCancel}> Cancel</Button>
        <Button color="green" onClick={handleSubmit}> Verify</Button>
      </Modal.Actions>
    </Modal>
    </>
  );
};

ModalVerifyUser.defaultProps = {
  open: false,
  onClose: (() => {}),
  onCancel: () => {},
  handleSubmitVerify: ()=>{}
}

ModalVerifyUser.propsTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
  handleSubmitVerify: PropTypes.func,
};

export default ModalVerifyUser;
