import React, { Component, createRef } from "react";
import {
  Tab,
  Segment,
  Loader,
  Grid,
  Dropdown,
  Dimmer,
} from "semantic-ui-react";

import CardICD10 from "../DPO/CardICD10";

import CoreManager from "react-lib/apis/manager/CoreManager";
// apis
import { EncounterDetail } from "../../apis/master/core/Encounter";
import { DPODiagnosisMedicalRecordDetail } from "../../apis/master/core/MedicalRecord";
import { TECRequestList } from "../../apis/master/apps/TEC/TECRequest";
import { TECSessionDetail } from "../../apis/master/apps/TEC/TECSession";

// ui
import ErrorMessage from "../common/ErrorMessage";
import { toast } from "react-toastify";
import ModInfo from "../common/ModInfo";

// tab Diagnosis
import CardPTDiagnosisOrthopedicOPDIPDLOW, {
  CardPTDiagnosisOrthopedicOPDIPDLOWInitialViewData,
  CardPTDiagnosisOrthopedicOPDIPDLOWViewDataType,
} from "./CardPTDiagnosisOrthopedicOPDIPDLOW";
import CardOTDiagnosisPhysicalDysfunctionLOW, {
  CardOTDiagnosisPhysicalDysfunctionLOWViewDataType,
  CardOTDiagnosisPhysicalDysfunctionLOWInitialViewData,
} from "./CardOTDiagnosisPhysicalDysfunctionLOW";
import CardOTDiagnosisSwallowingEvaluationLOW, {
  CardOTDiagnosisSwallowingEvaluationLOWViewDataType,
  CardOTDiagnosisSwallowingEvaluationLOWInitialViewData,
} from "./CardOTDiagnosisSwallowingEvaluationLOW";
import CardOTDiagnosisPediatricsLOW, {
  CardOTDiagnosisPediatricsLOWViewDataType,
  CardOTDiagnosisPediatricsLOWInitialViewData,
} from "./CardOTDiagnosisPediatricsLOW";
import CardOTDiagnosisCerebralPalsyLOW, {
  CardOTDiagnosisCerebralPalsyLOWViewDataType,
  CardOTDiagnosisCerebralPalsyLOWInitialViewData,
} from "./CardOTDiagnosisCerebralPalsyLOW";
import CardPODiagnosisOrthoticfootPremorbidIndependentLOW, {
  CardPODiagnosisOrthoticfootPremorbidIndependentLOWViewDataType,
  CardPODiagnosisOrthoticfootPremorbidIndependentLOWInitialViewData,
} from "./CardPODiagnosisOrthoticfootPremorbidIndependentLOW";

// tab Pain Assessment
import CardPainAssessment, {
  PainAssessmentInitialViewData,
  PainAssessmentViewDataType,
} from "./CardPainAssessment";

// tab Physical Examination
import CardPTPhysicalexaminationOrthopedicOPDTOP, {
  CardPTPhysicalexaminationOrthopedicOPDTOPViewDataType,
  CardPTPhysicalexaminationOrthopedicOPDTOPInitialViewData,
} from "./CardPTPhysicalexaminationOrthopedicOPDTOP";
import CardPTPhysicalExamOrthopedicMID, {
  CardPTPhysicalExamOrthopedicMIDInitialViewData,
  CardPTPhysicalExamOrthopedicMIDViewDataType,
} from "./CardPTPhysicalExamOrthopedicMID";
import CardPTPhysicalexaminationOrthopedicOPDLOW, {
  CardPTPhysicalexaminationOrthopedicOPDLOWViewDataType,
  CardPTPhysicalexaminationOrthopedicOPDLOWInitialViewData,
} from "./CardPTPhysicalexaminationOrthopedicOPDLOW";

import CardPTPhysicalexaminationCardiopulmonaryTOP, {
  CardPTPhysicalexaminationCardiopulmonaryTOPViewDataType,
  CardPTPhysicalexaminationCardiopulmonaryTOPInitialViewData,
} from "./CardPTPhysicalexaminationCardiopulmonaryTOP";
import CardPTPhysicalExamCardiopulmonaryMID, {
  CardPTPhysicalExamCardiopulmonaryMIDViewDataType,
  CardPTPhysicalExamCardiopulmonaryMIDInitialViewData,
} from "./CardPTPhysicalExamCardiopulmonaryMID";

import CardPTPhysicalexaminationNeuroogialOPDTOP, {
  CardPTPhysicalexaminationNeuroogialOPDTOPViewDataType,
  CardPTPhysicalexaminationNeuroogialOPDTOPInitialViewData,
} from "./CardPTPhysicalexaminationNeuroogialOPDTOP";
import CardPTPhysicalExamNeuroogialMID, {
  CardPTPhysicalExamNeuroogialMIDInitialViewData,
  CardPTPhysicalExamNeuroogialMIDViewDataType,
} from "./CardPTPhysicalExamNeuroogialMID";
import PTPhysicalexaminationNeuroogialOPDLOW, {
  PTPhysicalexaminationNeuroogialOPDLOWViewDataType,
  PTPhysicalexaminationNeuroogialOPDLOWInitialViewData,
} from "./uigen/PTPhysicalexaminationNeuroogialOPDLOW";

import POPhysicalexaminationOrthoticFittingandCheckout2TOP, {
  POPhysicalexaminationOrthoticFittingandCheckout2TOPViewDataType,
  POPhysicalexaminationOrthoticFittingandCheckout2TOPInitialViewData,
} from "./uigen/POPhysicalexaminationOrthoticFittingandCheckout2TOP";
import CardPOPhysicalExamOrthoticFittingAndCheckoutMID, {
  CardPOPhysicalExamOrthoticFittingAndCheckoutMIDInitialViewData,
  CardPOPhysicalExamOrthoticFittingAndCheckoutMIDViewDataType,
} from "./CardPOPhysicalExamOrthoticFittingAndCheckoutMID";
import POPhysicalexaminationOrthoticFittingandCheckout2LOW, {
  POPhysicalexaminationOrthoticFittingandCheckout2LOWViewDataType,
  POPhysicalexaminationOrthoticFittingandCheckout2LOWInitialViewData,
} from "./uigen/POPhysicalexaminationOrthoticFittingandCheckout2LOW";

import POPhysicalexaminationAnkleFootOrthosisTOP, {
  POPhysicalexaminationAnkleFootOrthosisTOPViewDataType,
  POPhysicalexaminationAnkleFootOrthosisTOPInitialViewData,
} from "./uigen/POPhysicalexaminationAnkleFootOrthosisTOP";
import CardPOPhysicalExamAnkleFootOrthosisMID, {
  CardPOPhysicalExamAnkleFootOrthosisMIDViewDataType,
  CardPOPhysicalExamAnkleFootOrthosisMIDInitialViewData,
} from "./CardPOPhysicalExamAnkleFootOrthosisMID";
import POPhysicalexaminationAnkleFootOrthosisLOW, {
  POPhysicalexaminationAnkleFootOrthosisLOWViewDataType,
  POPhysicalexaminationAnkleFootOrthosisLOWInitialViewData,
} from "./uigen/POPhysicalexaminationAnkleFootOrthosisLOW";

import POPhysicalexaminationOrthoticFootOrthotic2TOP, {
  POPhysicalexaminationOrthoticFootOrthotic2TOPViewDataType,
  POPhysicalexaminationOrthoticFootOrthotic2TOPInitialViewData,
} from "./uigen/POPhysicalexaminationOrthoticFootOrthotic2TOP";
import CardPOPhysicalExamOrthoticFootOrthoticMID, {
  CardPOPhysicalExamOrthoticFootOrthoticMIDViewDataType,
  CardPOPhysicalExamOrthoticFootOrthoticMIDInitialViewData,
} from "./CardPOPhysicalExamOrthoticFootOrthoticMID";
import POPhysicalexaminationOrthoticFootOrthotic2SIDE, {
  POPhysicalexaminationOrthoticFootOrthotic2SIDEViewDataType,
  POPhysicalexaminationOrthoticFootOrthotic2SIDEInitialViewData,
} from "./uigen/POPhysicalexaminationOrthoticFootOrthotic2SIDE";
import POPhysicalexaminationOrthoticFootOrthotic2LOW, {
  POPhysicalexaminationOrthoticFootOrthotic2LOWViewDataType,
  POPhysicalexaminationOrthoticFootOrthotic2LOWInitialViewData,
} from "./uigen/POPhysicalexaminationOrthoticFootOrthotic2LOW";
import CardMultiSelectPosition, {
  CardMultiSelectPositionViewDataType,
  CardMultiSelectPositionInitialViewData,
} from "./CardMultiSelectPosition";

import CardOTPhysicalExaminationPhysicalDysfunctionMain, {
  CardOTPhysicalExaminationPhysicalDysfunctionMainViewDataType,
  CardOTPhysicalExaminationPhysicalDysfunctionMainInitialViewData,
} from "./CardOTPhysicalExaminationPhysicalDysfunctionMain";
import CardOTPhysicalExaminationPhysicalDysfunctionSensorimotorFunction, {
  CardOTPhysicalExaminationPhysicalDysfunctionSensorimotorFunctionViewDataType,
  CardOTPhysicalExaminationPhysicalDysfunctionSensorimotorFunctionInitialViewData,
} from "./CardOTPhysicalExaminationPhysicalDysfunctionSensorimotorFunction";
import OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeft, {
  OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeftViewDataType,
  OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeftInitialViewData,
} from "./uigen/OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeft";
import OTPhysicalExaminationPhysicalDysfunctionHandFunctionRight, {
  OTPhysicalExaminationPhysicalDysfunctionHandFunctionRightViewDataType,
  OTPhysicalExaminationPhysicalDysfunctionHandFunctionRightInitialViewData,
} from "./uigen/OTPhysicalExaminationPhysicalDysfunctionHandFunctionRight";
import CardOTPhysicalExaminationPhysicalDysfunctionActivitiesOfDailyLiving, {
  CardOTPhysicalExaminationPhysicalDysfunctionActivitiesOfDailyLivingViewDataType,
  CardOTPhysicalExaminationPhysicalDysfunctionActivitiesOfDailyLivingInitialViewData,
} from "./CardOTPhysicalExaminationPhysicalDysfunctionActivitiesOfDailyLiving";
import CardOTPhysicalExaminationSwallowingEvaluation1, {
  CardOTPhysicalExaminationSwallowingEvaluation1ViewDataType,
  CardOTPhysicalExaminationSwallowingEvaluation1InitialViewData,
} from "./CardOTPhysicalExaminationSwallowingEvaluation1";
import OTPhysicalExaminationSwallowingEvaluation2, {
  OTPhysicalExaminationSwallowingEvaluation2ViewDataType,
  OTPhysicalExaminationSwallowingEvaluation2InitialViewData,
} from "./uigen/OTPhysicalExaminationSwallowingEvaluation2";
import CardOTPhysicalExaminationPediatrics1, {
  CardOTPhysicalExaminationPediatrics1ViewDataType,
  CardOTPhysicalExaminationPediatrics1InitialViewData,
} from "./CardOTPhysicalExaminationPediatrics1";
import OTPhysicalExaminationPediatrics2, {
  OTPhysicalExaminationPediatrics2ViewDataType,
  OTPhysicalExaminationPediatrics2InitialViewData,
} from "./uigen/OTPhysicalExaminationPediatrics2";
import OTPhysicalExaminationPediatrics3, {
  OTPhysicalExaminationPediatrics3ViewDataType,
  OTPhysicalExaminationPediatrics3InitialViewData,
} from "./uigen/OTPhysicalExaminationPediatrics3";
import CardOTPhysicalExaminationCerebralPalsy1, {
  OTPhysicalExaminationCerebralPalsy1ViewDataType,
  OTPhysicalExaminationCerebralPalsy1InitialViewData,
} from "./CardOTPhysicalExaminationCerebralPalsy1";
import CardOTPhysicalExaminationCerebralPalsyMID, {
  CardOTPhysicalExaminationCerebralPalsyMIDViewDataType,
  CardOTPhysicalExaminationCerebralPalsyMIDInitialViewData,
} from "./CardOTPhysicalExaminationCerebralPalsyMID";
import CardOTPhysicalExaminationCerebralPalsy2, {
  CardOTPhysicalExaminationCerebralPalsy2ViewDataType,
  CardOTPhysicalExaminationCerebralPalsy2InitialViewData,
} from "./CardOTPhysicalExaminationCerebralPalsy2";
import OTPhysicalExaminationCerebralPalsy3, {
  OTPhysicalExaminationCerebralPalsy3ViewDataType,
  OTPhysicalExaminationCerebralPalsy3InitialViewData,
} from "./uigen/OTPhysicalExaminationCerebralPalsy3";

// tab Rehab
import PTRehabilitationtreatment, {
  PTRehabilitationtreatmentViewDataType,
  PTRehabilitationtreatmentInitialViewData,
} from "./CardPTRehabilitationtreatment";
import OTRehabilitationTreatment, {
  OTRehabilitationTreatmentViewDataType,
  OTRehabilitationTreatmentInitialViewData,
} from "./CardOTRehabilitationTreatment";
import PORehabilitationTreatmentOrthoticFittingandCheckout, {
  PORehabilitationTreatmentOrthoticFittingandCheckoutViewDataType,
  PORehabilitationTreatmentOrthoticFittingandCheckoutInitialViewData,
} from "./CardPORehabilitationTreatmentOrthoticFittingandCheckout";
import PORehabilitationTreatmentAnkleFootOrthotic, {
  PORehabilitationTreatmentAnkleFootOrthoticViewDataType,
  PORehabilitationTreatmentAnkleFootOrthoticInitialViewData,
} from "./CardPORehabilitationTreatmentAnkleFootOrthotic";
import PORehabilitationTreatmentOrthoticFoot, {
  PORehabilitationTreatmentOrthoticFootViewDataType,
  PORehabilitationTreatmentOrthoticFootInitialViewData,
} from "./CardPORehabilitationTreatmentOrthoticFoot";

// IPD Progress Note
import CardRehabilitationProgressNote from "./CardRehabilitationProgressNote";

// tab Subjective
import PTOTSubjectiveLOW, {
  PTOTSubjectiveLOWViewDataType,
  PTOTSubjectiveLOWInitialViewData,
} from "./uigen/PTOTSubjectiveLOW";

// Objective
import PTObjectiveOrthoTOP, {
  PTObjectiveOrthoTOPViewDataType,
  PTObjectiveOrthoTOPInitialViewData,
} from "./uigen/PTObjectiveOrthoTOP";
import CardPTObjectiveOrthoLOW, {
  CardPTObjectiveOrthoLOWViewDataType,
  CardPTObjectiveOrthoLOWInitialViewData,
} from "./CardPTObjectiveOrthoLOW";

import PTObjectiveCardiopulmonaryOPD, {
  PTObjectiveCardiopulmonaryOPDViewDataType,
  PTObjectiveCardiopulmonaryOPDInitialViewData,
} from "./uigen/PTObjectiveCardiopulmonaryOPD";
import PTObjectiveNeuro, {
  PTObjectiveNeuroViewDataType,
  PTObjectiveNeuroInitialViewData,
} from "./uigen/PTObjectiveNeuro";

import CardOTObjectivePediatrics, {
  CardOTObjectivePediatricsViewDataType,
  CardOTObjectivePediatricsInitialViewData,
} from "./CardOTObjectivePediatrics";
import CardOTObjectivePhysicalDysfunction1, {
  CardOTObjectivePhysicalDysfunction1ViewDataType,
  CardOTObjectivePhysicalDysfunction1InitialViewData,
} from "./CardOTObjectivePhysicalDysfunction1";

// tab Plan
import PTprogressionnoteOPDCardioNeuro, {
  PTprogressionnoteOPDCardioNeuroViewDataType,
  PTprogressionnoteOPDCardioNeuroInitialViewData,
} from "./PTprogressionnoteOPDCardioNeuro";
import PTprogressionnoteOPDOrtho, {
  PTprogressionnoteOPDOrthoViewDataType,
  PTprogressionnoteOPDOrthoInitialViewData,
} from "./PTprogressionnoteOPDOrtho";
import CardOTProgressionNoteOPDDysfunction, {
  OTProgressionNoteOPDDysfunctionViewDataType,
  OTProgressionNoteOPDDysfunctionInitialViewData,
} from "./CardOTProgressionNoteOPDDysfunction";

// React Card
import CardPTLabImagingExam, {
  PTLabImaingExamInitialViewData,
} from "./CardPTLabImagingExam";
import CardDiagnosisReadOnlyDoctor from "./CardDiagnosisReadOnlyDoctor";
import ModalSwallowingRemark from "./ModalSwallowingRemark";
import ModalRequireField from "./ModalRequireField";
import { beToAd } from "../../utils";
import { useIntl } from "react-intl";

const styles = {
  qmlCardStyle: {
    marginBottom: "15px",
  },
  noPadding: {
    padding: "0px",
  },
  noTopPadding: {
    paddingTop: "0px",
  },
  noRightPadding: {
    paddingRight: "0px",
  },
};

const MENU = {
  ORTHO: "ORTHO",
  CARDIO: "CARDIO",
  NEURO: "NEURO",
  PHYSDYS: "PHYSDYS",
  SWALLOW: "SWALLOW",
  PEDIATRICS: "PEDIATRICS",
  CEREBRAL: "CEREBRAL",
  FITANDCHECK: "FITANDCHECK",
  ANKLEFOOT: "ANKLEFOOT",
  ORTHOTICFOOT: "ORTHOTICFOOT",
};

const TECTYPE = {
  PHYSICAL: "PHYSICAL",
  OCCUPATIONAL: "OCCUPATIONAL",
  PROSTHETIC_ORTHOTIC: "PROSTHETIC_ORTHOTIC",
};

class Card extends Component<
  {

    patientInfo?: any;
    patientData: any;
    sessionId: string;
    isOpenAssessment?: boolean;
    readonlySession?: boolean;
    subICD10DetailController: any;

    // For plug with MainPHV
    appEncounterId: any;
    onAppEncounterIdChange: any;
    cardLabResultController: any;
    hideLab?: boolean;
    cardImagingExamResultController: any;
    onEvent: any;
  },
  {
    isLoading: boolean;
    activeTab: number;
    activeMenu: string;
    isShowRemark: boolean;
    openModalRequiredField: boolean;
    requiredFieldMessage: string;

    TecType: string;

    medicalRecordDiagnosis: any;

    // tab Diagnosis
    CardPTDiagnosisOrthopedicOPDIPDLOWViewData: CardPTDiagnosisOrthopedicOPDIPDLOWViewDataType;

    CardOTDiagnosisPhysicalDysfunctionLOWViewData: CardOTDiagnosisPhysicalDysfunctionLOWViewDataType;
    CardOTDiagnosisSwallowingEvaluationLOWViewData: CardOTDiagnosisSwallowingEvaluationLOWViewDataType;
    CardOTDiagnosisPediatricsLOWViewData: CardOTDiagnosisPediatricsLOWViewDataType;
    CardOTDiagnosisCerebralPalsyLOWViewData: CardOTDiagnosisCerebralPalsyLOWViewDataType;

    // CardPODiagnosisOrthoticfootPremorbidIndependentLOWViewData: CardPODiagnosisOrthoticfootPremorbidIndependentLOWViewDataType;
    FITANDCHECKCardPODiagnosisOrthoticfootPremorbidIndependentLOWViewData: CardPODiagnosisOrthoticfootPremorbidIndependentLOWViewDataType;
    ORTHOTICFOOTCardPODiagnosisOrthoticfootPremorbidIndependentLOWViewData: CardPODiagnosisOrthoticfootPremorbidIndependentLOWViewDataType;
    ANKLEFOOTCardPODiagnosisOrthoticfootPremorbidIndependentLOWViewData: CardPODiagnosisOrthoticfootPremorbidIndependentLOWViewDataType;

    // tab Pain Assessment
    ORTHOProgressPainAssessmentViewData: PainAssessmentViewDataType;
    CARDIOProgressPainAssessmentViewData: PainAssessmentViewDataType;
    NEUROProgressPainAssessmentViewData: PainAssessmentViewDataType;
    AssessmentPainAssessmentViewData: PainAssessmentViewDataType;
    AnkleFootAssessmentPainAssessmentViewData: PainAssessmentViewDataType;
    OrthoticFootAssessmentPainAssessmentViewData: PainAssessmentViewDataType;
    PainAssessmentDropdown: {};

    // tab Physical Examination
    CardPTPhysicalexaminationOrthopedicOPDTOPViewData: CardPTPhysicalexaminationOrthopedicOPDTOPViewDataType;
    CardPTPhysicalExamOrthopedicMIDViewData: CardPTPhysicalExamOrthopedicMIDViewDataType;
    CardPTPhysicalexaminationOrthopedicOPDLOWViewData: CardPTPhysicalexaminationOrthopedicOPDLOWViewDataType;

    CardPTPhysicalexaminationCardiopulmonaryTOPViewData: CardPTPhysicalexaminationCardiopulmonaryTOPViewDataType;
    CardPTPhysicalExamCardiopulmonaryMIDViewData: CardPTPhysicalExamCardiopulmonaryMIDViewDataType;
    PhysicalExamCardioMIDDropdown: {};

    CardPTPhysicalexaminationNeuroogialOPDTOPViewData: CardPTPhysicalexaminationNeuroogialOPDTOPViewDataType;
    CardPTPhysicalExamNeuroogialMIDViewData: CardPTPhysicalExamNeuroogialMIDViewDataType;
    PTPhysicalexaminationNeuroogialOPDLOWViewData: PTPhysicalexaminationNeuroogialOPDLOWViewDataType;

    POPhysicalexaminationOrthoticFittingandCheckout2TOPViewData: POPhysicalexaminationOrthoticFittingandCheckout2TOPViewDataType;
    CardPOPhysicalExamOrthoticFittingAndCheckoutMIDViewData: CardPOPhysicalExamOrthoticFittingAndCheckoutMIDViewDataType;
    POPhysicalexaminationOrthoticFittingandCheckout2LOWViewData: POPhysicalexaminationOrthoticFittingandCheckout2LOWViewDataType;
    PhysicalExamOrthoticFittingAndCheckoutDropdown: {};

    POPhysicalexaminationAnkleFootOrthosisTOPViewData: POPhysicalexaminationAnkleFootOrthosisTOPViewDataType;
    CardPOPhysicalExamAnkleFootOrthosisMIDViewData: CardPOPhysicalExamAnkleFootOrthosisMIDViewDataType;
    POPhysicalexaminationAnkleFootOrthosisLOWViewData: POPhysicalexaminationAnkleFootOrthosisLOWViewDataType;
    PhysicalExamAnkleFootOrthosisDropdown: {};

    POPhysicalexaminationOrthoticFootOrthotic2TOPViewData: POPhysicalexaminationOrthoticFootOrthotic2TOPViewDataType;
    CardPOPhysicalExamOrthoticFootOrthoticMIDViewData: CardPOPhysicalExamOrthoticFootOrthoticMIDViewDataType;
    POPhysicalexaminationOrthoticFootOrthotic2LOWViewData: POPhysicalexaminationOrthoticFootOrthotic2LOWViewDataType;
    POPhysicalexaminationOrthoticFootOrthotic2SIDEViewData: POPhysicalexaminationOrthoticFootOrthotic2SIDEViewDataType;
    CardMultiSelectPositionViewData: CardMultiSelectPositionViewDataType;

    CardOTPhysicalExaminationPhysicalDysfunctionMainViewData: CardOTPhysicalExaminationPhysicalDysfunctionMainViewDataType;
    CardOTPhysicalExaminationPhysicalDysfunctionSensorimotorFunctionViewData: CardOTPhysicalExaminationPhysicalDysfunctionSensorimotorFunctionViewDataType;
    OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeftViewData: OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeftViewDataType;
    OTPhysicalExaminationPhysicalDysfunctionHandFunctionRightViewData: OTPhysicalExaminationPhysicalDysfunctionHandFunctionRightViewDataType;
    CardOTPhysicalExaminationPhysicalDysfunctionActivitiesOfDailyLivingViewData: CardOTPhysicalExaminationPhysicalDysfunctionActivitiesOfDailyLivingViewDataType;
    CardOTPhysicalExaminationSwallowingEvaluation1ViewData: CardOTPhysicalExaminationSwallowingEvaluation1ViewDataType;
    OTPhysicalExaminationSwallowingEvaluation2ViewData: OTPhysicalExaminationSwallowingEvaluation2ViewDataType;
    CardOTPhysicalExaminationPediatrics1ViewData: CardOTPhysicalExaminationPediatrics1ViewDataType;
    OTPhysicalExaminationPediatrics2ViewData: OTPhysicalExaminationPediatrics2ViewDataType;
    OTPhysicalExaminationPediatrics3ViewData: OTPhysicalExaminationPediatrics3ViewDataType;
    OTPhysicalExaminationCerebralPalsy1ViewData: OTPhysicalExaminationCerebralPalsy1ViewDataType;
    CardOTPhysicalExaminationCerebralPalsyMIDViewData: CardOTPhysicalExaminationCerebralPalsyMIDViewDataType;
    CardOTPhysicalExaminationCerebralPalsy2ViewData: CardOTPhysicalExaminationCerebralPalsy2ViewDataType;
    OTPhysicalExaminationCerebralPalsy3ViewData: OTPhysicalExaminationCerebralPalsy3ViewDataType;

    // tab Rehab
    PTRehabilitationtreatmentViewData: PTRehabilitationtreatmentViewDataType;
    OTRehabilitationTreatmentViewData: OTRehabilitationTreatmentViewDataType;
    PORehabilitationTreatmentOrthoticFittingandCheckoutViewData: PORehabilitationTreatmentOrthoticFittingandCheckoutViewDataType;
    PORehabilitationTreatmentAnkleFootOrthoticViewData: PORehabilitationTreatmentAnkleFootOrthoticViewDataType;
    PORehabilitationTreatmentOrthoticFootViewData: PORehabilitationTreatmentOrthoticFootViewDataType;

    // Subjective
    ORTHOPTOTSubjectiveLOWViewData: PTOTSubjectiveLOWViewDataType;
    CARDIOPTOTSubjectiveLOWViewData: PTOTSubjectiveLOWViewDataType;
    NEUROPTOTSubjectiveLOWViewData: PTOTSubjectiveLOWViewDataType;
    PHYSDYSPTOTSubjectiveLOWViewData: PTOTSubjectiveLOWViewDataType;
    PEDIATRICSPTOTSubjectiveLOWViewData: PTOTSubjectiveLOWViewDataType;

    // Objective
    PTObjectiveOrthoTOPViewData: PTObjectiveOrthoTOPViewDataType;
    CardPTObjectiveOrthoLOWViewData: CardPTObjectiveOrthoLOWViewDataType;

    PTObjectiveCardiopulmonaryOPDViewData: PTObjectiveCardiopulmonaryOPDViewDataType;
    PTObjectiveNeuroViewData: PTObjectiveNeuroViewDataType;
    CardOTObjectivePediatricsViewData: CardOTObjectivePediatricsViewDataType;
    CardOTObjectivePhysicalDysfunction1ViewData: CardOTObjectivePhysicalDysfunction1ViewDataType;

    // tab Plan
    PTprogressionnoteOPDOrthoViewData: PTprogressionnoteOPDOrthoViewDataType;
    PTprogressionnoteOPDCardioNeuroViewData: PTprogressionnoteOPDCardioNeuroViewDataType;
    OTProgressionNoteOPDDysfunctionViewData: OTProgressionNoteOPDDysfunctionViewDataType;

    // tab Lab / Imaging
    PTLabImaingExamViewData: PTLabImaingExamDataType;
  }
> {
  // Life cycle
  constructor(props) {
    super(props);
    this.coreManager = new CoreManager();
    this.progressCardPainAssessmentRef = createRef();
    this.assessmentCardPainAssessmentRef = createRef();
    this.ankleFootAssessmentCardPainAssessmentRef = createRef();
    this.orthoticFootAssessmentCardPainAssessmentRef = createRef();
    this.cardPTPhysicalExamOrthopedicMIDRef = createRef();
    this.cardPTPhysicalExamCardioMIDRef = createRef();
    this.cardPTPhysicalExamNeuroogialMIDRef = createRef();
    this.cardPTObjectiveOrthoLOWRef = createRef();
    this.cardPOPhysicalExamOrthoticFittingAndCheckoutMIDRef = createRef();
    this.cardPOPhysicalExamAnkleFootOrthosisMIDRef = createRef();
    this.cardPOPhysicalExamOrthoticFootOrthoticMIDRef = createRef();
    this.cardMultiSelectPositionRef = createRef();
    this.cardOTPhysicalExaminationCerebralPalsyMIDRef = createRef();
    this.tableRangeOfMotionRef = createRef();
    this.tableFunctionalMotionRef = createRef();
    this.cardOTPhysicalExaminationPhysicalDysfunctionSensorimotorFunctionRef = createRef();

    this.state = {
      error: "",
      isLoading: false,
      activeTab: 1,
      activeMenu: "",
      isShowRemark: false,
      openModalRequiredField: false,
      requiredFieldMessage: "",
      TecType: "",

      isOTPEDysClickLeft: false,
      isOTPEDysClickRight: false,

      medicalRecordDiagnosis: {},

      // ----- tab Diagnosis
      // กายภาพ Orthopedic, Cardiopulmonary, Neurological
      CardPTDiagnosisOrthopedicOPDIPDLOWViewData: {
        ...CardPTDiagnosisOrthopedicOPDIPDLOWInitialViewData,
        PtDiagnosisOrthoOpdipdLow21Disabled: true,
        PtDiagnosisOrthoOpdipdLow40Disabled: true,
        PtDiagnosisOrthoOpdipdLow52Disabled: true,
      },
      // กิจกรรม Dysfunction
      CardOTDiagnosisPhysicalDysfunctionLOWViewData: Object.assign(
        {},
        {
          ...CardOTDiagnosisPhysicalDysfunctionLOWInitialViewData,
          OtDiagnosisPhysicalDysfunctionLow21Disabled: true,
          OtDiagnosisPhysicalDysfunctionLow45Disabled: true,
          OtDiagnosisPhysicalDysfunctionLow57Disabled: true,
        }
      ),
      // กิจกรรม Swallowing
      CardOTDiagnosisSwallowingEvaluationLOWViewData: {
        ...CardOTDiagnosisSwallowingEvaluationLOWInitialViewData,
        OtDiagnosisSwallowingLow25Disabled: true,
        OtDiagnosisSwallowingLow48Disabled: true,
        OtDiagnosisSwallowingLow58Disabled: true,
      },
      // กิจกรรม Pediatrics
      CardOTDiagnosisPediatricsLOWViewData: {
        ...CardOTDiagnosisPediatricsLOWInitialViewData,
        OtDiagnosisPediatricsLow26Disabled: true,
        OtDiagnosisPediatricsLow54Disabled: true,
        OtDiagnosisPediatricsLow66Disabled: true,
        OtDiagnosisPediatricsLow96Disabled: true,
      },
      // กิจกรรม CerebralPalsy
      CardOTDiagnosisCerebralPalsyLOWViewData: {
        ...CardOTDiagnosisCerebralPalsyLOWInitialViewData,
        OtDiagnosisCerebralLow25Disabled: true,
        OtDiagnosisCerebralLow52Disabled: true,
        OtDiagnosisCerebralLow64Disabled: true,
        OtDiagnosisCerebralLow98Disabled: true,
      },
      // กายอุปกรณ์ Orthotic Fitting and Checkout, Ankle Foot Orthosis, Orthotic Foot
      // CardPODiagnosisOrthoticfootPremorbidIndependentLOWViewData: CardPODiagnosisOrthoticfootPremorbidIndependentLOWInitialViewData,
      FITANDCHECKCardPODiagnosisOrthoticfootPremorbidIndependentLOWViewData: {
        ...CardPODiagnosisOrthoticfootPremorbidIndependentLOWInitialViewData,
        PoDiagnosisOrthofootLow31Disabled: true,
      },
      ANKLEFOOTCardPODiagnosisOrthoticfootPremorbidIndependentLOWViewData: {
        ...CardPODiagnosisOrthoticfootPremorbidIndependentLOWInitialViewData,
        PoDiagnosisOrthofootLow31Disabled: true,
      },
      ORTHOTICFOOTCardPODiagnosisOrthoticfootPremorbidIndependentLOWViewData: {
        ...CardPODiagnosisOrthoticfootPremorbidIndependentLOWInitialViewData,
        PoDiagnosisOrthofootLow31Disabled: true,
      },
      // tab Pain Assessment
      ORTHOProgressPainAssessmentViewData: PainAssessmentInitialViewData,
      CARDIOProgressPainAssessmentViewData: PainAssessmentInitialViewData,
      NEUROProgressPainAssessmentViewData: PainAssessmentInitialViewData,
      AssessmentPainAssessmentViewData: PainAssessmentInitialViewData,
      AnkleFootAssessmentPainAssessmentViewData: PainAssessmentInitialViewData,
      OrthoticFootAssessmentPainAssessmentViewData: PainAssessmentInitialViewData,
      PainAssessmentDropdown: {},

      // tab Physical Examination
      CardPTPhysicalexaminationOrthopedicOPDTOPViewData: {
        ...CardPTPhysicalexaminationOrthopedicOPDTOPInitialViewData,
        Ct10GeneralAppearance: "normal",
        Ct12Consciousness: "alert",
        Ct15Communication: "good",
        Ct15CoOperation: "good",
        PtPhysicalOrthoOpdTop47Disabled: true,
        PtPhysicalOrthoOpdTop48Disabled: true,
        PtPhysicalOrthoOpdTop49Disabled: true,
        PtPhysicalOrthoOpdTop51Disabled: true,
        PtPhysicalOrthoOpdTop52Disabled: true,
        PtPhysicalOrthoOpdTop54Disabled: true,
        PtPhysicalOrthoOpdTop55Disabled: true,
        PtPhysicalOrthoOpdTop57Disabled: true,
        PtPhysicalOrthoOpdTop59: true,
      },
      CardPTPhysicalExamOrthopedicMIDViewData: CardPTPhysicalExamOrthopedicMIDInitialViewData,
      CardPTPhysicalexaminationOrthopedicOPDLOWViewData: {
        ...CardPTPhysicalexaminationOrthopedicOPDLOWInitialViewData,
        PtPhysicalOrthoOpdLow9: true,
        PtPhysicalOrthoOpdLow10Disabled: true,
        PtPhysicalOrthoOpdLow13Disabled: true,
      },

      CardPTPhysicalexaminationCardiopulmonaryTOPViewData: {
        ...CardPTPhysicalexaminationCardiopulmonaryTOPInitialViewData,
        Ct8GeneralAppearance: "normal",
        Ct10Consciousness: "alert",
        Ct13Communication: "good",
        Ct13CoOperation: "good",
        PtPhysicalCadioTop81Disabled: true,
        PtPhysicalCadioTop82Disabled: true,
        PtPhysicalCadioTop83Disabled: true,
        PtPhysicalCadioTop85Disabled: true,
        PtPhysicalCadioTop86Disabled: true,
        PtPhysicalCadioTop88Disabled: true,
        PtPhysicalCadioTop89Disabled: true,
        PtPhysicalCadioTop91Disabled: true,
        PtPhysicalCadioTop93: true,
        PtPhysicalCadioTop97Disabled: true,
        PtPhysicalCadioTop98: true,
        PtPhysicalCadioTop100Disabled: true,
        PtPhysicalCadioTop103Disabled: true,
        PtPhysicalCadioTop114Disabled: true,
        PtPhysicalCadioTop115Disabled: true,
        PtPhysicalCadioTop116Disabled: true,
        PtPhysicalCadioTop118Disabled: true,
      },
      CardPTPhysicalExamCardiopulmonaryMIDViewData: CardPTPhysicalExamCardiopulmonaryMIDInitialViewData,
      PhysicalExamCardioMIDDropdown: {},

      CardPTPhysicalexaminationNeuroogialOPDTOPViewData: {
        ...CardPTPhysicalexaminationNeuroogialOPDTOPInitialViewData,
        Ct9GeneralAppearance: "normal",
        Ct11Consciousness: "alert",
        Ct14Communication: "good",
        Ct14CoOperation: "good",
        PtPhysicalNeuroOpdTop46Disabled: true,
        PtPhysicalNeuroOpdTop47Disabled: true,
        PtPhysicalNeuroOpdTop48Disabled: true,
        PtPhysicalNeuroOpdTop49Disabled: true,
        PtPhysicalNeuroOpdTop50Disabled: true,
        PtPhysicalNeuroOpdTop52Disabled: true,
        PtPhysicalNeuroOpdTop54Disabled: true,
        PtPhysicalNeuroOpdTop56Disabled: true,
        PtPhysicalNeuroOpdTop58: true,
      },
      CardPTPhysicalExamNeuroogialMIDViewData: CardPTPhysicalExamNeuroogialMIDInitialViewData,

      PTPhysicalexaminationNeuroogialOPDLOWViewData: PTPhysicalexaminationNeuroogialOPDLOWInitialViewData,

      POPhysicalexaminationOrthoticFittingandCheckout2TOPViewData: {
        ...POPhysicalexaminationOrthoticFittingandCheckout2TOPInitialViewData,
        Ct5GeneralAppearance: "normal",
        Ct5Consciousness: "alert",
        Ct8Communication: "good",
        Ct8CoOperation: "good",
        PoPhysicalOrthoFittingCheckout2Top38: true,
        PoPhysicalOrthoFittingCheckout2Top26Disabled: true,
        PoPhysicalOrthoFittingCheckout2Top27Disabled: true,
        PoPhysicalOrthoFittingCheckout2Top28Disabled: true,
        PoPhysicalOrthoFittingCheckout2Top30Disabled: true,
        PoPhysicalOrthoFittingCheckout2Top31Disabled: true,
        PoPhysicalOrthoFittingCheckout2Top33Disabled: true,
        PoPhysicalOrthoFittingCheckout2Top34Disabled: true,
        PoPhysicalOrthoFittingCheckout2Top36Disabled: true,
      },
      CardPOPhysicalExamOrthoticFittingAndCheckoutMIDViewData: CardPOPhysicalExamOrthoticFittingAndCheckoutMIDInitialViewData,
      PhysicalExamOrthoticFittingAndCheckoutDropdown: {},
      POPhysicalexaminationOrthoticFittingandCheckout2LOWViewData: POPhysicalexaminationOrthoticFittingandCheckout2LOWInitialViewData,

      POPhysicalexaminationAnkleFootOrthosisTOPViewData: {
        ...POPhysicalexaminationAnkleFootOrthosisTOPInitialViewData,
        Ct4GeneralApperance: "normal",
        Ct4Consciousness: "alert",
        Ct7Communication: "good",
        Ct7CoOperation: "good",
        PoPhysicalAnkleFootOrthosisTop24Disabled: true,
        PoPhysicalAnkleFootOrthosisTop25Disabled: true,
        PoPhysicalAnkleFootOrthosisTop26Disabled: true,
        PoPhysicalAnkleFootOrthosisTop28Disabled: true,
        PoPhysicalAnkleFootOrthosisTop29Disabled: true,
        PoPhysicalAnkleFootOrthosisTop31Disabled: true,
        PoPhysicalAnkleFootOrthosisTop32Disabled: true,
        PoPhysicalAnkleFootOrthosisTop34Disabled: true,
        PoPhysicalAnkleFootOrthosisTop36: true,
      },
      CardPOPhysicalExamAnkleFootOrthosisMIDViewData: CardPOPhysicalExamAnkleFootOrthosisMIDInitialViewData,
      POPhysicalexaminationAnkleFootOrthosisLOWViewData: POPhysicalexaminationAnkleFootOrthosisLOWInitialViewData,
      PhysicalExamAnkleFootOrthosisMIDDropdown: {},

      POPhysicalexaminationOrthoticFootOrthotic2TOPViewData: {
        ...POPhysicalexaminationOrthoticFootOrthotic2TOPInitialViewData,
        Ct6GeneralAppearance: "normal",
        Ct6Consciousness: "alert",
        Ct9Communication: "good",
        Ct9CoOperation: "good",
      },
      CardPOPhysicalExamOrthoticFootOrthoticMIDViewData: CardPOPhysicalExamOrthoticFootOrthoticMIDInitialViewData,
      POPhysicalexaminationOrthoticFootOrthotic2SIDEViewData: POPhysicalexaminationOrthoticFootOrthotic2SIDEInitialViewData,
      POPhysicalexaminationOrthoticFootOrthotic2LOWViewData: POPhysicalexaminationOrthoticFootOrthotic2LOWInitialViewData,
      CardMultiSelectPositionViewData: CardMultiSelectPositionInitialViewData,
      PhysicalExamOrthoticFootOrthoticMIDDropdown: {},
      cardMultiSelectPositionDropdown: {},

      CardOTPhysicalExaminationPhysicalDysfunctionMainViewData: {
        ...CardOTPhysicalExaminationPhysicalDysfunctionMainInitialViewData,
        Ct2Consciousness: "alert",
        Ct2GeneralAppearance: "normal",
        Ct3Cognitive: "good",
        Ct5Communication: "good",
        Ct5CoOperation: "good",
        CardOtPhysicalDysfunctionMain29Disabled: true,
        CardOtPhysicalDysfunctionMain59Disabled: true,
        CardOtPhysicalDysfunctionMain61Disabled: true,
        CardOtPhysicalDysfunctionMain64Disabled: true,
        CardOtPhysicalDysfunctionMain67Disabled: true,
        CardOtPhysicalDysfunctionMain74Disabled: true,
        CardOtPhysicalDysfunctionMain76Disabled: true,
        CardOtPhysicalDysfunctionMain79Disabled: true,
        CardOtPhysicalDysfunctionMain93Disabled: true,
        CardOtPhysicalDysfunctionMain94Disabled: true,
        CardOtPhysicalDysfunctionMain95Disabled: true,
        CardOtPhysicalDysfunctionMain96Disabled: true,
      },
      CardOTPhysicalExaminationPhysicalDysfunctionSensorimotorFunctionViewData: Object.assign(
        {},
        CardOTPhysicalExaminationPhysicalDysfunctionSensorimotorFunctionInitialViewData
      ),
      OTPhysicalExaminationPhysicalDysfunctionHandFunctionRightViewData: Object.assign(
        {},
        OTPhysicalExaminationPhysicalDysfunctionHandFunctionRightInitialViewData
      ),
      OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeftViewData: Object.assign(
        {},
        OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeftInitialViewData
      ),
      CardOTPhysicalExaminationPhysicalDysfunctionActivitiesOfDailyLivingViewData: CardOTPhysicalExaminationPhysicalDysfunctionActivitiesOfDailyLivingInitialViewData,

      // CardOTPhysicalExaminationSwallowingEvaluation1ViewData: Object.assign(
      //   {},
      //   CardOTPhysicalExaminationSwallowingEvaluation1InitialViewData
      // ),
      CardOTPhysicalExaminationSwallowingEvaluation1ViewData: {
        ...CardOTPhysicalExaminationSwallowingEvaluation1InitialViewData,
        Ct3Consciousness: "alert",
        Ct3GeneralAppearance: "normal",
        Ct4Cognitive: "good",
        Ct6Communication: "good",
        Ct6CoOperation: "good",
        // set dropdown degree cause of it depen on dropdown able up right
        CtAbleToUpright: "no",
        CtDegreeDisabled: true,
        // set muscle tone
        CardOtPhysicalSwallowing133Disabled: true,
        CardOtPhysicalSwallowing178: false,
        CardOtPhysicalSwallowing177Disabled: true,
        CardOtPhysicalSwallowing182: false,
        CardOtPhysicalSwallowing179Disabled: true,
        CardOtPhysicalSwallowing185: false,
        CardOtPhysicalSwallowing183Disabled: true,
        CardOtPhysicalSwallowing188: true,
        CardOtPhysicalSwallowing178Disabled: true,
        CardOtPhysicalSwallowing182Disabled: true,
        CardOtPhysicalSwallowing185Disabled: true,
      },
      OTPhysicalExaminationSwallowingEvaluation2ViewData: {
        ...OTPhysicalExaminationSwallowingEvaluation2InitialViewData,
        OtPhysicalSwallowing210Disabled: true,
      },
      CardOTPhysicalExaminationPediatrics1ViewData: {
        ...CardOTPhysicalExaminationPediatrics1InitialViewData,
        CtGeneralAppearance: "normal",
        Ct4Communication: "good",
        Ct4CoOperation: "good",
        CardOtPhysicalPediatric135Disabled: true,
      },
      OTPhysicalExaminationPediatrics2ViewData: OTPhysicalExaminationPediatrics2InitialViewData,
      OTPhysicalExaminationPediatrics3ViewData: OTPhysicalExaminationPediatrics3InitialViewData,
      OTPhysicalExaminationCerebralPalsy1ViewData: {
        ...OTPhysicalExaminationCerebralPalsy1InitialViewData,
        Ct2Cognitive: "good",
        Ct3Communication: "good",
        Ct3CoOperation: "good",
        Ct7GeneralAppearance: "normal",
        OtPhysicalCerebral129Disabled: true,
        // Muscle Length
        OtPhysicalCerebral180: true,
        OtPhysicalCerebral169Disabled: true,
        OtPhysicalCerebral168Disabled: true,
        OtPhysicalCerebral170Disabled: true,
        OtPhysicalCerebral172Disabled: true,
        OtPhysicalCerebral175Disabled: true,
        OtPhysicalCerebral173Disabled: true,
        OtPhysicalCerebral176Disabled: true,
        OtPhysicalCerebral178Disabled: true,
        // Muscle Tone
        OtPhysicalCerebral192: true,
        OtPhysicalCerebral183Disabled: true,
        OtPhysicalCerebral184Disabled: true,
        OtPhysicalCerebral185Disabled: true,
        OtPhysicalCerebral187Disabled: true,
        OtPhysicalCerebral188Disabled: true,
        OtPhysicalCerebral190Disabled: true,
        // Motor power
        OtPhysicalCerebral1102Disabled: true,
        OtPhysicalCerebral1104Disabled: true,
        OtPhysicalCerebral1106Disabled: true,
        OtPhysicalCerebral1109Disabled: true,
      },
      CardOTPhysicalExaminationCerebralPalsyMIDViewData: CardOTPhysicalExaminationCerebralPalsyMIDInitialViewData,
      CardOTPhysicalExaminationCerebralPalsy2ViewData: CardOTPhysicalExaminationCerebralPalsy2InitialViewData,
      OTPhysicalExaminationCerebralPalsy3ViewData: OTPhysicalExaminationCerebralPalsy3InitialViewData,

      // tab Rehab
      PTRehabilitationtreatmentViewData: PTRehabilitationtreatmentInitialViewData,
      OTRehabilitationTreatmentViewData: OTRehabilitationTreatmentInitialViewData,
      PORehabilitationTreatmentOrthoticFittingandCheckoutViewData: PORehabilitationTreatmentOrthoticFittingandCheckoutInitialViewData,
      PORehabilitationTreatmentAnkleFootOrthoticViewData: PORehabilitationTreatmentAnkleFootOrthoticInitialViewData,
      PORehabilitationTreatmentOrthoticFootViewData: PORehabilitationTreatmentOrthoticFootInitialViewData,

      // Subjective
      ORTHOPTOTSubjectiveLOWViewData: {
        ...PTOTSubjectiveLOWInitialViewData,
        PtOtSubjectiveLow18Disabled: true,
        PtOtSubjectiveLow31Disabled: true,
      },
      CARDIOPTOTSubjectiveLOWViewData: {
        ...PTOTSubjectiveLOWInitialViewData,
        PtOtSubjectiveLow18Disabled: true,
        PtOtSubjectiveLow31Disabled: true,
      },
      NEUROPTOTSubjectiveLOWViewData: {
        ...PTOTSubjectiveLOWInitialViewData,
        PtOtSubjectiveLow18Disabled: true,
        PtOtSubjectiveLow31Disabled: true,
      },
      PHYSDYSPTOTSubjectiveLOWViewData: {
        ...PTOTSubjectiveLOWInitialViewData,
        PtOtSubjectiveLow18Disabled: true,
        PtOtSubjectiveLow31Disabled: true,
      },
      PEDIATRICSPTOTSubjectiveLOWViewData: {
        ...PTOTSubjectiveLOWInitialViewData,
        PtOtSubjectiveLow18Disabled: true,
        PtOtSubjectiveLow31Disabled: true,
      },

      // Objective
      PTObjectiveOrthoTOPViewData: {
        ...PTObjectiveOrthoTOPInitialViewData,
        PtObjectiveOrthoTop19Disabled: true,
        PtObjectiveOrthoTop20Disabled: true,
        PtObjectiveOrthoTop23Disabled: true,
        PtObjectiveOrthoTop25Disabled: true,
        PtObjectiveOrthoTop38Disabled: true,
        PtObjectiveOrthoTop40Disabled: true,
        PtObjectiveOrthoTop43Disabled: true,
      },
      CardPTObjectiveOrthoLOWViewData: CardPTObjectiveOrthoLOWInitialViewData,

      PTObjectiveCardiopulmonaryOPDViewData: {
        ...PTObjectiveCardiopulmonaryOPDInitialViewData,
        Ct7Consciousness: "alert",
        Ct10Communication: "good",
        Ct10CoOperation: "good",
        PtObjectiveCardioOpd58Disabled: true,
        PtObjectiveCardioOpd60Disabled: true,
        PtObjectiveCardioOpd64Disabled: true,
        PtObjectiveCardioOpd67Disabled: true,
        PtObjectiveCardioOpd76Disabled: true,
        PtObjectiveCardioOpd78Disabled: true,
        PtObjectiveCardioOpd81Disabled: true,
        PtObjectiveCardioOpd97Disabled: true,
        PtObjectiveCardioOpd98Disabled: true,
        PtObjectiveCardioOpd100Disabled: true,
        PtObjectiveCardioOpd101Disabled: true,
      },
      PTObjectiveNeuroViewData: {
        ...PTObjectiveNeuroInitialViewData,
        PtObjectiveNeuro24Disabled: true,
        PtObjectiveNeuro25Disabled: true,
        PtObjectiveNeuro28Disabled: true,
        PtObjectiveNeuro30Disabled: true,
        PtObjectiveNeuro39Disabled: true,
        PtObjectiveNeuro40Disabled: true,
        PtObjectiveNeuro43Disabled: true,
        PtObjectiveNeuro57Disabled: true,
        PtObjectiveNeuro58Disabled: true,
        PtObjectiveNeuro59Disabled: true,
        PtObjectiveNeuro98Disabled: true,
      },
      CardOTObjectivePediatricsViewData: {
        ...CardOTObjectivePediatricsInitialViewData,
        CardOtObjectivePediatrics36Disabled: true,
      },
      CardOTObjectivePhysicalDysfunction1ViewData: {
        ...CardOTObjectivePhysicalDysfunction1InitialViewData,
        CardOtObjectivePhysicalDysfunction139Disabled: true,
        CardOtObjectivePhysicalDysfunction179Disabled: true,
        CardOtObjectivePhysicalDysfunction182Disabled: true,
        CardOtObjectivePhysicalDysfunction183Disabled: true,
        CardOtObjectivePhysicalDysfunction189Disabled: true,
        CardOtObjectivePhysicalDysfunction196Disabled: true,
        CardOtObjectivePhysicalDysfunction197Disabled: true,
        CardOtObjectivePhysicalDysfunction1100Disabled: true,
      },

      // tab Plan
      CARDIOPTprogressionnoteOPDCardioNeuroViewData: {
        ...PTprogressionnoteOPDCardioNeuroInitialViewData,
        PtProgressnoteOpdCardioNeuro10Disabled: true,
      },
      NEUROPTprogressionnoteOPDCardioNeuroViewData: {
        ...PTprogressionnoteOPDCardioNeuroInitialViewData,
        PtProgressnoteOpdCardioNeuro10Disabled: true,
      },
      PTprogressionnoteOPDOrthoViewData: {
        ...PTprogressionnoteOPDOrthoInitialViewData,
        PtProgressnoteOpdOrtho8Disabled: true,
        PtProgressnoteOpdOrtho10Disabled: true,
        PtProgressnoteOpdOrtho14Disabled: true,
        PtProgressnoteOpdOrtho17Disabled: true,
        PtProgressnoteOpdOrtho20Disabled: true,
        PtProgressnoteOpdOrtho21Disabled: true,
        PtProgressnoteOpdOrtho26Disabled: true,
        PtProgressnoteOpdOrtho29Disabled: true,
        PtProgressnoteOpdOrtho32Disabled: true,
        PtProgressnoteOpdOrtho35Disabled: true,
        PtProgressnoteOpdOrtho38Disabled: true,
        PtProgressnoteOpdOrtho41Disabled: true,
        PtProgressnoteOpdOrtho44Disabled: true,
        PtProgressnoteOpdOrtho47Disabled: true,
        PtProgressnoteOpdOrtho50Disabled: true,
        PtProgressnoteOpdOrtho53Disabled: true,
        PtProgressnoteOpdOrtho55Disabled: true,
      },
      OTProgressionNoteOPDDysfunctionViewData: {
        ...OTProgressionNoteOPDDysfunctionInitialViewData,
        OtProgressnoteOpdDysfunction11Disabled: true,
      },

      // Lab / Imaging
      PTLabImaingExamViewData: Object.assign(
        {},
        PTLabImaingExamInitialViewData
      ),
    };
  }

  componentDidMount() {
    this.getEncounterInfo();
    this.getDiagnosisReadonlyFromAppEncounter();

    console.log("get info from sessionId...", this.props.sessionId);

    const refreshPromise = this.updateData();
    refreshPromise.then(() => {
      this.getClinicalTermSetOptions();
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.TecType !== this.state.TecType) {
      // Compute first option of TechType
      let options = this.getMenuOption();
      if (options && options.length > 0 && this.state.activeMenu === "") {
        let first = options[0].value;
        this.setState({ activeMenu: first });
      }
    }
    if (prevProps.isOpenAssessment !== this.props.isOpenAssessment) {
      this.setState({ activeTab: 1 });
    }
    if (
      prevProps.patientData &&
      prevProps.patientData.ENCOUNTER &&
      this.props.patientData &&
      this.props.patientData.ENCOUNTER &&
      prevProps.patientData.ENCOUNTER.encounter_id !==
        this.props.patientData.ENCOUNTER.encounter_id
    ) {
      this.getEncounterInfo();
      this.updateData();
    }
    if (prevProps.appEncounterId !== this.props.appEncounterId) {
      this.getDiagnosisReadonlyFromAppEncounter();
    }
    if (prevProps.readonlySession !== this.props.readonlySession) {
      this.setState({
        // tab Diagnosis
        CardPTDiagnosisOrthopedicOPDIPDLOWViewData: {
          ...this.state.CardPTDiagnosisOrthopedicOPDIPDLOWViewData,
          SaveRequestDisabled: this.props.readonlySession,
        },
        CardOTDiagnosisPhysicalDysfunctionLOWViewData: {
          ...this.state.CardOTDiagnosisPhysicalDysfunctionLOWViewData,
          SaveRequestDisabled: this.props.readonlySession,
        },
        CardOTDiagnosisSwallowingEvaluationLOWViewData: {
          ...this.state.CardOTDiagnosisSwallowingEvaluationLOWViewData,
          SaveRequestDisabled: this.props.readonlySession,
        },
        CardOTDiagnosisPediatricsLOWViewData: {
          ...this.state.CardOTDiagnosisPediatricsLOWViewData,
          SaveRequestDisabled: this.props.readonlySession,
        },
        CardOTDiagnosisCerebralPalsyLOWViewData: {
          ...this.state.CardOTDiagnosisCerebralPalsyLOWViewData,
          SaveRequestDisabled: this.props.readonlySession,
        },
        // CardPODiagnosisOrthoticfootPremorbidIndependentLOWViewData: {
        //   ...this.state.CardPODiagnosisOrthoticfootPremorbidIndependentLOWViewData,
        //   SaveRequestDisabled: this.props.readonlySession
        // },
        FITANDCHECKCardPODiagnosisOrthoticfootPremorbidIndependentLOWViewData: {
          ...this.state
            .FITANDCHECKCardPODiagnosisOrthoticfootPremorbidIndependentLOWViewData,
          SaveRequestDisabled: this.props.readonlySession,
        },
        ANKLEFOOTCardPODiagnosisOrthoticfootPremorbidIndependentLOWViewData: {
          ...this.state
            .ANKLEFOOTCardPODiagnosisOrthoticfootPremorbidIndependentLOWViewData,
          SaveRequestDisabled: this.props.readonlySession,
        },
        ORTHOTICFOOTCardPODiagnosisOrthoticfootPremorbidIndependentLOWViewData: {
          ...this.state
            .ORTHOTICFOOTCardPODiagnosisOrthoticfootPremorbidIndependentLOWViewData,
          SaveRequestDisabled: this.props.readonlySession,
        },
        // tab Pain Assessment
        ORTHOProgressPainAssessmentViewData: {
          ...this.state.ORTHOProgressPainAssessmentViewData,
          saveButtonDisabled: this.props.readonlySession,
        },
        CARDIOProgressPainAssessmentViewData: {
          ...this.state.CARDIOProgressPainAssessmentViewData,
          saveButtonDisabled: this.props.readonlySession,
        },
        NEUROProgressPainAssessmentViewData: {
          ...this.state.NEUROProgressPainAssessmentViewData,
          saveButtonDisabled: this.props.readonlySession,
        },
        AssessmentPainAssessmentViewData: {
          ...this.state.AssessmentPainAssessmentViewData,
          saveButtonDisabled: this.props.readonlySession,
        },
        AnkleFootAssessmentPainAssessmentViewData: {
          ...this.state.AnkleFootAssessmentPainAssessmentViewData,
          saveButtonDisabled: this.props.readonlySession,
        },
        OrthoticFootAssessmentPainAssessmentViewData: {
          ...this.state.OrthoticFootAssessmentPainAssessmentViewData,
          saveButtonDisabled: this.props.readonlySession,
        },
        // tab Physical Examination
        CardPTPhysicalexaminationOrthopedicOPDLOWViewData: {
          ...this.state.CardPTPhysicalexaminationOrthopedicOPDLOWViewData,
          SaveRequestDisabled: this.props.readonlySession,
        },
        CardPTPhysicalExamCardiopulmonaryMIDViewData: {
          ...this.state.CardPTPhysicalExamCardiopulmonaryMIDViewData,
          saveButtonDisabled: this.props.readonlySession,
        },
        PTPhysicalexaminationNeuroogialOPDLOWViewData: {
          ...this.state.PTPhysicalexaminationNeuroogialOPDLOWViewData,
          SaveRequestDisabled: this.props.readonlySession,
        },
        CardOTPhysicalExaminationPhysicalDysfunctionActivitiesOfDailyLivingViewData: {
          ...this.state
            .CardOTPhysicalExaminationPhysicalDysfunctionActivitiesOfDailyLivingViewData,
          SaveRequestDisabled: this.props.readonlySession,
        },
        OTPhysicalExaminationSwallowingEvaluation2ViewData: {
          ...this.state.OTPhysicalExaminationSwallowingEvaluation2ViewData,
          SaveRequestDisabled: this.props.readonlySession,
        },
        OTPhysicalExaminationPediatrics3ViewData: {
          ...this.state.OTPhysicalExaminationPediatrics3ViewData,
          SaveRequestDisabled: this.props.readonlySession,
        },
        OTPhysicalExaminationCerebralPalsy3ViewData: {
          ...this.state.OTPhysicalExaminationCerebralPalsy3ViewData,
          SaveRequestDisabled: this.props.readonlySession,
        },
        POPhysicalexaminationOrthoticFittingandCheckout2LOWViewData: {
          ...this.state
            .POPhysicalexaminationOrthoticFittingandCheckout2LOWViewData,
          SaveRequestDisabled: this.props.readonlySession,
        },
        POPhysicalexaminationAnkleFootOrthosisLOWViewData: {
          ...this.state.POPhysicalexaminationAnkleFootOrthosisLOWViewData,
          SaveRequestDisabled: this.props.readonlySession,
        },
        POPhysicalexaminationOrthoticFootOrthotic2SIDEViewData: {
          ...this.state.POPhysicalexaminationOrthoticFootOrthotic2SIDEViewData,
          SaveRequestDisabled: this.props.readonlySession,
        },
        // tab Rehabilitation
        PTRehabilitationtreatmentViewData: {
          ...this.state.PTRehabilitationtreatmentViewData,
          SaveRequestDisabled: this.props.readonlySession,
        },
        OTRehabilitationTreatmentViewData: {
          ...this.state.OTRehabilitationTreatmentViewData,
          SaveRequestDisabled: this.props.readonlySession,
        },
        PORehabilitationTreatmentOrthoticFittingandCheckoutViewData: {
          ...this.state
            .PORehabilitationTreatmentOrthoticFittingandCheckoutViewData,
          SaveRequestDisabled: this.props.readonlySession,
        },
        PORehabilitationTreatmentAnkleFootOrthoticViewData: {
          ...this.state.PORehabilitationTreatmentAnkleFootOrthoticViewData,
          SaveRequestDisabled: this.props.readonlySession,
        },
        PORehabilitationTreatmentOrthoticFootViewData: {
          ...this.state.PORehabilitationTreatmentOrthoticFootViewData,
          SaveRequestDisabled: this.props.readonlySession,
        },
        // tab Subjective
        ORTHOPTOTSubjectiveLOWViewData: {
          ...this.state.ORTHOPTOTSubjectiveLOWViewData,
          SaveRequestDisabled: this.props.readonlySession,
        },
        CARDIOPTOTSubjectiveLOWViewData: {
          ...this.state.CARDIOPTOTSubjectiveLOWViewData,
          SaveRequestDisabled: this.props.readonlySession,
        },
        NEUROPTOTSubjectiveLOWViewData: {
          ...this.state.NEUROPTOTSubjectiveLOWViewData,
          SaveRequestDisabled: this.props.readonlySession,
        },
        PHYSDYSPTOTSubjectiveLOWViewData: {
          ...this.state.PHYSDYSPTOTSubjectiveLOWViewData,
          SaveRequestDisabled: this.props.readonlySession,
        },
        PEDIATRICSPTOTSubjectiveLOWViewData: {
          ...this.state.PEDIATRICSPTOTSubjectiveLOWViewData,
          SaveRequestDisabled: this.props.readonlySession,
        },

        // tab Objective
        PTObjectiveCardiopulmonaryOPDViewData: {
          ...this.state.PTObjectiveCardiopulmonaryOPDViewData,
          SaveRequestDisabled: this.props.readonlySession,
        },
        PTObjectiveNeuroViewData: {
          ...this.state.PTObjectiveNeuroViewData,
          SaveRequestDisabled: this.props.readonlySession,
        },
        CardOTObjectivePediatricsViewData: {
          ...this.state.CardOTObjectivePediatricsViewData,
          SaveRequestDisabled: this.props.readonlySession,
        },
        CardOTObjectivePhysicalDysfunction1ViewData: {
          ...this.state.CardOTObjectivePhysicalDysfunction1ViewData,
          SaveRequestDisabled: this.props.readonlySession,
        },
        // tab Plan
        PTprogressionnoteOPDOrthoViewData: {
          ...this.state.PTprogressionnoteOPDOrthoViewData,
          SaveRequestDisabled: this.props.readonlySession,
        },
        CARDIOPTprogressionnoteOPDCardioNeuroViewData: {
          ...this.state.CARDIOPTprogressionnoteOPDCardioNeuroViewData,
          SaveRequestDisabled: this.props.readonlySession,
        },
        NEUROPTprogressionnoteOPDCardioNeuroViewData: {
          ...this.state.NEUROPTprogressionnoteOPDCardioNeuroViewData,
          SaveRequestDisabled: this.props.readonlySession,
        },
        OTProgressionNoteOPDDysfunctionViewData: {
          ...this.state.OTProgressionNoteOPDDysfunctionViewData,
          SaveRequestDisabled: this.props.readonlySession,
        },
      });
    }
  }

  /* Menu, Option */
  setActiveTab = (event, { activeIndex }) => {
    console.log("tab ---------- ", activeIndex);
    this.setState({ activeTab: activeIndex });
  };

  setActiveMenu = (event, { value }) => {
    console.log("menu ---------- ", value);
    this.setState({ activeMenu: value });
  };

  getMenuOption() {
    if (this.props.isOpenAssessment) {
      switch (this.state.TecType) {
        case TECTYPE.PHYSICAL:
          return [
            { key: 0, value: MENU.ORTHO, text: "Orthopedic OPD/IPD" },
            { key: 1, value: MENU.CARDIO, text: "Cardiopulmonary OPD/IPD" },
            { key: 2, value: MENU.NEURO, text: "Neurological OPD/IPD" },
          ];
        case TECTYPE.OCCUPATIONAL:
          return [
            { key: 0, value: MENU.PHYSDYS, text: "Physical Dysfunction" },
            { key: 1, value: MENU.SWALLOW, text: "Swallowing Evaluation" },
            { key: 2, value: MENU.PEDIATRICS, text: "Pediatrics" },
            { key: 3, value: MENU.CEREBRAL, text: "Cerebral Palsy" },
          ];
        case TECTYPE.PROSTHETIC_ORTHOTIC:
          return [
            {
              key: 0,
              value: MENU.FITANDCHECK,
              text: "Orthotic Fitting and Checkout",
            },
            { key: 1, value: MENU.ANKLEFOOT, text: "Ankle Foot Orthosis" },
            { key: 2, value: MENU.ORTHOTICFOOT, text: "Orthotic Foot" },
          ];
        default:
          return [];
      }
    } else {
      switch (this.state.TecType) {
        case TECTYPE.PHYSICAL:
          return [
            { key: 0, value: MENU.ORTHO, text: "Orthopedic OPD/IPD" },
            { key: 1, value: MENU.CARDIO, text: "Cardiopulmonary OPD/IPD" },
            { key: 2, value: MENU.NEURO, text: "Neurological OPD/IPD" },
          ];
        case TECTYPE.OCCUPATIONAL:
          return [
            { key: 0, value: MENU.PEDIATRICS, text: "Pediatrics" },
            { key: 1, value: MENU.PHYSDYS, text: "Physical Dysfunction" },
          ];
        default:
          return [];
      }
    }
  }

  setOption = (
    item: any,
    codeAsId: boolean = false,
    nameAsId: boolean = false
  ) => {
    let options = [{ key: "", value: "", text: "" }];
    if (item.items) {
      item.items.map((option: any) => {
        if (codeAsId) {
          // unknown id
          options.push({
            key: option.code,
            value: option.code,
            text: option.name,
          });
        } else if (nameAsId) {
          // multi selected
          options.push({
            key: option.name,
            value: option.name,
            text: option.name,
          });
        } else {
          options.push({
            key: option.id,
            value: option.id,
            text: option.name,
          });
        }
      });
    }
    return options;
  };

  /* ClinicalTerm */
  getClinicalTermSetOptions = async () => {
    console.log(" getClinicalTermSetOptions Called ");
    let termset = [];
    // assessment
    termset.push(
      "tec_method",
      "tec_pain_score",
      "tec_fall_risk",
      "tec_nursing_care",
      "tec_frequency_pain",
      "tec_duration",
      "tec_characteristic",
      "tec_observation",
      "general_appearance",
      "tec_consciousness",
      "tec_communication",
      "tec_co_operation",
      "tec_bed_rolling",
      "tec_sensory",
      "tec_transfer_depend",
      "tec_gait_ambulation",
      "tec_treatment_ankle",
      "tec_sitting_static",
      "tec_sitting_dynamic",
      "tec_standing_static",
      "tec_standing_dynamic",
      "tec_cough_ref",
      "tec_breathing",
      "tec_chest_move",
      "tec_chest_expansion",
      "tec_percussion_note",
      "tec_adventitious",
      "tec_attention",
      "tec_pathology",
      "tec_cognitive",
      "follow_command",
      "head_and_neck",
      "able_to_upright",
      "degree",
      "denture_use",
      "tec_swallow_feeding",
      "tec_resp",
      "tec_recurrent",
      "tec_copd",
      "tec_underly",
      "tec_jaw_move",
      "tec_lip_closure",
      "tec_cheek",
      "tec_tongue",
      "tec_soft_palete",
      "tec_voice_quality",
      "tec_voluntary",
      "tec_saliva",
      "tec_gag_ref",
      "tec_swallowing_ref",
      "tec_bite_ref",
      "tec_fois_level",
      "tec_scr_st_1st",
      "tec_scr_nd_1st",
      "tec_scr_nd_2nd",
      "tec_scr_rd_1st",
      "tec_scr_rd_2nd",
      "tec_scr_th_1st",
      "tec_scr_th_2nd",
      "tec_genapper_dysfunc",
      "tec_ankle_stability",
      "tec_knee_stability",
      "tec_hip",
      "tec_deformity",
      "tec_level_of_arousal",
      "personal_and_social",
      "fine_motor",
      "gross_motor",
      "language",
      "vestibular",
      "proprioceptive",
      "tactile",
      "visual",
      "auditory",
      "tec_premorbid",
      "tec_cough",
      "tec_constractures",
      "tec_grooming_oral",
      "tec_feeding_eating",
      "tec_dressing_upper",
      "tec_dressing_lower",
      "tec_transfer_bed",
      "tec_transfer_toilet",
      "tec_bed_mobility",
      "tec_bath_shower",
      "tec_toileting",
      "tec_physi_dys_other",
      "head_control_supine",
      "head_control_prone",
      "pull_to_sit",
      "supine_to_prone",
      "prone_to_supine",
      "side_lying_to_sit",
      "creeping_crawling",
      "pull_to_stand",
      "side_walking",
      "forward_walking",
      "backward_walking",
      "tec_running",
      "tec_jumping",
      "tec_hopping",
      "tec_grasp_reflex",
      "tec_sitting",
      "tec_standing",
      "tec_open_hand",
      "tec_reach_out",
      "tec_hand_grasp",
      "whole_hand_grasp",
      "pinch_grasp",
      "laterality",
      "bilateral_hand_use",
      "tec_dependent",
      "walk_with_walking",
      "rolling",
      "wheelchair",
      "walking",
      "transfer_in_sitting",
      "oro_motor",
      "tec_calcaneus",
      "tec_forefoot",
      "tec_arch_deformity",
      "tec_foot_position",
      "light_touch",
      "reach",
      "grasp",
      "hold_carry",
      "release",
      "Grasp_large",
      "hook_grasp",
      "spherical",
      "tripod_pinch",
      "lateral_pinch",
      "pad_pad_pinch",
      "tip_pinch"
    );
    // progression
    termset.push("tec_dis_today");

    const [result, error] = await this.coreManager.getClinicalTermSet({
      questionList: termset,
    });
    if (error) {
      console.log("ClinicalTermSet Error >> ", error);
    } else {
      console.log(result);
      // assessment
      this.setTabDiagnosisClinicalTerm(result);
      this.setTabPainAssessmentClinicalTerm(result);
      this.setTabPhysicalExamClinicalTerm(result);
      this.setTabRehabilitationTreatmentClinicalTerm(result);
      // progression
      // this.setTabSubjectClinicalTerm(result); // No Clinical Term
      this.setTabObjectiveClinialTerm(result);
      // this.setTabAssessmentClinicalTerm(result); // TODO: Question?
      this.setTabPlanClinicalTerm(result);
    }
  };

  setTabDiagnosisClinicalTerm = (clinicalterm) => {
    this.setState({
      CardPTDiagnosisOrthopedicOPDIPDLOWViewData: {
        ...this.state.CardPTDiagnosisOrthopedicOPDIPDLOWViewData,
        Ct6PremorbidOptions: this.setOption(
          clinicalterm.tec_premorbid,
          true,
          false
        ),
      },

      CardOTDiagnosisPhysicalDysfunctionLOWViewData: {
        ...this.state.CardOTDiagnosisPhysicalDysfunctionLOWViewData,
        Ct3PremorbidOptions: this.setOption(
          clinicalterm.tec_premorbid,
          true,
          false
        ),
      },

      CardOTDiagnosisSwallowingEvaluationLOWViewData: {
        ...this.state.CardOTDiagnosisSwallowingEvaluationLOWViewData,
        Ct4PremorbidOptions: this.setOption(
          clinicalterm.tec_premorbid,
          true,
          false
        ),
      },

      CardOTDiagnosisPediatricsLOWViewData: {
        ...this.state.CardOTDiagnosisPediatricsLOWViewData,
        Ct2PremorbidOptions: this.setOption(
          clinicalterm.tec_premorbid,
          true,
          false
        ),
      },

      CardOTDiagnosisCerebralPalsyLOWViewData: {
        ...this.state.CardOTDiagnosisCerebralPalsyLOWViewData,
        CtPremorbidOptions: this.setOption(
          clinicalterm.tec_premorbid,
          true,
          false
        ),
      },

      // CardPODiagnosisOrthoticfootPremorbidIndependentLOWViewData: {
      //   ...this.state.CardPODiagnosisOrthoticfootPremorbidIndependentLOWViewData,
      //   SaveRequestDisabled: this.props.readonlySession,
      //   Ct5PremorbidOptions: this.setOption(
      //     clinicalterm.tec_premorbid,
      //     true,
      //     false
      //   )
      // },
      FITANDCHECKCardPODiagnosisOrthoticfootPremorbidIndependentLOWViewData: {
        ...this.state
          .FITANDCHECKCardPODiagnosisOrthoticfootPremorbidIndependentLOWViewData,
        SaveRequestDisabled: this.props.readonlySession,
        Ct5PremorbidOptions: this.setOption(
          clinicalterm.tec_premorbid,
          true,
          false
        ),
      },

      ANKLEFOOTCardPODiagnosisOrthoticfootPremorbidIndependentLOWViewData: {
        ...this.state
          .ANKLEFOOTCardPODiagnosisOrthoticfootPremorbidIndependentLOWViewData,
        SaveRequestDisabled: this.props.readonlySession,
        Ct5PremorbidOptions: this.setOption(
          clinicalterm.tec_premorbid,
          true,
          false
        ),
      },

      ORTHOTICFOOTCardPODiagnosisOrthoticfootPremorbidIndependentLOWViewData: {
        ...this.state
          .ORTHOTICFOOTCardPODiagnosisOrthoticfootPremorbidIndependentLOWViewData,
        SaveRequestDisabled: this.props.readonlySession,
        Ct5PremorbidOptions: this.setOption(
          clinicalterm.tec_premorbid,
          true,
          false
        ),
      },
    });
  };

  setTabObjectiveClinialTerm = (clinicalterm) => {
    this.setState({
      PTObjectiveOrthoTOPViewData: {
        ...this.state.PTObjectiveOrthoTOPViewData,
        Ct9ConsciousnessOptions: this.setOption(
          clinicalterm.tec_consciousness,
          true,
          false
        ),
        Ct12CommunicationOptions: this.setOption(
          clinicalterm.tec_communication,
          true,
          false
        ),
        Ct12CoOperationOptions: this.setOption(
          clinicalterm.tec_co_operation,
          true,
          false
        ),
        Ct4SensoryOptions: this.setOption(
          clinicalterm.tec_sensory,
          true,
          false
        ),
      },

      PTObjectiveCardiopulmonaryOPDViewData: {
        ...this.state.PTObjectiveCardiopulmonaryOPDViewData,
        CtObservationOptions: this.setOption(
          clinicalterm.tec_observation,
          true,
          false
        ),
        Ct7ConsciousnessOptions: this.setOption(
          clinicalterm.tec_consciousness,
          true,
          false
        ),
        Ct10CommunicationOptions: this.setOption(
          clinicalterm.tec_communication,
          true,
          false
        ),
        Ct10CoOperationOptions: this.setOption(
          clinicalterm.tec_co_operation,
          true,
          false
        ),
        CtCoughOptions: this.setOption(clinicalterm.tec_cough, true, false),
        CtBreathingPatternOptions: this.setOption(
          clinicalterm.tec_breathing,
          true,
          false
        ),
        CtChestMovementOptions: this.setOption(
          clinicalterm.tec_chest_move,
          true,
          false
        ),
        CtChestExpansionOptions: this.setOption(
          clinicalterm.tec_chest_expansion,
          true,
          false
        ),
        CtPercussionNoteOptions: this.setOption(
          clinicalterm.tec_percussion_note,
          true,
          false
        ),
        CtAdventitiousSoundOptions: this.setOption(
          clinicalterm.tec_adventitious,
          true,
          false
        ),
      },

      PTObjectiveNeuroViewData: {
        ...this.state.PTObjectiveNeuroViewData,
        Ct2ObservationOptions: this.setOption(
          clinicalterm.tec_observation,
          true,
          false
        ),
        Ct8ConsciousnessOptions: this.setOption(
          clinicalterm.tec_consciousness,
          true,
          false
        ),
        Ct11CommunicationOptions: this.setOption(
          clinicalterm.tec_communication,
          true,
          false
        ),
        Ct11CoOperationOptions: this.setOption(
          clinicalterm.tec_co_operation,
          true,
          false
        ),
        Ct3SittingStaticOptions: this.setOption(
          clinicalterm.tec_sitting_static,
          true,
          false
        ),
        Ct3SittingDynamicOptions: this.setOption(
          clinicalterm.tec_sitting_dynamic,
          true,
          false
        ),
        Ct2StandingStaticOptions: this.setOption(
          clinicalterm.tec_standing_static,
          true,
          false
        ),
        Ct2StandingDynamicOptions: this.setOption(
          clinicalterm.tec_standing_dynamic,
          true,
          false
        ),
        CtTransferDependentOptions: this.setOption(
          clinicalterm.tec_transfer_depend,
          true,
          false
        ),
        CtGaitPatternAmbulationOptions: this.setOption(
          clinicalterm.tec_gait_ambulation,
          true,
          false
        ),
      },

      CardOTObjectivePediatricsViewData: {
        ...this.state.CardOTObjectivePediatricsViewData,
        CtCommunicationOptions: this.setOption(
          clinicalterm.tec_communication,
          true,
          false
        ),
        CtCoOperationOptions: this.setOption(
          clinicalterm.tec_co_operation,
          true,
          false
        ),
        CtLevelOfArousalOptions: this.setOption(
          clinicalterm.tec_level_of_arousal,
          true,
          false
        ),
      },

      CardOTObjectivePhysicalDysfunction1ViewData: {
        ...this.state.CardOTObjectivePhysicalDysfunction1ViewData,
        CtConsciousnessOptions: this.setOption(
          clinicalterm.tec_consciousness,
          true,
          false
        ),
        Ct2CommunicationOptions: this.setOption(
          clinicalterm.tec_communication,
          true,
          false
        ),
        Ct2CoOperationOptions: this.setOption(
          clinicalterm.tec_co_operation,
          true,
          false
        ),
        CtCognitiveOptions: this.setOption(
          clinicalterm.tec_cognitive,
          true,
          false
        ),
        CtSensoryOptions: this.setOption(clinicalterm.tec_sensory, true, false),
      },
    });
  };

  setTabPainAssessmentClinicalTerm = (clinicalterm) => {
    this.setState({
      PainAssessmentDropdown: {
        painAssessmentMethodOptions: this.setOption(
          clinicalterm.tec_method,
          true,
          false
        ),
        painAssessmentPainScoreOptions: this.setOption(
          clinicalterm.tec_pain_score
        ),
        painAssessmentFallRiskOptions: this.setOption(
          clinicalterm.tec_fall_risk,
          true,
          false
        ),
        painAssessmentFallRiskDetailOptions: this.setOption(
          clinicalterm.tec_nursing_care,
          false,
          true
        ),
        painAssessmentFrequencyOfPainOptions: this.setOption(
          clinicalterm.tec_frequency_pain,
          true,
          false
        ),
        painAssessmentDuratonOptions: this.setOption(clinicalterm.tec_duration),
        painAssessmentCharacteristicOptions: this.setOption(
          clinicalterm.tec_characteristic
        ),
      },
    });
  };

  setTabPhysicalExamClinicalTerm = (clinicalterm) => {
    this.setState({
      // PHYSICAL, ORTHO, TOP
      CardPTPhysicalexaminationOrthopedicOPDTOPViewData: {
        ...this.state.CardPTPhysicalexaminationOrthopedicOPDTOPViewData,
        Ct5ObservationOptions: this.setOption(clinicalterm.tec_observation),

        Ct10GeneralAppearanceOptions: this.setOption(
          clinicalterm.general_appearance,
          true,
          false
        ),

        Ct12ConsciousnessOptions: this.setOption(
          clinicalterm.tec_consciousness,
          true,
          false
        ),
        Ct15CommunicationOptions: this.setOption(
          clinicalterm.tec_communication,
          true,
          false
        ),

        Ct15CoOperationOptions: this.setOption(
          clinicalterm.tec_co_operation,
          true,
          false
        ),
        Ct3BedMobilityTestRollingOptions: this.setOption(
          clinicalterm.tec_bed_rolling
        ),
        PtPhysicalOrthoOpdTop29Options: this.setOption(
          clinicalterm.tec_transfer_depend
        ),
        PtPhysicalOrthoOpdTop35Options: this.setOption(
          clinicalterm.tec_bed_rolling
        ),
        PtPhysicalOrthoOpdTop38Options: this.setOption(
          clinicalterm.tec_transfer_depend
        ),
        PtPhysicalOrthoOpdTop41Options: this.setOption(
          clinicalterm.tec_bed_rolling
        ),
        PtPhysicalOrthoOpdTop44Options: this.setOption(
          clinicalterm.tec_transfer_depend
        ),
      },
      // PHYSICAL, ORTHO, LOW
      CardPTPhysicalexaminationOrthopedicOPDLOWViewData: {
        ...this.state.CardPTPhysicalexaminationOrthopedicOPDLOWViewData,
        Ct6SensoryOptions: this.setOption(clinicalterm.tec_sensory),
        Ct3TransferDependentOptions: this.setOption(
          clinicalterm.tec_transfer_depend
        ),
        Ct3GaitPatternAmbulationOptions: this.setOption(
          clinicalterm.tec_gait_ambulation
        ),
      },
      // PHYSICAL, CARDIO, TOP
      CardPTPhysicalexaminationCardiopulmonaryTOPViewData: {
        ...this.state.CardPTPhysicalexaminationCardiopulmonaryTOPViewData,
        Ct3ObservationOptions: this.setOption(clinicalterm.tec_observation),
        Ct8GeneralAppearanceOptions: this.setOption(
          clinicalterm.general_appearance,
          true,
          false
        ),
        Ct10ConsciousnessOptions: this.setOption(
          clinicalterm.tec_consciousness,
          true,
          false
        ),
        Ct13CommunicationOptions: this.setOption(
          clinicalterm.tec_communication,
          true,
          false
        ),
        Ct13CoOperationOptions: this.setOption(
          clinicalterm.tec_co_operation,
          true,
          false
        ),
        Ct2CoughOptions: this.setOption(clinicalterm.tec_cough_ref),
        Ct2BreathingPatternOptions: this.setOption(clinicalterm.tec_breathing),
        Ct2ChestMovementOptions: this.setOption(clinicalterm.tec_chest_move),
        Ct2ChestExpansionOptions: this.setOption(
          clinicalterm.tec_chest_expansion
        ),
        Ct2PercussionNoteOptions: this.setOption(
          clinicalterm.tec_percussion_note
        ),
        Ct2AdventitionsSoundOptions: this.setOption(
          clinicalterm.tec_adventitious
        ),
        CtBedMobilityTestRollingOptions: this.setOption(
          clinicalterm.tec_bed_rolling
        ),
        CtRollingTransferOptions: this.setOption(
          clinicalterm.tec_transfer_depend
        ),
        CtBedMobilityTestSupineOptions: this.setOption(
          clinicalterm.tec_bed_rolling
        ),
        CtSupineToSitTransferOptions: this.setOption(
          clinicalterm.tec_transfer_depend
        ),
        CtBedMobilityTestSitOptions: this.setOption(
          clinicalterm.tec_bed_rolling
        ),
        CtSitToStandTransferOptions: this.setOption(
          clinicalterm.tec_transfer_depend
        ),
      },
      // PHYSICAL, CARDIO, MID
      PhysicalExamCardioMIDDropdown: {
        sensoryOptions: this.setOption(clinicalterm.tec_sensory),
      },
      // PHYSICAL, NEURO, TOP
      CardPTPhysicalexaminationNeuroogialOPDTOPViewData: {
        ...this.state.CardPTPhysicalexaminationNeuroogialOPDTOPViewData,
        Ct4ObservationOptions: this.setOption(clinicalterm.tec_observation),
        Ct9GeneralAppearanceOptions: this.setOption(
          clinicalterm.general_appearance,
          true,
          false
        ),
        Ct11ConsciousnessOptions: this.setOption(
          clinicalterm.tec_consciousness,
          true,
          false
        ),
        Ct14CommunicationOptions: this.setOption(
          clinicalterm.tec_communication,
          true,
          false
        ),

        Ct14CoOperationOptions: this.setOption(
          clinicalterm.tec_co_operation,
          true,
          false
        ),
        Ct2BedMobilityTestRollingOptions: this.setOption(
          clinicalterm.tec_bed_rolling
        ),
        PtPhysicalNeuroOpdTop28Options: this.setOption(
          clinicalterm.tec_transfer_depend
        ),
        PtPhysicalNeuroOpdTop36Options: this.setOption(
          clinicalterm.tec_bed_rolling
        ),
        PtPhysicalNeuroOpdTop34Options: this.setOption(
          clinicalterm.tec_transfer_depend
        ),
        PtPhysicalNeuroOpdTop42Options: this.setOption(
          clinicalterm.tec_bed_rolling
        ),
        PtPhysicalNeuroOpdTop40Options: this.setOption(
          clinicalterm.tec_transfer_depend
        ),
      },
      // PHYSICAL, NEURO, LOW
      PTPhysicalexaminationNeuroogialOPDLOWViewData: {
        ...this.state.PTPhysicalexaminationNeuroogialOPDLOWViewData,
        Ct5SensonryOptions: this.setOption(clinicalterm.tec_sensory),
        Ct4SittingStaticOptions: this.setOption(
          clinicalterm.tec_sitting_static
        ),
        Ct4SittingDynamicOptions: this.setOption(
          clinicalterm.tec_sitting_dynamic
        ),
        Ct3StandingStaticOptions: this.setOption(
          clinicalterm.tec_standing_static
        ),
        Ct3StandingDynamicOptions: this.setOption(
          clinicalterm.tec_standing_dynamic
        ),
        Ct2TransferDependentOptions: this.setOption(
          clinicalterm.tec_transfer_depend
        ),
        Ct2GaitPatternAmbulationOptions: this.setOption(
          clinicalterm.tec_gait_ambulation
        ),
      },
      // PROSTHETIC_ORTHOTIC, FITANDCHECK, TOP
      POPhysicalexaminationOrthoticFittingandCheckout2TOPViewData: {
        ...this.state
          .POPhysicalexaminationOrthoticFittingandCheckout2TOPViewData,
        Ct2GeneralAppearanceDysfunctionOptions: this.setOption(
          clinicalterm.tec_genapper_dysfunc
        ),
        Ct5GeneralAppearanceOptions: this.setOption(
          clinicalterm.general_appearance,
          true,
          false
        ),
        Ct5ConsciousnessOptions: this.setOption(
          clinicalterm.tec_consciousness,
          true,
          false
        ),
        Ct8CommunicationOptions: this.setOption(
          clinicalterm.tec_communication,
          true,
          false
        ),
        Ct8CoOperationOptions: this.setOption(
          clinicalterm.tec_co_operation,
          true,
          false
        ),
      },
      // PROSTHETIC_ORTHOTIC, FITANDCHECK, MID
      PhysicalExamOrthoticFittingAndCheckoutDropdown: {
        sensoryOptions: this.setOption(clinicalterm.tec_sensory),
      },
      // PROSTHETIC_ORTHOTIC, FITANDCHECK, LOW
      POPhysicalexaminationOrthoticFittingandCheckout2LOWViewData: {
        ...this.state
          .POPhysicalexaminationOrthoticFittingandCheckout2LOWViewData,
        SaveRequestDisabled: this.props.readonlySession,
      },
      // PROSTHETIC_ORTHOTIC, Ankle Foot, TOP
      POPhysicalexaminationAnkleFootOrthosisTOPViewData: {
        ...this.state.POPhysicalexaminationAnkleFootOrthosisTOPViewData,
        CtGeneralApperanceDysfunctionOptions: this.setOption(
          clinicalterm.tec_genapper_dysfunc
        ),
        Ct4GeneralApperanceOptions: this.setOption(
          clinicalterm.general_appearance,
          true,
          false
        ),
        Ct4ConsciousnessOptions: this.setOption(
          clinicalterm.tec_consciousness,
          true,
          false
        ),
        Ct7CommunicationOptions: this.setOption(
          clinicalterm.tec_communication,
          true,
          false
        ),
        Ct7CoOperationOptions: this.setOption(
          clinicalterm.tec_co_operation,
          true,
          false
        ),
      },
      // PROSTHETIC_ORTHOTIC, Ankle Foot, MID
      PhysicalExamAnkleFootOrthosisMIDDropdown: {
        sensoryOptions: this.setOption(clinicalterm.tec_sensory),
      },
      // PROSTHETIC_ORTHOTIC, Ankle Foot, LOW
      POPhysicalexaminationAnkleFootOrthosisLOWViewData: {
        ...this.state.POPhysicalexaminationAnkleFootOrthosisLOWViewData,
        SaveRequestDisabled: this.props.readonlySession,
        PoPhysicalAnkleFootOrthosisLow2Options: this.setOption(
          clinicalterm.tec_ankle_stability
        ),
        PoPhysicalAnkleFootOrthosisLow7Options: this.setOption(
          clinicalterm.tec_knee_stability
        ),
        PoPhysicalAnkleFootOrthosisLow12Options: this.setOption(
          clinicalterm.tec_hip
        ),
        PoPhysicalAnkleFootOrthosisLow17Options: this.setOption(
          clinicalterm.tec_deformity
        ),
      },

      // PROSTHETIC_ORTHOTIC, Orthotic Foot, TOP
      POPhysicalexaminationOrthoticFootOrthotic2TOPViewData: {
        ...this.state.POPhysicalexaminationOrthoticFootOrthotic2TOPViewData,
        Ct3GeneralAppearanceDysfunctionOptions: this.setOption(
          clinicalterm.tec_genapper_dysfunc
        ),
        Ct6GeneralAppearanceOptions: this.setOption(
          clinicalterm.general_appearance,
          true,
          false
        ),
        Ct6ConsciousnessOptions: this.setOption(
          clinicalterm.tec_consciousness,
          true,
          false
        ),
        Ct9CommunicationOptions: this.setOption(
          clinicalterm.tec_communication,
          true,
          false
        ),
        Ct9CoOperationOptions: this.setOption(
          clinicalterm.tec_co_operation,
          true,
          false
        ),
      },
      // PROSTHETIC_ORTHOTIC, Orthotic Foot, MID
      PhysicalExamOrthoticFootOrthoticMIDDropdown: {
        sensoryOptions: this.setOption(clinicalterm.tec_sensory),
      },
      // PROSTHETIC_ORTHOTIC, Orthotic Foot, LOW
      POPhysicalexaminationOrthoticFootOrthotic2LOWViewData: {
        ...this.state.POPhysicalexaminationOrthoticFootOrthotic2LOWViewData,
        CtCalcaneusPositionOptions: this.setOption(clinicalterm.tec_calcaneus),
        CtForefootPositionOptions: this.setOption(clinicalterm.tec_forefoot),
        CtArchDeformityOptions: this.setOption(clinicalterm.tec_arch_deformity),
        PoPhysicalOrthoticFootOrthotic2Low33Options: this.setOption(
          clinicalterm.head_control_supine
        ),
        PoPhysicalOrthoticFootOrthotic2Low37Options: this.setOption(
          clinicalterm.head_control_supine
        ),
      },

      // OCCUPATION, PhysicalDysfunction, Main(TOP)
      CardOTPhysicalExaminationPhysicalDysfunctionMainViewData: {
        ...this.state.CardOTPhysicalExaminationPhysicalDysfunctionMainViewData,
        CtSittingDynamicOptions: this.setOption(
          clinicalterm.tec_sitting_dynamic,
          true,
          false
        ),
        CtSittingStaticOptions: this.setOption(
          clinicalterm.tec_sitting_static,
          true,
          false
        ),
        CtCardOtPhysicalDysfunctionMain115Options: this.setOption(
          clinicalterm.tec_standing_dynamic,
          true,
          false
        ),
        CtCardOtPhysicalDysfunctionMain112Options: this.setOption(
          clinicalterm.tec_standing_static,
          true,
          false
        ),
        Ct2ConsciousnessOptions: this.setOption(
          clinicalterm.tec_consciousness,
          true,
          false
        ),
        Ct2GeneralAppearanceOptions: this.setOption(
          clinicalterm.general_appearance,
          true,
          false
        ),
        Ct3CognitiveOptions: this.setOption(
          clinicalterm.tec_cognitive,
          true,
          false
        ),
        Ct5CommunicationOptions: this.setOption(
          clinicalterm.tec_communication,
          true,
          false
        ),
        Ct5CoOperationOptions: this.setOption(
          clinicalterm.tec_co_operation,
          true,
          false
        ),
      },

      // OCCUPATION, PhysicalDysfunction, SensoriFunction
      CardOTPhysicalExaminationPhysicalDysfunctionSensorimotorFunctionViewData: {
        ...this.state
          .CardOTPhysicalExaminationPhysicalDysfunctionSensorimotorFunctionViewData,
        lightTouchRightOptions: this.setOption(
          clinicalterm.light_touch,
          true,
          false
        ),
        lightTouchLeftOptions: this.setOption(
          clinicalterm.light_touch,
          true,
          false
        ),
        proprioceptiveRightOptions: this.setOption(
          clinicalterm.proprioceptive,
          true,
          false
        ),
        proprioceptiveLeftOptions: this.setOption(
          clinicalterm.proprioceptive,
          true,
          false
        ),
      },

      OTPhysicalExaminationPhysicalDysfunctionHandFunctionRightViewData: {
        ...this.state
          .OTPhysicalExaminationPhysicalDysfunctionHandFunctionRightViewData,
        CtReachOptions: this.setOption(clinicalterm.reach, true, false),
        CtGraspOptions: this.setOption(clinicalterm.grasp, true, false),
        CtHoldCarryOptions: this.setOption(
          clinicalterm.hold_carry,
          true,
          false
        ),
        CtReleaseOptions: this.setOption(clinicalterm.release, true, false),
        CtGraspLargeOptions: this.setOption(
          clinicalterm.Grasp_large,
          true,
          false
        ),
        CtHookGraspOptions: this.setOption(
          clinicalterm.hook_grasp,
          true,
          false
        ),
        CtCylindricalOptions: this.setOption(clinicalterm.release, true, false),
        CtSphericalOptions: this.setOption(clinicalterm.spherical, true, false),
        CtTripodPinchOptions: this.setOption(
          clinicalterm.tripod_pinch,
          true,
          false
        ),
        CtLateralPinchOptions: this.setOption(
          clinicalterm.lateral_pinch,
          true,
          false
        ),
        CtPadToPadOptions: this.setOption(
          clinicalterm.pad_pad_pinch,
          true,
          false
        ),
        CtTipPinchOptions: this.setOption(clinicalterm.tip_pinch, true, false),
      },

      OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeftViewData: {
        ...this.state
          .OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeftViewData,
        CtReachOptions: this.setOption(clinicalterm.reach, true, false),
        CtGraspOptions: this.setOption(clinicalterm.grasp, true, false),
        CtHoldCarryOptions: this.setOption(
          clinicalterm.hold_carry,
          true,
          false
        ),
        CtReleaseOptions: this.setOption(clinicalterm.release, true, false),
        CtGraspLargeOptions: this.setOption(
          clinicalterm.Grasp_large,
          true,
          false
        ),
        CtHookGraspOptions: this.setOption(
          clinicalterm.hook_grasp,
          true,
          false
        ),
        CtCylindricalOptions: this.setOption(clinicalterm.release, true, false),
        CtSphericalOptions: this.setOption(clinicalterm.spherical, true, false),
        CtTripodPinchOptions: this.setOption(
          clinicalterm.tripod_pinch,
          true,
          false
        ),
        CtLateralPinchOptions: this.setOption(
          clinicalterm.lateral_pinch,
          true,
          false
        ),
        CtPadToPadOptions: this.setOption(
          clinicalterm.pad_pad_pinch,
          true,
          false
        ),
        CtTipPinchOptions: this.setOption(clinicalterm.tip_pinch, true, false),
      },

      // OCCUPATIONAL, Physical Dysfunction, Activity of Dailyliving
      CardOTPhysicalExaminationPhysicalDysfunctionActivitiesOfDailyLivingViewData: {
        ...this.state
          .CardOTPhysicalExaminationPhysicalDysfunctionActivitiesOfDailyLivingViewData,
        Ct2Grooming_oralOptions: this.setOption(
          clinicalterm.tec_grooming_oral,
          true,
          false
        ),
        Ct2FeedingEatingOptions: this.setOption(
          clinicalterm.tec_feeding_eating,
          true,
          false
        ),
        Ct2DressingUpperOptions: this.setOption(
          clinicalterm.tec_dressing_upper,
          true,
          false
        ),
        Ct2DressingLowerOptions: this.setOption(
          clinicalterm.tec_dressing_lower,
          true,
          false
        ),
        Ct2TransferBedOptions: this.setOption(
          clinicalterm.tec_transfer_bed,
          true,
          false
        ),
        Ct2TransferToiletOptions: this.setOption(
          clinicalterm.tec_transfer_toilet,
          true,
          false
        ),
        Ct2BedMobilityOptions: this.setOption(
          clinicalterm.tec_bed_mobility,
          true,
          false
        ),
        Ct2BathingOptions: this.setOption(
          clinicalterm.tec_bath_shower,
          true,
          false
        ),
        Ct2ToiletingOptions: this.setOption(
          clinicalterm.tec_toileting,
          true,
          false
        ),
        Ct2OtherOptions: this.setOption(
          clinicalterm.tec_physi_dys_other,
          true,
          false
        ),
      },

      // PROSTHETIC_ORTHOTIC, Orthotic Foot, SIDE
      cardMultiSelectPositionDropdown: {
        positionOptions: this.setOption(
          clinicalterm.tec_foot_position,
          false,
          true
        ),
      },

      // OCCUPATIONAL, Swallowing
      CardOTPhysicalExaminationSwallowingEvaluation1ViewData: {
        ...this.state.CardOTPhysicalExaminationSwallowingEvaluation1ViewData,
        CtAbleToUprightOptions: this.setOption(
          clinicalterm.able_to_upright,
          true,
          false
        ),
        CtBiteReflexOptions: this.setOption(
          clinicalterm.tec_bite_ref,
          true,
          false
        ),
        CtCheekControlOptions: this.setOption(
          clinicalterm.tec_cheek,
          true,
          false
        ),
        CtCopOptions: this.setOption(clinicalterm.tec_copd, true, false),
        CtCoughReflexOptions: this.setOption(
          clinicalterm.tec_cough_ref,
          true,
          false
        ),
        CtDegreeOptions: this.setOption(clinicalterm.degree),
        CtDentureUseOptions: this.setOption(clinicalterm.denture_use),
        CtFollowCommandOptions: this.setOption(
          clinicalterm.follow_command,
          true,
          false
        ),
        CtGagReflexOptions: this.setOption(
          clinicalterm.tec_gag_ref,
          true,
          false
        ),
        CtHeadAndNeckOptions: this.setOption(
          clinicalterm.head_and_neck,
          true,
          false
        ),
        CtJawMovementOptions: this.setOption(
          clinicalterm.tec_jaw_move,
          true,
          false
        ),
        CtLipClosureOptions: this.setOption(
          clinicalterm.tec_lip_closure,
          true,
          false
        ),
        CtRecurrentStrokeOptions: this.setOption(
          clinicalterm.tec_recurrent,
          true,
          false
        ),
        CtResprirationOptions: this.setOption(
          clinicalterm.tec_resp,
          true,
          false
        ),
        CtSalivaControlOptions: this.setOption(
          clinicalterm.tec_saliva,
          true,
          false
        ),
        CtSoftPaleteOptions: this.setOption(
          clinicalterm.tec_soft_palete,
          true,
          false
        ),
        CtSwallowFeedingOptions: this.setOption(
          clinicalterm.tec_swallow_feeding,
          true,
          false
        ),
        CtSwallowingReflexOptions: this.setOption(
          clinicalterm.tec_swallowing_ref,
          true,
          false
        ),
        CtTongueMovementOptions: this.setOption(
          clinicalterm.tec_tongue,
          true,
          false
        ),
        CtUnderlyingOtherOptions: this.setOption(
          clinicalterm.tec_underly,
          true,
          false
        ),
        CtVoiceQualityOptions: this.setOption(
          clinicalterm.tec_voice_quality,
          true,
          false
        ),
        CtVoluntaryCoughOptions: this.setOption(
          clinicalterm.tec_voluntary,
          true,
          false
        ),
        Ct2PathologyOptions: this.setOption(
          clinicalterm.tec_pathology,
          true,
          false
        ),
        Ct2SittingStaticOptions: this.setOption(
          clinicalterm.tec_sitting_static,
          true,
          false
        ),
        Ct2SittingDynamicOptions: this.setOption(
          clinicalterm.tec_sitting_dynamic,
          true,
          false
        ),
        Ct3AttentionOptions: this.setOption(
          clinicalterm.tec_attention,
          true,
          false
        ),
        Ct3ConsciousnessOptions: this.setOption(
          clinicalterm.tec_consciousness,
          true,
          false
        ),
        Ct3GeneralAppearanceOptions: this.setOption(
          clinicalterm.general_appearance,
          true,
          false
        ),
        Ct3SensoryOptions: this.setOption(
          clinicalterm.tec_sensory,
          true,
          false
        ),
        Ct4CognitiveOptions: this.setOption(
          clinicalterm.tec_cognitive,
          true,
          false
        ),
        Ct6CommunicationOptions: this.setOption(
          clinicalterm.tec_communication,
          true,
          false
        ),
        Ct6CoOperationOptions: this.setOption(
          clinicalterm.tec_co_operation,
          true,
          false
        ),
      },
      OTPhysicalExaminationSwallowingEvaluation2ViewData: {
        ...this.state.OTPhysicalExaminationSwallowingEvaluation2ViewData,
        CtFoisLevelOptions: this.setOption(
          clinicalterm.tec_fois_level,
          true,
          false
        ),
        CtScrFirstStepOptions: this.setOption(
          clinicalterm.tec_scr_st_1st,
          true,
          false
        ),
        CtScrSecond1stOptions: this.setOption(
          clinicalterm.tec_scr_nd_1st,
          true,
          false
        ),
        CtScrSecond2ndOptions: this.setOption(
          clinicalterm.tec_scr_nd_2nd,
          true,
          false
        ),
        CtScrThird1stOptions: this.setOption(
          clinicalterm.tec_scr_rd_1st,
          true,
          false
        ),
        CtScrThird2ndOptions: this.setOption(
          clinicalterm.tec_scr_rd_2nd,
          true,
          false
        ),
        CtScrFourth1stOptions: this.setOption(
          clinicalterm.tec_scr_th_1st,
          true,
          false
        ),
        CtScrFourth2ndOptions: this.setOption(
          clinicalterm.tec_scr_th_2nd,
          true,
          false
        ),
      },
      // OCCUPATION, Pediatric 1
      CardOTPhysicalExaminationPediatrics1ViewData: {
        ...this.state.CardOTPhysicalExaminationPediatrics1ViewData,
        CtGeneralAppearanceOptions: this.setOption(
          clinicalterm.general_appearance,
          true,
          false
        ),
        Ct2AttentionOptions: this.setOption(
          clinicalterm.tec_attention,
          true,
          false
        ),
        Ct3LevelOfArousalOptions: this.setOption(
          clinicalterm.tec_level_of_arousal,
          true,
          false
        ),
        Ct4CommunicationOptions: this.setOption(
          clinicalterm.tec_communication,
          true,
          false
        ),
        Ct4CoOperationOptions: this.setOption(
          clinicalterm.tec_co_operation,
          true,
          false
        ),
      },
      // OCCUPATION, Pediatric 2
      OTPhysicalExaminationPediatrics2ViewData: {
        ...this.state.OTPhysicalExaminationPediatrics2ViewData,
        CtFineMotorOptions: this.setOption(
          clinicalterm.fine_motor,
          true,
          false
        ),
        CtGrossMotorOptions: this.setOption(
          clinicalterm.gross_motor,
          true,
          false
        ),
        CtLanguageOptions: this.setOption(clinicalterm.language, true, false),
        CtPersonalAndSocialOptions: this.setOption(
          clinicalterm.personal_and_social,
          true,
          false
        ),
      },
      // OCCUPATION, Pediatric 3
      OTPhysicalExaminationPediatrics3ViewData: {
        ...this.state.OTPhysicalExaminationPediatrics3ViewData,
        CtAuditoryOptions: this.setOption(clinicalterm.auditory, true, false),
        CtGustatoryOptions: this.setOption(clinicalterm.auditory, true, false),
        CtProprioceptiveOptions: this.setOption(
          clinicalterm.proprioceptive,
          true,
          false
        ),
        CtTactileOptions: this.setOption(clinicalterm.tactile, true, false),
        CtVestibularOptions: this.setOption(
          clinicalterm.vestibular,
          true,
          false
        ),
        CtVisualOptions: this.setOption(clinicalterm.visual, true, false),
      },
      // OCCUPATION, CELEBRAL 1
      OTPhysicalExaminationCerebralPalsy1ViewData: {
        ...this.state.OTPhysicalExaminationCerebralPalsy1ViewData,
        CtAttentionOptions: this.setOption(
          clinicalterm.tec_attention,
          true,
          false
        ),
        CtContracturesOptions: this.setOption(clinicalterm.tec_constractures),
        CtPathologyOptions: this.setOption(
          clinicalterm.tec_pathology,
          true,
          false
        ),
        Ct2CognitiveOptions: this.setOption(
          clinicalterm.tec_cognitive,
          true,
          false
        ),
        Ct2LevelOfArousalOptions: this.setOption(
          clinicalterm.tec_level_of_arousal,
          true,
          false
        ),
        Ct2SensoryOptions: this.setOption(
          clinicalterm.tec_sensory,
          true,
          false
        ),
        Ct3CommunicationOptions: this.setOption(
          clinicalterm.tec_communication,
          true,
          false
        ),
        Ct3CoOperationOptions: this.setOption(
          clinicalterm.tec_co_operation,
          true,
          false
        ),
        Ct7GeneralAppearanceOptions: this.setOption(
          clinicalterm.general_appearance,
          true,
          false
        ),
      },
      // OCCUPATION, CELEBRAL 2
      CardOTPhysicalExaminationCerebralPalsy2ViewData: {
        ...this.state.CardOTPhysicalExaminationCerebralPalsy2ViewData,
        CtBathingOptions: this.setOption(
          clinicalterm.tec_bath_shower,
          true,
          false
        ),
        CtBedMobilityOptions: this.setOption(
          clinicalterm.tec_bed_mobility,
          true,
          false
        ),
        CtDressingLowerOptions: this.setOption(
          clinicalterm.tec_dressing_lower,
          true,
          false
        ),
        CtDressingUpperOptions: this.setOption(
          clinicalterm.tec_dressing_upper,
          true,
          false
        ),
        CtFeedingEatingOptions: this.setOption(
          clinicalterm.tec_feeding_eating,
          true,
          false
        ),
        CtGrooming_oralOptions: this.setOption(
          clinicalterm.tec_grooming_oral,
          true,
          false
        ),
        CtOtherOptions: this.setOption(
          clinicalterm.tec_physi_dys_other,
          true,
          false
        ),
        CtToiletingOptions: this.setOption(
          clinicalterm.tec_toileting,
          true,
          false
        ),
        CtTransferBedOptions: this.setOption(
          clinicalterm.tec_transfer_bed,
          true,
          false
        ),
        CtTransferToiletOptions: this.setOption(
          clinicalterm.tec_transfer_toilet,
          true,
          false
        ),
      },
      // OCCUPATION, CELEBRAL 3
      OTPhysicalExaminationCerebralPalsy3ViewData: {
        ...this.state.OTPhysicalExaminationCerebralPalsy3ViewData,
        CtBackwardWalkingOptions: this.setOption(clinicalterm.backward_walking),
        CtBilateralHandUseOptions: this.setOption(
          clinicalterm.bilateral_hand_use
        ),
        CtCrawlingCreepingOptions: this.setOption(
          clinicalterm.creeping_crawling
        ),
        CtDependentOptions: this.setOption(clinicalterm.tec_dependent),
        CtForwardWalkingOptions: this.setOption(clinicalterm.forward_walking),
        CtGraspReflexOptions: this.setOption(clinicalterm.tec_grasp_reflex),
        CtHandGraspOptions: this.setOption(clinicalterm.tec_hand_grasp),
        CtHeadControlSupineOptions: this.setOption(
          clinicalterm.head_control_supine
        ),
        CtHeadControlProneOptions: this.setOption(
          clinicalterm.head_control_prone
        ),
        CtHoppingOptions: this.setOption(clinicalterm.tec_hopping),
        CtJumpingOptions: this.setOption(clinicalterm.tec_jumping),
        CtLateralityOptions: this.setOption(clinicalterm.laterality),
        CtOpenHandOptions: this.setOption(clinicalterm.tec_open_hand),
        CtOroMotorOptions: this.setOption(clinicalterm.oro_motor, true, false),
        CtPinchGraspOptions: this.setOption(clinicalterm.pinch_grasp),
        CtProneToSupineOptions: this.setOption(clinicalterm.prone_to_supine),
        CtPullToSitOptions: this.setOption(clinicalterm.pull_to_sit),
        CtPullToStandOptions: this.setOption(clinicalterm.pull_to_stand),
        CtReachOutOptions: this.setOption(clinicalterm.tec_reach_out),
        CtRollingOptions: this.setOption(clinicalterm.rolling),
        CtRunningOptions: this.setOption(clinicalterm.tec_running),
        CtSideLyingToSitOptions: this.setOption(clinicalterm.side_lying_to_sit),
        CtSideWalkingOptions: this.setOption(clinicalterm.side_walking),
        CtSittingOptions: this.setOption(clinicalterm.tec_sitting),
        CtStandingOptions: this.setOption(clinicalterm.tec_standing),
        CtSupineToProneOptions: this.setOption(clinicalterm.supine_to_prone),
        CtTransferInSittingOptions: this.setOption(
          clinicalterm.transfer_in_sitting
        ),
        CtWalkingOptions: this.setOption(clinicalterm.walking),
        CtWalkWithWalkingAidOptions: this.setOption(
          clinicalterm.walk_with_walking
        ),
        CtWheelchairOptions: this.setOption(clinicalterm.wheelchair),
        CtWholeHandGraspOptions: this.setOption(clinicalterm.whole_hand_grasp),
        Ct2CrawlingCreepingOptions: this.setOption(
          clinicalterm.creeping_crawling
        ),
      },
    });
  };

  setTabRehabilitationTreatmentClinicalTerm = (clinicalterm) => {
    this.setState({
      // PHYSICAL
      PTRehabilitationtreatmentViewData: {
        ...this.state.PTRehabilitationtreatmentViewData,
        Ct7PainScoreOptions: this.setOption(clinicalterm.tec_pain_score),
      },
      // OCCUPATIONAL
      OTRehabilitationTreatmentViewData: {
        ...this.state.OTRehabilitationTreatmentViewData,
        Ct6PainScoreOptions: this.setOption(clinicalterm.tec_pain_score),
      },
      // PROSTHETIC_ORTHOTIC, FITANDCHECK
      PORehabilitationTreatmentOrthoticFittingandCheckoutViewData: {
        ...this.state
          .PORehabilitationTreatmentOrthoticFittingandCheckoutViewData,
        SaveRequestDisabled: this.props.readonlySession,
        Ct4PainScoreOptions: this.setOption(clinicalterm.tec_pain_score),
      },
      // PROSTHETIC_ORTHOTIC, ANKLEFOOT
      PORehabilitationTreatmentAnkleFootOrthoticViewData: {
        ...this.state.PORehabilitationTreatmentAnkleFootOrthoticViewData,
        SaveRequestDisabled: this.props.readonlySession,
        CtTreatmentAnklefootOrthosisOptions: this.setOption(
          clinicalterm.tec_treatment_ankle
        ),
        Ct3PainScoreOptions: this.setOption(clinicalterm.tec_pain_score),
      },
      // PROSTHETIC_ORTHOTIC, ORTHOTICFOOT
      PORehabilitationTreatmentOrthoticFootViewData: {
        ...this.state.PORehabilitationTreatmentOrthoticFootViewData,
        SaveRequestDisabled: this.props.readonlySession,
        Ct5PainScoreOptions: this.setOption(clinicalterm.tec_pain_score),
      },
    });
  };

  setTabPlanClinicalTerm = (clinicalterm) => {
    this.setState({
      // PHYSICAL, CARDIO || NEURO
      CARDIOPTprogressionnoteOPDCardioNeuroViewData: {
        ...this.state.CARDIOPTprogressionnoteOPDCardioNeuroViewData,
        Ct2DischargeTodayOptions: this.setOption(clinicalterm.tec_dis_today),
      },
      NEUROPTprogressionnoteOPDCardioNeuroViewData: {
        ...this.state.NEUROPTprogressionnoteOPDCardioNeuroViewData,
        Ct2DischargeTodayOptions: this.setOption(clinicalterm.tec_dis_today),
      },
      // PHYSICAL, ORTHO
      PTprogressionnoteOPDOrthoViewData: {
        ...this.state.PTprogressionnoteOPDOrthoViewData,
        Ct3DischargeTodayOptions: this.setOption(clinicalterm.tec_dis_today),
      },
      // OCCUPATIONAL
      OTProgressionNoteOPDDysfunctionViewData: {
        ...this.state.OTProgressionNoteOPDDysfunctionViewData,
        CtDischargeTodayOptions: this.setOption(clinicalterm.tec_dis_today),
      },
    });
  };
  //CardOTPhysicalDys event
  handleOnLeftRightClick = (message) => {
    if (message === "left") {
      this.setState({
        isOTPEDysClickLeft: true,
        isOTPEDysClickRight: false,
      });
    } else if (message === "right") {
      this.setState({
        isOTPEDysClickRight: true,
        isOTPEDysClickLeft: false,
      });
    } else {
      this.setState({
        isOTPEDysClickRight: false,
        isOTPEDysClickLeft: false,
      });
    }
  };
  // Event handler
  handleEvent = async ({ message, params }) => {
    if (this.props && this.props.readonlySession) {
      return;
    }

    if (message === "inputChange") {
      this.setViewData(params.view, { [params.name]: params.value });
    } else if (message === "timeChange") {
      this.setViewData(params.view, { [params.name]: params.value });
    } else if (message === "dateChange") {
      this.setViewData(params.view, { [params.name]: params.value });
    } else if (message === "choose") {
      // tab Physical Exam, SWALLOWING, OCCUPATION
      let viewData = { [params.name]: params.value };
      if (params.view === "CardOTPhysicalExaminationSwallowingEvaluation1") {
        switch (params.value) {
          case "yes":
            viewData["CtDegreeDisabled"] = false;
            break;
          case "no":
            viewData["CtDegree"] = "";
            viewData["CtDegreeDisabled"] = true;
            break;
          default:
            break;
        }
      }
      // this.setViewData(params.view, {
      //   [params.name]: params.value,
      // });
      this.setViewData(params.view, viewData);
    } else if (message === "radio") {
      const value: boolean = this.state[params.view + "ViewData"][params.name];
      this.setViewData(params.view, { [params.name]: !value });

      let viewData = { [params.name]: !value };

      if (params.view === "CardPTDiagnosisOrthopedicOPDIPDLOW") {
        if (
          params.name === "PtDiagnosisOrthoOpdipdLow64" ||
          params.name === "PtDiagnosisOrthoOpdipdLow65"
        ) {
          viewData["PtDiagnosisOrthoOpdipdLow64"] =
            params.name === "PtDiagnosisOrthoOpdipdLow64" ? true : false;
          viewData["PtDiagnosisOrthoOpdipdLow65"] =
            params.name === "PtDiagnosisOrthoOpdipdLow65" ? true : false;
        }
      } else if (
        params.view === "CardPODiagnosisOrthoticfootPremorbidIndependent"
      ) {
        if (
          params.name === "PoDiagnosisOrthofootLow59" ||
          params.name === "PoDiagnosisOrthofootLow60"
        ) {
          viewData["PoDiagnosisOrthofootLow59"] =
            params.name === "PoDiagnosisOrthofootLow59" ? true : false;
          viewData["PoDiagnosisOrthofootLow60"] =
            params.name === "PoDiagnosisOrthofootLow60" ? true : false;
        }
      } else if (
        params.view ===
        this.state.activeMenu + "CardPODiagnosisOrthoticfootPremorbidIndependentLOW"
      ) {
        switch (params.name) {
          case "PoDiagnosisOrthofootLow59":
            viewData["PoDiagnosisOrthofootLow60"] = value;
            break;
          case "PoDiagnosisOrthofootLow60":
            viewData["PoDiagnosisOrthofootLow59"] = value;
            break;
          default:
            break;
        }
      } else if (params.view === "CardOTDiagnosisPhysicalDysfunctionLOW") {
        if (
          params.name === "OtDiagnosisPhysicalDysfunctionLow69" ||
          params.name === "OtDiagnosisPhysicalDysfunctionLow71"
        ) {
          viewData["OtDiagnosisPhysicalDysfunctionLow69"] =
            params.name === "OtDiagnosisPhysicalDysfunctionLow69"
              ? true
              : false;
          viewData["OtDiagnosisPhysicalDysfunctionLow71"] =
            params.name === "OtDiagnosisPhysicalDysfunctionLow71"
              ? true
              : false;
        }
      } else if (params.view === "CardOTDiagnosisSwallowingEvaluationLOW") {
        if (
          params.name === "OtDiagnosisSwallowingLow68" ||
          params.name === "OtDiagnosisSwallowingLow70"
        ) {
          viewData["OtDiagnosisSwallowingLow68"] =
            params.name === "OtDiagnosisSwallowingLow68" ? true : false;
          viewData["OtDiagnosisSwallowingLow70"] =
            params.name === "OtDiagnosisSwallowingLow70" ? true : false;
        }
      } else if (params.view === "CardOTDiagnosisPediatricsLOW") {
        if (
          params.name === "OtDiagnosisPediatricsLow74" ||
          params.name === "OtDiagnosisPediatricsLow76"
        ) {
          viewData["OtDiagnosisPediatricsLow74"] =
            params.name === "OtDiagnosisPediatricsLow74" ? true : false;
          viewData["OtDiagnosisPediatricsLow76"] =
            params.name === "OtDiagnosisPediatricsLow76" ? true : false;
        }
      } else if (params.view === "CardOTDiagnosisCerebralPalsyLOW") {
        if (
          params.name === "OtDiagnosisCerebralLow77" ||
          params.name === "OtDiagnosisCerebralLow79"
        ) {
          viewData["OtDiagnosisCerebralLow77"] =
            params.name === "OtDiagnosisCerebralLow77" ? true : false;
          viewData["OtDiagnosisCerebralLow79"] =
            params.name === "OtDiagnosisCerebralLow79" ? true : false;
        }
      } else if (
        params.view === "PORehabilitationTreatmentOrthoticFittingandCheckout"
      ) {
        if (
          params.name ===
            "PoRehabilitationTreatmentOrthoticFittingCheckout20" ||
          params.name === "PoRehabilitationTreatmentOrthoticFittingCheckout24"
        ) {
          viewData["PoRehabilitationTreatmentOrthoticFittingCheckout20"] =
            params.name === "PoRehabilitationTreatmentOrthoticFittingCheckout20"
              ? true
              : false;
          viewData["PoRehabilitationTreatmentOrthoticFittingCheckout24"] =
            params.name === "PoRehabilitationTreatmentOrthoticFittingCheckout24"
              ? true
              : false;
        }
      } else if (params.view === "PORehabilitationTreatmentAnkleFootOrthotic") {
        if (
          params.name === "PoRehabilitationTreatmentAnkleFootOrthotic23" ||
          params.name === "PoRehabilitationTreatmentAnkleFootOrthotic27"
        ) {
          viewData["PoRehabilitationTreatmentAnkleFootOrthotic23"] =
            params.name === "PoRehabilitationTreatmentAnkleFootOrthotic23"
              ? true
              : false;
          viewData["PoRehabilitationTreatmentAnkleFootOrthotic27"] =
            params.name === "PoRehabilitationTreatmentAnkleFootOrthotic27"
              ? true
              : false;
        }
      } else if (params.view === "PORehabilitationTreatmentOrthoticFoot") {
        if (
          params.name === "PoRehabilitationTreatmentOrthoticFoot20" ||
          params.name === "PoRehabilitationTreatmentOrthoticFoot24"
        ) {
          viewData["PoRehabilitationTreatmentOrthoticFoot20"] =
            params.name === "PoRehabilitationTreatmentOrthoticFoot20"
              ? true
              : false;
          viewData["PoRehabilitationTreatmentOrthoticFoot24"] =
            params.name === "PoRehabilitationTreatmentOrthoticFoot24"
              ? true
              : false;
        }
      } else if (params.view === "PTRehabilitationtreatment") {
        if (
          params.name === "PtRehabilitationTreatment22" ||
          params.name === "PtRehabilitationTreatment25"
        ) {
          viewData["PtRehabilitationTreatment22"] =
            params.name === "PtRehabilitationTreatment22" ? true : false;
          viewData["PtRehabilitationTreatment25"] =
            params.name === "PtRehabilitationTreatment25" ? true : false;
        }
      } else if (params.view === "OTRehabilitationTreatment") {
        if (
          params.name === "OtRehabilitationTreatment20" ||
          params.name === "OtRehabilitationTreatment24"
        ) {
          viewData["OtRehabilitationTreatment20"] =
            params.name === "OtRehabilitationTreatment20" ? true : false;
          viewData["OtRehabilitationTreatment24"] =
            params.name === "OtRehabilitationTreatment24" ? true : false;
        }
      }

      this.setViewData(params.view, viewData);
    } else if (message === "check") {
      const value: boolean = this.state[params.view + "ViewData"][params.name];
      let viewData = { [params.name]: !value };
      console.log(" Check before if  params.view", params.view, "params.name: ", params.name);
      // tab Physical Exam, PHYSICAL, ORTHO, TOP
      if (params.view === "CardPTPhysicalexaminationOrthopedicOPDTOP") {
        switch (params.name) {
          case "PtPhysicalOrthoOpdTop48":
            viewData["PtPhysicalOrthoOpdTop47Disabled"] = value;
            break;
          case "PtPhysicalOrthoOpdTop51":
            viewData["PtPhysicalOrthoOpdTop49Disabled"] = value;
            break;
          case "PtPhysicalOrthoOpdTop54":
            viewData["PtPhysicalOrthoOpdTop52Disabled"] = value;
            break;
          case "PtPhysicalOrthoOpdTop57":
            viewData["PtPhysicalOrthoOpdTop55Disabled"] = value;
            break;
          case "PtPhysicalOrthoOpdTop59":
            viewData["PtPhysicalOrthoOpdTop47Disabled"] = true;
            viewData["PtPhysicalOrthoOpdTop48"] = false;
            viewData["PtPhysicalOrthoOpdTop48Disabled"] = !value;
            viewData["PtPhysicalOrthoOpdTop49Disabled"] = true;
            viewData["PtPhysicalOrthoOpdTop51"] = false;
            viewData["PtPhysicalOrthoOpdTop51Disabled"] = !value;
            viewData["PtPhysicalOrthoOpdTop52Disabled"] = true;
            viewData["PtPhysicalOrthoOpdTop54"] = false;
            viewData["PtPhysicalOrthoOpdTop54Disabled"] = !value;
            viewData["PtPhysicalOrthoOpdTop55Disabled"] = true;
            viewData["PtPhysicalOrthoOpdTop57"] = false;
            viewData["PtPhysicalOrthoOpdTop57Disabled"] = !value;
            break;
          default:
            break;
        }
      }
      // tab Physical Exam, PHYSICAL, ORTHO, LOW
      else if (params.view === "CardPTPhysicalexaminationOrthopedicOPDLOW") {
        switch (params.name) {
          case "PtPhysicalOrthoOpdLow9":
            viewData["PtPhysicalOrthoOpdLow8Disabled"] = value;
            break;
          case "PtPhysicalOrthoOpdLow12":
            viewData["PtPhysicalOrthoOpdLow10Disabled"] = value;
            break;
          case "PtPhysicalOrthoOpdLow15":
            viewData["PtPhysicalOrthoOpdLow13Disabled"] = value;
            break;
          case "PtPhysicalOrthoOpdLow16":
            viewData["PtPhysicalOrthoOpdLow8Disabled"] = true;
            viewData["PtPhysicalOrthoOpdLow9"] = false;
            viewData["PtPhysicalOrthoOpdLow9Disabled"] = !value;
            viewData["PtPhysicalOrthoOpdLow10Disabled"] = true;
            viewData["PtPhysicalOrthoOpdLow12"] = false;
            viewData["PtPhysicalOrthoOpdLow12Disabled"] = !value;
            viewData["PtPhysicalOrthoOpdLow13Disabled"] = true;
            viewData["PtPhysicalOrthoOpdLow15"] = false;
            viewData["PtPhysicalOrthoOpdLow15Disabled"] = !value;
            break;
          default:
            break;
        }
      }
      // tab Physical Exam, PHYSICAL, CARDIO TOP
      else if (params.view === "CardPTPhysicalexaminationCardiopulmonaryTOP") {
        switch (params.name) {
          case "PtPhysicalCadioTop27":
            viewData["PtPhysicalCadioTop22Disabled"] = value;
            viewData["PtPhysicalCadioTop22"] = ""
            break;
          case "PtPhysicalCadioTop30":
            viewData["PtPhysicalCadioTop121Disabled"] = value;
            viewData["PtPhysicalCadioTop121"] = ""
            break;
          case "PtPhysicalCadioTop83":
            viewData["PtPhysicalCadioTop81Disabled"] = value;
            break;
          case "PtPhysicalCadioTop86":
            viewData["PtPhysicalCadioTop82Disabled"] = value;
            break;
          case "PtPhysicalCadioTop89":
            viewData["PtPhysicalCadioTop85Disabled"] = value;
            break;
          case "PtPhysicalCadioTop91":
            viewData["PtPhysicalCadioTop88Disabled"] = value;
            break;
          case "PtPhysicalCadioTop93":
            //check box
            viewData["PtPhysicalCadioTop83Disabled"] = !value;
            viewData["PtPhysicalCadioTop86Disabled"] = !value;
            viewData["PtPhysicalCadioTop89Disabled"] = !value;
            viewData["PtPhysicalCadioTop91Disabled"] = !value;
            viewData["PtPhysicalCadioTop83"] = false;
            viewData["PtPhysicalCadioTop86"] = false;
            viewData["PtPhysicalCadioTop89"] = false;
            viewData["PtPhysicalCadioTop91"] = false;
            // text box
            viewData["PtPhysicalCadioTop81Disabled"] = true;
            viewData["PtPhysicalCadioTop82Disabled"] = true;
            viewData["PtPhysicalCadioTop85Disabled"] = true;
            viewData["PtPhysicalCadioTop88Disabled"] = true;
            viewData["PtPhysicalCadioTop81"] = "";
            viewData["PtPhysicalCadioTop82"] = "";
            viewData["PtPhysicalCadioTop85"] = "";
            viewData["PtPhysicalCadioTop88"] = "";
            break;
          case "PtPhysicalCadioTop98":
            viewData["PtPhysicalCadioTop96Disabled"] = value;
            break;
          case "PtPhysicalCadioTop101":
            viewData["PtPhysicalCadioTop97Disabled"] = value;
            break;
          case "PtPhysicalCadioTop103":
            viewData["PtPhysicalCadioTop100Disabled"] = value;
            break;
          case "PtPhysicalCadioTop105":
            // check box
            viewData["PtPhysicalCadioTop98Disabled"] = !value;
            viewData["PtPhysicalCadioTop101Disabled"] = !value;
            viewData["PtPhysicalCadioTop103Disabled"] = !value;

            viewData["PtPhysicalCadioTop98"] = false;
            viewData["PtPhysicalCadioTop101"] = false;
            viewData["PtPhysicalCadioTop103"] = false;
            //text box
            viewData["PtPhysicalCadioTop96Disabled"] = true;
            viewData["PtPhysicalCadioTop97Disabled"] = true;
            viewData["PtPhysicalCadioTop100Disabled"] = true;

            viewData["PtPhysicalCadioTop96"] = "";
            viewData["PtPhysicalCadioTop97"] = "";
            viewData["PtPhysicalCadioTop100"] = "";
            break;
          case "PtPhysicalCadioTop108":
            viewData["PtPhysicalCadioTop114Disabled"] = value;
            viewData["PtPhysicalCadioTop118Disabled"] = value;
            viewData["PtPhysicalCadioTop115Disabled"] = value;
            viewData["PtPhysicalCadioTop116Disabled"] = value;
            break;
          case "PtPhysicalCadioTop111":
             // text
            viewData["PtPhysicalCadioTop114Disabled"] = !value;
            viewData["PtPhysicalCadioTop118Disabled"] = !value;
            viewData["PtPhysicalCadioTop115Disabled"] = !value;
            viewData["PtPhysicalCadioTop116Disabled"] = !value;
            viewData["PtPhysicalCadioTop114"] = "";
            viewData["PtPhysicalCadioTop118"] = "";
            viewData["PtPhysicalCadioTop115"] = "";
            viewData["PtPhysicalCadioTop116"] = "";
            viewData["PtPhysicalCadioTop122"] = "";
            viewData["PtPhysicalCadioTop122Disabled"] = !value;

            // checkbox
            viewData["PtPhysicalCadioTop108Disabled"] = !value;
            viewData["PtPhysicalCadioTop108"] = false;
            
            break;
        }
      }
      // tab Physical Exam, PHYSICAL, NEURO, TOP
      else if (params.view === "CardPTPhysicalexaminationNeuroogialOPDTOP") {
        switch (params.name) {
          case "PtPhysicalNeuroOpdTop50":
            viewData["PtPhysicalNeuroOpdTop46Disabled"] = value;
            break;
          case "PtPhysicalNeuroOpdTop52":
            viewData["PtPhysicalNeuroOpdTop47Disabled"] = value;
            break;
          case "PtPhysicalNeuroOpdTop54":
            viewData["PtPhysicalNeuroOpdTop48Disabled"] = value;
            break;
          case "PtPhysicalNeuroOpdTop56":
            viewData["PtPhysicalNeuroOpdTop49Disabled"] = value;
            break;
          case "PtPhysicalNeuroOpdTop58":
            viewData["PtPhysicalNeuroOpdTop46Disabled"] = true;
            viewData["PtPhysicalNeuroOpdTop47Disabled"] = true;
            viewData["PtPhysicalNeuroOpdTop48Disabled"] = true;
            viewData["PtPhysicalNeuroOpdTop49Disabled"] = true;
            viewData["PtPhysicalNeuroOpdTop50"] = false;
            viewData["PtPhysicalNeuroOpdTop50Disabled"] = !value;
            viewData["PtPhysicalNeuroOpdTop52"] = false;
            viewData["PtPhysicalNeuroOpdTop52Disabled"] = !value;
            viewData["PtPhysicalNeuroOpdTop54"] = false;
            viewData["PtPhysicalNeuroOpdTop54Disabled"] = !value;
            viewData["PtPhysicalNeuroOpdTop56"] = false;
            viewData["PtPhysicalNeuroOpdTop56Disabled"] = !value;
            break;
          default:
            break;
        }
      } else if (
        params.view ===
        this.state.activeMenu + "PTprogressionnoteOPDCardioNeuro"
      ) {
        switch (params.name) {
          case "PtProgressnoteOpdCardioNeuro13":
            viewData["PtProgressnoteOpdCardioNeuro10Disabled"] = value;
            break;
          default:
            break;
        }
      } else if (
        params.view ===
        this.state.activeMenu + "CardPODiagnosisOrthoticfootPremorbidIndependentLOW"
      ) {
        switch (params.name) {
          case "PoDiagnosisOrthofootLow11": 
            viewData["PoDiagnosisOrthofootLow12Disabled"] = !value
            viewData["PoDiagnosisOrthofootLow13Disabled"] = !value
            viewData["PoDiagnosisOrthofootLow15Disabled"] = !value
            viewData["PoDiagnosisOrthofootLow18Disabled"] = !value
            viewData["PoDiagnosisOrthofootLow22Disabled"] = !value
            viewData["PoDiagnosisOrthofootLow24Disabled"] = !value
            viewData["PoDiagnosisOrthofootLow29Disabled"] = !value
            viewData["PoDiagnosisOrthofootLow30Disabled"] = !value
            viewData["PoDiagnosisOrthofootLow32Disabled"] = !value
            viewData["PoDiagnosisOrthofootLow34Disabled"] = !value
            viewData["PoDiagnosisOrthofootLow36Disabled"] = !value
            viewData["PoDiagnosisOrthofootLow38Disabled"] = !value
            viewData["PoDiagnosisOrthofootLow43Disabled"] = !value
            viewData["PoDiagnosisOrthofootLow44Disabled"] = !value
            viewData["PoDiagnosisOrthofootLow12"] = false
            viewData["PoDiagnosisOrthofootLow13"] = false
            viewData["PoDiagnosisOrthofootLow15"] = false
            viewData["PoDiagnosisOrthofootLow18"] = false
            viewData["PoDiagnosisOrthofootLow22"] = false
            viewData["PoDiagnosisOrthofootLow24"] = false
            viewData["PoDiagnosisOrthofootLow29"] = false
            viewData["PoDiagnosisOrthofootLow30"] = false
            viewData["PoDiagnosisOrthofootLow32"] = false
            viewData["PoDiagnosisOrthofootLow34"] = false
            viewData["PoDiagnosisOrthofootLow36"] = false
            viewData["PoDiagnosisOrthofootLow38"] = false
            viewData["PoDiagnosisOrthofootLow43"] = false
            viewData["PoDiagnosisOrthofootLow44"] = false
            viewData["PoDiagnosisOrthofootLow31Disabled"] = true;
            viewData["PoDiagnosisOrthofootLow31"] = "";

          case "PoDiagnosisOrthofootLow59":
            viewData["PoDiagnosisOrthofootLow60"] = value;
            break;
          case "PoDiagnosisOrthofootLow60":
            viewData["PoDiagnosisOrthofootLow59"] = value;
            break;
          case "PoDiagnosisOrthofootLow34":
            viewData["PoDiagnosisOrthofootLow31Disabled"] = value;
            viewData["PoDiagnosisOrthofootLow31"] = "";
            break;
          default:
            break;
        }
      }
      // tab Physical Exam, PROSTHETIC_ORTHOTIC, FITANDCHECK, TOP
      else if (
        params.view === "POPhysicalexaminationOrthoticFittingandCheckout2TOP"
      ) {
        switch (params.name) {
          case "PoPhysicalOrthoFittingCheckout2Top28":
            viewData["PoPhysicalOrthoFittingCheckout2Top26Disabled"] = value;
            break;
          case "PoPhysicalOrthoFittingCheckout2Top31":
            viewData["PoPhysicalOrthoFittingCheckout2Top27Disabled"] = value;
            break;
          case "PoPhysicalOrthoFittingCheckout2Top34":
            viewData["PoPhysicalOrthoFittingCheckout2Top30Disabled"] = value;
            break;
          case "PoPhysicalOrthoFittingCheckout2Top36":
            viewData["PoPhysicalOrthoFittingCheckout2Top33Disabled"] = value;
            break;
          case "PoPhysicalOrthoFittingCheckout2Top38":
            viewData["PoPhysicalOrthoFittingCheckout2Top26Disabled"] = true;
            viewData["PoPhysicalOrthoFittingCheckout2Top27Disabled"] = true;
            viewData["PoPhysicalOrthoFittingCheckout2Top28"] = false;
            viewData["PoPhysicalOrthoFittingCheckout2Top28Disabled"] = !value;
            viewData["PoPhysicalOrthoFittingCheckout2Top30Disabled"] = true;
            viewData["PoPhysicalOrthoFittingCheckout2Top31"] = false;
            viewData["PoPhysicalOrthoFittingCheckout2Top31Disabled"] = !value;
            viewData["PoPhysicalOrthoFittingCheckout2Top33Disabled"] = true;
            viewData["PoPhysicalOrthoFittingCheckout2Top34"] = false;
            viewData["PoPhysicalOrthoFittingCheckout2Top34Disabled"] = !value;
            viewData["PoPhysicalOrthoFittingCheckout2Top36"] = false;
            viewData["PoPhysicalOrthoFittingCheckout2Top36Disabled"] = !value;
            break;
          default:
            break;
        }
      }
      // tab Physical Exam, PROSTHETIC_ORTHOTIC, Ankle Foot, TOP
      else if (params.view === "POPhysicalexaminationAnkleFootOrthosisTOP") {
        switch (params.name) {
          case "PoPhysicalAnkleFootOrthosisTop26":
            viewData["PoPhysicalAnkleFootOrthosisTop24Disabled"] = value;
            break;
          case "PoPhysicalAnkleFootOrthosisTop29":
            viewData["PoPhysicalAnkleFootOrthosisTop25Disabled"] = value;
            break;
          case "PoPhysicalAnkleFootOrthosisTop32":
            viewData["PoPhysicalAnkleFootOrthosisTop28Disabled"] = value;
            break;
          case "PoPhysicalAnkleFootOrthosisTop34":
            viewData["PoPhysicalAnkleFootOrthosisTop31Disabled"] = value;
            break;
          case "PoPhysicalAnkleFootOrthosisTop36":
            viewData["PoPhysicalAnkleFootOrthosisTop26Disabled"] = !value;
            viewData["PoPhysicalAnkleFootOrthosisTop29Disabled"] = !value;
            viewData["PoPhysicalAnkleFootOrthosisTop32Disabled"] = !value;
            viewData["PoPhysicalAnkleFootOrthosisTop34Disabled"] = !value;

            viewData["PoPhysicalAnkleFootOrthosisTop24Disabled"] = true;
            viewData["PoPhysicalAnkleFootOrthosisTop25Disabled"] = true;
            viewData["PoPhysicalAnkleFootOrthosisTop28Disabled"] = true;
            viewData["PoPhysicalAnkleFootOrthosisTop31Disabled"] = true;

            viewData["PoPhysicalAnkleFootOrthosisTop24"] = "";
            viewData["PoPhysicalAnkleFootOrthosisTop25"] = "";
            viewData["PoPhysicalAnkleFootOrthosisTop28"] = "";
            viewData["PoPhysicalAnkleFootOrthosisTop31"] = "";

            viewData["PoPhysicalAnkleFootOrthosisTop26"] = false;
            viewData["PoPhysicalAnkleFootOrthosisTop29"] = false;
            viewData["PoPhysicalAnkleFootOrthosisTop32"] = false;
            viewData["PoPhysicalAnkleFootOrthosisTop34"] = false;
            break;
          default:
            break;
        }
      }
      // tab Rehab, PHYSICAL
      else if (params.view === "PTRehabilitationtreatment") {
        switch (params.name) {
          case "PtRehabilitationTreatment19":
            viewData["PtRehabilitationTreatment17Disabled"] = !value;
            break;
          default:
            break;
        }
      }
      // tab Rehab, OCCUPATIONAL
      else if (params.view === "OTRehabilitationTreatment") {
        switch (params.name) {
          case "OtRehabilitationTreatment18":
            viewData["OtRehabilitationTreatment16Disabled"] = !value;
            break;

          default:
            break;
        }
      }
      // tab Rehab, PROSTHETIC_ORTHOTIC, FITANDCHECK
      else if (
        params.view === "PORehabilitationTreatmentOrthoticFittingandCheckout"
      ) {
        switch (params.name) {
          case "PoRehabilitationTreatmentOrthoticFittingCheckout18":
            viewData[
              "PoRehabilitationTreatmentOrthoticFittingCheckout16Disabled"
            ] = !value;
            break;

          default:
            break;
        }
      }
      // tab Rehab, PROSTHETIC_ORTHOTIC, ANKLEFOOT
      else if (params.view === "PORehabilitationTreatmentAnkleFootOrthotic") {
        switch (params.name) {
          case "PoRehabilitationTreatmentAnkleFootOrthotic21":
            viewData[
              "PoRehabilitationTreatmentAnkleFootOrthotic19Disabled"
            ] = !value;
            break;
          case "PoRehabilitationTreatmentAnkleFootOrthotic27":
            viewData[
              "PoRehabilitationTreatmentAnkleFootOrthotic26Disabled"
            ] = value;
            break;
          default:
            break;
        }
      }
      // tab Rehab, PROSTHETIC_ORTHOTIC, ORTHOTICFOOT
      else if (params.view === "PORehabilitationTreatmentOrthoticFoot") {
        switch (params.name) {
          case "PoRehabilitationTreatmentOrthoticFoot18":
            viewData[
              "PoRehabilitationTreatmentOrthoticFoot16Disabled"
            ] = !value;
            break;
          case "PoRehabilitationTreatmentOrthoticFoot24":
            viewData["PoRehabilitationTreatmentOrthoticFoot23Disabled"] = value;
            break;
          default:
            break;
        }
      }
      // tab OT, Physical Dysfunction, Diagnosis
      else if (params.view == "CardOTDiagnosisPhysicalDysfunctionLOW") {
        switch (params.name) {
          case "OtDiagnosisPhysicalDysfunctionLow29": 
            viewData["OtDiagnosisPhysicalDysfunctionLow30Disabled"] = !value
            viewData["OtDiagnosisPhysicalDysfunctionLow31Disabled"] = !value
            viewData["OtDiagnosisPhysicalDysfunctionLow33Disabled"] = !value
            viewData["OtDiagnosisPhysicalDysfunctionLow36Disabled"] = !value
            viewData["OtDiagnosisPhysicalDysfunctionLow37Disabled"] = !value
            viewData["OtDiagnosisPhysicalDysfunctionLow39Disabled"] = !value
            viewData["OtDiagnosisPhysicalDysfunctionLow44Disabled"] = !value
            viewData["OtDiagnosisPhysicalDysfunctionLow46Disabled"] = !value
            viewData["OtDiagnosisPhysicalDysfunctionLow47Disabled"] = !value
            viewData["OtDiagnosisPhysicalDysfunctionLow49Disabled"] = !value
            viewData["OtDiagnosisPhysicalDysfunctionLow51Disabled"] = !value
            viewData["OtDiagnosisPhysicalDysfunctionLow52Disabled"] = !value
            viewData["OtDiagnosisPhysicalDysfunctionLow58Disabled"] = !value
            viewData["OtDiagnosisPhysicalDysfunctionLow30"] = false
            viewData["OtDiagnosisPhysicalDysfunctionLow31"] = false
            viewData["OtDiagnosisPhysicalDysfunctionLow33"] = false
            viewData["OtDiagnosisPhysicalDysfunctionLow36"] = false
            viewData["OtDiagnosisPhysicalDysfunctionLow37"] = false
            viewData["OtDiagnosisPhysicalDysfunctionLow39"] = false
            viewData["OtDiagnosisPhysicalDysfunctionLow44"] = false
            viewData["OtDiagnosisPhysicalDysfunctionLow46"] = false
            viewData["OtDiagnosisPhysicalDysfunctionLow47"] = false
            viewData["OtDiagnosisPhysicalDysfunctionLow49"] = false
            viewData["OtDiagnosisPhysicalDysfunctionLow51"] = false
            viewData["OtDiagnosisPhysicalDysfunctionLow52"] = false
            viewData["OtDiagnosisPhysicalDysfunctionLow58"] = false
            viewData["OtDiagnosisPhysicalDysfunctionLow45Disabled"] = true;
            viewData["OtDiagnosisPhysicalDysfunctionLow45"] = "";
            viewData["OtDiagnosisPhysicalDysfunctionLow57Disabled"] = true;
            viewData["OtDiagnosisPhysicalDysfunctionLow57"] = "";
          
            break;
          case "OtDiagnosisPhysicalDysfunctionLow24":
            viewData["OtDiagnosisPhysicalDysfunctionLow21Disabled"] = value;
            viewData["OtDiagnosisPhysicalDysfunctionLow21"] = "";
            break;
          case "OtDiagnosisPhysicalDysfunctionLow49":
            viewData["OtDiagnosisPhysicalDysfunctionLow45Disabled"] = value;
            viewData["OtDiagnosisPhysicalDysfunctionLow45"] = "";
            break;
          case "OtDiagnosisPhysicalDysfunctionLow58":
            viewData["OtDiagnosisPhysicalDysfunctionLow57Disabled"] = value;
            viewData["OtDiagnosisPhysicalDysfunctionLow57"] = "";
            break;
          default:
            break;
        }
      }
      // tab OT, Swallowing, Diagnosis
      else if (params.view == "CardOTDiagnosisSwallowingEvaluationLOW") {
        switch (params.name) {
          case "OtDiagnosisSwallowingLow29":
              viewData["OtDiagnosisSwallowingLow30Disabled"] = !value
              viewData["OtDiagnosisSwallowingLow31Disabled"] = !value
              viewData["OtDiagnosisSwallowingLow34Disabled"] = !value
              viewData["OtDiagnosisSwallowingLow36Disabled"] = !value
              viewData["OtDiagnosisSwallowingLow38Disabled"] = !value
              viewData["OtDiagnosisSwallowingLow40Disabled"] = !value
              viewData["OtDiagnosisSwallowingLow44Disabled"] = !value
              viewData["OtDiagnosisSwallowingLow46Disabled"] = !value
              viewData["OtDiagnosisSwallowingLow47Disabled"] = !value
              viewData["OtDiagnosisSwallowingLow50Disabled"] = !value
              viewData["OtDiagnosisSwallowingLow52Disabled"] = !value
              viewData["OtDiagnosisSwallowingLow54Disabled"] = !value
              viewData["OtDiagnosisSwallowingLow59Disabled"] = !value
              viewData["OtDiagnosisSwallowingLow30"] = false
              viewData["OtDiagnosisSwallowingLow31"] = false
              viewData["OtDiagnosisSwallowingLow34"] = false
              viewData["OtDiagnosisSwallowingLow36"] = false
              viewData["OtDiagnosisSwallowingLow38"] = false
              viewData["OtDiagnosisSwallowingLow40"] = false
              viewData["OtDiagnosisSwallowingLow44"] = false
              viewData["OtDiagnosisSwallowingLow46"] = false
              viewData["OtDiagnosisSwallowingLow47"] = false
              viewData["OtDiagnosisSwallowingLow50"] = false
              viewData["OtDiagnosisSwallowingLow52"] = false
              viewData["OtDiagnosisSwallowingLow54"] = false
              viewData["OtDiagnosisSwallowingLow59"] = false
              viewData["OtDiagnosisSwallowingLow58Disabled"] = true;
              viewData["OtDiagnosisSwallowingLow58"] = "";
              viewData["OtDiagnosisSwallowingLow48Disabled"] = true;
              viewData["OtDiagnosisSwallowingLow48"] = "";
            
            break;
          case "OtDiagnosisSwallowingLow26":
            viewData["OtDiagnosisSwallowingLow25Disabled"] = value;
            viewData["OtDiagnosisSwallowingLow25"] = "";
            break;
          case "OtDiagnosisSwallowingLow50":
            viewData["OtDiagnosisSwallowingLow48Disabled"] = value;
            viewData["OtDiagnosisSwallowingLow48"] = "";
            break;
          case "OtDiagnosisSwallowingLow59":
            viewData["OtDiagnosisSwallowingLow58Disabled"] = value;
            viewData["OtDiagnosisSwallowingLow58"] = "";
            break;
          default:
            break;
        }
      }
      // tab OT, Prediatic, Diagnosis
      else if (params.view === "CardOTDiagnosisPediatricsLOW") {
        switch (params.name) {
          case "OtDiagnosisPediatricsLow35":
            viewData["OtDiagnosisPediatricsLow36Disabled"] = !value;
            viewData["OtDiagnosisPediatricsLow37Disabled"] = !value;
            viewData["OtDiagnosisPediatricsLow40Disabled"] = !value;
            viewData["OtDiagnosisPediatricsLow42Disabled"] = !value;
            viewData["OtDiagnosisPediatricsLow45Disabled"] = !value;
            viewData["OtDiagnosisPediatricsLow47Disabled"] = !value;
            viewData["OtDiagnosisPediatricsLow52Disabled"] = !value;
            viewData["OtDiagnosisPediatricsLow53Disabled"] = !value;
            viewData["OtDiagnosisPediatricsLow55Disabled"] = !value;
            viewData["OtDiagnosisPediatricsLow57Disabled"] = !value;
            viewData["OtDiagnosisPediatricsLow58Disabled"] = !value;
            viewData["OtDiagnosisPediatricsLow60Disabled"] = !value;
            viewData["OtDiagnosisPediatricsLow67Disabled"] = !value;

            viewData["OtDiagnosisPediatricsLow36"] = false;
            viewData["OtDiagnosisPediatricsLow37"] = false;
            viewData["OtDiagnosisPediatricsLow40"] = false;
            viewData["OtDiagnosisPediatricsLow42"] = false;
            viewData["OtDiagnosisPediatricsLow45"] = false;
            viewData["OtDiagnosisPediatricsLow47"] = false;
            viewData["OtDiagnosisPediatricsLow52"] = false;
            viewData["OtDiagnosisPediatricsLow53"] = false;
            viewData["OtDiagnosisPediatricsLow55"] = false;
            viewData["OtDiagnosisPediatricsLow57"] = false;
            viewData["OtDiagnosisPediatricsLow58"] = false;
            viewData["OtDiagnosisPediatricsLow60"] = false;
            viewData["OtDiagnosisPediatricsLow67"] = false;
            viewData["OtDiagnosisPediatricsLow54Disabled"] = true;
            viewData["OtDiagnosisPediatricsLow66Disabled"] = true;
            viewData["OtDiagnosisPediatricsLow54"] = "";
            viewData["OtDiagnosisPediatricsLow66"] = "";
            break;
          
          case "OtDiagnosisPediatricsLow28":
            viewData["OtDiagnosisPediatricsLow26Disabled"] = value;
            viewData["OtDiagnosisPediatricsLow26"] = "";
            break;
          case "OtDiagnosisPediatricsLow57":
            viewData["OtDiagnosisPediatricsLow54Disabled"] = value;
            viewData["OtDiagnosisPediatricsLow54"] = "";
            break;
          case "OtDiagnosisPediatricsLow67":
            viewData["OtDiagnosisPediatricsLow66Disabled"] = value;
            viewData["OtDiagnosisPediatricsLow66"] = "";
            break;
          case "OtDiagnosisPediatricsLow102":
            viewData["OtDiagnosisPediatricsLow96Disabled"] = value;
            viewData["OtDiagnosisPediatricsLow96"] = "";
            break;
          default:
            break;
        }
      }
      // tab OT, Prediatic, Diagnosis
      else if (params.view === "CardOTDiagnosisCerebralPalsyLOW") {
        switch (params.name) {
          case "OtDiagnosisCerebralLow34":
            viewData["OtDiagnosisCerebralLow35Disabled"] = !value
            viewData["OtDiagnosisCerebralLow36Disabled"] = !value
            viewData["OtDiagnosisCerebralLow38Disabled"] = !value
            viewData["OtDiagnosisCerebralLow41Disabled"] = !value
            viewData["OtDiagnosisCerebralLow42Disabled"] = !value
            viewData["OtDiagnosisCerebralLow45Disabled"] = !value
            viewData["OtDiagnosisCerebralLow50Disabled"] = !value
            viewData["OtDiagnosisCerebralLow51Disabled"] = !value
            viewData["OtDiagnosisCerebralLow53Disabled"] = !value
            viewData["OtDiagnosisCerebralLow54Disabled"] = !value
            viewData["OtDiagnosisCerebralLow57Disabled"] = !value
            viewData["OtDiagnosisCerebralLow58Disabled"] = !value
            viewData["OtDiagnosisCerebralLow65Disabled"] = !value
            viewData["OtDiagnosisCerebralLow35"] = false
            viewData["OtDiagnosisCerebralLow36"] = false
            viewData["OtDiagnosisCerebralLow38"] = false
            viewData["OtDiagnosisCerebralLow41"] = false
            viewData["OtDiagnosisCerebralLow42"] = false
            viewData["OtDiagnosisCerebralLow45"] = false
            viewData["OtDiagnosisCerebralLow50"] = false
            viewData["OtDiagnosisCerebralLow51"] = false
            viewData["OtDiagnosisCerebralLow53"] = false
            viewData["OtDiagnosisCerebralLow54"] = false
            viewData["OtDiagnosisCerebralLow57"] = false
            viewData["OtDiagnosisCerebralLow58"] = false
            viewData["OtDiagnosisCerebralLow65"] = false
            viewData["OtDiagnosisCerebralLow52Disabled"] = true;
            viewData["OtDiagnosisCerebralLow52"] = "";
            viewData["OtDiagnosisCerebralLow64Disabled"] = true;
            viewData["OtDiagnosisCerebralLow64"] = "";

          case "OtDiagnosisCerebralLow27":
            viewData["OtDiagnosisCerebralLow25Disabled"] = value;
            viewData["OtDiagnosisCerebralLow25"] = "";
            break;
          case "OtDiagnosisCerebralLow54":
            viewData["OtDiagnosisCerebralLow52Disabled"] = value;
            viewData["OtDiagnosisCerebralLow52"] = "";
            break;
          case "OtDiagnosisCerebralLow65":
            viewData["OtDiagnosisCerebralLow64Disabled"] = value;
            viewData["OtDiagnosisCerebralLow64"] = "";
            break;
          case "OtDiagnosisCerebralLow104":
            viewData["OtDiagnosisCerebralLow98Disabled"] = value;
            viewData["OtDiagnosisCerebralLow98"] = "";
            break;
          default:
            break;
        }
      }
      // tab Physical Exam, SWALLOWING, OCCUPATIONAL, 1
      else if (params.view === "CardOTPhysicalExaminationSwallowingEvaluation1") {
        switch (params.name) {
          case "CardOtPhysicalSwallowing139":
            viewData["CardOtPhysicalSwallowing133Disabled"] = value;
            viewData["CardOtPhysicalSwallowing133"] = "";
            break;
          case "CardOtPhysicalSwallowing178":
            viewData["CardOtPhysicalSwallowing177Disabled"] = value;
            break;
          case "CardOtPhysicalSwallowing182":
            viewData["CardOtPhysicalSwallowing179Disabled"] = value;
            break;
          case "CardOtPhysicalSwallowing185":
            viewData["CardOtPhysicalSwallowing183Disabled"] = value;
            break;
          case "CardOtPhysicalSwallowing188":
            //text
            viewData["CardOtPhysicalSwallowing177"] = "";
            viewData["CardOtPhysicalSwallowing179"] = "";
            viewData["CardOtPhysicalSwallowing183"] = "";
            viewData["CardOtPhysicalSwallowing177Disabled"] = !value;
            viewData["CardOtPhysicalSwallowing179Disabled"] = !value;
            viewData["CardOtPhysicalSwallowing183Disabled"] = !value;
            // checkbox
            viewData["CardOtPhysicalSwallowing178"] = false;
            viewData["CardOtPhysicalSwallowing182"] = false;
            viewData["CardOtPhysicalSwallowing185"] = false;
            viewData["CardOtPhysicalSwallowing178Disabled"] = !value;
            viewData["CardOtPhysicalSwallowing182Disabled"] = !value;
            viewData["CardOtPhysicalSwallowing185Disabled"] = !value;
            break;
          default:
            break;
        }
      } else if (params.view === "OTPhysicalExaminationSwallowingEvaluation2") {
        switch (params.name) {
          case "OtPhysicalSwallowing216":
            viewData["OtPhysicalSwallowing210Disabled"] = value;
            break;
          case "OtPhysicalSwallowing242":
            viewData["CtScrSecond1st"] = "";
            viewData["CtScrSecond1stDisabled"] = !value;
            viewData["CtScrSecond2nd"] = "";
            viewData["CtScrSecond2ndDisabled"] = !value;
            break;
          case "OtPhysicalSwallowing257":
            viewData["CtScrThird1st"] = "";
            viewData["CtScrThird1stDisabled"] = !value;
            viewData["CtScrThird2nd"] = "";
            viewData["CtScrThird2ndDisabled"] = !value;
            break;
          case "OtPhysicalSwallowing275":
            viewData["CtScrFourth1st"] = "";
            viewData["CtScrFourth1stDisabled"] = !value;
            viewData["CtScrFourth2nd"] = "";
            viewData["CtScrFourth2ndDisabled"] = !value;
            break;
          default:
            break;
        }
      } else if (
        params.view === "CardOTPhysicalExaminationPhysicalDysfunctionMain"
      ) {
        switch (params.name) {
          case "CardOtPhysicalDysfunctionMain33":
            viewData["CardOtPhysicalDysfunctionMain29Disabled"] = value;
            break;
          case "CardOtPhysicalDysfunctionMain60":
            viewData["CardOtPhysicalDysfunctionMain59Disabled"] = value;
            break;
          case "CardOtPhysicalDysfunctionMain63":
            viewData["CardOtPhysicalDysfunctionMain61Disabled"] = value;
            break;
          case "CardOtPhysicalDysfunctionMain66":
            viewData["CardOtPhysicalDysfunctionMain64Disabled"] = value;
            break;
          case "CardOtPhysicalDysfunctionMain69":
            viewData["CardOtPhysicalDysfunctionMain67Disabled"] = value;
            break;
          case "CardOtPhysicalDysfunctionMain71":

            // text
            viewData["CardOtPhysicalDysfunctionMain59Disabled"] = !value;
            viewData["CardOtPhysicalDysfunctionMain61Disabled"] = !value;
            viewData["CardOtPhysicalDysfunctionMain64Disabled"] = !value;
            viewData["CardOtPhysicalDysfunctionMain67Disabled"] = !value;
            viewData["CardOtPhysicalDysfunctionMain59"] = "";
            viewData["CardOtPhysicalDysfunctionMain61"] = "";
            viewData["CardOtPhysicalDysfunctionMain64"] = "";
            viewData["CardOtPhysicalDysfunctionMain67"] = "";

            // checkbox
            viewData["CardOtPhysicalDysfunctionMain60Disabled"] = !value;
            viewData["CardOtPhysicalDysfunctionMain63Disabled"] = !value;
            viewData["CardOtPhysicalDysfunctionMain66Disabled"] = !value;
            viewData["CardOtPhysicalDysfunctionMain69Disabled"] = !value;
            viewData["CardOtPhysicalDysfunctionMain60"] = false;
            viewData["CardOtPhysicalDysfunctionMain63"] = false;
            viewData["CardOtPhysicalDysfunctionMain66"] = false;
            viewData["CardOtPhysicalDysfunctionMain69"] = false;

            break;
          case "CardOtPhysicalDysfunctionMain75":
            viewData["CardOtPhysicalDysfunctionMain74Disabled"] = value;
            break;
          case "CardOtPhysicalDysfunctionMain78":
            viewData["CardOtPhysicalDysfunctionMain76Disabled"] = value;
            break;
          case "CardOtPhysicalDysfunctionMain81":
            viewData["CardOtPhysicalDysfunctionMain79Disabled"] = value;
            break;
          case "CardOtPhysicalDysfunctionMain83":
            // text
            viewData["CardOtPhysicalDysfunctionMain74Disabled"] = !value;
            viewData["CardOtPhysicalDysfunctionMain76Disabled"] = !value;
            viewData["CardOtPhysicalDysfunctionMain79Disabled"] = !value;
            viewData["CardOtPhysicalDysfunctionMain74"] = "";
            viewData["CardOtPhysicalDysfunctionMain76"] = "";
            viewData["CardOtPhysicalDysfunctionMain79"] = "";

            // checkbox
            viewData["CardOtPhysicalDysfunctionMain75Disabled"] = !value;
            viewData["CardOtPhysicalDysfunctionMain78Disabled"] = !value;
            viewData["CardOtPhysicalDysfunctionMain81Disabled"] = !value;
            viewData["CardOtPhysicalDysfunctionMain75"] = false;
            viewData["CardOtPhysicalDysfunctionMain78"] = false;
            viewData["CardOtPhysicalDysfunctionMain81"] = false;
            break;
          case "CardOtPhysicalDysfunctionMain86":
            viewData["CardOtPhysicalDysfunctionMain93Disabled"] = value;
            viewData["CardOtPhysicalDysfunctionMain95Disabled"] = value;
            viewData["CardOtPhysicalDysfunctionMain94Disabled"] = value;
            viewData["CardOtPhysicalDysfunctionMain96Disabled"] = value;
            break;
          case "CardOtPhysicalDysfunctionMain88":

            // text
            viewData["CardOtPhysicalDysfunctionMain93Disabled"] = !value;
            viewData["CardOtPhysicalDysfunctionMain95Disabled"] = !value;
            viewData["CardOtPhysicalDysfunctionMain94Disabled"] = !value;
            viewData["CardOtPhysicalDysfunctionMain96Disabled"] = !value;
            viewData["CardOtPhysicalDysfunctionMain93"] = "";
            viewData["CardOtPhysicalDysfunctionMain95"] = "";
            viewData["CardOtPhysicalDysfunctionMain94"] = "";
            viewData["CardOtPhysicalDysfunctionMain96"] = "";

            // checkbox 
            viewData["CardOtPhysicalDysfunctionMain86Disabled"] = !value;
            viewData["CardOtPhysicalDysfunctionMain86"] = false;
            break;
          default:
            break;
        }
      }
      // OCCUPATION, PHYSICAL, PEDIATRICS 1
      else if (params.view === "CardOTPhysicalExaminationPediatrics1") {
        switch (params.name) {
          case "CardOtPhysicalPediatric141":
            viewData["CardOtPhysicalPediatric135"] = "";
            viewData["CardOtPhysicalPediatric135Disabled"] = value;
            break;
          default:
            break;
        }
      }
      // OCCUPATION, PHYSICAL, PEDIATRICS 3
      else if (params.view === "OTPhysicalExaminationPediatrics3") {
        switch (params.name) {
          case "OtPhysicalPediatric335":
            viewData["OtPhysicalPediatric338"] = "";
            viewData["OtPhysicalPediatric338Disabled"] = !value;
            break;
          default:
            break;
        }
      }

      // OCCUPATION, PHYSICAL, CEREBRAL
      else if (params.view === "OTPhysicalExaminationCerebralPalsy1") {
        switch (params.name) {
          case "OtPhysicalCerebral132":
            viewData["OtPhysicalCerebral129Disabled"] = value;
            viewData["OtPhysicalCerebral129"] = "";
          case "OtPhysicalCerebral180":
            // muscle length checkbox
            viewData["OtPhysicalCerebral169Disabled"] = !value;
            viewData["OtPhysicalCerebral172Disabled"] = !value;
            viewData["OtPhysicalCerebral175Disabled"] = !value;
            viewData["OtPhysicalCerebral178Disabled"] = !value;
            viewData["OtPhysicalCerebral169"] = false;
            viewData["OtPhysicalCerebral172"] = false;
            viewData["OtPhysicalCerebral175"] = false;
            viewData["OtPhysicalCerebral178"] = false;
            // muscle length textbox
            viewData["OtPhysicalCerebral168Disabled"] = true;
            viewData["OtPhysicalCerebral170Disabled"] = true;
            viewData["OtPhysicalCerebral173Disabled"] = true;
            viewData["OtPhysicalCerebral176Disabled"] = true;
            viewData["OtPhysicalCerebral168"] = "";
            viewData["OtPhysicalCerebral170"] = "";
            viewData["OtPhysicalCerebral173"] = "";
            viewData["OtPhysicalCerebral176"] = "";
            break;
          case "OtPhysicalCerebral192":
            // muscle tone checkbox
            viewData["OtPhysicalCerebral184Disabled"] = !value;
            viewData["OtPhysicalCerebral187Disabled"] = !value;
            viewData["OtPhysicalCerebral190Disabled"] = !value;
            viewData["OtPhysicalCerebral184"] = false;
            viewData["OtPhysicalCerebral187"] = false;
            viewData["OtPhysicalCerebral190"] = false;
            // muscle tone textbox
            viewData["OtPhysicalCerebral183Disabled"] = true;
            viewData["OtPhysicalCerebral185Disabled"] = true;
            viewData["OtPhysicalCerebral188Disabled"] = true;
            viewData["OtPhysicalCerebral183"] = "";
            viewData["OtPhysicalCerebral185"] = "";
            viewData["OtPhysicalCerebral188"] = "";
            break;
          case "OtPhysicalCerebral169":
            viewData["OtPhysicalCerebral168"] = "";
            viewData["OtPhysicalCerebral168Disabled"] = value;
            break;
          case "OtPhysicalCerebral172":
            viewData["OtPhysicalCerebral170"] = "";
            viewData["OtPhysicalCerebral170Disabled"] = value;
            break;
          case "OtPhysicalCerebral175":
            viewData["OtPhysicalCerebral173"] = "";
            viewData["OtPhysicalCerebral173Disabled"] = value;
            break;
          case "OtPhysicalCerebral178":
            viewData["OtPhysicalCerebral176"] = "";
            viewData["OtPhysicalCerebral176Disabled"] = value;
            break;
          case "OtPhysicalCerebral184":
            viewData["OtPhysicalCerebral183"] = "";
            viewData["OtPhysicalCerebral183Disabled"] = value;
            break;
          case "OtPhysicalCerebral187":
            viewData["OtPhysicalCerebral185"] = "";
            viewData["OtPhysicalCerebral185Disabled"] = value;
            break;
          case "OtPhysicalCerebral190":
            viewData["OtPhysicalCerebral188"] = "";
            viewData["OtPhysicalCerebral188Disabled"] = value;
            break;
          case "OtPhysicalCerebral197":
            viewData["OtPhysicalCerebral195"] = false;
            viewData["OtPhysicalCerebral195Disabled"] = !value;
            viewData["OtPhysicalCerebral1102"] = "";
            viewData["OtPhysicalCerebral1104"] = "";
            viewData["OtPhysicalCerebral1106"] = "";
            viewData["OtPhysicalCerebral1109"] = "";
            viewData["OtPhysicalCerebral1102Disabled"] = true;
            viewData["OtPhysicalCerebral1104Disabled"] = true;
            viewData["OtPhysicalCerebral1106Disabled"] = true;
            viewData["OtPhysicalCerebral1109Disabled"] = true;
            break;
          case "OtPhysicalCerebral195":
            viewData["OtPhysicalCerebral1102"] = "";
            viewData["OtPhysicalCerebral1104"] = "";
            viewData["OtPhysicalCerebral1106"] = "";
            viewData["OtPhysicalCerebral1109"] = "";
            viewData["OtPhysicalCerebral1102Disabled"] = value;
            viewData["OtPhysicalCerebral1104Disabled"] = value;
            viewData["OtPhysicalCerebral1106Disabled"] = value;
            viewData["OtPhysicalCerebral1109Disabled"] = value;
          default:
            break;
        }
      }

      // PT, Diagnosis Orthopedic
      else if (params.view === "CardPTDiagnosisOrthopedicOPDIPDLOW") {
        switch (params.name) {
          case "PtDiagnosisOrthoOpdipdLow24":
            viewData["PtDiagnosisOrthoOpdipdLow25Disabled"] = !value
            viewData["PtDiagnosisOrthoOpdipdLow26Disabled"] = !value
            viewData["PtDiagnosisOrthoOpdipdLow29Disabled"] = !value
            viewData["PtDiagnosisOrthoOpdipdLow31Disabled"] = !value
            viewData["PtDiagnosisOrthoOpdipdLow33Disabled"] = !value
            viewData["PtDiagnosisOrthoOpdipdLow35Disabled"] = !value
            viewData["PtDiagnosisOrthoOpdipdLow39Disabled"] = !value
            viewData["PtDiagnosisOrthoOpdipdLow41Disabled"] = !value
            viewData["PtDiagnosisOrthoOpdipdLow42Disabled"] = !value
            viewData["PtDiagnosisOrthoOpdipdLow44Disabled"] = !value
            viewData["PtDiagnosisOrthoOpdipdLow46Disabled"] = !value
            viewData["PtDiagnosisOrthoOpdipdLow48Disabled"] = !value
            viewData["PtDiagnosisOrthoOpdipdLow53Disabled"] = !value
            viewData["PtDiagnosisOrthoOpdipdLow25"] = false
            viewData["PtDiagnosisOrthoOpdipdLow26"] = false
            viewData["PtDiagnosisOrthoOpdipdLow29"] = false
            viewData["PtDiagnosisOrthoOpdipdLow31"] = false
            viewData["PtDiagnosisOrthoOpdipdLow33"] = false
            viewData["PtDiagnosisOrthoOpdipdLow35"] = false
            viewData["PtDiagnosisOrthoOpdipdLow39"] = false
            viewData["PtDiagnosisOrthoOpdipdLow41"] = false
            viewData["PtDiagnosisOrthoOpdipdLow42"] = false
            viewData["PtDiagnosisOrthoOpdipdLow44"] = false
            viewData["PtDiagnosisOrthoOpdipdLow46"] = false
            viewData["PtDiagnosisOrthoOpdipdLow48"] = false
            viewData["PtDiagnosisOrthoOpdipdLow53"] = false
            viewData["PtDiagnosisOrthoOpdipdLow40Disabled"] = true
            viewData["PtDiagnosisOrthoOpdipdLow52Disabled"] = true
            viewData["PtDiagnosisOrthoOpdipdLow40"] = ""
            viewData["PtDiagnosisOrthoOpdipdLow52"] = ""
            break;
          case "PtDiagnosisOrthoOpdipdLow22":
            viewData["PtDiagnosisOrthoOpdipdLow21Disabled"] = value;
            break;
          case "PtDiagnosisOrthoOpdipdLow44":
            viewData["PtDiagnosisOrthoOpdipdLow40Disabled"] = value;
            break;
          case "PtDiagnosisOrthoOpdipdLow53":
            viewData["PtDiagnosisOrthoOpdipdLow52Disabled"] = value;
            break;
          case "PtDiagnosisOrthoOpdipdLow57":
            viewData["PtDiagnosisOrthoOpdipdLow55Disabled"] = !value;
            break;
          default:
            break;
        }
      } else if (params.view === "PTObjectiveCardiopulmonaryOPD") {
        switch (params.name) {
          case "PtObjectiveCardioOpd59":
            viewData["PtObjectiveCardioOpd58Disabled"] = value;
            break;
          case "PtObjectiveCardioOpd63":
            viewData["PtObjectiveCardioOpd60Disabled"] = value;
            break;
          case "PtObjectiveCardioOpd66":
            viewData["PtObjectiveCardioOpd64Disabled"] = value;
            break;
          case "PtObjectiveCardioOpd70":
            viewData["PtObjectiveCardioOpd67Disabled"] = value;
            break;
          case "PtObjectiveCardioOpd73":
            viewData["PtObjectiveCardioOpd59Disabled"] = !value;
            viewData["PtObjectiveCardioOpd63Disabled"] = !value;
            viewData["PtObjectiveCardioOpd66Disabled"] = !value;
            viewData["PtObjectiveCardioOpd70Disabled"] = !value;

            viewData["PtObjectiveCardioOpd59"] = false;
            viewData["PtObjectiveCardioOpd63"] = false;
            viewData["PtObjectiveCardioOpd66"] = false;
            viewData["PtObjectiveCardioOpd70"] = false;

            viewData["PtObjectiveCardioOpd58Disabled"] = true;
            viewData["PtObjectiveCardioOpd60Disabled"] = true;
            viewData["PtObjectiveCardioOpd64Disabled"] = true;
            viewData["PtObjectiveCardioOpd67Disabled"] = true;
            break;
          case "PtObjectiveCardioOpd77":
            viewData["PtObjectiveCardioOpd76Disabled"] = value;
            break;
          case "PtObjectiveCardioOpd80":
            viewData["PtObjectiveCardioOpd78Disabled"] = value;
            break;
          case "PtObjectiveCardioOpd84":
            viewData["PtObjectiveCardioOpd81Disabled"] = value;
            break;
          case "PtObjectiveCardioOpd86":
            viewData["PtObjectiveCardioOpd77Disabled"] = !value;
            viewData["PtObjectiveCardioOpd80Disabled"] = !value;
            viewData["PtObjectiveCardioOpd84Disabled"] = !value;

            viewData["PtObjectiveCardioOpd77"] = false;
            viewData["PtObjectiveCardioOpd80"] = false;
            viewData["PtObjectiveCardioOpd84"] = false;

            viewData["PtObjectiveCardioOpd76Disabled"] = true;
            viewData["PtObjectiveCardioOpd78Disabled"] = true;
            viewData["PtObjectiveCardioOpd81Disabled"] = true;
            break;
          case "PtObjectiveCardioOpd89":
            viewData["PtObjectiveCardioOpd97Disabled"] = value;
            viewData["PtObjectiveCardioOpd100Disabled"] = value;
            viewData["PtObjectiveCardioOpd98Disabled"] = value;
            viewData["PtObjectiveCardioOpd101Disabled"] = value;
            break;
          case "PtObjectiveCardioOpd92":
            viewData["PtObjectiveCardioOpd89Disabled"] = !value;

            viewData["PtObjectiveCardioOpd89"] = false;

            viewData["PtObjectiveCardioOpd97Disabled"] = true;
            viewData["PtObjectiveCardioOpd100Disabled"] = true;
            viewData["PtObjectiveCardioOpd98Disabled"] = true;
            viewData["PtObjectiveCardioOpd101Disabled"] = true;
            break;
          default:
            break;
        }
      } else if (params.view === this.state.activeMenu + "PTOTSubjectiveLOW") {
        switch (params.name) {
          case "PtOtSubjectiveLow2":
            viewData["PtOtSubjectiveLow3"] = false;
            viewData["PtOtSubjectiveLow4"] = false;
            viewData["PtOtSubjectiveLow7"] = false;
            viewData["PtOtSubjectiveLow9"] = false;
            viewData["PtOtSubjectiveLow11"] = false;
            viewData["PtOtSubjectiveLow13"] = false;
            viewData["PtOtSubjectiveLow17"] = false;
            viewData["PtOtSubjectiveLow19"] = false;
            viewData["PtOtSubjectiveLow20"] = false;
            viewData["PtOtSubjectiveLow22"] = false;
            viewData["PtOtSubjectiveLow24"] = false;
            viewData["PtOtSubjectiveLow26"] = false;
            viewData["PtOtSubjectiveLow32"] = false;
            viewData["PtOtSubjectiveLow3Disabled"] = !value;
            viewData["PtOtSubjectiveLow4Disabled"] = !value;
            viewData["PtOtSubjectiveLow7Disabled"] = !value;
            viewData["PtOtSubjectiveLow9Disabled"] = !value;
            viewData["PtOtSubjectiveLow11Disabled"] = !value;
            viewData["PtOtSubjectiveLow13Disabled"] = !value;
            viewData["PtOtSubjectiveLow17Disabled"] = !value;
            viewData["PtOtSubjectiveLow19Disabled"] = !value;
            viewData["PtOtSubjectiveLow20Disabled"] = !value;
            viewData["PtOtSubjectiveLow22Disabled"] = !value;
            viewData["PtOtSubjectiveLow24Disabled"] = !value;
            viewData["PtOtSubjectiveLow26Disabled"] = !value;
            viewData["PtOtSubjectiveLow32Disabled"] = !value;
            viewData["PtOtSubjectiveLow18Disabled"] = true;
            viewData["PtOtSubjectiveLow18"] = "";
            viewData["PtOtSubjectiveLow31Disabled"] = true;
            viewData["PtOtSubjectiveLow31"] = "";
            
            break;
          case "PtOtSubjectiveLow22":
            viewData["PtOtSubjectiveLow18Disabled"] = value;
            viewData["PtOtSubjectiveLow18"] = "";
            break;
          case "PtOtSubjectiveLow32":
            viewData["PtOtSubjectiveLow31Disabled"] = value;
            viewData["PtOtSubjectiveLow31"] = "";
            break;
          default:
            break;
        }
      } else if (params.view === "PTObjectiveNeuro") {
        switch (params.name) {
          case "PtObjectiveNeuro26":
            viewData["PtObjectiveNeuro24Disabled"] = value;
            break;
          case "PtObjectiveNeuro29":
            viewData["PtObjectiveNeuro25Disabled"] = value;
            break;
          case "PtObjectiveNeuro32":
            viewData["PtObjectiveNeuro28Disabled"] = value;
            break;
          case "PtObjectiveNeuro34":
            viewData["PtObjectiveNeuro30Disabled"] = value;
            break;
          case "PtObjectiveNeuro36":
            viewData["PtObjectiveNeuro26Disabled"] = !value;
            viewData["PtObjectiveNeuro29Disabled"] = !value;
            viewData["PtObjectiveNeuro32Disabled"] = !value;
            viewData["PtObjectiveNeuro34Disabled"] = !value;

            viewData["PtObjectiveNeuro26"] = false;
            viewData["PtObjectiveNeuro29"] = false;
            viewData["PtObjectiveNeuro32"] = false;
            viewData["PtObjectiveNeuro34"] = false;

            viewData["PtObjectiveNeuro24Disabled"] = true;
            viewData["PtObjectiveNeuro25Disabled"] = true;
            viewData["PtObjectiveNeuro28Disabled"] = true;
            viewData["PtObjectiveNeuro30Disabled"] = true;
            break;
          case "PtObjectiveNeuro41":
            viewData["PtObjectiveNeuro39Disabled"] = value;
            break;
          case "PtObjectiveNeuro44":
            viewData["PtObjectiveNeuro40Disabled"] = value;
            break;
          case "PtObjectiveNeuro46":
            viewData["PtObjectiveNeuro43Disabled"] = value;
            break;
          case "PtObjectiveNeuro48":
            viewData["PtObjectiveNeuro41Disabled"] = !value;
            viewData["PtObjectiveNeuro44Disabled"] = !value;
            viewData["PtObjectiveNeuro46Disabled"] = !value;

            viewData["PtObjectiveNeuro41"] = false;
            viewData["PtObjectiveNeuro44"] = false;
            viewData["PtObjectiveNeuro46"] = false;

            viewData["PtObjectiveNeuro39Disabled"] = true;
            viewData["PtObjectiveNeuro40Disabled"] = true;
            viewData["PtObjectiveNeuro43Disabled"] = true;
            break;
          case "PtObjectiveNeuro51":
            viewData["PtObjectiveNeuro57Disabled"] = value;
            viewData["PtObjectiveNeuro58Disabled"] = value;
            viewData["PtObjectiveNeuro59Disabled"] = value;
            viewData["PtObjectiveNeuro98Disabled"] = value;
            break;
          case "PtObjectiveNeuro53":
            viewData["PtObjectiveNeuro51Disabled"] = !value;
            viewData["PtObjectiveNeuro51"] = false;

            viewData["PtObjectiveNeuro57Disabled"] = true;
            viewData["PtObjectiveNeuro58Disabled"] = true;
            viewData["PtObjectiveNeuro59Disabled"] = true;
            viewData["PtObjectiveNeuro98Disabled"] = true;
            break;
          default:
            break;
        }
      } else if (params.view === "PTObjectiveOrthoTOP") {
        switch (params.name) {
          case "PtObjectiveOrthoTop21":
            viewData["PtObjectiveOrthoTop19Disabled"] = value;
            break;
          case "PtObjectiveOrthoTop24":
            viewData["PtObjectiveOrthoTop20Disabled"] = value;
            break;
          case "PtObjectiveOrthoTop27":
            viewData["PtObjectiveOrthoTop23Disabled"] = value;
            break;
          case "PtObjectiveOrthoTop29":
            viewData["PtObjectiveOrthoTop25Disabled"] = value;
            break;
          case "PtObjectiveOrthoTop31":
            viewData["PtObjectiveOrthoTop21Disabled"] = !value;
            viewData["PtObjectiveOrthoTop24Disabled"] = !value;
            viewData["PtObjectiveOrthoTop27Disabled"] = !value;
            viewData["PtObjectiveOrthoTop29Disabled"] = !value;

            viewData["PtObjectiveOrthoTop21"] = false;
            viewData["PtObjectiveOrthoTop24"] = false;
            viewData["PtObjectiveOrthoTop27"] = false;
            viewData["PtObjectiveOrthoTop29"] = false;

            viewData["PtObjectiveOrthoTop19Disabled"] = true;
            viewData["PtObjectiveOrthoTop20Disabled"] = true;
            viewData["PtObjectiveOrthoTop23Disabled"] = true;
            viewData["PtObjectiveOrthoTop25Disabled"] = true;
            break;
          case "PtObjectiveOrthoTop39":
            viewData["PtObjectiveOrthoTop38Disabled"] = value;
            break;
          case "PtObjectiveOrthoTop42":
            viewData["PtObjectiveOrthoTop40Disabled"] = value;
            break;
          case "PtObjectiveOrthoTop45":
            viewData["PtObjectiveOrthoTop43Disabled"] = value;
            break;
          case "PtObjectiveOrthoTop47":
            viewData["PtObjectiveOrthoTop39Disabled"] = !value;
            viewData["PtObjectiveOrthoTop42Disabled"] = !value;
            viewData["PtObjectiveOrthoTop45Disabled"] = !value;

            viewData["PtObjectiveOrthoTop39"] = false;
            viewData["PtObjectiveOrthoTop42"] = false;
            viewData["PtObjectiveOrthoTop45"] = false;

            viewData["PtObjectiveOrthoTop38Disabled"] = true;
            viewData["PtObjectiveOrthoTop40Disabled"] = true;
            viewData["PtObjectiveOrthoTop43Disabled"] = true;
            break;
          default:
            break;
        }
      } else if (params.view === "PTprogressionnoteOPDOrtho") {
        switch (params.name) {
          case "PtProgressnoteOpdOrtho7":
            viewData["PtProgressnoteOpdOrtho8Disabled"] = value;
            break;
          case "PtProgressnoteOpdOrtho11":
            viewData["PtProgressnoteOpdOrtho10Disabled"] = value;
            break;
          case "PtProgressnoteOpdOrtho13":
            viewData["PtProgressnoteOpdOrtho14Disabled"] = value;
            break;
          case "PtProgressnoteOpdOrtho16":
            viewData["PtProgressnoteOpdOrtho17Disabled"] = value;
            break;
          case "PtProgressnoteOpdOrtho19":
            viewData["PtProgressnoteOpdOrtho20Disabled"] = value;
            break;
          case "PtProgressnoteOpdOrtho23":
            viewData["PtProgressnoteOpdOrtho21Disabled"] = value;
            break;
          case "PtProgressnoteOpdOrtho25":
            viewData["PtProgressnoteOpdOrtho26Disabled"] = value;
            break;
          case "PtProgressnoteOpdOrtho28":
            viewData["PtProgressnoteOpdOrtho29Disabled"] = value;
            break;
          case "PtProgressnoteOpdOrtho31":
            viewData["PtProgressnoteOpdOrtho32Disabled"] = value;
            break;
          case "PtProgressnoteOpdOrtho34":
            viewData["PtProgressnoteOpdOrtho35Disabled"] = value;
            break;
          case "PtProgressnoteOpdOrtho37":
            viewData["PtProgressnoteOpdOrtho38Disabled"] = value;
            break;
          case "PtProgressnoteOpdOrtho40":
            viewData["PtProgressnoteOpdOrtho41Disabled"] = value;
            break;
          case "PtProgressnoteOpdOrtho43":
            viewData["PtProgressnoteOpdOrtho44Disabled"] = value;
            break;
          case "PtProgressnoteOpdOrtho46":
            viewData["PtProgressnoteOpdOrtho47Disabled"] = value;
            break;
          case "PtProgressnoteOpdOrtho49":
            viewData["PtProgressnoteOpdOrtho50Disabled"] = value;
            break;
          case "PtProgressnoteOpdOrtho52":
            viewData["PtProgressnoteOpdOrtho53Disabled"] = value;
            break;
          case "PtProgressnoteOpdOrtho58":
            viewData["PtProgressnoteOpdOrtho55Disabled"] = value;
            break;
          default:
            break;
        }
      } else if (
        params.view === "POPhysicalexaminationOrthoticFootOrthotic2TOP"
      ) {
        switch (params.name) {
          case "PoPhysicalOrthoticFootOrthotic2Top68":
            viewData["PoPhysicalOrthoticFootOrthotic2Top64Disabled"] = value;
            break;
          case "PoPhysicalOrthoticFootOrthotic2Top70":
            viewData["PoPhysicalOrthoticFootOrthotic2Top65Disabled"] = value;
            break;
          case "PoPhysicalOrthoticFootOrthotic2Top72":
            viewData["PoPhysicalOrthoticFootOrthotic2Top66Disabled"] = value;
            break;
          case "PoPhysicalOrthoticFootOrthotic2Top74":
            viewData["PoPhysicalOrthoticFootOrthotic2Top67Disabled"] = value;
            break;
          case "PoPhysicalOrthoticFootOrthotic2Top76":
            viewData["PoPhysicalOrthoticFootOrthotic2Top68Disabled"] = !value;
            viewData["PoPhysicalOrthoticFootOrthotic2Top70Disabled"] = !value;
            viewData["PoPhysicalOrthoticFootOrthotic2Top72Disabled"] = !value;
            viewData["PoPhysicalOrthoticFootOrthotic2Top74Disabled"] = !value;
            viewData["PoPhysicalOrthoticFootOrthotic2Top68"] = false;
            viewData["PoPhysicalOrthoticFootOrthotic2Top70"] = false;
            viewData["PoPhysicalOrthoticFootOrthotic2Top72"] = false;
            viewData["PoPhysicalOrthoticFootOrthotic2Top74"] = false;
            // text box
            viewData["PoPhysicalOrthoticFootOrthotic2Top64Disabled"] = true;
            viewData["PoPhysicalOrthoticFootOrthotic2Top65Disabled"] = true;
            viewData["PoPhysicalOrthoticFootOrthotic2Top66Disabled"] = true;
            viewData["PoPhysicalOrthoticFootOrthotic2Top67Disabled"] = true;
            viewData["PoPhysicalOrthoticFootOrthotic2Top64"] = "";
            viewData["PoPhysicalOrthoticFootOrthotic2Top65"] = "";
            viewData["PoPhysicalOrthoticFootOrthotic2Top66"] = "";
            viewData["PoPhysicalOrthoticFootOrthotic2Top67"] = "";
            break;
          default:
            break;
        }
      } else if (params.view === "CardOTObjectivePhysicalDysfunction1") {
        switch (params.name) {
          case "CardOtObjectivePhysicalDysfunction146":
            viewData["CardOtObjectivePhysicalDysfunction139Disabled"] = value;
            break;
          case "CardOtObjectivePhysicalDysfunction168":
            viewData["CardOtObjectivePhysicalDysfunction179Disabled"] = value;
            viewData["CardOtObjectivePhysicalDysfunction182Disabled"] = value;
            viewData["CardOtObjectivePhysicalDysfunction183Disabled"] = value;
            viewData["CardOtObjectivePhysicalDysfunction189Disabled"] = value;
            break;
          case "CardOtObjectivePhysicalDysfunction171":
            viewData["CardOtObjectivePhysicalDysfunction168Disabled"] = !value;
            viewData["CardOtObjectivePhysicalDysfunction168"] = false;

            viewData["CardOtObjectivePhysicalDysfunction179Disabled"] = true;
            viewData["CardOtObjectivePhysicalDysfunction182Disabled"] = true;
            viewData["CardOtObjectivePhysicalDysfunction183Disabled"] = true;
            viewData["CardOtObjectivePhysicalDysfunction189Disabled"] = true;
            break;
          case "CardOtObjectivePhysicalDysfunction198":
            viewData["CardOtObjectivePhysicalDysfunction196Disabled"] = value;
            break;
          case "CardOtObjectivePhysicalDysfunction1101":
            viewData["CardOtObjectivePhysicalDysfunction197Disabled"] = value;
            break;
          case "CardOtObjectivePhysicalDysfunction1104":
            viewData["CardOtObjectivePhysicalDysfunction1100Disabled"] = value;
            break;
          case "CardOtObjectivePhysicalDysfunction1106":
            viewData["CardOtObjectivePhysicalDysfunction198Disabled"] = !value;
            viewData["CardOtObjectivePhysicalDysfunction1101Disabled"] = !value;
            viewData["CardOtObjectivePhysicalDysfunction1104Disabled"] = !value;

            viewData["CardOtObjectivePhysicalDysfunction198"] = false;
            viewData["CardOtObjectivePhysicalDysfunction1101"] = false;
            viewData["CardOtObjectivePhysicalDysfunction1104"] = false;

            viewData["CardOtObjectivePhysicalDysfunction196Disabled"] = true;
            viewData["CardOtObjectivePhysicalDysfunction197Disabled"] = true;
            viewData["CardOtObjectivePhysicalDysfunction1100Disabled"] = true;
            break;
          default:
            break;
        }
      } else if (params.view === "CardOTObjectivePediatrics") {
        switch (params.name) {
          case "CardOtObjectivePediatrics44":
            viewData["CardOtObjectivePediatrics36Disabled"] = value;
            break;
          default:
            break;
        }
      } else if (
        params.view === "OTPhysicalExaminationPhysicalDysfunctionHandFunction"
      ) {
        switch (params.name) {
          case "OtPhysicalDysfunctionHandFunction68":
            viewData["OtPhysicalDysfunctionHandFunction65Disabled"] = value;
            break;
          case "OtPhysicalDysfunctionHandFunction71":
            viewData["OtPhysicalDysfunctionHandFunction67Disabled"] = value;
            break;
          case "OtPhysicalDysfunctionHandFunction78":
            viewData["OtPhysicalDysfunctionHandFunction73Disabled"] = value;
            break;
          case "OtPhysicalDysfunctionHandFunction82":
            viewData["OtPhysicalDysfunctionHandFunction76Disabled"] = value;
            break;
          case "OtPhysicalDysfunctionHandFunction88":
            viewData["OtPhysicalDysfunctionHandFunction84Disabled"] = value;
            break;
          case "OtPhysicalDysfunctionHandFunction92":
            viewData["OtPhysicalDysfunctionHandFunction86Disabled"] = value;
            break;
          default:
            break;
        }
      } else if (params.view === "OTProgressionNoteOPDDysfunction") {
        switch (params.name) {
          case "OtProgressnoteOpdDysfunction14":
            viewData["OtProgressnoteOpdDysfunction11Disabled"] = value;
            break;
          default:
            break;
        }
      } else if (
        params.view === "PORehabilitationTreatmentOrthoticFittingandCheckout"
      ) {
        switch (params.name) {
          case "PoRehabilitationTreatmentOrthoticFittingCheckout24":
            viewData[
              "PoRehabilitationTreatmentOrthoticFittingCheckout23Disabled"
            ] = value;
            break;
          default:
            break;
        }
      } else if (
        params.view ===
        "CardOTPhysicalExaminationPhysicalDysfunctionActivitiesOfDailyLiving"
      ) {
        switch (params.name) {
          case "OtPhysicalDysfunctionActivityOfDailyLiving3":
            viewData["Ct2Grooming_oralDisabled"] = !value;
            viewData[
              "OtPhysicalDysfunctionActivityOfDailyLiving9Disabled"
            ] = !value;
            viewData["Ct2FeedingEatingDisabled"] = !value;
            viewData[
              "OtPhysicalDysfunctionActivityOfDailyLiving14Disabled"
            ] = !value;
            viewData["Ct2DressingUpperDisabled"] = !value;
            viewData[
              "OtPhysicalDysfunctionActivityOfDailyLiving19Disabled"
            ] = !value;
            viewData["Ct2DressingLowerDisabled"] = !value;
            viewData[
              "OtPhysicalDysfunctionActivityOfDailyLiving24Disabled"
            ] = !value;
            viewData["Ct2TransferBedDisabled"] = !value;
            viewData[
              "OtPhysicalDysfunctionActivityOfDailyLiving30Disabled"
            ] = !value;
            viewData["Ct2TransferToiletDisabled"] = !value;
            viewData[
              "OtPhysicalDysfunctionActivityOfDailyLiving36Disabled"
            ] = !value;
            viewData["Ct2BedMobilityDisabled"] = !value;
            viewData[
              "OtPhysicalDysfunctionActivityOfDailyLiving41Disabled"
            ] = !value;
            viewData["Ct2BathingDisabled"] = !value;
            viewData[
              "OtPhysicalDysfunctionActivityOfDailyLiving46Disabled"
            ] = !value;
            viewData["Ct2ToiletingDisabled"] = !value;
            viewData[
              "OtPhysicalDysfunctionActivityOfDailyLiving51Disabled"
            ] = !value;
            viewData["Ct2OtherDisabled"] = !value;
            viewData[
              "OtPhysicalDysfunctionActivityOfDailyLiving56Disabled"
            ] = !value;
        }
      }
      this.setViewData(params.view, viewData);
    } else if (message === "clickButton" && params.name === "SaveRequest") {
      this.setState({ isLoading: true });
      if (this.props.isOpenAssessment) {
        // Assessment Flow
        let data = this.collectAssessment();
        //Check Require Field
        let check_require_data = this.handleValidateData(data, params.view);
        if (!check_require_data) {
          return;
        }
        // tab Pain Assessment
        try {
          data.AssessmentPainAssessmentViewData = {
            viewData: this.assessmentCardPainAssessmentRef.current.getData(),
          };
        } catch (err) {}
        try {
          data.AnkleFootAssessmentPainAssessmentViewData = {
            viewData: this.ankleFootAssessmentCardPainAssessmentRef.current.getData(),
          };
        } catch (err) {}
        try {
          data.OrthoticFootAssessmentPainAssessmentViewData = {
            viewData: this.orthoticFootAssessmentCardPainAssessmentRef.current.getData(),
          };
        } catch (err) {}

        // tab Physical Examination
        try {
          data.CardPTPhysicalExamOrthopedicMIDViewData = {
            viewData: this.cardPTPhysicalExamOrthopedicMIDRef.current.getData(),
          };
        } catch (err) {}
        try {
          data.CardPTPhysicalExamCardiopulmonaryMIDViewData = {
            viewData: this.cardPTPhysicalExamCardioMIDRef.current.getData(),
          };
        } catch (err) {}
        try {
          data.CardPTPhysicalExamNeuroogialMIDViewData = {
            viewData: this.cardPTPhysicalExamNeuroogialMIDRef.current.getData(),
          };
        } catch (err) {}
        try {
          data.CardPOPhysicalExamOrthoticFittingAndCheckoutMIDViewData = {
            viewData: this.cardPOPhysicalExamOrthoticFittingAndCheckoutMIDRef.current.getData(),
          };
        } catch (err) {}
        try {
          data.CardPOPhysicalExamAnkleFootOrthosisMIDViewData = {
            viewData: this.cardPOPhysicalExamAnkleFootOrthosisMIDRef.current.getData(),
          };
        } catch (err) {}
        try {
          data.CardPOPhysicalExamOrthoticFootOrthoticMIDViewData = {
            viewData: this.cardPOPhysicalExamOrthoticFootOrthoticMIDRef.current.getData(),
          };
        } catch (err) {}
        try {
          data.CardMultiSelectPositionViewData = {
            viewData: this.cardMultiSelectPositionRef.current.getData(),
          };
        } catch (err) {}
        try {
          data.CardOTPhysicalExaminationCerebralPalsyMIDViewData = {
            viewData: this.cardOTPhysicalExaminationCerebralPalsyMIDRef.current.getData(),
          };
        } catch (err) {}
        try {
          data.CardOTPhysicalExaminationPhysicalDysfunctionSensorimotorFunctionViewData = {
            viewData: this.cardOTPhysicalExaminationPhysicalDysfunctionSensorimotorFunctionRef.current.getData(),
          };
        } catch (err) {
          console.log(err);
        }
        data.LockMenu = this.state.activeMenu;
        const [r, error, n] = await TECSessionDetail.patch({
          pk: this.props.sessionId,
          data: {
            assessment: JSON.stringify(data),
          },
        });
        if (error) {
          toast.error(<ErrorMessage error={error} />);
        }
        toast.success("Success");
        this.updateData();
      } else {
        // Progression Note Flow
        const data = this.collectProgressionNote();

        let check_require_data = this.handleValidateData(data, params.view);
        if (!check_require_data) {
          return;
        }

        // tab Objective
        try {
          data.CardPTObjectiveOrthoLOWViewData = {
            viewData: this.cardPTObjectiveOrthoLOWRef.current.getData(),
          };
        } catch (err) {}

        // tab Pain Assessment
        try {
          data[this.state.activeMenu + "ProgressPainAssessmentViewData"] = {
            viewData: this.progressCardPainAssessmentRef.current.getData(),
          };
        } catch (err) {}

        data.LockMenu = this.state.activeMenu;
        const [r, error, n] = await TECSessionDetail.patch({
          pk: this.props.sessionId,
          data: {
            progression_note: JSON.stringify(data),
          },
        });
        if (error) {
          this.setState({ error, openModError: true });
          // toast.error(<ErrorMessage error={error} />);
        }
        toast.success("Success");
        this.updateData();
      }
      this.setState({ isLoading: false });
    } else if (
      message === "clickButton" &&
      params.name === "OtPhysicalSwallowing227"
    ) {
      this.setState({
        isShowRemark: true,
      });
    }
  };

  handleValidateData = (data: any, view: string) => {

    // console.log("handleValidateData view ", view, "this.state.activeMenu ", this.state.activeMenu , "data", data)

    if (view === "CardPTDiagnosisOrthopedicOPDIPDLOW") {
      // ORTHOPEDIC OPD/IPD
      let diagnosis_pt_data = data["CardPTDiagnosisOrthopedicOPDIPDLOWViewData"];
      if (diagnosis_pt_data) {
        if (!diagnosis_pt_data["PtDiagnosisOrthoOpdipdLow2"]) {
          toast.error(
            <ErrorMessage error="กรุณาระบุ Chief complaint ของ Diagnosis" />
          );
          this.setState({ isLoading: false });
          return false;
        }
        if (
          !diagnosis_pt_data["PtDiagnosisOrthoOpdipdLow7"] &&
          !diagnosis_pt_data["PtDiagnosisOrthoOpdipdLow8"] &&
          !diagnosis_pt_data["PtDiagnosisOrthoOpdipdLow10"] &&
          !diagnosis_pt_data["PtDiagnosisOrthoOpdipdLow12"] &&
          !diagnosis_pt_data["PtDiagnosisOrthoOpdipdLow15"] &&
          !diagnosis_pt_data["PtDiagnosisOrthoOpdipdLow17"] &&
          !diagnosis_pt_data["PtDiagnosisOrthoOpdipdLow22"]
        ) {
          toast.error(
            <ErrorMessage error="กรุณาเลือก Consult for ของ Diagnosis" />
          );
          this.setState({ isLoading: false });
          return false;
        }
        if (
          !diagnosis_pt_data["PtDiagnosisOrthoOpdipdLow24"] &&
          !diagnosis_pt_data["PtDiagnosisOrthoOpdipdLow25"] &&
          !diagnosis_pt_data["PtDiagnosisOrthoOpdipdLow26"] &&
          !diagnosis_pt_data["PtDiagnosisOrthoOpdipdLow29"] &&
          !diagnosis_pt_data["PtDiagnosisOrthoOpdipdLow31"] &&
          !diagnosis_pt_data["PtDiagnosisOrthoOpdipdLow33"] &&
          !diagnosis_pt_data["PtDiagnosisOrthoOpdipdLow35"] &&
          !diagnosis_pt_data["PtDiagnosisOrthoOpdipdLow39"] &&
          !diagnosis_pt_data["PtDiagnosisOrthoOpdipdLow41"] &&
          !diagnosis_pt_data["PtDiagnosisOrthoOpdipdLow42"] &&
          !diagnosis_pt_data["PtDiagnosisOrthoOpdipdLow44"] &&
          !diagnosis_pt_data["PtDiagnosisOrthoOpdipdLow46"] &&
          !diagnosis_pt_data["PtDiagnosisOrthoOpdipdLow48"] &&
          !diagnosis_pt_data["PtDiagnosisOrthoOpdipdLow53"]
        ) {
          toast.error(
            <ErrorMessage error="กรุณาเลือก Precaution ของ Diagnosis" />
          );
          this.setState({ isLoading: false });
          return false;
        }
      }
    } else if (view === this.state.activeMenu + "CardPODiagnosisOrthoticfootPremorbidIndependentLOW" ) {
      // PO Orthotic FOOT
      let diagnosis_pt_data =
        data[
          this.state.activeMenu +
            "CardPODiagnosisOrthoticfootPremorbidIndependentLOWViewData"
        ];
      if (diagnosis_pt_data) {
        if (!diagnosis_pt_data["PoDiagnosisOrthofootLow2"]) {
          toast.error(
            <ErrorMessage error="กรุณาระบุ Chief complaint ของ Diagnosis" />
          );
          this.setState({ isLoading: false });
          return false;
        }
        if (!diagnosis_pt_data["PoDiagnosisOrthofootLow8"]) {
          toast.error(
            <ErrorMessage error="กรุณาเลือก Consult for ของ Diagnosis" />
          );
          this.setState({ isLoading: false });
          return false;
        }
        if (
          !diagnosis_pt_data["PoDiagnosisOrthofootLow11"] &&
          !diagnosis_pt_data["PoDiagnosisOrthofootLow12"] &&
          !diagnosis_pt_data["PoDiagnosisOrthofootLow13"] &&
          !diagnosis_pt_data["PoDiagnosisOrthofootLow15"] &&
          !diagnosis_pt_data["PoDiagnosisOrthofootLow18"] &&
          !diagnosis_pt_data["PoDiagnosisOrthofootLow22"] &&
          !diagnosis_pt_data["PoDiagnosisOrthofootLow24"] &&
          !diagnosis_pt_data["PoDiagnosisOrthofootLow29"] &&
          !diagnosis_pt_data["PoDiagnosisOrthofootLow30"] &&
          !diagnosis_pt_data["PoDiagnosisOrthofootLow32"] &&
          !diagnosis_pt_data["PoDiagnosisOrthofootLow34"] &&
          !diagnosis_pt_data["PoDiagnosisOrthofootLow36"] &&
          !diagnosis_pt_data["PoDiagnosisOrthofootLow38"] &&
          !diagnosis_pt_data["PoDiagnosisOrthofootLow43"] &&
          !diagnosis_pt_data["PoDiagnosisOrthofootLow44"]
        ) {
          toast.error(
            <ErrorMessage error="กรุณาเลือก Precaution ของ Diagnosis" />
          );
          this.setState({ isLoading: false });
          return false;
        }
      }
    } else if (view === "CardOTDiagnosisPhysicalDysfunctionLOW") {
      // OTHO PHYSICAL DYSFUNCTION
      let diagnosis_ot_data = data["CardOTDiagnosisPhysicalDysfunctionLOWViewData"];
      if (diagnosis_ot_data) {
        if (!diagnosis_ot_data["OtDiagnosisPhysicalDysfunctionLow3"]) {
          toast.error(
            <ErrorMessage error="กรุณาระบุ Chief complaint ของ Diagnosis" />
          );
          this.setState({ isLoading: false });
          return false;
        }
        if (
          !diagnosis_ot_data["OtDiagnosisPhysicalDysfunctionLow7"] &&
          !diagnosis_ot_data["OtDiagnosisPhysicalDysfunctionLow8"] &&
          !diagnosis_ot_data["OtDiagnosisPhysicalDysfunctionLow10"] &&
          !diagnosis_ot_data["OtDiagnosisPhysicalDysfunctionLow12"] &&
          !diagnosis_ot_data["OtDiagnosisPhysicalDysfunctionLow15"] &&
          !diagnosis_ot_data["OtDiagnosisPhysicalDysfunctionLow16"] &&
          !diagnosis_ot_data["OtDiagnosisPhysicalDysfunctionLow20"] &&
          !diagnosis_ot_data["OtDiagnosisPhysicalDysfunctionLow22"] &&
          !diagnosis_ot_data["OtDiagnosisPhysicalDysfunctionLow23"] &&
          !diagnosis_ot_data["OtDiagnosisPhysicalDysfunctionLow24"]
        ) {
          toast.error(
            <ErrorMessage error="กรุณาเลือก Consult for ของ Diagnosis" />
          );
          this.setState({ isLoading: false });
          return false;
        }
        if (
          !diagnosis_ot_data["OtDiagnosisPhysicalDysfunctionLow29"] &&
          !diagnosis_ot_data["OtDiagnosisPhysicalDysfunctionLow30"] &&
          !diagnosis_ot_data["OtDiagnosisPhysicalDysfunctionLow31"] &&
          !diagnosis_ot_data["OtDiagnosisPhysicalDysfunctionLow33"] &&
          !diagnosis_ot_data["OtDiagnosisPhysicalDysfunctionLow36"] &&
          !diagnosis_ot_data["OtDiagnosisPhysicalDysfunctionLow37"] &&
          !diagnosis_ot_data["OtDiagnosisPhysicalDysfunctionLow39"] &&
          !diagnosis_ot_data["OtDiagnosisPhysicalDysfunctionLow44"] &&
          !diagnosis_ot_data["OtDiagnosisPhysicalDysfunctionLow46"] &&
          !diagnosis_ot_data["OtDiagnosisPhysicalDysfunctionLow47"] &&
          !diagnosis_ot_data["OtDiagnosisPhysicalDysfunctionLow49"] &&
          !diagnosis_ot_data["OtDiagnosisPhysicalDysfunctionLow51"] &&
          !diagnosis_ot_data["OtDiagnosisPhysicalDysfunctionLow52"] &&
          !diagnosis_ot_data["OtDiagnosisPhysicalDysfunctionLow58"]
        ) {
          toast.error(
            <ErrorMessage error="กรุณาเลือก Precaution ของ Diagnosis" />
          );
          this.setState({ isLoading: false });
          return false;
        }
      }
    } else if (view === "CardOTDiagnosisSwallowingEvaluationLOW") {
      // OT SWALLOWING EVALUATION
      let diagnosis_ot_data =
        data["CardOTDiagnosisSwallowingEvaluationLOWViewData"];
      if (diagnosis_ot_data) {
        if (!diagnosis_ot_data["OtDiagnosisSwallowingLow2"]) {
          toast.error(
            <ErrorMessage error="กรุณาระบุ Chief complaint ของ Diagnosis" />
          );
          this.setState({ isLoading: false });
          return false;
        }
        if (!diagnosis_ot_data["OtDiagnosisSwallowingLow7"]) {
          toast.error(
            <ErrorMessage error="กรุณาเลือก Consult for ของ Diagnosis" />
          );
          this.setState({ isLoading: false });
          return false;
        }
        if (
          !diagnosis_ot_data["OtDiagnosisSwallowingLow29"] &&
          !diagnosis_ot_data["OtDiagnosisSwallowingLow30"] &&
          !diagnosis_ot_data["OtDiagnosisSwallowingLow31"] &&
          !diagnosis_ot_data["OtDiagnosisSwallowingLow34"] &&
          !diagnosis_ot_data["OtDiagnosisSwallowingLow36"] &&
          !diagnosis_ot_data["OtDiagnosisSwallowingLow38"] &&
          !diagnosis_ot_data["OtDiagnosisSwallowingLow40"] &&
          !diagnosis_ot_data["OtDiagnosisSwallowingLow44"] &&
          !diagnosis_ot_data["OtDiagnosisSwallowingLow46"] &&
          !diagnosis_ot_data["OtDiagnosisSwallowingLow47"] &&
          !diagnosis_ot_data["OtDiagnosisSwallowingLow50"] &&
          !diagnosis_ot_data["OtDiagnosisSwallowingLow52"] &&
          !diagnosis_ot_data["OtDiagnosisSwallowingLow54"] &&
          !diagnosis_ot_data["OtDiagnosisSwallowingLow59"]
        ) {
          toast.error(
            <ErrorMessage error="กรุณาเลือก Precaution ของ Diagnosis" />
          );
          this.setState({ isLoading: false });
          return false;
        }
      }
    } else if (view === "CardOTDiagnosisPediatricsLOW") {
      // OT PREDIATIC EVALUATION
      let diagnosis_ot_data = data["CardOTDiagnosisPediatricsLOWViewData"];
      if (diagnosis_ot_data) {
        if (!diagnosis_ot_data["OtDiagnosisPediatricsLow2"]) {
          toast.error(
            <ErrorMessage error="กรุณาระบุ Chief complaint ของ Diagnosis" />
          );
          this.setState({ isLoading: false });
          return false;
        }
        if (
          !diagnosis_ot_data["OtDiagnosisPediatricsLow7"] &&
          !diagnosis_ot_data["OtDiagnosisPediatricsLow8"] &&
          !diagnosis_ot_data["OtDiagnosisPediatricsLow11"] &&
          !diagnosis_ot_data["OtDiagnosisPediatricsLow14"] &&
          !diagnosis_ot_data["OtDiagnosisPediatricsLow18"] &&
          !diagnosis_ot_data["OtDiagnosisPediatricsLow20"] &&
          !diagnosis_ot_data["OtDiagnosisPediatricsLow24"] &&
          !diagnosis_ot_data["OtDiagnosisPediatricsLow25"] &&
          !diagnosis_ot_data["OtDiagnosisPediatricsLow27"] &&
          !diagnosis_ot_data["OtDiagnosisPediatricsLow28"]
        ) {
          toast.error(
            <ErrorMessage error="กรุณาเลือก Consult for ของ Diagnosis" />
          );
          this.setState({ isLoading: false });
          return false;
        }
        if (
          !diagnosis_ot_data["OtDiagnosisPediatricsLow35"] &&
          !diagnosis_ot_data["OtDiagnosisPediatricsLow36"] &&
          !diagnosis_ot_data["OtDiagnosisPediatricsLow37"] &&
          !diagnosis_ot_data["OtDiagnosisPediatricsLow40"] &&
          !diagnosis_ot_data["OtDiagnosisPediatricsLow42"] &&
          !diagnosis_ot_data["OtDiagnosisPediatricsLow45"] &&
          !diagnosis_ot_data["OtDiagnosisPediatricsLow47"] &&
          !diagnosis_ot_data["OtDiagnosisPediatricsLow52"] &&
          !diagnosis_ot_data["OtDiagnosisPediatricsLow53"] &&
          !diagnosis_ot_data["OtDiagnosisPediatricsLow55"] &&
          !diagnosis_ot_data["OtDiagnosisPediatricsLow57"] &&
          !diagnosis_ot_data["OtDiagnosisPediatricsLow58"] &&
          !diagnosis_ot_data["OtDiagnosisPediatricsLow60"] &&
          !diagnosis_ot_data["OtDiagnosisPediatricsLow67"]
        ) {
          toast.error(
            <ErrorMessage error="กรุณาเลือก Precaution ของ Diagnosis" />
          );
          this.setState({ isLoading: false });
          return false;
        }
      }
    } else if (view === "CardOTDiagnosisCerebralPalsyLOW") {
      // OT CEREBRAL PALSY
      let diagnosis_ot_data = data["CardOTDiagnosisCerebralPalsyLOWViewData"];
      if (diagnosis_ot_data) {
        if (!diagnosis_ot_data["OtDiagnosisCerebralLow2"]) {
          toast.error(
            <ErrorMessage error="กรุณาระบุ Chief complaint ของ Diagnosis" />
          );
          this.setState({ isLoading: false });
          return false;
        }
        if (
          !diagnosis_ot_data["OtDiagnosisCerebralLow6"] &&
          !diagnosis_ot_data["OtDiagnosisCerebralLow7"] &&
          !diagnosis_ot_data["OtDiagnosisCerebralLow9"] &&
          !diagnosis_ot_data["OtDiagnosisCerebralLow13"] &&
          !diagnosis_ot_data["OtDiagnosisCerebralLow16"] &&
          !diagnosis_ot_data["OtDiagnosisCerebralLow19"] &&
          !diagnosis_ot_data["OtDiagnosisCerebralLow23"] &&
          !diagnosis_ot_data["OtDiagnosisCerebralLow24"] &&
          !diagnosis_ot_data["OtDiagnosisCerebralLow26"] &&
          !diagnosis_ot_data["OtDiagnosisCerebralLow27"]
        ) {
          toast.error(
            <ErrorMessage error="กรุณาเลือก Consult for ของ Diagnosis" />
          );
          this.setState({ isLoading: false });
          return false;
        }
        if (
          !diagnosis_ot_data["OtDiagnosisCerebralLow34"] &&
          !diagnosis_ot_data["OtDiagnosisCerebralLow35"] &&
          !diagnosis_ot_data["OtDiagnosisCerebralLow36"] &&
          !diagnosis_ot_data["OtDiagnosisCerebralLow38"] &&
          !diagnosis_ot_data["OtDiagnosisCerebralLow41"] &&
          !diagnosis_ot_data["OtDiagnosisCerebralLow42"] &&
          !diagnosis_ot_data["OtDiagnosisCerebralLow45"] &&
          !diagnosis_ot_data["OtDiagnosisCerebralLow50"] &&
          !diagnosis_ot_data["OtDiagnosisCerebralLow51"] &&
          !diagnosis_ot_data["OtDiagnosisCerebralLow53"] &&
          !diagnosis_ot_data["OtDiagnosisCerebralLow54"] &&
          !diagnosis_ot_data["OtDiagnosisCerebralLow57"] &&
          !diagnosis_ot_data["OtDiagnosisCerebralLow58"] &&
          !diagnosis_ot_data["OtDiagnosisCerebralLow65"]
        ) {
          toast.error(
            <ErrorMessage error="กรุณาเลือก Precaution ของ Diagnosis" />
          );
          this.setState({ isLoading: false });
          return false;
        }
        if (
          !diagnosis_ot_data["OtDiagnosisCerebralLow67"] &&
          !diagnosis_ot_data["OtDiagnosisCerebralLow70"]
        ) {
          toast.error(
            <ErrorMessage error="กรุณาระบุ Functional Limitation ของ Diagnosis" />
          );
          this.setState({ isLoading: false });
          return false;
        }
      }
    } else if (view === this.state.activeMenu + "PTOTSubjectiveLOW") {
      
      let ptot_subjective_data =
        data[
          this.state.activeMenu +
            "PTOTSubjectiveLOWViewData"
        ]

      if (
        !ptot_subjective_data["PtOtSubjectiveLow2"] &&
        !ptot_subjective_data["PtOtSubjectiveLow3"] &&
        !ptot_subjective_data["PtOtSubjectiveLow4"] &&
        !ptot_subjective_data["PtOtSubjectiveLow7"] &&
        !ptot_subjective_data["PtOtSubjectiveLow9"] &&
        !ptot_subjective_data["PtOtSubjectiveLow11"] &&
        !ptot_subjective_data["PtOtSubjectiveLow13"] &&
        !ptot_subjective_data["PtOtSubjectiveLow17"] &&
        !ptot_subjective_data["PtOtSubjectiveLow19"] &&
        !ptot_subjective_data["PtOtSubjectiveLow20"] &&
        !ptot_subjective_data["PtOtSubjectiveLow22"] &&
        !ptot_subjective_data["PtOtSubjectiveLow24"] &&
        !ptot_subjective_data["PtOtSubjectiveLow26"] &&
        !ptot_subjective_data["PtOtSubjectiveLow31"]
      ) {
        toast.error(
          <ErrorMessage error="กรุณาเลือก Precaution ของ Subjective" />
        );
        this.setState({ isLoading: false });
        return false;
      }
    } else if (view === "PTRehabilitationtreatment") {
      let pt_rehab_data = data["PTRehabilitationtreatmentViewData"]

      if (!pt_rehab_data["PtRehabilitationTreatment3"]) {
        toast.error(
          <ErrorMessage error="กรุณากรอก Problem/ Physical therapy diagnosis ของ Rehabilitation" />
        );
        this.setState({ isLoading: false });
        return false;
      }

      if (!pt_rehab_data["PtRehabilitationTreatment5"]) {
        toast.error(
          <ErrorMessage error="กรุณากรอก Goal of Treatment ของ Rehabilitation" />
        );
        this.setState({ isLoading: false });
        return false;
      }

      if (!pt_rehab_data["PtRehabilitationTreatment7"]) {
        toast.error(
          <ErrorMessage error="กรุณากรอก Treatment ของ Rehabilitation" />
        );
        this.setState({ isLoading: false });
        return false;
      }
      if (!pt_rehab_data["PtRehabilitationTreatment27"]) {
        toast.error(
          <ErrorMessage error="กรุณากรอก Instruction ของ Rehabilitation" />
        );
        this.setState({ isLoading: false });
        return false;
      }

      if (!pt_rehab_data["PtRehabilitationTreatment29"] && !pt_rehab_data["PtRehabilitationTreatment31"]) {
        toast.error(
          <ErrorMessage error="กรุณาเลือก (Checkbox) Instruction ของ Rehabilitation " />
        );
        this.setState({ isLoading: false });
        return false;
      }

    } else if (view === "OTRehabilitationTreatment") {

      let ot_rehab_data = data["OTRehabilitationTreatmentViewData"]
      // console.log("ot_rehab_data",ot_rehab_data)

      if (!ot_rehab_data["OtRehabilitationTreatment4"]) {
        toast.error(
          <ErrorMessage error="กรุณากรอก Problem/Occupational therapy diagnosis ของ Rehabilitation" />
        );
        this.setState({ isLoading: false });
        return false;
      }
      if (!ot_rehab_data["OtRehabilitationTreatment6"]) {
        toast.error(
          <ErrorMessage error="กรุณากรอก Goal of Treatment ของ Rehabilitation" />
        );
        this.setState({ isLoading: false });
        return false;
      }
      if (!ot_rehab_data["OtRehabilitationTreatment8"]) {
        toast.error(
          <ErrorMessage error="กรุณากรอก Treatment ของ Rehabilitation" />
        );
        this.setState({ isLoading: false });
        return false;
      }
      if (!ot_rehab_data["OtRehabilitationTreatment26"]) {
        toast.error(
          <ErrorMessage error="กรุณากรอก Instruction ของ Rehabilitation" />
        );
        this.setState({ isLoading: false });
        return false;
      }

      if (!ot_rehab_data["OtRehabilitationTreatment28"] && !ot_rehab_data["OtRehabilitationTreatment31"]) {
        toast.error(
          <ErrorMessage error="กรุณาเลือก (Checkbox) Instruction ของ Rehabilitation" />
        );
        this.setState({ isLoading: false });
        return false;
      }
    } else if (view === "OTProgressionNoteOPDDysfunction") {
      let ot_plan_data = data["OTProgressionNoteOPDDysfunctionViewData"]
      // console.log("ot_rehab_data",ot_plan_data)

      if ( !ot_plan_data["OtProgressnoteOpdDysfunction4"]) {
        toast.error(
          <ErrorMessage error="กรุณากรอก Problem/ Occupational Therapy diagnosis ของ Plan" />
        );
        this.setState({ isLoading: false });
        return false;
      }
      if ( !ot_plan_data["OtProgressnoteOpdDysfunction6"]) {
        toast.error(
          <ErrorMessage error="กรุณากรอก Goal of Treatment ของ Plan" />
        );
        this.setState({ isLoading: false });
        return false;
      }
      if ( !ot_plan_data["OtProgressnoteOpdDysfunction9"]) {
        toast.error(
          <ErrorMessage error="กรุณากรอก Treatment ของ Plan" />
        );
        this.setState({ isLoading: false });
        return false;
      }
      if ( !ot_plan_data["OtProgressnoteOpdDysfunction26"]) {
        toast.error(
          <ErrorMessage error="กรุณากรอก Instruction ของ Plan" />
        );
        this.setState({ isLoading: false });
        return false;
      }
      if (!ot_plan_data["OtProgressnoteOpdDysfunction28"] && !ot_plan_data["OtProgressnoteOpdDysfunction36"]) {
        toast.error(
          <ErrorMessage error="กรุณาเลือก (Checkbox) Instruction ของ Plan" />
        );
        this.setState({ isLoading: false });
        return false;
      }
      
    } else if (view === "PTprogressionnoteOPDOrtho") {
      let pt_plan_data = data["PTprogressionnoteOPDOrthoViewData"]
      // console.log("ot_rehab_data",ot_plan_data)

      if ( !pt_plan_data["PtProgressnoteOpdOrtho4"] ) {
        toast.error(
          <ErrorMessage error="กรุณากรอก Problem/ Orthopedic diagnosis ของ Plan" />
        );
        this.setState({ isLoading: false });
        return false;
      }

      if ( !pt_plan_data["PtProgressnoteOpdOrtho7"] &&
        !pt_plan_data["PtProgressnoteOpdOrtho11"] &&
        !pt_plan_data["PtProgressnoteOpdOrtho13"] &&
        !pt_plan_data["PtProgressnoteOpdOrtho16"] &&
        !pt_plan_data["PtProgressnoteOpdOrtho19"] &&
        !pt_plan_data["PtProgressnoteOpdOrtho23"] &&
        !pt_plan_data["PtProgressnoteOpdOrtho25"] &&
        !pt_plan_data["PtProgressnoteOpdOrtho28"] &&
        !pt_plan_data["PtProgressnoteOpdOrtho31"] &&
        !pt_plan_data["PtProgressnoteOpdOrtho34"] &&
        !pt_plan_data["PtProgressnoteOpdOrtho37"] &&
        !pt_plan_data["PtProgressnoteOpdOrtho40"] &&
        !pt_plan_data["PtProgressnoteOpdOrtho43"] &&
        !pt_plan_data["PtProgressnoteOpdOrtho46"] &&
        !pt_plan_data["PtProgressnoteOpdOrtho49"] ) {
          toast.error(
            <ErrorMessage error="กรุณาเลือก (Checkbox) Treatment ของ Plan" />
          );
          this.setState({ isLoading: false });
          return false;
        }

      if ( !pt_plan_data["PtProgressnoteOpdOrtho53"] ) {
        toast.error(
          <ErrorMessage error="กรุณากรอก Other ในหัวข้อ Treatment ของ Plan" />
        );
        this.setState({ isLoading: false });
        return false;
      }

      if ( !pt_plan_data["PtProgressnoteOpdOrtho67"] ) {
        toast.error(
          <ErrorMessage error="กรุณากรอก Instruction ของ Plan" />
        );
        this.setState({ isLoading: false });
        return false;
      }

      if ( !pt_plan_data["PtProgressnoteOpdOrtho69"] && !pt_plan_data["PtProgressnoteOpdOrtho72"] ) {
        toast.error(
          <ErrorMessage error="กรุณาเลืิอก (Checkbox) Instruction ของ Plan" />
        );
        this.setState({ isLoading: false });
        return false;
      }

    } else if (view === this.state.activeMenu + "PTprogressionnoteOPDCardioNeuro") {
      let pt_cardio_neuro_data = data[this.state.activeMenu + "PTprogressionnoteOPDCardioNeuroViewData"]
      //  console.log("pt_cardio_neuro_data",pt_cardio_neuro_data)
      if ( !pt_cardio_neuro_data["PtProgressnoteOpdCardioNeuro6"] ) {
        toast.error(
          <ErrorMessage error="กรุณากรอก Problem/ Physical therapy diagnosis ของ Plan" />
        );
        this.setState({ isLoading: false });
        return false;
      }

      if ( !pt_cardio_neuro_data["PtProgressnoteOpdCardioNeuro8"] ) {
        toast.error(
          <ErrorMessage error="กรุณากรอก Treatment ของ Plan" />
        );
        this.setState({ isLoading: false });
        return false;
      }

      if ( !pt_cardio_neuro_data["PtProgressnoteOpdCardioNeuro22"] ) {
        toast.error(
          <ErrorMessage error="กรุณากรอก Instruction ของ Plan" />
        );
        this.setState({ isLoading: false });
        return false;
      }

      if (!pt_cardio_neuro_data["PtProgressnoteOpdCardioNeuro24"] && !pt_cardio_neuro_data["PtProgressnoteOpdCardioNeuro27"]) {
        toast.error(
          <ErrorMessage error="กรุณาเลือก (Checkbox) Instruction ของ Plan" />
        );
        this.setState({ isLoading: false });
        return false;
      }
    } else if (view === "PORehabilitationTreatmentAnkleFootOrthotic") {
      let po_rehab_ankle_data = data["PORehabilitationTreatmentAnkleFootOrthoticViewData"]

      if ( !po_rehab_ankle_data["PoRehabilitationTreatmentAnkleFootOrthotic2"]) {
        toast.error(
          <ErrorMessage error="กรุณากรอก Problem ของ Rehabilitation Treatment" />
        );
        this.setState({ isLoading: false });
        return false;
      }
      if ( !po_rehab_ankle_data["PoRehabilitationTreatmentAnkleFootOrthotic4"]) {
        toast.error(
          <ErrorMessage error="กรุณากรอก Goal of Treatment ของ Rehabilitation Treatment" />
        );
        this.setState({ isLoading: false });
        return false;
      }
      if ( !po_rehab_ankle_data["PoRehabilitationTreatmentAnkleFootOrthotic9"]) {
        toast.error(
          <ErrorMessage error="กรุณากรอก Treatment ของ Rehabilitation Treatment" />
        );
        this.setState({ isLoading: false });
        return false;
      }
      if ( !po_rehab_ankle_data["CtTreatmentAnklefootOrthosis"]) {
        toast.error(
          <ErrorMessage error="กรุณาเลือก (Dropdown) Treatment ของ Rehabilitation Treatment" />
        );
        this.setState({ isLoading: false });
        return false;
      }
      
      if ( !po_rehab_ankle_data["PoRehabilitationTreatmentAnkleFootOrthotic29"]) {
        toast.error(
          <ErrorMessage error="กรุณากรอก Instruction ของ Rehabilitation Treatment" />
        );
        this.setState({ isLoading: false });
        return false;
      }
      if (!po_rehab_ankle_data["PoRehabilitationTreatmentAnkleFootOrthotic31"] && !po_rehab_ankle_data["PoRehabilitationTreatmentAnkleFootOrthotic33"]) {
        toast.error(
          <ErrorMessage error="กรุณาเลือก (Checkbox) Instruction ของ Rehabilitation Treatment" />
        );
        this.setState({ isLoading: false });
        return false;
      }

    } else if (view === "PORehabilitationTreatmentOrthoticFittingandCheckout") {
      let po_rehab_fitting_data = data["PORehabilitationTreatmentOrthoticFittingandCheckoutViewData"]

      if ( !po_rehab_fitting_data["PoRehabilitationTreatmentOrthoticFittingCheckout2"]) {
        toast.error(
          <ErrorMessage error="กรุณากรอก Problem ของ Rehabilitation Treatment" />
        );
        this.setState({ isLoading: false });
        return false;
      }
      if ( !po_rehab_fitting_data["PoRehabilitationTreatmentOrthoticFittingCheckout4"]) {
        toast.error(
          <ErrorMessage error="กรุณากรอก Goal of Treatment ของ Rehabilitation Treatment" />
        );
        this.setState({ isLoading: false });
        return false;
      }
      if ( !po_rehab_fitting_data["PoRehabilitationTreatmentOrthoticFittingCheckout6"]) {
        toast.error(
          <ErrorMessage error="กรุณากรอก Treatment ของ Rehabilitation Treatment" />
        );
        this.setState({ isLoading: false });
        return false;
      }
      if ( !po_rehab_fitting_data["PoRehabilitationTreatmentOrthoticFittingCheckout26"]) {
        toast.error(
          <ErrorMessage error="กรุณากรอก Instruction ของ Rehabilitation Treatment" />
        );
        this.setState({ isLoading: false });
        return false;
      }
      if (!po_rehab_fitting_data["PoRehabilitationTreatmentOrthoticFittingCheckout28"] && !po_rehab_fitting_data["PoRehabilitationTreatmentOrthoticFittingCheckout28"]) {
        toast.error(
          <ErrorMessage error="กรุณาเลือก (Checkbox) Instruction ของ Rehabilitation Treatment" />
        );
        this.setState({ isLoading: false });
        return false;
      }

    }  else if (view === "PORehabilitationTreatmentOrthoticFoot") {
      let po_rehab_foot_data = data["PORehabilitationTreatmentOrthoticFootViewData"]

      if ( !po_rehab_foot_data["PoRehabilitationTreatmentOrthoticFoot2"]) {
        toast.error(
          <ErrorMessage error="กรุณากรอก Problem ของ Rehabilitation Treatment" />
        );
        this.setState({ isLoading: false });
        return false;
      }
      if ( !po_rehab_foot_data["PoRehabilitationTreatmentOrthoticFoot4"]) {
        toast.error(
          <ErrorMessage error="กรุณากรอก Goal of Treatment ของ Rehabilitation Treatment" />
        );
        this.setState({ isLoading: false });
        return false;
      }
      if ( !po_rehab_foot_data["PoRehabilitationTreatmentOrthoticFoot6"]) {
        toast.error(
          <ErrorMessage error="กรุณากรอก Treatment ของ Rehabilitation Treatment" />
        );
        this.setState({ isLoading: false });
        return false;
      }
      if ( !po_rehab_foot_data["PoRehabilitationTreatmentOrthoticFoot26"]) {
        toast.error(
          <ErrorMessage error="กรุณากรอก Instruction ของ Rehabilitation Treatment" />
        );
        this.setState({ isLoading: false });
        return false;
      }
      if (!po_rehab_foot_data["PoRehabilitationTreatmentOrthoticFoot28"] && !po_rehab_foot_data["PoRehabilitationTreatmentOrthoticFoot30"]) {
        toast.error(
          <ErrorMessage error="กรุณาเลือก (Checkbox) Instruction ของ Rehabilitation Treatment" />
        );
        this.setState({ isLoading: false });
        return false;
      }
    }
    return true;
  };

  handleCloseRemarkSwallowing = () => {
    this.setState({
      isShowRemark: false,
    });
  };

  handleCloseModalRequiredField = () => {
    this.setState({ openModalRequiredField: false });
  };

  // Core methods
  async updateData() {
    if (!this.props.sessionId) {
      return;
    }

    this.setState({ isLoading: true });
    const [result, error, n] = await TECSessionDetail.retrieve({
      pk: this.props.sessionId,
    });
    if (error) {
      toast.error(<ErrorMessage error={error} />);
    } else {
      console.log("TECSessionDetail updateData result", result);
      this.props.onAppEncounterIdChange(result.app_encounter);
      let detailAssessment = initialAssessment;
      let detailProgression = initialProgression;
      if (result.assessment !== "") {
        detailAssessment = JSON.parse(result.assessment);
        //set CardPhysical Exam Dysfunction Options
        let physicalDys = this.state
          .CardOTPhysicalExaminationPhysicalDysfunctionSensorimotorFunctionViewData;
        let physicalDysFunctionOptions = {
          ...detailAssessment[
            "CardOTPhysicalExaminationPhysicalDysfunctionSensorimotorFunctionViewData"
          ],
          lightTouchRightOptions: physicalDys.lightTouchRightOptions,
          lightTouchLeftOptions: physicalDys.lightTouchLeftOptions,
          proprioceptiveRightOptions: physicalDys.proprioceptiveRightOptions,
          proprioceptiveLeftOptions: physicalDys.proprioceptiveLeftOptions,
        };
        detailAssessment[
          "CardOTPhysicalExaminationPhysicalDysfunctionSensorimotorFunctionViewData"
        ] = physicalDysFunctionOptions;
        this.setState({ ...detailAssessment });
        if (detailAssessment.LockMenu) {
          console.log("active menu", detailAssessment.LockMenu);
          this.setState({
            activeMenu: detailAssessment.LockMenu,
          });
        }
      }
      if (result.progression_note !== "") {
        detailProgression = JSON.parse(result.progression_note);
        if (detailProgression.LockMenu) {
          this.setState({
            activeMenu: detailProgression.LockMenu,
          });
        }
        this.setState({ ...detailProgression });
      }
      this.setState({
        TecType: result.tec_type_name,
      });
    }
    this.setState({ isLoading: false });
  }

  collectAssessment = () => {
    const keys = Object.keys(this.state).filter((item) =>
      [
        "CardPTDiagnosisOrthopedicOPDIPDViewData",
        "CardPTDiagnosisOrthopedicOPDIPDLOWViewData",
        "CardOTDiagnosisPhysicalDysfunctionLOWViewData",
        "CardOTDiagnosisSwallowingEvaluationLOWViewData",
        "CardOTDiagnosisPediatricsLOWViewData",
        "CardOTDiagnosisCerebralPalsyLOWViewData",
        // "CardPODiagnosisOrthoticfootPremorbidIndependentLOWViewData",
        "FITANDCHECKCardPODiagnosisOrthoticfootPremorbidIndependentLOWViewData",
        "ANKLEFOOTCardPODiagnosisOrthoticfootPremorbidIndependentLOWViewData",
        "ORTHOTICFOOTCardPODiagnosisOrthoticfootPremorbidIndependentLOWViewData",

        // tab Pain Assessment
        "AssessmentPainAssessmentViewData",
        "AnkleFootAssessmentPainAssessmentViewData",
        "OrthoticFootAssessmentPainAssessmentViewData",

        // tab Physical Examination
        "CardPTPhysicalexaminationOrthopedicOPDTOPViewData",
        "CardPTPhysicalExamOrthopedicMIDViewData",
        "CardPTPhysicalexaminationOrthopedicOPDLOWViewData",

        "CardPTPhysicalexaminationCardiopulmonaryTOPViewData",
        "CardPTPhysicalExamCardiopulmonaryMIDViewData",

        "CardPTPhysicalexaminationNeuroogialOPDTOPViewData",
        "CardPTPhysicalExamNeuroogialMIDViewData",
        "PTPhysicalexaminationNeuroogialOPDLOWViewData",

        "POPhysicalexaminationOrthoticFittingandCheckout2TOPViewData",
        "CardPOPhysicalExamOrthoticFittingAndCheckoutMIDViewData",
        "POPhysicalexaminationOrthoticFittingandCheckout2LOWViewData",

        "POPhysicalexaminationAnkleFootOrthosisTOPViewData",
        "CardPOPhysicalExamAnkleFootOrthosisMIDViewData",
        "POPhysicalexaminationAnkleFootOrthosisLOWViewData",

        "POPhysicalexaminationOrthoticFootOrthotic2TOPViewData",
        "CardPOPhysicalExamOrthoticFootOrthoticMIDViewData",
        "CardMultiSelectPositionViewData",
        "POPhysicalexaminationOrthoticFootOrthotic2LOWViewData",

        "CardOTPhysicalExaminationPhysicalDysfunctionMainViewData",
        "CardOTPhysicalExaminationPhysicalDysfunctionSensorimotorFunctionViewData",
        "OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeftViewData",
        "OTPhysicalExaminationPhysicalDysfunctionHandFunctionRightViewData",
        "CardOTPhysicalExaminationPhysicalDysfunctionActivitiesOfDailyLivingViewData",
        "CardOTPhysicalExaminationSwallowingEvaluation1ViewData",
        "OTPhysicalExaminationSwallowingEvaluation2ViewData",
        "CardOTPhysicalExaminationPediatrics1ViewData",
        "OTPhysicalExaminationPediatrics2ViewData",
        "OTPhysicalExaminationPediatrics3ViewData",
        "OTPhysicalExaminationCerebralPalsy1ViewData",
        "CardOTPhysicalExaminationCerebralPalsyMIDViewData",
        "CardOTPhysicalExaminationCerebralPalsy2ViewData",
        "OTPhysicalExaminationCerebralPalsy3ViewData",

        // tab Rehab
        "PTRehabilitationtreatmentViewData",
        "OTRehabilitationTreatmentViewData",
        "PORehabilitationTreatmentOrthoticFittingandCheckoutViewData",
        "PORehabilitationTreatmentAnkleFootOrthoticViewData",
        "PORehabilitationTreatmentOrthoticFootViewData",
      ].includes(item)
    );
    const data = Object.assign(
      {},
      ...keys.map((item) => ({ [item]: this.state[item] }))
    );
    console.log("collectAssestment: ", data);
    return data;
  };

  collectProgressionNote = () => {
    const keys = Object.keys(this.state).filter((item) =>
      [
        "ORTHOPTOTSubjectiveLOWViewData",
        "CARDIOPTOTSubjectiveLOWViewData",
        "NEUROPTOTSubjectiveLOWViewData",
        "PHYSDYSPTOTSubjectiveLOWViewData",
        "PEDIATRICSPTOTSubjectiveLOWViewData",

        // "OTSubjectiveLOWViewData",
        "PTObjectiveOrthoTOPViewData",
        "CardPTObjectiveOrthoLowViewData",
        "PTObjectiveCardiopulmonaryOPDViewData",
        "PTObjectiveNeuroViewData",
        "CardOTObjectivePediatricsViewData",
        "CardOTObjectivePhysicalDysfunction1ViewData",

        // tab Assessment
        "ORTHOProgressPainAssessmentViewData",
        "CARDIOProgressPainAssessmentViewData",
        "NEUROProgressPainAssessmentViewData",

        // tab Plan
        "CARDIOPTprogressionnoteOPDCardioNeuroViewData",
        "NEUROPTprogressionnoteOPDCardioNeuroViewData",
        "PTprogressionnoteOPDOrthoViewData",
        "OTProgressionNoteOPDDysfunctionViewData",
      ].includes(item)
    );
    const data = Object.assign(
      {},
      ...keys.map((item) => ({ [item]: this.state[item] }))
    );
    return data;
  };

  async getEncounterInfo() {
    const encounter_id =
      this.props.patientData && this.props.patientData.ENCOUNTER
        ? this.props.patientData.ENCOUNTER.encounter_id
        : null;
    if (!encounter_id) return;

    const [encounter, e1, n1] = await EncounterDetail.retrieve({
      pk: encounter_id,
    });
    if (e1) {
      console.log("Error fetching encounter", e1, n1);
      return;
    }

    const [tecRequest, e3, n3] = await TECRequestList.list({
      params: { encounter_id: encounter_id },
    });
    if (e3) {
      console.log("Error fetching tecrequest", e3, n3);
      return;
    }
    console.log("tecRequest", tecRequest);
  }

  async getDiagnosisReadonlyFromAppEncounter() {
    const encounter_id = this.props.appEncounterId;

    if (!encounter_id) return;

    const [encounter, e1, n1] = await EncounterDetail.retrieve({
      pk: encounter_id,
    });
    if (e1) {
      console.log("Error fetching encounter", e1, n1);
      return;
    }

    const [
      medicalRecord,
      e2,
      n2,
    ] = await DPODiagnosisMedicalRecordDetail.retrieve({
      pk: encounter.medical_record,
    });
    if (e2) {
      console.log("Error fetching medical_record", e2, n2);
      return;
    }
    console.log(
      this.state.medicalRecordDiagnosis,
      "medicalRecordDiagnosis 111"
    );
    console.log("medicalRecordDiagnosis 222", medicalRecord);

    this.setState({ medicalRecordDiagnosis: medicalRecord });
    // console.log('medicalRecord', medicalRecord)
  }

  // Utilities
  setViewData = (viewName: string, data: any, filter: boolean = false) => {
    console.log("Is missing viewName:", viewName);
    var newViewData = null;
    if (filter) {
      let filterData = {};
      _.assign(
        filterData,
        _.pick(data, _.keys(this.state[viewName + "ViewData"]))
      );

      newViewData = Object.assign(
        {},
        this.state[viewName + "ViewData"],
        filterData
      );
    } else {
      newViewData = Object.assign({}, this.state[viewName + "ViewData"], {
        ...data,
      });
    }
    const newState = Object.assign({}, this.state, {
      [viewName + "ViewData"]: newViewData,
    });
    this.setState(newState);
  };

  // setViewData = (viewName: string, data: any) => {
  //   const newViewData = Object.assign(
  //     {},
  //     this.state[viewName + "ViewData"],
  //     data
  //   );
  //   const newState = Object.assign({}, this.state, {
  //     [viewName + "ViewData"]: newViewData,
  //   });
  //   this.setState(newState);
  //   console.log(newState);
  // };

  render() {
    console.log(this.state.activeMenu, this.state.TecType);
    // console.log("CardOTPhysicalExaminationPhysicalDysfunctionSensorimotorFunctionViewData", this.state.CardOTPhysicalExaminationPhysicalDysfunctionSensorimotorFunctionViewData)
    let diag =
      this.state.medicalRecordDiagnosis.principal_diagnosis &&
      this.state.medicalRecordDiagnosis.principal_diagnosis.length > 0 &&
      this.state.medicalRecordDiagnosis.principal_diagnosis[0] &&
      this.state.medicalRecordDiagnosis.principal_diagnosis[0].icd_term
        ? this.state.medicalRecordDiagnosis.principal_diagnosis[0].icd_term
        : "";
    let doctorDiag =
      this.state.medicalRecordDiagnosis.principal_diagnosis &&
      this.state.medicalRecordDiagnosis.principal_diagnosis.length > 0 &&
      this.state.medicalRecordDiagnosis.principal_diagnosis[0] &&
      this.state.medicalRecordDiagnosis.principal_diagnosis[0].doctor
        ? this.state.medicalRecordDiagnosis.principal_diagnosis[0].doctor
        : "";

    let manageTemplateUrl =
      window.location.origin.replace("3000", "8000") +
      "/manage/core/diagnosistemplate/";

    const { activeMenu, TecType } = this.state;
    let appName = new URL(window.location.href).searchParams.get("app");
    console.log("CardTECSesion Render ");
    return (
      <>
        {/* <button onClick={() => {this.setState({ openModalRequiredField: true, requiredFieldMessage: 'TODO '})}}> Show </button> */}
        <Dimmer inverted active={this.state.isLoading}>
          <Loader active={this.state.isLoading}> Loading... </Loader>
        </Dimmer>
        <ModInfo
          type="error"
          children={
            <>
              <ErrorMessage error={this.state.error} />
            </>
          }
          duration={3000}
          onApprove={() => this.setState({ error: "", openModError: false })}
          open={this.state.openModError}
          closeOnDimmerClick
          onClose={() => this.setState({ error: "", openModError: false })}
        />
        <ModalRequireField
          open={this.state.openModalRequiredField}
          message={this.state.requiredFieldMessage}
          onClose={this.handleCloseModalRequiredField}
          languageUX={this.state.userSelectedLanguage}
        />
        <ModalSwallowingRemark
          open={this.state.isShowRemark}
          handleClose={this.handleCloseRemarkSwallowing}
          languageUX={this.state.userSelectedLanguage}
        />
        {/* Assessment Note */}
        {this.props.isOpenAssessment && (
          <Tab
            defaultActiveIndex={1}
            activeIndex={this.state.activeTab}
            renderActiveOnly={false}
            onTabChange={this.setActiveTab}
            menu={{ attached: false, className: "blueHoverBold" }}
            panes={[
              {
                menuItem: () => (
                  <Dropdown
                    className="disableDropDown"
                    style={{ width: "300px" }}
                    item
                    selection
                    key="cboAssessmentNote"
                    placeholder="Select SubMenu"
                    value={activeMenu}
                    options={this.getMenuOption()}
                    onChange={this.setActiveMenu}
                  />
                ),
              },

              {
                menuItem: {
                  // style: { width: "200px", justifyContent: "center" },
                  content: "Diagnosis",
                },
                pane: (
                  <Tab.Pane
                    key="tabDiagnosis"
                    style={{ padding: 0, border: 0 }}
                  >
                    {/* type PHYSICAL*/}
                    {TecType === TECTYPE.PHYSICAL && (
                      <>
                        <Segment>
                          <CardDiagnosisReadOnlyDoctor
                            diagnosis={diag}
                            doctor={doctorDiag}
                            languageUX={this.state.userSelectedLanguage}
                          />
                        </Segment>
                        <Segment
                          style={{ background: "#F3F3F3", height: "800px" }}
                        >
                          <CardPTDiagnosisOrthopedicOPDIPDLOW
                            viewData={
                              Object.keys(
                                this.state
                                  .CardPTDiagnosisOrthopedicOPDIPDLOWViewData
                              ).length > 0
                                ? this.state
                                    .CardPTDiagnosisOrthopedicOPDIPDLOWViewData
                                : CardPTDiagnosisOrthopedicOPDIPDLOWInitialViewData
                            }
                            onEvent={this.handleEvent}
                            languageUX={this.state.userSelectedLanguage}
                          />
                        </Segment>
                        {!this.props.readonlySession ? (
                          <CardICD10
                            style={styles.qmlCardStyle}
                            emrId={this.props.patientData.EMR.emr_id}
                            patientId={this.props.patientData.patient_id}
                            subICDController={
                              this.props.subICD10DetailController
                            }
                            manageTemplateUrl={manageTemplateUrl}
                            readOnly={this.props.readonlySession}
                            readonlyPrimary={true}
                            languageUX={this.state.userSelectedLanguage}
                          />
                        ) : null}
                      </>
                    )}

                    {/* type OCCUPATIONAL, menu PHYSDYS */}
                    {TecType === TECTYPE.OCCUPATIONAL &&
                      activeMenu === MENU.PHYSDYS && (
                        <>
                          <Segment>
                            <CardDiagnosisReadOnlyDoctor
                              diagnosis={diag}
                              doctor={doctorDiag}
                              languageUX={this.state.userSelectedLanguage}
                            />
                          </Segment>
                          <Segment
                            style={{ background: "#F3F3F3", height: "800px" }}
                          >
                            <CardOTDiagnosisPhysicalDysfunctionLOW
                              viewData={
                                this.state
                                  .CardOTDiagnosisPhysicalDysfunctionLOWViewData
                              }
                              onEvent={this.handleEvent}
                              languageUX={this.state.userSelectedLanguage}
                            />
                          </Segment>
                          {!this.props.readonlySession ? (
                            <CardICD10
                              style={styles.qmlCardStyle}
                              emrId={this.props.patientData.EMR.emr_id}
                              patientId={this.props.patientData.patient_id}
                              subICDController={
                                this.props.subICD10DetailController
                              }
                              manageTemplateUrl={manageTemplateUrl}
                              readonly={this.props.readonlySession}
                              readonlyPrimary={true}
                              languageUX={this.state.userSelectedLanguage}
                            />
                          ) : null}
                        </>
                      )}

                    {/* type OCCUPATIONAL, menu SWALLOW */}
                    {TecType === TECTYPE.OCCUPATIONAL &&
                      activeMenu === MENU.SWALLOW && (
                        <>
                          <Segment>
                            <CardDiagnosisReadOnlyDoctor
                              diagnosis={diag}
                              doctor={doctorDiag}
                              languageUX={this.state.userSelectedLanguage}
                            />
                          </Segment>
                          <Segment
                            style={{ background: "#F3F3F3", height: "800px" }}
                          >
                            <CardOTDiagnosisSwallowingEvaluationLOW
                              viewData={
                                this.state
                                  .CardOTDiagnosisSwallowingEvaluationLOWViewData
                              }
                              onEvent={this.handleEvent}
                              languageUX={this.state.userSelectedLanguage}
                            />
                          </Segment>
                          {!this.props.readonlySession ? (
                            <CardICD10
                              style={styles.qmlCardStyle}
                              emrId={this.props.patientData.EMR.emr_id}
                              patientId={this.props.patientData.patient_id}
                              subICDController={
                                this.props.subICD10DetailController
                              }
                              manageTemplateUrl={manageTemplateUrl}
                              readonly={this.props.readonlySession}
                              readonlyPrimary={true}
                              languageUX={this.state.userSelectedLanguage}
                            />
                          ) : null}
                        </>
                      )}

                    {/* type OCCUPATIONAL, menu PEDIATRICS */}
                    {TecType === TECTYPE.OCCUPATIONAL &&
                      activeMenu === MENU.PEDIATRICS && (
                        <>
                          <Segment>
                            <CardDiagnosisReadOnlyDoctor
                              diagnosis={diag}
                              doctor={doctorDiag}
                              languageUX={this.state.userSelectedLanguage}
                            />
                          </Segment>
                          <Segment
                            style={{ background: "#F3F3F3", height: "800px" }}
                          >
                            <CardOTDiagnosisPediatricsLOW
                              viewData={
                                this.state.CardOTDiagnosisPediatricsLOWViewData
                              }
                              onEvent={this.handleEvent}
                              languageUX={this.state.userSelectedLanguage}
                            />
                          </Segment>
                          {!this.props.readonlySession ? (
                            <CardICD10
                              style={styles.qmlCardStyle}
                              emrId={this.props.patientData.EMR.emr_id}
                              patientId={this.props.patientData.patient_id}
                              subICDController={
                                this.props.subICD10DetailController
                              }
                              manageTemplateUrl={manageTemplateUrl}
                              readonly={this.props.readonlySession}
                              readonlyPrimary={true}
                              languageUX={this.state.userSelectedLanguage}
                            />
                          ) : null}
                        </>
                      )}

                    {/* type OCCUPATIONAL, menu CEREBRAL */}
                    {TecType === TECTYPE.OCCUPATIONAL &&
                      activeMenu === MENU.CEREBRAL && (
                        <>
                          <Segment>
                            <CardDiagnosisReadOnlyDoctor
                              diagnosis={diag}
                              doctor={doctorDiag}
                              languageUX={this.state.userSelectedLanguage}
                            />
                          </Segment>
                          <Segment
                            style={{ background: "#F3F3F3", height: "850px" }}
                          >
                            <CardOTDiagnosisCerebralPalsyLOW
                              viewData={
                                this.state.CardOTDiagnosisCerebralPalsyLOWViewData
                              }
                              onEvent={this.handleEvent}
                              languageUX={this.state.userSelectedLanguage}
                            />
                          </Segment>
                          {!this.props.readonlySession ? (
                            <CardICD10
                              style={styles.qmlCardStyle}
                              emrId={this.props.patientData.EMR.emr_id}
                              patientId={this.props.patientData.patient_id}
                              subICDController={
                                this.props.subICD10DetailController
                              }
                              manageTemplateUrl={manageTemplateUrl}
                              readonly={this.props.readonlySession}
                              readonlyPrimary={true}
                              languageUX={this.state.userSelectedLanguage}
                            />
                          ) : null}
                        </>
                      )}

                    {/* type PROSTHETIC_ORTHOTIC */}
                    {TecType === TECTYPE.PROSTHETIC_ORTHOTIC && (
                      <>
                        <Segment>
                          <CardDiagnosisReadOnlyDoctor
                            diagnosis={diag}
                            doctor={doctorDiag}
                            languageUX={this.state.userSelectedLanguage}
                          />
                        </Segment>
                        <Segment
                          style={{ background: "#F3F3F3", height: "800px" }}
                        >
                          <CardPODiagnosisOrthoticfootPremorbidIndependentLOW
                            viewData={
                              this.state[
                                this.state.activeMenu +
                                  "CardPODiagnosisOrthoticfootPremorbidIndependentLOWViewData"
                              ]
                                ? this.state[
                                    this.state.activeMenu +
                                      "CardPODiagnosisOrthoticfootPremorbidIndependentLOWViewData"
                                  ]
                                : CardPODiagnosisOrthoticfootPremorbidIndependentLOWInitialViewData
                            }
                            onEvent={({ message, params }) => {
                              let newView = this.state.activeMenu + params.view;
                              params.view = newView;
                              this.handleEvent({ message, params });
                            }}
                            languageUX={this.state.userSelectedLanguage}
                          />
                        </Segment>
                        {!this.props.readonlySession ? (
                          <CardICD10
                            style={styles.qmlCardStyle}
                            emrId={this.props.patientData.EMR.emr_id}
                            patientId={this.props.patientData.patient_id}
                            subICDController={
                              this.props.subICD10DetailController
                            }
                            manageTemplateUrl={manageTemplateUrl}
                            readonly={this.props.readonlySession}
                            readonlyPrimary={true}
                            languageUX={this.state.userSelectedLanguage}
                          />
                        ) : null}
                      </>
                    )}
                  </Tab.Pane>
                ),
              },

              {
                menuItem: {
                  style: {
                    // width: "200px", justifyContent: "center",
                    display: this.props.hideLab ? "none" : "block",
                  },
                  content: "Laboratory/Imaging Exam",
                },
                pane: (
                  <Tab.Pane
                    key="tabLaboratory"
                    style={{ padding: 0, border: 0 }}
                  >
                    {/* type PHYSICAL */}
                    <CardPTLabImagingExam
                      cardLabResultController={
                        this.props.cardLabResultController
                      }
                      cardImagingExamResultController={
                        this.props.cardImagingExamResultController
                      }
                      patientInfo={this.props.patientInfo}
                      patientData={this.props.patientData}
                      viewData={this.state.PTLabImaingExamViewData}
                      onEvent={this.handleEvent}
                      languageUX={this.state.userSelectedLanguage}
                    />
                  </Tab.Pane>
                ),
              },

              {
                menuItem: {
                  // style: { width: "200px", justifyContent: "center" },
                  content: "Pain Assessment",
                },
                pane: (
                  <Tab.Pane
                    key="tabPainAssessment"
                    style={{ padding: 0, border: 0 }}
                  >
                    {TecType === TECTYPE.PROSTHETIC_ORTHOTIC &&
                      activeMenu === MENU.ORTHOTICFOOT && (
                        <CardPainAssessment
                          ref={this.orthoticFootAssessmentCardPainAssessmentRef}
                          viewData={
                            this.state
                              .OrthoticFootAssessmentPainAssessmentViewData
                              .viewData
                          }
                          dropDownOptions={this.state.PainAssessmentDropdown}
                          onEvent={this.handleEvent}
                          readonly={this.props.readonlySession}
                          languageUX={this.state.userSelectedLanguage}
                        />
                      )}
                    {TecType === TECTYPE.PROSTHETIC_ORTHOTIC &&
                      activeMenu === MENU.ANKLEFOOT && (
                        <CardPainAssessment
                          ref={this.ankleFootAssessmentCardPainAssessmentRef}
                          viewData={
                            this.state.AnkleFootAssessmentPainAssessmentViewData
                              .viewData
                          }
                          dropDownOptions={this.state.PainAssessmentDropdown}
                          onEvent={this.handleEvent}
                          readonly={this.props.readonlySession}
                          languageUX={this.state.userSelectedLanguage}
                        />
                      )}
                    {activeMenu !== MENU.ORTHOTICFOOT &&
                      activeMenu !== MENU.ANKLEFOOT && (
                        <CardPainAssessment
                          ref={this.assessmentCardPainAssessmentRef}
                          viewData={
                            this.state.AssessmentPainAssessmentViewData.viewData
                          }
                          dropDownOptions={this.state.PainAssessmentDropdown}
                          onEvent={this.handleEvent}
                          readonly={this.props.readonlySession}
                          languageUX={this.state.userSelectedLanguage}
                        />
                      )}
                  </Tab.Pane>
                ),
              },

              {
                menuItem: {
                  // style: { width: "200px", justifyContent: "center" },
                  content: "Physical examination",
                },
                pane: (
                  <Tab.Pane
                    key="tabPhysicalExam"
                    style={{ height: "350px", padding: 0, border: 0 }}
                  >
                    {/* type PHYSICAL, menu ORTHO */}
                    {TecType === TECTYPE.PHYSICAL && activeMenu === MENU.ORTHO && (
                      <>
                        <CardPTPhysicalexaminationOrthopedicOPDTOP
                          viewData={
                            this.state
                              .CardPTPhysicalexaminationOrthopedicOPDTOPViewData
                          }
                          readonly={this.props.readonlySession}
                          onEvent={this.handleEvent}
                          languageUX={this.state.userSelectedLanguage}
                        />
                        <CardPTPhysicalExamOrthopedicMID
                          ref={this.cardPTPhysicalExamOrthopedicMIDRef}
                          viewData={
                            this.state.CardPTPhysicalExamOrthopedicMIDViewData
                              .viewData
                          }
                          readonly={this.props.readonlySession}
                          languageUX={this.state.userSelectedLanguage}
                        />
                        <CardPTPhysicalexaminationOrthopedicOPDLOW
                          viewData={
                            this.state
                              .CardPTPhysicalexaminationOrthopedicOPDLOWViewData
                          }
                          onEvent={this.handleEvent}
                          readonly={this.props.readonlySession}
                          languageUX={this.state.userSelectedLanguage}
                        />
                      </>
                    )}

                    {/* type PHYSICAL, menu CARDIO */}
                    {TecType === TECTYPE.PHYSICAL &&
                      activeMenu === MENU.CARDIO && (
                        <div style={{ height: "800px" }}>
                          <CardPTPhysicalexaminationCardiopulmonaryTOP
                            onEvent={this.handleEvent}
                            viewData={
                              this.state
                                .CardPTPhysicalexaminationCardiopulmonaryTOPViewData
                            }
                            readonly={this.props.readonlySession}
                            languageUX={this.state.userSelectedLanguage}
                          />
                          <div
                            style={{
                              paddingTop: "40px",
                              backgroundColor: "#F3F3F3",
                            }}
                          >
                            <CardPTPhysicalExamCardiopulmonaryMID
                              ref={this.cardPTPhysicalExamCardioMIDRef}
                              onEvent={this.handleEvent}
                              viewData={
                                this.state
                                  .CardPTPhysicalExamCardiopulmonaryMIDViewData
                                  .viewData
                              }
                              readonly={this.props.readonlySession}
                              saveButtonDisabled={this.props.readonlySession}
                              dropDownOptions={
                                this.state.PhysicalExamCardioMIDDropdown
                              }
                              languageUX={this.state.userSelectedLanguage}
                            />
                          </div>
                        </div>
                      )}
                    {/* type PHYSICAL, menu NEURO */}
                    {TecType === TECTYPE.PHYSICAL && activeMenu === MENU.NEURO && (
                      <>
                        <CardPTPhysicalexaminationNeuroogialOPDTOP
                          viewData={
                            this.state
                              .CardPTPhysicalexaminationNeuroogialOPDTOPViewData
                          }
                          onEvent={this.handleEvent}
                          languageUX={this.state.userSelectedLanguage}
                        />
                        <CardPTPhysicalExamNeuroogialMID
                          ref={this.cardPTPhysicalExamNeuroogialMIDRef}
                          viewData={
                            this.state.CardPTPhysicalExamNeuroogialMIDViewData
                              .viewData
                          }
                          readonly={this.props.readonlySession}
                          languageUX={this.state.userSelectedLanguage}
                        />
                        <PTPhysicalexaminationNeuroogialOPDLOW
                          viewData={
                            this.state
                              .PTPhysicalexaminationNeuroogialOPDLOWViewData
                          }
                          onEvent={this.handleEvent}
                          readonly={this.props.readonlySession}
                          languageUX={this.state.userSelectedLanguage}
                        />
                      </>
                    )}

                    {/* type OCCUPATIONAL, menu PHYSDYS */}
                    {TecType === TECTYPE.OCCUPATIONAL &&
                      activeMenu === MENU.PHYSDYS && (
                        <>
                          <div style={{ height: "500px" }}>
                            <CardOTPhysicalExaminationPhysicalDysfunctionMain
                              viewData={
                                this.state
                                  .CardOTPhysicalExaminationPhysicalDysfunctionMainViewData
                              }
                              onEvent={this.handleEvent}
                              languageUX={this.state.userSelectedLanguage}
                            />
                          </div>
                          <CardOTPhysicalExaminationPhysicalDysfunctionSensorimotorFunction
                            ref={
                              this
                                .cardOTPhysicalExaminationPhysicalDysfunctionSensorimotorFunctionRef
                            }
                            viewData={
                              this.state
                                .CardOTPhysicalExaminationPhysicalDysfunctionSensorimotorFunctionViewData
                                .viewData
                            }
                            lightTouchRightOptions={
                              this.state
                                .CardOTPhysicalExaminationPhysicalDysfunctionSensorimotorFunctionViewData
                                .lightTouchRightOptions
                            }
                            lightTouchLeftOptions={
                              this.state
                                .CardOTPhysicalExaminationPhysicalDysfunctionSensorimotorFunctionViewData
                                .lightTouchLeftOptions
                            }
                            proprioceptiveRightOptions={
                              this.state
                                .CardOTPhysicalExaminationPhysicalDysfunctionSensorimotorFunctionViewData
                                .proprioceptiveRightOptions
                            }
                            proprioceptiveLeftOptions={
                              this.state
                                .CardOTPhysicalExaminationPhysicalDysfunctionSensorimotorFunctionViewData
                                .proprioceptiveLeftOptions
                            }
                            onEvent={this.handleOnLeftRightClick}
                            languageUX={this.state.userSelectedLanguage}
                          />
                          <div
                            style={{
                              height: "500px",
                              display: this.state.isOTPEDysClickLeft
                                ? ""
                                : "none",
                            }}
                          >
                            <OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeft
                              viewData={
                                this.state
                                  .OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeftViewData
                              }
                              onEvent={this.handleEvent}
                              languageUX={this.state.userSelectedLanguage}
                            />
                          </div>
                          <div
                            style={{
                              height: "500px",
                              display: this.state.isOTPEDysClickRight
                                ? ""
                                : "none",
                            }}
                          >
                            <OTPhysicalExaminationPhysicalDysfunctionHandFunctionRight
                              viewData={
                                this.state
                                  .OTPhysicalExaminationPhysicalDysfunctionHandFunctionRightViewData
                              }
                              onEvent={this.handleEvent}
                              languageUX={this.state.userSelectedLanguage}
                            />
                          </div>
                          <CardOTPhysicalExaminationPhysicalDysfunctionActivitiesOfDailyLiving
                            viewData={
                              this.state
                                .CardOTPhysicalExaminationPhysicalDysfunctionActivitiesOfDailyLivingViewData
                            }
                            onEvent={this.handleEvent}
                            languageUX={this.state.userSelectedLanguage}
                          />
                        </>
                      )}

                    {/* type OCCUPATIONAL, menu SWALLOW */}
                    {TecType === TECTYPE.OCCUPATIONAL &&
                      activeMenu === MENU.SWALLOW && (
                        <>
                          <div style={{ height: "800px" }}>
                            <CardOTPhysicalExaminationSwallowingEvaluation1
                              viewData={
                                this.state
                                  .CardOTPhysicalExaminationSwallowingEvaluation1ViewData
                              }
                              onEvent={this.handleEvent}
                              languageUX={this.state.userSelectedLanguage}
                            />
                          </div>
                          <div style={{ height: "800px" }}>
                            <OTPhysicalExaminationSwallowingEvaluation2
                              viewData={
                                this.state
                                  .OTPhysicalExaminationSwallowingEvaluation2ViewData
                              }
                              onEvent={this.handleEvent}
                              languageUX={this.state.userSelectedLanguage}
                            />
                          </div>
                        </>
                      )}

                    {/* type OCCUPATIONAL, menu PEDIATRICS */}
                    {TecType === TECTYPE.OCCUPATIONAL &&
                      activeMenu === MENU.PEDIATRICS && (
                        <>
                          <CardOTPhysicalExaminationPediatrics1
                            viewData={
                              this.state
                                .CardOTPhysicalExaminationPediatrics1ViewData
                            }
                            onEvent={this.handleEvent}
                            languageUX={this.state.userSelectedLanguage}
                          />
                          <div style={{ height: "750px" }}>
                            <OTPhysicalExaminationPediatrics2
                              viewData={
                                this.state
                                  .OTPhysicalExaminationPediatrics2ViewData
                              }
                              onEvent={this.handleEvent}
                              languageUX={this.state.userSelectedLanguage}
                            />
                          </div>
                          <div style={{ height: "1000px" }}>
                            <OTPhysicalExaminationPediatrics3
                              viewData={
                                this.state
                                  .OTPhysicalExaminationPediatrics3ViewData
                              }
                              onEvent={this.handleEvent}
                              languageUX={this.state.userSelectedLanguage}
                            />
                          </div>
                        </>
                      )}

                    {/* type OCCUPATIONAL, menu CEREBRAL */}
                    {TecType === TECTYPE.OCCUPATIONAL &&
                      activeMenu === MENU.CEREBRAL && (
                        <>
                          <div style={{ height: "573px" }}>
                            <CardOTPhysicalExaminationCerebralPalsy1
                              viewData={
                                this.state
                                  .OTPhysicalExaminationCerebralPalsy1ViewData
                              }
                              onEvent={this.handleEvent}
                              languageUX={this.state.userSelectedLanguage}
                            />
                          </div>
                          <CardOTPhysicalExaminationCerebralPalsyMID
                            ref={
                              this.cardOTPhysicalExaminationCerebralPalsyMIDRef
                            }
                            viewData={
                              this.state
                                .CardOTPhysicalExaminationCerebralPalsyMIDViewData
                                .viewData
                            }
                            languageUX={this.state.userSelectedLanguage}
                          />
                          <div style={{ height: "500px" }}>
                            <CardOTPhysicalExaminationCerebralPalsy2
                              viewData={
                                this.state
                                  .CardOTPhysicalExaminationCerebralPalsy2ViewData
                              }
                              onEvent={this.handleEvent}
                              languageUX={this.state.userSelectedLanguage}
                            />
                          </div>
                          <div style={{ height: "900px" }}>
                            <OTPhysicalExaminationCerebralPalsy3
                              viewData={
                                this.state
                                  .OTPhysicalExaminationCerebralPalsy3ViewData
                              }
                              onEvent={this.handleEvent}
                              languageUX={this.state.userSelectedLanguage}
                            />
                          </div>
                        </>
                      )}

                    {/* type PROSTHETIC_ORTHOTIC, menu FITANDCHECK */}
                    {TecType === TECTYPE.PROSTHETIC_ORTHOTIC &&
                      activeMenu === MENU.FITANDCHECK && (
                        <div>
                          <div style={{ height: "200px" }}>
                            <POPhysicalexaminationOrthoticFittingandCheckout2TOP
                              viewData={
                                this.state
                                  .POPhysicalexaminationOrthoticFittingandCheckout2TOPViewData
                              }
                              onEvent={this.handleEvent}
                              languageUX={this.state.userSelectedLanguage}
                            />
                          </div>
                          <CardPOPhysicalExamOrthoticFittingAndCheckoutMID
                            ref={
                              this
                                .cardPOPhysicalExamOrthoticFittingAndCheckoutMIDRef
                            }
                            viewData={
                              this.state
                                .CardPOPhysicalExamOrthoticFittingAndCheckoutMIDViewData
                                .viewData
                            }
                            dropDownOptions={
                              this.state
                                .PhysicalExamOrthoticFittingAndCheckoutDropdown
                            }
                            languageUX={this.state.userSelectedLanguage}
                          />
                          <div style={{ height: "600px" }}>
                            <POPhysicalexaminationOrthoticFittingandCheckout2LOW
                              viewData={
                                this.state
                                  .POPhysicalexaminationOrthoticFittingandCheckout2LOWViewData
                              }
                              onEvent={this.handleEvent}
                              languageUX={this.state.userSelectedLanguage}
                            />
                          </div>
                        </div>
                      )}

                    {/* type PROSTHETIC_ORTHOTIC, menu ANKLEFOOT */}
                    {TecType === TECTYPE.PROSTHETIC_ORTHOTIC &&
                      activeMenu === MENU.ANKLEFOOT && (
                        <div>
                          <div style={{ height: "200px" }}>
                            <POPhysicalexaminationAnkleFootOrthosisTOP
                              viewData={
                                this.state
                                  .POPhysicalexaminationAnkleFootOrthosisTOPViewData
                              }
                              onEvent={this.handleEvent}
                              languageUX={this.state.userSelectedLanguage}
                            />
                          </div>
                          <CardPOPhysicalExamAnkleFootOrthosisMID
                            ref={this.cardPOPhysicalExamAnkleFootOrthosisMIDRef}
                            viewData={
                              this.state
                                .CardPOPhysicalExamAnkleFootOrthosisMIDViewData
                                .viewData
                            }
                            dropDownOptions={
                              this.state
                                .PhysicalExamAnkleFootOrthosisMIDDropdown
                            }
                            languageUX={this.state.userSelectedLanguage}
                          />
                          <div style={{ height: "500px" }}>
                            <POPhysicalexaminationAnkleFootOrthosisLOW
                              viewData={
                                this.state
                                  .POPhysicalexaminationAnkleFootOrthosisLOWViewData
                              }
                              onEvent={this.handleEvent}
                              languageUX={this.state.userSelectedLanguage}
                            />
                          </div>
                        </div>
                      )}

                    {/* type PROSTHETIC_ORTHOTIC, menu ORTHOTICFOOT */}
                    {TecType === TECTYPE.PROSTHETIC_ORTHOTIC &&
                      activeMenu === MENU.ORTHOTICFOOT && (
                        <Grid>
                          <Grid.Row columns={1} style={{ height: "400px" }}>
                            <Grid.Column width={16}>
                              <POPhysicalexaminationOrthoticFootOrthotic2TOP
                                viewData={
                                  this.state
                                    .POPhysicalexaminationOrthoticFootOrthotic2TOPViewData
                                }
                                onEvent={this.handleEvent}
                                languageUX={this.state.userSelectedLanguage}
                              />
                            </Grid.Column>
                          </Grid.Row>

                          <Grid.Row columns={1} style={styles.noTopPadding}>
                            <Grid.Column width={16}>
                              <CardPOPhysicalExamOrthoticFootOrthoticMID
                                ref={
                                  this
                                    .cardPOPhysicalExamOrthoticFootOrthoticMIDRef
                                }
                                viewData={
                                  this.state
                                    .CardPOPhysicalExamOrthoticFootOrthoticMIDViewData
                                    .viewData
                                }
                                dropDownOptions={
                                  this.state
                                    .PhysicalExamOrthoticFootOrthoticMIDDropdown
                                }
                                languageUX={this.state.userSelectedLanguage}
                              />
                            </Grid.Column>
                          </Grid.Row>

                          <Grid.Row
                            columns={2}
                            style={{
                              height: "600px",
                              background: "#F3F3F3",
                              paddintTop: "0px",
                              margin: "0px 15px",
                            }}
                          >
                            <Grid.Column width={10} style={styles.noPadding}>
                              <POPhysicalexaminationOrthoticFootOrthotic2LOW
                                viewData={
                                  this.state
                                    .POPhysicalexaminationOrthoticFootOrthotic2LOWViewData
                                }
                                onEvent={this.handleEvent}
                                languageUX={this.state.userSelectedLanguage}
                              />
                            </Grid.Column>
                            <Grid.Column
                              width={6}
                              style={{ padding: "0px", marginLeft: "-15px" }}
                            >
                              <POPhysicalexaminationOrthoticFootOrthotic2SIDE
                                viewData={
                                  this.state
                                    .POPhysicalexaminationOrthoticFootOrthotic2SIDEViewData
                                }
                                onEvent={this.handleEvent}
                                languageUX={this.state.userSelectedLanguage}
                              >
                                <CardMultiSelectPosition
                                  ref={this.cardMultiSelectPositionRef}
                                  viewData={
                                    this.state.CardMultiSelectPositionViewData
                                      .viewData
                                  }
                                  dropDownOptions={
                                    this.state.cardMultiSelectPositionDropdown
                                  }
                                  isBody={false}
                                  languageUX={this.state.userSelectedLanguage}
                                />
                                {/* <FootDorsumOutline
                                  Organs={
                                    [
                                      // { x: 0, y: 0, width: 100, height: 100 },
                                    ]
                                  }
                                />
                                <FootPlantarOutline
                                  Organs={
                                    [
                                      // { x: 0, y: 0, width: 100, height: 100 },
                                    ]
                                  }
                                /> */}
                              </POPhysicalexaminationOrthoticFootOrthotic2SIDE>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      )}
                  </Tab.Pane>
                ),
              },

              {
                menuItem: {
                  // style: { width: "200px", justifyContent: "center" },
                  content: "Rehabilitation Treatment",
                },
                pane: (
                  <Tab.Pane
                    key="tabRehab"
                    style={{ height: "1000px", padding: 0, border: 0 }}
                  >
                    {/* type PHYSICAL */}
                    {TecType === TECTYPE.PHYSICAL && (
                      <PTRehabilitationtreatment
                        viewData={this.state.PTRehabilitationtreatmentViewData}
                        onEvent={this.handleEvent}
                        languageUX={this.state.userSelectedLanguage}
                      />
                    )}

                    {/* type OCCUPATIONAL */}
                    {TecType === TECTYPE.OCCUPATIONAL && (
                      <OTRehabilitationTreatment
                        viewData={this.state.OTRehabilitationTreatmentViewData}
                        onEvent={this.handleEvent}
                        languageUX={this.state.userSelectedLanguage}
                      />
                    )}

                    {/* type PROSTHETIC_ORTHOTIC, menu FITANDCHECK */}
                    {TecType === TECTYPE.PROSTHETIC_ORTHOTIC &&
                      activeMenu === MENU.FITANDCHECK && (
                        <PORehabilitationTreatmentOrthoticFittingandCheckout
                          viewData={
                            this.state
                              .PORehabilitationTreatmentOrthoticFittingandCheckoutViewData
                          }
                          onEvent={this.handleEvent}
                          languageUX={this.state.userSelectedLanguage}
                        />
                      )}

                    {/* type PROSTHETIC_ORTHOTIC, menu ANKLEFOOT */}
                    {TecType === TECTYPE.PROSTHETIC_ORTHOTIC &&
                      activeMenu === MENU.ANKLEFOOT && (
                        <PORehabilitationTreatmentAnkleFootOrthotic
                          viewData={
                            this.state
                              .PORehabilitationTreatmentAnkleFootOrthoticViewData
                          }
                          onEvent={this.handleEvent}
                          languageUX={this.state.userSelectedLanguage}
                        />
                      )}

                    {/* type PROSTHETIC_ORTHOTIC, menu ORTHOTICFOOT */}
                    {TecType === TECTYPE.PROSTHETIC_ORTHOTIC &&
                      activeMenu === MENU.ORTHOTICFOOT && (
                        <PORehabilitationTreatmentOrthoticFoot
                          viewData={
                            this.state
                              .PORehabilitationTreatmentOrthoticFootViewData
                          }
                          onEvent={this.handleEvent}
                          languageUX={this.state.userSelectedLanguage}
                        />
                      )}
                  </Tab.Pane>
                ),
              },
            ]}
          ></Tab>
        )}
        {/* Progression Note */}
        {!this.props.isOpenAssessment && this.props.patientData.encounter_type === "IPD" ? (
          <CardRehabilitationProgressNote
            django={this.props.django}
            isTechnicianPMR={this.props.isTechnicianPMR}
            patientData={this.props.patientData} //.EMR.emr_id}
            languageUX={this.state.userSelectedLanguage}
          />
        ) : null } 
        { !this.props.isOpenAssessment && !this.props.disabledProgressionNote && this.props.patientData.encounter_type !== "IPD" ? (
          <Tab
            defaultActiveIndex={1}
            activeIndex={this.state.activeTab}
            renderActiveOnly={false}
            onTabChange={this.setActiveTab}
            menu={{ attached: false, className: "blueHoverBold" }}
            panes={[
              {
                menuItem: () => (
                  <Dropdown
                    className="disableDropDown"
                    style={{ width: "300px" }}
                    item
                    selection
                    key="cboProgressNote"
                    placeholder="Select SubMenu"
                    onChange={this.setActiveMenu}
                    value={activeMenu}
                    options={this.getMenuOption()}
                  />
                ),
              },

              {
                menuItem: {
                  // style: { width: "200px", justifyContent: "center" },
                  content: "Subjective",
                },
                pane: (
                  <Tab.Pane
                    key="tabSubjective"
                    style={{ height: "450px", padding: 0 }}
                  >
                    {/* type PHYSICAL */}
                    {/* type OCCUPATIONAL */}
                    {(TecType === TECTYPE.PHYSICAL ||
                      TecType === TECTYPE.OCCUPATIONAL) && (
                      <>
                        <Segment
                          style={{ background: "#F3F3F3", height: "100%" }}
                        >
                          <CardDiagnosisReadOnlyDoctor
                            diagnosis={diag}
                            doctor={doctorDiag}
                            languageUX={this.state.userSelectedLanguage}
                          />
                          <PTOTSubjectiveLOW
                            viewData={
                              this.state[
                                this.state.activeMenu +
                                  "PTOTSubjectiveLOWViewData"
                              ]
                                ? this.state[
                                    this.state.activeMenu +
                                      "PTOTSubjectiveLOWViewData"
                                  ]
                                : PTOTSubjectiveLOWInitialViewData
                            }
                            onEvent={({ message, params }) => {
                              let newView = this.state.activeMenu + params.view;
                              params.view = newView;
                              this.handleEvent({ message, params });
                            }}
                            languageUX={this.state.userSelectedLanguage}
                          />
                        </Segment>
                        {!this.props.readonlySession ? (
                          <CardICD10
                            style={styles.qmlCardStyle}
                            emrId={this.props.patientData.EMR.emr_id}
                            patientId={this.props.patientData.patient_id}
                            subICDController={
                              this.props.subICD10DetailController
                            }
                            manageTemplateUrl={manageTemplateUrl}
                            readonly={this.props.readonlySession}
                            readonlyPrimary={true}
                            languageUX={this.state.userSelectedLanguage}
                          />
                        ) : null}
                      </>
                    )}
                  </Tab.Pane>
                ),
              },

              {
                menuItem: {
                  // style: { width: "200px", justifyContent: "center" },
                  content: "Objective",
                },
                pane: (
                  <Tab.Pane
                    key="tabObjective"
                    style={{ height: "800px", padding: 0 }}
                  >
                    {/* type PHYSICAL, menu ORTHO */}
                    {TecType === TECTYPE.PHYSICAL && activeMenu === MENU.ORTHO && (
                      <div style={{ height: "400px" }}>
                        <PTObjectiveOrthoTOP
                          viewData={this.state.PTObjectiveOrthoTOPViewData}
                          onEvent={this.handleEvent}
                          languageUX={this.state.userSelectedLanguage}
                        />
                        <CardPTObjectiveOrthoLOW
                          ref={this.cardPTObjectiveOrthoLOWRef}
                          readonly={this.props.readonlySession}
                          viewData={this.state.CardPTObjectiveOrthoLOWViewData}
                          SaveRequestDisabled={this.props.readonlySession}
                          onEvent={this.handleEvent}
                          languageUX={this.state.userSelectedLanguage}
                        />
                      </div>
                    )}

                    {/* type PHYSICAL, menu CARDIO */}
                    {TecType === TECTYPE.PHYSICAL &&
                      activeMenu === MENU.CARDIO && (
                        <React.Fragment style={{ height: "800px" }}>
                          <PTObjectiveCardiopulmonaryOPD
                            viewData={
                              this.state.PTObjectiveCardiopulmonaryOPDViewData
                            }
                            onEvent={this.handleEvent}
                            languageUX={this.state.userSelectedLanguage}
                          />
                        </React.Fragment>
                      )}

                    {/* type PHYSICAL, menu NEURO */}
                    {TecType === TECTYPE.PHYSICAL &&
                      activeMenu === MENU.NEURO && (
                        <PTObjectiveNeuro
                          viewData={this.state.PTObjectiveNeuroViewData}
                          onEvent={this.handleEvent}
                          languageUX={this.state.userSelectedLanguage}
                        />
                      )}
                    {/* type OCCUPATIONAL, menu PEDIATRICS */}
                    {TecType === TECTYPE.OCCUPATIONAL &&
                      activeMenu === MENU.PEDIATRICS && (
                        <CardOTObjectivePediatrics
                          viewData={this.state.CardOTObjectivePediatricsViewData}
                          onEvent={this.handleEvent}
                          languageUX={this.state.userSelectedLanguage}
                        />
                      )}

                    {/* type OCCUPATIONAL, menu PHYSDYS */}
                    {TecType === TECTYPE.OCCUPATIONAL &&
                      activeMenu === MENU.PHYSDYS && (
                        <CardOTObjectivePhysicalDysfunction1
                          viewData={
                            this.state.CardOTObjectivePhysicalDysfunction1ViewData
                          }
                          onEvent={this.handleEvent}
                          languageUX={this.state.userSelectedLanguage}
                        />
                      )}
                  </Tab.Pane>
                ),
              },

              {
                menuItem: {
                  // style: { width: "200px", justifyContent: "center" },
                  content: "Assessment",
                },
                pane: (
                  <Tab.Pane key="tabAssessment">
                    <CardPainAssessment
                      key={this.state.activeMenu}
                      ref={this.progressCardPainAssessmentRef}
                      viewData={
                        this.state[
                          this.state.activeMenu +
                            "ProgressPainAssessmentViewData"
                        ] &&
                        this.state[
                          this.state.activeMenu +
                            "ProgressPainAssessmentViewData"
                        ].viewData
                          ? this.state[
                              this.state.activeMenu +
                                "ProgressPainAssessmentViewData"
                            ].viewData
                          : PainAssessmentInitialViewData.viewData
                      }
                      dropDownOptions={this.state.PainAssessmentDropdown}
                      onEvent={this.handleEvent}
                      readonly={this.props.readonlySession}
                      languageUX={this.state.userSelectedLanguage}
                    />
                  </Tab.Pane>
                ),
              },

              {
                menuItem: {
                  // style: { width: "200px", justifyContent: "center" },
                  content: "Plan",
                },
                pane: (
                  <Tab.Pane
                    key="tabPlan"
                    style={{ height: "800px", padding: 0 }}
                  >
                    {/* type PHYSICAL, menu CARDIO | NEURO */}
                    {TecType === TECTYPE.PHYSICAL &&
                      (activeMenu === MENU.CARDIO ||
                        activeMenu === MENU.NEURO) && (
                        <PTprogressionnoteOPDCardioNeuro
                          viewData={
                            this.state[
                              this.state.activeMenu +
                                "PTprogressionnoteOPDCardioNeuroViewData"
                            ]
                          }
                          onEvent={({ message, params }) => {
                            let newView = this.state.activeMenu + params.view;
                            params.view = newView;
                            this.handleEvent({ message, params });
                          }}
                          languageUX={this.state.userSelectedLanguage}
                        />
                      )}

                    {/* type PHYSICAL, menu ORTHO */}
                    {TecType === TECTYPE.PHYSICAL &&
                      activeMenu === MENU.ORTHO && (
                        <PTprogressionnoteOPDOrtho
                          viewData={
                            this.state.PTprogressionnoteOPDOrthoViewData
                          }
                          onEvent={this.handleEvent}
                        />
                      )}

                    {/* type OCCUPATIONAL */}
                    {TecType === TECTYPE.OCCUPATIONAL && (
                      <CardOTProgressionNoteOPDDysfunction
                        viewData={
                          this.state.OTProgressionNoteOPDDysfunctionViewData
                        }
                        onEvent={this.handleEvent}
                        languageUX={this.state.userSelectedLanguage}
                      />
                    )}
                  </Tab.Pane>
                ),
              },
            ]}
          ></Tab>
        ) : null }
      </>
    );
  }
}

Card.defaultProps = {
  onAppEncounterIdChange: () => {},
};

export default Card;

const initialAssessment = {
  CardPTDiagnosisOrthopedicOPDIPDViewData: {},
  CardPTDiagnosisOrthopedicOPDIPDLOWViewData: CardPTDiagnosisOrthopedicOPDIPDLOWInitialViewData,
  CardOTDiagnosisPhysicalDysfunctionLOWViewData: CardOTDiagnosisPhysicalDysfunctionLOWInitialViewData,
  CardOTDiagnosisSwallowingEvaluationLOWViewData: CardOTDiagnosisSwallowingEvaluationLOWInitialViewData,
  CardOTDiagnosisPediatricsLOWViewData: CardOTDiagnosisPediatricsLOWInitialViewData,
  CardOTDiagnosisCerebralPalsyLOWViewData: CardOTDiagnosisCerebralPalsyLOWInitialViewData,
  // CardPODiagnosisOrthoticfootPremorbidIndependentLOWViewData: CardPODiagnosisOrthoticfootPremorbidIndependentLOWInitialViewData,
  FITANDCHECKCardPODiagnosisOrthoticfootPremorbidIndependentLOWViewData: CardPODiagnosisOrthoticfootPremorbidIndependentLOWInitialViewData,
  ANKLEFOOTCardPODiagnosisOrthoticfootPremorbidIndependentLOWViewData: CardPODiagnosisOrthoticfootPremorbidIndependentLOWInitialViewData,
  ORTHOTICFOOTCardPODiagnosisOrthoticfootPremorbidIndependentLOWViewData: CardPODiagnosisOrthoticfootPremorbidIndependentLOWInitialViewData,

  // tab Pain Assessment
  AssessmentPainAssessmentViewData: PainAssessmentInitialViewData,
  AnkleFootAssessmentPainAssessmentViewData: PainAssessmentInitialViewData,
  OrthoticFootAssessmentPainAssessmentViewData: PainAssessmentInitialViewData,

  // tab Physical Examination
  CardPTPhysicalexaminationOrthopedicOPDTOPViewData: CardPTPhysicalexaminationOrthopedicOPDTOPInitialViewData,
  CardPTPhysicalExamOrthopedicMIDViewData: CardPTPhysicalExamOrthopedicMIDInitialViewData,
  CardPTPhysicalexaminationOrthopedicOPDLOWViewData: CardPTPhysicalexaminationOrthopedicOPDLOWInitialViewData,

  CardPTPhysicalexaminationCardiopulmonaryTOPViewData: CardPTPhysicalexaminationCardiopulmonaryTOPInitialViewData,
  CardPTPhysicalExamCardiopulmonaryMIDViewData: CardPTPhysicalExamCardiopulmonaryMIDInitialViewData,

  CardPTPhysicalexaminationNeuroogialOPDTOPViewData: CardPTPhysicalexaminationNeuroogialOPDTOPInitialViewData,
  CardPTPhysicalExamNeuroogialMIDViewData: CardPTPhysicalExamNeuroogialMIDInitialViewData,
  PTPhysicalexaminationNeuroogialOPDLOWViewData: PTPhysicalexaminationNeuroogialOPDLOWInitialViewData,

  POPhysicalexaminationOrthoticFittingandCheckout2TOPViewData: POPhysicalexaminationOrthoticFittingandCheckout2TOPInitialViewData,
  CardPOPhysicalExamOrthoticFittingAndCheckoutMIDViewData: CardPOPhysicalExamOrthoticFittingAndCheckoutMIDInitialViewData,
  POPhysicalexaminationOrthoticFittingandCheckout2LOWViewData: POPhysicalexaminationOrthoticFittingandCheckout2LOWInitialViewData,

  POPhysicalexaminationAnkleFootOrthosisTOPViewData: POPhysicalexaminationAnkleFootOrthosisTOPInitialViewData,
  CardPOPhysicalExamAnkleFootOrthosisMIDViewData: CardPOPhysicalExamAnkleFootOrthosisMIDInitialViewData,
  POPhysicalexaminationAnkleFootOrthosisLOWViewData: POPhysicalexaminationAnkleFootOrthosisLOWInitialViewData,

  POPhysicalexaminationOrthoticFootOrthotic2TOPViewData: POPhysicalexaminationOrthoticFootOrthotic2TOPInitialViewData,
  CardPOPhysicalExamOrthoticFootOrthoticMIDViewData: CardPOPhysicalExamOrthoticFootOrthoticMIDInitialViewData,
  CardMultiSelectPositionViewData: CardMultiSelectPositionInitialViewData,
  POPhysicalexaminationOrthoticFootOrthotic2LOWViewData: POPhysicalexaminationOrthoticFootOrthotic2LOWInitialViewData,

  CardOTPhysicalExaminationPhysicalDysfunctionMainViewData: CardOTPhysicalExaminationPhysicalDysfunctionMainInitialViewData,
  CardOTPhysicalExaminationPhysicalDysfunctionSensorimotorFunctionViewData: CardOTPhysicalExaminationPhysicalDysfunctionSensorimotorFunctionInitialViewData,
  OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeftViewData: OTPhysicalExaminationPhysicalDysfunctionHandFunctionLeftInitialViewData,
  OTPhysicalExaminationPhysicalDysfunctionHandFunctionRightViewData: OTPhysicalExaminationPhysicalDysfunctionHandFunctionRightInitialViewData,
  CardOTPhysicalExaminationPhysicalDysfunctionActivitiesOfDailyLivingViewData: CardOTPhysicalExaminationPhysicalDysfunctionActivitiesOfDailyLivingInitialViewData,
  CardOTPhysicalExaminationSwallowingEvaluation1ViewData: CardOTPhysicalExaminationSwallowingEvaluation1InitialViewData,
  OTPhysicalExaminationSwallowingEvaluation2ViewData: OTPhysicalExaminationSwallowingEvaluation2InitialViewData,
  CardOTPhysicalExaminationPediatrics1ViewData: CardOTPhysicalExaminationPediatrics1InitialViewData,
  OTPhysicalExaminationPediatrics2ViewData: OTPhysicalExaminationPediatrics2InitialViewData,
  OTPhysicalExaminationPediatrics3ViewData: OTPhysicalExaminationPediatrics3InitialViewData,
  OTPhysicalExaminationCerebralPalsy1ViewData: OTPhysicalExaminationCerebralPalsy1InitialViewData,
  CardOTPhysicalExaminationCerebralPalsyMIDViewData: CardOTPhysicalExaminationCerebralPalsyMIDInitialViewData,
  CardOTPhysicalExaminationCerebralPalsy2ViewData: CardOTPhysicalExaminationCerebralPalsy2InitialViewData,
  OTPhysicalExaminationCerebralPalsy3ViewData: OTPhysicalExaminationCerebralPalsy3InitialViewData,

  // tab Rehab
  PTRehabilitationtreatmentViewData: PTRehabilitationtreatmentInitialViewData,
  OTRehabilitationTreatmentViewData: OTRehabilitationTreatmentInitialViewData,
  PORehabilitationTreatmentOrthoticFittingandCheckoutViewData: PORehabilitationTreatmentOrthoticFittingandCheckoutInitialViewData,
  PORehabilitationTreatmentAnkleFootOrthoticViewData: PORehabilitationTreatmentAnkleFootOrthoticInitialViewData,
  PORehabilitationTreatmentOrthoticFootViewData: PORehabilitationTreatmentOrthoticFootInitialViewData,
};

const initialProgression = {
  // "PTOTSubjectiveLOWViewData": PTOTSubjectiveLOWInitialViewData,
  ORTHOPTOTSubjectiveLOWViewData: PTOTSubjectiveLOWInitialViewData,
  CARDIOPTOTSubjectiveLOWViewData: PTOTSubjectiveLOWInitialViewData,
  NEUROPTOTSubjectiveLOWViewData: PTOTSubjectiveLOWInitialViewData,
  PHYSDYSPTOTSubjectiveLOWViewData: PTOTSubjectiveLOWInitialViewData,
  PEDIATRICSPTOTSubjectiveLOWViewData: PTOTSubjectiveLOWInitialViewData,

  // "OTSubjectiveLOWViewData",
  PTObjectiveOrthoTOPViewData: PTObjectiveOrthoTOPInitialViewData,
  CardPTObjectiveOrthoLowViewData: CardPTObjectiveOrthoLOWInitialViewData,
  PTObjectiveCardiopulmonaryOPDViewData: PTObjectiveCardiopulmonaryOPDInitialViewData,
  PTObjectiveNeuroViewData: PTObjectiveNeuroInitialViewData,
  CardOTObjectivePediatricsViewData: CardOTObjectivePediatricsInitialViewData,
  CardOTObjectivePhysicalDysfunction1ViewData: CardOTObjectivePhysicalDysfunction1InitialViewData,

  // tab Assessment
  ORTHOProgressPainAssessmentViewData: PainAssessmentInitialViewData,
  CARDIOProgressPainAssessmentViewData: PainAssessmentInitialViewData,
  NEUROProgressPainAssessmentViewData: PainAssessmentInitialViewData,

  // tab Plan
  CARDIOPTprogressionnoteOPDCardioNeuroViewData: PTprogressionnoteOPDCardioNeuroInitialViewData,
  NEUROPTprogressionnoteOPDCardioNeuroViewData: PTprogressionnoteOPDCardioNeuroInitialViewData,
  PTprogressionnoteOPDOrthoViewData: PTprogressionnoteOPDOrthoInitialViewData,
  OTProgressionNoteOPDDysfunctionViewData: OTProgressionNoteOPDDysfunctionInitialViewData,
};

// // Core methods
// async updateData() {
//   this.setState({ isLoading: true });
//   const [result, e, n] = await TECSessionDetail.retrieve({
//     pk: this.props.sessionId,
//   });
//   if (e) {
//     console.log(e);
//     console.log(n);
//     return;
//   }
//   console.log("TECSessionDetail updateData result", result);
//   var details;
//   if (this.props.isOpenAssessment) {
//     if (result.assessment !== "") {
//       details = JSON.parse(result.assessment);
//     } else {
//       details = {};
//     }
//   } else {
//     if (result.progression_note !== "") {
//       details = JSON.parse(result.progression_note);
//     } else {
//       details = {};
//     }
//   }

//   console.log("TECSessionDetail updateData JSON parse to details: ", details);
//   this.setState({
//     ...details,
//     TecType: result.tec_type_name,
//   });

//   // Compute first option of TechType
//   let menu = this.props.isOpenAssessment
//     ? this.state.Menu
//     : this.state.menuProgressionNote;
//   let options = this.getSubMenuOption(true, menu, result.tec_type_name);
//   if (options && options.length > 0) {
//     let first = options[0].value;
//     this.setState({ SubMenu: first });
//   }
//   options = this.getSubMenuOption(false, menu, result.tec_type_name);
//   if (options && options.length > 0) {
//     let first = options[0].value;
//     this.setState({ subMenuProgressionNote: first });
//   }

//   this.setState({ isLoading: false });
// }
