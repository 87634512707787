import React, {
  KeyboardEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { Button, Icon, Input, Modal } from "semantic-ui-react";

import { RowInfo } from "react-table-6";
import moment from "moment";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import DateTextBox from "react-lib/apps/common/DateTextBox";

// UX
import CardSearchInvoiceGroupUX from "./CardSearchInvoiceGroupUX";

// Utils
import { formatDate } from "react-lib/utils/dateUtils";
import { useIntl } from "react-intl";

// Types
type CardSearchInvoiceGroupType = {
  onEvent: (e: any) => any;
  setProp: (key: any, value: any, callback?: () => any) => any;
  groupType?: "IPD";
  isEClaim?: boolean;
  // CommonInterface
  buttonLoadCheck?: Record<string, any>;
  // callback
  onSelected: (data: Record<string, any> | null) => any;
};

type FilterType = Partial<{
  createDate: string;
  fiscalYear: string;
  invoiceNo: string;
  isCreateDate: boolean;
  isFiscalYear: boolean;
  isInvoiceNo: boolean;
}>;

const BTN_ACTIONS = {
  search: "SEARCH",
};

const CARD_SEARCH_INVOICE_GROUP = "CardSearchInvoiceGroup";

const ACTION_SEARCH = `${CARD_SEARCH_INVOICE_GROUP}_${BTN_ACTIONS.search}`;

const CardSearchInvoiceGroup = (props: CardSearchInvoiceGroupType) => {
  const intl = useIntl();
  const [open, setOpen] = useState<boolean>(false);
  const [filter, setFilter] = useState<FilterType>({});
  const [searchList, setSearchList] = useState<any[]>([]);
  const [selectedRow, setSelectedRow] = useState<Record<string, any> | null>(null);
  const [lotNo, setLotNo] = useState<string>("");
  const [inputId, setInputId] = useState<number | null>(null);

  const initSearchRef = useRef<boolean>(false);

  // Callback effect
  const handleSearch = useCallback(async () => {
    const formatParams = (isKey: keyof FilterType, value: FilterType[keyof FilterType]) =>
      filter[isKey] ? value : undefined;

    const [result] = await props.onEvent({
      message: "GetARTransactions",
      params: {
        card: ACTION_SEARCH,
        fiscal_year: formatParams("isFiscalYear", filter.fiscalYear),
        group_type: props.groupType,
        created_date: formatParams("isCreateDate", filter.createDate), //* issue 67919 67965 
        lot_no: formatParams("isInvoiceNo", filter.invoiceNo),
      },
    });

    const items: any[] = result?.items || [];

    const filteredEClaim = items.filter((item: any) => (props.isEClaim ? item.is_e_claim : true));

    setSearchList(filteredEClaim);
  }, [filter, props.isEClaim, props.groupType]);

  // Effect
  useEffect(() => {
    if (open) {
      initSearchRef.current = true;

      setFilter({ createDate: formatDate(moment()), isCreateDate: true });
    }
  }, [open]);

  useEffect(() => {
    if (initSearchRef.current) {
      handleSearch();

      initSearchRef.current = false;
    }
  }, [filter]);

  // Handler
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFilter({});
    setSearchList([]);
  };

  const handleChange = (e: SyntheticEvent | null, v: any) => {
    const value = typeof v.checked === "boolean" ? v.checked : v.value;
    setFilter({
      ...filter,
      [v.name]: value,
    });
  };

  const handleChangeDate = (name: string) => (value: string) => {
    handleChange(null, { name, value });
  };

  const handleSelect = () => {
    handleSelected(selectedRow);
  };

  const handleSelected = (row: any) => {
    props.onSelected(row);

    setLotNo(row?.lot_no || "");
    setInputId(row?.id || null);

    handleClose();
  };

  const handleChangeInput = (e: SyntheticEvent, v: any) => {
    setLotNo(v.value);
    setInputId(null);

    props.onSelected({ lot_no: v.value });
  };

  const handleKeyDown = async (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== "Enter") {
      return;
    }

    const target = e.target as HTMLInputElement;

    const [result] = await props.onEvent({
      message: "GetARTransactions",
      params: {
        limit: 1,
        lot_no: target.value,
      },
    });

    if (result?.items?.length) {
      props.onSelected(result.items[0]);
    }
  };

  const handleGetTrProps = (state: any, rowInfo?: RowInfo) => ({
    className:
      rowInfo?.original?.id && rowInfo.original?.id === selectedRow?.id ? "blueSelectedRow" : "",
    onClick: () => {
      setSelectedRow(selectedRow?.id === rowInfo?.original?.id ? null : rowInfo?.original);
    },
    onDoubleClick: () => {
      handleSelected(rowInfo?.original);
    },
  });

  const handleClearInput = () => {
    clearInput();
    props.onSelected(null);
  };

  const clearInput = () => {
    setInputId(null);
    setLotNo("");
    setSelectedRow(null);
  };

  return (
    <div>
      <Input
        className="input-encounter-search"
        placeholder=""
        value={lotNo}
        action
        readOnly
        onChange={handleChangeInput}
        onKeyDown={handleKeyDown}
      >
        <input />
        {inputId ? (
          <Icon
            name="close"
            onClick={handleClearInput}
            style={{
              cursor: "pointer",
              position: "absolute",
              right: "42px",
              top: "9px",
              zIndex: 1,
            }}
          />
        ) : null}
        <Button icon="search" onClick={handleClick} />
      </Input>

      <Modal open={open} size="large" closeOnDimmerClick onClose={handleClose}>
        <CardSearchInvoiceGroupUX
          createDate={filter.createDate}
          // config
          disabledButtonSelect={!selectedRow?.id}
          fiscalYear={filter.fiscalYear}
          getTrProps={handleGetTrProps}
          invoiceNo={filter.invoiceNo}
          isCreateDate={filter.isCreateDate}
          isFiscalYear={filter.isFiscalYear}
          isInvoiceNo={filter.isInvoiceNo}
          // Data
          items={searchList}
          // callback
          onChangeValue={handleChange}
          onClose={handleClose}
          onSearch={handleSearch}
          onSelect={handleSelect}
          buttonSearch={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              color="blue"
              name={BTN_ACTIONS.search}
              // data
              paramKey={ACTION_SEARCH}
              size="medium"
              title={intl.formatMessage({ id: "ค้นหา" })}
              // config
              fluid
              buttonLoadCheck={props.buttonLoadCheck?.[ACTION_SEARCH]}
              onClick={handleSearch}
            />
          }
          // Element
          dateTextBox={
            <DateTextBox
              disabled={!filter.isCreateDate}
              inputStyle={{ width: "145px" }}
              value={filter.createDate || ""}
              fluid
              onChange={handleChangeDate("createDate")}
            />
          }
          languageUX={props.languageUX}
        />
      </Modal>
    </div>
  );
};

CardSearchInvoiceGroup.displayName = "CardSearchInvoiceGroup";

export default React.memo(CardSearchInvoiceGroup);
