import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  Radio
} from 'semantic-ui-react'
import {
  DateTextBox,
  TimeFreeTextBox24
} from 'react-lib/apps/common'

const CardReAssessmentSurgicalUX = (props: any) => {
    return(
      <div
        style={{position: "relative", padding: "1rem", marginTop: "1rem"}}>
        <div>
          {props.surgicalTopicOne}
          <Form>
            <FormGroup
              style={{ display: "flex", alignItems: "center", marginLeft: "1rem", marginTop: "1rem" }}>
              <label
                style={{width: "40rem", whiteSpace: "normal"}}>
                1. รับผู้ป่วยไว้ในโรงพยาบาลก่อนการผ่าตัดให้ระยะสั้นที่สุด
              </label>
              <div
                style={{display: "flex", gap: "2rem", padding: "0rem 0rem 0rem 2rem"}}>
                
                <Radio
                  checked={props.ReAssessmentSequence?.surgicalBeforeQuestion?.surgicalBeforeQuestOne === "Yes"}
                  label="Yes"
                  name="surgicalBeforeQuestion.surgicalBeforeQuestOne"
                  onChange={props.onChangeData}
                  value="Yes">
                </Radio>
                <Radio
                  checked={props.ReAssessmentSequence?.surgicalBeforeQuestion?.surgicalBeforeQuestOne === "No"}
                  label="No"
                  name="surgicalBeforeQuestion.surgicalBeforeQuestOne"
                  onChange={props.onChangeData}
                  value="No">
                </Radio>
                <Radio
                  checked={props.ReAssessmentSequence?.surgicalBeforeQuestion?.surgicalBeforeQuestOne === "NA"}
                  label="NA"
                  name="surgicalBeforeQuestion.surgicalBeforeQuestOne"
                  onChange={props.onChangeData}
                  value="NA">
                </Radio>
              </div>
            </FormGroup>
            <FormGroup
              style={{ display: "flex", alignItems: "center", marginLeft: "1rem" }}>
              <label
                style={{width: "40rem", whiteSpace: "normal"}}>
                2. รักษาการติดเชื้อตำแหน่งอื่นในร่างกายให้หายก่อนการผ่าตัด
              </label>
              <div
                style={{display: "flex", gap: "2rem", padding: "0rem 0rem 0rem 2rem"}}>
                
                <Radio
                  checked={props.ReAssessmentSequence?.surgicalBeforeQuestion?.surgicalBeforeQuestTwo === "Yes"}
                  label="Yes"
                  name="surgicalBeforeQuestion.surgicalBeforeQuestTwo"
                  onChange={props.onChangeData}
                  value="Yes">
                </Radio>
                <Radio
                  checked={props.ReAssessmentSequence?.surgicalBeforeQuestion?.surgicalBeforeQuestTwo === "No"}
                  label="No"
                  name="surgicalBeforeQuestion.surgicalBeforeQuestTwo"
                  onChange={props.onChangeData}
                  value="No">
                </Radio>
                <Radio
                  checked={props.ReAssessmentSequence?.surgicalBeforeQuestion?.surgicalBeforeQuestTwo === "NA"}
                  label="NA"
                  name="surgicalBeforeQuestion.surgicalBeforeQuestTwo"
                  onChange={props.onChangeData}
                  value="NA">
                </Radio>
              </div>
            </FormGroup>
            <FormGroup
              style={{ display: "flex", alignItems: "center", marginLeft: "1rem" }}>
              <label
                style={{width: "40rem", whiteSpace: "normal"}}>
                3. อาบน้ำและสระผมด้วยสบู่หรือน้ำยาทำลายเชื้อในคืนก่อนผ่าตัดหรือเช้าของวันผ่าตัด
              </label>
              <div
                style={{display: "flex", gap: "2rem", padding: "0rem 0rem 0rem 2rem"}}>
                
                <Radio
                  checked={props.ReAssessmentSequence?.surgicalBeforeQuestion?.surgicalBeforeQuestThree === "Yes"}
                  label="Yes"
                  name="surgicalBeforeQuestion.surgicalBeforeQuestThree"
                  onChange={props.onChangeData}
                  value="Yes">
                </Radio>
                <Radio
                  checked={props.ReAssessmentSequence?.surgicalBeforeQuestion?.surgicalBeforeQuestThree === "No"}
                  label="No"
                  name="surgicalBeforeQuestion.surgicalBeforeQuestThree"
                  onChange={props.onChangeData}
                  value="No">
                </Radio>
                <Radio
                  checked={props.ReAssessmentSequence?.surgicalBeforeQuestion?.surgicalBeforeQuestThree === "NA"}
                  label="NA"
                  name="surgicalBeforeQuestion.surgicalBeforeQuestThree"
                  onChange={props.onChangeData}
                  value="NA">
                </Radio>
              </div>
            </FormGroup>
            <FormGroup
              style={{ display: "flex", alignItems: "center", marginLeft: "1rem" }}>
              <label
                style={{width: "40rem", whiteSpace: "normal"}}>
                4. ไม่โกนขนบริเวณผิวหนังที่จะผ่าตัด
              </label>
              <div
                style={{display: "flex", gap: "2rem", padding: "0rem 0rem 0rem 2rem"}}>
                
                <Radio
                  checked={props.ReAssessmentSequence?.surgicalBeforeQuestion?.surgicalBeforeQuestFour === "Yes"}
                  label="Yes"
                  name="surgicalBeforeQuestion.surgicalBeforeQuestFour"
                  onChange={props.onChangeData}
                  value="Yes">
                </Radio>
                <Radio
                  checked={props.ReAssessmentSequence?.surgicalBeforeQuestion?.surgicalBeforeQuestFour === "No"}
                  label="No"
                  name="surgicalBeforeQuestion.surgicalBeforeQuestFour"
                  onChange={props.onChangeData}
                  value="No">
                </Radio>
                <Radio
                  checked={props.ReAssessmentSequence?.surgicalBeforeQuestion?.surgicalBeforeQuestFour === "NA"}
                  label="NA"
                  name="surgicalBeforeQuestion.surgicalBeforeQuestFour"
                  onChange={props.onChangeData}
                  value="NA">
                </Radio>
              </div>
            </FormGroup>
          </Form>
        </div>
        <div
          style={{ display: "flex", justifyContent: "right", alignItems: "center", marginBottom: "1rem" }}>
          
          <label
            style={{ display: "flex", alignItems: "center", margin: "0rem 2rem", fontWeight: "bold"  }}>
            Shift
          </label>
          <Radio
            checked={props.ReAssessmentSequence?.surgicalBeforeData?.saveShift === "Day"}
            label="Day"
            name="surgicalBeforeData.saveShift"
            onChange={props.onChangeData}
            style={{ display: "flex", alignItems: "center"  }}
            value="Day">
          </Radio>
          <Radio
            checked={props.ReAssessmentSequence?.surgicalBeforeData?.saveShift === "Night"}
            label="Night"
            name="surgicalBeforeData.saveShift"
            onChange={props.onChangeData}
            style={{ display: "flex", alignItems: "center", margin: "0rem 1rem"  }}
            value="Night">
          </Radio>
          <label
            style={{ display: "flex", alignItems: "center", margin: "0rem 2rem", fontWeight: "bold"  }}>
            ประเมินวันที่
          </label>
          <DateTextBox
            onChange={props.onChangeDateSurgicalBefore}
            value={props.ReAssessmentSequence?.surgicalBeforeData?.saveData}>
          </DateTextBox>
          <label
            style={{ display: "flex", alignItems: "center", margin: "0rem 2rem", fontWeight: "bold"  }}>
            เวลา
          </label>
          <TimeFreeTextBox24
            autoFocus={false}
            onChange={props.onChangeTimeSurgicalBefore}
            value={props.ReAssessmentSequence?.surgicalBeforeData?.saveTime}>
          </TimeFreeTextBox24>
          <div
            style={{ margin: "0rem 0rem 0rem 2rem" }}>
            {props?.buttonSaveSurgicalBefore}
          </div>
        </div>
        <hr>
        </hr>
        <div
          style={{marginTop: "1rem"}}>
          {props.surgicalTopicTwo}
          <Form>
            <FormGroup
              style={{ display: "flex", alignItems: "center", marginLeft: "1rem", marginTop: "1rem" }}>
              <label
                style={{width: "40rem", whiteSpace: "normal"}}>
                1. ไม่เปิดแผลผ่าตัดก่อน 24-48 ชั่วโมง ยกเว้นในกรณีแผลมีเลือดหรือ Discharge ซึมมาก
              </label>
              <div
                style={{display: "flex", gap: "2rem", padding: "0rem 0rem 0rem 2rem"}}>
                
                <Radio
                  checked={props.ReAssessmentSequence?.surgicalAfterQuestion?.surgicalAfterQuestOne === "Yes"}
                  label="Yes"
                  name="surgicalAfterQuestion.surgicalAfterQuestOne"
                  onChange={props.onChangeData}
                  value="Yes">
                </Radio>
                <Radio
                  checked={props.ReAssessmentSequence?.surgicalAfterQuestion?.surgicalAfterQuestOne === "No"}
                  label="No"
                  name="surgicalAfterQuestion.surgicalAfterQuestOne"
                  onChange={props.onChangeData}
                  value="No">
                </Radio>
                <Radio
                  checked={props.ReAssessmentSequence?.surgicalAfterQuestion?.surgicalAfterQuestOne === "NA"}
                  label="NA"
                  name="surgicalAfterQuestion.surgicalAfterQuestOne"
                  onChange={props.onChangeData}
                  value="NA">
                </Radio>
              </div>
            </FormGroup>
            <FormGroup
              style={{ display: "flex", alignItems: "center", marginLeft: "1rem" }}>
              <label
                style={{width: "40rem", whiteSpace: "normal"}}>
                2. ล้างมือ Hand Washing 7 ขั้นตอน ตามหลักการ 5 Moment ก่อนและหลังทำแผล
              </label>
              <div
                style={{display: "flex", gap: "2rem", padding: "0rem 0rem 0rem 2rem"}}>
                
                <Radio
                  checked={props.ReAssessmentSequence?.surgicalAfterQuestion?.surgicalAfterQuestTwo === "Yes"}
                  label="Yes"
                  name="surgicalAfterQuestion.surgicalAfterQuestTwo"
                  onChange={props.onChangeData}
                  value="Yes">
                </Radio>
                <Radio
                  checked={props.ReAssessmentSequence?.surgicalAfterQuestion?.surgicalAfterQuestTwo === "No"}
                  label="No"
                  name="surgicalAfterQuestion.surgicalAfterQuestTwo"
                  onChange={props.onChangeData}
                  value="No">
                </Radio>
                <Radio
                  checked={props.ReAssessmentSequence?.surgicalAfterQuestion?.surgicalAfterQuestTwo === "NA"}
                  label="NA"
                  name="surgicalAfterQuestion.surgicalAfterQuestTwo"
                  onChange={props.onChangeData}
                  value="NA">
                </Radio>
              </div>
            </FormGroup>
            <FormGroup
              style={{ display: "flex", alignItems: "center", marginLeft: "1rem" }}>
              <label
                style={{width: "40rem", whiteSpace: "normal"}}>
                3. ทำแผลโดยยึดหลัก Aseptic Technique
              </label>
              <div
                style={{display: "flex", gap: "2rem", padding: "0rem 0rem 0rem 2rem"}}>
                
                <Radio
                  checked={props.ReAssessmentSequence?.surgicalAfterQuestion?.surgicalAfterQuestThree === "Yes"}
                  label="Yes"
                  name="surgicalAfterQuestion.surgicalAfterQuestThree"
                  onChange={props.onChangeData}
                  value="Yes">
                </Radio>
                <Radio
                  checked={props.ReAssessmentSequence?.surgicalAfterQuestion?.surgicalAfterQuestThree === "No"}
                  label="No"
                  name="surgicalAfterQuestion.surgicalAfterQuestThree"
                  onChange={props.onChangeData}
                  value="No">
                </Radio>
                <Radio
                  checked={props.ReAssessmentSequence?.surgicalAfterQuestion?.surgicalAfterQuestThree === "NA"}
                  label="NA"
                  name="surgicalAfterQuestion.surgicalAfterQuestThree"
                  onChange={props.onChangeData}
                  value="NA">
                </Radio>
              </div>
            </FormGroup>
          </Form>
        </div>
        <div
          style={{ display: "flex", justifyContent: "right", alignItems: "center" }}>
          
          <label
            style={{ display: "flex", alignItems: "center", margin: "0rem 2rem", fontWeight: "bold"  }}>
            Shift
          </label>
          <Radio
            checked={props.ReAssessmentSequence?.surgicalAfterData?.saveShift === "Day"}
            label="Day"
            name="surgicalAfterData.saveShift"
            onChange={props.onChangeData}
            style={{ display: "flex", alignItems: "center"  }}
            value="Day">
          </Radio>
          <Radio
            checked={props.ReAssessmentSequence?.surgicalAfterData?.saveShift === "Night"}
            label="Night"
            name="surgicalAfterData.saveShift"
            onChange={props.onChangeData}
            style={{ display: "flex", alignItems: "center", margin: "0rem 1rem"  }}
            value="Night">
          </Radio>
          <label
            style={{ display: "flex", alignItems: "center", margin: "0rem 2rem", fontWeight: "bold"  }}>
            ประเมินวันที่
          </label>
          <DateTextBox
            onChange={props.onChangeDateSurgicalAfter}
            value={props.ReAssessmentSequence?.surgicalAfterData?.saveData}>
          </DateTextBox>
          <label
            style={{ display: "flex", alignItems: "center", margin: "0rem 2rem", fontWeight: "bold"  }}>
            เวลา
          </label>
          <TimeFreeTextBox24
            autoFocus={false}
            onChange={props.onChangeTimeSurgicalAfter}
            value={props.ReAssessmentSequence?.surgicalAfterData?.saveTime}>
          </TimeFreeTextBox24>
          <div
            style={{ margin: "0rem 0rem 0rem 2rem" }}>
            {props?.buttonSaveSurgicalAfter}
          </div>
        </div>
      </div>
    )
}


export default CardReAssessmentSurgicalUX

export const screenPropsDefault = {}

/* Date Time : Wed Oct 02 2024 12:27:56 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{position: \"relative\", padding: \"1rem\", marginTop: \"1rem\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.surgicalTopicOne"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.surgicalTopicTwo"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"1rem\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "Form",
      "parent": 1,
      "props": {
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "Form",
      "parent": 2,
      "props": {
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", marginLeft: \"1rem\", marginTop: \"1rem\" }"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", marginLeft: \"1rem\" }"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", marginLeft: \"1rem\" }"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", marginLeft: \"1rem\" }"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "FormGroup",
      "parent": 4,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", marginLeft: \"1rem\", marginTop: \"1rem\" }"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "FormGroup",
      "parent": 4,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", marginLeft: \"1rem\" }"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "FormGroup",
      "parent": 4,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", marginLeft: \"1rem\" }"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "label",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "1. รับผู้ป่วยไว้ในโรงพยาบาลก่อนการผ่าตัดให้ระยะสั้นที่สุด"
        },
        "style": {
          "type": "code",
          "value": "{width: \"40rem\", whiteSpace: \"normal\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "label",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "2. รักษาการติดเชื้อตำแหน่งอื่นในร่างกายให้หายก่อนการผ่าตัด"
        },
        "style": {
          "type": "code",
          "value": "{width: \"40rem\", whiteSpace: \"normal\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "label",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "3. อาบน้ำและสระผมด้วยสบู่หรือน้ำยาทำลายเชื้อในคืนก่อนผ่าตัดหรือเช้าของวันผ่าตัด"
        },
        "style": {
          "type": "code",
          "value": "{width: \"40rem\", whiteSpace: \"normal\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "4. ไม่โกนขนบริเวณผิวหนังที่จะผ่าตัด"
        },
        "style": {
          "type": "code",
          "value": "{width: \"40rem\", whiteSpace: \"normal\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "label",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "1. ไม่เปิดแผลผ่าตัดก่อน 24-48 ชั่วโมง ยกเว้นในกรณีแผลมีเลือดหรือ Discharge ซึมมาก"
        },
        "style": {
          "type": "code",
          "value": "{width: \"40rem\", whiteSpace: \"normal\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "label",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "2. ล้างมือ Hand Washing 7 ขั้นตอน ตามหลักการ 5 Moment ก่อนและหลังทำแผล"
        },
        "style": {
          "type": "code",
          "value": "{width: \"40rem\", whiteSpace: \"normal\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "label",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": "3. ทำแผลโดยยึดหลัก Aseptic Technique"
        },
        "style": {
          "type": "code",
          "value": "{width: \"40rem\", whiteSpace: \"normal\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "hr",
      "parent": 0,
      "props": {
      },
      "seq": 19,
      "void": true
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"right\", alignItems: \"center\", marginBottom: \"1rem\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"right\", alignItems: \"center\" }"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "label",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": "Shift"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0rem 2rem\", fontWeight: \"bold\"  }"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "label",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": "Shift"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0rem 2rem\", fontWeight: \"bold\"  }"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "Radio",
      "parent": 20,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.surgicalBeforeData?.saveShift === \"Day\""
        },
        "label": {
          "type": "value",
          "value": "Day"
        },
        "name": {
          "type": "value",
          "value": "surgicalBeforeData.saveShift"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "Day"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "Radio",
      "parent": 20,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.surgicalBeforeData?.saveShift === \"Night\""
        },
        "label": {
          "type": "value",
          "value": "Night"
        },
        "name": {
          "type": "value",
          "value": "surgicalBeforeData.saveShift"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0rem 1rem\"  }"
        },
        "value": {
          "type": "value",
          "value": "Night"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "Radio",
      "parent": 21,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.surgicalAfterData?.saveShift === \"Day\""
        },
        "label": {
          "type": "value",
          "value": "Day"
        },
        "name": {
          "type": "value",
          "value": "surgicalAfterData.saveShift"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "Day"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "Radio",
      "parent": 21,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.surgicalAfterData?.saveShift === \"Night\""
        },
        "label": {
          "type": "value",
          "value": "Night"
        },
        "name": {
          "type": "value",
          "value": "surgicalAfterData.saveShift"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0rem 1rem\"  }"
        },
        "value": {
          "type": "value",
          "value": "Night"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "label",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเมินวันที่"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0rem 2rem\", fontWeight: \"bold\"  }"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "label",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเมินวันที่"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0rem 2rem\", fontWeight: \"bold\"  }"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 30,
      "name": "DateTextBox",
      "parent": 20,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeDateSurgicalBefore"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.surgicalBeforeData?.saveData"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 31,
      "name": "DateTextBox",
      "parent": 21,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeDateSurgicalAfter"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.surgicalAfterData?.saveData"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "label",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลา"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0rem 2rem\", fontWeight: \"bold\"  }"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "label",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลา"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0rem 2rem\", fontWeight: \"bold\"  }"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 34,
      "name": "TimeFreeTextBox24",
      "parent": 20,
      "props": {
        "autoFocus": {
          "type": "code",
          "value": "false"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeTimeSurgicalBefore"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.surgicalBeforeData?.saveTime"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 35,
      "name": "TimeFreeTextBox24",
      "parent": 21,
      "props": {
        "autoFocus": {
          "type": "code",
          "value": "false"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeTimeSurgicalAfter"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.surgicalAfterData?.saveTime"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.buttonSaveSurgicalBefore"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0rem 0rem 0rem 2rem\" }"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 21,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.buttonSaveSurgicalAfter"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0rem 0rem 0rem 2rem\" }"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", gap: \"2rem\", padding: \"0rem 0rem 0rem 2rem\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", gap: \"2rem\", padding: \"0rem 0rem 0rem 2rem\"}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", gap: \"2rem\", padding: \"0rem 0rem 0rem 2rem\"}"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", gap: \"2rem\", padding: \"0rem 0rem 0rem 2rem\"}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", gap: \"2rem\", padding: \"0rem 0rem 0rem 2rem\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", gap: \"2rem\", padding: \"0rem 0rem 0rem 2rem\"}"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", gap: \"2rem\", padding: \"0rem 0rem 0rem 2rem\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "Radio",
      "parent": 38,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.surgicalBeforeQuestion?.surgicalBeforeQuestOne === \"Yes\""
        },
        "label": {
          "type": "value",
          "value": "Yes"
        },
        "name": {
          "type": "value",
          "value": "surgicalBeforeQuestion.surgicalBeforeQuestOne"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "Yes"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "Radio",
      "parent": 38,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.surgicalBeforeQuestion?.surgicalBeforeQuestOne === \"No\""
        },
        "label": {
          "type": "value",
          "value": "No"
        },
        "name": {
          "type": "value",
          "value": "surgicalBeforeQuestion.surgicalBeforeQuestOne"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "No"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "Radio",
      "parent": 38,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.surgicalBeforeQuestion?.surgicalBeforeQuestOne === \"NA\""
        },
        "label": {
          "type": "value",
          "value": "NA"
        },
        "name": {
          "type": "value",
          "value": "surgicalBeforeQuestion.surgicalBeforeQuestOne"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "NA"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "Radio",
      "parent": 39,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.surgicalBeforeQuestion?.surgicalBeforeQuestTwo === \"Yes\""
        },
        "label": {
          "type": "value",
          "value": "Yes"
        },
        "name": {
          "type": "value",
          "value": "surgicalBeforeQuestion.surgicalBeforeQuestTwo"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "Yes"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "Radio",
      "parent": 39,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.surgicalBeforeQuestion?.surgicalBeforeQuestTwo === \"No\""
        },
        "label": {
          "type": "value",
          "value": "No"
        },
        "name": {
          "type": "value",
          "value": "surgicalBeforeQuestion.surgicalBeforeQuestTwo"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "No"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "Radio",
      "parent": 39,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.surgicalBeforeQuestion?.surgicalBeforeQuestTwo === \"NA\""
        },
        "label": {
          "type": "value",
          "value": "NA"
        },
        "name": {
          "type": "value",
          "value": "surgicalBeforeQuestion.surgicalBeforeQuestTwo"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "NA"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "Radio",
      "parent": 40,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.surgicalBeforeQuestion?.surgicalBeforeQuestThree === \"Yes\""
        },
        "label": {
          "type": "value",
          "value": "Yes"
        },
        "name": {
          "type": "value",
          "value": "surgicalBeforeQuestion.surgicalBeforeQuestThree"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "Yes"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "Radio",
      "parent": 40,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.surgicalBeforeQuestion?.surgicalBeforeQuestThree === \"No\""
        },
        "label": {
          "type": "value",
          "value": "No"
        },
        "name": {
          "type": "value",
          "value": "surgicalBeforeQuestion.surgicalBeforeQuestThree"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "No"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "Radio",
      "parent": 40,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.surgicalBeforeQuestion?.surgicalBeforeQuestThree === \"NA\""
        },
        "label": {
          "type": "value",
          "value": "NA"
        },
        "name": {
          "type": "value",
          "value": "surgicalBeforeQuestion.surgicalBeforeQuestThree"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "NA"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "Radio",
      "parent": 41,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.surgicalBeforeQuestion?.surgicalBeforeQuestFour === \"Yes\""
        },
        "label": {
          "type": "value",
          "value": "Yes"
        },
        "name": {
          "type": "value",
          "value": "surgicalBeforeQuestion.surgicalBeforeQuestFour"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "Yes"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "Radio",
      "parent": 41,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.surgicalBeforeQuestion?.surgicalBeforeQuestFour === \"No\""
        },
        "label": {
          "type": "value",
          "value": "No"
        },
        "name": {
          "type": "value",
          "value": "surgicalBeforeQuestion.surgicalBeforeQuestFour"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "No"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 56,
      "name": "Radio",
      "parent": 41,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.surgicalBeforeQuestion?.surgicalBeforeQuestFour === \"NA\""
        },
        "label": {
          "type": "value",
          "value": "NA"
        },
        "name": {
          "type": "value",
          "value": "surgicalBeforeQuestion.surgicalBeforeQuestFour"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "NA"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "Radio",
      "parent": 42,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.surgicalAfterQuestion?.surgicalAfterQuestOne === \"Yes\""
        },
        "label": {
          "type": "value",
          "value": "Yes"
        },
        "name": {
          "type": "value",
          "value": "surgicalAfterQuestion.surgicalAfterQuestOne"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "Yes"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "Radio",
      "parent": 42,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.surgicalAfterQuestion?.surgicalAfterQuestOne === \"No\""
        },
        "label": {
          "type": "value",
          "value": "No"
        },
        "name": {
          "type": "value",
          "value": "surgicalAfterQuestion.surgicalAfterQuestOne"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "No"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "Radio",
      "parent": 42,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.surgicalAfterQuestion?.surgicalAfterQuestOne === \"NA\""
        },
        "label": {
          "type": "value",
          "value": "NA"
        },
        "name": {
          "type": "value",
          "value": "surgicalAfterQuestion.surgicalAfterQuestOne"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "NA"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "Radio",
      "parent": 43,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.surgicalAfterQuestion?.surgicalAfterQuestTwo === \"Yes\""
        },
        "label": {
          "type": "value",
          "value": "Yes"
        },
        "name": {
          "type": "value",
          "value": "surgicalAfterQuestion.surgicalAfterQuestTwo"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "Yes"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "Radio",
      "parent": 43,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.surgicalAfterQuestion?.surgicalAfterQuestTwo === \"No\""
        },
        "label": {
          "type": "value",
          "value": "No"
        },
        "name": {
          "type": "value",
          "value": "surgicalAfterQuestion.surgicalAfterQuestTwo"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "No"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "Radio",
      "parent": 43,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.surgicalAfterQuestion?.surgicalAfterQuestTwo === \"NA\""
        },
        "label": {
          "type": "value",
          "value": "NA"
        },
        "name": {
          "type": "value",
          "value": "surgicalAfterQuestion.surgicalAfterQuestTwo"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "NA"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "Radio",
      "parent": 44,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.surgicalAfterQuestion?.surgicalAfterQuestThree === \"Yes\""
        },
        "label": {
          "type": "value",
          "value": "Yes"
        },
        "name": {
          "type": "value",
          "value": "surgicalAfterQuestion.surgicalAfterQuestThree"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "Yes"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "Radio",
      "parent": 44,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.surgicalAfterQuestion?.surgicalAfterQuestThree === \"No\""
        },
        "label": {
          "type": "value",
          "value": "No"
        },
        "name": {
          "type": "value",
          "value": "surgicalAfterQuestion.surgicalAfterQuestThree"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "No"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "Radio",
      "parent": 44,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.surgicalAfterQuestion?.surgicalAfterQuestThree === \"NA\""
        },
        "label": {
          "type": "value",
          "value": "NA"
        },
        "name": {
          "type": "value",
          "value": "surgicalAfterQuestion.surgicalAfterQuestThree"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "NA"
        }
      },
      "seq": 65,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardReAssessmentSurgicalUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
