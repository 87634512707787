import FormCheckboxImages from "./FormCheckbox";
export default function toPDFMakeData(props: any) {
  return {
    pageSize: `A4`,
    pageOrientation: `portrait`,
    defaultStyle: {
      font: `THSarabunNew`,
    },
    content: [
      {
        bold: `true`,
        alignment: `center`,
        pageBreak: ``,
        fontSize: `18`,
        width: `auto`,
        text: `Medical Certificate`,
        decoration: ``,
        color: ``,
        decorationColor: ``,
        decorationStyle: ``,
        preserveLeadingSpaces: true,
      },
      {
        bold: `true`,
        width: `auto`,
        preserveLeadingSpaces: true,
        alignment: `center`,
        decoration: ``,
        color: ``,
        decorationStyle: ``,
        text: `Driving License`,
        decorationColor: ``,
        pageBreak: ``,
        fontSize: `18`,
      },
      {
        pageBreak: ``,
        decoration: ``,
        width: `auto`,
        bold: false,
        decorationStyle: ``,
        fontSize: 15,
        decorationColor: ``,
        alignment: `left`,
        color: ``,
        text: `  `,
        preserveLeadingSpaces: true,
      },
      {
        columns: [
          {
            pageBreak: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            bold: false,
            color: ``,
            alignment: `left`,
            decorationStyle: ``,
            fontSize: 15,
            width: 420,
            text: ``,
          },
          {
            pageBreak: ``,
            width: `auto`,
            decorationStyle: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            color: ``,
            text: `Date `,
            bold: true,
            decorationColor: ``,
            alignment: `left`,
          },
          {
            width: 5,
            fontSize: 15,
            alignment: `left`,
            color: ``,
            decorationColor: ``,
            text: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            bold: false,
            decorationStyle: ``,
          },
          {
            alignment: `left`,
            width: `auto`,
            decorationStyle: ``,
            pageBreak: ``,
            color: ``,
            text: props.items?.formatCreatedDate,
            preserveLeadingSpaces: true,
            bold: true,
            decoration: ``,
            decorationColor: ``,
            fontSize: 15,
          },
        ],
      },
      {
        alignment: `left`,
        color: ``,
        width: `auto`,
        fontSize: 15,
        bold: false,
        pageBreak: ``,
        preserveLeadingSpaces: true,
        text: `  `,
        decoration: ``,
        decorationStyle: ``,
        decorationColor: ``,
      },
      {
        columns: [
          {
            width: `auto`,
            color: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            bold: true,
            pageBreak: ``,
            text: `Part 1 `,
            decorationColor: ``,
            fontSize: 15,
            decorationStyle: ``,
            decoration: ``,
          },
          {
            fontSize: 15,
            text: ``,
            width: 10,
            color: ``,
            decorationColor: ``,
            alignment: `left`,
            decoration: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            bold: false,
            decorationStyle: ``,
          },
          {
            pageBreak: ``,
            bold: true,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            text: `For the patient who requests for the medical fitness certificate`,
            alignment: `left`,
            decoration: ``,
            width: `auto`,
            decorationStyle: ``,
            fontSize: 15,
            color: ``,
          },
        ],
      },
      {
        columns: [
          {
            text: `I, Mr./Mrs./Miss `,
            pageBreak: ``,
            bold: false,
            decorationColor: ``,
            decorationStyle: ``,
            fontSize: 15,
            decoration: ``,
            color: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
            alignment: `left`,
          },
          {
            alignment: `left`,
            margin: [0, 0, 0, 0],
            text: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            pageBreak: ``,
            width: 10,
            color: ``,
            fontSize: 15,
            decoration: ``,
            bold: false,
          },
          {
            stack: [
              {
                width: `auto`,
                decorationStyle: ``,
                decoration: ``,
                text: `${
                  props.items.patientInfo?.full_name_en !== ""
                    ? props.items?.patientInfo?.full_name_en
                    : props.items?.patientInfo?.full_name_th !== ""
                    ? props.items?.patientInfo?.full_name_th
                    : props.items?.patientInfo?.full_name
                }`,
                margin: [0, 0, 0, -15],
                pageBreak: ``,
                decorationColor: ``,
                alignment: `left`,
                color: ``,
                bold: false,
                fontSize: 15,
                preserveLeadingSpaces: true,
              },
              {
                width: `auto`,
                decorationStyle: ``,
                pageBreak: ``,
                margin: [0, 0, 0, 0],
                text: `...............................................................................................................................................................................`,
                fontSize: 15,
                bold: false,
                decoration: ``,
                alignment: `left`,
                color: ``,
                decorationColor: ``,
                preserveLeadingSpaces: true,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            decoration: ``,
            width: `auto`,
            pageBreak: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            text: `Contacting address `,
            decorationStyle: ``,
            color: ``,
            bold: false,
            alignment: `left`,
          },
          {
            fontSize: 15,
            width: 10,
            text: ``,
            decorationColor: ``,
            decorationStyle: ``,
            color: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            bold: false,
            decoration: ``,
          },
          {
            stack: [
              {
                preserveLeadingSpaces: true,
                text: `${props.items?.checkAddressNo} ${props.items?.checkAddressName} ${props.items?.checkAddressTown} ${props.items?.checkAddressRoad} ${props.items?.checkAddressCityEn} ${props.items?.checkAddressDistrictEn} ${props.items?.checkAddressProvinceEn}`,
                decoration: ``,
                margin: [0, 0, 0, -15],
                color: ``,
                alignment: `left`,
                decorationStyle: ``,
                decorationColor: ``,
                bold: false,
                pageBreak: ``,
                fontSize: 15,
                width: `auto`,
              },
              {
                decorationColor: ``,
                preserveLeadingSpaces: true,
                color: ``,
                alignment: `left`,
                decorationStyle: ``,
                width: `auto`,
                pageBreak: ``,
                decoration: ``,
                margin: [0, 0, 0, 0],
                bold: false,
                text: `.......................................................................................................................................................................`,
                fontSize: 15,
              },
            ],
          },
        ],
      },
      {
        text: `...............................................................................................................................................................................................................`,
        decorationStyle: ``,
        width: `auto`,
        decoration: ``,
        pageBreak: ``,
        fontSize: `15`,
        preserveLeadingSpaces: true,
        bold: false,
        color: ``,
        decorationColor: ``,
        margin: [0, 0, 0, 0],
        alignment: `left`,
      },
      {
        columns: [
          {
            decoration: ``,
            bold: false,
            alignment: `left`,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decorationColor: ``,
            decorationStyle: ``,
            color: ``,
            width: `auto`,
            pageBreak: ``,
            text: `National identification number `,
          },
          {
            decorationColor: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            bold: false,
            color: ``,
            alignment: `left`,
            decoration: ``,
            decorationStyle: ``,
            width: 10,
            fontSize: 15,
            text: ``,
          },
          {
            stack: [
              {
                bold: false,
                width: `auto`,
                fontSize: 15,
                margin: [0, 0, 0, -15],
                decorationStyle: ``,
                decoration: ``,
                color: ``,
                alignment: `left`,
                text: !props.items?.formatIdentification
                  ? props.items?.patientInfo?.citizen_passport === "" ||
                    props.items?.patientInfo?.citizen_passport === "Non citizen identity"
                    ? " "
                    : props.items?.patientInfo?.citizen_passport
                  : props.items?.formatIdentification,
                pageBreak: ``,
                preserveLeadingSpaces: true,
                decorationColor: ``,
              },
              {
                alignment: `left`,
                decorationStyle: ``,
                decoration: ``,
                fontSize: 15,
                preserveLeadingSpaces: true,
                text: `..................................................................................................................................................`,
                margin: [0, 0, 0, 0],
                width: `auto`,
                decorationColor: ``,
                color: ``,
                bold: false,
                pageBreak: ``,
              },
            ],
          },
        ],
      },
      {
        alignment: `left`,
        preserveLeadingSpaces: true,
        text: `I would like to request the medical health certificate with my health history as follows:`,
        decoration: ``,
        width: `auto`,
        bold: false,
        decorationStyle: ``,
        fontSize: 15,
        decorationColor: ``,
        color: ``,
        pageBreak: ``,
      },
      {
        columns: [
          {
            color: ``,
            decorationStyle: ``,
            text: ``,
            pageBreak: ``,
            fontSize: 15,
            width: 10,
            bold: false,
            decorationColor: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
          },
          {
            decorationColor: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decorationStyle: ``,
            text: `1. Chronic health condition / disease `,
            bold: false,
            width: `auto`,
            pageBreak: ``,
            decoration: ``,
            color: ``,
          },
          {
            pageBreak: ``,
            decorationStyle: ``,
            fontSize: 15,
            decoration: ``,
            text: ``,
            preserveLeadingSpaces: true,
            bold: false,
            decorationColor: ``,
            alignment: `right`,
            color: ``,
            width: 10,
          },
          {
            image: props.items?.isFirstOption === "no" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            text: ` No  `,
            decorationColor: ``,
            decoration: ``,
            decorationStyle: ``,
            width: `auto`,
            color: ``,
            bold: false,
            alignment: `left`,
            fontSize: 15,
            preserveLeadingSpaces: true,
            pageBreak: ``,
          },
          {
            preserveLeadingSpaces: true,
            bold: false,
            width: 10,
            decorationStyle: ``,
            fontSize: 15,
            decorationColor: ``,
            alignment: `left`,
            pageBreak: ``,
            color: ``,
            text: ``,
            decoration: ``,
          },
          {
            image: props.items?.isFirstOption === "yes" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            text: ` Yes`,
            fontSize: 15,
            width: `auto`,
            decorationColor: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            color: ``,
            alignment: `left`,
            bold: false,
            decorationStyle: ``,
          },
          {
            pageBreak: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            bold: false,
            width: 10,
            text: ``,
            alignment: `left`,
            decorationColor: ``,
            decorationStyle: ``,
            color: ``,
            decoration: ``,
          },
          {
            fontSize: 15,
            bold: false,
            decorationColor: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationStyle: ``,
            text: `(please specify) `,
            alignment: `left`,
            width: `auto`,
            color: ``,
          },
          {
            margin: [0, 0, 0, 0],
            decoration: ``,
            decorationStyle: ``,
            bold: false,
            alignment: `left`,
            preserveLeadingSpaces: true,
            width: 10,
            fontSize: 15,
            color: ``,
            text: ``,
            decorationColor: ``,
            pageBreak: ``,
          },
          {
            stack: [
              {
                pageBreak: ``,
                color: ``,
                margin: [0, 0, 0, -15],
                decoration: ``,
                decorationColor: ``,
                text:
                  props.items?.isFirstOption === "yes"
                    ? props.items?.explainFirst &&
                      props.items?.explainFirst !== null &&
                      props.items?.explainFirst !== ""
                      ? props.items?.explainFirst
                      : " "
                    : " ",
                preserveLeadingSpaces: true,
                alignment: `left`,
                decorationStyle: ``,
                bold: false,
                width: `auto`,
                fontSize: 15,
              },
              {
                pageBreak: ``,
                bold: false,
                preserveLeadingSpaces: true,
                fontSize: 15,
                alignment: `left`,
                decorationStyle: ``,
                decoration: ``,
                text: `...................................................................`,
                width: `auto`,
                color: ``,
                decorationColor: ``,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            alignment: `left`,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            text: ``,
            decoration: ``,
            bold: false,
            decorationColor: ``,
            width: 10,
            color: ``,
            fontSize: 15,
            pageBreak: ``,
          },
          {
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            text: `2. Accident and operation `,
            alignment: `left`,
            fontSize: 15,
            width: `auto`,
            decorationColor: ``,
            bold: false,
            color: ``,
            decorationStyle: ``,
          },
          {
            decorationStyle: ``,
            alignment: `left`,
            fontSize: 15,
            pageBreak: ``,
            bold: false,
            text: `		   `,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            color: ``,
            decoration: ``,
            width: 60,
          },
          {
            image: props.items?.isSecondOption === "no" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            pageBreak: ``,
            decorationStyle: ``,
            width: `auto`,
            bold: false,
            fontSize: 15,
            color: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            text: ` No  `,
            decoration: ``,
            decorationColor: ``,
          },
          {
            decorationStyle: ``,
            text: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            color: ``,
            width: 10,
            pageBreak: ``,
            decoration: ``,
            bold: false,
            alignment: `left`,
            decorationColor: ``,
          },
          {
            image: props.items?.isSecondOption === "yes" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            bold: false,
            pageBreak: ``,
            text: ` Yes  `,
            preserveLeadingSpaces: true,
            width: `auto`,
            alignment: `left`,
            color: ``,
            fontSize: 15,
            decorationColor: ``,
            decoration: ``,
            decorationStyle: ``,
          },
          {
            pageBreak: ``,
            decoration: ``,
            decorationColor: ``,
            color: ``,
            text: ``,
            alignment: `left`,
            bold: false,
            decorationStyle: ``,
            width: 10,
            preserveLeadingSpaces: true,
            fontSize: 15,
          },
          {
            width: `auto`,
            color: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            alignment: `left`,
            decoration: ``,
            text: `(please specify)`,
            fontSize: 15,
            bold: false,
            decorationColor: ``,
            decorationStyle: ``,
          },
          {
            text: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            width: 10,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            bold: false,
            color: ``,
            alignment: `left`,
            fontSize: 15,
            pageBreak: ``,
          },
          {
            stack: [
              {
                decorationColor: ``,
                text:
                  props.items?.isSecondOption === "yes"
                    ? props.items?.explainSecond &&
                      props.items?.explainSecond !== null &&
                      props.items?.explainSecond !== ""
                      ? props.items?.explainSecond
                      : " "
                    : " ",
                width: `auto`,
                decorationStyle: ``,
                alignment: `left`,
                color: ``,
                pageBreak: ``,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
                fontSize: 15,
                decoration: ``,
                bold: false,
              },
              {
                width: `auto`,
                decoration: ``,
                alignment: `left`,
                text: `...................................................................`,
                pageBreak: ``,
                color: ``,
                bold: false,
                fontSize: 15,
                decorationStyle: ``,
                decorationColor: ``,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            bold: false,
            text: ``,
            color: ``,
            width: 10,
            fontSize: 15,
            alignment: `left`,
            pageBreak: ``,
            decorationColor: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
          },
          {
            text: `3. Hospital admission `,
            width: `auto`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decoration: ``,
            bold: false,
            color: ``,
            alignment: `left`,
            decorationColor: ``,
            decorationStyle: ``,
            fontSize: 15,
          },
          {
            bold: false,
            decoration: ``,
            color: ``,
            fontSize: 15,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            width: 82,
            decorationStyle: ``,
            decorationColor: ``,
            text: `		   `,
            alignment: `left`,
          },
          {
            image: props.items?.isThirdOption === "no" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            decorationColor: ``,
            text: ` No  `,
            fontSize: 15,
            alignment: `left`,
            decorationStyle: ``,
            decoration: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            bold: false,
            color: ``,
            width: `auto`,
          },
          {
            pageBreak: ``,
            color: ``,
            decoration: ``,
            width: 10,
            alignment: `left`,
            decorationStyle: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            bold: false,
            fontSize: 15,
            text: ``,
          },
          {
            image: props.items?.isThirdOption === "yes" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            text: ` Yes `,
            fontSize: 15,
            alignment: `left`,
            decoration: ``,
            color: ``,
            decorationColor: ``,
            decorationStyle: ``,
            width: `auto`,
            preserveLeadingSpaces: true,
            bold: false,
            pageBreak: ``,
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            width: 10,
            color: ``,
            bold: false,
            pageBreak: ``,
            decorationColor: ``,
            fontSize: 15,
            alignment: `left`,
            preserveLeadingSpaces: true,
          },
          {
            decorationColor: ``,
            alignment: `left`,
            decorationStyle: ``,
            pageBreak: ``,
            width: `auto`,
            decoration: ``,
            text: `(please specify)`,
            preserveLeadingSpaces: true,
            color: ``,
            fontSize: 15,
            bold: false,
          },
          {
            bold: false,
            margin: [0, 0, 0, 0],
            text: ``,
            width: 10,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            fontSize: 15,
            color: ``,
            alignment: `left`,
            decorationStyle: ``,
            decorationColor: ``,
          },
          {
            stack: [
              {
                fontSize: 15,
                color: ``,
                decorationStyle: ``,
                text:
                  props.items?.isThirdOption === "yes"
                    ? props.items?.explainThird &&
                      props.items?.explainThird !== null &&
                      props.items?.explainThird !== ""
                      ? props.items?.explainThird
                      : " "
                    : " ",
                decorationColor: ``,
                pageBreak: ``,
                width: `auto`,
                bold: false,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
                alignment: `left`,
                decoration: ``,
              },
              {
                alignment: `left`,
                margin: [0, 0, 0, 0],
                pageBreak: ``,
                color: ``,
                preserveLeadingSpaces: true,
                decoration: ``,
                text: `...................................................................`,
                decorationStyle: ``,
                decorationColor: ``,
                bold: false,
                fontSize: 15,
                width: `auto`,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            text: ``,
            decorationStyle: ``,
            color: ``,
            decorationColor: ``,
            fontSize: 15,
            alignment: `left`,
            preserveLeadingSpaces: true,
            width: 10,
            bold: false,
            decoration: ``,
          },
          {
            text: `4. Epilepsy * `,
            fontSize: 15,
            bold: false,
            width: `auto`,
            pageBreak: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            color: ``,
            decorationStyle: ``,
            alignment: `left`,
          },
          {
            text: `				   `,
            width: 122,
            decoration: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationColor: ``,
            alignment: `left`,
            bold: false,
            color: ``,
            decorationStyle: ``,
            fontSize: 15,
          },
          {
            image: props.items?.isFourthOption === "no" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            fontSize: 15,
            text: ` No  `,
            color: ``,
            pageBreak: ``,
            width: `auto`,
            alignment: `left`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            bold: false,
            decoration: ``,
            decorationColor: ``,
          },
          {
            color: ``,
            alignment: `left`,
            text: ``,
            fontSize: 15,
            decorationStyle: ``,
            width: 10,
            bold: false,
            pageBreak: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
          },
          {
            image: props.items?.isFourthOption === "yes" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            width: `auto`,
            pageBreak: ``,
            fontSize: 15,
            alignment: `left`,
            bold: false,
            decorationColor: ``,
            decorationStyle: ``,
            text: ` Yes`,
            color: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
          },
          {
            decorationStyle: ``,
            fontSize: 15,
            color: ``,
            alignment: `left`,
            pageBreak: ``,
            width: 10,
            decorationColor: ``,
            bold: false,
            decoration: ``,
            preserveLeadingSpaces: true,
            text: ``,
          },
          {
            width: `auto`,
            pageBreak: ``,
            color: ``,
            fontSize: 15,
            text: `(please specify) `,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decoration: ``,
            decorationColor: ``,
            bold: false,
            alignment: `left`,
          },
          {
            preserveLeadingSpaces: true,
            decoration: ``,
            color: ``,
            alignment: `left`,
            width: 10,
            fontSize: 15,
            bold: false,
            decorationStyle: ``,
            text: ``,
            pageBreak: ``,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
          },
          {
            stack: [
              {
                decoration: ``,
                bold: false,
                margin: [0, 0, 0, -15],
                decorationColor: ``,
                preserveLeadingSpaces: true,
                color: ``,
                fontSize: 15,
                text:
                  props.items?.isFourthOption === "yes"
                    ? props.items?.explainFourth &&
                      props.items?.explainFourth !== null &&
                      props.items?.explainFourth !== ""
                      ? props.items?.explainFourth
                      : " "
                    : " ",
                alignment: `left`,
                pageBreak: ``,
                decorationStyle: ``,
                width: `auto`,
              },
              {
                alignment: `left`,
                width: `auto`,
                decorationColor: ``,
                text: `...................................................................`,
                color: ``,
                preserveLeadingSpaces: true,
                decoration: ``,
                pageBreak: ``,
                bold: false,
                fontSize: 15,
                margin: [0, 0, 0, 0],
                decorationStyle: ``,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            text: ``,
            decoration: ``,
            bold: false,
            preserveLeadingSpaces: true,
            fontSize: 15,
            pageBreak: ``,
            color: ``,
            alignment: `left`,
            width: 10,
            decorationColor: ``,
            decorationStyle: ``,
          },
          {
            decoration: ``,
            bold: false,
            color: ``,
            fontSize: 15,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            pageBreak: ``,
            width: `auto`,
            text: `5. Other important history  `,
            decorationColor: ``,
          },
          {
            decorationStyle: ``,
            decoration: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            alignment: `left`,
            bold: false,
            width: 60,
            decorationColor: ``,
            text: `		   `,
            color: ``,
          },
          {
            image: props.items?.isFifthOption === "no" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            text: ` No  `,
            width: `auto`,
            alignment: `left`,
            pageBreak: ``,
            color: ``,
            decoration: ``,
            bold: false,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            fontSize: 15,
            decorationColor: ``,
          },
          {
            pageBreak: ``,
            bold: false,
            decoration: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            alignment: `left`,
            decorationStyle: ``,
            decorationColor: ``,
            text: ``,
            width: 10,
            color: ``,
          },
          {
            image: props.items?.isFifthOption === "yes" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            bold: false,
            color: ``,
            alignment: `left`,
            decorationColor: ``,
            width: `auto`,
            fontSize: 15,
            decoration: ``,
            text: ` Yes `,
            pageBreak: ``,
          },
          {
            width: 10,
            decorationStyle: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decoration: ``,
            text: ``,
            color: ``,
            decorationColor: ``,
            pageBreak: ``,
            alignment: `left`,
            bold: false,
          },
          {
            preserveLeadingSpaces: true,
            text: `(please specify) `,
            fontSize: 15,
            pageBreak: ``,
            width: `auto`,
            color: ``,
            alignment: `left`,
            decorationStyle: ``,
            decorationColor: ``,
            decoration: ``,
            bold: false,
          },
          {
            preserveLeadingSpaces: true,
            color: ``,
            bold: false,
            width: 10,
            decoration: ``,
            decorationStyle: ``,
            text: ``,
            pageBreak: ``,
            decorationColor: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            fontSize: 15,
          },
          {
            stack: [
              {
                bold: false,
                decorationColor: ``,
                text:
                  props.items?.isFifthOption === "yes"
                    ? props.items?.explainFifth &&
                      props.items?.explainFifth !== null &&
                      props.items?.explainFifth !== ""
                      ? props.items?.explainFifth
                      : " "
                    : " ",
                decorationStyle: ``,
                pageBreak: ``,
                margin: [0, 0, 0, -15],
                decoration: ``,
                fontSize: 15,
                width: `auto`,
                alignment: `left`,
                color: ``,
                preserveLeadingSpaces: true,
              },
              {
                fontSize: 15,
                bold: false,
                pageBreak: ``,
                decoration: ``,
                width: `auto`,
                alignment: `left`,
                color: ``,
                margin: [0, 0, 0, 0],
                text: `...................................................................`,
                decorationStyle: ``,
                decorationColor: ``,
                preserveLeadingSpaces: true,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            alignment: `left`,
            bold: false,
            decorationColor: ``,
            pageBreak: ``,
            color: ``,
            width: 10,
            decoration: ``,
            fontSize: `15`,
            text: ``,
            preserveLeadingSpaces: true,
          },
          {
            width: `auto`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            alignment: `left`,
            color: `red`,
            decoration: ``,
            fontSize: `11`,
            text: `*Seizure : treatment history was certified by doctor in charge must be accompanied to certify that no attack experienced within 1 (one) year.`,
            decorationStyle: ``,
            bold: false,
            italics: true,
            decorationColor: ``,
          },
        ],
      },
      {
        decorationStyle: ``,
        decorationColor: ``,
        color: ``,
        pageBreak: ``,
        decoration: ``,
        width: `auto`,
        fontSize: 15,
        alignment: `left`,
        text: ` `,
        bold: false,
        preserveLeadingSpaces: true,
      },
      {
        columns: [
          {
            bold: true,
            text: `Part 2 `,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            width: `auto`,
            color: ``,
            fontSize: 15,
            decorationStyle: ``,
            alignment: `left`,
            decoration: ``,
          },
          {
            fontSize: 15,
            bold: false,
            width: 10,
            decorationColor: ``,
            color: ``,
            alignment: `left`,
            pageBreak: ``,
            text: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decoration: ``,
          },
          {
            text: `For the physician`,
            alignment: `left`,
            fontSize: 15,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            color: ``,
            decorationStyle: ``,
            decoration: ``,
            width: `auto`,
            bold: true,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 15,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: `Place of examination`,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: `auto`,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            stack: [
              {
                text: props.items?.hospitalNameEn,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `........................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: `date`,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: `auto`,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            stack: [
              {
                text: `${props.items?.examDay} ${props.items?.examMonthEn} ${props.items?.examYear}`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.......................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            decoration: ``,
            bold: false,
            color: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            width: `auto`,
            text: `(1) I, Dr. `,
            decorationStyle: ``,
            decorationColor: ``,
            alignment: `left`,
          },
          {
            text: ``,
            margin: [0, 0, 0, 0],
            width: 5,
            decoration: ``,
            decorationColor: ``,
            bold: false,
            pageBreak: ``,
            decorationStyle: ``,
            color: ``,
            alignment: `left`,
            fontSize: 15,
            preserveLeadingSpaces: true,
          },
          {
            stack: [
              {
                text: props.items?.doctorNameEn,
                fontSize: 15,
                pageBreak: ``,
                decoration: ``,
                alignment: `left`,
                width: `auto`,
                color: ``,
                bold: false,
                margin: [0, 0, 0, -15],
                decorationStyle: ``,
                decorationColor: ``,
                preserveLeadingSpaces: true,
              },
              {
                margin: [0, 0, 0, 0],
                decorationColor: ``,
                width: `auto`,
                alignment: `left`,
                preserveLeadingSpaces: true,
                bold: false,
                decorationStyle: ``,
                color: ``,
                fontSize: 15,
                decoration: ``,
                pageBreak: ``,
                text: `...............................................................................................................................................................................................`,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            decoration: ``,
            alignment: `left`,
            text: `     Medical License No. `,
            pageBreak: ``,
            decorationStyle: ``,
            width: `auto`,
            fontSize: 15,
            color: ``,
            preserveLeadingSpaces: true,
            bold: false,
          },
          {
            pageBreak: ``,
            decoration: ``,
            alignment: `left`,
            width: 10,
            color: ``,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            text: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decorationColor: ``,
            bold: false,
          },
          {
            stack: [
              {
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                preserveLeadingSpaces: true,
                color: ``,
                margin: [0, 0, 0, -15],
                pageBreak: ``,
                decoration: ``,
                decorationColor: ``,
                alignment: `left`,
                text: props.items?.doctorCertNumber ? props.items?.doctorCertNumber : " ",
              },
              {
                pageBreak: ``,
                color: ``,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                decoration: ``,
                preserveLeadingSpaces: true,
                text: `..............................................................................................................................................................,`,
                alignment: `left`,
                margin: [0, 0, 0, 0],
                decorationColor: ``,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            alignment: `left`,
            color: ``,
            pageBreak: ``,
            text: `     Residential address`,
            decorationStyle: ``,
            fontSize: 15,
            width: `auto`,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decoration: ``,
          },
          {
            width: 10,
            bold: false,
            decorationColor: ``,
            decorationStyle: ``,
            color: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            pageBreak: ``,
            fontSize: 15,
            alignment: `left`,
            text: ``,
            decoration: ``,
          },
          {
            stack: [
              {
                margin: [0, 0, 0, -15],
                decorationColor: ``,
                width: `auto`,
                text: props.items?.hospitalAddress,
                color: ``,
                preserveLeadingSpaces: true,
                fontSize: 15,
                bold: false,
                decorationStyle: ``,
                pageBreak: ``,
                decoration: ``,
                alignment: `left`,
              },
              {
                width: `auto`,
                decoration: ``,
                margin: [0, 0, 0, 0],
                color: ``,
                bold: false,
                alignment: `left`,
                pageBreak: ``,
                preserveLeadingSpaces: true,
                fontSize: 15,
                decorationStyle: ``,
                text: `...............................................................................................................................................................,`,
                decorationColor: ``,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            fontSize: 15,
            decoration: ``,
            preserveLeadingSpaces: true,
            color: ``,
            width: `auto`,
            decorationStyle: ``,
            alignment: `left`,
            pageBreak: ``,
            decorationColor: ``,
            text: `     I had examined Mr./Mrs./Miss `,
          },
          {
            decoration: ``,
            decorationColor: ``,
            text: ``,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            color: ``,
            pageBreak: ``,
            width: 10,
            fontSize: 15,
            bold: false,
            alignment: `left`,
          },
          {
            stack: [
              {
                margin: [0, 0, 0, -15],
                preserveLeadingSpaces: true,
                alignment: `left`,
                decorationColor: ``,
                width: `auto`,
                bold: false,
                decoration: ``,
                fontSize: 15,
                pageBreak: ``,
                decorationStyle: ``,
                color: ``,
                text: `${
                  props.items.patientInfo?.full_name_en !== ""
                    ? props.items?.patientInfo?.full_name_en
                    : props.items?.patientInfo?.full_name_th !== ""
                    ? props.items?.patientInfo?.full_name_th
                    : props.items?.patientInfo?.full_name
                }`,
              },
              {
                text: `..............................................................................................................................................`,
                margin: [0, 0, 0, 0],
                preserveLeadingSpaces: true,
                color: ``,
                width: `auto`,
                alignment: `left`,
                fontSize: 15,
                decoration: ``,
                decorationColor: ``,
                bold: false,
                pageBreak: ``,
                decorationStyle: ``,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            alignment: `left`,
            pageBreak: ``,
            decorationColor: ``,
            color: ``,
            fontSize: 15,
            bold: false,
            text: `     On date`,
            width: `auto`,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decoration: ``,
          },
          {
            width: 10,
            fontSize: 15,
            decoration: ``,
            preserveLeadingSpaces: true,
            color: ``,
            bold: false,
            alignment: `left`,
            text: ``,
            decorationStyle: ``,
            decorationColor: ``,
            pageBreak: ``,
          },
          {
            stack: [
              {
                text: props.items?.examDay,
                bold: false,
                decoration: ``,
                color: ``,
                margin: [0, 0, 0, -15],
                preserveLeadingSpaces: true,
                decorationStyle: ``,
                alignment: `center`,
                pageBreak: ``,
                decorationColor: ``,
                fontSize: 15,
                width: `auto`,
              },
              {
                text: `....................`,
                bold: false,
                decoration: ``,
                color: ``,
                margin: [0, 0, 0, 0],
                preserveLeadingSpaces: true,
                decorationStyle: ``,
                alignment: `left`,
                pageBreak: ``,
                decorationColor: ``,
                fontSize: 15,
                width: `auto`,
              },
            ],
            width: `auto`,
          },
          {
            bold: false,
            color: ``,
            preserveLeadingSpaces: true,
            text: ``,
            decoration: ``,
            fontSize: 15,
            decorationColor: ``,
            decorationStyle: ``,
            width: 10,
            pageBreak: ``,
            alignment: `left`,
          },
          {
            decorationColor: ``,
            pageBreak: ``,
            decoration: ``,
            bold: false,
            color: ``,
            text: `month `,
            decorationStyle: ``,
            width: `auto`,
            preserveLeadingSpaces: true,
            fontSize: 15,
            alignment: `left`,
          },
          {
            fontSize: 15,
            decoration: ``,
            decorationStyle: ``,
            bold: false,
            color: ``,
            pageBreak: ``,
            width: 10,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            alignment: `right`,
            text: ``,
          },
          {
            stack: [
              {
                text: props.items?.examMonth,
                bold: false,
                decoration: ``,
                color: ``,
                margin: [0, 0, 0, -15],
                preserveLeadingSpaces: true,
                decorationStyle: ``,
                alignment: `center`,
                pageBreak: ``,
                decorationColor: ``,
                fontSize: 15,
                width: `auto`,
              },
              {
                text: `................................`,
                bold: false,
                decoration: ``,
                color: ``,
                margin: [0, 0, 0, 0],
                preserveLeadingSpaces: true,
                decorationStyle: ``,
                alignment: `left`,
                pageBreak: ``,
                decorationColor: ``,
                fontSize: 15,
                width: `auto`,
              },
            ],
            width: `auto`,
          },
          {
            preserveLeadingSpaces: true,
            color: ``,
            pageBreak: ``,
            fontSize: 15,
            alignment: `left`,
            width: 10,
            decoration: ``,
            text: ``,
            bold: false,
            decorationColor: ``,
            decorationStyle: ``,
          },
          {
            width: `auto`,
            decoration: ``,
            alignment: `left`,
            fontSize: 15,
            color: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            bold: false,
            text: `year `,
            decorationStyle: ``,
            pageBreak: ``,
          },
          {
            fontSize: 15,
            color: ``,
            decorationStyle: ``,
            text: ``,
            alignment: `right`,
            pageBreak: ``,
            decorationColor: ``,
            decoration: ``,
            bold: false,
            width: 10,
            preserveLeadingSpaces: true,
          },
          {
            stack: [
              {
                text: props.items?.examYear,
                bold: false,
                decoration: ``,
                color: ``,
                margin: [0, 0, 0, -15],
                preserveLeadingSpaces: true,
                decorationStyle: ``,
                alignment: `center`,
                pageBreak: ``,
                decorationColor: ``,
                fontSize: 15,
                width: `auto`,
              },
              {
                text: `................................`,
                bold: false,
                decoration: ``,
                color: ``,
                margin: [0, 0, 0, 0],
                preserveLeadingSpaces: true,
                decorationStyle: ``,
                alignment: `left`,
                pageBreak: ``,
                decorationColor: ``,
                fontSize: 15,
                width: `auto`,
              },
            ],
            width: `auto`,
          },
          {
            width: 10,
            alignment: `left`,
            color: ``,
            text: ``,
            fontSize: 15,
            decorationStyle: ``,
            decoration: ``,
            pageBreak: ``,
            bold: false,
            preserveLeadingSpaces: true,
            decorationColor: ``,
          },
          {
            decorationColor: ``,
            fontSize: 15,
            decorationStyle: ``,
            bold: false,
            text: `Details are as follows:`,
            decoration: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            color: ``,
            width: `auto`,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            decoration: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            width: `auto`,
            alignment: `left`,
            text: `     weight `,
            decorationColor: ``,
            fontSize: 15,
            color: ``,
            decorationStyle: ``,
          },
          {
            decorationColor: ``,
            bold: false,
            alignment: `left`,
            decorationStyle: ``,
            text: ``,
            color: ``,
            width: 10,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decoration: ``,
            fontSize: 15,
          },
          {
            stack: [
              {
                alignment: `center`,
                bold: false,
                decorationStyle: ``,
                text: props.items?.patientWeight ? props.items?.patientWeight : "-",
                preserveLeadingSpaces: true,
                decorationColor: ``,
                color: ``,
                fontSize: 15,
                margin: [0, 0, 0, -15],
                decoration: ``,
                width: `auto`,
                pageBreak: ``,
              },
              {
                alignment: `left`,
                text: `................`,
                fontSize: 15,
                bold: false,
                decoration: ``,
                width: `auto`,
                decorationStyle: ``,
                color: ``,
                margin: [0, 0, 0, 0],
                preserveLeadingSpaces: true,
                decorationColor: ``,
                pageBreak: ``,
              },
            ],
            width: `auto`,
          },
          {
            text: `Kg, height`,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            width: `auto`,
            decorationColor: ``,
            fontSize: 15,
            decoration: ``,
            bold: false,
            decorationStyle: ``,
            color: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
          },
          {
            width: 10,
            decorationColor: ``,
            decoration: ``,
            pageBreak: ``,
            decorationStyle: ``,
            color: ``,
            alignment: `left`,
            bold: false,
            preserveLeadingSpaces: true,
            fontSize: 15,
            text: ``,
          },
          {
            stack: [
              {
                decoration: ``,
                bold: false,
                decorationStyle: ``,
                preserveLeadingSpaces: true,
                alignment: `center`,
                width: `auto`,
                decorationColor: ``,
                margin: [0, 0, 0, -15],
                color: ``,
                fontSize: 15,
                text: props.items?.patientHeight ? props.items?.patientHeight : "-",
                pageBreak: ``,
              },
              {
                alignment: `left`,
                bold: false,
                decorationStyle: ``,
                decorationColor: ``,
                fontSize: 15,
                text: `.................`,
                width: `auto`,
                pageBreak: ``,
                color: ``,
                margin: [0, 0, 0, 0],
                preserveLeadingSpaces: true,
                decoration: ``,
              },
            ],
            width: `auto`,
          },
          {
            preserveLeadingSpaces: true,
            fontSize: 15,
            width: `auto`,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            decorationStyle: ``,
            alignment: `left`,
            decoration: ``,
            text: `cm, blood pressure`,
            color: ``,
            pageBreak: ``,
            bold: false,
          },
          {
            width: 10,
            text: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            bold: false,
            color: ``,
            alignment: `left`,
            fontSize: 15,
            pageBreak: ``,
            decorationColor: ``,
          },
          {
            stack: [
              {
                margin: [0, 0, 0, -15],
                width: `auto`,
                decorationColor: ``,
                color: ``,
                pageBreak: ``,
                bold: false,
                decorationStyle: ``,
                preserveLeadingSpaces: true,
                text: props.items?.patientPressure ? props.items?.patientPressure : "-",
                fontSize: 15,
                alignment: `center`,
                decoration: ``,
              },
              {
                pageBreak: ``,
                preserveLeadingSpaces: true,
                fontSize: 15,
                width: `auto`,
                margin: [0, 0, 0, 0],
                decorationStyle: ``,
                alignment: `left`,
                decoration: ``,
                decorationColor: ``,
                color: ``,
                bold: false,
                text: `..............`,
              },
            ],
            width: `auto`,
          },
          {
            decorationColor: ``,
            bold: false,
            pageBreak: ``,
            decoration: ``,
            width: `auto`,
            decorationStyle: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            color: ``,
            text: `mmHg, pulse`,
          },
          {
            decoration: ``,
            fontSize: 15,
            alignment: `left`,
            bold: false,
            width: 10,
            text: ``,
            color: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationStyle: ``,
            decorationColor: ``,
          },
          {
            stack: [
              {
                margin: [0, 0, 0, -15],
                color: ``,
                fontSize: 15,
                alignment: `center`,
                width: `auto`,
                preserveLeadingSpaces: true,
                pageBreak: ``,
                decoration: ``,
                decorationStyle: ``,
                text: props.items?.patientPulse ? props.items?.patientPulse : "-",
                bold: false,
                decorationColor: ``,
              },
              {
                decorationColor: ``,
                margin: [0, 0, 0, 0],
                bold: false,
                decoration: ``,
                pageBreak: ``,
                alignment: `left`,
                fontSize: 15,
                width: `auto`,
                text: `.............`,
                decorationStyle: ``,
                preserveLeadingSpaces: true,
                color: ``,
              },
            ],
            width: `auto`,
          },
          {
            decorationStyle: ``,
            fontSize: 15,
            width: `auto`,
            bold: false,
            decoration: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            color: ``,
            text: `beats per minute`,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            alignment: `left`,
            decorationStyle: ``,
            text: `     general health condition`,
            decorationColor: ``,
            width: `auto`,
            bold: false,
            preserveLeadingSpaces: true,
            fontSize: 15,
            pageBreak: ``,
            decoration: ``,
          },
          {
            text: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            width: 10,
            decorationColor: ``,
            decoration: ``,
            bold: false,
            pageBreak: ``,
            fontSize: 15,
            color: ``,
          },
          {
            image: props.items?.summaryCheck === "normal" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decorationColor: ``,
            fontSize: 15,
            width: `auto`,
            text: ` normal`,
            pageBreak: ``,
            color: ``,
            alignment: `left`,
            bold: false,
          },
          {
            text: ``,
            decorationColor: ``,
            decoration: ``,
            alignment: `left`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            color: ``,
            decorationStyle: ``,
            bold: false,
            fontSize: 15,
            width: 10,
          },
          {
            image: props.items?.summaryCheck === "abnormal" ? "checked" : "unchecked",
            margin: [0, 4, 0, 0],
            width: 10,
            border: [false, false, false, false],
          },
          {
            decorationColor: ``,
            alignment: `left`,
            decorationStyle: ``,
            text: ` abnormal`,
            color: ``,
            bold: false,
            width: `auto`,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decoration: ``,
            pageBreak: ``,
          },
          {
            decorationStyle: ``,
            color: ``,
            pageBreak: ``,
            width: 10,
            alignment: `left`,
            decorationColor: ``,
            decoration: ``,
            fontSize: 15,
            text: ``,
            bold: false,
            preserveLeadingSpaces: true,
          },
          {
            decorationStyle: ``,
            text: `(please specify)`,
            alignment: `left`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            color: ``,
            width: `auto`,
            bold: false,
            decorationColor: ``,
            decoration: ``,
            fontSize: 15,
          },
          {
            text: ``,
            bold: false,
            decorationStyle: ``,
            color: ``,
            preserveLeadingSpaces: true,
            width: 10,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            alignment: `left`,
            decoration: ``,
            fontSize: 15,
            decorationColor: ``,
          },
          {
            stack: [
              {
                bold: false,
                preserveLeadingSpaces: true,
                alignment: `left`,
                decoration: ``,
                width: `auto`,
                margin: [0, 0, 0, -15],
                fontSize: 15,
                decorationStyle: ``,
                color: ``,
                pageBreak: ``,
                decorationColor: ``,
                text:
                  props.items?.summaryCheck === "abnormal"
                    ? props.items?.explainSummary &&
                      props.items?.explainSummary !== null &&
                      props.items?.explainSummary !== ""
                      ? props.items?.explainSummary
                      : " "
                    : " ",
              },
              {
                decoration: ``,
                color: ``,
                decorationColor: ``,
                decorationStyle: ``,
                preserveLeadingSpaces: true,
                bold: false,
                text: `....................................................................`,
                pageBreak: ``,
                fontSize: 15,
                alignment: `left`,
                width: `auto`,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        fontSize: 15,
        pageBreak: `before`,
        decorationStyle: ``,
        color: ``,
        width: `auto`,
        alignment: `left`,
        bold: false,
        text: `     I certify that the person is fit to word and from disability with no symptom of psychosis , delusion mental`,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        decoration: ``,
      },
      {
        bold: false,
        decoration: ``,
        text: `retardation, drug addiction, and alcohol use disorder. Also, there is no sign and symptom of the following`,
        color: ``,
        alignment: `left`,
        decorationColor: ``,
        fontSize: 15,
        width: `auto`,
        decorationStyle: ``,
        pageBreak: ``,
        preserveLeadingSpaces: true,
      },
      {
        decorationStyle: ``,
        text: `diseases is no sign and symptom of the following diseases`,
        preserveLeadingSpaces: true,
        bold: false,
        decoration: ``,
        width: `auto`,
        fontSize: 15,
        color: ``,
        decorationColor: ``,
        alignment: `left`,
        pageBreak: ``,
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decoration: ``,
            decorationStyle: ``,
            pageBreak: ``,
            fontSize: 15,
            bold: false,
            alignment: `left`,
            text: ``,
            width: 10,
            color: ``,
          },
          {
            preserveLeadingSpaces: true,
            bold: false,
            pageBreak: ``,
            color: ``,
            decorationStyle: ``,
            text: ` (1) Leprosy at the infective stage or the stage with apparent symptoms that may be disgusted by society;`,
            decoration: ``,
            fontSize: 15,
            alignment: `left`,
            decorationColor: ``,
            width: `auto`,
          },
        ],
      },
      {
        columns: [
          {
            text: ``,
            pageBreak: ``,
            width: 10,
            decorationStyle: ``,
            bold: false,
            decoration: ``,
            alignment: `left`,
            fontSize: 15,
            preserveLeadingSpaces: true,
            color: ``,
            decorationColor: ``,
          },
          {
            color: ``,
            alignment: `left`,
            width: `auto`,
            pageBreak: ``,
            bold: false,
            text: ` (2) Tuberculosis at the dangerous period; `,
            decorationColor: ``,
            decorationStyle: ``,
            decoration: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            width: 10,
            decorationColor: ``,
            bold: false,
            color: ``,
            pageBreak: ``,
            alignment: `left`,
            decoration: ``,
            text: ``,
          },
          {
            fontSize: 15,
            color: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decoration: ``,
            pageBreak: ``,
            bold: false,
            decorationStyle: ``,
            width: `auto`,
            text: ` (3) Elephantiasis at the stage with apparent symptoms that may be disgusted by society`,
          },
        ],
      },
      {
        columns: [
          {
            width: 10,
            decorationStyle: ``,
            bold: false,
            text: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decoration: ``,
            fontSize: 15,
            decorationColor: ``,
            alignment: `left`,
            color: ``,
          },
          {
            preserveLeadingSpaces: true,
            decoration: ``,
            fontSize: 15,
            text: ` (4) other (if any)  ..........................................................................................................................................................................`,
            alignment: `left`,
            decorationStyle: ``,
            color: ``,
            pageBreak: ``,
            width: `auto`,
            bold: false,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            bold: false,
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationStyle: ``,
            fontSize: 15,
            text: `(2) Physician’s opinion and recommendation`,
            color: ``,
            alignment: `left`,
            width: `auto`,
            pageBreak: ``,
          },
          {
            width: 10,
            decorationStyle: ``,
            alignment: `left`,
            fontSize: 15,
            pageBreak: ``,
            bold: false,
            decorationColor: ``,
            text: ``,
            decoration: ``,
            color: ``,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            stack: [
              {
                pageBreak: ``,
                alignment: `left`,
                margin: [0, 0, 0, -15],
                decorationStyle: ``,
                fontSize: 15,
                color: ``,
                decoration: ``,
                bold: false,
                decorationColor: ``,
                width: `auto`,
                preserveLeadingSpaces: true,
                text: props.items?.comment ? props.items?.comment : " ",
              },
              {
                alignment: `left`,
                pageBreak: ``,
                text: `.........................................................................................................................`,
                decorationStyle: ``,
                width: `auto`,
                margin: [0, 0, 0, 0],
                decorationColor: ``,
                fontSize: 15,
                decoration: ``,
                color: ``,
                preserveLeadingSpaces: true,
                bold: false,
              },
            ],
          },
        ],
      },
      {
        decorationStyle: ``,
        width: `auto`,
        decoration: ``,
        color: ``,
        alignment: `left`,
        bold: false,
        text: ` `,
        preserveLeadingSpaces: true,
        fontSize: 15,
        decorationColor: ``,
        pageBreak: ``,
      },
      {
        columns: [
          {
            color: ``,
            alignment: `left`,
            fontSize: 15,
            bold: false,
            decorationColor: ``,
            decoration: ``,
            text: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            pageBreak: ``,
            width: 258,
          },
          {
            fontSize: 15,
            color: ``,
            bold: false,
            alignment: `left`,
            decoration: ``,
            preserveLeadingSpaces: true,
            text: `Signature  `,
            width: `auto`,
            pageBreak: ``,
            decorationStyle: ``,
            decorationColor: ``,
          },
          {
            width: 150,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            fontSize: 15,
            color: ``,
            pageBreak: ``,
            text: `....................................................`,
            alignment: `center`,
            decoration: ``,
            bold: false,
            decorationColor: ``,
          },
          {
            bold: false,
            decoration: ``,
            decorationColor: ``,
            width: `auto`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            color: ``,
            alignment: `left`,
            text: ` Physician`,
            fontSize: 15,
          },
        ],
      },
    ],
    header: props.header,
    footer: props.footer,
    images: {
      checked: FormCheckboxImages.checked,
      unchecked: FormCheckboxImages.unchecked,
    },
  };
}
