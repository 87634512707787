import WasmController from "react-lib/frameworks/WasmController";
import DefaultBarcodeView from "issara-sdk/apis/DefaultBarcodeView_core";
import BarcodePrefixList from "issara-sdk/apis/BarcodePrefixList_core";
import DrugOrderDetailForAction from "issara-sdk/apis/DrugOrderDetailForAction_apps_TPD";
import EncounterDetail from "issara-sdk/apis/EncounterDetail_core";
import SupplyOrderDetail from "issara-sdk/apis/SupplyOrderDetail_apps_MSD";
import SupplyTransferDetail from "issara-sdk/apis/SupplyTransferDetail_apps_MSD";


export type State = {
  errorMessage?: any
  successMessage?: any
  ReceiveOrderSequence?: {
    sequenceIndex?: string;
    selectedCode?: string | null;
    selectedType?: string | null;
    isOpenModReceive?: boolean;
  } | null;
  ChoiceBarcode?: any[];
  EncounterReceive?: any;
  DispensingOrderSupplySequence?: any;
  SupplyDeliverySequence?: any
  selectedDrugOrderWorking?: any
  selectedEncounter?: any
};

export const StateInitial: State = {
  ReceiveOrderSequence: null,
  ChoiceBarcode: [],
  EncounterReceive: {},
  errorMessage: null,
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetChoicesBarcode"; params: any }
// | { messsage: "SelectDrugOrderWorking"; params: any };

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;



export const SearchReceiveOrder: Handler = async (controller, params) => {
  const state = controller.getState();

  if (!state.ReceiveOrderSequence) return;

  if (!params?.action) return;

  let barcode = `${state.ReceiveOrderSequence?.selectedType || ""}${state.ReceiveOrderSequence?.selectedCode || ""}  `;

  if (params?.action === "search") {
    const orderReceive = await DefaultBarcodeView.get({
      barcode: barcode.trim(),
      params: "",
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });

    const defaultState = {
      DispensingOrderSupplySequence: {
        ...state.DispensingOrderSupplySequence,
        selectedOrder: null,
        selectedOrderId: null,
      },
      SupplyDeliverySequence: {
        ...state.SupplyDeliverySequence,
        selectedTransferId: null,
        deliveryManager: null,
      },
      EncounterReceive: {},
      selectedDrugOrderWorking: null
    }

    if (orderReceive?.[1]) {
      controller.setState({
        ReceiveOrderSequence: {
          selectedCode: null,
          sequenceIndex: "START"
        },
        errorMessage: {
          ...state.errorMessage,
          [params.card]: { error: true, },
        },
        ...defaultState
      });
    } else {
      if (orderReceive[0].type === "DrugOrder") {
        await DrugOrderDetailForAction.retrieve({
          pk: orderReceive[0].pk,
          apiToken: controller.apiToken,
          extra: {
            division: controller.data.division,
          },
        });

        const encounter = await EncounterDetail.retrieve({
          pk: orderReceive[0].encounter,
          apiToken: controller.apiToken,
          extra: {
            division: controller.data.division,
          },
        });

        controller.setState({
          ...defaultState,
          EncounterReceive: encounter[0],
        });

        controller.handleEvent({
          message: "SelectDrugOrderWorking",
          params: {
            drugOrder: {
              id: orderReceive[0].pk,
              encounter: orderReceive[0].encounter,
            },
            selectDrugOnly: true
          },
        } as any);

      } else if (orderReceive[0].type === "SupplyOrder") {
        const supplyOrder = await SupplyOrderDetail.retrieve({
          pk: orderReceive[0].pk,
          apiToken: controller.apiToken,
          extra: {
            division: controller.data.division,
          },
        })

        const encounter = await EncounterDetail.retrieve({
          pk: supplyOrder[0].encounter,
          apiToken: controller.apiToken,
          extra: {
            division: controller.data.division,
          },
        });

        console.log("selectedEncounter: null")
        controller.setState({
          ...defaultState,
          DispensingOrderSupplySequence: {
            ...state.DispensingOrderSupplySequence,
            selectedOrder: supplyOrder?.[0],
            selectedOrderId: supplyOrder?.[0]?.id,
            sequenceIndex: "SearchAndSelect",
          },
          selectedEncounter: encounter[0] || null
        })
      } else if (orderReceive[0].type === "SupplyTransfer") {
        const supplyTransfer = await SupplyTransferDetail.retrieve({
          pk: orderReceive[0].pk,
          apiToken: controller.apiToken,
          extra: {
            division: controller.data.division,
          },
        })

        controller.setState({
          ...defaultState,
          SupplyDeliverySequence: {
            ...state.SupplyDeliverySequence,
            sequenceIndex: "SearchAndSelect",
            selectedTransferId: supplyTransfer?.[0]?.pk,
            deliveryManager: supplyTransfer?.[0],
            isCardReceive: true
          },
        });

      }
      else {
        controller.setState({
          ReceiveOrderSequence: {
            selectedCode: null,
            sequenceIndex: "START"
          },
          errorMessage: {
            ...state.errorMessage,
            [params.card]: { error: true, },
          },
          ...defaultState
        });
      }
    }
  }
};

export const GetChoicesBarcode: Handler = async (controller, params) => {
  const state = controller.getState();

  const choiceBarcode = await BarcodePrefixList.list({
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division,
    },
  });

  controller.setState({ ChoiceBarcode: choiceBarcode[0] });
};
