import WasmController from "react-lib/frameworks/WasmController";

import moment from "moment";

// APIs
// DEN
import MediaDocumentDetailView from "issara-sdk/apis/MediaDocumentDetailView_apps_DENM";
import MediaDocumentListView from "issara-sdk/apis/MediaDocumentListView_apps_DENM";
import MediaDocumentWorkgroupListView from "issara-sdk/apis/MediaDocumentWorkgroupListView_apps_DEN";

// Serializer
import DentalMediaDocumentSerializerI from "issara-sdk/types/DentalMediaDocumentSerializer_apps_DEN";

// Interface
import { State as MainState } from "../../../../../HIS/MainHISInterface";
import { SetErrorMessage, SetProperty, mapOptions } from "../../common/CommonInterface";

// Utils
import { beToAd, formatDate } from "react-lib/utils/dateUtils";

export type State = Partial<{
  // sequence
  SettingMediaDocumentSequence: Partial<{
    sequenceIndex: "Start" | "Action" | null;
    mediaDocList: {
      items: DentalMediaDocumentSerializer[];
      total: number;
      activePage: number;
    };
    filter: FilterType;
    mediaDocDetail: Partial<DentalMediaDocumentSerializer> | null;
    workGroupOptions: OptionType[];
    showRequiredField: Record<keyof typeof REQUIRED_FIELDS, string[]> | null;
  }> | null;
}>;

export type FilterType = Partial<{
  name: string;
  workGroups: string;
  fromDate: string;
  toDate: string;
}>;

export type DentalMediaDocumentSerializer = {} & DentalMediaDocumentSerializerI;

type PickedState = Partial<Pick<MainState, "buttonLoadCheck" | "errorMessage" | "masterOptions">>;

export type PickedProps = Partial<Omit<PickedState, "masterOptions">>;

export type MasterOptionsType = Record<(typeof Masters)[number][0], OptionType[]>;

export type OptionType = {
  key: number | string;
  value: number | string;
  text: string;
};

// Sequence
type SeqState = {
  sequence: "SettingMediaDocument";
  restart?: boolean;
  clear?: boolean;
};

// Handle Action
type ActionType =
  // Search
  | { action: "SEARCH"; activePage: number }
  // Action
  | { action: "SELECT_ITEM"; data: DentalMediaDocumentSerializer }
  // Method
  | {
      action: "DELETE";
      card: string;
      mediaDocId: number;
      onSuccess?: () => any;
    }
  | { action: "SAVE"; card: string };

type SeqAct = ActionType & SeqState;
type SeqType<K> = K extends { action: string } ? Extract<SeqAct, K> : SeqState;

export type RunSequence = <K extends keyof SeqAct>(params: SeqType<Pick<SeqAct, K>>) => void;

export type SetProp = SetProperty<State & PickedState>;

type CustomExtract<T, U> = T extends object ? (U extends Partial<T> ? T : never) : never;

type Params<A extends ActionType["action"]> = CustomExtract<ActionType, { action: A }>;

export const StateInitial: State = {
  // sequence
  SettingMediaDocumentSequence: {
    sequenceIndex: null,
  },
};

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division?: number;
  device?: number;
};

export const DataInitial = {};

export const REQUIRED_FIELDS = {
  name: "ชื่อเอกสาร",
  workgroup: "กลุ่มงาน",
};

const Masters = [["workgroup", {}]] as const;

export const CARD_SETTING_MEDIA_DOCUMENT = "CardSettingMediaDocument";

type Optional<T> = {
  [K in keyof T]: `${typeof CARD_SETTING_MEDIA_DOCUMENT}_${T[K] & string}`;
};

export const ACTIONS = {
  SEARCH: "SEARCH",
  SAVE: "SAVE",
  DELETE: "DELETE",
  SELECT_ITEM: "SELECT_ITEM",
} as const;

export const BTN_ACTS = Object.fromEntries(
  Object.keys(ACTIONS).map((key) => [key, `${CARD_SETTING_MEDIA_DOCUMENT}_${key}`])
) as Optional<typeof ACTIONS>;

export const LIST_LIMIT = 20;

const DATE_FORMAT = "YYYY-MM-DD";

type Handler<P = any, R = any> = (
  controller: WasmController<State & PickedState, Event, Data>,
  params: P
) => R;

/* ------------------------------------------------------ */

/*                          START                         */

/* ------------------------------------------------------ */
export const GetMaster: Handler<SeqState> = async (controller, params) => {
  const state = controller.getState();

  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: Masters,
    },
  } as any);

  controller.setState(
    {
      SettingMediaDocumentSequence: {
        ...state.SettingMediaDocumentSequence,
        sequenceIndex: "Action",
      },
    },
    () => {
      Action(controller, { action: ACTIONS.SEARCH, activePage: 1 });
    }
  );
};

/* ------------------------------------------------------ */

/*                      Handle Action                     */

/* ------------------------------------------------------ */
export const Action: Handler<ActionType> = async (controller, params) => {
  const actionHandlers = {
    [ACTIONS.SEARCH]: HandleSearch,
    [ACTIONS.DELETE]: HandleDelete,
    [ACTIONS.SAVE]: HandleSave,
    [ACTIONS.SELECT_ITEM]: HandleSelectItem,
  };

  const action = params.action;

  if (action && actionHandlers[action]) {
    return actionHandlers[action](controller, params as any);
  }
};

const HandleSearch: Handler<Params<"SEARCH">> = async (controller, params) => {
  let state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SEARCH]: "LOADING" },
  });

  const [list, wg] = await Promise.all([
    GetPatientCommDocList(controller, params),
    MediaDocumentWorkgroupListView.list({ apiToken: controller.apiToken }),
  ]);

  state = controller.getState();

  const wgOptions = mapOptions(wg[0]);

  controller.setState({
    SettingMediaDocumentSequence: {
      ...state.SettingMediaDocumentSequence,
      mediaDocList: {
        items: list?.items || [],
        total: list?.total || 0,
        activePage: params.activePage,
      },
      workGroupOptions: wgOptions,
    },
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SEARCH]: "SUCCESS" },
  });
};

const HandleDelete: Handler<Params<"DELETE">> = async (controller, params) => {
  let state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.DELETE]: "LOADING" },
  });

  const [_, error] = await MediaDocumentDetailView.delete({
    apiToken: controller.apiToken,
    pk: params.mediaDocId,
  });

  if (error) {
    SetErrorMessage(controller, { ...params, error });
  } else {
    const activePage = state.SettingMediaDocumentSequence?.mediaDocList?.activePage || 1;

    controller.setState(
      {
        SettingMediaDocumentSequence: {
          ...state.SettingMediaDocumentSequence,
          mediaDocDetail: null,
        },
        buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.DELETE]: null },
      },
      () => Action(controller, { action: "SEARCH", activePage })
    );

    params.onSuccess?.();
  }
};

const HandleSave: Handler<Params<"SAVE">> = async (controller, params) => {
  let state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SAVE]: "LOADING" },
  });

  const detail = { ...(state.SettingMediaDocumentSequence?.mediaDocDetail || {}) };

  const api = detail.id ? MediaDocumentDetailView.update : MediaDocumentListView.create;

  if (!(detail.qr_image instanceof File) && detail.qr_image !== "") {
    delete detail.qr_image;
  }

  detail.qr_expired_at = detail.qr_expired_at ? beToAd(detail.qr_expired_at)?.toISOString() : "";

  const [_, error] = await api({
    apiToken: controller.apiToken,
    data: detail,
    pk: detail.id,
  });

  if (error) {
    controller.setState(
      {
        SettingMediaDocumentSequence: {
          ...state.SettingMediaDocumentSequence,
          showRequiredField: error,
        },
      },
      () => SetErrorMessage(controller, { ...params, error: error })
    );
  } else {
    const activePage = state.SettingMediaDocumentSequence?.mediaDocList?.activePage || 1;

    controller.setState(
      {
        SettingMediaDocumentSequence: {
          ...state.SettingMediaDocumentSequence,
          mediaDocDetail: null,
          showRequiredField: null,
        },
        buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SAVE]: null },
      },
      () => Action(controller, { action: "SEARCH", activePage: !detail.id ? 1 : activePage })
    );
  }
};

const HandleSelectItem: Handler<Params<"SELECT_ITEM">> = async (controller, params) => {
  const state = controller.getState();

  const data = params.data;

  if (data.qr_expired_at) {
    data.qr_expired_at = formatDate(moment(data.qr_expired_at));
  }

  controller.setState({
    SettingMediaDocumentSequence: {
      ...state.SettingMediaDocumentSequence,
      mediaDocDetail: data,
      showRequiredField: null,
    },
  });
};
/* ------------------------------------------------------ */

/*                          APIs                          */

/* ------------------------------------------------------ */
const GetPatientCommDocList: Handler<
  { activePage: number },
  Promise<{ items: DentalMediaDocumentSerializer[]; total: number }>
> = async (controller, params) => {
  const state = controller.getState();

  const seq = state.SettingMediaDocumentSequence || {};
  const filter = seq.filter || {};
  const offset = (params.activePage - 1) * LIST_LIMIT;

  const [result] = await MediaDocumentListView.list({
    apiToken: controller.apiToken,
    params: {
      name__icontains: filter.name,
      workgroup: filter.workGroups,
      qr_expired_at__gte: filter.fromDate
        ? beToAd(filter.fromDate)?.format(DATE_FORMAT)
        : undefined,
      qr_expired_at__lte: filter.toDate ? beToAd(filter.toDate)?.format(DATE_FORMAT) : undefined,
      offset,
      limit: LIST_LIMIT,
    },
  });

  let items: any[] = result?.items || [];

  return { items, total: result?.total || 0 };
};
