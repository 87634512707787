import { formatDropdown } from "react-lib/utils";
export default class PatientSearchBoxController {

	constructor(coreManager, regManager) {
		this.coreManager = coreManager;
		this.regManager = regManager;
	}

	getPatientList = async ({ hn, fullName, citizenId, nationality, patientCurrentPage, cancelToken = null }) => {
		var patientList = [];
		var numberOfPage = null;
		let limit = 40;
		let params = {
      hn,
      fullName,
      is_old_name_search: true,
      citizenId: citizenId,
      nationality,
      offset: (patientCurrentPage - 1) * limit,
      limit,
    };

		const [data, error] = await this.regManager.getSearchAllPatient(params, cancelToken);
		if (data) {
			patientList = data.items;
			numberOfPage = Math.ceil(data.total / params.limit);
		}
		return [patientList, error, numberOfPage];
	};

	getNatinalities = async () => {
		let list = null;
		const [data, error] = await this.coreManager.getNationalities();
		if (data) {
			let nationalitiesList = formatDropdown(data, "full_name", "id");
			list = nationalitiesList.map(item => {
				delete item.is_active;
				return item;
			});
		}
		return [list, error];
	};

	choiceNationalities = async () => {
		var choices = [];
		const [dataList, error] = await this.coreManager.getNationalities();
		if (dataList) {
			let nationalities = formatDropdown(dataList, "full_name", "id");
			choices = nationalities.map(item => {
				delete item.is_active;
				return item;
			});
		}
		return choices;
	};

	getPatientOldName = async patientId => {
		let names = null;
		const [data, error] = await this.regManager.getPatientOldName(patientId);
		if (data) {
			names = data.items;
		}
		return [names, error];
	};


	getPatientByHN = async hn => {
		let names = null;
		const [data, error, network] = await this.regManager.getPatientByHN(hn);
		return [names, error, network];
	};

	searchPatients = async ({ hn, fullName, citizenId, nationality, patientCurrentPage }) => {
		var patientList = [];
		var patientError = null;
		var numberOfPage = 0;
		let limit = 40;
		let params = {
			hn,
			fullName,
			citizenId,
			nationality,
			is_old_name_search: true,
			offset: (patientCurrentPage - 1) * limit,
			limit,
		};
		const [data, error] = await this.regManager.getSearchAllPatient(params);
		if (data) {
			patientList = data.items;
			numberOfPage = Math.ceil(data.total / params.limit);
		} else {
			patientError = error;
		}
		return [patientList, patientError, numberOfPage];
	};

	loadPatientOldName = async patientId => {
		const [data, error] = await this.regManager.getPatientNameWithID(patientId);
		return [data, error];
	};
}
