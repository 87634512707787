import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Icon,
  FormGroup,
  FormField,
  Input,
  Button
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const ModExecuteRadiologyOrderUX = (props: any) => {
    return(
      <div
        style={{width:"100%", padding: "10px",  height: "100%"}}>
        <div
          id="ModExecuteRadiologyOrder-Div-ExecuteRadiologyOrderHeader"
          style={{backgroundColor: "var(--primary-theme-color,#5DBCD2)",margin: "-0.75rem -0.75rem -1rem", padding: "1rem", display: "flex", justifyContent: "space-between"}}>
          
          <div
            style={{fontSize: "1.1rem", fontWeight: "bold", padding: "2.5px 0", color: "white"}}>
            Execute Radiology Order
          </div>
          <div
            style={{cursor: "pointer", color: "white", marginTop: "2.5px"}}>
            
            <Icon
              className="white"
              name="close"
              onClick={props.onClose}>
            </Icon>
          </div>
        </div>
        <div
          className="ui divider">
          
        </div>
        <div
          className="ui form"
          style={{padding: "0 0.5rem"}}>
          
          <FormGroup>
            <FormField
              width={6}>
              <label>
                Execute by
              </label>
              <Input
                id="ModExecuteRadiologyOrder-Input-ExecuteBy"
                readOnly={true}
                value={props.executeBy || ""}>
              </Input>
            </FormField>
            <FormField
              width={5}>
              <label>
                Executed date
              </label>
              <DateTextBox
                id="ModExecuteRadiologyOrder-DateTextBox-ExecutedDate"
                onChange={props.onChangeDate?.("date")}
                value={props.detail?.date}>
              </DateTextBox>
            </FormField>
            <FormField
              width={5}>
              <label>
                Executed time
              </label>
              <div>
                {props.TimeComboBox}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField
              className="required"
              width={16}>
              <label>
                Radiologist
              </label>
              <div
                style={{width: "100%"}}>
                {props.SearchBoxRadiologist}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField
              width={16}>
              <label>
                Note
              </label>
              <Input
                id="ModExecuteRadiologyOrder-Input-Note"
                name="note"
                onChange={props.onChangeValue}
                value={props.detail?.note || ""}>
              </Input>
            </FormField>
          </FormGroup>
        </div>
        <div
          style={{margin: "2rem 0 0.5rem", display: "flex", justifyContent: "center"}}>
          
          <div>
            {props.ButtonSave}
          </div>
          <Button
            basic={true}
            color="red"
            onClick={props.onCancel}
            style={{width: "130px", margin: "0 2rem"}}>
            CANCEL
          </Button>
        </div>
      </div>
    )
}

ModExecuteRadiologyOrderUX.displayName = "ModExecuteRadiologyOrderUX";
export default React.memo(ModExecuteRadiologyOrderUX)

export const screenPropsDefault = {}

/* Date Time : Wed Jul 31 2024 12:50:01 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width:\"100%\", padding: \"10px\",  height: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "ModExecuteRadiologyOrder-Div-ExecuteRadiologyOrderHeader"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"var(--primary-theme-color,#5DBCD2)\",margin: \"-0.75rem -0.75rem -1rem\", padding: \"1rem\", display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Execute Radiology Order"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.1rem\", fontWeight: \"bold\", padding: \"2.5px 0\", color: \"white\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 122,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{cursor: \"pointer\", color: \"white\", marginTop: \"2.5px\"}"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 123,
      "name": "Icon",
      "parent": 122,
      "props": {
        "className": {
          "type": "value",
          "value": "white"
        },
        "name": {
          "type": "value",
          "value": "close"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": null,
      "id": 132,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 0.5rem\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 133,
      "name": "FormGroup",
      "parent": 132,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 134,
      "name": "FormField",
      "parent": 133,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": null,
      "id": 135,
      "name": "label",
      "parent": 134,
      "props": {
        "children": {
          "type": "value",
          "value": "Execute by"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 136,
      "name": "Input",
      "parent": 134,
      "props": {
        "id": {
          "type": "value",
          "value": "ModExecuteRadiologyOrder-Input-ExecuteBy"
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.executeBy || \"\""
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 137,
      "name": "FormField",
      "parent": 133,
      "props": {
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 138,
      "name": "FormField",
      "parent": 133,
      "props": {
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": null,
      "id": 139,
      "name": "label",
      "parent": 137,
      "props": {
        "children": {
          "type": "value",
          "value": "Executed date"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": null,
      "id": 140,
      "name": "label",
      "parent": 138,
      "props": {
        "children": {
          "type": "value",
          "value": "Executed time"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 141,
      "name": "DateTextBox",
      "parent": 137,
      "props": {
        "id": {
          "type": "value",
          "value": "ModExecuteRadiologyOrder-DateTextBox-ExecutedDate"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeDate?.(\"date\")"
        },
        "value": {
          "type": "code",
          "value": "props.detail?.date"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": null,
      "id": 142,
      "name": "div",
      "parent": 138,
      "props": {
        "children": {
          "type": "code",
          "value": "props.TimeComboBox"
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 143,
      "name": "FormGroup",
      "parent": 132,
      "props": {
      },
      "seq": 143,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 144,
      "name": "FormField",
      "parent": 143,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": null,
      "id": 145,
      "name": "label",
      "parent": 144,
      "props": {
        "children": {
          "type": "value",
          "value": "Radiologist"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": null,
      "id": 147,
      "name": "div",
      "parent": 144,
      "props": {
        "children": {
          "type": "code",
          "value": "props.SearchBoxRadiologist"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 148,
      "name": "FormGroup",
      "parent": 132,
      "props": {
      },
      "seq": 148,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 149,
      "name": "FormField",
      "parent": 148,
      "props": {
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": null,
      "id": 150,
      "name": "label",
      "parent": 149,
      "props": {
        "children": {
          "type": "value",
          "value": "Note"
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 151,
      "name": "Input",
      "parent": 149,
      "props": {
        "id": {
          "type": "value",
          "value": "ModExecuteRadiologyOrder-Input-Note"
        },
        "name": {
          "type": "value",
          "value": "note"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "value": {
          "type": "code",
          "value": "props.detail?.note || \"\""
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": null,
      "id": 152,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{margin: \"2rem 0 0.5rem\", display: \"flex\", justifyContent: \"center\"}"
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": null,
      "id": 153,
      "name": "div",
      "parent": 152,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSave"
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 154,
      "name": "Button",
      "parent": 152,
      "props": {
        "basic": {
          "type": "code",
          "value": "true"
        },
        "children": {
          "type": "value",
          "value": "CANCEL"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancel"
        },
        "style": {
          "type": "code",
          "value": "{width: \"130px\", margin: \"0 2rem\"}"
        }
      },
      "seq": 154,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": true,
  "name": "ModExecuteRadiologyOrderUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
