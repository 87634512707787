import React from "react";

// Interface
import {
  Event as MobEvent,
  State as MobState,
} from "../../../../patient-lib/MobTransformInterface";

// Common
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import ModInfo from "react-lib/apps/common/ModInfo";
import { useIntl } from "react-intl";

type ModMessageProps = {
  onEvent: (e: MobEvent) => any;
} & Pick<MobState, "successMessage" | "errorMessage">;

const ModMessage: React.FunctionComponent<ModMessageProps> = (props) => {
  const handleCloseModInfo = () => {
    props.onEvent({ message: "HandleCloseMessage", params: {} });
  };

  return (
    <div>
      <ModInfo
        open={!!props.successMessage || !!props.errorMessage}
        titleName={props.successMessage}
        className="mod-error-custom"
        titleColor={props.successMessage ? "green" : "red"}
        onApprove={handleCloseModInfo}
        onClose={handleCloseModInfo}
      >
        {props.errorMessage ? (
          <ErrorMessage error={props.errorMessage} />
        ) : null}
      </ModInfo>
    </div>
  );
};

ModMessage.defaultProps = {} as ModMessageProps;

export default ModMessage;
