import React, { useState } from "react";
import { Message, Form, Menu, Dimmer, Loader } from "semantic-ui-react";

import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import ModInfo from "react-lib/apps/common/ModInfo";

import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import { useIntl } from "react-intl";

const ModConfirmReprint: React.FunctionComponent<any> = (props) => {
  const intl = useIntl();
  const [activeItem, setActiveItem] = useState<"TH" | "EN">("TH");
  const [note, setNote] = useState<string>("");

  const handleItemClick = (e: any, v: any) => {
    setActiveItem(v.name);
  };

  const handleApprove = () => {
    props.onApprove?.({ note, language: activeItem });
  };

  return (
    <>
      {props.success ? (
        <ModInfo
          open={true}
          titleName="สำเร็จ"
          titleColor="green"
          onApprove={props.onClose}
        />
      ) : (
        <ModConfirm
          openModal={true}
          titleName="ยืนยัน REPRINT ?"
          onApprove={handleApprove}
          onDeny={props.onDeny}
          onCloseWithDimmerClick={props.onClose}
          content={
            <>
              <Dimmer active={props.loading} inverted>
                <Loader />
              </Dimmer>
              <Message color="yellow">{props?.name}</Message>
              <ErrorMessage error={props.error} />
              <Form>
                <Form.TextArea
                  label="note"
                  rows={3}
                  value={note}
                  onChange={(e: any, v: any) => setNote(v.value)}
                />
              </Form>
              <div style={{ display: "flex", alignItems: "baseline" }}>
                <label style={{ paddingRight: "1rem" }}>{intl.formatMessage({ id: "ฉลากยาภาษา" })}</label>
                <Menu inverted size="small">
                  <Menu.Item
                    name="TH"
                    active={activeItem === "TH"}
                    onClick={handleItemClick}
                    color="purple"
                  >
                    Thai
                  </Menu.Item>
                  <Menu.Item
                    name="EN"
                    active={activeItem === "EN"}
                    onClick={handleItemClick}
                    color="purple"
                  >
                    Eng
                  </Menu.Item>
                </Menu>
              </div>
            </>
          }
        />
      )}
    </>
  );
};

export default React.memo(ModConfirmReprint);
