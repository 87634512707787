import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'

const CardDrugTransferRequestQueueUX = (props: any) => {
    return(
      <div
        style={{padding: "5px"}}>
        <div
          style={{fontWeight: "bold", fontSize: "1.2rem"}}>
          คิวใบขอยา
        </div>
        <div>
          
          <Table
            data={props.drugTransferRequestList}
            headers="คิว,ประเภท,ขอ,ตรวจ,จ่าย,รับ,หน่วยงาน,สถานะ"
            keys={"no,type,requested,approved,delivered,received,is_requester,status_name"}
            minRows="10"
            showPagination={false}
            style={{height: "300px"}}>
          </Table>
        </div>
      </div>
    )
}

export default CardDrugTransferRequestQueueUX

export const screenPropsDefault = {}

/* Date Time : Tue May 31 2022 09:13:51 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding: \"5px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "คิวใบขอยา"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.2rem\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 3,
      "name": "Table",
      "parent": 2,
      "props": {
        "data": {
          "type": "code",
          "value": "props.drugTransferRequestList"
        },
        "headers": {
          "type": "value",
          "value": "คิว,ประเภท,ขอ,ตรวจ,จ่าย,รับ,หน่วยงาน,สถานะ"
        },
        "keys": {
          "type": "code",
          "value": "\"no,type,requested,approved,delivered,received,is_requester,status_name\""
        },
        "minRows": {
          "type": "value",
          "value": "10"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"300px\"}"
        }
      },
      "seq": 3,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardDrugTransferRequestQueueUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
