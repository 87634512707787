import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { Dropdown } from "semantic-ui-react";
// ui ORM
import CardServiceRefundUX from "./CardServiceRefundUX";

// Common
import ModInfo from "react-lib/appcon/common/ModInfo";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import { formatComma } from "react-lib/apps/common/PureReactTable";
import { useIntl } from "react-intl";

// Types
type CardServiceRefundProps = {
  // function
  setProp: any;
  errorMessage?: any;
  successMessage?: any;

  selectedPatient?: any;

  // seq
  runSequence?: any;
  ORServiceRefundSequence?: any;
};

type ModeType = "refundable" | "refunding" | "refunded";

// Const
const CANCEL_REFUND_OPTIONS = [
  {
    key: 0,
    value: "แจ้งคืนเงินผิดรายการ",
    text: "แจ้งคืนเงินผิดรายการ",
  },
  {
    key: 1,
    value: "ไม่มีเหตุอันควรให้คืนเงิน",
    text: "ไม่มีเหตุอันควรให้คืนเงิน",
  },
];

const REFUND_OPTIONS = [
  {
    key: 0,
    value: "การให้บริการมีปัญหา",
    text: "การให้บริการมีปัญหา",
  },
  {
    key: 1,
    value: "บันทึกบริการผิดพลาด",
    text: "บันทึกบริการผิดพลาด",
  },
  {
    key: 2,
    value: "ได้รับการยกเว้นค่าบริการ",
    text: "ได้รับการยกเว้นค่าบริการ",
  },
];

const CARD_SERVICE_REFUND = "CardServiceRefund";

const CardServiceRefund = (props: CardServiceRefundProps) => {
  const intl = useIntl();
  // Select
  const [selectedMode, setSelectedMode] = useState<ModeType>("refundable");
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [refundSelect, setRefundSelect] = useState<string>("");

  // Mod
  const [showModalRefund, setShowModalRefund] = useState<
    "confirm" | "cancel" | ""
  >("");

  const sourceRef = useRef<any>(null);

  // Memo Callback
  const encounterSelect = useMemo(() => {
    return props.ORServiceRefundSequence?.data?.encounterSelect;
  }, [props.ORServiceRefundSequence?.data?.encounterSelect]);

  // Callback
  const handleGetInvoiceItem = useCallback(
    (mode: ModeType) => {
      // * หาก มี การ request อยู่แต่มีการแก้ไขคำค้นหาให้ Cancel request
      if (sourceRef.current) {
        sourceRef.current.cancel("Cancel request");
      }

      props.runSequence({
        sequence: "ORServiceRefund",
        action: "getInvoiceItem",
        encounterId: encounterSelect,
        mode,
        sourceRef,
      });
    },
    [encounterSelect]
  );

  // Effect
  useEffect(() => {
    const patient = props.selectedPatient?.id;

    if (patient) {
      props.runSequence({
        sequence: "ORServiceRefund",
        restart: true,
        patientId: patient,
      });
    }
  }, [props.selectedPatient?.id]);

  useEffect(() => {
    if (encounterSelect) {
      handleGetInvoiceItem(selectedMode);
    }
  }, [encounterSelect, selectedMode]);

  useEffect(() => {
    // * Clear ข้อมูล & refresh
    if (props.successMessage?.[CARD_SERVICE_REFUND] === "success") {
      setShowModalRefund("");
      setRefundSelect("");
      setSelectedRow(null);

      handleGetInvoiceItem(selectedMode);
    }
  }, [props.successMessage, selectedMode]);

  // Memo
  const invoiceItemList = useMemo(() => {
    const data: any[] = props.ORServiceRefundSequence?.data?.invoiceItemList || [];
    return data.map((item: any) => ({
      ...item,
      unitprice: <div style={{ textAlign: "right" }}>{formatComma(item.unitprice)}</div>,
      price: <div style={{ textAlign: "right" }}>{formatComma(item.price)}</div>,
      send_claim: <div style={{ textAlign: "right" }}>{formatComma(item.send_claim)}</div>,
      absorb: <div style={{ textAlign: "right" }}>{formatComma(item.absorb)}</div>,
      patient_claim: <div style={{ textAlign: "right" }}>{formatComma(item.patient_claim)}</div>,
      non_claimable: <div style={{ textAlign: "right" }}>{formatComma(item.non_claimable)}</div>,
      discount: <div style={{ textAlign: "right" }}>{formatComma(item.discount)}</div>,
      payable: <div style={{ textAlign: "right" }}>{formatComma(item.payable)}</div>,
    }));
  }, [props.ORServiceRefundSequence?.data?.invoiceItemList]);

  const encounterOptions = useMemo(() => {
    let lists: any[] = props.ORServiceRefundSequence?.encounterList || [];

    lists = lists.map((item, index) => {
      const text = `[${item.general_type}][${item.number}] ${item.edited}-${
        item.division_name || ""
      }-${item.doctor_name || ""}`;

      return {
        key: index + 1,
        value: item.id,
        text,
      };
    });

    return [{ key: 0, value: 0, text: "Select Encounter" }, ...lists];
  }, [props.ORServiceRefundSequence?.encounterList]);

  // Handler
  const handleSelectMode = (_e: any, v: any) => {
    setSelectedMode(v.name);
    setSelectedRow(null);
  };

  const handleChangeValue = (key: string) => (_event: any, data: any) => {
    props.setProp(`ORServiceRefundSequence.data.${key}`, data.value);
  };

  const handleRowProps = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => {
    return {
      style: {
        backgroundColor:
          rowInfo?.original?.id && rowInfo?.original?.id === selectedRow?.id
            ? "#cccccc"
            : "white",
      },
      onClick: () => {
        setSelectedRow(rowInfo.original);
      },
    };
  };

  const handleConfirmRefund = () => {
    props.runSequence({
      sequence: "ORServiceRefund",
      action: "updateInvoiceRefundView",
      type: showModalRefund,
      invoiceId: selectedRow?.id,
      reason: refundSelect,
      card: CARD_SERVICE_REFUND,
    });
  };

  const handleCloseModRefund = () => {
    setShowModalRefund("");
    setRefundSelect("");

    props.setProp(`errorMessage.${CARD_SERVICE_REFUND}`, null);
  };

  const handleCloseModSuccess = () => {
    props.setProp(`successMessage.${CARD_SERVICE_REFUND}`, null);
  };

  return (
    <>
      <CardServiceRefundUX
        selectedMode={selectedMode}
        selectedRow={selectedRow}
        data={props.ORServiceRefundSequence?.data}
        invoiceItemData={invoiceItemList}
        handleRowProps={handleRowProps}
        handleClickRefund={() => setShowModalRefund("confirm")}
        handleClickCancelRefund={() => {
          setShowModalRefund("cancel");
        }}
        // options
        encounterList={encounterOptions}
        // callback
        onSelectMode={handleSelectMode}
        onChangeValue={handleChangeValue}
        languageUX={props.languageUX}
      />

      <ModInfo
        open={props.successMessage?.[CARD_SERVICE_REFUND] === "success"}
        titleColor="green"
        closeOnDimmerClick={true}
        onClose={handleCloseModSuccess}
        onApprove={handleCloseModSuccess}
      >
        <div style={{ fontWeight: "bold", margin: "1.5rem 0 0.75rem" }}>
          แจ้งคืนเงินสำเร็จ
        </div>
      </ModInfo>

      <ModConfirm
        openModal={!!showModalRefund}
        titleName={intl.formatMessage({ id: "กรุณาระบุเหตุผลการแจ้งคืนเงิน" })}
        titleColor="blue"
        // callback
        onApprove={handleConfirmRefund}
        onDeny={handleCloseModRefund}
        onCloseWithDimmerClick={handleCloseModRefund}
        // Element
        content={
          <div style={{ margin: "-2.25rem 0px -1.25rem" }}>
            <ErrorMessage
              error={props.errorMessage?.[CARD_SERVICE_REFUND]}
              style={{ margin: "1rem 0 -1rem" }}
            />

            <div style={{ textAlign: "left" }}>
              <div style={{ margin: "20px 0px 10px 0px" }}>{intl.formatMessage({ id: "สาเหตุ" })}</div>

              <Dropdown
                value={refundSelect}
                options={
                  showModalRefund === "cancel"
                    ? CANCEL_REFUND_OPTIONS
                    : REFUND_OPTIONS
                }
                fluid={true}
                selection={true}
                onChange={(_event: any, data: any) =>
                  setRefundSelect(data?.value)
                }
              />
            </div>
          </div>
        }
      />
    </>
  );
};

export default React.memo(CardServiceRefund);
