import React, { useEffect, useMemo } from "react";
import { Icon, Popup } from "semantic-ui-react";

// UI
import CardReAssessmentRiskFallUX from "./CardReAssessmentRiskFallUX";
import { useIntl } from "react-intl";

// Interface
import {
  riskFallAgeRange,
  historyOfFallOptions,
  mentalStatusOptions,
  medicationOptions,
  mobilyOptions,
  deficitOptions,
  problemOptions,
  riskFallResultOptions,
} from "./sequence/ReAssessment";

type CardReAssessmentRiskFallProps = {
  // function
  onEvent?: any;
  setProp?: any;
  onChangeData?: any;
  onChangeDateRiskFall: any;
  onChangeTimeRiskFall: any;
  // seq
  runSequence: any;
  ReAssessmentSequence?: any;
  // options
  masterOptions?: any;
  reAssessmentOptions?: any;
  // common
  buttonLoadCheck?: any;
  buttonSaveRiskFall?: any;
};

const CardReAssessmentRiskFall = (props: CardReAssessmentRiskFallProps) => {
  const intl = useIntl();
  // console.log("Yeti Risk Fall Props: ", props);

  // useMemo
  const getTotalScore = useMemo(() => {
    const {
      riskFallAgeScore,
      riskFallHistoryScore,
      riskFallMentalScore,
      riskFallSensoryScore,
      riskFallEliminationScore,
      riskFallMedicationScore,
      riskFallMobilyScore,
    } = props.ReAssessmentSequence?.riskFallQuestion || {};

    return (
      (riskFallAgeScore || 0) +
      (riskFallHistoryScore || 0) +
      (riskFallMentalScore || 0) +
      (riskFallSensoryScore || 0) +
      (riskFallEliminationScore || 0) +
      (riskFallMedicationScore || 0) +
      (riskFallMobilyScore || 0)
    );
  }, [props.ReAssessmentSequence]);

  const getPreCaution = useMemo(() => {
    const selectedPreCaution = riskFallResultOptions.find(
      (opt: any) => getTotalScore >= opt.min && getTotalScore <= opt.max
    );

    return selectedPreCaution ? selectedPreCaution.value : null;
  }, [getTotalScore, props.ReAssessmentSequence]);

  // useEffect
  useEffect(() => {
    props.setProp(`ReAssessmentSequence.riskFallQuestion.riskFallTotalScore`, getTotalScore);
    props.setProp(`ReAssessmentSequence.riskFallQuestion.riskFallTotalPrecaution`, getPreCaution);
  }, [getTotalScore, getPreCaution]);

  // onChange
  const handleChangeDataNScore = (options: any[], scoreName: string) => {
    return (_event: any, data: any) => {
      if (data.value === null || data.value === undefined || data.value === "") {
        props.setProp(`ReAssessmentSequence.${data.name}`, null);
        props.setProp(`ReAssessmentSequence.riskFallQuestion.${scoreName}`, null);
      } else {
        const selected = options.find((opt: any) => opt.value === data.value);

        if (selected) {
          props.setProp(`ReAssessmentSequence.${data.name}`, selected.value);
          props.setProp(`ReAssessmentSequence.riskFallQuestion.${scoreName}`, selected.score);
        }
      }
    };
  };

  // Component
  const sensoryTooltip = () => {
    return (
      <Popup
        trigger={<Icon name="info circle" size="large" color="blue" />}
        content={
          <div style={{ display: "flex", gap: "1rem" }}>
            {"V: Vision"}&emsp;{"S: Speech"}
            <br />
            {"H: Hearing"}&emsp;{"T: Touch"}
            <br />
            {"L: Language barier"}
          </div>
        }
        size="small"
        position="bottom left"
      />
    )
  };

  const eliminationTooltip = () => {
    return (
      <Popup
        trigger={<Icon name="info circle" size="large" color="blue" />}
        content={
          <div style={{ display: "flex", gap: "1rem" }}>
            {"U: Urgency"}&emsp;{"N: Nocturia"}
            <br />
            {"F: Frequency"}&emsp;{"V: Vomiting"}
            <br />
            {"D: Diarrhea"}&emsp;{"L: Laxative"}
            <br />
            {"I: Incontinence"}
          </div>
        }
        size="small"
        position="bottom left"
      />
    )
  };

  return (
    <CardReAssessmentRiskFallUX
      // seq
      runSequence={props.runSequence}
      ReAssessmentSequence={props.ReAssessmentSequence}
      // options
      masterOptions={props.masterOptions}
      reAssessmentOptions={props.reAssessmentOptions}
      // onChange
      onChangeData={props.onChangeData}
      onChangeDateRiskFall={props.onChangeDateRiskFall}
      onChangeTimeRiskFall={props.onChangeTimeRiskFall}
      onChangeAge={handleChangeDataNScore(riskFallAgeRange, "riskFallAgeScore")}
      onChangeHistory={handleChangeDataNScore(historyOfFallOptions, "riskFallHistoryScore")}
      onChangeMental={handleChangeDataNScore(mentalStatusOptions, "riskFallMentalScore")}
      onChangeSensory={handleChangeDataNScore(deficitOptions, "riskFallSensoryScore")}
      onChangeElimination={handleChangeDataNScore(problemOptions, "riskFallEliminationScore")}
      onChangeMedication={handleChangeDataNScore(medicationOptions, "riskFallMedicationScore")}
      onChangeMobily={handleChangeDataNScore(mobilyOptions, "riskFallMobilyScore")}
      // value
      totalScoreValue={getTotalScore}
      // popup
      onSensoryPopup={sensoryTooltip()}
      onEliminationPopup={eliminationTooltip()}
      // button
      buttonSaveRiskFall={props.buttonSaveRiskFall}
      languageUX={props.languageUX}
    />
  );
};

export default CardReAssessmentRiskFall;
