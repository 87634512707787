import { TDocumentDefinitions } from "pdfmake/interfaces";

import HeaderSummaryReportForm from "./HeaderSummaryReportForm";

const FormClinicIncomeReportYearly = async (props: any): Promise<TDocumentDefinitions> => {
  console.log("Props form ClinicIncomeReport:", props);

  const headerForm = await HeaderSummaryReportForm({
    data: props.reportClinicIncomeData?.params,
    header: [
      {
        alignment: "center",
        bold: true,
        fontSize: 15,
        text: "รายงานสรุปภาพรวมรายรับ (รายปี)",
      },
      {
        alignment: "center",
        bold: true,
        fontSize: 15,
        text: `ประเภท : ${props.reportClinicIncomeData?.params?.encounter_type_label}`,
      },
      {
        alignment: "center",
        fontSize: 15,
        marginBottom: 5,
        text: `คลินิก: ${props.reportClinicIncomeData?.params?.division} วันที่ ${props.reportClinicIncomeData?.params?.start_date} ถึง ${props.reportClinicIncomeData?.params?.end_date}`,
      },
    ],
  });

  const { font, images, styles } = headerForm;

  const generateTableBody = (data: any, columns: any) => {
    let body = [];
    let yearList: any = [];
    let yearCount: any = {};

    // Header of Table
    body.push([
      { text: "ปี", bold: true, alignment: "center" },
      { text: "รหัสสิทธิ", bold: true, alignment: "center" },
      { text: "สิทธิการรักษา", bold: true, alignment: "center" },
      { text: "จำนวน Visit (ราย)", bold: true, alignment: "center" },
      { text: "ยอดส่งเบิก", bold: true, alignment: "center" },
      { text: "ยอดรับชำระ", bold: true, alignment: "center" },
      { text: "ค้างจ่าย", bold: true, alignment: "center" },
      { text: "ยอดรวม", bold: true, alignment: "center" },
    ]);

    if (data?.length > 0) {
      // Count Year Column
      data.forEach((row: any) => {
        if (!yearCount[row.year]) {
          yearCount[row.year] = 1;
        } else {
          yearCount[row.year]++;
        }
      });

      data.forEach((row: any) => {
        let dataRow: any = [];

        columns.forEach((column: any) => {
          if (row[column] !== null && row[column] !== undefined) {
            switch (column) {
              case "year":
                if (!yearList.includes(row[column])) {
                  yearList.push(row[column]);
                  dataRow.push({ text: row[column], rowSpan: yearCount[row[column]] });
                } else {
                  dataRow.push({});
                }
                break;
              case "coverage_count":
              case "total_send_claim":
              case "total_paid":
              case "total_overdue":
              case "total_price":
                dataRow.push({ text: row[column], alignment: "right" });
                break;
              default:
                dataRow.push({ text: row[column].toString() });
                break;
            }
          }
        });

        body.push(dataRow);
      });
    }

    // Total Row
    body.push([
      { text: "รวมทั้งสิ้น", colSpan: 3, bold: true, alignment: "center" },
      {},
      {},
      {
        text: props.reportClinicIncomeData?.params?.coverage_count,
        bold: true,
        alignment: "right",
      },
      {
        text: props.reportClinicIncomeData?.params?.total_send_claim,
        bold: true,
        alignment: "right",
      },
      { text: props.reportClinicIncomeData?.params?.total_paid, bold: true, alignment: "right" },
      { text: props.reportClinicIncomeData?.params?.total_overdue, bold: true, alignment: "right" },
      { text: props.reportClinicIncomeData?.params?.total_price, bold: true, alignment: "right" },
    ]);

    return body;
  };

  const tableReport = (data: any, columns: any) => {
    return {
      table: {
        widths: ["10%", "10%", "17.5%", "12.5%", "12.5%", "12.5%", "12.5%", "12.5%"],
        headerRows: 1,
        body: generateTableBody(data, columns),
      },
    };
  };

  return {
    ...headerForm,
    content: [
      tableReport(props.reportClinicIncomeData?.fields || [], [
        "year",
        "month",
        "coverage_code",
        "coverage_name",
        "coverage_count",
        "total_send_claim",
        "total_paid",
        "total_overdue",
        "total_price",
      ]),
    ],
    images: {
      ...images,
    },
    styles: {
      ...styles,
    },
    pageOrientation: `landscape`,
    defaultStyle: {
      font,
      // fontSize: 14,
    },
    pageSize: `A4`,
  };
};

export default FormClinicIncomeReportYearly;
