import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Input,
  Radio
} from 'semantic-ui-react'
import {
  DateTextBox,
  TimeFreeTextBox24
} from 'react-lib/apps/common'

const CardReAssessmentSleepUX = (props: any) => {
    return(
      <div
        style={{position: "relative", marginTop: "1rem"}}>
        <div
          style={{ marginLeft: "1rem" }}>
          
          <div
            style={{ display: "flex", alignItems: "center", margin: "16px"}}>
            
            <label
              style={{fontWeight: "bold", whiteSpace: "normal", width: "4rem"}}>
              นอน :
            </label>
            <Input
              name="sleepingPerDay"
              onChange={props.onChangeData}
              onKeyDown={props.onKeyDown}
              style={{ width: "5%" , marginRight: "20px"}}
              value={props.ReAssessmentSequence?.sleepingPerDay}>
            </Input>
            <label
              style={{fontWeight: "bold", whiteSpace: "normal", width: "6rem"}}>
              ชม./วัน
            </label>
            <Radio
              checked={props.ReAssessmentSequence?.sleepingEnough === "เพียงพอ"}
              label="เพียงพอ"
              name="sleepingEnough"
              onChange={props.onChangeData}
              style={{width: "8rem"}}
              value="เพียงพอ">
            </Radio>
            <Radio
              checked={props.ReAssessmentSequence?.sleepingEnough === "ไม่เพียงพอ"}
              label="ไม่เพียงพอ"
              name="sleepingEnough"
              onChange={props.onChangeData}
              value="ไม่เพียงพอ">
            </Radio>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "16px"}}>
            
            <label
              style={{fontWeight: "bold", whiteSpace: "normal", width: "10rem"}}>
              ปัญหาการนอน :
            </label>
            <Radio
              checked={props.ReAssessmentSequence?.sleepingProblem === "ไม่มี"}
              label="ไม่มี"
              name="sleepingProblem"
              onChange={props.onChangeData}
              style={{width: "8rem"}}
              value="ไม่มี">
            </Radio>
            <Radio
              checked={props.ReAssessmentSequence?.sleepingProblem === "มี"}
              label="มี โปรดระบุ"
              name="sleepingProblem"
              onChange={props.onChangeData}
              style={{width: "8rem"}}
              value="มี">
            </Radio>
            <div
              style={{ display: props.ReAssessmentSequence?.sleepingProblem !== "มี" ? "none" : "",  color: "red", marginRight: "10px", fontSize: "17px" }}>
              *
            </div>
            <Input
              disabled={props.ReAssessmentSequence?.sleepingProblem !== "มี"}
              name="sleepingProblemRemark"
              onChange={props.onChangeData}
              style={{ width: "30%" }}
              value={props.ReAssessmentSequence?.sleepingProblem === "มี"  ?  props.ReAssessmentSequence?.sleepingProblemRemark || ""   :  ""}>
            </Input>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "16px"}}>
            
            <label
              style={{fontWeight: "bold", whiteSpace: "normal", width: "10rem"}}>
              การใช้ยานอนหลับ :
            </label>
            <Radio
              checked={props.ReAssessmentSequence?.sleepPill === "ไม่ใช้"}
              label="ไม่ใช้"
              name="sleepPill"
              onChange={props.onChangeData}
              style={{width: "8rem"}}
              value="ไม่ใช้">
            </Radio>
            <Radio
              checked={props.ReAssessmentSequence?.sleepPill === "ไม่ทราบ"}
              label="ไม่ทราบ"
              name="sleepPill"
              onChange={props.onChangeData}
              style={{width: "8rem"}}
              value="ไม่ทราบ">
            </Radio>
            <Radio
              checked={props.ReAssessmentSequence?.sleepPill === "ใช้"}
              label="ใช้ โปรดระบุ"
              name="sleepPill"
              onChange={props.onChangeData}
              style={{width: "8rem"}}
              value="ใช้">
            </Radio>
            <div
              style={{ display: props.ReAssessmentSequence?.sleepPill !== "ใช้" ? "none" : "",  color: "red", marginRight: "10px", fontSize: "17px" }}>
              *
            </div>
            <Input
              disabled={props.ReAssessmentSequence?.sleepPill !== "ใช้" }
              name="sleepPillRemark"
              onChange={props.onChangeData}
              style={{ width: "30%" }}
              value={props.ReAssessmentSequence?.sleepPill === "ใช้"  ? props.ReAssessmentSequence?.sleepPillRemark  || ""   : ""}>
            </Input>
          </div>
        </div>
        <div
          style={{ display: "flex", justifyContent: "right", alignItems: "center" , margin: "1rem"}}>
          
          <label
            style={{ display: "flex", alignItems: "center", margin: "0rem 2rem", fontWeight: "bold"  }}>
            Shift
          </label>
          <Radio
            checked={props.ReAssessmentSequence?.sleepData?.saveShift === "Day"}
            label="Day"
            name="sleepData.saveShift"
            onChange={props.onChangeData}
            style={{ display: "flex", alignItems: "center"  }}
            value="Day">
          </Radio>
          <Radio
            checked={props.ReAssessmentSequence?.sleepData?.saveShift === "Night"}
            label="Night"
            name="sleepData.saveShift"
            onChange={props.onChangeData}
            style={{ display: "flex", alignItems: "center", margin: "0rem 1rem"  }}
            value="Night">
          </Radio>
          <label
            style={{ display: "flex", alignItems: "center", margin: "0rem 2rem", fontWeight: "bold"  }}>
            ประเมินวันที่
          </label>
          <DateTextBox
            onChange={props.onChangDateSleep}
            value={props.ReAssessmentSequence?.sleepData?.saveData}>
          </DateTextBox>
          <label
            style={{ display: "flex", alignItems: "center", margin: "0rem 2rem", fontWeight: "bold"  }}>
            เวลา
          </label>
          <TimeFreeTextBox24
            autoFocus={false}
            onChange={props.onChangTimeSleep}
            value={props.ReAssessmentSequence?.sleepData?.saveTime}>
          </TimeFreeTextBox24>
          <div
            style={{ margin: "0rem 0rem 0rem 2rem" }}>
            {props.buttonSaveSleep}
          </div>
        </div>
      </div>
    )
}


export default CardReAssessmentSleepUX

export const screenPropsDefault = {}

/* Date Time : Thu Oct 17 2024 14:39:56 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{position: \"relative\", marginTop: \"1rem\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"1rem\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"16px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "label",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "นอน :"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", whiteSpace: \"normal\", width: \"4rem\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "Input",
      "parent": 2,
      "props": {
        "name": {
          "type": "value",
          "value": "sleepingPerDay"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "code",
          "value": "props.onKeyDown"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"5%\" , marginRight: \"20px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.sleepingPerDay"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "label",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "ชม./วัน"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", whiteSpace: \"normal\", width: \"6rem\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "Radio",
      "parent": 2,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.sleepingEnough === \"เพียงพอ\""
        },
        "label": {
          "type": "value",
          "value": "เพียงพอ"
        },
        "name": {
          "type": "value",
          "value": "sleepingEnough"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width: \"8rem\"}"
        },
        "value": {
          "type": "value",
          "value": "เพียงพอ"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "Radio",
      "parent": 2,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.sleepingEnough === \"ไม่เพียงพอ\""
        },
        "label": {
          "type": "value",
          "value": "ไม่เพียงพอ"
        },
        "name": {
          "type": "value",
          "value": "sleepingEnough"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "value",
          "value": "ไม่เพียงพอ"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"16px\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"16px\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "ปัญหาการนอน :"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", whiteSpace: \"normal\", width: \"10rem\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "Radio",
      "parent": 8,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.sleepingProblem === \"ไม่มี\""
        },
        "label": {
          "type": "value",
          "value": "ไม่มี"
        },
        "name": {
          "type": "value",
          "value": "sleepingProblem"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width: \"8rem\"}"
        },
        "value": {
          "type": "value",
          "value": "ไม่มี"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "Radio",
      "parent": 8,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.sleepingProblem === \"มี\""
        },
        "label": {
          "type": "value",
          "value": "มี โปรดระบุ"
        },
        "name": {
          "type": "value",
          "value": "sleepingProblem"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width: \"8rem\"}"
        },
        "value": {
          "type": "value",
          "value": "มี"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "Input",
      "parent": 8,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.sleepingProblem !== \"มี\""
        },
        "name": {
          "type": "value",
          "value": "sleepingProblemRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.sleepingProblem === \"มี\"  ?  props.ReAssessmentSequence?.sleepingProblemRemark || \"\"   :  \"\""
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "label",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "การใช้ยานอนหลับ :"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", whiteSpace: \"normal\", width: \"10rem\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "Radio",
      "parent": 9,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.sleepPill === \"ไม่ใช้\""
        },
        "label": {
          "type": "value",
          "value": "ไม่ใช้"
        },
        "name": {
          "type": "value",
          "value": "sleepPill"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width: \"8rem\"}"
        },
        "value": {
          "type": "value",
          "value": "ไม่ใช้"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "Radio",
      "parent": 9,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.sleepPill === \"ไม่ทราบ\""
        },
        "label": {
          "type": "value",
          "value": "ไม่ทราบ"
        },
        "name": {
          "type": "value",
          "value": "sleepPill"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width: \"8rem\"}"
        },
        "value": {
          "type": "value",
          "value": "ไม่ทราบ"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "Radio",
      "parent": 9,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.sleepPill === \"ใช้\""
        },
        "label": {
          "type": "value",
          "value": "ใช้ โปรดระบุ"
        },
        "name": {
          "type": "value",
          "value": "sleepPill"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width: \"8rem\"}"
        },
        "value": {
          "type": "value",
          "value": "ใช้"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "Input",
      "parent": 9,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.sleepPill !== \"ใช้\" "
        },
        "name": {
          "type": "value",
          "value": "sleepPillRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"30%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.sleepPill === \"ใช้\"  ? props.ReAssessmentSequence?.sleepPillRemark  || \"\"   : \"\""
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"right\", alignItems: \"center\" , margin: \"1rem\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "label",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": "Shift"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0rem 2rem\", fontWeight: \"bold\"  }"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "Radio",
      "parent": 19,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.sleepData?.saveShift === \"Day\""
        },
        "label": {
          "type": "value",
          "value": "Day"
        },
        "name": {
          "type": "value",
          "value": "sleepData.saveShift"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "Day"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "Radio",
      "parent": 19,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.sleepData?.saveShift === \"Night\""
        },
        "label": {
          "type": "value",
          "value": "Night"
        },
        "name": {
          "type": "value",
          "value": "sleepData.saveShift"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0rem 1rem\"  }"
        },
        "value": {
          "type": "value",
          "value": "Night"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "label",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเมินวันที่"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0rem 2rem\", fontWeight: \"bold\"  }"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 24,
      "name": "DateTextBox",
      "parent": 19,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangDateSleep"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.sleepData?.saveData"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "label",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลา"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0rem 2rem\", fontWeight: \"bold\"  }"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 26,
      "name": "TimeFreeTextBox24",
      "parent": 19,
      "props": {
        "autoFocus": {
          "type": "code",
          "value": "false"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangTimeSleep"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.sleepData?.saveTime"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 19,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSaveSleep"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0rem 0rem 0rem 2rem\" }"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.ReAssessmentSequence?.sleepingProblem !== \"มี\" ? \"none\" : \"\",  color: \"red\", marginRight: \"10px\", fontSize: \"17px\" }"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "*"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.ReAssessmentSequence?.sleepPill !== \"ใช้\" ? \"none\" : \"\",  color: \"red\", marginRight: \"10px\", fontSize: \"17px\" }"
        }
      },
      "seq": 18,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardReAssessmentSleepUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
