import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  MutableRefObject,
} from "react";
import {
  Button,
  Modal,
  Input,
  Icon,
  Dropdown,
  Segment,
  Form,
  FormField,
  FormGroup,
} from "semantic-ui-react";
import { Table } from "react-lib/frameworks/Table";
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import SearchBox from "react-lib/appcon/common/SearchBox";
import SnackMessage from "../../common/SnackMessage";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";

import CardCountUX from "./CardCountUX";
import ORCountTable from "./ORCountTable";
import CardPackingUx from "./CardPackingUX";

import "./CardORHistory.scss";
import { useIntl } from "react-intl";

const CARD_NAME = "CardCountsAndPacking";

type CardCountsAndPackingProps = {
  // function
  onEvent: any;
  setProp: any;
  runSequence: any;
  ORCountsSequence?: any;
  errorMessage?: any;
  successMessage?: any;
  searchedItemList?: any[];
  selectedOrOrder?: any;
  searchedItemListWithKey?: any;
  masterOptions?: any;
  buttonLoadCheck?: any;
  searchOrItems?: any[];
  DJANGO?: any;
};

const CardCountsAndPackingInitial: CardCountsAndPackingProps = {
  // funtion
  onEvent: () => null,
  setProp: () => null,

  // seq
  runSequence: null,
  ORCountsSequence: {},

  errorMessage: null,
  successMessage: null,
};

const CardCountsAndPacking: React.FC<CardCountsAndPackingProps> = (
  props: any
) => {
  const intl = useIntl();
  const [tab, setTab] = useState("counts");
  const [openInstrumentAdder, setOpenInstrumentAdder] = useState(false);
  const [openPackingAdder, setOpenPackingAdder] = useState<any>({
    open: false,
    action: "",
    index: null,
  });
  const [openSearchItem, setOpenSearchItem] = useState(false);
  const [orItem, setOrItem] = useState("");
  const [orItemQuantity, setOrItemQuantity] = useState(1);
  const [selectedSection, setSelectedSection] = useState({});
  const [searchKeyword, setSearchKeyword] = useState("");
  const htmlRef = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    console.log("ORCounts restart");
    props.runSequence({ sequence: "ORCounts", card: CARD_NAME, restart: true });

    return ()=>{
      props.runSequence({ sequence: "ORCounts", card: CARD_NAME, clear: true });
    }
  }, []);

  useEffect(() => {
    console.log(
      "props.DJANGO?.user?.role_types",
      props.DJANGO?.user?.role_types
    );
    const isDoctor = props.DJANGO?.user?.role_types?.includes("DOCTOR");
    if (isDoctor) {
      const children = Array.from(
        htmlRef.current.querySelectorAll(
          ".dropdown, .input, .search, input, textarea, .ui.small.button"
        )
      ) as HTMLElement[];
      const childrenBtn = Array.from(
        htmlRef.current.querySelectorAll(".ui.small.button")
      ) as HTMLElement[];
      const childrenCheckBtn = Array.from(
        htmlRef.current.querySelectorAll(".ui.checkbox")
      ) as HTMLElement[];
      console.log("children: ", children);
      children.forEach((e) => {
        e.setAttribute("tabindex", "-1");
        e.classList.add("block-mouse");
      });
      childrenBtn.forEach((e) => {
        e.setAttribute("tabindex", "-1");
        e.setAttribute("disabled", "-1");
      });
      childrenCheckBtn.forEach((e) => {
        e.setAttribute("tabindex", "-1");
        e.setAttribute("disabled", "-1");
        e.classList.add("block-mouse");
      });
    }
  }, []);

  const handleChangeData = (_event: any, data: any) => {
    props.setProp(`ORCountsSequence.${data.name}`, data.value);
  };

  const itemSearchBtn = () => {
    props.onEvent({
      message: "SearchORItemList",
      params: {
        searchText: searchKeyword,
      },
    });
  };

  const handleActionScrubNurse =
    (index: number) => (cIndex: number, item: any) => {
      const items = props.ORCountsSequence?.selectedShift?.scrub_nurses || [];

      if (item === "add") {
        if (items.every((item: any) => item)) {
          items.push(null);
        } else {
          return;
        }
      } else if (item === "remove") {
        items.splice(cIndex, 1);
      } else {
        items[cIndex] = item;
      }

      props.setProp(`ORCountsSequence.selectedShift.scrub_nurses`, items);
    };

  const handleActionCirculatingNurse =
    (index: number) => (cIndex: number, item: any) => {
      console.log("handleActionCirculatingNurse", cIndex, item);
      const items =
        props.ORCountsSequence?.selectedShift?.circulating_nurses || [];

      if (item === "add") {
        if (items.every((item: any) => item)) {
          items.push(null);
        } else {
          return;
        }
      } else if (item === "remove") {
        items.splice(cIndex, 1);
      } else {
        items[cIndex] = item;
      }

      props.setProp(`ORCountsSequence.selectedShift.circulating_nurses`, items);
    };

  const handleQtyChange = (e: any, data: any) => {
    setOrItemQuantity(data.value);
  };

  const clearSelectedShift = () => {
    props.setProp("ORCountsSequence.selectedShift", {});
  };
  const createOreditShift = () => {
    if (props.ORCountsSequence?.selectedShift?.id) {
      props.runSequence({
        sequence: "ORCounts",
        action: "EDIT_SHIFT",
        card: CARD_NAME,
      });
    } else {
      props.runSequence({
        sequence: "ORCounts",
        action: "CREATE_SHIFT",
        card: CARD_NAME,
      });
    }
  };

  const handleSavePacking = () => {
    props.runSequence({
      sequence: "ORCounts",
      action: "CONFIRM_PACKING",
      card: CARD_NAME,
    });
  };
  const saveRemark = () => {
    props.runSequence({
      sequence: "ORCounts",
      action: "SAVE_REMARK",
      card: CARD_NAME,
    });
  };
  // Modal Packing Adder
  const addPackingHandler = () => {
    props.runSequence({
      sequence: "ORCounts",
      action: "SET_EDIT_PACKING_DATA",
      data: {
        packingTypeSelected: "",
        packingItemSelected: null,
        packingLocationSelected: "",
        packingPieces: "",
        packingRemark: "",
      },
    });
    setOpenPackingAdder({ open: true, action: "ADD_PACKING", index: null });
  };
  const closeModPackingAdder = () => {
    setOpenPackingAdder({ open: false, action: "", index: null });
  };
  const setSelectedPacking = (item: any, index: number) => {
    props.runSequence({
      sequence: "ORCounts",
      action: "SET_EDIT_PACKING_DATA",
      data: {
        packingTypeSelected: item.type || "",
        packingItemSelected: { ...item, name: item.item_name },
        packingLocationSelected: item.site || "",
        packingPieces: item.quantity || "",
        packingRemark: item.remark || "",
      },
    });
    setOpenPackingAdder({
      open: true,
      action: "EDIT_PACKING",
      index: index,
    });
  };

  //
  const addInstrumentHandler = (item: any) => {
    console.log("addInstrumentHandler", item);
    setSelectedSection(item);
    setOpenInstrumentAdder(true);
    setOrItem("");
    setOrItemQuantity(1);
  };

  const packingItems = useMemo(
    () =>
      props.ORCountsSequence?.packingItems?.map((item: any, idx: any) => {
        return {
          ...item,
          no: item.no || idx + 1,
          delete: (
            <>
              <Button
                circular
                icon="pencil"
                color="grey"
                onClick={() => {
                  setSelectedPacking(item, idx);
                }}
              />
              <Button
                circular
                icon="trash alternate"
                color="red"
                onClick={() => {
                  let items: any[] = props.ORCountsSequence?.packingItems;
                  items.splice(idx, 1);
                  props.setProp("ORCountsSequence.packingItems", items);
                  // clear previous order id
                  if (items.length <= 0) {
                    props.setProp("ORCountsSequence.packingItems", []);
                  }
                }}
              />
            </>
          ),
        };
      }),
    [props.ORCountsSequence, props.ORCountsSequence?.packingItems]
  );

  const countsData = useMemo(() => {
    let tmpData: any = [...(props.ORCountsSequence?.items || [])];

    tmpData?.forEach((operative_count: any, idx1: any) => {
      let sharpColor = "lightcoral";
      if (operative_count["sharp_status"] === "COMPLETE") {
        sharpColor = "lightgreen";
      }
      operative_count["sharpStatus"] = (
        <div
          style={{
            background: sharpColor,
            width: "200%",
            height: "200%",
            margin: "-7px -5px",
            padding: "7px 5px",
          }}
        >
          {operative_count["sharp_status"] || ""}
        </div>
      );

      let swabColor = "lightcoral";
      if (operative_count["swab_status"] === "COMPLETE") {
        swabColor = "lightgreen";
      }
      operative_count["swabStatus"] = (
        <div
          style={{
            background: swabColor,
            width: "200%",
            height: "200%",
            margin: "-7px -5px",
            padding: "7px 5px",
          }}
        >
          {operative_count["swab_status"] || ""}
        </div>
      );

      let instruColor = "lightcoral";
      if (operative_count["instrument_status_cal"] === "COMPLETE") {
        instruColor = "lightgreen";
      }
      operative_count["instrumentStatus"] = (
        <div
          style={{
            background: instruColor,
            width: "200%",
            height: "200%",
            margin: "-7px -5px",
            padding: "7px 5px",
          }}
        >
          {operative_count["instrument_status_cal"] || ""}
        </div>
      );

      operative_count?.counts_details.forEach((it: any, idx2: any) => {
        let color = "lightcoral";
        let sum =
          parseInt(it["sterile_count"] || 0) +
          parseInt(it["non_sterile_count"] || 0);
        console.log("sum", sum);
        if (sum === parseInt(it["total_all_shift"])) {
          color = "lightgreen";
        }
        it["countsInShift"] = (
          <div
            style={{
              background: color,
              width: "100%",
              height: "150%",
              margin: "-7px -5px",
              padding: "7px 5px",
            }}
          >
            {it.counts_in_shift || ""}
          </div>
        );

        it["sterileCountInput"] = (
          <input
            value={it["sterile_count"] || ""}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                props.runSequence({
                  sequence: "ORCounts",
                  action: "UPDATE_STERILE_COUNT",
                  sterile_count: it["sterile_count"],
                  non_sterile_count: it["non_sterile_count"],
                  operative_counts_id: it["id"],
                  card: CARD_NAME,
                });
              }
            }}
            onBlur={(e) => {
              props.runSequence({
                sequence: "ORCounts",
                action: "UPDATE_STERILE_COUNT",
                sterile_count: it["sterile_count"],
                non_sterile_count: it["non_sterile_count"],
                operative_counts_id: it["id"],
                card: CARD_NAME,
              });
            }}
            onChange={(e) => {
              props.setProp(
                `ORCountsSequence.items.${idx1}.counts_details.${idx2}.sterile_count`,
                e.target.value
              );
              let sum =
                parseInt(it["sterile_count"]) +
                parseInt(it["non_sterile_count"]);
              props.setProp(
                `ORCountsSequence.items.${idx1}.counts_details.${idx2}.counts_in_shift`,
                sum
              );
            }}
            style={{ border: "none" }}
          />
        );

        it["nonSterileCountInput"] = (
          <input
            value={it["non_sterile_count"] || ""}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                props.runSequence({
                  sequence: "ORCounts",
                  action: "UPDATE_NON_STERILE_COUNT",
                  sterile_count: it["sterile_count"],
                  non_sterile_count: it["non_sterile_count"],
                  operative_counts_id: it["id"],
                  card: CARD_NAME,
                });
              }
            }}
            onBlur={(e) => {
              console.log(it);
              props.runSequence({
                sequence: "ORCounts",
                action: "UPDATE_NON_STERILE_COUNT",
                sterile_count: it["sterile_count"],
                non_sterile_count: it["non_sterile_count"],
                operative_counts_id: it["id"],
                card: CARD_NAME,
              });
            }}
            onChange={(e) => {
              props.setProp(
                `ORCountsSequence.items.${idx1}.counts_details.${idx2}.non_sterile_count`,
                e.target.value
              );
              let sum =
                parseInt(it["sterile_count"]) +
                parseInt(it["non_sterile_count"]);
              props.setProp(
                `ORCountsSequence.items.${idx1}.counts_details.${idx2}.counts_in_shift`,
                sum
              );
            }}
            style={{ border: "none" }}
          />
        );

        it["item"].nameButton = (
          <div style={{ display: "flex", alignItems: "center" }}>
            <label>{it["item"].name || "aaa"}</label>
            <div style={{ display: "flex", width: "auto", marginLeft: "auto" }}>
              <Button
                size="tiny"
                color="red"
                onClick={() => {
                  props.runSequence({
                    sequence: "ORCounts",
                    action: "ACTION_COUNT",
                    sub_action: "DELETE",
                    counts_details_id: it["id"],
                    item: it["item"],
                    sterile_count: it["sterile_count"],
                    non_sterile_count: it["non_sterile_count"],
                    operative_counts_id: operative_count.id,
                    card: CARD_NAME,
                  });
                }}
              >
                -
              </Button>
              <Button
                size="tiny"
                color="green"
                onClick={() => {
                  props.runSequence({
                    sequence: "ORCounts",
                    action: "ACTION_COUNT",
                    sub_action: "ADD",
                    counts_details_id: it["id"],
                    item: it["item"],
                    sterile_count: it["sterile_count"],
                    non_sterile_count: it["non_sterile_count"],
                    operative_counts_id: operative_count.id,
                    card: CARD_NAME,
                  });
                }}
              >
                +
              </Button>
            </div>
          </div>
        );
      });
    });

    return tmpData;
  }, [props.ORCountsSequence, props.ORCountsSequence?.items]);

  // const handleGetTdProps = useCallback(
  //   (state: any, rowInfo: any = {}, column: any, instance: any) => {
  //     if (!rowInfo?.original) {
  //       return {};
  //     }

  //     const style = {} as CSSProperties;
  //     const { nameButton,total_previous_shift,add_times_text,total_all_shift,sterileCountInput,nonSterileCountInput,counts_in_shift } =
  //       rowInfo.original;

  //     if (counts_in_shift === 10) {
  //       style.background = "white";
  //       style.color = "#2185D0";
  //     } else {
  //       style.background = "#FBBD08";
  //     }

  //     return {
  //       style,
  //     };
  //   },
  //   [props.ORCountsSequence?.items]
  // );

  return (
    <div id="CardCountsAndPacking" ref={htmlRef}>
      <Modal
        closeIcon
        style={{ left: "unset !important" }}
        size={"tiny"}
        open={openInstrumentAdder}
        onClose={() => {
          setOpenInstrumentAdder(false);
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            margin: "10px 10px",
          }}
        >
          <label
            style={{
              fontWeight: "bold",
              fontSize: "18px",
              marginLeft: "4px",
            }}
          >
            Add Item
          </label>
        </div>
        <div>
          <hr></hr>
        </div>
        <div
          style={{
            border: "1px solid #ccc",
            borderRadius: "4px",
            padding: "8px 8px 8px 8px",
            margin: "10px 10px",
          }}
        >
          <SearchBox
            onEvent={props.onEvent}
            searchedItemList={props.searchedItemList}
            selectedItem={orItem}
            setSelectedItem={setOrItem}
            type="OperatingItem"
            toDisplay={(item: any) => `${item.name}`}
            style={{ width: "100%" }}
            label="Item"
            fluid={true}
            icon={
              <Button
                icon
                onClick={() => {
                  setOpenSearchItem(true);
                }}
              >
                <Icon name="search" />
              </Button>
            }
          />
          <div
            style={{ display: "flex", alignItems: "center", marginTop: "8px" }}
          >
            <label style={{ width: "40px" }}>Qty</label>
            <Input
              type="number"
              fluid
              style={{ width: "100%" }}
              value={orItemQuantity}
              onChange={handleQtyChange}
            />
          </div>
          <br />
          <Button
            color="green"
            size="small"
            onClick={() => {
              props.runSequence({
                sequence: "ORCounts",
                action: "ADD_ITEM",
                item: selectedSection?.item,
                qty: orItemQuantity,
                item_id: orItem.id,
                // sterile_count: it["sterile_count"],
                // non_sterile_count: it["non_sterile_count"],
                operative_counts_id: selectedSection?.id,
                card: CARD_NAME,
              });
              setOpenInstrumentAdder(false);
            }}
          >
            Confirm
          </Button>
        </div>
      </Modal>

      {/* PACKING */}
      <Modal
        closeIcon
        style={{ left: "unset !important" }}
        size={"tiny"}
        open={openPackingAdder.open}
        onClose={closeModPackingAdder}
      >
        <Segment inverted className="blue" style={{ margin: "0px" }}>
          Add Packing
        </Segment>
        <Modal.Content>
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ width: "65px" }}>Type</label>
              <Dropdown
                name="packingTypeSelected"
                onChange={handleChangeData}
                options={props.ORCountsSequence?.packingTypeOptions || []}
                selection={true}
                value={props.ORCountsSequence?.packingTypeSelected || ""}
                style={{ width: "100%" }}
              ></Dropdown>
            </div>
            <SearchBox
              onEvent={props.onEvent}
              searchedItemList={props.searchedItemList}
              selectedItem={props.ORCountsSequence?.packingItemSelected}
              setSelectedItem={(value: any) => {
                let data = { value: value, name: "packingItemSelected" };
                handleChangeData(null, data);
              }}
              searchType={props.ORCountsSequence?.packingTypeSelected}
              type="OperatingPackingItem"
              toDisplay={(item: any) => `${item.name}`}
              style={{ width: "100%", marginTop: "8px" }}
              label="Item&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
              fluid={true}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "8px",
              }}
            >
              <label style={{ width: "60px" }}>Site</label>
              <Dropdown
                name="packingLocationSelected"
                onChange={handleChangeData}
                options={props.ORCountsSequence?.packingLocationOptions || []}
                selection={true}
                value={props.ORCountsSequence?.packingLocationSelected || ""}
                style={{ width: "60%" }}
              ></Dropdown>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <label style={{ width: "45px" }}>Pieces</label>
              <Input
                name="packingPieces"
                fluid
                style={{ width: "20%" }}
                value={props.ORCountsSequence?.packingPieces}
                onChange={handleChangeData}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "8px",
              }}
            >
              <label style={{ width: "65px" }}>Remark</label>
              <Input
                name="packingRemark"
                fluid
                style={{ width: "100%" }}
                value={props.ORCountsSequence?.packingRemark}
                onChange={handleChangeData}
              />
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            size="small"
            onClick={() => {
              props.runSequence({
                sequence: "ORCounts",
                action: openPackingAdder.action,
                card: CARD_NAME,
                index: openPackingAdder.index,
              });
              closeModPackingAdder();
            }}
          >
            Confirm
          </Button>
        </Modal.Actions>
      </Modal>

      {/* Search Item Modal */}
      <Modal
        closeIcon
        style={{ left: "unset !important" }}
        size={"large"}
        open={openSearchItem}
        onClose={() => {
          setOpenSearchItem(false);
        }}
      >
        <Segment inverted className="blue" style={{ margin: "0px" }}>
          Search Operating Item
        </Segment>
        <Modal.Content>
          <div>
            <div style={{ padding: "15px 10px" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ marginLeft: "auto" }}></div>
                <Input
                  onChange={(e, data) => {
                    setSearchKeyword(data.value);
                  }}
                  onKeyDown={(e: any) => {
                    e.key === "Enter" && itemSearchBtn();
                  }}
                  style={{ marginLeft: "15px", width: "300px" }}
                  value={searchKeyword}
                ></Input>
                <Button
                  color="blue"
                  onClick={itemSearchBtn}
                  style={{ marginLeft: "15px" }}
                >{intl.formatMessage({ id: "ค้นหา" })}</Button>
              </div>
              <div>
                <Table
                  data={props.searchOrItems}
                  headers="Name,Quantity,Unit"
                  keys="name,quantity,unit"
                  minRows="5"
                  showPagination={false}
                  style={{
                    height: "300px",
                    marginTop: "10px",
                    fontSize: "1.2rem",
                  }}
                  widths="auto,150,150"
                  getTrProps={(
                    state: any,
                    rowInfo: any,
                    column: any,
                    instance: any
                  ) => ({
                    onClick: () => {
                      console.log(rowInfo?.original);
                      setOrItem(rowInfo?.original);
                    },
                    onDoubleClick: () => {
                      console.log(rowInfo?.original);
                      setOrItem(rowInfo?.original);
                      setOpenSearchItem(false);
                    },
                  })}
                ></Table>
              </div>
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="blue"
            onClick={() => {
              setOpenSearchItem(false);
            }}
            style={{ width: "150px", marginLeft: "10px" }}
          >
            เลือก
          </Button>
        </Modal.Actions>
      </Modal>

      <div
        className="counts-packing-tabs"
        style={{
          display: "flex",
          padding: "4px 0 4px 15px",
        }}
      >
        <MenuItem tab={tab} name="counts" title="Counts" setTab={setTab} />
        <MenuItem tab={tab} name="packing" title="Packing" setTab={setTab} />
      </div>
      <hr style={{ margin: 0 }} />
      {tab === "counts" ? (
        <CardCountUX
          onEvent={props.onEvent}
          setProp={props.setProp}
          items={countsData}
          ORCountsSequence={props.ORCountsSequence}
          handleChangeData={handleChangeData}
          saveRemark={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={saveRemark}
              // data
              paramKey={`${CARD_NAME}`}
              buttonLoadCheck={
                props.buttonLoadCheck?.[`${CARD_NAME}_SAVE_REMARK`]
              }
              disabled={props.disableCreate}
              // config
              color={"green"}
              // size="small"
              style={{ width: "100%" }}
              title={"Save"}
            />
          }
          // createOreditShift={createOreditShift}
          createOreditShift={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={createOreditShift}
              // data
              paramKey={`${CARD_NAME}`}
              buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_NAME}`]}
              disabled={props.disableCreate}
              // config
              color={"green"}
              title={
                props.ORCountsSequence?.selectedShift?.id ? "Save" : "New Shift"
              }
              // size="small"
              style={{ width: "100%" }}
            />
          }
          clearSelectedShift={clearSelectedShift}
          // getTdProps1={handleGetTdProps}
          CountTables={
            <>
              {countsData?.map((item: any) => {
                return (
                  <ORCountTable
                    key={item.id}
                    onEvent={props.onEvent}
                    setProp={props.setProp}
                    title={item.shift_name || ""}
                    item={item}
                    addItem={() => addInstrumentHandler(item)}
                    languageUX={props.languageUX}
                  />
                );
              })}
            </>
          }
          shiftTableRowProps={(
            state: any,
            rowInfo: any,
            column: any,
            instance: any
          ) => ({
            onClick: () => {
              console.log(rowInfo?.original);
              props.setProp(
                `ORCountsSequence.selectedShift`,
                rowInfo?.original
              );
              props.runSequence({
                sequence: "ORCounts",
                action: "SET_OPTION",
                selectedShift: rowInfo?.original,
                card: CARD_NAME,
              });
            },
          })}
          circulatingNurse={
            <MultiNurseElement
              // callback
              onEvent={props.onEvent}
              // data
              index={0}
              items={
                props.ORCountsSequence?.selectedShift?.circulating_nurses || []
              }
              type="User"
              role="REGISTERED_NURSE"
              searchedItemList={props.searchedItemList}
              setSelected={handleActionCirculatingNurse(0)}
              onClick={handleActionCirculatingNurse(0)}
            />
          }
          scrubNurse={
            <MultiNurseElement
              // callback
              onEvent={props.onEvent}
              // data
              index={0}
              items={props.ORCountsSequence?.selectedShift?.scrub_nurses || []}
              type="Nurse"
              role="REGISTERED_NURSE"
              searchedItemList={props.searchedItemList}
              setSelected={handleActionScrubNurse(0)}
              onClick={handleActionScrubNurse(0)}
            />
          }
          ErrorMessage={
            <ErrorMessage error={props?.errorMessage?.[CARD_NAME]} />
          }
          languageUX={props.languageUX}
        />
      ) : tab === "packing" ? (
        <CardPackingUx
          onEvent={props.onEvent}
          setProp={props.setProp}
          ORCountsSequence={props.ORCountsSequence}
          handleChangeData={handleChangeData}
          orPlanNImplPackingOpt={props.masterOptions.orPlanNImplPacking}
          addItem={addPackingHandler}
          items={packingItems}
          // save={handleSavePacking}
          save={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={handleSavePacking}
              // data
              paramKey={`${CARD_NAME}`}
              buttonLoadCheck={
                props.buttonLoadCheck?.[`${CARD_NAME}_CONFIRM_PACKING`]
              }
              disabled={props.disableCreate}
              // config
              color={"green"}
              title={"Save"}
              // size="small"
              style={{ width: "100%" }}
            />
          }
          ErrorMessage={
            <ErrorMessage error={props?.errorMessage?.[CARD_NAME]} />
          }
          languageUX={props.languageUX}
        />
      ) : (
        ""
      )}
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_NAME}`, null);
        }}
        error={props.errorMessage?.[CARD_NAME]}
        success={null}
        languageUX={props.languageUX}
      />
    </div>
  );
};

CardCountsAndPacking.defaultProps = CardCountsAndPackingInitial;

const MenuItem = (props: any) => (
  <Button
    size="medium"
    color={props.name === props.tab ? "blue" : undefined}
    onClick={(e: any) => props.setTab(props.name)}
  >
    {props.title}
  </Button>
);

export const MultiNurseElement = (props: any) => {
  const handleSelected = (index: number) => (item: any) => {
    console.log("handleSelected", index, item);
    props.setSelected(index, item);
  };

  const handleClick = (action: string, index: number) => (e: any) => {
    props.onClick(index, action);
  };

  const mapNurseOpt = (items: any) => {
    console.log("mapNurseOpt", items);
    return items.map((item: any) => ({
      key: item.id,
      value: item,
      text: item.full_name_code,
    }));
  };

  return (
    <>
      {(props.items?.length ? props.items : [null]).map(
        (item: any, cIndex: number) => {
          console.log("iii", item);
          console.log("props.searchedItemList", props.searchedItemList);
          return (
            <Form>
              <FormGroup inline={true}>
                <FormField inline width={11}>
                  <SearchBox
                    type={props.type}
                    id={props.id?.toString()}
                    style={{ width: "100%" }}
                    boxStyle={{ width: "100%" }}
                    fluid={true}
                    label=""
                    role={props.role}
                    disabled={props.disabled}
                    toDisplay={(item: any) => `${item?.full_name_code}`}
                    onEvent={props.onEvent}
                    searchedItemList={props.searchedItemList}
                    selectedItem={item}
                    setSelectedItem={handleSelected(cIndex)}
                    mapOptions={mapNurseOpt}
                    useWithKey={false}
                  />
                </FormField>
                <FormField inline style={{ display: "flex" }}>
                  <Button
                    color={"red"}
                    icon="minus"
                    onClick={handleClick("remove", cIndex)}
                  ></Button>

                  <Button
                    color={"green"}
                    icon="plus"
                    onClick={handleClick("add", cIndex)}
                  ></Button>
                </FormField>
              </FormGroup>
            </Form>
          );
        }
      )}
    </>
  );
};

export default React.memo(CardCountsAndPacking);
