import WasmController from 'react-lib/frameworks/WasmController';
import GetProviderInfo from "issara-sdk/apis/GetProviderInfo_apps_HRM";

export type State = 
  {
  }

export const StateInitial: State = 
  {
  }

export type Event = 
  { message: "getProviderInfo", params: any }

export type Data = 
  {
    division?: number,
  }

export const DataInitial = 
  {
  }

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any 

export const getProviderInfo: Handler = async (controller, { apiToken, userId }) => {
  let params: any = {};
  return await GetProviderInfo.get({
    user_id: userId,
    params: params,
    apiToken: controller.apiToken
  });
}
