import WasmController from 'react-lib/frameworks/WasmController';
import AdmissionList from "issara-sdk/apis/AdmissionList_apps_DPI";
import ProgressionNoteList from "issara-sdk/apis/ProgressionNoteList_apps_DPI";
import ProgressionNoteDetail from "issara-sdk/apis/ProgressionNoteDetail_apps_DPI";

export type State = 
  {
  }

export const StateInitial: State = 
  {
  }

export type Event = 
  { message: "getAdmissionForm", params: any }
  |  { message: "putAdmissionForm", params: any }
  |  { message: "createProgressionNote", params: any }
  |  { message: "putProgressNote", params: any }
  |  { message: "putProgressNoteById", params: any }

export type Data = 
  {
    division?: number,
  }

export const DataInitial = 
  {
  }

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any 

export const getAdmissionForm: Handler = async (controller, emrId) => {
  return await AdmissionList.retrieve({
    pk: emrId,
    apiToken: controller.apiToken
  });
}

export const putAdmissionForm: Handler = async (controller, {emrId, data}) => {
  return await AdmissionList.update({
    pk: emrId,
    data: {
      ...data,
      
      // Manually added. This key is not sent by SubAdmission (still using qml) but required in the backend
      initial_investigation: {
        investigation_detail: data?.initial_investigation?.investigation_detail || "",
        items: [],
        version: 2
      },
    },
    apiToken: controller.apiToken
  });
}

export const createProgressionNote: Handler = async (controller, { apiToken, data }) => {
  return await ProgressionNoteList.create({
    data: data,
    apiToken: controller.apiToken
  });
}

export const putProgressNote: Handler = async (controller, { apiToken, data = {}, emrId }) => {
  // original code use put, but from url, it should be post
  let params: any = { emr: emrId }
  let saveData: any = { ...data }
  return await ProgressionNoteList.create({
    params: params,
    data: saveData
  });
}

export const putProgressNoteById: Handler = async (controller, { apiToken, data = {}, progressNoteId }) => {
  let params: any = {}
  let saveData: any = { ...data }
  return await ProgressionNoteDetail.update({
    pk: progressNoteId,
    params: params,
    data: saveData,
    apiToken: controller.apiToken
  });
}
