import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Input,
  Dropdown,
  Checkbox
} from 'semantic-ui-react'

const CardPatientInfoREGUX = (props: any) => {
    return(
      <div
        style={{ width: "100%", height: "100%" ,padding: "10px"}}>
        <div
          style={{ display: "flex", alignItems: "center" }}>
          
          <div
            style={{marginBottom: "0.5rem", fontSize: "1.2rem", fontWeight: "bold"}}>
            ประวัติผู้ป่วย
          </div>
          <div
            style={{ margin: "0px 15px" }}>
            {props.buttonVerify}
          </div>
          <div>
            {props.onMergePatientBtn}
          </div>
        </div>
        <div
          className="ui form">
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={5}>
              <label
                style={{textAlign: "right", width: "100%", fontWeight: "normal"}}>
                HN ของระบบเดิม
              </label>
            </FormField>
            <FormField
              inline={true}
              width={5}>
              <Input
                disabled={true}
                fluid={true}>
              </Input>
            </FormField>
            <FormField
              className="required"
              inline={true}
              width={6}>
              <label
                style={{minWidth: "max-content", fontWeight: "normal", color: "red"}}>
                ช่องทางให้ข้อมูล
              </label>
              <Dropdown
                clearable={true}
                fluid={true}
                id="cb-infoChanel"
                onChange={props.changeProp("info_channel")}
                options={props.masterOptions?.infoChannel}
                selection={true}
                style={{width: "100%"}}
                value={props.patient?.info_channel}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{display: "none"}}>
            <FormField
              inline={true}
              width={5}>
              <label
                style={{fontWeight: "normal"}}>
                การปกปิดข้อมูลผู้ป่วย / Concealment of patient data
              </label>
            </FormField>
            <FormField
              inline={true}
              width={5}>
              <Dropdown
                clearable={true}
                fluid={true}
                id="cb-conceal"
                onChange={props.changeProp("is_secret")}
                options={props.secretOptions}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.patient?.is_secret}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField
              className="required"
              width={5}>
              <label
                style={{fontWeight: "normal", color: "red"}}>
                เอกสารระบุตัวตน / National Identification numbe
              </label>
              <Dropdown
                clearable={true}
                fluid={true}
                id="cb-identify"
                onChange={props.changeProp("citizen_type")}
                options={props.citizenTypeOptions}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.patient?.citizen_type}>
              </Dropdown>
            </FormField>
            <FormField
              className={props.citizenLable ? "required" : ""}
              width={6}>
              <label
                style={{fontWeight: "normal", color: "red"}}>
                {props.citizenLable || "\u00a0"}
              </label>
              <div
                style={{width: "100%"}}>
                {props.citizenInput}
              </div>
            </FormField>
            <FormField
              width={4}>
              <label
                style={{fontWeight: "normal"}}>
                {props.reimburseLabel|| "\u00a0"}
              </label>
              <div>
                {props.reimburseInput}
              </div>
            </FormField>
            <FormField>
              <label>
                {"\u00a0"}
              </label>
              <div>
                {props.nonResidentChk}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField
              className={(props.patient?.citizen_type === "" || props.patient?.citizen_type === "T") && "required"}
              width={3}>
              <label
                style={{fontWeight: "normal", ...(  (props.patient?.citizen_type === "" || props.patient?.citizen_type === "T") && {  color: "red" } )}}>
                คำนำหน้า
              </label>
              <Dropdown
                clearable={true}
                compact={true}
                fluid={true}
                id="cb-prefix"
                onChange={props.changeProp("pre_name_th")}
                options={props.masterOptions?.prenameTh}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.patient?.pre_name_th}>
              </Dropdown>
            </FormField>
            <FormField
              className={(props.patient?.citizen_type === "" || props.patient?.citizen_type === "T") && "required"}
              width={4}>
              <label
                style={{fontWeight: "normal", ...(  (props.patient?.citizen_type === "" || props.patient?.citizen_type === "T") && {  color: "red" } )}}>
                ชื่อ
              </label>
              <Input
                fluid={true}
                id="txt-firstName"
                onChange={props.changeProp("first_name_th")}
                style={{width: "100%"}}
                value={props.patient?.first_name_th}>
              </Input>
            </FormField>
            <FormField
              className={(props.patient?.citizen_type === "" || props.patient?.citizen_type === "T") && "required"}
              width={4}>
              <label
                style={{fontWeight: "normal", ...(  (props.patient?.citizen_type === "" || props.patient?.citizen_type === "T") && {  color: "red" } )}}>
                นามสกุล
              </label>
              <Input
                fluid={true}
                id="txt-lastName"
                onChange={props.changeProp("last_name_th")}
                style={{width: "100%"}}
                value={props.patient?.last_name_th}>
              </Input>
            </FormField>
            <FormField
              width={4}>
              <label
                style={{fontWeight: "normal"}}>
                ชื่อกลาง
              </label>
              <Input
                fluid={true}
                onChange={props.changeProp("middle_name_th")}
                style={{width: "100%"}}
                value={props.patient?.middle_name_th}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup>
            <FormField
              className={(props.patient?.citizen_type !== "" && props.patient?.citizen_type !== "T") && "required"}
              inline={true}
              width={3}>
              <label
                style={{fontWeight: "normal", ...(  (props.patient?.citizen_type !== "" && props.patient?.citizen_type !== "T") && {  color: "red" } )}}>
                Prefix
              </label>
              <Dropdown
                clearable={true}
                compact={true}
                fluid={true}
                onChange={props.changeProp("pre_name_en")}
                options={props.masterOptions?.prenameEn}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.patient?.pre_name_en}>
              </Dropdown>
            </FormField>
            <FormField
              className={(props.patient?.citizen_type !== "" && props.patient?.citizen_type !== "T") && "required"}
              inline={true}
              width={4}>
              <label
                style={{fontWeight: "normal", ...(  (props.patient?.citizen_type !== "" && props.patient?.citizen_type !== "T") && {  color: "red" } )}}>
                First name
              </label>
              <Input
                fluid={true}
                onChange={props.changeProp("first_name_en")}
                style={{width: "100%"}}
                value={props.patient?.first_name_en}>
              </Input>
            </FormField>
            <FormField
              className={(props.patient?.citizen_type !== "" && props.patient?.citizen_type !== "T") && "required"}
              inline={true}
              width={4}>
              <label
                style={{fontWeight: "normal", ...(  (props.patient?.citizen_type !== "" && props.patient?.citizen_type !== "T") && {  color: "red" } )}}>
                Last name
              </label>
              <Input
                fluid={true}
                onChange={props.changeProp("last_name_en")}
                style={{width: "100%"}}
                value={props.patient?.last_name_en}>
              </Input>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <label
                style={{fontWeight: "normal", }}>
                Middle name
              </label>
              <Input
                onChange={props.changeProp("middle_name_en")}
                style={{width: "100%"}}
                value={props.patient?.middle_name_en}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "80px", maxWidth: "80px",fontWeight: "normal", color: "red"}}>
                เพศ / Gender*
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Dropdown
                clearable={true}
                compact={true}
                fluid={true}
                id="cb-gender"
                onChange={props.changeProp("gender")}
                options={props.masterOptions?.gender}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.patient?.gender}>
              </Dropdown>
            </FormField>
            <FormField
              className="required"
              inline={true}>
              <label
                style={{fontWeight: "normal", color: "red"}}>
                หมู่เลือด / Blood type
              </label>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <Dropdown
                clearable={true}
                compact={true}
                fluid={true}
                id="cb-blood"
                onChange={props.changeProp("blood_type")}
                options={props.masterOptions?.bloodType}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.patient?.blood_type}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              className="required"
              inline={true}
              width={5}>
              <label
                style={{minWidth: "80px", maxWidth: "80px",fontWeight: "normal", color: "red"}}>
                วันเดือนปีเกิด date of birth
              </label>
              <div
                style={{width: "100%"}}>
                {props.dateTextBox}
              </div>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <Checkbox
                checked={props.patient?.use_birthdate_special}
                name="use_birthdate_special"
                onChange={props.changeBirthdateSpecial}>
              </Checkbox>
              <Dropdown
                disabled={!props.patient?.use_birthdate_special}
                fluid={true}
                onChange={props.changeProp("birthdate")}
                options={[ {key: 1, value: "", text: "ไม่ระบุ"}, { key: 2, value: "28/02/2482", text: "29/02/2482" }, { key: 3, value: "28/02/2478", text: "29/02/2478" }, { key: 4, value: "28/02/2474", text: "29/02/2474" }, { key: 5, value: "28/02/2470", text: "29/02/2470" }, { key: 6, value: "28/02/2466", text: "29/02/2466" }, { key: 7, value: "28/02/2462", text: "29/02/2462" }, { key: 8, value: "28/02/2458", text: "29/02/2458" }, { key: 9, value: "28/02/2454", text: "29/02/2454" }, { key: 10, value: "28/02/2450", text: "29/02/2450" }, { key: 11, value: "28/02/2446", text: "29/02/2446" }, { key: 12, value: "28/02/2442", text: "29/02/2442" } ]}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.patient?.use_birthdate_special ? props.patient?.birthdate : ""}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <label
                style={{minWidth: "max-content",fontWeight: "normal"}}>
                อายุ / Age
              </label>
              <div
                style={{width: "100%"}}>
                {props.age}
              </div>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <label
                style={{minWidth: "50px",maxWidth: "50px",fontWeight: "normal"}}>
                ศาสนา / Religion
              </label>
              <Dropdown
                className="cb-nationality"
                clearable={true}
                fluid={true}
                onChange={props.changeProp("religion_code")}
                options={props.masterOptions?.religion}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.patient?.religion_code}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={5}>
              <label
                style={{minWidth: "80px", maxWidth: "80px",fontWeight: "normal"}}>
                ประเทศที่เกิด / Birth country
              </label>
              <Dropdown
                clearable={true}
                fluid={true}
                onChange={props.changeProp("birth_country")}
                options={props.masterOptions?.country}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.patient?.birth_country}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}
              width={6}>
              <label
                style={{minWidth: "85px", maxWidth: "85px",fontWeight: "normal"}}>
                จังหวัดที่เกิด / Birth place
              </label>
              <Dropdown
                clearable={true}
                fluid={true}
                onChange={props.changeProp("birth_province")}
                options={props.masterOptions?.province}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.patient?.birth_province}>
              </Dropdown>
            </FormField>
            <FormField
              className="required"
              inline={true}
              width={5}>
              <label
                style={{minWidth: "85px", maxWidth: "85px",fontWeight: "normal", color: "red"}}>
                สัญชาติ / Nationality
              </label>
              <Dropdown
                clearable={true}
                compact={true}
                fluid={true}
                id="txt-nationality"
                onChange={props.changeProp("nationality")}
                options={props.masterOptions?.nationality}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.patient?.nationality}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={8}>
              <label
                style={{minWidth: "80px", maxWidth: "80px",fontWeight: "normal"}}>
                เชื้อชาติ / Ethnicity
              </label>
              <Dropdown
                clearable={true}
                fluid={true}
                onChange={props.changeProp("race")}
                options={props.masterOptions?.race}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.patient?.race}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}
              width={8}>
              <label
                style={{width: "125px",fontWeight: "normal"}}>
                ความเชื่อ / Belief
              </label>
              <Dropdown
                clearable={true}
                fluid={true}
                onChange={props.changeProp("belief")}
                options={props.masterOptions?.belief}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.patient?.belief}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={5}>
              <label
                style={{minWidth: "80px", maxWidth: "80px",fontWeight: "normal"}}>
                อาชีพ / Occupation
              </label>
              <Dropdown
                clearable={true}
                fluid={true}
                onChange={props.changeProp("career")}
                options={props.masterOptions?.career}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.patient?.career}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}
              width={6}>
              <label
                style={{minWidth: "85px", maxWidth: "85px",fontWeight: "normal"}}>
                ตำแหน่งงาน / Position
              </label>
              <Dropdown
                clearable={true}
                fluid={true}
                onChange={props.changeProp("position")}
                options={props.masterOptions?.position}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.patient?.position}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}
              width={5}>
              <label
                style={{minWidth: "85px", maxWidth: "85px",fontWeight: "normal"}}>
                ระดับการศึกษา / Education
              </label>
              <Dropdown
                clearable={true}
                fluid={true}
                onChange={props.changeProp("education")}
                options={props.masterOptions?.education}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.patient?.education}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={8}>
              <label
                style={{minWidth: "80px", maxWidth: "80px",fontWeight: "normal"}}>
                สถานภาพสมรส / Marital status
              </label>
              <Dropdown
                clearable={true}
                fluid={true}
                onChange={props.changeProp("marriage")}
                options={props.marriageOptions}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.patient?.marriage}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}
              width={8}>
              <label
                style={{minWidth: "100px", maxWidth: "100px",fontWeight: "normal"}}>
                ชื่อคู่สมรส / Spouse's Name
              </label>
              <Input
                fluid={true}
                onChange={props.changeProp("couple")}
                style={{width: "100%"}}
                value={props.patient?.couple}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              className={props.isRequired && "required"}
              inline={true}
              width={8}>
              <label
                style={{minWidth: "80px", maxWidth: "80px",fontWeight: "normal", ...(props.isRequired && {color: "red"})}}>
                ชื่อบิดา / Father's Name
              </label>
              <Input
                fluid={true}
                id="txt-fatherName"
                onChange={props.changeProp("father")}
                style={{width: "100%"}}
                value={props.patient?.father}>
              </Input>
            </FormField>
            <FormField
              className={props.isRequired && "required"}
              inline={true}
              width={8}>
              <label
                style={{minWidth: "80px", maxWidth: "80px",fontWeight: "normal", ...(props.isRequired && {color: "red"})}}>
                ชื่อมารดา / Mother's Name
              </label>
              <Input
                fluid={true}
                id="txt-motherName"
                onChange={props.changeProp("mother")}
                style={{width: "100%"}}
                value={props.patient?.mother}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={6}>
              <label
                style={{minWidth: "80px", maxWidth: "150px",fontWeight: "normal", color: "red"}}>
                ชื่อผู้ติดต่อฉุกเฉิน / Emergency contact *
              </label>
              <Input
                fluid={true}
                onChange={props.changeProp("relative_address.owner_name")}
                style={{width: "100%"}}
                value={props.patient?.relative_address?.owner_name}>
              </Input>
            </FormField>
            <FormField
              inline={true}
              width={6}>
              <label
                style={{minWidth: "100px", maxWidth: "100px",fontWeight: "normal", color: "red"}}>
                เบอร์โทรผู้ติดต่อ / Phone No. *
              </label>
              <Input
                fluid={true}
                onChange={props.changeProp("relative_address.tel_mobile")}
                style={{width: "100%"}}
                value={props.patient?.relative_address?.tel_mobile}>
              </Input>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <label>
                ความสัมพันธ์ / Relationship
              </label>
              <Dropdown
                clearable={true}
                fluid={true}
                onChange={props.changeProp("relative_address.relative")}
                options={props.masterOptions?.relative}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.patient?.relative_address?.relative}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "80px", maxWidth: "80px",fontWeight: "normal"}}>
                นามแฝง
              </label>
            </FormField>
            <FormField
              inline={true}>
              <Checkbox
                checked={props?.patient?.nickname_active || false}
                onChange={props?.handleCheckNameActive}
                toggle={true}>
              </Checkbox>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{display: !props?.patient?.nickname_active ? "none" : ""}}>
            <FormField
              inline={true}
              width={6}>
              <label
                style={{width: "129px",fontWeight: "normal"}}>
                ชื่อนามแฝง
              </label>
              <Dropdown
                clearable={true}
                disabled={props?.isNicknameActive}
                fluid={true}
                onChange={props.changeProp("nickname")}
                options={props?.masterOptions?.nickname}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props?.patient?.nickname}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}
              width={5}>
              <label
                style={{width: "110px",fontWeight: "normal"}}>
                เลขที่อนุมัตินามแฝง
              </label>
              <Input
                disabled={props?.isNicknameActive}
                fluid={true}
                onChange={props.changeProp("nickname_permit_no")}
                style={{width: "100%"}}
                value={props?.patient?.nickname_permit_no || ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{display: !props?.patient?.nickname_active ? "none" : ""}}>
            <FormField
              inline={true}
              width={6}>
              <label
                style={{width: "129px",fontWeight: "normal"}}>
                เหตุผล
              </label>
              <Dropdown
                clearable={true}
                disabled={props?.isNicknameActive}
                fluid={true}
                onAddItem={props.onAddOptions}
                onChange={props.changeProp("nickname_reason")}
                options={props?.reasonOptions}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props?.patient?.nickname_reason}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}
              width={5}>
              <label
                style={{width: "105px",fontWeight: "normal"}}>
                ผู้อนุมัตินามแฝง
              </label>
              <Input
                disabled={props?.isNicknameActive}
                fluid={true}
                onChange={props.changeProp("nickname_warrantor")}
                style={{width: "100%"}}
                value={props?.patient?.nickname_warrantor || ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{marginBottom: "3rem"}}>
            <FormField
              inline={true}
              style={{flex: 1}}>
            </FormField>
            <FormField
              inline={true}
              style={{display: "none"}}>
              <div>
                {props.buttonSecret}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <div>
                {props.buttonSave}
              </div>
            </FormField>
          </FormGroup>
        </div>
      </div>
    )
}


export default CardPatientInfoREGUX

export const screenPropsDefault = {"first_name":"ชื่อทดสอบ","last_name":"สกุลทดสอบ","secretOptions":[{"key":"N","text":"ไม่ปกปิด","value":"N"},{"key":"S","text":"ปกปิดประวัติ","value":"S"}]}

/* Date Time : Wed Nov 06 2024 09:05:04 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"100%\" ,padding: \"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติผู้ป่วย"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.5rem\", fontSize: \"1.2rem\", fontWeight: \"bold\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormField",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormField",
      "parent": 4,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "Input",
      "parent": 6,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "label",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "HN ของระบบเดิม"
        },
        "style": {
          "type": "code",
          "value": "{textAlign: \"right\", width: \"100%\", fontWeight: \"normal\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Dropdown",
      "parent": 8,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "cb-infoChanel"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeProp(\"info_channel\")"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.infoChannel"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.patient?.info_channel"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "ช่องทางให้ข้อมูล"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\", fontWeight: \"normal\", color: \"red\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: \"none\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "FormField",
      "parent": 16,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "FormField",
      "parent": 16,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "label",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": "การปกปิดข้อมูลผู้ป่วย / Concealment of patient data"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "Dropdown",
      "parent": 18,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "cb-conceal"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeProp(\"is_secret\")"
        },
        "options": {
          "type": "code",
          "value": "props.secretOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.patient?.is_secret"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "FormField",
      "parent": 21,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "FormField",
      "parent": 21,
      "props": {
        "className": {
          "type": "code",
          "value": "props.citizenLable ? \"required\" : \"\""
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "label",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": "เอกสารระบุตัวตน / National Identification numbe"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", color: \"red\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "label",
      "parent": 23,
      "props": {
        "children": {
          "type": "code",
          "value": "props.citizenLable || \"\\u00a0\""
        },
        "className": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", color: \"red\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "Dropdown",
      "parent": 22,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "cb-identify"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeProp(\"citizen_type\")"
        },
        "options": {
          "type": "code",
          "value": "props.citizenTypeOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.patient?.citizen_type"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "FormField",
      "parent": 21,
      "props": {
      },
      "seq": 138,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "label",
      "parent": 28,
      "props": {
        "children": {
          "type": "code",
          "value": "\"\\u00a0\""
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "FormGroup",
      "parent": 3,
      "props": {
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "FormField",
      "parent": 31,
      "props": {
        "className": {
          "type": "code",
          "value": "(props.patient?.citizen_type === \"\" || props.patient?.citizen_type === \"T\") && \"required\""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "FormField",
      "parent": 31,
      "props": {
        "className": {
          "type": "code",
          "value": "(props.patient?.citizen_type === \"\" || props.patient?.citizen_type === \"T\") && \"required\""
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "FormField",
      "parent": 31,
      "props": {
        "className": {
          "type": "code",
          "value": "(props.patient?.citizen_type === \"\" || props.patient?.citizen_type === \"T\") && \"required\""
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "FormField",
      "parent": 31,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "label",
      "parent": 32,
      "props": {
        "children": {
          "type": "value",
          "value": "คำนำหน้า"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", ...(  (props.patient?.citizen_type === \"\" || props.patient?.citizen_type === \"T\") && {  color: \"red\" } )}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "label",
      "parent": 33,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", ...(  (props.patient?.citizen_type === \"\" || props.patient?.citizen_type === \"T\") && {  color: \"red\" } )}"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "label",
      "parent": 34,
      "props": {
        "children": {
          "type": "value",
          "value": "นามสกุล"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", ...(  (props.patient?.citizen_type === \"\" || props.patient?.citizen_type === \"T\") && {  color: \"red\" } )}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "label",
      "parent": 35,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อกลาง"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "Dropdown",
      "parent": 32,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "compact": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "cb-prefix"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeProp(\"pre_name_th\")"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.prenameTh"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.patient?.pre_name_th"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "Input",
      "parent": 33,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "txt-firstName"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeProp(\"first_name_th\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.patient?.first_name_th"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "Input",
      "parent": 34,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "txt-lastName"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeProp(\"last_name_th\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.patient?.last_name_th"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "Input",
      "parent": 35,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeProp(\"middle_name_th\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.patient?.middle_name_th"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "FormGroup",
      "parent": 3,
      "props": {
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "FormField",
      "parent": 44,
      "props": {
        "className": {
          "type": "code",
          "value": "(props.patient?.citizen_type !== \"\" && props.patient?.citizen_type !== \"T\") && \"required\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "FormField",
      "parent": 44,
      "props": {
        "className": {
          "type": "code",
          "value": "(props.patient?.citizen_type !== \"\" && props.patient?.citizen_type !== \"T\") && \"required\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "FormField",
      "parent": 44,
      "props": {
        "className": {
          "type": "code",
          "value": "(props.patient?.citizen_type !== \"\" && props.patient?.citizen_type !== \"T\") && \"required\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "FormField",
      "parent": 44,
      "props": {
        "className": {
          "type": "code",
          "value": ""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "Dropdown",
      "parent": 45,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "compact": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.changeProp(\"pre_name_en\")"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.prenameEn"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.patient?.pre_name_en"
        }
      },
      "seq": 177,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "Input",
      "parent": 46,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeProp(\"first_name_en\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.patient?.first_name_en"
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "Input",
      "parent": 47,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeProp(\"last_name_en\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.patient?.last_name_en"
        }
      },
      "seq": 179,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "Input",
      "parent": 48,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changeProp(\"middle_name_en\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.patient?.middle_name_en"
        }
      },
      "seq": 180,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "FormField",
      "parent": 53,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "FormField",
      "parent": 53,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 56,
      "name": "FormField",
      "parent": 53,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "label",
      "parent": 54,
      "props": {
        "children": {
          "type": "value",
          "value": "เพศ / Gender*"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"80px\", maxWidth: \"80px\",fontWeight: \"normal\", color: \"red\"}"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "Dropdown",
      "parent": 55,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "compact": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "cb-gender"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeProp(\"gender\")"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.gender"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.patient?.gender"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "FormField",
      "parent": 53,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "Dropdown",
      "parent": 59,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "compact": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "cb-blood"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeProp(\"blood_type\")"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.bloodType"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.patient?.blood_type"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "label",
      "parent": 56,
      "props": {
        "children": {
          "type": "value",
          "value": "หมู่เลือด / Blood type"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", color: \"red\"}"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "FormField",
      "parent": 63,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "FormField",
      "parent": 63,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "FormField",
      "parent": 63,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "label",
      "parent": 64,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเทศที่เกิด / Birth country"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"80px\", maxWidth: \"80px\",fontWeight: \"normal\"}"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "label",
      "parent": 65,
      "props": {
        "children": {
          "type": "value",
          "value": "จังหวัดที่เกิด / Birth place"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"85px\", maxWidth: \"85px\",fontWeight: \"normal\"}"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "label",
      "parent": 66,
      "props": {
        "children": {
          "type": "value",
          "value": "สัญชาติ / Nationality"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"85px\", maxWidth: \"85px\",fontWeight: \"normal\", color: \"red\"}"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "Dropdown",
      "parent": 64,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeProp(\"birth_country\")"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.country"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.patient?.birth_country"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "Dropdown",
      "parent": 65,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeProp(\"birth_province\")"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.province"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.patient?.birth_province"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "Dropdown",
      "parent": 66,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "compact": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "txt-nationality"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeProp(\"nationality\")"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.nationality"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.patient?.nationality"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "FormField",
      "parent": 73,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "label",
      "parent": 75,
      "props": {
        "children": {
          "type": "value",
          "value": "เชื้อชาติ / Ethnicity"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"80px\", maxWidth: \"80px\",fontWeight: \"normal\"}"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 79,
      "name": "FormField",
      "parent": 73,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "label",
      "parent": 79,
      "props": {
        "children": {
          "type": "value",
          "value": "ความเชื่อ / Belief"
        },
        "style": {
          "type": "code",
          "value": "{width: \"125px\",fontWeight: \"normal\"}"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 81,
      "name": "Dropdown",
      "parent": 79,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeProp(\"belief\")"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.belief"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.patient?.belief"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "Dropdown",
      "parent": 75,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeProp(\"race\")"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.race"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.patient?.race"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 83,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 84,
      "name": "FormField",
      "parent": 83,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 85,
      "name": "FormField",
      "parent": 83,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 86,
      "name": "FormField",
      "parent": 83,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "label",
      "parent": 84,
      "props": {
        "children": {
          "type": "value",
          "value": "อาชีพ / Occupation"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"80px\", maxWidth: \"80px\",fontWeight: \"normal\"}"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "label",
      "parent": 85,
      "props": {
        "children": {
          "type": "value",
          "value": "ตำแหน่งงาน / Position"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"85px\", maxWidth: \"85px\",fontWeight: \"normal\"}"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "label",
      "parent": 86,
      "props": {
        "children": {
          "type": "value",
          "value": "ระดับการศึกษา / Education"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"85px\", maxWidth: \"85px\",fontWeight: \"normal\"}"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 90,
      "name": "Dropdown",
      "parent": 84,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeProp(\"career\")"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.career"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.patient?.career"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 91,
      "name": "Dropdown",
      "parent": 85,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeProp(\"position\")"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.position"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.patient?.position"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 92,
      "name": "Dropdown",
      "parent": 86,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeProp(\"education\")"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.education"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.patient?.education"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 93,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 94,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 95,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 96,
      "name": "FormField",
      "parent": 93,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 97,
      "name": "FormField",
      "parent": 93,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 98,
      "name": "FormField",
      "parent": 94,
      "props": {
        "className": {
          "type": "code",
          "value": "props.isRequired && \"required\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 99,
      "name": "FormField",
      "parent": 94,
      "props": {
        "className": {
          "type": "code",
          "value": "props.isRequired && \"required\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 100,
      "name": "FormField",
      "parent": 95,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": null,
      "id": 102,
      "name": "label",
      "parent": 96,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานภาพสมรส / Marital status"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"80px\", maxWidth: \"80px\",fontWeight: \"normal\"}"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": null,
      "id": 103,
      "name": "label",
      "parent": 98,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อบิดา / Father's Name"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"80px\", maxWidth: \"80px\",fontWeight: \"normal\", ...(props.isRequired && {color: \"red\"})}"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 105,
      "name": "Dropdown",
      "parent": 96,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeProp(\"marriage\")"
        },
        "options": {
          "type": "code",
          "value": "props.marriageOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.patient?.marriage"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": null,
      "id": 106,
      "name": "label",
      "parent": 97,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อคู่สมรส / Spouse's Name"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"100px\", maxWidth: \"100px\",fontWeight: \"normal\"}"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": null,
      "id": 107,
      "name": "label",
      "parent": 99,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อมารดา / Mother's Name"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"80px\", maxWidth: \"80px\",fontWeight: \"normal\", ...(props.isRequired && {color: \"red\"})}"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 108,
      "name": "Input",
      "parent": 99,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "txt-motherName"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeProp(\"mother\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.patient?.mother"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 109,
      "name": "Input",
      "parent": 98,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "txt-fatherName"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeProp(\"father\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.patient?.father"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 110,
      "name": "Input",
      "parent": 97,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeProp(\"couple\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.patient?.couple"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 111,
      "name": "FormField",
      "parent": 95,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 112,
      "name": "Checkbox",
      "parent": 111,
      "props": {
        "checked": {
          "type": "code",
          "value": "props?.patient?.nickname_active || false"
        },
        "onChange": {
          "type": "code",
          "value": "props?.handleCheckNameActive"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": null,
      "id": 113,
      "name": "label",
      "parent": 100,
      "props": {
        "children": {
          "type": "value",
          "value": "นามแฝง"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"80px\", maxWidth: \"80px\",fontWeight: \"normal\"}"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 114,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"3rem\"}"
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 115,
      "name": "FormField",
      "parent": 114,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 116,
      "name": "FormField",
      "parent": 114,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: \"none\"}"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 117,
      "name": "FormField",
      "parent": 114,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 120,
      "name": "FormField",
      "parent": 62,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 121,
      "name": "FormField",
      "parent": 62,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 122,
      "name": "FormField",
      "parent": 62,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 123,
      "name": "FormField",
      "parent": 62,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": null,
      "id": 124,
      "name": "label",
      "parent": 120,
      "props": {
        "children": {
          "type": "value",
          "value": "วันเดือนปีเกิด date of birth"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"80px\", maxWidth: \"80px\",fontWeight: \"normal\", color: \"red\"}"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 129,
      "name": "Checkbox",
      "parent": 121,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.patient?.use_birthdate_special"
        },
        "name": {
          "type": "value",
          "value": "use_birthdate_special"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeBirthdateSpecial"
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 130,
      "name": "Dropdown",
      "parent": 121,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.patient?.use_birthdate_special"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeProp(\"birthdate\")"
        },
        "options": {
          "type": "code",
          "value": "[ {key: 1, value: \"\", text: \"ไม่ระบุ\"}, { key: 2, value: \"28/02/2482\", text: \"29/02/2482\" }, { key: 3, value: \"28/02/2478\", text: \"29/02/2478\" }, { key: 4, value: \"28/02/2474\", text: \"29/02/2474\" }, { key: 5, value: \"28/02/2470\", text: \"29/02/2470\" }, { key: 6, value: \"28/02/2466\", text: \"29/02/2466\" }, { key: 7, value: \"28/02/2462\", text: \"29/02/2462\" }, { key: 8, value: \"28/02/2458\", text: \"29/02/2458\" }, { key: 9, value: \"28/02/2454\", text: \"29/02/2454\" }, { key: 10, value: \"28/02/2450\", text: \"29/02/2450\" }, { key: 11, value: \"28/02/2446\", text: \"29/02/2446\" }, { key: 12, value: \"28/02/2442\", text: \"29/02/2442\" } ]"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.patient?.use_birthdate_special ? props.patient?.birthdate : \"\""
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": null,
      "id": 131,
      "name": "label",
      "parent": 122,
      "props": {
        "children": {
          "type": "value",
          "value": "อายุ / Age"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\",fontWeight: \"normal\"}"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": null,
      "id": 132,
      "name": "label",
      "parent": 123,
      "props": {
        "children": {
          "type": "value",
          "value": "ศาสนา / Religion"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"50px\",maxWidth: \"50px\",fontWeight: \"normal\"}"
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 133,
      "name": "Dropdown",
      "parent": 123,
      "props": {
        "className": {
          "type": "value",
          "value": "cb-nationality"
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeProp(\"religion_code\")"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.religion"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.patient?.religion_code"
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": null,
      "id": 134,
      "name": "div",
      "parent": 120,
      "props": {
        "children": {
          "type": "code",
          "value": "props.dateTextBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": null,
      "id": 135,
      "name": "div",
      "parent": 122,
      "props": {
        "children": {
          "type": "code",
          "value": "props.age"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": null,
      "id": 136,
      "name": "div",
      "parent": 23,
      "props": {
        "children": {
          "type": "code",
          "value": "props.citizenInput"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": null,
      "id": 137,
      "name": "div",
      "parent": 117,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 138,
      "name": "FormField",
      "parent": 21,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 139,
      "name": "label",
      "parent": 138,
      "props": {
        "children": {
          "type": "code",
          "value": "props.reimburseLabel|| \"\\u00a0\""
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": null,
      "id": 140,
      "name": "div",
      "parent": 138,
      "props": {
        "children": {
          "type": "code",
          "value": "props.reimburseInput"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": null,
      "id": 141,
      "name": "div",
      "parent": 28,
      "props": {
        "children": {
          "type": "code",
          "value": "props.nonResidentChk"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 142,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: !props?.patient?.nickname_active ? \"none\" : \"\"}"
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 143,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: !props?.patient?.nickname_active ? \"none\" : \"\"}"
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 144,
      "name": "FormField",
      "parent": 142,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 145,
      "name": "FormField",
      "parent": 142,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 146,
      "name": "FormField",
      "parent": 143,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 147,
      "name": "FormField",
      "parent": 143,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": null,
      "id": 148,
      "name": "label",
      "parent": 144,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อนามแฝง"
        },
        "style": {
          "type": "code",
          "value": "{width: \"129px\",fontWeight: \"normal\"}"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": null,
      "id": 149,
      "name": "label",
      "parent": 145,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขที่อนุมัตินามแฝง"
        },
        "style": {
          "type": "code",
          "value": "{width: \"110px\",fontWeight: \"normal\"}"
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": null,
      "id": 150,
      "name": "label",
      "parent": 146,
      "props": {
        "children": {
          "type": "value",
          "value": "เหตุผล"
        },
        "style": {
          "type": "code",
          "value": "{width: \"129px\",fontWeight: \"normal\"}"
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": null,
      "id": 151,
      "name": "label",
      "parent": 147,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้อนุมัตินามแฝง"
        },
        "style": {
          "type": "code",
          "value": "{width: \"105px\",fontWeight: \"normal\"}"
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 152,
      "name": "Dropdown",
      "parent": 144,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props?.isNicknameActive"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeProp(\"nickname\")"
        },
        "options": {
          "type": "code",
          "value": "props?.masterOptions?.nickname"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props?.patient?.nickname"
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 153,
      "name": "Dropdown",
      "parent": 146,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props?.isNicknameActive"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onAddOptions"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeProp(\"nickname_reason\")"
        },
        "options": {
          "type": "code",
          "value": "props?.reasonOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props?.patient?.nickname_reason"
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 154,
      "name": "Input",
      "parent": 145,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props?.isNicknameActive"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeProp(\"nickname_permit_no\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props?.patient?.nickname_permit_no || \"\""
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 155,
      "name": "Input",
      "parent": 147,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props?.isNicknameActive"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeProp(\"nickname_warrantor\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props?.patient?.nickname_warrantor || \"\""
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": null,
      "id": 156,
      "name": "div",
      "parent": 116,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSecret"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": null,
      "id": 157,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonVerify"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 15px\" }"
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 158,
      "name": "FormGroup",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 159,
      "name": "FormField",
      "parent": 158,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 160,
      "name": "FormField",
      "parent": 158,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 160,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 161,
      "name": "FormField",
      "parent": 158,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": null,
      "id": 171,
      "name": "label",
      "parent": 159,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อผู้ติดต่อฉุกเฉิน / Emergency contact *"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"80px\", maxWidth: \"150px\",fontWeight: \"normal\", color: \"red\"}"
        }
      },
      "seq": 164,
      "void": false
    },
    {
      "from": null,
      "id": 172,
      "name": "label",
      "parent": 160,
      "props": {
        "children": {
          "type": "value",
          "value": "เบอร์โทรผู้ติดต่อ / Phone No. *"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"100px\", maxWidth: \"100px\",fontWeight: \"normal\", color: \"red\"}"
        }
      },
      "seq": 163,
      "void": false
    },
    {
      "from": null,
      "id": 173,
      "name": "label",
      "parent": 161,
      "props": {
        "children": {
          "type": "value",
          "value": "ความสัมพันธ์ / Relationship"
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 174,
      "name": "Input",
      "parent": 159,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.changeProp(\"relative_address.owner_name\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.patient?.relative_address?.owner_name"
        }
      },
      "seq": 174,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 175,
      "name": "Input",
      "parent": 160,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.changeProp(\"relative_address.tel_mobile\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.patient?.relative_address?.tel_mobile"
        }
      },
      "seq": 175,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 176,
      "name": "Dropdown",
      "parent": 161,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.changeProp(\"relative_address.relative\")"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.relative"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.patient?.relative_address?.relative"
        }
      },
      "seq": 176,
      "void": false
    },
    {
      "from": null,
      "id": 177,
      "name": "label",
      "parent": 45,
      "props": {
        "children": {
          "type": "value",
          "value": "Prefix"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", ...(  (props.patient?.citizen_type !== \"\" && props.patient?.citizen_type !== \"T\") && {  color: \"red\" } )}"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 178,
      "name": "label",
      "parent": 46,
      "props": {
        "children": {
          "type": "value",
          "value": "First name"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", ...(  (props.patient?.citizen_type !== \"\" && props.patient?.citizen_type !== \"T\") && {  color: \"red\" } )}"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 179,
      "name": "label",
      "parent": 47,
      "props": {
        "children": {
          "type": "value",
          "value": "Last name"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", ...(  (props.patient?.citizen_type !== \"\" && props.patient?.citizen_type !== \"T\") && {  color: \"red\" } )}"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 180,
      "name": "label",
      "parent": 48,
      "props": {
        "children": {
          "type": "value",
          "value": "Middle name"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\", }"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 181,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.onMergePatientBtn"
        }
      },
      "seq": 181,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 95,
  "isMounted": false,
  "memo": false,
  "name": "CardPatientInfoREGUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "first_name": "ชื่อทดสอบ",
    "last_name": "สกุลทดสอบ",
    "secretOptions": [
      {
        "key": "N",
        "text": "ไม่ปกปิด",
        "value": "N"
      },
      {
        "key": "S",
        "text": "ปกปิดประวัติ",
        "value": "S"
      }
    ]
  },
  "width": 85
}

*********************************************************************************** */
