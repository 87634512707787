import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Checkbox,
  Button,
  Dropdown
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardStockTransferOrderUX = (props: any) => {
    return(
      <div
        style={{width:"100%", padding: "10px",  height: "100%"}}>
        <div
          className="ui form">
          
          <FormGroup>
            <FormField>
              <Dropdown
                onChange={(e, v) => {props.setProp("StockTransferOrderSequence.apiMode", v.value)}}
                options={props.apiModeOptions || []}
                selection={true}
                value={props.StockTransferOrderSequence?.apiMode || ""}>
              </Dropdown>
            </FormField>
            <FormField>
              <div
                style={{display: "none", alignItems: "center"}}>
                
                <Checkbox>
                </Checkbox>
                <Button
                  style={{background: "linear-gradient(0deg, rgba(33, 150, 243, 0.5), rgba(33, 150, 243, 0.5)), #FFFFFF", color: "rgba(0,0,0,0.87)", padding: "2.5px", marginLeft: "5px"}}>
                  โอนออก
                </Button>
              </div>
            </FormField>
            <FormField>
              <div
                style={{display: "none", alignItems: "center"}}>
                
                <Checkbox>
                </Checkbox>
                <Button
                  style={{background: "white", color: "rgba(0,0,0,0.87)", padding: "2.5px", marginLeft: "5px"}}>
                  รับเข้า
                </Button>
              </div>
            </FormField>
          </FormGroup>
        </div>
        <div
          className="ui form">
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "max-content"}}>
                วันที่
              </label>
            </FormField>
            <FormField
              inline={true}>
              <div
                style={{width: "100%"}}>
                {props.StartDate}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "max-content"}}>
                ถึง
              </label>
            </FormField>
            <FormField
              inline={true}>
              <div
                style={{width: "100%"}}>
                {props.EndDate}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "max-content"}}>
                แผนกผู้ขอสินค้า
              </label>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <div
                style={{width: "100%"}}>
                {props.DropdownDivisionRequester}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "max-content"}}>
                แผนกผู้ให้สินค้า
              </label>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <div
                style={{width: "100%"}}>
                {props.DropdownDivisionProvider}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "max-content"}}>
                สถานะการอนุมัติ
              </label>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <div
                style={{width: "100%"}}>
                {props.DropdownStatus}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <Button
                color="blue"
                onClick={props.searchClick}
                style={{paddingLeft: "12px", paddingRight: "12px"}}>
                ค้นหา
              </Button>
            </FormField>
            <FormField
              inline={true}>
              <Button
                color="yellow"
                onClick={props.clearSearchClick}
                style={{paddingLeft: "12px", paddingRight: "12px", minWidth: "max-content"}}>
                ล้างตัวกรอง
              </Button>
            </FormField>
          </FormGroup>
        </div>
        <div
          className="ui form">
          
          <Table
            data={props.items || []}
            getTdProps={props.getTdProps}
            getTrProps={props.getTrProps}
            headers="วันที่ / เวลา,แผนกผู้ขอ,ผู้ขอ,เลขที่ใบขอสินค้า,สถานะ,แผนกผู้ให้,เลขที่ใบโอนสินค้า"
            keys="date,request_division,request_user,request_no,status,supply_division, transfer_no"
            minRows={5}
            showPagination={false}
            style={{height: "200px"}}>
          </Table>
        </div>
      </div>
    )
}

CardStockTransferOrderUX.displayName = "CardStockTransferOrderUX";
export default React.memo(CardStockTransferOrderUX)

export const screenPropsDefault = {}

/* Date Time : Fri Jul 14 2023 08:47:50 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width:\"100%\", padding: \"10px\",  height: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 2,
      "name": "FormGroup",
      "parent": 1,
      "props": {
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "FormField",
      "parent": 2,
      "props": {
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "FormField",
      "parent": 2,
      "props": {
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "FormField",
      "parent": 2,
      "props": {
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"none\", alignItems: \"center\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "Checkbox",
      "parent": 12,
      "props": {
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Button",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "โอนออก"
        },
        "style": {
          "type": "code",
          "value": "{background: \"linear-gradient(0deg, rgba(33, 150, 243, 0.5), rgba(33, 150, 243, 0.5)), #FFFFFF\", color: \"rgba(0,0,0,0.87)\", padding: \"2.5px\", marginLeft: \"5px\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"none\", alignItems: \"center\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "Checkbox",
      "parent": 15,
      "props": {
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "Button",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "รับเข้า"
        },
        "style": {
          "type": "code",
          "value": "{background: \"white\", color: \"rgba(0,0,0,0.87)\", padding: \"2.5px\", marginLeft: \"5px\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "FormGroup",
      "parent": 19,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "FormField",
      "parent": 20,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "FormField",
      "parent": 20,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "FormField",
      "parent": 20,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "FormField",
      "parent": 20,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "FormField",
      "parent": 20,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "FormField",
      "parent": 20,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "FormField",
      "parent": 20,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "FormField",
      "parent": 20,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "FormField",
      "parent": 20,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "FormField",
      "parent": 20,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "FormField",
      "parent": 20,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "FormField",
      "parent": 20,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "label",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 22,
      "props": {
        "children": {
          "type": "code",
          "value": "props.StartDate"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "label",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 24,
      "props": {
        "children": {
          "type": "code",
          "value": "props.EndDate"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "label",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนกผู้ขอสินค้า"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "label",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนกผู้ให้สินค้า"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 28,
      "props": {
        "children": {
          "type": "code",
          "value": "props.DropdownDivisionProvider"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "label",
      "parent": 29,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะการอนุมัติ"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 30,
      "props": {
        "children": {
          "type": "code",
          "value": "props.DropdownStatus"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "Button",
      "parent": 31,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.searchClick"
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"12px\", paddingRight: \"12px\"}"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "Button",
      "parent": 32,
      "props": {
        "children": {
          "type": "value",
          "value": "ล้างตัวกรอง"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.clearSearchClick"
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"12px\", paddingRight: \"12px\", minWidth: \"max-content\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 46,
      "name": "Table",
      "parent": 45,
      "props": {
        "data": {
          "type": "code",
          "value": "props.items || []"
        },
        "getTdProps": {
          "type": "code",
          "value": "props.getTdProps"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getTrProps"
        },
        "headers": {
          "type": "value",
          "value": "วันที่ / เวลา,แผนกผู้ขอ,ผู้ขอ,เลขที่ใบขอสินค้า,สถานะ,แผนกผู้ให้,เลขที่ใบโอนสินค้า"
        },
        "keys": {
          "type": "value",
          "value": "date,request_division,request_user,request_no,status,supply_division, transfer_no"
        },
        "minRows": {
          "type": "code",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"200px\"}"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "Dropdown",
      "parent": 3,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(e, v) => {props.setProp(\"StockTransferOrderSequence.apiMode\", v.value)}"
        },
        "options": {
          "type": "code",
          "value": "props.apiModeOptions || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.StockTransferOrderSequence?.apiMode || \"\""
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "code",
          "value": "props.DropdownDivisionRequester"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 48,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": true,
  "name": "CardStockTransferOrderUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
