export default function toPDFMakeData(props: any) {
  return {
    content: [
      {
        width: `auto`,
        decoration: ``,
        decorationColor: ``,
        text: `ใบรับรองแพทย์`,
        fontSize: `18`,
        alignment: `center`,
        pageBreak: ``,
        preserveLeadingSpaces: true,
        bold: `true`,
        color: ``,
        decorationStyle: ``,
      },
      {
        decorationColor: ``,
        bold: `true`,
        text: `ผลการตรวจโควิด-19 โดยห้องปฏิบัติการ`,
        decorationStyle: ``,
        color: ``,
        width: `auto`,
        preserveLeadingSpaces: true,
        decoration: ``,
        alignment: `center`,
        pageBreak: ``,
        fontSize: `18`,
      },
      {
        decorationColor: ``,
        alignment: `left`,
        text: ` `,
        decoration: ``,
        fontSize: 15,
        bold: false,
        pageBreak: ``,
        width: `auto`,
        preserveLeadingSpaces: true,
        color: ``,
        decorationStyle: ``,
      },
      {
        columns: [
          {
            width: 420,
            preserveLeadingSpaces: true,
            color: ``,
            bold: false,
            fontSize: 15,
            alignment: `left`,
            text: ``,
            decorationStyle: ``,
            decorationColor: ``,
            pageBreak: ``,
            decoration: ``,
          },
          {
            preserveLeadingSpaces: true,
            decorationColor: ``,
            bold: true,
            pageBreak: ``,
            decorationStyle: ``,
            text: `วันที่`,
            color: ``,
            decoration: ``,
            fontSize: `15`,
            width: `auto`,
            alignment: `left`,
          },
          {
            width: 5,
            fontSize: 15,
            preserveLeadingSpaces: true,
            text: ``,
            pageBreak: ``,
            decorationStyle: ``,
            alignment: `left`,
            decorationColor: ``,
            bold: false,
            color: ``,
            decoration: ``,
          },
          {
            decoration: ``,
            preserveLeadingSpaces: true,
            text: props.items?.formatCreatedDate,
            alignment: `center`,
            decorationStyle: ``,
            decorationColor: ``,
            width: `auto`,
            bold: true,
            color: ``,
            fontSize: 15,
            pageBreak: ``,
          },
        ],
      },
      {
        color: ``,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        text: ` `,
        fontSize: 15,
        width: `auto`,
        pageBreak: ``,
        bold: false,
        decoration: ``,
        decorationStyle: ``,
        alignment: `left`,
      },
      {
        columns: [
          {
            alignment: `left`,
            preserveLeadingSpaces: true,
            color: ``,
            decorationStyle: ``,
            text: ``,
            decorationColor: ``,
            pageBreak: ``,
            width: 40,
            decoration: ``,
            bold: false,
            fontSize: 15,
          },
          {
            width: `auto`,
            alignment: `left`,
            text: `ขอรับรองว่า `,
            pageBreak: ``,
            color: ``,
            fontSize: 15,
            decoration: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            bold: false,
          },
          {
            preserveLeadingSpaces: true,
            color: ``,
            bold: false,
            pageBreak: ``,
            alignment: `left`,
            decorationColor: ``,
            text: ``,
            width: 10,
            fontSize: 15,
            decorationStyle: ``,
            decoration: ``,
          },
          {
            stack: [
              {
                margin: [0, 0, 0, -15],
                decorationStyle: ``,
                alignment: `left`,
                color: ``,
                width: `auto`,
                preserveLeadingSpaces: true,
                decoration: ``,
                fontSize: 15,
                bold: false,
                text: props.items?.patientInfo?.full_name,
                decorationColor: ``,
                pageBreak: ``,
              },
              {
                preserveLeadingSpaces: true,
                decorationColor: ``,
                fontSize: 15,
                decoration: ``,
                pageBreak: ``,
                margin: [0, 0, 0, 0],
                width: `auto`,
                color: ``,
                alignment: `left`,
                bold: false,
                decorationStyle: ``,
                text: `.....................................................................................................................................................................`,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            width: `auto`,
            preserveLeadingSpaces: true,
            color: ``,
            text: `หมายเลขประจำตัวประชาชน / เลขที่หนังสือเดินทาง / อื่นๆ`,
            alignment: `left`,
            pageBreak: ``,
            decoration: ``,
            decorationStyle: ``,
            bold: false,
            fontSize: 15,
            decorationColor: ``,
          },
          {
            fontSize: 15,
            bold: false,
            width: 10,
            decorationColor: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            text: ``,
            color: ``,
            alignment: `left`,
            pageBreak: ``,
            decorationStyle: ``,
          },
          {
            stack: [
              {
                decorationColor: ``,
                color: ``,
                alignment: `left`,
                width: `auto`,
                decorationStyle: ``,
                fontSize: 15,
                margin: [0, 0, 0, -15],
                decoration: ``,
                preserveLeadingSpaces: true,
                bold: false,
                pageBreak: ``,
                text:
                  props.items?.patientInfo?.citizen_no === "" ||
                  props.items?.patientInfo?.citizen_no === "Non citizen identity"
                    ? props.items?.patientInfo?.citizen_passport === "" ||
                      props.items?.patientInfo?.citizen_passport === "Non citizen identity"
                      ? " "
                      : props.items?.patientInfo?.citizen_passport
                    : props.items?.patientInfo?.citizen_no,
              },
              {
                margin: [0, 0, 0, 0],
                decorationColor: ``,
                preserveLeadingSpaces: true,
                decorationStyle: ``,
                alignment: `left`,
                width: `auto`,
                color: ``,
                bold: false,
                pageBreak: ``,
                fontSize: 15,
                text: `.........................................................................................................`,
                decoration: ``,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            width: `auto`,
            decorationStyle: ``,
            text: `อายุ `,
            pageBreak: ``,
            color: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decoration: ``,
            bold: false,
            decorationColor: ``,
            fontSize: 15,
          },
          {
            width: 10,
            decorationColor: ``,
            text: ``,
            bold: false,
            decorationStyle: ``,
            decoration: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            color: ``,
            pageBreak: ``,
            alignment: `left`,
          },
          {
            stack: [
              {
                decoration: ``,
                decorationColor: ``,
                alignment: `left`,
                text: `${props.items?.ageYear} ปี ${props.items?.ageMonth} เดือน ${props.items?.ageDay} วัน`,
                margin: [0, 0, 0, -15],
                fontSize: 15,
                preserveLeadingSpaces: true,
                width: `auto`,
                bold: false,
                pageBreak: ``,
                color: ``,
                decorationStyle: ``,
              },
              {
                margin: [0, 0, 0, 0],
                bold: false,
                decorationColor: ``,
                pageBreak: ``,
                alignment: `left`,
                decorationStyle: ``,
                text: `............................................................................`,
                preserveLeadingSpaces: true,
                color: ``,
                decoration: ``,
                fontSize: 15,
                width: `auto`,
              },
            ],
          },
          {
            bold: false,
            pageBreak: ``,
            color: ``,
            text: `หมายเลขผู้ป่วย    `,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decorationColor: ``,
            decorationStyle: ``,
            width: `auto`,
            fontSize: 15,
            decoration: ``,
          },
          {
            fontSize: 15,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decoration: ``,
            color: ``,
            decorationColor: ``,
            bold: false,
            alignment: `left`,
            text: ``,
            decorationStyle: ``,
            width: 10,
          },
          {
            stack: [
              {
                decoration: ``,
                text: props.items?.patientInfo?.hn,
                width: `auto`,
                fontSize: 15,
                bold: false,
                color: ``,
                pageBreak: ``,
                preserveLeadingSpaces: true,
                alignment: `left`,
                decorationStyle: ``,
                decorationColor: ``,
                margin: [0, 0, 0, -15],
              },
              {
                preserveLeadingSpaces: true,
                color: ``,
                text: `............................................................................`,
                width: `auto`,
                decorationStyle: ``,
                decoration: ``,
                pageBreak: ``,
                alignment: `left`,
                fontSize: 15,
                margin: [0, 0, 0, 0],
                bold: false,
                decorationColor: ``,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            alignment: `left`,
            text: `ผลการทดสอบ `,
            bold: false,
            decorationStyle: ``,
            fontSize: 15,
            width: `auto`,
            pageBreak: ``,
            color: ``,
            decoration: ``,
            decorationColor: ``,
          },
          {
            preserveLeadingSpaces: true,
            alignment: `left`,
            width: 10,
            pageBreak: ``,
            text: ``,
            decoration: ``,
            bold: false,
            fontSize: 15,
            decorationStyle: ``,
            color: ``,
            decorationColor: ``,
          },
          {
            stack: [
              {
                bold: false,
                color: ``,
                decoration: ``,
                decorationColor: ``,
                pageBreak: ``,
                fontSize: 15,
                text: props.items?.testedResult ? props.items?.testedResult : ` `,
                width: `auto`,
                decorationStyle: ``,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
                alignment: `left`,
              },
              {
                text: `...........................................................`,
                fontSize: 15,
                bold: false,
                margin: [0, 0, 0, 0],
                decorationStyle: ``,
                decoration: ``,
                pageBreak: ``,
                width: `auto`,
                preserveLeadingSpaces: true,
                alignment: `left`,
                decorationColor: ``,
                color: ``,
              },
            ],
          },
          {
            margin: [-22, 0, 0, 0],
            fontSize: 15,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            bold: false,
            text: `ของโรค COVID-19 โดย`,
            pageBreak: ``,
            alignment: `left`,
            decoration: ``,
            decorationStyle: ``,
            color: ``,
            width: `auto`,
          },
          {
            decorationStyle: ``,
            text: ``,
            decoration: ``,
            bold: false,
            alignment: `left`,
            decorationColor: ``,
            pageBreak: ``,
            width: 10,
            preserveLeadingSpaces: true,
            color: ``,
            fontSize: 15,
          },
          {
            stack: [
              {
                decorationStyle: ``,
                preserveLeadingSpaces: true,
                alignment: `left`,
                text: props.items?.testedMethod ? props.items?.testedMethod : ` `,
                margin: [0, 0, 0, -15],
                fontSize: 15,
                color: ``,
                pageBreak: ``,
                decoration: ``,
                bold: false,
                decorationColor: ``,
                width: `auto`,
              },
              {
                decorationStyle: ``,
                alignment: `left`,
                text: `.....................................................................`,
                preserveLeadingSpaces: true,
                bold: false,
                fontSize: 15,
                decoration: ``,
                color: ``,
                width: `auto`,
                decorationColor: ``,
                pageBreak: ``,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            bold: false,
            color: ``,
            decorationStyle: ``,
            text: `Swab เมื่อวันที่ `,
            width: `auto`,
            fontSize: 15,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decoration: ``,
          },
          {
            preserveLeadingSpaces: true,
            text: ``,
            fontSize: 15,
            width: 10,
            alignment: `left`,
            decoration: ``,
            bold: false,
            decorationStyle: ``,
            color: ``,
            decorationColor: ``,
            pageBreak: ``,
          },
          {
            stack: [
              {
                color: ``,
                width: `auto`,
                decoration: ``,
                alignment: `left`,
                margin: [0, 0, 0, -15],
                pageBreak: ``,
                text: props.items?.swabDate ? props.items?.swabDate : ` `,
                decorationStyle: ``,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                fontSize: 15,
                bold: false,
              },
              {
                alignment: `left`,
                fontSize: 15,
                text: `..........................................................`,
                decoration: ``,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                bold: false,
                margin: [0, 0, 0, 0],
                color: ``,
                decorationStyle: ``,
                pageBreak: ``,
                width: `auto`,
              },
            ],
          },
          {
            text: `และตรวจเมื่อวันที่`,
            color: ``,
            decorationStyle: ``,
            margin: [-46, 0, 0, 0],
            preserveLeadingSpaces: true,
            decoration: ``,
            alignment: `left`,
            decorationColor: ``,
            bold: false,
            width: `auto`,
            fontSize: 15,
            pageBreak: ``,
          },
          {
            preserveLeadingSpaces: true,
            width: 10,
            alignment: `left`,
            decorationColor: ``,
            color: ``,
            decorationStyle: ``,
            text: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            decoration: ``,
          },
          {
            stack: [
              {
                pageBreak: ``,
                decorationColor: ``,
                margin: [0, 0, 0, -15],
                decoration: ``,
                width: `auto`,
                decorationStyle: ``,
                fontSize: 15,
                text: props.items?.examDate,
                alignment: `left`,
                bold: false,
                color: ``,
                preserveLeadingSpaces: true,
              },
              {
                decorationColor: ``,
                pageBreak: ``,
                alignment: `left`,
                color: ``,
                width: `auto`,
                fontSize: 15,
                bold: false,
                decorationStyle: ``,
                margin: [0, 0, 0, 0],
                decoration: ``,
                preserveLeadingSpaces: true,
                text: `..............................................................................`,
              },
            ],
          },
        ],
      },
      {
        fontSize: 15,
        text: `ผู้ป่วยได้รับคำแนะนำให้อยู่ในความดูแลของบุคลากรทางการแพทย์ตามแนวทางของกระทรวงสาธารณสุข (สธ.)`,
        pageBreak: ``,
        bold: false,
        color: ``,
        decorationStyle: ``,
        decoration: ``,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        alignment: `left`,
        width: `auto`,
      },
      {
        alignment: `left`,
        fontSize: 15,
        width: `auto`,
        decorationColor: ``,
        decoration: ``,
        pageBreak: ``,
        color: ``,
        preserveLeadingSpaces: true,
        bold: false,
        text: ` `,
        decorationStyle: ``,
      },
      {
        columns: [
          {
            fontSize: 15,
            bold: false,
            decorationColor: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            text: `ข้อเสนอแนะอื่นๆ : `,
            decorationStyle: ``,
            decoration: ``,
            color: ``,
            alignment: `left`,
            width: `auto`,
          },
          {
            decoration: ``,
            bold: false,
            width: 10,
            pageBreak: ``,
            fontSize: 15,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            color: ``,
            text: ``,
            decorationColor: ``,
          },
          {
            stack: [
              {
                width: `auto`,
                decorationStyle: ``,
                margin: [0, 0, 0, -15],
                alignment: `left`,
                color: ``,
                decorationColor: ``,
                fontSize: 15,
                text:
                  props.items?.comment == null || props.items?.comment === ""
                    ? " "
                    : props.items?.comment,
                bold: false,
                decoration: ``,
                preserveLeadingSpaces: true,
                pageBreak: ``,
              },
              {
                preserveLeadingSpaces: true,
                pageBreak: ``,
                width: `auto`,
                text: `...........................................................................................................................................................................`,
                color: ``,
                fontSize: 15,
                decorationStyle: ``,
                decorationColor: ``,
                margin: [0, 0, 0, 0],
                bold: false,
                decoration: ``,
                alignment: `left`,
              },
            ],
          },
        ],
      },
      {
        width: `auto`,
        decoration: ``,
        color: ``,
        preserveLeadingSpaces: true,
        bold: false,
        alignment: `left`,
        fontSize: 15,
        margin: [0, 0, 0, 0],
        decorationColor: ``,
        pageBreak: ``,
        text: `.............................................................................................................................................................................................................`,
        decorationStyle: ``,
      },
      {
        decoration: ``,
        width: `auto`,
        color: ``,
        bold: false,
        pageBreak: ``,
        decorationColor: ``,
        text: `.............................................................................................................................................................................................................`,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        alignment: `left`,
        fontSize: 15,
        margin: [0, 0, 0, 0],
      },
      {
        alignment: `left`,
        width: `auto`,
        bold: false,
        decoration: ``,
        fontSize: 15,
        pageBreak: ``,
        decorationColor: ``,
        color: ``,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        text: ` `,
      },
      {
        columns: [
          {
            text: `ผู้ป่วยจำเป็นต้องพักงานเป็นระยะเวลา จำนวน `,
            color: ``,
            alignment: `left`,
            pageBreak: ``,
            fontSize: 15,
            bold: false,
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationColor: ``,
            width: `auto`,
            decorationStyle: ``,
          },
          {
            text: ``,
            bold: false,
            decorationStyle: ``,
            pageBreak: ``,
            decorationColor: ``,
            color: ``,
            alignment: `left`,
            width: 10,
            preserveLeadingSpaces: true,
            decoration: ``,
            fontSize: 15,
          },
          {
            stack: [
              {
                text: props.items?.absenceDuration ? props.items?.absenceDuration : " ",
                color: ``,
                width: `auto`,
                decorationColor: ``,
                alignment: `center`,
                pageBreak: ``,
                decoration: ``,
                bold: false,
                decorationStyle: ``,
                fontSize: 15,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `....................`,
                color: ``,
                width: `auto`,
                decorationColor: ``,
                alignment: `left`,
                pageBreak: ``,
                decoration: ``,
                bold: false,
                decorationStyle: ``,
                fontSize: 15,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
            width: `auto`,
          },
          {
            decoration: ``,
            decorationStyle: ``,
            text: ``,
            width: 10,
            pageBreak: ``,
            decorationColor: ``,
            fontSize: 15,
            bold: false,
            alignment: `left`,
            preserveLeadingSpaces: true,
            color: ``,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            preserveLeadingSpaces: true,
            text: `วัน             ตั้งแต่`,
            decoration: ``,
            fontSize: 15,
            color: ``,
            bold: false,
            alignment: `left`,
            decorationStyle: ``,
            decorationColor: ``,
            pageBreak: ``,
          },
          {
            decoration: ``,
            width: 10,
            text: ``,
            decorationStyle: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            color: ``,
            fontSize: 15,
            bold: false,
            alignment: `left`,
          },
          {
            stack: [
              {
                text: props.items?.absenceStartDate ? props.items?.absenceStartDate : " ",
                color: ``,
                width: `auto`,
                decorationColor: ``,
                alignment: `center`,
                pageBreak: ``,
                decoration: ``,
                bold: false,
                decorationStyle: ``,
                fontSize: 15,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.........................`,
                color: ``,
                width: `auto`,
                decorationColor: ``,
                alignment: `left`,
                pageBreak: ``,
                decoration: ``,
                bold: false,
                decorationStyle: ``,
                fontSize: 15,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
            width: `auto`,
          },
          {
            pageBreak: ``,
            width: 10,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            text: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            bold: false,
            color: ``,
            decoration: ``,
            decorationColor: ``,
          },
          {
            bold: false,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decorationColor: ``,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            text: `ถึง`,
            color: ``,
            width: `auto`,
          },
          {
            width: 10,
            fontSize: 15,
            pageBreak: ``,
            decorationStyle: ``,
            text: ``,
            bold: false,
            color: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decoration: ``,
            decorationColor: ``,
          },
          {
            stack: [
              {
                text: props.items?.absenceEndDate ? props.items?.absenceEndDate : " ",
                color: ``,
                width: `auto`,
                decorationColor: ``,
                alignment: `center`,
                pageBreak: ``,
                decoration: ``,
                bold: false,
                decorationStyle: ``,
                fontSize: 15,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.........................`,
                color: ``,
                width: `auto`,
                decorationColor: ``,
                alignment: `left`,
                pageBreak: ``,
                decoration: ``,
                bold: false,
                decorationStyle: ``,
                fontSize: 15,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
            width: `auto`,
          },
        ],
      },
      {
        preserveLeadingSpaces: true,
        text: ` `,
        color: ``,
        decoration: ``,
        alignment: `left`,
        bold: false,
        decorationColor: ``,
        width: `auto`,
        decorationStyle: ``,
        fontSize: 15,
        pageBreak: ``,
      },
      {
        color: ``,
        fontSize: 15,
        width: `auto`,
        decorationColor: ``,
        alignment: `left`,
        bold: false,
        text: ` `,
        decorationStyle: ``,
        pageBreak: ``,
        preserveLeadingSpaces: true,
        decoration: ``,
      },
      {
        decorationColor: ``,
        preserveLeadingSpaces: true,
        bold: false,
        decoration: ``,
        fontSize: 15,
        width: `auto`,
        decorationStyle: ``,
        pageBreak: ``,
        alignment: `left`,
        color: ``,
        text: ` `,
      },
      {
        fontSize: 15,
        alignment: `left`,
        decorationStyle: ``,
        bold: false,
        width: `auto`,
        text: ` `,
        preserveLeadingSpaces: true,
        color: ``,
        pageBreak: ``,
        decorationColor: ``,
        decoration: ``,
      },
      {
        pageBreak: ``,
        bold: false,
        width: `auto`,
        decoration: ``,
        decorationStyle: ``,
        color: ``,
        alignment: `left`,
        fontSize: 15,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        text: ` `,
      },
      {
        width: `auto`,
        decorationColor: ``,
        color: ``,
        decorationStyle: ``,
        text: ` `,
        alignment: `left`,
        pageBreak: ``,
        bold: false,
        fontSize: 15,
        decoration: ``,
        preserveLeadingSpaces: true,
      },
      {
        alignment: `left`,
        preserveLeadingSpaces: true,
        color: ``,
        decorationColor: ``,
        decoration: ``,
        width: `auto`,
        bold: false,
        fontSize: 15,
        pageBreak: ``,
        text: ` `,
        decorationStyle: ``,
      },
      {
        decorationColor: ``,
        pageBreak: ``,
        fontSize: 15,
        text: ` `,
        alignment: `left`,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        color: ``,
        width: `auto`,
        decoration: ``,
        bold: false,
      },
      {
        columns: [
          {
            color: ``,
            decorationStyle: ``,
            width: `auto`,
            bold: false,
            text: `ลงนามแพทย์ผู้ตรวจ `,
            fontSize: 15,
            decoration: ``,
            decorationColor: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
          },
          {
            decorationColor: ``,
            width: 30,
            text: ``,
            fontSize: 15,
            decoration: ``,
            preserveLeadingSpaces: true,
            color: ``,
            decorationStyle: ``,
            bold: false,
            pageBreak: ``,
            alignment: `left`,
          },
          {
            decorationStyle: ``,
            width: `auto`,
            pageBreak: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            bold: false,
            fontSize: 15,
            decorationColor: ``,
            text: `.............................................................`,
            alignment: `center`,
            color: ``,
          },
          {
            text: ` `,
            width: 30,
            fontSize: 15,
            decoration: ``,
            pageBreak: ``,
            decorationStyle: ``,
            color: ``,
            bold: false,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decorationColor: ``,
          },
          {
            decorationColor: ``,
            width: `auto`,
            alignment: `left`,
            fontSize: 15,
            bold: false,
            color: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            text: `เลขใบประกอบวิชาชีพแพทย์ `,
            decoration: ``,
            decorationStyle: ``,
          },
          {
            fontSize: 15,
            color: ``,
            bold: false,
            width: 15,
            decorationStyle: ``,
            text: ``,
            decorationColor: ``,
            decoration: ``,
            alignment: `left`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
          },
          {
            fontSize: 15,
            decorationStyle: `dotted`,
            width: `auto`,
            bold: false,
            alignment: `center`,
            pageBreak: ``,
            decoration: `underline`,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            text: props.items?.doctorCertNumber
              ? props.items?.doctorCertNumber
              : "                    ",
            color: ``,
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            alignment: `left`,
            text: ``,
            width: 134,
            color: ``,
            bold: false,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decorationStyle: ``,
            decorationColor: ``,
            decoration: ``,
          },
          {
            pageBreak: ``,
            decoration: ``,
            decorationStyle: ``,
            bold: false,
            fontSize: 15,
            color: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            text: `(`,
            width: `auto`,
            alignment: `left`,
          },
          {
            bold: false,
            decorationStyle: ``,
            decorationColor: ``,
            decoration: ``,
            text: props.items?.doctorName,
            color: ``,
            alignment: `center`,
            width: `auto`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
          },
          {
            fontSize: 15,
            decorationStyle: ``,
            alignment: `left`,
            decoration: ``,
            width: `auto`,
            decorationColor: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            text: `)`,
            bold: false,
            color: ``,
          },
        ],
      },
    ],
    defaultStyle: {
      font: `THSarabunNew`,
    },
    pageSize: `A4`,
    pageOrientation: `portrait`,
    header: props.header,
    footer: props.footer,
  };
}
