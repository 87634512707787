import React from "react";
import { IntlProvider, FormattedMessage, useIntl } from "react-intl";

import { Checkbox, Button, Input } from "semantic-ui-react";

const ModSpecialDirectionUX = (props: any) => {
  return (
    <div style={{ height: "270px" }}>
      <div
        style={{
          fontWeight: "bold",
          fontSize: "15px",
          padding: "30px 50px 15px",
        }}
      >
        รายละเอียดการสั่งยา
      </div>
      <div style={{ display: props.hideDuration ? "none" : "" }}>
        hideDuration
      </div>
      <div style={{ display: props.hideWeekday ? "none" : "" }}>
        hideWeekday
      </div>
      <div style={{ height: props.hideAdminTime ? "0px" : "120px" }}>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "grid",
            position: "relative",
            gridTemplateRows: "repeat(6, 1fr)",
            gridTemplateColumns: "repeat(25, 1fr)",
          }}
        >
          <div style={{ gridRow: "2/3", gridColumn: "2/4" }}>
            <Checkbox
              checked={props.DrugSelectSequence?.admin_time?.includes("00:00")}
              label="00:00"
              name="00:00"
              onChange={props.onChangeCheckbox}
            ></Checkbox>
          </div>
          <div style={{ gridRow: "2/3", gridColumn: "5/7" }}>
            <Checkbox
              checked={props.DrugSelectSequence?.admin_time?.includes("01:00")}
              label="01:00"
              name="01:00"
              onChange={props.onChangeCheckbox}
            ></Checkbox>
          </div>
          <div style={{ gridRow: "2/3", gridColumn: "8/10" }}>
            <Checkbox
              checked={props.DrugSelectSequence?.admin_time?.includes("02:00")}
              label="02:00"
              name="02:00"
              onChange={props.onChangeCheckbox}
            ></Checkbox>
          </div>
          <div style={{ gridRow: "2/3", gridColumn: "11/13" }}>
            <Checkbox
              checked={props.DrugSelectSequence?.admin_time?.includes("03:00")}
              label="03:00"
              name="03:00"
              onChange={props.onChangeCheckbox}
            ></Checkbox>
          </div>
          <div style={{ gridRow: "2/3", gridColumn: "14/16" }}>
            <Checkbox
              checked={props.DrugSelectSequence?.admin_time?.includes("04:00")}
              label="04:00"
              name="04:00"
              onChange={props.onChangeCheckbox}
            ></Checkbox>
          </div>
          <div style={{ gridRow: "2/3", gridColumn: "17/19" }}>
            <Checkbox
              checked={props.DrugSelectSequence?.admin_time?.includes("05:00")}
              label="05:00"
              name="05:00"
              onChange={props.onChangeCheckbox}
            ></Checkbox>
          </div>
          <div style={{ gridRow: "2/3", gridColumn: "20/22" }}>
            <Checkbox
              checked={props.DrugSelectSequence?.admin_time?.includes("06:00")}
              label="06:00"
              name="06:00"
              onChange={props.onChangeCheckbox}
            ></Checkbox>
          </div>
          <div style={{ gridRow: "2/3", gridColumn: "23/25" }}>
            <Checkbox
              checked={props.DrugSelectSequence?.admin_time?.includes("07:00")}
              label="07:00"
              name="07:00"
              onChange={props.onChangeCheckbox}
            ></Checkbox>
          </div>
          <div style={{ gridRow: "4/5", gridColumn: "2/4" }}>
            <Checkbox
              checked={props.DrugSelectSequence?.admin_time?.includes("08:00")}
              label="08:00"
              name="08:00"
              onChange={props.onChangeCheckbox}
            ></Checkbox>
          </div>
          <div style={{ gridRow: "4/5", gridColumn: "5/7" }}>
            <Checkbox
              checked={props.DrugSelectSequence?.admin_time?.includes("09:00")}
              label="09:00"
              name="09:00"
              onChange={props.onChangeCheckbox}
            ></Checkbox>
          </div>
          <div style={{ gridRow: "4/5", gridColumn: "8/10" }}>
            <Checkbox
              checked={props.DrugSelectSequence?.admin_time?.includes("10:00")}
              label="10:00"
              name="10:00"
              onChange={props.onChangeCheckbox}
            ></Checkbox>
          </div>
          <div style={{ gridRow: "4/5", gridColumn: "11/13" }}>
            <Checkbox
              checked={props.DrugSelectSequence?.admin_time?.includes("11:00")}
              label="11:00"
              name="11:00"
              onChange={props.onChangeCheckbox}
            ></Checkbox>
          </div>
          <div style={{ gridRow: "4/5", gridColumn: "14/16" }}>
            <Checkbox
              checked={props.DrugSelectSequence?.admin_time?.includes("12:00")}
              label="12:00"
              name="12:00"
              onChange={props.onChangeCheckbox}
            ></Checkbox>
          </div>
          <div style={{ gridRow: "4/5", gridColumn: "17/19" }}>
            <Checkbox
              checked={props.DrugSelectSequence?.admin_time?.includes("13:00")}
              label="13:00"
              name="13:00"
              onChange={props.onChangeCheckbox}
            ></Checkbox>
          </div>
          <div style={{ gridRow: "4/5", gridColumn: "20/22" }}>
            <Checkbox
              checked={props.DrugSelectSequence?.admin_time?.includes("14:00")}
              label="14:00"
              name="14:00"
              onChange={props.onChangeCheckbox}
            ></Checkbox>
          </div>
          <div style={{ gridRow: "4/5", gridColumn: "23/25" }}>
            <Checkbox
              checked={props.DrugSelectSequence?.admin_time?.includes("15:00")}
              label="15:00"
              name="15:00"
              onChange={props.onChangeCheckbox}
            ></Checkbox>
          </div>
          <div style={{ gridRow: "6/7", gridColumn: "2/4" }}>
            <Checkbox
              checked={props.DrugSelectSequence?.admin_time?.includes("16:00")}
              label="16:00"
              name="16:00"
              onChange={props.onChangeCheckbox}
            ></Checkbox>
          </div>
          <div style={{ gridRow: "6/7", gridColumn: "5/7" }}>
            <Checkbox
              checked={props.DrugSelectSequence?.admin_time?.includes("17:00")}
              label="17:00"
              name="17:00"
              onChange={props.onChangeCheckbox}
            ></Checkbox>
          </div>
          <div style={{ gridRow: "6/7", gridColumn: "8/10" }}>
            <Checkbox
              checked={props.DrugSelectSequence?.admin_time?.includes("18:00")}
              label="18:00"
              name="18:00"
              onChange={props.onChangeCheckbox}
            ></Checkbox>
          </div>
          <div style={{ gridRow: "6/7", gridColumn: "11/13" }}>
            <Checkbox
              checked={props.DrugSelectSequence?.admin_time?.includes("19:00")}
              label="19:00"
              name="19:00"
              onChange={props.onChangeCheckbox}
            ></Checkbox>
          </div>
          <div style={{ gridRow: "6/7", gridColumn: "14/16" }}>
            <Checkbox
              checked={props.DrugSelectSequence?.admin_time?.includes("20:00")}
              label="20:00"
              name="20:00"
              onChange={props.onChangeCheckbox}
            ></Checkbox>
          </div>
          <div style={{ gridRow: "6/7", gridColumn: "17/19" }}>
            <Checkbox
              checked={props.DrugSelectSequence?.admin_time?.includes("21:00")}
              label="21:00"
              name="21:00"
              onChange={props.onChangeCheckbox}
            ></Checkbox>
          </div>
          <div style={{ gridRow: "6/7", gridColumn: "20/22" }}>
            <Checkbox
              checked={props.DrugSelectSequence?.admin_time?.includes("22:00")}
              label="22:00"
              name="22:00"
              onChange={props.onChangeCheckbox}
            ></Checkbox>
          </div>
          <div style={{ gridRow: "6/7", gridColumn: "23/25" }}>
            <Checkbox
              checked={props.DrugSelectSequence?.admin_time?.includes("23:00")}
              label="23:00"
              name="23:00"
              onChange={props.onChangeCheckbox}
            ></Checkbox>
          </div>
        </div>
      </div>
      <div style={{ display: props.hideIntraveneous ? "none" : "" }}>
        hideIntraveneous
      </div>
      <div style={{ height: "60px" }}>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "grid",
            position: "relative",
            gridTemplateRows: "repeat(2, 1fr)",
            gridTemplateColumns: "repeat(25, 1fr)",
          }}
        >
          <div
            style={{ gridRow: "2/3", gridColumn: "2/4", fontWeight: "bold" }}
          >
            Remark
          </div>
          <div
            style={{ gridRow: "2/3", gridColumn: "4/21", marginRight: "5px" }}
          >
            <Input
              fluid={true}
              name="extra_note"
              onChange={props.onChangeInput}
              value={props.DrugSelectSequence?.extra_note}
            ></Input>
          </div>
          <div style={{ gridRow: "2/3", gridColumn: "21/23" }}>
            <Button color="green" onClick={props.onClose} size="small">
              SAVE
            </Button>
          </div>
          <div style={{ gridRow: "2/3", gridColumn: "23/25" }}>
            <Button onClick={props.onClose} size="small">
              Close
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModSpecialDirectionUX;

export const screenPropsDefault = {};

/* Date Time : Wed Jun 01 2022 12:15:47 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ height: \"270px\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "hideDuration"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.hideDuration ? \"none\" : \"\" }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "hideWeekday"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.hideWeekday ? \"none\" : \"\" }"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ height: props.hideAdminTime ? \"0px\" : \"120px\" }"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "hideIntraveneous"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.hideIntraveneous ? \"none\" : \"\" }"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ height: \"60px\" }"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "label": "divgrid",
      "name": "div",
      "parent": 9,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(2, 1fr)\",\"gridTemplateColumns\":\"repeat(25, 1fr)\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "label": "divgrid",
      "name": "div",
      "parent": 5,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(6, 1fr)\",\"gridTemplateColumns\":\"repeat(25, 1fr)\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 11,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"2/4\" }"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 11,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"5/7\" }"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 11,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"8/10\" }"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "div",
      "parent": 11,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"11/13\" }"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "div",
      "parent": 11,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"14/16\" }"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 11,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"17/19\" }"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 11,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"20/22\" }"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "div",
      "parent": 11,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"23/25\" }"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "Checkbox",
      "parent": 28,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.DrugSelectSequence?.admin_time?.includes(\"00:00\")"
        },
        "label": {
          "type": "value",
          "value": "00:00"
        },
        "name": {
          "type": "value",
          "value": "00:00"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeCheckbox"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "Checkbox",
      "parent": 29,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.DrugSelectSequence?.admin_time?.includes(\"01:00\")"
        },
        "label": {
          "type": "value",
          "value": "01:00"
        },
        "name": {
          "type": "value",
          "value": "01:00"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeCheckbox"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "Checkbox",
      "parent": 30,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.DrugSelectSequence?.admin_time?.includes(\"02:00\")"
        },
        "label": {
          "type": "value",
          "value": "02:00"
        },
        "name": {
          "type": "value",
          "value": "02:00"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeCheckbox"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "Checkbox",
      "parent": 31,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.DrugSelectSequence?.admin_time?.includes(\"03:00\")"
        },
        "label": {
          "type": "value",
          "value": "03:00"
        },
        "name": {
          "type": "value",
          "value": "03:00"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeCheckbox"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "Checkbox",
      "parent": 32,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.DrugSelectSequence?.admin_time?.includes(\"04:00\")"
        },
        "label": {
          "type": "value",
          "value": "04:00"
        },
        "name": {
          "type": "value",
          "value": "04:00"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeCheckbox"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "Checkbox",
      "parent": 33,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.DrugSelectSequence?.admin_time?.includes(\"05:00\")"
        },
        "label": {
          "type": "value",
          "value": "05:00"
        },
        "name": {
          "type": "value",
          "value": "05:00"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeCheckbox"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "Checkbox",
      "parent": 34,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.DrugSelectSequence?.admin_time?.includes(\"06:00\")"
        },
        "label": {
          "type": "value",
          "value": "06:00"
        },
        "name": {
          "type": "value",
          "value": "06:00"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeCheckbox"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "Checkbox",
      "parent": 35,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.DrugSelectSequence?.admin_time?.includes(\"07:00\")"
        },
        "label": {
          "type": "value",
          "value": "07:00"
        },
        "name": {
          "type": "value",
          "value": "07:00"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeCheckbox"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "รายละเอียดการสั่งยา"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"15px\", padding: \"30px 50px 15px\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "div",
      "parent": 11,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"2/4\" }"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "div",
      "parent": 11,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"5/7\" }"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "div",
      "parent": 11,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"8/10\" }"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": null,
      "id": 64,
      "name": "div",
      "parent": 11,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"11/13\" }"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "div",
      "parent": 11,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"14/16\" }"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": null,
      "id": 66,
      "name": "div",
      "parent": 11,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"17/19\" }"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "div",
      "parent": 11,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"20/22\" }"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "div",
      "parent": 11,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"23/25\" }"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "Checkbox",
      "parent": 61,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.DrugSelectSequence?.admin_time?.includes(\"08:00\")"
        },
        "label": {
          "type": "value",
          "value": "08:00"
        },
        "name": {
          "type": "value",
          "value": "08:00"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeCheckbox"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "Checkbox",
      "parent": 62,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.DrugSelectSequence?.admin_time?.includes(\"09:00\")"
        },
        "label": {
          "type": "value",
          "value": "09:00"
        },
        "name": {
          "type": "value",
          "value": "09:00"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeCheckbox"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "Checkbox",
      "parent": 63,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.DrugSelectSequence?.admin_time?.includes(\"10:00\")"
        },
        "label": {
          "type": "value",
          "value": "10:00"
        },
        "name": {
          "type": "value",
          "value": "10:00"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeCheckbox"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "Checkbox",
      "parent": 64,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.DrugSelectSequence?.admin_time?.includes(\"11:00\")"
        },
        "label": {
          "type": "value",
          "value": "11:00"
        },
        "name": {
          "type": "value",
          "value": "11:00"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeCheckbox"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "Checkbox",
      "parent": 65,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.DrugSelectSequence?.admin_time?.includes(\"12:00\")"
        },
        "label": {
          "type": "value",
          "value": "12:00"
        },
        "name": {
          "type": "value",
          "value": "12:00"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeCheckbox"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "Checkbox",
      "parent": 66,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.DrugSelectSequence?.admin_time?.includes(\"13:00\")"
        },
        "label": {
          "type": "value",
          "value": "13:00"
        },
        "name": {
          "type": "value",
          "value": "13:00"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeCheckbox"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "Checkbox",
      "parent": 67,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.DrugSelectSequence?.admin_time?.includes(\"14:00\")"
        },
        "label": {
          "type": "value",
          "value": "14:00"
        },
        "name": {
          "type": "value",
          "value": "14:00"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeCheckbox"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "Checkbox",
      "parent": 68,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.DrugSelectSequence?.admin_time?.includes(\"15:00\")"
        },
        "label": {
          "type": "value",
          "value": "15:00"
        },
        "name": {
          "type": "value",
          "value": "15:00"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeCheckbox"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 93,
      "name": "div",
      "parent": 11,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"2/4\" }"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "div",
      "parent": 11,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"5/7\" }"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "div",
      "parent": 11,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"8/10\" }"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": null,
      "id": 96,
      "name": "div",
      "parent": 11,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"11/13\" }"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "div",
      "parent": 11,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"14/16\" }"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": null,
      "id": 98,
      "name": "div",
      "parent": 11,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"17/19\" }"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 99,
      "name": "div",
      "parent": 11,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"20/22\" }"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": null,
      "id": 100,
      "name": "div",
      "parent": 11,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"23/25\" }"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 101,
      "name": "Checkbox",
      "parent": 100,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.DrugSelectSequence?.admin_time?.includes(\"23:00\")"
        },
        "label": {
          "type": "value",
          "value": "23:00"
        },
        "name": {
          "type": "value",
          "value": "23:00"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeCheckbox"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 102,
      "name": "Checkbox",
      "parent": 99,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.DrugSelectSequence?.admin_time?.includes(\"22:00\")"
        },
        "label": {
          "type": "value",
          "value": "22:00"
        },
        "name": {
          "type": "value",
          "value": "22:00"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeCheckbox"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 103,
      "name": "Checkbox",
      "parent": 98,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.DrugSelectSequence?.admin_time?.includes(\"21:00\")"
        },
        "label": {
          "type": "value",
          "value": "21:00"
        },
        "name": {
          "type": "value",
          "value": "21:00"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeCheckbox"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 104,
      "name": "Checkbox",
      "parent": 97,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.DrugSelectSequence?.admin_time?.includes(\"20:00\")"
        },
        "label": {
          "type": "value",
          "value": "20:00"
        },
        "name": {
          "type": "value",
          "value": "20:00"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeCheckbox"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 105,
      "name": "Checkbox",
      "parent": 96,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.DrugSelectSequence?.admin_time?.includes(\"19:00\")"
        },
        "label": {
          "type": "value",
          "value": "19:00"
        },
        "name": {
          "type": "value",
          "value": "19:00"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeCheckbox"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 106,
      "name": "Checkbox",
      "parent": 95,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.DrugSelectSequence?.admin_time?.includes(\"18:00\")"
        },
        "label": {
          "type": "value",
          "value": "18:00"
        },
        "name": {
          "type": "value",
          "value": "18:00"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeCheckbox"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 107,
      "name": "Checkbox",
      "parent": 94,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.DrugSelectSequence?.admin_time?.includes(\"17:00\")"
        },
        "label": {
          "type": "value",
          "value": "17:00"
        },
        "name": {
          "type": "value",
          "value": "17:00"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeCheckbox"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 108,
      "name": "Checkbox",
      "parent": 93,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.DrugSelectSequence?.admin_time?.includes(\"16:00\")"
        },
        "label": {
          "type": "value",
          "value": "16:00"
        },
        "name": {
          "type": "value",
          "value": "16:00"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeCheckbox"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": null,
      "id": 111,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "Remark"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"2/4\", fontWeight: \"bold\" }"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": null,
      "id": 114,
      "name": "div",
      "parent": 10,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"23/25\" }"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 115,
      "name": "Button",
      "parent": 114,
      "props": {
        "children": {
          "type": "value",
          "value": "Close"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "color": {
          "type": "value",
          "value": ""
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": null,
      "id": 118,
      "name": "div",
      "parent": 10,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"21/23\" }"
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 119,
      "name": "Button",
      "parent": 118,
      "props": {
        "children": {
          "type": "value",
          "value": "SAVE"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": null,
      "id": 122,
      "name": "div",
      "parent": 10,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"4/21\", marginRight: \"5px\" }"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 123,
      "name": "Input",
      "parent": 122,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "label": {
          "type": "value",
          "value": ""
        },
        "name": {
          "type": "value",
          "value": "extra_note"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeInput"
        },
        "value": {
          "type": "code",
          "value": "props.DrugSelectSequence?.extra_note"
        }
      },
      "seq": 123,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "ModSpecialDirectionUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
