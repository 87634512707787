import WasmController from "react-lib/frameworks/WasmController";

// APIs
// INF
import BillTransactionIPDList from "issara-sdk/apis/BillTransactionIPDList_apps_INF";
import BillTransactionIPDSummary from "issara-sdk/apis/BillTransactionIPDSummary_apps_INF";
import BillTransactionList from "issara-sdk/apis/BillTransactionList_apps_INF";
import BillTransactionSummaryList from "issara-sdk/apis/BillTransactionSummaryList_apps_INF";
import CoveragePayerSentClaimGroupList from "issara-sdk/apis/CoveragePayerSentClaimGroupList_apps_INF";
import CreateARTransactionAPIView from "issara-sdk/apis/CreateARTransactionAPIView_apps_INF";
import DoGenerateSentClaimDataHISFromGroupAPIView from "issara-sdk/apis/DoGenerateSentClaimDataHISFromGroupAPIView_apps_INF";
import DoGenerateSentClaimDataIPDFromGroupAPIView from "issara-sdk/apis/DoGenerateSentClaimDataIPDFromGroupAPIView_apps_INF";
import SendClaimCoverageDocumentReport from "issara-sdk/apis/SendClaimCoverageDocumentReport_apps_INF";

// Serializer
import BillTransactionIPDListSerializerI from "issara-sdk/types/BillTransactionIPDListSerializer_apps_INF";
import BillTransactionListSerializerI from "issara-sdk/types/BillTransactionListSerializer_apps_INF";

// Common
import getPdfMake from "react-lib/appcon/common/pdfMake";
import {
  ProcessedLeaves,
  RetTypeAtPath,
  SetErrorMessage,
  SetProperty,
} from "react-lib/apps/HISV3/common/CommonInterface";

import FormMedicalExpenseInvoice from "../pdf/FormMedicalExpenseInvoice";

import { State as MainState } from "HIS/MainHISInterface";

// Utils
import { beToAd } from "react-lib/utils/dateUtils";
import { downloadFile } from "react-lib/utils/utils";

export type State = Partial<{
  ARInvoiceGroupSequence: Partial<
    {
      sequenceIndex?: "START" | NextIndexType | null;
      searchListAbort: AbortController | null;
    } & AllFilterType &
      AllSearchType
  > | null;
  // CommonInterface
  coveragePayerOptions: OptionType[];
}>;

type PickedState = Partial<
  Pick<
    MainState,
    | "billReportPrint"
    | "buttonLoadCheck"
    | "commonBillTransaction"
    | "errorMessage"
    | "invoiceItemByItems"
    | "invoiceItemByModes"
    | "invoiceItemByOrders"
    | "masterOptions"
    | "searchedItemListWithKey"
    | "successMessage"
  >
>;

export type PickedProps = Partial<Omit<PickedState, "masterOptions">>;

export type AllFilterType = {
  filterInvIssued: Partial<FilterInvIssued>;
  filterIssueInv: Partial<FilterIssueInv>;
};

export type FilterAllKeys = ProcessedLeaves<AllFilterType>;
export type FilterAllValues = RetTypeAtPath<FilterAllKeys, AllFilterType>;

export type AllSearchType = {
  invIssuedBillTransaction: BillTransactionType;
  issueInvBillTransaction: BillTransactionType;
};

export type BillTransactionType = {
  items: BillTransactionListSerializer[];
  summary: Partial<Record<string, number | string>>;
};

export type FilterIssueInv = {
  coveragePayer?: number | string | null;
  isNoInvoice?: boolean;
  payer?: number | string;
} & FilterInvoice;

export type FilterInvIssued = {
  arTransaction: Record<string, any> | null;
} & FilterInvoice;

type FilterInvoice = {
  encounterId: number | string;
  endDate?: string;
  isDate?: boolean;
  isEncounter?: boolean;
  isPatient?: boolean;
  patientId: number;
  startDate?: string;
};

export type BillTransactionListSerializer = {
  sent_claim_coverage_code: string;
  sent_claim_coverage_name: string;
  sent_claim_payer_code: string;
  sent_claim_payer_name: string;
} & BillTransactionIPDListSerializerI &
  BillTransactionListSerializerI;

type GroupType = "IPD" | "OPD";

export type MasterOptionsType = Record<(typeof Masters)[number][0], OptionType[]>;

export type OptionType = {
  key: number | string;
  original?: Record<string, any>;
  text: string;
  value: number | string;
};

// Sequence
type NextIndexType = "InvIssued" | "IssueInv";

type SeqState = {
  sequence: "ARInvoiceGroup";
  clear?: boolean;
  nextIndex?: NextIndexType;
  noInit?: boolean;
  restart?: boolean;
};

// Handle Action
type ActionType =
  // Search
  | {
      action: "create_ar";
      btnAction: string;
      callback?: () => any;
      coveragePayerId: FilterIssueInv["coveragePayer"];
      date: string;
    }
  // Action
  | {
      action: "create_ar_transaction";
      card: string;
      filterKey?: keyof AllFilterType;
      stateKey?: keyof AllSearchType;
    }
  | {
      action: "search";
      filterKey?: keyof AllFilterType;
      stateKey?: keyof AllSearchType;
    }
  // Method
  | { action: "download_excel" | "print_report"; card: string }
  | { action: "init" };

type SeqAct = ActionType & SeqState;
type SeqType<K> = K extends { action: string } ? Extract<SeqAct, K> : SeqState;

export type RunSequence = <K extends keyof SeqAct>(params: SeqType<Pick<SeqAct, K>>) => void;

export type SetProp = SetProperty<PickedState & State>;

type CustomExtract<T, U> = T extends object ? (U extends Partial<T> ? T : never) : never;

type Params<A extends ActionType["action"]> = CustomExtract<ActionType, { action: A }>;

export const StateInitial: State = {
  ARInvoiceGroupSequence: {
    sequenceIndex: null,
  },
  // sequence
  coveragePayerOptions: [],
};

export type Event =
  | { message: "GetMasterData"; params: Record<string, unknown> }
  | { message: "RunSequence"; params: Record<string, unknown> };

export type Data = {
  division?: number;
  masterData?: Record<string, any>;
};

export const DataInitial = {};

const Masters = [
  ["coverage", {}],
  ["payer", {}],
] as const;

export const ACTIONS = {
  INIT: "init",
  CREATE_AR: "create_ar",
  CREATE_AR_TRANSACTION: "create_ar_transaction",
  DOWNLOAD_EXCEL: "download_excel",
  PRINT_REPORT: "print_report",
  SEARCH: "search",
} as const;

export const CARD_CREATE_INV_GROUP = "CardCreateInvoiceGroup";

type Optional<T> = {
  [K in keyof T]: `${typeof CARD_CREATE_INV_GROUP}_${T[K] & string}`;
};

export const BTN_ACTS = Object.fromEntries(
  Object.entries(ACTIONS).map(([key, value]) => [key, `${CARD_CREATE_INV_GROUP}_${value}`])
) as Optional<typeof ACTIONS>;

const DATE_FORMAT = "YYYY-MM-DD";

// OPD = 1, IPD = 2
const AR_INVOICE_API = {
  IPD: {
    generate: DoGenerateSentClaimDataIPDFromGroupAPIView.get,
    list: BillTransactionIPDList.list,
    summary: BillTransactionIPDSummary.list,
  },
  OPD: {
    generate: DoGenerateSentClaimDataHISFromGroupAPIView.get,
    list: BillTransactionList.list,
    summary: BillTransactionSummaryList.get,
  },
};

type Handler<P = any, R = any> = (
  controller: WasmController<PickedState & State, Event, Data>,
  params: P
) => R;

export const Start: Handler<SeqState> = async (controller, params) => {
  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: Masters,
    },
  });

  const [result, error] = await CoveragePayerSentClaimGroupList.list({
    apiToken: controller.apiToken,
    params: {},
  });

  if (error) {
    SetErrorMessage(controller, { ...params, error });

    return;
  }

  const items: any[] = result?.items || [];

  const coveragePayerOptions = items.map((item: Record<string, any>) => ({
    key: item.id,
    original: item,
    text: `[${item.code}] ${item.name}`,
    value: item.id,
  }));

  controller.setState({ coveragePayerOptions });

  Next(controller, params);
};

/* ------------------------------------------------------ */

/*                          Next                          */

/* ------------------------------------------------------ */
const Next: Handler<SeqState> = (controller, params) => {
  const state = controller.getState();

  const { nextIndex, noInit } = params;

  if (!nextIndex) {
    return;
  }

  state.ARInvoiceGroupSequence?.searchListAbort?.abort();

  controller.setState(
    {
      ARInvoiceGroupSequence: { ...state.ARInvoiceGroupSequence, sequenceIndex: nextIndex },
    },
    () => {
      const init = {
        InvIssued,
        IssueInv,
      }[nextIndex];

      if (!noInit) {
        init(controller, { ...params, action: ACTIONS.INIT, nextIndex: undefined });
      }
    }
  );
};

/* ------------------------------------------------------ */

/*                      Handle Action                     */

/* ------------------------------------------------------ */
export const IssueInv: Handler<ActionType & Pick<SeqState, "nextIndex" | "sequence">> = async (
  controller,
  params
) => {
  if (params.nextIndex) {
    return Next(controller, params);
  }

  const actionHandlers: Partial<{ [K in ActionType["action"]]: Handler<Params<K>> }> = {
    [ACTIONS.INIT]: HandleInitIssueInv,
    [ACTIONS.CREATE_AR]: HandleCreateAr,
    [ACTIONS.CREATE_AR_TRANSACTION]: HandleCreateArTransaction,
    [ACTIONS.SEARCH]: HandleSearchBillTransaction,
  };

  const { action } = params;

  return actionHandlers[action]?.(controller, params as Params<typeof params.action>);
};

export const InvIssued: Handler<ActionType & Pick<SeqState, "nextIndex" | "sequence">> = async (
  controller,
  params
) => {
  if (params.nextIndex) {
    return Next(controller, params);
  }

  const actionHandlers: Partial<{ [K in ActionType["action"]]: Handler<Params<K>> }> = {
    [ACTIONS.INIT]: HandleInitInvIssued,
    [ACTIONS.DOWNLOAD_EXCEL]: HandleDownloadExcel,
    [ACTIONS.PRINT_REPORT]: HandlePrintReport,
    [ACTIONS.SEARCH]: HandleSearchBillTransaction,
  };

  const { action } = params;

  return actionHandlers[action]?.(controller, params as Params<typeof params.action>);
};

/* ------------------------------------------------------ */

/*                     Handle IssueInv                    */

/* ------------------------------------------------------ */
const HandleInitIssueInv: Handler<Params<"init">> = (controller) => {
  const state = controller.getState();

  const seq = state.ARInvoiceGroupSequence;
  const filter = { ...seq?.filterIssueInv };

  controller.setState(
    {
      ARInvoiceGroupSequence: { ...seq, filterIssueInv: filter },
    },
    () =>
      HandleSearchBillTransaction(controller, {
        action: ACTIONS.SEARCH,
        filterKey: "filterIssueInv",
        stateKey: "issueInvBillTransaction",
      })
  );
};

const HandleSearchBillTransaction: Handler<Params<"search">> = async (controller, params) => {
  let state = controller.getState();

  const { filterKey, stateKey } = params;

  if (!(filterKey && stateKey)) {
    return;
  }

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SEARCH]: "LOADING" },
  });

  const result = await GetBillTransactionList(controller, { ...params, filterKey });

  if (result.message) {
    return;
  }

  state = controller.getState();

  controller.setState({
    ARInvoiceGroupSequence: {
      ...state.ARInvoiceGroupSequence,
      [stateKey]: result.data,
    },
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SEARCH]: "SUCCESS" },
  });
};

const HandleCreateAr: Handler<Params<"create_ar">> = async (controller, params) => {
  const state = controller.getState();

  const coveragePayer = state.coveragePayerOptions?.find(
    (option) => option.value === params.coveragePayerId
  )?.original;

  if (!(params.coveragePayerId && params.date && coveragePayer)) {
    SetErrorMessage(controller, {
      ...params,
      error: [!params.coveragePayerId && "ระบุสิทธิ", !params.date && "ระบุวันที่"].filter(Boolean),
      errorKey: params.btnAction,
    });

    return;
  }

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [params.btnAction]: "LOADING" },
  });

  const groupType = coveragePayer.group_type as GroupType;
  const api = AR_INVOICE_API[groupType];

  const [, error] = await api.generate({
    apiToken: controller.apiToken,
    coverage_payer_sent_claim_group_id: params.coveragePayerId,
    end_date: beToAd(params.date)?.format(DATE_FORMAT),
    start_date: beToAd(params.date)?.format(DATE_FORMAT),
  });

  params.callback?.();

  if (error) {
    SetErrorMessage(controller, {
      ...params,
      btnError: "",
      error: "error_occurs",
      errorKey: params.btnAction,
    });
  } else {
    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [params.btnAction]: null },
      successMessage: {
        ...state.successMessage,
        [params.btnAction]: "create_successful",
      },
    });
  }
};

const HandleCreateArTransaction: Handler<Params<"create_ar_transaction">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.CREATE_AR_TRANSACTION]: "LOADING" },
  });

  const filter = state.ARInvoiceGroupSequence?.filterIssueInv || {};

  const [result, error] = await CreateARTransactionAPIView.post({
    apiToken: controller.apiToken,
    data: {
      coverage_payer_sent_claim_group: filter.coveragePayer,
      end_date: filter.endDate ? beToAd(filter.endDate)?.format(DATE_FORMAT) : undefined,
      start_date: filter.startDate ? beToAd(filter.startDate)?.format(DATE_FORMAT) : undefined,
    },
  });

  if (error) {
    SetErrorMessage(controller, { ...params, error });
  } else {
    controller.setState(
      {
        buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.CREATE_AR_TRANSACTION]: "SUCCESS" },
        successMessage: {
          ...state.successMessage,
          [BTN_ACTS.CREATE_AR_TRANSACTION]: result?.lot_no || "",
        },
      },
      () =>
        HandleSearchBillTransaction(controller, {
          ...params,
          action: ACTIONS.SEARCH,
        })
    );
  }
};

/* ------------------------------------------------------ */

/*                    Handle InvIssued                    */

/* ------------------------------------------------------ */
const HandleInitInvIssued: Handler<Params<"init">> = (controller) => {
  const state = controller.getState();

  const seq = state.ARInvoiceGroupSequence;
  const filter = { ...seq?.filterInvIssued };

  controller.setState({
    ARInvoiceGroupSequence: { ...seq, filterInvIssued: filter },
  });
};

const HandleDownloadExcel: Handler<Params<"download_excel">> = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.DOWNLOAD_EXCEL]: "LOADING" },
  });

  const filter = state.ARInvoiceGroupSequence?.filterInvIssued || {};
  const arTransaction = filter.arTransaction || {};

  const urlParams = {
    ar_transaction: arTransaction.id,
    export_excel: true,
  };

  const [result, error, net] = await SendClaimCoverageDocumentReport.get({
    apiToken: controller.apiToken,
    params: urlParams,
    extra: { responseType: "arraybuffer" },
  });

  if (error || !result) {
    SetErrorMessage(controller, { ...params, btnError: "", error });
  } else {
    downloadFile(net);

    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.DOWNLOAD_EXCEL]: "SUCCESS" },
    });
  }
};

const HandlePrintReport: Handler<Params<"print_report">> = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.PRINT_REPORT]: "LOADING" },
  });

  const filter = state.ARInvoiceGroupSequence?.filterInvIssued || {};
  const arTransaction = filter.arTransaction || {};

  const [result, error] = await SendClaimCoverageDocumentReport.get({
    apiToken: controller.apiToken,
    params: {
      ar_transaction: arTransaction.id,
    },
  });

  if (error || !result) {
    SetErrorMessage(controller, { ...params, btnError: "", error });

    return;
  }

  const docDef = FormMedicalExpenseInvoice({ ...result.params, items: result.fields });

  const pdfMake = await getPdfMake(true);

  const pdf = pdfMake.createPdf(docDef);

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.PRINT_REPORT]: "SUCCESS" },
  });

  pdf.open();
};

/* ------------------------------------------------------ */

/*                          APIs                          */

/* ------------------------------------------------------ */

const GetBillTransactionList: Handler<
  { filterKey: keyof AllFilterType },
  Promise<{ data: BillTransactionType; message: string }>
> = async (controller, params) => {
  const state = controller.getState();

  const seq = state.ARInvoiceGroupSequence;
  const defaultData = { items: [], summary: {} };
  const abortCtrl = new AbortController();

  const filter = seq?.[params.filterKey] || {};

  const arTransaction = "arTransaction" in filter ? filter.arTransaction : undefined;
  const coveragePayerId = "coveragePayer" in filter ? filter.coveragePayer : undefined;
  const payerId = "payer" in filter ? filter.payer : undefined;

  const coveragePayerSentClaimGroup =
    coveragePayerId || arTransaction?.coverage_payer_sent_claim_group;

  const coveragePayer = state.coveragePayerOptions?.find(
    (option) => option.value === coveragePayerSentClaimGroup
  )?.original;

  const shouldFilterInvIssued = params.filterKey === "filterInvIssued" && !arTransaction?.id;
  const shouldFilterIssueInv = params.filterKey === "filterIssueInv" && !coveragePayerId;

  if (shouldFilterInvIssued || shouldFilterIssueInv || !coveragePayer) {
    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SEARCH]: null },
    });

    return { data: defaultData, message: "required" };
  }

  controller.setState({
    ARInvoiceGroupSequence: { ...seq, searchListAbort: abortCtrl },
  });

  const formatParams = (isKey: keyof FilterInvoice, value: any) =>
    filter[isKey] ? value : undefined;

  const urlParams = {
    ar_transaction: arTransaction?.id,
    coverage_payer_sent_claim_group: coveragePayerId,
    encounter: formatParams("isEncounter", filter.encounterId),
    end_date: formatParams("isDate", filter.endDate),
    patient: formatParams("isPatient", filter.patientId),
    payer_id: payerId,
    start_date: formatParams("isDate", filter.startDate),
  };

  const groupType = coveragePayer.group_type as GroupType;

  const api = AR_INVOICE_API[groupType];

  const [list, sum] = await Promise.all([
    api.list({
      apiToken: controller.apiToken,
      params: urlParams,
      extra: {
        signal: abortCtrl.signal,
      },
    }),
    api.summary({
      apiToken: controller.apiToken,
      params: urlParams,
      extra: {},
    }),
  ]);

  if (list[2].message === "canceled") {
    return { data: defaultData, message: list[2].message };
  }

  return {
    data: { items: list[0]?.items || [], summary: sum[0]?.items || sum[0] || {} },
    message: "",
  };
};
