import React, { useEffect, useRef, useState } from "react";
// UX
import CardDefaultCertificateUX from "./CardDefaultCertificateUX";
// Common

// Interface
import { State } from "./sequence/DoctorCertificate";
// Utils
import moment from "moment";
import { Dimmer, Loader, Form, FormGroup } from "semantic-ui-react";
import { useIntl } from "react-intl";

// Main Props
type CardDefaultCertificateProps = {
  // Function
  setProp: any;

  // Options
  masterOptions?: any;

  // Sequence
  runSequence?: any;
  DoctorCertificateSequence: State["DoctorCertificateSequence"];
};

const CardDefaultCertificate = (props: CardDefaultCertificateProps) => {
  const intl = useIntl();
  // Call Sequence ( GET_REPORT Default )
  useEffect(() => {
    props.runSequence({
      sequence: "DoctorCertificate",
      action: "GET_REPORT",
      codeForm: "default",
    });
  }, []);

  // set State
  // const [isLoading, setIsLoading] = useState(false);

  // 5 Second Loading to Preparing State
  // useEffect(() => {
  //   setIsLoading(true);

  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 2000);
  // }, []);

  // Doctor Check
  const handleIsDoctorCheck = (isDoctor: any) => {
    if (isDoctor) {
      return (
        <>
          <FormGroup
            className="ui form"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "20em 0em 0em 0em",
            }}
          >
            <label
              style={{ fontSize: "18px", color: "grey", textAlign: "center" }}
            >{intl.formatMessage({ id: "กรุณาเลือกประเภทเอกสารที่ต้องการบันทึก" })}</label>
          </FormGroup>
          <FormGroup
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "0em 0em 0em 0em",
            }}
          >
            <label
              style={{ fontSize: "18px", color: "grey", textAlign: "center" }}
            >{intl.formatMessage({ id: "หรือเลือกรายการที่จะดูหรือสั่งพิมพ์จากตารางด้านขวา" })}</label>
          </FormGroup>
        </>
      );
    } else {
      return (
        <>
          <FormGroup
            className="ui form"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "20em 0em 0em 0em",
            }}
          >
            <label
              style={{ fontSize: "18px", color: "grey", textAlign: "center" }}
            >{intl.formatMessage({ id: "กรุณาเลือกรายการที่ต้องการดูหรือสั่งพิมพ์" })}</label>
          </FormGroup>
          <FormGroup
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "0em 0em 0em 0em",
            }}
          >
            <label
              style={{ fontSize: "18px", color: "grey", textAlign: "center" }}
            >{intl.formatMessage({ id: "จากตารางด้านขวา" })}</label>
          </FormGroup>
        </>
      );
    }
  };

  return (
    <>
      <CardDefaultCertificateUX
        // Start Text Check by role
        onStartText={handleIsDoctorCheck(
          props.DoctorCertificateSequence?.isDoctor
        )}
        languageUX={props.languageUX}
      />
      {/* <Dimmer active={isLoading} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer> */}
    </>
  );
};

export default CardDefaultCertificate;
