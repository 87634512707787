import WasmController from "react-lib/frameworks/WasmController";
//apis
import DrugTransferRequestList from "issara-sdk/apis/DrugTransferRequestList_apps_TPD";


export type State = {
  errorMessage?: any;
  successMessage?: any;

  DrugTransferRequestHistorySequence?: {
    sequenceIndex?: string | null;
    selectedDivisionRequest?: any | null;
    selectedDivisionProvider?: any | null;
    selectedStartDate?: string | null;
    selectedEndDate?: string | null;
    selectedCode?: string | null;
    checkedCode?: boolean | null;
    checkedDivisionProvider?: boolean | null;
    checkedDivisionRequest?: boolean | null;
    checkedFilterDate?: boolean | null;
    
    
  } | null;
  DrugTransferRequestHistoryList ?: any 
};

export const StateInitial: State = {
    DrugTransferRequestHistorySequence: null,
    DrugTransferRequestHistoryList: []
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: any };

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const Start: Handler = async (controller, params) => {
  var state = controller.getState();
  if (!state.DrugTransferRequestHistorySequence) return;

  // Master data
  await controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [["divisionPharma", {}]],
    },
  });

  state = controller.getState();

  controller.setState({
    DrugTransferRequestHistorySequence: {
      ...state.DrugTransferRequestHistorySequence,
      sequenceIndex: "SearchDrugTransferRequestHistory",
    },
  });
};

export const SearchDrugTransferRequestHistory: Handler = async (controller,params) => {
    const state = controller.getState(); 
    if(params?.action === "search"){
        const [response, error ,network] = await DrugTransferRequestList.list({
            params: {
                 code: state?.DrugTransferRequestHistorySequence?.checkedCode ? state?.DrugTransferRequestHistorySequence?.selectedCode : null,
                 from_date: state?.DrugTransferRequestHistorySequence?.checkedFilterDate ? state?.DrugTransferRequestHistorySequence?.selectedStartDate : null,
                 to_date: state?.DrugTransferRequestHistorySequence?.checkedFilterDate ? state?.DrugTransferRequestHistorySequence?.selectedEndDate : null,
                 requester: state?.DrugTransferRequestHistorySequence?.checkedDivisionRequest ? state?.DrugTransferRequestHistorySequence?.selectedDivisionRequest : null,
                 provider: state?.DrugTransferRequestHistorySequence?.checkedDivisionProvider ? state?.DrugTransferRequestHistorySequence?.selectedDivisionProvider : null,
                 sort:  "code",
                 offset: 0,
                 limit: 40,
            
            },
            apiToken: controller.apiToken,
            extra: {
              division: controller.data.division,
            },
          });

        controller.setState({
            DrugTransferRequestHistorySequence:{
                ...state.DrugTransferRequestHistorySequence
            },
            DrugTransferRequestHistoryList:response
        })   
    }
    else if (params?.action === "clear"){
        controller.setState({
            DrugTransferRequestHistorySequence:{
                ...state.DrugTransferRequestHistorySequence,
                selectedCode:null,
                selectedDivisionProvider:null,
                selectedDivisionRequest:null,
                selectedEndDate:null,
                selectedStartDate:null,
                checkedCode:false,
                checkedDivisionProvider:false,
                checkedDivisionRequest:false,
                checkedFilterDate:false
            },
            DrugTransferRequestHistoryList:{...state.DrugTransferRequestHistoryList}

        })

    }

};
