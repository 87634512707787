import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  MutableRefObject,
  SyntheticEvent,
} from "react";
import { Modal, Icon, Button, Input, InputProps } from "semantic-ui-react";
// Mui
import { Chip } from "@mui/material";

import moment from "moment";

// Common
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import TimeComboBox from "react-lib/apps/common/TimeComboBox";
import DateTextBox from "react-lib/apps/common/DateTextBox";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import PasswordRecheck from "react-lib/apps/common/PasswordRecheck";

// Utils
import { formatDate } from "../../../utils/dateUtils";
import { useIntl } from "react-intl";

type ModAttachFileProps = {
  setProp: (key: string, value: any) => any;
  // data
  open: boolean;
  labOrderItems: any[];
  errorMessage?: any;
  buttonLoadCheck?: any;
  labReportToken: Partial<Record<"error" | "loading" | "employeeName" | "password", any>>;
  // callback
  onRemoveItem: (index: number) => any;
  onEnterToken: (code: string) => any;
  onClose: () => any;
  onSave: (data: {
    upload: any[];
    date: string;
    time: string;
    labOrderItems: any[];
    remove: any[];
    password: string;
  }) => any;
};

type FileType = {
  file?: FileReader;
  name: string;
  label: string;
  active?: boolean;
  id?: number;
  ids?: number[];
};

const MOD_ATTACH_FILE = "ModAttachfile";

const ModAttachFile = (props: ModAttachFileProps) => {
  const intl = useIntl();
  const [date, setDate] = useState<string>("");
  const [time, setTime] = useState<string>("");
  const [files, setFiles] = useState<FileType[]>([]);
  const [error, setError] = useState<any>([]);
  const [disabledSelectFile, setDisabledSelectFile] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");

  const fileRef = useRef() as MutableRefObject<HTMLInputElement>;

  useEffect(() => {
    if (props.open) {
      setDate(formatDate(moment()));
      setTime(moment().format("HH:mm"));
      setPassword("");
    }
  }, [props.open]);

  // lab items ทั้งหมด
  const allLabItems = useMemo(() => {
    return (props.labOrderItems || []).flatMap((item: any) =>
      item.children.length ? item.children : [item]
    );
  }, [props.open, props.labOrderItems]);

  // รายการที่มีการ checkbox
  const filterLabItems = useMemo(() => {
    return allLabItems.flatMap((item) =>
      item.chk
        ? [
            {
              ...item,
              summary_file_result: item.summary_file_result.filter(
                (acc: any) => acc.active
              ),
            },
          ]
        : []
    );
  }, [allLabItems]);

  useEffect(() => {
    if (props.open) {
      setFiles([]);
      setDisabledSelectFile(false);
      setPassword(props.labReportToken?.password);

      // get ชื่อไฟล์จาก url
      const getName = (summaryFile: string) => {
        const reg = /lab_summary_document\/(.*?\.pdf)/g;
        const name = reg.exec(summaryFile)?.[1];

        return name;
      };

      // get label by ref_order_items
      const getLabel = (orderItems: number[], items: any[]) => {
        const nameList = orderItems
          .map(
            (id) =>
              items.find((acc) => acc.central_lab_order_item_id === id)?.name
          )
          .filter(Boolean)
          .join(" & ");

        return nameList;
      };

      const getArrayUniqueByKey = (fileResult: any[]) => {
        const map: Map<string, number> = new Map();

        return fileResult.reduce((result, item, index) => {
          if (!map.has(item.name)) {
            map.set(item.name, index);
            result.push({ ...item, ids: [item.id] });
          } else {
            result[map.get(item.name) as number].ids.push(item.id);
          }

          return result;
        }, [] as any[]);
      };

      // กรณีมีรายการเดียว
      if (filterLabItems.length === 1) {
        const fileResult = filterLabItems[0].summary_file_result.map(
          (item: any) => {
            return {
              name: getName(item.summary_file),
              label: getLabel(item.ref_order_items, allLabItems),
              active: true,
              id: item.id,
            };
          }
        );

        setFiles(getArrayUniqueByKey(fileResult));
      } else if (filterLabItems.length >= 2) {
        const isCompare = filterLabItems.every((item, index) => {
          return filterLabItems.length - 1 === index
            ? true
            : // ดูว่าใช้ไฟล์ร่วมกันทั้งหมดหรือไม่
              item.summary_file_result.every(
                (acc: any, idx: number) =>
                  acc.ref_order_items?.toString() ===
                  filterLabItems[index + 1]?.summary_file_result?.[
                    idx
                  ]?.ref_order_items?.toString()
              );
        });

        // กรณีเป็น lab ที่ไม่ได้ใช้ไฟล์ร่วมกัน
        if (!isCompare) {
          setDisabledSelectFile(true);
        }

        const arrayFlat = filterLabItems.flatMap(
          (item) => item.summary_file_result
        );

        const fileResult = arrayFlat.map((item: any) => {
          return {
            name: getName(item.summary_file) || "",
            label: getLabel(item.ref_order_items, allLabItems),
            active: true,
            id: item.id,
          };
        });

        setFiles(getArrayUniqueByKey(fileResult));
      }
      console.log("filterLabItems", filterLabItems);
    }
  }, [filterLabItems, props.open]);

  const allowedSave = useMemo(() => {
    const upload = files
      .filter((item) => item.active && item.file)
      .map((item) => item.file);

    const remove = files
      .filter((item) => !item.active && item.ids)
      .flatMap((item) => item.ids);

    return files.length && (upload.length || remove.length);
  }, [files]);

  const handleRemoveItem = (index: number) => (e: any) => {
    props.onRemoveItem?.(index);
  };

  const handleFileEvent = (event: any) => {
    const chosenFiles = Array.prototype.slice.call(event.target.files);

    handleUploadFiles(chosenFiles);

    fileRef.current.value = "";
  };

  const handleUploadFiles = async (file: File[]) => {
    const names = files.map((item) => item.name);
    const label = filterLabItems.map((item) => item.name).join(" & ");
    const filterFiles = file.flatMap((item) => {
      console.log(names, item.name);
      if (names.includes(item.name)) {
        return [];
      } else {
        let fileReader = new FileReader();
        fileReader.readAsDataURL(item);
        return [{ name: item.name, file: fileReader, label, active: true }];
      }
    });

    // const filterFiles = await Promise.all(promiseArr);

    setFiles([...files, ...filterFiles]);
  };

  // Import File
  const handleClickUpload = () => {
    fileRef.current?.click?.();
  };

  const handleOnAddFile = () => {
    let textAlert: any[] = [];

    // if (!files?.length) {
    //   textAlert.push("กรุณาระบุไฟล์");
    // }
    if (!date) {
      textAlert.push("กรุณาระบุวันที่รายงานผล");
    }
    if (!time) {
      textAlert.push("กรุณาระบุเวลารายงานผล");
    }
    if (!password) {
      textAlert.push("กรุณาระบุรหัสผ่านให้ถูกต้อง");
    }

    setError(textAlert);

    if (textAlert.length === 0) {
      props.onSave({
        upload: files
          .filter((item) => item.active && item.file)
          .map((item) => item.file),
        remove: files
          .filter((item) => !item.active && item.ids)
          .flatMap((item) => item.ids),
        date: date,
        time: time,
        labOrderItems: props.labOrderItems,
        password: password,
      });

      setPassword("");
    }
  };

  const handleClose = () => {
    setFiles([]);
    props.setProp(`errorMessage.${MOD_ATTACH_FILE}`, null);
    setError(null);
    setDate("");
    setTime("");
    setPassword("");
    props.setProp("LabReportSequence.LabReportToken", {});

    props.onClose();
  };

  const handleClearToken = () => {
    props.setProp("LabReportSequence.LabReportToken", {
      ...props.labReportToken,
      token: "",
      employeeName: "",
      loading: false,
      error: null,
    });
  };

  const handleDelete = (item: FileType) => (e: any) => {
    const itemsFile = [...files];
    let filterFile = [];

    if (item.file) {
      filterFile = itemsFile.filter((acc) => acc.name !== item?.name);
    } else {
      filterFile = itemsFile.map((acc) =>
        acc.name === item?.name ? { ...acc, active: false } : acc
      );
    }

    setFiles(filterFile);
  };

  //* issue 70592
  // const handleChangePassword = (e: SyntheticEvent, data: InputProps) => {
  //   props.setProp("LabReportSequence.LabReportToken.password", data.value);
  // };

  // console.log(props);

  return (
    <>
      <SnackMessage
        onClose={() => {
          setError(null);
          props.setProp(`errorMessage.${MOD_ATTACH_FILE}.error`, null);
        }}
        error={error?.length > 0 ? error : props.errorMessage?.[MOD_ATTACH_FILE]?.error}
        success={null}
        languageUX={props.languageUX}
      />

      <Modal className="centered" open={props.open} closeIcon onClose={handleClose}>
        <div style={{ padding: "10px" }}>
          <div style={{ paddingBottom: "10px" }}>
            <div style={{ fontSize: "1.2rem", fontWeight: "bold" }}>Lab Results</div>
            <hr></hr>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingBottom: "20px",
            }}
          >
            <div style={{ fontWeight: "bold", marginRight: "5px" }}>
              {intl.formatMessage({ id: "วันเวลาที่สั่ง :" })}
            </div>
            <div style={{ marginRight: "20px" }}>{/* {props.selectedLabOrderWorking?.date} */}</div>
            <div style={{ fontWeight: "bold", marginRight: "5px" }}>
              {intl.formatMessage({ id: "วันเวลารายงานผล :" })}
            </div>
            <div style={{ marginRight: "5px" }}>
              <DateTextBox
                onChange={(date: string) => {
                  setDate(date);
                }}
                value={date}
              ></DateTextBox>
            </div>

            <div>
              <TimeComboBox
                noMinWidth={true}
                defaultValue={time || ""}
                onTextChange={(time) => {
                  setTime(time);
                }}
              />
            </div>
          </div>

          <div style={{ fontSize: "1rem", paddingBottom: "10px" }}>
            โปรดแนบไฟล์สำหรับรายงานผลการทดสอบ ดังนี้
          </div>

          <div style={{ width: "50%", maxHeight: "55vh", overflow: "auto" }}>
            {props.labOrderItems?.map((item: any, index: number) => (
              <div style={{}}>
                <div
                  style={{
                    display: item.chk ? "" : "none",
                    borderBottom: "1px solid rgb(29, 189, 212)",
                    width: "100%",
                  }}
                />
                <div style={{ display: "flex" }}>
                  <div style={{ padding: item.chk ? "8px 0" : "" }}>
                    {" "}
                    {item.chk ? item.name : ""}{" "}
                  </div>
                  <div style={{ flex: "1" }} />

                  <div style={{ display: item.chk ? "" : "none", margin: "auto" }}>
                    <Icon
                      name="close"
                      color="red"
                      style={{ cursor: "pointer" }}
                      onClick={handleRemoveItem(index)}
                    ></Icon>
                  </div>
                </div>
                <div>
                  {item.children?.length > 0 &&
                    item.children?.map((acc: any) => (
                      <div style={{ padding: acc.chk ? "8px 0" : "" }}>
                        {" "}
                        {acc.chk ? acc.name : ""}{" "}
                      </div>
                    ))}
                </div>
              </div>
            ))}
          </div>

          <div style={{ display: "flex", marginTop: "20px" }}>
            <div style={{ marginRight: "10px" }}>
              <input
                ref={fileRef}
                type="file"
                accept=".pdf"
                multiple
                onChange={handleFileEvent}
                hidden
              />

              <Button
                className="basic"
                color="blue"
                disabled={disabledSelectFile}
                style={{ width: "110px" }}
                onClick={handleClickUpload}
              >
                Select file...
              </Button>
            </div>

            <div style={{ display: "grid", rowGap: "10px" }}>
              {files.map(
                (item) =>
                  item.active && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Chip
                        label={item?.name || ""}
                        icon={
                          <Icon
                            name="file pdf outline"
                            color="blue"
                            style={{
                              fontSize: "1.5rem",
                              margin: "0 0em 0 1rem",
                            }}
                          />
                        }
                        variant="outlined"
                        style={{ height: "36px" }}
                        disabled={filterLabItems.length > 1}
                        onDelete={handleDelete(item)}
                      />

                      <div style={{ marginLeft: "3rem" }}>
                        for{" "}
                        <strong>
                          <i>{item.label}</i>
                        </strong>
                      </div>
                    </div>
                  )
              )}
            </div>
          </div>

          <div style={{ paddingTop: "10px" }}>
            {/* <ErrorMessage
            error={error?.length > 0 ? error : props.errorMessage?.[MOD_ATTACH_FILE]?.error}
          /> */}
          </div>
          <div
            style={{
              display: "flex",
              paddingTop: "20px",
              alignItems: "center",
            }}
          >
            <div
              style={{
                fontSize: "1rem",
                fontWeight: "bold",
                marginRight: "15px",
              }}
            >
              ผู้แนบไฟล์
            </div>

            <div style={{ marginRight: "15px" }}>
              {/* <EmployeeToken
              onEnterToken={props.onEnterToken}
              onClearToken={handleClearToken}
              error={props.labReportToken?.error}
              loading={props.labReportToken?.loading}
              employeeName={props.labReportToken?.employeeName}
            /> */}
              {/* <Input
                placeholder={intl.formatMessage({ id: "ระบุรหัสผ่าน" })}
                type="password"
                value={props.labReportToken?.password}
                onChange={handleChangePassword}
              /> */}
              <PasswordRecheck
                password={password}
                setPassword={setPassword}
                placeholder={intl.formatMessage({ id: "ระบุรหัสผ่าน" })}
              />
            </div>

            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              disabled={!allowedSave}
              onClick={handleOnAddFile}
              // data
              paramKey={`${MOD_ATTACH_FILE}_SAVE`}
              buttonLoadCheck={props.buttonLoadCheck?.[`${MOD_ATTACH_FILE}_SAVE`]}
              // config
              size="small"
              color="green"
              name="SAVE"
              title="Save"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default React.memo(ModAttachFile);
