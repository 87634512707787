import React, { CSSProperties, useState, useMemo, useEffect } from "react";
// CSS
import {
  Button,
  Icon,
  Input,
  InputProps,
  Form,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

// @ts-ignore
import Cookies from "js-cookie";
import moment from "moment";

// Interface
import {
  Event as MobEvent,
  State as MobState,
  StateInitial as MobStateInitial,
} from "../../../../patient-lib/MobTransformInterface";

// Common
import DateTextBox from "react-lib/apps/common/DateTextBox";

// UI
import TFHead from "./TFHead";
import { ModCheckEmail } from "./TFSignInEmail";
import ModMessage from "./ModMessage";
import { useIntl } from "react-intl";

type TransformRegisterProps = {
  onEvent: (e: MobEvent) => any;
  history?: any;
  onHaveApiToken?: (history: any) => any;
} & Pick<
  MobState,
  | "registerInfo"
  | "viewIndexRegister"
  | "firstRegistration"
  | "loadingMainTransform"
  | "successMessage"
  | "errorMessage"
  | "modMessageType"
  | "setDevice"
>;

type SpecifyUsernameProps = {
  onSubmit?: () => any;
  autoFocus?: boolean;
  height?: string;
} & Pick<
  TransformRegisterProps,
  "registerInfo" | "onEvent" | "viewIndexRegister"
>;

type InputErrorMessageProps = {
  style?: CSSProperties;
} & Pick<TransformRegisterProps, "registerInfo" | "viewIndexRegister">;

const COLOR = {
  primary: "var(--primary-theme-color)",
  secondary: "#F2C94C",
  violet_blue: "#2D247F",
  font_white: "white",
  orange_light: "#EB5757",
};

const ICON = {
  pencil: "/images/tuh/pencil-icon.png",
};

export const DATE_FORMAT = "DD/MM/YYYY";

const styles = {
  box_title: {
    textAlign: "center",
    fontSize: "20px",
    color: COLOR.violet_blue,
    fontWeight: "bold",
    marginTop: "12%",
    marginBottom: "11%",
  } as CSSProperties,
  label: {
    color: COLOR.primary,
    fontSize: "16px",
    marginBottom: "10px",
    textAlign: "left",
    fontWeight: "bold",
  } as CSSProperties,
  description: {
    fontSize: "16px",
    textAlign: "center",
    color: COLOR.primary,
    lineHeight: "24px",
  } as CSSProperties,
};

export const formatDate = (date: moment.MomentInput) => {
  return moment(date, "DD/MM/YYYY").clone().locale("en").format("DD MMMM YYYY");
};

export const PencilIcon: React.FunctionComponent<any> = (props) => {
  return (
    <div
      onClick={props.onClick}
      style={{
        position: "absolute",
        height: "100%",
        alignItems: "center",
        display: "flex",
        right: "13px",
      }}
    >
      <img src={ICON.pencil} alt="pencil.icon" style={{ width: "18px" }} />
    </div>
  );
};

export const DisplayTitle: React.FunctionComponent<any> = (props) => {
  return (
    <>
      {props.title && (
        <div style={{ ...styles.box_title, ...(props.boxStyle || {}) }}>
          {props.title}
        </div>
      )}
      {props.description && (
        <div style={{ marginBottom: "45px", ...(props.style || {}) }}>
          {props.description?.map((item: any, index: number) => (
            <div
              key={"title-" + index}
              style={{ ...styles.description, ...(props.descriptStyle || {}) }}
            >
              {item}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

const InputErrorMessage: React.FunctionComponent<InputErrorMessageProps> = (
  props
) => {
  const intl = useIntl();
  return (
    <>
      {props.registerInfo?.error?.message &&
        props.viewIndexRegister === props.registerInfo.error.viewIndex && (
          <div
            style={{
              textAlign: "right",
              marginTop: "5px",
              color: COLOR.orange_light,
              ...(props.style || {}),
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: props.registerInfo?.error?.message || "",
              }}
            />
          </div>
        )}
    </>
  );
};

const SpecifyUsername = React.memo<SpecifyUsernameProps>((props) => {
  return (
    <>
      <div style={{ height: props.height || "63vh" }}>
        <DisplayTitle
          title="ระบุข้อมูลชื่อผู้ใช้งาน"
          description={[
            "กรุณาระบุชื่อผู้ใช้งานที่ประกอบไปด้วย",
            "ตัวอักษรภาษาอังกฤษ ตัวเล็ก ตัวใหญ่",
            "และตัวเลข อย่างน้อย 6 หลัก",
          ]}
        />

        <Form onSubmit={props.onSubmit}>
          <Form.Field error={props.registerInfo?.error?.username || false}>
            <label className="label-required" style={styles.label}>{intl.formatMessage({ id: "ชื่อผู้ใช้งาน" })}</label>
            <Input
              value={props.registerInfo?.username || ""}
              icon
              placeholder=""
              onChange={(e: any, v: InputProps) =>
                props.onEvent({
                  message: "HandleSetValueRegisterInfo",
                  params: { key: "username", value: v.value },
                })
              }
              autoFocus={props.autoFocus}
            />
            {props.registerInfo?.error?.message !== "USERNAME_AVAILABLE" && (
              <InputErrorMessage
                registerInfo={props.registerInfo}
                viewIndexRegister={props.viewIndexRegister}
              />
            )}
          </Form.Field>
        </Form>

        {props.registerInfo?.error?.message === "USERNAME_AVAILABLE" &&
          props.viewIndexRegister === props.registerInfo.error.viewIndex && (
            <DisplayTitle
              style={{ margin: 0, marginTop: "20%" }}
              descriptStyle={{ fontSize: "15px", color: COLOR.orange_light }}
              description={[
                "ชื่อผู้ใช้งานนี้ไม่สามารถใช้งานได้",
                "เนื่องจากถูกใช้งานไปแล้ว",
              ]}
            />
          )}
      </div>
    </>
  );
});

export const SpecifyPassword: React.FunctionComponent<SpecifyUsernameProps> = (
  props
) => {
  return (
    <>
      <div style={{ height: props.height || "63vh" }}>
        <DisplayTitle
          title="กรุณาระบุรหัสผ่าน"
          description={[
            "เป็นตัวอักษรภาษาอังกฤษ",
            "ตัวใหญ่ตัวเล็กและตัวเลข",
            "ความยาวไม่น้อยกว่า 8 ตัวอักษร",
          ]}
        />

        <Form onSubmit={props.onSubmit}>
          <Form.Field error={props.registerInfo?.error?.password || false}>
            <label className="label-required" style={styles.label}>{intl.formatMessage({ id: "รหัสผ่าน" })}</label>
            <Input
              value={props.registerInfo?.password || ""}
              icon={{
                name: !props.registerInfo?.hidePassword ? "eye" : "eye slash",
                link: true,
                onClick: (e: any) =>
                  props.onEvent({
                    message: "HandleSetValueRegisterInfo",
                    params: {
                      key: "hidePassword",
                      value: !props.registerInfo?.hidePassword,
                    },
                  }),
              }}
              placeholder=""
              type={!props.registerInfo?.hidePassword ? "input" : "password"}
              onChange={(e: any, v: InputProps) =>
                props.onEvent({
                  message: "HandleSetValueRegisterInfo",
                  params: { key: "password", value: v.value },
                })
              }
              autoFocus={props.autoFocus}
            />
          </Form.Field>

          <Form.Field
            error={props.registerInfo?.error?.confirmPassword || false}
          >
            <label className="label-required" style={styles.label}>{intl.formatMessage({ id: "ยืนยันรหัสผ่าน" })}</label>
            <Input
              value={props.registerInfo?.confirmPassword || ""}
              icon={{
                name: !props.registerInfo?.hideConfirmPassword
                  ? "eye"
                  : "eye slash",
                link: true,
                onClick: (e: any) =>
                  props.onEvent({
                    message: "HandleSetValueRegisterInfo",
                    params: {
                      key: "hideConfirmPassword",
                      value: !props.registerInfo?.hideConfirmPassword,
                    },
                  }),
              }}
              placeholder=""
              type={
                !props.registerInfo?.hideConfirmPassword ? "input" : "password"
              }
              onChange={(e: any, v: InputProps) =>
                props.onEvent({
                  message: "HandleSetValueRegisterInfo",
                  params: { key: "confirmPassword", value: v.value },
                })
              }
            />
          </Form.Field>

          <input type="submit" hidden />

          <InputErrorMessage
            registerInfo={props.registerInfo}
            viewIndexRegister={props.viewIndexRegister}
            style={{ marginTop: "-10px" }}
          />
        </Form>
      </div>
    </>
  );
};

export const PersonalInformation: React.FunctionComponent<
  SpecifyUsernameProps
> = (props) => {
  return (
    <>
      <div style={{ height: props.height || "63vh" }}>
        <DisplayTitle title="กรุณาระบุข้อมูลให้ครบถ้วน" />

        <Form onSubmit={props.onSubmit}>
          <Form.Field error={props.registerInfo?.error?.firstName || false}>
            <label className="label-required" style={styles.label}>{intl.formatMessage({ id: "ชื่อ" })}</label>
            <Input
              value={props.registerInfo?.firstName || ""}
              icon
              placeholder=""
              onChange={(e: any, v: InputProps) =>
                props.onEvent({
                  message: "HandleSetValueRegisterInfo",
                  params: { key: "firstName", value: v.value },
                })
              }
              autoFocus={props.autoFocus}
            />
          </Form.Field>
          <Form.Field error={props.registerInfo?.error?.lastName || false}>
            <label className="label-required" style={styles.label}>{intl.formatMessage({ id: "นามสกุล" })}</label>
            <Input
              value={props.registerInfo?.lastName || ""}
              icon
              placeholder=""
              onChange={(e: any, v: InputProps) =>
                props.onEvent({
                  message: "HandleSetValueRegisterInfo",
                  params: { key: "lastName", value: v.value },
                })
              }
            />
          </Form.Field>
          <Form.Field error={props.registerInfo?.error?.birthdate || false}>
            <label className="label-required" style={styles.label}>{intl.formatMessage({ id: "วันเกิด" })}</label>
            <DateTextBox
              value={props.registerInfo?.birthdate || ""}
              maxDate={formatDate(moment().format(DATE_FORMAT))}
              onChange={(date: string) =>
                props.onEvent({
                  message: "HandleSetValueRegisterInfo",
                  params: { key: "birthdate", value: date },
                })
              }
            />
          </Form.Field>

          <input type="submit" hidden />

          <InputErrorMessage
            registerInfo={props.registerInfo}
            viewIndexRegister={props.viewIndexRegister}
          />
        </Form>
      </div>
    </>
  );
};

export const SpecifyHN: React.FunctionComponent<SpecifyUsernameProps> = (
  props
) => {
  return (
    <>
      <div style={{ height: props.height || "63vh" }}>
        <DisplayTitle
          title="กรุณาระบุ HN เพื่อตรวจสอบ"
          description={[
            "กรุณาระบุ HN เลข 7 หลัก",
            "จากการลงทะเบียนไว้กับ",
            "โรงพยาบาลธรรมศาสตร์เฉลิมพระเกียรติ",
          ]}
        />

        <Form onSubmit={props.onSubmit}>
          <Form.Field error={props.registerInfo?.error?.hn || false}>
            <label className="label-required" style={styles.label}>
              HN
            </label>
            <Input
              value={props.registerInfo?.hn || ""}
              icon
              placeholder=""
              style={{ fontSize: "16px" }}
              onChange={(e: any, v: InputProps) =>
                props.onEvent({
                  message: "HandleSetValueRegisterInfo",
                  params: { key: "hn", value: v.value },
                })
              }
              autoFocus={props.autoFocus}
            >
              <input />
              <PencilIcon />
            </Input>
          </Form.Field>
        </Form>
      </div>
    </>
  );
};

const initialHeight = window.innerHeight;

const TransformRegister: React.FunctionComponent<TransformRegisterProps> = (
  props
) => {
  useEffect(() => {
    if (Cookies.get("apiToken")) {
      props.onHaveApiToken?.(props.history);
    }
  }, []);

  useEffect(() => {
    // call window.iosNative.getDevice() success
    //  setDevice from native
    if (props.setDevice?.device_token) {
      props.onEvent({
        message: "HandleNextViewRegister",
        params: { current: "password", next: "info", history: props.history },
      });
    }
  }, [props.setDevice]);

  /* ------------------------ Memo ------------------------ */
  const headerName = useMemo(() => {
    const names = [
      "ลงทะเบียน",
      "สร้างรหัสผ่าน",
      "ข้อมูลส่วนตัว",
      "กรุณาระบุ HN",
    ];
    return names[props.viewIndexRegister || 0];
  }, [props.viewIndexRegister]);

  const contentHeight = useMemo(() => {
    return `${(initialHeight * 63) / 100}px`;
  }, []);

  /* ----------------------- Handle ----------------------- */
  const handleLeftIconClick = () => {
    props.history.goBack();
  };

  const handleNextView = (
    current: "username" | "password" | "hn" | "info",
    next: string
  ) => {
    props.onEvent({
      message: "HandleNextViewRegister",
      params: { current, next, history: props.history },
    });
  };

  const handleClose = () => {
    props.onEvent({ message: "HandleCloseMessage", params: {} });
  };

  return (
    <div className="tf-register">
      <Dimmer
        active={props.loadingMainTransform}
        inverted
        style={{ zIndex: 1100 }}
      >
        <Loader inverted>Loading</Loader>
      </Dimmer>

      <TFHead title={headerName} leftIconClick={handleLeftIconClick} />

      <Carousel
        selectedItem={props.viewIndexRegister}
        swipeable={false}
        showThumbs={false}
        showArrows={false}
        showStatus={false}
        interval={5000000}
      >
        {[
          <div key={"carousel-1"} className="tf-main">
            <SpecifyUsername
              {...props}
              onSubmit={() => handleNextView("username", "password")}
              height={contentHeight}
            />
            <Button
              fluid
              className="button-submit-primary"
              onClick={() => handleNextView("username", "password")}
            >
              ต่อไป
            </Button>
          </div>,
          <div key={"carousel-2"} className="tf-main">
            <SpecifyPassword
              {...props}
              onSubmit={() => handleNextView("password", "info")}
              height={contentHeight}
            />
            <Button
              fluid
              className="button-submit-primary"
              onClick={() => handleNextView("password", "info")}
            >
              ต่อไป
            </Button>
          </div>,
          <div key={"carousel-3"} className="tf-main">
            <PersonalInformation
              {...props}
              onSubmit={() => handleNextView("info", "hn")}
              height={contentHeight}
            />
            <Button
              fluid
              className="button-submit-primary"
              onClick={() => handleNextView("info", "hn")}
            >
              ต่อไป
            </Button>
          </div>,
          <div key={"carousel-4"} className="tf-main">
            <SpecifyHN
              {...props}
              onSubmit={() => handleNextView("hn", "success")}
              height={contentHeight}
            />
            <Button
              fluid
              className="button-submit-primary"
              onClick={() => handleNextView("hn", "success")}
            >
              เสร็จสิ้น
            </Button>
          </div>,
        ]
          .filter((element, index) =>
            !props.firstRegistration ? index !== 2 : true
          )
          .map((element) => element)}
      </Carousel>

      {
        // props.errorMessage === "HN_NOT_AVAILABLE" ? (
        //   <TFModInfo
        //     type="not_available"
        //     open={true}
        //     buttonName="ปิด"
        //     onApprove={handleClose}
        //     onClose={handleClose}
        //     style={{ height: "105px" }}
        //   >
        //     <>
        //       <div>{intl.formatMessage({ id: "เนื่องจาก HN นี้" })}</div>
        //       <div>{intl.formatMessage({ id: "ถูกลงทะเบียนไปแล้ว" })}</div>
        //     </>
        //   </TFModInfo>
        // ) :
        props.modMessageType === "SEND_TO_EMAIL" && (
          <ModCheckEmail onClose={handleClose} languageUX={props.languageUX}/>
        )
      }

      <ModMessage
        onEvent={props.onEvent}
        successMessage={props.successMessage}
        errorMessage={props.errorMessage}
      />
    </div>
  );
};

TransformRegister.defaultProps = {
  onEvent: () => {},
  history: {},
  viewIndexRegister: 0,
  registerInfo: MobStateInitial.registerInfo,
  firstRegistration: false,
  loadingMainTransform: false,
} as TransformRegisterProps;

export default React.memo(TransformRegister);
