import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  DateTextBox
} from 'react-lib/apps/common'
import {
  Button
} from 'semantic-ui-react'

const ModSummaryWaitingListUX = (props: any) => {
    return(
      <div
        style={{paddingTop: "10px"}}>
        
        <div
          style={{display:"flex",marginBottom:"10px"}}>
          {}
          <div
            style={{marginLeft:"10px",marginTop:"5px",fontWeight: "bold", fontSize: "1.2rem"}}>
            ระยะเวลารอคิว :
          </div>
          <div
            style={{marginLeft:"10px",marginTop:"5px",fontWeight: "bold", fontSize: "1.2rem"}}>
            {props?.waitingListName}
          </div>
          <div
            style={{flex:"1"}}>
            
          </div>
          <div
            style={{marginLeft:"10px",marginTop:"10px"}}>
            วันที่เข้า WaittingList
          </div>
          <div
            style={{marginLeft:"10px"}}>
            
            <DateTextBox
              onChange={props.changeDate("startDateModWL")}
              value={props.startDateModWL}>
            </DateTextBox>
          </div>
          <div
            style={{marginLeft:"10px",marginTop:"10px"}}>
            ถึง
          </div>
          <div
            style={{marginLeft:"10px"}}>
            
            <DateTextBox
              onChange={props.changeDate("endDateModWL")}
              value={props.endDateModWL}>
            </DateTextBox>
          </div>
          <div
            style={{display:"flex", flexDirection: "row",marginLeft:"5px"}}>
            
            <Button
              color="blue"
              onClick={props.OnSearch}>
              ค้นหา
            </Button>
            <div>
              {props.buttonExportCSV}
            </div>
          </div>
        </div>
        <div>
          
          <Table
            data={props?.dataSummaryWaitingList}
            headers={props.headers}
            keys="patient__hn,name,created,appointment__estimated_at,waiting_time"
            minRows="7"
            showPagination={false}
            style={{height: "300px"}}>
          </Table>
        </div>
      </div>
    )
}

export default ModSummaryWaitingListUX

export const screenPropsDefault = {"title":"9:00-12:00"}

/* Date Time : Tue Aug 09 2022 11:42:34 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{paddingTop: \"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",marginBottom:\"10px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 2,
      "name": "Table",
      "parent": 3,
      "props": {
        "data": {
          "type": "code",
          "value": "props?.dataSummaryWaitingList"
        },
        "getTrProps": {
          "type": "code",
          "value": ""
        },
        "headers": {
          "type": "code",
          "value": "props.headers"
        },
        "keys": {
          "type": "value",
          "value": "patient__hn,name,created,appointment__estimated_at,waiting_time"
        },
        "minRows": {
          "type": "value",
          "value": "7"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"300px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ระยะเวลารอคิว :"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\",marginTop:\"5px\",fontWeight: \"bold\", fontSize: \"1.2rem\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.waitingListName"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\",marginTop:\"5px\",fontWeight: \"bold\", fontSize: \"1.2rem\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่เข้า WaittingList"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\",marginTop:\"10px\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 8,
      "name": "DateTextBox",
      "parent": 7,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changeDate(\"startDateModWL\")"
        },
        "value": {
          "type": "code",
          "value": "props.startDateModWL"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\",marginTop:\"10px\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"10px\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 11,
      "name": "DateTextBox",
      "parent": 10,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changeDate(\"endDateModWL\")"
        },
        "value": {
          "type": "code",
          "value": "props.endDateModWL"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:\"1\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", flexDirection: \"row\",marginLeft:\"5px\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "Button",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": ""
        },
        "onClick": {
          "type": "code",
          "value": "props.OnSearch"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonExportCSV"
        }
      },
      "seq": 18,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "ModSummaryWaitingListUX",
  "project": "cudent2",
  "screenPropsDefault": {
    "title": "9:00-12:00"
  },
  "width": 80
}

*********************************************************************************** */
