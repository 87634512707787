import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Icon
} from 'semantic-ui-react'

const CardQueuePrepareDrugUX = (props: any) => {
    return(
      <div
        style={{position:"relative",marginTop: props.openFullScreen ? "40px" : "0px"  }}>
        <div
          style={{display:"flex",margin:"10px",}}>
          
          <div
            style={{width:"10%"}}>
            {}
            <img
              src={props.logo}
              style={{width: "100%", height: "90%"}}>
            </img>
          </div>
          <div
            style={{width:"100%"}}>
            
            <div
              style={{ display: "flex", justifyContent: "space-between",marginLeft:"5px"}}>
              
              <div
                style={{ fontWeight: "bold", fontSize: "18px",alignItems:"center",display:"flex"}}>
                {props.hospital}
              </div>
              <div
                style={{ fontWeight: "bold", fontSize: "18px",alignItems:"center",display:"flex"}}>
                {props.date}
              </div>
            </div>
            <div
              style={{ border: "1px solid rgb(29, 189, 212)"}}>
              
            </div>
            <div
              style={{ display: "flex", justifyContent: "space-between",marginLeft:"5px",marginTop:"5px"}}>
              
              <div
                style={{ fontWeight: "bold", fontSize: "17px",alignItems:"center",display:"flex"}}>
                {props.division}
              </div>
              <div
                style={{ fontWeight: "bold", fontSize: "17px",alignItems:"center",display:"flex"}}>
                {props.time}
              </div>
            </div>
          </div>
          <div
            style={{display: props.openFullScreen ?  "flex" : "none",alignItems:"center",marginLeft:"5px"}}>
            
            <Icon
              className="red large"
              name={"close"}
              onClick={props.onClose}>
            </Icon>
          </div>
        </div>
        <div
          style={{justifyContent:"center",display: "flex"}}>
          
          <div
            style={{ backgroundColor: "rgb(29, 189, 212)",borderRadius:"10px",margin:"10px",padding:"10px",width:"20%",position:"absolute",top:"-10px"}}>
            
            <label
              style={{  fontWeight: "bold", fontSize: "24px",justifyContent:"center",display: "flex",color:"white"}}>
              ห้องจ่ายยา
            </label>
          </div>
        </div>
        <div
          style={{margin:"0px 10px 10px 10px",zoom:"85%"}}>
          
          <div
            style={{display: "grid", gridTemplateColumns: "60% 40%", columnGap: "2px", padding: "0px"}}>
            
            <div
              style={{ backgroundColor: "#C6EBF3",borderRadius:"5px",margin:"10px"}}>
              
              <div
                style={{ backgroundColor: "white",borderRadius:"20px",margin:"10px",padding:"10px"}}>
                
                <label
                  style={{  fontWeight: "bold", fontSize: "22px",justifyContent:"center",display: "flex",color:"#026EBC"}}>
                  กำลังจัดยา
                </label>
              </div>
              <div>
                {props.itemLess}
              </div>
              <div
                style={{display:"flex",margin:"10px",justifyContent:"flex-end"}}>
                
                <div
                  style={{ fontWeight: "bold", fontSize: "20px",alignItems:"center",display:"flex"}}>
                  จำนวนที่รอรับยา
                </div>
                <div
                  style={{ backgroundColor: "white",borderRadius:"10px",margin:"10px",padding:"10px",width:"5%"}}>
                  
                  <div
                    style={{ fontWeight: "bold", fontSize: "20px",alignItems:"center",display:"flex",justifyContent:"center",}}>
                    {props.countItemLess}
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{ backgroundColor: "#FFE6B9",borderRadius:"5px",margin:"10px"}}>
              
              <div
                style={{ backgroundColor: "white",borderRadius:"20px",margin:"10px",padding:"10px"}}>
                
                <label
                  style={{  fontWeight: "bold", fontSize: "22px",justifyContent:"center",display: "flex",color:"#026EBC"}}>
                  กำลังจัดยา  (มากกว่า 10 รายการ )
                </label>
              </div>
              <div>
                {props.itemMore}
              </div>
              <div
                style={{display:"flex",margin:"10px",justifyContent:"flex-end"}}>
                
                <div
                  style={{ fontWeight: "bold", fontSize: "20px",alignItems:"center",display:"flex"}}>
                  จำนวนที่รอรับยา
                </div>
                <div
                  style={{ backgroundColor: "white",borderRadius:"10px",margin:"10px",padding:"10px",width:"5%"}}>
                  
                  <div
                    style={{ fontWeight: "bold", fontSize: "20px",alignItems:"center",display:"flex",justifyContent:"center",}}>
                    {props.countItemMore}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{margin:"0px 10px 10px 10px",backgroundColor:"#FFB4B4"}}>
          
          <div
            style={{display:"flex",padding:"10px",position:"relative"}}>
            
            <div
              style={{ fontWeight: "bold", fontSize: "20px",marginRight:"5px"}}>
              ใบสั่งยารอเเก้ไข :
            </div>
            <div
              style={{width:"80%"}}>
              {props.itemReject}
            </div>
          </div>
        </div>
      </div>
    )
}

export default CardQueuePrepareDrugUX

export const screenPropsDefault = {}

/* Date Time : Tue Oct 04 2022 17:30:58 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{position:\"relative\",marginTop: props.openFullScreen ? \"40px\" : \"0px\"  }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{margin:\"0px 10px 10px 10px\",zoom:\"85%\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"grid\", gridTemplateColumns: \"60% 40%\", columnGap: \"2px\", padding: \"0px\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",margin:\"10px\",}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"space-between\",marginLeft:\"5px\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ border: \"1px solid rgb(29, 189, 212)\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"space-between\",marginLeft:\"5px\",marginTop:\"5px\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "code",
          "value": "props.hospital"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"18px\",alignItems:\"center\",display:\"flex\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "code",
          "value": "props.date"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"18px\",alignItems:\"center\",display:\"flex\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 17,
      "props": {
        "children": {
          "type": "code",
          "value": "props.division"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"17px\",alignItems:\"center\",display:\"flex\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 17,
      "props": {
        "children": {
          "type": "code",
          "value": "props.time"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"17px\",alignItems:\"center\",display:\"flex\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width:\"10%\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "img",
      "parent": 26,
      "props": {
        "src": {
          "type": "code",
          "value": "props.logo"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"90%\"}"
        }
      },
      "seq": 27,
      "void": true
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{justifyContent:\"center\",display: \"flex\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 29,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ backgroundColor: \"rgb(29, 189, 212)\",borderRadius:\"10px\",margin:\"10px\",padding:\"10px\",width:\"20%\",position:\"absolute\",top:\"-10px\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "label",
      "parent": 30,
      "props": {
        "children": {
          "type": "value",
          "value": "ห้องจ่ายยา"
        },
        "style": {
          "type": "code",
          "value": "{  fontWeight: \"bold\", fontSize: \"24px\",justifyContent:\"center\",display: \"flex\",color:\"white\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.openFullScreen ?  \"flex\" : \"none\",alignItems:\"center\",marginLeft:\"5px\"}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "Icon",
      "parent": 32,
      "props": {
        "className": {
          "type": "value",
          "value": "red large"
        },
        "name": {
          "type": "code",
          "value": "\"close\""
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ backgroundColor: \"#C6EBF3\",borderRadius:\"5px\",margin:\"10px\"}"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ backgroundColor: \"#FFE6B9\",borderRadius:\"5px\",margin:\"10px\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 35,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ backgroundColor: \"white\",borderRadius:\"20px\",margin:\"10px\",padding:\"10px\"}"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "label",
      "parent": 37,
      "props": {
        "children": {
          "type": "value",
          "value": "กำลังจัดยา"
        },
        "style": {
          "type": "code",
          "value": "{  fontWeight: \"bold\", fontSize: \"22px\",justifyContent:\"center\",display: \"flex\",color:\"#026EBC\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 36,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ backgroundColor: \"white\",borderRadius:\"20px\",margin:\"10px\",padding:\"10px\"}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "label",
      "parent": 39,
      "props": {
        "children": {
          "type": "value",
          "value": "กำลังจัดยา  (มากกว่า 10 รายการ )"
        },
        "style": {
          "type": "code",
          "value": "{  fontWeight: \"bold\", fontSize: \"22px\",justifyContent:\"center\",display: \"flex\",color:\"#026EBC\"}"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 36,
      "props": {
        "children": {
          "type": "code",
          "value": "props.itemMore"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 35,
      "props": {
        "children": {
          "type": "code",
          "value": "props.itemLess"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 35,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",margin:\"10px\",justifyContent:\"flex-end\"}"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 36,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",margin:\"10px\",justifyContent:\"flex-end\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "div",
      "parent": 43,
      "props": {
        "children": {
          "type": "value",
          "value": "จำนวนที่รอรับยา"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"20px\",alignItems:\"center\",display:\"flex\"}"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 43,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ backgroundColor: \"white\",borderRadius:\"10px\",margin:\"10px\",padding:\"10px\",width:\"5%\"}"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "div",
      "parent": 46,
      "props": {
        "children": {
          "type": "code",
          "value": "props.countItemLess"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"20px\",alignItems:\"center\",display:\"flex\",justifyContent:\"center\",}"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "div",
      "parent": 44,
      "props": {
        "children": {
          "type": "value",
          "value": "จำนวนที่รอรับยา"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"20px\",alignItems:\"center\",display:\"flex\"}"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "div",
      "parent": 44,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ backgroundColor: \"white\",borderRadius:\"10px\",margin:\"10px\",padding:\"10px\",width:\"5%\"}"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "div",
      "parent": 49,
      "props": {
        "children": {
          "type": "code",
          "value": "props.countItemMore"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"20px\",alignItems:\"center\",display:\"flex\",justifyContent:\"center\",}"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{margin:\"0px 10px 10px 10px\",backgroundColor:\"#FFB4B4\"}"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "div",
      "parent": 51,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",padding:\"10px\",position:\"relative\"}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 52,
      "props": {
        "children": {
          "type": "value",
          "value": "ใบสั่งยารอเเก้ไข :"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"20px\",marginRight:\"5px\"}"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "div",
      "parent": 52,
      "props": {
        "children": {
          "type": "code",
          "value": "props.itemReject"
        },
        "style": {
          "type": "code",
          "value": "{width:\"80%\"}"
        }
      },
      "seq": 54,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardQueuePrepareDrugUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
