import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Dropdown,
  Button,
  Checkbox,
  Input,
  TextArea
} from 'semantic-ui-react'

const CardSettingQueueUX = (props: any) => {
    return(
      <div
        style={{ margin: "15px" }}>
        <div
          style={{padding: "10px 10px 15px", fontSize: "1.2rem", fontWeight: "bold" ,display: "flex", alignItems: "center" }}>
          ตารางแจ้งเตือนคิวรอรับบริการให้ผู้ป่วยของคลินิก (อัตโนมัติ)
        </div>
        <div
          style={{display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          
          <div
            style={{ display: "flex", alignItems:  "center", width: "50%" }}>
            
            <div
              style={{ fontWeight: "bold" ,marginRight: "20px" }}>
              แผนก
            </div>
            <Dropdown
              clearable={true}
              multiple={true}
              onChange={props.handleChangeValue("searchDivision")}
              options={props.masterOptions?.division}
              search={true}
              selection={true}
              style={{ marginRight: "20px", width: "100%" }}
              value={props.notificationQueueOrder?.searchDivision}>
            </Dropdown>
            <Button
              color="teal"
              onClick={props.onSearchNotificationMessage}
              size="small">
              ค้นหา
            </Button>
          </div>
          <div>
            
            <Button
              color="green"
              onClick={props.onOpenSettingQueue}
              size="small">
              เพิ่มการกำหนดคิว
            </Button>
          </div>
        </div>
        <Table
          data={props.notificationQueue}
          headers="แผนก,จำนวนผู้ป่วยถูกเรียก/ครั้ง,รูปแบบข้อความ,แก้ไข,ลบ"
          keys="division,count,typeText,edit,delete"
          minRows="5"
          showPagination={false}
          style={{height: "300px", marginTop: "0.5rem"}}
          widths="{ ....,...,...,100px,100px }">
        </Table>
        <div
          style={{ margin: "20px 0px", padding: "20px", border:  "1.5px solid rgba(26, 179, 203, 1)", ...(!props.openSettingQueue && {display: "none" }) }}>
          
          <div>
            {props.errorRemind}
          </div>
          <div
            style={{display: "flex", alignItems: "center", width: "55%" }}>
            
            <div
              style={{ fontWeight: "bold" ,marginRight: "20px" }}>
              แผนก
            </div>
            <Dropdown
              clearable={true}
              multiple={true}
              onChange={props.handleChangeValue("selectDivision")}
              options={props.masterOptions?.division}
              search={true}
              selection={true}
              style={{ marginRight: "20px", width: "70%" , display: props.notificationQueueOrder?.selectAllDivision && "none"}}
              value={props.notificationQueueOrder?.selectDivision }>
            </Dropdown>
            <Input
              readOnly="true"
              style={{ marginRight: "20px", width: "70%" , display: !props.notificationQueueOrder?.selectAllDivision && "none"}}
              value="ืทั้งหมด">
            </Input>
            <Checkbox
              checked={props.notificationQueueOrder?.selectAllDivision}
              label="แผนกทั้งหมด"
              name="selectAllDivision"
              onChange={props.handleChangeSelectAllDivision}>
            </Checkbox>
          </div>
          <div
            style={{display: "flex", alignItems: "center", width: "55%", marginTop: "20px" }}>
            
            <div
              style={{ fontWeight: "bold" ,marginRight: "20px" }}>
              จำนวนผู้ป่วยถูกเรียก/ครั้ง
            </div>
            <Input
              min="0"
              onChange={props.handleChangeValuePatientCount}
              type="number"
              value={props.notificationQueueOrder?.patientCount || 0}>
            </Input>
          </div>
          <div
            style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
            
            <div
              style={{display: "flex", width: "80%", }}>
              
              <div
                style={{ fontWeight: "bold" ,marginRight: "20px" }}>
                ข้อความ
              </div>
              <TextArea
                onChange={props.handleChangeValue("notificationDetail")}
                rows="3"
                style={{ width: "100%", resize: "none" }}
                value={props.notificationQueueOrder?.notificationDetail}>
              </TextArea>
            </div>
            <div
              style={{ display: "flex", alignItems: "flex-end" }}>
              
              <Button
                color="green"
                onClick={props.onSaveSettingQueue}
                size="small"
                style={{ margin: "0px 10px" }}>
                บันทึก
              </Button>
              <Button
                color="red"
                onClick={props.onCloseSettingQueue}
                size="small">
                ยกเลิก
              </Button>
            </div>
          </div>
        </div>
        <div
          style={{padding: "10px 10px 15px", fontSize: "1.2rem", fontWeight: "bold" ,display: "flex", alignItems: "center", marginTop: "40px" }}>
          ตารางแสดงกลุ่มรูปแบบข้อความสำหรับส่งให้ผู้ป่วยผ่านโมบายแอพพลิเคชัน
        </div>
        <div
          style={{display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          
          <div
            style={{ display: "flex", alignItems:  "center", width: "50%" }}>
            
            <div
              style={{ fontWeight: "bold" ,marginRight: "20px" }}>
              แผนก
            </div>
            <Dropdown
              clearable={true}
              multiple={true}
              onChange={props.handleChangeMessageTemplate("searchDivision")}
              options={props.masterOptions?.division}
              search={true}
              selection={true}
              style={{ marginRight: "20px", width: "100%" }}
              value={props.messageTemplateOrder?.searchDivision}>
            </Dropdown>
            <Button
              color="teal"
              onClick={props.onSearchMessageTemplate}
              size="small">
              ค้นหา
            </Button>
          </div>
          <div>
            
            <Button
              color="green"
              onClick={props.onOpenMessageTemplate}
              size="small">
              เพิ่มรูปแบบข้อความ
            </Button>
          </div>
        </div>
        <Table
          data={props.messageTemplateQueue}
          headers="หัวข้อ,รายละเอียด,แผนก,แก้ไข,ลบ"
          keys="header,detail,division,edit,delete"
          minRows="5"
          showPagination={false}
          style={{height: "300px", marginTop: "0.5rem"}}
          widths="{ ...,...,...,100px,100px }">
        </Table>
        <div
          style={{ margin: "20px 0px", padding: "20px", border:  "1.5px solid rgba(26, 179, 203, 1)", ...(!props.openMessageTemplate && {display: "none" }) }}>
          
          <div>
            {props.errorTemplate}
          </div>
          <div
            style={{display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between" }}>
            
            <div
              style={{ fontWeight: "bold" ,marginRight: "20px" }}>
              หัวข้อ
            </div>
            <Input
              onChange={props.handleChangeMessageTemplate("header")}
              style={{  width: "90%", }}
              value={props.messageTemplateOrder?.header}>
            </Input>
          </div>
          <div
            style={{display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between", marginTop: "20px" }}>
            
            <div
              style={{ fontWeight: "bold" ,marginRight: "20px" }}>
              รายละเอียด
            </div>
            <Input
              onChange={props.handleChangeMessageTemplate("detail")}
              style={{ width: "90%" }}
              value={props.messageTemplateOrder?.detail}>
            </Input>
          </div>
          <div
            style={{display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between", marginTop: "20px" }}>
            
            <div
              style={{ fontWeight: "bold" ,marginRight: "20px" }}>
              แผนก
            </div>
            <Dropdown
              clearable={true}
              multiple={true}
              onChange={props.handleChangeMessageTemplate("selectDivision")}
              options={props.masterOptions?.division}
              search={true}
              selection={true}
              style={{ width: "90%" }}
              value={props.messageTemplateOrder?.selectDivision}>
            </Dropdown>
          </div>
          <div
            style={{display: "flex", alignItems: "center", width: "100%", justifyContent: "flex-end", marginTop: "20px" }}>
            
            <Button
              color="green"
              onClick={props.onSaveMessageTemplate}
              size="small"
              style={{ margin: "0px 10px" }}>
              บันทึก
            </Button>
            <Button
              color="red"
              onClick={props.onCloseMessageTemplate}
              size="small">
              ยกเลิก
            </Button>
          </div>
        </div>
      </div>
    )
}


export default CardSettingQueueUX

export const screenPropsDefault = {"price_claimable":0,"price_non_claimable":0,"price_total":0}

/* Date Time : Fri Feb 02 2024 17:22:10 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ margin: \"15px\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "ตารางแจ้งเตือนคิวรอรับบริการให้ผู้ป่วยของคลินิก (อัตโนมัติ)"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px 10px 15px\", fontSize: \"1.2rem\", fontWeight: \"bold\" ,display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", justifyContent: \"space-between\" }"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 10,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.notificationQueue"
        },
        "getTrProps": {
          "type": "code",
          "value": ""
        },
        "headers": {
          "type": "value",
          "value": "แผนก,จำนวนผู้ป่วยถูกเรียก/ครั้ง,รูปแบบข้อความ,แก้ไข,ลบ"
        },
        "id": {
          "type": "value",
          "value": ""
        },
        "keys": {
          "type": "value",
          "value": "division,count,typeText,edit,delete"
        },
        "minRows": {
          "type": "value",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"300px\", marginTop: \"0.5rem\"}"
        },
        "widths": {
          "type": "value",
          "value": "{ ....,...,...,100px,100px }"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems:  \"center\", width: \"50%\" }"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 51,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนก"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" ,marginRight: \"20px\" }"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "Dropdown",
      "parent": 51,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"searchDivision\")"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.division"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\", width: \"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.notificationQueueOrder?.searchDivision"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "Button",
      "parent": 51,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "teal"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSearchNotificationMessage"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 56,
      "name": "Button",
      "parent": 52,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มการกำหนดคิว"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onOpenSettingQueue"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "ตารางแสดงกลุ่มรูปแบบข้อความสำหรับส่งให้ผู้ป่วยผ่านโมบายแอพพลิเคชัน"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px 10px 15px\", fontSize: \"1.2rem\", fontWeight: \"bold\" ,display: \"flex\", alignItems: \"center\", marginTop: \"40px\" }"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", justifyContent: \"space-between\" }"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "div",
      "parent": 58,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems:  \"center\", width: \"50%\" }"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "div",
      "parent": 58,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "div",
      "parent": 59,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนก"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" ,marginRight: \"20px\" }"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 62,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.messageTemplateQueue"
        },
        "headers": {
          "type": "value",
          "value": "หัวข้อ,รายละเอียด,แผนก,แก้ไข,ลบ"
        },
        "keys": {
          "type": "value",
          "value": "header,detail,division,edit,delete"
        },
        "minRows": {
          "type": "value",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"300px\", marginTop: \"0.5rem\"}"
        },
        "widths": {
          "type": "value",
          "value": "{ ...,...,...,100px,100px }"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "Dropdown",
      "parent": 59,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeMessageTemplate(\"searchDivision\")"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.division"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\", width: \"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.messageTemplateOrder?.searchDivision"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "Button",
      "parent": 59,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "teal"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSearchMessageTemplate"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "Button",
      "parent": 60,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มรูปแบบข้อความ"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onOpenMessageTemplate"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": null,
      "id": 66,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"20px 0px\", padding: \"20px\", border:  \"1.5px solid rgba(26, 179, 203, 1)\", ...(!props.openSettingQueue && {display: \"none\" }) }"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "div",
      "parent": 66,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", width: \"55%\" }"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "div",
      "parent": 67,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนก"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" ,marginRight: \"20px\" }"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "Dropdown",
      "parent": 67,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectDivision\")"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.division"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\", width: \"70%\" , display: props.notificationQueueOrder?.selectAllDivision && \"none\"}"
        },
        "value": {
          "type": "code",
          "value": "props.notificationQueueOrder?.selectDivision "
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "Checkbox",
      "parent": 67,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.notificationQueueOrder?.selectAllDivision"
        },
        "label": {
          "type": "value",
          "value": "แผนกทั้งหมด"
        },
        "name": {
          "type": "value",
          "value": "selectAllDivision"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeSelectAllDivision"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "div",
      "parent": 66,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", width: \"55%\", marginTop: \"20px\" }"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "div",
      "parent": 71,
      "props": {
        "children": {
          "type": "value",
          "value": "จำนวนผู้ป่วยถูกเรียก/ครั้ง"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" ,marginRight: \"20px\" }"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "Input",
      "parent": 71,
      "props": {
        "min": {
          "type": "value",
          "value": "0"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValuePatientCount"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.notificationQueueOrder?.patientCount || 0"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "div",
      "parent": 66,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"space-between\", marginTop: \"20px\" }"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "div",
      "parent": 80,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", width: \"80%\", }"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "div",
      "parent": 80,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"flex-end\" }"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "div",
      "parent": 81,
      "props": {
        "children": {
          "type": "value",
          "value": "ข้อความ"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" ,marginRight: \"20px\" }"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 84,
      "name": "TextArea",
      "parent": 81,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"notificationDetail\")"
        },
        "rows": {
          "type": "value",
          "value": "3"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", resize: \"none\" }"
        },
        "value": {
          "type": "code",
          "value": "props.notificationQueueOrder?.notificationDetail"
        }
      },
      "seq": 84,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 85,
      "name": "Button",
      "parent": 82,
      "props": {
        "children": {
          "type": "value",
          "value": "บันทึก"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSaveSettingQueue"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 10px\" }"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 86,
      "name": "Button",
      "parent": 82,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิก"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCloseSettingQueue"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 87,
      "name": "Input",
      "parent": 67,
      "props": {
        "disabled": {
          "type": "value",
          "value": ""
        },
        "readOnly": {
          "type": "value",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"20px\", width: \"70%\" , display: !props.notificationQueueOrder?.selectAllDivision && \"none\"}"
        },
        "value": {
          "type": "value",
          "value": "ืทั้งหมด"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"20px 0px\", padding: \"20px\", border:  \"1.5px solid rgba(26, 179, 203, 1)\", ...(!props.openMessageTemplate && {display: \"none\" }) }"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "div",
      "parent": 88,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", width: \"100%\", justifyContent: \"space-between\" }"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": null,
      "id": 90,
      "name": "div",
      "parent": 88,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", width: \"100%\", justifyContent: \"space-between\", marginTop: \"20px\" }"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": null,
      "id": 91,
      "name": "div",
      "parent": 88,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", width: \"100%\", justifyContent: \"space-between\", marginTop: \"20px\" }"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "div",
      "parent": 89,
      "props": {
        "children": {
          "type": "value",
          "value": "หัวข้อ"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" ,marginRight: \"20px\" }"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "div",
      "parent": 90,
      "props": {
        "children": {
          "type": "value",
          "value": "รายละเอียด"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" ,marginRight: \"20px\" }"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 96,
      "name": "div",
      "parent": 91,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนก"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\" ,marginRight: \"20px\" }"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": null,
      "id": 98,
      "name": "div",
      "parent": 88,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", width: \"100%\", justifyContent: \"flex-end\", marginTop: \"20px\" }"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 99,
      "name": "Input",
      "parent": 89,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeMessageTemplate(\"header\")"
        },
        "style": {
          "type": "code",
          "value": "{  width: \"90%\", }"
        },
        "value": {
          "type": "code",
          "value": "props.messageTemplateOrder?.header"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 100,
      "name": "Input",
      "parent": 90,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeMessageTemplate(\"detail\")"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"90%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.messageTemplateOrder?.detail"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 101,
      "name": "Dropdown",
      "parent": 91,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeMessageTemplate(\"selectDivision\")"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.division"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"90%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.messageTemplateOrder?.selectDivision"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 102,
      "name": "Button",
      "parent": 98,
      "props": {
        "children": {
          "type": "value",
          "value": "บันทึก"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSaveMessageTemplate"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 10px\" }"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 103,
      "name": "Button",
      "parent": 98,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิก"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCloseMessageTemplate"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": null,
      "id": 104,
      "name": "div",
      "parent": 66,
      "props": {
        "children": {
          "type": "code",
          "value": "props.errorRemind"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 105,
      "name": "div",
      "parent": 88,
      "props": {
        "children": {
          "type": "code",
          "value": "props.errorTemplate"
        }
      },
      "seq": 89,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 90,
  "isMounted": false,
  "memo": false,
  "name": "CardSettingQueueUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "price_claimable": 0,
    "price_non_claimable": 0,
    "price_total": 0
  },
  "width": 55
}

*********************************************************************************** */
