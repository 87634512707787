import React, { useEffect, useState, useRef } from "react";
import { Container, Form, Input, Button, Modal, Dimmer, Loader } from "semantic-ui-react";
import PureReactTable from "../../common/PureReactTable";
import Cookies from "js-cookie";
import ErrorMessage from "../../common/ErrorMessage";
import { useIntl } from "react-intl";

const BindHNPage = (props) => {
  const intl = useIntl();

  const [selectedRow, setSelectedRow] = useState(null)
  const [search, setSearch] = useState("")
  const [error, setError] = useState(null)
  const [data, setData] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [hn, setHn] = useState("")
  const [loading, setLoading] = useState(false)
  const [bindHnError, setBindHnError] = useState("")
  const isMounted = useRef(true);

  useEffect(() => {
    return () => isMounted.current = false
  }, [])

  useEffect(() => {
    if (props.division || Cookies.get("division_id")) {
      getProxyPatient();
    }
  }, [props.division, Cookies.get("division_id")])
  
  const getProxyPatient = async () => {
    setError(null)
    setLoading(true)
    const [res, err, net] = await props.controller.getProxyPatientList({
      apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
      division: props.division ? props.division : Cookies.get("division_id"),
      search
    })
    setLoading(false)
   
    if(isMounted.current){
      if(err){
        let er = err;
        if (err.length > 200) {
          er = `${net.response.status}: ${net.response.statusText}`;
        }
        setError(er)
        setData([])
      } else {
        let newArr = res.items.filter((item) => item.proxy_patient_has_division.id)
        for(let item of newArr){
          item.id = item.patient
        }
        setData(newArr)
      }
    } else {
      console.log("unmount")
    }
  }

  useEffect(() => {
    if(!openModal){
      setHn("")
      setBindHnError("")
    }
  }, [openModal])

  const handleSelectRow = (row) => {
    setSelectedRow(row)
    setOpenModal(true)
    setHn(row.hn)
  }

  const handleBindHn = async () => {
    setBindHnError("")
    setLoading(true)
    const [res, err, network] = await props.controller.patchProxyPatientHasDivisionAdmin({
      apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
      hn,
      id: selectedRow.proxy_patient_has_division.id
    })
    if(res){
      getProxyPatient()
      setOpenModal(false)
    } else {
      let error = err
      if(err.length > 200){
        error = `${network.response.status}: ${network.response.statusText}`;
      }
      setBindHnError(error)
    }
    setLoading(false)
  }

  return (
    <Container className="bind-hn-page">
    <Modal open={openModal} onClose={() => setOpenModal(false)}>
      <Dimmer inverted active={loading}>
        <Loader inverted/>
      </Dimmer>
      <Modal.Header>กรุณาตรวจสอบข้อมูลและกรอก HN</Modal.Header>
      <Modal.Content>
        <ErrorMessage error={bindHnError}/>
        <h4>{`ชื่อ-นามสกุล:   ${selectedRow?.patient_name ? selectedRow.patient_name : "-"}`}</h4>
        <h4>{`E-mail:   ${selectedRow?.email ? selectedRow.email : "-"}`}</h4>
        <div style={{display: "flex", alignItems: "center"}}>
          <h4 style={{ margin: 0 }}>HN:</h4>&ensp;&ensp;
          <Input style={{ width: "100%" }} value={hn} onChange={(e) => setHn(e.target.value)}/>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button color="green" content={intl.formatMessage({ id: "ยืนยัน" })} onClick={handleBindHn}/>
      </Modal.Actions>
    </Modal>
    <ErrorMessage error={error}/>
      <Form>
        <Form.Group inline>
          <Form.Field width={1}>
            <label>Search</label>
          </Form.Field>
          <Form.Field width={13}>
            <Input value={search} onChange={(e) => setSearch(e.target.value)}/>
          </Form.Field>
          <Form.Field width={2}>
            <Button color="blue" content="search" fluid onClick={getProxyPatient}/>
          </Form.Field>
        </Form.Group>
      </Form>
      <PureReactTable
        showPageSizeOptions={false}
        showPagination={false}
        loading={loading}
        pageSize={data.length > 10 ? data.length : 10}
        columns={[
          {
            Header: "HN",
            accessor: "hn",
            width: 200
          },
          {
            Header: "ชื่อ-นามสกุล",
            accessor: "patient_name",
            style: { minWidth: "100px" },
          },
          {
            Header: "E-mail",
            accessor: "email",
            style: { minWidth: "100px" },
          }
        ]}
        selectedRow={selectedRow}
        onSelect={handleSelectRow}
        onEnter={handleSelectRow}
        data={data}
      />
    </Container>
  )
}

export default React.memo(BindHNPage)