import * as ImagingOrderI from "./ImagingOrder";
import * as ImagingWorkListI from "./ImagingWorkList";
import * as ImagingResultI from "./ImagingResult";

const SequencePattern: {
  [name: string]: { [flavor: string]: { [func: string]: any } };
} = {
  ImagingOrder: {
    default: {
      START: ImagingOrderI.GetMaster,
      Action: ImagingOrderI.Action,
    },
  },
  ImagingWorkList: {
    default: {
      START: ImagingWorkListI.GetMaster,
      Action: ImagingWorkListI.Action,
    },
  },
  ImagingResult: {
    default: {
      START: ImagingResultI.GetMaster,
      Action: ImagingResultI.Action,
    },
  },
};

export default SequencePattern;
