import BLBService from "../services/BLBService";
import { to } from "../../utils";
export default class BLBManager {
  apiToken?: string | null;

  constructor(apiToken: string | null = null) {
    this.apiToken = apiToken
  }

  getBloodGroupWithPatient = async (patientId: number) => {
    const blbService = new BLBService(this.apiToken);
    const [error, response, network] = await to(
      blbService.getBloodGroupWithPatient(patientId)
    );
    return [response, error, network];
  };
  getBloodTransfusion = async (params: any) => {
    const blbService = new BLBService(this.apiToken);
    const [error, response, network] = await to(
      blbService.getBloodTransfusion({ params })
    );
    return [response, error, network];
  };
}
