import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Icon,
  FormGroup,
  FormField,
  Button,
  Label
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const ModStockSelectLotNoUX = (props: any) => {
    return(
      <div
        style={{width:"100%", padding: "10px",  height: "100%"}}>
        <div
          style={{backgroundColor: "var(--primary-theme-color,#5DBCD2)",margin: "-0.75rem -0.75rem -1rem", padding: "1rem", display: "flex", justifyContent: "space-between"}}>
          
          <div
            style={{fontSize: "1.1rem", fontWeight: "bold", padding: "2.5px 0", color: "white"}}>
            Lot no./Expiry date
          </div>
          <div
            style={{cursor: "pointer", color: "white", marginTop: "2.5px"}}>
            
            <Icon
              className="white"
              name="close"
              onClick={props.onClose}>
            </Icon>
          </div>
        </div>
        <div
          className="ui divider">
          
        </div>
        <div
          className="ui form">
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label
                style={{marginLeft: "0.85rem",color: "rgba(0,0,0,0.87)"}}>
                {props.name || "-"}
              </label>
            </FormField>
            <FormField
              style={{flex: 1}}>
            </FormField>
            <FormField>
              <Label
                style={{backgroundColor: "#C3F4FF", color: "rgba(0,0,0,.87)"}}>
                
                <label
                  style={{marginRight: "1rem"}}>
                  Store :
                </label>
                <label>
                  {props.storageName || "-"}
                </label>
              </Label>
            </FormField>
          </FormGroup>
        </div>
        <div>
          
          <Table
            data={props.lotItems|| []}
            getTrProps={props.getTrProps}
            headers=",Lot No.,Expiry date,Balance"
            keys="check,lot_no,expire_date,balance"
            minRows={1}
            showPagination={false}
            style={{maxHeight: "300px", marginBottom: "1rem"}}
            widths="70,,,150">
          </Table>
        </div>
        <div
          style={{margin: "2rem 0 0.5rem", display: "flex", justifyContent: "center"}}>
          
          <Button
            color="green"
            disabled={props.disabledConfirm || false}
            onClick={props.onConfirm}
            style={{width: "130px", margin: "0 2rem"}}>
            ยืนยัน
          </Button>
          <Button
            color="red"
            onClick={props.onCancel}
            style={{width: "130px", margin: "0 2rem"}}>
            ยกเลิก
          </Button>
        </div>
      </div>
    )
}

ModStockSelectLotNoUX.displayName = "ModStockSelectLotNoUX";
export default React.memo(ModStockSelectLotNoUX)

export const screenPropsDefault = {}

/* Date Time : Wed May 31 2023 02:51:48 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width:\"100%\", padding: \"10px\",  height: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"var(--primary-theme-color,#5DBCD2)\",margin: \"-0.75rem -0.75rem -1rem\", padding: \"1rem\", display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Lot no./Expiry date"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.1rem\", fontWeight: \"bold\", padding: \"2.5px 0\", color: \"white\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 122,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{cursor: \"pointer\", color: \"white\", marginTop: \"2.5px\"}"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 123,
      "name": "Icon",
      "parent": 122,
      "props": {
        "className": {
          "type": "value",
          "value": "white"
        },
        "name": {
          "type": "value",
          "value": "close"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": null,
      "id": 132,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 133,
      "name": "FormGroup",
      "parent": 132,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 133,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 134,
      "name": "FormField",
      "parent": 133,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 134,
      "void": false
    },
    {
      "from": null,
      "id": 135,
      "name": "label",
      "parent": 134,
      "props": {
        "children": {
          "type": "code",
          "value": "props.name || \"-\""
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"0.85rem\",color: \"rgba(0,0,0,0.87)\"}"
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": null,
      "id": 136,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 137,
      "name": "Table",
      "parent": 136,
      "props": {
        "data": {
          "type": "code",
          "value": "props.lotItems|| []"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getTrProps"
        },
        "headers": {
          "type": "value",
          "value": ",Lot No.,Expiry date,Balance"
        },
        "keys": {
          "type": "value",
          "value": "check,lot_no,expire_date,balance"
        },
        "minRows": {
          "type": "code",
          "value": "1"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{maxHeight: \"300px\", marginBottom: \"1rem\"}"
        },
        "widths": {
          "type": "value",
          "value": "70,,,150"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": null,
      "id": 138,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{margin: \"2rem 0 0.5rem\", display: \"flex\", justifyContent: \"center\"}"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 140,
      "name": "Button",
      "parent": 138,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิก"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancel"
        },
        "style": {
          "type": "code",
          "value": "{width: \"130px\", margin: \"0 2rem\"}"
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 142,
      "name": "FormField",
      "parent": 133,
      "props": {
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 143,
      "name": "FormField",
      "parent": 133,
      "props": {
      },
      "seq": 143,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 144,
      "name": "Label",
      "parent": 143,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#C3F4FF\", color: \"rgba(0,0,0,.87)\"}"
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": null,
      "id": 145,
      "name": "label",
      "parent": 144,
      "props": {
        "children": {
          "type": "value",
          "value": "Store :"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"1rem\"}"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": null,
      "id": 146,
      "name": "label",
      "parent": 144,
      "props": {
        "children": {
          "type": "code",
          "value": "props.storageName || \"-\""
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 147,
      "name": "Button",
      "parent": 138,
      "props": {
        "children": {
          "type": "value",
          "value": "ยืนยัน"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledConfirm || false"
        },
        "onClick": {
          "type": "code",
          "value": "props.onConfirm"
        },
        "style": {
          "type": "code",
          "value": "{width: \"130px\", margin: \"0 2rem\"}"
        }
      },
      "seq": 141,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": true,
  "name": "ModStockSelectLotNoUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
