
import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  FormGroup,
  FormField,
  Input,
  Button,
  Dropdown
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardDrugResuscitationUX = (props: any) => {
    return(
      <div
        style={{margin: "10px", backgroundColor: "#F3F3F3", height:"90%"}}>
        <div
          className="ui form"
          style={{ padding: "15px"}}>
          
          <FormGroup
            inline="true">
            <FormField
              inline="true"
              width="3">
              <label
                style={{minWidth: "fit-content"}}>
                เลขที่ใบจัดกล่อง
              </label>
              <Input
                icon="search"
                name="filterBox.code"
                onChange={props.handleChangeData}
                style={{width:"100%"}}
                value={props.DrugResuscitationSequence?.filterBox?.code || ""}>
              </Input>
            </FormField>
            <FormField
              inline="true"
              style={{ marginLeft: "10px" }}
              width="5">
              <div
                style={{display: "flex",width:"100%", gap:"10px",alignItems:"center"}}>
                
                <label
                  style={{minWidth: "fit-content",  fontWeight:"bold",fontSize:"13px"}}>
                  วันที่สร้างกล่องยา
                </label>
                <DateTextBox
                  onChange={props.handleChangeStartDate}
                  value={props.DrugResuscitationSequence?.filterBox?.created_start || ""}>
                </DateTextBox>
                <label
                  style={{minWidth: "fit-content",  fontWeight: "bold",fontSize:"13px"}}>
                  ถึง
                </label>
                <DateTextBox
                  onChange={props.handleChangeEndDate}
                  value={props.DrugResuscitationSequence?.filterBox?.created_end || ""}>
                </DateTextBox>
              </div>
            </FormField>
            <FormField
              inline="true"
              style={{ marginLeft: "5px" }}
              width="3">
              <label
                style={{minWidth: "fit-content"}}>
                เลขที่กล่อง
              </label>
              <Input
                name="filterBox.container_code"
                onChange={props.handleChangeData}
                style={{width:"100%"}}
                value={props.DrugResuscitationSequence?.filterBox?.container_code || ""}>
              </Input>
            </FormField>
            <FormField
              inline="true"
              style={{ display: "flex", justifyContent: "flex-end" }}
              width="5">
              <div>
                {props.buttonSearchBox}
              </div>
              <Button
                color="blue"
                onClick={props.addNewClick}
                size="small"
                style={{display: props.isNurseScreen ? "none" : "flex", margin: "0px 4px 0px 5px"}}>
                เพิ่มใบจัดกล่องยา
              </Button>
            </FormField>
          </FormGroup>
          <FormGroup
            inline="true">
            <FormField
              inline="true"
              width="3">
              <label
                style={{minWidth: "fit-content"}}>
                ชื่อกล่องยา
              </label>
              <Input
                name="filterBox.container_name"
                onChange={props.handleChangeData}
                style={{width:"100%"}}
                value={props.DrugResuscitationSequence?.filterBox?.container_name || ""}>
              </Input>
            </FormField>
            <FormField
              inline="true"
              width="3">
              <label
                style={{minWidth: "fit-content"}}>
                สถานะ
              </label>
              <Dropdown
                clearable={true}
                name="filterBox.status"
                onChange={props.handleChangeData}
                options={props.statusTypeItems || []}
                search={true}
                selection={true}
                style={{width:"100%"}}
                value={props.DrugResuscitationSequence?.filterBox?.status || ""}>
              </Dropdown>
            </FormField>
          </FormGroup>
        </div>
        <div
          style={{ height: "20%", position: "relative", padding: "15px" }}>
          
          <Table
            columns={props.columnsBoxItem}
            data={props?.boxItems}
            getTrProps={props.boxTableRowProps}
            showPagination={false}
            style={{fontSize: "medium", height: "20vh"}}>
          </Table>
        </div>
        <div
          style={{ height: "50%", position: "relative", padding: "15px" }}>
          {props.container}
        </div>
      </div>
    )
}


export default CardDrugResuscitationUX

export const screenPropsDefault = {}

/* Date Time : Thu Jan 18 2024 05:28:40 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{margin: \"10px\", backgroundColor: \"#F3F3F3\", height:\"90%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ height: \"20%\", position: \"relative\", padding: \"15px\" }"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 15,
      "name": "Table",
      "parent": 14,
      "props": {
        "columns": {
          "type": "code",
          "value": "props.columnsBoxItem"
        },
        "data": {
          "type": "code",
          "value": "props?.boxItems"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.boxTableRowProps"
        },
        "headers": {
          "type": "value",
          "value": ""
        },
        "keys": {
          "type": "value",
          "value": ""
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"medium\", height: \"20vh\"}"
        },
        "widths": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.container"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"50%\", position: \"relative\", padding: \"15px\" }"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"15px\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "FormGroup",
      "parent": 40,
      "props": {
        "inline": {
          "type": "value",
          "value": "true"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "FormGroup",
      "parent": 40,
      "props": {
        "inline": {
          "type": "value",
          "value": "true"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "FormField",
      "parent": 41,
      "props": {
        "inline": {
          "type": "value",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "3"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "FormField",
      "parent": 41,
      "props": {
        "inline": {
          "type": "value",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"10px\" }"
        },
        "width": {
          "type": "value",
          "value": "5"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "FormField",
      "parent": 41,
      "props": {
        "inline": {
          "type": "value",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"5px\" }"
        },
        "width": {
          "type": "value",
          "value": "3"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "label",
      "parent": 43,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขที่ใบจัดกล่อง"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"fit-content\"}"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "Input",
      "parent": 43,
      "props": {
        "icon": {
          "type": "value",
          "value": "search"
        },
        "name": {
          "type": "value",
          "value": "filterBox.code"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.DrugResuscitationSequence?.filterBox?.code || \"\""
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "label",
      "parent": 66,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่สร้างกล่องยา"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"fit-content\",  fontWeight:\"bold\",fontSize:\"13px\"}"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 50,
      "name": "DateTextBox",
      "parent": 66,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeStartDate"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.DrugResuscitationSequence?.filterBox?.created_start || \"\""
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "label",
      "parent": 45,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขที่กล่อง"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"fit-content\"}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "Input",
      "parent": 45,
      "props": {
        "name": {
          "type": "value",
          "value": "filterBox.container_code"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.DrugResuscitationSequence?.filterBox?.container_code || \"\""
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "label",
      "parent": 66,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"fit-content\",  fontWeight: \"bold\",fontSize:\"13px\"}"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 56,
      "name": "DateTextBox",
      "parent": 66,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeEndDate"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.DrugResuscitationSequence?.filterBox?.created_end || \"\""
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "FormField",
      "parent": 41,
      "props": {
        "inline": {
          "type": "value",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"flex-end\" }"
        },
        "width": {
          "type": "value",
          "value": "5"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 57,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearchBox"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "Button",
      "parent": 57,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มใบจัดกล่องยา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.addNewClick"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{display: props.isNurseScreen ? \"none\" : \"flex\", margin: \"0px 4px 0px 5px\"}"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "FormField",
      "parent": 42,
      "props": {
        "inline": {
          "type": "value",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "3"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "FormField",
      "parent": 42,
      "props": {
        "inline": {
          "type": "value",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "3"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "label",
      "parent": 60,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อกล่องยา"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"fit-content\"}"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "label",
      "parent": 61,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะ"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"fit-content\"}"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "Input",
      "parent": 60,
      "props": {
        "name": {
          "type": "value",
          "value": "filterBox.container_name"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.DrugResuscitationSequence?.filterBox?.container_name || \"\""
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "Dropdown",
      "parent": 61,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "filterBox.status"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.statusTypeItems || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.DrugResuscitationSequence?.filterBox?.status || \"\""
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": null,
      "id": 66,
      "name": "div",
      "parent": 44,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",width:\"100%\", gap:\"10px\",alignItems:\"center\"}"
        }
      },
      "seq": 66,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 85,
  "isMounted": false,
  "memo": false,
  "name": "CardDrugResuscitationUx",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
