import React, { useRef, useState } from "react";
import { Input, Dropdown, Button, Segment, Form } from "semantic-ui-react";
import _ from "react-lib/compat/lodashplus";

import CardLabResult from "../LAB/CardLabResult";
import CardImagingExamResult from "../PHV/CardImagingExamResult";
import { useIntl } from "react-intl";

export interface PTLabImaingExamDataType {
  division: number;
}

export const PTLabImaingExamInitialViewData: PTLabImaingExamDataType = {
  division: 0,
};

const labImagingOptions = [
  { key: "Laboratory", value: "Laboratory", text: "Laboratory" },
  { key: "Imaging Exam", value: "Imaging Exam", text: "Imaging Exam" },
];

const CardPTLabImagingExam = (props: any) => {
  const intl = useIntl();
  const [selectType, setSelectType] = useState<string | null>("Laboratory");
  const [labCode, setLabCode] = useState({});
  const [labDetail, setLabDetail] = useState({});
  const [reportDetail, setReportDetail] = useState({});
  const [labError, setLabError] = useState({});

  const [imagingExamResultLoading, setImagingExamResultLoading] = useState(
    false
  );
  const [imagingExamResult, setImagingExamResult] = useState({});

  /**
   * Lab Result
   */

  const handleGetLabCode = async ({ divisionList }) => {
    if (props.patientData && props.patientData.ENCOUNTER && props.patientData.ENCOUNTER.encounter_id) {
      setLabDetail({});
      // setLabLoading(true);
      // TODO: handle case datenull

      let params = {};
      if (props.patientData.patient_id) {
        params.patient = props.patientData.patient_id;
      }
      if (divisionList) {
        params.lab_division = divisionList;
      }
      const [
        division,
        divisionError,
      ] = await props.cardLabResultController.getCenterLabDivision();
      const [
        filterResult,
        filterResultError,
      ] = await props.cardLabResultController.getCentalLabTestResultFitlerList(
        params
      );

      setLabCode({
        division: division,
        filterResult: filterResult,
      });
    }
  };

  const handleGetLabDetail = async ({
    LabCode,
    startDate,
    stopDate,
    username,
    password,
  }) => {
    if (props.patientData.ENCOUNTER.encounter_id) {
      setLabDetail({});
      // setLabLoading(true);
      // TODO: handle case datenull

      // const [labDetail, labDetailError] = await props.getLabDetail({

      let params = {
        columns: [],
        items: [],
      };

      if (startDate) {
        params.from_date = startDate;
      }
      if (stopDate) {
        params.to_date = stopDate;
      }
      if (LabCode) {
        params.products = LabCode;
      }
      if (props.patientData.patient_id) {
        params.patient = props.patientData.patient_id;
      }
      if (username) {
        params.username = username;
      }
      if (password) {
        params.password = password;
      }
      const [
        centralLabResultComparable,
        centralLabResultComparableError,
      ] = await props.cardLabResultController.getCentalLabTestResultComparable(
        params
      );

      if (
        centralLabResultComparable &&
        !_.isEmpty(centralLabResultComparable)
      ) {
        setLabDetail({ labCompare: centralLabResultComparable });
      } else {
        setLabError(centralLabResultComparableError);
      }

      // TODO: Handle medError
      // setLabLoading(false);
    }
  };

  const handleGetInterfaceSummaryReportDetail = async (params) => {
    const { cloi } = params; // Central Lab Order Id
    if (cloi) {
      // setLabLoading(true);
      let params = {
        cloi: cloi,
      };
      const [
        data,
        error,
      ] = await props.cardLabResultController.getInterfaceSummaryReportDetail(
        params
      );

      setReportDetail(data);
      // setLabLoading(false);
    }
  };

  /**
   *  Imaging Exam
   */

  // ImagingExamResult
  const handleGetImagingExamResult = async (params) => {
    if (props.patientData && props.patientData.ENCOUNTER && props.patientData.ENCOUNTER.encounter_id) {
      setImagingExamResultLoading(true);

      let params = { encounterId: props.patientData.ENCOUNTER.encounter_id }
      const [
        medicationData,
        medicationError,
      ] = await props.cardImagingExamResultController.getImagingExamResult(params);

      // TODO: Handle medError
      setImagingExamResult(medicationData.items);
      setImagingExamResultLoading(false);
    }
  };

  const handleOpenPACS = async ({ hnPatient }) => {
    let url =
      "http://pacscnmi4/explore.asp?path=/All%20Patients/InternalPatientUID=" +
      hnPatient;
    window.open(url, "_blank");
  };

  let content = (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          margin: 10,
        }}
      >
        <label style={{ fontWeight: "bold" }}> View&nbsp; </label>
        <Dropdown
          value={selectType}
          selection
          options={labImagingOptions}
          onChange={(e, data) => {
            setSelectType(data.value as string);
          }}
        />
      </div>
      <br />
      {selectType === "Laboratory" ? (
        <CardLabResult
          style={{ marginBottom: "10px" }}
          closeable={false}
          toggleable={false}
          hideHeaderIcon={true}
          labCode={labCode}
          labDetail={labDetail}
          reportDetail={reportDetail}
          labError={labError}
          getLabCode={handleGetLabCode}
          getLabDetail={handleGetLabDetail}
          getInterfaceSummaryReportDetail={
            handleGetInterfaceSummaryReportDetail
          }
          patientId={props.patientInfo ? parseInt(props.patientInfo.id) : null}
          patientInfo={props.patientInfo}
          languageUX={props.languageUX}
        />
      ) : (
        <CardImagingExamResult
          closeable={false}
          toggleable={false}
          hideHeaderIcon={true}
          imagingExameResult={imagingExamResult}
          getImagingExamResult={handleGetImagingExamResult}
          isLoading={imagingExamResultLoading}
          onOpenPACS={() => handleOpenPACS({ hnPatient: null })}
          languageUX={props.languageUX}
        />
      )}
    </>
  );
  return content;
};

export default CardPTLabImagingExam;
