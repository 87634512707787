import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Button,
  Dropdown
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardReceiptGroupSettingUX = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div>
        <div
          style={{boxShadow: "none", padding: "10px"}}>
          
          <div
            className="ui form small">
            {}
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={2}>
                <div
                  style={{fontWeight: "bold", fontSize: "1.2rem", display: "flex", justifyContent: "space-between"}}>
                  ตั้งค่าชุดใบเสร็จ
                </div>
              </FormField>
              <FormField
                inline={true}
                style={{ display: "flex", justifyContent: "center" }}
                width={2}>
                <div>
                  จุดรับชำระเงิน (Device)
                </div>
              </FormField>
              <FormField
                inline={true}
                width="2">
                <div
                  style={{ display: "flex", background: "#F1F1F1", padding: "5px", borderRadius: 4,  width: "100%", justifyContent: "center" }}>
                  {props.stationName?.name || "-"}
                </div>
              </FormField>
              <FormField
                inline={true}
                style={{ display: "flex", justifyContent: "center" }}
                width={2}>
                <div>
                  หมายเลขจุดรับเงิน
                </div>
              </FormField>
              <FormField
                inline={true}
                width={1}>
                <div
                  style={{ display: "flex", background: "#F1F1F1", padding: "5px", borderRadius: 4,  width: "100%", justifyContent: "center" }}>
                  {props.stationName?.number || "-"}
                </div>
              </FormField>
              <FormField
                inline={true}
                style={{ display: "flex", justifyContent: "center" }}
                width={2}>
                <div>
                  ชื่อจุดรับเงิน
                </div>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <div
                  style={{ display: "flex", background: "#F1F1F1", padding: "5px", borderRadius: 4,  width: "100%", justifyContent: "center" }}>
                  {props.stationName?.station_name || "-"}
                </div>
              </FormField>
              <FormField
                inline={true}
                style={{ display: "flex", justifyContent: "flex-end" }}
                width={3}>
                <Button
                  color="blue"
                  onClick={props.openModReceiptEdit}
                  size="mini">
                  เพิ่มชุดใบเสร็จรับเงิน
                </Button>
              </FormField>
            </FormGroup>
            <div
              style={{ padding: "0px 0px 15px"}}>
              
              <hr>
              </hr>
            </div>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={1}>
                <div>
                  ปีงบประมาณ
                </div>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <div
                  style={{ width: "100%" }}>
                  
                  <Dropdown
                    clearable={true}
                    fluid={true}
                    name="budgetYear"
                    onChange={props.onChangeDataSearch}
                    options={props.yearOptions}
                    search={true}
                    selection={true}
                    value={props.searchData?.budgetYear}>
                  </Dropdown>
                </div>
              </FormField>
              <FormField
                inline={true}
                style={{ display: "flex", justifyContent: "center" }}
                width={1}>
                <div>
                  สถานะ
                </div>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <div
                  style={{ width: "100%" }}>
                  
                  <Dropdown
                    fluid={true}
                    name="status"
                    onChange={props.onChangeDataSearch}
                    options={props.receiptGroupStatus}
                    search={true}
                    selection={true}
                    value={props.searchData?.status}>
                  </Dropdown>
                </div>
              </FormField>
              <FormField
                inline={true}
                style={{ display: "flex", justifyContent: "center" }}
                width={2}>
                <div>
                  วันเบิกใบเสร็จ
                </div>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <div
                  style={{ width: "100%" }}>
                  
                  <DateTextBox
                    onChange={props.onChangeDateSearch}
                    value={props.searchData?.date}>
                  </DateTextBox>
                </div>
              </FormField>
              <FormField
                inline={true}
                style={{ display: "flex", justifyContent: "flex-end" }}
                width={2}>
                <div>
                  {props.buttonSearch}
                </div>
              </FormField>
            </FormGroup>
          </div>
          <div>
            
          </div>
          <div>
            
            <Table
              data={props.receiptGroupData}
              headers="เปิดใช้งานได้,ปีงบประมาณ,เล่มที่,เลขที่เริ่มต้น,เลขที่สิ้นสุด,เลขที่กำลังใช้งาน,ผู้เบิกใบเสร็จ,วันเวลาที่เบิกใบเสร็จ,สถานะ,"
              id="tb-bilQueueReq"
              keys="isOn,year,prefix,running_start,running_end,current_running,user,date,status,action"
              minRows="10"
              style={{height: "65vh"}}>
            </Table>
          </div>
        </div>
      </div>
    )
}


export default CardReceiptGroupSettingUX

export const screenPropsDefault = {}

/* Date Time : Wed Oct 16 2024 11:59:48 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{boxShadow: \"none\", padding: \"10px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form small"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "FormGroup",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 32,
      "name": "Table",
      "parent": 31,
      "props": {
        "data": {
          "type": "code",
          "value": "props.receiptGroupData"
        },
        "defaultPageSize": {
          "type": "value",
          "value": ""
        },
        "getTdProps": {
          "type": "code",
          "value": ""
        },
        "getTrProps": {
          "type": "code",
          "value": ""
        },
        "headers": {
          "type": "value",
          "value": "เปิดใช้งานได้,ปีงบประมาณ,เล่มที่,เลขที่เริ่มต้น,เลขที่สิ้นสุด,เลขที่กำลังใช้งาน,ผู้เบิกใบเสร็จ,วันเวลาที่เบิกใบเสร็จ,สถานะ,"
        },
        "id": {
          "type": "value",
          "value": "tb-bilQueueReq"
        },
        "keys": {
          "type": "value",
          "value": "isOn,year,prefix,running_start,running_end,current_running,user,date,status,action"
        },
        "minRows": {
          "type": "value",
          "value": "10"
        },
        "pageSize": {
          "type": "value",
          "value": ""
        },
        "showPagination": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height: \"65vh\"}"
        },
        "widths": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\" }"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "value",
          "value": "2"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\" }"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "div",
      "parent": 48,
      "props": {
        "children": {
          "type": "code",
          "value": "props.stationName?.number || \"-\""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", background: \"#F1F1F1\", padding: \"5px\", borderRadius: 4,  width: \"100%\", justifyContent: \"center\" }"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "div",
      "parent": 46,
      "props": {
        "children": {
          "type": "value",
          "value": "หมายเลขจุดรับเงิน"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 45,
      "props": {
        "children": {
          "type": "code",
          "value": "props.stationName?.name || \"-\""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", background: \"#F1F1F1\", padding: \"5px\", borderRadius: 4,  width: \"100%\", justifyContent: \"center\" }"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "div",
      "parent": 44,
      "props": {
        "children": {
          "type": "value",
          "value": "จุดรับชำระเงิน (Device)"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 43,
      "props": {
        "children": {
          "type": "value",
          "value": "ตั้งค่าชุดใบเสร็จ"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.2rem\", display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\" }"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"flex-end\" }"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "div",
      "parent": 55,
      "props": {
        "children": {
          "type": "code",
          "value": "props.stationName?.station_name || \"-\""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", background: \"#F1F1F1\", padding: \"5px\", borderRadius: 4,  width: \"100%\", justifyContent: \"center\" }"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "div",
      "parent": 54,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อจุดรับเงิน"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "Button",
      "parent": 57,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มชุดใบเสร็จรับเงิน"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.openModReceiptEdit"
        },
        "size": {
          "type": "value",
          "value": "mini"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "FormGroup",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "FormField",
      "parent": 66,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "FormField",
      "parent": 66,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "FormField",
      "parent": 66,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\" }"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "FormField",
      "parent": 66,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "FormField",
      "parent": 66,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\" }"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "FormField",
      "parent": 66,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "FormField",
      "parent": 66,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"flex-end\" }"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "div",
      "parent": 67,
      "props": {
        "children": {
          "type": "value",
          "value": "ปีงบประมาณ"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "div",
      "parent": 69,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะ"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "div",
      "parent": 71,
      "props": {
        "children": {
          "type": "value",
          "value": "วันเบิกใบเสร็จ"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "div",
      "parent": 72,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 81,
      "name": "DateTextBox",
      "parent": 77,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeDateSearch"
        },
        "value": {
          "type": "code",
          "value": "props.searchData?.date"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"0px 0px 15px\"}"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "hr",
      "parent": 82,
      "props": {
      },
      "seq": 83,
      "void": true
    },
    {
      "from": null,
      "id": 84,
      "name": "div",
      "parent": 68,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "div",
      "parent": 70,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 86,
      "name": "Dropdown",
      "parent": 84,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "budgetYear"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeDataSearch"
        },
        "options": {
          "type": "code",
          "value": "props.yearOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.searchData?.budgetYear"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 87,
      "name": "Dropdown",
      "parent": 85,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "status"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeDataSearch"
        },
        "options": {
          "type": "code",
          "value": "props.receiptGroupStatus"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.searchData?.status"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 73,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearch"
        }
      },
      "seq": 88,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "CardReceiptGroupSettingUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
