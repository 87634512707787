import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Segment,
  Form,
  FormGroup,
  FormField,
  Input,
  Label
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardDrugPickingOrderUX = (props: any) => {
    return(
      <div
        style={{ width : "100%", height: "100%" }}>
        <Segment
          className="segment-no-shadow">
          <div
            style={{display: "flex", justifyContent: "space-between"}}>
            
            <div
              style={{fontWeight: "bold", fontSize: "1.15rem"}}>
              รายการยาเพิ่มเติมภายในหน่วยตรวจ
            </div>
            <div
              style={{cursor: "pointer"}}>
              
            </div>
          </div>
          <Form>
            <FormGroup
              inline={true}
              style={{marginTop: "1.25rem"}}>
              <FormField
                inline={true}
                width={6}>
                <label
                  style={{minWidth: "max-content"}}>
                  ชื่อหรือรหัสสินค้า
                </label>
                <Input
                  fluid={true}
                  name="keyword"
                  onChange={props.onChangeFilter}
                  size="small"
                  value={props.filter?.keyword || ""}>
                </Input>
              </FormField>
              <FormField
                inline={true}>
                <div>
                  {props.ButtonSearch}
                </div>
              </FormField>
            </FormGroup>
          </Form>
          <div>
            
            <Table
              data={props.drugPickingData || []}
              headers=",Generic name,Drug name,Form,Strength/Contain"
              keys="_add,generic_name,name,dosage_form_name,size"
              minRows={5}
              showPagination={false}
              style={{height: "210px"}}
              widths="70,^200,^175,^100,^100">
            </Table>
          </div>
          <div
            className="ui divider"
            style={{margin:"1.25rem 0"}}>
            
          </div>
          <div>
            {props.TabPans}
          </div>
          <div
            style={{display: props.selectedPickingOrder?.id?"flex":"none", alignItems: "center", margin: "0.75rem 0 -0.5rem"}}>
            
            <Label
              color="black">
              {`PICKING: ${props.selectedPickingOrder?.code || "-"}`}
            </Label>
            <div
              style={{flex: 1}}>
              
            </div>
            <Label
              style={{minWidth: "6rem", textAlign: "center", backgroundColor: props.statusColor, color: "white" }}>
              {`${props.selectedPickingOrder?.status_label || "-"}`}
            </Label>
          </div>
          <div
            style={{marginTop: "1rem"}}>
            
            <Table
              data={props.drugPickingOrderItemsData || []}
              headers="Drug name,Dose,Unit,Route,Site,Method,Qty,"
              keys="name,dose,code_unit,code_route,code_site,code_method,quantity,_remove"
              minRows={5}
              showPagination={false}
              style={{height: "210px"}}
              widths="^175,^80,^100,^100,^100,^100,^80,70">
            </Table>
          </div>
          <div
            style={{display: "flex", justifyContent: "flex-end", marginTop: "1rem"}}>
            
            <div>
              {props.ButtonSave}
            </div>
          </div>
        </Segment>
      </div>
    )
}


export default CardDrugPickingOrderUX

export const screenPropsDefault = {"cannotPay":false,"cashAmountText":"14","citizenID":"31020000552","oweText":123,"patientNameAndHN":"HN: xxxxx นายทดสอบ","sumAmount":{"price":100,"reimbursable":42}}

/* Date Time : Fri Mar 22 2024 18:20:58 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width : \"100%\", height: \"100%\" }"
        }
      },
      "seq": 0
    },
    {
      "from": "semantic-ui-react",
      "id": 1,
      "name": "Segment",
      "parent": 0,
      "props": {
        "className": {
          "type": "value",
          "value": "segment-no-shadow"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการยาเพิ่มเติมภายในหน่วยตรวจ"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.15rem\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{cursor: \"pointer\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "Form",
      "parent": 1,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "FormGroup",
      "parent": 13,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"1.25rem\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "FormField",
      "parent": 14,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "FormField",
      "parent": 14,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "label",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อหรือรหัสสินค้า"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "Input",
      "parent": 15,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "keyword"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "value": {
          "type": "code",
          "value": "props.filter?.keyword || \"\""
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 21,
      "name": "Table",
      "parent": 20,
      "props": {
        "data": {
          "type": "code",
          "value": "props.drugPickingData || []"
        },
        "headers": {
          "type": "value",
          "value": ",Generic name,Drug name,Form,Strength/Contain"
        },
        "keys": {
          "type": "value",
          "value": "_add,generic_name,name,dosage_form_name,size"
        },
        "minRows": {
          "type": "code",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"210px\"}"
        },
        "widths": {
          "type": "value",
          "value": "70,^200,^175,^100,^100"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSearch"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        },
        "style": {
          "type": "code",
          "value": "{margin:\"1.25rem 0\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.TabPans"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"1rem\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 26,
      "name": "Table",
      "parent": 25,
      "props": {
        "data": {
          "type": "code",
          "value": "props.drugPickingOrderItemsData || []"
        },
        "headers": {
          "type": "value",
          "value": "Drug name,Dose,Unit,Route,Site,Method,Qty,"
        },
        "keys": {
          "type": "value",
          "value": "name,dose,code_unit,code_route,code_site,code_method,quantity,_remove"
        },
        "minRows": {
          "type": "code",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"210px\"}"
        },
        "widths": {
          "type": "value",
          "value": "^175,^80,^100,^100,^100,^100,^80,70"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", marginTop: \"1rem\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 27,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSave"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.selectedPickingOrder?.id?\"flex\":\"none\", alignItems: \"center\", margin: \"0.75rem 0 -0.5rem\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "Label",
      "parent": 29,
      "props": {
        "children": {
          "type": "code",
          "value": "`PICKING: ${props.selectedPickingOrder?.code || \"-\"}`"
        },
        "color": {
          "type": "value",
          "value": "black"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "div",
      "parent": 29,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "Label",
      "parent": 29,
      "props": {
        "children": {
          "type": "code",
          "value": "`${props.selectedPickingOrder?.status_label || \"-\"}`"
        },
        "color": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"6rem\", textAlign: \"center\", backgroundColor: props.statusColor, color: \"white\" }"
        }
      },
      "seq": 32,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardDrugPickingOrderUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "cannotPay": false,
    "cashAmountText": "14",
    "citizenID": "31020000552",
    "oweText": 123,
    "patientNameAndHN": "HN: xxxxx นายทดสอบ",
    "sumAmount": {
      "price": 100,
      "reimbursable": 42
    }
  },
  "width": 75
}

*********************************************************************************** */
