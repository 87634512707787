import INFService from "../services/INFService";
import { to, formatDateToYYYYMMDD, downloadFile } from "../../utils";
import _ from "../../compat/lodashplus";
export default class INFManager {
  /**
   * Get sent claim choices
   */
  getSentClaimChoices = async ({ field } = {}) => {
    var dataList = [];
    var params = {};
    if (field) {
      params.field = field;
    }
    const infService = new INFService();
    const [error, response, network] = await to(
      infService.getSentClaimChoices(params)
    );
    if (!error) {
      dataList = _.map(response.items, item => ({
        id: item.id,
        text: item.label ? item.label : item.value,
        value: item.value
      }));
    }
    return [dataList, error, network];
  };
  getChoicesAdmitSource = async () => {
    return await this.getSentClaimChoices({ field: "admit_source" });
  };
  getChoicesPreDischargeType = async () => {
    return await this.getSentClaimChoices({ field: "predischarge_condition" });
  };
  getChoicesDischargeType = async () => {
    return await this.getSentClaimChoices({ field: "discharge_type" });
  };
  getChoicesDivision = async () => {
    return await this.getSentClaimChoices({ field: "division" });
  };
  getChoicesSentClaimStatus = async () => {
    return await this.getSentClaimChoices({ field: "status" });
  };
  /**
   * Create AR Transaction
   */
  createARTransaction = async ({
    startDate,
    endDate,
    coverageGroupId
  } = {}) => {
    var data = {};
    // Convert date
    if (startDate) {
      data.start_date = formatDateToYYYYMMDD(startDate);
    }
    if (endDate) {
      data.end_date = formatDateToYYYYMMDD(endDate);
    }
    if (coverageGroupId) {
      data.coverage_payer_sent_claim_group = coverageGroupId;
    }
    const infService = new INFService();
    const [error, response, network] = await to(
      infService.postCreateARTransaction(data, {})
    );
    return [response, error, network];
  };
  /**
   * Get AR Transaction list
   */
  getARTransaction = async ({
    invoiceDate,
    lotNo,
    fiscalYear,
    onlyContainFailed,
    groupType,
    page,
    pageSize
  } = {}) => {
    var dataList = [];
    var params = {};
    if (page) {
      params.page = page;
    }
    if (pageSize) {
      params.page_size = pageSize;
    }
    if (invoiceDate) {
      params.invoice_date = invoiceDate;
    }
    if (lotNo) {
      params.lot_no = lotNo;
    }
    if (fiscalYear) {
      params.fiscal_year = fiscalYear;
    }
    if (onlyContainFailed) {
      params.only_contain_failed = onlyContainFailed;
    }
    if (groupType) {
      params.group_type = groupType;
    }
    const infService = new INFService();
    const [error, response, network] = await to(
      infService.getARTransaction(params)
    );
    return [response, error, network];
  };
  getCoveragePayerSentClaimGroup = async ({ groupType } = {}) => {
    var dataList = [];
    var params = {};
    if (groupType) {
      params.group_type = groupType;
    }
    const infService = new INFService();
    const [error, response, network] = await to(
      infService.getCoveragePayerSentClaimGroup(params)
    );
    if (response) {
      dataList = response.items;
    }
    return [dataList, error, network];
  };
  getBillTransactionIPD = async ({
    coverageGroupId,
    startDate,
    endDate,
    transactionId,
    status,
    encounter,
    page,
    pageSize
  } = {}) => {
    let params = {};
    if (page) {
      params.page = page;
    }
    if (pageSize) {
      params.page_size = pageSize;
    }
    if (coverageGroupId) {
      params.coverage_payer_sent_claim_group = coverageGroupId;
    }
    if (startDate) {
      params.start_date = startDate;
    }
    if (endDate) {
      params.end_date = endDate;
    }
    if (status) {
      params.status = status;
    }
    if (encounter) {
      params.encounter = encounter;
    }
    // Get items
    if (transactionId) {
      params.ar_transaction = transactionId;
    }
    const infService = new INFService();
    const [error, response, network] = await to(
      infService.getBillTransactionIPD(params)
    );
    return [response, error, network];
  };
  getBillTransactionIPDWithId = async transactionId => {
    const infService = new INFService();
    const [error, response, network] = await to(
      infService.getBillTransactionIPDWithId(transactionId)
    );
    return [response, error, network];
  };
  updateBillTransactionIPDWithId = async (transactionId, data) => {
    const infService = new INFService();
    const [error, response, network] = await to(
      infService.patchBillTransactionIPDWithId(transactionId, data)
    );
    return [response, error, network];
  };
  updateIPDDiagnosisSentClaimWithId = async (ipdDiagnosisId, data) => {
    const infService = new INFService();
    const [error, response, network] = await to(
      infService.patchIPDDiagnosisSentClaimWithId(ipdDiagnosisId, data)
    );
    return [response, error, network];
  };
  updateIPDProcedureSentClaimWithId = async (ipdProcedureId, data) => {
    const infService = new INFService();
    const [error, response, network] = await to(
      infService.patchIPDProcedureSentClaimWithId(ipdProcedureId, data)
    );
    return [response, error, network];
  };
  putBillTransactionUpdateStatus = async (transactionId, data) => {
    const infService = new INFService();
    const [error, response, network] = await to(
      infService.putBillTransactionUpdateStatus(transactionId, data)
    );
    return [response, error, network];
  };
  putBillTransaction = async (transactionId, data) => {
    const infService = new INFService();
    const [error, response, network] = await to(
      infService.putBillTransaction(transactionId, data)
    );
    return [response, error, network];
  };
  getBillTransactionIPDSummary = async ({
    transactionId,
    startDate,
    endDate,
    coverageGroupId,
    status,
    encounter,
    sentClaimId
  } = {}) => {
    var params = {};
    if (transactionId) {
      params.ar_transaction = transactionId;
    }
    if (startDate) {
      params.start_date = startDate;
    }
    if (endDate) {
      params.end_date = endDate;
    }
    if (coverageGroupId) {
      params.coverage_payer_sent_claim_group = coverageGroupId;
    }
    if (status) {
      params.status = status;
    }
    if (sentClaimId) {
      params.sent_claim_transaction_ipd = sentClaimId;
    }
    if (encounter) {
      params.encounter = encounter;
    }
    const infService = new INFService();
    const [error, response, network] = await to(
      infService.getBillTransactionIPDSummary(params)
    );
    return [response, error, network];
  };
  getIPDDiagnosisSentClaim = async ({ transactionId }) => {
    var params = {};
    if (transactionId) {
      params.bill_transaction_ipd = transactionId;
    }
    const infService = new INFService();
    const [error, response, network] = await to(
      infService.getIPDDiagnosisSentClaim(params)
    );
    return [response, error, network];
  };
  getIPDProcedureSentClaim = async ({ transactionId }) => {
    var params = {};
    if (transactionId) {
      params.bill_transaction_ipd = transactionId;
    }
    const infService = new INFService();
    const [error, response, network] = await to(
      infService.getIPDProcedureSentClaim(params)
    );
    return [response, error, network];
  };
  getBillTransactionIPDItem = async ({ transactionId }) => {
    var params = {};
    if (transactionId) {
      params.bill_transaction_ipd = transactionId;
    }
    const infService = new INFService();
    const [error, response, network] = await to(
      infService.getBillTransactionIPDItem(params)
    );
    return [response, error, network];
  };
  doGenerateSentClaimDataIPD = async (coverageGroupId, startDate, endDate) => {
    const infService = new INFService();
    // Convert date
    let targetStartDate = formatDateToYYYYMMDD(startDate);
    let targetEndDate = formatDateToYYYYMMDD(endDate);
    const [error, response, network] = await to(
      infService.doGenerateSentClaimDataIPD(
        coverageGroupId,
        targetStartDate,
        targetEndDate
      )
    );
    return [response, error, network];
  };
  arTaskTrackingIPDGenerateSentClaim = async ({ groupType }) => {
    var params = {};
    if (groupType) {
      params.group_type = groupType;
    }
    let action = "IPD_GENERATE_SENT_CLAIM_DATA";
    const infService = new INFService();
    const [error, response, network] = await to(
      infService.arTaskTrackingAction(action, params)
    );
    return [response, error, network];
  };
  arTaskTrackingIPDUpdateSentClaim = async ({ groupType }) => {
    var params = {};
    if (groupType) {
      params.group_type = groupType;
    }
    let action = "IPD_UPDATE_SENT_CLAIM_DATA";
    const infService = new INFService();
    const [error, response, network] = await to(
      infService.arTaskTrackingAction(action, params)
    );
    return [response, error, network];
  };
  updateSentClaimDataIPDFromARId = async transactionId => {
    const infService = new INFService();
    const [error, response, network] = await to(
      infService.getUpdateSentClaimDataIPDFromARId(transactionId)
    );
    return [response, error, network];
  };
  generateSentClaimIPDFileFromARId = async (
    transactionId,
    { encounter, sentClaimStatus }
  ) => {
    var params = {};
    if (encounter) {
      params.encounter = encounter;
    }
    if (sentClaimStatus) {
      params.status = sentClaimStatus;
    }
    const infService = new INFService();
    const [error, response, network] = await to(
      infService.getGenerateSentClaimIPDFileFromARId(transactionId, params)
    );
    if (response) {
      // Download file
      downloadFile(response);
    }
    return [response, error, network];
  };
  importResponseFileIPD = async ({ file, isSave }) => {
    const formData = new FormData();
    if (file) {
      formData.append("file", file);
    }
    if (isSave !== undefined) {
      formData.append("save", isSave);
    }
    const infService = new INFService();
    const [error, response, network] = await to(
      infService.putImportResponseFileIPD(formData)
    );
    return [response, error, network];
  };
  getSentClaimTransactionIPD = async ({ arTransactionId }) => {
    var params = {};
    if (arTransactionId) {
      params.ar_transaction = arTransactionId;
    }
    const infService = new INFService();
    const [error, response, network] = await to(
      infService.getSentClaimTransactionIPD(params)
    );
    return [response, error, network];
  };
  getEncounterFromARId = async transactionId => {
    const infService = new INFService();
    const [error, response, network] = await to(
      infService.getEncounterFromARId(transactionId)
    );
    return [response, error, network];
  };
  importARLabCatalogFile = async ({ file, isSave }) => {
    const formData = new FormData();
    if (file) {
      formData.append("file", file);
    }
    if (isSave !== undefined) {
      formData.append("save", isSave);
    }
    formData.append("type", "LAB_CATALOG")
    const infService = new INFService();
    const [error, response, network] = await to(
      infService.postImportARLABCatalogMasterData(formData)
    );
    return [response, error, network];
  };
}
