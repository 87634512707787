import WasmController from "react-lib/frameworks/WasmController";

// API
import InvoiceItemList from "issara-sdk/apis/InvoiceItemList_apps_BIL";
import ShippingAddressList from "issara-sdk/apis/ShippingAddressList_apps_PRX";

export type State = {
  selectedEncounter?: any,
  selectedPatient?: any,
  InvoicesSummarySequence?: {
    invoiceLists?: any[];
    addressLists?: any[];
  } | null;
};

export const StateInitial: State = {
  InvoicesSummarySequence: null,
};

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division: any;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const Start: Handler = async (controller, params) => {
  const state = controller.getState();
  // console.log(" InvoicesSummary  Start", state.InvoicesSummarySequence)
  if (!state.InvoicesSummarySequence) return;

  // console.log("controller.data", controller.data)

  const [inv, error] = await InvoiceItemList.list({
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division,
    },
    params: {
      encounter: state.selectedEncounter?.id,
      patient: state.selectedPatient?.id,
    },
  });

  const [address, error2] = await ShippingAddressList.list({
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division,
    },
    params: {
      encounter: state.selectedEncounter?.id,
    },
  });

  if (error) {
    console.error("error: ", error);
  }

  if (error2) {
    console.error("error: ", error2);
  }

  if (inv && address) {
    controller?.setState({
      InvoicesSummarySequence: {
        ...state.InvoicesSummarySequence,
        invoiceLists: inv?.items,
        addressLists: address?.items,
      },
    });
  }
};
