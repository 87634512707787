import React from 'react'
import {
   Checkbox,
   Dropdown,
   Input,
} from 'semantic-ui-react'



import styles from './CardOTPhysicalExaminationPhysicalDysfunctionMain.module.css'

export interface CardOTPhysicalExaminationPhysicalDysfunctionMainViewDataType {
    CardOtPhysicalDysfunctionMain3: boolean,
    CardOtPhysicalDysfunctionMain3Disabled: boolean,
    CardOtPhysicalDysfunctionMain5: boolean,
    CardOtPhysicalDysfunctionMain5Disabled: boolean,
    CardOtPhysicalDysfunctionMain6: boolean,
    CardOtPhysicalDysfunctionMain6Disabled: boolean,
    CardOtPhysicalDysfunctionMain8: boolean,
    CardOtPhysicalDysfunctionMain8Disabled: boolean,
    CardOtPhysicalDysfunctionMain10: boolean,
    CardOtPhysicalDysfunctionMain10Disabled: boolean,
    CardOtPhysicalDysfunctionMain12: boolean,
    CardOtPhysicalDysfunctionMain12Disabled: boolean,
    CardOtPhysicalDysfunctionMain14: boolean,
    CardOtPhysicalDysfunctionMain14Disabled: boolean,
    CardOtPhysicalDysfunctionMain16: boolean,
    CardOtPhysicalDysfunctionMain16Disabled: boolean,
    CardOtPhysicalDysfunctionMain21: boolean,
    CardOtPhysicalDysfunctionMain21Disabled: boolean,
    CardOtPhysicalDysfunctionMain22: boolean,
    CardOtPhysicalDysfunctionMain22Disabled: boolean,
    CardOtPhysicalDysfunctionMain23: boolean,
    CardOtPhysicalDysfunctionMain23Disabled: boolean,
    CardOtPhysicalDysfunctionMain24: boolean,
    CardOtPhysicalDysfunctionMain24Disabled: boolean,
    CardOtPhysicalDysfunctionMain28: boolean,
    CardOtPhysicalDysfunctionMain28Disabled: boolean,
    CardOtPhysicalDysfunctionMain29: string,
    CardOtPhysicalDysfunctionMain29Disabled: boolean,
    CardOtPhysicalDysfunctionMain31: boolean,
    CardOtPhysicalDysfunctionMain31Disabled: boolean,
    CardOtPhysicalDysfunctionMain33: boolean,
    CardOtPhysicalDysfunctionMain33Disabled: boolean,
    CardOtPhysicalDysfunctionMain38: string,
    CardOtPhysicalDysfunctionMain38Disabled: boolean,
    Ct2GeneralAppearance: string,
    Ct2GeneralAppearanceDisabled: boolean,
    Ct2GeneralAppearanceOptions: any,
    Ct2Consciousness: string,
    Ct2ConsciousnessDisabled: boolean,
    Ct2ConsciousnessOptions: any,
    Ct5Communication: string,
    Ct5CommunicationDisabled: boolean,
    Ct5CommunicationOptions: any,
    Ct5CoOperation: string,
    Ct5CoOperationDisabled: boolean,
    Ct5CoOperationOptions: any,
    Ct3Cognitive: string,
    Ct3CognitiveDisabled: boolean,
    Ct3CognitiveOptions: any,
    CardOtPhysicalDysfunctionMain59: string,
    CardOtPhysicalDysfunctionMain59Disabled: boolean,
    CardOtPhysicalDysfunctionMain60: boolean,
    CardOtPhysicalDysfunctionMain60Disabled: boolean,
    CardOtPhysicalDysfunctionMain61: string,
    CardOtPhysicalDysfunctionMain61Disabled: boolean,
    CardOtPhysicalDysfunctionMain63: boolean,
    CardOtPhysicalDysfunctionMain63Disabled: boolean,
    CardOtPhysicalDysfunctionMain64: string,
    CardOtPhysicalDysfunctionMain64Disabled: boolean,
    CardOtPhysicalDysfunctionMain66: boolean,
    CardOtPhysicalDysfunctionMain66Disabled: boolean,
    CardOtPhysicalDysfunctionMain67: string,
    CardOtPhysicalDysfunctionMain67Disabled: boolean,
    CardOtPhysicalDysfunctionMain69: boolean,
    CardOtPhysicalDysfunctionMain69Disabled: boolean,
    CardOtPhysicalDysfunctionMain71: boolean,
    CardOtPhysicalDysfunctionMain71Disabled: boolean,
    CardOtPhysicalDysfunctionMain74: string,
    CardOtPhysicalDysfunctionMain74Disabled: boolean,
    CardOtPhysicalDysfunctionMain75: boolean,
    CardOtPhysicalDysfunctionMain75Disabled: boolean,
    CardOtPhysicalDysfunctionMain76: string,
    CardOtPhysicalDysfunctionMain76Disabled: boolean,
    CardOtPhysicalDysfunctionMain78: boolean,
    CardOtPhysicalDysfunctionMain78Disabled: boolean,
    CardOtPhysicalDysfunctionMain79: string,
    CardOtPhysicalDysfunctionMain79Disabled: boolean,
    CardOtPhysicalDysfunctionMain81: boolean,
    CardOtPhysicalDysfunctionMain81Disabled: boolean,
    CardOtPhysicalDysfunctionMain83: boolean,
    CardOtPhysicalDysfunctionMain83Disabled: boolean,
    CardOtPhysicalDysfunctionMain86: boolean,
    CardOtPhysicalDysfunctionMain86Disabled: boolean,
    CardOtPhysicalDysfunctionMain88: boolean,
    CardOtPhysicalDysfunctionMain88Disabled: boolean,
    CardOtPhysicalDysfunctionMain93: string,
    CardOtPhysicalDysfunctionMain93Disabled: boolean,
    CardOtPhysicalDysfunctionMain94: string,
    CardOtPhysicalDysfunctionMain94Disabled: boolean,
    CardOtPhysicalDysfunctionMain95: string,
    CardOtPhysicalDysfunctionMain95Disabled: boolean,
    CardOtPhysicalDysfunctionMain96: string,
    CardOtPhysicalDysfunctionMain96Disabled: boolean,
    CtSittingStatic: string,
    CtSittingStaticDisabled: boolean,
    CtSittingStaticOptions: any,
    CtSittingDynamic: string,
    CtSittingDynamicDisabled: boolean,
    CtSittingDynamicOptions: any,
    CtCardOtPhysicalDysfunctionMain112: string,
    CtCardOtPhysicalDysfunctionMain112Disabled: boolean,
    CtCardOtPhysicalDysfunctionMain112Options: any,
    CtCardOtPhysicalDysfunctionMain115: string,
    CtCardOtPhysicalDysfunctionMain115Disabled: boolean,
    CtCardOtPhysicalDysfunctionMain115Options: any,
}

export const CardOTPhysicalExaminationPhysicalDysfunctionMainInitialViewData: CardOTPhysicalExaminationPhysicalDysfunctionMainViewDataType = {
    CardOtPhysicalDysfunctionMain3: false,
    CardOtPhysicalDysfunctionMain3Disabled: false,
    CardOtPhysicalDysfunctionMain5: false,
    CardOtPhysicalDysfunctionMain5Disabled: false,
    CardOtPhysicalDysfunctionMain6: false,
    CardOtPhysicalDysfunctionMain6Disabled: false,
    CardOtPhysicalDysfunctionMain8: false,
    CardOtPhysicalDysfunctionMain8Disabled: false,
    CardOtPhysicalDysfunctionMain10: false,
    CardOtPhysicalDysfunctionMain10Disabled: false,
    CardOtPhysicalDysfunctionMain12: false,
    CardOtPhysicalDysfunctionMain12Disabled: false,
    CardOtPhysicalDysfunctionMain14: false,
    CardOtPhysicalDysfunctionMain14Disabled: false,
    CardOtPhysicalDysfunctionMain16: false,
    CardOtPhysicalDysfunctionMain16Disabled: false,
    CardOtPhysicalDysfunctionMain21: false,
    CardOtPhysicalDysfunctionMain21Disabled: false,
    CardOtPhysicalDysfunctionMain22: false,
    CardOtPhysicalDysfunctionMain22Disabled: false,
    CardOtPhysicalDysfunctionMain23: false,
    CardOtPhysicalDysfunctionMain23Disabled: false,
    CardOtPhysicalDysfunctionMain24: false,
    CardOtPhysicalDysfunctionMain24Disabled: false,
    CardOtPhysicalDysfunctionMain28: false,
    CardOtPhysicalDysfunctionMain28Disabled: false,
    CardOtPhysicalDysfunctionMain29: "",
    CardOtPhysicalDysfunctionMain29Disabled: false,
    CardOtPhysicalDysfunctionMain31: false,
    CardOtPhysicalDysfunctionMain31Disabled: false,
    CardOtPhysicalDysfunctionMain33: false,
    CardOtPhysicalDysfunctionMain33Disabled: false,
    CardOtPhysicalDysfunctionMain38: "",
    CardOtPhysicalDysfunctionMain38Disabled: false,
    Ct2GeneralAppearance: "",
    Ct2GeneralAppearanceDisabled: false,
    Ct2GeneralAppearanceOptions: [{key: 0, value: "A", text: "A"}],
    Ct2Consciousness: "",
    Ct2ConsciousnessDisabled: false,
    Ct2ConsciousnessOptions: [{key: 0, value: "A", text: "A"}],
    Ct5Communication: "",
    Ct5CommunicationDisabled: false,
    Ct5CommunicationOptions: [{key: 0, value: "A", text: "A"}],
    Ct5CoOperation: "",
    Ct5CoOperationDisabled: false,
    Ct5CoOperationOptions: [{key: 0, value: "A", text: "A"}],
    Ct3Cognitive: "",
    Ct3CognitiveDisabled: false,
    Ct3CognitiveOptions: [{key: 0, value: "A", text: "A"}],
    CardOtPhysicalDysfunctionMain59: "",
    CardOtPhysicalDysfunctionMain59Disabled: false,
    CardOtPhysicalDysfunctionMain60: false,
    CardOtPhysicalDysfunctionMain60Disabled: false,
    CardOtPhysicalDysfunctionMain61: "",
    CardOtPhysicalDysfunctionMain61Disabled: false,
    CardOtPhysicalDysfunctionMain63: false,
    CardOtPhysicalDysfunctionMain63Disabled: false,
    CardOtPhysicalDysfunctionMain64: "",
    CardOtPhysicalDysfunctionMain64Disabled: false,
    CardOtPhysicalDysfunctionMain66: false,
    CardOtPhysicalDysfunctionMain66Disabled: false,
    CardOtPhysicalDysfunctionMain67: "",
    CardOtPhysicalDysfunctionMain67Disabled: false,
    CardOtPhysicalDysfunctionMain69: false,
    CardOtPhysicalDysfunctionMain69Disabled: false,
    CardOtPhysicalDysfunctionMain71: false,
    CardOtPhysicalDysfunctionMain71Disabled: false,
    CardOtPhysicalDysfunctionMain74: "",
    CardOtPhysicalDysfunctionMain74Disabled: false,
    CardOtPhysicalDysfunctionMain75: false,
    CardOtPhysicalDysfunctionMain75Disabled: false,
    CardOtPhysicalDysfunctionMain76: "",
    CardOtPhysicalDysfunctionMain76Disabled: false,
    CardOtPhysicalDysfunctionMain78: false,
    CardOtPhysicalDysfunctionMain78Disabled: false,
    CardOtPhysicalDysfunctionMain79: "",
    CardOtPhysicalDysfunctionMain79Disabled: false,
    CardOtPhysicalDysfunctionMain81: false,
    CardOtPhysicalDysfunctionMain81Disabled: false,
    CardOtPhysicalDysfunctionMain83: false,
    CardOtPhysicalDysfunctionMain83Disabled: false,
    CardOtPhysicalDysfunctionMain86: false,
    CardOtPhysicalDysfunctionMain86Disabled: false,
    CardOtPhysicalDysfunctionMain88: false,
    CardOtPhysicalDysfunctionMain88Disabled: false,
    CardOtPhysicalDysfunctionMain93: "",
    CardOtPhysicalDysfunctionMain93Disabled: false,
    CardOtPhysicalDysfunctionMain94: "",
    CardOtPhysicalDysfunctionMain94Disabled: false,
    CardOtPhysicalDysfunctionMain95: "",
    CardOtPhysicalDysfunctionMain95Disabled: false,
    CardOtPhysicalDysfunctionMain96: "",
    CardOtPhysicalDysfunctionMain96Disabled: false,
    CtSittingStatic: "",
    CtSittingStaticDisabled: false,
    CtSittingStaticOptions: [{key: 0, value: "A", text: "A"}],
    CtSittingDynamic: "",
    CtSittingDynamicDisabled: false,
    CtSittingDynamicOptions: [{key: 0, value: "A", text: "A"}],
    CtCardOtPhysicalDysfunctionMain112: "",
    CtCardOtPhysicalDysfunctionMain112Disabled: false,
    CtCardOtPhysicalDysfunctionMain112Options: [{key: 0, value: "A", text: "A"}],
    CtCardOtPhysicalDysfunctionMain115: "",
    CtCardOtPhysicalDysfunctionMain115Disabled: false,
    CtCardOtPhysicalDysfunctionMain115Options: [{key: 0, value: "A", text: "A"}],
}

const Sub = (props: any) => {
    const children = React.Children.toArray(props.children)
    return (
        <div className={styles.container}>
            <div className={styles.CardOtPhysicalDysfunctionMain2}>
                <div
                    style={{backgroundColor: "#F3F3F3", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain3}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalDysfunctionMain3}
                    disabled={ props.viewData.CardOtPhysicalDysfunctionMain3Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationPhysicalDysfunctionMain",
                                name: "CardOtPhysicalDysfunctionMain3"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain4}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p><strong>Observation</strong></p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain5}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalDysfunctionMain5}
                    disabled={ props.viewData.CardOtPhysicalDysfunctionMain5Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationPhysicalDysfunctionMain",
                                name: "CardOtPhysicalDysfunctionMain5"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain6}>
                <Checkbox 
                    style={{ height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalDysfunctionMain6}
                    disabled={ props.viewData.CardOtPhysicalDysfunctionMain6Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationPhysicalDysfunctionMain",
                                name: "CardOtPhysicalDysfunctionMain6"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain7}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Normal</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain8}>
                <Checkbox 
                    style={{ height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalDysfunctionMain8}
                    disabled={ props.viewData.CardOtPhysicalDysfunctionMain8Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationPhysicalDysfunctionMain",
                                name: "CardOtPhysicalDysfunctionMain8"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain9}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Wheelchair</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain10}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalDysfunctionMain10}
                    disabled={ props.viewData.CardOtPhysicalDysfunctionMain10Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationPhysicalDysfunctionMain",
                                name: "CardOtPhysicalDysfunctionMain10"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain11}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Stroller</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain12}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalDysfunctionMain12}
                    disabled={ props.viewData.CardOtPhysicalDysfunctionMain12Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationPhysicalDysfunctionMain",
                                name: "CardOtPhysicalDysfunctionMain12"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain13}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Cane/walker</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain14}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalDysfunctionMain14}
                    disabled={ props.viewData.CardOtPhysicalDysfunctionMain14Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationPhysicalDysfunctionMain",
                                name: "CardOtPhysicalDysfunctionMain14"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain15}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Arm sling</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain16}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalDysfunctionMain16}
                    disabled={ props.viewData.CardOtPhysicalDysfunctionMain16Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationPhysicalDysfunctionMain",
                                name: "CardOtPhysicalDysfunctionMain16"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain17}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>On Ventilator</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain18}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>On ET</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain20}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>TT tube</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain21}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalDysfunctionMain21}
                    disabled={ props.viewData.CardOtPhysicalDysfunctionMain21Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationPhysicalDysfunctionMain",
                                name: "CardOtPhysicalDysfunctionMain21"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain22}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalDysfunctionMain22}
                    disabled={ props.viewData.CardOtPhysicalDysfunctionMain22Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationPhysicalDysfunctionMain",
                                name: "CardOtPhysicalDysfunctionMain22"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain23}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalDysfunctionMain23}
                    disabled={ props.viewData.CardOtPhysicalDysfunctionMain23Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationPhysicalDysfunctionMain",
                                name: "CardOtPhysicalDysfunctionMain23"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain24}>
                <Checkbox 
                    style={{ height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalDysfunctionMain24}
                    disabled={ props.viewData.CardOtPhysicalDysfunctionMain24Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationPhysicalDysfunctionMain",
                                name: "CardOtPhysicalDysfunctionMain24"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain25}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>On IV fluid</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain26}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>On Foley's catheter</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain28}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalDysfunctionMain28}
                    disabled={ props.viewData.CardOtPhysicalDysfunctionMain28Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationPhysicalDysfunctionMain",
                                name: "CardOtPhysicalDysfunctionMain28"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain29}>
                <Input
                    value={props.viewData.CardOtPhysicalDysfunctionMain29}
                    disabled={ props.viewData.CardOtPhysicalDysfunctionMain29Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTPhysicalExaminationPhysicalDysfunctionMain',
                            name: 'CardOtPhysicalDysfunctionMain29',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain31}>
                <Checkbox 
                    style={{ height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalDysfunctionMain31}
                    disabled={ props.viewData.CardOtPhysicalDysfunctionMain31Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationPhysicalDysfunctionMain",
                                name: "CardOtPhysicalDysfunctionMain31"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain32}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>On ICD drainage</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain33}>
                <Checkbox 
                    style={{ height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalDysfunctionMain33}
                    disabled={ props.viewData.CardOtPhysicalDysfunctionMain33Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationPhysicalDysfunctionMain",
                                name: "CardOtPhysicalDysfunctionMain33"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain34}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>NG Tube</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain35}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>OG Tube</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain36}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>PEG</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain37}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Other</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain38}>
                <Input
                    value={props.viewData.CardOtPhysicalDysfunctionMain38}
                    disabled={ props.viewData.CardOtPhysicalDysfunctionMain38Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTPhysicalExaminationPhysicalDysfunctionMain',
                            name: 'CardOtPhysicalDysfunctionMain38',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain39}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>General appearance</strong></p>
                </div>
            </div>
            <div className={styles.Ct2GeneralAppearance}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct2GeneralAppearance}
                    disabled={ props.viewData.Ct2GeneralAppearanceDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationPhysicalDysfunctionMain",
                                name: "Ct2GeneralAppearance",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct2GeneralAppearanceOptions}
                />
            </div>
            <div className={styles.Ct2Consciousness}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct2Consciousness}
                    disabled={ props.viewData.Ct2ConsciousnessDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationPhysicalDysfunctionMain",
                                name: "Ct2Consciousness",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct2ConsciousnessOptions}
                />
            </div>
            <div className={styles.Ct5Communication}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct5Communication}
                    disabled={ props.viewData.Ct5CommunicationDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationPhysicalDysfunctionMain",
                                name: "Ct5Communication",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct5CommunicationOptions}
                />
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain49}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p><strong>Consciousness</strong></p>
                </div>
            </div>
            <div className={styles.Ct5CoOperation}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct5CoOperation}
                    disabled={ props.viewData.Ct5CoOperationDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationPhysicalDysfunctionMain",
                                name: "Ct5CoOperation",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct5CoOperationOptions}
                />
            </div>
            <div className={styles.Ct3Cognitive}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct3Cognitive}
                    disabled={ props.viewData.Ct3CognitiveDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationPhysicalDysfunctionMain",
                                name: "Ct3Cognitive",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct3CognitiveOptions}
                />
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain56}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Communication</strong></p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain57}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Co-operation</strong></p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain58}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Cognitive</strong></p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain59}>
                <Input
                    value={props.viewData.CardOtPhysicalDysfunctionMain59}
                    disabled={ props.viewData.CardOtPhysicalDysfunctionMain59Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTPhysicalExaminationPhysicalDysfunctionMain',
                            name: 'CardOtPhysicalDysfunctionMain59',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain60}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalDysfunctionMain60}
                    disabled={ props.viewData.CardOtPhysicalDysfunctionMain60Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationPhysicalDysfunctionMain",
                                name: "CardOtPhysicalDysfunctionMain60"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain61}>
                <Input
                    value={props.viewData.CardOtPhysicalDysfunctionMain61}
                    disabled={ props.viewData.CardOtPhysicalDysfunctionMain61Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTPhysicalExaminationPhysicalDysfunctionMain',
                            name: 'CardOtPhysicalDysfunctionMain61',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain62}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Muscle length</strong></p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain63}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalDysfunctionMain63}
                    disabled={ props.viewData.CardOtPhysicalDysfunctionMain63Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationPhysicalDysfunctionMain",
                                name: "CardOtPhysicalDysfunctionMain63"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain64}>
                <Input
                    value={props.viewData.CardOtPhysicalDysfunctionMain64}
                    disabled={ props.viewData.CardOtPhysicalDysfunctionMain64Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTPhysicalExaminationPhysicalDysfunctionMain',
                            name: 'CardOtPhysicalDysfunctionMain64',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain65}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Normal</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain66}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalDysfunctionMain66}
                    disabled={ props.viewData.CardOtPhysicalDysfunctionMain66Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationPhysicalDysfunctionMain",
                                name: "CardOtPhysicalDysfunctionMain66"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain67}>
                <Input
                    value={props.viewData.CardOtPhysicalDysfunctionMain67}
                    disabled={ props.viewData.CardOtPhysicalDysfunctionMain67Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTPhysicalExaminationPhysicalDysfunctionMain',
                            name: 'CardOtPhysicalDysfunctionMain67',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain68}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Tightness</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain69}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalDysfunctionMain69}
                    disabled={ props.viewData.CardOtPhysicalDysfunctionMain69Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationPhysicalDysfunctionMain",
                                name: "CardOtPhysicalDysfunctionMain69"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain70}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p>Shortening</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain71}>
                <Checkbox 
                    style={{ height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalDysfunctionMain71}
                    disabled={ props.viewData.CardOtPhysicalDysfunctionMain71Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationPhysicalDysfunctionMain",
                                name: "CardOtPhysicalDysfunctionMain71"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain72}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Contracture</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain73}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>N/A</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain74}>
                <Input
                    value={props.viewData.CardOtPhysicalDysfunctionMain74}
                    disabled={ props.viewData.CardOtPhysicalDysfunctionMain74Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTPhysicalExaminationPhysicalDysfunctionMain',
                            name: 'CardOtPhysicalDysfunctionMain74',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain75}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalDysfunctionMain75}
                    disabled={ props.viewData.CardOtPhysicalDysfunctionMain75Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationPhysicalDysfunctionMain",
                                name: "CardOtPhysicalDysfunctionMain75"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain76}>
                <Input
                    value={props.viewData.CardOtPhysicalDysfunctionMain76}
                    disabled={ props.viewData.CardOtPhysicalDysfunctionMain76Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTPhysicalExaminationPhysicalDysfunctionMain',
                            name: 'CardOtPhysicalDysfunctionMain76',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain77}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Muscle tone</strong></p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain78}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalDysfunctionMain78}
                    disabled={ props.viewData.CardOtPhysicalDysfunctionMain78Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationPhysicalDysfunctionMain",
                                name: "CardOtPhysicalDysfunctionMain78"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain79}>
                <Input
                    value={props.viewData.CardOtPhysicalDysfunctionMain79}
                    disabled={ props.viewData.CardOtPhysicalDysfunctionMain79Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTPhysicalExaminationPhysicalDysfunctionMain',
                            name: 'CardOtPhysicalDysfunctionMain79',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain80}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Normal</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain81}>
                <Checkbox 
                    style={{ height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalDysfunctionMain81}
                    disabled={ props.viewData.CardOtPhysicalDysfunctionMain81Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationPhysicalDysfunctionMain",
                                name: "CardOtPhysicalDysfunctionMain81"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain82}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Spasticity</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain83}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalDysfunctionMain83}
                    disabled={ props.viewData.CardOtPhysicalDysfunctionMain83Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationPhysicalDysfunctionMain",
                                name: "CardOtPhysicalDysfunctionMain83"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain84}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p>Flaccid</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain85}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>N/A</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain86}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalDysfunctionMain86}
                    disabled={ props.viewData.CardOtPhysicalDysfunctionMain86Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationPhysicalDysfunctionMain",
                                name: "CardOtPhysicalDysfunctionMain86"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain87}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Motor Power:</strong></p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain88}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.CardOtPhysicalDysfunctionMain88}
                    disabled={ props.viewData.CardOtPhysicalDysfunctionMain88Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTPhysicalExaminationPhysicalDysfunctionMain",
                                name: "CardOtPhysicalDysfunctionMain88"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain89}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p>Right</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain93}>
                <Input
                    value={props.viewData.CardOtPhysicalDysfunctionMain93}
                    disabled={ props.viewData.CardOtPhysicalDysfunctionMain93Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTPhysicalExaminationPhysicalDysfunctionMain',
                            name: 'CardOtPhysicalDysfunctionMain93',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain94}>
                <Input
                    value={props.viewData.CardOtPhysicalDysfunctionMain94}
                    disabled={ props.viewData.CardOtPhysicalDysfunctionMain94Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTPhysicalExaminationPhysicalDysfunctionMain',
                            name: 'CardOtPhysicalDysfunctionMain94',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain95}>
                <Input
                    value={props.viewData.CardOtPhysicalDysfunctionMain95}
                    disabled={ props.viewData.CardOtPhysicalDysfunctionMain95Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTPhysicalExaminationPhysicalDysfunctionMain',
                            name: 'CardOtPhysicalDysfunctionMain95',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain96}>
                <Input
                    value={props.viewData.CardOtPhysicalDysfunctionMain96}
                    disabled={ props.viewData.CardOtPhysicalDysfunctionMain96Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTPhysicalExaminationPhysicalDysfunctionMain',
                            name: 'CardOtPhysicalDysfunctionMain96',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain97}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Left</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain98}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p>N/A</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain101}>
                <div
                    style={{backgroundColor: "#E0E0E0", height: "130%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.CtSittingStatic}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtSittingStatic}
                    disabled={ props.viewData.CtSittingStaticDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationPhysicalDysfunctionMain",
                                name: "CtSittingStatic",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtSittingStaticOptions}
                />
            </div>
            <div className={styles.CtSittingDynamic}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtSittingDynamic}
                    disabled={ props.viewData.CtSittingDynamicDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationPhysicalDysfunctionMain",
                                name: "CtSittingDynamic",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtSittingDynamicOptions}
                />
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain108}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Sitting</strong></p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain109}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p>Static</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain110}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Dynamic</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain111}>
                <div
                    style={{backgroundColor: "#E0E0E0", height: "130%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.CtCardOtPhysicalDysfunctionMain112}>
                <Dropdown selection
                    style={{ height: "100%", width: "100%"}}
                    value={props.viewData.CtCardOtPhysicalDysfunctionMain112}
                    disabled={ props.viewData.CtCardOtPhysicalDysfunctionMain112Disabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationPhysicalDysfunctionMain",
                                name: "CtCardOtPhysicalDysfunctionMain112",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtCardOtPhysicalDysfunctionMain112Options}
                />
            </div>
            <div className={styles.CtCardOtPhysicalDysfunctionMain115}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtCardOtPhysicalDysfunctionMain115}
                    disabled={ props.viewData.CtCardOtPhysicalDysfunctionMain115Disabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTPhysicalExaminationPhysicalDysfunctionMain",
                                name: "CtCardOtPhysicalDysfunctionMain115",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtCardOtPhysicalDysfunctionMain115Options}
                />
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain118}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Standing</strong></p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain119}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Static</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain120}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Dynamic</p>
                </div>
            </div>
            <div className={styles.CardOtPhysicalDysfunctionMain121}>
                <div
                    style={{ height: "100%", width: "100%"}}
                >
                    <p><strong>Balance test</strong></p>
                </div>
            </div>
        </div>
    )
}

export default Sub

const getTsName = (cssClass: string) => {
    return cssClass.split('-').map((s) => (
        s.charAt(0).toUpperCase() + s.slice(1)
    )).join('')
}
