export default class SubICD10DetailController {

	constructor(coreManager, dpoManager) {
		this.coreManager = coreManager;
		this.dpoManager = dpoManager;

  }
  
  getICDType = () => {
    return "ICD10"
	};

	searchWithICDCode = async icdCode => {
    var errorMsg = "ไม่สามารถค้นหา ICD10 Code!"
		if (!icdCode) {
			return [null, errorMsg];
		}
		const [data, error] = await this.coreManager.getICD10ListWithCode(icdCode);
		return [data, error];
	};

	searchWithICDTerm = async icdTerm => {
    var errorMsg = "ไม่สามารถค้นหา ICD10 Term!"
		if (!icdTerm) {
			return [null, errorMsg];
    }
    const [data, error] = await this.coreManager.getICD10ListWithTerm(icdTerm);
		return [data, error];
	};

	searchWithMedTerm = async medTerm => {
    var errorMsg = "ไม่สามารถค้นหา Medical Term!"
		if (!medTerm) {
			return [null, errorMsg];
		}
		const [data, error] = await this.coreManager.getICD10ListWithMedTerm(medTerm);
		return [data, error];
	};

	searchSublevelWithICDCode = async icdCode => {
    var errorMsg = "ไม่สามารถค้นหา ICD10 Sublevel"
		if (!icdCode) {
			return [null, errorMsg];
		}
		const [data, error] = await this.coreManager.getICD10SublevelListWithCode(icdCode);
		return [data, error];
	};

	getChoices = async ({ model, field, nameAsId, name }) => {
    const [response, error, network] = await this.coreManager.getChoices({
      model,
      field,
      nameAsId
    });
    return [response, error, network];
	};
	
	getDiagnosis = async emrId => {
    if (!emrId) {
      return null;
    }
    const [data, error] = await this.dpoManager.getDiagnosis(emrId);
    if (error) {
      alert('error', error)
      return null;
    }
    return data;
	};

	saveDiagnosis = async (emrId, pDiagnosis, sDiagnosis) => {
    if (!emrId) {
      return null;
    }
    const [data, error] = await this.dpoManager.updateDiagnosis(
      emrId,
      pDiagnosis,
      sDiagnosis
    );
    if (error) {
      console.log(error);
      return null;
    }
    return data;
	};

	// Treatment History card
	getAllDivision = async () => {
    const [data, error] = await this.coreManager.getDivisionChoice();
    if (error) {
      console.log(error);
      return [];
    }
    return data;
	};

	getAllDoctor = async () => {
    const [data, error] = await this.coreManager.getDoctorChoice();
    if (error) {
      console.log(error);
      return [];
    }
    return data;
	};
	
	searchPatientDiagnosis = patientId => async params => {
    const [data, error] = await this.dpoManager.getPatientDiagnosis(
      patientId,
      params
    );
    if (error) {
      console.log(error);
      return [];
    }
    return data;
	};

	//ICD10 Template card
  searchDiagnosisTemplate = async params => {
    const [data, error] = await this.dpoManager.getDiagnosisTemplate(params);
    if (error) {
      console.log(error);
      return [];
    }
    return data;
  };
	
}
