import React, { useState, useRef, useEffect, memo, useContext } from "react";
import {
  Segment,
  Button,
  Dimmer,
  Loader,
  Input,
  Icon,
  Checkbox,
  Dropdown
} from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import { injectIntl } from "react-intl";
import { AppContext } from "../patient-lib/PatientContext";

const EditAddress = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [countryList, setCountryList] = useState(null);
  const [country, setCountry] = useState(null);
  const [provinceList, setProvinceList] = useState([]);
  const [province, setProvince] = useState(null);
  const [districtList, setDistrictList] = useState([]);
  const [district, setDistrict] = useState(null);
  const [cityList, setCityList] = useState([]);
  const [city, setCity] = useState(null);
  const [zipcode, setZipcode] = useState("");
  const [addressNote, setAddressNote] = useState("");
  const [defaultAddress, setDefaultAddress] = useState(false);
  const [addressId, setAddressId] = useState(null); // addressId which is not patientAddressId
  const [error, setError] = useState(null);

  const isMounted = useRef(true);
  const history = useHistory();
  const context = useContext(AppContext);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!props.apiToken && !Cookies.get("apiToken")) {
      props.noApiToken();
    }
  }, [props.apiToken,  Cookies.get("apiToken")]);

  useEffect(() => {
    if (props.apiToken ||  Cookies.get("apiToken")) {
      getProvinceList();
      getCountryList();
    }
  }, [props.controller, props.apiToken,  Cookies.get("apiToken")]);

  useEffect(() => {
    if (props.match.params.addressId) {
      getPatientAddressDetail();
    } else if (props.location.init) {
      getInitPatientAddress();
    }
  }, [props.match.params.addressId, props.location.init]);

  useEffect(() => {
    if (province) {
      getDistrictList();
    }
  }, [province]);

  useEffect(() => {
    if (district) {
      getCityList();
    } else {
      setCityList([]);
      setCity(null);
    }
  }, [district]);

  useEffect(() => {
    if (!city) {
      setZipcode("");
    }
  }, [city]);

  const getInitPatientAddress = async () => {
    setIsLoading(true);
    const [res, err, network] = await props.controller.getInitPatientAddress({
      apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
      patientId: props.patientData.patient
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (res) {
        setProvince(res.province);
        setDistrict(res.district);
        setCity(res.city);
        setZipcode(res.zipcode);
        setAddressNote(res.note);
      }
    }
  };

  const getPatientAddressDetail = async () => {
    setIsLoading(true);
    const [res, err, network] = await props.controller.getPatientAddressDetail({
      apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
      patientAddressId: props.match.params.addressId
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (res) {
        setDefaultAddress(res.default);
        setProvince(res.address.province);
        setDistrict(res.address.district);
        setCity(res.address.city);
        setZipcode(res.address.zipcode);
        setAddressNote(res.address.note);
        setAddressId(res.address.id);
      } else if (err) {
        if (network.response.status === 404) {
          // props.onPatientAddressNotFound()
        }
        if (err.length > 200) {
          setError(
            `${network.response.status}: ${network.response.statusText}`
          );
        } else {
          setError(error);
        }
      }
    }
  };

  const getCountryList = async () => {
    const [res, err, network] = await props.controller.getCountryList({
      apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken")
    });
    if (isMounted.current) {
      if (res) {
        let options = res.map(item => {
          return {
            id: item.id,
            text: item.full_name,
            value: item.id
          };
        });
        setCountryList(options);
        let thailand = options.find(item =>
          item.text.toLowerCase().includes("thailand")
        );
        setCountry(thailand.id);
      }
    }
  };

  const getCityList = async () => {
    const [res, err, network] = await props.controller.getCityList({
      apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
      area: district
    });
    if (isMounted.current) {
      if (res) {
        let nameField = "name";
        if (context && context.language === "en-US") {
          nameField = "name_en";
        }
        let options = res.map(item => {
          return {
            id: item.id,
            text: item[nameField],
            value: item.id,
            zipcode: item.zipcode
          };
        });
        setCityList(options);
      }
    }
  };

  const getDistrictList = async () => {
    const [res, err, network] = await props.controller.getDistrictList({
      apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
      area: province
    });
    if (isMounted.current) {
      if (res) {
        let nameField = "name";
        if (context && context.language === "en-US") {
          nameField = "name_en";
        }
        let options = res.map(item => {
          return {
            id: item.id,
            text: item[nameField],
            value: item.id
          };
        });
        setDistrictList(options);
      }
    }
  };

  const getProvinceList = async () => {
    const [res, err, network] = await props.controller.getProvinceList({
      apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken")
    });
    if (isMounted.current) {
      if (res) {
        let nameField = "name";
        if (context && context.language === "en-US") {
          nameField = "name_en";
        }
        let options = res.map(item => {
          return {
            id: item.id,
            text: item[nameField],
            value: item.id
          };
        });
        setProvinceList(options);
      }
    }
  };

  const handleProvinceChange = (e, { value }) => {
    setDistrict(null);
    setDistrictList([]);
    setProvince(value);
  };

  const handleDistrictChange = (e, { value }) => {
    setCityList([]);
    setCity(null);
    setDistrict(value);
  };

  const handleCityChange = (e, { value, options }) => {
    let obj = options.find(item => item.value === value);
    setCity(value);
    setZipcode(obj.zipcode);
  };

  const handleCheckboxChange = () => {
    setDefaultAddress(!defaultAddress);
  };

  const handleChangeAddressNote = e => {
    const { value } = e.target;
    setAddressNote(value);
  };

  const handleConfirmAddress = async () => {
    setError(null);
    setIsLoading(true);
    if (!province || !district || !city) {
      setIsLoading(false);
      return setError("กรุณากรอกข้อมูลให้ครบถ้วน");
    }
    let data = {};
    data.patient = props.patientData.patient;
    data.default = defaultAddress;
    data.address = {
      note: addressNote,
      city,
      district,
      province,
      country
    };
    if (addressId) {
      data.address.id = addressId;
    }
    if (props.match.params.addressId) {
      data.id = props.match.params.addressId;
      updateAddress(data);
    } else {
      createNewAddress(data);
    }
  };

  const updateAddress = async data => {
    const [res, err, network] = await props.controller.putPatientAddressDetail({
      apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
      data,
      patientAddressId: props.match.params.addressId
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (res) {
        console.log(res, "res");
        history.goBack();
      } else if (err) {
        if (err.length > 200) {
          setError(
            `${network.response.status}: ${network.response.statusText}`
          );
        } else {
          setError(err);
        }
      }
    }
  };

  const createNewAddress = async data => {
    const [res, err, network] = await props.controller.postPatientAddress({
      apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
      data
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (res) {
        history.goBack();
      } else if (err) {
        if (err.length > 200) {
          setError(
            `${network.response.status}: ${network.response.statusText}`
          );
        } else {
          setError(err);
        }
      }
    }
  };

  const handleDeletePatientAddress = async () => {
    setIsLoading(true);
    const [res, err, network] = await props.controller.deletePatientAddress({
      apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
      patientAddressId: props.match.params.addressId
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (!err) {
        history.goBack();
      }
    }
  };

  return (
    <div className="edit-address">
      <Dimmer.Dimmable>
        {props.menuBar}
        <Dimmer active={isLoading} inverted>
          <Loader />
        </Dimmer>
        <div className="default-check">
          <ErrorMessage error={error} />
          <Checkbox
            label={props.intl.formatMessage({
              id: "drug_delivery.default_address"
            })}
            checked={defaultAddress}
            onChange={handleCheckboxChange}
          />
        </div>
        <div className="address-info">
          <label>
            {props.intl.formatMessage({
              id: "drug_delivery.address_detail"
            })}{" "}
            &ensp;&ensp;&ensp;
          </label>
          <div className="address-info-input">
            <Input
              fluid
              className="bottom-border"
              value={addressNote}
              onChange={handleChangeAddressNote}
            />
          </div>
        </div>
        <div className="address-detail">
          <label>
            {props.intl.formatMessage({
              id: "common.province"
            })}
            <span className="required">*</span>&ensp;
          </label>
          <div className="address-detail-dropdown">
            <Dropdown
              search
              selection
              fluid
              value={province}
              options={provinceList}
              className="bottom-border"
              onChange={handleProvinceChange}
            />
          </div>
        </div>
        <div className="address-detail">
          <label>
            {props.intl.formatMessage({
              id: "common.district_area"
            })}
            <span className="required">*</span>&ensp;
          </label>
          <div className="address-detail-dropdown">
            <Dropdown
              search
              selection
              fluid
              value={district}
              options={districtList}
              disabled={districtList.length === 0}
              onChange={handleDistrictChange}
              className="bottom-border"
            />
          </div>
        </div>
        <div className="address-detail">
          <label>
            {props.intl.formatMessage({
              id: "common.sub_district_sub_area"
            })}
            <span className="required">*</span>&ensp;
          </label>
          <div className="address-detail-dropdown">
            <Dropdown
              search
              selection
              fluid
              value={city}
              options={cityList}
              disabled={cityList.length === 0}
              onChange={handleCityChange}
              className="bottom-border"
            />
          </div>
        </div>
        <div className="address-detail postcode">
          <label>
            {props.intl.formatMessage({
              id: "common.postcode"
            })}{" "}
            &ensp;&ensp;&ensp;
          </label>
          <div className="address-detail-input">
            <Input fluid value={zipcode} readOnly />
          </div>
        </div>
        {props.match && props.match.params && props.match.params.addressId && (
          <div className="delete-address" onClick={handleDeletePatientAddress}>
            <span>ลบที่อยู่</span>
          </div>
        )}
      </Dimmer.Dimmable>
      <div className="btn-group">
        {/* <Button className="inverted-btn" content="ยกเลิก" fluid onClick={() => history.goBack()}/> */}
        <Button
          content={props.intl.formatMessage({
            id: "common.confirm"
          })}
          fluid
          onClick={handleConfirmAddress}
          disabled={isLoading || !province || !district || !city}
        />
      </div>
    </div>
  );
};

EditAddress.defaultProps = {
  controller: {
    getProvinceList: () => []
  }
};

export default memo(injectIntl(EditAddress, { forwardRef: true }));
