import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Dropdown,
  Button
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardPackagePurchaseHistoryUX = (props: any) => {
    return(
      <div
        style={{width: "100%"}}>
        <div
          style={{display: "flex", alignItems: "center", justifyContent: "space-between", padding: "15px 10px", backgroundColor: "#F2F2F2"}}>

          <div
            style={{fontSize: "1.2rem", fontWeight: "bold", color: "#0072BC"}}>
            ประวัติการซื้อแพ็กเกจ
          </div>
        </div>
        <div
          className="ui divider"
          style={{marginTop: 0, border: "none"}}>

        </div>
        <div
          style={{padding: "0 15px"}}>

          <div
            className="ui form">

            <FormGroup
              inline={true}>
              <FormField
                inline={true}>
                <label
                  style={{width: "110px"}}>
                  ชื่อ/รหัสแพ็กเกจ
                </label>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <div
                  style={{width: "100%"}}>
                  {props.SearchBoxPackageName}
                </div>
              </FormField>
              <FormField
                inline={true}>
                <label
                  style={{width: "105px", textAlign: "right"}}>
                  การใช้งาน
                </label>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <Dropdown
                  clearable={true}
                  fluid={true}
                  name="usage"
                  onChange={props.onChangeValue}
                  options={props.usageOptions || []}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.filterPurchaseHistory?.usage || ""}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}
                style={{opacity:0, pointerEvents: "none"}}>
                <label
                  style={{width: "105px",textAlign: "right"}}>
                  การชำระเงิน
                </label>
              </FormField>
              <FormField
                inline={true}
                style={{opacity:0, pointerEvents: "none"}}
                width={4}>
                <Dropdown
                  fluid={true}
                  selection={true}
                  style={{width: "100%"}}>
                </Dropdown>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}>
                <label
                  style={{width: "110px"}}>
                  แผนกที่ให้บริการ
                </label>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <Dropdown
                  clearable={true}
                  disabled={props.selectable}
                  fluid={true}
                  name="division"
                  onChange={props.onChangeValue}
                  options={props.serviceDivisionOptions || []}
                  search={true}
                  selection={true}
                  style={{width: "100%",  ...(props.selectable && {background: "#EAEAEA", opacity:0.75, border: "none"})}}
                  value={props.filterPurchaseHistory?.division || ""}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}>
                <label
                  style={{width: "95px", textAlign: "right"}}>
                  ประเภทบริการ
                </label>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <Dropdown
                  clearable={true}
                  fluid={true}
                  name="serviceType"
                  onChange={props.onChangeValue}
                  options={props.packageServiceTypeOptions || []}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.filterPurchaseHistory?.serviceType || ""}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}>
                <label
                  style={{width: "105px", textAlign: "right"}}>
                  ประเภทแพ็กเกจ
                </label>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <Dropdown
                  className="inline-label"
                  clearable={true}
                  fluid={true}
                  name="packageType"
                  onChange={props.onChangeValue}
                  options={props.packageTypeOptions ||  []}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.filterPurchaseHistory?.packageType || ""}>
                </Dropdown>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                style={{flex: 1}}>
              </FormField>
              <FormField
                inline={true}>
                <div>
                  {props.ButtonSearch}
                </div>
              </FormField>
              <FormField
                inline={true}>
                <Button
                  color="yellow"
                  onClick={props.onClear}>
                  เคลียร์
                </Button>
              </FormField>
            </FormGroup>
          </div>
          <div
            className="ui form"
            style={{marginTop: "1rem"}}>

            <Table
              data={props.purchaseHistoryItems || []}
              getTrProps={props.getTrProps}
              headers="วันที่สั่งซื้อ,รหัส,ชื่อแพ็กเกจ,ราคา,QTY,การชำระเงิน,การใช้งาน,ประวัติ,,"
              keys="date,product_code,product_name,price,quantity,payment,usage,history,description,print"
              minRows={3}
              showPagination={false}
              style={{maxHeight: "300px"}}
              widths="^110,^110,^200,^95,^75,^100,^100,75,100,115">
            </Table>
          </div>
        </div>
      </div>
    )
}


export default CardPackagePurchaseHistoryUX

export const screenPropsDefault = {"price_claimable":0,"price_non_claimable":0,"price_total":0}

/* Date Time : Tue Oct 08 2024 10:38:51 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", justifyContent: \"space-between\", padding: \"15px 10px\", backgroundColor: \"#F2F2F2\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: 0, border: \"none\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติการซื้อแพ็กเกจ"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.2rem\", fontWeight: \"bold\", color: \"#0072BC\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 15px\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormGroup",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "FormGroup",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"1rem\"}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 40,
      "name": "Table",
      "parent": 39,
      "props": {
        "data": {
          "type": "code",
          "value": "props.purchaseHistoryItems || []"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getTrProps"
        },
        "headers": {
          "type": "value",
          "value": "วันที่สั่งซื้อ,รหัส,ชื่อแพ็กเกจ,ราคา,QTY,การชำระเงิน,การใช้งาน,ประวัติ,,"
        },
        "keys": {
          "type": "value",
          "value": "date,product_code,product_name,price,quantity,payment,usage,history,description,print"
        },
        "minRows": {
          "type": "code",
          "value": "3"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{maxHeight: \"300px\"}"
        },
        "widths": {
          "type": "value",
          "value": "^110,^110,^200,^95,^75,^100,^100,75,100,115"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "FormField",
      "parent": 19,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "FormField",
      "parent": 19,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "FormField",
      "parent": 19,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "FormField",
      "parent": 19,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "FormField",
      "parent": 19,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "FormField",
      "parent": 19,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "label",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ/รหัสแพ็กเกจ"
        },
        "style": {
          "type": "code",
          "value": "{width: \"110px\"}"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "label",
      "parent": 45,
      "props": {
        "children": {
          "type": "value",
          "value": "การใช้งาน"
        },
        "style": {
          "type": "code",
          "value": "{width: \"105px\", textAlign: \"right\"}"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "label",
      "parent": 47,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนกที่ให้บริการ"
        },
        "style": {
          "type": "code",
          "value": "{width: \"110px\"}"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "label",
      "parent": 49,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทบริการ"
        },
        "style": {
          "type": "code",
          "value": "{width: \"95px\", textAlign: \"right\"}"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "label",
      "parent": 51,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทแพ็กเกจ"
        },
        "style": {
          "type": "code",
          "value": "{width: \"105px\", textAlign: \"right\"}"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "div",
      "parent": 42,
      "props": {
        "children": {
          "type": "code",
          "value": "props.SearchBoxPackageName"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "Dropdown",
      "parent": 46,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "usage"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.usageOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filterPurchaseHistory?.usage || \"\""
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "Dropdown",
      "parent": 48,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.selectable"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "division"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.serviceDivisionOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\",  ...(props.selectable && {background: \"#EAEAEA\", opacity:0.75, border: \"none\"})}"
        },
        "value": {
          "type": "code",
          "value": "props.filterPurchaseHistory?.division || \"\""
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "Dropdown",
      "parent": 50,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "serviceType"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.packageServiceTypeOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filterPurchaseHistory?.serviceType || \"\""
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "FormGroup",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "FormField",
      "parent": 65,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "FormField",
      "parent": 65,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "FormField",
      "parent": 65,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "Button",
      "parent": 68,
      "props": {
        "children": {
          "type": "value",
          "value": "เคลียร์"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClear"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "div",
      "parent": 67,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSearch"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "Dropdown",
      "parent": 52,
      "props": {
        "className": {
          "type": "value",
          "value": "inline-label"
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "packageType"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.packageTypeOptions ||  []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filterPurchaseHistory?.packageType || \"\""
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{opacity:0, pointerEvents: \"none\"}"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{opacity:0, pointerEvents: \"none\"}"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "label",
      "parent": 73,
      "props": {
        "children": {
          "type": "value",
          "value": "การชำระเงิน"
        },
        "style": {
          "type": "code",
          "value": "{width: \"105px\",textAlign: \"right\"}"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "Dropdown",
      "parent": 74,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 76,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 90,
  "isMounted": false,
  "memo": false,
  "name": "CardPackagePurchaseHistoryUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "price_claimable": 0,
    "price_non_claimable": 0,
    "price_total": 0
  },
  "width": 55
}

*********************************************************************************** */
