import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Button,
  Dropdown,
  Label,
  Icon
} from 'semantic-ui-react'

const CardImportLabValuesCalcDoctorFeeUX = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{width: "100%",height:"100%",padding:"10px"}}>
        <div
          className="ui form">
          
          <div
            style={{fontSize: "1.15rem", fontWeight: "bold", padding: "2.5px 0"}}>
            นำเข้าค่า Lab สำหรับคำนวณรายได้ค่าตอบแทนแพทย์
          </div>
        </div>
        <div
          className="ui divider">
          
        </div>
        <div
          className="ui form"
          style={{position: "relative"}}>
          {}
          <div>
            {props.tabPans}
          </div>
          <div>
            {props.buttonDownload}
          </div>
        </div>
        <div
          className="ui form"
          style={{ display: props.activeItem==="UPLOAD"?"":"none"}}>
          
          <FormGroup
            inline={true}
            style={{marginBottom: "0.25rem"}}>
            <FormField
              inline={true}>
              <Button
                onClick={props.onChooseFile}
                style={{minWidth: "max-content"}}>
                Choose file
              </Button>
            </FormField>
            <FormField
              className="required"
              inline={true}
              style={{minWidth: "max-content"}}
              width={2}>
              <label
                style={{minWidth: "max-content"}}>
                งวด
              </label>
              <Dropdown
                fluid={true}
                name="dfPayrollGroupId"
                onChange={props.onChangeValue}
                options={props.dfPayrollGroupOptions||[]}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.dfPayrollGroupId || ""}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}
              style={{minWidth: "max-content"}}>
              <label>
                ช่วงงวดวันที่
              </label>
              <Label
                size="large"
                style={{paddingTop: "0.8rem", paddingBottom: "0.8rem", width: "13.5rem"}}>
                {props.selectedLotNo?`${props.selectedLotNo.started_date} - ${props.selectedLotNo.ended_date}`:"\u00a0"}
              </Label>
            </FormField>
            <FormField
              inline={true}
              style={{minWidth: "11rem"}}
              width={1}>
              <label>
                ปี
              </label>
              <Dropdown
                disabled={true}
                fluid={true}
                name="fiscalYearId"
                onChange={props.onChangeValue}
                options={props.yearOptions}
                search={true}
                selection={true}
                style={{width: "100%", opacity: 1}}
                value={props.fiscalYearId || ""}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}
              style={{minWidth: "14rem"}}
              width={1}>
              <label>
                เดือน
              </label>
              <Dropdown
                disabled={true}
                fluid={true}
                name="monthId"
                onChange={props.onChangeValue}
                options={props.monthOptions}
                search={true}
                selection={true}
                style={{width: "100%", opacity: 1}}
                value={props.monthId || ""}>
              </Dropdown>
            </FormField>
            <FormField
              className="required"
              inline={true}
              width={4}>
              <label
                style={{minWidth: "max-content"}}>
                ชื่อแพทย์
              </label>
              <div
                style={{width: "100%"}}>
                {props.doctorSearchBox}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <Button
                color="green"
                onClick={props.onUpload}
                style={{minWidth: "max-content"}}>
                <label
                  style={{marginRight: "0.75rem"}}>
                  Upload
                </label>
                <Icon
                  name="upload">
                </Icon>
              </Button>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={16}>
              <label
                style={{marginRight: "0.5rem", fontWeight: "normal"}}>
                {props.fileName || "No File chosen"}
              </label>
              <div
                style={{display: props.fileName? "": "none"}}>
                
                <Icon
                  link={true}
                  name="close"
                  onClick={props.onClearFile}>
                </Icon>
              </div>
            </FormField>
          </FormGroup>
        </div>
      </div>
    )
}


export default CardImportLabValuesCalcDoctorFeeUX

export const screenPropsDefault = {}

/* Date Time : Thu Apr 18 2024 10:31:58 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width: \"100%\",height:\"100%\",padding:\"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "นำเข้าค่า Lab สำหรับคำนวณรายได้ค่าตอบแทนแพทย์"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.15rem\", fontWeight: \"bold\", padding: \"2.5px 0\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{position: \"relative\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "code",
          "value": "props.tabPans"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.activeItem===\"UPLOAD\"?\"\":\"none\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "FormGroup",
      "parent": 10,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"0.25rem\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "FormField",
      "parent": 11,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "FormField",
      "parent": 11,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "FormField",
      "parent": 11,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "Button",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "Choose file"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChooseFile"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "label",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "งวด"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "label",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": "ช่วงงวดวันที่"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "FormField",
      "parent": 11,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"11rem\"}"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "FormField",
      "parent": 11,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"14rem\"}"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "FormField",
      "parent": 11,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "label",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": "ปี"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "label",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": "เดือน"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "label",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อแพทย์"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 24,
      "props": {
        "children": {
          "type": "code",
          "value": "props.doctorSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "Dropdown",
      "parent": 23,
      "props": {
        "clearable": {
          "type": "code",
          "value": ""
        },
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "monthId"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.monthOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", opacity: 1}"
        },
        "value": {
          "type": "code",
          "value": "props.monthId || \"\""
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "Dropdown",
      "parent": 22,
      "props": {
        "clearable": {
          "type": "code",
          "value": ""
        },
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "fiscalYearId"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.yearOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", opacity: 1}"
        },
        "value": {
          "type": "code",
          "value": "props.fiscalYearId || \"\""
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "Label",
      "parent": 16,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectedLotNo?`${props.selectedLotNo.started_date} - ${props.selectedLotNo.ended_date}`:\"\\u00a0\""
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{paddingTop: \"0.8rem\", paddingBottom: \"0.8rem\", width: \"13.5rem\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "Dropdown",
      "parent": 15,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "dfPayrollGroupId"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.dfPayrollGroupOptions||[]"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.dfPayrollGroupId || \"\""
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "FormField",
      "parent": 11,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "Button",
      "parent": 33,
      "props": {
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": ""
        },
        "onClick": {
          "type": "code",
          "value": "props.onUpload"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "label",
      "parent": 34,
      "props": {
        "children": {
          "type": "value",
          "value": "Upload"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.75rem\"}"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "Icon",
      "parent": 34,
      "props": {
        "name": {
          "type": "value",
          "value": "upload"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "FormGroup",
      "parent": 10,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "FormField",
      "parent": 37,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "label",
      "parent": 38,
      "props": {
        "children": {
          "type": "code",
          "value": "props.fileName || \"No File chosen\""
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.5rem\", fontWeight: \"normal\"}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 38,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.fileName? \"\": \"none\"}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "Icon",
      "parent": 41,
      "props": {
        "link": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "close"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClearFile"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonDownload"
        }
      },
      "seq": 43,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 100,
  "isMounted": true,
  "memo": false,
  "name": "CardImportLabValuesCalcDoctorFeeUX",
  "project": "CLM CU",
  "screenPropsDefault": {
  },
  "width": 100
}

*********************************************************************************** */
