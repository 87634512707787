import React, { useState, useRef, MutableRefObject, CSSProperties, useEffect, SyntheticEvent } from "react";
import { Dropdown } from 'semantic-ui-react';
import { useIntl } from "react-intl";

export const ColumnStyle = {
    background: "rgb(255, 255, 204)",
    marginTop: -7,
    marginLeft: -4,
    width: "calc(100% + 10px)",
    height: "calc(100% + 14px)",
    padding: "7px 7px",
  } as CSSProperties;

const DropDownColumn:  React.FC<any> = (props) => {
    // showText use when options of Dropdowncolumn have many child with different
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [options, setOptions] = useState<any[]>([])

    const dropdownRef = useRef() as MutableRefObject<any>;

    useEffect(() => {
      setOptions(props.options);
    }, [props.options]);

    const handleClick = () => {
      if(props.readOnly){
        setIsEdit(false);
      }
      else{
        setIsEdit(true);
        setTimeout(() => {
          dropdownRef.current?.open();
        }, 100);
      }
    };

    const handleClose = () => {
      setIsEdit(false)
    }

    const handleValue = (e, data) => {
      props.onDataChanged?.(data.value);

      setIsEdit(false);
    };

    const handleAdditionOptions = (e: SyntheticEvent, v: any) => {
      setOptions([...options, { key: v.value, text: v.value, value: v.value }]);
    };

    return (
    <div style={{ ...ColumnStyle, background: props.backgroundColor ?? ColumnStyle.background }} onClick={handleClick}>
      {isEdit ? (
        <Dropdown
          ref={dropdownRef}
          className="fluidDropdown"
          style={{position: "absolute", maxWidth: "200px"}}
          disabled={props.readOnly}
          allowAdditions={props.allowAdditions}
          selection
          search
          clearable={props.clearable}
          value={props.value}
          options={options}
          onClose={handleClose}
          onBlur={handleClose}
          onChange={handleValue}
          onClick={handleClick}
          onAddItem={handleAdditionOptions}
      />
      ) : (
        <div style={{ textAlign: "center" }}>{options?.find(o => o.value === props.value)?.text || props.showText || ""}</div>
      )}
    </div>
    );
  };

  export default DropDownColumn