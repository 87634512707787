
/* Generated apis ---------------------- */
import { to } from 'react-lib/utils'
import BaseService from 'react-lib/apis/services/BaseService'
import config from '../../config/config'
const HOST = `${config.API_HOST}`
/* ['DivisionHasUserDetail'] */
/* params:  */
/* data:  */
const DivisionHasUserDetailForOtherUser:
  {
    get: ({ user_id, pk, params, apiToken, extra }:
      {
        user_id: any, pk: any,
        params?: any,
        apiToken?: any,
        extra?: any,
      }) => any,
    post: ({ params, data, apiToken, extra }:
      {
        user_id: any, pk: any,
        params?: any,
        data?: any,
        apiToken?: any,
        extra?: any
      }) => any,
    put: ({ user_id, pk, params, data, apiToken, extra }:
      {
        user_id: any, pk: any,
        params?: any,
        data?: any,
        apiToken?: any,
        extra?: any,
      }) => any,
    patch: ({ user_id, pk, params, data, apiToken, extra }:
      {
        user_id: any, pk: any,
        params?: any,
        data?: any,
        apiToken?: any,
        extra?: any,
      }) => any,
    delete: ({ user_id, pk, params, data, apiToken, extra }:
      {
        user_id: any, pk: any,
        params?: any,
        data?: any,
        apiToken?: any,
        extra?: any,
      }) => any,
  } =
{
  get: async ({ user_id, pk, params, apiToken, extra }) => {
    const base = new BaseService()
    if (apiToken)
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    if (extra?.division)
      base.client.defaults.headers["division"] = extra.division;
    if (extra?.device)
      base.client.defaults.headers["device"] = extra.device;
    const result = await to(base.client.get(`${HOST}/apis/PRX/users/${user_id}/division-has-user/${pk}/`,
      {
        params: params ? { ...params } : {},
        ...extra
      }
    )
      .then(base.handleResponse)
      .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  post: async ({ user_id, pk, params, data, apiToken, extra }) => {
    const base = new BaseService()
    if (apiToken)
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    if (extra?.division)
      base.client.defaults.headers["division"] = extra.division;
    if (extra?.device)
      base.client.defaults.headers["device"] = extra.device;
    const result = await to(base.client.post(`${HOST}/apis/PRX/users/${user_id}/division-has-user/${pk}/`,
      {
        ...data
      },
      {
        params: params ? { ...params } : {},
        ...extra
      }
    )
      .then(base.handleResponse)
      .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  put: async ({ user_id, pk, params, data, apiToken, extra }) => {
    const base = new BaseService()
    if (apiToken)
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    if (extra?.division)
      base.client.defaults.headers["division"] = extra.division;
    if (extra?.device)
      base.client.defaults.headers["device"] = extra.device;
    const result = await to(base.client.put(`${HOST}/apis/PRX/users/${user_id}/division-has-user/${pk}/`,
      {
        ...data
      },
      {
        params: params ? { ...params } : {},
        ...extra
      }
    )
      .then(base.handleResponse)
      .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  patch: async ({ user_id, pk, params, data, apiToken, extra }) => {
    const base = new BaseService()
    if (apiToken)
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    if (extra?.division)
      base.client.defaults.headers["division"] = extra.division;
    if (extra?.device)
      base.client.defaults.headers["device"] = extra.device;
    const result = await to(base.client.patch(`${HOST}/apis/PRX/users/${user_id}/division-has-user/${pk}/`,
      {
        ...data
      },
      {
        params: params ? { ...params } : {},
        ...extra
      }
    )
      .then(base.handleResponse)
      .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
  delete: async ({ user_id, pk, params, data, apiToken, extra }) => {
    const base = new BaseService()
    if (apiToken)
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    if (extra?.division)
      base.client.defaults.headers["division"] = extra.division;
    if (extra?.device)
      base.client.defaults.headers["device"] = extra.device;
    const result = await to(base.client.delete(`${HOST}/apis/PRX/users/${user_id}/division-has-user/${pk}/`,
      {
        params: params ? { ...params } : {},
        ...extra
      }
    )
      .then(base.handleResponse)
      .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

export default DivisionHasUserDetailForOtherUser

