import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Checkbox,
  Input,
  Dropdown
} from 'semantic-ui-react'

const ANES_PACU_nursingCare = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        id="CardPACURecordMonitor"
        style={{ width: "100%", height: "100%" ,padding:"10px"}}>
        <div
          style={{backgroundColor: "#EDF6F9",width: "100%",height:"80%",padding:"10px",borderRadius: 3,border : "solid 0.5px ",borderColor:"#5DBCD2"}}>
          
          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(20, 1fr)","gridTemplateColumns":"repeat(40, 1fr)"}}>
            <div
              style={{ gridRow: "2/3", gridColumn: "1/7"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                Monitors : 
              </label>
            </div>
            <div
              style={{ gridRow: "3/4", gridColumn: "1/7"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                Nursing care :
              </label>
            </div>
            <div
              style={{ gridRow: "4/5", gridColumn: "2/10"}}>
              <div
                style={{ display: "flex",  alignItems:"center",height:"100%",width:"100%"}}>
                
                <Checkbox
                  checked={props.PACURecordSequence?.data?.ObserveRRAndVS || false}
                  name="ObserveRRAndVS"
                  onChange={props.onChangeData}>
                </Checkbox>
                <label
                  style={{ marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                  Observe RR & V/S
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "5/6", gridColumn: "2/10"}}>
              <div
                style={{ display: "flex",  alignItems:"center",height:"100%",width:"100%"}}>
                
                <Checkbox
                  checked={props.PACURecordSequence?.data?.PainAssessment || false}
                  name="PainAssessment"
                  onChange={props.onChangeData}>
                </Checkbox>
                <label
                  style={{ marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                  Plain assessment
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "6/7", gridColumn: "2/10"}}>
              <div
                style={{ display: "flex",  alignItems:"center",height:"100%",width:"100%"}}>
                
                <Checkbox
                  checked={props.PACURecordSequence?.data?.KeepWarm || false}
                  name="KeepWarm"
                  onChange={props.onChangeData}>
                </Checkbox>
                <label
                  style={{ marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                  Keep warm
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "7/8", gridColumn: "2/8"}}>
              <div
                style={{ display: "flex",  alignItems:"center",height:"100%",width:"100%"}}>
                
                <Checkbox
                  checked={props.PACURecordSequence?.data?.TympanicTemperature || false}
                  name="TympanicTemperature"
                  onChange={props.onChangeData}>
                </Checkbox>
                <label
                  style={{ marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                  Tympanic Temperature
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "8/9", gridColumn: "2/8"}}>
              <div
                style={{ display: "flex",  alignItems:"center",height:"100%",width:"100%"}}>
                
                <Checkbox
                  checked={props.PACURecordSequence?.data?.NursingCareOther || false}
                  name="NursingCareOther"
                  onChange={props.onChangeData}>
                </Checkbox>
                <label
                  style={{ marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                  Others
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "4/5", gridColumn: "16/24"}}>
              <div
                style={{ display: "flex",  alignItems:"center",height:"100%",width:"100%"}}>
                
                <Checkbox
                  checked={props.PACURecordSequence?.data?.FallPrevention || false}
                  name="FallPrevention"
                  onChange={props.onChangeData}>
                </Checkbox>
                <label
                  style={{ marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                  Fall prevention
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "5/6", gridColumn: "16/24"}}>
              <div
                style={{ display: "flex",  alignItems:"center",height:"100%",width:"100%"}}>
                
                <Checkbox
                  checked={props.PACURecordSequence?.data?.RespiratoryCare || false}
                  name="RespiratoryCare"
                  onChange={props.onChangeData}>
                </Checkbox>
                <label
                  style={{ marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                  Respiratory care
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "6/7", gridColumn: "16/24"}}>
              <div
                style={{ display: "flex",  alignItems:"center",height:"100%",width:"100%"}}>
                
                <Checkbox
                  checked={props.PACURecordSequence?.data?.IsPositionManagement || false}
                  name="IsPositionManagement"
                  onChange={props.onChangeData}>
                </Checkbox>
                <label
                  style={{ marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                  Position management
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "7/8", gridColumn: "8/24"}}>
              <Input
                name="TympanicTemperatureRemark"
                onChange={props.onChangeData}
                style={{ display: "flex" ,height:"90%",width:"100%"}}
                value={props.PACURecordSequence?.data?.TympanicTemperatureRemark || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "8/9", gridColumn: "8/24" }}>
              <Input
                name="NursingCareOtherRemark"
                onChange={props.onChangeData}
                style={{ display: "flex" ,height:"90%",width:"100%"}}
                value={props.PACURecordSequence?.data?.NursingCareOtherRemark || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "10/11", gridColumn: "1/7"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                Attending staff
              </label>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "7/12"}}>
              <div
                style={{ display: "flex",  alignItems:"center",height:"100%",width:"100%"}}>
                
                <Checkbox
                  checked={props.PACURecordSequence?.data?.IsNIBP || false}
                  name="IsNIBP"
                  onChange={props.onChangeData}>
                </Checkbox>
                <label
                  style={{ marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                  NIBP
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "13/18" }}>
              <div
                style={{ display: "flex",  alignItems:"center",height:"100%",width:"100%"}}>
                
                <Checkbox
                  checked={props.PACURecordSequence?.data?.IsSpO2 || false}
                  name="IsSpO2"
                  onChange={props.onChangeData}>
                </Checkbox>
                <label
                  style={{ marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                  SpO2
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "19/24"}}>
              <div
                style={{ display: "flex",  alignItems:"center",height:"100%",width:"100%"}}>
                
                <Checkbox
                  checked={props.PACURecordSequence?.data?.IsEKG || false}
                  name="IsEKG"
                  onChange={props.onChangeData}>
                </Checkbox>
                <label
                  style={{ marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                  EKG
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "2/3", gridColumn: "25/30"}}>
              <div
                style={{ display: "flex",  alignItems:"center",height:"100%",width:"100%"}}>
                
                <Checkbox
                  checked={props.PACURecordSequence?.data?.IsUrine || false}
                  name="IsUrine"
                  onChange={props.onChangeData}>
                </Checkbox>
                <label
                  style={{ marginLeft:"10px",display: "flex",height:"40px", fontSize:"16px",alignItems:"center"}}>
                  Urine
                </label>
              </div>
            </div>
            <div
              style={{ gridRow: "11/12", gridColumn: "1/7"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                PACU Nurse
              </label>
            </div>
            <div
              style={{ gridRow: "10/11", gridColumn: "7/13"}}>
              <Input
                name="AttendingStaff"
                onChange={props.onChangeData}
                readOnly={true}
                style={{ display: "none" ,height:"90%",width:"100%"}}
                value={props.PACURecordSequence?.data?.AttendingStaff || ""}>
              </Input>
              <Dropdown
                clearable="true"
                fluid={true}
                name="AttendingStaff"
                onChange={props.onChangeData}
                options={props.masterOptions.doctor}
                search="true"
                selection={true}
                value={props.PACURecordSequence?.data?.AttendingStaff || ""}>
              </Dropdown>
            </div>
            <div
              style={{ gridRow: "11/12", gridColumn: "7/13"}}>
              <Input
                name="PACUNurse"
                onChange={props.onChangeData}
                readOnly={true}
                style={{ display: "none" ,height:"90%",width:"100%"}}
                value={props.PACURecordSequence?.data?.PACUNurse || ""}>
              </Input>
              <Dropdown
                clearable="true"
                fluid={true}
                name="PACUNurse"
                onChange={props.onChangeData}
                options={props.userNurseOptions}
                search="true"
                selection={true}
                style={{ display: "flex" ,height:"90%",width:"100%"}}
                value={props.PACURecordSequence?.data?.PACUNurse || ""}>
              </Dropdown>
            </div>
            <div
              style={{ gridRow: "10/11", gridColumn: "14/20"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                License number
              </label>
            </div>
            <div
              style={{ gridRow: "11/12", gridColumn: "14/20"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                License number
              </label>
            </div>
            <div
              style={{ gridRow: "10/11", gridColumn: "20/26"}}>
              <Input
                name="AttendingStaffLicenseNumber"
                onChange={props.onChangeData}
                readOnly={true}
                style={{ display: "flex" ,height:"90%",width:"100%"}}
                value={props.PACURecordSequence?.data?.AttendingStaffLicenseNumber || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "11/12", gridColumn: "20/26"}}>
              <Input
                name="PACUNurseLicenseNumber"
                onChange={props.onChangeData}
                readOnly={true}
                style={{ display: "flex" ,height:"90%",width:"100%"}}
                value={props.PACURecordSequence?.data?.PACUNurseLicenseNumber || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "10/11", gridColumn: "27/29"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                Code
              </label>
            </div>
            <div
              style={{ gridRow: "11/12", gridColumn: "27/29"}}>
              <label
                style={{display: "flex",height:"40px", fontSize:"16px",fontWeight:"bold",alignItems:"center"}}>
                Code
              </label>
            </div>
            <div
              style={{ gridRow: "10/11", gridColumn: "30/36"}}>
              <Input
                name="AttendingStaffCode"
                onChange={props.onChangeData}
                onKeyDown={props.onKeyAttendingStaff}
                style={{ display: "flex" ,height:"90%",width:"100%"}}
                value={props.PACURecordSequence?.data?.AttendingStaffCode || ""}>
              </Input>
            </div>
            <div
              style={{ gridRow: "11/12", gridColumn: "30/36"}}>
              <Input
                style={{ display: "flex" ,height:"90%",width:"100%"}}>
                <Input
                  name="PACUNurseCode"
                  onChange={props.onChangeData}
                  onKeyDown={props.onKeyPACUNurse}
                  style={{ display: "flex" ,height:"90%",width:"100%"}}
                  value={props.PACURecordSequence?.data?.PACUNurseCode || ""}>
                </Input>
              </Input>
            </div>
          </div>
        </div>
      </div>
    )
}


export default ANES_PACU_nursingCare

export const screenPropsDefault = {}

/* Date Time : Tue Dec 26 2023 10:21:23 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardPACURecordMonitor"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"100%\" ,padding:\"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#EDF6F9\",width: \"100%\",height:\"80%\",padding:\"10px\",borderRadius: 3,border : \"solid 0.5px \",borderColor:\"#5DBCD2\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "label": "divgrid",
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(20, 1fr)\",\"gridTemplateColumns\":\"repeat(40, 1fr)\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"1/7\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "label",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "Monitors : "
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"3/4\", gridColumn: \"1/7\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"2/10\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"2/10\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"2/10\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"2/8\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/9\", gridColumn: \"2/8\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"4/5\", gridColumn: \"16/24\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"5/6\", gridColumn: \"16/24\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"6/7\", gridColumn: \"16/24\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/8\", gridColumn: \"8/24\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"8/9\", gridColumn: \"8/24\" }"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"1/7\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "label",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": "Nursing care :"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"7/12\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"13/18\" }"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"19/24\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/3\", gridColumn: \"25/30\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "Checkbox",
      "parent": 31,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.ObserveRRAndVS || false"
        },
        "name": {
          "type": "value",
          "value": "ObserveRRAndVS"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "label",
      "parent": 31,
      "props": {
        "children": {
          "type": "value",
          "value": "Observe RR & V/S"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "Checkbox",
      "parent": 34,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.PainAssessment || false"
        },
        "name": {
          "type": "value",
          "value": "PainAssessment"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "label",
      "parent": 34,
      "props": {
        "children": {
          "type": "value",
          "value": "Plain assessment"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 17,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "Checkbox",
      "parent": 37,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.KeepWarm || false"
        },
        "name": {
          "type": "value",
          "value": "KeepWarm"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "label",
      "parent": 37,
      "props": {
        "children": {
          "type": "value",
          "value": "Keep warm"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "Checkbox",
      "parent": 40,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.TympanicTemperature || false"
        },
        "name": {
          "type": "value",
          "value": "TympanicTemperature"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "label",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": "Tympanic Temperature"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "Checkbox",
      "parent": 43,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.NursingCareOther || false"
        },
        "name": {
          "type": "value",
          "value": "NursingCareOther"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "label",
      "parent": 43,
      "props": {
        "children": {
          "type": "value",
          "value": "Others"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "Checkbox",
      "parent": 46,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.FallPrevention || false"
        },
        "name": {
          "type": "value",
          "value": "FallPrevention"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "label",
      "parent": 46,
      "props": {
        "children": {
          "type": "value",
          "value": "Fall prevention"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "div",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "Checkbox",
      "parent": 49,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.RespiratoryCare || false"
        },
        "name": {
          "type": "value",
          "value": "RespiratoryCare"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "label",
      "parent": 49,
      "props": {
        "children": {
          "type": "value",
          "value": "Respiratory care"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "div",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "Checkbox",
      "parent": 52,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.IsPositionManagement || false"
        },
        "name": {
          "type": "value",
          "value": "IsPositionManagement"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "label",
      "parent": 52,
      "props": {
        "children": {
          "type": "value",
          "value": "Position management"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 29,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "Checkbox",
      "parent": 58,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.IsEKG || false"
        },
        "name": {
          "type": "value",
          "value": "IsEKG"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "label",
      "parent": 58,
      "props": {
        "children": {
          "type": "value",
          "value": "EKG"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "div",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "Checkbox",
      "parent": 61,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.IsNIBP || false"
        },
        "name": {
          "type": "value",
          "value": "IsNIBP"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "label",
      "parent": 61,
      "props": {
        "children": {
          "type": "value",
          "value": "NIBP"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "div",
      "parent": 30,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "Checkbox",
      "parent": 67,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.IsUrine || false"
        },
        "name": {
          "type": "value",
          "value": "IsUrine"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "label",
      "parent": 67,
      "props": {
        "children": {
          "type": "value",
          "value": "Urine"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "div",
      "parent": 28,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\",  alignItems:\"center\",height:\"100%\",width:\"100%\"}"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "Checkbox",
      "parent": 70,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.IsSpO2 || false"
        },
        "name": {
          "type": "value",
          "value": "IsSpO2"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "label",
      "parent": 70,
      "props": {
        "children": {
          "type": "value",
          "value": "SpO2"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft:\"10px\",display: \"flex\",height:\"40px\", fontSize:\"16px\",alignItems:\"center\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "Input",
      "parent": 24,
      "props": {
        "name": {
          "type": "value",
          "value": "NursingCareOtherRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"90%\",width:\"100%\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.NursingCareOtherRemark || \"\""
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "Input",
      "parent": 23,
      "props": {
        "name": {
          "type": "value",
          "value": "TympanicTemperatureRemark"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"90%\",width:\"100%\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.TympanicTemperatureRemark || \"\""
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "label",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": "Attending staff"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/12\", gridColumn: \"1/7\"}"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "label",
      "parent": 77,
      "props": {
        "children": {
          "type": "value",
          "value": "PACU Nurse"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"7/13\"}"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/12\", gridColumn: \"7/13\"}"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 81,
      "name": "Input",
      "parent": 79,
      "props": {
        "name": {
          "type": "value",
          "value": "AttendingStaff"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "value",
          "value": ""
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"none\" ,height:\"90%\",width:\"100%\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.AttendingStaff || \"\""
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "Input",
      "parent": 80,
      "props": {
        "name": {
          "type": "value",
          "value": "PACUNurse"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "value",
          "value": ""
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"none\" ,height:\"90%\",width:\"100%\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.PACUNurse || \"\""
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"14/20\"}"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 84,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/12\", gridColumn: \"14/20\"}"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"20/26\"}"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": null,
      "id": 86,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/12\", gridColumn: \"20/26\"}"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "label",
      "parent": 83,
      "props": {
        "children": {
          "type": "value",
          "value": "License number"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "label",
      "parent": 84,
      "props": {
        "children": {
          "type": "value",
          "value": "License number"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 90,
      "name": "Input",
      "parent": 85,
      "props": {
        "name": {
          "type": "value",
          "value": "AttendingStaffLicenseNumber"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "value",
          "value": ""
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"90%\",width:\"100%\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.AttendingStaffLicenseNumber || \"\""
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 91,
      "name": "Input",
      "parent": 86,
      "props": {
        "name": {
          "type": "value",
          "value": "PACUNurseLicenseNumber"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "value",
          "value": ""
        },
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"90%\",width:\"100%\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.PACUNurseLicenseNumber || \"\""
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"27/29\"}"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 93,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/12\", gridColumn: \"27/29\"}"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"10/11\", gridColumn: \"30/36\"}"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "div",
      "parent": 2,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"11/12\", gridColumn: \"30/36\"}"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": null,
      "id": 96,
      "name": "label",
      "parent": 92,
      "props": {
        "children": {
          "type": "value",
          "value": "Code"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "label",
      "parent": 93,
      "props": {
        "children": {
          "type": "value",
          "value": "Code"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",height:\"40px\", fontSize:\"16px\",fontWeight:\"bold\",alignItems:\"center\"}"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 98,
      "name": "Input",
      "parent": 94,
      "props": {
        "name": {
          "type": "value",
          "value": "AttendingStaffCode"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "code",
          "value": "props.onKeyAttendingStaff"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"90%\",width:\"100%\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.AttendingStaffCode || \"\""
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 99,
      "name": "Input",
      "parent": 95,
      "props": {
        "onKeyDown": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"90%\",width:\"100%\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 101,
      "name": "Input",
      "parent": 99,
      "props": {
        "name": {
          "type": "value",
          "value": "PACUNurseCode"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "onKeyDown": {
          "type": "code",
          "value": "props.onKeyPACUNurse"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"90%\",width:\"100%\"}"
        },
        "type": {
          "type": "value",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.PACUNurseCode || \"\""
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 102,
      "name": "Dropdown",
      "parent": 79,
      "props": {
        "clearable": {
          "type": "value",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "AttendingStaff"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions.doctor"
        },
        "search": {
          "type": "value",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.AttendingStaff || \"\""
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 103,
      "name": "Dropdown",
      "parent": 80,
      "props": {
        "clearable": {
          "type": "value",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "PACUNurse"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.userNurseOptions"
        },
        "search": {
          "type": "value",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" ,height:\"90%\",width:\"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.PACURecordSequence?.data?.PACUNurse || \"\""
        }
      },
      "seq": 103,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "ANES_PACU_nursingCare",
  "project": "IsHealth_by_Front-end",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
