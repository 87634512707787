import React, { useState, useEffect, useMemo } from "react";
import { Dropdown, Form, Input, Modal, Segment, Button } from "semantic-ui-react";
// Common
import DateTextBox from "react-lib/apps/common/DateTextBox";
import TimeTextBox from "react-lib/apps/common/TimeTextBox";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import TimeFreeTextBox24 from "react-lib/apps/common/TimeFreeTextBox24";

// Utils
import { beStringDateTimeToDateObject } from "react-lib/utils/dateUtils";
import { useIntl } from "react-intl";
// import moment from "moment";

// Types
type ORDischargeComponentProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
  // data
  open: boolean;
  selectedOrOrder?: Record<string, any>;
  orDischargeResult?: Record<string, any>;
  errorMessage?: any;
  // options
  masterOptions?: Record<string, any[]>;
  // callback
  onClose: () => any;
};

const OR_DISCHARGE_COMPONENT = "ORDischargeComponent";
// const currentTime = moment().format("HH:mm");

const ORDischargeComponent = (props: ORDischargeComponentProps) => {
  const intl = useIntl();
  const [payload, setPayload] = useState<any>({
    predischarge_condition: "",
    discharge_status: "",
    discharge_note: "",
    transfer_location: "",
    patient_out_date: "",
    patient_out_time: "00:00",
  });

  const [openModConfirmCompensation, setOpenModConfirmCompensation] = useState<boolean>(false);
  const [openModConfirmAdmit, setOpenModConfirmAdmit] = useState<boolean>(false);

  useEffect(() => {
    props.onEvent({ message: "GetDischargeMaster" });
    props.setProp("orDischargeResult", null);
  }, [props.selectedOrOrder]);

  // TODO: Check if need same as 68657
  // useEffect(() => {
  //   if (props.masterOptions?.patientCondition && props.masterOptions?.patientCondition?.length > 0) {
  //     setPayload({...payload, predischarge_condition: props.masterOptions?.patientCondition[0]?.value})
  //   }
  // }, [props.masterOptions?.patientCondition])

  // useEffect(() => {
  //   if (props.masterOptions?.patientDischarge && props.masterOptions?.patientDischarge?.length > 0) {
  //     setPayload({...payload, discharge_status: props.masterOptions?.patientDischarge[0]?.value})
  //   }
  // }, [props.masterOptions?.patientDischarge])
  

  const orTransferLocation = useMemo(() => {
    const options = props.masterOptions?.orTransferLocation || [];

    return options.filter((option) => option.value);
  }, [props.masterOptions?.orTransferLocation]);

  const closeModORDischarge = () => {
    setPayload({
      predischarge_condition: "",
      discharge_status: "",
      discharge_note: "",
      transfer_location: "",
      patient_out_date: "",
      patient_out_time: "",
    });

    setOpenModConfirmCompensation(false);
    setOpenModConfirmAdmit(false);

    handleCloseErrMsg();

    props.onClose();
    props.onEvent({
      message: "GetOROrderByID",
      params: {
        order: props.selectedOrOrder,
      },
    });
  };

  const handleChangeORDischarge = (_event: any, data: any) => {
    setPayload({ ...payload, [data.name]: data.value });
  };

  const handleChangeDischargeDate = (date: string) => {
    setPayload({ ...payload, patient_out_date: date });
  };

  const handleChangeDischargeTime = (_event: any, time: string) => {
    setPayload({ ...payload, patient_out_time: time });
  };

  const handleChangeDischargeTime24 = (time: string) => {
    setPayload({ ...payload, patient_out_time: time });
  }

  const handleORDischarge = () => {
    props.onEvent({
      message: "ORDischarge",
      params: {
        order: props.selectedOrOrder,
        result: "",
        admit_detail: "",
        admit_result: "",
        predischarge_condition: payload.predischarge_condition,
        discharge_status: payload.discharge_status,
        discharge_note: payload.discharge_note,
        transfer_location: payload.transfer_location,
        patient_out_datetime: beStringDateTimeToDateObject(
          payload.patient_out_date + "-" + payload.patient_out_time
        ),
        card: OR_DISCHARGE_COMPONENT,
      },
    });
    setOpenModConfirmCompensation(true);
  };

  const handleConfirmCompensation = () => {
    props.onEvent({
      message: "ORConfirmCompensation",
      params: {
        order: props.selectedOrOrder,
      },
    });
    handleShowConfirmAdmit();
  };
  const handleShowConfirmAdmit = () => {
    if (props.orDischargeResult?.admit_result === "CONFIRM") {
      setOpenModConfirmAdmit(true);
    } else if (props.orDischargeResult?.admit_result === "INFO") {
      alert(props.orDischargeResult?.admit_detail);
      closeModORDischarge();
    } else {
      closeModORDischarge();
    }
  };

  const handleConfirmAdmit = () => {
    props.onEvent({
      message: "ORConfirmAdmit",
      params: {
        order: props.selectedOrOrder,
      },
    });

    closeModORDischarge();
  };

  const handleCloseErrMsg = () => {
    props.setProp(`errorMessage.${OR_DISCHARGE_COMPONENT}`, null);
  };

  return (
    <>
      <Modal
        size="small"
        closeIcon
        open={props.open}
        onClose={closeModORDischarge}
        style={{ willChange: "top,left,margin,opacity" }}
      >
        <SnackMessage
          onEvent={props.onEvent}
          onClose={handleCloseErrMsg}
          error={props.errorMessage?.[OR_DISCHARGE_COMPONENT]}
          success={null}
          languageUX={props.languageUX}
        />
        {/* @ts-ignore */}
        <Segment inverted className="blue" style={{ margin: "0px" }}>
          Discharge
        </Segment>
        <Modal.Content>
          {/* @ts-ignore */}
          <Form>
            <Form.Group inline>
              <Form.Field width={4}>
                <label>{intl.formatMessage({ id: "สภาพผู้ป่วยก่อนจำหน่าย" })}</label>
              </Form.Field>
              <Form.Field width={5}>
                <Dropdown
                  className="fluidDropdown"
                  selection
                  search
                  name="predischarge_condition"
                  value={payload.predischarge_condition || ""}
                  options={props.masterOptions?.patientCondition || []}
                  onChange={handleChangeORDischarge}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group inline>
              <Form.Field width={4}>
                <label>Discharge Status</label>
              </Form.Field>
              <Form.Field width={5}>
                <Dropdown
                  className="fluidDropdown"
                  selection
                  search
                  name="discharge_status"
                  value={payload.discharge_status || ""}
                  options={props.masterOptions?.patientDischarge || []}
                  onChange={handleChangeORDischarge}
                />
              </Form.Field>
              <Form.Field width={2}>
                <label style={{ width: "100%", textAlign: "right" }}>{intl.formatMessage({ id: "ระบุ" })}</label>
              </Form.Field>
              <Form.Field width={5}>
                <Input
                  name="discharge_note"
                  value={payload.discharge_note || ""}
                  onChange={handleChangeORDischarge}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group inline>
              <Form.Field width={4}>
                <label>Transfer to</label>
              </Form.Field>
              <Form.Field width={12}>
                <Dropdown
                  className="fluidDropdown"
                  name="transfer_location"
                  value={payload.transfer_location || ""}
                  selection
                  clearable
                  search
                  options={orTransferLocation}
                  onChange={handleChangeORDischarge}
                />
              </Form.Field>
            </Form.Group>
            {/* <Form.Group inline>
              <Form.Field width={4}>
                <label>Patient out</label>
              </Form.Field>
              <Form.Field width={6}>
                <DateTextBox
                  value={payload.patient_out_date}
                  onChange={handleChangeDischargeDate}
                />
              </Form.Field>
              <Form.Field width={6}>
                <TimeTextBox
                  value={payload.patient_out_time}
                  useDefaultTime={true}
                  onTimeChange={handleChangeDischargeTime}
                />
              </Form.Field>
            </Form.Group> */}
          </Form>
          <div style={{ display: "flex", alignItems: "center" }}>
            <label style={{ width: "170px", fontWeight: "bold", fontSize: "13px" }}>Patient out</label>
              <DateTextBox
                value={payload.patient_out_date}
                onChange={handleChangeDischargeDate}
                style={{ width: "240px" }}
              />
            <TimeFreeTextBox24
              value={payload.patient_out_time}
              onChange={handleChangeDischargeTime24}
              autoFocus={false}
            />
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={handleORDischarge}>
            ใช่
          </Button>
          <Button color="red" onClick={closeModORDischarge}>
            ไม่
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal size="small" open={!!openModConfirmCompensation && !!props.orDischargeResult}>
        {/* @ts-ignore */}
        <Segment inverted className="blue" style={{ margin: "0px" }}>
          ยืนยันรายการค่าตอบแทน
        </Segment>
        <Modal.Content>{props.orDischargeResult?.result}</Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={handleConfirmCompensation}>
            ใช่
          </Button>
          <Button color="red" onClick={handleShowConfirmAdmit}>
            ไม่
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal size="mini" open={openModConfirmAdmit}>
        {/* @ts-ignore */}
        <Segment inverted className="blue" style={{ margin: "0px" }}>
          ยืนยันการ admit
        </Segment>
        <Modal.Content>{props.orDischargeResult?.admit_detail}</Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={handleConfirmAdmit}>
            ใช่
          </Button>
          <Button color="red" onClick={() => setOpenModConfirmAdmit(false)}>
            ไม่
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

ORDischargeComponent.displayName = "ORDischargeComponent";
export default React.memo(ORDischargeComponent);
