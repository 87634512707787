import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Checkbox,
  Label,
  Dropdown,
  Button
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardReportPatientListUX = (props: any) => {
    return(
      <div
        style={{ margin: "10px" }}>
        <div
          className="ui form">
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <Checkbox
                checked={props.filter?.isHN || false}
                label="HN"
                name="isHN"
                onChange={props.onChangeValue}
                style={{fontWeight: "bold"}}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{width: "100%"}}>
                {props.PatientSearchBox}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "max-content"}}>
                ชื่อ-สกุลผู้ป่วย
              </label>
            </FormField>
            <FormField>
              <Label
                size="large"
                style={{ minWidth: "max-content", width: "10rem", padding:"0.833em"}}>
                {props.fullName || "-"}
              </Label>
            </FormField>
            <FormField>
              <label
                style={{minWidth: "max-content"}}>
                ประเภทผู้ป่วย
              </label>
            </FormField>
            <FormField
              width={2}>
              <Dropdown
                fluid={true}
                name="patientCase"
                onChange={props.onChangeValue}
                options={props.patientCaseOptions || []}
                selection={true}
                style={{width: "100%", minWidth: "max-content"}}
                value={props.filter?.patientCase || ""}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}>
              <label>
                คลินิก
              </label>
            </FormField>
            <FormField
              inline={true}
              width="3">
              <div
                style={{width: "100%"}}>
                {props.ClinicSearchBox}
              </div>
            </FormField>
            <FormField>
              <label
                style={{minWidth: "max-content"}}>
                ประเภท Visit
              </label>
            </FormField>
            <FormField>
              <Checkbox
                checked={props.filter?.isOPD || false}
                label="OPD"
                name="isOPD"
                onChange={props.onChangeValue}
                style={{fontWeight: "bold"}}>
              </Checkbox>
            </FormField>
            <FormField>
              <Checkbox
                checked={props.filter?.isIPD || false}
                label="IPD"
                name="isIPD"
                onChange={props.onChangeValue}
                style={{fontWeight: "bold"}}>
              </Checkbox>
            </FormField>
            <FormField>
              <Button
                color="yellow"
                onClick={props.onClear}
                size="small"
                style={{ minWidth: "max-content"}}>
                ล้างการค้นหา
              </Button>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <Checkbox
                checked={props.filter?.isDoctor ||false}
                label="แพทย์เจ้าของไข้"
                name="isDoctor"
                onChange={props.onChangeValue}
                style={{fontWeight: "bold", minWidth: "max-content"}}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <div
                style={{width: "100%"}}>
                {props.DoctorSearchBox}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <Checkbox
                checked={props.filter?.isDiagnosis ||false}
                label="Diagnosis"
                name="isDiagnosis"
                onChange={props.onChangeValue}
                style={{fontWeight: "bold", minWidth: "max-content"}}>
              </Checkbox>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{width: "100%"}}>
                {props.DiagnosisSearchBox}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{ minWidth: "max-content"}}>
                วันที่
              </label>
            </FormField>
            <FormField
              inline={true}>
              <div
                style={{width: "100%"}}>
                {props.StartDate}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <label>
                ถึง
              </label>
            </FormField>
            <FormField
              inline={true}>
              <div
                style={{width: "100%"}}>
                {props.EndDate}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <div>
                {props.ButtonSearch}
              </div>
            </FormField>
            <FormField
              inline="true">
              <div>
                {props.ButtonPrint}
              </div>
            </FormField>
            <FormField
              inline="true">
              <div>
                {props.ButtonStatistic}
              </div>
            </FormField>
            <FormField>
              <div>
                {props.ButtonStatisticPrint}
              </div>
            </FormField>
          </FormGroup>
        </div>
        <div>
          
          <Table
            data={props.orderItems || []}
            headers="วันที่รับเข้า,คลินิก/Ward,HN (VN/AN),ชื่อ-นามสกุล,ประเภทผู้ป่วย,ประเภท Visit, แพทย์เจ้าของใข้,Diagnosis"
            keys="encounter_datetime,division_name,hn_encounter_number,patient_fullname,patient_case_name,encounter_general_type,doctor_name,icd10_name"
            showPagination={false}
            style={{height: "calc(100vh - 16rem)", display: props.showStatistic ? "none": ""}}
            widths="120">
          </Table>
          <Table
            data={props.statItems || []}
            headers="ลำดับ,แพทย์เจ้าของไข้,ประเภทผู้ป่วย,ประเภท Visit,Diagnosis, จำนวน"
            keys="no,doctor_name,patient_case_name,encounter_general_type,icd10_name, encounter_count"
            showPagination={false}
            style={{height: "calc(100vh - 16rem)", display: props.showStatistic ? "": "none"}}
            widths="100,auto,auto,auto,auto,150">
          </Table>
        </div>
      </div>
    )
}

export default React.memo(CardReportPatientListUX)

export const screenPropsDefault = {}

/* Date Time : Tue Mar 21 2023 08:59:45 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ margin: \"10px\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 2,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormField",
      "parent": 2,
      "props": {
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormField",
      "parent": 2,
      "props": {
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "Checkbox",
      "parent": 3,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filter?.isHN || false"
        },
        "label": {
          "type": "value",
          "value": "HN"
        },
        "name": {
          "type": "value",
          "value": "isHN"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "code",
          "value": "props.PatientSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "label",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ-สกุลผู้ป่วย"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "Label",
      "parent": 6,
      "props": {
        "children": {
          "type": "code",
          "value": "props.fullName || \"-\""
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{ minWidth: \"max-content\", width: \"10rem\", padding:\"0.833em\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "FormField",
      "parent": 2,
      "props": {
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "FormField",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "value",
          "value": "3"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "label",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทผู้ป่วย"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "label",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": "คลินิก"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "Dropdown",
      "parent": 12,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "patientCase"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.patientCaseOptions || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", minWidth: \"max-content\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filter?.patientCase || \"\""
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ClinicSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "FormField",
      "parent": 2,
      "props": {
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "FormField",
      "parent": 2,
      "props": {
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "FormField",
      "parent": 2,
      "props": {
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "label",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภท Visit"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "Checkbox",
      "parent": 20,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filter?.isOPD || false"
        },
        "label": {
          "type": "value",
          "value": "OPD"
        },
        "name": {
          "type": "value",
          "value": "isOPD"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "Checkbox",
      "parent": 21,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filter?.isIPD || false"
        },
        "label": {
          "type": "value",
          "value": "IPD"
        },
        "name": {
          "type": "value",
          "value": "isIPD"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "FormField",
      "parent": 2,
      "props": {
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "Button",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": "ล้างการค้นหา"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClear"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{ minWidth: \"max-content\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "FormField",
      "parent": 27,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "FormField",
      "parent": 27,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "FormField",
      "parent": 27,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "FormField",
      "parent": 27,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "FormField",
      "parent": 27,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "FormField",
      "parent": 27,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "FormField",
      "parent": 27,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "FormField",
      "parent": 27,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "FormField",
      "parent": 27,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "Checkbox",
      "parent": 28,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filter?.isDoctor ||false"
        },
        "label": {
          "type": "value",
          "value": "แพทย์เจ้าของไข้"
        },
        "name": {
          "type": "value",
          "value": "isDoctor"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", minWidth: \"max-content\"}"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "div",
      "parent": 29,
      "props": {
        "children": {
          "type": "code",
          "value": "props.DoctorSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 31,
      "props": {
        "children": {
          "type": "code",
          "value": "props.DiagnosisSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "label",
      "parent": 32,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่"
        },
        "style": {
          "type": "code",
          "value": "{ minWidth: \"max-content\"}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "label",
      "parent": 34,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "FormField",
      "parent": 27,
      "props": {
        "inline": {
          "type": "value",
          "value": "true"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "FormField",
      "parent": 27,
      "props": {
        "inline": {
          "type": "value",
          "value": "true"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "FormField",
      "parent": 27,
      "props": {
      },
      "seq": 63,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 54,
      "name": "Table",
      "parent": 53,
      "props": {
        "data": {
          "type": "code",
          "value": "props.orderItems || []"
        },
        "headers": {
          "type": "value",
          "value": "วันที่รับเข้า,คลินิก/Ward,HN (VN/AN),ชื่อ-นามสกุล,ประเภทผู้ป่วย,ประเภท Visit, แพทย์เจ้าของใข้,Diagnosis"
        },
        "keys": {
          "type": "value",
          "value": "encounter_datetime,division_name,hn_encounter_number,patient_fullname,patient_case_name,encounter_general_type,doctor_name,icd10_name"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"calc(100vh - 16rem)\", display: props.showStatistic ? \"none\": \"\"}"
        },
        "widths": {
          "type": "value",
          "value": "120"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "div",
      "parent": 35,
      "props": {
        "children": {
          "type": "code",
          "value": "props.EndDate"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 33,
      "props": {
        "children": {
          "type": "code",
          "value": "props.StartDate"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 36,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSearch"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "div",
      "parent": 45,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonPrint"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "div",
      "parent": 46,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonStatistic"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "div",
      "parent": 47,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonStatisticPrint"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 62,
      "name": "Table",
      "parent": 53,
      "props": {
        "data": {
          "type": "code",
          "value": "props.statItems || []"
        },
        "headers": {
          "type": "value",
          "value": "ลำดับ,แพทย์เจ้าของไข้,ประเภทผู้ป่วย,ประเภท Visit,Diagnosis, จำนวน"
        },
        "keys": {
          "type": "value",
          "value": "no,doctor_name,patient_case_name,encounter_general_type,icd10_name, encounter_count"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"calc(100vh - 16rem)\", display: props.showStatistic ? \"\": \"none\"}"
        },
        "widths": {
          "type": "value",
          "value": "100,auto,auto,auto,auto,150"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "Checkbox",
      "parent": 30,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.filter?.isDiagnosis ||false"
        },
        "label": {
          "type": "value",
          "value": "Diagnosis"
        },
        "name": {
          "type": "value",
          "value": "isDiagnosis"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", minWidth: \"max-content\"}"
        }
      },
      "seq": 63,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": true,
  "name": "CardReportPatientListUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
