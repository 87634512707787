import FormSymbolImages from "./AddSymbolPrintList";
import CONFIG from "config/config";
import CompanyLogoForm from "react-lib/apps/HISV3/common/CompanyLogoForm";

type HeaderPrintListProps = Partial<{
  createdDate: string;
  patientInfo: any;
  formName: string;
  styles: Record<string, any>;
  header: any;
  footer: any;
  images: any;
  pageMargins: number[];
}>;

const FORM_NAME = "AddHeaderCudentPrintList";
const DEFAULT_PAGE_MARGINS = [38, 80, 38, 20];

const LOGO_HEIGHT = 30;
const LOGO_RESIZE =
  1 -
  (CONFIG.LOGO_REPORT.find((i: any) => i.type === 1)?.height - LOGO_HEIGHT) /
    CONFIG.LOGO_REPORT.find((i: any) => i.type === 1)?.height;
const LOGO_WIDTH = CONFIG.LOGO_REPORT.find((i: any) => i.type === 1)?.width * LOGO_RESIZE;

export const AddHeaderCudentPrintListTH = async (doc: any, props: HeaderPrintListProps) => {
  const companyLogoForm = await CompanyLogoForm({
    type: 1,
    font: `KanitLM`,
    height: LOGO_HEIGHT,
    form: FORM_NAME,
  });
  const { images } = companyLogoForm;

  return {
    ...doc,
    pageMargins: props.pageMargins ? props.pageMargins : DEFAULT_PAGE_MARGINS,
    header: () => {
      return {
        table: {
          widths: ["53%", "47%"],
          body: [
            [
              // Left Side (Logo and Form Name)
              {
                stack: [
                  {
                    columns: [
                      {
                        image: `logo`,
                        width: LOGO_WIDTH,
                        height: LOGO_HEIGHT,
                        // margin: [0, 0, 0, 0],
                      },
                      {
                        text: `Dental Hospital of the Faculty of Dentistry\nChulalongkorn University`,
                        alignment: `center`,
                        bold: true,
                        font: `KanitLM`,
                        fontSize: 9,
                      }
                    ]
                  },
                  {
                    text: props.formName || ``,
                    alignment: `center`,
                    bold: true,
                    font: `KanitLM`,
                    fontSize: 8,
                    margin: [0, 0, 0, 8]
                  }
                ],
                border: [false, false, false, true],
              },
              // Right Side (Patient Detail)
              {
                stack: [
                  {
                    text: [
                      { text: `HN: `, bold: true },
                      { text: props.patientInfo?.hn },
                    ],
                    font: `KanitLM`,
                    fontSize: 8,
                  },
                  {
                    columns: [
                      {
                        text: [
                          { text: `Name: `, bold: true },
                          { text: props.patientInfo?.full_name },
                        ],
                        font: `KanitLM`,
                        fontSize: 8,
                      },
                      {
                        text: [
                          { text: `Sex: `, bold: true },
                          { 
                            text: props.patientInfo?.gender
                              ? props.patientInfo.gender === "F"
                                ? "หญิง"
                                : props.patientInfo.gender === "M"
                                ? "ชาย"
                                : props.patientInfo.gender
                              : "ไม่ระบุ" 
                          },
                        ],
                        font: `KanitLM`,
                        fontSize: 8,
                      },
                    ]
                  },
                  {
                    columns: [
                      {
                        text: [
                          { text: `Birthdate: `, bold: true },
                          { text: props.patientInfo?.birthdate },
                        ],
                        font: `KanitLM`,
                        fontSize: 8,
                      },
                      {
                        text: [
                          { text: `Age: `, bold: true },
                          { text: props.patientInfo?.full_age },
                        ],
                        font: `KanitLM`,
                        fontSize: 8,
                      },
                    ]
                  },
                  {
                    columns: [
                      {
                        text: [
                          { text: `CitizenID/Passport: `, bold: true },
                          { text: props.patientInfo?.citizen_no },
                        ],
                        font: `KanitLM`,
                        fontSize: 8,
                      },
                      {
                        text: [
                          { text: `Tel: `, bold: true },
                          { text: props.patientInfo?.present_address?.tel_mobile || "-" },
                        ],
                        font: `KanitLM`,
                        fontSize: 8,
                      },
                    ]
                  },
                  {
                    text: [
                      { text: `Visit date [VN/AN]: `, bold: true },
                      { text: `${props.patientInfo?.formatVisitDate} (${props.patientInfo?.patientEncounter})` },
                    ],
                    font: `KanitLM`,
                    fontSize: 8,
                  },
                ],
                border: [false, false, false, true],
              }
            ],
          ]
        },
        layout: {
          paddingTop: function (i: any, node: any) {
            return 5;
          },
          paddingBottom: function (i: any, node: any) {
            return 5;
          },
          paddingLeft: function (i: any, node: any) {
            return 5;
          },
          paddingRight: function (i: any, node: any) {
            return 5;
          },
          hLineStyle: function (i: any, node: any) {
            return { dash: { length: 2, space: 2 } };
          },
        },
      }
    },
    footer: (currentPage: any, pageCount: any) => {
      if (props.footer) {
        return {
          table: {
            widths: ["*"],
            body: [
              [
                {
                  columns: [
                    {
                      text: `วันเวลาที่พิมพ์เอกสาร : ${props.createdDate} โดย ${props.patientInfo?.printer || "-"}`,
                      alignment: `left`,
                      font: `KanitLM`,
                      fontSize: 8,
                    },
                    {
                      text: props.footer,
                      alignment: `center`,
                      font: `THSarabunNew`,
                      fontSize: 12,
                    },
                    {
                      text: `${currentPage.toString()}/${pageCount}`,
                      alignment: `right`,
                      font: `KanitLM`,
                      fontSize: 8,
                    },
                  ],
                  border: [false, true, false, false],
                }
              ]
            ]
          },
          layout: {
            hLineStyle: function (i: any, node: any) {
              return { dash: { length: 2, space: 2 } };
            },
          },
        };
      } else {
        return {
          table: {
            widths: ["*"],
            body: [
              [
                {
                  columns: [
                    {
                      text: `วันเวลาที่พิมพ์เอกสาร : ${props.createdDate} โดย ${props.patientInfo?.printer || "-"}`,
                      alignment: `left`,
                      font: `KanitLM`,
                      fontSize: 8,
                    },
                    {
                      text: `${currentPage.toString()}/${pageCount}`,
                      alignment: `right`,
                      font: `KanitLM`,
                      fontSize: 8,
                    },
                  ],
                  border: [false, true, false, false],
                }
              ]
            ]
          },
          layout: {
            hLineStyle: function (i: any, node: any) {
              return { dash: { length: 2, space: 2 } };
            },
          },
        }
      }
    },
    images: {
      ...images,
      checked: FormSymbolImages.checked,
      unchecked: FormSymbolImages.unchecked,
      squarebox: FormSymbolImages.squarebox,
      checkmark: FormSymbolImages.checkmark,
      ...props.images,
    },
    styles: {
      ...(props.styles || {}),
    },
  }
};