import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  Icon,
  FormField,
  Dropdown,
  Input,
  Radio,
  Button,
  TextArea
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'
import {
  Calendar
} from 'react-big-calendar'

const ModPlanningUX = (props: any) => {
    return(
      <div
        style={{width:"100%", padding: "10px",  height: "100%"}}>
        <div
          style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          
          <div
            style={{fontSize: "1.15rem", fontWeight: "bold", padding: "2.5px 0"}}>
            {props.planSummary}
          </div>
          <div
            style={{cursor: "pointer"}}>
            
            <Icon
              className="red"
              name="close"
              onClick={props.onClose}>
            </Icon>
          </div>
        </div>
        <div
          className="ui divider">
          
        </div>
        <div
          className="ui form --override-disabled"
          style={{padding: "0 0.5rem"}}>
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}
              width={8}>
              <label
                style={{minWidth: "80px", maxWidth: "80px", paddingLeft: "1.65rem", fontWeight: "normal"}}>
                ประเภท
              </label>
              <Dropdown
                fluid={true}
                name="type"
                onChange={props.onChangePlanningData }
                options={[ {key: 1, value: "NORMAL", text: "ปกติ"}, {key: 2, value: "CUSTOM", text: "เลือกเอง"} ]}
                selection={true}
                style={{width: "100%"}}
                value={props.planningData?.type || "NORMAL"}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{display: props.planningData?.type !== "CUSTOM" ? "" : "none" }}>
            <FormField
              inline={true}
              width={5}>
              <label
                style={{minWidth: "80px", maxWidth: "80px", paddingLeft: "1.65rem", fontWeight: "normal"}}>
                เริ่มต้น
              </label>
              <div
                style={{width: "100%"}}>
                
                <DateTextBox
                  minDate={props.today}
                  onChange={props.onChangePlanningStartDate}
                  value={props.planningData?.start_date}>
                </DateTextBox>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{display: props.planningData?.type !== "CUSTOM" ? "" : "none" }}>
            <FormField
              inline={true}
              width={8}>
              <Radio
                checked={props.planningData?.scheduleType === "day"}
                label="ทำซ้ำ"
                onChange={props.onChangeRadio}
                style={{minWidth: "80px", maxWidth: "80px", fontSize: ".92857143em"}}
                value="day">
              </Radio>
              <Input
                action="วัน"
                disabled={props.planningData?.scheduleType !== "day"}
                fluid={true}
                name="day_take"
                onChange={props.onChangePlanningData}
                onKeyDown={(e: any)=> ["-","e"].includes(e.key) && e.preventDefault()}
                placeholder="ระบุตัวเลข"
                type="number"
                value={props.planningData?.day_take}>
              </Input>
            </FormField>
            <FormField
              inline={true}
              width={8}>
              <label
                style={{minWidth: "80px", maxWidth: "80px", textAlign: "right", fontWeight: "normal"}}>
                เว้น
              </label>
              <Input
                action="วัน"
                disabled={props.planningData?.scheduleType !== "day"}
                fluid={true}
                name="day_skip"
                onChange={props.onChangePlanningData}
                onKeyDown={(e: any)=> ["-","e"].includes(e.key) && e.preventDefault()}
                placeholder="ระบุตัวเลข"
                type="number"
                value={props.planningData?.day_skip}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{display: props.planningData?.type !== "CUSTOM" ? "" : "none" }}>
            <FormField
              inline={true}
              width={16}>
              <Radio
                checked={props.planningData?.scheduleType === "week_days"}
                label="ในวัน"
                onChange={props.onChangeRadio}
                style={{minWidth: "80px", maxWidth: "80px", fontSize: ".92857143em"}}
                value="week_days">
              </Radio>
              <Dropdown
                disabled={props.planningData?.scheduleType !== "week_days"}
                fluid={true}
                multiple={true}
                name="week_days"
                onChange={props.onChangePlanningData}
                options={[ {key: 1, value: "Mon", text: "จันทร์"}, { key: 2, value: "Tue", text: "อังคาร" }, {key: 3, value: "Wed", text: "พุธ"}, { key: 4, value: "Thu", text: "พฤหัสบดี" }, {key: 5, value: "Fri", text: "ศุกร์"}, { key: 6, value: "Sat", text: "เสาร์" }, {key: 7, value: "Sun", text: "อาทิตย์"} ]}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.planningData?.week_days}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{display: props.planningData?.type !== "CUSTOM" ? "" : "none" }}>
            <FormField
              inline={true}
              width={16}>
              <label
                style={{minWidth: "80px", maxWidth: "80px",paddingLeft: "1.65rem", fontWeight: "normal"}}>
                สิ้นสุด
              </label>
              <Radio
                checked={!["OCCURRENCE", "END_DATE"].includes(props.planningData?.endDate)}
                label="ไม่มี"
                name="endDate"
                onChange={props.onChangePlanningData}
                value="NONE">
              </Radio>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{display: props.planningData?.type !== "CUSTOM" ? "" : "none" }}>
            <FormField
              inline={true}
              style={{minWidth: "max-content"}}>
              <label
                style={{minWidth: "80px", maxWidth: "80px",}}>
                
              </label>
              <Radio
                checked={props.planningData?.endDate === "OCCURRENCE"}
                label="หลังจากเกิดขึ้น"
                name="endDate"
                onChange={props.onChangePlanningData}
                style={{minWidth: "120px", maxWidth: "120px"}}
                value="OCCURRENCE">
              </Radio>
            </FormField>
            <FormField
              inline={true}
              width={16}>
              <Input
                action="วัน"
                disabled={props.planningData?.endDate !== "OCCURRENCE"}
                fluid={true}
                name="occurrence"
                onChange={props.onChangePlanningData}
                onKeyDown={(e: any)=> ["-","e"].includes(e.key) && e.preventDefault()}
                type="number"
                value={props.planningData?.occurrence}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{display: props.planningData?.type !== "CUSTOM" ? "" : "none" }}>
            <FormField
              inline={true}
              style={{minWidth: "max-content"}}>
              <label
                style={{minWidth: "80px", maxWidth: "80px",}}>
                
              </label>
              <Radio
                checked={props.planningData?.endDate === "END_DATE"}
                label="ในวันที่"
                name="endDate"
                onChange={props.onChangePlanningData}
                style={{minWidth: "120px", maxWidth: "120px"}}
                value="END_DATE">
              </Radio>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <div
                style={{width: "100%"}}>
                
                <DateTextBox
                  disabled={props.planningData?.endDate !== "END_DATE"}
                  minDate="props.today"
                  onChange={props.onChangePlanningEndDate}
                  value={props.planningData?.end_date}>
                </DateTextBox>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            style={{display: props.planningData?.type === "CUSTOM" ? "" : "none",  }}>
            <FormField
              width={16}>
              <div
                style={{width: "100%", height: "13rem"}}>
                
                <Calendar
                  events={props.planningData?.dates || []}
                  localizer={props.calendarLocalizer}
                  min={new Date()}
                  onNavigate={props.onCalendarNavigate}
                  views={["month"]}>
                </Calendar>
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            style={{display: props.planningData?.type === "CUSTOM" ? "" : "none",  }}>
            <FormField
              width={16}>
              <TextArea
                disabled={true}
                style={{ width: "100%", textAlign: "center" }}
                value={props.planningData?.dates?.join('\n')}>
              </TextArea>
            </FormField>
          </FormGroup>
        </div>
        <div
          style={{ padding: "5px", textAlign: "center", border: "solid 1px rgb(200, 200, 200, 0.5)", margin: "1.5rem 0.5rem 0"}}>
          {props.planningData?.date_time_summary}
        </div>
        <div
          className="ui form"
          style={{marginTop: "1rem"}}>
          
          <FormGroup
            inline={true}>
            <FormField
              style={{flex: 1}}>
            </FormField>
            <FormField
              style={{padding: 0, display: "flex"}}>
              <Button
                color="green"
                onClick={props.onRequestPlanning}
                size="small"
                style={{ display: props.planningData?.allowed_actions?.includes("REQUEST") ? "" : "none" }}>
                OK
              </Button>
              <div>
                {props.buttonEdit}
              </div>
              <div>
                {props.buttonResume}
              </div>
              <div>
                {props.buttonOff}
              </div>
              <div>
                {props.buttonHold}
              </div>
              <div>
                {props.buttonRenew}
              </div>
            </FormField>
          </FormGroup>
        </div>
      </div>
    )
}


export default ModPlanningUX

export const screenPropsDefault = {}

/* Date Time : Thu Nov 14 2024 12:39:45 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width:\"100%\", padding: \"10px\",  height: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 139,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form --override-disabled"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 0.5rem\"}"
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 140,
      "name": "FormGroup",
      "parent": 139,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 141,
      "name": "FormGroup",
      "parent": 139,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: props.planningData?.type !== \"CUSTOM\" ? \"\" : \"none\" }"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": null,
      "id": 142,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"space-between\", alignItems: \"center\"}"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": null,
      "id": 143,
      "name": "div",
      "parent": 142,
      "props": {
        "children": {
          "type": "code",
          "value": "props.planSummary"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.15rem\", fontWeight: \"bold\", padding: \"2.5px 0\"}"
        }
      },
      "seq": 143,
      "void": false
    },
    {
      "from": null,
      "id": 144,
      "name": "div",
      "parent": 142,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{cursor: \"pointer\"}"
        }
      },
      "seq": 144,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 145,
      "name": "Icon",
      "parent": 144,
      "props": {
        "className": {
          "type": "value",
          "value": "red"
        },
        "name": {
          "type": "value",
          "value": "close"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        }
      },
      "seq": 145,
      "void": false
    },
    {
      "from": null,
      "id": 146,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 148,
      "name": "FormField",
      "parent": 140,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 148,
      "void": false
    },
    {
      "from": null,
      "id": 149,
      "name": "label",
      "parent": 148,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภท"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"80px\", maxWidth: \"80px\", paddingLeft: \"1.65rem\", fontWeight: \"normal\"}"
        }
      },
      "seq": 149,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 150,
      "name": "Dropdown",
      "parent": 148,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "type"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangePlanningData "
        },
        "options": {
          "type": "code",
          "value": "[ {key: 1, value: \"NORMAL\", text: \"ปกติ\"}, {key: 2, value: \"CUSTOM\", text: \"เลือกเอง\"} ]"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.planningData?.type || \"NORMAL\""
        }
      },
      "seq": 150,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 151,
      "name": "FormField",
      "parent": 141,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 151,
      "void": false
    },
    {
      "from": null,
      "id": 152,
      "name": "label",
      "parent": 151,
      "props": {
        "children": {
          "type": "value",
          "value": "เริ่มต้น"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"80px\", maxWidth: \"80px\", paddingLeft: \"1.65rem\", fontWeight: \"normal\"}"
        }
      },
      "seq": 152,
      "void": false
    },
    {
      "from": null,
      "id": 153,
      "name": "div",
      "parent": 151,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 153,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 154,
      "name": "DateTextBox",
      "parent": 153,
      "props": {
        "minDate": {
          "type": "code",
          "value": "props.today"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangePlanningStartDate"
        },
        "value": {
          "type": "code",
          "value": "props.planningData?.start_date"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 155,
      "name": "FormGroup",
      "parent": 139,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: props.planningData?.type !== \"CUSTOM\" ? \"\" : \"none\" }"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 156,
      "name": "FormField",
      "parent": 155,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 156,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 157,
      "name": "FormField",
      "parent": 155,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 157,
      "void": false
    },
    {
      "from": null,
      "id": 159,
      "name": "label",
      "parent": 157,
      "props": {
        "children": {
          "type": "value",
          "value": "เว้น"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"80px\", maxWidth: \"80px\", textAlign: \"right\", fontWeight: \"normal\"}"
        }
      },
      "seq": 159,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 160,
      "name": "Input",
      "parent": 156,
      "props": {
        "action": {
          "type": "value",
          "value": "วัน"
        },
        "disabled": {
          "type": "code",
          "value": "props.planningData?.scheduleType !== \"day\""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "day_take"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangePlanningData"
        },
        "onKeyDown": {
          "type": "code",
          "value": "(e: any)=> [\"-\",\"e\"].includes(e.key) && e.preventDefault()"
        },
        "placeholder": {
          "type": "value",
          "value": "ระบุตัวเลข"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.planningData?.day_take"
        }
      },
      "seq": 202,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 161,
      "name": "Input",
      "parent": 157,
      "props": {
        "action": {
          "type": "value",
          "value": "วัน"
        },
        "disabled": {
          "type": "code",
          "value": "props.planningData?.scheduleType !== \"day\""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "day_skip"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangePlanningData"
        },
        "onKeyDown": {
          "type": "code",
          "value": "(e: any)=> [\"-\",\"e\"].includes(e.key) && e.preventDefault()"
        },
        "placeholder": {
          "type": "value",
          "value": "ระบุตัวเลข"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.planningData?.day_skip"
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 162,
      "name": "FormGroup",
      "parent": 139,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: props.planningData?.type !== \"CUSTOM\" ? \"\" : \"none\" }"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 162,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 163,
      "name": "FormField",
      "parent": 162,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 163,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 165,
      "name": "Dropdown",
      "parent": 163,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.planningData?.scheduleType !== \"week_days\""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "week_days"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangePlanningData"
        },
        "options": {
          "type": "code",
          "value": "[ {key: 1, value: \"Mon\", text: \"จันทร์\"}, { key: 2, value: \"Tue\", text: \"อังคาร\" }, {key: 3, value: \"Wed\", text: \"พุธ\"}, { key: 4, value: \"Thu\", text: \"พฤหัสบดี\" }, {key: 5, value: \"Fri\", text: \"ศุกร์\"}, { key: 6, value: \"Sat\", text: \"เสาร์\" }, {key: 7, value: \"Sun\", text: \"อาทิตย์\"} ]"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.planningData?.week_days"
        }
      },
      "seq": 203,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 166,
      "name": "FormGroup",
      "parent": 139,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: props.planningData?.type !== \"CUSTOM\" ? \"\" : \"none\" }"
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 167,
      "name": "FormField",
      "parent": 166,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 167,
      "void": false
    },
    {
      "from": null,
      "id": 168,
      "name": "label",
      "parent": 167,
      "props": {
        "children": {
          "type": "value",
          "value": "สิ้นสุด"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"80px\", maxWidth: \"80px\",paddingLeft: \"1.65rem\", fontWeight: \"normal\"}"
        }
      },
      "seq": 168,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 170,
      "name": "FormGroup",
      "parent": 139,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: props.planningData?.type !== \"CUSTOM\" ? \"\" : \"none\" }"
        }
      },
      "seq": 170,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 171,
      "name": "FormGroup",
      "parent": 139,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: props.planningData?.type !== \"CUSTOM\" ? \"\" : \"none\" }"
        }
      },
      "seq": 171,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 172,
      "name": "Radio",
      "parent": 167,
      "props": {
        "checked": {
          "type": "code",
          "value": "![\"OCCURRENCE\", \"END_DATE\"].includes(props.planningData?.endDate)"
        },
        "label": {
          "type": "value",
          "value": "ไม่มี"
        },
        "name": {
          "type": "value",
          "value": "endDate"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangePlanningData"
        },
        "value": {
          "type": "value",
          "value": "NONE"
        }
      },
      "seq": 172,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 173,
      "name": "FormField",
      "parent": 170,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 173,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 174,
      "name": "FormField",
      "parent": 171,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 174,
      "void": false
    },
    {
      "from": null,
      "id": 175,
      "name": "label",
      "parent": 174,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"80px\", maxWidth: \"80px\",}"
        }
      },
      "seq": 175,
      "void": false
    },
    {
      "from": null,
      "id": 176,
      "name": "label",
      "parent": 173,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"80px\", maxWidth: \"80px\",}"
        }
      },
      "seq": 176,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 177,
      "name": "Radio",
      "parent": 173,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.planningData?.endDate === \"OCCURRENCE\""
        },
        "label": {
          "type": "value",
          "value": "หลังจากเกิดขึ้น"
        },
        "name": {
          "type": "value",
          "value": "endDate"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangePlanningData"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"120px\", maxWidth: \"120px\"}"
        },
        "value": {
          "type": "value",
          "value": "OCCURRENCE"
        }
      },
      "seq": 177,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 178,
      "name": "Radio",
      "parent": 174,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.planningData?.endDate === \"END_DATE\""
        },
        "disabled": {
          "type": "code",
          "value": ""
        },
        "label": {
          "type": "value",
          "value": "ในวันที่"
        },
        "name": {
          "type": "value",
          "value": "endDate"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangePlanningData"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"120px\", maxWidth: \"120px\"}"
        },
        "value": {
          "type": "value",
          "value": "END_DATE"
        }
      },
      "seq": 178,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 179,
      "name": "FormField",
      "parent": 170,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 179,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 180,
      "name": "Input",
      "parent": 179,
      "props": {
        "action": {
          "type": "value",
          "value": "วัน"
        },
        "disabled": {
          "type": "code",
          "value": "props.planningData?.endDate !== \"OCCURRENCE\""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "occurrence"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangePlanningData"
        },
        "onKeyDown": {
          "type": "code",
          "value": "(e: any)=> [\"-\",\"e\"].includes(e.key) && e.preventDefault()"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.planningData?.occurrence"
        }
      },
      "seq": 180,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 181,
      "name": "FormField",
      "parent": 171,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 181,
      "void": false
    },
    {
      "from": null,
      "id": 182,
      "name": "div",
      "parent": 181,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 182,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 183,
      "name": "DateTextBox",
      "parent": 182,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.planningData?.endDate !== \"END_DATE\""
        },
        "minDate": {
          "type": "value",
          "value": "props.today"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangePlanningEndDate"
        },
        "value": {
          "type": "code",
          "value": "props.planningData?.end_date"
        }
      },
      "seq": 183,
      "void": false
    },
    {
      "from": null,
      "id": 184,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.planningData?.date_time_summary"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"5px\", textAlign: \"center\", border: \"solid 1px rgb(200, 200, 200, 0.5)\", margin: \"1.5rem 0.5rem 0\"}"
        }
      },
      "seq": 184,
      "void": false
    },
    {
      "from": null,
      "id": 185,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"1rem\"}"
        }
      },
      "seq": 185,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 186,
      "name": "FormGroup",
      "parent": 185,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 186,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 187,
      "name": "FormField",
      "parent": 186,
      "props": {
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 187,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 188,
      "name": "FormField",
      "parent": 186,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding: 0, display: \"flex\"}"
        }
      },
      "seq": 188,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 189,
      "name": "Button",
      "parent": 188,
      "props": {
        "children": {
          "type": "value",
          "value": "OK"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onRequestPlanning"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.planningData?.allowed_actions?.includes(\"REQUEST\") ? \"\" : \"none\" }",
          "valueEN": ""
        }
      },
      "seq": 189,
      "void": false
    },
    {
      "from": null,
      "id": 190,
      "name": "div",
      "parent": 188,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonEdit"
        }
      },
      "seq": 190,
      "void": false
    },
    {
      "from": null,
      "id": 191,
      "name": "div",
      "parent": 188,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonResume"
        }
      },
      "seq": 191,
      "void": false
    },
    {
      "from": null,
      "id": 192,
      "name": "div",
      "parent": 188,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonOff"
        }
      },
      "seq": 192,
      "void": false
    },
    {
      "from": null,
      "id": 193,
      "name": "div",
      "parent": 188,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonHold"
        }
      },
      "seq": 193,
      "void": false
    },
    {
      "from": null,
      "id": 194,
      "name": "div",
      "parent": 188,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonRenew"
        }
      },
      "seq": 194,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 195,
      "name": "FormGroup",
      "parent": 139,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: props.planningData?.type === \"CUSTOM\" ? \"\" : \"none\",  }"
        }
      },
      "seq": 195,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 196,
      "name": "FormField",
      "parent": 195,
      "props": {
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 196,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 197,
      "name": "FormGroup",
      "parent": 139,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: props.planningData?.type === \"CUSTOM\" ? \"\" : \"none\",  }"
        }
      },
      "seq": 197,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 198,
      "name": "FormField",
      "parent": 197,
      "props": {
        "width": {
          "type": "code",
          "value": "16"
        }
      },
      "seq": 198,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 199,
      "name": "TextArea",
      "parent": 198,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", textAlign: \"center\" }"
        },
        "value": {
          "type": "code",
          "value": "props.planningData?.dates?.join('\\n')"
        }
      },
      "seq": 199,
      "void": true
    },
    {
      "from": null,
      "id": 200,
      "name": "div",
      "parent": 196,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"13rem\"}"
        }
      },
      "seq": 200,
      "void": false
    },
    {
      "from": "react-big-calendar",
      "id": 201,
      "name": "Calendar",
      "parent": 200,
      "props": {
        "events": {
          "type": "code",
          "value": "props.planningData?.dates || []"
        },
        "localizer": {
          "type": "code",
          "value": "props.calendarLocalizer"
        },
        "min": {
          "type": "code",
          "value": "new Date()"
        },
        "onNavigate": {
          "type": "code",
          "value": "props.onCalendarNavigate"
        },
        "views": {
          "type": "code",
          "value": "[\"month\"]"
        }
      },
      "seq": 201,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 202,
      "name": "Radio",
      "parent": 156,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.planningData?.scheduleType === \"day\""
        },
        "label": {
          "type": "value",
          "value": "ทำซ้ำ"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeRadio"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"80px\", maxWidth: \"80px\", fontSize: \".92857143em\"}"
        },
        "value": {
          "type": "value",
          "value": "day"
        }
      },
      "seq": 158,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 203,
      "name": "Radio",
      "parent": 163,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.planningData?.scheduleType === \"week_days\""
        },
        "label": {
          "type": "value",
          "value": "ในวัน"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeRadio"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"80px\", maxWidth: \"80px\", fontSize: \".92857143em\"}"
        },
        "value": {
          "type": "value",
          "value": "week_days"
        }
      },
      "seq": 165,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "ModPlanningUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
