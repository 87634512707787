import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Input,
  Button,
  Icon
} from 'semantic-ui-react'

const CardDiseaseSegmentGroupUX = (props: any) => {
    return(
      <div>
        <div
          className="ui form"
          style={{padding:"15px", paddingBottom: 0}}>
          
          <FormGroup
            className="equal width ">
            <FormField>
              <label
                style={{fontWeight: "normal"}}>
                ชื่อ Segment :
              </label>
              <Input
                icon="search"
                onChange={props.onChangeInput}
                value={props.searchText || ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            className="equal width ">
            <FormField>
              <Button
                color="green"
                fluid={true}
                onClick={props.onSearch}
                size="small">
                ค้นหา
              </Button>
            </FormField>
            <FormField>
              <Button
                color="blue"
                fluid={true}
                onClick={props.onNewSegment}
                size="small"
                style={{paddingLeft: 0, paddingRight: 0}}>
                <Icon
                  name="plus circle">
                </Icon>
                <label>
                  NEW Segment
                </label>
              </Button>
            </FormField>
          </FormGroup>
        </div>
        <div
          style={{width: "100%"}}>
          {props.SegmentList}
        </div>
      </div>
    )
}

export default React.memo(CardDiseaseSegmentGroupUX)

export const screenPropsDefault = {}

/* Date Time : Mon Jan 23 2023 13:16:31 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{padding:\"15px\", paddingBottom: 0}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 2,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "className": {
          "type": "value",
          "value": "equal width "
        },
        "inline": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "FormField",
      "parent": 2,
      "props": {
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "label",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ Segment :"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"normal\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "Input",
      "parent": 4,
      "props": {
        "icon": {
          "type": "value",
          "value": "search"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeInput"
        },
        "value": {
          "type": "code",
          "value": "props.searchText || \"\""
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "className": {
          "type": "value",
          "value": "equal width "
        },
        "inline": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "FormField",
      "parent": 10,
      "props": {
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "FormField",
      "parent": 10,
      "props": {
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "Button",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSearch"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Button",
      "parent": 12,
      "props": {
        "color": {
          "type": "value",
          "value": "blue"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "props.onNewSegment"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: 0, paddingRight: 0}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "Icon",
      "parent": 14,
      "props": {
        "name": {
          "type": "value",
          "value": "plus circle"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "label",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": "NEW Segment"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.SegmentList"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 19,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 70,
  "isMounted": false,
  "memo": true,
  "name": "CardDiseaseSegmentGroupUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 65
}

*********************************************************************************** */
