export default class CardBloodTransfusionHistoryController {
  constructor(props) {
    this.blbManager = props.blbManager;
    this.userManager = props.userManager;
  }

  getUserPermission = async () => {
    const params = {
      config_BLB_BLOOD_TRANSFUSION_LIS: null,
    };
    const [data, error] = await this.userManager.getUserPermission(params);
    return [data, error];
  };

  getBloodGroupWithPatient = async (patientId) => {
    const [
      data,
      error,
      network,
    ] = await this.blbManager.getBloodGroupWithPatient(patientId);
    return [data, error, network];
  };

  getBloodTransfusion = async (params) => {
    const [data, error, network] = await this.blbManager.getBloodTransfusion(
      params
    );
    return [data, error, network];
  };
}
