import React, { useEffect, useState, useMemo, useCallback, useRef, MutableRefObject } from "react";
import {
  Button,
  Divider,
  Dropdown,
  Form,
  FormField,
  Icon,
  Input,
  Label,
  Modal,
  TextArea,
} from "semantic-ui-react";

import moment from "moment";

// UX
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import CardMedicalFee from "./CardMedicalFee";
import CardBillingHistoryUX from "./CardBillingHistoryUX";
import ModConfirmVoid from "./ModConfirmVoid";
import ModConfirmReprint from "./ModConfirmReprint";

// Common

import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import ModInfo from "react-lib/apps/common/ModInfo";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";

import SnackMessage from "react-lib/apps/common/SnackMessage";

// Utils
import { formatDate } from "react-lib/utils/dateUtils";
import { Table } from "react-lib/frameworks/Table";
import { formatDatetime } from "../../../utils/dateUtils";
import EditorColumn from "react-lib/appcon/common/EditorColumn";
import ModCancelAdmitOrder from "react-lib/apps/HISV3/ADM/ModCancelAdmitOrder";

import CONFIG from "config/config";
import { useIntl } from "react-intl";

// Type
type PriceType = {
  price: number;
  send_claim: number;
  absorb: number;
  patient_claim: number;
  non_claimable: number;
  discount: number;
  payable: number;
  installment_sum: number;
};

// Const
const ACTIONS = {
  PRINT_RECEIPT_COPY: "print_receipt_copy",
};

export const STATUS_OPTIONS = [
  {
    value: "PAID",
    text: "PAID",
  },
  {
    value: "CANCELED",
    text: "CANCELED",
  },
  {
    value: "REPLACED",
    text: "REPLACED",
  },
];

const TYPE_OPTIONS = [
  {
    value: "receipt",
    text: "RECEIPT",
  },
  {
    value: "invoice",
    text: "INVOICE",
  },
  {
    value: "credit_note",
    text: "CREDIT NOTE",
  },
];

const priceInitial = {
  price: 0,
  send_claim: 0,
  absorb: 0,
  patient_claim: 0,
  non_claimable: 0,
  discount: 0,
  payable: 0,
  installment_sum: 0,
};

export const CARD_BILLING_HISTORY = "CardBillingHistory";

const CardBillingHistory = (props: any) => {
  const intl = useIntl();
  const [type, setType] = useState("receipt");
  const [byType, setByType] = useState("Order");
  const [receiptId, setReceiptId] = useState("");
  const [selectedReceipt, setSelectedReceipt] = useState("");
  const [showBillType, setShowBillType] = useState("receipt");

  const [divisionCheck, setDivisionCheck] = useState(false);
  const [division, setDivision] = useState();
  const [statusCheck, setStatusCheck] = useState(false);
  const [status, setStatus] = useState();
  const [dataCheck, setDateCheck] = useState(true);
  const [startDate, setStartDate] = useState(formatDate(moment()));
  const [endDate, setEndDate] = useState(formatDate(moment()));

  const [price, setPrice] = useState<PriceType>(priceInitial);

  const [patientNameOptions, setPatientNameOptions] = useState<any>();
  const [patientName, setPatientName] = useState("");

  const [showEDC, setShowEDC] = useState(false);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [cancelReceiptNote, setCancelReceiptNote] = useState("");
  // Mod
  const [openInvoice, setOpenInvoice] = useState(false);
  const [openCreditNote, setOpenCreditNote] = useState(false);
  const [openCancelReceipt, setOpenCancelReceipt] = useState(false);
  const [openModSuccess, setOpenModSuccess] = useState(false);
  const [openModError, setOpenModError] = useState(false);
  const [openModLog, setOpenModLog] = useState<boolean>(false);
  const [modWarning, setModWarning] = useState<string>("");
  const [modConfirmVoid, setModConfirmVoid] = useState<{
    type: "VOID" | "UNVOID" | "SAVE";
    data: Record<string, any> | null;
  } | null>(null);
  const [modConfirmReprint, setModConfirmReprint] = useState<Record<string, any> | null>(null);
  const [openReceiptCopy, setOpenReceiptCopy] = useState<boolean>(false);
  const [note, setNote] = useState<string>("");
  const [copiedPrintSuccess, setCopiedPrintSuccess] = useState<boolean>(false);

  const cardRef = useRef() as MutableRefObject<HTMLDivElement>;

  // #useEffect(() => {
  //   props.runSequence({
  //     sequence: "BillingHistory",
  //     action: "master_map",
  //   });
  // }, []);

  useEffect(() => {
    const isInvoiceHistory = props.invoiceHistory === true;

    if (isInvoiceHistory) {
      setType("invoice");
      setShowBillType("invoice");
    }

    const commonParams = {
      sequence: "BillingHistory",
      card: CARD_BILLING_HISTORY,
      division: divisionCheck ? division : "",
      from_date: dataCheck ? startDate : "",
      restart: true,
      status: statusCheck ? status : "",
      to_date: dataCheck ? endDate : "",
    };

    const invoiceParams = {
      ...commonParams,
      action: "get_data_invoice",
      type: "invoice",
    };

    const nonInvoiceParams = {
      ...commonParams,
      action: "get_data",
      type,
    };

    props.runSequence(isInvoiceHistory ? invoiceParams : nonInvoiceParams);

    return () => {
      props.runSequence({ sequence: "BillingHistory", clear: true });

      props.setProp("invoiceHistory", false);
    };
  }, []);

  useEffect(() => {
    if (props.BillingHistorySequence?.cancelReceipt === "error") {
      setOpenModError(true);
    } else if (props.BillingHistorySequence?.cancelReceipt === "success") {
      setOpenModSuccess(true);
      setUsername("");
      setPassword("");
      setCancelReceiptNote("");
      setOpenCancelReceipt(false);
    }
  }, [props.BillingHistorySequence?.cancelReceipt]);

  useEffect(() => {
    if (props.invoiceHistory === true) {
      let receiptItems = props?.BillingHistorySequence?.receiptItems?.items[0];
      setReceiptId(receiptItems?.id);
    } else {
      setReceiptId("");
    }
  }, [props.BillingHistorySequence?.receiptItems]);

  useEffect(() => {
    let price = 0;
    let send_claim = 0;
    let absorb = 0;
    let patient_claim = 0;
    let non_claimable = 0;
    let discount = 0;
    let payable = 0;

    props?.BillingHistorySequence?.receiptDetail?.[byType]?.map((item: any, index: any) => {
      if (byType === "Item") {
        price = price + Number(item.price);
        send_claim = send_claim + Number(item.send_claim);
        absorb = absorb + Number(item.absorb);
        patient_claim = patient_claim + Number(item.patient_claim);
        non_claimable = non_claimable + Number(item.non_claimable);
        discount = discount + Number(item.discount);
        payable = payable + Number(item.payable);
      } else {
        price = price + item.price__sum;
        send_claim = send_claim + item.send_claim__sum;
        absorb = absorb + item.absorb__sum;
        patient_claim = patient_claim + item.patient_claim__sum;
        non_claimable = non_claimable + item.non_claimable__sum;
        discount = discount + item.discount__sum;
        payable = payable + item.payable__sum;
      }
    });

    setPrice({
      price,
      send_claim,
      absorb,
      patient_claim,
      non_claimable,
      discount,
      payable: payable - Number(props?.BillingHistorySequence?.receiptData?.installment_sum || 0),
      installment_sum: props?.BillingHistorySequence?.receiptData?.installment_sum || 0.0,
    });
  }, [props?.BillingHistorySequence?.receiptDetail]);

  useEffect(() => {
    let price = 0;

    props?.BillingHistorySequence?.creditNoteData?.credit_note_items?.map(
      (item: any, index: any) => {
        price = price + Number(item.price);
      }
    );

    setPrice({
      price: parseFloat(price).toFixed(2),
      send_claim: 0,
      absorb: 0,
      patient_claim: 0,
      non_claimable: 0,
      discount: 0,
      payable: 0,
    });
  }, [props?.BillingHistorySequence?.creditNoteData]);

  useEffect(() => {
    setPatientName("");

    const patientName: any[] = [];
    const patient = props?.BillingHistorySequence?.patient || {};

    if (!!patient.full_name_th) {
      patientName.push({
        value: "full_name_th",
        text: patient?.full_name_th,
      });
    }
    if (!!patient.full_name_en) {
      patientName.push({
        value: "full_name_en",
        text: patient?.full_name_en,
      });
    }

    setPatientNameOptions(patientName);
    setPatientName(patientName?.[0]?.value);
  }, [props?.BillingHistorySequence?.patient]);

  // issue 67702
  useEffect(() => {
    if (props.buttonLoadCheck?.CardBillingHistory_print_receipt_copy === "SUCCESS") {
      setCopiedPrintSuccess(true);
    }
  }, [props.buttonLoadCheck]);

  useEffect(() => {
    if (props.buttonLoadCheck?.CardBillingHistory_print_receipt_copy === "SUCCESS") {
      setCopiedPrintSuccess(true);
    }
  }, [props.buttonLoadCheck]);

  const doctorNameOptions = useMemo(() => {
    return (props.masterOptions?.doctor || []).map((item: any) => {
      let doctorName = (item.text || "")?.split("(")?.[0] || ""
      return {
        key: item.key,
        value: item.value,
        text: doctorName,
      };
    });
  }, [props.masterOptions?.doctor]);

  const formatComma = (val: any) =>
    !isNaN(parseFloat(val))
      ? parseFloat(val)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(?:\d{3})+(?!\d))/g, ",")
      : val;

  // Callback Memo
  const handleShowLog = useCallback(
    (id: number) => (e: any) => {
      props.runSequence({
        sequence: "BillingHistory",
        action: "get_action_log",
        pk: id,
      });

      setOpenModLog(true);
    },
    []
  );

  const handleChangeEdit = useCallback(
    (data: { value: any; index: number; key: string }) => {
      const edc = [...(props.BillingHistorySequence?.edcTransaction || [])];

      edc[data.index][data.key] = data.value;

      props.setProp("BillingHistorySequence.edcTransaction", edc);
    },
    [props.BillingHistorySequence?.edcTransaction]
  );

  const creditNoteItems = useMemo(() => {
    return (props?.BillingHistorySequence?.creditNoteData?.credit_note_items || []).map(
      (item: any, index: number) => ({
        ...item,
        pricing_quantity: <div style={{ textAlign: "right" }}>{item.pricing_quantity}</div>,
        unitprice: <div style={{ textAlign: "right" }}>{item.unitprice}</div>,
        payable: <div style={{ textAlign: "right" }}>{item.payable}</div>,
        quantity: <div style={{ textAlign: "right" }}>{item.quantity}</div>,
        price: <div style={{ textAlign: "right" }}>{item.price}</div>,
      })
    );
  }, [props?.BillingHistorySequence?.creditNoteData]);

  const receiptItems = useMemo(() => {
    return (props?.BillingHistorySequence?.receiptItems?.items || []).map(
      (item: any, index: number) => ({
        ...item,
        no: <div style={{ textAlign: "center" }}>{index + 1}</div>,
        created: (
          <div>
            {formatDate(moment(item?.created))} [{moment(item?.created).format("HH:mm")}]
          </div>
        ),
        price: <div style={{ textAlign: "right" }}>{formatComma(item.price)}</div>,
      })
    );
  }, [props?.BillingHistorySequence?.receiptItems]);

  const invoiceItems = useMemo(() => {
    return (props?.BillingHistorySequence?.receiptDetail?.[byType] || []).map(
      (item: any, index: number) => ({
        ...item,
        created: (
          <div>
            {formatDate(moment(item?.created))} [{moment(item?.created).format("HH:mm")}]
          </div>
        ),
        price__sum: (
          <div style={{ textAlign: "right" }}>
            {byType === "Item" ? formatComma(item.price) : formatComma(item.price__sum)}
          </div>
        ),
        send_claim__sum: (
          <div style={{ textAlign: "right" }}>
            {byType === "Item" ? formatComma(item.send_claim) : formatComma(item.send_claim__sum)}
          </div>
        ),
        absorb__sum: (
          <div style={{ textAlign: "right" }}>
            {byType === "Item" ? formatComma(item.absorb) : formatComma(item.absorb__sum)}
          </div>
        ),
        patient_claim__sum: (
          <div style={{ textAlign: "right" }}>
            {byType === "Item"
              ? formatComma(item.patient_claim)
              : formatComma(item.patient_claim__sum)}
          </div>
        ),
        non_claimable__sum: (
          <div style={{ textAlign: "right" }}>
            {byType === "Item"
              ? formatComma(item.non_claimable)
              : formatComma(item.non_claimable__sum)}
          </div>
        ),
        discount__sum: (
          <div style={{ textAlign: "right" }}>
            {byType === "Item" ? formatComma(item.discount) : formatComma(item.discount__sum)}
          </div>
        ),
        payable__sum: (
          <div style={{ textAlign: "right" }}>
            {byType === "Item" ? formatComma(item.payable) : formatComma(item.payable__sum)}
          </div>
        ),
        quantity: <div style={{ textAlign: "right" }}>{formatComma(item.quantity)}</div>,
        unitprice: <div style={{ textAlign: "right" }}>{formatComma(item.unitprice)}</div>,
        drg_discount_amount: (
          <div style={{ textAlign: "right" }}>{formatComma(item.drg_discount_amount)}</div>
        ),
      })
    );
  }, [props?.BillingHistorySequence?.receiptDetail, byType]);

  const edcTransaction = useMemo(() => {
    return (props.BillingHistorySequence?.edcTransaction || []).map((item: any, index: number) => ({
      ...item,
      reference_code_edited: (
        <EditorColumn
          value={item.reference_code_edited}
          disabled={!(item.status === "INACTIVE")}
          valueOnFocus={true}
          selectOnFocus={true}
          align="left"
          // style
          backgroundColor={item.status === "INACTIVE" ? "rgb(255, 255, 204)" : ""}
          // callback
          onDataChanged={(value: string) =>
            handleChangeEdit({ value, index, key: "reference_code_edited" })
          }
        />
      ),
      approval_code_edited: (
        <EditorColumn
          value={item.approval_code_edited}
          valueOnFocus={true}
          selectOnFocus={true}
          align="left"
          // style
          backgroundColor={"rgb(255, 255, 204)"}
          // callback
          onDataChanged={(value: string) =>
            handleChangeEdit({ value, index, key: "approval_code_edited" })
          }
        />
      ),
      effective_date: <div>{formatDate(moment(item.effective_date))}</div>,
      total_price: <div style={{ textAlign: "right" }}>{formatComma(item.total_price)}</div>,
      actual_price: <div style={{ textAlign: "right" }}>{formatComma(item.actual_price)}</div>,
      actions: (
        <div style={{ marginLeft: "1rem" }}>
          {item.id && (
            <Button circular icon="history" size="mini" onClick={handleShowLog(item.id)}></Button>
          )}
          {!item.id && item.total_price !== item.actual_price && (
            <Button
              circular
              icon="warning"
              size="mini"
              onClick={() =>
                setModWarning(`กรุณาใช้จำนวนจากช่อง "Actual Price" เพื่อกรอกลงในเครื่อง EDC`)
              }
            ></Button>
          )}
          {item.id && item.total_price !== item.actual_price && (
            <Button
              circular
              icon="warning"
              size="mini"
              onClick={() =>
                setModWarning(
                  "กรุณา VOID รายการยืนยันการใช้สิทธินี้และบันทึกใหม่เพื่อให้ได้ข้อมูลราคาที่ถูกต้อง"
                )
              }
            ></Button>
          )}
          {(item.status === "ACTIVE" || item.status === "VOIDED") && (
            <Button
              circular
              icon="print"
              size="mini"
              onClick={() => setModConfirmReprint(item)}
            ></Button>
          )}
          {item.status === "ACTIVE" ? (
            <Button
              color="red"
              icon
              size="mini"
              onClick={() => setModConfirmVoid({ type: "VOID", data: item })}
            >
              <div style={{ display: "flex" }}>
                <div>
                  <Icon className="trash"></Icon>
                </div>
                <div>VOID</div>
              </div>
            </Button>
          ) : item.status === "VOIDED" ? (
            <Button
              color="orange"
              icon
              size="mini"
              onClick={() => setModConfirmVoid({ type: "UNVOID", data: item })}
            >
              <div style={{ display: "flex" }}>
                <div>
                  <Icon className="undo"></Icon>
                </div>
                <div>UNVOID</div>
              </div>
            </Button>
          ) : item.status === "INACTIVE" ? (
            <Button
              color="green"
              icon
              size="mini"
              onClick={() => setModConfirmVoid({ type: "SAVE", data: item })}
            >
              <div style={{ display: "flex" }}>
                <div>
                  <Icon className="save"></Icon>
                </div>
                <div>SAVE</div>
              </div>
            </Button>
          ) : null}
        </div>
      ),
    }));
  }, [props?.BillingHistorySequence?.edcTransaction]);

  const actionLogItems = useMemo(() => {
    return (props.BillingHistorySequence?.actionLogList || []).map((item: any) => ({
      ...item,
      datetime: formatDatetime(item.datetime),
    }));
  }, [props.BillingHistorySequence?.actionLogList]);

  const handleByOrderRowProps = (state: any, rowInfo: any) => {
    return {
      style: {
        backgroundColor: rowInfo?.original?.highlight && "rgb(255,200,200)",
      },
    };
  };

  const handleSearch = () => {
    setShowBillType(type);

    props.runSequence({
      sequence: "BillingHistory",
      action: "get_data",
      from_date: dataCheck ? startDate : "",
      to_date: dataCheck ? endDate : "",
      division: divisionCheck ? division : "",
      status: statusCheck ? status : "",
      type: type,
      card: CARD_BILLING_HISTORY,
    });
  };

  const onCancelReceipt = () => {
    props.runSequence({
      sequence: "BillingHistory",
      action: "cancel_receipt",
      receipt: receiptId,
      username: username,
      password: password,
      note: cancelReceiptNote,
      from_date: dataCheck ? startDate : "",
      to_date: dataCheck ? endDate : "",
      division: divisionCheck ? division : "",
      status: statusCheck ? status : "",
      type: type,
      onSuccess: () => {
        setShowEDC(false);

        props.setProp("BillingHistorySequence.edcTransaction", []);
      },
    });
  };

  const handlePrintReceiptCopy = (e: any, data: any) => {
    props.runSequence({
      sequence: "BillingHistory",
      action: ACTIONS.PRINT_RECEIPT_COPY,
      card: CARD_BILLING_HISTORY,
      receipt: receiptId,
      note: note,
      callback: (receiptCopy: boolean, note: string) => {
        setOpenReceiptCopy(receiptCopy);
        setNote(note);
      },
    });
  };

  const handlePrintReceipt = () => {
    props.runSequence({
      sequence: "BillingHistory",
      action: "print_receipt",
      receipt: receiptId,
    });
  };

  const handleConfirmReceipt = () => {
    setShowEDC(!showEDC);

    if (!showEDC) {
      props.runSequence({
        sequence: "BillingHistory",
        action: "confirm_receipt",
        receipt: receiptId,
      });
    }

    setTimeout(() => {
      const parent = cardRef.current.parentElement;

      if (parent) {
        parent.scrollTop = parent.scrollHeight;
      }
    }, 100);
  };

  const handlePrintInvoice = () => {
    props.runSequence({
      sequence: "BillingHistory",
      action: "print_invoice",
      receipt: receiptId,
    });
  };

  const handleCancelReceipt = () => {
    setOpenCancelReceipt(true);
  };

  const handleConfirmInvoice = () => {
    props.setProp("BillingHistorySequence.openCardPaying", true);
  };

  const handleChangePatientName = (e: any, data: any) => {
    setPatientName(data.value);
  };

  const handleChangeDoctorName = (e: any, data: any) => {
    props.setProp("BillingHistorySequence.doctorName", data.value);
  };

  const handleChangeOrderBy = (_event: any, data: any) => {
    setByType(data.name);
  };

  const handleGetTrProps = (state: any, rowInfo: any, column: any, instance: any) => ({
    style: {
      backgroundColor: receiptId && rowInfo?.original?.id === receiptId ? "#cccccc" : "white",
    },
    onClick: async () => {
      let newData = {
        ...rowInfo?.original,
        created: (
          <div>
            {formatDate(moment(rowInfo?.original?.edited))} [
            {moment(rowInfo?.original?.edited).format("HH:mm")}]
          </div>
        ),
      };

      setReceiptId(rowInfo?.original?.id);
      setSelectedReceipt(newData);

      setShowEDC(false);

      await props.setProp("BillingHistorySequence.edcTransaction", []);

      if (showBillType === "credit_note") {
        props.runSequence({
          sequence: "BillingHistory",
          action: "get_credit_note",
          receipt: rowInfo?.original?.id,
          by_type: byType,
          patient_id: rowInfo?.original?.patient_id,
          showBillType: showBillType,
        });
      } else {
        props.runSequence({
          sequence: "BillingHistory",
          action: "get_receipt",
          receipt: rowInfo?.original?.id,
          by_type: byType,
          patient_id: rowInfo?.original?.patient_id,
          showBillType: showBillType,
          // type_name === "FULL_PAYMENT" get EncounterDetail
          selectedReceiptType: rowInfo?.original?.type_name,
          selectedReceipt: rowInfo?.original,
        });
      }
    },
  });

  const handleCancelInvoice = (e: any) => {
    props.runSequence({
      sequence: "BillingHistory",
      action: "cancel_invoice",
      receipt: receiptId,
      from_date: dataCheck ? startDate : "",
      to_date: dataCheck ? endDate : "",
      division: divisionCheck ? division : "",
      status: statusCheck ? status : "",
      type: showBillType,
    });
    setOpenInvoice(false);
  };

  const handleCancelCreditNote = (note: any) => {
    props.setProp("errorMessage", {
      ...props.errorMessage,
      [`${CARD_BILLING_HISTORY}_cancel_credit_note`]: null,
    });

    props.runSequence({
      sequence: "BillingHistory",
      action: "cancel_credit_note",
      receipt: props?.BillingHistorySequence?.creditNoteData?.id,
      from_date: dataCheck ? startDate : "",
      to_date: dataCheck ? endDate : "",
      division: divisionCheck ? division : "",
      status: statusCheck ? status : "",
      type: showBillType,
      card: `${CARD_BILLING_HISTORY}_cancel_credit_note`,
      note: note,
    });

    if (note.length >= 10) {
      setOpenCreditNote(false);
    }
  };

  const handleCloseModCancel = () => {
    props.setProp("billReportPrint", {});
  };

  const handleCancelReportPrint = () => {
    props.onEvent({
      message: "HandleReportPrintTrackingResult",
      params: {
        action: "CANCEL",
        taskId: props.billReportPrint?.task_id,
      },
    });
  };

  const handleCloseModLog = () => {
    setOpenModLog(false);

    props.setProp("BillingHistorySequence.logList", []);
  };

  const handleSuccessPayment = () => {
    props.setProp("BillingHistorySequence.openCardPaying", false);

    props.setProp("invoiceHistory", false);

    props.setProp("goToMenu", "รายการรอรับชำระและคืนเงิน");
  };

  const handleCloseMedicalFee = () => {
    props.setProp("invoiceHistory", false);
    props.setProp("BillingHistorySequence.openCardPaying", false);

    props.onEvent({
      message: "RunSequence",
      params: { sequence: "BillPayment", action: "close" },
    });
  };

  const handleCloseReceiptCopy = () => {
    props.setProp("errorMessage", {
      ...props.errorMessage,
      [`${CARD_BILLING_HISTORY}_${ACTIONS.PRINT_RECEIPT_COPY}`]: null,
    });

    setOpenReceiptCopy(false);
    setNote("");
  };

  return (
    <div ref={cardRef} style={{ width: "calc(100vw - 28.5rem)", height: "100%" }}>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`BillPaymentSequence.payErrorMessage`, null);

          props.setProp(`errorMessage.${CARD_BILLING_HISTORY}`, null);
        }}
        error={
          props.BillPaymentSequence?.payErrorMessage || props.errorMessage?.[CARD_BILLING_HISTORY]
        }
        success={null}
        languageUX={props.languageUX}
      />

      <CardBillingHistoryUX
        // disabled button
        roleDoctor={props.roleDoctor}
        // data
        receiptId={receiptId}
        receiptData={invoiceItems}
        byOrderRowProps={handleByOrderRowProps}
        creditNoteData={creditNoteItems}
        creditNoteDetail={props?.BillingHistorySequence?.creditNoteData}
        edcData={edcTransaction}
        orderBy={byType}
        divisionCheck={divisionCheck}
        patientName={patientName}
        doctorName={props?.BillingHistorySequence?.doctorName}
        status={status}
        billingData={receiptItems}
        type={type}
        divisionSelect={division}
        showBillType={showBillType}
        receiptDataStatus={selectedReceipt?.status_name}
        selectedReceipt={selectedReceipt}
        receiptEncounterData={props?.BillingHistorySequence?.receiptEncounterData}
        // options
        doctorNameOptions={doctorNameOptions}
        patientNameOptions={patientNameOptions}
        division={props.masterOptions.division}
        typeOptions={TYPE_OPTIONS}
        statusOptions={STATUS_OPTIONS}
        // config
        isCudent={CONFIG.BIL_RECEIPT === "cudent"}
        showEDC={showEDC}
        style={{ zoom: "90%" }}
        hideBillPayment={props.BillingHistorySequence?.receiptDetail?.["Item"]?.some(
          (item: any) => item.status_name !== "BILLED"
        )}
        tableModeStyle={{ display: byType === "Mode" ? "" : "none" }}
        tableOrderStyle={{ display: byType === "Order" ? "" : "none" }}
        tableItemStyle={{ display: byType === "Item" ? "" : "none" }}
        tableStyle={{ zoom: "100%" }}
        // callback
        printReceipt={handlePrintReceipt}
        confirmReceipt={handleConfirmReceipt}
        cancelReceipt={handleCancelReceipt}
        printInvoice={handlePrintInvoice}
        confirmInvoice={handleConfirmInvoice}
        cancelInvoice={() => setOpenInvoice(true)}
        onChangePatientName={handleChangePatientName}
        onChangeDoctorName={handleChangeDoctorName}
        onChangeOrderBy={handleChangeOrderBy}
        price={formatComma(price.price)}
        send_claim={formatComma(price.send_claim)}
        absorb={formatComma(price.absorb)}
        patient_claim={formatComma(price.patient_claim)}
        non_claimable={formatComma(price.non_claimable)}
        discount={formatComma(price.discount)}
        payable={formatComma(price.payable)}
        installment_sum={formatComma(price.installment_sum)}
        search={handleSearch}
        onSelectedItems={handleGetTrProps}
        onChangeDivisionCheck={(e: any, { checked }: any) => setDivisionCheck(checked)}
        onChangeDivision={(e: any, data: any) => {
          setDivisionCheck(true);
          setDivision(data.value);
        }}
        statusCheck={statusCheck}
        onChangeStatusCheck={(e: any, { checked }: any) => setStatusCheck(checked)}
        onChangeStatus={(e: any, data: any) => {
          setStatus(data.value);
          setStatusCheck(true);
        }}
        onChangeType={(e: any, data: any) => setType(data.value)}
        dateCheck={dataCheck}
        onChangeDateCheck={(e: any, { checked }: any) => setDateCheck(checked)}
        startDate={startDate}
        endDate={endDate}
        onChangeStartDate={(date: any) => {
          setDateCheck(true);
          setStartDate(date);
        }}
        onChangeEndDate={(date: any) => {
          setDateCheck(true);
          setEndDate(date);
        }}
        buttonPrintReceipt={
          props.BillingHistorySequence?.permission?.config_BIL_CAN_PRINT_RECEIPT_COPY && (
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={() => setOpenReceiptCopy(true)}
              // data
              paramKey={`${CARD_BILLING_HISTORY}_${ACTIONS.PRINT_RECEIPT_COPY}`}
              buttonLoadCheck={
                props.buttonLoadCheck?.[`${CARD_BILLING_HISTORY}_${ACTIONS.PRINT_RECEIPT_COPY}`]
              }
              // config
              disabled={
                !["PAID", "REPLACED"].includes(
                  props.BillingHistorySequence?.receiptData?.status_name
                )
              }
              color={"blue"}
              name={ACTIONS.PRINT_RECEIPT_COPY}
              size="mini"
              title={intl.formatMessage({ id: "พิมพ์สำเนาใบเสร็จ" })}
            />
          )
        }
        buttonCancelCreditNote={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => setOpenCreditNote(true)}
            // data
            paramKey={`${CARD_BILLING_HISTORY}_cancel_credit_note`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_BILLING_HISTORY}_cancel_credit_note`]}
            // config
            disabled={selectedReceipt?.status_name === "CANCELED"}
            color="red"
            size="mini"
            title={intl.formatMessage({ id: "ยกเลิกคืนเงิน" })}
          />
        }
        buttonSearch={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSearch}
            // data
            paramKey={`${CARD_BILLING_HISTORY}_search`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_BILLING_HISTORY}_search`]}
            // config
            color="blue"
            size="medium"
            title={intl.formatMessage({ id: "ค้นหา" })}
          />
        }
        languageUX={props.languageUX}
      />

      <ModCancelAdmitOrder
        open={!!openCreditNote}
        title={intl.formatMessage({ id: "กรุณาระบุเหตุผลการยกเลิกใบสำคัญคืนเงิน" })}
        errorMessage={props.errorMessage}
        successMessage={props.successMessage}
        card={`${CARD_BILLING_HISTORY}_cancel_credit_note`}
        // config
        type={"cancel_reserve"}
        // callback
        onApprove={handleCancelCreditNote}
        onDeny={() => {
          props.setProp("errorMessage", {
            ...props.errorMessage,
            [`${CARD_BILLING_HISTORY}_cancel_credit_note`]: null,
          });
          setOpenCreditNote(false);
        }}
        languageUX={props.languageUX}
      />

      <ModConfirm
        titleColor="blue"
        openModal={openInvoice}
        titleName={<label>{intl.formatMessage({ id: "กรุณายืนยัน" })}</label>}
        content={<label>{intl.formatMessage({ id: "ต้องการยกเลิกใบแจ้งหนี้ใช่หรือไม่" })}</label>}
        onApprove={handleCancelInvoice}
        onDeny={() => setOpenInvoice(false)}
        onCloseWithDimmerClick={() => setOpenInvoice(false)}
        size={"mini"}
      />

      <Modal
        closeOnDimmerClick={true}
        style={{ maxHeight: "80%", width: "80%", overflow: "auto" }}
        open={!!props?.BillingHistorySequence?.openCardPaying}
        onClose={handleCloseMedicalFee}
      >
        <CardMedicalFee
          onEvent={props.onEvent}
          setProp={props.setProp}
          // seq
          BillPaymentSequence={props.BillPaymentSequence}
          // data
          patientInfo={props.patientInfo}
          invoiceItemByItems={props?.BillingHistorySequence?.receiptDetail?.["Item"]}
          invoiceHistoryItems={props?.BillingHistorySequence?.receiptDetail?.[byType]}
          // CommonInterface
          selectedEncounter={props.selectedEncounter}
          errorMessage={props.errorMessage}
          masterOptions={props.masterOptions}
          // config
          openPaying={true}
          invoiceId={receiptId}
          orderBy={byType}
          // callback
          onSuccess={handleSuccessPayment}
          // // Bill Report Print
          // billReportPrint={props.billReportPrint}
          // Bill Patient Language
          billingPatientLanguage={props.billingPatientLanguage}
          languageUX={props.languageUX}
        />
      </Modal>

      <ModConfirm
        closeIcon={true}
        titleColor={"blue"}
        openModal={openCancelReceipt}
        titleName={intl.formatMessage({ id: "ระบุเหตุผลการยกเลิกใบเสร็จ" })}
        content={
          <div style={{ fontWeight: "bold" }}>
            <div>Username</div>
            <div style={{ width: "100%" }}>
              <Input
                placeholder="Username"
                value={username}
                onChange={(e: any, { value }: any) => setUsername(value)}
                style={{ width: "100%", marginBottom: "15px" }}
              />
            </div>
            <div>Password</div>
            <div style={{ width: "100%" }}>
              <Input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e: any, { value }: any) => setPassword(value)}
                style={{ width: "100%", marginBottom: "15px" }}
              />
            </div>
            <div>{intl.formatMessage({ id: "เหตุผลการยกเลิกใบเสร็จ" })}</div>
            <div style={{ width: "100%" }}>
              <Dropdown
                selection
                options={props.masterOptions?.cancel_receipt}
                value={cancelReceiptNote}
                onChange={(e: any, data: any) => {
                  setCancelReceiptNote(data.value);
                }}
                style={{ width: "100%", marginBottom: "15px" }}
              />
            </div>
          </div>
        }
        onDeny={() => {
          setUsername("");
          setPassword("");
          setCancelReceiptNote("");
          setOpenCancelReceipt(false);
        }}
        onApprove={onCancelReceipt}
        onCloseWithDimmerClick={() => {
          setUsername("");
          setPassword("");
          setCancelReceiptNote("");
          setOpenCancelReceipt(false);
        }}
        approveButtonText={intl.formatMessage({ id: "ใช่" })}
        approveButtonColor={"green"}
        denyButtonText={intl.formatMessage({ id: "ไม่" })}
        denyButtonColor={"red"}
      />

      <ModInfo
        type="success"
        titleName={intl.formatMessage({ id: "ยกเลิกใบเสร็จ สำเร็จ" })}
        closeOnDimmerClick
        open={openModSuccess}
        onApprove={() => {
          props.setProp("BillingHistorySequence.cancelReceipt", null);
          setOpenModSuccess(false);
        }}
        onClose={() => setOpenModSuccess(false)}
      />

      <ModInfo
        type="error"
        titleName={intl.formatMessage({ id: "ไม่สามารถยกเลิกใบเสร็จได้" })}
        closeOnDimmerClick
        open={openModError}
        children={<ErrorMessage error={props.BillingHistorySequence?.cancelReceiptMessage} />}
        onApprove={() => {
          props.setProp("BillingHistorySequence.cancelReceipt", null);
          setOpenModError(false);
        }}
        onClose={() => setOpenModError(false)}
      />

      <ModInfo
        open={!!props.billReportPrint?.open}
        titleColor={"black"}
        titleName={intl.formatMessage({ id: "กำลังเตรียมเอกสาร" })}
        btnText={intl.formatMessage({ id: "ยกเลิก" })}
        buttonColor="red"
        style={{ width: "27.5%" }}
        onApprove={handleCancelReportPrint}
        // onClose={handleCloseModInfo}
      >
        <div
          style={{
            padding: "0.5rem 0",
            lineHeight: 1.75,
            textAlign: "left",
          }}
        >
          <div>
            กรุณารอสักครู่
            <br />
            {!!props.billReportPrint?.status && (
              <>
                Status: {props.billReportPrint?.status} {props.billReportPrint?.progress}%
                <br />
              </>
            )}
            For IT: task_id = {props.billReportPrint?.task_id}
          </div>
        </div>
      </ModInfo>

      <ModInfo
        open={!!props.billReportPrint?.error}
        titleColor={"red"}
        onApprove={handleCloseModCancel}
        onClose={handleCloseModCancel}
      >
        <div
          style={{
            padding: "0.5rem 0",
            lineHeight: 1.75,
            textAlign: "left",
          }}
        >
          {props.billReportPrint?.error}
        </div>
      </ModInfo>

      <ModInfo
        open={!!modWarning}
        titleColor={"red"}
        onApprove={() => setModWarning("")}
        onClose={() => setModWarning("")}
      >
        <div
          style={{
            padding: "0.5rem 0",
            lineHeight: 1.75,
            textAlign: "left",
          }}
        >
          <div>
            รายการยืนยันนี้มี CN รวมอยู่ด้วยทำให้ "Total Price" และ "Actual Price" ไม่ตรงกัน
          </div>
          <div>{modWarning}</div>
        </div>
      </ModInfo>

      <ModConfirmVoid
        setProp={props.setProp}
        // seq
        runSequence={props.runSequence}
        open={!!modConfirmVoid}
        data={modConfirmVoid?.data}
        receiptId={receiptId}
        // commonInterface
        buttonLoadCheck={props.buttonLoadCheck}
        // config
        errorKey={CARD_BILLING_HISTORY}
        type={modConfirmVoid?.type}
        // callback
        onClose={() => setModConfirmVoid(null)}
      />

      <ModConfirmReprint
        setProp={props.setProp}
        // seq
        runSequence={props.runSequence}
        open={!!modConfirmReprint}
        data={modConfirmReprint}
        receiptId={receiptId}
        // commonInterface
        buttonLoadCheck={props.buttonLoadCheck}
        // config
        errorKey={CARD_BILLING_HISTORY}
        // callback
        onClose={() => setModConfirmReprint(null)}
      />

      <Modal
        open={openModLog}
        closeOnDimmerClick={true}
        style={{ padding: "1rem" }}
        onClose={handleCloseModLog}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: "1.1rem",
              fontWeight: "bold",
              padding: "2.5px 0",
            }}
          >
            ประวัติการทำงาน
          </div>
        </div>
        <Divider style={{ margin: "1rem 0 0.5rem" }} />

        <Table
          keys="name,action,approval_code,datetime"
          headers="User,Action,Approval Code,Date - Time"
          data={actionLogItems}
          showPagination={false}
          style={{ height: "300px" }}
        />
      </Modal>

      <ModConfirm
        openModal={openReceiptCopy}
        onCloseWithDimmerClick={handleCloseReceiptCopy}
        onDeny={handleCloseReceiptCopy}
        onApprove={handlePrintReceiptCopy}
        approveButtonText={intl.formatMessage({ id: "ใช่" })}
        denyButtonText={intl.formatMessage({ id: "ไม่ใช่" })}
        basic
        approveButtonColor="green"
        denyButtonColor="red"
        titleName={intl.formatMessage({ id: "ระบุเหตุผลการพิมพ์สำเนาใบเสร็จ" })}
        size="tiny"
        content={
          <div>
            <ErrorMessage
              error={props.errorMessage?.[`${CARD_BILLING_HISTORY}_${ACTIONS.PRINT_RECEIPT_COPY}`]}
            />
            <div style={{ fontWeight: "bold", marginBottom: "10px" }}>
              ระบุเหตุผลการพิมพ์สำเนาใบเสร็จ<span style={{ color: "red" }}>*</span>
            </div>
            <Form>
              <TextArea
                rows={4}
                onChange={(e) => setNote(e.target.value)}
                style={{ resize: "none", width: "100%" }}
                value={note}
              ></TextArea>
            </Form>
          </div>
        }
      />

      <ModInfo
        type="success"
        titleColor={"green"}
        titleName={intl.formatMessage({ id: "แจ้งเตือน" })}
        closeOnDimmerClick
        open={copiedPrintSuccess}
        onApprove={() => {
          props.setProp("buttonLoadCheck.CardBillingHistory_print_receipt_copy", null);
          setCopiedPrintSuccess(false);
        }}
        alertText={
          <div style={{ fontSize: "16px", fontWeight: "bold" }}>{"พิมพ์สำเนาใบเสร็จสำเร็จ"}</div>
        }
        onClose={() => setCopiedPrintSuccess(false)}
      />
    </div>
  );
};

export default CardBillingHistory;
