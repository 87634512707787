import moment from "moment";
import { beToAd } from "react-lib/utils/dateUtils";

// Utils
//import { formatDate } from "react-lib/utils/dateUtils";

const FormDeathCertificate = (props: any) => {
  //console.log("Properties Preview Data: ", props);

  const newBirthDate = beToAd(props.items.patientInfo?.birthdate);
  const newDeathDate = beToAd(props.items.deathDate);

  const diffDate = moment(newDeathDate).diff(newBirthDate);
  const yearDuration = moment.duration(diffDate).years();
  const monthDuration = moment.duration(diffDate).months();
  const dayDuration = moment.duration(diffDate).days();

  // get Day from Date
  const getDayDate = (date: string) => {
    return date.slice(0, 2);
  };
  // get Month Name from Date
  const getMonthLocale = (date: string) => {
    const getMonth = date.slice(3, 5);
    return moment(getMonth, "MM").locale("th").format("MMMM");
  };
  // get Year from Date
  const getYearDate = (date: string) => {
    return date.slice(6, 10);
  };

  // get Gender (TH)
  const getGender = (gender: string) => {
    switch (gender) {
      case "M":
        return "ชาย";
      case "F":
        return "หญิง";
      default:
        return "ไม่ระบุ";
    }
  };

  // Value Checking (Icd10TypeC)
  const haveIcd10TypeCValue = (value: string) => {
    if (value === "" || value === null || value === undefined) {
      return null;
    } else {
      return [
        { text: ``, border: [true, false, false, false] },
        {
          columns: [
            { text: `(c)`, alignment: "right", width: 20 },
            {
              stack: [
                { text: `${props.items.icd10TypeC}`, style: "icd10Text" },
                {
                  text: `เนื่องจาก (หรือเป็นผลที่เกิดจาก)`,
                  style: "explainText",
                },
              ],
            },
          ],
          columnGap: 10,
          border: [false, false, true, false],
          colSpan: 2,
        },
        {},
        {
          text: `${props.items.amountC ? props.items.amountC : "-"} ${
            props.items.amountTypeC ? props.items.amountTypeC : " "
          }`,
          alignment: "center",
          border: [true, false, true, false],
        },
      ];
    }
  };

  // Value Checking (Icd10TypeD)
  const haveIcd10TypeDValue = (value: string) => {
    if (value === "" || value === null || value === undefined) {
      return null;
    } else {
      return [
        { text: ``, border: [true, false, false, true] },
        {
          columns: [
            { text: `(d)`, alignment: "right", width: 20 },
            {
              stack: [
                { text: `${props.items.icd10TypeD}`, style: "icd10Text" },
                {
                  text: `เนื่องจาก (หรือเป็นผลที่เกิดจาก)`,
                  style: "explainText",
                },
              ],
            },
          ],
          columnGap: 10,
          border: [false, false, true, true],
          colSpan: 2,
        },
        {},
        {
          text: `${props.items.amountD ? props.items.amountD : "-"} ${
            props.items.amountTypeD ? props.items.amountTypeD : " "
          }`,
          alignment: "center",
          border: [true, false, true, true],
        },
      ];
    }
  };

  // Checking Value (Condition1)
  const haveCondition1Type = () => {
    if (
      props.items.condition2Type === undefined ||
      props.items.condition2Type === "undefined" ||
      props.items.condition2Type === null
    ) {
      return [
        {
          text: `เงื่อนไขที่สำคัญอื่น ๆ`,
          bold: true,
          border: [true, false, false, true],
        },
        {
          columns: [
            { text: ``, width: 5 },
            {
              text: `${undefinedCheck(props.items.condition1Type)}`,
              style: "icd10Text",
            },
          ],
          columnGap: 10,
          border: [false, false, true, true],
          colSpan: 2,
        },
        {},
        {
          text: `${props.items.amountCondition1 ? props.items.amountCondition1 : "-"} ${
            props.items.amountTypeCondition1 ? props.items.amountTypeCondition1 : " "
          }`,
          alignment: "center",
          border: [true, false, true, true],
        },
      ];
    } else {
      return [
        {
          text: `เงื่อนไขที่สำคัญอื่น ๆ`,
          bold: true,
          border: [true, false, false, false],
        },
        {
          columns: [
            { text: ``, width: 5 },
            {
              text: `${undefinedCheck(props.items.condition1Type)}`,
              style: "icd10Text",
            },
          ],
          columnGap: 10,
          border: [false, false, true, false],
          colSpan: 2,
        },
        {},
        {
          text: `${props.items.amountCondition1 ? props.items.amountCondition1 : "-"} ${
            props.items.amountTypeCondition1 ? props.items.amountTypeCondition1 : " "
          }`,
          alignment: "center",
          border: [true, false, true, false],
        },
      ];
    }
  };

  // Checking Value (Condition2)
  const haveCondition2Type = (value: string) => {
    if (value === "" || value === null || value === undefined) {
      return null;
    } else {
      return [
        { text: ``, border: [true, false, false, true] },
        {
          columns: [
            { text: ``, width: 5 },
            { text: `${props.items.condition2Type}`, style: "icd10Text" },
          ],
          columnGap: 10,
          border: [false, false, true, true],
          colSpan: 2,
        },
        {},
        {
          text: `${props.items.amountCondition2 ? props.items.amountCondition2 : "-"} ${
            props.items.amountTypeCondition2 ? props.items.amountTypeCondition2 : " "
          }`,
          alignment: "center",
          border: [true, false, true, true],
        },
      ];
    }
  };

  // undefined Value Check
  const undefinedCheck = (value: any) => {
    if (value == undefined || value == null) {
      return "";
    } else {
      return value;
    }
  };

  // Empty Obj Check
  const emptyObjectCheck = (obj: any) => {
    return obj !== null && typeof obj === "object" && Object.keys(obj).length > 0;
  };

  // Format Address of Patient
  const formattedAddress = {
    haveAddress: emptyObjectCheck(props.items?.patientInfo?.present_address),
    no:
      props.items?.patientInfo?.present_address?.no !== null
        ? props.items?.patientInfo?.present_address?.no
        : " ",
    town:
      props.items?.patientInfo?.present_address?.town !== null
        ? props.items?.patientInfo?.present_address?.town
        : " ",
    name:
      props.items?.patientInfo?.present_address?.name !== null
        ? props.items?.patientInfo?.present_address?.name
        : " ",
    street:
      props.items?.patientInfo?.present_address?.street !== null
        ? props.items?.patientInfo?.present_address?.street
        : " ",
    road:
      props.items?.patientInfo?.present_address?.road !== null
        ? props.items?.patientInfo?.present_address?.road
        : " ",
    city:
      props.items?.patientInfo?.present_address?.city_label !== null
        ? props.items?.patientInfo?.present_address?.city_label
        : " ",
    district:
      props.items?.patientInfo?.present_address?.district_label !== null
        ? props.items?.patientInfo?.present_address?.district_label
        : " ",
    province:
      props.items?.patientInfo?.present_address?.province_label !== null
        ? props.items?.patientInfo?.present_address?.province_label
        : " ",
    zipcode:
      props.items?.patientInfo?.present_address?.zipcode !== null
        ? props.items?.patientInfo?.present_address?.zipcode
        : " ",
  };

  // Address of Patient
  const addressPatientCheck = (haveAddress: boolean) => {
    if (!haveAddress) {
      return {
        columns: [
          {
            text: `ที่อยู่`,
            bold: true,
            width: `auto`,
          },
          {
            text: ` :`,
            preserveLeadingSpaces: true,
            width: `auto`,
          },
          {
            text: ``,
            width: 5,
          },
          {
            text: `ไม่ทราบที่อยู่`,
            width: `auto`,
          },
        ],
      };
    } else {
      return {
        columns: [
          {
            text: `ที่อยู่`,
            bold: true,
            width: `auto`,
          },
          {
            text: ` :`,
            preserveLeadingSpaces: true,
            width: `auto`,
          },
          {
            text: ``,
            width: 5,
          },
          {
            text: `${formattedAddress.no} ${formattedAddress.name} ${formattedAddress.town} ${formattedAddress.road} ${formattedAddress.district} ${formattedAddress.province} ${formattedAddress.zipcode}`,
            width: `auto`,
          },
        ],
      };
    }
  };

  return {
    pageSize: "A4",
    defaultStyle: {
      font: "THSarabunNew",
      lineHeight: 1,
      fontSize: 14,
    },
    header: props.header,
    footer: props.footer,

    content: [
      // Certificate Header
      {
        text: `หนังสือรับรองการเสียชีวิต`,
        fontSize: 16,
        bold: true,
        alignment: "center",
      },
      {
        text: `วันที่ ${props.items?.formatCreatedDate}`,
        bold: true,
        alignment: "right",
      },

      // Table 1 Infomation Section
      {
        style: "tableFormat",
        unbreakable: true,
        table: {
          widths: ["30%", "30%", "35%", "*"],
          //heights: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 30, 'auto', 50],
          headerRows: 1,
          body: [
            // border: [left, top, right, bottom]
            // Section 1 ( Patient Info )
            [
              {
                text: `ข้อมูลเกี่ยวกับการเสียชีวิต`,
                fontSize: 16,
                bold: true,
                color: "white",
                colSpan: 4,
                fillColor: "black",
              },
              {},
              {},
              {},
            ],
            [
              {
                columns: [
                  {
                    text: `ชื่อของผู้เสียชีวิต`,
                    bold: true,
                    width: `auto`,
                  },
                  {
                    text: ` :`,
                    preserveLeadingSpaces: true,
                    width: `auto`,
                  },
                  {
                    text: ``,
                    width: 5,
                  },
                  {
                    text: `${
                      props.items.patientInfo?.full_name_th !== ""
                        ? props.items?.patientInfo?.full_name_th
                        : props.items?.patientInfo?.full_name
                    }`,
                    width: `auto`,
                  },
                ],
                border: [true, true, false, true],
                colSpan: 2,
              },
              {},
              {
                columns: [
                  {
                    text: `เพศ`,
                    bold: true,
                    width: `auto`,
                  },
                  {
                    text: ` :`,
                    preserveLeadingSpaces: true,
                    width: `auto`,
                  },
                  {
                    text: ``,
                    width: 5,
                  },
                  {
                    text: `${getGender(props.items.patientInfo?.gender)}`,
                    width: `auto`,
                  },
                ],
                border: [false, true, true, true],
                colSpan: 2,
              },
              {},
            ],
            [
              {
                columns: [
                  {
                    text: `วันเกิด`,
                    width: `auto`,
                    bold: true,
                  },
                  {
                    text: ` :`,
                    preserveLeadingSpaces: true,
                    width: `auto`,
                  },
                  {
                    text: ``,
                    width: 5,
                  },
                  {
                    text: `${getDayDate(props.items.patientInfo?.birthdate)} ${getMonthLocale(
                      props.items.patientInfo?.birthdate
                    )} ${getYearDate(props.items.patientInfo?.birthdate)}`,
                    width: `auto`,
                  },
                ],
                border: [true, true, false, true],
              },
              {
                columns: [
                  {
                    text: `วันเสียชีวิต`,
                    width: `auto`,
                    bold: true,
                  },
                  {
                    text: ` :`,
                    preserveLeadingSpaces: true,
                    width: `auto`,
                  },
                  {
                    text: ``,
                    width: 5,
                  },
                  {
                    text: `${getDayDate(props.items.deathDate)} ${getMonthLocale(
                      props.items.deathDate
                    )} ${getYearDate(props.items.deathDate)}`,
                    width: `auto`,
                  },
                ],
                border: [false, true, false, true],
              },
              {
                columns: [
                  {
                    text: `อายุ`,
                    width: `auto`,
                    bold: true,
                  },
                  {
                    text: ` :`,
                    preserveLeadingSpaces: true,
                    width: `auto`,
                  },
                  {
                    text: ``,
                    width: 5,
                  },
                  {
                    text: `${yearDuration} ปี ${monthDuration} เดือน ${dayDuration} วัน`,
                    width: `auto`,
                  },
                ],
                border: [false, true, true, true],
                colSpan: 2,
              },
              {},
            ],
            [
              {
                stack: [
                  {
                    columns: [
                      {
                        text: `สถานที่เสียชีวิต`,
                        width: `auto`,
                        bold: true,
                      },
                      {
                        text: ` :`,
                        preserveLeadingSpaces: true,
                        width: `auto`,
                      },
                      {
                        text: ``,
                        width: 5,
                      },
                      {
                        text: `${props.items.deathLocate}`,
                        width: `auto`,
                      },
                    ],
                  },
                  addressPatientCheck(formattedAddress.haveAddress),
                ],
                colSpan: 4,
              },
              {},
              {},
              {},
            ],
          ].filter((row) => row !== null),
        },
        layout: {
          hLineWidth: function (i: number, node: any) {
            return i === 0 || i === node.table.body.length ? 2 : 1;
          },
          vLineWidth: function (i: number, node: any) {
            return i === 0 || i === node.table.widths.length ? 2 : 1;
          },
          paddingLeft: function (i: number) {
            return i === 0 ? 8 : 4;
          },
          paddingRight: function (i: number) {
            return i === 0 ? 8 : 4;
          },
          paddingTop: function (i: number) {
            return i === 0 ? 1 : 4;
          },
          paddingBottom: function (i: number) {
            return i === 0 ? 1 : 4;
          },
        },
      },

      // Table 2 Cause of Death Section
      {
        style: "tableFormat",
        unbreakable: true,
        table: {
          widths: ["18%", "60%", "2%", "20%"],
          body: [
            [
              {
                text: "สาเหตุที่ทำให้ตาย",
                fontSize: 16,
                bold: true,
                color: "white",
                colSpan: 4,
                fillColor: "black",
              },
              {},
              {},
              {},
            ],
            // Section 2 ( ICD-10 Code )
            [
              {
                text: `ส่วนที่ 1`,
                fontSize: 16,
                bold: true,
                border: [true, true, true, false],
                colSpan: 3,
              },
              {},
              {},
              {
                text: `ระยะเวลาของการเจ็บ\nป่วยจนกระทั่งเสียชีวิต`,
                alignment: "center",
                border: [true, true, true, false],
              },
            ],
            [
              {
                text: `สาเหตุในขณะนั้น`,
                bold: true,
                border: [true, false, false, false],
              },
              {
                columns: [
                  {
                    text: `(a)`,
                    alignment: "right",
                    width: 20,
                  },
                  {
                    stack: [
                      {
                        text: `${props.items.icd10TypeA ? props.items.icd10TypeA : ` `}`,
                        style: "icd10Text",
                      },
                      {
                        text: `เนื่องจาก (หรือเป็นผลที่เกิดจาก)`,
                        style: "explainText",
                      },
                    ],
                  },
                ],
                columnGap: 10,
                border: [false, false, true, false],
                colSpan: 2,
              },
              {},
              {
                text: `${props.items.amountA ? props.items.amountA : "-"} ${
                  props.items.amountTypeA ? props.items.amountTypeA : " "
                }`,
                alignment: "center",
                border: [true, false, true, false],
              },
            ],
            [
              {
                text: `สาเหตุนำ`,
                bold: true,
                border: [true, false, false, false],
              },
              {
                columns: [
                  { text: `(b)`, alignment: "right", width: 20 },
                  {
                    stack: [
                      {
                        text: `${props.items.icd10TypeB ? props.items.icd10TypeB : ` `}`,
                        style: "icd10Text",
                      },
                      {
                        text: `เนื่องจาก (หรือเป็นผลที่เกิดจาก)`,
                        style: "explainText",
                      },
                    ],
                  },
                ],
                columnGap: 10,
                border: [false, false, true, false],
                colSpan: 2,
              },
              {},
              {
                text: `${props.items.amountB ? props.items.amountB : "-"} ${
                  props.items.amountTypeB ? props.items.amountTypeB : " "
                }`,
                alignment: "center",
                border: [true, false, true, false],
              },
            ],
            haveIcd10TypeCValue(props.items.icd10TypeC),
            haveIcd10TypeDValue(props.items.icd10TypeD),
          ].filter((row) => row !== null),
        },
        layout: {
          hLineWidth: function (i: number, node: any) {
            return i === 0 || i === node.table.body.length ? 2 : 1;
          },
          vLineWidth: function (i: number, node: any) {
            return i === 0 || i === node.table.widths.length ? 2 : 1;
          },
          paddingLeft: function (i: number) {
            return i === 0 ? 8 : 4;
          },
          paddingRight: function (i: number) {
            return i === 0 ? 8 : 4;
          },
          paddingTop: function (i: number) {
            return i === 0 ? 1 : 4;
          },
          paddingBottom: function (i: number) {
            return i === 0 ? 1 : 4;
          },
        },
      },

      // Table 3 Condition Section
      {
        style: "tableFormat",
        unbreakable: true,
        table: {
          widths: ["18%", "60%", "2%", "20%"],
          body: [
            // Section 3 ( Condition )
            [
              {
                text: `ส่วนที่ 2`,
                fontSize: 16,
                bold: true,
                border: [true, true, true, false],
                colSpan: 3,
              },
              {},
              {},
              { text: ``, border: [true, true, true, false] },
            ],
            haveCondition1Type(),
            haveCondition2Type(props.items.condition2Type),
          ].filter((row) => row !== null),
        },
        layout: {
          hLineWidth: function (i: number, node: any) {
            return i === 0 || i === node.table.body.length ? 2 : 1;
          },
          vLineWidth: function (i: number, node: any) {
            return i === 0 || i === node.table.widths.length ? 2 : 1;
          },
          paddingLeft: function (i: number) {
            return i === 0 ? 8 : 4;
          },
          paddingRight: function (i: number) {
            return i === 0 ? 8 : 4;
          },
          paddingTop: function (i: number) {
            return i === 0 ? 1 : 4;
          },
          paddingBottom: function (i: number) {
            return i === 0 ? 1 : 4;
          },
        },
      },

      // Table 4 Manner Section
      {
        style: "tableFormat",
        unbreakable: true,
        table: {
          widths: ["33%", "*", "25%", "*"],
          body: [
            // Section 4 ( Manner )
            [
              {
                text: `พฤติการณ์ที่ตาย`,
                fontSize: 16,
                bold: true,
                color: "white",
                colSpan: 4,
                fillColor: "black",
              },
              {},
              {},
              {},
            ],
            [
              {
                text: props.items?.deathManner
                  ? `สภาวะ${undefinedCheck(props.items.deathManner)}`
                  : `-`,
                colSpan: 4,
              },
              {},
              {},
              {},
            ],
          ].filter((row) => row !== null),
        },
        layout: {
          hLineWidth: function (i: number, node: any) {
            return i === 0 || i === node.table.body.length ? 2 : 1;
          },
          vLineWidth: function (i: number, node: any) {
            return i === 0 || i === node.table.widths.length ? 2 : 1;
          },
          paddingLeft: function (i: number) {
            return i === 0 ? 8 : 4;
          },
          paddingRight: function (i: number) {
            return i === 0 ? 8 : 4;
          },
          paddingTop: function (i: number) {
            return i === 0 ? 1 : 4;
          },
          paddingBottom: function (i: number) {
            return i === 0 ? 1 : 4;
          },
        },
      },

      // Doctor's Signature [Setting with Note]
      {
        margin: [0, 24, 0, 30],
        unbreakable: true,
        //absolutePosition: {x: 0, y: 752},
        columns: [
          {
            columns: [
              { text: `ลงนามแพทย์ผู้ตรวจ ` },
              {
                stack: [
                  {
                    text: "............................................................",
                  },
                  { text: `(${props.items.doctorName}, MD)` },
                ],
              },
            ],
          },
          {
            columns: [
              { text: `เลขใบประกอบวิชาชีพแพทย์` },
              { text: "", width: 5 },
              // Test Data
              // { text: `ก. 584120`, style: "icd10Text" },
              {
                text: undefinedCheck(props.items.doctorCertNumber)
                  ? undefinedCheck(props.items.doctorCertNumber)
                  : "                  ",
                preserveLeadingSpaces: true,
                decoration: "underline",
                decorationStyle: "dotted",
                margin: [-100, 0, 0, 0],
              },
            ],
          },
        ],
        alignment: "center",
      },
    ],
  };
};

export default FormDeathCertificate;
