import React, { CSSProperties, SyntheticEvent, useCallback, useMemo, useState } from "react";

import { Button, Icon, Pagination } from "semantic-ui-react";

import { RowInfo } from "react-table-6";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm"
import ModInfo from "react-lib/apps/common/ModInfo"

// UX
import { CARD_SETTING_PACKAGE } from "./CardSettingPackage";
import SubSearchPackageUX from "./SubSearchPackageUX";
import { useIntl } from "react-intl";

// Interface
import {
  FilterPackageType,
  MasterOptionsType,
  PACKAGE_LIST_LIMIT,
  PACKAGE_SEARCH_ID,
  PACKAGE_STATUS_OPTIONS,
  PACKAGE_USAGE_STATUS,
  RunSequence,
  SetProp,
  State,
  filterPackageInit,
} from "./sequence/SettingPackage";

// Types
type SubSearchPackageProps = {
  onEvent: (e: any) => any;
  setProp: SetProp;
  // seq
  runSequence: RunSequence;
  SettingPackageSequence?: State["SettingPackageSequence"];
  buttonLoadCheck?: Record<string, any>;
  errorMessage?: Record<string, any>;
  // CommonInterface
  searchedItemListWithKey?: Record<string, any>;
  // options
  masterOptions?: MasterOptionsType;
  // callback
  onEditPackage?: () => any;
  onNewPackage?: () => any;
};

// Const
const BUTTON_ACTIONS = {
  delete: "DELETE",
  search: "LIST_PACKAGE",
  select: "SELECT_PACKAGE",
};

const AlignRight = {
  display: "grid",
  placeContent: "flex-end",
  textAlign: "right",
} as CSSProperties;

const ALL_OPTION = { key: 1, text: "แสดงทั้งหมด", value: "ALL" };

export const SUB_SEARCH_PACKAGE = "SubSearchPackage";

const ACTION_SELECT = `${SUB_SEARCH_PACKAGE}_${BUTTON_ACTIONS.select}`;
const ACTION_DELETE = `${SUB_SEARCH_PACKAGE}_${BUTTON_ACTIONS.delete}`;
const ACTION_SEARCH = `${SUB_SEARCH_PACKAGE}_${BUTTON_ACTIONS.search}`;

const SubSearchPackage = (props: SubSearchPackageProps) => {
  const intl = useIntl();
  const [modDelete, setModDelete] = useState<any>(null);

  // Use Callback
  const handleSelectedItem = useCallback(
    async (value: any, key: any) => {
      props.runSequence({
        sequence: "SettingPackage",
        action: "SELECT_PACKAGE",
        packageId: key,
      });
    },
    [props.searchedItemListWithKey]
  );

  const handleGetTdProps = useCallback(() => ({ style: { paddingLeft: "10px" } }), []);

  const handleGetTrProps = useCallback(
    (state: any, rowInfo?: RowInfo) => {
      const packageId = props.SettingPackageSequence?.selectedPackage;
      const detail = props.SettingPackageSequence?.packageDetail;

      return {
        className:
          detail?.isActionEdit && packageId === rowInfo?.original.id ? "blueSelectedRow" : "",
      };
    },
    [props.SettingPackageSequence?.packageDetail, props.SettingPackageSequence?.selectedPackage]
  );

  const formatPrice = useCallback(
    (price: string) => (
      <div style={AlignRight}>
        {price
          ? Number(price).toLocaleString("en-US", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
              style: "decimal",
            })
          : ""}
      </div>
    ),
    []
  );

  const handleEdit = useCallback((data: any, index: number) => {
    props.runSequence({
      sequence: "SettingPackage",
      action: "SELECT_PACKAGE",
      card: SUB_SEARCH_PACKAGE,
      index,
      isActionEdit: true,
      packageId: data.id,
    });

    props.onEditPackage?.();
  }, []);

  const handleDelete = useCallback((data: any) => {
    setModDelete(data);
  }, []);

  // Memo
  const filterPackage = useMemo(
    () => props.SettingPackageSequence?.filterPackage || {},
    [props.SettingPackageSequence?.filterPackage]
  );

  const searchedItemListWithKey = useMemo(() => {
    const items: Record<string, any>[] = props.searchedItemListWithKey?.[PACKAGE_SEARCH_ID] || [];

    return {
      ...props.searchedItemListWithKey,
      [PACKAGE_SEARCH_ID]: items.map((item) => ({
        ...item,
        name_code: `[${item.code}] ${item.name}`,
      })),
    };
  }, [props.searchedItemListWithKey]);

  const selectedPackageId = useMemo(() => {
    const packageId = props.SettingPackageSequence?.selectedPackage || null;
    const detail = props.SettingPackageSequence?.packageDetail;

    return packageId && !detail?.isActionEdit ? packageId : null;
  }, [props.SettingPackageSequence?.packageDetail, props.SettingPackageSequence?.selectedPackage]);

  const packageTypeOptions = useMemo(
    () => [ALL_OPTION, ...(props.masterOptions?.packageType || [])],
    [props.masterOptions?.packageType]
  );

  const packageServiceTypeOptions = useMemo(
    () => [ALL_OPTION, ...(props.masterOptions?.packageServiceType || [])],
    [props.masterOptions?.packageServiceType]
  );

  const packageStatusOptions = useMemo(() => [ALL_OPTION, ...PACKAGE_STATUS_OPTIONS], []);

  const packageItems = useMemo(() => {
    const items = props.SettingPackageSequence?.packageList?.items || [];

    return items.map((item, index) => {
      const rightFormat = Object.fromEntries(
        "price_normal,price_premium,price_foreign"
          .split(",")
          .map<any>((k) => [k, formatPrice((item as any)[k])])
      );

      const packageType =
        props.masterOptions?.packageType?.find((option) => option.value === item.package_type)
          ?.text || "";

      const packageServiceType =
        props.masterOptions?.packageServiceType?.find(
          (option) => option.value === item.package_service_type
        )?.text || "";

      return {
        ...item,
        ...rightFormat,
        action: (
          <RenderButtonAction
            data={item}
            index={index}
            // CommonInterface
            buttonLoadCheck={props.buttonLoadCheck}
            onDelete={handleDelete}
            // callback
            onEdit={handleEdit}
          />
        ),
        package_service_type_name: packageServiceType,
        package_type_name: packageType,
        status_name: item.active ? PACKAGE_USAGE_STATUS.active : PACKAGE_USAGE_STATUS.inactive,
      };
    });
  }, [props.buttonLoadCheck, props.masterOptions, props.SettingPackageSequence?.packageList]);

  // Handler
  const handleChangeValue = (e: any, data: any) => {
    const value = data.value === undefined ? data.checked : data.value;
    const name = data.name as keyof FilterPackageType;

    const detail = { ...filterPackage };

    detail[name] = value;

    props.setProp("SettingPackageSequence.filterPackage", { ...detail });
  };

  const handleAddNewPackage = async () => {
    props.runSequence({ sequence: "SettingPackage", action: "NEW_PACKAGE" });

    props.onNewPackage?.();
  };

  const handleCloseModNotFound = () => {
    props.setProp(`errorMessage.${SUB_SEARCH_PACKAGE}_${BUTTON_ACTIONS.search}`, null);
  };

  const handleClear = async () => {
    props.setProp("SettingPackageSequence.filterPackage", filterPackageInit);
  };

  const handleConfirmDelete = () => {
    props.runSequence({
      sequence: "SettingPackage",
      action: "DELETE",
      card: SUB_SEARCH_PACKAGE,
      errorKey: CARD_SETTING_PACKAGE,
      packageId: modDelete.id,
      onSuccess: handleCloseModDelete,
    });
  };

  const handleCloseModDelete = () => {
    setModDelete(null);
  };

  const handlePageChange = (e: any, data: any) => {
    handleSearch(data.activePage)();
  };

  const handleSearch =
    (page = 1) =>
    () => {
      props.runSequence({
        sequence: "SettingPackage",
        action: "LIST_PACKAGE",
        activePage: page,
        card: SUB_SEARCH_PACKAGE,
      });
    };

  return (
    <div>
      <SubSearchPackageUX
        filterPackage={filterPackage}
        // data
        packageList={packageItems}
        showDetail={props.SettingPackageSequence?.showDetail}
        packageServiceTypeOptions={packageServiceTypeOptions}
        packageStatusOptions={packageStatusOptions}
        // options
        packageTypeOptions={packageTypeOptions}
        // callback
        onAddNewPackage={handleAddNewPackage}
        onChangeValue={handleChangeValue}
        onClear={handleClear}
        // table
        onGetTdProps={handleGetTdProps}
        // #onDelete={handleDelete}
        onGetTrProps={handleGetTrProps}
        ButtonSearch={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            // config
            color={"blue"}
            name={BUTTON_ACTIONS.search}
            // data
            paramKey={ACTION_SEARCH}
            size="medium"
            title={intl.formatMessage({ id: "ค้นหา" })}
            buttonLoadCheck={props.buttonLoadCheck?.[ACTION_SEARCH]}
            onClick={handleSearch()}
          />
        }
        Pagination={
          <Pagination
            activePage={props.SettingPackageSequence?.packageList?.activePage || 0}
            nextItem={{ icon: true, content: <Icon name="angle right" /> }}
            prevItem={{ icon: true, content: <Icon name="angle left" /> }}
            size="mini"
            // callback
            onPageChange={handlePageChange}
            ellipsisItem={{
              icon: true,
              content: <Icon name="ellipsis horizontal" />,
            }}
            firstItem={{
              icon: true,
              content: <Icon name="angle double left" />,
            }}
            lastItem={{
              icon: true,
              content: <Icon name="angle double right" />,
            }}
            totalPages={Math.ceil(
              (props.SettingPackageSequence?.packageList?.total || 0) / PACKAGE_LIST_LIMIT
            )}
          />
        }
        // Element
        SearchBox={
          <SearchBoxDropdown
            onEvent={props.onEvent}
            id="SSP"
            clearable={false}
            icon="search"
            limit={20}
            placeholder="package code, package name"
            search={!selectedPackageId}
            selectedItem={selectedPackageId || null}
            style={{ width: "100%" }}
            // config
            type="Package"
            fluid
            inline
            useWithKey
            // Select
            searchedItemListWithKey={searchedItemListWithKey}
            setSelectedItem={handleSelectedItem}
            noResultsMessage={
              <>
                ไม่พบรายการที่ค้นหา{" "}
                <strong style={{ marginLeft: "0.5rem" }}>“กรุณาเพิ่มแพ็กเกจใหม่”</strong>
              </>
            }
          />
        }
        allowDelete={
          !!props.SettingPackageSequence?.packageDetail?.can_delete &&
          !!props.SettingPackageSequence.packageDetail.id
        }
        languageUX={props.languageUX}
      />

      <ModInfo
        btnText={intl.formatMessage({ id: "ปิด" })}
        titleColor={"red"}
        titleName={intl.formatMessage({ id: "แจ้งเตือน!" })}
        onApprove={handleCloseModNotFound}
        onClose={handleCloseModNotFound}
        open={
          props.errorMessage?.[`${SUB_SEARCH_PACKAGE}_${BUTTON_ACTIONS.search}`] === "NOT_FOUND"
        }
      >
        <div style={{ fontWeight: "bold", lineHeight: 1.75, padding: "0.5rem 0" }}>
          <div>{intl.formatMessage({ id: "ไม่พบรายการที่ค้นหา" })}</div>
          <div>{intl.formatMessage({ id: "กรุณาเพิ่มแพ็กเกจใหม่" })}</div>
        </div>
      </ModInfo>

      <ModConfirm
        denyButtonColor="red"
        denyButtonText={intl.formatMessage({ id: "ยกเลิก" })}
        openModal={modDelete}
        size="mini"
        titleColor="red"
        titleName={intl.formatMessage({ id: "ยืนยันการลบแพ็กเกจ" })}
        onCloseWithDimmerClick={handleCloseModDelete}
        onDeny={handleCloseModDelete}
        approveButton={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            // config
            color={"green"}
            name={BUTTON_ACTIONS.delete}
            // data
            paramKey={ACTION_DELETE}
            size="medium"
            title={intl.formatMessage({ id: "ตกลง" })}
            basic
            buttonLoadCheck={props.buttonLoadCheck?.[ACTION_DELETE]}
            onClick={handleConfirmDelete}
          />
        }
        content={
          <div
            style={{
              fontSize: "1.2rem",
              margin: "-0.5rem 0rem -1rem 0rem",
              textAlign: "center",
            }}
          >
            “{modDelete?.name}”
          </div>
        }
      />
    </div>
  );
};

/* ------------------------------------------------------ */

/*                   RenderButtonAction;                  */

/* ------------------------------------------------------ */
type RenderButtonActionProps = {
  data: Record<string, any>;
  index: number;

  onDelete?: (data: Record<string, any>, index: number) => void;
  onEdit?: (data: Record<string, any>, index: number) => void;
} & Pick<SubSearchPackageProps, "buttonLoadCheck">;

const RenderButtonAction = (props: RenderButtonActionProps) => {
  // Memo
  const canDelete = useMemo(
    () => (props.data.can_delete === undefined ? !props.data.active : !!props.data.can_delete),
    [props.data]
  );

  return (
    <div style={{ paddingLeft: "0.5rem" }}>
      <Button
        color="yellow"
        icon="edit"
        loading={!!props.buttonLoadCheck?.[`${ACTION_SELECT}_${props.index}`]}
        size="mini"
        style={{ marginRight: "0.5rem", padding: "0.5rem" }}
        onClick={(event: SyntheticEvent) => {
          event.stopPropagation();

          props.onEdit?.(props.data, props.index);
        }}
      />
      {canDelete && (
        <Button
          color="red"
          icon="trash alternate"
          size="mini"
          style={{ padding: "0.5rem" }}
          onClick={(event: SyntheticEvent) => {
            event.stopPropagation();

            props.onDelete?.(props.data, props.index);
          }}
        />
      )}
    </div>
  );
};

SubSearchPackage.displayName = "SubSearchPackage";

export default React.memo(SubSearchPackage);
