import React from "react";
import { Button, Header, Icon, Modal } from "semantic-ui-react";
import { useIntl } from "react-intl";

const ModalWaitingPrinting = (props: any) => { 
  
  const intl = useIntl();
  return (
  <Modal 
    open={props.active} 
    onClose={props.onClose}
    closeIcon
    >
    <Header color='teal'  icon='print' content={intl.formatMessage({ id: "กำลังทำการพิมพ์เอกสาร" })} />
    <Modal.Content >
      <p>Status: {props.status} {props.progress}%</p>
      <p>For IT: taskId = {props.id}</p>
    </Modal.Content>
  </Modal>
)}

export default ModalWaitingPrinting;