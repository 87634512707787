import React from "react";
import PropTypes from "prop-types";
import DayPickerInput from "react-day-picker/DayPickerInput";
import MomentLocaleUtils from "react-day-picker/moment";
import moment from "moment";
import { validateTime } from "../../utils";

import "react-day-picker/lib/style.css";
import "../../css/DateTextBox.css";

import { Input, Icon, Dropdown, Select } from "semantic-ui-react";
import { useIntl } from "react-intl";

class TimeTextBox extends React.Component<any, any> {
  static propTypes: any;
  static defaultProps: any;

  validateTime: any;
  timerHandle: any;

  constructor(props: any) {
    super(props);
    this.state = {
      time: "",
      open: false,
      localOptions: [],
      minHour: props.minHour || 0,
      maxHour: props.maxHour || 23,
      typingTime: "",
    };
    // console.log("constructor this.state", this.state )
    this.validateTime = validateTime.bind(this);
  }

  componentDidMount() {
    // if (this.props.minHour) {
    //   this.setState({ minHour: this.props.minHour });
    // }
    // if (this.props.maxHour) {
    //   this.setState({ maxHour: this.props.maxHour });
    // }

    if (this.props.useDefaultTime) {
      if (this.validateTime(this.props.defaultTime)) {
        this.setState({ time: this.props.defaultTime });
        this.generateTimeShift(this.props.defaultTime);
      }
    } else if (this.props.value) {
      if (this.validateTime(this.props.value)) {
        this.timerHandle = setTimeout(() => {
          this.generateTimeShift(this.props.value);
          this.setState({ time: this.props.value });
          this.timerHandle = 0;
        }, 1);
      }
    }
  }

  componentWillUnmount() {
    if (this.timerHandle) {
      clearTimeout(this.timerHandle);
      this.timerHandle = 0;
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.availableOptions !== this.props.availableOptions) {
      if (this.validateTime(this.state.time)) {
        this.generateTimeShift(this.state.time);
      } else {
        // @ts-ignore
        this.generateTimeShift();
      }
    }

    if (this.props.interval && prevProps.interval !== this.props.interval) {
      if (this.validateTime(this.state.time)) {
        this.generateTimeShift(this.state.time);
      } else {
        // @ts-ignore
        this.generateTimeShift();
      }
    }

    if (!Number.isNaN(this.props.minHour)) {
      if (prevProps.minHour !== this.props.minHour) {
        this.setState({ minHour: this.props.minHour });
        if (this.validateTime(this.state.time)) {
          this.generateTimeShift(this.state.time);
        } else {
          // @ts-ignore
          this.generateTimeShift();
        }
      }
    }
    if (!Number.isNaN(this.props.maxHour)) {
      if (prevProps.maxHour !== this.props.maxHour) {
        this.setState({ maxHour: this.props.maxHour });
        if (this.validateTime(this.state.time)) {
          this.generateTimeShift(this.state.time);
        } else {
          // @ts-ignore
          this.generateTimeShift();
        }
      }
    }

    if (prevProps.value !== this.props.value) {
      if (this.validateTime(this.props.value)) {
        this.timerHandle = setTimeout(() => {
          this.setState({ time: this.props.value });
          this.generateTimeShift(this.props.value);
        }, 1);
      } else if (this.props.value === null) {
        this.setState({ time: "" });
      }
    }
  }

  generateTimeShift = (hourmin: any) => {
    // console.log("generateTimeShift hourmin: ", hourmin);
    var min = ":00";

    if (
      Array.isArray(this.props.availableOptions) &&
      this.props.availableOptions?.length >= 0
    ) {
      // CU Dent
    } else {
      // Default
      if (hourmin) {
        min = hourmin.slice(-3);
      }
    }
    // console.log("generateTimeShift min: ", min);
    const date = moment().format("MM/DD/YYYY");
    // console.log("generateTimeShift date: ", date);

    let timeList = [];
    let start = moment(
      date + " " + ("0" + this.props.minHour).slice(-2) + min,
      "MM/DD/YYYY HH:mm"
    );
    // console.log("generateTimeShift start: ", start.toString());
    // console.log("generateTimeShift props.minHour", this.props.minHour)
    // console.log("generateTimeShift props.maxHour", this.props.maxHour)

    let end = moment(
      date + " " + ("0" + this.props.maxHour).slice(-2) + min,
      "MM/DD/YYYY HH:mm"
    );
    if (this.props.maxHour == 23) {
      end = moment(
        date + " " + ("0" + this.props.maxHour).slice(-2) + ":59",
        "MM/DD/YYYY HH:mm"
      );
    }
    // console.log("end: ", end.toString());

    if (
      Array.isArray(this.props.availableOptions) &&
      this.props.availableOptions?.length >= 0
    ) {
      // CU dent config
      console.log("CUDent !");
      while (start < end) {
        const time = moment(start).format("HH:mm");
        timeList.push({ text: time, value: time, key: time });
        if (this.props.interval <= 1) {
          start = moment(start).add(5, "minutes");
        } else {
          start = moment(start).add(this.props.interval, "minutes");
        }
      }
    } else {
      // original config
      console.log("Not CUDent !");
      while (start <= end) {
        const time = moment(start).format("HH:mm");
        timeList.push({ text: time, value: time, key: time });
        if (this.props.interval <= 1) {
          start = moment(start).add(5, "minutes");
        } else {
          start = moment(start).add(this.props.interval, "minutes");
        }
      }
    }

    // console.log(
    //   "generateTimeShift this.props.availableOptions",
    //   this.props.availableOptions
    // );

    if (
      Array.isArray(this.props.availableOptions) &&
      this.props.availableOptions?.length >= 0
    ) {
      timeList = timeList.map((item: any) => {
        let found = this.props.availableOptions?.find(
          (aSlot: any) => aSlot.text === item.text
        );
        if (!found && this.props.reservedText) {
          return { ...item, text: item.text + " " + this.props.reservedText };
        } else {
          return item;
        }
      });
    }
    // console.log("generateTimeShift timeList: ", timeList);

    this.setState({ localOptions: timeList });
    return timeList;
  };

  signalTimeChange = (e: any, value: any) => {
    const { onTimeChange } = this.props;

    this.setState({ time: value }, () => {
      if (onTimeChange) {
        this.props.onTimeChange(e, value);
      }
    });
  };

  openDropdown = () => {
    this.setState({ open: true });
  };

  handleTypingOnInputField = (e: any, data: any) => {
    console.log("data: ", data);
    // Store and close Modal

    this.setState({ typingTime: data.searchQuery });
    this.setState({ open: false });
  };

  handleOnBlur = (e: any) => {
    // After User Typing If valid then generate options list
    // Click dropdown looking on list and not selected any one

    if (this.state.typingTime) {
      this.setState({ open: false });
      if (!this.validateTime(this.state.typingTime)) {
        return this.signalTimeChange(e, "");
      }
      console.log(" valid time");

      setTimeout(() => {
        this.generateTimeShift(this.state.typingTime);
        this.setState({ time: "" });
        setTimeout(() => {
          let time = this.state.typingTime;
          this.setState({ time: time });
          this.signalTimeChange(e, time);
          this.setState({ typingTime: "" });
        }, 1);
      }, 1);
    } else {
      this.setState({ open: false });
    }
  };

  handleOnSelected = (e: any, data: any) => {
    this.setState({ typingTime: "" });
    this.setState({ open: false });
    this.signalTimeChange(e, data.value);
  };

  handleOnClose = (e: any, data: any) => {
    if (this.state.open) {
      this.setState({ open: false });
    }
  };

  handleOnOpen = () => {};

  render() {
    const { interval, style } = this.props;

    return (
      <Dropdown
        selection
        lazyLoad
        className={`icon ${this.props.className}`}
        open={this.state.open}
        style={{ ...style }}
        onClick={this.openDropdown}
        onSearchChange={this.handleTypingOnInputField}
        onBlur={this.handleOnBlur}
        options={this.state.localOptions}
        value={this.state.time}
        onChange={this.handleOnSelected}
        onClose={this.handleOnClose}
        onOpen={this.handleOnOpen}
        disabled={this.props.disabled}
        search={this.props.search}
        clearable={this.props.clearable}
        text={this.props.confirmTime}
      ></Dropdown>
    );
  }
}

TimeTextBox.propTypes = {
  interval: PropTypes.number,
  style: PropTypes.object,
  value: PropTypes.string,
  minHour: PropTypes.number,
  maxHour: PropTypes.number,
  onTimeChange: PropTypes.func,
  defaultTime: PropTypes.string,
  useDefaultTime: PropTypes.bool,
  clearable: PropTypes.bool,
  search: PropTypes.bool,
  confirmTime: PropTypes.string,
};

TimeTextBox.defaultProps = {
  interval: 30,
  disabled: false,
  defaultTime: "00:00",
  minHour: 0,
  maxHour: 23,
  useDefaultTime: false,
  clearable: false,
  search: false,
  value: moment().format("HH:mm"),
  className: "",
  confirmTime: "",
};

export default TimeTextBox;
