import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'

const CardPatientPharmaListUX = (props: any) => {
    return(
      <div
        style={{height: "100%", position: "relative"}}>
        <Table
          data={props.encounterList}
          getTdProps={props.encounterListColumnsProps}
          getTrProps={props.encounterListRowProps}
          headers="วันเวลาที่ เข้ารับบริการ,EN,ประเภทคนไข้,ชื่อ-สกุล คนไข้,ประเภทการจัดส่ง,สถานะการจัดส่ง,เลขที่จัดส่ง,วันเวลาที่จัดส่ง,วันเวลาที่รับยา,สถานะการให้คำปรึกษา,ที่อยู่,หมายเหตุ,โทร"
          id="tb-encounterPatientList"
          keys="startDateTime,encounter,patientType,fullname,deliveryType,deliveryStatus,deliveryNo,sentDateTime,receivedDateTime,consultStatus,address,remark,telephone"
          minRows="10"
          showPagination={true}
          style={{ height: "75vh" }}
          widths="^90,^125,^90,^115,^110,^150,^110,^90,^90,^135,^95,^140,^70">
        </Table>
      </div>
    )
}


export default CardPatientPharmaListUX

export const screenPropsDefault = {}

/* Date Time : Tue Nov 07 2023 10:36:10 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{height: \"100%\", position: \"relative\"}"
        }
      },
      "seq": 0
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 1,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.encounterList"
        },
        "getTdProps": {
          "type": "code",
          "value": "props.encounterListColumnsProps"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.encounterListRowProps"
        },
        "headers": {
          "type": "value",
          "value": "วันเวลาที่ เข้ารับบริการ,EN,ประเภทคนไข้,ชื่อ-สกุล คนไข้,ประเภทการจัดส่ง,สถานะการจัดส่ง,เลขที่จัดส่ง,วันเวลาที่จัดส่ง,วันเวลาที่รับยา,สถานะการให้คำปรึกษา,ที่อยู่,หมายเหตุ,โทร"
        },
        "id": {
          "type": "value",
          "value": "tb-encounterPatientList"
        },
        "keys": {
          "type": "value",
          "value": "startDateTime,encounter,patientType,fullname,deliveryType,deliveryStatus,deliveryNo,sentDateTime,receivedDateTime,consultStatus,address,remark,telephone"
        },
        "minRows": {
          "type": "value",
          "value": "10"
        },
        "showPagination": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"75vh\" }"
        },
        "widths": {
          "type": "value",
          "value": "^90,^125,^90,^115,^110,^150,^110,^90,^90,^135,^95,^140,^70"
        }
      },
      "seq": 1,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardPatientPharmaListUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
