import React, { useEffect, useMemo, useState } from "react";
import CardDrugCounsellingUX from "./CardDrugCounsellingUX";
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";
import EmployeeToken from "react-lib/apps/common/EmployeeToken";
import { Button, Icon, Input } from "semantic-ui-react";
import { adToBe } from "react-lib/utils/dateUtils";
import ModConfirm from "react-lib/apps/common/ModConfirm";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import { useIntl } from "react-intl";

const CODE_MSG = "ชื่อผู้บันทึกข้อมูล";
const CARD_DRUG_COUNSELLING = "CardDrugCounselling"

const CardDrugCounselling = (props: any) => {
  const intl = useIntl();
	const [errorMessage, setErrorMessage] = useState<any>(null);

	useEffect(() => {
		props.runSequence({ sequence: "DrugCounselling", restart: true, card: CARD_DRUG_COUNSELLING });
	}, [])

	useEffect(() => {
		setErrorMessage(props.errorMessage || "")
	}, [props.errorMessage])

	const handleOnChange = (name: string, value: any) => {
		props.runSequence({
			sequence: "DrugCounselling",
			action: "change_value",
			name,
			value
		});
	}

	const handleOnPageChange = (page: number) => {
		props.runSequence({ sequence: "DrugCounselling", action: "page_change", page, card: CARD_DRUG_COUNSELLING });
	}

	const mapOptions = (items: any) => {
		return items.map((item: any) => ({
			key: item?.id,
			value: item?.full_name_code,
			text: item?.full_name_code,
		}));
	};

	const drugCounsellingList = useMemo(() => {
		return (props.DrugCounsellingSequence?.drugCounsellingData || []).map((item: any) => (
			{
				...item,
				date: adToBe(item.date),
				_action: (<div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
					<Button
						icon="edit"
						color="yellow"
						size="mini"
						onClick={() => { props.runSequence({ sequence: "DrugCounselling", action: "edit_item", data: item }) }}
					/>
					<Button
						icon="trash alternate"
						color="red"
						size="mini"
						onClick={() => { props.runSequence({ sequence: "DrugCounselling", action: "delete_item", data: item }) }}
					/>
				</div>)
			}
		))
	}, [props.DrugCounsellingSequence?.drugCounsellingData])

	console.log("CardDrugCounselling props: ", drugCounsellingList)

	return (
		<>
			<SnackMessage
				success={props.successMessage?.[CARD_DRUG_COUNSELLING]}
				error={props.errorMessage?.[CARD_DRUG_COUNSELLING]}
				onEvent={props.onEvent}
				onClose={() => {
					props.setProp(`errorMessage.${CARD_DRUG_COUNSELLING}`, null);
					props.setProp(`successMessage.${CARD_DRUG_COUNSELLING}`, null);
				}}
				languageUX={props.languageUX}
			/>
			<CardDrugCounsellingUX
				// data
				filterStartDate={props.DrugCounsellingSequence?.filterStartDate}
				filterEndDate={props.DrugCounsellingSequence?.filterEndDate}
				filterTitle={props.DrugCounsellingSequence?.filterTitle}
				adviseDate={props.DrugCounsellingSequence?.adviseDate}
				title={props.DrugCounsellingSequence?.title}
				description={props.DrugCounsellingSequence?.description}
				drugCounsellingList={drugCounsellingList}
				showDetail={props.DrugCounsellingSequence?.showCounsellingDetail}
				// function
				onChange={handleOnChange}
				onAddItem={() => { props.runSequence({ sequence: "DrugCounselling", action: "add_item" }) }}
				onSearch={() => { props.runSequence({ sequence: "DrugCounselling", action: "search", card: CARD_DRUG_COUNSELLING }) }}
				// element
				advisorSearch={
					<SearchBoxDropdown
						type={"User"}
						id={1}
						style={{ width: "100%" }}
						boxStyle={{ width: "100%" }}
						fluid={true}
						label=""
						toDisplay={(item: any) => `${item?.full_name_code}`}
						onEvent={props.onEvent}
						searchedItemListWithKey={props.searchedItemListWithKey}
						selectedItem={props.DrugCounsellingSequence?.selectedAdvisor}
						setSelectedItem={(value: any, key: any, obj: any) => {
							if (value === null && key === null) {
								handleOnChange("selectedAdvisor", null)
								return;
							}
							let data = props.searchedItemListWithKey?.User_1?.find((item: any) => (item.code || item.id) === key);
							handleOnChange("selectedAdvisor", data)
						}}
						useWithKey={true}
						mapOptions={mapOptions}
					/>
				}
				saveButton={
					<ButtonLoadCheck
						// function
						setProp={props.setProp}
						onClick={() => { props.runSequence({ sequence: "DrugCounselling", action: "save", card: CARD_DRUG_COUNSELLING }) }}
						// data
						disabled={!props.userTokenize?.employeeName}
						paramKey={CARD_DRUG_COUNSELLING}
						buttonLoadCheck={
							props.buttonLoadCheck?.[CARD_DRUG_COUNSELLING]
						}
						// config
						style={{marginLeft: "10px"}}
						color={props.DrugCounsellingSequence?.selectedId ? "yellow" : "green"}
						fluid={true}
						// name={}
						title={props.DrugCounsellingSequence?.selectedId ? "แก้ไข" : "บันทึก"}
					/>
				}
				advisorCode={
					<EmployeeToken
						placeholder={intl.formatMessage({ id: "รหัสผู้บันทึก" })}
						onEnterToken={(code) => {
							props.onEvent({
								message: "UserTokenize",
								params: {
									action: "CheckUserToken",
									code: code,
								},
							});

							// props.onEvent({
							//   message: "HandleGetDeliverDrugTokenization",
							//   params: { code },
							// });
						}}
						onClearToken={() =>
							props.setProp("userTokenize", {
								...props.userTokenize,
								token: "",
								employeeName: "",
								loading: false,
								error: null,
							})
						}
						error={!!props.userTokenize?.error || (errorMessage?.toString()?.includes(CODE_MSG) && !props.userTokenize?.employeeName)}
						loading={props.userTokenize?.loading}
						employeeName={props.userTokenize?.employeeName}
						employeeCode={props.userTokenize?.employeeCode}
					/>}
				closeIcon={
					<div style={{ display: "flex", justifyContent: "flex-end", marginTop: "-10px" }}>
						<Icon
							name="close"
							color="red"
							onClick={() => {
								props.runSequence({ sequence: "DrugCounselling", action: "close_detail" })
							}} /></div>
				}
				pagination={
					<div style={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}>
						<Pagination
							page={props.DrugCounsellingSequence?.page}
							totalPage={props.DrugCounsellingSequence?.totalPage}
							onChangePage={handleOnPageChange}
						/>
					</div>
				}
				languageUX={props.languageUX}
			/>
			<ModConfirm
				open={props.DrugCounsellingSequence?.openMod}
				titleName={props.DrugCounsellingSequence?.modTitle}
				titleColor={props.DrugCounsellingSequence?.modTitleColor}
				buttonLeft={intl.formatMessage({ id: "ยืนยัน" })}
				buttonLeftColor={"green"}
				onButtonLeftClick={() => {
					if (props.DrugCounsellingSequence?.modAction === "EDIT") {
						props.runSequence({ sequence: "DrugCounselling", action: "save", card: CARD_DRUG_COUNSELLING })
					} else if (props.DrugCounsellingSequence?.modAction === "DELETE") {
						props.runSequence({ sequence: "DrugCounselling", action: "delete_item", card: CARD_DRUG_COUNSELLING })
					}
				}}
				alertText={<Input
					style={{ width: "100%" }}
					value={props.DrugCounsellingSequence?.note}
					onChange={(e: any, data: any) => handleOnChange("note", data.value)}
				/>}
				buttonRight={intl.formatMessage({ id: "ยกเลิก" })}
				buttonRightColor={"red"}
				onButtonRightClick={() => {
					props.runSequence({ sequence: "DrugCounselling", action: "close_modal" })
				}}
			/>
		</>
	)
}

export const Pagination = (props: any) => {
	const [page, setPage] = useState<any>(null)

	useEffect(() => {
		setPage(props.page)
	}, [props.page])

	const nextPage = () => {
		if (parseInt(page) + 1 <= props.totalPage) {
			props.onChangePage(page + 1)
		}
	}

	const previousPage = () => {
		if (parseInt(page) - 1 >= 1) {
			props.onChangePage(parseInt(page) - 1)
		}
	}

	const pageChange = () => {
		if (parseInt(page) < 1) {
			setPage(1)
			props.onChangePage(1)
		} else if (parseInt(page) > props.totalPage) {
			setPage(props.totalPage)
			props.onChangePage(props.totalPage)
		} else {
			props.onChangePage(parseInt(page))
		}
	}

	const handleKeyPress = (e: any) => {
		console.log("handleKeyPress:", e.key)
		if (e.key === "Enter") {
			pageChange();
		}
	};

	return <div style={{ display: "flex", alignItems: "center" }}>
		<Icon name="caret left" onClick={previousPage} size="large"/>
		<Input
			style={{ minWidth: "10px", width: "50px" }}
			value={page}
			onChange={(e: any, data: any) => { setPage(data.value) }}
			onKeyPress={handleKeyPress}
			
		/>
		<span>/</span>
		<span>{props.totalPage}</span>
		<Icon name="caret right" onClick={nextPage} size="large"/>
	</div>
}

export default CardDrugCounselling