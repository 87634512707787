import DPIService from "../services/DPIService";
import { to } from "../../utils";
export default class DPIManager {
  // -------------------------------------------------- Admission Form
  getAdmissionForm = async emrId => {
    const dpiService = new DPIService();
    const [error, response, network] = await to(
      dpiService.getAdmissionForm(emrId)
    );
    return [response, error, network];
  };
  putAdmissionForm = async (emrId, data) => {
    const dpiService = new DPIService();
    const [error, response, network] = await to(
      dpiService.putAdmissionForm(emrId, data)
    );
    return [response, error, network];
  };

  createProgressionNote = async ({ apiToken, data } = {}) => {
    const dpiService = new DPIService();
    const [error, response, network] = await to(
      dpiService.createProgressionNote({ apiToken: apiToken, data: data })
    );
    return [response, error, network];
  };

  putProgressNote = async ({ apiToken, data = {}, emrId } = {}) => {
    let params = {}
    let saveData = { ...data }
    const dpiService = new DPIService();
    const [error, response, network] = await to(
      dpiService.putProgressNote({ apiToken, data: saveData, emrId, params })
    );
    return [response, error, network];
  };

  putProgressNoteById = async ({ apiToken, data = {}, progressNoteId } = {}) => {
    let params = {}
    let saveData = { ...data }
    const dpiService = new DPIService();
    const [error, response, network] = await to(
      dpiService.putProgressNoteById({ apiToken, data: saveData, progressNoteId, params })
    );
    return [response, error, network];
  };
}
