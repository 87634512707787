import React, { useEffect, useState } from "react";
import moment from "moment";
import { Button, Icon, Input, Modal, Segment } from "semantic-ui-react";
import ReactTable from "react-table-6";
// ui common
import SnackMessage from "react-lib/apps/common/SnackMessage";
// ui ADM
import CardReAssessmentHistoryUX from "./CardReAssessmentHistoryUX";
import { useIntl } from "react-intl";

const CARD_RE_ASSESSMENT_HISTORY: string = "CardReAssessmentHistory";

type CardReAssessmentHistoryProps = {
  // seq
  runSequence: null;

  // function
  onEvent: any;
  setProp: any;

  // CommonInterface
  errorMessage?: any;
  successMessage?: any;

  // data
  masterOptions?: any[];

  title?: { code?: string; name?: string };
  type?: string; // for multiple formCode
  data?: any; // {startDate, endDate, data, ...}
};

// Const
const CODE_SHIFT_LIST = [
  "PAIN",
  "RISK_FALL",
  "SLEEP",
  "VENOUS",
  "SURGICAL",
  "URINARY"
] as const;

const CardReAssessmentHistory = (props: CardReAssessmentHistoryProps) => {
  const intl = useIntl();
  // Table
  const [sliceData, setSliceData] = useState<number>(0);
  const [tableData, setTableData] = useState<any>({ pageSize: 0, row: [], column: [] });

  // Modal Delete
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [itemDelete, setItemDelete] = useState<any>({});
  const [textReason, setTextReason] = useState<string>("");

  useEffect(() => {
    createTableHeader();
  }, [props.data, sliceData]);

  const handleChangeStartDate = (date: string) => {
    let key = "";
    if (props.title?.code === "PAIN") {
      key = "painDataHistory";
    } else if (props.title?.code === "RISK_FALL") {
      key = "riskFallDataHistory";
    } else if (props.title?.code === "SLEEP") {
      key = "sleepDataHistory";
    } else if (props.title?.code === "VENOUS") {
      key = "venousDataHistory";
    } else if (props.title?.code === "SURGICAL") {
      key = "surgicalDataHistory";
    } else if (props.title?.code === "URINARY") {
      key = "urinaryDataHistory";
    }

    props.setProp(`ReAssessmentSequence.${key}.startDate`, date);
  };

  const handleChangeEndDate = (date: string) => {
    let key = "";
    if (props.title?.code === "PAIN") {
      key = "painDataHistory";
    } else if (props.title?.code === "RISK_FALL") {
      key = "riskFallDataHistory";
    } else if (props.title?.code === "SLEEP") {
      key = "sleepDataHistory";
    } else if (props.title?.code === "VENOUS") {
      key = "venousDataHistory";
    } else if (props.title?.code === "SURGICAL") {
      key = "surgicalDataHistory";
    } else if (props.title?.code === "URINARY") {
      key = "urinaryDataHistory";
    }

    props.setProp(`ReAssessmentSequence.${key}.endDate`, date);
  };

  const handleBackward = () => {
    if (sliceData - 1 < 0) {
      setSliceData(0);
    } else {
      setSliceData(sliceData - 1);
    }
  };

  const handleForward = () => {
    if (sliceData + 1 >= props.data?.data?.length) {
      setSliceData(sliceData);
    } else {
      setSliceData(sliceData + 1);
    }
  };

  const handleChangeDataForm = (dateString: string) => {
    if (dateString) {
      let date = dateString.split("/");
      let year = date[2].split("[");
      return `${parseInt(year[0], 10) - 543}-${date[1]}-${date[0]}`;
    }
  };

  const createTableHeader = () => {
    // formatDate to use isBetween()
    let startDate = handleChangeDataForm(props.data?.startDate);
    let endDate = handleChangeDataForm(props.data?.endDate);

    let itemData =
      props.data?.data?.filter((item: any) =>
        moment(handleChangeDataForm(item?.saveData)).isBetween(startDate, endDate, undefined, "[]")
      ) || [];

    const items = itemData.slice(0 + sliceData, 7 + sliceData).map((item: any, _index: any) => {
      if (props.title?.code === "PAIN") {
        let assessment;
        let location;
        let characteristic;
        // Old Ver.
        props.masterOptions?.pain?.filter((data: any) => {
          if (data.value === item?.assessPainLevel) {
            assessment = data.text;
          }
        });

        props.masterOptions?.location?.filter((data: any) => {
          if (data.value === item?.painArea || data.value === item?.painLocation) {
            location = data.text;
          }
        });

        props.masterOptions?.characteristic?.filter((data: any) => {
          if (data.value === item?.painNature || data.value === item?.painCharacteristic) {
            characteristic = data.text;
          }
        });

        // console.log("Yeti Item in Table: ", item);
        return {
          // ...item,
          // ความเจ็บปวด: item?.painStatus,
          // มาตรฐานการประเมิน: assessment,
          // ระดับความเจ็บปวด: item?.painLevel,
          // บริเวณที่ปวด: location,
          // ลักษณะการปวด: characteristic,
          // การให้การพยาบาลผู้ป่วย: item?.sensesNursingCare,
          // ผู้บันทึก: item.editUserName,
          // type: props.title?.code,
          ...item,
          ความเจ็บปวด: item?.painStatus,
          "Pain Assessment": assessment || item?.painAssessmentTool,
          Score: item?.painLevel || item?.painScore,
          Location: location,
          Duration: (item?.painDurationMinute || item.painDurationSecond) ? `${item?.painDurationMinute || "0"} นาที ${item?.painDurationSecond || "0"} วินาที` : ``,
          Characteristic: characteristic || `${item?.painCharacteristic || ``} ${item.painCharacteristicNote || ``}`,
          Frequency: item?.painFrequency,
          "Informed Physician": `${item?.painInformedPhysician || ``} ${item?.painInformedPhysicianNote || ``}`,
          "Non-Medical Intervention": `${item?.painNonMidicalIntervention || ""}`,
          "Medication Give": `${item?.painMedicationGive || ``} ${item?.painMedicationGiveNote || ``} ${item?.painMedicationGiveTime ? `[${item?.painMedicationGiveTime}]` : ``}`,
          "Sedation score (before Opiods given)": item?.painSedatScoreBefore,
          "Sedation score (after Opiods given)": item?.painSedatScoreAfter,
          "Pain score after intervention": `${item?.painScoreAfterIntervention || ``} ${item?.painScoreAfterInterventionScore ? `= ${item?.painScoreAfterInterventionScore}` : ``} ${item?.painScoreAfterInterventionTime ? `[${item?.painScoreAfterInterventionTime}]` : ``}`,
          "Side effects (SE)": `${item?.painSideEffect || ``} ${item?.painSideEffectNote || ``}`,
          การให้การพยาบาลผู้ป่วย: item?.sensesNursingCare,
          ผู้บันทึก: item?.editUserName,
          type: props.title?.code,
        };
      } else if (props.title?.code === "RISK_FALL") {
        return {
          // ...item,
          // ความเสี่ยง: item?.riskFall,
          // ระดับความเสี่ยง: item?.riskFallRemark,
          // การให้การพยาบาลผู้ป่วย: item?.nursingCare,
          // ผู้บันทึก: item.editUserName,
          // type: props.title?.code,
          ...item,
          ความเสี่ยง: item?.riskFall,
          ระดับความเสี่ยง: item?.riskFallRemark,
          การให้การพยาบาลผู้ป่วย: item?.nursingCare,
          Score: item?.riskFallScore,
          Age: `${item.riskFallAge || ``} ${item.riskFallAgeScore ? `(${item.riskFallAgeScore})` : ``}`,
          "History of fall": `${item.riskFallHistory || ``} ${item.riskFallHistoryScore ? `(${item.riskFallHistoryScore})` : ``}`,
          "Mental status Oriented to Time, Place, Person": `${item.riskFallMental || ``} ${item.riskFallMentalScore ? `(${item.riskFallMentalScore})` : ``}`,
          "Sensory/Communication deficit": `${item.riskFallSensory?.length > 0 ? `[${item.riskFallSensory}]` : ``} ${item.riskFallSensoryDeficit || ``} ${item.riskFallSensoryScore ? `(${item.riskFallSensoryScore})` : ``}`,
          Elimination: `${item.riskFallElimination?.length > 0 ? `[${item.riskFallElimination}]` : ``} ${item.riskFallEliminationProblem || ``} ${item.riskFallEliminationScore ? `(${item.riskFallEliminationScore})` : ``}`,
          Medication: `${item.riskFallMedication || ``} ${item.riskFallMedicationScore ? `(${item.riskFallMedicationScore})` : ``}`,
          "Mobily (Including desire autonomy)": `${item.riskFallMobily || ``} ${item.riskFallMobilyScore ? `(${item.riskFallMobilyScore})` : ``}`,
          "Total score": `${item.riskFallTotalScore ? `${item.riskFallTotalScore} : ` : ``}${item.riskFallTotalPrecaution || ``}`,
          ผู้บันทึก: item.editUserName,
          type: props.title?.code,
        };
      } else if (props.title?.code === "SLEEP") {
        return {
          ...item,
          ชั่วโมงการนอน: item?.sleepingPerDay,
          ความเพียงพอ: item?.sleepingEnough,
          ปัญหาการนอน:
            item?.sleepingProblem === "มี"
              ? `${item?.sleepingProblem} ${item?.sleepingProblemRemark}`
              : item?.sleepingProblem,
          ใช้ยานอนหลับ:
            item?.sleepPill === "ใช้"
              ? `${item?.sleepPill} ${item?.sleepPillRemark}`
              : item?.sleepPill,
          ผู้บันทึก: item?.editUserName,
          type: props.title?.code,
        };
      } else if (props.title?.code === "VENOUS") {
        return {
          ...item,
          "1. Hand Washing ล้างมือ 7 ขั้นตอน ตามหลักการ 5 Moment": item?.venousQuestOne,
          "2. ตรวจสอบบริเวณจุกปิดของ NSS Lock ให้ปิดสนิททุกครั้ง": item?.venousQuestTwo,
          "3. เมื่อเปิดจุกข้อต่อ ให้เช็ดทำความสะอาดด้วย 70 % alcohol ทุกครั้ง": item?.venousQuestThree,
          "4. ตรวจสอบบริเวณตำแหน่งที่แทงเข็มปละประเมินระดับความรุนแรงของหลอดเลือดดำอักเสบ (Phlebitis)": item?.venousQuestFour,
          "5. เปลี่ยนตำแหน่งที่แทงเข็มเมื่อเข็มออกนอกหลอดเลือดหรือเมื่อมีการอักเสบติดเชื้อในตำแหน่งที่แทงเข็มหรือเกิด Phlebitis": item?.venousQuestFive,
          "6. ติดสติกเกอร์วันเปลี่ยนเข็มทุกครั้งเมื่อเปิดเส้น": item?.venousQuestSix,
          "7. เปลี่ยนเข็มใหม่ทุกครั้งเมื่อครบ 96 ชม. (นับจากเวลาที่แทงเข็ม)": item?.venousQuestSeven,
          "8. เมื่อบริเวณที่แทงเข็ม หรือพลาสเตอร์มีการปนเปื้อนเลือดหรือคราบสกปรกให้ทำความสะอาด และเปลี่ยน Dressing ใหม่": item?.venousQuestEight,
          ผู้บันทึก: item?.editUserName,
          type: props.title?.code,
        };
      } else if (props.title?.code === "SURGICAL") {
        return {
          ...item,
          "1. รับผู้ป่วยไว้ในโรงพยาบาลก่อนการผ่าตัดให้ระยะสั้นที่สุด": item?.surgicalBeforeQuestOne,
          "2. รักษาการติดเชื้อตำแหน่งอื่นในร่างกายให้หายก่อนการผ่าตัด": item?.surgicalBeforeQuestTwo,
          "3. อาบน้ำและสระผมด้วยสบู่หรือน้ำยาทำลายเชื้อในคืนก่อนผ่าตัดหรือเช้าของวันผ่าตัด": item?.surgicalBeforeQuestThree,
          "4. ไม่โกนขนบริเวณผิวหนังที่จะผ่าตัด": item?.surgicalBeforeQuestFour,
          "1. ไม่เปิดแผลผ่าตัดก่อน 24-48 ชั่วโมง ยกเว้นในกรณีแผลมีเลือดหรือ Discharge ซึมมาก": item?.surgicalAfterQuestOne,
          "2. ล้างมือ Hand Washing 7 ขั้นตอน ตามหลักการ 5 Moment ก่อนและหลังทำแผล": item?.surgicalAfterQuestTwo,
          "3. ทำแผลโดยยึดหลัก Aseptic Technique": item?.surgicalAfterQuestThree,
          ผู้บันทึก: item?.editUserName,
          type: props.title?.code,
          formType: item?.type,
        }
      } else if (props.title?.code === "URINARY") {
        return {
          ...item,
          "1. Hand Washing ล้างมือ 7 ขั้นตอน ตามหลักการ 5 Moment": item?.urinaryQuestOne,
          "2. Avoid unnecessary urinary catheters สวนปัสสาวะเมื่อมีความจำเป็นและมีข้อบ่งชี้เท่านั้น": item?.urinaryQuestTwo,
          "3. Review urinary catheter necessity daily พยาบาลสอบถามความจำเป็นการคาสายสวนปัสสาวะ กับแพทย์ทุกเช้า": item?.urinaryQuestThree,
          "4. Maintain urinary catheter based on recommended guideline": item?.urinaryQuestFour,
          "4.1 Closed drainage system": item?.urinaryQuestFourSubOne,
          "4.2 Fixed catheter": item?.urinaryQuestFourSubTwo,
          "4.3 Urine bag hang below bladder": item?.urinaryQuestFourSubThree,
          "4.4 Aseptic technique": item?.urinaryQuestFourSubFour,
          "4.5 Perineum care": item?.urinaryQuestFourSubFive,
          ผู้บันทึก: item?.editUserName,
          type: props.title?.code,
        }
      }
    });

    const dictionary = Object.assign(
      {},
      ...items.map((item: any, index: any) => ({ [index]: item }))
    );

    let headerColumn = [
      {
        Header: " ",
        accessor: "name",
        fixed: "left",
        resizable: false,
        width: 200,
      },
      ...items.map((item: any, index: any) => ({
        accessor: `${index}`,
        // Header: "",
        Header: () => {
          if (CODE_SHIFT_LIST.includes(item.type)) {
            return (
              <div style={{ margin: "5px" }}>
                <div style={{ display: "flex", justifyContent: "left" }}>
                  <label>{item.saveData}</label>
                </div>
                <div style={{ display: "flex", justifyContent: "left" }}>
                  <label>{item.saveTime}</label>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <label>{`Shift ${item.saveShift || "-"}`}</label>
                  <div>
                    <Icon
                      name="trash alternate"
                      inverted
                      color="red"
                      link={true}
                      onClick={() => {
                        setItemDelete({ ...item, index: index });
                        setOpenDelete(true);
                      }}
                    />
                  </div>
                </div>
              </div>
            )
          }

          return (
            <div style={{ margin: "5px" }}>
              <div style={{ display: "flex", justifyContent: "left" }}>
                <label>{item.saveData}</label>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label>{item.saveTime}</label>
                <div>
                  <Icon
                    name="trash alternate"
                    inverted
                    color="red"
                    link={true}
                    onClick={() => {
                      setItemDelete({ ...item, index: index });
                      setOpenDelete(true);
                    }}
                  />
                </div>
              </div>
            </div>
          );
        },
      })),
    ];

    if (headerColumn.length < 8) {
      let i = headerColumn.length;
      for (i; i < 8; i++) {
        headerColumn.push({
          accessor: " ",
          Header: () => {
            return <div></div>;
          },
        });
      }
    }

    let data: any[] = [];
    let pageSize: number = 0;
    if (props.title?.code === "PAIN") {
      // For Old Pain Assessment Form
      let painData = itemData;

      data = [
        { header: "ความเจ็บปวด" },
        { header: "Pain Assessment" },
        { header: "Score" },
        { header: "Location" },
        { header: "Duration" },
        { header: "Characteristic" },
        { header: "Frequency" },
        { header: "Informed Physician" },
        { header: "Non-Medical Intervention" },
        { header: "Medication Give" },
        { header: "Sedation score (before Opiods given)" },
        { header: "Sedation score (after Opiods given)" },
        { header: "Pain score after intervention" },
        { header: "Side effects (SE)" },
        { header: "ผู้บันทึก" }
      ];

      const oldPainAssessmentFormCheck = () => {
        if (painData.some((item: any) => item.sensesNursingCare !== undefined)) { //*
          data.splice(14, 0, { header: "การให้การพยาบาลผู้ป่วย" });
          return {
            getPageSize: 16,
            getHeaderData: data,
          }
        }

        return {
          getPageSize: 15,
          getHeaderData: data,
        }
      }

      const { getPageSize, getHeaderData } = oldPainAssessmentFormCheck();
      pageSize = getPageSize;
      data = getHeaderData;
      // Old Pain Assessment
      // data = [
      //   { header: "ความเจ็บปวด" },
      //   { header: "มาตรฐานการประเมิน" },
      //   { header: "ระดับความเจ็บปวด" },
      //   { header: "บริเวณที่ปวด" },
      //   { header: "ลักษณะการปวด" },
      //   { header: "การให้การพยาบาลผู้ป่วย" },
      //   { header: "ผู้บันทึก" },
      // ];
    } else if (props.title?.code === "RISK_FALL") {
      // For Old Risk Fall Assessment
      let riskFallData = itemData;

      data = [
        { header: "Score" },
        { header: "Age" },
        { header: "History of fall" },
        { header: "Mental status Oriented to Time, Place, Person" },
        { header: "Sensory/Communication deficit" },
        { header: "Elimination" },
        { header: "Medication" },
        { header: "Mobily (Including desire autonomy)" },
        { header: "Total score" },
        { header: "ผู้บันทึก" },
      ];

      const oldRiskFallAssessmentFormCheck = () => {
        if (riskFallData.some((item: any) => 
            item.nursingCare !== undefined || 
            item.riskFall !== undefined ||
            item.riskFallRemark !== undefined
          )) {
          data.splice(0, 0, { header: "ความเสี่ยง" });
          data.splice(1, 0, { header: "ระดับความเสี่ยง" });
          data.splice(11, 0, { header: "การให้การพยาบาลผู้ป่วย" });
          return {
            getPageSize: 13,
            getHeaderData: data,
          }
        }

        return {
          getPageSize: 10,
          getHeaderData: data,
        }
      }

      const { getPageSize, getHeaderData } = oldRiskFallAssessmentFormCheck();
      pageSize = getPageSize;
      data = getHeaderData;

      // pageSize = 4;
      // Old Risk Fall Assessment
      // data = [
      //   { header: "ความเสี่ยง" },
      //   { header: "ระดับความเสี่ยง" },
      //   { header: "การให้การพยาบาลผู้ป่วย" },
      //   { header: "ผู้บันทึก" },
      // ];
    } else if (props.title?.code === "SLEEP") {
      pageSize = 5;
      data = [
        { header: "ชั่วโมงการนอน" },
        { header: "ความเพียงพอ" },
        { header: "ปัญหาการนอน" },
        { header: "ใช้ยานอนหลับ" },
        { header: "ผู้บันทึก" },
      ];
    } else if (props.title?.code === "VENOUS") {
      pageSize = 9;
      data = [
        { header: "1. Hand Washing ล้างมือ 7 ขั้นตอน ตามหลักการ 5 Moment" },
        { header: "2. ตรวจสอบบริเวณจุกปิดของ NSS Lock ให้ปิดสนิททุกครั้ง" },
        { header: "3. เมื่อเปิดจุกข้อต่อ ให้เช็ดทำความสะอาดด้วย 70 % alcohol ทุกครั้ง" },
        { header: "4. ตรวจสอบบริเวณตำแหน่งที่แทงเข็มปละประเมินระดับความรุนแรงของหลอดเลือดดำอักเสบ (Phlebitis)" },
        { header: "5. เปลี่ยนตำแหน่งที่แทงเข็มเมื่อเข็มออกนอกหลอดเลือดหรือเมื่อมีการอักเสบติดเชื้อในตำแหน่งที่แทงเข็มหรือเกิด Phlebitis" },
        { header: "6. ติดสติกเกอร์วันเปลี่ยนเข็มทุกครั้งเมื่อเปิดเส้น" },
        { header: "7. เปลี่ยนเข็มใหม่ทุกครั้งเมื่อครบ 96 ชม. (นับจากเวลาที่แทงเข็ม)" },
        { header: "8. เมื่อบริเวณที่แทงเข็ม หรือพลาสเตอร์มีการปนเปื้อนเลือดหรือคราบสกปรกให้ทำความสะอาด และเปลี่ยน Dressing ใหม่" },
        { header: "ผู้บันทึก" },
      ];
    } else if (props.title?.code === "SURGICAL") {
      //TODO Header ตามรูปใน issue #70400
      pageSize = 10;
      data = [
        { header: <div style={{ fontWeight: "bold" }}>การเตรียมผู้ป่วยก่อนผ่าตัด</div> },
        { header: "1. รับผู้ป่วยไว้ในโรงพยาบาลก่อนการผ่าตัดให้ระยะสั้นที่สุด" },
        { header: "2. รักษาการติดเชื้อตำแหน่งอื่นในร่างกายให้หายก่อนการผ่าตัด" },
        { header: "3. อาบน้ำและสระผมด้วยสบู่หรือน้ำยาทำลายเชื้อในคืนก่อนผ่าตัดหรือเช้าของวันผ่าตัด" },
        { header: "4. ไม่โกนขนบริเวณผิวหนังที่จะผ่าตัด" },
        { header: <div style={{ fontWeight: "bold" }}>การดูแลผู้ป่วยหลังผ่าตัด (วันที่แพทย์เปิดทำแผลครั้งแรก)</div> },
        { header: "1. ไม่เปิดแผลผ่าตัดก่อน 24-48 ชั่วโมง ยกเว้นในกรณีแผลมีเลือดหรือ Discharge ซึมมาก" },
        { header: "2. ล้างมือ Hand Washing 7 ขั้นตอน ตามหลักการ 5 Moment ก่อนและหลังทำแผล" },
        { header: "3. ทำแผลโดยยึดหลัก Aseptic Technique" },
        { header: "ผู้บันทึก" },
      ];
    } else if (props.title?.code === "URINARY") {
      pageSize = 10;
      data = [
        { header: "1. Hand Washing ล้างมือ 7 ขั้นตอน ตามหลักการ 5 Moment" },
        { header: "2. Avoid unnecessary urinary catheters สวนปัสสาวะเมื่อมีความจำเป็นและมีข้อบ่งชี้เท่านั้น" },
        { header: "3. Review urinary catheter necessity daily พยาบาลสอบถามความจำเป็นการคาสายสวนปัสสาวะ กับแพทย์ทุกเช้า" },
        { header: "4. Maintain urinary catheter based on recommended guideline" },
        { header: "4.1 Closed drainage system" },
        { header: "4.2 Fixed catheter" },
        { header: "4.3 Urine bag hang below bladder" },
        { header: "4.4 Aseptic technique" },
        { header: "4.5 Perineum care" },
        { header: "ผู้บันทึก" },
      ]
    }

    const rowData: any[] = data.map((item) => ({
      ...item,
      ...Object.assign(
        {},
        ...headerColumn.map((column: any, index: any) => ({
          [index]: dictionary[index]?.[item.header],
        }))
      ),
      name: item.header,
    }));

    setTableData({ row: rowData, column: headerColumn, pageSize: pageSize });
  };

  const handleDelete = () => {

    const deleteItem = () => {
      if (props.type) {
        const specFormType = props.type[itemDelete?.index]?.type;
        return { result: [], specFormType };
      } else {
        const result = [...props.data?.data];
        result.splice(itemDelete?.index, 1);
        return { result };
      }
    };

    const { result, specFormType } = deleteItem();

    props.runSequence({
      sequence: "ReAssessment",
      action: "deleteDataHistory",
      card: CARD_RE_ASSESSMENT_HISTORY,
      formCode: props.title?.code,
      ...(specFormType && { specFormType }),
      data: { item: result, remark: textReason },
    });

    handleCloseModalDelete();
  };

  const handleCloseModalDelete = () => {
    setItemDelete([]);
    setTextReason("");
    setOpenDelete(false);
  };

  return (
    <>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_RE_ASSESSMENT_HISTORY}`, null);
          props.setProp(`successMessage.${CARD_RE_ASSESSMENT_HISTORY}`, null);
        }}
        error={props.errorMessage?.[CARD_RE_ASSESSMENT_HISTORY]}
        success={props.successMessage?.[CARD_RE_ASSESSMENT_HISTORY]}
        languageUX={props.languageUX}
      />

      <CardReAssessmentHistoryUX
        title={props.title?.name}
        startDate={props.data?.startDate}
        endDate={props.data?.endDate}
        onChangeStartDate={handleChangeStartDate}
        onChangeEndDate={handleChangeEndDate}
        sliceA={handleBackward}
        sliceB={handleForward}
        tableData={
          <ReactTable
            showPagination={false}
            data={tableData?.row}
            columns={tableData?.column}
            pageSize={tableData?.pageSize}
            sortable={false}
          />
        }
        onSearch={createTableHeader}
        languageUX={props.languageUX}
      />

      <Modal size="tiny" open={openDelete} onClose={handleCloseModalDelete}>
        <Segment inverted className="red" style={{ margin: "0px" }}>
          ข้อความแจ้งเตือน
        </Segment>
        <div style={{ margin: "10px 20px" }}>
          <div style={{ fontWeight: "bold" }}>{intl.formatMessage({ id: "กรุณาระบุเหตุผล เพื่อยืนยันการลบข้อมูลการบันทึก" })}</div>
          <div style={{ margin: "10px 0px" }}>
            <Input
              fluid={true}
              value={textReason}
              onChange={(e, { value }) => setTextReason(value)}
            ></Input>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button color="green" onClick={handleDelete} style={{ margin: "0px 20px" }}>
              ยืนยัน
            </Button>
            <Button color="red" onClick={handleCloseModalDelete} style={{ margin: "0px 20px" }}>
              ยกเลิก
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

CardReAssessmentHistory.displayName = "CardReAssessmentHistory";

export default React.memo(CardReAssessmentHistory);
