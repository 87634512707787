var _ = require("lodash");

/**
 * VC Websocket library.
 * Responsible for connect to VC websocket server and listen to incoming messages.
 * Connect to server with method connect(div, reconnectTime); and disconnect with disconnect()
 * Listen to various event with callback method
 * - onConnectionOpen
 * - onConnectionClose
 * - onMessage
 * Check capability of the browser on websocket with isSupported flag
 * Example :
 * QMLComponent {
 *   Component.onCompleted: {
 *     vcMessenger.onConnectionOpen( function() {
 *       //..... blah blah
 *     });
 *     vcMessenger.onConnectionClose( function() {
 *       //..... blah blah
 *     });
 *     vcMessenger.onMessage( function(msg) {
 *       //..... print out message with
 *       //..... console.log(msg);
 *     });
 *
 *     vcMessenger.connect(_div);
 *     //..... do something ......
 *     vcMessenger.disconnect();
 *
 *     vcMessenger.send_local_message(msg);
 *   }
 * }
 */


/* Recommend to use 
 Messenger.push_to_location  -> when user use the same division
 Messenger.push_to_screen -> when know the screen and want to use in specificed screen
 
 Note: this method use in Django with import Messenger from his.core
*/
const WS_RECONNECTION_TIME = 5000; // Reconnection time in millisecond
const LOCAL_MSG_BUS = "VC_MSG_BUS";
const WS_PARAM_ENCOUNTERS = "encounters";
const WS_PARAM_SCREENS = "screens";
const MAX_RECONNECT = 3;

function checkBroadcastChannelSupported() {
  return typeof BroadcastChannel !== "undefined";
}

var WSMessenger = function () {
  var self = this;

  // Check websocket compatibility
  if ("WebSocket" in window) {
    self.isSupported = true;
  } else {
    self.isSupported = false;
  }

  self.isBroadcastChannelSupported = checkBroadcastChannelSupported();
  console.log('self.isBroadcastChannelSupported react-lib/compat', self.isBroadcastChannelSupported)

  // Add listener to local message connection
  // Use BroadcastChannel Technology to accomplish this
  // more info at
  // https://developer.mozilla.org/en-US/docs/Web/API/Broadcast_Channel_API
  // https://developer.mozilla.org/en-US/docs/Web/API/Broadcast_Channel_API
  if (self.isBroadcastChannelSupported) {
    self.localMessenger = new BroadcastChannel(LOCAL_MSG_BUS);
    self.localMessenger.onmessage = function (ev) {
      // Binding to fire listener when local message received.
      self.fireOnMessage(ev.data);
    };
  }

  self.timeoutReconnect = null;
  self.websocket = null;
  self.currentDiv = null;
  self.forceClose = false;
  self.isAutoReconnect = false;
  self.onConnectionOpenHandlers = []; // observers
  self.onConnectionCloseHandlers = []; // observers
  self.onConnectionAutoReconnectHandlers = []; // observers
  self.onMessageHandlers = []; // observers
  self.reconnectCount = 0;
};

/**
 * Add & Remove listeners
 **/
WSMessenger.prototype.onConnectionOpen = function (fn) {
  this.onConnectionOpenHandlers.push(fn);
};

WSMessenger.prototype.removeOnConnectionOpen = function (fn) {
  this.onConnectionOpenHandlers = this.onConnectionOpenHandlers.filter((item) => item !== fn)
};

WSMessenger.prototype.onConnectionClose = function (fn) {
  this.onConnectionCloseHandlers.push(fn);
};

WSMessenger.prototype.removeOnConnectionClose = function (fn) {
  this.onConnectionCloseHandlers = this.onConnectionCloseHandlers.filter((item) => item !== fn)
};

WSMessenger.prototype.onConnectionAutoReconnect = function (fn) {
  this.onConnectionAutoReconnectHandlers.push(fn);
};

WSMessenger.prototype.removeOnConnectionAutoReconnect = function (fn) {
  this.onConnectionAutoReconnectHandlers = this.onConnectionAutoReconnectHandlers.filter((item) => item !== fn)
};

WSMessenger.prototype.onMessage = function (fn) {
  this.onMessageHandlers.push(fn);
};

WSMessenger.prototype.removeOnMessage = function (fn) {
  this.onMessageHandlers = this.onMessageHandlers.filter((item) => item !== fn)
};

/**
 * Fire events to various handlers
 **/
WSMessenger.prototype.fireOnConnectionOpen = function () {
  var self = this;
  self.onConnectionOpenHandlers.forEach(function (item) {
    try {
      item.call(self);
    } catch (err) {
      console.error(
        "Error notifying websocket on connection open : " + err.message
      );
    }
  });
};

WSMessenger.prototype.fireOnConnectionClose = function () {
  var self = this;
  // console.log(" fireOnConnectionClose", self.onConnectionCloseHandlers)
  self.onConnectionCloseHandlers.forEach(function (item) {
    try {
      item.call(self);
    } catch (err) {
      console.error(
        "Error notifying websocket on connection close : " + err.message
      );
    }
  });
};

WSMessenger.prototype.fireOnConnnectionAutoReconnect = function () {
  var self = this;
  self.onConnectionAutoReconnectHandlers.forEach(function (item) {
    try {
      item.call(self);
    } catch (err) {
      console.error(
        "Error notifying websocket on connection reconnection : " + err.message
      );
    }
  });
};

WSMessenger.prototype.fireOnMessage = function (msg) {
  var self = this;
  let jsonObject = JSON.parse(msg);
  self.onMessageHandlers.forEach(function (item) {
    try {
      item.call(self, jsonObject);
    } catch (err) {
      console.error(
        "Error notifying websocket on message : " +
        err.message +
        " with message : " +
        msg
      );
    }
  });
};

/**
 * Connect to websocket. The IP address of server will getting from function ${window.location.host}
 * div : Division of this PC ( location of this PC )
 * reconnectTime : Reconnection Time in ms
 **/
WSMessenger.prototype.connect = function (div, params = {}, host) {
  // console.log("connect params", params)
  var self = this; // Hold this object for using in callback methods
  var loc = window.location,
    ws_protocol,
    wsUrl;

  // var save_host = ''
  // var timeOutReconnect = null

  if (loc.protocol === "https:") {
    ws_protocol = "wss://"; // Need to use Websocket secure in HTTPS Environment
  } else {
    ws_protocol = "ws://";
  }
  if (
    window.location.port !== "" &&
    window.location.port !== "80" &&
    window.location.port !== "443"
  ) {
    // Developer mode
    wsUrl = ws_protocol + window.location.hostname + ":3001/ws/" + div + "/";
  } else {
    wsUrl = ws_protocol + window.location.host + "/ws/" + div + "/";
  }

  if (host) {
    wsUrl = ws_protocol + host + "/ws/" + div + "/";
    this.save_host = host
  }

  if (this.save_host) {
    wsUrl = ws_protocol + this.save_host + "/ws/" + div + "/";
  }

  if (
    window.location.port !== "" &&
    window.location.port !== "80" &&
    window.location.port !== "443"
  ) {
    // Developer mode 
    wsUrl = ws_protocol + window.location.hostname + ":3001/ws/" + div + "/";
  } else {
    wsUrl = ws_protocol + window.location.host + "/ws/" + div + "/";
  }

  if (host) {
    wsUrl = ws_protocol + host + "/ws/" + div + "/";
    this.save_host = host
  }

  if (this.save_host) {
    wsUrl = ws_protocol + this.save_host + "/ws/" + div + "/";
  }
  // console.log("params" ,params)

  var qsParams = [];
  // Add connection params
  _.forOwn(params, (value, key) => {
    // Except empty value
    if (!String(value)) {
      return;
    }
    qsParams.push(key + "=" + value);
  });

  // console.log("qsParams" ,qsParams)

  if (qsParams.length) {
    wsUrl += "?" + qsParams.join("&");
  }
  console.log("vc-websocket Trying to connect to URL : " + wsUrl);

  if (!self.isSupported) {
    // Websocket not supported
    self.fireOnConnectionClose();
    return;
  }

  // console.log('wsUrl: ', wsUrl);

  // if (self && self.websocket && self.websocket.url) {
  //   console.log("self.websocket.url", self.websocket.url)
  // }

  // console.log('self.websocket: ', self.websocket);

  if (self.websocket != null) {
    if (self.websocket && self.websocket.url && wsUrl !== self.websocket.url) {
      // console.log("wsURL !=  self.websocket.url")
      self.websocket.onclose = null
      // console.log("self.websocket.close() should not see onClose")
      self.websocket.close();
      self.websocket = null;
      // console.log("Check timeOutReconnect", self.timeOutReconnect)
      if (self.timeOutReconnect) {
        // console.log("Check Clear Timeout ", self.timeOutReconnect)
        clearTimeout(self.timeOutReconnect)
      }
    } else if (
      (self.websocket.readyState === self.websocket.OPEN ||
        self.websocket.readyState === self.websocket.CONNECTING) && (self.websocket && self.websocket.url && wsUrl === self.websocket.url)
    ) {
      // same URL 
      // Already connected to server or connecting.
      // Do nothing.
      // console.log("Do nothing !! ")
      // console.log("Do nothing !! self.websocket:", self.websocket)
      // console.log("Do nothing !! self.websocket.readyState:", self.websocket.readyState)
      return
    }
  }

  // console.log("new Websocket wsUrl: ", wsUrl)

  // Open websocket
  try {
    self.websocket = new WebSocket(wsUrl);
  } catch (error) {
    console.warn("error", error)
    return
  }

  self.currentDiv = div;
  self.forceClose = false;

  // Bind the events to this client
  self.websocket.onopen = function () {
    if (self.isAutoReconnect) {
      // Reconnect success
      self.fireOnConnnectionAutoReconnect();
      self.isAutoReconnect = false;
    }
    self.fireOnConnectionOpen();
  };

  self.websocket.onclose = function () {
    // console.log("onClose Called !! self.forceClose: ", self.forceClose)
    // console.log("vc-websocket onClose reconnectCount", self.reconnectCount)
    // console.log("vc-websocket onClose wsUrl", wsUrl)
    self.fireOnConnectionClose(this);

    if (!self.forceClose) {
      // console.log("vc-websocket setTimeout with self.forceClose", self.forceClose)

      self.reconnectCount = self.reconnectCount + 1
      self.timeOutReconnect = setTimeout(function () {

        if (process.env.NODE_ENV === "development" && self.reconnectCount >= MAX_RECONNECT) {
          // console.log("vc-websocket Stop params: ", params)
          return;
        }

        // console.log("vc-websocket Timeout params", params)
        self.isAutoReconnect = true;
        // console.log('vc-websocket connect: ');
        try {
          self.connect(div, params);
        } catch (error) {
          console.warn("error", error)
        }

      }, WS_RECONNECTION_TIME);
    } else {
      // console.log(" set forceClose = false self.forceClose", self.forceClose)
      // Intentionally close this websocket. Do not reconnect
      self.forceClose = false;
    }
  };

  self.websocket.onmessage = function (msg) {
    self.fireOnMessage(msg.data);
  };
};

/**
 * Close the websocket connection
 * Don't need to close the localconnection
 **/
WSMessenger.prototype.disconnect = function () {
  console.log("vc-websocket disconnect !! ")
  if (this.websocket != null) {
    // console.log(" disconnect !! close called ")
    // console.log(" disconnect !! this.websocket.url: ", this.websocket.url)
    // console.log(" disconnect !! this.websocket.readyState", this.websocket.readyState)
    this.forceClose = true;
    this.websocket.close();
    this.websocket = null;

    if (this.timeOutReconnect) {
      console.log("Check Clear Timeout ", this.timeOutReconnect)
      clearTimeout(this.timeOutReconnect)
    }

  }
};

/**
 * Disconnect and re-connect the websocket without deleting the resources (handlers)
 **/
WSMessenger.prototype.reconnect = function (params = {}) {
  // console.log("vc-websocket reconnect !!")
  var div = this.currentDiv;
  this.disconnect();
  this.connect(div, params);
};

/**
 * Disconnect and clear all resources ( all handlers included ) of this object.
 **/
WSMessenger.prototype.dispose = function () {
  var self = this;
  self.disconnect();
  self.onConnectionOpenHandlers = [];
  self.onConnectionCloseHandlers = [];
  self.onConnectionAutoReconnectHandlers = [];
  self.onMessageHandlers = [];
};

/**
 * Function to send local message.
 * Use for broadcast message between browser window in same machine
 * e.g. two screen in doctor examination room.
 **/
WSMessenger.prototype.send_local_message = function (message) {
  if (this.isBroadcastChannelSupported) {
    if (this.localMessenger != null) {
      this.localMessenger.postMessage(message);
    }
  } else {
    this.fireOnMessage(message);
  }
};

/**
 * Method to check whether this websocket is connected or not
 **/
WSMessenger.prototype.isConnected = function () {
  let isConnected = this.websocket != null && (!this.isBroadcastChannelSupported || this.localMessenger != null) && this.websocket.readyState === this.websocket.OPEN
  // console.log('vc-websocket isConnected: ', isConnected);
  return isConnected
};

WSMessenger.prototype.isConnecting = function () {
  let isConnecting = this.websocket != null &&
    this.websocket.readyState === this.websocket.CONNECTING
  // console.log('vc-websocket isConnecting: ', isConnecting);
  return isConnecting
};

// Safari does not have BroadcastChannel used by vcMessenger

var vcMessenger = new WSMessenger();
module.exports = {
  vcMessenger: vcMessenger,
  WS_PARAM_ENCOUNTERS: WS_PARAM_ENCOUNTERS,
  WSMessenger,
};