import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'

const ModChairDetailUX = (props: any) => {
    return(
      <div>
        <Table
          data={props.data}
          headers="ชื่อเก้าอี้,หน่วยงาน/คลินิก,zone,สถานะเก้าอี้,แพทย์/นิสิตผู้จอง"
          keys="chair_name,division_name,zone_name,chair_status,doctor_provider_name"
          minRows="7"
          showPagination={false}
          style={{height: "300px"}}>
        </Table>
      </div>
    )
}

export default ModChairDetailUX

export const screenPropsDefault = {}

/* Date Time : Tue Aug 16 2022 07:48:01 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 1,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.data"
        },
        "headers": {
          "type": "value",
          "value": "ชื่อเก้าอี้,หน่วยงาน/คลินิก,zone,สถานะเก้าอี้,แพทย์/นิสิตผู้จอง"
        },
        "keys": {
          "type": "value",
          "value": "chair_name,division_name,zone_name,chair_status,doctor_provider_name"
        },
        "minRows": {
          "type": "value",
          "value": "7"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"300px\"}"
        }
      },
      "seq": 1,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "ModChairDetailUX",
  "project": "cudent2",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
