import WasmController from "react-lib/frameworks/WasmController";

import moment from "moment";

// APIs
import PlanTransferList from "issara-sdk/apis/PlanTransferList_apps_ADM";
import PlanTransferDetailByEmr from "issara-sdk/apis/PlanTransferDetailByEmr_apps_ADM";
import PlanTransferDetail from "issara-sdk/apis/PlanTransferDetail_apps_ADM";

// Interface
import { State as MainState } from "../../../../../HIS/MainHISInterface";
import { SetErrorMessage, SetProperty } from "../../common/CommonInterface";

export type State = {
  PlanTransferSequence?: {
    sequenceIndex?: string | null;
    id?: any;
    emr?: any;
    date?: any;
    time?: any;
    urgency?: any;
    wardType?: any;
    roomType?: any;
    division?: any;
    med_reconcile?: any;
    status_label?: any;
    openRedirect?: boolean;
  } | null;
};

type Picked = Pick<
  MainState,
  "selectedEmr" | "buttonLoadCheck" | "errorMessage" | "medReconcileCheck"
>;

export const StateInitial: State = {
  PlanTransferSequence: null,
};

export type SetProp = SetProperty<State & Picked>;

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division?: number;
  device?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State & Picked, Event, Data>,
  params?: any
) => any;

export const Start: Handler = async (controller, params) => {
  let state = controller.getState();
  console.log(" PLAN TRANSFER START ");

  let defaultTime = moment().format("HH:");

  controller.handleEvent({
    message: "GetMasterData" as any,
    params: {
      masters: [
        ["transferUrgency", {}],
        ["roomType", {}],
        ["wardType", {}],
      ],
    },
  });

  const [detail] = await PlanTransferDetailByEmr.retrieve({
    pk: state.selectedEmr?.id,
    apiToken: controller.apiToken,
    extra: { division: controller.data.division },
  });

  state = controller.getState();

  const data = {
    id: Number(detail?.id),
    emr: detail?.emr,
    date: detail?.date || null,
    time: detail?.time
      ? detail?.time?.split(":")?.slice(0, -1).join(":")
      : null,
    urgency: detail?.urgency || null,
    wardType: Number(detail?.ward_type) || null,
    roomType: Number(detail?.room_type) || null,
    division: Number(detail?.division) || null,
    med_reconcile: detail?.med_reconcile || null,
    status_label: detail?.status_label || null,
  };

  controller.setState({
    PlanTransferSequence: {
      ...state.PlanTransferSequence,
      sequenceIndex: "EDIT",
      ...(detail ? data : { time: defaultTime + "00" }),
    },
  });
};

export const Edit: Handler = async (controller, params) => {
  if (params.action === "save") {
    HandleSave(controller, params);
  } else if (params?.action === "delete") {
    HandleDelete(controller, params);
  }
};

const HandleSave: Handler = async (controller, params) => {
  let state = controller.getState();

  const btnKey = `${params.card}_${params.action}`;

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "LOADING" },
  });

  const seq = state.PlanTransferSequence;

  const data = {
    emr: state.selectedEmr.id,
    date: seq?.date,
    time: seq?.time,
    urgency: seq?.urgency || undefined,
    room_type: seq?.roomType,
    ward_type: seq?.wardType,
    division: seq?.division,
    action: seq?.id ? "EDIT_REQUEST" : "REQUEST",
    id: seq?.id || null,
    status_label: seq?.status_label || null,
    med_reconcile: seq?.med_reconcile || null,
  };

  let ptl = [];

  if (state.PlanTransferSequence?.id) {
    ptl = await PlanTransferDetail.update({
      pk: state.PlanTransferSequence?.id,
      apiToken: controller.apiToken,
      extra: { division: controller.data.division },
      data,
    });
  } else {
    ptl = await PlanTransferList.create({
      apiToken: controller.apiToken,
      extra: { division: controller.data.division },
      data,
    });
  }

  if (ptl[1]) {
    SetErrorMessage(controller, {
      ...params,
      error: { error: ptl[1], show: true },
    });
  } else {
    let [detail, error] = await PlanTransferDetailByEmr.retrieve({
      pk: state.selectedEmr.id,
      apiToken: controller.apiToken,
      extra: { division: controller.data.division },
    });

    if (error) {
      return SetErrorMessage(controller, {
        ...params,
        error: { error, show: true },
      });
    }

    state = controller.getState();

    controller.setState({
      PlanTransferSequence: {
        ...state.PlanTransferSequence,

        id: Number(detail?.id) || null,
        emr: detail?.emr,
        date: detail?.date || null,
        time: detail?.time
          ? detail?.time?.split(":")?.slice(0, -1).join(":")
          : null,
        urgency: detail?.urgency || null,
        wardType: Number(detail?.ward_type) || null,
        roomType: Number(detail?.room_type) || null,
        division: Number(detail?.division) || null,
        med_reconcile: Number(detail?.med_reconcile) || null,
        status_label: detail?.status_label || null,
        openRedirect: true,
      },
      errorMessage: { ...state.errorMessage, [params.card]: null },
      buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "SUCCESS" },
    });
  }
};

const HandleDelete: Handler = async (controller, params) => {
  let state = controller.getState();

  const seq = state.PlanTransferSequence;

  const data = {
    emr: seq?.emr,
    id: seq?.id,
    date: seq?.date,
    time: seq?.time,
    urgency: seq?.urgency || undefined,
    room_type: seq?.roomType,
    ward_type: seq?.wardType,
    division: seq?.division,
    med_reconcile: seq?.med_reconcile,
    action: "CANCEL",
    status_label: "Plan Transfer",
  };

  const ptd = await PlanTransferDetail.update({
    pk: state.PlanTransferSequence?.id,
    apiToken: controller.apiToken,
    extra: { division: controller.data.division },
    data,
  });

  if (ptd[1]) {
    return SetErrorMessage(controller, {
      ...params,
      error: { error: ptd[1], show: true },
    });
  }

  if (ptd[0]?.id) {
    const [detail, error] = await PlanTransferDetailByEmr.retrieve({
      pk: state.selectedEmr.id,
      apiToken: controller.apiToken,
      extra: { division: controller.data.division },
    });

    if (error) {
      return SetErrorMessage(controller, { ...params, error, show: true });
    }

    state = controller.getState();

    controller.setState({
      PlanTransferSequence: {
        ...state.PlanTransferSequence,
        id: Number(detail?.id) || null,
        emr: detail?.emr,
        date: detail?.date || null,
        time: detail?.time
          ? detail?.time?.split(":")?.slice(0, -1).join(":")
          : null,
        urgency: detail?.urgency || null,
        wardType: Number(detail?.ward_type) || null,
        roomType: Number(detail?.room_type) || null,
        division: Number(detail?.division) || null,
        med_reconcile: Number(detail?.med_reconcile) || null,
        status_label: detail?.status_label || null,
      },
      errorMessage: { ...state.errorMessage, [params.card]: null },
    });
  }
};
