import WasmController from "react-lib/frameworks/WasmController";

// APIs
// CLM
import PatientCoverageMaxReimbList from "issara-sdk/apis/PatientCoverageMaxReimbList_apps_CLM";
import PatientCoverageMaxReimbDetail from "issara-sdk/apis/PatientCoverageMaxReimbDetail_apps_CLM";
// CORE
import PatientCoverageList from "issara-sdk/apis/PatientCoverageList_core";

// Serializer
import PatientCoverageMaxReimbSerializer from "issara-sdk/types/PatientCoverageMaxReimbSerializer_apps_CLM";

// Interface
import { State as MainState } from "../../../../../HIS/MainHISInterface";
import { SetErrorMessage, SetProperty } from "../../common/CommonInterface";

export type State = Partial<{
  // main item
  financialAmountList: PatientCoverageMaxReimbSerializer[];
  modCoverageApprove: Partial<{
    open: boolean;
    patientCoverageList: Record<string, any>[];
    selectedPatientCoverage: any;
  }>;
  FinancialAmountSetSequence: Partial<{
    sequenceIndex: string | null;
    amount: any;
    selectedPatientCoverage: any;
    selectedPatientCoverageDetail: string | null;
    selectedFinancialAmount: any;
  }>;
}>;

type PickedState = Partial<
  Pick<MainState, "selectedPatient" | "buttonLoadCheck" | "errorMessage" | "successMessage">
>;

export type PickedProps = Partial<
  Omit<PickedState, ""> & Pick<State, "financialAmountList" | "modCoverageApprove">
>;

// Sequence
type NextIndexType = "Action" | "ModalAction";

type SeqState = {
  sequence: "FinancialAmountSet";
  restart?: boolean;
  nextIndex?: NextIndexType;
  noInit?: boolean;
  clear?: boolean;
  card?: string;
};

// Handle Action
type ActionType =
  | { action: "init"; card?: string }
  | {
      action: "fetch";
      card: string;
    }
  // Action
  | { action: "selectPatientReimb"; data: PatientCoverageMaxReimbSerializer }
  | { action: "save"; card: string }
  | { action: "delete"; data: PatientCoverageMaxReimbSerializer; card: string }
  | { action: "clearData" }
  | { action: "openModal"; card: string }
  | { action: "selectPatientCoverage" }
  | { action: "close" };

// Method

type SeqAct = ActionType & SeqState;
type SeqType<K> = K extends { action: string } ? Extract<SeqAct, K> : SeqState;

export type RunSequence = <K extends keyof SeqAct>(params: SeqType<Pick<SeqAct, K>>) => void;

export type SetProp = SetProperty<State & PickedState>;

type CustomExtract<T, U> = T extends object ? (U extends Partial<T> ? T : never) : never;

type Params<A extends ActionType["action"]> = CustomExtract<ActionType, { action: A }>;

export const StateInitial: State = {
  financialAmountList: [],
  modCoverageApprove: {
    open: false,
    patientCoverageList: [],
  },
  FinancialAmountSetSequence: {
    sequenceIndex: null,
  },
};

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division?: number;
  device?: number;
};

export const DataInitial = {};

export const ACTIONS = {
  INIT: "init",
  FETCH: "fetch",
  SELECT_PATIENT_REIMB: "selectPatientReimb",
  SAVE: "save",
  DELETE: "delete",
  CLEAR_DATA: "clearData",
  CLOSE: "close",
  SELECT_PATIENT_COVERAGE: "selectPatientCoverage",
  OPEN_MODAL: "openModal",
} as const;

type Handler<P = any, R = any> = (
  controller: WasmController<State & PickedState, Event, Data>,
  params: P
) => R;

export const Start: Handler<SeqState> = async (controller, params) => {
  Next(controller, params);
};

/* ------------------------------------------------------ */

/*                          Next                          */

/* ------------------------------------------------------ */
const Next: Handler<Pick<SeqState, "nextIndex" | "noInit">> = (controller, params) => {
  const state = controller.getState();

  const nextIndex = params.nextIndex;

  if (!nextIndex) {
    return;
  }

  controller.setState(
    {
      FinancialAmountSetSequence: { ...state.FinancialAmountSetSequence, sequenceIndex: nextIndex },
    },
    () => {
      const init = {
        Action: Action,
        ModalAction: ModalAction,
      }[nextIndex];

      if (!params.noInit) {
        init?.(controller, { ...params, action: "init", nextIndex: undefined });
      }
    }
  );
};

/* ------------------------------------------------------ */

/*                      Handle Action                     */

/* ------------------------------------------------------ */
export const Action: Handler<ActionType & { nextIndex?: NextIndexType }> = async (
  controller,
  params
) => {
  if (params.nextIndex) {
    return Next(controller, params);
  }

  const actionHandlers = {
    [ACTIONS.INIT]: HandleInitAction,
    [ACTIONS.SELECT_PATIENT_REIMB]: HandleSelectPatientReimb,
    [ACTIONS.SAVE]: HandleSave,
    [ACTIONS.DELETE]: HandleDelete,
    [ACTIONS.CLEAR_DATA]: HandleClearData,
  };

  const action = params.action as keyof typeof actionHandlers; // assert the type of action

  if (action && actionHandlers[action]) {
    return actionHandlers[action](controller, params as any);
  }
};

export const ModalAction: Handler<ActionType & { nextIndex?: NextIndexType }> = async (
  controller,
  params
) => {
  if (params.nextIndex) {
    return Next(controller, params);
  }

  const actionHandlers = {
    [ACTIONS.INIT]: HandleInitModalAction,
    [ACTIONS.SELECT_PATIENT_COVERAGE]: HandleSelectPatientCoverage,
    [ACTIONS.CLOSE]: HandleClose,
  };

  const action = params.action as keyof typeof actionHandlers; // assert the type of action

  if (action && actionHandlers[action]) {
    return actionHandlers[action](controller, params as any);
  }
};

/* ------------------------------------------------------ */

/*                      Handle Action                     */

/* ------------------------------------------------------ */
const HandleInitAction: Handler<Params<"init">> = async (controller, params) => {
  const [result, error] = await GetPatientCoverageMaxReimbList(controller, params);

  if (error) {
    return SetErrorMessage(controller, { ...params, error });
  }

  const state = controller.getState();

  controller.setState({
    financialAmountList: result?.items || [],
    FinancialAmountSetSequence: {
      ...state.FinancialAmountSetSequence,
      amount: "0.00",
      selectedPatientCoverage: null,
      selectedPatientCoverageDetail: "",
      selectedFinancialAmount: null,
    },
  });
};

const HandleSelectPatientReimb: Handler<Params<"selectPatientReimb">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    FinancialAmountSetSequence: {
      ...state.FinancialAmountSetSequence,
      amount: params.data.max_reimb || "0.00",
      selectedPatientCoverage: params?.data?.patient_coverage || null,
      selectedPatientCoverageDetail: `id: ${params.data.patient_coverage} coverage: ${
        params.data?.coverage_name || ""
      } ช่วงวันที่ ${params.data.start_date || ""} ${params.data.stop_date || ""}`,
      selectedFinancialAmount: { ...params.data },
    },
  });
};

const HandleSave: Handler<Params<"save">> = async (controller, params) => {
  const state = controller.getState();

  const seq = state.FinancialAmountSetSequence;

  const btnKey = `${params.card}_SAVE`;

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: "LOADING" },
  });

  const selectedId = seq?.selectedFinancialAmount?.id;
  const api = selectedId ? PatientCoverageMaxReimbDetail.patch : PatientCoverageMaxReimbList.create;

  const [_, error] = await api({
    pk: selectedId,
    apiToken: controller.apiToken,
    data: {
      max_reimb: seq?.amount,
      patient_coverage: seq?.selectedPatientCoverage,
    },
    extra: {
      division: controller.data?.division,
      device: controller.data?.device,
    },
  });

  if (error) {
    SetErrorMessage(controller, { ...params, btnAction: "SAVE", error });
  } else {
    controller.setState(
      {
        buttonLoadCheck: { ...state.buttonLoadCheck, [btnKey]: selectedId ? null : "SUCCESS" },
      },
      async () => {
        await Action(controller, { ...params, action: "init" });
      }
    );
  }
};

const HandleDelete: Handler<Params<"delete">> = async (controller, params) => {
  const [_, error] = await PatientCoverageMaxReimbDetail.delete({
    pk: params?.data.id,
    apiToken: controller.apiToken,
    params: {
      max_reimb: params?.data.max_reimb,
      patient_coverage: params?.data.patient_coverage,
    },
    extra: {
      division: controller.data?.division,
      device: controller.data?.device,
    },
  });

  if (error) {
    return SetErrorMessage(controller, { ...params, error });
  } else {
    Action(controller, { ...params, action: "init" });
  }
};

const HandleClearData: Handler<Params<"delete">> = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    FinancialAmountSetSequence: {
      ...state.FinancialAmountSetSequence,
      amount: "0.00",
      selectedPatientCoverage: null,
      selectedPatientCoverageDetail: "",
      selectedFinancialAmount: null,
    },
  });
};

/* ------------------------------------------------------ */

/*                   Handle ModalAction                   */

/* ------------------------------------------------------ */
const HandleInitModalAction: Handler<Params<"init">> = async (controller, params) => {
  const state = controller.getState();

  const [result, error] = await PatientCoverageList.list({
    apiToken: controller.apiToken,
    params: {
      patient: state.selectedPatient?.id,
      order_by_priority_and_id: true,
      unexpired: true,
      no_max_reimb: true,
      offset: 0,
      limit: 200,
    },
  });

  if (error) {
    return SetErrorMessage(controller, { ...params, error });
  }

  return controller.setState({
    modCoverageApprove: {
      open: true,
      patientCoverageList: result?.items || "",
      selectedPatientCoverage: null,
    },
  });
};

const HandleSelectPatientCoverage: Handler<Params<"selectPatientCoverage">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  let coverageDetail = "";

  if (state.modCoverageApprove?.selectedPatientCoverage?.id) {
    coverageDetail = `id: ${state.modCoverageApprove?.selectedPatientCoverage?.id} coverage: ${state.modCoverageApprove?.selectedPatientCoverage?.coverage_name} ช่วงวันที่ ${state.modCoverageApprove?.selectedPatientCoverage?.start_date} ${state.modCoverageApprove?.selectedPatientCoverage?.stop_date}`;
  }

  controller.setState({
    FinancialAmountSetSequence: {
      ...state.FinancialAmountSetSequence,
      sequenceIndex: "Action",
      selectedPatientCoverage: state.modCoverageApprove?.selectedPatientCoverage?.id || null,
      selectedPatientCoverageDetail: coverageDetail,
    },
    modCoverageApprove: {
      open: false,
      patientCoverageList: [],
      selectedPatientCoverage: null,
    },
  });
};

const HandleClose: Handler<Params<"close">> = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    FinancialAmountSetSequence: {
      ...state.FinancialAmountSetSequence,
      sequenceIndex: "Action",
    },
    modCoverageApprove: {
      open: false,
      patientCoverageList: [],
      selectedPatientCoverage: null,
    },
  });
};

/* ------------------------------------------------------ */

/*                          APIs                          */

/* ------------------------------------------------------ */
const GetPatientCoverageMaxReimbList: Handler = async (controller, params) => {
  const state = controller.getState();

  const [result, error] = await PatientCoverageMaxReimbList.list({
    apiToken: controller.apiToken,
    params: {
      patient_id: state.selectedPatient?.id,
      unexpired: true,
      offset: 0,
      limit: 200,
    },
    extra: {
      division: controller.data?.division,
      device: controller.data?.device,
    },
  });

  return [result, error];
};
