import React, { useEffect, useState } from "react";
import {
  Form,
  Modal,
  Input,
  Checkbox,
  Dropdown,
  Button,
  InputProps,
  CheckboxProps,
  DropdownProps,
} from "semantic-ui-react";
import { Table } from "react-lib/frameworks/Table";
import CardDivisionSearchUX from "./CardDivisionSearchUX";
import { useIntl } from "react-intl";

const CardDivisionSearch = (props: any) => {
  const intl = useIntl();
  const [codeContains, setCodeContains] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [checkedType, setCheckedType] = useState<boolean>(false);
  const [type, setType] = useState<any>("");
  const [divisionList, setDivisionList] = useState<any[]>([]);
  const [selectedDivision, setSelectedDivision] = useState<any>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [input, setInput] = useState<string>("");

  useEffect(() => {
    if (props.clearDivisionImput) {
      setInput("")
    }
  },[props.clearDivisionImput])

  const handleSelect = () => {
    if (selectedDivision) {
      props.onSelect?.(selectedDivision);
      setInput(selectedDivision?.code);
      handleClose();
    }
  };

  const handleChangeCode = (e: any, v: InputProps) => {
    setCodeContains(v.value);
  };

  const handleChangeName = (e: any, v: InputProps) => {
    setName(v.value);
  };

  const handleCheckedType = (e: any, v: CheckboxProps) => {
    setCheckedType(v.checked || false);
  };

  const handleChangeType = (e: any, v: DropdownProps) => {
    setType(v.value);
  };

  const handleClear = () => {
    setCodeContains("");
    setName("");
    setCheckedType(false);
    setType("");
    setDivisionList([]);
    setSelectedDivision(null)
  };

  const handleSearch = async () => {
    const result = await props.getOrderDivisionList({
      code_contains: codeContains || undefined,
      name: name || undefined,
      type: checkedType ? type : undefined,
    });
    
    setDivisionList(result[0]?.items || []);
  };

  const handleGetTrProps = (
    state: any,
    rowInfo: any,
    column: any,
    instance: any
  ) => ({
    style: {
      backgroundColor:
        selectedDivision?.id && rowInfo?.original?.id === selectedDivision?.id
          ? "#cccccc"
          : "white",
    },
    onClick: (e: any) => {
      setSelectedDivision(rowInfo?.original);
    },
    onDoubleClick: (e: any) => {
      props.onSelect?.(rowInfo?.original);
      setInput(rowInfo?.original?.code);
      handleClose();
    },
  });

  const handleOpenCard = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    handleClear();
  };

  const handleChangeInput = (e: any, v: InputProps) => {
    setInput(v.value);
    props.onEnter?.({});
  };

  const handleKeyPressInput = async (e: any) => {
    if (e.key === "Enter" && input) {
      const result = await props.getOrderDivisionList({
        code: input,
      });

      const division = result[0]?.items?.[0];

      if (!division?.id) {
        props.onEnter?.({});
        handleOpenCard();
      } else {
        props.onEnter?.(division);
      }
    }
  };

  return (
    <div>
      <Input
        id={"InputDivisionSearch"}
        value={input}
        disabled={props.disabled}
        onChange={handleChangeInput}
        onKeyPress={handleKeyPressInput}
        action={{
          icon: "search",
          onClick: (event: any) => {
            handleOpenCard();
          },
          type: "button",
        }}
        fluid={props.fluid}
      />
      <Modal open={open} onClose={handleClose} closeOnDimmerClick>
        <CardDivisionSearchUX
          // data
          codeContains={codeContains}
          name={name}
          checkedType={checkedType}
          type={type}
          divisionList={divisionList}
          // options
          divisionTypeOptions={props.divisionTypeOptions}
          // callback
          onClose={handleClose}
          onChangeCode={handleChangeCode}
          onChangeName={handleChangeName}
          onCheckedType={handleCheckedType}
          onChangeType={handleChangeType}
          onSearch={handleSearch}
          onClear={handleClear}
          onSelect={handleSelect}
          onGetTrProps={handleGetTrProps}
          languageUX={props.languageUX}
        />
      </Modal>
    </div>
  );
};

export default React.memo(CardDivisionSearch);
