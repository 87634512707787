import _ from 'lodash';
export default {
  "en-US": {
    common: {
      cancel: "Cancel",
      confirm: "Confirm",
      reject: "Reject",
      language: "Language",
      date: "Date",
      time: "Time",
      edit: "Edit",
      save: "Save",
      add: "Add",
      back: "Back",
      next: "Next",
      check: "Check",
      done: "Done",
      to: "To",
      approve: "Approve",
      block: "Block",
      detail: "Detail",
      canceled: "Canceled",
      completed: "Completed",
      customer: "Customer",
      project: "Project",
      sale: "Sale",
      agent: "Agent",
      location: "Location",
      ok: "OK",
      send: "Send",
      specify_reason: "Please specify reason",
      manage: "Manage",
      start: "Start",
      end: "End",
      start_datetime_should_less_then_end_datetime:
        "Start datetime should be less than end datetime",
      warning: "Warning",
      search: "Search",
      total: "Total",
      an_error_occurred: "An error occurred.",
      note: "Note",
      province: "Province",
      district_area: "District",
      sub_district_sub_area: "Subdistrict",
      postcode: "Postcode",
      male: "Male",
      female: "Female",
      none: "None",
      expire: "Expire",
      available: "Available",
      all: "All",
      or: "OR",
      try_again: "Try again",
      thank_you: "Thank you",
      no_data: "No Data",
      no_data_checkup: "No Checkup Data",
      no_data_labresult: "No Lab Result",
      no_data_medcert: "No Medical Certificate",
      retry: "Retry",
      notification: "Notification",
      contact_staff: "Contact Staff",
      back_to_home: "Back to Home"
    },
    auth: {
      ask_logout: "Are you sure you want to log out ? ",
      ask_delete: "Are you sure you want to delete user data and log out ?",
      logout: "Log out",
      delete_user: "Delete User Data & Log out",
    },
    appoint: {
      look_at_appointment: "Appointment",
      vaccine_appointment: "Vaccine Appointment",
      my_vaccine_appointment: "My Vaccine Appointment",
      no_appointment: "No Appointment",
      appointment_list: "Appointment List",
      unspecified: "Unspecified",
      specify_appointment: "Specify",
      cancel_or_postpone: "Cancel/Postpone",
      select_appointment: "Select Appointment",
      division: "Division",
      consultant: "Consultant",
      confirm_create_appointment: "Confirm creating an appointment",
      ask_create_appointment: "Please confirm your appointment",
      ask_cancel_postpone: "Cancel or postpone this appointment",
      specify_reason: "Specify your reason",
      search_placeholder: "Hospital / Province",
      registration_incomplete: "Registration Incomplete",
      you_can_use_only_part_of_app: "You will be able to use only part of the application. There are a lot more features behind this step. Please contact staff to verify yourself to access our full services.",
      contact_staff_to_verify_identity: "Please contact our staff to verify your identification",
    },
    bmi: {
      underweight: "Underweight",
      healthy: "Normal or Health weight",
      overweight: "Overweight",
      obese: "Obese BM",
    },
    form: {
      type_hospital_for_search: "Type the hospital's name for searching",
      form_hospital: "Form / Hospital",
      select_form: "Select form",
    },
    settings: {
      change_language: "Change language",
    },
    profile: {
      title: "Let's create your profile",
      ihid: "IH ID",
      edit_profile: "Edit Profile",
      profile: "Profile Info",
      contact: "Contact Info",
      medical: "Medical Info",
      first_name: "First name",
      last_name: "Last name",
      day_month_year_birth: "Birthdate (DD/MM/YYYY)",
      tel_no: "Tel No.",
      email: "Email",
      hospital: "Hospital",
      gender: "Gender",
      weight: "Weight (kg.)",
      height: "Height (cm.)",
      address: "Address",
      congenitaldisease: "Congenital disease",
      drugallergy: "Drug allergy",
      foodallergy: "Food allergy",
      bloodtype: "Blood",
      rh: "Rh",
      age: "Age",
      bmi: "BMI",
      requiredTitle: "Required Field",
      requiredGuide: "Please filled required field.",
      years: "years",
      months: "months",
      days: "days",
      prename: "Prename",
      lineid: "ID LINE",
      facebookid: "Facebook ID",
      citizenid_passportno: "Citizen Id/Passport No.",
      thainationalid_passportno: "Thai National ID/ Passport No.",
      nationality: "Nationality",
      phone_no: "Mobile",
      birthdate: "Birth date",
      firstname: "First Name",
      lastname: "Last Name",
      title_name: "Title",
      date_of_birth: "Date of Birth"
    },
    payment: {
      payment: "Payment",
      pending_payment: "Pending Payment",
      paid: "Paid",
      total_balance: "Total Balance",
      baht: "Baht",
      pay: "Pay",
      view_receipt: "View Receipt",
      no_patient_id_error: "There is no Patient Id",
    },
    drug_delivery: {
      confirm_drug_address: "Confirm shipping address",
      select_address: "Select Address",
      edit_address: "Edit Address",
      check_and_confirm: "Please verify your address before confirming.",
      receiver_name: "Receiver's Name",
      shipping_address: "Shipping Address",
      delivery_note:
        "Shipping costs 200 Baht for Bangkok Metropolitan Area, while other provinces may incur additional costs, which our staff will inform again to confirm.",
      contact_no: "Contact No.",
      receive_at_hospital: "Receive at Bangkok Hospital",
      hospital_address:
        "2 Soi Soonvijai 7, New Petchburi Rd., Huaykwang, Bangkok 10310 Thailand",
      change_shipping_address: "Change Shipping Address",
      add_new_address: "Add new address",
      add_edit_address: "Add/Edit address",
      default_address: "Default address",
      address_detail: "Address detail",
    },
    e_coupon: {
      redeem: "Redeem",
      order_no: "ORDER No.",
      coupon_id: "COUPON ID",
      add_coupon: "ADD COUPON",
      my_coupon: "MY COUPON",
      coupon_not_found: "Coupon not found",
      sorry_can_not_found_coupon:
        "Sorry, We can’t  found your coupon. Please, check your order number and coupon ID then try again",
      please_change_coupon: "please change your coupon ID and try again",
      sorry: "Sorry",
      select_location: "SELECT LOCATION",
      location_not_found: "Location not found",
      sorry_you_not_have_coupon: "Sorry, you don’t have any coupon",
      confirm_date: "Confirm date",
      selected_date: "SELECTED DATE",
      this_appointment_is_for_some_else: "This appointment is for some else",
      my_appointment: "My appointment",
      my_appointment_CRM: "Request Appointment",
      make_appointment: "Make appointment",
      make_additional_appointment: "Make Additional Appointment",
      detail_appointment_sent_via_sms: "Appointment detail will be sent to you as SMS via",
      would_you_like_to_book_moderna_vaccine: "Please specify your booking purpose.",
      book_for_yourself: "Booking for myself",
      book_for_some_else: "Booking for others",
      please_show_sms_massage: "Please present SMS message to staff on your vaccination date.",
      accepting_information_concerning_treatment:
        "I understand that information concerning my treatment will only be shared with doctors, nurses and staff who are involved in my care and treatment",
      fully_booked: "Fully booked",
      available: "Available",
      no_schedule: "No schedule",
      your_appointment_has_been_booked: "Your appointment has been booked",
      your_appointment_has_been_booked_CRM: "Your appointment will be confirmed with 24 hours.",
      your_appointment_has_been_confirmed: "Your apointment has been confirmed.",
      your_appointment_has_been_confirmed_CRM: "Your appointment will be confirmed with 24 hours.",
      appointment_booking_info_header: "Failure to show up as scheduled will be forfeited in any circumstance (due to the expiration of the vaccine).",
      appointment_booking_info_content: "The hospital reserves the right not to refund or compensate for the vaccine in any case. If you want to cancel the injection date, please make a cancel on this application at least 3 days in advance.",
      reasons_of_appointment: "Please specific reasons for the Appointment",
      vaccine_recipent_profile: "Vaccine Recipient’s Profile",
      action_cannot_completed: "This action cannot be completed because the program is busy. Please wait a moment and try agiain."
    },
    download: {
      download: "Download",
      download_pdf_info_medcert:
        "To open the downloaded PDF file, the password is the last 5 digits of the ID card. or the passport number you provided to the hospital.",
      download_pdf_info_checkup:
        "Download checkup report?",
    },
    feed: {
      you_donot_have_any_notification: "คุณยังไม่มีรายการแจ้งเตือน"
    },
    
  },

  "th-TH": {
    common: {
      cancel: "ยกเลิก",
      reject: "ปฏิเสธ",
      confirm: "ยืนยัน",
      language: "ภาษา",
      date: "วันที่",
      time: "เวลา",
      edit: "แก้ไข",
      save: "บันทึก",
      add: "เพิ่ม",
      back: "ย้อนกลับ",
      next: "ถัดไป",
      check: "ตรวจสอบ",
      done: "เสร็จสิ้น",
      to: "ถึง",
      approve: "ยืนยัน",
      block: "บล็อค",
      detail: "รายละอียด",
      canceled: "ถูกยกเลิก",
      completed: "เสร็จสิ้น",
      customer: "ลูกค้า",
      project: "โครงการ",
      sale: "เซลล์",
      agent: "เอเย่นต์",
      location: "สถานที่",
      ok: "ตกลง",
      send: "ส่ง",
      specify_reason: "กรุณาระบุเหตุผล",
      manage: "จัดการ",
      start: "เริ่มต้น",
      end: "สิ้นสุด",
      start_datetime_should_less_then_end_datetime:
        "เวลาเริ่มต้นต้องน้อยกว่าเวลาสิ้นสุด",
      warning: "แจ้งเตือน",
      search: "ค้นหา",
      total: "รวม",
      an_error_occurred: "เกิดข้อผิดพลาด",
      note: "หมายเหตุ",
      province: "จังหวัด",
      district_area: "เขต/อำเภอ",
      sub_district_sub_area: "แขวง/ตำบล",
      postcode: "รหัสไปรษณีย์",
      male: "ชาย",
      female: "หญิง",
      none: "ไม่มี",
      expire: "หมดอายุ",
      available: "ใช้งานได้",
      all: "ทั้งหมด",
      or: "หรือ",
      try_again: "ลองอีกครั้ง",
      thank_you: "ขอบคุณ",
      no_data: "ไม่มีข้อมูล",
      no_data_checkup: "ไม่มีข้อมูล",
      no_data_labresult: "ไม่มีข้อมูล",
      no_data_medcert: "ไม่มีข้อมูล",
      retry: "ลองอีกครั้ง",
      notification: "การแจ้งเตือน",
      contact_staff: "ติดต่อเจ้าหน้าที่",
      back_to_home: "กลับสู่หน้าหลัก"
    },
    auth: {
      ask_logout: "ต้องการออกจากระบบใช่หรือไม่",
      ask_delete:
        "ต้องการลบข้อมูล User ออกจากระบบ และ ทำการออกจากระบบใช่หรือไม่",
      logout: "ออกจากระบบ",
      delete_user: "ลบข้อมูล User จากระบบ และ ออกจากระบบ",
    },
    appoint: {
      look_at_appointment: "ดูนัดหมาย",
      vaccine_appointment: "นัดหมายวัคซีน",
      my_vaccine_appointment: "นัดหมายวัคซีนของฉัน",
      no_appointment: "ไม่มีนัดหมาย",
      appointment_list: "รายการนัดหมาย",
      unspecified: "ยังไม่ได้ระบุนัดหมาย",
      specify_appointment: "ระบุนัดหมาย",
      cancel_or_postpone: "ยกเลิก/เลื่อนนัด",
      select_appointment: "เลือกนัดหมาย",
      division: "แผนก",
      consultant: "ผู้ให้คำปรึกษา",
      confirm_create_appointment: "ยืนยันการสร้างนัดหมาย",
      ask_create_appointment: "คุณต้องการสร้างนัดหมายใช่หรือไม่",
      ask_cancel_postpone: "คุณต้องการยกเลิก/เลื่อนนัดหมายใช่หรือไม่",
      specify_reason: "ระบุเหตุผล",
      search_placeholder: "โรงพยาบาล / จังหวัด",
      registration_incomplete: "ลงทะเบียนไม่สมบูรณ์",
      you_can_use_only_part_of_app: "คุณจะสามารถใช้แอปพลิเคชันได้เพียงบางส่วนเท่านั้น กรุณาติดต่อเจ้าหน้าที่เพื่อยืนยันตัวตน เพื่อเข้าถึงการใช้งานแอปพริเคชันอย่างสมบูรณ์",
      contact_staff_to_verify_identity: "กรุณาติดต่อเจ้าหน้าที่ เพื่อยืนยันตัวตน",
    },
    bmi: {
      underweight: "น้ำหนักต่ำกว่าเกณฑ์",
      healthy: "สมส่วน",
      overweight: "น้ำหนักเกิน",
      obese: "โรคอ้วน",
    },
    settings: {
      change_language: "เปลี่ยนภาษา",
    },
    profile: {
      title: "สร้างข้อมูลของคุณ",
      ihid: "IH ID",
      edit_profile: "แก้ไขข้อมูลส่วนตัว",
      profile: "ข้อมูลส่วนตัว",
      contact: "ข้อมูลการติดต่อ",
      medical: "ข้อมูลทางการแพทย์",
      first_name: "ชื่อ",
      last_name: "นามสกุล",
      day_month_year_birth: "วัน/เดือน/ปีเกิด",
      tel_no: "เบอร์โทรศัพท์",
      email: "อีเมล",
      hospital: "โรงพยาบาล",
      gender: "เพศ",
      weight: "น้ำหนัก (kg.)",
      height: "ส่วนสูง (cm.)",
      address: "ที่อยู่",
      congenitaldisease: "โรคประจำตัว",
      drugallergy: "การแพ้ยา",
      foodallergy: "การแพ้อาหาร",
      bloodtype: "หมู่เลือด",
      rh: "Rh",
      age: "อายุ",
      bmi: "BMI",
      requiredTitle: "ข้อมูลที่ต้องการ",
      requiredGuide: "กรุณากรอกข้อมูล",
      years: "ปี",
      months: "เดือน",
      days: "วัน",
      prename: "คำนำหน้า",
      lineid: "ID LINE",
      facebookid: "Facebook ID",
      citizenid_passportno: "เลขบัตรประจำตัวประชาชน/หนังสือเดินทาง",
      thainationalid_passportno: "เลขบัตรประจำตัวประชาชน/หนังสือเดินทาง",
      nationality: "สัญชาติ",
      phone_no: "เบอร์โทรศัพท์",
      birthdate: "วันเดือนปี เกิด",
      firstname: "ชื่อจริง",
      lastname: "นามสกุล",
      title_name: "คำนำหน้า",
      date_of_birth: "วันเดือนปี เกิด"
    },
    form: {
      type_hospital_for_search:
        "พิมพ์ชื่อโรงพยาบาลที่ต้องการรับบริการเพื่อค้นหา",
      form_hospital: "แบบประเมิน / โรงพยาบาล",
      select_form: "เลือกแบบประเมิน",
    },
    payment: {
      payment: "การชำระเงิน",
      pending_payment: "รอชำระ",
      paid: "ชำระแล้ว",
      total_balance: "ยอดชำระ",
      baht: "บาท",
      pay: "จ่าย",
      view_receipt: "ดูใบเสร็จรับเงิน",
      no_patient_id_error: "เนื่องจากไม่มี Patient Id",
    },
    drug_delivery: {
      confirm_drug_address: "ยืนยันที่อยู่สำหรับการจัดส่งยา",
      select_address: "เลือกที่อยู่",
      edit_address: "แก้ไขที่อยู่",
      check_and_confirm: "กรุณาตรวจสอบก่อนยืนยัน",
      receiver_name: "ชื่อผู้รับ",
      shipping_address: "ที่อยู่สำหรับจัดส่ง",
      delivery_note:
        "ค่าจัดส่ง 200 บาท ในเขตกทม. และ ปริมณฑล ต่างจังหวัด อาจมีค่าใช้จ่ายเพิ่มเติม เจ้าหน้าที่จะแจ้งให้ทราบอีกครั้ง",
      contact_no: "เบอร์ติดต่อ",
      receive_at_hospital: "รับที่โรงพยาบาลกรุงเทพ",
      hospital_address:
        "เลขที่ 2 ซอยศูนย์วิจัย 7 ถนนเพชรบุรีตัดใหม่ แขวงบางกะปิ เขตห้วยขวาง กรุงเทพมหานคร",
      change_shipping_address: "เปลี่ยนที่อยู่จัดส่ง",
      add_new_address: "เพิ่มที่อยู่ใหม่",
      add_edit_address: "เพิ่ม/แก้ไขที่อยู่",
      default_address: "ที่อยู่ตั้งต้น",
      address_detail: "รายละเอียดที่อยู่",
    },
    e_coupon: {
      redeem: "ใช้คูปอง",
      order_no: "เลขที่ออเดอร์",
      coupon_id: "รหัสคูปอง",
      add_coupon: "ใช้คูปอง",
      my_coupon: "คูปองของฉัน",
      coupon_not_found: "ไม่พบคูปอง",
      sorry_can_not_found_coupon:
        "ขออภัย ระบบตรวจสอบไม่พบคูปองตามที่ท่านระบุ กรุณาตรวจสอบเลขที่ออเดอร์ (order no) และรหัสคูปอง แล้วลองอีกครั้ง",
      please_change_coupon: "กรุณาเปลี่ยนรหัสคูปองและลองอีกครั้ง",
      sorry: "ขออภัย",
      select_location: "เลือกสถานที่",
      selected_date: "วันที่ที่เลือก",
      location_not_found: "ไม่พบสถานที่",
      sorry_you_not_have_coupon: "ขออภัย คุณไม่มีรายการคูปอง",
      confirm_date: "ยืนยันวันที่นัด",
      this_appointment_is_for_some_else: "นัดหมายครั้งนี้สำหรับบุคคลอื่น",
      my_appointment: "นัดหมายของฉัน",
      my_appointment_CRM: "คำขอนัดหมาย",
      make_appointment: "ยืนยันการนัดหมาย",
      make_additional_appointment: "ทำนัดหมายเพิ่มเติม",
      detail_appointment_sent_via_sms: "รายละเอียดการนัดหมายจะถูกส่งถึงท่าน ทางข้อความ sms ที่เบอร์",
      would_you_like_to_book_moderna_vaccine: "คุณต้องการทำนัดหมาย Moderna Vaccine ให้กับใคร กรุณาระบุ",
      book_for_yourself: "นัดหมายให้ตนเอง",
      book_for_some_else: "นัดหมายให้ผู้อื่น",
      please_show_sms_massage: "กรุณาแสดงข้อความ sms แก่เจ้าหน้าที่ ในวันที่เข้ารับบริการ",
      accepting_information_concerning_treatment:
        "ฉันยอมรับข้อมูลเกี่ยวกับการรักษาของฉันเพื่อใช้ร่วมกับแพทย์ พยาบาล และเจ้าหน้าที่ที่เกี่ยวข้องกับการดูแลและการรักษา",
      fully_booked: "ตารางนัดเต็ม",
      available: "สามารถนัดหมายได้",
      no_schedule: "ไม่มีตารางออกตรวจ/ไม่สามารถนัดได้",
      your_appointment_has_been_booked: "นัดหมายของท่านถูกจองเรียบร้อยแล้ว",
      your_appointment_has_been_booked_CRM: "นัดหมายของท่านจะได้รับการยืนยันภายใน 24 ชม.",
      your_appointment_has_been_confirmed: "นัดหมายของท่านถูกจองเรียบร้อยแล้ว",
      your_appointment_has_been_confirmed_CRM: "นัดหมายของท่านจะได้รับการยืนยันภายใน 24 ชม.",
      appointment_booking_info_header: "เนื่องจากวัคซีนมีอายุจำกัด กรณีที่คุณไม่มารับบริการตามนัด คูปองนี้จะไม่สามารถใช้ได้อีก",
      appointment_booking_info_content: "โรงพยาบาลขอสงวนสิทธิ์ไม่คืนเงินหรือชดเชยค่าวัคซีนไม่ว่ากรณีใดก็ตาม ดังนั้นหากคุณต้องการยกเลิกนัดหมายฉีดวัคซีน กรุณาดำเนินการยกเลิกล่วงหน้าอย่างน้อย 3 วัน",
      reasons_of_appointment: "กรุณาระบุเหตุผลของการนัดหมาย",
      vaccine_recipent_profile: "ประวัติผู้ต้องการทำนัดหมาย",
      action_cannot_completed: "การทำนัดหมายของท่านยังไม่เสร็จสมบูรณ์ เนื่องจากมีการเข้าใช้งานระบบเป็นจำนวนมาก กรุณารอสักครู่ และลองใหม่อีกครั้ง"
    },
    download: {
      download: "ดาวน์โหลด",
      download_pdf_info_medcert:
        "ในการเปิดไฟล์ PDF ที่ดาวน์โหลดมา รหัสผ่านคือ 5 หลักสุดท้ายของบัตรประจำตัวประชาชน หรือหมายเลขหนังสือเดินทางที่ท่านให้ไว้กับโรงพยาบาล",
      download_pdf_info_checkup:
        "คุณจะดาวน์โหลด เอกสารตรวจสุขภาพนี้?",
    },
    feed: {
      you_donot_have_any_notification: "คุณยังไม่มีรายการแจ้งเตือน"
    },
  },
};