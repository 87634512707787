import { ContentStack, Style, TDocumentDefinitions } from "pdfmake/interfaces";

import CompanyLogoForm, { FontType } from "react-lib/apps/HISV3/common/CompanyLogoForm";

const FORM_NAME = "HeaderSummaryReportForm";

type HeaderSummaryReportFormProps = Partial<{
  data?: {
    division_name?: string;
    doctor_name?: string;
    end_date?: string;
    period?: string;
    print_date_time: string;
    print_user: string;
    start_date?: string;
  };
  extra: TDocumentDefinitions["content"];
  font?: FontType;
  header: ContentStack["stack"];
  images: Record<string, any>;
  isLightLines?: boolean;
  pageMarginBottom: number;
  pageMarginLeft: number;
  pageMarginRight: number;
  pageMarginTop: number;
  pageMargins: TDocumentDefinitions["pageMargins"];
  styles: TDocumentDefinitions["styles"];
  title?: string;
}>;

type ReturnType = {
  font?: string;
  fontSizes: Record<number, number>;
  lineHeights: Record<number, number>;
  styles: Record<"fieldHeader" | "fieldSubHeader", Style>;
} & Pick<TDocumentDefinitions, "header" | "images" | "pageMargins">;

const HeaderSummaryReportForm = async (
  props: HeaderSummaryReportFormProps = {}
): Promise<ReturnType> => {
  const defaultFont = props.font || "THSarabunNew";
  const extras = Array.isArray(props.extra) ? props.extra : [props.extra || { text: "" }];

  const pageMarginLeft = props.pageMargins?.[0] ?? props.pageMarginLeft ?? 15;
  const pageMarginTop = props.pageMargins?.[1] ?? props.pageMarginTop ?? 100;
  const pageMarginRight = props.pageMargins?.[2] ?? props.pageMarginRight ?? 15;

  const companyLogoForm = await CompanyLogoForm({
    font: defaultFont,
    form: FORM_NAME,
    height: 27.5,
    isHorizontal: true,
    showAddress: ["CU"],
    type: 2,
  });

  const { font, fontSizes, images, lineHeights } = companyLogoForm;

  return {
    font,
    fontSizes,
    images: {
      ...images,
      ...props.images,
    },
    lineHeights,
    // * Logo height 100
    pageMargins: props.pageMargins || [
      pageMarginLeft,
      pageMarginTop,
      pageMarginRight,
      props.pageMarginBottom ?? 15,
    ],
    styles: {
      ...props.styles,
      fieldHeader: { bold: true, fontSize: fontSizes[16] },
      fieldSubHeader: { fontSize: fontSizes[14] },
    },
    header: (currentPage: number, pageCount: number) => ({
      stack: [
        {
          margin: [pageMarginLeft, pageMarginLeft, pageMarginRight, 0],
          table: {
            widths: ["100%"],
            body: [
              [
                {
                  columns: [
                    {
                      stack: [
                        {
                          border: [true, true, false, true],
                          ...companyLogoForm,
                          absolutePosition: { x: pageMarginLeft + 5.5, y: pageMarginLeft + 11.5 },
                          marginTop: 2.5,
                        },
                      ],
                      width: "39%",
                    },
                    {
                      columns: [
                        {
                          alignment: "center",
                          border: [false, true, false, true],
                          margin: [0, 3.5, 0, 0],
                          stack: props.header || [
                            {
                              style: "fieldHeader",
                              text: props.title,
                            },
                            {
                              marginTop: -1,
                              preserveLeadingSpaces: true,
                              style: "fieldHeader",
                              text: props.data?.doctor_name || "",
                            },
                            {
                              alignment: "center",
                              style: "fieldHeader",
                              text: [
                                {
                                  text: `ออกตรวจ ${props.data?.division_name || ""}`,
                                },
                                {
                                  preserveLeadingSpaces: true,
                                  text: `   คาบ : ${props.data?.period || ""}`,
                                },
                              ],
                            },
                            {
                              style: "fieldSubHeader",
                              text: `วันที่ ${props.data?.start_date || ""} ถึง ${
                                props.data?.end_date || ""
                              }`,
                            },
                          ],
                          width: "auto",
                        },
                        {
                          text: "",
                          width: "*",
                        },
                      ],
                      width: "*",
                    },
                    {
                      border: [false, true, true, true],
                      margin: [0, 7.5, 10, 0],
                      stack: [
                        {
                          columns: [
                            { text: "", width: "*" },
                            {
                              stack: [
                                {
                                  style: "fieldSubHeader",
                                  text: [
                                    { text: "ผู้พิมพ์ : " },
                                    { text: props.data?.print_user || "" },
                                  ],
                                },
                                {
                                  noWrap: true,
                                  style: "fieldSubHeader",
                                  text: [
                                    { text: "วันที่พิมพ์ : " },
                                    { text: props.data?.print_date_time || "" },
                                  ],
                                },
                              ],
                              width: "auto",
                            },
                          ],
                        },
                        {
                          alignment: "right",
                          style: "fieldSubHeader",
                          text: `หน้า ${currentPage.toString()}/${pageCount.toString()}`,
                        },
                      ],
                      width: "auto",
                    },
                  ],
                  width: "100%",
                },
              ],
            ],
          },
          layout: {
            hLineWidth: () => (props.isLightLines ? 0.25 : 1),
            vLineWidth: () => (props.isLightLines ? 0.25 : 1),
          },
        },
        ...extras,
      ],
    }),
  };
};

export default HeaderSummaryReportForm;
