import React from "react";
import CardChartSummary from "./CardChartSummary";
import { useIntl } from "react-intl";

const CardChartSummaryContainer = (props: any) => {
  const intl = useIntl();

  const handleGetCSLabList = () => {

  }

  const handleGetCSMasterList = () => {

  }

  const handleCSSearchEncounterTable = () => {

  }

  const handleGetCSDiagnosisList = () => {

  }

  const handleGetCSPendingOrderList = () => {

  }

  const handleGetCSMedication = () => {

  }

  const handleGetCSPatientCare = () => {

  }

  const handleGetCSReplyConsult = () => {

  }

  const handleGetCSProcedureDetail = () => {

  }

  const handlePatchDiagnosis = () => {

  }

  return(
    <CardChartSummary 

      // PatientSearchBox 
      patientSearchController={{}}
      forcedSelectHN={""}
      nationalitiesList={[]}
      crdSearchPatientData={[]}
      patientCurrentPage={0}
      patientOldNameData={{}}

      patientOldNameLoading={false}
      patientListLoading={false}
      onSelectPatient={() => {}}

      // Control 
      csLoading={false}
      patientDataAPI={{}}
      ref={null}

      isNurse={false}

      allDoctorList={[]}
      allDivisionList={[]}
      allLabDivisionList={[]}

      // Handle
      handleGetCSLabList={handleGetCSLabList}
      handleGetChartSummaryAllList={handleGetCSMasterList}
      handleCSSearchEncounterTable={handleCSSearchEncounterTable}
      handleGetCSDiagnosisList={handleGetCSDiagnosisList}
      handleGetCSPendingOrderList={handleGetCSPendingOrderList}
      handleGetCSMedication={handleGetCSMedication}
      handleGetCSPatientCare={handleGetCSPatientCare}
      handleGetCSReplyConsult={handleGetCSReplyConsult}
      handleGetCSProcedureDetail={handleGetCSProcedureDetail}
      handlePatchDiagnosis={handlePatchDiagnosis}

      // Data 
      csEncounterList={[]}
      csLabList={[]}
      csDiagnosisList={[]}
      csPendingOrderList={[]}
      csMedicationList={[]}
      csPatientCareList={[]}
      csReplyConsultList={[]}
      csProcedureDetailList={[]}
      csADRData={[]}
      languageUX={props.languageUX}
    />
  )
}

export default CardChartSummaryContainer;