import JsBarcode from "jsbarcode";
import { TDocumentDefinitions } from "pdfmake/interfaces";

// Types
export type HeaderMedicineMoreProps = {
  hn: number;
  patient: string;
  birthdate?: string;
  type: string;
  full_age?: string;
  code: string;
  requested: Record<string, any> | null;
  page: string;
};

const textToBase64BarcodeBlob = (text: string) => {
  const canvas = document.createElement("canvas");
  JsBarcode(canvas, text, { displayValue: false });
  return canvas.toDataURL("image/png");
};

const HeaderMedicineMore = (props: HeaderMedicineMoreProps): TDocumentDefinitions => {
  return {
    pageSize: "A5",
    pageMargins: [15, 65, 15, 55],
    pageOrientation: "landscape",
    defaultStyle: {
      font: "THSarabunNew",
      lineHeight: 1,
      fontSize: 14,
    },
    header: () => {
      return {
        margin: [20, 10, 5, 0],
        columns: [
          {
            width: "76.25%",
            stack: [
              {
                columns: [
                  {
                    width: "46%",
                    stack: [
                      {
                        text: `${props.patient} HN: ${props.hn}`,
                        bold: true,
                        marginTop: 10,
                      },
                      {
                        text: [
                          { text: "ว/ด/ป เกิด: ", bold: true, fontSize: 13 },
                          {
                            text: `${props.birthdate || "-"} อายุ ${props.full_age || "-"}`,
                            fontSize: 13,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    width: "54%",
                    text: "",
                  },
                ],
              },
              {
                margin: [20, -20, 20, 0],
                columns: [
                  {
                    width: "46%",
                    text: "",
                  },
                  { text: "", width: "1%" },
                  {
                    width: "53%",
                    marginTop: 7.5,
                    stack: [
                      {
                        text: "",
                        bold: true,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            width: "*",
            stack: [
              {
                text: `Page ${props.page}`,
                alignment: "right",
                fontSize: 13,
                margin: [0, -2.5, 15, 0],
              },
              {
                image: textToBase64BarcodeBlob(props.code),
                width: 80,
                height: 12.5,
                border: [false, false, false, false],
                alignment: "right",
                margin: [0, 0, 5, 2.5],
              },
              {
                table: {
                  widths: [50, 50],
                  heights: [18],
                  body: [
                    [
                      {
                        text: props.type.replace("_", ""),
                        fillColor: "#000000",
                        color: "white",
                        alignment: "center",
                        fontSize: 13,
                      },
                      {
                        text: props.code,
                        alignment: "center",
                        fontSize: 13,
                      },
                    ],
                  ],
                },
                layout: {
                  paddingTop: () => {
                    return 0;
                  },
                  paddingBottom: () => {
                    return 0;
                  },
                  paddingLeft: () => {
                    return 5;
                  },
                  paddingRight: () => {
                    return 5;
                  },
                  hLineWidth: () => {
                    return 0.5;
                  },
                  vLineWidth: () => {
                    return 0.5;
                  },
                },
              },
            ],
          },
        ],
      };
    },
    footer: () => {
      return {
        margin: [15, 0, 15, 0],
        table: {
          widths: ["25%", "25%", "25%", "25%"],
          body: [
            [
              {
                text: `แพทย์: ${props.requested?.name || ""}`,
                style: "tableData",
              },
              { text: "", style: "tableData" },
              { text: "", style: "tableData" },
              { text: "", style: "tableData" },
            ],
            [
              { text: "\u00a0", style: "tableData" },
              { text: "", style: "tableData" },
              { text: "", style: "tableData" },
              { text: "", style: "tableData" },
            ],
          ],
        },
        layout: {
          hLineWidth: () => {
            return 0.5;
          },
          vLineWidth: () => {
            return 0.5;
          },
        },
      };
    },
    images: {},
  };
};

export default HeaderMedicineMore;
