import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button,
  Dropdown,
  FormGroup,
  FormField,
  Input,
  Label,
  Form,
  Checkbox
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardBillingDocumentUX = (props: any) => {
    return(
      <div
        style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{ display: props.viewMode ? "none" : "flex", padding: "10px 10px 0px 10px" , minWidth: "max-content", justifyContent: "space-between", alignItems: "flex-start" }}>
          {}
          <div
            style={{ fontSize: "1.1rem", fontWeight: "bold",display: "flex", alignItems: "center" }}>
            {props.title}
            <div
              style={{marginLeft: "15px", display: "none"}}>
              เลขที่ใบเสร็จ :
            </div>
            <Label
              style={{marginLeft: "5px"}}>
              {props.receiptCode}
            </Label>
          </div>
          <div
            style={{display: "flex"}}>
            {}
            <div
              style={{marginRight: "0.5rem"}}>
              {props.lockingStatusMessage}
            </div>
            <div
              style={{ fontSize: "1.1rem", fontWeight: "bold" }}>
              {props.titleDescription}
            </div>
          </div>
        </div>
        <div
          style={{padding: "0px 10px 10px 10px", display: props.viewMode ? "none" : "" }}>

          <hr>
          </hr>
        </div>
        <div
          style={{margin: "-0.5rem 1rem -0.15rem", display: props.showFilter? "": "none"}}>

          <Form
            className="size small">
            <FormGroup
              inline={true}>
              <FormField
                inline={true}>
                <Checkbox
                  checked={props.medicalFeeFilter?.isDivision || false}
                  disabled={["Mode", "Item"].includes(props.orderBy || "")}
                  label="แผนก"
                  name="isDivision"
                  onChange={props.onChangeFilter}
                  style={{minWidth: "max-content"}}>
                </Checkbox>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <Dropdown
                  className="inline-label"
                  disabled={!props.medicalFeeFilter?.isDivision || ["Mode", "Item"].includes(props.orderBy || "")}
                  fluid={true}
                  name="division"
                  onChange={props.onChangeFilter}
                  options={props.divisionOptions}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.medicalFeeFilter?.division || ""}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}>
                <Checkbox
                  checked={props.medicalFeeFilter?.isDate|| false}
                  disabled={props.orderBy === "Mode"}
                  label="วันที่ค่าใช้จ่าย"
                  name="isDate"
                  onChange={props.onChangeFilter}
                  style={{minWidth: "max-content"}}>
                </Checkbox>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <div
                  style={{width: "100%"}}>

                  <DateTextBox
                    disabled={!props.medicalFeeFilter?.isDate || props.orderBy === "Mode"}
                    onChange={(value)=>props.onChangeFilter?.(null, {name: "startDate", value})}
                    value={props.medicalFeeFilter?.startDate|| ""}>
                  </DateTextBox>
                </div>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <div
                  style={{width: "100%"}}>

                  <DateTextBox
                    disabled={!props.medicalFeeFilter?.isDate || props.orderBy === "Mode"}
                    onChange={(value)=>props.onChangeFilter?.(null, {name: "endDate", value})}
                    value={props.medicalFeeFilter?.endDate|| ""}>
                  </DateTextBox>
                </div>
              </FormField>
              <FormField
                inline={true}>
                <Checkbox
                  checked={props.medicalFeeFilter?.isEncounter|| false}
                  disabled={["Mode"].includes(props.orderBy || "")}
                  label="Encounter"
                  name="isEncounter"
                  onChange={props.onChangeFilter}
                  style={{minWidth: "max-content"}}>
                </Checkbox>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <Dropdown
                  className="inline-label"
                  disabled={!props.medicalFeeFilter?.isEncounter || ["Mode"].includes(props.orderBy || "")}
                  fluid={true}
                  name="encounter"
                  onChange={props.onChangeFilter}
                  options={props.encounterNumberOptions}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.medicalFeeFilter?.encounter|| ""}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}>
                <div>
                  {props.buttonSearch}
                </div>
              </FormField>
            </FormGroup>
          </Form>
        </div>
        <div
          style={{ display: props.orderBy == "Order" ? "block" : "none", padding: "0 10px" }}>

          <Table
            className="--rt-tbody-overflow-y-hidden"
            data={props.invoiceItems}
            getTrProps={props.byOrderRowProps}
            headers={`${props.viewMode ? "" : "...,"}Encounter,แผนก,ชื่อรายการคำสั่ง,ประเภท,วันที่และเวลาค่าใช้จ่าย,ราคา, ส่งเบิก, absorb,เบิกเอง, เบิกไม่ได้,ส่วนลด,ผู้ป่วยชำระ, ประเภทค่าใช้จ่าย,สถานะค่าใช้จ่าย`}
            keys={`${props.viewMode ? "": "chk,"}encounter__number,division__name,display_name,encounter__type,created,price__sum,send_claim__sum, absorb__sum,patient_claim__sum,non_claimable__sum,discount__sum,payable__sum,type__name,status__name`}
            minRows="10"
            showPagination={false}
            style={{ height: "50vh", ...(props.tableStyle || {})}}
            widths={props.viewMode ? "" : "60,100"}>
          </Table>
        </div>
        <div
          style={{ display: props.orderBy == "Mode" ? "block" : "none", padding: "0 10px" }}>

          <Table
            className="--rt-tbody-overflow-y-hidden"
            data={props.invoiceItems}
            getTdProps={props.onSelectedFaxClaim}
            headers="รหัสหมวด,ชื่อหมวดค่ารักษาพยาบาล, ราคา, ส่งเบิก, absorb, เบิกเอง, เบิกไม่ได้, ส่วนลด, ผู้ป่วยชำระ"
            keys="bill_mode__code, bill_mode__name, price__sum, send_claim__sum, absorb__sum, patient_claim__sum, non_claimable__sum, discount__sum, payable__sum"
            minRows="10"
            showPagination={false}
            style={{ height: "50vh" }}
            widths="100">
          </Table>
        </div>
        <div
          style={{ display: props.orderBy == "Item" ? "block" : "none", padding: "0 10px" }}>

          <Table
            className="--rt-tbody-overflow-y-hidden"
            data={props.invoiceItems}
            getTdProps={props.onSelectedFaxClaim}
            headers={`${props.viewMode ? "": "...,"}Encounter,รหัสหมวด, รหัสค่ารักษา, ชื่อรายการค่ารักษาพยาบาล, วันที่และเวลาค่าใช้จ่าย, จำนวน, ราคาต่อหน่วย, ราคา, ส่งเบิก, absorb, เบิกเอง, เบิกไม่ได้, ส่วนลด, ผู้ป่วยชำระ, ค้างชำระ, ส่วนลดสิทธิ, รหัสส่วนลด,`}
            id="tb-billInvoiceItem"
            keys={`${props.viewMode ? "" : "chk,"}encounter__number,mode_code, code, product, createdDate, quantity, unitprice, price, send_claim, absorb, patient_claim, non_claimable, discount, pay, unpaid, drg_discount_amount, discount_code,delete`}
            minRows="10"
            showPagination={false}
            style={{ height: "50vh", ...(props.tableStyle || {})}}
            widths={props.viewMode ? "" : "60,100,100,100,140"}>
          </Table>
        </div>
        <div
          style={{ height: "8vh" , display: "none"}}>

          <div
            style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(2, 1fr)","gridTemplateColumns":"repeat(7, 1fr)"}}>
            <div
              style={{ gridRow: "1/2", gridColumn: "1/2", padding: "5px" }}>

              <div
                style={{ height: "100%", display: "flex", alignItems: "flex-end" }}>
                ราคารวม
              </div>
            </div>
            <div
              style={{ gridRow: "1/2", gridColumn: "2/3", padding: "5px" }}>
              <div
                style={{ height: "100%", display: "flex", alignItems: "flex-end" }}>
                ส่งเบิก
              </div>
            </div>
            <div
              style={{ gridRow: "1/2", gridColumn: "3/4", padding: "5px" }}>
              <div
                style={{ height: "100%", display: "flex", alignItems: "flex-end" }}>
                absorb
              </div>
            </div>
            <div
              style={{ gridRow: "1/2", gridColumn: "4/5", padding: "5px" }}>
              <div
                style={{ height: "100%", display: "flex", alignItems: "flex-end" }}>
                เบิกเอง
              </div>
            </div>
            <div
              style={{ gridRow: "1/2", gridColumn: "5/6", padding: "5px" }}>
              <div
                style={{ height: "100%", display: "flex", alignItems: "flex-end" }}>
                เบิกไม่ได้
              </div>
            </div>
            <div
              style={{ gridRow: "1/2", gridColumn: "6/7", padding: "5px" }}>
              <div
                style={{ height: "100%", display: "flex", alignItems: "flex-end" }}>
                ส่วนลด
              </div>
            </div>
            <div
              style={{ gridRow: "1/2", gridColumn: "7/8", padding: "5px" }}>
              <div
                style={{ height: "100%", display: "flex", alignItems: "flex-end" }}>
                ผู้ป่วยชำระ
              </div>
            </div>
            <div
              style={{ textAlign: "right", gridRow: "2/3", gridColumn: "1/2", border: "solid 1px rgb(200, 200, 200, 0.5)" }}>
              {props.price_total}
            </div>
            <div
              style={{ textAlign: "right", gridRow: "2/3", gridColumn: "2/3", border: "solid 1px rgb(200, 200, 200, 0.5)" }}>
              {props.sendClaimTotal}
            </div>
            <div
              style={{ textAlign: "right", gridRow: "2/3", gridColumn: "3/4", border: "solid 1px rgb(200, 200, 200, 0.5)" }}>
              {props.absorbTotal}
            </div>
            <div
              style={{ textAlign: "right", gridRow: "2/3", gridColumn: "4/5", border: "solid 1px rgb(200, 200, 200, 0.5)" }}>
              {props.patientClaimTotal}
            </div>
            <div
              style={{ textAlign: "right", gridRow: "2/3", gridColumn: "5/6", border: "solid 1px rgb(200, 200, 200, 0.5)" }}>
              {props.nonClaimableTotal}
            </div>
            <div
              style={{ textAlign: "right", gridRow: "2/3", gridColumn: "6/7", border: "solid 1px rgb(200, 200, 200, 0.5)" }}>
              {props.discountTotal}
            </div>
            <div
              style={{ textAlign: "right", gridRow: "2/3", gridColumn: "7/8", border: "solid 1px rgb(200, 200, 200, 0.5)" }}>
              {props.payableTotal}
            </div>
          </div>
        </div>
        <div
          className="ui form"
          style={{padding: "10px 15px 5px", marginBottom: "-10px"}}>

          <FormGroup>
            <FormField
              className="noPadding --hard"
              style={{ margin: "0px 10px" }}
              width={3}>
              <label
                style={{ paddingLeft: "10px" }}>
                ราคารวม
              </label>
              <Input
                readOnly={true}
                size="small"
                value={props.price_total || ""}>
              </Input>
            </FormField>
            <FormField
              className="noPadding --hard"
              style={{ margin: "0px 10px" }}
              width={3}>
              <label
                style={{ paddingLeft: "10px" }}>
                ส่งเบิก
              </label>
              <Input
                readOnly={true}
                size="small"
                value={props.sendClaimTotal || ""}>
              </Input>
            </FormField>
            <FormField
              className="noPadding --hard"
              style={{ margin: "0px 10px" }}
              width={3}>
              <label
                style={{ paddingLeft: "10px" }}>
                absorb
              </label>
              <Input
                readOnly={true}
                size="small"
                value={props.absorbTotal || ""}>
              </Input>
            </FormField>
            <FormField
              className="noPadding --hard"
              style={{ margin: "0px 10px" }}
              width={3}>
              <label
                style={{ paddingLeft: "10px" }}>
                เบิกเอง
              </label>
              <Input
                readOnly={true}
                size="small"
                value={props.patientClaimTotal || ""}>
              </Input>
            </FormField>
            <FormField
              className="noPadding --hard"
              style={{ margin: "0px 10px" }}
              width={3}>
              <label
                style={{ paddingLeft: "10px" }}>
                 เบิกไม่ได้
              </label>
              <Input
                readOnly={true}
                value={props.nonClaimableTotal || ""}>
              </Input>
            </FormField>
            <FormField
              className="noPadding --hard"
              style={{ margin: "0px 10px" }}
              width={3}>
              <label
                style={{ paddingLeft: "10px" }}>
                ส่วนลด
              </label>
              <Input
                readOnly={true}
                size="small"
                value={props.discountTotal || ""}>
              </Input>
            </FormField>
            <FormField
              className="noPadding --hard"
              style={{ margin: "0px 10px" }}
              width={3}>
              <label
                style={{ paddingLeft: "10px" }}>
                ผู้ป่วยชำระ
              </label>
              <Input
                readOnly={true}
                size="small"
                value={props.payableTotal || ""}>
              </Input>
            </FormField>
            <FormField
              className={props.orderBy === "Item" ? "noPadding --hard" : "hidden noPadding --hard"}
              style={{ margin: "0px 10px" }}
              width={3}>
              <label
                style={{ paddingLeft: "10px" }}>
                ค้างชำระ
              </label>
              <Input
                readOnly={true}
                size="small"
                value={props.unpaidTotal || ""}>
              </Input>
            </FormField>
          </FormGroup>
        </div>
        <div
          style={{ display: "flex", padding: "5px 10px", alignItems: "center" }}>

          <Button
            className={props.orderBy === "Mode" ? "active" : ""}
            color="teal"
            id="btn-billMode"
            name="Mode"
            onClick={props.onChangeOrderBy}
            size="mini"
            style={{paddingLeft: "1.2em", paddingRight: "1.2em"}}>
            BY MODE
          </Button>
          <Button
            className={props.orderBy === "Order" ? "active" : ""}
            color="teal"
            id="btn-billOrder"
            name="Order"
            onClick={props.onChangeOrderBy}
            size="mini"
            style={{paddingLeft: "1.2em", paddingRight: "1.2em"}}>
            BY ORDER
          </Button>
          <Button
            className={props.orderBy === "Item" ? "active" : ""}
            color="teal"
            id="btn-billItem"
            name="Item"
            onClick={props.onChangeOrderBy}
            size="mini"
            style={{paddingLeft: "1.2em", paddingRight: "1.2em"}}>
            BY ITEM
          </Button>
          <div>
            {props.buttonRefresh}
          </div>
          <div
            style={{ flex: 1, ...props.displaySelect }}>
            {}
            <div
              style={{ marginLeft: "10px", display: "flex", alignItems: "center" }}>
              Select:
            </div>
            <Button
              id="btn-billAll"
              onClick={props.onClickAll}
              size="mini"
              style={{ marginLeft: "10px" }}>
              ALL
            </Button>
            <Button
              id="btn-billNone"
              onClick={props.onClickNone}
              size="mini">
              NONE
            </Button>
          </div>
          <div
            style={{ flex: 1 }}>

          </div>
          <div
            style={{ marginRight: "10px" , fontSize: "0.9rem", minWidth: "max-content", display: props.viewMode ? "none" : ""}}>
            ชื่อผู้ป่วยบนใบเสร็จ
          </div>
          <Dropdown
            onChange={props.onChangePatient}
            options={props.patientOptions}
            selection={true}
            style={{marginRight: "10px", fontSize: "0.9rem", maxWidth: "15rem", display: props.viewMode ? "none" : ""}}
            value={props.patientValue}>
          </Dropdown>
          <Button
            color="blue"
            id="btn-bilDetailReport"
            onClick={props.onPrintReceipt}
            size="mini"
            style={{paddingLeft: "1.2em", paddingRight: "1.2em"}}>
            พิมพ์รายการค้างชำระ
          </Button>
          <Button
            color="grey"
            disabled={props.viewMode || false}
            onClick={props.billPaymentTransfer}
            size="mini"
            style={{ ...(props.viewMode && { display: "none" }), ...(props.orderBy !== "Order" && { display: "none" })}}>
            โอนค่าใช้จ่าย
          </Button>
          <Button
            color="yellow"
            disabled={props.hidebpInvoice || false}
            id="btn-bilPaymentInvoice"
            onClick={props.billPaymentInvoice}
            size="mini"
            style={{...props.hidebpInvoice && {display: "none"}, paddingLeft: "1.2em", paddingRight: "1.2em"}}>
            แจ้งชำระเงิน
          </Button>
          <Button
            color="green"
            disabled={props.hidebp || false}
            id="btn-billPayment"
            onClick={props.billPayment}
            size="mini"
            style={{...props.hidebp && {display: "none"},paddingLeft: "1.2em", paddingRight: "1.2em"}}>
            รับชำระเงิน
          </Button>
        </div>
      </div>
    )
}


export default CardBillingDocumentUX

export const screenPropsDefault = {"by":"order","title":"MEDICAL FEE","titleDescription":"ข้อมูลรายการค่ารักษาพยาบาลรอรับชำระ"}

/* Date Time : Thu Nov 21 2024 14:50:43 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", flexDirection: \"column\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.viewMode ? \"none\" : \"flex\", padding: \"10px 10px 0px 10px\" , minWidth: \"max-content\", justifyContent: \"space-between\", alignItems: \"flex-start\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.orderBy == \"Mode\" ? \"block\" : \"none\", padding: \"0 10px\" }"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ height: \"8vh\" , display: \"none\"}"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", padding: \"5px 10px\", alignItems: \"center\" }"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 5,
      "name": "Table",
      "parent": 2,
      "props": {
        "className": {
          "type": "value",
          "value": "--rt-tbody-overflow-y-hidden"
        },
        "data": {
          "type": "code",
          "value": "props.invoiceItems"
        },
        "getTdProps": {
          "type": "code",
          "value": "props.onSelectedFaxClaim"
        },
        "getTrProps": {
          "type": "code",
          "value": ""
        },
        "headers": {
          "type": "value",
          "value": "รหัสหมวด,ชื่อหมวดค่ารักษาพยาบาล, ราคา, ส่งเบิก, absorb, เบิกเอง, เบิกไม่ได้, ส่วนลด, ผู้ป่วยชำระ"
        },
        "keys": {
          "type": "value",
          "value": "bill_mode__code, bill_mode__name, price__sum, send_claim__sum, absorb__sum, patient_claim__sum, non_claimable__sum, discount__sum, payable__sum"
        },
        "minRows": {
          "type": "value",
          "value": "10"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"50vh\" }"
        },
        "widths": {
          "type": "value",
          "value": "100"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.title"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"1.1rem\", fontWeight: \"bold\",display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "label": "divgrid",
      "name": "div",
      "parent": 3,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(2, 1fr)\",\"gridTemplateColumns\":\"repeat(7, 1fr)\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"1/2\", padding: \"5px\" }"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 8,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"2/3\", padding: \"5px\" }"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 8,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"3/4\", padding: \"5px\" }"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 8,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"4/5\", padding: \"5px\" }"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 8,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"5/6\", padding: \"5px\" }"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 8,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"6/7\", padding: \"5px\" }"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 8,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"7/8\", padding: \"5px\" }"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "code",
          "value": "props.price_total"
        },
        "style": {
          "type": "code",
          "value": "{ textAlign: \"right\", gridRow: \"2/3\", gridColumn: \"1/2\", border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "code",
          "value": "props.sendClaimTotal"
        },
        "style": {
          "type": "code",
          "value": "{ textAlign: \"right\", gridRow: \"2/3\", gridColumn: \"2/3\", border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "code",
          "value": "props.absorbTotal"
        },
        "style": {
          "type": "code",
          "value": "{ textAlign: \"right\", gridRow: \"2/3\", gridColumn: \"3/4\", border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientClaimTotal"
        },
        "style": {
          "type": "code",
          "value": "{ textAlign: \"right\", gridRow: \"2/3\", gridColumn: \"4/5\", border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "code",
          "value": "props.nonClaimableTotal"
        },
        "style": {
          "type": "code",
          "value": "{ textAlign: \"right\", gridRow: \"2/3\", gridColumn: \"5/6\", border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "code",
          "value": "props.discountTotal"
        },
        "style": {
          "type": "code",
          "value": "{ textAlign: \"right\", gridRow: \"2/3\", gridColumn: \"6/7\", border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "ราคารวม"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"100%\", display: \"flex\", alignItems: \"flex-end\" }"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "ส่งเบิก"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"100%\", display: \"flex\", alignItems: \"flex-end\" }"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": "absorb"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"100%\", display: \"flex\", alignItems: \"flex-end\" }"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "เบิกเอง"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"100%\", display: \"flex\", alignItems: \"flex-end\" }"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": "เบิกไม่ได้"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"100%\", display: \"flex\", alignItems: \"flex-end\" }"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": "ส่วนลด"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"100%\", display: \"flex\", alignItems: \"flex-end\" }"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ป่วยชำระ"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"100%\", display: \"flex\", alignItems: \"flex-end\" }"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "code",
          "value": "props.payableTotal"
        },
        "style": {
          "type": "code",
          "value": "{ textAlign: \"right\", gridRow: \"2/3\", gridColumn: \"7/8\", border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "Button",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "BY MODE"
        },
        "className": {
          "type": "code",
          "value": "props.orderBy === \"Mode\" ? \"active\" : \"\""
        },
        "color": {
          "type": "value",
          "value": "teal"
        },
        "id": {
          "type": "value",
          "value": "btn-billMode"
        },
        "name": {
          "type": "value",
          "value": "Mode"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeOrderBy"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"1.2em\", paddingRight: \"1.2em\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "Button",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "BY ORDER"
        },
        "className": {
          "type": "code",
          "value": "props.orderBy === \"Order\" ? \"active\" : \"\""
        },
        "color": {
          "type": "value",
          "value": "teal"
        },
        "id": {
          "type": "value",
          "value": "btn-billOrder"
        },
        "name": {
          "type": "value",
          "value": "Order"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeOrderBy"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"1.2em\", paddingRight: \"1.2em\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "Button",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "BY ITEM"
        },
        "className": {
          "type": "code",
          "value": "props.orderBy === \"Item\" ? \"active\" : \"\""
        },
        "color": {
          "type": "value",
          "value": "teal"
        },
        "id": {
          "type": "value",
          "value": "btn-billItem"
        },
        "name": {
          "type": "value",
          "value": "Item"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeOrderBy"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"1.2em\", paddingRight: \"1.2em\"}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ flex: 1 }"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อผู้ป่วยบนใบเสร็จ"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" , fontSize: \"0.9rem\", minWidth: \"max-content\", display: props.viewMode ? \"none\" : \"\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "Dropdown",
      "parent": 4,
      "props": {
        "compact": {
          "type": "code",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangePatient"
        },
        "options": {
          "type": "code",
          "value": "props.patientOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"10px\", fontSize: \"0.9rem\", maxWidth: \"15rem\", display: props.viewMode ? \"none\" : \"\"}"
        },
        "value": {
          "type": "code",
          "value": "props.patientValue"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "Button",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์รายการค้างชำระ"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "id": {
          "type": "value",
          "value": "btn-bilDetailReport"
        },
        "onClick": {
          "type": "code",
          "value": "props.onPrintReceipt"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{paddingLeft: \"1.2em\", paddingRight: \"1.2em\"}"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "Button",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "แจ้งชำระเงิน"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "disabled": {
          "type": "code",
          "value": "props.hidebpInvoice || false"
        },
        "id": {
          "type": "value",
          "value": "btn-bilPaymentInvoice"
        },
        "onClick": {
          "type": "code",
          "value": "props.billPaymentInvoice"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{...props.hidebpInvoice && {display: \"none\"}, paddingLeft: \"1.2em\", paddingRight: \"1.2em\"}"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "Button",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "รับชำระเงิน"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.hidebp || false"
        },
        "id": {
          "type": "value",
          "value": "btn-billPayment"
        },
        "onClick": {
          "type": "code",
          "value": "props.billPayment"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{...props.hidebp && {display: \"none\"},paddingLeft: \"1.2em\", paddingRight: \"1.2em\"}"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.orderBy == \"Order\" ? \"block\" : \"none\", padding: \"0 10px\" }"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 40,
      "name": "Table",
      "parent": 39,
      "props": {
        "className": {
          "type": "value",
          "value": "--rt-tbody-overflow-y-hidden"
        },
        "data": {
          "type": "code",
          "value": "props.invoiceItems"
        },
        "getTdProps": {
          "type": "code",
          "value": ""
        },
        "getTrProps": {
          "type": "code",
          "value": "props.byOrderRowProps"
        },
        "headers": {
          "type": "code",
          "value": "`${props.viewMode ? \"\" : \"...,\"}Encounter,แผนก,ชื่อรายการคำสั่ง,ประเภท,วันที่และเวลาค่าใช้จ่าย,ราคา, ส่งเบิก, absorb,เบิกเอง, เบิกไม่ได้,ส่วนลด,ผู้ป่วยชำระ, ประเภทค่าใช้จ่าย,สถานะค่าใช้จ่าย`"
        },
        "keys": {
          "type": "code",
          "value": "`${props.viewMode ? \"\": \"chk,\"}encounter__number,division__name,display_name,encounter__type,created,price__sum,send_claim__sum, absorb__sum,patient_claim__sum,non_claimable__sum,discount__sum,payable__sum,type__name,status__name`"
        },
        "minRows": {
          "type": "value",
          "value": "10"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"50vh\", ...(props.tableStyle || {})}"
        },
        "widths": {
          "type": "code",
          "value": "props.viewMode ? \"\" : \"60,100\""
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.orderBy == \"Item\" ? \"block\" : \"none\", padding: \"0 10px\" }"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 42,
      "name": "Table",
      "parent": 41,
      "props": {
        "className": {
          "type": "value",
          "value": "--rt-tbody-overflow-y-hidden"
        },
        "columns": {
          "type": "code",
          "value": ""
        },
        "data": {
          "type": "code",
          "value": "props.invoiceItems"
        },
        "getTdProps": {
          "type": "code",
          "value": "props.onSelectedFaxClaim"
        },
        "getTrProps": {
          "type": "code",
          "value": ""
        },
        "headers": {
          "type": "code",
          "value": "`${props.viewMode ? \"\": \"...,\"}Encounter,รหัสหมวด, รหัสค่ารักษา, ชื่อรายการค่ารักษาพยาบาล, วันที่และเวลาค่าใช้จ่าย, จำนวน, ราคาต่อหน่วย, ราคา, ส่งเบิก, absorb, เบิกเอง, เบิกไม่ได้, ส่วนลด, ผู้ป่วยชำระ, ค้างชำระ, ส่วนลดสิทธิ, รหัสส่วนลด,`"
        },
        "id": {
          "type": "value",
          "value": "tb-billInvoiceItem"
        },
        "keys": {
          "type": "code",
          "value": "`${props.viewMode ? \"\" : \"chk,\"}encounter__number,mode_code, code, product, createdDate, quantity, unitprice, price, send_claim, absorb, patient_claim, non_claimable, discount, pay, unpaid, drg_discount_amount, discount_code,delete`"
        },
        "minRows": {
          "type": "value",
          "value": "10"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"50vh\", ...(props.tableStyle || {})}"
        },
        "widths": {
          "type": "code",
          "value": "props.viewMode ? \"\" : \"60,100,100,100,140\""
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ flex: 1, ...props.displaySelect }"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "div",
      "parent": 46,
      "props": {
        "children": {
          "type": "value",
          "value": "Select:"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"10px\", display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "Button",
      "parent": 46,
      "props": {
        "children": {
          "type": "value",
          "value": "ALL"
        },
        "id": {
          "type": "value",
          "value": "btn-billAll"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickAll"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"10px\" }"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "Button",
      "parent": 46,
      "props": {
        "children": {
          "type": "value",
          "value": "NONE"
        },
        "id": {
          "type": "value",
          "value": "btn-billNone"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickNone"
        },
        "size": {
          "type": "value",
          "value": "mini"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขที่ใบเสร็จ : "
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"15px\", display: \"none\"}"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0px 10px 10px 10px\", display: props.viewMode ? \"none\" : \"\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "hr",
      "parent": 53,
      "props": {
      },
      "seq": 54,
      "void": true
    },
    {
      "from": null,
      "id": 55,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"10px 15px 5px\", marginBottom: \"-10px\"}"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 56,
      "name": "FormGroup",
      "parent": 55,
      "props": {
      },
      "seq": 56,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "FormField",
      "parent": 56,
      "props": {
        "className": {
          "type": "value",
          "value": "noPadding --hard"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 10px\" }"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "FormField",
      "parent": 56,
      "props": {
        "className": {
          "type": "value",
          "value": "noPadding --hard"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 10px\" }"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "FormField",
      "parent": 56,
      "props": {
        "className": {
          "type": "value",
          "value": "noPadding --hard"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 10px\" }"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "FormField",
      "parent": 56,
      "props": {
        "className": {
          "type": "value",
          "value": "noPadding --hard"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 10px\" }"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "FormField",
      "parent": 56,
      "props": {
        "className": {
          "type": "value",
          "value": "noPadding --hard"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 10px\" }"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "FormField",
      "parent": 56,
      "props": {
        "className": {
          "type": "value",
          "value": "noPadding --hard"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 10px\" }"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "label",
      "parent": 57,
      "props": {
        "children": {
          "type": "value",
          "value": "ราคารวม"
        },
        "style": {
          "type": "code",
          "value": "{ paddingLeft: \"10px\" }"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": null,
      "id": 64,
      "name": "label",
      "parent": 58,
      "props": {
        "children": {
          "type": "value",
          "value": "ส่งเบิก"
        },
        "style": {
          "type": "code",
          "value": "{ paddingLeft: \"10px\" }"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "label",
      "parent": 59,
      "props": {
        "children": {
          "type": "value",
          "value": "absorb"
        },
        "style": {
          "type": "code",
          "value": "{ paddingLeft: \"10px\" }"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": null,
      "id": 66,
      "name": "label",
      "parent": 60,
      "props": {
        "children": {
          "type": "value",
          "value": "เบิกเอง"
        },
        "style": {
          "type": "code",
          "value": "{ paddingLeft: \"10px\" }"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "label",
      "parent": 61,
      "props": {
        "children": {
          "type": "value",
          "value": "ส่วนลด"
        },
        "style": {
          "type": "code",
          "value": "{ paddingLeft: \"10px\" }"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "label",
      "parent": 62,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ป่วยชำระ"
        },
        "style": {
          "type": "code",
          "value": "{ paddingLeft: \"10px\" }"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "Input",
      "parent": 57,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "value": {
          "type": "code",
          "value": "props.price_total || \"\""
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "Input",
      "parent": 58,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "value": {
          "type": "code",
          "value": "props.sendClaimTotal || \"\""
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "Input",
      "parent": 59,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "value": {
          "type": "code",
          "value": "props.absorbTotal || \"\""
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "Input",
      "parent": 60,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "value": {
          "type": "code",
          "value": "props.patientClaimTotal || \"\""
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "Input",
      "parent": 61,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "value": {
          "type": "code",
          "value": "props.discountTotal || \"\""
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "Input",
      "parent": 62,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "value": {
          "type": "code",
          "value": "props.payableTotal || \"\""
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "FormField",
      "parent": 56,
      "props": {
        "className": {
          "type": "value",
          "value": "noPadding --hard"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 10px\" }"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "label",
      "parent": 75,
      "props": {
        "children": {
          "type": "value",
          "value": " เบิกไม่ได้"
        },
        "style": {
          "type": "code",
          "value": "{ paddingLeft: \"10px\" }"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 77,
      "name": "Input",
      "parent": 75,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.nonClaimableTotal || \"\""
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "Label",
      "parent": 6,
      "props": {
        "children": {
          "type": "code",
          "value": "props.receiptCode"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"5px\"}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 79,
      "name": "Button",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "โอนค่าใช้จ่าย"
        },
        "color": {
          "type": "value",
          "value": "grey"
        },
        "disabled": {
          "type": "code",
          "value": "props.viewMode || false"
        },
        "onClick": {
          "type": "code",
          "value": "props.billPaymentTransfer"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{ ...(props.viewMode && { display: \"none\" }), ...(props.orderBy !== \"Order\" && { display: \"none\" })}"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 80,
      "name": "FormField",
      "parent": 56,
      "props": {
        "className": {
          "type": "code",
          "value": "props.orderBy === \"Item\" ? \"noPadding --hard\" : \"hidden noPadding --hard\""
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 10px\" }"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "label",
      "parent": 80,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้างชำระ"
        },
        "style": {
          "type": "code",
          "value": "{ paddingLeft: \"10px\" }"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "Input",
      "parent": 80,
      "props": {
        "readOnly": {
          "type": "code",
          "value": "true"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "value": {
          "type": "code",
          "value": "props.unpaidTotal || \"\""
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 84,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonRefresh"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{margin: \"-0.5rem 1rem -0.15rem\", display: props.showFilter? \"\": \"none\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 86,
      "name": "Form",
      "parent": 85,
      "props": {
        "className": {
          "type": "value",
          "value": "size small"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 87,
      "name": "FormGroup",
      "parent": 86,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 88,
      "name": "FormField",
      "parent": 87,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 89,
      "name": "FormField",
      "parent": 87,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 90,
      "name": "FormField",
      "parent": 87,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 91,
      "name": "FormField",
      "parent": 87,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 92,
      "name": "FormField",
      "parent": 87,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 93,
      "name": "FormField",
      "parent": 87,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 94,
      "name": "Checkbox",
      "parent": 88,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.medicalFeeFilter?.isDivision || false"
        },
        "disabled": {
          "type": "code",
          "value": "[\"Mode\", \"Item\"].includes(props.orderBy || \"\")"
        },
        "label": {
          "type": "value",
          "value": "แผนก"
        },
        "name": {
          "type": "value",
          "value": "isDivision"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 95,
      "name": "Checkbox",
      "parent": 90,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.medicalFeeFilter?.isDate|| false"
        },
        "disabled": {
          "type": "code",
          "value": "props.orderBy === \"Mode\""
        },
        "label": {
          "type": "value",
          "value": "วันที่ค่าใช้จ่าย"
        },
        "name": {
          "type": "value",
          "value": "isDate"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 96,
      "name": "Dropdown",
      "parent": 89,
      "props": {
        "className": {
          "type": "value",
          "value": "inline-label"
        },
        "disabled": {
          "type": "code",
          "value": "!props.medicalFeeFilter?.isDivision || [\"Mode\", \"Item\"].includes(props.orderBy || \"\")"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "division"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "options": {
          "type": "code",
          "value": "props.divisionOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.medicalFeeFilter?.division || \"\""
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": null,
      "id": 100,
      "name": "div",
      "parent": 93,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearch"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 101,
      "name": "FormField",
      "parent": 87,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 102,
      "name": "FormField",
      "parent": 87,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 103,
      "name": "Checkbox",
      "parent": 101,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.medicalFeeFilter?.isEncounter|| false"
        },
        "disabled": {
          "type": "code",
          "value": "[\"Mode\"].includes(props.orderBy || \"\")"
        },
        "label": {
          "type": "value",
          "value": "Encounter"
        },
        "name": {
          "type": "value",
          "value": "isEncounter"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 104,
      "name": "Dropdown",
      "parent": 102,
      "props": {
        "className": {
          "type": "value",
          "value": "inline-label"
        },
        "defaultValue": {
          "type": "value",
          "value": ""
        },
        "disabled": {
          "type": "code",
          "value": "!props.medicalFeeFilter?.isEncounter || [\"Mode\"].includes(props.orderBy || \"\")"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "encounter"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "options": {
          "type": "code",
          "value": "props.encounterNumberOptions"
        },
        "placeholder": {
          "type": "value",
          "value": ""
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.medicalFeeFilter?.encounter|| \"\""
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": null,
      "id": 105,
      "name": "div",
      "parent": 92,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": null,
      "id": 106,
      "name": "div",
      "parent": 91,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 107,
      "name": "DateTextBox",
      "parent": 106,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.medicalFeeFilter?.isDate || props.orderBy === \"Mode\""
        },
        "onChange": {
          "type": "code",
          "value": "(value)=>props.onChangeFilter?.(null, {name: \"startDate\", value})"
        },
        "value": {
          "type": "code",
          "value": "props.medicalFeeFilter?.startDate|| \"\""
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 108,
      "name": "DateTextBox",
      "parent": 105,
      "props": {
        "disabled": {
          "type": "code",
          "value": "!props.medicalFeeFilter?.isDate || props.orderBy === \"Mode\""
        },
        "onChange": {
          "type": "code",
          "value": "(value)=>props.onChangeFilter?.(null, {name: \"endDate\", value})"
        },
        "value": {
          "type": "code",
          "value": "props.medicalFeeFilter?.endDate|| \"\""
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": null,
      "id": 110,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "code",
          "value": "props.lockingStatusMessage"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.5rem\"}"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": null,
      "id": 111,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "code",
          "value": "props.titleDescription"
        },
        "style": {
          "type": "code",
          "value": "{ fontSize: \"1.1rem\", fontWeight: \"bold\" }"
        }
      },
      "seq": 111,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardBillingDocumentUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "by": "order",
    "title": "MEDICAL FEE",
    "titleDescription": "ข้อมูลรายการค่ารักษาพยาบาลรอรับชำระ"
  },
  "width": 80
}

*********************************************************************************** */
