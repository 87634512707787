import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import CardLayout from "../common/CardLayout";
import { Button, Grid, Table, Label, Image } from "semantic-ui-react";
import { formatDatetime } from "../../utils";
import * as Util from "react-lib/utils";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";

const styles = {
  content: {
    maxHeight: 480,
    overflowY: "auto",
  },
  headerFont: {
    marginTop: "1%",
  },
  headerLabel: {
    float: "right",
    display: "block",
    marginRight: "1%",
    color: "black",
    width: "max-content",
  },
};

const SubOperativeDiagnosis = (props) => {
  return (
    <>
      {props.diagList.map((diag, index) => {
        return (
          <div key={`diag${index}`}>
            {diag.icd_code};{diag.icd_term};{diag.medical_description}
          </div>
        );
      })}
      {props.diagListSecondary ? (
        props.diagListSecondary.map((diag, index) => {
          return (
            <div key={`diag_sec${index}`}>
              {diag.icd_code};{diag.icd_term};{diag.medical_description}
            </div>
          );
        })
      ) : (
        <div />
      )}
    </>
  );
};

const SubOperation = (props) => {
  return (
    <>
      {props.procedureList.map((items, index) => {
        if (!items.icd9cm_code && !items.icd9cm_term) {
          return <div key={`procedure${index}`} />;
        } else {
          return (
            <div key={`procedure${index}`}>
              {`[${items.icd9cm_code}]  ${items.icd9cm_term}`}
            </div>
          );
        }
      })}
    </>
  );
};

const SubOperationTreatment = (props) => {
  return (
    <>
      {props.treatmentList.map((items, index) => {
        if (!items.name_code) {
          return <div key={`treatment${index}`} />;
        } else {
          return (
            <div key={`treatment${index}`}>
              {items.name_code}
            </div>
          );
        }
      })}
    </>
  );
};

const SubOperativeImage = (props) => {
  const isMounted = useRef(true);

  const [images, setImages] = useState([]);

  useEffect(() => {
    const getOpImage = async () => {
      const [
        response,
        error,
        network,
      ] = await props.controller.getOperativeImage(props.opNoteId);
      if (isMounted.current) {
        if (error) {
          toast.error(error);
        } else {
          setImages(response.items);
        }
      }
    };

    if (props.opNoteId) {
      getOpImage();
    }
  }, [props.opNoteId]);

  return (
    <>
      {images.length > 0 && (
        <Grid container columns={3}>
          {images.map((image, index) => {
            return (
              <Grid.Column key={`image${index}`}>
                <Image
                  src={image.image}
                  as="a"
                  size="medium"
                  href={image.image}
                  target="_blank"
                />
              </Grid.Column>
            );
          })}
        </Grid>
      )}
    </>
  );
};

const SubOperativeNote = (props) => {
  const handlePrintTeam = (team) => {
    props.onPrint(team.id);
  };

  return (
    <>
      {props.data.surgery_teams.map((team, index) => {
        let specimenText = "";
        if (team?.specimen?.checked) {
          specimenText += "YES:";
          if (team.specimen.isCyto) {
            specimenText += ` CYTO ${team.specimen.cyto} ขวด`;
          }
          if (team.specimen.isPatho) {
            specimenText += ` PATHO ${team.specimen.patho} ชิ้น`;
          }
        } else {
          specimenText = "N/A";
        }

        return (
          <div key={`or${index}`}>
            <Button
              color="orange"
              floated="right"
              onClick={(event) => {
                handlePrintTeam(team);
              }}
            >
              พิมพ์ Operative Note
            </Button>

            <div style={{ display: "flex"}}>
              <div style={{ width: "max-content" ,fontWeight: "bold", fontSize: "large"}}>
                {team.chief_surgeon}
              </div>
              <div style={{flexGrow: 1, marginLeft: "20px"}}>
                {props.data.pre_operation_summary.split('\n').map( str => <p> {str}</p>)}
              </div>
              <div style={{marginRight: "20px", marginTop: "4px"}}>
                <Label style={styles.headerLabel}>
                  {formatDatetime( team.edited )}
                </Label>
              </div>
            </div>


            <Table celled structured>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={3}>
                    Date of Operation
                  </Table.HeaderCell>
                  <Table.Cell colSpan={3}>
                    {props.data.incision_date}
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.HeaderCell width={3}>Operative Time</Table.HeaderCell>
                  <Table.Cell colSpan={3}>
                    {`${props.data.incision_datetime} - ${
                      props.data.closure_datetime || ""
                    } ${props.data.operation_time || ""}`}
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.HeaderCell width={3}>Swab/Instrument</Table.HeaderCell>
                  <Table.Cell colSpan={3}>
                    {team.swab_instrument_status_label}
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.HeaderCell width={3}>Surgery Team</Table.HeaderCell>
                  <Table.Cell colSpan={3}>
                    <Table celled>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell width={4}>
                            Chief Surgeon
                          </Table.HeaderCell>
                          <Table.HeaderCell width={4}>
                            Assistant Surgeon
                          </Table.HeaderCell>
                          <Table.HeaderCell width={4}>
                            Scrub Nurse
                          </Table.HeaderCell>
                          <Table.HeaderCell width={4}>
                            Circulating Nurse
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell>{team.chief_surgeon}</Table.Cell>
                          <Table.Cell>
                            {team.assistant_surgeons_summary}
                          </Table.Cell>
                          <Table.Cell>{team.scrub_nurse_summary}</Table.Cell>
                          <Table.Cell>
                            {team.circulating_nurse_summary}
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.HeaderCell width={3}>Anesthesia Team</Table.HeaderCell>
                  <Table.Cell colSpan={3}>
                    <Table celled>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell width={8}>
                            Anesthesiologist
                          </Table.HeaderCell>
                          <Table.HeaderCell width={8}>
                            Anesthetist
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell>
                            {team.anesthesiologist_summary}
                          </Table.Cell>
                          <Table.Cell>{team.anesthetist_summary}</Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.HeaderCell width={3}>
                    Pre-oprative Diagnosis
                  </Table.HeaderCell>
                  <Table.Cell width={5}>
                    <SubOperativeDiagnosis
                      diagList={team.pre_principal_diagnosis}
                      diagListSecondary={team.pre_secondary_diagnosis}
                      languageUX={props.languageUX}
                    />
                  </Table.Cell>
                  <Table.HeaderCell width={3}>
                    Post-oprative Diagnosis
                  </Table.HeaderCell>
                  <Table.Cell width={5}>
                    <SubOperativeDiagnosis
                      diagList={team.post_principal_diagnosis}
                      diagListSecondary={team.post_secondary_diagnosis}
                      languageUX={props.languageUX}
                    />
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.HeaderCell width={3}>Operation</Table.HeaderCell>
                  <Table.Cell colSpan={3}>
                    {team.post_operating_order_item
                      ? team.post_operating_order_item.name_code
                      : ""}
                    {team.post_operating_order_item &&
                      team.post_operating_order_item.procedures && (
                        <div>
                          <SubOperationTreatment
                            treatmentList={
                              team.post_operating_order_item.treatment
                            }
                            languageUX={props.languageUX}
                          />
                          <SubOperation
                            procedureList={
                              team.post_operating_order_item.procedures
                            }
                            languageUX={props.languageUX}
                          />
                        </div>
                      )}
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.HeaderCell width={3}>Implant</Table.HeaderCell>
                  <Table.Cell colSpan={3}>
                    {team?.implant?.post?.checked
                      ? `YES: ${team.implant.post.value}`
                      : "N/A"}
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.HeaderCell width={3}>
                    Specific Concern
                  </Table.HeaderCell>
                  <Table.Cell colSpan={3}>
                    {team?.specific_concern?.checked
                      ? `YES: ${team.specific_concern.value}`
                      : "N/A"}
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.HeaderCell width={3}>Type of wound</Table.HeaderCell>
                  <Table.Cell colSpan={3}>{team.type_of_wound}</Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.HeaderCell width={3}>Specimen</Table.HeaderCell>
                  <Table.Cell colSpan={3}>{specimenText}</Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.HeaderCell width={3}>Blood Loss (ml.)</Table.HeaderCell>
                  <Table.Cell colSpan={3}>{team.blood_loss}</Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.HeaderCell width={3}>Tourniquet</Table.HeaderCell>
                  <Table.Cell colSpan={3}>
                    {team?.tourniquet?.checked
                      ? `YES: ${team.tourniquet.value}`
                      : "N/A"}
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.HeaderCell width={3}>Procedure</Table.HeaderCell>
                  <Table.Cell colSpan={3}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: team.operative_note.replace(/\n/g, "<br/>"),
                      }}
                      style={{ wordBreak: "break-word" }}
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Header>
            </Table>
            <SubOperativeImage
              controller={props.controller}
              opNoteId={team.operative_note_id}
              languageUX={props.languageUX}
            />
          </div>
        );
      })}
    </>
  );
};

const CardOperativeNoteView = forwardRef((props, ref) => {
  const isMounted = useRef(true);

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  useImperativeHandle(ref, () => ({
    callGetOperativeNote: () => {
      return handleGetOperativeNote();
    },
  }));

  const handleGetOperativeNote = async () => {
    if (!props.encounterId) {
      return;
    }
    setIsLoading(true);
    const [
      response,
      error,
      numberOfPage,
    ] = await props.controller.getOperativeNote({
      encounterId: props.encounterId,
      startDate: props.startDate,
      endDate: props.endDate,
    });
    if (isMounted) {
      if (error) {
        toast.error(error);
      } else {
        setData(response);
      }
      setIsLoading(false);
    }
  };

  const handlePrintOperativeNote = async (teamId) => {
    if (!teamId) {
      return;
    }

    setIsLoading(true);
    const [
      response,
      error,
      network,
    ] = await props.controller.printOperativeNote(teamId);
    if (isMounted) {
      if (error) {
        toast.error(error);
      } else {
        Util.displayPDF(response.pdf_b64data);
      }
      setIsLoading(false);
    }
  };

  return (
    <CardLayout
      titleText="Operative Note"
      headerColor="pink"
      closeable={props.closeable}
      toggleable={props.toggleable}
      defaultBackground={props.defaultBackground}
      hideHeaderIcon={props.hideHeaderIcon}
      loading={isLoading}
    >
      {!!(data.length == 0) ? (
        "No Data."
      ) : (
        <div style={styles.content}>
          {data.map((opData, index) => {
            return (
              <SubOperativeNote
                key={`subNote${index}`}
                controller={props.controller}
                data={opData}
                onPrint={handlePrintOperativeNote}
                languageUX={props.languageUX}
              />
            );
          })}
        </div>
      )}
    </CardLayout>
  );
});

CardOperativeNoteView.defaultProps = {
  closeable: true,
  toggleable: true,
  defaultBackground: true,
  hideHeaderIcon: false,

  controller: null,

  encounterId: null,
  startDate: null,
  endDate: null,
};

export default CardOperativeNoteView;
