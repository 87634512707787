import React, { useState, useEffect, SetStateAction } from "react";
import { Form, Dropdown, Button, Checkbox } from "semantic-ui-react";
import CardLayout from "./CardLayout";
import ReactTable from "react-table-6";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

const CardDoctorSearch = ({
  preData,
  hideCallBack,
  getSearchDoctor,
  getSpecialitiesList,
  specialitiesList,
  doctorList,
  onSelectDoctor,
  doctorListLoading,
  numberOfPage,
  currentPage,
  onPageChange
}: any) => {
  const intl = useIntl();
  const [togDoctorName, setTogDoctorName] = useState(true);
  const [togSpeciality, setTogSpeciality] = useState(false);

  const [doctorName, setDoctorName] = useState("");
  const [speciality, setSpeciality] = useState(null);

  const [clickTimeout, setClickTimeout] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [doctorSelected, setDoctorSelected] = useState(null);

  useEffect(() => {
    getSpecialitiesList();
  }, []);

  useEffect(() => {
    setDoctorName(preData)
    getSearchDoctor(preData, null);
  }, [preData])

  const handleToggleName = (e: any, { checked }: any) => {
    if (!checked) {
      setDoctorName("");
    }
    setTogDoctorName(checked);
  };

  const handleToggleSpecility = (e: any, { checked }: any) => {
    if (!checked) {
      setSpeciality(null);
    }
    setTogSpeciality(checked);
  };

  const handleOnChangeDoctorName = (e: any) => {
    setDoctorName(e.target.value);
  };

  const handleOnChangeSpeciality = (e: any, { value }: any) => {
    setSpeciality(value);
  };

  const handleOnSearchDoctor = async () => {
    setIsLoading(true);
    await getSearchDoctor(doctorName, speciality);
    setIsLoading(false);
  };

  const handlePageChange = async (page: any) => {
    setIsLoading(true);
    await onPageChange(doctorName, speciality, page + 1);
    setIsLoading(false);
  };

  const handleSelectClick = () => {
    if (doctorSelected) {
      onSelectDoctor(
        (doctorSelected as any).original.id,
        (doctorSelected as any).original.full_name
      );
    }
    hideCallBack();
  }

  return (
    <CardLayout
      titleText="ค้นหาแพทย์"
      onClose={hideCallBack}
      toggleable={false}
      loading={isLoading}
    >
      <Form>
        <Form.Group inline>
          <Form.Checkbox
            toggle
            checked={togDoctorName}
            onChange={handleToggleName}
          />
          <Form.Field>
            <b>{intl.formatMessage({ id: "ชื่อ / รหัส" })}</b>
          </Form.Field>
          <Form.Input
            width={6}
            disabled={!togDoctorName}
            value={doctorName}
            onChange={handleOnChangeDoctorName}
          ></Form.Input>
          <Form.Checkbox
            toggle
            checked={togSpeciality}
            onChange={handleToggleSpecility}
          />
          <Form.Field>
            <b>Speciality</b>
          </Form.Field>
          <Dropdown
            search
            selection
            disabled={!togSpeciality}
            value={speciality as any}
            options={specialitiesList}
            onChange={handleOnChangeSpeciality}
          ></Dropdown>
          <Form.Field />
          <Form.Button color="blue" onClick={handleOnSearchDoctor}>
            ค้นหา
          </Form.Button>
        </Form.Group>
      </Form>

      <ReactTable
        style={{ height: 400, backgroundColor: "#FFFFFF" }}
        manual
        data={doctorList}
        loading={isLoading}
        showPageSizeOptions={false}
        page={currentPage - 1}
        pages={numberOfPage}
        onPageChange={handlePageChange}
        getNoDataProps={() => {
          return { style: { display: "none" } };
        }}
        getTrProps={(state: any, rowInfo: any) => {
          if (rowInfo && rowInfo.row) {
            let background =
              rowInfo.index === (doctorSelected ? (doctorSelected as any).index : null)
                ? "#CCE2FF"
                : "white";
            return {
              onClick: (e: any) => {
                setDoctorSelected(rowInfo);
                if (clickTimeout !== null) {
                  if (
                    (doctorSelected ? (doctorSelected as any).index : null) ===
                    rowInfo.index
                  ) {
                    clearTimeout(clickTimeout as any);
                    setClickTimeout(null);
                    onSelectDoctor(
                      rowInfo.original.id,
                      rowInfo.original.full_name
                    );
                    hideCallBack();
                  }
                } else {
                  setClickTimeout(
                    setTimeout(() => {
                      clearTimeout(clickTimeout as any);
                      setClickTimeout(null);
                    }, 500) as any
                  );
                }
              },
              style: {
                background: background
              }
            };
          } else {
            return {};
          }
        }}
        columns={[
          {
            Header: "Name",
            accessor: "name_code"
          },
          {
            Header: "Specialty",
            accessor: "specialty",
            resizable: false
          }
        ]}
      ></ReactTable>
      <br />
      <Button
        color="blue"
        onClick={handleSelectClick}
      >{intl.formatMessage({ id: "เลือก" })}</Button>
    </CardLayout>
  );
};

CardDoctorSearch.defaultProps = {
  preData: "",
  specialitiesList: [],
  doctorSearchList: [],
  doctorList: [],

  hideCallBack: () => { },
  getSpecialitiesList: () => { },
  getdoctorSearchList: () => { },
  onSelectDoctor: () => { },
  getSearchDoctor: () => { },
  onPageChange: () => { },
};

CardDoctorSearch.propTypes = {
  preData: PropTypes.string,
  specialitiesList: PropTypes.array,
  doctorSearchList: PropTypes.array,
  doctorList: PropTypes.array,

  hideCallBack: PropTypes.func,
  getSpecialitiesList: PropTypes.func,
  getdoctorSearchList: PropTypes.func,
  onSelectDoctor: PropTypes.func,
  getSearchDoctor: PropTypes.func,
  onPageChange: PropTypes.func,
};

export default React.memo(CardDoctorSearch);
