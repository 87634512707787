import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Input,
  Dropdown,
  Button
} from 'semantic-ui-react'

const ModAllergyUX = (props: any) => {
    return(
      <div>
        <div
          className="ui accordion fluid raised segment"
          style={{boxShadow: "none"}}>
          
          <div
            style={{display: "flex", justifyContent: "space-between"}}>
            
            <label
              style={{fontSize: "1.2rem", fontWeight: "bold"}}>
              ประวัติอาการไม่พึงประสงค์จากการใช้ยาของผู้ป่วย
            </label>
            <div
              style={{cursor: "pointer"}}>
              {}
            </div>
          </div>
          <div
            className="ui divider">
            
          </div>
          <div>
            
            <div
              style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(3, 1fr)","gridTemplateColumns":"repeat(50, 1fr)"}}>
              <div
                style={{  gridRow: "1/2", gridColumn: "1/10",  }}>
                การซักประวัติ
              </div>
              <div
                style={{  gridRow: "1/2", gridColumn: "32/40",  }}>
                ที่มาของข้อมูล
              </div>
              <div
                style={{  gridRow: "2/3", gridColumn: "1/31",  }}>
                
                <Input
                  fluid={true}
                  onChange={props?.onChangeNote("newNote")}
                  value={props?.note}>
                </Input>
              </div>
              <div
                style={{  gridRow: "2/3", gridColumn: "32/45",  }}>
                
                <Dropdown
                  fluid={true}
                  options={props?.options}
                  selection={true}>
                </Dropdown>
              </div>
              <div
                style={{  gridRow: "2/3", gridColumn: "46/50",  }}>
                
                <Button
                  color="green"
                  fluid={true}
                  onClick={props?.OnAddADR}>
                  เพิ่ม
                </Button>
              </div>
            </div>
          </div>
          <div>
            {props?.AllergyList}
          </div>
        </div>
      </div>
    )
}

export default ModAllergyUX

export const screenPropsDefault = {}

/* Date Time : Thu Jul 14 2022 18:10:28 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui accordion fluid raised segment"
        },
        "style": {
          "type": "code",
          "value": "{boxShadow: \"none\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "label",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติอาการไม่พึงประสงค์จากการใช้ยาของผู้ป่วย"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.2rem\", fontWeight: \"bold\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{cursor: \"pointer\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "label": "divgrid",
      "name": "div",
      "parent": 7,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(3, 1fr)\",\"gridTemplateColumns\":\"repeat(50, 1fr)\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "การซักประวัติ"
        },
        "style": {
          "type": "code",
          "value": "{  gridRow: \"1/2\", gridColumn: \"1/10\",  }"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "ที่มาของข้อมูล"
        },
        "style": {
          "type": "code",
          "value": "{  gridRow: \"1/2\", gridColumn: \"32/40\",  }"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{  gridRow: \"2/3\", gridColumn: \"1/31\",  }"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "Input",
      "parent": 11,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props?.onChangeNote(\"newNote\")"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props?.note"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{  gridRow: \"2/3\", gridColumn: \"32/45\",  }"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "Dropdown",
      "parent": 13,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": ""
        },
        "options": {
          "type": "code",
          "value": "props?.options"
        },
        "selection": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{  gridRow: \"2/3\", gridColumn: \"46/50\",  }"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "Button",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่ม"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "props?.OnAddADR"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.AllergyList"
        }
      },
      "seq": 17,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 70,
  "isMounted": false,
  "memo": false,
  "name": "ModAllergyUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 65
}

*********************************************************************************** */
