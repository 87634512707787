export default class CardDiagFormHistoryController {
  constructor(props) {
    this.prxManager = props.prxManager;
  }

  getDiagForm = async ({
    apiToken,
    patient,
    limit,
    offset,
    division,
    today,
  } = {}) => {
    const [response, error, network] = await this.prxManager.getDiagForm({
      apiToken,
      patient,
      limit,
      offset,
      division,
      today,
    });
    return [response, error, network];
  };

  getDiagFormClassify = async ({ apiToken, patient } = {}) => {
    const [
      response,
      error,
      network,
    ] = await this.prxManager.getDiagFormClassify({
      apiToken,
      patient,
    });
    return [response, error, network];
  };

  postDiagFormMonitor = async ({ apiToken, patient, division } = {}) => {
    const [
      response,
      error,
      network,
    ] = await this.prxManager.postDiagFormMonitor({
      apiToken,
      patient,
      division,
    });
    return [response, error, network];
  };

  getPublishedDiagRule = async ({ apiToken, params, diagRuleId }) => {
    const [
      response,
      error,
      network,
    ] = await this.prxManager.getPublishedDiagRule({
      apiToken,
      diagRuleId,
      params,
    });
    return [response, error, network];
  };

  getTriageLevelClassify = async ({ apiToken, divisionId } = {}) => {
    const [
      response,
      error,
      network,
    ] = await this.prxManager.getTriageLevelClassify({
      apiToken,
      divisionId,
    });
    return [response, error, network];
  };
}
