
/* Generated apis ---------------------- */
import { to } from 'react-lib/utils'
import BaseService from  'react-lib/apis/services/BaseService'
import config from '../../config/config'
const HOST = `${config.API_HOST}`
/* ['APIView'] */
/* params:  */
/* data: 'content', 'content_type' */
const MessagePostView : 
{
  get: ({chat_channel_id, params, apiToken, extra}:
             {  chat_channel_id: any, 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
  post:   ({params, data, apiToken, extra}:
            { chat_channel_id: any, 
            params?: any,
            data?: {[key: string]: any},
            apiToken?: any,
            extra?: any
        }) => any,
  put:   ({chat_channel_id, params, data, apiToken, extra}:
             { chat_channel_id: any, 
                params?: any,
                data?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
  patch:    ({chat_channel_id, params, data, apiToken, extra}:
             { chat_channel_id: any, 
                params?: any,
                data?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  get: async ({chat_channel_id, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken)
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    if (extra?.division)
      base.client.defaults.headers["division"] = extra.division;
    if (extra?.device)
      base.client.defaults.headers["device"] = extra.device;
    const result = await to(base.client.get(`${HOST}/apis/MSG/chat-channel/${chat_channel_id}/message/post/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  post: async ({chat_channel_id, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken)
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    if (extra?.division)
      base.client.defaults.headers["division"] = extra.division;
    if (extra?.device)
      base.client.defaults.headers["device"] = extra.device;
    base.client.defaults.headers["Content-Type"] = "multipart/form-data";

    const formData = new FormData();
    for (const [key, value] of Object.entries(data || {})) {
      formData.append(key, value)
    }
    const result = await to(base.client.post(`${HOST}/apis/MSG/chat-channel/${chat_channel_id}/message/post/`,
      formData,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  put: async ({chat_channel_id, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken)
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    if (extra?.division)
      base.client.defaults.headers["division"] = extra.division;
    if (extra?.device)
      base.client.defaults.headers["device"] = extra.device;
    const result = await to(base.client.put(`${HOST}/apis/MSG/chat-channel/${chat_channel_id}/message/post/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  patch: async ({chat_channel_id, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken)
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    if (extra?.division)
      base.client.defaults.headers["division"] = extra.division;
    if (extra?.device)
      base.client.defaults.headers["device"] = extra.device;
    const result = await to(base.client.patch(`${HOST}/apis/MSG/chat-channel/${chat_channel_id}/message/post/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

export default MessagePostView

