import React from "react";
import PropTypes from "prop-types";
import { Pagination } from "semantic-ui-react";
import { useIntl } from "react-intl";

// const defaultButton = props => <button {...props}>{props.children}</button>;

export default class SimplePagination extends React.Component {
  constructor(props) {
    super();
  }

  static propTypes = {
    pages: PropTypes.number,
    page: PropTypes.number,
    PageButtonComponent: PropTypes.any,
    onPageChange: PropTypes.func,
    previousText: PropTypes.string,
    nextText: PropTypes.string
  };

  

  // filterPages = (visiblePages, totalPages) => {
  //   return visiblePages.filter(page => page <= totalPages);
  // };

  // getVisiblePages = (page, total) => {
  //   if (total < 7) {
  //     return this.filterPages([1, 2, 3, 4, 5, 6], total);
  //   } else {
  //     if (page % 5 >= 0 && page > 4 && page + 2 < total) {
  //       return [1, page - 1, page, page + 1, total];
  //     } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
  //       return [1, total - 3, total - 2, total - 1, total];
  //     } else {
  //       return [1, 2, 3, 4, 5, total];
  //     }
  //   }
  // };

  onPaginationChange = (e, data) => {
    this.props.onPageChange(data.activePage - 1);
  };

  render() {
    // const { PageButtonComponent = defaultButton } = this.props;
 
    const activePage = this.props.page + 1;

    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          boundaryRange={0}
          defaultActivePage={activePage}
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          siblingRange={1}
          totalPages={this.props.pages}
          onPageChange={this.onPaginationChange}
        />
      </div>
    );
  }
}

// <div className="Table__pagination">
//   <div className="Table__prevPageWrapper">
//     <PageButtonComponent
//       className="Table__pageButton"
//       onClick={() => {
//         if (activePage === 1) return;
//         this.changePage(activePage - 1);
//       }}
//       disabled={activePage === 1}
//     >
//       {this.props.previousText}
//     </PageButtonComponent>
//   </div>
//   <div className="Table__visiblePagesWrapper">
//     {visiblePages.map((page, index, array) => {
//       return (
//         <PageButtonComponent
//           key={page}
//           className={
//             activePage === page
//               ? "Table__pageButton Table__pageButton--active"
//               : "Table__pageButton"
//           }
//           onClick={this.changePage.bind(null, page)}
//         >
//           {array[index - 1] + 2 < page ? `...${page}` : page}
//         </PageButtonComponent>
//       );
//     })}
//   </div>
//   <div className="Table__nextPageWrapper">
//     <PageButtonComponent
//       className="Table__pageButton"
//       onClick={() => {
//         if (activePage === this.props.pages) return;
//         this.changePage(activePage + 1);
//       }}
//       disabled={activePage === this.props.pages}
//     >
//       {this.props.nextText}
//     </PageButtonComponent>
//   </div>
// </div>
