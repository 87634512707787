import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Radio,
  Button
} from 'semantic-ui-react'

const CardNaranjoAlgorithmUX = (props: any) => {
    return(
      <div>
        <div
          style={{ display: "flex" , border: "solid 1px rgb(200, 200, 200, 0.5)","backgroundColor":"rgb(161, 221, 230)", justifyContent: "space-between", alingItem: "center", height: "70px" }}>
          
          <div
            style={{ padding: " 22px 50px 0px", fontWeight: "bold", fontSize: "17px"}}>
            Naranjo Algorithm
          </div>
        </div>
        <div
          style={{ margin: "0px 50px"}}>
          
          <div
            style={{ display: "flex"}}>
            
            <div
              style={{ padding: "10px", width: "60%",}}>
              
            </div>
            <div
              style={{ display: "flex", justifyContent: "center", padding: "10px", width: "30%", }}>
              
            </div>
            <div
              style={{ display: "flex", justifyContent: "center", padding: "10px", width: "10%", }}>
              คะแนน
            </div>
          </div>
          <div
            style={{ display: "flex", border: "solid gray 2px"}}>
            
            <div
              style={{ padding: "10px", width: "60%", borderRight: "solid gray 2px", "backgroundColor":"rgb(161, 221, 230,0.49)",}}>
              1. เคยมีสรุปหรือรายงานปฏิกิริยานี้มาแล้วหรือไม่
            </div>
            <div
              style={{ display: "flex", justifyContent: "center", padding: "10px", width: "30%", borderRight: "solid gray 2px"}}>
              
              <Radio
                checked={props.naranjo?.["1"] === "YES" }
                label="ใช่"
                name="1"
                onChange={props.onChangeNaranjo}
                style={{marginLeft:"25px"}}
                value="YES">
              </Radio>
              <Radio
                checked={props.naranjo?.["1"] === "NO"}
                label="ไม่ใช่ "
                name="1"
                onChange={props.onChangeNaranjo}
                style={{marginLeft:"25px"}}
                value="NO">
              </Radio>
              <Radio
                checked={props.naranjo?.["1"] === "NA"}
                label="ไม่ทราบ"
                name="1"
                onChange={props.onChangeNaranjo}
                style={{marginLeft:"25px"}}
                value="NA">
              </Radio>
            </div>
            <div
              style={{ display: "flex", justifyContent: "center", padding: "10px", width: "10%", }}>
              {props.score?.["1"] || 0}
            </div>
          </div>
          <div
            style={{ display: "flex", border: "solid gray 2px"}}>
            
            <div
              style={{ padding: "10px", width: "60%", borderRight: "solid gray 2px", "backgroundColor":"rgb(161, 221, 230,0.49)",}}>
              2. อาการไม่พึงประสงค์นั้นเกิดขึ้นภายหลังผู้ป่วยได้รับยาที่สงสัย
            </div>
            <div
              style={{ display: "flex", justifyContent: "center", padding: "10px", width: "30%", borderRight: "solid gray 2px"}}>
              
              <Radio
                checked={props.naranjo?.["2"] === "YES" }
                label="ใช่"
                name="2"
                onChange={props.onChangeNaranjo}
                style={{marginLeft:"25px"}}
                value="YES">
              </Radio>
              <Radio
                checked={props.naranjo?.["2"] === "NO" }
                label="ไม่ใช่"
                name="2"
                onChange={props.onChangeNaranjo}
                style={{marginLeft:"25px"}}
                value="NO">
              </Radio>
              <Radio
                checked={props.naranjo?.["2"] === "NA" }
                label="ไม่ทราบ"
                name="2"
                onChange={props.onChangeNaranjo}
                style={{marginLeft:"25px"}}
                value="NA">
              </Radio>
            </div>
            <div
              style={{ display: "flex", justifyContent: "center", padding: "10px", width: "10%", }}>
              {props.score?.["2"] || 0}
            </div>
          </div>
          <div
            style={{ display: "flex", border: "solid gray 2px"}}>
            
            <div
              style={{ padding: "10px", width: "60%", borderRight: "solid gray 2px", "backgroundColor":"rgb(161, 221, 230,0.49)",}}>
              3. อาการไม่พึงประสงค์นั้นบรรเทาลงเมื่อหยุดยาที่สงสัยหรือเมื่อมีการให้ specific antagonist
            </div>
            <div
              style={{ display: "flex", justifyContent: "center", padding: "10px", width: "30%", borderRight: "solid gray 2px"}}>
              
              <Radio
                checked={props.naranjo?.["3"] === "YES" }
                label="ใช่"
                name="3"
                onChange={props.onChangeNaranjo}
                style={{marginLeft:"25px"}}
                value="YES">
              </Radio>
              <Radio
                checked={props.naranjo?.["3"] === "NO" }
                label="ไม่ใช่"
                name="3"
                onChange={props.onChangeNaranjo}
                style={{marginLeft:"25px"}}
                value="NO">
              </Radio>
              <Radio
                checked={props.naranjo?.["3"] === "NA" }
                label="ไม่ทราบ"
                name="3"
                onChange={props.onChangeNaranjo}
                style={{marginLeft:"25px"}}
                value="NA">
              </Radio>
            </div>
            <div
              style={{ display: "flex", justifyContent: "center", padding: "10px", width: "10%", }}>
              {props.score?.["3"] || 0}
            </div>
          </div>
          <div
            style={{ display: "flex", border: "solid gray 2px"}}>
            
            <div
              style={{ padding: "10px", width: "60%", borderRight: "solid gray 2px", "backgroundColor":"rgb(161, 221, 230,0.49)",}}>
              4. อาการไม่พึงประสงค์นั้นกลับเป็นซ้ำเมื่อมีการใช้ยานั้นอีก
            </div>
            <div
              style={{ display: "flex", justifyContent: "center", padding: "10px", width: "30%", borderRight: "solid gray 2px"}}>
              
              <Radio
                checked={props.naranjo?.["4"] === "YES" }
                label="ใช่"
                name="4"
                onChange={props.onChangeNaranjo}
                style={{marginLeft:"25px"}}
                value="YES">
              </Radio>
              <Radio
                checked={props.naranjo?.["4"] === "NO" }
                label="ไม่ใช่"
                name="4"
                onChange={props.onChangeNaranjo}
                style={{marginLeft:"25px"}}
                value="NO">
              </Radio>
              <Radio
                checked={props.naranjo?.["4"] === "NA" }
                label="ไม่ทราบ"
                name="4"
                onChange={props.onChangeNaranjo}
                style={{marginLeft:"25px"}}
                value="NA">
              </Radio>
            </div>
            <div
              style={{ display: "flex", justifyContent: "center", padding: "10px", width: "10%", }}>
              {props.score?.["4"] || 0}
            </div>
          </div>
          <div
            style={{ display: "flex", border: "solid gray 2px"}}>
            
            <div
              style={{ padding: "10px", width: "60%", borderRight: "solid gray 2px", "backgroundColor":"rgb(161, 221, 230,0.49)",}}>
              5. อาจมีสาเหตุอื่นนอกเหนือจากยาที่สงสัยซึ่งเป็นสาเหตุให้ผู้ป่วย มีอาการไม่พึงประสงค์นั้น
            </div>
            <div
              style={{ display: "flex", justifyContent: "center", padding: "10px", width: "30%", borderRight: "solid gray 2px"}}>
              
              <Radio
                checked={props.naranjo?.["5"] === "YES" }
                label="ใช่"
                name="5"
                onChange={props.onChangeNaranjo}
                style={{marginLeft:"25px"}}
                value="YES">
              </Radio>
              <Radio
                checked={props.naranjo?.["5"] === "NO" }
                label="ไม่ใช่"
                name="5"
                onChange={props.onChangeNaranjo}
                style={{marginLeft:"25px"}}
                value="NO">
              </Radio>
              <Radio
                checked={props.naranjo?.["5"] === "NA" }
                label="ไม่ทราบ"
                name="5"
                onChange={props.onChangeNaranjo}
                style={{marginLeft:"25px"}}
                value="NA">
              </Radio>
            </div>
            <div
              style={{ display: "flex", justifyContent: "center", padding: "10px", width: "10%", }}>
              {props.score?.["5"] || 0}
            </div>
          </div>
          <div
            style={{ display: "flex", border: "solid gray 2px"}}>
            
            <div
              style={{ padding: "10px", width: "60%", borderRight: "solid gray 2px", "backgroundColor":"rgb(161, 221, 230,0.49)",}}>
              6. ปฏิกิริยาดังกล่าวนี้เกิดขึ้นอีกเมื่อให้ยาหลอกหรือไม่
            </div>
            <div
              style={{ display: "flex", justifyContent: "center", padding: "10px", width: "30%", borderRight: "solid gray 2px"}}>
              
              <Radio
                checked={props.naranjo?.["6"] === "YES" }
                label="ใช่"
                name="6"
                onChange={props.onChangeNaranjo}
                style={{marginLeft:"25px"}}
                value="YES">
              </Radio>
              <Radio
                checked={props.naranjo?.["6"] === "NO" }
                label="ไม่ใช่"
                name="6"
                onChange={props.onChangeNaranjo}
                style={{marginLeft:"25px"}}
                value="NO">
              </Radio>
              <Radio
                checked={props.naranjo?.["6"] === "NA" }
                label="ไม่ทราบ"
                name="6"
                onChange={props.onChangeNaranjo}
                style={{marginLeft:"25px"}}
                value="NA">
              </Radio>
            </div>
            <div
              style={{ display: "flex", justifyContent: "center", padding: "10px", width: "10%", }}>
              {props.score?.["6"] || 0}
            </div>
          </div>
          <div
            style={{ display: "flex", border: "solid gray 2px"}}>
            
            <div
              style={{ padding: "10px", width: "60%", borderRight: "solid gray 2px", "backgroundColor":"rgb(161, 221, 230,0.49)",}}>
              7. มีการวัดระดับยาในเลือดหรือใน body fluid อื่น และค่าดังกล่าว แสดงถึงระดับที่เป็นพิษ
            </div>
            <div
              style={{ display: "flex", justifyContent: "center", padding: "10px", width: "30%", borderRight: "solid gray 2px"}}>
              
              <Radio
                checked={props.naranjo?.["7"] === "YES" }
                label="ใช่"
                name="7"
                onChange={props.onChangeNaranjo}
                style={{marginLeft:"25px"}}
                value="YES">
              </Radio>
              <Radio
                checked={props.naranjo?.["7"] === "NO" }
                label="ไม่ใช่"
                name="7"
                onChange={props.onChangeNaranjo}
                style={{marginLeft:"25px"}}
                value="NO">
              </Radio>
              <Radio
                checked={props.naranjo?.["7"] === "NA" }
                label="ไม่ทราบ"
                name="7"
                onChange={props.onChangeNaranjo}
                style={{marginLeft:"25px"}}
                value="NA">
              </Radio>
            </div>
            <div
              style={{ display: "flex", justifyContent: "center", padding: "10px", width: "10%", }}>
              {props.score?.["7"] || 0}
            </div>
          </div>
          <div
            style={{ display: "flex", border: "solid gray 2px"}}>
            
            <div
              style={{ padding: "10px", width: "60%", borderRight: "solid gray 2px", "backgroundColor":"rgb(161, 221, 230,0.49)",}}>
              8. อาการไม่พึงประสงค์นั้นรุนแรงขึ้นเมื่อมีการเพิ่มขนาดยาและ/หรืออาการไม่พึงประสงค์นั้นลดความรุนแรงลงเมื่อลดขนาดยา
            </div>
            <div
              style={{ display: "flex", justifyContent: "center", padding: "10px", width: "30%", borderRight: "solid gray 2px"}}>
              
              <Radio
                checked={props.naranjo?.["8"] === "YES" }
                label="ใช่"
                name="8"
                onChange={props.onChangeNaranjo}
                style={{marginLeft:"25px"}}
                value="YES">
              </Radio>
              <Radio
                checked={props.naranjo?.["8"] === "NO" }
                label="ไม่ใช่"
                name="8"
                onChange={props.onChangeNaranjo}
                style={{marginLeft:"25px"}}
                value="NO">
              </Radio>
              <Radio
                checked={props.naranjo?.["8"] === "NA" }
                label="ไม่ทราบ"
                name="8"
                onChange={props.onChangeNaranjo}
                style={{marginLeft:"25px"}}
                value="NA">
              </Radio>
            </div>
            <div
              style={{ display: "flex", justifyContent: "center", padding: "10px", width: "10%", }}>
              {props.score?.["8"] || 0}
            </div>
          </div>
          <div
            style={{ display: "flex", border: "solid gray 2px"}}>
            
            <div
              style={{ padding: "10px", width: "60%", borderRight: "solid gray 2px", "backgroundColor":"rgb(161, 221, 230,0.49)",}}>
              9. ผู้ป่วยเคยแสดงอาการไม่พึงประสงค์เช่นนี้มาก่อนในอดีตเมื่อใช้ยาที่สงสัย หรือยาที่คล้ายกัน
            </div>
            <div
              style={{ display: "flex", justifyContent: "center", padding: "10px", width: "30%", borderRight: "solid gray 2px"}}>
              
              <Radio
                checked={props.naranjo?.["9"] === "YES" }
                label="ใช่"
                name="9"
                onChange={props.onChangeNaranjo}
                style={{marginLeft:"25px"}}
                value="YES">
              </Radio>
              <Radio
                checked={props.naranjo?.["9"] === "NO" }
                label="ไม่ใช่"
                name="9"
                onChange={props.onChangeNaranjo}
                style={{marginLeft:"25px"}}
                value="NO">
              </Radio>
              <Radio
                checked={props.naranjo?.["9"] === "NA" }
                label="ไม่ทราบ"
                name="9"
                onChange={props.onChangeNaranjo}
                style={{marginLeft:"25px"}}
                value="NA">
              </Radio>
            </div>
            <div
              style={{ display: "flex", justifyContent: "center", padding: "10px", width: "10%", }}>
              {props.score?.["9"] || 0}
            </div>
          </div>
          <div
            style={{ display: "flex", border: "solid gray 2px"}}>
            
            <div
              style={{ padding: "10px", width: "60%", borderRight: "solid gray 2px", "backgroundColor":"rgb(161, 221, 230,0.49)",}}>
              10. สามารถยืนยันอาการไม่พึงประสงค์ดังกล่าวได้ด้วย objective data
            </div>
            <div
              style={{ display: "flex", justifyContent: "center", padding: "10px", width: "30%", borderRight: "solid gray 2px"}}>
              
              <Radio
                checked={props.naranjo?.["10"] === "YES" }
                label="ใช่"
                name="10"
                onChange={props.onChangeNaranjo}
                style={{marginLeft:"25px"}}
                value="YES">
              </Radio>
              <Radio
                checked={props.naranjo?.["10"] === "NO" }
                label="ไม่ใช่"
                name="10"
                onChange={props.onChangeNaranjo}
                style={{marginLeft:"25px"}}
                value="NO">
              </Radio>
              <Radio
                checked={props.naranjo?.["10"] === "NA" }
                label="ไม่ทราบ"
                name="10"
                onChange={props.onChangeNaranjo}
                style={{marginLeft:"25px"}}
                value="NA">
              </Radio>
            </div>
            <div
              style={{ display: "flex", justifyContent: "center", padding: "10px", width: "10%", }}>
              {props.score?.["10"] || 0}
            </div>
          </div>
          <div
            style={{ display: "flex", justifyContent: "flex-end"}}>
            
            <div
              style={{ justifyContent: "space-between", display: "flex", padding: "10px", width: "65%", borderRight: "solid gray 2px", backgroundColor:"rgba(255, 208, 43, 0.46)",}}>
              
              <div
                style={{ margin: "0px 30px", display: "flex", color: "#1B75BB",  fontWeight: "bold", fontSize: "17px"}}>
                
                <div>
                  คะแนนรวม :
                </div>
                <div
                  style={{ margin: "0px 10px"}}>
                  {props.naranjo?.score || 0}
                </div>
              </div>
              <div
                style={{ margin: "0px 30px", display: "flex", color: "#1B9D2C",  fontWeight: "bold", fontSize: "17px"}}>
                
                <div>
                  ผลการประเมิน: 
                </div>
                <div
                  style={{ margin: "0px 10px"}}>
                  {props.naranjo?.status || ""}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ display: "flex", justifyContent: "flex-end", margin: "20px 0px" }}>
            
            <Button
              color="green"
              onClick={props.onSave}>
              บันทึก
            </Button>
            <Button
              color="red"
              onClick={props.onCencel}>
              ยกเลิก
            </Button>
          </div>
        </div>
      </div>
    )
}

export default CardNaranjoAlgorithmUX

export const screenPropsDefault = {}

/* Date Time : Thu Jan 19 2023 09:56:26 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" , border: \"solid 1px rgb(200, 200, 200, 0.5)\",\"backgroundColor\":\"rgb(161, 221, 230)\", justifyContent: \"space-between\", alingItem: \"center\", height: \"70px\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Naranjo Algorithm"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \" 22px 50px 0px\", fontWeight: \"bold\", fontSize: \"17px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 50px\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", border: \"solid gray 2px\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "1. เคยมีสรุปหรือรายงานปฏิกิริยานี้มาแล้วหรือไม่"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"10px\", width: \"60%\", borderRight: \"solid gray 2px\", \"backgroundColor\":\"rgb(161, 221, 230,0.49)\",}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\", padding: \"10px\", width: \"30%\", borderRight: \"solid gray 2px\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "code",
          "value": "props.score?.[\"1\"] || 0"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\", padding: \"10px\", width: \"10%\", }"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "Radio",
      "parent": 6,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.naranjo?.[\"1\"] === \"YES\" "
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "label": {
          "type": "value",
          "value": "ใช่"
        },
        "name": {
          "type": "value",
          "value": "1"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeNaranjo"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"25px\"}"
        },
        "value": {
          "type": "value",
          "value": "YES"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "Radio",
      "parent": 6,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.naranjo?.[\"1\"] === \"NO\""
        },
        "label": {
          "type": "value",
          "value": "ไม่ใช่ "
        },
        "name": {
          "type": "value",
          "value": "1"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeNaranjo"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"25px\"}"
        },
        "value": {
          "type": "value",
          "value": "NO"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "Radio",
      "parent": 6,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.naranjo?.[\"1\"] === \"NA\""
        },
        "label": {
          "type": "value",
          "value": "ไม่ทราบ"
        },
        "name": {
          "type": "value",
          "value": "1"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeNaranjo"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"25px\"}"
        },
        "value": {
          "type": "value",
          "value": "NA"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"10px\", width: \"60%\",}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\", padding: \"10px\", width: \"30%\", }"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": "คะแนน"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\", padding: \"10px\", width: \"10%\", }"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", border: \"solid gray 2px\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "2. อาการไม่พึงประสงค์นั้นเกิดขึ้นภายหลังผู้ป่วยได้รับยาที่สงสัย"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"10px\", width: \"60%\", borderRight: \"solid gray 2px\", \"backgroundColor\":\"rgb(161, 221, 230,0.49)\",}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\", padding: \"10px\", width: \"30%\", borderRight: \"solid gray 2px\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "code",
          "value": "props.score?.[\"2\"] || 0"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\", padding: \"10px\", width: \"10%\", }"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "Radio",
      "parent": 17,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.naranjo?.[\"2\"] === \"YES\" "
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "label": {
          "type": "value",
          "value": "ใช่"
        },
        "name": {
          "type": "value",
          "value": "2"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeNaranjo"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"25px\"}"
        },
        "value": {
          "type": "value",
          "value": "YES"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "Radio",
      "parent": 17,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.naranjo?.[\"2\"] === \"NO\" "
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "label": {
          "type": "value",
          "value": "ไม่ใช่"
        },
        "name": {
          "type": "value",
          "value": "2"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeNaranjo"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"25px\"}"
        },
        "value": {
          "type": "value",
          "value": "NO"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "Radio",
      "parent": 17,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.naranjo?.[\"2\"] === \"NA\" "
        },
        "label": {
          "type": "value",
          "value": "ไม่ทราบ"
        },
        "name": {
          "type": "value",
          "value": "2"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeNaranjo"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"25px\"}"
        },
        "value": {
          "type": "value",
          "value": "NA"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", border: \"solid gray 2px\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", border: \"solid gray 2px\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", border: \"solid gray 2px\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", border: \"solid gray 2px\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", border: \"solid gray 2px\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", border: \"solid gray 2px\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", border: \"solid gray 2px\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", border: \"solid gray 2px\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": "3. อาการไม่พึงประสงค์นั้นบรรเทาลงเมื่อหยุดยาที่สงสัยหรือเมื่อมีการให้ specific antagonist"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"10px\", width: \"60%\", borderRight: \"solid gray 2px\", \"backgroundColor\":\"rgb(161, 221, 230,0.49)\",}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "div",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": "4. อาการไม่พึงประสงค์นั้นกลับเป็นซ้ำเมื่อมีการใช้ยานั้นอีก"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"10px\", width: \"60%\", borderRight: \"solid gray 2px\", \"backgroundColor\":\"rgb(161, 221, 230,0.49)\",}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "div",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": "5. อาจมีสาเหตุอื่นนอกเหนือจากยาที่สงสัยซึ่งเป็นสาเหตุให้ผู้ป่วย มีอาการไม่พึงประสงค์นั้น"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"10px\", width: \"60%\", borderRight: \"solid gray 2px\", \"backgroundColor\":\"rgb(161, 221, 230,0.49)\",}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": "6. ปฏิกิริยาดังกล่าวนี้เกิดขึ้นอีกเมื่อให้ยาหลอกหรือไม่"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"10px\", width: \"60%\", borderRight: \"solid gray 2px\", \"backgroundColor\":\"rgb(161, 221, 230,0.49)\",}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": "7. มีการวัดระดับยาในเลือดหรือใน body fluid อื่น และค่าดังกล่าว แสดงถึงระดับที่เป็นพิษ"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"10px\", width: \"60%\", borderRight: \"solid gray 2px\", \"backgroundColor\":\"rgb(161, 221, 230,0.49)\",}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "div",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": "8. อาการไม่พึงประสงค์นั้นรุนแรงขึ้นเมื่อมีการเพิ่มขนาดยาและ/หรืออาการไม่พึงประสงค์นั้นลดความรุนแรงลงเมื่อลดขนาดยา"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"10px\", width: \"60%\", borderRight: \"solid gray 2px\", \"backgroundColor\":\"rgb(161, 221, 230,0.49)\",}"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 28,
      "props": {
        "children": {
          "type": "value",
          "value": "9. ผู้ป่วยเคยแสดงอาการไม่พึงประสงค์เช่นนี้มาก่อนในอดีตเมื่อใช้ยาที่สงสัย หรือยาที่คล้ายกัน"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"10px\", width: \"60%\", borderRight: \"solid gray 2px\", \"backgroundColor\":\"rgb(161, 221, 230,0.49)\",}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "div",
      "parent": 29,
      "props": {
        "children": {
          "type": "value",
          "value": "10. สามารถยืนยันอาการไม่พึงประสงค์ดังกล่าวได้ด้วย objective data"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"10px\", width: \"60%\", borderRight: \"solid gray 2px\", \"backgroundColor\":\"rgb(161, 221, 230,0.49)\",}"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "div",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\", padding: \"10px\", width: \"30%\", borderRight: \"solid gray 2px\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\", padding: \"10px\", width: \"30%\", borderRight: \"solid gray 2px\"}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "div",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\", padding: \"10px\", width: \"30%\", borderRight: \"solid gray 2px\"}"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\", padding: \"10px\", width: \"30%\", borderRight: \"solid gray 2px\"}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\", padding: \"10px\", width: \"30%\", borderRight: \"solid gray 2px\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\", padding: \"10px\", width: \"30%\", borderRight: \"solid gray 2px\"}"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "div",
      "parent": 28,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\", padding: \"10px\", width: \"30%\", borderRight: \"solid gray 2px\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "div",
      "parent": 29,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\", padding: \"10px\", width: \"30%\", borderRight: \"solid gray 2px\"}"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "Radio",
      "parent": 38,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.naranjo?.[\"3\"] === \"YES\" "
        },
        "label": {
          "type": "value",
          "value": "ใช่"
        },
        "name": {
          "type": "value",
          "value": "3"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeNaranjo"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"25px\"}"
        },
        "value": {
          "type": "value",
          "value": "YES"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "Radio",
      "parent": 38,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.naranjo?.[\"3\"] === \"NO\" "
        },
        "label": {
          "type": "value",
          "value": "ไม่ใช่"
        },
        "name": {
          "type": "value",
          "value": "3"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeNaranjo"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"25px\"}"
        },
        "value": {
          "type": "value",
          "value": "NO"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "Radio",
      "parent": 38,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.naranjo?.[\"3\"] === \"NA\" "
        },
        "label": {
          "type": "value",
          "value": "ไม่ทราบ"
        },
        "name": {
          "type": "value",
          "value": "3"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeNaranjo"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"25px\"}"
        },
        "value": {
          "type": "value",
          "value": "NA"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "Radio",
      "parent": 39,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.naranjo?.[\"4\"] === \"YES\" "
        },
        "label": {
          "type": "value",
          "value": "ใช่"
        },
        "name": {
          "type": "value",
          "value": "4"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeNaranjo"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"25px\"}"
        },
        "value": {
          "type": "value",
          "value": "YES"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "Radio",
      "parent": 39,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.naranjo?.[\"4\"] === \"NO\" "
        },
        "label": {
          "type": "value",
          "value": "ไม่ใช่"
        },
        "name": {
          "type": "value",
          "value": "4"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeNaranjo"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"25px\"}"
        },
        "value": {
          "type": "value",
          "value": "NO"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "Radio",
      "parent": 39,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.naranjo?.[\"4\"] === \"NA\" "
        },
        "label": {
          "type": "value",
          "value": "ไม่ทราบ"
        },
        "name": {
          "type": "value",
          "value": "4"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeNaranjo"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"25px\"}"
        },
        "value": {
          "type": "value",
          "value": "NA"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "Radio",
      "parent": 40,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.naranjo?.[\"5\"] === \"YES\" "
        },
        "label": {
          "type": "value",
          "value": "ใช่"
        },
        "name": {
          "type": "value",
          "value": "5"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeNaranjo"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"25px\"}"
        },
        "value": {
          "type": "value",
          "value": "YES"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "Radio",
      "parent": 40,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.naranjo?.[\"5\"] === \"NO\" "
        },
        "label": {
          "type": "value",
          "value": "ไม่ใช่"
        },
        "name": {
          "type": "value",
          "value": "5"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeNaranjo"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"25px\"}"
        },
        "value": {
          "type": "value",
          "value": "NO"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "Radio",
      "parent": 40,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.naranjo?.[\"5\"] === \"NA\" "
        },
        "label": {
          "type": "value",
          "value": "ไม่ทราบ"
        },
        "name": {
          "type": "value",
          "value": "5"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeNaranjo"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"25px\"}"
        },
        "value": {
          "type": "value",
          "value": "NA"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "Radio",
      "parent": 41,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.naranjo?.[\"6\"] === \"YES\" "
        },
        "label": {
          "type": "value",
          "value": "ใช่"
        },
        "name": {
          "type": "value",
          "value": "6"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeNaranjo"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"25px\"}"
        },
        "value": {
          "type": "value",
          "value": "YES"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 56,
      "name": "Radio",
      "parent": 41,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.naranjo?.[\"6\"] === \"NO\" "
        },
        "label": {
          "type": "value",
          "value": "ไม่ใช่"
        },
        "name": {
          "type": "value",
          "value": "6"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeNaranjo"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"25px\"}"
        },
        "value": {
          "type": "value",
          "value": "NO"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "Radio",
      "parent": 41,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.naranjo?.[\"6\"] === \"NA\" "
        },
        "label": {
          "type": "value",
          "value": "ไม่ทราบ"
        },
        "name": {
          "type": "value",
          "value": "6"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeNaranjo"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"25px\"}"
        },
        "value": {
          "type": "value",
          "value": "NA"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "Radio",
      "parent": 42,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.naranjo?.[\"7\"] === \"YES\" "
        },
        "label": {
          "type": "value",
          "value": "ใช่"
        },
        "name": {
          "type": "value",
          "value": "7"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeNaranjo"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"25px\"}"
        },
        "value": {
          "type": "value",
          "value": "YES"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "Radio",
      "parent": 42,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.naranjo?.[\"7\"] === \"NO\" "
        },
        "label": {
          "type": "value",
          "value": "ไม่ใช่"
        },
        "name": {
          "type": "value",
          "value": "7"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeNaranjo"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"25px\"}"
        },
        "value": {
          "type": "value",
          "value": "NO"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "Radio",
      "parent": 42,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.naranjo?.[\"7\"] === \"NA\" "
        },
        "label": {
          "type": "value",
          "value": "ไม่ทราบ"
        },
        "name": {
          "type": "value",
          "value": "7"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeNaranjo"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"25px\"}"
        },
        "value": {
          "type": "value",
          "value": "NA"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "Radio",
      "parent": 45,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.naranjo?.[\"10\"] === \"YES\" "
        },
        "label": {
          "type": "value",
          "value": "ใช่"
        },
        "name": {
          "type": "value",
          "value": "10"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeNaranjo"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"25px\"}"
        },
        "value": {
          "type": "value",
          "value": "YES"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "Radio",
      "parent": 45,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.naranjo?.[\"10\"] === \"NO\" "
        },
        "label": {
          "type": "value",
          "value": "ไม่ใช่"
        },
        "name": {
          "type": "value",
          "value": "10"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeNaranjo"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"25px\"}"
        },
        "value": {
          "type": "value",
          "value": "NO"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "Radio",
      "parent": 45,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.naranjo?.[\"10\"] === \"NA\" "
        },
        "label": {
          "type": "value",
          "value": "ไม่ทราบ"
        },
        "name": {
          "type": "value",
          "value": "10"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeNaranjo"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"25px\"}"
        },
        "value": {
          "type": "value",
          "value": "NA"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "Radio",
      "parent": 44,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.naranjo?.[\"9\"] === \"YES\" "
        },
        "label": {
          "type": "value",
          "value": "ใช่"
        },
        "name": {
          "type": "value",
          "value": "9"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeNaranjo"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"25px\"}"
        },
        "value": {
          "type": "value",
          "value": "YES"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "Radio",
      "parent": 44,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.naranjo?.[\"9\"] === \"NO\" "
        },
        "label": {
          "type": "value",
          "value": "ไม่ใช่"
        },
        "name": {
          "type": "value",
          "value": "9"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeNaranjo"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"25px\"}"
        },
        "value": {
          "type": "value",
          "value": "NO"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "Radio",
      "parent": 44,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.naranjo?.[\"9\"] === \"NA\" "
        },
        "label": {
          "type": "value",
          "value": "ไม่ทราบ"
        },
        "name": {
          "type": "value",
          "value": "9"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeNaranjo"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"25px\"}"
        },
        "value": {
          "type": "value",
          "value": "NA"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "Radio",
      "parent": 43,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.naranjo?.[\"8\"] === \"YES\" "
        },
        "label": {
          "type": "value",
          "value": "ใช่"
        },
        "name": {
          "type": "value",
          "value": "8"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeNaranjo"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"25px\"}"
        },
        "value": {
          "type": "value",
          "value": "YES"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "Radio",
      "parent": 43,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.naranjo?.[\"8\"] === \"NO\" "
        },
        "label": {
          "type": "value",
          "value": "ไม่ใช่"
        },
        "name": {
          "type": "value",
          "value": "8"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeNaranjo"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"25px\"}"
        },
        "value": {
          "type": "value",
          "value": "NO"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "Radio",
      "parent": 43,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.naranjo?.[\"8\"] === \"NA\" "
        },
        "label": {
          "type": "value",
          "value": "ไม่ทราบ"
        },
        "name": {
          "type": "value",
          "value": "8"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeNaranjo"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft:\"25px\"}"
        },
        "value": {
          "type": "value",
          "value": "NA"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "div",
      "parent": 22,
      "props": {
        "children": {
          "type": "code",
          "value": "props.score?.[\"3\"] || 0"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\", padding: \"10px\", width: \"10%\", }"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "div",
      "parent": 23,
      "props": {
        "children": {
          "type": "code",
          "value": "props.score?.[\"4\"] || 0"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\", padding: \"10px\", width: \"10%\", }"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "div",
      "parent": 24,
      "props": {
        "children": {
          "type": "code",
          "value": "props.score?.[\"5\"] || 0"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\", padding: \"10px\", width: \"10%\", }"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "div",
      "parent": 25,
      "props": {
        "children": {
          "type": "code",
          "value": "props.score?.[\"6\"] || 0"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\", padding: \"10px\", width: \"10%\", }"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "code",
          "value": "props.score?.[\"7\"] || 0"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\", padding: \"10px\", width: \"10%\", }"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "div",
      "parent": 27,
      "props": {
        "children": {
          "type": "code",
          "value": "props.score?.[\"8\"] || 0"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\", padding: \"10px\", width: \"10%\", }"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "div",
      "parent": 28,
      "props": {
        "children": {
          "type": "code",
          "value": "props.score?.[\"9\"] || 0"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\", padding: \"10px\", width: \"10%\", }"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 77,
      "name": "div",
      "parent": 29,
      "props": {
        "children": {
          "type": "code",
          "value": "props.score?.[\"10\"] || 0"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\", padding: \"10px\", width: \"10%\", }"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"flex-end\"}"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"flex-end\", margin: \"20px 0px\" }"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "div",
      "parent": 78,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ justifyContent: \"space-between\", display: \"flex\", padding: \"10px\", width: \"65%\", borderRight: \"solid gray 2px\", backgroundColor:\"rgba(255, 208, 43, 0.46)\",}"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "div",
      "parent": 80,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 30px\", display: \"flex\", color: \"#1B75BB\",  fontWeight: \"bold\", fontSize: \"17px\"}"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "div",
      "parent": 80,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 30px\", display: \"flex\", color: \"#1B9D2C\",  fontWeight: \"bold\", fontSize: \"17px\"}"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "div",
      "parent": 81,
      "props": {
        "children": {
          "type": "value",
          "value": "คะแนนรวม :"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 84,
      "name": "div",
      "parent": 81,
      "props": {
        "children": {
          "type": "code",
          "value": "props.naranjo?.score || 0"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 10px\"}"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "div",
      "parent": 82,
      "props": {
        "children": {
          "type": "value",
          "value": "ผลการประเมิน: "
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": null,
      "id": 86,
      "name": "div",
      "parent": 82,
      "props": {
        "children": {
          "type": "code",
          "value": "props.naranjo?.status || \"\""
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 10px\"}"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 87,
      "name": "Button",
      "parent": 79,
      "props": {
        "children": {
          "type": "value",
          "value": "บันทึก"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSave"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 88,
      "name": "Button",
      "parent": 79,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิก"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCencel"
        }
      },
      "seq": 88,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardNaranjoAlgorithmUX",
  "project": "New Project",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
