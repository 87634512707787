import WasmController from "react-lib/frameworks/WasmController";

// APIs
import FormDataDetail from "issara-sdk/apis/FormDataDetail_apps_PTM";

// SEQ
import * as PreAnestheticI from "./sequence/PreAnesthetic";
import * as PACURecordI from "./sequence/PACURecord";

// Utils
import { beToAd } from "react-lib/utils/dateUtils";
import moment from "moment";

export type State = {} & PreAnestheticI.State & PACURecordI.State;

export const StateInitial: State = {
  ...PreAnestheticI.StateInitial,
  // ...PACURecordI.StateInitial,
};

export type Event = PreAnestheticI.Event | PACURecordI.Event;

export type Data = {
  division?: number;
} & PreAnestheticI.Data &
  PACURecordI.Data;

export const DataInitial = {
  ...PreAnestheticI.DataInitial,
  ...PACURecordI.DataInitial,
};

type Handler = (controller: WasmController<State, Event, Data>, params?: any) => any;

export const DidMount: Handler = async (controller, params) => {};

export const GetAnesVitalSign: Handler = async (controller, params) => {
  const state = controller.getState();

  if (params.startDate) params.from_date = params.startDate;
  if (params.endDate) params.to_date = params.endDate;
  if (params.startTime) params.from_time = params.startTime;
  if (params.endTime) params.to_time = params.endTime;

  if (!state.PACURecordSequence?.anesPACUVitalSign?.id) {
    let defaultItems = {
      items: [],
      next: null,
      previous: null,
      total: 0,
      vitalsign_type: state.PACURecordSequence?.anesPACUVitalSign?.type,
    };

    return [defaultItems, null];
  }

  const resp = await FormDataDetail.retrieve({
    pk: state.PACURecordSequence?.anesPACUVitalSign?.id,
    apiToken: controller.apiToken,
  });

  if (!resp) {
    return [null, resp[1]];
  }

  if (resp[0]?.data && (params.startDate || params.endDate || params.startTime || params.endTime)) {
    const filteredData = {
      ...resp[0].data,
      items: resp[0].data.items.filter((item: any) => {
        const itemUtcDateTime = moment.utc(item.date_time_utc);
        const itemLocalDateTime = itemUtcDateTime.clone().utcOffset(7);

        let startDateTime: any = moment();
        let endDateTime: any = moment();

        if (params.startDate) {
          const startLocalDate = beToAd(params.startDate);
          startDateTime = startLocalDate?.clone();

          if (params.startTime) {
            const [hrs, mins] = params.startTime.split(":");
            startDateTime.set({ hours: parseInt(hrs), minutes: parseInt(mins) });
          } else {
            startDateTime.startOf("day");
          }
        } else if (params.startTime) {
          const [hrs, mins] = params.startTime.split(":");
          startDateTime = moment()
            .startOf("day")
            .set({
              hours: parseInt(hrs),
              minutes: parseInt(mins),
            });
        } else {
          startDateTime = moment(0);
        }

        if (params.endDate) {
          const endLocalDate = beToAd(params.endDate);
          endDateTime = endLocalDate?.clone();

          if (params.endTime) {
            const [hrs, mins] = params.endTime.split(":");
            endDateTime.set({ hours: parseInt(hrs), minutes: parseInt(mins) });
          } else {
            endDateTime.endOf("day");
          }
        } else if (params.endTime) {
          const [hrs, mins] = params.endTime.split(":");
          endDateTime = moment()
            .endOf("day")
            .set({
              hours: parseInt(hrs),
              minutes: parseInt(mins),
            });
        }

        return (
          itemLocalDateTime.isSameOrAfter(startDateTime) &&
          itemLocalDateTime.isSameOrBefore(endDateTime)
        );
      }),
    };

    filteredData.total = filteredData.items.length;

    return [filteredData, null];
  }

  return [resp[0]?.data, null];
};