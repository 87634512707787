import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button
} from 'semantic-ui-react'

const ModOpenSendToClinicUX = (props: any) => {
    return(
      <div>
        
        <div
          style={{display:"flex",marginBottom:"10px"}}>
          {}
          <div
            style={{marginTop:"5px",fontWeight: "bold", fontSize: "1.2rem"}}>
            รายชื่อ Waiting list  ที่ต้องการจะส่งต่อ
          </div>
        </div>
        <div
          style={{display:"flex",justifyContent: 'flex-end',marginBottom:"5px"}}>
          
          <div
            style={{marginRight:"10px",marginTop:"15px"}}>
            คลินิก 
          </div>
          <div
            style={{width:"200px"}}>
            {props.divisionSelector}
          </div>
        </div>
        <div>
          
          <Table
            data={props?.dataWaitingList}
            getTrProps={props?.getTrPropsSendWaitingList}
            headers="ชื่อ Waiting List,แผนก"
            keys="name,division_name"
            minRows="6"
            showPagination={false}
            style={{height: "200px"}}>
          </Table>
        </div>
        <div
          style={{display:"flex", flexDirection: "row",marginTop:"10px"}}>
          
          <Button
            color="green"
            onClick={props.OnConfirm}
            size="tiny"
            style={{marginRight: "10px"}}>
            ยืนยัน
          </Button>
          <Button
            color="red"
            onClick={props.OnCancel}
            size="tiny">
            ยกเลิก
          </Button>
        </div>
      </div>
    )
}

export default ModOpenSendToClinicUX

export const screenPropsDefault = {"title":"9:00-12:00"}

/* Date Time : Fri Aug 05 2022 15:01:10 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",marginBottom:\"10px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 2,
      "name": "Table",
      "parent": 3,
      "props": {
        "data": {
          "type": "code",
          "value": "props?.dataWaitingList"
        },
        "getTrProps": {
          "type": "code",
          "value": "props?.getTrPropsSendWaitingList"
        },
        "headers": {
          "type": "value",
          "value": "ชื่อ Waiting List,แผนก"
        },
        "keys": {
          "type": "value",
          "value": "name,division_name"
        },
        "minRows": {
          "type": "value",
          "value": "6"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"200px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "รายชื่อ Waiting list  ที่ต้องการจะส่งต่อ"
        },
        "style": {
          "type": "code",
          "value": "{marginTop:\"5px\",fontWeight: \"bold\", fontSize: \"1.2rem\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",justifyContent: 'flex-end',marginBottom:\"5px\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "คลินิก "
        },
        "style": {
          "type": "code",
          "value": "{marginRight:\"10px\",marginTop:\"15px\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "code",
          "value": "props.divisionSelector"
        },
        "style": {
          "type": "code",
          "value": "{width:\"200px\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", flexDirection: \"row\",marginTop:\"10px\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "Button",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "ยืนยัน"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.OnConfirm"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"10px\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "Button",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิก"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.OnCancel"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        }
      },
      "seq": 10,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 60,
  "isMounted": false,
  "memo": false,
  "name": "ModOpenSendToClinicUX",
  "project": "cudent2",
  "screenPropsDefault": {
    "title": "9:00-12:00"
  },
  "width": 70
}

*********************************************************************************** */
