import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Dropdown
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardPurchaseListUX = (props: any) => {
    return(
      <div
        style={{width: "100%"}}>
        <div
          style={{display: "flex", alignItems: "center", justifyContent: "space-between", padding: "8.5px 20px", backgroundColor: "#F2F2F2", minHeight: "50px"}}>
          
          <div
            style={{fontSize: "1.2rem", fontWeight: "bold", color: "#0072BC"}}>
            รายการรอชำระเงิน
          </div>
          <div
            style={{display: "flex", alignItems: "center", fontSize: "1.1rem"}}>
            
            <label>
              ยอดชำระรวม
            </label>
            <label
              style={{color:"#1B75BB", margin: "0 1rem", fontWeight: "bold"}}>
              {props.totalPayment || ""}
            </label>
            <label
              style={{marginRight: "2rem"}}>
              บาท
            </label>
            <div>
              {props.ButtonPayment}
            </div>
          </div>
        </div>
        <div
          className="ui divider"
          style={{marginTop: 0, border: "none"}}>
          
        </div>
        <div
          style={{padding: "0 15px"}}>
          
          <div
            className="ui form">
            
            <FormGroup
              inline={true}>
              <FormField>
                <label>
                  ค้นหา ชื่อ/รหัสสินค้า
                </label>
              </FormField>
              <FormField
                inline={true}
                width={11}>
                <div
                  style={{width: "100%"}}>
                  {props.SearchBoxPackageName}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}>
                <label
                  style={{minWidth: "max-content"}}>
                  ประเภทบริการ
                </label>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <Dropdown
                  clearable={true}
                  fluid={true}
                  name="serviceType"
                  onChange={props.onChangeValue}
                  options={props.packageServiceTypeOptions|| []}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.filterPurchaseList?.serviceType || ""}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}>
                <label
                  style={{minWidth: "max-content"}}>
                  ประเภทสินค้า
                </label>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <Dropdown
                  clearable={true}
                  fluid={true}
                  name="productType"
                  onChange={props.onChangeValue}
                  options={props.packageTypeOptions || []}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.filterPurchaseList?.productType|| ""}>
                </Dropdown>
              </FormField>
              <FormField>
                <label
                  style={{minWidth: "max-content"}}>
                  สถานะสินค้า
                </label>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <Dropdown
                  clearable={true}
                  fluid={true}
                  name="productStatus"
                  onChange={props.onChangeValue}
                  options={props.packageStatusOptions || []}
                  search={true}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.filterPurchaseList?.productStatus || ""}>
                </Dropdown>
              </FormField>
              <FormField>
                <div>
                  {props.ButtonSearch}
                </div>
              </FormField>
            </FormGroup>
          </div>
          <div
            className="ui form"
            style={{marginTop: "1.5rem"}}>
            
            <Table
              data={props.purchaseItems || []}
              getTdProps={props.getTdProps}
              headers={[...(props.CheckAll?[props.CheckAll]:[]),"รหัส","ประเภทบริการ","ประเภทสินค้า","ชื่อสินค้า","ราคา","จำนวน","รายละเอียด","ลบ"]}
              keys={`${props.CheckAll?"check,":""}code,service_type,product_type,product_name,price,qty,description,delete`}
              minRows={3}
              showPagination={false}
              style={{maxHeight: "calc(100vh - 18.5rem)"}}
              widths={`${props.CheckAll?"50,":""}120,,,,100,90,90,90`}>
            </Table>
          </div>
        </div>
      </div>
    )
}


export default CardPurchaseListUX

export const screenPropsDefault = {"price_claimable":0,"price_non_claimable":0,"price_total":0}

/* Date Time : Tue Jun 27 2023 07:12:20 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", justifyContent: \"space-between\", padding: \"8.5px 20px\", backgroundColor: \"#F2F2F2\", minHeight: \"50px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: 0, border: \"none\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการรอชำระเงิน"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.2rem\", fontWeight: \"bold\", color: \"#0072BC\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 15px\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormGroup",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "FormGroup",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "FormField",
      "parent": 19,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "FormField",
      "parent": 19,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "FormField",
      "parent": 19,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "FormField",
      "parent": 19,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"1.5rem\"}"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 40,
      "name": "Table",
      "parent": 39,
      "props": {
        "data": {
          "type": "code",
          "value": "props.purchaseItems || []"
        },
        "getTdProps": {
          "type": "code",
          "value": "props.getTdProps"
        },
        "headers": {
          "type": "code",
          "value": "[...(props.CheckAll?[props.CheckAll]:[]),\"รหัส\",\"ประเภทบริการ\",\"ประเภทสินค้า\",\"ชื่อสินค้า\",\"ราคา\",\"จำนวน\",\"รายละเอียด\",\"ลบ\"]"
        },
        "keys": {
          "type": "code",
          "value": "`${props.CheckAll?\"check,\":\"\"}code,service_type,product_type,product_name,price,qty,description,delete`"
        },
        "minRows": {
          "type": "code",
          "value": "3"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{maxHeight: \"calc(100vh - 18.5rem)\"}"
        },
        "widths": {
          "type": "code",
          "value": "`${props.CheckAll?\"50,\":\"\"}120,,,,100,90,90,90`"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", fontSize: \"1.1rem\"}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "label",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": "ยอดชำระรวม"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "label",
      "parent": 41,
      "props": {
        "children": {
          "type": "code",
          "value": "props.totalPayment || \"\""
        },
        "style": {
          "type": "code",
          "value": "{color:\"#1B75BB\", margin: \"0 1rem\", fontWeight: \"bold\"}"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "label",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": "บาท"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"2rem\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "FormField",
      "parent": 6,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "11"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "div",
      "parent": 46,
      "props": {
        "children": {
          "type": "code",
          "value": "props.SearchBoxPackageName"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "FormField",
      "parent": 6,
      "props": {
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "label",
      "parent": 48,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา ชื่อ/รหัสสินค้า"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "label",
      "parent": 25,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทบริการ"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "Dropdown",
      "parent": 26,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "serviceType"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.packageServiceTypeOptions|| []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filterPurchaseList?.serviceType || \"\""
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "label",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทสินค้า"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "FormField",
      "parent": 19,
      "props": {
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "FormField",
      "parent": 19,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "label",
      "parent": 54,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะสินค้า"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "Dropdown",
      "parent": 55,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "productStatus"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.packageStatusOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filterPurchaseList?.productStatus || \"\""
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "FormField",
      "parent": 19,
      "props": {
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "div",
      "parent": 58,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSearch"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "div",
      "parent": 41,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonPayment"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "Dropdown",
      "parent": 37,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "productType"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.packageTypeOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filterPurchaseList?.productType|| \"\""
        }
      },
      "seq": 62,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 90,
  "isMounted": false,
  "memo": false,
  "name": "CardPurchaseListUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "price_claimable": 0,
    "price_non_claimable": 0,
    "price_total": 0
  },
  "width": 55
}

*********************************************************************************** */
