import React from 'react'
import {
   Button,
   Checkbox,
   Dropdown,
   Input,
} from 'semantic-ui-react'



import styles from './CardPTPhysicalexaminationOrthopedicOPDLOW.module.css'

export interface CardPTPhysicalexaminationOrthopedicOPDLOWViewDataType {
    Ct6Sensory: string,
    Ct6SensoryDisabled: boolean,
    Ct6SensoryOptions: any,
    PtPhysicalOrthoOpdLow5: string,
    PtPhysicalOrthoOpdLow5Disabled: boolean,
    PtPhysicalOrthoOpdLow8: string,
    PtPhysicalOrthoOpdLow8Disabled: boolean,
    PtPhysicalOrthoOpdLow9: boolean,
    PtPhysicalOrthoOpdLow9Disabled: boolean,
    PtPhysicalOrthoOpdLow10: string,
    PtPhysicalOrthoOpdLow10Disabled: boolean,
    PtPhysicalOrthoOpdLow12: boolean,
    PtPhysicalOrthoOpdLow12Disabled: boolean,
    PtPhysicalOrthoOpdLow13: string,
    PtPhysicalOrthoOpdLow13Disabled: boolean,
    PtPhysicalOrthoOpdLow15: boolean,
    PtPhysicalOrthoOpdLow15Disabled: boolean,
    PtPhysicalOrthoOpdLow16: boolean,
    PtPhysicalOrthoOpdLow16Disabled: boolean,
    Ct3TransferDependent: string,
    Ct3TransferDependentDisabled: boolean,
    Ct3TransferDependentOptions: any,
    PtPhysicalOrthoOpdLow24: boolean,
    PtPhysicalOrthoOpdLow24Disabled: boolean,
    PtPhysicalOrthoOpdLow25: boolean,
    PtPhysicalOrthoOpdLow25Disabled: boolean,
    PtPhysicalOrthoOpdLow26: boolean,
    PtPhysicalOrthoOpdLow26Disabled: boolean,
    PtPhysicalOrthoOpdLow31: string,
    PtPhysicalOrthoOpdLow31Disabled: boolean,
    Ct3GaitPatternAmbulation: string,
    Ct3GaitPatternAmbulationDisabled: boolean,
    Ct3GaitPatternAmbulationOptions: any,
    SaveRequestDisabled: boolean,
}

export const CardPTPhysicalexaminationOrthopedicOPDLOWInitialViewData: CardPTPhysicalexaminationOrthopedicOPDLOWViewDataType = {
    Ct6Sensory: "",
    Ct6SensoryDisabled: false,
    Ct6SensoryOptions: [{key: 0, value: "A", text: "A"}],
    PtPhysicalOrthoOpdLow5: "",
    PtPhysicalOrthoOpdLow5Disabled: false,
    PtPhysicalOrthoOpdLow8: "",
    PtPhysicalOrthoOpdLow8Disabled: false,
    PtPhysicalOrthoOpdLow9: false,
    PtPhysicalOrthoOpdLow9Disabled: false,
    PtPhysicalOrthoOpdLow10: "",
    PtPhysicalOrthoOpdLow10Disabled: false,
    PtPhysicalOrthoOpdLow12: false,
    PtPhysicalOrthoOpdLow12Disabled: false,
    PtPhysicalOrthoOpdLow13: "",
    PtPhysicalOrthoOpdLow13Disabled: false,
    PtPhysicalOrthoOpdLow15: false,
    PtPhysicalOrthoOpdLow15Disabled: false,
    PtPhysicalOrthoOpdLow16: false,
    PtPhysicalOrthoOpdLow16Disabled: false,
    Ct3TransferDependent: "",
    Ct3TransferDependentDisabled: false,
    Ct3TransferDependentOptions: [{key: 0, value: "A", text: "A"}],
    PtPhysicalOrthoOpdLow24: false,
    PtPhysicalOrthoOpdLow24Disabled: false,
    PtPhysicalOrthoOpdLow25: false,
    PtPhysicalOrthoOpdLow25Disabled: false,
    PtPhysicalOrthoOpdLow26: false,
    PtPhysicalOrthoOpdLow26Disabled: false,
    PtPhysicalOrthoOpdLow31: "",
    PtPhysicalOrthoOpdLow31Disabled: false,
    Ct3GaitPatternAmbulation: "",
    Ct3GaitPatternAmbulationDisabled: false,
    Ct3GaitPatternAmbulationOptions: [{key: 0, value: "A", text: "A"}],
    SaveRequestDisabled: false,
}

const Sub = (props: any) => {
    const children = React.Children.toArray(props.children)
    return (
        <div className={styles.container}>
            <div className={styles.PtPhysicalOrthoOpdLow1}>
                <div
                    style={{background: "#F3F3F3", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.Ct6Sensory}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct6Sensory}
                    disabled={ props.viewData.Ct6SensoryDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardPTPhysicalexaminationOrthopedicOPDLOW",
                                name: "Ct6Sensory",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct6SensoryOptions}
                />
            </div>
            <div className={styles.PtPhysicalOrthoOpdLow5}>
                <Input
                    value={props.viewData.PtPhysicalOrthoOpdLow5}
                    disabled={ props.viewData.PtPhysicalOrthoOpdLow5Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPTPhysicalexaminationOrthopedicOPDLOW',
                            name: 'PtPhysicalOrthoOpdLow5',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtPhysicalOrthoOpdLow6}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Sensory</strong></p>
                </div>
            </div>
            <div className={styles.PtPhysicalOrthoOpdLow8}>
                <Input
                    value={props.viewData.PtPhysicalOrthoOpdLow8}
                    disabled={ props.viewData.PtPhysicalOrthoOpdLow8Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPTPhysicalexaminationOrthopedicOPDLOW',
                            name: 'PtPhysicalOrthoOpdLow8',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtPhysicalOrthoOpdLow9}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtPhysicalOrthoOpdLow9}
                    disabled={ props.viewData.PtPhysicalOrthoOpdLow9Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTPhysicalexaminationOrthopedicOPDLOW",
                                name: "PtPhysicalOrthoOpdLow9"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtPhysicalOrthoOpdLow10}>
                <Input
                    value={props.viewData.PtPhysicalOrthoOpdLow10}
                    disabled={ props.viewData.PtPhysicalOrthoOpdLow10Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPTPhysicalexaminationOrthopedicOPDLOW',
                            name: 'PtPhysicalOrthoOpdLow10',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtPhysicalOrthoOpdLow11}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Muscle tone</strong></p>
                </div>
            </div>
            <div className={styles.PtPhysicalOrthoOpdLow12}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtPhysicalOrthoOpdLow12}
                    disabled={ props.viewData.PtPhysicalOrthoOpdLow12Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTPhysicalexaminationOrthopedicOPDLOW",
                                name: "PtPhysicalOrthoOpdLow12"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtPhysicalOrthoOpdLow13}>
                <Input
                    value={props.viewData.PtPhysicalOrthoOpdLow13}
                    disabled={ props.viewData.PtPhysicalOrthoOpdLow13Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPTPhysicalexaminationOrthopedicOPDLOW',
                            name: 'PtPhysicalOrthoOpdLow13',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtPhysicalOrthoOpdLow14}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Normal</p>
                </div>
            </div>
            <div className={styles.PtPhysicalOrthoOpdLow15}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtPhysicalOrthoOpdLow15}
                    disabled={ props.viewData.PtPhysicalOrthoOpdLow15Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTPhysicalexaminationOrthopedicOPDLOW",
                                name: "PtPhysicalOrthoOpdLow15"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtPhysicalOrthoOpdLow16}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtPhysicalOrthoOpdLow16}
                    disabled={ props.viewData.PtPhysicalOrthoOpdLow16Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTPhysicalexaminationOrthopedicOPDLOW",
                                name: "PtPhysicalOrthoOpdLow16"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtPhysicalOrthoOpdLow17}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Spasticity</p>
                </div>
            </div>
            <div className={styles.PtPhysicalOrthoOpdLow18}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Flaccid</p>
                </div>
            </div>
            <div className={styles.PtPhysicalOrthoOpdLow19}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>N/A</p>
                </div>
            </div>
            <div className={styles.PtPhysicalOrthoOpdLow20}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Transfer to wheel chair</strong></p>
                </div>
            </div>
            <div className={styles.Ct3TransferDependent}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct3TransferDependent}
                    disabled={ props.viewData.Ct3TransferDependentDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardPTPhysicalexaminationOrthopedicOPDLOW",
                                name: "Ct3TransferDependent",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct3TransferDependentOptions}
                />
            </div>
            <div className={styles.PtPhysicalOrthoOpdLow24}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtPhysicalOrthoOpdLow24}
                    disabled={ props.viewData.PtPhysicalOrthoOpdLow24Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTPhysicalexaminationOrthopedicOPDLOW",
                                name: "PtPhysicalOrthoOpdLow24"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtPhysicalOrthoOpdLow25}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtPhysicalOrthoOpdLow25}
                    disabled={ props.viewData.PtPhysicalOrthoOpdLow25Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTPhysicalexaminationOrthopedicOPDLOW",
                                name: "PtPhysicalOrthoOpdLow25"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtPhysicalOrthoOpdLow26}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtPhysicalOrthoOpdLow26}
                    disabled={ props.viewData.PtPhysicalOrthoOpdLow26Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPTPhysicalexaminationOrthopedicOPDLOW",
                                name: "PtPhysicalOrthoOpdLow26"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtPhysicalOrthoOpdLow27}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>None</p>
                </div>
            </div>
            <div className={styles.PtPhysicalOrthoOpdLow28}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Independent</p>
                </div>
            </div>
            <div className={styles.PtPhysicalOrthoOpdLow29}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Dependent</p>
                </div>
            </div>
            <div className={styles.PtPhysicalOrthoOpdLow30}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Gait pattern &amp; Ambulation</strong></p>
                </div>
            </div>
            <div className={styles.PtPhysicalOrthoOpdLow31}>
                <Input
                    value={props.viewData.PtPhysicalOrthoOpdLow31}
                    disabled={ props.viewData.PtPhysicalOrthoOpdLow31Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPTPhysicalexaminationOrthopedicOPDLOW',
                            name: 'PtPhysicalOrthoOpdLow31',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.Ct3GaitPatternAmbulation}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct3GaitPatternAmbulation}
                    disabled={ props.viewData.Ct3GaitPatternAmbulationDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardPTPhysicalexaminationOrthopedicOPDLOW",
                                name: "Ct3GaitPatternAmbulation",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct3GaitPatternAmbulationOptions}
                />
            </div>
            <div className={styles.PtPhysicalOrthoOpdLow35}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>By</strong></p>
                </div>
            </div>
            <div className={styles.SaveRequest}>
                <Button
                    disabled={ props.viewData.SaveRequestDisabled }
                    onClick={(e) => { props.onEvent({
                        message: 'clickButton',
                        params: {
                            view: 'CardPTPhysicalexaminationOrthopedicOPDLOW',
                            name: 'SaveRequest'
                        }
                    })}}
                    color="green"
                    style={{width: "100%"}}
                >
                    Save
                </Button>
            </div>
        </div>
    )
}

export default Sub

const getTsName = (cssClass: string) => {
    return cssClass.split('-').map((s) => (
        s.charAt(0).toUpperCase() + s.slice(1)
    )).join('')
}
