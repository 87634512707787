import FormSymbolImages from "./AddSymbolPrintList";

export default function toPDFMakeData(props: any) {
  const injectImages = (haveHeader: boolean) => {
    let symbolUsage = {};
    if (!haveHeader) {
      symbolUsage = {
        checked: FormSymbolImages.checked,
        unchecked: FormSymbolImages.unchecked,
        squarebox: FormSymbolImages.squarebox,
        checkmark: FormSymbolImages.checkmark,
      };
    }
    return symbolUsage;
  };

  return {
    content: [
      {
        decoration: ``,
        decorationStyle: ``,
        text: `หนังสือแสดงความยินยอมการรับโลหิต และ/หรือ ส่วนประกอบของโลหิต`,
        decorationColor: ``,
        width: `auto`,
        alignment: `center`,
        bold: `true`,
        preserveLeadingSpaces: true,
        pageBreak: ``,
        color: ``,
        fontSize: `18`,
      },
      {
        color: ``,
        decorationColor: ``,
        text: ` `,
        fontSize: 15,
        decorationStyle: ``,
        bold: false,
        width: `auto`,
        alignment: `left`,
        decoration: ``,
        preserveLeadingSpaces: true,
        pageBreak: ``,
      },
      {
        columns: [
          {
            bold: false,
            fontSize: 15,
            color: ``,
            decoration: ``,
            decorationStyle: ``,
            width: 30,
            alignment: `left`,
            decorationColor: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            text: ``,
          },
          {
            width: `auto`,
            fontSize: 15,
            decoration: ``,
            text: `ข้าพเจ้า (นาย/นาง/นางสาว)`,
            pageBreak: ``,
            decorationColor: ``,
            color: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            bold: false,
          },
          {
            text: ` `,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            stack: [
              {
                text: props.items?.formatPatient,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.............................................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            width: `auto`,
            text: `ขอแสดงความยินยอมโดยสมัครใจให้`,
            bold: false,
            decorationStyle: ``,
            decorationColor: ``,
            pageBreak: ``,
            alignment: `left`,
            fontSize: 15,
            preserveLeadingSpaces: true,
            color: ``,
            decoration: ``,
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            width: `auto`,
            fontSize: 15,
            color: ``,
            bold: false,
            text: `นายแพทย์/ แพทย์หญิง`,
            decoration: ``,
            pageBreak: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
          },
          {
            text: ` `,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            stack: [
              {
                text: props.items?.formatDoctor,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `................................................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            color: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationColor: ``,
            width: `auto`,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            text: `และพนักงานของโรงพยาบาลที่ได้รับมอบหมาย`,
            alignment: `left`,
            decorationStyle: ``,
          },
        ],
      },
      {
        decorationColor: ``,
        preserveLeadingSpaces: true,
        pageBreak: ``,
        text: `ให้มีส่วนร่วมในการดูแลอาการของข้าพเจ้าและข้าพเจ้าได้รับการอธิบายว่าการรับโลหิต และ/หรือส่วนประกอบของโลหิตตามความ`,
        fontSize: 15,
        decoration: ``,
        bold: false,
        decorationStyle: ``,
        width: `auto`,
        alignment: `left`,
        color: ``,
      },
      {
        fontSize: 15,
        decorationColor: ``,
        decorationStyle: ``,
        bold: false,
        pageBreak: ``,
        preserveLeadingSpaces: true,
        text: `เห็นของแพทย์ ซึ่งข้าพเจ้าได้รับทราบรายละเอียดถึงความจำเป็นในการได้รับโลหิต และ/หรือส่วนประกอบของโลหิต ที่จะใช้ใน`,
        width: `auto`,
        decoration: ``,
        alignment: `left`,
        color: ``,
      },
      {
        text: `การรักษาอาการป่วยของโรคที่ข้าพเจ้าเป็นอยู่นี้ โดยไม่มีทางเลือกอื่น มิฉะนั้น ข้าพเจ้าอาจได้รับอันตรายที่ร้ายแรงได้ นอกจากนี้`,
        fontSize: 15,
        width: `auto`,
        decorationStyle: ``,
        bold: false,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        color: ``,
        decoration: ``,
        alignment: `left`,
        pageBreak: ``,
      },
      {
        decorationStyle: ``,
        color: ``,
        pageBreak: ``,
        width: `auto`,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        alignment: `left`,
        decoration: ``,
        bold: false,
        text: `ข้าพเจ้าทราบว่าจะมีการเจาะเลือดเพื่อติดตามผลการรักษาและอาจมีการให้โลหิตหรือส่วนประกอบของโลหิต เป็นระยะจนกว่าผล`,
        fontSize: 15,
      },
      {
        width: `auto`,
        decoration: ``,
        pageBreak: ``,
        decorationStyle: ``,
        color: ``,
        preserveLeadingSpaces: true,
        fontSize: 15,
        text: `การรักษาเป็นไปตามแผนการรักษาของแพทย์ รวมถึงได้รับทราบข้อมูลเกี่ยวกับวิธีการรับให้โลหิตและ/หรือส่วนประกอบของโลหิต ตลอดจนโอกาสเสี่ยงที่อาจเกิดขึ้นได้จากการรับโลหิต และ/หรือส่วนประกอบของโลหิต ดังต่อไปนี้`,
        decorationColor: ``,
        bold: false,
        alignment: `left`,
      },
      {
        columns: [
          {
            bold: `true`,
            width: `auto`,
            decorationColor: ``,
            decorationStyle: ``,
            color: ``,
            pageBreak: ``,
            fontSize: 15,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decoration: `underline`,
            text: `วิธีการรับโลหิตหรือส่วนประกอบของโลหิต`,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            fontSize: 15,
            text: ``,
            alignment: `left`,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            color: ``,
            pageBreak: ``,
            width: 30,
            preserveLeadingSpaces: true,
          },
          {
            preserveLeadingSpaces: true,
            bold: false,
            text: `การรับโลหิต และ/หรือส่วนประกอบของโลหิต จะได้รับโดยการผ่านทางหลอดเลือดดำ ด้วยวิธีการปลอดเชื้อ ประกอบด้วย`,
            width: `auto`,
            color: ``,
            decorationStyle: ``,
            fontSize: 15,
            decorationColor: ``,
            pageBreak: ``,
            alignment: `left`,
            decoration: ``,
          },
        ],
      },
      {
        decorationStyle: ``,
        decorationColor: ``,
        decoration: ``,
        bold: false,
        text: `การทำความสะอาดผิวหนังบริเวณที่แทงเข็ม การตรวจสอบความถูกต้องว่าได้ให้ถูกคนและถูกชนิด การเฝ้าระวังความผิดปกติต่างๆ ตลอดจนอาการผิดปกติอื่นๆ ที่อาจเกิดขึ้นได้ในระหว่างที่ได้รับหรือหลังการให้รับโลหิต และ/หรือส่วนประกอบของโลหิต โดยการวัด`,
        pageBreak: ``,
        fontSize: 15,
        width: `auto`,
        alignment: `left`,
        color: ``,
        preserveLeadingSpaces: true,
      },
      {
        alignment: `left`,
        width: `auto`,
        text: `สัญญาณชีพเป็นระยะๆ ตั้งแต่ก่อนได้รับ/ระหว่างการได้รับและหลังการได้รับโลหิต และ/หรือส่วนประกอบของโลหิต`,
        preserveLeadingSpaces: true,
        color: ``,
        pageBreak: ``,
        decoration: ``,
        decorationStyle: ``,
        fontSize: 15,
        decorationColor: ``,
        bold: false,
      },
      {
        columns: [
          {
            decorationColor: ``,
            fontSize: 15,
            decorationStyle: ``,
            width: `auto`,
            bold: `true`,
            preserveLeadingSpaces: true,
            color: ``,
            decoration: `underline`,
            alignment: `left`,
            pageBreak: ``,
            text: `โอกาสเสี่ยงจากการได้รับโลหิตหรือส่วนประกอบของโลหิต`,
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            pageBreak: ``,
            decorationColor: ``,
            decoration: ``,
            text: ``,
            color: ``,
            width: 30,
            fontSize: 15,
            bold: false,
            preserveLeadingSpaces: true,
            alignment: `left`,
          },
          {
            text: `โดยทั่วไปถือให้ว่าการได้รับโลหิต และ/หรือส่วนประกอบของโลหิต มีอัตราเสี่ยงน้อย ให้แก่`,
            bold: false,
            decorationStyle: ``,
            pageBreak: ``,
            alignment: `left`,
            decorationColor: ``,
            fontSize: 15,
            color: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
          },
        ],
      },
      {
        columns: [
          {
            alignment: `left`,
            decorationColor: ``,
            text: ``,
            fontSize: 15,
            width: 30,
            preserveLeadingSpaces: true,
            bold: false,
            pageBreak: ``,
            decorationStyle: ``,
            color: ``,
            decoration: ``,
          },
          {
            decorationStyle: ``,
            text: `- ปฏิกิริยาชั่วคราวและไม่รุนแรง ได้แก่ การมีรอยช้ำเขียว บวม และเจ็บในตำแหน่งที่แทงเข็ม นอกจากนี้อาจมีอาการข้างเคียง`,
            decoration: ``,
            pageBreak: ``,
            width: `auto`,
            decorationColor: ``,
            bold: false,
            fontSize: 15,
            preserveLeadingSpaces: true,
            alignment: `left`,
            color: ``,
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            width: 30,
            bold: false,
            pageBreak: ``,
            decorationStyle: ``,
            text: ``,
            decorationColor: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            color: ``,
          },
          {
            pageBreak: ``,
            alignment: `left`,
            fontSize: 15,
            text: `จากส่วนประกอบของโลหิต ได้แก่ อาการปวดหัว เป็นไข้ หนาวสั่น หรือมีผื่นคันที่ผิวหนัง`,
            decoration: ``,
            width: `auto`,
            decorationColor: ``,
            color: ``,
            bold: false,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            fontSize: 15,
            decoration: ``,
            text: ``,
            alignment: `left`,
            pageBreak: ``,
            width: 30,
            color: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
          },
          {
            decorationColor: ``,
            bold: false,
            width: `auto`,
            color: ``,
            fontSize: 15,
            pageBreak: ``,
            decorationStyle: ``,
            decoration: ``,
            alignment: `left`,
            text: `- ปฏิกิริยาที่รุนแรง ซึ่งอาจถึงแก่ชีวิตได้ แต่มีโอกาสเกิดขึ้นได้น้อยมาก เนื่องจากโลหิต หรือส่วนประกอบของโลหิตทุกหน่วย`,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            alignment: `left`,
            pageBreak: ``,
            decorationStyle: ``,
            text: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            color: ``,
            width: 30,
            fontSize: 15,
            decorationColor: ``,
          },
          {
            pageBreak: ``,
            text: `ได้รับการตรวจสอบการเข้ากันได้กับผู้ป่วยอย่างรอบคอบแล้ว`,
            width: `auto`,
            alignment: `left`,
            decoration: ``,
            decorationColor: ``,
            decorationStyle: ``,
            bold: false,
            preserveLeadingSpaces: true,
            fontSize: 15,
            color: ``,
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            decoration: ``,
            bold: false,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            width: 30,
            text: ``,
            alignment: `left`,
            fontSize: 15,
            color: ``,
            decorationColor: ``,
          },
          {
            width: `auto`,
            text: `- การติดเชื้อ การติดเชื้อโรคบางชนิดอาจเกิดจากการได้รับโลหิตหรือส่วนประกอบของโลหิตได้ เช่น เชื้อไวรัสตับอักเสบ มาลาเรีย เชื้อเอช ไอ วี เป็นต้น อย่างไรก็ดี อัตราการติดเชื้อเกิดขึ้นได้น้อยมาก ทั้งนี้เนื่องจากโลหิตและส่วนประกอบของโลหิต`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationColor: ``,
            color: ``,
            decoration: ``,
            fontSize: 15,
            bold: false,
            decorationStyle: ``,
            alignment: `left`,
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            width: 30,
            decoration: ``,
            text: ``,
            fontSize: 15,
            bold: false,
            decorationColor: ``,
            decorationStyle: ``,
            pageBreak: ``,
            alignment: `left`,
            color: ``,
          },
          {
            decoration: ``,
            width: `auto`,
            color: ``,
            bold: false,
            pageBreak: ``,
            text: `ทุกหน่วยได้รับการตรวจกรองการติดเชื้อดังกล่าวตามวิธีมาตรฐานระดับสากลแล้ว`,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decorationStyle: ``,
            fontSize: 15,
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            text: ``,
            bold: false,
            pageBreak: ``,
            decorationColor: ``,
            width: 30,
            decoration: ``,
            color: ``,
            fontSize: 15,
          },
          {
            decorationColor: ``,
            alignment: `left`,
            fontSize: 15,
            width: `auto`,
            text: `ณ ที่นี้ข้าพเจ้าได้อ่าน/ได้รับทราบข้อความข้างต้น ตลอดจนได้รับคำอธิบายจากแพทย์/พยาบาล รวมถึงเข้าใจในผลที่อาจจะ`,
            decoration: ``,
            preserveLeadingSpaces: true,
            bold: false,
            pageBreak: ``,
            decorationStyle: ``,
            color: ``,
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            width: 30,
            decoration: ``,
            decorationColor: ``,
            color: ``,
            decorationStyle: ``,
            pageBreak: ``,
            alignment: `left`,
            bold: false,
            preserveLeadingSpaces: true,
            text: ``,
          },
          {
            bold: false,
            decoration: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decorationStyle: ``,
            alignment: `left`,
            text: `เกิดขึ้น โดยไม่มีข้อสงสัยแล้ว จึงขอแสดงความยินยอมเพื่อรับการรักษาดังกล่าวข้างต้น`,
            pageBreak: ``,
            decorationColor: ``,
            width: `auto`,
            color: ``,
          },
        ],
      },
      {
        color: ``,
        decoration: ``,
        fontSize: 15,
        pageBreak: ``,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        bold: false,
        text: ` `,
        alignment: `left`,
        decorationColor: ``,
        width: `auto`,
      },
      {
        columns: [
          {
            text: `ลงนาม........................................................  `,
            color: ``,
            preserveLeadingSpaces: true,
            width: 200,
            decorationStyle: ``,
            alignment: `center`,
            pageBreak: ``,
            decorationColor: ``,
            bold: false,
            fontSize: 15,
            decoration: ``,
          },
          {
            bold: false,
            color: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            pageBreak: ``,
            width: 80,
            decorationStyle: ``,
            fontSize: 15,
            decoration: ``,
            text: `  `,
          },
          {
            bold: false,
            width: 200,
            text: `พยาน 1 ลงนาม........................................................ `,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            alignment: `center`,
            pageBreak: ``,
            decorationColor: ``,
            fontSize: 15,
            color: ``,
            decoration: ``,
          },
        ],
      },
      {
        columns: [
          {
            stack: [
              {
                decorationStyle: ``,
                color: ``,
                width: `auto`,
                preserveLeadingSpaces: true,
                decoration: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                decorationColor: ``,
                text: props.items?.full_name,
                alignment: `center`,
                margin: [0, 0, 0, -15],
              },
              {
                decorationStyle: ``,
                color: ``,
                width: 200,
                preserveLeadingSpaces: true,
                decoration: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                decorationColor: ``,
                text: `(.............................................................)`,
                alignment: `center`,
                margin: [0, 0, 0, 0],
              },
            ],
            width: 200,
          },
          {
            alignment: `left`,
            fontSize: 15,
            color: ``,
            text: ``,
            pageBreak: ``,
            decorationStyle: ``,
            width: 80,
            decoration: ``,
            decorationColor: ``,
            bold: false,
            preserveLeadingSpaces: true,
          },
          {
            fontSize: 15,
            bold: false,
            pageBreak: ``,
            alignment: `center`,
            color: ``,
            text: `(.............................................................)`,
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationStyle: ``,
            width: 200,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            width: 30,
            decorationColor: ``,
            text: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            pageBreak: ``,
            bold: false,
            alignment: `center`,
            color: ``,
            decoration: ``,
            decorationStyle: ``,
          },
          {
            columns: [
              {
                width: `auto`,
                decorationColor: ``,
                text: `วันที่`,
                preserveLeadingSpaces: true,
                fontSize: 15,
                pageBreak: ``,
                bold: false,
                alignment: `left`,
                color: ``,
                decoration: ``,
                decorationStyle: ``,
              },
              {
                stack: [
                  {
                    width: `auto`,
                    decorationColor: ``,
                    text: props.items?.signedDate,
                    preserveLeadingSpaces: true,
                    fontSize: 15,
                    pageBreak: ``,
                    bold: false,
                    alignment: `center`,
                    color: ``,
                    decoration: ``,
                    decorationStyle: ``,
                    margin: [0, 0, 0, -15],
                  },
                  {
                    width: `auto`,
                    decorationColor: ``,
                    text: `........................`,
                    preserveLeadingSpaces: true,
                    fontSize: 15,
                    pageBreak: ``,
                    bold: false,
                    alignment: `left`,
                    color: ``,
                    decoration: ``,
                    decorationStyle: ``,
                    margin: [0, 0, 0, 0],
                  },
                ],
                width: `auto`,
              },
              {
                width: `auto`,
                decorationColor: ``,
                text: `เวลา`,
                preserveLeadingSpaces: true,
                fontSize: 15,
                pageBreak: ``,
                bold: false,
                alignment: `left`,
                color: ``,
                decoration: ``,
                decorationStyle: ``,
              },
              {
                stack: [
                  {
                    width: `auto`,
                    decorationColor: ``,
                    text: props.items?.signedTime,
                    preserveLeadingSpaces: true,
                    fontSize: 15,
                    pageBreak: ``,
                    bold: false,
                    alignment: `center`,
                    color: ``,
                    decoration: ``,
                    decorationStyle: ``,
                    margin: [0, 0, 0, -15],
                  },
                  {
                    width: `auto`,
                    decorationColor: ``,
                    text: `..................`,
                    preserveLeadingSpaces: true,
                    fontSize: 15,
                    pageBreak: ``,
                    bold: false,
                    alignment: `left`,
                    color: ``,
                    decoration: ``,
                    decorationStyle: ``,
                    margin: [0, 0, 0, 0],
                  },
                ],
                width: `auto`,
              },
            ],
            width: 200,
          },
          {
            fontSize: 15,
            bold: false,
            text: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            color: ``,
            decorationColor: ``,
            decorationStyle: ``,
            alignment: `left`,
            width: 50,
            decoration: ``,
          },
          {
            width: 200,
            text: `พยาน 2 ลงนาม........................................................ `,
            pageBreak: ``,
            alignment: `center`,
            bold: false,
            preserveLeadingSpaces: true,
            fontSize: 15,
            color: ``,
            decorationColor: ``,
            decoration: ``,
            decorationStyle: ``,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            decorationStyle: ``,
            decoration: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            color: ``,
            text: ``,
            width: 280,
            fontSize: 15,
            pageBreak: ``,
          },
          {
            preserveLeadingSpaces: true,
            alignment: `center`,
            width: 200,
            fontSize: 15,
            color: ``,
            bold: false,
            decorationColor: ``,
            decoration: ``,
            decorationStyle: ``,
            pageBreak: ``,
            text: `(.............................................................)`,
          },
        ],
      },
      {
        alignment: `left`,
        width: `auto`,
        decorationStyle: ``,
        preserveLeadingSpaces: true,
        decoration: ``,
        pageBreak: `before`,
        text: ``,
        fontSize: 15,
        bold: false,
        decorationColor: ``,
        color: ``,
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            color: ``,
            pageBreak: ``,
            decorationColor: ``,
            bold: `true`,
            width: `auto`,
            text: `การแปลและให้ข้อมูลโดยผู้แปลภาษา`,
            alignment: `left`,
            fontSize: 15,
            decoration: `underline`,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            decoration: ``,
            width: 30,
            alignment: `left`,
            pageBreak: ``,
            fontSize: 15,
            decorationColor: ``,
            color: ``,
            text: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
          },
          {
            decorationStyle: ``,
            text: `ข้าพเจ้าได้แปลหนังสือแสดงความยินยอมการรับโลหิต และ/หรือ ส่วนประกอบของโลหิต (Consent for Blood/Blood `,
            color: ``,
            alignment: `left`,
            width: `auto`,
            fontSize: 15,
            bold: false,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            pageBreak: ``,
          },
        ],
      },
      {
        color: ``,
        alignment: `left`,
        fontSize: 15,
        preserveLeadingSpaces: true,
        bold: false,
        decorationStyle: ``,
        decoration: ``,
        width: `auto`,
        decorationColor: ``,
        text: `Product Transfusion) รวมทั้งข้อมูลซึ่งแพทย์ได้อธิบายให้ผู้ป่วย/ผู้แทนผู้ป่วยทราบ`,
        pageBreak: ``,
      },
      {
        text: ` `,
        fontSize: 15,
        preserveLeadingSpaces: true,
        width: `auto`,
        decoration: ``,
        decorationStyle: ``,
        alignment: `left`,
        color: ``,
        bold: false,
        decorationColor: ``,
        pageBreak: ``,
      },
      {
        columns: [
          {
            text: `ภาษาที่แปล............................................ `,
            bold: false,
            color: ``,
            alignment: `center`,
            decoration: ``,
            decorationColor: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            width: 200,
            fontSize: 15,
            decorationStyle: ``,
          },
          {
            text: ` `,
            decorationColor: ``,
            fontSize: 15,
            width: 80,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            pageBreak: ``,
            color: ``,
            bold: false,
            alignment: `left`,
          },
          {
            decoration: ``,
            width: 200,
            alignment: `center`,
            preserveLeadingSpaces: true,
            color: ``,
            pageBreak: ``,
            text: `ผู้แปลลงนาม........................................................`,
            fontSize: 15,
            decorationStyle: ``,
            bold: false,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            width: 280,
            color: ``,
            decorationStyle: ``,
            decorationColor: ``,
            decoration: ``,
            text: ``,
            pageBreak: ``,
            fontSize: 15,
            alignment: `left`,
            bold: false,
          },
          {
            width: 200,
            fontSize: 15,
            bold: false,
            pageBreak: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            text: `(.............................................................)`,
            decorationColor: ``,
            color: ``,
            decoration: ``,
            alignment: `center`,
          },
        ],
      },
      {
        fontSize: 15,
        preserveLeadingSpaces: true,
        text: ` `,
        decorationColor: ``,
        decorationStyle: ``,
        alignment: `left`,
        bold: false,
        width: `auto`,
        decoration: ``,
        pageBreak: ``,
        color: ``,
      },
      {
        color: ``,
        decoration: ``,
        bold: `true`,
        pageBreak: ``,
        alignment: `left`,
        text: `สถานะของผู้ลงนาม (ตามประมวลกฎหมายแพ่งและพาณิชย์)`,
        decorationColor: ``,
        decorationStyle: ``,
        preserveLeadingSpaces: true,
        width: `auto`,
        fontSize: 15,
      },
      {
        columns: [
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            width: 5,
            decorationColor: ``,
            color: ``,
            text: ``,
            pageBreak: ``,
            bold: false,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decoration: ``,
            fontSize: 15,
            decorationStyle: ``,
          },
          {
            text: `1. ผู้ป่วย ซึ่งบรรลุนิติภาวะ และมีสติสัมปชัญญะสมบูรณ์`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            alignment: `left`,
            width: `auto`,
            bold: false,
            color: ``,
            fontSize: 15,
            decorationStyle: ``,
            decorationColor: ``,
            decoration: ``,
          },
        ],
      },
      {
        columns: [
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            decorationStyle: ``,
            bold: false,
            width: 5,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decoration: ``,
            fontSize: 15,
            text: ``,
            alignment: `left`,
            decorationColor: ``,
            color: ``,
          },
          {
            decorationColor: ``,
            color: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            pageBreak: ``,
            bold: false,
            text: `2. สามีหรือภรรยาตามกฎหมาย กรณีที่ผู้ป่วยไม่มีสติสัมปชัญญะ (ไม่รู้สึกตัว)`,
            width: `auto`,
            decorationStyle: ``,
            alignment: `left`,
            fontSize: 15,
          },
        ],
      },
      {
        columns: [
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            bold: false,
            preserveLeadingSpaces: true,
            text: ``,
            alignment: `left`,
            pageBreak: ``,
            fontSize: 15,
            decorationColor: ``,
            color: ``,
            width: 5,
            decorationStyle: ``,
            decoration: ``,
          },
          {
            pageBreak: ``,
            fontSize: 15,
            bold: false,
            color: ``,
            preserveLeadingSpaces: true,
            text: `3. ผู้ใช้อำนาจปกครองกรณีผู้ป่วยอายุต่ำกว่า 20 ปี บริบูรณ์ที่ยังไม่บรรลุนิติภาวะ`,
            decorationColor: ``,
            width: `auto`,
            alignment: `left`,
            decorationStyle: ``,
            decoration: ``,
          },
        ],
      },
      {
        columns: [
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            text: ``,
            pageBreak: ``,
            fontSize: 15,
            bold: false,
            decorationStyle: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            color: ``,
            width: 5,
            decoration: ``,
            alignment: `left`,
          },
          {
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            fontSize: 15,
            alignment: `left`,
            width: `auto`,
            text: `4. ผู้พิทักษ์ กรณีผู้ป่วยเป็นคนเสมือนไร้ความสามารถ  (ตามคำสั่งศาล)`,
            bold: false,
            decorationColor: ``,
            color: ``,
            decoration: ``,
          },
        ],
      },
      {
        columns: [
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            bold: false,
            text: ``,
            preserveLeadingSpaces: true,
            width: 5,
            decorationColor: ``,
            fontSize: 15,
            decorationStyle: ``,
            pageBreak: ``,
            decoration: ``,
            color: ``,
            alignment: `left`,
          },
          {
            alignment: `left`,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            pageBreak: ``,
            width: `auto`,
            bold: false,
            fontSize: 15,
            decorationStyle: ``,
            text: `5. ผู้อนุบาล กรณีผู้ป่วยเป็นคนไร้ความสามารถ (ตามคำสั่งศาล)`,
            color: ``,
            decoration: ``,
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            preserveLeadingSpaces: true,
            text: ``,
            alignment: `left`,
            width: 15,
            fontSize: 15,
            decorationStyle: ``,
            pageBreak: ``,
            color: ``,
            bold: false,
            decoration: ``,
          },
          {
            pageBreak: ``,
            alignment: `left`,
            bold: false,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            text: `*กรณีข้อ 2-5 ให้ขอสำเนาเอกสารบัตรประชาชน หนังสือเดินทาง เอกสารที่ออกโดยราชการจากผู้ลงนามแทน โดยปกปิดข้อมูลศาสนาและหมู่เลือด ซึ่งปรากฎบนสำเนาดังกล่าว (หากมี) พร้อมลงนามรับรองสำเนาถูกต้อง`,
            decoration: ``,
            color: ``,
            width: `auto`,
            fontSize: 15,
            decorationColor: ``,
          },
        ],
      },
      {
        width: `auto`,
        pageBreak: ``,
        fontSize: 15,
        color: ``,
        decorationColor: ``,
        decorationStyle: ``,
        bold: false,
        alignment: `left`,
        decoration: ``,
        preserveLeadingSpaces: true,
        text: ` `,
      },
      {
        columns: [
          {
            width: `auto`,
            alignment: `left`,
            decorationStyle: ``,
            color: ``,
            bold: false,
            decoration: ``,
            text: `ความสัมพันธ์กับผู้ป่วย....................................................................................................................... `,
            decorationColor: ``,
            fontSize: 15,
            pageBreak: ``,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            width: `auto`,
            pageBreak: ``,
            text: `เลขที่บัตรประจำตัวประชาชนผู้ลงนามแทนผู้ป่วย............................................................................. `,
            decorationColor: ``,
            fontSize: 15,
            decorationStyle: ``,
            alignment: `left`,
            decoration: ``,
            bold: false,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            pageBreak: ``,
            alignment: `left`,
            color: ``,
            decorationColor: ``,
            text: `เบอร์โทรศัพท์....................................................................................................................................`,
            width: `auto`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            fontSize: 15,
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            decoration: ``,
            alignment: `left`,
            text: `อีเมล.................................................................................................................................................. `,
            color: ``,
            bold: false,
            width: `auto`,
            decorationColor: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
          },
        ],
      },
    ],
    pageSize: `A4`,
    defaultStyle: {
      font: `THSarabunNew`,
    },
    pageOrientation: `portrait`,
    images: injectImages(props.items?.haveHeader),
  };
}
