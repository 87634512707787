import WasmController from "react-lib/frameworks/WasmController";
import { HandleGetEmployeeTokenization } from "../../TPD/TPDInterface";
import SupplyTransferList from "issara-sdk/apis/SupplyTransferList_apps_MSD";
import SupplyTransferDetail from "issara-sdk/apis/SupplyTransferDetail_apps_MSD";

export type State = {
  SupplyDeliveryHistorySequence?: {
    sequenceIndex?: any;
    selectedType?: string | null;
    selectedStatus?: string | null;
    selectedOrderDivision?: string | null;
    selectedPerformDivision?: string | null;
    selectedOrderStartDate?: string | null;
    selectedOrderEndDate?: string | null;
    selectedPerformStartDate?: string | null;
    selectedPerformEndDate?: string | null;
    selectedUser?: string | null;
    checkedType?: boolean | null;
    checkedStatus?: boolean | null;
    checkedOrderDivision?: boolean | null;
    checkedPerformDivision?: boolean | null;
    checkedOrderDate?: boolean | null;
    checkedPerformDate?: boolean | null;
    checkedUser?: boolean | null;
    loading?: boolean | null;
    error?: any | null;
    selectedUserToken?: string | null;

    supplyDeliveryHistoryList?: any | null;
  } | null;
  successMessage?: any;
  errorMessage?: any;
  buttonLoadCheck?: any;
  DispensingOrderSupplySequence?: any;
  SupplyDeliverySequence?:any
};

export const StateInitial: State = {
  SupplyDeliveryHistorySequence: {},
  successMessage: null,
  errorMessage: null,
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  division?: number;
};

const LIMIT = 40;

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const SearchAndSelect: Handler = async (controller, params) => {
  var state = controller.getState();

  // Master data
  await controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["division", {}],
        ["divisionSupply", {}],
        ["supplyStatus", {}],
        ["supplyType", {}],
      ],
    },
  });

  state = controller.getState();

  controller.setState({
    SupplyDeliveryHistorySequence: {
      ...state.SupplyDeliveryHistorySequence,
    },
  });

  if (params.action === "search") {
    const data = state.SupplyDeliveryHistorySequence;

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "LOADING",
      },
    });

    const [r, e, n] = await SupplyTransferList.list({
      params: {
        status: data?.checkedStatus ? data?.selectedStatus : "",
        order_division: data?.checkedOrderDivision
          ? data?.selectedOrderDivision
          : "",
        perform_division: data?.checkedPerformDivision
          ? data?.selectedPerformDivision
          : "",
        user: data?.checkedUser ? data?.selectedUserToken : "",
        order_from_date: data?.checkedOrderDate
          ? data?.selectedOrderStartDate
          : "",
        order_to_date: data?.checkedOrderDate ? data?.selectedOrderEndDate : "",
        perform_from_date: data?.checkedPerformDate
          ? data?.selectedPerformStartDate
          : "",
        perform_to_date: data?.checkedPerformDate
          ? data?.selectedPerformEndDate
          : "",
      },
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });
    if (e) {
      if (params.card) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.card]: { error: e },
          },
        });
      }
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "ERROR",
        },
        errorMessage: {
          ...state.errorMessage,
          [params.card]: { error: e },
        },
      });
    } else {
      controller.setState({
        successMessage: { ...state.successMessage, [params?.card]: r },
        errorMessage: { ...state.errorMessage, [params.card]: { error: null } },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "SUCCESS",
        },
        SupplyDeliveryHistorySequence: {
          ...state.SupplyDeliveryHistorySequence,
          supplyDeliveryHistoryList: r?.items,
        },
      });
    }
  } else if (params.action === "searchToken"){
    controller.setState({
        SupplyDeliveryHistorySequence: {
          ...state.SupplyDeliveryHistorySequence,
          loading: true,
        },
      });
  
      const [response, error, network] = await HandleGetEmployeeTokenization(
        controller as any,
        {
          code: params.code,
        }
      );
  
      if (error) {
        controller.setState({
            SupplyDeliveryHistorySequence: {
            ...state.SupplyDeliveryHistorySequence,
            error: error,
            loading: false,
          },
        });
        return;
      }
      controller.setState({
        SupplyDeliveryHistorySequence: {
          ...state.SupplyDeliveryHistorySequence,
          selectedUserToken: response?.token || "",
          selectedUser:
            decodeURIComponent(atob(response?.token.split(".")[1])) || "",
          loading: false,
          checkedUser: true,
        },
      });
  } else if (params.action === "clear"){
    controller.setState({
        SupplyDeliveryHistorySequence:{
            ...state.SupplyDeliveryHistorySequence,
            checkedOrderDate:false,
            checkedOrderDivision:false,
            checkedPerformDate:false,
            checkedPerformDivision:false,
            checkedStatus:false,
            checkedType:false,
            checkedUser:false,
            loading:false,
            error:null,
            selectedOrderDivision:"",
            selectedOrderEndDate:"",
            selectedOrderStartDate:"",
            selectedPerformDivision:"",
            selectedPerformEndDate:"",
            selectedPerformStartDate:"",
            selectedStatus:"",
            selectedType:"",
            selectedUser:"",
            selectedUserToken:""
        }
    })
  } else if (params.action === "select"){
    const supplyTransfer = await SupplyTransferDetail.retrieve({
        pk: params.orderId,
        apiToken: controller.apiToken,
        extra: {
          division: controller.data.division,
        },
      })

      console.log("supplyTransfer",supplyTransfer);
      

      controller.setState({
        SupplyDeliverySequence: {
          sequenceIndex: "SearchAndSelect",
          selectedTransferId: supplyTransfer?.[0]?.pk,
          deliveryManager: supplyTransfer?.[0],
          isCardReceive:false
        },
      });
  }
};
