import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Label,
  Input,
  Button
} from 'semantic-ui-react'

const CardUnderlyingDiseaseUX = (props: any) => {
    return(
      <div>
        <div
          className="ui accordion fluid raised segment"
          style={{boxShadow: "none"}}>
          
          <div
            style={{display: "flex", justifyContent: "space-between"}}>
            
            <label
              style={{fontSize: "1.2rem", fontWeight: "bold"}}>
              ประวัติโรคประจำตัวและภาวะเฝ้าระวังของผู้ป่วย
            </label>
            <div
              style={{cursor: "pointer"}}>
              {}
            </div>
          </div>
          <div
            className="ui divider">
            
          </div>
          <div
            style={{display:"flex"}}>
            
            <div
              style={{marginRight:"10px",marginTop:"5px", fontWeight: "bold"}}>
              ข้อมูลโรคประจำตัวจาก Encounter :
            </div>
            <Label>
              {props?.encounter}
            </Label>
          </div>
          <div
            style={{ margin: "10px 0px 5px", fontWeight: "bold" }}>
            การซักประวัติ
          </div>
          <div
            style={{ display: "flex" , alignItems: "center"}}>
            
            <Input
              onChange={props.onChangeData}
              style={{ width: "100%" }}
              value={props.message}>
            </Input>
            <Button
              color="green"
              onClick={props.onAddData}
              style={{ width: "8%", marginLeft: "10px"}}>
              เพิ่ม
            </Button>
          </div>
          <div
            style={{ border:"2px solid #ccc", borderRadius: "4px", margin: "10px 0px" }}>
            {props?.underlyingDiseaseList}
          </div>
        </div>
      </div>
    )
}

export default CardUnderlyingDiseaseUX

export const screenPropsDefault = {}

/* Date Time : Wed Nov 16 2022 07:34:03 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui accordion fluid raised segment"
        },
        "style": {
          "type": "code",
          "value": "{boxShadow: \"none\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "label",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "ประวัติโรคประจำตัวและภาวะเฝ้าระวังของผู้ป่วย"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.2rem\", fontWeight: \"bold\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{cursor: \"pointer\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "ข้อมูลโรคประจำตัวจาก Encounter :"
        },
        "style": {
          "type": "code",
          "value": "{marginRight:\"10px\",marginTop:\"5px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "Label",
      "parent": 7,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.encounter"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.underlyingDiseaseList"
        },
        "style": {
          "type": "code",
          "value": "{ border:\"2px solid #ccc\", borderRadius: \"4px\", margin: \"10px 0px\" }"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "การซักประวัติ"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"10px 0px 5px\", fontWeight: \"bold\" }"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\" , alignItems: \"center\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "Input",
      "parent": 13,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        },
        "value": {
          "type": "code",
          "value": "props.message"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "Button",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่ม"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onAddData"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"8%\", marginLeft: \"10px\"}"
        }
      },
      "seq": 16,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 70,
  "isMounted": false,
  "memo": false,
  "name": "CardUnderlyingDiseaseUX",
  "project": "New Project",
  "screenPropsDefault": {
  },
  "width": 65
}

*********************************************************************************** */
