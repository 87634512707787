import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import {
  applyTextSpacing,
  applyTextSpacingforBranch,
} from "react-lib/apps/HISV3/CLM/pdf/FormTaxUtils";

// PDF
const PDF = "/static/pdf/taxDoctor/280360_attach3_kor.pdf";
const Font = "/static/fonts/THSarabunNew-Bold.ttf";

const FormPND3A = async (data: any, number: number) => {
  try {
    const existingPdfBytes = await fetch(PDF).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Load your custom font
    const fontUrl = Font; // Replace with your font URL
    const fontBytes = await fetch(fontUrl).then((res) => res.arrayBuffer());
    pdfDoc.registerFontkit(fontkit);
    const customFont = await pdfDoc.embedFont(fontBytes);

    const getDecimal = (value: number) => {
      let rounded = Math.floor(value);

      return (value - rounded || 0).toFixed(2)?.toLocaleString().slice(2, 4) || "";
    };

    const pages = pdfDoc.getPages();
    const firstPage = pages[0];
    const fontSize = 12;
    const textCoordinates = [
      { text: data?.taxId, x: 450, y: 528 },

      { text: data?.branch, x: 758, y: 543 },

      { text: data?.sheetNo, x: 693, y: 525 },
      { text: data?.quantity?.toString(), x: 750, y: 525 },

      {
        text:
          data?.fields?.[number]?.total >= 0
            ? Number(data?.fields?.[number]?.total?.toFixed(0))?.toLocaleString()
            : "",
        x:
          687 -
          customFont?.widthOfTextAtSize(
            (data?.fields?.[number]?.total || 0)?.toFixed(0)?.toLocaleString(),
            fontSize
          ),
        y: 143,
      },
      {
        text:
          data?.fields?.[number]?.total >= 0
            ? data?.fields?.[number]?.total === 0
              ? "00"
              : getDecimal(Number(data?.fields?.[number]?.total))
            : "",
        x: 693,
        y: 143,
      },
      {
        text: data?.totalTaxIf?.toLocaleString(),
        x: 787 - customFont.widthOfTextAtSize((data?.totalTaxIf || 0)?.toLocaleString(), fontSize),
        y: 143,
      },
      {
        text:
          data?.totalTaxIfDecimal === 0 ? "00" : data?.totalTaxIfDecimal?.toString().slice(0, 2),
        x: 790,
        y: 143,
      },
      { text: data?.payerFullName, x: 630, y: 100 },
      { text: data?.position, x: 655, y: 80 },
      { text: data?.submitDate, x: 600, y: 63 },
      { text: data?.submitMonth, x: 660, y: 63 },
      { text: data?.submitYear, x: 725, y: 63 },
      // Add other text fields here...
    ];

    for (const { text, x, y } of textCoordinates) {
      if (text === data?.taxId) {
        applyTextSpacing(text, x, y, customFont, fontSize, firstPage);
      } else if (text === data?.branch) {
        applyTextSpacingforBranch(text, x, y, customFont, fontSize, firstPage);
      } else {
        // Draw the text as it is without any adjustments
        firstPage.drawText(text, {
          x,
          y,
          size: fontSize,
          font: customFont,
          color: rgb(0, 0, 0),
        });
      }
    }

    // Drawing earnings section
    const initialY = 450;
    const yStep = 51.5;

    for (let i = 0; i < data?.fields?.[number]?.items?.length; i++) {
      const earning = data?.fields?.[number]?.items?.[i];

      const x = 100;
      const y = initialY - yStep * i;

      firstPage.drawText(`${earning?.seq || ""}`, {
        x: x - 25,
        y: y - 20,
        size: fontSize,
        font: customFont,
        color: rgb(0, 0, 0),
      });
      applyTextSpacing(`${earning?.citizen_id || ""}`, x + 4, y, customFont, fontSize, firstPage);

      // firstPage.drawText(earning.prefix, {
      //   x: x + 14,
      //   y: y - 15,
      //   size: fontSize,
      //   font: customFont,
      //   color: rgb(0, 0, 0),
      // });
      firstPage.drawText(`${earning?.first_name || ""}`, {
        x: x + 35,
        y: y - 16,
        size: fontSize,
        font: customFont,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(`${earning?.last_name || ""}`, {
        x: x + 210,
        y: y - 16,
        size: fontSize,
        font: customFont,
        color: rgb(0, 0, 0),
      });

      // firstPage.drawText(earning.address, {
      //   x: x + 22,
      //   y: y - 31,
      //   size: fontSize,
      //   font: customFont,
      //   color: rgb(0, 0, 0),
      // });

      // applyTextSpacingforBranch(earning.earnerBranch, x + 250, y, customFont, fontSize, firstPage);

      // firstPage.drawText(earning.moneyType, {
      //   x: x + 400,
      //   y,
      //   size: fontSize,
      //   font: customFont,
      //   color: rgb(0, 0, 0),
      // });

      // firstPage.drawText(earning.taxPercent?.toString(), {
      //   x: x + 509,
      //   y,
      //   size: fontSize,
      //   font: customFont,
      //   color: rgb(0, 0, 0),
      // });

      firstPage.drawText(
        `${Number(earning?.net_income_price?.toFixed(0))?.toLocaleString() || ""}`,
        {
          x:
            687 -
            customFont?.widthOfTextAtSize(
              (earning?.net_income_price || 0)?.toFixed(0)?.toLocaleString(),
              fontSize
            ),
          y,
          size: fontSize,
          font: customFont,
          color: rgb(0, 0, 0),
        }
      );

      firstPage.drawText(
        earning?.net_income_price === 0 ? "00" : getDecimal(Number(earning?.net_income_price)),
        {
          x: x + 594,
          y,
          size: fontSize,
          font: customFont,
          color: rgb(0, 0, 0),
        }
      );

      // firstPage.drawText(earning.totalTax?.toLocaleString(), {
      //   x: 787 - customFont.widthOfTextAtSize((earning.totalTax || 0)?.toLocaleString(), fontSize),
      //   y,
      //   size: fontSize,
      //   font: customFont,
      //   color: rgb(0, 0, 0),
      // });
      // firstPage.drawText(
      //   earning.totalTaxDecimal === 0 ? "00" : earning.totalTaxDecimal?.toString().slice(0, 2),
      //   {
      //     x: x + 691,
      //     y,
      //     size: fontSize,
      //     font: customFont,
      //     color: rgb(0, 0, 0),
      //   }
      // );
    }

    const modifiedPdfBytes = await pdfDoc.save();
    const modifiedPdfUrl = URL.createObjectURL(
      new Blob([modifiedPdfBytes], { type: "application/pdf" })
    );

    return { uint8Array: modifiedPdfBytes, blobUrl: modifiedPdfUrl };
  } catch (error) {
    console.error("Error modifying PDF:", error);

    return {};
  }
};

export default FormPND3A;
