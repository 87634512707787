import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button,
  Form,
  FormGroup,
  FormField,
  Label,
  Dropdown
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardDoctorFeeSummaryUX = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{width: "100%",height:"100%",padding:"10px"}}>
        <div
          style={{display: "flex", marginBottom: "0.75rem"}}>

          <Button
            color={props.activeTab === "create"? "blue":""}
            name="create"
            onClick={props.onChangeTab}
            size="small"
            style={{marginRight: "0.75rem"}}>
            สร้างเลขงวด
          </Button>
          <Button
            color={props.activeTab === "fee"? "blue":""}
            name="fee"
            onClick={props.onChangeTab}
            size="small"
            style={{marginRight: "0.75rem"}}>
            ค่าตอบแทนแพทย์
          </Button>
          <Button
            color={props.activeTab === "summary"? "blue":""}
            name="summary"
            onClick={props.onChangeTab}
            size="small">
            สรุปรายได้ค่าตอบแทนแพทย์
          </Button>
        </div>
        <div
          className="ui form">

          <div
            style={{fontSize: "1.15rem", fontWeight: "bold", padding: "2.5px 0"}}>
            {props.table?.title || "-"}
          </div>
        </div>
        <div
          className="ui divider"
          style={{marginTop: "0.5rem"}}>

        </div>
        <Form
          className="small">
          <FormGroup
            inline={true}
            style={{display: props.activeTab === "create"? "": "none"}}>
            <FormField
              className="required">
              <label
                style={{minWidth: "max-content"}}>
                วันที่เริ่มต้น
              </label>
            </FormField>
            <FormField>
              <div
                style={{width: "100%"}}>

                <DateTextBox
                  onChange={(value: string)=>props.onChangeFilter?.(null,{name:"filterCreate.startDate", value})}
                  value={props.filterCreate?.startDate || ""}>
                </DateTextBox>
              </div>
            </FormField>
            <FormField>
              <label>
                ถึง
              </label>
            </FormField>
            <FormField>
              <div
                style={{width: "100%"}}>

                <DateTextBox
                  onChange={(value: string)=>props.onChangeFilter?.(null,{name:"filterCreate.endDate", value})}
                  value={props.filterCreate?.endDate || ""}>
                </DateTextBox>
              </div>
            </FormField>
            <FormField>
              <div>
                {props.buttonSearch}
              </div>
            </FormField>
          </FormGroup>
        </Form>
        <div
          className="ui form small"
          style={{display: props.activeTab === "fee"? "": "none"}}>

          <FormGroup
            inline={true}>
            <FormField
              className="required"
              inline={true}>
              <label
                style={{minWidth: "max-content"}}>
                เลือกงวด
              </label>
            </FormField>
            <FormField
              inline={true}
              width={2}>
              <div
                style={{width: "100%"}}>
                {props.dfPayrollGroupFeeSearch}
              </div>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "max-content"}}>
                วันที่
              </label>
            </FormField>
            <FormField
              inline={true}
              style={{minWidth: "10rem"}}
              width="1">
              <Label
                size="large"
                style={{paddingTop: "0.7rem", paddingBottom: "0.7rem", width: "100%"}}>
                {props.filterFee?.dfPayrollGroup?.started_date || "\u00a0"}
              </Label>
            </FormField>
            <FormField
              inline={true}>
              <label>
                ถึง
              </label>
            </FormField>
            <FormField
              inline={true}
              style={{minWidth: "10rem"}}
              width={1}>
              <Label
                size="large"
                style={{paddingTop: "0.7rem", paddingBottom: "0.7rem", width: "100%"}}>
                {props.filterFee?.dfPayrollGroup?.ended_date || "\u00a0"}
              </Label>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <label
                style={{minWidth: "max-content"}}>
                ประเภทการรักษา
              </label>
              <Dropdown
                className="inline-label"
                clearable={true}
                fluid={true}
                loading={props.examinationTypeLoading || false}
                name="filterFee.examinationType"
                onChange={props.onChangeFilter}
                options={props.examinationTypeOptions||[]}
                search={true}
                selection={true}
                style={{width: "100%", fontSize: "1em"}}
                value={props.filterFee?.examinationType || ""}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <label
                style={{minWidth: "max-content"}}>
                ชื่อแพทย์
              </label>
              <Dropdown
                className="inline-label"
                fluid={true}
                loading={props.productTypeLoading|| false}
                name="filterFee.doctorId"
                onChange={props.onChangeFilter}
                options={props.doctorOptions || []}
                search={true}
                selection={true}
                style={{width: "100%", fontSize: "1em"}}
                value={props.filterFee?.doctorId || ""}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}>
              <div>
                {props.buttonSearch}
              </div>
            </FormField>
          </FormGroup>
        </div>
        <div
          className="ui form small"
          style={{display: props.activeTab === "summary"? "": "none"}}>

          <FormGroup
            inline={true}>
            <FormField
              className="required"
              inline={true}>
              <label
                style={{minWidth: "max-content"}}>
                เลือกงวด
              </label>
            </FormField>
            <FormField
              inline={true}
              width={3}>
              <div
                style={{width: "100%"}}>
                {props.dfPayrollGroupSummarySearch}
              </div>
            </FormField>
            <FormField
              inline={true}
              width={4}>
              <label
                style={{minWidth: "max-content"}}>
                ชื่อแพทย์
              </label>
              <Dropdown
                fluid={true}
                name="filterSummary.doctorId"
                onChange={props.onChangeFilter}
                options={props.doctorOptions || []}
                search={true}
                selection={true}
                style={{width: "100%", fontSize: "1em"}}
                value={props.filterSummary?.doctorId || ""}>
              </Dropdown>
            </FormField>
            <FormField
              inline={true}>
              <label
                style={{minWidth: "max-content"}}>
                วันที่
              </label>
            </FormField>
            <FormField
              inline={true}
              style={{minWidth: "15rem"}}
              width="1">
              <Label
                size="large"
                style={{paddingTop: "0.7rem", paddingBottom: "0.7rem", width: "100%"}}>
                {props.filterSummary?.dfPayrollGroup?.started_date || "\u00a0"}
              </Label>
            </FormField>
            <FormField
              inline={true}>
              <label>
                ถึง
              </label>
            </FormField>
            <FormField
              inline={true}
              style={{minWidth: "15rem"}}
              width={1}>
              <Label
                size="large"
                style={{paddingTop: "0.7rem", paddingBottom: "0.7rem", width: "100%"}}>
                {props.filterSummary?.dfPayrollGroup?.ended_date || "\u00a0"}
              </Label>
            </FormField>
            <FormField
              inline={true}>
              <div>
                {props.buttonSearch}
              </div>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{backgroundColor: "rgba(90, 200, 224, 0.15)", padding: "1rem"}}>
            <FormField
              className="required"
              inline={true}>
              <label>
                ระบุข้อมูล
              </label>
            </FormField>
            <FormField
              className={props.showRequiredField?.pay_to_bank_branch? "error": ""}
              inline={true}
              width={5}>
              <label
                style={{minWidth: "max-content"}}>
                สถานที่นำเงินเข้า
              </label>
              <Dropdown
                fluid={true}
                name="filterSummary.dfPayrollGroup.pay_to_bank_branch"
                onChange={props.onChangeFilter}
                options={props.bankBranchOptions || []}
                selection={true}
                style={{width: "100%", fontSize: "1em"}}
                value={props.filterSummary?.dfPayrollGroup?.pay_to_bank_branch || ""}>
              </Dropdown>
            </FormField>
            <FormField
              className={props.showRequiredField?.pay_date? "error": ""}
              inline={true}
              width={4}>
              <label
                style={{minWidth: "max-content"}}>
                วันเดือนปีที่จ่าย
              </label>
              <div
                style={{width: "100%"}}>

                <DateTextBox
                  onChange={(value: string)=>props.onChangeFilter?.(null,{name:"filterSummary.dfPayrollGroup.pay_date", value})}
                  value={props.filterSummary?.dfPayrollGroup?.pay_date|| ""}>
                </DateTextBox>
              </div>
            </FormField>
            <FormField
              inline={true}>
              <div>
                {props.buttonSave}
              </div>
            </FormField>
          </FormGroup>
        </div>
        <div>

          <Table
            data={props.data}
            getTdProps={props.onGetTdProps}
            headers={props.table?.headers}
            keys={props.table?.keys}
            minRows={9}
            showPagination={false}
            style={{height: props.table?.height || ""}}
            widths={props.table?.widths}>
          </Table>
        </div>
        <Form>
          <div
            className=" fields"
            style={{marginTop: "1rem", paddingRight: "1rem"}}>
            {props.summaryElement}
          </div>
        </Form>
        <Form>
          <FormGroup
            inline={true}
            style={{marginTop: "2rem"}}>
            <FormField
              style={{flex:1}}>
            </FormField>
            <FormField
              style={{display: "flex"}}>
              <div
                style={{display: props.table?.buttons?.includes("create")? "":"none", marginLeft: "1rem"}}>
                {props.buttonCreate}
              </div>
              <div
                style={{display: props.table?.buttons?.includes("df_payroll_report")? "":"none", marginLeft: "1rem"}}>
                {props.buttonDownloadDF}
              </div>
              <div
                style={{display: props.table?.buttons?.includes("print_summary")? "":"none", marginLeft: "1rem"}}>
                {props.buttonPrintSummary}
              </div>
              <div
                style={{display: props.table?.buttons?.includes("df_payroll_income_report")? "":"none", marginLeft: "1rem"}}>
                {props.buttonDownloadDFIncome}
              </div>
              <div
                style={{display: props.table?.buttons?.includes("print_payment_slip")? "":"none", marginLeft: "1rem"}}>
                {props.buttonPrintPaymentSlip}
              </div>
            </FormField>
          </FormGroup>
        </Form>
      </div>
    )
}

CardDoctorFeeSummaryUX.displayName = "CardDoctorFeeSummaryUX";
export default React.memo(CardDoctorFeeSummaryUX)

export const screenPropsDefault = {}

/* Date Time : Wed Jun 12 2024 10:38:38 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{width: \"100%\",height:\"100%\",padding:\"10px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"0.5rem\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.table?.title || \"-\""
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.15rem\", fontWeight: \"bold\", padding: \"2.5px 0\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", marginBottom: \"0.75rem\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "Button",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "สร้างเลขงวด"
        },
        "color": {
          "type": "code",
          "value": "props.activeTab === \"create\"? \"blue\":\"\""
        },
        "name": {
          "type": "value",
          "value": "create"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeTab"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.75rem\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "Button",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "ค่าตอบแทนแพทย์"
        },
        "color": {
          "type": "code",
          "value": "props.activeTab === \"fee\"? \"blue\":\"\""
        },
        "name": {
          "type": "value",
          "value": "fee"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeTab"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"0.75rem\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "Button",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "สรุปรายได้ค่าตอบแทนแพทย์"
        },
        "color": {
          "type": "code",
          "value": "props.activeTab === \"summary\"? \"blue\":\"\""
        },
        "name": {
          "type": "value",
          "value": "summary"
        },
        "onClick": {
          "type": "code",
          "value": "props.onChangeTab"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "Form",
      "parent": 0,
      "props": {
        "className": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "FormGroup",
      "parent": 9,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{display: props.activeTab === \"create\"? \"\": \"none\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "FormField",
      "parent": 10,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "FormField",
      "parent": 10,
      "props": {
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "FormField",
      "parent": 10,
      "props": {
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "FormField",
      "parent": 10,
      "props": {
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "label",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่เริ่มต้น"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "label",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 19,
      "name": "DateTextBox",
      "parent": 18,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(value: string)=>props.onChangeFilter?.(null,{name:\"filterCreate.startDate\", value})"
        },
        "value": {
          "type": "code",
          "value": "props.filterCreate?.startDate || \"\""
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 20,
      "name": "DateTextBox",
      "parent": 17,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(value: string)=>props.onChangeFilter?.(null,{name:\"filterCreate.endDate\", value})"
        },
        "value": {
          "type": "code",
          "value": "props.filterCreate?.endDate || \"\""
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "FormField",
      "parent": 10,
      "props": {
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 21,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearch"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 24,
      "name": "Table",
      "parent": 23,
      "props": {
        "data": {
          "type": "code",
          "value": "props.data"
        },
        "getTdProps": {
          "type": "code",
          "value": "props.onGetTdProps"
        },
        "headers": {
          "type": "code",
          "value": "props.table?.headers"
        },
        "keys": {
          "type": "code",
          "value": "props.table?.keys"
        },
        "minRows": {
          "type": "code",
          "value": "9"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: props.table?.height || \"\"}"
        },
        "widths": {
          "type": "code",
          "value": "props.table?.widths"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 25,
      "props": {
        "children": {
          "type": "code",
          "value": "props.summaryElement"
        },
        "className": {
          "type": "value",
          "value": " fields"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"1rem\", paddingRight: \"1rem\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 56,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "FormGroup",
      "parent": 27,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"2rem\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "FormField",
      "parent": 28,
      "props": {
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "FormField",
      "parent": 28,
      "props": {
        "style": {
          "type": "code",
          "value": "{display: \"flex\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "div",
      "parent": 30,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonCreate"
        },
        "style": {
          "type": "code",
          "value": "{display: props.table?.buttons?.includes(\"create\")? \"\":\"none\", marginLeft: \"1rem\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form small"
        },
        "style": {
          "type": "code",
          "value": "{display: props.activeTab === \"fee\"? \"\": \"none\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "FormGroup",
      "parent": 32,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "FormField",
      "parent": 33,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "FormField",
      "parent": 33,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "FormField",
      "parent": 33,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "FormField",
      "parent": 33,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"10rem\"}"
        },
        "width": {
          "type": "value",
          "value": "1"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "FormField",
      "parent": 33,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "FormField",
      "parent": 33,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"10rem\"}"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": null,
      "id": 40,
      "name": "label",
      "parent": 34,
      "props": {
        "children": {
          "type": "value",
          "value": "เลือกงวด"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 35,
      "props": {
        "children": {
          "type": "code",
          "value": "props.dfPayrollGroupFeeSearch"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "label",
      "parent": 36,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "label",
      "parent": 38,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "Label",
      "parent": 39,
      "props": {
        "children": {
          "type": "code",
          "value": "props.filterFee?.dfPayrollGroup?.ended_date || \"\\u00a0\""
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{paddingTop: \"0.7rem\", paddingBottom: \"0.7rem\", width: \"100%\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "Label",
      "parent": 37,
      "props": {
        "children": {
          "type": "code",
          "value": "props.filterFee?.dfPayrollGroup?.started_date || \"\\u00a0\""
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{paddingTop: \"0.7rem\", paddingBottom: \"0.7rem\", width: \"100%\"}"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "FormField",
      "parent": 33,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "label",
      "parent": 46,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทการรักษา"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "Dropdown",
      "parent": 46,
      "props": {
        "className": {
          "type": "value",
          "value": "inline-label"
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "loading": {
          "type": "code",
          "value": "props.examinationTypeLoading || false"
        },
        "name": {
          "type": "value",
          "value": "filterFee.examinationType"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "options": {
          "type": "code",
          "value": "props.examinationTypeOptions||[]"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", fontSize: \"1em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filterFee?.examinationType || \"\""
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "FormField",
      "parent": 33,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "div",
      "parent": 49,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearch"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "FormField",
      "parent": 33,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "label",
      "parent": 51,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อแพทย์"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "Dropdown",
      "parent": 51,
      "props": {
        "className": {
          "type": "value",
          "value": "inline-label"
        },
        "clearable": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "loading": {
          "type": "code",
          "value": "props.productTypeLoading|| false"
        },
        "name": {
          "type": "value",
          "value": "filterFee.doctorId"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "options": {
          "type": "code",
          "value": "props.doctorOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", fontSize: \"1em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filterFee?.doctorId || \"\""
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "div",
      "parent": 30,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonDownloadDF"
        },
        "style": {
          "type": "code",
          "value": "{display: props.table?.buttons?.includes(\"df_payroll_report\")? \"\":\"none\", marginLeft: \"1rem\"}"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "div",
      "parent": 30,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonPrintSummary"
        },
        "style": {
          "type": "code",
          "value": "{display: props.table?.buttons?.includes(\"print_summary\")? \"\":\"none\", marginLeft: \"1rem\"}"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form small"
        },
        "style": {
          "type": "code",
          "value": "{display: props.activeTab === \"summary\"? \"\": \"none\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "FormGroup",
      "parent": 56,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "FormField",
      "parent": 57,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "div",
      "parent": 58,
      "props": {
        "children": {
          "type": "code",
          "value": "props.dfPayrollGroupSummarySearch"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "FormField",
      "parent": 57,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "FormField",
      "parent": 57,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "FormField",
      "parent": 57,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"15rem\"}"
        },
        "width": {
          "type": "value",
          "value": "1"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "FormField",
      "parent": 57,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "FormField",
      "parent": 57,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"15rem\"}"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "label",
      "parent": 63,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": null,
      "id": 66,
      "name": "label",
      "parent": 61,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "label",
      "parent": 60,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อแพทย์"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "Dropdown",
      "parent": 60,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "filterSummary.doctorId"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "options": {
          "type": "code",
          "value": "props.doctorOptions || []"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", fontSize: \"1em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filterSummary?.doctorId || \"\""
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "FormField",
      "parent": 57,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "div",
      "parent": 69,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSearch"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "FormField",
      "parent": 57,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "label",
      "parent": 71,
      "props": {
        "children": {
          "type": "value",
          "value": "เลือกงวด"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "Label",
      "parent": 62,
      "props": {
        "children": {
          "type": "code",
          "value": "props.filterSummary?.dfPayrollGroup?.started_date || \"\\u00a0\""
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{paddingTop: \"0.7rem\", paddingBottom: \"0.7rem\", width: \"100%\"}"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "Label",
      "parent": 64,
      "props": {
        "children": {
          "type": "code",
          "value": "props.filterSummary?.dfPayrollGroup?.ended_date || \"\\u00a0\""
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{paddingTop: \"0.7rem\", paddingBottom: \"0.7rem\", width: \"100%\"}"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "FormGroup",
      "parent": 56,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"rgba(90, 200, 224, 0.15)\", padding: \"1rem\"}"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "FormField",
      "parent": 75,
      "props": {
        "className": {
          "type": "value",
          "value": "required"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 77,
      "name": "FormField",
      "parent": 75,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.pay_to_bank_branch? \"error\": \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "FormField",
      "parent": 75,
      "props": {
        "className": {
          "type": "code",
          "value": "props.showRequiredField?.pay_date? \"error\": \"\""
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 79,
      "name": "FormField",
      "parent": 75,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "label",
      "parent": 76,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุข้อมูล"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "label",
      "parent": 77,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานที่นำเงินเข้า"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "label",
      "parent": 78,
      "props": {
        "children": {
          "type": "value",
          "value": "วันเดือนปีที่จ่าย"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "div",
      "parent": 79,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 84,
      "name": "div",
      "parent": 78,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 85,
      "name": "DateTextBox",
      "parent": 84,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(value: string)=>props.onChangeFilter?.(null,{name:\"filterSummary.dfPayrollGroup.pay_date\", value})"
        },
        "value": {
          "type": "code",
          "value": "props.filterSummary?.dfPayrollGroup?.pay_date|| \"\""
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 86,
      "name": "Dropdown",
      "parent": 77,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "filterSummary.dfPayrollGroup.pay_to_bank_branch"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeFilter"
        },
        "options": {
          "type": "code",
          "value": "props.bankBranchOptions || []"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", fontSize: \"1em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filterSummary?.dfPayrollGroup?.pay_to_bank_branch || \"\""
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 30,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonDownloadDFIncome"
        },
        "style": {
          "type": "code",
          "value": "{display: props.table?.buttons?.includes(\"df_payroll_income_report\")? \"\":\"none\", marginLeft: \"1rem\"}"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 30,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonPrintPaymentSlip"
        },
        "style": {
          "type": "code",
          "value": "{display: props.table?.buttons?.includes(\"print_payment_slip\")? \"\":\"none\", marginLeft: \"1rem\"}"
        }
      },
      "seq": 88,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 100,
  "isMounted": true,
  "memo": true,
  "name": "CardDoctorFeeSummaryUX",
  "project": "CLM CU",
  "screenPropsDefault": {
  },
  "width": 100
}

*********************************************************************************** */
