import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from "react";
import { Button, Form, Icon, Input } from "semantic-ui-react";

import { RowInfo } from "react-table-6";
import moment from "moment";

// UX
import CardImagingOrderUX from "./CardImagingOrderUX";
import ModXrayDetail, { URGENCY_STATUS } from "./ModXrayDetail";
import ModCancelImagingOrder, { ApproveDataType } from "./ModCancelImagingOrder";

// Common
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import SearchBoxWithKey from "react-lib/appcon/common/SearchBoxWithKey";

// Interface
import {
  State,
  RunSequence,
  MasterOptionsType,
  XRAY_SEARCH_ID,
  DIAGNOSIS_SEARCH_ID,
} from "./sequence/ImagingOrder";
import {
  checkReadyToView,
  ORDER_STATUS_COLORS,
} from "./sequence/ImagingWorkList";
import {
  ORDER_ITEM_STATUS_LABEL,
  ORDER_STATUS,
  BILLING_STATUS,
  ImagingItemSerializer,
  ImagingOrderSerializer,
  ModXrayDetailType,
} from "./sequence/ImagingHandler";

// Utils
import { formatDate } from "react-lib/utils/dateUtils";
import { getLocationsSet, tidyLocation2 } from "react-lib/apps/common/CUDENT/DentalRecordUtil";
import { useIntl } from "react-intl";

import CONFIG from "config/config";

// Types
type CardImagingOrderProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
  // seq
  runSequence: RunSequence;
  ImagingOrderSequence: State["ImagingOrderSequence"];
  // data
  orderId: number | null;
  // CommonInterface
  searchedItemListWithKey?: Record<string, any>;
  buttonLoadCheck?: Record<string, any>;
  // selectedEncounter?: Record<string, any>;
  userTokenize?: Record<string, any> | null;
  errorMessage?: Record<string, any>;
  modXrayDetail: State["modXrayDetail"];
  // options
  masterOptions?: MasterOptionsType;
  // config
  editMode?: boolean; // แสดงเฉพาะรายละเอียด ไม่แสดง queue;
  isAppointment?: boolean;
  hideButtonSave?: boolean;
  // callback
  onClose?: () => any;

  // Dental disgram
  clinicalFindingId?: number | null;
  clinicalFindingIndex?: number | null;
  clinicalFindings: Record<string, any>[];
  filterClinicalFindingIds: number[];
  organ?: { name: string; items: any[] };
  clinicaltags?: { name: string; items: any[] };
  // ----- #
};

// Const
const styles = {
  label_status: {
    fontSize: "0.7rem",
    opacity: 0,
    position: "absolute",
    width: "80px",
    left: "calc(50% - 35px)",
    padding: "0.4rem",
    margin: 0,
    color: "white",
  } as CSSProperties,
};

const BUTTON_ACTIONS = {
  del: "CANCEL_ORDER",
  confirm: "CONFIRM_ORDER",
  init: "INIT_ORDER",
};

const IMAGES = {
  comment_green: "/static/images/x-ray/comment-outline-green.png",
  comment_grey: "/static/images/x-ray/comment-outline-grey.png",
};

const GridCenter = {
  display: "grid",
  placeContent: "center",
  textAlign: "center",
} as CSSProperties;

const CARD_IMAGING_ORDER = "CardImagingOrder";

const ACTION_INIT = `${CARD_IMAGING_ORDER}_${BUTTON_ACTIONS.init}`;
const ACTION_CONFIRM = `${CARD_IMAGING_ORDER}_${BUTTON_ACTIONS.confirm}`;

const CardImagingOrder = (props: CardImagingOrderProps) => {
  const intl = useIntl();
  const [modConfirmCancel, setModConfirmCancel] = useState<ImagingOrderSerializer | null>(null);

  // Effect
  useEffect(() => {
    props.runSequence({
      sequence: "ImagingOrder",
      restart: true,
      editMode: props.editMode,
      orderId: props.orderId,
      card: CARD_IMAGING_ORDER,
    });
  }, []);

  // #useEffect(() => {
  //   if (props.orderId) {
  //     props.runSequence({ sequence: "ImagingOrder", action: "ADD_ORDER", orderId: props.orderId });
  //   }
  // }, [props.orderId]);

  useEffect(() => {
    const imagingItemDetail = props.ImagingOrderSequence?.imagingItemDetail || {};
    const requestOrderList = props.ImagingOrderSequence?.requestOrderList || [];

    const findItem = requestOrderList.find((item) => item.id === imagingItemDetail.id);

    if (!findItem && !!imagingItemDetail.id && !props.editMode) {
      handleCloseEdit();
    }
  }, [props.ImagingOrderSequence?.imagingItemDetail, props.ImagingOrderSequence?.requestOrderList]);

  // callback
  const handleSelectedItem = useCallback(
    (data: any) => async (value: any) => {
      if (data.id === XRAY_SEARCH_ID) {
        handleAddImagingItem(value);
      } else {
        const [first, second] = data.name.split(".");

        const order = {
          ...props.ImagingOrderSequence,
          [first]: !second
            ? value || null
            : {
                ...(props.ImagingOrderSequence as any)[first],
                [second]: value || null,
              },
        };

        props.setProp(`ImagingOrderSequence`, order);
      }
    },
    [props.searchedItemListWithKey]
  );

  const handleOpenModXray = useCallback(
    (data: ImagingItemSerializer, index: number) => () => {
      props.runSequence({
        sequence: "ImagingOrder",
        action: "MOD_XRAY",
        data: { ...data, index, status: ORDER_ITEM_STATUS_LABEL[data.status] },
        type: "OPEN",
      });
    },
    []
  );

  const handleEditItem = useCallback(
    (index: number) => (e: any, data: any) => {
      props.runSequence({
        sequence: "ImagingOrder",
        action: "EDIT_ITEM",
        data: { [data.name]: data.value },
        index,
      });
    },
    [props.ImagingOrderSequence?.imagingItemList]
  );

  const handleDeleteItem = useCallback(
    (product: number | undefined) => () => {
      let list = [...(props.ImagingOrderSequence?.imagingItemList || [])];

      if (product) {
        list = list.map((item) => (item.product === product ? { ...item, active: false } : item));
      }

      props.setProp(`ImagingOrderSequence.imagingItemList`, [...list]);
    },
    [props.ImagingOrderSequence?.imagingItemList]
  );

  const handleEdit = useCallback(
    async (data: any) => {
      if (props.buttonLoadCheck?.[ACTION_INIT]) {
        return;
      }

      props.runSequence({
        sequence: "ImagingOrder",
        action: "EDIT_REQUEST",
        data,
      });
    },
    [props.buttonLoadCheck]
  );

  const getDetailImagingRequest = useCallback((items: ImagingOrderSerializer["order_items"]) => {
    return items.reduce((result: any, item) => {
      const groupName = item.group_name;

      if (result[groupName]) {
        result[groupName].items.push(item);
      } else {
        result[groupName] = {
          title: groupName,
          id: groupName.replaceAll(" ", "-"),
          items: [item],
        };
      }

      return result;
    }, {}) as Record<
      string,
      {
        id: string;
        title: string;
        items: ImagingOrderSerializer["order_items"];
      }
    >;
  }, []);

  const handleLabelPosition = useCallback(
    (data: { id: string; pIndex: number; index: number }) => (ref: any) => {
      const elm = document.querySelector(
        `.imaging-request-detail-${data.id.replaceAll(" ", "-")}-${data.pIndex}-${data.index}`
      ) as HTMLLIElement;

      if (!!elm && ref) {
        ref.style.opacity = "1";
        ref.style.top = `${elm.offsetTop - Number(ref.parentElement?.offsetTop)}px`;
      }
    },
    []
  );

  const getUnitName = useCallback(
    (unit: any) => {
      const unitName =
        typeof unit === "number"
          ? props.masterOptions?.unit?.find((option) => option.value === unit)?.text || ""
          : unit;

      return unitName;
    },
    [props.masterOptions?.unit]
  );

  const filterImagingItemList = useMemo(() => {
    return (props.ImagingOrderSequence?.imagingItemList || []).filter((item) => !!item.active);
  }, [props.ImagingOrderSequence?.imagingItemList]);

  const summaryPrice = useMemo(() => {
    return filterImagingItemList.reduce<{
      total: number | string;
      claimable: number | string;
      non_claimable: number | string;
    }>(
      (result, item) => ({
        total: (Number(result.total) + Number(item.price_unit) * Number(item.quantity)).toString(),
        claimable: (Number(result.claimable) + Number(item.price_claimable)).toString(),
        non_claimable: (Number(result.non_claimable) + Number(item.price_non_claimable)).toString(),
      }),
      { total: 0, claimable: 0, non_claimable: 0 }
    );
  }, [filterImagingItemList]);

  const readOnly = useMemo(() => {
    const detail = props.ImagingOrderSequence?.imagingItemDetail;

    return (
      ["In Progress", "Complete"].includes(detail?.order_status_label || "") ||
      detail?.order_items?.some((item) => item.payment_status === BILLING_STATUS.PAID)
    );
  }, [props.ImagingOrderSequence?.imagingItemDetail]);

  const allowConfirmOrder = useMemo(() => {
    const allowReadyToView = filterImagingItemList.some((item) =>
      checkReadyToView(ORDER_ITEM_STATUS_LABEL[item.status], item.payment_status)
    );

    return (
      !!props.ImagingOrderSequence?.imagingItemDetail?.suspected_diagnosis &&
      !!filterImagingItemList.length &&
      allowReadyToView
    );
  }, [props.ImagingOrderSequence?.imagingItemDetail, filterImagingItemList]);

  const hideConfirmOrder = useMemo(() => {
    return (
      !!filterImagingItemList.length &&
      filterImagingItemList.every((item) => !!item.status && !item.allow_editing)
    );
  }, [filterImagingItemList]);

  const allowReadyToView = useMemo(() => {
    const detail = props.modXrayDetail;

    return (
      !hideConfirmOrder &&
      !!detail?.status &&
      !!detail?.payment_status &&
      checkReadyToView(ORDER_STATUS[detail.status], detail.payment_status)
    );
  }, [props.modXrayDetail?.status, hideConfirmOrder]);

  // Memo
  const imagingRequestItems = useMemo(() => {
    return (props.ImagingOrderSequence?.requestOrderList || []).map((item, index) => {
      const detail = getDetailImagingRequest(item.order_items);

      const allowEdting = item.order_items.every((item) => item.allow_editing);

      return {
        ...item,
        request_date: (
          <div style={GridCenter}>
            {formatDate(moment(item.created))} [{moment(item.created).format("HH:mm")}]
          </div>
        ),
        order_status: (
          <div style={{ ...GridCenter, textTransform: "capitalize" }}>
            {/* #{DOCTOR_ORDER_STATUS[item.order_status].toLowerCase()} */}
            {item.order_status_label}
          </div>
        ),
        doctor_name: <div style={GridCenter}>{item.care_provider}</div>,
        summary: (
          <>
            {Object.entries(detail).map(([, value]) => (
              <>
                <div>
                  <strong>{value.title}</strong>
                </div>
                <ul style={{ margin: 0, paddingLeft: "2rem" }}>
                  {value.items.map((acc, idx) => {
                    const isStat = acc.urgency === URGENCY_STATUS.STAT;

                    return (
                      <li
                        key={`summary-item-${value.id}-${item.id}-${acc.id}`}
                        className={`imaging-request-detail-${value.id}-${index}-${idx}`}
                        style={{
                          marginBottom: "0.8rem",
                          color: isStat ? "#DA0000" : "",
                        }}
                      >
                        {acc.product_name}
                      </li>
                    );
                  })}
                </ul>
                <br />
              </>
            ))}
          </>
        ),
        status: (
          <div style={{ ...GridCenter, position: "relative" }}>
            {Object.entries(detail).map(([, value]) => (
              <>
                {value.items.map((acc, idx) => {
                  const status = ORDER_ITEM_STATUS_LABEL[acc.status];
                  return (
                    <div
                      key={`status-item-${value.id}-${item.id}-${acc.id}`}
                      ref={handleLabelPosition({
                        id: value.id,
                        pIndex: index,
                        index: idx,
                      })}
                      className={`ui label`}
                      style={{
                        ...styles.label_status,
                        backgroundColor: ORDER_STATUS_COLORS[status],
                      }}
                    >
                      {status}
                    </div>
                  );
                })}
              </>
            ))}
          </div>
        ),

        delete: (
          <div style={GridCenter}>
            <Button
              id="CardImagingOrder-Button-Delete"
              icon="minus"
              size="mini"
              color="red"
              disabled={!allowEdting}
              style={{ fontSize: "0.75rem" }}
              onClick={(e) => {
                e.stopPropagation();

                setModConfirmCancel(item);
              }}
            />
          </div>
        ),
        edit: (
          <div style={GridCenter}>
            <Button
              id="CardImagingOrder-Button-Edit"
              icon="pencil"
              size="mini"
              color="blue"
              style={{ fontSize: "0.75rem" }}
              disabled={!allowEdting}
              onClick={(e) => {
                e.stopPropagation();

                handleEdit(item);
              }}
            />
          </div>
        ),
      };
    });
  }, [
    props.ImagingOrderSequence?.requestOrderList,
    props.ImagingOrderSequence?.imagingItemDetail,
    handleEdit,
  ]);

  const imagingItems = useMemo(() => {
    return filterImagingItemList.map((item, index) => {
      const isStat = item.urgency === URGENCY_STATUS.STAT;
      const unitName = getUnitName(item.unit);
      const activeNote = !!item.note;
      const readOnly = !!item.status && !item.allow_editing;

      const locationsSet = getLocationsSet(item.locations_name || "", props.organ?.items || []);

      const locationsName = tidyLocation2(locationsSet, props.organ?.items || []).join(",");

      return {
        ...item,
        no: index + 1,
        code: (
          <div style={{ color: isStat ? "#DA0000" : "" }}>
            {/\[(\w+)\]/g.exec(item.name_code || "")?.[1] || ""}
          </div>
        ),
        name: <div style={{ color: isStat ? "#DA0000" : "" }}> {item.name} </div>,
        // Y14 : ภาพ
        unit_name: <div style={GridCenter}>{unitName.replace(/( \(\w+\))|(\w+ : )/g, "")}</div>,
        qty: (
          <Input
            ref={(ref: any) =>
              ref &&
              ((ref.inputRef.current.style.background = readOnly ? "" : "#DCF4FA"),
              (ref.inputRef.current.style.textAlign = "center"))
            }
            type="number"
            name="quantity"
            value={item.quantity}
            fluid={true}
            disabled={readOnly}
            // style
            style={{ width: "100%", opacity: 1 }}
            onChange={handleEditItem(index)}
          />
        ),
        // price_total: Number(item.quantity) * item.price_unit,
        summary: (
          <div style={{ display: "grid", gridTemplateColumns: "28px 1fr" }}>
            <img
              aria-hidden="true"
              src={activeNote ? IMAGES.comment_green : IMAGES.comment_grey}
              alt={activeNote ? "green speech bubble" : "grey speech bubble"}
              // style
              style={{
                width: "25px",
                cursor: "pointer",
              }}
              onClick={handleOpenModXray(item, index)}
            />
            <label
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 3,
                WebkitBoxOrient: "vertical",
              }}
            >
              {locationsName} {item.note}
            </label>
          </div>
        ),
        delete: (
          <div style={GridCenter}>
            <Button
              icon="minus"
              size="mini"
              color="red"
              disabled={readOnly}
              style={{ fontSize: "0.75rem" }}
              onClick={handleDeleteItem(item.product)}
            />
          </div>
        ),
        price_unit: (
          <div style={{ textAlign: "end" }}>
            {Number(item.price_unit).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>
        ),
        price_total: (
          <div style={{ textAlign: "end" }}>
            {Number(item.price_total).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>
        ),
        price_claimable: (
          <div style={{ textAlign: "end" }}>
            {Number(item.price_claimable).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>
        ),
        price_non_claimable: (
          <div style={{ textAlign: "end" }}>
            {Number(item.price_non_claimable).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>
        ),
      };
    });
  }, [filterImagingItemList, props.masterOptions?.unit]);

  // Handler
  const handleChangeValue = (e: any, data: any) => {
    const value = typeof data.checked === "boolean" ? data.checked : data.value;
    const name = data.name;

    props.setProp(`ImagingOrderSequence.imagingItemDetail.${name}`, value);
  };

  const handleConfirmCancel = (data: ApproveDataType) => {
    if (modConfirmCancel) {
      props.runSequence({
        sequence: "ImagingOrder",
        action: "CANCEL_ORDER",
        card: CARD_IMAGING_ORDER,
        data: modConfirmCancel,
        reason: data.reason,
        onSuccess: handleCloseModCancel,
      });
    }
  };

  const handleCloseModCancel = () => {
    setModConfirmCancel(null);
  };

  const handleGetTrPropsItem = (state: any, rowInfo?: RowInfo) => {
    const row = props.ImagingOrderSequence?.imagingItemList?.[rowInfo?.index || 0];

    return {
      style: {
        opacity: !!row?.status && ORDER_ITEM_STATUS_LABEL[row.status] !== "REQUESTED" ? 0.5 : 1,
      },
    };
  };

  const handleSelectOrder = (rowInfo?: RowInfo) => {
    const row = props.ImagingOrderSequence?.requestOrderList?.[rowInfo?.index || 0];

    if (row) {
      props.runSequence({
        sequence: "ImagingOrder",
        action: "EDIT_REQUEST",
        data: row,
      });
    }
  };

  const handleGetTrPropsRequest = (state: any, rowInfo?: RowInfo) => {
    return {
      className:
        !!props.ImagingOrderSequence?.imagingItemDetail?.id &&
        props.ImagingOrderSequence?.imagingItemDetail?.id === rowInfo?.original.id
          ? "blueSelectedRow"
          : "",
      onClick: () => {
        handleSelectOrder(rowInfo);
      },
    };
  };

  const handleCloseModXray = () => {
    props.runSequence({
      sequence: "ImagingOrder",
      action: "MOD_XRAY",
      type: "CLOSE",
    });
  };

  const handleClearImagingItem = () => {
    props.setProp(`searchedItemListWithKey.${XRAY_SEARCH_ID}`, []);

    props.setProp("ImagingOrderSequence.imagingItemId", null);
  };

  const handleAddImagingItem = (id?: number) => {
    if (id) {
      props.runSequence({
        sequence: "ImagingOrder",
        action: "ADD_ITEM",
        itemId: id,
      });
    }
  };

  const handleSaveXray = async (data: Partial<ModXrayDetailType>) => {
    const index = props.modXrayDetail?.index ?? -1;

    if (index >= 0) {
      props.runSequence({
        sequence: "ImagingOrder",
        action: "EDIT_ITEM",
        data: {
          eligibility_type: data.eligibility_type,
          urgency: data.urgency,
          note: data.note,
          image_id: data.image_id,
          image_url: data.image_url,
          locations_name: data.locations_name,
          ready_to_view: data.ready_to_view,
        },
        index,
        onSuccess: handleCloseModXray,
      });
    }
  };

  const handleAddOrder = async () => {
    props.runSequence({ sequence: "ImagingOrder", action: "ADD_ORDER" });
  };

  const handleEventSearch = (data: any) => {
    props.runSequence({
      sequence: "ImagingOrder",
      action: "SEARCH_DIAGNOSIS",
      data,
    });
  };

  const handleConfirmOrder = () => {
    const orderBy = props.ImagingOrderSequence?.orderDoctorId;

    if (props.isAppointment && !orderBy) {
      props.setProp(`errorMessage.${CARD_IMAGING_ORDER}`, "กรุณาระบุแพทย์ผู้สั่ง");

      return;
    }

    props.runSequence({
      sequence: "ImagingOrder",
      action: "CONFIRM_ORDER",
      card: CARD_IMAGING_ORDER,
      isAppointment: props.isAppointment,
      editMode: props.editMode,
      order_by: props.ImagingOrderSequence?.orderDoctorId,
      onSuccess: handleCloseEdit,
    });
  };

  const handleCloseEdit = async () => {
    if (props.editMode) {
      props.onClose?.();
    } else {
      await props.setProp(`ImagingOrderSequence.imagingItemList`, []);

      props.setProp(`ImagingOrderSequence.imagingItemDetail`, null);
    }
  };

  const handleSelectedOrderDoctor = (value: any) => {
    props.setProp("ImagingOrderSequence", {
      ...props.ImagingOrderSequence,
      orderDoctorId: value || null,
      orderDoctorNameCode: ""
    });
  };

  const handleOpenRIS = () => {
    globalThis.open(CONFIG.RIS_BASE_URL, "_blank");
  }

  const handleRefresh = () => {
    props.runSequence({
      sequence: "ImagingOrder",
      action: "SEARCH",
      card: CARD_IMAGING_ORDER,
      isLoading: true
    });
  };

  console.log("CardImagingOrder", props);

  return (
    <div id="CardImagingOrder">
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_IMAGING_ORDER}`, null);
        }}
        error={props.errorMessage?.[CARD_IMAGING_ORDER]}
        success={null}
        languageUX={props.languageUX}
      />

      <CardImagingOrderUX
        // data
        imagingRequestList={imagingRequestItems}
        imagingItemList={imagingItems}
        summaryPrice={summaryPrice}
        imagingItemDetail={props.ImagingOrderSequence?.imagingItemDetail}
        // options
        divisionForOrderDivOptions={props.masterOptions?.divisionForOrderDiv}
        // config
        readOnly={readOnly}
        editMode={props.editMode}
        disabledAddOrder={!!props.buttonLoadCheck?.[ACTION_INIT]}
        isOpenRIS={!!CONFIG.RIS_BASE_URL}
        // callback
        onGetTrPropsItem={handleGetTrPropsItem}
        onGetTrPropsRequest={handleGetTrPropsRequest}
        onClear={handleClearImagingItem}
        // onAdd={handleAddImagingItem}
        onChangeValue={handleChangeValue}
        onAddOrder={handleAddOrder}
        onClose={handleCloseEdit}
        onOpenRIS={handleOpenRIS}
        // Element
        SearchBoxPrincipalDiag={
          <SearchBoxDropdown
            onEvent={handleEventSearch}
            // config
            type="Diagnosis"
            id="IO"
            style={{ width: "0", minWidth: "100%" }}
            fluid={true}
            useWithKey={true}
            icon="search"
            limit={20}
            disabled={readOnly}
            // Select
            searchedItemListWithKey={props.searchedItemListWithKey}
            selectedItem={
              props.ImagingOrderSequence?.imagingItemDetail?.suspected_diagnosis || null
            }
            setSelectedItem={handleSelectedItem({
              id: DIAGNOSIS_SEARCH_ID,
              name: "imagingItemDetail.suspected_diagnosis",
            })}
          />
        }
        SearchBoxImagingItem={
          <SearchBoxDropdown
            onEvent={props.onEvent}
            // config
            type="ImagingXray"
            id="IO"
            style={{ width: "100%" }}
            fluid={true}
            useWithKey={true}
            icon="search"
            limit={20}
            disabled={readOnly}
            // Select
            searchedItemListWithKey={props.searchedItemListWithKey}
            selectedItem={null}
            setSelectedItem={handleSelectedItem({
              id: XRAY_SEARCH_ID,
              name: "imagingItemId",
            })}
          />
        }
        ButtonConfirmOrder={
          !(hideConfirmOrder || props.hideButtonSave) && (
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              onClick={handleConfirmOrder}
              // data
              paramKey={ACTION_CONFIRM}
              buttonLoadCheck={props.buttonLoadCheck?.[ACTION_CONFIRM]}
              // config
              disabled={!allowConfirmOrder}
              color={"green"}
              name={BUTTON_ACTIONS.confirm}
              size="medium"
              title="Confirm Order"
              fluid={true}
              style={{ minWidth: "max-content" }}
            />
          )
        }
        orderByBox={
          <Form style={{ display: props.isAppointment ? "" : "none" }}>
            <Form.Group inline style={{ margin: 0 }}>
              <Form.Field style={{ minWidth: "max-content" }} required>
                <label>{intl.formatMessage({ id: "แพทย์ผู้สั่ง" })}</label>
              </Form.Field>
              <Form.Field style={{ width: "300px" }}>
                <SearchBoxWithKey
                  id="LabOrderBy"
                  // callback
                  onEvent={props.onEvent}
                  setSelected={handleSelectedOrderDoctor}
                  // data
                  type="Doctor"
                  searchedItemListWithKey={props.searchedItemListWithKey}
                  selectedItem={props.ImagingOrderSequence?.orderDoctorId || null}
                  defaultText={props.ImagingOrderSequence?.orderDoctorNameCode}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        }
        refreshIcon={
          <Icon
            name="refresh"
            link
            color="blue"
            loading={!!props.buttonLoadCheck?.[ACTION_INIT]}
            onClick={handleRefresh}
          />
        }
        languageUX={props.languageUX}
      />

      <ModCancelImagingOrder
        onEvent={props.onEvent}
        setProp={props.setProp}
        // data
        open={!!modConfirmCancel}
        action="CANCEL"
        orderStatus="cancelled"
        // CommonInterface
        buttonLoadCheck={props.buttonLoadCheck}
        userTokenize={props.userTokenize}
        // options
        cancelImagingOrderOptions={props.masterOptions?.cancelImagingOrder}
        // config
        card={CARD_IMAGING_ORDER}
        // callback
        onApprove={handleConfirmCancel}
        onClose={handleCloseModCancel}
        languageUX={props.languageUX}
      />

      <ModXrayDetail
        onEvent={props.onEvent}
        setProp={props.setProp}
        // data
        open={!!props.modXrayDetail}
        data={props.modXrayDetail}
        clinicalFindingId={props.clinicalFindingId}
        clinicalFindingIndex={props.clinicalFindingIndex}
        masterData={{ organ: props.organ, clinicaltags: props.clinicaltags }}
        clinicalFindings={props.clinicalFindings}
        filterClinicalFindingIds={props.filterClinicalFindingIds}
        // options
        eligibilityOptions={props.masterOptions?.eligibilityType}
        // CommonInterface
        errorMessage={props.errorMessage}
        buttonLoadCheck={props.buttonLoadCheck}
        // config
        readOnly={!!props.modXrayDetail?.status && !props.modXrayDetail?.allow_editing}
        allowedReadyToView={allowReadyToView}
        // callback
        onClose={handleCloseModXray}
        onSave={handleSaveXray}
      />
    </div>
  );
};

CardImagingOrder.displayName = "CardImagingOrder";

export default React.memo(CardImagingOrder);
