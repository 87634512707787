import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Dropdown,
  Input,
  Button,
  FormGroup,
  FormField
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardTelepharQueueUX = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div>
        <div
          style={{boxShadow: "none", padding: "10px"}}>
          
          <div
            className="ui form small">
            {}
            <FormGroup
              inline={true}>
              <FormField
                inline={true}>
                <label
                  style={{minWidth:"max-content"}}>
                  ชื่อ-สกุล/HN
                </label>
              </FormField>
              <FormField
                inline={true}>
                <div
                  style={{flex:"1"}}>
                  
                  <Input
                    icon="search"
                    onChange={props.handleChangeValue("selectedPatientSearch")}
                    value={props.selectedPatient}>
                  </Input>
                </div>
              </FormField>
              <FormField
                inline={true}>
                <label
                  style={{minWidth:"max-content"}}>
                  วันที่นัดหมาย
                </label>
              </FormField>
              <FormField
                inline={true}>
                <div
                  style={{flex:"1"}}>
                  
                  <DateTextBox
                    onChange={props.handleChangeDate("selectedStartDate")}
                    value={props.selectedStartDate}>
                  </DateTextBox>
                </div>
              </FormField>
              <FormField
                inline={true}>
                <label>
                  ถึง
                </label>
              </FormField>
              <FormField
                inline={true}>
                <div
                  style={{flex:"1"}}>
                  
                  <DateTextBox
                    onChange={props.handleChangeDate("selectedEndDate")}
                    value={props.selectedEndDate}>
                  </DateTextBox>
                </div>
              </FormField>
              <FormField
                inline={true}>
                <label
                  style={{minWidth:"max-content"}}>
                  ประเภทคนไข้
                </label>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <div
                  style={{flex:"1"}}>
                  
                  <Dropdown
                    onChange={props.handleChangeValue("selectedPatientType")}
                    options={props.patientOptions}
                    selection={true}
                    value={props.selectedPatientType}>
                  </Dropdown>
                </div>
              </FormField>
              <FormField
                inline={true}>
                <label
                  style={{minWidth:"max-content"}}>
                  ช่องทางการนัด
                </label>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <div
                  style={{flex:"1"}}>
                  
                  <Dropdown
                    onChange={props.handleChangeValue("selectedApplicationType")}
                    options={props.applicationOption}
                    selection={true}
                    value={props.selectedApplicationType}>
                  </Dropdown>
                </div>
              </FormField>
              <FormField
                inline={true}>
                <label
                  style={{minWidth:"max-content"}}>
                  สถานะการนัด
                </label>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <div
                  style={{flex:"1"}}>
                  
                  <Dropdown
                    onChange={props.handleChangeValue("selectedAppointmentStatus")}
                    options={props.appointmentStatusOption}
                    selection={true}
                    value={props.selectedAppointmentStatus}>
                  </Dropdown>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <div
                style={{flex:"1"}}>
                
              </div>
              <FormField>
                <div>
                  
                  <Button
                    color="blue"
                    loading={props.searchLoading}
                    onClick={props.handleSearchAppointment}>
                    ค้นหา
                  </Button>
                </div>
              </FormField>
              <FormField>
                <div
                  style={{margin:" 0 10px  0 5px"}}>
                  
                  <Button
                    onClick={props.handleClearData}>
                    ล้างข้อมูล
                  </Button>
                </div>
              </FormField>
              <FormField>
                <div>
                  
                  <Button
                    color="blue"
                    icon="plus"
                    onClick={props.addPatient}>
                    {props.childrenButton}
                  </Button>
                </div>
              </FormField>
            </FormGroup>
          </div>
          <div>
            
            <Table
              data={props.tableItems}
              getTdProps={props.getTdProps}
              getTrProps={props.getTrProps}
              headers="EN,HN,ชื่อ - นามสกุล,ประเภทผู้ป่วย,ช่องทางการนัด,วันที่นัดหมาย,สถานะการนัด"
              id="tb-bilQueueReq"
              keys="en,hnElement,patientName,patientType,appointmentType,appointmentDateTime,booking"
              minRows="8"
              pageSize="5"
              showPagination={false}
              style={{height: "55vh"}}>
            </Table>
          </div>
          <div
            style={{padding: "20px 10px"}}>
            {props.pagination}
          </div>
        </div>
      </div>
    )
}

export default CardTelepharQueueUX

export const screenPropsDefault = {}

/* Date Time : Mon Apr 03 2023 09:31:27 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{boxShadow: \"none\", padding: \"10px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form small"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 32,
      "name": "Table",
      "parent": 31,
      "props": {
        "data": {
          "type": "code",
          "value": "props.tableItems"
        },
        "defaultPageSize": {
          "type": "value",
          "value": ""
        },
        "getTdProps": {
          "type": "code",
          "value": "props.getTdProps"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getTrProps"
        },
        "headers": {
          "type": "value",
          "value": "EN,HN,ชื่อ - นามสกุล,ประเภทผู้ป่วย,ช่องทางการนัด,วันที่นัดหมาย,สถานะการนัด"
        },
        "id": {
          "type": "value",
          "value": "tb-bilQueueReq"
        },
        "keys": {
          "type": "value",
          "value": "en,hnElement,patientName,patientType,appointmentType,appointmentDateTime,booking"
        },
        "minRows": {
          "type": "value",
          "value": "8"
        },
        "pageSize": {
          "type": "value",
          "value": "5"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"55vh\"}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.pagination"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"20px 10px\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "Dropdown",
      "parent": 148,
      "props": {
        "clearable": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedAppointmentStatus\")"
        },
        "options": {
          "type": "code",
          "value": "props.appointmentStatusOption"
        },
        "search": {
          "type": "code",
          "value": ""
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.selectedAppointmentStatus"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 56,
      "name": "Dropdown",
      "parent": 147,
      "props": {
        "clearable": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedApplicationType\")"
        },
        "options": {
          "type": "code",
          "value": "props.applicationOption"
        },
        "search": {
          "type": "code",
          "value": ""
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.selectedApplicationType"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "Dropdown",
      "parent": 146,
      "props": {
        "clearable": {
          "type": "code",
          "value": ""
        },
        "defaultValue": {
          "type": "value",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedPatientType\")"
        },
        "options": {
          "type": "code",
          "value": "props.patientOptions"
        },
        "search": {
          "type": "code",
          "value": ""
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.selectedPatientType"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "div",
      "parent": 119,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:\"1\"}"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 62,
      "name": "DateTextBox",
      "parent": 61,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDate(\"selectedStartDate\")"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.selectedStartDate"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "div",
      "parent": 121,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:\"1\"}"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 79,
      "name": "DateTextBox",
      "parent": 78,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.handleChangeDate(\"selectedEndDate\")"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.selectedEndDate"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 80,
      "name": "div",
      "parent": 117,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:\"1\"}"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "Input",
      "parent": 80,
      "props": {
        "icon": {
          "type": "value",
          "value": "search"
        },
        "onChange": {
          "type": "code",
          "value": "props.handleChangeValue(\"selectedPatientSearch\")"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.selectedPatient"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 101,
      "name": "div",
      "parent": 141,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:\"1\"}"
        }
      },
      "seq": 142,
      "void": false
    },
    {
      "from": null,
      "id": 102,
      "name": "div",
      "parent": 143,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": null,
      "id": 103,
      "name": "div",
      "parent": 144,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{margin:\" 0 10px  0 5px\"}"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": null,
      "id": 104,
      "name": "div",
      "parent": 145,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 105,
      "name": "Button",
      "parent": 102,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "loading": {
          "type": "code",
          "value": "props.searchLoading"
        },
        "onClick": {
          "type": "code",
          "value": "props.handleSearchAppointment"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 106,
      "name": "Button",
      "parent": 103,
      "props": {
        "children": {
          "type": "value",
          "value": "ล้างข้อมูล"
        },
        "onClick": {
          "type": "code",
          "value": "props.handleClearData"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 107,
      "name": "Button",
      "parent": 104,
      "props": {
        "children": {
          "type": "code",
          "value": "props.childrenButton"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "icon": {
          "type": "value",
          "value": "plus"
        },
        "onClick": {
          "type": "code",
          "value": "props.addPatient"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 115,
      "name": "FormGroup",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 116,
      "name": "FormField",
      "parent": 115,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 117,
      "name": "FormField",
      "parent": 115,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 118,
      "name": "FormField",
      "parent": 115,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 119,
      "name": "FormField",
      "parent": 115,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 120,
      "name": "FormField",
      "parent": 115,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 121,
      "name": "FormField",
      "parent": 115,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 123,
      "name": "FormField",
      "parent": 115,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 124,
      "name": "FormField",
      "parent": 115,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 128,
      "name": "FormField",
      "parent": 115,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 130,
      "name": "FormField",
      "parent": 115,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 130,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 131,
      "name": "FormField",
      "parent": 115,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 131,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 132,
      "name": "FormField",
      "parent": 115,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 132,
      "void": false
    },
    {
      "from": null,
      "id": 135,
      "name": "label",
      "parent": 116,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อ-สกุล/HN"
        },
        "style": {
          "type": "code",
          "value": "{minWidth:\"max-content\"}"
        }
      },
      "seq": 135,
      "void": false
    },
    {
      "from": null,
      "id": 136,
      "name": "label",
      "parent": 118,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่นัดหมาย"
        },
        "style": {
          "type": "code",
          "value": "{minWidth:\"max-content\"}"
        }
      },
      "seq": 136,
      "void": false
    },
    {
      "from": null,
      "id": 137,
      "name": "label",
      "parent": 120,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        }
      },
      "seq": 137,
      "void": false
    },
    {
      "from": null,
      "id": 138,
      "name": "label",
      "parent": 123,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทคนไข้"
        },
        "style": {
          "type": "code",
          "value": "{minWidth:\"max-content\"}"
        }
      },
      "seq": 138,
      "void": false
    },
    {
      "from": null,
      "id": 139,
      "name": "label",
      "parent": 128,
      "props": {
        "children": {
          "type": "value",
          "value": "ช่องทางการนัด"
        },
        "style": {
          "type": "code",
          "value": "{minWidth:\"max-content\"}"
        }
      },
      "seq": 139,
      "void": false
    },
    {
      "from": null,
      "id": 140,
      "name": "label",
      "parent": 131,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะการนัด"
        },
        "style": {
          "type": "code",
          "value": "{minWidth:\"max-content\"}"
        }
      },
      "seq": 140,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 141,
      "name": "FormGroup",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 141,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 143,
      "name": "FormField",
      "parent": 141,
      "props": {
      },
      "seq": 143,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 144,
      "name": "FormField",
      "parent": 141,
      "props": {
      },
      "seq": 144,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 145,
      "name": "FormField",
      "parent": 141,
      "props": {
      },
      "seq": 145,
      "void": false
    },
    {
      "from": null,
      "id": 146,
      "name": "div",
      "parent": 124,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:\"1\"}"
        }
      },
      "seq": 146,
      "void": false
    },
    {
      "from": null,
      "id": 147,
      "name": "div",
      "parent": 130,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:\"1\"}"
        }
      },
      "seq": 147,
      "void": false
    },
    {
      "from": null,
      "id": 148,
      "name": "div",
      "parent": 132,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:\"1\"}"
        }
      },
      "seq": 148,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "CardTelepharQueueUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
