import React from "react";
import PropTypes from "prop-types";
import { TextArea, Form, Segment, Button } from "semantic-ui-react";
import { useIntl } from "react-intl";

import ModInfo from "react-lib/apps/common/ModInfo"


function useAPI({ func = () => {}, initialValue = null } = {}) {
  const [state, setState] = React.useState(initialValue);
  const [error, setError] = React.useState(null);
  const [loading, setIsLoading] = React.useState(false);

  const callAPI = async ({
    args,
    isMounted,
    successCallback,
    failCallback,
    networkErrorCallback,
  } = {}) => {
    setIsLoading(true);
    const [data, error, network] = await func({
      ...args,
    });

    if (isMounted) {
      setIsLoading(false);
      if (data) {
        setState(data);
        if (successCallback) {
          successCallback(data);
        }
      }
      if (error && ![500.503].includes(network.response.status)) {
        setError(error);
        if (failCallback) {
          failCallback(error);
        }
      } else if (error && [500.503].includes(network.response.status)) {
        networkErrorCallback(error);
      }
    }
  };

  const setData = (data = {}) => {
    setState((prevState) => ({
      ...prevState,
      ...data,
    }));
  };

  return { data: state, error, loading, callAPI, setData };
}

//* This card do not have controller
const SubPatientHistory = React.forwardRef((props, ref) => {
  const isMounted = React.useRef(false);
  const [openModInfoError, setOpenModInfoError] = React.useState(false);
  const [openModInfoSuccess, setOpenModInfoSuccess] = React.useState(false);

  const {
    data: sensitiveNote,
    setData: setSensitiveNote,
    loading: sensitiveNoteLoading,
    callAPI: getSensitiveNoteEMR,
  } = useAPI({
    func: props.controller.getSensitiveNoteEMR,
    initialValue: {},
  });

  const {
    data: patientHistory,
    setData: setPatientHistory,
    loading: patientLoading,
    callAPI: getPatientHistoryEMR,
  } = useAPI({
    func: props.controller.getPatientHistoryEMR,
    initialValue: {},
  });

  const {
    callAPI: postSensitiveNoteEMR,
    loading: createSensitiveNoteLoading,
  } = useAPI({
    func: props.controller.postSensitiveNoteEMR,
  });

  const {
    callAPI: putSensitiveNoteEMR,
    loading: saveSensitiveNoteLoading,
  } = useAPI({
    func: props.controller.putSensitiveNoteEMR,
  });

  const {
    callAPI: putPatientHistoryEMR,
    loading: savePatientHistoryLoading,
  } = useAPI({
    func: props.controller.putPatientHistoryEMR,
  });

  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    getPatientHistoryEMR({
      args: { emrId: props.emrId },
      isMounted: isMounted.current,
    });
    console.log(props);
    console.log(props.django);
    let divisionId = props.django ? props.django.division.id : props.divisionId;
    getSensitiveNoteEMR({
      args: { emrId: props.emrId, divisionId: divisionId },
      isMounted: isMounted.current,
    });
  }, [props.emrId]);

  // const handleSave = async () => {
  //   putPatientHistoryEMR({
  //     args: {
  //       emrId: props.emrId,
  //       pastIllness: patientHistory.past_illness,
  //       personalHistory: patientHistory.personal_history,
  //       familyHistory: patientHistory.family_history
  //     },
  //     isMounted: isMounted.current
  //   })
  //   putSensitiveNoteEMR({
  //     args: {
  //       emrId: props.emrId,
  //       divisionId: props.divisionId,
  //       sensitiveNote: sensitiveNote.sensitive_note
  //     },
  //     failCallback: ( error ) => {
  //       setOpenModInfoError(true)
  //     },
  //     isMounted: isMounted.current
  //   });
  // };

  const handleSave = async () => {
    var errList = [];
    const a = putPatientHistoryEMR({
      args: {
        emrId: props.emrId,
        pastIllness: patientHistory.past_illness,
        personalHistory: patientHistory.personal_history,
        familyHistory: patientHistory.family_history,
      },
      failCallback: ({ error }) => {
        errList.push(error);
      },
      isMounted: isMounted.current,
    });

    let b;
    let divisionId = props.django ? props.django.division.id : props.divisionId;
    if (sensitiveNote?.id) {
      console.log("if");
      b = putSensitiveNoteEMR({
        args: {
          emrId: props.emrId,
          divisionId: divisionId,
          sensitiveNote: sensitiveNote.sensitive_note,
        },
        failCallback: ({ error }) => {
          errList.push(error);
        },
        isMounted: isMounted.current,
      });
    } else {
      b = postSensitiveNoteEMR({
        args: {
          emrId: props.emrId,
          divisionId: divisionId,
          sensitiveNote: sensitiveNote.sensitive_note,
        },
        failCallback: ({ error }) => {
          errList.push(error);
        },
        isMounted: isMounted.current,
      });
    }
    Promise.all([a, b]).then(() => {
      if (errList.length > 0) {
        return setOpenModInfoError(true);
      }
      setOpenModInfoSuccess(true);
    });
  };

  return (
    <Segment
      loading={
        patientLoading ||
        sensitiveNoteLoading ||
        createSensitiveNoteLoading ||
        saveSensitiveNoteLoading ||
        savePatientHistoryLoading
      }
    >
      <ModInfo
        open={openModInfoError}
        type="error"
        onApprove={props.onClose}
        closeOnDimmerClick={true}
        onClose={() => setOpenModInfoError(false)}
      />
      <ModInfo
        open={openModInfoSuccess}
        type="success"
        onApprove={props.onClose}
        closeOnDimmerClick={true}
        onClose={() => setOpenModInfoSuccess(false)}
      />
      <Form>
        <Form.Group>
          <Form.Field width={8}>
            <label>Past illness</label>
            <TextArea
              id="SubPatientHistory-TextArea-PastIllness"
              rows={5}
              value={patientHistory.past_illness}
              onChange={(e) =>
                setPatientHistory({
                  past_illness: e.target.value,
                })
              }
            />
          </Form.Field>
          <Form.Field width={8}>
            <label>Personal History</label>
            <TextArea
              id="SubPatientHistory-TextArea-PersonalHistory"
              rows={5}
              value={patientHistory.personal_history}
              onChange={(e) =>
                setPatientHistory({
                  personal_history: e.target.value,
                })
              }
            />
          </Form.Field>
        </Form.Group>
        <br />
        <Form.Group>
          <Form.Field width={8}>
            <label>Family History</label>
            <TextArea
              id="SubPatientHistory-TextArea-FamilyHistory"
              rows={5}
              value={patientHistory.family_history}
              onChange={(e) =>
                setPatientHistory({
                  family_history: e.target.value,
                })
              }
            />
          </Form.Field>
          <Form.Field width={8}>
            <label>Sensitive Note</label>
            <TextArea
              id="SubPatientHistory-TextArea-SensitiveNote"
              rows={5}
              value={sensitiveNote.sensitive_note}
              onChange={(e) =>
                setSensitiveNote({
                  sensitive_note: e.target.value,
                })
              }
            />
          </Form.Field>
        </Form.Group>
        <br />
        <Form.Group style={{ display: props.readOnly ? "none" : null }}>
          <Form.Field width={3}>
            <Button color="green" content="Save" fluid onClick={handleSave} />
          </Form.Field>
          <Form.Field width={3}>
            <Button
              color="red"
              content="Cancel"
              fluid
              onClick={props.onClose}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    </Segment>
  );
});

SubPatientHistory.defaultProps = {
  patientData: {},
  controller: {},
  django: null,
  emrId: null,
  divisionId: null,
  readOnly: false,
  onClose: () => {},
};

SubPatientHistory.propTypes = {
  controller: PropTypes.object,
  django: PropTypes.object,
  patientData: PropTypes.object,
  emrId: PropTypes.number,
  divisionId: PropTypes.number,
  readOnly: PropTypes.bool,
  onClose: PropTypes.func,
};

export default React.memo(SubPatientHistory);
