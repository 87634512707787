
/* Generated apis ---------------------- */
import { to } from 'react-lib/utils'
import BaseService from  'react-lib/apis/services/BaseService'
import config from '../../config/config'
import AppointmentEmployeePositionSerializer from '../types/AppointmentEmployeePositionSerializer_apps_APP'


const HOST = `${config.API_HOST}`
/* ['generics.ListCreateAPIView'] */
/* params: patient, order_encounter, status, from_schedule__division, patient__hn, start_time, 'date__lte', 'date__gte', 'patient__hn__exact', 'type', 'appointment_type', 'doctor', 'division_search', 'doctor_search', 'patient', 'is_chart_summary', 'only_telemed', 'from_today', 'status_request', 'exclude_canceled', 'exclude_postponed', 'exclude_finished', 'exclude_unconfirmed', 'request_flag', 'request_flag', 'to_real_doctor_order' */
/* data: 'order_dict', 'order_dict', 'is_telemed' */
const AppointmentList : 
{
  list:     ({params, apiToken, extra}:
             { 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
  create:   ({params, data, apiToken, extra}:
             { 
                params?: any,
                data?: AppointmentEmployeePositionSerializer,
                apiToken?: any,
                extra?: any
            }) => any,
} = 
{
  list: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken)
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    if (extra?.division)
      base.client.defaults.headers["division"] = extra.division;
    if (extra?.device)
      base.client.defaults.headers["device"] = extra.device;
    const result = await to(base.client.get(`${HOST}/apis/APP/appointment/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  create: async ({params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken)
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    if (extra?.division)
      base.client.defaults.headers["division"] = extra.division;
    if (extra?.device)
      base.client.defaults.headers["device"] = extra.device;
    const result = await to(base.client.post(`${HOST}/apis/APP/appointment/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

export default AppointmentList

