import AddTableLivingWillTH from "./AddTableLivingWillTH";

export default function toPDFMakeData(props: any) {
  return {
    content: [
      {
        decoration: ``,
        color: ``,
        width: `auto`,
        pageBreak: ``,
        fontSize: `18`,
        decorationColor: ``,
        alignment: `center`,
        decorationStyle: ``,
        bold: `true`,
        margin: [0, 0, 0, 0],
        preserveLeadingSpaces: true,
        text: `Living Will`,
      },
      {
        bold: false,
        text: ` `,
        decorationColor: ``,
        decoration: ``,
        decorationStyle: ``,
        color: ``,
        alignment: `left`,
        preserveLeadingSpaces: true,
        pageBreak: ``,
        fontSize: 15,
        margin: [0, 0, 0, 0],
        width: `auto`,
      },
      {
        columns: [
          {
            bold: false,
            decoration: ``,
            color: ``,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            text: ``,
            decorationStyle: ``,
            pageBreak: ``,
            alignment: `left`,
            width: 20,
            decorationColor: ``,
            preserveLeadingSpaces: true,
          },
          {
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationStyle: ``,
            decorationColor: ``,
            alignment: `left`,
            decoration: ``,
            color: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            fontSize: 15,
            text: `ข้าพเจ้า (นาย/นาง/น.ส)`,
            width: `auto`,
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.formatPatient,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.........................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            color: ``,
            fontSize: 15,
            width: 10,
            text: ``,
            pageBreak: ``,
            decoration: ``,
            bold: false,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            alignment: `left`,
          },
          {
            preserveLeadingSpaces: true,
            text: `บัตรประจำตัวประชาชนเลขที่`,
            alignment: `left`,
            fontSize: 15,
            bold: false,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            decoration: ``,
            color: ``,
            decorationColor: ``,
            width: `auto`,
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.citizen_no || " ",
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `....................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            bold: false,
            fontSize: 15,
            width: `auto`,
            alignment: `left`,
            text: `สัญชาติ`,
            decoration: ``,
            preserveLeadingSpaces: true,
            color: ``,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.patientNationality || " ",
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            decorationColor: ``,
            decoration: ``,
            color: ``,
            width: 5,
            text: ``,
            bold: false,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            alignment: `left`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
          },
          {
            color: ``,
            width: `auto`,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            bold: false,
            alignment: `left`,
            fontSize: 15,
            decoration: ``,
            decorationColor: ``,
            pageBreak: ``,
            text: `เบอร์โทรศัพท์`,
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.present_address?.tel_mobile || " ",
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 10,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: `ที่อยู่ปัจจุบัน บ้านเลขที่`,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: `auto`,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text:
                  (props.items?.present_address?.no
                    ? props.items?.present_address?.no.length > 0
                      ? props.items?.present_address?.no
                      : " "
                    : " ") || " ",
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.......................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: `หมู่`,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: `auto`,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text:
                  (props.items?.present_address?.town
                    ? props.items?.present_address?.town.length > 0
                      ? props.items?.present_address?.town
                      : " "
                    : " ") || " ",
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.............................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            text: `หมู่บ้าน`,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: `auto`,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text:
                  (props.items?.present_address?.name
                    ? props.items?.present_address?.name.length > 0
                      ? props.items?.present_address?.name
                      : " "
                    : " ") || " ",
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `..................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: `ซอย`,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: `auto`,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text:
                  (props.items?.present_address?.street
                    ? props.items?.present_address?.street.length > 0
                      ? props.items?.present_address?.street
                      : " "
                    : " ") || " ",
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `....................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: `ถนน`,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: `auto`,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text:
                  (props.items?.present_address?.road
                    ? props.items?.present_address?.road.length > 0
                      ? props.items?.present_address?.road
                      : " "
                    : " ") || " ",
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: `แขวง/ตำบล`,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: `auto`,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text:
                  (props.items?.present_address?.city_label
                    ? props.items?.present_address?.city_label.length > 0
                      ? props.items?.present_address?.city_label
                      : " "
                    : " ") || " ",
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `...............................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            text: `เขต/อำเภอ`,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: `auto`,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text:
                  (props.items?.present_address?.district_label
                    ? props.items?.present_address?.district_label.length > 0
                      ? props.items?.present_address?.district_label
                      : " "
                    : " ") || " ",
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `...................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: `จังหวัด`,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: `auto`,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text:
                  (props.items?.present_address?.province_label
                    ? props.items?.present_address?.province_label.length > 0
                      ? props.items?.present_address?.province_label
                      : " "
                    : " ") || " ",
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `......................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: `รหัสไปรษณีย์`,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: `auto`,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text:
                  (props.items?.present_address?.zipcode
                    ? props.items?.present_address?.zipcode.length > 0
                      ? props.items?.present_address?.zipcode
                      : " "
                    : " ") || " ",
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.............................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        decorationColor: ``,
        margin: [0, 0, 0, 0],
        decorationStyle: ``,
        decoration: ``,
        alignment: `left`,
        fontSize: 15,
        text: `ประสงค์ทำหนังสือแสดงเจตนาไม่ประสงค์จะรับบริการสาธารณสุข ฉบับนี้ เพื่อแสดงออกถึงเจตนาของข้าพเจ้าว่าเมื่อข้าพเจ้าอยู่ใน`,
        bold: false,
        color: ``,
        width: `auto`,
        pageBreak: ``,
        preserveLeadingSpaces: true,
      },
      {
        decorationStyle: ``,
        alignment: `left`,
        fontSize: 15,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        text: `วาระสุดท้ายของชีวิตตามความเห็นของแพทย์ผู้รักษา ข้าพเจ้าขอปฏิเสธการรับบริการสาธารณสุขจากแพทย์ พยาบาล และเจ้าหน้าที่`,
        width: `auto`,
        decoration: ``,
        color: ``,
        bold: false,
        margin: [0, 0, 0, 0],
        pageBreak: ``,
      },
      {
        alignment: `left`,
        bold: false,
        preserveLeadingSpaces: true,
        fontSize: 15,
        width: `auto`,
        pageBreak: ``,
        margin: [0, 0, 0, 0],
        text: `สาธารณสุขโรงพยาบาลเซเปี้ยนซ์ หรือสถานบริการอื่น ที่เป็นไปเพียงเพื่อยืดการตายในวาระสุดท้ายของชีวิต หรือความทรมานจากการ`,
        decorationColor: ``,
        color: ``,
        decoration: ``,
        decorationStyle: ``,
      },
      {
        preserveLeadingSpaces: true,
        fontSize: 15,
        decorationColor: ``,
        width: `auto`,
        pageBreak: ``,
        alignment: `left`,
        decorationStyle: ``,
        margin: [0, 0, 0, 0],
        bold: false,
        decoration: ``,
        text: `เจ็บป่วย`,
        color: ``,
      },
      {
        bold: false,
        pageBreak: ``,
        margin: [0, 0, 0, 0],
        color: ``,
        preserveLeadingSpaces: true,
        width: `auto`,
        fontSize: 15,
        decorationColor: ``,
        text: ` `,
        decoration: ``,
        decorationStyle: ``,
        alignment: `left`,
      },
      AddTableLivingWillTH,
      {
        color: ``,
        decorationStyle: ``,
        preserveLeadingSpaces: true,
        alignment: `left`,
        text: ` `,
        bold: false,
        margin: [0, 0, 0, 0],
        decorationColor: ``,
        fontSize: 15,
        pageBreak: ``,
        decoration: ``,
        width: `auto`,
      },
      {
        margin: [0, 0, 0, 0],
        text: `เมื่อผู้ประกอบวิชาชีพด้านสาธารณสุข ได้ปฏิบัติตามเจตนาของข้าพเจ้าตามที่ระบุไว้ในหนังสือฉบับนี้แล้ว มิให้ถือว่าการกระทำนั้นเป็น`,
        fontSize: 15,
        decoration: ``,
        pageBreak: ``,
        color: ``,
        alignment: `left`,
        preserveLeadingSpaces: true,
        width: `auto`,
        decorationColor: ``,
        decorationStyle: ``,
        bold: false,
      },
      {
        decorationColor: ``,
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        pageBreak: ``,
        margin: [0, 0, 0, 0],
        fontSize: 15,
        width: `auto`,
        alignment: `left`,
        bold: false,
        text: `ความผิด และให้พ้นจากความรับผิดชอบทั้งปวง`,
        decoration: ``,
        color: ``,
      },
      {
        width: `auto`,
        bold: false,
        color: ``,
        decorationStyle: ``,
        margin: [0, 0, 0, 0],
        pageBreak: ``,
        preserveLeadingSpaces: true,
        decoration: ``,
        decorationColor: ``,
        fontSize: 15,
        alignment: `left`,
        text: `ข้าพเจ้าได้อ่านและรับทราบหลักเกณฑ์และวิธีการดำเนินการตามเอกสารแนบท้ายหนังสือแสดงเจตนาไม่ประสงค์รับบริการสาธารณสุข`,
      },
      {
        preserveLeadingSpaces: true,
        bold: false,
        alignment: `left`,
        margin: [0, 0, 0, 0],
        decorationStyle: ``,
        decorationColor: ``,
        fontSize: 15,
        color: ``,
        width: `auto`,
        decoration: ``,
        pageBreak: `after`,
        text: `ฉบับนี้แล้ว`,
      },
      {
        decorationColor: ``,
        text: `ข้าพเจ้าขอรับรองว่าหนังสือฉบับนี้ ข้าพเจ้าทำขึ้นขณะที่มีความสามารถและสติสัมปชัญญะบริบูรณ์ ข้าพเจ้าได้อ่านและเข้าใจผลของ`,
        decorationStyle: ``,
        bold: false,
        color: ``,
        pageBreak: ``,
        width: `auto`,
        preserveLeadingSpaces: true,
        fontSize: 15,
        decoration: ``,
        margin: [0, 0, 0, 0],
        alignment: `left`,
      },
      {
        alignment: `left`,
        bold: false,
        fontSize: 15,
        decoration: ``,
        color: ``,
        margin: [0, 0, 0, 0],
        pageBreak: ``,
        decorationStyle: ``,
        text: `หนังสือฉบับนี้โดยชัดแจ้งแล้ว เห็นว่า ทำถูกต้องตามเจตนาของข้าพเจ้า ทุกประการ ข้าพเจ้าจึงลงลายมือชื่อไว้ต่อหน้าพยาน`,
        width: `auto`,
        preserveLeadingSpaces: true,
        decorationColor: ``,
      },
      {
        decorationColor: ``,
        color: ``,
        fontSize: 15,
        preserveLeadingSpaces: true,
        pageBreak: ``,
        decorationStyle: ``,
        bold: false,
        width: `auto`,
        decoration: ``,
        margin: [0, 0, 0, 0],
        text: ` `,
        alignment: `left`,
      },
      {
        fontSize: 15,
        color: ``,
        preserveLeadingSpaces: true,
        margin: [0, 0, 0, 0],
        text: ` `,
        alignment: `left`,
        decoration: ``,
        pageBreak: ``,
        decorationStyle: ``,
        bold: false,
        width: `auto`,
        decorationColor: ``,
      },
      {
        columns: [
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.full_name_th || props.items?.full_name,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `......................................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            color: ``,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            text: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            bold: false,
            decoration: ``,
            width: 10,
            decorationColor: ``,
            alignment: `left`,
            decorationStyle: ``,
          },
          {
            width: `auto`,
            stack: [
              {
                text: ` `,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.....................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            decoration: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            color: ``,
            text: ``,
            pageBreak: ``,
            bold: false,
            width: 10,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decorationColor: ``,
            decorationStyle: ``,
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.signedDate,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `...............................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            text: ``,
            preserveLeadingSpaces: true,
            color: ``,
            pageBreak: ``,
            decorationStyle: ``,
            decorationColor: ``,
            decoration: ``,
            width: 88,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            fontSize: 15,
          },
          {
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            width: `auto`,
            bold: false,
            decorationStyle: ``,
            decoration: ``,
            alignment: `left`,
            color: ``,
            preserveLeadingSpaces: true,
            text: `ชื่อผู้ป่วย`,
            pageBreak: ``,
          },
          {
            decorationStyle: ``,
            decoration: ``,
            text: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            pageBreak: ``,
            width: 165,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            bold: false,
            alignment: `left`,
            color: ``,
          },
          {
            text: `ลายเซ็นผู้ป่วย`,
            pageBreak: ``,
            fontSize: 15,
            decorationColor: ``,
            color: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            width: `auto`,
            bold: false,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
          },
          {
            text: ``,
            color: ``,
            pageBreak: ``,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decoration: ``,
            bold: false,
            fontSize: 15,
            alignment: `left`,
            width: 108,
          },
          {
            decoration: ``,
            decorationColor: ``,
            decorationStyle: ``,
            text: `วันที่`,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            color: ``,
            bold: false,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
            alignment: `left`,
          },
        ],
      },
      {
        pageBreak: ``,
        bold: false,
        preserveLeadingSpaces: true,
        text: ` `,
        width: `auto`,
        decoration: ``,
        decorationColor: ``,
        decorationStyle: ``,
        fontSize: 15,
        color: ``,
        alignment: `left`,
        margin: [0, 0, 0, 0],
      },
      {
        decoration: ``,
        width: `auto`,
        bold: false,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        pageBreak: ``,
        color: ``,
        margin: [0, 0, 0, 0],
        alignment: `left`,
        decorationStyle: ``,
        text: ` `,
        fontSize: 15,
      },
      {
        columns: [
          {
            width: `auto`,
            stack: [
              {
                text: ` `,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `......................................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            alignment: `left`,
            pageBreak: ``,
            fontSize: 15,
            decorationColor: ``,
            bold: false,
            margin: [0, 0, 0, 0],
            text: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            color: ``,
            decoration: ``,
            width: 10,
          },
          {
            width: `auto`,
            stack: [
              {
                text: ` `,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.....................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            fontSize: 15,
            decorationStyle: ``,
            pageBreak: ``,
            width: 10,
            decorationColor: ``,
            decoration: ``,
            color: ``,
            text: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            preserveLeadingSpaces: true,
            bold: false,
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.signedDate,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `...............................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decoration: ``,
            decorationStyle: ``,
            bold: false,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            text: ``,
            color: ``,
            width: 88,
          },
          {
            color: ``,
            decoration: ``,
            decorationStyle: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            bold: false,
            pageBreak: ``,
            width: `auto`,
            decorationColor: ``,
            text: `ชื่อพยาน`,
            fontSize: 15,
          },
          {
            width: 165,
            fontSize: 15,
            text: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            color: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            pageBreak: ``,
            decoration: ``,
            bold: false,
            decorationColor: ``,
          },
          {
            decorationStyle: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
            pageBreak: ``,
            fontSize: 15,
            alignment: `left`,
            color: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            text: `ลายเซ็นพยาน`,
            decorationColor: ``,
          },
          {
            margin: [0, 0, 0, 0],
            decoration: ``,
            text: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            color: ``,
            pageBreak: ``,
            decorationStyle: ``,
            bold: false,
            width: 108,
            alignment: `left`,
          },
          {
            width: `auto`,
            margin: [0, 0, 0, 0],
            text: `วันที่`,
            alignment: `left`,
            decorationColor: ``,
            bold: false,
            pageBreak: ``,
            color: ``,
            fontSize: 15,
            decoration: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        color: ``,
        preserveLeadingSpaces: true,
        margin: [0, 0, 0, 0],
        bold: false,
        fontSize: 15,
        pageBreak: ``,
        decorationStyle: ``,
        alignment: `left`,
        text: ` `,
        decorationColor: ``,
        decoration: ``,
        width: `auto`,
      },
      {
        pageBreak: ``,
        decorationColor: ``,
        alignment: `left`,
        fontSize: 15,
        preserveLeadingSpaces: true,
        bold: false,
        color: ``,
        margin: [0, 0, 0, 0],
        decorationStyle: ``,
        text: ` `,
        decoration: ``,
        width: `auto`,
      },
      {
        columns: [
          {
            width: `auto`,
            stack: [
              {
                text: ` `,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `......................................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            decoration: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            pageBreak: ``,
            bold: false,
            width: 10,
            color: ``,
            text: ``,
            decorationStyle: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
          },
          {
            width: `auto`,
            stack: [
              {
                text: ` `,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.....................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            width: 10,
            decoration: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            color: ``,
            decorationStyle: ``,
            pageBreak: ``,
            decorationColor: ``,
            fontSize: 15,
            bold: false,
            text: ``,
            preserveLeadingSpaces: true,
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.signedDate,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `...............................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            width: 88,
            decorationStyle: ``,
            fontSize: 15,
            text: ``,
            preserveLeadingSpaces: true,
            color: ``,
            alignment: `left`,
            decoration: ``,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            bold: false,
          },
          {
            fontSize: 15,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            color: ``,
            pageBreak: ``,
            decorationColor: ``,
            alignment: `left`,
            decoration: ``,
            bold: false,
            decorationStyle: ``,
            text: `ชื่อพยาน`,
            width: `auto`,
          },
          {
            margin: [0, 0, 0, 0],
            decoration: ``,
            text: ``,
            width: 165,
            bold: false,
            color: ``,
            decorationStyle: ``,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            fontSize: 15,
          },
          {
            pageBreak: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            color: ``,
            width: `auto`,
            fontSize: 15,
            decorationColor: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            text: `ลายเซ็นพยาน`,
            bold: false,
            decoration: ``,
          },
          {
            margin: [0, 0, 0, 0],
            fontSize: 15,
            decorationColor: ``,
            width: 108,
            pageBreak: ``,
            color: ``,
            alignment: `left`,
            text: ``,
            bold: false,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
          },
          {
            decoration: ``,
            color: ``,
            width: `auto`,
            alignment: `left`,
            bold: false,
            decorationStyle: ``,
            decorationColor: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            text: `วันที่`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
          },
        ],
      },
      {
        decorationColor: ``,
        pageBreak: ``,
        decorationStyle: ``,
        fontSize: 15,
        width: `auto`,
        decoration: ``,
        margin: [0, 0, 0, 0],
        preserveLeadingSpaces: true,
        color: ``,
        bold: false,
        alignment: `left`,
        text: ` `,
      },
      {
        columns: [
          {
            decoration: ``,
            text: ``,
            pageBreak: ``,
            fontSize: 15,
            alignment: `left`,
            bold: false,
            width: 25,
            decorationStyle: ``,
            color: ``,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
          },
          {
            alignment: `left`,
            text: `ข้าพเจ้า นพ./พญ.`,
            fontSize: 15,
            decorationStyle: ``,
            decorationColor: ``,
            decoration: ``,
            bold: false,
            width: `auto`,
            margin: [0, 0, 0, 0],
            color: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.doctorFullName,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            decorationColor: ``,
            width: 10,
            decoration: ``,
            bold: false,
            alignment: `left`,
            decorationStyle: ``,
            color: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            pageBreak: ``,
            text: ``,
            fontSize: 15,
          },
          {
            decorationColor: ``,
            text: `ใบประกอบวิชาชีพเวชกรรมเลขที่`,
            pageBreak: ``,
            decorationStyle: ``,
            bold: false,
            width: `auto`,
            decoration: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            color: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.doctorNumber || " ",
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `..............................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            text: `ขอรับรองว่า (ชื่อผู้ป่วย)`,
            alignment: `left`,
            decoration: ``,
            bold: false,
            preserveLeadingSpaces: true,
            fontSize: 15,
            color: ``,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            width: `auto`,
            pageBreak: ``,
          },
          {
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.full_name_th || props.items?.full_name,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `................................................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            width: 10,
            text: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            bold: false,
            decoration: ``,
            color: ``,
            decorationColor: ``,
          },
          {
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            bold: false,
            fontSize: 15,
            decorationColor: ``,
            decoration: ``,
            width: `auto`,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            text: `มีสติสัมปชัญญะบริบูรณ์ในขณะที่ทำ "หนังสือ`,
            pageBreak: ``,
            color: ``,
          },
        ],
      },
      {
        pageBreak: ``,
        text: `แสดงเจตนาไม่ประสงค์จะรับบริการสาธารณสุข" ฉบับนี้ จึงลงนามไว้เป็นหลักฐาน`,
        alignment: `left`,
        color: ``,
        fontSize: 15,
        decorationStyle: ``,
        bold: false,
        margin: [0, 0, 0, 0],
        decorationColor: ``,
        preserveLeadingSpaces: true,
        decoration: ``,
        width: `auto`,
      },
      {
        decorationStyle: ``,
        width: `auto`,
        bold: false,
        decorationColor: ``,
        margin: [0, 0, 0, 0],
        alignment: `left`,
        color: ``,
        preserveLeadingSpaces: true,
        pageBreak: ``,
        text: ` `,
        fontSize: 15,
        decoration: ``,
      },
      {
        pageBreak: ``,
        alignment: `left`,
        margin: [0, 0, 0, 0],
        decoration: ``,
        color: ``,
        bold: false,
        fontSize: 15,
        width: `auto`,
        text: ` `,
        preserveLeadingSpaces: true,
        decorationColor: ``,
        decorationStyle: ``,
      },
      {
        columns: [
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.doctorFullName,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `......................................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            text: ``,
            alignment: `left`,
            decoration: ``,
            color: ``,
            width: 10,
            decorationStyle: ``,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            fontSize: 15,
            bold: false,
            preserveLeadingSpaces: true,
          },
          {
            width: `auto`,
            stack: [
              {
                text: ` `,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `..................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            color: ``,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            width: 10,
            bold: false,
            decorationStyle: ``,
            decoration: ``,
            pageBreak: ``,
            alignment: `left`,
            text: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.signedDate,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `...............................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            decorationStyle: ``,
            bold: false,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            width: 75,
            decoration: ``,
            pageBreak: ``,
            text: ``,
            color: ``,
            fontSize: 15,
            alignment: `left`,
          },
          {
            decorationColor: ``,
            alignment: `left`,
            pageBreak: ``,
            text: `ชื่อแพทย์ผู้รักษา`,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationStyle: ``,
            color: ``,
            bold: false,
            margin: [0, 0, 0, 0],
            width: `auto`,
          },
          {
            decorationColor: ``,
            bold: false,
            decorationStyle: ``,
            alignment: `left`,
            width: 130,
            pageBreak: ``,
            fontSize: 15,
            decoration: ``,
            margin: [0, 0, 0, 0],
            text: ``,
            color: ``,
            preserveLeadingSpaces: true,
          },
          {
            color: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            text: `ลายเซ็นแพทย์ผู้รักษา`,
            fontSize: 15,
            width: `auto`,
            decoration: ``,
            decorationColor: ``,
            alignment: `left`,
          },
          {
            decoration: ``,
            pageBreak: ``,
            text: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            bold: false,
            decorationColor: ``,
            alignment: `left`,
            fontSize: 15,
            width: 95,
            color: ``,
          },
          {
            decoration: ``,
            text: `วันที่`,
            decorationColor: ``,
            fontSize: 15,
            width: `auto`,
            alignment: `left`,
            preserveLeadingSpaces: true,
            color: ``,
            pageBreak: ``,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            bold: false,
          },
        ],
      },
      {
        text: ` `,
        fontSize: 15,
        color: ``,
        decorationColor: ``,
        width: `auto`,
        pageBreak: ``,
        preserveLeadingSpaces: true,
        margin: [0, 0, 0, 0],
        alignment: `left`,
        bold: false,
        decoration: ``,
        decorationStyle: ``,
      },
      {
        preserveLeadingSpaces: true,
        decoration: ``,
        text: ` ข้าพเจ้าขอยกเลิกหนังสือแสดงเจตนาฉบับนี้ที่ข้าพเจ้าได้ทำไว้`,
        pageBreak: ``,
        decorationColor: ``,
        bold: `true`,
        width: `auto`,
        color: ``,
        margin: [0, 0, 0, 0],
        alignment: `left`,
        decorationStyle: ``,
        fontSize: 15,
      },
      {
        bold: false,
        color: ``,
        decorationStyle: ``,
        fontSize: 15,
        margin: [0, 0, 0, 0],
        decoration: ``,
        width: `auto`,
        text: ` `,
        alignment: `left`,
        pageBreak: ``,
        decorationColor: ``,
        preserveLeadingSpaces: true,
      },
      {
        columns: [
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.full_name_th || props.items?.full_name,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `......................................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            decoration: ``,
            width: 10,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decorationStyle: ``,
            pageBreak: ``,
            text: ``,
            bold: false,
            alignment: `left`,
            color: ``,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
          },
          {
            width: `auto`,
            stack: [
              {
                text: ` `,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.....................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            color: ``,
            pageBreak: ``,
            width: 10,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decorationStyle: ``,
            alignment: `left`,
            text: ``,
            margin: [0, 0, 0, 0],
            decoration: ``,
            bold: false,
            fontSize: 15,
          },
          {
            width: `auto`,
            stack: [
              {
                text: props.items?.signedDate,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `...............................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            decorationColor: ``,
            bold: false,
            decoration: ``,
            pageBreak: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            alignment: `center`,
            fontSize: 15,
            width: 210,
            text: props.items?.full_name_th,
            margin: [0, 0, 0, 0],
          },
          {
            text: `ลายเซ็นผู้ป่วย`,
            fontSize: 15,
            preserveLeadingSpaces: true,
            bold: false,
            decorationColor: ``,
            pageBreak: ``,
            width: 200,
            decoration: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            alignment: `center`,
            color: ``,
          },
          {
            fontSize: 15,
            margin: [0, 0, 0, 0],
            color: ``,
            width: 94,
            decorationStyle: ``,
            bold: false,
            decorationColor: ``,
            text: `วันที่`,
            pageBreak: `after`,
            alignment: `center`,
            decoration: ``,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        pageBreak: ``,
        bold: `true`,
        text: `หลักเกณฑ์และวิธีการดำเนินตามหนังสือแสดงเจตนาไม่ประสงค์จะรับบริการสาธารณสุข`,
        decorationColor: ``,
        decorationStyle: ``,
        margin: [0, 0, 0, 0],
        width: `auto`,
        preserveLeadingSpaces: true,
        decoration: ``,
        fontSize: 15,
        alignment: `left`,
        color: ``,
      },
      {
        decorationColor: ``,
        width: `auto`,
        pageBreak: ``,
        text: ` `,
        margin: [0, 0, 0, 0],
        alignment: `left`,
        fontSize: 15,
        color: ``,
        preserveLeadingSpaces: true,
        bold: false,
        decorationStyle: ``,
        decoration: ``,
      },
      {
        columns: [
          {
            pageBreak: ``,
            decorationColor: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            text: `(1)`,
            bold: false,
            width: `auto`,
            color: ``,
            fontSize: 15,
          },
          {
            decoration: ``,
            decorationStyle: ``,
            alignment: `left`,
            bold: false,
            width: 10,
            fontSize: 15,
            decorationColor: ``,
            pageBreak: ``,
            text: ``,
            color: ``,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            decoration: ``,
            width: `auto`,
            decorationStyle: ``,
            pageBreak: ``,
            bold: false,
            text: `พยานในการทำหนังสือแสดงเจตนาไม่ประสงค์จะรับบริการสาธารณสุขต้องมีจำนวน 2 คน โดยพยานคนที่ 1 ต้องเป็นญาติหรือ`,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            color: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            color: ``,
            text: ``,
            alignment: `left`,
            width: 20,
            bold: false,
            fontSize: 15,
            decoration: ``,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            decorationStyle: ``,
          },
          {
            preserveLeadingSpaces: true,
            width: `auto`,
            text: `บุคคลที่ผู้ทำหนังสือแสดงเจตนาไว้วางใจ และรับทราบความประสงค์ของผู้ทำหนังสือแสดงเจตนาเป็นอย่างดี และพยานคนที่ 2 ต้องไม่ใช่ญาติ และไม่ใช่ แพทย์ พยาบาล หรือ เจ้าหน้าที่ที่เกี่ยวข้องกับการดูแลผู้ป่วยที่เป็นผู้ทำหนังสือแสดงเจตนา`,
            alignment: `left`,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            bold: false,
            decoration: ``,
            decorationStyle: ``,
            color: ``,
            decorationColor: ``,
          },
        ],
      },
      {
        color: ``,
        decorationStyle: ``,
        width: `auto`,
        margin: [0, 0, 0, 0],
        alignment: `left`,
        pageBreak: ``,
        fontSize: 15,
        preserveLeadingSpaces: true,
        bold: false,
        decorationColor: ``,
        text: ` `,
        decoration: ``,
      },
      {
        columns: [
          {
            color: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            bold: false,
            decoration: ``,
            text: `(2)`,
            pageBreak: ``,
            fontSize: 15,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            width: `auto`,
          },
          {
            text: ``,
            bold: false,
            fontSize: 15,
            decorationStyle: ``,
            alignment: `left`,
            width: 10,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            decoration: ``,
            color: ``,
            pageBreak: ``,
            decorationColor: ``,
          },
          {
            decorationColor: ``,
            color: ``,
            fontSize: 15,
            text: `ในกรณีผู้ทำหนังสือแสดงเจตนา ได้รับอันตรายเป็นการฉุกเฉินเร่งด่วน ผู้ประกอบวิชาชีพด้านสาธารณสุขจะให้การช่วยเหลือและ`,
            alignment: `left`,
            decoration: ``,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            width: `auto`,
            preserveLeadingSpaces: true,
            bold: false,
            decorationStyle: ``,
          },
        ],
      },
      {
        columns: [
          {
            margin: [0, 0, 0, 0],
            width: 20,
            text: ``,
            decorationStyle: ``,
            decoration: ``,
            color: ``,
            alignment: `left`,
            fontSize: 15,
            preserveLeadingSpaces: true,
            bold: false,
            pageBreak: ``,
            decorationColor: ``,
          },
          {
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            text: `ดูแลผู้ทำหนังสือแสดงเจตนาตามมาตรฐานการช่วยเหลือผู้ป่วยในภาวะฉุกเฉิน เว้นแต่ผู้ทำหนังสือแสดงเจตนาได้รับการวินิจฉัย`,
            width: `auto`,
            pageBreak: ``,
            bold: false,
            color: ``,
            alignment: `left`,
            decoration: ``,
            fontSize: 15,
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            preserveLeadingSpaces: true,
            text: ``,
            decoration: ``,
            color: ``,
            bold: false,
            width: 20,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            pageBreak: ``,
            fontSize: 15,
          },
          {
            bold: false,
            preserveLeadingSpaces: true,
            color: ``,
            decoration: ``,
            alignment: `left`,
            fontSize: 15,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            width: `auto`,
            decorationColor: ``,
            decorationStyle: ``,
            text: `ตามมาตรฐานทางการแพทย์ว่า ผู้ทำหนังสือแสดงเจตนาอยู่ในวาระสุดท้ายของชีวิต และเหตุที่ฉุกเฉินนั้นเนื่องมาจากโรคที่แพทย์`,
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            decoration: ``,
            text: ``,
            width: 20,
            alignment: `left`,
            bold: false,
            fontSize: 15,
            color: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
          },
          {
            fontSize: 15,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            decoration: ``,
            color: ``,
            width: `auto`,
            bold: false,
            preserveLeadingSpaces: true,
            text: `ได้วินิจฉัยไว้แล้ว ผู้ประกอบวิชาชีพด้านสาธารณสุขจึงจะปฏิบัติตามหนังสือแสดงเจตนาไม่ประสงค์จะรับบริการสาธารณสุข`,
            decorationColor: ``,
            pageBreak: ``,
            decorationStyle: ``,
          },
        ],
      },
      {
        decorationColor: ``,
        bold: false,
        text: ` `,
        color: ``,
        alignment: `left`,
        width: `auto`,
        preserveLeadingSpaces: true,
        decoration: ``,
        decorationStyle: ``,
        margin: [0, 0, 0, 0],
        fontSize: 15,
        pageBreak: ``,
      },
      {
        columns: [
          {
            decorationStyle: ``,
            bold: false,
            fontSize: 15,
            width: `auto`,
            pageBreak: ``,
            decorationColor: ``,
            color: ``,
            text: `(3)`,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            decoration: ``,
            preserveLeadingSpaces: true,
          },
          {
            text: ``,
            pageBreak: ``,
            alignment: `left`,
            bold: false,
            preserveLeadingSpaces: true,
            color: ``,
            decorationStyle: ``,
            decoration: ``,
            decorationColor: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            width: 10,
          },
          {
            decoration: ``,
            fontSize: 15,
            alignment: `left`,
            decorationStyle: ``,
            bold: false,
            decorationColor: ``,
            width: `auto`,
            pageBreak: ``,
            color: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            text: `เมื่อวาระสุดท้ายของชีวิตใกล้จะมาถึง หรือเพื่อยุติการทรมานจากการเจ็บป่วย สถานบริการสาธารณสุข จะดำเนินการดังนี้`,
          },
        ],
      },
      {
        columns: [
          {
            width: 25,
            decorationColor: ``,
            bold: false,
            margin: [0, 0, 0, 0],
            color: ``,
            decorationStyle: ``,
            pageBreak: ``,
            alignment: `left`,
            fontSize: 15,
            decoration: ``,
            preserveLeadingSpaces: true,
            text: ``,
          },
          {
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            decorationColor: ``,
            bold: false,
            alignment: `left`,
            decoration: ``,
            decorationStyle: ``,
            fontSize: 15,
            pageBreak: ``,
            color: ``,
            text: `(3.1)`,
            width: `auto`,
          },
          {
            width: 10,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decoration: ``,
            text: ``,
            decorationStyle: ``,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            bold: false,
            color: ``,
            decorationColor: ``,
          },
          {
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decoration: ``,
            bold: false,
            alignment: `left`,
            color: ``,
            pageBreak: ``,
            text: `ในกรณีที่ผู้ทำหนังสือแสดงเจตนามีสติสัมปชัญญะดีพอที่จะสื่อสารได้ตามปกติ ผู้ประกอบวิชาชีพเวชกรรมผู้รับผิดชอบ`,
            decorationStyle: ``,
            width: `auto`,
            margin: [0, 0, 0, 0],
            fontSize: 15,
          },
        ],
      },
      {
        columns: [
          {
            text: ``,
            alignment: `left`,
            pageBreak: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            width: 55,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            color: ``,
            decoration: ``,
            bold: false,
            decorationStyle: ``,
          },
          {
            pageBreak: ``,
            text: `การรักษา จะอธิบายให้ผู้ทำหนังสือแสดงเจตนาทราบถึงภาวะและความเป็นไปได้ของโรคในขณะนั้น เพื่อขอคำยืนยัน`,
            preserveLeadingSpaces: true,
            fontSize: 15,
            bold: false,
            width: `auto`,
            decoration: ``,
            alignment: `left`,
            color: ``,
            decorationColor: ``,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            text: ``,
            bold: false,
            decoration: ``,
            preserveLeadingSpaces: true,
            color: ``,
            alignment: `left`,
            fontSize: 15,
            decorationColor: ``,
            width: 55,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
          },
          {
            decorationStyle: ``,
            width: `auto`,
            color: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            pageBreak: ``,
            bold: false,
            decorationColor: ``,
            text: `หรือปฏิเสธก่อนที่จะปฏิบัติตามหนังสือแสดงเจตนา`,
            alignment: `left`,
            margin: [0, 0, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            fontSize: 15,
            color: ``,
            text: ``,
            decorationColor: ``,
            pageBreak: ``,
            decorationStyle: ``,
            decoration: ``,
            width: 25,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            alignment: `left`,
          },
          {
            fontSize: 15,
            preserveLeadingSpaces: true,
            alignment: `left`,
            width: `auto`,
            decoration: ``,
            color: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            text: `(3.2)`,
            pageBreak: ``,
            bold: false,
            decorationColor: ``,
          },
          {
            width: 10,
            fontSize: 15,
            alignment: `left`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            bold: false,
            decorationStyle: ``,
            decoration: ``,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            text: ``,
            color: ``,
          },
          {
            alignment: `left`,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            width: `auto`,
            decorationStyle: ``,
            decorationColor: ``,
            pageBreak: ``,
            color: ``,
            text: `ในกรณีที่ผู้ทำหนังสือแสดงเจตนาไม่มีสติสัมปชัญญะดีพอที่จะสื่อสารได้ตามปกติ ผู้ประกอบวิชาชีพเวชกรรมผู้รับผิดชอบ`,
            decoration: ``,
            bold: false,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        columns: [
          {
            text: ``,
            margin: [0, 0, 0, 0],
            color: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            fontSize: 15,
            alignment: `left`,
            decoration: ``,
            width: 55,
            decorationColor: ``,
            decorationStyle: ``,
            bold: false,
          },
          {
            bold: false,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            decoration: ``,
            text: `การรักษาจะอธิบายถึงภาวะและความเป็นไปได้ของโรคให้พยานคนที่ 1 หรือญาติของผู้ทำหนังสือแสดงเจตนาทราบ`,
            decorationColor: ``,
            fontSize: 15,
            width: `auto`,
            preserveLeadingSpaces: true,
            alignment: `left`,
            color: ``,
            pageBreak: ``,
          },
        ],
      },
      {
        columns: [
          {
            alignment: `left`,
            decorationStyle: ``,
            decorationColor: ``,
            pageBreak: ``,
            width: 55,
            bold: false,
            fontSize: 15,
            preserveLeadingSpaces: true,
            text: ``,
            decoration: ``,
            margin: [0, 0, 0, 0],
            color: ``,
          },
          {
            width: `auto`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            text: `และแจ้งรายละเอียดเกี่ยวกับการดำเนินการตามหนังสือแสดงเจตนาก่อนที่จะปฏิบัติตามหนังสือแสดงเจตนา`,
            color: ``,
            decorationColor: ``,
            alignment: `left`,
            bold: false,
            decorationStyle: ``,
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            color: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            text: ``,
            fontSize: 15,
            width: 25,
            decorationStyle: ``,
            pageBreak: ``,
            bold: false,
            decoration: ``,
            decorationColor: ``,
          },
          {
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            bold: false,
            pageBreak: ``,
            decoration: ``,
            width: `auto`,
            color: ``,
            alignment: `left`,
            decorationColor: ``,
            text: `(3.3)`,
            fontSize: 15,
            margin: [0, 0, 0, 0],
          },
          {
            margin: [0, 0, 0, 0],
            color: ``,
            decoration: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            text: ``,
            width: 10,
            decorationStyle: ``,
            bold: false,
            pageBreak: ``,
            alignment: `left`,
          },
          {
            alignment: `left`,
            decorationColor: ``,
            width: `auto`,
            decoration: ``,
            preserveLeadingSpaces: true,
            text: `กรณีมีปัญหาเกี่ยวกับการดำเนินการตามหนังสือแสดงเจตนา ผู้ประกอบวิชาชีพเวชกรรมผู้รับผิดชอบการรักษาจะปรึกษา`,
            pageBreak: ``,
            bold: false,
            margin: [0, 0, 0, 0],
            color: ``,
            fontSize: 15,
            decorationStyle: ``,
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            fontSize: 15,
            color: ``,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            bold: false,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decorationStyle: ``,
            alignment: `left`,
            text: ``,
            width: 55,
          },
          {
            bold: false,
            decoration: ``,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            width: `auto`,
            pageBreak: ``,
            decorationStyle: ``,
            decorationColor: ``,
            color: ``,
            text: `กับพยานคนที่ 1 หรือญาติของผู้ทำหนังสือแสดงเจตนานั้น โดยคำนึงถึงเจตนาของผู้ทำหนังสือแสดงเจตนาเป็นสำคัญ`,
            fontSize: 15,
            alignment: `left`,
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            color: ``,
            bold: false,
            decorationColor: ``,
            text: ``,
            width: 25,
            decoration: ``,
            decorationStyle: ``,
            alignment: `left`,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
          },
          {
            pageBreak: ``,
            decoration: ``,
            text: `(3.4)`,
            fontSize: 15,
            width: `auto`,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            bold: false,
            alignment: `left`,
            decorationColor: ``,
            color: ``,
          },
          {
            text: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            pageBreak: ``,
            alignment: `left`,
            width: 10,
            color: ``,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            bold: false,
            decorationColor: ``,
            decoration: ``,
          },
          {
            decoration: ``,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            color: ``,
            fontSize: 15,
            bold: false,
            width: `auto`,
            alignment: `left`,
            decorationColor: ``,
            text: `ในกรณีผู้ทำหนังสือแสดงเจตนาอยู่ในระหว่างตั้งครรภ์ ให้ดำเนินการตามหนังสือแสดงเจตนาได้เมื่อผู้นั้นพ้นจากสภาพ`,
            pageBreak: ``,
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            bold: false,
            color: ``,
            alignment: `left`,
            text: ``,
            decorationColor: ``,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            width: 55,
          },
          {
            decoration: ``,
            decorationColor: ``,
            color: ``,
            fontSize: 15,
            decorationStyle: ``,
            bold: false,
            text: `การตั้งค่าครรภ์แล้ว`,
            alignment: `left`,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            width: `auto`,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            fontSize: 15,
            color: ``,
            bold: false,
            text: ``,
            pageBreak: ``,
            decoration: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            decorationColor: ``,
            width: 55,
          },
          {
            width: `auto`,
            color: ``,
            pageBreak: ``,
            bold: false,
            fontSize: 15,
            decoration: ``,
            decorationStyle: ``,
            alignment: `left`,
            text: `ทั้งนี้ ผู้ทำหนังสือแสดงเจตนายังคงได้รับการดูแลแบบประคอง (Palliative care) จากสถานบริการสาธารณสุข`,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
          },
        ],
      },
      {
        decorationColor: ``,
        decoration: ``,
        bold: false,
        text: ` `,
        fontSize: 15,
        decorationStyle: ``,
        alignment: `left`,
        color: ``,
        preserveLeadingSpaces: true,
        width: `auto`,
        margin: [0, 0, 0, 0],
        pageBreak: ``,
      },
      {
        columns: [
          {
            pageBreak: ``,
            decoration: ``,
            width: `auto`,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            alignment: `left`,
            fontSize: 15,
            preserveLeadingSpaces: true,
            color: ``,
            text: `(4)`,
            decorationStyle: ``,
            bold: false,
          },
          {
            text: ``,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            width: 10,
            alignment: `left`,
            pageBreak: ``,
            fontSize: 15,
            color: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            bold: false,
            decoration: ``,
          },
          {
            width: `auto`,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            decorationStyle: ``,
            bold: false,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            color: ``,
            alignment: `left`,
            text: `ผู้ทำหนังสือแสดงเจตนาอาจยกเลิกหรือเปลี่ยนแปลงหนังสือแสดงเจตนาได้ โดยแจ้งความประสงค์แก่สถานบริการสาธารณสุข`,
            decoration: ``,
            fontSize: 15,
          },
        ],
      },
      {
        columns: [
          {
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            alignment: `left`,
            decorationStyle: ``,
            fontSize: 15,
            width: 25,
            bold: false,
            color: ``,
            text: ``,
            decoration: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
          },
          {
            decorationColor: ``,
            fontSize: 15,
            decoration: ``,
            preserveLeadingSpaces: true,
            color: ``,
            pageBreak: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            bold: false,
            text: `และในกรณีที่มีการแสดงหนังสือแสดงเจตนาหลายฉบับให้ถือว่าฉบับที่ทำหลังสุดเป็นฉบับที่มีผลบังคับ`,
            width: `auto`,
          },
        ],
      },
    ],
    pageSize: `A4`,
    pageOrientation: `portrait`,
    defaultStyle: {
      font: `THSarabunNew`,
    },
    header: props.header,
    footer: props.footer,
  };
}
