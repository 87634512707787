import { WasmHandler } from "react-lib/frameworks/WasmController";

import CONFIG from "config/config";

export type State = {
  firebaseToken?: string;
};

export const StateInitial = {
  firebaseToken: "",
};

export type Event =
  | { message: "FirebaseSetupStateChangeHandler"; params: {} }
  | { message: "FirebaseCustomLoginWithUsername"; params: {} }
  | { message: "FirebaseLogout"; params: {} };

type Handler = WasmHandler<State, Event>;

export const SetupStateChangeHandler: Handler = (controller, params) => {
  const onFirebaseAuthStateChanged = (user: any) => {
    if (user) {
      // Check if logged in
      console.log("user: ", user.email);
      console.log(params);
      params?.loginCallback?.(user, controller);
    } else {
      console.log("no user");
    }
  };
  if (!CONFIG.OFFLINE) {
    controller.app.auth().onAuthStateChanged(onFirebaseAuthStateChanged);
  }
  OpenLoginForm(controller);
};

export const CustomLoginWithUsername: Handler = (controller, params) => {
  console.log("CustomLoginWithUsername: signInWithCustomToken" );
  if (!CONFIG.OFFLINE) {
    controller.functions
      .httpsCallable("createTokenFromUser")(params.username.toString())
      .then((result: any) => {
        // console.log("Token", result.data);
        controller.setState({ firebaseToken: result.data });
        controller.app
          .auth()
          .signInWithCustomToken(result.data)
          .catch(function (error: any) {
            console.log(error);
          });
      })
      .catch((error: any) => {
        console.log(error);
      });
  }
};

export const Logout: Handler = (controller, params) => {
  controller.app
    .auth()
    .signOut()
    .then(() => {
      params?.logoutCallback?.(controller);
      OpenLoginForm(controller, {});
    });
};

const OpenLoginForm: Handler = (controller, params) => {
  if (!CONFIG.OFFLINE) {
    if (!controller.app.auth().currentUser) {
      controller.ui.start("#firebase-login", {
        callbacks: {
          signInSuccessWithAuthResult: function (authResult, redirectUrl) {
            return false;
          },
        },
        signInOptions: [controller.app.auth.GoogleAuthProvider.PROVIDER_ID],
      });
    }
  }
};
