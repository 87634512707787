import WasmController from "react-lib/frameworks/WasmController";
import UserDivisionHasUserList from "issara-sdk/apis/UserDivisionHasUserList_apps_PRX";
import PositionList from "issara-sdk/apis/PositionList_users";

export type State = {
  buttonLoadCheck?: any;
  // Seq
  PersonnelInformationSequence?: {
    sequenceIndex?: string | null;
    staffList?: any;
    staffTotal?: any;
    positionOption?: any;
  } | null;
};

export const StateInitial: State = {
  PersonnelInformationSequence: null,
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  user?: number;
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const Start: Handler = async (controller, params) => {
  const state = controller.getState();

  // Master data
  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [["role", {}]],
    },
  });

  const [positionRes, positionErr, positionNet] = await PositionList.list({
    apiToken: controller.apiToken,
    extra: {
      division: controller.data.division,
    },
  });

  let positionOption = positionRes?.items?.map((item: any) => {
    return { id: item?.id, value: item?.id, text: item?.name };
  });

  const [userDataRes, userDataErr, userDataNet] =
    await UserDivisionHasUserList.list({
      params: {
        limit: 40,
        offset: 0,
      },
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });

  controller.setState({
    PersonnelInformationSequence: {
      ...state.PersonnelInformationSequence,
      sequenceIndex: "Action",
      staffList: userDataRes?.items,
      staffTotal: userDataRes?.total,
      positionOption: positionOption,
    },
  });
};

export const Action: Handler = async (controller, params) => {
  if (params.action === "search") {
    const state = controller.getState();

    await controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "LOADING",
      },
      PersonnelInformationSequence: {
        ...state.PersonnelInformationSequence,
        staffList: null,
        staffTotal: 0,
      },
    });

    let page = params?.page || 1;
    let limit = params?.limit ? params?.limit : 40;
    let offset = (page - 1) * (params?.limit ? params?.limit : 40);

    const [userDataRes, userDataErr, userDataNet] =
      await UserDivisionHasUserList.list({
        params: {
          first_name__icontains: params?.searchText?.firstName,
          last_name__icontains: params?.searchText?.lastName,
          employee__code__icontains: params?.searchText?.username,
          employee__position: params?.searchText?.position,
          limit: limit,
          offset: offset,
        },
        apiToken: controller.apiToken,
        extra: {
          division: controller.data.division,
        },
      });

    controller.setState({
      PersonnelInformationSequence: {
        ...state.PersonnelInformationSequence,
        staffList: userDataRes?.items,
        staffTotal: userDataRes?.total,
      },
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "",
      },
    });
  }
};
