import WasmController from 'react-lib/frameworks/WasmController';

// APIs
import AnesthesiaTemplateList from "issara-sdk/apis/AnesthesiaTemplateList_apps_ANS"
import AnesthesiaTemplateDetail from "issara-sdk/apis/AnesthesiaTemplateDetail_apps_ANS"

export type State = 
  {
  }

export const StateInitial: State = 
  {
  }

export type Event = 
  { message: "apiToken", params: any }
  |  { message: "getAnesthesiaQueue", params: any }
  |  { message: "getAnesthesiaQueueZoneChoice", params: any }
  |  { message: "getAnesthesiaOrderWithId", params: any }
  |  { message: "getAnesthesiaConsultWithId", params: any }
  |  { message: "putAnesthesiaOrderAction", params: any }
  |  { message: "putAnesthesiaConsultAction", params: any }
  |  { message: "getInvestigationResult", params: any }
  |  { message: "getAnesthesiaData", params: any }
  |  { message: "getPreAnestheticAnesthesiaRecord", params: any }
  |  { message: "getPreAnestheticAnesthesiaLog", params: any }
  |  { message: "printFormAns", params: any }
  |  { message: "postPreAnestheticAnesthesiaRecord", params: any }
  |  { message: "putPreAnestheticAnesthesiaRecord", params: any }
  |  { message: "getAnesthesiaGraph", params: any }
  |  { message: "getAgentTemplate", params: any }
  |  { message: "getAgentByRecord", params: any }
  |  { message: "getAgentItem", params: any }
  |  { message: "putAgentItem", params: any }
  |  { message: "postAnesthesiaAgent", params: any }
  |  { message: "getAnesthesiaEventMedication", params: any }
  |  { message: "getEventMedicationByRecord", params: any }
  |  { message: "postAnesthesiaEventMedication", params: any }
  |  { message: "putAnesthesiaEventMedication", params: any }
  |  { message: "getMonitorTemplate", params: any }
  |  { message: "getMonitorByRecord", params: any }
  |  { message: "getMonitorItem", params: any }
  |  { message: "putMonitorItem", params: any }
  |  { message: "postAnesthesiaMonitor", params: any }
  |  { message: "getIntakeOutputTemplate", params: any }
  |  { message: "getIntakeByRecord", params: any }
  |  { message: "postAnesthesiaIntake", params: any }
  |  { message: "getIntakeItem", params: any }
  |  { message: "putIntakeItem", params: any }
  |  { message: "getOutputByRecord", params: any }
  |  { message: "postAnesthesiaOutput", params: any }
  |  { message: "getOutputItem", params: any }
  |  { message: "putOutputItem", params: any }
  |  { message: "getAnesthesiaBillWithId", params: any }
  |  { message: "getAnesthesiaTemplateList", params: any }
  |  { message: "getAnesthesiaTemplateWithId", params: any }
  |  { message: "getAnesthesiaBillLog", params: any }
  |  { message: "printAnesthesiaBill", params: any }
  |  { message: "postAnesthesiaBill", params: any }
  |  { message: "putAnesthesiaBill", params: any }

export type Data = 
  {
    division?: number,
  }

export const DataInitial = 
  {
  }

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any 

export const apiToken: Handler = async (controller, {}) => {
//undefined
//
//----> Name mismatch between manager and service
}

export const getAnesthesiaQueue: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, hn = _b.hn, name = _b.name, doctor = _b.doctor, type = _b.type, zone = _b.zone, statusEN = _b.statusEN, statusANS = _b.statusANS, start = _b.start, end = _b.end;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, ansService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {
//                                hn: hn,
//                                name: name,
//                                doctor: doctor,
//                                type: type,
//                                zone: zone
//                            };
//                            if (start) {
//                                params.start = start;
//                            }
//                            if (end) {
//                                params.end = end;
//                            }
//                            if (statusEN) {
//                                params.status_encounter = statusEN;
//                            }
//                            if (statusANS) {
//                                params.status_ans = statusANS;
//                            }
//                            ansService = new ANSService_1.default(this.apiToken);
//                            return [4 /*yield*/, utils_1.to(ansService.getAnesthesiaQueue({ params: params }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (params) {
//        return this.client
//            .get(apis_1.ANS.ANESTHESIA_QUEUE, params)
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ANS/anesthesia-queue/
}

export const getAnesthesiaQueueZoneChoice: Handler = async (controller, {}) => {
//function () { return __awaiter(_this, void 0, void 0, function () {
//            var ansService, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        ansService = new ANSService_1.default(this.apiToken);
//                        return [4 /*yield*/, utils_1.to(ansService.getAnesthesiaQueueZoneChoice())];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (params) {
//        return this.client
//            .get(apis_1.ANS.ANESTHESIA_QUEUE_ZONE_CHOICE, params)
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ANS/anesthesia-queue-zone-choice/
}

export const getAnesthesiaOrderWithId: Handler = async (controller, {}) => {
//function (ans_order_id) { return __awaiter(_this, void 0, void 0, function () {
//            var ansService, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        ansService = new ANSService_1.default(this.apiToken);
//                        return [4 /*yield*/, utils_1.to(ansService.getAnesthesiaOrderWithId(ans_order_id))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (ans_order_id) {
//        return this.client
//            .get(apis_1.ANS.ANESTHESIA_ORDER_ID({ ans_order_id: ans_order_id }))
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/ANS/anesthesia-order/' +
//((__t = (ans_order_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const getAnesthesiaConsultWithId: Handler = async (controller, {}) => {
//function (encounterId) { return __awaiter(_this, void 0, void 0, function () {
//            var ansService, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        ansService = new ANSService_1.default(this.apiToken);
//                        return [4 /*yield*/, utils_1.to(ansService.getAnesthesiaConsultWithId(encounterId))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (encounterId) {
//        return this.client
//            .get(apis_1.ANS.ANESTHESIA_CONSULT_EN_ID({ encounter_id: encounterId }))
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/ANS/anesthesia-consult/' +
//((__t = (encounter_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const putAnesthesiaOrderAction: Handler = async (controller, {}) => {
//function (orderId, action) { return __awaiter(_this, void 0, void 0, function () {
//            var data, ansService, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        data = { action: action };
//                        ansService = new ANSService_1.default(this.apiToken);
//                        return [4 /*yield*/, utils_1.to(ansService.putAnesthesiaOrderWithID(orderId, data))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//
//----> Name mismatch between manager and service
}

export const putAnesthesiaConsultAction: Handler = async (controller, {}) => {
//function (encounterId, action) { return __awaiter(_this, void 0, void 0, function () {
//            var data, ansService, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        data = { action: action };
//                        ansService = new ANSService_1.default(this.apiToken);
//                        return [4 /*yield*/, utils_1.to(ansService.putAnesthesiaConsultWithID(encounterId, data))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//
//----> Name mismatch between manager and service
}

export const getInvestigationResult: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, patientId = _b.patientId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, ansService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            if (patientId) {
//                                params.patient = patientId;
//                            }
//                            ansService = new ANSService_1.default(this.apiToken);
//                            return [4 /*yield*/, utils_1.to(ansService.getInvestigationResult(params))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (params) {
//        return this.client
//            .get(apis_1.ANS.INVESTIGATION_RESULT, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ANS/investigation-result/
}

export const getAnesthesiaData: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, encounterId = _b.encounterId, ansOrderId = _b.ansOrderId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, ansService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            if (encounterId) {
//                                params.encounter = encounterId;
//                            }
//                            if (ansOrderId) {
//                                params.ans_order_id = ansOrderId;
//                            }
//                            ansService = new ANSService_1.default(this.apiToken);
//                            return [4 /*yield*/, utils_1.to(ansService.getAnesthesiaData(params))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (params) {
//        return this.client
//            .get(apis_1.ANS.ANESTHESIA_DATA, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ANS/anesthesia-data/
}

export const getPreAnestheticAnesthesiaRecord: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, patientId = _b.patientId, formCode = _b.formCode, formVersion = _b.formVersion;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, ansService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            if (patientId) {
//                                params.patient_id = patientId;
//                            }
//                            if (formCode) {
//                                params.form_code = formCode;
//                            }
//                            if (formVersion) {
//                                params.form_version = formVersion;
//                            }
//                            ansService = new ANSService_1.default(this.apiToken);
//                            return [4 /*yield*/, utils_1.to(ansService.getPreAnestheticAnesthesiaRecord(params))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (params) {
//        return this.client
//            .get(apis_1.ANS.PRE_ANESTHETIC_ANESTHESIA_RECORD_BY_PATIENT, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ANS/pre-anesthetic-record-by-patient/
}

export const getPreAnestheticAnesthesiaLog: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, encounterId = _b.encounterId, formCode = _b.formCode, formVersion = _b.formVersion;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, ansService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            if (encounterId) {
//                                params.encounter = encounterId;
//                            }
//                            if (formCode) {
//                                params.form_code = formCode;
//                            }
//                            if (formVersion) {
//                                params.form_version = formVersion;
//                            }
//                            ansService = new ANSService_1.default(this.apiToken);
//                            return [4 /*yield*/, utils_1.to(ansService.getPreAnestheticAnesthesiaLog(params))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (params) {
//        return this.client
//            .get(apis_1.ANS.PRE_ANESTHETIC_ANESTHESIA_LOG, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ANS/pre-anesthetic-log/
}

export const printFormAns: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, formId = _b.formId, pdf = _b.pdf, image = _b.image;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, ansService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            if (formId) {
//                                params.form_id = formId;
//                            }
//                            if (pdf) {
//                                params.pdf = pdf;
//                            }
//                            if (image) {
//                                params.image = image;
//                            }
//                            ansService = new ANSService_1.default(this.apiToken);
//                            return [4 /*yield*/, utils_1.to(ansService.printFormAns(params))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (params) {
//        return this.client
//            .post(apis_1.ANS.FORM_ANS_PRINT, params)
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ANS/ans-form/print/
}

export const postPreAnestheticAnesthesiaRecord: Handler = async (controller, {}) => {
//function (data) { return __awaiter(_this, void 0, void 0, function () {
//            var ansService, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        ansService = new ANSService_1.default(this.apiToken);
//                        return [4 /*yield*/, utils_1.to(ansService.postPreAnestheticAnesthesiaRecord(data))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (params) {
//        return this.client
//            .post(apis_1.ANS.PRE_ANESTHETIC_ANESTHESIA_RECORD_LIST, params)
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ANS/pre-anesthetic-record/
}

export const putPreAnestheticAnesthesiaRecord: Handler = async (controller, {}) => {
//function (preAnsId, data) { return __awaiter(_this, void 0, void 0, function () {
//            var ansService, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        ansService = new ANSService_1.default(this.apiToken);
//                        return [4 /*yield*/, utils_1.to(ansService.putPreAnestheticAnesthesiaRecord(preAnsId, data))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (preAnsId, data) {
//        var url = apis_1.ANS.PRE_ANESTHETIC_ANESTHESIA_RECORD_DETAIL({
//            pre_ans_id: preAnsId
//        });
//        return this.client
//            .put(url, data)
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/ANS/pre-anesthetic-record/' +
//((__t = (pre_ans_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const getAnesthesiaGraph: Handler = async (controller, {}) => {
//function (ans_order_id) { return __awaiter(_this, void 0, void 0, function () {
//            var ansService, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        ansService = new ANSService_1.default(this.apiToken);
//                        return [4 /*yield*/, utils_1.to(ansService.getAnesthesiaGraph(ans_order_id))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (ans_order_id) {
//        return this.client
//            .get(apis_1.ANS.ANESTHESIA_GRAPH({ ans_order_id: ans_order_id }))
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/ANS/anesthesia-graph/' +
//((__t = (ans_order_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const getAgentTemplate: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, _c = _b.always_on_display, always_on_display = _c === void 0 ? false : _c;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, ansService, _d, error, response, network;
//                return __generator(this, function (_e) {
//                    switch (_e.label) {
//                        case 0:
//                            params = {};
//                            params.always_on_display = always_on_display;
//                            ansService = new ANSService_1.default(this.apiToken);
//                            return [4 /*yield*/, utils_1.to(ansService.getAgentTemplate(params))];
//                        case 1:
//                            _d = _e.sent(), error = _d[0], response = _d[1], network = _d[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (params) {
//        return this.client
//            .get(apis_1.ANS.ANESTHESIA_AGENT_TEMPLATE, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ANS/agent-template/
}

export const getAgentByRecord: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, anesthesia_record = _b.anesthesia_record;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, ansService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            params.anesthesia_record = anesthesia_record;
//                            ansService = new ANSService_1.default(this.apiToken);
//                            return [4 /*yield*/, utils_1.to(ansService.getAgentByRecord(params))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (params) {
//        return this.client
//            .get(apis_1.ANS.ANESTHESIA_AGENT_BY_RECORD, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ANS/anesthesia-agent/by-record/
}

export const getAgentItem: Handler = async (controller, {}) => {
//function (ans_agent_id) { return __awaiter(_this, void 0, void 0, function () {
//            var params, ansService, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        params = {};
//                        ansService = new ANSService_1.default(this.apiToken);
//                        return [4 /*yield*/, utils_1.to(ansService.getAgentItem(ans_agent_id))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (ans_agent_id) {
//        return this.client
//            .get(apis_1.ANS.ANESTHESIA_AGENT_DETAIL({ ans_agent_id: ans_agent_id }))
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/ANS/anesthesia-agent/' +
//((__t = (ans_agent_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const putAgentItem: Handler = async (controller, {}) => {
//function (ansAgentId, data) { return __awaiter(_this, void 0, void 0, function () {
//            var ansService, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        ansService = new ANSService_1.default(this.apiToken);
//                        return [4 /*yield*/, utils_1.to(ansService.putAgentItem(ansAgentId, data))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (ansAgentId, data) {
//        var url = apis_1.ANS.ANESTHESIA_AGENT_DETAIL({ ans_agent_id: ansAgentId });
//        return this.client
//            .put(url, data)
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/ANS/anesthesia-agent/' +
//((__t = (ans_agent_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const postAnesthesiaAgent: Handler = async (controller, {}) => {
//function (data) { return __awaiter(_this, void 0, void 0, function () {
//            var ansService, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        ansService = new ANSService_1.default(this.apiToken);
//                        return [4 /*yield*/, utils_1.to(ansService.postAnesthesiaAgent(data))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (params) {
//        return this.client
//            .post(apis_1.ANS.ANESTHESIA_AGENT_LIST, params)
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ANS/anesthesia-agent/
}

export const getAnesthesiaEventMedication: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, anesthesiaRecordId = _b.anesthesiaRecordId, type = _b.type;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, ansService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            if (anesthesiaRecordId) {
//                                params.anesthesia_record = anesthesiaRecordId;
//                            }
//                            if (type) {
//                                params.type = type;
//                            }
//                            ansService = new ANSService_1.default(this.apiToken);
//                            return [4 /*yield*/, utils_1.to(ansService.getAnesthesiaEventMedication(params))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (params) {
//        return this.client
//            .get(apis_1.ANS.ANESTHESIA_EVENT_MEDICATION_LIST, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ANS/anesthesia-event-medication/
}

export const getEventMedicationByRecord: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, anesthesia_record = _b.anesthesia_record;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, ansService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            params.anesthesia_record = anesthesia_record;
//                            ansService = new ANSService_1.default(this.apiToken);
//                            return [4 /*yield*/, utils_1.to(ansService.getEventMedicationByRecord(params))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (params) {
//        return this.client
//            .get(apis_1.ANS.ANESTHESIA_EVENT_MEDICATION_BY_RECORD, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ANS/anesthesia-event-medication/by-record/
}

export const postAnesthesiaEventMedication: Handler = async (controller, {}) => {
//function (data) { return __awaiter(_this, void 0, void 0, function () {
//            var ansService, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        ansService = new ANSService_1.default(this.apiToken);
//                        return [4 /*yield*/, utils_1.to(ansService.postAnesthesiaEventMedication(data))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (params) {
//        return this.client
//            .post(apis_1.ANS.ANESTHESIA_EVENT_MEDICATION_LIST, params)
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ANS/anesthesia-event-medication/
}

export const putAnesthesiaEventMedication: Handler = async (controller, {}) => {
//function (ansEventMedId, data) { return __awaiter(_this, void 0, void 0, function () {
//            var ansService, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        ansService = new ANSService_1.default(this.apiToken);
//                        return [4 /*yield*/, utils_1.to(ansService.putAnesthesiaEventMedication(ansEventMedId, data))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (ansEventMedId, data) {
//        var url = apis_1.ANS.ANESTHESIA_EVENT_MEDICATION_DETAIL({
//            ans_event_med_id: ansEventMedId
//        });
//        return this.client
//            .put(url, data)
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/ANS/anesthesia-event-medication/' +
//((__t = (ans_event_med_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const getMonitorTemplate: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, _c = _b.always_on_display, always_on_display = _c === void 0 ? false : _c;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, ansService, _d, error, response, network;
//                return __generator(this, function (_e) {
//                    switch (_e.label) {
//                        case 0:
//                            params = {};
//                            params.always_on_display = always_on_display;
//                            ansService = new ANSService_1.default(this.apiToken);
//                            return [4 /*yield*/, utils_1.to(ansService.getMonitorTemplate(params))];
//                        case 1:
//                            _d = _e.sent(), error = _d[0], response = _d[1], network = _d[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (params) {
//        return this.client
//            .get(apis_1.ANS.ANESTHESIA_MONITOR_TEMPLATE, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ANS/monitor-template/
}

export const getMonitorByRecord: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, anesthesia_record = _b.anesthesia_record;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, ansService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            params.anesthesia_record = anesthesia_record;
//                            ansService = new ANSService_1.default(this.apiToken);
//                            return [4 /*yield*/, utils_1.to(ansService.getMonitorByRecord(params))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (params) {
//        return this.client
//            .get(apis_1.ANS.ANESTHESIA_MONITOR_BY_RECORD, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ANS/anesthesia-monitor/by-record/
}

export const getMonitorItem: Handler = async (controller, {}) => {
//function (ans_monitor_id) { return __awaiter(_this, void 0, void 0, function () {
//            var params, ansService, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        params = {};
//                        ansService = new ANSService_1.default(this.apiToken);
//                        return [4 /*yield*/, utils_1.to(ansService.getMonitorItem(ans_monitor_id))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (ans_monitor_id) {
//        return this.client
//            .get(apis_1.ANS.ANESTHESIA_MONITOR_DETAIL({ ans_monitor_id: ans_monitor_id }))
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/ANS/anesthesia-monitor/' +
//((__t = (ans_monitor_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const putMonitorItem: Handler = async (controller, {}) => {
//function (ansMonitorId, data) { return __awaiter(_this, void 0, void 0, function () {
//            var ansService, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        ansService = new ANSService_1.default(this.apiToken);
//                        return [4 /*yield*/, utils_1.to(ansService.putMonitorItem(ansMonitorId, data))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (ansMonitorId, data) {
//        var url = apis_1.ANS.ANESTHESIA_MONITOR_DETAIL({ ans_monitor_id: ansMonitorId });
//        return this.client
//            .put(url, data)
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/ANS/anesthesia-monitor/' +
//((__t = (ans_monitor_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const postAnesthesiaMonitor: Handler = async (controller, {}) => {
//function (data) { return __awaiter(_this, void 0, void 0, function () {
//            var ansService, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        ansService = new ANSService_1.default(this.apiToken);
//                        return [4 /*yield*/, utils_1.to(ansService.postAnesthesiaMonitor(data))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (params) {
//        return this.client
//            .post(apis_1.ANS.ANESTHESIA_MONITOR_LIST, params)
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ANS/anesthesia-monitor/
}

export const getIntakeOutputTemplate: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, type = _b.type, _c = _b.always_on_display, always_on_display = _c === void 0 ? false : _c;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, ansService, _d, error, response, network;
//                return __generator(this, function (_e) {
//                    switch (_e.label) {
//                        case 0:
//                            params = {};
//                            params.type = type;
//                            params.always_on_display = always_on_display;
//                            ansService = new ANSService_1.default(this.apiToken);
//                            return [4 /*yield*/, utils_1.to(ansService.getIntakeOutputTemplate(params))];
//                        case 1:
//                            _d = _e.sent(), error = _d[0], response = _d[1], network = _d[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (params) {
//        return this.client
//            .get(apis_1.ANS.ANESTHESIA_INTAKE_OUTPUT_TEMPLATE, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ANS/intake-output-template/
}

export const getIntakeByRecord: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, anesthesia_record = _b.anesthesia_record;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, ansService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            params.anesthesia_record = anesthesia_record;
//                            ansService = new ANSService_1.default(this.apiToken);
//                            return [4 /*yield*/, utils_1.to(ansService.getIntakeByRecord(params))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (params) {
//        return this.client
//            .get(apis_1.ANS.ANESTHESIA_INTAKE_BY_RECORD, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ANS/anesthesia-intake/by-record/
}

export const postAnesthesiaIntake: Handler = async (controller, {}) => {
//function (data) { return __awaiter(_this, void 0, void 0, function () {
//            var ansService, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        ansService = new ANSService_1.default(this.apiToken);
//                        return [4 /*yield*/, utils_1.to(ansService.postAnesthesiaIntake(data))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (params) {
//        return this.client
//            .post(apis_1.ANS.ANESTHESIA_INTAKE_LIST, params)
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ANS/anesthesia-intake/
}

export const getIntakeItem: Handler = async (controller, {}) => {
//function (ans_intake_id) { return __awaiter(_this, void 0, void 0, function () {
//            var params, ansService, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        params = {};
//                        ansService = new ANSService_1.default(this.apiToken);
//                        return [4 /*yield*/, utils_1.to(ansService.getIntakeItem(ans_intake_id))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (ans_intake_id) {
//        return this.client
//            .get(apis_1.ANS.ANESTHESIA_INTAKE_DETAIL({ ans_intake_id: ans_intake_id }))
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/ANS/anesthesia-intake/' +
//((__t = (ans_intake_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const putIntakeItem: Handler = async (controller, {}) => {
//function (ansIntakeId, data) { return __awaiter(_this, void 0, void 0, function () {
//            var ansService, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        ansService = new ANSService_1.default(this.apiToken);
//                        return [4 /*yield*/, utils_1.to(ansService.putIntakeItem(ansIntakeId, data))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (ansIntakeId, data) {
//        var url = apis_1.ANS.ANESTHESIA_INTAKE_DETAIL({ ans_intake_id: ansIntakeId });
//        return this.client
//            .put(url, data)
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/ANS/anesthesia-intake/' +
//((__t = (ans_intake_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const getOutputByRecord: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, anesthesia_record = _b.anesthesia_record;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, ansService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            params.anesthesia_record = anesthesia_record;
//                            ansService = new ANSService_1.default(this.apiToken);
//                            return [4 /*yield*/, utils_1.to(ansService.getOutputByRecord(params))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (params) {
//        return this.client
//            .get(apis_1.ANS.ANESTHESIA_OUTPUT_BY_RECORD, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ANS/anesthesia-output/by-record/
}

export const postAnesthesiaOutput: Handler = async (controller, {}) => {
//function (data) { return __awaiter(_this, void 0, void 0, function () {
//            var ansService, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        ansService = new ANSService_1.default(this.apiToken);
//                        return [4 /*yield*/, utils_1.to(ansService.postAnesthesiaOutput(data))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (params) {
//        return this.client
//            .post(apis_1.ANS.ANESTHESIA_OUTPUT_LIST, params)
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ANS/anesthesia-output/
}

export const getOutputItem: Handler = async (controller, {}) => {
//function (ans_output_id) { return __awaiter(_this, void 0, void 0, function () {
//            var params, ansService, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        params = {};
//                        ansService = new ANSService_1.default(this.apiToken);
//                        return [4 /*yield*/, utils_1.to(ansService.getOutputItem(ans_output_id))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (ans_output_id) {
//        return this.client
//            .get(apis_1.ANS.ANESTHESIA_OUTPUT_DETAIL({ ans_output_id: ans_output_id }))
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/ANS/anesthesia-output/' +
//((__t = (ans_output_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const putOutputItem: Handler = async (controller, {}) => {
//function (ansOutputId, data) { return __awaiter(_this, void 0, void 0, function () {
//            var ansService, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        ansService = new ANSService_1.default(this.apiToken);
//                        return [4 /*yield*/, utils_1.to(ansService.putOutputItem(ansOutputId, data))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (ansOutputId, data) {
//        var url = apis_1.ANS.ANESTHESIA_OUTPUT_DETAIL({ ans_output_id: ansOutputId });
//        return this.client
//            .put(url, data)
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/ANS/anesthesia-output/' +
//((__t = (ans_output_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const getAnesthesiaBillWithId: Handler = async (controller, {}) => {
//function (ans_bill_id) { return __awaiter(_this, void 0, void 0, function () {
//            var ansService, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        ansService = new ANSService_1.default(this.apiToken);
//                        return [4 /*yield*/, utils_1.to(ansService.getAnesthesiaBillWithId(ans_bill_id))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (ans_bill_id) {
//        return this.client
//            .get(apis_1.ANS.ANESTHESIA_BILL_ID({ ans_bill_id: ans_bill_id }))
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/ANS/anesthesia-bill/' +
//((__t = (ans_bill_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const getAnesthesiaTemplateList: Handler = async (controller, { params }) => {
  const result = await AnesthesiaTemplateList.list({
    params: params,
    apiToken: controller.apiToken
  });
  return result;
//function () { return __awaiter(_this, void 0, void 0, function () {
//            var ansService, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        ansService = new ANSService_1.default(this.apiToken);
//                        return [4 /*yield*/, utils_1.to(ansService.getAnesthesiaTemplateList())];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (params) {
//        return this.client
//            .get(apis_1.ANS.ANESTHESIA_TEMPLATE, params)
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ANS/anesthesia-template/
}

export const getAnesthesiaTemplateWithId: Handler = async (controller, params) => {
  const result = await AnesthesiaTemplateDetail.retrieve({
    pk: params,
    apiToken: controller.apiToken
  });
  return result;
//function (template_id) { return __awaiter(_this, void 0, void 0, function () {
//            var ansService, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        ansService = new ANSService_1.default(this.apiToken);
//                        return [4 /*yield*/, utils_1.to(ansService.getAnesthesiaTemplateWithId(template_id))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (ans_template_id) {
//        return this.client
//            .get(apis_1.ANS.ANESTHESIA_TEMPLATE_ID({ ans_template_id: ans_template_id }))
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/ANS/anesthesia-template/' +
//((__t = (ans_template_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const getAnesthesiaBillLog: Handler = async (controller, {}) => {
//function (ansBillId) { return __awaiter(_this, void 0, void 0, function () {
//            var ansService, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        ansService = new ANSService_1.default(this.apiToken);
//                        return [4 /*yield*/, utils_1.to(ansService.getAnesthesiaBillLog(ansBillId))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (ansBillId) {
//        return this.client
//            .get(apis_1.ANS.ANESTHESIA_BILL_LOG({ ans_bill_id: ansBillId }))
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/ANS/anesthesia-bill-action-log/' +
//((__t = (ans_bill_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const printAnesthesiaBill: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, ansBillId = _b.ansBillId, pdf = _b.pdf;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, ansService, _c, error, response, network;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            params.pdf = pdf;
//                            ansService = new ANSService_1.default(this.apiToken);
//                            return [4 /*yield*/, utils_1.to(ansService.printAnesthesiaBill(ansBillId, params))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1], network = _c[2];
//                            return [2 /*return*/, [response, error, network]];
//                    }
//                });
//            });
//        }
//function (ansBillId, params) {
//        var url = apis_1.ANS.ANESTHESIA_BILL_PRINT_DETAIL({ ans_bill_id: ansBillId });
//        return this.client
//            .get(url, params)
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/ANS/anesthesia-bill/' +
//((__t = (ans_bill_id)) == null ? '' : __t) +
//'/print/';
//
//}
//return __p
//}
}

export const postAnesthesiaBill: Handler = async (controller, {}) => {
//function (data) { return __awaiter(_this, void 0, void 0, function () {
//            var result, ansService, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        result = [];
//                        ansService = new ANSService_1.default(this.apiToken);
//                        return [4 /*yield*/, utils_1.to(ansService.postAnesthesiaBill(data))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (params) {
//        return this.client
//            .post(apis_1.ANS.ANESTHESIA_BILL, params)
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/ANS/anesthesia-bill/
}

export const putAnesthesiaBill: Handler = async (controller, {}) => {
//function (billId, data) { return __awaiter(_this, void 0, void 0, function () {
//            var ansService, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        ansService = new ANSService_1.default(this.apiToken);
//                        return [4 /*yield*/, utils_1.to(ansService.putAnesthesiaBill(billId, data))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (billId, data) {
//        var url = apis_1.ANS.ANESTHESIA_BILL_DETAIL({ anesthesia_bill_id: billId });
//        return this.client
//            .put(url, data)
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/ANS/anesthesia-bill/' +
//((__t = (anesthesia_bill_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}
