import React, { FC, useEffect, useRef, useState } from "react";
import { Button, Form, TextArea } from "semantic-ui-react";
import CardLayout from "react-lib/apps/common/CardLayout";
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import RightLabel from "react-lib/apps/common/RightLabel";
import {
  ProgressionNoteDetail,
  ProgressionNoteList,
} from "../../apis/master/apps/DPI/ProgressionNote";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";

export interface CardProgressNoteViewDataType {
  titleCard: string;
  closeable: boolean;
  toggleable: boolean;
  hideCallback: (...args: any[]) => any;
  successCallback: (...args: any[]) => any;

  django: {
    user: {
      id: number | null;
    };
  };
  createModule: string; // "DPI" or "TEC"
  doctorId: number | null; // for TEC, DPI=null
  emrId: number | null;
  progressionNoteId: number | null;
  readOnly: boolean;
  apiToken?: string | null;
  isTitleReporter?: boolean;
  rows?: number;
}

const CardProgressNote: FC<CardProgressNoteViewDataType> = (props) => {
  const intl = useIntl();
  const isMounted = useRef(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<any>(null);
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    if (props.progressionNoteId) {
      fetchProgressionNote();
    }
  }, [props.progressionNoteId]);

  const fetchProgressionNote = async () => {
    setIsLoading(true);
    const [response, error, network] = await ProgressionNoteDetail.retrieve({
      apiToken: props.apiToken,
      pk: props.progressionNoteId,
    });
    if (isMounted.current) {
      if (error) {
        setErrors(error);
      } else {
        setData(response);
      }
    }
    setIsLoading(false);
  };

  const handleValue = (event: any, { name, value }: any) => {
    setData({ ...data, [name]: value });
  };

  const save = async () => {
    setIsLoading(true);
    const result = {
      ...data,
      emr: props.emrId,
      createModule: props.createModule, // "DPI" or "TEC"
      reported_by: props.doctorId, // for TEC, DPI=null
      edit_user: props.django.user.id,
    };
    let [response, error, network] = [null, null, null];
    if (props.progressionNoteId) {
      [response, error, network] = await ProgressionNoteDetail.update({
        apiToken: props.apiToken,
        pk: props.progressionNoteId,
        data: result,
      });
    } else {
      [response, error, network] = await ProgressionNoteList.create({
        apiToken: props.apiToken,
        data: result,
      });
    }
    if (isMounted.current) {
      if (error) {
        setErrors(error);
      } else {
        toast.success("บันทึกสำเร็จ");
        props.successCallback();
      }
    }
    setIsLoading(false);
  };

  return (
    <CardLayout
      titleText={
        props.readOnly
          ? props.titleCard + " [View]"
          : props.isTitleReporter
          ? `Progress Note [${data.reporter} @ ${data.last_edit}]`
          : props.progressionNoteId
          ? props.titleCard + " [Update]"
          : props.titleCard + " [New]"
      }
      headerColor="orange"
      loading={isLoading}
      closeable={props.closeable}
      toggleable={props.toggleable}
      onClose={props.hideCallback}
    >
      <ErrorMessage error={errors} />
      <Form>
        <Form.Group inline>
          <Form.Field width={1}>
            <RightLabel>S: </RightLabel>
          </Form.Field>
          <Form.Field width={15}>
            <TextArea
              placeholder="Subjective"
              readOnly={props.readOnly}
              name="s_txt"
              value={data.s_txt}
              rows={props.rows ?? 3}
              onChange={handleValue}
            />
          </Form.Field>
        </Form.Group>

        <Form.Group inline>
          <Form.Field width={1}>
            <RightLabel>O: </RightLabel>
          </Form.Field>
          <Form.Field width={15}>
            <TextArea
              placeholder="Objective"
              readOnly={props.readOnly}
              name="o_txt"
              value={data.o_txt}
              rows={props.rows ?? 3}
              onChange={handleValue}
            />
          </Form.Field>
        </Form.Group>

        <Form.Group inline>
          <Form.Field width={1}>
            <RightLabel>A: </RightLabel>
          </Form.Field>
          <Form.Field width={15}>
            <TextArea
              placeholder="Assessment"
              readOnly={props.readOnly}
              name="a_txt"
              value={data.a_txt}
              rows={props.rows ?? 3}
              onChange={handleValue}
            />
          </Form.Field>
        </Form.Group>

        <Form.Group inline>
          <Form.Field width={1}>
            <RightLabel>P: </RightLabel>
          </Form.Field>
          <Form.Field width={15}>
            <TextArea
              placeholder="Plan"
              readOnly={props.readOnly}
              name="p_txt"
              value={data.p_txt}
              rows={props.rows ?? 3}
              onChange={handleValue}
            />
          </Form.Field>
        </Form.Group>

        <Form.Group inline className="rightAlign">
          <Form.Field width={2}>
            <Button
              fluid
              color="green"
              type="button"
              style={{ display: props.readOnly ? "none" : "block" }}
              onClick={save}
            >
              Save
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>
    </CardLayout>
  );
};

export const CardProgressNoteInitialViewData: CardProgressNoteViewDataType = {
  titleCard: "Progress Note",
  closeable: true,
  toggleable: false,
  hideCallback: () => {},
  successCallback: () => {},

  django: {
    user: {
      id: null,
    },
  },
  createModule: "DPI", // "DPI" or "TEC"
  doctorId: null, // for TEC, DPI=null
  emrId: null,
  progressionNoteId: null,
  readOnly: false,
  apiToken: null,
  isTitleReporter: false,
  rows: 3,
};

CardProgressNote.defaultProps = CardProgressNoteInitialViewData;

export default CardProgressNote;
