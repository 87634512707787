import WasmController from 'react-lib/frameworks/WasmController';

export type State = 
  {
  }

export const StateInitial: State = 
  {
  }

export type Event = 
  { message: "getBloodGroupWithPatient", params: any }
  |  { message: "getBloodTransfusion", params: any }

export type Data = 
  {
    division?: number,
  }

export const DataInitial = 
  {
  }

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any 

export const getBloodGroupWithPatient: Handler = async (controller, {}) => {
//function (patientId) { return __awaiter(_this, void 0, void 0, function () {
//            var blbService, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        blbService = new BLBService_1.default();
//                        return [4 /*yield*/, utils_1.to(blbService.getBloodGroupWithPatient(patientId))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (patientId) {
//        return this.client
//            .get(apis_1.BLB.BLOOD_GROUP_WITH_PATIENT({ patient_id: patientId }))
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/BLB/blood-group/by-patient/' +
//((__t = (patient_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const getBloodTransfusion: Handler = async (controller, {}) => {
//function (params) { return __awaiter(_this, void 0, void 0, function () {
//            var blbService, _a, error, response, network;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        blbService = new BLBService_1.default();
//                        return [4 /*yield*/, utils_1.to(blbService.getBloodTransfusion({ params: params }))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1], network = _a[2];
//                        return [2 /*return*/, [response, error, network]];
//                }
//            });
//        }); }
//function (params) {
//        return this.client
//            .get(apis_1.BLB.BLOOD_TRANSFUSION_LIST, params)
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/BLB/blood-transfusion/
}
