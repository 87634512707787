export default class CardMultidisciplinaryController {
    constructor(props) {
      this.coreManager = props.coreManager;
    }

    getChoices = async ({ model, field, nameAsId, name }) => {
      const [data, error] = await this.coreManager.getChoices({
        model,
        field,
        nameAsId
      });
      return [data, error];
    };
  }
  