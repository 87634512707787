import React, {
  useCallback,
  useState,
  useRef,
  MutableRefObject,
  useMemo,
  useEffect,
  CSSProperties,
} from "react";
import { Button, FormField, Grid, Icon, Image, Input, Modal, Segment } from "semantic-ui-react";

import moment from "moment";
import { useIntl } from "react-intl";

// UX
import CardSettingUserEmployeeUX from "./CardSettingUserEmployeeUX";
import CardTransferList from "./CardTransferList";

// Common
import DateTextBox from "react-lib/apps/common/DateTextBox";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import ModChangePassword from "./ModChangePassword";
import SnackMessage from "../common/SnackMessage";
import WebcamProfile from "react-lib/apps/HISV3/common/WebcamProfile";
import ModInfo from "../common/ModInfo";

// Interface
import { StaffDetailExtraType } from "./StaffSettingInterface";

// Utils
import { beToAd, adToBe, formatDate } from "react-lib/utils/dateUtils";
import { getAge, getRequireKeyError, RequiredFieldType } from "./CardSettingEmployee";

// Types
type CardSettingUserEmployeeProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
  // data
  settingsUser?: Record<string, any> | null;
  profileEmployee?: Record<string, any> | null;
  profileUser?: {
    staff_extra?: StaffDetailExtraType;
    [key: string]: any;
  } | null;
  selectedRow?: Record<string, any> | null;
  changePassword?: Record<string, any>;
  languageUX?: "th" | "en";
  // options
  roleOptions?: Record<string, any>[];
  masterOptions?: Record<string, any[]>;
  masterData?: Record<string, any[]>;
  memberGroupOptions?: Record<string, any>[];
  // CommonInterface
  errorMessage?: Record<string, any>;
  buttonLoadCheck?: Record<string, any>;
  successMessage?: Record<string, any>;
  // style
  style?: CSSProperties;
  // config
  hideDoctorForm?: boolean;
  disableCreate?: boolean;
  showPassword2?: boolean;
  pw2CheckTeacherFlag?: boolean;
  hideEmployeeForm?: boolean;
  showMemberGroupPermission?: boolean;
  //
  // #isRaksthaiRequirment?: boolean;
};

// Const
const IMG = "/static/images/person.png";

const STATUS_OPTIONS = [
  { key: 1, value: 1, text: "Active" },
  { key: 2, value: 2, text: "Inactive" },
];

const GENDER_OPTIONS = [
  { key: 0, value: "N", text: "ไม่ระบุ" },
  { key: 1, value: "M", text: "Male" },
  { key: 2, value: "F", text: "Female" },
];

const CURRENT_DATE = formatDate(moment());

const CARD_SETTING_USER_EMPLOYEE = "CardSettingUserEmployee";

const CardSettingUserEmployee = (props: CardSettingUserEmployeeProps) => {
  const intl = useIntl();

  const [openWebCam, setOpenWebCam] = useState(false);
  const [modInfo, setModInfo] = useState<{
    msg: string;
    color: "yellow" | "green";
  } | null>(null);

  // Password
  const [openChangePassword, setOpenChangePassword] = useState<boolean>(false);
  const [openPass1, setOpenPass1] = useState<boolean>(false);
  const [openConPass1, setOpenConPass1] = useState<boolean>(false);
  const [openPass2, setOpenPass2] = useState<boolean>(false);
  const [openConPass2, setOpenConPass2] = useState<boolean>(false);

  // Profile
  const [uploadImage, setUploadImage] = useState(null);

  const [showRequiredField, setShowRequiredField] = useState<RequiredFieldType>({});
  const [open, setOpen] = useState(false);

  const fileRef = useRef() as MutableRefObject<HTMLInputElement>;

  // Effect
  useEffect(() => {
    props.onEvent({ message: "SettingStaff", params: {} });
    return () => {
      props.setProp(`errorMessage.${CARD_SETTING_USER_EMPLOYEE}`, {
        error: null,
      });
    };
  }, []);

  useEffect(() => {
    const error = props?.errorMessage?.[CARD_SETTING_USER_EMPLOYEE]?.error;
    if (error) {
      setShowRequiredField(error);
    }
  }, [props?.errorMessage?.[CARD_SETTING_USER_EMPLOYEE]?.error]);

  // Callback
  const age = useCallback(() => {
    const birthdate = props.profileEmployee?.birth_date;
    const birthdate_ad = beToAd(birthdate)?.startOf("day");
    const age = typeof birthdate_ad === "undefined" ? "" : getAge(birthdate_ad);

    return age;
  }, [props.profileEmployee?.birth_date]);

  const getPositionFlag = useCallback(
    (positionId: number) => {
      return props.masterData?.positionAll?.find((position) => position.id === positionId) || {};
    },
    [props.masterData?.positionAll]
  );

  // Memo
  const doctorTypeOptions = useMemo(() => {
    return props.masterOptions?.doctorType || [];
  }, [props.masterOptions?.doctorType]);

  const selectedRoles = useMemo(() => {
    return props.settingsUser?.roles || [];
  }, [props.settingsUser?.roles]);

  const positionFlag = useMemo(() => {
    return getPositionFlag(props.profileEmployee?.position);
  }, [props.profileEmployee, getPositionFlag]);

  // Handler
  const changeValue = (key: string) => async (e: any, v: any) => {
    if (
      [
        "first_name",
        "pre_name",
        "last_name",
        "pre_name_en",
        "first_name_en",
        "last_name_en",
        "doctor_type",
        "username",
        "password",
        "confirm_password",
        "citizen_passport",
        "phone_no",
        "email",
        "gender",
        "secondary_password",
        "confirm_secondary_password",
      ].includes(key)
    ) {
      await props.setProp(`profileUser.${key}`, v.value);
      props.setProp(`settingsUser.${key}`, v.value);
    } else if (["certificate_no", "specialties", "codeDoctor"].includes(key)) {
      props.setProp(`settingsUser.${key}`, v.value);
    } else if (["password1", "confirm_password1"].includes(key)) {
      props.setProp(`changePassword.${key}`, v.value);
    } else {
      if (key === "position") {
        const positionFlag = getPositionFlag(v.value);

        await props.setProp(
          `profileEmployee.isCreateDoctor`,
          !!positionFlag.teacher_flag
        );
      } else if (key === "isCreateDoctor") {
        const positionFlag = getPositionFlag(props.profileEmployee?.position);

        if (positionFlag.teacher_flag || props.settingsUser?.doctor) {
          return;
        }
      }

      props.setProp(`profileEmployee.${key}`, v.value);
    }
  };

  const changeDate = (key: string) => (date: any) => {
    props.setProp(`profileEmployee.${key}`, date);
  };

  const handleResetPassword = () => {
    setOpenChangePassword(true);

    props.onEvent({
      message: "SettingStaff",
      params: {
        action: "OPEN_CHANGE",
        id: props.profileUser?.user,
      },
    });
  };

  // User
  const readChooseFile = (event: any) => {
    if (event.target.files?.[0]) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        setUploadImage(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);

      props.setProp("settingsUser.image", event.target.files[0]);
    }
  };

  const handleClickUpload = () => {
    fileRef.current?.click?.();
  };

  const handleSaveValue = () => {
    const userId = props.settingsUser?.id;

    let requiredKey: RequiredFieldType = {
      code: "กรุณาระบุ รหัสพนักงาน",
      birth_date: "กรุณาระบุ วัน/เดือน/ปีเกิด", //ตามเงื่อนไข
      position: "กรุณาระบุ ตำแหน่งงาน", //ตามเงื่อนไข
      flag_start_date: "กรุณาระบุ วันที่เริ่มงาน",
      flag_end_date: "", //ตามเงื่อนไข
    };

    let error = getRequireKeyError(
      requiredKey,
      { ...props.profileEmployee, positionFlag },
      props.settingsUser,
      intl
    );

    requiredKey = {
      // ให้เหมือนกันทั้ง CU และ Raksthai
      // ...(!props?.isRaksthaiRequirment && {
      //   citizen_passport: "กรุณาระบุ เลขบัตรประจำตัวประชาชน", // ตามเงื่อนไข
      //   }),
      email: "กรุณาระบุ Email",
      username: "กรุณาระบุ ชื่อผู้ใช้",
      pre_name: "กรุณาระบุ คำนำหน้า",
      pre_name_en: "กรุณาระบุ Pre-name",
      first_name: "กรุณาระบุ ชื่อ",
      last_name: "กรุณาระบุ นามสกุล",
      first_name_en: "กรุณาระบุ Name",
      last_name_en: "กรุณาระบุ Lastname",
      gender: "กรุณาระบุเพศ",
      ...(!userId
        ? {
            password: "กรุณาระบุ รหัสผ่าน",
            confirm_password: "กรุณา ยืนยันรหัสผ่าน",
          }
        : {}),
    };

    error = {
      ...error,
      ...getRequireKeyError(requiredKey, props.profileUser, null, intl),
    };

    // check ว่า password ตรงกันหรือไม่
    if (props.selectedRow === null && !error.confirm_password && !error.password) {
      if (props.profileUser?.password !== props.profileUser?.confirm_password) {
        error.confirm_password = intl.formatMessage({ id: "รหัสผ่านยืนยันไม่ตรงกับรหัสผ่าน กรุณากรอกใหม่" });
      }
    }

    if (
      props.selectedRow === null &&
      (!!props.profileUser?.confirm_secondary_password || !!props.profileUser?.secondary_password)
    ) {
      if (props.profileUser.secondary_password !== props.profileUser.confirm_secondary_password) {
        error.confirm_secondary_password =
          "รหัสผ่านยืนยันชุดที่ 2 ไม่ตรงกับรหัสผ่านชุดที่ 2 กรุณากรอกใหม่";
      }
    }

    // ไม่ต้องแจ้งเตือนข้อมูลเกี่ยวกับแพทย์
    if (props.hideDoctorForm || props.hideEmployeeForm) {
      delete error.certificate_no;
      delete error.specialties;
      delete error.codeDoctor;
    }

    setShowRequiredField(error);

    if (!Object.keys(error).length) {
      props.onEvent({
        message: "SettingStaff",
        params: {
          action: "SAVE_EMPLOYEE",
          card: CARD_SETTING_USER_EMPLOYEE,
          buttonLoadKey: `${CARD_SETTING_USER_EMPLOYEE}_SAVE`,
          id: props.settingsUser?.id,
          nameOnly: false,
          createDoctor: !props.hideDoctorForm,
          showMemberGroupPermission: props.showMemberGroupPermission,
          onSuccess: () => setModInfo({ msg: "บันทึกข้อมูลสำเร็จ", color: "green" }),
        },
      });
    }
  };

  const handleClose = () => {
    setOpenChangePassword(false);
  };

  const handleSaveNewImage = async (imgSrc: any) => {
    setUploadImage(imgSrc);

    props.setProp("settingsUser.image", imgSrc);
  };

  const handleSelectedRole = (selected: (string | number)[]) => {
    props.setProp("settingsUser.roles", selected);
  };

  const handleSelectPermission = (selected: any[]) => {
    props.setProp("profileUser.staff_extra.member_group_permission", selected);
  };

  const handleCloseModInfo = () => {
    setModInfo(null);
  };

  console.log("CardSettingUserEmployeeUX", props);

  return (
    <div style={props.style}>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_SETTING_USER_EMPLOYEE}`, null);
        }}
        error={props?.errorMessage?.[CARD_SETTING_USER_EMPLOYEE]?.error}
        success={null}
        languageUX={props.languageUX}
      />

      <CardSettingUserEmployeeUX
        // employee
        flagEndDate={props.profileEmployee?.flag_end_date}
        flagStartDate={props.profileEmployee?.flag_start_date}
        code={props.profileEmployee?.code}
        inactiveReason={props.profileEmployee?.inactive_reason}
        employmentType={props.profileEmployee?.employment_type}
        position={props.profileEmployee?.position}
        isCreateDoctor={props.profileEmployee?.isCreateDoctor}
        status={props.profileEmployee?.status}
        //profile  user
        preNameTh={props.profileUser?.pre_name}
        firstNameTh={props.profileUser?.first_name}
        lastNameTh={props.profileUser?.last_name}
        preNameEn={props.profileUser?.pre_name_en}
        firstNameEn={props.profileUser?.first_name_en}
        lastNameEn={props.profileUser?.last_name_en}
        gender={props.profileUser?.gender?.toString() || ""}
        doctorType={props.profileUser?.doctor_type}
        citizenPassport={props.profileUser?.citizen_passport}
        email={props.profileUser?.email}
        phoneNo={props.profileUser?.phone_no}
        username={props.profileUser?.username}
        password={props.profileUser?.password}
        positionFlag={positionFlag}
        confirmPassword={props.profileUser?.confirm_password}
        secondaryPassword={props.profileUser?.secondary_password}
        confirmSecondaryPassword={props.profileUser?.confirm_secondary_password}
        // user
        specialty={props.settingsUser?.specialty}
        specialties={props.settingsUser?.specialties}
        certificateNo={props.settingsUser?.certificate_no}
        codeDoctor={props.settingsUser?.codeDoctor}
        //require
        showRequiredField={showRequiredField}
        isRequireCID={false}
        isRequireEmail={true}
        // options
        prenameThOptions={props.masterOptions?.prenameTh || []}
        prenameEnOptions={props.masterOptions?.prenameEn || []}
        positionOptions={props.masterOptions?.positionAll || []}
        specialtyOptions={props.masterOptions?.specialty}
        employmentTypeOptions={props.masterOptions?.employmentType}
        doctorTypeOptions={doctorTypeOptions}
        statusOptions={STATUS_OPTIONS}
        genderOptions={GENDER_OPTIONS}
        // config
        hideDoctorForm={props.hideDoctorForm}
        showPassword2={props.showPassword2 && props.settingsUser?.id}
        hideEmployeeForm={props.hideEmployeeForm}
        allowedResetPassword={!!props.settingsUser?.id}
        showMemberGroupPermission={props.showMemberGroupPermission}
        // Callback
        changeValue={changeValue}
        changeDate={changeDate}
        resetPassword={handleResetPassword}
        // Element
        eyePass1={
          <Icon
            name={openPass1 ? "eye" : "eye slash"}
            link
            onClick={() => {
              setOpenPass1(!openPass1);
            }}
          />
        }
        typePassword1={openPass1 ? "" : "password"}
        eyePass2={
          <Icon
            name={openPass2 ? "eye" : "eye slash"}
            link
            onClick={() => {
              setOpenPass2(!openPass2);
            }}
          />
        }
        typePassword2={openPass2 ? "" : "password"}
        eyeConfirm1={
          <Icon
            name={openConPass1 ? "eye" : "eye slash"}
            link
            onClick={() => {
              setOpenConPass1(!openConPass1);
            }}
          />
        }
        typeConfirm1={openConPass1 ? "" : "password"}
        eyeConfirm2={
          <Icon
            name={openConPass2 ? "eye" : "eye slash"}
            link
            onClick={() => {
              setOpenConPass2(!openConPass2);
            }}
          />
        }
        typeConfirm2={openConPass2 ? "" : "password"}
        age={
          <Input
            value={age()}
            readOnly={true}
            fluid={true}
            style={{ height: "fit-content", width: "100%" }}
          />
        }
        birthDate={
          <FormField className={showRequiredField.birth_date ? "error" : ""}>
            <DateTextBox
              value={props.profileEmployee?.birth_date}
              inputFluid={true}
              style={{ width: "100%" }}
              inputStyle={{ width: "100%" }}
              maxDate={CURRENT_DATE}
              onChange={changeDate("birth_date")}
            ></DateTextBox>
            {showRequiredField.birth_date && (
              <div
                style={{
                  position: "absolute",
                  bottom: -17,
                  color: "red",
                  fontSize: "0.8rem",
                }}
              >
                {showRequiredField.birth_date}
              </div>
            )}
          </FormField>
        }
        profileImage={
          <div className="Img-container">
            <Image
              src={uploadImage || props.settingsUser?.image || IMG}
              size="small"
              className="image"
              onError={(e: any) => {
                e.target.onerror = null;
                e.target.src = IMG;
              }}
              style={{
                resizeMode: "contain",
                width: "175px",
                height: "200px",
                border: "solid black 1px",
                marginRight: "10px",
                objectFit: "contain",
              }}
            />

            <div style={{ marginTop: "5px" }}>
              <div style={{ display: "flex", marginLeft: "0.65rem" }}>
                <input
                  type="file"
                  id="file"
                  ref={fileRef}
                  style={{ display: "none" }}
                  onChange={readChooseFile}
                  accept="image/*"
                />
                <Button onClick={handleClickUpload} color="blue">
                  Upload
                </Button>
                <Button color="orange" onClick={() => setOpenWebCam(true)}>
                  <Icon className="camera" size="large" style={{ margin: 0, marginTop: "-7px" }} />
                </Button>
              </div>
            </div>
          </div>
        }
        selectRole={
          <CardTransferList
            list={props.roleOptions || []}
            textKey="name"
            valueKey="id"
            selected={selectedRoles}
            onSelected={handleSelectedRole}
            languageUX={props.languageUX}
          />
        }
        selectMainMemberGroup={
          <CardTransferList
            list={props.memberGroupOptions || []}
            textKey="text"
            valueKey="value"
            selected={props.profileUser?.staff_extra?.member_group_permission || []}
            onSelected={handleSelectPermission}
            languageUX={props.languageUX}
          />
        }
        buttonSave={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleSaveValue}
            // data
            paramKey={`${CARD_SETTING_USER_EMPLOYEE}_SAVE`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_SETTING_USER_EMPLOYEE}_SAVE`]}
            disabled={props.disableCreate}
            // config
            color={props.settingsUser?.id ? "yellow" : "green"}
            name="SAVE"
            size="small"
            style={{ width: "100%" }}
            title={
              props.settingsUser?.id
                ? intl.formatMessage({
                    id: "key805",
                    defaultMessage: "บันทึกการแก้ไข",
                  })
                : intl.formatMessage({ id: "key180", defaultMessage: "บันทึก" })
            }
          />
        }
        languageUX={props.languageUX}
      />

      <Modal open={openChangePassword} size="small" onClose={handleClose}>
        <ModChangePassword
          onEvent={props.onEvent}
          setProp={props.setProp}
          // data
          user={props.settingsUser || {}}
          changePassword={props.changePassword}
          // CommonInterface
          successMessage={props.successMessage}
          errorMessage={props.errorMessage}
          buttonLoadCheck={props.buttonLoadCheck}
          // config
          showPassword2={props.showPassword2 && positionFlag.teacher_flag}
          pw2CheckTeacherFlag={props.pw2CheckTeacherFlag}
          // callback
          onClose={handleClose}
          languageUX={props.languageUX}
        />
      </Modal>

      <WebcamProfile
        open={openWebCam}
        onClose={() => setOpenWebCam(false)}
        setNewImage={handleSaveNewImage}
      />

      <Modal open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(true)} size="mini">
        <Segment inverted className="red" style={{ margin: 0 }}>
          แจ้งเตือน
        </Segment>
        <Modal.Content>
          <Grid>
            <Grid.Column textAlign="center">
              <p>{intl.formatMessage({ id: "กรุณา กรอกข้อมูลผู้ใช้งานระบบ" })}</p>
              <Button basic color="red" size="small" onClick={() => setOpen(false)}>
                ตกลง
              </Button>
            </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>

      <ModInfo
        open={!!modInfo}
        titleColor={modInfo?.color}
        titleName={modInfo?.msg}
        onApprove={handleCloseModInfo}
        onClose={handleCloseModInfo}
      />
    </div>
  );
};

CardSettingUserEmployee.displayName = "CardSettingUserEmployee";

export default React.memo(CardSettingUserEmployee);
