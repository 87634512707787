import React, { forwardRef, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import { Button, Form, Grid, Menu, Tab } from "semantic-ui-react";

import CardLayout from "../common/CardLayout";
import ErrorMessage from "../common/ErrorMessage";
import PureReactTable from "../../component-js/common/PureReactTable";
import { useIntl } from "react-intl";

const style = {
  toRight: {
    marginLeft: "auto",
  },
  gridCustom: { height: 500 },
  marginTop: { marginTop: "-10px" },
};

const DataGrid = forwardRef((props: any, ref) => {
  const columns = [
    { Header: "รหัส", accessor: "code", width: 150 },
    { Header: "รายการ", accessor: "name", width: 490 },
  ];

  return (
    <PureReactTable
      data={props.dataList}
      style={style.gridCustom}
      columns={columns}
      showPagination={false}
      pageSize={10}
      manual
    />
  );
});

const CardSearchAnesthesiaTemplate = (props: any) => {
  const intl = useIntl();
  const isMounted = useRef(true);
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedTemplateName, setSelectedTemplateName] = useState(null);
  const [templateList, setTemplateList] = useState([]);
  const [drugList, setDrugList] = useState([]);
  const [operativeList, setOperativeList] = useState([]);
  const [supplyList, setSupplyList] = useState([]);

  useEffect(() => {
    const getAnesthesiaTemplateList = async () => {
      setIsLoading(true);
      const [data, error] = await props.controller.getAnesthesiaTemplateList();
      if (isMounted.current) {
        if (error) {
          setErrors(error);
        } else if (data.total > 0) {
          setTemplateList(data.items);
          handleTemplateClick(data.items[0]);
        }
      }
      setIsLoading(false);
    };

    getAnesthesiaTemplateList();
  }, []);

  const handleTemplateClick = (template:any) => {
    setSelectedTemplate(template);
    setSelectedTemplateName(template.name);
    setDrugList(template.drugs);
    setOperativeList(template.or_treatments);
    setSupplyList(template.supplys);
  };

  const handleSelected = () => {
    if (selectedTemplate) {
      props.onSelectedTemplate(selectedTemplate);
    } else {
      props.hideCallback();
    }
  };

  return (
    <CardLayout
      titleText={props.titleCard}
      headerColor="pink"
      loading={isLoading}
      closeable={props.closeable}
      toggleable={props.toggleable}
      onClose={props.hideCallback}
    >
      <Form error={errors ? true : false}>
        <ErrorMessage error={errors} />
      </Form>

      <Grid>
        <Grid.Column width={4}>
          <h4>{intl.formatMessage({ id: "เลือก template" })}</h4>
          <Menu fluid vertical tabular>
            {templateList.map((template:any) => {
              return (
                <Menu.Item
                  key={template.name}
                  name={template.name}
                  active={selectedTemplateName === template.name}
                  onClick={(e) => {
                    handleTemplateClick(template);
                  }}
                />
              );
            })}
          </Menu>
        </Grid.Column>

        <Grid.Column stretched width={12} style={style.marginTop}>
          <Tab
            panes={[
              {
                menuItem: (
                  <Menu.Item key="supply" style={style.toRight}>
                    เวชภัณฑ์
                  </Menu.Item>
                ),
                render: () => (
                  <Tab.Pane>
                    <DataGrid dataList={supplyList} />
                  </Tab.Pane>
                ),
              },
              {
                menuItem: "ยา",
                render: () => (
                  <Tab.Pane>
                    <DataGrid dataList={drugList} />
                  </Tab.Pane>
                ),
              },
              {
                menuItem: "หัตถการ",
                render: () => (
                  <Tab.Pane>
                    <DataGrid dataList={operativeList} />
                  </Tab.Pane>
                ),
              },
            ]}
          />
        </Grid.Column>
      </Grid>

      <Button color="orange" type="button" onClick={handleSelected}>
        Select
      </Button>
    </CardLayout>
  );
};

CardSearchAnesthesiaTemplate.defaultProps = {
  titleCard: "Anesthesia Template",
  controller: null,

  hideCallback: () => {},
  onSelectedTemplate: () => {},

  closeable: true,
  toggleable: false,
};

CardSearchAnesthesiaTemplate.propTypes = {
  titleCard: PropTypes.string,
  controller: PropTypes.object,

  hideCallback: PropTypes.func,
  onSelectedTemplate: PropTypes.func,

  closeable: PropTypes.bool,
  toggleable: PropTypes.bool,
};

export default React.memo(CardSearchAnesthesiaTemplate);
