import React from 'react';
import { useIntl } from "react-intl";
import { Table, Checkbox, Segment } from 'semantic-ui-react'

const styles = {
  gridHeader: {
    fontWeight: 'bold',
    backgroundColor: '#E0E0E0',
    color: '#4F4F4F'
  },
  subGridHeader: {
    fontWeight: 'bold',
  },
  table: {
    maxHeight: 470,
    overflow: 'auto',
    display: 'block',
    width: '100%'
  },
  tableBody: {
    backgroundColor: '#F3F3F3'
  }
}

const HAS_PROB = {
  Y: 'มีปัญหา',
  N: 'ไม่มีปัญหา',
}

const CardDischargePlanningView = (props) => {
  const intl = useIntl();

  const { dischargePlanning } = props;

  const planning = dischargePlanning.planning ? dischargePlanning.planning : {}
  const questions = planning.question ? planning.question : [];

  if(Object.keys(planning).length === 0){
    return (
      <Segment>
        No Data.
      </Segment>
    )
  }

  return (
    <Segment>
      <div style={{ maxHeight: 480, overflowY: 'auto', overflowX: 'hidden' }}>
        { questions.map((items, index) => {
          return (
            <Table key={index} columns={2} celled>
              <Table.Header style={styles.gridHeader}>
                <Table.Row>
                  <Table.Cell>
                    {planning[items].no + '. ' + planning[items].name}
                  </Table.Cell>
                  <Table.Cell>
                    {HAS_PROB[planning[items].has_problem]}
                  </Table.Cell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {planning[items].items.map((subItem, index) => {
                  if (!subItem.checked) {
                    return null;
                  }
                  return (
                    <Table.Row key={index}>
                      <Table.Cell>
                        <Checkbox label={subItem.name} checked={subItem.checked} />
                      </Table.Cell>
                      <Table.Cell>
                        {subItem.answer}
                      </Table.Cell>
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table>
          )
        })}
      </div>
      <br />
      <div>&ensp;<text style={{ fontWeight: 'bold', fontSize: 16 }}>{intl.formatMessage({ id: "ผู้บันทึกแบบประเมิน" })}</text>&emsp; {planning.edit_user}&emsp;{planning.edited}</div>
    </Segment>
  )
}

CardDischargePlanningView.defaultProps = {
  hideHeaderIcon: false,
  dischargePlanning: {},
  children: [],
};

export default CardDischargePlanningView;
