import React from 'react'
import {
   Checkbox,
   Dropdown,
   Input,
} from 'semantic-ui-react'



import styles from './POPhysicalexaminationOrthoticFootOrthotic2TOP.module.css'

export interface POPhysicalexaminationOrthoticFootOrthotic2TOPViewDataType {
    PoPhysicalOrthoticFootOrthotic2Top3: boolean,
    PoPhysicalOrthoticFootOrthotic2Top3Disabled: boolean,
    PoPhysicalOrthoticFootOrthotic2Top5: boolean,
    PoPhysicalOrthoticFootOrthotic2Top5Disabled: boolean,
    PoPhysicalOrthoticFootOrthotic2Top7: boolean,
    PoPhysicalOrthoticFootOrthotic2Top7Disabled: boolean,
    PoPhysicalOrthoticFootOrthotic2Top9: boolean,
    PoPhysicalOrthoticFootOrthotic2Top9Disabled: boolean,
    PoPhysicalOrthoticFootOrthotic2Top11: string,
    PoPhysicalOrthoticFootOrthotic2Top11Disabled: boolean,
    PoPhysicalOrthoticFootOrthotic2Top15: string,
    PoPhysicalOrthoticFootOrthotic2Top15Disabled: boolean,
    PoPhysicalOrthoticFootOrthotic2Top16: boolean,
    PoPhysicalOrthoticFootOrthotic2Top16Disabled: boolean,
    PoPhysicalOrthoticFootOrthotic2Top18: boolean,
    PoPhysicalOrthoticFootOrthotic2Top18Disabled: boolean,
    PoPhysicalOrthoticFootOrthotic2Top19: string,
    PoPhysicalOrthoticFootOrthotic2Top19Disabled: boolean,
    PoPhysicalOrthoticFootOrthotic2Top21: string,
    PoPhysicalOrthoticFootOrthotic2Top21Disabled: boolean,
    PoPhysicalOrthoticFootOrthotic2Top23: boolean,
    PoPhysicalOrthoticFootOrthotic2Top23Disabled: boolean,
    PoPhysicalOrthoticFootOrthotic2Top25: string,
    PoPhysicalOrthoticFootOrthotic2Top25Disabled: boolean,
    PoPhysicalOrthoticFootOrthotic2Top26: boolean,
    PoPhysicalOrthoticFootOrthotic2Top26Disabled: boolean,
    PoPhysicalOrthoticFootOrthotic2Top27: string,
    PoPhysicalOrthoticFootOrthotic2Top27Disabled: boolean,
    PoPhysicalOrthoticFootOrthotic2Top28: boolean,
    PoPhysicalOrthoticFootOrthotic2Top28Disabled: boolean,
    PoPhysicalOrthoticFootOrthotic2Top30: string,
    PoPhysicalOrthoticFootOrthotic2Top30Disabled: boolean,
    PoPhysicalOrthoticFootOrthotic2Top31: boolean,
    PoPhysicalOrthoticFootOrthotic2Top31Disabled: boolean,
    PoPhysicalOrthoticFootOrthotic2Top34: string,
    PoPhysicalOrthoticFootOrthotic2Top34Disabled: boolean,
    PoPhysicalOrthoticFootOrthotic2Top35: boolean,
    PoPhysicalOrthoticFootOrthotic2Top35Disabled: boolean,
    PoPhysicalOrthoticFootOrthotic2Top36: string,
    PoPhysicalOrthoticFootOrthotic2Top36Disabled: boolean,
    PoPhysicalOrthoticFootOrthotic2Top37: boolean,
    PoPhysicalOrthoticFootOrthotic2Top37Disabled: boolean,
    PoPhysicalOrthoticFootOrthotic2Top39: string,
    PoPhysicalOrthoticFootOrthotic2Top39Disabled: boolean,
    PoPhysicalOrthoticFootOrthotic2Top40: boolean,
    PoPhysicalOrthoticFootOrthotic2Top40Disabled: boolean,
    PoPhysicalOrthoticFootOrthotic2Top43: string,
    PoPhysicalOrthoticFootOrthotic2Top43Disabled: boolean,
    Ct3GeneralAppearanceDysfunction: string,
    Ct3GeneralAppearanceDysfunctionDisabled: boolean,
    Ct3GeneralAppearanceDysfunctionOptions: any,
    PoPhysicalOrthoticFootOrthotic2Top48: string,
    PoPhysicalOrthoticFootOrthotic2Top48Disabled: boolean,
    Ct6GeneralAppearance: string,
    Ct6GeneralAppearanceDisabled: boolean,
    Ct6GeneralAppearanceOptions: any,
    Ct6Consciousness: string,
    Ct6ConsciousnessDisabled: boolean,
    Ct6ConsciousnessOptions: any,
    Ct9Communication: string,
    Ct9CommunicationDisabled: boolean,
    Ct9CommunicationOptions: any,
    Ct9CoOperation: string,
    Ct9CoOperationDisabled: boolean,
    Ct9CoOperationOptions: any,
    PoPhysicalOrthoticFootOrthotic2Top64: string,
    PoPhysicalOrthoticFootOrthotic2Top64Disabled: boolean,
    PoPhysicalOrthoticFootOrthotic2Top65: string,
    PoPhysicalOrthoticFootOrthotic2Top65Disabled: boolean,
    PoPhysicalOrthoticFootOrthotic2Top66: string,
    PoPhysicalOrthoticFootOrthotic2Top66Disabled: boolean,
    PoPhysicalOrthoticFootOrthotic2Top67: string,
    PoPhysicalOrthoticFootOrthotic2Top67Disabled: boolean,
    PoPhysicalOrthoticFootOrthotic2Top68: boolean,
    PoPhysicalOrthoticFootOrthotic2Top68Disabled: boolean,
    PoPhysicalOrthoticFootOrthotic2Top70: boolean,
    PoPhysicalOrthoticFootOrthotic2Top70Disabled: boolean,
    PoPhysicalOrthoticFootOrthotic2Top72: boolean,
    PoPhysicalOrthoticFootOrthotic2Top72Disabled: boolean,
    PoPhysicalOrthoticFootOrthotic2Top74: boolean,
    PoPhysicalOrthoticFootOrthotic2Top74Disabled: boolean,
    PoPhysicalOrthoticFootOrthotic2Top76: boolean,
    PoPhysicalOrthoticFootOrthotic2Top76Disabled: boolean,
}

export const POPhysicalexaminationOrthoticFootOrthotic2TOPInitialViewData: POPhysicalexaminationOrthoticFootOrthotic2TOPViewDataType = {
    PoPhysicalOrthoticFootOrthotic2Top3: false,
    PoPhysicalOrthoticFootOrthotic2Top3Disabled: false,
    PoPhysicalOrthoticFootOrthotic2Top5: false,
    PoPhysicalOrthoticFootOrthotic2Top5Disabled: false,
    PoPhysicalOrthoticFootOrthotic2Top7: false,
    PoPhysicalOrthoticFootOrthotic2Top7Disabled: false,
    PoPhysicalOrthoticFootOrthotic2Top9: false,
    PoPhysicalOrthoticFootOrthotic2Top9Disabled: false,
    PoPhysicalOrthoticFootOrthotic2Top11: "",
    PoPhysicalOrthoticFootOrthotic2Top11Disabled: false,
    PoPhysicalOrthoticFootOrthotic2Top15: "",
    PoPhysicalOrthoticFootOrthotic2Top15Disabled: false,
    PoPhysicalOrthoticFootOrthotic2Top16: false,
    PoPhysicalOrthoticFootOrthotic2Top16Disabled: false,
    PoPhysicalOrthoticFootOrthotic2Top18: false,
    PoPhysicalOrthoticFootOrthotic2Top18Disabled: false,
    PoPhysicalOrthoticFootOrthotic2Top19: "",
    PoPhysicalOrthoticFootOrthotic2Top19Disabled: false,
    PoPhysicalOrthoticFootOrthotic2Top21: "",
    PoPhysicalOrthoticFootOrthotic2Top21Disabled: false,
    PoPhysicalOrthoticFootOrthotic2Top23: false,
    PoPhysicalOrthoticFootOrthotic2Top23Disabled: false,
    PoPhysicalOrthoticFootOrthotic2Top25: "",
    PoPhysicalOrthoticFootOrthotic2Top25Disabled: false,
    PoPhysicalOrthoticFootOrthotic2Top26: false,
    PoPhysicalOrthoticFootOrthotic2Top26Disabled: false,
    PoPhysicalOrthoticFootOrthotic2Top27: "",
    PoPhysicalOrthoticFootOrthotic2Top27Disabled: false,
    PoPhysicalOrthoticFootOrthotic2Top28: false,
    PoPhysicalOrthoticFootOrthotic2Top28Disabled: false,
    PoPhysicalOrthoticFootOrthotic2Top30: "",
    PoPhysicalOrthoticFootOrthotic2Top30Disabled: false,
    PoPhysicalOrthoticFootOrthotic2Top31: false,
    PoPhysicalOrthoticFootOrthotic2Top31Disabled: false,
    PoPhysicalOrthoticFootOrthotic2Top34: "",
    PoPhysicalOrthoticFootOrthotic2Top34Disabled: false,
    PoPhysicalOrthoticFootOrthotic2Top35: false,
    PoPhysicalOrthoticFootOrthotic2Top35Disabled: false,
    PoPhysicalOrthoticFootOrthotic2Top36: "",
    PoPhysicalOrthoticFootOrthotic2Top36Disabled: false,
    PoPhysicalOrthoticFootOrthotic2Top37: false,
    PoPhysicalOrthoticFootOrthotic2Top37Disabled: false,
    PoPhysicalOrthoticFootOrthotic2Top39: "",
    PoPhysicalOrthoticFootOrthotic2Top39Disabled: false,
    PoPhysicalOrthoticFootOrthotic2Top40: false,
    PoPhysicalOrthoticFootOrthotic2Top40Disabled: false,
    PoPhysicalOrthoticFootOrthotic2Top43: "",
    PoPhysicalOrthoticFootOrthotic2Top43Disabled: false,
    Ct3GeneralAppearanceDysfunction: "",
    Ct3GeneralAppearanceDysfunctionDisabled: false,
    Ct3GeneralAppearanceDysfunctionOptions: [{key: 0, value: "A", text: "A"}],
    PoPhysicalOrthoticFootOrthotic2Top48: "",
    PoPhysicalOrthoticFootOrthotic2Top48Disabled: false,
    Ct6GeneralAppearance: "",
    Ct6GeneralAppearanceDisabled: false,
    Ct6GeneralAppearanceOptions: [{key: 0, value: "A", text: "A"}],
    Ct6Consciousness: "",
    Ct6ConsciousnessDisabled: false,
    Ct6ConsciousnessOptions: [{key: 0, value: "A", text: "A"}],
    Ct9Communication: "",
    Ct9CommunicationDisabled: false,
    Ct9CommunicationOptions: [{key: 0, value: "A", text: "A"}],
    Ct9CoOperation: "",
    Ct9CoOperationDisabled: false,
    Ct9CoOperationOptions: [{key: 0, value: "A", text: "A"}],
    PoPhysicalOrthoticFootOrthotic2Top64: "",
    PoPhysicalOrthoticFootOrthotic2Top64Disabled: false,
    PoPhysicalOrthoticFootOrthotic2Top65: "",
    PoPhysicalOrthoticFootOrthotic2Top65Disabled: false,
    PoPhysicalOrthoticFootOrthotic2Top66: "",
    PoPhysicalOrthoticFootOrthotic2Top66Disabled: false,
    PoPhysicalOrthoticFootOrthotic2Top67: "",
    PoPhysicalOrthoticFootOrthotic2Top67Disabled: false,
    PoPhysicalOrthoticFootOrthotic2Top68: false,
    PoPhysicalOrthoticFootOrthotic2Top68Disabled: false,
    PoPhysicalOrthoticFootOrthotic2Top70: false,
    PoPhysicalOrthoticFootOrthotic2Top70Disabled: false,
    PoPhysicalOrthoticFootOrthotic2Top72: false,
    PoPhysicalOrthoticFootOrthotic2Top72Disabled: false,
    PoPhysicalOrthoticFootOrthotic2Top74: false,
    PoPhysicalOrthoticFootOrthotic2Top74Disabled: false,
    PoPhysicalOrthoticFootOrthotic2Top76: false,
    PoPhysicalOrthoticFootOrthotic2Top76Disabled: false,
}

const Sub = (props: any) => {
    const children = React.Children.toArray(props.children)
    return (
        <div className={styles.container}>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top1}>
                <div
                    style={{background: "#F3F3F3", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top3}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalOrthoticFootOrthotic2Top3}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Top3Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationOrthoticFootOrthotic2TOP",
                                name: "PoPhysicalOrthoticFootOrthotic2Top3"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top4}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Foot type</strong></p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top5}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalOrthoticFootOrthotic2Top5}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Top5Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationOrthoticFootOrthotic2TOP",
                                name: "PoPhysicalOrthoticFootOrthotic2Top5"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top7}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalOrthoticFootOrthotic2Top7}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Top7Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationOrthoticFootOrthotic2TOP",
                                name: "PoPhysicalOrthoticFootOrthotic2Top7"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top9}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalOrthoticFootOrthotic2Top9}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Top9Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationOrthoticFootOrthotic2TOP",
                                name: "PoPhysicalOrthoticFootOrthotic2Top9"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top10}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Chubby/Fleshy</p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top11}>
                <Input
                    value={props.viewData.PoPhysicalOrthoticFootOrthotic2Top11}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Top11Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationOrthoticFootOrthotic2TOP',
                            name: 'PoPhysicalOrthoticFootOrthotic2Top11',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top12}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Lean/Bony</p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top13}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Muscular</p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top14}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Other</p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top15}>
                <Input
                    value={props.viewData.PoPhysicalOrthoticFootOrthotic2Top15}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Top15Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationOrthoticFootOrthotic2TOP',
                            name: 'PoPhysicalOrthoticFootOrthotic2Top15',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top16}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalOrthoticFootOrthotic2Top16}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Top16Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationOrthoticFootOrthotic2TOP",
                                name: "PoPhysicalOrthoticFootOrthotic2Top16"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top17}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Observation</strong></p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top18}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalOrthoticFootOrthotic2Top18}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Top18Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationOrthoticFootOrthotic2TOP",
                                name: "PoPhysicalOrthoticFootOrthotic2Top18"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top19}>
                <Input
                    value={props.viewData.PoPhysicalOrthoticFootOrthotic2Top19}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Top19Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationOrthoticFootOrthotic2TOP',
                            name: 'PoPhysicalOrthoticFootOrthotic2Top19',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top20}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Normal</p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top21}>
                <Input
                    value={props.viewData.PoPhysicalOrthoticFootOrthotic2Top21}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Top21Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationOrthoticFootOrthotic2TOP',
                            name: 'PoPhysicalOrthoticFootOrthotic2Top21',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top22}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Second or third toe longest</p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top23}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalOrthoticFootOrthotic2Top23}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Top23Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationOrthoticFootOrthotic2TOP",
                                name: "PoPhysicalOrthoticFootOrthotic2Top23"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top24}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Claw/hammer Toe</p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top25}>
                <Input
                    value={props.viewData.PoPhysicalOrthoticFootOrthotic2Top25}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Top25Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationOrthoticFootOrthotic2TOP',
                            name: 'PoPhysicalOrthoticFootOrthotic2Top25',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top26}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalOrthoticFootOrthotic2Top26}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Top26Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationOrthoticFootOrthotic2TOP",
                                name: "PoPhysicalOrthoticFootOrthotic2Top26"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top27}>
                <Input
                    value={props.viewData.PoPhysicalOrthoticFootOrthotic2Top27}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Top27Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationOrthoticFootOrthotic2TOP',
                            name: 'PoPhysicalOrthoticFootOrthotic2Top27',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top28}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalOrthoticFootOrthotic2Top28}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Top28Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationOrthoticFootOrthotic2TOP",
                                name: "PoPhysicalOrthoticFootOrthotic2Top28"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top29}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Calluses</p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top30}>
                <Input
                    value={props.viewData.PoPhysicalOrthoticFootOrthotic2Top30}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Top30Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationOrthoticFootOrthotic2TOP',
                            name: 'PoPhysicalOrthoticFootOrthotic2Top30',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top31}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalOrthoticFootOrthotic2Top31}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Top31Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationOrthoticFootOrthotic2TOP",
                                name: "PoPhysicalOrthoticFootOrthotic2Top31"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top32}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Ingrown nails</p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top33}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Hallux valgus</p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top34}>
                <Input
                    value={props.viewData.PoPhysicalOrthoticFootOrthotic2Top34}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Top34Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationOrthoticFootOrthotic2TOP',
                            name: 'PoPhysicalOrthoticFootOrthotic2Top34',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top35}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalOrthoticFootOrthotic2Top35}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Top35Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationOrthoticFootOrthotic2TOP",
                                name: "PoPhysicalOrthoticFootOrthotic2Top35"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top36}>
                <Input
                    value={props.viewData.PoPhysicalOrthoticFootOrthotic2Top36}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Top36Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationOrthoticFootOrthotic2TOP',
                            name: 'PoPhysicalOrthoticFootOrthotic2Top36',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top37}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalOrthoticFootOrthotic2Top37}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Top37Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationOrthoticFootOrthotic2TOP",
                                name: "PoPhysicalOrthoticFootOrthotic2Top37"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top38}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Fungus</p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top39}>
                <Input
                    value={props.viewData.PoPhysicalOrthoticFootOrthotic2Top39}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Top39Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationOrthoticFootOrthotic2TOP',
                            name: 'PoPhysicalOrthoticFootOrthotic2Top39',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top40}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalOrthoticFootOrthotic2Top40}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Top40Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationOrthoticFootOrthotic2TOP",
                                name: "PoPhysicalOrthoticFootOrthotic2Top40"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top41}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Wound/ulcer at</p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top42}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Others</p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top43}>
                <Input
                    value={props.viewData.PoPhysicalOrthoticFootOrthotic2Top43}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Top43Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationOrthoticFootOrthotic2TOP',
                            name: 'PoPhysicalOrthoticFootOrthotic2Top43',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.Ct3GeneralAppearanceDysfunction}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct3GeneralAppearanceDysfunction}
                    disabled={ props.viewData.Ct3GeneralAppearanceDysfunctionDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "POPhysicalexaminationOrthoticFootOrthotic2TOP",
                                name: "Ct3GeneralAppearanceDysfunction",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct3GeneralAppearanceDysfunctionOptions}
                />
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top47}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>General appearance</strong></p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top48}>
                <Input
                    value={props.viewData.PoPhysicalOrthoticFootOrthotic2Top48}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Top48Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationOrthoticFootOrthotic2TOP',
                            name: 'PoPhysicalOrthoticFootOrthotic2Top48',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.Ct6GeneralAppearance}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct6GeneralAppearance}
                    disabled={ props.viewData.Ct6GeneralAppearanceDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "POPhysicalexaminationOrthoticFootOrthotic2TOP",
                                name: "Ct6GeneralAppearance",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct6GeneralAppearanceOptions}
                />
            </div>
            <div className={styles.Ct6Consciousness}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct6Consciousness}
                    disabled={ props.viewData.Ct6ConsciousnessDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "POPhysicalexaminationOrthoticFootOrthotic2TOP",
                                name: "Ct6Consciousness",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct6ConsciousnessOptions}
                />
            </div>
            <div className={styles.Ct9Communication}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct9Communication}
                    disabled={ props.viewData.Ct9CommunicationDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "POPhysicalexaminationOrthoticFootOrthotic2TOP",
                                name: "Ct9Communication",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct9CommunicationOptions}
                />
            </div>
            <div className={styles.Ct9CoOperation}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct9CoOperation}
                    disabled={ props.viewData.Ct9CoOperationDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "POPhysicalexaminationOrthoticFootOrthotic2TOP",
                                name: "Ct9CoOperation",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct9CoOperationOptions}
                />
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top61}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Consciousness</strong></p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top62}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Communication</strong></p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top63}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Co-operation</strong></p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top64}>
                <Input
                    value={props.viewData.PoPhysicalOrthoticFootOrthotic2Top64}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Top64Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationOrthoticFootOrthotic2TOP',
                            name: 'PoPhysicalOrthoticFootOrthotic2Top64',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top65}>
                <Input
                    value={props.viewData.PoPhysicalOrthoticFootOrthotic2Top65}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Top65Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationOrthoticFootOrthotic2TOP',
                            name: 'PoPhysicalOrthoticFootOrthotic2Top65',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top66}>
                <Input
                    value={props.viewData.PoPhysicalOrthoticFootOrthotic2Top66}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Top66Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationOrthoticFootOrthotic2TOP',
                            name: 'PoPhysicalOrthoticFootOrthotic2Top66',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top67}>
                <Input
                    value={props.viewData.PoPhysicalOrthoticFootOrthotic2Top67}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Top67Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'POPhysicalexaminationOrthoticFootOrthotic2TOP',
                            name: 'PoPhysicalOrthoticFootOrthotic2Top67',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top68}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalOrthoticFootOrthotic2Top68}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Top68Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationOrthoticFootOrthotic2TOP",
                                name: "PoPhysicalOrthoticFootOrthotic2Top68"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top69}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Muscle length</strong></p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top70}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalOrthoticFootOrthotic2Top70}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Top70Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationOrthoticFootOrthotic2TOP",
                                name: "PoPhysicalOrthoticFootOrthotic2Top70"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top71}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Normal</p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top72}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalOrthoticFootOrthotic2Top72}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Top72Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationOrthoticFootOrthotic2TOP",
                                name: "PoPhysicalOrthoticFootOrthotic2Top72"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top73}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Tightness</p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top74}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalOrthoticFootOrthotic2Top74}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Top74Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationOrthoticFootOrthotic2TOP",
                                name: "PoPhysicalOrthoticFootOrthotic2Top74"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top75}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Shortening</p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top76}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoPhysicalOrthoticFootOrthotic2Top76}
                    disabled={ props.viewData.PoPhysicalOrthoticFootOrthotic2Top76Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "POPhysicalexaminationOrthoticFootOrthotic2TOP",
                                name: "PoPhysicalOrthoticFootOrthotic2Top76"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top77}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Contracture</p>
                </div>
            </div>
            <div className={styles.PoPhysicalOrthoticFootOrthotic2Top78}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>N/A</p>
                </div>
            </div>
        </div>
    )
}

export default Sub

const getTsName = (cssClass: string) => {
    return cssClass.split('-').map((s) => (
        s.charAt(0).toUpperCase() + s.slice(1)
    )).join('')
}
