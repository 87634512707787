import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  DateTextBox
} from 'react-lib/apps/common'
import {
  Button
} from 'semantic-ui-react'

const CardNursingDiagnosisEditUX = (props: any) => {
    return(
      <div
        style={{padding: "5px"}}>
        <div
          style={{"width":"100%","height":"100%","display":"grid","position":"relative","gridTemplateRows":"repeat(9, 1fr)","gridTemplateColumns":"repeat(50, 1fr)"}}>
          <div
            className="ui form"
            style={{ gridRow: "2/6", gridColumn: "2/17" }}>
            <textarea
              onChange={props.changeNursingDiagnosis("diagnosis")}
              style={{width: "100%", height: "100%", resize: "none"}}
              value={props.diagnosis}>
            </textarea>
          </div>
          <div
            className="ui form"
            style={{ gridRow: "2/6", gridColumn: "18/33" }}>
            <textarea
              onChange={props.changeNursingDiagnosis("plan")}
              style={{width: "100%", height: "100%", resize: "none"}}
              value={props.plan}>
            </textarea>
          </div>
          <div
            className="ui form"
            style={{ gridRow: "2/6", gridColumn: "34/49", border: "solid 1px rgb(200, 200, 200, 0.5)" }}>
            <textarea
              onChange={props.changeNursingDiagnosis("goal")}
              style={{width: "100%", height: "100%", resize: "none"}}
              value={props.goal}>
            </textarea>
          </div>
          <div
            style={{ gridRow: "1/2", gridColumn: "2/17" }}>
            ข้อวินิจฉัยทางการพยาบาล
          </div>
          <div
            style={{ gridRow: "1/2", gridColumn: "18/33"}}>
            แผนการพยาบาล
          </div>
          <div
            style={{ gridRow: "1/2", gridColumn: "34/49"}}>
            ผลลัพธ์ที่คาดหวัง
          </div>
          <div
            style={{ gridRow: "7/9", gridColumn: "2/20", display: "flex", alignItems: "center"}}>
            <label
              style={{marginRight: "10px", minWidth: "max-content"}}>
              วันที่สิ้นสุดปัญหา
            </label>
            <DateTextBox
              onChange={props.changeDate("end_date")}
              style={{width: "100%"}}
              value={props.end_date}>
            </DateTextBox>
          </div>
          <div
            style={{ gridRow: "7/9", gridColumn: "46/49", display: "flex", alignItems: "center"}}>
            <Button
              color="green"
              onClick={props.onSave}
              style={ { display: "none", width: "100%" ,height: "100%"}}>
              SAVE
            </Button>
            <div>
              {props.buttonSave}
            </div>
          </div>
        </div>
      </div>
    )
}


export default CardNursingDiagnosisEditUX

export const screenPropsDefault = {}

/* Date Time : Wed May 10 2023 10:42:58 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding: \"5px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "label": "divgrid",
      "name": "div",
      "parent": 0,
      "props": {
        "style": {
          "type": "code",
          "value": "{\"width\":\"100%\",\"height\":\"100%\",\"display\":\"grid\",\"position\":\"relative\",\"gridTemplateRows\":\"repeat(9, 1fr)\",\"gridTemplateColumns\":\"repeat(50, 1fr)\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 1,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/6\", gridColumn: \"2/17\" }"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 31,
      "name": "div",
      "parent": 1,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/6\", gridColumn: \"18/33\" }"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "div",
      "parent": 1,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"2/6\", gridColumn: \"34/49\", border: \"solid 1px rgb(200, 200, 200, 0.5)\" }"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ข้อวินิจฉัยทางการพยาบาล"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"2/17\" }"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "แผนการพยาบาล"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"18/33\"}"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ผลลัพธ์ที่คาดหวัง"
        },
        "style": {
          "type": "code",
          "value": "{ gridRow: \"1/2\", gridColumn: \"34/49\"}"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 39,
      "name": "textarea",
      "parent": 30,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changeNursingDiagnosis(\"diagnosis\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\", resize: \"none\"}"
        },
        "value": {
          "type": "code",
          "value": "props.diagnosis"
        }
      },
      "seq": 39,
      "void": true
    },
    {
      "from": null,
      "id": 40,
      "name": "textarea",
      "parent": 31,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changeNursingDiagnosis(\"plan\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\", resize: \"none\"}"
        },
        "value": {
          "type": "code",
          "value": "props.plan"
        }
      },
      "seq": 40,
      "void": true
    },
    {
      "from": null,
      "id": 41,
      "name": "textarea",
      "parent": 32,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changeNursingDiagnosis(\"goal\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\", resize: \"none\"}"
        },
        "value": {
          "type": "code",
          "value": "props.goal"
        }
      },
      "seq": 41,
      "void": true
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/9\", gridColumn: \"2/20\", display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 44,
      "name": "DateTextBox",
      "parent": 43,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changeDate(\"end_date\")"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.end_date"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "div",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ gridRow: \"7/9\", gridColumn: \"46/49\", display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "Button",
      "parent": 45,
      "props": {
        "children": {
          "type": "value",
          "value": "SAVE"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSave"
        },
        "style": {
          "type": "code",
          "value": " { display: \"none\", width: \"100%\" ,height: \"100%\"}"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "div",
      "parent": 45,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "label",
      "parent": 43,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่สิ้นสุดปัญหา"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"10px\", minWidth: \"max-content\"}"
        }
      },
      "seq": 44,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 40,
  "isMounted": false,
  "memo": false,
  "name": "CardNursingDiagnosisEditUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
