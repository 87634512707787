import WasmController from "react-lib/frameworks/WasmController";
import DrugOrderHistoryList from "issara-sdk/apis/DrugOrderHistoryList_apps_TPD";
import DrugOrderDetail from "issara-sdk/apis/DrugOrderDetailForAction_apps_TPD";
// import DrugReturnItemDetail from "issara-sdk/apis/DrugReturnItemDetail_apps_TPD"

import moment from "moment";
import { formatDate } from '../../../../utils/dateUtils';

export type State = {
  selectedEncounter?: any;
  drugHistoryList?: any[];
  drugHistoryOrderList?: any[];
  masterOptions?: any;
  DrugOrderHistorySequence?: {
    sequenceIndex: string | null;
    loading: boolean;
    selectedDrugOrderType?: number[] | null;
    selectedDrugOrderStatus?: string[] | null;
    selectedDivisionPharma?: any;
    selectedStartDate?: any;
    selectedEndDate?: any;
    selectedPatientSearch?: any;
    selectedDrugSearch?: any;
    selectedDoctorSearch?: any;
    checkedDrugOrderType?: boolean | null;
    checkedDrugOrderStatus?: boolean | null;
    checkedDivisionPharma?: boolean | null;
    checkedFilterDate?: boolean | null;
    checkedPatientSearch?: boolean | null;
    checkedDrugSearch?: boolean | null;
    checkedDoctorSearch?: boolean | null;
  } | null;
};

export const StateInitial: State = {
  drugHistoryList: [],
  drugHistoryOrderList: [],
  DrugOrderHistorySequence: null,
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: any };

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const Start: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.DrugOrderHistorySequence) return;

  // Fetch master data, then transform into state.masterOptions[xxx]
  await controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        ["drugOrderType", {}],
        ["divisionPharma", {}],
        ["drugStatus", {}],
        ["unit", {}],
        ["route", {}],
        ["frequency", {}],
        ["method", {}],
        ["site", {}],
        ["shippingEmployee", {}],
      ],
    },
  });
  console.log("masterData:", state.masterOptions);

  if (params?.isDrugOrderHistory) {
    controller.setState(
      {
        DrugOrderHistorySequence: {
          ...state.DrugOrderHistorySequence,
          sequenceIndex: "SearchDrugHistoryOrder",
          selectedPatientSearch: state.selectedEncounter?.patient_id || params.selectedPatientSearch,
        },
        drugHistoryOrderList: [],
        drugHistoryList: []
      },
      () => {
        SearchDrugHistoryOrder(controller, { action: "search" });
      }
    );
  } else {
    controller.setState({
      DrugOrderHistorySequence: {
        ...state.DrugOrderHistorySequence,
        sequenceIndex: "SearchDrugHistoryOrder",
      },
      drugHistoryOrderList: [],
      drugHistoryList: []
    });
  }
};

export const SearchDrugHistoryOrder: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.DrugOrderHistorySequence) return;
  controller.setState({
    DrugOrderHistorySequence: {
      ...state.DrugOrderHistorySequence,
      loading: true,
    },
  });
  let defaultParams = {
    patient: state.DrugOrderHistorySequence?.selectedPatientSearch || "",
    type:
      state.DrugOrderHistorySequence?.selectedDrugOrderType?.join(",") || "",
    drug: state.DrugOrderHistorySequence?.selectedDrugSearch?.id || "",
    doctor: state.DrugOrderHistorySequence?.selectedDoctorSearch?.id || "",
    from_date: state.DrugOrderHistorySequence?.selectedStartDate || "",
    to_date: state.DrugOrderHistorySequence?.selectedEndDate || "",
    division: state.DrugOrderHistorySequence?.selectedDivisionPharma || "",
    status: state.DrugOrderHistorySequence?.selectedDrugOrderStatus || "",
  };

  if (params?.action === "search") {
    const [res, error, network] = await HandleGetDrugOrderHistory(
      controller,
      defaultParams
    );
    if (error) {
      console.log("get drug order histories error params:", defaultParams);
    }
    let filterData = [];
    if (res?.items?.length > 0) {
      filterData = res?.items.map((item: any) => {
        return { ...item, order_time: formatDate(moment(item.order_time)) };
      });
    }
    controller.setState({
      drugHistoryOrderList: filterData,
      DrugOrderHistorySequence: {
        ...state.DrugOrderHistorySequence,
        loading: false,
      },
    });
  } else if (params?.action === "today") {
    const [res, error, network] = await HandleGetDrugOrderHistory(controller, {
      ...defaultParams,
      from_date: getToday(),
      to_date: getToday(),
    });
    if (error) {
      console.log("get drug order histories error params:", {
        ...defaultParams,
        from_date: getToday(),
        to_date: getToday(),
      });
    }
    let filterData = [];
    if (res?.items?.length > 0) {
      filterData = res?.items.map((item: any) => {
        return { ...item, order_time: formatDate(moment(item.order_time)) };
      });
    }
    controller.setState({
      drugHistoryOrderList: filterData,
      DrugOrderHistorySequence: {
        ...state.DrugOrderHistorySequence,
        loading: false,
        checkedFilterDate: true,
        selectedStartDate: getToday(),
        selectedEndDate: getToday(),
      },
    });
  } else if (params?.action === "vaccine") {
    const [res, error, network] = await HandleGetDrugOrderHistory(controller, {
      ...defaultParams,
      vaccine: true,
    });
    if (error) {
      console.log("get drug order histories error params:", {
        ...defaultParams,
        vaccine: true,
      });
    }
    let filterData = [];
    if (res?.items?.length > 0) {
      filterData = res?.items.map((item: any) => {
        return { ...item, order_time: formatDate(moment(item.order_time)) };
      });
    }
    controller.setState({
      drugHistoryOrderList: filterData,
      DrugOrderHistorySequence: {
        ...state.DrugOrderHistorySequence,
        loading: false,
      },
    });
  } else if (params?.action === "clear") {
    controller.setState({
      DrugOrderHistorySequence: {
        ...state.DrugOrderHistorySequence,
        loading: false,
        selectedDrugOrderType: [],
        selectedDrugOrderStatus: [],
        selectedDivisionPharma: "",
        selectedStartDate: "",
        selectedEndDate: "",
        selectedPatientSearch: null,
        selectedDrugSearch: null,
        selectedDoctorSearch: null,
        checkedDrugOrderType: false,
        checkedDrugOrderStatus: false,
        checkedDivisionPharma: false,
        checkedFilterDate: false,
        checkedPatientSearch: false,
        checkedDrugSearch: false,
        checkedDoctorSearch: false,
      },
    });
  } else if (params?.action === "drug_order") {
    console.log("drug order history params:", params);
    let note_items = ["note_adverse_reaction", "note_rejected"];
    let drugHistory = [];
    let drugReturnItem = [];
    if (params.drug_order?.order_id) {
      const [res, error, network] = await DrugOrderDetail.retrieve({
        apiToken: controller.apiToken,
        pk: params.drug_order?.order_id,
      });

      if (error) {
        console.log("get drug history list error");
      }
      if (res?.items?.length > 0) {
        drugHistory = res?.items.map((item: any) => {
          let noteItems = "";
          note_items.forEach((note_key) => {
            if (item[note_key]) {
              noteItems += `${item[note_key]}\n`;
            }
          });
          // noteItems += </div>
          return { ...item, note_reason: noteItems };
        });

        // drugReturnItem = res?.items?.filter(item => item.is_returnable)
      }
    }

    const state = (controller as any).getState();

    controller.setState({
      drugHistoryList: drugHistory,
      DrugOrderHistorySequence: {
        ...state.DrugOrderHistorySequence,
        loading: false,
      },
    });
  }
};

// utilities
const HandleGetDrugOrderHistory: Handler = async (controller, params) => {
  const [res, error, network] = await DrugOrderHistoryList.list({
    apiToken: controller.apiToken,
    params: { ...params },
  });

  if (error) {
    console.log("error with params:", params);
  }

  return [res, error, network];
};

const getToday = () => {
  const date = moment(new Date()).format("DD/MM");
  const yearBE = new Date().getFullYear() + 543;
  return `${date}/${yearBE}`;
};
