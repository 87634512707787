import moment, { Moment } from "moment";
import React, { SyntheticEvent, useEffect, useMemo, useState } from "react";
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import { formatDate, parseDate } from "react-lib/utils/dateUtils";
import { Checkbox, CheckboxProps, Label, Loader } from "semantic-ui-react";
import CardFoodReportOrderQueueUX from "./CardFoodReportOrderQueueUX";
import CardFoodReportUX from "./CardFoodReportUX";
import { useIntl } from "react-intl";

// ================================================== CardFood
type CardFoodReportProps = {
  // function
  onEvent: any;
  setProp: any;

  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;
  DJANGO?: any;

  // seq
  runSequence: any;
  FoodListSequence?: any;
  FoodRequestSequence?: any;

  // options
  foodRequestOptions?: any;
  masterOptions?: any;
};

const CardFoodReportInitial: CardFoodReportProps = {
  // funtion
  onEvent: () => null,
  setProp: () => null,

  // CommonInterface
  errorMessage: null,
  successMessage: null,
  buttonLoadCheck: null,
  DJANGO: null,

  // seq
  runSequence: null,
  FoodListSequence: null,
  FoodRequestSequence: null,

  // options
  foodRequestOptions: {},
};

const CardFoodReport: React.FC<CardFoodReportProps> = (props: any) => {
  const intl = useIntl();
  const [foodLoading, setFoodLoading] = useState(false);
  const [checkedIds, setCheckedIds] = useState<Partial<Record<string, number[]>>>({});

  useEffect(() => {
    props.runSequence({
      sequence: "FoodList",
      restart: true,
    });
  }, []);

  useEffect(() => {
    if (props.FoodListSequence?.loadingData) {
      setFoodLoading(true);
    } else {
      setFoodLoading(false);
    }
  }, [props.FoodListSequence?.loadingData]);

  const foodTimes = useMemo(() => {
    return Object.keys(props.FoodListSequence?.foodData || {}).sort();
  }, [props.FoodListSequence?.foodData]);

  const foodTimeOptions = useMemo(() => {
    return foodTimes?.map((item: any) => {
      return { key: item, value: item, text: item };
    });
  }, [foodTimes]);

  const isCheckAllFoodItems = useMemo(() => {
    const allItems = Object.values(props.FoodListSequence?.foodData || {}).flat();
    const checkAllItems = Object.values(checkedIds).flat();

    return allItems.length > 0 && allItems.length === checkAllItems.length
  }, [checkedIds, props.FoodListSequence?.foodData]);

  const handleChangeData = (_event: any, data: any) => {
    if (data?.type === "checkbox") {
      props.setProp(`FoodListSequence.${data.name}`, data.checked);
    } else {
      props.setProp(`FoodListSequence.${data.name}`, data.value);
    }

    if (data.name === "filterQueue.division") {
    }
  };

  const handleChangeDate = (date: any) => {
    if (date === "") {
      date = formatDate(moment());
    }
    props.setProp(`FoodListSequence.filterQueue.date`, date);
  };

  const handleCheckAll =
    (time: string, ids: number[]) => (e: SyntheticEvent, data: CheckboxProps) => {
      setCheckedIds((prev) => ({
        ...prev,
        [time]: data.checked ? ids : [],
      }));
    };

  const handleToggleCheck =
    (time: string, id: number) => (e: SyntheticEvent, data: CheckboxProps) => {
      let ids = checkedIds[time] || [];

      if (data.checked) {
        ids.push(id);
      } else {
        ids = ids.filter((prevId) => prevId !== id);
      }

      setCheckedIds({ ...checkedIds, [time]: ids });
    };

  const handlePrintStickerReport = () => {
    props.runSequence({
      sequence: "FoodList",
      action: "stickerReport",
      checkedIds,
      onSuccess: () => {
        console.log("success !!!!")
        setCheckedIds({});
      },
    });
  };

  const handleCheckAllFoodItems = (e: any, data: CheckboxProps) => {
    const checked: Record<string, number[]> = {};

    for (const time of foodTimes) {
      const foodData: any[] = props.FoodListSequence?.foodData?.[time] || [];

      checked[time] = foodData.map((item) => item.id);
    }

    setCheckedIds(data.checked ? checked : {});
  };

  return (
    <>
      <CardFoodReportOrderQueueUX
        FoodListSequence={props.FoodListSequence}
        mealOptions={foodTimeOptions}
        wardOptions={props.masterOptions.divisionIpd}
        onChangeData={handleChangeData}
        onChangeDate={handleChangeDate}
        buttonPrintReport={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            // config
            color={"green"}
            name="SAVE"
            // data
            paramKey={`CardFoodReport_Print`}
            size="small"
            title={"พิมพ์รายงาน"}
            buttonLoadCheck={props.buttonLoadCheck?.[`CardFoodReport_Print`]}
            onClick={() => {
              props.runSequence({
                sequence: "FoodList",
                action: "printReport",
                checkedIds
              });
            }}
          />
        }
        buttonPrintSticker={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            // config
            color={"orange"}
            name="PRINT_STICKER"
            // data
            paramKey={`CardFoodReport_Sticker`}
            size="small"
            title={"Print sticker"}
            buttonLoadCheck={props.buttonLoadCheck?.[`CardFoodReport_Sticker`]}
            onClick={handlePrintStickerReport}
          />
        }
        buttonSearch={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            // config
            color={"blue"}
            name="SAVE"
            // data
            paramKey={`CardFoodReport_Search`}
            size="small"
            title={"แสดง"}
            buttonLoadCheck={props.buttonLoadCheck?.[`CardFoodReport_Search`]}
            onClick={() => {
              setCheckedIds({});

              props.runSequence({
                sequence: "FoodList",
                action: "getFoodDate",
              });
            }}
          />
        }
        languageUX={props.languageUX}
      />

      <div
        style={{
          alignItems: "center",
          display: foodLoading ? "flex" : "none",
          height: "100%",
        }}
      >
        <Loader active={foodLoading} inline="centered" Inverted>
          Loading
        </Loader>
      </div>

      {foodTimes?.length === 0 && (
        <div style={{ color: "grey", marginTop: "250px", textAlign: "center" }}>
          <h3>{intl.formatMessage({ id: "ไม่พบการสั่งรายการอาหาร" })}</h3>
        </div>
      )}

      {(foodTimes.length || 0) > 0 && (
        <div
          style={{
            backgroundColor: "var(--primary-theme-color)",
            display: "grid",
            gridTemplateColumns: "50px 1fr",
            margin: "0 20px",
          }}
        >
          <div
            style={{
              alignItems: "center",
              borderRight: "1px solid #ddd",
              display: "flex",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <Checkbox checked={isCheckAllFoodItems} onChange={handleCheckAllFoodItems} />
          </div>
          <div style={{ color: "white", fontSize: "1.35rem", padding: "8px", textAlign: "center" }}>
            <div style={{ fontWeight: "bold", marginLeft: "-40px" }}>รายงานอาหาร</div>
          </div>
        </div>
      )}
      {foodTimes?.map((time: string, index: number) => {
        if (
          props.FoodListSequence?.filterQueue?.meal === undefined ||
          props.FoodListSequence?.filterQueue?.meal === "" ||
          props.FoodListSequence?.filterQueue?.meal === time
        ) {
          const foodData: any[] = props.FoodListSequence?.foodData?.[time] || [];
          const ids = foodData.map((item) => item.id);

          const checkedAll =
            checkedIds[time] &&
            checkedIds[time].length > 0 &&
            ids.every((id) => checkedIds[time]?.includes(id));

          return (
            <CardFoodReportUX
              key={index}
              title={`มื้อ ${time}`}
              foodData={foodData.map((item: any) => ({
                ...item,
                patient_fullname: (
                  <div style={{ textAlign: "center" }}>
                    {item.patient_fullname}
                    <div>ศาสนา: {item.religion || "-"}</div>
                  </div>
                ),
                checkbox: (
                  <div style={{ display: "grid", placeContent: "center" }}>
                    <Checkbox
                      checked={checkedIds[time]?.includes(item.id) || false}
                      onChange={handleToggleCheck(time, item.id)}
                    />
                  </div>
                ),
                room_lbl: (
                  <>
                    {item.status_name === "OFF" && (
                      <Label color="black" style={{ marginRight: "5px" }}>
                        OFF
                      </Label>
                    )}
                    {item.room}
                  </>
                ),
                detail_lbl: (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item?.detail_label,
                    }}
                  />
                ),
                food_type_lbl: (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item?.food_type_label,
                    }}
                  />
                ),
                nutrition_control_detail_lbl: (
                  <>
                    <>
                      <span style={{ fontWeight: "bold" }}>
                        {intl.formatMessage({ id: "อาหารเฉพาะโรค :" })}
                      </span>
                      {item?.special_diet_detail}
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        {intl.formatMessage({ id: "หมายเหตุ :" })}
                      </span>
                      {item?.special_diet_remark}
                    </>
                    <hr
                      style={{
                        backgroundColor: "gray",
                        color: "gray",
                        height: 2,
                      }}
                    />
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item?.nutrition_control_detail_label,
                      }}
                    />
                  </>
                ),
              }))}
              headers={[
                <Checkbox
                  key={`checkbox-${time}`}
                  checked={checkedAll || false}
                  onChange={handleCheckAll(time, ids)}
                />,
                "ห้อง/เตียง",
                " HN",
                " ชื่อ นามสกุล",
                " รายการอาหาร",
                " ข้อมูลอาหารเฉพาะโรค/คำสั่งควบคุมต่อวัน",
                " รายละเอียด",
              ]}
              languageUX={props.languageUX}
            />
          );
        }
      })}
    </>
  );
};

CardFoodReport.defaultProps = CardFoodReportInitial;

export default React.memo(CardFoodReport);
