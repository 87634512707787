import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Button,
  Label
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardMedicationErrorStatisticsUX = (props: any) => {
    return(
      <div
        style={{padding: "0 5px"}}>
        <div
          style={{fontWeight: "bold", fontSize: "1.2rem", padding: "1rem 0"}}>
          สถิติการเกิดอุบัติการณ์ความเสี่ยงและความคลาดเคลื่อนทางยา (Medication Error)
        </div>
        <div
          className="ui form">
          
          <FormGroup
            inline={true}>
            <FormField
              inline={true}>
              <label>
                วันที่เกิดปัญหา
              </label>
            </FormField>
            <FormField
              inline={true}>
              <DateTextBox
                onChange={(date)=>props.onChange(null,{name: "start_date", value: date})}
                value={props.medErrorStatistics?.start_date}>
              </DateTextBox>
            </FormField>
            <FormField
              inline={true}>
              <label>
                ถึง
              </label>
            </FormField>
            <FormField
              inline={true}>
              <DateTextBox
                onChange={(date)=>props.onChange(null,{name: "end_date", value: date})}
                value={props.medErrorStatistics?.end_date}>
              </DateTextBox>
            </FormField>
            <FormField>
              <div>
                {props.ButtonSearch}
              </div>
            </FormField>
            <FormField>
              <Button
                color="yellow"
                onClick={props.onBack}>
                ย้อนกลับ
              </Button>
            </FormField>
            <div
              className="field"
              style={{flex: 1}}>
              
            </div>
            <FormField>
              <label>
                จำนวน Error ทั้งหมด
              </label>
            </FormField>
            <FormField
              width={{minWidth: "2rem"}}>
              <Label
                size="large"
                style={{backgroundColor: "transparent", border: "2px solid red", borderRadius: "3px", color: "red"}}>
                {props.number || "0"}
              </Label>
            </FormField>
            <FormField>
              <Button
                color="brown"
                onClick={props.onExportExcel}>
                Export Excel
              </Button>
            </FormField>
          </FormGroup>
        </div>
        <div>
          
          <Table
            data={props.items || []}
            headers="ลำดับ,ผู้ที่ทำให้เกิดความผิดพลาด,ประเภท,รายการที่คลาดเคลื่อน,จำนวน"
            keys="no,stakeholder_name, type_name,drug_name,qty"
            showPagination={false}
            style={{height: "calc(100vh - 15rem)"}}
            widths="150,auto,auto,auto,200">
          </Table>
        </div>
      </div>
    )
}

export default CardMedicationErrorStatisticsUX

export const screenPropsDefault = {"userSearch":"[User Search Box]"}

/* Date Time : Fri Mar 10 2023 05:48:44 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding: \"0 5px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "สถิติการเกิดอุบัติการณ์ความเสี่ยงและความคลาดเคลื่อนทางยา (Medication Error)"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.2rem\", padding: \"1rem 0\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "FormGroup",
      "parent": 2,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormField",
      "parent": 3,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "label",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่เกิดปัญหา"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 9,
      "name": "DateTextBox",
      "parent": 5,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(date)=>props.onChange(null,{name: \"start_date\", value: date})"
        },
        "value": {
          "type": "code",
          "value": "props.medErrorStatistics?.start_date"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 10,
      "name": "DateTextBox",
      "parent": 7,
      "props": {
        "onChange": {
          "type": "code",
          "value": "(date)=>props.onChange(null,{name: \"end_date\", value: date})"
        },
        "value": {
          "type": "code",
          "value": "props.medErrorStatistics?.end_date"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "label",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "field"
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "FormField",
      "parent": 3,
      "props": {
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "FormField",
      "parent": 3,
      "props": {
        "width": {
          "type": "code",
          "value": "{minWidth: \"2rem\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "FormField",
      "parent": 3,
      "props": {
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "label",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": "จำนวน Error ทั้งหมด"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "Button",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": "Export Excel"
        },
        "color": {
          "type": "value",
          "value": "brown"
        },
        "onClick": {
          "type": "code",
          "value": "props.onExportExcel"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "FormField",
      "parent": 3,
      "props": {
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "Label",
      "parent": 15,
      "props": {
        "children": {
          "type": "code",
          "value": "props.number || \"0\""
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"transparent\", border: \"2px solid red\", borderRadius: \"3px\", color: \"red\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 25,
      "name": "Table",
      "parent": 24,
      "props": {
        "data": {
          "type": "code",
          "value": "props.items || []"
        },
        "headers": {
          "type": "value",
          "value": "ลำดับ,ผู้ที่ทำให้เกิดความผิดพลาด,ประเภท,รายการที่คลาดเคลื่อน,จำนวน"
        },
        "keys": {
          "type": "value",
          "value": "no,stakeholder_name, type_name,drug_name,qty"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"calc(100vh - 15rem)\"}"
        },
        "widths": {
          "type": "value",
          "value": "150,auto,auto,auto,200"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSearch"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "FormField",
      "parent": 3,
      "props": {
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "Button",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": "ย้อนกลับ"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.onBack"
        }
      },
      "seq": 28,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardMedicationErrorStatisticsUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "userSearch": "[User Search Box]"
  },
  "width": 80
}

*********************************************************************************** */
