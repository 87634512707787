import React, { useCallback, useEffect, useMemo } from "react";

import { Modal } from "semantic-ui-react";

import moment from "moment";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SnackMessage from "react-lib/apps/common/SnackMessage";

// UX
import ModDownloadZipFileUX from "./ModDownloadZipFileUX";
import ModSummaryZipFileUX from "./ModSummaryZipFileUX";

// Interface
import {
  ACTIONS,
  BTN_ACTS,
  MOD_DOWNLOAD_ZIP_FILE,
  PickedProps,
  RunSequence,
  SendClaimType,
  SetProp,
  State,
} from "./sequence/SendClaimDownloadZipFile";

// Utils
import { formatDatetime } from "react-lib/utils/dateUtils";
import { useIntl } from "react-intl";

// Types
type ModDownloadZipFileProps = {
  onEvent: (e: any) => any;
  setProp: SetProp;
  // seq
  runSequence: RunSequence;
  SendClaimDownloadZipFileSequence: State["SendClaimDownloadZipFileSequence"];
  // data
  arTransaction?: Record<string, any> | null;
  open: boolean;
  type: SendClaimType;
  // callback
  onClose: () => any;
} & PickedProps;

const ModDownloadZipFile = (props: ModDownloadZipFileProps) => {
  const intl = useIntl();
  // Effect
  useEffect(() => {
    if (props.open) {
      props.runSequence({
        sequence: "SendClaimDownloadZipFile",
        arTransaction: props.arTransaction?.id,
        restart: true,
        type: props.type,
      });
    }
  }, [props.open]);

  // Callback
  const handleOpenModSummary = useCallback(
    (data: Record<string, any>) => () => {
      props.runSequence({
        sequence: "SendClaimDownloadZipFile",
        action: ACTIONS.SEND_CLAIM_SUMMARY,
        card: MOD_DOWNLOAD_ZIP_FILE,
        data,
        type: props.type,
      });
    },
    [props.type]
  );

  const handleDownloadZipFile = useCallback(
    (data: Record<string, any>) => () => {
      props.runSequence({
        sequence: "SendClaimDownloadZipFile",
        action: ACTIONS.DOWNLOAD_ZIP_FILE,
        card: MOD_DOWNLOAD_ZIP_FILE,
        data,
        type: props.type,
      });
    },
    [props.type]
  );

  const handleCloseModSummary = useCallback(() => {
    props.setProp("SendClaimDownloadZipFileSequence", {
      ...props.SendClaimDownloadZipFileSequence,
      selectedTransaction: null,
      transactionSummary: null,
    });
  }, [props.SendClaimDownloadZipFileSequence]);

  const handleClose = useCallback(() => {
    props.onClose();

    props.setProp(`errorMessage.${MOD_DOWNLOAD_ZIP_FILE}`, null);

    props.runSequence({
      sequence: "SendClaimDownloadZipFile",
      clear: true,
    });
  }, []);

  const handleCloseErrMsg = useCallback(() => {
    props.setProp(`errorMessage.${MOD_DOWNLOAD_ZIP_FILE}`, null);
  }, []);

  const handleRef = useCallback((ref: HTMLDivElement | null) => {
    if (ref) {
      const modal = ref.closest(".ui.page.modals.visible.active") as HTMLDivElement | null;

      if (modal) {
        modal.style.backgroundColor = "rgba(0,0,0,.5)";
      }
    }
  }, []);

  // Memo
  const fiscalYear = useMemo(() => {
    const startDate = props.arTransaction?.start_date;

    // 22/04/2567
    return /\d+$/g.exec(startDate)?.[0];
  }, [props.arTransaction]);

  const formattedSummary = useMemo(() => {
    const { total_rows: totalRows, ...summary } =
      props.SendClaimDownloadZipFileSequence?.transactionSummary || {};

    return {
      totalRows,
      ...Object.fromEntries(
        Object.entries(summary).map(([key, value]) => [
          key,
          Number(value).toLocaleString("en-US", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            style: "decimal",
          }),
        ])
      ),
    };
  }, [props.SendClaimDownloadZipFileSequence?.transactionSummary]);

  const summaryTitle = useMemo(() => {
    const selected = props.SendClaimDownloadZipFileSequence?.selectedTransaction;

    const fiscalYear = selected?.sent_at_datetime
      ? Number(moment(selected.sent_at_datetime).format("YYYY")) + 543
      : "-";

    return `งวดส่ง ${selected?.sent_no || "-"} ปีงบประมาณ ${fiscalYear}`;
  }, [props.SendClaimDownloadZipFileSequence?.selectedTransaction]);

  const data = useMemo(() => {
    const list = props.SendClaimDownloadZipFileSequence?.transactionList || [];

    return list.map((item) => ({
      ...item,
      datetime: formatDatetime(item.sent_at, true),
      download: (
        <div style={{ display: "grid", placeContent: "center" }}>
          <ButtonLoadCheck
            setProp={props.setProp}
            color={"green"}
            name={ACTIONS.DOWNLOAD_ZIP_FILE}
            // data
            paramKey={BTN_ACTS.DOWNLOAD_ZIP_FILE}
            size="mini"
            title="Download"
            buttonLoadCheck={props.buttonLoadCheck?.[BTN_ACTS.DOWNLOAD_ZIP_FILE]}
            // callback
            onClick={handleDownloadZipFile(item)}
          />
        </div>
      ),
      summary: (
        <div style={{ display: "grid", placeContent: "center" }}>
          <ButtonLoadCheck
            setProp={props.setProp}
            color={"violet"}
            name={ACTIONS.SEND_CLAIM_SUMMARY}
            paramKey={BTN_ACTS.SEND_CLAIM_SUMMARY}
            size="mini"
            title={intl.formatMessage({ id: "ยอดรวม" })}
            buttonLoadCheck={props.buttonLoadCheck?.[BTN_ACTS.SEND_CLAIM_SUMMARY]}
            // callback
            onClick={handleOpenModSummary(item)}
          />
        </div>
      ),
    }));
  }, [
    handleDownloadZipFile,
    handleOpenModSummary,
    props.buttonLoadCheck,
    props.SendClaimDownloadZipFileSequence?.transactionList,
  ]);

  return (
    <>
      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage?.[MOD_DOWNLOAD_ZIP_FILE]}
        success={null}
        onClose={handleCloseErrMsg}
        languageUX={props.languageUX}
      />

      <Modal open={props.open} closeOnDimmerClick onClose={handleClose}>
        <ModDownloadZipFileUX
          data={data}
          fiscalYear={fiscalYear}
          lotNo={props.arTransaction?.lot_no}
          onClose={handleClose}
          languageUX={props.languageUX}
        />
      </Modal>

      <Modal
        open={!!props.SendClaimDownloadZipFileSequence?.transactionSummary}
        size="small"
        closeOnDimmerClick
        onClose={handleCloseModSummary}
      >
        <div ref={handleRef}>
          <ModSummaryZipFileUX
            formattedSummary={formattedSummary}
            title={summaryTitle}
            // callback
            onClose={handleCloseModSummary}
            languageUX={props.languageUX}
          />
        </div>
      </Modal>
    </>
  );
};

ModDownloadZipFile.displayName = "ModDownloadZipFile";

export default React.memo(ModDownloadZipFile);
