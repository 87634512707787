import React, { useEffect, useMemo, useState } from "react";

// UX
import CardStationSummaryUX from "./CardStationSummaryUX";

// Common
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import ModInfo from "react-lib/apps/common/ModInfo";


// Utils
import { formatDatetime } from "react-lib/utils/dateUtils";
import { useIntl } from "react-intl";

const CardStationSummary: React.FunctionComponent<any> = (props) => {
  const [openModInfo, setOpenModInfo] = useState<any>(null);

  useEffect(() => {
    props.onEvent({ message: "GetReportStationSummary", params: {} });
  }, []);

  const stationSummaryItems = useMemo(() => {
    return (props.reportStationSummary?.receipts || []).map((item: any) => ({
      ...item,
      receipt_date: formatDatetime(item.receipt_date),
    }));
  }, [props.reportStationSummary]);

  const updateReportStationSummary = (params: any) => {
    if (props.reportStationSummary?.receipts?.length) {
      props.onEvent({
        message: "UpdateReportStationSummary",
        params: {
          data: {
            receipts: props.reportStationSummary?.receipts,
            ...params,
          },
          callback: setOpenModInfo,
        },
      });
    }
  };

  const handleRefresh = () => {
    props.onEvent({ message: "GetReportStationSummary", params: {} });
  };

  const handlePrintSummarySheet = () => {
    updateReportStationSummary({
      will_close: false,
      will_print: true,
    });
  };

  const handleOffShift = () => {
    updateReportStationSummary({
      will_close: true,
      will_print: false,
    });
  };

  const handleCloseModSuccess = () => {
    setOpenModInfo(null);
  };

  console.log(props);

  return (
    <div>
      <CardStationSummaryUX
        stationSummaryItems={stationSummaryItems}
        onRefresh={handleRefresh}
        printSummarySheet={handlePrintSummarySheet}
        offShift={handleOffShift}
        languageUX={props.languageUX}
      />
      <ModInfo
        open={!!openModInfo}
        titleColor={openModInfo?.status === "success" ? "green" : "red"}
        titleName={openModInfo?.status === "success" ? openModInfo.message : ""}
        onApprove={handleCloseModSuccess}
        style={{ top: "calc(50vh - 130px)" }}
      >
        {openModInfo?.status === "error" && (
          <ErrorMessage error={openModInfo?.message} />
        )}
      </ModInfo>
    </div>
  );
};

export default React.memo(CardStationSummary);
