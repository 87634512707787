import React from 'react'
import {
   Button,
   Checkbox,
   Dropdown,
   Input,
   Radio,
   TextArea,
} from 'semantic-ui-react'



import styles from './CardOTDiagnosisCerebralPalsyLOW.module.css'

export interface CardOTDiagnosisCerebralPalsyLOWViewDataType {
    OtDiagnosisCerebralLow2: string,
    OtDiagnosisCerebralLow2Disabled: boolean,
    OtDiagnosisCerebralLow3: string,
    OtDiagnosisCerebralLow3Disabled: boolean,
    OtDiagnosisCerebralLow6: boolean,
    OtDiagnosisCerebralLow6Disabled: boolean,
    OtDiagnosisCerebralLow7: boolean,
    OtDiagnosisCerebralLow7Disabled: boolean,
    OtDiagnosisCerebralLow9: boolean,
    OtDiagnosisCerebralLow9Disabled: boolean,
    OtDiagnosisCerebralLow13: boolean,
    OtDiagnosisCerebralLow13Disabled: boolean,
    OtDiagnosisCerebralLow16: boolean,
    OtDiagnosisCerebralLow16Disabled: boolean,
    OtDiagnosisCerebralLow19: boolean,
    OtDiagnosisCerebralLow19Disabled: boolean,
    OtDiagnosisCerebralLow23: boolean,
    OtDiagnosisCerebralLow23Disabled: boolean,
    OtDiagnosisCerebralLow24: boolean,
    OtDiagnosisCerebralLow24Disabled: boolean,
    OtDiagnosisCerebralLow25: string,
    OtDiagnosisCerebralLow25Disabled: boolean,
    OtDiagnosisCerebralLow26: boolean,
    OtDiagnosisCerebralLow26Disabled: boolean,
    OtDiagnosisCerebralLow27: boolean,
    OtDiagnosisCerebralLow27Disabled: boolean,
    OtDiagnosisCerebralLow34: boolean,
    OtDiagnosisCerebralLow34Disabled: boolean,
    OtDiagnosisCerebralLow35: boolean,
    OtDiagnosisCerebralLow35Disabled: boolean,
    OtDiagnosisCerebralLow36: boolean,
    OtDiagnosisCerebralLow36Disabled: boolean,
    OtDiagnosisCerebralLow38: boolean,
    OtDiagnosisCerebralLow38Disabled: boolean,
    OtDiagnosisCerebralLow41: boolean,
    OtDiagnosisCerebralLow41Disabled: boolean,
    OtDiagnosisCerebralLow42: boolean,
    OtDiagnosisCerebralLow42Disabled: boolean,
    OtDiagnosisCerebralLow45: boolean,
    OtDiagnosisCerebralLow45Disabled: boolean,
    OtDiagnosisCerebralLow50: boolean,
    OtDiagnosisCerebralLow50Disabled: boolean,
    OtDiagnosisCerebralLow51: boolean,
    OtDiagnosisCerebralLow51Disabled: boolean,
    OtDiagnosisCerebralLow52: string,
    OtDiagnosisCerebralLow52Disabled: boolean,
    OtDiagnosisCerebralLow53: boolean,
    OtDiagnosisCerebralLow53Disabled: boolean,
    OtDiagnosisCerebralLow54: boolean,
    OtDiagnosisCerebralLow54Disabled: boolean,
    OtDiagnosisCerebralLow57: boolean,
    OtDiagnosisCerebralLow57Disabled: boolean,
    OtDiagnosisCerebralLow58: boolean,
    OtDiagnosisCerebralLow58Disabled: boolean,
    OtDiagnosisCerebralLow64: string,
    OtDiagnosisCerebralLow64Disabled: boolean,
    OtDiagnosisCerebralLow65: boolean,
    OtDiagnosisCerebralLow65Disabled: boolean,
    OtDiagnosisCerebralLow67: string,
    OtDiagnosisCerebralLow67Disabled: boolean,
    OtDiagnosisCerebralLow70: boolean,
    OtDiagnosisCerebralLow70Disabled: boolean,
    CtPremorbid: string,
    CtPremorbidDisabled: boolean,
    CtPremorbidOptions: any,
    OtDiagnosisCerebralLow75: string,
    OtDiagnosisCerebralLow75Disabled: boolean,
    OtDiagnosisCerebralLow77: boolean,
    OtDiagnosisCerebralLow77Disabled: boolean,
    OtDiagnosisCerebralLow79: boolean,
    OtDiagnosisCerebralLow79Disabled: boolean,
    OtDiagnosisCerebralLow84: string,
    OtDiagnosisCerebralLow84Disabled: boolean,
    OtDiagnosisCerebralLow88: string,
    OtDiagnosisCerebralLow88Disabled: boolean,
    OtDiagnosisCerebralLow89: string,
    OtDiagnosisCerebralLow89Disabled: boolean,
    OtDiagnosisCerebralLow91: string,
    OtDiagnosisCerebralLow91Disabled: boolean,
    OtDiagnosisCerebralLow94: boolean,
    OtDiagnosisCerebralLow94Disabled: boolean,
    OtDiagnosisCerebralLow95: boolean,
    OtDiagnosisCerebralLow95Disabled: boolean,
    OtDiagnosisCerebralLow97: boolean,
    OtDiagnosisCerebralLow97Disabled: boolean,
    OtDiagnosisCerebralLow98: string,
    OtDiagnosisCerebralLow98Disabled: boolean,
    OtDiagnosisCerebralLow99: boolean,
    OtDiagnosisCerebralLow99Disabled: boolean,
    OtDiagnosisCerebralLow101: boolean,
    OtDiagnosisCerebralLow101Disabled: boolean,
    OtDiagnosisCerebralLow104: boolean,
    OtDiagnosisCerebralLow104Disabled: boolean,
    OtDiagnosisCerebralLow110: string,
    OtDiagnosisCerebralLow110Disabled: boolean,
    OtDiagnosisCerebralLow111: string,
    OtDiagnosisCerebralLow111Disabled: boolean,
    SaveRequestDisabled: boolean,
}

export const CardOTDiagnosisCerebralPalsyLOWInitialViewData: CardOTDiagnosisCerebralPalsyLOWViewDataType = {
    OtDiagnosisCerebralLow2: "",
    OtDiagnosisCerebralLow2Disabled: false,
    OtDiagnosisCerebralLow3: "",
    OtDiagnosisCerebralLow3Disabled: false,
    OtDiagnosisCerebralLow6: false,
    OtDiagnosisCerebralLow6Disabled: false,
    OtDiagnosisCerebralLow7: false,
    OtDiagnosisCerebralLow7Disabled: false,
    OtDiagnosisCerebralLow9: false,
    OtDiagnosisCerebralLow9Disabled: false,
    OtDiagnosisCerebralLow13: false,
    OtDiagnosisCerebralLow13Disabled: false,
    OtDiagnosisCerebralLow16: false,
    OtDiagnosisCerebralLow16Disabled: false,
    OtDiagnosisCerebralLow19: false,
    OtDiagnosisCerebralLow19Disabled: false,
    OtDiagnosisCerebralLow23: false,
    OtDiagnosisCerebralLow23Disabled: false,
    OtDiagnosisCerebralLow24: false,
    OtDiagnosisCerebralLow24Disabled: false,
    OtDiagnosisCerebralLow25: "",
    OtDiagnosisCerebralLow25Disabled: false,
    OtDiagnosisCerebralLow26: false,
    OtDiagnosisCerebralLow26Disabled: false,
    OtDiagnosisCerebralLow27: false,
    OtDiagnosisCerebralLow27Disabled: false,
    OtDiagnosisCerebralLow34: false,
    OtDiagnosisCerebralLow34Disabled: false,
    OtDiagnosisCerebralLow35: false,
    OtDiagnosisCerebralLow35Disabled: false,
    OtDiagnosisCerebralLow36: false,
    OtDiagnosisCerebralLow36Disabled: false,
    OtDiagnosisCerebralLow38: false,
    OtDiagnosisCerebralLow38Disabled: false,
    OtDiagnosisCerebralLow41: false,
    OtDiagnosisCerebralLow41Disabled: false,
    OtDiagnosisCerebralLow42: false,
    OtDiagnosisCerebralLow42Disabled: false,
    OtDiagnosisCerebralLow45: false,
    OtDiagnosisCerebralLow45Disabled: false,
    OtDiagnosisCerebralLow50: false,
    OtDiagnosisCerebralLow50Disabled: false,
    OtDiagnosisCerebralLow51: false,
    OtDiagnosisCerebralLow51Disabled: false,
    OtDiagnosisCerebralLow52: "",
    OtDiagnosisCerebralLow52Disabled: false,
    OtDiagnosisCerebralLow53: false,
    OtDiagnosisCerebralLow53Disabled: false,
    OtDiagnosisCerebralLow54: false,
    OtDiagnosisCerebralLow54Disabled: false,
    OtDiagnosisCerebralLow57: false,
    OtDiagnosisCerebralLow57Disabled: false,
    OtDiagnosisCerebralLow58: false,
    OtDiagnosisCerebralLow58Disabled: false,
    OtDiagnosisCerebralLow64: "",
    OtDiagnosisCerebralLow64Disabled: false,
    OtDiagnosisCerebralLow65: false,
    OtDiagnosisCerebralLow65Disabled: false,
    OtDiagnosisCerebralLow67: "",
    OtDiagnosisCerebralLow67Disabled: false,
    OtDiagnosisCerebralLow70: false,
    OtDiagnosisCerebralLow70Disabled: false,
    CtPremorbid: "",
    CtPremorbidDisabled: false,
    CtPremorbidOptions: [{key: 0, value: "A", text: "A"}],
    OtDiagnosisCerebralLow75: "",
    OtDiagnosisCerebralLow75Disabled: false,
    OtDiagnosisCerebralLow77: false,
    OtDiagnosisCerebralLow77Disabled: false,
    OtDiagnosisCerebralLow79: false,
    OtDiagnosisCerebralLow79Disabled: false,
    OtDiagnosisCerebralLow84: "",
    OtDiagnosisCerebralLow84Disabled: false,
    OtDiagnosisCerebralLow88: "",
    OtDiagnosisCerebralLow88Disabled: false,
    OtDiagnosisCerebralLow89: "",
    OtDiagnosisCerebralLow89Disabled: false,
    OtDiagnosisCerebralLow91: "",
    OtDiagnosisCerebralLow91Disabled: false,
    OtDiagnosisCerebralLow94: false,
    OtDiagnosisCerebralLow94Disabled: false,
    OtDiagnosisCerebralLow95: false,
    OtDiagnosisCerebralLow95Disabled: false,
    OtDiagnosisCerebralLow97: false,
    OtDiagnosisCerebralLow97Disabled: false,
    OtDiagnosisCerebralLow98: "",
    OtDiagnosisCerebralLow98Disabled: false,
    OtDiagnosisCerebralLow99: false,
    OtDiagnosisCerebralLow99Disabled: false,
    OtDiagnosisCerebralLow101: false,
    OtDiagnosisCerebralLow101Disabled: false,
    OtDiagnosisCerebralLow104: false,
    OtDiagnosisCerebralLow104Disabled: false,
    OtDiagnosisCerebralLow110: "",
    OtDiagnosisCerebralLow110Disabled: false,
    OtDiagnosisCerebralLow111: "",
    OtDiagnosisCerebralLow111Disabled: false,
    SaveRequestDisabled: false,
}

const Sub = (props: any) => {
    const children = React.Children.toArray(props.children)
    return (
        <div className={styles.container}>
            <div className={styles.OtDiagnosisCerebralLow1}>
                <div
                    style={{background: "#F3F3F3", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisCerebralLow2}>
                <Input
                    value={props.viewData.OtDiagnosisCerebralLow2}
                    disabled={ props.viewData.OtDiagnosisCerebralLow2Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTDiagnosisCerebralPalsyLOW',
                            name: 'OtDiagnosisCerebralLow2',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtDiagnosisCerebralLow3}>
                <Input
                    value={props.viewData.OtDiagnosisCerebralLow3}
                    disabled={ props.viewData.OtDiagnosisCerebralLow3Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTDiagnosisCerebralPalsyLOW',
                            name: 'OtDiagnosisCerebralLow3',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtDiagnosisCerebralLow4}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Chief complaint*</strong></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisCerebralLow5}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Underlying disease</strong></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisCerebralLow6}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisCerebralLow6}
                    disabled={ props.viewData.OtDiagnosisCerebralLow6Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisCerebralPalsyLOW",
                                name: "OtDiagnosisCerebralLow6"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisCerebralLow7}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisCerebralLow7}
                    disabled={ props.viewData.OtDiagnosisCerebralLow7Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisCerebralPalsyLOW",
                                name: "OtDiagnosisCerebralLow7"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisCerebralLow8}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Consult for*</strong></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisCerebralLow9}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisCerebralLow9}
                    disabled={ props.viewData.OtDiagnosisCerebralLow9Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisCerebralPalsyLOW",
                                name: "OtDiagnosisCerebralLow9"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisCerebralLow10}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Developmental Stimulation</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisCerebralLow13}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisCerebralLow13}
                    disabled={ props.viewData.OtDiagnosisCerebralLow13Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisCerebralPalsyLOW",
                                name: "OtDiagnosisCerebralLow13"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisCerebralLow14}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Kinetic activites</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisCerebralLow15}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>ADL evaluation &amp; training</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisCerebralLow16}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisCerebralLow16}
                    disabled={ props.viewData.OtDiagnosisCerebralLow16Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisCerebralPalsyLOW",
                                name: "OtDiagnosisCerebralLow16"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisCerebralLow18}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Cognitive/Perceptual evaluation &amp; training</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisCerebralLow19}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisCerebralLow19}
                    disabled={ props.viewData.OtDiagnosisCerebralLow19Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisCerebralPalsyLOW",
                                name: "OtDiagnosisCerebralLow19"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisCerebralLow21}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Adaptive device</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisCerebralLow22}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Multi-sensory room</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisCerebralLow23}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisCerebralLow23}
                    disabled={ props.viewData.OtDiagnosisCerebralLow23Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisCerebralPalsyLOW",
                                name: "OtDiagnosisCerebralLow23"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisCerebralLow24}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisCerebralLow24}
                    disabled={ props.viewData.OtDiagnosisCerebralLow24Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisCerebralPalsyLOW",
                                name: "OtDiagnosisCerebralLow24"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisCerebralLow25}>
                <Input
                    value={props.viewData.OtDiagnosisCerebralLow25}
                    disabled={ props.viewData.OtDiagnosisCerebralLow25Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTDiagnosisCerebralPalsyLOW',
                            name: 'OtDiagnosisCerebralLow25',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtDiagnosisCerebralLow26}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisCerebralLow26}
                    disabled={ props.viewData.OtDiagnosisCerebralLow26Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisCerebralPalsyLOW",
                                name: "OtDiagnosisCerebralLow26"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisCerebralLow27}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisCerebralLow27}
                    disabled={ props.viewData.OtDiagnosisCerebralLow27Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisCerebralPalsyLOW",
                                name: "OtDiagnosisCerebralLow27"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisCerebralLow28}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Swallowing evaluation &amp; training</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisCerebralLow29}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Hand orthosis/splint</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisCerebralLow32}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Home program</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisCerebralLow33}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Other</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisCerebralLow34}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisCerebralLow34}
                    disabled={ props.viewData.OtDiagnosisCerebralLow34Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisCerebralPalsyLOW",
                                name: "OtDiagnosisCerebralLow34"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisCerebralLow35}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisCerebralLow35}
                    disabled={ props.viewData.OtDiagnosisCerebralLow35Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisCerebralPalsyLOW",
                                name: "OtDiagnosisCerebralLow35"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisCerebralLow36}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisCerebralLow36}
                    disabled={ props.viewData.OtDiagnosisCerebralLow36Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisCerebralPalsyLOW",
                                name: "OtDiagnosisCerebralLow36"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisCerebralLow37}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Precaution*</strong></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisCerebralLow38}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisCerebralLow38}
                    disabled={ props.viewData.OtDiagnosisCerebralLow38Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisCerebralPalsyLOW",
                                name: "OtDiagnosisCerebralLow38"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisCerebralLow39}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>None</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisCerebralLow40}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Infectious precaution</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisCerebralLow41}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisCerebralLow41}
                    disabled={ props.viewData.OtDiagnosisCerebralLow41Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisCerebralPalsyLOW",
                                name: "OtDiagnosisCerebralLow41"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisCerebralLow42}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisCerebralLow42}
                    disabled={ props.viewData.OtDiagnosisCerebralLow42Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisCerebralPalsyLOW",
                                name: "OtDiagnosisCerebralLow42"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisCerebralLow43}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Malignancy</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisCerebralLow44}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Vascular disorder</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisCerebralLow45}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisCerebralLow45}
                    disabled={ props.viewData.OtDiagnosisCerebralLow45Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisCerebralPalsyLOW",
                                name: "OtDiagnosisCerebralLow45"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisCerebralLow46}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Internel Instrument</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisCerebralLow48}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Osteoporosis</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisCerebralLow49}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Pregnancy</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisCerebralLow50}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisCerebralLow50}
                    disabled={ props.viewData.OtDiagnosisCerebralLow50Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisCerebralPalsyLOW",
                                name: "OtDiagnosisCerebralLow50"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisCerebralLow51}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisCerebralLow51}
                    disabled={ props.viewData.OtDiagnosisCerebralLow51Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisCerebralPalsyLOW",
                                name: "OtDiagnosisCerebralLow51"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisCerebralLow52}>
                <Input
                    value={props.viewData.OtDiagnosisCerebralLow52}
                    disabled={ props.viewData.OtDiagnosisCerebralLow52Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTDiagnosisCerebralPalsyLOW',
                            name: 'OtDiagnosisCerebralLow52',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtDiagnosisCerebralLow53}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisCerebralLow53}
                    disabled={ props.viewData.OtDiagnosisCerebralLow53Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisCerebralPalsyLOW",
                                name: "OtDiagnosisCerebralLow53"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisCerebralLow54}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisCerebralLow54}
                    disabled={ props.viewData.OtDiagnosisCerebralLow54Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisCerebralPalsyLOW",
                                name: "OtDiagnosisCerebralLow54"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisCerebralLow55}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Hypertension</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisCerebralLow56}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Falling</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisCerebralLow57}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisCerebralLow57}
                    disabled={ props.viewData.OtDiagnosisCerebralLow57Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisCerebralPalsyLOW",
                                name: "OtDiagnosisCerebralLow57"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisCerebralLow58}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisCerebralLow58}
                    disabled={ props.viewData.OtDiagnosisCerebralLow58Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisCerebralPalsyLOW",
                                name: "OtDiagnosisCerebralLow58"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisCerebralLow59}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Diabetes mellitus</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisCerebralLow61}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Fracture</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisCerebralLow62}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Aspiration</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisCerebralLow63}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Seizure</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisCerebralLow64}>
                <Input
                    value={props.viewData.OtDiagnosisCerebralLow64}
                    disabled={ props.viewData.OtDiagnosisCerebralLow64Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTDiagnosisCerebralPalsyLOW',
                            name: 'OtDiagnosisCerebralLow64',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtDiagnosisCerebralLow65}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisCerebralLow65}
                    disabled={ props.viewData.OtDiagnosisCerebralLow65Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisCerebralPalsyLOW",
                                name: "OtDiagnosisCerebralLow65"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisCerebralLow66}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Other</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisCerebralLow67}>
                <Input
                    value={props.viewData.OtDiagnosisCerebralLow67}
                    disabled={ props.viewData.OtDiagnosisCerebralLow67Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTDiagnosisCerebralPalsyLOW',
                            name: 'OtDiagnosisCerebralLow67',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtDiagnosisCerebralLow68}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Functional Limitation*</strong></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisCerebralLow70}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisCerebralLow70}
                    disabled={ props.viewData.OtDiagnosisCerebralLow70Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisCerebralPalsyLOW",
                                name: "OtDiagnosisCerebralLow70"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisCerebralLow71}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>None</p>
                </div>
            </div>
            <div className={styles.CtPremorbid}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtPremorbid}
                    disabled={ props.viewData.CtPremorbidDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardOTDiagnosisCerebralPalsyLOW",
                                name: "CtPremorbid",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtPremorbidOptions}
                />
            </div>
            <div className={styles.OtDiagnosisCerebralLow75}>
                <Input
                    value={props.viewData.OtDiagnosisCerebralLow75}
                    disabled={ props.viewData.OtDiagnosisCerebralLow75Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTDiagnosisCerebralPalsyLOW',
                            name: 'OtDiagnosisCerebralLow75',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtDiagnosisCerebralLow76}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Premorbid</strong></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisCerebralLow77}>
                <Radio 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisCerebralLow77}
                    disabled={ props.viewData.OtDiagnosisCerebralLow77Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "radio",
                            params: {
                                view: "CardOTDiagnosisCerebralPalsyLOW",
                                name: "OtDiagnosisCerebralLow77"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisCerebralLow78}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Family Status</strong></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisCerebralLow79}>
                <Radio 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisCerebralLow79}
                    disabled={ props.viewData.OtDiagnosisCerebralLow79Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "radio",
                            params: {
                                view: "CardOTDiagnosisCerebralPalsyLOW",
                                name: "OtDiagnosisCerebralLow79"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisCerebralLow82}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Caregiver</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisCerebralLow83}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Non-Caregiver</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisCerebralLow84}>
                <TextArea
                    value={props.viewData.OtDiagnosisCerebralLow84}
                    disabled={ props.viewData.OtDiagnosisCerebralLow84Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTDiagnosisCerebralPalsyLOW',
                            name: 'OtDiagnosisCerebralLow84',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </TextArea>
            </div>
            <div className={styles.OtDiagnosisCerebralLow85}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Present and Past History</strong></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisCerebralLow88}>
                <Input
                    value={props.viewData.OtDiagnosisCerebralLow88}
                    disabled={ props.viewData.OtDiagnosisCerebralLow88Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTDiagnosisCerebralPalsyLOW',
                            name: 'OtDiagnosisCerebralLow88',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtDiagnosisCerebralLow89}>
                <Input
                    value={props.viewData.OtDiagnosisCerebralLow89}
                    disabled={ props.viewData.OtDiagnosisCerebralLow89Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTDiagnosisCerebralPalsyLOW',
                            name: 'OtDiagnosisCerebralLow89',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtDiagnosisCerebralLow90}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Nickname</strong></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisCerebralLow91}>
                <Input
                    value={props.viewData.OtDiagnosisCerebralLow91}
                    disabled={ props.viewData.OtDiagnosisCerebralLow91Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTDiagnosisCerebralPalsyLOW',
                            name: 'OtDiagnosisCerebralLow91',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtDiagnosisCerebralLow92}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>School</strong></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisCerebralLow93}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Grade</strong></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisCerebralLow94}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisCerebralLow94}
                    disabled={ props.viewData.OtDiagnosisCerebralLow94Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisCerebralPalsyLOW",
                                name: "OtDiagnosisCerebralLow94"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisCerebralLow95}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisCerebralLow95}
                    disabled={ props.viewData.OtDiagnosisCerebralLow95Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisCerebralPalsyLOW",
                                name: "OtDiagnosisCerebralLow95"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisCerebralLow96}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Other Special treatment</strong></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisCerebralLow97}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisCerebralLow97}
                    disabled={ props.viewData.OtDiagnosisCerebralLow97Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisCerebralPalsyLOW",
                                name: "OtDiagnosisCerebralLow97"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisCerebralLow98}>
                <Input
                    value={props.viewData.OtDiagnosisCerebralLow98}
                    disabled={ props.viewData.OtDiagnosisCerebralLow98Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTDiagnosisCerebralPalsyLOW',
                            name: 'OtDiagnosisCerebralLow98',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtDiagnosisCerebralLow99}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisCerebralLow99}
                    disabled={ props.viewData.OtDiagnosisCerebralLow99Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisCerebralPalsyLOW",
                                name: "OtDiagnosisCerebralLow99"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisCerebralLow100}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>None</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisCerebralLow101}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisCerebralLow101}
                    disabled={ props.viewData.OtDiagnosisCerebralLow101Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisCerebralPalsyLOW",
                                name: "OtDiagnosisCerebralLow101"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisCerebralLow102}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Speech therapy</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisCerebralLow103}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Physical therapy</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisCerebralLow104}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtDiagnosisCerebralLow104}
                    disabled={ props.viewData.OtDiagnosisCerebralLow104Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardOTDiagnosisCerebralPalsyLOW",
                                name: "OtDiagnosisCerebralLow104"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtDiagnosisCerebralLow107}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Psychotherapy</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisCerebralLow108}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Special education</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisCerebralLow109}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Other</p>
                </div>
            </div>
            <div className={styles.OtDiagnosisCerebralLow110}>
                <Input
                    value={props.viewData.OtDiagnosisCerebralLow110}
                    disabled={ props.viewData.OtDiagnosisCerebralLow110Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTDiagnosisCerebralPalsyLOW',
                            name: 'OtDiagnosisCerebralLow110',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtDiagnosisCerebralLow111}>
                <Input
                    value={props.viewData.OtDiagnosisCerebralLow111}
                    disabled={ props.viewData.OtDiagnosisCerebralLow111Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardOTDiagnosisCerebralPalsyLOW',
                            name: 'OtDiagnosisCerebralLow111',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtDiagnosisCerebralLow112}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Parent's name</strong></p>
                </div>
            </div>
            <div className={styles.OtDiagnosisCerebralLow114}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Related</strong></p>
                </div>
            </div>
            <div className={styles.SaveRequest}>
                <Button
                    disabled={ props.viewData.SaveRequestDisabled }
                    onClick={(e) => { props.onEvent({
                        message: 'clickButton',
                        params: {
                            view: 'CardOTDiagnosisCerebralPalsyLOW',
                            name: 'SaveRequest'
                        }
                    })}}
                    color="green"
                    style={{height: "100%", width: "100%"}}
                >
                    Save
                </Button>
            </div>
        </div>
    )
}

export default Sub

const getTsName = (cssClass: string) => {
    return cssClass.split('-').map((s) => (
        s.charAt(0).toUpperCase() + s.slice(1)
    )).join('')
}
