import WasmController from 'react-lib/frameworks/WasmController';

export type State = 
  {
  }

export const StateInitial: State = 
  {
  }

export type Event = 
  { message: "getSearchQueue", params: any }
  |  { message: "getANCRecordList", params: any }
  |  { message: "getPreviousPregnancy", params: any }
  |  { message: "getANCSummary", params: any }
  |  { message: "getANCItem", params: any }
  |  { message: "getANCLab", params: any }
  |  { message: "getFetalLie", params: any }
  |  { message: "postLabInvestigation", params: any }
  |  { message: "getLRLab", params: any }
  |  { message: "postSaveANCRecord", params: any }
  |  { message: "putLabInvestigation", params: any }
  |  { message: "getBirthCertificateApplication", params: any }
  |  { message: "postBirthCertificateApplication", params: any }
  |  { message: "putBirthCertificateApplication", params: any }
  |  { message: "putPrintBirthCertificateApplication", params: any }
  |  { message: "getObstetricBasicInfo", params: any }
  |  { message: "postObstetricBasicInfo", params: any }
  |  { message: "putObstetricBasicInfo", params: any }
  |  { message: "putPrintObstetric", params: any }
  |  { message: "getObstetricLabour", params: any }
  |  { message: "postObstetricLabour", params: any }
  |  { message: "putObstetricLabour", params: any }
  |  { message: "getObstetricInfant", params: any }
  |  { message: "postObstetricInfant", params: any }
  |  { message: "putObstetricInfant", params: any }
  |  { message: "getObstetricPostLabor", params: any }
  |  { message: "postObstetricPostLabour", params: any }
  |  { message: "putObstetricPostLabour", params: any }
  |  { message: "getEDC", params: any }
  |  { message: "postNewborn", params: any }
  |  { message: "getCheckOutChoice", params: any }
  |  { message: "putLRCheckOut", params: any }
  |  { message: "printANCRecordByEncounter", params: any }

export type Data = 
  {
    division?: number,
  }

export const DataInitial = 
  {
  }

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any 

export const getSearchQueue: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, params = _b.params;
//            return __awaiter(_this, void 0, void 0, function () {
//                var searchQueue, searchQueueError, numberOfPage, lrmService, _c, error, response;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            searchQueue = [];
//                            searchQueueError = null;
//                            numberOfPage = null;
//                            lrmService = new LRMService_1.default();
//                            return [4 /*yield*/, utils_1.to(lrmService.getSearchQueue({ params: params }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1];
//                            if (response) {
//                                numberOfPage = Math.ceil(response.total / params.limit);
//                                searchQueue = response.items;
//                            }
//                            else {
//                                searchQueueError = error;
//                            }
//                            return [2 /*return*/, [searchQueue, searchQueueError, numberOfPage]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var params = _a.params;
//        return this.client
//            .get(apis_1.LRM.QUEUE, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/LRM/queue/
}

export const getANCRecordList: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, episodeId = _b.episodeId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, ancRecord, ancRecordError, lrmService, _c, error, response;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            ancRecord = [];
//                            ancRecordError = null;
//                            lrmService = new LRMService_1.default();
//                            if (episodeId) {
//                                params.episode = episodeId;
//                            }
//                            return [4 /*yield*/, utils_1.to(lrmService.getANCRecordList(params))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1];
//                            if (response) {
//                                ancRecord = response.items;
//                            }
//                            else {
//                                ancRecordError = error;
//                            }
//                            return [2 /*return*/, [ancRecord, ancRecordError]];
//                    }
//                });
//            });
//        }
//function (params) {
//        return this.client
//            .get(apis_1.LRM.ANC_RECORD_LIST, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/LRM/anc-record-list/
}

export const getPreviousPregnancy: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, ancRecordId = _b.ancRecordId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, previousPregnancy, previousPregnancyError, lrmService, _c, error, response;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            previousPregnancy = [];
//                            previousPregnancyError = null;
//                            lrmService = new LRMService_1.default();
//                            if (ancRecordId) {
//                                params.anc_record = ancRecordId;
//                            }
//                            return [4 /*yield*/, utils_1.to(lrmService.getPreviousPregnancy(params))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1];
//                            if (response) {
//                                previousPregnancy = response.items;
//                            }
//                            else {
//                                previousPregnancyError = error;
//                            }
//                            return [2 /*return*/, [previousPregnancy, previousPregnancyError]];
//                    }
//                });
//            });
//        }
//function (params) {
//        return this.client
//            .get(apis_1.LRM.PREVIOUS_PREGNANCY, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/LRM/previous-pregnancy/
}

export const getANCSummary: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, ancSummaryId = _b.ancSummaryId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, ancSummary, ancSummaryError, lrmService, _c, error, response;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            ancSummary = [];
//                            ancSummaryError = null;
//                            lrmService = new LRMService_1.default();
//                            return [4 /*yield*/, utils_1.to(lrmService.getANCSummary({ ancSummaryId: ancSummaryId, params: params }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1];
//                            if (response) {
//                                ancSummary = response;
//                            }
//                            else {
//                                ancSummaryError = error;
//                            }
//                            return [2 /*return*/, [ancSummary, ancSummaryError]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, ancSummaryId = _b.ancSummaryId, params = _b.params;
//        var url = apis_1.LRM.ANC_SUMMARY({ anc_summary_id: ancSummaryId });
//        return this.client
//            .get(url, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/LRM/anc-summary/' +
//((__t = (anc_summary_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const getANCItem: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, ancRecordId = _b.ancRecordId, limit = _b.limit, offset = _b.offset;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, ancItem, ancItemError, lrmService, _c, error, response;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            ancItem = [];
//                            ancItemError = null;
//                            lrmService = new LRMService_1.default();
//                            if (ancRecordId) {
//                                params.anc_record = ancRecordId;
//                            }
//                            if (limit) {
//                                params.limit = limit;
//                            }
//                            if (offset) {
//                                params.offset = offset;
//                            }
//                            return [4 /*yield*/, utils_1.to(lrmService.getANCItem({ params: params }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1];
//                            if (response) {
//                                ancItem = response;
//                            }
//                            else {
//                                ancItemError = error;
//                            }
//                            return [2 /*return*/, [ancItem, ancItemError]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params;
//        return this.client
//            .get(apis_1.LRM.ANC_ITEM, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/LRM/anc-item/
}

export const getANCLab: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, ancRecordId = _b.ancRecordId, ancLabId = _b.ancLabId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, ancLab, ancLabError, lrmService, _c, error, response;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            if (!ancLabId) {
//                                // will not allow to fetch if there is no ancLabId
//                                // since ancLabId is sub-resources
//                                return [2 /*return*/];
//                            }
//                            params = {};
//                            ancLab = [];
//                            ancLabError = null;
//                            lrmService = new LRMService_1.default();
//                            if (ancRecordId) {
//                                params.anc_record = ancRecordId;
//                            }
//                            return [4 /*yield*/, utils_1.to(lrmService.getANCLab({ ancLabId: ancLabId, params: params }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1];
//                            if (response) {
//                                ancLab = response.items;
//                            }
//                            else {
//                                ancLabError = error;
//                            }
//                            return [2 /*return*/, [ancLab, ancLabError]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, ancLabId = _b.ancLabId, params = _b.params;
//        var url = apis_1.LRM.ANC_LAB({ anc_lab_id: ancLabId });
//        return this.client
//            .get(url, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/LRM/anc-lab/' +
//((__t = (anc_lab_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const getFetalLie: Handler = async (controller, {}) => {
//function () { return __awaiter(_this, void 0, void 0, function () {
//            var params, fetalLie, fetalLieError, lrmService, _a, error, response;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        params = {};
//                        fetalLie = [];
//                        fetalLieError = null;
//                        lrmService = new LRMService_1.default();
//                        return [4 /*yield*/, utils_1.to(lrmService.getFetalLie({ params: params }))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1];
//                        if (response) {
//                            fetalLie = response.items;
//                        }
//                        else {
//                            fetalLieError = error;
//                        }
//                        return [2 /*return*/, [fetalLie, fetalLieError]];
//                }
//            });
//        }); }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params;
//        return this.client
//            .get(apis_1.LRM.FETAL_LIE, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/LRM/fetal-lie/
}

export const postLabInvestigation: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, episodeId = _b.episodeId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var data, labInvestigation, labInvestigationError, lrmService, _c, error, response;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            data = {};
//                            labInvestigation = [];
//                            labInvestigationError = null;
//                            lrmService = new LRMService_1.default();
//                            if (episodeId) {
//                                data.episode_id = episodeId;
//                            }
//                            return [4 /*yield*/, utils_1.to(lrmService.postLabInvestigation({ data: data }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1];
//                            if (response) {
//                                labInvestigation = response;
//                            }
//                            else {
//                                labInvestigationError = error;
//                            }
//                            return [2 /*return*/, [labInvestigation, labInvestigationError]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params, data = _b.data;
//        return this.client
//            .post(apis_1.LRM.LAB_INVESTIGATION_FETCH, data, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/LRM/lab-investigation-fetch/
}

export const getLRLab: Handler = async (controller, {}) => {
//function () { return __awaiter(_this, void 0, void 0, function () {
//            var params, LRLab, LRLabError, lrmService, _a, error, response;
//            return __generator(this, function (_b) {
//                switch (_b.label) {
//                    case 0:
//                        params = {};
//                        LRLab = [];
//                        LRLabError = null;
//                        lrmService = new LRMService_1.default();
//                        return [4 /*yield*/, utils_1.to(lrmService.getLRLab({ params: params }))];
//                    case 1:
//                        _a = _b.sent(), error = _a[0], response = _a[1];
//                        if (response) {
//                            LRLab = response.items;
//                        }
//                        else {
//                            LRLabError = error;
//                        }
//                        return [2 /*return*/, [LRLab, LRLabError]];
//                }
//            });
//        }); }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params;
//        return this.client
//            .get(apis_1.LRM.LR_LAB, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/LRM/lr-lab/
}

export const postSaveANCRecord: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, saveData = _b.saveData;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, saveANCRecord, saveANCRecordError, lrmService, _c, error, response;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            saveANCRecord = [];
//                            saveANCRecordError = null;
//                            lrmService = new LRMService_1.default();
//                            return [4 /*yield*/, utils_1.to(lrmService.postANCRecord({ data: saveData }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1];
//                            if (response) {
//                                saveANCRecord = response.items;
//                            }
//                            else {
//                                saveANCRecordError = error;
//                            }
//                            return [2 /*return*/, [saveANCRecord, saveANCRecordError]];
//                    }
//                });
//            });
//        }
//
//----> Name mismatch between manager and service
}

export const putLabInvestigation: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, labInvestigationId = _b.labInvestigationId, labInvestigation = _b.labInvestigation;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, plLabInvestigation, labInvestigationError, lrmService, _c, error, response;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            plLabInvestigation = [];
//                            labInvestigationError = null;
//                            lrmService = new LRMService_1.default();
//                            return [4 /*yield*/, utils_1.to(lrmService.putLabInvestigation({
//                                    labInvestigationId: labInvestigationId,
//                                    data: labInvestigation
//                                }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1];
//                            if (response) {
//                                labInvestigation = response.items;
//                            }
//                            else {
//                                labInvestigationError = error;
//                            }
//                            return [2 /*return*/, [labInvestigation, labInvestigationError]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, labInvestigationId = _b.labInvestigationId, params = _b.params, data = _b.data;
//        var url = apis_1.LRM.LAB_INVESTIGATION_DETAIL({
//            investidation_id: labInvestigationId
//        });
//        return this.client
//            .put(url, data, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/LRM/lab-investigation/' +
//((__t = (investidation_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const getBirthCertificateApplication: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, episodeId = _b.episodeId, encounterId = _b.encounterId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, birthCertificate, birthCertificateError, lrmService, _c, error, response;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            birthCertificate = [];
//                            birthCertificateError = null;
//                            lrmService = new LRMService_1.default();
//                            if (episodeId) {
//                                params.episode = episodeId;
//                            }
//                            if (encounterId) {
//                                params.encounter = encounterId;
//                            }
//                            return [4 /*yield*/, utils_1.to(lrmService.getBirthCertificateApplication({ params: params }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1];
//                            if (response) {
//                                birthCertificate = response.items;
//                            }
//                            else {
//                                birthCertificateError = error;
//                            }
//                            return [2 /*return*/, [birthCertificate, birthCertificateError]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var params = _a.params;
//        return this.client
//            .get(apis_1.LRM.BIRTH_CERTIFICATE_APPLICATION, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/LRM/birth-certificate-application/
}

export const postBirthCertificateApplication: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, data = _b.data;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, saveBirthCertificateApplication, saveBirthCertificateApplicationError, lrmService, _c, error, response;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            saveBirthCertificateApplication = [];
//                            saveBirthCertificateApplicationError = null;
//                            lrmService = new LRMService_1.default();
//                            return [4 /*yield*/, utils_1.to(lrmService.postBirthCertificateApplication({ data: data }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1];
//                            if (response) {
//                                saveBirthCertificateApplication = response.items;
//                            }
//                            else {
//                                saveBirthCertificateApplicationError = error;
//                            }
//                            return [2 /*return*/, [
//                                    saveBirthCertificateApplication,
//                                    saveBirthCertificateApplicationError
//                                ]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, data = _b.data, params = _b.params;
//        return this.client
//            .post(apis_1.LRM.BIRTH_CERTIFICATE_APPLICATION, data, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/LRM/birth-certificate-application/
}

export const putBirthCertificateApplication: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, data = _b.data, birthCerAppId = _b.birthCerAppId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, editBirthCertificateApplication, editBirthCertificateApplicationError, lrmService, _c, error, response;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            editBirthCertificateApplication = [];
//                            editBirthCertificateApplicationError = null;
//                            lrmService = new LRMService_1.default();
//                            return [4 /*yield*/, utils_1.to(lrmService.putBirthCertificateApplication({ data: data, birthCerAppId: birthCerAppId }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1];
//                            if (response) {
//                                editBirthCertificateApplication = response.items;
//                            }
//                            else {
//                                editBirthCertificateApplicationError = error;
//                            }
//                            return [2 /*return*/, [
//                                    editBirthCertificateApplication,
//                                    editBirthCertificateApplicationError
//                                ]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, data = _b.data, params = _b.params, birthCerAppId = _b.birthCerAppId;
//        var url = apis_1.LRM.BIRTH_CERTIFICATE_APPLICATION_DETAIL({
//            birth_certification_application_id: birthCerAppId
//        });
//        return this.client
//            .put(url, data, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/LRM/birth-certificate-application/' +
//((__t = (birth_certification_application_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const putPrintBirthCertificateApplication: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, birthCerAppId = _b.birthCerAppId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, printBirthCertificateApplication, printBirthCertificateApplicationError, lrmService, _c, error, response;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            printBirthCertificateApplication = [];
//                            printBirthCertificateApplicationError = null;
//                            lrmService = new LRMService_1.default();
//                            return [4 /*yield*/, utils_1.to(lrmService.putPrintBirthCertificateApplication({ birthCerAppId: birthCerAppId }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1];
//                            if (response) {
//                                printBirthCertificateApplication = response.items;
//                            }
//                            else {
//                                printBirthCertificateApplicationError = error;
//                            }
//                            return [2 /*return*/, [
//                                    printBirthCertificateApplication,
//                                    printBirthCertificateApplicationError
//                                ]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params, data = _b.data, birthCerAppId = _b.birthCerAppId;
//        var url = apis_1.LRM.BIRTH_CERTIFICATE_APPLICATION_PRINT({
//            birth_certification_application_id: birthCerAppId
//        });
//        return this.client
//            .put(url, data, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/LRM/birth-certificate-application/' +
//((__t = (birth_certification_application_id)) == null ? '' : __t) +
//'/print/';
//
//}
//return __p
//}
}

export const getObstetricBasicInfo: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, episodeId = _b.episodeId, encounterId = _b.encounterId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, obstetricBasicInfo, obstetricBasicInfoError, lrmService, _c, error, response;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            obstetricBasicInfo = [];
//                            obstetricBasicInfoError = null;
//                            lrmService = new LRMService_1.default();
//                            if (encounterId) {
//                                params.encounter = encounterId;
//                            }
//                            if (episodeId) {
//                                params.episode = episodeId;
//                            }
//                            return [4 /*yield*/, utils_1.to(lrmService.getObstetricBasicInfo({ params: params }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1];
//                            if (response) {
//                                obstetricBasicInfo = response.items;
//                            }
//                            else {
//                                obstetricBasicInfoError = error;
//                            }
//                            return [2 /*return*/, [obstetricBasicInfo, obstetricBasicInfoError]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params;
//        return this.client
//            .get(apis_1.LRM.OBSTETRIC_BASIC_INFO, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/LRM/obstetric-basic-info/
}

export const postObstetricBasicInfo: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, saveData = _b.saveData;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, obstetricBasicInfo, obstetricBasicInfoError, lrmService, _c, error, response;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            obstetricBasicInfo = [];
//                            obstetricBasicInfoError = null;
//                            lrmService = new LRMService_1.default();
//                            return [4 /*yield*/, utils_1.to(lrmService.postObstetricBasicInfo({ data: saveData }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1];
//                            if (response) {
//                                obstetricBasicInfo = response;
//                            }
//                            else {
//                                obstetricBasicInfoError = error;
//                            }
//                            return [2 /*return*/, [obstetricBasicInfo, obstetricBasicInfoError]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, data = _b.data, params = _b.params;
//        return this.client
//            .post(apis_1.LRM.OBSTETRIC_BASIC_INFO, data, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/LRM/obstetric-basic-info/
}

export const putObstetricBasicInfo: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, saveData = _b.saveData, basicInfoId = _b.basicInfoId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, obstetricBasicInfo, obstetricBasicInfoError, lrmService, _c, error, response;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            obstetricBasicInfo = [];
//                            obstetricBasicInfoError = null;
//                            lrmService = new LRMService_1.default();
//                            return [4 /*yield*/, utils_1.to(lrmService.putObstetricBasicInfo({ data: saveData, basicInfoId: basicInfoId }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1];
//                            if (response) {
//                                obstetricBasicInfo = response;
//                            }
//                            else {
//                                obstetricBasicInfoError = error;
//                            }
//                            return [2 /*return*/, [obstetricBasicInfo, obstetricBasicInfoError]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, data = _b.data, params = _b.params, basicInfoId = _b.basicInfoId;
//        var url = apis_1.LRM.OBSTETRIC_BASIC_INFO_DETAIL({ basic_info_id: basicInfoId });
//        return this.client
//            .put(url, data, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/LRM/obstetric-basic-info/' +
//((__t = (basic_info_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const putPrintObstetric: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, infantId = _b.infantId, episodeId = _b.episodeId, encounterId = _b.encounterId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, printObstetric, printObstetricError, lrmService, _c, error, response;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            printObstetric = [];
//                            printObstetricError = null;
//                            lrmService = new LRMService_1.default();
//                            if (encounterId) {
//                                params.encounter = encounterId;
//                            }
//                            if (episodeId) {
//                                params.episode = episodeId;
//                            }
//                            if (infantId) {
//                                params.infant = infantId;
//                            }
//                            return [4 /*yield*/, utils_1.to(lrmService.putPrintObstetric({ params: params }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1];
//                            if (response) {
//                                printObstetric = response;
//                            }
//                            else {
//                                printObstetricError = error;
//                            }
//                            return [2 /*return*/, [printObstetric, printObstetricError]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, basicInfoId = _b.basicInfoId, data = _b.data, params = _b.params;
//        return this.client
//            .put(apis_1.LRM.OBSTETRIC_PRINT, data, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/LRM/obstetric/print/
}

export const getObstetricLabour: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, episodeId = _b.episodeId, encounterId = _b.encounterId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, obstetricLabour, obstetricLabourError, lrmService, _c, error, response;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            obstetricLabour = [];
//                            obstetricLabourError = null;
//                            lrmService = new LRMService_1.default();
//                            if (episodeId) {
//                                params.episode = episodeId;
//                            }
//                            if (encounterId) {
//                                params.encounter = encounterId;
//                            }
//                            return [4 /*yield*/, utils_1.to(lrmService.getObstetricLabor({ params: params }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1];
//                            if (response) {
//                                obstetricLabour = response.items;
//                            }
//                            else {
//                                obstetricLabourError = error;
//                            }
//                            return [2 /*return*/, [obstetricLabour, obstetricLabourError]];
//                    }
//                });
//            });
//        }
//
//----> Name mismatch between manager and service
}

export const postObstetricLabour: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, saveData = _b.saveData;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, obstetricLabour, obstetricLabourError, lrmService, _c, error, response;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            obstetricLabour = [];
//                            obstetricLabourError = null;
//                            lrmService = new LRMService_1.default();
//                            return [4 /*yield*/, utils_1.to(lrmService.postObstetricLabor({ data: saveData }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1];
//                            if (response) {
//                                obstetricLabour = response;
//                            }
//                            else {
//                                obstetricLabourError = error;
//                            }
//                            return [2 /*return*/, [obstetricLabour, obstetricLabourError]];
//                    }
//                });
//            });
//        }
//
//----> Name mismatch between manager and service
}

export const putObstetricLabour: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, saveData = _b.saveData, labourId = _b.labourId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, obstetricLabour, obstetricLabourError, lrmService, _c, error, response;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            obstetricLabour = [];
//                            obstetricLabourError = null;
//                            lrmService = new LRMService_1.default();
//                            return [4 /*yield*/, utils_1.to(lrmService.putObstetricLabor({ data: saveData, labourId: labourId }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1];
//                            if (response) {
//                                obstetricLabour = response;
//                            }
//                            else {
//                                obstetricLabourError = error;
//                            }
//                            return [2 /*return*/, [obstetricLabour, obstetricLabourError]];
//                    }
//                });
//            });
//        }
//
//----> Name mismatch between manager and service
}

export const getObstetricInfant: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, episodeId = _b.episodeId, encounterId = _b.encounterId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, obstetricInfant, obstetricInfantError, lrmService, _c, error, response;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            obstetricInfant = [];
//                            obstetricInfantError = null;
//                            lrmService = new LRMService_1.default();
//                            if (episodeId) {
//                                params.episode = episodeId;
//                            }
//                            if (encounterId) {
//                                params.encounter = encounterId;
//                            }
//                            return [4 /*yield*/, utils_1.to(lrmService.getObstetricInfant({ params: params }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1];
//                            if (response) {
//                                obstetricInfant = response.items;
//                            }
//                            else {
//                                obstetricInfantError = error;
//                            }
//                            return [2 /*return*/, [obstetricInfant, obstetricInfantError]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, infantId = _b.infantId, params = _b.params;
//        // let url = LRM.OBSTETRIC_INFANT_DETAIL({ infant_id: infantId })
//        return this.client
//            .get(apis_1.LRM.OBSTETRIC_INFANT, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/LRM/obstetric-infant/' +
//((__t = (infant_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
//undefined/apis/LRM/obstetric-infant/
}

export const postObstetricInfant: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, saveData = _b.saveData;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, obstetricInfant, obstetricInfantError, lrmService, _c, error, response;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            obstetricInfant = [];
//                            obstetricInfantError = null;
//                            lrmService = new LRMService_1.default();
//                            return [4 /*yield*/, utils_1.to(lrmService.postObstetricInfant({ data: saveData }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1];
//                            if (response) {
//                                obstetricInfant = response;
//                            }
//                            else {
//                                obstetricInfantError = error;
//                            }
//                            return [2 /*return*/, [obstetricInfant, obstetricInfantError]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, data = _b.data, params = _b.params;
//        return this.client
//            .post(apis_1.LRM.OBSTETRIC_INFANT, data, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/LRM/obstetric-infant/
}

export const putObstetricInfant: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, infantId = _b.infantId, saveData = _b.saveData;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, obstetricInfant, obstetricInfantError, lrmService, _c, error, response;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            obstetricInfant = [];
//                            obstetricInfantError = null;
//                            lrmService = new LRMService_1.default();
//                            return [4 /*yield*/, utils_1.to(lrmService.putObstetricInfant({ infantId: infantId, data: saveData }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1];
//                            if (response) {
//                                obstetricInfant = response;
//                            }
//                            else {
//                                obstetricInfantError = error;
//                            }
//                            return [2 /*return*/, [obstetricInfant, obstetricInfantError]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, infantId = _b.infantId, data = _b.data, params = _b.params;
//        var url = apis_1.LRM.OBSTETRIC_INFANT_DETAIL({ infant_id: infantId });
//        return this.client
//            .put(url, data, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/LRM/obstetric-infant/' +
//((__t = (infant_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const getObstetricPostLabor: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, episodeId = _b.episodeId, encounterId = _b.encounterId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, obstetricPostLabor, obstetricPostLaborError, lrmService, _c, error, response;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            obstetricPostLabor = [];
//                            obstetricPostLaborError = null;
//                            lrmService = new LRMService_1.default();
//                            if (episodeId) {
//                                params.episode = episodeId;
//                            }
//                            if (encounterId) {
//                                params.encounter = encounterId;
//                            }
//                            return [4 /*yield*/, utils_1.to(lrmService.getObstetricPostLabor({ params: params }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1];
//                            if (response) {
//                                obstetricPostLabor = response.items;
//                            }
//                            else {
//                                obstetricPostLaborError = error;
//                            }
//                            return [2 /*return*/, [obstetricPostLabor, obstetricPostLaborError]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, postLabourId = _b.postLabourId, params = _b.params;
//        // let url = LRM.OBSTETRIC_POST_LABOR_DETAIL({ post_labor_id: postLabourId })
//        return this.client
//            .get(apis_1.LRM.OBSTETRIC_POST_LABOUR, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/LRM/obstetric-post-labor/' +
//((__t = (post_labor_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
//undefined/apis/LRM/obstetric-post-labor/
}

export const postObstetricPostLabour: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, saveData = _b.saveData;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, obstetricLabour, obstetricLabourError, lrmService, _c, error, response;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            obstetricLabour = [];
//                            obstetricLabourError = null;
//                            lrmService = new LRMService_1.default();
//                            return [4 /*yield*/, utils_1.to(lrmService.postObstetricPostLabor({ data: saveData }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1];
//                            if (response) {
//                                obstetricLabour = response;
//                            }
//                            else {
//                                obstetricLabourError = error;
//                            }
//                            return [2 /*return*/, [obstetricLabour, obstetricLabourError]];
//                    }
//                });
//            });
//        }
//
//----> Name mismatch between manager and service
}

export const putObstetricPostLabour: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, postLabourId = _b.postLabourId, saveData = _b.saveData;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, obstetricPostLabour, obstetricPostLabourError, lrmService, _c, error, response;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            obstetricPostLabour = [];
//                            obstetricPostLabourError = null;
//                            lrmService = new LRMService_1.default();
//                            return [4 /*yield*/, utils_1.to(lrmService.putObstetricPostLabor({ postLabourId: postLabourId, data: saveData }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1];
//                            if (response) {
//                                obstetricPostLabour = response;
//                            }
//                            else {
//                                obstetricPostLabourError = error;
//                            }
//                            return [2 /*return*/, [obstetricPostLabour, obstetricPostLabourError]];
//                    }
//                });
//            });
//        }
//
//----> Name mismatch between manager and service
}

export const getEDC: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, date = _b.date;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, data, edc, edcError, lrmService, _c, error, response;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            data = {};
//                            edc = [];
//                            edcError = null;
//                            lrmService = new LRMService_1.default();
//                            if (date) {
//                                data.lmp_date = date;
//                            }
//                            return [4 /*yield*/, utils_1.to(lrmService.getEDC({ data: data }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1];
//                            if (response) {
//                                edc = response;
//                            }
//                            else {
//                                edcError = error;
//                            }
//                            return [2 /*return*/, [edc, edcError]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, data = _b.data, params = _b.params;
//        return this.client
//            .post(apis_1.LRM.EDC, data, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/LRM/edc/
}

export const postNewborn: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, encounterId = _b.encounterId, episodeId = _b.episodeId, items = _b.items;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, data, newBorn, newBornError, lrmService, _c, error, response;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            data = {};
//                            newBorn = [];
//                            newBornError = null;
//                            lrmService = new LRMService_1.default();
//                            if (encounterId) {
//                                data.encounter = encounterId;
//                            }
//                            if (episodeId) {
//                                data.episode = episodeId;
//                            }
//                            if (items) {
//                                data.items = items;
//                            }
//                            return [4 /*yield*/, utils_1.to(lrmService.postNewborn({ data: data }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1];
//                            if (response) {
//                                newBorn = response;
//                            }
//                            else {
//                                newBornError = error;
//                            }
//                            return [2 /*return*/, [newBorn, newBornError]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params, data = _b.data;
//        return this.client
//            .post(apis_1.LRM.NEWBORN, data, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/LRM/newborn/
}

export const getCheckOutChoice: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, patientId = _b.patientId;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, checkoutChoices, checkoutChoicesError, lrmService, _c, error, response;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            checkoutChoices = [];
//                            checkoutChoicesError = null;
//                            lrmService = new LRMService_1.default();
//                            if (patientId) {
//                                params.patient = patientId;
//                            }
//                            return [4 /*yield*/, utils_1.to(lrmService.getCheckOutChoice({ params: params }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1];
//                            if (response) {
//                                checkoutChoices = response.items;
//                            }
//                            else {
//                                checkoutChoicesError = error;
//                            }
//                            return [2 /*return*/, [checkoutChoices, checkoutChoicesError]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var _b = _a === void 0 ? {} : _a, params = _b.params;
//        return this.client
//            .get(apis_1.LRM.CHECK_OUT_CHOICE, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
//undefined/apis/LRM/check-out-choice/
}

export const putLRCheckOut: Handler = async (controller, {}) => {
//function (_a) {
//            var _b = _a === void 0 ? {} : _a, action = _b.action, dischargeStatus = _b.dischargeStatus, preDischargeCondition = _b.preDischargeCondition, encounterId = _b.encounterId, oldRoom = _b.oldRoom, cleaning = _b.cleaning, appointment = _b.appointment;
//            return __awaiter(_this, void 0, void 0, function () {
//                var params, data, checkOut, checkOutError, lrmService, _c, error, response;
//                return __generator(this, function (_d) {
//                    switch (_d.label) {
//                        case 0:
//                            params = {};
//                            data = {};
//                            checkOut = [];
//                            checkOutError = null;
//                            lrmService = new LRMService_1.default();
//                            if (action) {
//                                data.action = action;
//                            }
//                            if (dischargeStatus) {
//                                data.discharge_status = dischargeStatus;
//                            }
//                            if (preDischargeCondition) {
//                                data.predischarge_condition = preDischargeCondition;
//                            }
//                            if (oldRoom) {
//                                data.old_room = oldRoom;
//                            }
//                            if (cleaning) {
//                                data.cleaning = cleaning;
//                            }
//                            if (appointment) {
//                                data.appointment = appointment;
//                            }
//                            return [4 /*yield*/, utils_1.to(lrmService.putLRCheckOut({ params: params, data: data, encounterId: encounterId }))];
//                        case 1:
//                            _c = _d.sent(), error = _c[0], response = _c[1];
//                            if (response) {
//                                checkOut = response.items;
//                            }
//                            else {
//                                checkOutError = error;
//                            }
//                            return [2 /*return*/, [checkOut, checkOutError]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var params = _a.params, encounterId = _a.encounterId, data = _a.data;
//        var url = apis_1.LRM.LR_CHECK_OUT({ encounter_id: encounterId });
//        return this.client
//            .put(url, data, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/LRM/lr-check-out/' +
//((__t = (encounter_id)) == null ? '' : __t) +
//'/';
//
//}
//return __p
//}
}

export const printANCRecordByEncounter: Handler = async (controller, {}) => {
//function (_a) {
//            var encounterId = _a.encounterId, pdf = _a.pdf;
//            return __awaiter(_this, void 0, void 0, function () {
//                var data, print, printError, lrmService, _b, error, response;
//                return __generator(this, function (_c) {
//                    switch (_c.label) {
//                        case 0:
//                            data = {};
//                            print = [];
//                            printError = null;
//                            lrmService = new LRMService_1.default();
//                            if (encounterId) {
//                                data.encounter = encounterId;
//                            }
//                            if (pdf) {
//                                data.pdf = pdf;
//                            }
//                            return [4 /*yield*/, utils_1.to(lrmService.printANCRecordByEncounter({ data: data, encounterId: encounterId }))];
//                        case 1:
//                            _b = _c.sent(), error = _b[0], response = _b[1];
//                            if (response) {
//                                print = response;
//                            }
//                            else {
//                                printError = error;
//                            }
//                            return [2 /*return*/, [print, printError]];
//                    }
//                });
//            });
//        }
//function (_a) {
//        var params = _a.params, encounterId = _a.encounterId, data = _a.data;
//        var url = apis_1.LRM.PRINT_ANC_RECORD_BY_ENCOUNTER({ encounter_id: encounterId });
//        return this.client
//            .put(url, data, { params: params })
//            .then(this.handleResponse)
//            .catch(this.throwErrorMessage);
//    }
////function(obj) {
//obj || (obj = {});
//var __t, __p = '';
//with (obj) {
//__p += 'undefined/apis/LRM/anc-record/by-encounter/' +
//((__t = (encounter_id)) == null ? '' : __t) +
//'/print/';
//
//}
//return __p
//}
}
