import React, {
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
  useState,
} from "react";
import { Input, Dimmer, Loader, Button } from "semantic-ui-react";
import PatientSearchBox from "react-lib/apps/common/PatientSearchBox"

import CardCSDiagnosis from "./CardCSDiagnosis";
import CardCSEncounter from "./CardCSEncounter";
import CardCSLab from "./CardCSLab";

import CardCSMedication from "./CardCSMedication";
import CardCSPatientCareFlowSheet from "./CardCSPatientCareFlowSheet";
import CardCSPendingOrder from "./CardCSPendingOrder";

import CardCSProcedureDetail from "./CardCSProcedureDetail";
import CardCSReplyConsult from "./CardCSReplyConsult";


import "./CardChartSummary.scss";
import { useIntl } from "react-intl";

interface CardChartSummaryProps {
  csLoading: boolean;
  patientSearchController: any;
  forcedSelectHN: string;
  crdSearchPatientData: any;
  nationalitiesList: any;
  patientCurrentPage: any;
  patientOldNameData: any;

  patientOldNameLoading: boolean;
  patientListLoading: boolean;
  onSelectPatient: any;

  isNurse: boolean;
  allDoctorList: any;
  allDivisionList: any;
  allLabDivisionList: any;

  handleGetCSPendingOrderList: any;
  handleGetChartSummaryAllList: any;
  handleCSSearchEncounterTable: any;
  handleGetCSDiagnosisList: any;
  handleGetCSLabList: any;

  handleGetCSMedication: any;
  handleGetCSPatientCare: any;
  handleGetCSReplyConsult: any;
  handleGetCSProcedureDetail: any;
  handlePatchDiagnosis: any;

  patientDataAPI: any;
  csLabList: any;
  csEncounterList: any;
  csDiagnosisList: any;
  csPendingOrderList: any;
  csPatientCareList: any;
  csMedicationList: any;
  csReplyConsultList: any;
  csADRData: any;
  csProcedureDetailList:any; 
  ref: any;
}

const CardChartSummary = forwardRef((props: CardChartSummaryProps, ref) => {
  const intl = useIntl();
  const isMounted = useRef(true);
  const csEncounterRef = useRef(null);
  const csLabRef = useRef(null);
  const csMedicationRef = useRef(null);
  const csDiagnosisRef = useRef(null);
  const csProcedureDetailRef = useRef(null);
  const csPendingOrderRef = useRef(null);
  const csReplyConsultRef = useRef(null);
  const csHnSearchRef = useRef(null);

  const [openModADR, setOpenModADR] = useState(false)


  useImperativeHandle(ref, () => ({
    getCSEncounterParams: () => {
      // console.log(" getCSEncounterData");
      const data = csEncounterRef.current.getData();
      console.log(" Get From CSEncoutner ", data);
      return { ...data };
    },
    getLabParams: () => {
      // console.log(" getLabParams");
      const data = csLabRef.current.getData();
      console.log(" Get From getLabParams ", data);
      return { ...data };
    },
    getDiagnosisParams: () => {
      // console.log(" getDiagnosisParams");
      const data = csDiagnosisRef.current.getData();
      console.log(" Get From getDiagnosisParams ", data);
      return { ...data };
    },
    getProcedureParams: () => {
      // console.log(" getProcedureParams");
      const data = csProcedureDetailRef.current.getData();
      console.log(" Get From getProcedureParams ", data);
      return { ...data };
    },
    getPendingOrderParams: () => {
      // console.log(" getPendingOrderParams");
      const data = csPendingOrderRef.current.getData();
      console.log(" Get From getPendingOrderParams ", data);
      return { ...data };
    },
    getReplyConsultParams: () => {
      // console.log(" getReplyConsultParams");
      const data = csReplyConsultRef.current.getData();
      console.log(" Get From getReplyConsultParams ", data);
      return { ...data };
    },
  }));
  useEffect(() => {
    // props.handleGetChartSummaryAllList()
    return () => {
      if (isMounted.current) {
        isMounted.current = false;
      }
    };
  }, []);

  useEffect(() => {
    // console.log("csHnSearchRef.current ", csHnSearchRef.current);
    // console.log("cprops.patientDataAPI", props.patientDataAPI);
    if (csHnSearchRef.current && props.patientDataAPI) {
      // console.log(" setValue");
      csHnSearchRef.current.setValue(props.patientDataAPI.hn);
    }
  }, [props.patientDataAPI]);

  return (
    <>
      <Dimmer active={props.csLoading}>
        <Loader active={props.csLoading}>{intl.formatMessage({ id: "กำลังโหลดข้อมูลผู้ป่วย" })}</Loader>
      </Dimmer>

      {/* <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0px",
        }}
      >
        <div className="chart-patient" style={{ width: "500px" }}>
          <div className="font-bold">HN</div>
          <div>
            <PatientSearchBox
              controller={props.patientSearchController}
              ref={csHnSearchRef}
              // Data
              nationalitiesList={props.nationalitiesList}
              patientList={props.crdSearchPatientData}
              currentPage={props.patientCurrentPage}
              patientOldNameDataList={props.patientOldNameData}
              // Handle
              onSelectPatient={props.onSelectPatient}
              // Data Loading
              patientListLoading={props.patientListLoading}
              patientOldNameLoading={props.patientOldNameLoading}
            />
          </div>
          <div className="font-bold">{intl.formatMessage({ id: "ชื่อ" })}</div>
          <div>
            <Input style={{pointerEvents:"none"}} value={props.patientDataAPI?.full_name}/>
          </div>
          <div className="font-bold">{intl.formatMessage({ id: "วันเกิด" })}</div>
          <div>{props.patientDataAPI?.birthdate}</div>
          <div className="font-bold">{intl.formatMessage({ id: "อายุ" })}</div>
          <div>{props.patientDataAPI?.full_age}</div>
          <div className="font-bold">ADR</div>
          <div>{props.csADRData?.items?.length > 0 ? "มีแพ้ยา" : "ไม่มีข้อมูลแพ้ยา"} </div>
          <div> 
            <Button
              style={{ ...(props.csADRData?.items?.length === 0 && {pointerEvents:"none"}), padding: 3}}
              size="tiny"
              color={ props.csADRData?.items?.length > 0 ? "red" : "teal"}
              icon
              onClick={() => { 
                if (props.csADRData?.items?.length > 0) { 
                  setOpenModADR(true) 
                }
              }}
            >
              <img src={Pills} />
            </Button>
          </div> 
          

        </div>
      </div> */}
      <div className="chart-summary">
        <div className="cs-left">
          <CardCSEncounter
            patientDataAPI={props.patientDataAPI}
            ref={csEncounterRef}
            // selectedIsOPDEncounter={props.selectedIsOPDEncounter}
            // selectedIsIPDEncounter={props.selectedIsIPDEncounter}
            // isOPDEncounter={props.isOPDEncounter}
            // isIPDEncounter={props.isIPDEncounter}
            allDoctorList={props.allDoctorList}
            allDivisionList={props.allDivisionList}
            handleCSSearchEncounterTable={props.handleCSSearchEncounterTable}
            encounterList={props.csEncounterList}
            languageUX={props.languageUX}
          />

          <CardCSLab
            patientDataAPI={props.patientDataAPI}
            handleGetCSLabList={props.handleGetCSLabList}
            csLabList={props.csLabList}
            allLabDivisionList={props.allLabDivisionList}
            ref={csLabRef}
            languageUX={props.languageUX}
          />
        </div>
        <div className="cs-center">
          <CardCSMedication
            patientDataAPI={props.patientDataAPI}
            csMedicationList={props.csMedicationList}
            handleGetCSMedication={props.handleGetCSMedication}
            languageUX={props.languageUX}
          />
          <CardCSDiagnosis
            ref={csDiagnosisRef}
            isNurse={props.isNurse}
            patientDataAPI={props.patientDataAPI}
            csDiagnosisList={props.csDiagnosisList}
            handleGetCSDiagnosisList={props.handleGetCSDiagnosisList}
            handlePatchDiagnosis={props.handlePatchDiagnosis}
            languageUX={props.languageUX}
          />
          <CardCSProcedureDetail 
            ref={csProcedureDetailRef}
            patientDataAPI={props.patientDataAPI} 
            csProcedureDetailList={props.csProcedureDetailList}
            handleGetCSProcedureDetail={props.handleGetCSProcedureDetail}
            languageUX={props.languageUX}
          />
        </div>
        <div className="cs-right">
          <CardCSPendingOrder
            ref={csPendingOrderRef}
            patientDataAPI={props.patientDataAPI}
            csPendingOrderList={props.csPendingOrderList}
            handleGetCSPendingOrderList={props.handleGetCSPendingOrderList}
            languageUX={props.languageUX}
          />
          <CardCSReplyConsult
            ref={csReplyConsultRef}
            patientDataAPI={props.patientDataAPI}
            handleGetCSReplyConsult={props.handleGetCSReplyConsult}
            csReplyConsultList={props.csReplyConsultList}
            languageUX={props.languageUX}
          />
          {props.isNurse ? (
            <CardCSPatientCareFlowSheet
              patientDataAPI={props.patientDataAPI}
              csPatientCareList={props.csPatientCareList}
              handleGetCSPatientCare={props.handleGetCSPatientCare}
              languageUX={props.languageUX}
            />
          ) : null}
        </div>
      </div>
    </>
  );
});

export default CardChartSummary;
