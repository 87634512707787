import FormSymbolImages from "./AddSymbolPrintList";

export default function toPDFMakeData(props: any) {
  const injectImages = (haveHeader: boolean) => {
    let symbolUsage = {};
    if (!haveHeader) {
      symbolUsage = {
        checked: FormSymbolImages.checked,
        unchecked: FormSymbolImages.unchecked,
        squarebox: FormSymbolImages.squarebox,
        checkmark: FormSymbolImages.checkmark,
      };
    }
    return symbolUsage;
  };

  return {
    pageSize: `A4`,
    content: [
      {
        margin: [0, 0, 0, 0],
        preserveLeadingSpaces: true,
        bold: `true`,
        decorationStyle: ``,
        alignment: `center`,
        color: ``,
        decoration: ``,
        width: `auto`,
        decorationColor: ``,
        pageBreak: ``,
        fontSize: `18`,
        text: `การปฏิเสธการรักษา`,
      },
      {
        width: `auto`,
        decoration: ``,
        pageBreak: ``,
        decorationStyle: ``,
        bold: false,
        text: ` `,
        preserveLeadingSpaces: true,
        alignment: `left`,
        margin: [0, 0, 0, 0],
        decorationColor: ``,
        color: ``,
        fontSize: 15,
      },
      {
        columns: [
          {
            decorationStyle: ``,
            decoration: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            text: ``,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            width: 30,
            color: ``,
            pageBreak: ``,
            bold: false,
            fontSize: 15,
          },
          {
            decorationColor: ``,
            pageBreak: ``,
            bold: false,
            alignment: `left`,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            width: `auto`,
            text: `ข้าพเจ้า (นาย/นาง/นางสาว)`,
            color: ``,
            decoration: ``,
            fontSize: 15,
          },
          {
            pageBreak: ``,
            width: 50,
            decorationStyle: ``,
            decoration: ``,
            margin: [0, 0, 0, 0],
            text: ``,
            decorationColor: ``,
            color: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            bold: false,
            fontSize: 15,
          },
          {
            bold: false,
            width: `auto`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            color: ``,
            text: props.items?.formatPatient,
            decorationStyle: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            decoration: ``,
          },
        ],
      },
      {
        columns: [
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            alignment: `left`,
            decorationStyle: ``,
            bold: false,
            pageBreak: ``,
            color: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationColor: ``,
            text: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            width: 5,
          },
          {
            text: `การปฏิเสธการเข้ารับการรักษาในโรงพยาบาล`,
            width: `auto`,
            alignment: `left`,
            fontSize: 15,
            decoration: ``,
            margin: [0, 0, 0, 0],
            color: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            bold: `true`,
            decorationStyle: ``,
            pageBreak: ``,
          },
        ],
      },
      {
        columns: [
          {
            width: 25,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            bold: false,
            fontSize: 15,
            decorationStyle: ``,
            text: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decoration: ``,
            color: ``,
          },
          {
            pageBreak: ``,
            bold: false,
            color: ``,
            alignment: `left`,
            decorationStyle: ``,
            width: `auto`,
            fontSize: 15,
            decoration: ``,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            preserveLeadingSpaces: true,
            text: `ตามที่แพทย์ผู้รับผิดชอบได้แนะนำให้ข้าพเจ้าให้อยู่เพื่อรับการรักษาต่อในโรงพยาบาลครั้งนี้ ข้าพเจ้ามีความประสงค์ที่จะ`,
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            width: 25,
            decoration: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            bold: false,
            decorationStyle: ``,
            text: ``,
            alignment: `left`,
            color: ``,
          },
          {
            alignment: `left`,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            width: `auto`,
            preserveLeadingSpaces: true,
            decoration: ``,
            bold: false,
            text: `ปฏิเสธการอยู่เพื่อรับการรักษาต่อ ทั้งนี้ ข้าพเจ้าได้รับทราบถึงผลเสียหายที่อาจจะเกิดขึ้นจากการนี้ และขอแสดงความรับ`,
            decorationStyle: ``,
            color: ``,
            pageBreak: ``,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            width: 25,
            decoration: ``,
            preserveLeadingSpaces: true,
            text: ``,
            bold: false,
            color: ``,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
          },
          {
            width: `auto`,
            bold: false,
            text: `ผิดชอบต่อเหตุการณ์ที่อาจจะเกิดขึ้นอันเป็นผลสืบเนื่องจากการตัดสินใจของข้าพเจ้าในครั้งนี้แต่เพียงผู้เดียว`,
            color: ``,
            decoration: ``,
            pageBreak: ``,
            decorationColor: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            decoration: ``,
            fontSize: 15,
            width: 5,
            decorationStyle: ``,
            decorationColor: ``,
            bold: false,
            alignment: `left`,
            preserveLeadingSpaces: true,
            text: ``,
            margin: [0, 0, 0, 0],
            color: ``,
            pageBreak: ``,
          },
          {
            alignment: `left`,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            width: `auto`,
            bold: `true`,
            text: `การปฏิเสธการตรวจรักษาขณะที่พักรักษาตัวในโรงพยาบาลและ/หรือปฏิเสธที่จะรับการรักษาต่อในโรงพยาบาล`,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            decoration: ``,
            color: ``,
            decorationStyle: ``,
            pageBreak: ``,
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            decorationStyle: ``,
            fontSize: 15,
            decorationColor: ``,
            width: 25,
            text: ``,
            margin: [0, 0, 0, 0],
            color: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            bold: false,
            decoration: ``,
          },
          {
            pageBreak: ``,
            width: `auto`,
            fontSize: 15,
            decorationStyle: ``,
            alignment: `left`,
            text: `(หัตถการ อุปกรณ์ เครื่องมือ ฯลฯ ที่ผู้ป่วยปฏิเสธ คือ ..................................................................................................................)`,
            decorationColor: ``,
            bold: false,
            color: ``,
            decoration: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        margin: [0, 0, 0, 0],
        decoration: ``,
        color: ``,
        text: `ข้าพเจ้ามีความประสงค์ที่จะปฏิเสธให้แพทย์ผู้รับผิดชอบทำการตรวจรักษา ทั้งๆที่ข้าพเจ้าได้รับทราบคำชี้แจงจากแพทย์ และเจ้าหน้าที่`,
        alignment: `left`,
        decorationStyle: ``,
        width: `auto`,
        fontSize: 15,
        preserveLeadingSpaces: true,
        decorationColor: ``,
        pageBreak: ``,
        bold: false,
      },
      {
        alignment: `left`,
        pageBreak: ``,
        bold: false,
        color: ``,
        fontSize: 15,
        margin: [0, 0, 0, 0],
        decoration: ``,
        decorationStyle: ``,
        width: `auto`,
        decorationColor: ``,
        text: `ของโรงพยาบาลแล้ว ทั้งนี้ ข้าพเจ้ารับทราบดีว่าโรงพยาบาลจะไม่รับผิดชอบในความเสียหายที่เกิดจากการไม่ยินยอมนี้ทุกกรณี`,
        preserveLeadingSpaces: true,
      },
      {
        margin: [0, 0, 0, 0],
        width: `auto`,
        text: ` `,
        decorationStyle: ``,
        pageBreak: ``,
        bold: false,
        alignment: `left`,
        fontSize: 15,
        preserveLeadingSpaces: true,
        color: ``,
        decorationColor: ``,
        decoration: ``,
      },
      {
        columns: [
          {
            bold: false,
            fontSize: 15,
            alignment: `left`,
            text: `ลงนาม ......................................................`,
            margin: [0, 0, 0, 0],
            width: `auto`,
            decorationStyle: ``,
            color: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationColor: ``,
          },
          {
            alignment: `left`,
            pageBreak: ``,
            decorationColor: ``,
            fontSize: 15,
            color: ``,
            decorationStyle: ``,
            bold: false,
            width: 100,
            margin: [0, 0, 0, 0],
            text: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
          },
          {
            bold: false,
            pageBreak: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            width: `auto`,
            decoration: ``,
            margin: [0, 0, 0, 0],
            text: `พยาน 1 ลงนาม  ......................................................`,
            fontSize: 15,
            decorationColor: ``,
            color: ``,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            pageBreak: ``,
            color: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            width: 5,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decoration: ``,
            text: ``,
            alignment: `left`,
          },
          {
            stack: [
              {
                decoration: ``,
                decorationColor: ``,
                width: `auto`,
                preserveLeadingSpaces: true,
                color: ``,
                decorationStyle: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                text: props.items?.full_name_th || props.items?.full_name,
                margin: [0, 0, 0, -15],
                alignment: `center`,
              },
              {
                decoration: ``,
                fontSize: 15,
                pageBreak: ``,
                text: `(.............................................................)`,
                color: ``,
                width: `auto`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
                bold: false,
                decorationStyle: ``,
                alignment: `center`,
                decorationColor: ``,
              },
            ],
            width: `auto`,
          },
          {
            bold: false,
            pageBreak: ``,
            color: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            width: 100,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decoration: ``,
            text: ``,
            alignment: `left`,
          },
          {
            decorationColor: ``,
            preserveLeadingSpaces: true,
            bold: false,
            color: ``,
            width: `auto`,
            pageBreak: ``,
            decoration: ``,
            text: `(.................................................................................)`,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            alignment: `left`,
            fontSize: 15,
          },
        ],
      },
      {
        columns: [
          {
            alignment: `left`,
            preserveLeadingSpaces: true,
            text: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            decorationColor: ``,
            pageBreak: ``,
            decoration: ``,
            fontSize: 15,
            decorationStyle: ``,
            width: 10,
            color: ``,
          },
          {
            stack: [
              {
                decoration: ``,
                width: `auto`,
                decorationColor: ``,
                text: props.items?.signedDate,
                alignment: `center`,
                decorationStyle: ``,
                color: ``,
                bold: false,
                margin: [0, 0, 0, -15],
                preserveLeadingSpaces: true,
                pageBreak: ``,
                fontSize: 15,
              },
              {
                preserveLeadingSpaces: true,
                decorationStyle: ``,
                pageBreak: ``,
                width: `auto`,
                color: ``,
                decorationColor: ``,
                text: `........................`,
                alignment: `left`,
                decoration: ``,
                margin: [0, 0, 0, 0],
                bold: false,
                fontSize: 15,
              },
            ],
            width: `auto`
          },
          {
            alignment: `left`,
            preserveLeadingSpaces: true,
            text: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            decorationColor: ``,
            pageBreak: ``,
            decoration: ``,
            fontSize: 15,
            decorationStyle: ``,
            width: 40,
            color: ``,
          },
          {
            stack: [
              {
                decoration: ``,
                width: `auto`,
                decorationColor: ``,
                text: props.items?.signedTime,
                alignment: `center`,
                decorationStyle: ``,
                color: ``,
                bold: false,
                margin: [0, 0, 0, -15],
                preserveLeadingSpaces: true,
                pageBreak: ``,
                fontSize: 15,
              },
              {
                preserveLeadingSpaces: true,
                decorationStyle: ``,
                pageBreak: ``,
                width: `auto`,
                color: ``,
                decorationColor: ``,
                text: `..................`,
                alignment: `left`,
                decoration: ``,
                margin: [0, 0, 0, 0],
                bold: false,
                fontSize: 15,
              },
            ],
            width: `auto`
          },
          {
            decoration: ``,
            preserveLeadingSpaces: true,
            text: ``,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            alignment: `left`,
            width: 110,
            fontSize: 15,
            decorationStyle: ``,
            color: ``,
            bold: false,
          },
          {
            alignment: `left`,
            decorationStyle: ``,
            pageBreak: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            width: `auto`,
            text: `พยาน 2 ลงนาม ......................................................`,
            color: ``,
            decoration: ``,
            margin: [0, 0, 0, 0],
            bold: false,
          },
        ],
      },
      {
        columns: [
          {
            text: ``,
            fontSize: 15,
            alignment: `left`,
            decorationStyle: ``,
            color: ``,
            decorationColor: ``,
            pageBreak: ``,
            bold: false,
            decoration: ``,
            preserveLeadingSpaces: true,
            width: 27,
            margin: [0, 0, 0, 0],
          },
          {
            decorationStyle: ``,
            decoration: ``,
            width: `auto`,
            bold: false,
            fontSize: 15,
            alignment: `left`,
            color: ``,
            text: `วันที่`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            decorationColor: ``,
          },
          {
            decorationColor: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            bold: false,
            color: ``,
            decorationStyle: ``,
            alignment: `left`,
            fontSize: 15,
            width: 75,
            text: ``,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
          },
          {
            fontSize: 15,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationColor: ``,
            width: `auto`,
            pageBreak: ``,
            alignment: `left`,
            text: `เวลา`,
            color: ``,
            bold: false,
          },
          {
            fontSize: 15,
            text: ``,
            bold: false,
            decorationColor: ``,
            pageBreak: ``,
            color: ``,
            preserveLeadingSpaces: true,
            width: 128,
            decoration: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            alignment: `left`,
          },
          {
            color: ``,
            width: `auto`,
            bold: false,
            decorationStyle: ``,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            fontSize: 15,
            text: `(.................................................................................)`,
            alignment: `left`,
            decoration: ``,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            text: ``,
            decoration: ``,
            margin: [0, 0, 0, 0],
            width: 285,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationStyle: ``,
            color: ``,
            decorationColor: ``,
            alignment: `left`,
            bold: false,
            fontSize: 15,
          },
          {
            fontSize: 15,
            text: `(กรณีพิมพ์ลายนิ้วมือ/ยินยอมทางโทรศัพท์)`,
            decoration: ``,
            preserveLeadingSpaces: true,
            color: ``,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            alignment: `left`,
            pageBreak: ``,
            decorationColor: ``,
            width: `auto`,
          },
        ],
      },
      {
        preserveLeadingSpaces: true,
        decoration: ``,
        decorationStyle: ``,
        color: ``,
        pageBreak: ``,
        margin: [0, 0, 0, 0],
        alignment: `left`,
        decorationColor: ``,
        bold: false,
        text: ` `,
        fontSize: 15,
        width: `auto`,
      },
      {
        text: `การแปลและให้ข้อมูลโดยผู้แปลภาษา`,
        decoration: `underline`,
        decorationStyle: ``,
        decorationColor: ``,
        width: `auto`,
        color: ``,
        bold: true,
        fontSize: 15,
        pageBreak: ``,
        alignment: `left`,
        preserveLeadingSpaces: true,
        margin: [0, 0, 0, 0],
      },
      {
        columns: [
          {
            color: ``,
            decoration: ``,
            text: ``,
            bold: false,
            alignment: `left`,
            fontSize: 15,
            decorationColor: ``,
            decorationStyle: ``,
            width: 30,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            pageBreak: ``,
          },
          {
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            width: `auto`,
            text: `ข้าพเจ้าได้แปลหนังสือการปฏิเสธการรักษา (Consent : Against Medical Advice) รวมทั้งข้อมูล ซึ่งแพทย์ได้อธิบายให้ผู้ป่วย`,
            alignment: `left`,
            fontSize: 15,
            decorationColor: ``,
            color: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            bold: false,
            pageBreak: ``,
          },
        ],
      },
      {
        width: `auto`,
        decoration: ``,
        fontSize: 15,
        margin: [0, 0, 0, 0],
        decorationColor: ``,
        alignment: `left`,
        color: ``,
        bold: false,
        preserveLeadingSpaces: true,
        pageBreak: ``,
        decorationStyle: ``,
        text: `/ผู้แทนผู้ป่วยทราบ`,
      },
      {
        alignment: `left`,
        decoration: ``,
        text: ` `,
        color: ``,
        width: `auto`,
        decorationStyle: ``,
        fontSize: 15,
        decorationColor: ``,
        bold: false,
        pageBreak: ``,
        margin: [0, 0, 0, 0],
        preserveLeadingSpaces: true,
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            bold: false,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            pageBreak: ``,
            text: `ภาษาที่แปล ................................................`,
            alignment: `left`,
            decorationColor: ``,
            width: `auto`,
            fontSize: 15,
            decoration: ``,
            color: ``,
          },
          {
            margin: [0, 0, 0, 0],
            color: ``,
            fontSize: 15,
            bold: false,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            width: 100,
            decorationStyle: ``,
            decoration: ``,
            alignment: `left`,
            pageBreak: ``,
            text: ``,
          },
          {
            color: ``,
            bold: false,
            pageBreak: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            width: `auto`,
            alignment: `left`,
            fontSize: 15,
            decorationColor: ``,
            decoration: ``,
            text: `ผู้แปลลงนาม ................................................`,
          },
        ],
      },
      {
        columns: [
          {
            text: ``,
            alignment: `left`,
            fontSize: 15,
            width: 270,
            color: ``,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            bold: false,
            decorationColor: ``,
          },
          {
            decoration: ``,
            pageBreak: ``,
            color: ``,
            text: `(...................................................................)`,
            preserveLeadingSpaces: true,
            width: `auto`,
            alignment: `left`,
            decorationColor: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            bold: false,
          },
        ],
      },
      {
        color: ``,
        decoration: ``,
        fontSize: 15,
        decorationColor: ``,
        alignment: `left`,
        pageBreak: ``,
        decorationStyle: ``,
        bold: false,
        width: `auto`,
        margin: [0, 0, 0, 0],
        text: ` `,
        preserveLeadingSpaces: true,
      },
      {
        text: `สถานะของผู้ลงนาม (ตามประมวลกฎหมายแพ่งและพาณิชย์)`,
        decoration: `underline`,
        decorationStyle: ``,
        decorationColor: ``,
        width: `auto`,
        color: ``,
        bold: true,
        fontSize: 15,
        pageBreak: props.items?.haveHeader ? `before` : ``,
        alignment: `left`,
        preserveLeadingSpaces: true,
        margin: [0, 0, 0, 0],
      },
      {
        columns: [
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            color: ``,
            margin: [0, 0, 0, 0],
            text: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            width: 5,
            bold: false,
            fontSize: 15,
            alignment: `left`,
            decorationColor: ``,
            pageBreak: ``,
            decorationStyle: ``,
          },
          {
            preserveLeadingSpaces: true,
            bold: false,
            alignment: `left`,
            decorationColor: ``,
            decorationStyle: ``,
            width: `auto`,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            text: `ผู้ป่วย ซึ่งบรรลุนิติภาวะ (20 ปีบริบูรณ์) และมีสติสัมปชัญญะสมบูรณ์`,
            color: ``,
            decoration: ``,
            fontSize: 15,
          },
        ],
      },
      {
        columns: [
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            decoration: ``,
            decorationStyle: ``,
            text: ``,
            color: ``,
            width: 5,
            fontSize: 15,
            alignment: `left`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            bold: false,
          },
          {
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            text: `สามีหรือภรรยาตามกฎหมาย กรณีที่ผู้ป่วยไม่มีสติสัมปชัญญะ (ไม่รู้สึกตัว)`,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            color: ``,
            alignment: `left`,
            width: `auto`,
            fontSize: 15,
            bold: false,
            decoration: ``,
            pageBreak: ``,
          },
        ],
      },
      {
        columns: [
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            color: ``,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            width: 5,
            fontSize: 15,
            decorationStyle: ``,
            text: ``,
            decorationColor: ``,
            bold: false,
            decoration: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
          },
          {
            alignment: `left`,
            decorationColor: ``,
            color: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            fontSize: 15,
            bold: false,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            pageBreak: ``,
            width: `auto`,
            text: `ผู้ใช้อำนาจปกครอง กรณีผู้ป่วยอายุต่ำกว่า 20 ปีบริบูรณ์ที่ยังไม่บรรลุนิติภาวะ`,
          },
        ],
      },
      {
        columns: [
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            pageBreak: ``,
            alignment: `left`,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            decoration: ``,
            text: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            color: ``,
            decorationStyle: ``,
            width: 5,
            bold: false,
          },
          {
            decoration: ``,
            fontSize: 15,
            width: `auto`,
            bold: false,
            decorationStyle: ``,
            color: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            text: `ผู้พิทักษ์ กรณีผู้ป่วยเป็นคนเสมือนไร้ความสามารถ (ตามคำสั่งศาล)`,
            decorationColor: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        columns: [
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            alignment: `left`,
            text: ``,
            decorationStyle: ``,
            width: 5,
            color: ``,
            decoration: ``,
            fontSize: 15,
            bold: false,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
          },
          {
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            pageBreak: ``,
            fontSize: 15,
            bold: false,
            color: ``,
            decoration: ``,
            width: `auto`,
            decorationColor: ``,
            text: `ผู้อนุบาล กรณีผู้ป่วยเป็นคนไร้ความสามารถ (ตามคำสั่งศาล)`,
            alignment: `left`,
            margin: [0, 0, 0, 0],
          },
        ],
      },
      {
        width: `auto`,
        decorationStyle: ``,
        decorationColor: ``,
        text: `โปรดแนบหลักฐานประกอบ เช่น สำเนาเอกสารบัตรประชาชน/หนังสือเดินทาง/เอกสารที่ออกโดยราชการ โดยปกปิดข้อมูลศาสนา`,
        margin: [0, 0, 0, 0],
        alignment: `left`,
        preserveLeadingSpaces: true,
        fontSize: 15,
        decoration: ``,
        bold: false,
        color: ``,
        pageBreak: ``,
      },
      {
        fontSize: 15,
        decorationStyle: ``,
        preserveLeadingSpaces: true,
        bold: false,
        text: `และหมู่เลือดซึ่งปรากฎบนสำเนาดังกล่าว (หากมี) พร้อมลงนามรับรองสำเนาถูกต้อง`,
        width: `auto`,
        alignment: `left`,
        pageBreak: ``,
        margin: [0, 0, 0, 0],
        decorationColor: ``,
        decoration: ``,
        color: ``,
      },
      {
        preserveLeadingSpaces: true,
        margin: [0, 0, 0, 0],
        width: `auto`,
        text: ` `,
        decoration: ``,
        bold: false,
        fontSize: 15,
        alignment: `left`,
        color: ``,
        decorationStyle: ``,
        pageBreak: ``,
        decorationColor: ``,
      },
      {
        columns: [
          {
            decoration: ``,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            text: `ความสัมพันธ์กับผู้ป่วย  .............................................................................................................................................................................`,
            alignment: `left`,
            width: `auto`,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            color: ``,
            pageBreak: ``,
            bold: false,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            width: `auto`,
            text: `เลขที่บัตรประจำตัวประชาชนผู้ลงนามแทนผู้ป่วย  ...................................................................................................................................`,
            alignment: `left`,
            fontSize: 15,
            pageBreak: ``,
            color: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            width: `auto`,
            bold: false,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationStyle: ``,
            decoration: ``,
            text: `เบอร์โทรศัพท์ ............................................................................................................................................................................................`,
            color: ``,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            text: `อีเมล ..........................................................................................................................................................................................................`,
            decorationColor: ``,
            width: `auto`,
            color: ``,
            bold: false,
            alignment: `left`,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            decoration: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
          },
        ],
      },
    ],
    pageOrientation: `portrait`,
    defaultStyle: {
      font: `THSarabunNew`,
    },
    images: injectImages(props.items?.haveHeader),
  };
}
