import React from 'react';
import PropTypes from 'prop-types';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils from 'react-day-picker/moment';
import moment from 'moment';

import 'react-day-picker/lib/style.css';
import '../../css/DateTextBox.css';

import {
  Input,
  Icon,
} from 'semantic-ui-react';

import {
  formatDate,
  parseDate,
  isValidDate,
} from '../../utils';

const currentYear = new Date().getFullYear();
// Start from currentYear - 100
const fromMonth = new Date(currentYear - 100, 0);
// End at currentYear + 10
const toMonth = new Date(currentYear + 10, 11);
// Suggested by: https://stackoverflow.com/a/36963945
const range = (start, end) => Array.from({length: (end - start)}, (v, k) => k + start);


// For more information about this implementation
// see: http://react-day-picker.js.org/examples/elements-year-navigation
function YearMonthForm(props) {
  const months = props.localeUtils.getMonths('th');
  const years = range(fromMonth.getFullYear() + 543, toMonth.getFullYear() + 543 + 1);
  const monthOptions = months.map((month, i) => ({ text: month, value: i }));
  const yearOptions = years.map(year => ({ text: year, value: year }));
  const selectedMonth = props.date.getMonth();
  const selectedYear = props.date.getFullYear();

  return (
    <div className='YearMonthForm'>
      <div className='ui tiny form'>
        <select
          className='month'
          name='month'
          value={selectedMonth}
          onChange={(e) => props.onYearMonthChange(new Date(selectedYear, e.target.form ? e.target.form.month.value : e.target.value))}
        >
          {monthOptions.map(item => (
            <option key={item.text} value={item.value}>
              {item.text}
            </option>
          ))}
        </select>

        <select
          className='year'
          name='year'
          value={selectedYear + 543}
          onChange={(e) => props.onYearMonthChange(new Date(e.target.form ? e.target.form.year.value - 543 : parseInt(e.target.value) - 543, selectedMonth))}
        >
          {yearOptions.map(item => (
            <option key={item.text} value={item.value}>
              {item.text}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}



class InputWithIcon extends React.Component {
  // We must implement focus() method in order to keep DayPickerInput
  // focusing our InputWithIcon while user is picking a day.
  // see: http://react-day-picker.js.org/api/DayPickerInput#component

  handleRef = (ref) => {
    this.ref = ref;
  };

  focus = () => {
    this.ref.focus();
  };

  handleTodayClick = () => {
    this.props.onTodayClick(formatDate(moment()));
  };

  shouldComponentUpdate(nextProps) {
    // To prevent cursor position jumping
    const conditions = (
      nextProps.value !== this.props.value
      || nextProps.placeholder !== this.props.placeholder
      || nextProps.error !== this.props.error
      || nextProps.disabled !== this.props.disabled
    );
    return conditions;
  }

  render() {
    const { onTodayClick, ...rest } = this.props;

    return (
      <Input
        id={this.props.id}
        {...rest}
        ref={this.handleRef}
        maxLength={10}
        icon={
          <Icon
            name='calendar'
            link={true}
            onClick={this.handleTodayClick}
          />
        }
      />
    )
  }
}


class OverlayComponent extends React.Component {
  shouldComponentUpdate(nextProps) {
    // we will rerender the OverlayComponent if
    // - the date string in the Input is a correct date format
    // - user clicks something in the Calendar UI
    return isValidDate(formatDate(nextProps.selectedDay)) || isValidDate(formatDate(nextProps.month));
  }

  render() {
    const {
      input,
      selectedDay,
      month,
      children,
      classNames,
      ...props
    } = this.props;

    return (
      <div className={classNames.overlayWrapper} {...props}>
        <div className={classNames.overlay}> {children} </div>
      </div>
    )
  }
}

class CustomDayPickerInput extends DayPickerInput {
  handleDayClick = (day, modifiers, e) => {
    const {
      dayPickerProps,
      inputProps,
      formatDate,
    } = this.props;

    if (dayPickerProps.onDayClick) {
      dayPickerProps.onDayClick(day, modifiers, e);
    }

    // Do nothing if the day is disabled
    if ( modifiers.disabled || (dayPickerProps && dayPickerProps.classNames && modifiers[dayPickerProps.classNames.disabled]) ) {
      return;
    }

    if (inputProps.onChange) {
      inputProps.onChange(formatDate(day));
    }

    this.hideAfterDayClick();
  };

  handleInputChange = (e) => {
    const {
      inputProps,
    } = this.props;

    if (inputProps.onChange) {
      inputProps.onChange(e.target.value);
    }
  }
}

class DateTextBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      month: null,
    };
    // This property is used to check whether the month (in DayPicker UI)
    // is changed by clicking on UI or by typing in InputWithIcon
    this.monthChangedByDayPicker = false;
  }

  handleYearMonthChange = (month) => {
    this.monthChangedByDayPicker = true;
    this.setState({ month });
  };

  handleTextChange = (text) => {
    this.props.onChange(text);
  };

  handleBlur = (e) => {
    if (!isValidDate(e.target.value)) {
      this.props.onChange('');
    }
  };

  render() {
    const {
      placeholder,
      value,
      minDate,
      maxDate,
      error,
      disabled,
      size,
    } = this.props;

    const month = this.monthChangedByDayPicker
      ? this.state.month
      : parseDate(value);

    this.monthChangedByDayPicker = false;

    return (
      <CustomDayPickerInput
        component={InputWithIcon}
        inputProps={{
          disabled,
          error,
          onTodayClick: this.handleTextChange,
          onChange: this.handleTextChange,
          onBlur: this.handleBlur,
          size: size,
          id: this.props.id,
        }}
        placeholder={placeholder}
        formatDate={formatDate}
        parseDate={parseDate}
        value={value ? value : ''}
        overlayComponent={OverlayComponent}
        dayPickerProps={{
          locale: 'th',
          localeUtils: MomentLocaleUtils,
          month: month,  // Only used to change the month in the UI (it has nothing to do with changing the day in the UI)
          fromMonth: fromMonth,
          toMonth: toMonth,
          disabledDays: [ { after: parseDate(maxDate), before: parseDate(minDate) } ],
          captionElement: <YearMonthForm onYearMonthChange={this.handleYearMonthChange} />
        }}
      />
    );
  }
}

DateTextBox.propTypes = {
  id: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  onChange: PropTypes.func,
};

DateTextBox.defaultProps = {
  placeholder: '',
  error: false,
  onChange() {},
};

export default DateTextBox;
