import React from 'react';
import PropTypes from 'prop-types';
import _ from "react-lib/compat/lodashplus";
import ModBillTransaction from '../../../react-lib/component-js/hbill/ModBillTransaction';
import BillService from '../../../react-lib/services/BillService';
import { useIntl } from "react-intl";

const INITIAL_STATE = {
  invoiceNo: '',
  invalidateReasons: '',
  rectificationDetail: '',
  tryFixUrls: [],
  hn: '',
  name: '',
  encounterIds: [],
  items: [],
  page: 1,
  total: 1,
  pageSize: 10,
  isLoading: false
};

class ModBillTransactionContainer extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    billTransactionId: PropTypes.number,
    apiToken: PropTypes.string,
    modalStyle: PropTypes.object,
    onClose: PropTypes.func,
    onShowAdmin: PropTypes.func,
    onUrlRedirect: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
    this.billService = new BillService();
  }

  componentDidUpdate = async (prevProps) => {
    const { billTransactionId } = this.props;
    const { billTransactionId: prevBillTransactionId } = prevProps;

    if (billTransactionId && prevBillTransactionId !== billTransactionId) {
      this.setState({ isLoading: true });
      this.billService
        .getBillTransactionDetail(
          this.props.billTransactionId,
          this.props.apiToken
        )
        .then((data) => {
          this.setState({
            invoiceNo: data.invoice_no,
            invalidateReasons: data.error_message,
            rectificationDetail: data.rectification_detail,
            tryFixUrls: data.try_fix_urls,
            hn: data.hn,
            name: data.name,
            encounterIds: data.encounter_ids,
          });
          this.handleFetchTableData(billTransactionId);
        })
        .catch(() => {
          this.setState({ isLoading: false });
        });
    }
  };

  handleFetchTableData = () => {
    const { page, pageSize } = this.state;
    const { billTransactionId } = this.props;

    this.setState({ isLoading: true });
    this.billService
      .getAllBaseSendClaim({
        bill_transaction_id: billTransactionId,
        page_size: pageSize,
        page,
        apiToken: this.props.apiToken,
      })
      .then((data) => {
        this.setState({
          items: [...data.items],
          total: data.total,
          page: data.page,
          pageSize: data.page_size,
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };

  handleAdminRedirect = () => {
    this.billService.redirectToBillTransactionDetailAdmin(
      this.props.billTransactionId,
      this.props.apiToken
    );
  };

  handleUrlRedirect = (urls) => {
    if(this.props.onUrlRedirect){
      return this.props.onUrlRedirect(urls);
    }
    for (let url of urls) {
      window.open(url, "_blank");
    }
  };

  handleClose = () => {
    this.setState(this.initialState);
    this.props.onClose();
  };

  handleShowAdmin = (pk, model) => {
    if (this.props.onShowAdmin) {
      return this.props.onShowAdmin(pk, model);
    }

    const urlTemplate = _.template("/manage/INF/{{model}}/{{pk}}/change/");
    const url = urlTemplate({ model, pk });
    window.open(url, "_blank");
  };

  handlePageSizeChange = (pageSize) => {
    this.setState({ pageSize }, () => {
      this.handleFetchTableData();
    });
  };

  handlePageChange = (page) => {
    this.setState({ page: page + 1 }, () => {
      this.handleFetchTableData();
    });
  };

  render() {
    return (
      <ModBillTransaction
        {...this.state}
        open={this.props.open}
        billTransactionId={this.props.billTransactionId}
        modalStyle={this.props.modalStyle}
        onClose={this.handleClose}
        onFetchTableData={this.handleFetchTableData}
        onAdminRedirect={this.handleAdminRedirect}
        onUrlRedirect={this.handleUrlRedirect}
        onShowAdmin={this.handleShowAdmin}
        onPageSizeChange={this.handlePageSizeChange}
        onPageChange={this.handlePageChange}
      />
    );
  }
}

export default ModBillTransactionContainer;