import React from 'react'
import {
   Button,
   Checkbox,
   Dropdown,
   Input,
} from 'semantic-ui-react'



import styles from './PTPhysicalexaminationNeuroogialOPDLOW.module.css'

export interface PTPhysicalexaminationNeuroogialOPDLOWViewDataType {
    Ct5Sensonry: string,
    Ct5SensonryDisabled: boolean,
    Ct5SensonryOptions: any,
    PtPhysicalNeuroOpdLow5: string,
    PtPhysicalNeuroOpdLow5Disabled: boolean,
    Ct4SittingStatic: string,
    Ct4SittingStaticDisabled: boolean,
    Ct4SittingStaticOptions: any,
    Ct4SittingDynamic: string,
    Ct4SittingDynamicDisabled: boolean,
    Ct4SittingDynamicOptions: any,
    Ct3StandingStatic: string,
    Ct3StandingStaticDisabled: boolean,
    Ct3StandingStaticOptions: any,
    Ct3StandingDynamic: string,
    Ct3StandingDynamicDisabled: boolean,
    Ct3StandingDynamicOptions: any,
    Ct2TransferDependent: string,
    Ct2TransferDependentDisabled: boolean,
    Ct2TransferDependentOptions: any,
    PtPhysicalNeuroOpdLow34: boolean,
    PtPhysicalNeuroOpdLow34Disabled: boolean,
    PtPhysicalNeuroOpdLow35: boolean,
    PtPhysicalNeuroOpdLow35Disabled: boolean,
    PtPhysicalNeuroOpdLow43: string,
    PtPhysicalNeuroOpdLow43Disabled: boolean,
    Ct2GaitPatternAmbulation: string,
    Ct2GaitPatternAmbulationDisabled: boolean,
    Ct2GaitPatternAmbulationOptions: any,
    SaveRequestDisabled: boolean,
}

export const PTPhysicalexaminationNeuroogialOPDLOWInitialViewData: PTPhysicalexaminationNeuroogialOPDLOWViewDataType = {
    Ct5Sensonry: "",
    Ct5SensonryDisabled: false,
    Ct5SensonryOptions: [{key: 0, value: "A", text: "A"}],
    PtPhysicalNeuroOpdLow5: "",
    PtPhysicalNeuroOpdLow5Disabled: false,
    Ct4SittingStatic: "",
    Ct4SittingStaticDisabled: false,
    Ct4SittingStaticOptions: [{key: 0, value: "A", text: "A"}],
    Ct4SittingDynamic: "",
    Ct4SittingDynamicDisabled: false,
    Ct4SittingDynamicOptions: [{key: 0, value: "A", text: "A"}],
    Ct3StandingStatic: "",
    Ct3StandingStaticDisabled: false,
    Ct3StandingStaticOptions: [{key: 0, value: "A", text: "A"}],
    Ct3StandingDynamic: "",
    Ct3StandingDynamicDisabled: false,
    Ct3StandingDynamicOptions: [{key: 0, value: "A", text: "A"}],
    Ct2TransferDependent: "",
    Ct2TransferDependentDisabled: false,
    Ct2TransferDependentOptions: [{key: 0, value: "A", text: "A"}],
    PtPhysicalNeuroOpdLow34: false,
    PtPhysicalNeuroOpdLow34Disabled: false,
    PtPhysicalNeuroOpdLow35: false,
    PtPhysicalNeuroOpdLow35Disabled: false,
    PtPhysicalNeuroOpdLow43: "",
    PtPhysicalNeuroOpdLow43Disabled: false,
    Ct2GaitPatternAmbulation: "",
    Ct2GaitPatternAmbulationDisabled: false,
    Ct2GaitPatternAmbulationOptions: [{key: 0, value: "A", text: "A"}],
    SaveRequestDisabled: false,
}

const Sub = (props: any) => {
    const children = React.Children.toArray(props.children)
    return (
        <div className={styles.container}>
            <div className={styles.PtPhysicalNeuroOpdLow1}>
                <div
                    style={{background: "#F3F3F3", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.Ct5Sensonry}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct5Sensonry}
                    disabled={ props.viewData.Ct5SensonryDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "PTPhysicalexaminationNeuroogialOPDLOW",
                                name: "Ct5Sensonry",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct5SensonryOptions}
                />
            </div>
            <div className={styles.PtPhysicalNeuroOpdLow5}>
                <Input
                    value={props.viewData.PtPhysicalNeuroOpdLow5}
                    disabled={ props.viewData.PtPhysicalNeuroOpdLow5Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTPhysicalexaminationNeuroogialOPDLOW',
                            name: 'PtPhysicalNeuroOpdLow5',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtPhysicalNeuroOpdLow6}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Sensory</strong></p>
                </div>
            </div>
            <div className={styles.PtPhysicalNeuroOpdLow7}>
                <div
                    style={{background: "#E0E0E0", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.Ct4SittingStatic}>
                <Dropdown selection
                    style={{height: "100%", minWidth: "150px"}}
                    value={props.viewData.Ct4SittingStatic}
                    disabled={ props.viewData.Ct4SittingStaticDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "PTPhysicalexaminationNeuroogialOPDLOW",
                                name: "Ct4SittingStatic",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct4SittingStaticOptions}
                />
            </div>
            <div className={styles.Ct4SittingDynamic}>
                <Dropdown selection
                    style={{height: "100%", minWidth: "150px"}}
                    value={props.viewData.Ct4SittingDynamic}
                    disabled={ props.viewData.Ct4SittingDynamicDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "PTPhysicalexaminationNeuroogialOPDLOW",
                                name: "Ct4SittingDynamic",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct4SittingDynamicOptions}
                />
            </div>
            <div className={styles.PtPhysicalNeuroOpdLow14}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Sitting</strong></p>
                </div>
            </div>
            <div className={styles.PtPhysicalNeuroOpdLow15}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Static</p>
                </div>
            </div>
            <div className={styles.PtPhysicalNeuroOpdLow16}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Dynamic</p>
                </div>
            </div>
            <div className={styles.PtPhysicalNeuroOpdLow17}>
                <div
                    style={{background: "#E0E0E0", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.Ct3StandingStatic}>
                <Dropdown selection
                    style={{height: "100%", minWidth: "150px"}}
                    value={props.viewData.Ct3StandingStatic}
                    disabled={ props.viewData.Ct3StandingStaticDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "PTPhysicalexaminationNeuroogialOPDLOW",
                                name: "Ct3StandingStatic",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct3StandingStaticOptions}
                />
            </div>
            <div className={styles.Ct3StandingDynamic}>
                <Dropdown selection
                    style={{height: "100%", minWidth: "150px"}}
                    value={props.viewData.Ct3StandingDynamic}
                    disabled={ props.viewData.Ct3StandingDynamicDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "PTPhysicalexaminationNeuroogialOPDLOW",
                                name: "Ct3StandingDynamic",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct3StandingDynamicOptions}
                />
            </div>
            <div className={styles.PtPhysicalNeuroOpdLow24}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Standing</strong></p>
                </div>
            </div>
            <div className={styles.PtPhysicalNeuroOpdLow25}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Static</p>
                </div>
            </div>
            <div className={styles.PtPhysicalNeuroOpdLow26}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Dynamic</p>
                </div>
            </div>
            <div className={styles.PtPhysicalNeuroOpdLow27}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Balance test</strong></p>
                </div>
            </div>
            <div className={styles.PtPhysicalNeuroOpdLow29}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Transfer to wheel chair</strong></p>
                </div>
            </div>
            <div className={styles.Ct2TransferDependent}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct2TransferDependent}
                    disabled={ props.viewData.Ct2TransferDependentDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "PTPhysicalexaminationNeuroogialOPDLOW",
                                name: "Ct2TransferDependent",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct2TransferDependentOptions}
                />
            </div>
            <div className={styles.PtPhysicalNeuroOpdLow34}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtPhysicalNeuroOpdLow34}
                    disabled={ props.viewData.PtPhysicalNeuroOpdLow34Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTPhysicalexaminationNeuroogialOPDLOW",
                                name: "PtPhysicalNeuroOpdLow34"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtPhysicalNeuroOpdLow35}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtPhysicalNeuroOpdLow35}
                    disabled={ props.viewData.PtPhysicalNeuroOpdLow35Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTPhysicalexaminationNeuroogialOPDLOW",
                                name: "PtPhysicalNeuroOpdLow35"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtPhysicalNeuroOpdLow36}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Independent</p>
                </div>
            </div>
            <div className={styles.PtPhysicalNeuroOpdLow37}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Dependent</p>
                </div>
            </div>
            <div className={styles.PtPhysicalNeuroOpdLow40}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Gait pattern &amp; Ambulation</strong></p>
                </div>
            </div>
            <div className={styles.PtPhysicalNeuroOpdLow43}>
                <Input
                    value={props.viewData.PtPhysicalNeuroOpdLow43}
                    disabled={ props.viewData.PtPhysicalNeuroOpdLow43Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTPhysicalexaminationNeuroogialOPDLOW',
                            name: 'PtPhysicalNeuroOpdLow43',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.Ct2GaitPatternAmbulation}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.Ct2GaitPatternAmbulation}
                    disabled={ props.viewData.Ct2GaitPatternAmbulationDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "PTPhysicalexaminationNeuroogialOPDLOW",
                                name: "Ct2GaitPatternAmbulation",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct2GaitPatternAmbulationOptions}
                />
            </div>
            <div className={styles.PtPhysicalNeuroOpdLow50}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>By</strong></p>
                </div>
            </div>
            <div className={styles.SaveRequest}>
                <Button
                    disabled={ props.viewData.SaveRequestDisabled }
                    onClick={(e) => { props.onEvent({
                        message: 'clickButton',
                        params: {
                            view: 'PTPhysicalexaminationNeuroogialOPDLOW',
                            name: 'SaveRequest'
                        }
                    })}}
                    color="green"
                    style={{height: "100%", width: "100%"}}
                >
                    Save
                </Button>
            </div>
        </div>
    )
}

export default Sub

const getTsName = (cssClass: string) => {
    return cssClass.split('-').map((s) => (
        s.charAt(0).toUpperCase() + s.slice(1)
    )).join('')
}
