import React, { useEffect } from "react";
import { Grid, Image, Menu, Segment } from "semantic-ui-react";
// UI Common
import SnackMessage from "react-lib/apps/common/SnackMessage";
// UI
import CardSegmentSidebar from "./CardSegmentSidebar";
import CardSegmentDetail from "./CardSegmentDetail";
import CardSegmentPatient from "./CardSegmentPatient";
import { useIntl } from "react-intl";

export const CARD_KEY: string = "CardSegment";

type CardSegmentProps = {
  // function
  onEvent: any;
  setProp: any;

  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;

  // seq
  runSequence?: any;
  SegmentSequence?: any;

  // controller
  drugOrderQueueController?: any;
};

const CardSegment = (props: CardSegmentProps) => {
  const intl = useIntl();
  useEffect(() => {
    props.runSequence({
      sequence: "Segment",
      restart: true,
      action: "SEARCH_SEGMENT",
      cardKey: CARD_KEY,
    });
  }, []);

  useEffect(() => {
    if (props.SegmentSequence?.selectedSegment?.id) {
      props.runSequence({
        sequence: "Segment",
        action: "SEARCH_PATIENT",
        cardKey: CARD_KEY,
      });
    }
  }, [props.SegmentSequence?.selectedSegment?.id]);

  return (
    <>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_KEY}`, null);
          props.setProp(`successMessage.${CARD_KEY}`, null);
        }}
        error={props.errorMessage?.[CARD_KEY]}
        success={props.successMessage?.[CARD_KEY]}
        languageUX={props.languageUX}
      />

      <Grid column={2} divided>
        <Grid.Column width={3} style={{ paddingRight: "0px" }}>
          <CardSegmentSidebar
            // function
            setProp={props.setProp}
            // CommonInterface
            buttonLoadCheck={props.buttonLoadCheck}
            // seq
            runSequence={props.runSequence}
            SegmentSequence={props.SegmentSequence}
          />
        </Grid.Column>
        <Grid.Column width={13} style={{ paddingLeft: "0px" }}>
          <div style={{ display: "flex" }}>
            <Segment
              style={{ height: "100vh", width: "100px", padding: "0px" }}
            >
              <Menu icon="labeled" vertical>
                <Menu.Item
                  active={true}
                  style={{ background: "#2185D0", color: "white" }}
                >
                  <Image src="/static/images/segment_white.png" />
                  Segment
                </Menu.Item>
              </Menu>
            </Segment>

            <Segment style={{ marginTop: "0px", width: "100%" }}>
              <CardSegmentDetail
                // function
                setProp={props.setProp}
                // CommonInterface
                buttonLoadCheck={props.buttonLoadCheck}
                // seq
                runSequence={props.runSequence}
                SegmentSequence={props.SegmentSequence}
              />
              <hr />
              <CardSegmentPatient
                // function
                onEvent={props.onEvent}
                setProp={props.setProp}
                // seq
                runSequence={props.runSequence}
                SegmentSequence={props.SegmentSequence}
                // controller
                drugOrderQueueController={props.drugOrderQueueController}
              />
            </Segment>
          </div>
        </Grid.Column>
      </Grid>
    </>
  );
};

export default React.memo(CardSegment);
