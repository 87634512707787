import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
  MutableRefObject,
} from "react";
import { Modal, Header, Grid, Button, Icon } from "semantic-ui-react";

import { Line } from "react-chartjs-2";
import ReactTable from "react-table-6";
import withFixedColumns from "react-table-hoc-fixed-columns";
import { useIntl } from "react-intl";
// import { getRefValue, getStatus } from "../HISV3/LAB/sequence/OPDLabSummary";

const ReactTableFixedColumns = withFixedColumns(ReactTable);

// Types
type ModalLabChartProps = {
  centralLabResultList?: {
    items?: Record<string, any>[];
  };
  labNameResultList?: {
    items?: Record<string, any>[];
  };
  genderName?: "Male" | "Female";
  initialDatetime?: string;
  // callback
  onClose?: () => any;
};

const LIMIT = 6;

const ModalLabChart = (props: ModalLabChartProps) => {
  const intl = useIntl();
  const [openLabChart, setOpenLabChart] = useState<boolean>(false);
  const [startData, setStartData] = useState<number>(0);
  const [allChartData, setAllChartData] = useState<any[]>([]);
  const [chartTitle, setChartTitle] = useState<string>("");
  // const [refValueMax, setRefValueMax] = useState<string>("");
  // const [refValueMin, setRefValueMin] = useState<string>("");
  const [refValueTxT, setRefValueTxt] = useState<string>("");
  const [unit, setUnit] = useState<string>("");
  const [allChartLabel, setAllChartLabel] = useState<any[]>([]);
  const [openLabTable, setOpenLabTable] = useState<boolean>(false);
  const [refreshOption, setRefreshOption] = useState<boolean>(false);

  const chartRef = useRef() as MutableRefObject<any>;

  useEffect(() => {
    console.log("centralLabResultList change ", props.centralLabResultList);

    setRefreshOption(false);

    chartRef.current = null;

    if (!!props.centralLabResultList?.items?.length) {
      if (props.centralLabResultList?.items[0].is_numeric) {
        let labels = props.centralLabResultList?.items.map((item: any) => {
          const labelsText = item.created.split(" ");
          return [labelsText[0], labelsText[1]];
        });

        setAllChartLabel(labels);

        let datas = props.centralLabResultList?.items.map((item: any) => {
          return item.value;
        });

        setAllChartData(datas);
        setChartTitle(props.centralLabResultList?.items[0].product_name);

        const labResult = props.labNameResultList?.items?.find((acc: any) =>
          props.centralLabResultList?.items?.find(
            (item: any) => item?.product_name === acc.name
          )
        );

        setRefValueTxt(
          labResult?.ref_value_txt || labResult?.ref_value || null
        );
        setUnit(labResult?.unit || null);
        setOpenLabChart(true);
      } else {
        setOpenLabTable(true);
      }
    }
  }, [props.centralLabResultList]);

  useEffect(() => {
    const [date, time] = props.initialDatetime?.split(" ") || [];
    const index = allChartLabel.findIndex(
      (item) => item[0] === date && item[1] === time
    );

    if (index !== -1 && allChartLabel.length > LIMIT) {
      setStartData(index - LIMIT + 1);
    }
  }, [allChartLabel]);

  useEffect(() => {
    if (!refreshOption && chartRef.current) {
      setRefreshOption(true);
    }
  });

  const plugins = useMemo(() => {
    return [
      {
        id: "plugins-chart",
        afterDraw: (chart: any) => {
          chart.canvas.style.padding = `0 5px`;

          console.log("lineAt", chart.config.options.lineAt);

          if (typeof chart.config.options.lineAt?.[0] !== "undefined") {
            const drawLine = (lineAt: number) => {
              let ctxPlugin = chart.chart.ctx;
              let xAxe = chart.scales[chart.config.options.scales.xAxes[0]?.id];
              let yAxe = chart.scales[chart.config.options.scales.yAxes[0]?.id];

              // I'm not good at maths
              // So I couldn't find a way to make it work ...
              // ... without having the `min` property set to 0
              // if (yAxe.min !== 0) return;
              if (ctxPlugin) {
                ctxPlugin.strokeStyle = "red";
                ctxPlugin.lineWidth = 2;
                ctxPlugin.setLineDash([10, 10]);
                ctxPlugin.beginPath();
                lineAt = (lineAt - yAxe.min) * (100 / yAxe.max);
                lineAt = ((100 - lineAt) / 100) * yAxe.height + yAxe.top;
                ctxPlugin.moveTo(xAxe.left, lineAt);
                ctxPlugin.lineTo(xAxe.right, lineAt);
                ctxPlugin.stroke();
              }
            };

            for (const value of chart.config.options.lineAt) {
              drawLine(value);
            }
          }
        },
      },
    ];
  }, []);

  const displayRefValue = useMemo(() => {
    return getRefValue(refValueTxT, props.genderName || "");
  }, [refValueTxT, props.genderName]);

  const lineAt = useMemo(() => {
    return getStatus({
      refValue: refValueTxT,
      criticalValue: { max: null, min: null },
      value: "0",
      genderName: props.genderName || "",
    }).range;
  }, [refValueTxT, props.genderName]);

  // Effect Memo
  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.chartInstance.config.options.lineAt = lineAt;
    }
  }, [lineAt]);

  const options = useMemo(() => {
    const defaults: any = {
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {
            gridLines: {
              display: true,
            },
            ticks: {
              min: 0,
              beginAtZero: true,
            },
          },
        ],
      },
      legend: {
        display: false,
      },
      lineAt,
    };

    const yAxis = chartRef.current?.chartInstance?.chart?.scales?.["y-axis-0"];
    const yMax = yAxis?.max || 0;

    if (chartRef.current && yMax < lineAt.slice(-1)?.[0]) {
      defaults.scales.yAxes[0].ticks.max = lineAt.slice(-1)?.[0];
    }

    return { ...defaults };
  }, [lineAt, allChartData, refreshOption]);

  const chartData = useMemo(() => {
    return {
      labels: allChartLabel.slice(
        startData < 0 ? 0 : startData,
        startData + LIMIT
      ),
      data: allChartData.slice(
        startData < 0 ? 0 : startData,
        startData + LIMIT
      ),
    };
  }, [allChartData, allChartLabel, startData]);

  const pointBackground = (ctx: any) => {
    var v = ctx.dataset.data[ctx.dataIndex];

    const status = getStatus({
      refValue: refValueTxT,
      criticalValue: { max: null, min: null },
      value: v,
      genderName: props.genderName || "",
    });

    return status.status === "" ? "#31DAEF" : "#FF0000";
  };

  const pointBorder = (ctx: any) => {
    return pointBackground(ctx);
  };

  const handleClose = () => {
    setOpenLabChart(false);
    setOpenLabTable(false);

    setAllChartData([]);
    setAllChartLabel([]);
    setChartTitle("");
    setRefValueTxt("");
    setUnit("");
    setStartData(0);

    props.onClose?.();
  };

  console.log(startData, props.initialDatetime, allChartLabel);

  return (
    <>
      <Modal
        closeIcon
        style={{ left: "unset !important" }}
        size={"large"}
        open={openLabChart}
        onClose={handleClose}
      >
        <div style={{ padding: "20px" }}>
          <Header as="h1" style={{ margin: "0px" }}>
            {chartTitle}
          </Header>
          {refValueTxT && (
            <Header as="h3" color="grey" style={{ margin: "5px 0px 20px 0px" }}>
              {`[${displayRefValue}]`}
            </Header>
          )}

          <Grid columns={3}>
            <Grid.Row>
              <Grid.Column
                width={1}
                style={{
                  padding: "0px 0px 30px 14px",
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <Button
                  style={{ margin: "0px", padding: "10px" }}
                  onClick={() => setStartData(startData - 1)}
                >
                  <Icon
                    className="angle left"
                    size="big"
                    style={{ margin: "0px 0px 5px" }}
                  />
                </Button>
              </Grid.Column>
              <Grid.Column width={14}>
                <Header
                  as="h4"
                  color="grey"
                  style={{ margin: "0px 0px 8px 25px" }}
                >
                  {unit}
                </Header>
                <div style={{ height: "600px", width: "100%" }}>
                  <Line
                    ref={chartRef}
                    // redraw={true}
                    options={options}
                    data={{
                      labels: chartData.labels,
                      datasets: [
                        {
                          label: chartTitle,
                          data: chartData.data,
                          fill: true,
                          backgroundColor: "rgba(190,243,248, 0.6)",
                          pointBackgroundColor: pointBackground,
                          pointBorderColor: pointBorder,
                          pointRadius: 7,
                        },
                      ],
                    }}
                    plugins={plugins}
                  />
                </div>
              </Grid.Column>
              <Grid.Column
                width={1}
                style={{
                  padding: "0px 14px 30px 0px",
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <Button
                  style={{ margin: "0px", padding: "10px" }}
                  onClick={() => setStartData(startData + 1)}
                >
                  <Icon
                    className="angle right"
                    size="big"
                    style={{ margin: "0px 0px 5px" }}
                  />
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </Modal>

      <Modal
        closeIcon
        style={{ left: "unset !important" }}
        size={"large"}
        open={openLabTable}
        onClose={handleClose}
      >
        <div style={{ height: "600px", width: "100%" }}>
          <ReactTableFixedColumns
            // @ts-ignore
            data={props.centralLabResultList?.items}
            showPagination={false}
            columns={
              [
                { Header: "Date", accessor: "created" },
                { Header: "value", accessor: "value" },
              ] as any[]
            }
          />
        </div>
      </Modal>
    </>
  );
};

// Utils

// Pattern
const FLOAT_REGEX = "[+-]?\\d+(\\.\\d+|\\d*)";
const FLOAT_NONE_REGEX = `${FLOAT_REGEX}|None`;
const BETWEEN_REGEX = new RegExp(
  `^(${FLOAT_NONE_REGEX}) *- *(${FLOAT_REGEX})$`,
  "g"
);
const GTE_REGEX = new RegExp(`^>(=|) *(${FLOAT_REGEX})+$`, "g");
const LTE_REGEX = new RegExp(`^<(=|) *(${FLOAT_REGEX})+$`, "g");
const GENDER_REGEX = new RegExp(
  `(Female|Male): *([<|>]=?) *(${FLOAT_REGEX})`,
  "g"
);

export const getRefValue = (refValue: string, genderName: string) => {
  // Female: >=50 ,Male: >=40
  if (!!refValue?.match(GENDER_REGEX)?.length) {
    const matches = Array.from(refValue.matchAll(GENDER_REGEX));

    return matches.find((item) => item?.[1] === genderName)?.[0] || refValue;
  } else {
    return refValue;
  }
};

export const getStatus = (params: {
  refValue: string;
  criticalValue: Record<"max" | "min", number | null>;
  value: string;
  genderName: string;
}) => {
  const number = Number(params.value);

  if (params.value && !Number.isNaN(number)) {
    // Female: >=50 ,Male: >=40
    if (!!params.refValue?.match(GENDER_REGEX)?.length) {
      const matches = Array.from(params.refValue.matchAll(GENDER_REGEX));
      const type: Record<string, string> = {};

      for (const match of matches) {
        const status = match[2].includes("<") ? "H" : "L";
        type[match[1]] = eval(`${number}${match[2]}${match[3]}`) ? "" : status;
      }

      const status = type[params.genderName || ""] || "";
      const match = matches.find((match) => match[1] === params.genderName);

      return {
        status,
        is_critical: false,
        range: match?.[3] ? [Number(match?.[3])] : [],
        refValue: match?.[0] || params.refValue,
      };
    }
    // 10 - 20
    else if (!!params.refValue?.match(BETWEEN_REGEX)?.length) {
      const match = BETWEEN_REGEX.exec(params.refValue) || [];
      const status =
        number < (Number(match[1]) || 0)
          ? "L"
          : number > Number(match[3])
          ? "H"
          : "";
      const isCritical = status
        ? params.criticalValue.max !== null &&
          number >= params.criticalValue.max
          ? true
          : params.criticalValue.min !== null &&
            number <= params.criticalValue.min
          ? true
          : false
        : false;

      return {
        status,
        is_critical: isCritical,
        range: [Number(match[1]) || 0, Number(match[3])],
        refValue: params.refValue,
      };
    }
    // <=10
    else if (!!params.refValue?.match(LTE_REGEX)?.length) {
      const match = LTE_REGEX.exec(params.refValue) || [];
      const status = eval(`${number}${params.refValue}`) ? "" : "H";
      const isCritical =
        !!status &&
        params.criticalValue.max !== null &&
        number >= params.criticalValue.max;

      return {
        status,
        is_critical: isCritical,
        range: [Number(match[2])],
        refValue: params.refValue,
      };
    }
    // >20
    else if (!!params.refValue?.match(GTE_REGEX)?.length) {
      const match = GTE_REGEX.exec(params.refValue) || [];
      const status = eval(`${number}${params.refValue}`) ? "" : "L";
      const isCritical =
        !!status &&
        params.criticalValue.min !== null &&
        number <= params.criticalValue.min;

      return {
        status,
        is_critical: isCritical,
        range: [Number(match[2])],
        refValue: params.refValue,
      };
    }
  }

  return {
    status: "",
    is_critical: false,
    range: [],
    refValue: params.refValue,
  };
};

export default React.memo(ModalLabChart);
