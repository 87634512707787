import React, { useState, SyntheticEvent } from "react";
import { Button } from "semantic-ui-react";

import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import { useIntl } from "react-intl";

const DeleteColumn: React.FC<any> = (props) => {
  const [openModConfirm, setOpenModConfirm] = useState<boolean>(false);

  const handleCancel = (e: SyntheticEvent) => {
    props.onConfirm?.();
    handleCloseModConfirm(e);
  };

  const handleCloseModConfirm = (e: SyntheticEvent) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setOpenModConfirm(false);
  };

  return (
    <>
      {!(!props.value && props.id) && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            color="red"
            icon={props.icon || "minus"}
            circular={props.circular || false}
            disabled={props.disabled}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setOpenModConfirm(true);
            }}
            size={props.size}
          >
            {props?.nameButton || null}
          </Button>
        </div>
      )}
      <ModConfirm
        openModal={openModConfirm}
        titleName={props.titleName ?? "ต้องการลบรายการค่าธรรมเนียมหรือไม่"}
        titleColor={props.titleColor}
        onApprove={handleCancel}
        onDeny={handleCloseModConfirm}
        content={props.content}
      />
    </>
  );
};

export default DeleteColumn;
