import BaseService from "./BaseService";
import { DPI } from "../../configs/apis";
class DPIService extends BaseService {
  /**
   * get admit order
   * @param {object} data
   */
  getIpdOneDay(emrId, query) {
    const url = DPI.IPD_ONE_DAY({ patient_emr_id: emrId });
    return this.client
      .get(url, { params: query })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getIpdContinue(emrId, query) {
    const url = DPI.IPD_CONTINUE({ patient_emr_id: emrId });
    return this.client
      .get(url, { params: query })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  putPlanItem(continuePlanId, params) {
    const url = DPI.PLAN_ITEM({ plan_id: continuePlanId });
    return this.client
      .put(url, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  putCancelDoctororder(oneDayOrderId, params) {
    const url = DPI.CANCEL_DOCTOR_ORDER({ order_id: oneDayOrderId });
    return this.client
      .put(url, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  putAckDoctorOrder(params) {
    return this.client
      .put(DPI.ACK_DOCTOR_ORDER, params)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getIpdContinueHistory(continuePlanId) {
    const url = DPI.IPD_CONTINUE_HISTORY({ plan_item_id: continuePlanId });
    return this.client
      .get(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getProgressionNote(emrId, params) {
    let url = DPI.PROGRESSION_NOTE({ emr: emrId });
    return this.client
      .get(url, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  putProgressNote({ apiToken, emrId, params, data }) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    let url = DPI.PROGRESSION_NOTE({ emr: emrId })
    return this.client.put(url, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  putProgressNoteById({ apiToken, progressNoteId, params, data }) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    let url = DPI.PROGRESSION_NOTE_ID({ id: progressNoteId })
    return this.client.put(url, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  createProgressionNote({ apiToken, params, data } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    return this.client.post(DPI.PROGRESSION_NOTE_LIST, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getAdmissionForm(emrId) {
    let url = DPI.ADMISSION_FORM({ emr: emrId });
    return this.client
      .get(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  putAdmissionForm(emrId, data) {
    let url = DPI.ADMISSION_FORM({ emr: emrId });
    return this.client
      .put(url, data)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getDischargeSummary(emrId) {
    let url = DPI.DISCHARGE_SUMMARY({ emr: emrId });
    return this.client
      .get(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getDiagnosis(emrId) {
    let url = DPI.DIAGNOSIS({ emr: emrId });
    return this.client
      .get(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getProcedure(emrId) {
    let url = DPI.PROCEDURE({ emr: emrId });
    return this.client
      .get(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getDrg(emrId) {
    let url = DPI.DRG({ emr: emrId });
    return this.client
      .get(url)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getPrintDoctorOrder(params) {
    return this.client
      .get(DPI.IPD_DOCTOR_ORDER_PRINT, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
}
export default DPIService;
