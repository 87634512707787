import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  Radio
} from 'semantic-ui-react'
import {
  DateTextBox,
  TimeFreeTextBox24
} from 'react-lib/apps/common'

const CardReAssessmentVenousUX = (props: any) => {
    return(
      <div
        style={{position: "relative", padding: "1rem", marginTop: "1rem"}}>
        <Form>
          <FormGroup
            style={{ display: "flex", alignItems: "center" }}>
            <label
              style={{width: "40rem", whiteSpace: "normal"}}>
              1. Hand Washing ล้างมือ 7 ขั้นตอน ตามหลักการ 5 Moment
            </label>
            <div
              style={{display: "flex", gap: "2rem", padding: "0rem 0rem 0rem 2rem"}}>
              
              <Radio
                checked={props.ReAssessmentSequence?.venousQuestion?.venousQuestOne === "Yes"}
                label="Yes"
                name="venousQuestion.venousQuestOne"
                onChange={props.onChangeData}
                value="Yes">
              </Radio>
              <Radio
                checked={props.ReAssessmentSequence?.venousQuestion?.venousQuestOne === "No"}
                label="No"
                name="venousQuestion.venousQuestOne"
                onChange={props.onChangeData}
                value="No">
              </Radio>
              <Radio
                checked={props.ReAssessmentSequence?.venousQuestion?.venousQuestOne === "NA"}
                label="NA"
                name="venousQuestion.venousQuestOne"
                onChange={props.onChangeData}
                value="NA">
              </Radio>
            </div>
          </FormGroup>
          <FormGroup
            style={{ display: "flex", alignItems: "center" }}>
            <label
              style={{width: "40rem", whiteSpace: "normal"}}>
              2. ตรวจสอบบริเวณจุกปิดของ NSS Lock ให้ปิดสนิททุกครั้ง
            </label>
            <div
              style={{display: "flex", gap: "2rem", padding: "0rem 0rem 0rem 2rem"}}>
              
              <Radio
                checked={props.ReAssessmentSequence?.venousQuestion?.venousQuestTwo === "Yes"}
                label="Yes"
                name="venousQuestion.venousQuestTwo"
                onChange={props.onChangeData}
                value="Yes">
              </Radio>
              <Radio
                checked={props.ReAssessmentSequence?.venousQuestion?.venousQuestTwo === "No"}
                label="No"
                name="venousQuestion.venousQuestTwo"
                onChange={props.onChangeData}
                value="No">
              </Radio>
              <Radio
                checked={props.ReAssessmentSequence?.venousQuestion?.venousQuestTwo === "NA"}
                label="NA"
                name="venousQuestion.venousQuestTwo"
                onChange={props.onChangeData}
                value="NA">
              </Radio>
            </div>
          </FormGroup>
          <FormGroup
            style={{ display: "flex", alignItems: "center" }}>
            <label
              style={{width: "40rem", whiteSpace: "normal"}}>
              3. เมื่อเปิดจุกข้อต่อ ให้เช็ดทำความสะอาดด้วย 70 % alcohol ทุกครั้ง
            </label>
            <div
              style={{display: "flex", gap: "2rem", padding: "0rem 0rem 0rem 2rem"}}>
              
              <Radio
                checked={props.ReAssessmentSequence?.venousQuestion?.venousQuestThree === "Yes"}
                label="Yes"
                name="venousQuestion.venousQuestThree"
                onChange={props.onChangeData}
                value="Yes">
              </Radio>
              <Radio
                checked={props.ReAssessmentSequence?.venousQuestion?.venousQuestThree === "No"}
                label="No"
                name="venousQuestion.venousQuestThree"
                onChange={props.onChangeData}
                value="No">
              </Radio>
              <Radio
                checked={props.ReAssessmentSequence?.venousQuestion?.venousQuestThree === "NA"}
                label="NA"
                name="venousQuestion.venousQuestThree"
                onChange={props.onChangeData}
                value="NA">
              </Radio>
            </div>
          </FormGroup>
          <FormGroup
            style={{ display: "flex", alignItems: "center" }}>
            <label
              style={{width: "40rem", whiteSpace: "normal"}}>
              4. ตรวจสอบบริเวณตำแหน่งที่แทงเข็มปละประเมินระดับความรุนแรงของหลอดเลือดดำอักเสบ (Phlebitis)
            </label>
            <div
              style={{display: "flex", gap: "2rem", padding: "0rem 0rem 0rem 2rem"}}>
              
              <Radio
                checked={props.ReAssessmentSequence?.venousQuestion?.venousQuestFour === "Yes"}
                label="Yes"
                name="venousQuestion.venousQuestFour"
                onChange={props.onChangeData}
                value="Yes">
              </Radio>
              <Radio
                checked={props.ReAssessmentSequence?.venousQuestion?.venousQuestFour === "No"}
                label="No"
                name="venousQuestion.venousQuestFour"
                onChange={props.onChangeData}
                value="No">
              </Radio>
              <Radio
                checked={props.ReAssessmentSequence?.venousQuestion?.venousQuestFour === "NA"}
                label="NA"
                name="venousQuestion.venousQuestFour"
                onChange={props.onChangeData}
                value="NA">
              </Radio>
            </div>
          </FormGroup>
          <FormGroup
            style={{ display: "flex", alignItems: "center" }}>
            <label
              style={{width: "40rem", whiteSpace: "normal"}}>
              5. เปลี่ยนตำแหน่งที่แทงเข็มเมื่อเข็มออกนอกหลอดเลือดหรือเมื่อมีการอักเสบติดเชื้อในตำแหน่งที่แทงเข็มหรือเกิด Phlebitis
            </label>
            <div
              style={{display: "flex", gap: "2rem", padding: "0rem 0rem 0rem 2rem"}}>
              
              <Radio
                checked={props.ReAssessmentSequence?.venousQuestion?.venousQuestFive === "Yes"}
                label="Yes"
                name="venousQuestion.venousQuestFive"
                onChange={props.onChangeData}
                value="Yes">
              </Radio>
              <Radio
                checked={props.ReAssessmentSequence?.venousQuestion?.venousQuestFive === "No"}
                label="No"
                name="venousQuestion.venousQuestFive"
                onChange={props.onChangeData}
                value="No">
              </Radio>
              <Radio
                checked={props.ReAssessmentSequence?.venousQuestion?.venousQuestFive === "NA"}
                label="NA"
                name="venousQuestion.venousQuestFive"
                onChange={props.onChangeData}
                value="NA">
              </Radio>
            </div>
          </FormGroup>
          <FormGroup
            style={{ display: "flex", alignItems: "center" }}>
            <label
              style={{width: "40rem", whiteSpace: "normal"}}>
              6. ติดสติกเกอร์วันเปลี่ยนเข็มทุกครั้งเมื่อเปิดเส้น
            </label>
            <div
              style={{display: "flex", gap: "2rem", padding: "0rem 0rem 0rem 2rem"}}>
              
              <Radio
                checked={props.ReAssessmentSequence?.venousQuestion?.venousQuestSix === "Yes"}
                label="Yes"
                name="venousQuestion.venousQuestSix"
                onChange={props.onChangeData}
                value="Yes">
              </Radio>
              <Radio
                checked={props.ReAssessmentSequence?.venousQuestion?.venousQuestSix === "No"}
                label="No"
                name="venousQuestion.venousQuestSix"
                onChange={props.onChangeData}
                value="No">
              </Radio>
              <Radio
                checked={props.ReAssessmentSequence?.venousQuestion?.venousQuestSix === "NA"}
                label="NA"
                name="venousQuestion.venousQuestSix"
                onChange={props.onChangeData}
                value="NA">
              </Radio>
            </div>
          </FormGroup>
          <FormGroup
            style={{ display: "flex", alignItems: "center" }}>
            <label
              style={{width: "40rem", whiteSpace: "normal"}}>
              7. เปลี่ยนเข็มใหม่ทุกครั้งเมื่อครบ 96 ชม. (นับจากเวลาที่แทงเข็ม)
            </label>
            <div
              style={{display: "flex", gap: "2rem", padding: "0rem 0rem 0rem 2rem"}}>
              
              <Radio
                checked={props.ReAssessmentSequence?.venousQuestion?.venousQuestSeven=== "Yes"}
                label="Yes"
                name="venousQuestion.venousQuestSeven"
                onChange={props.onChangeData}
                value="Yes">
              </Radio>
              <Radio
                checked={props.ReAssessmentSequence?.venousQuestion?.venousQuestSeven=== "No"}
                label="No"
                name="venousQuestion.venousQuestSeven"
                onChange={props.onChangeData}
                value="No">
              </Radio>
              <Radio
                checked={props.ReAssessmentSequence?.venousQuestion?.venousQuestSeven=== "NA"}
                label="NA"
                name="venousQuestion.venousQuestSeven"
                onChange={props.onChangeData}
                value="NA">
              </Radio>
            </div>
          </FormGroup>
          <FormGroup
            style={{ display: "flex", alignItems: "center" }}>
            <label
              style={{width: "40rem", whiteSpace: "normal"}}>
              8. เมื่อบริเวณที่แทงเข็ม หรือพลาสเตอร์มีการปนเปื้อนเลือดหรือคราบสกปรกให้ทำความสะอาด และเปลี่ยน Dressing ใหม่
            </label>
            <div
              style={{display: "flex", gap: "2rem", padding: "0rem 0rem 0rem 2rem"}}>
              
              <Radio
                checked={props.ReAssessmentSequence?.venousQuestion?.venousQuestEight === "Yes"}
                label="Yes"
                name="venousQuestion.venousQuestEight"
                onChange={props.onChangeData}
                value="Yes">
              </Radio>
              <Radio
                checked={props.ReAssessmentSequence?.venousQuestion?.venousQuestEight === "No"}
                label="No"
                name="venousQuestion.venousQuestEight"
                onChange={props.onChangeData}
                value="No">
              </Radio>
              <Radio
                checked={props.ReAssessmentSequence?.venousQuestion?.venousQuestEight === "NA"}
                label="NA"
                name="venousQuestion.venousQuestEight"
                onChange={props.onChangeData}
                value="NA">
              </Radio>
            </div>
          </FormGroup>
        </Form>
        <div
          style={{ display: "flex", justifyContent: "right", alignItems: "center" }}>
          
          <label
            style={{ display: "flex", alignItems: "center", margin: "0rem 2rem", fontWeight: "bold"  }}>
            Shift
          </label>
          <Radio
            checked={props.ReAssessmentSequence?.venousData?.saveShift === "Day"}
            label="Day"
            name="venousData.saveShift"
            onChange={props.onChangeData}
            style={{ display: "flex", alignItems: "center"  }}
            value="Day">
          </Radio>
          <Radio
            checked={props.ReAssessmentSequence?.venousData?.saveShift === "Night"}
            label="Night"
            name="venousData.saveShift"
            onChange={props.onChangeData}
            style={{ display: "flex", alignItems: "center", margin: "0rem 1rem"  }}
            value="Night">
          </Radio>
          <label
            style={{ display: "flex", alignItems: "center", margin: "0rem 2rem", fontWeight: "bold"  }}>
            ประเมินวันที่
          </label>
          <DateTextBox
            onChange={props.onChangeDateVenous}
            value={props.ReAssessmentSequence?.venousData?.saveData}>
          </DateTextBox>
          <label
            style={{ display: "flex", alignItems: "center", margin: "0rem 2rem", fontWeight: "bold"  }}>
            เวลา
          </label>
          <TimeFreeTextBox24
            autoFocus={false}
            onChange={props.onChangeTimeVenous}
            value={props.ReAssessmentSequence?.venousData?.saveTime}>
          </TimeFreeTextBox24>
          <div
            style={{ margin: "0rem 0rem 0rem 2rem" }}>
            {props?.buttonSaveVenous}
          </div>
        </div>
      </div>
    )
}


export default CardReAssessmentVenousUX

export const screenPropsDefault = {}

/* Date Time : Wed Oct 02 2024 14:59:43 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{position: \"relative\", padding: \"1rem\", marginTop: \"1rem\"}"
        }
      },
      "seq": 0
    },
    {
      "from": "semantic-ui-react",
      "id": 1,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 2,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "label",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "1. Hand Washing ล้างมือ 7 ขั้นตอน ตามหลักการ 5 Moment"
        },
        "style": {
          "type": "code",
          "value": "{width: \"40rem\", whiteSpace: \"normal\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", gap: \"2rem\", padding: \"0rem 0rem 0rem 2rem\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "Radio",
      "parent": 4,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.venousQuestion?.venousQuestOne === \"Yes\""
        },
        "label": {
          "type": "value",
          "value": "Yes"
        },
        "name": {
          "type": "value",
          "value": "venousQuestion.venousQuestOne"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "Yes"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "Radio",
      "parent": 4,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.venousQuestion?.venousQuestOne === \"No\""
        },
        "label": {
          "type": "value",
          "value": "No"
        },
        "name": {
          "type": "value",
          "value": "venousQuestion.venousQuestOne"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "No"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "Radio",
      "parent": 4,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.venousQuestion?.venousQuestOne === \"NA\""
        },
        "label": {
          "type": "value",
          "value": "NA"
        },
        "name": {
          "type": "value",
          "value": "venousQuestion.venousQuestOne"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "NA"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "FormGroup",
      "parent": 1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "2. ตรวจสอบบริเวณจุกปิดของ NSS Lock ให้ปิดสนิททุกครั้ง"
        },
        "style": {
          "type": "code",
          "value": "{width: \"40rem\", whiteSpace: \"normal\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "label",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "3. เมื่อเปิดจุกข้อต่อ ให้เช็ดทำความสะอาดด้วย 70 % alcohol ทุกครั้ง"
        },
        "style": {
          "type": "code",
          "value": "{width: \"40rem\", whiteSpace: \"normal\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "label",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "4. ตรวจสอบบริเวณตำแหน่งที่แทงเข็มปละประเมินระดับความรุนแรงของหลอดเลือดดำอักเสบ (Phlebitis)"
        },
        "style": {
          "type": "code",
          "value": "{width: \"40rem\", whiteSpace: \"normal\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": null,
      "id": 18,
      "name": "label",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": "5. เปลี่ยนตำแหน่งที่แทงเข็มเมื่อเข็มออกนอกหลอดเลือดหรือเมื่อมีการอักเสบติดเชื้อในตำแหน่งที่แทงเข็มหรือเกิด Phlebitis"
        },
        "style": {
          "type": "code",
          "value": "{width: \"40rem\", whiteSpace: \"normal\"}"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "label",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "6. ติดสติกเกอร์วันเปลี่ยนเข็มทุกครั้งเมื่อเปิดเส้น"
        },
        "style": {
          "type": "code",
          "value": "{width: \"40rem\", whiteSpace: \"normal\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "label",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": "7. เปลี่ยนเข็มใหม่ทุกครั้งเมื่อครบ 96 ชม. (นับจากเวลาที่แทงเข็ม)"
        },
        "style": {
          "type": "code",
          "value": "{width: \"40rem\", whiteSpace: \"normal\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "label",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": "8. เมื่อบริเวณที่แทงเข็ม หรือพลาสเตอร์มีการปนเปื้อนเลือดหรือคราบสกปรกให้ทำความสะอาด และเปลี่ยน Dressing ใหม่"
        },
        "style": {
          "type": "code",
          "value": "{width: \"40rem\", whiteSpace: \"normal\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", gap: \"2rem\", padding: \"0rem 0rem 0rem 2rem\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", gap: \"2rem\", padding: \"0rem 0rem 0rem 2rem\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", gap: \"2rem\", padding: \"0rem 0rem 0rem 2rem\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", gap: \"2rem\", padding: \"0rem 0rem 0rem 2rem\"}"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", gap: \"2rem\", padding: \"0rem 0rem 0rem 2rem\"}"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", gap: \"2rem\", padding: \"0rem 0rem 0rem 2rem\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", gap: \"2rem\", padding: \"0rem 0rem 0rem 2rem\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "Radio",
      "parent": 22,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.venousQuestion?.venousQuestTwo === \"Yes\""
        },
        "label": {
          "type": "value",
          "value": "Yes"
        },
        "name": {
          "type": "value",
          "value": "venousQuestion.venousQuestTwo"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "Yes"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "Radio",
      "parent": 22,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.venousQuestion?.venousQuestTwo === \"No\""
        },
        "label": {
          "type": "value",
          "value": "No"
        },
        "name": {
          "type": "value",
          "value": "venousQuestion.venousQuestTwo"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "No"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "Radio",
      "parent": 22,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.venousQuestion?.venousQuestTwo === \"NA\""
        },
        "label": {
          "type": "value",
          "value": "NA"
        },
        "name": {
          "type": "value",
          "value": "venousQuestion.venousQuestTwo"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "NA"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "Radio",
      "parent": 23,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.venousQuestion?.venousQuestThree === \"Yes\""
        },
        "label": {
          "type": "value",
          "value": "Yes"
        },
        "name": {
          "type": "value",
          "value": "venousQuestion.venousQuestThree"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "Yes"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "Radio",
      "parent": 23,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.venousQuestion?.venousQuestThree === \"No\""
        },
        "label": {
          "type": "value",
          "value": "No"
        },
        "name": {
          "type": "value",
          "value": "venousQuestion.venousQuestThree"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "No"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "Radio",
      "parent": 23,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.venousQuestion?.venousQuestThree === \"NA\""
        },
        "label": {
          "type": "value",
          "value": "NA"
        },
        "name": {
          "type": "value",
          "value": "venousQuestion.venousQuestThree"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "NA"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "Radio",
      "parent": 24,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.venousQuestion?.venousQuestFour === \"Yes\""
        },
        "label": {
          "type": "value",
          "value": "Yes"
        },
        "name": {
          "type": "value",
          "value": "venousQuestion.venousQuestFour"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "Yes"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "Radio",
      "parent": 24,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.venousQuestion?.venousQuestFour === \"No\""
        },
        "label": {
          "type": "value",
          "value": "No"
        },
        "name": {
          "type": "value",
          "value": "venousQuestion.venousQuestFour"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "No"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "Radio",
      "parent": 24,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.venousQuestion?.venousQuestFour === \"NA\""
        },
        "label": {
          "type": "value",
          "value": "NA"
        },
        "name": {
          "type": "value",
          "value": "venousQuestion.venousQuestFour"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "NA"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "Radio",
      "parent": 25,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.venousQuestion?.venousQuestFive === \"Yes\""
        },
        "label": {
          "type": "value",
          "value": "Yes"
        },
        "name": {
          "type": "value",
          "value": "venousQuestion.venousQuestFive"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "Yes"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "Radio",
      "parent": 25,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.venousQuestion?.venousQuestFive === \"No\""
        },
        "label": {
          "type": "value",
          "value": "No"
        },
        "name": {
          "type": "value",
          "value": "venousQuestion.venousQuestFive"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "No"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "Radio",
      "parent": 25,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.venousQuestion?.venousQuestFive === \"NA\""
        },
        "label": {
          "type": "value",
          "value": "NA"
        },
        "name": {
          "type": "value",
          "value": "venousQuestion.venousQuestFive"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "NA"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "Radio",
      "parent": 26,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.venousQuestion?.venousQuestSix === \"Yes\""
        },
        "label": {
          "type": "value",
          "value": "Yes"
        },
        "name": {
          "type": "value",
          "value": "venousQuestion.venousQuestSix"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "Yes"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "Radio",
      "parent": 26,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.venousQuestion?.venousQuestSix === \"No\""
        },
        "label": {
          "type": "value",
          "value": "No"
        },
        "name": {
          "type": "value",
          "value": "venousQuestion.venousQuestSix"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "No"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "Radio",
      "parent": 26,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.venousQuestion?.venousQuestSix === \"NA\""
        },
        "label": {
          "type": "value",
          "value": "NA"
        },
        "name": {
          "type": "value",
          "value": "venousQuestion.venousQuestSix"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "NA"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "Radio",
      "parent": 27,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.venousQuestion?.venousQuestSeven=== \"Yes\""
        },
        "label": {
          "type": "value",
          "value": "Yes"
        },
        "name": {
          "type": "value",
          "value": "venousQuestion.venousQuestSeven"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "Yes"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "Radio",
      "parent": 27,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.venousQuestion?.venousQuestSeven=== \"No\""
        },
        "label": {
          "type": "value",
          "value": "No"
        },
        "name": {
          "type": "value",
          "value": "venousQuestion.venousQuestSeven"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "No"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "Radio",
      "parent": 27,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.venousQuestion?.venousQuestSeven=== \"NA\""
        },
        "label": {
          "type": "value",
          "value": "NA"
        },
        "name": {
          "type": "value",
          "value": "venousQuestion.venousQuestSeven"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "NA"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "Radio",
      "parent": 28,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.venousQuestion?.venousQuestEight === \"Yes\""
        },
        "label": {
          "type": "value",
          "value": "Yes"
        },
        "name": {
          "type": "value",
          "value": "venousQuestion.venousQuestEight"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "Yes"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "Radio",
      "parent": 28,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.venousQuestion?.venousQuestEight === \"No\""
        },
        "label": {
          "type": "value",
          "value": "No"
        },
        "name": {
          "type": "value",
          "value": "venousQuestion.venousQuestEight"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "No"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "Radio",
      "parent": 28,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.venousQuestion?.venousQuestEight === \"NA\""
        },
        "label": {
          "type": "value",
          "value": "NA"
        },
        "name": {
          "type": "value",
          "value": "venousQuestion.venousQuestEight"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "NA"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "label",
      "parent": 59,
      "props": {
        "children": {
          "type": "value",
          "value": "Shift"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0rem 2rem\", fontWeight: \"bold\"  }"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "Radio",
      "parent": 59,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.venousData?.saveShift === \"Day\""
        },
        "label": {
          "type": "value",
          "value": "Day"
        },
        "name": {
          "type": "value",
          "value": "venousData.saveShift"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "Day"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "Radio",
      "parent": 59,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.venousData?.saveShift === \"Night\""
        },
        "label": {
          "type": "value",
          "value": "Night"
        },
        "name": {
          "type": "value",
          "value": "venousData.saveShift"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0rem 1rem\"  }"
        },
        "value": {
          "type": "value",
          "value": "Night"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "label",
      "parent": 59,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเมินวันที่"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0rem 2rem\", fontWeight: \"bold\"  }"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 55,
      "name": "DateTextBox",
      "parent": 59,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeDateVenous"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.venousData?.saveData"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "label",
      "parent": 59,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลา"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0rem 2rem\", fontWeight: \"bold\"  }"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 57,
      "name": "TimeFreeTextBox24",
      "parent": 59,
      "props": {
        "autoFocus": {
          "type": "code",
          "value": "false"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeTimeVenous"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.venousData?.saveTime"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 59,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.buttonSaveVenous"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0rem 0rem 0rem 2rem\" }"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"right\", alignItems: \"center\" }"
        }
      },
      "seq": 59,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardReAssessmentVenousUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
