import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  DateTextBox
} from 'react-lib/apps/common'
import {
  Icon,
  Button
} from 'semantic-ui-react'

const CardReAssessmentHistoryUX = (props: any) => {
    return(
      <div>
        <div
          style={{ display: "flex", justifyContent: "center",  fontSize: "17px", fontWeight: "bold", alignItems: "center", background: "rgba(161, 221, 230, 0.49)", padding: "15px", margin: "0px 0px 20px" }}>
          {props.title}
        </div>
        <div
          style={{ margin: "0px 15px" }}>
          
          <div
            style={{ display: "flex", justifyContent: 'space-between' }}>
            
            <div
              style={{ display: "flex", alignItems: "center" }}>
              
              <div
                style={{ marginRight: "10px" }}>
                วันที่
              </div>
              <DateTextBox
                onChange={props.onChangeStartDate}
                style={{ marginRight: "10px" }}
                value={props.startDate}>
              </DateTextBox>
              <div
                style={{ marginRight: "10px" }}>
                ถึง
              </div>
              <DateTextBox
                onChange={props.onChangeEndDate}
                style={{ marginRight: "10px" }}
                value={props.endDate}>
              </DateTextBox>
              <Button
                color="blue"
                onClick={props.onSearch}
                style={{ marginLeft: "20px"}}>
                ค้นหา
              </Button>
            </div>
            <div
              style={{ display: "flex", alignItems: "center" }}>
              
              <div
                style={{ border: "1px solid #000000", padding: "5px 15px", borderTopLeftRadius: "15%", borderBottomLeftRadius: "15%" }}>
                {}
                <Icon
                  name="chevron left"
                  onClick={props.sliceA}>
                </Icon>
              </div>
              <div
                style={{ border: "1px solid #000000", padding: "5px 15px", borderTopRightRadius: "15%", borderBottomRightRadius: "15%" }}>
                
                <Icon
                  name="chevron right"
                  onClick={props.sliceB}>
                </Icon>
              </div>
            </div>
          </div>
          <div
            style={{ margin: "15px 0px"}}>
            {props.tableData}
          </div>
        </div>
        <div
          style={{ marginLeft: "15px" }}>
          
          <div
            style={{ display: "flex", alignItems: "center", margin: "0px 10px" }}>
            
            <div
              style={{ width: "4%", fontWeight: "bold" }}>
              
            </div>
            <div
              style={{ width: "80%", display: "flex"}}>
              
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "0px 10px" }}>
            
            <div
              style={{ width: "10%", fontWeight: "bold" }}>
              
            </div>
          </div>
        </div>
      </div>
    )
}

export default CardReAssessmentHistoryUX

export const screenPropsDefault = {}

/* Date Time : Tue Apr 11 2023 10:17:44 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1034,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"15px\" }"
        }
      },
      "seq": 1259,
      "void": false
    },
    {
      "from": null,
      "id": 1036,
      "name": "div",
      "parent": 1034,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0px 10px\" }"
        }
      },
      "seq": 1036,
      "void": false
    },
    {
      "from": null,
      "id": 1037,
      "name": "div",
      "parent": 1034,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0px 10px\" }"
        }
      },
      "seq": 1037,
      "void": false
    },
    {
      "from": null,
      "id": 1039,
      "name": "div",
      "parent": 1036,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"4%\", fontWeight: \"bold\" }"
        }
      },
      "seq": 1039,
      "void": false
    },
    {
      "from": null,
      "id": 1040,
      "name": "div",
      "parent": 1036,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"80%\", display: \"flex\"}"
        }
      },
      "seq": 1040,
      "void": false
    },
    {
      "from": null,
      "id": 1041,
      "name": "div",
      "parent": 1037,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10%\", fontWeight: \"bold\" }"
        }
      },
      "seq": 1041,
      "void": false
    },
    {
      "from": null,
      "id": 1062,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.title"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"center\",  fontSize: \"17px\", fontWeight: \"bold\", alignItems: \"center\", background: \"rgba(161, 221, 230, 0.49)\", padding: \"15px\", margin: \"0px 0px 20px\" }"
        }
      },
      "seq": 571,
      "void": false
    },
    {
      "from": null,
      "id": 1063,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 15px\" }"
        }
      },
      "seq": 574,
      "void": false
    },
    {
      "from": null,
      "id": 1316,
      "name": "div",
      "parent": 1063,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: 'space-between' }"
        }
      },
      "seq": 1316,
      "void": false
    },
    {
      "from": null,
      "id": 1317,
      "name": "div",
      "parent": 1063,
      "props": {
        "children": {
          "type": "code",
          "value": "props.tableData"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"15px 0px\"}"
        }
      },
      "seq": 1317,
      "void": false
    },
    {
      "from": null,
      "id": 1318,
      "name": "div",
      "parent": 1316,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 1318,
      "void": false
    },
    {
      "from": null,
      "id": 1319,
      "name": "div",
      "parent": 1316,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\" }"
        }
      },
      "seq": 1319,
      "void": false
    },
    {
      "from": null,
      "id": 1320,
      "name": "div",
      "parent": 1318,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        }
      },
      "seq": 1320,
      "void": false
    },
    {
      "from": null,
      "id": 1321,
      "name": "div",
      "parent": 1318,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        }
      },
      "seq": 1322,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 1322,
      "name": "DateTextBox",
      "parent": 1318,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeStartDate"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        },
        "value": {
          "type": "code",
          "value": "props.startDate"
        }
      },
      "seq": 1321,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 1323,
      "name": "DateTextBox",
      "parent": 1318,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeEndDate"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        },
        "value": {
          "type": "code",
          "value": "props.endDate"
        }
      },
      "seq": 1323,
      "void": false
    },
    {
      "from": null,
      "id": 1324,
      "name": "div",
      "parent": 1319,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ border: \"1px solid #000000\", padding: \"5px 15px\", borderTopLeftRadius: \"15%\", borderBottomLeftRadius: \"15%\" }"
        }
      },
      "seq": 1324,
      "void": false
    },
    {
      "from": null,
      "id": 1325,
      "name": "div",
      "parent": 1319,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ border: \"1px solid #000000\", padding: \"5px 15px\", borderTopRightRadius: \"15%\", borderBottomRightRadius: \"15%\" }"
        }
      },
      "seq": 1325,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1326,
      "name": "Icon",
      "parent": 1324,
      "props": {
        "name": {
          "type": "value",
          "value": "chevron left"
        },
        "onClick": {
          "type": "code",
          "value": "props.sliceA"
        }
      },
      "seq": 1326,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1327,
      "name": "Icon",
      "parent": 1325,
      "props": {
        "name": {
          "type": "value",
          "value": "chevron right"
        },
        "onClick": {
          "type": "code",
          "value": "props.sliceB"
        }
      },
      "seq": 1327,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 1328,
      "name": "Button",
      "parent": 1318,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSearch"
        },
        "size": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"20px\"}"
        }
      },
      "seq": 1328,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 90,
  "isMounted": false,
  "memo": false,
  "name": "CardReAssessmentHistoryUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
