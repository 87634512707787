import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Icon,
  Form,
  FormGroup,
  FormField,
  Input
} from 'semantic-ui-react'

const ModSummaryZipFileUX = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        style={{backgroundColor: "#FFFFF",width: "100%",height:"100%",padding:"1rem"}}>
        <div
          style={{display: "flex", alignItems: "center"}}>
          
          <label
            style={{fontWeight: "bold", fontSize: "1.1rem"}}>
            
            <label>
              {props.title || "-"}
            </label>
          </label>
          <div
            style={{flex:1}}>
            
          </div>
          <Icon
            className="red"
            link={true}
            name="close"
            onClick={props.onClose}>
          </Icon>
        </div>
        <div
          className="ui divider">
          
        </div>
        <Form
          className="--override-disabled --grey">
          <FormGroup
            inline={true}
            style={{margin: "2rem 0 1.5rem"}}>
            <FormField
              inline={true}
              width={8}>
              <label
                style={{minWidth: "10rem", maxWidth: "10rem"}}>
                จำนวนข้อมูลที่ส่งเบิกได้
              </label>
              <Input
                disabled={true}
                value={props.formattedSummary?.total_rows ?? ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}
              width={8}>
              <label
                style={{minWidth: "10rem", maxWidth: "10rem"}}>
                ยอดเบิก
              </label>
              <Input
                disabled={true}
                value={props.formattedSummary?.total_sent_claim_price|| ""}>
              </Input>
            </FormField>
          </FormGroup>
          <FormGroup
            inline={true}
            style={{marginBottom: "1.5rem"}}>
            <FormField
              inline={true}
              width={8}>
              <label
                style={{minWidth: "10rem", maxWidth: "10rem"}}>
                ยอดผู้ป่วยจ่าย
              </label>
              <Input
                disabled={true}
                value={props.formattedSummary?.total_paid_price|| props.formattedSummary?.total_paid|| ""}>
              </Input>
            </FormField>
            <FormField
              inline={true}
              width={8}>
              <label
                style={{minWidth: "10rem", maxWidth: "10rem"}}>
                ยอดร่วมจ่าย
              </label>
              <Input
                disabled={true}
                value={props.formattedSummary?.total_other_pay_price|| props.formattedSummary?.total_other_pay || ""}>
              </Input>
            </FormField>
          </FormGroup>
        </Form>
      </div>
    )
}

ModSummaryZipFileUX.displayName = "ModSummaryZipFileUX";
export default React.memo(ModSummaryZipFileUX)

export const screenPropsDefault = {}

/* Date Time : Thu Nov 14 2024 12:38:58 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"#FFFFF\",width: \"100%\",height:\"100%\",padding:\"1rem\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 58,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "label",
      "parent": 58,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.1rem\"}"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 66,
      "name": "label",
      "parent": 59,
      "props": {
        "children": {
          "type": "code",
          "value": "props.title || \"-\""
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 112,
      "name": "div",
      "parent": 58,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:1}"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 113,
      "name": "Icon",
      "parent": 58,
      "props": {
        "className": {
          "type": "value",
          "value": "red"
        },
        "link": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "close"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 114,
      "name": "Form",
      "parent": 0,
      "props": {
        "className": {
          "type": "value",
          "value": "--override-disabled --grey"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 115,
      "name": "FormGroup",
      "parent": 114,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"2rem 0 1.5rem\"}"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 116,
      "name": "FormGroup",
      "parent": 114,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"1.5rem\"}"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 117,
      "name": "FormField",
      "parent": 115,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 118,
      "name": "FormField",
      "parent": 115,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 121,
      "name": "FormField",
      "parent": 116,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 122,
      "name": "FormField",
      "parent": 116,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "8"
        }
      },
      "seq": 122,
      "void": false
    },
    {
      "from": null,
      "id": 123,
      "name": "label",
      "parent": 121,
      "props": {
        "children": {
          "type": "value",
          "value": "ยอดผู้ป่วยจ่าย"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"10rem\", maxWidth: \"10rem\"}"
        }
      },
      "seq": 123,
      "void": false
    },
    {
      "from": null,
      "id": 124,
      "name": "label",
      "parent": 122,
      "props": {
        "children": {
          "type": "value",
          "value": "ยอดร่วมจ่าย"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"10rem\", maxWidth: \"10rem\"}"
        }
      },
      "seq": 124,
      "void": false
    },
    {
      "from": null,
      "id": 125,
      "name": "label",
      "parent": 118,
      "props": {
        "children": {
          "type": "value",
          "value": "ยอดเบิก"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"10rem\", maxWidth: \"10rem\"}"
        }
      },
      "seq": 125,
      "void": false
    },
    {
      "from": null,
      "id": 126,
      "name": "label",
      "parent": 117,
      "props": {
        "children": {
          "type": "value",
          "value": "จำนวนข้อมูลที่ส่งเบิกได้"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"10rem\", maxWidth: \"10rem\"}"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 127,
      "name": "Input",
      "parent": 122,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.formattedSummary?.total_other_pay_price|| props.formattedSummary?.total_other_pay || \"\""
        }
      },
      "seq": 127,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 128,
      "name": "Input",
      "parent": 121,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.formattedSummary?.total_paid_price|| props.formattedSummary?.total_paid|| \"\""
        }
      },
      "seq": 128,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 129,
      "name": "Input",
      "parent": 118,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.formattedSummary?.total_sent_claim_price|| \"\""
        }
      },
      "seq": 129,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 130,
      "name": "Input",
      "parent": 117,
      "props": {
        "disabled": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.formattedSummary?.total_rows ?? \"\""
        }
      },
      "seq": 130,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 35,
  "isMounted": true,
  "memo": true,
  "name": "ModSummaryZipFileUX",
  "project": "CLM CU",
  "screenPropsDefault": {
  },
  "width": 75
}

*********************************************************************************** */
