import React, { useState, useRef, useEffect, memo, useContext } from "react";
import {
  Segment,
  Button,
  Dimmer,
  Loader,
  Input,
  Icon,
  Checkbox
} from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import { injectIntl } from "react-intl";
import { AppContext } from "../patient-lib/PatientContext";

const DrugAddressConfirm = React.forwardRef((props, ref) => {
  const [isLoading, setIsLoading] = useState(false);
  const [shippingAddress, setShippingAddress] = useState({ address: {} });
  const [readOnly, setReadOnly] = useState(false);
  const [error, setError] = useState(null);
  const [receiverName, setReceiverName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [receiveAtHos, setReceiveAtHos] = useState(false);

  const isMounted = useRef(true);
  const history = useHistory();

  const context = useContext(AppContext)

  React.useImperativeHandle(ref, () => ({
    goBack: () => {
      if (props.location.channelId) {
        history.replace(`/Chat/${props.location.channelId}`);
      } else {
        history.replace("/");
      }
    }
  }));

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!props.apiToken && !Cookies.get("apiToken")) {
      props.noApiToken();
    }
  }, [props.apiToken, Cookies.get("apiToken")]);

  useEffect(() => {
    if (
      (props.apiToken ||  Cookies.get("apiToken")) &&
      props.match.params.encounterId
    ) {
      getShippingAddress();
      getDefaultPatientAddressList();
    }
  }, [
    props.apiToken,
    Cookies.get("apiToken"),
    props.match.params && props.match.params.encounterId
  ]);

  useEffect(() => {
    if (props.patientData && props.patientData.patient_name) {
      setReceiverName(props.patientData.patient_name);
    }
  }, [props.patientData && props.patientData.patient_name]);

  useEffect(() => {
    if (props.patientData && props.patientData.phone_no) {
      setPhoneNo(props.patientData.phone_no);
    }
  }, [props.patientData && props.patientData.phone_no]);

  const getShippingAddress = async () => {
    setIsLoading(true);
    const [res, err, network] = await props.controller.getShippingAddress({
      apiToken: props.apiToken ? props.apiToken :  Cookies.get("apiToken"),
      encounterId: props.match.params.encounterId
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (res) {
        if (res.items.length > 0) {
          setReadOnly(true);
          setShippingAddress(res.items[0]);
          setPhoneNo(res.items[0].receiver_tel);
          setReceiverName(res.items[0].receiver_name);
        }
      }
    }
  };

  const handleGoToSelectAddress = () => {
    if (!readOnly) {
      history.push({
        pathname: `/select-address/${props.match.params.encounterId}`,
        channelId: props.location.channelId,
        shippingAddress
      });
    }
  };

  useEffect(() => {
    if (props.location && props.location.patientAddress) {
      setShippingAddress(props.location.patientAddress);
    }
  }, [props.location && props.location.patientAddress]);

  const displayAddress = address => {
    if (!address) {
      return;
    }
    let districtField = "district_label"
    let cityField = "city_label"
    let provinceField = "province_label"
    if(context && context.language === "en-US"){
      districtField = "district_en_label"
      cityField = "city_en_label"
      provinceField = "province_en_label"
    }
    let no = address.no ? `${address.no} ` : "";
    let district = address[districtField] ? `${address[districtField]} ` : "";
    let city = address[cityField] ? `${address[cityField]} ` : "";
    let province = address[provinceField] ? `${address[provinceField]} ` : "";
    let zipcode = address.zipcode ? `${address.zipcode} ` : "";
    let note = address.note ? `${address.note} ` : "";
    let text = note + no + district + city + province + zipcode;
    if (!text) {
      // text = "กรุณาเลือกที่อยู่จัดส่ง"
    }
    return (
      <>
        <p>{text}</p>
      </>
    );
  };

  const confirmShippingAddress = () => {
    setError(null);
    if (
      (!receiveAtHos && !shippingAddress.hasOwnProperty("id")) ||
      !receiverName ||
      !phoneNo
    ) {
      setError("กรุณากรอกข้อมูลให้ครบถ้วน");
    }
    postShippingAddress();
  };

  const handleReceiverNameChange = e => {
    const { value } = e.target;
    setReceiverName(value);
  };

  const handlePhoneNoChange = e => {
    const { value } = e.target;
    setPhoneNo(value);
  };

  const handleCheckboxChange = () => {
    if (!readOnly) {
      setReceiveAtHos(!receiveAtHos);
    }
  };

  const getDefaultPatientAddressList = async () => {
    setIsLoading(true);
    const [res, err, network] = await props.controller.getPatientAddressList({
      apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
      patientId: props.patientData.patient,
      defaultAddress: true
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (res) {
        if (res.items.length > 0 && !props.location.patientAddress) {
          setShippingAddress(res.items[0]);
        }
      }
    }
  };

  const postShippingAddress = async () => {
    setIsLoading(true);
    if (!receiveAtHos && !shippingAddress.id) {
      setIsLoading(false);
      return;
    }
    const [res, err, network] = await props.controller.postShippingAddress({
      apiToken: props.apiToken ? props.apiToken :  Cookies.get("apiToken"),
      patientAddressId: shippingAddress.id,
      encounterId: props.match.params.encounterId,
      selfPickup: receiveAtHos,
      receiverName,
      receiverTel: phoneNo
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (res) {
        if (props.location && props.location.channelId) {
          history.push(`/Chat/${props.location.channelId}`);
        } else {
          history.push("/");
        }
      } else if (err) {
        if (err.length > 200) {
          setError(
            `${network.response.status}: ${network.response.statusText}`
          );
        } else {
          setError(err);
        }
      }
    }
  };

  return (
    <div className="address-confirm">
      {props.menuBar}
      <Dimmer.Dimmable>
        <Dimmer active={isLoading} inverted>
          <Loader />
        </Dimmer>
        <div className="content">
          <ErrorMessage error={error} />
          <h3>{`${props.intl.formatMessage({
            id: "drug_delivery.check_and_confirm"
          })}`}</h3>
          <div className="receiver">
            <h5>
              {props.intl.formatMessage({ id: "drug_delivery.receiver_name" })}
              <span className="required">*</span>
              &ensp;&ensp;
            </h5>
            <div className="receiver-input">
              <Input
                fluid
                className="bottom-border"
                value={receiverName}
                onChange={handleReceiverNameChange}
                readOnly={readOnly}
              />
            </div>
          </div>
          <Segment onClick={handleGoToSelectAddress}>
            <div className="address">
              <h3>
                {props.intl.formatMessage({
                  id: "drug_delivery.shipping_address"
                })}
                {!receiveAtHos && <span className="required">*</span>}
                <Icon name="map marker alternate" />
              </h3>
              <Icon name="angle right" size="large" />
            </div>
            {shippingAddress.address
              ? displayAddress(shippingAddress.address)
              : displayAddress(shippingAddress)}
          </Segment>
          <p>
            <b>{props.intl.formatMessage({ id: "common.note" })}</b>
            {`: ${props.intl.formatMessage({
              id: "drug_delivery.delivery_note"
            })}`}
          </p>
          <div className="contact-tel">
            <h5>
              {props.intl.formatMessage({ id: "drug_delivery.contact_no" })}
              <span className="required">*</span>
              &ensp;&ensp;
            </h5>
            <div className="contact-tel-input">
              <Input
                fluid
                className="bottom-border"
                value={phoneNo}
                onChange={handlePhoneNoChange}
                readOnly={readOnly}
              />
            </div>
          </div>
          <br />
          <Checkbox
            label={props.intl.formatMessage({
              id: "drug_delivery.receive_at_hospital"
            })}
            checked={receiveAtHos}
            onChange={handleCheckboxChange}
          />
          <p className="hos-address">
            {props.intl.formatMessage({ id: "drug_delivery.hospital_address" })}
            <br />
            {props.intl.formatMessage({ id: "profile.tel_no" })} 02-310-3130
          </p>
        </div>
      </Dimmer.Dimmable>
      {!readOnly && (
        <div className="btn-group">
          <Button
            content={props.intl.formatMessage({ id: "common.cancel" })}
            fluid
            className="inverted-btn"
            onClick={props.onCancel}
          />
          <Button
            content={props.intl.formatMessage({ id: "common.confirm" })}
            fluid
            onClick={confirmShippingAddress}
            disabled={
              (!receiveAtHos || !receiverName || !phoneNo) &&
              (!shippingAddress || !receiverName || !phoneNo)
            }
          />
        </div>
      )}
    </div>
  );
});

DrugAddressConfirm.defaultProps = {
  onCancel: () => {}
};

export default memo(injectIntl(DrugAddressConfirm, { forwardRef: true }));
