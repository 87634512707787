import React from 'react'
import {
   Button,
   Checkbox,
   Dropdown,
   Input,
   Radio,
   TextArea
} from 'semantic-ui-react'

import styles from './CardPODiagnosisOrthoticfootPremorbidIndependentLOW.module.css'

export interface CardPODiagnosisOrthoticfootPremorbidIndependentLOWViewDataType {
    PoDiagnosisOrthofootLow2: string,
    PoDiagnosisOrthofootLow2Disabled: boolean,
    PoDiagnosisOrthofootLow4: string,
    PoDiagnosisOrthofootLow4Disabled: boolean,
    PoDiagnosisOrthofootLow8: string,
    PoDiagnosisOrthofootLow8Disabled: boolean,
    PoDiagnosisOrthofootLow11: boolean,
    PoDiagnosisOrthofootLow11Disabled: boolean,
    PoDiagnosisOrthofootLow12: boolean,
    PoDiagnosisOrthofootLow12Disabled: boolean,
    PoDiagnosisOrthofootLow13: boolean,
    PoDiagnosisOrthofootLow13Disabled: boolean,
    PoDiagnosisOrthofootLow15: boolean,
    PoDiagnosisOrthofootLow15Disabled: boolean,
    PoDiagnosisOrthofootLow18: boolean,
    PoDiagnosisOrthofootLow18Disabled: boolean,
    PoDiagnosisOrthofootLow22: boolean,
    PoDiagnosisOrthofootLow22Disabled: boolean,
    PoDiagnosisOrthofootLow24: boolean,
    PoDiagnosisOrthofootLow24Disabled: boolean,
    PoDiagnosisOrthofootLow29: boolean,
    PoDiagnosisOrthofootLow29Disabled: boolean,
    PoDiagnosisOrthofootLow30: boolean,
    PoDiagnosisOrthofootLow30Disabled: boolean,
    PoDiagnosisOrthofootLow31: string,
    PoDiagnosisOrthofootLow31Disabled: boolean,
    PoDiagnosisOrthofootLow32: boolean,
    PoDiagnosisOrthofootLow32Disabled: boolean,
    PoDiagnosisOrthofootLow34: boolean,
    PoDiagnosisOrthofootLow34Disabled: boolean,
    PoDiagnosisOrthofootLow36: boolean,
    PoDiagnosisOrthofootLow36Disabled: boolean,
    PoDiagnosisOrthofootLow38: boolean,
    PoDiagnosisOrthofootLow38Disabled: boolean,
    PoDiagnosisOrthofootLow43: boolean,
    PoDiagnosisOrthofootLow43Disabled: boolean,
    PoDiagnosisOrthofootLow44: boolean,
    PoDiagnosisOrthofootLow44Disabled: boolean,
    PoDiagnosisOrthofootLow49: string,
    PoDiagnosisOrthofootLow49Disabled: boolean,
    PoDiagnosisOrthofootLow52: boolean,
    PoDiagnosisOrthofootLow52Disabled: boolean,
    Ct5Premorbid: string,
    Ct5PremorbidDisabled: boolean,
    Ct5PremorbidOptions: any,
    PoDiagnosisOrthofootLow57: string,
    PoDiagnosisOrthofootLow57Disabled: boolean,
    PoDiagnosisOrthofootLow59: boolean,
    PoDiagnosisOrthofootLow59Disabled: boolean,
    PoDiagnosisOrthofootLow60: boolean,
    PoDiagnosisOrthofootLow60Disabled: boolean,
    PoDiagnosisOrthofootLow65: string,
    PoDiagnosisOrthofootLow65Disabled: boolean,
    PoDiagnosisOrthofootLow67: string,
    PoDiagnosisOrthofootLow67Disabled: boolean,
    PoDiagnosisOrthofootLow70: string,
    PoDiagnosisOrthofootLow70Disabled: boolean,
    PoDiagnosisOrthofootLow74: string,
    PoDiagnosisOrthofootLow74Disabled: boolean,
    SaveRequestDisabled: boolean,
}

export const CardPODiagnosisOrthoticfootPremorbidIndependentLOWInitialViewData: CardPODiagnosisOrthoticfootPremorbidIndependentLOWViewDataType = {
    PoDiagnosisOrthofootLow2: "",
    PoDiagnosisOrthofootLow2Disabled: false,
    PoDiagnosisOrthofootLow4: "",
    PoDiagnosisOrthofootLow4Disabled: false,
    PoDiagnosisOrthofootLow8: "",
    PoDiagnosisOrthofootLow8Disabled: false,
    PoDiagnosisOrthofootLow11: false,
    PoDiagnosisOrthofootLow11Disabled: false,
    PoDiagnosisOrthofootLow12: false,
    PoDiagnosisOrthofootLow12Disabled: false,
    PoDiagnosisOrthofootLow13: false,
    PoDiagnosisOrthofootLow13Disabled: false,
    PoDiagnosisOrthofootLow15: false,
    PoDiagnosisOrthofootLow15Disabled: false,
    PoDiagnosisOrthofootLow18: false,
    PoDiagnosisOrthofootLow18Disabled: false,
    PoDiagnosisOrthofootLow22: false,
    PoDiagnosisOrthofootLow22Disabled: false,
    PoDiagnosisOrthofootLow24: false,
    PoDiagnosisOrthofootLow24Disabled: false,
    PoDiagnosisOrthofootLow29: false,
    PoDiagnosisOrthofootLow29Disabled: false,
    PoDiagnosisOrthofootLow30: false,
    PoDiagnosisOrthofootLow30Disabled: false,
    PoDiagnosisOrthofootLow31: "",
    PoDiagnosisOrthofootLow31Disabled: false,
    PoDiagnosisOrthofootLow32: false,
    PoDiagnosisOrthofootLow32Disabled: false,
    PoDiagnosisOrthofootLow34: false,
    PoDiagnosisOrthofootLow34Disabled: false,
    PoDiagnosisOrthofootLow36: false,
    PoDiagnosisOrthofootLow36Disabled: false,
    PoDiagnosisOrthofootLow38: false,
    PoDiagnosisOrthofootLow38Disabled: false,
    PoDiagnosisOrthofootLow43: false,
    PoDiagnosisOrthofootLow43Disabled: false,
    PoDiagnosisOrthofootLow44: false,
    PoDiagnosisOrthofootLow44Disabled: false,
    PoDiagnosisOrthofootLow49: "",
    PoDiagnosisOrthofootLow49Disabled: false,
    PoDiagnosisOrthofootLow52: false,
    PoDiagnosisOrthofootLow52Disabled: false,
    Ct5Premorbid: "",
    Ct5PremorbidDisabled: false,
    Ct5PremorbidOptions: [{key: 0, value: "A", text: "A"}],
    PoDiagnosisOrthofootLow57: "",
    PoDiagnosisOrthofootLow57Disabled: false,
    PoDiagnosisOrthofootLow59: false,
    PoDiagnosisOrthofootLow59Disabled: false,
    PoDiagnosisOrthofootLow60: false,
    PoDiagnosisOrthofootLow60Disabled: false,
    PoDiagnosisOrthofootLow65: "",
    PoDiagnosisOrthofootLow65Disabled: false,
    PoDiagnosisOrthofootLow67: "",
    PoDiagnosisOrthofootLow67Disabled: false,
    PoDiagnosisOrthofootLow70: "",
    PoDiagnosisOrthofootLow70Disabled: false,
    PoDiagnosisOrthofootLow74: "",
    PoDiagnosisOrthofootLow74Disabled: false,
    SaveRequestDisabled: false,
}

const Sub = (props: any) => {
    const children = React.Children.toArray(props.children)
    return (
        <div className={styles.container}>
            <div className={styles.PoDiagnosisOrthofootLow1}>
                <div
                    style={{background: "#F3F3F3", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.PoDiagnosisOrthofootLow2}>
                <Input
                    value={props.viewData.PoDiagnosisOrthofootLow2}
                    disabled={ props.viewData.PoDiagnosisOrthofootLow2Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPODiagnosisOrthoticfootPremorbidIndependentLOW',
                            name: 'PoDiagnosisOrthofootLow2',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoDiagnosisOrthofootLow4}>
                <Input
                    value={props.viewData.PoDiagnosisOrthofootLow4}
                    disabled={ props.viewData.PoDiagnosisOrthofootLow4Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPODiagnosisOrthoticfootPremorbidIndependentLOW',
                            name: 'PoDiagnosisOrthofootLow4',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoDiagnosisOrthofootLow5}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Chief complaint*</strong></p>
                </div>
            </div>
            <div className={styles.PoDiagnosisOrthofootLow6}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Underlying disease</strong></p>
                </div>
            </div>
            <div className={styles.PoDiagnosisOrthofootLow8}>
                <Input
                    value={props.viewData.PoDiagnosisOrthofootLow8}
                    disabled={ props.viewData.PoDiagnosisOrthofootLow8Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPODiagnosisOrthoticfootPremorbidIndependentLOW',
                            name: 'PoDiagnosisOrthofootLow8',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoDiagnosisOrthofootLow9}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Consult for*</strong></p>
                </div>
            </div>
            <div className={styles.PoDiagnosisOrthofootLow11}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoDiagnosisOrthofootLow11}
                    disabled={ props.viewData.PoDiagnosisOrthofootLow11Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPODiagnosisOrthoticfootPremorbidIndependentLOW",
                                name: "PoDiagnosisOrthofootLow11"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoDiagnosisOrthofootLow12}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoDiagnosisOrthofootLow12}
                    disabled={ props.viewData.PoDiagnosisOrthofootLow12Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPODiagnosisOrthoticfootPremorbidIndependentLOW",
                                name: "PoDiagnosisOrthofootLow12"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoDiagnosisOrthofootLow13}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoDiagnosisOrthofootLow13}
                    disabled={ props.viewData.PoDiagnosisOrthofootLow13Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPODiagnosisOrthoticfootPremorbidIndependentLOW",
                                name: "PoDiagnosisOrthofootLow13"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoDiagnosisOrthofootLow14}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Precaution*</strong></p>
                </div>
            </div>
            <div className={styles.PoDiagnosisOrthofootLow15}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoDiagnosisOrthofootLow15}
                    disabled={ props.viewData.PoDiagnosisOrthofootLow15Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPODiagnosisOrthoticfootPremorbidIndependentLOW",
                                name: "PoDiagnosisOrthofootLow15"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoDiagnosisOrthofootLow16}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>None</p>
                </div>
            </div>
            <div className={styles.PoDiagnosisOrthofootLow18}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoDiagnosisOrthofootLow18}
                    disabled={ props.viewData.PoDiagnosisOrthofootLow18Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPODiagnosisOrthoticfootPremorbidIndependentLOW",
                                name: "PoDiagnosisOrthofootLow18"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoDiagnosisOrthofootLow20}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Infectious precaution</p>
                </div>
            </div>
            <div className={styles.PoDiagnosisOrthofootLow21}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Malignancy</p>
                </div>
            </div>
            <div className={styles.PoDiagnosisOrthofootLow22}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoDiagnosisOrthofootLow22}
                    disabled={ props.viewData.PoDiagnosisOrthofootLow22Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPODiagnosisOrthoticfootPremorbidIndependentLOW",
                                name: "PoDiagnosisOrthofootLow22"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoDiagnosisOrthofootLow23}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Vascular disorder</p>
                </div>
            </div>
            <div className={styles.PoDiagnosisOrthofootLow24}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoDiagnosisOrthofootLow24}
                    disabled={ props.viewData.PoDiagnosisOrthofootLow24Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPODiagnosisOrthoticfootPremorbidIndependentLOW",
                                name: "PoDiagnosisOrthofootLow24"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoDiagnosisOrthofootLow25}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Internel Instrument</p>
                </div>
            </div>
            <div className={styles.PoDiagnosisOrthofootLow27}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Osteoporosis</p>
                </div>
            </div>
            <div className={styles.PoDiagnosisOrthofootLow28}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Pregnancy</p>
                </div>
            </div>
            <div className={styles.PoDiagnosisOrthofootLow29}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoDiagnosisOrthofootLow29}
                    disabled={ props.viewData.PoDiagnosisOrthofootLow29Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPODiagnosisOrthoticfootPremorbidIndependentLOW",
                                name: "PoDiagnosisOrthofootLow29"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoDiagnosisOrthofootLow30}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoDiagnosisOrthofootLow30}
                    disabled={ props.viewData.PoDiagnosisOrthofootLow30Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPODiagnosisOrthoticfootPremorbidIndependentLOW",
                                name: "PoDiagnosisOrthofootLow30"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoDiagnosisOrthofootLow31}>
                <Input
                    value={props.viewData.PoDiagnosisOrthofootLow31}
                    disabled={ props.viewData.PoDiagnosisOrthofootLow31Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPODiagnosisOrthoticfootPremorbidIndependentLOW',
                            name: 'PoDiagnosisOrthofootLow31',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoDiagnosisOrthofootLow32}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoDiagnosisOrthofootLow32}
                    disabled={ props.viewData.PoDiagnosisOrthofootLow32Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPODiagnosisOrthoticfootPremorbidIndependentLOW",
                                name: "PoDiagnosisOrthofootLow32"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoDiagnosisOrthofootLow33}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Hypertension</p>
                </div>
            </div>
            <div className={styles.PoDiagnosisOrthofootLow34}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoDiagnosisOrthofootLow34}
                    disabled={ props.viewData.PoDiagnosisOrthofootLow34Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPODiagnosisOrthoticfootPremorbidIndependentLOW",
                                name: "PoDiagnosisOrthofootLow34"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoDiagnosisOrthofootLow35}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Falling</p>
                </div>
            </div>
            <div className={styles.PoDiagnosisOrthofootLow36}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoDiagnosisOrthofootLow36}
                    disabled={ props.viewData.PoDiagnosisOrthofootLow36Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPODiagnosisOrthoticfootPremorbidIndependentLOW",
                                name: "PoDiagnosisOrthofootLow36"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoDiagnosisOrthofootLow37}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Diabetes mellitus</p>
                </div>
            </div>
            <div className={styles.PoDiagnosisOrthofootLow38}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoDiagnosisOrthofootLow38}
                    disabled={ props.viewData.PoDiagnosisOrthofootLow38Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPODiagnosisOrthoticfootPremorbidIndependentLOW",
                                name: "PoDiagnosisOrthofootLow38"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoDiagnosisOrthofootLow40}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Fracture</p>
                </div>
            </div>
            <div className={styles.PoDiagnosisOrthofootLow41}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Aspiration</p>
                </div>
            </div>
            <div className={styles.PoDiagnosisOrthofootLow42}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Seizure</p>
                </div>
            </div>
            <div className={styles.PoDiagnosisOrthofootLow43}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoDiagnosisOrthofootLow43}
                    disabled={ props.viewData.PoDiagnosisOrthofootLow43Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPODiagnosisOrthoticfootPremorbidIndependentLOW",
                                name: "PoDiagnosisOrthofootLow43"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoDiagnosisOrthofootLow44}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoDiagnosisOrthofootLow44}
                    disabled={ props.viewData.PoDiagnosisOrthofootLow44Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPODiagnosisOrthoticfootPremorbidIndependentLOW",
                                name: "PoDiagnosisOrthofootLow44"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoDiagnosisOrthofootLow45}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Standard precaution</p>
                </div>
            </div>
            <div className={styles.PoDiagnosisOrthofootLow47}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Fall precaution</p>
                </div>
            </div>
            <div className={styles.PoDiagnosisOrthofootLow49}>
                <Input
                    value={props.viewData.PoDiagnosisOrthofootLow49}
                    disabled={ props.viewData.PoDiagnosisOrthofootLow49Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPODiagnosisOrthoticfootPremorbidIndependentLOW',
                            name: 'PoDiagnosisOrthofootLow49',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoDiagnosisOrthofootLow50}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Functional Limitation:</strong></p>
                </div>
            </div>
            <div className={styles.PoDiagnosisOrthofootLow52}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoDiagnosisOrthofootLow52}
                    disabled={ props.viewData.PoDiagnosisOrthofootLow52Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "CardPODiagnosisOrthoticfootPremorbidIndependentLOW",
                                name: "PoDiagnosisOrthofootLow52"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoDiagnosisOrthofootLow53}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>None</p>
                </div>
            </div>
            <div className={styles.Ct5Premorbid}>
                <Dropdown selection
                    style={{width: "100%"}}
                    value={props.viewData.Ct5Premorbid}
                    disabled={ props.viewData.Ct5PremorbidDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "CardPODiagnosisOrthoticfootPremorbidIndependentLOW",
                                name: "Ct5Premorbid",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.Ct5PremorbidOptions}
                />
            </div>
            <div className={styles.PoDiagnosisOrthofootLow57}>
                <Input
                    value={props.viewData.PoDiagnosisOrthofootLow57}
                    disabled={ props.viewData.PoDiagnosisOrthofootLow57Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPODiagnosisOrthoticfootPremorbidIndependentLOW',
                            name: 'PoDiagnosisOrthofootLow57',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoDiagnosisOrthofootLow58}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Premorbid</strong></p>
                </div>
            </div>
            <div className={styles.PoDiagnosisOrthofootLow59}>
                <Radio 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoDiagnosisOrthofootLow59}
                    disabled={ props.viewData.PoDiagnosisOrthofootLow59Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "radio",
                            params: {
                                view: "CardPODiagnosisOrthoticfootPremorbidIndependentLOW",
                                name: "PoDiagnosisOrthofootLow59"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoDiagnosisOrthofootLow60}>
                <Radio 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PoDiagnosisOrthofootLow60}
                    disabled={ props.viewData.PoDiagnosisOrthofootLow60Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "radio",
                            params: {
                                view: "CardPODiagnosisOrthoticfootPremorbidIndependentLOW",
                                name: "PoDiagnosisOrthofootLow60"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PoDiagnosisOrthofootLow61}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Family Status</strong></p>
                </div>
            </div>
            <div className={styles.PoDiagnosisOrthofootLow63}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Caregiver</p>
                </div>
            </div>
            <div className={styles.PoDiagnosisOrthofootLow64}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Non-Caregiver</p>
                </div>
            </div>
            <div className={styles.PoDiagnosisOrthofootLow65}>
                <Input
                    value={props.viewData.PoDiagnosisOrthofootLow65}
                    disabled={ props.viewData.PoDiagnosisOrthofootLow65Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPODiagnosisOrthoticfootPremorbidIndependentLOW',
                            name: 'PoDiagnosisOrthofootLow65',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoDiagnosisOrthofootLow66}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Goal</strong></p>
                </div>
            </div>
            <div className={styles.PoDiagnosisOrthofootLow67}>
                <Input
                    value={props.viewData.PoDiagnosisOrthofootLow67}
                    disabled={ props.viewData.PoDiagnosisOrthofootLow67Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPODiagnosisOrthoticfootPremorbidIndependentLOW',
                            name: 'PoDiagnosisOrthofootLow67',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoDiagnosisOrthofootLow69}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Treatment Plan</strong></p>
                </div>
            </div>
            <div className={styles.PoDiagnosisOrthofootLow70}>
                <TextArea
                    value={props.viewData.PoDiagnosisOrthofootLow70}
                    disabled={ props.viewData.PoDiagnosisOrthofootLow70Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPODiagnosisOrthoticfootPremorbidIndependentLOW',
                            name: 'PoDiagnosisOrthofootLow70',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </TextArea>
            </div>
            <div className={styles.PoDiagnosisOrthofootLow72}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Present and Past History</strong></p>
                </div>
            </div>
            <div className={styles.PoDiagnosisOrthofootLow74}>
                <Input
                    value={props.viewData.PoDiagnosisOrthofootLow74}
                    disabled={ props.viewData.PoDiagnosisOrthofootLow74Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'CardPODiagnosisOrthoticfootPremorbidIndependentLOW',
                            name: 'PoDiagnosisOrthofootLow74',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PoDiagnosisOrthofootLow73}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Others</strong></p>
                </div>
            </div>
            <div className={styles.SaveRequest}>
                <Button
                    disabled={ props.viewData.SaveRequestDisabled }
                    onClick={(e) => { props.onEvent({
                        message: 'clickButton',
                        params: {
                            view: 'CardPODiagnosisOrthoticfootPremorbidIndependentLOW',
                            name: 'SaveRequest'
                        }
                    })}}
                    color="green"
                    style={{width: "100%"}}
                >
                    Save
                </Button>
            </div>
        </div>
    )
}

export default Sub

const getTsName = (cssClass: string) => {
    return cssClass.split('-').map((s) => (
        s.charAt(0).toUpperCase() + s.slice(1)
    )).join('')
}
