import React, {
  FC,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  Checkbox,
  Form,
  Grid,
  GridColumn,
  GridRow,
  Input,
  TextArea,
} from "semantic-ui-react";

const styles = {
  rightAlign: {
    marginRight: "0px",
    marginLeft: "auto",
  },
  rightMargin: {
    marginRight: "-25px",
  },
};

export interface CardMotorPowerViewDataType {
  data: {
    isRightLeft: boolean;
    rigthTop: string;
    rigthBottom: string;
    leftTop: string;
    leftBottom: string;
    isNA: boolean;
    motorDetail: string;
  };
  readonly: boolean;
}

const CardMotorPower: FC<CardMotorPowerViewDataType> = forwardRef(
  (props, ref) => {
    const [motorPower, setMotorPower] = useState<any>(defaultData);

    useEffect(() => {
      setMotorPower(props.data);
    }, [props.data]);

    useImperativeHandle(ref, () => ({
      getData: () => {
        return motorPower;
      },
    }));

    return (
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column width={8}>
            <Form>
              <Form.Group inline>
                <Form.Field width={2}>
                  <Checkbox
                    checked={motorPower.isRightLeft}
                    readOnly={props.readonly}
                    disabled={motorPower.isNA}
                    onChange={(e, { checked }) => {
                      setMotorPower({ ...motorPower, isRightLeft: checked });
                    }}
                  />
                </Form.Field>
                <Form.Field width={7} style={{ justifyContent: "center" }}>
                  Right
                </Form.Field>
                <Form.Field width={7} style={{ justifyContent: "center" }}>
                  Left
                </Form.Field>
              </Form.Group>

              <Form.Group inline>
                <Form.Field width={2} />
                <Form.Field width={7}>
                  <Input
                    readOnly={props.readonly}
                    disabled={motorPower.isNA || !motorPower.isRightLeft}
                    value={motorPower.rigthTop}
                    onChange={(e, { value }) => {
                      setMotorPower({ ...motorPower, rigthTop: value });
                    }}
                  />
                </Form.Field>
                <Form.Field width={7}>
                  <Input
                    readOnly={props.readonly}
                    disabled={motorPower.isNA || !motorPower.isRightLeft}
                    value={motorPower.leftTop}
                    onChange={(e, { value }) => {
                      setMotorPower({ ...motorPower, leftTop: value });
                    }}
                  />
                </Form.Field>
              </Form.Group>

              <Form.Group>
                <Form.Field width={2} />
                <Form.Field width={14}>
                  <div
                    style={{
                      border: "solid lightgrey 1px",
                      height: "100%",
                      width: "95%",
                    }}
                  />
                </Form.Field>
              </Form.Group>

              <Form.Group inline>
                <Form.Field width={2} />
                <Form.Field width={7}>
                  <Input
                    readOnly={props.readonly}
                    disabled={motorPower.isNA || !motorPower.isRightLeft}
                    value={motorPower.rigthBottom}
                    onChange={(e, { value }) => {
                      setMotorPower({ ...motorPower, rigthBottom: value });
                    }}
                  />
                </Form.Field>
                <Form.Field width={7}>
                  <Input
                    readOnly={props.readonly}
                    disabled={motorPower.isNA || !motorPower.isRightLeft}
                    value={motorPower.leftBottom}
                    onChange={(e, { value }) => {
                      setMotorPower({ ...motorPower, leftBottom: value });
                    }}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>

          <Grid.Column width={8}>
            <Form>
              <Form.Group inline>
                <Form.Field width={1}>
                  <Checkbox 
                    checked={motorPower.isNA}
                    readOnly={props.readonly}
                    onChange={(e, { checked }) => {
                        setMotorPower({ ...defaultData, isNA: checked});
                    }}
                  />
                </Form.Field>
                <Form.Field width={1}>NA</Form.Field>
              </Form.Group>

              <Form.Group>
                <Form.Field width={2} />
                <Form.Field width={14}>
                  <TextArea 
                        disabled={motorPower.isNA}
                        readOnly={props.readonly}
                        value={motorPower.motorDetail}
                        rows="3" 
                        style={{ resize: "auto" }}
                        onChange={(e, { value }) => {
                            setMotorPower({ ...motorPower, motorDetail: value });
                          }}
                     />
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
);

const defaultData = {
  isRightLeft: false,
  rigthTop: "",
  rigthBottom: "",
  leftTop: "",
  leftBottom: "",
  isNA: false,
  motorDetail: "",
};

export const CardMotorPowerInitialViewData: CardMotorPowerViewDataType = {
  data: defaultData,
  readonly: false,
};

CardMotorPower.defaultProps = CardMotorPowerInitialViewData;

export default CardMotorPower;
