import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Header,
  Divider,
  Form,
  Label,
  Dimmer,
  Loader,
  Grid,
  Icon,
  Input,
} from "semantic-ui-react";
import { createForm, prepareForm } from "../Common/Diag";
import Tree from "./Tree";
import Detail from "./Detail";
import Emulator from "./Emulator";
import styles from "../css/styles.ts";
import AnnotateContent from "./AnnotateContent";
import AddNode from "./AddNode";
import RemoveNode from "./RemoveNode";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import * as Util from "../../../utils";
import { useIntl } from "react-intl";

const DiagComposer = (props) => {
  const intl = useIntl();
  const history = useHistory();
  const [selectedRowItem, setSelectedRowItem] = useState(null);
  const [rowParent, setRowParent] = useState(null);
  const [answerItemsOfSelectedRowItem, setAnswerItemsOfSelectedRowItem] =
    useState([]);
  const [diagRuleDetail, setDiagRuleDetail] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [triageLevel, setTriageLevel] = useState([]);
  const [openModalEditTitle, setOpenModalEditTitle] = useState(false);
  const [rename, setRename] = useState("");

  useEffect(() => {
    getDiagRuleDetail();
    // getTriageLevelClassify();
  }, []);

  useEffect(() => {
    if (selectedRowItem !== null) {
      let answers = getAnswerOfSelecteRow(selectedRowItem);
      setAnswerItemsOfSelectedRowItem(answers);
    }

    let lostSelect = true;
    let newItem = {};
    if (selectedRowItem !== null) {
      for (const [key, value] of Object.entries(diagRuleDetail.content)) {
        if (value.id == selectedRowItem.id) {
          lostSelect = false;
          newItem = value;
          break;
        }
      }
    }

    if (lostSelect) {
      setSelectedRowItem(null);
    } else {
      setSelectedRowItem(newItem);
    }

    setRename(diagRuleDetail.name);
  }, [diagRuleDetail]);

  const formatTriageLevelClassifyDropdown = (
    optionsList,
    textField,
    keyField,
    activeField
  ) => {
    if (optionsList && Array.isArray(optionsList)) {
      optionsList.forEach((items) => {
        //
        items.key = items[keyField];
        items.text = items[textField];
        items.value = items[keyField];
        if (activeField) {
          items.active = items[activeField];
        }
        return items;
      });
      return optionsList;
    } else {
      return [];
    }
  };

  const getTriageLevelClassify = async () => {
    let [response, error, network] =
      await props.controller.getTriageLevelClassify({
        apiToken: Cookies.get("apiToken") ? Cookies.get("apiToken") : props.apiToken,
        divisionId: props.division ? props.division : null,
      });
    //
    if (response) {
      let options = formatTriageLevelClassifyDropdown(
        response.classify,
        "description",
        "triage_level",
        "active"
      );
      //
      setTriageLevel(options);
    } else {
      setTriageLevel([]);
    }
  };

  const getDiagRuleDetail = async () => {
    if (!props.match || !props.match.params || !props.match.params.id) {
      return;
    }
    let id = props.match.params.id;
    let [response, error, network] = await props.controller.getDiagRuleDetail({
      apiToken: Cookies.get("apiToken") ? Cookies.get("apiToken") : props.apiToken,
      id,
    });
    if (response) {
      let newObj = {
        ...response,
        "content": JSON.parse(response.content),
      };

      setDiagRuleDetail(newObj);

      // console.log("response", response)
      let options = formatTriageLevelClassifyDropdown(
        JSON.parse(response.classify),
        "description",
        "triage_level",
        "active"
      );
      // console.log("formatTriageLevelClassifyDropdown options", options)
      //
      setTriageLevel(options);
    
    } else {
      setTriageLevel([]);
    }
  };

  const getAnswerOfSelecteRow = (item) => {
    let answers = [];
    if (item.id !== undefined && item.id !== null) {
      for (const [key, value] of Object.entries(diagRuleDetail.content)) {
        if (value.parent_id == item.id) {
          answers.push(value);
        }
      }
    }
    return answers;
  };

  const handleSetSelectedRow = (item) => {
    let row_parent = null;
    //
    if (
      item.parent_id != undefined &&
      item.parent_id !== null &&
      typeof item.parent_id == "number" &&
      diagRuleDetail.content[item.parent_id]
    ) {
      row_parent = diagRuleDetail.content[item.parent_id];
    }

    setRowParent(row_parent);
    setSelectedRowItem(item);
    let answers = getAnswerOfSelecteRow(item);
    setAnswerItemsOfSelectedRowItem(answers);
  };

  const doNode = (id, action) => {
    //
    let rule_id = props.match.params.id;
    if ("content" in diagRuleDetail) {
      var content = diagRuleDetail["content"];
      if (action === "insert") {
        content[id].type = "choices";
        delete content[id]["triage_level"];
        //
        content = AddNode(content, {
          parent_id: id,
          label: "",
          condition: "",
          text: "",
          type: "end",
        });
      } else if (action === "delete") {
        let parentId = content[id].parent_id;
        let count = 0;
        content = RemoveNode(content, id);
        if (parentId) {
          for (let item in content) {
            if (content[item].parent_id === parentId) {
              count += 1;
            }
          }
          if (count === 0) {
            content[parentId].type = "end";
          }
        }
      }
      let newObj = {
        ...diagRuleDetail,
        "content": content,
      };

      setDiagRuleDetail(newObj);
    }
  };

  const countNode = (content) => {
    if (content) {
      return Object.keys(content).length;
    } else {
      return 0;
    }
  };

  const handleRename = async (newName) => {
    let name = newName;
    setIsLoading(true);
    const [response, error, network] = await props.controller.patchDiagRuleName(
      {
        name,
        apiToken: Cookies.get("apiToken") ? Cookies.get("apiToken") : props.apiToken,
        id: diagRuleDetail.id,
      }
    );
    setIsLoading(false);
    if (response) {
      getDiagRuleDetail();
    }
  };

  const handleAddExtraItem = (row_id) => {
    let newObj = { ...diagRuleDetail };
    if (!Object.keys(newObj.content[row_id]).includes("extra_items")) {
      newObj.content[row_id]["extra_items"] = [];
      newObj.content[row_id]["extra_intro"] = intl.formatMessage({ id: "กรุณากรอกข้อมูล" });
      newObj.content[row_id]["extra_submit"] = intl.formatMessage({ id: "เสร็จสิ้น" });
    }
    let len = newObj.content[row_id]["extra_items"].length;
    newObj.content[row_id]["extra_items"].push({
      tag: "0",
      label: `ข้อมูลที่ ${len + 1}`,
      type: "Select",
      nullOk: true,
      default: "",
    });
    setDiagRuleDetail(newObj);
    console.log(newObj);
  };

  const handleChangeExtraItem = (row_id, ex_index, data) => {
    let newObj = { ...diagRuleDetail };
    Object.assign(newObj.content[row_id]["extra_items"][ex_index], data);
    setDiagRuleDetail(newObj);
  };

  const handleRemoveExtraItem = (row_id, ex_index) => {
    let newObj = { ...diagRuleDetail };
    newObj.content[row_id]["extra_items"].splice(ex_index, 1);
    setDiagRuleDetail(newObj);
  };

  const handleSaveOrRegister = async () => {
    var content = JSON.stringify(diagRuleDetail.content);
    setIsLoading(true);
    const [response, error, network] =
      await props.controller.patchDiagRuleDetail({
        content,
        apiToken: Cookies.get("apiToken") ? Cookies.get("apiToken") : props.apiToken,
        id: diagRuleDetail.id,
      });
    setIsLoading(false);
    if (response) {
      getDiagRuleDetail();
    }
  };

  const handleSetRowContent = (row_id, field, value) => {
    if (row_id in diagRuleDetail.content) {
      let newObj = { ...diagRuleDetail };
      newObj.content[row_id][field] = value;
      setDiagRuleDetail(newObj);
    }
  };

  const handleUnpublish = async () => {
    let id = diagRuleDetail.id;
    setIsLoading(true);
    let [response, error, network] =
      await props.controller.patchDiagRulePublish({
        id,
        published: false,
        apiToken: Cookies.get("apiToken") ? Cookies.get("apiToken") : props.apiToken,
      });
    setIsLoading(false);
    if (response) {
      getDiagRuleDetail();
    }
  };

  const handlePublish = async () => {
    let content = JSON.stringify(diagRuleDetail.content);
    setIsLoading(true);
    const [response, error, network] =
      await props.controller.postPublishedDiagRule({
        content,
        apiToken: Cookies.get("apiToken") ? Cookies.get("apiToken") : props.apiToken,
        diagRule: diagRuleDetail.id,
      });
    setIsLoading(false);
    if (response) {
      getDiagRuleDetail();
    }
  };

  // console.log(" DiagComposer props.division: ", props.division)
  if (props.rule === null) {
    // TODO: Fixed this this case not go thru because it's undefined !
    return <div></div>;
  } else {
    var content = Object.values(
      diagRuleDetail.content ? AnnotateContent(diagRuleDetail.content) : []
    );
    // var form = diagRuleDetail ? createForm(diagRuleDetail) : {};

    if (diagRuleDetail.length == 0) {
      return <> </>;
    }

    return (
      <>
        <Modal
          open={openModalEditTitle}
          closeIcon
          onClose={() => {
            setOpenModalEditTitle(false);
          }}
        >
          <Header icon="edit" content="Change Form Title" />

          <Modal.Content>
            <Input
              fluid
              value={rename}
              onChange={(e, data) => {
                setRename(data.value);
              }}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="red"
              onClick={() => {
                setOpenModalEditTitle(false);
              }}
            >
              <Icon name="remove" /> No
            </Button>
            <Button
              color="green"
              onClick={() => {
                handleRename(rename);
                setOpenModalEditTitle(false);
              }}
            >
              <Icon name="checkmark" /> Yes
            </Button>
          </Modal.Actions>
        </Modal>

        <Dimmer.Dimmable>
          <div style={{ height: "93vh", padding: 20 }}>
            <Form style={{ margin: 0 }}>
              <Form.Group inline style={{ justifyContent: "space-between" }}>
                <Form.Field>
                  <Form.Group inline>
                    <Form.Field style={{ border: "none" }}>
                      <Header
                        style={{ ...styles.header }}
                        onClick={() => {
                          setOpenModalEditTitle(true);
                        }}
                      >
                        {diagRuleDetail.name}
                      </Header>
                    </Form.Field>
                    {Cookies.get("apiToken") == "anonymous" ? null : (
                      <Form.Field>
                        <Label
                          content={
                            diagRuleDetail.published
                              ? "Published"
                              : "Unpublished"
                          }
                          style={styles.basicButton}
                          color={diagRuleDetail.published ? "teal" : "orange"}
                          size="large"
                          basic
                        />
                      </Form.Field>
                    )}
                  </Form.Group>
                </Form.Field>
                <Form.Field>
                  <Form.Group>
                    <Form.Field>
                      <Modal
                        style={{ width: "auto", height: "auto" }}
                        trigger={
                          <Button color="teal" fluid>
                            Test Run
                          </Button>
                        }
                        // onClose={()=>{ }}
                      >
                        <Emulator {...props} diagRuleDetail={diagRuleDetail} />
                      </Modal>
                    </Form.Field>
                    {/* <Form.Field>
                    <Button fluid color="blue" onClick={handleSave}>
                      Save
                    </Button>
                  </Form.Field> */}
                    {Cookies.get("apiToken") ==
                    "anonymous" ? null : diagRuleDetail.published &&
                      !diagRuleDetail.dirty ? (
                      <Form.Field>
                        <Button
                          fluid
                          style={styles.lightOrange}
                          onClick={handleUnpublish}
                        >
                          Unpublish
                        </Button>
                      </Form.Field>
                    ) : (
                      <Form.Field>
                        <Button
                          fluid
                          style={styles.darkgreen}
                          onClick={handlePublish}
                          disabled={!props.isVerified}
                        >
                          Publish
                        </Button>
                      </Form.Field>
                    )}
                  </Form.Group>
                </Form.Field>
              </Form.Group>
            </Form>
            <Divider style={styles.divider} />
            <Grid columns={2}>
              <Grid.Column style={{ overflow: "auto", maxHeight: "750px" }}>
                <Tree
                  className="composer"
                  content={content}
                  doNode={doNode}
                  setSelectedRow={handleSetSelectedRow}
                  countNode={countNode(diagRuleDetail.content)}
                  selectedRowId={selectedRowItem ? selectedRowItem.id : null}
                />
              </Grid.Column>
              <Grid.Column style={{ overflow: "auto" , maxHeight: "750px"}}>
                <Detail
                  className="detail"
                  storage={props.storage}
                  controller={props.controller}
                  row_item={selectedRowItem}
                  row_parent={rowParent}
                  setRowContent={handleSetRowContent}
                  triageLevel={triageLevel}
                  choise={answerItemsOfSelectedRowItem}
                  onAddExtraItem={handleAddExtraItem}
                  onChangeExtraItem={handleChangeExtraItem}
                  onRemoveExtraItem={handleRemoveExtraItem}
                  onSave={handleSaveOrRegister}
                />
              </Grid.Column>
            </Grid>
          </div>
          <Dimmer active={isLoading} inverted>
            <Loader inverted>{"Loading"}</Loader>
          </Dimmer>
        </Dimmer.Dimmable>
      </>
    );
  }
};

DiagComposer.defaultProps = {
  controller: {},
};

DiagComposer.propTypes = {
  controller: PropTypes.object,
};

export default DiagComposer;
