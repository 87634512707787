import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Icon,
  Button
} from 'semantic-ui-react'

const ModConsultAppointmentListUX = (props: any) => {
    return(
      <div
        style={{padding: "20px"}}>
        <div
          style={{display: "flex",  justifyContent: "space-between"}}>
          
          <div
            style={{color: "#0D71BB", fontSize: "18px", fontWeight: "bold"}}>
            รายชื่อผู้ป่วยอื่นๆในคาบเดียวกัน
          </div>
          <div
            style={{marginTop: "-15px"}}>
            
            <Icon
              className="large red"
              name="close"
              onClick={props.onClose}>
            </Icon>
          </div>
        </div>
        <hr>
        </hr>
        <div>
          
          <Table
            data={props.data}
            getTrProps={props.onRowClick}
            headers="รายชื่อผู้ป่วย,เวลาเริ่ม,ระยะเวลา,ผู้ทำนัดหมาย,วันเวลาที่ทำนัดหมาย"
            keys="patient_full_name,start_time,duration,appointment_user,booking_date_time"
            minRows={10}
            pageSize={10}
            showPagination={false}
            widths="300,150,150">
          </Table>
        </div>
        <div
          style={{display: props.showActionButton ? "flex" : "none", justifyContent: "flex-end", alignItems: "center", padding: "10px"}}>
          
          <Button
            color="green"
            onClick={props.onConfirmAppointment}
            style={{...(props.hideConfirmButton? {display: "none"}: {})}}>
            ยืนยันนัดหมาย
          </Button>
          <Button
            color="yellow"
            onClick={props.onEditAppointmentDateTime}>
            แก้ไขวันเวลานัดหมาย
          </Button>
          <Button
            color="red"
            onClick={props.onCancelAppointment}>
            ยกเลิกนัดหมาย
          </Button>
        </div>
      </div>
    )
}


export default ModConsultAppointmentListUX

export const screenPropsDefault = {}

/* Date Time : Wed Oct 11 2023 12:21:24 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding: \"20px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 2,
      "name": "hr",
      "parent": 0,
      "props": {
      },
      "seq": 3,
      "void": true
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 4,
      "name": "Table",
      "parent": 3,
      "props": {
        "data": {
          "type": "code",
          "value": "props.data"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.onRowClick"
        },
        "headers": {
          "type": "value",
          "value": "รายชื่อผู้ป่วย,เวลาเริ่ม,ระยะเวลา,ผู้ทำนัดหมาย,วันเวลาที่ทำนัดหมาย"
        },
        "keys": {
          "type": "value",
          "value": "patient_full_name,start_time,duration,appointment_user,booking_date_time"
        },
        "minRows": {
          "type": "code",
          "value": "10"
        },
        "pageSize": {
          "type": "code",
          "value": "10"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "widths": {
          "type": "value",
          "value": "300,150,150"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 5,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  justifyContent: \"space-between\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": "รายชื่อผู้ป่วยอื่นๆในคาบเดียวกัน"
        },
        "style": {
          "type": "code",
          "value": "{color: \"#0D71BB\", fontSize: \"18px\", fontWeight: \"bold\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 5,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"-15px\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "Icon",
      "parent": 7,
      "props": {
        "className": {
          "type": "value",
          "value": "large red"
        },
        "name": {
          "type": "value",
          "value": "close"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClose"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.showActionButton ? \"flex\" : \"none\", justifyContent: \"flex-end\", alignItems: \"center\", padding: \"10px\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "Button",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "ยืนยันนัดหมาย"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.onConfirmAppointment"
        },
        "style": {
          "type": "code",
          "value": "{...(props.hideConfirmButton? {display: \"none\"}: {})}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 11,
      "name": "Button",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "แก้ไขวันเวลานัดหมาย"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "onClick": {
          "type": "code",
          "value": "props.onEditAppointmentDateTime"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "Button",
      "parent": 9,
      "props": {
        "children": {
          "type": "value",
          "value": "ยกเลิกนัดหมาย"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.onCancelAppointment"
        }
      },
      "seq": 12,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "ModConsultAppointmentListUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
