import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'

const CardSpecimenCollectorListUX = (props: any) => {
    return(
      <div>
        <Table
          className="--rt-tbody-overflow-y-hidden"
          columns={props.columns_specimenItems}
          data={props.labOrderQueue}
          getTrProps={props.rowProps}
          headers="วันเวลาที่สั่ง, Lab ID, EN Type, แผนกที่สั่ง, แพทย์ผู้สั่ง, ประเภทของแลป, ความเร่งด่วน, วันเวลาที่รับเข้า, สถานนะ"
          keys="date,labId,encounter_type,division_name,doctor_name,lab_type_label,highest_urgency,arrive_time,status_name"
          minRows="10"
          showPagination={false}
          style={{ height: "44vh" }}>
        </Table>
        <div
          style={{display: "flex" }}>
          
          <div
            style={{flex: 1}}>
            
          </div>
          <div>
            {props.buttonReceive}
          </div>
          <div>
            {props.buttonReject}
          </div>
        </div>
      </div>
    )
}


export default CardSpecimenCollectorListUX

export const screenPropsDefault = {}

/* Date Time : Wed Dec 27 2023 09:12:40 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 1,
      "name": "Table",
      "parent": 0,
      "props": {
        "className": {
          "type": "value",
          "value": "--rt-tbody-overflow-y-hidden"
        },
        "columns": {
          "type": "code",
          "value": "props.columns_specimenItems"
        },
        "data": {
          "type": "code",
          "value": "props.labOrderQueue"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.rowProps"
        },
        "headers": {
          "type": "value",
          "value": "วันเวลาที่สั่ง, Lab ID, EN Type, แผนกที่สั่ง, แพทย์ผู้สั่ง, ประเภทของแลป, ความเร่งด่วน, วันเวลาที่รับเข้า, สถานนะ"
        },
        "keys": {
          "type": "value",
          "value": "date,labId,encounter_type,division_name,doctor_name,lab_type_label,highest_urgency,arrive_time,status_name"
        },
        "minRows": {
          "type": "value",
          "value": "10"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{ height: \"44vh\" }"
        },
        "widths": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\" }"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonReceive"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonReject"
        }
      },
      "seq": 24,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 55,
  "isMounted": false,
  "memo": false,
  "name": "CardSpecimenCollectorListUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 55
}

*********************************************************************************** */
