import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Checkbox,
  Label,
  Dropdown,
  Button,
  Input
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardReserveBedQueueUX = (props: any) => {
    return(
      <div>
        <div
          className="accordion ui fluid blue raised segment"
          style={{boxShadow: "none"}}>
          
          <div
            style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
            
            <label
              style={{fontSize: "1.25rem", fontWeight: "bold"}}>
              คิวใบจองเตียงผู้ป่วย
            </label>
            <div
              style={{display: "flex", alignItems: "center"}}>
              
              <label
                style={{fontWeight: "bold", fontSize: "1rem", marginRight: "0.8rem"}}>
                Barcode / เลขที่จองเตียง
              </label>
              <Input
                onChange={props.onChangeCodeIcontains}
                onKeyDown={props.onKeyDownCodeIcontains}
                value={props.codeIcontains}>
              </Input>
            </div>
          </div>
          <div
            className="ui divider">
            
          </div>
          <div
            className="ui form small">
            
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={2}>
                <Checkbox
                  checked={props.isHN}
                  label="HN"
                  name="isHN"
                  onChange={props.onChangeValue}>
                </Checkbox>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <div
                  style={{width: "100%"}}>
                  {props.PatientSearchBox}
                </div>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <Label
                  className="large fluid">
                  {props.patientFullName || "-"}
                </Label>
              </FormField>
              <FormField
                inline={true}
                width={1}>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <Checkbox
                  checked={props.isAdmitDate}
                  label="ช่วงวันที่นัดหมาย Admit"
                  name="isAdmitDate"
                  onChange={props.onChangeValue}>
                </Checkbox>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <div
                  style={{width: "100%"}}>
                  {props.AdmitStartDate}
                </div>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <div
                  style={{width: "100%"}}>
                  {props.AdmitEndDate}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={2}>
                <Checkbox
                  checked={props.isOrderDiv}
                  label="หน่วยตรวจที่จองห้องพัก"
                  name="isOrderDiv"
                  onChange={props.onChangeValue}>
                </Checkbox>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <div
                  style={{width: "100%"}}>
                  {props.CardDivisionSearch}
                </div>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <Label
                  className="large fluid">
                  {props.orderDivName || "-"}
                </Label>
              </FormField>
              <FormField
                inline={true}
                width={1}>
              </FormField>
              <FormField
                inline={true}
                width={3}>
                <Checkbox
                  checked={props.isSaveDate}
                  label="ช่วงวันที่บันทึกรายการจองห้องพัก"
                  name="isSaveDate"
                  onChange={props.onChangeValue}>
                </Checkbox>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <div
                  style={{width: "100%"}}>
                  {props.SaveStartDate}
                </div>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <div
                  style={{width: "100%"}}>
                  {props.SaveEndDate}
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={2}>
                <Checkbox
                  checked={props.isReserveStatus}
                  label="สถานะใบจอง"
                  name="isReserveStatus"
                  onChange={props.onChangeValue}
                  style={{minWidth: "max-content"}}>
                </Checkbox>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <Dropdown
                  clearable={true}
                  fluid={true}
                  name="reserveStatus"
                  onChange={props.onChangeValue}
                  options={props.admitOrderOptions}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.reserveStatus}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <Checkbox
                  checked={props.isWardType}
                  label="ประเภทหอผู้ป่วย"
                  name="isWardType"
                  onChange={props.onChangeValue}
                  style={{minWidth: "max-content"}}>
                </Checkbox>
              </FormField>
              <FormField
                inline={true}
                width={2}>
                <Dropdown
                  clearable={true}
                  fluid={true}
                  name="wardType"
                  onChange={props.onChangeValue}
                  options={props.wardTypeOptions}
                  selection={true}
                  style={{width: "100%"}}
                  value={props.wardType}>
                </Dropdown>
              </FormField>
              <FormField
                inline={true}
                style={{flex:1}}>
              </FormField>
              <FormField
                inline={true}>
                <Button
                  className="fluid"
                  color={"green"}
                  icon={true}
                  onClick={props.onSearch}
                  size={"small"}>
                  ค้นหา
                </Button>
              </FormField>
              <FormField
                inline={true}>
                <Button
                  className="fluid"
                  color={"yellow"}
                  onClick={props.onClear}
                  size={"small"}
                  style={{minWidth: "max-content"}}>
                  ล้างการค้นหา
                </Button>
              </FormField>
              <FormField
                inline={true}>
                <Button
                  className=" fluid"
                  color={"blue"}
                  icon={true}
                  onClick={props.onPrintReport}
                  size={"small"}
                  style={{minWidth: "max-content"}}>
                  พิมพ์รายงาน
                </Button>
              </FormField>
              <FormField
                inline={true}>
                <Button
                  className="fluid"
                  color={"blue"}
                  onClick={props.onPrintPostpone}
                  size={"small"}
                  style={{minWidth: "max-content"}}>
                  พิมพ์รายงานเลื่อนนัด
                </Button>
              </FormField>
              <FormField
                inline={true}>
                <Button
                  color="brown"
                  onClick={props.onPrintDailyReport}
                  style={{...(!props.showPrintReserveBedReportBtn && {display:"none"})}}>
                  พิมพ์รายงานประจำวัน
                </Button>
              </FormField>
            </FormGroup>
            <div>
              
              <Table
                data={props.reserveBedQueueList}
                getTrProps={props.getTrProps}
                headers="เลขที่, HN,ข้อมูลผู้ป่วย,วันนัด Admit, Diagnosis,ประเภท,ชื่อผู้จอง,เบอร์ติดต่อ,สถานะใบจอง"
                keys="code,hn,patient_name,admit_date,diagnosis,room_info,reservation_name,contact_number,status_label"
                showPagination={false}
                style={{height: "250px"}}>
              </Table>
            </div>
          </div>
        </div>
      </div>
    )
}


export default CardReserveBedQueueUX

export const screenPropsDefault = {}

/* Date Time : Wed Nov 15 2023 09:53:41 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "accordion ui fluid blue raised segment"
        },
        "style": {
          "type": "code",
          "value": "{boxShadow: \"none\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui divider"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form small"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "FormGroup",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 10,
      "name": "Checkbox",
      "parent": 6,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isHN"
        },
        "label": {
          "type": "value",
          "value": "HN"
        },
        "name": {
          "type": "value",
          "value": "isHN"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "code",
          "value": "props.PatientSearchBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "Label",
      "parent": 8,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientFullName || \"-\""
        },
        "className": {
          "type": "value",
          "value": "large fluid"
        },
        "name": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "Checkbox",
      "parent": 14,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isAdmitDate"
        },
        "label": {
          "type": "value",
          "value": "ช่วงวันที่นัดหมาย Admit"
        },
        "name": {
          "type": "value",
          "value": "isAdmitDate"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "code",
          "value": "props.AdmitStartDate"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "FormField",
      "parent": 5,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 18,
      "props": {
        "children": {
          "type": "code",
          "value": "props.AdmitEndDate"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "FormGroup",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "FormField",
      "parent": 20,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 22,
      "name": "FormField",
      "parent": 20,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "FormField",
      "parent": 20,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 24,
      "name": "FormField",
      "parent": 20,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 25,
      "name": "FormField",
      "parent": 20,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "FormField",
      "parent": 20,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "FormField",
      "parent": 20,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "Checkbox",
      "parent": 21,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isOrderDiv"
        },
        "label": {
          "type": "value",
          "value": "หน่วยตรวจที่จองห้องพัก"
        },
        "name": {
          "type": "value",
          "value": "isOrderDiv"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 22,
      "props": {
        "children": {
          "type": "code",
          "value": "props.CardDivisionSearch"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "Label",
      "parent": 23,
      "props": {
        "children": {
          "type": "code",
          "value": "props.orderDivName || \"-\""
        },
        "className": {
          "type": "value",
          "value": "large fluid"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "Checkbox",
      "parent": 25,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isSaveDate"
        },
        "label": {
          "type": "value",
          "value": "ช่วงวันที่บันทึกรายการจองห้องพัก"
        },
        "name": {
          "type": "value",
          "value": "isSaveDate"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "div",
      "parent": 26,
      "props": {
        "children": {
          "type": "code",
          "value": "props.SaveStartDate"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 27,
      "props": {
        "children": {
          "type": "code",
          "value": "props.SaveEndDate"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "FormGroup",
      "parent": 4,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "FormField",
      "parent": 34,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "FormField",
      "parent": 34,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "FormField",
      "parent": 34,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "FormField",
      "parent": 34,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "FormField",
      "parent": 34,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{flex:1}"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "FormField",
      "parent": 34,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "FormField",
      "parent": 34,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "FormField",
      "parent": 34,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "Checkbox",
      "parent": 35,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isReserveStatus"
        },
        "label": {
          "type": "value",
          "value": "สถานะใบจอง"
        },
        "name": {
          "type": "value",
          "value": "isReserveStatus"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "Dropdown",
      "parent": 36,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "reserveStatus"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.admitOrderOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.reserveStatus"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "Checkbox",
      "parent": 37,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isWardType"
        },
        "label": {
          "type": "value",
          "value": "ประเภทหอผู้ป่วย"
        },
        "name": {
          "type": "value",
          "value": "isWardType"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "Dropdown",
      "parent": 38,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "wardType"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "options": {
          "type": "code",
          "value": "props.wardTypeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.wardType"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "Button",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "className": {
          "type": "value",
          "value": "fluid"
        },
        "color": {
          "type": "code",
          "value": "\"green\""
        },
        "icon": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSearch"
        },
        "size": {
          "type": "code",
          "value": "\"small\""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "Button",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์รายงาน"
        },
        "className": {
          "type": "value",
          "value": " fluid"
        },
        "color": {
          "type": "code",
          "value": "\"blue\""
        },
        "icon": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "props.onPrintReport"
        },
        "size": {
          "type": "code",
          "value": "\"small\""
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "Button",
      "parent": 42,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์รายงานเลื่อนนัด"
        },
        "className": {
          "type": "value",
          "value": "fluid"
        },
        "color": {
          "type": "code",
          "value": "\"blue\""
        },
        "onClick": {
          "type": "code",
          "value": "props.onPrintPostpone"
        },
        "size": {
          "type": "code",
          "value": "\"small\""
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 51,
      "name": "Table",
      "parent": 50,
      "props": {
        "data": {
          "type": "code",
          "value": "props.reserveBedQueueList"
        },
        "getTrProps": {
          "type": "code",
          "value": "props.getTrProps"
        },
        "headers": {
          "type": "value",
          "value": "เลขที่, HN,ข้อมูลผู้ป่วย,วันนัด Admit, Diagnosis,ประเภท,ชื่อผู้จอง,เบอร์ติดต่อ,สถานะใบจอง"
        },
        "keys": {
          "type": "value",
          "value": "code,hn,patient_name,admit_date,diagnosis,room_info,reservation_name,contact_number,status_label"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"250px\"}"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "label",
      "parent": 52,
      "props": {
        "children": {
          "type": "value",
          "value": "คิวใบจองเตียงผู้ป่วย"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.25rem\", fontWeight: \"bold\"}"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "div",
      "parent": 52,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "label",
      "parent": 54,
      "props": {
        "children": {
          "type": "value",
          "value": "Barcode / เลขที่จองเตียง"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1rem\", marginRight: \"0.8rem\"}"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 56,
      "name": "Input",
      "parent": 54,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeCodeIcontains"
        },
        "onKeyDown": {
          "type": "code",
          "value": "props.onKeyDownCodeIcontains"
        },
        "value": {
          "type": "code",
          "value": "props.codeIcontains"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "FormField",
      "parent": 34,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "Button",
      "parent": 57,
      "props": {
        "children": {
          "type": "value",
          "value": "ล้างการค้นหา"
        },
        "className": {
          "type": "value",
          "value": "fluid"
        },
        "color": {
          "type": "code",
          "value": "\"yellow\""
        },
        "onClick": {
          "type": "code",
          "value": "props.onClear"
        },
        "size": {
          "type": "code",
          "value": "\"small\""
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "FormField",
      "parent": 34,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "Button",
      "parent": 60,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์รายงานประจำวัน"
        },
        "color": {
          "type": "value",
          "value": "brown"
        },
        "onClick": {
          "type": "code",
          "value": "props.onPrintDailyReport"
        },
        "style": {
          "type": "code",
          "value": "{...(!props.showPrintReserveBedReportBtn && {display:\"none\"})}"
        }
      },
      "seq": 61,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 70,
  "isMounted": false,
  "memo": false,
  "name": "CardReserveBedQueueUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 65
}

*********************************************************************************** */
