import React, { useEffect, useState } from "react";
import CardDrugVerbalOrderUX from "./CardDrugVerbalOrderUX";
import { useIntl } from "react-intl";

type CardDrugVerbalOrderProps = {
  setProp?: any;

  drugVerbalFreeTxt?: {
    id?: number | null;
    note?: string;
  };
};

const CardDrugVerbalOrder = (props: CardDrugVerbalOrderProps) => {
  const [hideDrugTxt, setHideDrugTxt] = useState<boolean>(true);

  useEffect(() =>{
    props.setProp("drugVerbalFreeTxt", {
      id: null,
      ref_order: null,
      drugType: "",
      note: "",
    });
    setHideDrugTxt(true);
  },[]);

  useEffect(() => {
    if (props.drugVerbalFreeTxt?.id) {
      setHideDrugTxt(false);
    }
  }, [props.drugVerbalFreeTxt]);

  const handleNoteChange = (e: any) => {
    props.setProp("drugVerbalFreeTxt.note", e.target.value);
  };

  const handleClickDrugTxt = () => {
    setHideDrugTxt(!hideDrugTxt);
  };

  return (
    <div>
      <CardDrugVerbalOrderUX
        note={props.drugVerbalFreeTxt?.note || ""}
        noteChange={handleNoteChange}
        hideDrugTxt={hideDrugTxt}
        onClickDrugTxt={handleClickDrugTxt}
      />
    </div>
  );
};

export default CardDrugVerbalOrder;
