export default class BilPatientPanelController {
  constructor(props) {
    this.coreManager = props.coreManager;
    this.bilManager = props.bilManager;
    this.prxManager = props.prxManager;
  }

  getProduct = async ({ apiToken, name, code } = {}) => {
    const [response, error, network] = await this.coreManager.getProduct({
      name,
      code,
      apiToken
    });
    return [response, error, network];
  };

  postMiscellaneousOrderListCreate = async ({ apiToken, orderList, division } = {}) => {
    const [response, error, network] = await this.coreManager.postMiscellaneousOrderListCreate({
      orderList,
      apiToken,
      division,
    });
    return [response, error, network];
  }

  getMiscellaneousOrder = async ({ apiToken, encounterId } = {}) => {
    const [response, error, network] = await this.coreManager.getMiscellaneousOrder({
      encounterId,
      apiToken,
    });
    return [response, error, network];
  }

  deleteMiscellaneousOrder = async ({ apiToken, orderId } = {}) => {
    const [response, error, network] = await this.coreManager.deleteMiscellaneousOrder({
      id: orderId,
      apiToken,
    });
    return [response, error, network];
  }

  patchMiscellaneousOrder = async ({ apiToken, orderId, data } = {}) => {
    const [response, error, network] = await this.coreManager.patchMiscellaneousOrder({
      id: orderId,
      apiToken,
      data,
    });
    return [response, error, network];
  }

  getInvoiceItemByItem = async ({ apiToken, encounterId, patientId } = {}) => {
    const [response, error, network] = await this.bilManager.getInvoiceItemByItem({
      encounterId,
      patientId,
      apiToken,
    });
    return [response, error, network];
  }

  postInvoice = async ({ apiToken, encounterId, items, patientId, type, device } = {}) => {
    const [response, error, network] = await this.bilManager.postInvoice({
      encounterId,
      apiToken,
      items,
      patientId,
      type,
      device
    });
    return [response, error, network];
  }

  getShippingAddress = async ({
    apiToken,
    patientId,
    encounterId
  } = {}) => {
    const [res, error, network] = await this.prxManager.getShippingAddress({
      apiToken,
      patientId,
      encounterId
    });
    return [res, error, network];
  }
}
