import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Form,
  FormGroup,
  Checkbox,
  Dropdown,
  TextArea,
  Input
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardCovidCertificateUX = (props: any) => {
    return(
      <div>
        <Form>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "1em 0em 0em 1em", gap: "1em"}}>
            <label
              style={{display: "flex",  alignItems: "center", fontWeight: "bold", width: "9.5em"}}>
              วันที่ตรวจ
            </label>
            <DateTextBox
              disabled={props.onHandleDisableForm}
              id="CardCovidCertificate-DateTextBox-ExamDate"
              minDate={props.onMinDate}
              onChange={(date: string) => props.onHandleChangeDate("examDate", date)}
              value={props.onExamDate}>
            </DateTextBox>
            <label
              style={{display: "flex",  alignItems: "center"}}>
              เวลา
            </label>
            <div
              style={{width: "6em"}}>
              {props.onExamTime}
            </div>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 0em 1em"}}>
            <label
              style={{display: "flex",  alignItems: "center", fontWeight: "bold", width: "9.5em"}}>
              ผลทดสอบของผู้ป่วย
            </label>
            <Checkbox
              checked={props.onTestedResult("Positive (ตรวจพบเชื้อ)")}
              disabled={props.onHandleDisableForm}
              id="CardCovidCertificate-Checkbox-Positive"
              label="Positive (ตรวจพบเชื้อ)"
              onChange={(e) => props.onHandleChangeTestedResult(e, "Positive (ตรวจพบเชื้อ)")}
              style={{display: "flex",  alignItems: "center", padding: "0em 0em 0em 1em"}}>
            </Checkbox>
            <label
              style={{display: "flex",  alignItems: "center", padding: "0em 0em 0em 0.5em"}}>
              ของโรค COVID-19
            </label>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 0em 1em"}}>
            <label
              style={{display: "flex",  alignItems: "center", fontWeight: "bold", width: "9.5em"}}>
               
            </label>
            <Checkbox
              checked={props.onTestedResult("Negative (ไม่พบเชื้อ)")}
              disabled={props.onHandleDisableForm}
              id="CardCovidCertificate-Checkbox-Negative"
              label="Negative (ไม่พบเชื้อ)"
              onChange={(e) => props.onHandleChangeTestedResult(e, "Negative (ไม่พบเชื้อ)")}
              style={{display: "flex",  alignItems: "center", padding: "0em 0em 0em 1em"}}>
            </Checkbox>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 0em 1em", gap: "1em"}}>
            <label
              style={{display: "flex",  alignItems: "center"}}>
              โดย
            </label>
            <Dropdown
              allowAdditions={true}
              clearable={true}
              disabled={props.onHandleDisableForm}
              id="CardCovidCertificate-Dropdown-Method"
              onAddItem={props.onHandleAddMethod}
              onChange={props.onHandleChangeMethod}
              options={props.onTestedMethod}
              search={true}
              selection={true}
              style={{minWidth: "12.5em"}}
              value={props.onTestedMethodValue}>
            </Dropdown>
            <label
              style={{display: "flex",  alignItems: "center"}}>
              Swab วันที่
            </label>
            <DateTextBox
              disabled={props.onHandleDisableForm}
              id="CardCovidCertificate-DateTextBox-SwabDate"
              onChange={(date: string) => props.onHandleChangeDate("swabDate", date)}
              style={{width: "10em"}}
              value={props.onSwabDate}>
            </DateTextBox>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "2em 0em 2em 1em"}}>
            <Checkbox
              checked={props.onGetAdvised}
              disabled={props.onHandleDisableForm}
              id="CardCovidCertificate-Checkbox-Advised"
              label="แนะนำให้ผู้ป่วยอยู่ในความดูแลของบุคลากรทางการแพทย์ตามแนวทางของกระทรวงสาธารณสุข (สธ.)"
              onChange={(e, data) => props.onHandleChangeCheckbox(e, "getAdvised", data)}
              style={{display: "flex",  alignItems: "center", width: "30.4em"}}>
            </Checkbox>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 0em 1em"}}>
            <label
              style={{display: "flex", fontWeight: "bold"}}>
              ข้อแนะนำอื่น ๆ
            </label>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 0em 2em"}}>
            <TextArea
              disabled={props.onHandleDisableForm}
              id="CardCovidCertificate-TextArea-Comment"
              onChange={(e) => props.onHandleChangeText("comment", e)}
              rows="15"
              value={props.onComment}>
            </TextArea>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 0em 2em", gap: "1em"}}>
            <Checkbox
              checked={props.onShouldAbsence}
              disabled={props.onHandleDisableForm}
              id="CardCovidCertificate-Checkbox-ShouldAbsence"
              label="ระยะเวลาที่ควรพักงาน"
              onChange={(e, data) => props.onHandleChangeCheckbox(e, "shouldAbsence", data)}
              style={{display: "flex",  alignItems: "center",  padding: "0em 2.1em 0em 0em"}}>
            </Checkbox>
            <Input
              disabled={props.onHandleDurationDisable() || props.onHandleDisableForm}
              id="CardCovidCertificate-Input-Duration"
              onChange={(e) => props.onHandleChangeText("absenceDuration", e)}
              style={{width: "6em"}}
              value={props.onAbsenceDuration}>
            </Input>
            <label
              style={{display: "flex",  alignItems: "center"}}>
              วัน
            </label>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", padding: "0em 0em 0em 2em", gap: "1em"}}>
            <label
              style={{display: "flex",  alignItems: "center"}}>
              ตั้งแต่
            </label>
            <DateTextBox
              disabled={props.onHandleDurationDisable() || props.onHandleDisableForm}
              id="CardCovidCertificate-DateTextBox-StartDate"
              minDate={props.onAbsenceMinDate}
              onChange={(date: string) => props.onHandleChangeDate("absenceStartDate", date)}
              style={{width: "12em"}}
              value={props.onAbsenceStartDate}>
            </DateTextBox>
            <label
              style={{display: "flex",  alignItems: "center"}}>
              ถึง
            </label>
            <DateTextBox
              disabled={props.onHandleDurationDisable() || props.onHandleDisableForm}
              id="CardCovidCertificate-DateTextBox-EndDate"
              minDate={props.onAbsenceMinDate}
              onChange={(date: string) => props.onHandleChangeDate("absenceEndDate", date)}
              style={{width: "12em"}}
              value={props.onAbsenceEndDate}>
            </DateTextBox>
          </FormGroup>
          <FormGroup
            className="ui form"
            style={{display: "flex", justifyContent: "flex-end"}}>
            <label>
              {props.onCloseSelected}
            </label>
          </FormGroup>
        </Form>
      </div>
    )
}


export default CardCovidCertificateUX

export const screenPropsDefault = {"language":"TH"}

/* Date Time : Wed Oct 09 2024 12:05:21 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "className": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 255,
      "name": "label",
      "parent": 255,
      "props": {
        "children": {
          "type": "value",
          "value": "หลังจากการติดเชื้อ COVID-19 : เมื่อหายป่วยตามอาการทางคลินิก แนะนำให้สวมหน้ากากอนามัย ล้างมือ เว้นระยะห่างทางสังคม และหลีกเลี่ยงสถานที่แออัด"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\"}"
        }
      },
      "seq": 255,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 270,
      "name": "Form",
      "parent": 0,
      "props": {
      },
      "seq": 270,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 271,
      "name": "FormGroup",
      "parent": 270,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"1em 0em 0em 1em\", gap: \"1em\"}"
        }
      },
      "seq": 271,
      "void": false
    },
    {
      "from": null,
      "id": 272,
      "name": "label",
      "parent": 271,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่ตรวจ"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\", fontWeight: \"bold\", width: \"9.5em\"}"
        }
      },
      "seq": 272,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 273,
      "name": "DateTextBox",
      "parent": 271,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardCovidCertificate-DateTextBox-ExamDate"
        },
        "minDate": {
          "type": "code",
          "value": "props.onMinDate"
        },
        "onChange": {
          "type": "code",
          "value": "(date: string) => props.onHandleChangeDate(\"examDate\", date)"
        },
        "value": {
          "type": "code",
          "value": "props.onExamDate"
        }
      },
      "seq": 273,
      "void": false
    },
    {
      "from": null,
      "id": 274,
      "name": "label",
      "parent": 271,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลา"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\"}"
        }
      },
      "seq": 274,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 276,
      "name": "FormGroup",
      "parent": 270,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 1em\"}"
        }
      },
      "seq": 276,
      "void": false
    },
    {
      "from": null,
      "id": 277,
      "name": "label",
      "parent": 276,
      "props": {
        "children": {
          "type": "value",
          "value": "ผลทดสอบของผู้ป่วย"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\", fontWeight: \"bold\", width: \"9.5em\"}"
        }
      },
      "seq": 277,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 278,
      "name": "Checkbox",
      "parent": 276,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.onTestedResult(\"Positive (ตรวจพบเชื้อ)\")"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardCovidCertificate-Checkbox-Positive"
        },
        "label": {
          "type": "value",
          "value": "Positive (ตรวจพบเชื้อ)"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleChangeTestedResult(e, \"Positive (ตรวจพบเชื้อ)\")"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\", padding: \"0em 0em 0em 1em\"}"
        }
      },
      "seq": 278,
      "void": false
    },
    {
      "from": null,
      "id": 279,
      "name": "label",
      "parent": 276,
      "props": {
        "children": {
          "type": "value",
          "value": "ของโรค COVID-19"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\", padding: \"0em 0em 0em 0.5em\"}"
        }
      },
      "seq": 279,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 281,
      "name": "FormGroup",
      "parent": 270,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 1em\"}"
        }
      },
      "seq": 281,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 282,
      "name": "Checkbox",
      "parent": 281,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.onTestedResult(\"Negative (ไม่พบเชื้อ)\")"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardCovidCertificate-Checkbox-Negative"
        },
        "label": {
          "type": "value",
          "value": "Negative (ไม่พบเชื้อ)"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleChangeTestedResult(e, \"Negative (ไม่พบเชื้อ)\")"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\", padding: \"0em 0em 0em 1em\"}"
        }
      },
      "seq": 312,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 283,
      "name": "FormGroup",
      "parent": 270,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 1em\", gap: \"1em\"}"
        }
      },
      "seq": 283,
      "void": false
    },
    {
      "from": null,
      "id": 284,
      "name": "label",
      "parent": 283,
      "props": {
        "children": {
          "type": "value",
          "value": "โดย"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\"}"
        }
      },
      "seq": 284,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 285,
      "name": "Dropdown",
      "parent": 283,
      "props": {
        "allowAdditions": {
          "type": "code",
          "value": "true"
        },
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardCovidCertificate-Dropdown-Method"
        },
        "onAddItem": {
          "type": "code",
          "value": "props.onHandleAddMethod"
        },
        "onChange": {
          "type": "code",
          "value": "props.onHandleChangeMethod"
        },
        "options": {
          "type": "code",
          "value": "props.onTestedMethod"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"12.5em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.onTestedMethodValue"
        }
      },
      "seq": 285,
      "void": false
    },
    {
      "from": null,
      "id": 286,
      "name": "label",
      "parent": 283,
      "props": {
        "children": {
          "type": "value",
          "value": "Swab วันที่"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\"}"
        }
      },
      "seq": 286,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 287,
      "name": "DateTextBox",
      "parent": 283,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardCovidCertificate-DateTextBox-SwabDate"
        },
        "onChange": {
          "type": "code",
          "value": "(date: string) => props.onHandleChangeDate(\"swabDate\", date)"
        },
        "style": {
          "type": "code",
          "value": "{width: \"10em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.onSwabDate"
        }
      },
      "seq": 287,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 288,
      "name": "FormGroup",
      "parent": 270,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"2em 0em 2em 1em\"}"
        }
      },
      "seq": 288,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 289,
      "name": "Checkbox",
      "parent": 288,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.onGetAdvised"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardCovidCertificate-Checkbox-Advised"
        },
        "label": {
          "type": "value",
          "value": "แนะนำให้ผู้ป่วยอยู่ในความดูแลของบุคลากรทางการแพทย์ตามแนวทางของกระทรวงสาธารณสุข (สธ.)"
        },
        "onChange": {
          "type": "code",
          "value": "(e, data) => props.onHandleChangeCheckbox(e, \"getAdvised\", data)"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\", width: \"30.4em\"}"
        }
      },
      "seq": 289,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 290,
      "name": "FormGroup",
      "parent": 270,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 1em\"}"
        }
      },
      "seq": 290,
      "void": false
    },
    {
      "from": null,
      "id": 291,
      "name": "label",
      "parent": 290,
      "props": {
        "children": {
          "type": "value",
          "value": "ข้อแนะนำอื่น ๆ"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", fontWeight: \"bold\"}"
        }
      },
      "seq": 291,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 292,
      "name": "TextArea",
      "parent": 304,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardCovidCertificate-TextArea-Comment"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleChangeText(\"comment\", e)"
        },
        "rows": {
          "type": "value",
          "value": "15"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.onComment"
        }
      },
      "seq": 292,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 293,
      "name": "FormGroup",
      "parent": 270,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 2em\", gap: \"1em\"}"
        }
      },
      "seq": 297,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 294,
      "name": "Checkbox",
      "parent": 293,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.onShouldAbsence"
        },
        "disabled": {
          "type": "code",
          "value": "props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardCovidCertificate-Checkbox-ShouldAbsence"
        },
        "label": {
          "type": "value",
          "value": "ระยะเวลาที่ควรพักงาน"
        },
        "onChange": {
          "type": "code",
          "value": "(e, data) => props.onHandleChangeCheckbox(e, \"shouldAbsence\", data)"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\",  padding: \"0em 2.1em 0em 0em\"}"
        }
      },
      "seq": 294,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 295,
      "name": "Input",
      "parent": 293,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleDurationDisable() || props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardCovidCertificate-Input-Duration"
        },
        "onChange": {
          "type": "code",
          "value": "(e) => props.onHandleChangeText(\"absenceDuration\", e)"
        },
        "style": {
          "type": "code",
          "value": "{width: \"6em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.onAbsenceDuration"
        }
      },
      "seq": 295,
      "void": false
    },
    {
      "from": null,
      "id": 296,
      "name": "label",
      "parent": 293,
      "props": {
        "children": {
          "type": "value",
          "value": "วัน"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\"}"
        }
      },
      "seq": 296,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 297,
      "name": "FormGroup",
      "parent": 270,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 2em\", gap: \"1em\"}"
        }
      },
      "seq": 304,
      "void": false
    },
    {
      "from": null,
      "id": 298,
      "name": "label",
      "parent": 297,
      "props": {
        "children": {
          "type": "value",
          "value": "ตั้งแต่"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\"}"
        }
      },
      "seq": 298,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 299,
      "name": "DateTextBox",
      "parent": 297,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleDurationDisable() || props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardCovidCertificate-DateTextBox-StartDate"
        },
        "minDate": {
          "type": "code",
          "value": "props.onAbsenceMinDate"
        },
        "onChange": {
          "type": "code",
          "value": "(date: string) => props.onHandleChangeDate(\"absenceStartDate\", date)"
        },
        "style": {
          "type": "code",
          "value": "{width: \"12em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.onAbsenceStartDate"
        }
      },
      "seq": 299,
      "void": false
    },
    {
      "from": null,
      "id": 300,
      "name": "label",
      "parent": 297,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\"}"
        }
      },
      "seq": 300,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 301,
      "name": "DateTextBox",
      "parent": 297,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.onHandleDurationDisable() || props.onHandleDisableForm"
        },
        "id": {
          "type": "value",
          "value": "CardCovidCertificate-DateTextBox-EndDate"
        },
        "minDate": {
          "type": "code",
          "value": "props.onAbsenceMinDate"
        },
        "onChange": {
          "type": "code",
          "value": "(date: string) => props.onHandleChangeDate(\"absenceEndDate\", date)"
        },
        "style": {
          "type": "code",
          "value": "{width: \"12em\"}"
        },
        "value": {
          "type": "code",
          "value": "props.onAbsenceEndDate"
        }
      },
      "seq": 301,
      "void": false
    },
    {
      "from": null,
      "id": 303,
      "name": "div",
      "parent": 271,
      "props": {
        "children": {
          "type": "value",
          "value": "{props.onExamTime}"
        },
        "style": {
          "type": "code",
          "value": "{width: \"6em\"}"
        }
      },
      "seq": 303,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 304,
      "name": "FormGroup",
      "parent": 270,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", padding: \"0em 0em 0em 2em\"}"
        }
      },
      "seq": 293,
      "void": false
    },
    {
      "from": null,
      "id": 312,
      "name": "label",
      "parent": 281,
      "props": {
        "children": {
          "type": "value",
          "value": " "
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\",  alignItems: \"center\", fontWeight: \"bold\", width: \"9.5em\"}"
        }
      },
      "seq": 282,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 313,
      "name": "FormGroup",
      "parent": 270,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\"}"
        }
      },
      "seq": 313,
      "void": false
    },
    {
      "from": null,
      "id": 314,
      "name": "label",
      "parent": 313,
      "props": {
        "children": {
          "type": "value",
          "value": "{props.onCloseSelected}"
        }
      },
      "seq": 314,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardCovidCertificateUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "language": "TH"
  },
  "width": 55
}

*********************************************************************************** */
