import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Calendar
} from 'react-big-calendar'
import {
  Dropdown,
  Input,
  Button,
  Checkbox
} from 'semantic-ui-react'

const CardDentistScheduleUX = (props: any) => {
    return(
      <div
        style={{height: "100%", display: "flex"}}>
        <div
          className="ui form --override-disabled --grey"
          style={{flex: "1 1 20%",overflowY:"scroll",height:"100%"}}>
          
          <div
            style={{...(props.telephar && {display : "none" })}}>
            {props.providerSelector}
          </div>
          <div
            style={{marginTop: "10px", display: props.telephar? "none" : "flex", flexDirection: "column"}}>
            
            <div
              style={{...(!props.isShowChair && { display: "none" }) }}>
              
              <div>
                ประเภทการออกตรวจ
              </div>
              <Dropdown
                disabled={props.disabledExaminationType || false}
                fluid={true}
                onChange={props.onChangeExaminationType}
                options={props.examinationTypeOptions}
                selection={true}
                value={props.examinationType}>
              </Dropdown>
            </div>
            <div
              style={{flex: 1}}>
              
              <div>
                เวลาให้บริการต่อคนไข้ (นาที)
              </div>
              <Input
                fluid={true}
                onChange={props.changeDefaultSlotLength}
                value={props.defaultSlotLength}>
              </Input>
            </div>
            <div
              style={{flex: 1, ...(!props.isShowChair && { display: "none" }) }}>
              
              <div>
                เลือกเก้าอี้สำหรับการลงตาราง
              </div>
              <Dropdown
                fluid={true}
                onChange={props.changeDefaultChair}
                options={props.chairOptions}
                selection={true}
                value={props.defaultChair}>
              </Dropdown>
            </div>
          </div>
          <div
            style={{display:props.telephar? "none" : "flex", padding: "5px"}}>
            
            <Checkbox
              checked={props.exceptNewPatientAll}
              onChange={props.changeExceptNewPatientAll}
              style={{marginRight: "7px"}}>
            </Checkbox>
            <label>
              ไม่รับผู้ป่วยใหม่
            </label>
          </div>
          <div>
            {props.cardSelectTemplate}
          </div>
        </div>
        <div
          style={{ flex: 3, height: "100%"}}>
          
          <div
            style={{paddingBottom: "5px", display: "flex", justifyContent: "flex-end"}}>
            
            <Button
              color="green"
              disabled={props.disablePrint}
              onClick={props.printSummary}
              size="tiny">
              พิมพ์สรุปตารางการทำงาน
            </Button>
          </div>
          <Calendar
            eventPropGetter={props.eventPropGetter}
            events={props.blockList || []}
            formats={props.formats}
            localizer={props.localizer}
            max={props.maxTime}
            min={props.minTime}
            onRangeChange={props.onRangeChange}
            onSelectEvent={props.onSelectEvent}
            views={["month", "week", "day"]}>
          </Calendar>
        </div>
      </div>
    )
}


export default CardDentistScheduleUX

export const screenPropsDefault = {"cardSelectTemplate":"[CardSelectTemplate]","providerSelector":"[ProviderSelector]"}

/* Date Time : Thu Nov 14 2024 12:50:14 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{height: \"100%\", display: \"flex\"}"
        }
      },
      "seq": 0
    },
    {
      "from": "react-big-calendar",
      "id": 1,
      "name": "Calendar",
      "parent": 3,
      "props": {
        "eventPropGetter": {
          "type": "code",
          "value": "props.eventPropGetter"
        },
        "events": {
          "type": "code",
          "value": "props.blockList || []"
        },
        "formats": {
          "type": "code",
          "value": "props.formats"
        },
        "localizer": {
          "type": "code",
          "value": "props.localizer"
        },
        "max": {
          "type": "code",
          "value": "props.maxTime"
        },
        "min": {
          "type": "code",
          "value": "props.minTime"
        },
        "onRangeChange": {
          "type": "code",
          "value": "props.onRangeChange"
        },
        "onSelectEvent": {
          "type": "code",
          "value": "props.onSelectEvent"
        },
        "views": {
          "type": "code",
          "value": "[\"month\", \"week\", \"day\"]"
        }
      },
      "seq": 29,
      "void": true
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "code",
          "value": "props.providerSelector"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{...(props.telephar && {display : \"none\" })}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ flex: 3, height: \"100%\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form --override-disabled --grey"
        },
        "style": {
          "type": "code",
          "value": "{flex: \"1 1 20%\",overflowY:\"scroll\",height:\"100%\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "Dropdown",
      "parent": 22,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeDefaultChair"
        },
        "options": {
          "type": "code",
          "value": "props.chairOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.defaultChair"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": "เลือกเก้าอี้สำหรับการลงตาราง"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginTop: \"10px\", display: props.telephar? \"none\" : \"flex\", flexDirection: \"column\"}"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "ลงตารางโดยอ้างอิงจากเทมเพลทตารางคลีนิค"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"15px 0 0 0\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 23,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลาให้บริการต่อคนไข้ (นาที)"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: 1, ...(!props.isShowChair && { display: \"none\" }) }"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "Input",
      "parent": 23,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeDefaultSlotLength"
        },
        "value": {
          "type": "code",
          "value": "props.defaultSlotLength"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 27,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "code",
          "value": "props.cardSelectTemplate"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "Button",
      "parent": 29,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์สรุปตารางการทำงาน"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.disablePrint"
        },
        "onClick": {
          "type": "code",
          "value": "props.printSummary"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 29,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{paddingBottom: \"5px\", display: \"flex\", justifyContent: \"flex-end\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "div",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:props.telephar? \"none\" : \"flex\", padding: \"5px\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "Checkbox",
      "parent": 30,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.exceptNewPatientAll"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeExceptNewPatientAll"
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"7px\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "label",
      "parent": 30,
      "props": {
        "children": {
          "type": "value",
          "value": "ไม่รับผู้ป่วยใหม่"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 33,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{...(!props.isShowChair && { display: \"none\" }) }"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 34,
      "name": "div",
      "parent": 33,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทการออกตรวจ"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "Dropdown",
      "parent": 33,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.disabledExaminationType || false"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeExaminationType"
        },
        "options": {
          "type": "code",
          "value": "props.examinationTypeOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.examinationType"
        }
      },
      "seq": 35,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardDentistScheduleUX",
  "project": "cudent2",
  "screenPropsDefault": {
    "cardSelectTemplate": "[CardSelectTemplate]",
    "providerSelector": "[ProviderSelector]"
  },
  "width": 80
}

*********************************************************************************** */
