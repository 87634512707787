import React from 'react'
import {
   Button,
   Checkbox,
   Dropdown,
   Input,
} from 'semantic-ui-react'



import styles from './OTPhysicalExaminationPediatrics3.module.css'

export interface OTPhysicalExaminationPediatrics3ViewDataType {
    CtVestibular: string,
    CtVestibularDisabled: boolean,
    CtVestibularOptions: any,
    OtPhysicalPediatric38: string,
    OtPhysicalPediatric38Disabled: boolean,
    CtProprioceptive: string,
    CtProprioceptiveDisabled: boolean,
    CtProprioceptiveOptions: any,
    OtPhysicalPediatric313: string,
    OtPhysicalPediatric313Disabled: boolean,
    CtTactile: string,
    CtTactileDisabled: boolean,
    CtTactileOptions: any,
    OtPhysicalPediatric318: string,
    OtPhysicalPediatric318Disabled: boolean,
    CtVisual: string,
    CtVisualDisabled: boolean,
    CtVisualOptions: any,
    OtPhysicalPediatric323: string,
    OtPhysicalPediatric323Disabled: boolean,
    CtAuditory: string,
    CtAuditoryDisabled: boolean,
    CtAuditoryOptions: any,
    OtPhysicalPediatric328: string,
    OtPhysicalPediatric328Disabled: boolean,
    CtGustatory: string,
    CtGustatoryDisabled: boolean,
    CtGustatoryOptions: any,
    OtPhysicalPediatric334: string,
    OtPhysicalPediatric334Disabled: boolean,
    OtPhysicalPediatric335: boolean,
    OtPhysicalPediatric335Disabled: boolean,
    OtPhysicalPediatric338: string,
    OtPhysicalPediatric338Disabled: boolean,
    SaveRequestDisabled: boolean,
}

export const OTPhysicalExaminationPediatrics3InitialViewData: OTPhysicalExaminationPediatrics3ViewDataType = {
    CtVestibular: "",
    CtVestibularDisabled: false,
    CtVestibularOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalPediatric38: "",
    OtPhysicalPediatric38Disabled: false,
    CtProprioceptive: "",
    CtProprioceptiveDisabled: false,
    CtProprioceptiveOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalPediatric313: "",
    OtPhysicalPediatric313Disabled: false,
    CtTactile: "",
    CtTactileDisabled: false,
    CtTactileOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalPediatric318: "",
    OtPhysicalPediatric318Disabled: false,
    CtVisual: "",
    CtVisualDisabled: false,
    CtVisualOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalPediatric323: "",
    OtPhysicalPediatric323Disabled: false,
    CtAuditory: "",
    CtAuditoryDisabled: false,
    CtAuditoryOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalPediatric328: "",
    OtPhysicalPediatric328Disabled: false,
    CtGustatory: "",
    CtGustatoryDisabled: false,
    CtGustatoryOptions: [{key: 0, value: "A", text: "A"}],
    OtPhysicalPediatric334: "",
    OtPhysicalPediatric334Disabled: false,
    OtPhysicalPediatric335: false,
    OtPhysicalPediatric335Disabled: false,
    OtPhysicalPediatric338: "",
    OtPhysicalPediatric338Disabled: false,
    SaveRequestDisabled: false,
}

const Sub = (props: any) => {
    const children = React.Children.toArray(props.children)
    return (
        <div className={styles.container}>
            <div className={styles.OtPhysicalPediatric31}>
                <div
                    style={{backgroundColor: "#F3F3F3", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.CtVestibular}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtVestibular}
                    disabled={ props.viewData.CtVestibularDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationPediatrics3",
                                name: "CtVestibular",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtVestibularOptions}
                />
            </div>
            <div className={styles.OtPhysicalPediatric35}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Sensory Integration assessment</strong></p>
                </div>
            </div>
            <div className={styles.OtPhysicalPediatric36}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Vestibular system</strong></p>
                </div>
            </div>
            <div className={styles.OtPhysicalPediatric38}>
                <Input
                    value={props.viewData.OtPhysicalPediatric38}
                    disabled={ props.viewData.OtPhysicalPediatric38Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPediatrics3',
                            name: 'OtPhysicalPediatric38',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CtProprioceptive}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtProprioceptive}
                    disabled={ props.viewData.CtProprioceptiveDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationPediatrics3",
                                name: "CtProprioceptive",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtProprioceptiveOptions}
                />
            </div>
            <div className={styles.OtPhysicalPediatric312}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Proprioceptive system</strong></p>
                </div>
            </div>
            <div className={styles.OtPhysicalPediatric313}>
                <Input
                    value={props.viewData.OtPhysicalPediatric313}
                    disabled={ props.viewData.OtPhysicalPediatric313Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPediatrics3',
                            name: 'OtPhysicalPediatric313',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CtTactile}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtTactile}
                    disabled={ props.viewData.CtTactileDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationPediatrics3",
                                name: "CtTactile",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtTactileOptions}
                />
            </div>
            <div className={styles.OtPhysicalPediatric317}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Tactile system</strong></p>
                </div>
            </div>
            <div className={styles.OtPhysicalPediatric318}>
                <Input
                    value={props.viewData.OtPhysicalPediatric318}
                    disabled={ props.viewData.OtPhysicalPediatric318Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPediatrics3',
                            name: 'OtPhysicalPediatric318',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CtVisual}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtVisual}
                    disabled={ props.viewData.CtVisualDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationPediatrics3",
                                name: "CtVisual",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtVisualOptions}
                />
            </div>
            <div className={styles.OtPhysicalPediatric322}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Visual system</strong></p>
                </div>
            </div>
            <div className={styles.OtPhysicalPediatric323}>
                <Input
                    value={props.viewData.OtPhysicalPediatric323}
                    disabled={ props.viewData.OtPhysicalPediatric323Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPediatrics3',
                            name: 'OtPhysicalPediatric323',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.CtAuditory}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtAuditory}
                    disabled={ props.viewData.CtAuditoryDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationPediatrics3",
                                name: "CtAuditory",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtAuditoryOptions}
                />
            </div>
            <div className={styles.OtPhysicalPediatric327}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Auditory system</strong></p>
                </div>
            </div>
            <div className={styles.OtPhysicalPediatric328}>
                <Input
                    value={props.viewData.OtPhysicalPediatric328}
                    disabled={ props.viewData.OtPhysicalPediatric328Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPediatrics3',
                            name: 'OtPhysicalPediatric328',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalPediatric329}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Gustatory system and olfactory system</strong></p>
                </div>
            </div>
            <div className={styles.CtGustatory}>
                <Dropdown selection
                    style={{height: "100%", width: "100%"}}
                    value={props.viewData.CtGustatory}
                    disabled={ props.viewData.CtGustatoryDisabled }
                    onChange={(e, data) => {
                        props.onEvent({
                            message: "choose",
                            params: {
                                view: "OTPhysicalExaminationPediatrics3",
                                name: "CtGustatory",
                                value: data.value
                            }
                        })
                    }}
                    options={props.viewData.CtGustatoryOptions}
                />
            </div>
            <div className={styles.OtPhysicalPediatric334}>
                <Input
                    value={props.viewData.OtPhysicalPediatric334}
                    disabled={ props.viewData.OtPhysicalPediatric334Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPediatrics3',
                            name: 'OtPhysicalPediatric334',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.OtPhysicalPediatric335}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.OtPhysicalPediatric335}
                    disabled={ props.viewData.OtPhysicalPediatric335Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "OTPhysicalExaminationPediatrics3",
                                name: "OtPhysicalPediatric335"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.OtPhysicalPediatric336}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Other assessment</strong></p>
                </div>
            </div>
            <div className={styles.OtPhysicalPediatric337}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>N/A</p>
                </div>
            </div>
            <div className={styles.OtPhysicalPediatric338}>
                <Input
                    value={props.viewData.OtPhysicalPediatric338}
                    disabled={ props.viewData.OtPhysicalPediatric338Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'OTPhysicalExaminationPediatrics3',
                            name: 'OtPhysicalPediatric338',
                            value: e.target.value
                        }
                    })}}
                    style={{ height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.SaveRequest}>
                <Button
                    disabled={ props.viewData.SaveRequestDisabled }
                    onClick={(e) => { props.onEvent({
                        message: 'clickButton',
                        params: {
                            view: 'OTPhysicalExaminationPediatrics3',
                            name: 'SaveRequest'
                        }
                    })}}
                    style={{color: "#FFFFFF",backgroundColor: "#27AE60", height: "100%", width: "100%"}}
                >
                    Save
                </Button>
            </div>
        </div>
    )
}

export default Sub

const getTsName = (cssClass: string) => {
    return cssClass.split('-').map((s) => (
        s.charAt(0).toUpperCase() + s.slice(1)
    )).join('')
}
