import WasmController from "react-lib/frameworks/WasmController";

// APIs
// INF
import EClaimCoverageList from "issara-sdk/apis/EClaimCoverageList_apps_INF";
import EclaimUploadResponse from "issara-sdk/apis/EclaimUploadResponse_apps_INFM";
import ImportResponseFileIPD from "issara-sdk/apis/ImportResponseFileIPD_apps_INFM";

// Interface
import { State as MainState } from "../../../../../HIS/MainHISInterface";
import { SetErrorMessage, SetProperty, mapOptions } from "../../common/CommonInterface";

// Utils
export type State = Partial<{
  SendClaimResponseFileSequence: Partial<{
    sequenceIndex?: "START" | NextIndexType | null;
    aipnDetail: Partial<AIPNDetail>;
    eclaimDetail: Partial<EClaimDetail>;
    responseFile: File | null;
    eclaimCoverageOptions: OptionType[];
  }> | null;
}>;

type PickedState = Partial<Pick<MainState, "buttonLoadCheck" | "errorMessage">>;

export type PickedProps = Partial<Omit<PickedState, "masterOptions">>;

export type AIPNDetail = {
  coverage_payer_sent_claim_group_name: string;
  hospital_code: string;
  items: {
    encounter_id: string;
    failed_reasons: string;
    invoice_no?: string;
    success: boolean;
  }[];
  response_at: string;
  response_no: string;
  sent_no: string;
};

export type EClaimDetail = {
  coverage_payer_sent_claim_group: number;
  document_date: string;
  document_no: string;
  hospital_code: string;
  items: {
    datetime_start: string;
    hn: string;
    receiving_total: number;
    rep_no: string;
    sent_claim_price: number;
  }[];
  reply_no: string;
};

export type MasterOptionsType = Record<(typeof Masters)[number][0], OptionType[]>;

export type OptionType = {
  key: number | string;
  text: string;
  value: number | string;
};

// Sequence
type NextIndexType = "AIPN" | "EClaim";

type SeqState = {
  sequence: "SendClaimResponseFile";
  clear?: boolean;
  nextIndex?: NextIndexType;
  noInit?: boolean;
  restart?: boolean;
};

// Handle Action
type ActionType =
  | {
      action: "INIT";
    }
  | { action: "SAVE"; card: string }
  | { action: "UPLOAD_FILE"; accepted: File[]; card: string; rejected: File[] };

type SeqAct = ActionType & SeqState;
type SeqType<K> = K extends { action: string } ? Extract<SeqAct, K> : SeqState;

export type RunSequence = <K extends keyof SeqAct>(params: SeqType<Pick<SeqAct, K>>) => void;

export type SetProp = SetProperty<PickedState & State>;

type CustomExtract<T, U> = T extends object ? (U extends Partial<T> ? T : never) : never;

type Params<A extends ActionType["action"]> = CustomExtract<ActionType, { action: A }>;

export const StateInitial: State = {
  // sequence
  SendClaimResponseFileSequence: {
    sequenceIndex: null,
  },
};

export type Event =
  | { message: "GetMasterData"; params: Record<string, unknown> }
  | { message: "RunSequence"; params: Record<string, unknown> };

export type Data = {
  division?: number;
  masterData?: Record<string, any>;
};

export const DataInitial = {};

const Masters = [] as const;

export const ACTIONS = {
  INIT: "INIT",
  SAVE: "SAVE",
  UPLOAD_FILE: "UPLOAD_FILE",
} as const;

export const CARD_SEND_CLAIM_RESPONSE_FILE = "CardSendClaimResponseFile";

type Optional<T> = {
  [K in keyof T]: `${typeof CARD_SEND_CLAIM_RESPONSE_FILE}_${T[K] & string}`;
};

export const BTN_ACTS = Object.fromEntries(
  Object.entries(ACTIONS).map(([key, value]) => [key, `${CARD_SEND_CLAIM_RESPONSE_FILE}_${value}`])
) as Optional<typeof ACTIONS>;

type Handler<P = any, R = any> = (
  controller: WasmController<PickedState & State, Event, Data>,
  params: P
) => R;

export const Start: Handler<SeqState> = async (controller, params) => {
  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: Masters,
    },
  });

  const [result] = await EClaimCoverageList.list({
    apiToken: controller.apiToken,
    params: {},
  });

  const items: any[] = result?.items || [];

  const eclaimCoverageOptions = mapOptions(items, "id", "code_name");

  const state = controller.getState();

  controller.setState({
    SendClaimResponseFileSequence: {
      ...state.SendClaimResponseFileSequence,
      eclaimCoverageOptions,
    },
  });

  Next(controller, params);
};

/* ------------------------------------------------------ */

/*                          Next                          */

/* ------------------------------------------------------ */
const Next: Handler<SeqState> = (controller, params) => {
  const state = controller.getState();

  const { nextIndex, noInit } = params;

  if (!nextIndex) {
    return;
  }

  controller.setState(
    {
      SendClaimResponseFileSequence: {
        ...state.SendClaimResponseFileSequence,
        sequenceIndex: nextIndex,
      },
    },
    () => {
      const init = {
        AIPN,
        EClaim,
      }[nextIndex];

      if (!noInit) {
        init(controller, { ...params, action: ACTIONS.INIT, nextIndex: undefined });
      }
    }
  );
};

/* ------------------------------------------------------ */

/*                      Handle Action                     */

/* ------------------------------------------------------ */
export const EClaim: Handler<ActionType & Pick<SeqState, "nextIndex" | "sequence">> = async (
  controller,
  params
) => {
  if (params.nextIndex) {
    return Next(controller, params);
  }

  const actionHandlers: Partial<{ [K in ActionType["action"]]: Handler<Params<K>> }> = {
    [ACTIONS.INIT]: HandleInitEClaim,
    [ACTIONS.SAVE]: HandleSaveEClaim,
    [ACTIONS.UPLOAD_FILE]: HandleUploadFileEClaim,
  };

  const { action } = params;

  return actionHandlers[action]?.(controller, params as Params<typeof params.action>);
};

export const AIPN: Handler<ActionType & Pick<SeqState, "nextIndex" | "sequence">> = async (
  controller,
  params
) => {
  if (params.nextIndex) {
    return Next(controller, params);
  }

  const actionHandlers: Partial<{ [K in ActionType["action"]]: Handler<Params<K>> }> = {
    [ACTIONS.INIT]: HandleInitAIPN,
    [ACTIONS.SAVE]: HandleSaveAIPN,
    [ACTIONS.UPLOAD_FILE]: HandleUploadFileAIPN,
  };

  const { action } = params;

  return actionHandlers[action]?.(controller, params as Params<typeof params.action>);
};

/* ------------------------------------------------------ */

/*                      Handle EClaim                     */

/* ------------------------------------------------------ */
const HandleInitEClaim: Handler<Params<"INIT">> = (controller) => {
  const state = controller.getState();

  controller.setState({
    SendClaimResponseFileSequence: {
      ...state.SendClaimResponseFileSequence,
      eclaimDetail: {},
      responseFile: null,
    },
  });
};

const HandleUploadFileEClaim: Handler<Params<"UPLOAD_FILE">> = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.UPLOAD_FILE]: "LOADING" },
  });

  const [result, error] = await PutImportResponseFile(controller, { ...params, type: "EClaim" });

  if (error) {
    SetErrorMessage(controller, { ...params, btnError: "", error });

    return;
  }

  const seq = state.SendClaimResponseFileSequence;

  controller.setState({
    SendClaimResponseFileSequence: {
      ...seq,
      eclaimDetail: {
        ...result,
        coverage_payer_sent_claim_group: seq?.eclaimDetail?.coverage_payer_sent_claim_group,
      },
      responseFile: params.accepted[0],
    },
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.UPLOAD_FILE]: null },
  });
};

const HandleSaveEClaim: Handler<Params<"SAVE">> = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SAVE]: "LOADING" },
  });

  const seq = state.SendClaimResponseFileSequence || {};

  const [, error] = await EclaimUploadResponse.post({
    apiToken: controller.apiToken,
    data: {
      coverage_id: seq.eclaimDetail?.coverage_payer_sent_claim_group,
      file: seq.responseFile,
      save: true,
    },
  });

  if (error) {
    SetErrorMessage(controller, { ...params, error });

    return;
  }

  controller.setState({
    SendClaimResponseFileSequence: {
      ...state.SendClaimResponseFileSequence,
      eclaimDetail: {},
      responseFile: null,
    },
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SAVE]: "SUCCESS" },
  });
};

/* ------------------------------------------------------ */

/*                       handle AIPN                      */

/* ------------------------------------------------------ */
const HandleInitAIPN: Handler<Params<"INIT">> = (controller) => {
  const state = controller.getState();

  controller.setState({
    SendClaimResponseFileSequence: {
      ...state.SendClaimResponseFileSequence,
      aipnDetail: {},
      responseFile: null,
    },
  });
};

const HandleUploadFileAIPN: Handler<Params<"UPLOAD_FILE">> = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.UPLOAD_FILE]: "LOADING" },
  });

  const [result, error] = await PutImportResponseFile(controller, { ...params, type: "AIPN" });

  if (error) {
    SetErrorMessage(controller, { ...params, btnError: "", error });

    return;
  }

  controller.setState({
    SendClaimResponseFileSequence: {
      ...state.SendClaimResponseFileSequence,
      aipnDetail: result,
      responseFile: params.accepted[0],
    },
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.UPLOAD_FILE]: null },
  });
};

const HandleSaveAIPN: Handler<Params<"SAVE">> = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SAVE]: "LOADING" },
  });

  const seq = state.SendClaimResponseFileSequence || {};

  const [, error] = await ImportResponseFileIPD.put({
    apiToken: controller.apiToken,
    data: {
      file: seq.responseFile,
      save: true,
    },
  });

  if (error) {
    SetErrorMessage(controller, { ...params, error });

    return;
  }

  controller.setState({
    SendClaimResponseFileSequence: {
      ...state.SendClaimResponseFileSequence,
      aipnDetail: {},
      responseFile: null,
    },
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SAVE]: "SUCCESS" },
  });
};

/* ------------------------------------------------------ */

/*                          APIs                          */

/* ------------------------------------------------------ */
const PutImportResponseFile: Handler<{
  accepted: File[];
  rejected: File[];
  type: "AIPN" | "EClaim";
}> = async (controller, params) => {
  const state = controller.getState();

  const acceptedFile = params.accepted[0];
  const rejectedFile = params.rejected[0];

  if (rejectedFile instanceof File) {
    return [null, `ประเภทไฟล์ไม่ถูกต้อง: ${rejectedFile.name}`];
  }

  if (!(acceptedFile instanceof File)) {
    return [null, "file is not an instance of File"];
  }

  const uploadConfig = {
    AIPN: { api: ImportResponseFileIPD.put, data: { file: acceptedFile } },
    EClaim: {
      api: EclaimUploadResponse.post,
      data: {
        coverage_id:
          state.SendClaimResponseFileSequence?.eclaimDetail?.coverage_payer_sent_claim_group,
        file: acceptedFile,
        save: false,
      },
    },
  }[params.type];

  return uploadConfig.api({
    apiToken: controller.apiToken,
    data: uploadConfig.data,
  });
};
