export default class CardLabResultController {
  
  constructor(labManager) {
    this.labManager = labManager 
  }

  getCenterLabDivision = async () => {
    let [data, error, network] = await this.labManager.getCenterLabDivision()
    return [data, error, network]
  }

  getCentalLabTestResultFitlerList = async (params) => {
    let [data, error, network] = await this.labManager.getCentalLabTestResultFitlerList(params)
    return [data, error, network]
  }

  getCentalLabTestResultComparable = async (params) => {
    let [data, error, network] = await this.labManager.getCentalLabTestResultComparable(params)
    return [data, error, network]
  }

  getInterfaceSummaryReportDetail = async (params) => {
    let [data, error, network] = await this.labManager.getInterfaceSummaryReportDetail(params)
    return [data, error, network]
  }
}