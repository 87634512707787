import React, { useState, useEffect, useRef } from "react";

// UX
import CardFilterOnlineQueueUX from "./CardFilterOnlineQueueUX";

// Interface
import {
  CALL_TYPE,
  FilterOnlineQueueType,
  ONLINE_QUEUE_FILTER,
} from "./sequence/IntraTelepharQueue";
import { CheckboxProps } from "semantic-ui-react";
import { useIntl } from "react-intl";

// Types
type CardFilterOnlineQueueProps = {
  // data
  filterOnlineQueue: Partial<FilterOnlineQueueType>;
  // callback
  onClose: () => any;
  onConfirm: (filter: Partial<FilterOnlineQueueType>) => any;
};

const CardFilterOnlineQueue = (props: CardFilterOnlineQueueProps) => {
  const intl = useIntl();
  const [filter, setFilter] = useState<Partial<FilterOnlineQueueType>>({});

  const cardRef = useRef<any>();

  // Callback effect
  const handleDocumentClick = (e: MouseEvent) => {
    if (!cardRef.current.contains(e.target as Node)) {
      props.onClose();
    }
  };

  // Effect
  useEffect(() => {
    setFilter(props.filterOnlineQueue);
  }, [props.filterOnlineQueue]);

  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener("click", handleDocumentClick);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []); // Only re-run the effect if modalOpen changes

  // Handler
  const handleChangeValue = (e: any, data: CheckboxProps) => {
    const name = data.name || "";
    const operations = {
      true: (values: any[]) => [...values, name],
      false: (values: any[]) => values.filter((value) => value !== name),
    } as any;

    const key = Object.keys(CALL_TYPE).includes(name) ? "type" : "status";

    setFilter({
      ...filter,
      [key]: operations[data.checked as any](filter[key]),
    });
  };

  const handleClearType = () => {
    setFilter({ ...filter, type: [] });
  };

  const handleClearStatus = () => {
    setFilter({ ...filter, status: [] });
  };

  const handleResetDefault = () => {
    setFilter(ONLINE_QUEUE_FILTER);
  };

  const handleConfirm = () => {
    props.onConfirm(filter);
  };

  return (
    <div ref={cardRef}>
      <CardFilterOnlineQueueUX
        // data
        filter={filter}
        // callback
        onChangeValue={handleChangeValue}
        onClearType={handleClearType}
        onClearStatus={handleClearStatus}
        onResetDefault={handleResetDefault}
        onConfirm={handleConfirm}
        onClose={props.onClose}
        languageUX={props.languageUX}
      />
    </div>
  );
};

export default React.memo(CardFilterOnlineQueue);
