import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button
} from 'semantic-ui-react'

const CardTaxDocumentUX = (props: any) => {
    return(
      <div
        style={{ zoom: "90%" }}>
        <div
          style={{fontWeight: "bold", fontSize: "1.2rem",padding: "10px 10px 0px 10px", display: "flex", justifyContent: "space-between"}}>
          
          <div>
            
          </div>
        </div>
        <div
          style={{ padding: "0px 10px 10px 10px"}}>
          
        </div>
        <div
          style={{ margin: "10px", width: "100%" }}>
          
          <div
            style={{ display : "flex", justifyContent:  "space-around" }}>
            
            <div
              style={{ width: "20%" }}>
              
              <Button
                fluid={true}
                onClick={(e) => props.reportShow("ภ.ง.ด. 3")}>
                ภ.ง.ด. 3
              </Button>
            </div>
            <div
              style={{ width: "20%" }}>
              
              <Button
                fluid={true}
                onClick={(e) => props.reportShow("ภ.ง.ด 3ก")}>
                ภ.ง.ด 3ก
              </Button>
            </div>
            <div
              style={{ width: "20%" }}>
              
              <Button
                fluid={true}
                onClick={(e) => props.reportShow("หนังสือรับรองการหักภาษี ณ ที่จ่าย")}>
                หนังสือรับรองการหักภาษี ณ ที่จ่าย
              </Button>
            </div>
            <div
              style={{ width: "20%" }}>
              
              <Button
                fluid={true}
                onClick={(e) => props.reportShow("ภ.ง.ด. 1")}>
                ภ.ง.ด. 1
              </Button>
            </div>
          </div>
          <div
            style={{ display : "flex", justifyContent:  "space-around", margin: "20px 0px" }}>
            
            <div
              style={{ width: "20%" }}>
              
              <Button
                fluid={true}
                onClick={(e) => props.reportShow("ใบแนบ ภ.ง.ด. 3")}>
                ใบแนบ ภ.ง.ด. 3
              </Button>
            </div>
            <div
              style={{ width: "20%" }}>
              
              <Button
                fluid={true}
                onClick={(e) => props.reportShow("ใบแนบ ภ.ง.ด. 3 ก")}>
                ใบแนบ ภ.ง.ด. 3 ก
              </Button>
            </div>
            <div
              style={{ width: "20%" }}>
              
              <Button
                fluid={true}
                onClick={(e) => props.reportShow("ใบแนบหนังสือรับรองการหักภาษี ณ ที่จ่าย")}>
                ใบแนบหนังสือรับรองการหักภาษี ณ ที่จ่าย
              </Button>
            </div>
            <div
              style={{ width: "20%" }}>
              
              <Button
                fluid={true}
                onClick={(e) => props.reportShow("ใบแนบ ภ.ง.ด. 1")}>
                ใบแนบ ภ.ง.ด. 1
              </Button>
            </div>
          </div>
        </div>
        <div
          style={{width: "100%", height: "200px"}}>
          
        </div>
      </div>
    )
}


export default CardTaxDocumentUX

export const screenPropsDefault = {"debug":true,"showSupplyList":false}

/* Date Time : Wed May 08 2024 10:35:49 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ zoom: \"90%\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.2rem\",padding: \"10px 10px 0px 10px\", display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"200px\"}"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "div",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"0px 10px 10px 10px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"10px\", width: \"100%\" }"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "div",
      "parent": 94,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display : \"flex\", justifyContent:  \"space-around\" }"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": null,
      "id": 96,
      "name": "div",
      "parent": 94,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display : \"flex\", justifyContent:  \"space-around\", margin: \"20px 0px\" }"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": null,
      "id": 98,
      "name": "div",
      "parent": 95,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\" }"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 99,
      "name": "div",
      "parent": 95,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\" }"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": null,
      "id": 100,
      "name": "div",
      "parent": 95,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\" }"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": null,
      "id": 101,
      "name": "div",
      "parent": 95,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\" }"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": null,
      "id": 102,
      "name": "div",
      "parent": 96,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\" }"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": null,
      "id": 103,
      "name": "div",
      "parent": 96,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\" }"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": null,
      "id": 104,
      "name": "div",
      "parent": 96,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\" }"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": null,
      "id": 105,
      "name": "div",
      "parent": 96,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"20%\" }"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 110,
      "name": "Button",
      "parent": 98,
      "props": {
        "children": {
          "type": "value",
          "value": "ภ.ง.ด. 3"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "color": {
          "type": "code",
          "value": ""
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "(e) => props.reportShow(\"ภ.ง.ด. 3\")"
        },
        "style": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 111,
      "name": "Button",
      "parent": 99,
      "props": {
        "children": {
          "type": "value",
          "value": "ภ.ง.ด 3ก"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "(e) => props.reportShow(\"ภ.ง.ด 3ก\")"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 112,
      "name": "Button",
      "parent": 100,
      "props": {
        "children": {
          "type": "value",
          "value": "หนังสือรับรองการหักภาษี ณ ที่จ่าย"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "(e) => props.reportShow(\"หนังสือรับรองการหักภาษี ณ ที่จ่าย\")"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 113,
      "name": "Button",
      "parent": 101,
      "props": {
        "children": {
          "type": "value",
          "value": "ภ.ง.ด. 1"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "(e) => props.reportShow(\"ภ.ง.ด. 1\")"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 114,
      "name": "Button",
      "parent": 102,
      "props": {
        "children": {
          "type": "value",
          "value": "ใบแนบ ภ.ง.ด. 3"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "(e) => props.reportShow(\"ใบแนบ ภ.ง.ด. 3\")"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 115,
      "name": "Button",
      "parent": 103,
      "props": {
        "children": {
          "type": "value",
          "value": "ใบแนบ ภ.ง.ด. 3 ก"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "(e) => props.reportShow(\"ใบแนบ ภ.ง.ด. 3 ก\")"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 116,
      "name": "Button",
      "parent": 104,
      "props": {
        "children": {
          "type": "value",
          "value": "ใบแนบหนังสือรับรองการหักภาษี ณ ที่จ่าย"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "(e) => props.reportShow(\"ใบแนบหนังสือรับรองการหักภาษี ณ ที่จ่าย\")"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 117,
      "name": "Button",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": "ใบแนบ ภ.ง.ด. 1"
        },
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onClick": {
          "type": "code",
          "value": "(e) => props.reportShow(\"ใบแนบ ภ.ง.ด. 1\")"
        }
      },
      "seq": 117,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardTaxDocumentUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "debug": true,
    "showSupplyList": false
  },
  "width": 80
}

*********************************************************************************** */
