import WasmController from "react-lib/frameworks/WasmController";

// APIs
import ImagingOrderResultList from "issara-sdk/apis/ImagingOrderResultList_apps_IME";

// Interface
import * as ImagingOrderI from "./sequence/ImagingOrder";
import * as ImagingWorkListI from "./sequence/ImagingWorkList";
import * as ImagingResultI from "./sequence/ImagingResult";

export type State = {
  imagingExamResultLoading?: boolean;
  imagingExameResult?: any[];
} & ImagingOrderI.State &
  ImagingWorkListI.State &
  ImagingResultI.State;

export const StateInitial: State = {
  imagingExamResultLoading: false,
  imagingExameResult: [],
  // ...ImagingOrderI.StateInitial,
  // ...ImagingWorkListI.StateInitial,
  // ...ImagingResultI.StateInitial,
};

export type Event =
  | { message: "ListImagingResult"; params: {} }
  | { message: "OpenPACS"; params: { hn: string } }
  | ImagingOrderI.Event
  | ImagingWorkListI.Event
  | ImagingResultI.Event;

export type Data = {
  division?: number;
} & ImagingOrderI.Data;

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const ListImagingResult: Handler = async (controller, params) => {
  controller.setState({ imagingExamResultLoading: true });

  const [resp, error, netw] = await ImagingOrderResultList.list({
    apiToken: controller.apiToken,
    params: params,
  });
  controller.setState({
    imagingExameResult: error ? [] : resp.items,
    imagingExamResultLoading: false,
  });
};

export const OpenPACS: Handler = async (controller, params) => {
  let url =
    "http://pacscnmi4/explore.asp?path=/All%20Patients/InternalPatientUID=" +
    params.hn;
  window.open(url, "_blank");
};
