import React from "react";
import { IntlProvider, FormattedMessage, useIntl } from "react-intl";

import { Table } from "react-lib/frameworks/Table";

const CardZoneLogUX = (props: any) => {
  const isMounted = React.useRef(true);
  const intl = useIntl();

  React.useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <div style={{ height: "400px" }}>
      <Table
        data={props.ZoneLogSequence?.zoneLogList?.items || []}
        headers="Zone, เวลาเข้า, รับเข้าโดย, เวลาออก, ย้ายออกโดย"
        keys="zone_name, check_in_time, check_in_user_fullname, check_out_time, check_out_user_fullname"
        minRows={10}
      ></Table>
    </div>
  );
};

export default CardZoneLogUX;

export const screenPropsDefault = {};

/* Date Time : Tue Sep 06 2022 15:22:26 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{height: \"400px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 1,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.ZoneLogSequence?.zoneLogList?.items || []"
        },
        "defaultPageSize": {
          "type": "value",
          "value": ""
        },
        "headers": {
          "type": "value",
          "value": "Zone, เวลาเข้า, รับเข้าโดย, เวลาออก, ย้ายออกโดย"
        },
        "keys": {
          "type": "value",
          "value": "zone_name, check_in_time, check_in_user_fullname, check_out_time, check_out_user_fullname"
        },
        "minRows": {
          "type": "code",
          "value": "10"
        }
      },
      "seq": 1,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "CardZoneLogUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
