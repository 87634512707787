// NPM: MAIN
import React, { useMemo, useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  Icon,
  Label,
  Radio,
  Modal,
  Segment,
  Header,
  Divider,
  Dimmer,
  Loader,
} from "semantic-ui-react";

// CARD
import CardLayout from "react-lib/apps/common/CardLayout";
import ModInfo from "react-lib/apps/common/ModInfo";
import PureReactTable from "react-lib/apps/common/PureReactTable";
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import { useIntl } from "react-intl";


type CardMedicationReconcileStateType = {
  closeable: boolean;
  toggleable: boolean;
  isLoading: boolean;
  isLoadingModal: boolean;
  medReconcileList: any[];
  medReconcileDetail: any;
  medRecordLog: any[];
  getMedReconciliation: () => any;
  getMedReconciliationDetail: (id: number) => any;
  getMedReconciliationLog: (params: { med_reconcile: number }) => any;
};

const CardMedicationReconcileInitial: CardMedicationReconcileStateType = {
  closeable: false,
  toggleable: false,
  isLoading: false,
  isLoadingModal: true,
  medReconcileList: [],
  medReconcileDetail: {},
  medRecordLog: [],
  getMedReconciliation: () => null,
  getMedReconciliationDetail: () => null,
  getMedReconciliationLog: () => null,
};

const radioButtonStyle = {
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  marginLeft: "8px",
};

const STATUS_RECONCILE = {
  OFF: "OFF",
  HOLD: "HOLD",
  CONTINUE: "CONTINUE",
};

const STATUS_ONE_DAY = {
  ON: "ON",
  OFF: "OFF",
};

const gridWorkHistory = [
  {
    Header: "User",
    accessor: "user_name",
    width: 200,
  },
  {
    Header: "Action",
    accessor: "action_label",
    width: 150,
  },
  {
    Header: "Remark",
    accessor: "remark",
    minWidth: 200,
  },
  {
    Header: "Division",
    accessor: "division_name",
    width: 200,
  },
  {
    Header: "Date-Time",
    accessor: "date_time",
    width: 200,
  },
];

const RECONCILE_TYPE = {
  OPD: "OPD",
  IPD_ADMIT: "IPD_ADMIT",
  TRANSFER: "TRANSFER",
  DISCHARGE: "DISCHARGE"
}

const CardMedicationReconcile: React.FC<CardMedicationReconcileStateType> = (props) => {
  const intl = useIntl();
  const [openModalLog, setOpenModalLog] = useState<boolean>(false);
  const [openModalSelectOrder, setOpenModalSelectOrder] = useState<boolean>(false);
  const [modMessage, setModMassage] = useState<JSX.Element>(<></>);
  const [selectedMedReconcile, setSelectedMedReconcile] = useState(null);

  const onSetModMessage = (err, title?: string, color = "red") => {
    setModMassage(
      <ModInfo
        color={color}
        titleName={title}
        closeOnDimmerClick
        open={true}
        onApprove={() => setModMassage(<></>)}
        onClose={() => setModMassage(<></>)}
      >
        {typeof err === "string" ? (
          <div>{err}</div>
        ) : (
          <ErrorMessage error={err} />
        )}
      </ModInfo>
    );
  };

  const handleGetMedReconciliation = () => {
    props.getMedReconciliation();
  };

  const handleGetMedReconciliationDetail = (id) => {
    props.getMedReconciliationDetail(id);
    selectedMedReconcile && setSelectedMedReconcile(null);
  };

  const handleOnClickLog = () => {
    props.getMedReconciliationLog({ med_reconcile: props.medReconcileDetail?.id });
    setOpenModalLog(true);
  };

  const handleSelectedMedReconcile = (selectedMedReconcile) => {
    if (selectedMedReconcile) {
      setOpenModalSelectOrder(false);
      handleGetMedReconciliationDetail(selectedMedReconcile.id);
    }
  };

  const handleSelectedOrder = () => {
    handleGetMedReconciliation();
    setOpenModalSelectOrder(true);
  };

  const handleGetProps = (_state, row, column) => ({
    onClick: () => setSelectedMedReconcile(row.original),
    onDoubleClick: () => handleSelectedMedReconcile(row.original),
    style: {
      cursor: "pointer",
      display: "flex",
      justifyContent: column.id === "created_datetime" ? "center" : "",
    },
  });

  const gridMedReconcileList = [
    {
      Header: "Encounter",
      accessor: "name_phv",
      minWidth: 350,
      getProps: handleGetProps
    },
    {
      Header: "วันที่และเวลาสร้าง",
      accessor: "created_datetime",
      minWidth: 300,
      getProps: handleGetProps
    },
  ];

  const columnsRadio = useMemo(() => [
    {
      Header: "Off  Hold  Cont",
      accessor: " ",
      width: 150,
      Cell: (row) => {
        return (
          <Form>
            <Form.Group inline>
              {Object.keys(STATUS_RECONCILE).map((key) => (
                <Form.Field>
                  <Radio
                    readOnly
                    style={{
                      transform: "scale(1.3)",
                    }}
                    defaultChecked={row.original.status === key}
                  />
                </Form.Field>
              ))}
            </Form.Group>
          </Form>
        );
      },
      getProps: (_state, row) => ({
        style: {
          ...radioButtonStyle,
          backgroundColor: "",
        },
      }),
    },
  ], []
  );

  const gridColumns = useMemo(
    () => [
      {
        Header: "รหัสยา",
        accessor: " ",
        width: 120,
        Cell: ({ original }) => {
          return (
            <span
              style={{
                padding: '5px',
                backgroundColor: original.is_external ? '#F2711C' : (original.is_suggestion ? '#2185D0' : 'inherit'),
                color: (original.is_external || original.is_suggestion) ? 'white' : 'inherit',
              }}
            >
              {original.code}
            </span>
          );
        },
      },
      {
        Header: "ชื่อยาและความแรง",
        accessor: " ",
        minWidth: 180,
        Cell: ({ original }) => <div>{original.name}</div>,
      },
      {
        Header: "วิธีใช้ยา",
        accessor: " ",
        minWidth: 150,
        Cell: ({ original }) => <div style={{ display: "flex" }}>{original.label}</div>,
      },
      {
        Header: "QTY",
        accessor: "quantity",
        width: 50,
        getProps: (_state, row) => ({
          style: {
            backgroundColor: "",
          },
        }),
      },
      {
        Header: "Start Date",
        accessor: "start_date",
        width: 120,
        getProps: (_state, row) => ({
          style: {
            backgroundColor: "",
          },
        }),
      },
      {
        Header: "Time",
        accessor: "start_time",
        width: 90,
        getProps: (_state, row) => ({
          style: {
            backgroundColor: "",
          },
        }),
      },
      {
        Header: "Last Date",
        accessor: "last_dose_date",
        width: 120,
        getProps: (_state, row) => ({
          style: {
            backgroundColor: "",
          },
        }),
      },
      {
        Header: "Time",
        accessor: "last_dose_time",
        width: 90,
        getProps: (_state, row) => ({
          style: {
            backgroundColor: "",
          },
        }),
      },
      {
        Header: "Note",
        accessor: "",
        width: 100,
        Cell: (row) => {
          return (
            <Button
              icon
              color="orange"
              style={{
                borderRadius: "8px",
                height: "35px"
              }}
              onClick={() =>
                onSetModMessage(row.original.note, "Note", "orange")
              }
            >
              <Icon name="comment alternate" />
            </Button>
          );
        },
        getProps: (_state, row) => ({
          style: {
            backgroundColor: "",
            display: "flex",
            justifyContent: "center",
          },
        }),
      },
    ], [columnsRadio]
  );

  // const gridDefault = useMemo(() => [...gridColumns, ...columnsRadio], [
  //   gridColumns,
  //   columnsRadio,
  // ]);

  const gridHome = useMemo(
    () => [
      ...gridColumns
    ],
    [gridColumns]
  );

  const gridOneDay = useMemo(
    () => [
      ...gridColumns,
      {
        Header: "On  Off",
        accessor: " ",
        width: 100,
        Cell: (row) => {
          return (
            <Form>
              <Form.Group inline>
                {Object.keys(STATUS_ONE_DAY).map((key) => (
                  <Form.Field>
                    <Radio
                      readOnly
                      style={{
                        transform: "scale(1.3)",
                      }}
                      defaultChecked={row.original.status === key}
                    />
                  </Form.Field>
                ))}
              </Form.Group>
            </Form>
          );
        },
        getProps: (_state, row) => ({
          style: {
            ...radioButtonStyle,
            backgroundColor: "",
          },
        }),
      },
    ],
    [gridColumns]
  );

  const gridContinue = useMemo(
    () => [
      ...gridColumns,
      ...columnsRadio,
      // {
      //   Header: "",
      //   accessor: " ",
      //   width: 70,
      //   Cell: (row) => {
      //     return (
      //       <div
      //         style={{
      //           display: "flex",
      //           justifyContent: "center",
      //         }}
      //       >
      //         <Button
      //           icon
      //           color="red"
      //           style={{
      //             borderRadius: "8px",
      //           }}
      //         >
      //           <Icon name="trash alternate" />
      //         </Button>
      //       </div>
      //     );
      //   },
      // },
    ],
    [gridColumns, columnsRadio]
  );

  const createTableReconcile = () => {
    const medType = props.medReconcileDetail.med_reconciliation_type || ""
    let gridType
    switch (medType) {
      case "OPD":
        gridType = gridContinue
        break
      case "IPD_ADMIT":
        gridType = gridContinue
        break
      case "DISCHARGE":
        gridType = gridHome
        break
      default:
        gridType = gridContinue
    }
    return <>
      {medType === "DISCHARGE" && <Header as="h2">Home Medication</Header>}
      {
        ["IPD_ADMIT", "OPD", "DISCHARGE", ""].includes(medType) &&
        <PureReactTable
          className="--rt-tbody-overflow-y-hidden"
          data={props.medReconcileDetail.items}
          columns={gridType}
          showPagination={false}
          style={{
            height: "300px",
          }}
        />
      }
      {
        medType === "TRANSFER" &&
        <>
          <Header as="h2">Current Medication (One Day)</Header>
          <PureReactTable
            data={props.medReconcileDetail.items_current_one_day}
            columns={gridOneDay}
            showPagination={false}
            style={{
              height: "300px",
            }}
          />

          <Header as="h2">Current Medication (Continue)</Header>
          <PureReactTable
            data={props.medReconcileDetail.items_current_continue}
            columns={gridContinue}
            showPagination={false}
            style={{
              height: "300px",
            }}
          />
        </>
      }
    </>
  }

  return (
    <CardLayout
      titleText="Medications"
      closeable={props.closeable}
      toggleable={props.toggleable}
      hideHeaderIcon={true}
    >
      <Dimmer active={props.isLoading} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      <Form>
        <Form.Group inline>
          <Form.Field>
            <Button >Medication Records</Button>
            <Button  primary >
              Reconciliations
            </Button>
          </Form.Field>
          <Form.Field style={{ flex: 1 }}></Form.Field>
          <Form.Field >
            <Button
              content={intl.formatMessage({ id: "เลือกใบ  Medication Reconciliation" })}
              icon="search"
              labelPosition="right"
              onClick={handleSelectedOrder}
            />
          </Form.Field>
          <Form.Field width={3}>
            <Button>{RECONCILE_TYPE[props.medReconcileDetail.med_reconciliation_type] || "IPD_ADMIT"}</Button>
            <Button icon color="teal">
              <Icon name="print" />
            </Button>
          </Form.Field>
        </Form.Group>
      </Form>

      {createTableReconcile()}

      <br />
      <Form>
        <Form.Group inline>
          <Form.Field width={2}>
            <label>{intl.formatMessage({ id: "แพทย์" })}</label>
          </Form.Field>
          <Form.Field width={5}>
            <div>{props.medReconcileDetail.doctor_name}</div>
          </Form.Field>
          <Form.Field>
            <label>{intl.formatMessage({ id: "หมายเหตุ" })}</label>
          </Form.Field>
          <Form.Field>
            <div>{`${props.medReconcileDetail.doctor_remark || "-"}`}</div>
          </Form.Field>
        </Form.Group>
        <br />
        <Form.Group inline>
          <Form.Field width={2}>
            <label>{intl.formatMessage({ id: "เภสัชกร" })}</label>
          </Form.Field>
          <Form.Field width={5}>
            <div>{props.medReconcileDetail.pharmacist_name}</div>
          </Form.Field>
          <Form.Field>
            <label>{intl.formatMessage({ id: "หมายเหตุ" })}</label>
          </Form.Field>
          <Form.Field>
            <div>{`${props.medReconcileDetail.pharmacist_remark || "-"}`}</div>
          </Form.Field>
        </Form.Group>
        <br />
        <Form.Group inline>
          <Form.Field width={2}>
            <label>{intl.formatMessage({ id: "พยาบาล DPO" })}</label>
          </Form.Field>
          <Form.Field width={5}>
            <div>{props.medReconcileDetail.nurse_name}</div>
          </Form.Field>
          <Form.Field>
            <label>{intl.formatMessage({ id: "หมายเหตุ" })}</label>
          </Form.Field>
          <Form.Field>
            <div>{`${props.medReconcileDetail.nurse_remark || "-"}`}</div>
          </Form.Field>
        </Form.Group>
        <br />
        <Form.Group inline>
          <Form.Field width={2}>
            <label>{intl.formatMessage({ id: "พยาบาล IPD" })}</label>
          </Form.Field>
          <Form.Field width={5}>
            <div>{props.medReconcileDetail.nurse_ipd_name}</div>
          </Form.Field>
          <Form.Field>
            <label>{intl.formatMessage({ id: "หมายเหตุ" })}</label>
          </Form.Field>
          <Form.Field>
            <div>{`${props.medReconcileDetail.nurse_ipd_remark || "-"}`}</div>
          </Form.Field>
        </Form.Group>
        <br />
        <Form.Group inline>
          <Form.Field width={2}>
            <label>{intl.formatMessage({ id: "ผู้แก้ไขล่าสุด" })}</label>
          </Form.Field>
          <Form.Field>
            <div>{props.medReconcileDetail.latest_edited_user_name}</div>
          </Form.Field>
          <Form.Field>
            <div>{props.medReconcileDetail.latest_edited_datetime}</div>
          </Form.Field>
          {props.medReconcileDetail.latest_edited_user_name && (
            <Form.Field>
              <Button color="grey" onClick={handleOnClickLog}>
                LOG
              </Button>
            </Form.Field>
          )}
        </Form.Group>
      </Form>

      <Modal
        open={openModalLog}
        size="large"
        onClose={() => setOpenModalLog(false)}
      >
        <Segment>
          <Header as="h3">{intl.formatMessage({ id: "ประวัติการทำงาน" })}</Header>
          <Divider />
          <Dimmer active={props.isLoadingModal} inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
          <PureReactTable
            data={props.medRecordLog}
            columns={gridWorkHistory}
            showPagination={false}
            style={{
              height: "500px",
            }}
          />
        </Segment>
      </Modal>

      <Modal
        open={openModalSelectOrder}
        size="large"
        onClose={() => {
          setOpenModalSelectOrder(false);
          setSelectedMedReconcile(null);
        }}
      >
        <Segment>
          <Dimmer active={props.isLoadingModal} inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
          <Form>
            <Form.Group>
              <Form.Field width={16}>
                <PureReactTable
                  data={props.medReconcileList}
                  columns={gridMedReconcileList}
                  showPagination={false}
                  style={{
                    height: "200px",
                  }}
                  selectedRow={selectedMedReconcile}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field width={16}>
                <Button
                  color="blue"
                  floated="right"
                  onClick={() =>
                    handleSelectedMedReconcile(selectedMedReconcile)
                  }
                >
                  เลือก
                </Button>
              </Form.Field>
            </Form.Group>
          </Form>
        </Segment>
      </Modal>
      {modMessage}
    </CardLayout>
  );
};

CardMedicationReconcile.defaultProps = CardMedicationReconcileInitial;

export default CardMedicationReconcile;
