import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Label,
  Checkbox
} from 'semantic-ui-react'

const CardMedicationUX = (props: any) => {
    return(
      <div
        id="CardMedication"
        style={{ margin: "15px",  padding: "20px",  backgroundColor: "rgba(198, 235, 243, 0.2)" }}>
        <div
          style={{ paddingBottom: "5px" }}>
          
          <label
            style={{ fontWeight: "bold", fontSize: "18px" }}>
            Medication
          </label>
          <Label
            color={props.statusColor}
            style={{display: props.config?.hideStatus ? "none" : "", float: "right" }}>
            {props.status}
          </Label>
          <hr>
          </hr>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <div
            style={{ fontWeight: "bold",margin: "5px 10px 0px 0px", minWidth: "235px", maxWidth: "235px"}}>
            
            <label>
              Medication
            </label>
            <Checkbox
              checked={props.isOrMedication || false}
              label={props.isOrMedication ? "Yes" : "No"}
              name="is_or_medication"
              onChange={props.onChangeData}
              style={{ float: "right", marginRight: "20px"}}
              toggle={true}>
            </Checkbox>
          </div>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <div
            style={{ fontWeight: "bold",  minWidth: "120px", maxWidth: "120px",  margin: "5px 10px 0px 0px", }}>
            
          </div>
          <div
            style={{width: "100%"}}>
            
            <div>
              {props.medication}
            </div>
          </div>
        </div>
        <div
          style={{ display: "flex", paddingBottom: "5px" }}>
          
          <div
            style={{ fontWeight: "bold",  margin: "5px 10px 0px 0px", minWidth: "120px", maxWidth: "120px"}}>
            
            <label>
              Other
            </label>
          </div>
          <div
            style={{width: "90%"}}>
            
            <div>
              {props.medicationOther}
            </div>
          </div>
        </div>
        <div
          style={{ height: "55px", paddingTop: "15px" }}>
          
          <div
            style={{ float: "right", display: "flex" }}>
            
            <div
              style={{margin: "0 0.25rem"}}>
              {props.ButtonSave}
            </div>
            <div
              style={{margin: "0 0.25rem"}}>
              {props.ButtonConfirm}
            </div>
            <div
              style={{margin: "0 0.25rem"}}>
              {props.ButtonUnConfirm}
            </div>
          </div>
        </div>
      </div>
    )
}


export default CardMedicationUX

export const screenPropsDefault = {}

/* Date Time : Wed Dec 27 2023 16:33:30 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardMedication"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"15px\",  padding: \"20px\",  backgroundColor: \"rgba(198, 235, 243, 0.2)\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ paddingBottom: \"5px\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "label",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "Medication"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"18px\" }"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "Label",
      "parent": 1,
      "props": {
        "children": {
          "type": "code",
          "value": "props.status"
        },
        "color": {
          "type": "code",
          "value": "props.statusColor"
        },
        "style": {
          "type": "code",
          "value": "{display: props.config?.hideStatus ? \"none\" : \"\", float: \"right\" }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "hr",
      "parent": 1,
      "props": {
      },
      "seq": 4,
      "void": true
    },
    {
      "from": null,
      "id": 21,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 21,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\",margin: \"5px 10px 0px 0px\", minWidth: \"235px\", maxWidth: \"235px\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "label",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": "Medication"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "Checkbox",
      "parent": 22,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.isOrMedication || false"
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "label": {
          "type": "code",
          "value": "props.isOrMedication ? \"Yes\" : \"No\""
        },
        "name": {
          "type": "value",
          "value": "is_or_medication"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ float: \"right\", marginRight: \"20px\"}"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 154,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ height: \"55px\", paddingTop: \"15px\" }"
        }
      },
      "seq": 183,
      "void": false
    },
    {
      "from": null,
      "id": 155,
      "name": "div",
      "parent": 154,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ float: \"right\", display: \"flex\" }"
        }
      },
      "seq": 155,
      "void": false
    },
    {
      "from": null,
      "id": 159,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 161,
      "void": false
    },
    {
      "from": null,
      "id": 163,
      "name": "div",
      "parent": 159,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\",  margin: \"5px 10px 0px 0px\", minWidth: \"120px\", maxWidth: \"120px\"}"
        }
      },
      "seq": 163,
      "void": false
    },
    {
      "from": null,
      "id": 166,
      "name": "label",
      "parent": 163,
      "props": {
        "children": {
          "type": "value",
          "value": "Other"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 166,
      "void": false
    },
    {
      "from": null,
      "id": 181,
      "name": "div",
      "parent": 159,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"90%\"}"
        }
      },
      "seq": 181,
      "void": false
    },
    {
      "from": null,
      "id": 182,
      "name": "div",
      "parent": 181,
      "props": {
        "children": {
          "type": "code",
          "value": "props.medicationOther"
        }
      },
      "seq": 182,
      "void": false
    },
    {
      "from": null,
      "id": 183,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", paddingBottom: \"5px\" }"
        }
      },
      "seq": 154,
      "void": false
    },
    {
      "from": null,
      "id": 184,
      "name": "div",
      "parent": 183,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\",  minWidth: \"120px\", maxWidth: \"120px\",  margin: \"5px 10px 0px 0px\", }"
        }
      },
      "seq": 184,
      "void": false
    },
    {
      "from": null,
      "id": 185,
      "name": "div",
      "parent": 183,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 185,
      "void": false
    },
    {
      "from": null,
      "id": 186,
      "name": "div",
      "parent": 185,
      "props": {
        "children": {
          "type": "code",
          "value": "props.medication"
        }
      },
      "seq": 186,
      "void": false
    },
    {
      "from": null,
      "id": 187,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSave"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 0.25rem\"}"
        }
      },
      "seq": 187,
      "void": false
    },
    {
      "from": null,
      "id": 188,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonConfirm"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 0.25rem\"}"
        }
      },
      "seq": 188,
      "void": false
    },
    {
      "from": null,
      "id": 189,
      "name": "div",
      "parent": 155,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonUnConfirm"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 0.25rem\"}"
        }
      },
      "seq": 189,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 85,
  "isMounted": false,
  "memo": false,
  "name": "CardMedicationUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
