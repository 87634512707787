import FormSymbolImages from "./AddSymbolPrintList";

export default function toPDFMakeData(props: any) {
  const injectImages = (haveHeader: boolean) => {
    let symbolUsage = {};
    if (!haveHeader) {
      symbolUsage = {
        checked: FormSymbolImages.checked,
        unchecked: FormSymbolImages.unchecked,
        squarebox: FormSymbolImages.squarebox,
        checkmark: FormSymbolImages.checkmark,
      };
    }
    return symbolUsage;
  };

  return {
    pageOrientation: `portrait`,
    defaultStyle: {
      font: `THSarabunNew`,
    },
    content: [
      {
        alignment: `center`,
        decoration: ``,
        bold: `true`,
        color: ``,
        fontSize: `18`,
        width: `auto`,
        pageBreak: ``,
        text: `หนังสือแสดงความยินยอมการตรวจวินิจฉัยเพื่อหาเชื้อเอชไอวี`,
        decorationStyle: ``,
        preserveLeadingSpaces: true,
        decorationColor: ``,
      },
      {
        text: ` `,
        bold: false,
        decorationStyle: ``,
        fontSize: 15,
        pageBreak: ``,
        alignment: `left`,
        decorationColor: ``,
        width: `auto`,
        color: ``,
        preserveLeadingSpaces: true,
        decoration: ``,
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            alignment: `left`,
            width: 30,
            decorationColor: ``,
            fontSize: 15,
            bold: false,
            pageBreak: ``,
            decoration: ``,
            text: ``,
            decorationStyle: ``,
            color: ``,
          },
          {
            fontSize: 15,
            decorationStyle: ``,
            color: ``,
            decorationColor: ``,
            alignment: `left`,
            text: `ข้าพเจ้า (นาย/นาง/นางสาว) `,
            pageBreak: ``,
            width: `auto`,
            decoration: ``,
            bold: false,
            preserveLeadingSpaces: true,
          },
          {
            text: ` `,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            width: 5,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
          },
          {
            stack: [
              {
                text: props.items?.formatPatient,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.............................................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            alignment: `left`,
            text: ` ขอแสดงความยินยอมโดยสมัครใจให้`,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            fontSize: 15,
            bold: false,
            color: ``,
            decorationColor: ``,
            pageBreak: ``,
            decoration: ``,
            width: `auto`,
          },
        ],
      },
      {
        columns: [
          {
            text: `นายแพทย์/แพทย์หญิง`,
            color: ``,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            alignment: `left`,
            decoration: ``,
            width: `auto`,
            decorationStyle: ``,
          },
          {
            decorationStyle: ``,
            alignment: `left`,
            bold: false,
            fontSize: 15,
            decorationColor: ``,
            color: ``,
            text: ` `,
            width: 5,
            preserveLeadingSpaces: true,
            decoration: ``,
            pageBreak: ``,
          },
          {
            stack: [
              {
                text: props.items?.formatDoctor,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `.........................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            fontSize: 15,
            width: `auto`,
            alignment: `left`,
            text: `และพนักงานของโรงพยาบาลเซเปี้ยนซ์ ดำเนินการเจาะเลือดตรวจวินิจฉัย`,
            color: ``,
            decorationStyle: ``,
            decorationColor: ``,
            pageBreak: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            bold: false,
          },
        ],
      },
      {
        width: `auto`,
        decoration: ``,
        pageBreak: ``,
        decorationStyle: ``,
        bold: false,
        fontSize: 15,
        color: ``,
        preserveLeadingSpaces: true,
        alignment: `left`,
        decorationColor: ``,
        text: `เพื่อหาสภาวะการติดเชื้อ (Anti HIV/HIV Ag/HIV RNA PCR)`,
      },
      {
        columns: [
          {
            pageBreak: ``,
            bold: false,
            text: ``,
            color: ``,
            decorationStyle: ``,
            alignment: `left`,
            fontSize: 15,
            width: 30,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
          },
          {
            alignment: `left`,
            pageBreak: ``,
            bold: false,
            preserveLeadingSpaces: true,
            text: `ซึ่งข้าพเจ้าได้รับทราบรายละเอียดจาก นายแพทย์/แพทย์หญิง ตามที่กล่าวข้างต้น ถึงความจำเป็นและความสำคัญในการ`,
            color: ``,
            width: `auto`,
            fontSize: 15,
            decorationStyle: ``,
            decorationColor: ``,
            decoration: ``,
          },
        ],
      },
      {
        alignment: `left`,
        width: `auto`,
        decorationStyle: ``,
        color: ``,
        fontSize: 15,
        preserveLeadingSpaces: true,
        pageBreak: ``,
        decorationColor: ``,
        bold: false,
        decoration: ``,
        text: `เจาะเลือดตรวจวินิจฉัยครั้งนี้ เพื่อประโยชน์ในการรักษา มิได้เป็นการละเมิดสิทธิของข้าพเจ้าแต่ประการใด`,
      },
      {
        columns: [
          {
            decoration: ``,
            text: ``,
            fontSize: 15,
            width: 30,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            bold: false,
            color: ``,
            alignment: `left`,
            decorationColor: ``,
            decorationStyle: ``,
          },
          {
            decoration: ``,
            pageBreak: ``,
            alignment: `left`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            color: ``,
            text: `ข้าพเจ้าได้รับเอกสารคำแนะนำ เรื่อง การให้บริการปรึกษาด้านโรคเอดส์`,
            width: `auto`,
            fontSize: 15,
            bold: false,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            color: ``,
            width: 30,
            bold: false,
            alignment: `left`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            text: ``,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
          },
          {
            text: `โดยผลทางการตรวจวินิจฉัยเพื่อหาเชื้อเอชไอวี ครั้งนี้ ข้าพเจ้ามีความยินดีจะมาฟังผลและรับการแนะนำหลังการตรวจ`,
            color: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            width: `auto`,
            fontSize: 15,
            decoration: ``,
            pageBreak: ``,
            bold: false,
            decorationColor: ``,
            decorationStyle: ``,
          },
        ],
      },
      {
        fontSize: 15,
        decorationColor: ``,
        preserveLeadingSpaces: true,
        color: ``,
        width: `auto`,
        text: `ด้วยตนเอง`,
        decoration: ``,
        alignment: `left`,
        decorationStyle: ``,
        bold: false,
        pageBreak: ``,
      },
      {
        width: `auto`,
        preserveLeadingSpaces: true,
        alignment: `left`,
        decorationStyle: ``,
        decorationColor: ``,
        color: ``,
        fontSize: 15,
        bold: false,
        decoration: ``,
        text: `ณ ที่นี่ข้าพเจ้าขอแสดงความยินยอมเพื่อดำเนินการเจาะเลือดตรวจวินิจฉัยเพื่อหาเชื้อเอชไอวี`,
        pageBreak: ``,
      },
      {
        alignment: `left`,
        text: ` `,
        pageBreak: ``,
        bold: false,
        color: ``,
        preserveLeadingSpaces: true,
        width: `auto`,
        fontSize: 15,
        decoration: ``,
        decorationStyle: ``,
        decorationColor: ``,
      },
      {
        columns: [
          {
            decorationColor: ``,
            alignment: `center`,
            decorationStyle: ``,
            width: 200,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            color: ``,
            bold: false,
            decoration: ``,
            text: `ลงนาม........................................................  `,
            fontSize: 15,
          },
          {
            color: ``,
            width: 80,
            fontSize: 15,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decorationColor: ``,
            pageBreak: ``,
            text: `  `,
            bold: false,
            decoration: ``,
          },
          {
            fontSize: 15,
            bold: false,
            decorationStyle: ``,
            text: `พยาน 1 ลงนาม........................................................ `,
            alignment: `center`,
            pageBreak: ``,
            color: ``,
            decorationColor: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            width: 200,
          },
        ],
      },
      {
        columns: [
          {
            stack: [
              {
                width: `auto`,
                text: props.items?.full_name,
                fontSize: 15,
                color: ``,
                pageBreak: ``,
                preserveLeadingSpaces: true,
                decoration: ``,
                bold: false,
                alignment: `center`,
                decorationColor: ``,
                decorationStyle: ``,
                margin: [0, 0, 0, -15],
              },
              {
                width: 200,
                text: `(.............................................................)`,
                fontSize: 15,
                color: ``,
                pageBreak: ``,
                preserveLeadingSpaces: true,
                decoration: ``,
                bold: false,
                alignment: `center`,
                decorationColor: ``,
                decorationStyle: ``,
              },
            ],
            width: 200,
          },
          {
            alignment: `left`,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            text: ``,
            width: 80,
            color: ``,
            bold: false,
            pageBreak: ``,
            fontSize: 15,
            decorationStyle: ``,
          },
          {
            text: `(.............................................................)`,
            alignment: `center`,
            fontSize: 15,
            pageBreak: ``,
            color: ``,
            decoration: ``,
            decorationStyle: ``,
            bold: false,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            width: 200,
          },
        ],
      },
      {
        columns: [
          // issue 68000 อย่าลืมไปแก้ที่ isMor
          {
            fontSize: 15,
            alignment: `center`,
            decorationColor: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            bold: false,
            width: 30,
            color: ``,
            decoration: ``,
            decorationStyle: ``,
            text: ``,
          },
          {
            columns: [
              {
                fontSize: 15,
                alignment: `center`,
                decorationColor: ``,
                pageBreak: ``,
                preserveLeadingSpaces: true,
                bold: false,
                width: `auto`,
                color: ``,
                decoration: ``,
                decorationStyle: ``,
                text: `วันที่`,
              },
              {
                stack: [
                  {
                    fontSize: 15,
                    alignment: `center`,
                    decorationColor: ``,
                    pageBreak: ``,
                    preserveLeadingSpaces: true,
                    bold: false,
                    width: `auto`,
                    color: ``,
                    decoration: ``,
                    decorationStyle: ``,
                    text: props.items?.signedDate,
                    margin: [0, 0, 0, -15],
                  },
                  {
                    fontSize: 15,
                    alignment: `left`,
                    decorationColor: ``,
                    pageBreak: ``,
                    preserveLeadingSpaces: true,
                    bold: false,
                    width: `auto`,
                    color: ``,
                    decoration: ``,
                    decorationStyle: ``,
                    text: `........................`,
                  },
                ],
                width: `auto`,
              },
              {
                fontSize: 15,
                alignment: `center`,
                decorationColor: ``,
                pageBreak: ``,
                preserveLeadingSpaces: true,
                bold: false,
                width: `auto`,
                color: ``,
                decoration: ``,
                decorationStyle: ``,
                text: `เวลา`,
              },
              {
                stack: [
                  {
                    fontSize: 15,
                    alignment: `center`,
                    decorationColor: ``,
                    pageBreak: ``,
                    preserveLeadingSpaces: true,
                    bold: false,
                    width: `auto`,
                    color: ``,
                    decoration: ``,
                    decorationStyle: ``,
                    text: props.items?.signedTime,
                    margin: [0, 0, 0, -15],
                  },
                  {
                    fontSize: 15,
                    alignment: `left`,
                    decorationColor: ``,
                    pageBreak: ``,
                    preserveLeadingSpaces: true,
                    bold: false,
                    width: `auto`,
                    color: ``,
                    decoration: ``,
                    decorationStyle: ``,
                    text: `..................`,
                  },
                ],
                width: `auto`,
              },
            ],
            width: 200,
          },
          {
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            bold: false,
            alignment: `left`,
            color: ``,
            decorationColor: ``,
            decoration: ``,
            width: 50,
            fontSize: 15,
            text: ``,
          },
          {
            decoration: ``,
            alignment: `left`,
            width: 200,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            text: `พยาน 2 ลงนาม........................................................`,
            decorationStyle: ``,
            decorationColor: ``,
            fontSize: 15,
            bold: false,
            color: ``,
          },
        ],
      },
      {
        columns: [
          {
            alignment: `left`,
            decoration: ``,
            text: ``,
            pageBreak: ``,
            decorationColor: ``,
            bold: false,
            width: 280,
            color: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            fontSize: 15,
          },
          {
            color: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            bold: false,
            pageBreak: ``,
            decorationStyle: ``,
            text: `(.............................................................)`,
            width: 200,
            alignment: `center`,
            fontSize: 15,
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            color: ``,
            fontSize: 15,
            decorationColor: ``,
            width: 306,
            text: ``,
            pageBreak: ``,
            decorationStyle: ``,
            bold: false,
          },
          {
            color: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            alignment: `left`,
            pageBreak: ``,
            decorationColor: ``,
            bold: false,
            decorationStyle: ``,
            fontSize: 15,
            width: `auto`,
            text: `(กรณีพิมพ์รายนิ้วมือ/ยินยอมทางโทรศัพท์) `,
          },
        ],
      },
      {
        preserveLeadingSpaces: true,
        color: ``,
        width: `auto`,
        fontSize: 15,
        text: ` `,
        decorationStyle: ``,
        pageBreak: ``,
        alignment: `left`,
        bold: false,
        decoration: ``,
        decorationColor: ``,
      },
      {
        columns: [
          {
            decoration: `underline`,
            text: `การแปลและให้ข้อมูลโดยผู้แปลภาษา`,
            color: ``,
            bold: `true`,
            width: `auto`,
            decorationColor: ``,
            decorationStyle: ``,
            fontSize: 15,
            alignment: `left`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        columns: [
          {
            width: 30,
            decorationColor: ``,
            alignment: `left`,
            pageBreak: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            color: ``,
            decoration: ``,
            bold: false,
            text: ``,
            fontSize: 15,
          },
          {
            bold: false,
            alignment: `left`,
            decorationStyle: ``,
            width: `auto`,
            decorationColor: ``,
            fontSize: 15,
            pageBreak: ``,
            decoration: ``,
            color: ``,
            text: `ข้าพเจ้าได้แปลหนังสือแสดงความยินยอมการตรวจวินิจฉัยเพื่อหาเชื้อเอชไอวี (Informed Consent HIV Blood Test)`,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        decorationColor: ``,
        decoration: ``,
        color: ``,
        text: `รวมทั้งข้อมูลซึ่งแพทย์ได้อธิบายให้แก่ผู้ป่วย/ตัวแทนผู้ป่วยทราบ`,
        fontSize: 15,
        width: `auto`,
        pageBreak: ``,
        alignment: `left`,
        decorationStyle: ``,
        bold: false,
        preserveLeadingSpaces: true,
      },
      {
        preserveLeadingSpaces: true,
        decorationColor: ``,
        pageBreak: ``,
        decoration: ``,
        color: ``,
        decorationStyle: ``,
        bold: false,
        alignment: `left`,
        fontSize: 15,
        text: ` `,
        width: `auto`,
      },
      {
        columns: [
          {
            width: 200,
            text: `ภาษาที่แปล................................................`,
            decorationColor: ``,
            pageBreak: ``,
            color: ``,
            decorationStyle: ``,
            decoration: ``,
            fontSize: 15,
            bold: false,
            preserveLeadingSpaces: true,
            alignment: `center`,
          },
          {
            pageBreak: ``,
            bold: false,
            width: 80,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decoration: ``,
            text: ` `,
            color: ``,
            alignment: `left`,
            decorationStyle: ``,
          },
          {
            fontSize: 15,
            decorationColor: ``,
            color: ``,
            width: 200,
            decoration: ``,
            bold: false,
            pageBreak: ``,
            decorationStyle: ``,
            text: `ผู้แปลลงนาม........................................................`,
            alignment: `center`,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        columns: [
          {
            alignment: `left`,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            bold: false,
            decoration: ``,
            fontSize: 15,
            width: 280,
            decorationStyle: ``,
            color: ``,
            text: ``,
            pageBreak: ``,
          },
          {
            color: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            bold: false,
            text: `(.............................................................)`,
            decoration: ``,
            fontSize: 15,
            width: 200,
            decorationColor: ``,
            decorationStyle: ``,
            alignment: `center`,
          },
        ],
      },
      {
        decorationStyle: ``,
        bold: false,
        alignment: `left`,
        preserveLeadingSpaces: true,
        decoration: ``,
        color: ``,
        width: `auto`,
        text: ` `,
        fontSize: 15,
        pageBreak: ``,
        decorationColor: ``,
      },
      {
        pageBreak: ``,
        color: ``,
        decoration: ``,
        alignment: `left`,
        decorationStyle: ``,
        bold: `true`,
        fontSize: 15,
        text: `สถานะของผู้ลงนาม (ตามประมวลกฎหมายแพ่งและพาณิชย์)`,
        preserveLeadingSpaces: true,
        width: `auto`,
        decorationColor: ``,
      },
      {
        columns: [
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            decoration: ``,
            fontSize: 15,
            bold: false,
            alignment: `left`,
            text: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decorationStyle: ``,
            color: ``,
            width: 5,
            pageBreak: ``,
          },
          {
            bold: false,
            text: `1. ผู้ป่วย ซึ่งบรรลุนิติภาวะ และมีสติสัมปชัญญะสมบูรณ์`,
            alignment: `left`,
            width: `auto`,
            decoration: ``,
            color: ``,
            decorationColor: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            pageBreak: ``,
          },
        ],
      },
      {
        columns: [
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            color: ``,
            text: ``,
            width: 5,
            preserveLeadingSpaces: true,
            bold: false,
            decorationStyle: ``,
            fontSize: 15,
            decorationColor: ``,
            decoration: ``,
            pageBreak: ``,
            alignment: `left`,
          },
          {
            alignment: `left`,
            decorationStyle: ``,
            decoration: ``,
            width: `auto`,
            decorationColor: ``,
            fontSize: 15,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            text: `2. สามีหรือภรรยาตามกฎหมาย กรณีที่ผู้ป่วยไม่มีสติสัมปชัญญะ (ไม่รู้สึกตัว)`,
            color: ``,
            bold: false,
          },
        ],
      },
      {
        columns: [
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            width: 5,
            text: ``,
            pageBreak: ``,
            color: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decoration: ``,
            fontSize: 15,
            decorationColor: ``,
            decorationStyle: ``,
            bold: false,
          },
          {
            decorationStyle: ``,
            text: `3. ผู้ใช้อำนาจปกครองกรณีผู้ป่วยอายุต่ำกว่า 20 ปี บริบูรณ์ที่ยังไม่บรรลุนิติภาวะ`,
            preserveLeadingSpaces: true,
            alignment: `left`,
            bold: false,
            color: ``,
            fontSize: 15,
            decoration: ``,
            width: `auto`,
            pageBreak: ``,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            decorationStyle: ``,
            width: 5,
            decoration: ``,
            decorationColor: ``,
            fontSize: 15,
            text: ``,
            alignment: `left`,
            bold: false,
            color: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
          },
          {
            decorationStyle: ``,
            pageBreak: ``,
            bold: false,
            width: `auto`,
            text: `4. ผู้พิทักษ์ กรณีผู้ป่วยเป็นคนเสมือนไร้ความสามารถ (ตามคำสั่งศาล)`,
            color: ``,
            decorationColor: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decoration: ``,
            alignment: `left`,
          },
        ],
      },
      {
        columns: [
          {
            image: "unchecked",
            width: 10,
            margin: [0, 4, 0, 0],
          },
          {
            preserveLeadingSpaces: true,
            text: ``,
            fontSize: 15,
            decorationStyle: ``,
            decoration: ``,
            decorationColor: ``,
            bold: false,
            pageBreak: ``,
            width: 5,
            alignment: `left`,
            color: ``,
          },
          {
            fontSize: 15,
            decorationStyle: ``,
            width: `auto`,
            decoration: ``,
            color: ``,
            decorationColor: ``,
            bold: false,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            alignment: `left`,
            text: `5. ผู้อนุบาล กรณีผู้ป่วยเป็นคนไร้ความสามารถ (ตามคำสั่งศาล)`,
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            decorationColor: ``,
            width: 15,
            alignment: `left`,
            decorationStyle: ``,
            bold: false,
            pageBreak: ``,
            color: ``,
            text: ``,
            decoration: ``,
            fontSize: 15,
          },
          {
            alignment: `left`,
            pageBreak: ``,
            bold: false,
            color: ``,
            decorationStyle: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            text: `*กรณีข้อ 2-5 ให้ขอสำเนาเอกสารบัตรประชาชน หนังสือเดินทาง เอกสารที่ออกโดยราชการจากผู้ลงนามแทน โดยปกปิดข้อมูลศาสนาและหมู่เลือด ซึ่งปรากฎบนสำเนาดังกล่าว (หากมี) พร้อมลงนามรับรองสำเนาถูกต้อง`,
            width: `auto`,
            fontSize: 15,
          },
        ],
      },
      {
        bold: false,
        fontSize: 15,
        preserveLeadingSpaces: true,
        decorationColor: ``,
        decoration: ``,
        text: ``,
        width: `auto`,
        alignment: `left`,
        decorationStyle: ``,
        pageBreak: `before`,
        color: ``,
      },
      {
        columns: [
          {
            text: `ความสัมพันธ์กับผู้ป่วย...................................................................................................................... `,
            width: `auto`,
            decorationStyle: ``,
            alignment: `left`,
            pageBreak: ``,
            decorationColor: ``,
            color: ``,
            bold: false,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decoration: ``,
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decorationColor: ``,
            width: `auto`,
            bold: false,
            decorationStyle: ``,
            text: `เลขที่บัตรประจำตัวประชาชนผู้ลงนามแทนผู้ป่วย............................................................................ `,
            pageBreak: ``,
            color: ``,
            alignment: `left`,
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            text: `เบอร์โทรศัพท์.................................................................................................................................... `,
            color: ``,
            decorationColor: ``,
            width: `auto`,
            decorationStyle: ``,
            bold: false,
            fontSize: 15,
            alignment: `left`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        columns: [
          {
            alignment: `left`,
            pageBreak: ``,
            width: `auto`,
            text: `อีเมล.................................................................................................................................................. `,
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            bold: false,
            color: ``,
            fontSize: 15,
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            decoration: ``,
            text: `หมายเหตุ:`,
            decorationStyle: ``,
            decorationColor: ``,
            color: ``,
            fontSize: 15,
            pageBreak: ``,
            alignment: `left`,
            bold: `true`,
            width: `auto`,
          },
          {
            color: ``,
            alignment: `left`,
            decorationColor: ``,
            bold: false,
            decorationStyle: ``,
            decoration: ``,
            width: 5,
            text: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            fontSize: 15,
          },
          {
            decorationStyle: ``,
            fontSize: 15,
            alignment: `left`,
            bold: false,
            color: ``,
            decoration: ``,
            decorationColor: ``,
            pageBreak: ``,
            text: `ในกรณีที่ต้องมอบผลตรวจให้กับบุคคลอื่นนอกจากผู้ป่วย`,
            width: `auto`,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        text: ` `,
        decoration: ``,
        decorationStyle: ``,
        decorationColor: ``,
        width: `auto`,
        color: ``,
        bold: false,
        fontSize: 15,
        pageBreak: ``,
        alignment: `left`,
        preserveLeadingSpaces: true,
        margin: [0, 0, 0, 0],
      },
      {
        columns: [
          {
            width: `auto`,
            decoration: ``,
            text: `ข้าพเจ้ายินยอมให้เปิดเผยข้อมูลการตรวจเอชไอวี ให้แก่ ............................................................................................................`,
            fontSize: 15,
            decorationColor: ``,
            bold: false,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            alignment: `left`,
            pageBreak: ``,
            color: ``,
          },
          {
            pageBreak: ``,
            decoration: ``,
            fontSize: 15,
            bold: false,
            decorationColor: ``,
            width: `auto`,
            decorationStyle: ``,
            alignment: `left`,
            text: ` เท่านั้น`,
            color: ``,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            text: `ลงนาม........................................................  `,
            pageBreak: ``,
            decorationColor: ``,
            fontSize: 15,
            decoration: ``,
            alignment: `center`,
            decorationStyle: ``,
            color: ``,
            width: 200,
            preserveLeadingSpaces: true,
          },
          {
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            bold: false,
            width: 80,
            fontSize: 15,
            decorationColor: ``,
            pageBreak: ``,
            color: ``,
            alignment: `left`,
            decoration: ``,
            text: `  `,
          },
          {
            preserveLeadingSpaces: true,
            width: 200,
            alignment: `center`,
            decoration: ``,
            text: `พยาน 1 ลงนาม........................................................`,
            fontSize: 15,
            decorationColor: ``,
            bold: false,
            pageBreak: ``,
            decorationStyle: ``,
            color: ``,
          },
        ],
      },
      {
        columns: [
          // ใส่ props patientname
          {
            stack: [
              {
                fontSize: 15,
                color: ``,
                decorationColor: ``,
                pageBreak: ``,
                alignment: `center`,
                decorationStyle: ``,
                preserveLeadingSpaces: true,
                decoration: ``,
                text: props.items?.full_name,
                width: `auto`,
                bold: false,
                margin: [0, 0, 0, -15],
              },
              {
                fontSize: 15,
                color: ``,
                decorationColor: ``,
                pageBreak: ``,
                alignment: `center`,
                decorationStyle: ``,
                preserveLeadingSpaces: true,
                decoration: ``,
                text: `(.............................................................)`,
                width: `auto`,
                bold: false,
                margin: [0, 0, 0, 0],
              },
            ],
            width: 200,
          },
          {
            pageBreak: ``,
            bold: false,
            alignment: `left`,
            text: ``,
            color: ``,
            decorationStyle: ``,
            decoration: ``,
            width: 80,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
          },
          {
            decoration: ``,
            width: 200,
            fontSize: 15,
            decorationColor: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            color: ``,
            bold: false,
            text: `(.............................................................)`,
            decorationStyle: ``,
            alignment: `center`,
          },
        ],
      },
      {
        columns: [
          // ใส่ prop signedDate
          {
            fontSize: 15,
            alignment: `center`,
            decorationColor: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            bold: false,
            width: 30,
            color: ``,
            decoration: ``,
            decorationStyle: ``,
            text: ``,
          },
          {
            columns: [
              {
                fontSize: 15,
                alignment: `center`,
                decorationColor: ``,
                pageBreak: ``,
                preserveLeadingSpaces: true,
                bold: false,
                width: `auto`,
                color: ``,
                decoration: ``,
                decorationStyle: ``,
                text: `วันที่`,
              },
              {
                stack: [
                  {
                    bold: false,
                    alignment: `center`,
                    width: `auto`,
                    text: props.items?.signedDate,
                    pageBreak: ``,
                    decorationStyle: ``,
                    decoration: ``,
                    preserveLeadingSpaces: true,
                    decorationColor: ``,
                    fontSize: 15,
                    color: ``,
                    margin: [0, 0, 0, -15],
                  },
                  {
                    bold: false,
                    alignment: `left`,
                    width: `auto`,
                    text: `....................................................`,
                    pageBreak: ``,
                    decorationStyle: ``,
                    decoration: ``,
                    preserveLeadingSpaces: true,
                    decorationColor: ``,
                    fontSize: 15,
                    color: ``,
                    margin: [0, 0, 0, 0],
                  },
                ],
                width: `auto`,
              },
            ],
            width: 200,
          },
          {
            text: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            bold: false,
            decorationColor: ``,
            alignment: `left`,
            fontSize: 15,
            decorationStyle: ``,
            color: ``,
            width: 50,
            pageBreak: ``,
          },
          {
            alignment: `left`,
            color: ``,
            decorationColor: ``,
            text: `พยาน 2 ลงนาม........................................................`,
            width: 200,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            fontSize: 15,
            pageBreak: ``,
            bold: false,
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            alignment: `center`,
            decorationColor: ``,
            text: ``,
            decorationStyle: ``,
            decoration: ``,
            fontSize: 15,
            color: ``,
            preserveLeadingSpaces: true,
            bold: false,
            width: 280,
          },
          {
            alignment: `center`,
            width: 200,
            decorationColor: ``,
            bold: false,
            text: `(.............................................................)`,
            fontSize: 15,
            color: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationStyle: ``,
          },
        ],
      },
      {
        decorationStyle: ``,
        decorationColor: ``,
        color: ``,
        preserveLeadingSpaces: true,
        decoration: ``,
        width: `auto`,
        fontSize: 15,
        pageBreak: ``,
        bold: false,
        alignment: `left`,
        text: ` `,
      },
      {
        columns: [
          {
            text: `หมายเหตุ :`,
            alignment: `left`,
            pageBreak: ``,
            decoration: ``,
            color: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            fontSize: 15,
            bold: `true`,
            decorationStyle: ``,
            width: `auto`,
          },
          {
            alignment: `left`,
            text: ``,
            preserveLeadingSpaces: true,
            width: 5,
            pageBreak: ``,
            decorationColor: ``,
            bold: false,
            fontSize: 15,
            decoration: ``,
            color: ``,
            decorationStyle: ``,
          },
          {
            alignment: `left`,
            fontSize: 15,
            decoration: ``,
            color: ``,
            text: `เอกสารหนังสือแสดงความยินยอมการตรวจวินิจฉัยเพื่อหาเชื้อเอชไอวี ถือเป็นข้อมูลส่วนตัวของผู้ป่วย`,
            decorationColor: ``,
            bold: false,
            preserveLeadingSpaces: true,
            width: `auto`,
            pageBreak: ``,
            decorationStyle: ``,
          },
        ],
      },
    ],
    pageSize: `A4`,
    images: injectImages(props.items?.haveHeader),
  };
}
