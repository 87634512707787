import React, { useState, useRef, useEffect } from "react";
import {
  Segment,
  Grid,
  Image,
  Label,
  Icon,
  List,
  Input,
  Divider,
  Menu
} from "semantic-ui-react";
import "./App.css";
import "semantic-ui-css/semantic.min.css";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import { FormattedMessage, injectIntl } from "react-intl";
import moment from 'moment';

const DEFAULT_IMAGE =
  "https://react.semantic-ui.com/images/wireframe/image.png";
const OfficialAccount = props => {
  const isMounted = useRef(true);
  const [division, setDivision] = useState(null);
  const [name, setName] = useState("");
  const [fullName, setFullName] = useState("");
  const [imageUrl, setImageUrl] = useState(DEFAULT_IMAGE);
  const [address, setAddress] = useState("");
  const [diagRuleList, setDiagRuleList] = useState([]);
  const inputRef = useRef();
  const [typingTimeout, setTypingTimeout] = React.useState(null);
  const history = useHistory();

  React.useEffect(() => {
    if(props.division){
      setDivision(props.division)
    }
  }, [props.division])

  const loadDivisionProfile = async id => {
    const [data, error] = await props.controller.loadDivisionProfile(
      props.apiToken ? props.apiToken : Cookies.get("apiToken"),
      id ? id : props.match.params.id
    );
    if (!isMounted.current) {
      return;
    }
    if (data) {
      if (data.items.length > 0) {
        var obj = JSON.parse(data.items[0].extra);
        setName(obj.name ? obj.name : "");
        setFullName(obj.full_name ? obj.full_name : "");
        setAddress(obj.address ? obj.address : "");
        setImageUrl(obj.image ? obj.image : DEFAULT_IMAGE);
      }
    }
  };

  const sortDiagRuleList = (data) => {
    let result = []
    if (data && data.items && data.items.length > 0) {
      // console.log(" sortDiagRuleList", data)
      result = data.items.sort((a, b) => moment(a.published_at).format('YYYYMMDDhhmmssSSS') - moment(b.published_at).format('YYYYMMDDhhmmssSSS'));
    }
    return result
  }


  const searchDiagRuleList = async () => {
    let search = inputRef.current.inputRef.current.value;
    const [data, error] = await props.controller.searchDiagRuleList(
      props.apiToken ? props.apiToken : Cookies.get("apiToken"),
      search,
      division ? division : props.match.params.id
    );
    if (!isMounted.current) {
      return;
    }
    if (data) {

      let drL = sortDiagRuleList(data)
      if (drL && drL.length > 0) {
        setDiagRuleList(drL);
      } else {
        setDiagRuleList([])
      }
    }
  };

  const handleInputKeyPress = event => {
    if (event.key === "Enter") {
      // GOTO Search
      searchDiagRuleList();
    }
  };

  const handleDiagRuleSelected = item => (event, data) => {
    history.push("/form/" + item.id + "/");
  };

  useEffect(() => {
    if (!division) {
      setDivision(props.match ? props.match.params.id : null);
    }
  }, [props.match.params.id]);

  useEffect(() => {
    if (division && props.controller) {
      loadDivisionProfile(division);
      searchDiagRuleList();
    }
  }, [props.controller, division]);

  useEffect(() => {
    if(props.onSetBackToApp){
      props.onSetBackToApp(false)
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  const createDiagRule = () => {
    console.log(" diagRuleList" , diagRuleList)

    return diagRuleList.map((item, index) => (
      <List.Item
        className="diag-rule-list-item"
        key={index}
        onClick={handleDiagRuleSelected(item)}
      >
        <Label
          size={"large"}
        >
          <p>{item.name}</p>
        </Label>
      </List.Item>
    ));
  };

  const handleSearchChange = e => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(() => {
        searchDiagRuleList();
      }, 400)
    );
  };
  
  return (
    <div className="official-account">
      <Segment
        basic
      >
        <p className="division-name">
          {name}
          <br />
          {fullName}
        </p>
        <Grid>
          <Grid.Column width={6}>
            <Image src={imageUrl} />
          </Grid.Column>
          <Grid.Column width={10}>
            <Label >
              <Icon name={"map marker alternate"} color={"red"} />
              {address ? address : "No Location"}
            </Label>
          </Grid.Column>
        </Grid>
      </Segment>

      <p className="sub-header">
        {props.intl.formatMessage({id: "form.select_form"})}
      </p>

      <Input
        ref={inputRef}
        fluid
        icon={"search"}
        iconPosition={"left"}
        placeholder={`${props.intl.formatMessage({id: "common.search"})}...`}
        onKeyDown={handleInputKeyPress}
        onChange={handleSearchChange}
      />
      <Divider />

      <List verticalAlign={"middle"} className="diag-rule-list">
        {createDiagRule()}
      </List>
    </div>
  );
};

OfficialAccount.defaultProps = {
  controller: {},
  onSetBackToApp: () => {},
};

export default (injectIntl(OfficialAccount));
