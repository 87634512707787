import WasmController from "react-lib/frameworks/WasmController";

// APIs
import OperatingOrderCheckout from "issara-sdk/apis/OperatingOrderCheckout_apps_ORM";
import OperatingOrderConfirmAdmit from "issara-sdk/apis/OperatingOrderConfirmAdmit_apps_ORM";
import OperatingOrderConfirmServiceCompensation from "issara-sdk/apis/OperatingOrderConfirmServiceCompensation_apps_ORM";
import OperatingOrderDetail from "issara-sdk/apis/OperatingOrderDetail_apps_ORM";
import OperatingItemList from "issara-sdk/apis/OperatingItemList_apps_ORM";

// SEQ
import * as ORQueueI from "./sequence/ORQueue";
import * as ORCountsI from "./sequence/ORCounts";
import * as PreOperationI from "./sequence/PreOperation";
import * as ORRequestI from "./sequence/ORRequest";
import * as ORPostOperationI from "./sequence/ORPostOperation";
import * as PerioperativeNursingI from "./sequence/PerioperativeNursing";
import * as ORHistoryI from './sequence/ORHistory'
import * as ORCancelListI from "./sequence/ORCancelList";
import * as ORCaseListI from './sequence/ORCaseList'
import * as OperatingDateTimeI from "./sequence/OperatingDateTime";
import * as ManageORI from "./sequence/ManageOR";
import * as ORServiceRefundI from "./sequence/ORServiceRefund";

export type State = {
  orDischargeResult?: any;
} & ORQueueI.State &
  ORCountsI.State &
  PreOperationI.State &
  ORPostOperationI.State &
  ORRequestI.State &
  PerioperativeNursingI.State &
  ORHistoryI.State &
  ORCancelListI.State &
  ORCaseListI.State &
  ManageORI.State &
  OperatingDateTimeI.State &
  ORServiceRefundI.State;

export const StateInitial: State = {
  orDischargeResult: null,
  // ...ORQueueI.StateInitial,
  // ...ORCountsI.StateInitial,
  // ...PreOperationI.StateInitial,
  // ...ORPostOperationI.StateInitial,
  // ...ORRequestI.StateInitial,
  // ...PerioperativeNursingI.StateInitial,
  // ...ORHistoryI.StateInitial,
  // ...ORCancelListI.StateInitial,
  // ...ORCaseListI.StateInitial,
  // ...OperatingDateTimeI.StateInitial,
  // ...ManageORI.StateInitial,
  // ...ORServiceRefundI.StateInitial
};

export type Event =
  // checkout
  | { message: "GetCheckoutMaster" }
  | { message: "ORCheckout"; params: any }
  // discharge
  | { message: "GetDischargeMaster" }
  | { message: "ORDischarge"; params: any }
  | { message: "ORConfirmCompensation"; params: any }
  | { message: "ORConfirmAdmit"; params: any }
  // cancel discharge
  | { message: "ORCancelDischarge"; params: any }
  // get
  | { message: "GetOROrderByID"; params: any }
  | { message: "SearchORItemList"; params: any }
  | ORQueueI.Event;

export type Data = {
  division?: number;
} & ORQueueI.Data &
  ORCountsI.Data &
  PreOperationI.Data &
  ORPostOperationI.Data &
  ORRequestI.Data &
  PerioperativeNursingI.Data &
  ORHistoryI.Data &
  ORCancelListI.Data &
  ORCaseListI.Data &
  OperatingDateTimeI.Data &
  ManageORI.Data &
  ORServiceRefundI.Data;

export const DataInitial = {
  ...ORQueueI.DataInitial,
  ...ORCountsI.DataInitial,
  ...PreOperationI.DataInitial,
  ...ORPostOperationI.DataInitial,
  ...ORRequestI.DataInitial,
  ...PerioperativeNursingI.DataInitial,
  ...ORHistoryI.DataInitial,
  ...ORCancelListI.DataInitial,
  ...ORCaseListI.DataInitial,
  ...ManageORI.DataInitial,
  ...OperatingDateTimeI.DataInitial,
};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const DidMount: Handler = async (controller, params) => { };

// OR Checkout
export const GetCheckoutMaster: Handler = async (controller) => {
  controller.handleEvent({
    message: "GetMasterData" as any,
    params: {
      masters: [["planDischargeType", {}]],
    },
  });
};

export const ORCheckout: Handler = async (controller, params) => {
  const [response, error, network] = await OperatingOrderCheckout.update({
    apiToken: controller.apiToken,
    pk: params.order?.id,
    data: {
      checkout_cause: params.checkout_cause,
      death_datetime: params.death_datetime,
    },
  });
  if (!error) {
    GetOROrderByID(controller, params);
    controller.handleEvent({
      message: "HandleRefreshEncounter" as any,
      params: {},
    });
  }
};

// OR Discharge
export const GetDischargeMaster: Handler = async (controller) => {
  controller.handleEvent({
    message: "GetMasterData" as any,
    params: {
      masters: [
        ["patientCondition", {}],
        ["patientDischarge", {}],
        ["orTransferLocation", {}],
      ],
    },
  });
};

export const ORDischarge: Handler = async (controller, params) => {
  const state = controller.getState()

  const [response, error, network] = await OperatingOrderDetail.update({
    apiToken: controller.apiToken,
    pk: params.order?.id,
    data: {
      action: "COMPLETE",
      result: params.result,
      admit_detail: params.admit_detail,
      admit_result: params.admit_result,
      predischarge_condition: params.predischarge_condition,
      discharge_status: params.discharge_status,
      discharge_note: params.discharge_note,
      transfer_location: params.transfer_location,
      patient_out_datetime: params.patient_out_datetime,
    },
    extra: {
      division: controller.data.division,
    },
  });
  if (!error) {
    controller.setState({ orDischargeResult: response });
  } else {
    controller.setState({
      errorMessage: { ...state.errorMessage, [params.card]: error }
    })
  }
};

export const ORConfirmCompensation: Handler = async (controller, params) => {
  const [response, error, network] =
    await OperatingOrderConfirmServiceCompensation.put({
      apiToken: controller.apiToken,
      pk: params.order?.id,
    });
};

export const ORConfirmAdmit: Handler = async (controller, params) => {
  const [response, error, network] = await OperatingOrderConfirmAdmit.put({
    apiToken: controller.apiToken,
    pk: params.order?.id,
  });
};

// OR Cancel Discharge
export const ORCancelDischarge: Handler = async (controller, params) => {
  const [response, error, network] = await OperatingOrderDetail.update({
    apiToken: controller.apiToken,
    pk: params.order?.id,
    data: {
      action: "CANCEL_COMPLETE",
      cancel_reason: params.cancel_reason,
    },
  });
  if (!error) {
    GetOROrderByID(controller, params);
  }
};

// GET
export const GetOROrderByID: Handler = async (controller, params) => {
  const [response, error, network] = await OperatingOrderDetail.retrieve({
    apiToken: controller.apiToken,
    pk: params.order?.id,
  });
  if (!error) {
    controller.setState({ selectedOrOrder: response });
  }
};

export const SearchORItemList: Handler = async (controller, params) => {
  const [response, error, network] = await OperatingItemList.list({
    params: { search: params?.searchText, limit: 100 },
    apiToken: controller.apiToken,
  });
  if (!error) {
    controller.setState({ searchOrItems: response?.items });
  }
};
