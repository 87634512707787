import WasmController from "../../../../frameworks/WasmController";

// APIs
import AnesthesiaTemplateDetail from "issara-sdk/apis/AnesthesiaTemplateDetail_apps_ANS";
import AnesthesiaTemplateList from "issara-sdk/apis/AnesthesiaTemplateList_apps_ANS";
import DrugANSItemDetail from "issara-sdk/apis/DrugANSItemDetail_apps_ANS";
import DrugANSItemList from "issara-sdk/apis/DrugANSItemList_apps_ANS";
import DrugList from "issara-sdk/apis/DrugList_apps_TPD";
import SupplyList from "issara-sdk/apis/SupplyList_apps_MSD";
import SupplyANSItemDetail from "issara-sdk/apis/SupplyANSItemDetail_apps_ANS";
import SupplyANSItemList from "issara-sdk/apis/SupplyANSItemList_apps_ANS";
import TreatmentList from "issara-sdk/apis/TreatmentList_apps_TRT";
import icd9cmKeyUp from "issara-sdk/apis/icd9cmKeyUp_core";
import icd10KeyUp from "issara-sdk/apis/icd10KeyUp_core";
import OperatingTreatmentANSItemDetail from "issara-sdk/apis/OperatingTreatmentANSItemDetail_apps_ANS";
import OperatingTreatmentANSItemList from "issara-sdk/apis/OperatingTreatmentANSItemList_apps_ANS";
import DrugDetail from "issara-sdk/apis/DrugDetail_apps_TPD";

export type State = {
  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;
  masterOptions?: any;

  // sequence
  AnesTemplateSequence?: {
    sequenceIndex?: string | null;

    templateList?: any;
    templateSelected?: any;
    drugList?: any[];
    supplyList?: any[];
    treatmentList?: any[];
    icd9List?: any[];
    icd9Name?: string;
    drugUnit?: any;
  } | null;
};

export const StateInitial: State = {
  AnesTemplateSequence: {
    sequenceIndex: null,

    templateList: {},
    templateSelected: {},
    drugList: [],
    supplyList: [],
    treatmentList: [],
    icd9List: [],
    icd9Name: "",
    drugUnit: {},
  },
};

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division?: number;
};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const GetMaster: Handler = async (controller, params) => {
  await controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [
        // drug
        ["unit", {}],
        ["route", {}],
        ["site", {}],
        ["frequency", {}],
        ["method", {}],
        // supply
        ["claimImaging", {}],
        // treatment
        ["doctor", {}],
        ["claimTreatment", {}],
      ],
    },
  });

  controller.setState(
    {
      AnesTemplateSequence: { sequenceIndex: "Action" },
    },
    () => {
      controller.handleEvent({ message: "RunSequence", params: params });
    }
  );
};

export const Action: Handler = async (controller, params) => {
  if (params?.action === "LIST_TEMPLATE") {
    const [templateResp, templateErr, templateNet] =
      await AnesthesiaTemplateList.list({
        apiToken: controller.apiToken,
        params: { name: params.name },
      });

    const state = controller.getState();

    if (templateErr) {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.cardKey]: templateErr,
        },
      });
    } else {
      let tempSel = state.AnesTemplateSequence?.templateSelected;
      if (tempSel) {
        tempSel = templateResp?.items?.filter(
          (t: any) => t.id === tempSel.id
        )[0];
      }

      controller.setState({
        AnesTemplateSequence: {
          ...state.AnesTemplateSequence,
          templateList: templateResp,
          templateSelected: tempSel,
        },
      });
    }
  } else if (params?.action === "CREATE_TEMPLATE") {
    const state = controller.getState();
    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    const [createResp, createErr, createNet] =
      await AnesthesiaTemplateList.create({
        apiToken: controller.apiToken,
        data: {
          name: params.templateName,
        },
      });
    if (createErr) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.cardKey]: createErr },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "ERROR",
        },
      });
    } else {
      controller.setState({
        successMessage: {
          ...state.successMessage,
          [params.cardKey]: `${params?.action} Success`,
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.cardKey}_${params.action}`]: "SUCCESS",
        },
      });
      Action(controller, { ...params, action: "LIST_TEMPLATE" });
    }
  } else if (params?.action === "CHANGE_TEMPLATE_NAME") {
    const state = controller.getState();

    const [changeResp, changeErr, changeNet] =
      await AnesthesiaTemplateDetail.update({
        apiToken: controller.apiToken,
        pk: state.AnesTemplateSequence?.templateSelected?.id,
        data: { name: params.templateName },
      });

    if (changeErr) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.cardKey]: changeErr },
      });
    } else {
      controller.setState({
        successMessage: {
          ...state.successMessage,
          [params.cardKey]: `${params?.action} Success`,
        },
      });
      Action(controller, { ...params, action: "LIST_TEMPLATE" });
    }
  } else if (params?.action === "DELETE_TEMPLATE") {
    const state = controller.getState();

    const [delResp, delErr, delNet] = await AnesthesiaTemplateDetail.delete({
      apiToken: controller.apiToken,
      pk: state.AnesTemplateSequence?.templateSelected?.id,
    });

    if (delErr) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.cardKey]: delErr },
      });
    } else {
      controller.setState({
        successMessage: {
          ...state.successMessage,
          [params.cardKey]: `${params?.action} Success`,
        },
      });
      Action(controller, { ...params, action: "LIST_TEMPLATE" });
    }
  } else if (params?.action === "SEARCH_DRUG") {
    if (params.keyword.length < 3) {
      return;
    }
    const [drugResp, drugErr, drugNet] = await DrugList.list({
      apiToken: controller.apiToken,
      params: { keyword: params.keyword },
      extra: { division: controller.data.division },
    });

    const state = controller.getState();

    controller.setState({
      AnesTemplateSequence: {
        ...state.AnesTemplateSequence,
        drugList: drugResp.items,
      },
    });
  } else if (params?.action === "SAVE_DRUG_ANS_ITEM") {
    const state = controller.getState();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    const data = {
      ...params.data,
      template: state.AnesTemplateSequence?.templateSelected?.id,
    };

    if (params.data?.id) {
      const [drugResp, drugErr, drugNet] = await DrugANSItemDetail.update({
        apiToken: controller.apiToken,
        pk: params.data.id,
        data: data,
      });

      if (drugErr) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.cardKey]: drugErr,
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "ERROR",
          },
        });
      } else {
        controller.setState({
          successMessage: {
            ...state.successMessage,
            [params.cardKey]: `${params?.action} Success`,
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "SUCCESS",
          },
        });
        Action(controller, { ...params, action: "LIST_TEMPLATE" });
      }
    } else {
      const [drugResp, drugErr, drugNet] = await DrugANSItemList.create({
        apiToken: controller.apiToken,
        data: data,
      });

      if (drugErr) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.cardKey]: drugErr,
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "ERROR",
          },
        });
      } else {
        controller.setState({
          successMessage: {
            ...state.successMessage,
            [params.cardKey]: `${params?.action} Success`,
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "SUCCESS",
          },
        });
        Action(controller, { ...params, action: "LIST_TEMPLATE" });
      }
    }
  } else if (params?.action === "DELETE_DRUG_ANS_ITEM") {
    const state = controller.getState();

    const [delResp, delErr, delNet] = await DrugANSItemDetail.delete({
      apiToken: controller.apiToken,
      pk: params.drug?.id,
    });

    if (delErr) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.cardKey]: delErr },
      });
    } else {
      controller.setState({
        successMessage: {
          ...state.successMessage,
          [params.cardKey]: `${params?.action} Success`,
        },
      });
      Action(controller, { ...params, action: "LIST_TEMPLATE" });
    }
  } else if (params?.action === "SEARCH_SUPPLY") {
    // if (params.keyword.length < 3) {
    //   return;
    // }
    const [supplyResp, supplyErr, supplyNet] = await SupplyList.list({
      apiToken: controller.apiToken,
      params: { keyword: params.keyword },
    });

    const state = controller.getState();

    controller.setState({
      AnesTemplateSequence: {
        ...state.AnesTemplateSequence,
        supplyList: supplyResp.items,
      },
    });
  } else if (params?.action === "SAVE_SUPPLY_ANS_ITEM") {
    const state = controller.getState();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    let supplyResp = null;
    let supplyErr = null;
    let supplyNet = null;
    const data = {
      ...params.data,
      template: state.AnesTemplateSequence?.templateSelected?.id,
    };

    if (params.data?.id) {
      const [supplyResp, supplyErr, supplyNet] =
        await SupplyANSItemDetail.update({
          apiToken: controller.apiToken,
          pk: params.data.id,
          data: data,
        });

      if (supplyErr) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.cardKey]: supplyErr,
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "ERROR",
          },
        });
      } else {
        controller.setState({
          successMessage: {
            ...state.successMessage,
            [params.cardKey]: `${params?.action} Success`,
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "SUCCESS",
          },
        });
        Action(controller, { ...params, action: "LIST_TEMPLATE" });
      }
    } else {
      const [supplyResp, supplyErr, supplyNet] = await SupplyANSItemList.create(
        {
          apiToken: controller.apiToken,
          data: data,
        }
      );

      if (supplyErr) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.cardKey]: supplyErr,
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "ERROR",
          },
        });
      } else {
        controller.setState({
          successMessage: {
            ...state.successMessage,
            [params.cardKey]: `${params?.action} Success`,
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "SUCCESS",
          },
        });
        Action(controller, { ...params, action: "LIST_TEMPLATE" });
      }
    }
  } else if (params?.action === "DELETE_SUPPLY_ANS_ITEM") {
    const state = controller.getState();

    const [supplyResp, supplyErr, supplyNet] = await SupplyANSItemDetail.delete(
      {
        apiToken: controller.apiToken,
        pk: params.supply?.id,
      }
    );

    if (supplyErr) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.cardKey]: supplyErr },
      });
    } else {
      controller.setState({
        successMessage: {
          ...state.successMessage,
          [params.cardKey]: `${params?.action} Success`,
        },
      });
      Action(controller, { ...params, action: "LIST_TEMPLATE" });
    }
  } else if (params?.action === "SEARCH_TREATMENT") {
    if (params.keyword.length < 3) {
      return;
    }
    const [treatmentResp, treatmentErr, treatmentNet] =
      await TreatmentList.list({
        apiToken: controller.apiToken,
        params: { keyword: params.keyword },
      });

    const state = controller.getState();


    controller.setState({
      AnesTemplateSequence: {
        ...state.AnesTemplateSequence,
        treatmentList: (treatmentResp.items || []).filter((item: any) =>
          item?.name.toLowerCase().includes(params?.keyword.toLowerCase())
        ),
      },
    });
  } else if (params?.action === "SEARCH_ICD9") {
    if (params.keyword.length < 3) {
      return;
    }
    const [icd9Resp, icd9Err, icd9Net] = await icd9cmKeyUp.post({
      apiToken: controller.apiToken,
      method: "icdterm",
      data: { icdterm: params.keyword },
    });

    const state = controller.getState();

    controller.setState({
      AnesTemplateSequence: {
        ...state.AnesTemplateSequence,
        icd9List: icd9Resp.response,
      },
    });
  } else if (params?.action === "SAVE_TREATMENT_ANS_ITEM") {
    const state = controller.getState();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.cardKey}_${params.action}`]: "LOADING",
      },
    });

    let treatmentResp = null;
    let treatmentErr = null;
    let treatmentNet = null;
    const data = {
      ...params.data,
      template: state.AnesTemplateSequence?.templateSelected?.id,
    };

    if (params.data?.id) {
      const [treatmentResp, treatmentErr, treatmentNet] =
        await OperatingTreatmentANSItemDetail.update({
          apiToken: controller.apiToken,
          pk: params.data.id,
          data: data,
        });

      if (treatmentErr) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.cardKey]: treatmentErr,
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "ERROR",
          },
        });
      } else {
        controller.setState({
          successMessage: {
            ...state.successMessage,
            [params.cardKey]: `${params?.action} Success`,
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "SUCCESS",
          },
        });
        Action(controller, { ...params, action: "LIST_TEMPLATE" });
      }
    } else {
      const [treatmentResp, treatmentErr, treatmentNet] =
        await OperatingTreatmentANSItemList.create({
          apiToken: controller.apiToken,
          data: data,
        });

      if (treatmentErr) {
        controller.setState({
          errorMessage: {
            ...state.errorMessage,
            [params.cardKey]: treatmentErr,
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "ERROR",
          },
        });
      } else {
        controller.setState({
          successMessage: {
            ...state.successMessage,
            [params.cardKey]: `${params?.action} Success`,
          },
          buttonLoadCheck: {
            ...state.buttonLoadCheck,
            [`${params.cardKey}_${params.action}`]: "SUCCESS",
          },
        });
        Action(controller, { ...params, action: "LIST_TEMPLATE" });
      }
    }
  } else if (params?.action === "DELETE_TREATMENT_ANS_ITEM") {
    const state = controller.getState();

    const [treatmentResp, treatmentErr, treatmentNet] =
      await OperatingTreatmentANSItemDetail.delete({
        apiToken: controller.apiToken,
        pk: params.treatment?.id,
      });

    if (treatmentErr) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.cardKey]: treatmentErr },
      });
    } else {
      controller.setState({
        successMessage: {
          ...state.successMessage,
          [params.cardKey]: `${params?.action} Success`,
        },
      });
      Action(controller, { ...params, action: "LIST_TEMPLATE" });
    }
  } else if (params?.action === "GET_ICD9") {
    const [icd9Resp, icd9Err, icd9Net] = await icd9cmKeyUp.post({
      apiToken: controller.apiToken,
      method: "icdterm",
      data: { icdterm: "" },
    });

    const state = controller.getState();

    controller.setState({
      AnesTemplateSequence: {
        ...state.AnesTemplateSequence,
        icd9List: icd9Resp.response,
      },
    });
  } else if (params?.action === "GET_DERUG_UNIT") {
    const [drugResp, drugErr, drugNet] = await DrugDetail.retrieve({
      apiToken: controller.apiToken,
      pk: params.id,
    });

    const state = controller.getState();

    controller.setState({
      AnesTemplateSequence: {
        ...state.AnesTemplateSequence,
        drugUnit: {
          unit: drugResp.default_dosage_unit,
          route: drugResp.default_route,
          site: drugResp.default_site,
          frequency: drugResp.default_frequency,
          method: drugResp.default_method,
        },
      },
    });
  }
};
