const FormStatIPDList = (props: any) => {
  return {
    defaultStyle: {
      font: "THSarabunNew",
      lineHeight: 1,
      fontSize: 12,
    },
    pageMargins: [15, 95, 15, 30],
    pageSize: "A4",
    styles: {
      table: {
        margin: [0, 5, 0, 15],
      },
      header: {
        fontSize: 22,
        bold: true,
        alignment: "center",
      },
      tableHeader: {
        bold: true,
        fontSize: 13,
        color: "black",
        fillColor: "lightgray",
      },
      tableData: {},
    },
    header: {
      margin: [20, 20, 20, 0],
      stack: [
        {
          layout: "noBorders",
          table: {
            widths: ["100%"],
            heights: [55],
            body: [
              [
                {
                  margin: [0, 0, 0, 0],
                  stack: [
                    {
                      text: "รายงานสถิติผู้ป่วยใน แยกตามประเภทผู้ป่วย โรค ทันตแพทย์/แพทย์เจ้าของไข้",
                      style: "header",
                    },
                    {
                      text: `ประจำวันที่ ${props?.start_date} ถึง ${props?.end_date}`,
                      style: "header",
                    },
                  ],
                },
              ],
            ],
          },
        },
      ],
    },
    content: [
      {
        style: "table",
        table: {
          headerRows: 1,
          widths: [130, 80, "*", 50],
          body: [
            [
              {
                text: "แพทย์เจ้าของไข้",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "ประเภทผู้ป่วย",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "Provisional Diagnosis",
                style: "tableHeader",
                alignment: "center",
              },
              {
                text: "จำนวน",
                style: "tableHeader",
                alignment: "center",
              },
            ],
            ...(props?.data || []).map((item: any) => [
              {
                text: item.doctor_fullname,
                style: "tableData",
                alignment: "center",
              },
              {
                text: item.patient_case_name,
                style: "tableData",
                alignment: "center",
              },
              {
                text: item.provisional_diagnosis,
                style: "tableData",
                alignment: "center",
              },
              {
                text: item.count,
                style: "tableData",
                alignment: "center",
              },
            ]),
          ],
        },
      },
    ],
    footer: function (currentPage: number, pageCount: number) {
      let pageText = currentPage.toString() + " of " + pageCount;
      return { text: pageText, alignment: "center" };
    },
  };
};

export default FormStatIPDList;
