import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'

const CardBillingSummaryUX = (props: any) => {
    return(
      <div
        style={{}}>
        <div
          style={{fontWeight: "bold", fontSize: "1.2rem",padding: "10px 10px 0px 10px", display: "flex", justifyContent: "space-between"}}>
          รายงานสรุปยอดและปิดกะ
          <div>
            
          </div>
        </div>
        <div
          style={{ padding: "0px 10px 5px 10px"}}>
          
          <hr>
          </hr>
        </div>
        <div
          style={{ padding: "0px 10px"}}>
          {props.errorMessage}
        </div>
        <div
          style={{ padding: "5px 10px 0px 10px"}}>
          
          <Table
            data={props.billingSummaryData}
            headers="วันที่-เวลา,เลขที่เอกสาร,สถานะ,เงินสด,เงินโอน,บัตร,เช็ค,คูปอง,คืนเงิน"
            id="tb-billingSummaryData"
            keys="receipt_date_text,receipt_no,receipt_status,payment_cash,payment_transfer,payment_credit,payment_cheque,payment_coupon,payment_refund"
            minRows={1}
            showPagination={false}
            style={{height: "450px",  zoom: "87%", margin: "0px 10px"}}>
          </Table>
        </div>
        <div
          style={{display: "flex", justifyContent: "flex-end", margin: "10px"}}>
          
          <div>
            {props.buttonRefresh}
          </div>
          <div
            id="btn-printShiftReport"
            style={{ margin: "0px 10px" }}>
            {props.buttonPrint}
          </div>
          <div
            id="btn-closeShiftReport">
            {props.buttonSave}
          </div>
        </div>
        <div
          style={{width: "100%", height: "200px"}}>
          
        </div>
      </div>
    )
}

export default CardBillingSummaryUX

export const screenPropsDefault = {"debug":true,"showSupplyList":false}

/* Date Time : Thu Feb 09 2023 02:28:53 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ zoom: \"90%\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "รายงานสรุปยอดและปิดกะ"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.2rem\",padding: \"10px 10px 0px 10px\", display: \"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"200px\"}"
        }
      },
      "seq": 126,
      "void": false
    },
    {
      "from": null,
      "id": 72,
      "name": "div",
      "parent": 24,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"0px 10px 5px 10px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "hr",
      "parent": 87,
      "props": {
      },
      "seq": 88,
      "void": true
    },
    {
      "from": null,
      "id": 89,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", justifyContent: \"flex-end\", margin: \"10px\"}"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": null,
      "id": 90,
      "name": "div",
      "parent": 89,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonRefresh"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": null,
      "id": 91,
      "name": "div",
      "parent": 89,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonPrint"
        },
        "id": {
          "type": "value",
          "value": "btn-printShiftReport"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0px 10px\" }"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "div",
      "parent": 89,
      "props": {
        "children": {
          "type": "code",
          "value": "props.buttonSave"
        },
        "id": {
          "type": "value",
          "value": "btn-closeShiftReport"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 93,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"5px 10px 0px 10px\"}"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 94,
      "name": "Table",
      "parent": 93,
      "props": {
        "data": {
          "type": "code",
          "value": "props.billingSummaryData"
        },
        "headers": {
          "type": "value",
          "value": "วันที่-เวลา,เลขที่เอกสาร,สถานะ,เงินสด,เงินโอน,บัตร,เช็ค,คูปอง,คืนเงิน"
        },
        "id": {
          "type": "value",
          "value": "tb-billingSummaryData"
        },
        "keys": {
          "type": "value",
          "value": "receipt_date_text,receipt_no,receipt_status,payment_cash,payment_transfer,payment_credit,payment_cheque,payment_coupon,payment_refund"
        },
        "minRows": {
          "type": "code",
          "value": "1"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"450px\",  zoom: \"87%\", margin: \"0px 10px\"}"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "code",
          "value": "props.errorMessage"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"0px 10px\"}"
        }
      },
      "seq": 89,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardBillingSummaryUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "debug": true,
    "showSupplyList": false
  },
  "width": 80
}

*********************************************************************************** */
