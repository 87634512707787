import React, { useEffect, useMemo, useState } from "react";
import { IntlProvider, FormattedMessage, useIntl } from "react-intl";
import { State } from "./sequence/MergePatient";

import { Form, FormGroup, Input, Dropdown, Modal, Button, Divider, Image } from "semantic-ui-react";

// Common
import PasswordRecheck from "react-lib/apps/common/PasswordRecheck";
import SnackMessage from "react-lib/apps/common/SnackMessage";

type ModMergePatientProps = {
  // function
  onEvent: any;
  setProp: any;
  runSequence: any;

  // common
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;
  masterOptions?: any;

  // lang
  languageUX?: any;

  // seq
  MergePatientSequence: State["MergePatientSequence"];
  openMod: boolean;
  closeMod: () => void;
};

const ModMergePatient = (props: ModMergePatientProps) => {
  const intl = useIntl();
  const [openSnackMessage, setOpenSnackMessage] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");

  useEffect(() => {
    props.runSequence({
      sequence: "MergePatient",
      restart: true,
    });
  }, []);

  useEffect(() => {
    if (props.errorMessage?.MergePatient || props.successMessage?.MergePatient) {
      setOpenSnackMessage(true);
    }
  }, [props.errorMessage, props.successMessage]);

  const destinationPatient = useMemo(() => {
    return props.MergePatientSequence?.destinationPatient;
  }, [props.MergePatientSequence]);

  const mergeReasonOptions = useMemo(() => {
    return Array.isArray(props.MergePatientSequence?.mergeReasonOptions)
      ? props.MergePatientSequence?.mergeReasonOptions
      : [];
  }, [props.MergePatientSequence]);

  const handleChangeData = (_event: any, data: any) => {
    props.setProp(`MergePatientSequence.${data.name}`, data.value);
  };

  const handleSearchPatient = () => {
    props.runSequence({
      sequence: "MergePatient",
      action: "SEARCH",
      hn: props.MergePatientSequence?.searchText,
    });
  };

  const handleMergePatient = () => {
    props.runSequence({
      sequence: "MergePatient",
      action: "MERGE",
      data: {
        reason: props.MergePatientSequence?.mergeReason,
        password: password,
      },
    });
  };

  const handleAddOptions = (_event: any, { value }: any) => {
    const currentOptions = Array.isArray(mergeReasonOptions) ? mergeReasonOptions : [];
    props.setProp(`MergePatientSequence.mergeReasonOptions`, [
      { text: value, value: value, key: value },
      ...currentOptions,
    ]);
  };

  return (
    <>
      {openSnackMessage && (
        <SnackMessage
          onEvent={props.onEvent}
          onClose={() => {
            props.setProp(`errorMessage.MergePatient`, null);
            props.setProp(`successMessage.MergePatient`, null);
          }}
          error={props.errorMessage?.MergePatient}
          success={props.successMessage?.MergePatient}
          languageUX={props.languageUX}
        />
      )}

      <Modal closeIcon={true} open={props.openMod} onClose={props.closeMod} size="large">
        <Form
          style={{ padding: "1rem", backgroundColor: "#E0F0FF" /*border: "1px solid #ff0019"*/ }}
        >
          <FormGroup style={{ display: "flex", alignItems: "center" }}>
            <label>รวมแฟ้มจาก</label>
            <strong style={{ marginLeft: "1rem", marginRight: "1rem" }}>{`HN ${
              props.MergePatientSequence?.sourcePatient?.hn || ``
            }`}</strong>
            <label>{props.MergePatientSequence?.sourcePatient?.full_name || ``}</label>
            <label style={{ marginLeft: "1rem", marginRight: "1rem" }}>ไปยัง HN</label>
            <Input
              id="ModMergePatient-Input-SearchPatient"
              name="searchText"
              icon="search"
              value={props.MergePatientSequence?.searchText}
              onKeyDown={(e: any) => e.key === "Enter" && handleSearchPatient()}
              onChange={handleChangeData}
            ></Input>
          </FormGroup>

          <FormGroup
            style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
          >
            <div style={{ border: "1px dashed #0390fc", padding: "1rem" }}>
              <label>ข้อมูลที่ถูกนำไปรวม ได้แก่</label>
              <ul>
                <li style={{ marginBottom: "1rem" }}>ประวัติการรักษา และการทำหัตถการของผู้ป่วย</li>
                <li style={{ marginBottom: "1rem" }}>รายการนัดหมายที่รอลงทะเบียน</li>
                <li style={{ marginBottom: "1rem" }}>ข้อมูลภาพสแกนของผู้ป่วย</li>
              </ul>
            </div>

            <div onContextMenu={(e) => e.preventDefault()}>
              <Image
                draggable={false}
                onError={(e: any) => {
                  e.target.onerror = null;
                  e.target.src = "/static/images/person.png";
                }}
                src={
                  destinationPatient?.profile_image != null
                    ? destinationPatient?.profile_image?.image
                    : "/static/images/person.png"
                }
                style={{
                  height: "200px",
                  border: "solid black 1px",
                  opacity: "1",
                  maxWidth: "160px",
                  objectFit: "cover",
                }}
              />
            </div>

            <Form>
              <FormGroup
                style={{
                  display: "flex",
                  marginLeft: "1rem",
                  marginRight: "1rem",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <label>{destinationPatient?.full_name || ``}</label>
                <span style={{ display: "flex", gap: "10px" }}>
                  <strong>เพศ:</strong>
                  <label>{destinationPatient?.format_gender_th || ``}</label>
                </span>
                <span style={{ display: "flex", gap: "10px" }}>
                  <strong>เอกสารระบุตัวตน:</strong>
                  <label>{destinationPatient?.citizen_no || ``}</label>
                </span>
              </FormGroup>
              <FormGroup
                style={{
                  display: "flex",
                  marginLeft: "1rem",
                  marginRight: "1rem",
                  alignItems: "center",
                }}
              >
                <span style={{ display: "flex", gap: "10px", marginRight: "58px" }}>
                  <strong>วันเกิด:</strong>
                  <label>{destinationPatient?.birthdate || ``}</label>
                </span>
                <span style={{ display: "flex", gap: "10px" }}>
                  <strong>อายุ:</strong>
                  <label>{destinationPatient?.full_age || ``}</label>
                </span>
              </FormGroup>
              <FormGroup
                style={{
                  display: "flex",
                  marginLeft: "1rem",
                  marginRight: "1rem",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span style={{ display: "flex", gap: "10px" }}>
                  <strong>หมู่เลือด:</strong>
                  <label>{destinationPatient?.format_blood_type || ``}</label>
                </span>
                <span style={{ display: "flex", gap: "10px" }}>
                  <strong>เชื้อชาติ:</strong>
                  <label>{destinationPatient?.format_race || ``}</label>
                </span>
                <span style={{ display: "flex", gap: "10px" }}>
                  <strong>สัญชาติ</strong>
                  <label>{destinationPatient?.format_nationality_th || ``}</label>
                </span>
                <span style={{ display: "flex", gap: "10px" }}>
                  <strong>ศาสนา:</strong>
                  <label>{destinationPatient?.religion}</label>
                </span>
              </FormGroup>
              <FormGroup
                style={{
                  display: "flex",
                  marginLeft: "1rem",
                  marginRight: "1rem",
                  alignItems: "center",
                }}
              >
                <span style={{ display: "flex", gap: "10px", marginRight: "97px" }}>
                  <strong>สถานภาพ:</strong>
                  <label>{destinationPatient?.format_marriage || ``}</label>
                </span>
                <span style={{ display: "flex", gap: "10px" }}>
                  <strong>อาชีพ:</strong>
                  <label>{destinationPatient?.career || ``}</label>
                </span>
              </FormGroup>
              <FormGroup
                style={{
                  marginLeft: "1rem",
                  marginRight: "1rem",
                  alignItems: "center",
                  width: "520px",
                }}
              >
                <span>
                  <strong style={{ marginRight: "10px" }}>ที่อยู่ปัจจุบัน:</strong>
                  <label>{destinationPatient?.format_present_address}</label>
                </span>
              </FormGroup>
              <FormGroup
                style={{
                  display: "flex",
                  marginLeft: "1rem",
                  marginRight: "1rem",
                  alignItems: "center",
                }}
              >
                <span style={{ display: "flex", gap: "10px", marginRight: "20px" }}>
                  <strong>โทรศัพท์มือถือ:</strong>
                  <label>{destinationPatient?.present_address?.tel_mobile || ``}</label>
                </span>
                <span style={{ display: "flex", gap: "10px" }}>
                  <strong>อีเมล:</strong>
                  <label>{destinationPatient?.present_address?.email || ``}</label>
                </span>
              </FormGroup>
            </Form>
          </FormGroup>
        </Form>
        <Divider />
        <Form>
          <FormGroup
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "1rem",
              marginRight: "1rem",
            }}
          >
            <label>ภายหลังการรวมแฟ้ม</label>
            <strong style={{ color: "#044c84", marginLeft: "10px" }}>{`HN ${
              props.MergePatientSequence?.sourcePatient?.hn || ``
            }`}</strong>
            <label style={{ marginLeft: "10px" }}>และแสดงสถานะ "</label>
            <strong style={{ color: "red" }}>
              {`ถูกรวมแฟ้มไปยัง HN ${
                destinationPatient?.hn || `.........................................`
              }`}
            </strong>
            <label>"</label>
          </FormGroup>
          <FormGroup
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "1rem",
              marginRight: "1rem",
              justifyContent: "space-between",
            }}
          >
            <label>
              เหตุผลในการโอนข้อมูลการรักษา<span style={{ color: "red" }}>*</span>
            </label>
            <Dropdown
              id="ModMergePatient-Dropdown-MergeReasons"
              name="mergeReason"
              onAddItem={handleAddOptions}
              onChange={handleChangeData}
              options={mergeReasonOptions}
              value={props.MergePatientSequence?.mergeReason || ""}
              allowAdditions={true}
              selection={true}
              search={true}
              style={{ minWidth: "30rem" }}
            ></Dropdown>
            <label>
              Password<span style={{ color: "red" }}>*</span>
            </label>
            <PasswordRecheck
              id="ModMergePatient-Input-PasswordRecheck"
              password={password}
              setPassword={setPassword}
              placeholder={intl.formatMessage({ id: "ระบุรหัสผ่าน" })}
            />
            <Button
              onClick={handleMergePatient}
              disabled={
                !(
                  props.MergePatientSequence?.sourcePatient &&
                  props.MergePatientSequence?.destinationPatient
                )
              }
              color="green"
            >
              ยืนยันรวมแฟ้ม
            </Button>
          </FormGroup>
        </Form>
      </Modal>
    </>
  );
};

export default ModMergePatient;
