import UserService from '../services/UserService';

import { to, setStateAsync, formatDateToYYYYMMDD } from '../../utils';

export default class UserManager {
  apiToken?: string | null;

  constructor(apiToken: string | null = null) {
    this.apiToken = apiToken
  }

  getUserPermission = async (params: any) => {
    var userPermissionData = [];
    var userPermissionError = null;
    const userService = new UserService(this.apiToken);
    const [error, response] = await to(userService.getUserPermission(params));
    if (response) {
      userPermissionData = response;
    } else {
      userPermissionError = error;
    }
    return [userPermissionData, userPermissionError];
  };
  getUserTokenize = async ({ code, apiToken }: any = {}) => {
    let params: Record<string, any> = {};
    var tokenData = [];
    var tokenError = null;
    const userService = new UserService(this.apiToken);
    if (code) {
      params.code = code;
    }
    const [error, response] = await to(userService.getUserTokenize(params, apiToken));
    if (response) {
      tokenData = response;
    } else {
      tokenError = error;
    }
    return [tokenData, tokenError];
  };
  getUserTokenizeLicense = async ({ code }: any={}) => {
    let params: Record<string, any> = {};
    var tokenData = [];
    var tokenError = null;
    const userService = new UserService();
    if (code) {
      params.code = code;
    }
    const [error, response] = await to(userService.getUserTokenizeLicense(params));
    if (response) {
      tokenData = response;
    } else {
      tokenError = error;
    }
    return [tokenData, tokenError];
  };
  getUsers = async ({ nameUserName, nurseOrDoctor, role, fullName }: any = {}) => {
    let params: Record<string, any> = {};
    var usersData = [];
    var usersError = null;
    const userService = new UserService();
    if (nameUserName) {
      params.name_username = nameUserName;
    }
    if (nurseOrDoctor) {
      params.nurse_or_doctor = nurseOrDoctor;
    }
    if (role) {
      params.role = role;
    }
    if (fullName) {
      params.full_name = fullName;
    }
    const [error, response] = await to(userService.getUser(params));
    if (response) {
      usersData = response.items;
    } else {
      usersError = error;
    }
    return [usersData, usersError];
  }

  getUserProfile = async ({ apiToken }: any={}) => {
    let headers: Record<string, any> = {}
    let params = {};
    if (apiToken) {
      headers.Authorization = `Token ${apiToken}`;
    }
    const userService = new UserService();
    const [error, response] = await to(userService.getUserProfile({ headers, params }));
    return [response, error];
  }

  updateUserProfile = async ({ apiToken, firstName, lastName, dob, phone }: any={}) => {
    let headers: Record<string, any> = {}
    if (apiToken) {
      headers.Authorization = `Token ${apiToken}`;
    }
    let data: Record<string, any> = {
      first_name: firstName,
      last_name: lastName,
      phone_no: phone,
    }
    if (dob) {
      data.dob = formatDateToYYYYMMDD(dob);
    }
    const userService = new UserService();
    const [error, response] = await to(userService.patchUserProfile({ headers, data }));
    return [response, error];
  }

  getUserPosition = async ({ apiToken }: any = {}) => {
    const userService = new UserService();
    let params = {};
    const [error, response, network] = await to(
      userService.getUserPosition({ apiToken, params })
    );
    return [response, error, network];
  }

  getUserWithPatient = async ({ apiToken, userId }: any = {}) => {
    const userService = new UserService();
    let params = {};
    const [error, response, network] = await to(
      userService.getUserWithPatient({ apiToken, params, userId })
    );
    return [response, error, network];
  }

  postUserSetPassword = async ({ password, apiToken, id }: any = {}) => {
    let params = {};
    let data: any = {};
    if (password) {
      data.password = password;
    }
    if (id) {
      data.id = id;
    }
    const userService = new UserService(apiToken)
    const [error, response, network] = await to(
      userService.postUserSetPassword({ apiToken, params, data })
    );
    return [response, error];
  };
}
