import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button,
  Dropdown,
  Input,
  Checkbox
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Calendar
} from 'react-big-calendar'

const CardPatientAppointmentUX = (props: any) => {
    return(
      <div
        style={{ width: "100%", height: "95dvh", display: "flex" }}>
        <div
          className="patient-appointment-panel"
          style={{ padding: "5px", flex: 1, backgroundColor: "#d6ecf3" }}>

          <div
            className="patient-appointment-menus"
            style={{marginBottom:"5px", borderBottom: "solid #cccccc 1px"}}>

            <Button
              color={props.mode === "search" ? "blue" : undefined}
              onClick={() => props.setMode("search")}
              size="tiny"
              style={{paddingRight: "1.25em", paddingLeft: "1.25rem"}}>
              ค้นหา
            </Button>
            <Button
              color={props.mode === "reschedule" ? "blue" : undefined}
              onClick={() => props.setMode("reschedule")}
              size="tiny"
              style={props.disabledPostponeBtn ? {display: "none"} : {display: "inline-block",paddingRight: "1.25em", paddingLeft: "1.25rem"}}>
              เลื่อน (คลินิก)
            </Button>
            <Button
              color={props.mode === "reconfirm" ? "blue" : undefined}
              onClick={() => props.setMode("reconfirm")}
              size="tiny"
              style={props.disabledPostponeBtn ? {display: "none"} : {display: "inline-block",paddingRight: "1.25em", paddingLeft: "1.25rem"}}>
              เลื่อน (ผู้ป่วย)
            </Button>
            <Button
              color={props.mode === "waitingList" ? "blue" : undefined}
              onClick={() => props.setMode("waitingList")}
              size="tiny"
              style={props.disabledWaitingListBtn ? {display: "none"} : {display: "inline-block",paddingRight: "1.25em", paddingLeft: "1.25rem"}}>
              Waiting List
            </Button>
          </div>
          <div
            style={{display: props.mode === "search" ? "block" : "none"}}>

            <div
              style={{marginLeft: "5px" }}>
              HN ผู้ป่วย
            </div>
            <div
              style={{ display: "flex", gap: "5px"}}>

              <div
                style={{width: "100%"}}>
                {props.patientSearch}
              </div>
              <Button
                color="brown"
                onClick={props.onPatientSearch}
                size="small">
                ค้นหา
              </Button>
              <Button
                color="red"
                onClick={props.clearAppointment}
                size="small">
                เคลียร์
              </Button>
            </div>
            <div>

              <div
                style={{ fontWeight: "bold", fontSize: "1.2rem", margin: "5px" }}>
                คนไข้จากคำค้นหา
              </div>
              <div>

                <Table
                  className="--rt-tbody-overflow-y-hidden"
                  data={props.patientList}
                  getTrProps={props.patientRowProps}
                  headers="HN,ชื่อ-สกุล,Citizen ID/Passport No.,เบอร์โทรติดต่อ"
                  keys="hn,full_name, citizen_passport, tel_num"
                  minRows="4"
                  showPagination={false}
                  style={{height: "135px", marginBottom: "10px" }}
                  widths="^80,^80,^120,^90">
                </Table>
              </div>
            </div>
            <div
              style={{ display: props.hideAppointmentTable ? "none" : "block" }}>

              <div
                style={{ fontWeight: "bold", fontSize: "1.2rem", margin: "12px 0px 5px 0px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>

                <div>
                  นัดหมายก่อนหน้า
                </div>
                <div>

                  <Button
                    color="green"
                    disabled={props.disabledCreatePatientAppointment}
                    onClick={props.createPatientAppointment}
                    size="small"
                    style={{display : props.PopupElement ? "none": ""}}>
                    เพิ่มนัดหมาย
                  </Button>
                  <div>
                    {props.PopupElement}
                  </div>
                </div>
              </div>
              <div
                style={{display:"flex", justifyContent: "space-between"}}>

                <div
                  style={{fontWeight: "bold", flex: "0 0 48%"}}>
                  แพทย์
                  <div>
                    {props.doctorSearch}
                  </div>
                </div>
                <div
                  style={{fontWeight: "bold", flex: "0 0 48%"}}>
                  หน่วยงาน/คลินิก
                  <div>
                    {props.divisionSearch}
                  </div>
                </div>
              </div>
              <div
                style={{display:"flex", justifyContent: "space-between", alignItems:"flex-end", marginBottom: "10px", ...(!props.isShowChair && {display: "none"})}}>

                <div
                  style={{fontWeight: "bold", flex: "0 0 48%"}}>
                  Waiting List
                  <Input
                    icon="filter"
                    onChange={props.onChangeWaitingListFilter}
                    value={props.waitingListFilter}>
                  </Input>
                </div>
                <div
                  style={{fontWeight: "bold", flex: "0 0 48%"}}>

                </div>
              </div>
              <div>

                <Table
                  className="--rt-tbody-overflow-y-hidden"
                  data={props.appointmentFilterList}
                  getTrProps={props.appointmentRowProps}
                  headers={props.appointmentHeaders || "ชื่อ-สกุล,หน่วยงาน-แพทย์นัดหมาย,วันเวลานัดหมาย,Waiting List"}
                  keys={props.appointmentKeys || "patient_name,detail,estimated_at,waiting_list_name"}
                  minRows="10"
                  showPagination={false}
                  style={{height: "calc(100vh - 37rem)", marginBottom: "10px" }}
                  widths={props.appointmentWidths || "^90,^90,^90,^90"}>
                </Table>
              </div>
            </div>
          </div>
          <div
            style={{display: props.mode === "reschedule" ? "block" : "none"}}>
            {props.reschedule}
          </div>
          <div
            style={{display: props.mode === "reconfirm" ? "block" : "none"}}>
            {props.reconfirm}
          </div>
          <div
            style={{display: props.mode === "waitingList" ? "block" : "none"}}>
            {props.waitingList}
          </div>
        </div>
        <div
          className="patient-appointment-content"
          style={{flex: 3, padding: "0px 5px 0px 5px", height: "95vh", overflow: "auto"}}>

          <div
            style={{paddingBottom: "2px",marginBottom: "5px", display: props.isOR || props.isPackage || props.isConsult ? "none": "flex", fontWeight: "bold", fontSize: "1.2rem",  borderBottom: "solid #cccccc 1px"   }}>

            <Button
              color={props.tab === "detail" ? "blue" : undefined}
              disabled={props.detailDisabled}
              onClick={(e: any) => props.setTab("detail")}
              size="small">
              รายละเอียดนัดหมาย
            </Button>
            <Button
              color={props.tab === "calendar" ? "blue" : undefined}
              disabled={props.calendarDisabled}
              onClick={(e: any) => props.setTab("calendar")}
              size="small">
              เลือกวันเวลาออกตรวจ
            </Button>
          </div>
          <div
            style={{display: props.tab === "calendar" && !props.isOR && !props.isConsult? "block" : "none" }}>

            <div
              style={{marginBottom: "5px",display: "flex", alignItems: "center", justifyContent: "flex-end"}}>

              <label
                style={{marginRight: "5px"}}>
                {<>{"แผนก"}<span style={{color:"red"}}>*</span></> }
              </label>
              <Dropdown
                onChange={props.onChangeSelectedDivision}
                options={props.allDivisionsOptions}
                search={true}
                selection={true}
                value={props.selectedDivision}>
              </Dropdown>
              <label
                style={{marginLeft: "20px", marginRight: "5px"}}>
                Specialty
              </label>
              <Dropdown
                onChange={props.changeSpecialty}
                options={props.specialtyOptions}
                search={true}
                selection={true}
                value={props.specialty}>
              </Dropdown>
              <div
                style={{ marginLeft: "20px", padding:"5px", display: "flex", alignItems: "center"}}>

                <Checkbox
                  checked={props.onlyNewPatient}
                  label="เฉพาะแพทย์ที่รับผู้ป่วยใหม่"
                  onChange={props.changeOnlyNewPatient}>
                </Checkbox>
              </div>
              <label
                style={{marginLeft: "20px", marginRight: "5px", display: props.hideFilterDivision ? "none" : ""}}>
                คลีนิค
              </label>
              <Dropdown
                onChange={props.changeDivision}
                options={props.divisionOptions}
                selection={true}
                style={{ display: props.hideFilterDivision ? "none" : ""} }
                value={props.selectedDivisionId}>
              </Dropdown>
              <div
                style={{marginLeft: "25px", width: "300px"}}>
                {props.providerSelector}
              </div>
            </div>
            <div
              style={{height: "85vh"}}>

              <Calendar
                endAccessor="end"
                eventPropGetter={props.eventPropGetter}
                events={props.blockList || []}
                formats={props.formats}
                localizer={props.localizer}
                max={props.maxTime}
                min={props.minTime}
                onRangeChange={props.onRangeChange}
                onSelectEvent={props.onSelectEvent}
                startAccessor="start"
                style={{width: "100%", height: "100%"}}
                tooltipAccessor="tooltip"
                views={["month", "week"]}>
              </Calendar>
            </div>
          </div>
          <div
            style={{ display: props.tab === "detail" && !props.isOR && !props.isPackage  ? "block": "none", height: "95vh" }}>
            {}
            <div
              style={{display: props.selectedAppointment ? "none" : "block" }}>
              กรุณาเลือกนัดหมายคนไข้ที่ต้องการทำรายการ
            </div>
            <div
              style={{display: props.selectedAppointment ? "block" : "none" }}>
              {props.appointmentDetail}
            </div>
          </div>
          <div
            className="patient-appointment-tabs"
            style={{paddingBottom: "2px",marginBottom: "5px", display: props.isOR && !props.isPackage ? "flex": "none", fontWeight: "bold", fontSize: "1.2rem",  borderBottom: "solid #cccccc 1px" }}>

            <Button
              color={props.tab === "or_request" ? "blue" : undefined}
              disabled={props.detailDisabled}
              onClick={(e: any) => props.setTab("or_request")}
              size="small">
              OR Request
            </Button>
            <Button
              color={props.tab === "selectdatetimeoperation" ? "blue" : undefined}
              disabled={props.detailDisabled || props.disabledSelectDateTime}
              onClick={(e: any) => props.setTab("selectdatetimeoperation")}
              size="small">
              เลือกวันเวลา ผ่าตัด
            </Button>
            <Button
              color={props.tab === "otherorderappointment" ? "blue" : undefined}
              disabled={props.detailDisabled || props.disabledOtherOrder}
              onClick={(e: any) => props.setTab("otherorderappointment")}
              size="small">
              คำสั่งแพทย์ สำหรับผ่าตัด
            </Button>
            <div>
              {props.ORRequestMessage}
            </div>
            <div
              style={{ flex: "1",  display: "flex", alignItems: "center", justifyContent: "flex-end", fontSize: "12px", ...( !props.isPrinterLanguage && { display: "none" } ) }}>

              <label
                style={{ marginRight: "10px" }}>
                ภาษา
              </label>
              <Dropdown
                onChange={props.onChangePrinterLanguage}
                options={props.printerLanguageOptions}
                selection={true}
                style={{ width: "10px" }}
                value={props.printerLanguage}>
              </Dropdown>
            </div>
            <div
              style={{ display: "flex", justifyContent: "flex-end", padding: "0px 5px", ...( !props.isPrinterLanguage && { flex: "1" })}}>
              {props.orPrintAppointmentButton}
            </div>
          </div>
          <div
            className="patient-appointment-tabs"
            style={{marginBottom: "5px", display: props.isPackage? "flex": "none", fontWeight: "bold", fontSize: "1.2rem",  borderBottom: "solid #cccccc 1px" }}>

            <Button
              color={props.tab === "selectpackage" ? "blue" : undefined}
              onClick={(e: any) => props.setTab("selectpackage")}
              size="small">
              Package ที่เลือก
            </Button>
            <Button
              color={props.tab === "selectdatetimepackage" ? "blue" : undefined}
              disabled={props.disabledSelectDateTimePackage || false}
              onClick={(e: any) => props.setTab("selectdatetimepackage")}
              size="small">
              เลือกวันเวลานัดหมาย
            </Button>
          </div>
          <div
            style={{display: props.tab === "selectdatetimeoperation" && props.isOR ? "block" : "none" }}>

            <div
              style={{display: props.selectedAppointment ? "block" : "none" }}>
              {props.selectdatetimeopertionCard}
            </div>
          </div>
          <div
            style={{ display: props.tab === "or_request" && props.isOR  ? "block": "none", height: "95vh" }}>
            {}
            <div
              style={{display: props.selectedAppointment ? "none" : "block" }}>
              กรุณาเลือกนัดหมายคนไข้ที่ต้องการทำรายการ
            </div>
            <div
              style={{display: props.selectedAppointment ? "block" : "none" }}>
              {props.CardORRequest}
            </div>
          </div>
          <div
            style={{display: props.tab === "otherorderappointment" && props.isOR ? "block" : "none" }}>

            <div
              style={{display: props.selectedAppointment ? "block" : "none" }}>
              {props.otherOrderAppointmentCard}
            </div>
          </div>
          <div
            style={{ display: props.tab === "selectpackage" && props.isPackage? "block": "none", height: "95vh" }}>

            <div>
              {props.selectpackageCard}
            </div>
          </div>
          <div
            style={{ display: props.tab === "selectdatetimepackage" && props.isPackage? "block": "none", height: "95vh" }}>

            <div>
              {props.selectdatetimepackageCard}
            </div>
          </div>
          <div
            style={{marginBottom: "5px", display: props.isConsult && !props.isPackage ? "flex": "none", fontWeight: "bold", fontSize: "1.2rem",  borderBottom: "solid #cccccc 1px"   }}>

            <Button
              color={props.tab === "consultDetail" ? "blue" : undefined}
              onClick={(e: any) => props.setTab("consultDetail")}
              size="small">
              รายละเอียดการ Consult
            </Button>
            <Button
              color={props.tab === "consultDateTime" ? "blue" : undefined}
              disabled={!props.consultDetail?.note || !props.consultDetail?.consultDivision}
              onClick={(e: any) => props.setTab("consultDateTime")}
              size="small">
              เลือกวันเวลานัดหมาย
            </Button>
          </div>
          <div
            style={{ display: props.tab === "consultDetail" && props.isConsult? "block": "none", height: "70vh" }}>

            <div>
              {props.selectConsultDetail}
            </div>
          </div>
          <div
            style={{ display: props.tab === "consultDateTime" && props.isConsult? "block": "none", height: "85vh" }}>

            <div>
              {props.selectConsultDateTime}
            </div>
          </div>
        </div>
      </div>
    )
}


export default CardPatientAppointmentUX

export const screenPropsDefault = {"appointmentDetail":"ux ดูใน CardAppointmentDetailUX","calendar":true,"disable":false,"hide":false,"label":"click","mode":"search","providerSelector":"[Component สำหรับเลือก Provider]","reschedule":"ux ดูใน CardRescheduleUX","selectedAppointment":{},"tab":"calendar","waitingList":"ux ดูใน CardWaitingListUX"}

/* Date Time : Fri Nov 22 2024 10:41:30 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"95dvh\", display: \"flex\" }"
        }
      },
      "seq": 0
    },
    {
      "from": "semantic-ui-react",
      "id": 1,
      "name": "Button",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "brown"
        },
        "name": {
          "type": "value",
          "value": ""
        },
        "onClick": {
          "type": "code",
          "value": "props.onPatientSearch"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 55,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", gap: \"5px\"}"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 5,
      "name": "Table",
      "parent": 4,
      "props": {
        "className": {
          "type": "value",
          "value": "--rt-tbody-overflow-y-hidden"
        },
        "data": {
          "type": "code",
          "value": "props.patientList"
        },
        "defaultPageSize": {
          "type": "value",
          "value": ""
        },
        "getTrProps": {
          "type": "code",
          "value": "props.patientRowProps"
        },
        "headers": {
          "type": "value",
          "value": "HN,ชื่อ-สกุล,Citizen ID/Passport No.,เบอร์โทรติดต่อ"
        },
        "keys": {
          "type": "value",
          "value": "hn,full_name, citizen_passport, tel_num"
        },
        "minRows": {
          "type": "value",
          "value": "4"
        },
        "pageSize": {
          "type": "value",
          "value": ""
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"135px\", marginBottom: \"10px\" }"
        },
        "widths": {
          "type": "value",
          "value": "^80,^80,^120,^90"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": null,
      "id": 6,
      "name": "div",
      "parent": 22,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.tab === \"detail\" && !props.isOR && !props.isPackage  ? \"block\": \"none\", height: \"95vh\" }"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 8,
      "name": "Table",
      "parent": 7,
      "props": {
        "className": {
          "type": "value",
          "value": "--rt-tbody-overflow-y-hidden"
        },
        "data": {
          "type": "code",
          "value": "props.appointmentFilterList"
        },
        "defaultPageSize": {
          "type": "value",
          "value": ""
        },
        "getTrProps": {
          "type": "code",
          "value": "props.appointmentRowProps"
        },
        "headers": {
          "type": "code",
          "value": "props.appointmentHeaders || \"ชื่อ-สกุล,หน่วยงาน-แพทย์นัดหมาย,วันเวลานัดหมาย,Waiting List\""
        },
        "keys": {
          "type": "code",
          "value": "props.appointmentKeys || \"patient_name,detail,estimated_at,waiting_list_name\""
        },
        "minRows": {
          "type": "value",
          "value": "10"
        },
        "pageSize": {
          "type": "value",
          "value": ""
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"calc(100vh - 37rem)\", marginBottom: \"10px\" }"
        },
        "widths": {
          "type": "code",
          "value": "props.appointmentWidths || \"^90,^90,^90,^90\""
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": "คนไข้จากคำค้นหา"
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"1.2rem\", margin: \"5px\" }"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ fontWeight: \"bold\", fontSize: \"1.2rem\", margin: \"12px 0px 5px 0px\", display: \"flex\", justifyContent: \"space-between\", alignItems: \"center\" }"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{paddingBottom: \"2px\",marginBottom: \"5px\", display: props.isOR || props.isPackage || props.isConsult ? \"none\": \"flex\", fontWeight: \"bold\", fontSize: \"1.2rem\",  borderBottom: \"solid #cccccc 1px\"   }"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "patient-appointment-panel"
        },
        "style": {
          "type": "code",
          "value": "{ padding: \"5px\", flex: 1, backgroundColor: \"#d6ecf3\" }"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "นัดหมายก่อนหน้า"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "div",
      "parent": 55,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "div",
      "parent": 55,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.hideAppointmentTable ? \"none\" : \"block\" }"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 22,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "patient-appointment-content"
        },
        "id": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: 3, padding: \"0px 5px 0px 5px\", height: \"95vh\", overflow: \"auto\"}"
        }
      },
      "seq": 22,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "div",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.tab === \"calendar\" && !props.isOR && !props.isConsult? \"block\" : \"none\" }"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "div",
      "parent": 32,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"5px\",display: \"flex\", alignItems: \"center\", justifyContent: \"flex-end\"}"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 37,
      "name": "label",
      "parent": 36,
      "props": {
        "children": {
          "type": "value",
          "value": "คลีนิค"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"20px\", marginRight: \"5px\", display: props.hideFilterDivision ? \"none\" : \"\"}"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "Dropdown",
      "parent": 36,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.changeDivision"
        },
        "options": {
          "type": "code",
          "value": "props.divisionOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ display: props.hideFilterDivision ? \"none\" : \"\"} "
        },
        "value": {
          "type": "code",
          "value": "props.selectedDivisionId"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "Button",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": "รายละเอียดนัดหมาย"
        },
        "color": {
          "type": "code",
          "value": "props.tab === \"detail\" ? \"blue\" : undefined"
        },
        "disabled": {
          "type": "code",
          "value": "props.detailDisabled"
        },
        "onClick": {
          "type": "code",
          "value": "(e: any) => props.setTab(\"detail\")"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "Button",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": "เลือกวันเวลาออกตรวจ"
        },
        "color": {
          "type": "code",
          "value": "props.tab === \"calendar\" ? \"blue\" : undefined"
        },
        "disabled": {
          "type": "code",
          "value": "props.calendarDisabled"
        },
        "onClick": {
          "type": "code",
          "value": "(e: any) => props.setTab(\"calendar\")"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "Button",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": "เคลียร์"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "onClick": {
          "type": "code",
          "value": "props.clearAppointment"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "div",
      "parent": 36,
      "props": {
        "children": {
          "type": "code",
          "value": "props.providerSelector"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"25px\", width: \"300px\"}"
        }
      },
      "seq": 121,
      "void": false
    },
    {
      "from": "react-big-calendar",
      "id": 48,
      "name": "Calendar",
      "parent": 49,
      "props": {
        "endAccessor": {
          "type": "value",
          "value": "end"
        },
        "endAccessors": {
          "type": "value",
          "value": ""
        },
        "eventPropGetter": {
          "type": "code",
          "value": "props.eventPropGetter"
        },
        "events": {
          "type": "code",
          "value": "props.blockList || []"
        },
        "formats": {
          "type": "code",
          "value": "props.formats"
        },
        "localizer": {
          "type": "code",
          "value": "props.localizer"
        },
        "max": {
          "type": "code",
          "value": "props.maxTime"
        },
        "min": {
          "type": "code",
          "value": "props.minTime"
        },
        "onRangeChange": {
          "type": "code",
          "value": "props.onRangeChange"
        },
        "onSelectEvent": {
          "type": "code",
          "value": "props.onSelectEvent"
        },
        "startAccessor": {
          "type": "value",
          "value": "start"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", height: \"100%\"}"
        },
        "tooltipAccessor": {
          "type": "value",
          "value": "tooltip"
        },
        "views": {
          "type": "code",
          "value": "[\"month\", \"week\"]"
        }
      },
      "seq": 48,
      "void": true
    },
    {
      "from": null,
      "id": 49,
      "name": "div",
      "parent": 32,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{height: \"85vh\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "กรุณาเลือกนัดหมายคนไข้ที่ต้องการทำรายการ"
        },
        "style": {
          "type": "code",
          "value": "{display: props.selectedAppointment ? \"none\" : \"block\" }"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "div",
      "parent": 6,
      "props": {
        "children": {
          "type": "code",
          "value": "props.appointmentDetail"
        },
        "style": {
          "type": "code",
          "value": "{display: props.selectedAppointment ? \"block\" : \"none\" }"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.mode === \"search\" ? \"block\" : \"none\"}"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "patient-appointment-menus"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom:\"5px\", borderBottom: \"solid #cccccc 1px\"}"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "Button",
      "parent": 56,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "code",
          "value": "props.mode === \"search\" ? \"blue\" : undefined"
        },
        "onClick": {
          "type": "code",
          "value": "() => props.setMode(\"search\")"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        },
        "style": {
          "type": "code",
          "value": "{paddingRight: \"1.25em\", paddingLeft: \"1.25rem\"}"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "Button",
      "parent": 56,
      "props": {
        "children": {
          "type": "value",
          "value": "เลื่อน (คลินิก)"
        },
        "color": {
          "type": "code",
          "value": "props.mode === \"reschedule\" ? \"blue\" : undefined"
        },
        "onClick": {
          "type": "code",
          "value": "() => props.setMode(\"reschedule\")"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        },
        "style": {
          "type": "code",
          "value": "props.disabledPostponeBtn ? {display: \"none\"} : {display: \"inline-block\",paddingRight: \"1.25em\", paddingLeft: \"1.25rem\"}"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "Button",
      "parent": 56,
      "props": {
        "children": {
          "type": "value",
          "value": "Waiting List"
        },
        "color": {
          "type": "code",
          "value": "props.mode === \"waitingList\" ? \"blue\" : undefined"
        },
        "onClick": {
          "type": "code",
          "value": "() => props.setMode(\"waitingList\")"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        },
        "style": {
          "type": "code",
          "value": "props.disabledWaitingListBtn ? {display: \"none\"} : {display: \"inline-block\",paddingRight: \"1.25em\", paddingLeft: \"1.25rem\"}"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "code",
          "value": "props.reschedule"
        },
        "style": {
          "type": "code",
          "value": "{display: props.mode === \"reschedule\" ? \"block\" : \"none\"}"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": null,
      "id": 61,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "code",
          "value": "props.waitingList"
        },
        "style": {
          "type": "code",
          "value": "{display: props.mode === \"waitingList\" ? \"block\" : \"none\"}"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "Button",
      "parent": 56,
      "props": {
        "children": {
          "type": "value",
          "value": "เลื่อน (ผู้ป่วย)"
        },
        "color": {
          "type": "code",
          "value": "props.mode === \"reconfirm\" ? \"blue\" : undefined"
        },
        "onClick": {
          "type": "code",
          "value": "() => props.setMode(\"reconfirm\")"
        },
        "size": {
          "type": "value",
          "value": "tiny"
        },
        "style": {
          "type": "code",
          "value": "props.disabledPostponeBtn ? {display: \"none\"} : {display: \"inline-block\",paddingRight: \"1.25em\", paddingLeft: \"1.25rem\"}"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 63,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "code",
          "value": "props.reconfirm"
        },
        "style": {
          "type": "code",
          "value": "{display: props.mode === \"reconfirm\" ? \"block\" : \"none\"}"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 64,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", justifyContent: \"space-between\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "div",
      "parent": 64,
      "props": {
        "children": {
          "type": "value",
          "value": "แพทย์"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", flex: \"0 0 48%\"}"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": null,
      "id": 66,
      "name": "div",
      "parent": 64,
      "props": {
        "children": {
          "type": "value",
          "value": "หน่วยงาน/คลินิก"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", flex: \"0 0 48%\"}"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": null,
      "id": 67,
      "name": "div",
      "parent": 73,
      "props": {
        "children": {
          "type": "value",
          "value": "Waiting List"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", flex: \"0 0 48%\"}"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": null,
      "id": 68,
      "name": "div",
      "parent": 73,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", flex: \"0 0 48%\"}"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": null,
      "id": 69,
      "name": "div",
      "parent": 65,
      "props": {
        "children": {
          "type": "code",
          "value": "props.doctorSearch"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": null,
      "id": 70,
      "name": "div",
      "parent": 66,
      "props": {
        "children": {
          "type": "code",
          "value": "props.divisionSearch"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "Input",
      "parent": 67,
      "props": {
        "icon": {
          "type": "value",
          "value": "filter"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeWaitingListFilter"
        },
        "value": {
          "type": "code",
          "value": "props.waitingListFilter"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", justifyContent: \"space-between\", alignItems:\"flex-end\", marginBottom: \"10px\", ...(!props.isShowChair && {display: \"none\"})}"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "div",
      "parent": 36,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"20px\", padding:\"5px\", display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "Checkbox",
      "parent": 75,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.onlyNewPatient"
        },
        "label": {
          "type": "value",
          "value": "เฉพาะแพทย์ที่รับผู้ป่วยใหม่"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeOnlyNewPatient"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": null,
      "id": 78,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "code",
          "value": "props.patientSearch"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "div",
      "parent": 55,
      "props": {
        "children": {
          "type": "value",
          "value": "HN ผู้ป่วย"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"5px\" }"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "div",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "Button",
      "parent": 81,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่มนัดหมาย"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledCreatePatientAppointment"
        },
        "onClick": {
          "type": "code",
          "value": "props.createPatientAppointment"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{display : props.PopupElement ? \"none\": \"\"}"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "div",
      "parent": 81,
      "props": {
        "children": {
          "type": "code",
          "value": "props.PopupElement"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 84,
      "name": "div",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "patient-appointment-tabs"
        },
        "style": {
          "type": "code",
          "value": "{paddingBottom: \"2px\",marginBottom: \"5px\", display: props.isOR && !props.isPackage ? \"flex\": \"none\", fontWeight: \"bold\", fontSize: \"1.2rem\",  borderBottom: \"solid #cccccc 1px\" }"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "div",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.tab === \"selectdatetimeoperation\" && props.isOR ? \"block\" : \"none\" }"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": null,
      "id": 86,
      "name": "div",
      "parent": 22,
      "props": {
        "children": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.tab === \"or_request\" && props.isOR  ? \"block\": \"none\", height: \"95vh\" }"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 87,
      "name": "Button",
      "parent": 84,
      "props": {
        "children": {
          "type": "value",
          "value": "OR Request"
        },
        "color": {
          "type": "code",
          "value": "props.tab === \"or_request\" ? \"blue\" : undefined"
        },
        "disabled": {
          "type": "code",
          "value": "props.detailDisabled"
        },
        "onClick": {
          "type": "code",
          "value": "(e: any) => props.setTab(\"or_request\")"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 88,
      "name": "Button",
      "parent": 84,
      "props": {
        "children": {
          "type": "value",
          "value": "เลือกวันเวลา ผ่าตัด"
        },
        "color": {
          "type": "code",
          "value": "props.tab === \"selectdatetimeoperation\" ? \"blue\" : undefined"
        },
        "disabled": {
          "type": "code",
          "value": "props.detailDisabled || props.disabledSelectDateTime"
        },
        "onClick": {
          "type": "code",
          "value": "(e: any) => props.setTab(\"selectdatetimeoperation\")"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 89,
      "name": "Button",
      "parent": 84,
      "props": {
        "children": {
          "type": "value",
          "value": "คำสั่งแพทย์ สำหรับผ่าตัด"
        },
        "color": {
          "type": "code",
          "value": "props.tab === \"otherorderappointment\" ? \"blue\" : undefined"
        },
        "disabled": {
          "type": "code",
          "value": "props.detailDisabled || props.disabledOtherOrder"
        },
        "onClick": {
          "type": "code",
          "value": "(e: any) => props.setTab(\"otherorderappointment\")"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": null,
      "id": 90,
      "name": "div",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.tab === \"otherorderappointment\" && props.isOR ? \"block\" : \"none\" }"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 91,
      "name": "div",
      "parent": 85,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectdatetimeopertionCard"
        },
        "style": {
          "type": "code",
          "value": "{display: props.selectedAppointment ? \"block\" : \"none\" }"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": null,
      "id": 93,
      "name": "div",
      "parent": 86,
      "props": {
        "children": {
          "type": "value",
          "value": "กรุณาเลือกนัดหมายคนไข้ที่ต้องการทำรายการ"
        },
        "style": {
          "type": "code",
          "value": "{display: props.selectedAppointment ? \"none\" : \"block\" }"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "div",
      "parent": 86,
      "props": {
        "children": {
          "type": "code",
          "value": "props.CardORRequest"
        },
        "style": {
          "type": "code",
          "value": "{display: props.selectedAppointment ? \"block\" : \"none\" }"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "div",
      "parent": 90,
      "props": {
        "children": {
          "type": "code",
          "value": "props.otherOrderAppointmentCard"
        },
        "style": {
          "type": "code",
          "value": "{display: props.selectedAppointment ? \"block\" : \"none\" }"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": null,
      "id": 96,
      "name": "div",
      "parent": 84,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ORRequestMessage"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "div",
      "parent": 84,
      "props": {
        "children": {
          "type": "code",
          "value": "props.orPrintAppointmentButton"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"flex-end\", padding: \"0px 5px\", ...( !props.isPrinterLanguage && { flex: \"1\" })}"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": null,
      "id": 98,
      "name": "div",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "patient-appointment-tabs"
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"5px\", display: props.isPackage? \"flex\": \"none\", fontWeight: \"bold\", fontSize: \"1.2rem\",  borderBottom: \"solid #cccccc 1px\" }"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 99,
      "name": "Button",
      "parent": 98,
      "props": {
        "children": {
          "type": "value",
          "value": "Package ที่เลือก"
        },
        "color": {
          "type": "code",
          "value": "props.tab === \"selectpackage\" ? \"blue\" : undefined"
        },
        "onClick": {
          "type": "code",
          "value": "(e: any) => props.setTab(\"selectpackage\")"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 100,
      "name": "Button",
      "parent": 98,
      "props": {
        "children": {
          "type": "value",
          "value": "เลือกวันเวลานัดหมาย"
        },
        "color": {
          "type": "code",
          "value": "props.tab === \"selectdatetimepackage\" ? \"blue\" : undefined"
        },
        "disabled": {
          "type": "code",
          "value": "props.disabledSelectDateTimePackage || false"
        },
        "onClick": {
          "type": "code",
          "value": "(e: any) => props.setTab(\"selectdatetimepackage\")"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": null,
      "id": 101,
      "name": "div",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.tab === \"selectpackage\" && props.isPackage? \"block\": \"none\", height: \"95vh\" }"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": null,
      "id": 102,
      "name": "div",
      "parent": 101,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectpackageCard"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": null,
      "id": 103,
      "name": "div",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.tab === \"selectdatetimepackage\" && props.isPackage? \"block\": \"none\", height: \"95vh\" }"
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": null,
      "id": 104,
      "name": "div",
      "parent": 103,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectdatetimepackageCard"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": null,
      "id": 105,
      "name": "div",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{marginBottom: \"5px\", display: props.isConsult && !props.isPackage ? \"flex\": \"none\", fontWeight: \"bold\", fontSize: \"1.2rem\",  borderBottom: \"solid #cccccc 1px\"   }"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 106,
      "name": "Button",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": "รายละเอียดการ Consult"
        },
        "color": {
          "type": "code",
          "value": "props.tab === \"consultDetail\" ? \"blue\" : undefined"
        },
        "disabled": {
          "type": "code",
          "value": ""
        },
        "onClick": {
          "type": "code",
          "value": "(e: any) => props.setTab(\"consultDetail\")"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 107,
      "name": "Button",
      "parent": 105,
      "props": {
        "children": {
          "type": "value",
          "value": "เลือกวันเวลานัดหมาย"
        },
        "color": {
          "type": "code",
          "value": "props.tab === \"consultDateTime\" ? \"blue\" : undefined"
        },
        "disabled": {
          "type": "code",
          "value": "!props.consultDetail?.note || !props.consultDetail?.consultDivision"
        },
        "onClick": {
          "type": "code",
          "value": "(e: any) => props.setTab(\"consultDateTime\")"
        },
        "size": {
          "type": "value",
          "value": "small"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": null,
      "id": 108,
      "name": "div",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.tab === \"consultDetail\" && props.isConsult? \"block\": \"none\", height: \"70vh\" }"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": null,
      "id": 109,
      "name": "div",
      "parent": 22,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: props.tab === \"consultDateTime\" && props.isConsult? \"block\": \"none\", height: \"85vh\" }"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": null,
      "id": 110,
      "name": "div",
      "parent": 108,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectConsultDetail"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": null,
      "id": 111,
      "name": "div",
      "parent": 109,
      "props": {
        "children": {
          "type": "code",
          "value": "props.selectConsultDateTime"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 113,
      "name": "Dropdown",
      "parent": 36,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeSelectedDivision"
        },
        "options": {
          "type": "code",
          "value": "props.allDivisionsOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.selectedDivision"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": null,
      "id": 114,
      "name": "label",
      "parent": 36,
      "props": {
        "children": {
          "type": "code",
          "value": "<>{\"แผนก\"}<span style={{color:\"red\"}}>*</span></> "
        },
        "style": {
          "type": "code",
          "value": "{marginRight: \"5px\"}"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 117,
      "name": "div",
      "parent": 84,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ flex: \"1\",  display: \"flex\", alignItems: \"center\", justifyContent: \"flex-end\", fontSize: \"12px\", ...( !props.isPrinterLanguage && { display: \"none\" } ) }"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 118,
      "name": "Dropdown",
      "parent": 117,
      "props": {
        "fluid": {
          "type": "code",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangePrinterLanguage"
        },
        "options": {
          "type": "code",
          "value": "props.printerLanguageOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"10px\" }"
        },
        "value": {
          "type": "code",
          "value": "props.printerLanguage"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": null,
      "id": 119,
      "name": "label",
      "parent": 117,
      "props": {
        "children": {
          "type": "value",
          "value": "ภาษา"
        },
        "style": {
          "type": "code",
          "value": "{ marginRight: \"10px\" }"
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": null,
      "id": 120,
      "name": "label",
      "parent": 36,
      "props": {
        "children": {
          "type": "value",
          "value": "Specialty"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"20px\", marginRight: \"5px\"}"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 121,
      "name": "Dropdown",
      "parent": 36,
      "props": {
        "fluid": {
          "type": "code",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.changeSpecialty"
        },
        "options": {
          "type": "code",
          "value": "props.specialtyOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.specialty"
        }
      },
      "seq": 75,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 95,
  "isMounted": false,
  "memo": false,
  "name": "CardPatientAppointmentUX",
  "project": "cudent2",
  "screenPropsDefault": {
    "appointmentDetail": "ux ดูใน CardAppointmentDetailUX",
    "calendar": true,
    "disable": false,
    "hide": false,
    "label": "click",
    "mode": "search",
    "providerSelector": "[Component สำหรับเลือก Provider]",
    "reschedule": "ux ดูใน CardRescheduleUX",
    "selectedAppointment": {
    },
    "tab": "calendar",
    "waitingList": "ux ดูใน CardWaitingListUX"
  },
  "width": 90
}

*********************************************************************************** */
