import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button,
  Checkbox
} from 'semantic-ui-react'

const ModSchedulingUX = (props: any) => {
    return(
      <div
        style={{padding: "5px"}}>
        <Table
          data={props.data}
          headers="Date,Time"
          keys="date,title"
          showPagination={false}
          style={{height: "100px"}}>
        </Table>
        <div
          style={{display:"flex", justifyContent: "flex-start", alignItems: "center"}}>
          
          <Checkbox
            checked={props.inactive}
            onChange={props.changeInactive}>
          </Checkbox>
          <div
            style={{marginLeft: "5px"}}>
            งดออกตรวจ
          </div>
          <div
            style={{flex: 1}}>
            
          </div>
          <Button
            color="green"
            onClick={props.addBlock}>
            ลงตาราง
          </Button>
        </div>
      </div>
    )
}

export default ModSchedulingUX

export const screenPropsDefault = {}

/* Date Time : Mon Apr 25 2022 19:49:58 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding: \"5px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 1,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.data"
        },
        "headers": {
          "type": "value",
          "value": "Date,Time"
        },
        "keys": {
          "type": "value",
          "value": "date,title"
        },
        "minRows": {
          "type": "value",
          "value": ""
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"100px\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\", justifyContent: \"flex-start\", alignItems: \"center\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "Button",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "ลงตาราง"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "onClick": {
          "type": "code",
          "value": "props.addBlock"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "Checkbox",
      "parent": 2,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.inactive"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeInactive"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "งดออกตรวจ"
        },
        "style": {
          "type": "code",
          "value": "{marginLeft: \"5px\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 5,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "ModSchedulingUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
