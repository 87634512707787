import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Segment,
  Form,
  FormField,
  Input,
  FormGroup,
  Button,
  Dropdown,
  Label,
  TextArea
} from 'semantic-ui-react'

const CardDrugTransportationUX = (props: any) => {
    return(
      <div>
        <div
          style={{fontSize: "1.5em", fontWeight: "bold", margin: "1rem 0.5rem 1rem"}}>
          รายการจัดส่งยาภายนอก
        </div>
        <div
          style={{display: props.barOption ? "flex" : "none",background: "#0072BC"}}>
          
          <div
            style={{display:"flex",}}>
            
            <label
              style={{padding: "18px",color:"white",borderRight:" 1px solid white"}}>
              {props.totalList}
            </label>
            <button
              onClick={props.onClickUpdateStatus}
              style={{color:"white", background: "#0072BC",margin: "10px",padding:"9px",border: "1px solid #C4C4C4",borderRadius: "5px"}}>
              อัพเดตสถานะ
            </button>
            <button
              disabled={props.disableTracking}
              onClick={props.onClickTrackingOption}
              style={{color: props.disableTracking? "gray" :"white", background: "#0072BC",margin: "10px",padding:"9px",border: "1px solid #C4C4C4",borderRadius: "5px"}}>
              แก้ไข Tracking no
            </button>
          </div>
        </div>
        <Table
          columns={props.columnsItem}
          data={props.data}
          getTdProps={props.getTdProps}
          minRows={5}>
        </Table>
        <Segment>
          <Form>
            <FormGroup
              inline={true}>
              <FormField>
                <label
                  style={{fontWeight:"bold",color:"#4E97D9"}}>
                  Encounter Type : 
                </label>
                <Input
                  value={props.encounterType}>
                </Input>
              </FormField>
              <FormField>
                <label
                  style={{fontWeight:"bold",color:"#4E97D9"}}>
                  Encounter No. :
                </label>
                <Input
                  value={props.encounterNo}>
                </Input>
              </FormField>
            </FormGroup>
            <div>
              {props.divider}
            </div>
            <FormGroup
              inline={true}>
              <FormField
                width={5}>
                <label
                  style={{    width: "100px"}}>
                  วันเวลาที่จัดส่ง
                </label>
                <Label
                  size="large"
                  style={{flex: "1 0 auto", padding:".67857143em 1em"}}>
                  {props.deliveryDateTime || "-"}
                </Label>
              </FormField>
              <FormField
                width={5}>
                <label
                  style={{    width: "97px"}}>
                  วันเวลาที่รับยา
                </label>
                <Label
                  size="large"
                  style={{flex: "1 0 auto", padding:".67857143em 1em"}}>
                  {props.receivedDateTime || "-"}
                </Label>
              </FormField>
              <FormField
                width={6}>
                <label
                  style={{    width: "97px"}}>
                  เลขที่จัดส่ง
                </label>
                <Input
                  onChange={props.onChangeDeliveryNo}
                  style={{flex:"1 0 50%"}}
                  value={props.deliveryNo}>
                </Input>
                <Label
                  style={{flex:"1 0 50%", display: "none"}}>
                  {props.deliveryNo || "-"}
                </Label>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                width={5}>
                <label
                  style={{    width: "100px"}}>
                  ประเภทการจัดส่ง
                </label>
                <Label
                  size="large"
                  style={{flex: "1 0 auto",padding:".67857143em 1em"}}>
                  {props.deliveryType || "-"}
                </Label>
              </FormField>
              <FormField
                width={5}>
                <label
                  style={{    width: "97px"}}>
                  สถานะการจัดส่ง
                </label>
                <Dropdown
                  onChange={props.onChangeDeliveryStatus}
                  options={props.deliveryStatusOptions}
                  selection={true}
                  style={{flex:"1 0 auto", ...(props.readOnly && {display: "none"})}}
                  value={props.deliveryStatus}>
                </Dropdown>
                <Label
                  style={{flex:"1 0 auto", ...(!props.readOnly && {display: "none"})}}>
                  {props.deliveryStatusOptions?.find((item:any) => item.value === props.deliveryStatus)?.text || "-"}
                </Label>
              </FormField>
              <FormField
                width={6}>
                <label
                  style={{    width: "97px"}}>
                  ผู้ให้บริการจัดส่ง
                </label>
                <Dropdown
                  onChange={props.onChangeShippingName}
                  options={props.shippingCompanyOptions}
                  selection={true}
                  style={{flex:"1 0 auto"}}
                  value={props.shippingCompany}>
                </Dropdown>
                <Label
                  style={{flex:"1 0 50%",display: "none"}}>
                  {props.shippingName || "-"}
                </Label>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{alignItems: "baseline"}}>
              <FormField
                width={5}>
                <label
                  style={{    width: "100px"}}>
                  เบอร์ติดต่อ
                </label>
                <Label
                  size="large"
                  style={{flex: "1 0 auto", padding:".67857143em 1em"}}>
                  {props.telephoneNo || "-"}
                </Label>
              </FormField>
              <FormField
                width={5}>
                <label>
                  รายละเอียดที่อยู่เพิ่มเติม
                </label>
                <Label
                  size="large"
                  style={{flex: "1 0 auto", padding:".67857143em 1em", width:"calc(100% - 7.75rem)"}}>
                  {props.additionAddress || "-"}
                </Label>
              </FormField>
              <FormField
                style={{alignItems:"baseline"}}
                width={6}>
                <label
                  style={{    width: "97px"}}>
                  ที่อยู่จัดส่ง
                </label>
                <TextArea
                  disabled={props.readOnly}
                  onChange={props.onChangeDeliveryAddress}
                  style={{flex:"1 0 40%"}}
                  value={props.addressDelivery}>
                </TextArea>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                width={5}>
                <label
                  style={{    width: "100px"}}>
                  ชื่อสถานที่
                </label>
                <Label
                  size="large"
                  style={{flex: "1 0 auto", padding:".67857143em 1em"}}>
                  {props.addressName || "-"}
                </Label>
              </FormField>
              <FormField
                width={5}>
                <label
                  style={{    width: "97px"}}>
                  ชื่อผู้รับ
                </label>
                <Label
                  size="large"
                  style={{flex: "1 0 auto", padding:".67857143em 1em"}}>
                  {props.name || "-"}
                </Label>
              </FormField>
            </FormGroup>
            <FormField
              style={{textAlign: "end"}}>
              <Button
                color={props.colorSave}
                loading={props.isLoading}
                onClick={props.onSave}>
                {props.labelSave}
              </Button>
            </FormField>
            <FormGroup>
            </FormGroup>
          </Form>
        </Segment>
      </div>
    )
}


export default CardDrugTransportationUX

export const screenPropsDefault = {}

/* Date Time : Tue Nov 07 2023 10:59:31 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "รายการจัดส่งยาภายนอก"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"1.5em\", fontWeight: \"bold\", margin: \"1rem 0.5rem 1rem\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 2,
      "name": "Table",
      "parent": 0,
      "props": {
        "columns": {
          "type": "code",
          "value": "props.columnsItem"
        },
        "data": {
          "type": "code",
          "value": "props.data"
        },
        "getTdProps": {
          "type": "code",
          "value": "props.getTdProps"
        },
        "getTrProps": {
          "type": "code",
          "value": ""
        },
        "headers": {
          "type": "value",
          "value": ""
        },
        "keys": {
          "type": "value",
          "value": ""
        },
        "minRows": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "Segment",
      "parent": 0,
      "props": {
      },
      "seq": 82,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "Form",
      "parent": 3,
      "props": {
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 8,
      "name": "FormField",
      "parent": 18,
      "props": {
        "width": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "div",
      "parent": 7,
      "props": {
        "children": {
          "type": "code",
          "value": "props.divider"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "FormField",
      "parent": 7,
      "props": {
        "style": {
          "type": "code",
          "value": "{textAlign: \"end\"}"
        }
      },
      "seq": 30,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "label",
      "parent": 8,
      "props": {
        "children": {
          "type": "value",
          "value": "Encounter Type : "
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bold\",color:\"#4E97D9\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "Input",
      "parent": 8,
      "props": {
        "value": {
          "type": "code",
          "value": "props.encounterType"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "FormGroup",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "FormField",
      "parent": 18,
      "props": {
      },
      "seq": 19,
      "void": false
    },
    {
      "from": null,
      "id": 20,
      "name": "label",
      "parent": 19,
      "props": {
        "children": {
          "type": "value",
          "value": "Encounter No. :"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight:\"bold\",color:\"#4E97D9\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 21,
      "name": "Input",
      "parent": 19,
      "props": {
        "value": {
          "type": "code",
          "value": "props.encounterNo"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "Button",
      "parent": 14,
      "props": {
        "children": {
          "type": "code",
          "value": "props.labelSave"
        },
        "color": {
          "type": "code",
          "value": "props.colorSave"
        },
        "icon": {
          "type": "value",
          "value": ""
        },
        "loading": {
          "type": "code",
          "value": "props.isLoading"
        },
        "onClick": {
          "type": "code",
          "value": "props.onSave"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "FormGroup",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "FormGroup",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "FormGroup",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{alignItems: \"baseline\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 30,
      "name": "FormGroup",
      "parent": 7,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "FormGroup",
      "parent": 7,
      "props": {
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 32,
      "name": "FormField",
      "parent": 27,
      "props": {
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "FormField",
      "parent": 27,
      "props": {
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "FormField",
      "parent": 27,
      "props": {
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 35,
      "name": "FormField",
      "parent": 28,
      "props": {
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 36,
      "name": "FormField",
      "parent": 28,
      "props": {
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "FormField",
      "parent": 28,
      "props": {
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 38,
      "name": "FormField",
      "parent": 29,
      "props": {
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "FormField",
      "parent": 29,
      "props": {
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "FormField",
      "parent": 29,
      "props": {
        "style": {
          "type": "code",
          "value": "{alignItems:\"baseline\"}"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 41,
      "name": "FormField",
      "parent": 30,
      "props": {
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "FormField",
      "parent": 30,
      "props": {
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "label",
      "parent": 32,
      "props": {
        "children": {
          "type": "value",
          "value": "วันเวลาที่จัดส่ง"
        },
        "style": {
          "type": "code",
          "value": "{    width: \"100px\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": null,
      "id": 45,
      "name": "label",
      "parent": 33,
      "props": {
        "children": {
          "type": "value",
          "value": "วันเวลาที่รับยา"
        },
        "style": {
          "type": "code",
          "value": "{    width: \"97px\"}"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "label",
      "parent": 34,
      "props": {
        "children": {
          "type": "value",
          "value": "เลขที่จัดส่ง"
        },
        "style": {
          "type": "code",
          "value": "{    width: \"97px\"}"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "label",
      "parent": 35,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภทการจัดส่ง"
        },
        "style": {
          "type": "code",
          "value": "{    width: \"100px\"}"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "label",
      "parent": 36,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะการจัดส่ง"
        },
        "style": {
          "type": "code",
          "value": "{    width: \"97px\"}"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "label",
      "parent": 37,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ให้บริการจัดส่ง"
        },
        "style": {
          "type": "code",
          "value": "{    width: \"97px\"}"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "label",
      "parent": 38,
      "props": {
        "children": {
          "type": "value",
          "value": "เบอร์ติดต่อ"
        },
        "style": {
          "type": "code",
          "value": "{    width: \"100px\"}"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": null,
      "id": 51,
      "name": "label",
      "parent": 39,
      "props": {
        "children": {
          "type": "value",
          "value": "รายละเอียดที่อยู่เพิ่มเติม"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "label",
      "parent": 40,
      "props": {
        "children": {
          "type": "value",
          "value": "ที่อยู่จัดส่ง"
        },
        "style": {
          "type": "code",
          "value": "{    width: \"97px\"}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "label",
      "parent": 41,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อสถานที่"
        },
        "style": {
          "type": "code",
          "value": "{    width: \"100px\"}"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "label",
      "parent": 42,
      "props": {
        "children": {
          "type": "value",
          "value": "ชื่อผู้รับ"
        },
        "style": {
          "type": "code",
          "value": "{    width: \"97px\"}"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 57,
      "name": "Input",
      "parent": 34,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeDeliveryNo"
        },
        "style": {
          "type": "code",
          "value": "{flex:\"1 0 50%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.deliveryNo"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "Dropdown",
      "parent": 36,
      "props": {
        "fluid": {
          "type": "code",
          "value": ""
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeDeliveryStatus"
        },
        "options": {
          "type": "code",
          "value": "props.deliveryStatusOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{flex:\"1 0 auto\", ...(props.readOnly && {display: \"none\"})}"
        },
        "value": {
          "type": "code",
          "value": "props.deliveryStatus"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "Label",
      "parent": 32,
      "props": {
        "children": {
          "type": "code",
          "value": "props.deliveryDateTime || \"-\""
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{flex: \"1 0 auto\", padding:\".67857143em 1em\"}"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "Label",
      "parent": 33,
      "props": {
        "children": {
          "type": "code",
          "value": "props.receivedDateTime || \"-\""
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{flex: \"1 0 auto\", padding:\".67857143em 1em\"}"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "Label",
      "parent": 38,
      "props": {
        "children": {
          "type": "code",
          "value": "props.telephoneNo || \"-\""
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{flex: \"1 0 auto\", padding:\".67857143em 1em\"}"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "Label",
      "parent": 39,
      "props": {
        "children": {
          "type": "code",
          "value": "props.additionAddress || \"-\""
        },
        "name": {
          "type": "code",
          "value": ""
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{flex: \"1 0 auto\", padding:\".67857143em 1em\", width:\"calc(100% - 7.75rem)\"}"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "Label",
      "parent": 35,
      "props": {
        "children": {
          "type": "code",
          "value": "props.deliveryType || \"-\""
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{flex: \"1 0 auto\",padding:\".67857143em 1em\"}"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "Label",
      "parent": 41,
      "props": {
        "children": {
          "type": "code",
          "value": "props.addressName || \"-\""
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{flex: \"1 0 auto\", padding:\".67857143em 1em\"}"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "Label",
      "parent": 42,
      "props": {
        "children": {
          "type": "code",
          "value": "props.name || \"-\""
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{flex: \"1 0 auto\", padding:\".67857143em 1em\"}"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 77,
      "name": "TextArea",
      "parent": 40,
      "props": {
        "disabled": {
          "type": "code",
          "value": "props.readOnly"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeDeliveryAddress"
        },
        "rows": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{flex:\"1 0 40%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.addressDelivery"
        }
      },
      "seq": 77,
      "void": true
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "Label",
      "parent": 37,
      "props": {
        "children": {
          "type": "code",
          "value": "props.shippingName || \"-\""
        },
        "style": {
          "type": "code",
          "value": "{flex:\"1 0 50%\",display: \"none\"}"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 79,
      "name": "Label",
      "parent": 34,
      "props": {
        "children": {
          "type": "code",
          "value": "props.deliveryNo || \"-\""
        },
        "style": {
          "type": "code",
          "value": "{flex:\"1 0 50%\", display: \"none\"}"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 80,
      "name": "Label",
      "parent": 36,
      "props": {
        "children": {
          "type": "code",
          "value": "props.deliveryStatusOptions?.find((item:any) => item.value === props.deliveryStatus)?.text || \"-\""
        },
        "style": {
          "type": "code",
          "value": "{flex:\"1 0 auto\", ...(!props.readOnly && {display: \"none\"})}"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 81,
      "name": "Dropdown",
      "parent": 37,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeShippingName"
        },
        "options": {
          "type": "code",
          "value": "props.shippingCompanyOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{flex:\"1 0 auto\"}"
        },
        "value": {
          "type": "code",
          "value": "props.shippingCompany"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 82,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: props.barOption ? \"flex\" : \"none\",background: \"#0072BC\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 83,
      "name": "div",
      "parent": 82,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display:\"flex\",}"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": null,
      "id": 84,
      "name": "label",
      "parent": 83,
      "props": {
        "children": {
          "type": "code",
          "value": "props.totalList"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"18px\",color:\"white\",borderRight:\" 1px solid white\"}"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "button",
      "parent": 83,
      "props": {
        "children": {
          "type": "value",
          "value": "แก้ไข Tracking no"
        },
        "disabled": {
          "type": "code",
          "value": "props.disableTracking"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickTrackingOption"
        },
        "style": {
          "type": "code",
          "value": "{color: props.disableTracking? \"gray\" :\"white\", background: \"#0072BC\",margin: \"10px\",padding:\"9px\",border: \"1px solid #C4C4C4\",borderRadius: \"5px\"}"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "button",
      "parent": 83,
      "props": {
        "children": {
          "type": "value",
          "value": "อัพเดตสถานะ"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickUpdateStatus"
        },
        "style": {
          "type": "code",
          "value": "{color:\"white\", background: \"#0072BC\",margin: \"10px\",padding:\"9px\",border: \"1px solid #C4C4C4\",borderRadius: \"5px\"}"
        }
      },
      "seq": 87,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardDrugTransportationUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
