import React, { useState, useEffect, useImperativeHandle } from "react";
import { Dropdown, Form, Modal } from "semantic-ui-react";

// UX
import CardDrugClaimQAUX from "react-lib/apps/HISV3/TPD/CardDrugClaimQAUX";
import { useIntl } from "react-intl";

const CardSupplyOrderQA: React.FunctionComponent<any> = (props: any) => {

  const handleChangeAnswerDict = (answer: any) => {

    props.runSequence({
      sequence: "OrderSupply",
      action: "setAnswerSupplyOrderItem",
      // index: props.index,
      answer: answer,
      supplyData: props.supplyQA
    });
  };

  const handleClose = (answer: any) => {
    props.runSequence({
      sequence: "OrderSupply",
      action: "answerClose",
      // index: props.index,
      supplyData: props.supplyQA
    });
  };

  const handleSave = (answer: any) => {
    props.runSequence({
      sequence: "OrderSupply",
      action: "answerSave",
      // index: props.index,
      supplyData: props.supplyQA,
    });
  };

  return (
    <Modal open={!props.supplyQA?.isConfirm}>
      <div>
        <CardDrugClaimQAUX
          title={props.supplyQA?.label}
          disabledSubQATab={true}
          subQAField={
            <SubQAField
              questions={props.supplyQA?.questions || []}
              currentPayload={props.supplyQA}
              onChangeAnswerDict={handleChangeAnswerDict}
              statusRequest={props.statusRequest}
              languageUX={props.languageUX}
            />
          }
          onSave={handleSave}
          onClose={handleClose}
          statusRequest={props.statusRequest}
          languageUX={props.languageUX}
          languageUX={props.languageUX}
        />
      </div>
    </Modal>
  );
};

const SubQAField = React.forwardRef<any, any>((props, ref) => {
  const [answerDict, setAnswerDict] = useState<Record<string, any>>({});

  useEffect(() => {
    setAnswerDict(props.currentPayload?.["answer_dict"] || {});
  }, [props.currentPayload?.["answer_dict"]]);

  const handleChange = (e: any, v: any) => {
    let checked: string = v.checked?.toString();
    if (typeof v.checked === "boolean") {
      checked = `${checked[0].toUpperCase()}${checked.slice(1)}`;
    }

    const dict = {
      ...answerDict,
      [v.name]: typeof v.checked === "boolean" ? checked : v.value,
    };

    setAnswerDict(dict);
    props.onChangeAnswerDict?.(dict);
  };

  return (
    <Form>
      {props.questions.map((item: any) => (
        <SubQAType
          label={item.label}
          type={item.type}
          answer={answerDict[item.id]}
          choices={item.choices}
          dataValidateKey={item.id}
          questionId={item.id}
          default_answer={item.default_answer}
          statusRequest={props.statusRequest}
          onChange={handleChange}
          languageUX={props.languageUX}
        />
      ))}
    </Form>
  );
});

const CHECK_PARSE: Record<string, boolean> = {
  True: true,
  False: false,
};

const SubQAType: React.FunctionComponent<any> = (props) => {
  const mapOptions = (list: any[]) => {
    return list.map((item: any) => ({
      key: item.id,
      text: item.name,
      value: item.id,
    }));
  };

  return (
    <>
      {props.type === "CHOICE" ? (
        <Form.Dropdown
          value={
            (typeof props.choices?.[0]?.id === "number"
              ? Number(props.answer)
              : props.answer) || ""
          }
          label={props.label}
          placeholder="กรุณาระบุ"
          options={mapOptions(props.choices)}
          selection
          style={{ width: "auto" }}
          name={props.dataValidateKey}
          onChange={props.onChange}
          disabled={props.statusRequest ? true : false}
        />
      ) : props.type === "BOOLEAN" ? (
        // <Form.Checkbox
        //   checked={CHECK_PARSE[props.answer]}
        //   label={props.label}
        //   name={props.dataValidateKey}
        //   onChange={props.onChange}
        //   readOnly={props.statusRequest ? true : false}
        // />
        <Form.Dropdown
          value={
            (typeof props.choices?.[0]?.id === "number"
              ? Number(props.answer)
              : props.answer) || ""
          }
          label={props.label}
          placeholder="กรุณาระบุ"
          options={mapOptions(props.choices)}
          selection
          style={{ width: "auto" }}
          name={props.dataValidateKey}
          onChange={props.onChange}
          disabled={props.statusRequest ? true : false}
        />
      ) : props.type === "DECIMAL" ? (
        <Form.Input
          value={props.answer || ""}
          type="number"
          name={props.dataValidateKey}
          onChange={props.onChange}
          readOnly={props.statusRequest ? true : false}
        />
      ) : props.type === "TEXT" ? (
        <Form.Input
          value={props.answer || ""}
          name={props.dataValidateKey}
          onChange={props.onChange}
          readOnly={props.statusRequest ? true : false}
        />
      ) : null}
    </>
  );
};

export default React.memo(CardSupplyOrderQA);
