import HeaderSapiensForm from "react-lib/apps/HISV3/DPO/sequence/HeaderSapiensForm";

const color = {
  greyHeader: "#D9D9D9",
  greySummary: "#E8E8E8",
  greyBorder: "#9F9F9F",
};

type FormSapiensReceiptProps = {
  name?: any;
  time?: any;
  code?: string;
  created?: string;
  createdTime?: string;
  hn?: string;
  patient_name?: string;
  // data
  fields?: Record<string, any>[];
  // summary
  totalNonClaimable?: any;
  totalSelfReimbursement?: any;
  totalAmount?: any;
  total_amount_text?: string;
  total_amount_textEN?: string;
  paymentTypeSapient?: string;
  patientNameEN?: string;
  price_text?: string;
  nonclaimable_sum?: string;
  discount_sum?: string;
  net_sum?: string;
  remark?: string;
  cashier?: string;
  isWatermark?: boolean;
  payment_type?: string;
  receiptType?: string;
  wallet_receipt_codes?: string;
  wallet_deposit_code?: string;
  cancelled_code?: string;
  encounter?: string;
  doctor?: string;
  visit_date?: string;
  coverage?: string;
  discharge_date?: string;
  division?: string;
  pageMargins?: any;
  isPayment?: boolean;
  divisionEN?: string;
  coverageEN?: string;
  priceTextEN?: string;
};

const FormSapiensReceipt = async (props: FormSapiensReceiptProps) => {
  var origin = window?.location?.origin;

  const formatPrice = (number: number) => {
    return Number(number).toLocaleString("en-US", {
      style: "decimal",
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  };
  const headerSapiensForm = await HeaderSapiensForm({
    logoHight: 24,
    pageMargins: props?.pageMargins || [0, 284, 0, 225],
    hospitalAddress: {
      relativePosition: { x: -10, y: 0 },
      table: {
        body: [
          [
            {
              lineHeight: 1,
              alignment: "left",
              border: [false, false, true, false],
              stack: [
                {
                  font: "KanitLM",
                  style: "hospitalName",
                  text: "โรงพยาบาลเซเปี้ยนซ์",
                },
                {
                  font: "KanitLM",
                  style: "hospitalInfo",
                  text: "บริษัท เซเปี้ยนซ์ เมดิเคชั่น จำกัด",
                },
                {
                  font: "KanitLM",
                  style: "hospitalInfo",
                  text: "สำนักงานใหญ่ 498/7 ซอยรามคำแหง 39 (เทพลีลา 1)",
                },
                {
                  font: "KanitLM",
                  style: "hospitalInfo",
                  text: "แขวงวังทองหลาง เขตวังทองหลาง",
                },
                {
                  font: "KanitLM",
                  style: "hospitalInfo",
                  text: "กรุงเทพมหานคร 10310",
                },
                {
                  font: "KanitLM",
                  style: "hospitalInfo",
                  text: "โทร +(662) 111-3703",
                },
                {
                  font: "KanitLM",
                  style: "hospitalInfo",
                  text: "เลขประจำตัวผู้เสียภาษี 0105563159409",
                },
              ],
            },
            {
              lineHeight: 1.05,
              alignment: "left",
              margin: [5, 0, 0, 0],
              border: [true, false, false, false],
              stack: [
                {
                  font: "Poppins",
                  style: "hospitalName",
                  text: "Sapiens Pain Hospital",
                },
                {
                  font: "Poppins",
                  style: "hospitalInfo",
                  text: "Sapiens Medication Co.,Ltd.",
                },
                {
                  font: "Poppins",
                  style: "hospitalInfo",
                  text: "Head Office 498/7 Soi Ramkhamhaeng 39 (Thepleela 1)",
                },
                {
                  font: "Poppins",
                  style: "hospitalInfo",
                  text: "Wang Thonglang, Wang Thonglang,",
                },
                {
                  font: "Poppins",
                  style: "hospitalInfo",
                  text: "Bangkok 10310",
                },
                {
                  font: "Poppins",
                  style: "hospitalInfo",
                  text: "Tel +(662) 111-3703",
                },
                {
                  font: "Poppins",
                  style: "hospitalInfo",
                  text: "Tax ID No. 0105563159409",
                },
              ],
            },
          ],
        ],
      },
      width: 380,
    },
    header: {
      margin: [-8, 0, 0, 0],
      stack: [
        {
          margin: [0, 15, 0, 0],
          table: {
            widths: [345, "*"],
            body: [
              [
                {
                  text: [
                    {
                      text:
                        props.receiptType === "refund"
                          ? "ใบคืนเงินมัดจำ"
                          : props.receiptType === "receipt"
                          ? "ใบเสร็จรับเงิน"
                          : props.receiptType === "deposit"
                          ? "ใบรับเงินมัดจำ"
                          : props.receiptType === "invoice" && "ใบแจ้งค่ารักษาพยาบาล",
                      font: "KanitLM",
                      style: {
                        bold: true,
                        fontSize: 10,
                      },
                    },
                    {
                      text:
                        props.receiptType === "refund"
                          ? "(DEPOSIT REFUND)"
                          : props.receiptType === "receipt"
                          ? "(RECEIPT)"
                          : props.receiptType === "deposit"
                          ? "(DEPOSIT)"
                          : props.receiptType === "invoice" && "(INVOICE)",
                      font: "PoppinsLM",
                      style: {
                        bold: true,
                        fontSize: 10,
                      },
                    },
                  ],
                  alignment: "right",
                  border: [false, false, false, false],
                },
                {
                  stack: [
                    {
                      text: props.isWatermark ?  [
                        {
                          text: "สำเนา",
                          font: "KanitLM",
                          style: "receiptHeader",
                        },
                        {
                          text: "/Copy",
                          font: "PoppinsLM",
                          style: "receiptHeader",
                        },
                      ] : [
                        {
                          text: "ต้นฉบับ",
                          font: "KanitLM",
                          style: "receiptHeader",
                        },
                        {
                          text: "/Original",
                          font: "PoppinsLM",
                          style: "receiptHeader",
                        },
                      ]
                    },
                    {
                      text: [
                        {
                          text: "เลขที่",
                          font: "KanitLM",
                          style: "receiptHeader",
                        },
                        {
                          text:
                            props.receiptType === "deposit" || props.receiptType === "refund"
                              ? "/Doc No.  "
                              : "/Receipt No.  ",
                          font: "PoppinsLM",
                          style: "receiptHeader",
                        },
                        {
                          text:
                            props.receiptType === "deposit"
                              ? props?.wallet_deposit_code
                              : props.receiptType === "refund"
                              ? props.cancelled_code
                              : props.code,
                          font: "PoppinsLM",
                          style: "receiptHeaderNumber",
                        },
                      ],
                    }
                  ],
                  alignment: "right",
                  border: [false, false, false, false],
                  margin: [0, 3, 20, 0],
                },
              ],
              [
                { text: "", border: [false, false, false, false] },
                { text: "", border: [false, false, false, false] },
              ],
              [
                { text: "", border: [false, false, false, false] },
                { text: "", border: [false, false, false, false] },
              ],
            ],
          },
        },
        {
          table: {
            widths: [110, 215, 70, "*"],
            body: [
              [
                {
                  text: [
                    {
                      text: "เลขประจำตัวผู้ป่วย ",
                      font: "KanitLM",
                      style: "receiptHeader",
                    },
                    {
                      text: "/ HN :",
                      font: "PoppinsLM",
                      style: "receiptHeader",
                    },
                  ],
                  alignment: "letf",
                  border: [false, false, false, false],
                  margin: [20, 0, 0, 0],
                },
                {
                  text: [
                    {
                      text: props.hn,
                      font: "PoppinsLM",
                      style: "receiptInfo",
                    },
                  ],
                  alignment: "left",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                },
                {
                  text: [
                    {
                      text: "วันที่ ",
                      font: "KanitLM",
                      style: "receiptHeader",
                    },
                    {
                      text: "/ Date :",
                      font: "PoppinsLM",
                      style: "receiptHeader",
                    },
                  ],
                  alignment: "letf",
                  border: [false, false, false, false],
                  margin: [20, 0, 0, 0],
                },
                {
                  columns: [
                    {
                      text: props.created,
                      font: "KanitLM",
                      style: "receiptInfo",
                      width: "auto",
                    },
                    {
                      text: (props.time && `${props.time} น.`) || "",
                      font: "KanitLM",
                      style: "receiptInfo",
                      width: "*",
                      margin: [10, 0, 0, 0],
                    },
                  ],
                  alignment: "left",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                },
              ],
              [
                {
                  text: [
                    {
                      text: "ชื่อ ",
                      font: "KanitLM",
                      style: "receiptHeader",
                    },
                    {
                      text: "/ Patient Name :",
                      font: "PoppinsLM",
                      style: "receiptHeader",
                    },
                  ],
                  alignment: "letf",
                  border: [false, false, false, false],
                  margin: [20, 0, 0, 0],
                },
                {
                  text: [
                    {
                      text: `${props.name}  ${props.patientNameEN || ""}`,
                      font: "KanitLM",
                      style: "receiptInfo",
                    },
                    {
                      text: "",
                      font: "PoppinsLM",
                      style: "receiptInfo",
                    },
                  ],
                  alignment: "left",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                },
                {
                  text: [
                    {
                      text: "EN ",
                      font: "PoppinsLM",
                      style: "receiptHeader",
                    },
                    {
                      text: "/ AN :",
                      font: "PoppinsLM",
                      style: "receiptHeader",
                    },
                  ],
                  alignment: "letf",
                  border: [false, false, false, false],
                  margin: [20, 0, 0, 0],
                },
                {
                  text: [
                    {
                      text: props.encounter,
                      font: "KanitLM",
                      style: "receiptInfo",
                    },
                  ],
                  alignment: "left",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                },
              ],
            ],
          },
        },
        {
          table: {
            widths: [110, 215, 140, "*"],
            body: [
              [
                {
                  text: [
                    {
                      text: "แพทย์ ",
                      font: "KanitLM",
                      style: "receiptHeader",
                    },
                    {
                      text: "/ Doctor Name :",
                      font: "PoppinsLM",
                      style: "receiptHeader",
                    },
                  ],
                  alignment: "letf",
                  border: [false, false, false, false],
                  margin: [20, 0, 0, 0],
                },
                {
                  text: `${props.doctor}`,
                  font: "KanitLM",
                  style: "receiptInfo",
                  alignment: "left",
                  border: [false, false, false, false],
                },
                {
                  text: [
                    {
                      text: "วันที่เข้ารับการรักษา ",
                      font: "KanitLM",
                      style: "receiptHeader",
                    },
                    {
                      text: "/ Visit Date :",
                      font: "PoppinsLM",
                      style: "receiptHeader",
                    },
                  ],
                  alignment: "letf",
                  border: [false, false, false, false],
                  margin: [20, 0, 0, 0],
                },
                {
                  text: props.visit_date,
                  font: "KanitLM",
                  style: "receiptInfo",
                  alignment: "left",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                },
              ],
              [
                {
                  text: [
                    {
                      text: "สิทธิรักษา ",
                      font: "KanitLM",
                      style: "receiptHeader",
                    },
                    {
                      text: "/ Coverage :",
                      font: "PoppinsLM",
                      style: "receiptHeader",
                    },
                  ],
                  alignment: "letf",
                  border: [false, false, false, false],
                  margin: [20, 0, 0, 0],
                },
                {
                  text: props.coverageEN
                    ? `${props.coverage} (${props.coverageEN})`
                    : `${props.coverage}`,
                  font: "KanitLM",
                  style: "receiptInfo",
                  alignment: "left",
                  border: [false, false, false, false],
                },
                {
                  text: [
                    {
                      text: "วันที่จำหน่าย ",
                      font: "KanitLM",
                      style: "receiptHeader",
                    },
                    {
                      text: "/ Discharge Date :",
                      font: "PoppinsLM",
                      style: "receiptHeader",
                    },
                  ],
                  alignment: "letf",
                  border: [false, false, false, false],
                  margin: [20, 0, 0, 0],
                },
                {
                  text: props.discharge_date,
                  font: "KanitLM",
                  style: "receiptInfo",
                  alignment: "left",
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 0],
                },
              ],
            ],
          },
        },
        {
          table: {
            widths: ["auto", "*"],
            body: [
              [
                {
                  text: [
                    {
                      text: "แผนก ",
                      font: "KanitLM",
                      style: "receiptHeader",
                    },
                    {
                      text: "/ Division :",
                      font: "PoppinsLM",
                      style: "receiptHeader",
                    },
                  ],
                  alignment: "letf",
                  border: [false, false, false, false],
                  margin: [20, 0, 0, 0],
                },
                {
                  text: props.divisionEN
                    ? `${props.division} (${props.divisionEN})`
                    : `${props.division}`,
                  font: "KanitLM",
                  style: "receiptInfo",
                  alignment: "left",
                  border: [false, false, false, false],
                },
              ],
            ],
          },
        },
        {
          margin: [0, 12, 7, 0],
          table: {
            widths: [315, "*", "*", "*"],
            body: [
              [
                {
                  text: [
                    {
                      text: "รายการ",
                      font: "KanitLM",
                      style: "receiptHeader",
                    },
                    {
                      text: "/Description",
                      font: "PoppinsLM",
                      style: "receiptHeader",
                    },
                  ],
                  alignment: "center",
                  fillColor: color.greyHeader,
                  position: "absolute",
                  relativePosition: { y: 14 },
                  rowSpan: 2,
                },
                {
                  text: [
                    {
                      text: "จำนวนเงิน(บาท) ",
                      font: "KanitLM",
                      style: "receiptHeader",
                    },
                    {
                      text: "/Amount (Baht)",
                      font: "PoppinsLM",
                      style: "receiptHeader",
                    },
                  ],
                  alignment: "center",
                  fillColor: color.greyHeader,
                  colSpan: 3,
                },
                {},
                {},
              ],
              [
                {},
                {
                  stack: [
                    {
                      text: "จำนวนเงิน",
                      font: "Kanit",
                      style: "tableData",
                    },
                    {
                      text: "(Total Amount)",
                      font: "Poppins",
                      style: "tableData",
                    },
                  ],
                  alignment: "center",
                  fillColor: color.greyHeader,
                },
                {
                  stack: [
                    {
                      text: "ส่วนลด",
                      font: "Kanit",
                      style: "tableData",
                    },
                    {
                      text: "(Discount)",
                      font: "Poppins",
                      style: "tableData",
                    },
                  ],
                  alignment: "center",
                  fillColor: color.greyHeader,
                },
                {
                  stack: [
                    {
                      text: "จำนวนเงินสุทธิ",
                      font: "Kanit",
                      style: "tableData",
                    },
                    {
                      text: "(Net Amount)",
                      font: "Poppins",
                      style: "tableData",
                    },
                  ],
                  alignment: "center",
                  fillColor: color.greyHeader,
                },
              ],
            ],
          },
          layout: {
            hLineColor: function (i: any, node: any) {
              return color.greyBorder;
            },
            vLineColor: function (i: any, node: any) {
              return color.greyBorder;
            },
          },
        },
      ],
    },
    styles: {
      receiptHeader: {
        bold: true,
        fontSize: 8,
      },
      receiptInfo: {
        fontSize: 8,
      },
      receiptHeaderNumber: {
        bold: true,
        fontSize: 8,
        color: "#095E9D",
      },
    },
  });

  let fieldsLength =
    (props.fields || []).length > 10
      ? (props.fields || []).length % 10
      : (props.fields || []).length;
  let numberRows = 10 - fieldsLength;

  const rowEmpty = Array.from({ length: numberRows }).map((item: any, index: number) => [
    {
      stack: [
        {
          text: " ",
          font: "KanitLM",
          style: "tableData",
        },
        {
          text: " ",
          font: "PoppinsLM",
          style: "tableData",
        },
      ],
      margin: [10, 0, 0, 5],
      border: [true, false, true, false],
    },
    {
      text: " ",
      margin: [0, 0, 3, 5],
      border: [true, false, true, false],
    },
    {
      text: " ",
      margin: [0, 0, 3, 5],
      border: [true, false, true, false],
    },
    {
      text: " ",
      margin: [0, 0, 3, 5],
      border: [true, false, true, false],
    },
  ]);

  const fileData = (props.fields || []).map((item: any, index: number) => {
    let bilModeName = item.bill_mode__name.split(" (");
    let bilModeNameEn = [""];
    if (bilModeName?.[1].split(")")) {
      bilModeNameEn = bilModeName?.[1].split(")");
    }

    return [
      {
        // pageBreak: (index + 1) % 7 === 0 && 'after',
        stack: [
          {
            text: bilModeName?.[0] || "",
            font: "KanitLM",
            style: "tableData",
          },
          {
            text: bilModeNameEn?.[0] ? `(${bilModeNameEn?.[0]})` || "\n" : "",
            font: "PoppinsLM",
            style: "tableData",
          },
        ],
        margin: [10, 0, 0, 5],
        border: [true, false, true, false],
      },
      {
        stack: [
          {
            text: item.nonclaimable__sum,
            font: "KanitLM",
            style: "tableData",
          },
          {
            text: "\n",
            font: "PoppinsLM",
            style: "tableData",
          },
        ],
        font: "Poppins",
        style: "tableData",
        alignment: "right",
        margin: [0, 0, 3, 5],
        border: [true, false, true, false],
      },
      {
        stack: [
          {
            text: item?.discount__sum,
            font: "KanitLM",
            style: "tableData",
          },
          {
            text: "\n",
            font: "PoppinsLM",
            style: "tableData",
          },
        ],
        font: "Poppins",
        style: "tableData",
        alignment: "right",
        margin: [0, 0, 3, 5],
        border: [true, false, true, false],
      },
      {
        stack: [
          {
            text: item?.net__sum,
            font: "KanitLM",
            style: "tableData",
          },
          {
            text: "\n",
            font: "PoppinsLM",
            style: "tableData",
          },
        ],
        font: "Poppins",
        style: "tableData",
        alignment: "right",
        margin: [0, 0, 3, 5],
        border: [true, false, true, false],
      },
    ];
  });

  return {
    pageSize: "A4",
    ...headerSapiensForm,
    watermark: {
      text: props.isWatermark ? "สำเนา" : "",
      font: "KanitLM",
      opacity: 0.2,
      bold: true,
      angle: -15,
      fontSize: 70,
    },
    background: [
      props.isPayment
        ? {
            image: "logo",
            opacity: 0.18,
            width: 400,
            alignment: "center",
            absolutePosition: { y: 400 },
          }
        : {},
    ],
    content: [
      {
        margin: [7, 0, 7, 0],
        stack: [
          //data
          {
            table: {
              widths: [315, "*", "*", "*"],
              body: [...fileData, ...rowEmpty],
            },
            layout: {
              hLineColor: function (i: any, node: any) {
                return color.greyBorder;
              },
              vLineColor: function (i: any, node: any) {
                return color.greyBorder;
              },
            },
          },
        ],
      },
    ],
    styles: {
      ...headerSapiensForm.styles,
      tableData: { fontSize: 8 },
      tableHeaderSummary: {
        bold: true,
        fontSize: 8,
      },
      footerSignature: { fontSize: 9 },
      footerData: { bold: true, fontSize: 6 },
    },
    footer: function (currentPage: number, pageCount: number) {
      return {
        margin: [7, 0, 7, 0],
        stack: [
          {
            table: {
              widths: [315, "*", "*", "*"],
              body: [
                [
                  {
                    text: [
                      {
                        text: "รวมทั้งสิ้น ",
                        font: "KanitLM",
                        style: "tableHeaderSummary",
                      },
                      {
                        text: "/Total",
                        font: "PoppinsLM",
                        style: "tableHeaderSummary",
                      },
                    ],
                    margin: [10, 5, 0, 5],
                    border: [true, true, false, false],
                  },
                  {
                    text: props.nonclaimable_sum || "0.00",
                    font: "Poppins",
                    style: "tableData",
                    alignment: "right",
                    margin: [0, 5, 3, 5],
                    border: [false, true, false, false],
                  },
                  {
                    text: props.discount_sum || "0.00",
                    font: "Poppins",
                    style: "tableData",
                    alignment: "right",
                    margin: [0, 5, 3, 5],
                    border: [false, true, false, false],
                  },
                  {
                    text: props.net_sum || "0.00",
                    font: "Poppins",
                    style: "tableData",
                    alignment: "right",
                    margin: [0, 5, 3, 5],
                    border: [false, true, true, false],
                  },
                ],
              ],
            },
            layout: {
              hLineColor: function (i: any, node: any) {
                return color.greyBorder;
              },
              vLineColor: function (i: any, node: any) {
                return color.greyBorder;
              },
            },
          },
          {
            table: {
              widths: [props.receiptType === "invoice" ? 160 : 140, "*", 10],
              body: [
                [
                  {
                    text: [
                      {
                        text:
                          props.receiptType === "invoice"
                            ? "รับชำระด้วยเงินมัดจำเลขที่ "
                            : "ตัวอักษร ",
                        font: "KanitLM",
                        style: "tableHeaderSummary",
                      },
                      {
                        text: props.receiptType === "invoice" ? "/Deposit No." : "/Alphabet",
                        font: "PoppinsLM",
                        style: "tableHeaderSummary",
                      },
                    ],
                    margin: [10, 0, 0, 0],
                    border: [true, false, false, false],
                  },
                  {
                    stack:
                      props.receiptType === "invoice"
                        ? [
                            {
                              text: props.wallet_receipt_codes,
                              font: "KanitLM",
                              style: "tableData",
                            },
                          ]
                        : [
                            {
                              text: `${props.price_text} (${props.priceTextEN})`,
                              font: "KanitLM",
                              style: "tableData",
                            },
                          ],
                    margin: [5, 0, 0, 0],
                    border: [false, false, false, false],
                    fillColor: color.greySummary,
                  },
                  {
                    text: "",
                    border: [false, false, true, false],
                  },
                ],
                [
                  {
                    text:
                      props.receiptType === "receipt" || props.receiptType === "deposit"
                        ? [
                            {
                              text: "ชำระเงินโดย ",
                              font: "KanitLM",
                              style: "tableHeaderSummary",
                            },
                            {
                              text: "/Payment",
                              font: "PoppinsLM",
                              style: "tableHeaderSummary",
                            },
                          ]
                        : "\n",
                    margin: [10, 0, 0, 0],
                    border: [true, false, false, true],
                  },
                  {
                    text:
                      props.receiptType === "receipt" || props.receiptType === "deposit"
                        ? props.payment_type
                        : "",
                    font: "KanitLM",
                    style: "tableData",
                    margin: [0, 0, 0, 0],
                    border: [false, false, false, true],
                  },
                  {
                    text: "",
                    border: [false, false, true, true],
                  },
                ],
                [
                  {
                    text: [
                      {
                        text: "หมายเหตุ ",
                        font: "KanitLM",
                        style: "tableData",
                      },
                      {
                        text: "/Remark",
                        font: "Poppins",
                        style: "tableData",
                      },
                    ],
                    margin: [10, 0, 0, 0],
                    border: [false, false, false, false],
                  },
                  {
                    text: props.remark,
                    // text: "ปรับลดเศษสตางค์ 50 สตางค์",
                    font: "KanitLM",
                    style: "tableData",
                    margin: [5, 0, 0, 0],
                    border: [false, false, false, false],
                  },
                  {
                    text: "",
                    border: [false, false, false, false],
                  },
                ],
              ],
            },
            layout: {
              hLineColor: function (i: any, node: any) {
                return color.greyBorder;
              },
              vLineColor: function (i: any, node: any) {
                return color.greyBorder;
              },
            },
          },
          {
            table: {
              widths: ["*", 240],
              body:
                props.receiptType === "refund"
                  ? [
                      [
                        {
                          stack: [
                            {
                              text: "เพื่อความปลอดภัยของผู้ป่วย ทางโรงพยาบาลขอสงวนสิทธิ์ไม่รับ เปลี่ยนหรือคืนยาและเวชภัณฑ์",
                              font: "KanitLM",
                              style: "footerData",
                            },
                            {
                              text: "For patient safety the hospital reserves the right not to accept any exchange or return of medical supplies.",
                              font: "PoppinsLM",
                              style: "footerData",
                            },
                            // {
                            //   text: "\n",
                            // },
                          ],
                          margin: [0, 8, 0, 0],
                          border: [false, false, false, false],
                        },
                        {
                          text: "",
                          border: [false, false, false, false],
                        },
                      ],
                      [
                        {
                          text: "",
                          border: [false, false, false, false],
                        },
                        {
                          text: "ได้รับเงินไว้เป็นการถูกต้องแล้ว\n\n",
                          font: "KanitLM",
                          style: "footerSignature",
                          alignment: "center",
                          border: [false, false, false, false],
                        },
                      ],
                      [
                        {
                          stack: [
                            {
                              text: "ลงชื่อ/Signature ____________________ผู้รับเงิน/Cashier",
                              font: "KanitLM",
                              style: "footerSignature",
                              alignment: "center",
                            },
                            {
                              text: `(          ${props.cashier}        )`,
                              font: "KanitLM",
                              style: "footerSignature",
                              alignment: "center",
                            },
                            // {
                            //   text: `${props.position}`,
                            //   font: "KanitLM",
                            //   style: "footerSignature",
                            //   alignment: "center",
                            // },
                          ],
                          border: [false, false, false, false],
                        },
                        {
                          stack: [
                            {
                              text: "ลงชื่อ/Signature ____________________ผู้รับเงิน",
                              font: "KanitLM",
                              style: "footerSignature",
                              alignment: "center",
                            },
                            {
                              text: `(          ${props.name}        )`,
                              font: "KanitLM",
                              style: "footerSignature",
                              alignment: "center",
                            },
                          ],
                          border: [false, false, false, false],
                        },
                      ],
                    ]
                  : [
                      [
                        {
                          text: "",
                          border: [false, false, false, false],
                        },
                        {
                          text: "ได้รับเงินไว้เป็นการถูกต้องแล้ว\n\n",
                          font: "KanitLM",
                          style: "footerSignature",
                          alignment: "center",
                          border: [false, false, false, false],
                        },
                      ],
                      [
                        {
                          stack: [
                            {
                              text: "ลงชื่อ/Signature ____________________",
                              font: "KanitLM",
                              style: "footerSignature",
                              alignment: "left",
                              margin: [45, 0, 0, 0],
                            },
                            {
                              text: `(          ผู้ป่วย / Patient        )`,
                              font: "KanitLM",
                              style: "footerSignature",
                              alignment: "center",
                            },
                          ],
                          border: [false, false, false, false],
                        },
                        {
                          stack: [
                            {
                              text: "ลงชื่อ/Signature ____________________ผู้รับเงิน/Cashier",
                              font: "KanitLM",
                              style: "footerSignature",
                              alignment: "center",
                            },
                            {
                              text: `(          ${props.cashier}        )`,
                              font: "KanitLM",
                              style: "footerSignature",
                              alignment: "center",
                            },
                            // {
                            //   text: `${props.position}`,
                            //   font: "KanitLM",
                            //   style: "footerSignature",
                            //   alignment: "center",
                            // },
                          ],
                          border: [false, false, false, false],
                        },
                      ],
                      [
                        {
                          stack: [
                            {
                              text: "\n\n\nเพื่อความปลอดภัยของผู้ป่วย ทางโรงพยาบาลขอสงวนสิทธิ์ไม่รับ เปลี่ยนหรือคืนยาและเวชภัณฑ์",
                              font: "KanitLM",
                              style: "footerData",
                            },
                            {
                              text: "For patient safety the hospital reserves the right not to accept any exchange or return of medical supplies.",
                              font: "PoppinsLM",
                              style: "footerData",
                            },
                            // {
                            //   text: "\n",
                            // },
                          ],
                          margin: [0, 8, 0, 0],
                          border: [false, false, false, false],
                        },
                        {
                          text: "",
                          border: [false, false, false, false],
                        },
                      ],
                    ],
            },
          },
        ],
      };
    },
    images: {
      ...headerSapiensForm.images,
      background: origin + "/static/images/logoform/SAPIENS/HeaderSapiensForm.png",
    },
  };
};

export default FormSapiensReceipt;
