import React from "react";
import { Button, Header } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import * as Util from "../../../utils/tsUtils";
import { useIntl } from "react-intl";

interface Props {
  detail?: {
    id?: number | string;
    price?: number | string;
    edited?: string;
  };
}

const SubPatientPayment = React.forwardRef((props: Props, ref) => {
  const intl = useIntl();
  const history = useHistory();

  return (
    <div className="sub-patient-payment">
      <div className="content-row">
        <b>{intl.formatMessage({ id: "เวลาที่เข้ารับการรักษา" })}</b>
        <div>
          {Util.convertToBEDate({
            date: moment(props.detail.edited).format("DD/MM/YYYY HH:mm"),
            startFormat: "DD/MM/YYYY HH:mm",
            endFormat: "DD/MM/YYYY HH:mm",
          })}
        </div>
      </div>
      <br />
      <div className="content-row">
        <b>{intl.formatMessage({ id: "ยอดที่ต้องชำระ" })}</b>
        <Header
          as="h3"
          className="price-text"
        >{`${Util.formatComma(props.detail.price)} ฿`}</Header>
      </div>
      <br />
      <div className="pay-btn">
        <Button
          content="ชำระเงิน"
          compact
          onClick={() => {
            history.push(`/paymentDetail/${props.detail.id}/`);
          }}
        />
      </div>
    </div>
  );
});

export default React.memo(SubPatientPayment);
