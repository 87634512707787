import moment from "moment";
import { ContentStack, TDocumentDefinitions } from "pdfmake/interfaces";

import { checkPageBreakBefore } from "react-lib/apps/HISV3/common/CommonInterface";
import {
  HeaderLogoWithPatientDetail,
  HeaderPrintListProps,
} from "react-lib/apps/HISV3/common/HeaderPdfFormTemplate";

import { getCompanyFont } from "../common/CompanyLogoForm";

import { formatDatetime } from "react-lib/utils/dateUtils";

import CONFIG from "config/config";

// Types
type DiagnosisType = {
  bold?: boolean;
  detail: string;
  icd10_code?: string;
  icd10_term?: string;
  icd9cm_code?: string;
  icd9cm_term?: string;
  label?: string;
  type: string;
};

export type FormDischargeSummaryProps = {
  attendingPhysicianName: string;
  detail: HeaderPrintListProps["detail"];
  diagnosis: {
    primary: DiagnosisType;
    secondary: [DiagnosisType];
  };
  dischargeDate?: string;
  dischargeSummary: {
    brief: string;
    condition: string;
    course: string;
    discharge_status: string;
    followup_plan: string;
  };
  procedure: {
    primary_procedure: DiagnosisType;
    secondary_procedures: [DiagnosisType];
  };
  provisionalDiagnosis: {
    primary: DiagnosisType;
    secondary: DiagnosisType[];
  };
  residentName: string;
  startWardDate?: string;
  userFullName: string;
};

interface ContentStackWithId extends Omit<ContentStack, "stack"> {
  id?: string;
  stack: ContentStack["stack"];
}

interface ITDocumentDefinitions extends TDocumentDefinitions {
  content: (ContentStackWithId | TDocumentDefinitions["content"])[];
}

const FONT_SIZE = 14;

const FORM_EMR_SUMMARY = "FormDischargeSummary";

const getDuration = (startDate?: string, endDate?: string) => {
  if (!(startDate && endDate)) {
    return "";
  }

  const start = moment(startDate);
  const end = moment(endDate);

  const diffInHours = end.diff(start, "hours");
  const days = Math.floor(diffInHours / 24);
  const hours = diffInHours % 24;

  // แสดงผลเฉพาะเมื่อมีค่าเป็นบวก
  if (diffInHours >= 0) {
    let result = "";

    if (days > 0) {
      result += `${days} Day(s) `;
    }

    if (hours > 0 || (hours === 0 && days === 0)) {
      result += `${hours} Hour(s)`;
    }

    return result.trim();
  }

  return "";
};

const FormDischargeSummary = async (
  props: FormDischargeSummaryProps
): Promise<ITDocumentDefinitions> => {
  const currentDate = moment();

  const headerForm = await HeaderLogoWithPatientDetail({
    detail: props.detail,
    font: getCompanyFont(),
    form: FORM_EMR_SUMMARY,
    formName: "DISCHARGE SUMMARY",
    logoHeight: 38,
    pageMargins: [12.5, 95.25, 12.5, 100],
    titleContent: [],
  });
  const { font, fonts, fontSizes, images, lineHeights, styles } = headerForm;

  console.log("FormDischargeSummary", props);

  return {
    defaultStyle: {
      font,
      fontSize: fontSizes[FONT_SIZE],
      lineHeight: lineHeights[1],
    },
    pageSize: "A4",
    ...headerForm,
    images: { ...images },
    styles: {
      ...styles,
      fieldKey: { bold: true, font: fonts.POPPINS_LM, fontSize: fontSizes[13.25] },
      fieldKeyBolder: { bold: true, font: fonts.POPPINS, fontSize: fontSizes[13.25] },
      fieldNote: {
        font: fonts.POPPINS_LM,
        fontSize: fontSizes[12],
        italics: true,
      },
      miniField: { fontSize: fontSizes[12] },
    },
    content: [
      {
        margin: [0, 0, 15, 0],
        stack: [
          {
            columns: [
              {
                text: [
                  { style: "fieldKey", text: "Admission date " },
                  { text: formatDatetime(props.startWardDate, true) },
                ],
                width: "*",
              },
              {
                text: [
                  { style: "fieldKey", text: "Discharge date " },
                  { text: formatDatetime(props.dischargeDate, true) },
                ],
                width: "*",
              },
              {
                text: [
                  { style: "fieldKey", text: "Length of Stay " },
                  {
                    text: getDuration(props.startWardDate, props.dischargeDate),
                  },
                ],
                width: "*",
              },
            ],
          },
          { marginTop: -13.25, text: " " },
          ...[
            { ...props.provisionalDiagnosis.primary, label: "Provisional Primary Diagnosis" },
            {
              ...props.diagnosis.primary,
              label: "Principal Diagnosis",
            },
            { label: "Secondary Diagnosis", type: "" } as DiagnosisType,
            ...props.diagnosis.secondary.map((item) => ({
              ...item,
              bold: false,
              label: (item.label || "").replace("Secondary", "-"),
            })),
            {
              ...props.procedure.primary_procedure,
              label: "Primary Procedure",
            },
          ].map((item) => ({
            stack: [
              { marginTop: -13.25, text: " " },
              {
                columns: [
                  {
                    bold: item.bold ?? true,
                    style: "fieldKey",
                    text: item.label || "",
                    width: 175,
                  },
                  {
                    stack: [
                      {
                        text: [
                          {
                            bold: true,
                            text:
                              item.icd9cm_code || item.icd10_code
                                ? `[${item.icd9cm_code || item.icd10_code}] `
                                : "",
                          },
                          item.icd9cm_term || item.icd10_term || "",
                        ],
                        width: "*",
                      },
                      { text: item.detail || "" },
                    ],
                  },
                ],
              },
            ],
          })),
        ],
      },
      { marginTop: -13.25, text: " " },
      {
        id: "brief",
        stack: [
          { style: "fieldKey", text: "Brief History and Physical Finding" },
          {
            text: props.dischargeSummary.brief,
          },
        ],
      },
      { marginTop: -13.25, text: " " },
      {
        id: "course",
        stack: [
          { style: "fieldKey", text: "Hospital Course" },
          {
            text: props.dischargeSummary.course,
          },
        ],
      },
      { marginTop: -13.25, text: " " },
      {
        id: "condition",
        stack: [
          { style: "fieldKey", text: "Prescription/Advice on Discharge" },
          {
            text: props.dischargeSummary.condition,
          },
        ],
      },
      { marginTop: -13.25, text: " " },
      {
        id: "followup_plan",
        stack: [
          { style: "fieldKey", text: "Follow up plan" },
          {
            text: props.dischargeSummary.followup_plan,
          },
        ],
      },
      { marginTop: -13.25, text: " " },
      {
        text: [
          { style: "fieldKey", text: "Discharge status " },
          {
            text: props.dischargeSummary.discharge_status,
          },
        ],
      },
    ],
    footer: (currentPage, pageCount) => ({
      marginTop: 20,
      stack: [
        {
          columns: [
            { hidden: CONFIG.COMPANY !== "CU", label: "Resident", name: props.residentName || " " },
            { label: "Attending physician", name: props.attendingPhysicianName || "" },
          ].map((item) => ({
            alignment: "center",
            stack: item.hidden
              ? []
              : [
                  {
                    marginTop: 5,
                    table: {
                      widths: ["*", "60%", "*"],
                      body: [
                        [
                          { border: [false, false, false, false], text: "" },
                          { border: [false, true, false, false], text: "" },
                          { border: [false, false, false, false], text: "" },
                        ],
                      ],
                    },
                    layout: {
                      hLineColor: () => "#606060",
                      hLineStyle: () => ({ dash: { length: 1, space: 1 } }),
                      hLineWidth: () => 1,
                      vLineWidth: () => 0,
                    },
                  },
                  { text: item.name },
                  { bold: true, text: item.label },
                ],
            width: "50%",
          })),
        },
        {
          marginTop: 7.5,
          table: {
            widths: ["100%"],
            body: [[{ border: [false, true, false, false], text: "" }]],
          },
          layout: {
            hLineColor: () => "#c0c0c0",
            hLineStyle: () => ({ dash: { length: 1, space: 1 } }),
            hLineWidth: () => 1,
            vLineWidth: () => 0,
          },
        },
        {
          columns: [
            {
              text: [
                "วันเวลาที่พิมพ์เอกสาร ",
                { text: formatDatetime(currentDate, true) },
                ` โดย ${props.userFullName}`,
              ],
            },
            { alignment: "right", text: `หน้า ${currentPage}/${pageCount}` },
          ],
          margin: [15, 0, 15, 0],
          style: "miniField",
        },
      ],
    }),
    pageBreakBefore: (currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) => {
      const config = {
        ids: ["brief", "course", "condition", "followup_plan"],
      };

      const checkBreak = checkPageBreakBefore(config);

      return checkBreak(currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage);
    },
  };
};

export default FormDischargeSummary;
