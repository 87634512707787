import React, {
  CSSProperties,
  ChangeEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useState,
} from "react";

import { Button, ButtonProps, Form, Input, Modal } from "semantic-ui-react";
import { useIntl } from "react-intl";

// Types
type ModDrugEditNoteProps = {
  setProp: (key: string, value: any, callback?: () => void) => void;
  open: boolean;
  type?: string;
  // callback
  onClose: () => void;
  onSave: (e: MouseEvent<HTMLButtonElement>, data: ButtonProps) => void;
};

type Styles = Record<"modal", CSSProperties>;

// Constants
const styles: Styles = {
  modal: { width: "auto" },
};

const ModDrugEditNote = (props: ModDrugEditNoteProps) => {
  const intl = useIntl();
  const [note, setNote] = useState<string>("");

  useEffect(() => {
    if (props.open) {
      handleChangeEditNote(null, { value: "" });
    }
  }, [props.open]);

  const handleChangeEditNote = useCallback((e: ChangeEvent<HTMLInputElement> | null, data: any) => {
    setNote(data.value);

    props.setProp("DrugSelectSequence.note", data.value);
  }, []);

  const handleSave = useCallback(
    (e: MouseEvent<HTMLButtonElement>, data: ButtonProps) => {
      props.onSave(e, { ...data, value: note });
    },
    [note, props.onSave]
  );

  return (
    <Modal
      open={props.open}
      style={styles.modal}
      closeIcon
      closeOnDimmerClick
      onClose={props.onClose}
    >
      <Modal.Content>
        <Form>
          <Form.Group style={{ margin: "0.5rem 0" }} inline>
            <Form.Field>
              <label htmlFor="note">{intl.formatMessage({ id: "ระบุเหตุผลที่ดำเนินการแทนแพทย์" })}</label>
              <Input id="note" name="note" value={note} onChange={handleChangeEditNote} />
            </Form.Field>
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button color="blue" name={props.type} onClick={handleSave}>
          Save
        </Button>
        <Button color="red" onClick={props.onClose}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

ModDrugEditNote.displayName = "ModDrugEditNote";

export default React.memo(ModDrugEditNote);
