import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Input,
  Button
} from 'semantic-ui-react'

const SubProductIngredientsUX = (props: any) => {
    return(
      <div
        className="ui form"
        style={{width:"100%", backgroundColor: "#bbe7f1",padding: "1em 10px 1em"}}>
        <FormGroup
          className="noMargin"
          inline={true}>
          <FormField
            inline={true}>
            <label
              style={{width: "105px"}}>
              Ingredients
            </label>
          </FormField>
          <FormField
            inline={true}
            width={3}>
            <div
              style={{width: "100%"}}>
              {props.SearchBox}
            </div>
          </FormField>
          <FormField
            inline={true}>
            <label
              style={{margin: "0 2.5rem", width: "75px"}}>
              Strength
            </label>
          </FormField>
          <FormField
            inline={true}
            width={3}>
            <Input
              fluid={true}
              name="strength"
              onChange={props.onChangeValue}
              style={{width: "100%"}}
              value={props.strength || ""}>
            </Input>
          </FormField>
          <FormField
            inline={true}>
            <label
              style={{margin: "0 2.5rem", width: "85px"}}>
              Sequence
            </label>
          </FormField>
          <FormField
            inline={true}
            width={3}>
            <Input
              fluid={true}
              name="sequence"
              onChange={props.onChangeValue}
              onKeyDown={(e: any)=> ["-","e"].includes(e.key) && e.preventDefault()}
              style={{width: "100%"}}
              type="number"
              value={props.sequence || ""}>
            </Input>
          </FormField>
          <FormField>
            <Button
              className="circular"
              color="green"
              icon="plus"
              onClick={props.onAdd}
              size="mini"
              style={{fontSize: "0.65rem", padding: "0.5rem"}}>
            </Button>
          </FormField>
          <FormField>
            <Button
              className="circular"
              color="red"
              disabled={props.hideRemove || false}
              icon="minus"
              onClick={props.onRemove}
              size="mini"
              style={{fontSize: "0.65rem", padding: "0.5rem", ...(props.hideRemove ? {opacity:0, pointerEvents: "none", background: "transparent"} : {})}}>
            </Button>
          </FormField>
        </FormGroup>
      </div>
    )
}

SubProductIngredientsUX.displayName = "SubProductIngredientsUX";
export default React.memo(SubProductIngredientsUX)

export const screenPropsDefault = {}

/* Date Time : Tue Jul 11 2023 09:16:58 GMT+0000 (Coordinated Universal Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "style": {
          "type": "code",
          "value": "{width:\"100%\", backgroundColor: \"#bbe7f1\",padding: \"1em 10px 1em\"}"
        }
      },
      "seq": 0
    },
    {
      "from": "semantic-ui-react",
      "id": 1,
      "name": "FormGroup",
      "parent": 0,
      "props": {
        "className": {
          "type": "value",
          "value": "noMargin"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 2,
      "name": "FormField",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "FormField",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "FormField",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "FormField",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormField",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormField",
      "parent": 1,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "label",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "Ingredients"
        },
        "style": {
          "type": "code",
          "value": "{width: \"105px\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": null,
      "id": 9,
      "name": "label",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "Strength"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 2.5rem\", width: \"75px\"}"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "label",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "Sequence"
        },
        "style": {
          "type": "code",
          "value": "{margin: \"0 2.5rem\", width: \"85px\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "code",
          "value": "props.SearchBox"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 12,
      "name": "Input",
      "parent": 5,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "strength"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "onKeyDown": {
          "type": "code",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.strength || \"\""
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "Input",
      "parent": 7,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "sequence"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeValue"
        },
        "onKeyDown": {
          "type": "code",
          "value": "(e: any)=> [\"-\",\"e\"].includes(e.key) && e.preventDefault()"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "type": {
          "type": "value",
          "value": "number"
        },
        "value": {
          "type": "code",
          "value": "props.sequence || \"\""
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "FormField",
      "parent": 1,
      "props": {
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "FormField",
      "parent": 1,
      "props": {
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "Button",
      "parent": 14,
      "props": {
        "className": {
          "type": "value",
          "value": "circular"
        },
        "color": {
          "type": "value",
          "value": "green"
        },
        "icon": {
          "type": "value",
          "value": "plus"
        },
        "onClick": {
          "type": "code",
          "value": "props.onAdd"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"0.65rem\", padding: \"0.5rem\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 17,
      "name": "Button",
      "parent": 15,
      "props": {
        "className": {
          "type": "value",
          "value": "circular"
        },
        "color": {
          "type": "value",
          "value": "red"
        },
        "disabled": {
          "type": "code",
          "value": "props.hideRemove || false"
        },
        "icon": {
          "type": "value",
          "value": "minus"
        },
        "onClick": {
          "type": "code",
          "value": "props.onRemove"
        },
        "size": {
          "type": "value",
          "value": "mini"
        },
        "style": {
          "type": "code",
          "value": "{fontSize: \"0.65rem\", padding: \"0.5rem\", ...(props.hideRemove ? {opacity:0, pointerEvents: \"none\", background: \"transparent\"} : {})}"
        }
      },
      "seq": 17,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": true,
  "name": "SubProductIngredientsUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
