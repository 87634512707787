import moment from "moment";
import React, { useEffect, useState, useMemo, CSSProperties } from "react";
import CardBillingDepositHistoryUX from "react-lib/apps/HISV3/BIL/CardBillingDepositHistoryUX";
import ModInfo from "react-lib/apps/common/ModInfo";
import { formatComma } from "react-lib/utils/tsUtils";
import { Button } from "semantic-ui-react";
import { useIntl } from "react-intl";

type CardBillingDepositHistoryProps = {
  // function
  onEvent: any;
  setProp: any;
  runSequence: any;

  // seq
  BillingDepositHistorySequence?: any;
  billReportPrint?: any;
};

const AlignCenterStyle = { textAlign: "center" } as CSSProperties;
const AlignRightStyle = { textAlign: "right" } as CSSProperties;

const CardBillingDepositHistory = (props: CardBillingDepositHistoryProps) => {
  const intl = useIntl();
  useEffect(() => {
    props.runSequence({
      sequence: "BillingDepositHistory",
      restart: true,
    });
  }, []);

  const handlePrintReceipt = (payment_receipt_id: any) => {
    props.runSequence({
      sequence: "BillingDepositHistory",
      action: "printReceipt",
      receipt: payment_receipt_id,
    });
  };

  const handleCloseModCancel = () => {
    props.setProp("billReportPrint", {});
  };

  const handleCancelReportPrint = () => {
    props.onEvent({
      message: "HandleReportPrintTrackingResult",
      params: {
        action: "CANCEL",
        taskId: props.billReportPrint?.task_id,
      },
    });
  };

  const formatDeposit = (data: any) => {
    return formatComma(Number(data.replaceAll(",", "")));
  };

  const memoDepositHistoryItems = useMemo(() => {
    return (props.BillingDepositHistorySequence?.depositHistory || []).map(
      (item: any) => ({
        ...item,
        paymentDatetime: (
          <div style={AlignCenterStyle}>{item?.payment_datetime}</div>
        ),
        paymentReceipt: (
          <div style={AlignCenterStyle}>{item?.payment_receipt_no}</div>
        ),
        depositReceipt: (
          <div style={AlignCenterStyle}>{item?.deposit_code}</div>
        ),
        encounterType: (
          <div style={AlignCenterStyle}>{item?.payment_encounter_type}</div>
        ),
        payment: <div style={AlignRightStyle}>{formatDeposit(item?.value || "0.00")}</div>,
        paymentCashier: (
          <div style={AlignCenterStyle}>{item?.payment_cashier}</div>
        ),
        buttonPrint: (
          <div style={AlignCenterStyle}>
            <Button
              color="blue"
              onClick={() => handlePrintReceipt(item?.payment_receipt_id)}
            >
              พิมพ์ใบแสดงรายละเอียด
            </Button>
          </div>
        ),
      })
    );
  }, [props.BillingDepositHistorySequence?.depositHistory]);

  const handleChangeDate = (date: any, name: any) => {
    props.setProp(`BillingDepositHistorySequence.${name}`, date);
  };

  const handleChange = (e: any, v: any) => {
    props.setProp(`BillingDepositHistorySequence.${v?.name}`, v?.value);
  };

  const onSearchDepositHistory = () => {
    props.onEvent({
      message: "RunSequence",
      params: {
        sequence: "BillingDepositHistory",
        action: "getDepositHistory",
      },
    });
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <CardBillingDepositHistoryUX
        depositHistory={props.BillingDepositHistorySequence}
        startDate={props.BillingDepositHistorySequence?.startDate}
        endDate={props.BillingDepositHistorySequence?.endDate}
        depositHistoryData={memoDepositHistoryItems}
        depositTotal={props.BillingDepositHistorySequence?.depositTotal}
        onChangeStartDate={(date: any) => handleChangeDate(date, "startDate")}
        onChangeEndDate={(date: any) => handleChangeDate(date, "endDate")}
        onChange={handleChange}
        onSearchDepositHistory={() => onSearchDepositHistory()}
        languageUX={props.languageUX}
      />

      <ModInfo
        open={!!props.billReportPrint?.open}
        titleColor={"black"}
        titleName={intl.formatMessage({ id: "กำลังเตรียมเอกสาร" })}
        btnText={intl.formatMessage({ id: "ยกเลิก" })}
        buttonColor="red"
        style={{ width: "27.5%" }}
        onApprove={handleCancelReportPrint}
        // onClose={handleCloseModInfo}
      >
        <div
          style={{
            padding: "0.5rem 0",
            lineHeight: 1.75,
            textAlign: "left",
          }}
        >
          <div>
            กรุณารอสักครู่
            <br />
            {!!props.billReportPrint?.status && (
              <>
                Status: {props.billReportPrint?.status}{" "}
                {props.billReportPrint?.progress}%
                <br />
              </>
            )}
            For IT: task_id = {props.billReportPrint?.task_id}
          </div>
        </div>
      </ModInfo>

      <ModInfo
        open={!!props.billReportPrint?.error}
        titleColor={"red"}
        onApprove={handleCloseModCancel}
        onClose={handleCloseModCancel}
      >
        <div
          style={{
            padding: "0.5rem 0",
            lineHeight: 1.75,
            textAlign: "left",
          }}
        >
          {props.billReportPrint?.error}
        </div>
      </ModInfo>
    </div>
  );
};

export default CardBillingDepositHistory;
