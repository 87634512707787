import moment from "moment";
import { ContentStack, TDocumentDefinitions } from "pdfmake/interfaces";
import { HeaderPrintList } from "react-lib/apps/HISV3/common/HeaderPdfFormTemplate";

const FONT_SIZE = 14;

const FORM_NAME = "FormProgressNote";

const FormProgressNote = async (props: any): Promise<TDocumentDefinitions> => {
  const currentDate = moment();

  const headerForm = await HeaderPrintList({
    detail: props.detail,
    font: "THSarabunNew",
    form: FORM_NAME,
    formName: "Progress Note",
    headerMargins: [16, 8, 16, 0],
    logoHeight: 42,
    nameFontSize: 18,
    pageMargins: [16, 108.5, 16, 16]
  });
  const { font, fontSizes, images, lineHeights, styles } = headerForm;

  const items = props.items;

  const mapItems: ContentStack[] = items?.map((item: any) => {
    const fields = [
      { label: "S: Subjective:", value: item.s_txt },
      { label: "O: Objective:", value: item.o_txt },
      { label: "A: Assessment:", value: item.a_txt },
      { label: "P: Plan:", value: item.p_txt },
    ];

    const filteredFields = fields.filter((field: any) => field.value && field.value !== "-");

    const content = [
      {
        columns: [
          { text: item.last_edit, bold: true, width: `auto` },
          { text: "", width: 10 },
          { text: item.editer || item.reporter },
        ],
        margin: [8, 4, 0, 0],
      },
      ...filteredFields.map((field: any) => ({
        columns: [
          { text: field.label, width: 70 },
          { text: "", width: 10 },
          { text: field.value, width: `auto` },
        ],
        margin: field.label === "P: Plan:" ? [16, 4, 0, 4] : [16, 4, 0, 0],
      })),
      {
        canvas: [
          {
            type: "line",
            x1: 0,
            y1: 0,
            x2: 558,
            y2: 0,
            lineWidth: 0.5,
            dash: {
              length: 5,
              space: 3,
            },
          },
        ],
      },
    ];

    return { stack: content };
  });

  return {
    pageSize: "A4",
    ...headerForm,
    defaultStyle: {
      font: font,
      fontSize: fontSizes[FONT_SIZE],
      lineHeight: lineHeights[1],
    },
    images: { ...images },
    styles: {
      ...styles,
    },
    content: [
      {
        text: "Progress Note",
        bold: true,
        fontSize: FONT_SIZE + 4,
      },
      ...mapItems,
    ],
    footer: (currentPage, countPage) => ({
      stack: [
        {
          columns: [
            {
              text: `วันเวลาที่พิมพ์เอกสาร ${currentDate.format(
                "DD/MM/YYYY"
              )} [${currentDate.format("HH:mm")}] โดย ${props.printer}`,
              font: "KanitLM",
              fontSize: 8,
              margin: [16, 0, 0, 0],
            },
            {
              text: `หน้า ${currentPage}/${countPage}`,
              font: "KanitLM",
              fontSize: 8,
              margin: [0, 0, 16, 0],
              alignment: "right",
            },
          ],
        },
      ],
    }),
  };
};

export default FormProgressNote;
