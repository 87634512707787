import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Radio,
  Dropdown,
  Input
} from 'semantic-ui-react'
import {
  DateTextBox,
  TimeFreeTextBox24
} from 'react-lib/apps/common'

const CardReAssessmentPainUX = (props: any) => {
    return(
      <div
        style={{position: "relative", marginTop: "1rem"}}>
        <div
          style={{ marginLeft: "1rem" }}>
          
          <div
            style={{ display: "flex", alignItems: "center", margin: "16px"}}>
            
            <label
              style={{width: "8rem", whiteSpace: "normal"}}>
              ความเจ็บปวด:
            </label>
            <div
              style={{display: "flex", gap: "2rem", padding: "0rem 0rem 0rem 2rem"}}>
              
              <Radio
                checked={props.ReAssessmentSequence?.painQuestion?.painStatus === "ปวด"}
                label="ปวด"
                name="painQuestion.painStatus"
                onChange={props.onChangeData}
                value="ปวด">
              </Radio>
              <Radio
                checked={props.ReAssessmentSequence?.painQuestion?.painStatus === "ไม่ปวด"}
                label="ไม่ปวด"
                name="painQuestion.painStatus"
                onChange={props.onChangeData}
                value="ไม่ปวด">
              </Radio>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "16px"}}>
            
            <label
              style={{width: "16rem", whiteSpace: "normal"}}>
              Pain Assessment Tool:
            </label>
            <Dropdown
              clearable={true}
              name="painQuestion.painAssessmentTool"
              onChange={props.onChangeData}
              options={props.reAssessmentOptions?.painAssessmentTool}
              selection={true}
              value={props.ReAssessmentSequence?.painQuestion?.painAssessmentTool}>
            </Dropdown>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "16px"}}>
            
            <label
              style={{width: "4rem", whiteSpace: "normal"}}>
              Score:
            </label>
            <Dropdown
              clearable={true}
              name="painQuestion.painScore"
              onChange={props.onChangeData}
              options={props.reAssessmentOptions?.painLevel}
              selection={true}
              style={{ minWidth: "6rem" }}
              value={props.ReAssessmentSequence?.painQuestion?.painScore}>
            </Dropdown>
            <label
              style={{width: "6rem", whiteSpace: "normal", marginLeft: "4rem"}}>
              Location:
            </label>
            <Dropdown
              clearable={true}
              name="painQuestion.painLocation"
              onChange={props.onChangeData}
              options={props.masterOptions?.location}
              selection={true}
              value={props.ReAssessmentSequence?.painQuestion?.painLocation}>
            </Dropdown>
            <label
              style={{width: "6rem", whiteSpace: "normal", marginLeft: "4rem"}}>
              Duration:
            </label>
            <Input
              name="painQuestion.painDurationMinute"
              onChange={props.onChangeData}
              style={{width: "6rem"}}
              value={props.ReAssessmentSequence?.painQuestion?.painDurationMinute}>
            </Input>
            <label
              style={{width: "3rem", whiteSpace: "normal", marginLeft: "1rem"}}>
              นาที
            </label>
            <Input
              name="painQuestion.painDurationSecond"
              onChange={props.onChangeData}
              style={{width: "6rem"}}
              value={props.ReAssessmentSequence?.painQuestion?.painDurationSecond}>
            </Input>
            <label
              style={{width: "3rem", whiteSpace: "normal", marginLeft: "1rem"}}>
              วินาที
            </label>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "16px"}}>
            
            <label
              style={{width: "8rem", whiteSpace: "normal"}}>
              Characteristic:
            </label>
            <Dropdown
              clearable={true}
              name="painQuestion.painCharacteristic"
              onChange={props.onChangeData}
              options={props.characteristicOptions}
              selection={true}
              value={props.ReAssessmentSequence?.painQuestion?.painCharacteristic}>
            </Dropdown>
            <div>
              {props.isOtherCharacteristic}
            </div>
            <label
              style={{width: "6rem", whiteSpace: "normal", marginLeft: "4rem"}}>
              Frequency:
            </label>
            <Dropdown
              clearable={true}
              name="painQuestion.painFrequency"
              onChange={props.onChangeData}
              options={props.reAssessmentOptions?.painFrequency}
              selection={true}
              value={props.ReAssessmentSequence?.painQuestion?.painFrequency}>
            </Dropdown>
            <label
              style={{width: "10rem", whiteSpace: "normal", marginLeft: "4rem"}}>
              Informed Physician:
            </label>
            <Radio
              checked={props.ReAssessmentSequence?.painQuestion?.painInformedPhysician === "No"}
              label="No"
              name="painQuestion.painInformedPhysician"
              onChange={props.onChangeData}
              style={{width: "5rem", whiteSpace: "normal", marginLeft: "1rem"}}
              value="No">
            </Radio>
            <Radio
              checked={props.ReAssessmentSequence?.painQuestion?.painInformedPhysician === "Yes"}
              label="Yes"
              name="painQuestion.painInformedPhysician"
              onChange={props.onChangeData}
              style={{width: "6rem", whiteSpace: "normal"}}
              value="Yes">
            </Radio>
            <div>
              {props.isInformedOptions}
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "16px"}}>
            
            <label
              style={{width: "14rem", whiteSpace: "normal"}}>
              Non-Medical Intervention:
            </label>
            <Dropdown
              clearable={true}
              multiple={true}
              name="painQuestion.painNonMidicalIntervention"
              onChange={props.onChangeData}
              options={props.reAssessmentOptions?.painNonMedicalIntervention}
              selection={true}
              value={props.ReAssessmentSequence?.painQuestion?.painNonMidicalIntervention}>
            </Dropdown>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "16px"}}>
            
            <label
              style={{width: "10rem", whiteSpace: "normal"}}>
              Medication Give:
            </label>
            <Radio
              checked={props.ReAssessmentSequence?.painQuestion?.painMedicationGive === "No"}
              label="No"
              name="painQuestion.painMedicationGive"
              onChange={props.onChangeData}
              style={{width: "5rem", whiteSpace: "normal"}}
              value="No">
            </Radio>
            <Radio
              checked={props.ReAssessmentSequence?.painQuestion?.painMedicationGive === "Yes"}
              label="Yes"
              name="painQuestion.painMedicationGive"
              onChange={props.onChangeData}
              style={{width: "6rem", whiteSpace: "normal"}}
              value="Yes">
            </Radio>
            <div>
              {props.isMedicationGive}
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "16px"}}>
            
            <div
              style={{width: "2rem"}}>
              {props.onSedatScoreTooltip}
            </div>
            <label
              style={{width: "18rem", whiteSpace: "normal"}}>
              Sedation score (before Opiods given):
            </label>
            <Dropdown
              name="painQuestion.painSedatScoreBefore"
              onChange={props.onChangeData}
              options={props.reAssessmentOptions?.painSedatScore}
              selection={true}
              style={{ minWidth: "6rem" }}
              value={props.ReAssessmentSequence?.painQuestion?.painSedatScoreBefore}>
            </Dropdown>
            <div
              style={{width: "2rem", marginLeft: "4rem"}}>
              {props.onSedatScoreTooltip}
            </div>
            <label
              style={{width: "18rem", whiteSpace: "normal"}}>
              Sedation score (after Opiods given):
            </label>
            <Dropdown
              name="painQuestion.painSedatScoreAfter"
              onChange={props.onChangeData}
              options={props.reAssessmentOptions?.painSedatScore}
              selection={true}
              style={{ minWidth: "6rem" }}
              value={props.ReAssessmentSequence?.painQuestion?.painSedatScoreAfter}>
            </Dropdown>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "16px"}}>
            
            <label
              style={{width: "16rem", whiteSpace: "normal"}}>
              Pain score after intervention:
            </label>
            <Radio
              checked={props.ReAssessmentSequence?.painQuestion?.painScoreAfterIntervention === "IV min"}
              label="IV min"
              name="painQuestion.painScoreAfterIntervention"
              onChange={props.onChangeData}
              style={{width: "8rem", whiteSpace: "normal"}}
              value="IV min">
            </Radio>
            <Radio
              checked={props.ReAssessmentSequence?.painQuestion?.painScoreAfterIntervention === "IM 30 min"}
              label="IM 30 min"
              name="painQuestion.painScoreAfterIntervention"
              onChange={props.onChangeData}
              style={{width: "8rem", whiteSpace: "normal"}}
              value="IM 30 min">
            </Radio>
            <Radio
              checked={props.ReAssessmentSequence?.painQuestion?.painScoreAfterIntervention === "Oral / non med 60 min"}
              label="Oral / non med 60 min"
              name="painQuestion.painScoreAfterIntervention"
              onChange={props.onChangeData}
              style={{width: "15rem", whiteSpace: "normal"}}
              value="Oral / non med 60 min">
            </Radio>
            <div>
              {props.isPainScoreAfterIntervention}
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", margin: "16px"}}>
            
            <label
              style={{width: "9rem", whiteSpace: "normal"}}>
              Side Effects (SE):
            </label>
            <Dropdown
              clearable={true}
              name="painQuestion.painSideEffect"
              onChange={props.onChangeData}
              options={props.reAssessmentOptions?.painSideEffect}
              selection={true}
              value={props.ReAssessmentSequence?.painQuestion?.painSideEffect}>
            </Dropdown>
            <div>
              {props.isOtherSideEffect}
            </div>
          </div>
        </div>
        <div
          style={{ display: "flex", justifyContent: "right", alignItems: "center" , margin: "1rem"}}>
          
          <label
            style={{ display: "flex", alignItems: "center", margin: "0rem 2rem", fontWeight: "bold"  }}>
            Shift
          </label>
          <Radio
            checked={props.ReAssessmentSequence?.painData?.saveShift === "Day"}
            label="Day"
            name="painData.saveShift"
            onChange={props.onChangeData}
            style={{ display: "flex", alignItems: "center"  }}
            value="Day">
          </Radio>
          <Radio
            checked={props.ReAssessmentSequence?.painData?.saveShift === "Night"}
            label="Night"
            name="painData.saveShift"
            onChange={props.onChangeData}
            style={{ display: "flex", alignItems: "center", margin: "0rem 1rem"  }}
            value="Night">
          </Radio>
          <label
            style={{ display: "flex", alignItems: "center", margin: "0rem 2rem", fontWeight: "bold"  }}>
            ประเมินวันที่
          </label>
          <DateTextBox
            onChange={props.onChangeDatePain}
            value={props.ReAssessmentSequence?.painData?.saveData}>
          </DateTextBox>
          <label
            style={{ display: "flex", alignItems: "center", margin: "0rem 2rem", fontWeight: "bold"  }}>
            เวลา
          </label>
          <TimeFreeTextBox24
            autoFocus={false}
            onChange={props.onChangeTimePain}
            value={props.ReAssessmentSequence?.painData?.saveTime}>
          </TimeFreeTextBox24>
          <div
            style={{ margin: "0rem 0rem 0rem 2rem" }}>
            {props?.buttonSavePain}
          </div>
        </div>
      </div>
    )
}


export default CardReAssessmentPainUX

export const screenPropsDefault = {}

/* Date Time : Mon Oct 07 2024 16:40:21 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{position: \"relative\", marginTop: \"1rem\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"1rem\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"16px\"}"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "label",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": "ความเจ็บปวด:"
        },
        "style": {
          "type": "code",
          "value": "{width: \"8rem\", whiteSpace: \"normal\"}"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": null,
      "id": 4,
      "name": "div",
      "parent": 2,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", gap: \"2rem\", padding: \"0rem 0rem 0rem 2rem\"}"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "Radio",
      "parent": 4,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.painQuestion?.painStatus === \"ปวด\""
        },
        "label": {
          "type": "value",
          "value": "ปวด"
        },
        "name": {
          "type": "value",
          "value": "painQuestion.painStatus"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "ปวด"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "Radio",
      "parent": 4,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.painQuestion?.painStatus === \"ไม่ปวด\""
        },
        "label": {
          "type": "value",
          "value": "ไม่ปวด"
        },
        "name": {
          "type": "value",
          "value": "painQuestion.painStatus"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "value": {
          "type": "value",
          "value": "ไม่ปวด"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": null,
      "id": 7,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"16px\"}"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "label",
      "parent": 7,
      "props": {
        "children": {
          "type": "value",
          "value": "Pain Assessment Tool:"
        },
        "style": {
          "type": "code",
          "value": "{width: \"16rem\", whiteSpace: \"normal\"}"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 9,
      "name": "Dropdown",
      "parent": 7,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "painQuestion.painAssessmentTool"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.reAssessmentOptions?.painAssessmentTool"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.painQuestion?.painAssessmentTool"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": null,
      "id": 10,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"16px\"}"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"16px\"}"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 12,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"16px\"}"
        }
      },
      "seq": 12,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"16px\"}"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"16px\"}"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": null,
      "id": 15,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"16px\"}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": null,
      "id": 16,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"right\", alignItems: \"center\" , margin: \"1rem\"}"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "label",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "Score:"
        },
        "style": {
          "type": "code",
          "value": "{width: \"4rem\", whiteSpace: \"normal\"}"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 18,
      "name": "Dropdown",
      "parent": 10,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "painQuestion.painScore"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.reAssessmentOptions?.painLevel"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ minWidth: \"6rem\" }"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.painQuestion?.painScore"
        }
      },
      "seq": 18,
      "void": false
    },
    {
      "from": null,
      "id": 19,
      "name": "label",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "Location:"
        },
        "style": {
          "type": "code",
          "value": "{width: \"6rem\", whiteSpace: \"normal\", marginLeft: \"4rem\"}"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "Dropdown",
      "parent": 10,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "painQuestion.painLocation"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.masterOptions?.location"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": ""
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.painQuestion?.painLocation"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": null,
      "id": 21,
      "name": "label",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "Duration:"
        },
        "style": {
          "type": "code",
          "value": "{width: \"6rem\", whiteSpace: \"normal\", marginLeft: \"4rem\"}"
        }
      },
      "seq": 21,
      "void": false
    },
    {
      "from": null,
      "id": 23,
      "name": "label",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "นาที"
        },
        "style": {
          "type": "code",
          "value": "{width: \"3rem\", whiteSpace: \"normal\", marginLeft: \"1rem\"}"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": null,
      "id": 25,
      "name": "label",
      "parent": 10,
      "props": {
        "children": {
          "type": "value",
          "value": "วินาที"
        },
        "style": {
          "type": "code",
          "value": "{width: \"3rem\", whiteSpace: \"normal\", marginLeft: \"1rem\"}"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 26,
      "name": "Input",
      "parent": 10,
      "props": {
        "name": {
          "type": "value",
          "value": "painQuestion.painDurationMinute"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width: \"6rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.painQuestion?.painDurationMinute"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "Input",
      "parent": 10,
      "props": {
        "name": {
          "type": "value",
          "value": "painQuestion.painDurationSecond"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width: \"6rem\"}"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.painQuestion?.painDurationSecond"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": null,
      "id": 28,
      "name": "label",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": "Characteristic:"
        },
        "style": {
          "type": "code",
          "value": "{width: \"8rem\", whiteSpace: \"normal\"}"
        }
      },
      "seq": 28,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 29,
      "name": "Dropdown",
      "parent": 11,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "painQuestion.painCharacteristic"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.characteristicOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.painQuestion?.painCharacteristic"
        }
      },
      "seq": 29,
      "void": false
    },
    {
      "from": null,
      "id": 30,
      "name": "label",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": "Frequency:"
        },
        "style": {
          "type": "code",
          "value": "{width: \"6rem\", whiteSpace: \"normal\", marginLeft: \"4rem\"}"
        }
      },
      "seq": 31,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 31,
      "name": "Dropdown",
      "parent": 11,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "painQuestion.painFrequency"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.reAssessmentOptions?.painFrequency"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.painQuestion?.painFrequency"
        }
      },
      "seq": 32,
      "void": false
    },
    {
      "from": null,
      "id": 32,
      "name": "label",
      "parent": 11,
      "props": {
        "children": {
          "type": "value",
          "value": "Informed Physician:"
        },
        "style": {
          "type": "code",
          "value": "{width: \"10rem\", whiteSpace: \"normal\", marginLeft: \"4rem\"}"
        }
      },
      "seq": 33,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 33,
      "name": "Radio",
      "parent": 11,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.painQuestion?.painInformedPhysician === \"No\""
        },
        "label": {
          "type": "value",
          "value": "No"
        },
        "name": {
          "type": "value",
          "value": "painQuestion.painInformedPhysician"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width: \"5rem\", whiteSpace: \"normal\", marginLeft: \"1rem\"}"
        },
        "value": {
          "type": "value",
          "value": "No"
        }
      },
      "seq": 34,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 34,
      "name": "Radio",
      "parent": 11,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.painQuestion?.painInformedPhysician === \"Yes\""
        },
        "label": {
          "type": "value",
          "value": "Yes"
        },
        "name": {
          "type": "value",
          "value": "painQuestion.painInformedPhysician"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width: \"6rem\", whiteSpace: \"normal\"}"
        },
        "value": {
          "type": "value",
          "value": "Yes"
        }
      },
      "seq": 35,
      "void": false
    },
    {
      "from": null,
      "id": 35,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "code",
          "value": "props.isInformedOptions"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": null,
      "id": 36,
      "name": "label",
      "parent": 12,
      "props": {
        "children": {
          "type": "value",
          "value": "Non-Medical Intervention:"
        },
        "style": {
          "type": "code",
          "value": "{width: \"14rem\", whiteSpace: \"normal\"}"
        }
      },
      "seq": 36,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 37,
      "name": "Dropdown",
      "parent": 12,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "multiple": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "painQuestion.painNonMidicalIntervention"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.reAssessmentOptions?.painNonMedicalIntervention"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.painQuestion?.painNonMidicalIntervention"
        }
      },
      "seq": 37,
      "void": false
    },
    {
      "from": null,
      "id": 38,
      "name": "label",
      "parent": 13,
      "props": {
        "children": {
          "type": "value",
          "value": "Medication Give:"
        },
        "style": {
          "type": "code",
          "value": "{width: \"10rem\", whiteSpace: \"normal\"}"
        }
      },
      "seq": 38,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 39,
      "name": "Radio",
      "parent": 13,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.painQuestion?.painMedicationGive === \"No\""
        },
        "label": {
          "type": "value",
          "value": "No"
        },
        "name": {
          "type": "value",
          "value": "painQuestion.painMedicationGive"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width: \"5rem\", whiteSpace: \"normal\"}"
        },
        "value": {
          "type": "value",
          "value": "No"
        }
      },
      "seq": 39,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 40,
      "name": "Radio",
      "parent": 13,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.painQuestion?.painMedicationGive === \"Yes\""
        },
        "label": {
          "type": "value",
          "value": "Yes"
        },
        "name": {
          "type": "value",
          "value": "painQuestion.painMedicationGive"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width: \"6rem\", whiteSpace: \"normal\"}"
        },
        "value": {
          "type": "value",
          "value": "Yes"
        }
      },
      "seq": 40,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 13,
      "props": {
        "children": {
          "type": "code",
          "value": "props.isMedicationGive"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": null,
      "id": 42,
      "name": "div",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"16px\"}"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": null,
      "id": 43,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "code",
          "value": "props.onSedatScoreTooltip"
        },
        "style": {
          "type": "code",
          "value": "{width: \"2rem\"}"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": null,
      "id": 44,
      "name": "label",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": "Sedation score (before Opiods given):"
        },
        "style": {
          "type": "code",
          "value": "{width: \"18rem\", whiteSpace: \"normal\"}"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "Dropdown",
      "parent": 14,
      "props": {
        "name": {
          "type": "value",
          "value": "painQuestion.painSedatScoreBefore"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.reAssessmentOptions?.painSedatScore"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ minWidth: \"6rem\" }"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.painQuestion?.painSedatScoreBefore"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 14,
      "props": {
        "children": {
          "type": "code",
          "value": "props.onSedatScoreTooltip"
        },
        "style": {
          "type": "code",
          "value": "{width: \"2rem\", marginLeft: \"4rem\"}"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 47,
      "name": "label",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": "Sedation score (after Opiods given):"
        },
        "style": {
          "type": "code",
          "value": "{width: \"18rem\", whiteSpace: \"normal\"}"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "Dropdown",
      "parent": 14,
      "props": {
        "name": {
          "type": "value",
          "value": "painQuestion.painSedatScoreAfter"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.reAssessmentOptions?.painSedatScore"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ minWidth: \"6rem\" }"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.painQuestion?.painSedatScoreAfter"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": null,
      "id": 49,
      "name": "label",
      "parent": 15,
      "props": {
        "children": {
          "type": "value",
          "value": "Pain score after intervention:"
        },
        "style": {
          "type": "code",
          "value": "{width: \"16rem\", whiteSpace: \"normal\"}"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "Radio",
      "parent": 15,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.painQuestion?.painScoreAfterIntervention === \"IV min\""
        },
        "label": {
          "type": "value",
          "value": "IV min"
        },
        "name": {
          "type": "value",
          "value": "painQuestion.painScoreAfterIntervention"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width: \"8rem\", whiteSpace: \"normal\"}"
        },
        "value": {
          "type": "value",
          "value": "IV min"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "Radio",
      "parent": 15,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.painQuestion?.painScoreAfterIntervention === \"IM 30 min\""
        },
        "label": {
          "type": "value",
          "value": "IM 30 min"
        },
        "name": {
          "type": "value",
          "value": "painQuestion.painScoreAfterIntervention"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width: \"8rem\", whiteSpace: \"normal\"}"
        },
        "value": {
          "type": "value",
          "value": "IM 30 min"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "Radio",
      "parent": 15,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.painQuestion?.painScoreAfterIntervention === \"Oral / non med 60 min\""
        },
        "label": {
          "type": "value",
          "value": "Oral / non med 60 min"
        },
        "name": {
          "type": "value",
          "value": "painQuestion.painScoreAfterIntervention"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{width: \"15rem\", whiteSpace: \"normal\"}"
        },
        "value": {
          "type": "value",
          "value": "Oral / non med 60 min"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 53,
      "name": "div",
      "parent": 15,
      "props": {
        "children": {
          "type": "code",
          "value": "props.isPainScoreAfterIntervention"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "label",
      "parent": 42,
      "props": {
        "children": {
          "type": "value",
          "value": "Side Effects (SE):"
        },
        "style": {
          "type": "code",
          "value": "{width: \"9rem\", whiteSpace: \"normal\"}"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 55,
      "name": "Dropdown",
      "parent": 42,
      "props": {
        "clearable": {
          "type": "code",
          "value": "true"
        },
        "name": {
          "type": "value",
          "value": "painQuestion.painSideEffect"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "options": {
          "type": "code",
          "value": "props.reAssessmentOptions?.painSideEffect"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.painQuestion?.painSideEffect"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "div",
      "parent": 42,
      "props": {
        "children": {
          "type": "code",
          "value": "props.isOtherSideEffect"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "label",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": "Shift"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0rem 2rem\", fontWeight: \"bold\"  }"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "Radio",
      "parent": 16,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.painData?.saveShift === \"Day\""
        },
        "label": {
          "type": "value",
          "value": "Day"
        },
        "name": {
          "type": "value",
          "value": "painData.saveShift"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\"  }"
        },
        "value": {
          "type": "value",
          "value": "Day"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "Radio",
      "parent": 16,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.painData?.saveShift === \"Night\""
        },
        "label": {
          "type": "value",
          "value": "Night"
        },
        "name": {
          "type": "value",
          "value": "painData.saveShift"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeData"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0rem 1rem\"  }"
        },
        "value": {
          "type": "value",
          "value": "Night"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": null,
      "id": 60,
      "name": "label",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเมินวันที่"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0rem 2rem\", fontWeight: \"bold\"  }"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 61,
      "name": "DateTextBox",
      "parent": 16,
      "props": {
        "onChange": {
          "type": "code",
          "value": "props.onChangeDatePain"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.painData?.saveData"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": null,
      "id": 62,
      "name": "label",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": "เวลา"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", alignItems: \"center\", margin: \"0rem 2rem\", fontWeight: \"bold\"  }"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 63,
      "name": "TimeFreeTextBox24",
      "parent": 16,
      "props": {
        "autoFocus": {
          "type": "code",
          "value": "false"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChangeTimePain"
        },
        "value": {
          "type": "code",
          "value": "props.ReAssessmentSequence?.painData?.saveTime"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": null,
      "id": 64,
      "name": "div",
      "parent": 16,
      "props": {
        "children": {
          "type": "code",
          "value": "props?.buttonSavePain"
        },
        "style": {
          "type": "code",
          "value": "{ margin: \"0rem 0rem 0rem 2rem\" }"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": null,
      "id": 65,
      "name": "div",
      "parent": 11,
      "props": {
        "children": {
          "type": "code",
          "value": "props.isOtherCharacteristic"
        },
        "style": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 30,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "name": "CardReAssessmentPainUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
