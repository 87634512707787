import React, { useEffect, useState } from "react";
import ReactTable, { CellInfo } from "react-table-6";
import { Button } from "semantic-ui-react";
import { useIntl } from "react-intl";

type CardActiveEncounterType = {
  onEvent: any;
  toMiscellaneous: (data : {patient_id: number, id: number, type: string}) => void ;
  encounterPatientList?: any[] | undefined;
  patientInfo: any;
};

const CardActiveEncounter = (props: CardActiveEncounterType) => {
  const intl = useIntl();
  const [activeEncounter, setActiveEncounter] = useState<any[]>([]);

  useEffect(() => {
    if (props.patientInfo?.id) {
      props.onEvent({
        message: "GetEncounterWithPatient",
        params: { patientId: props.patientInfo?.id, excludeCancel: true },
      });
    }
  }, [props.patientInfo]);

  useEffect(() => {
    if (props.encounterPatientList && props.encounterPatientList?.length > 0) {
      setActiveEncounter(props.encounterPatientList)
    }
  }, [props.encounterPatientList])


  const columns = [
    {
      Header: "",
      Cell: (cellInfo: CellInfo) => {
        return (
          <Button size="mini" basic color="blue" onClick={() => {
            // console.log('cellInfo?.original: ', cellInfo?.original);
            props.toMiscellaneous(cellInfo?.original);
          }}>
            Select
          </Button>
        );
      },
      width: 90
    },
    {
      Header: "Encounter",
      accessor: "number",
      width: 90
    },
    {
      Header: "Division",
      accessor: "division_name",
    },
    {
      Header: "Doctor",
      accessor: "doctor_name",
    },
    {
      Header: "Status",
      accessor: "status_name",
    },
    {
      Header: "Created",
      accessor: "created",
    },
    {
      Header: "Update",
      accessor: "edited",
    },
  ];

  return (
    <div style={{ margin: "4px" }}>
      <ReactTable
        className="-striped -highlight"
        // defaultPageSize={activeEncounter?.length}
        showPagination={false}
        data={activeEncounter}
        columns={columns}
      />
    </div>
  );
};

export default CardActiveEncounter;
