// Types
type CostCenterMedFeeSlipProps = {
  fields: any[];
  params: any;
  division: string;
  report_name: any;
};

const FONT_SIZE = 14;

const MOCK_DATA = [
  { name: "ภาควิชาทันตกรรมหัตถการ", code: "1320800000", price: "3,400" },
  { name: "ภาควิชาทันตกรรมสำหรับเด็ก", code: "1321200000", price: "910" },
  { name: "คลินิกรังสีวิทยา", code: "1325006000", price: "16,930" },
  { name: "งานคลินิกทันตกรรมบริการ", code: "1325301000", price: "150" },
  { name: "งานคลินิกทันตกรรมบริการพิเศษ", code: "1325302000", price: "1,460" },
  { name: "หน่วยหออภิบาลผู้ป่วยใน", code: "1325303020", price: "17,500" },
  { name: "ห้องตรวจศัลยกรรมและฉุกเฉิน", code: "1325303030", price: "500" },
  { name: "งานทันตกรรมรากเทียม", code: "1325306000", price: "450" },
  { name: "หน่วยเวชระเบียน", code: "1325311030", price: "390" },
  { name: "ห้องจ่ายยา", code: "1325312010", price: "813" },
  {
    name: "หลักสูตร วบ.ม. สาขาวิชาทันตกรรมผู้สูงอายุและการดูแลผู้ป่วยพิเศษ (หลักสูตรนานาชาติ)",
    code: "2320032203",
    price: "389",
  },
  { name: "หลักสูตรปกศ.บ.สูงทันตกรรมจัดฟัน-inter", code: "2320042202", price: "800" },
  { name: "หลักสูตร ทบ.ภาควิชาทันตกรรมจัดฟัน", code: "2320611101", price: "100" },
  { name: "หลักสูตรปกศ.บ.สูง สาขาวิชาทันตกรรมจัดฟัน", code: "2320641201", price: "6,050" },
  {
    name: "หลักสูตร วท.ด.สาขาวิชาทันตกรรมจัดฟัน (หลักสูตรนานาชาติ)",
    code: "2320652201",
    price: "10,500",
  },
  { name: "หลักสูตร วท.ม. สาขาวิชาทันตกรรมประดิษฐุ์", code: "2320731201", price: "21,300" },
  { name: "หลักสูตรปกศ.บ.สูง สาขาวิชาทันตกรรมประดิษฐุ์", code: "2320741201", price: "5,500" },
  { name: "หลักสูตร วท.ด.สาขาวิชาทันตกรรมประดิษฐุ์", code: "2320751201", price: "27,001" },
  { name: "หลักสูตร ปกศ.บ.สูงสาขาวิชาวิทยาเอนโดดอนต์", code: "23208411202", price: "4,100" },
  { name: "หลักสูต ทบ.ภาควิชาศัลยกรรมศาสตร์", code: "2321011101", price: "2,600" },
  {
    name: "หลักสูตร วท.ม. สาขาศัลยศาสตร์ช่องปากและแม็กซิลโลเฟเซียล",
    code: "2321031201",
    price: "3,900",
  },
  {
    name: "หลักสูตร ทบ.ภาควิชา.เวชศาสตร์ช่องปาก",
    code: "2321111101",
    price: "200",
  },
  {
    name: "หลักสูตร ทบ.ภาควิชาทันตกรรมสำหรับเด็ก",
    code: "2321211101",
    price: "590",
  },
  {
    name: "หลักสูตรวทม.ภาควิชาทันตกรรมสำหรับเด็ก",
    code: "2321231201",
    price: "1,160",
  },
  {
    name: "หลักสูตร ปกศ.ชั้นสูงทางวิทยาศาสตร์การแพทย์คลินิก สาขาปริทันตวิทยา",
    code: "2321541201",
    price: "1,866",
  },
];

const SvgCheckbox = (check?: boolean) => {
  return `<svg width="10" height="10">
  <rect x="1" y="5" width="10" height="10" x="1" ry="1" style="fill:transparent;stroke:${"black"};stroke-width:1.25;fill-opacity:1;stroke-opacity:0.75" />
  ${
    check &&
    `<polygon style="fill:${"black"};stroke-width:1;stroke:${"black"};transform:scale(0.045) translate(40 120)" points="191.268,26.967 59.541,158.683 5.615,104.76 0,110.386 59.541,169.92 196.887,32.585"/>`
  }
  </svg>`;
};

const genSignatureTemplate = (by: string, hidePosition = false) => {
  return {
    border: [true, false, true, true],
    style: "miniField",
    marginTop: 2.5,
    stack: [
      {
        marginLeft: 10,
        text: [
          { text: "ลงชื่อ " },
          { text: Array(105).fill("").join(".") },
          { text: "        " },
          { text: by },
        ],
        preserveLeadingSpaces: true,
      },
      {
        margin: [28, -2.5, 0, 0],
        text: [{ text: "( " }, { text: Array(105).fill("").join(".") }, { text: " )" }],
      },
      hidePosition
        ? { text: "" }
        : {
            text: [{ text: "ตำแหน่ง" }, { text: Array(120).fill("").join(".") }],
          },
      {
        marginTop: -2.5,
        text: [{ text: "วันที่นำส่ง" }, { text: Array(118).fill("").join(".") }],
      },
    ],
  };
};

const getPaddingBottom = (i: number, node: any, length: number) => {
  const bottom = 547.25;
  const nps = node.positions || [];
  const yLast = bottom - 15 - 2.5;
  const isLast = i === MOCK_DATA.length + 2;
  const current = nps?.[i * 5 - 1]?.top;
  const manyPage = nps.slice(-1)[0]?.pageNumber > 1 ? 8 : 0;

  const top = (nps[nps.length - 1]?.top || 0) - (manyPage ? 9 : 0);

  if (isLast || current > yLast) {
    return bottom - top;
  }

  return 0;
};

const FormCostCenterMedFeeSlip = (props: CostCenterMedFeeSlipProps) => {
  const length = props?.fields?.length || 0
  const fieldsLength =
    length > 19 ? length % 19 : length;
  const numberRows = 19 - fieldsLength;
  const rowEmpty = Array.from({ length: numberRows }).map((item: any, index: number) => [
    {
      text: " ",
      border: [true, false, true, false],
    },
    {
      text: " ",
      border: [true, false, true, false],
    },
    {
      text: " ",
      border: [true, false, true, false],
    },
    {
      text: " ",
      border: [true, false, true, false],
    },
    {
      text: " ",
      border: [true, false, true, false],
    },
  ]);

  console.log("CostCenterMedFeeSlip", props);

  return {
    pageSize: "A4",
    defaultStyle: {
      font: "THSarabunNew",
      lineHeight: 1,
      fontSize: FONT_SIZE,
    },
    pageMargins: [10, 105, 10, 264],
    styles: {
      fieldHeader: {
        fontSize: 14,
      },
      tableHeader: {
        bold: false,
        alignment: "center",
      },
      miniField: {
        fontSize: 12,
      },
      footerValue: {
        fontSize: 14,
      },
    },
    header: (currentPage: number, pageCount: number) => {
      return {
        margin: [15, 2.5, 15, 0],
        stack: [
          {
            table: {
              headerRows: 1,
              widths: ["100%"],
              body: [
                [
                  {
                    border: [false, false, false, false],
                    stack: [
                      {
                        marginTop: 17,
                        columns: [
                          {
                            width: 150,
                            text: "",
                          },
                          {
                            width: "*",
                            style: "fieldHeader",
                            alignment: "center",
                            stack: [
                              {
                                text: `${props.report_name || "ใบนำส่งเงินรายรับค่ารักษาพยาบาลตามศูนย์ต้นทุน"}`, // issue 67979 prevent undefined title
                                bold: true,
                              },
                              {
                                text: "โรงพยาบาลคณะทันตแพทยศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย",
                                marginTop: 2,
                              },
                            ],
                          },
                          {
                            width: 150,
                            stack: [
                              {
                                text: "\u00A0",
                              },
                              {
                                text: [
                                  { text: "ใบนำส่งเงินที่" },
                                  { text: "  :  " },
                                  { text: (props.params?.document_no || "") },
                                ],
                                preserveLeadingSpaces: true,
                              },
                            ],
                            alignment: "right",
                            marginTop: 2,
                          },
                        ],
                      },
                      {
                        text: [
                          { text: "จุดรับเงิน" },
                          { text: "  :  " },
                          { text: `${props.division || ""}` },
                          { text: "         " },
                          { text: "ผู้สรุปเงิน" },
                          { text: "  :  " },
                          { text: `${props.params?.closed_user_name || ""}` },
                        ],
                        preserveLeadingSpaces: true,
                        alignment: "center",
                        style: "fieldHeader",
                        marginTop: 2,
                      },
                      {
                        text: [
                          { text: "ข้าพเจ้าขอนำเงินที่ได้รับเมื่อ" },
                          { text: "    " },
                          { text: `วันที่ ${props.params?.start_date || ""}` },
                          { text: " - " },
                          { text: `วันที่ ${props.params?.end_date || ""}` },
                          { text: "    " },
                          { text: "ตามรายละเอียดดังต่อไปนี้" },
                        ],
                        style: "fieldHeader",
                        marginTop: 2,
                      },
                    ],
                  },
                ],
              ],
            },
            layout: {
              paddingRight: () => 0,
            },
          },
        ],
      };
    },
    content: [
      {
        table: {
          headerRows: 2,
          widths: ["5.5%", "*", "12.5%", "12.5%", "4%"],
          body: [
            [
              {
                text: "",
                style: "tableHeader",
                border: [true, true, true, false],
              },
              {
                text: "",
                style: "tableHeader",
                border: [true, true, true, false],
              },
              {
                text: "รหัส",
                style: "tableHeader",
                border: [true, true, true, false],
              },
              { text: "จำนวนเงิน", colSpan: 2, style: "tableHeader" },
              {},
            ],
            [
              {
                text: "ลำดับ",
                style: "tableHeader",
                marginTop: -8.5,
                border: [true, false, true, true],
              },
              {
                text: "รายการ",
                style: "tableHeader",
                marginTop: -8.5,
                border: [true, false, true, true],
              },
              { text: "ศูนย์ต้นทุน", style: "tableHeader", border: [true, false, true, true] },
              { text: "บาท", style: "tableHeader" },
              { text: "สต.", style: "tableHeader" },
            ],
            ...(props?.fields || []).map((item, index) => [
              { text: `${index+1}`, alignment: "center" },
              { text: `${item?.name || ""}` },
              { text: `${item?.code || ""}` },
              { text: `${item?.price_round_down || "0"}`, alignment: "right" },
              { text: `${item?.price_satang || ""}`, alignment: "center" },
            ]),
            ...rowEmpty,
            // Array(5)
            //   .fill("")
            //   .map((_, index) => ({
            //     id: `table_row_${index}_blank`,
            //     text: "",
            //   })),
          ],
        },
        layout: {
          vLineWidth: () => 0.25,
          hLineWidth: (i: number) => (i > 2 ? 0 : 0.25),
          paddingLeft: () => 4,
          paddingRight: () => 4,
          paddingTop: (i: number) => (i >= 2 ? 2.5 : 0),
          // paddingBottom: (i: any, node: any) => {
          //   return getPaddingBottom(i, node, MOCK_DATA.length);
          // },
        },
      },
    ],
    footer: () => {
      return {
        margin: [10, 0, 10, 0],
        stack: [
          {
            table: {
              headerRows: 1,
              widths: ["4%", "*", "12.5%", "12.5%", "4%"],
              body: [
                [
                  { text: "", border: [true, true, false, true] },
                  {
                    bold: true,
                    border: [false, true, false, true],
                    columns: [
                      {
                        width: "auto",
                        alignment: "center",
                        stack: [{ text: "รวมเงินนำส่ง" }, { text: "(ตัวอักษร)" }],
                        lineHeight: 0.6,
                        margin: [0, 1, 0, 7],
                      },
                      {
                        width: "*",
                        text: props.params?.total_cost_center_price_text
                          ? [
                              { text: "( " },
                              {
                                text: `${props.params?.total_cost_center_price_text || ""}`,
                                alignment: "center",
                              },
                              { text: " )" },
                            ]
                          : [],
                        marginRight: 20,
                        alignment: "right",
                        marginTop: 7,
                      },
                    ],
                  },
                  {
                    text: `${props.params?.total_cost_center_price_round_down || ""}`,
                    border: [false, true, true, true],
                    alignment: "center",
                    marginTop: 7,
                  },
                  {
                    text: `${props.params?.total_cost_center_price_satang || ""}`,
                    bold: true,
                    alignment: "right",
                    marginTop: 7,
                  },
                  { text: ".-", bold: true, alignment: "center", marginTop: 7 },
                ],
              ],
            },
            layout: {
              vLineWidth: () => 0.25,
              hLineWidth: (i: number) => 0.25,
              paddingLeft: () => 4,
              paddingRight: () => 4,
              paddingTop: () => 0,
              paddingBottom: () => 0,
            },
          },
          {
            columns: [
              {
                width: "50%",
                stack: [
                  {
                    table: {
                      headerRows: 1,
                      widths: ["60%", "32%", "8%"],
                      body: [
                        [
                          {
                            border: [true, false, true, true],
                            text: "รายละเอียดเงินนำส่ง",
                            colSpan: 3,
                            style: "miniField",
                            alignment: "center",
                          },
                          {},
                          {},
                        ],
                        [{ text: "เงินสด", style: "miniField" }, { text: "" }, { text: "" }],
                        ...[
                          { label: "เช็ค, ตั๋วแลกเงินธนาคาร (คราฟท์)", dot: 20 },
                          { label: "ธนาณัติ, ตั๋วแลกเงินไปรษณีย์", dot: 27 },
                          { label: "ใบนำฝากธนาคาร", dot: 0 },
                          {
                            title: "สลิปบัตรเครดิต",
                            label: "ธนาคารไทยพาณิชย์",
                            dot: 32,
                            left: 20,
                            bottom: false,
                          },
                          { label: "ธนาคารกรุงเทพ", dot: 38, top: false, bottom: false, left: 20 },
                          { label: "ธนาคารอื่น ๆ", dot: 42, top: false, left: 20 },
                        ].map((item) => [
                          {
                            border: [true, item.top ?? true, true, item.bottom ?? true],
                            stack: [
                              { text: item.title || "" },
                              {
                                marginLeft: item.left,

                                columns: [
                                  { width: "auto", text: item.label },
                                  {
                                    width: "*",
                                    text: " " + Array(item.dot).fill("").join("."),
                                    preserveLeadingSpaces: true,
                                    marginBottom: -1,
                                  },
                                  { width: "auto", text: "ฉบับ", alignment: "right" },
                                ],
                              },
                            ],
                            style: "miniField",
                            marginBottom: -2.5,
                          },
                          {
                            text: "\u00A0",
                            marginBottom: -2.5,
                            style: "miniField",
                          },
                          {
                            text: "\u00A0",
                            marginBottom: -2.5,
                            style: "miniField",
                          },
                        ]),
                      ],
                    },
                    layout: {
                      vLineWidth: () => 0.25,
                      hLineWidth: () => 0.25,
                      paddingLeft: () => 4,
                      paddingRight: () => 2,
                      paddingTop: () => 2,
                      paddingBottom: () => 0,
                    },
                  },
                  {
                    table: {
                      headerRows: 1,
                      widths: ["*"],
                      body: [[genSignatureTemplate("ผู้นำส่ง")]],
                    },
                    layout: {
                      vLineWidth: () => 0.25,
                      hLineWidth: () => 0.25,
                      paddingLeft: () => 4,
                      paddingRight: () => 2,
                      paddingTop: () => 1,
                      paddingBottom: () => 0,
                    },
                  },
                ],
              },
              {
                width: "50%",
                stack: [
                  {
                    table: {
                      headerRows: 1,
                      widths: ["60%", "32%", "8%"],
                      body: [
                        [
                          {
                            border: [false, false, true, false],
                            text: "\u00A0",
                            colSpan: 3,
                            style: "miniField",
                            alignment: "center",
                          },
                          {},
                          {},
                        ],
                        [
                          {
                            border: [false, true, true, true],
                            text: "ได้รับเงินถูกต้องแล้วจำนวน",
                            style: "miniField",
                          },
                          { text: "" },
                          { text: "" },
                        ],
                      ],
                    },
                    layout: {
                      vLineWidth: () => 0.25,
                      hLineWidth: () => 0.25,
                      paddingLeft: () => 4,
                      paddingRight: () => 2,
                      paddingTop: () => 2,
                      paddingBottom: () => 0,
                    },
                  },

                  {
                    table: {
                      headerRows: 1,
                      widths: ["*"],
                      body: [
                        [
                          {
                            border: [false, false, true, false],
                            style: "miniField",
                            stack: [
                              {
                                text: [
                                  { text: " (ตัวอักษร)" },
                                  { text: Array(117).fill("").join(".") },
                                ],
                              },
                              { text: Array(133).fill("").join("."), marginTop: -2.5 },
                              { text: "", marginTop: 14.5 },
                              genSignatureTemplate("ผู้รับเงิน"),
                            ],
                          },
                        ],
                      ],
                    },
                    layout: {
                      vLineWidth: () => 0.25,
                      hLineWidth: (i: number) => 0.25,
                      paddingLeft: () => 4,
                      paddingRight: () => 2,
                      paddingTop: () => 4,
                      paddingBottom: () => 0,
                    },
                  },
                  {
                    table: {
                      headerRows: 1,
                      widths: ["*"],
                      body: [
                        [
                          {
                            border: [false, true, true, true],
                            style: "miniField",
                            stack: [
                              {
                                columns: [
                                  { svg: SvgCheckbox(false), width: 15, marginTop: 1 },
                                  {
                                    width: "auto",
                                    text: "สมุดเงินสด",
                                    margin: [0, 2.95, 5, 0],
                                  },
                                  {
                                    svg: SvgCheckbox(false),
                                    width: 15,
                                    marginTop: 1,
                                  },
                                  {
                                    width: "auto",
                                    text: "ทะเบียนผลประโยชน์",
                                    margin: [0, 2.95, 5, 0],
                                  },
                                  {
                                    width: "auto",
                                    margin: [0, 2.95, 5, 0],
                                    text: [
                                      { text: "เล่ม" },
                                      { text: Array(30).fill("").join(".") },
                                    ],
                                  },
                                  {
                                    width: "auto",
                                    marginTop: 2.95,
                                    text: [
                                      { text: "หน้า" },
                                      { text: Array(30).fill("").join(".") },
                                    ],
                                  },
                                ],
                              },
                              {
                                text: "",
                                marginTop: -17.5,
                              },
                              genSignatureTemplate("ผู้บันทึก", true),
                            ],
                          },
                        ],
                      ],
                    },
                    layout: {
                      vLineWidth: () => 0.25,
                      hLineWidth: (i: number) => 0.25,
                      paddingLeft: () => 2,
                      paddingRight: () => 2,
                      paddingTop: () => 0,
                      paddingBottom: () => 0,
                    },
                  },
                ],
              },
            ],
          },
        ],
      };
    },
  };
};

export default FormCostCenterMedFeeSlip;
