import React from 'react'
import {
   Button,
   Checkbox,
   Input,
} from 'semantic-ui-react'



import styles from './PTOTSubjectiveLOW.module.css'

export interface PTOTSubjectiveLOWViewDataType {
    PtOtSubjectiveLow2: boolean,
    PtOtSubjectiveLow2Disabled: boolean,
    PtOtSubjectiveLow3: boolean,
    PtOtSubjectiveLow3Disabled: boolean,
    PtOtSubjectiveLow4: boolean,
    PtOtSubjectiveLow4Disabled: boolean,
    PtOtSubjectiveLow7: boolean,
    PtOtSubjectiveLow7Disabled: boolean,
    PtOtSubjectiveLow9: boolean,
    PtOtSubjectiveLow9Disabled: boolean,
    PtOtSubjectiveLow11: boolean,
    PtOtSubjectiveLow11Disabled: boolean,
    PtOtSubjectiveLow13: boolean,
    PtOtSubjectiveLow13Disabled: boolean,
    PtOtSubjectiveLow17: boolean,
    PtOtSubjectiveLow17Disabled: boolean,
    PtOtSubjectiveLow18: string,
    PtOtSubjectiveLow18Disabled: boolean,
    PtOtSubjectiveLow19: boolean,
    PtOtSubjectiveLow19Disabled: boolean,
    PtOtSubjectiveLow20: boolean,
    PtOtSubjectiveLow20Disabled: boolean,
    PtOtSubjectiveLow22: boolean,
    PtOtSubjectiveLow22Disabled: boolean,
    PtOtSubjectiveLow24: boolean,
    PtOtSubjectiveLow24Disabled: boolean,
    PtOtSubjectiveLow26: boolean,
    PtOtSubjectiveLow26Disabled: boolean,
    PtOtSubjectiveLow31: string,
    PtOtSubjectiveLow31Disabled: boolean,
    PtOtSubjectiveLow32: boolean,
    PtOtSubjectiveLow32Disabled: boolean,
    PtOtSubjectiveLow34: string,
    PtOtSubjectiveLow34Disabled: boolean,
    SaveRequestDisabled: boolean,
}

export const PTOTSubjectiveLOWInitialViewData: PTOTSubjectiveLOWViewDataType = {
    PtOtSubjectiveLow2: false,
    PtOtSubjectiveLow2Disabled: false,
    PtOtSubjectiveLow3: false,
    PtOtSubjectiveLow3Disabled: false,
    PtOtSubjectiveLow4: false,
    PtOtSubjectiveLow4Disabled: false,
    PtOtSubjectiveLow7: false,
    PtOtSubjectiveLow7Disabled: false,
    PtOtSubjectiveLow9: false,
    PtOtSubjectiveLow9Disabled: false,
    PtOtSubjectiveLow11: false,
    PtOtSubjectiveLow11Disabled: false,
    PtOtSubjectiveLow13: false,
    PtOtSubjectiveLow13Disabled: false,
    PtOtSubjectiveLow17: false,
    PtOtSubjectiveLow17Disabled: false,
    PtOtSubjectiveLow18: "",
    PtOtSubjectiveLow18Disabled: false,
    PtOtSubjectiveLow19: false,
    PtOtSubjectiveLow19Disabled: false,
    PtOtSubjectiveLow20: false,
    PtOtSubjectiveLow20Disabled: false,
    PtOtSubjectiveLow22: false,
    PtOtSubjectiveLow22Disabled: false,
    PtOtSubjectiveLow24: false,
    PtOtSubjectiveLow24Disabled: false,
    PtOtSubjectiveLow26: false,
    PtOtSubjectiveLow26Disabled: false,
    PtOtSubjectiveLow31: "",
    PtOtSubjectiveLow31Disabled: false,
    PtOtSubjectiveLow32: false,
    PtOtSubjectiveLow32Disabled: false,
    PtOtSubjectiveLow34: "",
    PtOtSubjectiveLow34Disabled: false,
    SaveRequestDisabled: false,
}

const Sub = (props: any) => {
    console.log(props.viewData, "1123123")
    const children = React.Children.toArray(props.children)
    return (
        <div className={styles.container}>
            <div className={styles.PtOtSubjectiveLow1}>
                <div
                    style={{background: "#F3F3F3", height: "100%", width: "100%"}}
                >
                    <p></p>
                </div>
            </div>
            <div className={styles.PtOtSubjectiveLow2}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtOtSubjectiveLow2}
                    disabled={ props.viewData.PtOtSubjectiveLow2Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTOTSubjectiveLOW",
                                name: "PtOtSubjectiveLow2"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtOtSubjectiveLow3}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtOtSubjectiveLow3}
                    disabled={ props.viewData.PtOtSubjectiveLow3Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTOTSubjectiveLOW",
                                name: "PtOtSubjectiveLow3"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtOtSubjectiveLow4}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtOtSubjectiveLow4}
                    disabled={ props.viewData.PtOtSubjectiveLow4Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTOTSubjectiveLOW",
                                name: "PtOtSubjectiveLow4"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtOtSubjectiveLow5}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Precaution*</strong></p>
                </div>
            </div>
            <div className={styles.PtOtSubjectiveLow6}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>None</p>
                </div>
            </div>
            <div className={styles.PtOtSubjectiveLow7}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtOtSubjectiveLow7}
                    disabled={ props.viewData.PtOtSubjectiveLow7Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTOTSubjectiveLOW",
                                name: "PtOtSubjectiveLow7"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtOtSubjectiveLow8}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Infectious precaution</p>
                </div>
            </div>
            <div className={styles.PtOtSubjectiveLow9}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtOtSubjectiveLow9}
                    disabled={ props.viewData.PtOtSubjectiveLow9Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTOTSubjectiveLOW",
                                name: "PtOtSubjectiveLow9"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtOtSubjectiveLow10}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Malignancy</p>
                </div>
            </div>
            <div className={styles.PtOtSubjectiveLow11}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtOtSubjectiveLow11}
                    disabled={ props.viewData.PtOtSubjectiveLow11Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTOTSubjectiveLOW",
                                name: "PtOtSubjectiveLow11"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtOtSubjectiveLow12}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Vascular disorder</p>
                </div>
            </div>
            <div className={styles.PtOtSubjectiveLow13}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtOtSubjectiveLow13}
                    disabled={ props.viewData.PtOtSubjectiveLow13Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTOTSubjectiveLOW",
                                name: "PtOtSubjectiveLow13"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtOtSubjectiveLow14}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Internel Instrument</p>
                </div>
            </div>
            <div className={styles.PtOtSubjectiveLow15}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Osteoporosis</p>
                </div>
            </div>
            <div className={styles.PtOtSubjectiveLow16}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Pregnancy</p>
                </div>
            </div>
            <div className={styles.PtOtSubjectiveLow17}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtOtSubjectiveLow17}
                    disabled={ props.viewData.PtOtSubjectiveLow17Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTOTSubjectiveLOW",
                                name: "PtOtSubjectiveLow17"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtOtSubjectiveLow18}>
                <Input
                    value={props.viewData.PtOtSubjectiveLow18}
                    disabled={ props.viewData.PtOtSubjectiveLow18Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTOTSubjectiveLOW',
                            name: 'PtOtSubjectiveLow18',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtOtSubjectiveLow19}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtOtSubjectiveLow19}
                    disabled={ props.viewData.PtOtSubjectiveLow19Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTOTSubjectiveLOW",
                                name: "PtOtSubjectiveLow19"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtOtSubjectiveLow20}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtOtSubjectiveLow20}
                    disabled={ props.viewData.PtOtSubjectiveLow20Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTOTSubjectiveLOW",
                                name: "PtOtSubjectiveLow20"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtOtSubjectiveLow21}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Hypertension</p>
                </div>
            </div>
            <div className={styles.PtOtSubjectiveLow22}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtOtSubjectiveLow22}
                    disabled={ props.viewData.PtOtSubjectiveLow22Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTOTSubjectiveLOW",
                                name: "PtOtSubjectiveLow22"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtOtSubjectiveLow23}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Falling</p>
                </div>
            </div>
            <div className={styles.PtOtSubjectiveLow24}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtOtSubjectiveLow24}
                    disabled={ props.viewData.PtOtSubjectiveLow24Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTOTSubjectiveLOW",
                                name: "PtOtSubjectiveLow24"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtOtSubjectiveLow26}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtOtSubjectiveLow26}
                    disabled={ props.viewData.PtOtSubjectiveLow26Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTOTSubjectiveLOW",
                                name: "PtOtSubjectiveLow26"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtOtSubjectiveLow27}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Diabetes mellitus</p>
                </div>
            </div>
            <div className={styles.PtOtSubjectiveLow28}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Fracture</p>
                </div>
            </div>
            <div className={styles.PtOtSubjectiveLow29}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Aspiration</p>
                </div>
            </div>
            <div className={styles.PtOtSubjectiveLow30}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Seizure</p>
                </div>
            </div>
            <div className={styles.PtOtSubjectiveLow31}>
                <Input
                    value={props.viewData.PtOtSubjectiveLow31}
                    disabled={ props.viewData.PtOtSubjectiveLow31Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTOTSubjectiveLOW',
                            name: 'PtOtSubjectiveLow31',
                            value: e.target.value
                        }
                    })}}
                    style={{height: "100%", width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtOtSubjectiveLow32}>
                <Checkbox 
                    style={{height: "100%", width: "100%"}}
                    checked={props.viewData.PtOtSubjectiveLow32}
                    disabled={ props.viewData.PtOtSubjectiveLow32Disabled }
                    onChange={() => {
                        props.onEvent({
                            message: "check",
                            params: {
                                view: "PTOTSubjectiveLOW",
                                name: "PtOtSubjectiveLow32"
                            }
                        })
                    }}
                />
            </div>
            <div className={styles.PtOtSubjectiveLow33}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p>Other</p>
                </div>
            </div>
            <div className={styles.PtOtSubjectiveLow34}>
                <Input
                    value={props.viewData.PtOtSubjectiveLow34}
                    disabled={ props.viewData.PtOtSubjectiveLow34Disabled }
                    onChange={(e) => { props.onEvent({
                        message: 'inputChange',
                        params: {
                            view: 'PTOTSubjectiveLOW',
                            name: 'PtOtSubjectiveLow34',
                            value: e.target.value
                        }
                    })}}
                    style={{width: "100%"}}
                >
                </Input>
            </div>
            <div className={styles.PtOtSubjectiveLow35}>
                <div
                    style={{height: "100%", width: "100%"}}
                >
                    <p><strong>Subjective</strong></p>
                </div>
            </div>
            <div className={styles.SaveRequest}>
                <Button
                    disabled={ props.viewData.SaveRequestDisabled }
                    onClick={(e) => { props.onEvent({
                        message: 'clickButton',
                        params: {
                            view: 'PTOTSubjectiveLOW',
                            name: 'SaveRequest'
                        }
                    })}}
                    color="green"
                    style={{width: "100%"}}
                >
                    Save
                </Button>
            </div>
        </div>
    )
}

export default Sub

const getTsName = (cssClass: string) => {
    return cssClass.split('-').map((s) => (
        s.charAt(0).toUpperCase() + s.slice(1)
    )).join('')
}
